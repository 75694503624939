import { IconButton } from '@material-ui/core';
import { CDNUrl } from 'utils/constants';
import { useEffect, useMemo, useState } from 'react';
import { SuggestedTagsType } from 'common/schema/commonSchemaRemoteTypes';
import clsx from 'clsx';
import { suggestedTagsStyles } from './SuggestedTags.styles';

type SuggestedTagsProps = {
  tags: SuggestedTagsType[];
  onTagClick?: (tag: string) => void;
  onTagRemoveClick?: (tag: string) => void;
  canRemove: boolean;
  limitTagsToShow?: number;
  showPlusIcon?: boolean;
  containerClassName?: string;
  buttonTagContainerClassName?: string;
  removeButtonClassName?: string;
};

const SuggestedTags: React.FC<SuggestedTagsProps> = ({
  tags,
  onTagClick,
  onTagRemoveClick,
  canRemove,
  limitTagsToShow,
  showPlusIcon = true,
  containerClassName,
  buttonTagContainerClassName,
  removeButtonClassName,
}) => {
  const [suggestedTagList, setSuggestedTagList] = useState<SuggestedTagsType[]>([]);
  const customStyles = suggestedTagsStyles();

  useEffect(() => {
    setSuggestedTagList(tags);
  }, [tags]);

  const removeTagFromLocalList = (tag: string) => {
    const newTagList = suggestedTagList.filter((t) => t.value !== tag);
    setSuggestedTagList(newTagList);
  };

  const onClick = (tag: string) => {
    removeTagFromLocalList(tag);
    onTagClick?.(tag);
  };

  const onRemoveClick = (tag: string) => {
    removeTagFromLocalList(tag);
    onTagRemoveClick?.(tag);
  };

  const suggestedTags = useMemo(
    () => suggestedTagList.sort((a, b) => a.order - b.order).slice(0, limitTagsToShow || undefined),
    [suggestedTagList, limitTagsToShow]
  );

  return (
    <div className={clsx(customStyles.container, containerClassName)}>
      {suggestedTags.map((tag) => (
        <div className={clsx(customStyles.buttonContainer, buttonTagContainerClassName)} key={tag.value}>
          <div
            className={customStyles.addButton}
            style={canRemove ? {} : { paddingRight: '10px' }}
            role="button"
            tabIndex={0}
            onClick={() => onClick(tag.value)}
          >
            {showPlusIcon && <span> + </span>} {tag.description}
          </div>
          {canRemove && (
            <IconButton
              className={clsx(customStyles.removeButton, removeButtonClassName)}
              onClick={() => onRemoveClick(tag.value)}
            >
              <img
                draggable="false"
                className={customStyles.removeImage}
                src={`${CDNUrl}/images/exitIcon.svg`}
                alt="delete"
              />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};

export default SuggestedTags;
