import { useMemo } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from 'common/utils/constants';
import { useCurrentWindowSize } from 'common/utils/windowSize';
import { isMobile } from 'react-device-detect';
import { useShouldShowFeatureFlag } from 'common/features/FeatureFlag/hooks/useFeatureFlag';

/**
 * * Returns true if the screen size is small enough to be considered mobile
 * * or if the device is a mobile device
 */
export const useIsMobileScreen = (): boolean => {
  const [accessDesktopVersionsFromTablets] = useShouldShowFeatureFlag('accessDesktopVersionsFromTablets');
  const breakpoint = useMemo(
    () => (accessDesktopVersionsFromTablets ? MOBILE_BREAKPOINT : TABLET_BREAKPOINT),
    [accessDesktopVersionsFromTablets]
  );
  const isMobileWindowSize = useCurrentWindowSize().down(breakpoint);
  const isMobileMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down(breakpoint), {
    noSsr: true,
  });
  return useMemo(() => {
    if (!accessDesktopVersionsFromTablets) {
      return isMobileWindowSize || isMobileMediaQuery || isMobile;
    }
    return isMobileWindowSize || isMobileMediaQuery;
  }, [accessDesktopVersionsFromTablets, isMobileMediaQuery, isMobileWindowSize]);
};
