import * as Types from './commonSchemaRemoteOperationTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export const LocalCommonModifiersRequiredFieldsFragmentDoc = gql`
    fragment LocalCommonModifiersRequiredFields on Artwork {
  transaction_histories(
    where: {_and: [{status: {_eq: ACCEPTED}}, {trashed_at: {_is_null: true}}, {print_id: {_is_null: true}}]}
  ) {
    status
  }
  publishDate: published_date
  creationDate: created_at
  showcasePrice: Artwork_prices(where: {scope: {_eq: SHOWCASE}}) {
    price
  }
  details: artwork_details {
    id
    year: creation_date_year
    height
    width
    discipline_other
    ENUM_artwork_discipline {
      description
    }
  }
  artworkToCollections: artwork_to_collections(
    where: {Collection: {type: {_in: [CREATED, OWNED]}}}
  ) {
    collection_id
    order
    Collection {
      name
      type
    }
    Artwork_to_showroom_detail {
      artwork_showroom_price {
        price
      }
    }
  }
  artworkToSeries: artwork_to_collections(
    where: {Collection: {type: {_eq: SERIES}}}
  ) {
    id
    collection: Collection {
      id
      name
    }
  }
  art_prizes(
    where: {Art_prize_winner: {Art_prize_show: {published_at: {_lte: "now()"}}}}
  ) {
    art_prize_show_id
    Art_prize_winner {
      position_type
      Art_prize_show {
        id
        name: prize_show_slug
        title
      }
    }
  }
}
    `;
export const ProfileArtworkEditionFragmentDoc = gql`
    fragment ProfileArtworkEdition on Edition {
  id
  fixedPrice: price
  currency
  isPricePublic: is_price_public
  isAvailableForSale: is_available_sale
  title
  editionSizes: Edition_By_Sizes(where: {status: {_eq: COMPLETE}}) {
    id
    width
    height
    measuring_unit
    print_size
    print_date_year
    fixedPrice: price
    currency
    isPricePublic: is_price_public
  }
  printAmounts: Print_amounts {
    availablePrintAmount: available_print_amount
  }
}
    `;
export const ProfileArtistArtworksFragmentDoc = gql`
    fragment ProfileArtistArtworks on Artwork {
  id
  slug
  is_available_sale
  primary_image_metadata_id
  thumbnail_metadata_id
  edition_type
  Artwork_prices {
    price
    currency
    is_public
    scope
  }
  artwork_details {
    title
    creation_date_day
    creation_date_month
    creation_date_year
    width
    height
    measuring_unit
  }
  artist_context {
    id
    profile {
      id
      avatar
      title
      handle
      first_name
      last_name
    }
  }
  artwork_to_collections(where: {Collection: {type: {_eq: CREATED}}}) {
    collection_id
    order
  }
  Editions(
    where: {publish_date: {_is_null: false}, is_public: {_eq: true}, deleted_at: {_is_null: true}}
  ) {
    ...ProfileArtworkEdition
  }
}
    ${ProfileArtworkEditionFragmentDoc}`;
export const ArtistContextFragmentDoc = gql`
    fragment ArtistContext on Context {
  id
  profile {
    id
    avatar
    handle
    first_name
    last_name
    title
  }
  locations {
    city
    country
  }
}
    `;
export const ProfileCollectorArtworkFragmentDoc = gql`
    fragment ProfileCollectorArtwork on Artwork {
  id
  slug
  primary_image_metadata_id
  thumbnail_metadata_id
  updated_at
  editionType: edition_type
  artworkDetails: artwork_details {
    creation_date_year
    depth
    height
    title
    width
  }
  artistContext: artist_context {
    ...ArtistContext
  }
}
    ${ArtistContextFragmentDoc}`;
export const ProfileLocalCommonModifiersRequiredFieldsFragmentDoc = gql`
    fragment ProfileLocalCommonModifiersRequiredFields on Context {
  id
}
    `;
export const ExplorePageProfileRequiredFieldsFragmentDoc = gql`
    fragment ExplorePageProfileRequiredFields on Explore_artists {
  contextId: context_id
  artworksCount: artworks_count
}
    `;
export const CommonNotificationCenterListingCursorFragmentDoc = gql`
    fragment CommonNotificationCenterListingCursor on app_notifications_listing {
  id
  type
  updatedAt: updated_message_at
}
    `;
export const ProfileArtworksListingContentFragmentDoc = gql`
    fragment ProfileArtworksListingContent on Artwork {
  id
  slug
  is_available_sale
  primary_image_metadata_id
  thumbnail_metadata_id
  Artwork_prices {
    price
  }
  artwork_details {
    title
    creation_date_day
    creation_date_month
    creation_date_year
    width
    height
  }
  artist_context {
    id
    profile {
      id
      avatar
      title
      handle
      first_name
      last_name
    }
  }
  artwork_to_collections(where: {Collection: {type: {_eq: $collectionType}}}) {
    collection_id
    order
  }
}
    `;
export const ProfileAppearanceSettingsFragmentDoc = gql`
    fragment ProfileAppearanceSettings on Profile {
  profile_appearance_settings {
    id
    artwork_info_trigger
    artwork_info
    profile_columns_count
    profile_layout_type
  }
}
    `;
export const CommonArtShowArtworkListingCursorFragmentDoc = gql`
    fragment CommonArtShowArtworkListingCursor on Art_show_artwork_listing {
  artworkId: artwork_id
  artistContextId: artist_context_id
  isCollected: is_collected
  aspectRatio: aspect_ratio
}
    `;
export const AnnouncedArtShowDetailsFragmentDoc = gql`
    fragment AnnouncedArtShowDetails on Announced_art_show {
  uri
  organizerHandle: organizer_handle
  addressOne: address_line_1
  addressTwo: address_line_2
  collectionId: art_show_collection_id
  bannerMetaId: banner_metadata_id
  organizerId: organized_by_context_id
  endAt: end_at
  startAt: start_at
  grandOpeningAt: grand_opening_at
  openingSoonAt: opening_soon_at
  closingSoonAt: closing_soon_at
  isAnnounced: is_announced
  statement
  title
  organizer {
    profile {
      id
      avatar
      title
    }
  }
  zipCode: zip_code
  worldCity: world_city {
    displayName: display_name
    state: state_code
    country: country_name
    lat
    lng
  }
}
    `;
export const CommonArtworkManagerArtistListingCursorFragmentDoc = gql`
    fragment CommonArtworkManagerArtistListingCursor on artwork_manager_view {
  artworkId: artwork_id
  edition: Edition {
    id
  }
  aspect {
    ratio: aspect_ratio
  }
}
    `;
export const CommonArtworkArtistProfileListingCursorFragmentDoc = gql`
    fragment CommonArtworkArtistProfileListingCursor on Artwork_artist_profile_listing {
  artworkId: artwork_id
  aspect {
    ratio: aspect_ratio
  }
}
    `;
export const BillingArtworkPaymentIntentProductFragmentDoc = gql`
    fragment BillingArtworkPaymentIntentProduct on Billing_artwork_payment_intent_product {
  id
  artwork_id
  payment_intent_id
  status
  artwork_price_amount_units
  kaleido_fee_units
  rejection_reason
}
    `;
export const BillingArtworkPaymentIntentFragmentDoc = gql`
    fragment BillingArtworkPaymentIntent on Billing_artwork_payment_intent {
  id
  created_at
  updated_at
  buyer_context_id
  seller_context_id
  status
  payment_authorized_at
  currency_code
  amount_total_units
  amount_subtotal_units
  amount_capturable_units
  shipping_fee_estimate
  stripe_checkout_session_id
  stripe_payment_intent_id
  authorized_by_context_id
}
    `;
export const BillingArtworkPaymentIntentStatusHistoryFragmentDoc = gql`
    fragment BillingArtworkPaymentIntentStatusHistory on Billing_artwork_payment_intent_status_history {
  id
  created_at
  payment_intent_id
  status_change
  source
  admin_context {
    id
    profile {
      id
      email
      title
    }
  }
  admin_notes
}
    `;
export const BillingBuyerSellerContextFragmentDoc = gql`
    fragment BillingBuyerSellerContext on Context {
  id
  type
  profile {
    id
    title
    email
    handle
  }
}
    `;
export const FollowerFieldsFragmentDoc = gql`
    fragment FollowerFields on Social_graph_followers {
  id
  target_context_id
  context_id
  is_mutual
  state
  natural_order
  selected_order
  created_at
  updated_at
  Social_graph_followers_Context {
    profile {
      id
      avatar
      title
      first_name
      last_name
      handle
      is_public
      context {
        locations {
          city
          country
        }
      }
    }
  }
  Social_graph_followers_Target_Context {
    profile {
      id
      avatar
      title
      first_name
      last_name
      handle
      is_public
      context {
        locations {
          city
          country
        }
      }
    }
  }
}
    `;
export const ProfileCollectorEditionPrintFragmentDoc = gql`
    fragment ProfileCollectorEditionPrint on Print {
  id
  number: print_number
  editionByType: Edition_By_Type {
    editionBySize: Edition_By_Size {
      creationYear: print_date_year
    }
    editionType: Edition_Type {
      value
    }
  }
}
    `;
export const ManageCreatedArtworkFragmentDoc = gql`
    fragment manageCreatedArtwork on Artwork {
  createdCollection: artwork_to_collections(
    where: {Collection: {owner_context_id: {_eq: $contextId}, type: {_eq: CREATED}}}
  ) {
    is_public
    id
    Collection {
      type
      owner_context_id
    }
  }
}
    `;
export const ManageOwnedArtworkFragmentDoc = gql`
    fragment manageOwnedArtwork on Artwork {
  ownedCollection: artwork_to_collections(
    where: {Collection: {owner_context_id: {_eq: $contextId}, type: {_eq: OWNED}}}
  ) {
    is_public
    id
    Collection {
      type
      owner_context_id
    }
  }
}
    `;
export const ManageManagedArtworkFragmentDoc = gql`
    fragment manageManagedArtwork on Artwork {
  managedCollection: artwork_to_collections(
    where: {Collection: {owner_context_id: {_eq: $contextId}, type: {_eq: MANAGED}}}
  ) {
    is_public
    id
    Collection {
      type
      owner_context_id
    }
  }
}
    `;
export const ManageArtworkContentFragmentDoc = gql`
    fragment manageArtworkContent on Artwork {
  id
  slug
  thumbnail_metadata_id
  primary_image_metadata_id
  is_hard_minted
  is_transaction_locked
  published_date
  edition_type
  ...manageCreatedArtwork
  ...manageOwnedArtwork
  ...manageManagedArtwork
  transaction_histories(
    order_by: {status_at: desc}
    where: {print_id: {_is_null: true}}
    limit: 2
  ) {
    status
  }
  artwork_details {
    title
    creation_date_day
    creation_date_year
    creation_date_month
  }
  artist_context {
    profile {
      id
      avatar
      handle
      title
    }
  }
  Artwork_to_favorite_count {
    favorite_count
  }
}
    ${ManageCreatedArtworkFragmentDoc}
${ManageOwnedArtworkFragmentDoc}
${ManageManagedArtworkFragmentDoc}`;
export const ManageArtworkEditionFragmentDoc = gql`
    fragment manageArtworkEdition on Edition {
  id
  title
  is_public
  publish_date
  Artwork {
    ...manageArtworkContent
  }
}
    ${ManageArtworkContentFragmentDoc}`;
export const ManageArtworkPrintFragmentDoc = gql`
    fragment manageArtworkPrint on Print {
  ...ProfileCollectorEditionPrint
  edition: Edition {
    ...manageArtworkEdition
  }
  print_to_collections(where: {Collection: {owner_context_id: {_eq: $contextId}}}) {
    is_public
    id
  }
}
    ${ProfileCollectorEditionPrintFragmentDoc}
${ManageArtworkEditionFragmentDoc}`;
export const ManageArtworkContentViewFragmentDoc = gql`
    fragment manageArtworkContentView on Artwork {
  id
  slug
  thumbnail_metadata_id
  primary_image_metadata_id
  is_public
  is_hard_minted
  is_transaction_locked
  published_date
  edition_type
  transaction_histories(
    order_by: {status_at: desc}
    where: {print_id: {_is_null: true}}
    limit: 2
  ) {
    status
  }
  artwork_details {
    title
    creation_date_day
    creation_date_year
    creation_date_month
    artwork {
      Artwork_to_favorite_count {
        favorite_count
      }
    }
  }
  artist_context {
    profile {
      id
      avatar
      handle
      title
    }
  }
  collections: artwork_to_collections(
    where: {Collection: {owner_context_id: {_eq: $contextId}, type: {_in: [MANAGED, CREATED, OWNED]}}}
  ) {
    is_public
    id
    Collection {
      type
      owner_context_id
    }
  }
}
    `;
export const PrintAcceptedTransactionsFragmentDoc = gql`
    fragment PrintAcceptedTransactions on Print {
  acceptedTransactions: transaction_histories(
    where: {status: {_eq: ACCEPTED}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    buyer_context {
      profile {
        id
        avatar
        is_public
        title
        first_name
        last_name
        handle
      }
    }
  }
}
    `;
export const PrintDetailsFragmentDoc = gql`
    fragment PrintDetails on Print {
  id
  number: print_number
  price
  status
  editionByTypeId: edition_by_type_id
  ...PrintAcceptedTransactions
}
    ${PrintAcceptedTransactionsFragmentDoc}`;
export const CollectorProfileFragmentDoc = gql`
    fragment CollectorProfile on Profile {
  id
  avatar
  isPublic: is_public
  title
  firstName: first_name
  lastName: last_name
  handle
}
    `;
export const ArtworkCommercialDetailsFragmentDoc = gql`
    fragment ArtworkCommercialDetails on Artwork {
  gallery_context_id
  artwork_details {
    title
  }
  Artwork_prices(
    where: {scope: {_eq: SHOWCASE}}
    order_by: {updated_at: desc}
    limit: 1
  ) {
    currency
    price
    is_public
    id
    scope
  }
  is_available_sale
}
    `;
export const ArtworkDataForCongratsPageFragmentDoc = gql`
    fragment ArtworkDataForCongratsPage on Artwork {
  id
  primary_image_metadata_id
  slug
  vuforia_rating
  artwork_details {
    title
    art_type
    creation_date_year
    creation_date_month
    creation_date_day
  }
  artist_context {
    profile {
      handle
    }
  }
  artwork_to_collections(where: {Collection: {type: {_eq: CREATED}}}) {
    is_public
  }
}
    `;
export const ArtworkTagsFragmentDoc = gql`
    fragment ArtworkTags on Artwork {
  Artwork_ai_tags {
    id
    user_selected_image_tags
  }
  primary_tag: artwork_to_tags(where: {type: {_eq: PRIMARY}}) {
    id
    tag {
      id
      name
      value
      description
      family
      genome_id
      can_be_primary
    }
  }
  tags: artwork_to_tags(where: {type: {_eq: SECONDARY}}) {
    id
    tag {
      id
      name
      value
      description
      family
      genome_id
      can_be_primary
    }
  }
}
    `;
export const ArtworkImageryFragmentDoc = gql`
    fragment ArtworkImagery on Artwork {
  id
  edition_type
  original_image_metadata_id
  primary_image_metadata_id
  thumbnail_metadata_id
  artwork_secondary_media(order_by: {order: asc}) {
    id
    order
    file_metadata_id
    edition_id
  }
  artwork_story {
    artwork_story_media(order_by: {order: asc}) {
      id
      order
      file_metadata_id
      artwork_story_media_download_link
    }
  }
  artwork_details {
    is_dark_mode
  }
  original_image_metadata {
    id
    file_extension
  }
  crop_area {
    id
    x
    y
    height
    width
    unit
    zoom
  }
}
    `;
export const ArtworkReviewDataFragmentDoc = gql`
    fragment ArtworkReviewData on Artwork {
  id
  is_available_sale
  vuforia_rating
  primary_image_metadata_id
  thumbnail_metadata_id
  edition_type
  artwork_details {
    art_type
    depth
    duration
    height
    is_dark_mode
    materials_and_medium
    measuring_unit
    title
    width
    creation_date_year
    creation_date_month
    creation_date_day
    discipline
    discipline_other
  }
  artwork_secondary_media(order_by: {order: asc}) {
    id
    order
    file_metadata_id
  }
  artwork_story {
    artwork_story_media(order_by: {order: asc}) {
      id
      order
      file_metadata_id
    }
    id
    statement
    story_audio_download_link
    audio_metadata {
      extra
    }
    artwork_story_video_links {
      id
      platform
      URL
    }
  }
  artwork_to_collections(where: {Collection: {type: {_eq: SERIES}}}) {
    Collection {
      name
      description
      collection_audio_download_link
      collection_video_links {
        id
        URL
        platform
      }
    }
  }
  Artwork_prices(where: {trashed_at: {_is_null: true}, scope: {_eq: SHOWCASE}}) {
    currency
    is_public
    price
  }
  artwork_to_tags(where: {deleted_at: {_is_null: true}}) {
    type
    tag {
      name
    }
  }
}
    `;
export const EditionBySizeDetailsFragmentDoc = gql`
    fragment EditionBySizeDetails on Edition_By_Size {
  id
  size: print_size
  width
  height
  measuringUnit: measuring_unit
  technique
  material: materials
}
    `;
export const EditionByTypeDetailsFragmentDoc = gql`
    fragment EditionByTypeDetails on Edition_By_Type {
  id
  printAmount: print_amount
  creationDate: created_at
  editionType: Edition_Type {
    name: value
  }
  Edition_By_Size {
    ...EditionBySizeDetails
  }
}
    ${EditionBySizeDetailsFragmentDoc}`;
export const ArtworkFieldsFragmentDoc = gql`
    fragment artworkFields on Artwork {
  id
  slug
  is_public
  is_available_sale
  primary_image_metadata_id
  thumbnail_metadata_id
  Artwork_prices(where: {scope: {_eq: SHOWCASE}}) {
    id
    price
    currency
    is_public
  }
  artwork_details {
    title
    creation_date_day
    creation_date_month
    creation_date_year
    width
    height
  }
  artist_context {
    id
    profile {
      title
      handle
      first_name
      last_name
    }
  }
}
    `;
export const PlacesFragmentDoc = gql`
    fragment Places on PlacesSearchResult {
  places {
    id
    name
    website
    description
    email
    tel
    popularity
    rating
    categories {
      id
      name
    }
    geoCoordinates {
      latitude
      longitude
    }
    hours {
      display
      is_local_holiday
      open_now
      regular {
        day
        close
        open
      }
    }
    location {
      address
      locality
      region
      postcode
      country
    }
    photos {
      id
      url
      width
      height
      classifications
    }
  }
  context {
    centerPointLat
    centerPointLng
    radiusMeters
  }
}
    `;
export const ExploreArtistCommonFilterFieldsFragmentDoc = gql`
    fragment ExploreArtistCommonFilterFields on Explore_artists {
  artist_title
  artworks_count
  region
  context_id
  artworks: exploreArtworks(limit: 3, order_by: {id: asc}) {
    id
    slug
    is_public
    primary_image_metadata_id
    thumbnail_metadata_id
    title
  }
  cardGridArtworks(where: {rank: {_lte: 3}}) {
    id
    exploreArtwork {
      id
      slug
      is_public
      primary_image_metadata_id
      thumbnail_metadata_id
      title
    }
  }
  context {
    profile {
      id
      avatar
      title
      first_name
      last_name
      handle
      created_at
    }
    locations {
      country
      city
      name
      region
    }
  }
}
    `;
export const FollowerFieldsWithArtworksFragmentDoc = gql`
    fragment FollowerFieldsWithArtworks on Social_graph_followers {
  id
  target_context_id
  context_id
  is_mutual
  state
  natural_order
  selected_order
  created_at
  updated_at
  Social_graph_followers_Target_Context {
    profile {
      id
      avatar
      title
      handle
      context {
        locations {
          city
          country
        }
      }
    }
    Artworks(limit: 3, where: {is_public: {_eq: true}}) {
      id
      slug
      thumbnail_metadata_id
      thumbnail_metadata {
        file_name
        file_extension
        status
        storage_path
        storage_service
      }
    }
  }
}
    `;
export const CommonProfileMuseumArtworkListingCursorFragmentDoc = gql`
    fragment CommonProfileMuseumArtworkListingCursor on Museum_artwork_listing {
  artworkId: artwork_id
  artistContextId: artist_context_id
  isCollected: is_collected
  aspectRatio: aspect_ratio
}
    `;
export const ExhibitionDetailsFragmentDoc = gql`
    fragment ExhibitionDetails on Announced_art_show {
  uri
  organizerHandle: organizer_handle
  addressOne: address_line_1
  addressTwo: address_line_2
  collectionId: art_show_collection_id
  bannerMetaId: banner_metadata_id
  organizerId: organized_by_context_id
  endAt: end_at
  startAt: start_at
  grandOpeningAt: grand_opening_at
  openingSoonAt: opening_soon_at
  closingSoonAt: closing_soon_at
  isAnnounced: is_announced
  statement
  title
  organizer {
    profile {
      id
      avatar
      title
    }
  }
  zipCode: zip_code
  worldCity: world_city {
    displayName: display_name
    state: state_code
    country: country_name
    lat
    lng
  }
}
    `;
export const CommonArtworkShopListingCursorFragmentDoc = gql`
    fragment CommonArtworkShopListingCursor on artwork_shop_listing {
  artworkId: artwork_id
  artistContextId: artist_context_id
  aspect: aspect_ratio {
    ratio: aspect_ratio
  }
}
    `;
export const ArtworkShowcaseEditionFragmentDoc = gql`
    fragment ArtworkShowcaseEdition on Edition {
  id
  price
  currency
  isForSale: is_available_sale
  isPricePublic: is_price_public
  Edition_By_Sizes(where: {status: {_eq: COMPLETE}}) {
    id
    print_size
    width
    height
    measuring_unit
    technique
    materials
    price
    currency
    isPricePublic: is_price_public
    year: print_date_year
    month: print_date_month
    day: print_date_day
    Edition_By_Types(
      where: {status: {_eq: COMPLETE}, Edition_Type: {deleted_at: {_is_null: true}}}
    ) {
      id
      print_amount
      Edition_Type {
        value
      }
    }
  }
}
    `;
export const GetProfileForAdminEditDocument = gql`
    query getProfileForAdminEdit($id: bigint!) {
  Context_by_pk(id: $id) {
    type
    profile_social_links {
      platform
      URL
      custom_link
      id
    }
    profile {
      id
      title
      handle
      avatar
      banner
      kard_banner
      featured_art
      statement
      quote
      first_name
      last_name
      email
      status
    }
    locations {
      name
      address
      city
      id
      country
      zip_code
    }
  }
}
    `;

/**
 * __useGetProfileForAdminEditQuery__
 *
 * To run a query within a React component, call `useGetProfileForAdminEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileForAdminEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileForAdminEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileForAdminEditQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileForAdminEditQuery, Types.GetProfileForAdminEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileForAdminEditQuery, Types.GetProfileForAdminEditQueryVariables>(GetProfileForAdminEditDocument, options);
      }
export function useGetProfileForAdminEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileForAdminEditQuery, Types.GetProfileForAdminEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileForAdminEditQuery, Types.GetProfileForAdminEditQueryVariables>(GetProfileForAdminEditDocument, options);
        }
export type GetProfileForAdminEditQueryHookResult = ReturnType<typeof useGetProfileForAdminEditQuery>;
export type GetProfileForAdminEditLazyQueryHookResult = ReturnType<typeof useGetProfileForAdminEditLazyQuery>;
export type GetProfileForAdminEditQueryResult = Apollo.QueryResult<Types.GetProfileForAdminEditQuery, Types.GetProfileForAdminEditQueryVariables>;
export const AddProfileAchievementDocument = gql`
    mutation addProfileAchievement($amount: Int!, $context_id: Int!, $profile_id: Int!) {
  instantGratification(
    amount: $amount
    profileContextID: $context_id
    updateNow: true
    userID: $profile_id
  )
}
    `;
export type AddProfileAchievementMutationFn = Apollo.MutationFunction<Types.AddProfileAchievementMutation, Types.AddProfileAchievementMutationVariables>;

/**
 * __useAddProfileAchievementMutation__
 *
 * To run a mutation, you first call `useAddProfileAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfileAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfileAchievementMutation, { data, loading, error }] = useAddProfileAchievementMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      context_id: // value for 'context_id'
 *      profile_id: // value for 'profile_id'
 *   },
 * });
 */
export function useAddProfileAchievementMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddProfileAchievementMutation, Types.AddProfileAchievementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddProfileAchievementMutation, Types.AddProfileAchievementMutationVariables>(AddProfileAchievementDocument, options);
      }
export type AddProfileAchievementMutationHookResult = ReturnType<typeof useAddProfileAchievementMutation>;
export type AddProfileAchievementMutationResult = Apollo.MutationResult<Types.AddProfileAchievementMutation>;
export type AddProfileAchievementMutationOptions = Apollo.BaseMutationOptions<Types.AddProfileAchievementMutation, Types.AddProfileAchievementMutationVariables>;
export const GetProfileCoinsAndIdDocument = gql`
    query GetProfileCoinsAndId($contextId: bigint!) {
  Profile(where: {context: {id: {_eq: $contextId}}}) {
    total_points
    id
    title
  }
}
    `;

/**
 * __useGetProfileCoinsAndIdQuery__
 *
 * To run a query within a React component, call `useGetProfileCoinsAndIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCoinsAndIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCoinsAndIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileCoinsAndIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileCoinsAndIdQuery, Types.GetProfileCoinsAndIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileCoinsAndIdQuery, Types.GetProfileCoinsAndIdQueryVariables>(GetProfileCoinsAndIdDocument, options);
      }
export function useGetProfileCoinsAndIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileCoinsAndIdQuery, Types.GetProfileCoinsAndIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileCoinsAndIdQuery, Types.GetProfileCoinsAndIdQueryVariables>(GetProfileCoinsAndIdDocument, options);
        }
export type GetProfileCoinsAndIdQueryHookResult = ReturnType<typeof useGetProfileCoinsAndIdQuery>;
export type GetProfileCoinsAndIdLazyQueryHookResult = ReturnType<typeof useGetProfileCoinsAndIdLazyQuery>;
export type GetProfileCoinsAndIdQueryResult = Apollo.QueryResult<Types.GetProfileCoinsAndIdQuery, Types.GetProfileCoinsAndIdQueryVariables>;
export const GetUserRolesDocument = gql`
    query getUserRoles($contextId: bigint!) {
  Flat_context_access(where: {receiver_id: {_eq: $contextId}}) {
    granter {
      type
    }
  }
}
    `;

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetUserRolesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserRolesQuery, Types.GetUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserRolesQuery, Types.GetUserRolesQueryVariables>(GetUserRolesDocument, options);
      }
export function useGetUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserRolesQuery, Types.GetUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserRolesQuery, Types.GetUserRolesQueryVariables>(GetUserRolesDocument, options);
        }
export type GetUserRolesQueryHookResult = ReturnType<typeof useGetUserRolesQuery>;
export type GetUserRolesLazyQueryHookResult = ReturnType<typeof useGetUserRolesLazyQuery>;
export type GetUserRolesQueryResult = Apollo.QueryResult<Types.GetUserRolesQuery, Types.GetUserRolesQueryVariables>;
export const GetPubNubChannelsDocument = gql`
    query getPubNubChannels($contextId: bigint!) {
  getPubNubChannels(contextId: $contextId) {
    id
    name
    description
    eTag
    type
    status
    updated
    custom {
      firstUserName
      firstProfileUrl
      firstUserContextId
      secondUserName
      secondProfileUrl
      secondUserContextId
      lastMessage
      lastMessageSenderContextId
      lastMessageUpdatedAt
      lastReadTimetoken
    }
  }
}
    `;

/**
 * __useGetPubNubChannelsQuery__
 *
 * To run a query within a React component, call `useGetPubNubChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPubNubChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPubNubChannelsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetPubNubChannelsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPubNubChannelsQuery, Types.GetPubNubChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPubNubChannelsQuery, Types.GetPubNubChannelsQueryVariables>(GetPubNubChannelsDocument, options);
      }
export function useGetPubNubChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPubNubChannelsQuery, Types.GetPubNubChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPubNubChannelsQuery, Types.GetPubNubChannelsQueryVariables>(GetPubNubChannelsDocument, options);
        }
export type GetPubNubChannelsQueryHookResult = ReturnType<typeof useGetPubNubChannelsQuery>;
export type GetPubNubChannelsLazyQueryHookResult = ReturnType<typeof useGetPubNubChannelsLazyQuery>;
export type GetPubNubChannelsQueryResult = Apollo.QueryResult<Types.GetPubNubChannelsQuery, Types.GetPubNubChannelsQueryVariables>;
export const UpdatePubNubChannelLastMessageDocument = gql`
    mutation updatePubNubChannelLastMessage($message: String!, $receiverContextId: bigint!, $senderContextId: bigint!) {
  updatePubNubChannelLastMessage(
    message: $message
    receiverContextId: $receiverContextId
    senderContextId: $senderContextId
  )
}
    `;
export type UpdatePubNubChannelLastMessageMutationFn = Apollo.MutationFunction<Types.UpdatePubNubChannelLastMessageMutation, Types.UpdatePubNubChannelLastMessageMutationVariables>;

/**
 * __useUpdatePubNubChannelLastMessageMutation__
 *
 * To run a mutation, you first call `useUpdatePubNubChannelLastMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePubNubChannelLastMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePubNubChannelLastMessageMutation, { data, loading, error }] = useUpdatePubNubChannelLastMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *      receiverContextId: // value for 'receiverContextId'
 *      senderContextId: // value for 'senderContextId'
 *   },
 * });
 */
export function useUpdatePubNubChannelLastMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePubNubChannelLastMessageMutation, Types.UpdatePubNubChannelLastMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePubNubChannelLastMessageMutation, Types.UpdatePubNubChannelLastMessageMutationVariables>(UpdatePubNubChannelLastMessageDocument, options);
      }
export type UpdatePubNubChannelLastMessageMutationHookResult = ReturnType<typeof useUpdatePubNubChannelLastMessageMutation>;
export type UpdatePubNubChannelLastMessageMutationResult = Apollo.MutationResult<Types.UpdatePubNubChannelLastMessageMutation>;
export type UpdatePubNubChannelLastMessageMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePubNubChannelLastMessageMutation, Types.UpdatePubNubChannelLastMessageMutationVariables>;
export const UpdatePubNubChannelLastReadDocument = gql`
    mutation updatePubNubChannelLastRead($channelId: String!, $lastReadTimetoken: String!) {
  updatePubNubChannelLastRead(
    channelId: $channelId
    lastReadTimetoken: $lastReadTimetoken
  )
}
    `;
export type UpdatePubNubChannelLastReadMutationFn = Apollo.MutationFunction<Types.UpdatePubNubChannelLastReadMutation, Types.UpdatePubNubChannelLastReadMutationVariables>;

/**
 * __useUpdatePubNubChannelLastReadMutation__
 *
 * To run a mutation, you first call `useUpdatePubNubChannelLastReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePubNubChannelLastReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePubNubChannelLastReadMutation, { data, loading, error }] = useUpdatePubNubChannelLastReadMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      lastReadTimetoken: // value for 'lastReadTimetoken'
 *   },
 * });
 */
export function useUpdatePubNubChannelLastReadMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePubNubChannelLastReadMutation, Types.UpdatePubNubChannelLastReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePubNubChannelLastReadMutation, Types.UpdatePubNubChannelLastReadMutationVariables>(UpdatePubNubChannelLastReadDocument, options);
      }
export type UpdatePubNubChannelLastReadMutationHookResult = ReturnType<typeof useUpdatePubNubChannelLastReadMutation>;
export type UpdatePubNubChannelLastReadMutationResult = Apollo.MutationResult<Types.UpdatePubNubChannelLastReadMutation>;
export type UpdatePubNubChannelLastReadMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePubNubChannelLastReadMutation, Types.UpdatePubNubChannelLastReadMutationVariables>;
export const AnalyzeMessageContentDocument = gql`
    query analyzeMessageContent($contextId: Int!, $message: String!) {
  analyzeMessageContentQuery(contextId: $contextId, message: $message) {
    isScammer
    infractionInfo {
      id
      contextId: context_id
      counter: infractions_counter
    }
  }
}
    `;

/**
 * __useAnalyzeMessageContentQuery__
 *
 * To run a query within a React component, call `useAnalyzeMessageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyzeMessageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyzeMessageContentQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAnalyzeMessageContentQuery(baseOptions: Apollo.QueryHookOptions<Types.AnalyzeMessageContentQuery, Types.AnalyzeMessageContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AnalyzeMessageContentQuery, Types.AnalyzeMessageContentQueryVariables>(AnalyzeMessageContentDocument, options);
      }
export function useAnalyzeMessageContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AnalyzeMessageContentQuery, Types.AnalyzeMessageContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AnalyzeMessageContentQuery, Types.AnalyzeMessageContentQueryVariables>(AnalyzeMessageContentDocument, options);
        }
export type AnalyzeMessageContentQueryHookResult = ReturnType<typeof useAnalyzeMessageContentQuery>;
export type AnalyzeMessageContentLazyQueryHookResult = ReturnType<typeof useAnalyzeMessageContentLazyQuery>;
export type AnalyzeMessageContentQueryResult = Apollo.QueryResult<Types.AnalyzeMessageContentQuery, Types.AnalyzeMessageContentQueryVariables>;
export const GetArtworkDetailsForSwipeContainerDocument = gql`
    query getArtworkDetailsForSwipeContainer($artworkId: bigint!, $userContextId: bigint! = 0) {
  Artwork(
    where: {id: {_eq: $artworkId}, edition_type: {_neq: STANDALONE_EDITION}}
  ) {
    slug
    inquires: purchase_inquires(where: {context_sender_id: {_eq: $userContextId}}) {
      id
      inquiredAt: created_at
    }
    artworkDetails: artwork_details {
      width
      height
      title
      artType: art_type
      year: creation_date_year
      discipline: ENUM_artwork_discipline {
        description
      }
      depth
      measuringUnit: measuring_unit
      medium: materials_and_medium
    }
    isAvailable: is_available_sale
    prices: Artwork_prices(where: {price: {_is_null: false}}) {
      currency
      price
      isPublic: is_public
    }
    provenance: transaction_histories(limit: 1) {
      artwork_id
    }
    ar: vuforia_rating
    vuforiaTargetId: target_id
    pendingVuforiaImagePath: pending_vuforia_image_path
    id
    artistContextId: artist_context_id
    artist: artist_context {
      profile {
        handle
      }
    }
    series: artwork_to_collections(where: {Collection: {type: {_eq: SERIES}}}) {
      collection: Collection {
        name
      }
    }
    printAmounts: Print_amounts {
      available_print_amount
    }
  }
  Edition: Artwork(
    where: {id: {_eq: $artworkId}, edition_type: {_eq: STANDALONE_EDITION}}
  ) {
    id
    slug
    inquires: purchase_inquires(where: {context_sender_id: {_eq: $userContextId}}) {
      id
      inquiredAt: created_at
    }
    Editions {
      sizes: Edition_By_Sizes {
        byTypes: Edition_By_Types {
          id
          status
          print_amount
          unavailable_print_amount
          type: Edition_Type {
            id
            value
          }
        }
        id
        currency
        height
        width
        depth
        measuringUnit: measuring_unit
        year: print_date_year
        medium: materials
        technique
        price
        isPublic: is_price_public
      }
      id
      price
      isPublic: is_price_public
      title
      isAvailable: is_available_sale
    }
    artworkDetails: artwork_details {
      id
      title
    }
    ar: vuforia_rating
    vuforiaTargetId: target_id
    pendingVuforiaImagePath: pending_vuforia_image_path
    artistContextId: artist_context_id
    artist: artist_context {
      profile {
        handle
      }
    }
    series: artwork_to_collections(where: {Collection: {type: {_eq: SERIES}}}) {
      collection: Collection {
        name
      }
    }
    printAmounts: Print_amounts {
      available_print_amount
    }
  }
}
    `;

/**
 * __useGetArtworkDetailsForSwipeContainerQuery__
 *
 * To run a query within a React component, call `useGetArtworkDetailsForSwipeContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkDetailsForSwipeContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkDetailsForSwipeContainerQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *      userContextId: // value for 'userContextId'
 *   },
 * });
 */
export function useGetArtworkDetailsForSwipeContainerQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkDetailsForSwipeContainerQuery, Types.GetArtworkDetailsForSwipeContainerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkDetailsForSwipeContainerQuery, Types.GetArtworkDetailsForSwipeContainerQueryVariables>(GetArtworkDetailsForSwipeContainerDocument, options);
      }
export function useGetArtworkDetailsForSwipeContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkDetailsForSwipeContainerQuery, Types.GetArtworkDetailsForSwipeContainerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkDetailsForSwipeContainerQuery, Types.GetArtworkDetailsForSwipeContainerQueryVariables>(GetArtworkDetailsForSwipeContainerDocument, options);
        }
export type GetArtworkDetailsForSwipeContainerQueryHookResult = ReturnType<typeof useGetArtworkDetailsForSwipeContainerQuery>;
export type GetArtworkDetailsForSwipeContainerLazyQueryHookResult = ReturnType<typeof useGetArtworkDetailsForSwipeContainerLazyQuery>;
export type GetArtworkDetailsForSwipeContainerQueryResult = Apollo.QueryResult<Types.GetArtworkDetailsForSwipeContainerQuery, Types.GetArtworkDetailsForSwipeContainerQueryVariables>;
export const ExpireActiveCheckoutSessionsForBuyerDocument = gql`
    mutation ExpireActiveCheckoutSessionsForBuyer($buyerContextId: bigint!) {
  expireCheckoutActiveCheckoutSessionsForBuyer(buyerContextId: $buyerContextId)
}
    `;
export type ExpireActiveCheckoutSessionsForBuyerMutationFn = Apollo.MutationFunction<Types.ExpireActiveCheckoutSessionsForBuyerMutation, Types.ExpireActiveCheckoutSessionsForBuyerMutationVariables>;

/**
 * __useExpireActiveCheckoutSessionsForBuyerMutation__
 *
 * To run a mutation, you first call `useExpireActiveCheckoutSessionsForBuyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpireActiveCheckoutSessionsForBuyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expireActiveCheckoutSessionsForBuyerMutation, { data, loading, error }] = useExpireActiveCheckoutSessionsForBuyerMutation({
 *   variables: {
 *      buyerContextId: // value for 'buyerContextId'
 *   },
 * });
 */
export function useExpireActiveCheckoutSessionsForBuyerMutation(baseOptions?: Apollo.MutationHookOptions<Types.ExpireActiveCheckoutSessionsForBuyerMutation, Types.ExpireActiveCheckoutSessionsForBuyerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ExpireActiveCheckoutSessionsForBuyerMutation, Types.ExpireActiveCheckoutSessionsForBuyerMutationVariables>(ExpireActiveCheckoutSessionsForBuyerDocument, options);
      }
export type ExpireActiveCheckoutSessionsForBuyerMutationHookResult = ReturnType<typeof useExpireActiveCheckoutSessionsForBuyerMutation>;
export type ExpireActiveCheckoutSessionsForBuyerMutationResult = Apollo.MutationResult<Types.ExpireActiveCheckoutSessionsForBuyerMutation>;
export type ExpireActiveCheckoutSessionsForBuyerMutationOptions = Apollo.BaseMutationOptions<Types.ExpireActiveCheckoutSessionsForBuyerMutation, Types.ExpireActiveCheckoutSessionsForBuyerMutationVariables>;
export const GetBuyModalDetailsDocument = gql`
    query GetBuyModalDetails($artworkId: bigint!) {
  Artwork(where: {id: {_eq: $artworkId}, is_public: {_eq: true}}) {
    details: artwork_details {
      creationYear: creation_date_year
      height
      width
      depth
      measuringUnit: measuring_unit
      materials: materials_and_medium
      discipline
      title
    }
    artist: artist_context {
      profile {
        avatar
        id
        title
        handle
      }
      locations {
        city
        country
      }
    }
    prices: Artwork_prices(where: {scope: {_eq: SHOWCASE}}) {
      currency
      price
      isPublic: is_public
    }
    artistContextId: artist_context_id
    artworkId: id
    primaryMetadataId: primary_image_metadata_id
  }
  shippingPrice: getArtworkEstimatedShippingPrice(artworkId: $artworkId)
}
    `;

/**
 * __useGetBuyModalDetailsQuery__
 *
 * To run a query within a React component, call `useGetBuyModalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyModalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyModalDetailsQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetBuyModalDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBuyModalDetailsQuery, Types.GetBuyModalDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBuyModalDetailsQuery, Types.GetBuyModalDetailsQueryVariables>(GetBuyModalDetailsDocument, options);
      }
export function useGetBuyModalDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBuyModalDetailsQuery, Types.GetBuyModalDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBuyModalDetailsQuery, Types.GetBuyModalDetailsQueryVariables>(GetBuyModalDetailsDocument, options);
        }
export type GetBuyModalDetailsQueryHookResult = ReturnType<typeof useGetBuyModalDetailsQuery>;
export type GetBuyModalDetailsLazyQueryHookResult = ReturnType<typeof useGetBuyModalDetailsLazyQuery>;
export type GetBuyModalDetailsQueryResult = Apollo.QueryResult<Types.GetBuyModalDetailsQuery, Types.GetBuyModalDetailsQueryVariables>;
export const GetArtistProfileAudiosDocument = gql`
    query getArtistProfileAudios($artistHandle: String = "", $artworkSlug: String = "") {
  Profile(where: {handle: {_ilike: $artistHandle}}) {
    title
    first_name
    last_name
    audio_download_link
    audio_metadata {
      extra
    }
    Valid_profile_statement_audio_syntheses {
      audio_id
      profile_id
      language
    }
  }
  Artwork(where: {slug: {_eq: $artworkSlug}}) {
    artwork_details {
      title
    }
    artwork_story {
      story_audio_download_link
      audio_metadata {
        extra
      }
    }
    artwork_to_collections(where: {Collection: {type: {_eq: SERIES}}}) {
      Collection {
        name
        audio_metadata {
          extra
        }
      }
    }
  }
}
    `;

/**
 * __useGetArtistProfileAudiosQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileAudiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileAudiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileAudiosQuery({
 *   variables: {
 *      artistHandle: // value for 'artistHandle'
 *      artworkSlug: // value for 'artworkSlug'
 *   },
 * });
 */
export function useGetArtistProfileAudiosQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtistProfileAudiosQuery, Types.GetArtistProfileAudiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileAudiosQuery, Types.GetArtistProfileAudiosQueryVariables>(GetArtistProfileAudiosDocument, options);
      }
export function useGetArtistProfileAudiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileAudiosQuery, Types.GetArtistProfileAudiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileAudiosQuery, Types.GetArtistProfileAudiosQueryVariables>(GetArtistProfileAudiosDocument, options);
        }
export type GetArtistProfileAudiosQueryHookResult = ReturnType<typeof useGetArtistProfileAudiosQuery>;
export type GetArtistProfileAudiosLazyQueryHookResult = ReturnType<typeof useGetArtistProfileAudiosLazyQuery>;
export type GetArtistProfileAudiosQueryResult = Apollo.QueryResult<Types.GetArtistProfileAudiosQuery, Types.GetArtistProfileAudiosQueryVariables>;
export const GetLanguagePreferanceOptionsDocument = gql`
    query getLanguagePreferanceOptions {
  ENUM_language_preference {
    value
    description
  }
}
    `;

/**
 * __useGetLanguagePreferanceOptionsQuery__
 *
 * To run a query within a React component, call `useGetLanguagePreferanceOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagePreferanceOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagePreferanceOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagePreferanceOptionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLanguagePreferanceOptionsQuery, Types.GetLanguagePreferanceOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLanguagePreferanceOptionsQuery, Types.GetLanguagePreferanceOptionsQueryVariables>(GetLanguagePreferanceOptionsDocument, options);
      }
export function useGetLanguagePreferanceOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLanguagePreferanceOptionsQuery, Types.GetLanguagePreferanceOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLanguagePreferanceOptionsQuery, Types.GetLanguagePreferanceOptionsQueryVariables>(GetLanguagePreferanceOptionsDocument, options);
        }
export type GetLanguagePreferanceOptionsQueryHookResult = ReturnType<typeof useGetLanguagePreferanceOptionsQuery>;
export type GetLanguagePreferanceOptionsLazyQueryHookResult = ReturnType<typeof useGetLanguagePreferanceOptionsLazyQuery>;
export type GetLanguagePreferanceOptionsQueryResult = Apollo.QueryResult<Types.GetLanguagePreferanceOptionsQuery, Types.GetLanguagePreferanceOptionsQueryVariables>;
export const GetProfileCreatedAtByPkDocument = gql`
    query GetProfileCreatedAtByPK($profileId: bigint!) {
  Profile_by_pk(id: $profileId) {
    created_at
  }
}
    `;

/**
 * __useGetProfileCreatedAtByPkQuery__
 *
 * To run a query within a React component, call `useGetProfileCreatedAtByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCreatedAtByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCreatedAtByPkQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetProfileCreatedAtByPkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileCreatedAtByPkQuery, Types.GetProfileCreatedAtByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileCreatedAtByPkQuery, Types.GetProfileCreatedAtByPkQueryVariables>(GetProfileCreatedAtByPkDocument, options);
      }
export function useGetProfileCreatedAtByPkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileCreatedAtByPkQuery, Types.GetProfileCreatedAtByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileCreatedAtByPkQuery, Types.GetProfileCreatedAtByPkQueryVariables>(GetProfileCreatedAtByPkDocument, options);
        }
export type GetProfileCreatedAtByPkQueryHookResult = ReturnType<typeof useGetProfileCreatedAtByPkQuery>;
export type GetProfileCreatedAtByPkLazyQueryHookResult = ReturnType<typeof useGetProfileCreatedAtByPkLazyQuery>;
export type GetProfileCreatedAtByPkQueryResult = Apollo.QueryResult<Types.GetProfileCreatedAtByPkQuery, Types.GetProfileCreatedAtByPkQueryVariables>;
export const SubscribeToNewsletterDocument = gql`
    mutation subscribeToNewsletter($artist_context_id: bigint!, $first_name: String!, $last_name: String!, $email: String!, $language: ENUM_language_preference_enum!) {
  insert_Artist_newsletter_one(
    object: {artist_context_id: $artist_context_id, first_name: $first_name, last_name: $last_name, email: $email, preferred_language: $language}
    on_conflict: {constraint: Artist_newsletter_artist_context_id_email_key, update_columns: [first_name]}
  ) {
    first_name
  }
}
    `;
export type SubscribeToNewsletterMutationFn = Apollo.MutationFunction<Types.SubscribeToNewsletterMutation, Types.SubscribeToNewsletterMutationVariables>;

/**
 * __useSubscribeToNewsletterMutation__
 *
 * To run a mutation, you first call `useSubscribeToNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToNewsletterMutation, { data, loading, error }] = useSubscribeToNewsletterMutation({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubscribeToNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubscribeToNewsletterMutation, Types.SubscribeToNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubscribeToNewsletterMutation, Types.SubscribeToNewsletterMutationVariables>(SubscribeToNewsletterDocument, options);
      }
export type SubscribeToNewsletterMutationHookResult = ReturnType<typeof useSubscribeToNewsletterMutation>;
export type SubscribeToNewsletterMutationResult = Apollo.MutationResult<Types.SubscribeToNewsletterMutation>;
export type SubscribeToNewsletterMutationOptions = Apollo.BaseMutationOptions<Types.SubscribeToNewsletterMutation, Types.SubscribeToNewsletterMutationVariables>;
export const UpsertSubscribeToNewsletterDocument = gql`
    mutation upsertSubscribeToNewsletter($artist_context_id: bigint!, $first_name: String!, $last_name: String!, $email: String!, $language: String!) {
  upsertArtistNewsletter(
    artistContextId: $artist_context_id
    first_name: $first_name
    last_name: $last_name
    email: $email
    language: $language
  )
}
    `;
export type UpsertSubscribeToNewsletterMutationFn = Apollo.MutationFunction<Types.UpsertSubscribeToNewsletterMutation, Types.UpsertSubscribeToNewsletterMutationVariables>;

/**
 * __useUpsertSubscribeToNewsletterMutation__
 *
 * To run a mutation, you first call `useUpsertSubscribeToNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSubscribeToNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSubscribeToNewsletterMutation, { data, loading, error }] = useUpsertSubscribeToNewsletterMutation({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpsertSubscribeToNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertSubscribeToNewsletterMutation, Types.UpsertSubscribeToNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertSubscribeToNewsletterMutation, Types.UpsertSubscribeToNewsletterMutationVariables>(UpsertSubscribeToNewsletterDocument, options);
      }
export type UpsertSubscribeToNewsletterMutationHookResult = ReturnType<typeof useUpsertSubscribeToNewsletterMutation>;
export type UpsertSubscribeToNewsletterMutationResult = Apollo.MutationResult<Types.UpsertSubscribeToNewsletterMutation>;
export type UpsertSubscribeToNewsletterMutationOptions = Apollo.BaseMutationOptions<Types.UpsertSubscribeToNewsletterMutation, Types.UpsertSubscribeToNewsletterMutationVariables>;
export const CheckoutMyStudioDocument = gql`
    mutation CheckoutMyStudio($artistContextId: bigint!, $domain: CheckoutSuperSiteDomainInput, $subscriptionType: SubscriptionType!, $period: SubscriptionPeriod!, $redirectUrl: String!, $discountCode: DiscountCodeInput) {
  checkoutMyStudio(
    artistContextId: $artistContextId
    domain: $domain
    subscriptionType: $subscriptionType
    period: $period
    redirectUrl: $redirectUrl
    discountCode: $discountCode
  ) {
    checkoutUrl
  }
}
    `;
export type CheckoutMyStudioMutationFn = Apollo.MutationFunction<Types.CheckoutMyStudioMutation, Types.CheckoutMyStudioMutationVariables>;

/**
 * __useCheckoutMyStudioMutation__
 *
 * To run a mutation, you first call `useCheckoutMyStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutMyStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutMyStudioMutation, { data, loading, error }] = useCheckoutMyStudioMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      domain: // value for 'domain'
 *      subscriptionType: // value for 'subscriptionType'
 *      period: // value for 'period'
 *      redirectUrl: // value for 'redirectUrl'
 *      discountCode: // value for 'discountCode'
 *   },
 * });
 */
export function useCheckoutMyStudioMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutMyStudioMutation, Types.CheckoutMyStudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutMyStudioMutation, Types.CheckoutMyStudioMutationVariables>(CheckoutMyStudioDocument, options);
      }
export type CheckoutMyStudioMutationHookResult = ReturnType<typeof useCheckoutMyStudioMutation>;
export type CheckoutMyStudioMutationResult = Apollo.MutationResult<Types.CheckoutMyStudioMutation>;
export type CheckoutMyStudioMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutMyStudioMutation, Types.CheckoutMyStudioMutationVariables>;
export const GetUserTrapArtworkInformationDocument = gql`
    query GetUserTrapArtworkInformation($artworkId: bigint!) {
  Artwork_by_pk(id: $artworkId) {
    id
    slug
    thumbnail_metadata_id
    artwork_details {
      creation_date_year
      depth
      height
      measuring_unit
      discipline_other
      discipline
      title
      width
      ENUM_artwork_discipline {
        description
      }
    }
    artist_context {
      profile {
        title
        handle
      }
    }
    Artwork_prices(where: {id: {}, scope: {_eq: SHOWCASE}}) {
      price
      currency
      created_at
      is_public
    }
    edition_type
    Editions(
      where: {publish_date: {_is_null: false}, is_public: {_eq: true}, deleted_at: {_is_null: true}}
    ) {
      title
    }
  }
}
    `;

/**
 * __useGetUserTrapArtworkInformationQuery__
 *
 * To run a query within a React component, call `useGetUserTrapArtworkInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTrapArtworkInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTrapArtworkInformationQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetUserTrapArtworkInformationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserTrapArtworkInformationQuery, Types.GetUserTrapArtworkInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserTrapArtworkInformationQuery, Types.GetUserTrapArtworkInformationQueryVariables>(GetUserTrapArtworkInformationDocument, options);
      }
export function useGetUserTrapArtworkInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserTrapArtworkInformationQuery, Types.GetUserTrapArtworkInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserTrapArtworkInformationQuery, Types.GetUserTrapArtworkInformationQueryVariables>(GetUserTrapArtworkInformationDocument, options);
        }
export type GetUserTrapArtworkInformationQueryHookResult = ReturnType<typeof useGetUserTrapArtworkInformationQuery>;
export type GetUserTrapArtworkInformationLazyQueryHookResult = ReturnType<typeof useGetUserTrapArtworkInformationLazyQuery>;
export type GetUserTrapArtworkInformationQueryResult = Apollo.QueryResult<Types.GetUserTrapArtworkInformationQuery, Types.GetUserTrapArtworkInformationQueryVariables>;
export const GetUserTrapArtistInformationDocument = gql`
    query GetUserTrapArtistInformation($contextId: bigint!) {
  Context_by_pk(id: $contextId) {
    id
    profile {
      id
      title
      handle
      avatar
    }
    locations {
      country
      city
    }
  }
}
    `;

/**
 * __useGetUserTrapArtistInformationQuery__
 *
 * To run a query within a React component, call `useGetUserTrapArtistInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTrapArtistInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTrapArtistInformationQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetUserTrapArtistInformationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserTrapArtistInformationQuery, Types.GetUserTrapArtistInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserTrapArtistInformationQuery, Types.GetUserTrapArtistInformationQueryVariables>(GetUserTrapArtistInformationDocument, options);
      }
export function useGetUserTrapArtistInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserTrapArtistInformationQuery, Types.GetUserTrapArtistInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserTrapArtistInformationQuery, Types.GetUserTrapArtistInformationQueryVariables>(GetUserTrapArtistInformationDocument, options);
        }
export type GetUserTrapArtistInformationQueryHookResult = ReturnType<typeof useGetUserTrapArtistInformationQuery>;
export type GetUserTrapArtistInformationLazyQueryHookResult = ReturnType<typeof useGetUserTrapArtistInformationLazyQuery>;
export type GetUserTrapArtistInformationQueryResult = Apollo.QueryResult<Types.GetUserTrapArtistInformationQuery, Types.GetUserTrapArtistInformationQueryVariables>;
export const CheckIfValidationTokenIsValidDocument = gql`
    mutation checkIfValidationTokenIsValid($token: String!, $email: String, $tokenId: Int) {
  checkIfValidationTokenIsValid(token: $token, email: $email, tokenId: $tokenId) {
    valid
  }
}
    `;
export type CheckIfValidationTokenIsValidMutationFn = Apollo.MutationFunction<Types.CheckIfValidationTokenIsValidMutation, Types.CheckIfValidationTokenIsValidMutationVariables>;

/**
 * __useCheckIfValidationTokenIsValidMutation__
 *
 * To run a mutation, you first call `useCheckIfValidationTokenIsValidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfValidationTokenIsValidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfValidationTokenIsValidMutation, { data, loading, error }] = useCheckIfValidationTokenIsValidMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useCheckIfValidationTokenIsValidMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckIfValidationTokenIsValidMutation, Types.CheckIfValidationTokenIsValidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckIfValidationTokenIsValidMutation, Types.CheckIfValidationTokenIsValidMutationVariables>(CheckIfValidationTokenIsValidDocument, options);
      }
export type CheckIfValidationTokenIsValidMutationHookResult = ReturnType<typeof useCheckIfValidationTokenIsValidMutation>;
export type CheckIfValidationTokenIsValidMutationResult = Apollo.MutationResult<Types.CheckIfValidationTokenIsValidMutation>;
export type CheckIfValidationTokenIsValidMutationOptions = Apollo.BaseMutationOptions<Types.CheckIfValidationTokenIsValidMutation, Types.CheckIfValidationTokenIsValidMutationVariables>;
export const ContactArtistDocument = gql`
    mutation contactArtist($artistContextId: Int!, $contactName: String!, $contactEmail: String!, $contactMessage: String!) {
  contactArtist(
    artistContextId: $artistContextId
    contactName: $contactName
    contactEmail: $contactEmail
    contactMessage: $contactMessage
  )
}
    `;
export type ContactArtistMutationFn = Apollo.MutationFunction<Types.ContactArtistMutation, Types.ContactArtistMutationVariables>;

/**
 * __useContactArtistMutation__
 *
 * To run a mutation, you first call `useContactArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactArtistMutation, { data, loading, error }] = useContactArtistMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      contactName: // value for 'contactName'
 *      contactEmail: // value for 'contactEmail'
 *      contactMessage: // value for 'contactMessage'
 *   },
 * });
 */
export function useContactArtistMutation(baseOptions?: Apollo.MutationHookOptions<Types.ContactArtistMutation, Types.ContactArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ContactArtistMutation, Types.ContactArtistMutationVariables>(ContactArtistDocument, options);
      }
export type ContactArtistMutationHookResult = ReturnType<typeof useContactArtistMutation>;
export type ContactArtistMutationResult = Apollo.MutationResult<Types.ContactArtistMutation>;
export type ContactArtistMutationOptions = Apollo.BaseMutationOptions<Types.ContactArtistMutation, Types.ContactArtistMutationVariables>;
export const CloseAccountDocument = gql`
    mutation closeAccount {
  closeAccount
}
    `;
export type CloseAccountMutationFn = Apollo.MutationFunction<Types.CloseAccountMutation, Types.CloseAccountMutationVariables>;

/**
 * __useCloseAccountMutation__
 *
 * To run a mutation, you first call `useCloseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAccountMutation, { data, loading, error }] = useCloseAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useCloseAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.CloseAccountMutation, Types.CloseAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CloseAccountMutation, Types.CloseAccountMutationVariables>(CloseAccountDocument, options);
      }
export type CloseAccountMutationHookResult = ReturnType<typeof useCloseAccountMutation>;
export type CloseAccountMutationResult = Apollo.MutationResult<Types.CloseAccountMutation>;
export type CloseAccountMutationOptions = Apollo.BaseMutationOptions<Types.CloseAccountMutation, Types.CloseAccountMutationVariables>;
export const GetCustomDomainInfoDocument = gql`
    query getCustomDomainInfo($domainName: String!) {
  Connected_domain(
    where: {domain_name: {_eq: $domainName}, deleted_at: {_is_null: true}}
  ) {
    domain_name
    state
    context_id
    context {
      profile {
        first_name
        last_name
        email
        handle
        preferred_language
      }
    }
  }
}
    `;

/**
 * __useGetCustomDomainInfoQuery__
 *
 * To run a query within a React component, call `useGetCustomDomainInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomDomainInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomDomainInfoQuery({
 *   variables: {
 *      domainName: // value for 'domainName'
 *   },
 * });
 */
export function useGetCustomDomainInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCustomDomainInfoQuery, Types.GetCustomDomainInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCustomDomainInfoQuery, Types.GetCustomDomainInfoQueryVariables>(GetCustomDomainInfoDocument, options);
      }
export function useGetCustomDomainInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCustomDomainInfoQuery, Types.GetCustomDomainInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCustomDomainInfoQuery, Types.GetCustomDomainInfoQueryVariables>(GetCustomDomainInfoDocument, options);
        }
export type GetCustomDomainInfoQueryHookResult = ReturnType<typeof useGetCustomDomainInfoQuery>;
export type GetCustomDomainInfoLazyQueryHookResult = ReturnType<typeof useGetCustomDomainInfoLazyQuery>;
export type GetCustomDomainInfoQueryResult = Apollo.QueryResult<Types.GetCustomDomainInfoQuery, Types.GetCustomDomainInfoQueryVariables>;
export const GetPubNubTokenDocument = gql`
    query getPubNubToken($userContextId: Int!) {
  getPubNubToken(userContextId: $userContextId)
}
    `;

/**
 * __useGetPubNubTokenQuery__
 *
 * To run a query within a React component, call `useGetPubNubTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPubNubTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPubNubTokenQuery({
 *   variables: {
 *      userContextId: // value for 'userContextId'
 *   },
 * });
 */
export function useGetPubNubTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPubNubTokenQuery, Types.GetPubNubTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPubNubTokenQuery, Types.GetPubNubTokenQueryVariables>(GetPubNubTokenDocument, options);
      }
export function useGetPubNubTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPubNubTokenQuery, Types.GetPubNubTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPubNubTokenQuery, Types.GetPubNubTokenQueryVariables>(GetPubNubTokenDocument, options);
        }
export type GetPubNubTokenQueryHookResult = ReturnType<typeof useGetPubNubTokenQuery>;
export type GetPubNubTokenLazyQueryHookResult = ReturnType<typeof useGetPubNubTokenLazyQuery>;
export type GetPubNubTokenQueryResult = Apollo.QueryResult<Types.GetPubNubTokenQuery, Types.GetPubNubTokenQueryVariables>;
export const GetArtistRecognitionsDocument = gql`
    query getArtistRecognitions($contextId: bigint!, $offset: Int = 0, $limit: Int = 100, $filter: String = "%") {
  recognitions: Social_graph_followers_view(
    where: {state: {_eq: "ACTIVE"}, context_id: {_eq: $contextId}, _or: [{target_profile_title: {_ilike: $filter}}, {target_profile_first_name: {_ilike: $filter}}, {target_profile_last_name: {_ilike: $filter}}]}
    offset: $offset
    limit: $limit
    order_by: {selected_order: asc}
  ) {
    id
    contextId: context_id
    targetContextId: target_context_id
    targetProfileId: target_profile_id
    targetProfileAvatar: target_profile_avatar
    targetProfileTitle: target_profile_title
    targetProfileFirstName: target_profile_first_name
    targetProfileLastName: target_profile_last_name
    targetProfileHandle: target_profile_handle
  }
}
    `;

/**
 * __useGetArtistRecognitionsQuery__
 *
 * To run a query within a React component, call `useGetArtistRecognitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistRecognitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistRecognitionsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetArtistRecognitionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistRecognitionsQuery, Types.GetArtistRecognitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistRecognitionsQuery, Types.GetArtistRecognitionsQueryVariables>(GetArtistRecognitionsDocument, options);
      }
export function useGetArtistRecognitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistRecognitionsQuery, Types.GetArtistRecognitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistRecognitionsQuery, Types.GetArtistRecognitionsQueryVariables>(GetArtistRecognitionsDocument, options);
        }
export type GetArtistRecognitionsQueryHookResult = ReturnType<typeof useGetArtistRecognitionsQuery>;
export type GetArtistRecognitionsLazyQueryHookResult = ReturnType<typeof useGetArtistRecognitionsLazyQuery>;
export type GetArtistRecognitionsQueryResult = Apollo.QueryResult<Types.GetArtistRecognitionsQuery, Types.GetArtistRecognitionsQueryVariables>;
export const GetArtistInfoForMessageSectionDocument = gql`
    query getArtistInfoForMessageSection($contextId: bigint!) {
  context: Context_by_pk(id: $contextId) {
    id
    type
    profile {
      id
      title
      avatar
      handle
    }
  }
}
    `;

/**
 * __useGetArtistInfoForMessageSectionQuery__
 *
 * To run a query within a React component, call `useGetArtistInfoForMessageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistInfoForMessageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistInfoForMessageSectionQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistInfoForMessageSectionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistInfoForMessageSectionQuery, Types.GetArtistInfoForMessageSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistInfoForMessageSectionQuery, Types.GetArtistInfoForMessageSectionQueryVariables>(GetArtistInfoForMessageSectionDocument, options);
      }
export function useGetArtistInfoForMessageSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistInfoForMessageSectionQuery, Types.GetArtistInfoForMessageSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistInfoForMessageSectionQuery, Types.GetArtistInfoForMessageSectionQueryVariables>(GetArtistInfoForMessageSectionDocument, options);
        }
export type GetArtistInfoForMessageSectionQueryHookResult = ReturnType<typeof useGetArtistInfoForMessageSectionQuery>;
export type GetArtistInfoForMessageSectionLazyQueryHookResult = ReturnType<typeof useGetArtistInfoForMessageSectionLazyQuery>;
export type GetArtistInfoForMessageSectionQueryResult = Apollo.QueryResult<Types.GetArtistInfoForMessageSectionQuery, Types.GetArtistInfoForMessageSectionQueryVariables>;
export const StartChatDocument = gql`
    mutation startChat($senderContextId: Int!, $receiverContextId: Int!) {
  dmChannelName: startChat(
    senderContextId: $senderContextId
    receiverContextId: $receiverContextId
  )
}
    `;
export type StartChatMutationFn = Apollo.MutationFunction<Types.StartChatMutation, Types.StartChatMutationVariables>;

/**
 * __useStartChatMutation__
 *
 * To run a mutation, you first call `useStartChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startChatMutation, { data, loading, error }] = useStartChatMutation({
 *   variables: {
 *      senderContextId: // value for 'senderContextId'
 *      receiverContextId: // value for 'receiverContextId'
 *   },
 * });
 */
export function useStartChatMutation(baseOptions?: Apollo.MutationHookOptions<Types.StartChatMutation, Types.StartChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StartChatMutation, Types.StartChatMutationVariables>(StartChatDocument, options);
      }
export type StartChatMutationHookResult = ReturnType<typeof useStartChatMutation>;
export type StartChatMutationResult = Apollo.MutationResult<Types.StartChatMutation>;
export type StartChatMutationOptions = Apollo.BaseMutationOptions<Types.StartChatMutation, Types.StartChatMutationVariables>;
export const InitChatDocument = gql`
    mutation initChat($contextId: Int!) {
  subChannelName: initChat(contextId: $contextId)
}
    `;
export type InitChatMutationFn = Apollo.MutationFunction<Types.InitChatMutation, Types.InitChatMutationVariables>;

/**
 * __useInitChatMutation__
 *
 * To run a mutation, you first call `useInitChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initChatMutation, { data, loading, error }] = useInitChatMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useInitChatMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitChatMutation, Types.InitChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitChatMutation, Types.InitChatMutationVariables>(InitChatDocument, options);
      }
export type InitChatMutationHookResult = ReturnType<typeof useInitChatMutation>;
export type InitChatMutationResult = Apollo.MutationResult<Types.InitChatMutation>;
export type InitChatMutationOptions = Apollo.BaseMutationOptions<Types.InitChatMutation, Types.InitChatMutationVariables>;
export const StartDmPushNotificationLogicDocument = gql`
    mutation startDMPushNotificationLogic($senderContextId: Int!, $receiverContextId: Int!) {
  sendMessageNotifications(
    context_receiver_id: $receiverContextId
    context_sender_id: $senderContextId
  )
}
    `;
export type StartDmPushNotificationLogicMutationFn = Apollo.MutationFunction<Types.StartDmPushNotificationLogicMutation, Types.StartDmPushNotificationLogicMutationVariables>;

/**
 * __useStartDmPushNotificationLogicMutation__
 *
 * To run a mutation, you first call `useStartDmPushNotificationLogicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDmPushNotificationLogicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDmPushNotificationLogicMutation, { data, loading, error }] = useStartDmPushNotificationLogicMutation({
 *   variables: {
 *      senderContextId: // value for 'senderContextId'
 *      receiverContextId: // value for 'receiverContextId'
 *   },
 * });
 */
export function useStartDmPushNotificationLogicMutation(baseOptions?: Apollo.MutationHookOptions<Types.StartDmPushNotificationLogicMutation, Types.StartDmPushNotificationLogicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.StartDmPushNotificationLogicMutation, Types.StartDmPushNotificationLogicMutationVariables>(StartDmPushNotificationLogicDocument, options);
      }
export type StartDmPushNotificationLogicMutationHookResult = ReturnType<typeof useStartDmPushNotificationLogicMutation>;
export type StartDmPushNotificationLogicMutationResult = Apollo.MutationResult<Types.StartDmPushNotificationLogicMutation>;
export type StartDmPushNotificationLogicMutationOptions = Apollo.BaseMutationOptions<Types.StartDmPushNotificationLogicMutation, Types.StartDmPushNotificationLogicMutationVariables>;
export const GetAccountNoticeAckDocument = gql`
    query getAccountNoticeAck($where: Account_notice_ack_bool_exp!) {
  Account_notice_ack(where: $where) {
    id
    context_id
    type
    acknowledgement
  }
}
    `;

/**
 * __useGetAccountNoticeAckQuery__
 *
 * To run a query within a React component, call `useGetAccountNoticeAckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountNoticeAckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountNoticeAckQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAccountNoticeAckQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAccountNoticeAckQuery, Types.GetAccountNoticeAckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAccountNoticeAckQuery, Types.GetAccountNoticeAckQueryVariables>(GetAccountNoticeAckDocument, options);
      }
export function useGetAccountNoticeAckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAccountNoticeAckQuery, Types.GetAccountNoticeAckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAccountNoticeAckQuery, Types.GetAccountNoticeAckQueryVariables>(GetAccountNoticeAckDocument, options);
        }
export type GetAccountNoticeAckQueryHookResult = ReturnType<typeof useGetAccountNoticeAckQuery>;
export type GetAccountNoticeAckLazyQueryHookResult = ReturnType<typeof useGetAccountNoticeAckLazyQuery>;
export type GetAccountNoticeAckQueryResult = Apollo.QueryResult<Types.GetAccountNoticeAckQuery, Types.GetAccountNoticeAckQueryVariables>;
export const AcknowledgeAccountNoticeDocument = gql`
    mutation acknowledgeAccountNotice($contextId: bigint!, $type: ENUM_account_notice_type_enum!, $ack: String!) {
  insert_Account_notice_ack_one(
    object: {context_id: $contextId, type: $type, acknowledgement: $ack}
    on_conflict: {constraint: Account_notice_ack_context_id_type_key, update_columns: [acknowledgement]}
  ) {
    id
    context_id
    type
    acknowledgement
  }
}
    `;
export type AcknowledgeAccountNoticeMutationFn = Apollo.MutationFunction<Types.AcknowledgeAccountNoticeMutation, Types.AcknowledgeAccountNoticeMutationVariables>;

/**
 * __useAcknowledgeAccountNoticeMutation__
 *
 * To run a mutation, you first call `useAcknowledgeAccountNoticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeAccountNoticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeAccountNoticeMutation, { data, loading, error }] = useAcknowledgeAccountNoticeMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      type: // value for 'type'
 *      ack: // value for 'ack'
 *   },
 * });
 */
export function useAcknowledgeAccountNoticeMutation(baseOptions?: Apollo.MutationHookOptions<Types.AcknowledgeAccountNoticeMutation, Types.AcknowledgeAccountNoticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AcknowledgeAccountNoticeMutation, Types.AcknowledgeAccountNoticeMutationVariables>(AcknowledgeAccountNoticeDocument, options);
      }
export type AcknowledgeAccountNoticeMutationHookResult = ReturnType<typeof useAcknowledgeAccountNoticeMutation>;
export type AcknowledgeAccountNoticeMutationResult = Apollo.MutationResult<Types.AcknowledgeAccountNoticeMutation>;
export type AcknowledgeAccountNoticeMutationOptions = Apollo.BaseMutationOptions<Types.AcknowledgeAccountNoticeMutation, Types.AcknowledgeAccountNoticeMutationVariables>;
export const SetFavoriteArtworkDocument = gql`
    mutation setFavoriteArtwork($artwork_id: Int!, $context_id: Int!) {
  setFavoriteArtwork(artwork_id: $artwork_id, context_id: $context_id)
}
    `;
export type SetFavoriteArtworkMutationFn = Apollo.MutationFunction<Types.SetFavoriteArtworkMutation, Types.SetFavoriteArtworkMutationVariables>;

/**
 * __useSetFavoriteArtworkMutation__
 *
 * To run a mutation, you first call `useSetFavoriteArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFavoriteArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFavoriteArtworkMutation, { data, loading, error }] = useSetFavoriteArtworkMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useSetFavoriteArtworkMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetFavoriteArtworkMutation, Types.SetFavoriteArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetFavoriteArtworkMutation, Types.SetFavoriteArtworkMutationVariables>(SetFavoriteArtworkDocument, options);
      }
export type SetFavoriteArtworkMutationHookResult = ReturnType<typeof useSetFavoriteArtworkMutation>;
export type SetFavoriteArtworkMutationResult = Apollo.MutationResult<Types.SetFavoriteArtworkMutation>;
export type SetFavoriteArtworkMutationOptions = Apollo.BaseMutationOptions<Types.SetFavoriteArtworkMutation, Types.SetFavoriteArtworkMutationVariables>;
export const GetFavoritesDocument = gql`
    query getFavorites($context_id: bigint!) {
  Collection(where: {type: {_eq: FAVORITE}, owner_context_id: {_eq: $context_id}}) {
    id
    owner_context_id
    artwork_to_collections(order_by: {updated_at: desc, created_at: desc}) {
      artwork_id
    }
  }
}
    `;

/**
 * __useGetFavoritesQuery__
 *
 * To run a query within a React component, call `useGetFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritesQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetFavoritesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoritesQuery, Types.GetFavoritesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoritesQuery, Types.GetFavoritesQueryVariables>(GetFavoritesDocument, options);
      }
export function useGetFavoritesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoritesQuery, Types.GetFavoritesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoritesQuery, Types.GetFavoritesQueryVariables>(GetFavoritesDocument, options);
        }
export type GetFavoritesQueryHookResult = ReturnType<typeof useGetFavoritesQuery>;
export type GetFavoritesLazyQueryHookResult = ReturnType<typeof useGetFavoritesLazyQuery>;
export type GetFavoritesQueryResult = Apollo.QueryResult<Types.GetFavoritesQuery, Types.GetFavoritesQueryVariables>;
export const CheckIfArtworkIsFavoriteDocument = gql`
    query checkIfArtworkIsFavorite($context_id: bigint!, $artwork_id: bigint!) {
  Artwork(
    where: {id: {_eq: $artwork_id}, artwork_to_collections: {Collection: {_and: {type: {_eq: FAVORITE}, owner_context_id: {_eq: $context_id}}}}}
  ) {
    __typename
  }
}
    `;

/**
 * __useCheckIfArtworkIsFavoriteQuery__
 *
 * To run a query within a React component, call `useCheckIfArtworkIsFavoriteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfArtworkIsFavoriteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfArtworkIsFavoriteQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useCheckIfArtworkIsFavoriteQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckIfArtworkIsFavoriteQuery, Types.CheckIfArtworkIsFavoriteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckIfArtworkIsFavoriteQuery, Types.CheckIfArtworkIsFavoriteQueryVariables>(CheckIfArtworkIsFavoriteDocument, options);
      }
export function useCheckIfArtworkIsFavoriteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckIfArtworkIsFavoriteQuery, Types.CheckIfArtworkIsFavoriteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckIfArtworkIsFavoriteQuery, Types.CheckIfArtworkIsFavoriteQueryVariables>(CheckIfArtworkIsFavoriteDocument, options);
        }
export type CheckIfArtworkIsFavoriteQueryHookResult = ReturnType<typeof useCheckIfArtworkIsFavoriteQuery>;
export type CheckIfArtworkIsFavoriteLazyQueryHookResult = ReturnType<typeof useCheckIfArtworkIsFavoriteLazyQuery>;
export type CheckIfArtworkIsFavoriteQueryResult = Apollo.QueryResult<Types.CheckIfArtworkIsFavoriteQuery, Types.CheckIfArtworkIsFavoriteQueryVariables>;
export const GetFavoritesCountDocument = gql`
    query getFavoritesCount($artworkId: bigint) {
  artwork_favorite_count(where: {artwork_id: {_eq: $artworkId}}) {
    favorite_count
  }
}
    `;

/**
 * __useGetFavoritesCountQuery__
 *
 * To run a query within a React component, call `useGetFavoritesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritesCountQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetFavoritesCountQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFavoritesCountQuery, Types.GetFavoritesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoritesCountQuery, Types.GetFavoritesCountQueryVariables>(GetFavoritesCountDocument, options);
      }
export function useGetFavoritesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoritesCountQuery, Types.GetFavoritesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoritesCountQuery, Types.GetFavoritesCountQueryVariables>(GetFavoritesCountDocument, options);
        }
export type GetFavoritesCountQueryHookResult = ReturnType<typeof useGetFavoritesCountQuery>;
export type GetFavoritesCountLazyQueryHookResult = ReturnType<typeof useGetFavoritesCountLazyQuery>;
export type GetFavoritesCountQueryResult = Apollo.QueryResult<Types.GetFavoritesCountQuery, Types.GetFavoritesCountQueryVariables>;
export const GetFavoriteModalDetailsDocument = gql`
    query GetFavoriteModalDetails($userIds: [bigint!], $skipCollectors: Boolean!, $skipArtists: Boolean!, $skipGalleries: Boolean!) {
  Artists: Artist_explore_info(where: {artist_context_id: {_in: $userIds}}) @skip(if: $skipArtists) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    firstName: first_name
    lastName: last_name
    isPublic: is_public
  }
  Collectors: Collector_explore_info(
    where: {collector_context_id: {_in: $userIds}}
  ) @skip(if: $skipCollectors) {
    contextId: collector_context_id
    profileId: collector_profile_id
    title
    handle
    avatar
    firstName: first_name
    lastName: last_name
    isPublic: is_public
  }
  Galleries: Gallery_explore_info(where: {gallery_context_id: {_in: $userIds}}) @skip(if: $skipGalleries) {
    contextId: gallery_context_id
    profileId: gallery_profile_id
    title
    handle
    avatar
    firstName: first_name
    lastName: last_name
    isPublic: is_public
  }
  Artworks: artist_cardgrid_artworks_view(
    where: {rank: {_lte: 3}, artist_context_id: {_in: $userIds}}
  ) @skip(if: $skipArtists) {
    id
    artistContextId: artist_context_id
    thumbnailMetadataId: thumbnail_metadata_id
    rank
  }
}
    `;

/**
 * __useGetFavoriteModalDetailsQuery__
 *
 * To run a query within a React component, call `useGetFavoriteModalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteModalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteModalDetailsQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      skipCollectors: // value for 'skipCollectors'
 *      skipArtists: // value for 'skipArtists'
 *      skipGalleries: // value for 'skipGalleries'
 *   },
 * });
 */
export function useGetFavoriteModalDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoriteModalDetailsQuery, Types.GetFavoriteModalDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoriteModalDetailsQuery, Types.GetFavoriteModalDetailsQueryVariables>(GetFavoriteModalDetailsDocument, options);
      }
export function useGetFavoriteModalDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoriteModalDetailsQuery, Types.GetFavoriteModalDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoriteModalDetailsQuery, Types.GetFavoriteModalDetailsQueryVariables>(GetFavoriteModalDetailsDocument, options);
        }
export type GetFavoriteModalDetailsQueryHookResult = ReturnType<typeof useGetFavoriteModalDetailsQuery>;
export type GetFavoriteModalDetailsLazyQueryHookResult = ReturnType<typeof useGetFavoriteModalDetailsLazyQuery>;
export type GetFavoriteModalDetailsQueryResult = Apollo.QueryResult<Types.GetFavoriteModalDetailsQuery, Types.GetFavoriteModalDetailsQueryVariables>;
export const GetFavoriteModalListingDocument = gql`
    query GetFavoriteModalListing($limit: Int!, $artworkId: bigint!, $lastTimeStamp: timestamptz!) {
  users: Artwork_favorited_by_listing(
    where: {_and: [{favorited_at: {_lt: $lastTimeStamp}}, {artwork_id: {_eq: $artworkId}}]}
    order_by: {favorited_at: desc}
    limit: $limit
  ) {
    favoritedByType: favorited_by_type
    favoritedAt: favorited_at
    favoritedBy: favorited_by
  }
}
    `;

/**
 * __useGetFavoriteModalListingQuery__
 *
 * To run a query within a React component, call `useGetFavoriteModalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteModalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteModalListingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      artworkId: // value for 'artworkId'
 *      lastTimeStamp: // value for 'lastTimeStamp'
 *   },
 * });
 */
export function useGetFavoriteModalListingQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoriteModalListingQuery, Types.GetFavoriteModalListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoriteModalListingQuery, Types.GetFavoriteModalListingQueryVariables>(GetFavoriteModalListingDocument, options);
      }
export function useGetFavoriteModalListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoriteModalListingQuery, Types.GetFavoriteModalListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoriteModalListingQuery, Types.GetFavoriteModalListingQueryVariables>(GetFavoriteModalListingDocument, options);
        }
export type GetFavoriteModalListingQueryHookResult = ReturnType<typeof useGetFavoriteModalListingQuery>;
export type GetFavoriteModalListingLazyQueryHookResult = ReturnType<typeof useGetFavoriteModalListingLazyQuery>;
export type GetFavoriteModalListingQueryResult = Apollo.QueryResult<Types.GetFavoriteModalListingQuery, Types.GetFavoriteModalListingQueryVariables>;
export const GetArtworkSlugAndHandleDocument = gql`
    query GetArtworkSlugAndHandle($id: bigint!) {
  Artwork_by_pk(id: $id) {
    slug
    artist_context {
      profile {
        handle
      }
    }
  }
}
    `;

/**
 * __useGetArtworkSlugAndHandleQuery__
 *
 * To run a query within a React component, call `useGetArtworkSlugAndHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkSlugAndHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkSlugAndHandleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtworkSlugAndHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkSlugAndHandleQuery, Types.GetArtworkSlugAndHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkSlugAndHandleQuery, Types.GetArtworkSlugAndHandleQueryVariables>(GetArtworkSlugAndHandleDocument, options);
      }
export function useGetArtworkSlugAndHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkSlugAndHandleQuery, Types.GetArtworkSlugAndHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkSlugAndHandleQuery, Types.GetArtworkSlugAndHandleQueryVariables>(GetArtworkSlugAndHandleDocument, options);
        }
export type GetArtworkSlugAndHandleQueryHookResult = ReturnType<typeof useGetArtworkSlugAndHandleQuery>;
export type GetArtworkSlugAndHandleLazyQueryHookResult = ReturnType<typeof useGetArtworkSlugAndHandleLazyQuery>;
export type GetArtworkSlugAndHandleQueryResult = Apollo.QueryResult<Types.GetArtworkSlugAndHandleQuery, Types.GetArtworkSlugAndHandleQueryVariables>;
export const GetShowroomFilteredArtworksDocument = gql`
    query getShowroomFilteredArtworks($where: Artwork_bool_exp = {}, $showroomId: bigint!) {
  Artwork(
    where: {_and: [$where, {artwork_to_collections: {collection_id: {_eq: $showroomId}}}]}
  ) {
    id
    slug
    primary_image_metadata_id
    thumbnail_metadata_id
    ...LocalCommonModifiersRequiredFields
    published_date
    artwork_details {
      creation_date_day
      creation_date_month
      creation_date_year
      depth
      duration
      height
      is_dark_mode
      materials
      medium
      measuring_unit
      materials_and_medium
      discipline_other
      discipline
      title
      tools
      width
    }
    artwork_to_collections(where: {collection_id: {_eq: $showroomId}}) {
      order
      collection_id
    }
    Artwork_prices {
      price
      scope
      currency
      id
      artwork_to_showroom_details {
        status
        artwork_to_collection {
          collection_id
        }
      }
    }
    thumbnail_metadata {
      extra
    }
    primary_image_metadata {
      extra
    }
  }
}
    ${LocalCommonModifiersRequiredFieldsFragmentDoc}`;

/**
 * __useGetShowroomFilteredArtworksQuery__
 *
 * To run a query within a React component, call `useGetShowroomFilteredArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomFilteredArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomFilteredArtworksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useGetShowroomFilteredArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowroomFilteredArtworksQuery, Types.GetShowroomFilteredArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowroomFilteredArtworksQuery, Types.GetShowroomFilteredArtworksQueryVariables>(GetShowroomFilteredArtworksDocument, options);
      }
export function useGetShowroomFilteredArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowroomFilteredArtworksQuery, Types.GetShowroomFilteredArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowroomFilteredArtworksQuery, Types.GetShowroomFilteredArtworksQueryVariables>(GetShowroomFilteredArtworksDocument, options);
        }
export type GetShowroomFilteredArtworksQueryHookResult = ReturnType<typeof useGetShowroomFilteredArtworksQuery>;
export type GetShowroomFilteredArtworksLazyQueryHookResult = ReturnType<typeof useGetShowroomFilteredArtworksLazyQuery>;
export type GetShowroomFilteredArtworksQueryResult = Apollo.QueryResult<Types.GetShowroomFilteredArtworksQuery, Types.GetShowroomFilteredArtworksQueryVariables>;
export const GetShowroomArtworkOrderDocument = gql`
    query getShowroomArtworkOrder($collection_id: bigint!) {
  Artwork_to_Collection(where: {collection_id: {_eq: $collection_id}}) {
    artwork_id
    order
  }
}
    `;

/**
 * __useGetShowroomArtworkOrderQuery__
 *
 * To run a query within a React component, call `useGetShowroomArtworkOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomArtworkOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomArtworkOrderQuery({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *   },
 * });
 */
export function useGetShowroomArtworkOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowroomArtworkOrderQuery, Types.GetShowroomArtworkOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowroomArtworkOrderQuery, Types.GetShowroomArtworkOrderQueryVariables>(GetShowroomArtworkOrderDocument, options);
      }
export function useGetShowroomArtworkOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowroomArtworkOrderQuery, Types.GetShowroomArtworkOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowroomArtworkOrderQuery, Types.GetShowroomArtworkOrderQueryVariables>(GetShowroomArtworkOrderDocument, options);
        }
export type GetShowroomArtworkOrderQueryHookResult = ReturnType<typeof useGetShowroomArtworkOrderQuery>;
export type GetShowroomArtworkOrderLazyQueryHookResult = ReturnType<typeof useGetShowroomArtworkOrderLazyQuery>;
export type GetShowroomArtworkOrderQueryResult = Apollo.QueryResult<Types.GetShowroomArtworkOrderQuery, Types.GetShowroomArtworkOrderQueryVariables>;
export const GetArtistFilteredArtworksDocument = gql`
    query getArtistFilteredArtworks($where: Artwork_bool_exp = {}, $artistContextId: bigint!, $skipCounts: Boolean = true) {
  Artwork(
    where: {_and: [$where, {_or: [{edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}, artwork_to_collections: {is_public: {_eq: true}, Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $artistContextId}}}}, {edition_type: {_eq: STANDALONE_EDITION}, Editions: {publish_date: {_is_null: false}, deleted_at: {_is_null: true}}, artwork_to_collections: {is_public: {_eq: true}, Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $artistContextId}}}}, {transaction_histories: {id: {_is_null: false}}, artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $artistContextId}}}}], artist_context_id: {_eq: $artistContextId}}]}
    order_by: [{published_date: desc}]
  ) {
    id
    target_id
    ...LocalCommonModifiersRequiredFields
    ...ProfileArtistArtworks
    Artwork_to_favorite_count @skip(if: $skipCounts) {
      favorite_count
    }
  }
}
    ${LocalCommonModifiersRequiredFieldsFragmentDoc}
${ProfileArtistArtworksFragmentDoc}`;

/**
 * __useGetArtistFilteredArtworksQuery__
 *
 * To run a query within a React component, call `useGetArtistFilteredArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistFilteredArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistFilteredArtworksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      artistContextId: // value for 'artistContextId'
 *      skipCounts: // value for 'skipCounts'
 *   },
 * });
 */
export function useGetArtistFilteredArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistFilteredArtworksQuery, Types.GetArtistFilteredArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistFilteredArtworksQuery, Types.GetArtistFilteredArtworksQueryVariables>(GetArtistFilteredArtworksDocument, options);
      }
export function useGetArtistFilteredArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistFilteredArtworksQuery, Types.GetArtistFilteredArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistFilteredArtworksQuery, Types.GetArtistFilteredArtworksQueryVariables>(GetArtistFilteredArtworksDocument, options);
        }
export type GetArtistFilteredArtworksQueryHookResult = ReturnType<typeof useGetArtistFilteredArtworksQuery>;
export type GetArtistFilteredArtworksLazyQueryHookResult = ReturnType<typeof useGetArtistFilteredArtworksLazyQuery>;
export type GetArtistFilteredArtworksQueryResult = Apollo.QueryResult<Types.GetArtistFilteredArtworksQuery, Types.GetArtistFilteredArtworksQueryVariables>;
export const GetPublishedArtworksAmountByArtistDocument = gql`
    query getPublishedArtworksAmountByArtist($artist_context_id: bigint!) {
  aggregateProxy(
    type: TotalNumberOfArtworksForArtistMilestones
    variables: {artist_context_id: $artist_context_id}
  )
}
    `;

/**
 * __useGetPublishedArtworksAmountByArtistQuery__
 *
 * To run a query within a React component, call `useGetPublishedArtworksAmountByArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishedArtworksAmountByArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishedArtworksAmountByArtistQuery({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useGetPublishedArtworksAmountByArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPublishedArtworksAmountByArtistQuery, Types.GetPublishedArtworksAmountByArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPublishedArtworksAmountByArtistQuery, Types.GetPublishedArtworksAmountByArtistQueryVariables>(GetPublishedArtworksAmountByArtistDocument, options);
      }
export function useGetPublishedArtworksAmountByArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPublishedArtworksAmountByArtistQuery, Types.GetPublishedArtworksAmountByArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPublishedArtworksAmountByArtistQuery, Types.GetPublishedArtworksAmountByArtistQueryVariables>(GetPublishedArtworksAmountByArtistDocument, options);
        }
export type GetPublishedArtworksAmountByArtistQueryHookResult = ReturnType<typeof useGetPublishedArtworksAmountByArtistQuery>;
export type GetPublishedArtworksAmountByArtistLazyQueryHookResult = ReturnType<typeof useGetPublishedArtworksAmountByArtistLazyQuery>;
export type GetPublishedArtworksAmountByArtistQueryResult = Apollo.QueryResult<Types.GetPublishedArtworksAmountByArtistQuery, Types.GetPublishedArtworksAmountByArtistQueryVariables>;
export const GetCollectorFilteredArtworksDocument = gql`
    query getCollectorFilteredArtworks($where: Artwork_bool_exp = {}, $collectorContextId: bigint!) {
  Artwork(
    where: {_and: [$where, {is_public: {_eq: true}, artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $collectorContextId}}}}]}
    order_by: [{published_date: desc}]
  ) {
    ...LocalCommonModifiersRequiredFields
    ...ProfileCollectorArtwork
    artwork_to_collections(where: {Collection: {type: {_in: [OWNED]}}}) {
      order
      collection_id
    }
    Artwork_prices(where: {scope: {_eq: SHOWCASE}}) {
      price
      scope
      currency
      id
    }
    editions: Editions {
      id
      title
      Prints(
        where: {print_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $collectorContextId}}}}
      ) {
        ...ProfileCollectorEditionPrint
      }
    }
  }
}
    ${LocalCommonModifiersRequiredFieldsFragmentDoc}
${ProfileCollectorArtworkFragmentDoc}
${ProfileCollectorEditionPrintFragmentDoc}`;

/**
 * __useGetCollectorFilteredArtworksQuery__
 *
 * To run a query within a React component, call `useGetCollectorFilteredArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorFilteredArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorFilteredArtworksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      collectorContextId: // value for 'collectorContextId'
 *   },
 * });
 */
export function useGetCollectorFilteredArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorFilteredArtworksQuery, Types.GetCollectorFilteredArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorFilteredArtworksQuery, Types.GetCollectorFilteredArtworksQueryVariables>(GetCollectorFilteredArtworksDocument, options);
      }
export function useGetCollectorFilteredArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorFilteredArtworksQuery, Types.GetCollectorFilteredArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorFilteredArtworksQuery, Types.GetCollectorFilteredArtworksQueryVariables>(GetCollectorFilteredArtworksDocument, options);
        }
export type GetCollectorFilteredArtworksQueryHookResult = ReturnType<typeof useGetCollectorFilteredArtworksQuery>;
export type GetCollectorFilteredArtworksLazyQueryHookResult = ReturnType<typeof useGetCollectorFilteredArtworksLazyQuery>;
export type GetCollectorFilteredArtworksQueryResult = Apollo.QueryResult<Types.GetCollectorFilteredArtworksQuery, Types.GetCollectorFilteredArtworksQueryVariables>;
export const GetCollectorFilteredPrintsDocument = gql`
    query getCollectorFilteredPrints($where: Print_bool_exp = {}, $collectorContextId: bigint!) {
  Print(
    where: {_and: [$where, {print_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $collectorContextId}}}}]}
    order_by: {Artwork: {published_date: desc}}
  ) {
    edition: Edition {
      title
    }
    artwork: Artwork {
      ...ProfileCollectorArtwork
      ...LocalCommonModifiersRequiredFields
    }
    ...ProfileCollectorEditionPrint
    print_to_collections(where: {Collection: {type: {_in: [OWNED]}}}) {
      order
      collection_id
    }
  }
}
    ${ProfileCollectorArtworkFragmentDoc}
${LocalCommonModifiersRequiredFieldsFragmentDoc}
${ProfileCollectorEditionPrintFragmentDoc}`;

/**
 * __useGetCollectorFilteredPrintsQuery__
 *
 * To run a query within a React component, call `useGetCollectorFilteredPrintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorFilteredPrintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorFilteredPrintsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      collectorContextId: // value for 'collectorContextId'
 *   },
 * });
 */
export function useGetCollectorFilteredPrintsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorFilteredPrintsQuery, Types.GetCollectorFilteredPrintsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorFilteredPrintsQuery, Types.GetCollectorFilteredPrintsQueryVariables>(GetCollectorFilteredPrintsDocument, options);
      }
export function useGetCollectorFilteredPrintsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorFilteredPrintsQuery, Types.GetCollectorFilteredPrintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorFilteredPrintsQuery, Types.GetCollectorFilteredPrintsQueryVariables>(GetCollectorFilteredPrintsDocument, options);
        }
export type GetCollectorFilteredPrintsQueryHookResult = ReturnType<typeof useGetCollectorFilteredPrintsQuery>;
export type GetCollectorFilteredPrintsLazyQueryHookResult = ReturnType<typeof useGetCollectorFilteredPrintsLazyQuery>;
export type GetCollectorFilteredPrintsQueryResult = Apollo.QueryResult<Types.GetCollectorFilteredPrintsQuery, Types.GetCollectorFilteredPrintsQueryVariables>;
export const GetFeaturedCollectionsDocument = gql`
    query getFeaturedCollections($where: Artist_Collection_bool_exp!) {
  Artist_Collection(
    where: {_and: [$where, {deleted_at: {_is_null: true}, type: {_eq: virtual_exhibition}}]}
  ) {
    id
    title
    description
    short_description
    banner_metadata_id
    thumbnail_metadata_id
    updated_at
    internal_url
    Artist_Collection_to_Artist_Contexts(where: {deleted_at: {_is_null: true}}) {
      id
    }
  }
}
    `;

/**
 * __useGetFeaturedCollectionsQuery__
 *
 * To run a query within a React component, call `useGetFeaturedCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedCollectionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFeaturedCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFeaturedCollectionsQuery, Types.GetFeaturedCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFeaturedCollectionsQuery, Types.GetFeaturedCollectionsQueryVariables>(GetFeaturedCollectionsDocument, options);
      }
export function useGetFeaturedCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFeaturedCollectionsQuery, Types.GetFeaturedCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFeaturedCollectionsQuery, Types.GetFeaturedCollectionsQueryVariables>(GetFeaturedCollectionsDocument, options);
        }
export type GetFeaturedCollectionsQueryHookResult = ReturnType<typeof useGetFeaturedCollectionsQuery>;
export type GetFeaturedCollectionsLazyQueryHookResult = ReturnType<typeof useGetFeaturedCollectionsLazyQuery>;
export type GetFeaturedCollectionsQueryResult = Apollo.QueryResult<Types.GetFeaturedCollectionsQuery, Types.GetFeaturedCollectionsQueryVariables>;
export const GetArtistsFilteredProfilesDocument = gql`
    query getArtistsFilteredProfiles($where: Context_bool_exp = {}) {
  Context(where: {_and: [$where]}) {
    ...ProfileLocalCommonModifiersRequiredFields
  }
}
    ${ProfileLocalCommonModifiersRequiredFieldsFragmentDoc}`;

/**
 * __useGetArtistsFilteredProfilesQuery__
 *
 * To run a query within a React component, call `useGetArtistsFilteredProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsFilteredProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsFilteredProfilesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetArtistsFilteredProfilesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtistsFilteredProfilesQuery, Types.GetArtistsFilteredProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistsFilteredProfilesQuery, Types.GetArtistsFilteredProfilesQueryVariables>(GetArtistsFilteredProfilesDocument, options);
      }
export function useGetArtistsFilteredProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistsFilteredProfilesQuery, Types.GetArtistsFilteredProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistsFilteredProfilesQuery, Types.GetArtistsFilteredProfilesQueryVariables>(GetArtistsFilteredProfilesDocument, options);
        }
export type GetArtistsFilteredProfilesQueryHookResult = ReturnType<typeof useGetArtistsFilteredProfilesQuery>;
export type GetArtistsFilteredProfilesLazyQueryHookResult = ReturnType<typeof useGetArtistsFilteredProfilesLazyQuery>;
export type GetArtistsFilteredProfilesQueryResult = Apollo.QueryResult<Types.GetArtistsFilteredProfilesQuery, Types.GetArtistsFilteredProfilesQueryVariables>;
export const TriggerRunRuleDocument = gql`
    mutation triggerRunRule($achievementName: [String]!, $profile_id: Int!, $updateNow: Boolean) {
  runRule(
    achievementName: $achievementName
    updateNow: $updateNow
    userID: $profile_id
  )
}
    `;
export type TriggerRunRuleMutationFn = Apollo.MutationFunction<Types.TriggerRunRuleMutation, Types.TriggerRunRuleMutationVariables>;

/**
 * __useTriggerRunRuleMutation__
 *
 * To run a mutation, you first call `useTriggerRunRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerRunRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerRunRuleMutation, { data, loading, error }] = useTriggerRunRuleMutation({
 *   variables: {
 *      achievementName: // value for 'achievementName'
 *      profile_id: // value for 'profile_id'
 *      updateNow: // value for 'updateNow'
 *   },
 * });
 */
export function useTriggerRunRuleMutation(baseOptions?: Apollo.MutationHookOptions<Types.TriggerRunRuleMutation, Types.TriggerRunRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TriggerRunRuleMutation, Types.TriggerRunRuleMutationVariables>(TriggerRunRuleDocument, options);
      }
export type TriggerRunRuleMutationHookResult = ReturnType<typeof useTriggerRunRuleMutation>;
export type TriggerRunRuleMutationResult = Apollo.MutationResult<Types.TriggerRunRuleMutation>;
export type TriggerRunRuleMutationOptions = Apollo.BaseMutationOptions<Types.TriggerRunRuleMutation, Types.TriggerRunRuleMutationVariables>;
export const TriggergrantAchievementDocument = gql`
    mutation triggergrantAchievement($userID: Int!, $profileContextId: Int!, $achievementName: String!, $visitorIdCookie: String!, $updateNow: Boolean) {
  grantAchievement(
    achievementName: $achievementName
    updateNow: $updateNow
    userID: $userID
    profileContextId: $profileContextId
    visitorIdCookie: $visitorIdCookie
  )
}
    `;
export type TriggergrantAchievementMutationFn = Apollo.MutationFunction<Types.TriggergrantAchievementMutation, Types.TriggergrantAchievementMutationVariables>;

/**
 * __useTriggergrantAchievementMutation__
 *
 * To run a mutation, you first call `useTriggergrantAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggergrantAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggergrantAchievementMutation, { data, loading, error }] = useTriggergrantAchievementMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      profileContextId: // value for 'profileContextId'
 *      achievementName: // value for 'achievementName'
 *      visitorIdCookie: // value for 'visitorIdCookie'
 *      updateNow: // value for 'updateNow'
 *   },
 * });
 */
export function useTriggergrantAchievementMutation(baseOptions?: Apollo.MutationHookOptions<Types.TriggergrantAchievementMutation, Types.TriggergrantAchievementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TriggergrantAchievementMutation, Types.TriggergrantAchievementMutationVariables>(TriggergrantAchievementDocument, options);
      }
export type TriggergrantAchievementMutationHookResult = ReturnType<typeof useTriggergrantAchievementMutation>;
export type TriggergrantAchievementMutationResult = Apollo.MutationResult<Types.TriggergrantAchievementMutation>;
export type TriggergrantAchievementMutationOptions = Apollo.BaseMutationOptions<Types.TriggergrantAchievementMutation, Types.TriggergrantAchievementMutationVariables>;
export const ConfirmArtworkAvailabilityDocument = gql`
    mutation confirmArtworkAvailability($input: ConfirmArtworkAvailabilityInput!) {
  confirmArtworkAvailability(input: $input)
}
    `;
export type ConfirmArtworkAvailabilityMutationFn = Apollo.MutationFunction<Types.ConfirmArtworkAvailabilityMutation, Types.ConfirmArtworkAvailabilityMutationVariables>;

/**
 * __useConfirmArtworkAvailabilityMutation__
 *
 * To run a mutation, you first call `useConfirmArtworkAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmArtworkAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmArtworkAvailabilityMutation, { data, loading, error }] = useConfirmArtworkAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmArtworkAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<Types.ConfirmArtworkAvailabilityMutation, Types.ConfirmArtworkAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ConfirmArtworkAvailabilityMutation, Types.ConfirmArtworkAvailabilityMutationVariables>(ConfirmArtworkAvailabilityDocument, options);
      }
export type ConfirmArtworkAvailabilityMutationHookResult = ReturnType<typeof useConfirmArtworkAvailabilityMutation>;
export type ConfirmArtworkAvailabilityMutationResult = Apollo.MutationResult<Types.ConfirmArtworkAvailabilityMutation>;
export type ConfirmArtworkAvailabilityMutationOptions = Apollo.BaseMutationOptions<Types.ConfirmArtworkAvailabilityMutation, Types.ConfirmArtworkAvailabilityMutationVariables>;
export const GetArtworkDetailsBasedOnIdDocument = gql`
    query GetArtworkDetailsBasedOnId($artworkId: bigint!) {
  Artwork_by_pk(id: $artworkId) {
    primary_image_metadata_id
    artwork_details {
      title
    }
    editions: Editions {
      sizes: Edition_By_Sizes {
        printSize: print_size
        price
        isPricePublic: is_price_public
        editionByTypes: Edition_By_Types(
          where: {Edition_Type: {deleted_at: {_is_null: true}}}
        ) {
          id
          status
          printAmount: print_amount
          unavailablePrintAmount: unavailable_print_amount
          prints: Prints(order_by: {print_number: asc}) {
            printNumber: print_number
            status
            price
            isTransactionLocked: is_transaction_locked
            isPublic: is_public
          }
        }
      }
    }
    prices: Artwork_prices(
      where: {trashed_at: {_is_null: true}, is_public: {_eq: true}, scope: {_eq: SHOWCASE}}
      order_by: {updated_at: desc}
      limit: 1
    ) {
      isPublic: is_public
    }
  }
}
    `;

/**
 * __useGetArtworkDetailsBasedOnIdQuery__
 *
 * To run a query within a React component, call `useGetArtworkDetailsBasedOnIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkDetailsBasedOnIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkDetailsBasedOnIdQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetArtworkDetailsBasedOnIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkDetailsBasedOnIdQuery, Types.GetArtworkDetailsBasedOnIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkDetailsBasedOnIdQuery, Types.GetArtworkDetailsBasedOnIdQueryVariables>(GetArtworkDetailsBasedOnIdDocument, options);
      }
export function useGetArtworkDetailsBasedOnIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkDetailsBasedOnIdQuery, Types.GetArtworkDetailsBasedOnIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkDetailsBasedOnIdQuery, Types.GetArtworkDetailsBasedOnIdQueryVariables>(GetArtworkDetailsBasedOnIdDocument, options);
        }
export type GetArtworkDetailsBasedOnIdQueryHookResult = ReturnType<typeof useGetArtworkDetailsBasedOnIdQuery>;
export type GetArtworkDetailsBasedOnIdLazyQueryHookResult = ReturnType<typeof useGetArtworkDetailsBasedOnIdLazyQuery>;
export type GetArtworkDetailsBasedOnIdQueryResult = Apollo.QueryResult<Types.GetArtworkDetailsBasedOnIdQuery, Types.GetArtworkDetailsBasedOnIdQueryVariables>;
export const GetArtworkPurchaseJourneysInProgressDocument = gql`
    query getArtworkPurchaseJourneysInProgress($contextId: bigint!) {
  journey: Artwork_purchase_journey(
    where: {status: {_eq: IN_PROGRESS}, seller_context_id: {_eq: $contextId}}
  ) {
    id
    artworkId: artwork_id
    createdAt: created_at
    updatedAt: updated_at
    updates: Last_updates(where: {entry_kind: {_eq: EVENT}}) {
      journeyId: journey_id
      entryKind: entry_kind
      partyType: party_type
      historyEntry: History_entry {
        id
        entryKindType: entry_kind_type
      }
    }
  }
}
    `;

/**
 * __useGetArtworkPurchaseJourneysInProgressQuery__
 *
 * To run a query within a React component, call `useGetArtworkPurchaseJourneysInProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkPurchaseJourneysInProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkPurchaseJourneysInProgressQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtworkPurchaseJourneysInProgressQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkPurchaseJourneysInProgressQuery, Types.GetArtworkPurchaseJourneysInProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkPurchaseJourneysInProgressQuery, Types.GetArtworkPurchaseJourneysInProgressQueryVariables>(GetArtworkPurchaseJourneysInProgressDocument, options);
      }
export function useGetArtworkPurchaseJourneysInProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkPurchaseJourneysInProgressQuery, Types.GetArtworkPurchaseJourneysInProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkPurchaseJourneysInProgressQuery, Types.GetArtworkPurchaseJourneysInProgressQueryVariables>(GetArtworkPurchaseJourneysInProgressDocument, options);
        }
export type GetArtworkPurchaseJourneysInProgressQueryHookResult = ReturnType<typeof useGetArtworkPurchaseJourneysInProgressQuery>;
export type GetArtworkPurchaseJourneysInProgressLazyQueryHookResult = ReturnType<typeof useGetArtworkPurchaseJourneysInProgressLazyQuery>;
export type GetArtworkPurchaseJourneysInProgressQueryResult = Apollo.QueryResult<Types.GetArtworkPurchaseJourneysInProgressQuery, Types.GetArtworkPurchaseJourneysInProgressQueryVariables>;
export const GetLastShippingDetailsDocument = gql`
    query getLastShippingDetails($contextId: bigint!) {
  journey: Artwork_purchase_journey(where: {seller_context_id: {_eq: $contextId}}) {
    shipping: Shipping_locations {
      id
      cityId: city_id
      method
      zip
      worldCity: Worldcity {
        country: Country {
          iso2
        }
        adminType: admin_type
        adminName: admin_name
        city
      }
    }
  }
}
    `;

/**
 * __useGetLastShippingDetailsQuery__
 *
 * To run a query within a React component, call `useGetLastShippingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastShippingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastShippingDetailsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetLastShippingDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLastShippingDetailsQuery, Types.GetLastShippingDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLastShippingDetailsQuery, Types.GetLastShippingDetailsQueryVariables>(GetLastShippingDetailsDocument, options);
      }
export function useGetLastShippingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLastShippingDetailsQuery, Types.GetLastShippingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLastShippingDetailsQuery, Types.GetLastShippingDetailsQueryVariables>(GetLastShippingDetailsDocument, options);
        }
export type GetLastShippingDetailsQueryHookResult = ReturnType<typeof useGetLastShippingDetailsQuery>;
export type GetLastShippingDetailsLazyQueryHookResult = ReturnType<typeof useGetLastShippingDetailsLazyQuery>;
export type GetLastShippingDetailsQueryResult = Apollo.QueryResult<Types.GetLastShippingDetailsQuery, Types.GetLastShippingDetailsQueryVariables>;
export const GetMilestonesDocument = gql`
    query GetMilestones($context_id: bigint!) {
  Milestone {
    id
    feature
    min_value
    required_milestone {
      id
      feature
      min_value
    }
    context_to_milestones(where: {context_id: {_eq: $context_id}}) {
      id
      status
      milestone_id
    }
  }
}
    `;

/**
 * __useGetMilestonesQuery__
 *
 * To run a query within a React component, call `useGetMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMilestonesQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetMilestonesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMilestonesQuery, Types.GetMilestonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMilestonesQuery, Types.GetMilestonesQueryVariables>(GetMilestonesDocument, options);
      }
export function useGetMilestonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMilestonesQuery, Types.GetMilestonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMilestonesQuery, Types.GetMilestonesQueryVariables>(GetMilestonesDocument, options);
        }
export type GetMilestonesQueryHookResult = ReturnType<typeof useGetMilestonesQuery>;
export type GetMilestonesLazyQueryHookResult = ReturnType<typeof useGetMilestonesLazyQuery>;
export type GetMilestonesQueryResult = Apollo.QueryResult<Types.GetMilestonesQuery, Types.GetMilestonesQueryVariables>;
export const GetUserMilestonesByFeaturesDocument = gql`
    query GetUserMilestonesByFeatures($context_id: bigint!, $features: [ENUM_tutorial_step_type_enum!]!) {
  Context_to_milestone(
    where: {context_id: {_eq: $context_id}, milestone: {feature: {_in: $features}}}
  ) {
    id
    status
    milestone_id
  }
}
    `;

/**
 * __useGetUserMilestonesByFeaturesQuery__
 *
 * To run a query within a React component, call `useGetUserMilestonesByFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMilestonesByFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMilestonesByFeaturesQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      features: // value for 'features'
 *   },
 * });
 */
export function useGetUserMilestonesByFeaturesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserMilestonesByFeaturesQuery, Types.GetUserMilestonesByFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserMilestonesByFeaturesQuery, Types.GetUserMilestonesByFeaturesQueryVariables>(GetUserMilestonesByFeaturesDocument, options);
      }
export function useGetUserMilestonesByFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserMilestonesByFeaturesQuery, Types.GetUserMilestonesByFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserMilestonesByFeaturesQuery, Types.GetUserMilestonesByFeaturesQueryVariables>(GetUserMilestonesByFeaturesDocument, options);
        }
export type GetUserMilestonesByFeaturesQueryHookResult = ReturnType<typeof useGetUserMilestonesByFeaturesQuery>;
export type GetUserMilestonesByFeaturesLazyQueryHookResult = ReturnType<typeof useGetUserMilestonesByFeaturesLazyQuery>;
export type GetUserMilestonesByFeaturesQueryResult = Apollo.QueryResult<Types.GetUserMilestonesByFeaturesQuery, Types.GetUserMilestonesByFeaturesQueryVariables>;
export const GetArtistPublicArtworksIdsDocument = gql`
    query GetArtistPublicArtworksIds($artist_context_id: bigint!) {
  Artwork(
    where: {is_public: {_eq: true}, artist_context_id: {_eq: $artist_context_id}}
  ) {
    id
  }
}
    `;

/**
 * __useGetArtistPublicArtworksIdsQuery__
 *
 * To run a query within a React component, call `useGetArtistPublicArtworksIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistPublicArtworksIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistPublicArtworksIdsQuery({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useGetArtistPublicArtworksIdsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistPublicArtworksIdsQuery, Types.GetArtistPublicArtworksIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistPublicArtworksIdsQuery, Types.GetArtistPublicArtworksIdsQueryVariables>(GetArtistPublicArtworksIdsDocument, options);
      }
export function useGetArtistPublicArtworksIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistPublicArtworksIdsQuery, Types.GetArtistPublicArtworksIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistPublicArtworksIdsQuery, Types.GetArtistPublicArtworksIdsQueryVariables>(GetArtistPublicArtworksIdsDocument, options);
        }
export type GetArtistPublicArtworksIdsQueryHookResult = ReturnType<typeof useGetArtistPublicArtworksIdsQuery>;
export type GetArtistPublicArtworksIdsLazyQueryHookResult = ReturnType<typeof useGetArtistPublicArtworksIdsLazyQuery>;
export type GetArtistPublicArtworksIdsQueryResult = Apollo.QueryResult<Types.GetArtistPublicArtworksIdsQuery, Types.GetArtistPublicArtworksIdsQueryVariables>;
export const InsertUserMilestoneDocument = gql`
    mutation InsertUserMilestone($object: Context_to_milestone_insert_input!) {
  insert_Context_to_milestone_one(object: $object) {
    id
  }
}
    `;
export type InsertUserMilestoneMutationFn = Apollo.MutationFunction<Types.InsertUserMilestoneMutation, Types.InsertUserMilestoneMutationVariables>;

/**
 * __useInsertUserMilestoneMutation__
 *
 * To run a mutation, you first call `useInsertUserMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMilestoneMutation, { data, loading, error }] = useInsertUserMilestoneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertUserMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertUserMilestoneMutation, Types.InsertUserMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertUserMilestoneMutation, Types.InsertUserMilestoneMutationVariables>(InsertUserMilestoneDocument, options);
      }
export type InsertUserMilestoneMutationHookResult = ReturnType<typeof useInsertUserMilestoneMutation>;
export type InsertUserMilestoneMutationResult = Apollo.MutationResult<Types.InsertUserMilestoneMutation>;
export type InsertUserMilestoneMutationOptions = Apollo.BaseMutationOptions<Types.InsertUserMilestoneMutation, Types.InsertUserMilestoneMutationVariables>;
export const UpdateUserMilestoneDocument = gql`
    mutation UpdateUserMilestone($status: String!, $context_id: bigint!, $milestone_id: bigint!) {
  update_Context_to_milestone(
    where: {context_id: {_eq: $context_id}, milestone_id: {_eq: $milestone_id}}
    _set: {status: $status}
  ) {
    affected_rows
  }
}
    `;
export type UpdateUserMilestoneMutationFn = Apollo.MutationFunction<Types.UpdateUserMilestoneMutation, Types.UpdateUserMilestoneMutationVariables>;

/**
 * __useUpdateUserMilestoneMutation__
 *
 * To run a mutation, you first call `useUpdateUserMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMilestoneMutation, { data, loading, error }] = useUpdateUserMilestoneMutation({
 *   variables: {
 *      status: // value for 'status'
 *      context_id: // value for 'context_id'
 *      milestone_id: // value for 'milestone_id'
 *   },
 * });
 */
export function useUpdateUserMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserMilestoneMutation, Types.UpdateUserMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserMilestoneMutation, Types.UpdateUserMilestoneMutationVariables>(UpdateUserMilestoneDocument, options);
      }
export type UpdateUserMilestoneMutationHookResult = ReturnType<typeof useUpdateUserMilestoneMutation>;
export type UpdateUserMilestoneMutationResult = Apollo.MutationResult<Types.UpdateUserMilestoneMutation>;
export type UpdateUserMilestoneMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserMilestoneMutation, Types.UpdateUserMilestoneMutationVariables>;
export const GetArtworkMilestoneAppearanceDocument = gql`
    query getArtworkMilestoneAppearance($artist_context_id: bigint!) {
  Artist_explore_visibility(
    where: {artist_context_id: {_eq: $artist_context_id}, appear_at: {_is_null: false}, appear_reason: {_like: "ARTWORK_COUNT_MILESTONES.ARTIST_EXPLORE%"}}
    order_by: {appear_at: asc}
  ) {
    appear_reason
    artist_context_id
    appear_at
    hasDismissedMessage: has_dismissed_message
  }
}
    `;

/**
 * __useGetArtworkMilestoneAppearanceQuery__
 *
 * To run a query within a React component, call `useGetArtworkMilestoneAppearanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkMilestoneAppearanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkMilestoneAppearanceQuery({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useGetArtworkMilestoneAppearanceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkMilestoneAppearanceQuery, Types.GetArtworkMilestoneAppearanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkMilestoneAppearanceQuery, Types.GetArtworkMilestoneAppearanceQueryVariables>(GetArtworkMilestoneAppearanceDocument, options);
      }
export function useGetArtworkMilestoneAppearanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkMilestoneAppearanceQuery, Types.GetArtworkMilestoneAppearanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkMilestoneAppearanceQuery, Types.GetArtworkMilestoneAppearanceQueryVariables>(GetArtworkMilestoneAppearanceDocument, options);
        }
export type GetArtworkMilestoneAppearanceQueryHookResult = ReturnType<typeof useGetArtworkMilestoneAppearanceQuery>;
export type GetArtworkMilestoneAppearanceLazyQueryHookResult = ReturnType<typeof useGetArtworkMilestoneAppearanceLazyQuery>;
export type GetArtworkMilestoneAppearanceQueryResult = Apollo.QueryResult<Types.GetArtworkMilestoneAppearanceQuery, Types.GetArtworkMilestoneAppearanceQueryVariables>;
export const GetArtistExploreMilestonesDocument = gql`
    query getArtistExploreMilestones($milestone_type: ENUM_artwork_count_milestone_type_enum!) {
  Artwork_count_milestones(
    where: {milestone_type: {_eq: $milestone_type}}
    order_by: {id: asc}
  ) {
    id
    artwork_count
  }
}
    `;

/**
 * __useGetArtistExploreMilestonesQuery__
 *
 * To run a query within a React component, call `useGetArtistExploreMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistExploreMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistExploreMilestonesQuery({
 *   variables: {
 *      milestone_type: // value for 'milestone_type'
 *   },
 * });
 */
export function useGetArtistExploreMilestonesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistExploreMilestonesQuery, Types.GetArtistExploreMilestonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistExploreMilestonesQuery, Types.GetArtistExploreMilestonesQueryVariables>(GetArtistExploreMilestonesDocument, options);
      }
export function useGetArtistExploreMilestonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistExploreMilestonesQuery, Types.GetArtistExploreMilestonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistExploreMilestonesQuery, Types.GetArtistExploreMilestonesQueryVariables>(GetArtistExploreMilestonesDocument, options);
        }
export type GetArtistExploreMilestonesQueryHookResult = ReturnType<typeof useGetArtistExploreMilestonesQuery>;
export type GetArtistExploreMilestonesLazyQueryHookResult = ReturnType<typeof useGetArtistExploreMilestonesLazyQuery>;
export type GetArtistExploreMilestonesQueryResult = Apollo.QueryResult<Types.GetArtistExploreMilestonesQuery, Types.GetArtistExploreMilestonesQueryVariables>;
export const GetNotificationCenterListingDocument = gql`
    query GetNotificationCenterListing($limit: Int!, $appliedSorts: [app_notifications_listing_order_by!], $appliedFilters: app_notifications_listing_bool_exp!) {
  notifications: app_notifications_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonNotificationCenterListingCursor
  }
}
    ${CommonNotificationCenterListingCursorFragmentDoc}`;

/**
 * __useGetNotificationCenterListingQuery__
 *
 * To run a query within a React component, call `useGetNotificationCenterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationCenterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationCenterListingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetNotificationCenterListingQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNotificationCenterListingQuery, Types.GetNotificationCenterListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNotificationCenterListingQuery, Types.GetNotificationCenterListingQueryVariables>(GetNotificationCenterListingDocument, options);
      }
export function useGetNotificationCenterListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNotificationCenterListingQuery, Types.GetNotificationCenterListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNotificationCenterListingQuery, Types.GetNotificationCenterListingQueryVariables>(GetNotificationCenterListingDocument, options);
        }
export type GetNotificationCenterListingQueryHookResult = ReturnType<typeof useGetNotificationCenterListingQuery>;
export type GetNotificationCenterListingLazyQueryHookResult = ReturnType<typeof useGetNotificationCenterListingLazyQuery>;
export type GetNotificationCenterListingQueryResult = Apollo.QueryResult<Types.GetNotificationCenterListingQuery, Types.GetNotificationCenterListingQueryVariables>;
export const GetNotificationCenterInfoDocument = gql`
    query GetNotificationCenterInfo($notificationIds: [bigint!]!) {
  app_notifications_info(where: {id: {_in: $notificationIds}}) {
    id
    type
    message
    updatedAt: updated_message_at
    createdAt: created_at
    readAt: read_at
  }
}
    `;

/**
 * __useGetNotificationCenterInfoQuery__
 *
 * To run a query within a React component, call `useGetNotificationCenterInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationCenterInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationCenterInfoQuery({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *   },
 * });
 */
export function useGetNotificationCenterInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNotificationCenterInfoQuery, Types.GetNotificationCenterInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNotificationCenterInfoQuery, Types.GetNotificationCenterInfoQueryVariables>(GetNotificationCenterInfoDocument, options);
      }
export function useGetNotificationCenterInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNotificationCenterInfoQuery, Types.GetNotificationCenterInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNotificationCenterInfoQuery, Types.GetNotificationCenterInfoQueryVariables>(GetNotificationCenterInfoDocument, options);
        }
export type GetNotificationCenterInfoQueryHookResult = ReturnType<typeof useGetNotificationCenterInfoQuery>;
export type GetNotificationCenterInfoLazyQueryHookResult = ReturnType<typeof useGetNotificationCenterInfoLazyQuery>;
export type GetNotificationCenterInfoQueryResult = Apollo.QueryResult<Types.GetNotificationCenterInfoQuery, Types.GetNotificationCenterInfoQueryVariables>;
export const GetInfoGroupedNotificationsByIdDocument = gql`
    query GetInfoGroupedNotificationsById($artworkIds: [bigint!] = [], $contextIds: [bigint!] = [], $targetContextId: bigint!) {
  Artwork(where: {id: {_in: $artworkIds}}) {
    id
    slug
    artwork_details {
      title
    }
    artist_context {
      profile {
        handle
      }
    }
    thumbnail_metadata_id
  }
  Context(where: {id: {_in: $contextIds}}) {
    id
    type
    profile {
      id
      is_public
      avatar
      title
      first_name
      last_name
      handle
    }
  }
  recognitions: Social_graph_followers_view(
    where: {context_id: {_in: $contextIds}, target_context_id: {_eq: $targetContextId}}
  ) {
    id
    __typename
    context_id
  }
  artworks: artist_cardgrid_artworks_view(
    where: {rank: {_lte: 3}, artist_context_id: {_in: $contextIds}}
  ) {
    thumbnail_metadata_id
    id
    artist_context_id
  }
}
    `;

/**
 * __useGetInfoGroupedNotificationsByIdQuery__
 *
 * To run a query within a React component, call `useGetInfoGroupedNotificationsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoGroupedNotificationsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoGroupedNotificationsByIdQuery({
 *   variables: {
 *      artworkIds: // value for 'artworkIds'
 *      contextIds: // value for 'contextIds'
 *      targetContextId: // value for 'targetContextId'
 *   },
 * });
 */
export function useGetInfoGroupedNotificationsByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInfoGroupedNotificationsByIdQuery, Types.GetInfoGroupedNotificationsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInfoGroupedNotificationsByIdQuery, Types.GetInfoGroupedNotificationsByIdQueryVariables>(GetInfoGroupedNotificationsByIdDocument, options);
      }
export function useGetInfoGroupedNotificationsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInfoGroupedNotificationsByIdQuery, Types.GetInfoGroupedNotificationsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInfoGroupedNotificationsByIdQuery, Types.GetInfoGroupedNotificationsByIdQueryVariables>(GetInfoGroupedNotificationsByIdDocument, options);
        }
export type GetInfoGroupedNotificationsByIdQueryHookResult = ReturnType<typeof useGetInfoGroupedNotificationsByIdQuery>;
export type GetInfoGroupedNotificationsByIdLazyQueryHookResult = ReturnType<typeof useGetInfoGroupedNotificationsByIdLazyQuery>;
export type GetInfoGroupedNotificationsByIdQueryResult = Apollo.QueryResult<Types.GetInfoGroupedNotificationsByIdQuery, Types.GetInfoGroupedNotificationsByIdQueryVariables>;
export const GetUserAppNotificationsDocument = gql`
    subscription GetUserAppNotifications($context_id: bigint!, $offset: Int = 0, $limit: Int!) {
  App_notifications(
    where: {context_id: {_eq: $context_id}, dismissed_at: {_is_null: true}}
    order_by: {updated_message_at: desc}
    offset: $offset
    limit: $limit
  ) {
    id
    created_at
    type
    message
    read_at
    updated_message_at
  }
}
    `;

/**
 * __useGetUserAppNotificationsSubscription__
 *
 * To run a query within a React component, call `useGetUserAppNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAppNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAppNotificationsSubscription({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserAppNotificationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.GetUserAppNotificationsSubscription, Types.GetUserAppNotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.GetUserAppNotificationsSubscription, Types.GetUserAppNotificationsSubscriptionVariables>(GetUserAppNotificationsDocument, options);
      }
export type GetUserAppNotificationsSubscriptionHookResult = ReturnType<typeof useGetUserAppNotificationsSubscription>;
export type GetUserAppNotificationsSubscriptionResult = Apollo.SubscriptionResult<Types.GetUserAppNotificationsSubscription>;
export const GetUserAppNotificationsByBatchesDocument = gql`
    query GetUserAppNotificationsByBatches($context_id: bigint!, $offset: Int = 0, $limit: Int!) {
  app_notifications_view(
    where: {context_id: {_eq: $context_id}}
    offset: $offset
    limit: $limit
  ) {
    id
    created_at
    type
    message
    read_at
    updated_message_at
  }
}
    `;

/**
 * __useGetUserAppNotificationsByBatchesQuery__
 *
 * To run a query within a React component, call `useGetUserAppNotificationsByBatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAppNotificationsByBatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAppNotificationsByBatchesQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserAppNotificationsByBatchesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserAppNotificationsByBatchesQuery, Types.GetUserAppNotificationsByBatchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserAppNotificationsByBatchesQuery, Types.GetUserAppNotificationsByBatchesQueryVariables>(GetUserAppNotificationsByBatchesDocument, options);
      }
export function useGetUserAppNotificationsByBatchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserAppNotificationsByBatchesQuery, Types.GetUserAppNotificationsByBatchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserAppNotificationsByBatchesQuery, Types.GetUserAppNotificationsByBatchesQueryVariables>(GetUserAppNotificationsByBatchesDocument, options);
        }
export type GetUserAppNotificationsByBatchesQueryHookResult = ReturnType<typeof useGetUserAppNotificationsByBatchesQuery>;
export type GetUserAppNotificationsByBatchesLazyQueryHookResult = ReturnType<typeof useGetUserAppNotificationsByBatchesLazyQuery>;
export type GetUserAppNotificationsByBatchesQueryResult = Apollo.QueryResult<Types.GetUserAppNotificationsByBatchesQuery, Types.GetUserAppNotificationsByBatchesQueryVariables>;
export const MarkAppNotificationReadDocument = gql`
    mutation MarkAppNotificationRead($notification_id: bigint!, $read_at: timestamptz) {
  update_App_notifications_by_pk(
    pk_columns: {id: $notification_id}
    _set: {read_at: $read_at}
  ) {
    id
  }
}
    `;
export type MarkAppNotificationReadMutationFn = Apollo.MutationFunction<Types.MarkAppNotificationReadMutation, Types.MarkAppNotificationReadMutationVariables>;

/**
 * __useMarkAppNotificationReadMutation__
 *
 * To run a mutation, you first call `useMarkAppNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAppNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAppNotificationReadMutation, { data, loading, error }] = useMarkAppNotificationReadMutation({
 *   variables: {
 *      notification_id: // value for 'notification_id'
 *      read_at: // value for 'read_at'
 *   },
 * });
 */
export function useMarkAppNotificationReadMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkAppNotificationReadMutation, Types.MarkAppNotificationReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkAppNotificationReadMutation, Types.MarkAppNotificationReadMutationVariables>(MarkAppNotificationReadDocument, options);
      }
export type MarkAppNotificationReadMutationHookResult = ReturnType<typeof useMarkAppNotificationReadMutation>;
export type MarkAppNotificationReadMutationResult = Apollo.MutationResult<Types.MarkAppNotificationReadMutation>;
export type MarkAppNotificationReadMutationOptions = Apollo.BaseMutationOptions<Types.MarkAppNotificationReadMutation, Types.MarkAppNotificationReadMutationVariables>;
export const MarkAppNotificationDismissedDocument = gql`
    mutation MarkAppNotificationDismissed($notification_id: bigint!) {
  update_App_notifications_by_pk(
    pk_columns: {id: $notification_id}
    _set: {dismissed_at: "now()"}
  ) {
    id
  }
}
    `;
export type MarkAppNotificationDismissedMutationFn = Apollo.MutationFunction<Types.MarkAppNotificationDismissedMutation, Types.MarkAppNotificationDismissedMutationVariables>;

/**
 * __useMarkAppNotificationDismissedMutation__
 *
 * To run a mutation, you first call `useMarkAppNotificationDismissedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAppNotificationDismissedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAppNotificationDismissedMutation, { data, loading, error }] = useMarkAppNotificationDismissedMutation({
 *   variables: {
 *      notification_id: // value for 'notification_id'
 *   },
 * });
 */
export function useMarkAppNotificationDismissedMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkAppNotificationDismissedMutation, Types.MarkAppNotificationDismissedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkAppNotificationDismissedMutation, Types.MarkAppNotificationDismissedMutationVariables>(MarkAppNotificationDismissedDocument, options);
      }
export type MarkAppNotificationDismissedMutationHookResult = ReturnType<typeof useMarkAppNotificationDismissedMutation>;
export type MarkAppNotificationDismissedMutationResult = Apollo.MutationResult<Types.MarkAppNotificationDismissedMutation>;
export type MarkAppNotificationDismissedMutationOptions = Apollo.BaseMutationOptions<Types.MarkAppNotificationDismissedMutation, Types.MarkAppNotificationDismissedMutationVariables>;
export const MarkUserAppNotificationsDismissedDocument = gql`
    mutation MarkUserAppNotificationsDismissed($context_id: bigint!) {
  update_App_notifications(
    where: {context_id: {_eq: $context_id}, dismissed_at: {_is_null: true}}
    _set: {dismissed_at: "now()"}
  ) {
    affected_rows
  }
}
    `;
export type MarkUserAppNotificationsDismissedMutationFn = Apollo.MutationFunction<Types.MarkUserAppNotificationsDismissedMutation, Types.MarkUserAppNotificationsDismissedMutationVariables>;

/**
 * __useMarkUserAppNotificationsDismissedMutation__
 *
 * To run a mutation, you first call `useMarkUserAppNotificationsDismissedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkUserAppNotificationsDismissedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markUserAppNotificationsDismissedMutation, { data, loading, error }] = useMarkUserAppNotificationsDismissedMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useMarkUserAppNotificationsDismissedMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkUserAppNotificationsDismissedMutation, Types.MarkUserAppNotificationsDismissedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkUserAppNotificationsDismissedMutation, Types.MarkUserAppNotificationsDismissedMutationVariables>(MarkUserAppNotificationsDismissedDocument, options);
      }
export type MarkUserAppNotificationsDismissedMutationHookResult = ReturnType<typeof useMarkUserAppNotificationsDismissedMutation>;
export type MarkUserAppNotificationsDismissedMutationResult = Apollo.MutationResult<Types.MarkUserAppNotificationsDismissedMutation>;
export type MarkUserAppNotificationsDismissedMutationOptions = Apollo.BaseMutationOptions<Types.MarkUserAppNotificationsDismissedMutation, Types.MarkUserAppNotificationsDismissedMutationVariables>;
export const MarkUserAppNotificationsReadDocument = gql`
    mutation MarkUserAppNotificationsRead($context_id: bigint!) {
  update_App_notifications(
    where: {context_id: {_eq: $context_id}, dismissed_at: {_is_null: true}}
    _set: {read_at: "now()"}
  ) {
    affected_rows
  }
}
    `;
export type MarkUserAppNotificationsReadMutationFn = Apollo.MutationFunction<Types.MarkUserAppNotificationsReadMutation, Types.MarkUserAppNotificationsReadMutationVariables>;

/**
 * __useMarkUserAppNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkUserAppNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkUserAppNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markUserAppNotificationsReadMutation, { data, loading, error }] = useMarkUserAppNotificationsReadMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useMarkUserAppNotificationsReadMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkUserAppNotificationsReadMutation, Types.MarkUserAppNotificationsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkUserAppNotificationsReadMutation, Types.MarkUserAppNotificationsReadMutationVariables>(MarkUserAppNotificationsReadDocument, options);
      }
export type MarkUserAppNotificationsReadMutationHookResult = ReturnType<typeof useMarkUserAppNotificationsReadMutation>;
export type MarkUserAppNotificationsReadMutationResult = Apollo.MutationResult<Types.MarkUserAppNotificationsReadMutation>;
export type MarkUserAppNotificationsReadMutationOptions = Apollo.BaseMutationOptions<Types.MarkUserAppNotificationsReadMutation, Types.MarkUserAppNotificationsReadMutationVariables>;
export const GetProfileForNotificationsByContextIdDocument = gql`
    query GetProfileForNotificationsByContextId($contextId: bigint!) {
  Context_by_pk(id: $contextId) {
    id
    type
    profile {
      id
      is_public
      avatar
      title
      first_name
      last_name
      handle
    }
  }
}
    `;

/**
 * __useGetProfileForNotificationsByContextIdQuery__
 *
 * To run a query within a React component, call `useGetProfileForNotificationsByContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileForNotificationsByContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileForNotificationsByContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileForNotificationsByContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileForNotificationsByContextIdQuery, Types.GetProfileForNotificationsByContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileForNotificationsByContextIdQuery, Types.GetProfileForNotificationsByContextIdQueryVariables>(GetProfileForNotificationsByContextIdDocument, options);
      }
export function useGetProfileForNotificationsByContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileForNotificationsByContextIdQuery, Types.GetProfileForNotificationsByContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileForNotificationsByContextIdQuery, Types.GetProfileForNotificationsByContextIdQueryVariables>(GetProfileForNotificationsByContextIdDocument, options);
        }
export type GetProfileForNotificationsByContextIdQueryHookResult = ReturnType<typeof useGetProfileForNotificationsByContextIdQuery>;
export type GetProfileForNotificationsByContextIdLazyQueryHookResult = ReturnType<typeof useGetProfileForNotificationsByContextIdLazyQuery>;
export type GetProfileForNotificationsByContextIdQueryResult = Apollo.QueryResult<Types.GetProfileForNotificationsByContextIdQuery, Types.GetProfileForNotificationsByContextIdQueryVariables>;
export const GetArtworkForNotificationsByIdDocument = gql`
    query GetArtworkForNotificationsById($artworkId: bigint!) {
  Artwork_by_pk(id: $artworkId) {
    id
    slug
    artwork_details {
      title
    }
    artist_context {
      profile {
        handle
      }
    }
    thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetArtworkForNotificationsByIdQuery__
 *
 * To run a query within a React component, call `useGetArtworkForNotificationsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkForNotificationsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkForNotificationsByIdQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetArtworkForNotificationsByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkForNotificationsByIdQuery, Types.GetArtworkForNotificationsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkForNotificationsByIdQuery, Types.GetArtworkForNotificationsByIdQueryVariables>(GetArtworkForNotificationsByIdDocument, options);
      }
export function useGetArtworkForNotificationsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkForNotificationsByIdQuery, Types.GetArtworkForNotificationsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkForNotificationsByIdQuery, Types.GetArtworkForNotificationsByIdQueryVariables>(GetArtworkForNotificationsByIdDocument, options);
        }
export type GetArtworkForNotificationsByIdQueryHookResult = ReturnType<typeof useGetArtworkForNotificationsByIdQuery>;
export type GetArtworkForNotificationsByIdLazyQueryHookResult = ReturnType<typeof useGetArtworkForNotificationsByIdLazyQuery>;
export type GetArtworkForNotificationsByIdQueryResult = Apollo.QueryResult<Types.GetArtworkForNotificationsByIdQuery, Types.GetArtworkForNotificationsByIdQueryVariables>;
export const GetUserAppNotificationsCountDocument = gql`
    query getUserAppNotificationsCount($contextId: bigint!) {
  aggregateProxy(
    type: NumberOfAppNotifications
    variables: {context_id: $contextId}
  )
}
    `;

/**
 * __useGetUserAppNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetUserAppNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAppNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAppNotificationsCountQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetUserAppNotificationsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserAppNotificationsCountQuery, Types.GetUserAppNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserAppNotificationsCountQuery, Types.GetUserAppNotificationsCountQueryVariables>(GetUserAppNotificationsCountDocument, options);
      }
export function useGetUserAppNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserAppNotificationsCountQuery, Types.GetUserAppNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserAppNotificationsCountQuery, Types.GetUserAppNotificationsCountQueryVariables>(GetUserAppNotificationsCountDocument, options);
        }
export type GetUserAppNotificationsCountQueryHookResult = ReturnType<typeof useGetUserAppNotificationsCountQuery>;
export type GetUserAppNotificationsCountLazyQueryHookResult = ReturnType<typeof useGetUserAppNotificationsCountLazyQuery>;
export type GetUserAppNotificationsCountQueryResult = Apollo.QueryResult<Types.GetUserAppNotificationsCountQuery, Types.GetUserAppNotificationsCountQueryVariables>;
export const GetUserUnreadAppNotificationsCountDocument = gql`
    query getUserUnreadAppNotificationsCount($contextId: bigint!) {
  aggregateProxy(
    type: NumberOfUnreadAppNotifications
    variables: {context_id: $contextId}
  )
}
    `;

/**
 * __useGetUserUnreadAppNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetUserUnreadAppNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserUnreadAppNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserUnreadAppNotificationsCountQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetUserUnreadAppNotificationsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserUnreadAppNotificationsCountQuery, Types.GetUserUnreadAppNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserUnreadAppNotificationsCountQuery, Types.GetUserUnreadAppNotificationsCountQueryVariables>(GetUserUnreadAppNotificationsCountDocument, options);
      }
export function useGetUserUnreadAppNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserUnreadAppNotificationsCountQuery, Types.GetUserUnreadAppNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserUnreadAppNotificationsCountQuery, Types.GetUserUnreadAppNotificationsCountQueryVariables>(GetUserUnreadAppNotificationsCountDocument, options);
        }
export type GetUserUnreadAppNotificationsCountQueryHookResult = ReturnType<typeof useGetUserUnreadAppNotificationsCountQuery>;
export type GetUserUnreadAppNotificationsCountLazyQueryHookResult = ReturnType<typeof useGetUserUnreadAppNotificationsCountLazyQuery>;
export type GetUserUnreadAppNotificationsCountQueryResult = Apollo.QueryResult<Types.GetUserUnreadAppNotificationsCountQuery, Types.GetUserUnreadAppNotificationsCountQueryVariables>;
export const GetRecognitionsCountDocument = gql`
    query getRecognitionsCount($context_id: bigint!) {
  aggregateProxy(
    type: FollowersCount
    variables: {artist_context_id: $context_id}
  )
}
    `;

/**
 * __useGetRecognitionsCountQuery__
 *
 * To run a query within a React component, call `useGetRecognitionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecognitionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecognitionsCountQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetRecognitionsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRecognitionsCountQuery, Types.GetRecognitionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRecognitionsCountQuery, Types.GetRecognitionsCountQueryVariables>(GetRecognitionsCountDocument, options);
      }
export function useGetRecognitionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRecognitionsCountQuery, Types.GetRecognitionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRecognitionsCountQuery, Types.GetRecognitionsCountQueryVariables>(GetRecognitionsCountDocument, options);
        }
export type GetRecognitionsCountQueryHookResult = ReturnType<typeof useGetRecognitionsCountQuery>;
export type GetRecognitionsCountLazyQueryHookResult = ReturnType<typeof useGetRecognitionsCountLazyQuery>;
export type GetRecognitionsCountQueryResult = Apollo.QueryResult<Types.GetRecognitionsCountQuery, Types.GetRecognitionsCountQueryVariables>;
export const GetTop6AdmirationsAvatarsByContextIdForNotifCenterDocument = gql`
    query getTop6AdmirationsAvatarsByContextIdForNotifCenter($context_id: bigint!) {
  Social_graph_followers(
    where: {context_id: {_eq: $context_id}, state: {_eq: ACTIVE}}
    order_by: {selected_order: asc}
    limit: 6
  ) {
    id
    Social_graph_followers_Target_Context {
      profile {
        id
        avatar
        title
      }
    }
  }
}
    `;

/**
 * __useGetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery__
 *
 * To run a query within a React component, call `useGetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery, Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery, Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQueryVariables>(GetTop6AdmirationsAvatarsByContextIdForNotifCenterDocument, options);
      }
export function useGetTop6AdmirationsAvatarsByContextIdForNotifCenterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery, Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery, Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQueryVariables>(GetTop6AdmirationsAvatarsByContextIdForNotifCenterDocument, options);
        }
export type GetTop6AdmirationsAvatarsByContextIdForNotifCenterQueryHookResult = ReturnType<typeof useGetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery>;
export type GetTop6AdmirationsAvatarsByContextIdForNotifCenterLazyQueryHookResult = ReturnType<typeof useGetTop6AdmirationsAvatarsByContextIdForNotifCenterLazyQuery>;
export type GetTop6AdmirationsAvatarsByContextIdForNotifCenterQueryResult = Apollo.QueryResult<Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQuery, Types.GetTop6AdmirationsAvatarsByContextIdForNotifCenterQueryVariables>;
export const GetRecognizeModalDetailsDocument = gql`
    query GetRecognizeModalDetails($userIds: [bigint!], $skipCollectors: Boolean!, $skipArtists: Boolean!, $skipGalleries: Boolean!) {
  Artists: Artist_explore_info(where: {artist_context_id: {_in: $userIds}}) @skip(if: $skipArtists) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    firstName: first_name
    lastName: last_name
    isPublic: is_public
  }
  Collectors: Collector_explore_info(
    where: {collector_context_id: {_in: $userIds}}
  ) @skip(if: $skipCollectors) {
    contextId: collector_context_id
    profileId: collector_profile_id
    title
    handle
    avatar
    firstName: first_name
    lastName: last_name
    isPublic: is_public
  }
  Galleries: Gallery_explore_info(where: {gallery_context_id: {_in: $userIds}}) @skip(if: $skipGalleries) {
    contextId: gallery_context_id
    profileId: gallery_profile_id
    title
    handle
    avatar
    firstName: first_name
    lastName: last_name
    isPublic: is_public
  }
  Artworks: artist_cardgrid_artworks_view(
    where: {rank: {_lte: 3}, artist_context_id: {_in: $userIds}}
  ) @skip(if: $skipArtists) {
    id
    artistContextId: artist_context_id
    thumbnailMetadataId: thumbnail_metadata_id
    rank
  }
}
    `;

/**
 * __useGetRecognizeModalDetailsQuery__
 *
 * To run a query within a React component, call `useGetRecognizeModalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecognizeModalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecognizeModalDetailsQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      skipCollectors: // value for 'skipCollectors'
 *      skipArtists: // value for 'skipArtists'
 *      skipGalleries: // value for 'skipGalleries'
 *   },
 * });
 */
export function useGetRecognizeModalDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRecognizeModalDetailsQuery, Types.GetRecognizeModalDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRecognizeModalDetailsQuery, Types.GetRecognizeModalDetailsQueryVariables>(GetRecognizeModalDetailsDocument, options);
      }
export function useGetRecognizeModalDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRecognizeModalDetailsQuery, Types.GetRecognizeModalDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRecognizeModalDetailsQuery, Types.GetRecognizeModalDetailsQueryVariables>(GetRecognizeModalDetailsDocument, options);
        }
export type GetRecognizeModalDetailsQueryHookResult = ReturnType<typeof useGetRecognizeModalDetailsQuery>;
export type GetRecognizeModalDetailsLazyQueryHookResult = ReturnType<typeof useGetRecognizeModalDetailsLazyQuery>;
export type GetRecognizeModalDetailsQueryResult = Apollo.QueryResult<Types.GetRecognizeModalDetailsQuery, Types.GetRecognizeModalDetailsQueryVariables>;
export const GetRecognitionModalListingDocument = gql`
    query GetRecognitionModalListing($limit: Int!, $appliedFilters: Social_graph_followers_view_bool_exp!) {
  users: Social_graph_followers_view(
    where: $appliedFilters
    order_by: [{context_profile_is_private: asc}, {updated_at: desc}]
    limit: $limit
  ) {
    recognizedByType: context_type
    recognizedAt: updated_at
    recognizedBy: context_id
  }
}
    `;

/**
 * __useGetRecognitionModalListingQuery__
 *
 * To run a query within a React component, call `useGetRecognitionModalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecognitionModalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecognitionModalListingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetRecognitionModalListingQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRecognitionModalListingQuery, Types.GetRecognitionModalListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRecognitionModalListingQuery, Types.GetRecognitionModalListingQueryVariables>(GetRecognitionModalListingDocument, options);
      }
export function useGetRecognitionModalListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRecognitionModalListingQuery, Types.GetRecognitionModalListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRecognitionModalListingQuery, Types.GetRecognitionModalListingQueryVariables>(GetRecognitionModalListingDocument, options);
        }
export type GetRecognitionModalListingQueryHookResult = ReturnType<typeof useGetRecognitionModalListingQuery>;
export type GetRecognitionModalListingLazyQueryHookResult = ReturnType<typeof useGetRecognitionModalListingLazyQuery>;
export type GetRecognitionModalListingQueryResult = Apollo.QueryResult<Types.GetRecognitionModalListingQuery, Types.GetRecognitionModalListingQueryVariables>;
export const GetArtistFollowersCountDocument = gql`
    query getArtistFollowersCount($artistContextId: bigint!) {
  getFollowersCount(artist_context_id: $artistContextId) {
    followers
    artistFollowers
    collectorFollowers
  }
}
    `;

/**
 * __useGetArtistFollowersCountQuery__
 *
 * To run a query within a React component, call `useGetArtistFollowersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistFollowersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistFollowersCountQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetArtistFollowersCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistFollowersCountQuery, Types.GetArtistFollowersCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistFollowersCountQuery, Types.GetArtistFollowersCountQueryVariables>(GetArtistFollowersCountDocument, options);
      }
export function useGetArtistFollowersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistFollowersCountQuery, Types.GetArtistFollowersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistFollowersCountQuery, Types.GetArtistFollowersCountQueryVariables>(GetArtistFollowersCountDocument, options);
        }
export type GetArtistFollowersCountQueryHookResult = ReturnType<typeof useGetArtistFollowersCountQuery>;
export type GetArtistFollowersCountLazyQueryHookResult = ReturnType<typeof useGetArtistFollowersCountLazyQuery>;
export type GetArtistFollowersCountQueryResult = Apollo.QueryResult<Types.GetArtistFollowersCountQuery, Types.GetArtistFollowersCountQueryVariables>;
export const GetFollowingListingDocument = gql`
    query GetFollowingListing($limit: Int!, $appliedFilters: Social_graph_followers_view_bool_exp!) {
  users: Social_graph_followers_view(
    where: $appliedFilters
    order_by: [{context_profile_is_private: asc}, {updated_at: desc}]
    limit: $limit
  ) {
    context_type: target_context_type
    recognizedAt: updated_at
    context_id: target_context_id
  }
}
    `;

/**
 * __useGetFollowingListingQuery__
 *
 * To run a query within a React component, call `useGetFollowingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowingListingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetFollowingListingQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFollowingListingQuery, Types.GetFollowingListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFollowingListingQuery, Types.GetFollowingListingQueryVariables>(GetFollowingListingDocument, options);
      }
export function useGetFollowingListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFollowingListingQuery, Types.GetFollowingListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFollowingListingQuery, Types.GetFollowingListingQueryVariables>(GetFollowingListingDocument, options);
        }
export type GetFollowingListingQueryHookResult = ReturnType<typeof useGetFollowingListingQuery>;
export type GetFollowingListingLazyQueryHookResult = ReturnType<typeof useGetFollowingListingLazyQuery>;
export type GetFollowingListingQueryResult = Apollo.QueryResult<Types.GetFollowingListingQuery, Types.GetFollowingListingQueryVariables>;
export const GetFollowersListingDocument = gql`
    query GetFollowersListing($limit: Int!, $appliedFilters: Social_graph_followers_view_bool_exp!) {
  users: Social_graph_followers_view(
    where: $appliedFilters
    order_by: [{context_profile_is_private: asc}, {updated_at: desc}]
    limit: $limit
  ) {
    context_type: context_type
    recognizedAt: updated_at
    context_id: context_id
  }
}
    `;

/**
 * __useGetFollowersListingQuery__
 *
 * To run a query within a React component, call `useGetFollowersListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowersListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowersListingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetFollowersListingQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFollowersListingQuery, Types.GetFollowersListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFollowersListingQuery, Types.GetFollowersListingQueryVariables>(GetFollowersListingDocument, options);
      }
export function useGetFollowersListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFollowersListingQuery, Types.GetFollowersListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFollowersListingQuery, Types.GetFollowersListingQueryVariables>(GetFollowersListingDocument, options);
        }
export type GetFollowersListingQueryHookResult = ReturnType<typeof useGetFollowersListingQuery>;
export type GetFollowersListingLazyQueryHookResult = ReturnType<typeof useGetFollowersListingLazyQuery>;
export type GetFollowersListingQueryResult = Apollo.QueryResult<Types.GetFollowersListingQuery, Types.GetFollowersListingQueryVariables>;
export const GetTutorialStepsDocument = gql`
    query GetTutorialSteps($tutorial_type: ENUM_tutorial_type_enum!) {
  Tutorial(where: {type: {_eq: $tutorial_type}, disabled: {_eq: false}}) {
    id
    type
    skippable
    tutorial_steps(order_by: {order: asc}) {
      id
      type
      order
    }
    Context_to_tutorials {
      id
      context_id
      tutorial_id
      current_step
      status
    }
  }
}
    `;

/**
 * __useGetTutorialStepsQuery__
 *
 * To run a query within a React component, call `useGetTutorialStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTutorialStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTutorialStepsQuery({
 *   variables: {
 *      tutorial_type: // value for 'tutorial_type'
 *   },
 * });
 */
export function useGetTutorialStepsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTutorialStepsQuery, Types.GetTutorialStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTutorialStepsQuery, Types.GetTutorialStepsQueryVariables>(GetTutorialStepsDocument, options);
      }
export function useGetTutorialStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTutorialStepsQuery, Types.GetTutorialStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTutorialStepsQuery, Types.GetTutorialStepsQueryVariables>(GetTutorialStepsDocument, options);
        }
export type GetTutorialStepsQueryHookResult = ReturnType<typeof useGetTutorialStepsQuery>;
export type GetTutorialStepsLazyQueryHookResult = ReturnType<typeof useGetTutorialStepsLazyQuery>;
export type GetTutorialStepsQueryResult = Apollo.QueryResult<Types.GetTutorialStepsQuery, Types.GetTutorialStepsQueryVariables>;
export const UpsertContextTutorialDocument = gql`
    mutation UpsertContextTutorial($status_or_step: String!, $tutorial_id: Int!) {
  upsertContextTutorial(
    status_or_step: $status_or_step
    tutorial_id: $tutorial_id
  )
}
    `;
export type UpsertContextTutorialMutationFn = Apollo.MutationFunction<Types.UpsertContextTutorialMutation, Types.UpsertContextTutorialMutationVariables>;

/**
 * __useUpsertContextTutorialMutation__
 *
 * To run a mutation, you first call `useUpsertContextTutorialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContextTutorialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContextTutorialMutation, { data, loading, error }] = useUpsertContextTutorialMutation({
 *   variables: {
 *      status_or_step: // value for 'status_or_step'
 *      tutorial_id: // value for 'tutorial_id'
 *   },
 * });
 */
export function useUpsertContextTutorialMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertContextTutorialMutation, Types.UpsertContextTutorialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertContextTutorialMutation, Types.UpsertContextTutorialMutationVariables>(UpsertContextTutorialDocument, options);
      }
export type UpsertContextTutorialMutationHookResult = ReturnType<typeof useUpsertContextTutorialMutation>;
export type UpsertContextTutorialMutationResult = Apollo.MutationResult<Types.UpsertContextTutorialMutation>;
export type UpsertContextTutorialMutationOptions = Apollo.BaseMutationOptions<Types.UpsertContextTutorialMutation, Types.UpsertContextTutorialMutationVariables>;
export const GetContextFlagDocument = gql`
    query getContextFlag($contextId: bigint!, $flag: ENUM_flags_type_enum!) {
  flag: Context_flags_by_pk(context_id: $contextId, flag_type: $flag) {
    contextId: context_id
    type: flag_type
    value: flag_value
  }
}
    `;

/**
 * __useGetContextFlagQuery__
 *
 * To run a query within a React component, call `useGetContextFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContextFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContextFlagQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      flag: // value for 'flag'
 *   },
 * });
 */
export function useGetContextFlagQuery(baseOptions: Apollo.QueryHookOptions<Types.GetContextFlagQuery, Types.GetContextFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetContextFlagQuery, Types.GetContextFlagQueryVariables>(GetContextFlagDocument, options);
      }
export function useGetContextFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContextFlagQuery, Types.GetContextFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetContextFlagQuery, Types.GetContextFlagQueryVariables>(GetContextFlagDocument, options);
        }
export type GetContextFlagQueryHookResult = ReturnType<typeof useGetContextFlagQuery>;
export type GetContextFlagLazyQueryHookResult = ReturnType<typeof useGetContextFlagLazyQuery>;
export type GetContextFlagQueryResult = Apollo.QueryResult<Types.GetContextFlagQuery, Types.GetContextFlagQueryVariables>;
export const SetContextFlagDocument = gql`
    mutation setContextFlag($contextId: bigint!, $flag: String!, $value: String!) {
  upsertContextFlag(context_id: $contextId, flag_type: $flag, flag_value: $value)
}
    `;
export type SetContextFlagMutationFn = Apollo.MutationFunction<Types.SetContextFlagMutation, Types.SetContextFlagMutationVariables>;

/**
 * __useSetContextFlagMutation__
 *
 * To run a mutation, you first call `useSetContextFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetContextFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setContextFlagMutation, { data, loading, error }] = useSetContextFlagMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      flag: // value for 'flag'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetContextFlagMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetContextFlagMutation, Types.SetContextFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetContextFlagMutation, Types.SetContextFlagMutationVariables>(SetContextFlagDocument, options);
      }
export type SetContextFlagMutationHookResult = ReturnType<typeof useSetContextFlagMutation>;
export type SetContextFlagMutationResult = Apollo.MutationResult<Types.SetContextFlagMutation>;
export type SetContextFlagMutationOptions = Apollo.BaseMutationOptions<Types.SetContextFlagMutation, Types.SetContextFlagMutationVariables>;
export const GetArtworkExternalBuyLinkDocument = gql`
    query GetArtworkExternalBuyLink($artworkId: bigint!) {
  artwork: Artwork_external_buy_link_by_pk(artwork_id: $artworkId) {
    externalLink: external_link
  }
}
    `;

/**
 * __useGetArtworkExternalBuyLinkQuery__
 *
 * To run a query within a React component, call `useGetArtworkExternalBuyLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkExternalBuyLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkExternalBuyLinkQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetArtworkExternalBuyLinkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkExternalBuyLinkQuery, Types.GetArtworkExternalBuyLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkExternalBuyLinkQuery, Types.GetArtworkExternalBuyLinkQueryVariables>(GetArtworkExternalBuyLinkDocument, options);
      }
export function useGetArtworkExternalBuyLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkExternalBuyLinkQuery, Types.GetArtworkExternalBuyLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkExternalBuyLinkQuery, Types.GetArtworkExternalBuyLinkQueryVariables>(GetArtworkExternalBuyLinkDocument, options);
        }
export type GetArtworkExternalBuyLinkQueryHookResult = ReturnType<typeof useGetArtworkExternalBuyLinkQuery>;
export type GetArtworkExternalBuyLinkLazyQueryHookResult = ReturnType<typeof useGetArtworkExternalBuyLinkLazyQuery>;
export type GetArtworkExternalBuyLinkQueryResult = Apollo.QueryResult<Types.GetArtworkExternalBuyLinkQuery, Types.GetArtworkExternalBuyLinkQueryVariables>;
export const GetUserProfilesDocument = gql`
    query GetUserProfiles($id: bigint!) {
  Flat_context_access: Context_delegate_access(
    where: {receiver_id: {_eq: $id}, granter: {_and: [{type: {_in: [ARTIST, COLLECTOR, GALLERY]}}, {profile: {status: {_nin: [REJECTED]}}}]}}
  ) {
    role
    granter_id
    receiver_id
    granter {
      id
      type
      state
      latest_setup_step
      profile_selected_price_ranges {
        price_range_id
      }
      profile {
        id
        kard_banner
        avatar
        profile_theme
        first_name
        last_name
        is_public
        quote
        statement
        status
        title
        description
        handle
        has_used_card
        email
      }
      profile_social_links {
        URL
        id
        platform
        custom_link
      }
      locations {
        name
        country
        city
        city_id
        address
        zip_code
        location
        location_map_id
        id
      }
      profile_settings {
        id
        preferred_language
        currency
        crop_image
      }
      profile_phones(where: {deleted_at: {_is_null: true}}) {
        phone
      }
    }
  }
  Context_by_pk(id: $id) {
    id
    state
    features(where: {expires_at: {_gte: "now()"}, created_at: {_lte: "now()"}}) {
      feature_flag
      flag_status
      created_at
      expires_at
    }
    auth_context_accesses {
      auth {
        preferred_language
      }
    }
  }
}
    `;

/**
 * __useGetUserProfilesQuery__
 *
 * To run a query within a React component, call `useGetUserProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserProfilesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserProfilesQuery, Types.GetUserProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserProfilesQuery, Types.GetUserProfilesQueryVariables>(GetUserProfilesDocument, options);
      }
export function useGetUserProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserProfilesQuery, Types.GetUserProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserProfilesQuery, Types.GetUserProfilesQueryVariables>(GetUserProfilesDocument, options);
        }
export type GetUserProfilesQueryHookResult = ReturnType<typeof useGetUserProfilesQuery>;
export type GetUserProfilesLazyQueryHookResult = ReturnType<typeof useGetUserProfilesLazyQuery>;
export type GetUserProfilesQueryResult = Apollo.QueryResult<Types.GetUserProfilesQuery, Types.GetUserProfilesQueryVariables>;
export const FirebaseSignUpWithVerificationDocument = gql`
    mutation FirebaseSignUpWithVerification($token: String!, $first_name: String!, $last_name: String!, $password: String!, $language: String!, $email: String, $tokenId: Int) {
  firebaseSignUpWithVerification(
    token: $token
    first_name: $first_name
    last_name: $last_name
    password: $password
    preferred_language: $language
    email: $email
    tokenId: $tokenId
  ) {
    valid
    message
    email
  }
}
    `;
export type FirebaseSignUpWithVerificationMutationFn = Apollo.MutationFunction<Types.FirebaseSignUpWithVerificationMutation, Types.FirebaseSignUpWithVerificationMutationVariables>;

/**
 * __useFirebaseSignUpWithVerificationMutation__
 *
 * To run a mutation, you first call `useFirebaseSignUpWithVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFirebaseSignUpWithVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [firebaseSignUpWithVerificationMutation, { data, loading, error }] = useFirebaseSignUpWithVerificationMutation({
 *   variables: {
 *      token: // value for 'token'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      password: // value for 'password'
 *      language: // value for 'language'
 *      email: // value for 'email'
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useFirebaseSignUpWithVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.FirebaseSignUpWithVerificationMutation, Types.FirebaseSignUpWithVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FirebaseSignUpWithVerificationMutation, Types.FirebaseSignUpWithVerificationMutationVariables>(FirebaseSignUpWithVerificationDocument, options);
      }
export type FirebaseSignUpWithVerificationMutationHookResult = ReturnType<typeof useFirebaseSignUpWithVerificationMutation>;
export type FirebaseSignUpWithVerificationMutationResult = Apollo.MutationResult<Types.FirebaseSignUpWithVerificationMutation>;
export type FirebaseSignUpWithVerificationMutationOptions = Apollo.BaseMutationOptions<Types.FirebaseSignUpWithVerificationMutation, Types.FirebaseSignUpWithVerificationMutationVariables>;
export const RequestFirebaseCustomAuthDocument = gql`
    mutation RequestFirebaseCustomAuth($email: String!, $firstName: String, $languagePreference: String, $actionId: Int, $action: String) {
  requestFirebaseCustomAuth(
    email: $email
    firstName: $firstName
    languagePreference: $languagePreference
    actionId: $actionId
    action: $action
  ) {
    message
    status
  }
}
    `;
export type RequestFirebaseCustomAuthMutationFn = Apollo.MutationFunction<Types.RequestFirebaseCustomAuthMutation, Types.RequestFirebaseCustomAuthMutationVariables>;

/**
 * __useRequestFirebaseCustomAuthMutation__
 *
 * To run a mutation, you first call `useRequestFirebaseCustomAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFirebaseCustomAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFirebaseCustomAuthMutation, { data, loading, error }] = useRequestFirebaseCustomAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      languagePreference: // value for 'languagePreference'
 *      actionId: // value for 'actionId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useRequestFirebaseCustomAuthMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestFirebaseCustomAuthMutation, Types.RequestFirebaseCustomAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestFirebaseCustomAuthMutation, Types.RequestFirebaseCustomAuthMutationVariables>(RequestFirebaseCustomAuthDocument, options);
      }
export type RequestFirebaseCustomAuthMutationHookResult = ReturnType<typeof useRequestFirebaseCustomAuthMutation>;
export type RequestFirebaseCustomAuthMutationResult = Apollo.MutationResult<Types.RequestFirebaseCustomAuthMutation>;
export type RequestFirebaseCustomAuthMutationOptions = Apollo.BaseMutationOptions<Types.RequestFirebaseCustomAuthMutation, Types.RequestFirebaseCustomAuthMutationVariables>;
export const CompleteFirebaseCustomAuthDocument = gql`
    mutation CompleteFirebaseCustomAuth($token: String!, $tokenId: Int, $email: String) {
  completeFirebaseCustomAuth(token: $token, tokenId: $tokenId, email: $email) {
    status {
      message
      status
    }
    token
  }
}
    `;
export type CompleteFirebaseCustomAuthMutationFn = Apollo.MutationFunction<Types.CompleteFirebaseCustomAuthMutation, Types.CompleteFirebaseCustomAuthMutationVariables>;

/**
 * __useCompleteFirebaseCustomAuthMutation__
 *
 * To run a mutation, you first call `useCompleteFirebaseCustomAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFirebaseCustomAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFirebaseCustomAuthMutation, { data, loading, error }] = useCompleteFirebaseCustomAuthMutation({
 *   variables: {
 *      token: // value for 'token'
 *      tokenId: // value for 'tokenId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCompleteFirebaseCustomAuthMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteFirebaseCustomAuthMutation, Types.CompleteFirebaseCustomAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteFirebaseCustomAuthMutation, Types.CompleteFirebaseCustomAuthMutationVariables>(CompleteFirebaseCustomAuthDocument, options);
      }
export type CompleteFirebaseCustomAuthMutationHookResult = ReturnType<typeof useCompleteFirebaseCustomAuthMutation>;
export type CompleteFirebaseCustomAuthMutationResult = Apollo.MutationResult<Types.CompleteFirebaseCustomAuthMutation>;
export type CompleteFirebaseCustomAuthMutationOptions = Apollo.BaseMutationOptions<Types.CompleteFirebaseCustomAuthMutation, Types.CompleteFirebaseCustomAuthMutationVariables>;
export const RequestEmailVerificationDocument = gql`
    mutation RequestEmailVerification($email: String!, $firstName: String, $languagePreference: String) {
  requestEmailVerification(
    email: $email
    firstName: $firstName
    languagePreference: $languagePreference
  )
}
    `;
export type RequestEmailVerificationMutationFn = Apollo.MutationFunction<Types.RequestEmailVerificationMutation, Types.RequestEmailVerificationMutationVariables>;

/**
 * __useRequestEmailVerificationMutation__
 *
 * To run a mutation, you first call `useRequestEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailVerificationMutation, { data, loading, error }] = useRequestEmailVerificationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      languagePreference: // value for 'languagePreference'
 *   },
 * });
 */
export function useRequestEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestEmailVerificationMutation, Types.RequestEmailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestEmailVerificationMutation, Types.RequestEmailVerificationMutationVariables>(RequestEmailVerificationDocument, options);
      }
export type RequestEmailVerificationMutationHookResult = ReturnType<typeof useRequestEmailVerificationMutation>;
export type RequestEmailVerificationMutationResult = Apollo.MutationResult<Types.RequestEmailVerificationMutation>;
export type RequestEmailVerificationMutationOptions = Apollo.BaseMutationOptions<Types.RequestEmailVerificationMutation, Types.RequestEmailVerificationMutationVariables>;
export const CompleteEmailVerificationDocument = gql`
    mutation CompleteEmailVerification($token: String!, $tokenId: Int, $email: String) {
  completeEmailVerification(token: $token, tokenId: $tokenId, email: $email) {
    tokenId
    email
  }
}
    `;
export type CompleteEmailVerificationMutationFn = Apollo.MutationFunction<Types.CompleteEmailVerificationMutation, Types.CompleteEmailVerificationMutationVariables>;

/**
 * __useCompleteEmailVerificationMutation__
 *
 * To run a mutation, you first call `useCompleteEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeEmailVerificationMutation, { data, loading, error }] = useCompleteEmailVerificationMutation({
 *   variables: {
 *      token: // value for 'token'
 *      tokenId: // value for 'tokenId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCompleteEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteEmailVerificationMutation, Types.CompleteEmailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteEmailVerificationMutation, Types.CompleteEmailVerificationMutationVariables>(CompleteEmailVerificationDocument, options);
      }
export type CompleteEmailVerificationMutationHookResult = ReturnType<typeof useCompleteEmailVerificationMutation>;
export type CompleteEmailVerificationMutationResult = Apollo.MutationResult<Types.CompleteEmailVerificationMutation>;
export type CompleteEmailVerificationMutationOptions = Apollo.BaseMutationOptions<Types.CompleteEmailVerificationMutation, Types.CompleteEmailVerificationMutationVariables>;
export const CheckValidationCodeDocument = gql`
    query CheckValidationCode($code: String = "", $email: String = "") {
  checkValidationCode(code: $code, email: $email)
}
    `;

/**
 * __useCheckValidationCodeQuery__
 *
 * To run a query within a React component, call `useCheckValidationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckValidationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckValidationCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckValidationCodeQuery(baseOptions?: Apollo.QueryHookOptions<Types.CheckValidationCodeQuery, Types.CheckValidationCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckValidationCodeQuery, Types.CheckValidationCodeQueryVariables>(CheckValidationCodeDocument, options);
      }
export function useCheckValidationCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckValidationCodeQuery, Types.CheckValidationCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckValidationCodeQuery, Types.CheckValidationCodeQueryVariables>(CheckValidationCodeDocument, options);
        }
export type CheckValidationCodeQueryHookResult = ReturnType<typeof useCheckValidationCodeQuery>;
export type CheckValidationCodeLazyQueryHookResult = ReturnType<typeof useCheckValidationCodeLazyQuery>;
export type CheckValidationCodeQueryResult = Apollo.QueryResult<Types.CheckValidationCodeQuery, Types.CheckValidationCodeQueryVariables>;
export const GetDefaultContextDocument = gql`
    query GetDefaultContext {
  default: Setting_context_default_profile {
    contextId: profile_context_id
  }
}
    `;

/**
 * __useGetDefaultContextQuery__
 *
 * To run a query within a React component, call `useGetDefaultContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultContextQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetDefaultContextQuery, Types.GetDefaultContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDefaultContextQuery, Types.GetDefaultContextQueryVariables>(GetDefaultContextDocument, options);
      }
export function useGetDefaultContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDefaultContextQuery, Types.GetDefaultContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDefaultContextQuery, Types.GetDefaultContextQueryVariables>(GetDefaultContextDocument, options);
        }
export type GetDefaultContextQueryHookResult = ReturnType<typeof useGetDefaultContextQuery>;
export type GetDefaultContextLazyQueryHookResult = ReturnType<typeof useGetDefaultContextLazyQuery>;
export type GetDefaultContextQueryResult = Apollo.QueryResult<Types.GetDefaultContextQuery, Types.GetDefaultContextQueryVariables>;
export const SetDefaultContextDocument = gql`
    mutation SetDefaultContext($appAccessId: bigint!, $contextId: bigint!) {
  updateDefaultContextResult: update_Setting_context_default_profile_by_pk(
    pk_columns: {app_access_context_id: $appAccessId}
    _set: {profile_context_id: $contextId}
  ) {
    updatedAt: updated_at
    profileContextId: profile_context_id
  }
}
    `;
export type SetDefaultContextMutationFn = Apollo.MutationFunction<Types.SetDefaultContextMutation, Types.SetDefaultContextMutationVariables>;

/**
 * __useSetDefaultContextMutation__
 *
 * To run a mutation, you first call `useSetDefaultContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultContextMutation, { data, loading, error }] = useSetDefaultContextMutation({
 *   variables: {
 *      appAccessId: // value for 'appAccessId'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useSetDefaultContextMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDefaultContextMutation, Types.SetDefaultContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetDefaultContextMutation, Types.SetDefaultContextMutationVariables>(SetDefaultContextDocument, options);
      }
export type SetDefaultContextMutationHookResult = ReturnType<typeof useSetDefaultContextMutation>;
export type SetDefaultContextMutationResult = Apollo.MutationResult<Types.SetDefaultContextMutation>;
export type SetDefaultContextMutationOptions = Apollo.BaseMutationOptions<Types.SetDefaultContextMutation, Types.SetDefaultContextMutationVariables>;
export const SetLatestContextSetupStepDocument = gql`
    mutation SetLatestContextSetupStep($contextId: bigint!, $step: Int!) {
  updateSetupStepResult: update_Context_by_pk(
    pk_columns: {id: $contextId}
    _set: {latest_setup_step: $step}
  ) {
    latestStep: latest_setup_step
  }
}
    `;
export type SetLatestContextSetupStepMutationFn = Apollo.MutationFunction<Types.SetLatestContextSetupStepMutation, Types.SetLatestContextSetupStepMutationVariables>;

/**
 * __useSetLatestContextSetupStepMutation__
 *
 * To run a mutation, you first call `useSetLatestContextSetupStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLatestContextSetupStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLatestContextSetupStepMutation, { data, loading, error }] = useSetLatestContextSetupStepMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useSetLatestContextSetupStepMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetLatestContextSetupStepMutation, Types.SetLatestContextSetupStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetLatestContextSetupStepMutation, Types.SetLatestContextSetupStepMutationVariables>(SetLatestContextSetupStepDocument, options);
      }
export type SetLatestContextSetupStepMutationHookResult = ReturnType<typeof useSetLatestContextSetupStepMutation>;
export type SetLatestContextSetupStepMutationResult = Apollo.MutationResult<Types.SetLatestContextSetupStepMutation>;
export type SetLatestContextSetupStepMutationOptions = Apollo.BaseMutationOptions<Types.SetLatestContextSetupStepMutation, Types.SetLatestContextSetupStepMutationVariables>;
export const GetCountriesDocument = gql`
    query GetCountries {
  Countries {
    iso2
    iso3
    name
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<Types.GetCountriesQuery, Types.GetCountriesQueryVariables>;
export const GetStatesByCountryDocument = gql`
    query GetStatesByCountry($iso2: String!) {
  states: country_states_mv(where: {iso2: {_eq: $iso2}}) {
    code: admin_code
    displayName: admin_name
    nameAscii: admin_name_ascii
    iso3
    iso2
    country
  }
}
    `;

/**
 * __useGetStatesByCountryQuery__
 *
 * To run a query within a React component, call `useGetStatesByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatesByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatesByCountryQuery({
 *   variables: {
 *      iso2: // value for 'iso2'
 *   },
 * });
 */
export function useGetStatesByCountryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetStatesByCountryQuery, Types.GetStatesByCountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetStatesByCountryQuery, Types.GetStatesByCountryQueryVariables>(GetStatesByCountryDocument, options);
      }
export function useGetStatesByCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetStatesByCountryQuery, Types.GetStatesByCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetStatesByCountryQuery, Types.GetStatesByCountryQueryVariables>(GetStatesByCountryDocument, options);
        }
export type GetStatesByCountryQueryHookResult = ReturnType<typeof useGetStatesByCountryQuery>;
export type GetStatesByCountryLazyQueryHookResult = ReturnType<typeof useGetStatesByCountryLazyQuery>;
export type GetStatesByCountryQueryResult = Apollo.QueryResult<Types.GetStatesByCountryQuery, Types.GetStatesByCountryQueryVariables>;
export const GetCitiesDocument = gql`
    query GetCities($where: worldcities_bool_exp!) {
  worldcities(where: $where) {
    id
    name: city
    alternateNames: city_alt
    nameAscii: city_ascii
    lat
    lng
    timezone
    population
    ranking
    hasSameName: same_name
    capital
    country: iso2
    stateName: admin_name
    stateNameAscii: admin_name_ascii
    stateCode: admin_code
    Country {
      iso3
      iso2
      name
    }
  }
}
    `;

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCitiesQuery, Types.GetCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCitiesQuery, Types.GetCitiesQueryVariables>(GetCitiesDocument, options);
      }
export function useGetCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCitiesQuery, Types.GetCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCitiesQuery, Types.GetCitiesQueryVariables>(GetCitiesDocument, options);
        }
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<typeof useGetCitiesLazyQuery>;
export type GetCitiesQueryResult = Apollo.QueryResult<Types.GetCitiesQuery, Types.GetCitiesQueryVariables>;
export const UpdateCertificateOfAuthenticityDocument = gql`
    mutation UpdateCertificateOfAuthenticity($artPieceId: bigint!, $pdf: Boolean = false) {
  updateCertificateOfAuthenticity(artPieceId: $artPieceId, pdf: $pdf)
}
    `;
export type UpdateCertificateOfAuthenticityMutationFn = Apollo.MutationFunction<Types.UpdateCertificateOfAuthenticityMutation, Types.UpdateCertificateOfAuthenticityMutationVariables>;

/**
 * __useUpdateCertificateOfAuthenticityMutation__
 *
 * To run a mutation, you first call `useUpdateCertificateOfAuthenticityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCertificateOfAuthenticityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCertificateOfAuthenticityMutation, { data, loading, error }] = useUpdateCertificateOfAuthenticityMutation({
 *   variables: {
 *      artPieceId: // value for 'artPieceId'
 *      pdf: // value for 'pdf'
 *   },
 * });
 */
export function useUpdateCertificateOfAuthenticityMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCertificateOfAuthenticityMutation, Types.UpdateCertificateOfAuthenticityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCertificateOfAuthenticityMutation, Types.UpdateCertificateOfAuthenticityMutationVariables>(UpdateCertificateOfAuthenticityDocument, options);
      }
export type UpdateCertificateOfAuthenticityMutationHookResult = ReturnType<typeof useUpdateCertificateOfAuthenticityMutation>;
export type UpdateCertificateOfAuthenticityMutationResult = Apollo.MutationResult<Types.UpdateCertificateOfAuthenticityMutation>;
export type UpdateCertificateOfAuthenticityMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCertificateOfAuthenticityMutation, Types.UpdateCertificateOfAuthenticityMutationVariables>;
export const GetContentTranslationDocument = gql`
    mutation getContentTranslation($entityId: Int!, $contentType: String!, $targetLanguage: String!, $content: String!, $secondaryEntityId: Int = 0) {
  getContentTranslation(
    entity_id: $entityId
    content_type: $contentType
    target_language: $targetLanguage
    content: $content
    secondary_entity_id: $secondaryEntityId
  )
}
    `;
export type GetContentTranslationMutationFn = Apollo.MutationFunction<Types.GetContentTranslationMutation, Types.GetContentTranslationMutationVariables>;

/**
 * __useGetContentTranslationMutation__
 *
 * To run a mutation, you first call `useGetContentTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetContentTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getContentTranslationMutation, { data, loading, error }] = useGetContentTranslationMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      contentType: // value for 'contentType'
 *      targetLanguage: // value for 'targetLanguage'
 *      content: // value for 'content'
 *      secondaryEntityId: // value for 'secondaryEntityId'
 *   },
 * });
 */
export function useGetContentTranslationMutation(baseOptions?: Apollo.MutationHookOptions<Types.GetContentTranslationMutation, Types.GetContentTranslationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GetContentTranslationMutation, Types.GetContentTranslationMutationVariables>(GetContentTranslationDocument, options);
      }
export type GetContentTranslationMutationHookResult = ReturnType<typeof useGetContentTranslationMutation>;
export type GetContentTranslationMutationResult = Apollo.MutationResult<Types.GetContentTranslationMutation>;
export type GetContentTranslationMutationOptions = Apollo.BaseMutationOptions<Types.GetContentTranslationMutation, Types.GetContentTranslationMutationVariables>;
export const GetCurrencyConversionDocument = gql`
    query getCurrencyConversion {
  Currency_conversion {
    currency
    rate
  }
}
    `;

/**
 * __useGetCurrencyConversionQuery__
 *
 * To run a query within a React component, call `useGetCurrencyConversionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyConversionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyConversionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrencyConversionQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCurrencyConversionQuery, Types.GetCurrencyConversionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCurrencyConversionQuery, Types.GetCurrencyConversionQueryVariables>(GetCurrencyConversionDocument, options);
      }
export function useGetCurrencyConversionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCurrencyConversionQuery, Types.GetCurrencyConversionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCurrencyConversionQuery, Types.GetCurrencyConversionQueryVariables>(GetCurrencyConversionDocument, options);
        }
export type GetCurrencyConversionQueryHookResult = ReturnType<typeof useGetCurrencyConversionQuery>;
export type GetCurrencyConversionLazyQueryHookResult = ReturnType<typeof useGetCurrencyConversionLazyQuery>;
export type GetCurrencyConversionQueryResult = Apollo.QueryResult<Types.GetCurrencyConversionQuery, Types.GetCurrencyConversionQueryVariables>;
export const GetArtUniverseArtistsDocument = gql`
    query getArtUniverseArtists {
  listing: Artist_vision_listing {
    contextId: artist_context_id
    profileId: artist_profile_id
  }
}
    `;

/**
 * __useGetArtUniverseArtistsQuery__
 *
 * To run a query within a React component, call `useGetArtUniverseArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtUniverseArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtUniverseArtistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArtUniverseArtistsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtUniverseArtistsQuery, Types.GetArtUniverseArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtUniverseArtistsQuery, Types.GetArtUniverseArtistsQueryVariables>(GetArtUniverseArtistsDocument, options);
      }
export function useGetArtUniverseArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtUniverseArtistsQuery, Types.GetArtUniverseArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtUniverseArtistsQuery, Types.GetArtUniverseArtistsQueryVariables>(GetArtUniverseArtistsDocument, options);
        }
export type GetArtUniverseArtistsQueryHookResult = ReturnType<typeof useGetArtUniverseArtistsQuery>;
export type GetArtUniverseArtistsLazyQueryHookResult = ReturnType<typeof useGetArtUniverseArtistsLazyQuery>;
export type GetArtUniverseArtistsQueryResult = Apollo.QueryResult<Types.GetArtUniverseArtistsQuery, Types.GetArtUniverseArtistsQueryVariables>;
export const ProfileArtworksListingDocument = gql`
    query ProfileArtworksListing($id: bigint!, $availability: Artwork_bool_exp!, $series: Artwork_bool_exp!, $years: Artwork_bool_exp!, $orderBy: Artwork_order_by!, $collectionType: ENUM_collection_type_enum) {
  Artwork(
    where: {artist_context: {profile: {id: {_eq: $id}}}, _and: [$availability, $series, $years], is_public: {_eq: true}}
    order_by: [$orderBy]
  ) {
    ...ProfileArtworksListingContent
  }
}
    ${ProfileArtworksListingContentFragmentDoc}`;

/**
 * __useProfileArtworksListingQuery__
 *
 * To run a query within a React component, call `useProfileArtworksListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileArtworksListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileArtworksListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *      availability: // value for 'availability'
 *      series: // value for 'series'
 *      years: // value for 'years'
 *      orderBy: // value for 'orderBy'
 *      collectionType: // value for 'collectionType'
 *   },
 * });
 */
export function useProfileArtworksListingQuery(baseOptions: Apollo.QueryHookOptions<Types.ProfileArtworksListingQuery, Types.ProfileArtworksListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfileArtworksListingQuery, Types.ProfileArtworksListingQueryVariables>(ProfileArtworksListingDocument, options);
      }
export function useProfileArtworksListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileArtworksListingQuery, Types.ProfileArtworksListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfileArtworksListingQuery, Types.ProfileArtworksListingQueryVariables>(ProfileArtworksListingDocument, options);
        }
export type ProfileArtworksListingQueryHookResult = ReturnType<typeof useProfileArtworksListingQuery>;
export type ProfileArtworksListingLazyQueryHookResult = ReturnType<typeof useProfileArtworksListingLazyQuery>;
export type ProfileArtworksListingQueryResult = Apollo.QueryResult<Types.ProfileArtworksListingQuery, Types.ProfileArtworksListingQueryVariables>;
export const GetArtistInformationByHandleDocument = gql`
    query GetArtistInformationByHandle($handle: String!) {
  Profile(where: {handle: {_ilike: $handle}, context: {type: {_eq: ARTIST}}}) {
    id
    avatar
    handle
    profile_theme
    first_name
    last_name
    title
    quote
    statement
    avatar
    cv_download_link
    audio_download_link
    Valid_profile_statement_audio_syntheses {
      audio_id
      profile_id
      language
    }
    avatar_metadata {
      id
      extra
    }
    context {
      id
      locations {
        country
        city
      }
      profile_social_links {
        platform
        URL
        custom_link
      }
      collaboration_by_artist_id {
        gallery_context {
          profile {
            id
            banner
            kard_banner
            title
            handle
          }
        }
      }
    }
    ...ProfileAppearanceSettings
  }
}
    ${ProfileAppearanceSettingsFragmentDoc}`;

/**
 * __useGetArtistInformationByHandleQuery__
 *
 * To run a query within a React component, call `useGetArtistInformationByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistInformationByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistInformationByHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetArtistInformationByHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistInformationByHandleQuery, Types.GetArtistInformationByHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistInformationByHandleQuery, Types.GetArtistInformationByHandleQueryVariables>(GetArtistInformationByHandleDocument, options);
      }
export function useGetArtistInformationByHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistInformationByHandleQuery, Types.GetArtistInformationByHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistInformationByHandleQuery, Types.GetArtistInformationByHandleQueryVariables>(GetArtistInformationByHandleDocument, options);
        }
export type GetArtistInformationByHandleQueryHookResult = ReturnType<typeof useGetArtistInformationByHandleQuery>;
export type GetArtistInformationByHandleLazyQueryHookResult = ReturnType<typeof useGetArtistInformationByHandleLazyQuery>;
export type GetArtistInformationByHandleQueryResult = Apollo.QueryResult<Types.GetArtistInformationByHandleQuery, Types.GetArtistInformationByHandleQueryVariables>;
export const DeleteContentTranslationDocument = gql`
    mutation DeleteContentTranslation($entityId: Int!, $contentType: String!, $secondaryEntityId: Int = 0) {
  deleteContentTranslation(
    entityId: $entityId
    contentType: $contentType
    secondaryEntityId: $secondaryEntityId
  )
}
    `;
export type DeleteContentTranslationMutationFn = Apollo.MutationFunction<Types.DeleteContentTranslationMutation, Types.DeleteContentTranslationMutationVariables>;

/**
 * __useDeleteContentTranslationMutation__
 *
 * To run a mutation, you first call `useDeleteContentTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentTranslationMutation, { data, loading, error }] = useDeleteContentTranslationMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      contentType: // value for 'contentType'
 *      secondaryEntityId: // value for 'secondaryEntityId'
 *   },
 * });
 */
export function useDeleteContentTranslationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteContentTranslationMutation, Types.DeleteContentTranslationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteContentTranslationMutation, Types.DeleteContentTranslationMutationVariables>(DeleteContentTranslationDocument, options);
      }
export type DeleteContentTranslationMutationHookResult = ReturnType<typeof useDeleteContentTranslationMutation>;
export type DeleteContentTranslationMutationResult = Apollo.MutationResult<Types.DeleteContentTranslationMutation>;
export type DeleteContentTranslationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteContentTranslationMutation, Types.DeleteContentTranslationMutationVariables>;
export const GetAppearanceSettingsByHandleDocument = gql`
    query getAppearanceSettingsByHandle($handle: String!) {
  profile: Profile(where: {handle: {_ilike: $handle}}) {
    id
    handle
    theme: profile_theme
    context {
      id
      type
    }
  }
  gridAppearanceSettings: Profile_appearance_settings(
    where: {profile: {handle: {_ilike: $handle}}}
  ) {
    id
    columnCount: profile_columns_count
    layoutType: profile_layout_type
  }
}
    `;

/**
 * __useGetAppearanceSettingsByHandleQuery__
 *
 * To run a query within a React component, call `useGetAppearanceSettingsByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppearanceSettingsByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppearanceSettingsByHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetAppearanceSettingsByHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAppearanceSettingsByHandleQuery, Types.GetAppearanceSettingsByHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAppearanceSettingsByHandleQuery, Types.GetAppearanceSettingsByHandleQueryVariables>(GetAppearanceSettingsByHandleDocument, options);
      }
export function useGetAppearanceSettingsByHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAppearanceSettingsByHandleQuery, Types.GetAppearanceSettingsByHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAppearanceSettingsByHandleQuery, Types.GetAppearanceSettingsByHandleQueryVariables>(GetAppearanceSettingsByHandleDocument, options);
        }
export type GetAppearanceSettingsByHandleQueryHookResult = ReturnType<typeof useGetAppearanceSettingsByHandleQuery>;
export type GetAppearanceSettingsByHandleLazyQueryHookResult = ReturnType<typeof useGetAppearanceSettingsByHandleLazyQuery>;
export type GetAppearanceSettingsByHandleQueryResult = Apollo.QueryResult<Types.GetAppearanceSettingsByHandleQuery, Types.GetAppearanceSettingsByHandleQueryVariables>;
export const GetTotalFollowersFollowingByContextDocument = gql`
    query getTotalFollowersFollowingByContext($contextId: bigint!) {
  collectorsFollowers: aggregateProxy(
    type: CollectorFollowersCount
    variables: {artist_context_id: $contextId}
  )
  artistsFollowers: aggregateProxy(
    type: ArtistFollowersCount
    variables: {artist_context_id: $contextId}
  )
  artistsFollowing: aggregateProxy(
    type: ArtistFollowingCount
    variables: {artist_context_id: $contextId}
  )
  collectorsFollowing: aggregateProxy(
    type: CollectorFollowingCount
    variables: {artist_context_id: $contextId}
  )
}
    `;

/**
 * __useGetTotalFollowersFollowingByContextQuery__
 *
 * To run a query within a React component, call `useGetTotalFollowersFollowingByContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalFollowersFollowingByContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalFollowersFollowingByContextQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetTotalFollowersFollowingByContextQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTotalFollowersFollowingByContextQuery, Types.GetTotalFollowersFollowingByContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTotalFollowersFollowingByContextQuery, Types.GetTotalFollowersFollowingByContextQueryVariables>(GetTotalFollowersFollowingByContextDocument, options);
      }
export function useGetTotalFollowersFollowingByContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTotalFollowersFollowingByContextQuery, Types.GetTotalFollowersFollowingByContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTotalFollowersFollowingByContextQuery, Types.GetTotalFollowersFollowingByContextQueryVariables>(GetTotalFollowersFollowingByContextDocument, options);
        }
export type GetTotalFollowersFollowingByContextQueryHookResult = ReturnType<typeof useGetTotalFollowersFollowingByContextQuery>;
export type GetTotalFollowersFollowingByContextLazyQueryHookResult = ReturnType<typeof useGetTotalFollowersFollowingByContextLazyQuery>;
export type GetTotalFollowersFollowingByContextQueryResult = Apollo.QueryResult<Types.GetTotalFollowersFollowingByContextQuery, Types.GetTotalFollowersFollowingByContextQueryVariables>;
export const GetProfileInterestsBackgroundsDocument = gql`
    query getProfileInterestsBackgrounds($contextId: bigint!) {
  interests: profile_interests_view(where: {context_id: {_eq: $contextId}}) {
    id
    translation_key
  }
  backgrounds: profile_backgrounds_view(where: {context_id: {_eq: $contextId}}) {
    id
    background_type
  }
}
    `;

/**
 * __useGetProfileInterestsBackgroundsQuery__
 *
 * To run a query within a React component, call `useGetProfileInterestsBackgroundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileInterestsBackgroundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileInterestsBackgroundsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileInterestsBackgroundsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileInterestsBackgroundsQuery, Types.GetProfileInterestsBackgroundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileInterestsBackgroundsQuery, Types.GetProfileInterestsBackgroundsQueryVariables>(GetProfileInterestsBackgroundsDocument, options);
      }
export function useGetProfileInterestsBackgroundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileInterestsBackgroundsQuery, Types.GetProfileInterestsBackgroundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileInterestsBackgroundsQuery, Types.GetProfileInterestsBackgroundsQueryVariables>(GetProfileInterestsBackgroundsDocument, options);
        }
export type GetProfileInterestsBackgroundsQueryHookResult = ReturnType<typeof useGetProfileInterestsBackgroundsQuery>;
export type GetProfileInterestsBackgroundsLazyQueryHookResult = ReturnType<typeof useGetProfileInterestsBackgroundsLazyQuery>;
export type GetProfileInterestsBackgroundsQueryResult = Apollo.QueryResult<Types.GetProfileInterestsBackgroundsQuery, Types.GetProfileInterestsBackgroundsQueryVariables>;
export const GetArtistTitleByCtxIdDocument = gql`
    query GetArtistTitleByCtxId($contextId: bigint!) {
  profile: Profile(where: {context: {id: {_eq: $contextId}}}) {
    title
  }
}
    `;

/**
 * __useGetArtistTitleByCtxIdQuery__
 *
 * To run a query within a React component, call `useGetArtistTitleByCtxIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistTitleByCtxIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistTitleByCtxIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistTitleByCtxIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistTitleByCtxIdQuery, Types.GetArtistTitleByCtxIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistTitleByCtxIdQuery, Types.GetArtistTitleByCtxIdQueryVariables>(GetArtistTitleByCtxIdDocument, options);
      }
export function useGetArtistTitleByCtxIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistTitleByCtxIdQuery, Types.GetArtistTitleByCtxIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistTitleByCtxIdQuery, Types.GetArtistTitleByCtxIdQueryVariables>(GetArtistTitleByCtxIdDocument, options);
        }
export type GetArtistTitleByCtxIdQueryHookResult = ReturnType<typeof useGetArtistTitleByCtxIdQuery>;
export type GetArtistTitleByCtxIdLazyQueryHookResult = ReturnType<typeof useGetArtistTitleByCtxIdLazyQuery>;
export type GetArtistTitleByCtxIdQueryResult = Apollo.QueryResult<Types.GetArtistTitleByCtxIdQuery, Types.GetArtistTitleByCtxIdQueryVariables>;
export const GetFlaggedUserCounterDocument = gql`
    query getFlaggedUserCounter($contextId: bigint!) {
  flaggedUsers: Flagged_users(
    where: {context_id: {_eq: $contextId}, flag_type: {_eq: SCAM_MESSAGES}}
  ) {
    counter: infractions_counter
  }
}
    `;

/**
 * __useGetFlaggedUserCounterQuery__
 *
 * To run a query within a React component, call `useGetFlaggedUserCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlaggedUserCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlaggedUserCounterQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetFlaggedUserCounterQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFlaggedUserCounterQuery, Types.GetFlaggedUserCounterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFlaggedUserCounterQuery, Types.GetFlaggedUserCounterQueryVariables>(GetFlaggedUserCounterDocument, options);
      }
export function useGetFlaggedUserCounterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFlaggedUserCounterQuery, Types.GetFlaggedUserCounterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFlaggedUserCounterQuery, Types.GetFlaggedUserCounterQueryVariables>(GetFlaggedUserCounterDocument, options);
        }
export type GetFlaggedUserCounterQueryHookResult = ReturnType<typeof useGetFlaggedUserCounterQuery>;
export type GetFlaggedUserCounterLazyQueryHookResult = ReturnType<typeof useGetFlaggedUserCounterLazyQuery>;
export type GetFlaggedUserCounterQueryResult = Apollo.QueryResult<Types.GetFlaggedUserCounterQuery, Types.GetFlaggedUserCounterQueryVariables>;
export const AuthHandlerCreateAppAccountDocument = gql`
    mutation AuthHandlerCreateAppAccount($email: String!, $first_name: String!, $last_name: String!, $password: String!, $language: String!, $invitationToken: String) {
  firebaseSignUp(
    email: $email
    first_name: $first_name
    last_name: $last_name
    password: $password
    preferred_language: $language
    invitationToken: $invitationToken
  ) {
    valid
    message
  }
}
    `;
export type AuthHandlerCreateAppAccountMutationFn = Apollo.MutationFunction<Types.AuthHandlerCreateAppAccountMutation, Types.AuthHandlerCreateAppAccountMutationVariables>;

/**
 * __useAuthHandlerCreateAppAccountMutation__
 *
 * To run a mutation, you first call `useAuthHandlerCreateAppAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthHandlerCreateAppAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authHandlerCreateAppAccountMutation, { data, loading, error }] = useAuthHandlerCreateAppAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      password: // value for 'password'
 *      language: // value for 'language'
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useAuthHandlerCreateAppAccountMutation(baseOptions?: Apollo.MutationHookOptions<Types.AuthHandlerCreateAppAccountMutation, Types.AuthHandlerCreateAppAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AuthHandlerCreateAppAccountMutation, Types.AuthHandlerCreateAppAccountMutationVariables>(AuthHandlerCreateAppAccountDocument, options);
      }
export type AuthHandlerCreateAppAccountMutationHookResult = ReturnType<typeof useAuthHandlerCreateAppAccountMutation>;
export type AuthHandlerCreateAppAccountMutationResult = Apollo.MutationResult<Types.AuthHandlerCreateAppAccountMutation>;
export type AuthHandlerCreateAppAccountMutationOptions = Apollo.BaseMutationOptions<Types.AuthHandlerCreateAppAccountMutation, Types.AuthHandlerCreateAppAccountMutationVariables>;
export const AuthHandlerRequestFirebaseCustomAuthDocument = gql`
    mutation AuthHandlerRequestFirebaseCustomAuth($email: String!, $firstName: String, $languagePreference: String, $actionId: Int, $action: String) {
  requestFirebaseCustomAuth(
    email: $email
    firstName: $firstName
    languagePreference: $languagePreference
    actionId: $actionId
    action: $action
  ) {
    message
    status
  }
}
    `;
export type AuthHandlerRequestFirebaseCustomAuthMutationFn = Apollo.MutationFunction<Types.AuthHandlerRequestFirebaseCustomAuthMutation, Types.AuthHandlerRequestFirebaseCustomAuthMutationVariables>;

/**
 * __useAuthHandlerRequestFirebaseCustomAuthMutation__
 *
 * To run a mutation, you first call `useAuthHandlerRequestFirebaseCustomAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthHandlerRequestFirebaseCustomAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authHandlerRequestFirebaseCustomAuthMutation, { data, loading, error }] = useAuthHandlerRequestFirebaseCustomAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      languagePreference: // value for 'languagePreference'
 *      actionId: // value for 'actionId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useAuthHandlerRequestFirebaseCustomAuthMutation(baseOptions?: Apollo.MutationHookOptions<Types.AuthHandlerRequestFirebaseCustomAuthMutation, Types.AuthHandlerRequestFirebaseCustomAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AuthHandlerRequestFirebaseCustomAuthMutation, Types.AuthHandlerRequestFirebaseCustomAuthMutationVariables>(AuthHandlerRequestFirebaseCustomAuthDocument, options);
      }
export type AuthHandlerRequestFirebaseCustomAuthMutationHookResult = ReturnType<typeof useAuthHandlerRequestFirebaseCustomAuthMutation>;
export type AuthHandlerRequestFirebaseCustomAuthMutationResult = Apollo.MutationResult<Types.AuthHandlerRequestFirebaseCustomAuthMutation>;
export type AuthHandlerRequestFirebaseCustomAuthMutationOptions = Apollo.BaseMutationOptions<Types.AuthHandlerRequestFirebaseCustomAuthMutation, Types.AuthHandlerRequestFirebaseCustomAuthMutationVariables>;
export const GetArtistByHandleDocument = gql`
    query GetArtistByHandle($handle: String!) {
  Profile(where: {handle: {_ilike: $handle}, context: {type: {_eq: ARTIST}}}) {
    id
    avatar
    handle
    first_name
    last_name
    title
    avatar_metadata {
      id
      extra
    }
    context {
      id
      locations {
        country
        city
      }
    }
  }
}
    `;

/**
 * __useGetArtistByHandleQuery__
 *
 * To run a query within a React component, call `useGetArtistByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistByHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetArtistByHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistByHandleQuery, Types.GetArtistByHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistByHandleQuery, Types.GetArtistByHandleQueryVariables>(GetArtistByHandleDocument, options);
      }
export function useGetArtistByHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistByHandleQuery, Types.GetArtistByHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistByHandleQuery, Types.GetArtistByHandleQueryVariables>(GetArtistByHandleDocument, options);
        }
export type GetArtistByHandleQueryHookResult = ReturnType<typeof useGetArtistByHandleQuery>;
export type GetArtistByHandleLazyQueryHookResult = ReturnType<typeof useGetArtistByHandleLazyQuery>;
export type GetArtistByHandleQueryResult = Apollo.QueryResult<Types.GetArtistByHandleQuery, Types.GetArtistByHandleQueryVariables>;
export const GetThumbnailBySlugDocument = gql`
    query getThumbnailBySlug($slug: String!, $contextId: bigint!) {
  Artwork(where: {slug: {_ilike: $slug}, artist_context_id: {_eq: $contextId}}) {
    id
    artist_context_id
    slug
    status
    thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetThumbnailBySlugQuery__
 *
 * To run a query within a React component, call `useGetThumbnailBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThumbnailBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThumbnailBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetThumbnailBySlugQuery(baseOptions: Apollo.QueryHookOptions<Types.GetThumbnailBySlugQuery, Types.GetThumbnailBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetThumbnailBySlugQuery, Types.GetThumbnailBySlugQueryVariables>(GetThumbnailBySlugDocument, options);
      }
export function useGetThumbnailBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetThumbnailBySlugQuery, Types.GetThumbnailBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetThumbnailBySlugQuery, Types.GetThumbnailBySlugQueryVariables>(GetThumbnailBySlugDocument, options);
        }
export type GetThumbnailBySlugQueryHookResult = ReturnType<typeof useGetThumbnailBySlugQuery>;
export type GetThumbnailBySlugLazyQueryHookResult = ReturnType<typeof useGetThumbnailBySlugLazyQuery>;
export type GetThumbnailBySlugQueryResult = Apollo.QueryResult<Types.GetThumbnailBySlugQuery, Types.GetThumbnailBySlugQueryVariables>;
export const UpsertArtPrizeShowDocument = gql`
    mutation upsertArtPrizeShow($id: Int, $title: String, $description: String, $subtitle: String, $short_description: String, $prize_show_slug: String, $social_media_url: String, $amount_participants: Int, $published_at: String) {
  upsertArtPrizeShow(
    id: $id
    title: $title
    description: $description
    short_description: $short_description
    subtitle: $subtitle
    prize_show_slug: $prize_show_slug
    social_media_url: $social_media_url
    amount_participants: $amount_participants
    published_at: $published_at
  ) {
    id
  }
}
    `;
export type UpsertArtPrizeShowMutationFn = Apollo.MutationFunction<Types.UpsertArtPrizeShowMutation, Types.UpsertArtPrizeShowMutationVariables>;

/**
 * __useUpsertArtPrizeShowMutation__
 *
 * To run a mutation, you first call `useUpsertArtPrizeShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArtPrizeShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArtPrizeShowMutation, { data, loading, error }] = useUpsertArtPrizeShowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      subtitle: // value for 'subtitle'
 *      short_description: // value for 'short_description'
 *      prize_show_slug: // value for 'prize_show_slug'
 *      social_media_url: // value for 'social_media_url'
 *      amount_participants: // value for 'amount_participants'
 *      published_at: // value for 'published_at'
 *   },
 * });
 */
export function useUpsertArtPrizeShowMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertArtPrizeShowMutation, Types.UpsertArtPrizeShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertArtPrizeShowMutation, Types.UpsertArtPrizeShowMutationVariables>(UpsertArtPrizeShowDocument, options);
      }
export type UpsertArtPrizeShowMutationHookResult = ReturnType<typeof useUpsertArtPrizeShowMutation>;
export type UpsertArtPrizeShowMutationResult = Apollo.MutationResult<Types.UpsertArtPrizeShowMutation>;
export type UpsertArtPrizeShowMutationOptions = Apollo.BaseMutationOptions<Types.UpsertArtPrizeShowMutation, Types.UpsertArtPrizeShowMutationVariables>;
export const DeleteArtPrizeShowDocument = gql`
    mutation deleteArtPrizeShow($id: Int!) {
  deleteArtPrizeShow(art_prize_show_id: $id)
}
    `;
export type DeleteArtPrizeShowMutationFn = Apollo.MutationFunction<Types.DeleteArtPrizeShowMutation, Types.DeleteArtPrizeShowMutationVariables>;

/**
 * __useDeleteArtPrizeShowMutation__
 *
 * To run a mutation, you first call `useDeleteArtPrizeShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtPrizeShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtPrizeShowMutation, { data, loading, error }] = useDeleteArtPrizeShowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArtPrizeShowMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteArtPrizeShowMutation, Types.DeleteArtPrizeShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteArtPrizeShowMutation, Types.DeleteArtPrizeShowMutationVariables>(DeleteArtPrizeShowDocument, options);
      }
export type DeleteArtPrizeShowMutationHookResult = ReturnType<typeof useDeleteArtPrizeShowMutation>;
export type DeleteArtPrizeShowMutationResult = Apollo.MutationResult<Types.DeleteArtPrizeShowMutation>;
export type DeleteArtPrizeShowMutationOptions = Apollo.BaseMutationOptions<Types.DeleteArtPrizeShowMutation, Types.DeleteArtPrizeShowMutationVariables>;
export const DeleteArtPrizeWinnerDocument = gql`
    mutation deleteArtPrizeWinner($id: Int!) {
  deleteArtPrizeWinner(art_prize_winner_id: $id)
}
    `;
export type DeleteArtPrizeWinnerMutationFn = Apollo.MutationFunction<Types.DeleteArtPrizeWinnerMutation, Types.DeleteArtPrizeWinnerMutationVariables>;

/**
 * __useDeleteArtPrizeWinnerMutation__
 *
 * To run a mutation, you first call `useDeleteArtPrizeWinnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtPrizeWinnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtPrizeWinnerMutation, { data, loading, error }] = useDeleteArtPrizeWinnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArtPrizeWinnerMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteArtPrizeWinnerMutation, Types.DeleteArtPrizeWinnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteArtPrizeWinnerMutation, Types.DeleteArtPrizeWinnerMutationVariables>(DeleteArtPrizeWinnerDocument, options);
      }
export type DeleteArtPrizeWinnerMutationHookResult = ReturnType<typeof useDeleteArtPrizeWinnerMutation>;
export type DeleteArtPrizeWinnerMutationResult = Apollo.MutationResult<Types.DeleteArtPrizeWinnerMutation>;
export type DeleteArtPrizeWinnerMutationOptions = Apollo.BaseMutationOptions<Types.DeleteArtPrizeWinnerMutation, Types.DeleteArtPrizeWinnerMutationVariables>;
export const UpsertArtPrizeWinnerDocument = gql`
    mutation upsertArtPrizeWinner($artPrizeArtworks: [CreateArtPrizeArtwork], $artPrizeWinner: CreateArtPrizeWinner!) {
  upsertArtPrizeWinnerArtwork(
    artPrizeArtworks: $artPrizeArtworks
    artPrizeWinner: $artPrizeWinner
  )
}
    `;
export type UpsertArtPrizeWinnerMutationFn = Apollo.MutationFunction<Types.UpsertArtPrizeWinnerMutation, Types.UpsertArtPrizeWinnerMutationVariables>;

/**
 * __useUpsertArtPrizeWinnerMutation__
 *
 * To run a mutation, you first call `useUpsertArtPrizeWinnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertArtPrizeWinnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertArtPrizeWinnerMutation, { data, loading, error }] = useUpsertArtPrizeWinnerMutation({
 *   variables: {
 *      artPrizeArtworks: // value for 'artPrizeArtworks'
 *      artPrizeWinner: // value for 'artPrizeWinner'
 *   },
 * });
 */
export function useUpsertArtPrizeWinnerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertArtPrizeWinnerMutation, Types.UpsertArtPrizeWinnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertArtPrizeWinnerMutation, Types.UpsertArtPrizeWinnerMutationVariables>(UpsertArtPrizeWinnerDocument, options);
      }
export type UpsertArtPrizeWinnerMutationHookResult = ReturnType<typeof useUpsertArtPrizeWinnerMutation>;
export type UpsertArtPrizeWinnerMutationResult = Apollo.MutationResult<Types.UpsertArtPrizeWinnerMutation>;
export type UpsertArtPrizeWinnerMutationOptions = Apollo.BaseMutationOptions<Types.UpsertArtPrizeWinnerMutation, Types.UpsertArtPrizeWinnerMutationVariables>;
export const GetArtPrizesDocument = gql`
    query getArtPrizes {
  Art_prize_show(order_by: {published_at: desc}) {
    id
    title
    published_at
    updated_at
  }
}
    `;

/**
 * __useGetArtPrizesQuery__
 *
 * To run a query within a React component, call `useGetArtPrizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtPrizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtPrizesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArtPrizesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtPrizesQuery, Types.GetArtPrizesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtPrizesQuery, Types.GetArtPrizesQueryVariables>(GetArtPrizesDocument, options);
      }
export function useGetArtPrizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtPrizesQuery, Types.GetArtPrizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtPrizesQuery, Types.GetArtPrizesQueryVariables>(GetArtPrizesDocument, options);
        }
export type GetArtPrizesQueryHookResult = ReturnType<typeof useGetArtPrizesQuery>;
export type GetArtPrizesLazyQueryHookResult = ReturnType<typeof useGetArtPrizesLazyQuery>;
export type GetArtPrizesQueryResult = Apollo.QueryResult<Types.GetArtPrizesQuery, Types.GetArtPrizesQueryVariables>;
export const GetArtPrizeShowByIdDocument = gql`
    query getArtPrizeShowById($id: bigint!) {
  Art_prize_show_by_pk(id: $id) {
    id
    description
    short_description
    prize_show_slug
    published_at
    social_media_url
    subtitle
    title
    amount_participants
    background_metadata_id
    thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetArtPrizeShowByIdQuery__
 *
 * To run a query within a React component, call `useGetArtPrizeShowByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtPrizeShowByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtPrizeShowByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtPrizeShowByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtPrizeShowByIdQuery, Types.GetArtPrizeShowByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtPrizeShowByIdQuery, Types.GetArtPrizeShowByIdQueryVariables>(GetArtPrizeShowByIdDocument, options);
      }
export function useGetArtPrizeShowByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtPrizeShowByIdQuery, Types.GetArtPrizeShowByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtPrizeShowByIdQuery, Types.GetArtPrizeShowByIdQueryVariables>(GetArtPrizeShowByIdDocument, options);
        }
export type GetArtPrizeShowByIdQueryHookResult = ReturnType<typeof useGetArtPrizeShowByIdQuery>;
export type GetArtPrizeShowByIdLazyQueryHookResult = ReturnType<typeof useGetArtPrizeShowByIdLazyQuery>;
export type GetArtPrizeShowByIdQueryResult = Apollo.QueryResult<Types.GetArtPrizeShowByIdQuery, Types.GetArtPrizeShowByIdQueryVariables>;
export const UpsertDiscountCodeDocument = gql`
    mutation upsertDiscountCode($id: bigint, $discount_type: String, $email: String, $state: String, $code: String, $discount_value_type: String, $discount_professional_value: Float, $discount_emerging_value: Float, $expired_at: timestamptz, $trial_period_days: Int) {
  upsertDiscountCode(
    id: $id
    discount_type: $discount_type
    email: $email
    state: $state
    code: $code
    discount_value_type: $discount_value_type
    discount_professional_value: $discount_professional_value
    discount_emerging_value: $discount_emerging_value
    expired_at: $expired_at
    trial_period_days: $trial_period_days
  )
}
    `;
export type UpsertDiscountCodeMutationFn = Apollo.MutationFunction<Types.UpsertDiscountCodeMutation, Types.UpsertDiscountCodeMutationVariables>;

/**
 * __useUpsertDiscountCodeMutation__
 *
 * To run a mutation, you first call `useUpsertDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDiscountCodeMutation, { data, loading, error }] = useUpsertDiscountCodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      discount_type: // value for 'discount_type'
 *      email: // value for 'email'
 *      state: // value for 'state'
 *      code: // value for 'code'
 *      discount_value_type: // value for 'discount_value_type'
 *      discount_professional_value: // value for 'discount_professional_value'
 *      discount_emerging_value: // value for 'discount_emerging_value'
 *      expired_at: // value for 'expired_at'
 *      trial_period_days: // value for 'trial_period_days'
 *   },
 * });
 */
export function useUpsertDiscountCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertDiscountCodeMutation, Types.UpsertDiscountCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertDiscountCodeMutation, Types.UpsertDiscountCodeMutationVariables>(UpsertDiscountCodeDocument, options);
      }
export type UpsertDiscountCodeMutationHookResult = ReturnType<typeof useUpsertDiscountCodeMutation>;
export type UpsertDiscountCodeMutationResult = Apollo.MutationResult<Types.UpsertDiscountCodeMutation>;
export type UpsertDiscountCodeMutationOptions = Apollo.BaseMutationOptions<Types.UpsertDiscountCodeMutation, Types.UpsertDiscountCodeMutationVariables>;
export const SendDiscountCodeEmailDocument = gql`
    mutation sendDiscountCodeEmail($discountCodeId: bigint!) {
  sendDiscountCodeEmail(id: $discountCodeId)
}
    `;
export type SendDiscountCodeEmailMutationFn = Apollo.MutationFunction<Types.SendDiscountCodeEmailMutation, Types.SendDiscountCodeEmailMutationVariables>;

/**
 * __useSendDiscountCodeEmailMutation__
 *
 * To run a mutation, you first call `useSendDiscountCodeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDiscountCodeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDiscountCodeEmailMutation, { data, loading, error }] = useSendDiscountCodeEmailMutation({
 *   variables: {
 *      discountCodeId: // value for 'discountCodeId'
 *   },
 * });
 */
export function useSendDiscountCodeEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendDiscountCodeEmailMutation, Types.SendDiscountCodeEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendDiscountCodeEmailMutation, Types.SendDiscountCodeEmailMutationVariables>(SendDiscountCodeEmailDocument, options);
      }
export type SendDiscountCodeEmailMutationHookResult = ReturnType<typeof useSendDiscountCodeEmailMutation>;
export type SendDiscountCodeEmailMutationResult = Apollo.MutationResult<Types.SendDiscountCodeEmailMutation>;
export type SendDiscountCodeEmailMutationOptions = Apollo.BaseMutationOptions<Types.SendDiscountCodeEmailMutation, Types.SendDiscountCodeEmailMutationVariables>;
export const GetAllDiscountCodesDocument = gql`
    query getAllDiscountCodes {
  codes: discount_code_view(order_by: {id: desc}) {
    id
    discount_type
    email
    state
    code
    discount_value_type
    discount_professional_value
    discount_emerging_value
    amount_used
    expired_at
    trial_period_days
  }
}
    `;

/**
 * __useGetAllDiscountCodesQuery__
 *
 * To run a query within a React component, call `useGetAllDiscountCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDiscountCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDiscountCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDiscountCodesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllDiscountCodesQuery, Types.GetAllDiscountCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllDiscountCodesQuery, Types.GetAllDiscountCodesQueryVariables>(GetAllDiscountCodesDocument, options);
      }
export function useGetAllDiscountCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllDiscountCodesQuery, Types.GetAllDiscountCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllDiscountCodesQuery, Types.GetAllDiscountCodesQueryVariables>(GetAllDiscountCodesDocument, options);
        }
export type GetAllDiscountCodesQueryHookResult = ReturnType<typeof useGetAllDiscountCodesQuery>;
export type GetAllDiscountCodesLazyQueryHookResult = ReturnType<typeof useGetAllDiscountCodesLazyQuery>;
export type GetAllDiscountCodesQueryResult = Apollo.QueryResult<Types.GetAllDiscountCodesQuery, Types.GetAllDiscountCodesQueryVariables>;
export const GetDiscountCodeByIdDocument = gql`
    query getDiscountCodeById($codeId: bigint!) {
  code: Discount_code_by_pk(id: $codeId) {
    id
    discount_type
    email
    state
    code
    discount_value_type
    discount_professional_value
    discount_emerging_value
    expired_at
  }
}
    `;

/**
 * __useGetDiscountCodeByIdQuery__
 *
 * To run a query within a React component, call `useGetDiscountCodeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountCodeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountCodeByIdQuery({
 *   variables: {
 *      codeId: // value for 'codeId'
 *   },
 * });
 */
export function useGetDiscountCodeByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDiscountCodeByIdQuery, Types.GetDiscountCodeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDiscountCodeByIdQuery, Types.GetDiscountCodeByIdQueryVariables>(GetDiscountCodeByIdDocument, options);
      }
export function useGetDiscountCodeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDiscountCodeByIdQuery, Types.GetDiscountCodeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDiscountCodeByIdQuery, Types.GetDiscountCodeByIdQueryVariables>(GetDiscountCodeByIdDocument, options);
        }
export type GetDiscountCodeByIdQueryHookResult = ReturnType<typeof useGetDiscountCodeByIdQuery>;
export type GetDiscountCodeByIdLazyQueryHookResult = ReturnType<typeof useGetDiscountCodeByIdLazyQuery>;
export type GetDiscountCodeByIdQueryResult = Apollo.QueryResult<Types.GetDiscountCodeByIdQuery, Types.GetDiscountCodeByIdQueryVariables>;
export const GenerateDiscountCodeDocument = gql`
    query generateDiscountCode($code: String!) {
  generateDiscountCode(code: $code)
}
    `;

/**
 * __useGenerateDiscountCodeQuery__
 *
 * To run a query within a React component, call `useGenerateDiscountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateDiscountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateDiscountCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGenerateDiscountCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GenerateDiscountCodeQuery, Types.GenerateDiscountCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GenerateDiscountCodeQuery, Types.GenerateDiscountCodeQueryVariables>(GenerateDiscountCodeDocument, options);
      }
export function useGenerateDiscountCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GenerateDiscountCodeQuery, Types.GenerateDiscountCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GenerateDiscountCodeQuery, Types.GenerateDiscountCodeQueryVariables>(GenerateDiscountCodeDocument, options);
        }
export type GenerateDiscountCodeQueryHookResult = ReturnType<typeof useGenerateDiscountCodeQuery>;
export type GenerateDiscountCodeLazyQueryHookResult = ReturnType<typeof useGenerateDiscountCodeLazyQuery>;
export type GenerateDiscountCodeQueryResult = Apollo.QueryResult<Types.GenerateDiscountCodeQuery, Types.GenerateDiscountCodeQueryVariables>;
export const GetAvailableDiscountCodeByEmailDocument = gql`
    query getAvailableDiscountCodeByEmail($email: String!) {
  discount_code_view(
    where: {email: {_ilike: $email}, state: {_eq: "ACTIVE"}, expired_at: {_gte: "now()"}}
  ) {
    id
    email
    code
    discount_value_type
    discount_professional_value
    discount_emerging_value
    expired_at
  }
}
    `;

/**
 * __useGetAvailableDiscountCodeByEmailQuery__
 *
 * To run a query within a React component, call `useGetAvailableDiscountCodeByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableDiscountCodeByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableDiscountCodeByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetAvailableDiscountCodeByEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAvailableDiscountCodeByEmailQuery, Types.GetAvailableDiscountCodeByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAvailableDiscountCodeByEmailQuery, Types.GetAvailableDiscountCodeByEmailQueryVariables>(GetAvailableDiscountCodeByEmailDocument, options);
      }
export function useGetAvailableDiscountCodeByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAvailableDiscountCodeByEmailQuery, Types.GetAvailableDiscountCodeByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAvailableDiscountCodeByEmailQuery, Types.GetAvailableDiscountCodeByEmailQueryVariables>(GetAvailableDiscountCodeByEmailDocument, options);
        }
export type GetAvailableDiscountCodeByEmailQueryHookResult = ReturnType<typeof useGetAvailableDiscountCodeByEmailQuery>;
export type GetAvailableDiscountCodeByEmailLazyQueryHookResult = ReturnType<typeof useGetAvailableDiscountCodeByEmailLazyQuery>;
export type GetAvailableDiscountCodeByEmailQueryResult = Apollo.QueryResult<Types.GetAvailableDiscountCodeByEmailQuery, Types.GetAvailableDiscountCodeByEmailQueryVariables>;
export const GetDiscountCodeUsedByContextIdDocument = gql`
    query getDiscountCodeUsedByContextId($contextId: bigint!) {
  Discount_code_usage(
    where: {context_id: {_eq: $contextId}, deleted_at: {_is_null: true}}
  ) {
    id
  }
}
    `;

/**
 * __useGetDiscountCodeUsedByContextIdQuery__
 *
 * To run a query within a React component, call `useGetDiscountCodeUsedByContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountCodeUsedByContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountCodeUsedByContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetDiscountCodeUsedByContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDiscountCodeUsedByContextIdQuery, Types.GetDiscountCodeUsedByContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDiscountCodeUsedByContextIdQuery, Types.GetDiscountCodeUsedByContextIdQueryVariables>(GetDiscountCodeUsedByContextIdDocument, options);
      }
export function useGetDiscountCodeUsedByContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDiscountCodeUsedByContextIdQuery, Types.GetDiscountCodeUsedByContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDiscountCodeUsedByContextIdQuery, Types.GetDiscountCodeUsedByContextIdQueryVariables>(GetDiscountCodeUsedByContextIdDocument, options);
        }
export type GetDiscountCodeUsedByContextIdQueryHookResult = ReturnType<typeof useGetDiscountCodeUsedByContextIdQuery>;
export type GetDiscountCodeUsedByContextIdLazyQueryHookResult = ReturnType<typeof useGetDiscountCodeUsedByContextIdLazyQuery>;
export type GetDiscountCodeUsedByContextIdQueryResult = Apollo.QueryResult<Types.GetDiscountCodeUsedByContextIdQuery, Types.GetDiscountCodeUsedByContextIdQueryVariables>;
export const ResetCounterForPossibleScammersDocument = gql`
    mutation resetCounterForPossibleScammers($flaggedUserId: Int!) {
  resetFlaggedUserInfractionsCounterMutation(flaggedUserId: $flaggedUserId)
}
    `;
export type ResetCounterForPossibleScammersMutationFn = Apollo.MutationFunction<Types.ResetCounterForPossibleScammersMutation, Types.ResetCounterForPossibleScammersMutationVariables>;

/**
 * __useResetCounterForPossibleScammersMutation__
 *
 * To run a mutation, you first call `useResetCounterForPossibleScammersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCounterForPossibleScammersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCounterForPossibleScammersMutation, { data, loading, error }] = useResetCounterForPossibleScammersMutation({
 *   variables: {
 *      flaggedUserId: // value for 'flaggedUserId'
 *   },
 * });
 */
export function useResetCounterForPossibleScammersMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetCounterForPossibleScammersMutation, Types.ResetCounterForPossibleScammersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetCounterForPossibleScammersMutation, Types.ResetCounterForPossibleScammersMutationVariables>(ResetCounterForPossibleScammersDocument, options);
      }
export type ResetCounterForPossibleScammersMutationHookResult = ReturnType<typeof useResetCounterForPossibleScammersMutation>;
export type ResetCounterForPossibleScammersMutationResult = Apollo.MutationResult<Types.ResetCounterForPossibleScammersMutation>;
export type ResetCounterForPossibleScammersMutationOptions = Apollo.BaseMutationOptions<Types.ResetCounterForPossibleScammersMutation, Types.ResetCounterForPossibleScammersMutationVariables>;
export const GetFlaggedUsersDocument = gql`
    query getFlaggedUsers {
  Flagged_users(where: {infractions_counter: {_gt: 0}}) {
    id
    counter: infractions_counter
    flag_type
    Context {
      id
      type
      profile {
        first_name
        last_name
        title
        email
        preferred_language
        handle
      }
    }
  }
}
    `;

/**
 * __useGetFlaggedUsersQuery__
 *
 * To run a query within a React component, call `useGetFlaggedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlaggedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlaggedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFlaggedUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFlaggedUsersQuery, Types.GetFlaggedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFlaggedUsersQuery, Types.GetFlaggedUsersQueryVariables>(GetFlaggedUsersDocument, options);
      }
export function useGetFlaggedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFlaggedUsersQuery, Types.GetFlaggedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFlaggedUsersQuery, Types.GetFlaggedUsersQueryVariables>(GetFlaggedUsersDocument, options);
        }
export type GetFlaggedUsersQueryHookResult = ReturnType<typeof useGetFlaggedUsersQuery>;
export type GetFlaggedUsersLazyQueryHookResult = ReturnType<typeof useGetFlaggedUsersLazyQuery>;
export type GetFlaggedUsersQueryResult = Apollo.QueryResult<Types.GetFlaggedUsersQuery, Types.GetFlaggedUsersQueryVariables>;
export const GetAnalyticsStatsByDayDocument = gql`
    query GetAnalyticsStatsByDay($start_day: timestamptz, $end_day: timestamptz, $typeQuery: ENUM_context_type_enum_comparison_exp) {
  accounts_created: Profile(
    where: {created_at: {_gte: $start_day, _lt: $end_day}, context: {type: $typeQuery}}
  ) {
    id
    created_at
    context {
      type
    }
  }
  accounts_created_incomplete: Profile(
    where: {created_at: {_gte: $start_day, _lt: $end_day}, context: {type: $typeQuery}, status: {_eq: INCOMPLETE}}
  ) {
    id
    created_at
    context {
      type
    }
  }
  accounts_created_complete: Profile(
    where: {created_at: {_gte: $start_day, _lt: $end_day}, context: {type: $typeQuery}, status: {_eq: ACCEPTED}}
  ) {
    id
    created_at
    context {
      type
    }
  }
  published_artworks: Artwork(
    where: {created_at: {_gte: $start_day, _lt: $end_day}, slug: {_is_null: false}, is_public: {_eq: true}, artist_context: {type: $typeQuery}}
  ) {
    id
    created_at
    gallery_context_id
    artist_context_id
    artist_context {
      type
    }
  }
  success_transfer: Artwork_transactions(
    where: {created_at: {_gte: $start_day, _lt: $end_day}, context: {type: $typeQuery}}
  ) {
    id
    artwork_id
    created_at
    context {
      type
    }
  }
  purchase_inquires: Purchase_inquires(
    where: {created_at: {_gte: $start_day, _lt: $end_day}, context: {type: $typeQuery}}
  ) {
    id
    artwork_id
    context_sender_id
    created_at
    context {
      type
    }
  }
}
    `;

/**
 * __useGetAnalyticsStatsByDayQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsStatsByDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsStatsByDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsStatsByDayQuery({
 *   variables: {
 *      start_day: // value for 'start_day'
 *      end_day: // value for 'end_day'
 *      typeQuery: // value for 'typeQuery'
 *   },
 * });
 */
export function useGetAnalyticsStatsByDayQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAnalyticsStatsByDayQuery, Types.GetAnalyticsStatsByDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAnalyticsStatsByDayQuery, Types.GetAnalyticsStatsByDayQueryVariables>(GetAnalyticsStatsByDayDocument, options);
      }
export function useGetAnalyticsStatsByDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAnalyticsStatsByDayQuery, Types.GetAnalyticsStatsByDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAnalyticsStatsByDayQuery, Types.GetAnalyticsStatsByDayQueryVariables>(GetAnalyticsStatsByDayDocument, options);
        }
export type GetAnalyticsStatsByDayQueryHookResult = ReturnType<typeof useGetAnalyticsStatsByDayQuery>;
export type GetAnalyticsStatsByDayLazyQueryHookResult = ReturnType<typeof useGetAnalyticsStatsByDayLazyQuery>;
export type GetAnalyticsStatsByDayQueryResult = Apollo.QueryResult<Types.GetAnalyticsStatsByDayQuery, Types.GetAnalyticsStatsByDayQueryVariables>;
export const GetArtworksForAnArtistDocument = gql`
    query getArtworksForAnArtist($artistContextId: bigint!) {
  Artwork(
    where: {deleted_at: {_is_null: true}, artwork_to_collections: {Collection: {owner_context_id: {_eq: $artistContextId}, type: {_eq: CREATED}}}, is_public: {_eq: true}}
    order_by: [{published_date: desc}]
  ) {
    id
    thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetArtworksForAnArtistQuery__
 *
 * To run a query within a React component, call `useGetArtworksForAnArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworksForAnArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworksForAnArtistQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetArtworksForAnArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworksForAnArtistQuery, Types.GetArtworksForAnArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworksForAnArtistQuery, Types.GetArtworksForAnArtistQueryVariables>(GetArtworksForAnArtistDocument, options);
      }
export function useGetArtworksForAnArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworksForAnArtistQuery, Types.GetArtworksForAnArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworksForAnArtistQuery, Types.GetArtworksForAnArtistQueryVariables>(GetArtworksForAnArtistDocument, options);
        }
export type GetArtworksForAnArtistQueryHookResult = ReturnType<typeof useGetArtworksForAnArtistQuery>;
export type GetArtworksForAnArtistLazyQueryHookResult = ReturnType<typeof useGetArtworksForAnArtistLazyQuery>;
export type GetArtworksForAnArtistQueryResult = Apollo.QueryResult<Types.GetArtworksForAnArtistQuery, Types.GetArtworksForAnArtistQueryVariables>;
export const GetArtistArtVisionVisibilityDocument = gql`
    query getArtistArtVisionVisibility($artistContextId: bigint_comparison_exp = {}) {
  Artist_vision_visibility(where: {artist_context_id: $artistContextId}) {
    appeared_at
    disappeared_at
  }
}
    `;

/**
 * __useGetArtistArtVisionVisibilityQuery__
 *
 * To run a query within a React component, call `useGetArtistArtVisionVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistArtVisionVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistArtVisionVisibilityQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetArtistArtVisionVisibilityQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtistArtVisionVisibilityQuery, Types.GetArtistArtVisionVisibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistArtVisionVisibilityQuery, Types.GetArtistArtVisionVisibilityQueryVariables>(GetArtistArtVisionVisibilityDocument, options);
      }
export function useGetArtistArtVisionVisibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistArtVisionVisibilityQuery, Types.GetArtistArtVisionVisibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistArtVisionVisibilityQuery, Types.GetArtistArtVisionVisibilityQueryVariables>(GetArtistArtVisionVisibilityDocument, options);
        }
export type GetArtistArtVisionVisibilityQueryHookResult = ReturnType<typeof useGetArtistArtVisionVisibilityQuery>;
export type GetArtistArtVisionVisibilityLazyQueryHookResult = ReturnType<typeof useGetArtistArtVisionVisibilityLazyQuery>;
export type GetArtistArtVisionVisibilityQueryResult = Apollo.QueryResult<Types.GetArtistArtVisionVisibilityQuery, Types.GetArtistArtVisionVisibilityQueryVariables>;
export const ActivateArtistVisionVisibilityDocument = gql`
    mutation activateArtistVisionVisibility($artistContextId: bigint!, $skipCollection: Boolean!) {
  insert_Artist_vision_visibility_one(
    object: {appeared_at: "now()", disappeared_at: null, artist_context_id: $artistContextId}
    on_conflict: {constraint: Artist_vision_visibility_artist_context_id_key, update_columns: [disappeared_at, appeared_at]}
  ) {
    id
  }
  insert_Collection_one(
    object: {name: "ART_UNIVERSE", type: ART_UNIVERSE, owner_context_id: $artistContextId, public: false}
  ) @skip(if: $skipCollection) {
    id
  }
}
    `;
export type ActivateArtistVisionVisibilityMutationFn = Apollo.MutationFunction<Types.ActivateArtistVisionVisibilityMutation, Types.ActivateArtistVisionVisibilityMutationVariables>;

/**
 * __useActivateArtistVisionVisibilityMutation__
 *
 * To run a mutation, you first call `useActivateArtistVisionVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateArtistVisionVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateArtistVisionVisibilityMutation, { data, loading, error }] = useActivateArtistVisionVisibilityMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      skipCollection: // value for 'skipCollection'
 *   },
 * });
 */
export function useActivateArtistVisionVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateArtistVisionVisibilityMutation, Types.ActivateArtistVisionVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateArtistVisionVisibilityMutation, Types.ActivateArtistVisionVisibilityMutationVariables>(ActivateArtistVisionVisibilityDocument, options);
      }
export type ActivateArtistVisionVisibilityMutationHookResult = ReturnType<typeof useActivateArtistVisionVisibilityMutation>;
export type ActivateArtistVisionVisibilityMutationResult = Apollo.MutationResult<Types.ActivateArtistVisionVisibilityMutation>;
export type ActivateArtistVisionVisibilityMutationOptions = Apollo.BaseMutationOptions<Types.ActivateArtistVisionVisibilityMutation, Types.ActivateArtistVisionVisibilityMutationVariables>;
export const DeactivateArtistVisionVisibilityDocument = gql`
    mutation deactivateArtistVisionVisibility($artistContextId: bigint!) {
  insert_Artist_vision_visibility_one(
    object: {disappeared_at: "now()", artist_context_id: $artistContextId}
    on_conflict: {constraint: Artist_vision_visibility_artist_context_id_key, update_columns: [disappeared_at, appeared_at]}
  ) {
    id
  }
}
    `;
export type DeactivateArtistVisionVisibilityMutationFn = Apollo.MutationFunction<Types.DeactivateArtistVisionVisibilityMutation, Types.DeactivateArtistVisionVisibilityMutationVariables>;

/**
 * __useDeactivateArtistVisionVisibilityMutation__
 *
 * To run a mutation, you first call `useDeactivateArtistVisionVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateArtistVisionVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateArtistVisionVisibilityMutation, { data, loading, error }] = useDeactivateArtistVisionVisibilityMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useDeactivateArtistVisionVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeactivateArtistVisionVisibilityMutation, Types.DeactivateArtistVisionVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeactivateArtistVisionVisibilityMutation, Types.DeactivateArtistVisionVisibilityMutationVariables>(DeactivateArtistVisionVisibilityDocument, options);
      }
export type DeactivateArtistVisionVisibilityMutationHookResult = ReturnType<typeof useDeactivateArtistVisionVisibilityMutation>;
export type DeactivateArtistVisionVisibilityMutationResult = Apollo.MutationResult<Types.DeactivateArtistVisionVisibilityMutation>;
export type DeactivateArtistVisionVisibilityMutationOptions = Apollo.BaseMutationOptions<Types.DeactivateArtistVisionVisibilityMutation, Types.DeactivateArtistVisionVisibilityMutationVariables>;
export const GetArtUniverseCollectionDocument = gql`
    query getArtUniverseCollection($artistContextId: bigint!) {
  Collection(
    where: {owner_context_id: {_eq: $artistContextId}, type: {_eq: ART_UNIVERSE}}
  ) {
    id
  }
}
    `;

/**
 * __useGetArtUniverseCollectionQuery__
 *
 * To run a query within a React component, call `useGetArtUniverseCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtUniverseCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtUniverseCollectionQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetArtUniverseCollectionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtUniverseCollectionQuery, Types.GetArtUniverseCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtUniverseCollectionQuery, Types.GetArtUniverseCollectionQueryVariables>(GetArtUniverseCollectionDocument, options);
      }
export function useGetArtUniverseCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtUniverseCollectionQuery, Types.GetArtUniverseCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtUniverseCollectionQuery, Types.GetArtUniverseCollectionQueryVariables>(GetArtUniverseCollectionDocument, options);
        }
export type GetArtUniverseCollectionQueryHookResult = ReturnType<typeof useGetArtUniverseCollectionQuery>;
export type GetArtUniverseCollectionLazyQueryHookResult = ReturnType<typeof useGetArtUniverseCollectionLazyQuery>;
export type GetArtUniverseCollectionQueryResult = Apollo.QueryResult<Types.GetArtUniverseCollectionQuery, Types.GetArtUniverseCollectionQueryVariables>;
export const GetArtUniverseCollectionArtworksDocument = gql`
    query getArtUniverseCollectionArtworks($artistContextId: bigint!) {
  Artwork_artist_vision_listing(
    where: {artist_context_id: {_eq: $artistContextId}}
  ) {
    artwork_id
  }
}
    `;

/**
 * __useGetArtUniverseCollectionArtworksQuery__
 *
 * To run a query within a React component, call `useGetArtUniverseCollectionArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtUniverseCollectionArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtUniverseCollectionArtworksQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetArtUniverseCollectionArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtUniverseCollectionArtworksQuery, Types.GetArtUniverseCollectionArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtUniverseCollectionArtworksQuery, Types.GetArtUniverseCollectionArtworksQueryVariables>(GetArtUniverseCollectionArtworksDocument, options);
      }
export function useGetArtUniverseCollectionArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtUniverseCollectionArtworksQuery, Types.GetArtUniverseCollectionArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtUniverseCollectionArtworksQuery, Types.GetArtUniverseCollectionArtworksQueryVariables>(GetArtUniverseCollectionArtworksDocument, options);
        }
export type GetArtUniverseCollectionArtworksQueryHookResult = ReturnType<typeof useGetArtUniverseCollectionArtworksQuery>;
export type GetArtUniverseCollectionArtworksLazyQueryHookResult = ReturnType<typeof useGetArtUniverseCollectionArtworksLazyQuery>;
export type GetArtUniverseCollectionArtworksQueryResult = Apollo.QueryResult<Types.GetArtUniverseCollectionArtworksQuery, Types.GetArtUniverseCollectionArtworksQueryVariables>;
export const GetOrderedArtworksForArtUniverseDocument = gql`
    query getOrderedArtworksForArtUniverse($artistContextId: bigint!) {
  Artwork_artist_vision_listing(
    where: {artist_context_id: {_eq: $artistContextId}}
    order_by: [{artist_order: asc_nulls_last}, {artwork_id: desc}]
  ) {
    artwork_id
    artist_order
    published_date
    display_time_millis
    id
    Artwork {
      slug
      thumbnail_metadata_id
      artwork_details {
        art_type
        title
      }
    }
  }
}
    `;

/**
 * __useGetOrderedArtworksForArtUniverseQuery__
 *
 * To run a query within a React component, call `useGetOrderedArtworksForArtUniverseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderedArtworksForArtUniverseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderedArtworksForArtUniverseQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetOrderedArtworksForArtUniverseQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOrderedArtworksForArtUniverseQuery, Types.GetOrderedArtworksForArtUniverseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOrderedArtworksForArtUniverseQuery, Types.GetOrderedArtworksForArtUniverseQueryVariables>(GetOrderedArtworksForArtUniverseDocument, options);
      }
export function useGetOrderedArtworksForArtUniverseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOrderedArtworksForArtUniverseQuery, Types.GetOrderedArtworksForArtUniverseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOrderedArtworksForArtUniverseQuery, Types.GetOrderedArtworksForArtUniverseQueryVariables>(GetOrderedArtworksForArtUniverseDocument, options);
        }
export type GetOrderedArtworksForArtUniverseQueryHookResult = ReturnType<typeof useGetOrderedArtworksForArtUniverseQuery>;
export type GetOrderedArtworksForArtUniverseLazyQueryHookResult = ReturnType<typeof useGetOrderedArtworksForArtUniverseLazyQuery>;
export type GetOrderedArtworksForArtUniverseQueryResult = Apollo.QueryResult<Types.GetOrderedArtworksForArtUniverseQuery, Types.GetOrderedArtworksForArtUniverseQueryVariables>;
export const HideArtUniverseArtworksDocument = gql`
    mutation hideArtUniverseArtworks($artistContextId: Int!, $artworkIds: [Int!]!) {
  hideArtUniverseArtworks(context_id: $artistContextId, artworks: $artworkIds)
}
    `;
export type HideArtUniverseArtworksMutationFn = Apollo.MutationFunction<Types.HideArtUniverseArtworksMutation, Types.HideArtUniverseArtworksMutationVariables>;

/**
 * __useHideArtUniverseArtworksMutation__
 *
 * To run a mutation, you first call `useHideArtUniverseArtworksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideArtUniverseArtworksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideArtUniverseArtworksMutation, { data, loading, error }] = useHideArtUniverseArtworksMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      artworkIds: // value for 'artworkIds'
 *   },
 * });
 */
export function useHideArtUniverseArtworksMutation(baseOptions?: Apollo.MutationHookOptions<Types.HideArtUniverseArtworksMutation, Types.HideArtUniverseArtworksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.HideArtUniverseArtworksMutation, Types.HideArtUniverseArtworksMutationVariables>(HideArtUniverseArtworksDocument, options);
      }
export type HideArtUniverseArtworksMutationHookResult = ReturnType<typeof useHideArtUniverseArtworksMutation>;
export type HideArtUniverseArtworksMutationResult = Apollo.MutationResult<Types.HideArtUniverseArtworksMutation>;
export type HideArtUniverseArtworksMutationOptions = Apollo.BaseMutationOptions<Types.HideArtUniverseArtworksMutation, Types.HideArtUniverseArtworksMutationVariables>;
export const GetArtUniverseActiveInterestsDocument = gql`
    query getArtUniverseActiveInterests {
  categories: Vision_active_interests {
    id
    translation_key
  }
}
    `;

/**
 * __useGetArtUniverseActiveInterestsQuery__
 *
 * To run a query within a React component, call `useGetArtUniverseActiveInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtUniverseActiveInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtUniverseActiveInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArtUniverseActiveInterestsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtUniverseActiveInterestsQuery, Types.GetArtUniverseActiveInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtUniverseActiveInterestsQuery, Types.GetArtUniverseActiveInterestsQueryVariables>(GetArtUniverseActiveInterestsDocument, options);
      }
export function useGetArtUniverseActiveInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtUniverseActiveInterestsQuery, Types.GetArtUniverseActiveInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtUniverseActiveInterestsQuery, Types.GetArtUniverseActiveInterestsQueryVariables>(GetArtUniverseActiveInterestsDocument, options);
        }
export type GetArtUniverseActiveInterestsQueryHookResult = ReturnType<typeof useGetArtUniverseActiveInterestsQuery>;
export type GetArtUniverseActiveInterestsLazyQueryHookResult = ReturnType<typeof useGetArtUniverseActiveInterestsLazyQuery>;
export type GetArtUniverseActiveInterestsQueryResult = Apollo.QueryResult<Types.GetArtUniverseActiveInterestsQuery, Types.GetArtUniverseActiveInterestsQueryVariables>;
export const GetArtistAssignedArtUniverseCategoryDocument = gql`
    query getArtistAssignedArtUniverseCategory($artistContextId: bigint!, $interestIds: [bigint!]!) {
  assignedCategories: Profile_Interests(
    where: {context_id: {_eq: $artistContextId}, interest_id: {_in: $interestIds}}
  ) {
    id
    context_id
    interest_id
  }
}
    `;

/**
 * __useGetArtistAssignedArtUniverseCategoryQuery__
 *
 * To run a query within a React component, call `useGetArtistAssignedArtUniverseCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistAssignedArtUniverseCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistAssignedArtUniverseCategoryQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      interestIds: // value for 'interestIds'
 *   },
 * });
 */
export function useGetArtistAssignedArtUniverseCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistAssignedArtUniverseCategoryQuery, Types.GetArtistAssignedArtUniverseCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistAssignedArtUniverseCategoryQuery, Types.GetArtistAssignedArtUniverseCategoryQueryVariables>(GetArtistAssignedArtUniverseCategoryDocument, options);
      }
export function useGetArtistAssignedArtUniverseCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistAssignedArtUniverseCategoryQuery, Types.GetArtistAssignedArtUniverseCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistAssignedArtUniverseCategoryQuery, Types.GetArtistAssignedArtUniverseCategoryQueryVariables>(GetArtistAssignedArtUniverseCategoryDocument, options);
        }
export type GetArtistAssignedArtUniverseCategoryQueryHookResult = ReturnType<typeof useGetArtistAssignedArtUniverseCategoryQuery>;
export type GetArtistAssignedArtUniverseCategoryLazyQueryHookResult = ReturnType<typeof useGetArtistAssignedArtUniverseCategoryLazyQuery>;
export type GetArtistAssignedArtUniverseCategoryQueryResult = Apollo.QueryResult<Types.GetArtistAssignedArtUniverseCategoryQuery, Types.GetArtistAssignedArtUniverseCategoryQueryVariables>;
export const GetArtUniverseArtistListingDocument = gql`
    query getArtUniverseArtistListing($limit: Int = null, $offset: Int = 0, $where: Artist_Vision_Listings_By_Interest_bool_exp = null) {
  Artist_Vision_Listings_By_Interest(
    where: $where
    order_by: [{interest_collection_order: asc_nulls_last}]
    limit: $limit
    offset: $offset
  ) {
    artistContextId: artist_context_id
    artistProfileId: artist_profile_id
    title
    handle
    avatar
    city
    country
    order: interest_collection_order
    interestCollectionId: interest_collection_id
    artistCollectionToArtistContextId: artist_collection_to_artist_context_id
  }
}
    `;

/**
 * __useGetArtUniverseArtistListingQuery__
 *
 * To run a query within a React component, call `useGetArtUniverseArtistListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtUniverseArtistListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtUniverseArtistListingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetArtUniverseArtistListingQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtUniverseArtistListingQuery, Types.GetArtUniverseArtistListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtUniverseArtistListingQuery, Types.GetArtUniverseArtistListingQueryVariables>(GetArtUniverseArtistListingDocument, options);
      }
export function useGetArtUniverseArtistListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtUniverseArtistListingQuery, Types.GetArtUniverseArtistListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtUniverseArtistListingQuery, Types.GetArtUniverseArtistListingQueryVariables>(GetArtUniverseArtistListingDocument, options);
        }
export type GetArtUniverseArtistListingQueryHookResult = ReturnType<typeof useGetArtUniverseArtistListingQuery>;
export type GetArtUniverseArtistListingLazyQueryHookResult = ReturnType<typeof useGetArtUniverseArtistListingLazyQuery>;
export type GetArtUniverseArtistListingQueryResult = Apollo.QueryResult<Types.GetArtUniverseArtistListingQuery, Types.GetArtUniverseArtistListingQueryVariables>;
export const GetArtUniverseArtistsAmountByCategoryDocument = gql`
    query getArtUniverseArtistsAmountByCategory($interestId: String!) {
  Artist_Vision_Listings_By_Interest_aggregate(
    where: {internal_url: {_ilike: $interestId}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetArtUniverseArtistsAmountByCategoryQuery__
 *
 * To run a query within a React component, call `useGetArtUniverseArtistsAmountByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtUniverseArtistsAmountByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtUniverseArtistsAmountByCategoryQuery({
 *   variables: {
 *      interestId: // value for 'interestId'
 *   },
 * });
 */
export function useGetArtUniverseArtistsAmountByCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtUniverseArtistsAmountByCategoryQuery, Types.GetArtUniverseArtistsAmountByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtUniverseArtistsAmountByCategoryQuery, Types.GetArtUniverseArtistsAmountByCategoryQueryVariables>(GetArtUniverseArtistsAmountByCategoryDocument, options);
      }
export function useGetArtUniverseArtistsAmountByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtUniverseArtistsAmountByCategoryQuery, Types.GetArtUniverseArtistsAmountByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtUniverseArtistsAmountByCategoryQuery, Types.GetArtUniverseArtistsAmountByCategoryQueryVariables>(GetArtUniverseArtistsAmountByCategoryDocument, options);
        }
export type GetArtUniverseArtistsAmountByCategoryQueryHookResult = ReturnType<typeof useGetArtUniverseArtistsAmountByCategoryQuery>;
export type GetArtUniverseArtistsAmountByCategoryLazyQueryHookResult = ReturnType<typeof useGetArtUniverseArtistsAmountByCategoryLazyQuery>;
export type GetArtUniverseArtistsAmountByCategoryQueryResult = Apollo.QueryResult<Types.GetArtUniverseArtistsAmountByCategoryQuery, Types.GetArtUniverseArtistsAmountByCategoryQueryVariables>;
export const ReorderArtistInCollectionDocument = gql`
    mutation reorderArtistInCollection($collectionId: bigint!, $artistList: [CustomArtistCollectionToArtistContextInsertInput!]!) {
  reorderArtistsInCollection(collectionId: $collectionId, artistList: $artistList)
}
    `;
export type ReorderArtistInCollectionMutationFn = Apollo.MutationFunction<Types.ReorderArtistInCollectionMutation, Types.ReorderArtistInCollectionMutationVariables>;

/**
 * __useReorderArtistInCollectionMutation__
 *
 * To run a mutation, you first call `useReorderArtistInCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderArtistInCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderArtistInCollectionMutation, { data, loading, error }] = useReorderArtistInCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      artistList: // value for 'artistList'
 *   },
 * });
 */
export function useReorderArtistInCollectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReorderArtistInCollectionMutation, Types.ReorderArtistInCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ReorderArtistInCollectionMutation, Types.ReorderArtistInCollectionMutationVariables>(ReorderArtistInCollectionDocument, options);
      }
export type ReorderArtistInCollectionMutationHookResult = ReturnType<typeof useReorderArtistInCollectionMutation>;
export type ReorderArtistInCollectionMutationResult = Apollo.MutationResult<Types.ReorderArtistInCollectionMutation>;
export type ReorderArtistInCollectionMutationOptions = Apollo.BaseMutationOptions<Types.ReorderArtistInCollectionMutation, Types.ReorderArtistInCollectionMutationVariables>;
export const UpdateArtistArtUniverseCollectionsDocument = gql`
    mutation updateArtistArtUniverseCollections($contextId: Int!, $interestIds: [Int!]!) {
  ensureArtUniverseArtistInterestOrder(
    context_id: $contextId
    interest_ids: $interestIds
  )
}
    `;
export type UpdateArtistArtUniverseCollectionsMutationFn = Apollo.MutationFunction<Types.UpdateArtistArtUniverseCollectionsMutation, Types.UpdateArtistArtUniverseCollectionsMutationVariables>;

/**
 * __useUpdateArtistArtUniverseCollectionsMutation__
 *
 * To run a mutation, you first call `useUpdateArtistArtUniverseCollectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtistArtUniverseCollectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtistArtUniverseCollectionsMutation, { data, loading, error }] = useUpdateArtistArtUniverseCollectionsMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      interestIds: // value for 'interestIds'
 *   },
 * });
 */
export function useUpdateArtistArtUniverseCollectionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtistArtUniverseCollectionsMutation, Types.UpdateArtistArtUniverseCollectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtistArtUniverseCollectionsMutation, Types.UpdateArtistArtUniverseCollectionsMutationVariables>(UpdateArtistArtUniverseCollectionsDocument, options);
      }
export type UpdateArtistArtUniverseCollectionsMutationHookResult = ReturnType<typeof useUpdateArtistArtUniverseCollectionsMutation>;
export type UpdateArtistArtUniverseCollectionsMutationResult = Apollo.MutationResult<Types.UpdateArtistArtUniverseCollectionsMutation>;
export type UpdateArtistArtUniverseCollectionsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtistArtUniverseCollectionsMutation, Types.UpdateArtistArtUniverseCollectionsMutationVariables>;
export const GetArtistArtHistoryVisibilityDocument = gql`
    query getArtistArtHistoryVisibility($artistContextId: bigint_comparison_exp = {}) {
  Artist_art_history_visibility(where: {artist_context_id: $artistContextId}) {
    appeared_at
    disappeared_at
  }
}
    `;

/**
 * __useGetArtistArtHistoryVisibilityQuery__
 *
 * To run a query within a React component, call `useGetArtistArtHistoryVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistArtHistoryVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistArtHistoryVisibilityQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetArtistArtHistoryVisibilityQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtistArtHistoryVisibilityQuery, Types.GetArtistArtHistoryVisibilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistArtHistoryVisibilityQuery, Types.GetArtistArtHistoryVisibilityQueryVariables>(GetArtistArtHistoryVisibilityDocument, options);
      }
export function useGetArtistArtHistoryVisibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistArtHistoryVisibilityQuery, Types.GetArtistArtHistoryVisibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistArtHistoryVisibilityQuery, Types.GetArtistArtHistoryVisibilityQueryVariables>(GetArtistArtHistoryVisibilityDocument, options);
        }
export type GetArtistArtHistoryVisibilityQueryHookResult = ReturnType<typeof useGetArtistArtHistoryVisibilityQuery>;
export type GetArtistArtHistoryVisibilityLazyQueryHookResult = ReturnType<typeof useGetArtistArtHistoryVisibilityLazyQuery>;
export type GetArtistArtHistoryVisibilityQueryResult = Apollo.QueryResult<Types.GetArtistArtHistoryVisibilityQuery, Types.GetArtistArtHistoryVisibilityQueryVariables>;
export const ActivateHistoricArtistVisibilityDocument = gql`
    mutation activateHistoricArtistVisibility($artistContextId: bigint!) {
  activateArtHistoryArtUniverse(context_id: $artistContextId)
}
    `;
export type ActivateHistoricArtistVisibilityMutationFn = Apollo.MutationFunction<Types.ActivateHistoricArtistVisibilityMutation, Types.ActivateHistoricArtistVisibilityMutationVariables>;

/**
 * __useActivateHistoricArtistVisibilityMutation__
 *
 * To run a mutation, you first call `useActivateHistoricArtistVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateHistoricArtistVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateHistoricArtistVisibilityMutation, { data, loading, error }] = useActivateHistoricArtistVisibilityMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useActivateHistoricArtistVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateHistoricArtistVisibilityMutation, Types.ActivateHistoricArtistVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateHistoricArtistVisibilityMutation, Types.ActivateHistoricArtistVisibilityMutationVariables>(ActivateHistoricArtistVisibilityDocument, options);
      }
export type ActivateHistoricArtistVisibilityMutationHookResult = ReturnType<typeof useActivateHistoricArtistVisibilityMutation>;
export type ActivateHistoricArtistVisibilityMutationResult = Apollo.MutationResult<Types.ActivateHistoricArtistVisibilityMutation>;
export type ActivateHistoricArtistVisibilityMutationOptions = Apollo.BaseMutationOptions<Types.ActivateHistoricArtistVisibilityMutation, Types.ActivateHistoricArtistVisibilityMutationVariables>;
export const DeactivateHistoricArtistVisibilityDocument = gql`
    mutation deactivateHistoricArtistVisibility($artistContextId: bigint!) {
  deactivateArtHistoryArtUniverse(context_id: $artistContextId)
}
    `;
export type DeactivateHistoricArtistVisibilityMutationFn = Apollo.MutationFunction<Types.DeactivateHistoricArtistVisibilityMutation, Types.DeactivateHistoricArtistVisibilityMutationVariables>;

/**
 * __useDeactivateHistoricArtistVisibilityMutation__
 *
 * To run a mutation, you first call `useDeactivateHistoricArtistVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateHistoricArtistVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateHistoricArtistVisibilityMutation, { data, loading, error }] = useDeactivateHistoricArtistVisibilityMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useDeactivateHistoricArtistVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeactivateHistoricArtistVisibilityMutation, Types.DeactivateHistoricArtistVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeactivateHistoricArtistVisibilityMutation, Types.DeactivateHistoricArtistVisibilityMutationVariables>(DeactivateHistoricArtistVisibilityDocument, options);
      }
export type DeactivateHistoricArtistVisibilityMutationHookResult = ReturnType<typeof useDeactivateHistoricArtistVisibilityMutation>;
export type DeactivateHistoricArtistVisibilityMutationResult = Apollo.MutationResult<Types.DeactivateHistoricArtistVisibilityMutation>;
export type DeactivateHistoricArtistVisibilityMutationOptions = Apollo.BaseMutationOptions<Types.DeactivateHistoricArtistVisibilityMutation, Types.DeactivateHistoricArtistVisibilityMutationVariables>;
export const GetArtHistoryCollectionDocument = gql`
    query getArtHistoryCollection($artistContextId: bigint!) {
  Collection(
    where: {owner_context_id: {_eq: $artistContextId}, type: {_eq: ART_HISTORY}}
  ) {
    id
  }
}
    `;

/**
 * __useGetArtHistoryCollectionQuery__
 *
 * To run a query within a React component, call `useGetArtHistoryCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtHistoryCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtHistoryCollectionQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetArtHistoryCollectionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtHistoryCollectionQuery, Types.GetArtHistoryCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtHistoryCollectionQuery, Types.GetArtHistoryCollectionQueryVariables>(GetArtHistoryCollectionDocument, options);
      }
export function useGetArtHistoryCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtHistoryCollectionQuery, Types.GetArtHistoryCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtHistoryCollectionQuery, Types.GetArtHistoryCollectionQueryVariables>(GetArtHistoryCollectionDocument, options);
        }
export type GetArtHistoryCollectionQueryHookResult = ReturnType<typeof useGetArtHistoryCollectionQuery>;
export type GetArtHistoryCollectionLazyQueryHookResult = ReturnType<typeof useGetArtHistoryCollectionLazyQuery>;
export type GetArtHistoryCollectionQueryResult = Apollo.QueryResult<Types.GetArtHistoryCollectionQuery, Types.GetArtHistoryCollectionQueryVariables>;
export const GetAdminToolArtistArtHistoryMovementsDocument = gql`
    query getAdminToolArtistArtHistoryMovements($artistContextId: bigint!) {
  movements: Art_history_movement(where: {_and: [{active: {_eq: true}}]}) {
    id
    translation_key
  }
  movementCollections: Artist_Collection(
    where: {type: {_eq: art_history_movement}}
  ) {
    id
    internal_url
  }
  assignedMovements: Artist_Collection_to_Artist_Context(
    where: {_and: [{context_id: {_eq: $artistContextId}}], artist_Collection: {internal_url: {_like: "movement_id_%"}}}
  ) {
    id
    deleted_at
    artist_Collection {
      id
      internal_url
    }
  }
}
    `;

/**
 * __useGetAdminToolArtistArtHistoryMovementsQuery__
 *
 * To run a query within a React component, call `useGetAdminToolArtistArtHistoryMovementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminToolArtistArtHistoryMovementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminToolArtistArtHistoryMovementsQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetAdminToolArtistArtHistoryMovementsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAdminToolArtistArtHistoryMovementsQuery, Types.GetAdminToolArtistArtHistoryMovementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAdminToolArtistArtHistoryMovementsQuery, Types.GetAdminToolArtistArtHistoryMovementsQueryVariables>(GetAdminToolArtistArtHistoryMovementsDocument, options);
      }
export function useGetAdminToolArtistArtHistoryMovementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAdminToolArtistArtHistoryMovementsQuery, Types.GetAdminToolArtistArtHistoryMovementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAdminToolArtistArtHistoryMovementsQuery, Types.GetAdminToolArtistArtHistoryMovementsQueryVariables>(GetAdminToolArtistArtHistoryMovementsDocument, options);
        }
export type GetAdminToolArtistArtHistoryMovementsQueryHookResult = ReturnType<typeof useGetAdminToolArtistArtHistoryMovementsQuery>;
export type GetAdminToolArtistArtHistoryMovementsLazyQueryHookResult = ReturnType<typeof useGetAdminToolArtistArtHistoryMovementsLazyQuery>;
export type GetAdminToolArtistArtHistoryMovementsQueryResult = Apollo.QueryResult<Types.GetAdminToolArtistArtHistoryMovementsQuery, Types.GetAdminToolArtistArtHistoryMovementsQueryVariables>;
export const InsertArtistCollectionToArtistContextDocument = gql`
    mutation insertArtistCollectionToArtistContext($artistContextId: bigint!, $artistCollectionId: bigint!) {
  insert_Artist_Collection_to_Artist_Context_one(
    object: {artist_collection_id: $artistCollectionId, context_id: $artistContextId, order: 20}
  ) {
    id
  }
}
    `;
export type InsertArtistCollectionToArtistContextMutationFn = Apollo.MutationFunction<Types.InsertArtistCollectionToArtistContextMutation, Types.InsertArtistCollectionToArtistContextMutationVariables>;

/**
 * __useInsertArtistCollectionToArtistContextMutation__
 *
 * To run a mutation, you first call `useInsertArtistCollectionToArtistContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertArtistCollectionToArtistContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertArtistCollectionToArtistContextMutation, { data, loading, error }] = useInsertArtistCollectionToArtistContextMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      artistCollectionId: // value for 'artistCollectionId'
 *   },
 * });
 */
export function useInsertArtistCollectionToArtistContextMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertArtistCollectionToArtistContextMutation, Types.InsertArtistCollectionToArtistContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertArtistCollectionToArtistContextMutation, Types.InsertArtistCollectionToArtistContextMutationVariables>(InsertArtistCollectionToArtistContextDocument, options);
      }
export type InsertArtistCollectionToArtistContextMutationHookResult = ReturnType<typeof useInsertArtistCollectionToArtistContextMutation>;
export type InsertArtistCollectionToArtistContextMutationResult = Apollo.MutationResult<Types.InsertArtistCollectionToArtistContextMutation>;
export type InsertArtistCollectionToArtistContextMutationOptions = Apollo.BaseMutationOptions<Types.InsertArtistCollectionToArtistContextMutation, Types.InsertArtistCollectionToArtistContextMutationVariables>;
export const MarkArtHistoryMovementCollectionDeletedDocument = gql`
    mutation markArtHistoryMovementCollectionDeleted($artistContextId: bigint!, $artistCollectionId: bigint!) {
  update_Artist_Collection_to_Artist_Context(
    where: {context_id: {_eq: $artistContextId}, artist_collection_id: {_eq: $artistCollectionId}}
    _set: {deleted_at: "now()"}
  ) {
    affected_rows
  }
}
    `;
export type MarkArtHistoryMovementCollectionDeletedMutationFn = Apollo.MutationFunction<Types.MarkArtHistoryMovementCollectionDeletedMutation, Types.MarkArtHistoryMovementCollectionDeletedMutationVariables>;

/**
 * __useMarkArtHistoryMovementCollectionDeletedMutation__
 *
 * To run a mutation, you first call `useMarkArtHistoryMovementCollectionDeletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkArtHistoryMovementCollectionDeletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markArtHistoryMovementCollectionDeletedMutation, { data, loading, error }] = useMarkArtHistoryMovementCollectionDeletedMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      artistCollectionId: // value for 'artistCollectionId'
 *   },
 * });
 */
export function useMarkArtHistoryMovementCollectionDeletedMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkArtHistoryMovementCollectionDeletedMutation, Types.MarkArtHistoryMovementCollectionDeletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkArtHistoryMovementCollectionDeletedMutation, Types.MarkArtHistoryMovementCollectionDeletedMutationVariables>(MarkArtHistoryMovementCollectionDeletedDocument, options);
      }
export type MarkArtHistoryMovementCollectionDeletedMutationHookResult = ReturnType<typeof useMarkArtHistoryMovementCollectionDeletedMutation>;
export type MarkArtHistoryMovementCollectionDeletedMutationResult = Apollo.MutationResult<Types.MarkArtHistoryMovementCollectionDeletedMutation>;
export type MarkArtHistoryMovementCollectionDeletedMutationOptions = Apollo.BaseMutationOptions<Types.MarkArtHistoryMovementCollectionDeletedMutation, Types.MarkArtHistoryMovementCollectionDeletedMutationVariables>;
export const MarkArtHistoryMovementCollectionUndeletedDocument = gql`
    mutation markArtHistoryMovementCollectionUndeleted($artistContextId: bigint!, $artistCollectionId: bigint!) {
  update_Artist_Collection_to_Artist_Context(
    where: {context_id: {_eq: $artistContextId}, artist_collection_id: {_eq: $artistCollectionId}}
    _set: {deleted_at: null}
  ) {
    affected_rows
  }
}
    `;
export type MarkArtHistoryMovementCollectionUndeletedMutationFn = Apollo.MutationFunction<Types.MarkArtHistoryMovementCollectionUndeletedMutation, Types.MarkArtHistoryMovementCollectionUndeletedMutationVariables>;

/**
 * __useMarkArtHistoryMovementCollectionUndeletedMutation__
 *
 * To run a mutation, you first call `useMarkArtHistoryMovementCollectionUndeletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkArtHistoryMovementCollectionUndeletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markArtHistoryMovementCollectionUndeletedMutation, { data, loading, error }] = useMarkArtHistoryMovementCollectionUndeletedMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      artistCollectionId: // value for 'artistCollectionId'
 *   },
 * });
 */
export function useMarkArtHistoryMovementCollectionUndeletedMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkArtHistoryMovementCollectionUndeletedMutation, Types.MarkArtHistoryMovementCollectionUndeletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkArtHistoryMovementCollectionUndeletedMutation, Types.MarkArtHistoryMovementCollectionUndeletedMutationVariables>(MarkArtHistoryMovementCollectionUndeletedDocument, options);
      }
export type MarkArtHistoryMovementCollectionUndeletedMutationHookResult = ReturnType<typeof useMarkArtHistoryMovementCollectionUndeletedMutation>;
export type MarkArtHistoryMovementCollectionUndeletedMutationResult = Apollo.MutationResult<Types.MarkArtHistoryMovementCollectionUndeletedMutation>;
export type MarkArtHistoryMovementCollectionUndeletedMutationOptions = Apollo.BaseMutationOptions<Types.MarkArtHistoryMovementCollectionUndeletedMutation, Types.MarkArtHistoryMovementCollectionUndeletedMutationVariables>;
export const AddArtHistoryArtworkDisplayTimeDocument = gql`
    mutation addArtHistoryArtworkDisplayTime($artwork_to_collection_id: bigint!, $display_time_millis: Int!) {
  insert_Artwork_art_history_timetable_one(
    object: {artwork_to_collection_id: $artwork_to_collection_id, display_time_millis: $display_time_millis}
    on_conflict: {constraint: Artwork_art_history_timetable_artwork_to_collection_id_key, update_columns: [display_time_millis]}
  ) {
    id
  }
}
    `;
export type AddArtHistoryArtworkDisplayTimeMutationFn = Apollo.MutationFunction<Types.AddArtHistoryArtworkDisplayTimeMutation, Types.AddArtHistoryArtworkDisplayTimeMutationVariables>;

/**
 * __useAddArtHistoryArtworkDisplayTimeMutation__
 *
 * To run a mutation, you first call `useAddArtHistoryArtworkDisplayTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtHistoryArtworkDisplayTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtHistoryArtworkDisplayTimeMutation, { data, loading, error }] = useAddArtHistoryArtworkDisplayTimeMutation({
 *   variables: {
 *      artwork_to_collection_id: // value for 'artwork_to_collection_id'
 *      display_time_millis: // value for 'display_time_millis'
 *   },
 * });
 */
export function useAddArtHistoryArtworkDisplayTimeMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddArtHistoryArtworkDisplayTimeMutation, Types.AddArtHistoryArtworkDisplayTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddArtHistoryArtworkDisplayTimeMutation, Types.AddArtHistoryArtworkDisplayTimeMutationVariables>(AddArtHistoryArtworkDisplayTimeDocument, options);
      }
export type AddArtHistoryArtworkDisplayTimeMutationHookResult = ReturnType<typeof useAddArtHistoryArtworkDisplayTimeMutation>;
export type AddArtHistoryArtworkDisplayTimeMutationResult = Apollo.MutationResult<Types.AddArtHistoryArtworkDisplayTimeMutation>;
export type AddArtHistoryArtworkDisplayTimeMutationOptions = Apollo.BaseMutationOptions<Types.AddArtHistoryArtworkDisplayTimeMutation, Types.AddArtHistoryArtworkDisplayTimeMutationVariables>;
export const InsertActivateArtistCollectionsToArtistContextDocument = gql`
    mutation insertActivateArtistCollectionsToArtistContext($newCollections: [Artist_Collection_to_Artist_Context_insert_input!]!, $activateCollectionsId: [bigint!]!, $deleteCollectionsId: [bigint!]!) {
  insert_Artist_Collection_to_Artist_Context(objects: $newCollections) {
    returning {
      id
    }
  }
  activatedCollections: update_Artist_Collection_to_Artist_Context(
    _set: {deleted_at: null}
    where: {id: {_in: $activateCollectionsId}}
  ) {
    returning {
      id
    }
  }
  deletedCollections: update_Artist_Collection_to_Artist_Context(
    _set: {deleted_at: "now()"}
    where: {id: {_in: $deleteCollectionsId}}
  ) {
    returning {
      id
    }
  }
}
    `;
export type InsertActivateArtistCollectionsToArtistContextMutationFn = Apollo.MutationFunction<Types.InsertActivateArtistCollectionsToArtistContextMutation, Types.InsertActivateArtistCollectionsToArtistContextMutationVariables>;

/**
 * __useInsertActivateArtistCollectionsToArtistContextMutation__
 *
 * To run a mutation, you first call `useInsertActivateArtistCollectionsToArtistContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertActivateArtistCollectionsToArtistContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertActivateArtistCollectionsToArtistContextMutation, { data, loading, error }] = useInsertActivateArtistCollectionsToArtistContextMutation({
 *   variables: {
 *      newCollections: // value for 'newCollections'
 *      activateCollectionsId: // value for 'activateCollectionsId'
 *      deleteCollectionsId: // value for 'deleteCollectionsId'
 *   },
 * });
 */
export function useInsertActivateArtistCollectionsToArtistContextMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertActivateArtistCollectionsToArtistContextMutation, Types.InsertActivateArtistCollectionsToArtistContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertActivateArtistCollectionsToArtistContextMutation, Types.InsertActivateArtistCollectionsToArtistContextMutationVariables>(InsertActivateArtistCollectionsToArtistContextDocument, options);
      }
export type InsertActivateArtistCollectionsToArtistContextMutationHookResult = ReturnType<typeof useInsertActivateArtistCollectionsToArtistContextMutation>;
export type InsertActivateArtistCollectionsToArtistContextMutationResult = Apollo.MutationResult<Types.InsertActivateArtistCollectionsToArtistContextMutation>;
export type InsertActivateArtistCollectionsToArtistContextMutationOptions = Apollo.BaseMutationOptions<Types.InsertActivateArtistCollectionsToArtistContextMutation, Types.InsertActivateArtistCollectionsToArtistContextMutationVariables>;
export const GetArtistInfosAdminToolDocument = gql`
    query GetArtistInfosAdminTool($contextId: bigint!) {
  artistCuration: Artist_curation_score_by_pk(artist_context_id: $contextId) {
    artistContextId: artist_context_id
    score
  }
  artistProfile: Context_by_pk(id: $contextId) {
    profile {
      email
      first_name
      last_name
      quote
    }
  }
  interestsArtist: Profile_Interests(where: {context_id: {_eq: $contextId}}) {
    interest {
      translation_key
      id
      type
      image_url
    }
  }
}
    `;

/**
 * __useGetArtistInfosAdminToolQuery__
 *
 * To run a query within a React component, call `useGetArtistInfosAdminToolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistInfosAdminToolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistInfosAdminToolQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistInfosAdminToolQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistInfosAdminToolQuery, Types.GetArtistInfosAdminToolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistInfosAdminToolQuery, Types.GetArtistInfosAdminToolQueryVariables>(GetArtistInfosAdminToolDocument, options);
      }
export function useGetArtistInfosAdminToolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistInfosAdminToolQuery, Types.GetArtistInfosAdminToolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistInfosAdminToolQuery, Types.GetArtistInfosAdminToolQueryVariables>(GetArtistInfosAdminToolDocument, options);
        }
export type GetArtistInfosAdminToolQueryHookResult = ReturnType<typeof useGetArtistInfosAdminToolQuery>;
export type GetArtistInfosAdminToolLazyQueryHookResult = ReturnType<typeof useGetArtistInfosAdminToolLazyQuery>;
export type GetArtistInfosAdminToolQueryResult = Apollo.QueryResult<Types.GetArtistInfosAdminToolQuery, Types.GetArtistInfosAdminToolQueryVariables>;
export const AddArtistCuratorScoreDocument = gql`
    mutation addArtistCuratorScore($artist_context_id: Int!, $score: Int!) {
  addArtistCuratorScore(artist_context_id: $artist_context_id, score: $score)
}
    `;
export type AddArtistCuratorScoreMutationFn = Apollo.MutationFunction<Types.AddArtistCuratorScoreMutation, Types.AddArtistCuratorScoreMutationVariables>;

/**
 * __useAddArtistCuratorScoreMutation__
 *
 * To run a mutation, you first call `useAddArtistCuratorScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtistCuratorScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtistCuratorScoreMutation, { data, loading, error }] = useAddArtistCuratorScoreMutation({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useAddArtistCuratorScoreMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddArtistCuratorScoreMutation, Types.AddArtistCuratorScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddArtistCuratorScoreMutation, Types.AddArtistCuratorScoreMutationVariables>(AddArtistCuratorScoreDocument, options);
      }
export type AddArtistCuratorScoreMutationHookResult = ReturnType<typeof useAddArtistCuratorScoreMutation>;
export type AddArtistCuratorScoreMutationResult = Apollo.MutationResult<Types.AddArtistCuratorScoreMutation>;
export type AddArtistCuratorScoreMutationOptions = Apollo.BaseMutationOptions<Types.AddArtistCuratorScoreMutation, Types.AddArtistCuratorScoreMutationVariables>;
export const AddArtworkCuratorScoreDocument = gql`
    mutation addArtworkCuratorScore($artwork_id: Int!, $score: Int!) {
  addArtworkCuratorScore(artwork_id: $artwork_id, score: $score)
}
    `;
export type AddArtworkCuratorScoreMutationFn = Apollo.MutationFunction<Types.AddArtworkCuratorScoreMutation, Types.AddArtworkCuratorScoreMutationVariables>;

/**
 * __useAddArtworkCuratorScoreMutation__
 *
 * To run a mutation, you first call `useAddArtworkCuratorScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtworkCuratorScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtworkCuratorScoreMutation, { data, loading, error }] = useAddArtworkCuratorScoreMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useAddArtworkCuratorScoreMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddArtworkCuratorScoreMutation, Types.AddArtworkCuratorScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddArtworkCuratorScoreMutation, Types.AddArtworkCuratorScoreMutationVariables>(AddArtworkCuratorScoreDocument, options);
      }
export type AddArtworkCuratorScoreMutationHookResult = ReturnType<typeof useAddArtworkCuratorScoreMutation>;
export type AddArtworkCuratorScoreMutationResult = Apollo.MutationResult<Types.AddArtworkCuratorScoreMutation>;
export type AddArtworkCuratorScoreMutationOptions = Apollo.BaseMutationOptions<Types.AddArtworkCuratorScoreMutation, Types.AddArtworkCuratorScoreMutationVariables>;
export const SendArtistBestPracticesEmailDocument = gql`
    mutation sendArtistBestPracticesEmail($user_context_id: Int!) {
  sendArtistBestPracticesEmail(user_context_id: $user_context_id)
}
    `;
export type SendArtistBestPracticesEmailMutationFn = Apollo.MutationFunction<Types.SendArtistBestPracticesEmailMutation, Types.SendArtistBestPracticesEmailMutationVariables>;

/**
 * __useSendArtistBestPracticesEmailMutation__
 *
 * To run a mutation, you first call `useSendArtistBestPracticesEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendArtistBestPracticesEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendArtistBestPracticesEmailMutation, { data, loading, error }] = useSendArtistBestPracticesEmailMutation({
 *   variables: {
 *      user_context_id: // value for 'user_context_id'
 *   },
 * });
 */
export function useSendArtistBestPracticesEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendArtistBestPracticesEmailMutation, Types.SendArtistBestPracticesEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendArtistBestPracticesEmailMutation, Types.SendArtistBestPracticesEmailMutationVariables>(SendArtistBestPracticesEmailDocument, options);
      }
export type SendArtistBestPracticesEmailMutationHookResult = ReturnType<typeof useSendArtistBestPracticesEmailMutation>;
export type SendArtistBestPracticesEmailMutationResult = Apollo.MutationResult<Types.SendArtistBestPracticesEmailMutation>;
export type SendArtistBestPracticesEmailMutationOptions = Apollo.BaseMutationOptions<Types.SendArtistBestPracticesEmailMutation, Types.SendArtistBestPracticesEmailMutationVariables>;
export const GetLastSentBestPracticesEmailDocument = gql`
    query getLastSentBestPracticesEmail($user_context_id: bigint_comparison_exp = {}) {
  Admin_to_user_notification(where: {user_context_id: $user_context_id}) {
    best_practices_email_last_sent
    user_context_id
  }
}
    `;

/**
 * __useGetLastSentBestPracticesEmailQuery__
 *
 * To run a query within a React component, call `useGetLastSentBestPracticesEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSentBestPracticesEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSentBestPracticesEmailQuery({
 *   variables: {
 *      user_context_id: // value for 'user_context_id'
 *   },
 * });
 */
export function useGetLastSentBestPracticesEmailQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLastSentBestPracticesEmailQuery, Types.GetLastSentBestPracticesEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLastSentBestPracticesEmailQuery, Types.GetLastSentBestPracticesEmailQueryVariables>(GetLastSentBestPracticesEmailDocument, options);
      }
export function useGetLastSentBestPracticesEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLastSentBestPracticesEmailQuery, Types.GetLastSentBestPracticesEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLastSentBestPracticesEmailQuery, Types.GetLastSentBestPracticesEmailQueryVariables>(GetLastSentBestPracticesEmailDocument, options);
        }
export type GetLastSentBestPracticesEmailQueryHookResult = ReturnType<typeof useGetLastSentBestPracticesEmailQuery>;
export type GetLastSentBestPracticesEmailLazyQueryHookResult = ReturnType<typeof useGetLastSentBestPracticesEmailLazyQuery>;
export type GetLastSentBestPracticesEmailQueryResult = Apollo.QueryResult<Types.GetLastSentBestPracticesEmailQuery, Types.GetLastSentBestPracticesEmailQueryVariables>;
export const SendArtistRequestMeetingEmailDocument = gql`
    mutation sendArtistRequestMeetingEmail($user_context_id: Int!) {
  sendArtistRequestMeetingEmail(user_context_id: $user_context_id)
}
    `;
export type SendArtistRequestMeetingEmailMutationFn = Apollo.MutationFunction<Types.SendArtistRequestMeetingEmailMutation, Types.SendArtistRequestMeetingEmailMutationVariables>;

/**
 * __useSendArtistRequestMeetingEmailMutation__
 *
 * To run a mutation, you first call `useSendArtistRequestMeetingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendArtistRequestMeetingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendArtistRequestMeetingEmailMutation, { data, loading, error }] = useSendArtistRequestMeetingEmailMutation({
 *   variables: {
 *      user_context_id: // value for 'user_context_id'
 *   },
 * });
 */
export function useSendArtistRequestMeetingEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendArtistRequestMeetingEmailMutation, Types.SendArtistRequestMeetingEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendArtistRequestMeetingEmailMutation, Types.SendArtistRequestMeetingEmailMutationVariables>(SendArtistRequestMeetingEmailDocument, options);
      }
export type SendArtistRequestMeetingEmailMutationHookResult = ReturnType<typeof useSendArtistRequestMeetingEmailMutation>;
export type SendArtistRequestMeetingEmailMutationResult = Apollo.MutationResult<Types.SendArtistRequestMeetingEmailMutation>;
export type SendArtistRequestMeetingEmailMutationOptions = Apollo.BaseMutationOptions<Types.SendArtistRequestMeetingEmailMutation, Types.SendArtistRequestMeetingEmailMutationVariables>;
export const GetLastSentRequestMeetingEmailDocument = gql`
    query getLastSentRequestMeetingEmail($user_context_id: bigint_comparison_exp = {}) {
  Admin_to_user_notification(where: {user_context_id: $user_context_id}) {
    request_meeting_email_last_sent
    user_context_id
  }
}
    `;

/**
 * __useGetLastSentRequestMeetingEmailQuery__
 *
 * To run a query within a React component, call `useGetLastSentRequestMeetingEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastSentRequestMeetingEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastSentRequestMeetingEmailQuery({
 *   variables: {
 *      user_context_id: // value for 'user_context_id'
 *   },
 * });
 */
export function useGetLastSentRequestMeetingEmailQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLastSentRequestMeetingEmailQuery, Types.GetLastSentRequestMeetingEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLastSentRequestMeetingEmailQuery, Types.GetLastSentRequestMeetingEmailQueryVariables>(GetLastSentRequestMeetingEmailDocument, options);
      }
export function useGetLastSentRequestMeetingEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLastSentRequestMeetingEmailQuery, Types.GetLastSentRequestMeetingEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLastSentRequestMeetingEmailQuery, Types.GetLastSentRequestMeetingEmailQueryVariables>(GetLastSentRequestMeetingEmailDocument, options);
        }
export type GetLastSentRequestMeetingEmailQueryHookResult = ReturnType<typeof useGetLastSentRequestMeetingEmailQuery>;
export type GetLastSentRequestMeetingEmailLazyQueryHookResult = ReturnType<typeof useGetLastSentRequestMeetingEmailLazyQuery>;
export type GetLastSentRequestMeetingEmailQueryResult = Apollo.QueryResult<Types.GetLastSentRequestMeetingEmailQuery, Types.GetLastSentRequestMeetingEmailQueryVariables>;
export const GetLoginTokenDocument = gql`
    query getLoginToken($user_email: String!) {
  AdminLoginToken(email: $user_email)
}
    `;

/**
 * __useGetLoginTokenQuery__
 *
 * To run a query within a React component, call `useGetLoginTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoginTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoginTokenQuery({
 *   variables: {
 *      user_email: // value for 'user_email'
 *   },
 * });
 */
export function useGetLoginTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLoginTokenQuery, Types.GetLoginTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLoginTokenQuery, Types.GetLoginTokenQueryVariables>(GetLoginTokenDocument, options);
      }
export function useGetLoginTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLoginTokenQuery, Types.GetLoginTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLoginTokenQuery, Types.GetLoginTokenQueryVariables>(GetLoginTokenDocument, options);
        }
export type GetLoginTokenQueryHookResult = ReturnType<typeof useGetLoginTokenQuery>;
export type GetLoginTokenLazyQueryHookResult = ReturnType<typeof useGetLoginTokenLazyQuery>;
export type GetLoginTokenQueryResult = Apollo.QueryResult<Types.GetLoginTokenQuery, Types.GetLoginTokenQueryVariables>;
export const GetAdminToolArtistCollectionsDocument = gql`
    query getAdminToolArtistCollections($artistContextId: bigint!) {
  collections: Artist_Collection(
    where: {_and: [{deleted_at: {_is_null: true}, type: {_eq: virtual_exhibition}}]}
  ) {
    id
    title
  }
  assignedCollections: Artist_Collection_to_Artist_Context(
    where: {_and: [{context_id: {_eq: $artistContextId}}, {deleted_at: {_is_null: true}}]}
  ) {
    id
    context_id
    artist_collection_id
  }
}
    `;

/**
 * __useGetAdminToolArtistCollectionsQuery__
 *
 * To run a query within a React component, call `useGetAdminToolArtistCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminToolArtistCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminToolArtistCollectionsQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetAdminToolArtistCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAdminToolArtistCollectionsQuery, Types.GetAdminToolArtistCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAdminToolArtistCollectionsQuery, Types.GetAdminToolArtistCollectionsQueryVariables>(GetAdminToolArtistCollectionsDocument, options);
      }
export function useGetAdminToolArtistCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAdminToolArtistCollectionsQuery, Types.GetAdminToolArtistCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAdminToolArtistCollectionsQuery, Types.GetAdminToolArtistCollectionsQueryVariables>(GetAdminToolArtistCollectionsDocument, options);
        }
export type GetAdminToolArtistCollectionsQueryHookResult = ReturnType<typeof useGetAdminToolArtistCollectionsQuery>;
export type GetAdminToolArtistCollectionsLazyQueryHookResult = ReturnType<typeof useGetAdminToolArtistCollectionsLazyQuery>;
export type GetAdminToolArtistCollectionsQueryResult = Apollo.QueryResult<Types.GetAdminToolArtistCollectionsQuery, Types.GetAdminToolArtistCollectionsQueryVariables>;
export const GetAdminToolArtistCategoriesDocument = gql`
    query getAdminToolArtistCategories($artistContextId: bigint!) {
  categories: Interests(where: {_and: [{active: {_eq: true}}]}) {
    id
    translation_key
  }
  assignedCategories: Profile_Interests(
    where: {_and: [{context_id: {_eq: $artistContextId}}]}
  ) {
    id
    context_id
    interest_id
  }
}
    `;

/**
 * __useGetAdminToolArtistCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAdminToolArtistCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminToolArtistCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminToolArtistCategoriesQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetAdminToolArtistCategoriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAdminToolArtistCategoriesQuery, Types.GetAdminToolArtistCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAdminToolArtistCategoriesQuery, Types.GetAdminToolArtistCategoriesQueryVariables>(GetAdminToolArtistCategoriesDocument, options);
      }
export function useGetAdminToolArtistCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAdminToolArtistCategoriesQuery, Types.GetAdminToolArtistCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAdminToolArtistCategoriesQuery, Types.GetAdminToolArtistCategoriesQueryVariables>(GetAdminToolArtistCategoriesDocument, options);
        }
export type GetAdminToolArtistCategoriesQueryHookResult = ReturnType<typeof useGetAdminToolArtistCategoriesQuery>;
export type GetAdminToolArtistCategoriesLazyQueryHookResult = ReturnType<typeof useGetAdminToolArtistCategoriesLazyQuery>;
export type GetAdminToolArtistCategoriesQueryResult = Apollo.QueryResult<Types.GetAdminToolArtistCategoriesQuery, Types.GetAdminToolArtistCategoriesQueryVariables>;
export const EditArtistCategoriesDocument = gql`
    mutation editArtistCategories($artist_context_id: bigint!, $interestIds: [bigint!]!) {
  editArtistCategories(
    artistContextId: $artist_context_id
    interestIds: $interestIds
  )
}
    `;
export type EditArtistCategoriesMutationFn = Apollo.MutationFunction<Types.EditArtistCategoriesMutation, Types.EditArtistCategoriesMutationVariables>;

/**
 * __useEditArtistCategoriesMutation__
 *
 * To run a mutation, you first call `useEditArtistCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditArtistCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editArtistCategoriesMutation, { data, loading, error }] = useEditArtistCategoriesMutation({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *      interestIds: // value for 'interestIds'
 *   },
 * });
 */
export function useEditArtistCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditArtistCategoriesMutation, Types.EditArtistCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditArtistCategoriesMutation, Types.EditArtistCategoriesMutationVariables>(EditArtistCategoriesDocument, options);
      }
export type EditArtistCategoriesMutationHookResult = ReturnType<typeof useEditArtistCategoriesMutation>;
export type EditArtistCategoriesMutationResult = Apollo.MutationResult<Types.EditArtistCategoriesMutation>;
export type EditArtistCategoriesMutationOptions = Apollo.BaseMutationOptions<Types.EditArtistCategoriesMutation, Types.EditArtistCategoriesMutationVariables>;
export const EditArtistCollectionsDocument = gql`
    mutation editArtistCollections($artist_context_id: bigint!, $collectionIds: [bigint!]!) {
  editArtistCollections(
    artistContextId: $artist_context_id
    collectionIds: $collectionIds
  )
}
    `;
export type EditArtistCollectionsMutationFn = Apollo.MutationFunction<Types.EditArtistCollectionsMutation, Types.EditArtistCollectionsMutationVariables>;

/**
 * __useEditArtistCollectionsMutation__
 *
 * To run a mutation, you first call `useEditArtistCollectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditArtistCollectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editArtistCollectionsMutation, { data, loading, error }] = useEditArtistCollectionsMutation({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *      collectionIds: // value for 'collectionIds'
 *   },
 * });
 */
export function useEditArtistCollectionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditArtistCollectionsMutation, Types.EditArtistCollectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EditArtistCollectionsMutation, Types.EditArtistCollectionsMutationVariables>(EditArtistCollectionsDocument, options);
      }
export type EditArtistCollectionsMutationHookResult = ReturnType<typeof useEditArtistCollectionsMutation>;
export type EditArtistCollectionsMutationResult = Apollo.MutationResult<Types.EditArtistCollectionsMutation>;
export type EditArtistCollectionsMutationOptions = Apollo.BaseMutationOptions<Types.EditArtistCollectionsMutation, Types.EditArtistCollectionsMutationVariables>;
export const GetSpotlightForArtistDocument = gql`
    query GetSpotlightForArtist($artistContextId: bigint!) {
  Spotlight_context(where: {context_id: {_eq: $artistContextId}}) {
    id
  }
}
    `;

/**
 * __useGetSpotlightForArtistQuery__
 *
 * To run a query within a React component, call `useGetSpotlightForArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotlightForArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotlightForArtistQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetSpotlightForArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSpotlightForArtistQuery, Types.GetSpotlightForArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSpotlightForArtistQuery, Types.GetSpotlightForArtistQueryVariables>(GetSpotlightForArtistDocument, options);
      }
export function useGetSpotlightForArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSpotlightForArtistQuery, Types.GetSpotlightForArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSpotlightForArtistQuery, Types.GetSpotlightForArtistQueryVariables>(GetSpotlightForArtistDocument, options);
        }
export type GetSpotlightForArtistQueryHookResult = ReturnType<typeof useGetSpotlightForArtistQuery>;
export type GetSpotlightForArtistLazyQueryHookResult = ReturnType<typeof useGetSpotlightForArtistLazyQuery>;
export type GetSpotlightForArtistQueryResult = Apollo.QueryResult<Types.GetSpotlightForArtistQuery, Types.GetSpotlightForArtistQueryVariables>;
export const DeactivateAllSpotlightsDocument = gql`
    mutation deactivateAllSpotlights {
  update_Spotlight_context(
    where: {is_enabled: {_eq: true}}
    _set: {is_enabled: false, end_at: "now()"}
  ) {
    affected_rows
  }
}
    `;
export type DeactivateAllSpotlightsMutationFn = Apollo.MutationFunction<Types.DeactivateAllSpotlightsMutation, Types.DeactivateAllSpotlightsMutationVariables>;

/**
 * __useDeactivateAllSpotlightsMutation__
 *
 * To run a mutation, you first call `useDeactivateAllSpotlightsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateAllSpotlightsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateAllSpotlightsMutation, { data, loading, error }] = useDeactivateAllSpotlightsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeactivateAllSpotlightsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeactivateAllSpotlightsMutation, Types.DeactivateAllSpotlightsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeactivateAllSpotlightsMutation, Types.DeactivateAllSpotlightsMutationVariables>(DeactivateAllSpotlightsDocument, options);
      }
export type DeactivateAllSpotlightsMutationHookResult = ReturnType<typeof useDeactivateAllSpotlightsMutation>;
export type DeactivateAllSpotlightsMutationResult = Apollo.MutationResult<Types.DeactivateAllSpotlightsMutation>;
export type DeactivateAllSpotlightsMutationOptions = Apollo.BaseMutationOptions<Types.DeactivateAllSpotlightsMutation, Types.DeactivateAllSpotlightsMutationVariables>;
export const AddSpotlightContextDocument = gql`
    mutation addSpotlightContext($contextId: bigint!, $startAt: timestamptz!, $endAt: timestamptz!, $statement: String) {
  insert_Spotlight_context_one(
    object: {context_id: $contextId, start_at: $startAt, end_at: $endAt, statement: $statement}
  ) {
    id
  }
}
    `;
export type AddSpotlightContextMutationFn = Apollo.MutationFunction<Types.AddSpotlightContextMutation, Types.AddSpotlightContextMutationVariables>;

/**
 * __useAddSpotlightContextMutation__
 *
 * To run a mutation, you first call `useAddSpotlightContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSpotlightContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSpotlightContextMutation, { data, loading, error }] = useAddSpotlightContextMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      statement: // value for 'statement'
 *   },
 * });
 */
export function useAddSpotlightContextMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddSpotlightContextMutation, Types.AddSpotlightContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddSpotlightContextMutation, Types.AddSpotlightContextMutationVariables>(AddSpotlightContextDocument, options);
      }
export type AddSpotlightContextMutationHookResult = ReturnType<typeof useAddSpotlightContextMutation>;
export type AddSpotlightContextMutationResult = Apollo.MutationResult<Types.AddSpotlightContextMutation>;
export type AddSpotlightContextMutationOptions = Apollo.BaseMutationOptions<Types.AddSpotlightContextMutation, Types.AddSpotlightContextMutationVariables>;
export const UpdateSpotlightContextDocument = gql`
    mutation updateSpotlightContext($id: bigint!, $updatedSpotlight: Spotlight_context_set_input!) {
  update_Spotlight_context_by_pk(pk_columns: {id: $id}, _set: $updatedSpotlight) {
    id
  }
}
    `;
export type UpdateSpotlightContextMutationFn = Apollo.MutationFunction<Types.UpdateSpotlightContextMutation, Types.UpdateSpotlightContextMutationVariables>;

/**
 * __useUpdateSpotlightContextMutation__
 *
 * To run a mutation, you first call `useUpdateSpotlightContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpotlightContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpotlightContextMutation, { data, loading, error }] = useUpdateSpotlightContextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedSpotlight: // value for 'updatedSpotlight'
 *   },
 * });
 */
export function useUpdateSpotlightContextMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSpotlightContextMutation, Types.UpdateSpotlightContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSpotlightContextMutation, Types.UpdateSpotlightContextMutationVariables>(UpdateSpotlightContextDocument, options);
      }
export type UpdateSpotlightContextMutationHookResult = ReturnType<typeof useUpdateSpotlightContextMutation>;
export type UpdateSpotlightContextMutationResult = Apollo.MutationResult<Types.UpdateSpotlightContextMutation>;
export type UpdateSpotlightContextMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSpotlightContextMutation, Types.UpdateSpotlightContextMutationVariables>;
export const GetActiveSpotlightDocument = gql`
    query GetActiveSpotlight {
  Spotlight_artist(where: {is_showing_now: {_eq: true}}) {
    contextId: context_id
    profileId: profile_id
    title
    city
    country
    avatar
    statement
    handle
    cardgridArtworks: cardgrid_artworks(limit: 5) {
      id
      thumbnailMetadataId: thumbnail_metadata_id
    }
  }
}
    `;

/**
 * __useGetActiveSpotlightQuery__
 *
 * To run a query within a React component, call `useGetActiveSpotlightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSpotlightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSpotlightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveSpotlightQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetActiveSpotlightQuery, Types.GetActiveSpotlightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetActiveSpotlightQuery, Types.GetActiveSpotlightQueryVariables>(GetActiveSpotlightDocument, options);
      }
export function useGetActiveSpotlightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetActiveSpotlightQuery, Types.GetActiveSpotlightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetActiveSpotlightQuery, Types.GetActiveSpotlightQueryVariables>(GetActiveSpotlightDocument, options);
        }
export type GetActiveSpotlightQueryHookResult = ReturnType<typeof useGetActiveSpotlightQuery>;
export type GetActiveSpotlightLazyQueryHookResult = ReturnType<typeof useGetActiveSpotlightLazyQuery>;
export type GetActiveSpotlightQueryResult = Apollo.QueryResult<Types.GetActiveSpotlightQuery, Types.GetActiveSpotlightQueryVariables>;
export const GenerateAudioStatementDocument = gql`
    mutation generateAudioStatement($language: String!, $profileId: bigint!, $text: String!) {
  generateAudioStatement(language: $language, profileId: $profileId, text: $text) {
    id
    audioGenerationStatus
    audioId
    failReason
  }
}
    `;
export type GenerateAudioStatementMutationFn = Apollo.MutationFunction<Types.GenerateAudioStatementMutation, Types.GenerateAudioStatementMutationVariables>;

/**
 * __useGenerateAudioStatementMutation__
 *
 * To run a mutation, you first call `useGenerateAudioStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAudioStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAudioStatementMutation, { data, loading, error }] = useGenerateAudioStatementMutation({
 *   variables: {
 *      language: // value for 'language'
 *      profileId: // value for 'profileId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useGenerateAudioStatementMutation(baseOptions?: Apollo.MutationHookOptions<Types.GenerateAudioStatementMutation, Types.GenerateAudioStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GenerateAudioStatementMutation, Types.GenerateAudioStatementMutationVariables>(GenerateAudioStatementDocument, options);
      }
export type GenerateAudioStatementMutationHookResult = ReturnType<typeof useGenerateAudioStatementMutation>;
export type GenerateAudioStatementMutationResult = Apollo.MutationResult<Types.GenerateAudioStatementMutation>;
export type GenerateAudioStatementMutationOptions = Apollo.BaseMutationOptions<Types.GenerateAudioStatementMutation, Types.GenerateAudioStatementMutationVariables>;
export const DisableExistingValidAiAudioStatementsDocument = gql`
    mutation disableExistingValidAIAudioStatements($profileId: bigint!) {
  disableExistingValidAIAudioStatements(profileId: $profileId)
}
    `;
export type DisableExistingValidAiAudioStatementsMutationFn = Apollo.MutationFunction<Types.DisableExistingValidAiAudioStatementsMutation, Types.DisableExistingValidAiAudioStatementsMutationVariables>;

/**
 * __useDisableExistingValidAiAudioStatementsMutation__
 *
 * To run a mutation, you first call `useDisableExistingValidAiAudioStatementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableExistingValidAiAudioStatementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableExistingValidAiAudioStatementsMutation, { data, loading, error }] = useDisableExistingValidAiAudioStatementsMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useDisableExistingValidAiAudioStatementsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DisableExistingValidAiAudioStatementsMutation, Types.DisableExistingValidAiAudioStatementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DisableExistingValidAiAudioStatementsMutation, Types.DisableExistingValidAiAudioStatementsMutationVariables>(DisableExistingValidAiAudioStatementsDocument, options);
      }
export type DisableExistingValidAiAudioStatementsMutationHookResult = ReturnType<typeof useDisableExistingValidAiAudioStatementsMutation>;
export type DisableExistingValidAiAudioStatementsMutationResult = Apollo.MutationResult<Types.DisableExistingValidAiAudioStatementsMutation>;
export type DisableExistingValidAiAudioStatementsMutationOptions = Apollo.BaseMutationOptions<Types.DisableExistingValidAiAudioStatementsMutation, Types.DisableExistingValidAiAudioStatementsMutationVariables>;
export const GetProfileAudioStatementDataDocument = gql`
    query getProfileAudioStatementData($contextId: bigint!) {
  Context_by_pk(id: $contextId) {
    profile_id
    type
    state
    profile {
      preferred_language
      Valid_profile_statement_audio_syntheses {
        audio_id
        language
        profile_id
        text
      }
      email
      status
      audio
      handle
    }
  }
}
    `;

/**
 * __useGetProfileAudioStatementDataQuery__
 *
 * To run a query within a React component, call `useGetProfileAudioStatementDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileAudioStatementDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileAudioStatementDataQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileAudioStatementDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileAudioStatementDataQuery, Types.GetProfileAudioStatementDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileAudioStatementDataQuery, Types.GetProfileAudioStatementDataQueryVariables>(GetProfileAudioStatementDataDocument, options);
      }
export function useGetProfileAudioStatementDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileAudioStatementDataQuery, Types.GetProfileAudioStatementDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileAudioStatementDataQuery, Types.GetProfileAudioStatementDataQueryVariables>(GetProfileAudioStatementDataDocument, options);
        }
export type GetProfileAudioStatementDataQueryHookResult = ReturnType<typeof useGetProfileAudioStatementDataQuery>;
export type GetProfileAudioStatementDataLazyQueryHookResult = ReturnType<typeof useGetProfileAudioStatementDataLazyQuery>;
export type GetProfileAudioStatementDataQueryResult = Apollo.QueryResult<Types.GetProfileAudioStatementDataQuery, Types.GetProfileAudioStatementDataQueryVariables>;
export const GetFilteredExploreCurationArtistsDocument = gql`
    query getFilteredExploreCurationArtists($limit: Int = 10, $offset: Int = 0, $where: Explore_artists_curation_score_bool_exp = {}) {
  Explore_artists_curation_score(
    limit: $limit
    offset: $offset
    where: {_and: [$where]}
  ) {
    score
    artist_title
    artworks_count
    region
    context_id
    artworks: exploreArtworks(limit: 3, order_by: {id: asc}) {
      id
      slug
      is_public
      primary_image_metadata_id
      thumbnail_metadata_id
      title
    }
    context {
      profile {
        id
        avatar
        title
        first_name
        last_name
        handle
        created_at
      }
      locations {
        country
        city
        name
        region
      }
    }
    disciplines {
      discipline
      context_id
    }
  }
}
    `;

/**
 * __useGetFilteredExploreCurationArtistsQuery__
 *
 * To run a query within a React component, call `useGetFilteredExploreCurationArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredExploreCurationArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredExploreCurationArtistsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFilteredExploreCurationArtistsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFilteredExploreCurationArtistsQuery, Types.GetFilteredExploreCurationArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFilteredExploreCurationArtistsQuery, Types.GetFilteredExploreCurationArtistsQueryVariables>(GetFilteredExploreCurationArtistsDocument, options);
      }
export function useGetFilteredExploreCurationArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilteredExploreCurationArtistsQuery, Types.GetFilteredExploreCurationArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFilteredExploreCurationArtistsQuery, Types.GetFilteredExploreCurationArtistsQueryVariables>(GetFilteredExploreCurationArtistsDocument, options);
        }
export type GetFilteredExploreCurationArtistsQueryHookResult = ReturnType<typeof useGetFilteredExploreCurationArtistsQuery>;
export type GetFilteredExploreCurationArtistsLazyQueryHookResult = ReturnType<typeof useGetFilteredExploreCurationArtistsLazyQuery>;
export type GetFilteredExploreCurationArtistsQueryResult = Apollo.QueryResult<Types.GetFilteredExploreCurationArtistsQuery, Types.GetFilteredExploreCurationArtistsQueryVariables>;
export const GetExplorePageArtistsNumberDocument = gql`
    query getExplorePageArtistsNumber($where: Explore_artists_curation_score_bool_exp = {}) {
  Explore_artists_curation_score_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetExplorePageArtistsNumberQuery__
 *
 * To run a query within a React component, call `useGetExplorePageArtistsNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExplorePageArtistsNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExplorePageArtistsNumberQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetExplorePageArtistsNumberQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetExplorePageArtistsNumberQuery, Types.GetExplorePageArtistsNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExplorePageArtistsNumberQuery, Types.GetExplorePageArtistsNumberQueryVariables>(GetExplorePageArtistsNumberDocument, options);
      }
export function useGetExplorePageArtistsNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExplorePageArtistsNumberQuery, Types.GetExplorePageArtistsNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExplorePageArtistsNumberQuery, Types.GetExplorePageArtistsNumberQueryVariables>(GetExplorePageArtistsNumberDocument, options);
        }
export type GetExplorePageArtistsNumberQueryHookResult = ReturnType<typeof useGetExplorePageArtistsNumberQuery>;
export type GetExplorePageArtistsNumberLazyQueryHookResult = ReturnType<typeof useGetExplorePageArtistsNumberLazyQuery>;
export type GetExplorePageArtistsNumberQueryResult = Apollo.QueryResult<Types.GetExplorePageArtistsNumberQuery, Types.GetExplorePageArtistsNumberQueryVariables>;
export const SendArtUniversePushNotificationDocument = gql`
    mutation sendArtUniversePushNotification($message: String!) {
  sendArtUniversePush(message: $message)
}
    `;
export type SendArtUniversePushNotificationMutationFn = Apollo.MutationFunction<Types.SendArtUniversePushNotificationMutation, Types.SendArtUniversePushNotificationMutationVariables>;

/**
 * __useSendArtUniversePushNotificationMutation__
 *
 * To run a mutation, you first call `useSendArtUniversePushNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendArtUniversePushNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendArtUniversePushNotificationMutation, { data, loading, error }] = useSendArtUniversePushNotificationMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendArtUniversePushNotificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendArtUniversePushNotificationMutation, Types.SendArtUniversePushNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendArtUniversePushNotificationMutation, Types.SendArtUniversePushNotificationMutationVariables>(SendArtUniversePushNotificationDocument, options);
      }
export type SendArtUniversePushNotificationMutationHookResult = ReturnType<typeof useSendArtUniversePushNotificationMutation>;
export type SendArtUniversePushNotificationMutationResult = Apollo.MutationResult<Types.SendArtUniversePushNotificationMutation>;
export type SendArtUniversePushNotificationMutationOptions = Apollo.BaseMutationOptions<Types.SendArtUniversePushNotificationMutation, Types.SendArtUniversePushNotificationMutationVariables>;
export const GetAdminTableProfilesDocument = gql`
    query getAdminTableProfiles($where: Admin_table_profiles_v2_bool_exp!, $order_by: [Admin_table_profiles_v2_order_by!], $limit: Int!, $offset: Int!) {
  Admin_table_profiles_v2(
    where: $where
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    context_id
    title
    handle
    city
    country
    instagram_url
    artwork_count
    email
    status
    legal_name
    profile_collaboration_code
    preferred_language
    created_at
  }
  counter: Admin_table_profiles_v2_aggregate(where: $where) {
    aggregate {
      count(columns: context_id)
    }
  }
}
    `;

/**
 * __useGetAdminTableProfilesQuery__
 *
 * To run a query within a React component, call `useGetAdminTableProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminTableProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminTableProfilesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAdminTableProfilesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAdminTableProfilesQuery, Types.GetAdminTableProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAdminTableProfilesQuery, Types.GetAdminTableProfilesQueryVariables>(GetAdminTableProfilesDocument, options);
      }
export function useGetAdminTableProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAdminTableProfilesQuery, Types.GetAdminTableProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAdminTableProfilesQuery, Types.GetAdminTableProfilesQueryVariables>(GetAdminTableProfilesDocument, options);
        }
export type GetAdminTableProfilesQueryHookResult = ReturnType<typeof useGetAdminTableProfilesQuery>;
export type GetAdminTableProfilesLazyQueryHookResult = ReturnType<typeof useGetAdminTableProfilesLazyQuery>;
export type GetAdminTableProfilesQueryResult = Apollo.QueryResult<Types.GetAdminTableProfilesQuery, Types.GetAdminTableProfilesQueryVariables>;
export const GetArtPrizeShowDocument = gql`
    query getArtPrizeShow($artPrizeSlug: String!) {
  Art_prize_show(
    where: {prize_show_slug: {_eq: $artPrizeSlug}, deleted_at: {_is_null: true}, published_at: {_lt: "now()"}}
  ) {
    id
    title
    subtitle
    description
    amount_participants
    prize_show_slug
    social_media_url
    background_metadata_id
    thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetArtPrizeShowQuery__
 *
 * To run a query within a React component, call `useGetArtPrizeShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtPrizeShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtPrizeShowQuery({
 *   variables: {
 *      artPrizeSlug: // value for 'artPrizeSlug'
 *   },
 * });
 */
export function useGetArtPrizeShowQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtPrizeShowQuery, Types.GetArtPrizeShowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtPrizeShowQuery, Types.GetArtPrizeShowQueryVariables>(GetArtPrizeShowDocument, options);
      }
export function useGetArtPrizeShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtPrizeShowQuery, Types.GetArtPrizeShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtPrizeShowQuery, Types.GetArtPrizeShowQueryVariables>(GetArtPrizeShowDocument, options);
        }
export type GetArtPrizeShowQueryHookResult = ReturnType<typeof useGetArtPrizeShowQuery>;
export type GetArtPrizeShowLazyQueryHookResult = ReturnType<typeof useGetArtPrizeShowLazyQuery>;
export type GetArtPrizeShowQueryResult = Apollo.QueryResult<Types.GetArtPrizeShowQuery, Types.GetArtPrizeShowQueryVariables>;
export const GetArtPrizeShowWinnersV2Document = gql`
    query getArtPrizeShowWinnersV2($artPrizeShowId: bigint!) {
  getArtPrizeShowWinners(args: {_art_prize_show_id: $artPrizeShowId}) {
    art_prize_winner_id
    art_prize_show_id
    position_type
    art_prize_winner_order
    artist_context_id
    city
    country
    avatar
    handle
    profile_id
    title
    first_name
    last_name
    quote
    artwork_id
    artwork_slug
    artwork_thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetArtPrizeShowWinnersV2Query__
 *
 * To run a query within a React component, call `useGetArtPrizeShowWinnersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetArtPrizeShowWinnersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtPrizeShowWinnersV2Query({
 *   variables: {
 *      artPrizeShowId: // value for 'artPrizeShowId'
 *   },
 * });
 */
export function useGetArtPrizeShowWinnersV2Query(baseOptions: Apollo.QueryHookOptions<Types.GetArtPrizeShowWinnersV2Query, Types.GetArtPrizeShowWinnersV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtPrizeShowWinnersV2Query, Types.GetArtPrizeShowWinnersV2QueryVariables>(GetArtPrizeShowWinnersV2Document, options);
      }
export function useGetArtPrizeShowWinnersV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtPrizeShowWinnersV2Query, Types.GetArtPrizeShowWinnersV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtPrizeShowWinnersV2Query, Types.GetArtPrizeShowWinnersV2QueryVariables>(GetArtPrizeShowWinnersV2Document, options);
        }
export type GetArtPrizeShowWinnersV2QueryHookResult = ReturnType<typeof useGetArtPrizeShowWinnersV2Query>;
export type GetArtPrizeShowWinnersV2LazyQueryHookResult = ReturnType<typeof useGetArtPrizeShowWinnersV2LazyQuery>;
export type GetArtPrizeShowWinnersV2QueryResult = Apollo.QueryResult<Types.GetArtPrizeShowWinnersV2Query, Types.GetArtPrizeShowWinnersV2QueryVariables>;
export const GetFeaturedArtPrizesDocument = gql`
    query getFeaturedArtPrizes {
  Art_prize_show(
    where: {_and: [{deleted_at: {_is_null: true}, published_at: {_is_null: false}}, {published_at: {_lte: "now()"}}]}
  ) {
    id
    title
    subtitle
    description
    short_description
    background_metadata_id
    thumbnail_metadata_id
    amount_participants
    prize_show_slug
    published_at
    Art_prize_winners {
      artist_context_id
    }
  }
}
    `;

/**
 * __useGetFeaturedArtPrizesQuery__
 *
 * To run a query within a React component, call `useGetFeaturedArtPrizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedArtPrizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedArtPrizesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturedArtPrizesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFeaturedArtPrizesQuery, Types.GetFeaturedArtPrizesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFeaturedArtPrizesQuery, Types.GetFeaturedArtPrizesQueryVariables>(GetFeaturedArtPrizesDocument, options);
      }
export function useGetFeaturedArtPrizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFeaturedArtPrizesQuery, Types.GetFeaturedArtPrizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFeaturedArtPrizesQuery, Types.GetFeaturedArtPrizesQueryVariables>(GetFeaturedArtPrizesDocument, options);
        }
export type GetFeaturedArtPrizesQueryHookResult = ReturnType<typeof useGetFeaturedArtPrizesQuery>;
export type GetFeaturedArtPrizesLazyQueryHookResult = ReturnType<typeof useGetFeaturedArtPrizesLazyQuery>;
export type GetFeaturedArtPrizesQueryResult = Apollo.QueryResult<Types.GetFeaturedArtPrizesQuery, Types.GetFeaturedArtPrizesQueryVariables>;
export const GetArtShowArtworkInfoDocument = gql`
    query GetArtShowArtworkInfo($callerCtxId: bigint, $artworks: [bigint!]!, $artists: [bigint!]!, $skipRecognitions: Boolean!, $skipFavorites: Boolean!) {
  artworks: Artwork_explore_info(
    where: {_and: [{artwork_id: {_in: $artworks}}, {_or: [{last_transaction_artwork_id: {_is_null: true}}, {last_transaction_artwork_id: {_in: $artworks}}]}]}
  ) {
    artworkId: artwork_id
    thumbnailMetadataId: thumbnail_metadata_id
    primaryMetadataId: primary_image_metadata_id
    artistContextId: artist_context_id
    isAvailableSale: is_available_sale
    title
    width
    height
    depth
    slug
    discipline
    editionType: edition_type
    measuringUnit: measuring_unit
    lastTransactionId: last_transaction_id
    availablePrintAmount: available_print_amount
    editionIsAvailableSale: edition_is_available_sale
    originalArtworkId: original_artwork_id
  }
  artists: Artist_explore_info(where: {artist_context_id: {_in: $artists}}) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    country
    city
  }
  collectedDetails: Artwork_collected_listing(
    where: {artwork_id: {_in: $artworks}}
  ) {
    artworkId: artwork_id
    publishedDate: published_date
    collector: collector_explore_info {
      contextId: collector_context_id
      profileId: collector_profile_id
      title
      handle
      avatar
    }
    gallery: gallery_explore_info {
      contextId: gallery_context_id
      profileId: gallery_profile_id
      title
      handle
      avatar
      country
      city
    }
  }
  recognitions: Social_graph_followers(
    where: {context_id: {_eq: $callerCtxId}, target_context_id: {_in: $artists}}
  ) @skip(if: $skipRecognitions) {
    artistContextId: target_context_id
    state
    isMutual: is_mutual
    naturalOrder: natural_order
    selectedOrder: selected_order
  }
  favorites: Artwork_to_Collection(
    where: {artwork_id: {_in: $artworks}, Collection: {owner_context_id: {_eq: $callerCtxId}, type: {_eq: FAVORITE}}}
  ) @skip(if: $skipFavorites) {
    artworkId: artwork_id
  }
}
    `;

/**
 * __useGetArtShowArtworkInfoQuery__
 *
 * To run a query within a React component, call `useGetArtShowArtworkInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtShowArtworkInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtShowArtworkInfoQuery({
 *   variables: {
 *      callerCtxId: // value for 'callerCtxId'
 *      artworks: // value for 'artworks'
 *      artists: // value for 'artists'
 *      skipRecognitions: // value for 'skipRecognitions'
 *      skipFavorites: // value for 'skipFavorites'
 *   },
 * });
 */
export function useGetArtShowArtworkInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtShowArtworkInfoQuery, Types.GetArtShowArtworkInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtShowArtworkInfoQuery, Types.GetArtShowArtworkInfoQueryVariables>(GetArtShowArtworkInfoDocument, options);
      }
export function useGetArtShowArtworkInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtShowArtworkInfoQuery, Types.GetArtShowArtworkInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtShowArtworkInfoQuery, Types.GetArtShowArtworkInfoQueryVariables>(GetArtShowArtworkInfoDocument, options);
        }
export type GetArtShowArtworkInfoQueryHookResult = ReturnType<typeof useGetArtShowArtworkInfoQuery>;
export type GetArtShowArtworkInfoLazyQueryHookResult = ReturnType<typeof useGetArtShowArtworkInfoLazyQuery>;
export type GetArtShowArtworkInfoQueryResult = Apollo.QueryResult<Types.GetArtShowArtworkInfoQuery, Types.GetArtShowArtworkInfoQueryVariables>;
export const GetArtShowArtworksListingBySizeDocument = gql`
    query GetArtShowArtworksListingBySize($limit: Int!, $appliedSorts: [Art_show_artwork_listing_order_by!], $appliedFilters: Art_show_artwork_listing_bool_exp!) {
  artworks: Art_show_artwork_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonArtShowArtworkListingCursor
    area
  }
}
    ${CommonArtShowArtworkListingCursorFragmentDoc}`;

/**
 * __useGetArtShowArtworksListingBySizeQuery__
 *
 * To run a query within a React component, call `useGetArtShowArtworksListingBySizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtShowArtworksListingBySizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtShowArtworksListingBySizeQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetArtShowArtworksListingBySizeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtShowArtworksListingBySizeQuery, Types.GetArtShowArtworksListingBySizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtShowArtworksListingBySizeQuery, Types.GetArtShowArtworksListingBySizeQueryVariables>(GetArtShowArtworksListingBySizeDocument, options);
      }
export function useGetArtShowArtworksListingBySizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtShowArtworksListingBySizeQuery, Types.GetArtShowArtworksListingBySizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtShowArtworksListingBySizeQuery, Types.GetArtShowArtworksListingBySizeQueryVariables>(GetArtShowArtworksListingBySizeDocument, options);
        }
export type GetArtShowArtworksListingBySizeQueryHookResult = ReturnType<typeof useGetArtShowArtworksListingBySizeQuery>;
export type GetArtShowArtworksListingBySizeLazyQueryHookResult = ReturnType<typeof useGetArtShowArtworksListingBySizeLazyQuery>;
export type GetArtShowArtworksListingBySizeQueryResult = Apollo.QueryResult<Types.GetArtShowArtworksListingBySizeQuery, Types.GetArtShowArtworksListingBySizeQueryVariables>;
export const GetArtShowArtworksListingByPriceDocument = gql`
    query GetArtShowArtworksListingByPrice($limit: Int!, $appliedSorts: [Art_show_artwork_listing_order_by!], $appliedFilters: Art_show_artwork_listing_bool_exp!) {
  artworks: Art_show_artwork_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonArtShowArtworkListingCursor
    price
  }
}
    ${CommonArtShowArtworkListingCursorFragmentDoc}`;

/**
 * __useGetArtShowArtworksListingByPriceQuery__
 *
 * To run a query within a React component, call `useGetArtShowArtworksListingByPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtShowArtworksListingByPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtShowArtworksListingByPriceQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetArtShowArtworksListingByPriceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtShowArtworksListingByPriceQuery, Types.GetArtShowArtworksListingByPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtShowArtworksListingByPriceQuery, Types.GetArtShowArtworksListingByPriceQueryVariables>(GetArtShowArtworksListingByPriceDocument, options);
      }
export function useGetArtShowArtworksListingByPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtShowArtworksListingByPriceQuery, Types.GetArtShowArtworksListingByPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtShowArtworksListingByPriceQuery, Types.GetArtShowArtworksListingByPriceQueryVariables>(GetArtShowArtworksListingByPriceDocument, options);
        }
export type GetArtShowArtworksListingByPriceQueryHookResult = ReturnType<typeof useGetArtShowArtworksListingByPriceQuery>;
export type GetArtShowArtworksListingByPriceLazyQueryHookResult = ReturnType<typeof useGetArtShowArtworksListingByPriceLazyQuery>;
export type GetArtShowArtworksListingByPriceQueryResult = Apollo.QueryResult<Types.GetArtShowArtworksListingByPriceQuery, Types.GetArtShowArtworksListingByPriceQueryVariables>;
export const GetAnnouncedArtShowsDocument = gql`
    query GetAnnouncedArtShows {
  Announced_art_show(where: {is_announced: {_eq: true}}) {
    ...AnnouncedArtShowDetails
  }
}
    ${AnnouncedArtShowDetailsFragmentDoc}`;

/**
 * __useGetAnnouncedArtShowsQuery__
 *
 * To run a query within a React component, call `useGetAnnouncedArtShowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncedArtShowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncedArtShowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnnouncedArtShowsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAnnouncedArtShowsQuery, Types.GetAnnouncedArtShowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAnnouncedArtShowsQuery, Types.GetAnnouncedArtShowsQueryVariables>(GetAnnouncedArtShowsDocument, options);
      }
export function useGetAnnouncedArtShowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAnnouncedArtShowsQuery, Types.GetAnnouncedArtShowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAnnouncedArtShowsQuery, Types.GetAnnouncedArtShowsQueryVariables>(GetAnnouncedArtShowsDocument, options);
        }
export type GetAnnouncedArtShowsQueryHookResult = ReturnType<typeof useGetAnnouncedArtShowsQuery>;
export type GetAnnouncedArtShowsLazyQueryHookResult = ReturnType<typeof useGetAnnouncedArtShowsLazyQuery>;
export type GetAnnouncedArtShowsQueryResult = Apollo.QueryResult<Types.GetAnnouncedArtShowsQuery, Types.GetAnnouncedArtShowsQueryVariables>;
export const GetArtShowByUriDocument = gql`
    query GetArtShowByUri($uri: String = "") {
  Announced_art_show(where: {uri: {_eq: $uri}}) {
    ...AnnouncedArtShowDetails
  }
}
    ${AnnouncedArtShowDetailsFragmentDoc}`;

/**
 * __useGetArtShowByUriQuery__
 *
 * To run a query within a React component, call `useGetArtShowByUriQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtShowByUriQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtShowByUriQuery({
 *   variables: {
 *      uri: // value for 'uri'
 *   },
 * });
 */
export function useGetArtShowByUriQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtShowByUriQuery, Types.GetArtShowByUriQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtShowByUriQuery, Types.GetArtShowByUriQueryVariables>(GetArtShowByUriDocument, options);
      }
export function useGetArtShowByUriLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtShowByUriQuery, Types.GetArtShowByUriQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtShowByUriQuery, Types.GetArtShowByUriQueryVariables>(GetArtShowByUriDocument, options);
        }
export type GetArtShowByUriQueryHookResult = ReturnType<typeof useGetArtShowByUriQuery>;
export type GetArtShowByUriLazyQueryHookResult = ReturnType<typeof useGetArtShowByUriLazyQuery>;
export type GetArtShowByUriQueryResult = Apollo.QueryResult<Types.GetArtShowByUriQuery, Types.GetArtShowByUriQueryVariables>;
export const GetArtistManageArtworksListingByArtistOrderDocument = gql`
    query GetArtistManageArtworksListingByArtistOrder($limit: Int!, $appliedSorts: [artwork_manager_view_order_by!], $appliedFilters: artwork_manager_view_bool_exp!) {
  artworks: artwork_manager_view(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonArtworkManagerArtistListingCursor
  }
}
    ${CommonArtworkManagerArtistListingCursorFragmentDoc}`;

/**
 * __useGetArtistManageArtworksListingByArtistOrderQuery__
 *
 * To run a query within a React component, call `useGetArtistManageArtworksListingByArtistOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistManageArtworksListingByArtistOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistManageArtworksListingByArtistOrderQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetArtistManageArtworksListingByArtistOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistManageArtworksListingByArtistOrderQuery, Types.GetArtistManageArtworksListingByArtistOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistManageArtworksListingByArtistOrderQuery, Types.GetArtistManageArtworksListingByArtistOrderQueryVariables>(GetArtistManageArtworksListingByArtistOrderDocument, options);
      }
export function useGetArtistManageArtworksListingByArtistOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistManageArtworksListingByArtistOrderQuery, Types.GetArtistManageArtworksListingByArtistOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistManageArtworksListingByArtistOrderQuery, Types.GetArtistManageArtworksListingByArtistOrderQueryVariables>(GetArtistManageArtworksListingByArtistOrderDocument, options);
        }
export type GetArtistManageArtworksListingByArtistOrderQueryHookResult = ReturnType<typeof useGetArtistManageArtworksListingByArtistOrderQuery>;
export type GetArtistManageArtworksListingByArtistOrderLazyQueryHookResult = ReturnType<typeof useGetArtistManageArtworksListingByArtistOrderLazyQuery>;
export type GetArtistManageArtworksListingByArtistOrderQueryResult = Apollo.QueryResult<Types.GetArtistManageArtworksListingByArtistOrderQuery, Types.GetArtistManageArtworksListingByArtistOrderQueryVariables>;
export const GetManageArtworksInfoDocument = gql`
    query GetManageArtworksInfo($artworkIds: [bigint!]!, $ownerContextId: bigint!) {
  artworks: artwork_manager_view(where: {artwork_id: {_in: $artworkIds}}) {
    id: artwork_id
    artworkEditionId: artwork_edition_id
    artworkId: artwork_id
    editionId: edition_id
    editionType: edition_type
    isHardMinted: is_hard_minted
    isPublic: is_public
    thumbnailMetadataId: thumbnail_metadata_id
    slug
    primaryImageMetadataId: primary_image_metadata_id
    transferState: transfer_state
    Edition {
      id
      publishDate: publish_date
      isPublic: is_public
    }
    transaction_histories(
      where: {_and: [{status: {_eq: ACCEPTED}}, {trashed_at: {_is_null: true}}, {print_id: {_is_null: true}}]}
    ) {
      status
    }
    publishDate: published_date
    creationDate: created_at
    details: artwork_details {
      id
      title
      year: creation_date_year
      height
      width
      disciplineOther: discipline_other
      ENUM_artwork_discipline {
        description
      }
    }
    collections: artwork_to_collections(
      where: {Collection: {owner_context_id: {_eq: $ownerContextId}}}
    ) {
      id
      isPublic: is_public
      collection: Collection {
        type
      }
    }
  }
}
    `;

/**
 * __useGetManageArtworksInfoQuery__
 *
 * To run a query within a React component, call `useGetManageArtworksInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtworksInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtworksInfoQuery({
 *   variables: {
 *      artworkIds: // value for 'artworkIds'
 *      ownerContextId: // value for 'ownerContextId'
 *   },
 * });
 */
export function useGetManageArtworksInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtworksInfoQuery, Types.GetManageArtworksInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtworksInfoQuery, Types.GetManageArtworksInfoQueryVariables>(GetManageArtworksInfoDocument, options);
      }
export function useGetManageArtworksInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtworksInfoQuery, Types.GetManageArtworksInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtworksInfoQuery, Types.GetManageArtworksInfoQueryVariables>(GetManageArtworksInfoDocument, options);
        }
export type GetManageArtworksInfoQueryHookResult = ReturnType<typeof useGetManageArtworksInfoQuery>;
export type GetManageArtworksInfoLazyQueryHookResult = ReturnType<typeof useGetManageArtworksInfoLazyQuery>;
export type GetManageArtworksInfoQueryResult = Apollo.QueryResult<Types.GetManageArtworksInfoQuery, Types.GetManageArtworksInfoQueryVariables>;
export const GetArtistProfileArtworkInfoDocument = gql`
    query GetArtistProfileArtworkInfo($artistContextId: bigint!, $artworkIds: [bigint!]!, $callerCtxId: bigint!, $skipArtistInfo: Boolean!, $skipPrizes: Boolean!, $skipFavorites: Boolean!, $skipFavoriteCount: Boolean!) {
  artist: Artist_explore_info(where: {artist_context_id: {_eq: $artistContextId}}) @skip(if: $skipArtistInfo) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    country
    city
  }
  artworks: Artwork_artist_profile_info(where: {artwork_id: {_in: $artworkIds}}) {
    artworkId: artwork_id
    artistContextId: artist_context_id
    primaryImageMetadataId: primary_image_metadata_id
    thumbnailMetadataId: thumbnail_metadata_id
    title
    editionType: edition_type
    isAvailable: is_available
    isCollected: is_collected
    isUnavailable: is_unavailable
    width
    height
    depth
    measuringUnit: measuring_unit
    discipline
    slug
    prices(
      where: {is_public: {_eq: true}, scope: {_eq: SHOWCASE}, trashed_at: {_is_null: true}}
    ) {
      id
      currency
      price
    }
  }
  prizes: Artwork_prize_info(where: {artwork_id: {_in: $artworkIds}}) @skip(if: $skipPrizes) {
    artworkId: artwork_id
    artPrizeShowId: art_prize_show_id
    artworkPrizeIds: artwork_prize_ids
    title
    slug: prize_show_slug
    position: position_type
  }
  favorites: Artwork_to_Collection(
    where: {artwork_id: {_in: $artworkIds}, Collection: {owner_context_id: {_eq: $callerCtxId}, type: {_eq: FAVORITE}}}
  ) @skip(if: $skipFavorites) {
    artworkId: artwork_id
  }
  artworkFavoriteCounts: artwork_favorite_count(
    where: {artwork_id: {_in: $artworkIds}}
  ) @skip(if: $skipFavoriteCount) {
    artworkId: artwork_id
    favoriteCount: favorite_count
  }
}
    `;

/**
 * __useGetArtistProfileArtworkInfoQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileArtworkInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileArtworkInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileArtworkInfoQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      artworkIds: // value for 'artworkIds'
 *      callerCtxId: // value for 'callerCtxId'
 *      skipArtistInfo: // value for 'skipArtistInfo'
 *      skipPrizes: // value for 'skipPrizes'
 *      skipFavorites: // value for 'skipFavorites'
 *      skipFavoriteCount: // value for 'skipFavoriteCount'
 *   },
 * });
 */
export function useGetArtistProfileArtworkInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileArtworkInfoQuery, Types.GetArtistProfileArtworkInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileArtworkInfoQuery, Types.GetArtistProfileArtworkInfoQueryVariables>(GetArtistProfileArtworkInfoDocument, options);
      }
export function useGetArtistProfileArtworkInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileArtworkInfoQuery, Types.GetArtistProfileArtworkInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileArtworkInfoQuery, Types.GetArtistProfileArtworkInfoQueryVariables>(GetArtistProfileArtworkInfoDocument, options);
        }
export type GetArtistProfileArtworkInfoQueryHookResult = ReturnType<typeof useGetArtistProfileArtworkInfoQuery>;
export type GetArtistProfileArtworkInfoLazyQueryHookResult = ReturnType<typeof useGetArtistProfileArtworkInfoLazyQuery>;
export type GetArtistProfileArtworkInfoQueryResult = Apollo.QueryResult<Types.GetArtistProfileArtworkInfoQuery, Types.GetArtistProfileArtworkInfoQueryVariables>;
export const GetArtistProfileArtworksListingByDateDocument = gql`
    query GetArtistProfileArtworksListingByDate($limit: Int!, $appliedSorts: [Artwork_artist_profile_listing_order_by!], $appliedFilters: Artwork_artist_profile_listing_bool_exp!) {
  artworks: Artwork_artist_profile_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonArtworkArtistProfileListingCursor
    publishedDate: published_date
  }
}
    ${CommonArtworkArtistProfileListingCursorFragmentDoc}`;

/**
 * __useGetArtistProfileArtworksListingByDateQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileArtworksListingByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileArtworksListingByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileArtworksListingByDateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetArtistProfileArtworksListingByDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileArtworksListingByDateQuery, Types.GetArtistProfileArtworksListingByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileArtworksListingByDateQuery, Types.GetArtistProfileArtworksListingByDateQueryVariables>(GetArtistProfileArtworksListingByDateDocument, options);
      }
export function useGetArtistProfileArtworksListingByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileArtworksListingByDateQuery, Types.GetArtistProfileArtworksListingByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileArtworksListingByDateQuery, Types.GetArtistProfileArtworksListingByDateQueryVariables>(GetArtistProfileArtworksListingByDateDocument, options);
        }
export type GetArtistProfileArtworksListingByDateQueryHookResult = ReturnType<typeof useGetArtistProfileArtworksListingByDateQuery>;
export type GetArtistProfileArtworksListingByDateLazyQueryHookResult = ReturnType<typeof useGetArtistProfileArtworksListingByDateLazyQuery>;
export type GetArtistProfileArtworksListingByDateQueryResult = Apollo.QueryResult<Types.GetArtistProfileArtworksListingByDateQuery, Types.GetArtistProfileArtworksListingByDateQueryVariables>;
export const GetArtistProfileArtworksListingBySizeDocument = gql`
    query GetArtistProfileArtworksListingBySize($limit: Int!, $appliedSorts: [Artwork_artist_profile_listing_order_by!], $appliedFilters: Artwork_artist_profile_listing_bool_exp!) {
  artworks: Artwork_artist_profile_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonArtworkArtistProfileListingCursor
    area
  }
}
    ${CommonArtworkArtistProfileListingCursorFragmentDoc}`;

/**
 * __useGetArtistProfileArtworksListingBySizeQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileArtworksListingBySizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileArtworksListingBySizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileArtworksListingBySizeQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetArtistProfileArtworksListingBySizeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileArtworksListingBySizeQuery, Types.GetArtistProfileArtworksListingBySizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileArtworksListingBySizeQuery, Types.GetArtistProfileArtworksListingBySizeQueryVariables>(GetArtistProfileArtworksListingBySizeDocument, options);
      }
export function useGetArtistProfileArtworksListingBySizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileArtworksListingBySizeQuery, Types.GetArtistProfileArtworksListingBySizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileArtworksListingBySizeQuery, Types.GetArtistProfileArtworksListingBySizeQueryVariables>(GetArtistProfileArtworksListingBySizeDocument, options);
        }
export type GetArtistProfileArtworksListingBySizeQueryHookResult = ReturnType<typeof useGetArtistProfileArtworksListingBySizeQuery>;
export type GetArtistProfileArtworksListingBySizeLazyQueryHookResult = ReturnType<typeof useGetArtistProfileArtworksListingBySizeLazyQuery>;
export type GetArtistProfileArtworksListingBySizeQueryResult = Apollo.QueryResult<Types.GetArtistProfileArtworksListingBySizeQuery, Types.GetArtistProfileArtworksListingBySizeQueryVariables>;
export const GetArtistProfileArtworksListingByPriceDocument = gql`
    query GetArtistProfileArtworksListingByPrice($limit: Int!, $appliedSorts: [Artwork_artist_profile_listing_order_by!], $appliedFilters: Artwork_artist_profile_listing_bool_exp!) {
  artworks: Artwork_artist_profile_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonArtworkArtistProfileListingCursor
    price
  }
}
    ${CommonArtworkArtistProfileListingCursorFragmentDoc}`;

/**
 * __useGetArtistProfileArtworksListingByPriceQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileArtworksListingByPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileArtworksListingByPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileArtworksListingByPriceQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetArtistProfileArtworksListingByPriceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileArtworksListingByPriceQuery, Types.GetArtistProfileArtworksListingByPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileArtworksListingByPriceQuery, Types.GetArtistProfileArtworksListingByPriceQueryVariables>(GetArtistProfileArtworksListingByPriceDocument, options);
      }
export function useGetArtistProfileArtworksListingByPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileArtworksListingByPriceQuery, Types.GetArtistProfileArtworksListingByPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileArtworksListingByPriceQuery, Types.GetArtistProfileArtworksListingByPriceQueryVariables>(GetArtistProfileArtworksListingByPriceDocument, options);
        }
export type GetArtistProfileArtworksListingByPriceQueryHookResult = ReturnType<typeof useGetArtistProfileArtworksListingByPriceQuery>;
export type GetArtistProfileArtworksListingByPriceLazyQueryHookResult = ReturnType<typeof useGetArtistProfileArtworksListingByPriceLazyQuery>;
export type GetArtistProfileArtworksListingByPriceQueryResult = Apollo.QueryResult<Types.GetArtistProfileArtworksListingByPriceQuery, Types.GetArtistProfileArtworksListingByPriceQueryVariables>;
export const GetArtistProfileArtworksListingByArtistOrderDocument = gql`
    query GetArtistProfileArtworksListingByArtistOrder($limit: Int!, $appliedSorts: [Artwork_artist_profile_listing_order_by!], $appliedFilters: Artwork_artist_profile_listing_bool_exp!) {
  artworks: Artwork_artist_profile_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonArtworkArtistProfileListingCursor
    artistOrder: artist_order
  }
}
    ${CommonArtworkArtistProfileListingCursorFragmentDoc}`;

/**
 * __useGetArtistProfileArtworksListingByArtistOrderQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileArtworksListingByArtistOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileArtworksListingByArtistOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileArtworksListingByArtistOrderQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetArtistProfileArtworksListingByArtistOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileArtworksListingByArtistOrderQuery, Types.GetArtistProfileArtworksListingByArtistOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileArtworksListingByArtistOrderQuery, Types.GetArtistProfileArtworksListingByArtistOrderQueryVariables>(GetArtistProfileArtworksListingByArtistOrderDocument, options);
      }
export function useGetArtistProfileArtworksListingByArtistOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileArtworksListingByArtistOrderQuery, Types.GetArtistProfileArtworksListingByArtistOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileArtworksListingByArtistOrderQuery, Types.GetArtistProfileArtworksListingByArtistOrderQueryVariables>(GetArtistProfileArtworksListingByArtistOrderDocument, options);
        }
export type GetArtistProfileArtworksListingByArtistOrderQueryHookResult = ReturnType<typeof useGetArtistProfileArtworksListingByArtistOrderQuery>;
export type GetArtistProfileArtworksListingByArtistOrderLazyQueryHookResult = ReturnType<typeof useGetArtistProfileArtworksListingByArtistOrderLazyQuery>;
export type GetArtistProfileArtworksListingByArtistOrderQueryResult = Apollo.QueryResult<Types.GetArtistProfileArtworksListingByArtistOrderQuery, Types.GetArtistProfileArtworksListingByArtistOrderQueryVariables>;
export const GetFiltersDataForContextIdDocument = gql`
    query GetFiltersDataForContextId($artistContextId: bigint!) {
  Artwork_artist_profile_listing(
    where: {artist_context_id: {_eq: $artistContextId}}
    distinct_on: creation_date_year
  ) {
    creationDateYear: creation_date_year
  }
  Artwork(where: {artist_context_id: {_eq: $artistContextId}}) {
    artworkToSeries: artwork_to_collections(
      where: {Collection: {type: {_eq: SERIES}}}
    ) {
      id
      collection: Collection {
        id
        name
      }
    }
    art_prizes(
      where: {Art_prize_winner: {Art_prize_show: {published_at: {_lte: "now()"}}}}
    ) {
      art_prize_show_id
      Art_prize_winner {
        position_type
        Art_prize_show {
          id
          name: prize_show_slug
          title
        }
      }
    }
  }
}
    `;

/**
 * __useGetFiltersDataForContextIdQuery__
 *
 * To run a query within a React component, call `useGetFiltersDataForContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiltersDataForContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiltersDataForContextIdQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetFiltersDataForContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFiltersDataForContextIdQuery, Types.GetFiltersDataForContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFiltersDataForContextIdQuery, Types.GetFiltersDataForContextIdQueryVariables>(GetFiltersDataForContextIdDocument, options);
      }
export function useGetFiltersDataForContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFiltersDataForContextIdQuery, Types.GetFiltersDataForContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFiltersDataForContextIdQuery, Types.GetFiltersDataForContextIdQueryVariables>(GetFiltersDataForContextIdDocument, options);
        }
export type GetFiltersDataForContextIdQueryHookResult = ReturnType<typeof useGetFiltersDataForContextIdQuery>;
export type GetFiltersDataForContextIdLazyQueryHookResult = ReturnType<typeof useGetFiltersDataForContextIdLazyQuery>;
export type GetFiltersDataForContextIdQueryResult = Apollo.QueryResult<Types.GetFiltersDataForContextIdQuery, Types.GetFiltersDataForContextIdQueryVariables>;
export const GetArtistProfileBottomNavbarDataDocument = gql`
    query GetArtistProfileBottomNavbarData($handle: String!) {
  Profile(where: {handle: {_ilike: $handle}, context: {type: {_eq: ARTIST}}}) {
    context {
      id
      profile_video_links(order_by: {id: desc}, limit: 1) {
        id
        platform
        URL
      }
    }
  }
}
    `;

/**
 * __useGetArtistProfileBottomNavbarDataQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileBottomNavbarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileBottomNavbarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileBottomNavbarDataQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetArtistProfileBottomNavbarDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileBottomNavbarDataQuery, Types.GetArtistProfileBottomNavbarDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileBottomNavbarDataQuery, Types.GetArtistProfileBottomNavbarDataQueryVariables>(GetArtistProfileBottomNavbarDataDocument, options);
      }
export function useGetArtistProfileBottomNavbarDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileBottomNavbarDataQuery, Types.GetArtistProfileBottomNavbarDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileBottomNavbarDataQuery, Types.GetArtistProfileBottomNavbarDataQueryVariables>(GetArtistProfileBottomNavbarDataDocument, options);
        }
export type GetArtistProfileBottomNavbarDataQueryHookResult = ReturnType<typeof useGetArtistProfileBottomNavbarDataQuery>;
export type GetArtistProfileBottomNavbarDataLazyQueryHookResult = ReturnType<typeof useGetArtistProfileBottomNavbarDataLazyQuery>;
export type GetArtistProfileBottomNavbarDataQueryResult = Apollo.QueryResult<Types.GetArtistProfileBottomNavbarDataQuery, Types.GetArtistProfileBottomNavbarDataQueryVariables>;
export const GetShowroomDetailsDocument = gql`
    query getShowroomDetails($showroomSlug: String!) {
  showroom_password_check(where: {slug: {_eq: $showroomSlug}}) {
    showroom_id
    expiration_date
    name
    description
  }
}
    `;

/**
 * __useGetShowroomDetailsQuery__
 *
 * To run a query within a React component, call `useGetShowroomDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomDetailsQuery({
 *   variables: {
 *      showroomSlug: // value for 'showroomSlug'
 *   },
 * });
 */
export function useGetShowroomDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowroomDetailsQuery, Types.GetShowroomDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowroomDetailsQuery, Types.GetShowroomDetailsQueryVariables>(GetShowroomDetailsDocument, options);
      }
export function useGetShowroomDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowroomDetailsQuery, Types.GetShowroomDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowroomDetailsQuery, Types.GetShowroomDetailsQueryVariables>(GetShowroomDetailsDocument, options);
        }
export type GetShowroomDetailsQueryHookResult = ReturnType<typeof useGetShowroomDetailsQuery>;
export type GetShowroomDetailsLazyQueryHookResult = ReturnType<typeof useGetShowroomDetailsLazyQuery>;
export type GetShowroomDetailsQueryResult = Apollo.QueryResult<Types.GetShowroomDetailsQuery, Types.GetShowroomDetailsQueryVariables>;
export const CheckShowroomPasswordAnonymousDocument = gql`
    query checkShowroomPasswordAnonymous($showroom_slug: String = "") {
  Showroom_details(where: {slug: {_eq: $showroom_slug}}) {
    id
  }
}
    `;

/**
 * __useCheckShowroomPasswordAnonymousQuery__
 *
 * To run a query within a React component, call `useCheckShowroomPasswordAnonymousQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckShowroomPasswordAnonymousQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckShowroomPasswordAnonymousQuery({
 *   variables: {
 *      showroom_slug: // value for 'showroom_slug'
 *   },
 * });
 */
export function useCheckShowroomPasswordAnonymousQuery(baseOptions?: Apollo.QueryHookOptions<Types.CheckShowroomPasswordAnonymousQuery, Types.CheckShowroomPasswordAnonymousQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckShowroomPasswordAnonymousQuery, Types.CheckShowroomPasswordAnonymousQueryVariables>(CheckShowroomPasswordAnonymousDocument, options);
      }
export function useCheckShowroomPasswordAnonymousLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckShowroomPasswordAnonymousQuery, Types.CheckShowroomPasswordAnonymousQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckShowroomPasswordAnonymousQuery, Types.CheckShowroomPasswordAnonymousQueryVariables>(CheckShowroomPasswordAnonymousDocument, options);
        }
export type CheckShowroomPasswordAnonymousQueryHookResult = ReturnType<typeof useCheckShowroomPasswordAnonymousQuery>;
export type CheckShowroomPasswordAnonymousLazyQueryHookResult = ReturnType<typeof useCheckShowroomPasswordAnonymousLazyQuery>;
export type CheckShowroomPasswordAnonymousQueryResult = Apollo.QueryResult<Types.CheckShowroomPasswordAnonymousQuery, Types.CheckShowroomPasswordAnonymousQueryVariables>;
export const EnterShowroomPasswordDocument = gql`
    mutation enterShowroomPassword($password: String!, $showroom_slug: String!) {
  enterShowroomPassword(password: $password, showroom_slug: $showroom_slug) {
    error
    status
  }
}
    `;
export type EnterShowroomPasswordMutationFn = Apollo.MutationFunction<Types.EnterShowroomPasswordMutation, Types.EnterShowroomPasswordMutationVariables>;

/**
 * __useEnterShowroomPasswordMutation__
 *
 * To run a mutation, you first call `useEnterShowroomPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterShowroomPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterShowroomPasswordMutation, { data, loading, error }] = useEnterShowroomPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      showroom_slug: // value for 'showroom_slug'
 *   },
 * });
 */
export function useEnterShowroomPasswordMutation(baseOptions?: Apollo.MutationHookOptions<Types.EnterShowroomPasswordMutation, Types.EnterShowroomPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EnterShowroomPasswordMutation, Types.EnterShowroomPasswordMutationVariables>(EnterShowroomPasswordDocument, options);
      }
export type EnterShowroomPasswordMutationHookResult = ReturnType<typeof useEnterShowroomPasswordMutation>;
export type EnterShowroomPasswordMutationResult = Apollo.MutationResult<Types.EnterShowroomPasswordMutation>;
export type EnterShowroomPasswordMutationOptions = Apollo.BaseMutationOptions<Types.EnterShowroomPasswordMutation, Types.EnterShowroomPasswordMutationVariables>;
export const GetShowroomFilterDetailsDocument = gql`
    query getShowroomFilterDetails($slug: String!) {
  Showroom_details(where: {slug: {_eq: $slug}}) {
    collection {
      id
      artwork_to_collections {
        Artwork {
          artwork_details {
            creation_date_year
          }
          artwork_to_collections(where: {Collection: {type: {_eq: SERIES}}}) {
            Collection {
              name
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetShowroomFilterDetailsQuery__
 *
 * To run a query within a React component, call `useGetShowroomFilterDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomFilterDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomFilterDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetShowroomFilterDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowroomFilterDetailsQuery, Types.GetShowroomFilterDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowroomFilterDetailsQuery, Types.GetShowroomFilterDetailsQueryVariables>(GetShowroomFilterDetailsDocument, options);
      }
export function useGetShowroomFilterDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowroomFilterDetailsQuery, Types.GetShowroomFilterDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowroomFilterDetailsQuery, Types.GetShowroomFilterDetailsQueryVariables>(GetShowroomFilterDetailsDocument, options);
        }
export type GetShowroomFilterDetailsQueryHookResult = ReturnType<typeof useGetShowroomFilterDetailsQuery>;
export type GetShowroomFilterDetailsLazyQueryHookResult = ReturnType<typeof useGetShowroomFilterDetailsLazyQuery>;
export type GetShowroomFilterDetailsQueryResult = Apollo.QueryResult<Types.GetShowroomFilterDetailsQuery, Types.GetShowroomFilterDetailsQueryVariables>;
export const AddShowroomViewDocument = gql`
    mutation addShowroomView($showroomId: Int!) {
  increaseShowroomViews(showroom_id: $showroomId)
}
    `;
export type AddShowroomViewMutationFn = Apollo.MutationFunction<Types.AddShowroomViewMutation, Types.AddShowroomViewMutationVariables>;

/**
 * __useAddShowroomViewMutation__
 *
 * To run a mutation, you first call `useAddShowroomViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShowroomViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShowroomViewMutation, { data, loading, error }] = useAddShowroomViewMutation({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useAddShowroomViewMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddShowroomViewMutation, Types.AddShowroomViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddShowroomViewMutation, Types.AddShowroomViewMutationVariables>(AddShowroomViewDocument, options);
      }
export type AddShowroomViewMutationHookResult = ReturnType<typeof useAddShowroomViewMutation>;
export type AddShowroomViewMutationResult = Apollo.MutationResult<Types.AddShowroomViewMutation>;
export type AddShowroomViewMutationOptions = Apollo.BaseMutationOptions<Types.AddShowroomViewMutation, Types.AddShowroomViewMutationVariables>;
export const GetIfShowroomHasPasswordDocument = gql`
    query getIfShowroomHasPassword($showroomSlug: String!) {
  showroom_password_check(where: {slug: {_eq: $showroomSlug}}) {
    showroom_id
    is_password_protected
  }
}
    `;

/**
 * __useGetIfShowroomHasPasswordQuery__
 *
 * To run a query within a React component, call `useGetIfShowroomHasPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIfShowroomHasPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIfShowroomHasPasswordQuery({
 *   variables: {
 *      showroomSlug: // value for 'showroomSlug'
 *   },
 * });
 */
export function useGetIfShowroomHasPasswordQuery(baseOptions: Apollo.QueryHookOptions<Types.GetIfShowroomHasPasswordQuery, Types.GetIfShowroomHasPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIfShowroomHasPasswordQuery, Types.GetIfShowroomHasPasswordQueryVariables>(GetIfShowroomHasPasswordDocument, options);
      }
export function useGetIfShowroomHasPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIfShowroomHasPasswordQuery, Types.GetIfShowroomHasPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIfShowroomHasPasswordQuery, Types.GetIfShowroomHasPasswordQueryVariables>(GetIfShowroomHasPasswordDocument, options);
        }
export type GetIfShowroomHasPasswordQueryHookResult = ReturnType<typeof useGetIfShowroomHasPasswordQuery>;
export type GetIfShowroomHasPasswordLazyQueryHookResult = ReturnType<typeof useGetIfShowroomHasPasswordLazyQuery>;
export type GetIfShowroomHasPasswordQueryResult = Apollo.QueryResult<Types.GetIfShowroomHasPasswordQuery, Types.GetIfShowroomHasPasswordQueryVariables>;
export const GetArtistDetailsFromHandleForShowroomDocument = gql`
    query getArtistDetailsFromHandleForShowroom($handle: String!) {
  Profile(where: {handle: {_ilike: $handle}, context: {type: {_eq: ARTIST}}}) {
    title
    avatar
    id
    first_name
    last_name
  }
}
    `;

/**
 * __useGetArtistDetailsFromHandleForShowroomQuery__
 *
 * To run a query within a React component, call `useGetArtistDetailsFromHandleForShowroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistDetailsFromHandleForShowroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistDetailsFromHandleForShowroomQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetArtistDetailsFromHandleForShowroomQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistDetailsFromHandleForShowroomQuery, Types.GetArtistDetailsFromHandleForShowroomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistDetailsFromHandleForShowroomQuery, Types.GetArtistDetailsFromHandleForShowroomQueryVariables>(GetArtistDetailsFromHandleForShowroomDocument, options);
      }
export function useGetArtistDetailsFromHandleForShowroomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistDetailsFromHandleForShowroomQuery, Types.GetArtistDetailsFromHandleForShowroomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistDetailsFromHandleForShowroomQuery, Types.GetArtistDetailsFromHandleForShowroomQueryVariables>(GetArtistDetailsFromHandleForShowroomDocument, options);
        }
export type GetArtistDetailsFromHandleForShowroomQueryHookResult = ReturnType<typeof useGetArtistDetailsFromHandleForShowroomQuery>;
export type GetArtistDetailsFromHandleForShowroomLazyQueryHookResult = ReturnType<typeof useGetArtistDetailsFromHandleForShowroomLazyQuery>;
export type GetArtistDetailsFromHandleForShowroomQueryResult = Apollo.QueryResult<Types.GetArtistDetailsFromHandleForShowroomQuery, Types.GetArtistDetailsFromHandleForShowroomQueryVariables>;
export const GetArtistCvDocument = gql`
    query getArtistCV($artist_context_id: bigint!) {
  Profile(where: {context: {id: {_eq: $artist_context_id}}}) {
    cv_download_link
    cv_metadata {
      storage_path
      file_name
    }
  }
}
    `;

/**
 * __useGetArtistCvQuery__
 *
 * To run a query within a React component, call `useGetArtistCvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistCvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistCvQuery({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useGetArtistCvQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistCvQuery, Types.GetArtistCvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistCvQuery, Types.GetArtistCvQueryVariables>(GetArtistCvDocument, options);
      }
export function useGetArtistCvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistCvQuery, Types.GetArtistCvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistCvQuery, Types.GetArtistCvQueryVariables>(GetArtistCvDocument, options);
        }
export type GetArtistCvQueryHookResult = ReturnType<typeof useGetArtistCvQuery>;
export type GetArtistCvLazyQueryHookResult = ReturnType<typeof useGetArtistCvLazyQuery>;
export type GetArtistCvQueryResult = Apollo.QueryResult<Types.GetArtistCvQuery, Types.GetArtistCvQueryVariables>;
export const GetArtistFirstArtworksDocument = gql`
    query GetArtistFirstArtworks($contextId: bigint!) {
  Artwork(
    where: {artist_context_id: {_eq: $contextId}, deleted_at: {_is_null: true}, is_public: {_eq: true}}
    limit: 3
    order_by: {id: asc}
  ) {
    id
    thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetArtistFirstArtworksQuery__
 *
 * To run a query within a React component, call `useGetArtistFirstArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistFirstArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistFirstArtworksQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistFirstArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistFirstArtworksQuery, Types.GetArtistFirstArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistFirstArtworksQuery, Types.GetArtistFirstArtworksQueryVariables>(GetArtistFirstArtworksDocument, options);
      }
export function useGetArtistFirstArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistFirstArtworksQuery, Types.GetArtistFirstArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistFirstArtworksQuery, Types.GetArtistFirstArtworksQueryVariables>(GetArtistFirstArtworksDocument, options);
        }
export type GetArtistFirstArtworksQueryHookResult = ReturnType<typeof useGetArtistFirstArtworksQuery>;
export type GetArtistFirstArtworksLazyQueryHookResult = ReturnType<typeof useGetArtistFirstArtworksLazyQuery>;
export type GetArtistFirstArtworksQueryResult = Apollo.QueryResult<Types.GetArtistFirstArtworksQuery, Types.GetArtistFirstArtworksQueryVariables>;
export const GetArtistDetailsFromHandleDocument = gql`
    query GetArtistDetailsFromHandle($handle: String!) {
  Profile(where: {handle: {_ilike: $handle}, context: {type: {_eq: ARTIST}}}) {
    handle
    title
    avatar
    id
    first_name
    last_name
    context {
      id
      locations {
        country
        city
      }
    }
  }
}
    `;

/**
 * __useGetArtistDetailsFromHandleQuery__
 *
 * To run a query within a React component, call `useGetArtistDetailsFromHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistDetailsFromHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistDetailsFromHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetArtistDetailsFromHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistDetailsFromHandleQuery, Types.GetArtistDetailsFromHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistDetailsFromHandleQuery, Types.GetArtistDetailsFromHandleQueryVariables>(GetArtistDetailsFromHandleDocument, options);
      }
export function useGetArtistDetailsFromHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistDetailsFromHandleQuery, Types.GetArtistDetailsFromHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistDetailsFromHandleQuery, Types.GetArtistDetailsFromHandleQueryVariables>(GetArtistDetailsFromHandleDocument, options);
        }
export type GetArtistDetailsFromHandleQueryHookResult = ReturnType<typeof useGetArtistDetailsFromHandleQuery>;
export type GetArtistDetailsFromHandleLazyQueryHookResult = ReturnType<typeof useGetArtistDetailsFromHandleLazyQuery>;
export type GetArtistDetailsFromHandleQueryResult = Apollo.QueryResult<Types.GetArtistDetailsFromHandleQuery, Types.GetArtistDetailsFromHandleQueryVariables>;
export const GetArtistDetailsForTabsByIdDocument = gql`
    query GetArtistDetailsForTabsById($contextId: bigint!) {
  profile: Profile(where: {context: {id: {_eq: $contextId}}}) {
    id
    handle
    first_name
    last_name
    title
    quote
    statement
    avatar
    cv_download_link
    audio_download_link
    Valid_profile_statement_audio_syntheses {
      audio_id
      profile_id
      language
    }
    avatar_metadata {
      id
      extra
    }
    context {
      id
      locations {
        country
        city
      }
      profile_social_links {
        platform
        URL
        custom_link
      }
      collaboration_by_artist_id {
        gallery_context {
          profile {
            id
            banner
            kard_banner
            title
            handle
          }
        }
      }
    }
  }
  videos: Profile_video_links(where: {context_id: {_eq: $contextId}}) {
    id
    platform
    URL
  }
}
    `;

/**
 * __useGetArtistDetailsForTabsByIdQuery__
 *
 * To run a query within a React component, call `useGetArtistDetailsForTabsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistDetailsForTabsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistDetailsForTabsByIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistDetailsForTabsByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistDetailsForTabsByIdQuery, Types.GetArtistDetailsForTabsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistDetailsForTabsByIdQuery, Types.GetArtistDetailsForTabsByIdQueryVariables>(GetArtistDetailsForTabsByIdDocument, options);
      }
export function useGetArtistDetailsForTabsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistDetailsForTabsByIdQuery, Types.GetArtistDetailsForTabsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistDetailsForTabsByIdQuery, Types.GetArtistDetailsForTabsByIdQueryVariables>(GetArtistDetailsForTabsByIdDocument, options);
        }
export type GetArtistDetailsForTabsByIdQueryHookResult = ReturnType<typeof useGetArtistDetailsForTabsByIdQuery>;
export type GetArtistDetailsForTabsByIdLazyQueryHookResult = ReturnType<typeof useGetArtistDetailsForTabsByIdLazyQuery>;
export type GetArtistDetailsForTabsByIdQueryResult = Apollo.QueryResult<Types.GetArtistDetailsForTabsByIdQuery, Types.GetArtistDetailsForTabsByIdQueryVariables>;
export const GetBillingArtworkPaymentIntentsByStatusDocument = gql`
    query getBillingArtworkPaymentIntentsByStatus($status: [ENUM_billing_artwork_payment_intent_status_enum!]!, $orderBy: [Billing_artwork_payment_intent_order_by!]) {
  Billing_artwork_payment_intent(
    where: {status: {_in: $status}}
    order_by: $orderBy
  ) {
    ...BillingArtworkPaymentIntent
    products {
      ...BillingArtworkPaymentIntentProduct
      artwork {
        id
        slug
        artist_context {
          id
          profile {
            handle
          }
        }
      }
    }
    buyer {
      stripe_customer_id
      context {
        ...BillingBuyerSellerContext
      }
    }
    seller_context {
      ...BillingBuyerSellerContext
    }
  }
}
    ${BillingArtworkPaymentIntentFragmentDoc}
${BillingArtworkPaymentIntentProductFragmentDoc}
${BillingBuyerSellerContextFragmentDoc}`;

/**
 * __useGetBillingArtworkPaymentIntentsByStatusQuery__
 *
 * To run a query within a React component, call `useGetBillingArtworkPaymentIntentsByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingArtworkPaymentIntentsByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingArtworkPaymentIntentsByStatusQuery({
 *   variables: {
 *      status: // value for 'status'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetBillingArtworkPaymentIntentsByStatusQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBillingArtworkPaymentIntentsByStatusQuery, Types.GetBillingArtworkPaymentIntentsByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBillingArtworkPaymentIntentsByStatusQuery, Types.GetBillingArtworkPaymentIntentsByStatusQueryVariables>(GetBillingArtworkPaymentIntentsByStatusDocument, options);
      }
export function useGetBillingArtworkPaymentIntentsByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBillingArtworkPaymentIntentsByStatusQuery, Types.GetBillingArtworkPaymentIntentsByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBillingArtworkPaymentIntentsByStatusQuery, Types.GetBillingArtworkPaymentIntentsByStatusQueryVariables>(GetBillingArtworkPaymentIntentsByStatusDocument, options);
        }
export type GetBillingArtworkPaymentIntentsByStatusQueryHookResult = ReturnType<typeof useGetBillingArtworkPaymentIntentsByStatusQuery>;
export type GetBillingArtworkPaymentIntentsByStatusLazyQueryHookResult = ReturnType<typeof useGetBillingArtworkPaymentIntentsByStatusLazyQuery>;
export type GetBillingArtworkPaymentIntentsByStatusQueryResult = Apollo.QueryResult<Types.GetBillingArtworkPaymentIntentsByStatusQuery, Types.GetBillingArtworkPaymentIntentsByStatusQueryVariables>;
export const GetBillingArtworkPaymentIntentHistoryDocument = gql`
    query getBillingArtworkPaymentIntentHistory($paymentIntentId: bigint!) {
  Billing_artwork_payment_intent_status_history(
    where: {payment_intent_id: {_eq: $paymentIntentId}}
    order_by: {created_at: desc}
  ) {
    ...BillingArtworkPaymentIntentStatusHistory
  }
}
    ${BillingArtworkPaymentIntentStatusHistoryFragmentDoc}`;

/**
 * __useGetBillingArtworkPaymentIntentHistoryQuery__
 *
 * To run a query within a React component, call `useGetBillingArtworkPaymentIntentHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingArtworkPaymentIntentHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingArtworkPaymentIntentHistoryQuery({
 *   variables: {
 *      paymentIntentId: // value for 'paymentIntentId'
 *   },
 * });
 */
export function useGetBillingArtworkPaymentIntentHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBillingArtworkPaymentIntentHistoryQuery, Types.GetBillingArtworkPaymentIntentHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBillingArtworkPaymentIntentHistoryQuery, Types.GetBillingArtworkPaymentIntentHistoryQueryVariables>(GetBillingArtworkPaymentIntentHistoryDocument, options);
      }
export function useGetBillingArtworkPaymentIntentHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBillingArtworkPaymentIntentHistoryQuery, Types.GetBillingArtworkPaymentIntentHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBillingArtworkPaymentIntentHistoryQuery, Types.GetBillingArtworkPaymentIntentHistoryQueryVariables>(GetBillingArtworkPaymentIntentHistoryDocument, options);
        }
export type GetBillingArtworkPaymentIntentHistoryQueryHookResult = ReturnType<typeof useGetBillingArtworkPaymentIntentHistoryQuery>;
export type GetBillingArtworkPaymentIntentHistoryLazyQueryHookResult = ReturnType<typeof useGetBillingArtworkPaymentIntentHistoryLazyQuery>;
export type GetBillingArtworkPaymentIntentHistoryQueryResult = Apollo.QueryResult<Types.GetBillingArtworkPaymentIntentHistoryQuery, Types.GetBillingArtworkPaymentIntentHistoryQueryVariables>;
export const GetArtworkDetailsForCoaDocument = gql`
    query GetArtworkDetailsForCOA($artwork_id: bigint!, $profile_id: bigint!) {
  Print(
    where: {id: {_eq: $artwork_id}, print_to_collections: {Collection: {type: {_in: [OWNED, CREATED, MANAGED]}, owner_context_id: {_eq: $profile_id}}}}
  ) {
    id
    number: print_number
    editionByType: Edition_By_Type {
      ...EditionByTypeDetails
    }
  }
  Artwork(
    where: {_or: [{id: {_eq: $artwork_id}, artwork_to_collections: {Collection: {type: {_in: [OWNED, CREATED, MANAGED]}, owner_context_id: {_eq: $profile_id}}}}, {Editions: {Prints: {id: {_eq: $artwork_id}, print_to_collections: {Collection: {type: {_in: [OWNED, CREATED, MANAGED]}, owner_context_id: {_eq: $profile_id}}}}}}]}
  ) {
    id
    primary_image_metadata_id
    slug
    is_available_sale
    vumarks(where: {type: {_eq: CERTIFICATE}}) {
      file_metadata {
        storage_path
      }
    }
    Artwork_prices(where: {scope: {_eq: SHOWCASE}}) {
      price
      currency
      is_public
    }
    artwork_details {
      width
      height
      depth
      materials_and_medium
      measuring_unit
      discipline_other
      discipline
      title
      creation_date_day
      creation_date_month
      creation_date_year
    }
    artist_context {
      locations {
        city
        country
      }
      profile {
        id
        avatar
        handle
        title
      }
    }
    gallery_context {
      id
      profile {
        id
        avatar
        title
        handle
      }
    }
    transaction_histories(
      where: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}
      order_by: {created_at: desc}
      limit: 1
    ) {
      buyer_context {
        profile {
          id
          avatar
          is_public
          title
          first_name
          last_name
          handle
        }
      }
    }
    links {
      showcase_url
    }
  }
}
    ${EditionByTypeDetailsFragmentDoc}`;

/**
 * __useGetArtworkDetailsForCoaQuery__
 *
 * To run a query within a React component, call `useGetArtworkDetailsForCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkDetailsForCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkDetailsForCoaQuery({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      profile_id: // value for 'profile_id'
 *   },
 * });
 */
export function useGetArtworkDetailsForCoaQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkDetailsForCoaQuery, Types.GetArtworkDetailsForCoaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkDetailsForCoaQuery, Types.GetArtworkDetailsForCoaQueryVariables>(GetArtworkDetailsForCoaDocument, options);
      }
export function useGetArtworkDetailsForCoaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkDetailsForCoaQuery, Types.GetArtworkDetailsForCoaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkDetailsForCoaQuery, Types.GetArtworkDetailsForCoaQueryVariables>(GetArtworkDetailsForCoaDocument, options);
        }
export type GetArtworkDetailsForCoaQueryHookResult = ReturnType<typeof useGetArtworkDetailsForCoaQuery>;
export type GetArtworkDetailsForCoaLazyQueryHookResult = ReturnType<typeof useGetArtworkDetailsForCoaLazyQuery>;
export type GetArtworkDetailsForCoaQueryResult = Apollo.QueryResult<Types.GetArtworkDetailsForCoaQuery, Types.GetArtworkDetailsForCoaQueryVariables>;
export const CreateArtworkVumarkDocument = gql`
    mutation createArtworkVumark($artwork_id: bigint!) {
  certificateVumarkUpload(artwork_id: $artwork_id) {
    certificateURL
    status
  }
}
    `;
export type CreateArtworkVumarkMutationFn = Apollo.MutationFunction<Types.CreateArtworkVumarkMutation, Types.CreateArtworkVumarkMutationVariables>;

/**
 * __useCreateArtworkVumarkMutation__
 *
 * To run a mutation, you first call `useCreateArtworkVumarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtworkVumarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtworkVumarkMutation, { data, loading, error }] = useCreateArtworkVumarkMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useCreateArtworkVumarkMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateArtworkVumarkMutation, Types.CreateArtworkVumarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateArtworkVumarkMutation, Types.CreateArtworkVumarkMutationVariables>(CreateArtworkVumarkDocument, options);
      }
export type CreateArtworkVumarkMutationHookResult = ReturnType<typeof useCreateArtworkVumarkMutation>;
export type CreateArtworkVumarkMutationResult = Apollo.MutationResult<Types.CreateArtworkVumarkMutation>;
export type CreateArtworkVumarkMutationOptions = Apollo.BaseMutationOptions<Types.CreateArtworkVumarkMutation, Types.CreateArtworkVumarkMutationVariables>;
export const GetArtworkInfoForClaimCertificateDocument = gql`
    query GetArtworkInfoForClaimCertificate($id: bigint!) {
  Artwork_by_pk(id: $id) {
    id
    slug
    primary_image_metadata_id
    thumbnail_metadata_id
    artist_context {
      profile {
        title
        handle
      }
    }
    artwork_details {
      title
    }
    pending_transactions: transaction_histories(
      where: {status: {_eq: PENDING}, print_id: {_is_null: true}}
    ) {
      id
    }
  }
}
    `;

/**
 * __useGetArtworkInfoForClaimCertificateQuery__
 *
 * To run a query within a React component, call `useGetArtworkInfoForClaimCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkInfoForClaimCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkInfoForClaimCertificateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtworkInfoForClaimCertificateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkInfoForClaimCertificateQuery, Types.GetArtworkInfoForClaimCertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkInfoForClaimCertificateQuery, Types.GetArtworkInfoForClaimCertificateQueryVariables>(GetArtworkInfoForClaimCertificateDocument, options);
      }
export function useGetArtworkInfoForClaimCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkInfoForClaimCertificateQuery, Types.GetArtworkInfoForClaimCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkInfoForClaimCertificateQuery, Types.GetArtworkInfoForClaimCertificateQueryVariables>(GetArtworkInfoForClaimCertificateDocument, options);
        }
export type GetArtworkInfoForClaimCertificateQueryHookResult = ReturnType<typeof useGetArtworkInfoForClaimCertificateQuery>;
export type GetArtworkInfoForClaimCertificateLazyQueryHookResult = ReturnType<typeof useGetArtworkInfoForClaimCertificateLazyQuery>;
export type GetArtworkInfoForClaimCertificateQueryResult = Apollo.QueryResult<Types.GetArtworkInfoForClaimCertificateQuery, Types.GetArtworkInfoForClaimCertificateQueryVariables>;
export const VerifyArtworkTransactionBuyerDocument = gql`
    query VerifyArtworkTransactionBuyer($artwork_id: Int!, $email: String!) {
  verifyArtworkTransactionBuyer(artwork_id: $artwork_id, email: $email)
}
    `;

/**
 * __useVerifyArtworkTransactionBuyerQuery__
 *
 * To run a query within a React component, call `useVerifyArtworkTransactionBuyerQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyArtworkTransactionBuyerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyArtworkTransactionBuyerQuery({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyArtworkTransactionBuyerQuery(baseOptions: Apollo.QueryHookOptions<Types.VerifyArtworkTransactionBuyerQuery, Types.VerifyArtworkTransactionBuyerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.VerifyArtworkTransactionBuyerQuery, Types.VerifyArtworkTransactionBuyerQueryVariables>(VerifyArtworkTransactionBuyerDocument, options);
      }
export function useVerifyArtworkTransactionBuyerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.VerifyArtworkTransactionBuyerQuery, Types.VerifyArtworkTransactionBuyerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.VerifyArtworkTransactionBuyerQuery, Types.VerifyArtworkTransactionBuyerQueryVariables>(VerifyArtworkTransactionBuyerDocument, options);
        }
export type VerifyArtworkTransactionBuyerQueryHookResult = ReturnType<typeof useVerifyArtworkTransactionBuyerQuery>;
export type VerifyArtworkTransactionBuyerLazyQueryHookResult = ReturnType<typeof useVerifyArtworkTransactionBuyerLazyQuery>;
export type VerifyArtworkTransactionBuyerQueryResult = Apollo.QueryResult<Types.VerifyArtworkTransactionBuyerQuery, Types.VerifyArtworkTransactionBuyerQueryVariables>;
export const GetKaleidoCollectionDocument = gql`
    query getKaleidoCollection($url: String) {
  Artist_Collection(
    where: {internal_url: {_eq: $url}, deleted_at: {_is_null: true}, type: {_eq: virtual_exhibition}}
  ) {
    id
    external_url
    internal_url
    state
    title
    type
    description
    thumbnail_metadata_id
    banner_metadata {
      storage_path
    }
    thumbnail_metadata {
      storage_path
    }
  }
}
    `;

/**
 * __useGetKaleidoCollectionQuery__
 *
 * To run a query within a React component, call `useGetKaleidoCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKaleidoCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKaleidoCollectionQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetKaleidoCollectionQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetKaleidoCollectionQuery, Types.GetKaleidoCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKaleidoCollectionQuery, Types.GetKaleidoCollectionQueryVariables>(GetKaleidoCollectionDocument, options);
      }
export function useGetKaleidoCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKaleidoCollectionQuery, Types.GetKaleidoCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKaleidoCollectionQuery, Types.GetKaleidoCollectionQueryVariables>(GetKaleidoCollectionDocument, options);
        }
export type GetKaleidoCollectionQueryHookResult = ReturnType<typeof useGetKaleidoCollectionQuery>;
export type GetKaleidoCollectionLazyQueryHookResult = ReturnType<typeof useGetKaleidoCollectionLazyQuery>;
export type GetKaleidoCollectionQueryResult = Apollo.QueryResult<Types.GetKaleidoCollectionQuery, Types.GetKaleidoCollectionQueryVariables>;
export const GetCollectionArtistsDocument = gql`
    query getCollectionArtists($collectionId: bigint!) {
  Artist_Collection_to_Artist_Context(
    where: {artist_collection_id: {_eq: $collectionId}, deleted_at: {_is_null: true}}
    order_by: {order: asc}
  ) {
    artist_collection_id
    context_id
    created_at
    data
    deleted_at
    id
    order
    updated_at
    context {
      explore_view {
        artist_title
        artworks_count
        context_id
        artworks(limit: 3) {
          id
          slug
          is_public
          primary_image_metadata_id
          thumbnail_metadata_id
          artwork_details {
            id
            title
          }
        }
        context {
          profile {
            id
            avatar
            title
            first_name
            last_name
            handle
          }
          locations {
            country
            city
            name
            region
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCollectionArtistsQuery__
 *
 * To run a query within a React component, call `useGetCollectionArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionArtistsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useGetCollectionArtistsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectionArtistsQuery, Types.GetCollectionArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectionArtistsQuery, Types.GetCollectionArtistsQueryVariables>(GetCollectionArtistsDocument, options);
      }
export function useGetCollectionArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectionArtistsQuery, Types.GetCollectionArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectionArtistsQuery, Types.GetCollectionArtistsQueryVariables>(GetCollectionArtistsDocument, options);
        }
export type GetCollectionArtistsQueryHookResult = ReturnType<typeof useGetCollectionArtistsQuery>;
export type GetCollectionArtistsLazyQueryHookResult = ReturnType<typeof useGetCollectionArtistsLazyQuery>;
export type GetCollectionArtistsQueryResult = Apollo.QueryResult<Types.GetCollectionArtistsQuery, Types.GetCollectionArtistsQueryVariables>;
export const GetFilteredCollectionArtistsDocument = gql`
    query getFilteredCollectionArtists($collectionId: bigint!, $where: Explore_artists_bool_exp = {}, $sort: [Explore_artists_order_by!] = {}, $limit: Int = 10, $offset: Int = 0) {
  Explore_artists(
    where: {context: {Artist_Collection_to_Artist_Contexts: {artist_collection_id: {_eq: $collectionId}, deleted_at: {_is_null: true}}}, _and: [$where]}
    order_by: $sort
  ) {
    context {
      Artist_Collection_to_Artist_Contexts(order_by: {order: asc}) {
        artist_collection_id
        context_id
        created_at
        data
        deleted_at
        id
        order
        updated_at
      }
    }
    ...ExploreArtistCommonFilterFields
  }
}
    ${ExploreArtistCommonFilterFieldsFragmentDoc}`;

/**
 * __useGetFilteredCollectionArtistsQuery__
 *
 * To run a query within a React component, call `useGetFilteredCollectionArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredCollectionArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredCollectionArtistsQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      where: // value for 'where'
 *      sort: // value for 'sort'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetFilteredCollectionArtistsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFilteredCollectionArtistsQuery, Types.GetFilteredCollectionArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFilteredCollectionArtistsQuery, Types.GetFilteredCollectionArtistsQueryVariables>(GetFilteredCollectionArtistsDocument, options);
      }
export function useGetFilteredCollectionArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilteredCollectionArtistsQuery, Types.GetFilteredCollectionArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFilteredCollectionArtistsQuery, Types.GetFilteredCollectionArtistsQueryVariables>(GetFilteredCollectionArtistsDocument, options);
        }
export type GetFilteredCollectionArtistsQueryHookResult = ReturnType<typeof useGetFilteredCollectionArtistsQuery>;
export type GetFilteredCollectionArtistsLazyQueryHookResult = ReturnType<typeof useGetFilteredCollectionArtistsLazyQuery>;
export type GetFilteredCollectionArtistsQueryResult = Apollo.QueryResult<Types.GetFilteredCollectionArtistsQuery, Types.GetFilteredCollectionArtistsQueryVariables>;
export const UpdateUserNameDocument = gql`
    mutation UpdateUserName($contextId: bigint!, $appAccessId: bigint!, $firstName: String!, $lastName: String!) {
  update_Profile(
    where: {context: {id: {_eq: $contextId}}}
    _set: {first_name: $firstName, last_name: $lastName}
  ) {
    affected_rows
  }
  update_Auth(
    where: {auth_context_accesses: {context_id: {_eq: $appAccessId}}}
    _set: {first_name: $firstName, last_name: $lastName}
  ) {
    affected_rows
  }
}
    `;
export type UpdateUserNameMutationFn = Apollo.MutationFunction<Types.UpdateUserNameMutation, Types.UpdateUserNameMutationVariables>;

/**
 * __useUpdateUserNameMutation__
 *
 * To run a mutation, you first call `useUpdateUserNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNameMutation, { data, loading, error }] = useUpdateUserNameMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      appAccessId: // value for 'appAccessId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateUserNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserNameMutation, Types.UpdateUserNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserNameMutation, Types.UpdateUserNameMutationVariables>(UpdateUserNameDocument, options);
      }
export type UpdateUserNameMutationHookResult = ReturnType<typeof useUpdateUserNameMutation>;
export type UpdateUserNameMutationResult = Apollo.MutationResult<Types.UpdateUserNameMutation>;
export type UpdateUserNameMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserNameMutation, Types.UpdateUserNameMutationVariables>;
export const UpgradePseudoCollectorDocument = gql`
    mutation UpgradePseudoCollector($password: String!) {
  upgradePseudoCollector(password: $password)
}
    `;
export type UpgradePseudoCollectorMutationFn = Apollo.MutationFunction<Types.UpgradePseudoCollectorMutation, Types.UpgradePseudoCollectorMutationVariables>;

/**
 * __useUpgradePseudoCollectorMutation__
 *
 * To run a mutation, you first call `useUpgradePseudoCollectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradePseudoCollectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradePseudoCollectorMutation, { data, loading, error }] = useUpgradePseudoCollectorMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpgradePseudoCollectorMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpgradePseudoCollectorMutation, Types.UpgradePseudoCollectorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpgradePseudoCollectorMutation, Types.UpgradePseudoCollectorMutationVariables>(UpgradePseudoCollectorDocument, options);
      }
export type UpgradePseudoCollectorMutationHookResult = ReturnType<typeof useUpgradePseudoCollectorMutation>;
export type UpgradePseudoCollectorMutationResult = Apollo.MutationResult<Types.UpgradePseudoCollectorMutation>;
export type UpgradePseudoCollectorMutationOptions = Apollo.BaseMutationOptions<Types.UpgradePseudoCollectorMutation, Types.UpgradePseudoCollectorMutationVariables>;
export const UpgradePseudoCollectorToArtistDocument = gql`
    mutation UpgradePseudoCollectorToArtist($password: String!, $token: String) {
  upgradePseudoCollectorToArtist(password: $password, token: $token)
}
    `;
export type UpgradePseudoCollectorToArtistMutationFn = Apollo.MutationFunction<Types.UpgradePseudoCollectorToArtistMutation, Types.UpgradePseudoCollectorToArtistMutationVariables>;

/**
 * __useUpgradePseudoCollectorToArtistMutation__
 *
 * To run a mutation, you first call `useUpgradePseudoCollectorToArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradePseudoCollectorToArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradePseudoCollectorToArtistMutation, { data, loading, error }] = useUpgradePseudoCollectorToArtistMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpgradePseudoCollectorToArtistMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpgradePseudoCollectorToArtistMutation, Types.UpgradePseudoCollectorToArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpgradePseudoCollectorToArtistMutation, Types.UpgradePseudoCollectorToArtistMutationVariables>(UpgradePseudoCollectorToArtistDocument, options);
      }
export type UpgradePseudoCollectorToArtistMutationHookResult = ReturnType<typeof useUpgradePseudoCollectorToArtistMutation>;
export type UpgradePseudoCollectorToArtistMutationResult = Apollo.MutationResult<Types.UpgradePseudoCollectorToArtistMutation>;
export type UpgradePseudoCollectorToArtistMutationOptions = Apollo.BaseMutationOptions<Types.UpgradePseudoCollectorToArtistMutation, Types.UpgradePseudoCollectorToArtistMutationVariables>;
export const UserUpgradeReminderTriggerDocument = gql`
    mutation UserUpgradeReminderTrigger {
  userUpgradeReminderTrigger
}
    `;
export type UserUpgradeReminderTriggerMutationFn = Apollo.MutationFunction<Types.UserUpgradeReminderTriggerMutation, Types.UserUpgradeReminderTriggerMutationVariables>;

/**
 * __useUserUpgradeReminderTriggerMutation__
 *
 * To run a mutation, you first call `useUserUpgradeReminderTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpgradeReminderTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpgradeReminderTriggerMutation, { data, loading, error }] = useUserUpgradeReminderTriggerMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserUpgradeReminderTriggerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UserUpgradeReminderTriggerMutation, Types.UserUpgradeReminderTriggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UserUpgradeReminderTriggerMutation, Types.UserUpgradeReminderTriggerMutationVariables>(UserUpgradeReminderTriggerDocument, options);
      }
export type UserUpgradeReminderTriggerMutationHookResult = ReturnType<typeof useUserUpgradeReminderTriggerMutation>;
export type UserUpgradeReminderTriggerMutationResult = Apollo.MutationResult<Types.UserUpgradeReminderTriggerMutation>;
export type UserUpgradeReminderTriggerMutationOptions = Apollo.BaseMutationOptions<Types.UserUpgradeReminderTriggerMutation, Types.UserUpgradeReminderTriggerMutationVariables>;
export const GetCompleteProfileArtworkInformationDocument = gql`
    query GetCompleteProfileArtworkInformation($id: bigint!) {
  Artwork_by_pk(id: $id) {
    id
    primary_image_metadata_id
    thumbnail_metadata_id
    artwork_details {
      title
      width
      height
      measuring_unit
      ENUM_artwork_discipline {
        description
      }
      materials_and_medium
    }
    Artwork_prices {
      currency
      price
      is_public
    }
    artist_context {
      id
      profile {
        id
        avatar
        title
        handle
      }
      locations {
        country
        city
      }
    }
    edition_type
    Editions(
      where: {publish_date: {_is_null: false}, is_public: {_eq: true}, deleted_at: {_is_null: true}}
    ) {
      title
    }
  }
}
    `;

/**
 * __useGetCompleteProfileArtworkInformationQuery__
 *
 * To run a query within a React component, call `useGetCompleteProfileArtworkInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompleteProfileArtworkInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompleteProfileArtworkInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompleteProfileArtworkInformationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompleteProfileArtworkInformationQuery, Types.GetCompleteProfileArtworkInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompleteProfileArtworkInformationQuery, Types.GetCompleteProfileArtworkInformationQueryVariables>(GetCompleteProfileArtworkInformationDocument, options);
      }
export function useGetCompleteProfileArtworkInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompleteProfileArtworkInformationQuery, Types.GetCompleteProfileArtworkInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompleteProfileArtworkInformationQuery, Types.GetCompleteProfileArtworkInformationQueryVariables>(GetCompleteProfileArtworkInformationDocument, options);
        }
export type GetCompleteProfileArtworkInformationQueryHookResult = ReturnType<typeof useGetCompleteProfileArtworkInformationQuery>;
export type GetCompleteProfileArtworkInformationLazyQueryHookResult = ReturnType<typeof useGetCompleteProfileArtworkInformationLazyQuery>;
export type GetCompleteProfileArtworkInformationQueryResult = Apollo.QueryResult<Types.GetCompleteProfileArtworkInformationQuery, Types.GetCompleteProfileArtworkInformationQueryVariables>;
export const GetCompleteProfileArtistInformationDocument = gql`
    query GetCompleteProfileArtistInformation($contextId: bigint!) {
  Context_by_pk(id: $contextId) {
    id
    profile {
      id
      title
      handle
      avatar
    }
    locations {
      country
      city
    }
    Artworks(
      where: {deleted_at: {_is_null: true}, is_public: {_eq: true}}
      limit: 3
      order_by: {id: asc}
    ) {
      id
      thumbnail_metadata_id
    }
  }
}
    `;

/**
 * __useGetCompleteProfileArtistInformationQuery__
 *
 * To run a query within a React component, call `useGetCompleteProfileArtistInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompleteProfileArtistInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompleteProfileArtistInformationQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetCompleteProfileArtistInformationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCompleteProfileArtistInformationQuery, Types.GetCompleteProfileArtistInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCompleteProfileArtistInformationQuery, Types.GetCompleteProfileArtistInformationQueryVariables>(GetCompleteProfileArtistInformationDocument, options);
      }
export function useGetCompleteProfileArtistInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCompleteProfileArtistInformationQuery, Types.GetCompleteProfileArtistInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCompleteProfileArtistInformationQuery, Types.GetCompleteProfileArtistInformationQueryVariables>(GetCompleteProfileArtistInformationDocument, options);
        }
export type GetCompleteProfileArtistInformationQueryHookResult = ReturnType<typeof useGetCompleteProfileArtistInformationQuery>;
export type GetCompleteProfileArtistInformationLazyQueryHookResult = ReturnType<typeof useGetCompleteProfileArtistInformationLazyQuery>;
export type GetCompleteProfileArtistInformationQueryResult = Apollo.QueryResult<Types.GetCompleteProfileArtistInformationQuery, Types.GetCompleteProfileArtistInformationQueryVariables>;
export const UnsubscribeFromArtistNewsletterDocument = gql`
    mutation UnsubscribeFromArtistNewsletter($artistContextId: bigint!, $email: String!) {
  unsubscribeFromArtistNewsletter(
    artistContextId: $artistContextId
    email: $email
  )
}
    `;
export type UnsubscribeFromArtistNewsletterMutationFn = Apollo.MutationFunction<Types.UnsubscribeFromArtistNewsletterMutation, Types.UnsubscribeFromArtistNewsletterMutationVariables>;

/**
 * __useUnsubscribeFromArtistNewsletterMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromArtistNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromArtistNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromArtistNewsletterMutation, { data, loading, error }] = useUnsubscribeFromArtistNewsletterMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUnsubscribeFromArtistNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnsubscribeFromArtistNewsletterMutation, Types.UnsubscribeFromArtistNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnsubscribeFromArtistNewsletterMutation, Types.UnsubscribeFromArtistNewsletterMutationVariables>(UnsubscribeFromArtistNewsletterDocument, options);
      }
export type UnsubscribeFromArtistNewsletterMutationHookResult = ReturnType<typeof useUnsubscribeFromArtistNewsletterMutation>;
export type UnsubscribeFromArtistNewsletterMutationResult = Apollo.MutationResult<Types.UnsubscribeFromArtistNewsletterMutation>;
export type UnsubscribeFromArtistNewsletterMutationOptions = Apollo.BaseMutationOptions<Types.UnsubscribeFromArtistNewsletterMutation, Types.UnsubscribeFromArtistNewsletterMutationVariables>;
export const UpsertQuestionnaireAnswersDocument = gql`
    mutation upsertQuestionnaireAnswers($profileId: bigint!, $questionnaireType: String!, $questionAndAnswers: [QuestionAndAnswer!]!) {
  upsertQuestionnaireAnswers(
    profileId: $profileId
    questionnaireType: $questionnaireType
    questionAndAnswers: $questionAndAnswers
  )
}
    `;
export type UpsertQuestionnaireAnswersMutationFn = Apollo.MutationFunction<Types.UpsertQuestionnaireAnswersMutation, Types.UpsertQuestionnaireAnswersMutationVariables>;

/**
 * __useUpsertQuestionnaireAnswersMutation__
 *
 * To run a mutation, you first call `useUpsertQuestionnaireAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertQuestionnaireAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertQuestionnaireAnswersMutation, { data, loading, error }] = useUpsertQuestionnaireAnswersMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      questionnaireType: // value for 'questionnaireType'
 *      questionAndAnswers: // value for 'questionAndAnswers'
 *   },
 * });
 */
export function useUpsertQuestionnaireAnswersMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertQuestionnaireAnswersMutation, Types.UpsertQuestionnaireAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertQuestionnaireAnswersMutation, Types.UpsertQuestionnaireAnswersMutationVariables>(UpsertQuestionnaireAnswersDocument, options);
      }
export type UpsertQuestionnaireAnswersMutationHookResult = ReturnType<typeof useUpsertQuestionnaireAnswersMutation>;
export type UpsertQuestionnaireAnswersMutationResult = Apollo.MutationResult<Types.UpsertQuestionnaireAnswersMutation>;
export type UpsertQuestionnaireAnswersMutationOptions = Apollo.BaseMutationOptions<Types.UpsertQuestionnaireAnswersMutation, Types.UpsertQuestionnaireAnswersMutationVariables>;
export const TriggerAiIssueEmailDocument = gql`
    mutation triggerAiIssueEmail($options: [String!]!) {
  triggerAiIssueEmail(options: $options)
}
    `;
export type TriggerAiIssueEmailMutationFn = Apollo.MutationFunction<Types.TriggerAiIssueEmailMutation, Types.TriggerAiIssueEmailMutationVariables>;

/**
 * __useTriggerAiIssueEmailMutation__
 *
 * To run a mutation, you first call `useTriggerAiIssueEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerAiIssueEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerAiIssueEmailMutation, { data, loading, error }] = useTriggerAiIssueEmailMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useTriggerAiIssueEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.TriggerAiIssueEmailMutation, Types.TriggerAiIssueEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TriggerAiIssueEmailMutation, Types.TriggerAiIssueEmailMutationVariables>(TriggerAiIssueEmailDocument, options);
      }
export type TriggerAiIssueEmailMutationHookResult = ReturnType<typeof useTriggerAiIssueEmailMutation>;
export type TriggerAiIssueEmailMutationResult = Apollo.MutationResult<Types.TriggerAiIssueEmailMutation>;
export type TriggerAiIssueEmailMutationOptions = Apollo.BaseMutationOptions<Types.TriggerAiIssueEmailMutation, Types.TriggerAiIssueEmailMutationVariables>;
export const GetProfileStatementValidQuestionnaireQuestionsDocument = gql`
    query getProfileStatementValidQuestionnaireQuestions {
  Questionnaire_questions(
    where: {deleted_at: {_is_null: true}, type: {_eq: PROFILE_STATEMENT}}
  ) {
    id
    defaultText: default_text
  }
}
    `;

/**
 * __useGetProfileStatementValidQuestionnaireQuestionsQuery__
 *
 * To run a query within a React component, call `useGetProfileStatementValidQuestionnaireQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileStatementValidQuestionnaireQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileStatementValidQuestionnaireQuestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileStatementValidQuestionnaireQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetProfileStatementValidQuestionnaireQuestionsQuery, Types.GetProfileStatementValidQuestionnaireQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileStatementValidQuestionnaireQuestionsQuery, Types.GetProfileStatementValidQuestionnaireQuestionsQueryVariables>(GetProfileStatementValidQuestionnaireQuestionsDocument, options);
      }
export function useGetProfileStatementValidQuestionnaireQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileStatementValidQuestionnaireQuestionsQuery, Types.GetProfileStatementValidQuestionnaireQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileStatementValidQuestionnaireQuestionsQuery, Types.GetProfileStatementValidQuestionnaireQuestionsQueryVariables>(GetProfileStatementValidQuestionnaireQuestionsDocument, options);
        }
export type GetProfileStatementValidQuestionnaireQuestionsQueryHookResult = ReturnType<typeof useGetProfileStatementValidQuestionnaireQuestionsQuery>;
export type GetProfileStatementValidQuestionnaireQuestionsLazyQueryHookResult = ReturnType<typeof useGetProfileStatementValidQuestionnaireQuestionsLazyQuery>;
export type GetProfileStatementValidQuestionnaireQuestionsQueryResult = Apollo.QueryResult<Types.GetProfileStatementValidQuestionnaireQuestionsQuery, Types.GetProfileStatementValidQuestionnaireQuestionsQueryVariables>;
export const GetValidProfileQAndAsDocument = gql`
    query getValidProfileQAndAs($profileId: bigint!, $type: String!) {
  Valid_profile_questions_and_answers_view(
    where: {profile_id: {_eq: $profileId}, type: {_eq: $type}}
  ) {
    questionId: question_id
    defaultText: default_text
    answer
  }
}
    `;

/**
 * __useGetValidProfileQAndAsQuery__
 *
 * To run a query within a React component, call `useGetValidProfileQAndAsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidProfileQAndAsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidProfileQAndAsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetValidProfileQAndAsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetValidProfileQAndAsQuery, Types.GetValidProfileQAndAsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetValidProfileQAndAsQuery, Types.GetValidProfileQAndAsQueryVariables>(GetValidProfileQAndAsDocument, options);
      }
export function useGetValidProfileQAndAsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetValidProfileQAndAsQuery, Types.GetValidProfileQAndAsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetValidProfileQAndAsQuery, Types.GetValidProfileQAndAsQueryVariables>(GetValidProfileQAndAsDocument, options);
        }
export type GetValidProfileQAndAsQueryHookResult = ReturnType<typeof useGetValidProfileQAndAsQuery>;
export type GetValidProfileQAndAsLazyQueryHookResult = ReturnType<typeof useGetValidProfileQAndAsLazyQuery>;
export type GetValidProfileQAndAsQueryResult = Apollo.QueryResult<Types.GetValidProfileQAndAsQuery, Types.GetValidProfileQAndAsQueryVariables>;
export const GetAiGeneratedTextStatementDocument = gql`
    query getAIGeneratedTextStatement($profileId: String!, $language: String, $ignoreExistingStatment: Boolean) {
  textStatement: getTextStatement(
    profileId: $profileId
    language: $language
    ignoreExistingStatment: $ignoreExistingStatment
  )
}
    `;

/**
 * __useGetAiGeneratedTextStatementQuery__
 *
 * To run a query within a React component, call `useGetAiGeneratedTextStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAiGeneratedTextStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAiGeneratedTextStatementQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      language: // value for 'language'
 *      ignoreExistingStatment: // value for 'ignoreExistingStatment'
 *   },
 * });
 */
export function useGetAiGeneratedTextStatementQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAiGeneratedTextStatementQuery, Types.GetAiGeneratedTextStatementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAiGeneratedTextStatementQuery, Types.GetAiGeneratedTextStatementQueryVariables>(GetAiGeneratedTextStatementDocument, options);
      }
export function useGetAiGeneratedTextStatementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAiGeneratedTextStatementQuery, Types.GetAiGeneratedTextStatementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAiGeneratedTextStatementQuery, Types.GetAiGeneratedTextStatementQueryVariables>(GetAiGeneratedTextStatementDocument, options);
        }
export type GetAiGeneratedTextStatementQueryHookResult = ReturnType<typeof useGetAiGeneratedTextStatementQuery>;
export type GetAiGeneratedTextStatementLazyQueryHookResult = ReturnType<typeof useGetAiGeneratedTextStatementLazyQuery>;
export type GetAiGeneratedTextStatementQueryResult = Apollo.QueryResult<Types.GetAiGeneratedTextStatementQuery, Types.GetAiGeneratedTextStatementQueryVariables>;
export const GetGeneratedAudiosDocument = gql`
    query getGeneratedAudios($profileId: bigint!) {
  Profile_statement_audio_syntheses(
    where: {profile_id: {_eq: $profileId}, deleted_at: {_is_null: true}}
  ) {
    audio_id
    profile_id
    language
    text
  }
}
    `;

/**
 * __useGetGeneratedAudiosQuery__
 *
 * To run a query within a React component, call `useGetGeneratedAudiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedAudiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedAudiosQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetGeneratedAudiosQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGeneratedAudiosQuery, Types.GetGeneratedAudiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGeneratedAudiosQuery, Types.GetGeneratedAudiosQueryVariables>(GetGeneratedAudiosDocument, options);
      }
export function useGetGeneratedAudiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGeneratedAudiosQuery, Types.GetGeneratedAudiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGeneratedAudiosQuery, Types.GetGeneratedAudiosQueryVariables>(GetGeneratedAudiosDocument, options);
        }
export type GetGeneratedAudiosQueryHookResult = ReturnType<typeof useGetGeneratedAudiosQuery>;
export type GetGeneratedAudiosLazyQueryHookResult = ReturnType<typeof useGetGeneratedAudiosLazyQuery>;
export type GetGeneratedAudiosQueryResult = Apollo.QueryResult<Types.GetGeneratedAudiosQuery, Types.GetGeneratedAudiosQueryVariables>;
export const GetCollectorListGalleryDocument = gql`
    query getCollectorListGallery($gallery_context_id: bigint!) {
  Artwork_transactions(
    where: {artwork: {gallery_context_id: {_eq: $gallery_context_id}}}
    distinct_on: context_buyer_id
  ) {
    buyer_context {
      id
      profile {
        id
        avatar
        handle
        first_name
        last_name
        email
        is_public
      }
    }
  }
}
    `;

/**
 * __useGetCollectorListGalleryQuery__
 *
 * To run a query within a React component, call `useGetCollectorListGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorListGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorListGalleryQuery({
 *   variables: {
 *      gallery_context_id: // value for 'gallery_context_id'
 *   },
 * });
 */
export function useGetCollectorListGalleryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorListGalleryQuery, Types.GetCollectorListGalleryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorListGalleryQuery, Types.GetCollectorListGalleryQueryVariables>(GetCollectorListGalleryDocument, options);
      }
export function useGetCollectorListGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorListGalleryQuery, Types.GetCollectorListGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorListGalleryQuery, Types.GetCollectorListGalleryQueryVariables>(GetCollectorListGalleryDocument, options);
        }
export type GetCollectorListGalleryQueryHookResult = ReturnType<typeof useGetCollectorListGalleryQuery>;
export type GetCollectorListGalleryLazyQueryHookResult = ReturnType<typeof useGetCollectorListGalleryLazyQuery>;
export type GetCollectorListGalleryQueryResult = Apollo.QueryResult<Types.GetCollectorListGalleryQuery, Types.GetCollectorListGalleryQueryVariables>;
export const GetCollectorListArtistDocument = gql`
    query getCollectorListArtist($artist_context_id: bigint!) {
  Artwork_transactions(
    where: {status: {_eq: ACCEPTED}, artwork: {artist_context_id: {_eq: $artist_context_id}}}
    distinct_on: context_buyer_id
  ) {
    buyer_context {
      id
      profile {
        id
        avatar
        handle
        first_name
        last_name
        email
        is_public
      }
    }
  }
}
    `;

/**
 * __useGetCollectorListArtistQuery__
 *
 * To run a query within a React component, call `useGetCollectorListArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorListArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorListArtistQuery({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useGetCollectorListArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorListArtistQuery, Types.GetCollectorListArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorListArtistQuery, Types.GetCollectorListArtistQueryVariables>(GetCollectorListArtistDocument, options);
      }
export function useGetCollectorListArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorListArtistQuery, Types.GetCollectorListArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorListArtistQuery, Types.GetCollectorListArtistQueryVariables>(GetCollectorListArtistDocument, options);
        }
export type GetCollectorListArtistQueryHookResult = ReturnType<typeof useGetCollectorListArtistQuery>;
export type GetCollectorListArtistLazyQueryHookResult = ReturnType<typeof useGetCollectorListArtistLazyQuery>;
export type GetCollectorListArtistQueryResult = Apollo.QueryResult<Types.GetCollectorListArtistQuery, Types.GetCollectorListArtistQueryVariables>;
export const GetCollectorEmailsDocument = gql`
    query GetCollectorEmails($collectorIds: [bigint!]!) {
  All_collectors(where: {context_id: {_in: $collectorIds}}) {
    context_id
    email
  }
}
    `;

/**
 * __useGetCollectorEmailsQuery__
 *
 * To run a query within a React component, call `useGetCollectorEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorEmailsQuery({
 *   variables: {
 *      collectorIds: // value for 'collectorIds'
 *   },
 * });
 */
export function useGetCollectorEmailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorEmailsQuery, Types.GetCollectorEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorEmailsQuery, Types.GetCollectorEmailsQueryVariables>(GetCollectorEmailsDocument, options);
      }
export function useGetCollectorEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorEmailsQuery, Types.GetCollectorEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorEmailsQuery, Types.GetCollectorEmailsQueryVariables>(GetCollectorEmailsDocument, options);
        }
export type GetCollectorEmailsQueryHookResult = ReturnType<typeof useGetCollectorEmailsQuery>;
export type GetCollectorEmailsLazyQueryHookResult = ReturnType<typeof useGetCollectorEmailsLazyQuery>;
export type GetCollectorEmailsQueryResult = Apollo.QueryResult<Types.GetCollectorEmailsQuery, Types.GetCollectorEmailsQueryVariables>;
export const GetArtistInvitesDocument = gql`
    query GetArtistInvites($artist_id: bigint!, $status: ENUM_collaboration_status_enum_comparison_exp) {
  All_galleries(
    where: {Context: {collaboration_by_gallery_id: {artist_id: {_eq: $artist_id}, artist_status: $status, gallery_status: {_eq: ACCEPTED}}}}
  ) {
    title
    email
    Context {
      id
      profile {
        id
        handle
        avatar
      }
      collaboration_by_gallery_id(where: {artist_id: {_eq: $artist_id}}) {
        id
      }
    }
  }
}
    `;

/**
 * __useGetArtistInvitesQuery__
 *
 * To run a query within a React component, call `useGetArtistInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistInvitesQuery({
 *   variables: {
 *      artist_id: // value for 'artist_id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetArtistInvitesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistInvitesQuery, Types.GetArtistInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistInvitesQuery, Types.GetArtistInvitesQueryVariables>(GetArtistInvitesDocument, options);
      }
export function useGetArtistInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistInvitesQuery, Types.GetArtistInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistInvitesQuery, Types.GetArtistInvitesQueryVariables>(GetArtistInvitesDocument, options);
        }
export type GetArtistInvitesQueryHookResult = ReturnType<typeof useGetArtistInvitesQuery>;
export type GetArtistInvitesLazyQueryHookResult = ReturnType<typeof useGetArtistInvitesLazyQuery>;
export type GetArtistInvitesQueryResult = Apollo.QueryResult<Types.GetArtistInvitesQuery, Types.GetArtistInvitesQueryVariables>;
export const PublicGetArtistInvitesDocument = gql`
    query PublicGetArtistInvites($artist_id: bigint!) {
  All_galleries(
    where: {Context: {collaboration_by_gallery_id: {artist_id: {_eq: $artist_id}}}}
  ) {
    title
    email
    Context {
      id
      profile {
        id
        handle
        avatar
      }
      collaboration_by_gallery_id(where: {artist_id: {_eq: $artist_id}}) {
        id
      }
    }
  }
}
    `;

/**
 * __usePublicGetArtistInvitesQuery__
 *
 * To run a query within a React component, call `usePublicGetArtistInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicGetArtistInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicGetArtistInvitesQuery({
 *   variables: {
 *      artist_id: // value for 'artist_id'
 *   },
 * });
 */
export function usePublicGetArtistInvitesQuery(baseOptions: Apollo.QueryHookOptions<Types.PublicGetArtistInvitesQuery, Types.PublicGetArtistInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicGetArtistInvitesQuery, Types.PublicGetArtistInvitesQueryVariables>(PublicGetArtistInvitesDocument, options);
      }
export function usePublicGetArtistInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicGetArtistInvitesQuery, Types.PublicGetArtistInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicGetArtistInvitesQuery, Types.PublicGetArtistInvitesQueryVariables>(PublicGetArtistInvitesDocument, options);
        }
export type PublicGetArtistInvitesQueryHookResult = ReturnType<typeof usePublicGetArtistInvitesQuery>;
export type PublicGetArtistInvitesLazyQueryHookResult = ReturnType<typeof usePublicGetArtistInvitesLazyQuery>;
export type PublicGetArtistInvitesQueryResult = Apollo.QueryResult<Types.PublicGetArtistInvitesQuery, Types.PublicGetArtistInvitesQueryVariables>;
export const GetAllArtistArtworksDocument = gql`
    query GetAllArtistArtworks($artistContextId: bigint!) {
  Artwork(where: {artist_context_id: {_eq: $artistContextId}}) {
    id
  }
}
    `;

/**
 * __useGetAllArtistArtworksQuery__
 *
 * To run a query within a React component, call `useGetAllArtistArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllArtistArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllArtistArtworksQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetAllArtistArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllArtistArtworksQuery, Types.GetAllArtistArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllArtistArtworksQuery, Types.GetAllArtistArtworksQueryVariables>(GetAllArtistArtworksDocument, options);
      }
export function useGetAllArtistArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllArtistArtworksQuery, Types.GetAllArtistArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllArtistArtworksQuery, Types.GetAllArtistArtworksQueryVariables>(GetAllArtistArtworksDocument, options);
        }
export type GetAllArtistArtworksQueryHookResult = ReturnType<typeof useGetAllArtistArtworksQuery>;
export type GetAllArtistArtworksLazyQueryHookResult = ReturnType<typeof useGetAllArtistArtworksLazyQuery>;
export type GetAllArtistArtworksQueryResult = Apollo.QueryResult<Types.GetAllArtistArtworksQuery, Types.GetAllArtistArtworksQueryVariables>;
export const GetCardPosibleArtworksDocument = gql`
    query getCardPosibleArtworks($id: bigint!) {
  published: Artwork(
    order_by: {published_date: desc_nulls_last}
    where: {is_public: {_eq: true}, artist_context_id: {_eq: $id}}
  ) {
    id
    thumbnail_metadata_id
    artwork_details {
      title
    }
  }
}
    `;

/**
 * __useGetCardPosibleArtworksQuery__
 *
 * To run a query within a React component, call `useGetCardPosibleArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardPosibleArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardPosibleArtworksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCardPosibleArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCardPosibleArtworksQuery, Types.GetCardPosibleArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCardPosibleArtworksQuery, Types.GetCardPosibleArtworksQueryVariables>(GetCardPosibleArtworksDocument, options);
      }
export function useGetCardPosibleArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCardPosibleArtworksQuery, Types.GetCardPosibleArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCardPosibleArtworksQuery, Types.GetCardPosibleArtworksQueryVariables>(GetCardPosibleArtworksDocument, options);
        }
export type GetCardPosibleArtworksQueryHookResult = ReturnType<typeof useGetCardPosibleArtworksQuery>;
export type GetCardPosibleArtworksLazyQueryHookResult = ReturnType<typeof useGetCardPosibleArtworksLazyQuery>;
export type GetCardPosibleArtworksQueryResult = Apollo.QueryResult<Types.GetCardPosibleArtworksQuery, Types.GetCardPosibleArtworksQueryVariables>;
export const SetCardGridArtworksDocument = gql`
    mutation setCardGridArtworks($artwork_ids: [Int]!, $context_id: Int!) {
  setCardGridArtworks(artwork_ids: $artwork_ids, context_id: $context_id)
}
    `;
export type SetCardGridArtworksMutationFn = Apollo.MutationFunction<Types.SetCardGridArtworksMutation, Types.SetCardGridArtworksMutationVariables>;

/**
 * __useSetCardGridArtworksMutation__
 *
 * To run a mutation, you first call `useSetCardGridArtworksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCardGridArtworksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCardGridArtworksMutation, { data, loading, error }] = useSetCardGridArtworksMutation({
 *   variables: {
 *      artwork_ids: // value for 'artwork_ids'
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useSetCardGridArtworksMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetCardGridArtworksMutation, Types.SetCardGridArtworksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetCardGridArtworksMutation, Types.SetCardGridArtworksMutationVariables>(SetCardGridArtworksDocument, options);
      }
export type SetCardGridArtworksMutationHookResult = ReturnType<typeof useSetCardGridArtworksMutation>;
export type SetCardGridArtworksMutationResult = Apollo.MutationResult<Types.SetCardGridArtworksMutation>;
export type SetCardGridArtworksMutationOptions = Apollo.BaseMutationOptions<Types.SetCardGridArtworksMutation, Types.SetCardGridArtworksMutationVariables>;
export const GetProfileCustomLinksDocument = gql`
    query getProfileCustomLinks($id: bigint!) {
  Profile_by_pk(id: $id) {
    context {
      profile_social_links(where: {status: {_eq: ACTIVE}}, order_by: {order: asc}) {
        is_pinned
        URL
        platform
        id
        custom_link
        type
        status
        order
      }
    }
  }
}
    `;

/**
 * __useGetProfileCustomLinksQuery__
 *
 * To run a query within a React component, call `useGetProfileCustomLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCustomLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCustomLinksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileCustomLinksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileCustomLinksQuery, Types.GetProfileCustomLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileCustomLinksQuery, Types.GetProfileCustomLinksQueryVariables>(GetProfileCustomLinksDocument, options);
      }
export function useGetProfileCustomLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileCustomLinksQuery, Types.GetProfileCustomLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileCustomLinksQuery, Types.GetProfileCustomLinksQueryVariables>(GetProfileCustomLinksDocument, options);
        }
export type GetProfileCustomLinksQueryHookResult = ReturnType<typeof useGetProfileCustomLinksQuery>;
export type GetProfileCustomLinksLazyQueryHookResult = ReturnType<typeof useGetProfileCustomLinksLazyQuery>;
export type GetProfileCustomLinksQueryResult = Apollo.QueryResult<Types.GetProfileCustomLinksQuery, Types.GetProfileCustomLinksQueryVariables>;
export const GetHandleDocument = gql`
    query getHandle($id: bigint!) {
  Profile_by_pk(id: $id) {
    handle
  }
}
    `;

/**
 * __useGetHandleQuery__
 *
 * To run a query within a React component, call `useGetHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetHandleQuery, Types.GetHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetHandleQuery, Types.GetHandleQueryVariables>(GetHandleDocument, options);
      }
export function useGetHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetHandleQuery, Types.GetHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetHandleQuery, Types.GetHandleQueryVariables>(GetHandleDocument, options);
        }
export type GetHandleQueryHookResult = ReturnType<typeof useGetHandleQuery>;
export type GetHandleLazyQueryHookResult = ReturnType<typeof useGetHandleLazyQuery>;
export type GetHandleQueryResult = Apollo.QueryResult<Types.GetHandleQuery, Types.GetHandleQueryVariables>;
export const GetProfileThemeDocument = gql`
    query getProfileTheme($id: bigint!) {
  Profile_by_pk(id: $id) {
    profile_theme
  }
}
    `;

/**
 * __useGetProfileThemeQuery__
 *
 * To run a query within a React component, call `useGetProfileThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileThemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileThemeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileThemeQuery, Types.GetProfileThemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileThemeQuery, Types.GetProfileThemeQueryVariables>(GetProfileThemeDocument, options);
      }
export function useGetProfileThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileThemeQuery, Types.GetProfileThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileThemeQuery, Types.GetProfileThemeQueryVariables>(GetProfileThemeDocument, options);
        }
export type GetProfileThemeQueryHookResult = ReturnType<typeof useGetProfileThemeQuery>;
export type GetProfileThemeLazyQueryHookResult = ReturnType<typeof useGetProfileThemeLazyQuery>;
export type GetProfileThemeQueryResult = Apollo.QueryResult<Types.GetProfileThemeQuery, Types.GetProfileThemeQueryVariables>;
export const GetKaleidoCardBannerImageDocument = gql`
    query getKaleidoCardBannerImage($id: bigint!) {
  Profile_by_pk(id: $id) {
    id
    avatar
    banner
    kard_banner
  }
}
    `;

/**
 * __useGetKaleidoCardBannerImageQuery__
 *
 * To run a query within a React component, call `useGetKaleidoCardBannerImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKaleidoCardBannerImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKaleidoCardBannerImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKaleidoCardBannerImageQuery(baseOptions: Apollo.QueryHookOptions<Types.GetKaleidoCardBannerImageQuery, Types.GetKaleidoCardBannerImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKaleidoCardBannerImageQuery, Types.GetKaleidoCardBannerImageQueryVariables>(GetKaleidoCardBannerImageDocument, options);
      }
export function useGetKaleidoCardBannerImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKaleidoCardBannerImageQuery, Types.GetKaleidoCardBannerImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKaleidoCardBannerImageQuery, Types.GetKaleidoCardBannerImageQueryVariables>(GetKaleidoCardBannerImageDocument, options);
        }
export type GetKaleidoCardBannerImageQueryHookResult = ReturnType<typeof useGetKaleidoCardBannerImageQuery>;
export type GetKaleidoCardBannerImageLazyQueryHookResult = ReturnType<typeof useGetKaleidoCardBannerImageLazyQuery>;
export type GetKaleidoCardBannerImageQueryResult = Apollo.QueryResult<Types.GetKaleidoCardBannerImageQuery, Types.GetKaleidoCardBannerImageQueryVariables>;
export const DeleteProfileSocialLinksDocument = gql`
    mutation deleteProfileSocialLinks($id: bigint!) {
  delete_Profile_social_links_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteProfileSocialLinksMutationFn = Apollo.MutationFunction<Types.DeleteProfileSocialLinksMutation, Types.DeleteProfileSocialLinksMutationVariables>;

/**
 * __useDeleteProfileSocialLinksMutation__
 *
 * To run a mutation, you first call `useDeleteProfileSocialLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileSocialLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileSocialLinksMutation, { data, loading, error }] = useDeleteProfileSocialLinksMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProfileSocialLinksMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProfileSocialLinksMutation, Types.DeleteProfileSocialLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProfileSocialLinksMutation, Types.DeleteProfileSocialLinksMutationVariables>(DeleteProfileSocialLinksDocument, options);
      }
export type DeleteProfileSocialLinksMutationHookResult = ReturnType<typeof useDeleteProfileSocialLinksMutation>;
export type DeleteProfileSocialLinksMutationResult = Apollo.MutationResult<Types.DeleteProfileSocialLinksMutation>;
export type DeleteProfileSocialLinksMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProfileSocialLinksMutation, Types.DeleteProfileSocialLinksMutationVariables>;
export const UpdateProfileSocialLinksDocument = gql`
    mutation updateProfileSocialLinks($id: bigint) {
  update_Profile_social_links(where: {id: {_eq: $id}}, _set: {status: DELETED}) {
    affected_rows
  }
}
    `;
export type UpdateProfileSocialLinksMutationFn = Apollo.MutationFunction<Types.UpdateProfileSocialLinksMutation, Types.UpdateProfileSocialLinksMutationVariables>;

/**
 * __useUpdateProfileSocialLinksMutation__
 *
 * To run a mutation, you first call `useUpdateProfileSocialLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileSocialLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileSocialLinksMutation, { data, loading, error }] = useUpdateProfileSocialLinksMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProfileSocialLinksMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileSocialLinksMutation, Types.UpdateProfileSocialLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileSocialLinksMutation, Types.UpdateProfileSocialLinksMutationVariables>(UpdateProfileSocialLinksDocument, options);
      }
export type UpdateProfileSocialLinksMutationHookResult = ReturnType<typeof useUpdateProfileSocialLinksMutation>;
export type UpdateProfileSocialLinksMutationResult = Apollo.MutationResult<Types.UpdateProfileSocialLinksMutation>;
export type UpdateProfileSocialLinksMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileSocialLinksMutation, Types.UpdateProfileSocialLinksMutationVariables>;
export const UpdateProfileHasUsedCardDocument = gql`
    mutation updateProfileHasUsedCard($id: bigint!) {
  update_Profile(where: {id: {_eq: $id}}, _set: {has_used_card: true}) {
    affected_rows
  }
}
    `;
export type UpdateProfileHasUsedCardMutationFn = Apollo.MutationFunction<Types.UpdateProfileHasUsedCardMutation, Types.UpdateProfileHasUsedCardMutationVariables>;

/**
 * __useUpdateProfileHasUsedCardMutation__
 *
 * To run a mutation, you first call `useUpdateProfileHasUsedCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileHasUsedCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileHasUsedCardMutation, { data, loading, error }] = useUpdateProfileHasUsedCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateProfileHasUsedCardMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileHasUsedCardMutation, Types.UpdateProfileHasUsedCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileHasUsedCardMutation, Types.UpdateProfileHasUsedCardMutationVariables>(UpdateProfileHasUsedCardDocument, options);
      }
export type UpdateProfileHasUsedCardMutationHookResult = ReturnType<typeof useUpdateProfileHasUsedCardMutation>;
export type UpdateProfileHasUsedCardMutationResult = Apollo.MutationResult<Types.UpdateProfileHasUsedCardMutation>;
export type UpdateProfileHasUsedCardMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileHasUsedCardMutation, Types.UpdateProfileHasUsedCardMutationVariables>;
export const UpdateSocialLinkDocument = gql`
    mutation updateSocialLink($link_id: bigint!, $platform: String = "", $order: Int = 10, $custom_link: Boolean = false, $URL: String = "", $is_pinned: Boolean = false) {
  update_Profile_social_links(
    where: {id: {_eq: $link_id}}
    _set: {platform: $platform, order: $order, custom_link: $custom_link, URL: $URL, is_pinned: $is_pinned}
  ) {
    affected_rows
  }
}
    `;
export type UpdateSocialLinkMutationFn = Apollo.MutationFunction<Types.UpdateSocialLinkMutation, Types.UpdateSocialLinkMutationVariables>;

/**
 * __useUpdateSocialLinkMutation__
 *
 * To run a mutation, you first call `useUpdateSocialLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSocialLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSocialLinkMutation, { data, loading, error }] = useUpdateSocialLinkMutation({
 *   variables: {
 *      link_id: // value for 'link_id'
 *      platform: // value for 'platform'
 *      order: // value for 'order'
 *      custom_link: // value for 'custom_link'
 *      URL: // value for 'URL'
 *      is_pinned: // value for 'is_pinned'
 *   },
 * });
 */
export function useUpdateSocialLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSocialLinkMutation, Types.UpdateSocialLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSocialLinkMutation, Types.UpdateSocialLinkMutationVariables>(UpdateSocialLinkDocument, options);
      }
export type UpdateSocialLinkMutationHookResult = ReturnType<typeof useUpdateSocialLinkMutation>;
export type UpdateSocialLinkMutationResult = Apollo.MutationResult<Types.UpdateSocialLinkMutation>;
export type UpdateSocialLinkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSocialLinkMutation, Types.UpdateSocialLinkMutationVariables>;
export const UpdateKaleidoCardLinkDocument = gql`
    mutation updateKaleidoCardLink($linkId: bigint!, $data: Profile_social_links_set_input!) {
  update_Profile_social_links_by_pk(pk_columns: {id: $linkId}, _set: $data) {
    id
  }
}
    `;
export type UpdateKaleidoCardLinkMutationFn = Apollo.MutationFunction<Types.UpdateKaleidoCardLinkMutation, Types.UpdateKaleidoCardLinkMutationVariables>;

/**
 * __useUpdateKaleidoCardLinkMutation__
 *
 * To run a mutation, you first call `useUpdateKaleidoCardLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKaleidoCardLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKaleidoCardLinkMutation, { data, loading, error }] = useUpdateKaleidoCardLinkMutation({
 *   variables: {
 *      linkId: // value for 'linkId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateKaleidoCardLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateKaleidoCardLinkMutation, Types.UpdateKaleidoCardLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateKaleidoCardLinkMutation, Types.UpdateKaleidoCardLinkMutationVariables>(UpdateKaleidoCardLinkDocument, options);
      }
export type UpdateKaleidoCardLinkMutationHookResult = ReturnType<typeof useUpdateKaleidoCardLinkMutation>;
export type UpdateKaleidoCardLinkMutationResult = Apollo.MutationResult<Types.UpdateKaleidoCardLinkMutation>;
export type UpdateKaleidoCardLinkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateKaleidoCardLinkMutation, Types.UpdateKaleidoCardLinkMutationVariables>;
export const DeleteProfileSocialLinkDocument = gql`
    mutation deleteProfileSocialLink($ids: [bigint!]!) {
  update_Profile_social_links(where: {id: {_in: $ids}}, _set: {status: DELETED}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteProfileSocialLinkMutationFn = Apollo.MutationFunction<Types.DeleteProfileSocialLinkMutation, Types.DeleteProfileSocialLinkMutationVariables>;

/**
 * __useDeleteProfileSocialLinkMutation__
 *
 * To run a mutation, you first call `useDeleteProfileSocialLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileSocialLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileSocialLinkMutation, { data, loading, error }] = useDeleteProfileSocialLinkMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteProfileSocialLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProfileSocialLinkMutation, Types.DeleteProfileSocialLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProfileSocialLinkMutation, Types.DeleteProfileSocialLinkMutationVariables>(DeleteProfileSocialLinkDocument, options);
      }
export type DeleteProfileSocialLinkMutationHookResult = ReturnType<typeof useDeleteProfileSocialLinkMutation>;
export type DeleteProfileSocialLinkMutationResult = Apollo.MutationResult<Types.DeleteProfileSocialLinkMutation>;
export type DeleteProfileSocialLinkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProfileSocialLinkMutation, Types.DeleteProfileSocialLinkMutationVariables>;
export const InsertProfileSocialLinksDocument = gql`
    mutation insertProfileSocialLinks($objects: [Profile_social_links_insert_input!]! = []) {
  insert_Profile_social_links(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type InsertProfileSocialLinksMutationFn = Apollo.MutationFunction<Types.InsertProfileSocialLinksMutation, Types.InsertProfileSocialLinksMutationVariables>;

/**
 * __useInsertProfileSocialLinksMutation__
 *
 * To run a mutation, you first call `useInsertProfileSocialLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProfileSocialLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProfileSocialLinksMutation, { data, loading, error }] = useInsertProfileSocialLinksMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertProfileSocialLinksMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertProfileSocialLinksMutation, Types.InsertProfileSocialLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertProfileSocialLinksMutation, Types.InsertProfileSocialLinksMutationVariables>(InsertProfileSocialLinksDocument, options);
      }
export type InsertProfileSocialLinksMutationHookResult = ReturnType<typeof useInsertProfileSocialLinksMutation>;
export type InsertProfileSocialLinksMutationResult = Apollo.MutationResult<Types.InsertProfileSocialLinksMutation>;
export type InsertProfileSocialLinksMutationOptions = Apollo.BaseMutationOptions<Types.InsertProfileSocialLinksMutation, Types.InsertProfileSocialLinksMutationVariables>;
export const GetKaleidoCardByIdDocument = gql`
    query GetKaleidoCardById($id: bigint!) {
  Profile(where: {id: {_eq: $id}, context: {type: {_eq: ARTIST}}}) {
    id
    avatar
    banner
    kard_banner
    audio_download_link
    Valid_profile_statement_audio_syntheses {
      audio_id
      profile_id
      language
    }
    handle
    title
    quote
    profile_theme
    profile_theme
    audio_metadata {
      file_name
      extra
    }
    context {
      locations {
        city
        country
      }
      id
      profile_social_links(where: {status: {_eq: ACTIVE}}, order_by: {order: asc}) {
        URL
        platform
        id
        custom_link
        status
        type
        is_pinned
        order
      }
    }
  }
}
    `;

/**
 * __useGetKaleidoCardByIdQuery__
 *
 * To run a query within a React component, call `useGetKaleidoCardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKaleidoCardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKaleidoCardByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetKaleidoCardByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetKaleidoCardByIdQuery, Types.GetKaleidoCardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKaleidoCardByIdQuery, Types.GetKaleidoCardByIdQueryVariables>(GetKaleidoCardByIdDocument, options);
      }
export function useGetKaleidoCardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKaleidoCardByIdQuery, Types.GetKaleidoCardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKaleidoCardByIdQuery, Types.GetKaleidoCardByIdQueryVariables>(GetKaleidoCardByIdDocument, options);
        }
export type GetKaleidoCardByIdQueryHookResult = ReturnType<typeof useGetKaleidoCardByIdQuery>;
export type GetKaleidoCardByIdLazyQueryHookResult = ReturnType<typeof useGetKaleidoCardByIdLazyQuery>;
export type GetKaleidoCardByIdQueryResult = Apollo.QueryResult<Types.GetKaleidoCardByIdQuery, Types.GetKaleidoCardByIdQueryVariables>;
export const GetAnalyticsKaleidoCardByProfileIdDocument = gql`
    query getAnalyticsKaleidoCardByProfileId($profileId: bigint!, $from: date, $to: date) {
  getAnalyticsKaleidoCardByProfileId(
    args: {_profile_id: $profileId, _from: $from, _to: $to}
  ) {
    id
    type
    category
    description
    url
    amount
  }
}
    `;

/**
 * __useGetAnalyticsKaleidoCardByProfileIdQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsKaleidoCardByProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsKaleidoCardByProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsKaleidoCardByProfileIdQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useGetAnalyticsKaleidoCardByProfileIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAnalyticsKaleidoCardByProfileIdQuery, Types.GetAnalyticsKaleidoCardByProfileIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAnalyticsKaleidoCardByProfileIdQuery, Types.GetAnalyticsKaleidoCardByProfileIdQueryVariables>(GetAnalyticsKaleidoCardByProfileIdDocument, options);
      }
export function useGetAnalyticsKaleidoCardByProfileIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAnalyticsKaleidoCardByProfileIdQuery, Types.GetAnalyticsKaleidoCardByProfileIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAnalyticsKaleidoCardByProfileIdQuery, Types.GetAnalyticsKaleidoCardByProfileIdQueryVariables>(GetAnalyticsKaleidoCardByProfileIdDocument, options);
        }
export type GetAnalyticsKaleidoCardByProfileIdQueryHookResult = ReturnType<typeof useGetAnalyticsKaleidoCardByProfileIdQuery>;
export type GetAnalyticsKaleidoCardByProfileIdLazyQueryHookResult = ReturnType<typeof useGetAnalyticsKaleidoCardByProfileIdLazyQuery>;
export type GetAnalyticsKaleidoCardByProfileIdQueryResult = Apollo.QueryResult<Types.GetAnalyticsKaleidoCardByProfileIdQuery, Types.GetAnalyticsKaleidoCardByProfileIdQueryVariables>;
export const UpsertBiolinkSectionOrderDocument = gql`
    mutation upsertBiolinkSectionOrder($context_id: bigint!, $section: ENUM_social_link_type_enum!, $order: Int!) {
  insert_Biolink_sections_one(
    object: {context_id: $context_id, section: $section, order: $order}
    on_conflict: {constraint: Biolink_sections_context_id_section_key, update_columns: [order]}
  ) {
    id
  }
}
    `;
export type UpsertBiolinkSectionOrderMutationFn = Apollo.MutationFunction<Types.UpsertBiolinkSectionOrderMutation, Types.UpsertBiolinkSectionOrderMutationVariables>;

/**
 * __useUpsertBiolinkSectionOrderMutation__
 *
 * To run a mutation, you first call `useUpsertBiolinkSectionOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBiolinkSectionOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBiolinkSectionOrderMutation, { data, loading, error }] = useUpsertBiolinkSectionOrderMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      section: // value for 'section'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpsertBiolinkSectionOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertBiolinkSectionOrderMutation, Types.UpsertBiolinkSectionOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertBiolinkSectionOrderMutation, Types.UpsertBiolinkSectionOrderMutationVariables>(UpsertBiolinkSectionOrderDocument, options);
      }
export type UpsertBiolinkSectionOrderMutationHookResult = ReturnType<typeof useUpsertBiolinkSectionOrderMutation>;
export type UpsertBiolinkSectionOrderMutationResult = Apollo.MutationResult<Types.UpsertBiolinkSectionOrderMutation>;
export type UpsertBiolinkSectionOrderMutationOptions = Apollo.BaseMutationOptions<Types.UpsertBiolinkSectionOrderMutation, Types.UpsertBiolinkSectionOrderMutationVariables>;
export const GetBiolinkSectionsOrderDocument = gql`
    query getBiolinkSectionsOrder($context_id: bigint!) {
  Biolink_sections(
    where: {context_id: {_eq: $context_id}}
    order_by: {order: asc}
  ) {
    id
    section
    order
  }
}
    `;

/**
 * __useGetBiolinkSectionsOrderQuery__
 *
 * To run a query within a React component, call `useGetBiolinkSectionsOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBiolinkSectionsOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBiolinkSectionsOrderQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetBiolinkSectionsOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBiolinkSectionsOrderQuery, Types.GetBiolinkSectionsOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBiolinkSectionsOrderQuery, Types.GetBiolinkSectionsOrderQueryVariables>(GetBiolinkSectionsOrderDocument, options);
      }
export function useGetBiolinkSectionsOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBiolinkSectionsOrderQuery, Types.GetBiolinkSectionsOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBiolinkSectionsOrderQuery, Types.GetBiolinkSectionsOrderQueryVariables>(GetBiolinkSectionsOrderDocument, options);
        }
export type GetBiolinkSectionsOrderQueryHookResult = ReturnType<typeof useGetBiolinkSectionsOrderQuery>;
export type GetBiolinkSectionsOrderLazyQueryHookResult = ReturnType<typeof useGetBiolinkSectionsOrderLazyQuery>;
export type GetBiolinkSectionsOrderQueryResult = Apollo.QueryResult<Types.GetBiolinkSectionsOrderQuery, Types.GetBiolinkSectionsOrderQueryVariables>;
export const GetArtistSubscriptionTypeDocument = gql`
    query getArtistSubscriptionType($artistContextId: bigint!) {
  getActiveSubscription(artistContextId: $artistContextId) {
    subscriptionType
  }
}
    `;

/**
 * __useGetArtistSubscriptionTypeQuery__
 *
 * To run a query within a React component, call `useGetArtistSubscriptionTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistSubscriptionTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistSubscriptionTypeQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetArtistSubscriptionTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistSubscriptionTypeQuery, Types.GetArtistSubscriptionTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistSubscriptionTypeQuery, Types.GetArtistSubscriptionTypeQueryVariables>(GetArtistSubscriptionTypeDocument, options);
      }
export function useGetArtistSubscriptionTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistSubscriptionTypeQuery, Types.GetArtistSubscriptionTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistSubscriptionTypeQuery, Types.GetArtistSubscriptionTypeQueryVariables>(GetArtistSubscriptionTypeDocument, options);
        }
export type GetArtistSubscriptionTypeQueryHookResult = ReturnType<typeof useGetArtistSubscriptionTypeQuery>;
export type GetArtistSubscriptionTypeLazyQueryHookResult = ReturnType<typeof useGetArtistSubscriptionTypeLazyQuery>;
export type GetArtistSubscriptionTypeQueryResult = Apollo.QueryResult<Types.GetArtistSubscriptionTypeQuery, Types.GetArtistSubscriptionTypeQueryVariables>;
export const GetImageBannerByProfileIdDocument = gql`
    query getImageBannerByProfileId($id: bigint!) {
  Profile_by_pk(id: $id) {
    id
    avatar
    kard_banner
    banner
    featured_art
    handle
    cv_download_link
    cv_metadata {
      file_name
      file_extension
    }
  }
}
    `;

/**
 * __useGetImageBannerByProfileIdQuery__
 *
 * To run a query within a React component, call `useGetImageBannerByProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageBannerByProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageBannerByProfileIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetImageBannerByProfileIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetImageBannerByProfileIdQuery, Types.GetImageBannerByProfileIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetImageBannerByProfileIdQuery, Types.GetImageBannerByProfileIdQueryVariables>(GetImageBannerByProfileIdDocument, options);
      }
export function useGetImageBannerByProfileIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetImageBannerByProfileIdQuery, Types.GetImageBannerByProfileIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetImageBannerByProfileIdQuery, Types.GetImageBannerByProfileIdQueryVariables>(GetImageBannerByProfileIdDocument, options);
        }
export type GetImageBannerByProfileIdQueryHookResult = ReturnType<typeof useGetImageBannerByProfileIdQuery>;
export type GetImageBannerByProfileIdLazyQueryHookResult = ReturnType<typeof useGetImageBannerByProfileIdLazyQuery>;
export type GetImageBannerByProfileIdQueryResult = Apollo.QueryResult<Types.GetImageBannerByProfileIdQuery, Types.GetImageBannerByProfileIdQueryVariables>;
export const UpdateProfileDetailsDocument = gql`
    mutation updateProfileDetails($profile_id: bigint!, $quote: String = "", $statement: String = "") {
  update_Profile_by_pk(
    pk_columns: {id: $profile_id}
    _set: {quote: $quote, statement: $statement}
  ) {
    id
  }
}
    `;
export type UpdateProfileDetailsMutationFn = Apollo.MutationFunction<Types.UpdateProfileDetailsMutation, Types.UpdateProfileDetailsMutationVariables>;

/**
 * __useUpdateProfileDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateProfileDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileDetailsMutation, { data, loading, error }] = useUpdateProfileDetailsMutation({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *      quote: // value for 'quote'
 *      statement: // value for 'statement'
 *   },
 * });
 */
export function useUpdateProfileDetailsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileDetailsMutation, Types.UpdateProfileDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileDetailsMutation, Types.UpdateProfileDetailsMutationVariables>(UpdateProfileDetailsDocument, options);
      }
export type UpdateProfileDetailsMutationHookResult = ReturnType<typeof useUpdateProfileDetailsMutation>;
export type UpdateProfileDetailsMutationResult = Apollo.MutationResult<Types.UpdateProfileDetailsMutation>;
export type UpdateProfileDetailsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileDetailsMutation, Types.UpdateProfileDetailsMutationVariables>;
export const UpdateProfileLocationDocument = gql`
    mutation updateProfileLocation($contextId: bigint!, $objects: [Profile_location_insert_input!]!) {
  delete_Profile_location(where: {context_id: {_eq: $contextId}}) {
    affected_rows
  }
  insert_Profile_location(objects: $objects) {
    affected_rows
    returning {
      address
      city
      country
      id
      name
      zip_code
      region
      city_id
      WorldCity {
        admin_name
        admin_name_ascii
        admin_code
      }
    }
  }
}
    `;
export type UpdateProfileLocationMutationFn = Apollo.MutationFunction<Types.UpdateProfileLocationMutation, Types.UpdateProfileLocationMutationVariables>;

/**
 * __useUpdateProfileLocationMutation__
 *
 * To run a mutation, you first call `useUpdateProfileLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileLocationMutation, { data, loading, error }] = useUpdateProfileLocationMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateProfileLocationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileLocationMutation, Types.UpdateProfileLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileLocationMutation, Types.UpdateProfileLocationMutationVariables>(UpdateProfileLocationDocument, options);
      }
export type UpdateProfileLocationMutationHookResult = ReturnType<typeof useUpdateProfileLocationMutation>;
export type UpdateProfileLocationMutationResult = Apollo.MutationResult<Types.UpdateProfileLocationMutation>;
export type UpdateProfileLocationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileLocationMutation, Types.UpdateProfileLocationMutationVariables>;
export const GetVideoUrlDocument = gql`
    query getVideoUrl($contextId: bigint!) {
  Profile_video_links(where: {context_id: {_eq: $contextId}}) {
    id
    platform
    URL
  }
}
    `;

/**
 * __useGetVideoUrlQuery__
 *
 * To run a query within a React component, call `useGetVideoUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoUrlQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetVideoUrlQuery(baseOptions: Apollo.QueryHookOptions<Types.GetVideoUrlQuery, Types.GetVideoUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetVideoUrlQuery, Types.GetVideoUrlQueryVariables>(GetVideoUrlDocument, options);
      }
export function useGetVideoUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetVideoUrlQuery, Types.GetVideoUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetVideoUrlQuery, Types.GetVideoUrlQueryVariables>(GetVideoUrlDocument, options);
        }
export type GetVideoUrlQueryHookResult = ReturnType<typeof useGetVideoUrlQuery>;
export type GetVideoUrlLazyQueryHookResult = ReturnType<typeof useGetVideoUrlLazyQuery>;
export type GetVideoUrlQueryResult = Apollo.QueryResult<Types.GetVideoUrlQuery, Types.GetVideoUrlQueryVariables>;
export const UpdateVideoUrlDocument = gql`
    mutation updateVideoUrl($contextId: bigint!, $objects: [Profile_video_links_insert_input!]!) {
  update_Profile_video_links(
    where: {context_id: {_eq: $contextId}, deleted_at: {_is_null: true}}
    _set: {deleted_at: "now()"}
  ) {
    affected_rows
  }
  insert_Profile_video_links(objects: $objects) {
    returning {
      id
      platform
      URL
    }
  }
}
    `;
export type UpdateVideoUrlMutationFn = Apollo.MutationFunction<Types.UpdateVideoUrlMutation, Types.UpdateVideoUrlMutationVariables>;

/**
 * __useUpdateVideoUrlMutation__
 *
 * To run a mutation, you first call `useUpdateVideoUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoUrlMutation, { data, loading, error }] = useUpdateVideoUrlMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateVideoUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateVideoUrlMutation, Types.UpdateVideoUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateVideoUrlMutation, Types.UpdateVideoUrlMutationVariables>(UpdateVideoUrlDocument, options);
      }
export type UpdateVideoUrlMutationHookResult = ReturnType<typeof useUpdateVideoUrlMutation>;
export type UpdateVideoUrlMutationResult = Apollo.MutationResult<Types.UpdateVideoUrlMutation>;
export type UpdateVideoUrlMutationOptions = Apollo.BaseMutationOptions<Types.UpdateVideoUrlMutation, Types.UpdateVideoUrlMutationVariables>;
export const DeleteVideoUrlDocument = gql`
    mutation deleteVideoUrl($contextId: bigint!) {
  update_Profile_video_links(
    where: {context_id: {_eq: $contextId}, deleted_at: {_is_null: true}}
    _set: {deleted_at: "now()"}
  ) {
    affected_rows
    returning {
      id
      platform
      URL
    }
  }
}
    `;
export type DeleteVideoUrlMutationFn = Apollo.MutationFunction<Types.DeleteVideoUrlMutation, Types.DeleteVideoUrlMutationVariables>;

/**
 * __useDeleteVideoUrlMutation__
 *
 * To run a mutation, you first call `useDeleteVideoUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoUrlMutation, { data, loading, error }] = useDeleteVideoUrlMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useDeleteVideoUrlMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteVideoUrlMutation, Types.DeleteVideoUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteVideoUrlMutation, Types.DeleteVideoUrlMutationVariables>(DeleteVideoUrlDocument, options);
      }
export type DeleteVideoUrlMutationHookResult = ReturnType<typeof useDeleteVideoUrlMutation>;
export type DeleteVideoUrlMutationResult = Apollo.MutationResult<Types.DeleteVideoUrlMutation>;
export type DeleteVideoUrlMutationOptions = Apollo.BaseMutationOptions<Types.DeleteVideoUrlMutation, Types.DeleteVideoUrlMutationVariables>;
export const GetAudioDocument = gql`
    query getAudio($id: bigint!) {
  Profile_by_pk(id: $id) {
    audio_download_link
    Valid_profile_statement_audio_syntheses {
      audio_id
      profile_id
      language
      text
    }
  }
}
    `;

/**
 * __useGetAudioQuery__
 *
 * To run a query within a React component, call `useGetAudioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAudioQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAudioQuery, Types.GetAudioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAudioQuery, Types.GetAudioQueryVariables>(GetAudioDocument, options);
      }
export function useGetAudioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAudioQuery, Types.GetAudioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAudioQuery, Types.GetAudioQueryVariables>(GetAudioDocument, options);
        }
export type GetAudioQueryHookResult = ReturnType<typeof useGetAudioQuery>;
export type GetAudioLazyQueryHookResult = ReturnType<typeof useGetAudioLazyQuery>;
export type GetAudioQueryResult = Apollo.QueryResult<Types.GetAudioQuery, Types.GetAudioQueryVariables>;
export const DeleteProfileAudioDocument = gql`
    mutation deleteProfileAudio($profileId: bigint!) {
  profileAudioDelete(profile_id: $profileId)
}
    `;
export type DeleteProfileAudioMutationFn = Apollo.MutationFunction<Types.DeleteProfileAudioMutation, Types.DeleteProfileAudioMutationVariables>;

/**
 * __useDeleteProfileAudioMutation__
 *
 * To run a mutation, you first call `useDeleteProfileAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileAudioMutation, { data, loading, error }] = useDeleteProfileAudioMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useDeleteProfileAudioMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProfileAudioMutation, Types.DeleteProfileAudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProfileAudioMutation, Types.DeleteProfileAudioMutationVariables>(DeleteProfileAudioDocument, options);
      }
export type DeleteProfileAudioMutationHookResult = ReturnType<typeof useDeleteProfileAudioMutation>;
export type DeleteProfileAudioMutationResult = Apollo.MutationResult<Types.DeleteProfileAudioMutation>;
export type DeleteProfileAudioMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProfileAudioMutation, Types.DeleteProfileAudioMutationVariables>;
export const UpdateProfileThemeDocument = gql`
    mutation updateProfileTheme($id: bigint!, $profile_theme: ENUM_profile_theme_enum = LIGHT) {
  update_Profile_by_pk(
    pk_columns: {id: $id}
    _set: {profile_theme: $profile_theme}
  ) {
    profile_theme
  }
}
    `;
export type UpdateProfileThemeMutationFn = Apollo.MutationFunction<Types.UpdateProfileThemeMutation, Types.UpdateProfileThemeMutationVariables>;

/**
 * __useUpdateProfileThemeMutation__
 *
 * To run a mutation, you first call `useUpdateProfileThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileThemeMutation, { data, loading, error }] = useUpdateProfileThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      profile_theme: // value for 'profile_theme'
 *   },
 * });
 */
export function useUpdateProfileThemeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileThemeMutation, Types.UpdateProfileThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileThemeMutation, Types.UpdateProfileThemeMutationVariables>(UpdateProfileThemeDocument, options);
      }
export type UpdateProfileThemeMutationHookResult = ReturnType<typeof useUpdateProfileThemeMutation>;
export type UpdateProfileThemeMutationResult = Apollo.MutationResult<Types.UpdateProfileThemeMutation>;
export type UpdateProfileThemeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileThemeMutation, Types.UpdateProfileThemeMutationVariables>;
export const InsertProfilePhoneNumberDocument = gql`
    mutation insertProfilePhoneNumber($context_id: Int!, $phone: String!) {
  insertProfilePhoneNumber(context_id: $context_id, phone: $phone)
}
    `;
export type InsertProfilePhoneNumberMutationFn = Apollo.MutationFunction<Types.InsertProfilePhoneNumberMutation, Types.InsertProfilePhoneNumberMutationVariables>;

/**
 * __useInsertProfilePhoneNumberMutation__
 *
 * To run a mutation, you first call `useInsertProfilePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProfilePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProfilePhoneNumberMutation, { data, loading, error }] = useInsertProfilePhoneNumberMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useInsertProfilePhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertProfilePhoneNumberMutation, Types.InsertProfilePhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertProfilePhoneNumberMutation, Types.InsertProfilePhoneNumberMutationVariables>(InsertProfilePhoneNumberDocument, options);
      }
export type InsertProfilePhoneNumberMutationHookResult = ReturnType<typeof useInsertProfilePhoneNumberMutation>;
export type InsertProfilePhoneNumberMutationResult = Apollo.MutationResult<Types.InsertProfilePhoneNumberMutation>;
export type InsertProfilePhoneNumberMutationOptions = Apollo.BaseMutationOptions<Types.InsertProfilePhoneNumberMutation, Types.InsertProfilePhoneNumberMutationVariables>;
export const DeleteProfilePhoneNumberDocument = gql`
    mutation deleteProfilePhoneNumber($context_id: Int!, $phone: String!) {
  deleteProfilePhoneNumber(context_id: $context_id, phone: $phone)
}
    `;
export type DeleteProfilePhoneNumberMutationFn = Apollo.MutationFunction<Types.DeleteProfilePhoneNumberMutation, Types.DeleteProfilePhoneNumberMutationVariables>;

/**
 * __useDeleteProfilePhoneNumberMutation__
 *
 * To run a mutation, you first call `useDeleteProfilePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfilePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfilePhoneNumberMutation, { data, loading, error }] = useDeleteProfilePhoneNumberMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useDeleteProfilePhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteProfilePhoneNumberMutation, Types.DeleteProfilePhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteProfilePhoneNumberMutation, Types.DeleteProfilePhoneNumberMutationVariables>(DeleteProfilePhoneNumberDocument, options);
      }
export type DeleteProfilePhoneNumberMutationHookResult = ReturnType<typeof useDeleteProfilePhoneNumberMutation>;
export type DeleteProfilePhoneNumberMutationResult = Apollo.MutationResult<Types.DeleteProfilePhoneNumberMutation>;
export type DeleteProfilePhoneNumberMutationOptions = Apollo.BaseMutationOptions<Types.DeleteProfilePhoneNumberMutation, Types.DeleteProfilePhoneNumberMutationVariables>;
export const UpdateProfileInformationDocument = gql`
    mutation updateProfileInformation($profile_id: bigint!, $data: Profile_set_input!) {
  update_Profile_by_pk(pk_columns: {id: $profile_id}, _set: $data) {
    id
  }
}
    `;
export type UpdateProfileInformationMutationFn = Apollo.MutationFunction<Types.UpdateProfileInformationMutation, Types.UpdateProfileInformationMutationVariables>;

/**
 * __useUpdateProfileInformationMutation__
 *
 * To run a mutation, you first call `useUpdateProfileInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileInformationMutation, { data, loading, error }] = useUpdateProfileInformationMutation({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProfileInformationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileInformationMutation, Types.UpdateProfileInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileInformationMutation, Types.UpdateProfileInformationMutationVariables>(UpdateProfileInformationDocument, options);
      }
export type UpdateProfileInformationMutationHookResult = ReturnType<typeof useUpdateProfileInformationMutation>;
export type UpdateProfileInformationMutationResult = Apollo.MutationResult<Types.UpdateProfileInformationMutation>;
export type UpdateProfileInformationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileInformationMutation, Types.UpdateProfileInformationMutationVariables>;
export const GetTransferrableArtworkOrPrintDocument = gql`
    query GetTransferrableArtworkOrPrint($artwork_id: bigint!, $profile_id: bigint!) {
  Print(
    where: {id: {_eq: $artwork_id}, print_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $profile_id}}}}
  ) {
    id
    isTransferLocked: is_transaction_locked
  }
  Artwork(
    where: {id: {_eq: $artwork_id}, artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $profile_id}}}, published_date: {_is_null: false}}
  ) {
    id
    isTransferLocked: is_transaction_locked
  }
}
    `;

/**
 * __useGetTransferrableArtworkOrPrintQuery__
 *
 * To run a query within a React component, call `useGetTransferrableArtworkOrPrintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferrableArtworkOrPrintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferrableArtworkOrPrintQuery({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      profile_id: // value for 'profile_id'
 *   },
 * });
 */
export function useGetTransferrableArtworkOrPrintQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTransferrableArtworkOrPrintQuery, Types.GetTransferrableArtworkOrPrintQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransferrableArtworkOrPrintQuery, Types.GetTransferrableArtworkOrPrintQueryVariables>(GetTransferrableArtworkOrPrintDocument, options);
      }
export function useGetTransferrableArtworkOrPrintLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferrableArtworkOrPrintQuery, Types.GetTransferrableArtworkOrPrintQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransferrableArtworkOrPrintQuery, Types.GetTransferrableArtworkOrPrintQueryVariables>(GetTransferrableArtworkOrPrintDocument, options);
        }
export type GetTransferrableArtworkOrPrintQueryHookResult = ReturnType<typeof useGetTransferrableArtworkOrPrintQuery>;
export type GetTransferrableArtworkOrPrintLazyQueryHookResult = ReturnType<typeof useGetTransferrableArtworkOrPrintLazyQuery>;
export type GetTransferrableArtworkOrPrintQueryResult = Apollo.QueryResult<Types.GetTransferrableArtworkOrPrintQuery, Types.GetTransferrableArtworkOrPrintQueryVariables>;
export const GetTransferrableArtworkOrPrintAsAGalleryDocument = gql`
    query GetTransferrableArtworkOrPrintAsAGallery($gallery_context_id: bigint!, $artwork_id: bigint!) {
  Artwork(
    where: {gallery_context_id: {_eq: $gallery_context_id}, id: {_eq: $artwork_id}}
  ) {
    isTransferLocked: is_transaction_locked
    id
  }
  Print(
    where: {id: {_eq: $artwork_id}, Artwork: {gallery_context_id: {_eq: $gallery_context_id}}}
  ) {
    isTransferLocked: is_transaction_locked
    id
  }
}
    `;

/**
 * __useGetTransferrableArtworkOrPrintAsAGalleryQuery__
 *
 * To run a query within a React component, call `useGetTransferrableArtworkOrPrintAsAGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferrableArtworkOrPrintAsAGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferrableArtworkOrPrintAsAGalleryQuery({
 *   variables: {
 *      gallery_context_id: // value for 'gallery_context_id'
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useGetTransferrableArtworkOrPrintAsAGalleryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTransferrableArtworkOrPrintAsAGalleryQuery, Types.GetTransferrableArtworkOrPrintAsAGalleryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransferrableArtworkOrPrintAsAGalleryQuery, Types.GetTransferrableArtworkOrPrintAsAGalleryQueryVariables>(GetTransferrableArtworkOrPrintAsAGalleryDocument, options);
      }
export function useGetTransferrableArtworkOrPrintAsAGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferrableArtworkOrPrintAsAGalleryQuery, Types.GetTransferrableArtworkOrPrintAsAGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransferrableArtworkOrPrintAsAGalleryQuery, Types.GetTransferrableArtworkOrPrintAsAGalleryQueryVariables>(GetTransferrableArtworkOrPrintAsAGalleryDocument, options);
        }
export type GetTransferrableArtworkOrPrintAsAGalleryQueryHookResult = ReturnType<typeof useGetTransferrableArtworkOrPrintAsAGalleryQuery>;
export type GetTransferrableArtworkOrPrintAsAGalleryLazyQueryHookResult = ReturnType<typeof useGetTransferrableArtworkOrPrintAsAGalleryLazyQuery>;
export type GetTransferrableArtworkOrPrintAsAGalleryQueryResult = Apollo.QueryResult<Types.GetTransferrableArtworkOrPrintAsAGalleryQuery, Types.GetTransferrableArtworkOrPrintAsAGalleryQueryVariables>;
export const MaterializePrintDocument = gql`
    mutation MaterializePrint($printNumber: Int!, $printEditionByTypeId: bigint!) {
  materializePrint(
    print_number: $printNumber
    edition_by_type_id: $printEditionByTypeId
  ) {
    id
    print_number
    edition_by_type_id
  }
}
    `;
export type MaterializePrintMutationFn = Apollo.MutationFunction<Types.MaterializePrintMutation, Types.MaterializePrintMutationVariables>;

/**
 * __useMaterializePrintMutation__
 *
 * To run a mutation, you first call `useMaterializePrintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMaterializePrintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [materializePrintMutation, { data, loading, error }] = useMaterializePrintMutation({
 *   variables: {
 *      printNumber: // value for 'printNumber'
 *      printEditionByTypeId: // value for 'printEditionByTypeId'
 *   },
 * });
 */
export function useMaterializePrintMutation(baseOptions?: Apollo.MutationHookOptions<Types.MaterializePrintMutation, Types.MaterializePrintMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MaterializePrintMutation, Types.MaterializePrintMutationVariables>(MaterializePrintDocument, options);
      }
export type MaterializePrintMutationHookResult = ReturnType<typeof useMaterializePrintMutation>;
export type MaterializePrintMutationResult = Apollo.MutationResult<Types.MaterializePrintMutation>;
export type MaterializePrintMutationOptions = Apollo.BaseMutationOptions<Types.MaterializePrintMutation, Types.MaterializePrintMutationVariables>;
export const CheckIfCertificateExistsDocument = gql`
    query CheckIfCertificateExists($artpieceId: bigint!) {
  checkCoAFile(artPieceId: $artpieceId) {
    exists
    lastUpdate
  }
}
    `;

/**
 * __useCheckIfCertificateExistsQuery__
 *
 * To run a query within a React component, call `useCheckIfCertificateExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfCertificateExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfCertificateExistsQuery({
 *   variables: {
 *      artpieceId: // value for 'artpieceId'
 *   },
 * });
 */
export function useCheckIfCertificateExistsQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckIfCertificateExistsQuery, Types.CheckIfCertificateExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckIfCertificateExistsQuery, Types.CheckIfCertificateExistsQueryVariables>(CheckIfCertificateExistsDocument, options);
      }
export function useCheckIfCertificateExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckIfCertificateExistsQuery, Types.CheckIfCertificateExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckIfCertificateExistsQuery, Types.CheckIfCertificateExistsQueryVariables>(CheckIfCertificateExistsDocument, options);
        }
export type CheckIfCertificateExistsQueryHookResult = ReturnType<typeof useCheckIfCertificateExistsQuery>;
export type CheckIfCertificateExistsLazyQueryHookResult = ReturnType<typeof useCheckIfCertificateExistsLazyQuery>;
export type CheckIfCertificateExistsQueryResult = Apollo.QueryResult<Types.CheckIfCertificateExistsQuery, Types.CheckIfCertificateExistsQueryVariables>;
export const CheckIfArtworkCertificateExistsDocument = gql`
    query CheckIfArtworkCertificateExists($artworkId: bigint!) {
  Certificate_of_authenticity(where: {Artwork: {id: {_eq: $artworkId}}}) {
    fingerprint
  }
}
    `;

/**
 * __useCheckIfArtworkCertificateExistsQuery__
 *
 * To run a query within a React component, call `useCheckIfArtworkCertificateExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfArtworkCertificateExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfArtworkCertificateExistsQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useCheckIfArtworkCertificateExistsQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckIfArtworkCertificateExistsQuery, Types.CheckIfArtworkCertificateExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckIfArtworkCertificateExistsQuery, Types.CheckIfArtworkCertificateExistsQueryVariables>(CheckIfArtworkCertificateExistsDocument, options);
      }
export function useCheckIfArtworkCertificateExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckIfArtworkCertificateExistsQuery, Types.CheckIfArtworkCertificateExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckIfArtworkCertificateExistsQuery, Types.CheckIfArtworkCertificateExistsQueryVariables>(CheckIfArtworkCertificateExistsDocument, options);
        }
export type CheckIfArtworkCertificateExistsQueryHookResult = ReturnType<typeof useCheckIfArtworkCertificateExistsQuery>;
export type CheckIfArtworkCertificateExistsLazyQueryHookResult = ReturnType<typeof useCheckIfArtworkCertificateExistsLazyQuery>;
export type CheckIfArtworkCertificateExistsQueryResult = Apollo.QueryResult<Types.CheckIfArtworkCertificateExistsQuery, Types.CheckIfArtworkCertificateExistsQueryVariables>;
export const GetAllArtistCollectionsDocument = gql`
    query GetAllArtistCollections($contextId: bigint!) {
  Collection(where: {owner_context_id: {_eq: $contextId}, type: {_eq: SERIES}}) {
    id
    name
    artwork_to_collections {
      id
    }
  }
}
    `;

/**
 * __useGetAllArtistCollectionsQuery__
 *
 * To run a query within a React component, call `useGetAllArtistCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllArtistCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllArtistCollectionsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetAllArtistCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllArtistCollectionsQuery, Types.GetAllArtistCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllArtistCollectionsQuery, Types.GetAllArtistCollectionsQueryVariables>(GetAllArtistCollectionsDocument, options);
      }
export function useGetAllArtistCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllArtistCollectionsQuery, Types.GetAllArtistCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllArtistCollectionsQuery, Types.GetAllArtistCollectionsQueryVariables>(GetAllArtistCollectionsDocument, options);
        }
export type GetAllArtistCollectionsQueryHookResult = ReturnType<typeof useGetAllArtistCollectionsQuery>;
export type GetAllArtistCollectionsLazyQueryHookResult = ReturnType<typeof useGetAllArtistCollectionsLazyQuery>;
export type GetAllArtistCollectionsQueryResult = Apollo.QueryResult<Types.GetAllArtistCollectionsQuery, Types.GetAllArtistCollectionsQueryVariables>;
export const DeleteSeriesDocument = gql`
    mutation deleteSeries($collection_id: Int!) {
  deleteSeries(collection_id: $collection_id)
}
    `;
export type DeleteSeriesMutationFn = Apollo.MutationFunction<Types.DeleteSeriesMutation, Types.DeleteSeriesMutationVariables>;

/**
 * __useDeleteSeriesMutation__
 *
 * To run a mutation, you first call `useDeleteSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeriesMutation, { data, loading, error }] = useDeleteSeriesMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *   },
 * });
 */
export function useDeleteSeriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSeriesMutation, Types.DeleteSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSeriesMutation, Types.DeleteSeriesMutationVariables>(DeleteSeriesDocument, options);
      }
export type DeleteSeriesMutationHookResult = ReturnType<typeof useDeleteSeriesMutation>;
export type DeleteSeriesMutationResult = Apollo.MutationResult<Types.DeleteSeriesMutation>;
export type DeleteSeriesMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSeriesMutation, Types.DeleteSeriesMutationVariables>;
export const GetCollectionByIdDocument = gql`
    query GetCollectionById($id: bigint!) {
  Collection_by_pk(id: $id) {
    id
    name
    description
    collection_audio_download_link
    audio_metadata {
      extra
    }
    collection_video_links(where: {deleted_at: {_is_null: true}}) {
      URL
      platform
      id
    }
  }
}
    `;

/**
 * __useGetCollectionByIdQuery__
 *
 * To run a query within a React component, call `useGetCollectionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectionByIdQuery, Types.GetCollectionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectionByIdQuery, Types.GetCollectionByIdQueryVariables>(GetCollectionByIdDocument, options);
      }
export function useGetCollectionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectionByIdQuery, Types.GetCollectionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectionByIdQuery, Types.GetCollectionByIdQueryVariables>(GetCollectionByIdDocument, options);
        }
export type GetCollectionByIdQueryHookResult = ReturnType<typeof useGetCollectionByIdQuery>;
export type GetCollectionByIdLazyQueryHookResult = ReturnType<typeof useGetCollectionByIdLazyQuery>;
export type GetCollectionByIdQueryResult = Apollo.QueryResult<Types.GetCollectionByIdQuery, Types.GetCollectionByIdQueryVariables>;
export const DeleteSeriesAudioDocument = gql`
    mutation deleteSeriesAudio($seriesId: bigint!) {
  collectionAudioDelete(collection_id: $seriesId)
}
    `;
export type DeleteSeriesAudioMutationFn = Apollo.MutationFunction<Types.DeleteSeriesAudioMutation, Types.DeleteSeriesAudioMutationVariables>;

/**
 * __useDeleteSeriesAudioMutation__
 *
 * To run a mutation, you first call `useDeleteSeriesAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSeriesAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSeriesAudioMutation, { data, loading, error }] = useDeleteSeriesAudioMutation({
 *   variables: {
 *      seriesId: // value for 'seriesId'
 *   },
 * });
 */
export function useDeleteSeriesAudioMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSeriesAudioMutation, Types.DeleteSeriesAudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSeriesAudioMutation, Types.DeleteSeriesAudioMutationVariables>(DeleteSeriesAudioDocument, options);
      }
export type DeleteSeriesAudioMutationHookResult = ReturnType<typeof useDeleteSeriesAudioMutation>;
export type DeleteSeriesAudioMutationResult = Apollo.MutationResult<Types.DeleteSeriesAudioMutation>;
export type DeleteSeriesAudioMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSeriesAudioMutation, Types.DeleteSeriesAudioMutationVariables>;
export const DismissExploreMilestoneMessageDocument = gql`
    mutation dismissExploreMilestoneMessage($contextId: bigint!, $appearReason: String!) {
  result: update_Artist_explore_visibility(
    _set: {has_dismissed_message: true}
    where: {artist_context_id: {_eq: $contextId}, appear_reason: {_eq: $appearReason}}
  ) {
    affectedRows: affected_rows
  }
}
    `;
export type DismissExploreMilestoneMessageMutationFn = Apollo.MutationFunction<Types.DismissExploreMilestoneMessageMutation, Types.DismissExploreMilestoneMessageMutationVariables>;

/**
 * __useDismissExploreMilestoneMessageMutation__
 *
 * To run a mutation, you first call `useDismissExploreMilestoneMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissExploreMilestoneMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissExploreMilestoneMessageMutation, { data, loading, error }] = useDismissExploreMilestoneMessageMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      appearReason: // value for 'appearReason'
 *   },
 * });
 */
export function useDismissExploreMilestoneMessageMutation(baseOptions?: Apollo.MutationHookOptions<Types.DismissExploreMilestoneMessageMutation, Types.DismissExploreMilestoneMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DismissExploreMilestoneMessageMutation, Types.DismissExploreMilestoneMessageMutationVariables>(DismissExploreMilestoneMessageDocument, options);
      }
export type DismissExploreMilestoneMessageMutationHookResult = ReturnType<typeof useDismissExploreMilestoneMessageMutation>;
export type DismissExploreMilestoneMessageMutationResult = Apollo.MutationResult<Types.DismissExploreMilestoneMessageMutation>;
export type DismissExploreMilestoneMessageMutationOptions = Apollo.BaseMutationOptions<Types.DismissExploreMilestoneMessageMutation, Types.DismissExploreMilestoneMessageMutationVariables>;
export const GetBillingByArtistContextIdDocument = gql`
    query getBillingByArtistContextId($contextId: bigint!, $subscriptionProductTypes: [ENUM_billing_subscription_product_type_enum!] = []) {
  Billing_subscription_product(
    where: {context_id: {_eq: $contextId}, type: {_in: $subscriptionProductTypes}, last_billing_subscription: {billing_subscription_status: {_nin: [SUSPENDED, CANCELED]}}}
  ) {
    id
    type
    last_billing_subscription {
      id
      billing_subscription_status
      last_failed_payment_at
      last_failed_payment_error_code
      last_failed_payment_decline_code
      billing_subscription_period
      next_billing_date
      stripe_subscription {
        id
        nextInvoiceDate
        products {
          type
          price {
            amount
            currency
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetBillingByArtistContextIdQuery__
 *
 * To run a query within a React component, call `useGetBillingByArtistContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingByArtistContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingByArtistContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      subscriptionProductTypes: // value for 'subscriptionProductTypes'
 *   },
 * });
 */
export function useGetBillingByArtistContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBillingByArtistContextIdQuery, Types.GetBillingByArtistContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBillingByArtistContextIdQuery, Types.GetBillingByArtistContextIdQueryVariables>(GetBillingByArtistContextIdDocument, options);
      }
export function useGetBillingByArtistContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBillingByArtistContextIdQuery, Types.GetBillingByArtistContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBillingByArtistContextIdQuery, Types.GetBillingByArtistContextIdQueryVariables>(GetBillingByArtistContextIdDocument, options);
        }
export type GetBillingByArtistContextIdQueryHookResult = ReturnType<typeof useGetBillingByArtistContextIdQuery>;
export type GetBillingByArtistContextIdLazyQueryHookResult = ReturnType<typeof useGetBillingByArtistContextIdLazyQuery>;
export type GetBillingByArtistContextIdQueryResult = Apollo.QueryResult<Types.GetBillingByArtistContextIdQuery, Types.GetBillingByArtistContextIdQueryVariables>;
export const GetDomainByArtistContextIdDocument = gql`
    query getDomainByArtistContextId($contextId: bigint!) {
  Connected_domain(
    where: {context_id: {_eq: $contextId}, deleted_at: {_is_null: true}}
  ) {
    id
    domain_name
    state
    created_at
    tld
    context_id
    ssl_generated
    context {
      profile {
        last_name
        first_name
        email
        handle
      }
    }
  }
  Domain_registration(
    where: {context_id: {_eq: $contextId}, status: {_neq: FAILED_TO_REGISTER}}
  ) {
    domain_name
    status
    connected_domain_id
  }
}
    `;

/**
 * __useGetDomainByArtistContextIdQuery__
 *
 * To run a query within a React component, call `useGetDomainByArtistContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainByArtistContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainByArtistContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetDomainByArtistContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDomainByArtistContextIdQuery, Types.GetDomainByArtistContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDomainByArtistContextIdQuery, Types.GetDomainByArtistContextIdQueryVariables>(GetDomainByArtistContextIdDocument, options);
      }
export function useGetDomainByArtistContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDomainByArtistContextIdQuery, Types.GetDomainByArtistContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDomainByArtistContextIdQuery, Types.GetDomainByArtistContextIdQueryVariables>(GetDomainByArtistContextIdDocument, options);
        }
export type GetDomainByArtistContextIdQueryHookResult = ReturnType<typeof useGetDomainByArtistContextIdQuery>;
export type GetDomainByArtistContextIdLazyQueryHookResult = ReturnType<typeof useGetDomainByArtistContextIdLazyQuery>;
export type GetDomainByArtistContextIdQueryResult = Apollo.QueryResult<Types.GetDomainByArtistContextIdQuery, Types.GetDomainByArtistContextIdQueryVariables>;
export const GetBillingPortalUrlDocument = gql`
    query getBillingPortalUrl($portalRedirectUrl: String!) {
  getBillingAccountDetails(
    portalRedirectUrl: $portalRedirectUrl
    subscriptionProductTypes: []
  ) {
    portalUrl
  }
}
    `;

/**
 * __useGetBillingPortalUrlQuery__
 *
 * To run a query within a React component, call `useGetBillingPortalUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingPortalUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingPortalUrlQuery({
 *   variables: {
 *      portalRedirectUrl: // value for 'portalRedirectUrl'
 *   },
 * });
 */
export function useGetBillingPortalUrlQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBillingPortalUrlQuery, Types.GetBillingPortalUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBillingPortalUrlQuery, Types.GetBillingPortalUrlQueryVariables>(GetBillingPortalUrlDocument, options);
      }
export function useGetBillingPortalUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBillingPortalUrlQuery, Types.GetBillingPortalUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBillingPortalUrlQuery, Types.GetBillingPortalUrlQueryVariables>(GetBillingPortalUrlDocument, options);
        }
export type GetBillingPortalUrlQueryHookResult = ReturnType<typeof useGetBillingPortalUrlQuery>;
export type GetBillingPortalUrlLazyQueryHookResult = ReturnType<typeof useGetBillingPortalUrlLazyQuery>;
export type GetBillingPortalUrlQueryResult = Apollo.QueryResult<Types.GetBillingPortalUrlQuery, Types.GetBillingPortalUrlQueryVariables>;
export const GetEmailDocument = gql`
    query getEmail($access_context_id: bigint!) {
  Context_by_pk(id: $access_context_id) {
    auth_context_accesses {
      auth {
        firebase_profiles {
          email
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmailQuery__
 *
 * To run a query within a React component, call `useGetEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailQuery({
 *   variables: {
 *      access_context_id: // value for 'access_context_id'
 *   },
 * });
 */
export function useGetEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEmailQuery, Types.GetEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmailQuery, Types.GetEmailQueryVariables>(GetEmailDocument, options);
      }
export function useGetEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmailQuery, Types.GetEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmailQuery, Types.GetEmailQueryVariables>(GetEmailDocument, options);
        }
export type GetEmailQueryHookResult = ReturnType<typeof useGetEmailQuery>;
export type GetEmailLazyQueryHookResult = ReturnType<typeof useGetEmailLazyQuery>;
export type GetEmailQueryResult = Apollo.QueryResult<Types.GetEmailQuery, Types.GetEmailQueryVariables>;
export const GetAllCollectorArtworksDocument = gql`
    query GetAllCollectorArtworks($collectorContextId: bigint!) {
  Artwork(
    where: {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $collectorContextId}}}}
  ) {
    id
  }
  Print(
    where: {print_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $collectorContextId}}}}
  ) {
    id
  }
}
    `;

/**
 * __useGetAllCollectorArtworksQuery__
 *
 * To run a query within a React component, call `useGetAllCollectorArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCollectorArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCollectorArtworksQuery({
 *   variables: {
 *      collectorContextId: // value for 'collectorContextId'
 *   },
 * });
 */
export function useGetAllCollectorArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllCollectorArtworksQuery, Types.GetAllCollectorArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllCollectorArtworksQuery, Types.GetAllCollectorArtworksQueryVariables>(GetAllCollectorArtworksDocument, options);
      }
export function useGetAllCollectorArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllCollectorArtworksQuery, Types.GetAllCollectorArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllCollectorArtworksQuery, Types.GetAllCollectorArtworksQueryVariables>(GetAllCollectorArtworksDocument, options);
        }
export type GetAllCollectorArtworksQueryHookResult = ReturnType<typeof useGetAllCollectorArtworksQuery>;
export type GetAllCollectorArtworksLazyQueryHookResult = ReturnType<typeof useGetAllCollectorArtworksLazyQuery>;
export type GetAllCollectorArtworksQueryResult = Apollo.QueryResult<Types.GetAllCollectorArtworksQuery, Types.GetAllCollectorArtworksQueryVariables>;
export const GetCollectorEmailDocument = gql`
    query GetCollectorEmail($context_id: bigint) {
  All_collectors(where: {context_id: {_eq: $context_id}}) {
    email
  }
}
    `;

/**
 * __useGetCollectorEmailQuery__
 *
 * To run a query within a React component, call `useGetCollectorEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorEmailQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetCollectorEmailQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCollectorEmailQuery, Types.GetCollectorEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorEmailQuery, Types.GetCollectorEmailQueryVariables>(GetCollectorEmailDocument, options);
      }
export function useGetCollectorEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorEmailQuery, Types.GetCollectorEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorEmailQuery, Types.GetCollectorEmailQueryVariables>(GetCollectorEmailDocument, options);
        }
export type GetCollectorEmailQueryHookResult = ReturnType<typeof useGetCollectorEmailQuery>;
export type GetCollectorEmailLazyQueryHookResult = ReturnType<typeof useGetCollectorEmailLazyQuery>;
export type GetCollectorEmailQueryResult = Apollo.QueryResult<Types.GetCollectorEmailQuery, Types.GetCollectorEmailQueryVariables>;
export const UpdateCollectorProfileDocument = gql`
    mutation UpdateCollectorProfile($id: bigint!, $handle: String = "", $is_public: ENUM_profile_public_status_enum = PUBLIC) {
  update_Profile_by_pk(
    pk_columns: {id: $id}
    _set: {handle: $handle, is_public: $is_public}
  ) {
    id
    handle
    is_public
  }
}
    `;
export type UpdateCollectorProfileMutationFn = Apollo.MutationFunction<Types.UpdateCollectorProfileMutation, Types.UpdateCollectorProfileMutationVariables>;

/**
 * __useUpdateCollectorProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCollectorProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectorProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectorProfileMutation, { data, loading, error }] = useUpdateCollectorProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      handle: // value for 'handle'
 *      is_public: // value for 'is_public'
 *   },
 * });
 */
export function useUpdateCollectorProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCollectorProfileMutation, Types.UpdateCollectorProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCollectorProfileMutation, Types.UpdateCollectorProfileMutationVariables>(UpdateCollectorProfileDocument, options);
      }
export type UpdateCollectorProfileMutationHookResult = ReturnType<typeof useUpdateCollectorProfileMutation>;
export type UpdateCollectorProfileMutationResult = Apollo.MutationResult<Types.UpdateCollectorProfileMutation>;
export type UpdateCollectorProfileMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCollectorProfileMutation, Types.UpdateCollectorProfileMutationVariables>;
export const GetGalleryInvitesDocument = gql`
    query getGalleryInvites($gallery_id: bigint!, $status: ENUM_collaboration_status_enum_comparison_exp) {
  Profile(
    where: {context: {collaboration_by_artist_id: {gallery_id: {_eq: $gallery_id}, artist_status: {_eq: ACCEPTED}, gallery_status: $status}}}
  ) {
    title
    email
    context {
      profile {
        id
        handle
        avatar
      }
      collaboration_by_artist_id(where: {gallery_id: {_eq: $gallery_id}}) {
        id
        gallery_status
      }
    }
  }
}
    `;

/**
 * __useGetGalleryInvitesQuery__
 *
 * To run a query within a React component, call `useGetGalleryInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryInvitesQuery({
 *   variables: {
 *      gallery_id: // value for 'gallery_id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetGalleryInvitesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryInvitesQuery, Types.GetGalleryInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryInvitesQuery, Types.GetGalleryInvitesQueryVariables>(GetGalleryInvitesDocument, options);
      }
export function useGetGalleryInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryInvitesQuery, Types.GetGalleryInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryInvitesQuery, Types.GetGalleryInvitesQueryVariables>(GetGalleryInvitesDocument, options);
        }
export type GetGalleryInvitesQueryHookResult = ReturnType<typeof useGetGalleryInvitesQuery>;
export type GetGalleryInvitesLazyQueryHookResult = ReturnType<typeof useGetGalleryInvitesLazyQuery>;
export type GetGalleryInvitesQueryResult = Apollo.QueryResult<Types.GetGalleryInvitesQuery, Types.GetGalleryInvitesQueryVariables>;
export const GetAllGalleryArtworksDocument = gql`
    query GetAllGalleryArtworks($galleryContextId: bigint!) {
  Artwork(where: {gallery_context_id: {_eq: $galleryContextId}}) {
    id
  }
  Collaboration(
    where: {gallery_id: {_eq: $galleryContextId}, artist_status: {_eq: ACCEPTED}, gallery_status: {_eq: ACCEPTED}}
  ) {
    id
  }
}
    `;

/**
 * __useGetAllGalleryArtworksQuery__
 *
 * To run a query within a React component, call `useGetAllGalleryArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGalleryArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGalleryArtworksQuery({
 *   variables: {
 *      galleryContextId: // value for 'galleryContextId'
 *   },
 * });
 */
export function useGetAllGalleryArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAllGalleryArtworksQuery, Types.GetAllGalleryArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllGalleryArtworksQuery, Types.GetAllGalleryArtworksQueryVariables>(GetAllGalleryArtworksDocument, options);
      }
export function useGetAllGalleryArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllGalleryArtworksQuery, Types.GetAllGalleryArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllGalleryArtworksQuery, Types.GetAllGalleryArtworksQueryVariables>(GetAllGalleryArtworksDocument, options);
        }
export type GetAllGalleryArtworksQueryHookResult = ReturnType<typeof useGetAllGalleryArtworksQuery>;
export type GetAllGalleryArtworksLazyQueryHookResult = ReturnType<typeof useGetAllGalleryArtworksLazyQuery>;
export type GetAllGalleryArtworksQueryResult = Apollo.QueryResult<Types.GetAllGalleryArtworksQuery, Types.GetAllGalleryArtworksQueryVariables>;
export const ResetTutorialDocument = gql`
    mutation ResetTutorial($current_step: String, $tutorial_id: Int) {
  resetContextTutorial(tutorial_id: $tutorial_id, current_step: $current_step)
}
    `;
export type ResetTutorialMutationFn = Apollo.MutationFunction<Types.ResetTutorialMutation, Types.ResetTutorialMutationVariables>;

/**
 * __useResetTutorialMutation__
 *
 * To run a mutation, you first call `useResetTutorialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTutorialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTutorialMutation, { data, loading, error }] = useResetTutorialMutation({
 *   variables: {
 *      current_step: // value for 'current_step'
 *      tutorial_id: // value for 'tutorial_id'
 *   },
 * });
 */
export function useResetTutorialMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResetTutorialMutation, Types.ResetTutorialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResetTutorialMutation, Types.ResetTutorialMutationVariables>(ResetTutorialDocument, options);
      }
export type ResetTutorialMutationHookResult = ReturnType<typeof useResetTutorialMutation>;
export type ResetTutorialMutationResult = Apollo.MutationResult<Types.ResetTutorialMutation>;
export type ResetTutorialMutationOptions = Apollo.BaseMutationOptions<Types.ResetTutorialMutation, Types.ResetTutorialMutationVariables>;
export const GetAccountInfoDocument = gql`
    query getAccountInfo($access_context_id: bigint!, $context_id: bigint!) {
  appAccessContext: Context_by_pk(id: $access_context_id) {
    auth_context_accesses {
      auth {
        preferred_language
        firebase_profiles {
          email
        }
      }
    }
  }
  selectedContext: Context_by_pk(id: $context_id) {
    profile {
      first_name
      last_name
      title
      handle
    }
    profile_settings {
      currency
    }
  }
}
    `;

/**
 * __useGetAccountInfoQuery__
 *
 * To run a query within a React component, call `useGetAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountInfoQuery({
 *   variables: {
 *      access_context_id: // value for 'access_context_id'
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetAccountInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAccountInfoQuery, Types.GetAccountInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAccountInfoQuery, Types.GetAccountInfoQueryVariables>(GetAccountInfoDocument, options);
      }
export function useGetAccountInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAccountInfoQuery, Types.GetAccountInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAccountInfoQuery, Types.GetAccountInfoQueryVariables>(GetAccountInfoDocument, options);
        }
export type GetAccountInfoQueryHookResult = ReturnType<typeof useGetAccountInfoQuery>;
export type GetAccountInfoLazyQueryHookResult = ReturnType<typeof useGetAccountInfoLazyQuery>;
export type GetAccountInfoQueryResult = Apollo.QueryResult<Types.GetAccountInfoQuery, Types.GetAccountInfoQueryVariables>;
export const ChangeEmailAddressDocument = gql`
    mutation ChangeEmailAddress($email: String!) {
  stepOneChangeEmail(new_email: $email)
}
    `;
export type ChangeEmailAddressMutationFn = Apollo.MutationFunction<Types.ChangeEmailAddressMutation, Types.ChangeEmailAddressMutationVariables>;

/**
 * __useChangeEmailAddressMutation__
 *
 * To run a mutation, you first call `useChangeEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailAddressMutation, { data, loading, error }] = useChangeEmailAddressMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useChangeEmailAddressMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeEmailAddressMutation, Types.ChangeEmailAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeEmailAddressMutation, Types.ChangeEmailAddressMutationVariables>(ChangeEmailAddressDocument, options);
      }
export type ChangeEmailAddressMutationHookResult = ReturnType<typeof useChangeEmailAddressMutation>;
export type ChangeEmailAddressMutationResult = Apollo.MutationResult<Types.ChangeEmailAddressMutation>;
export type ChangeEmailAddressMutationOptions = Apollo.BaseMutationOptions<Types.ChangeEmailAddressMutation, Types.ChangeEmailAddressMutationVariables>;
export const UpdateAccountSettingsDocument = gql`
    mutation UpdateAccountSettings($contextId: bigint!, $profileId: bigint!, $title: String = "", $first_name: String = "", $last_name: String = "", $handle: String = "", $preferred_language: ENUM_language_preference_enum = ENGLISH) {
  update_Auth(
    where: {auth_context_accesses: {context_id: {_eq: $contextId}}}
    _set: {first_name: $first_name, last_name: $last_name, preferred_language: $preferred_language}
  ) {
    affected_rows
  }
  update_Profile(
    where: {id: {_eq: $profileId}}
    _set: {first_name: $first_name, last_name: $last_name, title: $title, handle: $handle}
  ) {
    affected_rows
  }
}
    `;
export type UpdateAccountSettingsMutationFn = Apollo.MutationFunction<Types.UpdateAccountSettingsMutation, Types.UpdateAccountSettingsMutationVariables>;

/**
 * __useUpdateAccountSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountSettingsMutation, { data, loading, error }] = useUpdateAccountSettingsMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      profileId: // value for 'profileId'
 *      title: // value for 'title'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      handle: // value for 'handle'
 *      preferred_language: // value for 'preferred_language'
 *   },
 * });
 */
export function useUpdateAccountSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAccountSettingsMutation, Types.UpdateAccountSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAccountSettingsMutation, Types.UpdateAccountSettingsMutationVariables>(UpdateAccountSettingsDocument, options);
      }
export type UpdateAccountSettingsMutationHookResult = ReturnType<typeof useUpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationResult = Apollo.MutationResult<Types.UpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAccountSettingsMutation, Types.UpdateAccountSettingsMutationVariables>;
export const SetSubscriptionToNotificationGroupDocument = gql`
    mutation setSubscriptionToNotificationGroup($notification_group: String!, $blocked: Boolean!) {
  setSubscriptionToNotificationGroup(
    notification_group: $notification_group
    blocked: $blocked
  )
}
    `;
export type SetSubscriptionToNotificationGroupMutationFn = Apollo.MutationFunction<Types.SetSubscriptionToNotificationGroupMutation, Types.SetSubscriptionToNotificationGroupMutationVariables>;

/**
 * __useSetSubscriptionToNotificationGroupMutation__
 *
 * To run a mutation, you first call `useSetSubscriptionToNotificationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSubscriptionToNotificationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSubscriptionToNotificationGroupMutation, { data, loading, error }] = useSetSubscriptionToNotificationGroupMutation({
 *   variables: {
 *      notification_group: // value for 'notification_group'
 *      blocked: // value for 'blocked'
 *   },
 * });
 */
export function useSetSubscriptionToNotificationGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetSubscriptionToNotificationGroupMutation, Types.SetSubscriptionToNotificationGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetSubscriptionToNotificationGroupMutation, Types.SetSubscriptionToNotificationGroupMutationVariables>(SetSubscriptionToNotificationGroupDocument, options);
      }
export type SetSubscriptionToNotificationGroupMutationHookResult = ReturnType<typeof useSetSubscriptionToNotificationGroupMutation>;
export type SetSubscriptionToNotificationGroupMutationResult = Apollo.MutationResult<Types.SetSubscriptionToNotificationGroupMutation>;
export type SetSubscriptionToNotificationGroupMutationOptions = Apollo.BaseMutationOptions<Types.SetSubscriptionToNotificationGroupMutation, Types.SetSubscriptionToNotificationGroupMutationVariables>;
export const GetEmailGroupsPreferencesDocument = gql`
    query GetEmailGroupsPreferences {
  Notification_preference(where: {channel: {_eq: EMAIL}}) {
    notification
    blocked
  }
}
    `;

/**
 * __useGetEmailGroupsPreferencesQuery__
 *
 * To run a query within a React component, call `useGetEmailGroupsPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailGroupsPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailGroupsPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailGroupsPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmailGroupsPreferencesQuery, Types.GetEmailGroupsPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmailGroupsPreferencesQuery, Types.GetEmailGroupsPreferencesQueryVariables>(GetEmailGroupsPreferencesDocument, options);
      }
export function useGetEmailGroupsPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmailGroupsPreferencesQuery, Types.GetEmailGroupsPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmailGroupsPreferencesQuery, Types.GetEmailGroupsPreferencesQueryVariables>(GetEmailGroupsPreferencesDocument, options);
        }
export type GetEmailGroupsPreferencesQueryHookResult = ReturnType<typeof useGetEmailGroupsPreferencesQuery>;
export type GetEmailGroupsPreferencesLazyQueryHookResult = ReturnType<typeof useGetEmailGroupsPreferencesLazyQuery>;
export type GetEmailGroupsPreferencesQueryResult = Apollo.QueryResult<Types.GetEmailGroupsPreferencesQuery, Types.GetEmailGroupsPreferencesQueryVariables>;
export const GetNewRecognitionsDocument = gql`
    query getNewRecognitions($contextId: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, target_context_id: {_eq: $contextId}, is_mutual: {_eq: false}, Social_graph_followers_Context: {type: {_eq: ARTIST}}}
    order_by: {created_at: desc}
  ) {
    ...FollowerFields
  }
}
    ${FollowerFieldsFragmentDoc}`;

/**
 * __useGetNewRecognitionsQuery__
 *
 * To run a query within a React component, call `useGetNewRecognitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewRecognitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewRecognitionsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetNewRecognitionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNewRecognitionsQuery, Types.GetNewRecognitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNewRecognitionsQuery, Types.GetNewRecognitionsQueryVariables>(GetNewRecognitionsDocument, options);
      }
export function useGetNewRecognitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNewRecognitionsQuery, Types.GetNewRecognitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNewRecognitionsQuery, Types.GetNewRecognitionsQueryVariables>(GetNewRecognitionsDocument, options);
        }
export type GetNewRecognitionsQueryHookResult = ReturnType<typeof useGetNewRecognitionsQuery>;
export type GetNewRecognitionsLazyQueryHookResult = ReturnType<typeof useGetNewRecognitionsLazyQuery>;
export type GetNewRecognitionsQueryResult = Apollo.QueryResult<Types.GetNewRecognitionsQuery, Types.GetNewRecognitionsQueryVariables>;
export const GetNewRecognitionsV2Document = gql`
    query getNewRecognitionsV2($contextId: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, target_context_id: {_eq: $contextId}, is_mutual: {_eq: false}, Social_graph_followers_Context: {type: {_eq: ARTIST}}}
    order_by: {updated_at: desc}
  ) {
    context_id
    target_context_id
    selected_order
    updated_at
  }
}
    `;

/**
 * __useGetNewRecognitionsV2Query__
 *
 * To run a query within a React component, call `useGetNewRecognitionsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetNewRecognitionsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewRecognitionsV2Query({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetNewRecognitionsV2Query(baseOptions: Apollo.QueryHookOptions<Types.GetNewRecognitionsV2Query, Types.GetNewRecognitionsV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNewRecognitionsV2Query, Types.GetNewRecognitionsV2QueryVariables>(GetNewRecognitionsV2Document, options);
      }
export function useGetNewRecognitionsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNewRecognitionsV2Query, Types.GetNewRecognitionsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNewRecognitionsV2Query, Types.GetNewRecognitionsV2QueryVariables>(GetNewRecognitionsV2Document, options);
        }
export type GetNewRecognitionsV2QueryHookResult = ReturnType<typeof useGetNewRecognitionsV2Query>;
export type GetNewRecognitionsV2LazyQueryHookResult = ReturnType<typeof useGetNewRecognitionsV2LazyQuery>;
export type GetNewRecognitionsV2QueryResult = Apollo.QueryResult<Types.GetNewRecognitionsV2Query, Types.GetNewRecognitionsV2QueryVariables>;
export const GetNewRecognitionsV2LimitedDocument = gql`
    query getNewRecognitionsV2Limited($contextId: bigint!, $limit: Int!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, target_context_id: {_eq: $contextId}, is_mutual: {_eq: false}, Social_graph_followers_Context: {type: {_eq: ARTIST}}}
    order_by: {updated_at: desc}
    limit: $limit
  ) {
    id
    context_id
    target_context_id
    selected_order
    updated_at
  }
}
    `;

/**
 * __useGetNewRecognitionsV2LimitedQuery__
 *
 * To run a query within a React component, call `useGetNewRecognitionsV2LimitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewRecognitionsV2LimitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewRecognitionsV2LimitedQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetNewRecognitionsV2LimitedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNewRecognitionsV2LimitedQuery, Types.GetNewRecognitionsV2LimitedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNewRecognitionsV2LimitedQuery, Types.GetNewRecognitionsV2LimitedQueryVariables>(GetNewRecognitionsV2LimitedDocument, options);
      }
export function useGetNewRecognitionsV2LimitedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNewRecognitionsV2LimitedQuery, Types.GetNewRecognitionsV2LimitedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNewRecognitionsV2LimitedQuery, Types.GetNewRecognitionsV2LimitedQueryVariables>(GetNewRecognitionsV2LimitedDocument, options);
        }
export type GetNewRecognitionsV2LimitedQueryHookResult = ReturnType<typeof useGetNewRecognitionsV2LimitedQuery>;
export type GetNewRecognitionsV2LimitedLazyQueryHookResult = ReturnType<typeof useGetNewRecognitionsV2LimitedLazyQuery>;
export type GetNewRecognitionsV2LimitedQueryResult = Apollo.QueryResult<Types.GetNewRecognitionsV2LimitedQuery, Types.GetNewRecognitionsV2LimitedQueryVariables>;
export const GetIgnoredRecognitionsDocument = gql`
    query getIgnoredRecognitions($contextId: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: DECLINED}, context_id: {_eq: $contextId}, is_mutual: {_eq: false}, Social_graph_followers_Context: {type: {_eq: ARTIST}}}
    order_by: {created_at: desc}
  ) {
    ...FollowerFields
  }
}
    ${FollowerFieldsFragmentDoc}`;

/**
 * __useGetIgnoredRecognitionsQuery__
 *
 * To run a query within a React component, call `useGetIgnoredRecognitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIgnoredRecognitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIgnoredRecognitionsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetIgnoredRecognitionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetIgnoredRecognitionsQuery, Types.GetIgnoredRecognitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIgnoredRecognitionsQuery, Types.GetIgnoredRecognitionsQueryVariables>(GetIgnoredRecognitionsDocument, options);
      }
export function useGetIgnoredRecognitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIgnoredRecognitionsQuery, Types.GetIgnoredRecognitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIgnoredRecognitionsQuery, Types.GetIgnoredRecognitionsQueryVariables>(GetIgnoredRecognitionsDocument, options);
        }
export type GetIgnoredRecognitionsQueryHookResult = ReturnType<typeof useGetIgnoredRecognitionsQuery>;
export type GetIgnoredRecognitionsLazyQueryHookResult = ReturnType<typeof useGetIgnoredRecognitionsLazyQuery>;
export type GetIgnoredRecognitionsQueryResult = Apollo.QueryResult<Types.GetIgnoredRecognitionsQuery, Types.GetIgnoredRecognitionsQueryVariables>;
export const GetNewRecognitionsForProviderDocument = gql`
    query getNewRecognitionsForProvider($contextId: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, target_context_id: {_eq: $contextId}, is_mutual: {_eq: false}, Social_graph_followers_Context: {type: {_eq: ARTIST}}}
    order_by: {created_at: desc}
  ) {
    id
    context_id
    target_context_id
    state
  }
}
    `;

/**
 * __useGetNewRecognitionsForProviderQuery__
 *
 * To run a query within a React component, call `useGetNewRecognitionsForProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewRecognitionsForProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewRecognitionsForProviderQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetNewRecognitionsForProviderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNewRecognitionsForProviderQuery, Types.GetNewRecognitionsForProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNewRecognitionsForProviderQuery, Types.GetNewRecognitionsForProviderQueryVariables>(GetNewRecognitionsForProviderDocument, options);
      }
export function useGetNewRecognitionsForProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNewRecognitionsForProviderQuery, Types.GetNewRecognitionsForProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNewRecognitionsForProviderQuery, Types.GetNewRecognitionsForProviderQueryVariables>(GetNewRecognitionsForProviderDocument, options);
        }
export type GetNewRecognitionsForProviderQueryHookResult = ReturnType<typeof useGetNewRecognitionsForProviderQuery>;
export type GetNewRecognitionsForProviderLazyQueryHookResult = ReturnType<typeof useGetNewRecognitionsForProviderLazyQuery>;
export type GetNewRecognitionsForProviderQueryResult = Apollo.QueryResult<Types.GetNewRecognitionsForProviderQuery, Types.GetNewRecognitionsForProviderQueryVariables>;
export const GetIgnoredRecognitionsForProviderDocument = gql`
    query getIgnoredRecognitionsForProvider($contextId: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: DECLINED}, context_id: {_eq: $contextId}, is_mutual: {_eq: false}, Social_graph_followers_Context: {type: {_eq: ARTIST}}}
    order_by: {created_at: desc}
  ) {
    id
    context_id
    target_context_id
    state
  }
}
    `;

/**
 * __useGetIgnoredRecognitionsForProviderQuery__
 *
 * To run a query within a React component, call `useGetIgnoredRecognitionsForProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIgnoredRecognitionsForProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIgnoredRecognitionsForProviderQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetIgnoredRecognitionsForProviderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetIgnoredRecognitionsForProviderQuery, Types.GetIgnoredRecognitionsForProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIgnoredRecognitionsForProviderQuery, Types.GetIgnoredRecognitionsForProviderQueryVariables>(GetIgnoredRecognitionsForProviderDocument, options);
      }
export function useGetIgnoredRecognitionsForProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIgnoredRecognitionsForProviderQuery, Types.GetIgnoredRecognitionsForProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIgnoredRecognitionsForProviderQuery, Types.GetIgnoredRecognitionsForProviderQueryVariables>(GetIgnoredRecognitionsForProviderDocument, options);
        }
export type GetIgnoredRecognitionsForProviderQueryHookResult = ReturnType<typeof useGetIgnoredRecognitionsForProviderQuery>;
export type GetIgnoredRecognitionsForProviderLazyQueryHookResult = ReturnType<typeof useGetIgnoredRecognitionsForProviderLazyQuery>;
export type GetIgnoredRecognitionsForProviderQueryResult = Apollo.QueryResult<Types.GetIgnoredRecognitionsForProviderQuery, Types.GetIgnoredRecognitionsForProviderQueryVariables>;
export const GetMutualAdmirationsDocument = gql`
    query getMutualAdmirations($context_id: bigint!, $offset: Int = 0, $limit: Int!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, context_id: {_eq: $context_id}, is_mutual: {_eq: true}}
    order_by: {selected_order: asc}
    offset: $offset
    limit: $limit
  ) {
    ...FollowerFields
  }
}
    ${FollowerFieldsFragmentDoc}`;

/**
 * __useGetMutualAdmirationsQuery__
 *
 * To run a query within a React component, call `useGetMutualAdmirationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMutualAdmirationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMutualAdmirationsQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMutualAdmirationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMutualAdmirationsQuery, Types.GetMutualAdmirationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMutualAdmirationsQuery, Types.GetMutualAdmirationsQueryVariables>(GetMutualAdmirationsDocument, options);
      }
export function useGetMutualAdmirationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMutualAdmirationsQuery, Types.GetMutualAdmirationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMutualAdmirationsQuery, Types.GetMutualAdmirationsQueryVariables>(GetMutualAdmirationsDocument, options);
        }
export type GetMutualAdmirationsQueryHookResult = ReturnType<typeof useGetMutualAdmirationsQuery>;
export type GetMutualAdmirationsLazyQueryHookResult = ReturnType<typeof useGetMutualAdmirationsLazyQuery>;
export type GetMutualAdmirationsQueryResult = Apollo.QueryResult<Types.GetMutualAdmirationsQuery, Types.GetMutualAdmirationsQueryVariables>;
export const GetMutualRecognitionsCountDocument = gql`
    query getMutualRecognitionsCount($context_id: bigint!) {
  aggregateProxy(
    type: MutualRecognitionsOfArtist
    variables: {context_id: $context_id}
  )
}
    `;

/**
 * __useGetMutualRecognitionsCountQuery__
 *
 * To run a query within a React component, call `useGetMutualRecognitionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMutualRecognitionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMutualRecognitionsCountQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetMutualRecognitionsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMutualRecognitionsCountQuery, Types.GetMutualRecognitionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMutualRecognitionsCountQuery, Types.GetMutualRecognitionsCountQueryVariables>(GetMutualRecognitionsCountDocument, options);
      }
export function useGetMutualRecognitionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMutualRecognitionsCountQuery, Types.GetMutualRecognitionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMutualRecognitionsCountQuery, Types.GetMutualRecognitionsCountQueryVariables>(GetMutualRecognitionsCountDocument, options);
        }
export type GetMutualRecognitionsCountQueryHookResult = ReturnType<typeof useGetMutualRecognitionsCountQuery>;
export type GetMutualRecognitionsCountLazyQueryHookResult = ReturnType<typeof useGetMutualRecognitionsCountLazyQuery>;
export type GetMutualRecognitionsCountQueryResult = Apollo.QueryResult<Types.GetMutualRecognitionsCountQuery, Types.GetMutualRecognitionsCountQueryVariables>;
export const GetCollectorsRecognizingArtistDocument = gql`
    query getCollectorsRecognizingArtist($contextId: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, target_context_id: {_eq: $contextId}, Social_graph_followers_Context: {type: {_eq: COLLECTOR}}}
    order_by: {updated_at: desc}
  ) {
    ...FollowerFields
  }
}
    ${FollowerFieldsFragmentDoc}`;

/**
 * __useGetCollectorsRecognizingArtistQuery__
 *
 * To run a query within a React component, call `useGetCollectorsRecognizingArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorsRecognizingArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorsRecognizingArtistQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetCollectorsRecognizingArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorsRecognizingArtistQuery, Types.GetCollectorsRecognizingArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorsRecognizingArtistQuery, Types.GetCollectorsRecognizingArtistQueryVariables>(GetCollectorsRecognizingArtistDocument, options);
      }
export function useGetCollectorsRecognizingArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorsRecognizingArtistQuery, Types.GetCollectorsRecognizingArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorsRecognizingArtistQuery, Types.GetCollectorsRecognizingArtistQueryVariables>(GetCollectorsRecognizingArtistDocument, options);
        }
export type GetCollectorsRecognizingArtistQueryHookResult = ReturnType<typeof useGetCollectorsRecognizingArtistQuery>;
export type GetCollectorsRecognizingArtistLazyQueryHookResult = ReturnType<typeof useGetCollectorsRecognizingArtistLazyQuery>;
export type GetCollectorsRecognizingArtistQueryResult = Apollo.QueryResult<Types.GetCollectorsRecognizingArtistQuery, Types.GetCollectorsRecognizingArtistQueryVariables>;
export const GetCollectorsRecognizingArtistLimitedDocument = gql`
    query getCollectorsRecognizingArtistLimited($contextId: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, target_context_id: {_eq: $contextId}, Social_graph_followers_Context: {type: {_eq: COLLECTOR}}}
    limit: 6
    order_by: {updated_at: desc}
  ) {
    ...FollowerFields
  }
}
    ${FollowerFieldsFragmentDoc}`;

/**
 * __useGetCollectorsRecognizingArtistLimitedQuery__
 *
 * To run a query within a React component, call `useGetCollectorsRecognizingArtistLimitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorsRecognizingArtistLimitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorsRecognizingArtistLimitedQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetCollectorsRecognizingArtistLimitedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorsRecognizingArtistLimitedQuery, Types.GetCollectorsRecognizingArtistLimitedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorsRecognizingArtistLimitedQuery, Types.GetCollectorsRecognizingArtistLimitedQueryVariables>(GetCollectorsRecognizingArtistLimitedDocument, options);
      }
export function useGetCollectorsRecognizingArtistLimitedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorsRecognizingArtistLimitedQuery, Types.GetCollectorsRecognizingArtistLimitedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorsRecognizingArtistLimitedQuery, Types.GetCollectorsRecognizingArtistLimitedQueryVariables>(GetCollectorsRecognizingArtistLimitedDocument, options);
        }
export type GetCollectorsRecognizingArtistLimitedQueryHookResult = ReturnType<typeof useGetCollectorsRecognizingArtistLimitedQuery>;
export type GetCollectorsRecognizingArtistLimitedLazyQueryHookResult = ReturnType<typeof useGetCollectorsRecognizingArtistLimitedLazyQuery>;
export type GetCollectorsRecognizingArtistLimitedQueryResult = Apollo.QueryResult<Types.GetCollectorsRecognizingArtistLimitedQuery, Types.GetCollectorsRecognizingArtistLimitedQueryVariables>;
export const GetCollectorsRecognizingArtistForProviderDocument = gql`
    query getCollectorsRecognizingArtistForProvider($contextId: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, target_context_id: {_eq: $contextId}, Social_graph_followers_Context: {type: {_eq: COLLECTOR}}}
  ) {
    id
    context_id
    target_context_id
    state
  }
}
    `;

/**
 * __useGetCollectorsRecognizingArtistForProviderQuery__
 *
 * To run a query within a React component, call `useGetCollectorsRecognizingArtistForProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorsRecognizingArtistForProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorsRecognizingArtistForProviderQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetCollectorsRecognizingArtistForProviderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorsRecognizingArtistForProviderQuery, Types.GetCollectorsRecognizingArtistForProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorsRecognizingArtistForProviderQuery, Types.GetCollectorsRecognizingArtistForProviderQueryVariables>(GetCollectorsRecognizingArtistForProviderDocument, options);
      }
export function useGetCollectorsRecognizingArtistForProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorsRecognizingArtistForProviderQuery, Types.GetCollectorsRecognizingArtistForProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorsRecognizingArtistForProviderQuery, Types.GetCollectorsRecognizingArtistForProviderQueryVariables>(GetCollectorsRecognizingArtistForProviderDocument, options);
        }
export type GetCollectorsRecognizingArtistForProviderQueryHookResult = ReturnType<typeof useGetCollectorsRecognizingArtistForProviderQuery>;
export type GetCollectorsRecognizingArtistForProviderLazyQueryHookResult = ReturnType<typeof useGetCollectorsRecognizingArtistForProviderLazyQuery>;
export type GetCollectorsRecognizingArtistForProviderQueryResult = Apollo.QueryResult<Types.GetCollectorsRecognizingArtistForProviderQuery, Types.GetCollectorsRecognizingArtistForProviderQueryVariables>;
export const GetMutualAdmirationsForProviderDocument = gql`
    query getMutualAdmirationsForProvider($context_id: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, context_id: {_eq: $context_id}, is_mutual: {_eq: true}}
    order_by: {selected_order: asc}
  ) {
    id
    context_id
    target_context_id
    state
  }
}
    `;

/**
 * __useGetMutualAdmirationsForProviderQuery__
 *
 * To run a query within a React component, call `useGetMutualAdmirationsForProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMutualAdmirationsForProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMutualAdmirationsForProviderQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetMutualAdmirationsForProviderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMutualAdmirationsForProviderQuery, Types.GetMutualAdmirationsForProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMutualAdmirationsForProviderQuery, Types.GetMutualAdmirationsForProviderQueryVariables>(GetMutualAdmirationsForProviderDocument, options);
      }
export function useGetMutualAdmirationsForProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMutualAdmirationsForProviderQuery, Types.GetMutualAdmirationsForProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMutualAdmirationsForProviderQuery, Types.GetMutualAdmirationsForProviderQueryVariables>(GetMutualAdmirationsForProviderDocument, options);
        }
export type GetMutualAdmirationsForProviderQueryHookResult = ReturnType<typeof useGetMutualAdmirationsForProviderQuery>;
export type GetMutualAdmirationsForProviderLazyQueryHookResult = ReturnType<typeof useGetMutualAdmirationsForProviderLazyQuery>;
export type GetMutualAdmirationsForProviderQueryResult = Apollo.QueryResult<Types.GetMutualAdmirationsForProviderQuery, Types.GetMutualAdmirationsForProviderQueryVariables>;
export const GetAdmirationsForProviderDocument = gql`
    query getAdmirationsForProvider($context_id: bigint!) {
  Social_graph_followers(
    where: {_and: {context_id: {_eq: $context_id}, state: {_eq: ACTIVE}}}
    order_by: {selected_order: asc, updated_at: asc}
  ) {
    id
    context_id
    target_context_id
    state
    selected_order
  }
}
    `;

/**
 * __useGetAdmirationsForProviderQuery__
 *
 * To run a query within a React component, call `useGetAdmirationsForProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdmirationsForProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdmirationsForProviderQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetAdmirationsForProviderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAdmirationsForProviderQuery, Types.GetAdmirationsForProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAdmirationsForProviderQuery, Types.GetAdmirationsForProviderQueryVariables>(GetAdmirationsForProviderDocument, options);
      }
export function useGetAdmirationsForProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAdmirationsForProviderQuery, Types.GetAdmirationsForProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAdmirationsForProviderQuery, Types.GetAdmirationsForProviderQueryVariables>(GetAdmirationsForProviderDocument, options);
        }
export type GetAdmirationsForProviderQueryHookResult = ReturnType<typeof useGetAdmirationsForProviderQuery>;
export type GetAdmirationsForProviderLazyQueryHookResult = ReturnType<typeof useGetAdmirationsForProviderLazyQuery>;
export type GetAdmirationsForProviderQueryResult = Apollo.QueryResult<Types.GetAdmirationsForProviderQuery, Types.GetAdmirationsForProviderQueryVariables>;
export const GetFollowIdDocument = gql`
    query getFollowId($target_context_id: bigint!, $context_id: bigint!) {
  Social_graph_followers(
    where: {context_id: {_eq: $context_id}, target_context_id: {_eq: $target_context_id}}
  ) {
    ...FollowerFields
  }
}
    ${FollowerFieldsFragmentDoc}`;

/**
 * __useGetFollowIdQuery__
 *
 * To run a query within a React component, call `useGetFollowIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowIdQuery({
 *   variables: {
 *      target_context_id: // value for 'target_context_id'
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetFollowIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFollowIdQuery, Types.GetFollowIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFollowIdQuery, Types.GetFollowIdQueryVariables>(GetFollowIdDocument, options);
      }
export function useGetFollowIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFollowIdQuery, Types.GetFollowIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFollowIdQuery, Types.GetFollowIdQueryVariables>(GetFollowIdDocument, options);
        }
export type GetFollowIdQueryHookResult = ReturnType<typeof useGetFollowIdQuery>;
export type GetFollowIdLazyQueryHookResult = ReturnType<typeof useGetFollowIdLazyQuery>;
export type GetFollowIdQueryResult = Apollo.QueryResult<Types.GetFollowIdQuery, Types.GetFollowIdQueryVariables>;
export const GetFollowIdNotificationsDocument = gql`
    query getFollowIdNotifications($target_context_id: bigint!, $context_id: bigint!) {
  Social_graph_followers_view(
    where: {context_id: {_eq: $context_id}, target_context_id: {_eq: $target_context_id}}
  ) {
    id
    target_context_id
    context_id
    is_mutual
    state
    natural_order
    selected_order
    created_at
    updated_at
    context_profile_id
    context_profile_avatar
    context_profile_title
    context_profile_first_name
    context_profile_last_name
    context_profile_handle
    context_profile_is_public
    target_profile_id
    target_profile_avatar
    target_profile_title
    target_profile_first_name
    target_profile_last_name
    target_profile_handle
    target_profile_is_public
  }
}
    `;

/**
 * __useGetFollowIdNotificationsQuery__
 *
 * To run a query within a React component, call `useGetFollowIdNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowIdNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowIdNotificationsQuery({
 *   variables: {
 *      target_context_id: // value for 'target_context_id'
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetFollowIdNotificationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFollowIdNotificationsQuery, Types.GetFollowIdNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFollowIdNotificationsQuery, Types.GetFollowIdNotificationsQueryVariables>(GetFollowIdNotificationsDocument, options);
      }
export function useGetFollowIdNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFollowIdNotificationsQuery, Types.GetFollowIdNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFollowIdNotificationsQuery, Types.GetFollowIdNotificationsQueryVariables>(GetFollowIdNotificationsDocument, options);
        }
export type GetFollowIdNotificationsQueryHookResult = ReturnType<typeof useGetFollowIdNotificationsQuery>;
export type GetFollowIdNotificationsLazyQueryHookResult = ReturnType<typeof useGetFollowIdNotificationsLazyQuery>;
export type GetFollowIdNotificationsQueryResult = Apollo.QueryResult<Types.GetFollowIdNotificationsQuery, Types.GetFollowIdNotificationsQueryVariables>;
export const GetActiveFollowIdDocument = gql`
    query getActiveFollowId($target_context_id: bigint!, $context_id: bigint!) {
  Social_graph_followers(
    where: {context_id: {_eq: $context_id}, target_context_id: {_eq: $target_context_id}, state: {_eq: ACTIVE}}
  ) {
    id
  }
}
    `;

/**
 * __useGetActiveFollowIdQuery__
 *
 * To run a query within a React component, call `useGetActiveFollowIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveFollowIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveFollowIdQuery({
 *   variables: {
 *      target_context_id: // value for 'target_context_id'
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetActiveFollowIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetActiveFollowIdQuery, Types.GetActiveFollowIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetActiveFollowIdQuery, Types.GetActiveFollowIdQueryVariables>(GetActiveFollowIdDocument, options);
      }
export function useGetActiveFollowIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetActiveFollowIdQuery, Types.GetActiveFollowIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetActiveFollowIdQuery, Types.GetActiveFollowIdQueryVariables>(GetActiveFollowIdDocument, options);
        }
export type GetActiveFollowIdQueryHookResult = ReturnType<typeof useGetActiveFollowIdQuery>;
export type GetActiveFollowIdLazyQueryHookResult = ReturnType<typeof useGetActiveFollowIdLazyQuery>;
export type GetActiveFollowIdQueryResult = Apollo.QueryResult<Types.GetActiveFollowIdQuery, Types.GetActiveFollowIdQueryVariables>;
export const IsFollowingDocument = gql`
    query isFollowing($context_id: Int!, $target_context_id: Int!) {
  canFollow(context_id: $context_id, target_context_id: $target_context_id)
}
    `;

/**
 * __useIsFollowingQuery__
 *
 * To run a query within a React component, call `useIsFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFollowingQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      target_context_id: // value for 'target_context_id'
 *   },
 * });
 */
export function useIsFollowingQuery(baseOptions: Apollo.QueryHookOptions<Types.IsFollowingQuery, Types.IsFollowingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsFollowingQuery, Types.IsFollowingQueryVariables>(IsFollowingDocument, options);
      }
export function useIsFollowingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsFollowingQuery, Types.IsFollowingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsFollowingQuery, Types.IsFollowingQueryVariables>(IsFollowingDocument, options);
        }
export type IsFollowingQueryHookResult = ReturnType<typeof useIsFollowingQuery>;
export type IsFollowingLazyQueryHookResult = ReturnType<typeof useIsFollowingLazyQuery>;
export type IsFollowingQueryResult = Apollo.QueryResult<Types.IsFollowingQuery, Types.IsFollowingQueryVariables>;
export const InsertFollowDocument = gql`
    mutation insertFollow($context_id: Int!, $target_context_id: Int!, $state: String!) {
  setFollowRelation(
    context_id: $context_id
    state: $state
    target_context_id: $target_context_id
  )
}
    `;
export type InsertFollowMutationFn = Apollo.MutationFunction<Types.InsertFollowMutation, Types.InsertFollowMutationVariables>;

/**
 * __useInsertFollowMutation__
 *
 * To run a mutation, you first call `useInsertFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFollowMutation, { data, loading, error }] = useInsertFollowMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      target_context_id: // value for 'target_context_id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useInsertFollowMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertFollowMutation, Types.InsertFollowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertFollowMutation, Types.InsertFollowMutationVariables>(InsertFollowDocument, options);
      }
export type InsertFollowMutationHookResult = ReturnType<typeof useInsertFollowMutation>;
export type InsertFollowMutationResult = Apollo.MutationResult<Types.InsertFollowMutation>;
export type InsertFollowMutationOptions = Apollo.BaseMutationOptions<Types.InsertFollowMutation, Types.InsertFollowMutationVariables>;
export const SetFollowRelationOrderDocument = gql`
    mutation setFollowRelationOrder($contextId: Int!, $targetContextId: Int!, $newSelectedOrder: Int!) {
  setFollowRelationOrder(
    context_id: $contextId
    target_context_id: $targetContextId
    new_selected_order: $newSelectedOrder
  )
}
    `;
export type SetFollowRelationOrderMutationFn = Apollo.MutationFunction<Types.SetFollowRelationOrderMutation, Types.SetFollowRelationOrderMutationVariables>;

/**
 * __useSetFollowRelationOrderMutation__
 *
 * To run a mutation, you first call `useSetFollowRelationOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFollowRelationOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFollowRelationOrderMutation, { data, loading, error }] = useSetFollowRelationOrderMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      targetContextId: // value for 'targetContextId'
 *      newSelectedOrder: // value for 'newSelectedOrder'
 *   },
 * });
 */
export function useSetFollowRelationOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetFollowRelationOrderMutation, Types.SetFollowRelationOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetFollowRelationOrderMutation, Types.SetFollowRelationOrderMutationVariables>(SetFollowRelationOrderDocument, options);
      }
export type SetFollowRelationOrderMutationHookResult = ReturnType<typeof useSetFollowRelationOrderMutation>;
export type SetFollowRelationOrderMutationResult = Apollo.MutationResult<Types.SetFollowRelationOrderMutation>;
export type SetFollowRelationOrderMutationOptions = Apollo.BaseMutationOptions<Types.SetFollowRelationOrderMutation, Types.SetFollowRelationOrderMutationVariables>;
export const GetFollowByFollowIdDocument = gql`
    query getFollowByFollowId($followId: Int!) {
  Social_graph_followers_by_pk(id: $followId) {
    ...FollowerFields
  }
}
    ${FollowerFieldsFragmentDoc}`;

/**
 * __useGetFollowByFollowIdQuery__
 *
 * To run a query within a React component, call `useGetFollowByFollowIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowByFollowIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowByFollowIdQuery({
 *   variables: {
 *      followId: // value for 'followId'
 *   },
 * });
 */
export function useGetFollowByFollowIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFollowByFollowIdQuery, Types.GetFollowByFollowIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFollowByFollowIdQuery, Types.GetFollowByFollowIdQueryVariables>(GetFollowByFollowIdDocument, options);
      }
export function useGetFollowByFollowIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFollowByFollowIdQuery, Types.GetFollowByFollowIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFollowByFollowIdQuery, Types.GetFollowByFollowIdQueryVariables>(GetFollowByFollowIdDocument, options);
        }
export type GetFollowByFollowIdQueryHookResult = ReturnType<typeof useGetFollowByFollowIdQuery>;
export type GetFollowByFollowIdLazyQueryHookResult = ReturnType<typeof useGetFollowByFollowIdLazyQuery>;
export type GetFollowByFollowIdQueryResult = Apollo.QueryResult<Types.GetFollowByFollowIdQuery, Types.GetFollowByFollowIdQueryVariables>;
export const GetContextTypeDocument = gql`
    query getContextType($contextId: bigint!) {
  Context_by_pk(id: $contextId) {
    type
  }
}
    `;

/**
 * __useGetContextTypeQuery__
 *
 * To run a query within a React component, call `useGetContextTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContextTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContextTypeQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetContextTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetContextTypeQuery, Types.GetContextTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetContextTypeQuery, Types.GetContextTypeQueryVariables>(GetContextTypeDocument, options);
      }
export function useGetContextTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContextTypeQuery, Types.GetContextTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetContextTypeQuery, Types.GetContextTypeQueryVariables>(GetContextTypeDocument, options);
        }
export type GetContextTypeQueryHookResult = ReturnType<typeof useGetContextTypeQuery>;
export type GetContextTypeLazyQueryHookResult = ReturnType<typeof useGetContextTypeLazyQuery>;
export type GetContextTypeQueryResult = Apollo.QueryResult<Types.GetContextTypeQuery, Types.GetContextTypeQueryVariables>;
export const HasNewRecognitionsFlagDocument = gql`
    subscription hasNewRecognitionsFlag($contextId: bigint!) {
  Context_flags(
    where: {context_id: {_eq: $contextId}, flag_type: {_eq: HAS_NEW_RECOGNITION}}
  ) {
    flag_value
  }
}
    `;

/**
 * __useHasNewRecognitionsFlagSubscription__
 *
 * To run a query within a React component, call `useHasNewRecognitionsFlagSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHasNewRecognitionsFlagSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasNewRecognitionsFlagSubscription({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useHasNewRecognitionsFlagSubscription(baseOptions: Apollo.SubscriptionHookOptions<Types.HasNewRecognitionsFlagSubscription, Types.HasNewRecognitionsFlagSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<Types.HasNewRecognitionsFlagSubscription, Types.HasNewRecognitionsFlagSubscriptionVariables>(HasNewRecognitionsFlagDocument, options);
      }
export type HasNewRecognitionsFlagSubscriptionHookResult = ReturnType<typeof useHasNewRecognitionsFlagSubscription>;
export type HasNewRecognitionsFlagSubscriptionResult = Apollo.SubscriptionResult<Types.HasNewRecognitionsFlagSubscription>;
export const ChangeEnteredConnectionsPageDocument = gql`
    mutation changeEnteredConnectionsPage($contextId: bigint!) {
  upsertContextFlag(
    context_id: $contextId
    flag_type: "HAS_NEW_RECOGNITION"
    flag_value: "false"
  )
}
    `;
export type ChangeEnteredConnectionsPageMutationFn = Apollo.MutationFunction<Types.ChangeEnteredConnectionsPageMutation, Types.ChangeEnteredConnectionsPageMutationVariables>;

/**
 * __useChangeEnteredConnectionsPageMutation__
 *
 * To run a mutation, you first call `useChangeEnteredConnectionsPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEnteredConnectionsPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEnteredConnectionsPageMutation, { data, loading, error }] = useChangeEnteredConnectionsPageMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useChangeEnteredConnectionsPageMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeEnteredConnectionsPageMutation, Types.ChangeEnteredConnectionsPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ChangeEnteredConnectionsPageMutation, Types.ChangeEnteredConnectionsPageMutationVariables>(ChangeEnteredConnectionsPageDocument, options);
      }
export type ChangeEnteredConnectionsPageMutationHookResult = ReturnType<typeof useChangeEnteredConnectionsPageMutation>;
export type ChangeEnteredConnectionsPageMutationResult = Apollo.MutationResult<Types.ChangeEnteredConnectionsPageMutation>;
export type ChangeEnteredConnectionsPageMutationOptions = Apollo.BaseMutationOptions<Types.ChangeEnteredConnectionsPageMutation, Types.ChangeEnteredConnectionsPageMutationVariables>;
export const GetSeriesByArtistContextIdDocument = gql`
    query GetSeriesByArtistContextId($contextId: bigint!) {
  Collection(where: {type: {_eq: SERIES}, owner_context_id: {_eq: $contextId}}) {
    id
    name
  }
}
    `;

/**
 * __useGetSeriesByArtistContextIdQuery__
 *
 * To run a query within a React component, call `useGetSeriesByArtistContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesByArtistContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesByArtistContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetSeriesByArtistContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSeriesByArtistContextIdQuery, Types.GetSeriesByArtistContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSeriesByArtistContextIdQuery, Types.GetSeriesByArtistContextIdQueryVariables>(GetSeriesByArtistContextIdDocument, options);
      }
export function useGetSeriesByArtistContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSeriesByArtistContextIdQuery, Types.GetSeriesByArtistContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSeriesByArtistContextIdQuery, Types.GetSeriesByArtistContextIdQueryVariables>(GetSeriesByArtistContextIdDocument, options);
        }
export type GetSeriesByArtistContextIdQueryHookResult = ReturnType<typeof useGetSeriesByArtistContextIdQuery>;
export type GetSeriesByArtistContextIdLazyQueryHookResult = ReturnType<typeof useGetSeriesByArtistContextIdLazyQuery>;
export type GetSeriesByArtistContextIdQueryResult = Apollo.QueryResult<Types.GetSeriesByArtistContextIdQuery, Types.GetSeriesByArtistContextIdQueryVariables>;
export const GetArtworkYearsByArtistContextIdDocument = gql`
    query GetArtworkYearsByArtistContextId($contextId: bigint!) {
  Artwork_details(
    where: {artwork: {artist_context_id: {_eq: $contextId}}}
    distinct_on: creation_date_year
  ) {
    creation_date_year
  }
}
    `;

/**
 * __useGetArtworkYearsByArtistContextIdQuery__
 *
 * To run a query within a React component, call `useGetArtworkYearsByArtistContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkYearsByArtistContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkYearsByArtistContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtworkYearsByArtistContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkYearsByArtistContextIdQuery, Types.GetArtworkYearsByArtistContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkYearsByArtistContextIdQuery, Types.GetArtworkYearsByArtistContextIdQueryVariables>(GetArtworkYearsByArtistContextIdDocument, options);
      }
export function useGetArtworkYearsByArtistContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkYearsByArtistContextIdQuery, Types.GetArtworkYearsByArtistContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkYearsByArtistContextIdQuery, Types.GetArtworkYearsByArtistContextIdQueryVariables>(GetArtworkYearsByArtistContextIdDocument, options);
        }
export type GetArtworkYearsByArtistContextIdQueryHookResult = ReturnType<typeof useGetArtworkYearsByArtistContextIdQuery>;
export type GetArtworkYearsByArtistContextIdLazyQueryHookResult = ReturnType<typeof useGetArtworkYearsByArtistContextIdLazyQuery>;
export type GetArtworkYearsByArtistContextIdQueryResult = Apollo.QueryResult<Types.GetArtworkYearsByArtistContextIdQuery, Types.GetArtworkYearsByArtistContextIdQueryVariables>;
export const GetSeriesByGalleryContextIdDocument = gql`
    query GetSeriesByGalleryContextId($contextId: bigint!) {
  Collaboration(
    where: {gallery_id: {_eq: $contextId}, artist_status: {_eq: ACCEPTED}, gallery_status: {_eq: ACCEPTED}}
  ) {
    artist_context {
      collections(
        where: {type: {_eq: SERIES}, artwork_to_collections: {Artwork: {gallery_context_id: {_eq: $contextId}}}}
      ) {
        id
        name
        artwork_to_collections {
          Artwork {
            id
            gallery_context_id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetSeriesByGalleryContextIdQuery__
 *
 * To run a query within a React component, call `useGetSeriesByGalleryContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesByGalleryContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesByGalleryContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetSeriesByGalleryContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSeriesByGalleryContextIdQuery, Types.GetSeriesByGalleryContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSeriesByGalleryContextIdQuery, Types.GetSeriesByGalleryContextIdQueryVariables>(GetSeriesByGalleryContextIdDocument, options);
      }
export function useGetSeriesByGalleryContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSeriesByGalleryContextIdQuery, Types.GetSeriesByGalleryContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSeriesByGalleryContextIdQuery, Types.GetSeriesByGalleryContextIdQueryVariables>(GetSeriesByGalleryContextIdDocument, options);
        }
export type GetSeriesByGalleryContextIdQueryHookResult = ReturnType<typeof useGetSeriesByGalleryContextIdQuery>;
export type GetSeriesByGalleryContextIdLazyQueryHookResult = ReturnType<typeof useGetSeriesByGalleryContextIdLazyQuery>;
export type GetSeriesByGalleryContextIdQueryResult = Apollo.QueryResult<Types.GetSeriesByGalleryContextIdQuery, Types.GetSeriesByGalleryContextIdQueryVariables>;
export const GetArtworkYearsByGalleryContextIdDocument = gql`
    query GetArtworkYearsByGalleryContextId($contextId: bigint!) {
  Artwork_details(
    where: {artwork: {gallery_context_id: {_eq: $contextId}}}
    distinct_on: creation_date_year
  ) {
    creation_date_year
  }
}
    `;

/**
 * __useGetArtworkYearsByGalleryContextIdQuery__
 *
 * To run a query within a React component, call `useGetArtworkYearsByGalleryContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkYearsByGalleryContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkYearsByGalleryContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtworkYearsByGalleryContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkYearsByGalleryContextIdQuery, Types.GetArtworkYearsByGalleryContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkYearsByGalleryContextIdQuery, Types.GetArtworkYearsByGalleryContextIdQueryVariables>(GetArtworkYearsByGalleryContextIdDocument, options);
      }
export function useGetArtworkYearsByGalleryContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkYearsByGalleryContextIdQuery, Types.GetArtworkYearsByGalleryContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkYearsByGalleryContextIdQuery, Types.GetArtworkYearsByGalleryContextIdQueryVariables>(GetArtworkYearsByGalleryContextIdDocument, options);
        }
export type GetArtworkYearsByGalleryContextIdQueryHookResult = ReturnType<typeof useGetArtworkYearsByGalleryContextIdQuery>;
export type GetArtworkYearsByGalleryContextIdLazyQueryHookResult = ReturnType<typeof useGetArtworkYearsByGalleryContextIdLazyQuery>;
export type GetArtworkYearsByGalleryContextIdQueryResult = Apollo.QueryResult<Types.GetArtworkYearsByGalleryContextIdQuery, Types.GetArtworkYearsByGalleryContextIdQueryVariables>;
export const GetArtworkYearsByCollectorContextIdDocument = gql`
    query GetArtworkYearsByCollectorContextId($contextId: bigint!) {
  Artwork_details(
    where: {artwork: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}, _or: [{context_seller_id: {_eq: $contextId}}, {context_buyer_id: {_eq: $contextId}}]}}}
    distinct_on: creation_date_year
  ) {
    creation_date_year
  }
}
    `;

/**
 * __useGetArtworkYearsByCollectorContextIdQuery__
 *
 * To run a query within a React component, call `useGetArtworkYearsByCollectorContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkYearsByCollectorContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkYearsByCollectorContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtworkYearsByCollectorContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkYearsByCollectorContextIdQuery, Types.GetArtworkYearsByCollectorContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkYearsByCollectorContextIdQuery, Types.GetArtworkYearsByCollectorContextIdQueryVariables>(GetArtworkYearsByCollectorContextIdDocument, options);
      }
export function useGetArtworkYearsByCollectorContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkYearsByCollectorContextIdQuery, Types.GetArtworkYearsByCollectorContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkYearsByCollectorContextIdQuery, Types.GetArtworkYearsByCollectorContextIdQueryVariables>(GetArtworkYearsByCollectorContextIdDocument, options);
        }
export type GetArtworkYearsByCollectorContextIdQueryHookResult = ReturnType<typeof useGetArtworkYearsByCollectorContextIdQuery>;
export type GetArtworkYearsByCollectorContextIdLazyQueryHookResult = ReturnType<typeof useGetArtworkYearsByCollectorContextIdLazyQuery>;
export type GetArtworkYearsByCollectorContextIdQueryResult = Apollo.QueryResult<Types.GetArtworkYearsByCollectorContextIdQuery, Types.GetArtworkYearsByCollectorContextIdQueryVariables>;
export const GetArtistsByGalleryContextIdDocument = gql`
    query GetArtistsByGalleryContextId($contextId: bigint!) {
  Collaboration(where: {gallery_id: {_eq: $contextId}}) {
    artist_context {
      id
      profile {
        title
      }
    }
  }
}
    `;

/**
 * __useGetArtistsByGalleryContextIdQuery__
 *
 * To run a query within a React component, call `useGetArtistsByGalleryContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsByGalleryContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsByGalleryContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistsByGalleryContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistsByGalleryContextIdQuery, Types.GetArtistsByGalleryContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistsByGalleryContextIdQuery, Types.GetArtistsByGalleryContextIdQueryVariables>(GetArtistsByGalleryContextIdDocument, options);
      }
export function useGetArtistsByGalleryContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistsByGalleryContextIdQuery, Types.GetArtistsByGalleryContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistsByGalleryContextIdQuery, Types.GetArtistsByGalleryContextIdQueryVariables>(GetArtistsByGalleryContextIdDocument, options);
        }
export type GetArtistsByGalleryContextIdQueryHookResult = ReturnType<typeof useGetArtistsByGalleryContextIdQuery>;
export type GetArtistsByGalleryContextIdLazyQueryHookResult = ReturnType<typeof useGetArtistsByGalleryContextIdLazyQuery>;
export type GetArtistsByGalleryContextIdQueryResult = Apollo.QueryResult<Types.GetArtistsByGalleryContextIdQuery, Types.GetArtistsByGalleryContextIdQueryVariables>;
export const GetArtistsByCollectorContextIdDocument = gql`
    query GetArtistsByCollectorContextId($contextId: bigint!) {
  Profile(
    where: {context: {_and: {type: {_eq: ARTIST}, Artworks: {artwork_to_collections: {Collection: {_and: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}}}}}
  ) {
    title
    context {
      id
    }
  }
}
    `;

/**
 * __useGetArtistsByCollectorContextIdQuery__
 *
 * To run a query within a React component, call `useGetArtistsByCollectorContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsByCollectorContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsByCollectorContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistsByCollectorContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistsByCollectorContextIdQuery, Types.GetArtistsByCollectorContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistsByCollectorContextIdQuery, Types.GetArtistsByCollectorContextIdQueryVariables>(GetArtistsByCollectorContextIdDocument, options);
      }
export function useGetArtistsByCollectorContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistsByCollectorContextIdQuery, Types.GetArtistsByCollectorContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistsByCollectorContextIdQuery, Types.GetArtistsByCollectorContextIdQueryVariables>(GetArtistsByCollectorContextIdDocument, options);
        }
export type GetArtistsByCollectorContextIdQueryHookResult = ReturnType<typeof useGetArtistsByCollectorContextIdQuery>;
export type GetArtistsByCollectorContextIdLazyQueryHookResult = ReturnType<typeof useGetArtistsByCollectorContextIdLazyQuery>;
export type GetArtistsByCollectorContextIdQueryResult = Apollo.QueryResult<Types.GetArtistsByCollectorContextIdQuery, Types.GetArtistsByCollectorContextIdQueryVariables>;
export const GetGalleryPartenerArtistsDocument = gql`
    query GetGalleryPartenerArtists($contextId: bigint!) {
  Collaboration(
    where: {gallery_id: {_eq: $contextId}, artist_status: {_eq: ACCEPTED}, gallery_status: {_eq: ACCEPTED}}
  ) {
    artist_id
    artist_context {
      profile {
        id
        avatar
        title
      }
    }
  }
}
    `;

/**
 * __useGetGalleryPartenerArtistsQuery__
 *
 * To run a query within a React component, call `useGetGalleryPartenerArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryPartenerArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryPartenerArtistsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetGalleryPartenerArtistsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryPartenerArtistsQuery, Types.GetGalleryPartenerArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryPartenerArtistsQuery, Types.GetGalleryPartenerArtistsQueryVariables>(GetGalleryPartenerArtistsDocument, options);
      }
export function useGetGalleryPartenerArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryPartenerArtistsQuery, Types.GetGalleryPartenerArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryPartenerArtistsQuery, Types.GetGalleryPartenerArtistsQueryVariables>(GetGalleryPartenerArtistsDocument, options);
        }
export type GetGalleryPartenerArtistsQueryHookResult = ReturnType<typeof useGetGalleryPartenerArtistsQuery>;
export type GetGalleryPartenerArtistsLazyQueryHookResult = ReturnType<typeof useGetGalleryPartenerArtistsLazyQuery>;
export type GetGalleryPartenerArtistsQueryResult = Apollo.QueryResult<Types.GetGalleryPartenerArtistsQuery, Types.GetGalleryPartenerArtistsQueryVariables>;
export const UpdateArtworkPublicStateDocument = gql`
    mutation UpdateArtworkPublicState($id: bigint!, $is_public: Boolean = false) {
  update_Artwork_to_Collection_by_pk(
    pk_columns: {id: $id}
    _set: {is_public: $is_public}
  ) {
    is_public
  }
}
    `;
export type UpdateArtworkPublicStateMutationFn = Apollo.MutationFunction<Types.UpdateArtworkPublicStateMutation, Types.UpdateArtworkPublicStateMutationVariables>;

/**
 * __useUpdateArtworkPublicStateMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkPublicStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkPublicStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkPublicStateMutation, { data, loading, error }] = useUpdateArtworkPublicStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      is_public: // value for 'is_public'
 *   },
 * });
 */
export function useUpdateArtworkPublicStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkPublicStateMutation, Types.UpdateArtworkPublicStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkPublicStateMutation, Types.UpdateArtworkPublicStateMutationVariables>(UpdateArtworkPublicStateDocument, options);
      }
export type UpdateArtworkPublicStateMutationHookResult = ReturnType<typeof useUpdateArtworkPublicStateMutation>;
export type UpdateArtworkPublicStateMutationResult = Apollo.MutationResult<Types.UpdateArtworkPublicStateMutation>;
export type UpdateArtworkPublicStateMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkPublicStateMutation, Types.UpdateArtworkPublicStateMutationVariables>;
export const UpdatePrintPublicStateDocument = gql`
    mutation UpdatePrintPublicState($id: Int!, $is_public: Boolean = false) {
  update_Print_to_Collection_by_pk(
    pk_columns: {id: $id}
    _set: {is_public: $is_public}
  ) {
    is_public
  }
}
    `;
export type UpdatePrintPublicStateMutationFn = Apollo.MutationFunction<Types.UpdatePrintPublicStateMutation, Types.UpdatePrintPublicStateMutationVariables>;

/**
 * __useUpdatePrintPublicStateMutation__
 *
 * To run a mutation, you first call `useUpdatePrintPublicStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrintPublicStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrintPublicStateMutation, { data, loading, error }] = useUpdatePrintPublicStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      is_public: // value for 'is_public'
 *   },
 * });
 */
export function useUpdatePrintPublicStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePrintPublicStateMutation, Types.UpdatePrintPublicStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePrintPublicStateMutation, Types.UpdatePrintPublicStateMutationVariables>(UpdatePrintPublicStateDocument, options);
      }
export type UpdatePrintPublicStateMutationHookResult = ReturnType<typeof useUpdatePrintPublicStateMutation>;
export type UpdatePrintPublicStateMutationResult = Apollo.MutationResult<Types.UpdatePrintPublicStateMutation>;
export type UpdatePrintPublicStateMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePrintPublicStateMutation, Types.UpdatePrintPublicStateMutationVariables>;
export const DeleteArtworkDocument = gql`
    mutation DeleteArtwork($id: bigint!) {
  update_Artwork_by_pk(pk_columns: {id: $id}, _set: {deleted_at: "now()"}) {
    id
  }
}
    `;
export type DeleteArtworkMutationFn = Apollo.MutationFunction<Types.DeleteArtworkMutation, Types.DeleteArtworkMutationVariables>;

/**
 * __useDeleteArtworkMutation__
 *
 * To run a mutation, you first call `useDeleteArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtworkMutation, { data, loading, error }] = useDeleteArtworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArtworkMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteArtworkMutation, Types.DeleteArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteArtworkMutation, Types.DeleteArtworkMutationVariables>(DeleteArtworkDocument, options);
      }
export type DeleteArtworkMutationHookResult = ReturnType<typeof useDeleteArtworkMutation>;
export type DeleteArtworkMutationResult = Apollo.MutationResult<Types.DeleteArtworkMutation>;
export type DeleteArtworkMutationOptions = Apollo.BaseMutationOptions<Types.DeleteArtworkMutation, Types.DeleteArtworkMutationVariables>;
export const GetManageArtAllArtistArtworksDocument = gql`
    query getManageArtAllArtistArtworks($extra_where: Artwork_bool_exp = {}, $contextId: bigint!) {
  untransferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: true}}, {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  transferable: Artwork(
    where: {_and: [$extra_where, {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: false}}, {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
    __typename
  }
  transferring: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: true}}, {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  tranferred: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}, {_not: {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}}]}
  ) {
    ...manageArtworkContent
  }
  editions: Edition(
    where: {Artwork: {_and: [$extra_where, {edition_type: {_in: [STANDALONE_EDITION, ARTWORK_EDITION]}, artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}, deleted_at: {_is_null: true}}
  ) {
    ...manageArtworkEdition
  }
}
    ${ManageArtworkContentFragmentDoc}
${ManageArtworkEditionFragmentDoc}`;

/**
 * __useGetManageArtAllArtistArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtAllArtistArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtAllArtistArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtAllArtistArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtAllArtistArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtAllArtistArtworksQuery, Types.GetManageArtAllArtistArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtAllArtistArtworksQuery, Types.GetManageArtAllArtistArtworksQueryVariables>(GetManageArtAllArtistArtworksDocument, options);
      }
export function useGetManageArtAllArtistArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtAllArtistArtworksQuery, Types.GetManageArtAllArtistArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtAllArtistArtworksQuery, Types.GetManageArtAllArtistArtworksQueryVariables>(GetManageArtAllArtistArtworksDocument, options);
        }
export type GetManageArtAllArtistArtworksQueryHookResult = ReturnType<typeof useGetManageArtAllArtistArtworksQuery>;
export type GetManageArtAllArtistArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtAllArtistArtworksLazyQuery>;
export type GetManageArtAllArtistArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtAllArtistArtworksQuery, Types.GetManageArtAllArtistArtworksQueryVariables>;
export const GetManageArtAllArtistUniqueArtworksDocument = gql`
    query getManageArtAllArtistUniqueArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  untransferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: true}}, {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  transferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: false}}, {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  transferring: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: true}}, {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  tranferred: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}, {_not: {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}}]}
  ) {
    ...manageArtworkContent
  }
}
    ${ManageArtworkContentFragmentDoc}`;

/**
 * __useGetManageArtAllArtistUniqueArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtAllArtistUniqueArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtAllArtistUniqueArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtAllArtistUniqueArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtAllArtistUniqueArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtAllArtistUniqueArtworksQuery, Types.GetManageArtAllArtistUniqueArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtAllArtistUniqueArtworksQuery, Types.GetManageArtAllArtistUniqueArtworksQueryVariables>(GetManageArtAllArtistUniqueArtworksDocument, options);
      }
export function useGetManageArtAllArtistUniqueArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtAllArtistUniqueArtworksQuery, Types.GetManageArtAllArtistUniqueArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtAllArtistUniqueArtworksQuery, Types.GetManageArtAllArtistUniqueArtworksQueryVariables>(GetManageArtAllArtistUniqueArtworksDocument, options);
        }
export type GetManageArtAllArtistUniqueArtworksQueryHookResult = ReturnType<typeof useGetManageArtAllArtistUniqueArtworksQuery>;
export type GetManageArtAllArtistUniqueArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtAllArtistUniqueArtworksLazyQuery>;
export type GetManageArtAllArtistUniqueArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtAllArtistUniqueArtworksQuery, Types.GetManageArtAllArtistUniqueArtworksQueryVariables>;
export const GetManageArtAllArtistEditionArtworksDocument = gql`
    query getManageArtAllArtistEditionArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  editions: Edition(
    where: {Artwork: {_and: [$extra_where, {edition_type: {_in: [STANDALONE_EDITION, ARTWORK_EDITION]}, artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}, deleted_at: {_is_null: true}}
  ) {
    ...manageArtworkEdition
  }
}
    ${ManageArtworkEditionFragmentDoc}`;

/**
 * __useGetManageArtAllArtistEditionArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtAllArtistEditionArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtAllArtistEditionArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtAllArtistEditionArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtAllArtistEditionArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtAllArtistEditionArtworksQuery, Types.GetManageArtAllArtistEditionArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtAllArtistEditionArtworksQuery, Types.GetManageArtAllArtistEditionArtworksQueryVariables>(GetManageArtAllArtistEditionArtworksDocument, options);
      }
export function useGetManageArtAllArtistEditionArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtAllArtistEditionArtworksQuery, Types.GetManageArtAllArtistEditionArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtAllArtistEditionArtworksQuery, Types.GetManageArtAllArtistEditionArtworksQueryVariables>(GetManageArtAllArtistEditionArtworksDocument, options);
        }
export type GetManageArtAllArtistEditionArtworksQueryHookResult = ReturnType<typeof useGetManageArtAllArtistEditionArtworksQuery>;
export type GetManageArtAllArtistEditionArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtAllArtistEditionArtworksLazyQuery>;
export type GetManageArtAllArtistEditionArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtAllArtistEditionArtworksQuery, Types.GetManageArtAllArtistEditionArtworksQueryVariables>;
export const GetManageArtAllGalleryUniqueArtworksDocument = gql`
    query getManageArtAllGalleryUniqueArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  untransferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE]}}], published_date: {_is_null: true}}, {_or: [{artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}
  ) {
    ...manageArtworkContent
  }
  transferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: false}}, {_or: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}
  ) {
    ...manageArtworkContent
  }
  transferring: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: true}}, {_or: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}
  ) {
    ...manageArtworkContent
  }
  tranferred: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE]}}], _and: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_neq: $contextId}}}}, {artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}]}]}
  ) {
    ...manageArtworkContent
  }
}
    ${ManageArtworkContentFragmentDoc}`;

/**
 * __useGetManageArtAllGalleryUniqueArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtAllGalleryUniqueArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtAllGalleryUniqueArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtAllGalleryUniqueArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtAllGalleryUniqueArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtAllGalleryUniqueArtworksQuery, Types.GetManageArtAllGalleryUniqueArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtAllGalleryUniqueArtworksQuery, Types.GetManageArtAllGalleryUniqueArtworksQueryVariables>(GetManageArtAllGalleryUniqueArtworksDocument, options);
      }
export function useGetManageArtAllGalleryUniqueArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtAllGalleryUniqueArtworksQuery, Types.GetManageArtAllGalleryUniqueArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtAllGalleryUniqueArtworksQuery, Types.GetManageArtAllGalleryUniqueArtworksQueryVariables>(GetManageArtAllGalleryUniqueArtworksDocument, options);
        }
export type GetManageArtAllGalleryUniqueArtworksQueryHookResult = ReturnType<typeof useGetManageArtAllGalleryUniqueArtworksQuery>;
export type GetManageArtAllGalleryUniqueArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtAllGalleryUniqueArtworksLazyQuery>;
export type GetManageArtAllGalleryUniqueArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtAllGalleryUniqueArtworksQuery, Types.GetManageArtAllGalleryUniqueArtworksQueryVariables>;
export const GetManageArtAllGalleryEditionArtworksDocument = gql`
    query getManageArtAllGalleryEditionArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  editions: Edition(
    where: {Artwork: {_and: [$extra_where, {edition_type: {_in: [STANDALONE_EDITION, ARTWORK_EDITION]}, _or: [{artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}, deleted_at: {_is_null: true}}
  ) {
    ...manageArtworkEdition
  }
}
    ${ManageArtworkEditionFragmentDoc}`;

/**
 * __useGetManageArtAllGalleryEditionArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtAllGalleryEditionArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtAllGalleryEditionArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtAllGalleryEditionArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtAllGalleryEditionArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtAllGalleryEditionArtworksQuery, Types.GetManageArtAllGalleryEditionArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtAllGalleryEditionArtworksQuery, Types.GetManageArtAllGalleryEditionArtworksQueryVariables>(GetManageArtAllGalleryEditionArtworksDocument, options);
      }
export function useGetManageArtAllGalleryEditionArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtAllGalleryEditionArtworksQuery, Types.GetManageArtAllGalleryEditionArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtAllGalleryEditionArtworksQuery, Types.GetManageArtAllGalleryEditionArtworksQueryVariables>(GetManageArtAllGalleryEditionArtworksDocument, options);
        }
export type GetManageArtAllGalleryEditionArtworksQueryHookResult = ReturnType<typeof useGetManageArtAllGalleryEditionArtworksQuery>;
export type GetManageArtAllGalleryEditionArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtAllGalleryEditionArtworksLazyQuery>;
export type GetManageArtAllGalleryEditionArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtAllGalleryEditionArtworksQuery, Types.GetManageArtAllGalleryEditionArtworksQueryVariables>;
export const GetManageArtAvailableArtistArtworksDocument = gql`
    query getManageArtAvailableArtistArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  transferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], is_available_sale: {_eq: true}}, {published_date: {_is_null: false}}, {is_transaction_locked: {_eq: false}}, {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  editions: Edition(
    where: {is_available_sale: {_eq: true}, publish_date: {_is_null: false}, deleted_at: {_is_null: true}, Artwork: {_and: [$extra_where, {edition_type: {_in: [STANDALONE_EDITION, ARTWORK_EDITION]}, artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}}
  ) {
    ...manageArtworkEdition
  }
}
    ${ManageArtworkContentFragmentDoc}
${ManageArtworkEditionFragmentDoc}`;

/**
 * __useGetManageArtAvailableArtistArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtAvailableArtistArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtAvailableArtistArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtAvailableArtistArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtAvailableArtistArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtAvailableArtistArtworksQuery, Types.GetManageArtAvailableArtistArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtAvailableArtistArtworksQuery, Types.GetManageArtAvailableArtistArtworksQueryVariables>(GetManageArtAvailableArtistArtworksDocument, options);
      }
export function useGetManageArtAvailableArtistArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtAvailableArtistArtworksQuery, Types.GetManageArtAvailableArtistArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtAvailableArtistArtworksQuery, Types.GetManageArtAvailableArtistArtworksQueryVariables>(GetManageArtAvailableArtistArtworksDocument, options);
        }
export type GetManageArtAvailableArtistArtworksQueryHookResult = ReturnType<typeof useGetManageArtAvailableArtistArtworksQuery>;
export type GetManageArtAvailableArtistArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtAvailableArtistArtworksLazyQuery>;
export type GetManageArtAvailableArtistArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtAvailableArtistArtworksQuery, Types.GetManageArtAvailableArtistArtworksQueryVariables>;
export const GetManageArtUnAvailableArtistArtworksDocument = gql`
    query getManageArtUnAvailableArtistArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  transferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], is_available_sale: {_eq: false}}, {published_date: {_is_null: false}}, {is_transaction_locked: {_eq: false}}, {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  editions: Edition(
    where: {is_available_sale: {_eq: false}, publish_date: {_is_null: false}, deleted_at: {_is_null: true}, Artwork: {_and: [$extra_where, {edition_type: {_in: [STANDALONE_EDITION, ARTWORK_EDITION]}, artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}}
  ) {
    ...manageArtworkEdition
  }
}
    ${ManageArtworkContentFragmentDoc}
${ManageArtworkEditionFragmentDoc}`;

/**
 * __useGetManageArtUnAvailableArtistArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtUnAvailableArtistArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtUnAvailableArtistArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtUnAvailableArtistArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtUnAvailableArtistArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtUnAvailableArtistArtworksQuery, Types.GetManageArtUnAvailableArtistArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtUnAvailableArtistArtworksQuery, Types.GetManageArtUnAvailableArtistArtworksQueryVariables>(GetManageArtUnAvailableArtistArtworksDocument, options);
      }
export function useGetManageArtUnAvailableArtistArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtUnAvailableArtistArtworksQuery, Types.GetManageArtUnAvailableArtistArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtUnAvailableArtistArtworksQuery, Types.GetManageArtUnAvailableArtistArtworksQueryVariables>(GetManageArtUnAvailableArtistArtworksDocument, options);
        }
export type GetManageArtUnAvailableArtistArtworksQueryHookResult = ReturnType<typeof useGetManageArtUnAvailableArtistArtworksQuery>;
export type GetManageArtUnAvailableArtistArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtUnAvailableArtistArtworksLazyQuery>;
export type GetManageArtUnAvailableArtistArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtUnAvailableArtistArtworksQuery, Types.GetManageArtUnAvailableArtistArtworksQueryVariables>;
export const GetManageArtTransferredArtistArtworksDocument = gql`
    query getManageArtTransferredArtistArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  transferring: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], is_available_sale: {_eq: true}}, {is_transaction_locked: {_eq: true}}, {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  tranferred: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}, {_not: {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}}]}
  ) {
    ...manageArtworkContent
  }
}
    ${ManageArtworkContentFragmentDoc}`;

/**
 * __useGetManageArtTransferredArtistArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtTransferredArtistArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtTransferredArtistArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtTransferredArtistArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtTransferredArtistArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtTransferredArtistArtworksQuery, Types.GetManageArtTransferredArtistArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtTransferredArtistArtworksQuery, Types.GetManageArtTransferredArtistArtworksQueryVariables>(GetManageArtTransferredArtistArtworksDocument, options);
      }
export function useGetManageArtTransferredArtistArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtTransferredArtistArtworksQuery, Types.GetManageArtTransferredArtistArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtTransferredArtistArtworksQuery, Types.GetManageArtTransferredArtistArtworksQueryVariables>(GetManageArtTransferredArtistArtworksDocument, options);
        }
export type GetManageArtTransferredArtistArtworksQueryHookResult = ReturnType<typeof useGetManageArtTransferredArtistArtworksQuery>;
export type GetManageArtTransferredArtistArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtTransferredArtistArtworksLazyQuery>;
export type GetManageArtTransferredArtistArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtTransferredArtistArtworksQuery, Types.GetManageArtTransferredArtistArtworksQueryVariables>;
export const GetManageArtAllGalleryArtworksDocument = gql`
    query getManageArtAllGalleryArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  untransferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: true}}, {_or: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}
  ) {
    ...manageArtworkContent
  }
  transferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: false}}, {_or: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}
  ) {
    ...manageArtworkContent
  }
  transferring: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: true}}, {_or: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}
  ) {
    ...manageArtworkContent
  }
  tranferred: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], _and: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_neq: $contextId}}}}, {artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}]}]}
  ) {
    ...manageArtworkContent
  }
}
    ${ManageArtworkContentFragmentDoc}`;

/**
 * __useGetManageArtAllGalleryArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtAllGalleryArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtAllGalleryArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtAllGalleryArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtAllGalleryArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtAllGalleryArtworksQuery, Types.GetManageArtAllGalleryArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtAllGalleryArtworksQuery, Types.GetManageArtAllGalleryArtworksQueryVariables>(GetManageArtAllGalleryArtworksDocument, options);
      }
export function useGetManageArtAllGalleryArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtAllGalleryArtworksQuery, Types.GetManageArtAllGalleryArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtAllGalleryArtworksQuery, Types.GetManageArtAllGalleryArtworksQueryVariables>(GetManageArtAllGalleryArtworksDocument, options);
        }
export type GetManageArtAllGalleryArtworksQueryHookResult = ReturnType<typeof useGetManageArtAllGalleryArtworksQuery>;
export type GetManageArtAllGalleryArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtAllGalleryArtworksLazyQuery>;
export type GetManageArtAllGalleryArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtAllGalleryArtworksQuery, Types.GetManageArtAllGalleryArtworksQueryVariables>;
export const GetManageArtAllGalleryArtworksViewDocument = gql`
    query getManageArtAllGalleryArtworksView($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  untransferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: true}}, {artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContentView
  }
  transferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: false}}, {artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContentView
  }
  transferring: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: true}}, {artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContentView
  }
  tranferred: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}}]}
  ) {
    ...manageArtworkContentView
  }
  editions: Edition(
    where: {Artwork: {_and: [$extra_where, {edition_type: {_in: [STANDALONE_EDITION, ARTWORK_EDITION]}, artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}]}, deleted_at: {_is_null: true}}
  ) {
    ...manageArtworkEdition
  }
}
    ${ManageArtworkContentViewFragmentDoc}
${ManageArtworkEditionFragmentDoc}`;

/**
 * __useGetManageArtAllGalleryArtworksViewQuery__
 *
 * To run a query within a React component, call `useGetManageArtAllGalleryArtworksViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtAllGalleryArtworksViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtAllGalleryArtworksViewQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtAllGalleryArtworksViewQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtAllGalleryArtworksViewQuery, Types.GetManageArtAllGalleryArtworksViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtAllGalleryArtworksViewQuery, Types.GetManageArtAllGalleryArtworksViewQueryVariables>(GetManageArtAllGalleryArtworksViewDocument, options);
      }
export function useGetManageArtAllGalleryArtworksViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtAllGalleryArtworksViewQuery, Types.GetManageArtAllGalleryArtworksViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtAllGalleryArtworksViewQuery, Types.GetManageArtAllGalleryArtworksViewQueryVariables>(GetManageArtAllGalleryArtworksViewDocument, options);
        }
export type GetManageArtAllGalleryArtworksViewQueryHookResult = ReturnType<typeof useGetManageArtAllGalleryArtworksViewQuery>;
export type GetManageArtAllGalleryArtworksViewLazyQueryHookResult = ReturnType<typeof useGetManageArtAllGalleryArtworksViewLazyQuery>;
export type GetManageArtAllGalleryArtworksViewQueryResult = Apollo.QueryResult<Types.GetManageArtAllGalleryArtworksViewQuery, Types.GetManageArtAllGalleryArtworksViewQueryVariables>;
export const GetManageArtAvailableGalleryArtworksDocument = gql`
    query getManageArtAvailableGalleryArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  transferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_available_sale: {_eq: true}}, {is_transaction_locked: {_eq: false}}, {_or: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}
  ) {
    ...manageArtworkContent
  }
}
    ${ManageArtworkContentFragmentDoc}`;

/**
 * __useGetManageArtAvailableGalleryArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtAvailableGalleryArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtAvailableGalleryArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtAvailableGalleryArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtAvailableGalleryArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtAvailableGalleryArtworksQuery, Types.GetManageArtAvailableGalleryArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtAvailableGalleryArtworksQuery, Types.GetManageArtAvailableGalleryArtworksQueryVariables>(GetManageArtAvailableGalleryArtworksDocument, options);
      }
export function useGetManageArtAvailableGalleryArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtAvailableGalleryArtworksQuery, Types.GetManageArtAvailableGalleryArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtAvailableGalleryArtworksQuery, Types.GetManageArtAvailableGalleryArtworksQueryVariables>(GetManageArtAvailableGalleryArtworksDocument, options);
        }
export type GetManageArtAvailableGalleryArtworksQueryHookResult = ReturnType<typeof useGetManageArtAvailableGalleryArtworksQuery>;
export type GetManageArtAvailableGalleryArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtAvailableGalleryArtworksLazyQuery>;
export type GetManageArtAvailableGalleryArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtAvailableGalleryArtworksQuery, Types.GetManageArtAvailableGalleryArtworksQueryVariables>;
export const GetManageArtUnAvailableGalleryArtworksDocument = gql`
    query getManageArtUnAvailableGalleryArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  transferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_available_sale: {_eq: false}}, {is_transaction_locked: {_eq: false}}, {_or: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}
  ) {
    ...manageArtworkContent
  }
}
    ${ManageArtworkContentFragmentDoc}`;

/**
 * __useGetManageArtUnAvailableGalleryArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtUnAvailableGalleryArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtUnAvailableGalleryArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtUnAvailableGalleryArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtUnAvailableGalleryArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtUnAvailableGalleryArtworksQuery, Types.GetManageArtUnAvailableGalleryArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtUnAvailableGalleryArtworksQuery, Types.GetManageArtUnAvailableGalleryArtworksQueryVariables>(GetManageArtUnAvailableGalleryArtworksDocument, options);
      }
export function useGetManageArtUnAvailableGalleryArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtUnAvailableGalleryArtworksQuery, Types.GetManageArtUnAvailableGalleryArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtUnAvailableGalleryArtworksQuery, Types.GetManageArtUnAvailableGalleryArtworksQueryVariables>(GetManageArtUnAvailableGalleryArtworksDocument, options);
        }
export type GetManageArtUnAvailableGalleryArtworksQueryHookResult = ReturnType<typeof useGetManageArtUnAvailableGalleryArtworksQuery>;
export type GetManageArtUnAvailableGalleryArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtUnAvailableGalleryArtworksLazyQuery>;
export type GetManageArtUnAvailableGalleryArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtUnAvailableGalleryArtworksQuery, Types.GetManageArtUnAvailableGalleryArtworksQueryVariables>;
export const GetManageArtTransferredGalleryArtworksDocument = gql`
    query getManageArtTransferredGalleryArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  transferring: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: true}}, {_or: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_and: [{artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {_not: {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}}]}]}]}
  ) {
    ...manageArtworkContent
  }
  tranferred: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], _and: [{artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_neq: $contextId}}}}, {artwork_to_collections: {Collection: {type: {_eq: MANAGED}, owner_context_id: {_eq: $contextId}}}}, {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}}]}]}
  ) {
    ...manageArtworkContent
  }
}
    ${ManageArtworkContentFragmentDoc}`;

/**
 * __useGetManageArtTransferredGalleryArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtTransferredGalleryArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtTransferredGalleryArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtTransferredGalleryArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtTransferredGalleryArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtTransferredGalleryArtworksQuery, Types.GetManageArtTransferredGalleryArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtTransferredGalleryArtworksQuery, Types.GetManageArtTransferredGalleryArtworksQueryVariables>(GetManageArtTransferredGalleryArtworksDocument, options);
      }
export function useGetManageArtTransferredGalleryArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtTransferredGalleryArtworksQuery, Types.GetManageArtTransferredGalleryArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtTransferredGalleryArtworksQuery, Types.GetManageArtTransferredGalleryArtworksQueryVariables>(GetManageArtTransferredGalleryArtworksDocument, options);
        }
export type GetManageArtTransferredGalleryArtworksQueryHookResult = ReturnType<typeof useGetManageArtTransferredGalleryArtworksQuery>;
export type GetManageArtTransferredGalleryArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtTransferredGalleryArtworksLazyQuery>;
export type GetManageArtTransferredGalleryArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtTransferredGalleryArtworksQuery, Types.GetManageArtTransferredGalleryArtworksQueryVariables>;
export const GetManageArtTransferredCollectorArtworksDocument = gql`
    query getManageArtTransferredCollectorArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  transferred: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], _not: {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}}, {transaction_histories: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}, _or: [{context_seller_id: {_eq: $contextId}}, {context_buyer_id: {_eq: $contextId}}]}}]}
  ) {
    ...manageArtworkContent
  }
  prints: Print(
    where: {_not: {print_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, transaction_histories: {status: {_eq: ACCEPTED}, _or: [{context_seller_id: {_eq: $contextId}}, {context_buyer_id: {_eq: $contextId}}]}}
  ) {
    ...manageArtworkPrint
  }
}
    ${ManageArtworkContentFragmentDoc}
${ManageArtworkPrintFragmentDoc}`;

/**
 * __useGetManageArtTransferredCollectorArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtTransferredCollectorArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtTransferredCollectorArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtTransferredCollectorArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtTransferredCollectorArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtTransferredCollectorArtworksQuery, Types.GetManageArtTransferredCollectorArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtTransferredCollectorArtworksQuery, Types.GetManageArtTransferredCollectorArtworksQueryVariables>(GetManageArtTransferredCollectorArtworksDocument, options);
      }
export function useGetManageArtTransferredCollectorArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtTransferredCollectorArtworksQuery, Types.GetManageArtTransferredCollectorArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtTransferredCollectorArtworksQuery, Types.GetManageArtTransferredCollectorArtworksQueryVariables>(GetManageArtTransferredCollectorArtworksDocument, options);
        }
export type GetManageArtTransferredCollectorArtworksQueryHookResult = ReturnType<typeof useGetManageArtTransferredCollectorArtworksQuery>;
export type GetManageArtTransferredCollectorArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtTransferredCollectorArtworksLazyQuery>;
export type GetManageArtTransferredCollectorArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtTransferredCollectorArtworksQuery, Types.GetManageArtTransferredCollectorArtworksQueryVariables>;
export const GetManageArtOwnedCollectorArtworksDocument = gql`
    query getManageArtOwnedCollectorArtworks($extra_where: Artwork_bool_exp!, $contextId: bigint!) {
  owned: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}], artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  prints: Print(
    where: {print_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}
  ) {
    ...manageArtworkPrint
  }
}
    ${ManageArtworkContentFragmentDoc}
${ManageArtworkPrintFragmentDoc}`;

/**
 * __useGetManageArtOwnedCollectorArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtOwnedCollectorArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtOwnedCollectorArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtOwnedCollectorArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtOwnedCollectorArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtOwnedCollectorArtworksQuery, Types.GetManageArtOwnedCollectorArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtOwnedCollectorArtworksQuery, Types.GetManageArtOwnedCollectorArtworksQueryVariables>(GetManageArtOwnedCollectorArtworksDocument, options);
      }
export function useGetManageArtOwnedCollectorArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtOwnedCollectorArtworksQuery, Types.GetManageArtOwnedCollectorArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtOwnedCollectorArtworksQuery, Types.GetManageArtOwnedCollectorArtworksQueryVariables>(GetManageArtOwnedCollectorArtworksDocument, options);
        }
export type GetManageArtOwnedCollectorArtworksQueryHookResult = ReturnType<typeof useGetManageArtOwnedCollectorArtworksQuery>;
export type GetManageArtOwnedCollectorArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtOwnedCollectorArtworksLazyQuery>;
export type GetManageArtOwnedCollectorArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtOwnedCollectorArtworksQuery, Types.GetManageArtOwnedCollectorArtworksQueryVariables>;
export const GetPrintTransferStatusDocument = gql`
    query GetPrintTransferStatus($id: bigint!) {
  Print_by_pk(id: $id) {
    id
    status
    is_transaction_locked
    print_to_collections(where: {Collection: {type: {_in: [CREATED, OWNED]}}}) {
      Collection {
        type
        owner_context_id
      }
    }
    Edition {
      publish_date
    }
    transaction_histories(where: {status: {_eq: ACCEPTED}}) {
      status
    }
  }
}
    `;

/**
 * __useGetPrintTransferStatusQuery__
 *
 * To run a query within a React component, call `useGetPrintTransferStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintTransferStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintTransferStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrintTransferStatusQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPrintTransferStatusQuery, Types.GetPrintTransferStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPrintTransferStatusQuery, Types.GetPrintTransferStatusQueryVariables>(GetPrintTransferStatusDocument, options);
      }
export function useGetPrintTransferStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPrintTransferStatusQuery, Types.GetPrintTransferStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPrintTransferStatusQuery, Types.GetPrintTransferStatusQueryVariables>(GetPrintTransferStatusDocument, options);
        }
export type GetPrintTransferStatusQueryHookResult = ReturnType<typeof useGetPrintTransferStatusQuery>;
export type GetPrintTransferStatusLazyQueryHookResult = ReturnType<typeof useGetPrintTransferStatusLazyQuery>;
export type GetPrintTransferStatusQueryResult = Apollo.QueryResult<Types.GetPrintTransferStatusQuery, Types.GetPrintTransferStatusQueryVariables>;
export const GetShopArtworkIdsDocument = gql`
    query getShopArtworkIds($artistContextId: bigint!) {
  artworks: artwork_shop_listing(
    where: {artist_context_id: {_eq: $artistContextId}, edition_type: {_in: ["UNIQUE"]}}
  ) {
    id: artwork_id
  }
}
    `;

/**
 * __useGetShopArtworkIdsQuery__
 *
 * To run a query within a React component, call `useGetShopArtworkIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopArtworkIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopArtworkIdsQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetShopArtworkIdsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShopArtworkIdsQuery, Types.GetShopArtworkIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShopArtworkIdsQuery, Types.GetShopArtworkIdsQueryVariables>(GetShopArtworkIdsDocument, options);
      }
export function useGetShopArtworkIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShopArtworkIdsQuery, Types.GetShopArtworkIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShopArtworkIdsQuery, Types.GetShopArtworkIdsQueryVariables>(GetShopArtworkIdsDocument, options);
        }
export type GetShopArtworkIdsQueryHookResult = ReturnType<typeof useGetShopArtworkIdsQuery>;
export type GetShopArtworkIdsLazyQueryHookResult = ReturnType<typeof useGetShopArtworkIdsLazyQuery>;
export type GetShopArtworkIdsQueryResult = Apollo.QueryResult<Types.GetShopArtworkIdsQuery, Types.GetShopArtworkIdsQueryVariables>;
export const GetManageArtArtworksDocument = gql`
    query getManageArtArtworks($artworkIds: [bigint!]!, $contextId: bigint!) {
  transferable: Artwork(where: {id: {_in: $artworkIds}}) {
    ...manageArtworkContent
  }
}
    ${ManageArtworkContentFragmentDoc}`;

/**
 * __useGetManageArtArtworksQuery__
 *
 * To run a query within a React component, call `useGetManageArtArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManageArtArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManageArtArtworksQuery({
 *   variables: {
 *      artworkIds: // value for 'artworkIds'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetManageArtArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetManageArtArtworksQuery, Types.GetManageArtArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetManageArtArtworksQuery, Types.GetManageArtArtworksQueryVariables>(GetManageArtArtworksDocument, options);
      }
export function useGetManageArtArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetManageArtArtworksQuery, Types.GetManageArtArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetManageArtArtworksQuery, Types.GetManageArtArtworksQueryVariables>(GetManageArtArtworksDocument, options);
        }
export type GetManageArtArtworksQueryHookResult = ReturnType<typeof useGetManageArtArtworksQuery>;
export type GetManageArtArtworksLazyQueryHookResult = ReturnType<typeof useGetManageArtArtworksLazyQuery>;
export type GetManageArtArtworksQueryResult = Apollo.QueryResult<Types.GetManageArtArtworksQuery, Types.GetManageArtArtworksQueryVariables>;
export const GetArtworksForSelectOriginalArtworkDocument = gql`
    query getArtworksForSelectOriginalArtwork($artistContextId: bigint!, $availability: Boolean_comparison_exp = {}, $public: Boolean_comparison_exp = {}) {
  Artwork(
    where: {artist_context_id: {_eq: $artistContextId}, is_public: $public, is_available_sale: $availability, published_date: {_is_null: false}, _or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE, ARTWORK_EDITION]}}]}
  ) {
    id
    slug
    is_public
    is_available_sale
    thumbnail_metadata_id
    primary_image_metadata_id
    artwork_details {
      title
      creation_date_day
      creation_date_month
      creation_date_year
      width
      height
    }
  }
}
    `;

/**
 * __useGetArtworksForSelectOriginalArtworkQuery__
 *
 * To run a query within a React component, call `useGetArtworksForSelectOriginalArtworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworksForSelectOriginalArtworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworksForSelectOriginalArtworkQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      availability: // value for 'availability'
 *      public: // value for 'public'
 *   },
 * });
 */
export function useGetArtworksForSelectOriginalArtworkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworksForSelectOriginalArtworkQuery, Types.GetArtworksForSelectOriginalArtworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworksForSelectOriginalArtworkQuery, Types.GetArtworksForSelectOriginalArtworkQueryVariables>(GetArtworksForSelectOriginalArtworkDocument, options);
      }
export function useGetArtworksForSelectOriginalArtworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworksForSelectOriginalArtworkQuery, Types.GetArtworksForSelectOriginalArtworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworksForSelectOriginalArtworkQuery, Types.GetArtworksForSelectOriginalArtworkQueryVariables>(GetArtworksForSelectOriginalArtworkDocument, options);
        }
export type GetArtworksForSelectOriginalArtworkQueryHookResult = ReturnType<typeof useGetArtworksForSelectOriginalArtworkQuery>;
export type GetArtworksForSelectOriginalArtworkLazyQueryHookResult = ReturnType<typeof useGetArtworksForSelectOriginalArtworkLazyQuery>;
export type GetArtworksForSelectOriginalArtworkQueryResult = Apollo.QueryResult<Types.GetArtworksForSelectOriginalArtworkQuery, Types.GetArtworksForSelectOriginalArtworkQueryVariables>;
export const InsertEditionTypeDocument = gql`
    mutation InsertEditionType($object: Edition_Type_insert_input!) {
  insert_Edition_Type_one(object: $object) {
    id
    value
    creator_context_id
  }
}
    `;
export type InsertEditionTypeMutationFn = Apollo.MutationFunction<Types.InsertEditionTypeMutation, Types.InsertEditionTypeMutationVariables>;

/**
 * __useInsertEditionTypeMutation__
 *
 * To run a mutation, you first call `useInsertEditionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEditionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEditionTypeMutation, { data, loading, error }] = useInsertEditionTypeMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertEditionTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertEditionTypeMutation, Types.InsertEditionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertEditionTypeMutation, Types.InsertEditionTypeMutationVariables>(InsertEditionTypeDocument, options);
      }
export type InsertEditionTypeMutationHookResult = ReturnType<typeof useInsertEditionTypeMutation>;
export type InsertEditionTypeMutationResult = Apollo.MutationResult<Types.InsertEditionTypeMutation>;
export type InsertEditionTypeMutationOptions = Apollo.BaseMutationOptions<Types.InsertEditionTypeMutation, Types.InsertEditionTypeMutationVariables>;
export const GetEditionTypesDocument = gql`
    query GetEditionTypes($context_id: bigint!) {
  Edition_Type(
    where: {_or: [{creator_context_id: {_eq: $context_id}}, {creator_context_id: {_is_null: true}}]}
  ) {
    id
    value
    creator_context_id
    deleted_at
  }
}
    `;

/**
 * __useGetEditionTypesQuery__
 *
 * To run a query within a React component, call `useGetEditionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionTypesQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetEditionTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionTypesQuery, Types.GetEditionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionTypesQuery, Types.GetEditionTypesQueryVariables>(GetEditionTypesDocument, options);
      }
export function useGetEditionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionTypesQuery, Types.GetEditionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionTypesQuery, Types.GetEditionTypesQueryVariables>(GetEditionTypesDocument, options);
        }
export type GetEditionTypesQueryHookResult = ReturnType<typeof useGetEditionTypesQuery>;
export type GetEditionTypesLazyQueryHookResult = ReturnType<typeof useGetEditionTypesLazyQuery>;
export type GetEditionTypesQueryResult = Apollo.QueryResult<Types.GetEditionTypesQuery, Types.GetEditionTypesQueryVariables>;
export const UpdateEditionTypeDocument = gql`
    mutation UpdateEditionType($id: bigint!, $deleted_at: timestamptz = "now()") {
  update_Edition_Type_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: $deleted_at}
  ) {
    id
    deleted_at
  }
}
    `;
export type UpdateEditionTypeMutationFn = Apollo.MutationFunction<Types.UpdateEditionTypeMutation, Types.UpdateEditionTypeMutationVariables>;

/**
 * __useUpdateEditionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateEditionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditionTypeMutation, { data, loading, error }] = useUpdateEditionTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useUpdateEditionTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEditionTypeMutation, Types.UpdateEditionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEditionTypeMutation, Types.UpdateEditionTypeMutationVariables>(UpdateEditionTypeDocument, options);
      }
export type UpdateEditionTypeMutationHookResult = ReturnType<typeof useUpdateEditionTypeMutation>;
export type UpdateEditionTypeMutationResult = Apollo.MutationResult<Types.UpdateEditionTypeMutation>;
export type UpdateEditionTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEditionTypeMutation, Types.UpdateEditionTypeMutationVariables>;
export const GetPrintTechniquesDocument = gql`
    query GetPrintTechniques {
  ENUM_print_technique {
    id
    description
  }
}
    `;

/**
 * __useGetPrintTechniquesQuery__
 *
 * To run a query within a React component, call `useGetPrintTechniquesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintTechniquesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintTechniquesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrintTechniquesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPrintTechniquesQuery, Types.GetPrintTechniquesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPrintTechniquesQuery, Types.GetPrintTechniquesQueryVariables>(GetPrintTechniquesDocument, options);
      }
export function useGetPrintTechniquesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPrintTechniquesQuery, Types.GetPrintTechniquesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPrintTechniquesQuery, Types.GetPrintTechniquesQueryVariables>(GetPrintTechniquesDocument, options);
        }
export type GetPrintTechniquesQueryHookResult = ReturnType<typeof useGetPrintTechniquesQuery>;
export type GetPrintTechniquesLazyQueryHookResult = ReturnType<typeof useGetPrintTechniquesLazyQuery>;
export type GetPrintTechniquesQueryResult = Apollo.QueryResult<Types.GetPrintTechniquesQuery, Types.GetPrintTechniquesQueryVariables>;
export const GetEditionDataForDetailsStepDocument = gql`
    query GetEditionDataForDetailsStep($id: bigint!) {
  Edition_by_pk(id: $id) {
    title
    Artwork {
      id
      artwork_details {
        title
      }
      ...ArtworkTags
    }
    Edition_By_Sizes {
      id
      print_size
      height
      width
      measuring_unit
      technique
      materials
      print_date_day
      print_date_month
      print_date_year
      Edition_By_Types(where: {Edition_Type: {deleted_at: {_is_null: true}}}) {
        print_amount
        Edition_Type {
          value
        }
      }
    }
  }
}
    ${ArtworkTagsFragmentDoc}`;

/**
 * __useGetEditionDataForDetailsStepQuery__
 *
 * To run a query within a React component, call `useGetEditionDataForDetailsStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionDataForDetailsStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionDataForDetailsStepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditionDataForDetailsStepQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionDataForDetailsStepQuery, Types.GetEditionDataForDetailsStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionDataForDetailsStepQuery, Types.GetEditionDataForDetailsStepQueryVariables>(GetEditionDataForDetailsStepDocument, options);
      }
export function useGetEditionDataForDetailsStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionDataForDetailsStepQuery, Types.GetEditionDataForDetailsStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionDataForDetailsStepQuery, Types.GetEditionDataForDetailsStepQueryVariables>(GetEditionDataForDetailsStepDocument, options);
        }
export type GetEditionDataForDetailsStepQueryHookResult = ReturnType<typeof useGetEditionDataForDetailsStepQuery>;
export type GetEditionDataForDetailsStepLazyQueryHookResult = ReturnType<typeof useGetEditionDataForDetailsStepLazyQuery>;
export type GetEditionDataForDetailsStepQueryResult = Apollo.QueryResult<Types.GetEditionDataForDetailsStepQuery, Types.GetEditionDataForDetailsStepQueryVariables>;
export const UpsertEditionBySizeDocument = gql`
    mutation UpsertEditionBySize($edition_id: Int!, $print_size: String!, $measuring_unit: String, $height: Float, $width: Float, $technique: String, $materials: String, $print_date_day: Int, $print_date_month: Int, $print_date_year: Int, $status: String, $price: Float, $currency: String, $is_price_public: Boolean) {
  artistUpsertEditionBySize(
    edition_id: $edition_id
    print_size: $print_size
    measuring_unit: $measuring_unit
    height: $height
    width: $width
    technique: $technique
    materials: $materials
    print_date_day: $print_date_day
    print_date_month: $print_date_month
    print_date_year: $print_date_year
    status: $status
    price: $price
    currency: $currency
    is_price_public: $is_price_public
  ) {
    id
  }
}
    `;
export type UpsertEditionBySizeMutationFn = Apollo.MutationFunction<Types.UpsertEditionBySizeMutation, Types.UpsertEditionBySizeMutationVariables>;

/**
 * __useUpsertEditionBySizeMutation__
 *
 * To run a mutation, you first call `useUpsertEditionBySizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEditionBySizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEditionBySizeMutation, { data, loading, error }] = useUpsertEditionBySizeMutation({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *      print_size: // value for 'print_size'
 *      measuring_unit: // value for 'measuring_unit'
 *      height: // value for 'height'
 *      width: // value for 'width'
 *      technique: // value for 'technique'
 *      materials: // value for 'materials'
 *      print_date_day: // value for 'print_date_day'
 *      print_date_month: // value for 'print_date_month'
 *      print_date_year: // value for 'print_date_year'
 *      status: // value for 'status'
 *      price: // value for 'price'
 *      currency: // value for 'currency'
 *      is_price_public: // value for 'is_price_public'
 *   },
 * });
 */
export function useUpsertEditionBySizeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertEditionBySizeMutation, Types.UpsertEditionBySizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertEditionBySizeMutation, Types.UpsertEditionBySizeMutationVariables>(UpsertEditionBySizeDocument, options);
      }
export type UpsertEditionBySizeMutationHookResult = ReturnType<typeof useUpsertEditionBySizeMutation>;
export type UpsertEditionBySizeMutationResult = Apollo.MutationResult<Types.UpsertEditionBySizeMutation>;
export type UpsertEditionBySizeMutationOptions = Apollo.BaseMutationOptions<Types.UpsertEditionBySizeMutation, Types.UpsertEditionBySizeMutationVariables>;
export const SoftDeleteEditionDocument = gql`
    mutation SoftDeleteEdition($edition_id: Int!) {
  artistUpsertEdition(deleted_at: "now()", edition_id: $edition_id) {
    id
    deleted_at
  }
}
    `;
export type SoftDeleteEditionMutationFn = Apollo.MutationFunction<Types.SoftDeleteEditionMutation, Types.SoftDeleteEditionMutationVariables>;

/**
 * __useSoftDeleteEditionMutation__
 *
 * To run a mutation, you first call `useSoftDeleteEditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteEditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteEditionMutation, { data, loading, error }] = useSoftDeleteEditionMutation({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *   },
 * });
 */
export function useSoftDeleteEditionMutation(baseOptions?: Apollo.MutationHookOptions<Types.SoftDeleteEditionMutation, Types.SoftDeleteEditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SoftDeleteEditionMutation, Types.SoftDeleteEditionMutationVariables>(SoftDeleteEditionDocument, options);
      }
export type SoftDeleteEditionMutationHookResult = ReturnType<typeof useSoftDeleteEditionMutation>;
export type SoftDeleteEditionMutationResult = Apollo.MutationResult<Types.SoftDeleteEditionMutation>;
export type SoftDeleteEditionMutationOptions = Apollo.BaseMutationOptions<Types.SoftDeleteEditionMutation, Types.SoftDeleteEditionMutationVariables>;
export const UpsertEditionDocument = gql`
    mutation UpsertEdition($artwork_id: Int, $edition_id: Int, $currency: String, $title: String, $price: Float, $add_edition_flow_step: String, $is_available_sale: Boolean, $publish_date: String, $is_public: Boolean, $is_price_public: Boolean) {
  artistUpsertEdition(
    artwork_id: $artwork_id
    title: $title
    currency: $currency
    edition_id: $edition_id
    price: $price
    add_edition_flow_step: $add_edition_flow_step
    is_available_sale: $is_available_sale
    scope: "SHOWCASE"
    publish_date: $publish_date
    is_public: $is_public
    is_price_public: $is_price_public
  ) {
    id
    artwork_id
    title
    currency
    price
    is_available_sale
    add_edition_flow_step
    is_public
    Artwork {
      edition_type
    }
  }
}
    `;
export type UpsertEditionMutationFn = Apollo.MutationFunction<Types.UpsertEditionMutation, Types.UpsertEditionMutationVariables>;

/**
 * __useUpsertEditionMutation__
 *
 * To run a mutation, you first call `useUpsertEditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEditionMutation, { data, loading, error }] = useUpsertEditionMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      edition_id: // value for 'edition_id'
 *      currency: // value for 'currency'
 *      title: // value for 'title'
 *      price: // value for 'price'
 *      add_edition_flow_step: // value for 'add_edition_flow_step'
 *      is_available_sale: // value for 'is_available_sale'
 *      publish_date: // value for 'publish_date'
 *      is_public: // value for 'is_public'
 *      is_price_public: // value for 'is_price_public'
 *   },
 * });
 */
export function useUpsertEditionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertEditionMutation, Types.UpsertEditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertEditionMutation, Types.UpsertEditionMutationVariables>(UpsertEditionDocument, options);
      }
export type UpsertEditionMutationHookResult = ReturnType<typeof useUpsertEditionMutation>;
export type UpsertEditionMutationResult = Apollo.MutationResult<Types.UpsertEditionMutation>;
export type UpsertEditionMutationOptions = Apollo.BaseMutationOptions<Types.UpsertEditionMutation, Types.UpsertEditionMutationVariables>;
export const UpdateEditionTitleDocument = gql`
    mutation UpdateEditionTitle($edition_id: Int, $title: String!) {
  edition: artistUpsertEdition(edition_id: $edition_id, title: $title) {
    id
    title
  }
}
    `;
export type UpdateEditionTitleMutationFn = Apollo.MutationFunction<Types.UpdateEditionTitleMutation, Types.UpdateEditionTitleMutationVariables>;

/**
 * __useUpdateEditionTitleMutation__
 *
 * To run a mutation, you first call `useUpdateEditionTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditionTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditionTitleMutation, { data, loading, error }] = useUpdateEditionTitleMutation({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateEditionTitleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEditionTitleMutation, Types.UpdateEditionTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEditionTitleMutation, Types.UpdateEditionTitleMutationVariables>(UpdateEditionTitleDocument, options);
      }
export type UpdateEditionTitleMutationHookResult = ReturnType<typeof useUpdateEditionTitleMutation>;
export type UpdateEditionTitleMutationResult = Apollo.MutationResult<Types.UpdateEditionTitleMutation>;
export type UpdateEditionTitleMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEditionTitleMutation, Types.UpdateEditionTitleMutationVariables>;
export const UpdateStandaloneEditionArtworkTitleDocument = gql`
    mutation UpdateStandaloneEditionArtworkTitle($artwork_id: bigint!, $title: String!) {
  artworkDetails: update_Artwork_details(
    where: {artwork: {id: {_eq: $artwork_id}, edition_type: {_eq: STANDALONE_EDITION}}}
    _set: {title: $title}
  ) {
    returning {
      id
      title
    }
  }
}
    `;
export type UpdateStandaloneEditionArtworkTitleMutationFn = Apollo.MutationFunction<Types.UpdateStandaloneEditionArtworkTitleMutation, Types.UpdateStandaloneEditionArtworkTitleMutationVariables>;

/**
 * __useUpdateStandaloneEditionArtworkTitleMutation__
 *
 * To run a mutation, you first call `useUpdateStandaloneEditionArtworkTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStandaloneEditionArtworkTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStandaloneEditionArtworkTitleMutation, { data, loading, error }] = useUpdateStandaloneEditionArtworkTitleMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateStandaloneEditionArtworkTitleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStandaloneEditionArtworkTitleMutation, Types.UpdateStandaloneEditionArtworkTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStandaloneEditionArtworkTitleMutation, Types.UpdateStandaloneEditionArtworkTitleMutationVariables>(UpdateStandaloneEditionArtworkTitleDocument, options);
      }
export type UpdateStandaloneEditionArtworkTitleMutationHookResult = ReturnType<typeof useUpdateStandaloneEditionArtworkTitleMutation>;
export type UpdateStandaloneEditionArtworkTitleMutationResult = Apollo.MutationResult<Types.UpdateStandaloneEditionArtworkTitleMutation>;
export type UpdateStandaloneEditionArtworkTitleMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStandaloneEditionArtworkTitleMutation, Types.UpdateStandaloneEditionArtworkTitleMutationVariables>;
export const CreateEditionBySizeDocument = gql`
    mutation CreateEditionBySize($edition_id: Int!, $print_size: String!) {
  artistUpsertEditionBySize(edition_id: $edition_id, print_size: $print_size) {
    id
  }
}
    `;
export type CreateEditionBySizeMutationFn = Apollo.MutationFunction<Types.CreateEditionBySizeMutation, Types.CreateEditionBySizeMutationVariables>;

/**
 * __useCreateEditionBySizeMutation__
 *
 * To run a mutation, you first call `useCreateEditionBySizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditionBySizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditionBySizeMutation, { data, loading, error }] = useCreateEditionBySizeMutation({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *      print_size: // value for 'print_size'
 *   },
 * });
 */
export function useCreateEditionBySizeMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEditionBySizeMutation, Types.CreateEditionBySizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateEditionBySizeMutation, Types.CreateEditionBySizeMutationVariables>(CreateEditionBySizeDocument, options);
      }
export type CreateEditionBySizeMutationHookResult = ReturnType<typeof useCreateEditionBySizeMutation>;
export type CreateEditionBySizeMutationResult = Apollo.MutationResult<Types.CreateEditionBySizeMutation>;
export type CreateEditionBySizeMutationOptions = Apollo.BaseMutationOptions<Types.CreateEditionBySizeMutation, Types.CreateEditionBySizeMutationVariables>;
export const UpsertEditionByTypeDocument = gql`
    mutation UpsertEditionByType($edition_by_type_id: Int, $edition_by_size_id: Int, $edition_type_id: Int, $print_amount: Int, $status: String) {
  artistUpsertEditionByType(
    edition_by_type_id: $edition_by_type_id
    edition_by_size_id: $edition_by_size_id
    edition_type_id: $edition_type_id
    print_amount: $print_amount
    status: $status
  ) {
    id
  }
}
    `;
export type UpsertEditionByTypeMutationFn = Apollo.MutationFunction<Types.UpsertEditionByTypeMutation, Types.UpsertEditionByTypeMutationVariables>;

/**
 * __useUpsertEditionByTypeMutation__
 *
 * To run a mutation, you first call `useUpsertEditionByTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEditionByTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEditionByTypeMutation, { data, loading, error }] = useUpsertEditionByTypeMutation({
 *   variables: {
 *      edition_by_type_id: // value for 'edition_by_type_id'
 *      edition_by_size_id: // value for 'edition_by_size_id'
 *      edition_type_id: // value for 'edition_type_id'
 *      print_amount: // value for 'print_amount'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpsertEditionByTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertEditionByTypeMutation, Types.UpsertEditionByTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertEditionByTypeMutation, Types.UpsertEditionByTypeMutationVariables>(UpsertEditionByTypeDocument, options);
      }
export type UpsertEditionByTypeMutationHookResult = ReturnType<typeof useUpsertEditionByTypeMutation>;
export type UpsertEditionByTypeMutationResult = Apollo.MutationResult<Types.UpsertEditionByTypeMutation>;
export type UpsertEditionByTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpsertEditionByTypeMutation, Types.UpsertEditionByTypeMutationVariables>;
export const RemoveEditionByTypeDocument = gql`
    mutation RemoveEditionByType($edition_by_type_id: Int!, $edition_by_size_id: Int!, $edition_type_id: Int) {
  removeEditionByType(
    edition_by_type_id: $edition_by_type_id
    edition_by_size_id: $edition_by_size_id
    edition_type_id: $edition_type_id
  )
}
    `;
export type RemoveEditionByTypeMutationFn = Apollo.MutationFunction<Types.RemoveEditionByTypeMutation, Types.RemoveEditionByTypeMutationVariables>;

/**
 * __useRemoveEditionByTypeMutation__
 *
 * To run a mutation, you first call `useRemoveEditionByTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEditionByTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEditionByTypeMutation, { data, loading, error }] = useRemoveEditionByTypeMutation({
 *   variables: {
 *      edition_by_type_id: // value for 'edition_by_type_id'
 *      edition_by_size_id: // value for 'edition_by_size_id'
 *      edition_type_id: // value for 'edition_type_id'
 *   },
 * });
 */
export function useRemoveEditionByTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveEditionByTypeMutation, Types.RemoveEditionByTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveEditionByTypeMutation, Types.RemoveEditionByTypeMutationVariables>(RemoveEditionByTypeDocument, options);
      }
export type RemoveEditionByTypeMutationHookResult = ReturnType<typeof useRemoveEditionByTypeMutation>;
export type RemoveEditionByTypeMutationResult = Apollo.MutationResult<Types.RemoveEditionByTypeMutation>;
export type RemoveEditionByTypeMutationOptions = Apollo.BaseMutationOptions<Types.RemoveEditionByTypeMutation, Types.RemoveEditionByTypeMutationVariables>;
export const RemoveEditionBySizeDocument = gql`
    mutation RemoveEditionBySize($edition_id: Int!, $print_size: String!) {
  removeEditionBySize(edition_id: $edition_id, print_size: $print_size)
}
    `;
export type RemoveEditionBySizeMutationFn = Apollo.MutationFunction<Types.RemoveEditionBySizeMutation, Types.RemoveEditionBySizeMutationVariables>;

/**
 * __useRemoveEditionBySizeMutation__
 *
 * To run a mutation, you first call `useRemoveEditionBySizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEditionBySizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEditionBySizeMutation, { data, loading, error }] = useRemoveEditionBySizeMutation({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *      print_size: // value for 'print_size'
 *   },
 * });
 */
export function useRemoveEditionBySizeMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveEditionBySizeMutation, Types.RemoveEditionBySizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveEditionBySizeMutation, Types.RemoveEditionBySizeMutationVariables>(RemoveEditionBySizeDocument, options);
      }
export type RemoveEditionBySizeMutationHookResult = ReturnType<typeof useRemoveEditionBySizeMutation>;
export type RemoveEditionBySizeMutationResult = Apollo.MutationResult<Types.RemoveEditionBySizeMutation>;
export type RemoveEditionBySizeMutationOptions = Apollo.BaseMutationOptions<Types.RemoveEditionBySizeMutation, Types.RemoveEditionBySizeMutationVariables>;
export const GetEditionSizesAndTypesDocument = gql`
    query GetEditionSizesAndTypes($edition_id: bigint!) {
  Edition_by_pk(id: $edition_id) {
    Artwork {
      Editions {
        Edition_By_Sizes {
          id
          print_size
        }
      }
    }
    Edition_By_Sizes {
      id
      print_size
      Edition_By_Types(where: {Edition_Type: {deleted_at: {_is_null: true}}}) {
        id
        Edition_Type {
          id
          value
          creator_context_id
          deleted_at
        }
      }
    }
  }
}
    `;

/**
 * __useGetEditionSizesAndTypesQuery__
 *
 * To run a query within a React component, call `useGetEditionSizesAndTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionSizesAndTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionSizesAndTypesQuery({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *   },
 * });
 */
export function useGetEditionSizesAndTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionSizesAndTypesQuery, Types.GetEditionSizesAndTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionSizesAndTypesQuery, Types.GetEditionSizesAndTypesQueryVariables>(GetEditionSizesAndTypesDocument, options);
      }
export function useGetEditionSizesAndTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionSizesAndTypesQuery, Types.GetEditionSizesAndTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionSizesAndTypesQuery, Types.GetEditionSizesAndTypesQueryVariables>(GetEditionSizesAndTypesDocument, options);
        }
export type GetEditionSizesAndTypesQueryHookResult = ReturnType<typeof useGetEditionSizesAndTypesQuery>;
export type GetEditionSizesAndTypesLazyQueryHookResult = ReturnType<typeof useGetEditionSizesAndTypesLazyQuery>;
export type GetEditionSizesAndTypesQueryResult = Apollo.QueryResult<Types.GetEditionSizesAndTypesQuery, Types.GetEditionSizesAndTypesQueryVariables>;
export const GetEditionImageryDocument = gql`
    query GetEditionImagery($id: bigint!) {
  Edition_by_pk(id: $id) {
    Artwork {
      ...ArtworkImagery
    }
  }
}
    ${ArtworkImageryFragmentDoc}`;

/**
 * __useGetEditionImageryQuery__
 *
 * To run a query within a React component, call `useGetEditionImageryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionImageryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionImageryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditionImageryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionImageryQuery, Types.GetEditionImageryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionImageryQuery, Types.GetEditionImageryQueryVariables>(GetEditionImageryDocument, options);
      }
export function useGetEditionImageryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionImageryQuery, Types.GetEditionImageryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionImageryQuery, Types.GetEditionImageryQueryVariables>(GetEditionImageryDocument, options);
        }
export type GetEditionImageryQueryHookResult = ReturnType<typeof useGetEditionImageryQuery>;
export type GetEditionImageryLazyQueryHookResult = ReturnType<typeof useGetEditionImageryLazyQuery>;
export type GetEditionImageryQueryResult = Apollo.QueryResult<Types.GetEditionImageryQuery, Types.GetEditionImageryQueryVariables>;
export const UpsertPrintDocument = gql`
    mutation UpsertPrint($edition_by_type_id: Int!, $print_id: Int, $print_number: Int, $print_price: Float!, $status: String!) {
  artistUpsertPrint(
    edition_by_type_id: $edition_by_type_id
    print_id: $print_id
    print_number: $print_number
    print_price: $print_price
    status: $status
  ) {
    id
  }
}
    `;
export type UpsertPrintMutationFn = Apollo.MutationFunction<Types.UpsertPrintMutation, Types.UpsertPrintMutationVariables>;

/**
 * __useUpsertPrintMutation__
 *
 * To run a mutation, you first call `useUpsertPrintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPrintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPrintMutation, { data, loading, error }] = useUpsertPrintMutation({
 *   variables: {
 *      edition_by_type_id: // value for 'edition_by_type_id'
 *      print_id: // value for 'print_id'
 *      print_number: // value for 'print_number'
 *      print_price: // value for 'print_price'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpsertPrintMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertPrintMutation, Types.UpsertPrintMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertPrintMutation, Types.UpsertPrintMutationVariables>(UpsertPrintDocument, options);
      }
export type UpsertPrintMutationHookResult = ReturnType<typeof useUpsertPrintMutation>;
export type UpsertPrintMutationResult = Apollo.MutationResult<Types.UpsertPrintMutation>;
export type UpsertPrintMutationOptions = Apollo.BaseMutationOptions<Types.UpsertPrintMutation, Types.UpsertPrintMutationVariables>;
export const GetEditionReviewStepDataDocument = gql`
    query GetEditionReviewStepData($id: bigint!) {
  Edition_by_pk(id: $id) {
    id
    currency
    price
    is_available_sale
    is_price_public
    title
    Edition_By_Sizes(where: {status: {_eq: COMPLETE}}) {
      id
      print_size
      width
      height
      measuring_unit
      print_date_year
      print_date_month
      print_date_day
      technique
      materials
      price
      currency
      isPricePublic: is_price_public
      Edition_By_Types(
        where: {status: {_eq: COMPLETE}, Edition_Type: {deleted_at: {_is_null: true}}}
      ) {
        id
        print_amount
        Edition_Type {
          id
          value
        }
        Prints(limit: 10, order_by: {print_number: asc}) {
          ...PrintDetails
        }
      }
    }
    Artwork_secondary_media(order_by: {order: asc}) {
      id
      order
      file_metadata_id
    }
    Artwork {
      ...ArtworkReviewData
    }
  }
}
    ${PrintDetailsFragmentDoc}
${ArtworkReviewDataFragmentDoc}`;

/**
 * __useGetEditionReviewStepDataQuery__
 *
 * To run a query within a React component, call `useGetEditionReviewStepDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionReviewStepDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionReviewStepDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditionReviewStepDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionReviewStepDataQuery, Types.GetEditionReviewStepDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionReviewStepDataQuery, Types.GetEditionReviewStepDataQueryVariables>(GetEditionReviewStepDataDocument, options);
      }
export function useGetEditionReviewStepDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionReviewStepDataQuery, Types.GetEditionReviewStepDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionReviewStepDataQuery, Types.GetEditionReviewStepDataQueryVariables>(GetEditionReviewStepDataDocument, options);
        }
export type GetEditionReviewStepDataQueryHookResult = ReturnType<typeof useGetEditionReviewStepDataQuery>;
export type GetEditionReviewStepDataLazyQueryHookResult = ReturnType<typeof useGetEditionReviewStepDataLazyQuery>;
export type GetEditionReviewStepDataQueryResult = Apollo.QueryResult<Types.GetEditionReviewStepDataQuery, Types.GetEditionReviewStepDataQueryVariables>;
export const UpdateArtworkEditionTypeDocument = gql`
    mutation UpdateArtworkEditionType($id: bigint!, $edition_type: ENUM_artwork_edition_type_enum!) {
  update_Artwork_by_pk(pk_columns: {id: $id}, _set: {edition_type: $edition_type}) {
    id
    edition_type
  }
}
    `;
export type UpdateArtworkEditionTypeMutationFn = Apollo.MutationFunction<Types.UpdateArtworkEditionTypeMutation, Types.UpdateArtworkEditionTypeMutationVariables>;

/**
 * __useUpdateArtworkEditionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkEditionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkEditionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkEditionTypeMutation, { data, loading, error }] = useUpdateArtworkEditionTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      edition_type: // value for 'edition_type'
 *   },
 * });
 */
export function useUpdateArtworkEditionTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkEditionTypeMutation, Types.UpdateArtworkEditionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkEditionTypeMutation, Types.UpdateArtworkEditionTypeMutationVariables>(UpdateArtworkEditionTypeDocument, options);
      }
export type UpdateArtworkEditionTypeMutationHookResult = ReturnType<typeof useUpdateArtworkEditionTypeMutation>;
export type UpdateArtworkEditionTypeMutationResult = Apollo.MutationResult<Types.UpdateArtworkEditionTypeMutation>;
export type UpdateArtworkEditionTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkEditionTypeMutation, Types.UpdateArtworkEditionTypeMutationVariables>;
export const GetEditionCommercialDocument = gql`
    query GetEditionCommercial($id: bigint!) {
  Edition_by_pk(id: $id) {
    price
    currency
    is_available_sale
    is_price_public
    Artwork {
      ...ArtworkCommercialDetails
    }
    Edition_By_Sizes(where: {status: {_eq: COMPLETE}}) {
      id
      print_size
      width
      height
      measuring_unit
      price
      currency
      is_price_public
      Edition_By_Types(where: {Edition_Type: {deleted_at: {_is_null: true}}}) {
        id
        status
        print_amount
        Edition_Type {
          id
          value
        }
        Prints(order_by: {print_number: asc}) {
          ...PrintDetails
        }
      }
    }
  }
}
    ${ArtworkCommercialDetailsFragmentDoc}
${PrintDetailsFragmentDoc}`;

/**
 * __useGetEditionCommercialQuery__
 *
 * To run a query within a React component, call `useGetEditionCommercialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionCommercialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionCommercialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditionCommercialQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionCommercialQuery, Types.GetEditionCommercialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionCommercialQuery, Types.GetEditionCommercialQueryVariables>(GetEditionCommercialDocument, options);
      }
export function useGetEditionCommercialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionCommercialQuery, Types.GetEditionCommercialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionCommercialQuery, Types.GetEditionCommercialQueryVariables>(GetEditionCommercialDocument, options);
        }
export type GetEditionCommercialQueryHookResult = ReturnType<typeof useGetEditionCommercialQuery>;
export type GetEditionCommercialLazyQueryHookResult = ReturnType<typeof useGetEditionCommercialLazyQuery>;
export type GetEditionCommercialQueryResult = Apollo.QueryResult<Types.GetEditionCommercialQuery, Types.GetEditionCommercialQueryVariables>;
export const GetEditionBySizeForArtworkDocument = gql`
    query GetEditionBySizeForArtwork($artworkId: bigint!) {
  Edition_By_Size(where: {Edition: {artwork_id: {_eq: $artworkId}}}) {
    id
    print_size
  }
}
    `;

/**
 * __useGetEditionBySizeForArtworkQuery__
 *
 * To run a query within a React component, call `useGetEditionBySizeForArtworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionBySizeForArtworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionBySizeForArtworkQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetEditionBySizeForArtworkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionBySizeForArtworkQuery, Types.GetEditionBySizeForArtworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionBySizeForArtworkQuery, Types.GetEditionBySizeForArtworkQueryVariables>(GetEditionBySizeForArtworkDocument, options);
      }
export function useGetEditionBySizeForArtworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionBySizeForArtworkQuery, Types.GetEditionBySizeForArtworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionBySizeForArtworkQuery, Types.GetEditionBySizeForArtworkQueryVariables>(GetEditionBySizeForArtworkDocument, options);
        }
export type GetEditionBySizeForArtworkQueryHookResult = ReturnType<typeof useGetEditionBySizeForArtworkQuery>;
export type GetEditionBySizeForArtworkLazyQueryHookResult = ReturnType<typeof useGetEditionBySizeForArtworkLazyQuery>;
export type GetEditionBySizeForArtworkQueryResult = Apollo.QueryResult<Types.GetEditionBySizeForArtworkQuery, Types.GetEditionBySizeForArtworkQueryVariables>;
export const PublishEditionDocument = gql`
    mutation PublishEdition($edition_id: Int!) {
  artistUpsertEdition(
    edition_id: $edition_id
    publish_date: "now()"
    is_public: true
  ) {
    id
  }
}
    `;
export type PublishEditionMutationFn = Apollo.MutationFunction<Types.PublishEditionMutation, Types.PublishEditionMutationVariables>;

/**
 * __usePublishEditionMutation__
 *
 * To run a mutation, you first call `usePublishEditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEditionMutation, { data, loading, error }] = usePublishEditionMutation({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *   },
 * });
 */
export function usePublishEditionMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishEditionMutation, Types.PublishEditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishEditionMutation, Types.PublishEditionMutationVariables>(PublishEditionDocument, options);
      }
export type PublishEditionMutationHookResult = ReturnType<typeof usePublishEditionMutation>;
export type PublishEditionMutationResult = Apollo.MutationResult<Types.PublishEditionMutation>;
export type PublishEditionMutationOptions = Apollo.BaseMutationOptions<Types.PublishEditionMutation, Types.PublishEditionMutationVariables>;
export const PublishEditionArtworkDocument = gql`
    mutation PublishEditionArtwork($edition_id: Int!, $artworkId: Int!) {
  publishEditionArtwork(edition_id: $edition_id, artworkId: $artworkId)
}
    `;
export type PublishEditionArtworkMutationFn = Apollo.MutationFunction<Types.PublishEditionArtworkMutation, Types.PublishEditionArtworkMutationVariables>;

/**
 * __usePublishEditionArtworkMutation__
 *
 * To run a mutation, you first call `usePublishEditionArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEditionArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEditionArtworkMutation, { data, loading, error }] = usePublishEditionArtworkMutation({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function usePublishEditionArtworkMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishEditionArtworkMutation, Types.PublishEditionArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishEditionArtworkMutation, Types.PublishEditionArtworkMutationVariables>(PublishEditionArtworkDocument, options);
      }
export type PublishEditionArtworkMutationHookResult = ReturnType<typeof usePublishEditionArtworkMutation>;
export type PublishEditionArtworkMutationResult = Apollo.MutationResult<Types.PublishEditionArtworkMutation>;
export type PublishEditionArtworkMutationOptions = Apollo.BaseMutationOptions<Types.PublishEditionArtworkMutation, Types.PublishEditionArtworkMutationVariables>;
export const GetEditionByTypePrintsPaginatedDocument = gql`
    query GetEditionByTypePrintsPaginated($edition_by_type_id: bigint!, $offset: Int = 0, $limit: Int = 10) {
  Print(
    where: {edition_by_type_id: {_eq: $edition_by_type_id}}
    offset: $offset
    limit: $limit
    order_by: {print_number: asc}
  ) {
    ...PrintDetails
  }
}
    ${PrintDetailsFragmentDoc}`;

/**
 * __useGetEditionByTypePrintsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetEditionByTypePrintsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionByTypePrintsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionByTypePrintsPaginatedQuery({
 *   variables: {
 *      edition_by_type_id: // value for 'edition_by_type_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetEditionByTypePrintsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionByTypePrintsPaginatedQuery, Types.GetEditionByTypePrintsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionByTypePrintsPaginatedQuery, Types.GetEditionByTypePrintsPaginatedQueryVariables>(GetEditionByTypePrintsPaginatedDocument, options);
      }
export function useGetEditionByTypePrintsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionByTypePrintsPaginatedQuery, Types.GetEditionByTypePrintsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionByTypePrintsPaginatedQuery, Types.GetEditionByTypePrintsPaginatedQueryVariables>(GetEditionByTypePrintsPaginatedDocument, options);
        }
export type GetEditionByTypePrintsPaginatedQueryHookResult = ReturnType<typeof useGetEditionByTypePrintsPaginatedQuery>;
export type GetEditionByTypePrintsPaginatedLazyQueryHookResult = ReturnType<typeof useGetEditionByTypePrintsPaginatedLazyQuery>;
export type GetEditionByTypePrintsPaginatedQueryResult = Apollo.QueryResult<Types.GetEditionByTypePrintsPaginatedQuery, Types.GetEditionByTypePrintsPaginatedQueryVariables>;
export const UpdateArtworkTagsDocument = gql`
    mutation UpdateArtworkTags($artworkId: bigint!, $tags: [Artwork_to_tag_insert_input!]!) {
  update_Artwork_to_tag(
    where: {artwork_id: {_eq: $artworkId}, deleted_at: {_is_null: true}}
    _set: {deleted_at: "now()"}
  ) {
    affected_rows
  }
  insert_Artwork_to_tag(objects: $tags) {
    returning {
      id
    }
  }
}
    `;
export type UpdateArtworkTagsMutationFn = Apollo.MutationFunction<Types.UpdateArtworkTagsMutation, Types.UpdateArtworkTagsMutationVariables>;

/**
 * __useUpdateArtworkTagsMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkTagsMutation, { data, loading, error }] = useUpdateArtworkTagsMutation({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateArtworkTagsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkTagsMutation, Types.UpdateArtworkTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkTagsMutation, Types.UpdateArtworkTagsMutationVariables>(UpdateArtworkTagsDocument, options);
      }
export type UpdateArtworkTagsMutationHookResult = ReturnType<typeof useUpdateArtworkTagsMutation>;
export type UpdateArtworkTagsMutationResult = Apollo.MutationResult<Types.UpdateArtworkTagsMutation>;
export type UpdateArtworkTagsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkTagsMutation, Types.UpdateArtworkTagsMutationVariables>;
export const GetPublishEditionDataDocument = gql`
    query GetPublishEditionData($id: bigint!) {
  Edition_by_pk(id: $id) {
    id
    add_edition_flow_step
    publish_date
    Artwork {
      id
      thumbnail_metadata_id
      edition_type
    }
  }
  transferredPrints: Print(
    where: {edition_id: {_eq: $id}, transaction_histories: {status: {_in: [ACCEPTED]}}}
  ) {
    id
  }
}
    `;

/**
 * __useGetPublishEditionDataQuery__
 *
 * To run a query within a React component, call `useGetPublishEditionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishEditionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishEditionDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublishEditionDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPublishEditionDataQuery, Types.GetPublishEditionDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPublishEditionDataQuery, Types.GetPublishEditionDataQueryVariables>(GetPublishEditionDataDocument, options);
      }
export function useGetPublishEditionDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPublishEditionDataQuery, Types.GetPublishEditionDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPublishEditionDataQuery, Types.GetPublishEditionDataQueryVariables>(GetPublishEditionDataDocument, options);
        }
export type GetPublishEditionDataQueryHookResult = ReturnType<typeof useGetPublishEditionDataQuery>;
export type GetPublishEditionDataLazyQueryHookResult = ReturnType<typeof useGetPublishEditionDataLazyQuery>;
export type GetPublishEditionDataQueryResult = Apollo.QueryResult<Types.GetPublishEditionDataQuery, Types.GetPublishEditionDataQueryVariables>;
export const UpdateEditionFlowStepDocument = gql`
    mutation UpdateEditionFlowStep($edition_id: Int!, $add_edition_flow_step: String!) {
  artistUpsertEdition(
    edition_id: $edition_id
    add_edition_flow_step: $add_edition_flow_step
  ) {
    id
    add_edition_flow_step
  }
}
    `;
export type UpdateEditionFlowStepMutationFn = Apollo.MutationFunction<Types.UpdateEditionFlowStepMutation, Types.UpdateEditionFlowStepMutationVariables>;

/**
 * __useUpdateEditionFlowStepMutation__
 *
 * To run a mutation, you first call `useUpdateEditionFlowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditionFlowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditionFlowStepMutation, { data, loading, error }] = useUpdateEditionFlowStepMutation({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *      add_edition_flow_step: // value for 'add_edition_flow_step'
 *   },
 * });
 */
export function useUpdateEditionFlowStepMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEditionFlowStepMutation, Types.UpdateEditionFlowStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEditionFlowStepMutation, Types.UpdateEditionFlowStepMutationVariables>(UpdateEditionFlowStepDocument, options);
      }
export type UpdateEditionFlowStepMutationHookResult = ReturnType<typeof useUpdateEditionFlowStepMutation>;
export type UpdateEditionFlowStepMutationResult = Apollo.MutationResult<Types.UpdateEditionFlowStepMutation>;
export type UpdateEditionFlowStepMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEditionFlowStepMutation, Types.UpdateEditionFlowStepMutationVariables>;
export const UpdateEditionBySizeStatusDocument = gql`
    mutation UpdateEditionBySizeStatus($id: bigint!, $status: ENUM_edition_status_enum = COMPLETE) {
  update_Edition_By_Size_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    id
    status
  }
}
    `;
export type UpdateEditionBySizeStatusMutationFn = Apollo.MutationFunction<Types.UpdateEditionBySizeStatusMutation, Types.UpdateEditionBySizeStatusMutationVariables>;

/**
 * __useUpdateEditionBySizeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEditionBySizeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditionBySizeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditionBySizeStatusMutation, { data, loading, error }] = useUpdateEditionBySizeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateEditionBySizeStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEditionBySizeStatusMutation, Types.UpdateEditionBySizeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEditionBySizeStatusMutation, Types.UpdateEditionBySizeStatusMutationVariables>(UpdateEditionBySizeStatusDocument, options);
      }
export type UpdateEditionBySizeStatusMutationHookResult = ReturnType<typeof useUpdateEditionBySizeStatusMutation>;
export type UpdateEditionBySizeStatusMutationResult = Apollo.MutationResult<Types.UpdateEditionBySizeStatusMutation>;
export type UpdateEditionBySizeStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEditionBySizeStatusMutation, Types.UpdateEditionBySizeStatusMutationVariables>;
export const UpdateEditionByTypesStatusDocument = gql`
    mutation UpdateEditionByTypesStatus($ids: [bigint!]!, $status: ENUM_edition_status_enum = COMPLETE) {
  update_Edition_By_Type(where: {id: {_in: $ids}}, _set: {status: $status}) {
    affected_rows
  }
}
    `;
export type UpdateEditionByTypesStatusMutationFn = Apollo.MutationFunction<Types.UpdateEditionByTypesStatusMutation, Types.UpdateEditionByTypesStatusMutationVariables>;

/**
 * __useUpdateEditionByTypesStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEditionByTypesStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditionByTypesStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditionByTypesStatusMutation, { data, loading, error }] = useUpdateEditionByTypesStatusMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateEditionByTypesStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateEditionByTypesStatusMutation, Types.UpdateEditionByTypesStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateEditionByTypesStatusMutation, Types.UpdateEditionByTypesStatusMutationVariables>(UpdateEditionByTypesStatusDocument, options);
      }
export type UpdateEditionByTypesStatusMutationHookResult = ReturnType<typeof useUpdateEditionByTypesStatusMutation>;
export type UpdateEditionByTypesStatusMutationResult = Apollo.MutationResult<Types.UpdateEditionByTypesStatusMutation>;
export type UpdateEditionByTypesStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateEditionByTypesStatusMutation, Types.UpdateEditionByTypesStatusMutationVariables>;
export const GetEditionPrintsForCertificatesDocument = gql`
    query GetEditionPrintsForCertificates($editionId: bigint!) {
  Edition_by_pk(id: $editionId) {
    Edition_By_Sizes {
      ...EditionBySizeDetails
      Edition_By_Types(where: {Edition_Type: {deleted_at: {_is_null: true}}}) {
        id
        print_amount
        created_at
        Prints {
          id
          print_number
          status
          isTransactionLocked: is_transaction_locked
          acceptedTransactions: transaction_histories(
            where: {status: {_eq: ACCEPTED}}
            order_by: {created_at: desc}
            limit: 1
          ) {
            owner: buyer_context {
              profile {
                ...CollectorProfile
              }
            }
          }
        }
        Edition_Type {
          value
        }
      }
    }
  }
}
    ${EditionBySizeDetailsFragmentDoc}
${CollectorProfileFragmentDoc}`;

/**
 * __useGetEditionPrintsForCertificatesQuery__
 *
 * To run a query within a React component, call `useGetEditionPrintsForCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionPrintsForCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionPrintsForCertificatesQuery({
 *   variables: {
 *      editionId: // value for 'editionId'
 *   },
 * });
 */
export function useGetEditionPrintsForCertificatesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionPrintsForCertificatesQuery, Types.GetEditionPrintsForCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionPrintsForCertificatesQuery, Types.GetEditionPrintsForCertificatesQueryVariables>(GetEditionPrintsForCertificatesDocument, options);
      }
export function useGetEditionPrintsForCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionPrintsForCertificatesQuery, Types.GetEditionPrintsForCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionPrintsForCertificatesQuery, Types.GetEditionPrintsForCertificatesQueryVariables>(GetEditionPrintsForCertificatesDocument, options);
        }
export type GetEditionPrintsForCertificatesQueryHookResult = ReturnType<typeof useGetEditionPrintsForCertificatesQuery>;
export type GetEditionPrintsForCertificatesLazyQueryHookResult = ReturnType<typeof useGetEditionPrintsForCertificatesLazyQuery>;
export type GetEditionPrintsForCertificatesQueryResult = Apollo.QueryResult<Types.GetEditionPrintsForCertificatesQuery, Types.GetEditionPrintsForCertificatesQueryVariables>;
export const GetMeasuringUnitDocument = gql`
    query GetMeasuringUnit($editionId: bigint!) {
  Edition_By_Size(
    where: {status: {_eq: COMPLETE}, edition_id: {_eq: $editionId}}
    limit: 1
  ) {
    measuring_unit
  }
}
    `;

/**
 * __useGetMeasuringUnitQuery__
 *
 * To run a query within a React component, call `useGetMeasuringUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasuringUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasuringUnitQuery({
 *   variables: {
 *      editionId: // value for 'editionId'
 *   },
 * });
 */
export function useGetMeasuringUnitQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMeasuringUnitQuery, Types.GetMeasuringUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMeasuringUnitQuery, Types.GetMeasuringUnitQueryVariables>(GetMeasuringUnitDocument, options);
      }
export function useGetMeasuringUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMeasuringUnitQuery, Types.GetMeasuringUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMeasuringUnitQuery, Types.GetMeasuringUnitQueryVariables>(GetMeasuringUnitDocument, options);
        }
export type GetMeasuringUnitQueryHookResult = ReturnType<typeof useGetMeasuringUnitQuery>;
export type GetMeasuringUnitLazyQueryHookResult = ReturnType<typeof useGetMeasuringUnitLazyQuery>;
export type GetMeasuringUnitQueryResult = Apollo.QueryResult<Types.GetMeasuringUnitQuery, Types.GetMeasuringUnitQueryVariables>;
export const GetArtworkCommercialDetailsDocument = gql`
    query GetArtworkCommercialDetails($artwork_id: bigint!, $skipInvitations: Boolean!, $skipCollaborations: Boolean!) {
  Artwork_by_pk(id: $artwork_id) {
    ...ArtworkCommercialDetails
  }
  invitations: Collaboration_invitation_on_artwork(
    where: {artwork_id: {_eq: $artwork_id}}
  ) @skip(if: $skipInvitations) {
    invitation_gallery_name
    profile_invitation {
      id
      invitee_email
    }
  }
  collaborations: All_galleries(
    where: {_or: [{Context: {collaboration_by_gallery_id: {pending_collaboration_on_artworks: {artwork_id: {_eq: $artwork_id}}}}}]}
  ) @skip(if: $skipCollaborations) {
    email
    title
    Context {
      collaboration_by_gallery_id(
        where: {pending_collaboration_on_artworks: {artwork_id: {_eq: $artwork_id}}}
      ) {
        id
      }
    }
  }
}
    ${ArtworkCommercialDetailsFragmentDoc}`;

/**
 * __useGetArtworkCommercialDetailsQuery__
 *
 * To run a query within a React component, call `useGetArtworkCommercialDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkCommercialDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkCommercialDetailsQuery({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      skipInvitations: // value for 'skipInvitations'
 *      skipCollaborations: // value for 'skipCollaborations'
 *   },
 * });
 */
export function useGetArtworkCommercialDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkCommercialDetailsQuery, Types.GetArtworkCommercialDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkCommercialDetailsQuery, Types.GetArtworkCommercialDetailsQueryVariables>(GetArtworkCommercialDetailsDocument, options);
      }
export function useGetArtworkCommercialDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkCommercialDetailsQuery, Types.GetArtworkCommercialDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkCommercialDetailsQuery, Types.GetArtworkCommercialDetailsQueryVariables>(GetArtworkCommercialDetailsDocument, options);
        }
export type GetArtworkCommercialDetailsQueryHookResult = ReturnType<typeof useGetArtworkCommercialDetailsQuery>;
export type GetArtworkCommercialDetailsLazyQueryHookResult = ReturnType<typeof useGetArtworkCommercialDetailsLazyQuery>;
export type GetArtworkCommercialDetailsQueryResult = Apollo.QueryResult<Types.GetArtworkCommercialDetailsQuery, Types.GetArtworkCommercialDetailsQueryVariables>;
export const UpdateCurrencyPrefferenceDocument = gql`
    mutation updateCurrencyPrefference($profile_settings_id: bigint!, $preferrence: String!) {
  update_Profile_settings_by_pk(
    pk_columns: {id: $profile_settings_id}
    _set: {currency: $preferrence}
  ) {
    id
    currency
  }
}
    `;
export type UpdateCurrencyPrefferenceMutationFn = Apollo.MutationFunction<Types.UpdateCurrencyPrefferenceMutation, Types.UpdateCurrencyPrefferenceMutationVariables>;

/**
 * __useUpdateCurrencyPrefferenceMutation__
 *
 * To run a mutation, you first call `useUpdateCurrencyPrefferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrencyPrefferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrencyPrefferenceMutation, { data, loading, error }] = useUpdateCurrencyPrefferenceMutation({
 *   variables: {
 *      profile_settings_id: // value for 'profile_settings_id'
 *      preferrence: // value for 'preferrence'
 *   },
 * });
 */
export function useUpdateCurrencyPrefferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCurrencyPrefferenceMutation, Types.UpdateCurrencyPrefferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCurrencyPrefferenceMutation, Types.UpdateCurrencyPrefferenceMutationVariables>(UpdateCurrencyPrefferenceDocument, options);
      }
export type UpdateCurrencyPrefferenceMutationHookResult = ReturnType<typeof useUpdateCurrencyPrefferenceMutation>;
export type UpdateCurrencyPrefferenceMutationResult = Apollo.MutationResult<Types.UpdateCurrencyPrefferenceMutation>;
export type UpdateCurrencyPrefferenceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCurrencyPrefferenceMutation, Types.UpdateCurrencyPrefferenceMutationVariables>;
export const GetArtworkCongratsDataDocument = gql`
    query GetArtworkCongratsData($id: bigint!) {
  Artwork_by_pk(id: $id) {
    ...ArtworkDataForCongratsPage
  }
  Edition_by_pk(id: $id) {
    Artwork {
      ...ArtworkDataForCongratsPage
    }
  }
}
    ${ArtworkDataForCongratsPageFragmentDoc}`;

/**
 * __useGetArtworkCongratsDataQuery__
 *
 * To run a query within a React component, call `useGetArtworkCongratsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkCongratsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkCongratsDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtworkCongratsDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkCongratsDataQuery, Types.GetArtworkCongratsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkCongratsDataQuery, Types.GetArtworkCongratsDataQueryVariables>(GetArtworkCongratsDataDocument, options);
      }
export function useGetArtworkCongratsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkCongratsDataQuery, Types.GetArtworkCongratsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkCongratsDataQuery, Types.GetArtworkCongratsDataQueryVariables>(GetArtworkCongratsDataDocument, options);
        }
export type GetArtworkCongratsDataQueryHookResult = ReturnType<typeof useGetArtworkCongratsDataQuery>;
export type GetArtworkCongratsDataLazyQueryHookResult = ReturnType<typeof useGetArtworkCongratsDataLazyQuery>;
export type GetArtworkCongratsDataQueryResult = Apollo.QueryResult<Types.GetArtworkCongratsDataQuery, Types.GetArtworkCongratsDataQueryVariables>;
export const Get_Artwork_Details_For_Edit_By_IdDocument = gql`
    query GET_ARTWORK_DETAILS_FOR_EDIT_BY_ID($id: bigint!) {
  Artwork_by_pk(id: $id) {
    id
    artwork_details {
      id
      title
      art_type
      creation_date_day
      creation_date_month
      creation_date_year
      measuring_unit
      height
      width
      depth
      duration
      discipline_other
      materials_and_medium
      discipline
      genre_id
      location
      blockchain
      blockchain_other
      nft_url
      nft_copies
    }
    artwork_story {
      id
      statement
    }
    ...ArtworkTags
  }
}
    ${ArtworkTagsFragmentDoc}`;

/**
 * __useGet_Artwork_Details_For_Edit_By_IdQuery__
 *
 * To run a query within a React component, call `useGet_Artwork_Details_For_Edit_By_IdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Artwork_Details_For_Edit_By_IdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Artwork_Details_For_Edit_By_IdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Artwork_Details_For_Edit_By_IdQuery(baseOptions: Apollo.QueryHookOptions<Types.Get_Artwork_Details_For_Edit_By_IdQuery, Types.Get_Artwork_Details_For_Edit_By_IdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.Get_Artwork_Details_For_Edit_By_IdQuery, Types.Get_Artwork_Details_For_Edit_By_IdQueryVariables>(Get_Artwork_Details_For_Edit_By_IdDocument, options);
      }
export function useGet_Artwork_Details_For_Edit_By_IdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.Get_Artwork_Details_For_Edit_By_IdQuery, Types.Get_Artwork_Details_For_Edit_By_IdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.Get_Artwork_Details_For_Edit_By_IdQuery, Types.Get_Artwork_Details_For_Edit_By_IdQueryVariables>(Get_Artwork_Details_For_Edit_By_IdDocument, options);
        }
export type Get_Artwork_Details_For_Edit_By_IdQueryHookResult = ReturnType<typeof useGet_Artwork_Details_For_Edit_By_IdQuery>;
export type Get_Artwork_Details_For_Edit_By_IdLazyQueryHookResult = ReturnType<typeof useGet_Artwork_Details_For_Edit_By_IdLazyQuery>;
export type Get_Artwork_Details_For_Edit_By_IdQueryResult = Apollo.QueryResult<Types.Get_Artwork_Details_For_Edit_By_IdQuery, Types.Get_Artwork_Details_For_Edit_By_IdQueryVariables>;
export const GetArtworkTagsDocument = gql`
    query getArtworkTags {
  Artwork_tag(order_by: {name: asc}) {
    id
    name
    value
    description
    family
    genome_id
    can_be_primary
  }
}
    `;

/**
 * __useGetArtworkTagsQuery__
 *
 * To run a query within a React component, call `useGetArtworkTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArtworkTagsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtworkTagsQuery, Types.GetArtworkTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkTagsQuery, Types.GetArtworkTagsQueryVariables>(GetArtworkTagsDocument, options);
      }
export function useGetArtworkTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkTagsQuery, Types.GetArtworkTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkTagsQuery, Types.GetArtworkTagsQueryVariables>(GetArtworkTagsDocument, options);
        }
export type GetArtworkTagsQueryHookResult = ReturnType<typeof useGetArtworkTagsQuery>;
export type GetArtworkTagsLazyQueryHookResult = ReturnType<typeof useGetArtworkTagsLazyQuery>;
export type GetArtworkTagsQueryResult = Apollo.QueryResult<Types.GetArtworkTagsQuery, Types.GetArtworkTagsQueryVariables>;
export const UpdateArtworkDetailsOnlyDocument = gql`
    mutation updateArtworkDetailsOnly($artworkDetailsId: bigint!, $_set: Artwork_details_set_input) {
  update_Artwork_details_by_pk(pk_columns: {id: $artworkDetailsId}, _set: $_set) {
    id
  }
}
    `;
export type UpdateArtworkDetailsOnlyMutationFn = Apollo.MutationFunction<Types.UpdateArtworkDetailsOnlyMutation, Types.UpdateArtworkDetailsOnlyMutationVariables>;

/**
 * __useUpdateArtworkDetailsOnlyMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkDetailsOnlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkDetailsOnlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkDetailsOnlyMutation, { data, loading, error }] = useUpdateArtworkDetailsOnlyMutation({
 *   variables: {
 *      artworkDetailsId: // value for 'artworkDetailsId'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateArtworkDetailsOnlyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkDetailsOnlyMutation, Types.UpdateArtworkDetailsOnlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkDetailsOnlyMutation, Types.UpdateArtworkDetailsOnlyMutationVariables>(UpdateArtworkDetailsOnlyDocument, options);
      }
export type UpdateArtworkDetailsOnlyMutationHookResult = ReturnType<typeof useUpdateArtworkDetailsOnlyMutation>;
export type UpdateArtworkDetailsOnlyMutationResult = Apollo.MutationResult<Types.UpdateArtworkDetailsOnlyMutation>;
export type UpdateArtworkDetailsOnlyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkDetailsOnlyMutation, Types.UpdateArtworkDetailsOnlyMutationVariables>;
export const Update_Artwork_DetailsDocument = gql`
    mutation UPDATE_ARTWORK_DETAILS($artworkDetailsId: bigint!, $artworkId: bigint!, $_set: Artwork_details_set_input, $tags: [Artwork_to_tag_insert_input!]!) {
  update_Artwork_details_by_pk(pk_columns: {id: $artworkDetailsId}, _set: $_set) {
    id
  }
  update_Artwork_to_tag(
    where: {artwork_id: {_eq: $artworkId}, deleted_at: {_is_null: true}}
    _set: {deleted_at: "now()"}
  ) {
    affected_rows
  }
  insert_Artwork_to_tag(objects: $tags) {
    returning {
      id
    }
  }
}
    `;
export type Update_Artwork_DetailsMutationFn = Apollo.MutationFunction<Types.Update_Artwork_DetailsMutation, Types.Update_Artwork_DetailsMutationVariables>;

/**
 * __useUpdate_Artwork_DetailsMutation__
 *
 * To run a mutation, you first call `useUpdate_Artwork_DetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Artwork_DetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkDetailsMutation, { data, loading, error }] = useUpdate_Artwork_DetailsMutation({
 *   variables: {
 *      artworkDetailsId: // value for 'artworkDetailsId'
 *      artworkId: // value for 'artworkId'
 *      _set: // value for '_set'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdate_Artwork_DetailsMutation(baseOptions?: Apollo.MutationHookOptions<Types.Update_Artwork_DetailsMutation, Types.Update_Artwork_DetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.Update_Artwork_DetailsMutation, Types.Update_Artwork_DetailsMutationVariables>(Update_Artwork_DetailsDocument, options);
      }
export type Update_Artwork_DetailsMutationHookResult = ReturnType<typeof useUpdate_Artwork_DetailsMutation>;
export type Update_Artwork_DetailsMutationResult = Apollo.MutationResult<Types.Update_Artwork_DetailsMutation>;
export type Update_Artwork_DetailsMutationOptions = Apollo.BaseMutationOptions<Types.Update_Artwork_DetailsMutation, Types.Update_Artwork_DetailsMutationVariables>;
export const Get_DisciplinesDocument = gql`
    query GET_DISCIPLINES {
  ENUM_artwork_discipline {
    value
    description
  }
}
    `;

/**
 * __useGet_DisciplinesQuery__
 *
 * To run a query within a React component, call `useGet_DisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_DisciplinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_DisciplinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_DisciplinesQuery(baseOptions?: Apollo.QueryHookOptions<Types.Get_DisciplinesQuery, Types.Get_DisciplinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.Get_DisciplinesQuery, Types.Get_DisciplinesQueryVariables>(Get_DisciplinesDocument, options);
      }
export function useGet_DisciplinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.Get_DisciplinesQuery, Types.Get_DisciplinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.Get_DisciplinesQuery, Types.Get_DisciplinesQueryVariables>(Get_DisciplinesDocument, options);
        }
export type Get_DisciplinesQueryHookResult = ReturnType<typeof useGet_DisciplinesQuery>;
export type Get_DisciplinesLazyQueryHookResult = ReturnType<typeof useGet_DisciplinesLazyQuery>;
export type Get_DisciplinesQueryResult = Apollo.QueryResult<Types.Get_DisciplinesQuery, Types.Get_DisciplinesQueryVariables>;
export const GetDisciplinesByArtTypeDocument = gql`
    query getDisciplinesByArtType($art_type: ENUM_art_type_enum!) {
  Art_type_to_Artwork_discipline(
    where: {art_type: {_eq: $art_type}}
    order_by: {order: asc}
  ) {
    ENUM_artwork_discipline {
      description
      value
    }
  }
}
    `;

/**
 * __useGetDisciplinesByArtTypeQuery__
 *
 * To run a query within a React component, call `useGetDisciplinesByArtTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisciplinesByArtTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisciplinesByArtTypeQuery({
 *   variables: {
 *      art_type: // value for 'art_type'
 *   },
 * });
 */
export function useGetDisciplinesByArtTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDisciplinesByArtTypeQuery, Types.GetDisciplinesByArtTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDisciplinesByArtTypeQuery, Types.GetDisciplinesByArtTypeQueryVariables>(GetDisciplinesByArtTypeDocument, options);
      }
export function useGetDisciplinesByArtTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDisciplinesByArtTypeQuery, Types.GetDisciplinesByArtTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDisciplinesByArtTypeQuery, Types.GetDisciplinesByArtTypeQueryVariables>(GetDisciplinesByArtTypeDocument, options);
        }
export type GetDisciplinesByArtTypeQueryHookResult = ReturnType<typeof useGetDisciplinesByArtTypeQuery>;
export type GetDisciplinesByArtTypeLazyQueryHookResult = ReturnType<typeof useGetDisciplinesByArtTypeLazyQuery>;
export type GetDisciplinesByArtTypeQueryResult = Apollo.QueryResult<Types.GetDisciplinesByArtTypeQuery, Types.GetDisciplinesByArtTypeQueryVariables>;
export const InvitePartenerGalleryOnArtworkDocument = gql`
    mutation invitePartenerGalleryOnArtwork($artwork_id: Int = 10, $email: String = "", $context_id: Int = 10, $name: String = "") {
  artistInviteGalleryCollaboration(
    artwork_id: $artwork_id
    email: $email
    context_id: $context_id
    name: $name
  ) {
    id
    type
  }
}
    `;
export type InvitePartenerGalleryOnArtworkMutationFn = Apollo.MutationFunction<Types.InvitePartenerGalleryOnArtworkMutation, Types.InvitePartenerGalleryOnArtworkMutationVariables>;

/**
 * __useInvitePartenerGalleryOnArtworkMutation__
 *
 * To run a mutation, you first call `useInvitePartenerGalleryOnArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitePartenerGalleryOnArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitePartenerGalleryOnArtworkMutation, { data, loading, error }] = useInvitePartenerGalleryOnArtworkMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      email: // value for 'email'
 *      context_id: // value for 'context_id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInvitePartenerGalleryOnArtworkMutation(baseOptions?: Apollo.MutationHookOptions<Types.InvitePartenerGalleryOnArtworkMutation, Types.InvitePartenerGalleryOnArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InvitePartenerGalleryOnArtworkMutation, Types.InvitePartenerGalleryOnArtworkMutationVariables>(InvitePartenerGalleryOnArtworkDocument, options);
      }
export type InvitePartenerGalleryOnArtworkMutationHookResult = ReturnType<typeof useInvitePartenerGalleryOnArtworkMutation>;
export type InvitePartenerGalleryOnArtworkMutationResult = Apollo.MutationResult<Types.InvitePartenerGalleryOnArtworkMutation>;
export type InvitePartenerGalleryOnArtworkMutationOptions = Apollo.BaseMutationOptions<Types.InvitePartenerGalleryOnArtworkMutation, Types.InvitePartenerGalleryOnArtworkMutationVariables>;
export const RevokeInvitationOnArtworkDocument = gql`
    mutation revokeInvitationOnArtwork($artwork_id: Int!, $invitation_id: Int!) {
  revokeInvitationOnArtwork(
    artwork_id: $artwork_id
    invitation_id: $invitation_id
  )
}
    `;
export type RevokeInvitationOnArtworkMutationFn = Apollo.MutationFunction<Types.RevokeInvitationOnArtworkMutation, Types.RevokeInvitationOnArtworkMutationVariables>;

/**
 * __useRevokeInvitationOnArtworkMutation__
 *
 * To run a mutation, you first call `useRevokeInvitationOnArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInvitationOnArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInvitationOnArtworkMutation, { data, loading, error }] = useRevokeInvitationOnArtworkMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      invitation_id: // value for 'invitation_id'
 *   },
 * });
 */
export function useRevokeInvitationOnArtworkMutation(baseOptions?: Apollo.MutationHookOptions<Types.RevokeInvitationOnArtworkMutation, Types.RevokeInvitationOnArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RevokeInvitationOnArtworkMutation, Types.RevokeInvitationOnArtworkMutationVariables>(RevokeInvitationOnArtworkDocument, options);
      }
export type RevokeInvitationOnArtworkMutationHookResult = ReturnType<typeof useRevokeInvitationOnArtworkMutation>;
export type RevokeInvitationOnArtworkMutationResult = Apollo.MutationResult<Types.RevokeInvitationOnArtworkMutation>;
export type RevokeInvitationOnArtworkMutationOptions = Apollo.BaseMutationOptions<Types.RevokeInvitationOnArtworkMutation, Types.RevokeInvitationOnArtworkMutationVariables>;
export const Get_Art_TypesDocument = gql`
    query GET_ART_TYPES {
  ENUM_art_type {
    value
    description
  }
}
    `;

/**
 * __useGet_Art_TypesQuery__
 *
 * To run a query within a React component, call `useGet_Art_TypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Art_TypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Art_TypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Art_TypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.Get_Art_TypesQuery, Types.Get_Art_TypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.Get_Art_TypesQuery, Types.Get_Art_TypesQueryVariables>(Get_Art_TypesDocument, options);
      }
export function useGet_Art_TypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.Get_Art_TypesQuery, Types.Get_Art_TypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.Get_Art_TypesQuery, Types.Get_Art_TypesQueryVariables>(Get_Art_TypesDocument, options);
        }
export type Get_Art_TypesQueryHookResult = ReturnType<typeof useGet_Art_TypesQuery>;
export type Get_Art_TypesLazyQueryHookResult = ReturnType<typeof useGet_Art_TypesLazyQuery>;
export type Get_Art_TypesQueryResult = Apollo.QueryResult<Types.Get_Art_TypesQuery, Types.Get_Art_TypesQueryVariables>;
export const Get_Art_Types_By_OrderDocument = gql`
    query GET_ART_TYPES_BY_ORDER {
  ENUM_art_type_extension(order_by: {order: asc}) {
    Art_type {
      value
      description
    }
  }
}
    `;

/**
 * __useGet_Art_Types_By_OrderQuery__
 *
 * To run a query within a React component, call `useGet_Art_Types_By_OrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Art_Types_By_OrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Art_Types_By_OrderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Art_Types_By_OrderQuery(baseOptions?: Apollo.QueryHookOptions<Types.Get_Art_Types_By_OrderQuery, Types.Get_Art_Types_By_OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.Get_Art_Types_By_OrderQuery, Types.Get_Art_Types_By_OrderQueryVariables>(Get_Art_Types_By_OrderDocument, options);
      }
export function useGet_Art_Types_By_OrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.Get_Art_Types_By_OrderQuery, Types.Get_Art_Types_By_OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.Get_Art_Types_By_OrderQuery, Types.Get_Art_Types_By_OrderQueryVariables>(Get_Art_Types_By_OrderDocument, options);
        }
export type Get_Art_Types_By_OrderQueryHookResult = ReturnType<typeof useGet_Art_Types_By_OrderQuery>;
export type Get_Art_Types_By_OrderLazyQueryHookResult = ReturnType<typeof useGet_Art_Types_By_OrderLazyQuery>;
export type Get_Art_Types_By_OrderQueryResult = Apollo.QueryResult<Types.Get_Art_Types_By_OrderQuery, Types.Get_Art_Types_By_OrderQueryVariables>;
export const Get_BlockchainDocument = gql`
    query GET_BLOCKCHAIN {
  ENUM_blockchain {
    value
    description
  }
}
    `;

/**
 * __useGet_BlockchainQuery__
 *
 * To run a query within a React component, call `useGet_BlockchainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_BlockchainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_BlockchainQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_BlockchainQuery(baseOptions?: Apollo.QueryHookOptions<Types.Get_BlockchainQuery, Types.Get_BlockchainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.Get_BlockchainQuery, Types.Get_BlockchainQueryVariables>(Get_BlockchainDocument, options);
      }
export function useGet_BlockchainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.Get_BlockchainQuery, Types.Get_BlockchainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.Get_BlockchainQuery, Types.Get_BlockchainQueryVariables>(Get_BlockchainDocument, options);
        }
export type Get_BlockchainQueryHookResult = ReturnType<typeof useGet_BlockchainQuery>;
export type Get_BlockchainLazyQueryHookResult = ReturnType<typeof useGet_BlockchainLazyQuery>;
export type Get_BlockchainQueryResult = Apollo.QueryResult<Types.Get_BlockchainQuery, Types.Get_BlockchainQueryVariables>;
export const GetArtistLastArtworkTypeDocument = gql`
    query getArtistLastArtworkType($artist_context_id: bigint!) {
  Artwork(
    where: {artist_context_id: {_eq: $artist_context_id}, published_date: {_is_null: false}, artwork_details: {art_type: {_is_null: false}}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    artwork_details {
      art_type
      measuring_unit
    }
  }
}
    `;

/**
 * __useGetArtistLastArtworkTypeQuery__
 *
 * To run a query within a React component, call `useGetArtistLastArtworkTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistLastArtworkTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistLastArtworkTypeQuery({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useGetArtistLastArtworkTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistLastArtworkTypeQuery, Types.GetArtistLastArtworkTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistLastArtworkTypeQuery, Types.GetArtistLastArtworkTypeQueryVariables>(GetArtistLastArtworkTypeDocument, options);
      }
export function useGetArtistLastArtworkTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistLastArtworkTypeQuery, Types.GetArtistLastArtworkTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistLastArtworkTypeQuery, Types.GetArtistLastArtworkTypeQueryVariables>(GetArtistLastArtworkTypeDocument, options);
        }
export type GetArtistLastArtworkTypeQueryHookResult = ReturnType<typeof useGetArtistLastArtworkTypeQuery>;
export type GetArtistLastArtworkTypeLazyQueryHookResult = ReturnType<typeof useGetArtistLastArtworkTypeLazyQuery>;
export type GetArtistLastArtworkTypeQueryResult = Apollo.QueryResult<Types.GetArtistLastArtworkTypeQuery, Types.GetArtistLastArtworkTypeQueryVariables>;
export const GetProfileSuggestedPrimarySecondaryTagsDocument = gql`
    query getProfileSuggestedPrimarySecondaryTags($contextId: bigint!) {
  getProfileSuggestedPrimarySecondaryTags(contextId: $contextId) {
    primarySuggestedTags {
      value
      description
      order
    }
    secondarySuggestedTags {
      value
      description
      order
    }
  }
}
    `;

/**
 * __useGetProfileSuggestedPrimarySecondaryTagsQuery__
 *
 * To run a query within a React component, call `useGetProfileSuggestedPrimarySecondaryTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileSuggestedPrimarySecondaryTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileSuggestedPrimarySecondaryTagsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileSuggestedPrimarySecondaryTagsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileSuggestedPrimarySecondaryTagsQuery, Types.GetProfileSuggestedPrimarySecondaryTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileSuggestedPrimarySecondaryTagsQuery, Types.GetProfileSuggestedPrimarySecondaryTagsQueryVariables>(GetProfileSuggestedPrimarySecondaryTagsDocument, options);
      }
export function useGetProfileSuggestedPrimarySecondaryTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileSuggestedPrimarySecondaryTagsQuery, Types.GetProfileSuggestedPrimarySecondaryTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileSuggestedPrimarySecondaryTagsQuery, Types.GetProfileSuggestedPrimarySecondaryTagsQueryVariables>(GetProfileSuggestedPrimarySecondaryTagsDocument, options);
        }
export type GetProfileSuggestedPrimarySecondaryTagsQueryHookResult = ReturnType<typeof useGetProfileSuggestedPrimarySecondaryTagsQuery>;
export type GetProfileSuggestedPrimarySecondaryTagsLazyQueryHookResult = ReturnType<typeof useGetProfileSuggestedPrimarySecondaryTagsLazyQuery>;
export type GetProfileSuggestedPrimarySecondaryTagsQueryResult = Apollo.QueryResult<Types.GetProfileSuggestedPrimarySecondaryTagsQuery, Types.GetProfileSuggestedPrimarySecondaryTagsQueryVariables>;
export const GetAiArtworkTextStatementDocument = gql`
    query getAIArtworkTextStatement($artworkId: bigint!, $processAITags: Boolean!) {
  getAIArtworkTextStatement(artworkId: $artworkId, processAITags: $processAITags)
}
    `;

/**
 * __useGetAiArtworkTextStatementQuery__
 *
 * To run a query within a React component, call `useGetAiArtworkTextStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAiArtworkTextStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAiArtworkTextStatementQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *      processAITags: // value for 'processAITags'
 *   },
 * });
 */
export function useGetAiArtworkTextStatementQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAiArtworkTextStatementQuery, Types.GetAiArtworkTextStatementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAiArtworkTextStatementQuery, Types.GetAiArtworkTextStatementQueryVariables>(GetAiArtworkTextStatementDocument, options);
      }
export function useGetAiArtworkTextStatementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAiArtworkTextStatementQuery, Types.GetAiArtworkTextStatementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAiArtworkTextStatementQuery, Types.GetAiArtworkTextStatementQueryVariables>(GetAiArtworkTextStatementDocument, options);
        }
export type GetAiArtworkTextStatementQueryHookResult = ReturnType<typeof useGetAiArtworkTextStatementQuery>;
export type GetAiArtworkTextStatementLazyQueryHookResult = ReturnType<typeof useGetAiArtworkTextStatementLazyQuery>;
export type GetAiArtworkTextStatementQueryResult = Apollo.QueryResult<Types.GetAiArtworkTextStatementQuery, Types.GetAiArtworkTextStatementQueryVariables>;
export const GetArtworkImageryDocument = gql`
    query GetArtworkImagery($id: bigint!) {
  Artwork_by_pk(id: $id) {
    ...ArtworkImagery
  }
}
    ${ArtworkImageryFragmentDoc}`;

/**
 * __useGetArtworkImageryQuery__
 *
 * To run a query within a React component, call `useGetArtworkImageryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkImageryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkImageryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtworkImageryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkImageryQuery, Types.GetArtworkImageryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkImageryQuery, Types.GetArtworkImageryQueryVariables>(GetArtworkImageryDocument, options);
      }
export function useGetArtworkImageryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkImageryQuery, Types.GetArtworkImageryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkImageryQuery, Types.GetArtworkImageryQueryVariables>(GetArtworkImageryDocument, options);
        }
export type GetArtworkImageryQueryHookResult = ReturnType<typeof useGetArtworkImageryQuery>;
export type GetArtworkImageryLazyQueryHookResult = ReturnType<typeof useGetArtworkImageryLazyQuery>;
export type GetArtworkImageryQueryResult = Apollo.QueryResult<Types.GetArtworkImageryQuery, Types.GetArtworkImageryQueryVariables>;
export const UpdateArtworkIsDarkModeDocument = gql`
    mutation UpdateArtworkIsDarkMode($artwork_id: bigint!, $is_dark_mode: Boolean = false) {
  update_Artwork_details(
    where: {artwork: {id: {_eq: $artwork_id}}}
    _set: {is_dark_mode: $is_dark_mode}
  ) {
    returning {
      id
      is_dark_mode
    }
  }
}
    `;
export type UpdateArtworkIsDarkModeMutationFn = Apollo.MutationFunction<Types.UpdateArtworkIsDarkModeMutation, Types.UpdateArtworkIsDarkModeMutationVariables>;

/**
 * __useUpdateArtworkIsDarkModeMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkIsDarkModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkIsDarkModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkIsDarkModeMutation, { data, loading, error }] = useUpdateArtworkIsDarkModeMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      is_dark_mode: // value for 'is_dark_mode'
 *   },
 * });
 */
export function useUpdateArtworkIsDarkModeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkIsDarkModeMutation, Types.UpdateArtworkIsDarkModeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkIsDarkModeMutation, Types.UpdateArtworkIsDarkModeMutationVariables>(UpdateArtworkIsDarkModeDocument, options);
      }
export type UpdateArtworkIsDarkModeMutationHookResult = ReturnType<typeof useUpdateArtworkIsDarkModeMutation>;
export type UpdateArtworkIsDarkModeMutationResult = Apollo.MutationResult<Types.UpdateArtworkIsDarkModeMutation>;
export type UpdateArtworkIsDarkModeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkIsDarkModeMutation, Types.UpdateArtworkIsDarkModeMutationVariables>;
export const CreateEditionDocument = gql`
    mutation CreateEdition($artwork_id: bigint!) {
  insert_Edition_one(object: {artwork_id: $artwork_id}) {
    id
  }
}
    `;
export type CreateEditionMutationFn = Apollo.MutationFunction<Types.CreateEditionMutation, Types.CreateEditionMutationVariables>;

/**
 * __useCreateEditionMutation__
 *
 * To run a mutation, you first call `useCreateEditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEditionMutation, { data, loading, error }] = useCreateEditionMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useCreateEditionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEditionMutation, Types.CreateEditionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateEditionMutation, Types.CreateEditionMutationVariables>(CreateEditionDocument, options);
      }
export type CreateEditionMutationHookResult = ReturnType<typeof useCreateEditionMutation>;
export type CreateEditionMutationResult = Apollo.MutationResult<Types.CreateEditionMutation>;
export type CreateEditionMutationOptions = Apollo.BaseMutationOptions<Types.CreateEditionMutation, Types.CreateEditionMutationVariables>;
export const CreateArtworkDocument = gql`
    mutation CreateArtwork($artist_context_id: Int!, $title: String!, $gallery_context_id: Int = null, $edition_type: String = "UNIQUE") {
  artistCreateArtwork(
    artist_context_id: $artist_context_id
    title: $title
    gallery_context_id: $gallery_context_id
    edition_type: $edition_type
  ) {
    artwork_details_id
    artwork_story_id
    id
    edition_type
  }
}
    `;
export type CreateArtworkMutationFn = Apollo.MutationFunction<Types.CreateArtworkMutation, Types.CreateArtworkMutationVariables>;

/**
 * __useCreateArtworkMutation__
 *
 * To run a mutation, you first call `useCreateArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtworkMutation, { data, loading, error }] = useCreateArtworkMutation({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *      title: // value for 'title'
 *      gallery_context_id: // value for 'gallery_context_id'
 *      edition_type: // value for 'edition_type'
 *   },
 * });
 */
export function useCreateArtworkMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateArtworkMutation, Types.CreateArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateArtworkMutation, Types.CreateArtworkMutationVariables>(CreateArtworkDocument, options);
      }
export type CreateArtworkMutationHookResult = ReturnType<typeof useCreateArtworkMutation>;
export type CreateArtworkMutationResult = Apollo.MutationResult<Types.CreateArtworkMutation>;
export type CreateArtworkMutationOptions = Apollo.BaseMutationOptions<Types.CreateArtworkMutation, Types.CreateArtworkMutationVariables>;
export const InsertCropDocument = gql`
    mutation insertCrop($cropArea: Crop_Area_insert_input!) {
  insert_Crop_Area_one(object: $cropArea) {
    id
  }
}
    `;
export type InsertCropMutationFn = Apollo.MutationFunction<Types.InsertCropMutation, Types.InsertCropMutationVariables>;

/**
 * __useInsertCropMutation__
 *
 * To run a mutation, you first call `useInsertCropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCropMutation, { data, loading, error }] = useInsertCropMutation({
 *   variables: {
 *      cropArea: // value for 'cropArea'
 *   },
 * });
 */
export function useInsertCropMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertCropMutation, Types.InsertCropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertCropMutation, Types.InsertCropMutationVariables>(InsertCropDocument, options);
      }
export type InsertCropMutationHookResult = ReturnType<typeof useInsertCropMutation>;
export type InsertCropMutationResult = Apollo.MutationResult<Types.InsertCropMutation>;
export type InsertCropMutationOptions = Apollo.BaseMutationOptions<Types.InsertCropMutation, Types.InsertCropMutationVariables>;
export const UpdateCropDocument = gql`
    mutation updateCrop($crop_id: Int!, $crop: Crop_Area_set_input!) {
  update_Crop_Area_by_pk(pk_columns: {id: $crop_id}, _set: $crop) {
    id
  }
}
    `;
export type UpdateCropMutationFn = Apollo.MutationFunction<Types.UpdateCropMutation, Types.UpdateCropMutationVariables>;

/**
 * __useUpdateCropMutation__
 *
 * To run a mutation, you first call `useUpdateCropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCropMutation, { data, loading, error }] = useUpdateCropMutation({
 *   variables: {
 *      crop_id: // value for 'crop_id'
 *      crop: // value for 'crop'
 *   },
 * });
 */
export function useUpdateCropMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCropMutation, Types.UpdateCropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCropMutation, Types.UpdateCropMutationVariables>(UpdateCropDocument, options);
      }
export type UpdateCropMutationHookResult = ReturnType<typeof useUpdateCropMutation>;
export type UpdateCropMutationResult = Apollo.MutationResult<Types.UpdateCropMutation>;
export type UpdateCropMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCropMutation, Types.UpdateCropMutationVariables>;
export const UpdateArtworkCropDocument = gql`
    mutation updateArtworkCrop($artwork_id: bigint!, $crop_id: bigint!) {
  update_Artwork_by_pk(
    pk_columns: {id: $artwork_id}
    _set: {crop_area_id: $crop_id}
  ) {
    id
  }
}
    `;
export type UpdateArtworkCropMutationFn = Apollo.MutationFunction<Types.UpdateArtworkCropMutation, Types.UpdateArtworkCropMutationVariables>;

/**
 * __useUpdateArtworkCropMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkCropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkCropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkCropMutation, { data, loading, error }] = useUpdateArtworkCropMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      crop_id: // value for 'crop_id'
 *   },
 * });
 */
export function useUpdateArtworkCropMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkCropMutation, Types.UpdateArtworkCropMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkCropMutation, Types.UpdateArtworkCropMutationVariables>(UpdateArtworkCropDocument, options);
      }
export type UpdateArtworkCropMutationHookResult = ReturnType<typeof useUpdateArtworkCropMutation>;
export type UpdateArtworkCropMutationResult = Apollo.MutationResult<Types.UpdateArtworkCropMutation>;
export type UpdateArtworkCropMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkCropMutation, Types.UpdateArtworkCropMutationVariables>;
export const UpdateCropPrefferenceDocument = gql`
    mutation updateCropPrefference($profile_settings_id: bigint!, $preferrence: Boolean!) {
  update_Profile_settings_by_pk(
    pk_columns: {id: $profile_settings_id}
    _set: {crop_image: $preferrence}
  ) {
    id
    crop_image
  }
}
    `;
export type UpdateCropPrefferenceMutationFn = Apollo.MutationFunction<Types.UpdateCropPrefferenceMutation, Types.UpdateCropPrefferenceMutationVariables>;

/**
 * __useUpdateCropPrefferenceMutation__
 *
 * To run a mutation, you first call `useUpdateCropPrefferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCropPrefferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCropPrefferenceMutation, { data, loading, error }] = useUpdateCropPrefferenceMutation({
 *   variables: {
 *      profile_settings_id: // value for 'profile_settings_id'
 *      preferrence: // value for 'preferrence'
 *   },
 * });
 */
export function useUpdateCropPrefferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCropPrefferenceMutation, Types.UpdateCropPrefferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCropPrefferenceMutation, Types.UpdateCropPrefferenceMutationVariables>(UpdateCropPrefferenceDocument, options);
      }
export type UpdateCropPrefferenceMutationHookResult = ReturnType<typeof useUpdateCropPrefferenceMutation>;
export type UpdateCropPrefferenceMutationResult = Apollo.MutationResult<Types.UpdateCropPrefferenceMutation>;
export type UpdateCropPrefferenceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCropPrefferenceMutation, Types.UpdateCropPrefferenceMutationVariables>;
export const GetArtworkDataForReviewDocument = gql`
    query GetArtworkDataForReview($id: bigint!) {
  Artwork_by_pk(id: $id) {
    ...ArtworkReviewData
  }
}
    ${ArtworkReviewDataFragmentDoc}`;

/**
 * __useGetArtworkDataForReviewQuery__
 *
 * To run a query within a React component, call `useGetArtworkDataForReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkDataForReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkDataForReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtworkDataForReviewQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkDataForReviewQuery, Types.GetArtworkDataForReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkDataForReviewQuery, Types.GetArtworkDataForReviewQueryVariables>(GetArtworkDataForReviewDocument, options);
      }
export function useGetArtworkDataForReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkDataForReviewQuery, Types.GetArtworkDataForReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkDataForReviewQuery, Types.GetArtworkDataForReviewQueryVariables>(GetArtworkDataForReviewDocument, options);
        }
export type GetArtworkDataForReviewQueryHookResult = ReturnType<typeof useGetArtworkDataForReviewQuery>;
export type GetArtworkDataForReviewLazyQueryHookResult = ReturnType<typeof useGetArtworkDataForReviewLazyQuery>;
export type GetArtworkDataForReviewQueryResult = Apollo.QueryResult<Types.GetArtworkDataForReviewQuery, Types.GetArtworkDataForReviewQueryVariables>;
export const PublishArtworkDocument = gql`
    mutation PublishArtwork($id: bigint!, $isPublic: Boolean = true, $publishedDate: timestamptz = "now()") {
  update_Artwork_to_Collection(
    where: {artwork_id: {_eq: $id}}
    _set: {is_public: $isPublic}
  ) {
    affected_rows
  }
  update_Artwork_by_pk(
    pk_columns: {id: $id}
    _set: {is_public: $isPublic, published_date: $publishedDate}
  ) {
    id
  }
}
    `;
export type PublishArtworkMutationFn = Apollo.MutationFunction<Types.PublishArtworkMutation, Types.PublishArtworkMutationVariables>;

/**
 * __usePublishArtworkMutation__
 *
 * To run a mutation, you first call `usePublishArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishArtworkMutation, { data, loading, error }] = usePublishArtworkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isPublic: // value for 'isPublic'
 *      publishedDate: // value for 'publishedDate'
 *   },
 * });
 */
export function usePublishArtworkMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishArtworkMutation, Types.PublishArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishArtworkMutation, Types.PublishArtworkMutationVariables>(PublishArtworkDocument, options);
      }
export type PublishArtworkMutationHookResult = ReturnType<typeof usePublishArtworkMutation>;
export type PublishArtworkMutationResult = Apollo.MutationResult<Types.PublishArtworkMutation>;
export type PublishArtworkMutationOptions = Apollo.BaseMutationOptions<Types.PublishArtworkMutation, Types.PublishArtworkMutationVariables>;
export const GetGalleryCollaboratingArtistsDocument = gql`
    query GetGalleryCollaboratingArtists($galleryId: bigint!) {
  Collaboration(
    where: {gallery_id: {_eq: $galleryId}, gallery_status: {_eq: ACCEPTED}, artist_status: {_eq: ACCEPTED}}
  ) {
    artist_context {
      id
      profile {
        title
      }
    }
  }
}
    `;

/**
 * __useGetGalleryCollaboratingArtistsQuery__
 *
 * To run a query within a React component, call `useGetGalleryCollaboratingArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryCollaboratingArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryCollaboratingArtistsQuery({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *   },
 * });
 */
export function useGetGalleryCollaboratingArtistsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryCollaboratingArtistsQuery, Types.GetGalleryCollaboratingArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryCollaboratingArtistsQuery, Types.GetGalleryCollaboratingArtistsQueryVariables>(GetGalleryCollaboratingArtistsDocument, options);
      }
export function useGetGalleryCollaboratingArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryCollaboratingArtistsQuery, Types.GetGalleryCollaboratingArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryCollaboratingArtistsQuery, Types.GetGalleryCollaboratingArtistsQueryVariables>(GetGalleryCollaboratingArtistsDocument, options);
        }
export type GetGalleryCollaboratingArtistsQueryHookResult = ReturnType<typeof useGetGalleryCollaboratingArtistsQuery>;
export type GetGalleryCollaboratingArtistsLazyQueryHookResult = ReturnType<typeof useGetGalleryCollaboratingArtistsLazyQuery>;
export type GetGalleryCollaboratingArtistsQueryResult = Apollo.QueryResult<Types.GetGalleryCollaboratingArtistsQuery, Types.GetGalleryCollaboratingArtistsQueryVariables>;
export const GetArtistProfileIdWithCollaborationCodeDocument = gql`
    query getArtistProfileIdWithCollaborationCode($code: String!) {
  getArtistProfileIdWithCollaborationCode(code: $code)
}
    `;

/**
 * __useGetArtistProfileIdWithCollaborationCodeQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileIdWithCollaborationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileIdWithCollaborationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileIdWithCollaborationCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetArtistProfileIdWithCollaborationCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileIdWithCollaborationCodeQuery, Types.GetArtistProfileIdWithCollaborationCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileIdWithCollaborationCodeQuery, Types.GetArtistProfileIdWithCollaborationCodeQueryVariables>(GetArtistProfileIdWithCollaborationCodeDocument, options);
      }
export function useGetArtistProfileIdWithCollaborationCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileIdWithCollaborationCodeQuery, Types.GetArtistProfileIdWithCollaborationCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileIdWithCollaborationCodeQuery, Types.GetArtistProfileIdWithCollaborationCodeQueryVariables>(GetArtistProfileIdWithCollaborationCodeDocument, options);
        }
export type GetArtistProfileIdWithCollaborationCodeQueryHookResult = ReturnType<typeof useGetArtistProfileIdWithCollaborationCodeQuery>;
export type GetArtistProfileIdWithCollaborationCodeLazyQueryHookResult = ReturnType<typeof useGetArtistProfileIdWithCollaborationCodeLazyQuery>;
export type GetArtistProfileIdWithCollaborationCodeQueryResult = Apollo.QueryResult<Types.GetArtistProfileIdWithCollaborationCodeQuery, Types.GetArtistProfileIdWithCollaborationCodeQueryVariables>;
export const GetArtistsByEmailDocument = gql`
    query getArtistsByEmail($email: String = "") {
  All_artists(where: {email: {_eq: $email}}) {
    title
    context_id
    email
  }
}
    `;

/**
 * __useGetArtistsByEmailQuery__
 *
 * To run a query within a React component, call `useGetArtistsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetArtistsByEmailQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtistsByEmailQuery, Types.GetArtistsByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistsByEmailQuery, Types.GetArtistsByEmailQueryVariables>(GetArtistsByEmailDocument, options);
      }
export function useGetArtistsByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistsByEmailQuery, Types.GetArtistsByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistsByEmailQuery, Types.GetArtistsByEmailQueryVariables>(GetArtistsByEmailDocument, options);
        }
export type GetArtistsByEmailQueryHookResult = ReturnType<typeof useGetArtistsByEmailQuery>;
export type GetArtistsByEmailLazyQueryHookResult = ReturnType<typeof useGetArtistsByEmailLazyQuery>;
export type GetArtistsByEmailQueryResult = Apollo.QueryResult<Types.GetArtistsByEmailQuery, Types.GetArtistsByEmailQueryVariables>;
export const GetArtistInforForPartenershipDocument = gql`
    query GetArtistInforForPartenership($id: bigint!) {
  Context_by_pk(id: $id) {
    profile {
      id
      title
      handle
      avatar
    }
    locations {
      city
      country
    }
  }
}
    `;

/**
 * __useGetArtistInforForPartenershipQuery__
 *
 * To run a query within a React component, call `useGetArtistInforForPartenershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistInforForPartenershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistInforForPartenershipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtistInforForPartenershipQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistInforForPartenershipQuery, Types.GetArtistInforForPartenershipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistInforForPartenershipQuery, Types.GetArtistInforForPartenershipQueryVariables>(GetArtistInforForPartenershipDocument, options);
      }
export function useGetArtistInforForPartenershipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistInforForPartenershipQuery, Types.GetArtistInforForPartenershipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistInforForPartenershipQuery, Types.GetArtistInforForPartenershipQueryVariables>(GetArtistInforForPartenershipDocument, options);
        }
export type GetArtistInforForPartenershipQueryHookResult = ReturnType<typeof useGetArtistInforForPartenershipQuery>;
export type GetArtistInforForPartenershipLazyQueryHookResult = ReturnType<typeof useGetArtistInforForPartenershipLazyQuery>;
export type GetArtistInforForPartenershipQueryResult = Apollo.QueryResult<Types.GetArtistInforForPartenershipQuery, Types.GetArtistInforForPartenershipQueryVariables>;
export const GalleryInviteArtistDocument = gql`
    mutation GalleryInviteArtist($context_id: Int!, $email: String!, $invitee_context_id: Int = 0) {
  galleryInviteArtistCollaboration(
    context_id: $context_id
    email: $email
    invitee_context_id: $invitee_context_id
  ) {
    id
  }
}
    `;
export type GalleryInviteArtistMutationFn = Apollo.MutationFunction<Types.GalleryInviteArtistMutation, Types.GalleryInviteArtistMutationVariables>;

/**
 * __useGalleryInviteArtistMutation__
 *
 * To run a mutation, you first call `useGalleryInviteArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGalleryInviteArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [galleryInviteArtistMutation, { data, loading, error }] = useGalleryInviteArtistMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      email: // value for 'email'
 *      invitee_context_id: // value for 'invitee_context_id'
 *   },
 * });
 */
export function useGalleryInviteArtistMutation(baseOptions?: Apollo.MutationHookOptions<Types.GalleryInviteArtistMutation, Types.GalleryInviteArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GalleryInviteArtistMutation, Types.GalleryInviteArtistMutationVariables>(GalleryInviteArtistDocument, options);
      }
export type GalleryInviteArtistMutationHookResult = ReturnType<typeof useGalleryInviteArtistMutation>;
export type GalleryInviteArtistMutationResult = Apollo.MutationResult<Types.GalleryInviteArtistMutation>;
export type GalleryInviteArtistMutationOptions = Apollo.BaseMutationOptions<Types.GalleryInviteArtistMutation, Types.GalleryInviteArtistMutationVariables>;
export const GetArtistOfArtworkDocument = gql`
    query GetArtistOfArtwork($id: bigint!) {
  Artwork_by_pk(id: $id) {
    artist_context {
      id
      profile {
        title
      }
    }
  }
}
    `;

/**
 * __useGetArtistOfArtworkQuery__
 *
 * To run a query within a React component, call `useGetArtistOfArtworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistOfArtworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistOfArtworkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtistOfArtworkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistOfArtworkQuery, Types.GetArtistOfArtworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistOfArtworkQuery, Types.GetArtistOfArtworkQueryVariables>(GetArtistOfArtworkDocument, options);
      }
export function useGetArtistOfArtworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistOfArtworkQuery, Types.GetArtistOfArtworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistOfArtworkQuery, Types.GetArtistOfArtworkQueryVariables>(GetArtistOfArtworkDocument, options);
        }
export type GetArtistOfArtworkQueryHookResult = ReturnType<typeof useGetArtistOfArtworkQuery>;
export type GetArtistOfArtworkLazyQueryHookResult = ReturnType<typeof useGetArtistOfArtworkLazyQuery>;
export type GetArtistOfArtworkQueryResult = Apollo.QueryResult<Types.GetArtistOfArtworkQuery, Types.GetArtistOfArtworkQueryVariables>;
export const UpdateArtworkArtistContextIdDocument = gql`
    mutation UpdateArtworkArtistContextId($id: bigint!, $artist_context_id: bigint!) {
  update_Artwork_by_pk(
    pk_columns: {id: $id}
    _set: {artist_context_id: $artist_context_id}
  ) {
    artist_context_id
  }
}
    `;
export type UpdateArtworkArtistContextIdMutationFn = Apollo.MutationFunction<Types.UpdateArtworkArtistContextIdMutation, Types.UpdateArtworkArtistContextIdMutationVariables>;

/**
 * __useUpdateArtworkArtistContextIdMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkArtistContextIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkArtistContextIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkArtistContextIdMutation, { data, loading, error }] = useUpdateArtworkArtistContextIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useUpdateArtworkArtistContextIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkArtistContextIdMutation, Types.UpdateArtworkArtistContextIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkArtistContextIdMutation, Types.UpdateArtworkArtistContextIdMutationVariables>(UpdateArtworkArtistContextIdDocument, options);
      }
export type UpdateArtworkArtistContextIdMutationHookResult = ReturnType<typeof useUpdateArtworkArtistContextIdMutation>;
export type UpdateArtworkArtistContextIdMutationResult = Apollo.MutationResult<Types.UpdateArtworkArtistContextIdMutation>;
export type UpdateArtworkArtistContextIdMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkArtistContextIdMutation, Types.UpdateArtworkArtistContextIdMutationVariables>;
export const GetArtworkSeriesByArtworkIdDocument = gql`
    query GetArtworkSeriesByArtworkId($artwork_id: bigint!) {
  Artwork_to_Collection(
    where: {Artwork: {id: {_eq: $artwork_id}}, Collection: {type: {_eq: SERIES}}}
  ) {
    Collection {
      id
      name
      description
    }
  }
  Artwork_by_pk(id: $artwork_id) {
    add_artwork_flow_step
  }
}
    `;

/**
 * __useGetArtworkSeriesByArtworkIdQuery__
 *
 * To run a query within a React component, call `useGetArtworkSeriesByArtworkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkSeriesByArtworkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkSeriesByArtworkIdQuery({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useGetArtworkSeriesByArtworkIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkSeriesByArtworkIdQuery, Types.GetArtworkSeriesByArtworkIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkSeriesByArtworkIdQuery, Types.GetArtworkSeriesByArtworkIdQueryVariables>(GetArtworkSeriesByArtworkIdDocument, options);
      }
export function useGetArtworkSeriesByArtworkIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkSeriesByArtworkIdQuery, Types.GetArtworkSeriesByArtworkIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkSeriesByArtworkIdQuery, Types.GetArtworkSeriesByArtworkIdQueryVariables>(GetArtworkSeriesByArtworkIdDocument, options);
        }
export type GetArtworkSeriesByArtworkIdQueryHookResult = ReturnType<typeof useGetArtworkSeriesByArtworkIdQuery>;
export type GetArtworkSeriesByArtworkIdLazyQueryHookResult = ReturnType<typeof useGetArtworkSeriesByArtworkIdLazyQuery>;
export type GetArtworkSeriesByArtworkIdQueryResult = Apollo.QueryResult<Types.GetArtworkSeriesByArtworkIdQuery, Types.GetArtworkSeriesByArtworkIdQueryVariables>;
export const GetCollectionByUserAndTypeDocument = gql`
    query GetCollectionByUserAndType($collectionType: ENUM_collection_type_enum = SERIES, $ownerContextId: bigint!) {
  Collection(
    where: {type: {_eq: $collectionType}, owner_context_id: {_eq: $ownerContextId}}
  ) {
    id
    name
    collection_audio_download_link
    description
    collection_video_links(where: {deleted_at: {_is_null: true}}) {
      URL
      platform
      id
    }
  }
}
    `;

/**
 * __useGetCollectionByUserAndTypeQuery__
 *
 * To run a query within a React component, call `useGetCollectionByUserAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionByUserAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionByUserAndTypeQuery({
 *   variables: {
 *      collectionType: // value for 'collectionType'
 *      ownerContextId: // value for 'ownerContextId'
 *   },
 * });
 */
export function useGetCollectionByUserAndTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectionByUserAndTypeQuery, Types.GetCollectionByUserAndTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectionByUserAndTypeQuery, Types.GetCollectionByUserAndTypeQueryVariables>(GetCollectionByUserAndTypeDocument, options);
      }
export function useGetCollectionByUserAndTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectionByUserAndTypeQuery, Types.GetCollectionByUserAndTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectionByUserAndTypeQuery, Types.GetCollectionByUserAndTypeQueryVariables>(GetCollectionByUserAndTypeDocument, options);
        }
export type GetCollectionByUserAndTypeQueryHookResult = ReturnType<typeof useGetCollectionByUserAndTypeQuery>;
export type GetCollectionByUserAndTypeLazyQueryHookResult = ReturnType<typeof useGetCollectionByUserAndTypeLazyQuery>;
export type GetCollectionByUserAndTypeQueryResult = Apollo.QueryResult<Types.GetCollectionByUserAndTypeQuery, Types.GetCollectionByUserAndTypeQueryVariables>;
export const SetSeriesForArtworkDocument = gql`
    mutation SetSeriesForArtwork($collection_id: Int!, $context_id: Int!, $artwork_id: Int!) {
  setSeriesForArtwork(
    collection_id: $collection_id
    context_id: $context_id
    artwork_id: $artwork_id
  )
}
    `;
export type SetSeriesForArtworkMutationFn = Apollo.MutationFunction<Types.SetSeriesForArtworkMutation, Types.SetSeriesForArtworkMutationVariables>;

/**
 * __useSetSeriesForArtworkMutation__
 *
 * To run a mutation, you first call `useSetSeriesForArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSeriesForArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSeriesForArtworkMutation, { data, loading, error }] = useSetSeriesForArtworkMutation({
 *   variables: {
 *      collection_id: // value for 'collection_id'
 *      context_id: // value for 'context_id'
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useSetSeriesForArtworkMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetSeriesForArtworkMutation, Types.SetSeriesForArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetSeriesForArtworkMutation, Types.SetSeriesForArtworkMutationVariables>(SetSeriesForArtworkDocument, options);
      }
export type SetSeriesForArtworkMutationHookResult = ReturnType<typeof useSetSeriesForArtworkMutation>;
export type SetSeriesForArtworkMutationResult = Apollo.MutationResult<Types.SetSeriesForArtworkMutation>;
export type SetSeriesForArtworkMutationOptions = Apollo.BaseMutationOptions<Types.SetSeriesForArtworkMutation, Types.SetSeriesForArtworkMutationVariables>;
export const RemoveArtworkFromSeriesDocument = gql`
    mutation removeArtworkFromSeries($artwork_id: Int!) {
  removeSeriesForArtwork(artwork_id: $artwork_id)
}
    `;
export type RemoveArtworkFromSeriesMutationFn = Apollo.MutationFunction<Types.RemoveArtworkFromSeriesMutation, Types.RemoveArtworkFromSeriesMutationVariables>;

/**
 * __useRemoveArtworkFromSeriesMutation__
 *
 * To run a mutation, you first call `useRemoveArtworkFromSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveArtworkFromSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeArtworkFromSeriesMutation, { data, loading, error }] = useRemoveArtworkFromSeriesMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useRemoveArtworkFromSeriesMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveArtworkFromSeriesMutation, Types.RemoveArtworkFromSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveArtworkFromSeriesMutation, Types.RemoveArtworkFromSeriesMutationVariables>(RemoveArtworkFromSeriesDocument, options);
      }
export type RemoveArtworkFromSeriesMutationHookResult = ReturnType<typeof useRemoveArtworkFromSeriesMutation>;
export type RemoveArtworkFromSeriesMutationResult = Apollo.MutationResult<Types.RemoveArtworkFromSeriesMutation>;
export type RemoveArtworkFromSeriesMutationOptions = Apollo.BaseMutationOptions<Types.RemoveArtworkFromSeriesMutation, Types.RemoveArtworkFromSeriesMutationVariables>;
export const GetArtworkStorytellingDataByIdDocument = gql`
    query getArtworkStorytellingDataById($id: bigint!) {
  Artwork_by_pk(id: $id) {
    artwork_story {
      id
      statement
      story_audio_download_link
    }
    ...ArtworkTags
  }
  Artwork_story_video_links(where: {artwork_story: {artwork: {id: {_eq: $id}}}}) {
    id
    URL
    platform
  }
}
    ${ArtworkTagsFragmentDoc}`;

/**
 * __useGetArtworkStorytellingDataByIdQuery__
 *
 * To run a query within a React component, call `useGetArtworkStorytellingDataByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkStorytellingDataByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkStorytellingDataByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtworkStorytellingDataByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkStorytellingDataByIdQuery, Types.GetArtworkStorytellingDataByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkStorytellingDataByIdQuery, Types.GetArtworkStorytellingDataByIdQueryVariables>(GetArtworkStorytellingDataByIdDocument, options);
      }
export function useGetArtworkStorytellingDataByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkStorytellingDataByIdQuery, Types.GetArtworkStorytellingDataByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkStorytellingDataByIdQuery, Types.GetArtworkStorytellingDataByIdQueryVariables>(GetArtworkStorytellingDataByIdDocument, options);
        }
export type GetArtworkStorytellingDataByIdQueryHookResult = ReturnType<typeof useGetArtworkStorytellingDataByIdQuery>;
export type GetArtworkStorytellingDataByIdLazyQueryHookResult = ReturnType<typeof useGetArtworkStorytellingDataByIdLazyQuery>;
export type GetArtworkStorytellingDataByIdQueryResult = Apollo.QueryResult<Types.GetArtworkStorytellingDataByIdQuery, Types.GetArtworkStorytellingDataByIdQueryVariables>;
export const Update_Artwork_Story_Statement_By_PkDocument = gql`
    mutation UPDATE_ARTWORK_STORY_STATEMENT_BY_PK($id: bigint!, $statement: String = "") {
  update_Artwork_story_by_pk(pk_columns: {id: $id}, _set: {statement: $statement}) {
    id
  }
}
    `;
export type Update_Artwork_Story_Statement_By_PkMutationFn = Apollo.MutationFunction<Types.Update_Artwork_Story_Statement_By_PkMutation, Types.Update_Artwork_Story_Statement_By_PkMutationVariables>;

/**
 * __useUpdate_Artwork_Story_Statement_By_PkMutation__
 *
 * To run a mutation, you first call `useUpdate_Artwork_Story_Statement_By_PkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Artwork_Story_Statement_By_PkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkStoryStatementByPkMutation, { data, loading, error }] = useUpdate_Artwork_Story_Statement_By_PkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      statement: // value for 'statement'
 *   },
 * });
 */
export function useUpdate_Artwork_Story_Statement_By_PkMutation(baseOptions?: Apollo.MutationHookOptions<Types.Update_Artwork_Story_Statement_By_PkMutation, Types.Update_Artwork_Story_Statement_By_PkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.Update_Artwork_Story_Statement_By_PkMutation, Types.Update_Artwork_Story_Statement_By_PkMutationVariables>(Update_Artwork_Story_Statement_By_PkDocument, options);
      }
export type Update_Artwork_Story_Statement_By_PkMutationHookResult = ReturnType<typeof useUpdate_Artwork_Story_Statement_By_PkMutation>;
export type Update_Artwork_Story_Statement_By_PkMutationResult = Apollo.MutationResult<Types.Update_Artwork_Story_Statement_By_PkMutation>;
export type Update_Artwork_Story_Statement_By_PkMutationOptions = Apollo.BaseMutationOptions<Types.Update_Artwork_Story_Statement_By_PkMutation, Types.Update_Artwork_Story_Statement_By_PkMutationVariables>;
export const Add_Artwork_Story_AudioDocument = gql`
    mutation ADD_ARTWORK_STORY_AUDIO($artwork_id: bigint!, $file: Upload!) {
  artworkStoryAudioUpload(artwork_id: $artwork_id, file: $file) {
    url
  }
}
    `;
export type Add_Artwork_Story_AudioMutationFn = Apollo.MutationFunction<Types.Add_Artwork_Story_AudioMutation, Types.Add_Artwork_Story_AudioMutationVariables>;

/**
 * __useAdd_Artwork_Story_AudioMutation__
 *
 * To run a mutation, you first call `useAdd_Artwork_Story_AudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Artwork_Story_AudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtworkStoryAudioMutation, { data, loading, error }] = useAdd_Artwork_Story_AudioMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAdd_Artwork_Story_AudioMutation(baseOptions?: Apollo.MutationHookOptions<Types.Add_Artwork_Story_AudioMutation, Types.Add_Artwork_Story_AudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.Add_Artwork_Story_AudioMutation, Types.Add_Artwork_Story_AudioMutationVariables>(Add_Artwork_Story_AudioDocument, options);
      }
export type Add_Artwork_Story_AudioMutationHookResult = ReturnType<typeof useAdd_Artwork_Story_AudioMutation>;
export type Add_Artwork_Story_AudioMutationResult = Apollo.MutationResult<Types.Add_Artwork_Story_AudioMutation>;
export type Add_Artwork_Story_AudioMutationOptions = Apollo.BaseMutationOptions<Types.Add_Artwork_Story_AudioMutation, Types.Add_Artwork_Story_AudioMutationVariables>;
export const Update_Collection_Video_LinkDocument = gql`
    mutation UPDATE_COLLECTION_VIDEO_LINK($id: bigint!, $deleted_at: timestamptz!) {
  update_Collection_video_links_by_pk(
    pk_columns: {id: $id}
    _set: {deleted_at: $deleted_at}
  ) {
    id
    collection_id
    deleted_at
  }
}
    `;
export type Update_Collection_Video_LinkMutationFn = Apollo.MutationFunction<Types.Update_Collection_Video_LinkMutation, Types.Update_Collection_Video_LinkMutationVariables>;

/**
 * __useUpdate_Collection_Video_LinkMutation__
 *
 * To run a mutation, you first call `useUpdate_Collection_Video_LinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Collection_Video_LinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionVideoLinkMutation, { data, loading, error }] = useUpdate_Collection_Video_LinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleted_at: // value for 'deleted_at'
 *   },
 * });
 */
export function useUpdate_Collection_Video_LinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.Update_Collection_Video_LinkMutation, Types.Update_Collection_Video_LinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.Update_Collection_Video_LinkMutation, Types.Update_Collection_Video_LinkMutationVariables>(Update_Collection_Video_LinkDocument, options);
      }
export type Update_Collection_Video_LinkMutationHookResult = ReturnType<typeof useUpdate_Collection_Video_LinkMutation>;
export type Update_Collection_Video_LinkMutationResult = Apollo.MutationResult<Types.Update_Collection_Video_LinkMutation>;
export type Update_Collection_Video_LinkMutationOptions = Apollo.BaseMutationOptions<Types.Update_Collection_Video_LinkMutation, Types.Update_Collection_Video_LinkMutationVariables>;
export const Add_Artwork_Story_VideoDocument = gql`
    mutation ADD_ARTWORK_STORY_VIDEO($URL: String!, $platform: String!, $collection_id: bigint!) {
  insert_Collection_video_links_one(
    object: {URL: $URL, platform: $platform, collection_id: $collection_id}
  ) {
    id
  }
}
    `;
export type Add_Artwork_Story_VideoMutationFn = Apollo.MutationFunction<Types.Add_Artwork_Story_VideoMutation, Types.Add_Artwork_Story_VideoMutationVariables>;

/**
 * __useAdd_Artwork_Story_VideoMutation__
 *
 * To run a mutation, you first call `useAdd_Artwork_Story_VideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Artwork_Story_VideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtworkStoryVideoMutation, { data, loading, error }] = useAdd_Artwork_Story_VideoMutation({
 *   variables: {
 *      URL: // value for 'URL'
 *      platform: // value for 'platform'
 *      collection_id: // value for 'collection_id'
 *   },
 * });
 */
export function useAdd_Artwork_Story_VideoMutation(baseOptions?: Apollo.MutationHookOptions<Types.Add_Artwork_Story_VideoMutation, Types.Add_Artwork_Story_VideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.Add_Artwork_Story_VideoMutation, Types.Add_Artwork_Story_VideoMutationVariables>(Add_Artwork_Story_VideoDocument, options);
      }
export type Add_Artwork_Story_VideoMutationHookResult = ReturnType<typeof useAdd_Artwork_Story_VideoMutation>;
export type Add_Artwork_Story_VideoMutationResult = Apollo.MutationResult<Types.Add_Artwork_Story_VideoMutation>;
export type Add_Artwork_Story_VideoMutationOptions = Apollo.BaseMutationOptions<Types.Add_Artwork_Story_VideoMutation, Types.Add_Artwork_Story_VideoMutationVariables>;
export const Update_Artwork_Story_VideoDocument = gql`
    mutation UPDATE_ARTWORK_STORY_VIDEO($id: bigint!, $objects: [Artwork_story_video_links_insert_input!]!) {
  update_Artwork_story_video_links(
    where: {artwork_story_id: {_eq: $id}, deleted_at: {_is_null: true}}
    _set: {deleted_at: "now()"}
  ) {
    affected_rows
  }
  insert_Artwork_story_video_links(objects: $objects) {
    returning {
      id
      platform
      URL
    }
  }
}
    `;
export type Update_Artwork_Story_VideoMutationFn = Apollo.MutationFunction<Types.Update_Artwork_Story_VideoMutation, Types.Update_Artwork_Story_VideoMutationVariables>;

/**
 * __useUpdate_Artwork_Story_VideoMutation__
 *
 * To run a mutation, you first call `useUpdate_Artwork_Story_VideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Artwork_Story_VideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkStoryVideoMutation, { data, loading, error }] = useUpdate_Artwork_Story_VideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdate_Artwork_Story_VideoMutation(baseOptions?: Apollo.MutationHookOptions<Types.Update_Artwork_Story_VideoMutation, Types.Update_Artwork_Story_VideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.Update_Artwork_Story_VideoMutation, Types.Update_Artwork_Story_VideoMutationVariables>(Update_Artwork_Story_VideoDocument, options);
      }
export type Update_Artwork_Story_VideoMutationHookResult = ReturnType<typeof useUpdate_Artwork_Story_VideoMutation>;
export type Update_Artwork_Story_VideoMutationResult = Apollo.MutationResult<Types.Update_Artwork_Story_VideoMutation>;
export type Update_Artwork_Story_VideoMutationOptions = Apollo.BaseMutationOptions<Types.Update_Artwork_Story_VideoMutation, Types.Update_Artwork_Story_VideoMutationVariables>;
export const DeleteArtworkAudioDocument = gql`
    mutation deleteArtworkAudio($storyId: bigint!) {
  artworkAudioDelete(story_id: $storyId)
}
    `;
export type DeleteArtworkAudioMutationFn = Apollo.MutationFunction<Types.DeleteArtworkAudioMutation, Types.DeleteArtworkAudioMutationVariables>;

/**
 * __useDeleteArtworkAudioMutation__
 *
 * To run a mutation, you first call `useDeleteArtworkAudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtworkAudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtworkAudioMutation, { data, loading, error }] = useDeleteArtworkAudioMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useDeleteArtworkAudioMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteArtworkAudioMutation, Types.DeleteArtworkAudioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteArtworkAudioMutation, Types.DeleteArtworkAudioMutationVariables>(DeleteArtworkAudioDocument, options);
      }
export type DeleteArtworkAudioMutationHookResult = ReturnType<typeof useDeleteArtworkAudioMutation>;
export type DeleteArtworkAudioMutationResult = Apollo.MutationResult<Types.DeleteArtworkAudioMutation>;
export type DeleteArtworkAudioMutationOptions = Apollo.BaseMutationOptions<Types.DeleteArtworkAudioMutation, Types.DeleteArtworkAudioMutationVariables>;
export const UpdateUserAiArtworkTagsDocument = gql`
    mutation updateUserAIArtworkTags($artworkAITagId: bigint!, $tags: jsonb!) {
  update_Artwork_ai_tags_by_pk(
    pk_columns: {id: $artworkAITagId}
    _set: {user_selected_image_tags: $tags}
  ) {
    id
  }
}
    `;
export type UpdateUserAiArtworkTagsMutationFn = Apollo.MutationFunction<Types.UpdateUserAiArtworkTagsMutation, Types.UpdateUserAiArtworkTagsMutationVariables>;

/**
 * __useUpdateUserAiArtworkTagsMutation__
 *
 * To run a mutation, you first call `useUpdateUserAiArtworkTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAiArtworkTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAiArtworkTagsMutation, { data, loading, error }] = useUpdateUserAiArtworkTagsMutation({
 *   variables: {
 *      artworkAITagId: // value for 'artworkAITagId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdateUserAiArtworkTagsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserAiArtworkTagsMutation, Types.UpdateUserAiArtworkTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserAiArtworkTagsMutation, Types.UpdateUserAiArtworkTagsMutationVariables>(UpdateUserAiArtworkTagsDocument, options);
      }
export type UpdateUserAiArtworkTagsMutationHookResult = ReturnType<typeof useUpdateUserAiArtworkTagsMutation>;
export type UpdateUserAiArtworkTagsMutationResult = Apollo.MutationResult<Types.UpdateUserAiArtworkTagsMutation>;
export type UpdateUserAiArtworkTagsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserAiArtworkTagsMutation, Types.UpdateUserAiArtworkTagsMutationVariables>;
export const GetAddArtworkDataDocument = gql`
    query GetAddArtworkData($id: bigint!) {
  Artwork_by_pk(id: $id) {
    add_artwork_flow_step
    thumbnail_metadata_id
    primary_image_metadata_id
    is_public
    published_date
    artwork_details {
      art_type
    }
  }
}
    `;

/**
 * __useGetAddArtworkDataQuery__
 *
 * To run a query within a React component, call `useGetAddArtworkDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddArtworkDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddArtworkDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAddArtworkDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAddArtworkDataQuery, Types.GetAddArtworkDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAddArtworkDataQuery, Types.GetAddArtworkDataQueryVariables>(GetAddArtworkDataDocument, options);
      }
export function useGetAddArtworkDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAddArtworkDataQuery, Types.GetAddArtworkDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAddArtworkDataQuery, Types.GetAddArtworkDataQueryVariables>(GetAddArtworkDataDocument, options);
        }
export type GetAddArtworkDataQueryHookResult = ReturnType<typeof useGetAddArtworkDataQuery>;
export type GetAddArtworkDataLazyQueryHookResult = ReturnType<typeof useGetAddArtworkDataLazyQuery>;
export type GetAddArtworkDataQueryResult = Apollo.QueryResult<Types.GetAddArtworkDataQuery, Types.GetAddArtworkDataQueryVariables>;
export const UpdateArtworkFlowStepDocument = gql`
    mutation UpdateArtworkFlowStep($id: bigint!, $add_artwork_flow_step: ENUM_add_artwork_flow_step_enum = IMAGERY) {
  update_Artwork_by_pk(
    pk_columns: {id: $id}
    _set: {add_artwork_flow_step: $add_artwork_flow_step}
  ) {
    id
    add_artwork_flow_step
  }
}
    `;
export type UpdateArtworkFlowStepMutationFn = Apollo.MutationFunction<Types.UpdateArtworkFlowStepMutation, Types.UpdateArtworkFlowStepMutationVariables>;

/**
 * __useUpdateArtworkFlowStepMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkFlowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkFlowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkFlowStepMutation, { data, loading, error }] = useUpdateArtworkFlowStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      add_artwork_flow_step: // value for 'add_artwork_flow_step'
 *   },
 * });
 */
export function useUpdateArtworkFlowStepMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkFlowStepMutation, Types.UpdateArtworkFlowStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkFlowStepMutation, Types.UpdateArtworkFlowStepMutationVariables>(UpdateArtworkFlowStepDocument, options);
      }
export type UpdateArtworkFlowStepMutationHookResult = ReturnType<typeof useUpdateArtworkFlowStepMutation>;
export type UpdateArtworkFlowStepMutationResult = Apollo.MutationResult<Types.UpdateArtworkFlowStepMutation>;
export type UpdateArtworkFlowStepMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkFlowStepMutation, Types.UpdateArtworkFlowStepMutationVariables>;
export const GetProfileTitleByContextIdDocument = gql`
    query GetProfileTitleByContextId($id: bigint!) {
  Context_by_pk(id: $id) {
    profile {
      title
      first_name
      last_name
    }
  }
}
    `;

/**
 * __useGetProfileTitleByContextIdQuery__
 *
 * To run a query within a React component, call `useGetProfileTitleByContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileTitleByContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileTitleByContextIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileTitleByContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileTitleByContextIdQuery, Types.GetProfileTitleByContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileTitleByContextIdQuery, Types.GetProfileTitleByContextIdQueryVariables>(GetProfileTitleByContextIdDocument, options);
      }
export function useGetProfileTitleByContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileTitleByContextIdQuery, Types.GetProfileTitleByContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileTitleByContextIdQuery, Types.GetProfileTitleByContextIdQueryVariables>(GetProfileTitleByContextIdDocument, options);
        }
export type GetProfileTitleByContextIdQueryHookResult = ReturnType<typeof useGetProfileTitleByContextIdQuery>;
export type GetProfileTitleByContextIdLazyQueryHookResult = ReturnType<typeof useGetProfileTitleByContextIdLazyQuery>;
export type GetProfileTitleByContextIdQueryResult = Apollo.QueryResult<Types.GetProfileTitleByContextIdQuery, Types.GetProfileTitleByContextIdQueryVariables>;
export const GetProfileTitleByArtworkIdDocument = gql`
    query GetProfileTitleByArtworkId($id: bigint!) {
  Artwork_by_pk(id: $id) {
    artist_context {
      id
    }
  }
}
    `;

/**
 * __useGetProfileTitleByArtworkIdQuery__
 *
 * To run a query within a React component, call `useGetProfileTitleByArtworkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileTitleByArtworkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileTitleByArtworkIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileTitleByArtworkIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileTitleByArtworkIdQuery, Types.GetProfileTitleByArtworkIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileTitleByArtworkIdQuery, Types.GetProfileTitleByArtworkIdQueryVariables>(GetProfileTitleByArtworkIdDocument, options);
      }
export function useGetProfileTitleByArtworkIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileTitleByArtworkIdQuery, Types.GetProfileTitleByArtworkIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileTitleByArtworkIdQuery, Types.GetProfileTitleByArtworkIdQueryVariables>(GetProfileTitleByArtworkIdDocument, options);
        }
export type GetProfileTitleByArtworkIdQueryHookResult = ReturnType<typeof useGetProfileTitleByArtworkIdQuery>;
export type GetProfileTitleByArtworkIdLazyQueryHookResult = ReturnType<typeof useGetProfileTitleByArtworkIdLazyQuery>;
export type GetProfileTitleByArtworkIdQueryResult = Apollo.QueryResult<Types.GetProfileTitleByArtworkIdQuery, Types.GetProfileTitleByArtworkIdQueryVariables>;
export const GetArtworkCommercialDocument = gql`
    query GetArtworkCommercial($artwork_id: bigint!) {
  Artwork_by_pk(id: $artwork_id) {
    gallery_context_id
    artwork_details {
      title
    }
    Artwork_prices(
      where: {scope: {_eq: SHOWCASE}}
      order_by: {updated_at: desc}
      limit: 1
    ) {
      currency
      price
      is_public
      id
      scope
    }
    is_available_sale
  }
  invitations: Collaboration_invitation_on_artwork(
    where: {artwork_id: {_eq: $artwork_id}}
  ) {
    invitation_gallery_name
    profile_invitation {
      id
      invitee_email
    }
  }
  collaborations: All_galleries(
    where: {_or: [{Context: {collaboration_by_gallery_id: {pending_collaboration_on_artworks: {artwork_id: {_eq: $artwork_id}}}}}]}
  ) {
    email
    title
    Context {
      collaboration_by_gallery_id(
        where: {pending_collaboration_on_artworks: {artwork_id: {_eq: $artwork_id}}}
      ) {
        id
      }
    }
  }
}
    `;

/**
 * __useGetArtworkCommercialQuery__
 *
 * To run a query within a React component, call `useGetArtworkCommercialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkCommercialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkCommercialQuery({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useGetArtworkCommercialQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkCommercialQuery, Types.GetArtworkCommercialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkCommercialQuery, Types.GetArtworkCommercialQueryVariables>(GetArtworkCommercialDocument, options);
      }
export function useGetArtworkCommercialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkCommercialQuery, Types.GetArtworkCommercialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkCommercialQuery, Types.GetArtworkCommercialQueryVariables>(GetArtworkCommercialDocument, options);
        }
export type GetArtworkCommercialQueryHookResult = ReturnType<typeof useGetArtworkCommercialQuery>;
export type GetArtworkCommercialLazyQueryHookResult = ReturnType<typeof useGetArtworkCommercialLazyQuery>;
export type GetArtworkCommercialQueryResult = Apollo.QueryResult<Types.GetArtworkCommercialQuery, Types.GetArtworkCommercialQueryVariables>;
export const GetArtworkInfoToConditionPublicationFlowStepsDocument = gql`
    query getArtworkInfoToConditionPublicationFlowSteps($artworkId: bigint!) {
  Artwork_by_pk(id: $artworkId) {
    ...ArtworkTags
  }
}
    ${ArtworkTagsFragmentDoc}`;

/**
 * __useGetArtworkInfoToConditionPublicationFlowStepsQuery__
 *
 * To run a query within a React component, call `useGetArtworkInfoToConditionPublicationFlowStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkInfoToConditionPublicationFlowStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkInfoToConditionPublicationFlowStepsQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetArtworkInfoToConditionPublicationFlowStepsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkInfoToConditionPublicationFlowStepsQuery, Types.GetArtworkInfoToConditionPublicationFlowStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkInfoToConditionPublicationFlowStepsQuery, Types.GetArtworkInfoToConditionPublicationFlowStepsQueryVariables>(GetArtworkInfoToConditionPublicationFlowStepsDocument, options);
      }
export function useGetArtworkInfoToConditionPublicationFlowStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkInfoToConditionPublicationFlowStepsQuery, Types.GetArtworkInfoToConditionPublicationFlowStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkInfoToConditionPublicationFlowStepsQuery, Types.GetArtworkInfoToConditionPublicationFlowStepsQueryVariables>(GetArtworkInfoToConditionPublicationFlowStepsDocument, options);
        }
export type GetArtworkInfoToConditionPublicationFlowStepsQueryHookResult = ReturnType<typeof useGetArtworkInfoToConditionPublicationFlowStepsQuery>;
export type GetArtworkInfoToConditionPublicationFlowStepsLazyQueryHookResult = ReturnType<typeof useGetArtworkInfoToConditionPublicationFlowStepsLazyQuery>;
export type GetArtworkInfoToConditionPublicationFlowStepsQueryResult = Apollo.QueryResult<Types.GetArtworkInfoToConditionPublicationFlowStepsQuery, Types.GetArtworkInfoToConditionPublicationFlowStepsQueryVariables>;
export const GetShowcaseLinkDocument = gql`
    query GetShowcaseLink($id: bigint!) {
  Artwork_links(where: {id: {_eq: $id}}) {
    showcase_url
  }
}
    `;

/**
 * __useGetShowcaseLinkQuery__
 *
 * To run a query within a React component, call `useGetShowcaseLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowcaseLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowcaseLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShowcaseLinkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowcaseLinkQuery, Types.GetShowcaseLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowcaseLinkQuery, Types.GetShowcaseLinkQueryVariables>(GetShowcaseLinkDocument, options);
      }
export function useGetShowcaseLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowcaseLinkQuery, Types.GetShowcaseLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowcaseLinkQuery, Types.GetShowcaseLinkQueryVariables>(GetShowcaseLinkDocument, options);
        }
export type GetShowcaseLinkQueryHookResult = ReturnType<typeof useGetShowcaseLinkQuery>;
export type GetShowcaseLinkLazyQueryHookResult = ReturnType<typeof useGetShowcaseLinkLazyQuery>;
export type GetShowcaseLinkQueryResult = Apollo.QueryResult<Types.GetShowcaseLinkQuery, Types.GetShowcaseLinkQueryVariables>;
export const GetArtworkCollectionOwnerDocument = gql`
    query GetArtworkCollectionOwner($id: bigint!) {
  Artwork_by_pk(id: $id) {
    artwork_to_collections {
      Collection {
        owner_context_id
        type
      }
    }
  }
}
    `;

/**
 * __useGetArtworkCollectionOwnerQuery__
 *
 * To run a query within a React component, call `useGetArtworkCollectionOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkCollectionOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkCollectionOwnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtworkCollectionOwnerQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkCollectionOwnerQuery, Types.GetArtworkCollectionOwnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkCollectionOwnerQuery, Types.GetArtworkCollectionOwnerQueryVariables>(GetArtworkCollectionOwnerDocument, options);
      }
export function useGetArtworkCollectionOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkCollectionOwnerQuery, Types.GetArtworkCollectionOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkCollectionOwnerQuery, Types.GetArtworkCollectionOwnerQueryVariables>(GetArtworkCollectionOwnerDocument, options);
        }
export type GetArtworkCollectionOwnerQueryHookResult = ReturnType<typeof useGetArtworkCollectionOwnerQuery>;
export type GetArtworkCollectionOwnerLazyQueryHookResult = ReturnType<typeof useGetArtworkCollectionOwnerLazyQuery>;
export type GetArtworkCollectionOwnerQueryResult = Apollo.QueryResult<Types.GetArtworkCollectionOwnerQuery, Types.GetArtworkCollectionOwnerQueryVariables>;
export const CancelTransferDocument = gql`
    mutation CancelTransfer($transfer_id: Int!) {
  cancelArtworkTransfer(transfer_id: $transfer_id)
}
    `;
export type CancelTransferMutationFn = Apollo.MutationFunction<Types.CancelTransferMutation, Types.CancelTransferMutationVariables>;

/**
 * __useCancelTransferMutation__
 *
 * To run a mutation, you first call `useCancelTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTransferMutation, { data, loading, error }] = useCancelTransferMutation({
 *   variables: {
 *      transfer_id: // value for 'transfer_id'
 *   },
 * });
 */
export function useCancelTransferMutation(baseOptions?: Apollo.MutationHookOptions<Types.CancelTransferMutation, Types.CancelTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CancelTransferMutation, Types.CancelTransferMutationVariables>(CancelTransferDocument, options);
      }
export type CancelTransferMutationHookResult = ReturnType<typeof useCancelTransferMutation>;
export type CancelTransferMutationResult = Apollo.MutationResult<Types.CancelTransferMutation>;
export type CancelTransferMutationOptions = Apollo.BaseMutationOptions<Types.CancelTransferMutation, Types.CancelTransferMutationVariables>;
export const GetTransactionInformationDocument = gql`
    query GetTransactionInformation($id: bigint!) {
  Artwork_transactions(where: {artwork_id: {_eq: $id}}, order_by: {id: asc}) {
    id
    context_buyer_id
    context_seller_id
    buyer_email
    status_at
    status
    artwork {
      primary_image_metadata_id
      thumbnail_metadata_id
      artist_context_id
      is_transaction_locked
      artwork_details {
        title
      }
      artist_context {
        profile {
          id
          avatar
          title
        }
      }
    }
    seller_context {
      profile {
        id
        avatar
        title
        handle
        is_public
        first_name
        last_name
      }
    }
    buyer_context {
      profile {
        id
        avatar
        first_name
        handle
        is_public
        last_name
      }
    }
  }
}
    `;

/**
 * __useGetTransactionInformationQuery__
 *
 * To run a query within a React component, call `useGetTransactionInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransactionInformationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTransactionInformationQuery, Types.GetTransactionInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransactionInformationQuery, Types.GetTransactionInformationQueryVariables>(GetTransactionInformationDocument, options);
      }
export function useGetTransactionInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransactionInformationQuery, Types.GetTransactionInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransactionInformationQuery, Types.GetTransactionInformationQueryVariables>(GetTransactionInformationDocument, options);
        }
export type GetTransactionInformationQueryHookResult = ReturnType<typeof useGetTransactionInformationQuery>;
export type GetTransactionInformationLazyQueryHookResult = ReturnType<typeof useGetTransactionInformationLazyQuery>;
export type GetTransactionInformationQueryResult = Apollo.QueryResult<Types.GetTransactionInformationQuery, Types.GetTransactionInformationQueryVariables>;
export const GetTransferPrintDetailsDocument = gql`
    query GetTransferPrintDetails($printId: bigint!) {
  Print_by_pk(id: $printId) {
    id
    number: print_number
    Edition_By_Type {
      id
      printAmount: print_amount
      Edition_Type {
        name: value
      }
    }
  }
}
    `;

/**
 * __useGetTransferPrintDetailsQuery__
 *
 * To run a query within a React component, call `useGetTransferPrintDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferPrintDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferPrintDetailsQuery({
 *   variables: {
 *      printId: // value for 'printId'
 *   },
 * });
 */
export function useGetTransferPrintDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTransferPrintDetailsQuery, Types.GetTransferPrintDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransferPrintDetailsQuery, Types.GetTransferPrintDetailsQueryVariables>(GetTransferPrintDetailsDocument, options);
      }
export function useGetTransferPrintDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferPrintDetailsQuery, Types.GetTransferPrintDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransferPrintDetailsQuery, Types.GetTransferPrintDetailsQueryVariables>(GetTransferPrintDetailsDocument, options);
        }
export type GetTransferPrintDetailsQueryHookResult = ReturnType<typeof useGetTransferPrintDetailsQuery>;
export type GetTransferPrintDetailsLazyQueryHookResult = ReturnType<typeof useGetTransferPrintDetailsLazyQuery>;
export type GetTransferPrintDetailsQueryResult = Apollo.QueryResult<Types.GetTransferPrintDetailsQuery, Types.GetTransferPrintDetailsQueryVariables>;
export const GetArtworkInformationDocument = gql`
    query GetArtworkInformation($id: bigint!) {
  Artwork_by_pk(id: $id) {
    transaction_histories(
      where: {status: {_in: [INITIALIZED, PENDING]}, print_id: {_is_null: true}}
    ) {
      status
    }
    artwork_to_collections(where: {Collection: {type: {_eq: OWNED}}}) {
      Collection {
        owner_context_id
        owner_context {
          type
          profile {
            id
            avatar
            first_name
            last_name
            title
            handle
            is_public
          }
        }
      }
      Artwork {
        primary_image_metadata_id
        artist_context_id
        artist_context {
          profile {
            id
            avatar
            title
          }
        }
        artwork_details {
          title
        }
        gallery_context_id
        gallery_context {
          profile {
            title
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetArtworkInformationQuery__
 *
 * To run a query within a React component, call `useGetArtworkInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtworkInformationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkInformationQuery, Types.GetArtworkInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkInformationQuery, Types.GetArtworkInformationQueryVariables>(GetArtworkInformationDocument, options);
      }
export function useGetArtworkInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkInformationQuery, Types.GetArtworkInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkInformationQuery, Types.GetArtworkInformationQueryVariables>(GetArtworkInformationDocument, options);
        }
export type GetArtworkInformationQueryHookResult = ReturnType<typeof useGetArtworkInformationQuery>;
export type GetArtworkInformationLazyQueryHookResult = ReturnType<typeof useGetArtworkInformationLazyQuery>;
export type GetArtworkInformationQueryResult = Apollo.QueryResult<Types.GetArtworkInformationQuery, Types.GetArtworkInformationQueryVariables>;
export const GetPrintByNumberAndEditionByTypeIdDocument = gql`
    query GetPrintByNumberAndEditionByTypeId($print_number: Int!, $edition_by_type_id: bigint!) {
  Print(
    where: {print_number: {_eq: $print_number}, edition_by_type_id: {_eq: $edition_by_type_id}}
  ) {
    id
  }
}
    `;

/**
 * __useGetPrintByNumberAndEditionByTypeIdQuery__
 *
 * To run a query within a React component, call `useGetPrintByNumberAndEditionByTypeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintByNumberAndEditionByTypeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintByNumberAndEditionByTypeIdQuery({
 *   variables: {
 *      print_number: // value for 'print_number'
 *      edition_by_type_id: // value for 'edition_by_type_id'
 *   },
 * });
 */
export function useGetPrintByNumberAndEditionByTypeIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPrintByNumberAndEditionByTypeIdQuery, Types.GetPrintByNumberAndEditionByTypeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPrintByNumberAndEditionByTypeIdQuery, Types.GetPrintByNumberAndEditionByTypeIdQueryVariables>(GetPrintByNumberAndEditionByTypeIdDocument, options);
      }
export function useGetPrintByNumberAndEditionByTypeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPrintByNumberAndEditionByTypeIdQuery, Types.GetPrintByNumberAndEditionByTypeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPrintByNumberAndEditionByTypeIdQuery, Types.GetPrintByNumberAndEditionByTypeIdQueryVariables>(GetPrintByNumberAndEditionByTypeIdDocument, options);
        }
export type GetPrintByNumberAndEditionByTypeIdQueryHookResult = ReturnType<typeof useGetPrintByNumberAndEditionByTypeIdQuery>;
export type GetPrintByNumberAndEditionByTypeIdLazyQueryHookResult = ReturnType<typeof useGetPrintByNumberAndEditionByTypeIdLazyQuery>;
export type GetPrintByNumberAndEditionByTypeIdQueryResult = Apollo.QueryResult<Types.GetPrintByNumberAndEditionByTypeIdQuery, Types.GetPrintByNumberAndEditionByTypeIdQueryVariables>;
export const GetEditionByTypeNameDocument = gql`
    query GetEditionByTypeName($id: bigint!) {
  Edition_By_Type_by_pk(id: $id) {
    ...EditionByTypeDetails
  }
}
    ${EditionByTypeDetailsFragmentDoc}`;

/**
 * __useGetEditionByTypeNameQuery__
 *
 * To run a query within a React component, call `useGetEditionByTypeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionByTypeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionByTypeNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditionByTypeNameQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionByTypeNameQuery, Types.GetEditionByTypeNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionByTypeNameQuery, Types.GetEditionByTypeNameQueryVariables>(GetEditionByTypeNameDocument, options);
      }
export function useGetEditionByTypeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionByTypeNameQuery, Types.GetEditionByTypeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionByTypeNameQuery, Types.GetEditionByTypeNameQueryVariables>(GetEditionByTypeNameDocument, options);
        }
export type GetEditionByTypeNameQueryHookResult = ReturnType<typeof useGetEditionByTypeNameQuery>;
export type GetEditionByTypeNameLazyQueryHookResult = ReturnType<typeof useGetEditionByTypeNameLazyQuery>;
export type GetEditionByTypeNameQueryResult = Apollo.QueryResult<Types.GetEditionByTypeNameQuery, Types.GetEditionByTypeNameQueryVariables>;
export const GetTransferPrintDataByPrintIdDocument = gql`
    query GetTransferPrintDataByPrintId($id: bigint!) {
  Print_by_pk(id: $id) {
    id
    print_number
    Edition_By_Type {
      ...EditionByTypeDetails
    }
  }
}
    ${EditionByTypeDetailsFragmentDoc}`;

/**
 * __useGetTransferPrintDataByPrintIdQuery__
 *
 * To run a query within a React component, call `useGetTransferPrintDataByPrintIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferPrintDataByPrintIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferPrintDataByPrintIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransferPrintDataByPrintIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTransferPrintDataByPrintIdQuery, Types.GetTransferPrintDataByPrintIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTransferPrintDataByPrintIdQuery, Types.GetTransferPrintDataByPrintIdQueryVariables>(GetTransferPrintDataByPrintIdDocument, options);
      }
export function useGetTransferPrintDataByPrintIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTransferPrintDataByPrintIdQuery, Types.GetTransferPrintDataByPrintIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTransferPrintDataByPrintIdQuery, Types.GetTransferPrintDataByPrintIdQueryVariables>(GetTransferPrintDataByPrintIdDocument, options);
        }
export type GetTransferPrintDataByPrintIdQueryHookResult = ReturnType<typeof useGetTransferPrintDataByPrintIdQuery>;
export type GetTransferPrintDataByPrintIdLazyQueryHookResult = ReturnType<typeof useGetTransferPrintDataByPrintIdLazyQuery>;
export type GetTransferPrintDataByPrintIdQueryResult = Apollo.QueryResult<Types.GetTransferPrintDataByPrintIdQuery, Types.GetTransferPrintDataByPrintIdQueryVariables>;
export const GetCollectorAvatarAndLanguageDocument = gql`
    query GetCollectorAvatarAndLanguage($id: bigint!) {
  Context_by_pk(id: $id) {
    profile {
      id
      is_public
      handle
      avatar
    }
    granted_access {
      receiver {
        auth_context_accesses {
          auth {
            preferred_language
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCollectorAvatarAndLanguageQuery__
 *
 * To run a query within a React component, call `useGetCollectorAvatarAndLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorAvatarAndLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorAvatarAndLanguageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectorAvatarAndLanguageQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorAvatarAndLanguageQuery, Types.GetCollectorAvatarAndLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorAvatarAndLanguageQuery, Types.GetCollectorAvatarAndLanguageQueryVariables>(GetCollectorAvatarAndLanguageDocument, options);
      }
export function useGetCollectorAvatarAndLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorAvatarAndLanguageQuery, Types.GetCollectorAvatarAndLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorAvatarAndLanguageQuery, Types.GetCollectorAvatarAndLanguageQueryVariables>(GetCollectorAvatarAndLanguageDocument, options);
        }
export type GetCollectorAvatarAndLanguageQueryHookResult = ReturnType<typeof useGetCollectorAvatarAndLanguageQuery>;
export type GetCollectorAvatarAndLanguageLazyQueryHookResult = ReturnType<typeof useGetCollectorAvatarAndLanguageLazyQuery>;
export type GetCollectorAvatarAndLanguageQueryResult = Apollo.QueryResult<Types.GetCollectorAvatarAndLanguageQuery, Types.GetCollectorAvatarAndLanguageQueryVariables>;
export const GetCollectorByEmailOrHandleDocument = gql`
    query getCollectorByEmailOrHandle($email: String = "") {
  getCollectorByEmailOrHandle(email: $email) {
    first_name
    last_name
    context_id
    is_public
    handle
    user_exists
  }
}
    `;

/**
 * __useGetCollectorByEmailOrHandleQuery__
 *
 * To run a query within a React component, call `useGetCollectorByEmailOrHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorByEmailOrHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorByEmailOrHandleQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCollectorByEmailOrHandleQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCollectorByEmailOrHandleQuery, Types.GetCollectorByEmailOrHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorByEmailOrHandleQuery, Types.GetCollectorByEmailOrHandleQueryVariables>(GetCollectorByEmailOrHandleDocument, options);
      }
export function useGetCollectorByEmailOrHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorByEmailOrHandleQuery, Types.GetCollectorByEmailOrHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorByEmailOrHandleQuery, Types.GetCollectorByEmailOrHandleQueryVariables>(GetCollectorByEmailOrHandleDocument, options);
        }
export type GetCollectorByEmailOrHandleQueryHookResult = ReturnType<typeof useGetCollectorByEmailOrHandleQuery>;
export type GetCollectorByEmailOrHandleLazyQueryHookResult = ReturnType<typeof useGetCollectorByEmailOrHandleLazyQuery>;
export type GetCollectorByEmailOrHandleQueryResult = Apollo.QueryResult<Types.GetCollectorByEmailOrHandleQuery, Types.GetCollectorByEmailOrHandleQueryVariables>;
export const TransactionUpdateSoldDateDocument = gql`
    mutation transactionUpdateSoldDate($day: Int, $year: Int!, $month: Int, $artwork_tranzaction_id: bigint!) {
  update_Artwork_transactions(
    where: {id: {_eq: $artwork_tranzaction_id}}
    _set: {creation_day: $day, creation_year: $year, creation_month: $month}
  ) {
    affected_rows
  }
}
    `;
export type TransactionUpdateSoldDateMutationFn = Apollo.MutationFunction<Types.TransactionUpdateSoldDateMutation, Types.TransactionUpdateSoldDateMutationVariables>;

/**
 * __useTransactionUpdateSoldDateMutation__
 *
 * To run a mutation, you first call `useTransactionUpdateSoldDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransactionUpdateSoldDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transactionUpdateSoldDateMutation, { data, loading, error }] = useTransactionUpdateSoldDateMutation({
 *   variables: {
 *      day: // value for 'day'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      artwork_tranzaction_id: // value for 'artwork_tranzaction_id'
 *   },
 * });
 */
export function useTransactionUpdateSoldDateMutation(baseOptions?: Apollo.MutationHookOptions<Types.TransactionUpdateSoldDateMutation, Types.TransactionUpdateSoldDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TransactionUpdateSoldDateMutation, Types.TransactionUpdateSoldDateMutationVariables>(TransactionUpdateSoldDateDocument, options);
      }
export type TransactionUpdateSoldDateMutationHookResult = ReturnType<typeof useTransactionUpdateSoldDateMutation>;
export type TransactionUpdateSoldDateMutationResult = Apollo.MutationResult<Types.TransactionUpdateSoldDateMutation>;
export type TransactionUpdateSoldDateMutationOptions = Apollo.BaseMutationOptions<Types.TransactionUpdateSoldDateMutation, Types.TransactionUpdateSoldDateMutationVariables>;
export const TransactionUpdateWasSoldByGalleryDocument = gql`
    mutation transactionUpdateWasSoldByGallery($id: bigint!, $is_gallery_sold: Boolean!) {
  update_Artwork_transactions(
    where: {id: {_eq: $id}}
    _set: {is_gallery_sold: $is_gallery_sold}
  ) {
    returning {
      id
    }
  }
}
    `;
export type TransactionUpdateWasSoldByGalleryMutationFn = Apollo.MutationFunction<Types.TransactionUpdateWasSoldByGalleryMutation, Types.TransactionUpdateWasSoldByGalleryMutationVariables>;

/**
 * __useTransactionUpdateWasSoldByGalleryMutation__
 *
 * To run a mutation, you first call `useTransactionUpdateWasSoldByGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransactionUpdateWasSoldByGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transactionUpdateWasSoldByGalleryMutation, { data, loading, error }] = useTransactionUpdateWasSoldByGalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      is_gallery_sold: // value for 'is_gallery_sold'
 *   },
 * });
 */
export function useTransactionUpdateWasSoldByGalleryMutation(baseOptions?: Apollo.MutationHookOptions<Types.TransactionUpdateWasSoldByGalleryMutation, Types.TransactionUpdateWasSoldByGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TransactionUpdateWasSoldByGalleryMutation, Types.TransactionUpdateWasSoldByGalleryMutationVariables>(TransactionUpdateWasSoldByGalleryDocument, options);
      }
export type TransactionUpdateWasSoldByGalleryMutationHookResult = ReturnType<typeof useTransactionUpdateWasSoldByGalleryMutation>;
export type TransactionUpdateWasSoldByGalleryMutationResult = Apollo.MutationResult<Types.TransactionUpdateWasSoldByGalleryMutation>;
export type TransactionUpdateWasSoldByGalleryMutationOptions = Apollo.BaseMutationOptions<Types.TransactionUpdateWasSoldByGalleryMutation, Types.TransactionUpdateWasSoldByGalleryMutationVariables>;
export const TransferArtworkToCollectorIdDocument = gql`
    mutation TransferArtworkToCollectorId($artwork_id: Int!, $from_id: Int!, $to_id: Int!, $edition_by_type_id: Int, $print_id: Int, $print_number: Int) {
  transferArtworkToCollector(
    artwork_id: $artwork_id
    from_id: $from_id
    to_id: $to_id
    edition_by_type_id: $edition_by_type_id
    print_id: $print_id
    print_number: $print_number
  )
}
    `;
export type TransferArtworkToCollectorIdMutationFn = Apollo.MutationFunction<Types.TransferArtworkToCollectorIdMutation, Types.TransferArtworkToCollectorIdMutationVariables>;

/**
 * __useTransferArtworkToCollectorIdMutation__
 *
 * To run a mutation, you first call `useTransferArtworkToCollectorIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferArtworkToCollectorIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferArtworkToCollectorIdMutation, { data, loading, error }] = useTransferArtworkToCollectorIdMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      from_id: // value for 'from_id'
 *      to_id: // value for 'to_id'
 *      edition_by_type_id: // value for 'edition_by_type_id'
 *      print_id: // value for 'print_id'
 *      print_number: // value for 'print_number'
 *   },
 * });
 */
export function useTransferArtworkToCollectorIdMutation(baseOptions?: Apollo.MutationHookOptions<Types.TransferArtworkToCollectorIdMutation, Types.TransferArtworkToCollectorIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TransferArtworkToCollectorIdMutation, Types.TransferArtworkToCollectorIdMutationVariables>(TransferArtworkToCollectorIdDocument, options);
      }
export type TransferArtworkToCollectorIdMutationHookResult = ReturnType<typeof useTransferArtworkToCollectorIdMutation>;
export type TransferArtworkToCollectorIdMutationResult = Apollo.MutationResult<Types.TransferArtworkToCollectorIdMutation>;
export type TransferArtworkToCollectorIdMutationOptions = Apollo.BaseMutationOptions<Types.TransferArtworkToCollectorIdMutation, Types.TransferArtworkToCollectorIdMutationVariables>;
export const TransferArtworkToEmailDocument = gql`
    mutation TransferArtworkToEmail($artwork_id: Int!, $from_id: Int!, $to_email: String!, $language: String!, $first_name: String!, $print_id: Int, $print_number: Int, $edition_by_type_id: Int) {
  transferArtworkToEmail(
    artwork_id: $artwork_id
    from_id: $from_id
    to_email: $to_email
    language: $language
    first_name: $first_name
    print_id: $print_id
    print_number: $print_number
    edition_by_type_id: $edition_by_type_id
  )
}
    `;
export type TransferArtworkToEmailMutationFn = Apollo.MutationFunction<Types.TransferArtworkToEmailMutation, Types.TransferArtworkToEmailMutationVariables>;

/**
 * __useTransferArtworkToEmailMutation__
 *
 * To run a mutation, you first call `useTransferArtworkToEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferArtworkToEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferArtworkToEmailMutation, { data, loading, error }] = useTransferArtworkToEmailMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      from_id: // value for 'from_id'
 *      to_email: // value for 'to_email'
 *      language: // value for 'language'
 *      first_name: // value for 'first_name'
 *      print_id: // value for 'print_id'
 *      print_number: // value for 'print_number'
 *      edition_by_type_id: // value for 'edition_by_type_id'
 *   },
 * });
 */
export function useTransferArtworkToEmailMutation(baseOptions?: Apollo.MutationHookOptions<Types.TransferArtworkToEmailMutation, Types.TransferArtworkToEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TransferArtworkToEmailMutation, Types.TransferArtworkToEmailMutationVariables>(TransferArtworkToEmailDocument, options);
      }
export type TransferArtworkToEmailMutationHookResult = ReturnType<typeof useTransferArtworkToEmailMutation>;
export type TransferArtworkToEmailMutationResult = Apollo.MutationResult<Types.TransferArtworkToEmailMutation>;
export type TransferArtworkToEmailMutationOptions = Apollo.BaseMutationOptions<Types.TransferArtworkToEmailMutation, Types.TransferArtworkToEmailMutationVariables>;
export const GetIsSimpleUserDocument = gql`
    query getIsSimpleUser($contextId: bigint!) {
  Profile(
    where: {context: {id: {_eq: $contextId}, type: {_eq: COLLECTOR}}, type: {_eq: SIMPLE_USER}}
  ) {
    id
  }
}
    `;

/**
 * __useGetIsSimpleUserQuery__
 *
 * To run a query within a React component, call `useGetIsSimpleUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsSimpleUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsSimpleUserQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetIsSimpleUserQuery(baseOptions: Apollo.QueryHookOptions<Types.GetIsSimpleUserQuery, Types.GetIsSimpleUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetIsSimpleUserQuery, Types.GetIsSimpleUserQueryVariables>(GetIsSimpleUserDocument, options);
      }
export function useGetIsSimpleUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetIsSimpleUserQuery, Types.GetIsSimpleUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetIsSimpleUserQuery, Types.GetIsSimpleUserQueryVariables>(GetIsSimpleUserDocument, options);
        }
export type GetIsSimpleUserQueryHookResult = ReturnType<typeof useGetIsSimpleUserQuery>;
export type GetIsSimpleUserLazyQueryHookResult = ReturnType<typeof useGetIsSimpleUserLazyQuery>;
export type GetIsSimpleUserQueryResult = Apollo.QueryResult<Types.GetIsSimpleUserQuery, Types.GetIsSimpleUserQueryVariables>;
export const IsSimpleUserByProfileIdDocument = gql`
    query isSimpleUserByProfileID($profileId: bigint!) {
  Profile(where: {type: {_eq: SIMPLE_USER}, id: {_eq: $profileId}}) {
    id
  }
}
    `;

/**
 * __useIsSimpleUserByProfileIdQuery__
 *
 * To run a query within a React component, call `useIsSimpleUserByProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSimpleUserByProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSimpleUserByProfileIdQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useIsSimpleUserByProfileIdQuery(baseOptions: Apollo.QueryHookOptions<Types.IsSimpleUserByProfileIdQuery, Types.IsSimpleUserByProfileIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsSimpleUserByProfileIdQuery, Types.IsSimpleUserByProfileIdQueryVariables>(IsSimpleUserByProfileIdDocument, options);
      }
export function useIsSimpleUserByProfileIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsSimpleUserByProfileIdQuery, Types.IsSimpleUserByProfileIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsSimpleUserByProfileIdQuery, Types.IsSimpleUserByProfileIdQueryVariables>(IsSimpleUserByProfileIdDocument, options);
        }
export type IsSimpleUserByProfileIdQueryHookResult = ReturnType<typeof useIsSimpleUserByProfileIdQuery>;
export type IsSimpleUserByProfileIdLazyQueryHookResult = ReturnType<typeof useIsSimpleUserByProfileIdLazyQuery>;
export type IsSimpleUserByProfileIdQueryResult = Apollo.QueryResult<Types.IsSimpleUserByProfileIdQuery, Types.IsSimpleUserByProfileIdQueryVariables>;
export const IsPseudoCollectorDocument = gql`
    query isPseudoCollector($profileId: bigint!) {
  Profile(where: {type: {_eq: PSEUDO_COLLECTOR}, id: {_eq: $profileId}}) {
    id
  }
}
    `;

/**
 * __useIsPseudoCollectorQuery__
 *
 * To run a query within a React component, call `useIsPseudoCollectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPseudoCollectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPseudoCollectorQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useIsPseudoCollectorQuery(baseOptions: Apollo.QueryHookOptions<Types.IsPseudoCollectorQuery, Types.IsPseudoCollectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsPseudoCollectorQuery, Types.IsPseudoCollectorQueryVariables>(IsPseudoCollectorDocument, options);
      }
export function useIsPseudoCollectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsPseudoCollectorQuery, Types.IsPseudoCollectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsPseudoCollectorQuery, Types.IsPseudoCollectorQueryVariables>(IsPseudoCollectorDocument, options);
        }
export type IsPseudoCollectorQueryHookResult = ReturnType<typeof useIsPseudoCollectorQuery>;
export type IsPseudoCollectorLazyQueryHookResult = ReturnType<typeof useIsPseudoCollectorLazyQuery>;
export type IsPseudoCollectorQueryResult = Apollo.QueryResult<Types.IsPseudoCollectorQuery, Types.IsPseudoCollectorQueryVariables>;
export const GetUserProfileTypeDocument = gql`
    query getUserProfileType($profileId: bigint!) {
  Profile_by_pk(id: $profileId) {
    id
    type
  }
}
    `;

/**
 * __useGetUserProfileTypeQuery__
 *
 * To run a query within a React component, call `useGetUserProfileTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileTypeQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetUserProfileTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserProfileTypeQuery, Types.GetUserProfileTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserProfileTypeQuery, Types.GetUserProfileTypeQueryVariables>(GetUserProfileTypeDocument, options);
      }
export function useGetUserProfileTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserProfileTypeQuery, Types.GetUserProfileTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserProfileTypeQuery, Types.GetUserProfileTypeQueryVariables>(GetUserProfileTypeDocument, options);
        }
export type GetUserProfileTypeQueryHookResult = ReturnType<typeof useGetUserProfileTypeQuery>;
export type GetUserProfileTypeLazyQueryHookResult = ReturnType<typeof useGetUserProfileTypeLazyQuery>;
export type GetUserProfileTypeQueryResult = Apollo.QueryResult<Types.GetUserProfileTypeQuery, Types.GetUserProfileTypeQueryVariables>;
export const GetUserSubscriptionsDocument = gql`
    query getUserSubscriptions($contextId: bigint!) {
  Billing_subscription(
    where: {billing_subscription_status: {_nin: [CANCELED, PENDING]}, context_id: {_eq: $contextId}}
  ) {
    billing_subscription_status
    billing_subscription_period
    subscription_products {
      type
    }
  }
}
    `;

/**
 * __useGetUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSubscriptionsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetUserSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserSubscriptionsQuery, Types.GetUserSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserSubscriptionsQuery, Types.GetUserSubscriptionsQueryVariables>(GetUserSubscriptionsDocument, options);
      }
export function useGetUserSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserSubscriptionsQuery, Types.GetUserSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserSubscriptionsQuery, Types.GetUserSubscriptionsQueryVariables>(GetUserSubscriptionsDocument, options);
        }
export type GetUserSubscriptionsQueryHookResult = ReturnType<typeof useGetUserSubscriptionsQuery>;
export type GetUserSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetUserSubscriptionsLazyQuery>;
export type GetUserSubscriptionsQueryResult = Apollo.QueryResult<Types.GetUserSubscriptionsQuery, Types.GetUserSubscriptionsQueryVariables>;
export const GetProfileCoinsDocument = gql`
    query getProfileCoins($profileId: bigint!, $contextId: bigint!) {
  Profile_by_pk(id: $profileId) {
    total_points
  }
  kaleido_coins_distribution(where: {context_id: {_eq: $contextId}}) {
    context_id
    percentage
    points
    position
    total_points
    updated_at
    total_positions
  }
}
    `;

/**
 * __useGetProfileCoinsQuery__
 *
 * To run a query within a React component, call `useGetProfileCoinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileCoinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileCoinsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileCoinsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileCoinsQuery, Types.GetProfileCoinsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileCoinsQuery, Types.GetProfileCoinsQueryVariables>(GetProfileCoinsDocument, options);
      }
export function useGetProfileCoinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileCoinsQuery, Types.GetProfileCoinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileCoinsQuery, Types.GetProfileCoinsQueryVariables>(GetProfileCoinsDocument, options);
        }
export type GetProfileCoinsQueryHookResult = ReturnType<typeof useGetProfileCoinsQuery>;
export type GetProfileCoinsLazyQueryHookResult = ReturnType<typeof useGetProfileCoinsLazyQuery>;
export type GetProfileCoinsQueryResult = Apollo.QueryResult<Types.GetProfileCoinsQuery, Types.GetProfileCoinsQueryVariables>;
export const GetAchivementsDocument = gql`
    query getAchivements {
  Achievement_tags {
    achievement {
      description
      amount
    }
    title
  }
}
    `;

/**
 * __useGetAchivementsQuery__
 *
 * To run a query within a React component, call `useGetAchivementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchivementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchivementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAchivementsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAchivementsQuery, Types.GetAchivementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAchivementsQuery, Types.GetAchivementsQueryVariables>(GetAchivementsDocument, options);
      }
export function useGetAchivementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAchivementsQuery, Types.GetAchivementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAchivementsQuery, Types.GetAchivementsQueryVariables>(GetAchivementsDocument, options);
        }
export type GetAchivementsQueryHookResult = ReturnType<typeof useGetAchivementsQuery>;
export type GetAchivementsLazyQueryHookResult = ReturnType<typeof useGetAchivementsLazyQuery>;
export type GetAchivementsQueryResult = Apollo.QueryResult<Types.GetAchivementsQuery, Types.GetAchivementsQueryVariables>;
export const MyAugmentationsDocument = gql`
    query MyAugmentations($contextId: bigint!) {
  Artwork(where: {artist_context: {id: {_eq: $contextId}}}) {
    id
    artist_context_id
    artwork_details {
      title
    }
    has_ar_animation
    is_public
    thumbnail_metadata_id
    vuforia_rating
    status
  }
}
    `;

/**
 * __useMyAugmentationsQuery__
 *
 * To run a query within a React component, call `useMyAugmentationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAugmentationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAugmentationsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useMyAugmentationsQuery(baseOptions: Apollo.QueryHookOptions<Types.MyAugmentationsQuery, Types.MyAugmentationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyAugmentationsQuery, Types.MyAugmentationsQueryVariables>(MyAugmentationsDocument, options);
      }
export function useMyAugmentationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyAugmentationsQuery, Types.MyAugmentationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyAugmentationsQuery, Types.MyAugmentationsQueryVariables>(MyAugmentationsDocument, options);
        }
export type MyAugmentationsQueryHookResult = ReturnType<typeof useMyAugmentationsQuery>;
export type MyAugmentationsLazyQueryHookResult = ReturnType<typeof useMyAugmentationsLazyQuery>;
export type MyAugmentationsQueryResult = Apollo.QueryResult<Types.MyAugmentationsQuery, Types.MyAugmentationsQueryVariables>;
export const GetFollowersLimitedDocument = gql`
    query getFollowersLimited($contextId: bigint!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, target_context_id: {_eq: $contextId}, Social_graph_followers_Context: {type: {_eq: ARTIST}}}
    order_by: {updated_at: desc}
    limit: 6
  ) {
    id
    context_id
    target_context_id
    selected_order
    natural_order
    updated_at
    state
    is_mutual
  }
}
    `;

/**
 * __useGetFollowersLimitedQuery__
 *
 * To run a query within a React component, call `useGetFollowersLimitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowersLimitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowersLimitedQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetFollowersLimitedQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFollowersLimitedQuery, Types.GetFollowersLimitedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFollowersLimitedQuery, Types.GetFollowersLimitedQueryVariables>(GetFollowersLimitedDocument, options);
      }
export function useGetFollowersLimitedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFollowersLimitedQuery, Types.GetFollowersLimitedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFollowersLimitedQuery, Types.GetFollowersLimitedQueryVariables>(GetFollowersLimitedDocument, options);
        }
export type GetFollowersLimitedQueryHookResult = ReturnType<typeof useGetFollowersLimitedQuery>;
export type GetFollowersLimitedLazyQueryHookResult = ReturnType<typeof useGetFollowersLimitedLazyQuery>;
export type GetFollowersLimitedQueryResult = Apollo.QueryResult<Types.GetFollowersLimitedQuery, Types.GetFollowersLimitedQueryVariables>;
export const GetArtworksForChangeArtworksOrderDocument = gql`
    query GetArtworksForChangeArtworksOrder($ownerContextId: bigint!, $collectionType: ENUM_collection_type_enum!) {
  Artwork(
    where: {deleted_at: {_is_null: true}, artwork_to_collections: {Collection: {owner_context_id: {_eq: $ownerContextId}, type: {_eq: $collectionType}}}, is_public: {_eq: true}}
    order_by: [{published_date: desc}]
  ) {
    id
    slug
    thumbnail_metadata_id
    artwork_details {
      title
    }
    artwork_to_collections(where: {Collection: {type: {_eq: $collectionType}}}) {
      collection_id
      order
    }
  }
  Print(
    where: {is_public: {_eq: true}, print_to_collections: {Collection: {owner_context_id: {_eq: $ownerContextId}, type: {_eq: $collectionType}}}}
    order_by: {Artwork: {published_date: desc}}
  ) {
    id
    Artwork {
      id
      slug
      thumbnail_metadata_id
      edition_type
      artwork_details {
        title
      }
    }
    Edition {
      title
    }
    print_to_collections(where: {Collection: {type: {_eq: $collectionType}}}) {
      collection_id
      order
    }
  }
}
    `;

/**
 * __useGetArtworksForChangeArtworksOrderQuery__
 *
 * To run a query within a React component, call `useGetArtworksForChangeArtworksOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworksForChangeArtworksOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworksForChangeArtworksOrderQuery({
 *   variables: {
 *      ownerContextId: // value for 'ownerContextId'
 *      collectionType: // value for 'collectionType'
 *   },
 * });
 */
export function useGetArtworksForChangeArtworksOrderQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworksForChangeArtworksOrderQuery, Types.GetArtworksForChangeArtworksOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworksForChangeArtworksOrderQuery, Types.GetArtworksForChangeArtworksOrderQueryVariables>(GetArtworksForChangeArtworksOrderDocument, options);
      }
export function useGetArtworksForChangeArtworksOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworksForChangeArtworksOrderQuery, Types.GetArtworksForChangeArtworksOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworksForChangeArtworksOrderQuery, Types.GetArtworksForChangeArtworksOrderQueryVariables>(GetArtworksForChangeArtworksOrderDocument, options);
        }
export type GetArtworksForChangeArtworksOrderQueryHookResult = ReturnType<typeof useGetArtworksForChangeArtworksOrderQuery>;
export type GetArtworksForChangeArtworksOrderLazyQueryHookResult = ReturnType<typeof useGetArtworksForChangeArtworksOrderLazyQuery>;
export type GetArtworksForChangeArtworksOrderQueryResult = Apollo.QueryResult<Types.GetArtworksForChangeArtworksOrderQuery, Types.GetArtworksForChangeArtworksOrderQueryVariables>;
export const GetArtworksForChangeArtworksOrderV2Document = gql`
    query GetArtworksForChangeArtworksOrderV2($ownerContextId: bigint!, $collectionType: String!) {
  artworks: Print_artwork_info(
    where: {deleted_at: {_is_null: true}, is_public: {_eq: true}, published_date: {_is_null: false}, owner_context_id: {_eq: $ownerContextId}, collection_type: {_eq: $collectionType}}
    order_by: [{published_date: desc}]
  ) {
    id: artwork_id
    slug
    thumbnail_metadata_id
    title
    collection_id
    order: artwork_collection_order
    collected_at
    published_date
    artwork_type
  }
}
    `;

/**
 * __useGetArtworksForChangeArtworksOrderV2Query__
 *
 * To run a query within a React component, call `useGetArtworksForChangeArtworksOrderV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworksForChangeArtworksOrderV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworksForChangeArtworksOrderV2Query({
 *   variables: {
 *      ownerContextId: // value for 'ownerContextId'
 *      collectionType: // value for 'collectionType'
 *   },
 * });
 */
export function useGetArtworksForChangeArtworksOrderV2Query(baseOptions: Apollo.QueryHookOptions<Types.GetArtworksForChangeArtworksOrderV2Query, Types.GetArtworksForChangeArtworksOrderV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworksForChangeArtworksOrderV2Query, Types.GetArtworksForChangeArtworksOrderV2QueryVariables>(GetArtworksForChangeArtworksOrderV2Document, options);
      }
export function useGetArtworksForChangeArtworksOrderV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworksForChangeArtworksOrderV2Query, Types.GetArtworksForChangeArtworksOrderV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworksForChangeArtworksOrderV2Query, Types.GetArtworksForChangeArtworksOrderV2QueryVariables>(GetArtworksForChangeArtworksOrderV2Document, options);
        }
export type GetArtworksForChangeArtworksOrderV2QueryHookResult = ReturnType<typeof useGetArtworksForChangeArtworksOrderV2Query>;
export type GetArtworksForChangeArtworksOrderV2LazyQueryHookResult = ReturnType<typeof useGetArtworksForChangeArtworksOrderV2LazyQuery>;
export type GetArtworksForChangeArtworksOrderV2QueryResult = Apollo.QueryResult<Types.GetArtworksForChangeArtworksOrderV2Query, Types.GetArtworksForChangeArtworksOrderV2QueryVariables>;
export const InsertArtworkArtUniverseOrderDocument = gql`
    mutation InsertArtworkArtUniverseOrder($artworkId: bigint!, $order: Int!, $collectionId: bigint!) {
  insert_Artwork_to_Collection_one(
    object: {artwork_id: $artworkId, collection_id: $collectionId, order: $order}
    on_conflict: {constraint: Artwork_to_Collection_artwork_id_collection_id_key, update_columns: [order]}
  ) {
    id
    artwork_id
    collection_id
    order
  }
}
    `;
export type InsertArtworkArtUniverseOrderMutationFn = Apollo.MutationFunction<Types.InsertArtworkArtUniverseOrderMutation, Types.InsertArtworkArtUniverseOrderMutationVariables>;

/**
 * __useInsertArtworkArtUniverseOrderMutation__
 *
 * To run a mutation, you first call `useInsertArtworkArtUniverseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertArtworkArtUniverseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertArtworkArtUniverseOrderMutation, { data, loading, error }] = useInsertArtworkArtUniverseOrderMutation({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *      order: // value for 'order'
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useInsertArtworkArtUniverseOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertArtworkArtUniverseOrderMutation, Types.InsertArtworkArtUniverseOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertArtworkArtUniverseOrderMutation, Types.InsertArtworkArtUniverseOrderMutationVariables>(InsertArtworkArtUniverseOrderDocument, options);
      }
export type InsertArtworkArtUniverseOrderMutationHookResult = ReturnType<typeof useInsertArtworkArtUniverseOrderMutation>;
export type InsertArtworkArtUniverseOrderMutationResult = Apollo.MutationResult<Types.InsertArtworkArtUniverseOrderMutation>;
export type InsertArtworkArtUniverseOrderMutationOptions = Apollo.BaseMutationOptions<Types.InsertArtworkArtUniverseOrderMutation, Types.InsertArtworkArtUniverseOrderMutationVariables>;
export const UpdateArtworkOrderDocument = gql`
    mutation UpdateArtworkOrder($artworkId: bigint!, $order: Int!, $collectionId: bigint!) {
  update_Artwork_to_Collection(
    where: {Artwork: {id: {_eq: $artworkId}}, collection_id: {_eq: $collectionId}}
    _set: {order: $order}
  ) {
    returning {
      id
      artwork_id
      collection_id
      order
    }
  }
}
    `;
export type UpdateArtworkOrderMutationFn = Apollo.MutationFunction<Types.UpdateArtworkOrderMutation, Types.UpdateArtworkOrderMutationVariables>;

/**
 * __useUpdateArtworkOrderMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkOrderMutation, { data, loading, error }] = useUpdateArtworkOrderMutation({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *      order: // value for 'order'
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useUpdateArtworkOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkOrderMutation, Types.UpdateArtworkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkOrderMutation, Types.UpdateArtworkOrderMutationVariables>(UpdateArtworkOrderDocument, options);
      }
export type UpdateArtworkOrderMutationHookResult = ReturnType<typeof useUpdateArtworkOrderMutation>;
export type UpdateArtworkOrderMutationResult = Apollo.MutationResult<Types.UpdateArtworkOrderMutation>;
export type UpdateArtworkOrderMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkOrderMutation, Types.UpdateArtworkOrderMutationVariables>;
export const UpdatePrintOrderDocument = gql`
    mutation UpdatePrintOrder($printId: bigint!, $order: Int!, $collectionId: bigint!) {
  update_Print_to_Collection(
    where: {Print: {id: {_eq: $printId}}, collection_id: {_eq: $collectionId}}
    _set: {order: $order}
  ) {
    returning {
      print_id
      collection_id
      order
    }
  }
}
    `;
export type UpdatePrintOrderMutationFn = Apollo.MutationFunction<Types.UpdatePrintOrderMutation, Types.UpdatePrintOrderMutationVariables>;

/**
 * __useUpdatePrintOrderMutation__
 *
 * To run a mutation, you first call `useUpdatePrintOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrintOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrintOrderMutation, { data, loading, error }] = useUpdatePrintOrderMutation({
 *   variables: {
 *      printId: // value for 'printId'
 *      order: // value for 'order'
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useUpdatePrintOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePrintOrderMutation, Types.UpdatePrintOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePrintOrderMutation, Types.UpdatePrintOrderMutationVariables>(UpdatePrintOrderDocument, options);
      }
export type UpdatePrintOrderMutationHookResult = ReturnType<typeof useUpdatePrintOrderMutation>;
export type UpdatePrintOrderMutationResult = Apollo.MutationResult<Types.UpdatePrintOrderMutation>;
export type UpdatePrintOrderMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePrintOrderMutation, Types.UpdatePrintOrderMutationVariables>;
export const GetArtworksCountForArrangeArtworksDocument = gql`
    query getArtworksCountForArrangeArtworks($contextId: bigint!, $collectionType: String!) {
  artworks: aggregateProxy(
    type: GetArtworksCountArrangeArtworks
    variables: {owner_context_id: $contextId, collection_type: $collectionType}
  )
}
    `;

/**
 * __useGetArtworksCountForArrangeArtworksQuery__
 *
 * To run a query within a React component, call `useGetArtworksCountForArrangeArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworksCountForArrangeArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworksCountForArrangeArtworksQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      collectionType: // value for 'collectionType'
 *   },
 * });
 */
export function useGetArtworksCountForArrangeArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworksCountForArrangeArtworksQuery, Types.GetArtworksCountForArrangeArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworksCountForArrangeArtworksQuery, Types.GetArtworksCountForArrangeArtworksQueryVariables>(GetArtworksCountForArrangeArtworksDocument, options);
      }
export function useGetArtworksCountForArrangeArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworksCountForArrangeArtworksQuery, Types.GetArtworksCountForArrangeArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworksCountForArrangeArtworksQuery, Types.GetArtworksCountForArrangeArtworksQueryVariables>(GetArtworksCountForArrangeArtworksDocument, options);
        }
export type GetArtworksCountForArrangeArtworksQueryHookResult = ReturnType<typeof useGetArtworksCountForArrangeArtworksQuery>;
export type GetArtworksCountForArrangeArtworksLazyQueryHookResult = ReturnType<typeof useGetArtworksCountForArrangeArtworksLazyQuery>;
export type GetArtworksCountForArrangeArtworksQueryResult = Apollo.QueryResult<Types.GetArtworksCountForArrangeArtworksQuery, Types.GetArtworksCountForArrangeArtworksQueryVariables>;
export const InsertProfileAppearanceSettinsDocument = gql`
    mutation InsertProfileAppearanceSettins($object: Profile_appearance_settings_insert_input!) {
  insert_Profile_appearance_settings_one(object: $object) {
    id
  }
}
    `;
export type InsertProfileAppearanceSettinsMutationFn = Apollo.MutationFunction<Types.InsertProfileAppearanceSettinsMutation, Types.InsertProfileAppearanceSettinsMutationVariables>;

/**
 * __useInsertProfileAppearanceSettinsMutation__
 *
 * To run a mutation, you first call `useInsertProfileAppearanceSettinsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProfileAppearanceSettinsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProfileAppearanceSettinsMutation, { data, loading, error }] = useInsertProfileAppearanceSettinsMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertProfileAppearanceSettinsMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertProfileAppearanceSettinsMutation, Types.InsertProfileAppearanceSettinsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertProfileAppearanceSettinsMutation, Types.InsertProfileAppearanceSettinsMutationVariables>(InsertProfileAppearanceSettinsDocument, options);
      }
export type InsertProfileAppearanceSettinsMutationHookResult = ReturnType<typeof useInsertProfileAppearanceSettinsMutation>;
export type InsertProfileAppearanceSettinsMutationResult = Apollo.MutationResult<Types.InsertProfileAppearanceSettinsMutation>;
export type InsertProfileAppearanceSettinsMutationOptions = Apollo.BaseMutationOptions<Types.InsertProfileAppearanceSettinsMutation, Types.InsertProfileAppearanceSettinsMutationVariables>;
export const UpdateProfileAppearanceSettingsDocument = gql`
    mutation UpdateProfileAppearanceSettings($profile_id: bigint!, $object: Profile_appearance_settings_set_input!) {
  update_Profile_appearance_settings(
    where: {profile_id: {_eq: $profile_id}}
    _set: $object
  ) {
    affected_rows
  }
}
    `;
export type UpdateProfileAppearanceSettingsMutationFn = Apollo.MutationFunction<Types.UpdateProfileAppearanceSettingsMutation, Types.UpdateProfileAppearanceSettingsMutationVariables>;

/**
 * __useUpdateProfileAppearanceSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProfileAppearanceSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileAppearanceSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileAppearanceSettingsMutation, { data, loading, error }] = useUpdateProfileAppearanceSettingsMutation({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateProfileAppearanceSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfileAppearanceSettingsMutation, Types.UpdateProfileAppearanceSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfileAppearanceSettingsMutation, Types.UpdateProfileAppearanceSettingsMutationVariables>(UpdateProfileAppearanceSettingsDocument, options);
      }
export type UpdateProfileAppearanceSettingsMutationHookResult = ReturnType<typeof useUpdateProfileAppearanceSettingsMutation>;
export type UpdateProfileAppearanceSettingsMutationResult = Apollo.MutationResult<Types.UpdateProfileAppearanceSettingsMutation>;
export type UpdateProfileAppearanceSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfileAppearanceSettingsMutation, Types.UpdateProfileAppearanceSettingsMutationVariables>;
export const GetProfileAppearanceSettingsDocument = gql`
    query GetProfileAppearanceSettings($profile_id: bigint!) {
  Profile_appearance_settings(where: {profile_id: {_eq: $profile_id}}) {
    id
    profile {
      profile_theme
    }
    profile_columns_count
    profile_layout_type
    artwork_info
    artwork_info_trigger
  }
}
    `;

/**
 * __useGetProfileAppearanceSettingsQuery__
 *
 * To run a query within a React component, call `useGetProfileAppearanceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileAppearanceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileAppearanceSettingsQuery({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *   },
 * });
 */
export function useGetProfileAppearanceSettingsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileAppearanceSettingsQuery, Types.GetProfileAppearanceSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileAppearanceSettingsQuery, Types.GetProfileAppearanceSettingsQueryVariables>(GetProfileAppearanceSettingsDocument, options);
      }
export function useGetProfileAppearanceSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileAppearanceSettingsQuery, Types.GetProfileAppearanceSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileAppearanceSettingsQuery, Types.GetProfileAppearanceSettingsQueryVariables>(GetProfileAppearanceSettingsDocument, options);
        }
export type GetProfileAppearanceSettingsQueryHookResult = ReturnType<typeof useGetProfileAppearanceSettingsQuery>;
export type GetProfileAppearanceSettingsLazyQueryHookResult = ReturnType<typeof useGetProfileAppearanceSettingsLazyQuery>;
export type GetProfileAppearanceSettingsQueryResult = Apollo.QueryResult<Types.GetProfileAppearanceSettingsQuery, Types.GetProfileAppearanceSettingsQueryVariables>;
export const GetGalleryProfileIdWithCollaborationCodeDocument = gql`
    query getGalleryProfileIdWithCollaborationCode($code: String!) {
  getGalleryProfileIdWithCollaborationCode(code: $code)
}
    `;

/**
 * __useGetGalleryProfileIdWithCollaborationCodeQuery__
 *
 * To run a query within a React component, call `useGetGalleryProfileIdWithCollaborationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryProfileIdWithCollaborationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryProfileIdWithCollaborationCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetGalleryProfileIdWithCollaborationCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryProfileIdWithCollaborationCodeQuery, Types.GetGalleryProfileIdWithCollaborationCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryProfileIdWithCollaborationCodeQuery, Types.GetGalleryProfileIdWithCollaborationCodeQueryVariables>(GetGalleryProfileIdWithCollaborationCodeDocument, options);
      }
export function useGetGalleryProfileIdWithCollaborationCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryProfileIdWithCollaborationCodeQuery, Types.GetGalleryProfileIdWithCollaborationCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryProfileIdWithCollaborationCodeQuery, Types.GetGalleryProfileIdWithCollaborationCodeQueryVariables>(GetGalleryProfileIdWithCollaborationCodeDocument, options);
        }
export type GetGalleryProfileIdWithCollaborationCodeQueryHookResult = ReturnType<typeof useGetGalleryProfileIdWithCollaborationCodeQuery>;
export type GetGalleryProfileIdWithCollaborationCodeLazyQueryHookResult = ReturnType<typeof useGetGalleryProfileIdWithCollaborationCodeLazyQuery>;
export type GetGalleryProfileIdWithCollaborationCodeQueryResult = Apollo.QueryResult<Types.GetGalleryProfileIdWithCollaborationCodeQuery, Types.GetGalleryProfileIdWithCollaborationCodeQueryVariables>;
export const GetProfileDataFromIdDocument = gql`
    query getProfileDataFromId($id: bigint!) {
  Context_by_pk(id: $id) {
    profile {
      id
      title
      avatar
    }
  }
}
    `;

/**
 * __useGetProfileDataFromIdQuery__
 *
 * To run a query within a React component, call `useGetProfileDataFromIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileDataFromIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileDataFromIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileDataFromIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileDataFromIdQuery, Types.GetProfileDataFromIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileDataFromIdQuery, Types.GetProfileDataFromIdQueryVariables>(GetProfileDataFromIdDocument, options);
      }
export function useGetProfileDataFromIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileDataFromIdQuery, Types.GetProfileDataFromIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileDataFromIdQuery, Types.GetProfileDataFromIdQueryVariables>(GetProfileDataFromIdDocument, options);
        }
export type GetProfileDataFromIdQueryHookResult = ReturnType<typeof useGetProfileDataFromIdQuery>;
export type GetProfileDataFromIdLazyQueryHookResult = ReturnType<typeof useGetProfileDataFromIdLazyQuery>;
export type GetProfileDataFromIdQueryResult = Apollo.QueryResult<Types.GetProfileDataFromIdQuery, Types.GetProfileDataFromIdQueryVariables>;
export const AddCollaborationInviteProfileCodeDocument = gql`
    mutation addCollaborationInviteProfileCode($context_id: Int!, $code: String!) {
  collaborationInviteWithProfileCode(context_id: $context_id, code: $code)
}
    `;
export type AddCollaborationInviteProfileCodeMutationFn = Apollo.MutationFunction<Types.AddCollaborationInviteProfileCodeMutation, Types.AddCollaborationInviteProfileCodeMutationVariables>;

/**
 * __useAddCollaborationInviteProfileCodeMutation__
 *
 * To run a mutation, you first call `useAddCollaborationInviteProfileCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaborationInviteProfileCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaborationInviteProfileCodeMutation, { data, loading, error }] = useAddCollaborationInviteProfileCodeMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAddCollaborationInviteProfileCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddCollaborationInviteProfileCodeMutation, Types.AddCollaborationInviteProfileCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddCollaborationInviteProfileCodeMutation, Types.AddCollaborationInviteProfileCodeMutationVariables>(AddCollaborationInviteProfileCodeDocument, options);
      }
export type AddCollaborationInviteProfileCodeMutationHookResult = ReturnType<typeof useAddCollaborationInviteProfileCodeMutation>;
export type AddCollaborationInviteProfileCodeMutationResult = Apollo.MutationResult<Types.AddCollaborationInviteProfileCodeMutation>;
export type AddCollaborationInviteProfileCodeMutationOptions = Apollo.BaseMutationOptions<Types.AddCollaborationInviteProfileCodeMutation, Types.AddCollaborationInviteProfileCodeMutationVariables>;
export const GetCollaborationCodeOfProfileDocument = gql`
    query getCollaborationCodeOfProfile($profile_id: Int!) {
  getCollaborationCodeOfProfile(profile_id: $profile_id)
}
    `;

/**
 * __useGetCollaborationCodeOfProfileQuery__
 *
 * To run a query within a React component, call `useGetCollaborationCodeOfProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaborationCodeOfProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaborationCodeOfProfileQuery({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *   },
 * });
 */
export function useGetCollaborationCodeOfProfileQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollaborationCodeOfProfileQuery, Types.GetCollaborationCodeOfProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollaborationCodeOfProfileQuery, Types.GetCollaborationCodeOfProfileQueryVariables>(GetCollaborationCodeOfProfileDocument, options);
      }
export function useGetCollaborationCodeOfProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollaborationCodeOfProfileQuery, Types.GetCollaborationCodeOfProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollaborationCodeOfProfileQuery, Types.GetCollaborationCodeOfProfileQueryVariables>(GetCollaborationCodeOfProfileDocument, options);
        }
export type GetCollaborationCodeOfProfileQueryHookResult = ReturnType<typeof useGetCollaborationCodeOfProfileQuery>;
export type GetCollaborationCodeOfProfileLazyQueryHookResult = ReturnType<typeof useGetCollaborationCodeOfProfileLazyQuery>;
export type GetCollaborationCodeOfProfileQueryResult = Apollo.QueryResult<Types.GetCollaborationCodeOfProfileQuery, Types.GetCollaborationCodeOfProfileQueryVariables>;
export const AddGenerateProfileCollaborationCodeDocument = gql`
    mutation addGenerateProfileCollaborationCode($profile_id: Int!) {
  generateProfileCollaborationCode(profile_id: $profile_id)
}
    `;
export type AddGenerateProfileCollaborationCodeMutationFn = Apollo.MutationFunction<Types.AddGenerateProfileCollaborationCodeMutation, Types.AddGenerateProfileCollaborationCodeMutationVariables>;

/**
 * __useAddGenerateProfileCollaborationCodeMutation__
 *
 * To run a mutation, you first call `useAddGenerateProfileCollaborationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGenerateProfileCollaborationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGenerateProfileCollaborationCodeMutation, { data, loading, error }] = useAddGenerateProfileCollaborationCodeMutation({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *   },
 * });
 */
export function useAddGenerateProfileCollaborationCodeMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddGenerateProfileCollaborationCodeMutation, Types.AddGenerateProfileCollaborationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddGenerateProfileCollaborationCodeMutation, Types.AddGenerateProfileCollaborationCodeMutationVariables>(AddGenerateProfileCollaborationCodeDocument, options);
      }
export type AddGenerateProfileCollaborationCodeMutationHookResult = ReturnType<typeof useAddGenerateProfileCollaborationCodeMutation>;
export type AddGenerateProfileCollaborationCodeMutationResult = Apollo.MutationResult<Types.AddGenerateProfileCollaborationCodeMutation>;
export type AddGenerateProfileCollaborationCodeMutationOptions = Apollo.BaseMutationOptions<Types.AddGenerateProfileCollaborationCodeMutation, Types.AddGenerateProfileCollaborationCodeMutationVariables>;
export const GetCollaborationInvitationsDocument = gql`
    query getCollaborationInvitations($id: bigint!) {
  Collaboration(
    where: {_or: [{gallery_id: {_eq: $id}, artist_status: {_in: [PENDING, REJECTED]}, gallery_status: {_eq: ACCEPTED}}, {artist_id: {_eq: $id}, gallery_status: {_in: [PENDING, REJECTED]}, artist_status: {_eq: ACCEPTED}}]}
  ) {
    id
    artist_id
    gallery_id
    artist_status
    gallery_status
    artist_context {
      profile {
        title
        email
      }
    }
    gallery_context {
      profile {
        title
        email
        avatar
      }
    }
  }
}
    `;

/**
 * __useGetCollaborationInvitationsQuery__
 *
 * To run a query within a React component, call `useGetCollaborationInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaborationInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaborationInvitationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollaborationInvitationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollaborationInvitationsQuery, Types.GetCollaborationInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollaborationInvitationsQuery, Types.GetCollaborationInvitationsQueryVariables>(GetCollaborationInvitationsDocument, options);
      }
export function useGetCollaborationInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollaborationInvitationsQuery, Types.GetCollaborationInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollaborationInvitationsQuery, Types.GetCollaborationInvitationsQueryVariables>(GetCollaborationInvitationsDocument, options);
        }
export type GetCollaborationInvitationsQueryHookResult = ReturnType<typeof useGetCollaborationInvitationsQuery>;
export type GetCollaborationInvitationsLazyQueryHookResult = ReturnType<typeof useGetCollaborationInvitationsLazyQuery>;
export type GetCollaborationInvitationsQueryResult = Apollo.QueryResult<Types.GetCollaborationInvitationsQuery, Types.GetCollaborationInvitationsQueryVariables>;
export const GetProfilesInvitedByEmailDocument = gql`
    query getProfilesInvitedByEmail($context_id: bigint!, $type: String!) {
  Profile_invitations(
    where: {inviter_context_id: {_eq: $context_id}, status: {_eq: PENDING}, invitee_context_type: {_eq: $type}}
  ) {
    id
    invitee_email
  }
}
    `;

/**
 * __useGetProfilesInvitedByEmailQuery__
 *
 * To run a query within a React component, call `useGetProfilesInvitedByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilesInvitedByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilesInvitedByEmailQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetProfilesInvitedByEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfilesInvitedByEmailQuery, Types.GetProfilesInvitedByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfilesInvitedByEmailQuery, Types.GetProfilesInvitedByEmailQueryVariables>(GetProfilesInvitedByEmailDocument, options);
      }
export function useGetProfilesInvitedByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfilesInvitedByEmailQuery, Types.GetProfilesInvitedByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfilesInvitedByEmailQuery, Types.GetProfilesInvitedByEmailQueryVariables>(GetProfilesInvitedByEmailDocument, options);
        }
export type GetProfilesInvitedByEmailQueryHookResult = ReturnType<typeof useGetProfilesInvitedByEmailQuery>;
export type GetProfilesInvitedByEmailLazyQueryHookResult = ReturnType<typeof useGetProfilesInvitedByEmailLazyQuery>;
export type GetProfilesInvitedByEmailQueryResult = Apollo.QueryResult<Types.GetProfilesInvitedByEmailQuery, Types.GetProfilesInvitedByEmailQueryVariables>;
export const GetSearchGalleriesByEmailDocument = gql`
    query getSearchGalleriesByEmail($email: String = "") {
  All_galleries(where: {email: {_eq: $email}}) {
    title
    context_id
    email
  }
}
    `;

/**
 * __useGetSearchGalleriesByEmailQuery__
 *
 * To run a query within a React component, call `useGetSearchGalleriesByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchGalleriesByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchGalleriesByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetSearchGalleriesByEmailQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSearchGalleriesByEmailQuery, Types.GetSearchGalleriesByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSearchGalleriesByEmailQuery, Types.GetSearchGalleriesByEmailQueryVariables>(GetSearchGalleriesByEmailDocument, options);
      }
export function useGetSearchGalleriesByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchGalleriesByEmailQuery, Types.GetSearchGalleriesByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSearchGalleriesByEmailQuery, Types.GetSearchGalleriesByEmailQueryVariables>(GetSearchGalleriesByEmailDocument, options);
        }
export type GetSearchGalleriesByEmailQueryHookResult = ReturnType<typeof useGetSearchGalleriesByEmailQuery>;
export type GetSearchGalleriesByEmailLazyQueryHookResult = ReturnType<typeof useGetSearchGalleriesByEmailLazyQuery>;
export type GetSearchGalleriesByEmailQueryResult = Apollo.QueryResult<Types.GetSearchGalleriesByEmailQuery, Types.GetSearchGalleriesByEmailQueryVariables>;
export const GetSearchArtistsByEmailDocument = gql`
    query getSearchArtistsByEmail($email: String = "") {
  All_artists(where: {email: {_eq: $email}}) {
    title
    context_id
    email
  }
}
    `;

/**
 * __useGetSearchArtistsByEmailQuery__
 *
 * To run a query within a React component, call `useGetSearchArtistsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchArtistsByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchArtistsByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetSearchArtistsByEmailQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSearchArtistsByEmailQuery, Types.GetSearchArtistsByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSearchArtistsByEmailQuery, Types.GetSearchArtistsByEmailQueryVariables>(GetSearchArtistsByEmailDocument, options);
      }
export function useGetSearchArtistsByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSearchArtistsByEmailQuery, Types.GetSearchArtistsByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSearchArtistsByEmailQuery, Types.GetSearchArtistsByEmailQueryVariables>(GetSearchArtistsByEmailDocument, options);
        }
export type GetSearchArtistsByEmailQueryHookResult = ReturnType<typeof useGetSearchArtistsByEmailQuery>;
export type GetSearchArtistsByEmailLazyQueryHookResult = ReturnType<typeof useGetSearchArtistsByEmailLazyQuery>;
export type GetSearchArtistsByEmailQueryResult = Apollo.QueryResult<Types.GetSearchArtistsByEmailQuery, Types.GetSearchArtistsByEmailQueryVariables>;
export const AddArtistInviteGalleryDocument = gql`
    mutation addArtistInviteGallery($context_id: Int!, $email: String!, $invitee_context_id: Int, $language: String) {
  artistInviteGalleryCollaboration(
    context_id: $context_id
    email: $email
    invitee_context_id: $invitee_context_id
    language: $language
  ) {
    id
    type
  }
}
    `;
export type AddArtistInviteGalleryMutationFn = Apollo.MutationFunction<Types.AddArtistInviteGalleryMutation, Types.AddArtistInviteGalleryMutationVariables>;

/**
 * __useAddArtistInviteGalleryMutation__
 *
 * To run a mutation, you first call `useAddArtistInviteGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtistInviteGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtistInviteGalleryMutation, { data, loading, error }] = useAddArtistInviteGalleryMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      email: // value for 'email'
 *      invitee_context_id: // value for 'invitee_context_id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useAddArtistInviteGalleryMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddArtistInviteGalleryMutation, Types.AddArtistInviteGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddArtistInviteGalleryMutation, Types.AddArtistInviteGalleryMutationVariables>(AddArtistInviteGalleryDocument, options);
      }
export type AddArtistInviteGalleryMutationHookResult = ReturnType<typeof useAddArtistInviteGalleryMutation>;
export type AddArtistInviteGalleryMutationResult = Apollo.MutationResult<Types.AddArtistInviteGalleryMutation>;
export type AddArtistInviteGalleryMutationOptions = Apollo.BaseMutationOptions<Types.AddArtistInviteGalleryMutation, Types.AddArtistInviteGalleryMutationVariables>;
export const AddGalleryInviteArtistDocument = gql`
    mutation addGalleryInviteArtist($context_id: Int!, $email: String!, $invitee_context_id: Int, $language: String) {
  galleryInviteArtistCollaboration(
    context_id: $context_id
    email: $email
    invitee_context_id: $invitee_context_id
    language: $language
  ) {
    id
    type
  }
}
    `;
export type AddGalleryInviteArtistMutationFn = Apollo.MutationFunction<Types.AddGalleryInviteArtistMutation, Types.AddGalleryInviteArtistMutationVariables>;

/**
 * __useAddGalleryInviteArtistMutation__
 *
 * To run a mutation, you first call `useAddGalleryInviteArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGalleryInviteArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGalleryInviteArtistMutation, { data, loading, error }] = useAddGalleryInviteArtistMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      email: // value for 'email'
 *      invitee_context_id: // value for 'invitee_context_id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useAddGalleryInviteArtistMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddGalleryInviteArtistMutation, Types.AddGalleryInviteArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddGalleryInviteArtistMutation, Types.AddGalleryInviteArtistMutationVariables>(AddGalleryInviteArtistDocument, options);
      }
export type AddGalleryInviteArtistMutationHookResult = ReturnType<typeof useAddGalleryInviteArtistMutation>;
export type AddGalleryInviteArtistMutationResult = Apollo.MutationResult<Types.AddGalleryInviteArtistMutation>;
export type AddGalleryInviteArtistMutationOptions = Apollo.BaseMutationOptions<Types.AddGalleryInviteArtistMutation, Types.AddGalleryInviteArtistMutationVariables>;
export const AddRevokeCollaborationDocument = gql`
    mutation addRevokeCollaboration($collaboration_id: Int!) {
  revokeCollaboration(collaboration_id: $collaboration_id)
}
    `;
export type AddRevokeCollaborationMutationFn = Apollo.MutationFunction<Types.AddRevokeCollaborationMutation, Types.AddRevokeCollaborationMutationVariables>;

/**
 * __useAddRevokeCollaborationMutation__
 *
 * To run a mutation, you first call `useAddRevokeCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRevokeCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRevokeCollaborationMutation, { data, loading, error }] = useAddRevokeCollaborationMutation({
 *   variables: {
 *      collaboration_id: // value for 'collaboration_id'
 *   },
 * });
 */
export function useAddRevokeCollaborationMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddRevokeCollaborationMutation, Types.AddRevokeCollaborationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddRevokeCollaborationMutation, Types.AddRevokeCollaborationMutationVariables>(AddRevokeCollaborationDocument, options);
      }
export type AddRevokeCollaborationMutationHookResult = ReturnType<typeof useAddRevokeCollaborationMutation>;
export type AddRevokeCollaborationMutationResult = Apollo.MutationResult<Types.AddRevokeCollaborationMutation>;
export type AddRevokeCollaborationMutationOptions = Apollo.BaseMutationOptions<Types.AddRevokeCollaborationMutation, Types.AddRevokeCollaborationMutationVariables>;
export const AddRevokeInvitationDocument = gql`
    mutation addRevokeInvitation($invitation_id: Int!) {
  revokeInvitation(invitation_id: $invitation_id)
}
    `;
export type AddRevokeInvitationMutationFn = Apollo.MutationFunction<Types.AddRevokeInvitationMutation, Types.AddRevokeInvitationMutationVariables>;

/**
 * __useAddRevokeInvitationMutation__
 *
 * To run a mutation, you first call `useAddRevokeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRevokeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRevokeInvitationMutation, { data, loading, error }] = useAddRevokeInvitationMutation({
 *   variables: {
 *      invitation_id: // value for 'invitation_id'
 *   },
 * });
 */
export function useAddRevokeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddRevokeInvitationMutation, Types.AddRevokeInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddRevokeInvitationMutation, Types.AddRevokeInvitationMutationVariables>(AddRevokeInvitationDocument, options);
      }
export type AddRevokeInvitationMutationHookResult = ReturnType<typeof useAddRevokeInvitationMutation>;
export type AddRevokeInvitationMutationResult = Apollo.MutationResult<Types.AddRevokeInvitationMutation>;
export type AddRevokeInvitationMutationOptions = Apollo.BaseMutationOptions<Types.AddRevokeInvitationMutation, Types.AddRevokeInvitationMutationVariables>;
export const AddArtistAcceptGalleryDocument = gql`
    mutation addArtistAcceptGallery($collaboration_id: Int!) {
  artistAcceptInvitation(collaboration_id: $collaboration_id)
}
    `;
export type AddArtistAcceptGalleryMutationFn = Apollo.MutationFunction<Types.AddArtistAcceptGalleryMutation, Types.AddArtistAcceptGalleryMutationVariables>;

/**
 * __useAddArtistAcceptGalleryMutation__
 *
 * To run a mutation, you first call `useAddArtistAcceptGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtistAcceptGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtistAcceptGalleryMutation, { data, loading, error }] = useAddArtistAcceptGalleryMutation({
 *   variables: {
 *      collaboration_id: // value for 'collaboration_id'
 *   },
 * });
 */
export function useAddArtistAcceptGalleryMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddArtistAcceptGalleryMutation, Types.AddArtistAcceptGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddArtistAcceptGalleryMutation, Types.AddArtistAcceptGalleryMutationVariables>(AddArtistAcceptGalleryDocument, options);
      }
export type AddArtistAcceptGalleryMutationHookResult = ReturnType<typeof useAddArtistAcceptGalleryMutation>;
export type AddArtistAcceptGalleryMutationResult = Apollo.MutationResult<Types.AddArtistAcceptGalleryMutation>;
export type AddArtistAcceptGalleryMutationOptions = Apollo.BaseMutationOptions<Types.AddArtistAcceptGalleryMutation, Types.AddArtistAcceptGalleryMutationVariables>;
export const AddArtistRejectGalleryDocument = gql`
    mutation addArtistRejectGallery($collaboration_id: Int!) {
  artistRejectInvitation(collaboration_id: $collaboration_id)
}
    `;
export type AddArtistRejectGalleryMutationFn = Apollo.MutationFunction<Types.AddArtistRejectGalleryMutation, Types.AddArtistRejectGalleryMutationVariables>;

/**
 * __useAddArtistRejectGalleryMutation__
 *
 * To run a mutation, you first call `useAddArtistRejectGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtistRejectGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtistRejectGalleryMutation, { data, loading, error }] = useAddArtistRejectGalleryMutation({
 *   variables: {
 *      collaboration_id: // value for 'collaboration_id'
 *   },
 * });
 */
export function useAddArtistRejectGalleryMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddArtistRejectGalleryMutation, Types.AddArtistRejectGalleryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddArtistRejectGalleryMutation, Types.AddArtistRejectGalleryMutationVariables>(AddArtistRejectGalleryDocument, options);
      }
export type AddArtistRejectGalleryMutationHookResult = ReturnType<typeof useAddArtistRejectGalleryMutation>;
export type AddArtistRejectGalleryMutationResult = Apollo.MutationResult<Types.AddArtistRejectGalleryMutation>;
export type AddArtistRejectGalleryMutationOptions = Apollo.BaseMutationOptions<Types.AddArtistRejectGalleryMutation, Types.AddArtistRejectGalleryMutationVariables>;
export const AddGalleryAcceptArtistDocument = gql`
    mutation addGalleryAcceptArtist($collaboration_id: Int!) {
  galleryAcceptInvitation(collaboration_id: $collaboration_id)
}
    `;
export type AddGalleryAcceptArtistMutationFn = Apollo.MutationFunction<Types.AddGalleryAcceptArtistMutation, Types.AddGalleryAcceptArtistMutationVariables>;

/**
 * __useAddGalleryAcceptArtistMutation__
 *
 * To run a mutation, you first call `useAddGalleryAcceptArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGalleryAcceptArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGalleryAcceptArtistMutation, { data, loading, error }] = useAddGalleryAcceptArtistMutation({
 *   variables: {
 *      collaboration_id: // value for 'collaboration_id'
 *   },
 * });
 */
export function useAddGalleryAcceptArtistMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddGalleryAcceptArtistMutation, Types.AddGalleryAcceptArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddGalleryAcceptArtistMutation, Types.AddGalleryAcceptArtistMutationVariables>(AddGalleryAcceptArtistDocument, options);
      }
export type AddGalleryAcceptArtistMutationHookResult = ReturnType<typeof useAddGalleryAcceptArtistMutation>;
export type AddGalleryAcceptArtistMutationResult = Apollo.MutationResult<Types.AddGalleryAcceptArtistMutation>;
export type AddGalleryAcceptArtistMutationOptions = Apollo.BaseMutationOptions<Types.AddGalleryAcceptArtistMutation, Types.AddGalleryAcceptArtistMutationVariables>;
export const AddGalleryRejectArtistDocument = gql`
    mutation addGalleryRejectArtist($collaboration_id: Int!) {
  galleryRejectInvitation(collaboration_id: $collaboration_id)
}
    `;
export type AddGalleryRejectArtistMutationFn = Apollo.MutationFunction<Types.AddGalleryRejectArtistMutation, Types.AddGalleryRejectArtistMutationVariables>;

/**
 * __useAddGalleryRejectArtistMutation__
 *
 * To run a mutation, you first call `useAddGalleryRejectArtistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGalleryRejectArtistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGalleryRejectArtistMutation, { data, loading, error }] = useAddGalleryRejectArtistMutation({
 *   variables: {
 *      collaboration_id: // value for 'collaboration_id'
 *   },
 * });
 */
export function useAddGalleryRejectArtistMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddGalleryRejectArtistMutation, Types.AddGalleryRejectArtistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddGalleryRejectArtistMutation, Types.AddGalleryRejectArtistMutationVariables>(AddGalleryRejectArtistDocument, options);
      }
export type AddGalleryRejectArtistMutationHookResult = ReturnType<typeof useAddGalleryRejectArtistMutation>;
export type AddGalleryRejectArtistMutationResult = Apollo.MutationResult<Types.AddGalleryRejectArtistMutation>;
export type AddGalleryRejectArtistMutationOptions = Apollo.BaseMutationOptions<Types.AddGalleryRejectArtistMutation, Types.AddGalleryRejectArtistMutationVariables>;
export const AddResendProfileInvitationDocument = gql`
    mutation addResendProfileInvitation($invitation_id: Int!) {
  resendInvitation(invitation_id: $invitation_id)
}
    `;
export type AddResendProfileInvitationMutationFn = Apollo.MutationFunction<Types.AddResendProfileInvitationMutation, Types.AddResendProfileInvitationMutationVariables>;

/**
 * __useAddResendProfileInvitationMutation__
 *
 * To run a mutation, you first call `useAddResendProfileInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddResendProfileInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addResendProfileInvitationMutation, { data, loading, error }] = useAddResendProfileInvitationMutation({
 *   variables: {
 *      invitation_id: // value for 'invitation_id'
 *   },
 * });
 */
export function useAddResendProfileInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddResendProfileInvitationMutation, Types.AddResendProfileInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddResendProfileInvitationMutation, Types.AddResendProfileInvitationMutationVariables>(AddResendProfileInvitationDocument, options);
      }
export type AddResendProfileInvitationMutationHookResult = ReturnType<typeof useAddResendProfileInvitationMutation>;
export type AddResendProfileInvitationMutationResult = Apollo.MutationResult<Types.AddResendProfileInvitationMutation>;
export type AddResendProfileInvitationMutationOptions = Apollo.BaseMutationOptions<Types.AddResendProfileInvitationMutation, Types.AddResendProfileInvitationMutationVariables>;
export const AddResendCollaborationInvitationDocument = gql`
    mutation addResendCollaborationInvitation($collaboration_id: Int!) {
  resendCollaborationInvitation(collaboration_id: $collaboration_id)
}
    `;
export type AddResendCollaborationInvitationMutationFn = Apollo.MutationFunction<Types.AddResendCollaborationInvitationMutation, Types.AddResendCollaborationInvitationMutationVariables>;

/**
 * __useAddResendCollaborationInvitationMutation__
 *
 * To run a mutation, you first call `useAddResendCollaborationInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddResendCollaborationInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addResendCollaborationInvitationMutation, { data, loading, error }] = useAddResendCollaborationInvitationMutation({
 *   variables: {
 *      collaboration_id: // value for 'collaboration_id'
 *   },
 * });
 */
export function useAddResendCollaborationInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddResendCollaborationInvitationMutation, Types.AddResendCollaborationInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddResendCollaborationInvitationMutation, Types.AddResendCollaborationInvitationMutationVariables>(AddResendCollaborationInvitationDocument, options);
      }
export type AddResendCollaborationInvitationMutationHookResult = ReturnType<typeof useAddResendCollaborationInvitationMutation>;
export type AddResendCollaborationInvitationMutationResult = Apollo.MutationResult<Types.AddResendCollaborationInvitationMutation>;
export type AddResendCollaborationInvitationMutationOptions = Apollo.BaseMutationOptions<Types.AddResendCollaborationInvitationMutation, Types.AddResendCollaborationInvitationMutationVariables>;
export const GetShareKaleidoDataByArtistDocument = gql`
    query getShareKaleidoDataByArtist($contextId: bigint!) {
  Collection(
    where: {type: {_eq: SERIES}, owner_context_id: {_eq: $contextId}, artwork_to_collections: {Artwork: {is_public: {_eq: true}}}}
  ) {
    name
  }
  Artwork_details(
    distinct_on: creation_date_year
    where: {creation_date_year: {_is_null: false}, artwork: {is_public: {_eq: true}, artist_context_id: {_eq: $contextId}}}
  ) {
    creation_date_year
  }
}
    `;

/**
 * __useGetShareKaleidoDataByArtistQuery__
 *
 * To run a query within a React component, call `useGetShareKaleidoDataByArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShareKaleidoDataByArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShareKaleidoDataByArtistQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetShareKaleidoDataByArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShareKaleidoDataByArtistQuery, Types.GetShareKaleidoDataByArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShareKaleidoDataByArtistQuery, Types.GetShareKaleidoDataByArtistQueryVariables>(GetShareKaleidoDataByArtistDocument, options);
      }
export function useGetShareKaleidoDataByArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShareKaleidoDataByArtistQuery, Types.GetShareKaleidoDataByArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShareKaleidoDataByArtistQuery, Types.GetShareKaleidoDataByArtistQueryVariables>(GetShareKaleidoDataByArtistDocument, options);
        }
export type GetShareKaleidoDataByArtistQueryHookResult = ReturnType<typeof useGetShareKaleidoDataByArtistQuery>;
export type GetShareKaleidoDataByArtistLazyQueryHookResult = ReturnType<typeof useGetShareKaleidoDataByArtistLazyQuery>;
export type GetShareKaleidoDataByArtistQueryResult = Apollo.QueryResult<Types.GetShareKaleidoDataByArtistQuery, Types.GetShareKaleidoDataByArtistQueryVariables>;
export const GetPendingArtistInvitationsDocument = gql`
    query GetPendingArtistInvitations($contextId: bigint!) {
  Profile_invitations(
    where: {inviter_context_id: {_eq: $contextId}, status: {_eq: PENDING}, invitee_context_type: {_eq: "ARTIST"}}
    order_by: {created_at: desc}
  ) {
    invitee_email
    invitee_first_name
    id
    language
  }
}
    `;

/**
 * __useGetPendingArtistInvitationsQuery__
 *
 * To run a query within a React component, call `useGetPendingArtistInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingArtistInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingArtistInvitationsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetPendingArtistInvitationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPendingArtistInvitationsQuery, Types.GetPendingArtistInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPendingArtistInvitationsQuery, Types.GetPendingArtistInvitationsQueryVariables>(GetPendingArtistInvitationsDocument, options);
      }
export function useGetPendingArtistInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPendingArtistInvitationsQuery, Types.GetPendingArtistInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPendingArtistInvitationsQuery, Types.GetPendingArtistInvitationsQueryVariables>(GetPendingArtistInvitationsDocument, options);
        }
export type GetPendingArtistInvitationsQueryHookResult = ReturnType<typeof useGetPendingArtistInvitationsQuery>;
export type GetPendingArtistInvitationsLazyQueryHookResult = ReturnType<typeof useGetPendingArtistInvitationsLazyQuery>;
export type GetPendingArtistInvitationsQueryResult = Apollo.QueryResult<Types.GetPendingArtistInvitationsQuery, Types.GetPendingArtistInvitationsQueryVariables>;
export const GetAcceptedArtistInvitationsDocument = gql`
    query GetAcceptedArtistInvitations($contextId: bigint!) {
  Profile_invitations(
    where: {inviter_context_id: {_eq: $contextId}, invitee_context_type: {_eq: "ARTIST"}, status: {_neq: PENDING}}
    order_by: {created_at: desc}
  ) {
    invitee_email
    invitee_first_name
    language
    id
    invitee_context {
      profile {
        avatar
        id
        handle
        title
      }
    }
  }
}
    `;

/**
 * __useGetAcceptedArtistInvitationsQuery__
 *
 * To run a query within a React component, call `useGetAcceptedArtistInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcceptedArtistInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcceptedArtistInvitationsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetAcceptedArtistInvitationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAcceptedArtistInvitationsQuery, Types.GetAcceptedArtistInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAcceptedArtistInvitationsQuery, Types.GetAcceptedArtistInvitationsQueryVariables>(GetAcceptedArtistInvitationsDocument, options);
      }
export function useGetAcceptedArtistInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAcceptedArtistInvitationsQuery, Types.GetAcceptedArtistInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAcceptedArtistInvitationsQuery, Types.GetAcceptedArtistInvitationsQueryVariables>(GetAcceptedArtistInvitationsDocument, options);
        }
export type GetAcceptedArtistInvitationsQueryHookResult = ReturnType<typeof useGetAcceptedArtistInvitationsQuery>;
export type GetAcceptedArtistInvitationsLazyQueryHookResult = ReturnType<typeof useGetAcceptedArtistInvitationsLazyQuery>;
export type GetAcceptedArtistInvitationsQueryResult = Apollo.QueryResult<Types.GetAcceptedArtistInvitationsQuery, Types.GetAcceptedArtistInvitationsQueryVariables>;
export const GetPendingCollectorInvitationsDocument = gql`
    query GetPendingCollectorInvitations($contextId: bigint!) {
  Profile_invitations(
    where: {inviter_context_id: {_eq: $contextId}, status: {_eq: PENDING}, invitee_context_type: {_eq: "COLLECTOR"}}
    order_by: {created_at: desc}
  ) {
    invitee_email
    invitee_first_name
    id
    language
  }
}
    `;

/**
 * __useGetPendingCollectorInvitationsQuery__
 *
 * To run a query within a React component, call `useGetPendingCollectorInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingCollectorInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingCollectorInvitationsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetPendingCollectorInvitationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPendingCollectorInvitationsQuery, Types.GetPendingCollectorInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPendingCollectorInvitationsQuery, Types.GetPendingCollectorInvitationsQueryVariables>(GetPendingCollectorInvitationsDocument, options);
      }
export function useGetPendingCollectorInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPendingCollectorInvitationsQuery, Types.GetPendingCollectorInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPendingCollectorInvitationsQuery, Types.GetPendingCollectorInvitationsQueryVariables>(GetPendingCollectorInvitationsDocument, options);
        }
export type GetPendingCollectorInvitationsQueryHookResult = ReturnType<typeof useGetPendingCollectorInvitationsQuery>;
export type GetPendingCollectorInvitationsLazyQueryHookResult = ReturnType<typeof useGetPendingCollectorInvitationsLazyQuery>;
export type GetPendingCollectorInvitationsQueryResult = Apollo.QueryResult<Types.GetPendingCollectorInvitationsQuery, Types.GetPendingCollectorInvitationsQueryVariables>;
export const GetAcceptedCollectorInvitationsDocument = gql`
    query GetAcceptedCollectorInvitations($contextId: bigint!) {
  Profile_invitations(
    where: {inviter_context_id: {_eq: $contextId}, invitee_context_type: {_eq: "COLLECTOR"}, status: {_neq: PENDING}}
    order_by: {created_at: desc}
  ) {
    invitee_email
    invitee_first_name
    language
    id
    invitee_context {
      profile {
        avatar
        id
        handle
        title
      }
    }
  }
}
    `;

/**
 * __useGetAcceptedCollectorInvitationsQuery__
 *
 * To run a query within a React component, call `useGetAcceptedCollectorInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAcceptedCollectorInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAcceptedCollectorInvitationsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetAcceptedCollectorInvitationsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAcceptedCollectorInvitationsQuery, Types.GetAcceptedCollectorInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAcceptedCollectorInvitationsQuery, Types.GetAcceptedCollectorInvitationsQueryVariables>(GetAcceptedCollectorInvitationsDocument, options);
      }
export function useGetAcceptedCollectorInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAcceptedCollectorInvitationsQuery, Types.GetAcceptedCollectorInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAcceptedCollectorInvitationsQuery, Types.GetAcceptedCollectorInvitationsQueryVariables>(GetAcceptedCollectorInvitationsDocument, options);
        }
export type GetAcceptedCollectorInvitationsQueryHookResult = ReturnType<typeof useGetAcceptedCollectorInvitationsQuery>;
export type GetAcceptedCollectorInvitationsLazyQueryHookResult = ReturnType<typeof useGetAcceptedCollectorInvitationsLazyQuery>;
export type GetAcceptedCollectorInvitationsQueryResult = Apollo.QueryResult<Types.GetAcceptedCollectorInvitationsQuery, Types.GetAcceptedCollectorInvitationsQueryVariables>;
export const GetInvitationIdDocument = gql`
    query GetInvitationId($contextId: bigint!, $inviteeType: String!, $inviteeEmail: String!) {
  Profile_invitations(
    where: {inviter_context_id: {_eq: $contextId}, invitee_context_type: {_eq: $inviteeType}, invitee_email: {_eq: $inviteeEmail}}
  ) {
    id
  }
}
    `;

/**
 * __useGetInvitationIdQuery__
 *
 * To run a query within a React component, call `useGetInvitationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      inviteeType: // value for 'inviteeType'
 *      inviteeEmail: // value for 'inviteeEmail'
 *   },
 * });
 */
export function useGetInvitationIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInvitationIdQuery, Types.GetInvitationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInvitationIdQuery, Types.GetInvitationIdQueryVariables>(GetInvitationIdDocument, options);
      }
export function useGetInvitationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInvitationIdQuery, Types.GetInvitationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInvitationIdQuery, Types.GetInvitationIdQueryVariables>(GetInvitationIdDocument, options);
        }
export type GetInvitationIdQueryHookResult = ReturnType<typeof useGetInvitationIdQuery>;
export type GetInvitationIdLazyQueryHookResult = ReturnType<typeof useGetInvitationIdLazyQuery>;
export type GetInvitationIdQueryResult = Apollo.QueryResult<Types.GetInvitationIdQuery, Types.GetInvitationIdQueryVariables>;
export const ResendInvitationDocument = gql`
    mutation resendInvitation($invitation_id: Int = 10) {
  resendInvitation(invitation_id: $invitation_id)
}
    `;
export type ResendInvitationMutationFn = Apollo.MutationFunction<Types.ResendInvitationMutation, Types.ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      invitation_id: // value for 'invitation_id'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResendInvitationMutation, Types.ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResendInvitationMutation, Types.ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<Types.ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<Types.ResendInvitationMutation, Types.ResendInvitationMutationVariables>;
export const RevokeInvitationDocument = gql`
    mutation revokeInvitation($invitationId: Int!) {
  revokeInvitation(invitation_id: $invitationId)
}
    `;
export type RevokeInvitationMutationFn = Apollo.MutationFunction<Types.RevokeInvitationMutation, Types.RevokeInvitationMutationVariables>;

/**
 * __useRevokeInvitationMutation__
 *
 * To run a mutation, you first call `useRevokeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInvitationMutation, { data, loading, error }] = useRevokeInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useRevokeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RevokeInvitationMutation, Types.RevokeInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RevokeInvitationMutation, Types.RevokeInvitationMutationVariables>(RevokeInvitationDocument, options);
      }
export type RevokeInvitationMutationHookResult = ReturnType<typeof useRevokeInvitationMutation>;
export type RevokeInvitationMutationResult = Apollo.MutationResult<Types.RevokeInvitationMutation>;
export type RevokeInvitationMutationOptions = Apollo.BaseMutationOptions<Types.RevokeInvitationMutation, Types.RevokeInvitationMutationVariables>;
export const SendInvitationDocument = gql`
    mutation sendInvitation($contextId: Int!, $inviteeEmail: String!, $inviteeType: String!, $inviteeName: String, $language: String) {
  sendSimpleInvitation(
    contextId: $contextId
    inviteeEmail: $inviteeEmail
    inviteeType: $inviteeType
    inviteeName: $inviteeName
    language: $language
  )
}
    `;
export type SendInvitationMutationFn = Apollo.MutationFunction<Types.SendInvitationMutation, Types.SendInvitationMutationVariables>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      inviteeEmail: // value for 'inviteeEmail'
 *      inviteeType: // value for 'inviteeType'
 *      inviteeName: // value for 'inviteeName'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendInvitationMutation, Types.SendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendInvitationMutation, Types.SendInvitationMutationVariables>(SendInvitationDocument, options);
      }
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>;
export type SendInvitationMutationResult = Apollo.MutationResult<Types.SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<Types.SendInvitationMutation, Types.SendInvitationMutationVariables>;
export const GetEmailPreferedLanguagesDocument = gql`
    query GetEmailPreferedLanguages {
  ENUM_email_language {
    value
    description
  }
}
    `;

/**
 * __useGetEmailPreferedLanguagesQuery__
 *
 * To run a query within a React component, call `useGetEmailPreferedLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailPreferedLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailPreferedLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailPreferedLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetEmailPreferedLanguagesQuery, Types.GetEmailPreferedLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEmailPreferedLanguagesQuery, Types.GetEmailPreferedLanguagesQueryVariables>(GetEmailPreferedLanguagesDocument, options);
      }
export function useGetEmailPreferedLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEmailPreferedLanguagesQuery, Types.GetEmailPreferedLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEmailPreferedLanguagesQuery, Types.GetEmailPreferedLanguagesQueryVariables>(GetEmailPreferedLanguagesDocument, options);
        }
export type GetEmailPreferedLanguagesQueryHookResult = ReturnType<typeof useGetEmailPreferedLanguagesQuery>;
export type GetEmailPreferedLanguagesLazyQueryHookResult = ReturnType<typeof useGetEmailPreferedLanguagesLazyQuery>;
export type GetEmailPreferedLanguagesQueryResult = Apollo.QueryResult<Types.GetEmailPreferedLanguagesQuery, Types.GetEmailPreferedLanguagesQueryVariables>;
export const GetShowroomsDocument = gql`
    query getShowrooms($context_id: bigint!) {
  Collection(
    where: {type: {_eq: SHOWROOM}, _or: [{owner_context_id: {_eq: $context_id}}, {Showroom_detail: {creator_context_id: {_eq: $context_id}}}]}
    order_by: {id: desc}
  ) {
    id
    name
    description
    Showroom_detail {
      expiration_date
      views
      currency
      slug
      owner_password {
        password
      }
    }
  }
}
    `;

/**
 * __useGetShowroomsQuery__
 *
 * To run a query within a React component, call `useGetShowroomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomsQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetShowroomsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowroomsQuery, Types.GetShowroomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowroomsQuery, Types.GetShowroomsQueryVariables>(GetShowroomsDocument, options);
      }
export function useGetShowroomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowroomsQuery, Types.GetShowroomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowroomsQuery, Types.GetShowroomsQueryVariables>(GetShowroomsDocument, options);
        }
export type GetShowroomsQueryHookResult = ReturnType<typeof useGetShowroomsQuery>;
export type GetShowroomsLazyQueryHookResult = ReturnType<typeof useGetShowroomsLazyQuery>;
export type GetShowroomsQueryResult = Apollo.QueryResult<Types.GetShowroomsQuery, Types.GetShowroomsQueryVariables>;
export const DeleteShowroomDocument = gql`
    mutation deleteShowroom($showroom_id: Int!) {
  deleteShowroom(showroom_id: $showroom_id)
}
    `;
export type DeleteShowroomMutationFn = Apollo.MutationFunction<Types.DeleteShowroomMutation, Types.DeleteShowroomMutationVariables>;

/**
 * __useDeleteShowroomMutation__
 *
 * To run a mutation, you first call `useDeleteShowroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShowroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShowroomMutation, { data, loading, error }] = useDeleteShowroomMutation({
 *   variables: {
 *      showroom_id: // value for 'showroom_id'
 *   },
 * });
 */
export function useDeleteShowroomMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteShowroomMutation, Types.DeleteShowroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteShowroomMutation, Types.DeleteShowroomMutationVariables>(DeleteShowroomDocument, options);
      }
export type DeleteShowroomMutationHookResult = ReturnType<typeof useDeleteShowroomMutation>;
export type DeleteShowroomMutationResult = Apollo.MutationResult<Types.DeleteShowroomMutation>;
export type DeleteShowroomMutationOptions = Apollo.BaseMutationOptions<Types.DeleteShowroomMutation, Types.DeleteShowroomMutationVariables>;
export const GetShowroomArtistArtworksDocument = gql`
    query getShowroomArtistArtworks($artistContextId: bigint!, $availability: Boolean_comparison_exp, $public: Boolean_comparison_exp) {
  Artwork(
    where: {artist_context_id: {_eq: $artistContextId}, is_public: $public, is_available_sale: $availability, published_date: {_is_null: false}}
  ) {
    ...artworkFields
  }
}
    ${ArtworkFieldsFragmentDoc}`;

/**
 * __useGetShowroomArtistArtworksQuery__
 *
 * To run a query within a React component, call `useGetShowroomArtistArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomArtistArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomArtistArtworksQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      availability: // value for 'availability'
 *      public: // value for 'public'
 *   },
 * });
 */
export function useGetShowroomArtistArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowroomArtistArtworksQuery, Types.GetShowroomArtistArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowroomArtistArtworksQuery, Types.GetShowroomArtistArtworksQueryVariables>(GetShowroomArtistArtworksDocument, options);
      }
export function useGetShowroomArtistArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowroomArtistArtworksQuery, Types.GetShowroomArtistArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowroomArtistArtworksQuery, Types.GetShowroomArtistArtworksQueryVariables>(GetShowroomArtistArtworksDocument, options);
        }
export type GetShowroomArtistArtworksQueryHookResult = ReturnType<typeof useGetShowroomArtistArtworksQuery>;
export type GetShowroomArtistArtworksLazyQueryHookResult = ReturnType<typeof useGetShowroomArtistArtworksLazyQuery>;
export type GetShowroomArtistArtworksQueryResult = Apollo.QueryResult<Types.GetShowroomArtistArtworksQuery, Types.GetShowroomArtistArtworksQueryVariables>;
export const GetShowroomArtworksDocument = gql`
    query getShowroomArtworks($showroom_id: bigint!) {
  Artwork_to_Collection(
    where: {collection_id: {_eq: $showroom_id}}
    order_by: {order: asc}
  ) {
    id
    artwork_id
    collection_id
    order
    Artwork {
      ...artworkFields
    }
  }
}
    ${ArtworkFieldsFragmentDoc}`;

/**
 * __useGetShowroomArtworksQuery__
 *
 * To run a query within a React component, call `useGetShowroomArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomArtworksQuery({
 *   variables: {
 *      showroom_id: // value for 'showroom_id'
 *   },
 * });
 */
export function useGetShowroomArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowroomArtworksQuery, Types.GetShowroomArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowroomArtworksQuery, Types.GetShowroomArtworksQueryVariables>(GetShowroomArtworksDocument, options);
      }
export function useGetShowroomArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowroomArtworksQuery, Types.GetShowroomArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowroomArtworksQuery, Types.GetShowroomArtworksQueryVariables>(GetShowroomArtworksDocument, options);
        }
export type GetShowroomArtworksQueryHookResult = ReturnType<typeof useGetShowroomArtworksQuery>;
export type GetShowroomArtworksLazyQueryHookResult = ReturnType<typeof useGetShowroomArtworksLazyQuery>;
export type GetShowroomArtworksQueryResult = Apollo.QueryResult<Types.GetShowroomArtworksQuery, Types.GetShowroomArtworksQueryVariables>;
export const GetArtworkShowroomDetailsDocument = gql`
    query getArtworkShowroomDetails($showroom_id: bigint!) {
  Artwork_to_showroom_details(
    where: {artwork_to_collection: {collection_id: {_eq: $showroom_id}}}
    order_by: {artwork_to_collection: {order: asc}}
  ) {
    id
    price_id
    status
    artwork_to_collection {
      artwork_id
      collection_id
      order
    }
    artwork_showroom_price {
      currency
      price
      id
      Artwork {
        ...artworkFields
      }
    }
  }
}
    ${ArtworkFieldsFragmentDoc}`;

/**
 * __useGetArtworkShowroomDetailsQuery__
 *
 * To run a query within a React component, call `useGetArtworkShowroomDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkShowroomDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkShowroomDetailsQuery({
 *   variables: {
 *      showroom_id: // value for 'showroom_id'
 *   },
 * });
 */
export function useGetArtworkShowroomDetailsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkShowroomDetailsQuery, Types.GetArtworkShowroomDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkShowroomDetailsQuery, Types.GetArtworkShowroomDetailsQueryVariables>(GetArtworkShowroomDetailsDocument, options);
      }
export function useGetArtworkShowroomDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkShowroomDetailsQuery, Types.GetArtworkShowroomDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkShowroomDetailsQuery, Types.GetArtworkShowroomDetailsQueryVariables>(GetArtworkShowroomDetailsDocument, options);
        }
export type GetArtworkShowroomDetailsQueryHookResult = ReturnType<typeof useGetArtworkShowroomDetailsQuery>;
export type GetArtworkShowroomDetailsLazyQueryHookResult = ReturnType<typeof useGetArtworkShowroomDetailsLazyQuery>;
export type GetArtworkShowroomDetailsQueryResult = Apollo.QueryResult<Types.GetArtworkShowroomDetailsQuery, Types.GetArtworkShowroomDetailsQueryVariables>;
export const CreateShowroomDocument = gql`
    mutation createShowroom($context_id: Int!, $description: String!, $title: String!) {
  createShowroom(
    title: $title
    description: $description
    context_id: $context_id
  ) {
    collection_id
    collection_details_id
    slug
  }
}
    `;
export type CreateShowroomMutationFn = Apollo.MutationFunction<Types.CreateShowroomMutation, Types.CreateShowroomMutationVariables>;

/**
 * __useCreateShowroomMutation__
 *
 * To run a mutation, you first call `useCreateShowroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShowroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShowroomMutation, { data, loading, error }] = useCreateShowroomMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      description: // value for 'description'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateShowroomMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateShowroomMutation, Types.CreateShowroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateShowroomMutation, Types.CreateShowroomMutationVariables>(CreateShowroomDocument, options);
      }
export type CreateShowroomMutationHookResult = ReturnType<typeof useCreateShowroomMutation>;
export type CreateShowroomMutationResult = Apollo.MutationResult<Types.CreateShowroomMutation>;
export type CreateShowroomMutationOptions = Apollo.BaseMutationOptions<Types.CreateShowroomMutation, Types.CreateShowroomMutationVariables>;
export const UpdateShowroomDetailDocument = gql`
    mutation updateShowroomDetail($detail_id: bigint!, $password: String = null, $expiration_date: timestamptz = null) {
  update_Showroom_details_by_pk(
    pk_columns: {id: $detail_id}
    _set: {password: $password, expiration_date: $expiration_date}
  ) {
    id
  }
}
    `;
export type UpdateShowroomDetailMutationFn = Apollo.MutationFunction<Types.UpdateShowroomDetailMutation, Types.UpdateShowroomDetailMutationVariables>;

/**
 * __useUpdateShowroomDetailMutation__
 *
 * To run a mutation, you first call `useUpdateShowroomDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShowroomDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShowroomDetailMutation, { data, loading, error }] = useUpdateShowroomDetailMutation({
 *   variables: {
 *      detail_id: // value for 'detail_id'
 *      password: // value for 'password'
 *      expiration_date: // value for 'expiration_date'
 *   },
 * });
 */
export function useUpdateShowroomDetailMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateShowroomDetailMutation, Types.UpdateShowroomDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateShowroomDetailMutation, Types.UpdateShowroomDetailMutationVariables>(UpdateShowroomDetailDocument, options);
      }
export type UpdateShowroomDetailMutationHookResult = ReturnType<typeof useUpdateShowroomDetailMutation>;
export type UpdateShowroomDetailMutationResult = Apollo.MutationResult<Types.UpdateShowroomDetailMutation>;
export type UpdateShowroomDetailMutationOptions = Apollo.BaseMutationOptions<Types.UpdateShowroomDetailMutation, Types.UpdateShowroomDetailMutationVariables>;
export const UpdateShowroomCommercialDetailDocument = gql`
    mutation updateShowroomCommercialDetail($showroomId: bigint!, $currency: String = "$", $is_commercial: Boolean = false) {
  update_Showroom_details(
    where: {collection_id: {_eq: $showroomId}}
    _set: {currency: $currency, is_commercial: $is_commercial}
  ) {
    returning {
      collection_id
      currency
      is_commercial
    }
  }
}
    `;
export type UpdateShowroomCommercialDetailMutationFn = Apollo.MutationFunction<Types.UpdateShowroomCommercialDetailMutation, Types.UpdateShowroomCommercialDetailMutationVariables>;

/**
 * __useUpdateShowroomCommercialDetailMutation__
 *
 * To run a mutation, you first call `useUpdateShowroomCommercialDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShowroomCommercialDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShowroomCommercialDetailMutation, { data, loading, error }] = useUpdateShowroomCommercialDetailMutation({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *      currency: // value for 'currency'
 *      is_commercial: // value for 'is_commercial'
 *   },
 * });
 */
export function useUpdateShowroomCommercialDetailMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateShowroomCommercialDetailMutation, Types.UpdateShowroomCommercialDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateShowroomCommercialDetailMutation, Types.UpdateShowroomCommercialDetailMutationVariables>(UpdateShowroomCommercialDetailDocument, options);
      }
export type UpdateShowroomCommercialDetailMutationHookResult = ReturnType<typeof useUpdateShowroomCommercialDetailMutation>;
export type UpdateShowroomCommercialDetailMutationResult = Apollo.MutationResult<Types.UpdateShowroomCommercialDetailMutation>;
export type UpdateShowroomCommercialDetailMutationOptions = Apollo.BaseMutationOptions<Types.UpdateShowroomCommercialDetailMutation, Types.UpdateShowroomCommercialDetailMutationVariables>;
export const UpdateShowroomsDocument = gql`
    mutation updateShowrooms($showroomId: bigint!, $title: String!, $description: String, $password: String, $expiration_date: timestamptz) {
  update_Collection_by_pk(
    pk_columns: {id: $showroomId}
    _set: {name: $title, description: $description}
  ) {
    id
    description
  }
  update_Showroom_details(
    where: {collection_id: {_eq: $showroomId}}
    _set: {expiration_date: $expiration_date, password: $password}
  ) {
    returning {
      id
      collection_id
      expiration_date
    }
  }
}
    `;
export type UpdateShowroomsMutationFn = Apollo.MutationFunction<Types.UpdateShowroomsMutation, Types.UpdateShowroomsMutationVariables>;

/**
 * __useUpdateShowroomsMutation__
 *
 * To run a mutation, you first call `useUpdateShowroomsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShowroomsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShowroomsMutation, { data, loading, error }] = useUpdateShowroomsMutation({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      password: // value for 'password'
 *      expiration_date: // value for 'expiration_date'
 *   },
 * });
 */
export function useUpdateShowroomsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateShowroomsMutation, Types.UpdateShowroomsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateShowroomsMutation, Types.UpdateShowroomsMutationVariables>(UpdateShowroomsDocument, options);
      }
export type UpdateShowroomsMutationHookResult = ReturnType<typeof useUpdateShowroomsMutation>;
export type UpdateShowroomsMutationResult = Apollo.MutationResult<Types.UpdateShowroomsMutation>;
export type UpdateShowroomsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateShowroomsMutation, Types.UpdateShowroomsMutationVariables>;
export const GetShowroomDocument = gql`
    query getShowroom($showroomId: bigint!) {
  Collection_by_pk(id: $showroomId) {
    id
    name
    description
    public
    type
    owner_context_id
    Showroom_detail {
      id
      expiration_date
      currency
      is_commercial
    }
  }
  showroom_owner_password(where: {collection_id: {_eq: $showroomId}}) {
    password
  }
}
    `;

/**
 * __useGetShowroomQuery__
 *
 * To run a query within a React component, call `useGetShowroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowroomQuery({
 *   variables: {
 *      showroomId: // value for 'showroomId'
 *   },
 * });
 */
export function useGetShowroomQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowroomQuery, Types.GetShowroomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowroomQuery, Types.GetShowroomQueryVariables>(GetShowroomDocument, options);
      }
export function useGetShowroomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowroomQuery, Types.GetShowroomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowroomQuery, Types.GetShowroomQueryVariables>(GetShowroomDocument, options);
        }
export type GetShowroomQueryHookResult = ReturnType<typeof useGetShowroomQuery>;
export type GetShowroomLazyQueryHookResult = ReturnType<typeof useGetShowroomLazyQuery>;
export type GetShowroomQueryResult = Apollo.QueryResult<Types.GetShowroomQuery, Types.GetShowroomQueryVariables>;
export const SetShowroomArtworksDocument = gql`
    mutation setShowroomArtworks($artwork_ids: [Int!]!, $showroom_id: Int!) {
  setShowroomArtworks(artwork_ids: $artwork_ids, showroom_id: $showroom_id)
}
    `;
export type SetShowroomArtworksMutationFn = Apollo.MutationFunction<Types.SetShowroomArtworksMutation, Types.SetShowroomArtworksMutationVariables>;

/**
 * __useSetShowroomArtworksMutation__
 *
 * To run a mutation, you first call `useSetShowroomArtworksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShowroomArtworksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShowroomArtworksMutation, { data, loading, error }] = useSetShowroomArtworksMutation({
 *   variables: {
 *      artwork_ids: // value for 'artwork_ids'
 *      showroom_id: // value for 'showroom_id'
 *   },
 * });
 */
export function useSetShowroomArtworksMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetShowroomArtworksMutation, Types.SetShowroomArtworksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetShowroomArtworksMutation, Types.SetShowroomArtworksMutationVariables>(SetShowroomArtworksDocument, options);
      }
export type SetShowroomArtworksMutationHookResult = ReturnType<typeof useSetShowroomArtworksMutation>;
export type SetShowroomArtworksMutationResult = Apollo.MutationResult<Types.SetShowroomArtworksMutation>;
export type SetShowroomArtworksMutationOptions = Apollo.BaseMutationOptions<Types.SetShowroomArtworksMutation, Types.SetShowroomArtworksMutationVariables>;
export const UpdateShowroomOrderDocument = gql`
    mutation updateShowroomOrder($artwork_id: bigint!, $showroom_id: bigint!, $order: Int!) {
  update_Artwork_to_Collection(
    where: {artwork_id: {_eq: $artwork_id}, collection_id: {_eq: $showroom_id}}
    _set: {order: $order}
  ) {
    affected_rows
  }
}
    `;
export type UpdateShowroomOrderMutationFn = Apollo.MutationFunction<Types.UpdateShowroomOrderMutation, Types.UpdateShowroomOrderMutationVariables>;

/**
 * __useUpdateShowroomOrderMutation__
 *
 * To run a mutation, you first call `useUpdateShowroomOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShowroomOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShowroomOrderMutation, { data, loading, error }] = useUpdateShowroomOrderMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      showroom_id: // value for 'showroom_id'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateShowroomOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateShowroomOrderMutation, Types.UpdateShowroomOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateShowroomOrderMutation, Types.UpdateShowroomOrderMutationVariables>(UpdateShowroomOrderDocument, options);
      }
export type UpdateShowroomOrderMutationHookResult = ReturnType<typeof useUpdateShowroomOrderMutation>;
export type UpdateShowroomOrderMutationResult = Apollo.MutationResult<Types.UpdateShowroomOrderMutation>;
export type UpdateShowroomOrderMutationOptions = Apollo.BaseMutationOptions<Types.UpdateShowroomOrderMutation, Types.UpdateShowroomOrderMutationVariables>;
export const PublishShowroomDocument = gql`
    mutation publishShowroom($context_id: Int!, $showroom_id: Int!) {
  publishShowroom(showroom_id: $showroom_id, context_id: $context_id)
}
    `;
export type PublishShowroomMutationFn = Apollo.MutationFunction<Types.PublishShowroomMutation, Types.PublishShowroomMutationVariables>;

/**
 * __usePublishShowroomMutation__
 *
 * To run a mutation, you first call `usePublishShowroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishShowroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishShowroomMutation, { data, loading, error }] = usePublishShowroomMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      showroom_id: // value for 'showroom_id'
 *   },
 * });
 */
export function usePublishShowroomMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishShowroomMutation, Types.PublishShowroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishShowroomMutation, Types.PublishShowroomMutationVariables>(PublishShowroomDocument, options);
      }
export type PublishShowroomMutationHookResult = ReturnType<typeof usePublishShowroomMutation>;
export type PublishShowroomMutationResult = Apollo.MutationResult<Types.PublishShowroomMutation>;
export type PublishShowroomMutationOptions = Apollo.BaseMutationOptions<Types.PublishShowroomMutation, Types.PublishShowroomMutationVariables>;
export const UpdateArtworkShowroomPriceDocument = gql`
    mutation updateArtworkShowroomPrice($artwork_id: bigint!, $collection_id: bigint!, $price: float8 = null, $currency: String) {
  update_Artwork_prices(
    where: {artwork_to_showroom_details: {artwork_to_collection: {collection_id: {_eq: $collection_id}}}, artwork_id: {_eq: $artwork_id}}
    _set: {price: $price, currency: $currency}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateArtworkShowroomPriceMutationFn = Apollo.MutationFunction<Types.UpdateArtworkShowroomPriceMutation, Types.UpdateArtworkShowroomPriceMutationVariables>;

/**
 * __useUpdateArtworkShowroomPriceMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkShowroomPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkShowroomPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkShowroomPriceMutation, { data, loading, error }] = useUpdateArtworkShowroomPriceMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      collection_id: // value for 'collection_id'
 *      price: // value for 'price'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useUpdateArtworkShowroomPriceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkShowroomPriceMutation, Types.UpdateArtworkShowroomPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkShowroomPriceMutation, Types.UpdateArtworkShowroomPriceMutationVariables>(UpdateArtworkShowroomPriceDocument, options);
      }
export type UpdateArtworkShowroomPriceMutationHookResult = ReturnType<typeof useUpdateArtworkShowroomPriceMutation>;
export type UpdateArtworkShowroomPriceMutationResult = Apollo.MutationResult<Types.UpdateArtworkShowroomPriceMutation>;
export type UpdateArtworkShowroomPriceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkShowroomPriceMutation, Types.UpdateArtworkShowroomPriceMutationVariables>;
export const UpdateArtworkShowroomStatusDocument = gql`
    mutation updateArtworkShowroomStatus($artwork_id: bigint!, $collection_id: bigint!, $status: ENUM_artwork_showroom_status_enum!) {
  update_Artwork_to_showroom_details(
    where: {artwork_to_collection: {collection_id: {_eq: $collection_id}, artwork_id: {_eq: $artwork_id}}}
    _set: {status: $status}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateArtworkShowroomStatusMutationFn = Apollo.MutationFunction<Types.UpdateArtworkShowroomStatusMutation, Types.UpdateArtworkShowroomStatusMutationVariables>;

/**
 * __useUpdateArtworkShowroomStatusMutation__
 *
 * To run a mutation, you first call `useUpdateArtworkShowroomStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtworkShowroomStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtworkShowroomStatusMutation, { data, loading, error }] = useUpdateArtworkShowroomStatusMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      collection_id: // value for 'collection_id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateArtworkShowroomStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtworkShowroomStatusMutation, Types.UpdateArtworkShowroomStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtworkShowroomStatusMutation, Types.UpdateArtworkShowroomStatusMutationVariables>(UpdateArtworkShowroomStatusDocument, options);
      }
export type UpdateArtworkShowroomStatusMutationHookResult = ReturnType<typeof useUpdateArtworkShowroomStatusMutation>;
export type UpdateArtworkShowroomStatusMutationResult = Apollo.MutationResult<Types.UpdateArtworkShowroomStatusMutation>;
export type UpdateArtworkShowroomStatusMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtworkShowroomStatusMutation, Types.UpdateArtworkShowroomStatusMutationVariables>;
export const PublisShowroomDocument = gql`
    mutation publisShowroom($context_id: Int!, $showroom_id: Int!) {
  publishShowroom(showroom_id: $showroom_id, context_id: $context_id)
}
    `;
export type PublisShowroomMutationFn = Apollo.MutationFunction<Types.PublisShowroomMutation, Types.PublisShowroomMutationVariables>;

/**
 * __usePublisShowroomMutation__
 *
 * To run a mutation, you first call `usePublisShowroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublisShowroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publisShowroomMutation, { data, loading, error }] = usePublisShowroomMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      showroom_id: // value for 'showroom_id'
 *   },
 * });
 */
export function usePublisShowroomMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublisShowroomMutation, Types.PublisShowroomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublisShowroomMutation, Types.PublisShowroomMutationVariables>(PublisShowroomDocument, options);
      }
export type PublisShowroomMutationHookResult = ReturnType<typeof usePublisShowroomMutation>;
export type PublisShowroomMutationResult = Apollo.MutationResult<Types.PublisShowroomMutation>;
export type PublisShowroomMutationOptions = Apollo.BaseMutationOptions<Types.PublisShowroomMutation, Types.PublisShowroomMutationVariables>;
export const UpdateIsPublicPriceDocument = gql`
    mutation updateIsPublicPrice($artworkPriceId: bigint!, $isPublic: Boolean!) {
  update_Artwork_prices_by_pk(
    pk_columns: {id: $artworkPriceId}
    _set: {is_public: $isPublic}
  ) {
    id
    is_public
  }
}
    `;
export type UpdateIsPublicPriceMutationFn = Apollo.MutationFunction<Types.UpdateIsPublicPriceMutation, Types.UpdateIsPublicPriceMutationVariables>;

/**
 * __useUpdateIsPublicPriceMutation__
 *
 * To run a mutation, you first call `useUpdateIsPublicPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsPublicPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsPublicPriceMutation, { data, loading, error }] = useUpdateIsPublicPriceMutation({
 *   variables: {
 *      artworkPriceId: // value for 'artworkPriceId'
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useUpdateIsPublicPriceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateIsPublicPriceMutation, Types.UpdateIsPublicPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateIsPublicPriceMutation, Types.UpdateIsPublicPriceMutationVariables>(UpdateIsPublicPriceDocument, options);
      }
export type UpdateIsPublicPriceMutationHookResult = ReturnType<typeof useUpdateIsPublicPriceMutation>;
export type UpdateIsPublicPriceMutationResult = Apollo.MutationResult<Types.UpdateIsPublicPriceMutation>;
export type UpdateIsPublicPriceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateIsPublicPriceMutation, Types.UpdateIsPublicPriceMutationVariables>;
export const UpdatePublicPriceDocument = gql`
    mutation updatePublicPrice($artworkPriceId: bigint!, $currency: String = null, $price: float8 = null) {
  update_Artwork_prices_by_pk(
    pk_columns: {id: $artworkPriceId}
    _set: {currency: $currency, price: $price}
  ) {
    id
    currency
    price
  }
}
    `;
export type UpdatePublicPriceMutationFn = Apollo.MutationFunction<Types.UpdatePublicPriceMutation, Types.UpdatePublicPriceMutationVariables>;

/**
 * __useUpdatePublicPriceMutation__
 *
 * To run a mutation, you first call `useUpdatePublicPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicPriceMutation, { data, loading, error }] = useUpdatePublicPriceMutation({
 *   variables: {
 *      artworkPriceId: // value for 'artworkPriceId'
 *      currency: // value for 'currency'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useUpdatePublicPriceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePublicPriceMutation, Types.UpdatePublicPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePublicPriceMutation, Types.UpdatePublicPriceMutationVariables>(UpdatePublicPriceDocument, options);
      }
export type UpdatePublicPriceMutationHookResult = ReturnType<typeof useUpdatePublicPriceMutation>;
export type UpdatePublicPriceMutationResult = Apollo.MutationResult<Types.UpdatePublicPriceMutation>;
export type UpdatePublicPriceMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePublicPriceMutation, Types.UpdatePublicPriceMutationVariables>;
export const UpdateIsAvailableArtworkDocument = gql`
    mutation updateIsAvailableArtwork($artworkId: bigint!, $isAvailable: Boolean!) {
  update_Artwork_by_pk(
    pk_columns: {id: $artworkId}
    _set: {is_available_sale: $isAvailable}
  ) {
    id
    is_available_sale
  }
}
    `;
export type UpdateIsAvailableArtworkMutationFn = Apollo.MutationFunction<Types.UpdateIsAvailableArtworkMutation, Types.UpdateIsAvailableArtworkMutationVariables>;

/**
 * __useUpdateIsAvailableArtworkMutation__
 *
 * To run a mutation, you first call `useUpdateIsAvailableArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsAvailableArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsAvailableArtworkMutation, { data, loading, error }] = useUpdateIsAvailableArtworkMutation({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *      isAvailable: // value for 'isAvailable'
 *   },
 * });
 */
export function useUpdateIsAvailableArtworkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateIsAvailableArtworkMutation, Types.UpdateIsAvailableArtworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateIsAvailableArtworkMutation, Types.UpdateIsAvailableArtworkMutationVariables>(UpdateIsAvailableArtworkDocument, options);
      }
export type UpdateIsAvailableArtworkMutationHookResult = ReturnType<typeof useUpdateIsAvailableArtworkMutation>;
export type UpdateIsAvailableArtworkMutationResult = Apollo.MutationResult<Types.UpdateIsAvailableArtworkMutation>;
export type UpdateIsAvailableArtworkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateIsAvailableArtworkMutation, Types.UpdateIsAvailableArtworkMutationVariables>;
export const FoursquarePlacesSearchDocument = gql`
    query foursquarePlacesSearch($queryParams: PlacesSearchParameters!) {
  foursquarePlacesSearch(queryParams: $queryParams) {
    ...Places
  }
}
    ${PlacesFragmentDoc}`;

/**
 * __useFoursquarePlacesSearchQuery__
 *
 * To run a query within a React component, call `useFoursquarePlacesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoursquarePlacesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoursquarePlacesSearchQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useFoursquarePlacesSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.FoursquarePlacesSearchQuery, Types.FoursquarePlacesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FoursquarePlacesSearchQuery, Types.FoursquarePlacesSearchQueryVariables>(FoursquarePlacesSearchDocument, options);
      }
export function useFoursquarePlacesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FoursquarePlacesSearchQuery, Types.FoursquarePlacesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FoursquarePlacesSearchQuery, Types.FoursquarePlacesSearchQueryVariables>(FoursquarePlacesSearchDocument, options);
        }
export type FoursquarePlacesSearchQueryHookResult = ReturnType<typeof useFoursquarePlacesSearchQuery>;
export type FoursquarePlacesSearchLazyQueryHookResult = ReturnType<typeof useFoursquarePlacesSearchLazyQuery>;
export type FoursquarePlacesSearchQueryResult = Apollo.QueryResult<Types.FoursquarePlacesSearchQuery, Types.FoursquarePlacesSearchQueryVariables>;
export const InsertDynamicCvDocument = gql`
    mutation insertDynamicCV($artist_context_id: bigint!, $city_id: String) {
  insert_Dynamic_cv(
    objects: {artist_context_id: $artist_context_id, city_id: $city_id}
  ) {
    returning {
      artist_context_id
      city {
        admin_code
        id
        iso2
        country_name
        city
      }
      id
    }
  }
}
    `;
export type InsertDynamicCvMutationFn = Apollo.MutationFunction<Types.InsertDynamicCvMutation, Types.InsertDynamicCvMutationVariables>;

/**
 * __useInsertDynamicCvMutation__
 *
 * To run a mutation, you first call `useInsertDynamicCvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDynamicCvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDynamicCvMutation, { data, loading, error }] = useInsertDynamicCvMutation({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *      city_id: // value for 'city_id'
 *   },
 * });
 */
export function useInsertDynamicCvMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertDynamicCvMutation, Types.InsertDynamicCvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertDynamicCvMutation, Types.InsertDynamicCvMutationVariables>(InsertDynamicCvDocument, options);
      }
export type InsertDynamicCvMutationHookResult = ReturnType<typeof useInsertDynamicCvMutation>;
export type InsertDynamicCvMutationResult = Apollo.MutationResult<Types.InsertDynamicCvMutation>;
export type InsertDynamicCvMutationOptions = Apollo.BaseMutationOptions<Types.InsertDynamicCvMutation, Types.InsertDynamicCvMutationVariables>;
export const UpdateDynamicCvDocument = gql`
    mutation updateDynamicCV($id: bigint!, $dynamicCVInput: Dynamic_cv_set_input!) {
  update_Dynamic_cv_by_pk(pk_columns: {id: $id}, _set: $dynamicCVInput) {
    city {
      country_name
    }
    published_at
  }
}
    `;
export type UpdateDynamicCvMutationFn = Apollo.MutationFunction<Types.UpdateDynamicCvMutation, Types.UpdateDynamicCvMutationVariables>;

/**
 * __useUpdateDynamicCvMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCvMutation, { data, loading, error }] = useUpdateDynamicCvMutation({
 *   variables: {
 *      id: // value for 'id'
 *      dynamicCVInput: // value for 'dynamicCVInput'
 *   },
 * });
 */
export function useUpdateDynamicCvMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDynamicCvMutation, Types.UpdateDynamicCvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDynamicCvMutation, Types.UpdateDynamicCvMutationVariables>(UpdateDynamicCvDocument, options);
      }
export type UpdateDynamicCvMutationHookResult = ReturnType<typeof useUpdateDynamicCvMutation>;
export type UpdateDynamicCvMutationResult = Apollo.MutationResult<Types.UpdateDynamicCvMutation>;
export type UpdateDynamicCvMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDynamicCvMutation, Types.UpdateDynamicCvMutationVariables>;
export const InsertDynamicCvEducationDocument = gql`
    mutation insertDynamicCVEducation($education: Dynamic_cv_education_insert_input!) {
  insert_Dynamic_cv_education_one(object: $education) {
    id
    city_id
    currently_attending
    degree
    end_year
    institution_name
    city {
      admin_code
      admin_name
      iso2
      city
      country_name
    }
  }
}
    `;
export type InsertDynamicCvEducationMutationFn = Apollo.MutationFunction<Types.InsertDynamicCvEducationMutation, Types.InsertDynamicCvEducationMutationVariables>;

/**
 * __useInsertDynamicCvEducationMutation__
 *
 * To run a mutation, you first call `useInsertDynamicCvEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDynamicCvEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDynamicCvEducationMutation, { data, loading, error }] = useInsertDynamicCvEducationMutation({
 *   variables: {
 *      education: // value for 'education'
 *   },
 * });
 */
export function useInsertDynamicCvEducationMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertDynamicCvEducationMutation, Types.InsertDynamicCvEducationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertDynamicCvEducationMutation, Types.InsertDynamicCvEducationMutationVariables>(InsertDynamicCvEducationDocument, options);
      }
export type InsertDynamicCvEducationMutationHookResult = ReturnType<typeof useInsertDynamicCvEducationMutation>;
export type InsertDynamicCvEducationMutationResult = Apollo.MutationResult<Types.InsertDynamicCvEducationMutation>;
export type InsertDynamicCvEducationMutationOptions = Apollo.BaseMutationOptions<Types.InsertDynamicCvEducationMutation, Types.InsertDynamicCvEducationMutationVariables>;
export const UpdateDynamicCvEducationDocument = gql`
    mutation updateDynamicCVEducation($id: bigint!, $education: Dynamic_cv_education_set_input!) {
  update_Dynamic_cv_education_by_pk(pk_columns: {id: $id}, _set: $education) {
    id
    city_id
    currently_attending
    degree
    end_year
    institution_name
    city {
      admin_code
      admin_name
      iso2
      city
      country_name
    }
  }
}
    `;
export type UpdateDynamicCvEducationMutationFn = Apollo.MutationFunction<Types.UpdateDynamicCvEducationMutation, Types.UpdateDynamicCvEducationMutationVariables>;

/**
 * __useUpdateDynamicCvEducationMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCvEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCvEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCvEducationMutation, { data, loading, error }] = useUpdateDynamicCvEducationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      education: // value for 'education'
 *   },
 * });
 */
export function useUpdateDynamicCvEducationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDynamicCvEducationMutation, Types.UpdateDynamicCvEducationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDynamicCvEducationMutation, Types.UpdateDynamicCvEducationMutationVariables>(UpdateDynamicCvEducationDocument, options);
      }
export type UpdateDynamicCvEducationMutationHookResult = ReturnType<typeof useUpdateDynamicCvEducationMutation>;
export type UpdateDynamicCvEducationMutationResult = Apollo.MutationResult<Types.UpdateDynamicCvEducationMutation>;
export type UpdateDynamicCvEducationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDynamicCvEducationMutation, Types.UpdateDynamicCvEducationMutationVariables>;
export const DeleteDynamicCvEducationDocument = gql`
    mutation deleteDynamicCVEducation($id: bigint!) {
  delete_Dynamic_cv_education_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteDynamicCvEducationMutationFn = Apollo.MutationFunction<Types.DeleteDynamicCvEducationMutation, Types.DeleteDynamicCvEducationMutationVariables>;

/**
 * __useDeleteDynamicCvEducationMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicCvEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicCvEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicCvEducationMutation, { data, loading, error }] = useDeleteDynamicCvEducationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicCvEducationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDynamicCvEducationMutation, Types.DeleteDynamicCvEducationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDynamicCvEducationMutation, Types.DeleteDynamicCvEducationMutationVariables>(DeleteDynamicCvEducationDocument, options);
      }
export type DeleteDynamicCvEducationMutationHookResult = ReturnType<typeof useDeleteDynamicCvEducationMutation>;
export type DeleteDynamicCvEducationMutationResult = Apollo.MutationResult<Types.DeleteDynamicCvEducationMutation>;
export type DeleteDynamicCvEducationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDynamicCvEducationMutation, Types.DeleteDynamicCvEducationMutationVariables>;
export const InsertDynamicCvExhibitionDocument = gql`
    mutation insertDynamicCVExhibition($exhibition: Dynamic_cv_exhibition_insert_input!) {
  insert_Dynamic_cv_exhibition_one(object: $exhibition) {
    id
    institution_name
    name
    type
    year
    city_id
    cv_id
    city {
      admin_code
      admin_name
      city
      iso2
      country_name
    }
  }
}
    `;
export type InsertDynamicCvExhibitionMutationFn = Apollo.MutationFunction<Types.InsertDynamicCvExhibitionMutation, Types.InsertDynamicCvExhibitionMutationVariables>;

/**
 * __useInsertDynamicCvExhibitionMutation__
 *
 * To run a mutation, you first call `useInsertDynamicCvExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDynamicCvExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDynamicCvExhibitionMutation, { data, loading, error }] = useInsertDynamicCvExhibitionMutation({
 *   variables: {
 *      exhibition: // value for 'exhibition'
 *   },
 * });
 */
export function useInsertDynamicCvExhibitionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertDynamicCvExhibitionMutation, Types.InsertDynamicCvExhibitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertDynamicCvExhibitionMutation, Types.InsertDynamicCvExhibitionMutationVariables>(InsertDynamicCvExhibitionDocument, options);
      }
export type InsertDynamicCvExhibitionMutationHookResult = ReturnType<typeof useInsertDynamicCvExhibitionMutation>;
export type InsertDynamicCvExhibitionMutationResult = Apollo.MutationResult<Types.InsertDynamicCvExhibitionMutation>;
export type InsertDynamicCvExhibitionMutationOptions = Apollo.BaseMutationOptions<Types.InsertDynamicCvExhibitionMutation, Types.InsertDynamicCvExhibitionMutationVariables>;
export const UpdateDynamicCvExhibitionDocument = gql`
    mutation updateDynamicCVExhibition($id: bigint!, $exhibition: Dynamic_cv_exhibition_set_input!) {
  update_Dynamic_cv_exhibition_by_pk(pk_columns: {id: $id}, _set: $exhibition) {
    id
    institution_name
    name
    type
    year
    cv_id
    city_id
    city {
      admin_code
      admin_name
      city
      iso2
      country_name
    }
  }
}
    `;
export type UpdateDynamicCvExhibitionMutationFn = Apollo.MutationFunction<Types.UpdateDynamicCvExhibitionMutation, Types.UpdateDynamicCvExhibitionMutationVariables>;

/**
 * __useUpdateDynamicCvExhibitionMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCvExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCvExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCvExhibitionMutation, { data, loading, error }] = useUpdateDynamicCvExhibitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      exhibition: // value for 'exhibition'
 *   },
 * });
 */
export function useUpdateDynamicCvExhibitionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDynamicCvExhibitionMutation, Types.UpdateDynamicCvExhibitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDynamicCvExhibitionMutation, Types.UpdateDynamicCvExhibitionMutationVariables>(UpdateDynamicCvExhibitionDocument, options);
      }
export type UpdateDynamicCvExhibitionMutationHookResult = ReturnType<typeof useUpdateDynamicCvExhibitionMutation>;
export type UpdateDynamicCvExhibitionMutationResult = Apollo.MutationResult<Types.UpdateDynamicCvExhibitionMutation>;
export type UpdateDynamicCvExhibitionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDynamicCvExhibitionMutation, Types.UpdateDynamicCvExhibitionMutationVariables>;
export const DeleteDynamicCvExhibitionDocument = gql`
    mutation deleteDynamicCVExhibition($id: bigint!) {
  delete_Dynamic_cv_exhibition_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteDynamicCvExhibitionMutationFn = Apollo.MutationFunction<Types.DeleteDynamicCvExhibitionMutation, Types.DeleteDynamicCvExhibitionMutationVariables>;

/**
 * __useDeleteDynamicCvExhibitionMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicCvExhibitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicCvExhibitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicCvExhibitionMutation, { data, loading, error }] = useDeleteDynamicCvExhibitionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicCvExhibitionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDynamicCvExhibitionMutation, Types.DeleteDynamicCvExhibitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDynamicCvExhibitionMutation, Types.DeleteDynamicCvExhibitionMutationVariables>(DeleteDynamicCvExhibitionDocument, options);
      }
export type DeleteDynamicCvExhibitionMutationHookResult = ReturnType<typeof useDeleteDynamicCvExhibitionMutation>;
export type DeleteDynamicCvExhibitionMutationResult = Apollo.MutationResult<Types.DeleteDynamicCvExhibitionMutation>;
export type DeleteDynamicCvExhibitionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDynamicCvExhibitionMutation, Types.DeleteDynamicCvExhibitionMutationVariables>;
export const InsertDynamicCvPublicationDocument = gql`
    mutation insertDynamicCVPublication($publication: Dynamic_cv_publication_insert_input!) {
  insert_Dynamic_cv_publication_one(object: $publication) {
    id
    title
    url
    year
  }
}
    `;
export type InsertDynamicCvPublicationMutationFn = Apollo.MutationFunction<Types.InsertDynamicCvPublicationMutation, Types.InsertDynamicCvPublicationMutationVariables>;

/**
 * __useInsertDynamicCvPublicationMutation__
 *
 * To run a mutation, you first call `useInsertDynamicCvPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDynamicCvPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDynamicCvPublicationMutation, { data, loading, error }] = useInsertDynamicCvPublicationMutation({
 *   variables: {
 *      publication: // value for 'publication'
 *   },
 * });
 */
export function useInsertDynamicCvPublicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertDynamicCvPublicationMutation, Types.InsertDynamicCvPublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertDynamicCvPublicationMutation, Types.InsertDynamicCvPublicationMutationVariables>(InsertDynamicCvPublicationDocument, options);
      }
export type InsertDynamicCvPublicationMutationHookResult = ReturnType<typeof useInsertDynamicCvPublicationMutation>;
export type InsertDynamicCvPublicationMutationResult = Apollo.MutationResult<Types.InsertDynamicCvPublicationMutation>;
export type InsertDynamicCvPublicationMutationOptions = Apollo.BaseMutationOptions<Types.InsertDynamicCvPublicationMutation, Types.InsertDynamicCvPublicationMutationVariables>;
export const UpdateDynamicCvPublicationDocument = gql`
    mutation updateDynamicCVPublication($id: bigint!, $publication: Dynamic_cv_publication_set_input!) {
  update_Dynamic_cv_publication_by_pk(pk_columns: {id: $id}, _set: $publication) {
    id
    title
    url
    year
  }
}
    `;
export type UpdateDynamicCvPublicationMutationFn = Apollo.MutationFunction<Types.UpdateDynamicCvPublicationMutation, Types.UpdateDynamicCvPublicationMutationVariables>;

/**
 * __useUpdateDynamicCvPublicationMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCvPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCvPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCvPublicationMutation, { data, loading, error }] = useUpdateDynamicCvPublicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      publication: // value for 'publication'
 *   },
 * });
 */
export function useUpdateDynamicCvPublicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDynamicCvPublicationMutation, Types.UpdateDynamicCvPublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDynamicCvPublicationMutation, Types.UpdateDynamicCvPublicationMutationVariables>(UpdateDynamicCvPublicationDocument, options);
      }
export type UpdateDynamicCvPublicationMutationHookResult = ReturnType<typeof useUpdateDynamicCvPublicationMutation>;
export type UpdateDynamicCvPublicationMutationResult = Apollo.MutationResult<Types.UpdateDynamicCvPublicationMutation>;
export type UpdateDynamicCvPublicationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDynamicCvPublicationMutation, Types.UpdateDynamicCvPublicationMutationVariables>;
export const DeleteDynamicCvPublicationDocument = gql`
    mutation deleteDynamicCVPublication($id: bigint!) {
  delete_Dynamic_cv_publication_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteDynamicCvPublicationMutationFn = Apollo.MutationFunction<Types.DeleteDynamicCvPublicationMutation, Types.DeleteDynamicCvPublicationMutationVariables>;

/**
 * __useDeleteDynamicCvPublicationMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicCvPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicCvPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicCvPublicationMutation, { data, loading, error }] = useDeleteDynamicCvPublicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicCvPublicationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDynamicCvPublicationMutation, Types.DeleteDynamicCvPublicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDynamicCvPublicationMutation, Types.DeleteDynamicCvPublicationMutationVariables>(DeleteDynamicCvPublicationDocument, options);
      }
export type DeleteDynamicCvPublicationMutationHookResult = ReturnType<typeof useDeleteDynamicCvPublicationMutation>;
export type DeleteDynamicCvPublicationMutationResult = Apollo.MutationResult<Types.DeleteDynamicCvPublicationMutation>;
export type DeleteDynamicCvPublicationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDynamicCvPublicationMutation, Types.DeleteDynamicCvPublicationMutationVariables>;
export const InsertDynamicCvAwardDocument = gql`
    mutation insertDynamicCVAward($award: Dynamic_cv_award_insert_input!) {
  insert_Dynamic_cv_award_one(object: $award) {
    id
    name
    year
    institution_name
    city_id
    is_hidden
    is_kaleido_prize
    kaleido_prize_show_slug
    city {
      admin_code
      admin_name
      city
      iso2
      country_name
    }
  }
}
    `;
export type InsertDynamicCvAwardMutationFn = Apollo.MutationFunction<Types.InsertDynamicCvAwardMutation, Types.InsertDynamicCvAwardMutationVariables>;

/**
 * __useInsertDynamicCvAwardMutation__
 *
 * To run a mutation, you first call `useInsertDynamicCvAwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDynamicCvAwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDynamicCvAwardMutation, { data, loading, error }] = useInsertDynamicCvAwardMutation({
 *   variables: {
 *      award: // value for 'award'
 *   },
 * });
 */
export function useInsertDynamicCvAwardMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertDynamicCvAwardMutation, Types.InsertDynamicCvAwardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertDynamicCvAwardMutation, Types.InsertDynamicCvAwardMutationVariables>(InsertDynamicCvAwardDocument, options);
      }
export type InsertDynamicCvAwardMutationHookResult = ReturnType<typeof useInsertDynamicCvAwardMutation>;
export type InsertDynamicCvAwardMutationResult = Apollo.MutationResult<Types.InsertDynamicCvAwardMutation>;
export type InsertDynamicCvAwardMutationOptions = Apollo.BaseMutationOptions<Types.InsertDynamicCvAwardMutation, Types.InsertDynamicCvAwardMutationVariables>;
export const UpdateDynamicCvAwardDocument = gql`
    mutation updateDynamicCVAward($id: bigint!, $award: Dynamic_cv_award_set_input!) {
  update_Dynamic_cv_award_by_pk(pk_columns: {id: $id}, _set: $award) {
    id
    name
    year
    is_kaleido_prize
    is_hidden
    institution_name
    city_id
    kaleido_prize_show_slug
    city {
      admin_code
      admin_name
      city
      iso2
      country_name
    }
  }
}
    `;
export type UpdateDynamicCvAwardMutationFn = Apollo.MutationFunction<Types.UpdateDynamicCvAwardMutation, Types.UpdateDynamicCvAwardMutationVariables>;

/**
 * __useUpdateDynamicCvAwardMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCvAwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCvAwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCvAwardMutation, { data, loading, error }] = useUpdateDynamicCvAwardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      award: // value for 'award'
 *   },
 * });
 */
export function useUpdateDynamicCvAwardMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDynamicCvAwardMutation, Types.UpdateDynamicCvAwardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDynamicCvAwardMutation, Types.UpdateDynamicCvAwardMutationVariables>(UpdateDynamicCvAwardDocument, options);
      }
export type UpdateDynamicCvAwardMutationHookResult = ReturnType<typeof useUpdateDynamicCvAwardMutation>;
export type UpdateDynamicCvAwardMutationResult = Apollo.MutationResult<Types.UpdateDynamicCvAwardMutation>;
export type UpdateDynamicCvAwardMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDynamicCvAwardMutation, Types.UpdateDynamicCvAwardMutationVariables>;
export const DeleteDynamicCvAwardDocument = gql`
    mutation deleteDynamicCVAward($id: bigint!) {
  delete_Dynamic_cv_award_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteDynamicCvAwardMutationFn = Apollo.MutationFunction<Types.DeleteDynamicCvAwardMutation, Types.DeleteDynamicCvAwardMutationVariables>;

/**
 * __useDeleteDynamicCvAwardMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicCvAwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicCvAwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicCvAwardMutation, { data, loading, error }] = useDeleteDynamicCvAwardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicCvAwardMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDynamicCvAwardMutation, Types.DeleteDynamicCvAwardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDynamicCvAwardMutation, Types.DeleteDynamicCvAwardMutationVariables>(DeleteDynamicCvAwardDocument, options);
      }
export type DeleteDynamicCvAwardMutationHookResult = ReturnType<typeof useDeleteDynamicCvAwardMutation>;
export type DeleteDynamicCvAwardMutationResult = Apollo.MutationResult<Types.DeleteDynamicCvAwardMutation>;
export type DeleteDynamicCvAwardMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDynamicCvAwardMutation, Types.DeleteDynamicCvAwardMutationVariables>;
export const InsertDynamicCvCollectionDocument = gql`
    mutation insertDynamicCVCollection($collection: Dynamic_cv_collection_insert_input!) {
  insert_Dynamic_cv_collection_one(object: $collection) {
    id
    name
    year
    city_id
    city {
      admin_code
      admin_name
      city
      iso2
      country_name
    }
  }
}
    `;
export type InsertDynamicCvCollectionMutationFn = Apollo.MutationFunction<Types.InsertDynamicCvCollectionMutation, Types.InsertDynamicCvCollectionMutationVariables>;

/**
 * __useInsertDynamicCvCollectionMutation__
 *
 * To run a mutation, you first call `useInsertDynamicCvCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDynamicCvCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDynamicCvCollectionMutation, { data, loading, error }] = useInsertDynamicCvCollectionMutation({
 *   variables: {
 *      collection: // value for 'collection'
 *   },
 * });
 */
export function useInsertDynamicCvCollectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertDynamicCvCollectionMutation, Types.InsertDynamicCvCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertDynamicCvCollectionMutation, Types.InsertDynamicCvCollectionMutationVariables>(InsertDynamicCvCollectionDocument, options);
      }
export type InsertDynamicCvCollectionMutationHookResult = ReturnType<typeof useInsertDynamicCvCollectionMutation>;
export type InsertDynamicCvCollectionMutationResult = Apollo.MutationResult<Types.InsertDynamicCvCollectionMutation>;
export type InsertDynamicCvCollectionMutationOptions = Apollo.BaseMutationOptions<Types.InsertDynamicCvCollectionMutation, Types.InsertDynamicCvCollectionMutationVariables>;
export const UpdateDynamicCvCollectionDocument = gql`
    mutation updateDynamicCVCollection($id: bigint!, $collection: Dynamic_cv_collection_set_input!) {
  update_Dynamic_cv_collection_by_pk(pk_columns: {id: $id}, _set: $collection) {
    id
    name
    year
    city_id
    city {
      admin_code
      admin_name
      city
      iso2
      country_name
    }
  }
}
    `;
export type UpdateDynamicCvCollectionMutationFn = Apollo.MutationFunction<Types.UpdateDynamicCvCollectionMutation, Types.UpdateDynamicCvCollectionMutationVariables>;

/**
 * __useUpdateDynamicCvCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCvCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCvCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCvCollectionMutation, { data, loading, error }] = useUpdateDynamicCvCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      collection: // value for 'collection'
 *   },
 * });
 */
export function useUpdateDynamicCvCollectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDynamicCvCollectionMutation, Types.UpdateDynamicCvCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDynamicCvCollectionMutation, Types.UpdateDynamicCvCollectionMutationVariables>(UpdateDynamicCvCollectionDocument, options);
      }
export type UpdateDynamicCvCollectionMutationHookResult = ReturnType<typeof useUpdateDynamicCvCollectionMutation>;
export type UpdateDynamicCvCollectionMutationResult = Apollo.MutationResult<Types.UpdateDynamicCvCollectionMutation>;
export type UpdateDynamicCvCollectionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDynamicCvCollectionMutation, Types.UpdateDynamicCvCollectionMutationVariables>;
export const DeleteDynamicCvCollectionDocument = gql`
    mutation deleteDynamicCVCollection($id: bigint!) {
  delete_Dynamic_cv_collection_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteDynamicCvCollectionMutationFn = Apollo.MutationFunction<Types.DeleteDynamicCvCollectionMutation, Types.DeleteDynamicCvCollectionMutationVariables>;

/**
 * __useDeleteDynamicCvCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicCvCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicCvCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicCvCollectionMutation, { data, loading, error }] = useDeleteDynamicCvCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDynamicCvCollectionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDynamicCvCollectionMutation, Types.DeleteDynamicCvCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteDynamicCvCollectionMutation, Types.DeleteDynamicCvCollectionMutationVariables>(DeleteDynamicCvCollectionDocument, options);
      }
export type DeleteDynamicCvCollectionMutationHookResult = ReturnType<typeof useDeleteDynamicCvCollectionMutation>;
export type DeleteDynamicCvCollectionMutationResult = Apollo.MutationResult<Types.DeleteDynamicCvCollectionMutation>;
export type DeleteDynamicCvCollectionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDynamicCvCollectionMutation, Types.DeleteDynamicCvCollectionMutationVariables>;
export const UpsertDynamicCvContactDocument = gql`
    mutation upsertDynamicCvContact($contact: Dynamic_cv_contact_insert_input!) {
  insert_Dynamic_cv_contact_one(
    object: $contact
    on_conflict: {constraint: Dynamic_cv_contact_pkey, update_columns: [address, email, phone_number, website]}
  ) {
    address
    cv_id
    email
    phone_number
    website
  }
}
    `;
export type UpsertDynamicCvContactMutationFn = Apollo.MutationFunction<Types.UpsertDynamicCvContactMutation, Types.UpsertDynamicCvContactMutationVariables>;

/**
 * __useUpsertDynamicCvContactMutation__
 *
 * To run a mutation, you first call `useUpsertDynamicCvContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDynamicCvContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDynamicCvContactMutation, { data, loading, error }] = useUpsertDynamicCvContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpsertDynamicCvContactMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertDynamicCvContactMutation, Types.UpsertDynamicCvContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertDynamicCvContactMutation, Types.UpsertDynamicCvContactMutationVariables>(UpsertDynamicCvContactDocument, options);
      }
export type UpsertDynamicCvContactMutationHookResult = ReturnType<typeof useUpsertDynamicCvContactMutation>;
export type UpsertDynamicCvContactMutationResult = Apollo.MutationResult<Types.UpsertDynamicCvContactMutation>;
export type UpsertDynamicCvContactMutationOptions = Apollo.BaseMutationOptions<Types.UpsertDynamicCvContactMutation, Types.UpsertDynamicCvContactMutationVariables>;
export const UpdateDynamicCvCurrentLocationDocument = gql`
    mutation updateDynamicCVCurrentLocation($artist_context_id: bigint!, $city_id: String) {
  update_Dynamic_cv(
    where: {artist_context_id: {_eq: $artist_context_id}}
    _set: {city_id: $city_id}
  ) {
    returning {
      artist_context_id
      id
      city {
        admin_code
        city
        iso2
        id
      }
    }
  }
}
    `;
export type UpdateDynamicCvCurrentLocationMutationFn = Apollo.MutationFunction<Types.UpdateDynamicCvCurrentLocationMutation, Types.UpdateDynamicCvCurrentLocationMutationVariables>;

/**
 * __useUpdateDynamicCvCurrentLocationMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCvCurrentLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCvCurrentLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCvCurrentLocationMutation, { data, loading, error }] = useUpdateDynamicCvCurrentLocationMutation({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *      city_id: // value for 'city_id'
 *   },
 * });
 */
export function useUpdateDynamicCvCurrentLocationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDynamicCvCurrentLocationMutation, Types.UpdateDynamicCvCurrentLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDynamicCvCurrentLocationMutation, Types.UpdateDynamicCvCurrentLocationMutationVariables>(UpdateDynamicCvCurrentLocationDocument, options);
      }
export type UpdateDynamicCvCurrentLocationMutationHookResult = ReturnType<typeof useUpdateDynamicCvCurrentLocationMutation>;
export type UpdateDynamicCvCurrentLocationMutationResult = Apollo.MutationResult<Types.UpdateDynamicCvCurrentLocationMutation>;
export type UpdateDynamicCvCurrentLocationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDynamicCvCurrentLocationMutation, Types.UpdateDynamicCvCurrentLocationMutationVariables>;
export const UpdateDynamicCvOrderDocument = gql`
    mutation updateDynamicCVOrder($sections: [Dynamic_cv_section_insert_input!]!) {
  insert_Dynamic_cv_section(
    objects: $sections
    on_conflict: {constraint: Dynamic_cv_section_pkey, update_columns: [order]}
  ) {
    returning {
      cv_id
      type
    }
  }
}
    `;
export type UpdateDynamicCvOrderMutationFn = Apollo.MutationFunction<Types.UpdateDynamicCvOrderMutation, Types.UpdateDynamicCvOrderMutationVariables>;

/**
 * __useUpdateDynamicCvOrderMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicCvOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicCvOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicCvOrderMutation, { data, loading, error }] = useUpdateDynamicCvOrderMutation({
 *   variables: {
 *      sections: // value for 'sections'
 *   },
 * });
 */
export function useUpdateDynamicCvOrderMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDynamicCvOrderMutation, Types.UpdateDynamicCvOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateDynamicCvOrderMutation, Types.UpdateDynamicCvOrderMutationVariables>(UpdateDynamicCvOrderDocument, options);
      }
export type UpdateDynamicCvOrderMutationHookResult = ReturnType<typeof useUpdateDynamicCvOrderMutation>;
export type UpdateDynamicCvOrderMutationResult = Apollo.MutationResult<Types.UpdateDynamicCvOrderMutation>;
export type UpdateDynamicCvOrderMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDynamicCvOrderMutation, Types.UpdateDynamicCvOrderMutationVariables>;
export const GetArtistDynamicCvDocument = gql`
    query getArtistDynamicCV($artistContextId: bigint!) {
  Dynamic_cv(where: {artist_context_id: {_eq: $artistContextId}}) {
    sections {
      type
      order
    }
    city {
      admin_code
      id
      iso2
      country_name
      city
    }
    birth_year
    country_of_origin
    id
    published_at
    status
    education {
      id
      institution_name
      degree
      city_id
      city {
        admin_code
        admin_name
        iso2
        city
        country_name
      }
      end_year
      currently_attending
    }
    awards {
      id
      name
      year
      institution_name
      is_kaleido_prize
      is_hidden
      city_id
      kaleido_prize_show_slug
      city {
        admin_code
        admin_name
        iso2
        city
        country_name
      }
    }
    exhibitions {
      id
      cv_id
      institution_name
      name
      type
      year
      city_id
      city {
        admin_code
        admin_name
        city
        iso2
        country_name
      }
    }
    collections {
      id
      name
      year
      city_id
      city {
        admin_code
        admin_name
        iso2
        city
        country_name
      }
    }
    publications {
      id
      title
      url
      year
    }
    contact {
      address
      email
      phone_number
      website
      cv_id
    }
    context {
      profile {
        id
        avatar
        title
      }
    }
  }
}
    `;

/**
 * __useGetArtistDynamicCvQuery__
 *
 * To run a query within a React component, call `useGetArtistDynamicCvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistDynamicCvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistDynamicCvQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetArtistDynamicCvQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistDynamicCvQuery, Types.GetArtistDynamicCvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistDynamicCvQuery, Types.GetArtistDynamicCvQueryVariables>(GetArtistDynamicCvDocument, options);
      }
export function useGetArtistDynamicCvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistDynamicCvQuery, Types.GetArtistDynamicCvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistDynamicCvQuery, Types.GetArtistDynamicCvQueryVariables>(GetArtistDynamicCvDocument, options);
        }
export type GetArtistDynamicCvQueryHookResult = ReturnType<typeof useGetArtistDynamicCvQuery>;
export type GetArtistDynamicCvLazyQueryHookResult = ReturnType<typeof useGetArtistDynamicCvLazyQuery>;
export type GetArtistDynamicCvQueryResult = Apollo.QueryResult<Types.GetArtistDynamicCvQuery, Types.GetArtistDynamicCvQueryVariables>;
export const GetKaleidoPrizesByArtistContextIdDocument = gql`
    query getKaleidoPrizesByArtistContextId($artistContextId: bigint!) {
  Art_prize_winner(
    where: {deleted_at: {_is_null: true}, artist_context_id: {_eq: $artistContextId}}
  ) {
    created_at
    position_type
    order
    Art_prize_show {
      title
      prize_show_slug
    }
  }
}
    `;

/**
 * __useGetKaleidoPrizesByArtistContextIdQuery__
 *
 * To run a query within a React component, call `useGetKaleidoPrizesByArtistContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKaleidoPrizesByArtistContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKaleidoPrizesByArtistContextIdQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetKaleidoPrizesByArtistContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetKaleidoPrizesByArtistContextIdQuery, Types.GetKaleidoPrizesByArtistContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKaleidoPrizesByArtistContextIdQuery, Types.GetKaleidoPrizesByArtistContextIdQueryVariables>(GetKaleidoPrizesByArtistContextIdDocument, options);
      }
export function useGetKaleidoPrizesByArtistContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKaleidoPrizesByArtistContextIdQuery, Types.GetKaleidoPrizesByArtistContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKaleidoPrizesByArtistContextIdQuery, Types.GetKaleidoPrizesByArtistContextIdQueryVariables>(GetKaleidoPrizesByArtistContextIdDocument, options);
        }
export type GetKaleidoPrizesByArtistContextIdQueryHookResult = ReturnType<typeof useGetKaleidoPrizesByArtistContextIdQuery>;
export type GetKaleidoPrizesByArtistContextIdLazyQueryHookResult = ReturnType<typeof useGetKaleidoPrizesByArtistContextIdLazyQuery>;
export type GetKaleidoPrizesByArtistContextIdQueryResult = Apollo.QueryResult<Types.GetKaleidoPrizesByArtistContextIdQuery, Types.GetKaleidoPrizesByArtistContextIdQueryVariables>;
export const GetExploreArtistsDocument = gql`
    query getExploreArtists($limit: Int = 10, $offset: Int = 0) {
  Explore_artists(limit: $limit, offset: $offset) {
    ...ExploreArtistCommonFilterFields
  }
}
    ${ExploreArtistCommonFilterFieldsFragmentDoc}`;

/**
 * __useGetExploreArtistsQuery__
 *
 * To run a query within a React component, call `useGetExploreArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExploreArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExploreArtistsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetExploreArtistsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetExploreArtistsQuery, Types.GetExploreArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExploreArtistsQuery, Types.GetExploreArtistsQueryVariables>(GetExploreArtistsDocument, options);
      }
export function useGetExploreArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExploreArtistsQuery, Types.GetExploreArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExploreArtistsQuery, Types.GetExploreArtistsQueryVariables>(GetExploreArtistsDocument, options);
        }
export type GetExploreArtistsQueryHookResult = ReturnType<typeof useGetExploreArtistsQuery>;
export type GetExploreArtistsLazyQueryHookResult = ReturnType<typeof useGetExploreArtistsLazyQuery>;
export type GetExploreArtistsQueryResult = Apollo.QueryResult<Types.GetExploreArtistsQuery, Types.GetExploreArtistsQueryVariables>;
export const GetFilteredExploreArtistsDocument = gql`
    query getFilteredExploreArtists($limit: Int = 10, $offset: Int = 0, $where: Explore_artists_bool_exp = {}, $sort: [Explore_artists_order_by!] = {}) {
  Explore_artists(
    limit: $limit
    offset: $offset
    where: {_and: [$where]}
    order_by: $sort
  ) {
    ...ExploreArtistCommonFilterFields
  }
}
    ${ExploreArtistCommonFilterFieldsFragmentDoc}`;

/**
 * __useGetFilteredExploreArtistsQuery__
 *
 * To run a query within a React component, call `useGetFilteredExploreArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredExploreArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredExploreArtistsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetFilteredExploreArtistsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFilteredExploreArtistsQuery, Types.GetFilteredExploreArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFilteredExploreArtistsQuery, Types.GetFilteredExploreArtistsQueryVariables>(GetFilteredExploreArtistsDocument, options);
      }
export function useGetFilteredExploreArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilteredExploreArtistsQuery, Types.GetFilteredExploreArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFilteredExploreArtistsQuery, Types.GetFilteredExploreArtistsQueryVariables>(GetFilteredExploreArtistsDocument, options);
        }
export type GetFilteredExploreArtistsQueryHookResult = ReturnType<typeof useGetFilteredExploreArtistsQuery>;
export type GetFilteredExploreArtistsLazyQueryHookResult = ReturnType<typeof useGetFilteredExploreArtistsLazyQuery>;
export type GetFilteredExploreArtistsQueryResult = Apollo.QueryResult<Types.GetFilteredExploreArtistsQuery, Types.GetFilteredExploreArtistsQueryVariables>;
export const GetFavoritesFilteredArtworksDocument = gql`
    query getFavoritesFilteredArtworks($where: Artwork_bool_exp = {}, $contextId: bigint!) {
  Artwork(
    where: {_and: [$where, {artwork_to_collections: {Collection: {type: {_eq: FAVORITE}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    id
    slug
    edition_type
    primary_image_metadata_id
    thumbnail_metadata_id
    is_available_sale
    ...LocalCommonModifiersRequiredFields
    published_date
    artist_context {
      id
      profile {
        id
        avatar
        title
        handle
      }
      locations {
        city
        country
      }
    }
    artwork_details {
      creation_date_day
      creation_date_month
      creation_date_year
      depth
      duration
      height
      is_dark_mode
      materials
      medium
      measuring_unit
      materials_and_medium
      discipline_other
      discipline
      title
      tools
      width
    }
    artwork_to_collections(where: {Collection: {type: {_eq: FAVORITE}}}) {
      order
      collection_id
    }
    Artwork_prices {
      price
      scope
      currency
      id
      artwork_to_showroom_details {
        status
        artwork_to_collection {
          collection_id
        }
      }
    }
    standaloneEditions: Editions(
      where: {Artwork: {edition_type: {_eq: STANDALONE_EDITION}}}
    ) {
      id
      title
      editionSizes: Edition_By_Sizes(where: {status: {_eq: COMPLETE}}) {
        id
        size: print_size
        width
        height
        measureUnit: measuring_unit
        technique
        materials
      }
    }
  }
}
    ${LocalCommonModifiersRequiredFieldsFragmentDoc}`;

/**
 * __useGetFavoritesFilteredArtworksQuery__
 *
 * To run a query within a React component, call `useGetFavoritesFilteredArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritesFilteredArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritesFilteredArtworksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetFavoritesFilteredArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoritesFilteredArtworksQuery, Types.GetFavoritesFilteredArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoritesFilteredArtworksQuery, Types.GetFavoritesFilteredArtworksQueryVariables>(GetFavoritesFilteredArtworksDocument, options);
      }
export function useGetFavoritesFilteredArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoritesFilteredArtworksQuery, Types.GetFavoritesFilteredArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoritesFilteredArtworksQuery, Types.GetFavoritesFilteredArtworksQueryVariables>(GetFavoritesFilteredArtworksDocument, options);
        }
export type GetFavoritesFilteredArtworksQueryHookResult = ReturnType<typeof useGetFavoritesFilteredArtworksQuery>;
export type GetFavoritesFilteredArtworksLazyQueryHookResult = ReturnType<typeof useGetFavoritesFilteredArtworksLazyQuery>;
export type GetFavoritesFilteredArtworksQueryResult = Apollo.QueryResult<Types.GetFavoritesFilteredArtworksQuery, Types.GetFavoritesFilteredArtworksQueryVariables>;
export const GetFavoriteArtworkInfoDocument = gql`
    query GetFavoriteArtworkInfo($artworks: [bigint!]!, $artists: [bigint!]!, $skipRecognitions: Boolean!, $skipFavorites: Boolean!, $callerCtxId: bigint) {
  artworks: Artwork_explore_info(
    where: {_and: [{artwork_id: {_in: $artworks}}, {_or: [{last_transaction_artwork_id: {_is_null: true}}, {last_transaction_artwork_id: {_in: $artworks}}]}]}
  ) {
    artworkId: artwork_id
    thumbnailMetadataId: thumbnail_metadata_id
    primaryMetadataId: primary_image_metadata_id
    artistContextId: artist_context_id
    isAvailableSale: is_available_sale
    title
    width
    height
    depth
    slug
    discipline
    editionType: edition_type
    measuringUnit: measuring_unit
    lastTransactionId: last_transaction_id
    availablePrintAmount: available_print_amount
    editionIsAvailableSale: edition_is_available_sale
    originalArtworkId: original_artwork_id
  }
  artists: Artist_explore_info(where: {artist_context_id: {_in: $artists}}) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    country
    city
  }
  recognitions: Social_graph_followers(
    where: {context_id: {_eq: $callerCtxId}, target_context_id: {_in: $artists}}
  ) @skip(if: $skipRecognitions) {
    artistContextId: target_context_id
    state
    isMutual: is_mutual
    naturalOrder: natural_order
    selectedOrder: selected_order
  }
  favorites: Artwork_to_Collection(
    where: {artwork_id: {_in: $artworks}, Collection: {owner_context_id: {_eq: $callerCtxId}, type: {_eq: FAVORITE}}}
  ) @skip(if: $skipFavorites) {
    artworkId: artwork_id
  }
}
    `;

/**
 * __useGetFavoriteArtworkInfoQuery__
 *
 * To run a query within a React component, call `useGetFavoriteArtworkInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteArtworkInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteArtworkInfoQuery({
 *   variables: {
 *      artworks: // value for 'artworks'
 *      artists: // value for 'artists'
 *      skipRecognitions: // value for 'skipRecognitions'
 *      skipFavorites: // value for 'skipFavorites'
 *      callerCtxId: // value for 'callerCtxId'
 *   },
 * });
 */
export function useGetFavoriteArtworkInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoriteArtworkInfoQuery, Types.GetFavoriteArtworkInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoriteArtworkInfoQuery, Types.GetFavoriteArtworkInfoQueryVariables>(GetFavoriteArtworkInfoDocument, options);
      }
export function useGetFavoriteArtworkInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoriteArtworkInfoQuery, Types.GetFavoriteArtworkInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoriteArtworkInfoQuery, Types.GetFavoriteArtworkInfoQueryVariables>(GetFavoriteArtworkInfoDocument, options);
        }
export type GetFavoriteArtworkInfoQueryHookResult = ReturnType<typeof useGetFavoriteArtworkInfoQuery>;
export type GetFavoriteArtworkInfoLazyQueryHookResult = ReturnType<typeof useGetFavoriteArtworkInfoLazyQuery>;
export type GetFavoriteArtworkInfoQueryResult = Apollo.QueryResult<Types.GetFavoriteArtworkInfoQuery, Types.GetFavoriteArtworkInfoQueryVariables>;
export const GetFavoriteArtworkListingByFavoriteLeastRecentDocument = gql`
    query GetFavoriteArtworkListingByFavoriteLeastRecent($limit: Int!, $contextId: bigint!, $lastTimeStamp: timestamptz!, $where: Artwork_favorite_listing_bool_exp!) {
  artworks: Artwork_favorite_listing(
    where: {_and: [{favorited_at: {_gt: $lastTimeStamp}}, {owner_context_id: {_eq: $contextId}}, $where]}
    order_by: {favorited_at: asc}
    limit: $limit
  ) {
    artistContextId: artist_context_id
    favoritedAt: favorited_at
    artworkId: artwork_id
    aspectRatio: aspect_ratio
  }
}
    `;

/**
 * __useGetFavoriteArtworkListingByFavoriteLeastRecentQuery__
 *
 * To run a query within a React component, call `useGetFavoriteArtworkListingByFavoriteLeastRecentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteArtworkListingByFavoriteLeastRecentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteArtworkListingByFavoriteLeastRecentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      contextId: // value for 'contextId'
 *      lastTimeStamp: // value for 'lastTimeStamp'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFavoriteArtworkListingByFavoriteLeastRecentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoriteArtworkListingByFavoriteLeastRecentQuery, Types.GetFavoriteArtworkListingByFavoriteLeastRecentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoriteArtworkListingByFavoriteLeastRecentQuery, Types.GetFavoriteArtworkListingByFavoriteLeastRecentQueryVariables>(GetFavoriteArtworkListingByFavoriteLeastRecentDocument, options);
      }
export function useGetFavoriteArtworkListingByFavoriteLeastRecentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoriteArtworkListingByFavoriteLeastRecentQuery, Types.GetFavoriteArtworkListingByFavoriteLeastRecentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoriteArtworkListingByFavoriteLeastRecentQuery, Types.GetFavoriteArtworkListingByFavoriteLeastRecentQueryVariables>(GetFavoriteArtworkListingByFavoriteLeastRecentDocument, options);
        }
export type GetFavoriteArtworkListingByFavoriteLeastRecentQueryHookResult = ReturnType<typeof useGetFavoriteArtworkListingByFavoriteLeastRecentQuery>;
export type GetFavoriteArtworkListingByFavoriteLeastRecentLazyQueryHookResult = ReturnType<typeof useGetFavoriteArtworkListingByFavoriteLeastRecentLazyQuery>;
export type GetFavoriteArtworkListingByFavoriteLeastRecentQueryResult = Apollo.QueryResult<Types.GetFavoriteArtworkListingByFavoriteLeastRecentQuery, Types.GetFavoriteArtworkListingByFavoriteLeastRecentQueryVariables>;
export const GetFavoriteArtworkListingByFavoriteMostRecentDocument = gql`
    query GetFavoriteArtworkListingByFavoriteMostRecent($limit: Int!, $contextId: bigint!, $lastTimeStamp: timestamptz!, $where: Artwork_favorite_listing_bool_exp!) {
  artworks: Artwork_favorite_listing(
    where: {_and: [{favorited_at: {_lt: $lastTimeStamp}}, {owner_context_id: {_eq: $contextId}}, $where]}
    order_by: {favorited_at: desc}
    limit: $limit
  ) {
    artistContextId: artist_context_id
    favoritedAt: favorited_at
    artworkId: artwork_id
    aspectRatio: aspect_ratio
  }
}
    `;

/**
 * __useGetFavoriteArtworkListingByFavoriteMostRecentQuery__
 *
 * To run a query within a React component, call `useGetFavoriteArtworkListingByFavoriteMostRecentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteArtworkListingByFavoriteMostRecentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteArtworkListingByFavoriteMostRecentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      contextId: // value for 'contextId'
 *      lastTimeStamp: // value for 'lastTimeStamp'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetFavoriteArtworkListingByFavoriteMostRecentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoriteArtworkListingByFavoriteMostRecentQuery, Types.GetFavoriteArtworkListingByFavoriteMostRecentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoriteArtworkListingByFavoriteMostRecentQuery, Types.GetFavoriteArtworkListingByFavoriteMostRecentQueryVariables>(GetFavoriteArtworkListingByFavoriteMostRecentDocument, options);
      }
export function useGetFavoriteArtworkListingByFavoriteMostRecentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoriteArtworkListingByFavoriteMostRecentQuery, Types.GetFavoriteArtworkListingByFavoriteMostRecentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoriteArtworkListingByFavoriteMostRecentQuery, Types.GetFavoriteArtworkListingByFavoriteMostRecentQueryVariables>(GetFavoriteArtworkListingByFavoriteMostRecentDocument, options);
        }
export type GetFavoriteArtworkListingByFavoriteMostRecentQueryHookResult = ReturnType<typeof useGetFavoriteArtworkListingByFavoriteMostRecentQuery>;
export type GetFavoriteArtworkListingByFavoriteMostRecentLazyQueryHookResult = ReturnType<typeof useGetFavoriteArtworkListingByFavoriteMostRecentLazyQuery>;
export type GetFavoriteArtworkListingByFavoriteMostRecentQueryResult = Apollo.QueryResult<Types.GetFavoriteArtworkListingByFavoriteMostRecentQuery, Types.GetFavoriteArtworkListingByFavoriteMostRecentQueryVariables>;
export const GetSixMoreRecentFeaturedArtPrizesDocument = gql`
    query getSixMoreRecentFeaturedArtPrizes {
  Art_prize_show(
    where: {_and: [{deleted_at: {_is_null: true}, published_at: {_is_null: false}}, {published_at: {_lte: "now()"}}]}
    limit: 6
    order_by: {published_at: desc}
  ) {
    id
    title
    subtitle
    description
    short_description
    background_metadata_id
    thumbnail_metadata_id
    amount_participants
    prize_show_slug
    published_at
    Art_prize_winners {
      artist_context_id
    }
  }
}
    `;

/**
 * __useGetSixMoreRecentFeaturedArtPrizesQuery__
 *
 * To run a query within a React component, call `useGetSixMoreRecentFeaturedArtPrizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSixMoreRecentFeaturedArtPrizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSixMoreRecentFeaturedArtPrizesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSixMoreRecentFeaturedArtPrizesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetSixMoreRecentFeaturedArtPrizesQuery, Types.GetSixMoreRecentFeaturedArtPrizesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSixMoreRecentFeaturedArtPrizesQuery, Types.GetSixMoreRecentFeaturedArtPrizesQueryVariables>(GetSixMoreRecentFeaturedArtPrizesDocument, options);
      }
export function useGetSixMoreRecentFeaturedArtPrizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSixMoreRecentFeaturedArtPrizesQuery, Types.GetSixMoreRecentFeaturedArtPrizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSixMoreRecentFeaturedArtPrizesQuery, Types.GetSixMoreRecentFeaturedArtPrizesQueryVariables>(GetSixMoreRecentFeaturedArtPrizesDocument, options);
        }
export type GetSixMoreRecentFeaturedArtPrizesQueryHookResult = ReturnType<typeof useGetSixMoreRecentFeaturedArtPrizesQuery>;
export type GetSixMoreRecentFeaturedArtPrizesLazyQueryHookResult = ReturnType<typeof useGetSixMoreRecentFeaturedArtPrizesLazyQuery>;
export type GetSixMoreRecentFeaturedArtPrizesQueryResult = Apollo.QueryResult<Types.GetSixMoreRecentFeaturedArtPrizesQuery, Types.GetSixMoreRecentFeaturedArtPrizesQueryVariables>;
export const GetExploreArtistInfoDocument = gql`
    query GetExploreArtistInfo($artists: [bigint!]!, $skipRecognitions: Boolean!, $callerCtxId: bigint) {
  artworks: artist_cardgrid_artworks_view(
    where: {artist_context_id: {_in: $artists}, rank: {_lte: 3}}
  ) {
    id
    thumbnailMetadataId: thumbnail_metadata_id
    rank
    artistContextId: artist_context_id
  }
  artists: Artist_explore_info(where: {artist_context_id: {_in: $artists}}) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    first_name
    last_name
    handle
    avatar
    country
    city
    is_public
  }
  recognitions: Social_graph_followers(
    where: {context_id: {_eq: $callerCtxId}, target_context_id: {_in: $artists}}
  ) @skip(if: $skipRecognitions) {
    artistContextId: target_context_id
    state
    isMutual: is_mutual
    naturalOrder: natural_order
    selectedOrder: selected_order
  }
}
    `;

/**
 * __useGetExploreArtistInfoQuery__
 *
 * To run a query within a React component, call `useGetExploreArtistInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExploreArtistInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExploreArtistInfoQuery({
 *   variables: {
 *      artists: // value for 'artists'
 *      skipRecognitions: // value for 'skipRecognitions'
 *      callerCtxId: // value for 'callerCtxId'
 *   },
 * });
 */
export function useGetExploreArtistInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetExploreArtistInfoQuery, Types.GetExploreArtistInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExploreArtistInfoQuery, Types.GetExploreArtistInfoQueryVariables>(GetExploreArtistInfoDocument, options);
      }
export function useGetExploreArtistInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExploreArtistInfoQuery, Types.GetExploreArtistInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExploreArtistInfoQuery, Types.GetExploreArtistInfoQueryVariables>(GetExploreArtistInfoDocument, options);
        }
export type GetExploreArtistInfoQueryHookResult = ReturnType<typeof useGetExploreArtistInfoQuery>;
export type GetExploreArtistInfoLazyQueryHookResult = ReturnType<typeof useGetExploreArtistInfoLazyQuery>;
export type GetExploreArtistInfoQueryResult = Apollo.QueryResult<Types.GetExploreArtistInfoQuery, Types.GetExploreArtistInfoQueryVariables>;
export const GetExploreArtistsListingByDateDocument = gql`
    query GetExploreArtistsListingByDate($limit: Int!, $where: Artist_explore_listing_bool_exp!, $sorts: [Artist_explore_listing_order_by!]!, $offset: Int = 0) {
  artists: Artist_explore_listing(
    where: $where
    order_by: $sorts
    limit: $limit
    offset: $offset
  ) {
    contextId: artist_context_id
    curation_order
    appearedAt: appear_at
  }
}
    `;

/**
 * __useGetExploreArtistsListingByDateQuery__
 *
 * To run a query within a React component, call `useGetExploreArtistsListingByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExploreArtistsListingByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExploreArtistsListingByDateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      sorts: // value for 'sorts'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetExploreArtistsListingByDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetExploreArtistsListingByDateQuery, Types.GetExploreArtistsListingByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExploreArtistsListingByDateQuery, Types.GetExploreArtistsListingByDateQueryVariables>(GetExploreArtistsListingByDateDocument, options);
      }
export function useGetExploreArtistsListingByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExploreArtistsListingByDateQuery, Types.GetExploreArtistsListingByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExploreArtistsListingByDateQuery, Types.GetExploreArtistsListingByDateQueryVariables>(GetExploreArtistsListingByDateDocument, options);
        }
export type GetExploreArtistsListingByDateQueryHookResult = ReturnType<typeof useGetExploreArtistsListingByDateQuery>;
export type GetExploreArtistsListingByDateLazyQueryHookResult = ReturnType<typeof useGetExploreArtistsListingByDateLazyQuery>;
export type GetExploreArtistsListingByDateQueryResult = Apollo.QueryResult<Types.GetExploreArtistsListingByDateQuery, Types.GetExploreArtistsListingByDateQueryVariables>;
export const GetExploreArtistsListingByTitleDocument = gql`
    query GetExploreArtistsListingByTitle($limit: Int!, $where: Artist_explore_listing_bool_exp!, $sorts: [Artist_explore_listing_order_by!]!, $offset: Int = 0) {
  artists: Artist_explore_listing(
    where: $where
    order_by: $sorts
    limit: $limit
    offset: $offset
  ) {
    contextId: artist_context_id
    curation_order
    title
  }
}
    `;

/**
 * __useGetExploreArtistsListingByTitleQuery__
 *
 * To run a query within a React component, call `useGetExploreArtistsListingByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExploreArtistsListingByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExploreArtistsListingByTitleQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      sorts: // value for 'sorts'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetExploreArtistsListingByTitleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetExploreArtistsListingByTitleQuery, Types.GetExploreArtistsListingByTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExploreArtistsListingByTitleQuery, Types.GetExploreArtistsListingByTitleQueryVariables>(GetExploreArtistsListingByTitleDocument, options);
      }
export function useGetExploreArtistsListingByTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExploreArtistsListingByTitleQuery, Types.GetExploreArtistsListingByTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExploreArtistsListingByTitleQuery, Types.GetExploreArtistsListingByTitleQueryVariables>(GetExploreArtistsListingByTitleDocument, options);
        }
export type GetExploreArtistsListingByTitleQueryHookResult = ReturnType<typeof useGetExploreArtistsListingByTitleQuery>;
export type GetExploreArtistsListingByTitleLazyQueryHookResult = ReturnType<typeof useGetExploreArtistsListingByTitleLazyQuery>;
export type GetExploreArtistsListingByTitleQueryResult = Apollo.QueryResult<Types.GetExploreArtistsListingByTitleQuery, Types.GetExploreArtistsListingByTitleQueryVariables>;
export const GetExploreArtworkInfoDocument = gql`
    query GetExploreArtworkInfo($callerCtxId: bigint, $artworks: [bigint!]!, $artists: [bigint!]!, $galleries: [bigint!]!, $artworkPrizeIds: [String!]!, $collectors: [bigint!]!, $skipRecognitions: Boolean!, $skipFavorites: Boolean!, $skipPrizes: Boolean!, $skipCollected: Boolean!) {
  artworks: Artwork_explore_info(
    where: {_and: [{artwork_id: {_in: $artworks}}, {_or: [{last_transaction_artwork_id: {_is_null: true}}, {last_transaction_artwork_id: {_in: $artworks}}]}]}
  ) {
    artworkId: artwork_id
    thumbnailMetadataId: thumbnail_metadata_id
    primaryMetadataId: primary_image_metadata_id
    artistContextId: artist_context_id
    isAvailableSale: is_available_sale
    title
    width
    height
    depth
    slug
    discipline
    editionType: edition_type
    measuringUnit: measuring_unit
    lastTransactionId: last_transaction_id
    availablePrintAmount: available_print_amount
    editionIsAvailableSale: edition_is_available_sale
    originalArtworkId: original_artwork_id
  }
  artists: Artist_explore_info(where: {artist_context_id: {_in: $artists}}) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    country
    city
  }
  galleries: Gallery_explore_info(where: {gallery_context_id: {_in: $galleries}}) @skip(if: $skipCollected) {
    contextId: gallery_context_id
    profileId: gallery_profile_id
    title
    handle
    avatar
    country
    city
  }
  collectors: Collector_explore_info(
    where: {collector_context_id: {_in: $collectors}}
  ) @skip(if: $skipCollected) {
    contextId: collector_context_id
    profileId: collector_profile_id
    title
    handle
    avatar
  }
  prizes: Artwork_prize_info(where: {artwork_prize_ids: {_in: $artworkPrizeIds}}) @skip(if: $skipPrizes) {
    artworkId: artwork_id
    artPrizeShowId: art_prize_show_id
    artworkPrizeIds: artwork_prize_ids
    title
    slug: prize_show_slug
    position: position_type
  }
  recognitions: Social_graph_followers(
    where: {context_id: {_eq: $callerCtxId}, target_context_id: {_in: $artists}}
  ) @skip(if: $skipRecognitions) {
    artistContextId: target_context_id
    state
    isMutual: is_mutual
    naturalOrder: natural_order
    selectedOrder: selected_order
  }
  favorites: Artwork_to_Collection(
    where: {artwork_id: {_in: $artworks}, Collection: {owner_context_id: {_eq: $callerCtxId}, type: {_eq: FAVORITE}}}
  ) @skip(if: $skipFavorites) {
    artworkId: artwork_id
  }
}
    `;

/**
 * __useGetExploreArtworkInfoQuery__
 *
 * To run a query within a React component, call `useGetExploreArtworkInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExploreArtworkInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExploreArtworkInfoQuery({
 *   variables: {
 *      callerCtxId: // value for 'callerCtxId'
 *      artworks: // value for 'artworks'
 *      artists: // value for 'artists'
 *      galleries: // value for 'galleries'
 *      artworkPrizeIds: // value for 'artworkPrizeIds'
 *      collectors: // value for 'collectors'
 *      skipRecognitions: // value for 'skipRecognitions'
 *      skipFavorites: // value for 'skipFavorites'
 *      skipPrizes: // value for 'skipPrizes'
 *      skipCollected: // value for 'skipCollected'
 *   },
 * });
 */
export function useGetExploreArtworkInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetExploreArtworkInfoQuery, Types.GetExploreArtworkInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExploreArtworkInfoQuery, Types.GetExploreArtworkInfoQueryVariables>(GetExploreArtworkInfoDocument, options);
      }
export function useGetExploreArtworkInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExploreArtworkInfoQuery, Types.GetExploreArtworkInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExploreArtworkInfoQuery, Types.GetExploreArtworkInfoQueryVariables>(GetExploreArtworkInfoDocument, options);
        }
export type GetExploreArtworkInfoQueryHookResult = ReturnType<typeof useGetExploreArtworkInfoQuery>;
export type GetExploreArtworkInfoLazyQueryHookResult = ReturnType<typeof useGetExploreArtworkInfoLazyQuery>;
export type GetExploreArtworkInfoQueryResult = Apollo.QueryResult<Types.GetExploreArtworkInfoQuery, Types.GetExploreArtworkInfoQueryVariables>;
export const GetKaleidoLiveListingByDateDocument = gql`
    query GetKaleidoLiveListingByDate($limit: Int!, $where: Kaleido_live_listing_bool_exp!) {
  artworks: Kaleido_live_listing(
    where: {_and: [$where]}
    order_by: [{published_date: desc}, {artwork_id: desc}]
    limit: $limit
  ) {
    artistContextId: artist_context_id
    artworkId: artwork_id
    publishedDate: published_date
    eventType: event_type
    collectedByCtxId: collected_by_context_id
    galleryContextId: gallery_context_id
    artPrizeShowId: art_prize_show_id
    aspect {
      ratio: aspect_ratio
    }
  }
}
    `;

/**
 * __useGetKaleidoLiveListingByDateQuery__
 *
 * To run a query within a React component, call `useGetKaleidoLiveListingByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKaleidoLiveListingByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKaleidoLiveListingByDateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetKaleidoLiveListingByDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetKaleidoLiveListingByDateQuery, Types.GetKaleidoLiveListingByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKaleidoLiveListingByDateQuery, Types.GetKaleidoLiveListingByDateQueryVariables>(GetKaleidoLiveListingByDateDocument, options);
      }
export function useGetKaleidoLiveListingByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKaleidoLiveListingByDateQuery, Types.GetKaleidoLiveListingByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKaleidoLiveListingByDateQuery, Types.GetKaleidoLiveListingByDateQueryVariables>(GetKaleidoLiveListingByDateDocument, options);
        }
export type GetKaleidoLiveListingByDateQueryHookResult = ReturnType<typeof useGetKaleidoLiveListingByDateQuery>;
export type GetKaleidoLiveListingByDateLazyQueryHookResult = ReturnType<typeof useGetKaleidoLiveListingByDateLazyQuery>;
export type GetKaleidoLiveListingByDateQueryResult = Apollo.QueryResult<Types.GetKaleidoLiveListingByDateQuery, Types.GetKaleidoLiveListingByDateQueryVariables>;
export const GetContextIdAndProfileDisplaySettingsByHandleDocument = gql`
    query getContextIdAndProfileDisplaySettingsByHandle($handle: String!) {
  Profile(where: {handle: {_ilike: $handle}}) {
    id
    context {
      id
    }
  }
  Profile_appearance_settings(where: {profile: {handle: {_eq: $handle}}}) {
    id
    profile_columns_count
    profile_layout_type
  }
}
    `;

/**
 * __useGetContextIdAndProfileDisplaySettingsByHandleQuery__
 *
 * To run a query within a React component, call `useGetContextIdAndProfileDisplaySettingsByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContextIdAndProfileDisplaySettingsByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContextIdAndProfileDisplaySettingsByHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetContextIdAndProfileDisplaySettingsByHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetContextIdAndProfileDisplaySettingsByHandleQuery, Types.GetContextIdAndProfileDisplaySettingsByHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetContextIdAndProfileDisplaySettingsByHandleQuery, Types.GetContextIdAndProfileDisplaySettingsByHandleQueryVariables>(GetContextIdAndProfileDisplaySettingsByHandleDocument, options);
      }
export function useGetContextIdAndProfileDisplaySettingsByHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContextIdAndProfileDisplaySettingsByHandleQuery, Types.GetContextIdAndProfileDisplaySettingsByHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetContextIdAndProfileDisplaySettingsByHandleQuery, Types.GetContextIdAndProfileDisplaySettingsByHandleQueryVariables>(GetContextIdAndProfileDisplaySettingsByHandleDocument, options);
        }
export type GetContextIdAndProfileDisplaySettingsByHandleQueryHookResult = ReturnType<typeof useGetContextIdAndProfileDisplaySettingsByHandleQuery>;
export type GetContextIdAndProfileDisplaySettingsByHandleLazyQueryHookResult = ReturnType<typeof useGetContextIdAndProfileDisplaySettingsByHandleLazyQuery>;
export type GetContextIdAndProfileDisplaySettingsByHandleQueryResult = Apollo.QueryResult<Types.GetContextIdAndProfileDisplaySettingsByHandleQuery, Types.GetContextIdAndProfileDisplaySettingsByHandleQueryVariables>;
export const GetPrintSizesByArtworkIdDocument = gql`
    query GetPrintSizesByArtworkId($artworkId: bigint!) {
  editions: Artwork(where: {id: {_eq: $artworkId}}) {
    edition: Editions {
      sizes: Edition_By_Sizes {
        width
        height
        depth
        measuring_unit
        print_size
      }
    }
  }
}
    `;

/**
 * __useGetPrintSizesByArtworkIdQuery__
 *
 * To run a query within a React component, call `useGetPrintSizesByArtworkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrintSizesByArtworkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrintSizesByArtworkIdQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetPrintSizesByArtworkIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPrintSizesByArtworkIdQuery, Types.GetPrintSizesByArtworkIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPrintSizesByArtworkIdQuery, Types.GetPrintSizesByArtworkIdQueryVariables>(GetPrintSizesByArtworkIdDocument, options);
      }
export function useGetPrintSizesByArtworkIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPrintSizesByArtworkIdQuery, Types.GetPrintSizesByArtworkIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPrintSizesByArtworkIdQuery, Types.GetPrintSizesByArtworkIdQueryVariables>(GetPrintSizesByArtworkIdDocument, options);
        }
export type GetPrintSizesByArtworkIdQueryHookResult = ReturnType<typeof useGetPrintSizesByArtworkIdQuery>;
export type GetPrintSizesByArtworkIdLazyQueryHookResult = ReturnType<typeof useGetPrintSizesByArtworkIdLazyQuery>;
export type GetPrintSizesByArtworkIdQueryResult = Apollo.QueryResult<Types.GetPrintSizesByArtworkIdQuery, Types.GetPrintSizesByArtworkIdQueryVariables>;
export const GetCollectedArtworksDocument = gql`
    query getCollectedArtworks($limit: Int!) {
  Artwork_collected_listing(limit: $limit) {
    artist_explore_info {
      contextId: artist_context_id
      handle
      artistProfileId: artist_profile_id
      avatar
      title
      firstName: first_name
      lastName: last_name
    }
    collector_explore_info {
      contextId: collector_context_id
      title
      firstName: first_name
      lastName: last_name
      handle
      collectorProfileId: collector_profile_id
      avatar
    }
    artwork_explore_info {
      artworkId: artwork_id
      metadataId: primary_image_metadata_id
      title
      slug
      editionType: edition_type
      measuringUnit: measuring_unit
      width
      height
      discipline
    }
  }
}
    `;

/**
 * __useGetCollectedArtworksQuery__
 *
 * To run a query within a React component, call `useGetCollectedArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectedArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectedArtworksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCollectedArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectedArtworksQuery, Types.GetCollectedArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectedArtworksQuery, Types.GetCollectedArtworksQueryVariables>(GetCollectedArtworksDocument, options);
      }
export function useGetCollectedArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectedArtworksQuery, Types.GetCollectedArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectedArtworksQuery, Types.GetCollectedArtworksQueryVariables>(GetCollectedArtworksDocument, options);
        }
export type GetCollectedArtworksQueryHookResult = ReturnType<typeof useGetCollectedArtworksQuery>;
export type GetCollectedArtworksLazyQueryHookResult = ReturnType<typeof useGetCollectedArtworksLazyQuery>;
export type GetCollectedArtworksQueryResult = Apollo.QueryResult<Types.GetCollectedArtworksQuery, Types.GetCollectedArtworksQueryVariables>;
export const GetHomeFeaturedCollectionsDocument = gql`
    query getHomeFeaturedCollections($limit: Int!) {
  Artist_Collection(
    where: {deleted_at: {_is_null: true}, type: {_eq: virtual_exhibition}}
    limit: $limit
  ) {
    id
    title
    bannerMetadataId: banner_metadata_id
    internalUrl: internal_url
    description
  }
}
    `;

/**
 * __useGetHomeFeaturedCollectionsQuery__
 *
 * To run a query within a React component, call `useGetHomeFeaturedCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeFeaturedCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeFeaturedCollectionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetHomeFeaturedCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetHomeFeaturedCollectionsQuery, Types.GetHomeFeaturedCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetHomeFeaturedCollectionsQuery, Types.GetHomeFeaturedCollectionsQueryVariables>(GetHomeFeaturedCollectionsDocument, options);
      }
export function useGetHomeFeaturedCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetHomeFeaturedCollectionsQuery, Types.GetHomeFeaturedCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetHomeFeaturedCollectionsQuery, Types.GetHomeFeaturedCollectionsQueryVariables>(GetHomeFeaturedCollectionsDocument, options);
        }
export type GetHomeFeaturedCollectionsQueryHookResult = ReturnType<typeof useGetHomeFeaturedCollectionsQuery>;
export type GetHomeFeaturedCollectionsLazyQueryHookResult = ReturnType<typeof useGetHomeFeaturedCollectionsLazyQuery>;
export type GetHomeFeaturedCollectionsQueryResult = Apollo.QueryResult<Types.GetHomeFeaturedCollectionsQuery, Types.GetHomeFeaturedCollectionsQueryVariables>;
export const GetLandingPageArtworkDocument = gql`
    query getLandingPageArtwork {
  Landing_page_artwork(where: {active: {_eq: true}}, limit: 1) {
    artwork {
      id
      primary_image_metadata_id
      artwork_details {
        title
        creation_date_year
      }
      artist_context {
        profile {
          id
          avatar
          title
          handle
        }
      }
    }
  }
}
    `;

/**
 * __useGetLandingPageArtworkQuery__
 *
 * To run a query within a React component, call `useGetLandingPageArtworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLandingPageArtworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLandingPageArtworkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLandingPageArtworkQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLandingPageArtworkQuery, Types.GetLandingPageArtworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLandingPageArtworkQuery, Types.GetLandingPageArtworkQueryVariables>(GetLandingPageArtworkDocument, options);
      }
export function useGetLandingPageArtworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLandingPageArtworkQuery, Types.GetLandingPageArtworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLandingPageArtworkQuery, Types.GetLandingPageArtworkQueryVariables>(GetLandingPageArtworkDocument, options);
        }
export type GetLandingPageArtworkQueryHookResult = ReturnType<typeof useGetLandingPageArtworkQuery>;
export type GetLandingPageArtworkLazyQueryHookResult = ReturnType<typeof useGetLandingPageArtworkLazyQuery>;
export type GetLandingPageArtworkQueryResult = Apollo.QueryResult<Types.GetLandingPageArtworkQuery, Types.GetLandingPageArtworkQueryVariables>;
export const GetSelectedArtworksInfoDocument = gql`
    query GetSelectedArtworksInfo($artworks: [bigint!]!) {
  artworks: Artwork_documents_info(where: {artwork_id: {_in: $artworks}}) {
    artworkId: artwork_id
    thumbnailMetadataId: thumbnail_metadata_id
    artistContextId: artist_context_id
    title
    width
    height
    depth
    slug
    measuringUnit: measuring_unit
    editionType: edition_type
    originalArtworkId: original_artwork_id
  }
}
    `;

/**
 * __useGetSelectedArtworksInfoQuery__
 *
 * To run a query within a React component, call `useGetSelectedArtworksInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedArtworksInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedArtworksInfoQuery({
 *   variables: {
 *      artworks: // value for 'artworks'
 *   },
 * });
 */
export function useGetSelectedArtworksInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSelectedArtworksInfoQuery, Types.GetSelectedArtworksInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSelectedArtworksInfoQuery, Types.GetSelectedArtworksInfoQueryVariables>(GetSelectedArtworksInfoDocument, options);
      }
export function useGetSelectedArtworksInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSelectedArtworksInfoQuery, Types.GetSelectedArtworksInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSelectedArtworksInfoQuery, Types.GetSelectedArtworksInfoQueryVariables>(GetSelectedArtworksInfoDocument, options);
        }
export type GetSelectedArtworksInfoQueryHookResult = ReturnType<typeof useGetSelectedArtworksInfoQuery>;
export type GetSelectedArtworksInfoLazyQueryHookResult = ReturnType<typeof useGetSelectedArtworksInfoLazyQuery>;
export type GetSelectedArtworksInfoQueryResult = Apollo.QueryResult<Types.GetSelectedArtworksInfoQuery, Types.GetSelectedArtworksInfoQueryVariables>;
export const GetEditionPrintsToGenerateCertificatesDocument = gql`
    query GetEditionPrintsToGenerateCertificates($editionsId: [bigint!]!) {
  Edition(where: {id: {_in: $editionsId}}) {
    title
    Edition_By_Sizes {
      ...EditionBySizeDetails
      Edition_By_Types(where: {Edition_Type: {deleted_at: {_is_null: true}}}) {
        id
        print_amount
        created_at
        Prints {
          id
          print_number
          status
          isTransactionLocked: is_transaction_locked
          acceptedTransactions: transaction_histories(
            where: {status: {_eq: ACCEPTED}}
            order_by: {created_at: desc}
            limit: 1
          ) {
            owner: buyer_context {
              profile {
                ...CollectorProfile
              }
            }
          }
        }
        Edition_Type {
          value
        }
      }
    }
  }
}
    ${EditionBySizeDetailsFragmentDoc}
${CollectorProfileFragmentDoc}`;

/**
 * __useGetEditionPrintsToGenerateCertificatesQuery__
 *
 * To run a query within a React component, call `useGetEditionPrintsToGenerateCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionPrintsToGenerateCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionPrintsToGenerateCertificatesQuery({
 *   variables: {
 *      editionsId: // value for 'editionsId'
 *   },
 * });
 */
export function useGetEditionPrintsToGenerateCertificatesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionPrintsToGenerateCertificatesQuery, Types.GetEditionPrintsToGenerateCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionPrintsToGenerateCertificatesQuery, Types.GetEditionPrintsToGenerateCertificatesQueryVariables>(GetEditionPrintsToGenerateCertificatesDocument, options);
      }
export function useGetEditionPrintsToGenerateCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionPrintsToGenerateCertificatesQuery, Types.GetEditionPrintsToGenerateCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionPrintsToGenerateCertificatesQuery, Types.GetEditionPrintsToGenerateCertificatesQueryVariables>(GetEditionPrintsToGenerateCertificatesDocument, options);
        }
export type GetEditionPrintsToGenerateCertificatesQueryHookResult = ReturnType<typeof useGetEditionPrintsToGenerateCertificatesQuery>;
export type GetEditionPrintsToGenerateCertificatesLazyQueryHookResult = ReturnType<typeof useGetEditionPrintsToGenerateCertificatesLazyQuery>;
export type GetEditionPrintsToGenerateCertificatesQueryResult = Apollo.QueryResult<Types.GetEditionPrintsToGenerateCertificatesQuery, Types.GetEditionPrintsToGenerateCertificatesQueryVariables>;
export const GetGeneratedCertificatesDocument = gql`
    query getGeneratedCertificates($contextId: bigint!) {
  Certificate_of_authenticity(
    where: {Artwork: {artist_context_id: {_eq: $contextId}, published_date: {_is_null: false}, deleted_at: {_is_null: true}}}
    order_by: {updated_at: desc}
  ) {
    id
    fingerprint
    updated_at
    Artwork {
      id
      slug
      thumbnail_metadata_id
      artwork_details {
        title
      }
    }
    Print {
      id
      edition_id
      Artwork {
        id
        thumbnail_metadata_id
        slug
        artwork_details {
          title
        }
      }
    }
  }
}
    `;

/**
 * __useGetGeneratedCertificatesQuery__
 *
 * To run a query within a React component, call `useGetGeneratedCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedCertificatesQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetGeneratedCertificatesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGeneratedCertificatesQuery, Types.GetGeneratedCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGeneratedCertificatesQuery, Types.GetGeneratedCertificatesQueryVariables>(GetGeneratedCertificatesDocument, options);
      }
export function useGetGeneratedCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGeneratedCertificatesQuery, Types.GetGeneratedCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGeneratedCertificatesQuery, Types.GetGeneratedCertificatesQueryVariables>(GetGeneratedCertificatesDocument, options);
        }
export type GetGeneratedCertificatesQueryHookResult = ReturnType<typeof useGetGeneratedCertificatesQuery>;
export type GetGeneratedCertificatesLazyQueryHookResult = ReturnType<typeof useGetGeneratedCertificatesLazyQuery>;
export type GetGeneratedCertificatesQueryResult = Apollo.QueryResult<Types.GetGeneratedCertificatesQuery, Types.GetGeneratedCertificatesQueryVariables>;
export const GetGeneratedCertificatesCountDocument = gql`
    query getGeneratedCertificatesCount($contextId: bigint!) {
  documents: aggregateProxy(
    type: DocumentsCount
    variables: {context_id: $contextId}
  )
}
    `;

/**
 * __useGetGeneratedCertificatesCountQuery__
 *
 * To run a query within a React component, call `useGetGeneratedCertificatesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedCertificatesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedCertificatesCountQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetGeneratedCertificatesCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGeneratedCertificatesCountQuery, Types.GetGeneratedCertificatesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGeneratedCertificatesCountQuery, Types.GetGeneratedCertificatesCountQueryVariables>(GetGeneratedCertificatesCountDocument, options);
      }
export function useGetGeneratedCertificatesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGeneratedCertificatesCountQuery, Types.GetGeneratedCertificatesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGeneratedCertificatesCountQuery, Types.GetGeneratedCertificatesCountQueryVariables>(GetGeneratedCertificatesCountDocument, options);
        }
export type GetGeneratedCertificatesCountQueryHookResult = ReturnType<typeof useGetGeneratedCertificatesCountQuery>;
export type GetGeneratedCertificatesCountLazyQueryHookResult = ReturnType<typeof useGetGeneratedCertificatesCountLazyQuery>;
export type GetGeneratedCertificatesCountQueryResult = Apollo.QueryResult<Types.GetGeneratedCertificatesCountQuery, Types.GetGeneratedCertificatesCountQueryVariables>;
export const GetArtistsArtworksCountDocument = gql`
    query getArtistsArtworksCount($contextId: bigint!) {
  artworks: aggregateProxy(
    type: TotalArtworksCountOfArtist
    variables: {context_id: $contextId}
  )
}
    `;

/**
 * __useGetArtistsArtworksCountQuery__
 *
 * To run a query within a React component, call `useGetArtistsArtworksCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsArtworksCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsArtworksCountQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistsArtworksCountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistsArtworksCountQuery, Types.GetArtistsArtworksCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistsArtworksCountQuery, Types.GetArtistsArtworksCountQueryVariables>(GetArtistsArtworksCountDocument, options);
      }
export function useGetArtistsArtworksCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistsArtworksCountQuery, Types.GetArtistsArtworksCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistsArtworksCountQuery, Types.GetArtistsArtworksCountQueryVariables>(GetArtistsArtworksCountDocument, options);
        }
export type GetArtistsArtworksCountQueryHookResult = ReturnType<typeof useGetArtistsArtworksCountQuery>;
export type GetArtistsArtworksCountLazyQueryHookResult = ReturnType<typeof useGetArtistsArtworksCountLazyQuery>;
export type GetArtistsArtworksCountQueryResult = Apollo.QueryResult<Types.GetArtistsArtworksCountQuery, Types.GetArtistsArtworksCountQueryVariables>;
export const GetDocumentsAllArtistUniqueArtworksDocument = gql`
    query getDocumentsAllArtistUniqueArtworks($extra_where: Artwork_bool_exp = {}, $contextId: bigint!) {
  untransferable: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE]}}], published_date: {_is_null: true}}, {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  transferable: Artwork(
    where: {_and: [$extra_where, {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: false}}, {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
    __typename
  }
  transferring: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE]}}], published_date: {_is_null: false}}, {is_transaction_locked: {_eq: true}}, {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}
  ) {
    ...manageArtworkContent
  }
  tranferred: Artwork(
    where: {_and: [$extra_where, {_or: [{edition_type: {_is_null: true}}, {edition_type: {_in: [UNIQUE]}}], artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}, {_not: {artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $contextId}}}}}]}
  ) {
    ...manageArtworkContent
  }
  editions: Edition(
    where: {Artwork: {_and: [$extra_where, {edition_type: {_in: [STANDALONE_EDITION, ARTWORK_EDITION]}, artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $contextId}}}}]}, deleted_at: {_is_null: true}}
  ) {
    ...manageArtworkEdition
  }
}
    ${ManageArtworkContentFragmentDoc}
${ManageArtworkEditionFragmentDoc}`;

/**
 * __useGetDocumentsAllArtistUniqueArtworksQuery__
 *
 * To run a query within a React component, call `useGetDocumentsAllArtistUniqueArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsAllArtistUniqueArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsAllArtistUniqueArtworksQuery({
 *   variables: {
 *      extra_where: // value for 'extra_where'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetDocumentsAllArtistUniqueArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDocumentsAllArtistUniqueArtworksQuery, Types.GetDocumentsAllArtistUniqueArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDocumentsAllArtistUniqueArtworksQuery, Types.GetDocumentsAllArtistUniqueArtworksQueryVariables>(GetDocumentsAllArtistUniqueArtworksDocument, options);
      }
export function useGetDocumentsAllArtistUniqueArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDocumentsAllArtistUniqueArtworksQuery, Types.GetDocumentsAllArtistUniqueArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDocumentsAllArtistUniqueArtworksQuery, Types.GetDocumentsAllArtistUniqueArtworksQueryVariables>(GetDocumentsAllArtistUniqueArtworksDocument, options);
        }
export type GetDocumentsAllArtistUniqueArtworksQueryHookResult = ReturnType<typeof useGetDocumentsAllArtistUniqueArtworksQuery>;
export type GetDocumentsAllArtistUniqueArtworksLazyQueryHookResult = ReturnType<typeof useGetDocumentsAllArtistUniqueArtworksLazyQuery>;
export type GetDocumentsAllArtistUniqueArtworksQueryResult = Apollo.QueryResult<Types.GetDocumentsAllArtistUniqueArtworksQuery, Types.GetDocumentsAllArtistUniqueArtworksQueryVariables>;
export const UpsertMyStudioSettingsDocument = gql`
    mutation upsertMyStudioSettings($object: My_studio_settings_insert_input!) {
  insert_My_studio_settings_one(
    object: $object
    on_conflict: {constraint: My_studio_settings_context_id_key, update_columns: [my_documents_pagination, my_provenance_pagination]}
  ) {
    my_documents_pagination
    my_provenance_pagination
  }
}
    `;
export type UpsertMyStudioSettingsMutationFn = Apollo.MutationFunction<Types.UpsertMyStudioSettingsMutation, Types.UpsertMyStudioSettingsMutationVariables>;

/**
 * __useUpsertMyStudioSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertMyStudioSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMyStudioSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMyStudioSettingsMutation, { data, loading, error }] = useUpsertMyStudioSettingsMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpsertMyStudioSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertMyStudioSettingsMutation, Types.UpsertMyStudioSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertMyStudioSettingsMutation, Types.UpsertMyStudioSettingsMutationVariables>(UpsertMyStudioSettingsDocument, options);
      }
export type UpsertMyStudioSettingsMutationHookResult = ReturnType<typeof useUpsertMyStudioSettingsMutation>;
export type UpsertMyStudioSettingsMutationResult = Apollo.MutationResult<Types.UpsertMyStudioSettingsMutation>;
export type UpsertMyStudioSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpsertMyStudioSettingsMutation, Types.UpsertMyStudioSettingsMutationVariables>;
export const GetMyProvenanceArtworksDataDocument = gql`
    query getMyProvenanceArtworksData($contextId: Int!) {
  getMyProvenanceArtworksDataQuery(contextId: $contextId) {
    artistContextId
    artworks {
      artworkId
      status
      artworkTitle
      artworkShowcaseUrl
      artworkSlug
      thumbnailMetadataId
      artworkType
      hasMorePrints
      hasMultipleCollectors
      lastTransactionDate
      publishedDate
      editionId
      collectorInfo {
        isPrivateProfile
        collectorName
        collectorEmail
        collectorAvatar
        collectorHandle
        collectorProfileId
        isKnownProfile
      }
    }
  }
}
    `;

/**
 * __useGetMyProvenanceArtworksDataQuery__
 *
 * To run a query within a React component, call `useGetMyProvenanceArtworksDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProvenanceArtworksDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProvenanceArtworksDataQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetMyProvenanceArtworksDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMyProvenanceArtworksDataQuery, Types.GetMyProvenanceArtworksDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMyProvenanceArtworksDataQuery, Types.GetMyProvenanceArtworksDataQueryVariables>(GetMyProvenanceArtworksDataDocument, options);
      }
export function useGetMyProvenanceArtworksDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMyProvenanceArtworksDataQuery, Types.GetMyProvenanceArtworksDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMyProvenanceArtworksDataQuery, Types.GetMyProvenanceArtworksDataQueryVariables>(GetMyProvenanceArtworksDataDocument, options);
        }
export type GetMyProvenanceArtworksDataQueryHookResult = ReturnType<typeof useGetMyProvenanceArtworksDataQuery>;
export type GetMyProvenanceArtworksDataLazyQueryHookResult = ReturnType<typeof useGetMyProvenanceArtworksDataLazyQuery>;
export type GetMyProvenanceArtworksDataQueryResult = Apollo.QueryResult<Types.GetMyProvenanceArtworksDataQuery, Types.GetMyProvenanceArtworksDataQueryVariables>;
export const GetMyStudioConfigurationDocument = gql`
    query getMyStudioConfiguration($contextId: bigint!) {
  My_studio_settings(where: {context_id: {_eq: $contextId}}) {
    my_documents_pagination
    my_provenance_pagination
  }
}
    `;

/**
 * __useGetMyStudioConfigurationQuery__
 *
 * To run a query within a React component, call `useGetMyStudioConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyStudioConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyStudioConfigurationQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetMyStudioConfigurationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMyStudioConfigurationQuery, Types.GetMyStudioConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMyStudioConfigurationQuery, Types.GetMyStudioConfigurationQueryVariables>(GetMyStudioConfigurationDocument, options);
      }
export function useGetMyStudioConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMyStudioConfigurationQuery, Types.GetMyStudioConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMyStudioConfigurationQuery, Types.GetMyStudioConfigurationQueryVariables>(GetMyStudioConfigurationDocument, options);
        }
export type GetMyStudioConfigurationQueryHookResult = ReturnType<typeof useGetMyStudioConfigurationQuery>;
export type GetMyStudioConfigurationLazyQueryHookResult = ReturnType<typeof useGetMyStudioConfigurationLazyQuery>;
export type GetMyStudioConfigurationQueryResult = Apollo.QueryResult<Types.GetMyStudioConfigurationQuery, Types.GetMyStudioConfigurationQueryVariables>;
export const GetMySubscribersDocument = gql`
    query getMySubscribers($contextId: bigint!) {
  Artist_newsletter(
    where: {artist_context_id: {_eq: $contextId}}
    order_by: {created_at: desc}
  ) {
    email
    first_name
    last_name
    preferred_language
    created_at
  }
}
    `;

/**
 * __useGetMySubscribersQuery__
 *
 * To run a query within a React component, call `useGetMySubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySubscribersQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetMySubscribersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMySubscribersQuery, Types.GetMySubscribersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMySubscribersQuery, Types.GetMySubscribersQueryVariables>(GetMySubscribersDocument, options);
      }
export function useGetMySubscribersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMySubscribersQuery, Types.GetMySubscribersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMySubscribersQuery, Types.GetMySubscribersQueryVariables>(GetMySubscribersDocument, options);
        }
export type GetMySubscribersQueryHookResult = ReturnType<typeof useGetMySubscribersQuery>;
export type GetMySubscribersLazyQueryHookResult = ReturnType<typeof useGetMySubscribersLazyQuery>;
export type GetMySubscribersQueryResult = Apollo.QueryResult<Types.GetMySubscribersQuery, Types.GetMySubscribersQueryVariables>;
export const InsertMyWebsiteSettingsDocument = gql`
    mutation insertMyWebsiteSettings($object: My_Website_Settings_insert_input!) {
  insert_My_Website_Settings_one(
    object: $object
    on_conflict: {constraint: My_Website_Settings_profile_id_key, update_columns: [artwork_info, columns, filters, layout, navigation, theme, ecommerce_enabled, published_settings, updated_at]}
  ) {
    id
  }
}
    `;
export type InsertMyWebsiteSettingsMutationFn = Apollo.MutationFunction<Types.InsertMyWebsiteSettingsMutation, Types.InsertMyWebsiteSettingsMutationVariables>;

/**
 * __useInsertMyWebsiteSettingsMutation__
 *
 * To run a mutation, you first call `useInsertMyWebsiteSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMyWebsiteSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMyWebsiteSettingsMutation, { data, loading, error }] = useInsertMyWebsiteSettingsMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertMyWebsiteSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertMyWebsiteSettingsMutation, Types.InsertMyWebsiteSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertMyWebsiteSettingsMutation, Types.InsertMyWebsiteSettingsMutationVariables>(InsertMyWebsiteSettingsDocument, options);
      }
export type InsertMyWebsiteSettingsMutationHookResult = ReturnType<typeof useInsertMyWebsiteSettingsMutation>;
export type InsertMyWebsiteSettingsMutationResult = Apollo.MutationResult<Types.InsertMyWebsiteSettingsMutation>;
export type InsertMyWebsiteSettingsMutationOptions = Apollo.BaseMutationOptions<Types.InsertMyWebsiteSettingsMutation, Types.InsertMyWebsiteSettingsMutationVariables>;
export const GetMyWebsiteSettingsDocument = gql`
    query getMyWebsiteSettings($profileId: bigint!) {
  My_Website_Settings(where: {profile_id: {_eq: $profileId}}) {
    id
    artwork_info
    columns
    ecommerce_enabled
    filters
    layout
    navigation
    theme
    published_settings
  }
}
    `;

/**
 * __useGetMyWebsiteSettingsQuery__
 *
 * To run a query within a React component, call `useGetMyWebsiteSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWebsiteSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWebsiteSettingsQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetMyWebsiteSettingsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMyWebsiteSettingsQuery, Types.GetMyWebsiteSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMyWebsiteSettingsQuery, Types.GetMyWebsiteSettingsQueryVariables>(GetMyWebsiteSettingsDocument, options);
      }
export function useGetMyWebsiteSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMyWebsiteSettingsQuery, Types.GetMyWebsiteSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMyWebsiteSettingsQuery, Types.GetMyWebsiteSettingsQueryVariables>(GetMyWebsiteSettingsDocument, options);
        }
export type GetMyWebsiteSettingsQueryHookResult = ReturnType<typeof useGetMyWebsiteSettingsQuery>;
export type GetMyWebsiteSettingsLazyQueryHookResult = ReturnType<typeof useGetMyWebsiteSettingsLazyQuery>;
export type GetMyWebsiteSettingsQueryResult = Apollo.QueryResult<Types.GetMyWebsiteSettingsQuery, Types.GetMyWebsiteSettingsQueryVariables>;
export const GetArtistProfileVisibleArtworksDocument = gql`
    query getArtistProfileVisibleArtworks($contextId: bigint!) {
  Artwork_artist_profile_listing(where: {artist_context_id: {_eq: $contextId}}) {
    artwork_id
  }
}
    `;

/**
 * __useGetArtistProfileVisibleArtworksQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileVisibleArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileVisibleArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileVisibleArtworksQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistProfileVisibleArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileVisibleArtworksQuery, Types.GetArtistProfileVisibleArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileVisibleArtworksQuery, Types.GetArtistProfileVisibleArtworksQueryVariables>(GetArtistProfileVisibleArtworksDocument, options);
      }
export function useGetArtistProfileVisibleArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileVisibleArtworksQuery, Types.GetArtistProfileVisibleArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileVisibleArtworksQuery, Types.GetArtistProfileVisibleArtworksQueryVariables>(GetArtistProfileVisibleArtworksDocument, options);
        }
export type GetArtistProfileVisibleArtworksQueryHookResult = ReturnType<typeof useGetArtistProfileVisibleArtworksQuery>;
export type GetArtistProfileVisibleArtworksLazyQueryHookResult = ReturnType<typeof useGetArtistProfileVisibleArtworksLazyQuery>;
export type GetArtistProfileVisibleArtworksQueryResult = Apollo.QueryResult<Types.GetArtistProfileVisibleArtworksQuery, Types.GetArtistProfileVisibleArtworksQueryVariables>;
export const GetMyWebsitePublishedSettingsDocument = gql`
    query getMyWebsitePublishedSettings($artistHandle: String!) {
  My_Website_Settings(where: {profile: {handle: {_ilike: $artistHandle}}}) {
    published_settings
  }
}
    `;

/**
 * __useGetMyWebsitePublishedSettingsQuery__
 *
 * To run a query within a React component, call `useGetMyWebsitePublishedSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWebsitePublishedSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWebsitePublishedSettingsQuery({
 *   variables: {
 *      artistHandle: // value for 'artistHandle'
 *   },
 * });
 */
export function useGetMyWebsitePublishedSettingsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMyWebsitePublishedSettingsQuery, Types.GetMyWebsitePublishedSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMyWebsitePublishedSettingsQuery, Types.GetMyWebsitePublishedSettingsQueryVariables>(GetMyWebsitePublishedSettingsDocument, options);
      }
export function useGetMyWebsitePublishedSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMyWebsitePublishedSettingsQuery, Types.GetMyWebsitePublishedSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMyWebsitePublishedSettingsQuery, Types.GetMyWebsitePublishedSettingsQueryVariables>(GetMyWebsitePublishedSettingsDocument, options);
        }
export type GetMyWebsitePublishedSettingsQueryHookResult = ReturnType<typeof useGetMyWebsitePublishedSettingsQuery>;
export type GetMyWebsitePublishedSettingsLazyQueryHookResult = ReturnType<typeof useGetMyWebsitePublishedSettingsLazyQuery>;
export type GetMyWebsitePublishedSettingsQueryResult = Apollo.QueryResult<Types.GetMyWebsitePublishedSettingsQuery, Types.GetMyWebsitePublishedSettingsQueryVariables>;
export const GetMyWebsiteArtistDataDocument = gql`
    query GetMyWebsiteArtistData($contextId: bigint!) {
  profile: Profile(where: {context: {id: {_eq: $contextId}}}) {
    first_name
    last_name
    quote
    statement
    type
    handle
    title
    context {
      profile_social_links {
        platform
        URL
        custom_link
      }
      collaboration_by_artist_id {
        gallery_context {
          profile {
            id
            banner
            kard_banner
            title
            handle
          }
        }
      }
    }
  }
  videos: Profile_video_links(where: {context_id: {_eq: $contextId}}) {
    id
    platform
    URL
  }
}
    `;

/**
 * __useGetMyWebsiteArtistDataQuery__
 *
 * To run a query within a React component, call `useGetMyWebsiteArtistDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWebsiteArtistDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWebsiteArtistDataQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetMyWebsiteArtistDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMyWebsiteArtistDataQuery, Types.GetMyWebsiteArtistDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMyWebsiteArtistDataQuery, Types.GetMyWebsiteArtistDataQueryVariables>(GetMyWebsiteArtistDataDocument, options);
      }
export function useGetMyWebsiteArtistDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMyWebsiteArtistDataQuery, Types.GetMyWebsiteArtistDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMyWebsiteArtistDataQuery, Types.GetMyWebsiteArtistDataQueryVariables>(GetMyWebsiteArtistDataDocument, options);
        }
export type GetMyWebsiteArtistDataQueryHookResult = ReturnType<typeof useGetMyWebsiteArtistDataQuery>;
export type GetMyWebsiteArtistDataLazyQueryHookResult = ReturnType<typeof useGetMyWebsiteArtistDataLazyQuery>;
export type GetMyWebsiteArtistDataQueryResult = Apollo.QueryResult<Types.GetMyWebsiteArtistDataQuery, Types.GetMyWebsiteArtistDataQueryVariables>;
export const GetNearbyArtistsListingByDateDocument = gql`
    query GetNearbyArtistsListingByDate($lat: float8!, $lng: float8!, $rangeKm: float8!, $where: Artist_explore_listing_with_city_data_bool_exp!, $limit: Int!, $offset: Int = 0, $sorts: [Artist_explore_listing_with_city_data_order_by!]!) {
  artists: get_artists_in_range(
    args: {input_lat: $lat, input_long: $lng, range_km: $rangeKm}
    limit: $limit
    offset: $offset
    order_by: $sorts
    where: $where
  ) {
    contextId: artist_context_id
    appear_at
    curation_order
  }
}
    `;

/**
 * __useGetNearbyArtistsListingByDateQuery__
 *
 * To run a query within a React component, call `useGetNearbyArtistsListingByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNearbyArtistsListingByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNearbyArtistsListingByDateQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      rangeKm: // value for 'rangeKm'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sorts: // value for 'sorts'
 *   },
 * });
 */
export function useGetNearbyArtistsListingByDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNearbyArtistsListingByDateQuery, Types.GetNearbyArtistsListingByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNearbyArtistsListingByDateQuery, Types.GetNearbyArtistsListingByDateQueryVariables>(GetNearbyArtistsListingByDateDocument, options);
      }
export function useGetNearbyArtistsListingByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNearbyArtistsListingByDateQuery, Types.GetNearbyArtistsListingByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNearbyArtistsListingByDateQuery, Types.GetNearbyArtistsListingByDateQueryVariables>(GetNearbyArtistsListingByDateDocument, options);
        }
export type GetNearbyArtistsListingByDateQueryHookResult = ReturnType<typeof useGetNearbyArtistsListingByDateQuery>;
export type GetNearbyArtistsListingByDateLazyQueryHookResult = ReturnType<typeof useGetNearbyArtistsListingByDateLazyQuery>;
export type GetNearbyArtistsListingByDateQueryResult = Apollo.QueryResult<Types.GetNearbyArtistsListingByDateQuery, Types.GetNearbyArtistsListingByDateQueryVariables>;
export const GetNearbyArtistsListingByTitleDocument = gql`
    query GetNearbyArtistsListingByTitle($lat: float8!, $lng: float8!, $rangeKm: float8!, $where: Artist_explore_listing_with_city_data_bool_exp!, $limit: Int!, $offset: Int = 0, $sorts: [Artist_explore_listing_with_city_data_order_by!]!) {
  artists: get_artists_in_range(
    args: {input_lat: $lat, input_long: $lng, range_km: $rangeKm}
    limit: $limit
    offset: $offset
    order_by: $sorts
    where: $where
  ) {
    contextId: artist_context_id
    title
    curation_order
  }
}
    `;

/**
 * __useGetNearbyArtistsListingByTitleQuery__
 *
 * To run a query within a React component, call `useGetNearbyArtistsListingByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNearbyArtistsListingByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNearbyArtistsListingByTitleQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      rangeKm: // value for 'rangeKm'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sorts: // value for 'sorts'
 *   },
 * });
 */
export function useGetNearbyArtistsListingByTitleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNearbyArtistsListingByTitleQuery, Types.GetNearbyArtistsListingByTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNearbyArtistsListingByTitleQuery, Types.GetNearbyArtistsListingByTitleQueryVariables>(GetNearbyArtistsListingByTitleDocument, options);
      }
export function useGetNearbyArtistsListingByTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNearbyArtistsListingByTitleQuery, Types.GetNearbyArtistsListingByTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNearbyArtistsListingByTitleQuery, Types.GetNearbyArtistsListingByTitleQueryVariables>(GetNearbyArtistsListingByTitleDocument, options);
        }
export type GetNearbyArtistsListingByTitleQueryHookResult = ReturnType<typeof useGetNearbyArtistsListingByTitleQuery>;
export type GetNearbyArtistsListingByTitleLazyQueryHookResult = ReturnType<typeof useGetNearbyArtistsListingByTitleLazyQuery>;
export type GetNearbyArtistsListingByTitleQueryResult = Apollo.QueryResult<Types.GetNearbyArtistsListingByTitleQuery, Types.GetNearbyArtistsListingByTitleQueryVariables>;
export const GetExploreGalleryInfoDocument = gql`
    query GetExploreGalleryInfo($galleries: [bigint!]!) {
  galleries: Gallery_explore_info(where: {gallery_context_id: {_in: $galleries}}) {
    contextId: gallery_context_id
    profileId: gallery_profile_id
    title
    handle
    avatar
    country
    city
    kard_banner
    address
  }
}
    `;

/**
 * __useGetExploreGalleryInfoQuery__
 *
 * To run a query within a React component, call `useGetExploreGalleryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExploreGalleryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExploreGalleryInfoQuery({
 *   variables: {
 *      galleries: // value for 'galleries'
 *   },
 * });
 */
export function useGetExploreGalleryInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetExploreGalleryInfoQuery, Types.GetExploreGalleryInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetExploreGalleryInfoQuery, Types.GetExploreGalleryInfoQueryVariables>(GetExploreGalleryInfoDocument, options);
      }
export function useGetExploreGalleryInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetExploreGalleryInfoQuery, Types.GetExploreGalleryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetExploreGalleryInfoQuery, Types.GetExploreGalleryInfoQueryVariables>(GetExploreGalleryInfoDocument, options);
        }
export type GetExploreGalleryInfoQueryHookResult = ReturnType<typeof useGetExploreGalleryInfoQuery>;
export type GetExploreGalleryInfoLazyQueryHookResult = ReturnType<typeof useGetExploreGalleryInfoLazyQuery>;
export type GetExploreGalleryInfoQueryResult = Apollo.QueryResult<Types.GetExploreGalleryInfoQuery, Types.GetExploreGalleryInfoQueryVariables>;
export const GetNearbyGalleriesListingByTitleDocument = gql`
    query GetNearbyGalleriesListingByTitle($lat: float8!, $lng: float8!, $rangeKm: float8!, $where: Gallery_explore_info_with_city_data_bool_exp!, $limit: Int!, $offset: Int = 0) {
  galleries: get_galleries_in_range(
    args: {input_lat: $lat, input_long: $lng, range_km: $rangeKm}
    limit: $limit
    offset: $offset
    order_by: [{title: asc}, {id: desc}]
    where: $where
  ) {
    contextId: id
    title
  }
}
    `;

/**
 * __useGetNearbyGalleriesListingByTitleQuery__
 *
 * To run a query within a React component, call `useGetNearbyGalleriesListingByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNearbyGalleriesListingByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNearbyGalleriesListingByTitleQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      rangeKm: // value for 'rangeKm'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNearbyGalleriesListingByTitleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNearbyGalleriesListingByTitleQuery, Types.GetNearbyGalleriesListingByTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNearbyGalleriesListingByTitleQuery, Types.GetNearbyGalleriesListingByTitleQueryVariables>(GetNearbyGalleriesListingByTitleDocument, options);
      }
export function useGetNearbyGalleriesListingByTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNearbyGalleriesListingByTitleQuery, Types.GetNearbyGalleriesListingByTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNearbyGalleriesListingByTitleQuery, Types.GetNearbyGalleriesListingByTitleQueryVariables>(GetNearbyGalleriesListingByTitleDocument, options);
        }
export type GetNearbyGalleriesListingByTitleQueryHookResult = ReturnType<typeof useGetNearbyGalleriesListingByTitleQuery>;
export type GetNearbyGalleriesListingByTitleLazyQueryHookResult = ReturnType<typeof useGetNearbyGalleriesListingByTitleLazyQuery>;
export type GetNearbyGalleriesListingByTitleQueryResult = Apollo.QueryResult<Types.GetNearbyGalleriesListingByTitleQuery, Types.GetNearbyGalleriesListingByTitleQueryVariables>;
export const GetDuplicateCitiesDocument = gql`
    query getDuplicateCities($city: String!, $iso2: String!) {
  worldcities(where: {city: {_eq: $city}, iso2: {_eq: $iso2}}) {
    city
    iso2
    admin_code
  }
}
    `;

/**
 * __useGetDuplicateCitiesQuery__
 *
 * To run a query within a React component, call `useGetDuplicateCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuplicateCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuplicateCitiesQuery({
 *   variables: {
 *      city: // value for 'city'
 *      iso2: // value for 'iso2'
 *   },
 * });
 */
export function useGetDuplicateCitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDuplicateCitiesQuery, Types.GetDuplicateCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDuplicateCitiesQuery, Types.GetDuplicateCitiesQueryVariables>(GetDuplicateCitiesDocument, options);
      }
export function useGetDuplicateCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDuplicateCitiesQuery, Types.GetDuplicateCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDuplicateCitiesQuery, Types.GetDuplicateCitiesQueryVariables>(GetDuplicateCitiesDocument, options);
        }
export type GetDuplicateCitiesQueryHookResult = ReturnType<typeof useGetDuplicateCitiesQuery>;
export type GetDuplicateCitiesLazyQueryHookResult = ReturnType<typeof useGetDuplicateCitiesLazyQuery>;
export type GetDuplicateCitiesQueryResult = Apollo.QueryResult<Types.GetDuplicateCitiesQuery, Types.GetDuplicateCitiesQueryVariables>;
export const GetInterestsDocument = gql`
    query getInterests($interests: [String!]!) {
  ENUM_interest_type(where: {VALUE: {_in: $interests}}) {
    description
    Interests(order_by: {order: asc}) {
      id
      image_url
      translation_key
    }
  }
}
    `;

/**
 * __useGetInterestsQuery__
 *
 * To run a query within a React component, call `useGetInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterestsQuery({
 *   variables: {
 *      interests: // value for 'interests'
 *   },
 * });
 */
export function useGetInterestsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInterestsQuery, Types.GetInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInterestsQuery, Types.GetInterestsQueryVariables>(GetInterestsDocument, options);
      }
export function useGetInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInterestsQuery, Types.GetInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInterestsQuery, Types.GetInterestsQueryVariables>(GetInterestsDocument, options);
        }
export type GetInterestsQueryHookResult = ReturnType<typeof useGetInterestsQuery>;
export type GetInterestsLazyQueryHookResult = ReturnType<typeof useGetInterestsLazyQuery>;
export type GetInterestsQueryResult = Apollo.QueryResult<Types.GetInterestsQuery, Types.GetInterestsQueryVariables>;
export const SetCollectorInterestsDocument = gql`
    mutation setCollectorInterests($collectorContextId: bigint!, $collectorInterests: [Profile_Interests_insert_input!]!) {
  delete_Profile_Interests(where: {context_id: {_eq: $collectorContextId}}) {
    affected_rows
  }
  insert_Profile_Interests(objects: $collectorInterests) {
    affected_rows
  }
}
    `;
export type SetCollectorInterestsMutationFn = Apollo.MutationFunction<Types.SetCollectorInterestsMutation, Types.SetCollectorInterestsMutationVariables>;

/**
 * __useSetCollectorInterestsMutation__
 *
 * To run a mutation, you first call `useSetCollectorInterestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCollectorInterestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCollectorInterestsMutation, { data, loading, error }] = useSetCollectorInterestsMutation({
 *   variables: {
 *      collectorContextId: // value for 'collectorContextId'
 *      collectorInterests: // value for 'collectorInterests'
 *   },
 * });
 */
export function useSetCollectorInterestsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetCollectorInterestsMutation, Types.SetCollectorInterestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetCollectorInterestsMutation, Types.SetCollectorInterestsMutationVariables>(SetCollectorInterestsDocument, options);
      }
export type SetCollectorInterestsMutationHookResult = ReturnType<typeof useSetCollectorInterestsMutation>;
export type SetCollectorInterestsMutationResult = Apollo.MutationResult<Types.SetCollectorInterestsMutation>;
export type SetCollectorInterestsMutationOptions = Apollo.BaseMutationOptions<Types.SetCollectorInterestsMutation, Types.SetCollectorInterestsMutationVariables>;
export const SetArtistInterestsDocument = gql`
    mutation setArtistInterests($artistContextId: bigint!, $artistInterests: [Profile_Interests_insert_input!]!) {
  delete_Profile_Interests(where: {context_id: {_eq: $artistContextId}}) {
    affected_rows
  }
  insert_Profile_Interests(objects: $artistInterests) {
    affected_rows
  }
}
    `;
export type SetArtistInterestsMutationFn = Apollo.MutationFunction<Types.SetArtistInterestsMutation, Types.SetArtistInterestsMutationVariables>;

/**
 * __useSetArtistInterestsMutation__
 *
 * To run a mutation, you first call `useSetArtistInterestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetArtistInterestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setArtistInterestsMutation, { data, loading, error }] = useSetArtistInterestsMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      artistInterests: // value for 'artistInterests'
 *   },
 * });
 */
export function useSetArtistInterestsMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetArtistInterestsMutation, Types.SetArtistInterestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetArtistInterestsMutation, Types.SetArtistInterestsMutationVariables>(SetArtistInterestsDocument, options);
      }
export type SetArtistInterestsMutationHookResult = ReturnType<typeof useSetArtistInterestsMutation>;
export type SetArtistInterestsMutationResult = Apollo.MutationResult<Types.SetArtistInterestsMutation>;
export type SetArtistInterestsMutationOptions = Apollo.BaseMutationOptions<Types.SetArtistInterestsMutation, Types.SetArtistInterestsMutationVariables>;
export const UpdateCollectorProfileOnboardingInfoDocument = gql`
    mutation updateCollectorProfileOnboardingInfo($profileId: bigint!, $is_public: ENUM_profile_public_status_enum!, $handle: String = "") {
  update_Profile_by_pk(
    pk_columns: {id: $profileId}
    _set: {is_public: $is_public, handle: $handle}
  ) {
    id
  }
}
    `;
export type UpdateCollectorProfileOnboardingInfoMutationFn = Apollo.MutationFunction<Types.UpdateCollectorProfileOnboardingInfoMutation, Types.UpdateCollectorProfileOnboardingInfoMutationVariables>;

/**
 * __useUpdateCollectorProfileOnboardingInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCollectorProfileOnboardingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectorProfileOnboardingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectorProfileOnboardingInfoMutation, { data, loading, error }] = useUpdateCollectorProfileOnboardingInfoMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      is_public: // value for 'is_public'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useUpdateCollectorProfileOnboardingInfoMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateCollectorProfileOnboardingInfoMutation, Types.UpdateCollectorProfileOnboardingInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateCollectorProfileOnboardingInfoMutation, Types.UpdateCollectorProfileOnboardingInfoMutationVariables>(UpdateCollectorProfileOnboardingInfoDocument, options);
      }
export type UpdateCollectorProfileOnboardingInfoMutationHookResult = ReturnType<typeof useUpdateCollectorProfileOnboardingInfoMutation>;
export type UpdateCollectorProfileOnboardingInfoMutationResult = Apollo.MutationResult<Types.UpdateCollectorProfileOnboardingInfoMutation>;
export type UpdateCollectorProfileOnboardingInfoMutationOptions = Apollo.BaseMutationOptions<Types.UpdateCollectorProfileOnboardingInfoMutation, Types.UpdateCollectorProfileOnboardingInfoMutationVariables>;
export const GetProfileSelectedInterestsDocument = gql`
    query getProfileSelectedInterests($contextId: bigint!) {
  Profile_Interests(where: {context_id: {_eq: $contextId}}) {
    interest_id
  }
}
    `;

/**
 * __useGetProfileSelectedInterestsQuery__
 *
 * To run a query within a React component, call `useGetProfileSelectedInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileSelectedInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileSelectedInterestsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileSelectedInterestsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileSelectedInterestsQuery, Types.GetProfileSelectedInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileSelectedInterestsQuery, Types.GetProfileSelectedInterestsQueryVariables>(GetProfileSelectedInterestsDocument, options);
      }
export function useGetProfileSelectedInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileSelectedInterestsQuery, Types.GetProfileSelectedInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileSelectedInterestsQuery, Types.GetProfileSelectedInterestsQueryVariables>(GetProfileSelectedInterestsDocument, options);
        }
export type GetProfileSelectedInterestsQueryHookResult = ReturnType<typeof useGetProfileSelectedInterestsQuery>;
export type GetProfileSelectedInterestsLazyQueryHookResult = ReturnType<typeof useGetProfileSelectedInterestsLazyQuery>;
export type GetProfileSelectedInterestsQueryResult = Apollo.QueryResult<Types.GetProfileSelectedInterestsQuery, Types.GetProfileSelectedInterestsQueryVariables>;
export const GetProfileLocationRangesDocument = gql`
    query GetProfileLocationRanges($contextId: bigint) {
  Profile_price_ranges(where: {context_id: {_eq: $contextId}}) {
    id
  }
  Profile_location(where: {context_id: {_eq: $contextId}}) {
    id
  }
}
    `;

/**
 * __useGetProfileLocationRangesQuery__
 *
 * To run a query within a React component, call `useGetProfileLocationRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileLocationRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileLocationRangesQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileLocationRangesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetProfileLocationRangesQuery, Types.GetProfileLocationRangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileLocationRangesQuery, Types.GetProfileLocationRangesQueryVariables>(GetProfileLocationRangesDocument, options);
      }
export function useGetProfileLocationRangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileLocationRangesQuery, Types.GetProfileLocationRangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileLocationRangesQuery, Types.GetProfileLocationRangesQueryVariables>(GetProfileLocationRangesDocument, options);
        }
export type GetProfileLocationRangesQueryHookResult = ReturnType<typeof useGetProfileLocationRangesQuery>;
export type GetProfileLocationRangesLazyQueryHookResult = ReturnType<typeof useGetProfileLocationRangesLazyQuery>;
export type GetProfileLocationRangesQueryResult = Apollo.QueryResult<Types.GetProfileLocationRangesQuery, Types.GetProfileLocationRangesQueryVariables>;
export const GetCollectorAcceptedArtworkTransactionsDocument = gql`
    query getCollectorAcceptedArtworkTransactions($email: String!) {
  Artwork_transactions(
    where: {buyer_email: {_eq: $email}, status: {_eq: ACCEPTED}}
    limit: 1
  ) {
    id
    artwork {
      id
      slug
      primary_image_metadata_id
      artwork_details {
        title
      }
      artist_context {
        profile {
          title
          handle
        }
      }
    }
  }
}
    `;

/**
 * __useGetCollectorAcceptedArtworkTransactionsQuery__
 *
 * To run a query within a React component, call `useGetCollectorAcceptedArtworkTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorAcceptedArtworkTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorAcceptedArtworkTransactionsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCollectorAcceptedArtworkTransactionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorAcceptedArtworkTransactionsQuery, Types.GetCollectorAcceptedArtworkTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorAcceptedArtworkTransactionsQuery, Types.GetCollectorAcceptedArtworkTransactionsQueryVariables>(GetCollectorAcceptedArtworkTransactionsDocument, options);
      }
export function useGetCollectorAcceptedArtworkTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorAcceptedArtworkTransactionsQuery, Types.GetCollectorAcceptedArtworkTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorAcceptedArtworkTransactionsQuery, Types.GetCollectorAcceptedArtworkTransactionsQueryVariables>(GetCollectorAcceptedArtworkTransactionsDocument, options);
        }
export type GetCollectorAcceptedArtworkTransactionsQueryHookResult = ReturnType<typeof useGetCollectorAcceptedArtworkTransactionsQuery>;
export type GetCollectorAcceptedArtworkTransactionsLazyQueryHookResult = ReturnType<typeof useGetCollectorAcceptedArtworkTransactionsLazyQuery>;
export type GetCollectorAcceptedArtworkTransactionsQueryResult = Apollo.QueryResult<Types.GetCollectorAcceptedArtworkTransactionsQuery, Types.GetCollectorAcceptedArtworkTransactionsQueryVariables>;
export const GetCollectorPendingArtworksDocument = gql`
    query GetCollectorPendingArtworks($email: String!) {
  Artwork_transactions(
    where: {buyer_email: {_eq: $email}, status: {_eq: ACCEPTED}}
    limit: 1
  ) {
    id
    artwork {
      slug
      primary_image_metadata_id
      thumbnail_metadata_id
      artwork_details {
        title
      }
      artist_context {
        profile {
          id
          title
          handle
          avatar
        }
      }
    }
  }
}
    `;

/**
 * __useGetCollectorPendingArtworksQuery__
 *
 * To run a query within a React component, call `useGetCollectorPendingArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorPendingArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorPendingArtworksQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCollectorPendingArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorPendingArtworksQuery, Types.GetCollectorPendingArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorPendingArtworksQuery, Types.GetCollectorPendingArtworksQueryVariables>(GetCollectorPendingArtworksDocument, options);
      }
export function useGetCollectorPendingArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorPendingArtworksQuery, Types.GetCollectorPendingArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorPendingArtworksQuery, Types.GetCollectorPendingArtworksQueryVariables>(GetCollectorPendingArtworksDocument, options);
        }
export type GetCollectorPendingArtworksQueryHookResult = ReturnType<typeof useGetCollectorPendingArtworksQuery>;
export type GetCollectorPendingArtworksLazyQueryHookResult = ReturnType<typeof useGetCollectorPendingArtworksLazyQuery>;
export type GetCollectorPendingArtworksQueryResult = Apollo.QueryResult<Types.GetCollectorPendingArtworksQuery, Types.GetCollectorPendingArtworksQueryVariables>;
export const GetProfileRegistrationDataDocument = gql`
    query GetProfileRegistrationData($id: bigint! = "") {
  Context_by_pk(id: $id) {
    profile {
      id
      handle
      avatar
      banner
      kard_banner
      cv_metadata {
        file_name
      }
      title
      quote
      statement
      type
    }
    locations(limit: 1) {
      address
      city
      country
      region
    }
    profile_social_links {
      URL
      platform
    }
  }
}
    `;

/**
 * __useGetProfileRegistrationDataQuery__
 *
 * To run a query within a React component, call `useGetProfileRegistrationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileRegistrationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileRegistrationDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileRegistrationDataQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetProfileRegistrationDataQuery, Types.GetProfileRegistrationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileRegistrationDataQuery, Types.GetProfileRegistrationDataQueryVariables>(GetProfileRegistrationDataDocument, options);
      }
export function useGetProfileRegistrationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileRegistrationDataQuery, Types.GetProfileRegistrationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileRegistrationDataQuery, Types.GetProfileRegistrationDataQueryVariables>(GetProfileRegistrationDataDocument, options);
        }
export type GetProfileRegistrationDataQueryHookResult = ReturnType<typeof useGetProfileRegistrationDataQuery>;
export type GetProfileRegistrationDataLazyQueryHookResult = ReturnType<typeof useGetProfileRegistrationDataLazyQuery>;
export type GetProfileRegistrationDataQueryResult = Apollo.QueryResult<Types.GetProfileRegistrationDataQuery, Types.GetProfileRegistrationDataQueryVariables>;
export const UpdateNameAndHandleDocument = gql`
    mutation UpdateNameAndHandle($handle: String = "", $title: String = "", $contextId: bigint!) {
  update_Profile(
    where: {context: {id: {_eq: $contextId}}}
    _set: {handle: $handle, title: $title}
  ) {
    affected_rows
  }
}
    `;
export type UpdateNameAndHandleMutationFn = Apollo.MutationFunction<Types.UpdateNameAndHandleMutation, Types.UpdateNameAndHandleMutationVariables>;

/**
 * __useUpdateNameAndHandleMutation__
 *
 * To run a mutation, you first call `useUpdateNameAndHandleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNameAndHandleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNameAndHandleMutation, { data, loading, error }] = useUpdateNameAndHandleMutation({
 *   variables: {
 *      handle: // value for 'handle'
 *      title: // value for 'title'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useUpdateNameAndHandleMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateNameAndHandleMutation, Types.UpdateNameAndHandleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateNameAndHandleMutation, Types.UpdateNameAndHandleMutationVariables>(UpdateNameAndHandleDocument, options);
      }
export type UpdateNameAndHandleMutationHookResult = ReturnType<typeof useUpdateNameAndHandleMutation>;
export type UpdateNameAndHandleMutationResult = Apollo.MutationResult<Types.UpdateNameAndHandleMutation>;
export type UpdateNameAndHandleMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNameAndHandleMutation, Types.UpdateNameAndHandleMutationVariables>;
export const UpdateArtistProfileDocument = gql`
    mutation UpdateArtistProfile($title: String!, $handle: String!) {
  updateArtistProfile(title: $title, handle: $handle)
}
    `;
export type UpdateArtistProfileMutationFn = Apollo.MutationFunction<Types.UpdateArtistProfileMutation, Types.UpdateArtistProfileMutationVariables>;

/**
 * __useUpdateArtistProfileMutation__
 *
 * To run a mutation, you first call `useUpdateArtistProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtistProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtistProfileMutation, { data, loading, error }] = useUpdateArtistProfileMutation({
 *   variables: {
 *      title: // value for 'title'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useUpdateArtistProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateArtistProfileMutation, Types.UpdateArtistProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateArtistProfileMutation, Types.UpdateArtistProfileMutationVariables>(UpdateArtistProfileDocument, options);
      }
export type UpdateArtistProfileMutationHookResult = ReturnType<typeof useUpdateArtistProfileMutation>;
export type UpdateArtistProfileMutationResult = Apollo.MutationResult<Types.UpdateArtistProfileMutation>;
export type UpdateArtistProfileMutationOptions = Apollo.BaseMutationOptions<Types.UpdateArtistProfileMutation, Types.UpdateArtistProfileMutationVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($object: Profile_location_insert_input!) {
  insert_Profile_location_one(object: $object) {
    id
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<Types.CreateLocationMutation, Types.CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateLocationMutation, Types.CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateLocationMutation, Types.CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<Types.CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<Types.CreateLocationMutation, Types.CreateLocationMutationVariables>;
export const CreateLocationAndDeleteOldOneDocument = gql`
    mutation CreateLocationAndDeleteOldOne($object: Profile_location_insert_input!, $contextId: bigint!) {
  delete_Profile_location(where: {context_id: {_eq: $contextId}}) {
    affected_rows
  }
  insert_Profile_location_one(object: $object) {
    id
  }
}
    `;
export type CreateLocationAndDeleteOldOneMutationFn = Apollo.MutationFunction<Types.CreateLocationAndDeleteOldOneMutation, Types.CreateLocationAndDeleteOldOneMutationVariables>;

/**
 * __useCreateLocationAndDeleteOldOneMutation__
 *
 * To run a mutation, you first call `useCreateLocationAndDeleteOldOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationAndDeleteOldOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationAndDeleteOldOneMutation, { data, loading, error }] = useCreateLocationAndDeleteOldOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useCreateLocationAndDeleteOldOneMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateLocationAndDeleteOldOneMutation, Types.CreateLocationAndDeleteOldOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateLocationAndDeleteOldOneMutation, Types.CreateLocationAndDeleteOldOneMutationVariables>(CreateLocationAndDeleteOldOneDocument, options);
      }
export type CreateLocationAndDeleteOldOneMutationHookResult = ReturnType<typeof useCreateLocationAndDeleteOldOneMutation>;
export type CreateLocationAndDeleteOldOneMutationResult = Apollo.MutationResult<Types.CreateLocationAndDeleteOldOneMutation>;
export type CreateLocationAndDeleteOldOneMutationOptions = Apollo.BaseMutationOptions<Types.CreateLocationAndDeleteOldOneMutation, Types.CreateLocationAndDeleteOldOneMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($address: String = "", $city: String = "", $country: String = "", $contextId: bigint!, $region: ENUM_profile_region_enum = null) {
  update_Profile_location(
    _set: {address: $address, city: $city, country: $country, region: $region}
    where: {context_id: {_eq: $contextId}}
  ) {
    affected_rows
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<Types.UpdateLocationMutation, Types.UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      contextId: // value for 'contextId'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateLocationMutation, Types.UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateLocationMutation, Types.UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<Types.UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateLocationMutation, Types.UpdateLocationMutationVariables>;
export const CreateArtistProfileDocument = gql`
    mutation CreateArtistProfile($handle: String!, $title: String!, $token: String, $firstName: String, $lastName: String) {
  newArtistProfile(
    title: $title
    handle: $handle
    token: $token
    firstName: $firstName
    lastName: $lastName
  ) {
    profile_id
    profile_settings_id
    context_id
  }
}
    `;
export type CreateArtistProfileMutationFn = Apollo.MutationFunction<Types.CreateArtistProfileMutation, Types.CreateArtistProfileMutationVariables>;

/**
 * __useCreateArtistProfileMutation__
 *
 * To run a mutation, you first call `useCreateArtistProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtistProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtistProfileMutation, { data, loading, error }] = useCreateArtistProfileMutation({
 *   variables: {
 *      handle: // value for 'handle'
 *      title: // value for 'title'
 *      token: // value for 'token'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useCreateArtistProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateArtistProfileMutation, Types.CreateArtistProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateArtistProfileMutation, Types.CreateArtistProfileMutationVariables>(CreateArtistProfileDocument, options);
      }
export type CreateArtistProfileMutationHookResult = ReturnType<typeof useCreateArtistProfileMutation>;
export type CreateArtistProfileMutationResult = Apollo.MutationResult<Types.CreateArtistProfileMutation>;
export type CreateArtistProfileMutationOptions = Apollo.BaseMutationOptions<Types.CreateArtistProfileMutation, Types.CreateArtistProfileMutationVariables>;
export const CreateGalleryProfileDocument = gql`
    mutation CreateGalleryProfile($handle: String!, $title: String!, $token: String) {
  newGalleryProfile(title: $title, handle: $handle, token: $token) {
    profile_id
    profile_settings_id
    context_id
  }
}
    `;
export type CreateGalleryProfileMutationFn = Apollo.MutationFunction<Types.CreateGalleryProfileMutation, Types.CreateGalleryProfileMutationVariables>;

/**
 * __useCreateGalleryProfileMutation__
 *
 * To run a mutation, you first call `useCreateGalleryProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGalleryProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGalleryProfileMutation, { data, loading, error }] = useCreateGalleryProfileMutation({
 *   variables: {
 *      handle: // value for 'handle'
 *      title: // value for 'title'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCreateGalleryProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateGalleryProfileMutation, Types.CreateGalleryProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateGalleryProfileMutation, Types.CreateGalleryProfileMutationVariables>(CreateGalleryProfileDocument, options);
      }
export type CreateGalleryProfileMutationHookResult = ReturnType<typeof useCreateGalleryProfileMutation>;
export type CreateGalleryProfileMutationResult = Apollo.MutationResult<Types.CreateGalleryProfileMutation>;
export type CreateGalleryProfileMutationOptions = Apollo.BaseMutationOptions<Types.CreateGalleryProfileMutation, Types.CreateGalleryProfileMutationVariables>;
export const UpdateStatementAndQuoteDocument = gql`
    mutation UpdateStatementAndQuote($contextId: bigint!, $quote: String = "", $statement: String = "") {
  update_Profile(
    where: {context: {id: {_eq: $contextId}}}
    _set: {quote: $quote, statement: $statement}
  ) {
    affected_rows
  }
}
    `;
export type UpdateStatementAndQuoteMutationFn = Apollo.MutationFunction<Types.UpdateStatementAndQuoteMutation, Types.UpdateStatementAndQuoteMutationVariables>;

/**
 * __useUpdateStatementAndQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateStatementAndQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatementAndQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatementAndQuoteMutation, { data, loading, error }] = useUpdateStatementAndQuoteMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      quote: // value for 'quote'
 *      statement: // value for 'statement'
 *   },
 * });
 */
export function useUpdateStatementAndQuoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStatementAndQuoteMutation, Types.UpdateStatementAndQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStatementAndQuoteMutation, Types.UpdateStatementAndQuoteMutationVariables>(UpdateStatementAndQuoteDocument, options);
      }
export type UpdateStatementAndQuoteMutationHookResult = ReturnType<typeof useUpdateStatementAndQuoteMutation>;
export type UpdateStatementAndQuoteMutationResult = Apollo.MutationResult<Types.UpdateStatementAndQuoteMutation>;
export type UpdateStatementAndQuoteMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStatementAndQuoteMutation, Types.UpdateStatementAndQuoteMutationVariables>;
export const MarkProfileStatusDocument = gql`
    mutation MarkProfileStatus($profile_id: Int!, $status: String!) {
  markProfileStatus(profile_id: $profile_id, status: $status)
}
    `;
export type MarkProfileStatusMutationFn = Apollo.MutationFunction<Types.MarkProfileStatusMutation, Types.MarkProfileStatusMutationVariables>;

/**
 * __useMarkProfileStatusMutation__
 *
 * To run a mutation, you first call `useMarkProfileStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkProfileStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markProfileStatusMutation, { data, loading, error }] = useMarkProfileStatusMutation({
 *   variables: {
 *      profile_id: // value for 'profile_id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMarkProfileStatusMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkProfileStatusMutation, Types.MarkProfileStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkProfileStatusMutation, Types.MarkProfileStatusMutationVariables>(MarkProfileStatusDocument, options);
      }
export type MarkProfileStatusMutationHookResult = ReturnType<typeof useMarkProfileStatusMutation>;
export type MarkProfileStatusMutationResult = Apollo.MutationResult<Types.MarkProfileStatusMutation>;
export type MarkProfileStatusMutationOptions = Apollo.BaseMutationOptions<Types.MarkProfileStatusMutation, Types.MarkProfileStatusMutationVariables>;
export const UpsertSocialLinksDocument = gql`
    mutation UpsertSocialLinks($platforms: [String!]!, $objects: [Profile_social_links_insert_input!]!) {
  update_Profile_social_links(
    where: {platform: {_in: $platforms}}
    _set: {status: DELETED}
  ) {
    affected_rows
  }
  insert_Profile_social_links(objects: $objects) {
    affected_rows
    __typename
  }
}
    `;
export type UpsertSocialLinksMutationFn = Apollo.MutationFunction<Types.UpsertSocialLinksMutation, Types.UpsertSocialLinksMutationVariables>;

/**
 * __useUpsertSocialLinksMutation__
 *
 * To run a mutation, you first call `useUpsertSocialLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSocialLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSocialLinksMutation, { data, loading, error }] = useUpsertSocialLinksMutation({
 *   variables: {
 *      platforms: // value for 'platforms'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertSocialLinksMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertSocialLinksMutation, Types.UpsertSocialLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertSocialLinksMutation, Types.UpsertSocialLinksMutationVariables>(UpsertSocialLinksDocument, options);
      }
export type UpsertSocialLinksMutationHookResult = ReturnType<typeof useUpsertSocialLinksMutation>;
export type UpsertSocialLinksMutationResult = Apollo.MutationResult<Types.UpsertSocialLinksMutation>;
export type UpsertSocialLinksMutationOptions = Apollo.BaseMutationOptions<Types.UpsertSocialLinksMutation, Types.UpsertSocialLinksMutationVariables>;
export const GetProfileBycontextIdDocument = gql`
    query GetProfileBycontextId($id: bigint!) {
  Context_by_pk(id: $id) {
    type
    id
    state
    profile {
      id
      first_name
      last_name
      is_public
      quote
      statement
      type
      title
      handle
      avatar
      kard_banner
      banner
      email
    }
    profile_social_links {
      URL
      id
      platform
      custom_link
    }
    locations {
      name
      country
      city
      address
      zip_code
      id
    }
    profile_settings {
      id
      preferred_language
      currency
      crop_image
    }
  }
}
    `;

/**
 * __useGetProfileBycontextIdQuery__
 *
 * To run a query within a React component, call `useGetProfileBycontextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileBycontextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileBycontextIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileBycontextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileBycontextIdQuery, Types.GetProfileBycontextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileBycontextIdQuery, Types.GetProfileBycontextIdQueryVariables>(GetProfileBycontextIdDocument, options);
      }
export function useGetProfileBycontextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileBycontextIdQuery, Types.GetProfileBycontextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileBycontextIdQuery, Types.GetProfileBycontextIdQueryVariables>(GetProfileBycontextIdDocument, options);
        }
export type GetProfileBycontextIdQueryHookResult = ReturnType<typeof useGetProfileBycontextIdQuery>;
export type GetProfileBycontextIdLazyQueryHookResult = ReturnType<typeof useGetProfileBycontextIdLazyQuery>;
export type GetProfileBycontextIdQueryResult = Apollo.QueryResult<Types.GetProfileBycontextIdQuery, Types.GetProfileBycontextIdQueryVariables>;
export const GetArtistProfileLocationAndTitleDocument = gql`
    query getArtistProfileLocationAndTitle($contextId: bigint!, $profileId: bigint!) {
  location: Profile_location(where: {context_id: {_eq: $contextId}}) {
    country
    city
    city_id
    address
  }
  profile: Profile_by_pk(id: $profileId) {
    title
    handle
    avatar
  }
}
    `;

/**
 * __useGetArtistProfileLocationAndTitleQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileLocationAndTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileLocationAndTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileLocationAndTitleQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetArtistProfileLocationAndTitleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileLocationAndTitleQuery, Types.GetArtistProfileLocationAndTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileLocationAndTitleQuery, Types.GetArtistProfileLocationAndTitleQueryVariables>(GetArtistProfileLocationAndTitleDocument, options);
      }
export function useGetArtistProfileLocationAndTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileLocationAndTitleQuery, Types.GetArtistProfileLocationAndTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileLocationAndTitleQuery, Types.GetArtistProfileLocationAndTitleQueryVariables>(GetArtistProfileLocationAndTitleDocument, options);
        }
export type GetArtistProfileLocationAndTitleQueryHookResult = ReturnType<typeof useGetArtistProfileLocationAndTitleQuery>;
export type GetArtistProfileLocationAndTitleLazyQueryHookResult = ReturnType<typeof useGetArtistProfileLocationAndTitleLazyQuery>;
export type GetArtistProfileLocationAndTitleQueryResult = Apollo.QueryResult<Types.GetArtistProfileLocationAndTitleQuery, Types.GetArtistProfileLocationAndTitleQueryVariables>;
export const GetArtistProfileStatementAndQuoteDocument = gql`
    query getArtistProfileStatementAndQuote($profileId: bigint!) {
  profile: Profile_by_pk(id: $profileId) {
    statement
    quote
  }
}
    `;

/**
 * __useGetArtistProfileStatementAndQuoteQuery__
 *
 * To run a query within a React component, call `useGetArtistProfileStatementAndQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProfileStatementAndQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProfileStatementAndQuoteQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetArtistProfileStatementAndQuoteQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistProfileStatementAndQuoteQuery, Types.GetArtistProfileStatementAndQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistProfileStatementAndQuoteQuery, Types.GetArtistProfileStatementAndQuoteQueryVariables>(GetArtistProfileStatementAndQuoteDocument, options);
      }
export function useGetArtistProfileStatementAndQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistProfileStatementAndQuoteQuery, Types.GetArtistProfileStatementAndQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistProfileStatementAndQuoteQuery, Types.GetArtistProfileStatementAndQuoteQueryVariables>(GetArtistProfileStatementAndQuoteDocument, options);
        }
export type GetArtistProfileStatementAndQuoteQueryHookResult = ReturnType<typeof useGetArtistProfileStatementAndQuoteQuery>;
export type GetArtistProfileStatementAndQuoteLazyQueryHookResult = ReturnType<typeof useGetArtistProfileStatementAndQuoteLazyQuery>;
export type GetArtistProfileStatementAndQuoteQueryResult = Apollo.QueryResult<Types.GetArtistProfileStatementAndQuoteQuery, Types.GetArtistProfileStatementAndQuoteQueryVariables>;
export const GetArtistCountByCountryDocument = gql`
    query getArtistCountByCountry($country: String!) {
  aggregateProxy(type: ArtistCountByCountry, variables: {country_code: $country})
}
    `;

/**
 * __useGetArtistCountByCountryQuery__
 *
 * To run a query within a React component, call `useGetArtistCountByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistCountByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistCountByCountryQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetArtistCountByCountryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistCountByCountryQuery, Types.GetArtistCountByCountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistCountByCountryQuery, Types.GetArtistCountByCountryQueryVariables>(GetArtistCountByCountryDocument, options);
      }
export function useGetArtistCountByCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistCountByCountryQuery, Types.GetArtistCountByCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistCountByCountryQuery, Types.GetArtistCountByCountryQueryVariables>(GetArtistCountByCountryDocument, options);
        }
export type GetArtistCountByCountryQueryHookResult = ReturnType<typeof useGetArtistCountByCountryQuery>;
export type GetArtistCountByCountryLazyQueryHookResult = ReturnType<typeof useGetArtistCountByCountryLazyQuery>;
export type GetArtistCountByCountryQueryResult = Apollo.QueryResult<Types.GetArtistCountByCountryQuery, Types.GetArtistCountByCountryQueryVariables>;
export const GetProfileSocialLinksDocument = gql`
    query getProfileSocialLinks($contextId: bigint!) {
  profile: Profile_social_links(where: {context_id: {_eq: $contextId}}) {
    link: URL
    platform
  }
}
    `;

/**
 * __useGetProfileSocialLinksQuery__
 *
 * To run a query within a React component, call `useGetProfileSocialLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileSocialLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileSocialLinksQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileSocialLinksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileSocialLinksQuery, Types.GetProfileSocialLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileSocialLinksQuery, Types.GetProfileSocialLinksQueryVariables>(GetProfileSocialLinksDocument, options);
      }
export function useGetProfileSocialLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileSocialLinksQuery, Types.GetProfileSocialLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileSocialLinksQuery, Types.GetProfileSocialLinksQueryVariables>(GetProfileSocialLinksDocument, options);
        }
export type GetProfileSocialLinksQueryHookResult = ReturnType<typeof useGetProfileSocialLinksQuery>;
export type GetProfileSocialLinksLazyQueryHookResult = ReturnType<typeof useGetProfileSocialLinksLazyQuery>;
export type GetProfileSocialLinksQueryResult = Apollo.QueryResult<Types.GetProfileSocialLinksQuery, Types.GetProfileSocialLinksQueryVariables>;
export const GetGalleryProfileAppearanceDocument = gql`
    query getGalleryProfileAppearance($profileId: bigint!) {
  data: Profile_by_pk(id: $profileId) {
    banner: kard_banner
    avatar: avatar_download_link
  }
}
    `;

/**
 * __useGetGalleryProfileAppearanceQuery__
 *
 * To run a query within a React component, call `useGetGalleryProfileAppearanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryProfileAppearanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryProfileAppearanceQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetGalleryProfileAppearanceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryProfileAppearanceQuery, Types.GetGalleryProfileAppearanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryProfileAppearanceQuery, Types.GetGalleryProfileAppearanceQueryVariables>(GetGalleryProfileAppearanceDocument, options);
      }
export function useGetGalleryProfileAppearanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryProfileAppearanceQuery, Types.GetGalleryProfileAppearanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryProfileAppearanceQuery, Types.GetGalleryProfileAppearanceQueryVariables>(GetGalleryProfileAppearanceDocument, options);
        }
export type GetGalleryProfileAppearanceQueryHookResult = ReturnType<typeof useGetGalleryProfileAppearanceQuery>;
export type GetGalleryProfileAppearanceLazyQueryHookResult = ReturnType<typeof useGetGalleryProfileAppearanceLazyQuery>;
export type GetGalleryProfileAppearanceQueryResult = Apollo.QueryResult<Types.GetGalleryProfileAppearanceQuery, Types.GetGalleryProfileAppearanceQueryVariables>;
export const CreateCollectorProfileV2Document = gql`
    mutation CreateCollectorProfileV2($is_public: String!, $handle: String!) {
  newCollectorProfile(is_public: $is_public, handle: $handle) {
    context_id
    profile_id
    profile_settings_id
  }
}
    `;
export type CreateCollectorProfileV2MutationFn = Apollo.MutationFunction<Types.CreateCollectorProfileV2Mutation, Types.CreateCollectorProfileV2MutationVariables>;

/**
 * __useCreateCollectorProfileV2Mutation__
 *
 * To run a mutation, you first call `useCreateCollectorProfileV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectorProfileV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectorProfileV2Mutation, { data, loading, error }] = useCreateCollectorProfileV2Mutation({
 *   variables: {
 *      is_public: // value for 'is_public'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useCreateCollectorProfileV2Mutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCollectorProfileV2Mutation, Types.CreateCollectorProfileV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCollectorProfileV2Mutation, Types.CreateCollectorProfileV2MutationVariables>(CreateCollectorProfileV2Document, options);
      }
export type CreateCollectorProfileV2MutationHookResult = ReturnType<typeof useCreateCollectorProfileV2Mutation>;
export type CreateCollectorProfileV2MutationResult = Apollo.MutationResult<Types.CreateCollectorProfileV2Mutation>;
export type CreateCollectorProfileV2MutationOptions = Apollo.BaseMutationOptions<Types.CreateCollectorProfileV2Mutation, Types.CreateCollectorProfileV2MutationVariables>;
export const GetProfileLastAndFirstNameDocument = gql`
    query getProfileLastAndFirstName($userId: bigint!) {
  names: Auth(where: {auth_context_accesses: {context_id: {_eq: $userId}}}) {
    firstName: first_name
    lastName: last_name
  }
}
    `;

/**
 * __useGetProfileLastAndFirstNameQuery__
 *
 * To run a query within a React component, call `useGetProfileLastAndFirstNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileLastAndFirstNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileLastAndFirstNameQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetProfileLastAndFirstNameQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileLastAndFirstNameQuery, Types.GetProfileLastAndFirstNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileLastAndFirstNameQuery, Types.GetProfileLastAndFirstNameQueryVariables>(GetProfileLastAndFirstNameDocument, options);
      }
export function useGetProfileLastAndFirstNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileLastAndFirstNameQuery, Types.GetProfileLastAndFirstNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileLastAndFirstNameQuery, Types.GetProfileLastAndFirstNameQueryVariables>(GetProfileLastAndFirstNameDocument, options);
        }
export type GetProfileLastAndFirstNameQueryHookResult = ReturnType<typeof useGetProfileLastAndFirstNameQuery>;
export type GetProfileLastAndFirstNameLazyQueryHookResult = ReturnType<typeof useGetProfileLastAndFirstNameLazyQuery>;
export type GetProfileLastAndFirstNameQueryResult = Apollo.QueryResult<Types.GetProfileLastAndFirstNameQuery, Types.GetProfileLastAndFirstNameQueryVariables>;
export const HandleCounterCheckDocument = gql`
    query handleCounterCheck($handle: String!) {
  collisions: aggregateProxy(
    type: HandleCollisionsCount
    variables: {handle: $handle}
  )
  safety: aggregateProxy(type: HandleSafetyCounter, variables: {handle: $handle})
}
    `;

/**
 * __useHandleCounterCheckQuery__
 *
 * To run a query within a React component, call `useHandleCounterCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandleCounterCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandleCounterCheckQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useHandleCounterCheckQuery(baseOptions: Apollo.QueryHookOptions<Types.HandleCounterCheckQuery, Types.HandleCounterCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HandleCounterCheckQuery, Types.HandleCounterCheckQueryVariables>(HandleCounterCheckDocument, options);
      }
export function useHandleCounterCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HandleCounterCheckQuery, Types.HandleCounterCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HandleCounterCheckQuery, Types.HandleCounterCheckQueryVariables>(HandleCounterCheckDocument, options);
        }
export type HandleCounterCheckQueryHookResult = ReturnType<typeof useHandleCounterCheckQuery>;
export type HandleCounterCheckLazyQueryHookResult = ReturnType<typeof useHandleCounterCheckLazyQuery>;
export type HandleCounterCheckQueryResult = Apollo.QueryResult<Types.HandleCounterCheckQuery, Types.HandleCounterCheckQueryVariables>;
export const GetProfilePriceRangesDocument = gql`
    query getProfilePriceRanges($contextId: bigint!) {
  ranges: Profile_price_ranges(where: {context_id: {_eq: $contextId}}) {
    id: price_range_id
  }
}
    `;

/**
 * __useGetProfilePriceRangesQuery__
 *
 * To run a query within a React component, call `useGetProfilePriceRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilePriceRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilePriceRangesQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfilePriceRangesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfilePriceRangesQuery, Types.GetProfilePriceRangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfilePriceRangesQuery, Types.GetProfilePriceRangesQueryVariables>(GetProfilePriceRangesDocument, options);
      }
export function useGetProfilePriceRangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfilePriceRangesQuery, Types.GetProfilePriceRangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfilePriceRangesQuery, Types.GetProfilePriceRangesQueryVariables>(GetProfilePriceRangesDocument, options);
        }
export type GetProfilePriceRangesQueryHookResult = ReturnType<typeof useGetProfilePriceRangesQuery>;
export type GetProfilePriceRangesLazyQueryHookResult = ReturnType<typeof useGetProfilePriceRangesLazyQuery>;
export type GetProfilePriceRangesQueryResult = Apollo.QueryResult<Types.GetProfilePriceRangesQuery, Types.GetProfilePriceRangesQueryVariables>;
export const GetProfileLocationDocument = gql`
    query getProfileLocation($contextId: bigint!) {
  location: Profile_location(where: {context_id: {_eq: $contextId}}) {
    country
    city
    city_id
    region
    WorldCity {
      id
      city
      admin_name
      admin_name_ascii
      admin_code
    }
  }
}
    `;

/**
 * __useGetProfileLocationQuery__
 *
 * To run a query within a React component, call `useGetProfileLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileLocationQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileLocationQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileLocationQuery, Types.GetProfileLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileLocationQuery, Types.GetProfileLocationQueryVariables>(GetProfileLocationDocument, options);
      }
export function useGetProfileLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileLocationQuery, Types.GetProfileLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileLocationQuery, Types.GetProfileLocationQueryVariables>(GetProfileLocationDocument, options);
        }
export type GetProfileLocationQueryHookResult = ReturnType<typeof useGetProfileLocationQuery>;
export type GetProfileLocationLazyQueryHookResult = ReturnType<typeof useGetProfileLocationLazyQuery>;
export type GetProfileLocationQueryResult = Apollo.QueryResult<Types.GetProfileLocationQuery, Types.GetProfileLocationQueryVariables>;
export const GetProfileBackgroundsDocument = gql`
    query getProfileBackgrounds($contextId: bigint!) {
  Profile_background(where: {context_id: {_eq: $contextId}}) {
    background_type
  }
}
    `;

/**
 * __useGetProfileBackgroundsQuery__
 *
 * To run a query within a React component, call `useGetProfileBackgroundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileBackgroundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileBackgroundsQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetProfileBackgroundsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileBackgroundsQuery, Types.GetProfileBackgroundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileBackgroundsQuery, Types.GetProfileBackgroundsQueryVariables>(GetProfileBackgroundsDocument, options);
      }
export function useGetProfileBackgroundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileBackgroundsQuery, Types.GetProfileBackgroundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileBackgroundsQuery, Types.GetProfileBackgroundsQueryVariables>(GetProfileBackgroundsDocument, options);
        }
export type GetProfileBackgroundsQueryHookResult = ReturnType<typeof useGetProfileBackgroundsQuery>;
export type GetProfileBackgroundsLazyQueryHookResult = ReturnType<typeof useGetProfileBackgroundsLazyQuery>;
export type GetProfileBackgroundsQueryResult = Apollo.QueryResult<Types.GetProfileBackgroundsQuery, Types.GetProfileBackgroundsQueryVariables>;
export const InsertProfileBackgroundsDocument = gql`
    mutation insertProfileBackgrounds($context_id: Int!, $backgrounds: [String!]!) {
  insertProfileBackgrounds(context_id: $context_id, backgrounds: $backgrounds)
}
    `;
export type InsertProfileBackgroundsMutationFn = Apollo.MutationFunction<Types.InsertProfileBackgroundsMutation, Types.InsertProfileBackgroundsMutationVariables>;

/**
 * __useInsertProfileBackgroundsMutation__
 *
 * To run a mutation, you first call `useInsertProfileBackgroundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProfileBackgroundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProfileBackgroundsMutation, { data, loading, error }] = useInsertProfileBackgroundsMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      backgrounds: // value for 'backgrounds'
 *   },
 * });
 */
export function useInsertProfileBackgroundsMutation(baseOptions?: Apollo.MutationHookOptions<Types.InsertProfileBackgroundsMutation, Types.InsertProfileBackgroundsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InsertProfileBackgroundsMutation, Types.InsertProfileBackgroundsMutationVariables>(InsertProfileBackgroundsDocument, options);
      }
export type InsertProfileBackgroundsMutationHookResult = ReturnType<typeof useInsertProfileBackgroundsMutation>;
export type InsertProfileBackgroundsMutationResult = Apollo.MutationResult<Types.InsertProfileBackgroundsMutation>;
export type InsertProfileBackgroundsMutationOptions = Apollo.BaseMutationOptions<Types.InsertProfileBackgroundsMutation, Types.InsertProfileBackgroundsMutationVariables>;
export const UpdateProfilePreferredCurrencyDocument = gql`
    mutation updateProfilePreferredCurrency($contextId: bigint!, $preferredCurrency: String!) {
  update_Profile_settings(
    where: {context: {id: {_eq: $contextId}}}
    _set: {currency: $preferredCurrency}
  ) {
    affected_rows
  }
}
    `;
export type UpdateProfilePreferredCurrencyMutationFn = Apollo.MutationFunction<Types.UpdateProfilePreferredCurrencyMutation, Types.UpdateProfilePreferredCurrencyMutationVariables>;

/**
 * __useUpdateProfilePreferredCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePreferredCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePreferredCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePreferredCurrencyMutation, { data, loading, error }] = useUpdateProfilePreferredCurrencyMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      preferredCurrency: // value for 'preferredCurrency'
 *   },
 * });
 */
export function useUpdateProfilePreferredCurrencyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateProfilePreferredCurrencyMutation, Types.UpdateProfilePreferredCurrencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateProfilePreferredCurrencyMutation, Types.UpdateProfilePreferredCurrencyMutationVariables>(UpdateProfilePreferredCurrencyDocument, options);
      }
export type UpdateProfilePreferredCurrencyMutationHookResult = ReturnType<typeof useUpdateProfilePreferredCurrencyMutation>;
export type UpdateProfilePreferredCurrencyMutationResult = Apollo.MutationResult<Types.UpdateProfilePreferredCurrencyMutation>;
export type UpdateProfilePreferredCurrencyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateProfilePreferredCurrencyMutation, Types.UpdateProfilePreferredCurrencyMutationVariables>;
export const SetMyFcmTokenDocument = gql`
    mutation setMyFCMToken($platform: String!, $token: String!) {
  setMyFCMToken(platform: $platform, token: $token)
}
    `;
export type SetMyFcmTokenMutationFn = Apollo.MutationFunction<Types.SetMyFcmTokenMutation, Types.SetMyFcmTokenMutationVariables>;

/**
 * __useSetMyFcmTokenMutation__
 *
 * To run a mutation, you first call `useSetMyFcmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMyFcmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMyFcmTokenMutation, { data, loading, error }] = useSetMyFcmTokenMutation({
 *   variables: {
 *      platform: // value for 'platform'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSetMyFcmTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetMyFcmTokenMutation, Types.SetMyFcmTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetMyFcmTokenMutation, Types.SetMyFcmTokenMutationVariables>(SetMyFcmTokenDocument, options);
      }
export type SetMyFcmTokenMutationHookResult = ReturnType<typeof useSetMyFcmTokenMutation>;
export type SetMyFcmTokenMutationResult = Apollo.MutationResult<Types.SetMyFcmTokenMutation>;
export type SetMyFcmTokenMutationOptions = Apollo.BaseMutationOptions<Types.SetMyFcmTokenMutation, Types.SetMyFcmTokenMutationVariables>;
export const TrashMyFcmTokenDocument = gql`
    mutation trashMyFCMToken($platform: String!) {
  trashMyFCMToken(platform: $platform)
}
    `;
export type TrashMyFcmTokenMutationFn = Apollo.MutationFunction<Types.TrashMyFcmTokenMutation, Types.TrashMyFcmTokenMutationVariables>;

/**
 * __useTrashMyFcmTokenMutation__
 *
 * To run a mutation, you first call `useTrashMyFcmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrashMyFcmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trashMyFcmTokenMutation, { data, loading, error }] = useTrashMyFcmTokenMutation({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useTrashMyFcmTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.TrashMyFcmTokenMutation, Types.TrashMyFcmTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TrashMyFcmTokenMutation, Types.TrashMyFcmTokenMutationVariables>(TrashMyFcmTokenDocument, options);
      }
export type TrashMyFcmTokenMutationHookResult = ReturnType<typeof useTrashMyFcmTokenMutation>;
export type TrashMyFcmTokenMutationResult = Apollo.MutationResult<Types.TrashMyFcmTokenMutation>;
export type TrashMyFcmTokenMutationOptions = Apollo.BaseMutationOptions<Types.TrashMyFcmTokenMutation, Types.TrashMyFcmTokenMutationVariables>;
export const DeactivateMyFcmTokenDocument = gql`
    mutation deactivateMyFCMToken($platform: String!) {
  deactivateMyFCMToken(platform: $platform)
}
    `;
export type DeactivateMyFcmTokenMutationFn = Apollo.MutationFunction<Types.DeactivateMyFcmTokenMutation, Types.DeactivateMyFcmTokenMutationVariables>;

/**
 * __useDeactivateMyFcmTokenMutation__
 *
 * To run a mutation, you first call `useDeactivateMyFcmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateMyFcmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateMyFcmTokenMutation, { data, loading, error }] = useDeactivateMyFcmTokenMutation({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useDeactivateMyFcmTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeactivateMyFcmTokenMutation, Types.DeactivateMyFcmTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeactivateMyFcmTokenMutation, Types.DeactivateMyFcmTokenMutationVariables>(DeactivateMyFcmTokenDocument, options);
      }
export type DeactivateMyFcmTokenMutationHookResult = ReturnType<typeof useDeactivateMyFcmTokenMutation>;
export type DeactivateMyFcmTokenMutationResult = Apollo.MutationResult<Types.DeactivateMyFcmTokenMutation>;
export type DeactivateMyFcmTokenMutationOptions = Apollo.BaseMutationOptions<Types.DeactivateMyFcmTokenMutation, Types.DeactivateMyFcmTokenMutationVariables>;
export const ActivateMyFcmTokenDocument = gql`
    mutation activateMyFCMToken($platform: String!) {
  activateMyFCMToken(platform: $platform)
}
    `;
export type ActivateMyFcmTokenMutationFn = Apollo.MutationFunction<Types.ActivateMyFcmTokenMutation, Types.ActivateMyFcmTokenMutationVariables>;

/**
 * __useActivateMyFcmTokenMutation__
 *
 * To run a mutation, you first call `useActivateMyFcmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateMyFcmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateMyFcmTokenMutation, { data, loading, error }] = useActivateMyFcmTokenMutation({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useActivateMyFcmTokenMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateMyFcmTokenMutation, Types.ActivateMyFcmTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateMyFcmTokenMutation, Types.ActivateMyFcmTokenMutationVariables>(ActivateMyFcmTokenDocument, options);
      }
export type ActivateMyFcmTokenMutationHookResult = ReturnType<typeof useActivateMyFcmTokenMutation>;
export type ActivateMyFcmTokenMutationResult = Apollo.MutationResult<Types.ActivateMyFcmTokenMutation>;
export type ActivateMyFcmTokenMutationOptions = Apollo.BaseMutationOptions<Types.ActivateMyFcmTokenMutation, Types.ActivateMyFcmTokenMutationVariables>;
export const GetMyFcmTokenDocument = gql`
    query getMyFCMToken($deviceId: String!) {
  getMyFCMToken(deviceId: $deviceId) {
    token
    deactivated_at
    trashed_at
  }
}
    `;

/**
 * __useGetMyFcmTokenQuery__
 *
 * To run a query within a React component, call `useGetMyFcmTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyFcmTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyFcmTokenQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetMyFcmTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMyFcmTokenQuery, Types.GetMyFcmTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMyFcmTokenQuery, Types.GetMyFcmTokenQueryVariables>(GetMyFcmTokenDocument, options);
      }
export function useGetMyFcmTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMyFcmTokenQuery, Types.GetMyFcmTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMyFcmTokenQuery, Types.GetMyFcmTokenQueryVariables>(GetMyFcmTokenDocument, options);
        }
export type GetMyFcmTokenQueryHookResult = ReturnType<typeof useGetMyFcmTokenQuery>;
export type GetMyFcmTokenLazyQueryHookResult = ReturnType<typeof useGetMyFcmTokenLazyQuery>;
export type GetMyFcmTokenQueryResult = Apollo.QueryResult<Types.GetMyFcmTokenQuery, Types.GetMyFcmTokenQueryVariables>;
export const GetArtworkDataDocument = gql`
    query getArtworkData($artworkId: bigint!) {
  Artwork_by_pk(id: $artworkId) {
    artworkId: id
    primaryMetadataId: primary_image_metadata_id
    artwork_details {
      title
    }
  }
}
    `;

/**
 * __useGetArtworkDataQuery__
 *
 * To run a query within a React component, call `useGetArtworkDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkDataQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetArtworkDataQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkDataQuery, Types.GetArtworkDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkDataQuery, Types.GetArtworkDataQueryVariables>(GetArtworkDataDocument, options);
      }
export function useGetArtworkDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkDataQuery, Types.GetArtworkDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkDataQuery, Types.GetArtworkDataQueryVariables>(GetArtworkDataDocument, options);
        }
export type GetArtworkDataQueryHookResult = ReturnType<typeof useGetArtworkDataQuery>;
export type GetArtworkDataLazyQueryHookResult = ReturnType<typeof useGetArtworkDataLazyQuery>;
export type GetArtworkDataQueryResult = Apollo.QueryResult<Types.GetArtworkDataQuery, Types.GetArtworkDataQueryVariables>;
export const GetArtistArtworksDocument = gql`
    query getArtistArtworks($artistId: bigint!) {
  artworks: artist_cardgrid_artworks_view(
    where: {artist_context_id: {_eq: $artistId}, rank: {_lte: 3}}
  ) {
    id
    thumbnailMetadataId: thumbnail_metadata_id
  }
  artist: Artist_explore_info(where: {artist_context_id: {_eq: $artistId}}) {
    title
    handle
  }
}
    `;

/**
 * __useGetArtistArtworksQuery__
 *
 * To run a query within a React component, call `useGetArtistArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistArtworksQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useGetArtistArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistArtworksQuery, Types.GetArtistArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistArtworksQuery, Types.GetArtistArtworksQueryVariables>(GetArtistArtworksDocument, options);
      }
export function useGetArtistArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistArtworksQuery, Types.GetArtistArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistArtworksQuery, Types.GetArtistArtworksQueryVariables>(GetArtistArtworksDocument, options);
        }
export type GetArtistArtworksQueryHookResult = ReturnType<typeof useGetArtistArtworksQuery>;
export type GetArtistArtworksLazyQueryHookResult = ReturnType<typeof useGetArtistArtworksLazyQuery>;
export type GetArtistArtworksQueryResult = Apollo.QueryResult<Types.GetArtistArtworksQuery, Types.GetArtistArtworksQueryVariables>;
export const GetArtistsArtworksDocument = gql`
    query getArtistsArtworks($artistIds: [bigint!]) {
  artworks: artist_cardgrid_artworks_view(
    where: {artist_context_id: {_in: $artistIds}, rank: {_lte: 2}}
    order_by: {rank: asc}
  ) {
    id
    thumbnailMetadataId: thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetArtistsArtworksQuery__
 *
 * To run a query within a React component, call `useGetArtistsArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistsArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistsArtworksQuery({
 *   variables: {
 *      artistIds: // value for 'artistIds'
 *   },
 * });
 */
export function useGetArtistsArtworksQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetArtistsArtworksQuery, Types.GetArtistsArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistsArtworksQuery, Types.GetArtistsArtworksQueryVariables>(GetArtistsArtworksDocument, options);
      }
export function useGetArtistsArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistsArtworksQuery, Types.GetArtistsArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistsArtworksQuery, Types.GetArtistsArtworksQueryVariables>(GetArtistsArtworksDocument, options);
        }
export type GetArtistsArtworksQueryHookResult = ReturnType<typeof useGetArtistsArtworksQuery>;
export type GetArtistsArtworksLazyQueryHookResult = ReturnType<typeof useGetArtistsArtworksLazyQuery>;
export type GetArtistsArtworksQueryResult = Apollo.QueryResult<Types.GetArtistsArtworksQuery, Types.GetArtistsArtworksQueryVariables>;
export const CheckUserAuthStateDocument = gql`
    query CheckUserAuthState($email: String!) {
  checkUserAuthState(email: $email) {
    status
    authTypes
  }
}
    `;

/**
 * __useCheckUserAuthStateQuery__
 *
 * To run a query within a React component, call `useCheckUserAuthStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserAuthStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserAuthStateQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckUserAuthStateQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckUserAuthStateQuery, Types.CheckUserAuthStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckUserAuthStateQuery, Types.CheckUserAuthStateQueryVariables>(CheckUserAuthStateDocument, options);
      }
export function useCheckUserAuthStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckUserAuthStateQuery, Types.CheckUserAuthStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckUserAuthStateQuery, Types.CheckUserAuthStateQueryVariables>(CheckUserAuthStateDocument, options);
        }
export type CheckUserAuthStateQueryHookResult = ReturnType<typeof useCheckUserAuthStateQuery>;
export type CheckUserAuthStateLazyQueryHookResult = ReturnType<typeof useCheckUserAuthStateLazyQuery>;
export type CheckUserAuthStateQueryResult = Apollo.QueryResult<Types.CheckUserAuthStateQuery, Types.CheckUserAuthStateQueryVariables>;
export const GetArtistVideoDocument = gql`
    query GetArtistVideo($contextId: bigint!) {
  Profile_video_links(where: {context_id: {_eq: $contextId}}) {
    id
    platform
    URL
  }
}
    `;

/**
 * __useGetArtistVideoQuery__
 *
 * To run a query within a React component, call `useGetArtistVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistVideoQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetArtistVideoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistVideoQuery, Types.GetArtistVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistVideoQuery, Types.GetArtistVideoQueryVariables>(GetArtistVideoDocument, options);
      }
export function useGetArtistVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistVideoQuery, Types.GetArtistVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistVideoQuery, Types.GetArtistVideoQueryVariables>(GetArtistVideoDocument, options);
        }
export type GetArtistVideoQueryHookResult = ReturnType<typeof useGetArtistVideoQuery>;
export type GetArtistVideoLazyQueryHookResult = ReturnType<typeof useGetArtistVideoLazyQuery>;
export type GetArtistVideoQueryResult = Apollo.QueryResult<Types.GetArtistVideoQuery, Types.GetArtistVideoQueryVariables>;
export const GetArtworkByHandleSlugDocument = gql`
    query GetArtworkByHandleSlug($slug: String!, $handle: String!) {
  Artwork(
    where: {slug: {_eq: $slug}, _or: [{artist_context: {profile: {handle: {_ilike: $handle}}}}, {gallery_context: {profile: {handle: {_ilike: $handle}}}}]}
  ) {
    id
    slug
    artist_context_id
    created_at
    is_public
    has_ar_animation
    published_date
    is_available_sale
    primary_image_metadata_id
    thumbnail_metadata_id
    primary_image_metadata {
      file_name
      file_extension
      file_size
    }
    artwork_details {
      creation_date_day
      creation_date_month
      creation_date_year
      depth
      duration
      height
      materials_and_medium
      measuring_unit
      discipline_other
      discipline
      title
      tools
      width
      is_dark_mode
      art_type
      ENUM_artwork_discipline {
        description
      }
    }
    artwork_story {
      id
      statement
      artwork_story_media(order_by: {order: asc}) {
        id
        artwork_story_media_download_link
        order
        file_metadata_id
      }
      story_audio_download_link
      audio_metadata {
        file_name
        extra
      }
    }
    gallery_context_id
    vuforia_rating
    gallery_context {
      locations {
        city
        country
      }
      profile {
        id
        avatar
        first_name
        handle
        is_public
        last_name
        quote
        statement
        title
      }
    }
    artist_context {
      locations {
        city
        country
      }
      profile {
        id
        avatar
        first_name
        handle
        is_public
        last_name
        quote
        statement
        title
        audio_download_link
        Valid_profile_statement_audio_syntheses {
          audio_id
          profile_id
          language
        }
        audio_metadata {
          file_name
          extra
        }
      }
    }
    artwork_secondary_media(order_by: {order: asc}) {
      id
      file_metadata_id
    }
    transaction_histories(
      where: {status: {_eq: ACCEPTED}, print_id: {_is_null: true}}
      order_by: {created_at: desc}
      limit: 1
    ) {
      buyer_context {
        profile {
          id
          avatar
          is_public
          title
          first_name
          last_name
          handle
        }
      }
    }
    artwork_to_collections(where: {Collection: {type: {_eq: SERIES}}}) {
      Collection {
        id
        name
        description
        audio_metadata {
          file_name
          extra
        }
        collection_audio_download_link
      }
    }
    collection_for_owners: artwork_to_collections {
      Collection {
        owner_context_id
        type
      }
    }
    owner_collection: artwork_to_collections(
      where: {Collection: {type: {_eq: OWNED}}}
    ) {
      Collection {
        owner_context_id
        owner_context {
          profile {
            title
          }
        }
      }
    }
    Artwork_prices(where: {id: {}, scope: {_eq: SHOWCASE}}) {
      price
      currency
      created_at
      is_public
    }
    primary_tag: artwork_to_tags(where: {type: {_eq: PRIMARY}}) {
      tag {
        id
        description
        family
        genome_id
        name
        value
        can_be_primary
      }
    }
    secondary_tags: artwork_to_tags(where: {type: {_eq: SECONDARY}}) {
      tag {
        id
        description
        family
        genome_id
        name
        value
        can_be_primary
      }
    }
    editions: Editions(
      where: {publish_date: {_is_null: false}, is_public: {_eq: true}}
    ) {
      ...ArtworkShowcaseEdition
    }
  }
}
    ${ArtworkShowcaseEditionFragmentDoc}`;

/**
 * __useGetArtworkByHandleSlugQuery__
 *
 * To run a query within a React component, call `useGetArtworkByHandleSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkByHandleSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkByHandleSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetArtworkByHandleSlugQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkByHandleSlugQuery, Types.GetArtworkByHandleSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkByHandleSlugQuery, Types.GetArtworkByHandleSlugQueryVariables>(GetArtworkByHandleSlugDocument, options);
      }
export function useGetArtworkByHandleSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkByHandleSlugQuery, Types.GetArtworkByHandleSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkByHandleSlugQuery, Types.GetArtworkByHandleSlugQueryVariables>(GetArtworkByHandleSlugDocument, options);
        }
export type GetArtworkByHandleSlugQueryHookResult = ReturnType<typeof useGetArtworkByHandleSlugQuery>;
export type GetArtworkByHandleSlugLazyQueryHookResult = ReturnType<typeof useGetArtworkByHandleSlugLazyQuery>;
export type GetArtworkByHandleSlugQueryResult = Apollo.QueryResult<Types.GetArtworkByHandleSlugQuery, Types.GetArtworkByHandleSlugQueryVariables>;
export const GetArtworkStoryVideoDocument = gql`
    query GetArtworkStoryVideo($id: bigint!) {
  Artwork_story_video_links(where: {artwork_story_id: {_eq: $id}}) {
    id
    platform
    URL
  }
}
    `;

/**
 * __useGetArtworkStoryVideoQuery__
 *
 * To run a query within a React component, call `useGetArtworkStoryVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkStoryVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkStoryVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetArtworkStoryVideoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworkStoryVideoQuery, Types.GetArtworkStoryVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworkStoryVideoQuery, Types.GetArtworkStoryVideoQueryVariables>(GetArtworkStoryVideoDocument, options);
      }
export function useGetArtworkStoryVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworkStoryVideoQuery, Types.GetArtworkStoryVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworkStoryVideoQuery, Types.GetArtworkStoryVideoQueryVariables>(GetArtworkStoryVideoDocument, options);
        }
export type GetArtworkStoryVideoQueryHookResult = ReturnType<typeof useGetArtworkStoryVideoQuery>;
export type GetArtworkStoryVideoLazyQueryHookResult = ReturnType<typeof useGetArtworkStoryVideoLazyQuery>;
export type GetArtworkStoryVideoQueryResult = Apollo.QueryResult<Types.GetArtworkStoryVideoQuery, Types.GetArtworkStoryVideoQueryVariables>;
export const GetArtworksFromArtistDocument = gql`
    query GetArtworksFromArtist($handle: String!) {
  Artwork_to_Collection(
    where: {_and: [{Collection: {type: {_eq: CREATED}}}, {Artwork: {_or: [{artist_context: {profile: {handle: {_ilike: $handle}}}}, {gallery_context: {profile: {handle: {_ilike: $handle}}}}]}}]}
    order_by: {order: asc}
  ) {
    order
    id
    Artwork {
      id
      slug
      primary_image_metadata_id
      artwork_details {
        title
      }
      artist_context {
        profile {
          handle
        }
      }
    }
  }
}
    `;

/**
 * __useGetArtworksFromArtistQuery__
 *
 * To run a query within a React component, call `useGetArtworksFromArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworksFromArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworksFromArtistQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetArtworksFromArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworksFromArtistQuery, Types.GetArtworksFromArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworksFromArtistQuery, Types.GetArtworksFromArtistQueryVariables>(GetArtworksFromArtistDocument, options);
      }
export function useGetArtworksFromArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworksFromArtistQuery, Types.GetArtworksFromArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworksFromArtistQuery, Types.GetArtworksFromArtistQueryVariables>(GetArtworksFromArtistDocument, options);
        }
export type GetArtworksFromArtistQueryHookResult = ReturnType<typeof useGetArtworksFromArtistQuery>;
export type GetArtworksFromArtistLazyQueryHookResult = ReturnType<typeof useGetArtworksFromArtistLazyQuery>;
export type GetArtworksFromArtistQueryResult = Apollo.QueryResult<Types.GetArtworksFromArtistQuery, Types.GetArtworksFromArtistQueryVariables>;
export const GetLastInquiryDateDocument = gql`
    query GetLastInquiryDate($context_sender_id: bigint!, $artwork_id: bigint!) {
  Purchase_inquires(
    where: {context_sender_id: {_eq: $context_sender_id}, artwork_id: {_eq: $artwork_id}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    created_at
    expires_at
  }
}
    `;

/**
 * __useGetLastInquiryDateQuery__
 *
 * To run a query within a React component, call `useGetLastInquiryDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastInquiryDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastInquiryDateQuery({
 *   variables: {
 *      context_sender_id: // value for 'context_sender_id'
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useGetLastInquiryDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLastInquiryDateQuery, Types.GetLastInquiryDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLastInquiryDateQuery, Types.GetLastInquiryDateQueryVariables>(GetLastInquiryDateDocument, options);
      }
export function useGetLastInquiryDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLastInquiryDateQuery, Types.GetLastInquiryDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLastInquiryDateQuery, Types.GetLastInquiryDateQueryVariables>(GetLastInquiryDateDocument, options);
        }
export type GetLastInquiryDateQueryHookResult = ReturnType<typeof useGetLastInquiryDateQuery>;
export type GetLastInquiryDateLazyQueryHookResult = ReturnType<typeof useGetLastInquiryDateLazyQuery>;
export type GetLastInquiryDateQueryResult = Apollo.QueryResult<Types.GetLastInquiryDateQuery, Types.GetLastInquiryDateQueryVariables>;
export const GetProvenanceByArtworkIdDocument = gql`
    query GetProvenanceByArtworkId($artwork_id: Int!) {
  getArtworkEventByArtworkId(artwork_id: $artwork_id) {
    date
    photoURL
    text
    event_type
    link
  }
}
    `;

/**
 * __useGetProvenanceByArtworkIdQuery__
 *
 * To run a query within a React component, call `useGetProvenanceByArtworkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvenanceByArtworkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvenanceByArtworkIdQuery({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useGetProvenanceByArtworkIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProvenanceByArtworkIdQuery, Types.GetProvenanceByArtworkIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProvenanceByArtworkIdQuery, Types.GetProvenanceByArtworkIdQueryVariables>(GetProvenanceByArtworkIdDocument, options);
      }
export function useGetProvenanceByArtworkIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProvenanceByArtworkIdQuery, Types.GetProvenanceByArtworkIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProvenanceByArtworkIdQuery, Types.GetProvenanceByArtworkIdQueryVariables>(GetProvenanceByArtworkIdDocument, options);
        }
export type GetProvenanceByArtworkIdQueryHookResult = ReturnType<typeof useGetProvenanceByArtworkIdQuery>;
export type GetProvenanceByArtworkIdLazyQueryHookResult = ReturnType<typeof useGetProvenanceByArtworkIdLazyQuery>;
export type GetProvenanceByArtworkIdQueryResult = Apollo.QueryResult<Types.GetProvenanceByArtworkIdQuery, Types.GetProvenanceByArtworkIdQueryVariables>;
export const GetProvenanceAndHandlesByArtworkIdDocument = gql`
    query GetProvenanceAndHandlesByArtworkId($artwork_id: Int!, $artworkId: bigint!) {
  getArtworkEventByArtworkId(artwork_id: $artwork_id) {
    date
    photoURL
    text
    event_type
    __typename
    artwork_transaction {
      buyer_context {
        profile {
          handle
        }
      }
    }
  }
  Artwork(where: {id: {_eq: $artworkId}}) {
    transaction_histories(limit: 1) {
      seller_context {
        profile {
          handle
        }
      }
    }
  }
}
    `;

/**
 * __useGetProvenanceAndHandlesByArtworkIdQuery__
 *
 * To run a query within a React component, call `useGetProvenanceAndHandlesByArtworkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvenanceAndHandlesByArtworkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvenanceAndHandlesByArtworkIdQuery({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetProvenanceAndHandlesByArtworkIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProvenanceAndHandlesByArtworkIdQuery, Types.GetProvenanceAndHandlesByArtworkIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProvenanceAndHandlesByArtworkIdQuery, Types.GetProvenanceAndHandlesByArtworkIdQueryVariables>(GetProvenanceAndHandlesByArtworkIdDocument, options);
      }
export function useGetProvenanceAndHandlesByArtworkIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProvenanceAndHandlesByArtworkIdQuery, Types.GetProvenanceAndHandlesByArtworkIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProvenanceAndHandlesByArtworkIdQuery, Types.GetProvenanceAndHandlesByArtworkIdQueryVariables>(GetProvenanceAndHandlesByArtworkIdDocument, options);
        }
export type GetProvenanceAndHandlesByArtworkIdQueryHookResult = ReturnType<typeof useGetProvenanceAndHandlesByArtworkIdQuery>;
export type GetProvenanceAndHandlesByArtworkIdLazyQueryHookResult = ReturnType<typeof useGetProvenanceAndHandlesByArtworkIdLazyQuery>;
export type GetProvenanceAndHandlesByArtworkIdQueryResult = Apollo.QueryResult<Types.GetProvenanceAndHandlesByArtworkIdQuery, Types.GetProvenanceAndHandlesByArtworkIdQueryVariables>;
export const GetProvenanceByEditionIdDocument = gql`
    query GetProvenanceByEditionId($edition_id: Int!, $edition_by_type_id: Int = 0) {
  getEditionEventByEditionId(
    edition_id: $edition_id
    edition_by_type_id: $edition_by_type_id
  ) {
    date
    photoURL
    text
    event_type
  }
}
    `;

/**
 * __useGetProvenanceByEditionIdQuery__
 *
 * To run a query within a React component, call `useGetProvenanceByEditionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvenanceByEditionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvenanceByEditionIdQuery({
 *   variables: {
 *      edition_id: // value for 'edition_id'
 *      edition_by_type_id: // value for 'edition_by_type_id'
 *   },
 * });
 */
export function useGetProvenanceByEditionIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProvenanceByEditionIdQuery, Types.GetProvenanceByEditionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProvenanceByEditionIdQuery, Types.GetProvenanceByEditionIdQueryVariables>(GetProvenanceByEditionIdDocument, options);
      }
export function useGetProvenanceByEditionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProvenanceByEditionIdQuery, Types.GetProvenanceByEditionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProvenanceByEditionIdQuery, Types.GetProvenanceByEditionIdQueryVariables>(GetProvenanceByEditionIdDocument, options);
        }
export type GetProvenanceByEditionIdQueryHookResult = ReturnType<typeof useGetProvenanceByEditionIdQuery>;
export type GetProvenanceByEditionIdLazyQueryHookResult = ReturnType<typeof useGetProvenanceByEditionIdLazyQuery>;
export type GetProvenanceByEditionIdQueryResult = Apollo.QueryResult<Types.GetProvenanceByEditionIdQuery, Types.GetProvenanceByEditionIdQueryVariables>;
export const GetSeriesVideoDocument = gql`
    query GetSeriesVideo($id: bigint!) {
  Collection_video_links(where: {collection_id: {_eq: $id}}) {
    platform
    URL
  }
}
    `;

/**
 * __useGetSeriesVideoQuery__
 *
 * To run a query within a React component, call `useGetSeriesVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSeriesVideoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSeriesVideoQuery, Types.GetSeriesVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSeriesVideoQuery, Types.GetSeriesVideoQueryVariables>(GetSeriesVideoDocument, options);
      }
export function useGetSeriesVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSeriesVideoQuery, Types.GetSeriesVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSeriesVideoQuery, Types.GetSeriesVideoQueryVariables>(GetSeriesVideoDocument, options);
        }
export type GetSeriesVideoQueryHookResult = ReturnType<typeof useGetSeriesVideoQuery>;
export type GetSeriesVideoLazyQueryHookResult = ReturnType<typeof useGetSeriesVideoLazyQuery>;
export type GetSeriesVideoQueryResult = Apollo.QueryResult<Types.GetSeriesVideoQuery, Types.GetSeriesVideoQueryVariables>;
export const InitArtworkPurchaseFlowDocument = gql`
    mutation initArtworkPurchaseFlow($artworkId: bigint!, $scope: String!, $successUrl: String!, $cancelUrl: String!) {
  initiateArtworkPurchaseTransaction(
    artworkId: $artworkId
    artworkPriceScope: $scope
    successUrl: $successUrl
    cancelUrl: $cancelUrl
  ) {
    checkoutUrl
  }
}
    `;
export type InitArtworkPurchaseFlowMutationFn = Apollo.MutationFunction<Types.InitArtworkPurchaseFlowMutation, Types.InitArtworkPurchaseFlowMutationVariables>;

/**
 * __useInitArtworkPurchaseFlowMutation__
 *
 * To run a mutation, you first call `useInitArtworkPurchaseFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitArtworkPurchaseFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initArtworkPurchaseFlowMutation, { data, loading, error }] = useInitArtworkPurchaseFlowMutation({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *      scope: // value for 'scope'
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *   },
 * });
 */
export function useInitArtworkPurchaseFlowMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitArtworkPurchaseFlowMutation, Types.InitArtworkPurchaseFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitArtworkPurchaseFlowMutation, Types.InitArtworkPurchaseFlowMutationVariables>(InitArtworkPurchaseFlowDocument, options);
      }
export type InitArtworkPurchaseFlowMutationHookResult = ReturnType<typeof useInitArtworkPurchaseFlowMutation>;
export type InitArtworkPurchaseFlowMutationResult = Apollo.MutationResult<Types.InitArtworkPurchaseFlowMutation>;
export type InitArtworkPurchaseFlowMutationOptions = Apollo.BaseMutationOptions<Types.InitArtworkPurchaseFlowMutation, Types.InitArtworkPurchaseFlowMutationVariables>;
export const InquireForPurchaseRegisteredUserDocument = gql`
    mutation InquireForPurchaseRegisteredUser($user_context_id: Int!, $artwork_id: Int!) {
  inquireForPurchaseByLoggedUser(
    user_context_id: $user_context_id
    artwork_id: $artwork_id
  )
}
    `;
export type InquireForPurchaseRegisteredUserMutationFn = Apollo.MutationFunction<Types.InquireForPurchaseRegisteredUserMutation, Types.InquireForPurchaseRegisteredUserMutationVariables>;

/**
 * __useInquireForPurchaseRegisteredUserMutation__
 *
 * To run a mutation, you first call `useInquireForPurchaseRegisteredUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquireForPurchaseRegisteredUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquireForPurchaseRegisteredUserMutation, { data, loading, error }] = useInquireForPurchaseRegisteredUserMutation({
 *   variables: {
 *      user_context_id: // value for 'user_context_id'
 *      artwork_id: // value for 'artwork_id'
 *   },
 * });
 */
export function useInquireForPurchaseRegisteredUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.InquireForPurchaseRegisteredUserMutation, Types.InquireForPurchaseRegisteredUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InquireForPurchaseRegisteredUserMutation, Types.InquireForPurchaseRegisteredUserMutationVariables>(InquireForPurchaseRegisteredUserDocument, options);
      }
export type InquireForPurchaseRegisteredUserMutationHookResult = ReturnType<typeof useInquireForPurchaseRegisteredUserMutation>;
export type InquireForPurchaseRegisteredUserMutationResult = Apollo.MutationResult<Types.InquireForPurchaseRegisteredUserMutation>;
export type InquireForPurchaseRegisteredUserMutationOptions = Apollo.BaseMutationOptions<Types.InquireForPurchaseRegisteredUserMutation, Types.InquireForPurchaseRegisteredUserMutationVariables>;
export const InquireForPurchaseUnegisteredUserDocument = gql`
    mutation InquireForPurchaseUnegisteredUser($artwork_id: Int!, $inquirer_email: String!, $inquirer_ip_addr: String!, $inquirer_first_name: String!, $is_custom_domain: Boolean, $language: String) {
  inquireForPurchaseByOutsideUser(
    artwork_id: $artwork_id
    inquirer_email: $inquirer_email
    inquirer_ip_addr: $inquirer_ip_addr
    inquirer_first_name: $inquirer_first_name
    is_custom_domain: $is_custom_domain
    language: $language
  )
}
    `;
export type InquireForPurchaseUnegisteredUserMutationFn = Apollo.MutationFunction<Types.InquireForPurchaseUnegisteredUserMutation, Types.InquireForPurchaseUnegisteredUserMutationVariables>;

/**
 * __useInquireForPurchaseUnegisteredUserMutation__
 *
 * To run a mutation, you first call `useInquireForPurchaseUnegisteredUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquireForPurchaseUnegisteredUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquireForPurchaseUnegisteredUserMutation, { data, loading, error }] = useInquireForPurchaseUnegisteredUserMutation({
 *   variables: {
 *      artwork_id: // value for 'artwork_id'
 *      inquirer_email: // value for 'inquirer_email'
 *      inquirer_ip_addr: // value for 'inquirer_ip_addr'
 *      inquirer_first_name: // value for 'inquirer_first_name'
 *      is_custom_domain: // value for 'is_custom_domain'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useInquireForPurchaseUnegisteredUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.InquireForPurchaseUnegisteredUserMutation, Types.InquireForPurchaseUnegisteredUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InquireForPurchaseUnegisteredUserMutation, Types.InquireForPurchaseUnegisteredUserMutationVariables>(InquireForPurchaseUnegisteredUserDocument, options);
      }
export type InquireForPurchaseUnegisteredUserMutationHookResult = ReturnType<typeof useInquireForPurchaseUnegisteredUserMutation>;
export type InquireForPurchaseUnegisteredUserMutationResult = Apollo.MutationResult<Types.InquireForPurchaseUnegisteredUserMutation>;
export type InquireForPurchaseUnegisteredUserMutationOptions = Apollo.BaseMutationOptions<Types.InquireForPurchaseUnegisteredUserMutation, Types.InquireForPurchaseUnegisteredUserMutationVariables>;
export const IsEmailRegisteredDocument = gql`
    query IsEmailRegistered($email: String = "") {
  All_artists(where: {email: {_eq: $email}}) {
    title
    context_id
  }
  All_collectors(where: {email: {_eq: $email}}) {
    first_name
    last_name
    context_id
    is_public
    handle
  }
  All_galleries(where: {email: {_eq: $email}}) {
    title
    context_id
  }
}
    `;

/**
 * __useIsEmailRegisteredQuery__
 *
 * To run a query within a React component, call `useIsEmailRegisteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailRegisteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailRegisteredQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailRegisteredQuery(baseOptions?: Apollo.QueryHookOptions<Types.IsEmailRegisteredQuery, Types.IsEmailRegisteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsEmailRegisteredQuery, Types.IsEmailRegisteredQueryVariables>(IsEmailRegisteredDocument, options);
      }
export function useIsEmailRegisteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsEmailRegisteredQuery, Types.IsEmailRegisteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsEmailRegisteredQuery, Types.IsEmailRegisteredQueryVariables>(IsEmailRegisteredDocument, options);
        }
export type IsEmailRegisteredQueryHookResult = ReturnType<typeof useIsEmailRegisteredQuery>;
export type IsEmailRegisteredLazyQueryHookResult = ReturnType<typeof useIsEmailRegisteredLazyQuery>;
export type IsEmailRegisteredQueryResult = Apollo.QueryResult<Types.IsEmailRegisteredQuery, Types.IsEmailRegisteredQueryVariables>;
export const GetMutualAdmirationsWithArtworksDocument = gql`
    query getMutualAdmirationsWithArtworks($context_id: bigint!, $offset: Int = 0, $limit: Int!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, context_id: {_eq: $context_id}, is_mutual: {_eq: true}, Social_graph_followers_Target_Context: {type: {_eq: ARTIST}}}
    order_by: [{selected_order: asc}]
    offset: $offset
    limit: $limit
  ) {
    ...FollowerFieldsWithArtworks
  }
}
    ${FollowerFieldsWithArtworksFragmentDoc}`;

/**
 * __useGetMutualAdmirationsWithArtworksQuery__
 *
 * To run a query within a React component, call `useGetMutualAdmirationsWithArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMutualAdmirationsWithArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMutualAdmirationsWithArtworksQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMutualAdmirationsWithArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMutualAdmirationsWithArtworksQuery, Types.GetMutualAdmirationsWithArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMutualAdmirationsWithArtworksQuery, Types.GetMutualAdmirationsWithArtworksQueryVariables>(GetMutualAdmirationsWithArtworksDocument, options);
      }
export function useGetMutualAdmirationsWithArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMutualAdmirationsWithArtworksQuery, Types.GetMutualAdmirationsWithArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMutualAdmirationsWithArtworksQuery, Types.GetMutualAdmirationsWithArtworksQueryVariables>(GetMutualAdmirationsWithArtworksDocument, options);
        }
export type GetMutualAdmirationsWithArtworksQueryHookResult = ReturnType<typeof useGetMutualAdmirationsWithArtworksQuery>;
export type GetMutualAdmirationsWithArtworksLazyQueryHookResult = ReturnType<typeof useGetMutualAdmirationsWithArtworksLazyQuery>;
export type GetMutualAdmirationsWithArtworksQueryResult = Apollo.QueryResult<Types.GetMutualAdmirationsWithArtworksQuery, Types.GetMutualAdmirationsWithArtworksQueryVariables>;
export const GetMutualAdmirationsWithArtworksV2Document = gql`
    query getMutualAdmirationsWithArtworksV2($context_id: bigint!, $offset: Int = 0, $limit: Int!) {
  Social_graph_followers(
    where: {state: {_eq: ACTIVE}, context_id: {_eq: $context_id}, is_mutual: {_eq: true}, Social_graph_followers_Target_Context: {type: {_eq: ARTIST}}}
    order_by: [{selected_order: asc}, {updated_at: desc}]
    offset: $offset
    limit: $limit
  ) {
    context_id
    target_context_id
    selected_order
    updated_at
  }
}
    `;

/**
 * __useGetMutualAdmirationsWithArtworksV2Query__
 *
 * To run a query within a React component, call `useGetMutualAdmirationsWithArtworksV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetMutualAdmirationsWithArtworksV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMutualAdmirationsWithArtworksV2Query({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMutualAdmirationsWithArtworksV2Query(baseOptions: Apollo.QueryHookOptions<Types.GetMutualAdmirationsWithArtworksV2Query, Types.GetMutualAdmirationsWithArtworksV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMutualAdmirationsWithArtworksV2Query, Types.GetMutualAdmirationsWithArtworksV2QueryVariables>(GetMutualAdmirationsWithArtworksV2Document, options);
      }
export function useGetMutualAdmirationsWithArtworksV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMutualAdmirationsWithArtworksV2Query, Types.GetMutualAdmirationsWithArtworksV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMutualAdmirationsWithArtworksV2Query, Types.GetMutualAdmirationsWithArtworksV2QueryVariables>(GetMutualAdmirationsWithArtworksV2Document, options);
        }
export type GetMutualAdmirationsWithArtworksV2QueryHookResult = ReturnType<typeof useGetMutualAdmirationsWithArtworksV2Query>;
export type GetMutualAdmirationsWithArtworksV2LazyQueryHookResult = ReturnType<typeof useGetMutualAdmirationsWithArtworksV2LazyQuery>;
export type GetMutualAdmirationsWithArtworksV2QueryResult = Apollo.QueryResult<Types.GetMutualAdmirationsWithArtworksV2Query, Types.GetMutualAdmirationsWithArtworksV2QueryVariables>;
export const GetRecognitionsInfoWithArtworksDocument = gql`
    query getRecognitionsInfoWithArtworks($context_id: [bigint!]) {
  Artists: Context(where: {id: {_in: $context_id}}) {
    id
    profile {
      id
      avatar
      title
      handle
      first_name
      last_name
      context {
        type
        locations {
          city
          country
        }
      }
    }
  }
  Artworks: artist_cardgrid_artworks_view(
    where: {rank: {_lte: 3}, artist_context_id: {_in: $context_id}}
  ) {
    id
    artist_context_id
    thumbnail_metadata_id
    rank
  }
}
    `;

/**
 * __useGetRecognitionsInfoWithArtworksQuery__
 *
 * To run a query within a React component, call `useGetRecognitionsInfoWithArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecognitionsInfoWithArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecognitionsInfoWithArtworksQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetRecognitionsInfoWithArtworksQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetRecognitionsInfoWithArtworksQuery, Types.GetRecognitionsInfoWithArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRecognitionsInfoWithArtworksQuery, Types.GetRecognitionsInfoWithArtworksQueryVariables>(GetRecognitionsInfoWithArtworksDocument, options);
      }
export function useGetRecognitionsInfoWithArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRecognitionsInfoWithArtworksQuery, Types.GetRecognitionsInfoWithArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRecognitionsInfoWithArtworksQuery, Types.GetRecognitionsInfoWithArtworksQueryVariables>(GetRecognitionsInfoWithArtworksDocument, options);
        }
export type GetRecognitionsInfoWithArtworksQueryHookResult = ReturnType<typeof useGetRecognitionsInfoWithArtworksQuery>;
export type GetRecognitionsInfoWithArtworksLazyQueryHookResult = ReturnType<typeof useGetRecognitionsInfoWithArtworksLazyQuery>;
export type GetRecognitionsInfoWithArtworksQueryResult = Apollo.QueryResult<Types.GetRecognitionsInfoWithArtworksQuery, Types.GetRecognitionsInfoWithArtworksQueryVariables>;
export const GetContextIdByHandleDocument = gql`
    query getContextIdByHandle($handle: String!) {
  Context(where: {profile: {handle: {_ilike: $handle}}}) {
    id
  }
}
    `;

/**
 * __useGetContextIdByHandleQuery__
 *
 * To run a query within a React component, call `useGetContextIdByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContextIdByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContextIdByHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetContextIdByHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetContextIdByHandleQuery, Types.GetContextIdByHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetContextIdByHandleQuery, Types.GetContextIdByHandleQueryVariables>(GetContextIdByHandleDocument, options);
      }
export function useGetContextIdByHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetContextIdByHandleQuery, Types.GetContextIdByHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetContextIdByHandleQuery, Types.GetContextIdByHandleQueryVariables>(GetContextIdByHandleDocument, options);
        }
export type GetContextIdByHandleQueryHookResult = ReturnType<typeof useGetContextIdByHandleQuery>;
export type GetContextIdByHandleLazyQueryHookResult = ReturnType<typeof useGetContextIdByHandleLazyQuery>;
export type GetContextIdByHandleQueryResult = Apollo.QueryResult<Types.GetContextIdByHandleQuery, Types.GetContextIdByHandleQueryVariables>;
export const GetProfileFollowingDocument = gql`
    query getProfileFollowing($context_id: bigint!, $offset: Int = 0, $limit: Int!, $additionalConditions: Social_graph_followers_view_bool_exp!) {
  Social_graph_followers_view(
    where: {_and: [{state: {_eq: "ACTIVE"}}, {context_id: {_eq: $context_id}}, $additionalConditions]}
    order_by: [{selected_order: asc}, {updated_at: desc}]
    offset: $offset
    limit: $limit
  ) {
    context_id
    target_context_id
    selected_order
    updated_at
  }
}
    `;

/**
 * __useGetProfileFollowingQuery__
 *
 * To run a query within a React component, call `useGetProfileFollowingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileFollowingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileFollowingQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      additionalConditions: // value for 'additionalConditions'
 *   },
 * });
 */
export function useGetProfileFollowingQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileFollowingQuery, Types.GetProfileFollowingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileFollowingQuery, Types.GetProfileFollowingQueryVariables>(GetProfileFollowingDocument, options);
      }
export function useGetProfileFollowingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileFollowingQuery, Types.GetProfileFollowingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileFollowingQuery, Types.GetProfileFollowingQueryVariables>(GetProfileFollowingDocument, options);
        }
export type GetProfileFollowingQueryHookResult = ReturnType<typeof useGetProfileFollowingQuery>;
export type GetProfileFollowingLazyQueryHookResult = ReturnType<typeof useGetProfileFollowingLazyQuery>;
export type GetProfileFollowingQueryResult = Apollo.QueryResult<Types.GetProfileFollowingQuery, Types.GetProfileFollowingQueryVariables>;
export const GetProfileFollowersDocument = gql`
    query getProfileFollowers($context_id: bigint!, $offset: Int = 0, $limit: Int!, $additionalConditions: Social_graph_followers_view_bool_exp!) {
  Social_graph_followers_view(
    where: {_and: [{state: {_eq: "ACTIVE"}}, {target_context_id: {_eq: $context_id}}, $additionalConditions]}
    order_by: [{selected_order: asc}, {updated_at: desc}]
    offset: $offset
    limit: $limit
  ) {
    context_id
    target_context_id
    selected_order
    updated_at
  }
}
    `;

/**
 * __useGetProfileFollowersQuery__
 *
 * To run a query within a React component, call `useGetProfileFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileFollowersQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      additionalConditions: // value for 'additionalConditions'
 *   },
 * });
 */
export function useGetProfileFollowersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileFollowersQuery, Types.GetProfileFollowersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileFollowersQuery, Types.GetProfileFollowersQueryVariables>(GetProfileFollowersDocument, options);
      }
export function useGetProfileFollowersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileFollowersQuery, Types.GetProfileFollowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileFollowersQuery, Types.GetProfileFollowersQueryVariables>(GetProfileFollowersDocument, options);
        }
export type GetProfileFollowersQueryHookResult = ReturnType<typeof useGetProfileFollowersQuery>;
export type GetProfileFollowersLazyQueryHookResult = ReturnType<typeof useGetProfileFollowersLazyQuery>;
export type GetProfileFollowersQueryResult = Apollo.QueryResult<Types.GetProfileFollowersQuery, Types.GetProfileFollowersQueryVariables>;
export const GetKaleidoCardByHandleV2Document = gql`
    query GetKaleidoCardByHandleV2($handle: String!) {
  Profile(where: {handle: {_ilike: $handle}, context: {type: {_eq: ARTIST}}}) {
    id
    avatar
    banner
    kard_banner
    audio_download_link
    Valid_profile_statement_audio_syntheses {
      audio_id
      profile_id
      language
    }
    handle
    title
    quote
    profile_theme
    audio_metadata {
      file_name
      extra
    }
    context {
      locations {
        city
        country
      }
      id
      profile_social_links(where: {status: {_eq: ACTIVE}}, order_by: {order: asc}) {
        created_at
        URL
        platform
        id
        custom_link
        status
        type
        is_pinned
        order
      }
    }
  }
}
    `;

/**
 * __useGetKaleidoCardByHandleV2Query__
 *
 * To run a query within a React component, call `useGetKaleidoCardByHandleV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetKaleidoCardByHandleV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKaleidoCardByHandleV2Query({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetKaleidoCardByHandleV2Query(baseOptions: Apollo.QueryHookOptions<Types.GetKaleidoCardByHandleV2Query, Types.GetKaleidoCardByHandleV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKaleidoCardByHandleV2Query, Types.GetKaleidoCardByHandleV2QueryVariables>(GetKaleidoCardByHandleV2Document, options);
      }
export function useGetKaleidoCardByHandleV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKaleidoCardByHandleV2Query, Types.GetKaleidoCardByHandleV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKaleidoCardByHandleV2Query, Types.GetKaleidoCardByHandleV2QueryVariables>(GetKaleidoCardByHandleV2Document, options);
        }
export type GetKaleidoCardByHandleV2QueryHookResult = ReturnType<typeof useGetKaleidoCardByHandleV2Query>;
export type GetKaleidoCardByHandleV2LazyQueryHookResult = ReturnType<typeof useGetKaleidoCardByHandleV2LazyQuery>;
export type GetKaleidoCardByHandleV2QueryResult = Apollo.QueryResult<Types.GetKaleidoCardByHandleV2Query, Types.GetKaleidoCardByHandleV2QueryVariables>;
export const GetCountOfAvailableArtworksOfArtistDocument = gql`
    query getCountOfAvailableArtworksOfArtist($id: bigint!) {
  aggregateProxy(
    type: AvailableArtworksOfArtist
    variables: {artist_context_id: $id}
  )
}
    `;

/**
 * __useGetCountOfAvailableArtworksOfArtistQuery__
 *
 * To run a query within a React component, call `useGetCountOfAvailableArtworksOfArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountOfAvailableArtworksOfArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountOfAvailableArtworksOfArtistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCountOfAvailableArtworksOfArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCountOfAvailableArtworksOfArtistQuery, Types.GetCountOfAvailableArtworksOfArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountOfAvailableArtworksOfArtistQuery, Types.GetCountOfAvailableArtworksOfArtistQueryVariables>(GetCountOfAvailableArtworksOfArtistDocument, options);
      }
export function useGetCountOfAvailableArtworksOfArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountOfAvailableArtworksOfArtistQuery, Types.GetCountOfAvailableArtworksOfArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountOfAvailableArtworksOfArtistQuery, Types.GetCountOfAvailableArtworksOfArtistQueryVariables>(GetCountOfAvailableArtworksOfArtistDocument, options);
        }
export type GetCountOfAvailableArtworksOfArtistQueryHookResult = ReturnType<typeof useGetCountOfAvailableArtworksOfArtistQuery>;
export type GetCountOfAvailableArtworksOfArtistLazyQueryHookResult = ReturnType<typeof useGetCountOfAvailableArtworksOfArtistLazyQuery>;
export type GetCountOfAvailableArtworksOfArtistQueryResult = Apollo.QueryResult<Types.GetCountOfAvailableArtworksOfArtistQuery, Types.GetCountOfAvailableArtworksOfArtistQueryVariables>;
export const GetLatestAvailableArtworksOfArtistDocument = gql`
    query getLatestAvailableArtworksOfArtist($id: bigint!, $limit: Int!) {
  Artwork(
    order_by: {published_date: desc_nulls_last}
    where: {is_public: {_eq: true}, is_available_sale: {_eq: true}, artist_context_id: {_eq: $id}}
    limit: $limit
  ) {
    thumbnail_metadata_id
    artist_context {
      profile {
        id
        avatar
        handle
      }
    }
    slug
  }
}
    `;

/**
 * __useGetLatestAvailableArtworksOfArtistQuery__
 *
 * To run a query within a React component, call `useGetLatestAvailableArtworksOfArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAvailableArtworksOfArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAvailableArtworksOfArtistQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestAvailableArtworksOfArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLatestAvailableArtworksOfArtistQuery, Types.GetLatestAvailableArtworksOfArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLatestAvailableArtworksOfArtistQuery, Types.GetLatestAvailableArtworksOfArtistQueryVariables>(GetLatestAvailableArtworksOfArtistDocument, options);
      }
export function useGetLatestAvailableArtworksOfArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLatestAvailableArtworksOfArtistQuery, Types.GetLatestAvailableArtworksOfArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLatestAvailableArtworksOfArtistQuery, Types.GetLatestAvailableArtworksOfArtistQueryVariables>(GetLatestAvailableArtworksOfArtistDocument, options);
        }
export type GetLatestAvailableArtworksOfArtistQueryHookResult = ReturnType<typeof useGetLatestAvailableArtworksOfArtistQuery>;
export type GetLatestAvailableArtworksOfArtistLazyQueryHookResult = ReturnType<typeof useGetLatestAvailableArtworksOfArtistLazyQuery>;
export type GetLatestAvailableArtworksOfArtistQueryResult = Apollo.QueryResult<Types.GetLatestAvailableArtworksOfArtistQuery, Types.GetLatestAvailableArtworksOfArtistQueryVariables>;
export const GetCountOfPublishedArtworksOfArtistDocument = gql`
    query getCountOfPublishedArtworksOfArtist($id: bigint!) {
  aggregateProxy(
    type: PublishedArtworksOfArtist
    variables: {artist_context_id: $id}
  )
}
    `;

/**
 * __useGetCountOfPublishedArtworksOfArtistQuery__
 *
 * To run a query within a React component, call `useGetCountOfPublishedArtworksOfArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountOfPublishedArtworksOfArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountOfPublishedArtworksOfArtistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCountOfPublishedArtworksOfArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCountOfPublishedArtworksOfArtistQuery, Types.GetCountOfPublishedArtworksOfArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCountOfPublishedArtworksOfArtistQuery, Types.GetCountOfPublishedArtworksOfArtistQueryVariables>(GetCountOfPublishedArtworksOfArtistDocument, options);
      }
export function useGetCountOfPublishedArtworksOfArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCountOfPublishedArtworksOfArtistQuery, Types.GetCountOfPublishedArtworksOfArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCountOfPublishedArtworksOfArtistQuery, Types.GetCountOfPublishedArtworksOfArtistQueryVariables>(GetCountOfPublishedArtworksOfArtistDocument, options);
        }
export type GetCountOfPublishedArtworksOfArtistQueryHookResult = ReturnType<typeof useGetCountOfPublishedArtworksOfArtistQuery>;
export type GetCountOfPublishedArtworksOfArtistLazyQueryHookResult = ReturnType<typeof useGetCountOfPublishedArtworksOfArtistLazyQuery>;
export type GetCountOfPublishedArtworksOfArtistQueryResult = Apollo.QueryResult<Types.GetCountOfPublishedArtworksOfArtistQuery, Types.GetCountOfPublishedArtworksOfArtistQueryVariables>;
export const GetLatesPublishedArtworksOfArtistDocument = gql`
    query getLatesPublishedArtworksOfArtist($id: bigint!, $limit: Int!) {
  Artwork(
    order_by: {published_date: desc_nulls_last}
    where: {is_public: {_eq: true}, artist_context_id: {_eq: $id}}
    limit: $limit
  ) {
    thumbnail_metadata_id
    artist_context {
      profile {
        id
        avatar
        handle
      }
    }
    slug
  }
}
    `;

/**
 * __useGetLatesPublishedArtworksOfArtistQuery__
 *
 * To run a query within a React component, call `useGetLatesPublishedArtworksOfArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatesPublishedArtworksOfArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatesPublishedArtworksOfArtistQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatesPublishedArtworksOfArtistQuery(baseOptions: Apollo.QueryHookOptions<Types.GetLatesPublishedArtworksOfArtistQuery, Types.GetLatesPublishedArtworksOfArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLatesPublishedArtworksOfArtistQuery, Types.GetLatesPublishedArtworksOfArtistQueryVariables>(GetLatesPublishedArtworksOfArtistDocument, options);
      }
export function useGetLatesPublishedArtworksOfArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLatesPublishedArtworksOfArtistQuery, Types.GetLatesPublishedArtworksOfArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLatesPublishedArtworksOfArtistQuery, Types.GetLatesPublishedArtworksOfArtistQueryVariables>(GetLatesPublishedArtworksOfArtistDocument, options);
        }
export type GetLatesPublishedArtworksOfArtistQueryHookResult = ReturnType<typeof useGetLatesPublishedArtworksOfArtistQuery>;
export type GetLatesPublishedArtworksOfArtistLazyQueryHookResult = ReturnType<typeof useGetLatesPublishedArtworksOfArtistLazyQuery>;
export type GetLatesPublishedArtworksOfArtistQueryResult = Apollo.QueryResult<Types.GetLatesPublishedArtworksOfArtistQuery, Types.GetLatesPublishedArtworksOfArtistQueryVariables>;
export const GetCardGridArtworksDocument = gql`
    query getCardGridArtworks($artist_context_id: bigint!) {
  preferred: Artwork_to_Collection(
    where: {Collection: {type: {_eq: CARD_GRID}, owner_context_id: {_eq: $artist_context_id}}, Artwork: {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $artist_context_id}}}, is_public: {_eq: true}, artist_context_id: {_eq: $artist_context_id}}}
    limit: 3
  ) {
    id
    Artwork {
      id
      is_available_sale
      thumbnail_metadata_id
      artist_context {
        profile {
          id
          avatar
          handle
        }
      }
      slug
    }
  }
  all: Artwork(
    where: {artwork_to_collections: {Collection: {type: {_eq: CREATED}, owner_context_id: {_eq: $artist_context_id}}}, is_public: {_eq: true}, artist_context_id: {_eq: $artist_context_id}}
    limit: 4
    order_by: [{is_available_sale: desc_nulls_last}, {published_date: desc}]
  ) {
    id
    is_available_sale
    primary_image_metadata_id
    thumbnail_metadata_id
    artist_context {
      profile {
        handle
      }
    }
    slug
  }
}
    `;

/**
 * __useGetCardGridArtworksQuery__
 *
 * To run a query within a React component, call `useGetCardGridArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardGridArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardGridArtworksQuery({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useGetCardGridArtworksQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCardGridArtworksQuery, Types.GetCardGridArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCardGridArtworksQuery, Types.GetCardGridArtworksQueryVariables>(GetCardGridArtworksDocument, options);
      }
export function useGetCardGridArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCardGridArtworksQuery, Types.GetCardGridArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCardGridArtworksQuery, Types.GetCardGridArtworksQueryVariables>(GetCardGridArtworksDocument, options);
        }
export type GetCardGridArtworksQueryHookResult = ReturnType<typeof useGetCardGridArtworksQuery>;
export type GetCardGridArtworksLazyQueryHookResult = ReturnType<typeof useGetCardGridArtworksLazyQuery>;
export type GetCardGridArtworksQueryResult = Apollo.QueryResult<Types.GetCardGridArtworksQuery, Types.GetCardGridArtworksQueryVariables>;
export const GetTop6AdmirationsAvatarsByContextIdDocument = gql`
    query getTop6AdmirationsAvatarsByContextId($context_id: bigint!) {
  Social_graph_followers(
    where: {context_id: {_eq: $context_id}, is_mutual: {_eq: true}, state: {_eq: ACTIVE}, Social_graph_followers_Target_Context: {type: {_eq: ARTIST}}}
    order_by: {selected_order: asc}
    limit: 6
  ) {
    id
    Social_graph_followers_Target_Context {
      profile {
        id
        avatar
        title
      }
    }
  }
}
    `;

/**
 * __useGetTop6AdmirationsAvatarsByContextIdQuery__
 *
 * To run a query within a React component, call `useGetTop6AdmirationsAvatarsByContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTop6AdmirationsAvatarsByContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTop6AdmirationsAvatarsByContextIdQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetTop6AdmirationsAvatarsByContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTop6AdmirationsAvatarsByContextIdQuery, Types.GetTop6AdmirationsAvatarsByContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTop6AdmirationsAvatarsByContextIdQuery, Types.GetTop6AdmirationsAvatarsByContextIdQueryVariables>(GetTop6AdmirationsAvatarsByContextIdDocument, options);
      }
export function useGetTop6AdmirationsAvatarsByContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTop6AdmirationsAvatarsByContextIdQuery, Types.GetTop6AdmirationsAvatarsByContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTop6AdmirationsAvatarsByContextIdQuery, Types.GetTop6AdmirationsAvatarsByContextIdQueryVariables>(GetTop6AdmirationsAvatarsByContextIdDocument, options);
        }
export type GetTop6AdmirationsAvatarsByContextIdQueryHookResult = ReturnType<typeof useGetTop6AdmirationsAvatarsByContextIdQuery>;
export type GetTop6AdmirationsAvatarsByContextIdLazyQueryHookResult = ReturnType<typeof useGetTop6AdmirationsAvatarsByContextIdLazyQuery>;
export type GetTop6AdmirationsAvatarsByContextIdQueryResult = Apollo.QueryResult<Types.GetTop6AdmirationsAvatarsByContextIdQuery, Types.GetTop6AdmirationsAvatarsByContextIdQueryVariables>;
export const GetTop6AdmirationsByContextIdV2Document = gql`
    query getTop6AdmirationsByContextIdV2($context_id: bigint!) {
  Social_graph_followers(
    where: {context_id: {_eq: $context_id}, is_mutual: {_eq: true}, Social_graph_followers_Target_Context: {type: {_eq: ARTIST}}}
    order_by: {selected_order: asc}
    limit: 6
  ) {
    ...FollowerFieldsWithArtworks
  }
}
    ${FollowerFieldsWithArtworksFragmentDoc}`;

/**
 * __useGetTop6AdmirationsByContextIdV2Query__
 *
 * To run a query within a React component, call `useGetTop6AdmirationsByContextIdV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetTop6AdmirationsByContextIdV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTop6AdmirationsByContextIdV2Query({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetTop6AdmirationsByContextIdV2Query(baseOptions: Apollo.QueryHookOptions<Types.GetTop6AdmirationsByContextIdV2Query, Types.GetTop6AdmirationsByContextIdV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTop6AdmirationsByContextIdV2Query, Types.GetTop6AdmirationsByContextIdV2QueryVariables>(GetTop6AdmirationsByContextIdV2Document, options);
      }
export function useGetTop6AdmirationsByContextIdV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTop6AdmirationsByContextIdV2Query, Types.GetTop6AdmirationsByContextIdV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTop6AdmirationsByContextIdV2Query, Types.GetTop6AdmirationsByContextIdV2QueryVariables>(GetTop6AdmirationsByContextIdV2Document, options);
        }
export type GetTop6AdmirationsByContextIdV2QueryHookResult = ReturnType<typeof useGetTop6AdmirationsByContextIdV2Query>;
export type GetTop6AdmirationsByContextIdV2LazyQueryHookResult = ReturnType<typeof useGetTop6AdmirationsByContextIdV2LazyQuery>;
export type GetTop6AdmirationsByContextIdV2QueryResult = Apollo.QueryResult<Types.GetTop6AdmirationsByContextIdV2Query, Types.GetTop6AdmirationsByContextIdV2QueryVariables>;
export const GetAdmirationsQuantityDocument = gql`
    query getAdmirationsQuantity($context_id: bigint!) {
  aggregateProxy(
    type: MutualRecognitionsOfArtist
    variables: {context_id: $context_id}
  )
}
    `;

/**
 * __useGetAdmirationsQuantityQuery__
 *
 * To run a query within a React component, call `useGetAdmirationsQuantityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdmirationsQuantityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdmirationsQuantityQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetAdmirationsQuantityQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAdmirationsQuantityQuery, Types.GetAdmirationsQuantityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAdmirationsQuantityQuery, Types.GetAdmirationsQuantityQueryVariables>(GetAdmirationsQuantityDocument, options);
      }
export function useGetAdmirationsQuantityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAdmirationsQuantityQuery, Types.GetAdmirationsQuantityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAdmirationsQuantityQuery, Types.GetAdmirationsQuantityQueryVariables>(GetAdmirationsQuantityDocument, options);
        }
export type GetAdmirationsQuantityQueryHookResult = ReturnType<typeof useGetAdmirationsQuantityQuery>;
export type GetAdmirationsQuantityLazyQueryHookResult = ReturnType<typeof useGetAdmirationsQuantityLazyQuery>;
export type GetAdmirationsQuantityQueryResult = Apollo.QueryResult<Types.GetAdmirationsQuantityQuery, Types.GetAdmirationsQuantityQueryVariables>;
export const GetArtistCollectionsDocument = gql`
    query getArtistCollections($artist_context_id: bigint!) {
  Artist_Collection_to_Artist_Context(
    where: {context_id: {_eq: $artist_context_id}, deleted_at: {_is_null: true}, artist_Collection: {type: {_eq: virtual_exhibition}}}
  ) {
    artist_Collection {
      created_at
      deleted_at
      title
      internal_url
      thumbnail_metadata_id
      id
      thumbnail_metadata {
        storage_path
      }
    }
  }
}
    `;

/**
 * __useGetArtistCollectionsQuery__
 *
 * To run a query within a React component, call `useGetArtistCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistCollectionsQuery({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useGetArtistCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistCollectionsQuery, Types.GetArtistCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistCollectionsQuery, Types.GetArtistCollectionsQueryVariables>(GetArtistCollectionsDocument, options);
      }
export function useGetArtistCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistCollectionsQuery, Types.GetArtistCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistCollectionsQuery, Types.GetArtistCollectionsQueryVariables>(GetArtistCollectionsDocument, options);
        }
export type GetArtistCollectionsQueryHookResult = ReturnType<typeof useGetArtistCollectionsQuery>;
export type GetArtistCollectionsLazyQueryHookResult = ReturnType<typeof useGetArtistCollectionsLazyQuery>;
export type GetArtistCollectionsQueryResult = Apollo.QueryResult<Types.GetArtistCollectionsQuery, Types.GetArtistCollectionsQueryVariables>;
export const GetArtistArtPrizesDocument = gql`
    query getArtistArtPrizes($artist_context_id: bigint!) {
  Art_prize_winner(
    where: {artist_context_id: {_eq: $artist_context_id}, _and: {Art_prize_show: {published_at: {_lte: "now()"}}}}
  ) {
    position_type
    created_at
    Art_prize_show {
      title
      subtitle
      prize_show_slug
    }
  }
}
    `;

/**
 * __useGetArtistArtPrizesQuery__
 *
 * To run a query within a React component, call `useGetArtistArtPrizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistArtPrizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistArtPrizesQuery({
 *   variables: {
 *      artist_context_id: // value for 'artist_context_id'
 *   },
 * });
 */
export function useGetArtistArtPrizesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistArtPrizesQuery, Types.GetArtistArtPrizesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistArtPrizesQuery, Types.GetArtistArtPrizesQueryVariables>(GetArtistArtPrizesDocument, options);
      }
export function useGetArtistArtPrizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistArtPrizesQuery, Types.GetArtistArtPrizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistArtPrizesQuery, Types.GetArtistArtPrizesQueryVariables>(GetArtistArtPrizesDocument, options);
        }
export type GetArtistArtPrizesQueryHookResult = ReturnType<typeof useGetArtistArtPrizesQuery>;
export type GetArtistArtPrizesLazyQueryHookResult = ReturnType<typeof useGetArtistArtPrizesLazyQuery>;
export type GetArtistArtPrizesQueryResult = Apollo.QueryResult<Types.GetArtistArtPrizesQuery, Types.GetArtistArtPrizesQueryVariables>;
export const GetFilterDetailsByCollectorIdDocument = gql`
    query GetFilterDetailsByCollectorId($collectorId: bigint) {
  All_artists(
    where: {Context: {Artworks: {is_public: {_eq: true}, artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $collectorId}}}}}}
  ) {
    title
    context_id
  }
  Artwork_details(
    distinct_on: creation_date_year
    where: {artwork: {is_public: {_eq: true}, artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $collectorId}}}}}
  ) {
    creation_date_year
  }
}
    `;

/**
 * __useGetFilterDetailsByCollectorIdQuery__
 *
 * To run a query within a React component, call `useGetFilterDetailsByCollectorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterDetailsByCollectorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterDetailsByCollectorIdQuery({
 *   variables: {
 *      collectorId: // value for 'collectorId'
 *   },
 * });
 */
export function useGetFilterDetailsByCollectorIdQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetFilterDetailsByCollectorIdQuery, Types.GetFilterDetailsByCollectorIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFilterDetailsByCollectorIdQuery, Types.GetFilterDetailsByCollectorIdQueryVariables>(GetFilterDetailsByCollectorIdDocument, options);
      }
export function useGetFilterDetailsByCollectorIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFilterDetailsByCollectorIdQuery, Types.GetFilterDetailsByCollectorIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFilterDetailsByCollectorIdQuery, Types.GetFilterDetailsByCollectorIdQueryVariables>(GetFilterDetailsByCollectorIdDocument, options);
        }
export type GetFilterDetailsByCollectorIdQueryHookResult = ReturnType<typeof useGetFilterDetailsByCollectorIdQuery>;
export type GetFilterDetailsByCollectorIdLazyQueryHookResult = ReturnType<typeof useGetFilterDetailsByCollectorIdLazyQuery>;
export type GetFilterDetailsByCollectorIdQueryResult = Apollo.QueryResult<Types.GetFilterDetailsByCollectorIdQuery, Types.GetFilterDetailsByCollectorIdQueryVariables>;
export const GetCollectorProfileInfoDocument = gql`
    query GetCollectorProfileInfo($handle: String = "") {
  Context(
    where: {profile: {handle: {_ilike: $handle}, is_public: {_neq: PRIVATE}}, type: {_eq: COLLECTOR}}
  ) {
    id
    profile {
      id
      avatar
      first_name
      last_name
      title
      profile_theme
      ...ProfileAppearanceSettings
      avatar_metadata {
        file_name
      }
    }
    locations {
      country
      city
    }
  }
}
    ${ProfileAppearanceSettingsFragmentDoc}`;

/**
 * __useGetCollectorProfileInfoQuery__
 *
 * To run a query within a React component, call `useGetCollectorProfileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorProfileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorProfileInfoQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetCollectorProfileInfoQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCollectorProfileInfoQuery, Types.GetCollectorProfileInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorProfileInfoQuery, Types.GetCollectorProfileInfoQueryVariables>(GetCollectorProfileInfoDocument, options);
      }
export function useGetCollectorProfileInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorProfileInfoQuery, Types.GetCollectorProfileInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorProfileInfoQuery, Types.GetCollectorProfileInfoQueryVariables>(GetCollectorProfileInfoDocument, options);
        }
export type GetCollectorProfileInfoQueryHookResult = ReturnType<typeof useGetCollectorProfileInfoQuery>;
export type GetCollectorProfileInfoLazyQueryHookResult = ReturnType<typeof useGetCollectorProfileInfoLazyQuery>;
export type GetCollectorProfileInfoQueryResult = Apollo.QueryResult<Types.GetCollectorProfileInfoQuery, Types.GetCollectorProfileInfoQueryVariables>;
export const GetCollectorArtistsSidebarDocument = gql`
    query GetCollectorArtistsSidebar($handle: String = "", $limit: Int = 10, $offset: Int = 0) {
  Context(
    limit: $limit
    offset: $offset
    where: {Artworks: {is_public: {_eq: true}, artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context: {profile: {handle: {_eq: $handle}}, type: {_eq: COLLECTOR}}}}}}
  ) {
    id
    profile {
      id
      avatar
      title
      handle
      first_name
      last_name
    }
    locations {
      country
      city
    }
  }
}
    `;

/**
 * __useGetCollectorArtistsSidebarQuery__
 *
 * To run a query within a React component, call `useGetCollectorArtistsSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorArtistsSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorArtistsSidebarQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCollectorArtistsSidebarQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCollectorArtistsSidebarQuery, Types.GetCollectorArtistsSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorArtistsSidebarQuery, Types.GetCollectorArtistsSidebarQueryVariables>(GetCollectorArtistsSidebarDocument, options);
      }
export function useGetCollectorArtistsSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorArtistsSidebarQuery, Types.GetCollectorArtistsSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorArtistsSidebarQuery, Types.GetCollectorArtistsSidebarQueryVariables>(GetCollectorArtistsSidebarDocument, options);
        }
export type GetCollectorArtistsSidebarQueryHookResult = ReturnType<typeof useGetCollectorArtistsSidebarQuery>;
export type GetCollectorArtistsSidebarLazyQueryHookResult = ReturnType<typeof useGetCollectorArtistsSidebarLazyQuery>;
export type GetCollectorArtistsSidebarQueryResult = Apollo.QueryResult<Types.GetCollectorArtistsSidebarQuery, Types.GetCollectorArtistsSidebarQueryVariables>;
export const GetCollectorProfilePageDataDocument = gql`
    query GetCollectorProfilePageData($handle: String = "") {
  collectorData: Context(
    where: {profile: {handle: {_eq: $handle}, is_public: {_neq: PRIVATE}}, type: {_eq: COLLECTOR}}
  ) {
    id
    profile {
      id
      avatar
      first_name
      last_name
      title
      profile_theme
      ...ProfileAppearanceSettings
    }
    locations {
      country
      city
    }
  }
  artistsData: Context(
    where: {Artworks: {is_public: {_eq: true}, artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context: {profile: {handle: {_eq: $handle}}, type: {_eq: COLLECTOR}}}}}}
  ) {
    id
    profile {
      id
      avatar
      title
      handle
      first_name
      last_name
    }
    locations {
      country
      city
    }
  }
}
    ${ProfileAppearanceSettingsFragmentDoc}`;

/**
 * __useGetCollectorProfilePageDataQuery__
 *
 * To run a query within a React component, call `useGetCollectorProfilePageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorProfilePageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorProfilePageDataQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetCollectorProfilePageDataQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCollectorProfilePageDataQuery, Types.GetCollectorProfilePageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorProfilePageDataQuery, Types.GetCollectorProfilePageDataQueryVariables>(GetCollectorProfilePageDataDocument, options);
      }
export function useGetCollectorProfilePageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorProfilePageDataQuery, Types.GetCollectorProfilePageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorProfilePageDataQuery, Types.GetCollectorProfilePageDataQueryVariables>(GetCollectorProfilePageDataDocument, options);
        }
export type GetCollectorProfilePageDataQueryHookResult = ReturnType<typeof useGetCollectorProfilePageDataQuery>;
export type GetCollectorProfilePageDataLazyQueryHookResult = ReturnType<typeof useGetCollectorProfilePageDataLazyQuery>;
export type GetCollectorProfilePageDataQueryResult = Apollo.QueryResult<Types.GetCollectorProfilePageDataQuery, Types.GetCollectorProfilePageDataQueryVariables>;
export const GetNumberOfOwnedArtworksFromArtistsDocument = gql`
    query GetNumberOfOwnedArtworksFromArtists($artist_context_ids: [bigint!]!, $owner_context_id: bigint!) {
  aggregateProxy(
    type: TotalNumberOfPublicArtworksFromArtists
    variables: {artist_context_ids: $artist_context_ids, owner_context_id: $owner_context_id}
  )
}
    `;

/**
 * __useGetNumberOfOwnedArtworksFromArtistsQuery__
 *
 * To run a query within a React component, call `useGetNumberOfOwnedArtworksFromArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNumberOfOwnedArtworksFromArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumberOfOwnedArtworksFromArtistsQuery({
 *   variables: {
 *      artist_context_ids: // value for 'artist_context_ids'
 *      owner_context_id: // value for 'owner_context_id'
 *   },
 * });
 */
export function useGetNumberOfOwnedArtworksFromArtistsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNumberOfOwnedArtworksFromArtistsQuery, Types.GetNumberOfOwnedArtworksFromArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNumberOfOwnedArtworksFromArtistsQuery, Types.GetNumberOfOwnedArtworksFromArtistsQueryVariables>(GetNumberOfOwnedArtworksFromArtistsDocument, options);
      }
export function useGetNumberOfOwnedArtworksFromArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNumberOfOwnedArtworksFromArtistsQuery, Types.GetNumberOfOwnedArtworksFromArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNumberOfOwnedArtworksFromArtistsQuery, Types.GetNumberOfOwnedArtworksFromArtistsQueryVariables>(GetNumberOfOwnedArtworksFromArtistsDocument, options);
        }
export type GetNumberOfOwnedArtworksFromArtistsQueryHookResult = ReturnType<typeof useGetNumberOfOwnedArtworksFromArtistsQuery>;
export type GetNumberOfOwnedArtworksFromArtistsLazyQueryHookResult = ReturnType<typeof useGetNumberOfOwnedArtworksFromArtistsLazyQuery>;
export type GetNumberOfOwnedArtworksFromArtistsQueryResult = Apollo.QueryResult<Types.GetNumberOfOwnedArtworksFromArtistsQuery, Types.GetNumberOfOwnedArtworksFromArtistsQueryVariables>;
export const GetCollectorArtworkDocument = gql`
    query GetCollectorArtwork($ownerContextId: bigint!, $where: Artwork_bool_exp = {}, $limit: Int = 10) {
  Artwork(
    limit: $limit
    order_by: {id: asc}
    where: {_and: [$where, {is_public: {_eq: true}, artwork_to_collections: {Collection: {type: {_eq: OWNED}, owner_context_id: {_eq: $ownerContextId}}}}]}
  ) {
    id
    artist_context {
      id
      profile_id
    }
  }
}
    `;

/**
 * __useGetCollectorArtworkQuery__
 *
 * To run a query within a React component, call `useGetCollectorArtworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorArtworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorArtworkQuery({
 *   variables: {
 *      ownerContextId: // value for 'ownerContextId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCollectorArtworkQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorArtworkQuery, Types.GetCollectorArtworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorArtworkQuery, Types.GetCollectorArtworkQueryVariables>(GetCollectorArtworkDocument, options);
      }
export function useGetCollectorArtworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorArtworkQuery, Types.GetCollectorArtworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorArtworkQuery, Types.GetCollectorArtworkQueryVariables>(GetCollectorArtworkDocument, options);
        }
export type GetCollectorArtworkQueryHookResult = ReturnType<typeof useGetCollectorArtworkQuery>;
export type GetCollectorArtworkLazyQueryHookResult = ReturnType<typeof useGetCollectorArtworkLazyQuery>;
export type GetCollectorArtworkQueryResult = Apollo.QueryResult<Types.GetCollectorArtworkQuery, Types.GetCollectorArtworkQueryVariables>;
export const GetCollectorArtworksV2Document = gql`
    query getCollectorArtworksV2($artworksId: [bigint!]!) {
  artworks: Print_artwork_info(
    where: {artwork_id: {_in: $artworksId}, is_public: {_eq: true}, collection_type: {_eq: "OWNED"}}
  ) {
    artworkId: artwork_id
    artistContextId: artist_context_id
    handle
    artistTitle: artist_title
    firstName: first_name
    lastName: last_name
    avatar
    city
    country
    artworkType: artwork_type
    discipline
    title
    slug
    lastTransactionId: last_transaction_id
    isAvailableSale: is_available_sale
    primaryImageMetadataId: primary_image_metadata_id
    thumbnailMetadataId: thumbnail_metadata_id
    measuringUnit: measuring_unit
    width
    height
    depth
    profileId: profile_id
    originalArtworkId: original_artwork_id
  }
  prizes: Artwork_prize_info(where: {artwork_id: {_in: $artworksId}}) {
    artworkPrizeIds: artwork_prize_ids
    artPrizeShowId: art_prize_show_id
    artworkId: artwork_id
    title
    subtitle
    slug: prize_show_slug
    position: position_type
  }
}
    `;

/**
 * __useGetCollectorArtworksV2Query__
 *
 * To run a query within a React component, call `useGetCollectorArtworksV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorArtworksV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorArtworksV2Query({
 *   variables: {
 *      artworksId: // value for 'artworksId'
 *   },
 * });
 */
export function useGetCollectorArtworksV2Query(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorArtworksV2Query, Types.GetCollectorArtworksV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorArtworksV2Query, Types.GetCollectorArtworksV2QueryVariables>(GetCollectorArtworksV2Document, options);
      }
export function useGetCollectorArtworksV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorArtworksV2Query, Types.GetCollectorArtworksV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorArtworksV2Query, Types.GetCollectorArtworksV2QueryVariables>(GetCollectorArtworksV2Document, options);
        }
export type GetCollectorArtworksV2QueryHookResult = ReturnType<typeof useGetCollectorArtworksV2Query>;
export type GetCollectorArtworksV2LazyQueryHookResult = ReturnType<typeof useGetCollectorArtworksV2LazyQuery>;
export type GetCollectorArtworksV2QueryResult = Apollo.QueryResult<Types.GetCollectorArtworksV2Query, Types.GetCollectorArtworksV2QueryVariables>;
export const GetCollectorArtworksV2ByDateDocument = gql`
    query getCollectorArtworksV2ByDate($collectorId: bigint!, $limit: Int = 10, $where: Print_artwork_collector_listing_bool_exp!) {
  Print_artwork_collector_listing(
    where: {_and: [$where, {owner_context_id: {_eq: $collectorId}}]}
    limit: $limit
    order_by: {collected_at: asc}
  ) {
    artwork_id
    collected_at
  }
}
    `;

/**
 * __useGetCollectorArtworksV2ByDateQuery__
 *
 * To run a query within a React component, call `useGetCollectorArtworksV2ByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorArtworksV2ByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorArtworksV2ByDateQuery({
 *   variables: {
 *      collectorId: // value for 'collectorId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCollectorArtworksV2ByDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorArtworksV2ByDateQuery, Types.GetCollectorArtworksV2ByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorArtworksV2ByDateQuery, Types.GetCollectorArtworksV2ByDateQueryVariables>(GetCollectorArtworksV2ByDateDocument, options);
      }
export function useGetCollectorArtworksV2ByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorArtworksV2ByDateQuery, Types.GetCollectorArtworksV2ByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorArtworksV2ByDateQuery, Types.GetCollectorArtworksV2ByDateQueryVariables>(GetCollectorArtworksV2ByDateDocument, options);
        }
export type GetCollectorArtworksV2ByDateQueryHookResult = ReturnType<typeof useGetCollectorArtworksV2ByDateQuery>;
export type GetCollectorArtworksV2ByDateLazyQueryHookResult = ReturnType<typeof useGetCollectorArtworksV2ByDateLazyQuery>;
export type GetCollectorArtworksV2ByDateQueryResult = Apollo.QueryResult<Types.GetCollectorArtworksV2ByDateQuery, Types.GetCollectorArtworksV2ByDateQueryVariables>;
export const GetCollectorArtworksV2ByLeastRecentDocument = gql`
    query getCollectorArtworksV2ByLeastRecent($collectorId: bigint!, $limit: Int = 10, $where: Print_artwork_collector_listing_bool_exp!) {
  Print_artwork_collector_listing(
    where: {_and: [$where, {owner_context_id: {_eq: $collectorId}}]}
    limit: $limit
    order_by: {collected_at: asc}
  ) {
    artwork_id
    collected_at
  }
}
    `;

/**
 * __useGetCollectorArtworksV2ByLeastRecentQuery__
 *
 * To run a query within a React component, call `useGetCollectorArtworksV2ByLeastRecentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorArtworksV2ByLeastRecentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorArtworksV2ByLeastRecentQuery({
 *   variables: {
 *      collectorId: // value for 'collectorId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCollectorArtworksV2ByLeastRecentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorArtworksV2ByLeastRecentQuery, Types.GetCollectorArtworksV2ByLeastRecentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorArtworksV2ByLeastRecentQuery, Types.GetCollectorArtworksV2ByLeastRecentQueryVariables>(GetCollectorArtworksV2ByLeastRecentDocument, options);
      }
export function useGetCollectorArtworksV2ByLeastRecentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorArtworksV2ByLeastRecentQuery, Types.GetCollectorArtworksV2ByLeastRecentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorArtworksV2ByLeastRecentQuery, Types.GetCollectorArtworksV2ByLeastRecentQueryVariables>(GetCollectorArtworksV2ByLeastRecentDocument, options);
        }
export type GetCollectorArtworksV2ByLeastRecentQueryHookResult = ReturnType<typeof useGetCollectorArtworksV2ByLeastRecentQuery>;
export type GetCollectorArtworksV2ByLeastRecentLazyQueryHookResult = ReturnType<typeof useGetCollectorArtworksV2ByLeastRecentLazyQuery>;
export type GetCollectorArtworksV2ByLeastRecentQueryResult = Apollo.QueryResult<Types.GetCollectorArtworksV2ByLeastRecentQuery, Types.GetCollectorArtworksV2ByLeastRecentQueryVariables>;
export const GetCollectorArtworksV2ByMostRecentDocument = gql`
    query getCollectorArtworksV2ByMostRecent($collectorId: bigint!, $limit: Int = 10, $where: Print_artwork_collector_listing_bool_exp!) {
  Print_artwork_collector_listing(
    where: {_and: [$where, {owner_context_id: {_eq: $collectorId}}]}
    limit: $limit
    order_by: {collected_at: desc}
  ) {
    artwork_id
    collected_at
  }
}
    `;

/**
 * __useGetCollectorArtworksV2ByMostRecentQuery__
 *
 * To run a query within a React component, call `useGetCollectorArtworksV2ByMostRecentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorArtworksV2ByMostRecentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorArtworksV2ByMostRecentQuery({
 *   variables: {
 *      collectorId: // value for 'collectorId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCollectorArtworksV2ByMostRecentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorArtworksV2ByMostRecentQuery, Types.GetCollectorArtworksV2ByMostRecentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorArtworksV2ByMostRecentQuery, Types.GetCollectorArtworksV2ByMostRecentQueryVariables>(GetCollectorArtworksV2ByMostRecentDocument, options);
      }
export function useGetCollectorArtworksV2ByMostRecentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorArtworksV2ByMostRecentQuery, Types.GetCollectorArtworksV2ByMostRecentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorArtworksV2ByMostRecentQuery, Types.GetCollectorArtworksV2ByMostRecentQueryVariables>(GetCollectorArtworksV2ByMostRecentDocument, options);
        }
export type GetCollectorArtworksV2ByMostRecentQueryHookResult = ReturnType<typeof useGetCollectorArtworksV2ByMostRecentQuery>;
export type GetCollectorArtworksV2ByMostRecentLazyQueryHookResult = ReturnType<typeof useGetCollectorArtworksV2ByMostRecentLazyQuery>;
export type GetCollectorArtworksV2ByMostRecentQueryResult = Apollo.QueryResult<Types.GetCollectorArtworksV2ByMostRecentQuery, Types.GetCollectorArtworksV2ByMostRecentQueryVariables>;
export const GetCollectorArtworksV2ByCollectorSelectionDocument = gql`
    query getCollectorArtworksV2ByCollectorSelection($collectorId: bigint!, $limit: Int = 10, $where: Print_artwork_collector_listing_bool_exp!) {
  Print_artwork_collector_listing(
    where: {_and: [$where, {owner_context_id: {_eq: $collectorId}, is_public: {_eq: true}}]}
    limit: $limit
    order_by: [{artwork_collection_order: asc}, {collected_at: asc}]
  ) {
    artwork_id
    collected_at
  }
}
    `;

/**
 * __useGetCollectorArtworksV2ByCollectorSelectionQuery__
 *
 * To run a query within a React component, call `useGetCollectorArtworksV2ByCollectorSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorArtworksV2ByCollectorSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorArtworksV2ByCollectorSelectionQuery({
 *   variables: {
 *      collectorId: // value for 'collectorId'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCollectorArtworksV2ByCollectorSelectionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorArtworksV2ByCollectorSelectionQuery, Types.GetCollectorArtworksV2ByCollectorSelectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorArtworksV2ByCollectorSelectionQuery, Types.GetCollectorArtworksV2ByCollectorSelectionQueryVariables>(GetCollectorArtworksV2ByCollectorSelectionDocument, options);
      }
export function useGetCollectorArtworksV2ByCollectorSelectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorArtworksV2ByCollectorSelectionQuery, Types.GetCollectorArtworksV2ByCollectorSelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorArtworksV2ByCollectorSelectionQuery, Types.GetCollectorArtworksV2ByCollectorSelectionQueryVariables>(GetCollectorArtworksV2ByCollectorSelectionDocument, options);
        }
export type GetCollectorArtworksV2ByCollectorSelectionQueryHookResult = ReturnType<typeof useGetCollectorArtworksV2ByCollectorSelectionQuery>;
export type GetCollectorArtworksV2ByCollectorSelectionLazyQueryHookResult = ReturnType<typeof useGetCollectorArtworksV2ByCollectorSelectionLazyQuery>;
export type GetCollectorArtworksV2ByCollectorSelectionQueryResult = Apollo.QueryResult<Types.GetCollectorArtworksV2ByCollectorSelectionQuery, Types.GetCollectorArtworksV2ByCollectorSelectionQueryVariables>;
export const GetCollectorArtistsArtworksDocument = gql`
    query getCollectorArtistsArtworks($contextId: [bigint!]) {
  artist_cardgrid_artworks_view(
    where: {rank: {_lte: 3}, artist_context_id: {_in: $contextId}}
  ) {
    id
    artist_context_id
    thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetCollectorArtistsArtworksQuery__
 *
 * To run a query within a React component, call `useGetCollectorArtistsArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorArtistsArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorArtistsArtworksQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetCollectorArtistsArtworksQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCollectorArtistsArtworksQuery, Types.GetCollectorArtistsArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorArtistsArtworksQuery, Types.GetCollectorArtistsArtworksQueryVariables>(GetCollectorArtistsArtworksDocument, options);
      }
export function useGetCollectorArtistsArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorArtistsArtworksQuery, Types.GetCollectorArtistsArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorArtistsArtworksQuery, Types.GetCollectorArtistsArtworksQueryVariables>(GetCollectorArtistsArtworksDocument, options);
        }
export type GetCollectorArtistsArtworksQueryHookResult = ReturnType<typeof useGetCollectorArtistsArtworksQuery>;
export type GetCollectorArtistsArtworksLazyQueryHookResult = ReturnType<typeof useGetCollectorArtistsArtworksLazyQuery>;
export type GetCollectorArtistsArtworksQueryResult = Apollo.QueryResult<Types.GetCollectorArtistsArtworksQuery, Types.GetCollectorArtistsArtworksQueryVariables>;
export const GetCollectorFiltersUniqueValuesDocument = gql`
    query getCollectorFiltersUniqueValues($collectorContextId: bigint!) {
  artists: Print_artwork_collector_listing(
    where: {owner_context_id: {_eq: $collectorContextId}}
    distinct_on: artist_context_id
  ) {
    artistContextId: artist_context_id
    artistTitle: artist_title
  }
  createdYears: Print_artwork_collector_listing(
    where: {owner_context_id: {_eq: $collectorContextId}}
    distinct_on: creation_date_year
  ) {
    creationDateYear: creation_date_year
  }
}
    `;

/**
 * __useGetCollectorFiltersUniqueValuesQuery__
 *
 * To run a query within a React component, call `useGetCollectorFiltersUniqueValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorFiltersUniqueValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorFiltersUniqueValuesQuery({
 *   variables: {
 *      collectorContextId: // value for 'collectorContextId'
 *   },
 * });
 */
export function useGetCollectorFiltersUniqueValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorFiltersUniqueValuesQuery, Types.GetCollectorFiltersUniqueValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorFiltersUniqueValuesQuery, Types.GetCollectorFiltersUniqueValuesQueryVariables>(GetCollectorFiltersUniqueValuesDocument, options);
      }
export function useGetCollectorFiltersUniqueValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorFiltersUniqueValuesQuery, Types.GetCollectorFiltersUniqueValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorFiltersUniqueValuesQuery, Types.GetCollectorFiltersUniqueValuesQueryVariables>(GetCollectorFiltersUniqueValuesDocument, options);
        }
export type GetCollectorFiltersUniqueValuesQueryHookResult = ReturnType<typeof useGetCollectorFiltersUniqueValuesQuery>;
export type GetCollectorFiltersUniqueValuesLazyQueryHookResult = ReturnType<typeof useGetCollectorFiltersUniqueValuesLazyQuery>;
export type GetCollectorFiltersUniqueValuesQueryResult = Apollo.QueryResult<Types.GetCollectorFiltersUniqueValuesQuery, Types.GetCollectorFiltersUniqueValuesQueryVariables>;
export const GetTotalArtworksPrintsByCollectorDocument = gql`
    query getTotalArtworksPrintsByCollector($collectorId: bigint!) {
  aggregateProxy(
    type: TotalArtworksPrintsByCollector
    variables: {context_id: $collectorId}
  )
}
    `;

/**
 * __useGetTotalArtworksPrintsByCollectorQuery__
 *
 * To run a query within a React component, call `useGetTotalArtworksPrintsByCollectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalArtworksPrintsByCollectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalArtworksPrintsByCollectorQuery({
 *   variables: {
 *      collectorId: // value for 'collectorId'
 *   },
 * });
 */
export function useGetTotalArtworksPrintsByCollectorQuery(baseOptions: Apollo.QueryHookOptions<Types.GetTotalArtworksPrintsByCollectorQuery, Types.GetTotalArtworksPrintsByCollectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTotalArtworksPrintsByCollectorQuery, Types.GetTotalArtworksPrintsByCollectorQueryVariables>(GetTotalArtworksPrintsByCollectorDocument, options);
      }
export function useGetTotalArtworksPrintsByCollectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTotalArtworksPrintsByCollectorQuery, Types.GetTotalArtworksPrintsByCollectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTotalArtworksPrintsByCollectorQuery, Types.GetTotalArtworksPrintsByCollectorQueryVariables>(GetTotalArtworksPrintsByCollectorDocument, options);
        }
export type GetTotalArtworksPrintsByCollectorQueryHookResult = ReturnType<typeof useGetTotalArtworksPrintsByCollectorQuery>;
export type GetTotalArtworksPrintsByCollectorLazyQueryHookResult = ReturnType<typeof useGetTotalArtworksPrintsByCollectorLazyQuery>;
export type GetTotalArtworksPrintsByCollectorQueryResult = Apollo.QueryResult<Types.GetTotalArtworksPrintsByCollectorQuery, Types.GetTotalArtworksPrintsByCollectorQueryVariables>;
export const GetFavoritesArtworksByContextIdDocument = gql`
    query getFavoritesArtworksByContextId($contextId: bigint!, $limit: Int = 11) {
  Collection(where: {type: {_eq: FAVORITE}, owner_context_id: {_eq: $contextId}}) {
    artwork_to_collections(
      order_by: {updated_at: desc, created_at: desc}
      limit: $limit
    ) {
      artwork_id
      Artwork {
        id
        slug
        edition_type
        primary_image_metadata_id
        thumbnail_metadata_id
        is_available_sale
        artist_context {
          id
          profile {
            id
            avatar
            title
            handle
          }
          locations {
            city
            country
          }
        }
        artwork_details {
          creation_date_year
          title
        }
        Artwork_prices {
          price
          scope
          currency
          id
          artwork_to_showroom_details {
            status
            artwork_to_collection {
              collection_id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetFavoritesArtworksByContextIdQuery__
 *
 * To run a query within a React component, call `useGetFavoritesArtworksByContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritesArtworksByContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritesArtworksByContextIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetFavoritesArtworksByContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFavoritesArtworksByContextIdQuery, Types.GetFavoritesArtworksByContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFavoritesArtworksByContextIdQuery, Types.GetFavoritesArtworksByContextIdQueryVariables>(GetFavoritesArtworksByContextIdDocument, options);
      }
export function useGetFavoritesArtworksByContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFavoritesArtworksByContextIdQuery, Types.GetFavoritesArtworksByContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFavoritesArtworksByContextIdQuery, Types.GetFavoritesArtworksByContextIdQueryVariables>(GetFavoritesArtworksByContextIdDocument, options);
        }
export type GetFavoritesArtworksByContextIdQueryHookResult = ReturnType<typeof useGetFavoritesArtworksByContextIdQuery>;
export type GetFavoritesArtworksByContextIdLazyQueryHookResult = ReturnType<typeof useGetFavoritesArtworksByContextIdLazyQuery>;
export type GetFavoritesArtworksByContextIdQueryResult = Apollo.QueryResult<Types.GetFavoritesArtworksByContextIdQuery, Types.GetFavoritesArtworksByContextIdQueryVariables>;
export const GetGalleryInfoByHandleDocument = gql`
    query getGalleryInfoByHandle($galleryHandle: String!) {
  Context(where: {profile: {handle: {_ilike: $galleryHandle}}}) {
    id
    profile {
      id
      title
      description
      avatar
      statement
      quote
      email
      title
      featured_art
      kard_banner
    }
    profile_phones(where: {deleted_at: {_is_null: true}}) {
      phone
    }
    locations {
      id
      city
      country
      zip_code
      address
      location
      location_map_id
    }
    profile_social_links(where: {status: {_eq: ACTIVE}}, order_by: {order: asc}) {
      URL
      platform
      id
    }
  }
}
    `;

/**
 * __useGetGalleryInfoByHandleQuery__
 *
 * To run a query within a React component, call `useGetGalleryInfoByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryInfoByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryInfoByHandleQuery({
 *   variables: {
 *      galleryHandle: // value for 'galleryHandle'
 *   },
 * });
 */
export function useGetGalleryInfoByHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryInfoByHandleQuery, Types.GetGalleryInfoByHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryInfoByHandleQuery, Types.GetGalleryInfoByHandleQueryVariables>(GetGalleryInfoByHandleDocument, options);
      }
export function useGetGalleryInfoByHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryInfoByHandleQuery, Types.GetGalleryInfoByHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryInfoByHandleQuery, Types.GetGalleryInfoByHandleQueryVariables>(GetGalleryInfoByHandleDocument, options);
        }
export type GetGalleryInfoByHandleQueryHookResult = ReturnType<typeof useGetGalleryInfoByHandleQuery>;
export type GetGalleryInfoByHandleLazyQueryHookResult = ReturnType<typeof useGetGalleryInfoByHandleLazyQuery>;
export type GetGalleryInfoByHandleQueryResult = Apollo.QueryResult<Types.GetGalleryInfoByHandleQuery, Types.GetGalleryInfoByHandleQueryVariables>;
export const GetGalleryArtistsDocument = gql`
    query getGalleryArtists($galleryId: bigint!) {
  Profile(
    where: {context: {collaboration_by_artist_id: {gallery_id: {_eq: $galleryId}, artist_status: {_eq: ACCEPTED}, gallery_status: {_eq: ACCEPTED}}}}
  ) {
    id
    title
    handle
    avatar
    context {
      id
      locations {
        country
      }
    }
  }
}
    `;

/**
 * __useGetGalleryArtistsQuery__
 *
 * To run a query within a React component, call `useGetGalleryArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryArtistsQuery({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *   },
 * });
 */
export function useGetGalleryArtistsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryArtistsQuery, Types.GetGalleryArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryArtistsQuery, Types.GetGalleryArtistsQueryVariables>(GetGalleryArtistsDocument, options);
      }
export function useGetGalleryArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryArtistsQuery, Types.GetGalleryArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryArtistsQuery, Types.GetGalleryArtistsQueryVariables>(GetGalleryArtistsDocument, options);
        }
export type GetGalleryArtistsQueryHookResult = ReturnType<typeof useGetGalleryArtistsQuery>;
export type GetGalleryArtistsLazyQueryHookResult = ReturnType<typeof useGetGalleryArtistsLazyQuery>;
export type GetGalleryArtistsQueryResult = Apollo.QueryResult<Types.GetGalleryArtistsQuery, Types.GetGalleryArtistsQueryVariables>;
export const GetPaginatedGalleryActiveArtistsDocument = gql`
    query getPaginatedGalleryActiveArtists($galleryId: bigint!, $limit: Int = 10, $offset: Int = 0) {
  Collaboration(
    limit: $limit
    offset: $offset
    order_by: {artist_context: {profile: {title: asc}}}
    where: {gallery_id: {_eq: $galleryId}, artist_status: {_eq: ACCEPTED}, gallery_status: {_eq: ACCEPTED}}
  ) {
    artist_context {
      id
      profile {
        id
        title
        handle
        avatar
      }
      locations {
        country
        city
      }
    }
  }
}
    `;

/**
 * __useGetPaginatedGalleryActiveArtistsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedGalleryActiveArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedGalleryActiveArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedGalleryActiveArtistsQuery({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPaginatedGalleryActiveArtistsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPaginatedGalleryActiveArtistsQuery, Types.GetPaginatedGalleryActiveArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPaginatedGalleryActiveArtistsQuery, Types.GetPaginatedGalleryActiveArtistsQueryVariables>(GetPaginatedGalleryActiveArtistsDocument, options);
      }
export function useGetPaginatedGalleryActiveArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPaginatedGalleryActiveArtistsQuery, Types.GetPaginatedGalleryActiveArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPaginatedGalleryActiveArtistsQuery, Types.GetPaginatedGalleryActiveArtistsQueryVariables>(GetPaginatedGalleryActiveArtistsDocument, options);
        }
export type GetPaginatedGalleryActiveArtistsQueryHookResult = ReturnType<typeof useGetPaginatedGalleryActiveArtistsQuery>;
export type GetPaginatedGalleryActiveArtistsLazyQueryHookResult = ReturnType<typeof useGetPaginatedGalleryActiveArtistsLazyQuery>;
export type GetPaginatedGalleryActiveArtistsQueryResult = Apollo.QueryResult<Types.GetPaginatedGalleryActiveArtistsQuery, Types.GetPaginatedGalleryActiveArtistsQueryVariables>;
export const GetGalleryActiveArtistsByTitleDocument = gql`
    query getGalleryActiveArtistsByTitle($galleryId: bigint!, $where: Collaboration_bool_exp!, $limit: Int = 10) {
  Collaboration(
    limit: $limit
    order_by: [{artist_context: {profile: {title: asc}}}, {id: desc}]
    where: {_and: [$where, {gallery_id: {_eq: $galleryId}, artist_status: {_eq: ACCEPTED}, gallery_status: {_eq: ACCEPTED}}]}
  ) {
    artist_context_id: artist_id
    artist_context {
      profile {
        title
      }
    }
  }
}
    `;

/**
 * __useGetGalleryActiveArtistsByTitleQuery__
 *
 * To run a query within a React component, call `useGetGalleryActiveArtistsByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryActiveArtistsByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryActiveArtistsByTitleQuery({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetGalleryActiveArtistsByTitleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryActiveArtistsByTitleQuery, Types.GetGalleryActiveArtistsByTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryActiveArtistsByTitleQuery, Types.GetGalleryActiveArtistsByTitleQueryVariables>(GetGalleryActiveArtistsByTitleDocument, options);
      }
export function useGetGalleryActiveArtistsByTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryActiveArtistsByTitleQuery, Types.GetGalleryActiveArtistsByTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryActiveArtistsByTitleQuery, Types.GetGalleryActiveArtistsByTitleQueryVariables>(GetGalleryActiveArtistsByTitleDocument, options);
        }
export type GetGalleryActiveArtistsByTitleQueryHookResult = ReturnType<typeof useGetGalleryActiveArtistsByTitleQuery>;
export type GetGalleryActiveArtistsByTitleLazyQueryHookResult = ReturnType<typeof useGetGalleryActiveArtistsByTitleLazyQuery>;
export type GetGalleryActiveArtistsByTitleQueryResult = Apollo.QueryResult<Types.GetGalleryActiveArtistsByTitleQuery, Types.GetGalleryActiveArtistsByTitleQueryVariables>;
export const GetGalleryActiveArtistsByIdDocument = gql`
    query getGalleryActiveArtistsById($galleryId: bigint!, $where: Collaboration_bool_exp!, $limit: Int = 10) {
  Collaboration(
    limit: $limit
    order_by: {id: asc}
    where: {_and: [$where, {gallery_id: {_eq: $galleryId}, artist_status: {_eq: ACCEPTED}, gallery_status: {_eq: ACCEPTED}}]}
  ) {
    artist_context_id: artist_id
    id
  }
}
    `;

/**
 * __useGetGalleryActiveArtistsByIdQuery__
 *
 * To run a query within a React component, call `useGetGalleryActiveArtistsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryActiveArtistsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryActiveArtistsByIdQuery({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetGalleryActiveArtistsByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryActiveArtistsByIdQuery, Types.GetGalleryActiveArtistsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryActiveArtistsByIdQuery, Types.GetGalleryActiveArtistsByIdQueryVariables>(GetGalleryActiveArtistsByIdDocument, options);
      }
export function useGetGalleryActiveArtistsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryActiveArtistsByIdQuery, Types.GetGalleryActiveArtistsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryActiveArtistsByIdQuery, Types.GetGalleryActiveArtistsByIdQueryVariables>(GetGalleryActiveArtistsByIdDocument, options);
        }
export type GetGalleryActiveArtistsByIdQueryHookResult = ReturnType<typeof useGetGalleryActiveArtistsByIdQuery>;
export type GetGalleryActiveArtistsByIdLazyQueryHookResult = ReturnType<typeof useGetGalleryActiveArtistsByIdLazyQuery>;
export type GetGalleryActiveArtistsByIdQueryResult = Apollo.QueryResult<Types.GetGalleryActiveArtistsByIdQuery, Types.GetGalleryActiveArtistsByIdQueryVariables>;
export const GetGalleryActiveArtistsByDateDocument = gql`
    query getGalleryActiveArtistsByDate($galleryId: bigint!, $where: Collaboration_bool_exp!, $limit: Int = 10) {
  Collaboration(
    limit: $limit
    order_by: {created_at: asc}
    where: {_and: [$where, {gallery_id: {_eq: $galleryId}, artist_status: {_eq: ACCEPTED}, gallery_status: {_eq: ACCEPTED}}]}
  ) {
    artist_context_id: artist_id
    created_at
  }
}
    `;

/**
 * __useGetGalleryActiveArtistsByDateQuery__
 *
 * To run a query within a React component, call `useGetGalleryActiveArtistsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryActiveArtistsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryActiveArtistsByDateQuery({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetGalleryActiveArtistsByDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryActiveArtistsByDateQuery, Types.GetGalleryActiveArtistsByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryActiveArtistsByDateQuery, Types.GetGalleryActiveArtistsByDateQueryVariables>(GetGalleryActiveArtistsByDateDocument, options);
      }
export function useGetGalleryActiveArtistsByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryActiveArtistsByDateQuery, Types.GetGalleryActiveArtistsByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryActiveArtistsByDateQuery, Types.GetGalleryActiveArtistsByDateQueryVariables>(GetGalleryActiveArtistsByDateDocument, options);
        }
export type GetGalleryActiveArtistsByDateQueryHookResult = ReturnType<typeof useGetGalleryActiveArtistsByDateQuery>;
export type GetGalleryActiveArtistsByDateLazyQueryHookResult = ReturnType<typeof useGetGalleryActiveArtistsByDateLazyQuery>;
export type GetGalleryActiveArtistsByDateQueryResult = Apollo.QueryResult<Types.GetGalleryActiveArtistsByDateQuery, Types.GetGalleryActiveArtistsByDateQueryVariables>;
export const GetGalleryArtistsAmountDocument = gql`
    query getGalleryArtistsAmount($galleryId: bigint!) {
  aggregateProxy(
    type: TotalArtistsPartnershipByGallery
    variables: {context_id: $galleryId}
  )
}
    `;

/**
 * __useGetGalleryArtistsAmountQuery__
 *
 * To run a query within a React component, call `useGetGalleryArtistsAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryArtistsAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryArtistsAmountQuery({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *   },
 * });
 */
export function useGetGalleryArtistsAmountQuery(baseOptions: Apollo.QueryHookOptions<Types.GetGalleryArtistsAmountQuery, Types.GetGalleryArtistsAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryArtistsAmountQuery, Types.GetGalleryArtistsAmountQueryVariables>(GetGalleryArtistsAmountDocument, options);
      }
export function useGetGalleryArtistsAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryArtistsAmountQuery, Types.GetGalleryArtistsAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryArtistsAmountQuery, Types.GetGalleryArtistsAmountQueryVariables>(GetGalleryArtistsAmountDocument, options);
        }
export type GetGalleryArtistsAmountQueryHookResult = ReturnType<typeof useGetGalleryArtistsAmountQuery>;
export type GetGalleryArtistsAmountLazyQueryHookResult = ReturnType<typeof useGetGalleryArtistsAmountLazyQuery>;
export type GetGalleryArtistsAmountQueryResult = Apollo.QueryResult<Types.GetGalleryArtistsAmountQuery, Types.GetGalleryArtistsAmountQueryVariables>;
export const GetGalleryArtistsArtworksDocument = gql`
    query getGalleryArtistsArtworks($contextId: [bigint!]) {
  artist_cardgrid_artworks_view(
    where: {rank: {_lte: 3}, artist_context_id: {_in: $contextId}}
  ) {
    id
    artist_context_id
    thumbnail_metadata_id
  }
}
    `;

/**
 * __useGetGalleryArtistsArtworksQuery__
 *
 * To run a query within a React component, call `useGetGalleryArtistsArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGalleryArtistsArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGalleryArtistsArtworksQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetGalleryArtistsArtworksQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetGalleryArtistsArtworksQuery, Types.GetGalleryArtistsArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetGalleryArtistsArtworksQuery, Types.GetGalleryArtistsArtworksQueryVariables>(GetGalleryArtistsArtworksDocument, options);
      }
export function useGetGalleryArtistsArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetGalleryArtistsArtworksQuery, Types.GetGalleryArtistsArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetGalleryArtistsArtworksQuery, Types.GetGalleryArtistsArtworksQueryVariables>(GetGalleryArtistsArtworksDocument, options);
        }
export type GetGalleryArtistsArtworksQueryHookResult = ReturnType<typeof useGetGalleryArtistsArtworksQuery>;
export type GetGalleryArtistsArtworksLazyQueryHookResult = ReturnType<typeof useGetGalleryArtistsArtworksLazyQuery>;
export type GetGalleryArtistsArtworksQueryResult = Apollo.QueryResult<Types.GetGalleryArtistsArtworksQuery, Types.GetGalleryArtistsArtworksQueryVariables>;
export const GetProfileMuseumArtworkInfoDocument = gql`
    query GetProfileMuseumArtworkInfo($callerCtxId: bigint, $artworks: [bigint!]!, $artists: [bigint!]!, $skipRecognitions: Boolean!, $skipFavorites: Boolean!) {
  artworks: Artwork_explore_info(
    where: {_and: [{artwork_id: {_in: $artworks}}, {_or: [{last_transaction_artwork_id: {_is_null: true}}, {last_transaction_artwork_id: {_in: $artworks}}]}]}
  ) {
    artworkId: artwork_id
    thumbnailMetadataId: thumbnail_metadata_id
    primaryMetadataId: primary_image_metadata_id
    artistContextId: artist_context_id
    isAvailableSale: is_available_sale
    title
    width
    height
    depth
    slug
    discipline
    editionType: edition_type
    measuringUnit: measuring_unit
    lastTransactionId: last_transaction_id
    availablePrintAmount: available_print_amount
    editionIsAvailableSale: edition_is_available_sale
    originalArtworkId: original_artwork_id
  }
  artists: Artist_explore_info(where: {artist_context_id: {_in: $artists}}) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    country
    city
  }
  collectedDetails: Artwork_collected_listing(
    where: {artwork_id: {_in: $artworks}}
  ) {
    artworkId: artwork_id
    publishedDate: published_date
    collector: collector_explore_info {
      contextId: collector_context_id
      profileId: collector_profile_id
      title
      handle
      avatar
    }
    gallery: gallery_explore_info {
      contextId: gallery_context_id
      profileId: gallery_profile_id
      title
      handle
      avatar
      country
      city
    }
  }
  recognitions: Social_graph_followers(
    where: {context_id: {_eq: $callerCtxId}, target_context_id: {_in: $artists}}
  ) @skip(if: $skipRecognitions) {
    artistContextId: target_context_id
    state
    isMutual: is_mutual
    naturalOrder: natural_order
    selectedOrder: selected_order
  }
  favorites: Artwork_to_Collection(
    where: {artwork_id: {_in: $artworks}, Collection: {owner_context_id: {_eq: $callerCtxId}, type: {_eq: FAVORITE}}}
  ) @skip(if: $skipFavorites) {
    artworkId: artwork_id
  }
}
    `;

/**
 * __useGetProfileMuseumArtworkInfoQuery__
 *
 * To run a query within a React component, call `useGetProfileMuseumArtworkInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileMuseumArtworkInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileMuseumArtworkInfoQuery({
 *   variables: {
 *      callerCtxId: // value for 'callerCtxId'
 *      artworks: // value for 'artworks'
 *      artists: // value for 'artists'
 *      skipRecognitions: // value for 'skipRecognitions'
 *      skipFavorites: // value for 'skipFavorites'
 *   },
 * });
 */
export function useGetProfileMuseumArtworkInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileMuseumArtworkInfoQuery, Types.GetProfileMuseumArtworkInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileMuseumArtworkInfoQuery, Types.GetProfileMuseumArtworkInfoQueryVariables>(GetProfileMuseumArtworkInfoDocument, options);
      }
export function useGetProfileMuseumArtworkInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileMuseumArtworkInfoQuery, Types.GetProfileMuseumArtworkInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileMuseumArtworkInfoQuery, Types.GetProfileMuseumArtworkInfoQueryVariables>(GetProfileMuseumArtworkInfoDocument, options);
        }
export type GetProfileMuseumArtworkInfoQueryHookResult = ReturnType<typeof useGetProfileMuseumArtworkInfoQuery>;
export type GetProfileMuseumArtworkInfoLazyQueryHookResult = ReturnType<typeof useGetProfileMuseumArtworkInfoLazyQuery>;
export type GetProfileMuseumArtworkInfoQueryResult = Apollo.QueryResult<Types.GetProfileMuseumArtworkInfoQuery, Types.GetProfileMuseumArtworkInfoQueryVariables>;
export const GetProfileMuseumArtworksListingBySizeDocument = gql`
    query GetProfileMuseumArtworksListingBySize($limit: Int!, $appliedSorts: [Museum_artwork_listing_order_by!], $appliedFilters: Museum_artwork_listing_bool_exp!) {
  artworks: Museum_artwork_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonProfileMuseumArtworkListingCursor
    area
  }
}
    ${CommonProfileMuseumArtworkListingCursorFragmentDoc}`;

/**
 * __useGetProfileMuseumArtworksListingBySizeQuery__
 *
 * To run a query within a React component, call `useGetProfileMuseumArtworksListingBySizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileMuseumArtworksListingBySizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileMuseumArtworksListingBySizeQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetProfileMuseumArtworksListingBySizeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileMuseumArtworksListingBySizeQuery, Types.GetProfileMuseumArtworksListingBySizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileMuseumArtworksListingBySizeQuery, Types.GetProfileMuseumArtworksListingBySizeQueryVariables>(GetProfileMuseumArtworksListingBySizeDocument, options);
      }
export function useGetProfileMuseumArtworksListingBySizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileMuseumArtworksListingBySizeQuery, Types.GetProfileMuseumArtworksListingBySizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileMuseumArtworksListingBySizeQuery, Types.GetProfileMuseumArtworksListingBySizeQueryVariables>(GetProfileMuseumArtworksListingBySizeDocument, options);
        }
export type GetProfileMuseumArtworksListingBySizeQueryHookResult = ReturnType<typeof useGetProfileMuseumArtworksListingBySizeQuery>;
export type GetProfileMuseumArtworksListingBySizeLazyQueryHookResult = ReturnType<typeof useGetProfileMuseumArtworksListingBySizeLazyQuery>;
export type GetProfileMuseumArtworksListingBySizeQueryResult = Apollo.QueryResult<Types.GetProfileMuseumArtworksListingBySizeQuery, Types.GetProfileMuseumArtworksListingBySizeQueryVariables>;
export const GetProfileMuseumArtworksListingByPriceDocument = gql`
    query GetProfileMuseumArtworksListingByPrice($limit: Int!, $appliedSorts: [Museum_artwork_listing_order_by!], $appliedFilters: Museum_artwork_listing_bool_exp!) {
  artworks: Museum_artwork_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonProfileMuseumArtworkListingCursor
    price
  }
}
    ${CommonProfileMuseumArtworkListingCursorFragmentDoc}`;

/**
 * __useGetProfileMuseumArtworksListingByPriceQuery__
 *
 * To run a query within a React component, call `useGetProfileMuseumArtworksListingByPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileMuseumArtworksListingByPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileMuseumArtworksListingByPriceQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetProfileMuseumArtworksListingByPriceQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProfileMuseumArtworksListingByPriceQuery, Types.GetProfileMuseumArtworksListingByPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProfileMuseumArtworksListingByPriceQuery, Types.GetProfileMuseumArtworksListingByPriceQueryVariables>(GetProfileMuseumArtworksListingByPriceDocument, options);
      }
export function useGetProfileMuseumArtworksListingByPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProfileMuseumArtworksListingByPriceQuery, Types.GetProfileMuseumArtworksListingByPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProfileMuseumArtworksListingByPriceQuery, Types.GetProfileMuseumArtworksListingByPriceQueryVariables>(GetProfileMuseumArtworksListingByPriceDocument, options);
        }
export type GetProfileMuseumArtworksListingByPriceQueryHookResult = ReturnType<typeof useGetProfileMuseumArtworksListingByPriceQuery>;
export type GetProfileMuseumArtworksListingByPriceLazyQueryHookResult = ReturnType<typeof useGetProfileMuseumArtworksListingByPriceLazyQuery>;
export type GetProfileMuseumArtworksListingByPriceQueryResult = Apollo.QueryResult<Types.GetProfileMuseumArtworksListingByPriceQuery, Types.GetProfileMuseumArtworksListingByPriceQueryVariables>;
export const GetMuseumExhibitionsDocument = gql`
    query GetMuseumExhibitions($handle: String!) {
  exhibitions: Announced_art_show(where: {organizer_handle: {_ilike: $handle}}) {
    ...ExhibitionDetails
  }
}
    ${ExhibitionDetailsFragmentDoc}`;

/**
 * __useGetMuseumExhibitionsQuery__
 *
 * To run a query within a React component, call `useGetMuseumExhibitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMuseumExhibitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMuseumExhibitionsQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetMuseumExhibitionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetMuseumExhibitionsQuery, Types.GetMuseumExhibitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetMuseumExhibitionsQuery, Types.GetMuseumExhibitionsQueryVariables>(GetMuseumExhibitionsDocument, options);
      }
export function useGetMuseumExhibitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetMuseumExhibitionsQuery, Types.GetMuseumExhibitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetMuseumExhibitionsQuery, Types.GetMuseumExhibitionsQueryVariables>(GetMuseumExhibitionsDocument, options);
        }
export type GetMuseumExhibitionsQueryHookResult = ReturnType<typeof useGetMuseumExhibitionsQuery>;
export type GetMuseumExhibitionsLazyQueryHookResult = ReturnType<typeof useGetMuseumExhibitionsLazyQuery>;
export type GetMuseumExhibitionsQueryResult = Apollo.QueryResult<Types.GetMuseumExhibitionsQuery, Types.GetMuseumExhibitionsQueryVariables>;
export const GetUserRecognitionsDocument = gql`
    query GetUserRecognitions($userContextId: bigint!, $offset: Int!, $limit: Int!) {
  Social_graph_followers(
    where: {_and: {context_id: {_eq: $userContextId}, state: {_eq: ACTIVE}}}
    limit: $limit
    offset: $offset
    order_by: {selected_order: asc, updated_at: asc}
  ) {
    target_context_id
    is_mutual
    selected_order
    Social_graph_followers_Target_Context {
      id
      profile {
        title
        handle
        id
        avatar
      }
      locations {
        city
        country
      }
      Artworks(limit: 3) {
        artist_context_id
        id
        slug
        artwork_details {
          title
        }
        thumbnail_metadata_id
      }
    }
  }
}
    `;

/**
 * __useGetUserRecognitionsQuery__
 *
 * To run a query within a React component, call `useGetUserRecognitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRecognitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRecognitionsQuery({
 *   variables: {
 *      userContextId: // value for 'userContextId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserRecognitionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserRecognitionsQuery, Types.GetUserRecognitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserRecognitionsQuery, Types.GetUserRecognitionsQueryVariables>(GetUserRecognitionsDocument, options);
      }
export function useGetUserRecognitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserRecognitionsQuery, Types.GetUserRecognitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserRecognitionsQuery, Types.GetUserRecognitionsQueryVariables>(GetUserRecognitionsDocument, options);
        }
export type GetUserRecognitionsQueryHookResult = ReturnType<typeof useGetUserRecognitionsQuery>;
export type GetUserRecognitionsLazyQueryHookResult = ReturnType<typeof useGetUserRecognitionsLazyQuery>;
export type GetUserRecognitionsQueryResult = Apollo.QueryResult<Types.GetUserRecognitionsQuery, Types.GetUserRecognitionsQueryVariables>;
export const GetRecognitionsArtistInfoDocument = gql`
    query GetRecognitionsArtistInfo($artists: [bigint!]!, $skipRecognitions: Boolean!, $callerCtxId: bigint) {
  artworks: artist_cardgrid_artworks_view(
    where: {artist_context_id: {_in: $artists}, rank: {_lte: 3}}
  ) {
    id
    thumbnailMetadataId: thumbnail_metadata_id
    rank
    artistContextId: artist_context_id
  }
  artists: Artist_explore_info(where: {artist_context_id: {_in: $artists}}) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    country
    city
  }
  recognitions: Social_graph_followers(
    where: {context_id: {_eq: $callerCtxId}, target_context_id: {_in: $artists}}
  ) @skip(if: $skipRecognitions) {
    artistContextId: target_context_id
    state
    isMutual: is_mutual
    naturalOrder: natural_order
    selectedOrder: selected_order
  }
}
    `;

/**
 * __useGetRecognitionsArtistInfoQuery__
 *
 * To run a query within a React component, call `useGetRecognitionsArtistInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecognitionsArtistInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecognitionsArtistInfoQuery({
 *   variables: {
 *      artists: // value for 'artists'
 *      skipRecognitions: // value for 'skipRecognitions'
 *      callerCtxId: // value for 'callerCtxId'
 *   },
 * });
 */
export function useGetRecognitionsArtistInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRecognitionsArtistInfoQuery, Types.GetRecognitionsArtistInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRecognitionsArtistInfoQuery, Types.GetRecognitionsArtistInfoQueryVariables>(GetRecognitionsArtistInfoDocument, options);
      }
export function useGetRecognitionsArtistInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRecognitionsArtistInfoQuery, Types.GetRecognitionsArtistInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRecognitionsArtistInfoQuery, Types.GetRecognitionsArtistInfoQueryVariables>(GetRecognitionsArtistInfoDocument, options);
        }
export type GetRecognitionsArtistInfoQueryHookResult = ReturnType<typeof useGetRecognitionsArtistInfoQuery>;
export type GetRecognitionsArtistInfoLazyQueryHookResult = ReturnType<typeof useGetRecognitionsArtistInfoLazyQuery>;
export type GetRecognitionsArtistInfoQueryResult = Apollo.QueryResult<Types.GetRecognitionsArtistInfoQuery, Types.GetRecognitionsArtistInfoQueryVariables>;
export const GetFollowersArtistInfoDocument = gql`
    query GetFollowersArtistInfo($artists: [bigint!]!, $skipRecognitions: Boolean!, $callerCtxId: bigint) {
  artworks: artist_cardgrid_artworks_view(
    where: {artist_context_id: {_in: $artists}, rank: {_lte: 3}}
  ) {
    id
    thumbnailMetadataId: thumbnail_metadata_id
    rank
    artistContextId: artist_context_id
  }
  artists: Artist_explore_info(where: {artist_context_id: {_in: $artists}}) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    country
    city
  }
  recognitions: Social_graph_followers(
    where: {target_context_id: {_eq: $callerCtxId}, context_id: {_in: $artists}}
  ) @skip(if: $skipRecognitions) {
    artistContextId: context_id
    state
    isMutual: is_mutual
    naturalOrder: natural_order
    selectedOrder: selected_order
  }
}
    `;

/**
 * __useGetFollowersArtistInfoQuery__
 *
 * To run a query within a React component, call `useGetFollowersArtistInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowersArtistInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowersArtistInfoQuery({
 *   variables: {
 *      artists: // value for 'artists'
 *      skipRecognitions: // value for 'skipRecognitions'
 *      callerCtxId: // value for 'callerCtxId'
 *   },
 * });
 */
export function useGetFollowersArtistInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFollowersArtistInfoQuery, Types.GetFollowersArtistInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFollowersArtistInfoQuery, Types.GetFollowersArtistInfoQueryVariables>(GetFollowersArtistInfoDocument, options);
      }
export function useGetFollowersArtistInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFollowersArtistInfoQuery, Types.GetFollowersArtistInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFollowersArtistInfoQuery, Types.GetFollowersArtistInfoQueryVariables>(GetFollowersArtistInfoDocument, options);
        }
export type GetFollowersArtistInfoQueryHookResult = ReturnType<typeof useGetFollowersArtistInfoQuery>;
export type GetFollowersArtistInfoLazyQueryHookResult = ReturnType<typeof useGetFollowersArtistInfoLazyQuery>;
export type GetFollowersArtistInfoQueryResult = Apollo.QueryResult<Types.GetFollowersArtistInfoQuery, Types.GetFollowersArtistInfoQueryVariables>;
export const GetFollowersCollectorInfoDocument = gql`
    query GetFollowersCollectorInfo($collectors: [bigint!]!, $skipRecognitions: Boolean!, $callerCtxId: bigint) {
  collectors: Collector_explore_info(
    where: {collector_context_id: {_in: $collectors}}
  ) {
    contextId: collector_context_id
    profileId: collector_profile_id
    first_name
    last_name
    handle
    avatar
    country
    city
  }
  recognitions: Social_graph_followers(
    where: {target_context_id: {_eq: $callerCtxId}, context_id: {_in: $collectors}}
  ) @skip(if: $skipRecognitions) {
    artistContextId: context_id
    state
    isMutual: is_mutual
    naturalOrder: natural_order
    selectedOrder: selected_order
  }
}
    `;

/**
 * __useGetFollowersCollectorInfoQuery__
 *
 * To run a query within a React component, call `useGetFollowersCollectorInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowersCollectorInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowersCollectorInfoQuery({
 *   variables: {
 *      collectors: // value for 'collectors'
 *      skipRecognitions: // value for 'skipRecognitions'
 *      callerCtxId: // value for 'callerCtxId'
 *   },
 * });
 */
export function useGetFollowersCollectorInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFollowersCollectorInfoQuery, Types.GetFollowersCollectorInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFollowersCollectorInfoQuery, Types.GetFollowersCollectorInfoQueryVariables>(GetFollowersCollectorInfoDocument, options);
      }
export function useGetFollowersCollectorInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFollowersCollectorInfoQuery, Types.GetFollowersCollectorInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFollowersCollectorInfoQuery, Types.GetFollowersCollectorInfoQueryVariables>(GetFollowersCollectorInfoDocument, options);
        }
export type GetFollowersCollectorInfoQueryHookResult = ReturnType<typeof useGetFollowersCollectorInfoQuery>;
export type GetFollowersCollectorInfoLazyQueryHookResult = ReturnType<typeof useGetFollowersCollectorInfoLazyQuery>;
export type GetFollowersCollectorInfoQueryResult = Apollo.QueryResult<Types.GetFollowersCollectorInfoQuery, Types.GetFollowersCollectorInfoQueryVariables>;
export const GetRecognitionsListingByDateDocument = gql`
    query GetRecognitionsListingByDate($limit: Int!, $cursorWhere: Social_graph_followers_bool_exp!, $where: Artist_explore_info_bool_exp!) {
  artists: Social_graph_followers(
    where: {_and: [{artist_explore_info: $where}, $cursorWhere]}
    order_by: [{updated_at: desc}, {id: desc}]
    limit: $limit
  ) {
    contextId: target_context_id
    appearedAt: updated_at
  }
}
    `;

/**
 * __useGetRecognitionsListingByDateQuery__
 *
 * To run a query within a React component, call `useGetRecognitionsListingByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecognitionsListingByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecognitionsListingByDateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursorWhere: // value for 'cursorWhere'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetRecognitionsListingByDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRecognitionsListingByDateQuery, Types.GetRecognitionsListingByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRecognitionsListingByDateQuery, Types.GetRecognitionsListingByDateQueryVariables>(GetRecognitionsListingByDateDocument, options);
      }
export function useGetRecognitionsListingByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRecognitionsListingByDateQuery, Types.GetRecognitionsListingByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRecognitionsListingByDateQuery, Types.GetRecognitionsListingByDateQueryVariables>(GetRecognitionsListingByDateDocument, options);
        }
export type GetRecognitionsListingByDateQueryHookResult = ReturnType<typeof useGetRecognitionsListingByDateQuery>;
export type GetRecognitionsListingByDateLazyQueryHookResult = ReturnType<typeof useGetRecognitionsListingByDateLazyQuery>;
export type GetRecognitionsListingByDateQueryResult = Apollo.QueryResult<Types.GetRecognitionsListingByDateQuery, Types.GetRecognitionsListingByDateQueryVariables>;
export const GetFollowersListingByDateDocument = gql`
    query GetFollowersListingByDate($limit: Int!, $cursorWhere: Social_graph_followers_bool_exp!) {
  followers: Social_graph_followers(
    where: {_and: [$cursorWhere]}
    order_by: [{updated_at: desc}, {id: desc}]
    limit: $limit
  ) {
    contextId: context_id
    appearedAt: updated_at
  }
}
    `;

/**
 * __useGetFollowersListingByDateQuery__
 *
 * To run a query within a React component, call `useGetFollowersListingByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowersListingByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowersListingByDateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursorWhere: // value for 'cursorWhere'
 *   },
 * });
 */
export function useGetFollowersListingByDateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFollowersListingByDateQuery, Types.GetFollowersListingByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFollowersListingByDateQuery, Types.GetFollowersListingByDateQueryVariables>(GetFollowersListingByDateDocument, options);
      }
export function useGetFollowersListingByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFollowersListingByDateQuery, Types.GetFollowersListingByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFollowersListingByDateQuery, Types.GetFollowersListingByDateQueryVariables>(GetFollowersListingByDateDocument, options);
        }
export type GetFollowersListingByDateQueryHookResult = ReturnType<typeof useGetFollowersListingByDateQuery>;
export type GetFollowersListingByDateLazyQueryHookResult = ReturnType<typeof useGetFollowersListingByDateLazyQuery>;
export type GetFollowersListingByDateQueryResult = Apollo.QueryResult<Types.GetFollowersListingByDateQuery, Types.GetFollowersListingByDateQueryVariables>;
export const GetArtistIdsFromCollectionsDocument = gql`
    query getArtistIdsFromCollections($internal_urls: [String!]!) {
  collections: Artist_Collection(
    where: {internal_url: {_in: $internal_urls}, state: {_eq: ENABLED}, deleted_at: {_is_null: true}, type: {_eq: virtual_exhibition}}
  ) {
    artists: Artist_Collection_to_Artist_Contexts(
      where: {deleted_at: {_is_null: true}}
    ) {
      context_id
    }
  }
}
    `;

/**
 * __useGetArtistIdsFromCollectionsQuery__
 *
 * To run a query within a React component, call `useGetArtistIdsFromCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistIdsFromCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistIdsFromCollectionsQuery({
 *   variables: {
 *      internal_urls: // value for 'internal_urls'
 *   },
 * });
 */
export function useGetArtistIdsFromCollectionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistIdsFromCollectionsQuery, Types.GetArtistIdsFromCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistIdsFromCollectionsQuery, Types.GetArtistIdsFromCollectionsQueryVariables>(GetArtistIdsFromCollectionsDocument, options);
      }
export function useGetArtistIdsFromCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistIdsFromCollectionsQuery, Types.GetArtistIdsFromCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistIdsFromCollectionsQuery, Types.GetArtistIdsFromCollectionsQueryVariables>(GetArtistIdsFromCollectionsDocument, options);
        }
export type GetArtistIdsFromCollectionsQueryHookResult = ReturnType<typeof useGetArtistIdsFromCollectionsQuery>;
export type GetArtistIdsFromCollectionsLazyQueryHookResult = ReturnType<typeof useGetArtistIdsFromCollectionsLazyQuery>;
export type GetArtistIdsFromCollectionsQueryResult = Apollo.QueryResult<Types.GetArtistIdsFromCollectionsQuery, Types.GetArtistIdsFromCollectionsQueryVariables>;
export const GetCollectorInterestsIdDocument = gql`
    query getCollectorInterestsId($contextId: bigint!) {
  interests: Profile_Interests(where: {context_id: {_eq: $contextId}}) {
    interest_id
  }
}
    `;

/**
 * __useGetCollectorInterestsIdQuery__
 *
 * To run a query within a React component, call `useGetCollectorInterestsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorInterestsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorInterestsIdQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetCollectorInterestsIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorInterestsIdQuery, Types.GetCollectorInterestsIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorInterestsIdQuery, Types.GetCollectorInterestsIdQueryVariables>(GetCollectorInterestsIdDocument, options);
      }
export function useGetCollectorInterestsIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorInterestsIdQuery, Types.GetCollectorInterestsIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorInterestsIdQuery, Types.GetCollectorInterestsIdQueryVariables>(GetCollectorInterestsIdDocument, options);
        }
export type GetCollectorInterestsIdQueryHookResult = ReturnType<typeof useGetCollectorInterestsIdQuery>;
export type GetCollectorInterestsIdLazyQueryHookResult = ReturnType<typeof useGetCollectorInterestsIdLazyQuery>;
export type GetCollectorInterestsIdQueryResult = Apollo.QueryResult<Types.GetCollectorInterestsIdQuery, Types.GetCollectorInterestsIdQueryVariables>;
export const ThirdPartySignInDocument = gql`
    mutation thirdPartySignIn($firstName: String!, $lastName: String!, $email: String!, $preferredLanguage: String!, $providerId: String!) {
  thirdPartySignIn(
    first_name: $firstName
    last_name: $lastName
    preferred_language: $preferredLanguage
    email: $email
    provider_id: $providerId
  ) {
    valid
    message
  }
}
    `;
export type ThirdPartySignInMutationFn = Apollo.MutationFunction<Types.ThirdPartySignInMutation, Types.ThirdPartySignInMutationVariables>;

/**
 * __useThirdPartySignInMutation__
 *
 * To run a mutation, you first call `useThirdPartySignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThirdPartySignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [thirdPartySignInMutation, { data, loading, error }] = useThirdPartySignInMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      preferredLanguage: // value for 'preferredLanguage'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useThirdPartySignInMutation(baseOptions?: Apollo.MutationHookOptions<Types.ThirdPartySignInMutation, Types.ThirdPartySignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ThirdPartySignInMutation, Types.ThirdPartySignInMutationVariables>(ThirdPartySignInDocument, options);
      }
export type ThirdPartySignInMutationHookResult = ReturnType<typeof useThirdPartySignInMutation>;
export type ThirdPartySignInMutationResult = Apollo.MutationResult<Types.ThirdPartySignInMutation>;
export type ThirdPartySignInMutationOptions = Apollo.BaseMutationOptions<Types.ThirdPartySignInMutation, Types.ThirdPartySignInMutationVariables>;
export const GetRegisterBannerArtistDisplayInfoDocument = gql`
    query getRegisterBannerArtistDisplayInfo($id: bigint!) {
  Profile(where: {context: {id: {_eq: $id}}}) {
    image: avatar_download_link
    name: title
  }
}
    `;

/**
 * __useGetRegisterBannerArtistDisplayInfoQuery__
 *
 * To run a query within a React component, call `useGetRegisterBannerArtistDisplayInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisterBannerArtistDisplayInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisterBannerArtistDisplayInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegisterBannerArtistDisplayInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRegisterBannerArtistDisplayInfoQuery, Types.GetRegisterBannerArtistDisplayInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRegisterBannerArtistDisplayInfoQuery, Types.GetRegisterBannerArtistDisplayInfoQueryVariables>(GetRegisterBannerArtistDisplayInfoDocument, options);
      }
export function useGetRegisterBannerArtistDisplayInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRegisterBannerArtistDisplayInfoQuery, Types.GetRegisterBannerArtistDisplayInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRegisterBannerArtistDisplayInfoQuery, Types.GetRegisterBannerArtistDisplayInfoQueryVariables>(GetRegisterBannerArtistDisplayInfoDocument, options);
        }
export type GetRegisterBannerArtistDisplayInfoQueryHookResult = ReturnType<typeof useGetRegisterBannerArtistDisplayInfoQuery>;
export type GetRegisterBannerArtistDisplayInfoLazyQueryHookResult = ReturnType<typeof useGetRegisterBannerArtistDisplayInfoLazyQuery>;
export type GetRegisterBannerArtistDisplayInfoQueryResult = Apollo.QueryResult<Types.GetRegisterBannerArtistDisplayInfoQuery, Types.GetRegisterBannerArtistDisplayInfoQueryVariables>;
export const GetRegisterBannerArtworkDisplayInfoDocument = gql`
    query getRegisterBannerArtworkDisplayInfo($id: bigint!) {
  data: Artwork_by_pk(id: $id) {
    details: artwork_details {
      title
    }
    metadataId: primary_image_metadata_id
    id
  }
}
    `;

/**
 * __useGetRegisterBannerArtworkDisplayInfoQuery__
 *
 * To run a query within a React component, call `useGetRegisterBannerArtworkDisplayInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisterBannerArtworkDisplayInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisterBannerArtworkDisplayInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegisterBannerArtworkDisplayInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRegisterBannerArtworkDisplayInfoQuery, Types.GetRegisterBannerArtworkDisplayInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRegisterBannerArtworkDisplayInfoQuery, Types.GetRegisterBannerArtworkDisplayInfoQueryVariables>(GetRegisterBannerArtworkDisplayInfoDocument, options);
      }
export function useGetRegisterBannerArtworkDisplayInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRegisterBannerArtworkDisplayInfoQuery, Types.GetRegisterBannerArtworkDisplayInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRegisterBannerArtworkDisplayInfoQuery, Types.GetRegisterBannerArtworkDisplayInfoQueryVariables>(GetRegisterBannerArtworkDisplayInfoDocument, options);
        }
export type GetRegisterBannerArtworkDisplayInfoQueryHookResult = ReturnType<typeof useGetRegisterBannerArtworkDisplayInfoQuery>;
export type GetRegisterBannerArtworkDisplayInfoLazyQueryHookResult = ReturnType<typeof useGetRegisterBannerArtworkDisplayInfoLazyQuery>;
export type GetRegisterBannerArtworkDisplayInfoQueryResult = Apollo.QueryResult<Types.GetRegisterBannerArtworkDisplayInfoQuery, Types.GetRegisterBannerArtworkDisplayInfoQueryVariables>;
export const GetArtistContextIdByHandleDocument = gql`
    query getArtistContextIdByHandle($handle: String!) {
  profile: Profile(where: {handle: {_ilike: $handle}}) {
    context {
      id
    }
  }
}
    `;

/**
 * __useGetArtistContextIdByHandleQuery__
 *
 * To run a query within a React component, call `useGetArtistContextIdByHandleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistContextIdByHandleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistContextIdByHandleQuery({
 *   variables: {
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetArtistContextIdByHandleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistContextIdByHandleQuery, Types.GetArtistContextIdByHandleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistContextIdByHandleQuery, Types.GetArtistContextIdByHandleQueryVariables>(GetArtistContextIdByHandleDocument, options);
      }
export function useGetArtistContextIdByHandleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistContextIdByHandleQuery, Types.GetArtistContextIdByHandleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistContextIdByHandleQuery, Types.GetArtistContextIdByHandleQueryVariables>(GetArtistContextIdByHandleDocument, options);
        }
export type GetArtistContextIdByHandleQueryHookResult = ReturnType<typeof useGetArtistContextIdByHandleQuery>;
export type GetArtistContextIdByHandleLazyQueryHookResult = ReturnType<typeof useGetArtistContextIdByHandleLazyQuery>;
export type GetArtistContextIdByHandleQueryResult = Apollo.QueryResult<Types.GetArtistContextIdByHandleQuery, Types.GetArtistContextIdByHandleQueryVariables>;
export const GetShopArtworkInfoDocument = gql`
    query GetShopArtworkInfo($artists: [bigint!]!, $artworkIds: [bigint!]!, $callerCtxId: bigint!, $skipPrizes: Boolean!, $skipFavorites: Boolean!) {
  artists: Artist_explore_info(where: {artist_context_id: {_in: $artists}}) {
    contextId: artist_context_id
    profileId: artist_profile_id
    title
    handle
    avatar
    country
    city
  }
  artworks: artwork_shop_info(where: {artwork_id: {_in: $artworkIds}}) {
    artworkId: artwork_id
    artistContextId: artist_context_id
    primaryImageMetadataId: primary_image_metadata_id
    thumbnailMetadataId: thumbnail_metadata_id
    title
    editionType: edition_type
    width
    height
    depth
    measuringUnit: measuring_unit
    slug
    price
    currency
  }
  prizes: Artwork_prize_info(where: {artwork_id: {_in: $artworkIds}}) @skip(if: $skipPrizes) {
    artworkId: artwork_id
    artPrizeShowId: art_prize_show_id
    artworkPrizeIds: artwork_prize_ids
    title
    slug: prize_show_slug
    position: position_type
  }
  favorites: Artwork_to_Collection(
    where: {artwork_id: {_in: $artworkIds}, Collection: {owner_context_id: {_eq: $callerCtxId}, type: {_eq: FAVORITE}}}
  ) @skip(if: $skipFavorites) {
    artworkId: artwork_id
  }
}
    `;

/**
 * __useGetShopArtworkInfoQuery__
 *
 * To run a query within a React component, call `useGetShopArtworkInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopArtworkInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopArtworkInfoQuery({
 *   variables: {
 *      artists: // value for 'artists'
 *      artworkIds: // value for 'artworkIds'
 *      callerCtxId: // value for 'callerCtxId'
 *      skipPrizes: // value for 'skipPrizes'
 *      skipFavorites: // value for 'skipFavorites'
 *   },
 * });
 */
export function useGetShopArtworkInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShopArtworkInfoQuery, Types.GetShopArtworkInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShopArtworkInfoQuery, Types.GetShopArtworkInfoQueryVariables>(GetShopArtworkInfoDocument, options);
      }
export function useGetShopArtworkInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShopArtworkInfoQuery, Types.GetShopArtworkInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShopArtworkInfoQuery, Types.GetShopArtworkInfoQueryVariables>(GetShopArtworkInfoDocument, options);
        }
export type GetShopArtworkInfoQueryHookResult = ReturnType<typeof useGetShopArtworkInfoQuery>;
export type GetShopArtworkInfoLazyQueryHookResult = ReturnType<typeof useGetShopArtworkInfoLazyQuery>;
export type GetShopArtworkInfoQueryResult = Apollo.QueryResult<Types.GetShopArtworkInfoQuery, Types.GetShopArtworkInfoQueryVariables>;
export const GetShopArtworksListingByCurationScoreDocument = gql`
    query GetShopArtworksListingByCurationScore($limit: Int!, $appliedSorts: [artwork_shop_listing_order_by!], $appliedFilters: artwork_shop_listing_bool_exp!) {
  artworks: artwork_shop_listing(
    where: $appliedFilters
    order_by: $appliedSorts
    limit: $limit
  ) {
    ...CommonArtworkShopListingCursor
    score
  }
}
    ${CommonArtworkShopListingCursorFragmentDoc}`;

/**
 * __useGetShopArtworksListingByCurationScoreQuery__
 *
 * To run a query within a React component, call `useGetShopArtworksListingByCurationScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopArtworksListingByCurationScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopArtworksListingByCurationScoreQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      appliedSorts: // value for 'appliedSorts'
 *      appliedFilters: // value for 'appliedFilters'
 *   },
 * });
 */
export function useGetShopArtworksListingByCurationScoreQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShopArtworksListingByCurationScoreQuery, Types.GetShopArtworksListingByCurationScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShopArtworksListingByCurationScoreQuery, Types.GetShopArtworksListingByCurationScoreQueryVariables>(GetShopArtworksListingByCurationScoreDocument, options);
      }
export function useGetShopArtworksListingByCurationScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShopArtworksListingByCurationScoreQuery, Types.GetShopArtworksListingByCurationScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShopArtworksListingByCurationScoreQuery, Types.GetShopArtworksListingByCurationScoreQueryVariables>(GetShopArtworksListingByCurationScoreDocument, options);
        }
export type GetShopArtworksListingByCurationScoreQueryHookResult = ReturnType<typeof useGetShopArtworksListingByCurationScoreQuery>;
export type GetShopArtworksListingByCurationScoreLazyQueryHookResult = ReturnType<typeof useGetShopArtworksListingByCurationScoreLazyQuery>;
export type GetShopArtworksListingByCurationScoreQueryResult = Apollo.QueryResult<Types.GetShopArtworksListingByCurationScoreQuery, Types.GetShopArtworksListingByCurationScoreQueryVariables>;
export const AddItemToCartDocument = gql`
    mutation addItemToCart($contextId: bigint!, $artworkId: bigint!) {
  addArtworkToCart(context_id: $contextId, artwork_id: $artworkId) {
    artistContextId
    artistTitle
    primaryMetadataId
    artworkId
    height
    width
    depth
    discipline
    title
    measuringUnit
    materials
    prices {
      price
      currency
      isPublic
    }
    shippingPrice
    isAvailableForSale
    isInAnInitiatedCheckout
  }
}
    `;
export type AddItemToCartMutationFn = Apollo.MutationFunction<Types.AddItemToCartMutation, Types.AddItemToCartMutationVariables>;

/**
 * __useAddItemToCartMutation__
 *
 * To run a mutation, you first call `useAddItemToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemToCartMutation, { data, loading, error }] = useAddItemToCartMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useAddItemToCartMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddItemToCartMutation, Types.AddItemToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddItemToCartMutation, Types.AddItemToCartMutationVariables>(AddItemToCartDocument, options);
      }
export type AddItemToCartMutationHookResult = ReturnType<typeof useAddItemToCartMutation>;
export type AddItemToCartMutationResult = Apollo.MutationResult<Types.AddItemToCartMutation>;
export type AddItemToCartMutationOptions = Apollo.BaseMutationOptions<Types.AddItemToCartMutation, Types.AddItemToCartMutationVariables>;
export const RemoveItemFromCartDocument = gql`
    mutation removeItemFromCart($contextId: bigint!, $artworkId: bigint) {
  removeArtworkFromCart(context_id: $contextId, artwork_id: $artworkId) {
    artistContextId
    artistTitle
    primaryMetadataId
    artworkId
    height
    width
    depth
    discipline
    title
    measuringUnit
    materials
    prices {
      price
      currency
      isPublic
    }
    shippingPrice
    isAvailableForSale
    isInAnInitiatedCheckout
  }
}
    `;
export type RemoveItemFromCartMutationFn = Apollo.MutationFunction<Types.RemoveItemFromCartMutation, Types.RemoveItemFromCartMutationVariables>;

/**
 * __useRemoveItemFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromCartMutation, { data, loading, error }] = useRemoveItemFromCartMutation({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useRemoveItemFromCartMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveItemFromCartMutation, Types.RemoveItemFromCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveItemFromCartMutation, Types.RemoveItemFromCartMutationVariables>(RemoveItemFromCartDocument, options);
      }
export type RemoveItemFromCartMutationHookResult = ReturnType<typeof useRemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationResult = Apollo.MutationResult<Types.RemoveItemFromCartMutation>;
export type RemoveItemFromCartMutationOptions = Apollo.BaseMutationOptions<Types.RemoveItemFromCartMutation, Types.RemoveItemFromCartMutationVariables>;
export const InitCartItemsPurchaseFlowDocument = gql`
    mutation initCartItemsPurchaseFlow($buyerContextId: bigint!, $successUrl: String!, $cancelUrl: String!) {
  initiateCartItemsPurchaseTransaction(
    buyerContextId: $buyerContextId
    successUrl: $successUrl
    cancelUrl: $cancelUrl
  ) {
    checkoutUrl
  }
}
    `;
export type InitCartItemsPurchaseFlowMutationFn = Apollo.MutationFunction<Types.InitCartItemsPurchaseFlowMutation, Types.InitCartItemsPurchaseFlowMutationVariables>;

/**
 * __useInitCartItemsPurchaseFlowMutation__
 *
 * To run a mutation, you first call `useInitCartItemsPurchaseFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitCartItemsPurchaseFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initCartItemsPurchaseFlowMutation, { data, loading, error }] = useInitCartItemsPurchaseFlowMutation({
 *   variables: {
 *      buyerContextId: // value for 'buyerContextId'
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *   },
 * });
 */
export function useInitCartItemsPurchaseFlowMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitCartItemsPurchaseFlowMutation, Types.InitCartItemsPurchaseFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitCartItemsPurchaseFlowMutation, Types.InitCartItemsPurchaseFlowMutationVariables>(InitCartItemsPurchaseFlowDocument, options);
      }
export type InitCartItemsPurchaseFlowMutationHookResult = ReturnType<typeof useInitCartItemsPurchaseFlowMutation>;
export type InitCartItemsPurchaseFlowMutationResult = Apollo.MutationResult<Types.InitCartItemsPurchaseFlowMutation>;
export type InitCartItemsPurchaseFlowMutationOptions = Apollo.BaseMutationOptions<Types.InitCartItemsPurchaseFlowMutation, Types.InitCartItemsPurchaseFlowMutationVariables>;
export const InitCartItemsPurchaseFlowForAnonDocument = gql`
    mutation initCartItemsPurchaseFlowForAnon($cartArtworkIds: [bigint!]!, $email: String, $anonId: bigint, $successUrl: String!, $cancelUrl: String!, $origin: String) {
  initiateCartItemsAnonPurchaseTransaction(
    cartArtworkIds: $cartArtworkIds
    email: $email
    anonId: $anonId
    successUrl: $successUrl
    cancelUrl: $cancelUrl
    origin: $origin
  ) {
    checkoutUrl
    anonId
  }
}
    `;
export type InitCartItemsPurchaseFlowForAnonMutationFn = Apollo.MutationFunction<Types.InitCartItemsPurchaseFlowForAnonMutation, Types.InitCartItemsPurchaseFlowForAnonMutationVariables>;

/**
 * __useInitCartItemsPurchaseFlowForAnonMutation__
 *
 * To run a mutation, you first call `useInitCartItemsPurchaseFlowForAnonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitCartItemsPurchaseFlowForAnonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initCartItemsPurchaseFlowForAnonMutation, { data, loading, error }] = useInitCartItemsPurchaseFlowForAnonMutation({
 *   variables: {
 *      cartArtworkIds: // value for 'cartArtworkIds'
 *      email: // value for 'email'
 *      anonId: // value for 'anonId'
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useInitCartItemsPurchaseFlowForAnonMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitCartItemsPurchaseFlowForAnonMutation, Types.InitCartItemsPurchaseFlowForAnonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitCartItemsPurchaseFlowForAnonMutation, Types.InitCartItemsPurchaseFlowForAnonMutationVariables>(InitCartItemsPurchaseFlowForAnonDocument, options);
      }
export type InitCartItemsPurchaseFlowForAnonMutationHookResult = ReturnType<typeof useInitCartItemsPurchaseFlowForAnonMutation>;
export type InitCartItemsPurchaseFlowForAnonMutationResult = Apollo.MutationResult<Types.InitCartItemsPurchaseFlowForAnonMutation>;
export type InitCartItemsPurchaseFlowForAnonMutationOptions = Apollo.BaseMutationOptions<Types.InitCartItemsPurchaseFlowForAnonMutation, Types.InitCartItemsPurchaseFlowForAnonMutationVariables>;
export const GetCartItemsListDocument = gql`
    query getCartItemsList($contextId: bigint!) {
  getCartItems(context_id: $contextId) {
    artistContextId
    artistTitle
    primaryMetadataId
    artworkId
    height
    width
    depth
    discipline
    title
    measuringUnit
    materials
    prices {
      price
      currency
      isPublic
    }
    shippingPrice
    isAvailableForSale
    isInAnInitiatedCheckout
  }
}
    `;

/**
 * __useGetCartItemsListQuery__
 *
 * To run a query within a React component, call `useGetCartItemsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemsListQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *   },
 * });
 */
export function useGetCartItemsListQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCartItemsListQuery, Types.GetCartItemsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCartItemsListQuery, Types.GetCartItemsListQueryVariables>(GetCartItemsListDocument, options);
      }
export function useGetCartItemsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCartItemsListQuery, Types.GetCartItemsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCartItemsListQuery, Types.GetCartItemsListQueryVariables>(GetCartItemsListDocument, options);
        }
export type GetCartItemsListQueryHookResult = ReturnType<typeof useGetCartItemsListQuery>;
export type GetCartItemsListLazyQueryHookResult = ReturnType<typeof useGetCartItemsListLazyQuery>;
export type GetCartItemsListQueryResult = Apollo.QueryResult<Types.GetCartItemsListQuery, Types.GetCartItemsListQueryVariables>;
export const GetCartItemsListForAnonUsersDocument = gql`
    query getCartItemsListForAnonUsers($artworksId: [bigint!]!) {
  getCartItemsByArtworksId(artworksId: $artworksId) {
    artistContextId
    artistTitle
    primaryMetadataId
    artworkId
    height
    width
    depth
    discipline
    title
    measuringUnit
    materials
    prices {
      price
      currency
      isPublic
    }
    shippingPrice
    isAvailableForSale
    isInAnInitiatedCheckout
  }
}
    `;

/**
 * __useGetCartItemsListForAnonUsersQuery__
 *
 * To run a query within a React component, call `useGetCartItemsListForAnonUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartItemsListForAnonUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartItemsListForAnonUsersQuery({
 *   variables: {
 *      artworksId: // value for 'artworksId'
 *   },
 * });
 */
export function useGetCartItemsListForAnonUsersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCartItemsListForAnonUsersQuery, Types.GetCartItemsListForAnonUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCartItemsListForAnonUsersQuery, Types.GetCartItemsListForAnonUsersQueryVariables>(GetCartItemsListForAnonUsersDocument, options);
      }
export function useGetCartItemsListForAnonUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCartItemsListForAnonUsersQuery, Types.GetCartItemsListForAnonUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCartItemsListForAnonUsersQuery, Types.GetCartItemsListForAnonUsersQueryVariables>(GetCartItemsListForAnonUsersDocument, options);
        }
export type GetCartItemsListForAnonUsersQueryHookResult = ReturnType<typeof useGetCartItemsListForAnonUsersQuery>;
export type GetCartItemsListForAnonUsersLazyQueryHookResult = ReturnType<typeof useGetCartItemsListForAnonUsersLazyQuery>;
export type GetCartItemsListForAnonUsersQueryResult = Apollo.QueryResult<Types.GetCartItemsListForAnonUsersQuery, Types.GetCartItemsListForAnonUsersQueryVariables>;
export const GetCartProductsFromBillingPaymentIntentDocument = gql`
    query getCartProductsFromBillingPaymentIntent($cartId: bigint!) {
  getCartProductsFromBillingPaymentIntent(cartId: $cartId) {
    artworkId
    artworkTitle
    primaryMetadataId
    ownerArtistId
    ownerArtistTitle
  }
}
    `;

/**
 * __useGetCartProductsFromBillingPaymentIntentQuery__
 *
 * To run a query within a React component, call `useGetCartProductsFromBillingPaymentIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartProductsFromBillingPaymentIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartProductsFromBillingPaymentIntentQuery({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useGetCartProductsFromBillingPaymentIntentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCartProductsFromBillingPaymentIntentQuery, Types.GetCartProductsFromBillingPaymentIntentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCartProductsFromBillingPaymentIntentQuery, Types.GetCartProductsFromBillingPaymentIntentQueryVariables>(GetCartProductsFromBillingPaymentIntentDocument, options);
      }
export function useGetCartProductsFromBillingPaymentIntentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCartProductsFromBillingPaymentIntentQuery, Types.GetCartProductsFromBillingPaymentIntentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCartProductsFromBillingPaymentIntentQuery, Types.GetCartProductsFromBillingPaymentIntentQueryVariables>(GetCartProductsFromBillingPaymentIntentDocument, options);
        }
export type GetCartProductsFromBillingPaymentIntentQueryHookResult = ReturnType<typeof useGetCartProductsFromBillingPaymentIntentQuery>;
export type GetCartProductsFromBillingPaymentIntentLazyQueryHookResult = ReturnType<typeof useGetCartProductsFromBillingPaymentIntentLazyQuery>;
export type GetCartProductsFromBillingPaymentIntentQueryResult = Apollo.QueryResult<Types.GetCartProductsFromBillingPaymentIntentQuery, Types.GetCartProductsFromBillingPaymentIntentQueryVariables>;
export const GetPaginatedPrintsByEditionByTypeIdsDocument = gql`
    query GetPaginatedPrintsByEditionByTypeIds($edition_by_type_ids: [bigint!]!, $offset: Int = 0, $limit: Int = 10) {
  Print(
    where: {edition_by_type_id: {_in: $edition_by_type_ids}}
    offset: $offset
    limit: $limit
    order_by: [{Edition_By_Type: {id: asc}}, {print_number: asc}]
  ) {
    id
    price
    number: print_number
    status
    Edition_By_Type {
      id
      Edition_Type {
        editionType: value
      }
    }
    ...PrintAcceptedTransactions
  }
}
    ${PrintAcceptedTransactionsFragmentDoc}`;

/**
 * __useGetPaginatedPrintsByEditionByTypeIdsQuery__
 *
 * To run a query within a React component, call `useGetPaginatedPrintsByEditionByTypeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedPrintsByEditionByTypeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedPrintsByEditionByTypeIdsQuery({
 *   variables: {
 *      edition_by_type_ids: // value for 'edition_by_type_ids'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPaginatedPrintsByEditionByTypeIdsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPaginatedPrintsByEditionByTypeIdsQuery, Types.GetPaginatedPrintsByEditionByTypeIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPaginatedPrintsByEditionByTypeIdsQuery, Types.GetPaginatedPrintsByEditionByTypeIdsQueryVariables>(GetPaginatedPrintsByEditionByTypeIdsDocument, options);
      }
export function useGetPaginatedPrintsByEditionByTypeIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPaginatedPrintsByEditionByTypeIdsQuery, Types.GetPaginatedPrintsByEditionByTypeIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPaginatedPrintsByEditionByTypeIdsQuery, Types.GetPaginatedPrintsByEditionByTypeIdsQueryVariables>(GetPaginatedPrintsByEditionByTypeIdsDocument, options);
        }
export type GetPaginatedPrintsByEditionByTypeIdsQueryHookResult = ReturnType<typeof useGetPaginatedPrintsByEditionByTypeIdsQuery>;
export type GetPaginatedPrintsByEditionByTypeIdsLazyQueryHookResult = ReturnType<typeof useGetPaginatedPrintsByEditionByTypeIdsLazyQuery>;
export type GetPaginatedPrintsByEditionByTypeIdsQueryResult = Apollo.QueryResult<Types.GetPaginatedPrintsByEditionByTypeIdsQuery, Types.GetPaginatedPrintsByEditionByTypeIdsQueryVariables>;
export const GetNumArtworksNotInCurrentSeriesDocument = gql`
    query GetNumArtworksNotInCurrentSeries($seriesTitle: String, $artistContextId: bigint!, $artworkId: bigint!) {
  Artwork(
    where: {artist_context_id: {_eq: $artistContextId}, is_public: {_eq: true}, deleted_at: {_is_null: true}, id: {_neq: $artworkId}, _not: {artwork_to_collections: {Collection: {name: {_eq: $seriesTitle}, type: {_eq: SERIES}}}}}
  ) {
    id
  }
}
    `;

/**
 * __useGetNumArtworksNotInCurrentSeriesQuery__
 *
 * To run a query within a React component, call `useGetNumArtworksNotInCurrentSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNumArtworksNotInCurrentSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumArtworksNotInCurrentSeriesQuery({
 *   variables: {
 *      seriesTitle: // value for 'seriesTitle'
 *      artistContextId: // value for 'artistContextId'
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetNumArtworksNotInCurrentSeriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNumArtworksNotInCurrentSeriesQuery, Types.GetNumArtworksNotInCurrentSeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNumArtworksNotInCurrentSeriesQuery, Types.GetNumArtworksNotInCurrentSeriesQueryVariables>(GetNumArtworksNotInCurrentSeriesDocument, options);
      }
export function useGetNumArtworksNotInCurrentSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNumArtworksNotInCurrentSeriesQuery, Types.GetNumArtworksNotInCurrentSeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNumArtworksNotInCurrentSeriesQuery, Types.GetNumArtworksNotInCurrentSeriesQueryVariables>(GetNumArtworksNotInCurrentSeriesDocument, options);
        }
export type GetNumArtworksNotInCurrentSeriesQueryHookResult = ReturnType<typeof useGetNumArtworksNotInCurrentSeriesQuery>;
export type GetNumArtworksNotInCurrentSeriesLazyQueryHookResult = ReturnType<typeof useGetNumArtworksNotInCurrentSeriesLazyQuery>;
export type GetNumArtworksNotInCurrentSeriesQueryResult = Apollo.QueryResult<Types.GetNumArtworksNotInCurrentSeriesQuery, Types.GetNumArtworksNotInCurrentSeriesQueryVariables>;
export const GetArtworksNotInCurrentSeriesDocument = gql`
    query GetArtworksNotInCurrentSeries($seriesTitle: String, $artistContextId: bigint!, $offset: Int!, $limit: Int!, $artworkId: bigint!) {
  Artwork(
    where: {artist_context_id: {_eq: $artistContextId}, is_public: {_eq: true}, deleted_at: {_is_null: true}, id: {_neq: $artworkId}, _not: {artwork_to_collections: {Collection: {name: {_eq: $seriesTitle}, type: {_eq: SERIES}}}}}
    offset: $offset
    limit: $limit
  ) {
    slug
    id
    primary_image_metadata_id
  }
}
    `;

/**
 * __useGetArtworksNotInCurrentSeriesQuery__
 *
 * To run a query within a React component, call `useGetArtworksNotInCurrentSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworksNotInCurrentSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworksNotInCurrentSeriesQuery({
 *   variables: {
 *      seriesTitle: // value for 'seriesTitle'
 *      artistContextId: // value for 'artistContextId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetArtworksNotInCurrentSeriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworksNotInCurrentSeriesQuery, Types.GetArtworksNotInCurrentSeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworksNotInCurrentSeriesQuery, Types.GetArtworksNotInCurrentSeriesQueryVariables>(GetArtworksNotInCurrentSeriesDocument, options);
      }
export function useGetArtworksNotInCurrentSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworksNotInCurrentSeriesQuery, Types.GetArtworksNotInCurrentSeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworksNotInCurrentSeriesQuery, Types.GetArtworksNotInCurrentSeriesQueryVariables>(GetArtworksNotInCurrentSeriesDocument, options);
        }
export type GetArtworksNotInCurrentSeriesQueryHookResult = ReturnType<typeof useGetArtworksNotInCurrentSeriesQuery>;
export type GetArtworksNotInCurrentSeriesLazyQueryHookResult = ReturnType<typeof useGetArtworksNotInCurrentSeriesLazyQuery>;
export type GetArtworksNotInCurrentSeriesQueryResult = Apollo.QueryResult<Types.GetArtworksNotInCurrentSeriesQuery, Types.GetArtworksNotInCurrentSeriesQueryVariables>;
export const GetNumArtworksInCurrentSeriesDocument = gql`
    query GetNumArtworksInCurrentSeries($seriesTitle: String, $artistContextId: bigint!, $artworkId: bigint!) {
  Artwork(
    where: {artist_context_id: {_eq: $artistContextId}, is_public: {_eq: true}, deleted_at: {_is_null: true}, id: {_neq: $artworkId}, _and: {artwork_to_collections: {Collection: {name: {_eq: $seriesTitle}, type: {_eq: SERIES}}}}}
  ) {
    id
  }
}
    `;

/**
 * __useGetNumArtworksInCurrentSeriesQuery__
 *
 * To run a query within a React component, call `useGetNumArtworksInCurrentSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNumArtworksInCurrentSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumArtworksInCurrentSeriesQuery({
 *   variables: {
 *      seriesTitle: // value for 'seriesTitle'
 *      artistContextId: // value for 'artistContextId'
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetNumArtworksInCurrentSeriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetNumArtworksInCurrentSeriesQuery, Types.GetNumArtworksInCurrentSeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetNumArtworksInCurrentSeriesQuery, Types.GetNumArtworksInCurrentSeriesQueryVariables>(GetNumArtworksInCurrentSeriesDocument, options);
      }
export function useGetNumArtworksInCurrentSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetNumArtworksInCurrentSeriesQuery, Types.GetNumArtworksInCurrentSeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetNumArtworksInCurrentSeriesQuery, Types.GetNumArtworksInCurrentSeriesQueryVariables>(GetNumArtworksInCurrentSeriesDocument, options);
        }
export type GetNumArtworksInCurrentSeriesQueryHookResult = ReturnType<typeof useGetNumArtworksInCurrentSeriesQuery>;
export type GetNumArtworksInCurrentSeriesLazyQueryHookResult = ReturnType<typeof useGetNumArtworksInCurrentSeriesLazyQuery>;
export type GetNumArtworksInCurrentSeriesQueryResult = Apollo.QueryResult<Types.GetNumArtworksInCurrentSeriesQuery, Types.GetNumArtworksInCurrentSeriesQueryVariables>;
export const GetArtworksInCurrentSeriesDocument = gql`
    query GetArtworksInCurrentSeries($seriesTitle: String, $artistContextId: bigint!, $offset: Int!, $limit: Int!, $artworkId: bigint!) {
  Artwork(
    where: {artist_context_id: {_eq: $artistContextId}, is_public: {_eq: true}, deleted_at: {_is_null: true}, id: {_neq: $artworkId}, _and: {artwork_to_collections: {Collection: {name: {_eq: $seriesTitle}, type: {_eq: SERIES}}}}}
    offset: $offset
    limit: $limit
  ) {
    slug
    id
    primary_image_metadata_id
  }
}
    `;

/**
 * __useGetArtworksInCurrentSeriesQuery__
 *
 * To run a query within a React component, call `useGetArtworksInCurrentSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworksInCurrentSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworksInCurrentSeriesQuery({
 *   variables: {
 *      seriesTitle: // value for 'seriesTitle'
 *      artistContextId: // value for 'artistContextId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetArtworksInCurrentSeriesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtworksInCurrentSeriesQuery, Types.GetArtworksInCurrentSeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtworksInCurrentSeriesQuery, Types.GetArtworksInCurrentSeriesQueryVariables>(GetArtworksInCurrentSeriesDocument, options);
      }
export function useGetArtworksInCurrentSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtworksInCurrentSeriesQuery, Types.GetArtworksInCurrentSeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtworksInCurrentSeriesQuery, Types.GetArtworksInCurrentSeriesQueryVariables>(GetArtworksInCurrentSeriesDocument, options);
        }
export type GetArtworksInCurrentSeriesQueryHookResult = ReturnType<typeof useGetArtworksInCurrentSeriesQuery>;
export type GetArtworksInCurrentSeriesLazyQueryHookResult = ReturnType<typeof useGetArtworksInCurrentSeriesLazyQuery>;
export type GetArtworksInCurrentSeriesQueryResult = Apollo.QueryResult<Types.GetArtworksInCurrentSeriesQuery, Types.GetArtworksInCurrentSeriesQueryVariables>;
export const GetShowcaseDetailsByHandleAndSlugDocument = gql`
    query GetShowcaseDetailsByHandleAndSlug($slug: String!, $handle: String!) {
  Artwork(
    where: {slug: {_eq: $slug}, is_public: {_eq: true}, artwork_to_collections: {Collection: {type: {_in: [OWNED, CREATED, MANAGED]}, owner_context: {profile: {handle: {_ilike: $handle}}}}}}
  ) {
    id
    vuforia_rating
    target_id
    pending_vuforia_image_path
    edition_type
    artist_context_id
    artist_context {
      profile {
        avatar
        id
        statement
        avatar_metadata {
          id
        }
        first_name
        last_name
        title
      }
      locations {
        city
        country
      }
    }
    artwork_details {
      id
      creation_date_day
      creation_date_month
      creation_date_year
      height
      width
      depth
      measuring_unit
      materials_and_medium
      discipline_other
      discipline
      title
      location
      location_address
      location_map_id
      ENUM_artwork_discipline {
        description
      }
      art_type
      blockchain
      ENUM_blockchain {
        value
        description
      }
      blockchain_other
      nft_url
      nft_copies
    }
    artwork_story {
      statement
    }
    gallery_context_id
    gallery_context {
      profile {
        id
        handle
        title
        avatar
        avatar_download_link
      }
    }
    transaction_histories(
      where: {_and: [{status: {_eq: ACCEPTED}}, {trashed_at: {_is_null: true}}, {print_id: {_is_null: true}}]}
      order_by: {created_at: desc}
      limit: 1
    ) {
      buyer_context {
        profile {
          id
          is_public
          title
          first_name
          last_name
          handle
          avatar
          avatar_download_link
        }
      }
    }
    artwork_to_collections(where: {Collection: {type: {_eq: SERIES}}}) {
      Collection {
        id
        name
        description
        artwork_to_collections(where: {Artwork: {is_public: {_eq: true}}}) {
          Artwork {
            id
            slug
            primary_image_metadata_id
            primary_image_download_link
          }
        }
      }
    }
    primary_tag: artwork_to_tags(where: {type: {_eq: PRIMARY}}) {
      tag {
        id
        description
        name
      }
    }
    secondary_tags: artwork_to_tags(where: {type: {_eq: SECONDARY}}) {
      tag {
        id
        name
      }
    }
    editions: Editions(
      where: {is_public: {_eq: true}, publish_date: {_is_null: false}, deleted_at: {_is_null: true}}
    ) {
      ...ArtworkShowcaseEdition
    }
    art_prizes(
      where: {Art_prize_winner: {Art_prize_show: {published_at: {_lte: "now()"}, deleted_at: {_is_null: true}}}}
    ) {
      Art_prize_winner {
        position_type
        Art_prize_show {
          title
          prize_show_slug
        }
      }
    }
  }
}
    ${ArtworkShowcaseEditionFragmentDoc}`;

/**
 * __useGetShowcaseDetailsByHandleAndSlugQuery__
 *
 * To run a query within a React component, call `useGetShowcaseDetailsByHandleAndSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowcaseDetailsByHandleAndSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowcaseDetailsByHandleAndSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetShowcaseDetailsByHandleAndSlugQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowcaseDetailsByHandleAndSlugQuery, Types.GetShowcaseDetailsByHandleAndSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowcaseDetailsByHandleAndSlugQuery, Types.GetShowcaseDetailsByHandleAndSlugQueryVariables>(GetShowcaseDetailsByHandleAndSlugDocument, options);
      }
export function useGetShowcaseDetailsByHandleAndSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowcaseDetailsByHandleAndSlugQuery, Types.GetShowcaseDetailsByHandleAndSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowcaseDetailsByHandleAndSlugQuery, Types.GetShowcaseDetailsByHandleAndSlugQueryVariables>(GetShowcaseDetailsByHandleAndSlugDocument, options);
        }
export type GetShowcaseDetailsByHandleAndSlugQueryHookResult = ReturnType<typeof useGetShowcaseDetailsByHandleAndSlugQuery>;
export type GetShowcaseDetailsByHandleAndSlugLazyQueryHookResult = ReturnType<typeof useGetShowcaseDetailsByHandleAndSlugLazyQuery>;
export type GetShowcaseDetailsByHandleAndSlugQueryResult = Apollo.QueryResult<Types.GetShowcaseDetailsByHandleAndSlugQuery, Types.GetShowcaseDetailsByHandleAndSlugQueryVariables>;
export const GetShowcaseGeneralInfoByHandleAndSlugDocument = gql`
    query GetShowcaseGeneralInfoByHandleAndSlug($slug: String!, $handle: String!) {
  Artwork(
    where: {slug: {_eq: $slug}, is_public: {_eq: true}, artwork_to_collections: {Collection: {type: {_in: [OWNED, CREATED, MANAGED]}, owner_context: {profile: {handle: {_ilike: $handle}}}}}}
  ) {
    id
    edition_type
    transaction_histories(
      where: {_and: [{status: {_eq: ACCEPTED}}, {trashed_at: {_is_null: true}}, {print_id: {_is_null: true}}]}
    ) {
      artwork_id
    }
    artist_context_id
    is_available_sale
    primary_image_download_link
    primary_image_metadata {
      extra
    }
    artwork_details {
      height
      width
      depth
      measuring_unit
      title
      is_dark_mode
      ENUM_artwork_discipline {
        description
      }
    }
    artwork_story {
      id
      artwork_story_media(order_by: {order: asc}) {
        order
        artwork_story_media_download_link
        artwork_story_media_metadata {
          extra
        }
      }
      story_audio_download_link
      audio_metadata {
        file_name
        extra
      }
      artwork_story_video_links {
        URL
        platform
      }
    }
    vuforia_rating
    artist_context {
      profile {
        id
        avatar
        handle
        title
        avatar_download_link
        audio_download_link
        Valid_profile_statement_audio_syntheses {
          audio_id
          profile_id
          language
        }
        audio_metadata {
          file_name
          extra
        }
      }
      profile_video_links {
        URL
        platform
      }
    }
    artwork_secondary_media(order_by: {order: asc}) {
      artwork_secondary_image_download_link
      file_metadata {
        extra
      }
    }
    artwork_to_collections(where: {Collection: {type: {_eq: SERIES}}}) {
      Collection {
        name
        audio_metadata {
          file_name
          extra
        }
        collection_audio_download_link
        collection_video_links {
          URL
          platform
        }
      }
    }
    collection_for_owners: artwork_to_collections {
      Collection {
        owner_context_id
        type
      }
    }
    Artwork_prices(where: {id: {}, scope: {_eq: SHOWCASE}}) {
      price
      currency
      created_at
      is_public
    }
    Editions(
      where: {is_public: {_eq: true}, publish_date: {_is_null: false}, deleted_at: {_is_null: true}}
    ) {
      id
      price
      currency
      Edition_By_Sizes(where: {status: {_eq: COMPLETE}}) {
        id
        Edition_By_Types(
          where: {status: {_eq: COMPLETE}, Edition_Type: {deleted_at: {_is_null: true}}}
        ) {
          id
        }
      }
    }
    Print_amounts {
      available_print_amount
    }
  }
}
    `;

/**
 * __useGetShowcaseGeneralInfoByHandleAndSlugQuery__
 *
 * To run a query within a React component, call `useGetShowcaseGeneralInfoByHandleAndSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowcaseGeneralInfoByHandleAndSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowcaseGeneralInfoByHandleAndSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetShowcaseGeneralInfoByHandleAndSlugQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowcaseGeneralInfoByHandleAndSlugQuery, Types.GetShowcaseGeneralInfoByHandleAndSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowcaseGeneralInfoByHandleAndSlugQuery, Types.GetShowcaseGeneralInfoByHandleAndSlugQueryVariables>(GetShowcaseGeneralInfoByHandleAndSlugDocument, options);
      }
export function useGetShowcaseGeneralInfoByHandleAndSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowcaseGeneralInfoByHandleAndSlugQuery, Types.GetShowcaseGeneralInfoByHandleAndSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowcaseGeneralInfoByHandleAndSlugQuery, Types.GetShowcaseGeneralInfoByHandleAndSlugQueryVariables>(GetShowcaseGeneralInfoByHandleAndSlugDocument, options);
        }
export type GetShowcaseGeneralInfoByHandleAndSlugQueryHookResult = ReturnType<typeof useGetShowcaseGeneralInfoByHandleAndSlugQuery>;
export type GetShowcaseGeneralInfoByHandleAndSlugLazyQueryHookResult = ReturnType<typeof useGetShowcaseGeneralInfoByHandleAndSlugLazyQuery>;
export type GetShowcaseGeneralInfoByHandleAndSlugQueryResult = Apollo.QueryResult<Types.GetShowcaseGeneralInfoByHandleAndSlugQuery, Types.GetShowcaseGeneralInfoByHandleAndSlugQueryVariables>;
export const GetShowcaseImagesByHandleAndSlugDocument = gql`
    query GetShowcaseImagesByHandleAndSlug($slug: String!, $handle: String!) {
  Artwork(
    where: {slug: {_eq: $slug}, is_public: {_eq: true}, artwork_to_collections: {Collection: {type: {_in: [OWNED, CREATED, MANAGED]}, owner_context: {profile: {handle: {_ilike: $handle}}}}}}
  ) {
    id
    primary_image_metadata_id
    thumbnail_metadata_id
    artwork_story {
      artwork_story_media(order_by: {order: asc}) {
        id
        file_metadata_id
      }
    }
    artwork_secondary_media(order_by: {order: asc}) {
      id
      file_metadata_id
    }
  }
  otherAvailabileArtworks: Artwork(
    limit: 5
    where: {is_available_sale: {_eq: true}, artist_context: {profile: {handle: {_ilike: $handle}}}}
  ) {
    slug
    id
    thumbnail_metadata_id
    primary_image_metadata_id
  }
}
    `;

/**
 * __useGetShowcaseImagesByHandleAndSlugQuery__
 *
 * To run a query within a React component, call `useGetShowcaseImagesByHandleAndSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShowcaseImagesByHandleAndSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShowcaseImagesByHandleAndSlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      handle: // value for 'handle'
 *   },
 * });
 */
export function useGetShowcaseImagesByHandleAndSlugQuery(baseOptions: Apollo.QueryHookOptions<Types.GetShowcaseImagesByHandleAndSlugQuery, Types.GetShowcaseImagesByHandleAndSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetShowcaseImagesByHandleAndSlugQuery, Types.GetShowcaseImagesByHandleAndSlugQueryVariables>(GetShowcaseImagesByHandleAndSlugDocument, options);
      }
export function useGetShowcaseImagesByHandleAndSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetShowcaseImagesByHandleAndSlugQuery, Types.GetShowcaseImagesByHandleAndSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetShowcaseImagesByHandleAndSlugQuery, Types.GetShowcaseImagesByHandleAndSlugQueryVariables>(GetShowcaseImagesByHandleAndSlugDocument, options);
        }
export type GetShowcaseImagesByHandleAndSlugQueryHookResult = ReturnType<typeof useGetShowcaseImagesByHandleAndSlugQuery>;
export type GetShowcaseImagesByHandleAndSlugLazyQueryHookResult = ReturnType<typeof useGetShowcaseImagesByHandleAndSlugLazyQuery>;
export type GetShowcaseImagesByHandleAndSlugQueryResult = Apollo.QueryResult<Types.GetShowcaseImagesByHandleAndSlugQuery, Types.GetShowcaseImagesByHandleAndSlugQueryVariables>;
export const GetAvailablePrintDataByArtworkIdDocument = gql`
    query GetAvailablePrintDataByArtworkId($artworkId: bigint!) {
  artwork: Artwork_by_pk(id: $artworkId) {
    id
    editions: Editions(
      where: {is_available_sale: {_eq: true}, is_public: {_eq: true}, publish_date: {_is_null: false}, deleted_at: {_is_null: true}}
    ) {
      id
      price
      currency
      isAvailableForSale: is_available_sale
      isPricePublic: is_price_public
    }
  }
  artworkPrintAmounts: Print_amounts(
    where: {artwork_id: {_eq: $artworkId}, available_print_amount: {_gt: 0}}
  ) {
    artwork_id
    edition_id
    edition_by_size_id
    edition_by_type_id
    available_print_amount
    unavailable_print_amount
  }
  cheapestAvailablePrint: get_min_price_available_print(
    args: {_artwork_id: $artworkId}
  ) {
    print_id
    price
    edition_by_type_id
    edition_by_size_id
    artwork_id
    edition_id
  }
  cheapestAvailableEditionBySize: get_min_price_available_edition_by_size(
    args: {_artwork_id: $artworkId}
  ) {
    print_id
    price
    edition_by_type_id
    edition_by_size_id
    artwork_id
    edition_id
  }
}
    `;

/**
 * __useGetAvailablePrintDataByArtworkIdQuery__
 *
 * To run a query within a React component, call `useGetAvailablePrintDataByArtworkIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailablePrintDataByArtworkIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailablePrintDataByArtworkIdQuery({
 *   variables: {
 *      artworkId: // value for 'artworkId'
 *   },
 * });
 */
export function useGetAvailablePrintDataByArtworkIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAvailablePrintDataByArtworkIdQuery, Types.GetAvailablePrintDataByArtworkIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAvailablePrintDataByArtworkIdQuery, Types.GetAvailablePrintDataByArtworkIdQueryVariables>(GetAvailablePrintDataByArtworkIdDocument, options);
      }
export function useGetAvailablePrintDataByArtworkIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAvailablePrintDataByArtworkIdQuery, Types.GetAvailablePrintDataByArtworkIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAvailablePrintDataByArtworkIdQuery, Types.GetAvailablePrintDataByArtworkIdQueryVariables>(GetAvailablePrintDataByArtworkIdDocument, options);
        }
export type GetAvailablePrintDataByArtworkIdQueryHookResult = ReturnType<typeof useGetAvailablePrintDataByArtworkIdQuery>;
export type GetAvailablePrintDataByArtworkIdLazyQueryHookResult = ReturnType<typeof useGetAvailablePrintDataByArtworkIdLazyQuery>;
export type GetAvailablePrintDataByArtworkIdQueryResult = Apollo.QueryResult<Types.GetAvailablePrintDataByArtworkIdQuery, Types.GetAvailablePrintDataByArtworkIdQueryVariables>;
export const GetEditionBySizeCheapestPrintDocument = gql`
    query GetEditionBySizeCheapestPrint($editionBySizeId: bigint!) {
  get_min_price_available_print(args: {_edition_by_size_id: $editionBySizeId}) {
    edition_by_size_id
    print_id
    price
  }
}
    `;

/**
 * __useGetEditionBySizeCheapestPrintQuery__
 *
 * To run a query within a React component, call `useGetEditionBySizeCheapestPrintQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditionBySizeCheapestPrintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditionBySizeCheapestPrintQuery({
 *   variables: {
 *      editionBySizeId: // value for 'editionBySizeId'
 *   },
 * });
 */
export function useGetEditionBySizeCheapestPrintQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEditionBySizeCheapestPrintQuery, Types.GetEditionBySizeCheapestPrintQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEditionBySizeCheapestPrintQuery, Types.GetEditionBySizeCheapestPrintQueryVariables>(GetEditionBySizeCheapestPrintDocument, options);
      }
export function useGetEditionBySizeCheapestPrintLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEditionBySizeCheapestPrintQuery, Types.GetEditionBySizeCheapestPrintQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEditionBySizeCheapestPrintQuery, Types.GetEditionBySizeCheapestPrintQueryVariables>(GetEditionBySizeCheapestPrintDocument, options);
        }
export type GetEditionBySizeCheapestPrintQueryHookResult = ReturnType<typeof useGetEditionBySizeCheapestPrintQuery>;
export type GetEditionBySizeCheapestPrintLazyQueryHookResult = ReturnType<typeof useGetEditionBySizeCheapestPrintLazyQuery>;
export type GetEditionBySizeCheapestPrintQueryResult = Apollo.QueryResult<Types.GetEditionBySizeCheapestPrintQuery, Types.GetEditionBySizeCheapestPrintQueryVariables>;
export const CreateSimpleUserProfileDocument = gql`
    mutation createSimpleUserProfile {
  newSimpleCollectorProfile {
    context_id
    profile_id
    profile_settings_id
  }
}
    `;
export type CreateSimpleUserProfileMutationFn = Apollo.MutationFunction<Types.CreateSimpleUserProfileMutation, Types.CreateSimpleUserProfileMutationVariables>;

/**
 * __useCreateSimpleUserProfileMutation__
 *
 * To run a mutation, you first call `useCreateSimpleUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimpleUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimpleUserProfileMutation, { data, loading, error }] = useCreateSimpleUserProfileMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSimpleUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSimpleUserProfileMutation, Types.CreateSimpleUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSimpleUserProfileMutation, Types.CreateSimpleUserProfileMutationVariables>(CreateSimpleUserProfileDocument, options);
      }
export type CreateSimpleUserProfileMutationHookResult = ReturnType<typeof useCreateSimpleUserProfileMutation>;
export type CreateSimpleUserProfileMutationResult = Apollo.MutationResult<Types.CreateSimpleUserProfileMutation>;
export type CreateSimpleUserProfileMutationOptions = Apollo.BaseMutationOptions<Types.CreateSimpleUserProfileMutation, Types.CreateSimpleUserProfileMutationVariables>;
export const SetInitialProfileTypeDocument = gql`
    mutation setInitialProfileType($context_id: bigint!, $type: ENUM_profile_type_enum!) {
  insert_Account_initial_profile_type(
    objects: {context_id: $context_id, type: $type}
    on_conflict: {constraint: Account_initial_profile_type_pkey, update_columns: type}
  ) {
    affected_rows
  }
}
    `;
export type SetInitialProfileTypeMutationFn = Apollo.MutationFunction<Types.SetInitialProfileTypeMutation, Types.SetInitialProfileTypeMutationVariables>;

/**
 * __useSetInitialProfileTypeMutation__
 *
 * To run a mutation, you first call `useSetInitialProfileTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInitialProfileTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInitialProfileTypeMutation, { data, loading, error }] = useSetInitialProfileTypeMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSetInitialProfileTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetInitialProfileTypeMutation, Types.SetInitialProfileTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SetInitialProfileTypeMutation, Types.SetInitialProfileTypeMutationVariables>(SetInitialProfileTypeDocument, options);
      }
export type SetInitialProfileTypeMutationHookResult = ReturnType<typeof useSetInitialProfileTypeMutation>;
export type SetInitialProfileTypeMutationResult = Apollo.MutationResult<Types.SetInitialProfileTypeMutation>;
export type SetInitialProfileTypeMutationOptions = Apollo.BaseMutationOptions<Types.SetInitialProfileTypeMutation, Types.SetInitialProfileTypeMutationVariables>;
export const GetInitialAccountTypeDocument = gql`
    query getInitialAccountType($context_id: bigint!) {
  Account_initial_profile_type_by_pk(context_id: $context_id) {
    type
  }
}
    `;

/**
 * __useGetInitialAccountTypeQuery__
 *
 * To run a query within a React component, call `useGetInitialAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInitialAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInitialAccountTypeQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetInitialAccountTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInitialAccountTypeQuery, Types.GetInitialAccountTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInitialAccountTypeQuery, Types.GetInitialAccountTypeQueryVariables>(GetInitialAccountTypeDocument, options);
      }
export function useGetInitialAccountTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInitialAccountTypeQuery, Types.GetInitialAccountTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInitialAccountTypeQuery, Types.GetInitialAccountTypeQueryVariables>(GetInitialAccountTypeDocument, options);
        }
export type GetInitialAccountTypeQueryHookResult = ReturnType<typeof useGetInitialAccountTypeQuery>;
export type GetInitialAccountTypeLazyQueryHookResult = ReturnType<typeof useGetInitialAccountTypeLazyQuery>;
export type GetInitialAccountTypeQueryResult = Apollo.QueryResult<Types.GetInitialAccountTypeQuery, Types.GetInitialAccountTypeQueryVariables>;
export const GetDefaultAccountNameDocument = gql`
    query getDefaultAccountName($context_id: bigint!) {
  Context_by_pk(id: $context_id) {
    auth_context_accesses {
      auth {
        first_name
        last_name
      }
    }
  }
}
    `;

/**
 * __useGetDefaultAccountNameQuery__
 *
 * To run a query within a React component, call `useGetDefaultAccountNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultAccountNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultAccountNameQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetDefaultAccountNameQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDefaultAccountNameQuery, Types.GetDefaultAccountNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDefaultAccountNameQuery, Types.GetDefaultAccountNameQueryVariables>(GetDefaultAccountNameDocument, options);
      }
export function useGetDefaultAccountNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDefaultAccountNameQuery, Types.GetDefaultAccountNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDefaultAccountNameQuery, Types.GetDefaultAccountNameQueryVariables>(GetDefaultAccountNameDocument, options);
        }
export type GetDefaultAccountNameQueryHookResult = ReturnType<typeof useGetDefaultAccountNameQuery>;
export type GetDefaultAccountNameLazyQueryHookResult = ReturnType<typeof useGetDefaultAccountNameLazyQuery>;
export type GetDefaultAccountNameQueryResult = Apollo.QueryResult<Types.GetDefaultAccountNameQuery, Types.GetDefaultAccountNameQueryVariables>;
export const GetInvitationDataByTokenDocument = gql`
    query getInvitationDataByToken($token_uuid: String!) {
  getInvitationDataByToken(token_uuid: $token_uuid) {
    email
    first_name
    preferred_language
    inviter_context_id
  }
}
    `;

/**
 * __useGetInvitationDataByTokenQuery__
 *
 * To run a query within a React component, call `useGetInvitationDataByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationDataByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationDataByTokenQuery({
 *   variables: {
 *      token_uuid: // value for 'token_uuid'
 *   },
 * });
 */
export function useGetInvitationDataByTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInvitationDataByTokenQuery, Types.GetInvitationDataByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInvitationDataByTokenQuery, Types.GetInvitationDataByTokenQueryVariables>(GetInvitationDataByTokenDocument, options);
      }
export function useGetInvitationDataByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInvitationDataByTokenQuery, Types.GetInvitationDataByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInvitationDataByTokenQuery, Types.GetInvitationDataByTokenQueryVariables>(GetInvitationDataByTokenDocument, options);
        }
export type GetInvitationDataByTokenQueryHookResult = ReturnType<typeof useGetInvitationDataByTokenQuery>;
export type GetInvitationDataByTokenLazyQueryHookResult = ReturnType<typeof useGetInvitationDataByTokenLazyQuery>;
export type GetInvitationDataByTokenQueryResult = Apollo.QueryResult<Types.GetInvitationDataByTokenQuery, Types.GetInvitationDataByTokenQueryVariables>;
export const GetPriceRangesDocument = gql`
    query getPriceRanges {
  Price_Ranges(order_by: {order: asc}) {
    id
    translation_key
    order
  }
}
    `;

/**
 * __useGetPriceRangesQuery__
 *
 * To run a query within a React component, call `useGetPriceRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriceRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriceRangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPriceRangesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPriceRangesQuery, Types.GetPriceRangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPriceRangesQuery, Types.GetPriceRangesQueryVariables>(GetPriceRangesDocument, options);
      }
export function useGetPriceRangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPriceRangesQuery, Types.GetPriceRangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPriceRangesQuery, Types.GetPriceRangesQueryVariables>(GetPriceRangesDocument, options);
        }
export type GetPriceRangesQueryHookResult = ReturnType<typeof useGetPriceRangesQuery>;
export type GetPriceRangesLazyQueryHookResult = ReturnType<typeof useGetPriceRangesLazyQuery>;
export type GetPriceRangesQueryResult = Apollo.QueryResult<Types.GetPriceRangesQuery, Types.GetPriceRangesQueryVariables>;
export const MarkProfilePriceRangesDocument = gql`
    mutation markProfilePriceRanges($price_range_ids: [Int!]!, $context_id: Int!) {
  markProfilePriceRanges(
    price_range_ids: $price_range_ids
    context_id: $context_id
  )
}
    `;
export type MarkProfilePriceRangesMutationFn = Apollo.MutationFunction<Types.MarkProfilePriceRangesMutation, Types.MarkProfilePriceRangesMutationVariables>;

/**
 * __useMarkProfilePriceRangesMutation__
 *
 * To run a mutation, you first call `useMarkProfilePriceRangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkProfilePriceRangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markProfilePriceRangesMutation, { data, loading, error }] = useMarkProfilePriceRangesMutation({
 *   variables: {
 *      price_range_ids: // value for 'price_range_ids'
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useMarkProfilePriceRangesMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkProfilePriceRangesMutation, Types.MarkProfilePriceRangesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkProfilePriceRangesMutation, Types.MarkProfilePriceRangesMutationVariables>(MarkProfilePriceRangesDocument, options);
      }
export type MarkProfilePriceRangesMutationHookResult = ReturnType<typeof useMarkProfilePriceRangesMutation>;
export type MarkProfilePriceRangesMutationResult = Apollo.MutationResult<Types.MarkProfilePriceRangesMutation>;
export type MarkProfilePriceRangesMutationOptions = Apollo.BaseMutationOptions<Types.MarkProfilePriceRangesMutation, Types.MarkProfilePriceRangesMutationVariables>;
export const SendProfilePublicationReminderDocument = gql`
    mutation sendProfilePublicationReminder($context_id: Int!) {
  sendProfilePublicationReminder(context_id: $context_id)
}
    `;
export type SendProfilePublicationReminderMutationFn = Apollo.MutationFunction<Types.SendProfilePublicationReminderMutation, Types.SendProfilePublicationReminderMutationVariables>;

/**
 * __useSendProfilePublicationReminderMutation__
 *
 * To run a mutation, you first call `useSendProfilePublicationReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendProfilePublicationReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendProfilePublicationReminderMutation, { data, loading, error }] = useSendProfilePublicationReminderMutation({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useSendProfilePublicationReminderMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendProfilePublicationReminderMutation, Types.SendProfilePublicationReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendProfilePublicationReminderMutation, Types.SendProfilePublicationReminderMutationVariables>(SendProfilePublicationReminderDocument, options);
      }
export type SendProfilePublicationReminderMutationHookResult = ReturnType<typeof useSendProfilePublicationReminderMutation>;
export type SendProfilePublicationReminderMutationResult = Apollo.MutationResult<Types.SendProfilePublicationReminderMutation>;
export type SendProfilePublicationReminderMutationOptions = Apollo.BaseMutationOptions<Types.SendProfilePublicationReminderMutation, Types.SendProfilePublicationReminderMutationVariables>;
export const RequestAccountValidationDocument = gql`
    mutation requestAccountValidation($email: String!, $firstName: String!, $languagePreference: String!, $lastName: String!, $action: String, $actionId: Int, $isCustomDomain: Boolean) {
  requestAccountValidation(
    email: $email
    firstName: $firstName
    languagePreference: $languagePreference
    lastName: $lastName
    action: $action
    actionId: $actionId
    isCustomDomain: $isCustomDomain
  ) {
    token
  }
}
    `;
export type RequestAccountValidationMutationFn = Apollo.MutationFunction<Types.RequestAccountValidationMutation, Types.RequestAccountValidationMutationVariables>;

/**
 * __useRequestAccountValidationMutation__
 *
 * To run a mutation, you first call `useRequestAccountValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccountValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccountValidationMutation, { data, loading, error }] = useRequestAccountValidationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      languagePreference: // value for 'languagePreference'
 *      lastName: // value for 'lastName'
 *      action: // value for 'action'
 *      actionId: // value for 'actionId'
 *      isCustomDomain: // value for 'isCustomDomain'
 *   },
 * });
 */
export function useRequestAccountValidationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestAccountValidationMutation, Types.RequestAccountValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestAccountValidationMutation, Types.RequestAccountValidationMutationVariables>(RequestAccountValidationDocument, options);
      }
export type RequestAccountValidationMutationHookResult = ReturnType<typeof useRequestAccountValidationMutation>;
export type RequestAccountValidationMutationResult = Apollo.MutationResult<Types.RequestAccountValidationMutation>;
export type RequestAccountValidationMutationOptions = Apollo.BaseMutationOptions<Types.RequestAccountValidationMutation, Types.RequestAccountValidationMutationVariables>;
export const FirebaseSignUpDocument = gql`
    mutation firebaseSignUp($email: String!, $password: String!, $token: String!, $invitationToken: String) {
  firebaseSignUpNew(
    password: $password
    token: $token
    email: $email
    invitationToken: $invitationToken
  ) {
    message
    valid
  }
}
    `;
export type FirebaseSignUpMutationFn = Apollo.MutationFunction<Types.FirebaseSignUpMutation, Types.FirebaseSignUpMutationVariables>;

/**
 * __useFirebaseSignUpMutation__
 *
 * To run a mutation, you first call `useFirebaseSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFirebaseSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [firebaseSignUpMutation, { data, loading, error }] = useFirebaseSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useFirebaseSignUpMutation(baseOptions?: Apollo.MutationHookOptions<Types.FirebaseSignUpMutation, Types.FirebaseSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FirebaseSignUpMutation, Types.FirebaseSignUpMutationVariables>(FirebaseSignUpDocument, options);
      }
export type FirebaseSignUpMutationHookResult = ReturnType<typeof useFirebaseSignUpMutation>;
export type FirebaseSignUpMutationResult = Apollo.MutationResult<Types.FirebaseSignUpMutation>;
export type FirebaseSignUpMutationOptions = Apollo.BaseMutationOptions<Types.FirebaseSignUpMutation, Types.FirebaseSignUpMutationVariables>;
export const FirebaseSignUpFullContentDocument = gql`
    mutation firebaseSignUpFullContent($email: String!, $password: String!, $token: String!, $firstName: String!, $lastName: String!, $languagePreference: String!, $invitationToken: String) {
  firebaseSignUpNewFullContent(
    password: $password
    token: $token
    email: $email
    firstName: $firstName
    lastName: $lastName
    languagePreference: $languagePreference
    invitationToken: $invitationToken
  ) {
    message
    valid
  }
}
    `;
export type FirebaseSignUpFullContentMutationFn = Apollo.MutationFunction<Types.FirebaseSignUpFullContentMutation, Types.FirebaseSignUpFullContentMutationVariables>;

/**
 * __useFirebaseSignUpFullContentMutation__
 *
 * To run a mutation, you first call `useFirebaseSignUpFullContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFirebaseSignUpFullContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [firebaseSignUpFullContentMutation, { data, loading, error }] = useFirebaseSignUpFullContentMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      languagePreference: // value for 'languagePreference'
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useFirebaseSignUpFullContentMutation(baseOptions?: Apollo.MutationHookOptions<Types.FirebaseSignUpFullContentMutation, Types.FirebaseSignUpFullContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FirebaseSignUpFullContentMutation, Types.FirebaseSignUpFullContentMutationVariables>(FirebaseSignUpFullContentDocument, options);
      }
export type FirebaseSignUpFullContentMutationHookResult = ReturnType<typeof useFirebaseSignUpFullContentMutation>;
export type FirebaseSignUpFullContentMutationResult = Apollo.MutationResult<Types.FirebaseSignUpFullContentMutation>;
export type FirebaseSignUpFullContentMutationOptions = Apollo.BaseMutationOptions<Types.FirebaseSignUpFullContentMutation, Types.FirebaseSignUpFullContentMutationVariables>;
export const GetCollectorOnboardingPendingArtworksDocument = gql`
    query getCollectorOnboardingPendingArtworks {
  getMyTransactionsPreview {
    transactions {
      id
      slug
      primary_image_metadata_id
      artwork_details_title
      artist_context_profile_title
      artist_context_profile_handle
    }
    number_of_pending_transactions
  }
}
    `;

/**
 * __useGetCollectorOnboardingPendingArtworksQuery__
 *
 * To run a query within a React component, call `useGetCollectorOnboardingPendingArtworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorOnboardingPendingArtworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorOnboardingPendingArtworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCollectorOnboardingPendingArtworksQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetCollectorOnboardingPendingArtworksQuery, Types.GetCollectorOnboardingPendingArtworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorOnboardingPendingArtworksQuery, Types.GetCollectorOnboardingPendingArtworksQueryVariables>(GetCollectorOnboardingPendingArtworksDocument, options);
      }
export function useGetCollectorOnboardingPendingArtworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorOnboardingPendingArtworksQuery, Types.GetCollectorOnboardingPendingArtworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorOnboardingPendingArtworksQuery, Types.GetCollectorOnboardingPendingArtworksQueryVariables>(GetCollectorOnboardingPendingArtworksDocument, options);
        }
export type GetCollectorOnboardingPendingArtworksQueryHookResult = ReturnType<typeof useGetCollectorOnboardingPendingArtworksQuery>;
export type GetCollectorOnboardingPendingArtworksLazyQueryHookResult = ReturnType<typeof useGetCollectorOnboardingPendingArtworksLazyQuery>;
export type GetCollectorOnboardingPendingArtworksQueryResult = Apollo.QueryResult<Types.GetCollectorOnboardingPendingArtworksQuery, Types.GetCollectorOnboardingPendingArtworksQueryVariables>;
export const GetCollectorOnboardingPendingArtworksNoJwtDocument = gql`
    query getCollectorOnboardingPendingArtworksNoJWT($email: String!) {
  getMyTransactionsPreviewNoJWT(email: $email) {
    transactions {
      id
      slug
      primary_image_metadata_id
      artwork_details_title
      artist_context_profile_title
      artist_context_profile_handle
    }
    number_of_pending_transactions
  }
}
    `;

/**
 * __useGetCollectorOnboardingPendingArtworksNoJwtQuery__
 *
 * To run a query within a React component, call `useGetCollectorOnboardingPendingArtworksNoJwtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectorOnboardingPendingArtworksNoJwtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectorOnboardingPendingArtworksNoJwtQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCollectorOnboardingPendingArtworksNoJwtQuery(baseOptions: Apollo.QueryHookOptions<Types.GetCollectorOnboardingPendingArtworksNoJwtQuery, Types.GetCollectorOnboardingPendingArtworksNoJwtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetCollectorOnboardingPendingArtworksNoJwtQuery, Types.GetCollectorOnboardingPendingArtworksNoJwtQueryVariables>(GetCollectorOnboardingPendingArtworksNoJwtDocument, options);
      }
export function useGetCollectorOnboardingPendingArtworksNoJwtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetCollectorOnboardingPendingArtworksNoJwtQuery, Types.GetCollectorOnboardingPendingArtworksNoJwtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetCollectorOnboardingPendingArtworksNoJwtQuery, Types.GetCollectorOnboardingPendingArtworksNoJwtQueryVariables>(GetCollectorOnboardingPendingArtworksNoJwtDocument, options);
        }
export type GetCollectorOnboardingPendingArtworksNoJwtQueryHookResult = ReturnType<typeof useGetCollectorOnboardingPendingArtworksNoJwtQuery>;
export type GetCollectorOnboardingPendingArtworksNoJwtLazyQueryHookResult = ReturnType<typeof useGetCollectorOnboardingPendingArtworksNoJwtLazyQuery>;
export type GetCollectorOnboardingPendingArtworksNoJwtQueryResult = Apollo.QueryResult<Types.GetCollectorOnboardingPendingArtworksNoJwtQuery, Types.GetCollectorOnboardingPendingArtworksNoJwtQueryVariables>;
export const GetArtistDataByContextIdDocument = gql`
    query getArtistDataByContextId($context_id: bigint!) {
  Profile(where: {context: {id: {_eq: $context_id}}}) {
    avatar
    id
    title
    context {
      locations {
        city
        country
      }
    }
  }
}
    `;

/**
 * __useGetArtistDataByContextIdQuery__
 *
 * To run a query within a React component, call `useGetArtistDataByContextIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistDataByContextIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistDataByContextIdQuery({
 *   variables: {
 *      context_id: // value for 'context_id'
 *   },
 * });
 */
export function useGetArtistDataByContextIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetArtistDataByContextIdQuery, Types.GetArtistDataByContextIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetArtistDataByContextIdQuery, Types.GetArtistDataByContextIdQueryVariables>(GetArtistDataByContextIdDocument, options);
      }
export function useGetArtistDataByContextIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetArtistDataByContextIdQuery, Types.GetArtistDataByContextIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetArtistDataByContextIdQuery, Types.GetArtistDataByContextIdQueryVariables>(GetArtistDataByContextIdDocument, options);
        }
export type GetArtistDataByContextIdQueryHookResult = ReturnType<typeof useGetArtistDataByContextIdQuery>;
export type GetArtistDataByContextIdLazyQueryHookResult = ReturnType<typeof useGetArtistDataByContextIdLazyQuery>;
export type GetArtistDataByContextIdQueryResult = Apollo.QueryResult<Types.GetArtistDataByContextIdQuery, Types.GetArtistDataByContextIdQueryVariables>;
export const CheckoutSuperSiteDocument = gql`
    mutation checkoutSuperSite($artistContextId: bigint!, $domain: CheckoutSuperSiteDomainInput, $subscriptionPeriod: SubscriptionPeriod!, $redirectUrl: String!) {
  checkoutSuperSite(
    artistContextId: $artistContextId
    domain: $domain
    period: $subscriptionPeriod
    redirectUrl: $redirectUrl
  ) {
    checkoutUrl
  }
}
    `;
export type CheckoutSuperSiteMutationFn = Apollo.MutationFunction<Types.CheckoutSuperSiteMutation, Types.CheckoutSuperSiteMutationVariables>;

/**
 * __useCheckoutSuperSiteMutation__
 *
 * To run a mutation, you first call `useCheckoutSuperSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSuperSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutSuperSiteMutation, { data, loading, error }] = useCheckoutSuperSiteMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      domain: // value for 'domain'
 *      subscriptionPeriod: // value for 'subscriptionPeriod'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCheckoutSuperSiteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutSuperSiteMutation, Types.CheckoutSuperSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutSuperSiteMutation, Types.CheckoutSuperSiteMutationVariables>(CheckoutSuperSiteDocument, options);
      }
export type CheckoutSuperSiteMutationHookResult = ReturnType<typeof useCheckoutSuperSiteMutation>;
export type CheckoutSuperSiteMutationResult = Apollo.MutationResult<Types.CheckoutSuperSiteMutation>;
export type CheckoutSuperSiteMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutSuperSiteMutation, Types.CheckoutSuperSiteMutationVariables>;
export const CheckoutDomainDocument = gql`
    mutation checkoutDomain($artistContextId: bigint!, $domain: CheckoutDomainInput!, $redirectUrl: String!) {
  checkoutDomain(
    artistContextId: $artistContextId
    domain: $domain
    redirectUrl: $redirectUrl
  ) {
    checkoutUrl
  }
}
    `;
export type CheckoutDomainMutationFn = Apollo.MutationFunction<Types.CheckoutDomainMutation, Types.CheckoutDomainMutationVariables>;

/**
 * __useCheckoutDomainMutation__
 *
 * To run a mutation, you first call `useCheckoutDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutDomainMutation, { data, loading, error }] = useCheckoutDomainMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      domain: // value for 'domain'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCheckoutDomainMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckoutDomainMutation, Types.CheckoutDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckoutDomainMutation, Types.CheckoutDomainMutationVariables>(CheckoutDomainDocument, options);
      }
export type CheckoutDomainMutationHookResult = ReturnType<typeof useCheckoutDomainMutation>;
export type CheckoutDomainMutationResult = Apollo.MutationResult<Types.CheckoutDomainMutation>;
export type CheckoutDomainMutationOptions = Apollo.BaseMutationOptions<Types.CheckoutDomainMutation, Types.CheckoutDomainMutationVariables>;
export const GetFinalPriceUsingDiscountCodeDocument = gql`
    query getFinalPriceUsingDiscountCode($contextId: bigint!, $discountCode: String!, $originalPrice: Float!, $purchasedPlan: String!) {
  getFinalPriceUsingDiscountCode(
    contextId: $contextId
    discountCode: $discountCode
    originalPrice: $originalPrice
    purchasedItem: $purchasedPlan
  ) {
    context_id
    discount_code_id
    discount_value
    discount_value_type
    email
    original_price
    final_discount
    final_price
    purchased_item
    trial_period_days
  }
}
    `;

/**
 * __useGetFinalPriceUsingDiscountCodeQuery__
 *
 * To run a query within a React component, call `useGetFinalPriceUsingDiscountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinalPriceUsingDiscountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinalPriceUsingDiscountCodeQuery({
 *   variables: {
 *      contextId: // value for 'contextId'
 *      discountCode: // value for 'discountCode'
 *      originalPrice: // value for 'originalPrice'
 *      purchasedPlan: // value for 'purchasedPlan'
 *   },
 * });
 */
export function useGetFinalPriceUsingDiscountCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetFinalPriceUsingDiscountCodeQuery, Types.GetFinalPriceUsingDiscountCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetFinalPriceUsingDiscountCodeQuery, Types.GetFinalPriceUsingDiscountCodeQueryVariables>(GetFinalPriceUsingDiscountCodeDocument, options);
      }
export function useGetFinalPriceUsingDiscountCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetFinalPriceUsingDiscountCodeQuery, Types.GetFinalPriceUsingDiscountCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetFinalPriceUsingDiscountCodeQuery, Types.GetFinalPriceUsingDiscountCodeQueryVariables>(GetFinalPriceUsingDiscountCodeDocument, options);
        }
export type GetFinalPriceUsingDiscountCodeQueryHookResult = ReturnType<typeof useGetFinalPriceUsingDiscountCodeQuery>;
export type GetFinalPriceUsingDiscountCodeLazyQueryHookResult = ReturnType<typeof useGetFinalPriceUsingDiscountCodeLazyQuery>;
export type GetFinalPriceUsingDiscountCodeQueryResult = Apollo.QueryResult<Types.GetFinalPriceUsingDiscountCodeQuery, Types.GetFinalPriceUsingDiscountCodeQueryVariables>;
export const GetDomainRegistrationPreferencesDocument = gql`
    query getDomainRegistrationPreferences($artistContextId: bigint!) {
  getDomainRegistrationPreferences(artistContextId: $artistContextId) {
    domain {
      domainName
      registerPrice {
        amount
        currency
      }
      renewalPrice {
        amount
        currency
      }
    }
    registrantInfo {
      firstName
      lastName
      address1
      city
      stateProvince
      postalCode
      country
      phone
    }
  }
}
    `;

/**
 * __useGetDomainRegistrationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetDomainRegistrationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainRegistrationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainRegistrationPreferencesQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useGetDomainRegistrationPreferencesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDomainRegistrationPreferencesQuery, Types.GetDomainRegistrationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDomainRegistrationPreferencesQuery, Types.GetDomainRegistrationPreferencesQueryVariables>(GetDomainRegistrationPreferencesDocument, options);
      }
export function useGetDomainRegistrationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDomainRegistrationPreferencesQuery, Types.GetDomainRegistrationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDomainRegistrationPreferencesQuery, Types.GetDomainRegistrationPreferencesQueryVariables>(GetDomainRegistrationPreferencesDocument, options);
        }
export type GetDomainRegistrationPreferencesQueryHookResult = ReturnType<typeof useGetDomainRegistrationPreferencesQuery>;
export type GetDomainRegistrationPreferencesLazyQueryHookResult = ReturnType<typeof useGetDomainRegistrationPreferencesLazyQuery>;
export type GetDomainRegistrationPreferencesQueryResult = Apollo.QueryResult<Types.GetDomainRegistrationPreferencesQuery, Types.GetDomainRegistrationPreferencesQueryVariables>;
export const GetDomainSuggestionsDocument = gql`
    query GetDomainSuggestions($domainName: String!) {
  getDomainSuggestions(domainName: $domainName) {
    domains {
      domainName
      registerPrice {
        amount
        currency
      }
      renewalPrice {
        amount
        currency
      }
      availability
    }
  }
}
    `;

/**
 * __useGetDomainSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetDomainSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainSuggestionsQuery({
 *   variables: {
 *      domainName: // value for 'domainName'
 *   },
 * });
 */
export function useGetDomainSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetDomainSuggestionsQuery, Types.GetDomainSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetDomainSuggestionsQuery, Types.GetDomainSuggestionsQueryVariables>(GetDomainSuggestionsDocument, options);
      }
export function useGetDomainSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetDomainSuggestionsQuery, Types.GetDomainSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetDomainSuggestionsQuery, Types.GetDomainSuggestionsQueryVariables>(GetDomainSuggestionsDocument, options);
        }
export type GetDomainSuggestionsQueryHookResult = ReturnType<typeof useGetDomainSuggestionsQuery>;
export type GetDomainSuggestionsLazyQueryHookResult = ReturnType<typeof useGetDomainSuggestionsLazyQuery>;
export type GetDomainSuggestionsQueryResult = Apollo.QueryResult<Types.GetDomainSuggestionsQuery, Types.GetDomainSuggestionsQueryVariables>;
export const GetProrationForSubscriptionUpdateDocument = gql`
    query GetProrationForSubscriptionUpdate($artistContextId: bigint!, $period: SubscriptionPeriod!) {
  getProrationForSubscriptionUpdate(
    artistContextId: $artistContextId
    period: $period
  ) {
    prorationAmount
    prorationDate
  }
}
    `;

/**
 * __useGetProrationForSubscriptionUpdateQuery__
 *
 * To run a query within a React component, call `useGetProrationForSubscriptionUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProrationForSubscriptionUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProrationForSubscriptionUpdateQuery({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetProrationForSubscriptionUpdateQuery(baseOptions: Apollo.QueryHookOptions<Types.GetProrationForSubscriptionUpdateQuery, Types.GetProrationForSubscriptionUpdateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetProrationForSubscriptionUpdateQuery, Types.GetProrationForSubscriptionUpdateQueryVariables>(GetProrationForSubscriptionUpdateDocument, options);
      }
export function useGetProrationForSubscriptionUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetProrationForSubscriptionUpdateQuery, Types.GetProrationForSubscriptionUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetProrationForSubscriptionUpdateQuery, Types.GetProrationForSubscriptionUpdateQueryVariables>(GetProrationForSubscriptionUpdateDocument, options);
        }
export type GetProrationForSubscriptionUpdateQueryHookResult = ReturnType<typeof useGetProrationForSubscriptionUpdateQuery>;
export type GetProrationForSubscriptionUpdateLazyQueryHookResult = ReturnType<typeof useGetProrationForSubscriptionUpdateLazyQuery>;
export type GetProrationForSubscriptionUpdateQueryResult = Apollo.QueryResult<Types.GetProrationForSubscriptionUpdateQuery, Types.GetProrationForSubscriptionUpdateQueryVariables>;
export const MarkDomainRegistrationAsFailedDocument = gql`
    mutation markDomainRegistrationAsFailed($artistContextId: bigint!) {
  markDomainRegistrationAsFailed(ctxId: $artistContextId)
}
    `;
export type MarkDomainRegistrationAsFailedMutationFn = Apollo.MutationFunction<Types.MarkDomainRegistrationAsFailedMutation, Types.MarkDomainRegistrationAsFailedMutationVariables>;

/**
 * __useMarkDomainRegistrationAsFailedMutation__
 *
 * To run a mutation, you first call `useMarkDomainRegistrationAsFailedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDomainRegistrationAsFailedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDomainRegistrationAsFailedMutation, { data, loading, error }] = useMarkDomainRegistrationAsFailedMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *   },
 * });
 */
export function useMarkDomainRegistrationAsFailedMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkDomainRegistrationAsFailedMutation, Types.MarkDomainRegistrationAsFailedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkDomainRegistrationAsFailedMutation, Types.MarkDomainRegistrationAsFailedMutationVariables>(MarkDomainRegistrationAsFailedDocument, options);
      }
export type MarkDomainRegistrationAsFailedMutationHookResult = ReturnType<typeof useMarkDomainRegistrationAsFailedMutation>;
export type MarkDomainRegistrationAsFailedMutationResult = Apollo.MutationResult<Types.MarkDomainRegistrationAsFailedMutation>;
export type MarkDomainRegistrationAsFailedMutationOptions = Apollo.BaseMutationOptions<Types.MarkDomainRegistrationAsFailedMutation, Types.MarkDomainRegistrationAsFailedMutationVariables>;
export const UpdateSubscriptionPlanDocument = gql`
    mutation UpdateSubscriptionPlan($artistContextId: bigint!, $period: SubscriptionPeriod!, $prorationDate: Int!, $discountCode: DiscountCodeInput) {
  updateSubscriptionPlan(
    artistContextId: $artistContextId
    period: $period
    prorationDate: $prorationDate
    discountCode: $discountCode
  )
}
    `;
export type UpdateSubscriptionPlanMutationFn = Apollo.MutationFunction<Types.UpdateSubscriptionPlanMutation, Types.UpdateSubscriptionPlanMutationVariables>;

/**
 * __useUpdateSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionPlanMutation, { data, loading, error }] = useUpdateSubscriptionPlanMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      period: // value for 'period'
 *      prorationDate: // value for 'prorationDate'
 *      discountCode: // value for 'discountCode'
 *   },
 * });
 */
export function useUpdateSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSubscriptionPlanMutation, Types.UpdateSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSubscriptionPlanMutation, Types.UpdateSubscriptionPlanMutationVariables>(UpdateSubscriptionPlanDocument, options);
      }
export type UpdateSubscriptionPlanMutationHookResult = ReturnType<typeof useUpdateSubscriptionPlanMutation>;
export type UpdateSubscriptionPlanMutationResult = Apollo.MutationResult<Types.UpdateSubscriptionPlanMutation>;
export type UpdateSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSubscriptionPlanMutation, Types.UpdateSubscriptionPlanMutationVariables>;
export const UpsertConnectedDomainDocument = gql`
    mutation upsertConnectedDomain($artistContextId: bigint!, $domainName: String!, $provider: String) {
  upsertConnectedDomain(
    artistContextId: $artistContextId
    domainName: $domainName
    provider: $provider
  )
}
    `;
export type UpsertConnectedDomainMutationFn = Apollo.MutationFunction<Types.UpsertConnectedDomainMutation, Types.UpsertConnectedDomainMutationVariables>;

/**
 * __useUpsertConnectedDomainMutation__
 *
 * To run a mutation, you first call `useUpsertConnectedDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertConnectedDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertConnectedDomainMutation, { data, loading, error }] = useUpsertConnectedDomainMutation({
 *   variables: {
 *      artistContextId: // value for 'artistContextId'
 *      domainName: // value for 'domainName'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useUpsertConnectedDomainMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpsertConnectedDomainMutation, Types.UpsertConnectedDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpsertConnectedDomainMutation, Types.UpsertConnectedDomainMutationVariables>(UpsertConnectedDomainDocument, options);
      }
export type UpsertConnectedDomainMutationHookResult = ReturnType<typeof useUpsertConnectedDomainMutation>;
export type UpsertConnectedDomainMutationResult = Apollo.MutationResult<Types.UpsertConnectedDomainMutation>;
export type UpsertConnectedDomainMutationOptions = Apollo.BaseMutationOptions<Types.UpsertConnectedDomainMutation, Types.UpsertConnectedDomainMutationVariables>;
export const TestDocument = gql`
    query test {
  helloArtwork(surname: "ASfasdf", firstname: "Asdfasdf")
}
    `;

/**
 * __useTestQuery__
 *
 * To run a query within a React component, call `useTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestQuery(baseOptions?: Apollo.QueryHookOptions<Types.TestQuery, Types.TestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TestQuery, Types.TestQueryVariables>(TestDocument, options);
      }
export function useTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TestQuery, Types.TestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TestQuery, Types.TestQueryVariables>(TestDocument, options);
        }
export type TestQueryHookResult = ReturnType<typeof useTestQuery>;
export type TestLazyQueryHookResult = ReturnType<typeof useTestLazyQuery>;
export type TestQueryResult = Apollo.QueryResult<Types.TestQuery, Types.TestQueryVariables>;