export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
  bigint: number;
  date: string | Date;
  float8: number;
  geography: any;
  geometry: any;
  json: any;
  jsonb: any;
  numeric: number;
  smallint: number;
  timestamp: string | Date;
  timestamptz: string | Date;
  timetz: any;
  uuid: string;
};


/** columns and relationships of "Account_initial_profile_type" */
export type Account_Initial_Profile_Type = {
  __typename?: 'Account_initial_profile_type';
  context_id: Scalars['bigint'];
  type: Enum_Profile_Type_Enum;
};

/** aggregated selection of "Account_initial_profile_type" */
export type Account_Initial_Profile_Type_Aggregate = {
  __typename?: 'Account_initial_profile_type_aggregate';
  aggregate?: Maybe<Account_Initial_Profile_Type_Aggregate_Fields>;
  nodes: Array<Account_Initial_Profile_Type>;
};

/** aggregate fields of "Account_initial_profile_type" */
export type Account_Initial_Profile_Type_Aggregate_Fields = {
  __typename?: 'Account_initial_profile_type_aggregate_fields';
  avg?: Maybe<Account_Initial_Profile_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Initial_Profile_Type_Max_Fields>;
  min?: Maybe<Account_Initial_Profile_Type_Min_Fields>;
  stddev?: Maybe<Account_Initial_Profile_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Initial_Profile_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Initial_Profile_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Initial_Profile_Type_Sum_Fields>;
  var_pop?: Maybe<Account_Initial_Profile_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Initial_Profile_Type_Var_Samp_Fields>;
  variance?: Maybe<Account_Initial_Profile_Type_Variance_Fields>;
};


/** aggregate fields of "Account_initial_profile_type" */
export type Account_Initial_Profile_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Initial_Profile_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Initial_Profile_Type_Avg_Fields = {
  __typename?: 'Account_initial_profile_type_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Account_initial_profile_type". All fields are combined with a logical 'AND'. */
export type Account_Initial_Profile_Type_Bool_Exp = {
  _and?: Maybe<Array<Account_Initial_Profile_Type_Bool_Exp>>;
  _not?: Maybe<Account_Initial_Profile_Type_Bool_Exp>;
  _or?: Maybe<Array<Account_Initial_Profile_Type_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<Enum_Profile_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "Account_initial_profile_type" */
export enum Account_Initial_Profile_Type_Constraint {
  /** unique or primary key constraint on columns "context_id" */
  AccountInitialProfileTypePkey = 'Account_initial_profile_type_pkey'
}

/** input type for incrementing numeric columns in table "Account_initial_profile_type" */
export type Account_Initial_Profile_Type_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Account_initial_profile_type" */
export type Account_Initial_Profile_Type_Insert_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Profile_Type_Enum>;
};

/** aggregate max on columns */
export type Account_Initial_Profile_Type_Max_Fields = {
  __typename?: 'Account_initial_profile_type_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Account_Initial_Profile_Type_Min_Fields = {
  __typename?: 'Account_initial_profile_type_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Account_initial_profile_type" */
export type Account_Initial_Profile_Type_Mutation_Response = {
  __typename?: 'Account_initial_profile_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Initial_Profile_Type>;
};

/** on_conflict condition type for table "Account_initial_profile_type" */
export type Account_Initial_Profile_Type_On_Conflict = {
  constraint: Account_Initial_Profile_Type_Constraint;
  update_columns?: Array<Account_Initial_Profile_Type_Update_Column>;
  where?: Maybe<Account_Initial_Profile_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "Account_initial_profile_type". */
export type Account_Initial_Profile_Type_Order_By = {
  context_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: Account_initial_profile_type */
export type Account_Initial_Profile_Type_Pk_Columns_Input = {
  context_id: Scalars['bigint'];
};

/** select columns of table "Account_initial_profile_type" */
export enum Account_Initial_Profile_Type_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "Account_initial_profile_type" */
export type Account_Initial_Profile_Type_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Profile_Type_Enum>;
};

/** aggregate stddev on columns */
export type Account_Initial_Profile_Type_Stddev_Fields = {
  __typename?: 'Account_initial_profile_type_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Initial_Profile_Type_Stddev_Pop_Fields = {
  __typename?: 'Account_initial_profile_type_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Initial_Profile_Type_Stddev_Samp_Fields = {
  __typename?: 'Account_initial_profile_type_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Account_Initial_Profile_Type_Sum_Fields = {
  __typename?: 'Account_initial_profile_type_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Account_initial_profile_type" */
export enum Account_Initial_Profile_Type_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Account_Initial_Profile_Type_Var_Pop_Fields = {
  __typename?: 'Account_initial_profile_type_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Initial_Profile_Type_Var_Samp_Fields = {
  __typename?: 'Account_initial_profile_type_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Initial_Profile_Type_Variance_Fields = {
  __typename?: 'Account_initial_profile_type_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** Acknowledgements for account specific notices */
export type Account_Notice_Ack = {
  __typename?: 'Account_notice_ack';
  acknowledgement?: Maybe<Scalars['String']>;
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  type: Enum_Account_Notice_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Account_notice_ack" */
export type Account_Notice_Ack_Aggregate = {
  __typename?: 'Account_notice_ack_aggregate';
  aggregate?: Maybe<Account_Notice_Ack_Aggregate_Fields>;
  nodes: Array<Account_Notice_Ack>;
};

/** aggregate fields of "Account_notice_ack" */
export type Account_Notice_Ack_Aggregate_Fields = {
  __typename?: 'Account_notice_ack_aggregate_fields';
  avg?: Maybe<Account_Notice_Ack_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Notice_Ack_Max_Fields>;
  min?: Maybe<Account_Notice_Ack_Min_Fields>;
  stddev?: Maybe<Account_Notice_Ack_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Notice_Ack_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Notice_Ack_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Notice_Ack_Sum_Fields>;
  var_pop?: Maybe<Account_Notice_Ack_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Notice_Ack_Var_Samp_Fields>;
  variance?: Maybe<Account_Notice_Ack_Variance_Fields>;
};


/** aggregate fields of "Account_notice_ack" */
export type Account_Notice_Ack_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Notice_Ack_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Notice_Ack_Avg_Fields = {
  __typename?: 'Account_notice_ack_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Account_notice_ack". All fields are combined with a logical 'AND'. */
export type Account_Notice_Ack_Bool_Exp = {
  _and?: Maybe<Array<Account_Notice_Ack_Bool_Exp>>;
  _not?: Maybe<Account_Notice_Ack_Bool_Exp>;
  _or?: Maybe<Array<Account_Notice_Ack_Bool_Exp>>;
  acknowledgement?: Maybe<String_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<Enum_Account_Notice_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Account_notice_ack" */
export enum Account_Notice_Ack_Constraint {
  /** unique or primary key constraint on columns "type", "context_id" */
  AccountNoticeAckContextIdTypeKey = 'Account_notice_ack_context_id_type_key',
  /** unique or primary key constraint on columns "id" */
  AccountNoticeAckPkey = 'Account_notice_ack_pkey'
}

/** input type for incrementing numeric columns in table "Account_notice_ack" */
export type Account_Notice_Ack_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Account_notice_ack" */
export type Account_Notice_Ack_Insert_Input = {
  acknowledgement?: Maybe<Scalars['String']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Account_Notice_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Notice_Ack_Max_Fields = {
  __typename?: 'Account_notice_ack_max_fields';
  acknowledgement?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Account_Notice_Ack_Min_Fields = {
  __typename?: 'Account_notice_ack_min_fields';
  acknowledgement?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Account_notice_ack" */
export type Account_Notice_Ack_Mutation_Response = {
  __typename?: 'Account_notice_ack_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Notice_Ack>;
};

/** on_conflict condition type for table "Account_notice_ack" */
export type Account_Notice_Ack_On_Conflict = {
  constraint: Account_Notice_Ack_Constraint;
  update_columns?: Array<Account_Notice_Ack_Update_Column>;
  where?: Maybe<Account_Notice_Ack_Bool_Exp>;
};

/** Ordering options when selecting data from "Account_notice_ack". */
export type Account_Notice_Ack_Order_By = {
  acknowledgement?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Account_notice_ack */
export type Account_Notice_Ack_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Account_notice_ack" */
export enum Account_Notice_Ack_Select_Column {
  /** column name */
  Acknowledgement = 'acknowledgement',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Account_notice_ack" */
export type Account_Notice_Ack_Set_Input = {
  acknowledgement?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Account_Notice_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Account_Notice_Ack_Stddev_Fields = {
  __typename?: 'Account_notice_ack_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Notice_Ack_Stddev_Pop_Fields = {
  __typename?: 'Account_notice_ack_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Notice_Ack_Stddev_Samp_Fields = {
  __typename?: 'Account_notice_ack_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Account_Notice_Ack_Sum_Fields = {
  __typename?: 'Account_notice_ack_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Account_notice_ack" */
export enum Account_Notice_Ack_Update_Column {
  /** column name */
  Acknowledgement = 'acknowledgement',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Account_Notice_Ack_Var_Pop_Fields = {
  __typename?: 'Account_notice_ack_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Notice_Ack_Var_Samp_Fields = {
  __typename?: 'Account_notice_ack_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Notice_Ack_Variance_Fields = {
  __typename?: 'Account_notice_ack_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Stores the validation codes for user registration */
export type Account_Validation = {
  __typename?: 'Account_validation';
  code: Scalars['String'];
  /** An array relationship */
  contexts: Array<Context>;
  /** An aggregate relationship */
  contexts_aggregate: Context_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  trashed_at?: Maybe<Scalars['timestamptz']>;
  trashed_by?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** Stores the validation codes for user registration */
export type Account_ValidationContextsArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};


/** Stores the validation codes for user registration */
export type Account_ValidationContexts_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};

/** aggregated selection of "Account_validation" */
export type Account_Validation_Aggregate = {
  __typename?: 'Account_validation_aggregate';
  aggregate?: Maybe<Account_Validation_Aggregate_Fields>;
  nodes: Array<Account_Validation>;
};

/** aggregate fields of "Account_validation" */
export type Account_Validation_Aggregate_Fields = {
  __typename?: 'Account_validation_aggregate_fields';
  avg?: Maybe<Account_Validation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Validation_Max_Fields>;
  min?: Maybe<Account_Validation_Min_Fields>;
  stddev?: Maybe<Account_Validation_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Validation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Validation_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Validation_Sum_Fields>;
  var_pop?: Maybe<Account_Validation_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Validation_Var_Samp_Fields>;
  variance?: Maybe<Account_Validation_Variance_Fields>;
};


/** aggregate fields of "Account_validation" */
export type Account_Validation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Validation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Account_Validation_Avg_Fields = {
  __typename?: 'Account_validation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  trashed_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Account_validation". All fields are combined with a logical 'AND'. */
export type Account_Validation_Bool_Exp = {
  _and?: Maybe<Array<Account_Validation_Bool_Exp>>;
  _not?: Maybe<Account_Validation_Bool_Exp>;
  _or?: Maybe<Array<Account_Validation_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  contexts?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  trashed_by?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Account_validation" */
export enum Account_Validation_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountValidationPkey = 'Account_validation_pkey'
}

/** input type for incrementing numeric columns in table "Account_validation" */
export type Account_Validation_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  trashed_by?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Account_validation" */
export type Account_Validation_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  contexts?: Maybe<Context_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  trashed_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Validation_Max_Fields = {
  __typename?: 'Account_validation_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  trashed_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Account_Validation_Min_Fields = {
  __typename?: 'Account_validation_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  trashed_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Account_validation" */
export type Account_Validation_Mutation_Response = {
  __typename?: 'Account_validation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Validation>;
};

/** input type for inserting object relation for remote table "Account_validation" */
export type Account_Validation_Obj_Rel_Insert_Input = {
  data: Account_Validation_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Account_Validation_On_Conflict>;
};

/** on_conflict condition type for table "Account_validation" */
export type Account_Validation_On_Conflict = {
  constraint: Account_Validation_Constraint;
  update_columns?: Array<Account_Validation_Update_Column>;
  where?: Maybe<Account_Validation_Bool_Exp>;
};

/** Ordering options when selecting data from "Account_validation". */
export type Account_Validation_Order_By = {
  code?: Maybe<Order_By>;
  contexts_aggregate?: Maybe<Context_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  trashed_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Account_validation */
export type Account_Validation_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Account_validation" */
export enum Account_Validation_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  TrashedBy = 'trashed_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Account_validation" */
export type Account_Validation_Set_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  trashed_by?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Account_Validation_Stddev_Fields = {
  __typename?: 'Account_validation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  trashed_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Validation_Stddev_Pop_Fields = {
  __typename?: 'Account_validation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  trashed_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Validation_Stddev_Samp_Fields = {
  __typename?: 'Account_validation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  trashed_by?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Account_Validation_Sum_Fields = {
  __typename?: 'Account_validation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  trashed_by?: Maybe<Scalars['Int']>;
};

/** update columns of table "Account_validation" */
export enum Account_Validation_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  TrashedBy = 'trashed_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Account_Validation_Var_Pop_Fields = {
  __typename?: 'Account_validation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  trashed_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Validation_Var_Samp_Fields = {
  __typename?: 'Account_validation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  trashed_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Validation_Variance_Fields = {
  __typename?: 'Account_validation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  trashed_by?: Maybe<Scalars['Float']>;
};

/** Defines function name that will run for any achievement_id */
export type Achievement_Hooks = {
  __typename?: 'Achievement_hooks';
  /** An object relationship */
  achievement?: Maybe<Achievements>;
  achievement_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** Function name to be run */
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Achievement_hooks" */
export type Achievement_Hooks_Aggregate = {
  __typename?: 'Achievement_hooks_aggregate';
  aggregate?: Maybe<Achievement_Hooks_Aggregate_Fields>;
  nodes: Array<Achievement_Hooks>;
};

/** aggregate fields of "Achievement_hooks" */
export type Achievement_Hooks_Aggregate_Fields = {
  __typename?: 'Achievement_hooks_aggregate_fields';
  avg?: Maybe<Achievement_Hooks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Achievement_Hooks_Max_Fields>;
  min?: Maybe<Achievement_Hooks_Min_Fields>;
  stddev?: Maybe<Achievement_Hooks_Stddev_Fields>;
  stddev_pop?: Maybe<Achievement_Hooks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Achievement_Hooks_Stddev_Samp_Fields>;
  sum?: Maybe<Achievement_Hooks_Sum_Fields>;
  var_pop?: Maybe<Achievement_Hooks_Var_Pop_Fields>;
  var_samp?: Maybe<Achievement_Hooks_Var_Samp_Fields>;
  variance?: Maybe<Achievement_Hooks_Variance_Fields>;
};


/** aggregate fields of "Achievement_hooks" */
export type Achievement_Hooks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Achievement_Hooks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Achievement_hooks" */
export type Achievement_Hooks_Aggregate_Order_By = {
  avg?: Maybe<Achievement_Hooks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Achievement_Hooks_Max_Order_By>;
  min?: Maybe<Achievement_Hooks_Min_Order_By>;
  stddev?: Maybe<Achievement_Hooks_Stddev_Order_By>;
  stddev_pop?: Maybe<Achievement_Hooks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Achievement_Hooks_Stddev_Samp_Order_By>;
  sum?: Maybe<Achievement_Hooks_Sum_Order_By>;
  var_pop?: Maybe<Achievement_Hooks_Var_Pop_Order_By>;
  var_samp?: Maybe<Achievement_Hooks_Var_Samp_Order_By>;
  variance?: Maybe<Achievement_Hooks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Achievement_hooks" */
export type Achievement_Hooks_Arr_Rel_Insert_Input = {
  data: Array<Achievement_Hooks_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Achievement_Hooks_On_Conflict>;
};

/** aggregate avg on columns */
export type Achievement_Hooks_Avg_Fields = {
  __typename?: 'Achievement_hooks_avg_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Avg_Order_By = {
  achievement_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Achievement_hooks". All fields are combined with a logical 'AND'. */
export type Achievement_Hooks_Bool_Exp = {
  _and?: Maybe<Array<Achievement_Hooks_Bool_Exp>>;
  _not?: Maybe<Achievement_Hooks_Bool_Exp>;
  _or?: Maybe<Array<Achievement_Hooks_Bool_Exp>>;
  achievement?: Maybe<Achievements_Bool_Exp>;
  achievement_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Achievement_hooks" */
export enum Achievement_Hooks_Constraint {
  /** unique or primary key constraint on columns "id" */
  AchievementHooksPkey = 'Achievement_hooks_pkey'
}

/** input type for incrementing numeric columns in table "Achievement_hooks" */
export type Achievement_Hooks_Inc_Input = {
  achievement_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Achievement_hooks" */
export type Achievement_Hooks_Insert_Input = {
  achievement?: Maybe<Achievements_Obj_Rel_Insert_Input>;
  achievement_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** Function name to be run */
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Achievement_Hooks_Max_Fields = {
  __typename?: 'Achievement_hooks_max_fields';
  achievement_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** Function name to be run */
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Max_Order_By = {
  achievement_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Function name to be run */
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Achievement_Hooks_Min_Fields = {
  __typename?: 'Achievement_hooks_min_fields';
  achievement_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** Function name to be run */
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Min_Order_By = {
  achievement_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Function name to be run */
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Achievement_hooks" */
export type Achievement_Hooks_Mutation_Response = {
  __typename?: 'Achievement_hooks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Achievement_Hooks>;
};

/** on_conflict condition type for table "Achievement_hooks" */
export type Achievement_Hooks_On_Conflict = {
  constraint: Achievement_Hooks_Constraint;
  update_columns?: Array<Achievement_Hooks_Update_Column>;
  where?: Maybe<Achievement_Hooks_Bool_Exp>;
};

/** Ordering options when selecting data from "Achievement_hooks". */
export type Achievement_Hooks_Order_By = {
  achievement?: Maybe<Achievements_Order_By>;
  achievement_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Achievement_hooks */
export type Achievement_Hooks_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Achievement_hooks" */
export enum Achievement_Hooks_Select_Column {
  /** column name */
  AchievementId = 'achievement_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Achievement_hooks" */
export type Achievement_Hooks_Set_Input = {
  achievement_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  /** Function name to be run */
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Achievement_Hooks_Stddev_Fields = {
  __typename?: 'Achievement_hooks_stddev_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Stddev_Order_By = {
  achievement_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Achievement_Hooks_Stddev_Pop_Fields = {
  __typename?: 'Achievement_hooks_stddev_pop_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Stddev_Pop_Order_By = {
  achievement_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Achievement_Hooks_Stddev_Samp_Fields = {
  __typename?: 'Achievement_hooks_stddev_samp_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Stddev_Samp_Order_By = {
  achievement_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Achievement_Hooks_Sum_Fields = {
  __typename?: 'Achievement_hooks_sum_fields';
  achievement_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Sum_Order_By = {
  achievement_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Achievement_hooks" */
export enum Achievement_Hooks_Update_Column {
  /** column name */
  AchievementId = 'achievement_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Achievement_Hooks_Var_Pop_Fields = {
  __typename?: 'Achievement_hooks_var_pop_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Var_Pop_Order_By = {
  achievement_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Achievement_Hooks_Var_Samp_Fields = {
  __typename?: 'Achievement_hooks_var_samp_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Var_Samp_Order_By = {
  achievement_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Achievement_Hooks_Variance_Fields = {
  __typename?: 'Achievement_hooks_variance_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Achievement_hooks" */
export type Achievement_Hooks_Variance_Order_By = {
  achievement_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Map achievements to tags to be displayed in the dashboard. */
export type Achievement_Tags = {
  __typename?: 'Achievement_tags';
  /** An object relationship */
  ENUM_achievement_tag: Enum_Achievement_Tags;
  /** An object relationship */
  achievement: Achievements;
  achievement_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  title: Enum_Achievement_Tags_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Achievement_tags" */
export type Achievement_Tags_Aggregate = {
  __typename?: 'Achievement_tags_aggregate';
  aggregate?: Maybe<Achievement_Tags_Aggregate_Fields>;
  nodes: Array<Achievement_Tags>;
};

/** aggregate fields of "Achievement_tags" */
export type Achievement_Tags_Aggregate_Fields = {
  __typename?: 'Achievement_tags_aggregate_fields';
  avg?: Maybe<Achievement_Tags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Achievement_Tags_Max_Fields>;
  min?: Maybe<Achievement_Tags_Min_Fields>;
  stddev?: Maybe<Achievement_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Achievement_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Achievement_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Achievement_Tags_Sum_Fields>;
  var_pop?: Maybe<Achievement_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Achievement_Tags_Var_Samp_Fields>;
  variance?: Maybe<Achievement_Tags_Variance_Fields>;
};


/** aggregate fields of "Achievement_tags" */
export type Achievement_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Achievement_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Achievement_Tags_Avg_Fields = {
  __typename?: 'Achievement_tags_avg_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Achievement_tags". All fields are combined with a logical 'AND'. */
export type Achievement_Tags_Bool_Exp = {
  ENUM_achievement_tag?: Maybe<Enum_Achievement_Tags_Bool_Exp>;
  _and?: Maybe<Array<Achievement_Tags_Bool_Exp>>;
  _not?: Maybe<Achievement_Tags_Bool_Exp>;
  _or?: Maybe<Array<Achievement_Tags_Bool_Exp>>;
  achievement?: Maybe<Achievements_Bool_Exp>;
  achievement_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<Enum_Achievement_Tags_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Achievement_tags" */
export enum Achievement_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  AchievementTagsPkey = 'Achievement_tags_pkey'
}

/** input type for incrementing numeric columns in table "Achievement_tags" */
export type Achievement_Tags_Inc_Input = {
  achievement_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Achievement_tags" */
export type Achievement_Tags_Insert_Input = {
  ENUM_achievement_tag?: Maybe<Enum_Achievement_Tags_Obj_Rel_Insert_Input>;
  achievement?: Maybe<Achievements_Obj_Rel_Insert_Input>;
  achievement_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Enum_Achievement_Tags_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Achievement_Tags_Max_Fields = {
  __typename?: 'Achievement_tags_max_fields';
  achievement_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Achievement_Tags_Min_Fields = {
  __typename?: 'Achievement_tags_min_fields';
  achievement_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Achievement_tags" */
export type Achievement_Tags_Mutation_Response = {
  __typename?: 'Achievement_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Achievement_Tags>;
};

/** on_conflict condition type for table "Achievement_tags" */
export type Achievement_Tags_On_Conflict = {
  constraint: Achievement_Tags_Constraint;
  update_columns?: Array<Achievement_Tags_Update_Column>;
  where?: Maybe<Achievement_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "Achievement_tags". */
export type Achievement_Tags_Order_By = {
  ENUM_achievement_tag?: Maybe<Enum_Achievement_Tags_Order_By>;
  achievement?: Maybe<Achievements_Order_By>;
  achievement_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Achievement_tags */
export type Achievement_Tags_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Achievement_tags" */
export enum Achievement_Tags_Select_Column {
  /** column name */
  AchievementId = 'achievement_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Achievement_tags" */
export type Achievement_Tags_Set_Input = {
  achievement_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Enum_Achievement_Tags_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Achievement_Tags_Stddev_Fields = {
  __typename?: 'Achievement_tags_stddev_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Achievement_Tags_Stddev_Pop_Fields = {
  __typename?: 'Achievement_tags_stddev_pop_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Achievement_Tags_Stddev_Samp_Fields = {
  __typename?: 'Achievement_tags_stddev_samp_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Achievement_Tags_Sum_Fields = {
  __typename?: 'Achievement_tags_sum_fields';
  achievement_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Achievement_tags" */
export enum Achievement_Tags_Update_Column {
  /** column name */
  AchievementId = 'achievement_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Achievement_Tags_Var_Pop_Fields = {
  __typename?: 'Achievement_tags_var_pop_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Achievement_Tags_Var_Samp_Fields = {
  __typename?: 'Achievement_tags_var_samp_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Achievement_Tags_Variance_Fields = {
  __typename?: 'Achievement_tags_variance_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Defines Achievements used to calculate points for Kaleido Coins */
export type Achievements = {
  __typename?: 'Achievements';
  /** An array relationship */
  achievement_hooks: Array<Achievement_Hooks>;
  /** An aggregate relationship */
  achievement_hooks_aggregate: Achievement_Hooks_Aggregate;
  achievement_type: Scalars['String'];
  /** Defines the amount of points given. */
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  /** Defines what the user must do in order to receive coins */
  description: Scalars['String'];
  id: Scalars['Int'];
  max_awarded: Scalars['numeric'];
  max_awarded_total: Scalars['numeric'];
  name: Scalars['String'];
  payload?: Maybe<Scalars['jsonb']>;
  /** If enabled this achievement will give points to users. If hidden achievement will still work. If disabled then the achievement will not run */
  state: Scalars['String'];
  state_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};


/** Defines Achievements used to calculate points for Kaleido Coins */
export type AchievementsAchievement_HooksArgs = {
  distinct_on?: Maybe<Array<Achievement_Hooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Hooks_Order_By>>;
  where?: Maybe<Achievement_Hooks_Bool_Exp>;
};


/** Defines Achievements used to calculate points for Kaleido Coins */
export type AchievementsAchievement_Hooks_AggregateArgs = {
  distinct_on?: Maybe<Array<Achievement_Hooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Hooks_Order_By>>;
  where?: Maybe<Achievement_Hooks_Bool_Exp>;
};


/** Defines Achievements used to calculate points for Kaleido Coins */
export type AchievementsPayloadArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Achievements" */
export type Achievements_Aggregate = {
  __typename?: 'Achievements_aggregate';
  aggregate?: Maybe<Achievements_Aggregate_Fields>;
  nodes: Array<Achievements>;
};

/** aggregate fields of "Achievements" */
export type Achievements_Aggregate_Fields = {
  __typename?: 'Achievements_aggregate_fields';
  avg?: Maybe<Achievements_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Achievements_Max_Fields>;
  min?: Maybe<Achievements_Min_Fields>;
  stddev?: Maybe<Achievements_Stddev_Fields>;
  stddev_pop?: Maybe<Achievements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Achievements_Stddev_Samp_Fields>;
  sum?: Maybe<Achievements_Sum_Fields>;
  var_pop?: Maybe<Achievements_Var_Pop_Fields>;
  var_samp?: Maybe<Achievements_Var_Samp_Fields>;
  variance?: Maybe<Achievements_Variance_Fields>;
};


/** aggregate fields of "Achievements" */
export type Achievements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Achievements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Achievements_Append_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Achievements_Avg_Fields = {
  __typename?: 'Achievements_avg_fields';
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_awarded?: Maybe<Scalars['Float']>;
  max_awarded_total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Achievements". All fields are combined with a logical 'AND'. */
export type Achievements_Bool_Exp = {
  _and?: Maybe<Array<Achievements_Bool_Exp>>;
  _not?: Maybe<Achievements_Bool_Exp>;
  _or?: Maybe<Array<Achievements_Bool_Exp>>;
  achievement_hooks?: Maybe<Achievement_Hooks_Bool_Exp>;
  achievement_type?: Maybe<String_Comparison_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  max_awarded?: Maybe<Numeric_Comparison_Exp>;
  max_awarded_total?: Maybe<Numeric_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payload?: Maybe<Jsonb_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  state_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Achievements" */
export enum Achievements_Constraint {
  /** unique or primary key constraint on columns "name" */
  AchievementsNameKey = 'Achievements_name_key',
  /** unique or primary key constraint on columns "id" */
  AchievementsPkey = 'Achievements_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Achievements_Delete_At_Path_Input = {
  payload?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Achievements_Delete_Elem_Input = {
  payload?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Achievements_Delete_Key_Input = {
  payload?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Achievements" */
export type Achievements_Inc_Input = {
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  max_awarded?: Maybe<Scalars['numeric']>;
  max_awarded_total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Achievements" */
export type Achievements_Insert_Input = {
  achievement_hooks?: Maybe<Achievement_Hooks_Arr_Rel_Insert_Input>;
  achievement_type?: Maybe<Scalars['String']>;
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Defines what the user must do in order to receive coins */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_awarded?: Maybe<Scalars['numeric']>;
  max_awarded_total?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['jsonb']>;
  /** If enabled this achievement will give points to users. If hidden achievement will still work. If disabled then the achievement will not run */
  state?: Maybe<Scalars['String']>;
  state_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Achievements_Max_Fields = {
  __typename?: 'Achievements_max_fields';
  achievement_type?: Maybe<Scalars['String']>;
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Defines what the user must do in order to receive coins */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_awarded?: Maybe<Scalars['numeric']>;
  max_awarded_total?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  /** If enabled this achievement will give points to users. If hidden achievement will still work. If disabled then the achievement will not run */
  state?: Maybe<Scalars['String']>;
  state_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Achievements_Min_Fields = {
  __typename?: 'Achievements_min_fields';
  achievement_type?: Maybe<Scalars['String']>;
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Defines what the user must do in order to receive coins */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_awarded?: Maybe<Scalars['numeric']>;
  max_awarded_total?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  /** If enabled this achievement will give points to users. If hidden achievement will still work. If disabled then the achievement will not run */
  state?: Maybe<Scalars['String']>;
  state_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Achievements" */
export type Achievements_Mutation_Response = {
  __typename?: 'Achievements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Achievements>;
};

/** input type for inserting object relation for remote table "Achievements" */
export type Achievements_Obj_Rel_Insert_Input = {
  data: Achievements_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Achievements_On_Conflict>;
};

/** on_conflict condition type for table "Achievements" */
export type Achievements_On_Conflict = {
  constraint: Achievements_Constraint;
  update_columns?: Array<Achievements_Update_Column>;
  where?: Maybe<Achievements_Bool_Exp>;
};

/** Ordering options when selecting data from "Achievements". */
export type Achievements_Order_By = {
  achievement_hooks_aggregate?: Maybe<Achievement_Hooks_Aggregate_Order_By>;
  achievement_type?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  max_awarded?: Maybe<Order_By>;
  max_awarded_total?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  state_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Achievements */
export type Achievements_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Achievements_Prepend_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Achievements" */
export enum Achievements_Select_Column {
  /** column name */
  AchievementType = 'achievement_type',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAwarded = 'max_awarded',
  /** column name */
  MaxAwardedTotal = 'max_awarded_total',
  /** column name */
  Name = 'name',
  /** column name */
  Payload = 'payload',
  /** column name */
  State = 'state',
  /** column name */
  StateAt = 'state_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Achievements" */
export type Achievements_Set_Input = {
  achievement_type?: Maybe<Scalars['String']>;
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Defines what the user must do in order to receive coins */
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  max_awarded?: Maybe<Scalars['numeric']>;
  max_awarded_total?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['jsonb']>;
  /** If enabled this achievement will give points to users. If hidden achievement will still work. If disabled then the achievement will not run */
  state?: Maybe<Scalars['String']>;
  state_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Achievements_Stddev_Fields = {
  __typename?: 'Achievements_stddev_fields';
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_awarded?: Maybe<Scalars['Float']>;
  max_awarded_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Achievements_Stddev_Pop_Fields = {
  __typename?: 'Achievements_stddev_pop_fields';
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_awarded?: Maybe<Scalars['Float']>;
  max_awarded_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Achievements_Stddev_Samp_Fields = {
  __typename?: 'Achievements_stddev_samp_fields';
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_awarded?: Maybe<Scalars['Float']>;
  max_awarded_total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Achievements_Sum_Fields = {
  __typename?: 'Achievements_sum_fields';
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  max_awarded?: Maybe<Scalars['numeric']>;
  max_awarded_total?: Maybe<Scalars['numeric']>;
};

/** update columns of table "Achievements" */
export enum Achievements_Update_Column {
  /** column name */
  AchievementType = 'achievement_type',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MaxAwarded = 'max_awarded',
  /** column name */
  MaxAwardedTotal = 'max_awarded_total',
  /** column name */
  Name = 'name',
  /** column name */
  Payload = 'payload',
  /** column name */
  State = 'state',
  /** column name */
  StateAt = 'state_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Achievements_Var_Pop_Fields = {
  __typename?: 'Achievements_var_pop_fields';
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_awarded?: Maybe<Scalars['Float']>;
  max_awarded_total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Achievements_Var_Samp_Fields = {
  __typename?: 'Achievements_var_samp_fields';
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_awarded?: Maybe<Scalars['Float']>;
  max_awarded_total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Achievements_Variance_Fields = {
  __typename?: 'Achievements_variance_fields';
  /** Defines the amount of points given. */
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max_awarded?: Maybe<Scalars['Float']>;
  max_awarded_total?: Maybe<Scalars['Float']>;
};

/** In order to reach the admin dashboard of Kaleido you need to have an admin account and use the password in this table. */
export type Admin_Password = {
  __typename?: 'Admin_password';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  password: Scalars['String'];
};

/** aggregated selection of "Admin_password" */
export type Admin_Password_Aggregate = {
  __typename?: 'Admin_password_aggregate';
  aggregate?: Maybe<Admin_Password_Aggregate_Fields>;
  nodes: Array<Admin_Password>;
};

/** aggregate fields of "Admin_password" */
export type Admin_Password_Aggregate_Fields = {
  __typename?: 'Admin_password_aggregate_fields';
  avg?: Maybe<Admin_Password_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Admin_Password_Max_Fields>;
  min?: Maybe<Admin_Password_Min_Fields>;
  stddev?: Maybe<Admin_Password_Stddev_Fields>;
  stddev_pop?: Maybe<Admin_Password_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Admin_Password_Stddev_Samp_Fields>;
  sum?: Maybe<Admin_Password_Sum_Fields>;
  var_pop?: Maybe<Admin_Password_Var_Pop_Fields>;
  var_samp?: Maybe<Admin_Password_Var_Samp_Fields>;
  variance?: Maybe<Admin_Password_Variance_Fields>;
};


/** aggregate fields of "Admin_password" */
export type Admin_Password_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Admin_Password_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Admin_Password_Avg_Fields = {
  __typename?: 'Admin_password_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Admin_password". All fields are combined with a logical 'AND'. */
export type Admin_Password_Bool_Exp = {
  _and?: Maybe<Array<Admin_Password_Bool_Exp>>;
  _not?: Maybe<Admin_Password_Bool_Exp>;
  _or?: Maybe<Array<Admin_Password_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Admin_password" */
export enum Admin_Password_Constraint {
  /** unique or primary key constraint on columns "id" */
  AdminPasswordPkey = 'admin_password_pkey'
}

/** input type for incrementing numeric columns in table "Admin_password" */
export type Admin_Password_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Admin_password" */
export type Admin_Password_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Admin_Password_Max_Fields = {
  __typename?: 'Admin_password_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Admin_Password_Min_Fields = {
  __typename?: 'Admin_password_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Admin_password" */
export type Admin_Password_Mutation_Response = {
  __typename?: 'Admin_password_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Admin_Password>;
};

/** on_conflict condition type for table "Admin_password" */
export type Admin_Password_On_Conflict = {
  constraint: Admin_Password_Constraint;
  update_columns?: Array<Admin_Password_Update_Column>;
  where?: Maybe<Admin_Password_Bool_Exp>;
};

/** Ordering options when selecting data from "Admin_password". */
export type Admin_Password_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
};

/** primary key columns input for table: Admin_password */
export type Admin_Password_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Admin_password" */
export enum Admin_Password_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password'
}

/** input type for updating data in table "Admin_password" */
export type Admin_Password_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Admin_Password_Stddev_Fields = {
  __typename?: 'Admin_password_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Admin_Password_Stddev_Pop_Fields = {
  __typename?: 'Admin_password_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Admin_Password_Stddev_Samp_Fields = {
  __typename?: 'Admin_password_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Admin_Password_Sum_Fields = {
  __typename?: 'Admin_password_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Admin_password" */
export enum Admin_Password_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password'
}

/** aggregate var_pop on columns */
export type Admin_Password_Var_Pop_Fields = {
  __typename?: 'Admin_password_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Admin_Password_Var_Samp_Fields = {
  __typename?: 'Admin_password_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Admin_Password_Variance_Fields = {
  __typename?: 'Admin_password_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Admin_table_profiles" */
export type Admin_Table_Profiles = {
  __typename?: 'Admin_table_profiles';
  /** An object relationship */
  ENUM_context_type?: Maybe<Enum_Context_Type>;
  artwork_count?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  collection_type?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  instagram_url?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  legal_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  profile_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Admin_table_profiles" */
export type Admin_Table_Profiles_Aggregate = {
  __typename?: 'Admin_table_profiles_aggregate';
  aggregate?: Maybe<Admin_Table_Profiles_Aggregate_Fields>;
  nodes: Array<Admin_Table_Profiles>;
};

/** aggregate fields of "Admin_table_profiles" */
export type Admin_Table_Profiles_Aggregate_Fields = {
  __typename?: 'Admin_table_profiles_aggregate_fields';
  avg?: Maybe<Admin_Table_Profiles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Admin_Table_Profiles_Max_Fields>;
  min?: Maybe<Admin_Table_Profiles_Min_Fields>;
  stddev?: Maybe<Admin_Table_Profiles_Stddev_Fields>;
  stddev_pop?: Maybe<Admin_Table_Profiles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Admin_Table_Profiles_Stddev_Samp_Fields>;
  sum?: Maybe<Admin_Table_Profiles_Sum_Fields>;
  var_pop?: Maybe<Admin_Table_Profiles_Var_Pop_Fields>;
  var_samp?: Maybe<Admin_Table_Profiles_Var_Samp_Fields>;
  variance?: Maybe<Admin_Table_Profiles_Variance_Fields>;
};


/** aggregate fields of "Admin_table_profiles" */
export type Admin_Table_Profiles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Admin_Table_Profiles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Admin_Table_Profiles_Avg_Fields = {
  __typename?: 'Admin_table_profiles_avg_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Admin_table_profiles". All fields are combined with a logical 'AND'. */
export type Admin_Table_Profiles_Bool_Exp = {
  ENUM_context_type?: Maybe<Enum_Context_Type_Bool_Exp>;
  _and?: Maybe<Array<Admin_Table_Profiles_Bool_Exp>>;
  _not?: Maybe<Admin_Table_Profiles_Bool_Exp>;
  _or?: Maybe<Array<Admin_Table_Profiles_Bool_Exp>>;
  artwork_count?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  collection_type?: Maybe<String_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  instagram_url?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  legal_name?: Maybe<String_Comparison_Exp>;
  preferred_language?: Maybe<String_Comparison_Exp>;
  profile_collaboration_code?: Maybe<String_Comparison_Exp>;
  profile_type?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Admin_Table_Profiles_Max_Fields = {
  __typename?: 'Admin_table_profiles_max_fields';
  artwork_count?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  collection_type?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  instagram_url?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  legal_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  profile_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Admin_Table_Profiles_Min_Fields = {
  __typename?: 'Admin_table_profiles_min_fields';
  artwork_count?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  collection_type?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  instagram_url?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  legal_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  profile_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Admin_table_profiles". */
export type Admin_Table_Profiles_Order_By = {
  ENUM_context_type?: Maybe<Enum_Context_Type_Order_By>;
  artwork_count?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  collection_type?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  instagram_url?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  legal_name?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  profile_collaboration_code?: Maybe<Order_By>;
  profile_type?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Admin_table_profiles" */
export enum Admin_Table_Profiles_Select_Column {
  /** column name */
  ArtworkCount = 'artwork_count',
  /** column name */
  City = 'city',
  /** column name */
  CollectionType = 'collection_type',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Handle = 'handle',
  /** column name */
  InstagramUrl = 'instagram_url',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LegalName = 'legal_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  ProfileCollaborationCode = 'profile_collaboration_code',
  /** column name */
  ProfileType = 'profile_type',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Admin_Table_Profiles_Stddev_Fields = {
  __typename?: 'Admin_table_profiles_stddev_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Admin_Table_Profiles_Stddev_Pop_Fields = {
  __typename?: 'Admin_table_profiles_stddev_pop_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Admin_Table_Profiles_Stddev_Samp_Fields = {
  __typename?: 'Admin_table_profiles_stddev_samp_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Admin_Table_Profiles_Sum_Fields = {
  __typename?: 'Admin_table_profiles_sum_fields';
  artwork_count?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "Admin_table_profiles_v2" */
export type Admin_Table_Profiles_V2 = {
  __typename?: 'Admin_table_profiles_v2';
  artwork_count?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  instagram_url?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  legal_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Admin_table_profiles_v2" */
export type Admin_Table_Profiles_V2_Aggregate = {
  __typename?: 'Admin_table_profiles_v2_aggregate';
  aggregate?: Maybe<Admin_Table_Profiles_V2_Aggregate_Fields>;
  nodes: Array<Admin_Table_Profiles_V2>;
};

/** aggregate fields of "Admin_table_profiles_v2" */
export type Admin_Table_Profiles_V2_Aggregate_Fields = {
  __typename?: 'Admin_table_profiles_v2_aggregate_fields';
  avg?: Maybe<Admin_Table_Profiles_V2_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Admin_Table_Profiles_V2_Max_Fields>;
  min?: Maybe<Admin_Table_Profiles_V2_Min_Fields>;
  stddev?: Maybe<Admin_Table_Profiles_V2_Stddev_Fields>;
  stddev_pop?: Maybe<Admin_Table_Profiles_V2_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Admin_Table_Profiles_V2_Stddev_Samp_Fields>;
  sum?: Maybe<Admin_Table_Profiles_V2_Sum_Fields>;
  var_pop?: Maybe<Admin_Table_Profiles_V2_Var_Pop_Fields>;
  var_samp?: Maybe<Admin_Table_Profiles_V2_Var_Samp_Fields>;
  variance?: Maybe<Admin_Table_Profiles_V2_Variance_Fields>;
};


/** aggregate fields of "Admin_table_profiles_v2" */
export type Admin_Table_Profiles_V2_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Admin_Table_Profiles_V2_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Admin_Table_Profiles_V2_Avg_Fields = {
  __typename?: 'Admin_table_profiles_v2_avg_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Admin_table_profiles_v2". All fields are combined with a logical 'AND'. */
export type Admin_Table_Profiles_V2_Bool_Exp = {
  _and?: Maybe<Array<Admin_Table_Profiles_V2_Bool_Exp>>;
  _not?: Maybe<Admin_Table_Profiles_V2_Bool_Exp>;
  _or?: Maybe<Array<Admin_Table_Profiles_V2_Bool_Exp>>;
  artwork_count?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  instagram_url?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  legal_name?: Maybe<String_Comparison_Exp>;
  preferred_language?: Maybe<String_Comparison_Exp>;
  profile_collaboration_code?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Admin_Table_Profiles_V2_Max_Fields = {
  __typename?: 'Admin_table_profiles_v2_max_fields';
  artwork_count?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  instagram_url?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  legal_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Admin_Table_Profiles_V2_Min_Fields = {
  __typename?: 'Admin_table_profiles_v2_min_fields';
  artwork_count?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  instagram_url?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  legal_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Admin_table_profiles_v2". */
export type Admin_Table_Profiles_V2_Order_By = {
  artwork_count?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  instagram_url?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  legal_name?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  profile_collaboration_code?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "Admin_table_profiles_v2" */
export enum Admin_Table_Profiles_V2_Select_Column {
  /** column name */
  ArtworkCount = 'artwork_count',
  /** column name */
  City = 'city',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Handle = 'handle',
  /** column name */
  InstagramUrl = 'instagram_url',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LegalName = 'legal_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  ProfileCollaborationCode = 'profile_collaboration_code',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Admin_Table_Profiles_V2_Stddev_Fields = {
  __typename?: 'Admin_table_profiles_v2_stddev_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Admin_Table_Profiles_V2_Stddev_Pop_Fields = {
  __typename?: 'Admin_table_profiles_v2_stddev_pop_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Admin_Table_Profiles_V2_Stddev_Samp_Fields = {
  __typename?: 'Admin_table_profiles_v2_stddev_samp_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Admin_Table_Profiles_V2_Sum_Fields = {
  __typename?: 'Admin_table_profiles_v2_sum_fields';
  artwork_count?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Admin_Table_Profiles_V2_Var_Pop_Fields = {
  __typename?: 'Admin_table_profiles_v2_var_pop_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Admin_Table_Profiles_V2_Var_Samp_Fields = {
  __typename?: 'Admin_table_profiles_v2_var_samp_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Admin_Table_Profiles_V2_Variance_Fields = {
  __typename?: 'Admin_table_profiles_v2_variance_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type Admin_Table_Profiles_Var_Pop_Fields = {
  __typename?: 'Admin_table_profiles_var_pop_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Admin_Table_Profiles_Var_Samp_Fields = {
  __typename?: 'Admin_table_profiles_var_samp_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Admin_Table_Profiles_Variance_Fields = {
  __typename?: 'Admin_table_profiles_variance_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
};

/** a table to track notifications sent by the admin to the user */
export type Admin_To_User_Notification = {
  __typename?: 'Admin_to_user_notification';
  best_practices_email_last_sent?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  request_meeting_email_last_sent?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  user_context_id: Scalars['bigint'];
};

/** aggregated selection of "Admin_to_user_notification" */
export type Admin_To_User_Notification_Aggregate = {
  __typename?: 'Admin_to_user_notification_aggregate';
  aggregate?: Maybe<Admin_To_User_Notification_Aggregate_Fields>;
  nodes: Array<Admin_To_User_Notification>;
};

/** aggregate fields of "Admin_to_user_notification" */
export type Admin_To_User_Notification_Aggregate_Fields = {
  __typename?: 'Admin_to_user_notification_aggregate_fields';
  avg?: Maybe<Admin_To_User_Notification_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Admin_To_User_Notification_Max_Fields>;
  min?: Maybe<Admin_To_User_Notification_Min_Fields>;
  stddev?: Maybe<Admin_To_User_Notification_Stddev_Fields>;
  stddev_pop?: Maybe<Admin_To_User_Notification_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Admin_To_User_Notification_Stddev_Samp_Fields>;
  sum?: Maybe<Admin_To_User_Notification_Sum_Fields>;
  var_pop?: Maybe<Admin_To_User_Notification_Var_Pop_Fields>;
  var_samp?: Maybe<Admin_To_User_Notification_Var_Samp_Fields>;
  variance?: Maybe<Admin_To_User_Notification_Variance_Fields>;
};


/** aggregate fields of "Admin_to_user_notification" */
export type Admin_To_User_Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Admin_To_User_Notification_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Admin_To_User_Notification_Avg_Fields = {
  __typename?: 'Admin_to_user_notification_avg_fields';
  user_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Admin_to_user_notification". All fields are combined with a logical 'AND'. */
export type Admin_To_User_Notification_Bool_Exp = {
  _and?: Maybe<Array<Admin_To_User_Notification_Bool_Exp>>;
  _not?: Maybe<Admin_To_User_Notification_Bool_Exp>;
  _or?: Maybe<Array<Admin_To_User_Notification_Bool_Exp>>;
  best_practices_email_last_sent?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  request_meeting_email_last_sent?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_context_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Admin_to_user_notification" */
export enum Admin_To_User_Notification_Constraint {
  /** unique or primary key constraint on columns "user_context_id" */
  AdminToUserNotificationPkey = 'Admin_to_user_notification_pkey'
}

/** input type for incrementing numeric columns in table "Admin_to_user_notification" */
export type Admin_To_User_Notification_Inc_Input = {
  user_context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Admin_to_user_notification" */
export type Admin_To_User_Notification_Insert_Input = {
  best_practices_email_last_sent?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  request_meeting_email_last_sent?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Admin_To_User_Notification_Max_Fields = {
  __typename?: 'Admin_to_user_notification_max_fields';
  best_practices_email_last_sent?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  request_meeting_email_last_sent?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Admin_To_User_Notification_Min_Fields = {
  __typename?: 'Admin_to_user_notification_min_fields';
  best_practices_email_last_sent?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  request_meeting_email_last_sent?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_context_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Admin_to_user_notification" */
export type Admin_To_User_Notification_Mutation_Response = {
  __typename?: 'Admin_to_user_notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Admin_To_User_Notification>;
};

/** on_conflict condition type for table "Admin_to_user_notification" */
export type Admin_To_User_Notification_On_Conflict = {
  constraint: Admin_To_User_Notification_Constraint;
  update_columns?: Array<Admin_To_User_Notification_Update_Column>;
  where?: Maybe<Admin_To_User_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "Admin_to_user_notification". */
export type Admin_To_User_Notification_Order_By = {
  best_practices_email_last_sent?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  request_meeting_email_last_sent?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_context_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Admin_to_user_notification */
export type Admin_To_User_Notification_Pk_Columns_Input = {
  user_context_id: Scalars['bigint'];
};

/** select columns of table "Admin_to_user_notification" */
export enum Admin_To_User_Notification_Select_Column {
  /** column name */
  BestPracticesEmailLastSent = 'best_practices_email_last_sent',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  RequestMeetingEmailLastSent = 'request_meeting_email_last_sent',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserContextId = 'user_context_id'
}

/** input type for updating data in table "Admin_to_user_notification" */
export type Admin_To_User_Notification_Set_Input = {
  best_practices_email_last_sent?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  request_meeting_email_last_sent?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Admin_To_User_Notification_Stddev_Fields = {
  __typename?: 'Admin_to_user_notification_stddev_fields';
  user_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Admin_To_User_Notification_Stddev_Pop_Fields = {
  __typename?: 'Admin_to_user_notification_stddev_pop_fields';
  user_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Admin_To_User_Notification_Stddev_Samp_Fields = {
  __typename?: 'Admin_to_user_notification_stddev_samp_fields';
  user_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Admin_To_User_Notification_Sum_Fields = {
  __typename?: 'Admin_to_user_notification_sum_fields';
  user_context_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Admin_to_user_notification" */
export enum Admin_To_User_Notification_Update_Column {
  /** column name */
  BestPracticesEmailLastSent = 'best_practices_email_last_sent',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  RequestMeetingEmailLastSent = 'request_meeting_email_last_sent',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserContextId = 'user_context_id'
}

/** aggregate var_pop on columns */
export type Admin_To_User_Notification_Var_Pop_Fields = {
  __typename?: 'Admin_to_user_notification_var_pop_fields';
  user_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Admin_To_User_Notification_Var_Samp_Fields = {
  __typename?: 'Admin_to_user_notification_var_samp_fields';
  user_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Admin_To_User_Notification_Variance_Fields = {
  __typename?: 'Admin_to_user_notification_variance_fields';
  user_context_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "All_artists" */
export type All_Artists = {
  __typename?: 'All_artists';
  /** An object relationship */
  Context?: Maybe<Context>;
  context_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "All_artists" */
export type All_Artists_Aggregate = {
  __typename?: 'All_artists_aggregate';
  aggregate?: Maybe<All_Artists_Aggregate_Fields>;
  nodes: Array<All_Artists>;
};

/** aggregate fields of "All_artists" */
export type All_Artists_Aggregate_Fields = {
  __typename?: 'All_artists_aggregate_fields';
  avg?: Maybe<All_Artists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<All_Artists_Max_Fields>;
  min?: Maybe<All_Artists_Min_Fields>;
  stddev?: Maybe<All_Artists_Stddev_Fields>;
  stddev_pop?: Maybe<All_Artists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<All_Artists_Stddev_Samp_Fields>;
  sum?: Maybe<All_Artists_Sum_Fields>;
  var_pop?: Maybe<All_Artists_Var_Pop_Fields>;
  var_samp?: Maybe<All_Artists_Var_Samp_Fields>;
  variance?: Maybe<All_Artists_Variance_Fields>;
};


/** aggregate fields of "All_artists" */
export type All_Artists_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<All_Artists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type All_Artists_Avg_Fields = {
  __typename?: 'All_artists_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "All_artists". All fields are combined with a logical 'AND'. */
export type All_Artists_Bool_Exp = {
  Context?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<All_Artists_Bool_Exp>>;
  _not?: Maybe<All_Artists_Bool_Exp>;
  _or?: Maybe<Array<All_Artists_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type All_Artists_Max_Fields = {
  __typename?: 'All_artists_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type All_Artists_Min_Fields = {
  __typename?: 'All_artists_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "All_artists". */
export type All_Artists_Order_By = {
  Context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "All_artists" */
export enum All_Artists_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Email = 'email',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type All_Artists_Stddev_Fields = {
  __typename?: 'All_artists_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type All_Artists_Stddev_Pop_Fields = {
  __typename?: 'All_artists_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type All_Artists_Stddev_Samp_Fields = {
  __typename?: 'All_artists_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type All_Artists_Sum_Fields = {
  __typename?: 'All_artists_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type All_Artists_Var_Pop_Fields = {
  __typename?: 'All_artists_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type All_Artists_Var_Samp_Fields = {
  __typename?: 'All_artists_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type All_Artists_Variance_Fields = {
  __typename?: 'All_artists_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "All_artwork_availability" */
export type All_Artwork_Availability = {
  __typename?: 'All_artwork_availability';
  artwork_id?: Maybe<Scalars['bigint']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "All_artwork_availability" */
export type All_Artwork_Availability_Aggregate = {
  __typename?: 'All_artwork_availability_aggregate';
  aggregate?: Maybe<All_Artwork_Availability_Aggregate_Fields>;
  nodes: Array<All_Artwork_Availability>;
};

/** aggregate fields of "All_artwork_availability" */
export type All_Artwork_Availability_Aggregate_Fields = {
  __typename?: 'All_artwork_availability_aggregate_fields';
  avg?: Maybe<All_Artwork_Availability_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<All_Artwork_Availability_Max_Fields>;
  min?: Maybe<All_Artwork_Availability_Min_Fields>;
  stddev?: Maybe<All_Artwork_Availability_Stddev_Fields>;
  stddev_pop?: Maybe<All_Artwork_Availability_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<All_Artwork_Availability_Stddev_Samp_Fields>;
  sum?: Maybe<All_Artwork_Availability_Sum_Fields>;
  var_pop?: Maybe<All_Artwork_Availability_Var_Pop_Fields>;
  var_samp?: Maybe<All_Artwork_Availability_Var_Samp_Fields>;
  variance?: Maybe<All_Artwork_Availability_Variance_Fields>;
};


/** aggregate fields of "All_artwork_availability" */
export type All_Artwork_Availability_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<All_Artwork_Availability_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type All_Artwork_Availability_Avg_Fields = {
  __typename?: 'All_artwork_availability_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "All_artwork_availability". All fields are combined with a logical 'AND'. */
export type All_Artwork_Availability_Bool_Exp = {
  _and?: Maybe<Array<All_Artwork_Availability_Bool_Exp>>;
  _not?: Maybe<All_Artwork_Availability_Bool_Exp>;
  _or?: Maybe<Array<All_Artwork_Availability_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type All_Artwork_Availability_Max_Fields = {
  __typename?: 'All_artwork_availability_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type All_Artwork_Availability_Min_Fields = {
  __typename?: 'All_artwork_availability_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "All_artwork_availability". */
export type All_Artwork_Availability_Order_By = {
  artwork_id?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
};

/** select columns of table "All_artwork_availability" */
export enum All_Artwork_Availability_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsUnavailable = 'is_unavailable'
}

/** aggregate stddev on columns */
export type All_Artwork_Availability_Stddev_Fields = {
  __typename?: 'All_artwork_availability_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type All_Artwork_Availability_Stddev_Pop_Fields = {
  __typename?: 'All_artwork_availability_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type All_Artwork_Availability_Stddev_Samp_Fields = {
  __typename?: 'All_artwork_availability_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type All_Artwork_Availability_Sum_Fields = {
  __typename?: 'All_artwork_availability_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "All_artwork_availability_table" */
export type All_Artwork_Availability_Table = {
  __typename?: 'All_artwork_availability_table';
  artwork_id: Scalars['bigint'];
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "All_artwork_availability_table" */
export type All_Artwork_Availability_Table_Aggregate = {
  __typename?: 'All_artwork_availability_table_aggregate';
  aggregate?: Maybe<All_Artwork_Availability_Table_Aggregate_Fields>;
  nodes: Array<All_Artwork_Availability_Table>;
};

/** aggregate fields of "All_artwork_availability_table" */
export type All_Artwork_Availability_Table_Aggregate_Fields = {
  __typename?: 'All_artwork_availability_table_aggregate_fields';
  avg?: Maybe<All_Artwork_Availability_Table_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<All_Artwork_Availability_Table_Max_Fields>;
  min?: Maybe<All_Artwork_Availability_Table_Min_Fields>;
  stddev?: Maybe<All_Artwork_Availability_Table_Stddev_Fields>;
  stddev_pop?: Maybe<All_Artwork_Availability_Table_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<All_Artwork_Availability_Table_Stddev_Samp_Fields>;
  sum?: Maybe<All_Artwork_Availability_Table_Sum_Fields>;
  var_pop?: Maybe<All_Artwork_Availability_Table_Var_Pop_Fields>;
  var_samp?: Maybe<All_Artwork_Availability_Table_Var_Samp_Fields>;
  variance?: Maybe<All_Artwork_Availability_Table_Variance_Fields>;
};


/** aggregate fields of "All_artwork_availability_table" */
export type All_Artwork_Availability_Table_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<All_Artwork_Availability_Table_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type All_Artwork_Availability_Table_Avg_Fields = {
  __typename?: 'All_artwork_availability_table_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "All_artwork_availability_table". All fields are combined with a logical 'AND'. */
export type All_Artwork_Availability_Table_Bool_Exp = {
  _and?: Maybe<Array<All_Artwork_Availability_Table_Bool_Exp>>;
  _not?: Maybe<All_Artwork_Availability_Table_Bool_Exp>;
  _or?: Maybe<Array<All_Artwork_Availability_Table_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "All_artwork_availability_table" */
export enum All_Artwork_Availability_Table_Constraint {
  /** unique or primary key constraint on columns "artwork_id" */
  AllArtworkAvailabilityTablePkey = 'All_artwork_availability_table_pkey'
}

/** input type for incrementing numeric columns in table "All_artwork_availability_table" */
export type All_Artwork_Availability_Table_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "All_artwork_availability_table" */
export type All_Artwork_Availability_Table_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type All_Artwork_Availability_Table_Max_Fields = {
  __typename?: 'All_artwork_availability_table_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type All_Artwork_Availability_Table_Min_Fields = {
  __typename?: 'All_artwork_availability_table_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "All_artwork_availability_table" */
export type All_Artwork_Availability_Table_Mutation_Response = {
  __typename?: 'All_artwork_availability_table_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<All_Artwork_Availability_Table>;
};

/** on_conflict condition type for table "All_artwork_availability_table" */
export type All_Artwork_Availability_Table_On_Conflict = {
  constraint: All_Artwork_Availability_Table_Constraint;
  update_columns?: Array<All_Artwork_Availability_Table_Update_Column>;
  where?: Maybe<All_Artwork_Availability_Table_Bool_Exp>;
};

/** Ordering options when selecting data from "All_artwork_availability_table". */
export type All_Artwork_Availability_Table_Order_By = {
  artwork_id?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
};

/** primary key columns input for table: All_artwork_availability_table */
export type All_Artwork_Availability_Table_Pk_Columns_Input = {
  artwork_id: Scalars['bigint'];
};

/** select columns of table "All_artwork_availability_table" */
export enum All_Artwork_Availability_Table_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsUnavailable = 'is_unavailable'
}

/** input type for updating data in table "All_artwork_availability_table" */
export type All_Artwork_Availability_Table_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type All_Artwork_Availability_Table_Stddev_Fields = {
  __typename?: 'All_artwork_availability_table_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type All_Artwork_Availability_Table_Stddev_Pop_Fields = {
  __typename?: 'All_artwork_availability_table_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type All_Artwork_Availability_Table_Stddev_Samp_Fields = {
  __typename?: 'All_artwork_availability_table_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type All_Artwork_Availability_Table_Sum_Fields = {
  __typename?: 'All_artwork_availability_table_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "All_artwork_availability_table" */
export enum All_Artwork_Availability_Table_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsUnavailable = 'is_unavailable'
}

/** aggregate var_pop on columns */
export type All_Artwork_Availability_Table_Var_Pop_Fields = {
  __typename?: 'All_artwork_availability_table_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type All_Artwork_Availability_Table_Var_Samp_Fields = {
  __typename?: 'All_artwork_availability_table_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type All_Artwork_Availability_Table_Variance_Fields = {
  __typename?: 'All_artwork_availability_table_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type All_Artwork_Availability_Var_Pop_Fields = {
  __typename?: 'All_artwork_availability_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type All_Artwork_Availability_Var_Samp_Fields = {
  __typename?: 'All_artwork_availability_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type All_Artwork_Availability_Variance_Fields = {
  __typename?: 'All_artwork_availability_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "All_collectors" */
export type All_Collectors = {
  __typename?: 'All_collectors';
  /** An object relationship */
  Context?: Maybe<Context>;
  context_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "All_collectors" */
export type All_Collectors_Aggregate = {
  __typename?: 'All_collectors_aggregate';
  aggregate?: Maybe<All_Collectors_Aggregate_Fields>;
  nodes: Array<All_Collectors>;
};

/** aggregate fields of "All_collectors" */
export type All_Collectors_Aggregate_Fields = {
  __typename?: 'All_collectors_aggregate_fields';
  avg?: Maybe<All_Collectors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<All_Collectors_Max_Fields>;
  min?: Maybe<All_Collectors_Min_Fields>;
  stddev?: Maybe<All_Collectors_Stddev_Fields>;
  stddev_pop?: Maybe<All_Collectors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<All_Collectors_Stddev_Samp_Fields>;
  sum?: Maybe<All_Collectors_Sum_Fields>;
  var_pop?: Maybe<All_Collectors_Var_Pop_Fields>;
  var_samp?: Maybe<All_Collectors_Var_Samp_Fields>;
  variance?: Maybe<All_Collectors_Variance_Fields>;
};


/** aggregate fields of "All_collectors" */
export type All_Collectors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<All_Collectors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type All_Collectors_Avg_Fields = {
  __typename?: 'All_collectors_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "All_collectors". All fields are combined with a logical 'AND'. */
export type All_Collectors_Bool_Exp = {
  Context?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<All_Collectors_Bool_Exp>>;
  _not?: Maybe<All_Collectors_Bool_Exp>;
  _or?: Maybe<Array<All_Collectors_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  is_public?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type All_Collectors_Max_Fields = {
  __typename?: 'All_collectors_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type All_Collectors_Min_Fields = {
  __typename?: 'All_collectors_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "All_collectors". */
export type All_Collectors_Order_By = {
  Context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
};

/** select columns of table "All_collectors" */
export enum All_Collectors_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Handle = 'handle',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  LastName = 'last_name'
}

/** aggregate stddev on columns */
export type All_Collectors_Stddev_Fields = {
  __typename?: 'All_collectors_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type All_Collectors_Stddev_Pop_Fields = {
  __typename?: 'All_collectors_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type All_Collectors_Stddev_Samp_Fields = {
  __typename?: 'All_collectors_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type All_Collectors_Sum_Fields = {
  __typename?: 'All_collectors_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type All_Collectors_Var_Pop_Fields = {
  __typename?: 'All_collectors_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type All_Collectors_Var_Samp_Fields = {
  __typename?: 'All_collectors_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type All_Collectors_Variance_Fields = {
  __typename?: 'All_collectors_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "All_galleries" */
export type All_Galleries = {
  __typename?: 'All_galleries';
  /** An object relationship */
  Context?: Maybe<Context>;
  context_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "All_galleries" */
export type All_Galleries_Aggregate = {
  __typename?: 'All_galleries_aggregate';
  aggregate?: Maybe<All_Galleries_Aggregate_Fields>;
  nodes: Array<All_Galleries>;
};

/** aggregate fields of "All_galleries" */
export type All_Galleries_Aggregate_Fields = {
  __typename?: 'All_galleries_aggregate_fields';
  avg?: Maybe<All_Galleries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<All_Galleries_Max_Fields>;
  min?: Maybe<All_Galleries_Min_Fields>;
  stddev?: Maybe<All_Galleries_Stddev_Fields>;
  stddev_pop?: Maybe<All_Galleries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<All_Galleries_Stddev_Samp_Fields>;
  sum?: Maybe<All_Galleries_Sum_Fields>;
  var_pop?: Maybe<All_Galleries_Var_Pop_Fields>;
  var_samp?: Maybe<All_Galleries_Var_Samp_Fields>;
  variance?: Maybe<All_Galleries_Variance_Fields>;
};


/** aggregate fields of "All_galleries" */
export type All_Galleries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<All_Galleries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type All_Galleries_Avg_Fields = {
  __typename?: 'All_galleries_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "All_galleries". All fields are combined with a logical 'AND'. */
export type All_Galleries_Bool_Exp = {
  Context?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<All_Galleries_Bool_Exp>>;
  _not?: Maybe<All_Galleries_Bool_Exp>;
  _or?: Maybe<Array<All_Galleries_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type All_Galleries_Max_Fields = {
  __typename?: 'All_galleries_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type All_Galleries_Min_Fields = {
  __typename?: 'All_galleries_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "All_galleries". */
export type All_Galleries_Order_By = {
  Context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "All_galleries" */
export enum All_Galleries_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Email = 'email',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type All_Galleries_Stddev_Fields = {
  __typename?: 'All_galleries_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type All_Galleries_Stddev_Pop_Fields = {
  __typename?: 'All_galleries_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type All_Galleries_Stddev_Samp_Fields = {
  __typename?: 'All_galleries_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type All_Galleries_Sum_Fields = {
  __typename?: 'All_galleries_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type All_Galleries_Var_Pop_Fields = {
  __typename?: 'All_galleries_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type All_Galleries_Var_Samp_Fields = {
  __typename?: 'All_galleries_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type All_Galleries_Variance_Fields = {
  __typename?: 'All_galleries_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** Used to store analytics data generated by mobile, backend and frontend apps. Only production data will be stored. */
export type Analytics_Events = {
  __typename?: 'Analytics_events';
  /** An array relationship */
  Analytics_kaleidoCard_events: Array<Analytics_KaleidoCard_Events>;
  /** An aggregate relationship */
  Analytics_kaleidoCard_events_aggregate: Analytics_KaleidoCard_Events_Aggregate;
  /** An object relationship */
  context?: Maybe<Context>;
  created_at: Scalars['timestamptz'];
  event_action: Scalars['String'];
  event_category: Scalars['String'];
  hit_type: Scalars['String'];
  id: Scalars['bigint'];
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  test_account: Array<Test_Accounts>;
  /** An aggregate relationship */
  test_account_aggregate: Test_Accounts_Aggregate;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};


/** Used to store analytics data generated by mobile, backend and frontend apps. Only production data will be stored. */
export type Analytics_EventsAnalytics_KaleidoCard_EventsArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Events_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
};


/** Used to store analytics data generated by mobile, backend and frontend apps. Only production data will be stored. */
export type Analytics_EventsAnalytics_KaleidoCard_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Events_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
};


/** Used to store analytics data generated by mobile, backend and frontend apps. Only production data will be stored. */
export type Analytics_EventsTagsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Used to store analytics data generated by mobile, backend and frontend apps. Only production data will be stored. */
export type Analytics_EventsTest_AccountArgs = {
  distinct_on?: Maybe<Array<Test_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Accounts_Order_By>>;
  where?: Maybe<Test_Accounts_Bool_Exp>;
};


/** Used to store analytics data generated by mobile, backend and frontend apps. Only production data will be stored. */
export type Analytics_EventsTest_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Accounts_Order_By>>;
  where?: Maybe<Test_Accounts_Bool_Exp>;
};

/** aggregated selection of "Analytics_events" */
export type Analytics_Events_Aggregate = {
  __typename?: 'Analytics_events_aggregate';
  aggregate?: Maybe<Analytics_Events_Aggregate_Fields>;
  nodes: Array<Analytics_Events>;
};

/** aggregate fields of "Analytics_events" */
export type Analytics_Events_Aggregate_Fields = {
  __typename?: 'Analytics_events_aggregate_fields';
  avg?: Maybe<Analytics_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Analytics_Events_Max_Fields>;
  min?: Maybe<Analytics_Events_Min_Fields>;
  stddev?: Maybe<Analytics_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Analytics_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Analytics_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Analytics_Events_Sum_Fields>;
  var_pop?: Maybe<Analytics_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Analytics_Events_Var_Samp_Fields>;
  variance?: Maybe<Analytics_Events_Variance_Fields>;
};


/** aggregate fields of "Analytics_events" */
export type Analytics_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Analytics_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Analytics_Events_Append_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Analytics_Events_Avg_Fields = {
  __typename?: 'Analytics_events_avg_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Analytics_events". All fields are combined with a logical 'AND'. */
export type Analytics_Events_Bool_Exp = {
  Analytics_kaleidoCard_events?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
  _and?: Maybe<Array<Analytics_Events_Bool_Exp>>;
  _not?: Maybe<Analytics_Events_Bool_Exp>;
  _or?: Maybe<Array<Analytics_Events_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  event_action?: Maybe<String_Comparison_Exp>;
  event_category?: Maybe<String_Comparison_Exp>;
  hit_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  profile_context_id?: Maybe<Bigint_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  tags?: Maybe<Jsonb_Comparison_Exp>;
  test_account?: Maybe<Test_Accounts_Bool_Exp>;
  visitor_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Analytics_events" */
export enum Analytics_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  AnalyticsEventsPkey = 'Analytics_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Analytics_Events_Delete_At_Path_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Analytics_Events_Delete_Elem_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Analytics_Events_Delete_Key_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Analytics_events" */
export type Analytics_Events_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Analytics_events" */
export type Analytics_Events_Insert_Input = {
  Analytics_kaleidoCard_events?: Maybe<Analytics_KaleidoCard_Events_Arr_Rel_Insert_Input>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_action?: Maybe<Scalars['String']>;
  event_category?: Maybe<Scalars['String']>;
  hit_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
  test_account?: Maybe<Test_Accounts_Arr_Rel_Insert_Input>;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Analytics_Events_Max_Fields = {
  __typename?: 'Analytics_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_action?: Maybe<Scalars['String']>;
  event_category?: Maybe<Scalars['String']>;
  hit_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Analytics_Events_Min_Fields = {
  __typename?: 'Analytics_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_action?: Maybe<Scalars['String']>;
  event_category?: Maybe<Scalars['String']>;
  hit_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Analytics_events" */
export type Analytics_Events_Mutation_Response = {
  __typename?: 'Analytics_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Analytics_Events>;
};

/** input type for inserting object relation for remote table "Analytics_events" */
export type Analytics_Events_Obj_Rel_Insert_Input = {
  data: Analytics_Events_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Analytics_Events_On_Conflict>;
};

/** on_conflict condition type for table "Analytics_events" */
export type Analytics_Events_On_Conflict = {
  constraint: Analytics_Events_Constraint;
  update_columns?: Array<Analytics_Events_Update_Column>;
  where?: Maybe<Analytics_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "Analytics_events". */
export type Analytics_Events_Order_By = {
  Analytics_kaleidoCard_events_aggregate?: Maybe<Analytics_KaleidoCard_Events_Aggregate_Order_By>;
  context?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  event_action?: Maybe<Order_By>;
  event_category?: Maybe<Order_By>;
  hit_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  test_account_aggregate?: Maybe<Test_Accounts_Aggregate_Order_By>;
  visitor_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Analytics_events */
export type Analytics_Events_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Analytics_Events_Prepend_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Analytics_events" */
export enum Analytics_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventAction = 'event_action',
  /** column name */
  EventCategory = 'event_category',
  /** column name */
  HitType = 'hit_type',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  VisitorId = 'visitor_id'
}

/** input type for updating data in table "Analytics_events" */
export type Analytics_Events_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  event_action?: Maybe<Scalars['String']>;
  event_category?: Maybe<Scalars['String']>;
  hit_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Analytics_Events_Stddev_Fields = {
  __typename?: 'Analytics_events_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Analytics_Events_Stddev_Pop_Fields = {
  __typename?: 'Analytics_events_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Analytics_Events_Stddev_Samp_Fields = {
  __typename?: 'Analytics_events_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Analytics_Events_Sum_Fields = {
  __typename?: 'Analytics_events_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Analytics_events" */
export enum Analytics_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventAction = 'event_action',
  /** column name */
  EventCategory = 'event_category',
  /** column name */
  HitType = 'hit_type',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  VisitorId = 'visitor_id'
}

/** aggregate var_pop on columns */
export type Analytics_Events_Var_Pop_Fields = {
  __typename?: 'Analytics_events_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Analytics_Events_Var_Samp_Fields = {
  __typename?: 'Analytics_events_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Analytics_Events_Variance_Fields = {
  __typename?: 'Analytics_events_variance_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** Used to calculate engagement of an user. */
export type Analytics_Index_History = {
  __typename?: 'Analytics_index_history';
  /** An object relationship */
  Analytics_index_value: Analytics_Index_Values;
  /** An object relationship */
  Context: Context;
  analytics_index_values_id: Scalars['bigint'];
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['bigint'];
};


/** Used to calculate engagement of an user. */
export type Analytics_Index_HistoryDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Analytics_index_history" */
export type Analytics_Index_History_Aggregate = {
  __typename?: 'Analytics_index_history_aggregate';
  aggregate?: Maybe<Analytics_Index_History_Aggregate_Fields>;
  nodes: Array<Analytics_Index_History>;
};

/** aggregate fields of "Analytics_index_history" */
export type Analytics_Index_History_Aggregate_Fields = {
  __typename?: 'Analytics_index_history_aggregate_fields';
  avg?: Maybe<Analytics_Index_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Analytics_Index_History_Max_Fields>;
  min?: Maybe<Analytics_Index_History_Min_Fields>;
  stddev?: Maybe<Analytics_Index_History_Stddev_Fields>;
  stddev_pop?: Maybe<Analytics_Index_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Analytics_Index_History_Stddev_Samp_Fields>;
  sum?: Maybe<Analytics_Index_History_Sum_Fields>;
  var_pop?: Maybe<Analytics_Index_History_Var_Pop_Fields>;
  var_samp?: Maybe<Analytics_Index_History_Var_Samp_Fields>;
  variance?: Maybe<Analytics_Index_History_Variance_Fields>;
};


/** aggregate fields of "Analytics_index_history" */
export type Analytics_Index_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Analytics_Index_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Analytics_Index_History_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Analytics_Index_History_Avg_Fields = {
  __typename?: 'Analytics_index_history_avg_fields';
  analytics_index_values_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Analytics_index_history". All fields are combined with a logical 'AND'. */
export type Analytics_Index_History_Bool_Exp = {
  Analytics_index_value?: Maybe<Analytics_Index_Values_Bool_Exp>;
  Context?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<Analytics_Index_History_Bool_Exp>>;
  _not?: Maybe<Analytics_Index_History_Bool_Exp>;
  _or?: Maybe<Array<Analytics_Index_History_Bool_Exp>>;
  analytics_index_values_id?: Maybe<Bigint_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Analytics_index_history" */
export enum Analytics_Index_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  AnalyticsIndexHistoryPkey = 'Analytics_index_history_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Analytics_Index_History_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Analytics_Index_History_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Analytics_Index_History_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Analytics_index_history" */
export type Analytics_Index_History_Inc_Input = {
  analytics_index_values_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Analytics_index_history" */
export type Analytics_Index_History_Insert_Input = {
  Analytics_index_value?: Maybe<Analytics_Index_Values_Obj_Rel_Insert_Input>;
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  analytics_index_values_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Analytics_Index_History_Max_Fields = {
  __typename?: 'Analytics_index_history_max_fields';
  analytics_index_values_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Analytics_Index_History_Min_Fields = {
  __typename?: 'Analytics_index_history_min_fields';
  analytics_index_values_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Analytics_index_history" */
export type Analytics_Index_History_Mutation_Response = {
  __typename?: 'Analytics_index_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Analytics_Index_History>;
};

/** on_conflict condition type for table "Analytics_index_history" */
export type Analytics_Index_History_On_Conflict = {
  constraint: Analytics_Index_History_Constraint;
  update_columns?: Array<Analytics_Index_History_Update_Column>;
  where?: Maybe<Analytics_Index_History_Bool_Exp>;
};

/** Ordering options when selecting data from "Analytics_index_history". */
export type Analytics_Index_History_Order_By = {
  Analytics_index_value?: Maybe<Analytics_Index_Values_Order_By>;
  Context?: Maybe<Context_Order_By>;
  analytics_index_values_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: Analytics_index_history */
export type Analytics_Index_History_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Analytics_Index_History_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Analytics_index_history" */
export enum Analytics_Index_History_Select_Column {
  /** column name */
  AnalyticsIndexValuesId = 'analytics_index_values_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "Analytics_index_history" */
export type Analytics_Index_History_Set_Input = {
  analytics_index_values_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Analytics_Index_History_Stddev_Fields = {
  __typename?: 'Analytics_index_history_stddev_fields';
  analytics_index_values_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Analytics_Index_History_Stddev_Pop_Fields = {
  __typename?: 'Analytics_index_history_stddev_pop_fields';
  analytics_index_values_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Analytics_Index_History_Stddev_Samp_Fields = {
  __typename?: 'Analytics_index_history_stddev_samp_fields';
  analytics_index_values_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Analytics_Index_History_Sum_Fields = {
  __typename?: 'Analytics_index_history_sum_fields';
  analytics_index_values_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Analytics_index_history" */
export enum Analytics_Index_History_Update_Column {
  /** column name */
  AnalyticsIndexValuesId = 'analytics_index_values_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Analytics_Index_History_Var_Pop_Fields = {
  __typename?: 'Analytics_index_history_var_pop_fields';
  analytics_index_values_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Analytics_Index_History_Var_Samp_Fields = {
  __typename?: 'Analytics_index_history_var_samp_fields';
  analytics_index_values_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Analytics_Index_History_Variance_Fields = {
  __typename?: 'Analytics_index_history_variance_fields';
  analytics_index_values_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Defines type of user engagement. (ex: Artwork publication) */
export type Analytics_Index_Type = {
  __typename?: 'Analytics_index_type';
  created_at: Scalars['timestamptz'];
  enabled: Scalars['Boolean'];
  id: Scalars['bigint'];
  name: Scalars['String'];
};

/** aggregated selection of "Analytics_index_type" */
export type Analytics_Index_Type_Aggregate = {
  __typename?: 'Analytics_index_type_aggregate';
  aggregate?: Maybe<Analytics_Index_Type_Aggregate_Fields>;
  nodes: Array<Analytics_Index_Type>;
};

/** aggregate fields of "Analytics_index_type" */
export type Analytics_Index_Type_Aggregate_Fields = {
  __typename?: 'Analytics_index_type_aggregate_fields';
  avg?: Maybe<Analytics_Index_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Analytics_Index_Type_Max_Fields>;
  min?: Maybe<Analytics_Index_Type_Min_Fields>;
  stddev?: Maybe<Analytics_Index_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Analytics_Index_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Analytics_Index_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Analytics_Index_Type_Sum_Fields>;
  var_pop?: Maybe<Analytics_Index_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Analytics_Index_Type_Var_Samp_Fields>;
  variance?: Maybe<Analytics_Index_Type_Variance_Fields>;
};


/** aggregate fields of "Analytics_index_type" */
export type Analytics_Index_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Analytics_Index_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Analytics_Index_Type_Avg_Fields = {
  __typename?: 'Analytics_index_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Analytics_index_type". All fields are combined with a logical 'AND'. */
export type Analytics_Index_Type_Bool_Exp = {
  _and?: Maybe<Array<Analytics_Index_Type_Bool_Exp>>;
  _not?: Maybe<Analytics_Index_Type_Bool_Exp>;
  _or?: Maybe<Array<Analytics_Index_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  enabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Analytics_index_type" */
export enum Analytics_Index_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  DataIndexTypePkey = 'Data_index_type_pkey'
}

/** input type for incrementing numeric columns in table "Analytics_index_type" */
export type Analytics_Index_Type_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Analytics_index_type" */
export type Analytics_Index_Type_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Analytics_Index_Type_Max_Fields = {
  __typename?: 'Analytics_index_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Analytics_Index_Type_Min_Fields = {
  __typename?: 'Analytics_index_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Analytics_index_type" */
export type Analytics_Index_Type_Mutation_Response = {
  __typename?: 'Analytics_index_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Analytics_Index_Type>;
};

/** on_conflict condition type for table "Analytics_index_type" */
export type Analytics_Index_Type_On_Conflict = {
  constraint: Analytics_Index_Type_Constraint;
  update_columns?: Array<Analytics_Index_Type_Update_Column>;
  where?: Maybe<Analytics_Index_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "Analytics_index_type". */
export type Analytics_Index_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  enabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: Analytics_index_type */
export type Analytics_Index_Type_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Analytics_index_type" */
export enum Analytics_Index_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "Analytics_index_type" */
export type Analytics_Index_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Analytics_Index_Type_Stddev_Fields = {
  __typename?: 'Analytics_index_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Analytics_Index_Type_Stddev_Pop_Fields = {
  __typename?: 'Analytics_index_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Analytics_Index_Type_Stddev_Samp_Fields = {
  __typename?: 'Analytics_index_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Analytics_Index_Type_Sum_Fields = {
  __typename?: 'Analytics_index_type_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Analytics_index_type" */
export enum Analytics_Index_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Analytics_Index_Type_Var_Pop_Fields = {
  __typename?: 'Analytics_index_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Analytics_Index_Type_Var_Samp_Fields = {
  __typename?: 'Analytics_index_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Analytics_Index_Type_Variance_Fields = {
  __typename?: 'Analytics_index_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Defines values in order to calculate user engagement */
export type Analytics_Index_Values = {
  __typename?: 'Analytics_index_values';
  analytics_index_type_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  pointValue: Scalars['numeric'];
};

/** aggregated selection of "Analytics_index_values" */
export type Analytics_Index_Values_Aggregate = {
  __typename?: 'Analytics_index_values_aggregate';
  aggregate?: Maybe<Analytics_Index_Values_Aggregate_Fields>;
  nodes: Array<Analytics_Index_Values>;
};

/** aggregate fields of "Analytics_index_values" */
export type Analytics_Index_Values_Aggregate_Fields = {
  __typename?: 'Analytics_index_values_aggregate_fields';
  avg?: Maybe<Analytics_Index_Values_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Analytics_Index_Values_Max_Fields>;
  min?: Maybe<Analytics_Index_Values_Min_Fields>;
  stddev?: Maybe<Analytics_Index_Values_Stddev_Fields>;
  stddev_pop?: Maybe<Analytics_Index_Values_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Analytics_Index_Values_Stddev_Samp_Fields>;
  sum?: Maybe<Analytics_Index_Values_Sum_Fields>;
  var_pop?: Maybe<Analytics_Index_Values_Var_Pop_Fields>;
  var_samp?: Maybe<Analytics_Index_Values_Var_Samp_Fields>;
  variance?: Maybe<Analytics_Index_Values_Variance_Fields>;
};


/** aggregate fields of "Analytics_index_values" */
export type Analytics_Index_Values_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Analytics_Index_Values_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Analytics_Index_Values_Avg_Fields = {
  __typename?: 'Analytics_index_values_avg_fields';
  analytics_index_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Analytics_index_values". All fields are combined with a logical 'AND'. */
export type Analytics_Index_Values_Bool_Exp = {
  _and?: Maybe<Array<Analytics_Index_Values_Bool_Exp>>;
  _not?: Maybe<Analytics_Index_Values_Bool_Exp>;
  _or?: Maybe<Array<Analytics_Index_Values_Bool_Exp>>;
  analytics_index_type_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  pointValue?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "Analytics_index_values" */
export enum Analytics_Index_Values_Constraint {
  /** unique or primary key constraint on columns "id" */
  DataIndexValuePkey = 'Data_Index_Value_pkey'
}

/** input type for incrementing numeric columns in table "Analytics_index_values" */
export type Analytics_Index_Values_Inc_Input = {
  analytics_index_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pointValue?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Analytics_index_values" */
export type Analytics_Index_Values_Insert_Input = {
  analytics_index_type_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pointValue?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Analytics_Index_Values_Max_Fields = {
  __typename?: 'Analytics_index_values_max_fields';
  analytics_index_type_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pointValue?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Analytics_Index_Values_Min_Fields = {
  __typename?: 'Analytics_index_values_min_fields';
  analytics_index_type_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pointValue?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "Analytics_index_values" */
export type Analytics_Index_Values_Mutation_Response = {
  __typename?: 'Analytics_index_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Analytics_Index_Values>;
};

/** input type for inserting object relation for remote table "Analytics_index_values" */
export type Analytics_Index_Values_Obj_Rel_Insert_Input = {
  data: Analytics_Index_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Analytics_Index_Values_On_Conflict>;
};

/** on_conflict condition type for table "Analytics_index_values" */
export type Analytics_Index_Values_On_Conflict = {
  constraint: Analytics_Index_Values_Constraint;
  update_columns?: Array<Analytics_Index_Values_Update_Column>;
  where?: Maybe<Analytics_Index_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "Analytics_index_values". */
export type Analytics_Index_Values_Order_By = {
  analytics_index_type_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pointValue?: Maybe<Order_By>;
};

/** primary key columns input for table: Analytics_index_values */
export type Analytics_Index_Values_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Analytics_index_values" */
export enum Analytics_Index_Values_Select_Column {
  /** column name */
  AnalyticsIndexTypeId = 'analytics_index_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PointValue = 'pointValue'
}

/** input type for updating data in table "Analytics_index_values" */
export type Analytics_Index_Values_Set_Input = {
  analytics_index_type_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  pointValue?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Analytics_Index_Values_Stddev_Fields = {
  __typename?: 'Analytics_index_values_stddev_fields';
  analytics_index_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Analytics_Index_Values_Stddev_Pop_Fields = {
  __typename?: 'Analytics_index_values_stddev_pop_fields';
  analytics_index_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Analytics_Index_Values_Stddev_Samp_Fields = {
  __typename?: 'Analytics_index_values_stddev_samp_fields';
  analytics_index_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Analytics_Index_Values_Sum_Fields = {
  __typename?: 'Analytics_index_values_sum_fields';
  analytics_index_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  pointValue?: Maybe<Scalars['numeric']>;
};

/** update columns of table "Analytics_index_values" */
export enum Analytics_Index_Values_Update_Column {
  /** column name */
  AnalyticsIndexTypeId = 'analytics_index_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PointValue = 'pointValue'
}

/** aggregate var_pop on columns */
export type Analytics_Index_Values_Var_Pop_Fields = {
  __typename?: 'Analytics_index_values_var_pop_fields';
  analytics_index_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Analytics_Index_Values_Var_Samp_Fields = {
  __typename?: 'Analytics_index_values_var_samp_fields';
  analytics_index_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Analytics_Index_Values_Variance_Fields = {
  __typename?: 'Analytics_index_values_variance_fields';
  analytics_index_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  pointValue?: Maybe<Scalars['Float']>;
};

/** Table to get the result for the function */
export type Analytics_KaleidoCard_Amounts = {
  __typename?: 'Analytics_kaleidoCard_Amounts';
  amount: Scalars['bigint'];
  category: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['bigint'];
  type: Scalars['String'];
  url: Scalars['String'];
};

/** aggregated selection of "Analytics_kaleidoCard_Amounts" */
export type Analytics_KaleidoCard_Amounts_Aggregate = {
  __typename?: 'Analytics_kaleidoCard_Amounts_aggregate';
  aggregate?: Maybe<Analytics_KaleidoCard_Amounts_Aggregate_Fields>;
  nodes: Array<Analytics_KaleidoCard_Amounts>;
};

/** aggregate fields of "Analytics_kaleidoCard_Amounts" */
export type Analytics_KaleidoCard_Amounts_Aggregate_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_aggregate_fields';
  avg?: Maybe<Analytics_KaleidoCard_Amounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Analytics_KaleidoCard_Amounts_Max_Fields>;
  min?: Maybe<Analytics_KaleidoCard_Amounts_Min_Fields>;
  stddev?: Maybe<Analytics_KaleidoCard_Amounts_Stddev_Fields>;
  stddev_pop?: Maybe<Analytics_KaleidoCard_Amounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Analytics_KaleidoCard_Amounts_Stddev_Samp_Fields>;
  sum?: Maybe<Analytics_KaleidoCard_Amounts_Sum_Fields>;
  var_pop?: Maybe<Analytics_KaleidoCard_Amounts_Var_Pop_Fields>;
  var_samp?: Maybe<Analytics_KaleidoCard_Amounts_Var_Samp_Fields>;
  variance?: Maybe<Analytics_KaleidoCard_Amounts_Variance_Fields>;
};


/** aggregate fields of "Analytics_kaleidoCard_Amounts" */
export type Analytics_KaleidoCard_Amounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Analytics_KaleidoCard_Amounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Analytics_KaleidoCard_Amounts_Avg_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Analytics_kaleidoCard_Amounts". All fields are combined with a logical 'AND'. */
export type Analytics_KaleidoCard_Amounts_Bool_Exp = {
  _and?: Maybe<Array<Analytics_KaleidoCard_Amounts_Bool_Exp>>;
  _not?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
  _or?: Maybe<Array<Analytics_KaleidoCard_Amounts_Bool_Exp>>;
  amount?: Maybe<Bigint_Comparison_Exp>;
  category?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Analytics_kaleidoCard_Amounts" */
export enum Analytics_KaleidoCard_Amounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  AnalyticsKaleidoCardAmountsPkey = 'Analytics_kaleidoCard_Amounts_pkey'
}

/** input type for incrementing numeric columns in table "Analytics_kaleidoCard_Amounts" */
export type Analytics_KaleidoCard_Amounts_Inc_Input = {
  amount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Analytics_kaleidoCard_Amounts" */
export type Analytics_KaleidoCard_Amounts_Insert_Input = {
  amount?: Maybe<Scalars['bigint']>;
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Analytics_KaleidoCard_Amounts_Max_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_max_fields';
  amount?: Maybe<Scalars['bigint']>;
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Analytics_KaleidoCard_Amounts_Min_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_min_fields';
  amount?: Maybe<Scalars['bigint']>;
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Analytics_kaleidoCard_Amounts" */
export type Analytics_KaleidoCard_Amounts_Mutation_Response = {
  __typename?: 'Analytics_kaleidoCard_Amounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Analytics_KaleidoCard_Amounts>;
};

/** on_conflict condition type for table "Analytics_kaleidoCard_Amounts" */
export type Analytics_KaleidoCard_Amounts_On_Conflict = {
  constraint: Analytics_KaleidoCard_Amounts_Constraint;
  update_columns?: Array<Analytics_KaleidoCard_Amounts_Update_Column>;
  where?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
};

/** Ordering options when selecting data from "Analytics_kaleidoCard_Amounts". */
export type Analytics_KaleidoCard_Amounts_Order_By = {
  amount?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: Analytics_kaleidoCard_Amounts */
export type Analytics_KaleidoCard_Amounts_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Analytics_kaleidoCard_Amounts" */
export enum Analytics_KaleidoCard_Amounts_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "Analytics_kaleidoCard_Amounts" */
export type Analytics_KaleidoCard_Amounts_Set_Input = {
  amount?: Maybe<Scalars['bigint']>;
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Analytics_KaleidoCard_Amounts_Stddev_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Analytics_KaleidoCard_Amounts_Stddev_Pop_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Analytics_KaleidoCard_Amounts_Stddev_Samp_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Analytics_KaleidoCard_Amounts_Sum_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_sum_fields';
  amount?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Analytics_kaleidoCard_Amounts" */
export enum Analytics_KaleidoCard_Amounts_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Category = 'category',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Analytics_KaleidoCard_Amounts_Var_Pop_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Analytics_KaleidoCard_Amounts_Var_Samp_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Analytics_KaleidoCard_Amounts_Variance_Fields = {
  __typename?: 'Analytics_kaleidoCard_Amounts_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Table to keep information about Kaleido Card events */
export type Analytics_KaleidoCard_Events = {
  __typename?: 'Analytics_kaleidoCard_events';
  category?: Maybe<Enum_Analytics_KaleidoCard_Category_Enum>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  event_ref_id?: Maybe<Scalars['bigint']>;
  id: Scalars['Int'];
  profile_id: Scalars['bigint'];
  type: Enum_Analytics_Type_Enum;
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Aggregate = {
  __typename?: 'Analytics_kaleidoCard_events_aggregate';
  aggregate?: Maybe<Analytics_KaleidoCard_Events_Aggregate_Fields>;
  nodes: Array<Analytics_KaleidoCard_Events>;
};

/** aggregate fields of "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Aggregate_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_aggregate_fields';
  avg?: Maybe<Analytics_KaleidoCard_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Analytics_KaleidoCard_Events_Max_Fields>;
  min?: Maybe<Analytics_KaleidoCard_Events_Min_Fields>;
  stddev?: Maybe<Analytics_KaleidoCard_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Analytics_KaleidoCard_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Analytics_KaleidoCard_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Analytics_KaleidoCard_Events_Sum_Fields>;
  var_pop?: Maybe<Analytics_KaleidoCard_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Analytics_KaleidoCard_Events_Var_Samp_Fields>;
  variance?: Maybe<Analytics_KaleidoCard_Events_Variance_Fields>;
};


/** aggregate fields of "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Analytics_KaleidoCard_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Aggregate_Order_By = {
  avg?: Maybe<Analytics_KaleidoCard_Events_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Analytics_KaleidoCard_Events_Max_Order_By>;
  min?: Maybe<Analytics_KaleidoCard_Events_Min_Order_By>;
  stddev?: Maybe<Analytics_KaleidoCard_Events_Stddev_Order_By>;
  stddev_pop?: Maybe<Analytics_KaleidoCard_Events_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Analytics_KaleidoCard_Events_Stddev_Samp_Order_By>;
  sum?: Maybe<Analytics_KaleidoCard_Events_Sum_Order_By>;
  var_pop?: Maybe<Analytics_KaleidoCard_Events_Var_Pop_Order_By>;
  var_samp?: Maybe<Analytics_KaleidoCard_Events_Var_Samp_Order_By>;
  variance?: Maybe<Analytics_KaleidoCard_Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Arr_Rel_Insert_Input = {
  data: Array<Analytics_KaleidoCard_Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Analytics_KaleidoCard_Events_On_Conflict>;
};

/** aggregate avg on columns */
export type Analytics_KaleidoCard_Events_Avg_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_avg_fields';
  event_ref_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Avg_Order_By = {
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Analytics_kaleidoCard_events". All fields are combined with a logical 'AND'. */
export type Analytics_KaleidoCard_Events_Bool_Exp = {
  _and?: Maybe<Array<Analytics_KaleidoCard_Events_Bool_Exp>>;
  _not?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
  _or?: Maybe<Array<Analytics_KaleidoCard_Events_Bool_Exp>>;
  category?: Maybe<Enum_Analytics_KaleidoCard_Category_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  event_ref_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<Enum_Analytics_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Analytics_kaleidoCard_events" */
export enum Analytics_KaleidoCard_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  AnalyticsKaleidoCardEventsPkey = 'Analytics_kaleidoCard_events_pkey'
}

/** input type for incrementing numeric columns in table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Inc_Input = {
  event_ref_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Insert_Input = {
  category?: Maybe<Enum_Analytics_KaleidoCard_Category_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event_ref_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Analytics_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Analytics_KaleidoCard_Events_Max_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event_ref_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Analytics_KaleidoCard_Events_Min_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event_ref_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Mutation_Response = {
  __typename?: 'Analytics_kaleidoCard_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Analytics_KaleidoCard_Events>;
};

/** on_conflict condition type for table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_On_Conflict = {
  constraint: Analytics_KaleidoCard_Events_Constraint;
  update_columns?: Array<Analytics_KaleidoCard_Events_Update_Column>;
  where?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "Analytics_kaleidoCard_events". */
export type Analytics_KaleidoCard_Events_Order_By = {
  category?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: Analytics_kaleidoCard_events */
export type Analytics_KaleidoCard_Events_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Analytics_kaleidoCard_events" */
export enum Analytics_KaleidoCard_Events_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventRefId = 'event_ref_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Set_Input = {
  category?: Maybe<Enum_Analytics_KaleidoCard_Category_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  event_ref_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Analytics_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Analytics_KaleidoCard_Events_Stddev_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_stddev_fields';
  event_ref_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Stddev_Order_By = {
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Analytics_KaleidoCard_Events_Stddev_Pop_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_stddev_pop_fields';
  event_ref_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Stddev_Pop_Order_By = {
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Analytics_KaleidoCard_Events_Stddev_Samp_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_stddev_samp_fields';
  event_ref_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Stddev_Samp_Order_By = {
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Analytics_KaleidoCard_Events_Sum_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_sum_fields';
  event_ref_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Sum_Order_By = {
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** update columns of table "Analytics_kaleidoCard_events" */
export enum Analytics_KaleidoCard_Events_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EventRefId = 'event_ref_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Analytics_KaleidoCard_Events_Var_Pop_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_var_pop_fields';
  event_ref_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Var_Pop_Order_By = {
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Analytics_KaleidoCard_Events_Var_Samp_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_var_samp_fields';
  event_ref_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Var_Samp_Order_By = {
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Analytics_KaleidoCard_Events_Variance_Fields = {
  __typename?: 'Analytics_kaleidoCard_events_variance_fields';
  event_ref_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Analytics_kaleidoCard_events" */
export type Analytics_KaleidoCard_Events_Variance_Order_By = {
  event_ref_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

export type AnalyzeMessageContentReturnType = {
  __typename?: 'AnalyzeMessageContentReturnType';
  infractionInfo?: Maybe<FlagUserIfIsScammerReturnType>;
  isScammer: Scalars['Boolean'];
};

/** columns and relationships of "Announced_art_show" */
export type Announced_Art_Show = {
  __typename?: 'Announced_art_show';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  announce_at?: Maybe<Scalars['timestamptz']>;
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  closing_soon_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  grand_opening_at?: Maybe<Scalars['timestamptz']>;
  is_announced?: Maybe<Scalars['Boolean']>;
  opening_soon_at?: Maybe<Scalars['timestamptz']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  organizer?: Maybe<Context>;
  organizer_handle?: Maybe<Scalars['String']>;
  organizer_profile_id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  /** An object relationship */
  world_city?: Maybe<World_Cities>;
  world_city_id?: Maybe<Scalars['bigint']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Announced_art_show" */
export type Announced_Art_Show_Aggregate = {
  __typename?: 'Announced_art_show_aggregate';
  aggregate?: Maybe<Announced_Art_Show_Aggregate_Fields>;
  nodes: Array<Announced_Art_Show>;
};

/** aggregate fields of "Announced_art_show" */
export type Announced_Art_Show_Aggregate_Fields = {
  __typename?: 'Announced_art_show_aggregate_fields';
  avg?: Maybe<Announced_Art_Show_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Announced_Art_Show_Max_Fields>;
  min?: Maybe<Announced_Art_Show_Min_Fields>;
  stddev?: Maybe<Announced_Art_Show_Stddev_Fields>;
  stddev_pop?: Maybe<Announced_Art_Show_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Announced_Art_Show_Stddev_Samp_Fields>;
  sum?: Maybe<Announced_Art_Show_Sum_Fields>;
  var_pop?: Maybe<Announced_Art_Show_Var_Pop_Fields>;
  var_samp?: Maybe<Announced_Art_Show_Var_Samp_Fields>;
  variance?: Maybe<Announced_Art_Show_Variance_Fields>;
};


/** aggregate fields of "Announced_art_show" */
export type Announced_Art_Show_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Announced_Art_Show_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Announced_Art_Show_Avg_Fields = {
  __typename?: 'Announced_art_show_avg_fields';
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  organizer_profile_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Announced_art_show". All fields are combined with a logical 'AND'. */
export type Announced_Art_Show_Bool_Exp = {
  _and?: Maybe<Array<Announced_Art_Show_Bool_Exp>>;
  _not?: Maybe<Announced_Art_Show_Bool_Exp>;
  _or?: Maybe<Array<Announced_Art_Show_Bool_Exp>>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  announce_at?: Maybe<Timestamptz_Comparison_Exp>;
  art_show_collection_id?: Maybe<Bigint_Comparison_Exp>;
  banner_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  closing_soon_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  end_at?: Maybe<Timestamptz_Comparison_Exp>;
  grand_opening_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_announced?: Maybe<Boolean_Comparison_Exp>;
  opening_soon_at?: Maybe<Timestamptz_Comparison_Exp>;
  organized_by_context_id?: Maybe<Bigint_Comparison_Exp>;
  organizer?: Maybe<Context_Bool_Exp>;
  organizer_handle?: Maybe<String_Comparison_Exp>;
  organizer_profile_id?: Maybe<Bigint_Comparison_Exp>;
  start_at?: Maybe<Timestamptz_Comparison_Exp>;
  statement?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uri?: Maybe<String_Comparison_Exp>;
  world_city?: Maybe<World_Cities_Bool_Exp>;
  world_city_id?: Maybe<Bigint_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Announced_Art_Show_Max_Fields = {
  __typename?: 'Announced_art_show_max_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  announce_at?: Maybe<Scalars['timestamptz']>;
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  closing_soon_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  grand_opening_at?: Maybe<Scalars['timestamptz']>;
  opening_soon_at?: Maybe<Scalars['timestamptz']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  organizer_handle?: Maybe<Scalars['String']>;
  organizer_profile_id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  world_city_id?: Maybe<Scalars['bigint']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Announced_Art_Show_Min_Fields = {
  __typename?: 'Announced_art_show_min_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  announce_at?: Maybe<Scalars['timestamptz']>;
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  closing_soon_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  grand_opening_at?: Maybe<Scalars['timestamptz']>;
  opening_soon_at?: Maybe<Scalars['timestamptz']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  organizer_handle?: Maybe<Scalars['String']>;
  organizer_profile_id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  world_city_id?: Maybe<Scalars['bigint']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Announced_art_show". */
export type Announced_Art_Show_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  announce_at?: Maybe<Order_By>;
  art_show_collection_id?: Maybe<Order_By>;
  banner_metadata_id?: Maybe<Order_By>;
  closing_soon_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  end_at?: Maybe<Order_By>;
  grand_opening_at?: Maybe<Order_By>;
  is_announced?: Maybe<Order_By>;
  opening_soon_at?: Maybe<Order_By>;
  organized_by_context_id?: Maybe<Order_By>;
  organizer?: Maybe<Context_Order_By>;
  organizer_handle?: Maybe<Order_By>;
  organizer_profile_id?: Maybe<Order_By>;
  start_at?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uri?: Maybe<Order_By>;
  world_city?: Maybe<World_Cities_Order_By>;
  world_city_id?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** select columns of table "Announced_art_show" */
export enum Announced_Art_Show_Select_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AnnounceAt = 'announce_at',
  /** column name */
  ArtShowCollectionId = 'art_show_collection_id',
  /** column name */
  BannerMetadataId = 'banner_metadata_id',
  /** column name */
  ClosingSoonAt = 'closing_soon_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  GrandOpeningAt = 'grand_opening_at',
  /** column name */
  IsAnnounced = 'is_announced',
  /** column name */
  OpeningSoonAt = 'opening_soon_at',
  /** column name */
  OrganizedByContextId = 'organized_by_context_id',
  /** column name */
  OrganizerHandle = 'organizer_handle',
  /** column name */
  OrganizerProfileId = 'organizer_profile_id',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Statement = 'statement',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uri = 'uri',
  /** column name */
  WorldCityId = 'world_city_id',
  /** column name */
  ZipCode = 'zip_code'
}

/** aggregate stddev on columns */
export type Announced_Art_Show_Stddev_Fields = {
  __typename?: 'Announced_art_show_stddev_fields';
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  organizer_profile_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Announced_Art_Show_Stddev_Pop_Fields = {
  __typename?: 'Announced_art_show_stddev_pop_fields';
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  organizer_profile_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Announced_Art_Show_Stddev_Samp_Fields = {
  __typename?: 'Announced_art_show_stddev_samp_fields';
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  organizer_profile_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Announced_Art_Show_Sum_Fields = {
  __typename?: 'Announced_art_show_sum_fields';
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  organizer_profile_id?: Maybe<Scalars['bigint']>;
  world_city_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Announced_Art_Show_Var_Pop_Fields = {
  __typename?: 'Announced_art_show_var_pop_fields';
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  organizer_profile_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Announced_Art_Show_Var_Samp_Fields = {
  __typename?: 'Announced_art_show_var_samp_fields';
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  organizer_profile_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Announced_Art_Show_Variance_Fields = {
  __typename?: 'Announced_art_show_variance_fields';
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  organizer_profile_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "App_notifications" */
export type App_Notifications = {
  __typename?: 'App_notifications';
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  dismissed_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['bigint']>;
  group_type?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  message: Scalars['String'];
  read_at?: Maybe<Scalars['timestamptz']>;
  type: Enum_App_Notification_Type_Enum;
  updated_at: Scalars['timestamptz'];
  updated_message_at: Scalars['timestamptz'];
};

/** aggregated selection of "App_notifications" */
export type App_Notifications_Aggregate = {
  __typename?: 'App_notifications_aggregate';
  aggregate?: Maybe<App_Notifications_Aggregate_Fields>;
  nodes: Array<App_Notifications>;
};

/** aggregate fields of "App_notifications" */
export type App_Notifications_Aggregate_Fields = {
  __typename?: 'App_notifications_aggregate_fields';
  avg?: Maybe<App_Notifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<App_Notifications_Max_Fields>;
  min?: Maybe<App_Notifications_Min_Fields>;
  stddev?: Maybe<App_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<App_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<App_Notifications_Sum_Fields>;
  var_pop?: Maybe<App_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<App_Notifications_Var_Samp_Fields>;
  variance?: Maybe<App_Notifications_Variance_Fields>;
};


/** aggregate fields of "App_notifications" */
export type App_Notifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type App_Notifications_Avg_Fields = {
  __typename?: 'App_notifications_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "App_notifications". All fields are combined with a logical 'AND'. */
export type App_Notifications_Bool_Exp = {
  _and?: Maybe<Array<App_Notifications_Bool_Exp>>;
  _not?: Maybe<App_Notifications_Bool_Exp>;
  _or?: Maybe<Array<App_Notifications_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dismissed_at?: Maybe<Timestamptz_Comparison_Exp>;
  group_id?: Maybe<Bigint_Comparison_Exp>;
  group_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  read_at?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<Enum_App_Notification_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_message_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "App_notifications" */
export enum App_Notifications_Constraint {
  /** unique or primary key constraint on columns "type", "context_id" */
  AppNotificationsMultipleRecognitionsIdx = 'App_notifications_multiple_recognitions_idx',
  /** unique or primary key constraint on columns "id" */
  AppNotificationsPkey = 'App_notifications_pkey'
}

/** input type for incrementing numeric columns in table "App_notifications" */
export type App_Notifications_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "App_notifications" */
export type App_Notifications_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dismissed_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['bigint']>;
  group_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Enum_App_Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type App_Notifications_Max_Fields = {
  __typename?: 'App_notifications_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dismissed_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['bigint']>;
  group_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type App_Notifications_Min_Fields = {
  __typename?: 'App_notifications_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dismissed_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['bigint']>;
  group_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "App_notifications" */
export type App_Notifications_Mutation_Response = {
  __typename?: 'App_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Notifications>;
};

/** on_conflict condition type for table "App_notifications" */
export type App_Notifications_On_Conflict = {
  constraint: App_Notifications_Constraint;
  update_columns?: Array<App_Notifications_Update_Column>;
  where?: Maybe<App_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "App_notifications". */
export type App_Notifications_Order_By = {
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dismissed_at?: Maybe<Order_By>;
  group_id?: Maybe<Order_By>;
  group_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_message_at?: Maybe<Order_By>;
};

/** primary key columns input for table: App_notifications */
export type App_Notifications_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "App_notifications" */
export enum App_Notifications_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DismissedAt = 'dismissed_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  GroupType = 'group_type',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedMessageAt = 'updated_message_at'
}

/** input type for updating data in table "App_notifications" */
export type App_Notifications_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dismissed_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['bigint']>;
  group_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Enum_App_Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type App_Notifications_Stddev_Fields = {
  __typename?: 'App_notifications_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type App_Notifications_Stddev_Pop_Fields = {
  __typename?: 'App_notifications_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type App_Notifications_Stddev_Samp_Fields = {
  __typename?: 'App_notifications_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type App_Notifications_Sum_Fields = {
  __typename?: 'App_notifications_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "App_notifications" */
export enum App_Notifications_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DismissedAt = 'dismissed_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  GroupType = 'group_type',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedMessageAt = 'updated_message_at'
}

/** aggregate var_pop on columns */
export type App_Notifications_Var_Pop_Fields = {
  __typename?: 'App_notifications_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type App_Notifications_Var_Samp_Fields = {
  __typename?: 'App_notifications_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type App_Notifications_Variance_Fields = {
  __typename?: 'App_notifications_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Same as Interests table, but with some overrides specific for the Art History module of Art Universe app */
export type Art_History_Movement = {
  __typename?: 'Art_history_movement';
  active: Scalars['Boolean'];
  id: Scalars['Int'];
  image_url: Scalars['String'];
  order: Scalars['Int'];
  translation_key: Scalars['String'];
};

/** aggregated selection of "Art_history_movement" */
export type Art_History_Movement_Aggregate = {
  __typename?: 'Art_history_movement_aggregate';
  aggregate?: Maybe<Art_History_Movement_Aggregate_Fields>;
  nodes: Array<Art_History_Movement>;
};

/** aggregate fields of "Art_history_movement" */
export type Art_History_Movement_Aggregate_Fields = {
  __typename?: 'Art_history_movement_aggregate_fields';
  avg?: Maybe<Art_History_Movement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_History_Movement_Max_Fields>;
  min?: Maybe<Art_History_Movement_Min_Fields>;
  stddev?: Maybe<Art_History_Movement_Stddev_Fields>;
  stddev_pop?: Maybe<Art_History_Movement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_History_Movement_Stddev_Samp_Fields>;
  sum?: Maybe<Art_History_Movement_Sum_Fields>;
  var_pop?: Maybe<Art_History_Movement_Var_Pop_Fields>;
  var_samp?: Maybe<Art_History_Movement_Var_Samp_Fields>;
  variance?: Maybe<Art_History_Movement_Variance_Fields>;
};


/** aggregate fields of "Art_history_movement" */
export type Art_History_Movement_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_History_Movement_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Art_History_Movement_Avg_Fields = {
  __typename?: 'Art_history_movement_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Art_history_movement". All fields are combined with a logical 'AND'. */
export type Art_History_Movement_Bool_Exp = {
  _and?: Maybe<Array<Art_History_Movement_Bool_Exp>>;
  _not?: Maybe<Art_History_Movement_Bool_Exp>;
  _or?: Maybe<Array<Art_History_Movement_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  translation_key?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Art_history_movement" */
export enum Art_History_Movement_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtHistoryMovementPkey = 'Art_history_movement_pkey'
}

/** input type for incrementing numeric columns in table "Art_history_movement" */
export type Art_History_Movement_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Art_history_movement" */
export type Art_History_Movement_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Art_History_Movement_Max_Fields = {
  __typename?: 'Art_history_movement_max_fields';
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Art_History_Movement_Min_Fields = {
  __typename?: 'Art_history_movement_min_fields';
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Art_history_movement" */
export type Art_History_Movement_Mutation_Response = {
  __typename?: 'Art_history_movement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Art_History_Movement>;
};

/** on_conflict condition type for table "Art_history_movement" */
export type Art_History_Movement_On_Conflict = {
  constraint: Art_History_Movement_Constraint;
  update_columns?: Array<Art_History_Movement_Update_Column>;
  where?: Maybe<Art_History_Movement_Bool_Exp>;
};

/** Ordering options when selecting data from "Art_history_movement". */
export type Art_History_Movement_Order_By = {
  active?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  translation_key?: Maybe<Order_By>;
};

/** primary key columns input for table: Art_history_movement */
export type Art_History_Movement_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Art_history_movement" */
export enum Art_History_Movement_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translation_key'
}

/** input type for updating data in table "Art_history_movement" */
export type Art_History_Movement_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Art_History_Movement_Stddev_Fields = {
  __typename?: 'Art_history_movement_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Art_History_Movement_Stddev_Pop_Fields = {
  __typename?: 'Art_history_movement_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Art_History_Movement_Stddev_Samp_Fields = {
  __typename?: 'Art_history_movement_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Art_History_Movement_Sum_Fields = {
  __typename?: 'Art_history_movement_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "Art_history_movement" */
export enum Art_History_Movement_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translation_key'
}

/** aggregate var_pop on columns */
export type Art_History_Movement_Var_Pop_Fields = {
  __typename?: 'Art_history_movement_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Art_History_Movement_Var_Samp_Fields = {
  __typename?: 'Art_history_movement_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Art_History_Movement_Variance_Fields = {
  __typename?: 'Art_history_movement_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Winner artworks for  art prize show */
export type Art_Prize_Artwork = {
  __typename?: 'Art_prize_artwork';
  /** An object relationship */
  Art_prize_winner: Art_Prize_Winner;
  /** An object relationship */
  Artwork: Artwork;
  art_prize_show_id: Scalars['bigint'];
  art_prize_winner_id: Scalars['bigint'];
  artist_context_id: Scalars['bigint'];
  artwork_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  order: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Art_prize_artwork" */
export type Art_Prize_Artwork_Aggregate = {
  __typename?: 'Art_prize_artwork_aggregate';
  aggregate?: Maybe<Art_Prize_Artwork_Aggregate_Fields>;
  nodes: Array<Art_Prize_Artwork>;
};

/** aggregate fields of "Art_prize_artwork" */
export type Art_Prize_Artwork_Aggregate_Fields = {
  __typename?: 'Art_prize_artwork_aggregate_fields';
  avg?: Maybe<Art_Prize_Artwork_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_Prize_Artwork_Max_Fields>;
  min?: Maybe<Art_Prize_Artwork_Min_Fields>;
  stddev?: Maybe<Art_Prize_Artwork_Stddev_Fields>;
  stddev_pop?: Maybe<Art_Prize_Artwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_Prize_Artwork_Stddev_Samp_Fields>;
  sum?: Maybe<Art_Prize_Artwork_Sum_Fields>;
  var_pop?: Maybe<Art_Prize_Artwork_Var_Pop_Fields>;
  var_samp?: Maybe<Art_Prize_Artwork_Var_Samp_Fields>;
  variance?: Maybe<Art_Prize_Artwork_Variance_Fields>;
};


/** aggregate fields of "Art_prize_artwork" */
export type Art_Prize_Artwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Aggregate_Order_By = {
  avg?: Maybe<Art_Prize_Artwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Art_Prize_Artwork_Max_Order_By>;
  min?: Maybe<Art_Prize_Artwork_Min_Order_By>;
  stddev?: Maybe<Art_Prize_Artwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Art_Prize_Artwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Art_Prize_Artwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Art_Prize_Artwork_Sum_Order_By>;
  var_pop?: Maybe<Art_Prize_Artwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Art_Prize_Artwork_Var_Samp_Order_By>;
  variance?: Maybe<Art_Prize_Artwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Art_prize_artwork" */
export type Art_Prize_Artwork_Arr_Rel_Insert_Input = {
  data: Array<Art_Prize_Artwork_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Art_Prize_Artwork_On_Conflict>;
};

/** aggregate avg on columns */
export type Art_Prize_Artwork_Avg_Fields = {
  __typename?: 'Art_prize_artwork_avg_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Avg_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Art_prize_artwork". All fields are combined with a logical 'AND'. */
export type Art_Prize_Artwork_Bool_Exp = {
  Art_prize_winner?: Maybe<Art_Prize_Winner_Bool_Exp>;
  Artwork?: Maybe<Artwork_Bool_Exp>;
  _and?: Maybe<Array<Art_Prize_Artwork_Bool_Exp>>;
  _not?: Maybe<Art_Prize_Artwork_Bool_Exp>;
  _or?: Maybe<Array<Art_Prize_Artwork_Bool_Exp>>;
  art_prize_show_id?: Maybe<Bigint_Comparison_Exp>;
  art_prize_winner_id?: Maybe<Bigint_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Art_prize_artwork" */
export enum Art_Prize_Artwork_Constraint {
  /** unique or primary key constraint on columns "art_prize_winner_id", "artwork_id" */
  ArtPrizeArtworkArtPrizeWinnerIdArtworkIdKey = 'Art_prize_artwork_art_prize_winner_id_artwork_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtPrizeArtworkPkey = 'Art_prize_artwork_pkey'
}

/** input type for incrementing numeric columns in table "Art_prize_artwork" */
export type Art_Prize_Artwork_Inc_Input = {
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Art_prize_artwork" */
export type Art_Prize_Artwork_Insert_Input = {
  Art_prize_winner?: Maybe<Art_Prize_Winner_Obj_Rel_Insert_Input>;
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Art_Prize_Artwork_Max_Fields = {
  __typename?: 'Art_prize_artwork_max_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Max_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Art_Prize_Artwork_Min_Fields = {
  __typename?: 'Art_prize_artwork_min_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Min_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Art_prize_artwork" */
export type Art_Prize_Artwork_Mutation_Response = {
  __typename?: 'Art_prize_artwork_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Art_Prize_Artwork>;
};

/** on_conflict condition type for table "Art_prize_artwork" */
export type Art_Prize_Artwork_On_Conflict = {
  constraint: Art_Prize_Artwork_Constraint;
  update_columns?: Array<Art_Prize_Artwork_Update_Column>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};

/** Ordering options when selecting data from "Art_prize_artwork". */
export type Art_Prize_Artwork_Order_By = {
  Art_prize_winner?: Maybe<Art_Prize_Winner_Order_By>;
  Artwork?: Maybe<Artwork_Order_By>;
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Art_prize_artwork */
export type Art_Prize_Artwork_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Art_prize_artwork" */
export enum Art_Prize_Artwork_Select_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtPrizeWinnerId = 'art_prize_winner_id',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Art_prize_artwork" */
export type Art_Prize_Artwork_Set_Input = {
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Art_Prize_Artwork_Stddev_Fields = {
  __typename?: 'Art_prize_artwork_stddev_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Stddev_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Art_Prize_Artwork_Stddev_Pop_Fields = {
  __typename?: 'Art_prize_artwork_stddev_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Stddev_Pop_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Art_Prize_Artwork_Stddev_Samp_Fields = {
  __typename?: 'Art_prize_artwork_stddev_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Stddev_Samp_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Art_Prize_Artwork_Sum_Fields = {
  __typename?: 'Art_prize_artwork_sum_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Sum_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "Art_prize_artwork" */
export enum Art_Prize_Artwork_Update_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtPrizeWinnerId = 'art_prize_winner_id',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Art_Prize_Artwork_Var_Pop_Fields = {
  __typename?: 'Art_prize_artwork_var_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Var_Pop_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Art_Prize_Artwork_Var_Samp_Fields = {
  __typename?: 'Art_prize_artwork_var_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Var_Samp_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Art_Prize_Artwork_Variance_Fields = {
  __typename?: 'Art_prize_artwork_variance_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Art_prize_artwork" */
export type Art_Prize_Artwork_Variance_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Contains the art prize show header information */
export type Art_Prize_Show = {
  __typename?: 'Art_prize_show';
  /** An array relationship */
  Art_prize_winners: Array<Art_Prize_Winner>;
  /** An aggregate relationship */
  Art_prize_winners_aggregate: Art_Prize_Winner_Aggregate;
  amount_participants?: Maybe<Scalars['Int']>;
  /** An object relationship */
  background_metadata?: Maybe<File_Metadata>;
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['bigint'];
  prize_show_slug: Scalars['String'];
  published_at?: Maybe<Scalars['timestamptz']>;
  short_description?: Maybe<Scalars['String']>;
  social_media_url?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  /** An object relationship */
  thumbnail_metadata?: Maybe<File_Metadata>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Contains the art prize show header information */
export type Art_Prize_ShowArt_Prize_WinnersArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Bool_Exp>;
};


/** Contains the art prize show header information */
export type Art_Prize_ShowArt_Prize_Winners_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Bool_Exp>;
};

/** aggregated selection of "Art_prize_show" */
export type Art_Prize_Show_Aggregate = {
  __typename?: 'Art_prize_show_aggregate';
  aggregate?: Maybe<Art_Prize_Show_Aggregate_Fields>;
  nodes: Array<Art_Prize_Show>;
};

/** aggregate fields of "Art_prize_show" */
export type Art_Prize_Show_Aggregate_Fields = {
  __typename?: 'Art_prize_show_aggregate_fields';
  avg?: Maybe<Art_Prize_Show_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_Prize_Show_Max_Fields>;
  min?: Maybe<Art_Prize_Show_Min_Fields>;
  stddev?: Maybe<Art_Prize_Show_Stddev_Fields>;
  stddev_pop?: Maybe<Art_Prize_Show_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_Prize_Show_Stddev_Samp_Fields>;
  sum?: Maybe<Art_Prize_Show_Sum_Fields>;
  var_pop?: Maybe<Art_Prize_Show_Var_Pop_Fields>;
  var_samp?: Maybe<Art_Prize_Show_Var_Samp_Fields>;
  variance?: Maybe<Art_Prize_Show_Variance_Fields>;
};


/** aggregate fields of "Art_prize_show" */
export type Art_Prize_Show_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_Prize_Show_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Art_Prize_Show_Avg_Fields = {
  __typename?: 'Art_prize_show_avg_fields';
  amount_participants?: Maybe<Scalars['Float']>;
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Art_prize_show". All fields are combined with a logical 'AND'. */
export type Art_Prize_Show_Bool_Exp = {
  Art_prize_winners?: Maybe<Art_Prize_Winner_Bool_Exp>;
  _and?: Maybe<Array<Art_Prize_Show_Bool_Exp>>;
  _not?: Maybe<Art_Prize_Show_Bool_Exp>;
  _or?: Maybe<Array<Art_Prize_Show_Bool_Exp>>;
  amount_participants?: Maybe<Int_Comparison_Exp>;
  background_metadata?: Maybe<File_Metadata_Bool_Exp>;
  background_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  prize_show_slug?: Maybe<String_Comparison_Exp>;
  published_at?: Maybe<Timestamptz_Comparison_Exp>;
  short_description?: Maybe<String_Comparison_Exp>;
  social_media_url?: Maybe<String_Comparison_Exp>;
  subtitle?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata?: Maybe<File_Metadata_Bool_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Art_prize_show" */
export enum Art_Prize_Show_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtPrizeShowPkey = 'Art_prize_show_pkey',
  /** unique or primary key constraint on columns "prize_show_slug" */
  ArtPrizeShowPrizeShowSlugKey = 'Art_prize_show_prize_show_slug_key'
}

/** input type for incrementing numeric columns in table "Art_prize_show" */
export type Art_Prize_Show_Inc_Input = {
  amount_participants?: Maybe<Scalars['Int']>;
  background_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Art_prize_show" */
export type Art_Prize_Show_Insert_Input = {
  Art_prize_winners?: Maybe<Art_Prize_Winner_Arr_Rel_Insert_Input>;
  amount_participants?: Maybe<Scalars['Int']>;
  background_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  prize_show_slug?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  short_description?: Maybe<Scalars['String']>;
  social_media_url?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  thumbnail_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Art_Prize_Show_Max_Fields = {
  __typename?: 'Art_prize_show_max_fields';
  amount_participants?: Maybe<Scalars['Int']>;
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  prize_show_slug?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  short_description?: Maybe<Scalars['String']>;
  social_media_url?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Art_Prize_Show_Min_Fields = {
  __typename?: 'Art_prize_show_min_fields';
  amount_participants?: Maybe<Scalars['Int']>;
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  prize_show_slug?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  short_description?: Maybe<Scalars['String']>;
  social_media_url?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Art_prize_show" */
export type Art_Prize_Show_Mutation_Response = {
  __typename?: 'Art_prize_show_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Art_Prize_Show>;
};

/** input type for inserting object relation for remote table "Art_prize_show" */
export type Art_Prize_Show_Obj_Rel_Insert_Input = {
  data: Art_Prize_Show_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Art_Prize_Show_On_Conflict>;
};

/** on_conflict condition type for table "Art_prize_show" */
export type Art_Prize_Show_On_Conflict = {
  constraint: Art_Prize_Show_Constraint;
  update_columns?: Array<Art_Prize_Show_Update_Column>;
  where?: Maybe<Art_Prize_Show_Bool_Exp>;
};

/** Ordering options when selecting data from "Art_prize_show". */
export type Art_Prize_Show_Order_By = {
  Art_prize_winners_aggregate?: Maybe<Art_Prize_Winner_Aggregate_Order_By>;
  amount_participants?: Maybe<Order_By>;
  background_metadata?: Maybe<File_Metadata_Order_By>;
  background_metadata_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  prize_show_slug?: Maybe<Order_By>;
  published_at?: Maybe<Order_By>;
  short_description?: Maybe<Order_By>;
  social_media_url?: Maybe<Order_By>;
  subtitle?: Maybe<Order_By>;
  thumbnail_metadata?: Maybe<File_Metadata_Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Art_prize_show */
export type Art_Prize_Show_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Art_prize_show" */
export enum Art_Prize_Show_Select_Column {
  /** column name */
  AmountParticipants = 'amount_participants',
  /** column name */
  BackgroundMetadataId = 'background_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PrizeShowSlug = 'prize_show_slug',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  SocialMediaUrl = 'social_media_url',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Art_prize_show" */
export type Art_Prize_Show_Set_Input = {
  amount_participants?: Maybe<Scalars['Int']>;
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  prize_show_slug?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  short_description?: Maybe<Scalars['String']>;
  social_media_url?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Art_Prize_Show_Stddev_Fields = {
  __typename?: 'Art_prize_show_stddev_fields';
  amount_participants?: Maybe<Scalars['Float']>;
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Art_Prize_Show_Stddev_Pop_Fields = {
  __typename?: 'Art_prize_show_stddev_pop_fields';
  amount_participants?: Maybe<Scalars['Float']>;
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Art_Prize_Show_Stddev_Samp_Fields = {
  __typename?: 'Art_prize_show_stddev_samp_fields';
  amount_participants?: Maybe<Scalars['Float']>;
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Art_Prize_Show_Sum_Fields = {
  __typename?: 'Art_prize_show_sum_fields';
  amount_participants?: Maybe<Scalars['Int']>;
  background_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Art_prize_show" */
export enum Art_Prize_Show_Update_Column {
  /** column name */
  AmountParticipants = 'amount_participants',
  /** column name */
  BackgroundMetadataId = 'background_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  PrizeShowSlug = 'prize_show_slug',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  SocialMediaUrl = 'social_media_url',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Art_Prize_Show_Var_Pop_Fields = {
  __typename?: 'Art_prize_show_var_pop_fields';
  amount_participants?: Maybe<Scalars['Float']>;
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Art_Prize_Show_Var_Samp_Fields = {
  __typename?: 'Art_prize_show_var_samp_fields';
  amount_participants?: Maybe<Scalars['Float']>;
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Art_Prize_Show_Variance_Fields = {
  __typename?: 'Art_prize_show_variance_fields';
  amount_participants?: Maybe<Scalars['Float']>;
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** Empty table structure to work with getArtPrizeShowWinners function */
export type Art_Prize_Show_Winners = {
  __typename?: 'Art_prize_show_winners';
  art_prize_show_id: Scalars['bigint'];
  art_prize_winner_id: Scalars['bigint'];
  art_prize_winner_order: Scalars['Int'];
  artist_context_id: Scalars['bigint'];
  artwork_id: Scalars['bigint'];
  artwork_slug: Scalars['String'];
  artwork_thumbnail_metadata_id: Scalars['bigint'];
  avatar: Scalars['bigint'];
  city: Scalars['String'];
  country: Scalars['String'];
  first_name: Scalars['String'];
  handle: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  position_type: Enum_Position_Type_Enum;
  profile_id: Scalars['bigint'];
  quote: Scalars['String'];
  title: Scalars['String'];
};

/** aggregated selection of "Art_prize_show_winners" */
export type Art_Prize_Show_Winners_Aggregate = {
  __typename?: 'Art_prize_show_winners_aggregate';
  aggregate?: Maybe<Art_Prize_Show_Winners_Aggregate_Fields>;
  nodes: Array<Art_Prize_Show_Winners>;
};

/** aggregate fields of "Art_prize_show_winners" */
export type Art_Prize_Show_Winners_Aggregate_Fields = {
  __typename?: 'Art_prize_show_winners_aggregate_fields';
  avg?: Maybe<Art_Prize_Show_Winners_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_Prize_Show_Winners_Max_Fields>;
  min?: Maybe<Art_Prize_Show_Winners_Min_Fields>;
  stddev?: Maybe<Art_Prize_Show_Winners_Stddev_Fields>;
  stddev_pop?: Maybe<Art_Prize_Show_Winners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_Prize_Show_Winners_Stddev_Samp_Fields>;
  sum?: Maybe<Art_Prize_Show_Winners_Sum_Fields>;
  var_pop?: Maybe<Art_Prize_Show_Winners_Var_Pop_Fields>;
  var_samp?: Maybe<Art_Prize_Show_Winners_Var_Samp_Fields>;
  variance?: Maybe<Art_Prize_Show_Winners_Variance_Fields>;
};


/** aggregate fields of "Art_prize_show_winners" */
export type Art_Prize_Show_Winners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_Prize_Show_Winners_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Art_Prize_Show_Winners_Avg_Fields = {
  __typename?: 'Art_prize_show_winners_avg_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_order?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Art_prize_show_winners". All fields are combined with a logical 'AND'. */
export type Art_Prize_Show_Winners_Bool_Exp = {
  _and?: Maybe<Array<Art_Prize_Show_Winners_Bool_Exp>>;
  _not?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
  _or?: Maybe<Array<Art_Prize_Show_Winners_Bool_Exp>>;
  art_prize_show_id?: Maybe<Bigint_Comparison_Exp>;
  art_prize_winner_id?: Maybe<Bigint_Comparison_Exp>;
  art_prize_winner_order?: Maybe<Int_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_slug?: Maybe<String_Comparison_Exp>;
  artwork_thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  position_type?: Maybe<Enum_Position_Type_Enum_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  quote?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Art_prize_show_winners" */
export enum Art_Prize_Show_Winners_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtPrizeShowWinnersPkey = 'Art_prize_show_winners_pkey'
}

/** input type for incrementing numeric columns in table "Art_prize_show_winners" */
export type Art_Prize_Show_Winners_Inc_Input = {
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_order?: Maybe<Scalars['Int']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Art_prize_show_winners" */
export type Art_Prize_Show_Winners_Insert_Input = {
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_order?: Maybe<Scalars['Int']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_slug?: Maybe<Scalars['String']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  position_type?: Maybe<Enum_Position_Type_Enum>;
  profile_id?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Art_Prize_Show_Winners_Max_Fields = {
  __typename?: 'Art_prize_show_winners_max_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_order?: Maybe<Scalars['Int']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_slug?: Maybe<Scalars['String']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Art_Prize_Show_Winners_Min_Fields = {
  __typename?: 'Art_prize_show_winners_min_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_order?: Maybe<Scalars['Int']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_slug?: Maybe<Scalars['String']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Art_prize_show_winners" */
export type Art_Prize_Show_Winners_Mutation_Response = {
  __typename?: 'Art_prize_show_winners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Art_Prize_Show_Winners>;
};

/** on_conflict condition type for table "Art_prize_show_winners" */
export type Art_Prize_Show_Winners_On_Conflict = {
  constraint: Art_Prize_Show_Winners_Constraint;
  update_columns?: Array<Art_Prize_Show_Winners_Update_Column>;
  where?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
};

/** Ordering options when selecting data from "Art_prize_show_winners". */
export type Art_Prize_Show_Winners_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  art_prize_winner_order?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_slug?: Maybe<Order_By>;
  artwork_thumbnail_metadata_id?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  position_type?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  quote?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: Art_prize_show_winners */
export type Art_Prize_Show_Winners_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Art_prize_show_winners" */
export enum Art_Prize_Show_Winners_Select_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtPrizeWinnerId = 'art_prize_winner_id',
  /** column name */
  ArtPrizeWinnerOrder = 'art_prize_winner_order',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkSlug = 'artwork_slug',
  /** column name */
  ArtworkThumbnailMetadataId = 'artwork_thumbnail_metadata_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PositionType = 'position_type',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Quote = 'quote',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "Art_prize_show_winners" */
export type Art_Prize_Show_Winners_Set_Input = {
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_order?: Maybe<Scalars['Int']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_slug?: Maybe<Scalars['String']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  position_type?: Maybe<Enum_Position_Type_Enum>;
  profile_id?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Art_Prize_Show_Winners_Stddev_Fields = {
  __typename?: 'Art_prize_show_winners_stddev_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_order?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Art_Prize_Show_Winners_Stddev_Pop_Fields = {
  __typename?: 'Art_prize_show_winners_stddev_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_order?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Art_Prize_Show_Winners_Stddev_Samp_Fields = {
  __typename?: 'Art_prize_show_winners_stddev_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_order?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Art_Prize_Show_Winners_Sum_Fields = {
  __typename?: 'Art_prize_show_winners_sum_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_order?: Maybe<Scalars['Int']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Art_prize_show_winners" */
export enum Art_Prize_Show_Winners_Update_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtPrizeWinnerId = 'art_prize_winner_id',
  /** column name */
  ArtPrizeWinnerOrder = 'art_prize_winner_order',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkSlug = 'artwork_slug',
  /** column name */
  ArtworkThumbnailMetadataId = 'artwork_thumbnail_metadata_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PositionType = 'position_type',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Quote = 'quote',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Art_Prize_Show_Winners_Var_Pop_Fields = {
  __typename?: 'Art_prize_show_winners_var_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_order?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Art_Prize_Show_Winners_Var_Samp_Fields = {
  __typename?: 'Art_prize_show_winners_var_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_order?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Art_Prize_Show_Winners_Variance_Fields = {
  __typename?: 'Art_prize_show_winners_variance_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_order?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** Winners for the art prize show */
export type Art_Prize_Winner = {
  __typename?: 'Art_prize_winner';
  /** An array relationship */
  Art_prize_artworks: Array<Art_Prize_Artwork>;
  /** An aggregate relationship */
  Art_prize_artworks_aggregate: Art_Prize_Artwork_Aggregate;
  /** An object relationship */
  Art_prize_show: Art_Prize_Show;
  /** An object relationship */
  Art_prize_winner_Context?: Maybe<Context>;
  /** An object relationship */
  ENUM_position_type: Enum_Position_Type;
  art_prize_show_id: Scalars['bigint'];
  artist_context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  order: Scalars['Int'];
  position_type: Enum_Position_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/** Winners for the art prize show */
export type Art_Prize_WinnerArt_Prize_ArtworksArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};


/** Winners for the art prize show */
export type Art_Prize_WinnerArt_Prize_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};

/** aggregated selection of "Art_prize_winner" */
export type Art_Prize_Winner_Aggregate = {
  __typename?: 'Art_prize_winner_aggregate';
  aggregate?: Maybe<Art_Prize_Winner_Aggregate_Fields>;
  nodes: Array<Art_Prize_Winner>;
};

/** aggregate fields of "Art_prize_winner" */
export type Art_Prize_Winner_Aggregate_Fields = {
  __typename?: 'Art_prize_winner_aggregate_fields';
  avg?: Maybe<Art_Prize_Winner_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_Prize_Winner_Max_Fields>;
  min?: Maybe<Art_Prize_Winner_Min_Fields>;
  stddev?: Maybe<Art_Prize_Winner_Stddev_Fields>;
  stddev_pop?: Maybe<Art_Prize_Winner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_Prize_Winner_Stddev_Samp_Fields>;
  sum?: Maybe<Art_Prize_Winner_Sum_Fields>;
  var_pop?: Maybe<Art_Prize_Winner_Var_Pop_Fields>;
  var_samp?: Maybe<Art_Prize_Winner_Var_Samp_Fields>;
  variance?: Maybe<Art_Prize_Winner_Variance_Fields>;
};


/** aggregate fields of "Art_prize_winner" */
export type Art_Prize_Winner_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_Prize_Winner_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Art_prize_winner" */
export type Art_Prize_Winner_Aggregate_Order_By = {
  avg?: Maybe<Art_Prize_Winner_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Art_Prize_Winner_Max_Order_By>;
  min?: Maybe<Art_Prize_Winner_Min_Order_By>;
  stddev?: Maybe<Art_Prize_Winner_Stddev_Order_By>;
  stddev_pop?: Maybe<Art_Prize_Winner_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Art_Prize_Winner_Stddev_Samp_Order_By>;
  sum?: Maybe<Art_Prize_Winner_Sum_Order_By>;
  var_pop?: Maybe<Art_Prize_Winner_Var_Pop_Order_By>;
  var_samp?: Maybe<Art_Prize_Winner_Var_Samp_Order_By>;
  variance?: Maybe<Art_Prize_Winner_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Art_prize_winner" */
export type Art_Prize_Winner_Arr_Rel_Insert_Input = {
  data: Array<Art_Prize_Winner_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Art_Prize_Winner_On_Conflict>;
};

/** aggregate avg on columns */
export type Art_Prize_Winner_Avg_Fields = {
  __typename?: 'Art_prize_winner_avg_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Avg_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Art_prize_winner". All fields are combined with a logical 'AND'. */
export type Art_Prize_Winner_Bool_Exp = {
  Art_prize_artworks?: Maybe<Art_Prize_Artwork_Bool_Exp>;
  Art_prize_show?: Maybe<Art_Prize_Show_Bool_Exp>;
  Art_prize_winner_Context?: Maybe<Context_Bool_Exp>;
  ENUM_position_type?: Maybe<Enum_Position_Type_Bool_Exp>;
  _and?: Maybe<Array<Art_Prize_Winner_Bool_Exp>>;
  _not?: Maybe<Art_Prize_Winner_Bool_Exp>;
  _or?: Maybe<Array<Art_Prize_Winner_Bool_Exp>>;
  art_prize_show_id?: Maybe<Bigint_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  position_type?: Maybe<Enum_Position_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Art_prize_winner" */
export enum Art_Prize_Winner_Constraint {
  /** unique or primary key constraint on columns "artist_context_id", "art_prize_show_id" */
  ArtPrizeWinnerArtPrizeShowIdArtistContextIdKey = 'Art_prize_winner_art_prize_show_id_artist_context_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtPrizeWinnerPkey = 'Art_prize_winner_pkey'
}

/** input type for incrementing numeric columns in table "Art_prize_winner" */
export type Art_Prize_Winner_Inc_Input = {
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Art_prize_winner" */
export type Art_Prize_Winner_Insert_Input = {
  Art_prize_artworks?: Maybe<Art_Prize_Artwork_Arr_Rel_Insert_Input>;
  Art_prize_show?: Maybe<Art_Prize_Show_Obj_Rel_Insert_Input>;
  Art_prize_winner_Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  ENUM_position_type?: Maybe<Enum_Position_Type_Obj_Rel_Insert_Input>;
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  position_type?: Maybe<Enum_Position_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Art_Prize_Winner_Max_Fields = {
  __typename?: 'Art_prize_winner_max_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Max_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Art_Prize_Winner_Min_Fields = {
  __typename?: 'Art_prize_winner_min_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Min_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Art_prize_winner" */
export type Art_Prize_Winner_Mutation_Response = {
  __typename?: 'Art_prize_winner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Art_Prize_Winner>;
};

/** input type for inserting object relation for remote table "Art_prize_winner" */
export type Art_Prize_Winner_Obj_Rel_Insert_Input = {
  data: Art_Prize_Winner_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Art_Prize_Winner_On_Conflict>;
};

/** on_conflict condition type for table "Art_prize_winner" */
export type Art_Prize_Winner_On_Conflict = {
  constraint: Art_Prize_Winner_Constraint;
  update_columns?: Array<Art_Prize_Winner_Update_Column>;
  where?: Maybe<Art_Prize_Winner_Bool_Exp>;
};

/** Ordering options when selecting data from "Art_prize_winner". */
export type Art_Prize_Winner_Order_By = {
  Art_prize_artworks_aggregate?: Maybe<Art_Prize_Artwork_Aggregate_Order_By>;
  Art_prize_show?: Maybe<Art_Prize_Show_Order_By>;
  Art_prize_winner_Context?: Maybe<Context_Order_By>;
  ENUM_position_type?: Maybe<Enum_Position_Type_Order_By>;
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  position_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Art_prize_winner */
export type Art_Prize_Winner_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "Art_prize_winner_ranking" */
export type Art_Prize_Winner_Ranking = {
  __typename?: 'Art_prize_winner_ranking';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_rank?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "Art_prize_winner_ranking" */
export type Art_Prize_Winner_Ranking_Aggregate = {
  __typename?: 'Art_prize_winner_ranking_aggregate';
  aggregate?: Maybe<Art_Prize_Winner_Ranking_Aggregate_Fields>;
  nodes: Array<Art_Prize_Winner_Ranking>;
};

/** aggregate fields of "Art_prize_winner_ranking" */
export type Art_Prize_Winner_Ranking_Aggregate_Fields = {
  __typename?: 'Art_prize_winner_ranking_aggregate_fields';
  avg?: Maybe<Art_Prize_Winner_Ranking_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_Prize_Winner_Ranking_Max_Fields>;
  min?: Maybe<Art_Prize_Winner_Ranking_Min_Fields>;
  stddev?: Maybe<Art_Prize_Winner_Ranking_Stddev_Fields>;
  stddev_pop?: Maybe<Art_Prize_Winner_Ranking_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_Prize_Winner_Ranking_Stddev_Samp_Fields>;
  sum?: Maybe<Art_Prize_Winner_Ranking_Sum_Fields>;
  var_pop?: Maybe<Art_Prize_Winner_Ranking_Var_Pop_Fields>;
  var_samp?: Maybe<Art_Prize_Winner_Ranking_Var_Samp_Fields>;
  variance?: Maybe<Art_Prize_Winner_Ranking_Variance_Fields>;
};


/** aggregate fields of "Art_prize_winner_ranking" */
export type Art_Prize_Winner_Ranking_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_Prize_Winner_Ranking_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Art_Prize_Winner_Ranking_Avg_Fields = {
  __typename?: 'Art_prize_winner_ranking_avg_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_rank?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Art_prize_winner_ranking". All fields are combined with a logical 'AND'. */
export type Art_Prize_Winner_Ranking_Bool_Exp = {
  _and?: Maybe<Array<Art_Prize_Winner_Ranking_Bool_Exp>>;
  _not?: Maybe<Art_Prize_Winner_Ranking_Bool_Exp>;
  _or?: Maybe<Array<Art_Prize_Winner_Ranking_Bool_Exp>>;
  art_prize_show_id?: Maybe<Bigint_Comparison_Exp>;
  art_prize_winner_id?: Maybe<Bigint_Comparison_Exp>;
  art_prize_winner_rank?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Art_Prize_Winner_Ranking_Max_Fields = {
  __typename?: 'Art_prize_winner_ranking_max_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_rank?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Art_Prize_Winner_Ranking_Min_Fields = {
  __typename?: 'Art_prize_winner_ranking_min_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_rank?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "Art_prize_winner_ranking". */
export type Art_Prize_Winner_Ranking_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  art_prize_winner_id?: Maybe<Order_By>;
  art_prize_winner_rank?: Maybe<Order_By>;
};

/** select columns of table "Art_prize_winner_ranking" */
export enum Art_Prize_Winner_Ranking_Select_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtPrizeWinnerId = 'art_prize_winner_id',
  /** column name */
  ArtPrizeWinnerRank = 'art_prize_winner_rank'
}

/** aggregate stddev on columns */
export type Art_Prize_Winner_Ranking_Stddev_Fields = {
  __typename?: 'Art_prize_winner_ranking_stddev_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Art_Prize_Winner_Ranking_Stddev_Pop_Fields = {
  __typename?: 'Art_prize_winner_ranking_stddev_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Art_Prize_Winner_Ranking_Stddev_Samp_Fields = {
  __typename?: 'Art_prize_winner_ranking_stddev_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_rank?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Art_Prize_Winner_Ranking_Sum_Fields = {
  __typename?: 'Art_prize_winner_ranking_sum_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_id?: Maybe<Scalars['bigint']>;
  art_prize_winner_rank?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Art_Prize_Winner_Ranking_Var_Pop_Fields = {
  __typename?: 'Art_prize_winner_ranking_var_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_rank?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Art_Prize_Winner_Ranking_Var_Samp_Fields = {
  __typename?: 'Art_prize_winner_ranking_var_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_rank?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Art_Prize_Winner_Ranking_Variance_Fields = {
  __typename?: 'Art_prize_winner_ranking_variance_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  art_prize_winner_id?: Maybe<Scalars['Float']>;
  art_prize_winner_rank?: Maybe<Scalars['Float']>;
};

/** select columns of table "Art_prize_winner" */
export enum Art_Prize_Winner_Select_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  PositionType = 'position_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Art_prize_winner" */
export type Art_Prize_Winner_Set_Input = {
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  position_type?: Maybe<Enum_Position_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Art_Prize_Winner_Stddev_Fields = {
  __typename?: 'Art_prize_winner_stddev_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Stddev_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Art_Prize_Winner_Stddev_Pop_Fields = {
  __typename?: 'Art_prize_winner_stddev_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Stddev_Pop_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Art_Prize_Winner_Stddev_Samp_Fields = {
  __typename?: 'Art_prize_winner_stddev_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Stddev_Samp_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Art_Prize_Winner_Sum_Fields = {
  __typename?: 'Art_prize_winner_sum_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Sum_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "Art_prize_winner" */
export enum Art_Prize_Winner_Update_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  PositionType = 'position_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Art_Prize_Winner_Var_Pop_Fields = {
  __typename?: 'Art_prize_winner_var_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Var_Pop_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Art_Prize_Winner_Var_Samp_Fields = {
  __typename?: 'Art_prize_winner_var_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Var_Samp_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Art_Prize_Winner_Variance_Fields = {
  __typename?: 'Art_prize_winner_variance_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Art_prize_winner" */
export type Art_Prize_Winner_Variance_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** columns and relationships of "Art_prizes_links_filtered" */
export type Art_Prizes_Links_Filtered = {
  __typename?: 'Art_prizes_links_filtered';
  id?: Maybe<Scalars['bigint']>;
  prize_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Art_prizes_links_filtered" */
export type Art_Prizes_Links_Filtered_Aggregate = {
  __typename?: 'Art_prizes_links_filtered_aggregate';
  aggregate?: Maybe<Art_Prizes_Links_Filtered_Aggregate_Fields>;
  nodes: Array<Art_Prizes_Links_Filtered>;
};

/** aggregate fields of "Art_prizes_links_filtered" */
export type Art_Prizes_Links_Filtered_Aggregate_Fields = {
  __typename?: 'Art_prizes_links_filtered_aggregate_fields';
  avg?: Maybe<Art_Prizes_Links_Filtered_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_Prizes_Links_Filtered_Max_Fields>;
  min?: Maybe<Art_Prizes_Links_Filtered_Min_Fields>;
  stddev?: Maybe<Art_Prizes_Links_Filtered_Stddev_Fields>;
  stddev_pop?: Maybe<Art_Prizes_Links_Filtered_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_Prizes_Links_Filtered_Stddev_Samp_Fields>;
  sum?: Maybe<Art_Prizes_Links_Filtered_Sum_Fields>;
  var_pop?: Maybe<Art_Prizes_Links_Filtered_Var_Pop_Fields>;
  var_samp?: Maybe<Art_Prizes_Links_Filtered_Var_Samp_Fields>;
  variance?: Maybe<Art_Prizes_Links_Filtered_Variance_Fields>;
};


/** aggregate fields of "Art_prizes_links_filtered" */
export type Art_Prizes_Links_Filtered_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_Prizes_Links_Filtered_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Art_Prizes_Links_Filtered_Avg_Fields = {
  __typename?: 'Art_prizes_links_filtered_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Art_prizes_links_filtered". All fields are combined with a logical 'AND'. */
export type Art_Prizes_Links_Filtered_Bool_Exp = {
  _and?: Maybe<Array<Art_Prizes_Links_Filtered_Bool_Exp>>;
  _not?: Maybe<Art_Prizes_Links_Filtered_Bool_Exp>;
  _or?: Maybe<Array<Art_Prizes_Links_Filtered_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  prize_url?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "Art_prizes_links_filtered" */
export type Art_Prizes_Links_Filtered_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Art_prizes_links_filtered" */
export type Art_Prizes_Links_Filtered_Insert_Input = {
  id?: Maybe<Scalars['bigint']>;
  prize_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Art_Prizes_Links_Filtered_Max_Fields = {
  __typename?: 'Art_prizes_links_filtered_max_fields';
  id?: Maybe<Scalars['bigint']>;
  prize_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Art_Prizes_Links_Filtered_Min_Fields = {
  __typename?: 'Art_prizes_links_filtered_min_fields';
  id?: Maybe<Scalars['bigint']>;
  prize_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Art_prizes_links_filtered" */
export type Art_Prizes_Links_Filtered_Mutation_Response = {
  __typename?: 'Art_prizes_links_filtered_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Art_Prizes_Links_Filtered>;
};

/** Ordering options when selecting data from "Art_prizes_links_filtered". */
export type Art_Prizes_Links_Filtered_Order_By = {
  id?: Maybe<Order_By>;
  prize_url?: Maybe<Order_By>;
};

/** select columns of table "Art_prizes_links_filtered" */
export enum Art_Prizes_Links_Filtered_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PrizeUrl = 'prize_url'
}

/** input type for updating data in table "Art_prizes_links_filtered" */
export type Art_Prizes_Links_Filtered_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  prize_url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Art_Prizes_Links_Filtered_Stddev_Fields = {
  __typename?: 'Art_prizes_links_filtered_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Art_Prizes_Links_Filtered_Stddev_Pop_Fields = {
  __typename?: 'Art_prizes_links_filtered_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Art_Prizes_Links_Filtered_Stddev_Samp_Fields = {
  __typename?: 'Art_prizes_links_filtered_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Art_Prizes_Links_Filtered_Sum_Fields = {
  __typename?: 'Art_prizes_links_filtered_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Art_Prizes_Links_Filtered_Var_Pop_Fields = {
  __typename?: 'Art_prizes_links_filtered_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Art_Prizes_Links_Filtered_Var_Samp_Fields = {
  __typename?: 'Art_prizes_links_filtered_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Art_Prizes_Links_Filtered_Variance_Fields = {
  __typename?: 'Art_prizes_links_filtered_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Art_show_artwork_listing" */
export type Art_Show_Artwork_Listing = {
  __typename?: 'Art_show_artwork_listing';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_order?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Art_show_artwork_listing" */
export type Art_Show_Artwork_Listing_Aggregate = {
  __typename?: 'Art_show_artwork_listing_aggregate';
  aggregate?: Maybe<Art_Show_Artwork_Listing_Aggregate_Fields>;
  nodes: Array<Art_Show_Artwork_Listing>;
};

/** aggregate fields of "Art_show_artwork_listing" */
export type Art_Show_Artwork_Listing_Aggregate_Fields = {
  __typename?: 'Art_show_artwork_listing_aggregate_fields';
  avg?: Maybe<Art_Show_Artwork_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_Show_Artwork_Listing_Max_Fields>;
  min?: Maybe<Art_Show_Artwork_Listing_Min_Fields>;
  stddev?: Maybe<Art_Show_Artwork_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Art_Show_Artwork_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_Show_Artwork_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Art_Show_Artwork_Listing_Sum_Fields>;
  var_pop?: Maybe<Art_Show_Artwork_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Art_Show_Artwork_Listing_Var_Samp_Fields>;
  variance?: Maybe<Art_Show_Artwork_Listing_Variance_Fields>;
};


/** aggregate fields of "Art_show_artwork_listing" */
export type Art_Show_Artwork_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_Show_Artwork_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Art_Show_Artwork_Listing_Avg_Fields = {
  __typename?: 'Art_show_artwork_listing_avg_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Art_show_artwork_listing". All fields are combined with a logical 'AND'. */
export type Art_Show_Artwork_Listing_Bool_Exp = {
  _and?: Maybe<Array<Art_Show_Artwork_Listing_Bool_Exp>>;
  _not?: Maybe<Art_Show_Artwork_Listing_Bool_Exp>;
  _or?: Maybe<Array<Art_Show_Artwork_Listing_Bool_Exp>>;
  area?: Maybe<Float8_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
  collection_order?: Maybe<Int_Comparison_Exp>;
  creation_date_year?: Maybe<Int_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_landscape?: Maybe<Boolean_Comparison_Exp>;
  is_portrait?: Maybe<Boolean_Comparison_Exp>;
  is_square?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  uri?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Art_Show_Artwork_Listing_Max_Fields = {
  __typename?: 'Art_show_artwork_listing_max_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_order?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Art_Show_Artwork_Listing_Min_Fields = {
  __typename?: 'Art_show_artwork_listing_min_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_order?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Art_show_artwork_listing". */
export type Art_Show_Artwork_Listing_Order_By = {
  area?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  collection_order?: Maybe<Order_By>;
  creation_date_year?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_landscape?: Maybe<Order_By>;
  is_portrait?: Maybe<Order_By>;
  is_square?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  uri?: Maybe<Order_By>;
};

/** select columns of table "Art_show_artwork_listing" */
export enum Art_Show_Artwork_Listing_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AspectRatio = 'aspect_ratio',
  /** column name */
  CollectionOrder = 'collection_order',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsPortrait = 'is_portrait',
  /** column name */
  IsSquare = 'is_square',
  /** column name */
  IsUnavailable = 'is_unavailable',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Uri = 'uri'
}

/** aggregate stddev on columns */
export type Art_Show_Artwork_Listing_Stddev_Fields = {
  __typename?: 'Art_show_artwork_listing_stddev_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Art_Show_Artwork_Listing_Stddev_Pop_Fields = {
  __typename?: 'Art_show_artwork_listing_stddev_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Art_Show_Artwork_Listing_Stddev_Samp_Fields = {
  __typename?: 'Art_show_artwork_listing_stddev_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Art_Show_Artwork_Listing_Sum_Fields = {
  __typename?: 'Art_show_artwork_listing_sum_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_order?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Art_Show_Artwork_Listing_Var_Pop_Fields = {
  __typename?: 'Art_show_artwork_listing_var_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Art_Show_Artwork_Listing_Var_Samp_Fields = {
  __typename?: 'Art_show_artwork_listing_var_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Art_Show_Artwork_Listing_Variance_Fields = {
  __typename?: 'Art_show_artwork_listing_variance_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** will contain all the display data necessary to list and view art fairs and private shows */
export type Art_Show_Details = {
  __typename?: 'Art_show_details';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  announce_at: Scalars['timestamptz'];
  /** every art show requires a collection */
  art_show_collection_id: Scalars['bigint'];
  /** An object relationship */
  banner_metadata?: Maybe<File_Metadata>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  closing_soon_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  collection: Collection;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_at: Scalars['timestamptz'];
  grand_opening_at?: Maybe<Scalars['timestamptz']>;
  opening_soon_at?: Maybe<Scalars['timestamptz']>;
  organized_by_context_id: Scalars['bigint'];
  /** An object relationship */
  organizer: Context;
  start_at: Scalars['timestamptz'];
  statement: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  uri: Scalars['String'];
  /** An object relationship */
  world_city?: Maybe<World_Cities>;
  world_city_id: Scalars['bigint'];
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Art_show_details" */
export type Art_Show_Details_Aggregate = {
  __typename?: 'Art_show_details_aggregate';
  aggregate?: Maybe<Art_Show_Details_Aggregate_Fields>;
  nodes: Array<Art_Show_Details>;
};

/** aggregate fields of "Art_show_details" */
export type Art_Show_Details_Aggregate_Fields = {
  __typename?: 'Art_show_details_aggregate_fields';
  avg?: Maybe<Art_Show_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_Show_Details_Max_Fields>;
  min?: Maybe<Art_Show_Details_Min_Fields>;
  stddev?: Maybe<Art_Show_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Art_Show_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_Show_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Art_Show_Details_Sum_Fields>;
  var_pop?: Maybe<Art_Show_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Art_Show_Details_Var_Samp_Fields>;
  variance?: Maybe<Art_Show_Details_Variance_Fields>;
};


/** aggregate fields of "Art_show_details" */
export type Art_Show_Details_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_Show_Details_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Art_Show_Details_Avg_Fields = {
  __typename?: 'Art_show_details_avg_fields';
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Art_show_details". All fields are combined with a logical 'AND'. */
export type Art_Show_Details_Bool_Exp = {
  _and?: Maybe<Array<Art_Show_Details_Bool_Exp>>;
  _not?: Maybe<Art_Show_Details_Bool_Exp>;
  _or?: Maybe<Array<Art_Show_Details_Bool_Exp>>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  announce_at?: Maybe<Timestamptz_Comparison_Exp>;
  art_show_collection_id?: Maybe<Bigint_Comparison_Exp>;
  banner_metadata?: Maybe<File_Metadata_Bool_Exp>;
  banner_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  closing_soon_at?: Maybe<Timestamptz_Comparison_Exp>;
  collection?: Maybe<Collection_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  end_at?: Maybe<Timestamptz_Comparison_Exp>;
  grand_opening_at?: Maybe<Timestamptz_Comparison_Exp>;
  opening_soon_at?: Maybe<Timestamptz_Comparison_Exp>;
  organized_by_context_id?: Maybe<Bigint_Comparison_Exp>;
  organizer?: Maybe<Context_Bool_Exp>;
  start_at?: Maybe<Timestamptz_Comparison_Exp>;
  statement?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uri?: Maybe<String_Comparison_Exp>;
  world_city?: Maybe<World_Cities_Bool_Exp>;
  world_city_id?: Maybe<Bigint_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Art_show_details" */
export enum Art_Show_Details_Constraint {
  /** unique or primary key constraint on columns "art_show_collection_id" */
  ArtShowCollectionIdx = 'Art_show_collection_idx',
  /** unique or primary key constraint on columns "art_show_collection_id" */
  ArtShowDetailsArtShowCollectionIdKey = 'Art_show_details_art_show_collection_id_key',
  /** unique or primary key constraint on columns "uri" */
  ArtShowPkey = 'Art_show_pkey'
}

/** input type for incrementing numeric columns in table "Art_show_details" */
export type Art_Show_Details_Inc_Input = {
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  world_city_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Art_show_details" */
export type Art_Show_Details_Insert_Input = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  announce_at?: Maybe<Scalars['timestamptz']>;
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  closing_soon_at?: Maybe<Scalars['timestamptz']>;
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  grand_opening_at?: Maybe<Scalars['timestamptz']>;
  opening_soon_at?: Maybe<Scalars['timestamptz']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  organizer?: Maybe<Context_Obj_Rel_Insert_Input>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  world_city?: Maybe<World_Cities_Obj_Rel_Insert_Input>;
  world_city_id?: Maybe<Scalars['bigint']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Art_Show_Details_Max_Fields = {
  __typename?: 'Art_show_details_max_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  announce_at?: Maybe<Scalars['timestamptz']>;
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  closing_soon_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  grand_opening_at?: Maybe<Scalars['timestamptz']>;
  opening_soon_at?: Maybe<Scalars['timestamptz']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  world_city_id?: Maybe<Scalars['bigint']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Art_Show_Details_Min_Fields = {
  __typename?: 'Art_show_details_min_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  announce_at?: Maybe<Scalars['timestamptz']>;
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  closing_soon_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  grand_opening_at?: Maybe<Scalars['timestamptz']>;
  opening_soon_at?: Maybe<Scalars['timestamptz']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  world_city_id?: Maybe<Scalars['bigint']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Art_show_details" */
export type Art_Show_Details_Mutation_Response = {
  __typename?: 'Art_show_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Art_Show_Details>;
};

/** on_conflict condition type for table "Art_show_details" */
export type Art_Show_Details_On_Conflict = {
  constraint: Art_Show_Details_Constraint;
  update_columns?: Array<Art_Show_Details_Update_Column>;
  where?: Maybe<Art_Show_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "Art_show_details". */
export type Art_Show_Details_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  announce_at?: Maybe<Order_By>;
  art_show_collection_id?: Maybe<Order_By>;
  banner_metadata?: Maybe<File_Metadata_Order_By>;
  banner_metadata_id?: Maybe<Order_By>;
  closing_soon_at?: Maybe<Order_By>;
  collection?: Maybe<Collection_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  end_at?: Maybe<Order_By>;
  grand_opening_at?: Maybe<Order_By>;
  opening_soon_at?: Maybe<Order_By>;
  organized_by_context_id?: Maybe<Order_By>;
  organizer?: Maybe<Context_Order_By>;
  start_at?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uri?: Maybe<Order_By>;
  world_city?: Maybe<World_Cities_Order_By>;
  world_city_id?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** primary key columns input for table: Art_show_details */
export type Art_Show_Details_Pk_Columns_Input = {
  uri: Scalars['String'];
};

/** select columns of table "Art_show_details" */
export enum Art_Show_Details_Select_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AnnounceAt = 'announce_at',
  /** column name */
  ArtShowCollectionId = 'art_show_collection_id',
  /** column name */
  BannerMetadataId = 'banner_metadata_id',
  /** column name */
  ClosingSoonAt = 'closing_soon_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  GrandOpeningAt = 'grand_opening_at',
  /** column name */
  OpeningSoonAt = 'opening_soon_at',
  /** column name */
  OrganizedByContextId = 'organized_by_context_id',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Statement = 'statement',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uri = 'uri',
  /** column name */
  WorldCityId = 'world_city_id',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "Art_show_details" */
export type Art_Show_Details_Set_Input = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  announce_at?: Maybe<Scalars['timestamptz']>;
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  closing_soon_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  grand_opening_at?: Maybe<Scalars['timestamptz']>;
  opening_soon_at?: Maybe<Scalars['timestamptz']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uri?: Maybe<Scalars['String']>;
  world_city_id?: Maybe<Scalars['bigint']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Art_Show_Details_Stddev_Fields = {
  __typename?: 'Art_show_details_stddev_fields';
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Art_Show_Details_Stddev_Pop_Fields = {
  __typename?: 'Art_show_details_stddev_pop_fields';
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Art_Show_Details_Stddev_Samp_Fields = {
  __typename?: 'Art_show_details_stddev_samp_fields';
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Art_Show_Details_Sum_Fields = {
  __typename?: 'Art_show_details_sum_fields';
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['bigint']>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  organized_by_context_id?: Maybe<Scalars['bigint']>;
  world_city_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Art_show_details" */
export enum Art_Show_Details_Update_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AnnounceAt = 'announce_at',
  /** column name */
  ArtShowCollectionId = 'art_show_collection_id',
  /** column name */
  BannerMetadataId = 'banner_metadata_id',
  /** column name */
  ClosingSoonAt = 'closing_soon_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  GrandOpeningAt = 'grand_opening_at',
  /** column name */
  OpeningSoonAt = 'opening_soon_at',
  /** column name */
  OrganizedByContextId = 'organized_by_context_id',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Statement = 'statement',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uri = 'uri',
  /** column name */
  WorldCityId = 'world_city_id',
  /** column name */
  ZipCode = 'zip_code'
}

/** aggregate var_pop on columns */
export type Art_Show_Details_Var_Pop_Fields = {
  __typename?: 'Art_show_details_var_pop_fields';
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Art_Show_Details_Var_Samp_Fields = {
  __typename?: 'Art_show_details_var_samp_fields';
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Art_Show_Details_Variance_Fields = {
  __typename?: 'Art_show_details_variance_fields';
  /** every art show requires a collection */
  art_show_collection_id?: Maybe<Scalars['Float']>;
  banner_metadata_id?: Maybe<Scalars['Float']>;
  organized_by_context_id?: Maybe<Scalars['Float']>;
  world_city_id?: Maybe<Scalars['Float']>;
};

/** Relationship table Enum_art_type to Enum_artwork_discipline */
export type Art_Type_To_Artwork_Discipline = {
  __typename?: 'Art_type_to_Artwork_discipline';
  /** An object relationship */
  ENUM_art_type: Enum_Art_Type;
  /** An object relationship */
  ENUM_artwork_discipline: Enum_Artwork_Discipline;
  art_type: Enum_Art_Type_Enum;
  artwork_discipline: Enum_Artwork_Discipline_Enum;
  id: Scalars['Int'];
  order: Scalars['Int'];
};

/** aggregated selection of "Art_type_to_Artwork_discipline" */
export type Art_Type_To_Artwork_Discipline_Aggregate = {
  __typename?: 'Art_type_to_Artwork_discipline_aggregate';
  aggregate?: Maybe<Art_Type_To_Artwork_Discipline_Aggregate_Fields>;
  nodes: Array<Art_Type_To_Artwork_Discipline>;
};

/** aggregate fields of "Art_type_to_Artwork_discipline" */
export type Art_Type_To_Artwork_Discipline_Aggregate_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_aggregate_fields';
  avg?: Maybe<Art_Type_To_Artwork_Discipline_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Art_Type_To_Artwork_Discipline_Max_Fields>;
  min?: Maybe<Art_Type_To_Artwork_Discipline_Min_Fields>;
  stddev?: Maybe<Art_Type_To_Artwork_Discipline_Stddev_Fields>;
  stddev_pop?: Maybe<Art_Type_To_Artwork_Discipline_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Art_Type_To_Artwork_Discipline_Stddev_Samp_Fields>;
  sum?: Maybe<Art_Type_To_Artwork_Discipline_Sum_Fields>;
  var_pop?: Maybe<Art_Type_To_Artwork_Discipline_Var_Pop_Fields>;
  var_samp?: Maybe<Art_Type_To_Artwork_Discipline_Var_Samp_Fields>;
  variance?: Maybe<Art_Type_To_Artwork_Discipline_Variance_Fields>;
};


/** aggregate fields of "Art_type_to_Artwork_discipline" */
export type Art_Type_To_Artwork_Discipline_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Art_Type_To_Artwork_Discipline_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Art_Type_To_Artwork_Discipline_Avg_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Art_type_to_Artwork_discipline". All fields are combined with a logical 'AND'. */
export type Art_Type_To_Artwork_Discipline_Bool_Exp = {
  ENUM_art_type?: Maybe<Enum_Art_Type_Bool_Exp>;
  ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline_Bool_Exp>;
  _and?: Maybe<Array<Art_Type_To_Artwork_Discipline_Bool_Exp>>;
  _not?: Maybe<Art_Type_To_Artwork_Discipline_Bool_Exp>;
  _or?: Maybe<Array<Art_Type_To_Artwork_Discipline_Bool_Exp>>;
  art_type?: Maybe<Enum_Art_Type_Enum_Comparison_Exp>;
  artwork_discipline?: Maybe<Enum_Artwork_Discipline_Enum_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Art_type_to_Artwork_discipline" */
export enum Art_Type_To_Artwork_Discipline_Constraint {
  /** unique or primary key constraint on columns "artwork_discipline", "art_type" */
  ArtTypeToArtworkDisciplineArtTypeArtworkDisciplineKey = 'Art_type_to_Artwork_discipline_art_type_artwork_discipline_key',
  /** unique or primary key constraint on columns "id" */
  ArtTypeToArtworkDisciplineIdKey = 'Art_type_to_Artwork_discipline_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtTypeToArtworkDisciplinePkey = 'Art_type_to_Artwork_discipline_pkey'
}

/** input type for incrementing numeric columns in table "Art_type_to_Artwork_discipline" */
export type Art_Type_To_Artwork_Discipline_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Art_type_to_Artwork_discipline" */
export type Art_Type_To_Artwork_Discipline_Insert_Input = {
  ENUM_art_type?: Maybe<Enum_Art_Type_Obj_Rel_Insert_Input>;
  ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline_Obj_Rel_Insert_Input>;
  art_type?: Maybe<Enum_Art_Type_Enum>;
  artwork_discipline?: Maybe<Enum_Artwork_Discipline_Enum>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Art_Type_To_Artwork_Discipline_Max_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_max_fields';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Art_Type_To_Artwork_Discipline_Min_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_min_fields';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Art_type_to_Artwork_discipline" */
export type Art_Type_To_Artwork_Discipline_Mutation_Response = {
  __typename?: 'Art_type_to_Artwork_discipline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Art_Type_To_Artwork_Discipline>;
};

/** on_conflict condition type for table "Art_type_to_Artwork_discipline" */
export type Art_Type_To_Artwork_Discipline_On_Conflict = {
  constraint: Art_Type_To_Artwork_Discipline_Constraint;
  update_columns?: Array<Art_Type_To_Artwork_Discipline_Update_Column>;
  where?: Maybe<Art_Type_To_Artwork_Discipline_Bool_Exp>;
};

/** Ordering options when selecting data from "Art_type_to_Artwork_discipline". */
export type Art_Type_To_Artwork_Discipline_Order_By = {
  ENUM_art_type?: Maybe<Enum_Art_Type_Order_By>;
  ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline_Order_By>;
  art_type?: Maybe<Order_By>;
  artwork_discipline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** primary key columns input for table: Art_type_to_Artwork_discipline */
export type Art_Type_To_Artwork_Discipline_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Art_type_to_Artwork_discipline" */
export enum Art_Type_To_Artwork_Discipline_Select_Column {
  /** column name */
  ArtType = 'art_type',
  /** column name */
  ArtworkDiscipline = 'artwork_discipline',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order'
}

/** input type for updating data in table "Art_type_to_Artwork_discipline" */
export type Art_Type_To_Artwork_Discipline_Set_Input = {
  art_type?: Maybe<Enum_Art_Type_Enum>;
  artwork_discipline?: Maybe<Enum_Artwork_Discipline_Enum>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Art_Type_To_Artwork_Discipline_Stddev_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Art_Type_To_Artwork_Discipline_Stddev_Pop_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Art_Type_To_Artwork_Discipline_Stddev_Samp_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Art_Type_To_Artwork_Discipline_Sum_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "Art_type_to_Artwork_discipline" */
export enum Art_Type_To_Artwork_Discipline_Update_Column {
  /** column name */
  ArtType = 'art_type',
  /** column name */
  ArtworkDiscipline = 'artwork_discipline',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order'
}

/** aggregate var_pop on columns */
export type Art_Type_To_Artwork_Discipline_Var_Pop_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Art_Type_To_Artwork_Discipline_Var_Samp_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Art_Type_To_Artwork_Discipline_Variance_Fields = {
  __typename?: 'Art_type_to_Artwork_discipline_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Stores information about Kaleido Collections. */
export type Artist_Collection = {
  __typename?: 'Artist_Collection';
  /** An array relationship */
  Artist_Collection_to_Artist_Contexts: Array<Artist_Collection_To_Artist_Context>;
  /** An aggregate relationship */
  Artist_Collection_to_Artist_Contexts_aggregate: Artist_Collection_To_Artist_Context_Aggregate;
  /** An object relationship */
  ENUM_artist_collection_state: Enum_Artist_Collection_State;
  /** An object relationship */
  ENUM_artist_collection_type?: Maybe<Enum_Artist_Collection_Type>;
  /** An object relationship */
  banner_metadata?: Maybe<File_Metadata>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  categories?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  external_url?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  internal_url?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  state: Enum_Artist_Collection_State_Enum;
  /** An object relationship */
  thumbnail_metadata?: Maybe<File_Metadata>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title: Scalars['String'];
  type?: Maybe<Enum_Artist_Collection_Type_Enum>;
  updated_at: Scalars['timestamptz'];
};


/** Stores information about Kaleido Collections. */
export type Artist_CollectionArtist_Collection_To_Artist_ContextsArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


/** Stores information about Kaleido Collections. */
export type Artist_CollectionArtist_Collection_To_Artist_Contexts_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


/** Stores information about Kaleido Collections. */
export type Artist_CollectionCategoriesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** Stores Information about Collection categories. */
export type Artist_Collection_Categories = {
  __typename?: 'Artist_Collection_Categories';
  artist_collection_id: Scalars['bigint'];
  category: Enum_Artist_Collection_Categories_Enum;
  id: Scalars['Int'];
};

/** aggregated selection of "Artist_Collection_Categories" */
export type Artist_Collection_Categories_Aggregate = {
  __typename?: 'Artist_Collection_Categories_aggregate';
  aggregate?: Maybe<Artist_Collection_Categories_Aggregate_Fields>;
  nodes: Array<Artist_Collection_Categories>;
};

/** aggregate fields of "Artist_Collection_Categories" */
export type Artist_Collection_Categories_Aggregate_Fields = {
  __typename?: 'Artist_Collection_Categories_aggregate_fields';
  avg?: Maybe<Artist_Collection_Categories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Collection_Categories_Max_Fields>;
  min?: Maybe<Artist_Collection_Categories_Min_Fields>;
  stddev?: Maybe<Artist_Collection_Categories_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Collection_Categories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Collection_Categories_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Collection_Categories_Sum_Fields>;
  var_pop?: Maybe<Artist_Collection_Categories_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Collection_Categories_Var_Samp_Fields>;
  variance?: Maybe<Artist_Collection_Categories_Variance_Fields>;
};


/** aggregate fields of "Artist_Collection_Categories" */
export type Artist_Collection_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Collection_Categories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Collection_Categories_Avg_Fields = {
  __typename?: 'Artist_Collection_Categories_avg_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_Collection_Categories". All fields are combined with a logical 'AND'. */
export type Artist_Collection_Categories_Bool_Exp = {
  _and?: Maybe<Array<Artist_Collection_Categories_Bool_Exp>>;
  _not?: Maybe<Artist_Collection_Categories_Bool_Exp>;
  _or?: Maybe<Array<Artist_Collection_Categories_Bool_Exp>>;
  artist_collection_id?: Maybe<Bigint_Comparison_Exp>;
  category?: Maybe<Enum_Artist_Collection_Categories_Enum_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artist_Collection_Categories" */
export enum Artist_Collection_Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtistCollectionCategoriesPkey = 'Artist_Collection_Categories_pkey'
}

/** input type for incrementing numeric columns in table "Artist_Collection_Categories" */
export type Artist_Collection_Categories_Inc_Input = {
  artist_collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artist_Collection_Categories" */
export type Artist_Collection_Categories_Insert_Input = {
  artist_collection_id?: Maybe<Scalars['bigint']>;
  category?: Maybe<Enum_Artist_Collection_Categories_Enum>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Artist_Collection_Categories_Max_Fields = {
  __typename?: 'Artist_Collection_Categories_max_fields';
  artist_collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Artist_Collection_Categories_Min_Fields = {
  __typename?: 'Artist_Collection_Categories_min_fields';
  artist_collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Artist_Collection_Categories" */
export type Artist_Collection_Categories_Mutation_Response = {
  __typename?: 'Artist_Collection_Categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist_Collection_Categories>;
};

/** on_conflict condition type for table "Artist_Collection_Categories" */
export type Artist_Collection_Categories_On_Conflict = {
  constraint: Artist_Collection_Categories_Constraint;
  update_columns?: Array<Artist_Collection_Categories_Update_Column>;
  where?: Maybe<Artist_Collection_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "Artist_Collection_Categories". */
export type Artist_Collection_Categories_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: Artist_Collection_Categories */
export type Artist_Collection_Categories_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Artist_Collection_Categories" */
export enum Artist_Collection_Categories_Select_Column {
  /** column name */
  ArtistCollectionId = 'artist_collection_id',
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "Artist_Collection_Categories" */
export type Artist_Collection_Categories_Set_Input = {
  artist_collection_id?: Maybe<Scalars['bigint']>;
  category?: Maybe<Enum_Artist_Collection_Categories_Enum>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Artist_Collection_Categories_Stddev_Fields = {
  __typename?: 'Artist_Collection_Categories_stddev_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Collection_Categories_Stddev_Pop_Fields = {
  __typename?: 'Artist_Collection_Categories_stddev_pop_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Collection_Categories_Stddev_Samp_Fields = {
  __typename?: 'Artist_Collection_Categories_stddev_samp_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Collection_Categories_Sum_Fields = {
  __typename?: 'Artist_Collection_Categories_sum_fields';
  artist_collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Artist_Collection_Categories" */
export enum Artist_Collection_Categories_Update_Column {
  /** column name */
  ArtistCollectionId = 'artist_collection_id',
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Artist_Collection_Categories_Var_Pop_Fields = {
  __typename?: 'Artist_Collection_Categories_var_pop_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Collection_Categories_Var_Samp_Fields = {
  __typename?: 'Artist_Collection_Categories_var_samp_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Collection_Categories_Variance_Fields = {
  __typename?: 'Artist_Collection_Categories_variance_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Artist_Collection" */
export type Artist_Collection_Aggregate = {
  __typename?: 'Artist_Collection_aggregate';
  aggregate?: Maybe<Artist_Collection_Aggregate_Fields>;
  nodes: Array<Artist_Collection>;
};

/** aggregate fields of "Artist_Collection" */
export type Artist_Collection_Aggregate_Fields = {
  __typename?: 'Artist_Collection_aggregate_fields';
  avg?: Maybe<Artist_Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Collection_Max_Fields>;
  min?: Maybe<Artist_Collection_Min_Fields>;
  stddev?: Maybe<Artist_Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Collection_Sum_Fields>;
  var_pop?: Maybe<Artist_Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Collection_Var_Samp_Fields>;
  variance?: Maybe<Artist_Collection_Variance_Fields>;
};


/** aggregate fields of "Artist_Collection" */
export type Artist_Collection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Collection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Artist_Collection_Append_Input = {
  categories?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Artist_Collection_Avg_Fields = {
  __typename?: 'Artist_Collection_avg_fields';
  banner_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_Collection". All fields are combined with a logical 'AND'. */
export type Artist_Collection_Bool_Exp = {
  Artist_Collection_to_Artist_Contexts?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
  ENUM_artist_collection_state?: Maybe<Enum_Artist_Collection_State_Bool_Exp>;
  ENUM_artist_collection_type?: Maybe<Enum_Artist_Collection_Type_Bool_Exp>;
  _and?: Maybe<Array<Artist_Collection_Bool_Exp>>;
  _not?: Maybe<Artist_Collection_Bool_Exp>;
  _or?: Maybe<Array<Artist_Collection_Bool_Exp>>;
  banner_metadata?: Maybe<File_Metadata_Bool_Exp>;
  banner_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  categories?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  external_url?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  internal_url?: Maybe<String_Comparison_Exp>;
  short_description?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Enum_Artist_Collection_State_Enum_Comparison_Exp>;
  thumbnail_metadata?: Maybe<File_Metadata_Bool_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Enum_Artist_Collection_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artist_Collection" */
export enum Artist_Collection_Constraint {
  /** unique or primary key constraint on columns "id" */
  VirtualCollectionPkey = 'Virtual_Collection_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Artist_Collection_Delete_At_Path_Input = {
  categories?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Artist_Collection_Delete_Elem_Input = {
  categories?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Artist_Collection_Delete_Key_Input = {
  categories?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Artist_Collection" */
export type Artist_Collection_Inc_Input = {
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artist_Collection" */
export type Artist_Collection_Insert_Input = {
  Artist_Collection_to_Artist_Contexts?: Maybe<Artist_Collection_To_Artist_Context_Arr_Rel_Insert_Input>;
  ENUM_artist_collection_state?: Maybe<Enum_Artist_Collection_State_Obj_Rel_Insert_Input>;
  ENUM_artist_collection_type?: Maybe<Enum_Artist_Collection_Type_Obj_Rel_Insert_Input>;
  banner_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  categories?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  state?: Maybe<Enum_Artist_Collection_State_Enum>;
  thumbnail_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Artist_Collection_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artist_Collection_Max_Fields = {
  __typename?: 'Artist_Collection_max_fields';
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artist_Collection_Min_Fields = {
  __typename?: 'Artist_Collection_min_fields';
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Artist_Collection" */
export type Artist_Collection_Mutation_Response = {
  __typename?: 'Artist_Collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist_Collection>;
};

/** input type for inserting object relation for remote table "Artist_Collection" */
export type Artist_Collection_Obj_Rel_Insert_Input = {
  data: Artist_Collection_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artist_Collection_On_Conflict>;
};

/** on_conflict condition type for table "Artist_Collection" */
export type Artist_Collection_On_Conflict = {
  constraint: Artist_Collection_Constraint;
  update_columns?: Array<Artist_Collection_Update_Column>;
  where?: Maybe<Artist_Collection_Bool_Exp>;
};

/** Ordering options when selecting data from "Artist_Collection". */
export type Artist_Collection_Order_By = {
  Artist_Collection_to_Artist_Contexts_aggregate?: Maybe<Artist_Collection_To_Artist_Context_Aggregate_Order_By>;
  ENUM_artist_collection_state?: Maybe<Enum_Artist_Collection_State_Order_By>;
  ENUM_artist_collection_type?: Maybe<Enum_Artist_Collection_Type_Order_By>;
  banner_metadata?: Maybe<File_Metadata_Order_By>;
  banner_metadata_id?: Maybe<Order_By>;
  categories?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  external_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_url?: Maybe<Order_By>;
  short_description?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  thumbnail_metadata?: Maybe<File_Metadata_Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artist_Collection */
export type Artist_Collection_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Artist_Collection_Prepend_Input = {
  categories?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Artist_Collection" */
export enum Artist_Collection_Select_Column {
  /** column name */
  BannerMetadataId = 'banner_metadata_id',
  /** column name */
  Categories = 'categories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalUrl = 'external_url',
  /** column name */
  Id = 'id',
  /** column name */
  InternalUrl = 'internal_url',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  State = 'state',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artist_Collection" */
export type Artist_Collection_Set_Input = {
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  categories?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  state?: Maybe<Enum_Artist_Collection_State_Enum>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Artist_Collection_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artist_Collection_Stddev_Fields = {
  __typename?: 'Artist_Collection_stddev_fields';
  banner_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Collection_Stddev_Pop_Fields = {
  __typename?: 'Artist_Collection_stddev_pop_fields';
  banner_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Collection_Stddev_Samp_Fields = {
  __typename?: 'Artist_Collection_stddev_samp_fields';
  banner_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Collection_Sum_Fields = {
  __typename?: 'Artist_Collection_sum_fields';
  banner_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** Stores artists that are in a Collection. */
export type Artist_Collection_To_Artist_Context = {
  __typename?: 'Artist_Collection_to_Artist_Context';
  /** An object relationship */
  artist_Collection: Artist_Collection;
  artist_collection_id: Scalars['bigint'];
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  /** Extra data stored. Like artworks ids that we want to server on a collection from a particular artist. */
  data?: Maybe<Scalars['jsonb']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};


/** Stores artists that are in a Collection. */
export type Artist_Collection_To_Artist_ContextDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Aggregate = {
  __typename?: 'Artist_Collection_to_Artist_Context_aggregate';
  aggregate?: Maybe<Artist_Collection_To_Artist_Context_Aggregate_Fields>;
  nodes: Array<Artist_Collection_To_Artist_Context>;
};

/** aggregate fields of "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Aggregate_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_aggregate_fields';
  avg?: Maybe<Artist_Collection_To_Artist_Context_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Collection_To_Artist_Context_Max_Fields>;
  min?: Maybe<Artist_Collection_To_Artist_Context_Min_Fields>;
  stddev?: Maybe<Artist_Collection_To_Artist_Context_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Collection_To_Artist_Context_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Collection_To_Artist_Context_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Collection_To_Artist_Context_Sum_Fields>;
  var_pop?: Maybe<Artist_Collection_To_Artist_Context_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Collection_To_Artist_Context_Var_Samp_Fields>;
  variance?: Maybe<Artist_Collection_To_Artist_Context_Variance_Fields>;
};


/** aggregate fields of "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Aggregate_Order_By = {
  avg?: Maybe<Artist_Collection_To_Artist_Context_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artist_Collection_To_Artist_Context_Max_Order_By>;
  min?: Maybe<Artist_Collection_To_Artist_Context_Min_Order_By>;
  stddev?: Maybe<Artist_Collection_To_Artist_Context_Stddev_Order_By>;
  stddev_pop?: Maybe<Artist_Collection_To_Artist_Context_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artist_Collection_To_Artist_Context_Stddev_Samp_Order_By>;
  sum?: Maybe<Artist_Collection_To_Artist_Context_Sum_Order_By>;
  var_pop?: Maybe<Artist_Collection_To_Artist_Context_Var_Pop_Order_By>;
  var_samp?: Maybe<Artist_Collection_To_Artist_Context_Var_Samp_Order_By>;
  variance?: Maybe<Artist_Collection_To_Artist_Context_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Artist_Collection_To_Artist_Context_Append_Input = {
  /** Extra data stored. Like artworks ids that we want to server on a collection from a particular artist. */
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Arr_Rel_Insert_Input = {
  data: Array<Artist_Collection_To_Artist_Context_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artist_Collection_To_Artist_Context_On_Conflict>;
};

/** aggregate avg on columns */
export type Artist_Collection_To_Artist_Context_Avg_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_avg_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Avg_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artist_Collection_to_Artist_Context". All fields are combined with a logical 'AND'. */
export type Artist_Collection_To_Artist_Context_Bool_Exp = {
  _and?: Maybe<Array<Artist_Collection_To_Artist_Context_Bool_Exp>>;
  _not?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
  _or?: Maybe<Array<Artist_Collection_To_Artist_Context_Bool_Exp>>;
  artist_Collection?: Maybe<Artist_Collection_Bool_Exp>;
  artist_collection_id?: Maybe<Bigint_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artist_Collection_to_Artist_Context" */
export enum Artist_Collection_To_Artist_Context_Constraint {
  /** unique or primary key constraint on columns "id" */
  VirtualCollectionToArtistPkey = 'Virtual_Collection_to_Artist_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Artist_Collection_To_Artist_Context_Delete_At_Path_Input = {
  /** Extra data stored. Like artworks ids that we want to server on a collection from a particular artist. */
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Artist_Collection_To_Artist_Context_Delete_Elem_Input = {
  /** Extra data stored. Like artworks ids that we want to server on a collection from a particular artist. */
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Artist_Collection_To_Artist_Context_Delete_Key_Input = {
  /** Extra data stored. Like artworks ids that we want to server on a collection from a particular artist. */
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Inc_Input = {
  artist_collection_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Insert_Input = {
  artist_Collection?: Maybe<Artist_Collection_Obj_Rel_Insert_Input>;
  artist_collection_id?: Maybe<Scalars['bigint']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Extra data stored. Like artworks ids that we want to server on a collection from a particular artist. */
  data?: Maybe<Scalars['jsonb']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artist_Collection_To_Artist_Context_Max_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_max_fields';
  artist_collection_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Max_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artist_Collection_To_Artist_Context_Min_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_min_fields';
  artist_collection_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Min_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Mutation_Response = {
  __typename?: 'Artist_Collection_to_Artist_Context_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist_Collection_To_Artist_Context>;
};

/** on_conflict condition type for table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_On_Conflict = {
  constraint: Artist_Collection_To_Artist_Context_Constraint;
  update_columns?: Array<Artist_Collection_To_Artist_Context_Update_Column>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};

/** Ordering options when selecting data from "Artist_Collection_to_Artist_Context". */
export type Artist_Collection_To_Artist_Context_Order_By = {
  artist_Collection?: Maybe<Artist_Collection_Order_By>;
  artist_collection_id?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artist_Collection_to_Artist_Context */
export type Artist_Collection_To_Artist_Context_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Artist_Collection_To_Artist_Context_Prepend_Input = {
  /** Extra data stored. Like artworks ids that we want to server on a collection from a particular artist. */
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Artist_Collection_to_Artist_Context" */
export enum Artist_Collection_To_Artist_Context_Select_Column {
  /** column name */
  ArtistCollectionId = 'artist_collection_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Set_Input = {
  artist_collection_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Extra data stored. Like artworks ids that we want to server on a collection from a particular artist. */
  data?: Maybe<Scalars['jsonb']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artist_Collection_To_Artist_Context_Stddev_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_stddev_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Stddev_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artist_Collection_To_Artist_Context_Stddev_Pop_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_stddev_pop_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Stddev_Pop_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artist_Collection_To_Artist_Context_Stddev_Samp_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_stddev_samp_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Stddev_Samp_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artist_Collection_To_Artist_Context_Sum_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_sum_fields';
  artist_collection_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Sum_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
};

/** update columns of table "Artist_Collection_to_Artist_Context" */
export enum Artist_Collection_To_Artist_Context_Update_Column {
  /** column name */
  ArtistCollectionId = 'artist_collection_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artist_Collection_To_Artist_Context_Var_Pop_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_var_pop_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Var_Pop_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artist_Collection_To_Artist_Context_Var_Samp_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_var_samp_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Var_Samp_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artist_Collection_To_Artist_Context_Variance_Fields = {
  __typename?: 'Artist_Collection_to_Artist_Context_variance_fields';
  artist_collection_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artist_Collection_to_Artist_Context" */
export type Artist_Collection_To_Artist_Context_Variance_Order_By = {
  artist_collection_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Order in which the artists must appear on a collection. */
  order?: Maybe<Order_By>;
};

/** update columns of table "Artist_Collection" */
export enum Artist_Collection_Update_Column {
  /** column name */
  BannerMetadataId = 'banner_metadata_id',
  /** column name */
  Categories = 'categories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalUrl = 'external_url',
  /** column name */
  Id = 'id',
  /** column name */
  InternalUrl = 'internal_url',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  State = 'state',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artist_Collection_Var_Pop_Fields = {
  __typename?: 'Artist_Collection_var_pop_fields';
  banner_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Collection_Var_Samp_Fields = {
  __typename?: 'Artist_Collection_var_samp_fields';
  banner_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Collection_Variance_Fields = {
  __typename?: 'Artist_Collection_variance_fields';
  banner_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artist_Vision_Listings_By_Interest" */
export type Artist_Vision_Listings_By_Interest = {
  __typename?: 'Artist_Vision_Listings_By_Interest';
  artist_collection_to_artist_context_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** An object relationship */
  featured_artwork?: Maybe<Artwork>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  featured_artworks: Array<Artist_Cardgrid_Artworks_View>;
  /** An aggregate relationship */
  featured_artworks_aggregate: Artist_Cardgrid_Artworks_View_Aggregate;
  handle?: Maybe<Scalars['String']>;
  interest_collection_id?: Maybe<Scalars['bigint']>;
  interest_collection_order?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Artist_Vision_Listings_By_Interest" */
export type Artist_Vision_Listings_By_InterestFeatured_ArtworksArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


/** columns and relationships of "Artist_Vision_Listings_By_Interest" */
export type Artist_Vision_Listings_By_InterestFeatured_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};

/** aggregated selection of "Artist_Vision_Listings_By_Interest" */
export type Artist_Vision_Listings_By_Interest_Aggregate = {
  __typename?: 'Artist_Vision_Listings_By_Interest_aggregate';
  aggregate?: Maybe<Artist_Vision_Listings_By_Interest_Aggregate_Fields>;
  nodes: Array<Artist_Vision_Listings_By_Interest>;
};

/** aggregate fields of "Artist_Vision_Listings_By_Interest" */
export type Artist_Vision_Listings_By_Interest_Aggregate_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_aggregate_fields';
  avg?: Maybe<Artist_Vision_Listings_By_Interest_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Vision_Listings_By_Interest_Max_Fields>;
  min?: Maybe<Artist_Vision_Listings_By_Interest_Min_Fields>;
  stddev?: Maybe<Artist_Vision_Listings_By_Interest_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Vision_Listings_By_Interest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Vision_Listings_By_Interest_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Vision_Listings_By_Interest_Sum_Fields>;
  var_pop?: Maybe<Artist_Vision_Listings_By_Interest_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Vision_Listings_By_Interest_Var_Samp_Fields>;
  variance?: Maybe<Artist_Vision_Listings_By_Interest_Variance_Fields>;
};


/** aggregate fields of "Artist_Vision_Listings_By_Interest" */
export type Artist_Vision_Listings_By_Interest_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Vision_Listings_By_Interest_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Vision_Listings_By_Interest_Avg_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_avg_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_Vision_Listings_By_Interest". All fields are combined with a logical 'AND'. */
export type Artist_Vision_Listings_By_Interest_Bool_Exp = {
  _and?: Maybe<Array<Artist_Vision_Listings_By_Interest_Bool_Exp>>;
  _not?: Maybe<Artist_Vision_Listings_By_Interest_Bool_Exp>;
  _or?: Maybe<Array<Artist_Vision_Listings_By_Interest_Bool_Exp>>;
  artist_collection_to_artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_profile_id?: Maybe<Bigint_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  featured_artwork?: Maybe<Artwork_Bool_Exp>;
  featured_artwork_id?: Maybe<Bigint_Comparison_Exp>;
  featured_artworks?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  interest_collection_id?: Maybe<Bigint_Comparison_Exp>;
  interest_collection_order?: Maybe<Bigint_Comparison_Exp>;
  internal_url?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artist_Vision_Listings_By_Interest_Max_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_max_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  interest_collection_id?: Maybe<Scalars['bigint']>;
  interest_collection_order?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artist_Vision_Listings_By_Interest_Min_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_min_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  interest_collection_id?: Maybe<Scalars['bigint']>;
  interest_collection_order?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artist_Vision_Listings_By_Interest". */
export type Artist_Vision_Listings_By_Interest_Order_By = {
  artist_collection_to_artist_context_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  featured_artwork?: Maybe<Artwork_Order_By>;
  featured_artwork_id?: Maybe<Order_By>;
  featured_artworks_aggregate?: Maybe<Artist_Cardgrid_Artworks_View_Aggregate_Order_By>;
  handle?: Maybe<Order_By>;
  interest_collection_id?: Maybe<Order_By>;
  interest_collection_order?: Maybe<Order_By>;
  internal_url?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Artist_Vision_Listings_By_Interest" */
export enum Artist_Vision_Listings_By_Interest_Select_Column {
  /** column name */
  ArtistCollectionToArtistContextId = 'artist_collection_to_artist_context_id',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistProfileId = 'artist_profile_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  FeaturedArtworkId = 'featured_artwork_id',
  /** column name */
  Handle = 'handle',
  /** column name */
  InterestCollectionId = 'interest_collection_id',
  /** column name */
  InterestCollectionOrder = 'interest_collection_order',
  /** column name */
  InternalUrl = 'internal_url',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Artist_Vision_Listings_By_Interest_Stddev_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_stddev_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Vision_Listings_By_Interest_Stddev_Pop_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_stddev_pop_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Vision_Listings_By_Interest_Stddev_Samp_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_stddev_samp_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Vision_Listings_By_Interest_Sum_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_sum_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  interest_collection_id?: Maybe<Scalars['bigint']>;
  interest_collection_order?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artist_Vision_Listings_By_Interest_Var_Pop_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_var_pop_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Vision_Listings_By_Interest_Var_Samp_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_var_samp_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Vision_Listings_By_Interest_Variance_Fields = {
  __typename?: 'Artist_Vision_Listings_By_Interest_variance_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artist_art_history_listing" */
export type Artist_Art_History_Listing = {
  __typename?: 'Artist_art_history_listing';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  premium_artist?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artist_art_history_listing" */
export type Artist_Art_History_Listing_Aggregate = {
  __typename?: 'Artist_art_history_listing_aggregate';
  aggregate?: Maybe<Artist_Art_History_Listing_Aggregate_Fields>;
  nodes: Array<Artist_Art_History_Listing>;
};

/** aggregate fields of "Artist_art_history_listing" */
export type Artist_Art_History_Listing_Aggregate_Fields = {
  __typename?: 'Artist_art_history_listing_aggregate_fields';
  avg?: Maybe<Artist_Art_History_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Art_History_Listing_Max_Fields>;
  min?: Maybe<Artist_Art_History_Listing_Min_Fields>;
  stddev?: Maybe<Artist_Art_History_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Art_History_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Art_History_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Art_History_Listing_Sum_Fields>;
  var_pop?: Maybe<Artist_Art_History_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Art_History_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artist_Art_History_Listing_Variance_Fields>;
};


/** aggregate fields of "Artist_art_history_listing" */
export type Artist_Art_History_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Art_History_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Art_History_Listing_Avg_Fields = {
  __typename?: 'Artist_art_history_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_art_history_listing". All fields are combined with a logical 'AND'. */
export type Artist_Art_History_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artist_Art_History_Listing_Bool_Exp>>;
  _not?: Maybe<Artist_Art_History_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artist_Art_History_Listing_Bool_Exp>>;
  appeared_at?: Maybe<Timestamptz_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_profile_id?: Maybe<Bigint_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  curation_order?: Maybe<Int_Comparison_Exp>;
  curation_score?: Maybe<Int_Comparison_Exp>;
  featured_artwork_id?: Maybe<Bigint_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  premium_artist?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** columns and relationships of "Artist_art_history_listing_by_movement" */
export type Artist_Art_History_Listing_By_Movement = {
  __typename?: 'Artist_art_history_listing_by_movement';
  artist_collection_to_artist_context_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  interest_collection_id?: Maybe<Scalars['bigint']>;
  interest_collection_order?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  premium_artist?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artist_art_history_listing_by_movement" */
export type Artist_Art_History_Listing_By_Movement_Aggregate = {
  __typename?: 'Artist_art_history_listing_by_movement_aggregate';
  aggregate?: Maybe<Artist_Art_History_Listing_By_Movement_Aggregate_Fields>;
  nodes: Array<Artist_Art_History_Listing_By_Movement>;
};

/** aggregate fields of "Artist_art_history_listing_by_movement" */
export type Artist_Art_History_Listing_By_Movement_Aggregate_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_aggregate_fields';
  avg?: Maybe<Artist_Art_History_Listing_By_Movement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Art_History_Listing_By_Movement_Max_Fields>;
  min?: Maybe<Artist_Art_History_Listing_By_Movement_Min_Fields>;
  stddev?: Maybe<Artist_Art_History_Listing_By_Movement_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Art_History_Listing_By_Movement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Art_History_Listing_By_Movement_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Art_History_Listing_By_Movement_Sum_Fields>;
  var_pop?: Maybe<Artist_Art_History_Listing_By_Movement_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Art_History_Listing_By_Movement_Var_Samp_Fields>;
  variance?: Maybe<Artist_Art_History_Listing_By_Movement_Variance_Fields>;
};


/** aggregate fields of "Artist_art_history_listing_by_movement" */
export type Artist_Art_History_Listing_By_Movement_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Art_History_Listing_By_Movement_Avg_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_avg_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_art_history_listing_by_movement". All fields are combined with a logical 'AND'. */
export type Artist_Art_History_Listing_By_Movement_Bool_Exp = {
  _and?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Bool_Exp>>;
  _not?: Maybe<Artist_Art_History_Listing_By_Movement_Bool_Exp>;
  _or?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Bool_Exp>>;
  artist_collection_to_artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_profile_id?: Maybe<Bigint_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  featured_artwork_id?: Maybe<Bigint_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  interest_collection_id?: Maybe<Bigint_Comparison_Exp>;
  interest_collection_order?: Maybe<Bigint_Comparison_Exp>;
  internal_url?: Maybe<String_Comparison_Exp>;
  premium_artist?: Maybe<Boolean_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artist_Art_History_Listing_By_Movement_Max_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_max_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  interest_collection_id?: Maybe<Scalars['bigint']>;
  interest_collection_order?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artist_Art_History_Listing_By_Movement_Min_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_min_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  interest_collection_id?: Maybe<Scalars['bigint']>;
  interest_collection_order?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artist_art_history_listing_by_movement". */
export type Artist_Art_History_Listing_By_Movement_Order_By = {
  artist_collection_to_artist_context_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  featured_artwork_id?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  interest_collection_id?: Maybe<Order_By>;
  interest_collection_order?: Maybe<Order_By>;
  internal_url?: Maybe<Order_By>;
  premium_artist?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Artist_art_history_listing_by_movement" */
export enum Artist_Art_History_Listing_By_Movement_Select_Column {
  /** column name */
  ArtistCollectionToArtistContextId = 'artist_collection_to_artist_context_id',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistProfileId = 'artist_profile_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  FeaturedArtworkId = 'featured_artwork_id',
  /** column name */
  Handle = 'handle',
  /** column name */
  InterestCollectionId = 'interest_collection_id',
  /** column name */
  InterestCollectionOrder = 'interest_collection_order',
  /** column name */
  InternalUrl = 'internal_url',
  /** column name */
  PremiumArtist = 'premium_artist',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Artist_Art_History_Listing_By_Movement_Stddev_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_stddev_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Art_History_Listing_By_Movement_Stddev_Pop_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_stddev_pop_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Art_History_Listing_By_Movement_Stddev_Samp_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_stddev_samp_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Art_History_Listing_By_Movement_Sum_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_sum_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  interest_collection_id?: Maybe<Scalars['bigint']>;
  interest_collection_order?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artist_Art_History_Listing_By_Movement_Var_Pop_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_var_pop_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Art_History_Listing_By_Movement_Var_Samp_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_var_samp_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Art_History_Listing_By_Movement_Variance_Fields = {
  __typename?: 'Artist_art_history_listing_by_movement_variance_fields';
  artist_collection_to_artist_context_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  interest_collection_id?: Maybe<Scalars['Float']>;
  interest_collection_order?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Artist_Art_History_Listing_Max_Fields = {
  __typename?: 'Artist_art_history_listing_max_fields';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artist_Art_History_Listing_Min_Fields = {
  __typename?: 'Artist_art_history_listing_min_fields';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artist_art_history_listing". */
export type Artist_Art_History_Listing_Order_By = {
  appeared_at?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  curation_order?: Maybe<Order_By>;
  curation_score?: Maybe<Order_By>;
  featured_artwork_id?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  premium_artist?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Artist_art_history_listing" */
export enum Artist_Art_History_Listing_Select_Column {
  /** column name */
  AppearedAt = 'appeared_at',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistProfileId = 'artist_profile_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CurationOrder = 'curation_order',
  /** column name */
  CurationScore = 'curation_score',
  /** column name */
  FeaturedArtworkId = 'featured_artwork_id',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  PremiumArtist = 'premium_artist',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Artist_Art_History_Listing_Stddev_Fields = {
  __typename?: 'Artist_art_history_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Art_History_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artist_art_history_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Art_History_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artist_art_history_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Art_History_Listing_Sum_Fields = {
  __typename?: 'Artist_art_history_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artist_Art_History_Listing_Var_Pop_Fields = {
  __typename?: 'Artist_art_history_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Art_History_Listing_Var_Samp_Fields = {
  __typename?: 'Artist_art_history_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Art_History_Listing_Variance_Fields = {
  __typename?: 'Artist_art_history_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Artists who have been whitelisted to appear in the Art History module of the Art Universe app */
export type Artist_Art_History_Visibility = {
  __typename?: 'Artist_art_history_visibility';
  /** An array relationship */
  access: Array<Flat_Context_Access>;
  /** An aggregate relationship */
  access_aggregate: Flat_Context_Access_Aggregate;
  appeared_at: Scalars['timestamptz'];
  artist_context_id: Scalars['bigint'];
  /** An object relationship */
  context: Context;
  created_at: Scalars['timestamptz'];
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  premium: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};


/** Artists who have been whitelisted to appear in the Art History module of the Art Universe app */
export type Artist_Art_History_VisibilityAccessArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


/** Artists who have been whitelisted to appear in the Art History module of the Art Universe app */
export type Artist_Art_History_VisibilityAccess_AggregateArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};

/** aggregated selection of "Artist_art_history_visibility" */
export type Artist_Art_History_Visibility_Aggregate = {
  __typename?: 'Artist_art_history_visibility_aggregate';
  aggregate?: Maybe<Artist_Art_History_Visibility_Aggregate_Fields>;
  nodes: Array<Artist_Art_History_Visibility>;
};

/** aggregate fields of "Artist_art_history_visibility" */
export type Artist_Art_History_Visibility_Aggregate_Fields = {
  __typename?: 'Artist_art_history_visibility_aggregate_fields';
  avg?: Maybe<Artist_Art_History_Visibility_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Art_History_Visibility_Max_Fields>;
  min?: Maybe<Artist_Art_History_Visibility_Min_Fields>;
  stddev?: Maybe<Artist_Art_History_Visibility_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Art_History_Visibility_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Art_History_Visibility_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Art_History_Visibility_Sum_Fields>;
  var_pop?: Maybe<Artist_Art_History_Visibility_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Art_History_Visibility_Var_Samp_Fields>;
  variance?: Maybe<Artist_Art_History_Visibility_Variance_Fields>;
};


/** aggregate fields of "Artist_art_history_visibility" */
export type Artist_Art_History_Visibility_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Art_History_Visibility_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Art_History_Visibility_Avg_Fields = {
  __typename?: 'Artist_art_history_visibility_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_art_history_visibility". All fields are combined with a logical 'AND'. */
export type Artist_Art_History_Visibility_Bool_Exp = {
  _and?: Maybe<Array<Artist_Art_History_Visibility_Bool_Exp>>;
  _not?: Maybe<Artist_Art_History_Visibility_Bool_Exp>;
  _or?: Maybe<Array<Artist_Art_History_Visibility_Bool_Exp>>;
  access?: Maybe<Flat_Context_Access_Bool_Exp>;
  appeared_at?: Maybe<Timestamptz_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disappeared_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  premium?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artist_art_history_visibility" */
export enum Artist_Art_History_Visibility_Constraint {
  /** unique or primary key constraint on columns "artist_context_id" */
  ArtistArtHistoryVisibilityArtistContextIdKey = 'Artist_art_history_visibility_artist_context_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtistArtHistoryVisibilityPkey = 'Artist_art_history_visibility_pkey'
}

/** input type for incrementing numeric columns in table "Artist_art_history_visibility" */
export type Artist_Art_History_Visibility_Inc_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artist_art_history_visibility" */
export type Artist_Art_History_Visibility_Insert_Input = {
  access?: Maybe<Flat_Context_Access_Arr_Rel_Insert_Input>;
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  premium?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artist_Art_History_Visibility_Max_Fields = {
  __typename?: 'Artist_art_history_visibility_max_fields';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artist_Art_History_Visibility_Min_Fields = {
  __typename?: 'Artist_art_history_visibility_min_fields';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Artist_art_history_visibility" */
export type Artist_Art_History_Visibility_Mutation_Response = {
  __typename?: 'Artist_art_history_visibility_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist_Art_History_Visibility>;
};

/** on_conflict condition type for table "Artist_art_history_visibility" */
export type Artist_Art_History_Visibility_On_Conflict = {
  constraint: Artist_Art_History_Visibility_Constraint;
  update_columns?: Array<Artist_Art_History_Visibility_Update_Column>;
  where?: Maybe<Artist_Art_History_Visibility_Bool_Exp>;
};

/** Ordering options when selecting data from "Artist_art_history_visibility". */
export type Artist_Art_History_Visibility_Order_By = {
  access_aggregate?: Maybe<Flat_Context_Access_Aggregate_Order_By>;
  appeared_at?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  disappeared_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  premium?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artist_art_history_visibility */
export type Artist_Art_History_Visibility_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artist_art_history_visibility" */
export enum Artist_Art_History_Visibility_Select_Column {
  /** column name */
  AppearedAt = 'appeared_at',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisappearedAt = 'disappeared_at',
  /** column name */
  Id = 'id',
  /** column name */
  Premium = 'premium',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artist_art_history_visibility" */
export type Artist_Art_History_Visibility_Set_Input = {
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  premium?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artist_Art_History_Visibility_Stddev_Fields = {
  __typename?: 'Artist_art_history_visibility_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Art_History_Visibility_Stddev_Pop_Fields = {
  __typename?: 'Artist_art_history_visibility_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Art_History_Visibility_Stddev_Samp_Fields = {
  __typename?: 'Artist_art_history_visibility_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Art_History_Visibility_Sum_Fields = {
  __typename?: 'Artist_art_history_visibility_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Artist_art_history_visibility" */
export enum Artist_Art_History_Visibility_Update_Column {
  /** column name */
  AppearedAt = 'appeared_at',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisappearedAt = 'disappeared_at',
  /** column name */
  Id = 'id',
  /** column name */
  Premium = 'premium',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artist_Art_History_Visibility_Var_Pop_Fields = {
  __typename?: 'Artist_art_history_visibility_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Art_History_Visibility_Var_Samp_Fields = {
  __typename?: 'Artist_art_history_visibility_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Art_History_Visibility_Variance_Fields = {
  __typename?: 'Artist_art_history_visibility_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** An Kaleido admin account can add a score for an artist in order to show more, less or not at all. */
export type Artist_Curation_Score = {
  __typename?: 'Artist_curation_score';
  /** An object relationship */
  artist_context: Context;
  artist_context_id: Scalars['bigint'];
  /** An array relationship */
  artwork_listing: Array<Artwork_Explore_Listing>;
  /** An aggregate relationship */
  artwork_listing_aggregate: Artwork_Explore_Listing_Aggregate;
  /** An object relationship */
  curator_context: Context;
  curator_context_id: Scalars['bigint'];
  score: Scalars['Int'];
};


/** An Kaleido admin account can add a score for an artist in order to show more, less or not at all. */
export type Artist_Curation_ScoreArtwork_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Listing_Order_By>>;
  where?: Maybe<Artwork_Explore_Listing_Bool_Exp>;
};


/** An Kaleido admin account can add a score for an artist in order to show more, less or not at all. */
export type Artist_Curation_ScoreArtwork_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Listing_Order_By>>;
  where?: Maybe<Artwork_Explore_Listing_Bool_Exp>;
};

/** aggregated selection of "Artist_curation_score" */
export type Artist_Curation_Score_Aggregate = {
  __typename?: 'Artist_curation_score_aggregate';
  aggregate?: Maybe<Artist_Curation_Score_Aggregate_Fields>;
  nodes: Array<Artist_Curation_Score>;
};

/** aggregate fields of "Artist_curation_score" */
export type Artist_Curation_Score_Aggregate_Fields = {
  __typename?: 'Artist_curation_score_aggregate_fields';
  avg?: Maybe<Artist_Curation_Score_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Curation_Score_Max_Fields>;
  min?: Maybe<Artist_Curation_Score_Min_Fields>;
  stddev?: Maybe<Artist_Curation_Score_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Curation_Score_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Curation_Score_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Curation_Score_Sum_Fields>;
  var_pop?: Maybe<Artist_Curation_Score_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Curation_Score_Var_Samp_Fields>;
  variance?: Maybe<Artist_Curation_Score_Variance_Fields>;
};


/** aggregate fields of "Artist_curation_score" */
export type Artist_Curation_Score_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Curation_Score_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Curation_Score_Avg_Fields = {
  __typename?: 'Artist_curation_score_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_curation_score". All fields are combined with a logical 'AND'. */
export type Artist_Curation_Score_Bool_Exp = {
  _and?: Maybe<Array<Artist_Curation_Score_Bool_Exp>>;
  _not?: Maybe<Artist_Curation_Score_Bool_Exp>;
  _or?: Maybe<Array<Artist_Curation_Score_Bool_Exp>>;
  artist_context?: Maybe<Context_Bool_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_listing?: Maybe<Artwork_Explore_Listing_Bool_Exp>;
  curator_context?: Maybe<Context_Bool_Exp>;
  curator_context_id?: Maybe<Bigint_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artist_curation_score" */
export enum Artist_Curation_Score_Constraint {
  /** unique or primary key constraint on columns "artist_context_id" */
  ArtistCurationScorePkey = 'Artist_curation_score_pkey'
}

/** input type for incrementing numeric columns in table "Artist_curation_score" */
export type Artist_Curation_Score_Inc_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artist_curation_score" */
export type Artist_Curation_Score_Insert_Input = {
  artist_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_listing?: Maybe<Artwork_Explore_Listing_Arr_Rel_Insert_Input>;
  curator_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Artist_Curation_Score_Max_Fields = {
  __typename?: 'Artist_curation_score_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Artist_Curation_Score_Min_Fields = {
  __typename?: 'Artist_curation_score_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Artist_curation_score" */
export type Artist_Curation_Score_Mutation_Response = {
  __typename?: 'Artist_curation_score_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist_Curation_Score>;
};

/** on_conflict condition type for table "Artist_curation_score" */
export type Artist_Curation_Score_On_Conflict = {
  constraint: Artist_Curation_Score_Constraint;
  update_columns?: Array<Artist_Curation_Score_Update_Column>;
  where?: Maybe<Artist_Curation_Score_Bool_Exp>;
};

/** Ordering options when selecting data from "Artist_curation_score". */
export type Artist_Curation_Score_Order_By = {
  artist_context?: Maybe<Context_Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_listing_aggregate?: Maybe<Artwork_Explore_Listing_Aggregate_Order_By>;
  curator_context?: Maybe<Context_Order_By>;
  curator_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
};

/** primary key columns input for table: Artist_curation_score */
export type Artist_Curation_Score_Pk_Columns_Input = {
  artist_context_id: Scalars['bigint'];
};

/** select columns of table "Artist_curation_score" */
export enum Artist_Curation_Score_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CuratorContextId = 'curator_context_id',
  /** column name */
  Score = 'score'
}

/** input type for updating data in table "Artist_curation_score" */
export type Artist_Curation_Score_Set_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Artist_Curation_Score_Stddev_Fields = {
  __typename?: 'Artist_curation_score_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Curation_Score_Stddev_Pop_Fields = {
  __typename?: 'Artist_curation_score_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Curation_Score_Stddev_Samp_Fields = {
  __typename?: 'Artist_curation_score_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Curation_Score_Sum_Fields = {
  __typename?: 'Artist_curation_score_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
};

/** update columns of table "Artist_curation_score" */
export enum Artist_Curation_Score_Update_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CuratorContextId = 'curator_context_id',
  /** column name */
  Score = 'score'
}

/** aggregate var_pop on columns */
export type Artist_Curation_Score_Var_Pop_Fields = {
  __typename?: 'Artist_curation_score_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Curation_Score_Var_Samp_Fields = {
  __typename?: 'Artist_curation_score_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Curation_Score_Variance_Fields = {
  __typename?: 'Artist_curation_score_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artist_explore_info" */
export type Artist_Explore_Info = {
  __typename?: 'Artist_explore_info';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  /** An array relationship */
  interests: Array<Artist_Explore_Interests>;
  /** An aggregate relationship */
  interests_aggregate: Artist_Explore_Interests_Aggregate;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  priceRanges: Array<Profile_Price_Ranges>;
  /** An aggregate relationship */
  priceRanges_aggregate: Profile_Price_Ranges_Aggregate;
  /** An array relationship */
  tempCollections: Array<Temp_Artist_Explore_Collection>;
  /** An aggregate relationship */
  tempCollections_aggregate: Temp_Artist_Explore_Collection_Aggregate;
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Artist_explore_info" */
export type Artist_Explore_InfoInterestsArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_info" */
export type Artist_Explore_InfoInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_info" */
export type Artist_Explore_InfoPriceRangesArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_info" */
export type Artist_Explore_InfoPriceRanges_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_info" */
export type Artist_Explore_InfoTempCollectionsArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_info" */
export type Artist_Explore_InfoTempCollections_AggregateArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};

/** aggregated selection of "Artist_explore_info" */
export type Artist_Explore_Info_Aggregate = {
  __typename?: 'Artist_explore_info_aggregate';
  aggregate?: Maybe<Artist_Explore_Info_Aggregate_Fields>;
  nodes: Array<Artist_Explore_Info>;
};

/** aggregate fields of "Artist_explore_info" */
export type Artist_Explore_Info_Aggregate_Fields = {
  __typename?: 'Artist_explore_info_aggregate_fields';
  avg?: Maybe<Artist_Explore_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Explore_Info_Max_Fields>;
  min?: Maybe<Artist_Explore_Info_Min_Fields>;
  stddev?: Maybe<Artist_Explore_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Explore_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Explore_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Explore_Info_Sum_Fields>;
  var_pop?: Maybe<Artist_Explore_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Explore_Info_Var_Samp_Fields>;
  variance?: Maybe<Artist_Explore_Info_Variance_Fields>;
};


/** aggregate fields of "Artist_explore_info" */
export type Artist_Explore_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Explore_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Explore_Info_Avg_Fields = {
  __typename?: 'Artist_explore_info_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_explore_info". All fields are combined with a logical 'AND'. */
export type Artist_Explore_Info_Bool_Exp = {
  _and?: Maybe<Array<Artist_Explore_Info_Bool_Exp>>;
  _not?: Maybe<Artist_Explore_Info_Bool_Exp>;
  _or?: Maybe<Array<Artist_Explore_Info_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_profile_id?: Maybe<Bigint_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  city_id?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  interests?: Maybe<Artist_Explore_Interests_Bool_Exp>;
  is_public?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  priceRanges?: Maybe<Profile_Price_Ranges_Bool_Exp>;
  tempCollections?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Artist_explore_info" */
export type Artist_Explore_Info_Insert_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  interests?: Maybe<Artist_Explore_Interests_Arr_Rel_Insert_Input>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  priceRanges?: Maybe<Profile_Price_Ranges_Arr_Rel_Insert_Input>;
  tempCollections?: Maybe<Temp_Artist_Explore_Collection_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Artist_Explore_Info_Max_Fields = {
  __typename?: 'Artist_explore_info_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artist_Explore_Info_Min_Fields = {
  __typename?: 'Artist_explore_info_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "Artist_explore_info" */
export type Artist_Explore_Info_Obj_Rel_Insert_Input = {
  data: Artist_Explore_Info_Insert_Input;
};

/** Ordering options when selecting data from "Artist_explore_info". */
export type Artist_Explore_Info_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  interests_aggregate?: Maybe<Artist_Explore_Interests_Aggregate_Order_By>;
  is_public?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  priceRanges_aggregate?: Maybe<Profile_Price_Ranges_Aggregate_Order_By>;
  tempCollections_aggregate?: Maybe<Temp_Artist_Explore_Collection_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Artist_explore_info" */
export enum Artist_Explore_Info_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistProfileId = 'artist_profile_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  CityId = 'city_id',
  /** column name */
  Country = 'country',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Handle = 'handle',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Artist_Explore_Info_Stddev_Fields = {
  __typename?: 'Artist_explore_info_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Explore_Info_Stddev_Pop_Fields = {
  __typename?: 'Artist_explore_info_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Explore_Info_Stddev_Samp_Fields = {
  __typename?: 'Artist_explore_info_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Explore_Info_Sum_Fields = {
  __typename?: 'Artist_explore_info_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artist_Explore_Info_Var_Pop_Fields = {
  __typename?: 'Artist_explore_info_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Explore_Info_Var_Samp_Fields = {
  __typename?: 'Artist_explore_info_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Explore_Info_Variance_Fields = {
  __typename?: 'Artist_explore_info_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artist_explore_interests" */
export type Artist_Explore_Interests = {
  __typename?: 'Artist_explore_interests';
  context_id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artist_explore_interests" */
export type Artist_Explore_Interests_Aggregate = {
  __typename?: 'Artist_explore_interests_aggregate';
  aggregate?: Maybe<Artist_Explore_Interests_Aggregate_Fields>;
  nodes: Array<Artist_Explore_Interests>;
};

/** aggregate fields of "Artist_explore_interests" */
export type Artist_Explore_Interests_Aggregate_Fields = {
  __typename?: 'Artist_explore_interests_aggregate_fields';
  avg?: Maybe<Artist_Explore_Interests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Explore_Interests_Max_Fields>;
  min?: Maybe<Artist_Explore_Interests_Min_Fields>;
  stddev?: Maybe<Artist_Explore_Interests_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Explore_Interests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Explore_Interests_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Explore_Interests_Sum_Fields>;
  var_pop?: Maybe<Artist_Explore_Interests_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Explore_Interests_Var_Samp_Fields>;
  variance?: Maybe<Artist_Explore_Interests_Variance_Fields>;
};


/** aggregate fields of "Artist_explore_interests" */
export type Artist_Explore_Interests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Aggregate_Order_By = {
  avg?: Maybe<Artist_Explore_Interests_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artist_Explore_Interests_Max_Order_By>;
  min?: Maybe<Artist_Explore_Interests_Min_Order_By>;
  stddev?: Maybe<Artist_Explore_Interests_Stddev_Order_By>;
  stddev_pop?: Maybe<Artist_Explore_Interests_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artist_Explore_Interests_Stddev_Samp_Order_By>;
  sum?: Maybe<Artist_Explore_Interests_Sum_Order_By>;
  var_pop?: Maybe<Artist_Explore_Interests_Var_Pop_Order_By>;
  var_samp?: Maybe<Artist_Explore_Interests_Var_Samp_Order_By>;
  variance?: Maybe<Artist_Explore_Interests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artist_explore_interests" */
export type Artist_Explore_Interests_Arr_Rel_Insert_Input = {
  data: Array<Artist_Explore_Interests_Insert_Input>;
};

/** aggregate avg on columns */
export type Artist_Explore_Interests_Avg_Fields = {
  __typename?: 'Artist_explore_interests_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artist_explore_interests". All fields are combined with a logical 'AND'. */
export type Artist_Explore_Interests_Bool_Exp = {
  _and?: Maybe<Array<Artist_Explore_Interests_Bool_Exp>>;
  _not?: Maybe<Artist_Explore_Interests_Bool_Exp>;
  _or?: Maybe<Array<Artist_Explore_Interests_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  interest_id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Artist_explore_interests" */
export type Artist_Explore_Interests_Insert_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Artist_Explore_Interests_Max_Fields = {
  __typename?: 'Artist_explore_interests_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artist_Explore_Interests_Min_Fields = {
  __typename?: 'Artist_explore_interests_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "Artist_explore_interests". */
export type Artist_Explore_Interests_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "Artist_explore_interests" */
export enum Artist_Explore_Interests_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  InterestId = 'interest_id',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Artist_Explore_Interests_Stddev_Fields = {
  __typename?: 'Artist_explore_interests_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artist_Explore_Interests_Stddev_Pop_Fields = {
  __typename?: 'Artist_explore_interests_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artist_Explore_Interests_Stddev_Samp_Fields = {
  __typename?: 'Artist_explore_interests_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artist_Explore_Interests_Sum_Fields = {
  __typename?: 'Artist_explore_interests_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Artist_Explore_Interests_Var_Pop_Fields = {
  __typename?: 'Artist_explore_interests_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artist_Explore_Interests_Var_Samp_Fields = {
  __typename?: 'Artist_explore_interests_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artist_Explore_Interests_Variance_Fields = {
  __typename?: 'Artist_explore_interests_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artist_explore_interests" */
export type Artist_Explore_Interests_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** columns and relationships of "Artist_explore_listing" */
export type Artist_Explore_Listing = {
  __typename?: 'Artist_explore_listing';
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  interests: Array<Artist_Explore_Interests>;
  /** An aggregate relationship */
  interests_aggregate: Artist_Explore_Interests_Aggregate;
  /** An array relationship */
  priceRanges: Array<Profile_Price_Ranges>;
  /** An aggregate relationship */
  priceRanges_aggregate: Profile_Price_Ranges_Aggregate;
  /** An array relationship */
  recognitions: Array<Social_Graph_Followers>;
  /** An aggregate relationship */
  recognitions_aggregate: Social_Graph_Followers_Aggregate;
  /** An array relationship */
  tempCollections: Array<Temp_Artist_Explore_Collection>;
  /** An aggregate relationship */
  tempCollections_aggregate: Temp_Artist_Explore_Collection_Aggregate;
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Artist_explore_listing" */
export type Artist_Explore_ListingInterestsArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing" */
export type Artist_Explore_ListingInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing" */
export type Artist_Explore_ListingPriceRangesArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing" */
export type Artist_Explore_ListingPriceRanges_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing" */
export type Artist_Explore_ListingRecognitionsArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing" */
export type Artist_Explore_ListingRecognitions_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing" */
export type Artist_Explore_ListingTempCollectionsArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing" */
export type Artist_Explore_ListingTempCollections_AggregateArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};

/** aggregated selection of "Artist_explore_listing" */
export type Artist_Explore_Listing_Aggregate = {
  __typename?: 'Artist_explore_listing_aggregate';
  aggregate?: Maybe<Artist_Explore_Listing_Aggregate_Fields>;
  nodes: Array<Artist_Explore_Listing>;
};

/** aggregate fields of "Artist_explore_listing" */
export type Artist_Explore_Listing_Aggregate_Fields = {
  __typename?: 'Artist_explore_listing_aggregate_fields';
  avg?: Maybe<Artist_Explore_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Explore_Listing_Max_Fields>;
  min?: Maybe<Artist_Explore_Listing_Min_Fields>;
  stddev?: Maybe<Artist_Explore_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Explore_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Explore_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Explore_Listing_Sum_Fields>;
  var_pop?: Maybe<Artist_Explore_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Explore_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artist_Explore_Listing_Variance_Fields>;
};


/** aggregate fields of "Artist_explore_listing" */
export type Artist_Explore_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Explore_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Explore_Listing_Avg_Fields = {
  __typename?: 'Artist_explore_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_explore_listing". All fields are combined with a logical 'AND'. */
export type Artist_Explore_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artist_Explore_Listing_Bool_Exp>>;
  _not?: Maybe<Artist_Explore_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artist_Explore_Listing_Bool_Exp>>;
  appear_at?: Maybe<Timestamptz_Comparison_Exp>;
  appear_reason?: Maybe<String_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_profile_id?: Maybe<Bigint_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  curation_order?: Maybe<Int_Comparison_Exp>;
  curation_score?: Maybe<Int_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  interests?: Maybe<Artist_Explore_Interests_Bool_Exp>;
  priceRanges?: Maybe<Profile_Price_Ranges_Bool_Exp>;
  recognitions?: Maybe<Social_Graph_Followers_Bool_Exp>;
  tempCollections?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artist_Explore_Listing_Max_Fields = {
  __typename?: 'Artist_explore_listing_max_fields';
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artist_Explore_Listing_Min_Fields = {
  __typename?: 'Artist_explore_listing_min_fields';
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artist_explore_listing". */
export type Artist_Explore_Listing_Order_By = {
  appear_at?: Maybe<Order_By>;
  appear_reason?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  curation_order?: Maybe<Order_By>;
  curation_score?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interests_aggregate?: Maybe<Artist_Explore_Interests_Aggregate_Order_By>;
  priceRanges_aggregate?: Maybe<Profile_Price_Ranges_Aggregate_Order_By>;
  recognitions_aggregate?: Maybe<Social_Graph_Followers_Aggregate_Order_By>;
  tempCollections_aggregate?: Maybe<Temp_Artist_Explore_Collection_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Artist_explore_listing" */
export enum Artist_Explore_Listing_Select_Column {
  /** column name */
  AppearAt = 'appear_at',
  /** column name */
  AppearReason = 'appear_reason',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistProfileId = 'artist_profile_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CurationOrder = 'curation_order',
  /** column name */
  CurationScore = 'curation_score',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Artist_Explore_Listing_Stddev_Fields = {
  __typename?: 'Artist_explore_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Explore_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artist_explore_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Explore_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artist_explore_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Explore_Listing_Sum_Fields = {
  __typename?: 'Artist_explore_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artist_Explore_Listing_Var_Pop_Fields = {
  __typename?: 'Artist_explore_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Explore_Listing_Var_Samp_Fields = {
  __typename?: 'Artist_explore_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Explore_Listing_Variance_Fields = {
  __typename?: 'Artist_explore_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_Data = {
  __typename?: 'Artist_explore_listing_with_city_data';
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  interests: Array<Artist_Explore_Interests>;
  /** An aggregate relationship */
  interests_aggregate: Artist_Explore_Interests_Aggregate;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  priceRanges: Array<Profile_Price_Ranges>;
  /** An aggregate relationship */
  priceRanges_aggregate: Profile_Price_Ranges_Aggregate;
  /** An array relationship */
  recognitions: Array<Social_Graph_Followers>;
  /** An aggregate relationship */
  recognitions_aggregate: Social_Graph_Followers_Aggregate;
  state_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  tempCollections: Array<Temp_Artist_Explore_Collection>;
  /** An aggregate relationship */
  tempCollections_aggregate: Temp_Artist_Explore_Collection_Aggregate;
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_DataInterestsArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_DataInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_DataPriceRangesArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_DataPriceRanges_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_DataRecognitionsArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_DataRecognitions_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_DataTempCollectionsArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};


/** columns and relationships of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_DataTempCollections_AggregateArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};

/** aggregated selection of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_Data_Aggregate = {
  __typename?: 'Artist_explore_listing_with_city_data_aggregate';
  aggregate?: Maybe<Artist_Explore_Listing_With_City_Data_Aggregate_Fields>;
  nodes: Array<Artist_Explore_Listing_With_City_Data>;
};

/** aggregate fields of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_Data_Aggregate_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_aggregate_fields';
  avg?: Maybe<Artist_Explore_Listing_With_City_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Explore_Listing_With_City_Data_Max_Fields>;
  min?: Maybe<Artist_Explore_Listing_With_City_Data_Min_Fields>;
  stddev?: Maybe<Artist_Explore_Listing_With_City_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Explore_Listing_With_City_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Explore_Listing_With_City_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Explore_Listing_With_City_Data_Sum_Fields>;
  var_pop?: Maybe<Artist_Explore_Listing_With_City_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Explore_Listing_With_City_Data_Var_Samp_Fields>;
  variance?: Maybe<Artist_Explore_Listing_With_City_Data_Variance_Fields>;
};


/** aggregate fields of "Artist_explore_listing_with_city_data" */
export type Artist_Explore_Listing_With_City_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Explore_Listing_With_City_Data_Avg_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_explore_listing_with_city_data". All fields are combined with a logical 'AND'. */
export type Artist_Explore_Listing_With_City_Data_Bool_Exp = {
  _and?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Bool_Exp>>;
  _not?: Maybe<Artist_Explore_Listing_With_City_Data_Bool_Exp>;
  _or?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Bool_Exp>>;
  appear_at?: Maybe<Timestamptz_Comparison_Exp>;
  appear_reason?: Maybe<String_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_profile_id?: Maybe<Bigint_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  city_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  curation_order?: Maybe<Int_Comparison_Exp>;
  curation_score?: Maybe<Int_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  interests?: Maybe<Artist_Explore_Interests_Bool_Exp>;
  lat?: Maybe<Numeric_Comparison_Exp>;
  lng?: Maybe<Numeric_Comparison_Exp>;
  priceRanges?: Maybe<Profile_Price_Ranges_Bool_Exp>;
  recognitions?: Maybe<Social_Graph_Followers_Bool_Exp>;
  state_code?: Maybe<String_Comparison_Exp>;
  tempCollections?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artist_Explore_Listing_With_City_Data_Max_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_max_fields';
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state_code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artist_Explore_Listing_With_City_Data_Min_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_min_fields';
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state_code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artist_explore_listing_with_city_data". */
export type Artist_Explore_Listing_With_City_Data_Order_By = {
  appear_at?: Maybe<Order_By>;
  appear_reason?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  curation_order?: Maybe<Order_By>;
  curation_score?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interests_aggregate?: Maybe<Artist_Explore_Interests_Aggregate_Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  priceRanges_aggregate?: Maybe<Profile_Price_Ranges_Aggregate_Order_By>;
  recognitions_aggregate?: Maybe<Social_Graph_Followers_Aggregate_Order_By>;
  state_code?: Maybe<Order_By>;
  tempCollections_aggregate?: Maybe<Temp_Artist_Explore_Collection_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Artist_explore_listing_with_city_data" */
export enum Artist_Explore_Listing_With_City_Data_Select_Column {
  /** column name */
  AppearAt = 'appear_at',
  /** column name */
  AppearReason = 'appear_reason',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistProfileId = 'artist_profile_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  CityId = 'city_id',
  /** column name */
  Country = 'country',
  /** column name */
  CurationOrder = 'curation_order',
  /** column name */
  CurationScore = 'curation_score',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  StateCode = 'state_code',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Artist_Explore_Listing_With_City_Data_Stddev_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Explore_Listing_With_City_Data_Stddev_Pop_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Explore_Listing_With_City_Data_Stddev_Samp_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Explore_Listing_With_City_Data_Sum_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city_id?: Maybe<Scalars['bigint']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Artist_Explore_Listing_With_City_Data_Var_Pop_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Explore_Listing_With_City_Data_Var_Samp_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Explore_Listing_With_City_Data_Variance_Fields = {
  __typename?: 'Artist_explore_listing_with_city_data_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** A table to track artist explore feed visibility */
export type Artist_Explore_Visibility = {
  __typename?: 'Artist_explore_visibility';
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason: Scalars['String'];
  artist_context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  disappear_at?: Maybe<Scalars['timestamptz']>;
  disappear_reason?: Maybe<Scalars['String']>;
  has_dismissed_message?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artist_explore_visibility" */
export type Artist_Explore_Visibility_Aggregate = {
  __typename?: 'Artist_explore_visibility_aggregate';
  aggregate?: Maybe<Artist_Explore_Visibility_Aggregate_Fields>;
  nodes: Array<Artist_Explore_Visibility>;
};

/** aggregate fields of "Artist_explore_visibility" */
export type Artist_Explore_Visibility_Aggregate_Fields = {
  __typename?: 'Artist_explore_visibility_aggregate_fields';
  avg?: Maybe<Artist_Explore_Visibility_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Explore_Visibility_Max_Fields>;
  min?: Maybe<Artist_Explore_Visibility_Min_Fields>;
  stddev?: Maybe<Artist_Explore_Visibility_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Explore_Visibility_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Explore_Visibility_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Explore_Visibility_Sum_Fields>;
  var_pop?: Maybe<Artist_Explore_Visibility_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Explore_Visibility_Var_Samp_Fields>;
  variance?: Maybe<Artist_Explore_Visibility_Variance_Fields>;
};


/** aggregate fields of "Artist_explore_visibility" */
export type Artist_Explore_Visibility_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Explore_Visibility_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Explore_Visibility_Avg_Fields = {
  __typename?: 'Artist_explore_visibility_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_explore_visibility". All fields are combined with a logical 'AND'. */
export type Artist_Explore_Visibility_Bool_Exp = {
  _and?: Maybe<Array<Artist_Explore_Visibility_Bool_Exp>>;
  _not?: Maybe<Artist_Explore_Visibility_Bool_Exp>;
  _or?: Maybe<Array<Artist_Explore_Visibility_Bool_Exp>>;
  appear_at?: Maybe<Timestamptz_Comparison_Exp>;
  appear_reason?: Maybe<String_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disappear_at?: Maybe<Timestamptz_Comparison_Exp>;
  disappear_reason?: Maybe<String_Comparison_Exp>;
  has_dismissed_message?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artist_explore_visibility" */
export enum Artist_Explore_Visibility_Constraint {
  /** unique or primary key constraint on columns "artist_context_id", "appear_reason" */
  ArtistExploreVisibilityPkey = 'Artist_explore_visibility_pkey',
  /** unique or primary key constraint on columns "artist_context_id" */
  IdxSingleExploreAppearance = 'idx_single_explore_appearance'
}

/** input type for incrementing numeric columns in table "Artist_explore_visibility" */
export type Artist_Explore_Visibility_Inc_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artist_explore_visibility" */
export type Artist_Explore_Visibility_Insert_Input = {
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappear_at?: Maybe<Scalars['timestamptz']>;
  disappear_reason?: Maybe<Scalars['String']>;
  has_dismissed_message?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artist_Explore_Visibility_Max_Fields = {
  __typename?: 'Artist_explore_visibility_max_fields';
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappear_at?: Maybe<Scalars['timestamptz']>;
  disappear_reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artist_Explore_Visibility_Min_Fields = {
  __typename?: 'Artist_explore_visibility_min_fields';
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappear_at?: Maybe<Scalars['timestamptz']>;
  disappear_reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Artist_explore_visibility" */
export type Artist_Explore_Visibility_Mutation_Response = {
  __typename?: 'Artist_explore_visibility_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist_Explore_Visibility>;
};

/** on_conflict condition type for table "Artist_explore_visibility" */
export type Artist_Explore_Visibility_On_Conflict = {
  constraint: Artist_Explore_Visibility_Constraint;
  update_columns?: Array<Artist_Explore_Visibility_Update_Column>;
  where?: Maybe<Artist_Explore_Visibility_Bool_Exp>;
};

/** Ordering options when selecting data from "Artist_explore_visibility". */
export type Artist_Explore_Visibility_Order_By = {
  appear_at?: Maybe<Order_By>;
  appear_reason?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  disappear_at?: Maybe<Order_By>;
  disappear_reason?: Maybe<Order_By>;
  has_dismissed_message?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artist_explore_visibility */
export type Artist_Explore_Visibility_Pk_Columns_Input = {
  appear_reason: Scalars['String'];
  artist_context_id: Scalars['bigint'];
};

/** select columns of table "Artist_explore_visibility" */
export enum Artist_Explore_Visibility_Select_Column {
  /** column name */
  AppearAt = 'appear_at',
  /** column name */
  AppearReason = 'appear_reason',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisappearAt = 'disappear_at',
  /** column name */
  DisappearReason = 'disappear_reason',
  /** column name */
  HasDismissedMessage = 'has_dismissed_message',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artist_explore_visibility" */
export type Artist_Explore_Visibility_Set_Input = {
  appear_at?: Maybe<Scalars['timestamptz']>;
  appear_reason?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappear_at?: Maybe<Scalars['timestamptz']>;
  disappear_reason?: Maybe<Scalars['String']>;
  has_dismissed_message?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artist_Explore_Visibility_Stddev_Fields = {
  __typename?: 'Artist_explore_visibility_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Explore_Visibility_Stddev_Pop_Fields = {
  __typename?: 'Artist_explore_visibility_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Explore_Visibility_Stddev_Samp_Fields = {
  __typename?: 'Artist_explore_visibility_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Explore_Visibility_Sum_Fields = {
  __typename?: 'Artist_explore_visibility_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Artist_explore_visibility" */
export enum Artist_Explore_Visibility_Update_Column {
  /** column name */
  AppearAt = 'appear_at',
  /** column name */
  AppearReason = 'appear_reason',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisappearAt = 'disappear_at',
  /** column name */
  DisappearReason = 'disappear_reason',
  /** column name */
  HasDismissedMessage = 'has_dismissed_message',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artist_Explore_Visibility_Var_Pop_Fields = {
  __typename?: 'Artist_explore_visibility_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Explore_Visibility_Var_Samp_Fields = {
  __typename?: 'Artist_explore_visibility_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Explore_Visibility_Variance_Fields = {
  __typename?: 'Artist_explore_visibility_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artist_links_filtered" */
export type Artist_Links_Filtered = {
  __typename?: 'Artist_links_filtered';
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artist_links_filtered" */
export type Artist_Links_Filtered_Aggregate = {
  __typename?: 'Artist_links_filtered_aggregate';
  aggregate?: Maybe<Artist_Links_Filtered_Aggregate_Fields>;
  nodes: Array<Artist_Links_Filtered>;
};

/** aggregate fields of "Artist_links_filtered" */
export type Artist_Links_Filtered_Aggregate_Fields = {
  __typename?: 'Artist_links_filtered_aggregate_fields';
  avg?: Maybe<Artist_Links_Filtered_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Links_Filtered_Max_Fields>;
  min?: Maybe<Artist_Links_Filtered_Min_Fields>;
  stddev?: Maybe<Artist_Links_Filtered_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Links_Filtered_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Links_Filtered_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Links_Filtered_Sum_Fields>;
  var_pop?: Maybe<Artist_Links_Filtered_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Links_Filtered_Var_Samp_Fields>;
  variance?: Maybe<Artist_Links_Filtered_Variance_Fields>;
};


/** aggregate fields of "Artist_links_filtered" */
export type Artist_Links_Filtered_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Links_Filtered_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Links_Filtered_Avg_Fields = {
  __typename?: 'Artist_links_filtered_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_links_filtered". All fields are combined with a logical 'AND'. */
export type Artist_Links_Filtered_Bool_Exp = {
  _and?: Maybe<Array<Artist_Links_Filtered_Bool_Exp>>;
  _not?: Maybe<Artist_Links_Filtered_Bool_Exp>;
  _or?: Maybe<Array<Artist_Links_Filtered_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  profile_card_url?: Maybe<String_Comparison_Exp>;
  profile_url?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "Artist_links_filtered" */
export type Artist_Links_Filtered_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artist_links_filtered" */
export type Artist_Links_Filtered_Insert_Input = {
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Artist_Links_Filtered_Max_Fields = {
  __typename?: 'Artist_links_filtered_max_fields';
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artist_Links_Filtered_Min_Fields = {
  __typename?: 'Artist_links_filtered_min_fields';
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Artist_links_filtered" */
export type Artist_Links_Filtered_Mutation_Response = {
  __typename?: 'Artist_links_filtered_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist_Links_Filtered>;
};

/** Ordering options when selecting data from "Artist_links_filtered". */
export type Artist_Links_Filtered_Order_By = {
  id?: Maybe<Order_By>;
  profile_card_url?: Maybe<Order_By>;
  profile_url?: Maybe<Order_By>;
};

/** select columns of table "Artist_links_filtered" */
export enum Artist_Links_Filtered_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProfileCardUrl = 'profile_card_url',
  /** column name */
  ProfileUrl = 'profile_url'
}

/** input type for updating data in table "Artist_links_filtered" */
export type Artist_Links_Filtered_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Artist_Links_Filtered_Stddev_Fields = {
  __typename?: 'Artist_links_filtered_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Links_Filtered_Stddev_Pop_Fields = {
  __typename?: 'Artist_links_filtered_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Links_Filtered_Stddev_Samp_Fields = {
  __typename?: 'Artist_links_filtered_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Links_Filtered_Sum_Fields = {
  __typename?: 'Artist_links_filtered_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artist_Links_Filtered_Var_Pop_Fields = {
  __typename?: 'Artist_links_filtered_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Links_Filtered_Var_Samp_Fields = {
  __typename?: 'Artist_links_filtered_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Links_Filtered_Variance_Fields = {
  __typename?: 'Artist_links_filtered_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artist_newsletter" */
export type Artist_Newsletter = {
  __typename?: 'Artist_newsletter';
  artist_context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  preferred_language: Enum_Language_Preference_Enum;
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artist_newsletter" */
export type Artist_Newsletter_Aggregate = {
  __typename?: 'Artist_newsletter_aggregate';
  aggregate?: Maybe<Artist_Newsletter_Aggregate_Fields>;
  nodes: Array<Artist_Newsletter>;
};

/** aggregate fields of "Artist_newsletter" */
export type Artist_Newsletter_Aggregate_Fields = {
  __typename?: 'Artist_newsletter_aggregate_fields';
  avg?: Maybe<Artist_Newsletter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Newsletter_Max_Fields>;
  min?: Maybe<Artist_Newsletter_Min_Fields>;
  stddev?: Maybe<Artist_Newsletter_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Newsletter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Newsletter_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Newsletter_Sum_Fields>;
  var_pop?: Maybe<Artist_Newsletter_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Newsletter_Var_Samp_Fields>;
  variance?: Maybe<Artist_Newsletter_Variance_Fields>;
};


/** aggregate fields of "Artist_newsletter" */
export type Artist_Newsletter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Newsletter_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artist_newsletter" */
export type Artist_Newsletter_Aggregate_Order_By = {
  avg?: Maybe<Artist_Newsletter_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artist_Newsletter_Max_Order_By>;
  min?: Maybe<Artist_Newsletter_Min_Order_By>;
  stddev?: Maybe<Artist_Newsletter_Stddev_Order_By>;
  stddev_pop?: Maybe<Artist_Newsletter_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artist_Newsletter_Stddev_Samp_Order_By>;
  sum?: Maybe<Artist_Newsletter_Sum_Order_By>;
  var_pop?: Maybe<Artist_Newsletter_Var_Pop_Order_By>;
  var_samp?: Maybe<Artist_Newsletter_Var_Samp_Order_By>;
  variance?: Maybe<Artist_Newsletter_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artist_newsletter" */
export type Artist_Newsletter_Arr_Rel_Insert_Input = {
  data: Array<Artist_Newsletter_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artist_Newsletter_On_Conflict>;
};

/** aggregate avg on columns */
export type Artist_Newsletter_Avg_Fields = {
  __typename?: 'Artist_newsletter_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Avg_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artist_newsletter". All fields are combined with a logical 'AND'. */
export type Artist_Newsletter_Bool_Exp = {
  _and?: Maybe<Array<Artist_Newsletter_Bool_Exp>>;
  _not?: Maybe<Artist_Newsletter_Bool_Exp>;
  _or?: Maybe<Array<Artist_Newsletter_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artist_newsletter" */
export enum Artist_Newsletter_Constraint {
  /** unique or primary key constraint on columns "artist_context_id", "email" */
  ArtistNewsletterArtistContextIdEmailKey = 'Artist_newsletter_artist_context_id_email_key',
  /** unique or primary key constraint on columns "id" */
  ArtistNewsletterPkey = 'Artist_newsletter_pkey'
}

/** input type for incrementing numeric columns in table "Artist_newsletter" */
export type Artist_Newsletter_Inc_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artist_newsletter" */
export type Artist_Newsletter_Insert_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artist_Newsletter_Max_Fields = {
  __typename?: 'Artist_newsletter_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Max_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artist_Newsletter_Min_Fields = {
  __typename?: 'Artist_newsletter_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Min_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artist_newsletter" */
export type Artist_Newsletter_Mutation_Response = {
  __typename?: 'Artist_newsletter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist_Newsletter>;
};

/** on_conflict condition type for table "Artist_newsletter" */
export type Artist_Newsletter_On_Conflict = {
  constraint: Artist_Newsletter_Constraint;
  update_columns?: Array<Artist_Newsletter_Update_Column>;
  where?: Maybe<Artist_Newsletter_Bool_Exp>;
};

/** Ordering options when selecting data from "Artist_newsletter". */
export type Artist_Newsletter_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artist_newsletter */
export type Artist_Newsletter_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Artist_newsletter" */
export enum Artist_Newsletter_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artist_newsletter" */
export type Artist_Newsletter_Set_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artist_Newsletter_Stddev_Fields = {
  __typename?: 'Artist_newsletter_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Stddev_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artist_Newsletter_Stddev_Pop_Fields = {
  __typename?: 'Artist_newsletter_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Stddev_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artist_Newsletter_Stddev_Samp_Fields = {
  __typename?: 'Artist_newsletter_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Stddev_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artist_Newsletter_Sum_Fields = {
  __typename?: 'Artist_newsletter_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Sum_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Artist_newsletter" */
export enum Artist_Newsletter_Update_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artist_Newsletter_Var_Pop_Fields = {
  __typename?: 'Artist_newsletter_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Var_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artist_Newsletter_Var_Samp_Fields = {
  __typename?: 'Artist_newsletter_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Var_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artist_Newsletter_Variance_Fields = {
  __typename?: 'Artist_newsletter_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artist_newsletter" */
export type Artist_Newsletter_Variance_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "Artist_popular_discipline" */
export type Artist_Popular_Discipline = {
  __typename?: 'Artist_popular_discipline';
  artist_context_id?: Maybe<Scalars['bigint']>;
  counter?: Maybe<Scalars['bigint']>;
  discipline?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artist_popular_discipline" */
export type Artist_Popular_Discipline_Aggregate = {
  __typename?: 'Artist_popular_discipline_aggregate';
  aggregate?: Maybe<Artist_Popular_Discipline_Aggregate_Fields>;
  nodes: Array<Artist_Popular_Discipline>;
};

/** aggregate fields of "Artist_popular_discipline" */
export type Artist_Popular_Discipline_Aggregate_Fields = {
  __typename?: 'Artist_popular_discipline_aggregate_fields';
  avg?: Maybe<Artist_Popular_Discipline_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Popular_Discipline_Max_Fields>;
  min?: Maybe<Artist_Popular_Discipline_Min_Fields>;
  stddev?: Maybe<Artist_Popular_Discipline_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Popular_Discipline_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Popular_Discipline_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Popular_Discipline_Sum_Fields>;
  var_pop?: Maybe<Artist_Popular_Discipline_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Popular_Discipline_Var_Samp_Fields>;
  variance?: Maybe<Artist_Popular_Discipline_Variance_Fields>;
};


/** aggregate fields of "Artist_popular_discipline" */
export type Artist_Popular_Discipline_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Popular_Discipline_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Popular_Discipline_Avg_Fields = {
  __typename?: 'Artist_popular_discipline_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  counter?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_popular_discipline". All fields are combined with a logical 'AND'. */
export type Artist_Popular_Discipline_Bool_Exp = {
  _and?: Maybe<Array<Artist_Popular_Discipline_Bool_Exp>>;
  _not?: Maybe<Artist_Popular_Discipline_Bool_Exp>;
  _or?: Maybe<Array<Artist_Popular_Discipline_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  counter?: Maybe<Bigint_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artist_Popular_Discipline_Max_Fields = {
  __typename?: 'Artist_popular_discipline_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  counter?: Maybe<Scalars['bigint']>;
  discipline?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artist_Popular_Discipline_Min_Fields = {
  __typename?: 'Artist_popular_discipline_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  counter?: Maybe<Scalars['bigint']>;
  discipline?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artist_popular_discipline". */
export type Artist_Popular_Discipline_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  counter?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
};

/** select columns of table "Artist_popular_discipline" */
export enum Artist_Popular_Discipline_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  Counter = 'counter',
  /** column name */
  Discipline = 'discipline'
}

/** aggregate stddev on columns */
export type Artist_Popular_Discipline_Stddev_Fields = {
  __typename?: 'Artist_popular_discipline_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Popular_Discipline_Stddev_Pop_Fields = {
  __typename?: 'Artist_popular_discipline_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Popular_Discipline_Stddev_Samp_Fields = {
  __typename?: 'Artist_popular_discipline_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Popular_Discipline_Sum_Fields = {
  __typename?: 'Artist_popular_discipline_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  counter?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artist_Popular_Discipline_Var_Pop_Fields = {
  __typename?: 'Artist_popular_discipline_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Popular_Discipline_Var_Samp_Fields = {
  __typename?: 'Artist_popular_discipline_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Popular_Discipline_Variance_Fields = {
  __typename?: 'Artist_popular_discipline_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  counter?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artist_preview_artworks" */
export type Artist_Preview_Artworks = {
  __typename?: 'Artist_preview_artworks';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "Artist_preview_artworks" */
export type Artist_Preview_Artworks_Aggregate = {
  __typename?: 'Artist_preview_artworks_aggregate';
  aggregate?: Maybe<Artist_Preview_Artworks_Aggregate_Fields>;
  nodes: Array<Artist_Preview_Artworks>;
};

/** aggregate fields of "Artist_preview_artworks" */
export type Artist_Preview_Artworks_Aggregate_Fields = {
  __typename?: 'Artist_preview_artworks_aggregate_fields';
  avg?: Maybe<Artist_Preview_Artworks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Preview_Artworks_Max_Fields>;
  min?: Maybe<Artist_Preview_Artworks_Min_Fields>;
  stddev?: Maybe<Artist_Preview_Artworks_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Preview_Artworks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Preview_Artworks_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Preview_Artworks_Sum_Fields>;
  var_pop?: Maybe<Artist_Preview_Artworks_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Preview_Artworks_Var_Samp_Fields>;
  variance?: Maybe<Artist_Preview_Artworks_Variance_Fields>;
};


/** aggregate fields of "Artist_preview_artworks" */
export type Artist_Preview_Artworks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Preview_Artworks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Preview_Artworks_Avg_Fields = {
  __typename?: 'Artist_preview_artworks_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_preview_artworks". All fields are combined with a logical 'AND'. */
export type Artist_Preview_Artworks_Bool_Exp = {
  _and?: Maybe<Array<Artist_Preview_Artworks_Bool_Exp>>;
  _not?: Maybe<Artist_Preview_Artworks_Bool_Exp>;
  _or?: Maybe<Array<Artist_Preview_Artworks_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  rank?: Maybe<Bigint_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artist_Preview_Artworks_Max_Fields = {
  __typename?: 'Artist_preview_artworks_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Artist_Preview_Artworks_Min_Fields = {
  __typename?: 'Artist_preview_artworks_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "Artist_preview_artworks". */
export type Artist_Preview_Artworks_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** select columns of table "Artist_preview_artworks" */
export enum Artist_Preview_Artworks_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rank = 'rank',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id'
}

/** aggregate stddev on columns */
export type Artist_Preview_Artworks_Stddev_Fields = {
  __typename?: 'Artist_preview_artworks_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Preview_Artworks_Stddev_Pop_Fields = {
  __typename?: 'Artist_preview_artworks_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Preview_Artworks_Stddev_Samp_Fields = {
  __typename?: 'Artist_preview_artworks_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Preview_Artworks_Sum_Fields = {
  __typename?: 'Artist_preview_artworks_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artist_Preview_Artworks_Var_Pop_Fields = {
  __typename?: 'Artist_preview_artworks_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Preview_Artworks_Var_Samp_Fields = {
  __typename?: 'Artist_preview_artworks_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Preview_Artworks_Variance_Fields = {
  __typename?: 'Artist_preview_artworks_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artist_vision_listing" */
export type Artist_Vision_Listing = {
  __typename?: 'Artist_vision_listing';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  artist_collections: Array<Artist_Collection_To_Artist_Context>;
  /** An aggregate relationship */
  artist_collections_aggregate: Artist_Collection_To_Artist_Context_Aggregate;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  /** An object relationship */
  featured_artwork?: Maybe<Artwork>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  featured_artworks: Array<Artist_Cardgrid_Artworks_View>;
  /** An aggregate relationship */
  featured_artworks_aggregate: Artist_Cardgrid_Artworks_View_Aggregate;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  interests: Array<Profile_Interests>;
  /** An aggregate relationship */
  interests_aggregate: Profile_Interests_Aggregate;
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Artist_vision_listing" */
export type Artist_Vision_ListingArtist_CollectionsArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


/** columns and relationships of "Artist_vision_listing" */
export type Artist_Vision_ListingArtist_Collections_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


/** columns and relationships of "Artist_vision_listing" */
export type Artist_Vision_ListingFeatured_ArtworksArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


/** columns and relationships of "Artist_vision_listing" */
export type Artist_Vision_ListingFeatured_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


/** columns and relationships of "Artist_vision_listing" */
export type Artist_Vision_ListingInterestsArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_Order_By>>;
  where?: Maybe<Profile_Interests_Bool_Exp>;
};


/** columns and relationships of "Artist_vision_listing" */
export type Artist_Vision_ListingInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_Order_By>>;
  where?: Maybe<Profile_Interests_Bool_Exp>;
};

/** aggregated selection of "Artist_vision_listing" */
export type Artist_Vision_Listing_Aggregate = {
  __typename?: 'Artist_vision_listing_aggregate';
  aggregate?: Maybe<Artist_Vision_Listing_Aggregate_Fields>;
  nodes: Array<Artist_Vision_Listing>;
};

/** aggregate fields of "Artist_vision_listing" */
export type Artist_Vision_Listing_Aggregate_Fields = {
  __typename?: 'Artist_vision_listing_aggregate_fields';
  avg?: Maybe<Artist_Vision_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Vision_Listing_Max_Fields>;
  min?: Maybe<Artist_Vision_Listing_Min_Fields>;
  stddev?: Maybe<Artist_Vision_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Vision_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Vision_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Vision_Listing_Sum_Fields>;
  var_pop?: Maybe<Artist_Vision_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Vision_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artist_Vision_Listing_Variance_Fields>;
};


/** aggregate fields of "Artist_vision_listing" */
export type Artist_Vision_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Vision_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Vision_Listing_Avg_Fields = {
  __typename?: 'Artist_vision_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_vision_listing". All fields are combined with a logical 'AND'. */
export type Artist_Vision_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artist_Vision_Listing_Bool_Exp>>;
  _not?: Maybe<Artist_Vision_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artist_Vision_Listing_Bool_Exp>>;
  appeared_at?: Maybe<Timestamptz_Comparison_Exp>;
  artist_collections?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_profile_id?: Maybe<Bigint_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  curation_order?: Maybe<Int_Comparison_Exp>;
  curation_score?: Maybe<Int_Comparison_Exp>;
  featured_artwork?: Maybe<Artwork_Bool_Exp>;
  featured_artwork_id?: Maybe<Bigint_Comparison_Exp>;
  featured_artworks?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  interests?: Maybe<Profile_Interests_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artist_Vision_Listing_Max_Fields = {
  __typename?: 'Artist_vision_listing_max_fields';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artist_Vision_Listing_Min_Fields = {
  __typename?: 'Artist_vision_listing_min_fields';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artist_vision_listing". */
export type Artist_Vision_Listing_Order_By = {
  appeared_at?: Maybe<Order_By>;
  artist_collections_aggregate?: Maybe<Artist_Collection_To_Artist_Context_Aggregate_Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  curation_order?: Maybe<Order_By>;
  curation_score?: Maybe<Order_By>;
  featured_artwork?: Maybe<Artwork_Order_By>;
  featured_artwork_id?: Maybe<Order_By>;
  featured_artworks_aggregate?: Maybe<Artist_Cardgrid_Artworks_View_Aggregate_Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interests_aggregate?: Maybe<Profile_Interests_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Artist_vision_listing" */
export enum Artist_Vision_Listing_Select_Column {
  /** column name */
  AppearedAt = 'appeared_at',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistProfileId = 'artist_profile_id',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CurationOrder = 'curation_order',
  /** column name */
  CurationScore = 'curation_score',
  /** column name */
  FeaturedArtworkId = 'featured_artwork_id',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Artist_Vision_Listing_Stddev_Fields = {
  __typename?: 'Artist_vision_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Vision_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artist_vision_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Vision_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artist_vision_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Vision_Listing_Sum_Fields = {
  __typename?: 'Artist_vision_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  curation_order?: Maybe<Scalars['Int']>;
  curation_score?: Maybe<Scalars['Int']>;
  featured_artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artist_Vision_Listing_Var_Pop_Fields = {
  __typename?: 'Artist_vision_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Vision_Listing_Var_Samp_Fields = {
  __typename?: 'Artist_vision_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Vision_Listing_Variance_Fields = {
  __typename?: 'Artist_vision_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  curation_order?: Maybe<Scalars['Float']>;
  curation_score?: Maybe<Scalars['Float']>;
  featured_artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Artists that have been whitelisted to show up in the Vision app */
export type Artist_Vision_Visibility = {
  __typename?: 'Artist_vision_visibility';
  /** An array relationship */
  access: Array<Flat_Context_Access>;
  /** An aggregate relationship */
  access_aggregate: Flat_Context_Access_Aggregate;
  appeared_at: Scalars['timestamptz'];
  artist_context_id: Scalars['bigint'];
  /** An object relationship */
  context: Context;
  created_at: Scalars['timestamptz'];
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};


/** Artists that have been whitelisted to show up in the Vision app */
export type Artist_Vision_VisibilityAccessArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


/** Artists that have been whitelisted to show up in the Vision app */
export type Artist_Vision_VisibilityAccess_AggregateArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};

/** aggregated selection of "Artist_vision_visibility" */
export type Artist_Vision_Visibility_Aggregate = {
  __typename?: 'Artist_vision_visibility_aggregate';
  aggregate?: Maybe<Artist_Vision_Visibility_Aggregate_Fields>;
  nodes: Array<Artist_Vision_Visibility>;
};

/** aggregate fields of "Artist_vision_visibility" */
export type Artist_Vision_Visibility_Aggregate_Fields = {
  __typename?: 'Artist_vision_visibility_aggregate_fields';
  avg?: Maybe<Artist_Vision_Visibility_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Vision_Visibility_Max_Fields>;
  min?: Maybe<Artist_Vision_Visibility_Min_Fields>;
  stddev?: Maybe<Artist_Vision_Visibility_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Vision_Visibility_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Vision_Visibility_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Vision_Visibility_Sum_Fields>;
  var_pop?: Maybe<Artist_Vision_Visibility_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Vision_Visibility_Var_Samp_Fields>;
  variance?: Maybe<Artist_Vision_Visibility_Variance_Fields>;
};


/** aggregate fields of "Artist_vision_visibility" */
export type Artist_Vision_Visibility_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Vision_Visibility_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artist_Vision_Visibility_Avg_Fields = {
  __typename?: 'Artist_vision_visibility_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artist_vision_visibility". All fields are combined with a logical 'AND'. */
export type Artist_Vision_Visibility_Bool_Exp = {
  _and?: Maybe<Array<Artist_Vision_Visibility_Bool_Exp>>;
  _not?: Maybe<Artist_Vision_Visibility_Bool_Exp>;
  _or?: Maybe<Array<Artist_Vision_Visibility_Bool_Exp>>;
  access?: Maybe<Flat_Context_Access_Bool_Exp>;
  appeared_at?: Maybe<Timestamptz_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disappeared_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artist_vision_visibility" */
export enum Artist_Vision_Visibility_Constraint {
  /** unique or primary key constraint on columns "artist_context_id" */
  ArtistVisionVisibilityArtistContextIdKey = 'Artist_vision_visibility_artist_context_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtistVisionVisibilityPkey = 'Artist_vision_visibility_pkey',
  /** unique or primary key constraint on columns "artist_context_id" */
  ArtistVisionVisibilityNullDisappear = 'artist_vision_visibility_null_disappear'
}

/** input type for incrementing numeric columns in table "Artist_vision_visibility" */
export type Artist_Vision_Visibility_Inc_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artist_vision_visibility" */
export type Artist_Vision_Visibility_Insert_Input = {
  access?: Maybe<Flat_Context_Access_Arr_Rel_Insert_Input>;
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artist_Vision_Visibility_Max_Fields = {
  __typename?: 'Artist_vision_visibility_max_fields';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artist_Vision_Visibility_Min_Fields = {
  __typename?: 'Artist_vision_visibility_min_fields';
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Artist_vision_visibility" */
export type Artist_Vision_Visibility_Mutation_Response = {
  __typename?: 'Artist_vision_visibility_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artist_Vision_Visibility>;
};

/** on_conflict condition type for table "Artist_vision_visibility" */
export type Artist_Vision_Visibility_On_Conflict = {
  constraint: Artist_Vision_Visibility_Constraint;
  update_columns?: Array<Artist_Vision_Visibility_Update_Column>;
  where?: Maybe<Artist_Vision_Visibility_Bool_Exp>;
};

/** Ordering options when selecting data from "Artist_vision_visibility". */
export type Artist_Vision_Visibility_Order_By = {
  access_aggregate?: Maybe<Flat_Context_Access_Aggregate_Order_By>;
  appeared_at?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  disappeared_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artist_vision_visibility */
export type Artist_Vision_Visibility_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artist_vision_visibility" */
export enum Artist_Vision_Visibility_Select_Column {
  /** column name */
  AppearedAt = 'appeared_at',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisappearedAt = 'disappeared_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artist_vision_visibility" */
export type Artist_Vision_Visibility_Set_Input = {
  appeared_at?: Maybe<Scalars['timestamptz']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disappeared_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artist_Vision_Visibility_Stddev_Fields = {
  __typename?: 'Artist_vision_visibility_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artist_Vision_Visibility_Stddev_Pop_Fields = {
  __typename?: 'Artist_vision_visibility_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artist_Vision_Visibility_Stddev_Samp_Fields = {
  __typename?: 'Artist_vision_visibility_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artist_Vision_Visibility_Sum_Fields = {
  __typename?: 'Artist_vision_visibility_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Artist_vision_visibility" */
export enum Artist_Vision_Visibility_Update_Column {
  /** column name */
  AppearedAt = 'appeared_at',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisappearedAt = 'disappeared_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artist_Vision_Visibility_Var_Pop_Fields = {
  __typename?: 'Artist_vision_visibility_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artist_Vision_Visibility_Var_Samp_Fields = {
  __typename?: 'Artist_vision_visibility_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artist_Vision_Visibility_Variance_Fields = {
  __typename?: 'Artist_vision_visibility_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Stores the artworks owned by the users */
export type Artwork = {
  __typename?: 'Artwork';
  /** An array relationship */
  Artwork_Art_Universe_Listing: Array<Artwork_Art_Universe_Listing>;
  /** An aggregate relationship */
  Artwork_Art_Universe_Listing_aggregate: Artwork_Art_Universe_Listing_Aggregate;
  /** An array relationship */
  Artwork_ai_tags: Array<Artwork_Ai_Tags>;
  /** An aggregate relationship */
  Artwork_ai_tags_aggregate: Artwork_Ai_Tags_Aggregate;
  /** An object relationship */
  Artwork_artist_profile_listing?: Maybe<Artwork_Artist_Profile_Listing>;
  /** An array relationship */
  Artwork_events: Array<Artwork_Events>;
  /** An aggregate relationship */
  Artwork_events_aggregate: Artwork_Events_Aggregate;
  /** An array relationship */
  Artwork_prices: Array<Artwork_Prices>;
  /** An aggregate relationship */
  Artwork_prices_aggregate: Artwork_Prices_Aggregate;
  /** An array relationship */
  Artwork_state_histories: Array<Artwork_State_History>;
  /** An aggregate relationship */
  Artwork_state_histories_aggregate: Artwork_State_History_Aggregate;
  /** An object relationship */
  Artwork_to_favorite_count?: Maybe<Artwork_Favorite_Count>;
  /** An object relationship */
  ENUM_add_artwork_flow_step: Enum_Add_Artwork_Flow_Step;
  /** An object relationship */
  ENUM_artwork_edition_type?: Maybe<Enum_Artwork_Edition_Type>;
  /** An array relationship */
  Editions: Array<Edition>;
  /** An aggregate relationship */
  Editions_aggregate: Edition_Aggregate;
  /** An array relationship */
  Print_amounts: Array<Print_Amounts>;
  /** An aggregate relationship */
  Print_amounts_aggregate: Print_Amounts_Aggregate;
  add_artwork_flow_step: Enum_Add_Artwork_Flow_Step_Enum;
  /** An array relationship */
  art_prizes: Array<Art_Prize_Artwork>;
  /** An aggregate relationship */
  art_prizes_aggregate: Art_Prize_Artwork_Aggregate;
  /** An object relationship */
  artist_context: Context;
  artist_context_id: Scalars['bigint'];
  artwork_artist_index: Scalars['Int'];
  /** An object relationship */
  artwork_details?: Maybe<Artwork_Details>;
  artwork_details_id: Scalars['bigint'];
  /** An array relationship */
  artwork_secondary_media: Array<Artwork_Secondary_Media>;
  /** An aggregate relationship */
  artwork_secondary_media_aggregate: Artwork_Secondary_Media_Aggregate;
  /** An object relationship */
  artwork_story?: Maybe<Artwork_Story>;
  artwork_story_id: Scalars['bigint'];
  artwork_thumbnail_download_link?: Maybe<Scalars['String']>;
  /** An array relationship */
  artwork_to_collections: Array<Artwork_To_Collection>;
  /** An aggregate relationship */
  artwork_to_collections_aggregate: Artwork_To_Collection_Aggregate;
  /** An array relationship */
  artwork_to_tags: Array<Artwork_To_Tag>;
  /** An aggregate relationship */
  artwork_to_tags_aggregate: Artwork_To_Tag_Aggregate;
  /** An object relationship */
  certificate?: Maybe<Certificate_Of_Authenticity>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  crop_area?: Maybe<Crop_Area>;
  crop_area_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edition_type?: Maybe<Enum_Artwork_Edition_Type_Enum>;
  /** An object relationship */
  gallery_context?: Maybe<Context>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  has_ar_animation: Scalars['Boolean'];
  has_video: Scalars['Boolean'];
  id: Scalars['bigint'];
  is_available_sale: Scalars['Boolean'];
  is_hard_minted?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  last_updater?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  links?: Maybe<Artwork_Links>;
  listed_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  original_image_metadata?: Maybe<File_Metadata>;
  original_image_metadata_id?: Maybe<Scalars['bigint']>;
  /** Pending vuforia image path generated by forge to control vuforia process start */
  pending_vuforia_image_path?: Maybe<Scalars['String']>;
  primary_image_download_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  primary_image_metadata?: Maybe<File_Metadata>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  purchase_inquires: Array<Purchase_Inquires>;
  /** An aggregate relationship */
  purchase_inquires_aggregate: Purchase_Inquires_Aggregate;
  /** An array relationship */
  purchase_inquires_outside_users: Array<Purchase_Inquires_Outside_Users>;
  /** An aggregate relationship */
  purchase_inquires_outside_users_aggregate: Purchase_Inquires_Outside_Users_Aggregate;
  slug?: Maybe<Scalars['String']>;
  status: Enum_Artwork_Status_Enum;
  target_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  thumbnail_metadata?: Maybe<File_Metadata>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  transaction_histories: Array<Artwork_Transactions>;
  /** An aggregate relationship */
  transaction_histories_aggregate: Artwork_Transactions_Aggregate;
  updated_at: Scalars['timestamptz'];
  vuforia_rating?: Maybe<Scalars['Int']>;
  /** An array relationship */
  vumarks: Array<Vumarks>;
  /** An aggregate relationship */
  vumarks_aggregate: Vumarks_Aggregate;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_Art_Universe_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Art_Universe_Listing_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_Art_Universe_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Art_Universe_Listing_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_Ai_TagsArgs = {
  distinct_on?: Maybe<Array<Artwork_Ai_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Ai_Tags_Order_By>>;
  where?: Maybe<Artwork_Ai_Tags_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_Ai_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Ai_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Ai_Tags_Order_By>>;
  where?: Maybe<Artwork_Ai_Tags_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_EventsArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_PricesArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_Prices_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_State_HistoriesArgs = {
  distinct_on?: Maybe<Array<Artwork_State_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_State_History_Order_By>>;
  where?: Maybe<Artwork_State_History_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_State_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_State_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_State_History_Order_By>>;
  where?: Maybe<Artwork_State_History_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkEditionsArgs = {
  distinct_on?: Maybe<Array<Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Order_By>>;
  where?: Maybe<Edition_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkEditions_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Order_By>>;
  where?: Maybe<Edition_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkPrint_AmountsArgs = {
  distinct_on?: Maybe<Array<Print_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Amounts_Order_By>>;
  where?: Maybe<Print_Amounts_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkPrint_Amounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Amounts_Order_By>>;
  where?: Maybe<Print_Amounts_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArt_PrizesArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArt_Prizes_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_Secondary_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_Secondary_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_To_CollectionsArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_To_Collections_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_To_TagsArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Tag_Order_By>>;
  where?: Maybe<Artwork_To_Tag_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkArtwork_To_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Tag_Order_By>>;
  where?: Maybe<Artwork_To_Tag_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkPurchase_InquiresArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkPurchase_Inquires_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkPurchase_Inquires_Outside_UsersArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkPurchase_Inquires_Outside_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkTransaction_HistoriesArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkTransaction_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkVumarksArgs = {
  distinct_on?: Maybe<Array<Vumarks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vumarks_Order_By>>;
  where?: Maybe<Vumarks_Bool_Exp>;
};


/** Stores the artworks owned by the users */
export type ArtworkVumarks_AggregateArgs = {
  distinct_on?: Maybe<Array<Vumarks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vumarks_Order_By>>;
  where?: Maybe<Vumarks_Bool_Exp>;
};

export enum ArtworkAvailabilityConfirmationEnum {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export type ArtworkPrice = {
  __typename?: 'ArtworkPrice';
  currency?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing = {
  __typename?: 'Artwork_Art_Universe_Listing';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_title?: Maybe<Scalars['String']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  discipline_other?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  interest_id?: Maybe<Scalars['bigint']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Aggregate = {
  __typename?: 'Artwork_Art_Universe_Listing_aggregate';
  aggregate?: Maybe<Artwork_Art_Universe_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Art_Universe_Listing>;
};

/** aggregate fields of "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_aggregate_fields';
  avg?: Maybe<Artwork_Art_Universe_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Art_Universe_Listing_Max_Fields>;
  min?: Maybe<Artwork_Art_Universe_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Art_Universe_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Art_Universe_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Art_Universe_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Art_Universe_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Art_Universe_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Art_Universe_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Art_Universe_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Art_Universe_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Art_Universe_Listing_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Art_Universe_Listing_Max_Order_By>;
  min?: Maybe<Artwork_Art_Universe_Listing_Min_Order_By>;
  stddev?: Maybe<Artwork_Art_Universe_Listing_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Art_Universe_Listing_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Art_Universe_Listing_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Art_Universe_Listing_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Art_Universe_Listing_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Art_Universe_Listing_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Art_Universe_Listing_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Art_Universe_Listing_Insert_Input>;
};

/** aggregate avg on columns */
export type Artwork_Art_Universe_Listing_Avg_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Avg_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_Art_Universe_Listing". All fields are combined with a logical 'AND'. */
export type Artwork_Art_Universe_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Art_Universe_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Art_Universe_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Art_Universe_Listing_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_profile_id?: Maybe<Bigint_Comparison_Exp>;
  artist_title?: Maybe<String_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_title?: Maybe<String_Comparison_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  discipline_other?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  interest_id?: Maybe<Bigint_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_landscape?: Maybe<Boolean_Comparison_Exp>;
  is_portrait?: Maybe<Boolean_Comparison_Exp>;
  is_square?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** input type for inserting data into table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Insert_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_title?: Maybe<Scalars['String']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  discipline_other?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  interest_id?: Maybe<Scalars['bigint']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Artwork_Art_Universe_Listing_Max_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_title?: Maybe<Scalars['String']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  discipline_other?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  interest_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Max_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artist_title?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_title?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  discipline_other?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Art_Universe_Listing_Min_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_title?: Maybe<Scalars['String']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  discipline_other?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  interest_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Min_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artist_title?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_title?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  discipline_other?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "Artwork_Art_Universe_Listing". */
export type Artwork_Art_Universe_Listing_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artist_title?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_title?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  discipline_other?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_landscape?: Maybe<Order_By>;
  is_portrait?: Maybe<Order_By>;
  is_square?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Artwork_Art_Universe_Listing" */
export enum Artwork_Art_Universe_Listing_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistProfileId = 'artist_profile_id',
  /** column name */
  ArtistTitle = 'artist_title',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkTitle = 'artwork_title',
  /** column name */
  AspectRatio = 'aspect_ratio',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Currency = 'currency',
  /** column name */
  Depth = 'depth',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  DisciplineOther = 'discipline_other',
  /** column name */
  Height = 'height',
  /** column name */
  InterestId = 'interest_id',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsPortrait = 'is_portrait',
  /** column name */
  IsSquare = 'is_square',
  /** column name */
  IsUnavailable = 'is_unavailable',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Type = 'type',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Art_Universe_Listing_Stddev_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Stddev_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Art_Universe_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Stddev_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Art_Universe_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Stddev_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Art_Universe_Listing_Sum_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_profile_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  height?: Maybe<Scalars['float8']>;
  interest_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  width?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Sum_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Artwork_Art_Universe_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Var_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Art_Universe_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Var_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Art_Universe_Listing_Variance_Fields = {
  __typename?: 'Artwork_Art_Universe_Listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_profile_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_Art_Universe_Listing" */
export type Artwork_Art_Universe_Listing_Variance_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_profile_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregated selection of "Artwork" */
export type Artwork_Aggregate = {
  __typename?: 'Artwork_aggregate';
  aggregate?: Maybe<Artwork_Aggregate_Fields>;
  nodes: Array<Artwork>;
};

/** aggregate fields of "Artwork" */
export type Artwork_Aggregate_Fields = {
  __typename?: 'Artwork_aggregate_fields';
  avg?: Maybe<Artwork_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Max_Fields>;
  min?: Maybe<Artwork_Min_Fields>;
  stddev?: Maybe<Artwork_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Sum_Fields>;
  var_pop?: Maybe<Artwork_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Variance_Fields>;
};


/** aggregate fields of "Artwork" */
export type Artwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork" */
export type Artwork_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Max_Order_By>;
  min?: Maybe<Artwork_Min_Order_By>;
  stddev?: Maybe<Artwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Variance_Order_By>;
};

/** Table to record AI generated tags from the artwork */
export type Artwork_Ai_Tags = {
  __typename?: 'Artwork_ai_tags';
  /** An object relationship */
  Artwork: Artwork;
  /** Field to save json data from ai generated tags, this field shouldn't be modified by the user in order to keep the original tags from the AI */
  ai_raw_image_analysis: Scalars['jsonb'];
  artwork_id: Scalars['bigint'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Field that keeps all english tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  en_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all french tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  fr_image_tags?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** Field that keeps all portuguese tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  pg_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all romanian tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  ro_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all spanish tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  sp_image_tags?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** Field with Json data about the artwork tags, this field can be used by FE, the user can edit / add values here */
  user_selected_image_tags?: Maybe<Scalars['jsonb']>;
};


/** Table to record AI generated tags from the artwork */
export type Artwork_Ai_TagsAi_Raw_Image_AnalysisArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table to record AI generated tags from the artwork */
export type Artwork_Ai_TagsUser_Selected_Image_TagsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Aggregate = {
  __typename?: 'Artwork_ai_tags_aggregate';
  aggregate?: Maybe<Artwork_Ai_Tags_Aggregate_Fields>;
  nodes: Array<Artwork_Ai_Tags>;
};

/** aggregate fields of "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Aggregate_Fields = {
  __typename?: 'Artwork_ai_tags_aggregate_fields';
  avg?: Maybe<Artwork_Ai_Tags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Ai_Tags_Max_Fields>;
  min?: Maybe<Artwork_Ai_Tags_Min_Fields>;
  stddev?: Maybe<Artwork_Ai_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Ai_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Ai_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Ai_Tags_Sum_Fields>;
  var_pop?: Maybe<Artwork_Ai_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Ai_Tags_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Ai_Tags_Variance_Fields>;
};


/** aggregate fields of "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Ai_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Ai_Tags_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Ai_Tags_Max_Order_By>;
  min?: Maybe<Artwork_Ai_Tags_Min_Order_By>;
  stddev?: Maybe<Artwork_Ai_Tags_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Ai_Tags_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Ai_Tags_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Ai_Tags_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Ai_Tags_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Ai_Tags_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Ai_Tags_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Artwork_Ai_Tags_Append_Input = {
  /** Field to save json data from ai generated tags, this field shouldn't be modified by the user in order to keep the original tags from the AI */
  ai_raw_image_analysis?: Maybe<Scalars['jsonb']>;
  /** Field with Json data about the artwork tags, this field can be used by FE, the user can edit / add values here */
  user_selected_image_tags?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Ai_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Ai_Tags_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Ai_Tags_Avg_Fields = {
  __typename?: 'Artwork_ai_tags_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_ai_tags". All fields are combined with a logical 'AND'. */
export type Artwork_Ai_Tags_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Ai_Tags_Bool_Exp>>;
  _not?: Maybe<Artwork_Ai_Tags_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Ai_Tags_Bool_Exp>>;
  ai_raw_image_analysis?: Maybe<Jsonb_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  en_image_tags?: Maybe<String_Comparison_Exp>;
  fr_image_tags?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  pg_image_tags?: Maybe<String_Comparison_Exp>;
  ro_image_tags?: Maybe<String_Comparison_Exp>;
  sp_image_tags?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_selected_image_tags?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_ai_tags" */
export enum Artwork_Ai_Tags_Constraint {
  /** unique or primary key constraint on columns "artwork_id" */
  ArtworkAiTagsArtworkIdKey = 'Artwork_ai_tags_artwork_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtworkAiTagsPkey = 'Artwork_ai_tags_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Artwork_Ai_Tags_Delete_At_Path_Input = {
  /** Field to save json data from ai generated tags, this field shouldn't be modified by the user in order to keep the original tags from the AI */
  ai_raw_image_analysis?: Maybe<Array<Scalars['String']>>;
  /** Field with Json data about the artwork tags, this field can be used by FE, the user can edit / add values here */
  user_selected_image_tags?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Artwork_Ai_Tags_Delete_Elem_Input = {
  /** Field to save json data from ai generated tags, this field shouldn't be modified by the user in order to keep the original tags from the AI */
  ai_raw_image_analysis?: Maybe<Scalars['Int']>;
  /** Field with Json data about the artwork tags, this field can be used by FE, the user can edit / add values here */
  user_selected_image_tags?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Artwork_Ai_Tags_Delete_Key_Input = {
  /** Field to save json data from ai generated tags, this field shouldn't be modified by the user in order to keep the original tags from the AI */
  ai_raw_image_analysis?: Maybe<Scalars['String']>;
  /** Field with Json data about the artwork tags, this field can be used by FE, the user can edit / add values here */
  user_selected_image_tags?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  /** Field to save json data from ai generated tags, this field shouldn't be modified by the user in order to keep the original tags from the AI */
  ai_raw_image_analysis?: Maybe<Scalars['jsonb']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Field that keeps all english tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  en_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all french tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  fr_image_tags?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** Field that keeps all portuguese tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  pg_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all romanian tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  ro_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all spanish tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  sp_image_tags?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** Field with Json data about the artwork tags, this field can be used by FE, the user can edit / add values here */
  user_selected_image_tags?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Artwork_Ai_Tags_Max_Fields = {
  __typename?: 'Artwork_ai_tags_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Field that keeps all english tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  en_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all french tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  fr_image_tags?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** Field that keeps all portuguese tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  pg_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all romanian tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  ro_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all spanish tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  sp_image_tags?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Field that keeps all english tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  en_image_tags?: Maybe<Order_By>;
  /** Field that keeps all french tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  fr_image_tags?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Field that keeps all portuguese tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  pg_image_tags?: Maybe<Order_By>;
  /** Field that keeps all romanian tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  ro_image_tags?: Maybe<Order_By>;
  /** Field that keeps all spanish tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  sp_image_tags?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Ai_Tags_Min_Fields = {
  __typename?: 'Artwork_ai_tags_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Field that keeps all english tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  en_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all french tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  fr_image_tags?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** Field that keeps all portuguese tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  pg_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all romanian tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  ro_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all spanish tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  sp_image_tags?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Field that keeps all english tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  en_image_tags?: Maybe<Order_By>;
  /** Field that keeps all french tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  fr_image_tags?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Field that keeps all portuguese tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  pg_image_tags?: Maybe<Order_By>;
  /** Field that keeps all romanian tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  ro_image_tags?: Maybe<Order_By>;
  /** Field that keeps all spanish tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  sp_image_tags?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Mutation_Response = {
  __typename?: 'Artwork_ai_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Ai_Tags>;
};

/** on_conflict condition type for table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_On_Conflict = {
  constraint: Artwork_Ai_Tags_Constraint;
  update_columns?: Array<Artwork_Ai_Tags_Update_Column>;
  where?: Maybe<Artwork_Ai_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_ai_tags". */
export type Artwork_Ai_Tags_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  ai_raw_image_analysis?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  en_image_tags?: Maybe<Order_By>;
  fr_image_tags?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pg_image_tags?: Maybe<Order_By>;
  ro_image_tags?: Maybe<Order_By>;
  sp_image_tags?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_selected_image_tags?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_ai_tags */
export type Artwork_Ai_Tags_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Artwork_Ai_Tags_Prepend_Input = {
  /** Field to save json data from ai generated tags, this field shouldn't be modified by the user in order to keep the original tags from the AI */
  ai_raw_image_analysis?: Maybe<Scalars['jsonb']>;
  /** Field with Json data about the artwork tags, this field can be used by FE, the user can edit / add values here */
  user_selected_image_tags?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Artwork_ai_tags" */
export enum Artwork_Ai_Tags_Select_Column {
  /** column name */
  AiRawImageAnalysis = 'ai_raw_image_analysis',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnImageTags = 'en_image_tags',
  /** column name */
  FrImageTags = 'fr_image_tags',
  /** column name */
  Id = 'id',
  /** column name */
  PgImageTags = 'pg_image_tags',
  /** column name */
  RoImageTags = 'ro_image_tags',
  /** column name */
  SpImageTags = 'sp_image_tags',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserSelectedImageTags = 'user_selected_image_tags'
}

/** input type for updating data in table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Set_Input = {
  /** Field to save json data from ai generated tags, this field shouldn't be modified by the user in order to keep the original tags from the AI */
  ai_raw_image_analysis?: Maybe<Scalars['jsonb']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Field that keeps all english tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  en_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all french tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  fr_image_tags?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** Field that keeps all portuguese tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  pg_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all romanian tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  ro_image_tags?: Maybe<Scalars['String']>;
  /** Field that keeps all spanish tags in one string separated with commas. This field shouldn't be manually modified, it's extracted with the update trigger for this row */
  sp_image_tags?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** Field with Json data about the artwork tags, this field can be used by FE, the user can edit / add values here */
  user_selected_image_tags?: Maybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Artwork_Ai_Tags_Stddev_Fields = {
  __typename?: 'Artwork_ai_tags_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Ai_Tags_Stddev_Pop_Fields = {
  __typename?: 'Artwork_ai_tags_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Ai_Tags_Stddev_Samp_Fields = {
  __typename?: 'Artwork_ai_tags_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Ai_Tags_Sum_Fields = {
  __typename?: 'Artwork_ai_tags_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_ai_tags" */
export enum Artwork_Ai_Tags_Update_Column {
  /** column name */
  AiRawImageAnalysis = 'ai_raw_image_analysis',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnImageTags = 'en_image_tags',
  /** column name */
  FrImageTags = 'fr_image_tags',
  /** column name */
  Id = 'id',
  /** column name */
  PgImageTags = 'pg_image_tags',
  /** column name */
  RoImageTags = 'ro_image_tags',
  /** column name */
  SpImageTags = 'sp_image_tags',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserSelectedImageTags = 'user_selected_image_tags'
}

/** aggregate var_pop on columns */
export type Artwork_Ai_Tags_Var_Pop_Fields = {
  __typename?: 'Artwork_ai_tags_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Ai_Tags_Var_Samp_Fields = {
  __typename?: 'Artwork_ai_tags_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Ai_Tags_Variance_Fields = {
  __typename?: 'Artwork_ai_tags_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_ai_tags" */
export type Artwork_Ai_Tags_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** input type for inserting array relation for remote table "Artwork" */
export type Artwork_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_On_Conflict>;
};

/** Contains the slide duration time for the Art History presentation (for Art Universe app) */
export type Artwork_Art_History_Timetable = {
  __typename?: 'Artwork_art_history_timetable';
  /** An object relationship */
  artwork_to_collection: Artwork_To_Collection;
  artwork_to_collection_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  display_time_millis: Scalars['Int'];
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_art_history_timetable" */
export type Artwork_Art_History_Timetable_Aggregate = {
  __typename?: 'Artwork_art_history_timetable_aggregate';
  aggregate?: Maybe<Artwork_Art_History_Timetable_Aggregate_Fields>;
  nodes: Array<Artwork_Art_History_Timetable>;
};

/** aggregate fields of "Artwork_art_history_timetable" */
export type Artwork_Art_History_Timetable_Aggregate_Fields = {
  __typename?: 'Artwork_art_history_timetable_aggregate_fields';
  avg?: Maybe<Artwork_Art_History_Timetable_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Art_History_Timetable_Max_Fields>;
  min?: Maybe<Artwork_Art_History_Timetable_Min_Fields>;
  stddev?: Maybe<Artwork_Art_History_Timetable_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Art_History_Timetable_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Art_History_Timetable_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Art_History_Timetable_Sum_Fields>;
  var_pop?: Maybe<Artwork_Art_History_Timetable_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Art_History_Timetable_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Art_History_Timetable_Variance_Fields>;
};


/** aggregate fields of "Artwork_art_history_timetable" */
export type Artwork_Art_History_Timetable_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Art_History_Timetable_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Art_History_Timetable_Avg_Fields = {
  __typename?: 'Artwork_art_history_timetable_avg_fields';
  artwork_to_collection_id?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_art_history_timetable". All fields are combined with a logical 'AND'. */
export type Artwork_Art_History_Timetable_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Art_History_Timetable_Bool_Exp>>;
  _not?: Maybe<Artwork_Art_History_Timetable_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Art_History_Timetable_Bool_Exp>>;
  artwork_to_collection?: Maybe<Artwork_To_Collection_Bool_Exp>;
  artwork_to_collection_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  display_time_millis?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_art_history_timetable" */
export enum Artwork_Art_History_Timetable_Constraint {
  /** unique or primary key constraint on columns "artwork_to_collection_id" */
  ArtworkArtHistoryTimetableArtworkToCollectionIdKey = 'Artwork_art_history_timetable_artwork_to_collection_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtworkArtHistoryTimetablePkey = 'Artwork_art_history_timetable_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_art_history_timetable" */
export type Artwork_Art_History_Timetable_Inc_Input = {
  artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_art_history_timetable" */
export type Artwork_Art_History_Timetable_Insert_Input = {
  artwork_to_collection?: Maybe<Artwork_To_Collection_Obj_Rel_Insert_Input>;
  artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Art_History_Timetable_Max_Fields = {
  __typename?: 'Artwork_art_history_timetable_max_fields';
  artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artwork_Art_History_Timetable_Min_Fields = {
  __typename?: 'Artwork_art_history_timetable_min_fields';
  artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Artwork_art_history_timetable" */
export type Artwork_Art_History_Timetable_Mutation_Response = {
  __typename?: 'Artwork_art_history_timetable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Art_History_Timetable>;
};

/** on_conflict condition type for table "Artwork_art_history_timetable" */
export type Artwork_Art_History_Timetable_On_Conflict = {
  constraint: Artwork_Art_History_Timetable_Constraint;
  update_columns?: Array<Artwork_Art_History_Timetable_Update_Column>;
  where?: Maybe<Artwork_Art_History_Timetable_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_art_history_timetable". */
export type Artwork_Art_History_Timetable_Order_By = {
  artwork_to_collection?: Maybe<Artwork_To_Collection_Order_By>;
  artwork_to_collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_time_millis?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_art_history_timetable */
export type Artwork_Art_History_Timetable_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_art_history_timetable" */
export enum Artwork_Art_History_Timetable_Select_Column {
  /** column name */
  ArtworkToCollectionId = 'artwork_to_collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayTimeMillis = 'display_time_millis',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_art_history_timetable" */
export type Artwork_Art_History_Timetable_Set_Input = {
  artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Art_History_Timetable_Stddev_Fields = {
  __typename?: 'Artwork_art_history_timetable_stddev_fields';
  artwork_to_collection_id?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Art_History_Timetable_Stddev_Pop_Fields = {
  __typename?: 'Artwork_art_history_timetable_stddev_pop_fields';
  artwork_to_collection_id?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Art_History_Timetable_Stddev_Samp_Fields = {
  __typename?: 'Artwork_art_history_timetable_stddev_samp_fields';
  artwork_to_collection_id?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Art_History_Timetable_Sum_Fields = {
  __typename?: 'Artwork_art_history_timetable_sum_fields';
  artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Artwork_art_history_timetable" */
export enum Artwork_Art_History_Timetable_Update_Column {
  /** column name */
  ArtworkToCollectionId = 'artwork_to_collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayTimeMillis = 'display_time_millis',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Art_History_Timetable_Var_Pop_Fields = {
  __typename?: 'Artwork_art_history_timetable_var_pop_fields';
  artwork_to_collection_id?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Art_History_Timetable_Var_Samp_Fields = {
  __typename?: 'Artwork_art_history_timetable_var_samp_fields';
  artwork_to_collection_id?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Art_History_Timetable_Variance_Fields = {
  __typename?: 'Artwork_art_history_timetable_variance_fields';
  artwork_to_collection_id?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_artist_art_history_listing" */
export type Artwork_Artist_Art_History_Listing = {
  __typename?: 'Artwork_artist_art_history_listing';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Artwork_artist_art_history_listing" */
export type Artwork_Artist_Art_History_Listing_Aggregate = {
  __typename?: 'Artwork_artist_art_history_listing_aggregate';
  aggregate?: Maybe<Artwork_Artist_Art_History_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Artist_Art_History_Listing>;
};

/** aggregate fields of "Artwork_artist_art_history_listing" */
export type Artwork_Artist_Art_History_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_aggregate_fields';
  avg?: Maybe<Artwork_Artist_Art_History_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Artist_Art_History_Listing_Max_Fields>;
  min?: Maybe<Artwork_Artist_Art_History_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Artist_Art_History_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Artist_Art_History_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Artist_Art_History_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Artist_Art_History_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Artist_Art_History_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Artist_Art_History_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Artist_Art_History_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_artist_art_history_listing" */
export type Artwork_Artist_Art_History_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Artist_Art_History_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Artist_Art_History_Listing_Avg_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_avg_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_artist_art_history_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Artist_Art_History_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Artist_Art_History_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Artist_Art_History_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Artist_Art_History_Listing_Bool_Exp>>;
  area?: Maybe<Float8_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_order?: Maybe<Int_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
  creation_date_year?: Maybe<Int_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_landscape?: Maybe<Boolean_Comparison_Exp>;
  is_portrait?: Maybe<Boolean_Comparison_Exp>;
  is_square?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Artist_Art_History_Listing_Max_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_max_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Artist_Art_History_Listing_Min_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_min_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "Artwork_artist_art_history_listing". */
export type Artwork_Artist_Art_History_Listing_Order_By = {
  area?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_order?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  creation_date_year?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_landscape?: Maybe<Order_By>;
  is_portrait?: Maybe<Order_By>;
  is_square?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Artwork_artist_art_history_listing" */
export enum Artwork_Artist_Art_History_Listing_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistOrder = 'artist_order',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AspectRatio = 'aspect_ratio',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  Height = 'height',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsPortrait = 'is_portrait',
  /** column name */
  IsSquare = 'is_square',
  /** column name */
  IsUnavailable = 'is_unavailable',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Artist_Art_History_Listing_Stddev_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_stddev_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Artist_Art_History_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_stddev_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Artist_Art_History_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_stddev_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Artist_Art_History_Listing_Sum_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_sum_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Artist_Art_History_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_var_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Artist_Art_History_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_var_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Artist_Art_History_Listing_Variance_Fields = {
  __typename?: 'Artwork_artist_art_history_listing_variance_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_artist_collection_type_listing" */
export type Artwork_Artist_Collection_Type_Listing = {
  __typename?: 'Artwork_artist_collection_type_listing';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_title?: Maybe<Scalars['String']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_type?: Maybe<Scalars['String']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  discipline_other?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Artwork_artist_collection_type_listing" */
export type Artwork_Artist_Collection_Type_Listing_Aggregate = {
  __typename?: 'Artwork_artist_collection_type_listing_aggregate';
  aggregate?: Maybe<Artwork_Artist_Collection_Type_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Artist_Collection_Type_Listing>;
};

/** aggregate fields of "Artwork_artist_collection_type_listing" */
export type Artwork_Artist_Collection_Type_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_aggregate_fields';
  avg?: Maybe<Artwork_Artist_Collection_Type_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Artist_Collection_Type_Listing_Max_Fields>;
  min?: Maybe<Artwork_Artist_Collection_Type_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Artist_Collection_Type_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Artist_Collection_Type_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Artist_Collection_Type_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Artist_Collection_Type_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Artist_Collection_Type_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Artist_Collection_Type_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Artist_Collection_Type_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_artist_collection_type_listing" */
export type Artwork_Artist_Collection_Type_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Artist_Collection_Type_Listing_Avg_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_avg_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_artist_collection_type_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Artist_Collection_Type_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Artist_Collection_Type_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Bool_Exp>>;
  area?: Maybe<Float8_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_order?: Maybe<Int_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_title?: Maybe<String_Comparison_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
  collection_type?: Maybe<String_Comparison_Exp>;
  creation_date_year?: Maybe<Int_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  discipline_other?: Maybe<String_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_landscape?: Maybe<Boolean_Comparison_Exp>;
  is_portrait?: Maybe<Boolean_Comparison_Exp>;
  is_square?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Artist_Collection_Type_Listing_Max_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_max_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_title?: Maybe<Scalars['String']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_type?: Maybe<Scalars['String']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  discipline_other?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Artist_Collection_Type_Listing_Min_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_min_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_title?: Maybe<Scalars['String']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_type?: Maybe<Scalars['String']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  discipline_other?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "Artwork_artist_collection_type_listing". */
export type Artwork_Artist_Collection_Type_Listing_Order_By = {
  area?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_order?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_title?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  collection_type?: Maybe<Order_By>;
  creation_date_year?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  discipline_other?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_landscape?: Maybe<Order_By>;
  is_portrait?: Maybe<Order_By>;
  is_square?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Artwork_artist_collection_type_listing" */
export enum Artwork_Artist_Collection_Type_Listing_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistOrder = 'artist_order',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkTitle = 'artwork_title',
  /** column name */
  AspectRatio = 'aspect_ratio',
  /** column name */
  CollectionType = 'collection_type',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  Currency = 'currency',
  /** column name */
  Depth = 'depth',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  DisciplineOther = 'discipline_other',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  Height = 'height',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsPortrait = 'is_portrait',
  /** column name */
  IsSquare = 'is_square',
  /** column name */
  IsUnavailable = 'is_unavailable',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Artist_Collection_Type_Listing_Stddev_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_stddev_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Artist_Collection_Type_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_stddev_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Artist_Collection_Type_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_stddev_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Artist_Collection_Type_Listing_Sum_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_sum_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  height?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Artist_Collection_Type_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_var_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Artist_Collection_Type_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_var_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Artist_Collection_Type_Listing_Variance_Fields = {
  __typename?: 'Artwork_artist_collection_type_listing_variance_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_artist_profile_info" */
export type Artwork_Artist_Profile_Info = {
  __typename?: 'Artwork_artist_profile_info';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  /** An array relationship */
  edition: Array<Edition>;
  /** An aggregate relationship */
  edition_aggregate: Edition_Aggregate;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  measuring_unit?: Maybe<Scalars['String']>;
  /** An array relationship */
  prices: Array<Artwork_Prices>;
  /** An aggregate relationship */
  prices_aggregate: Artwork_Prices_Aggregate;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};


/** columns and relationships of "Artwork_artist_profile_info" */
export type Artwork_Artist_Profile_InfoEditionArgs = {
  distinct_on?: Maybe<Array<Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Order_By>>;
  where?: Maybe<Edition_Bool_Exp>;
};


/** columns and relationships of "Artwork_artist_profile_info" */
export type Artwork_Artist_Profile_InfoEdition_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Order_By>>;
  where?: Maybe<Edition_Bool_Exp>;
};


/** columns and relationships of "Artwork_artist_profile_info" */
export type Artwork_Artist_Profile_InfoPricesArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


/** columns and relationships of "Artwork_artist_profile_info" */
export type Artwork_Artist_Profile_InfoPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};

/** aggregated selection of "Artwork_artist_profile_info" */
export type Artwork_Artist_Profile_Info_Aggregate = {
  __typename?: 'Artwork_artist_profile_info_aggregate';
  aggregate?: Maybe<Artwork_Artist_Profile_Info_Aggregate_Fields>;
  nodes: Array<Artwork_Artist_Profile_Info>;
};

/** aggregate fields of "Artwork_artist_profile_info" */
export type Artwork_Artist_Profile_Info_Aggregate_Fields = {
  __typename?: 'Artwork_artist_profile_info_aggregate_fields';
  avg?: Maybe<Artwork_Artist_Profile_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Artist_Profile_Info_Max_Fields>;
  min?: Maybe<Artwork_Artist_Profile_Info_Min_Fields>;
  stddev?: Maybe<Artwork_Artist_Profile_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Artist_Profile_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Artist_Profile_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Artist_Profile_Info_Sum_Fields>;
  var_pop?: Maybe<Artwork_Artist_Profile_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Artist_Profile_Info_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Artist_Profile_Info_Variance_Fields>;
};


/** aggregate fields of "Artwork_artist_profile_info" */
export type Artwork_Artist_Profile_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Artist_Profile_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Artist_Profile_Info_Avg_Fields = {
  __typename?: 'Artwork_artist_profile_info_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_artist_profile_info". All fields are combined with a logical 'AND'. */
export type Artwork_Artist_Profile_Info_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Artist_Profile_Info_Bool_Exp>>;
  _not?: Maybe<Artwork_Artist_Profile_Info_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Artist_Profile_Info_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  edition?: Maybe<Edition_Bool_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  prices?: Maybe<Artwork_Prices_Bool_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Artist_Profile_Info_Max_Fields = {
  __typename?: 'Artwork_artist_profile_info_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  measuring_unit?: Maybe<Scalars['String']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Artist_Profile_Info_Min_Fields = {
  __typename?: 'Artwork_artist_profile_info_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  measuring_unit?: Maybe<Scalars['String']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "Artwork_artist_profile_info". */
export type Artwork_Artist_Profile_Info_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  edition_aggregate?: Maybe<Edition_Aggregate_Order_By>;
  edition_type?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  prices_aggregate?: Maybe<Artwork_Prices_Aggregate_Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Artwork_artist_profile_info" */
export enum Artwork_Artist_Profile_Info_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  Depth = 'depth',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  Height = 'height',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsUnavailable = 'is_unavailable',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Artist_Profile_Info_Stddev_Fields = {
  __typename?: 'Artwork_artist_profile_info_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Artist_Profile_Info_Stddev_Pop_Fields = {
  __typename?: 'Artwork_artist_profile_info_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Artist_Profile_Info_Stddev_Samp_Fields = {
  __typename?: 'Artwork_artist_profile_info_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Artist_Profile_Info_Sum_Fields = {
  __typename?: 'Artwork_artist_profile_info_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  height?: Maybe<Scalars['float8']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Artist_Profile_Info_Var_Pop_Fields = {
  __typename?: 'Artwork_artist_profile_info_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Artist_Profile_Info_Var_Samp_Fields = {
  __typename?: 'Artwork_artist_profile_info_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Artist_Profile_Info_Variance_Fields = {
  __typename?: 'Artwork_artist_profile_info_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_artist_profile_listing" */
export type Artwork_Artist_Profile_Listing = {
  __typename?: 'Artwork_artist_profile_listing';
  /** An object relationship */
  Artwork?: Maybe<Artwork>;
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  aspect?: Maybe<Artwork_Aspect_Ratio>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Artwork_artist_profile_listing" */
export type Artwork_Artist_Profile_Listing_Aggregate = {
  __typename?: 'Artwork_artist_profile_listing_aggregate';
  aggregate?: Maybe<Artwork_Artist_Profile_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Artist_Profile_Listing>;
};

/** aggregate fields of "Artwork_artist_profile_listing" */
export type Artwork_Artist_Profile_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_artist_profile_listing_aggregate_fields';
  avg?: Maybe<Artwork_Artist_Profile_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Artist_Profile_Listing_Max_Fields>;
  min?: Maybe<Artwork_Artist_Profile_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Artist_Profile_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Artist_Profile_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Artist_Profile_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Artist_Profile_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Artist_Profile_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Artist_Profile_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Artist_Profile_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_artist_profile_listing" */
export type Artwork_Artist_Profile_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Artist_Profile_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Artist_Profile_Listing_Avg_Fields = {
  __typename?: 'Artwork_artist_profile_listing_avg_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_artist_profile_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Artist_Profile_Listing_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Artist_Profile_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Artist_Profile_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Artist_Profile_Listing_Bool_Exp>>;
  area?: Maybe<Float8_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_order?: Maybe<Int_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  aspect?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
  creation_date_year?: Maybe<Int_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_landscape?: Maybe<Boolean_Comparison_Exp>;
  is_portrait?: Maybe<Boolean_Comparison_Exp>;
  is_square?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** input type for inserting data into table "Artwork_artist_profile_listing" */
export type Artwork_Artist_Profile_Listing_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect?: Maybe<Artwork_Aspect_Ratio_Obj_Rel_Insert_Input>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Artwork_Artist_Profile_Listing_Max_Fields = {
  __typename?: 'Artwork_artist_profile_listing_max_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Artist_Profile_Listing_Min_Fields = {
  __typename?: 'Artwork_artist_profile_listing_min_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** input type for inserting object relation for remote table "Artwork_artist_profile_listing" */
export type Artwork_Artist_Profile_Listing_Obj_Rel_Insert_Input = {
  data: Artwork_Artist_Profile_Listing_Insert_Input;
};

/** Ordering options when selecting data from "Artwork_artist_profile_listing". */
export type Artwork_Artist_Profile_Listing_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  area?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_order?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect?: Maybe<Artwork_Aspect_Ratio_Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  creation_date_year?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_landscape?: Maybe<Order_By>;
  is_portrait?: Maybe<Order_By>;
  is_square?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Artwork_artist_profile_listing" */
export enum Artwork_Artist_Profile_Listing_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistOrder = 'artist_order',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AspectRatio = 'aspect_ratio',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  Height = 'height',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsPortrait = 'is_portrait',
  /** column name */
  IsSquare = 'is_square',
  /** column name */
  IsUnavailable = 'is_unavailable',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Artist_Profile_Listing_Stddev_Fields = {
  __typename?: 'Artwork_artist_profile_listing_stddev_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Artist_Profile_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_artist_profile_listing_stddev_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Artist_Profile_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_artist_profile_listing_stddev_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Artist_Profile_Listing_Sum_Fields = {
  __typename?: 'Artwork_artist_profile_listing_sum_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Artist_Profile_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_artist_profile_listing_var_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Artist_Profile_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_artist_profile_listing_var_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Artist_Profile_Listing_Variance_Fields = {
  __typename?: 'Artwork_artist_profile_listing_variance_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_artist_vision_listing" */
export type Artwork_Artist_Vision_Listing = {
  __typename?: 'Artwork_artist_vision_listing';
  /** An object relationship */
  Artwork?: Maybe<Artwork>;
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  artist_explore_info?: Maybe<Artist_Explore_Info>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Artwork_artist_vision_listing" */
export type Artwork_Artist_Vision_Listing_Aggregate = {
  __typename?: 'Artwork_artist_vision_listing_aggregate';
  aggregate?: Maybe<Artwork_Artist_Vision_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Artist_Vision_Listing>;
};

/** aggregate fields of "Artwork_artist_vision_listing" */
export type Artwork_Artist_Vision_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_artist_vision_listing_aggregate_fields';
  avg?: Maybe<Artwork_Artist_Vision_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Artist_Vision_Listing_Max_Fields>;
  min?: Maybe<Artwork_Artist_Vision_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Artist_Vision_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Artist_Vision_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Artist_Vision_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Artist_Vision_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Artist_Vision_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Artist_Vision_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Artist_Vision_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_artist_vision_listing" */
export type Artwork_Artist_Vision_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Artist_Vision_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Artist_Vision_Listing_Avg_Fields = {
  __typename?: 'Artwork_artist_vision_listing_avg_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_artist_vision_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Artist_Vision_Listing_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Artist_Vision_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Artist_Vision_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Artist_Vision_Listing_Bool_Exp>>;
  area?: Maybe<Float8_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_explore_info?: Maybe<Artist_Explore_Info_Bool_Exp>;
  artist_order?: Maybe<Int_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
  creation_date_year?: Maybe<Int_Comparison_Exp>;
  display_time_millis?: Maybe<Int_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_landscape?: Maybe<Boolean_Comparison_Exp>;
  is_portrait?: Maybe<Boolean_Comparison_Exp>;
  is_square?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Artist_Vision_Listing_Max_Fields = {
  __typename?: 'Artwork_artist_vision_listing_max_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Artist_Vision_Listing_Min_Fields = {
  __typename?: 'Artwork_artist_vision_listing_min_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "Artwork_artist_vision_listing". */
export type Artwork_Artist_Vision_Listing_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  area?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_explore_info?: Maybe<Artist_Explore_Info_Order_By>;
  artist_order?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  creation_date_year?: Maybe<Order_By>;
  display_time_millis?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_landscape?: Maybe<Order_By>;
  is_portrait?: Maybe<Order_By>;
  is_square?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Artwork_artist_vision_listing" */
export enum Artwork_Artist_Vision_Listing_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistOrder = 'artist_order',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AspectRatio = 'aspect_ratio',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  DisplayTimeMillis = 'display_time_millis',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsPortrait = 'is_portrait',
  /** column name */
  IsSquare = 'is_square',
  /** column name */
  IsUnavailable = 'is_unavailable',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Artist_Vision_Listing_Stddev_Fields = {
  __typename?: 'Artwork_artist_vision_listing_stddev_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Artist_Vision_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_artist_vision_listing_stddev_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Artist_Vision_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_artist_vision_listing_stddev_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Artist_Vision_Listing_Sum_Fields = {
  __typename?: 'Artwork_artist_vision_listing_sum_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  display_time_millis?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Artist_Vision_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_artist_vision_listing_var_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Artist_Vision_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_artist_vision_listing_var_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Artist_Vision_Listing_Variance_Fields = {
  __typename?: 'Artwork_artist_vision_listing_variance_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artist_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  display_time_millis?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** table to keep the aspect ratio of artworks */
export type Artwork_Aspect_Ratio = {
  __typename?: 'Artwork_aspect_ratio';
  artwork_id: Scalars['bigint'];
  /** width / height * 10000 */
  aspect_ratio: Scalars['Int'];
};

/** aggregated selection of "Artwork_aspect_ratio" */
export type Artwork_Aspect_Ratio_Aggregate = {
  __typename?: 'Artwork_aspect_ratio_aggregate';
  aggregate?: Maybe<Artwork_Aspect_Ratio_Aggregate_Fields>;
  nodes: Array<Artwork_Aspect_Ratio>;
};

/** aggregate fields of "Artwork_aspect_ratio" */
export type Artwork_Aspect_Ratio_Aggregate_Fields = {
  __typename?: 'Artwork_aspect_ratio_aggregate_fields';
  avg?: Maybe<Artwork_Aspect_Ratio_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Aspect_Ratio_Max_Fields>;
  min?: Maybe<Artwork_Aspect_Ratio_Min_Fields>;
  stddev?: Maybe<Artwork_Aspect_Ratio_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Aspect_Ratio_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Aspect_Ratio_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Aspect_Ratio_Sum_Fields>;
  var_pop?: Maybe<Artwork_Aspect_Ratio_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Aspect_Ratio_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Aspect_Ratio_Variance_Fields>;
};


/** aggregate fields of "Artwork_aspect_ratio" */
export type Artwork_Aspect_Ratio_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Aspect_Ratio_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Aspect_Ratio_Avg_Fields = {
  __typename?: 'Artwork_aspect_ratio_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_aspect_ratio". All fields are combined with a logical 'AND'. */
export type Artwork_Aspect_Ratio_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Aspect_Ratio_Bool_Exp>>;
  _not?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Aspect_Ratio_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_aspect_ratio" */
export enum Artwork_Aspect_Ratio_Constraint {
  /** unique or primary key constraint on columns "artwork_id" */
  ArtworkAspectRatioPkey = 'Artwork_aspect_ratio_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_aspect_ratio" */
export type Artwork_Aspect_Ratio_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artwork_aspect_ratio" */
export type Artwork_Aspect_Ratio_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Artwork_Aspect_Ratio_Max_Fields = {
  __typename?: 'Artwork_aspect_ratio_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Artwork_Aspect_Ratio_Min_Fields = {
  __typename?: 'Artwork_aspect_ratio_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Artwork_aspect_ratio" */
export type Artwork_Aspect_Ratio_Mutation_Response = {
  __typename?: 'Artwork_aspect_ratio_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Aspect_Ratio>;
};

/** input type for inserting object relation for remote table "Artwork_aspect_ratio" */
export type Artwork_Aspect_Ratio_Obj_Rel_Insert_Input = {
  data: Artwork_Aspect_Ratio_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Aspect_Ratio_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_aspect_ratio" */
export type Artwork_Aspect_Ratio_On_Conflict = {
  constraint: Artwork_Aspect_Ratio_Constraint;
  update_columns?: Array<Artwork_Aspect_Ratio_Update_Column>;
  where?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_aspect_ratio". */
export type Artwork_Aspect_Ratio_Order_By = {
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_aspect_ratio */
export type Artwork_Aspect_Ratio_Pk_Columns_Input = {
  artwork_id: Scalars['bigint'];
};

/** select columns of table "Artwork_aspect_ratio" */
export enum Artwork_Aspect_Ratio_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AspectRatio = 'aspect_ratio'
}

/** input type for updating data in table "Artwork_aspect_ratio" */
export type Artwork_Aspect_Ratio_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Artwork_Aspect_Ratio_Stddev_Fields = {
  __typename?: 'Artwork_aspect_ratio_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Aspect_Ratio_Stddev_Pop_Fields = {
  __typename?: 'Artwork_aspect_ratio_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Aspect_Ratio_Stddev_Samp_Fields = {
  __typename?: 'Artwork_aspect_ratio_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Aspect_Ratio_Sum_Fields = {
  __typename?: 'Artwork_aspect_ratio_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Int']>;
};

/** update columns of table "Artwork_aspect_ratio" */
export enum Artwork_Aspect_Ratio_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AspectRatio = 'aspect_ratio'
}

/** aggregate var_pop on columns */
export type Artwork_Aspect_Ratio_Var_Pop_Fields = {
  __typename?: 'Artwork_aspect_ratio_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Aspect_Ratio_Var_Samp_Fields = {
  __typename?: 'Artwork_aspect_ratio_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Aspect_Ratio_Variance_Fields = {
  __typename?: 'Artwork_aspect_ratio_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  /** width / height * 10000 */
  aspect_ratio?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type Artwork_Avg_Fields = {
  __typename?: 'Artwork_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  crop_area_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  original_image_metadata_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork" */
export type Artwork_Avg_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork". All fields are combined with a logical 'AND'. */
export type Artwork_Bool_Exp = {
  Artwork_Art_Universe_Listing?: Maybe<Artwork_Art_Universe_Listing_Bool_Exp>;
  Artwork_ai_tags?: Maybe<Artwork_Ai_Tags_Bool_Exp>;
  Artwork_artist_profile_listing?: Maybe<Artwork_Artist_Profile_Listing_Bool_Exp>;
  Artwork_events?: Maybe<Artwork_Events_Bool_Exp>;
  Artwork_prices?: Maybe<Artwork_Prices_Bool_Exp>;
  Artwork_state_histories?: Maybe<Artwork_State_History_Bool_Exp>;
  Artwork_to_favorite_count?: Maybe<Artwork_Favorite_Count_Bool_Exp>;
  ENUM_add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Bool_Exp>;
  ENUM_artwork_edition_type?: Maybe<Enum_Artwork_Edition_Type_Bool_Exp>;
  Editions?: Maybe<Edition_Bool_Exp>;
  Print_amounts?: Maybe<Print_Amounts_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Bool_Exp>>;
  _not?: Maybe<Artwork_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Bool_Exp>>;
  add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Enum_Comparison_Exp>;
  art_prizes?: Maybe<Art_Prize_Artwork_Bool_Exp>;
  artist_context?: Maybe<Context_Bool_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_artist_index?: Maybe<Int_Comparison_Exp>;
  artwork_details?: Maybe<Artwork_Details_Bool_Exp>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
  artwork_story?: Maybe<Artwork_Story_Bool_Exp>;
  artwork_story_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_to_collections?: Maybe<Artwork_To_Collection_Bool_Exp>;
  artwork_to_tags?: Maybe<Artwork_To_Tag_Bool_Exp>;
  certificate?: Maybe<Certificate_Of_Authenticity_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  crop_area?: Maybe<Crop_Area_Bool_Exp>;
  crop_area_id?: Maybe<Bigint_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  edition_type?: Maybe<Enum_Artwork_Edition_Type_Enum_Comparison_Exp>;
  gallery_context?: Maybe<Context_Bool_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  has_ar_animation?: Maybe<Boolean_Comparison_Exp>;
  has_video?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  is_hard_minted?: Maybe<Boolean_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  is_transaction_locked?: Maybe<Boolean_Comparison_Exp>;
  last_updater?: Maybe<Bigint_Comparison_Exp>;
  links?: Maybe<Artwork_Links_Bool_Exp>;
  listed_at?: Maybe<Timestamptz_Comparison_Exp>;
  original_image_metadata?: Maybe<File_Metadata_Bool_Exp>;
  original_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  pending_vuforia_image_path?: Maybe<String_Comparison_Exp>;
  primary_image_metadata?: Maybe<File_Metadata_Bool_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  purchase_inquires?: Maybe<Purchase_Inquires_Bool_Exp>;
  purchase_inquires_outside_users?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Enum_Artwork_Status_Enum_Comparison_Exp>;
  target_id?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata?: Maybe<File_Metadata_Bool_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  transaction_histories?: Maybe<Artwork_Transactions_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vuforia_rating?: Maybe<Int_Comparison_Exp>;
  vumarks?: Maybe<Vumarks_Bool_Exp>;
};

/** columns and relationships of "Artwork_collected_listing" */
export type Artwork_Collected_Listing = {
  __typename?: 'Artwork_collected_listing';
  artist_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  artist_explore_info?: Maybe<Artist_Explore_Info>;
  /** An object relationship */
  artwork_explore_info?: Maybe<Artwork_Explore_Info>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  collector_explore_info?: Maybe<Collector_Explore_Info>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  gallery_explore_info?: Maybe<Gallery_Explore_Info>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "Artwork_collected_listing" */
export type Artwork_Collected_Listing_Aggregate = {
  __typename?: 'Artwork_collected_listing_aggregate';
  aggregate?: Maybe<Artwork_Collected_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Collected_Listing>;
};

/** aggregate fields of "Artwork_collected_listing" */
export type Artwork_Collected_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_collected_listing_aggregate_fields';
  avg?: Maybe<Artwork_Collected_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Collected_Listing_Max_Fields>;
  min?: Maybe<Artwork_Collected_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Collected_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Collected_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Collected_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Collected_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Collected_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Collected_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Collected_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_collected_listing" */
export type Artwork_Collected_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Collected_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Collected_Listing_Avg_Fields = {
  __typename?: 'Artwork_collected_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_collected_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Collected_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Collected_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Collected_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Collected_Listing_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_explore_info?: Maybe<Artist_Explore_Info_Bool_Exp>;
  artwork_explore_info?: Maybe<Artwork_Explore_Info_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_score?: Maybe<Int_Comparison_Exp>;
  collected_by_context_id?: Maybe<Bigint_Comparison_Exp>;
  collector_explore_info?: Maybe<Collector_Explore_Info_Bool_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  gallery_explore_info?: Maybe<Gallery_Explore_Info_Bool_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  vuforia_rating?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Collected_Listing_Max_Fields = {
  __typename?: 'Artwork_collected_listing_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Artwork_Collected_Listing_Min_Fields = {
  __typename?: 'Artwork_collected_listing_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "Artwork_collected_listing". */
export type Artwork_Collected_Listing_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_explore_info?: Maybe<Artist_Explore_Info_Order_By>;
  artwork_explore_info?: Maybe<Artwork_Explore_Info_Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  collected_by_context_id?: Maybe<Order_By>;
  collector_explore_info?: Maybe<Collector_Explore_Info_Order_By>;
  edition_type?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  gallery_explore_info?: Maybe<Gallery_Explore_Info_Order_By>;
  published_date?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** select columns of table "Artwork_collected_listing" */
export enum Artwork_Collected_Listing_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkScore = 'artwork_score',
  /** column name */
  CollectedByContextId = 'collected_by_context_id',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Score = 'score',
  /** column name */
  VuforiaRating = 'vuforia_rating'
}

/** aggregate stddev on columns */
export type Artwork_Collected_Listing_Stddev_Fields = {
  __typename?: 'Artwork_collected_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Collected_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_collected_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Collected_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_collected_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Collected_Listing_Sum_Fields = {
  __typename?: 'Artwork_collected_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Artwork_Collected_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_collected_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Collected_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_collected_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Collected_Listing_Variance_Fields = {
  __typename?: 'Artwork_collected_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "Artwork" */
export enum Artwork_Constraint {
  /** unique or primary key constraint on columns "artist_context_id", "slug" */
  ArtworkArtistContextIdSlugKey = 'Artwork_artist_context_id_slug_key',
  /** unique or primary key constraint on columns "artwork_details_id" */
  ArtworkArtworkDetailsIdKey = 'Artwork_artwork_details_id_key',
  /** unique or primary key constraint on columns "artwork_story_id" */
  ArtworkArtworkStoryIdKey = 'Artwork_artwork_story_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtworkPkey = 'Artwork_pkey'
}

/** A table to track the static milestones */
export type Artwork_Count_Milestones = {
  __typename?: 'Artwork_count_milestones';
  artwork_count: Scalars['Int'];
  id: Scalars['Int'];
  milestone_type?: Maybe<Enum_Artwork_Count_Milestone_Type_Enum>;
};

/** aggregated selection of "Artwork_count_milestones" */
export type Artwork_Count_Milestones_Aggregate = {
  __typename?: 'Artwork_count_milestones_aggregate';
  aggregate?: Maybe<Artwork_Count_Milestones_Aggregate_Fields>;
  nodes: Array<Artwork_Count_Milestones>;
};

/** aggregate fields of "Artwork_count_milestones" */
export type Artwork_Count_Milestones_Aggregate_Fields = {
  __typename?: 'Artwork_count_milestones_aggregate_fields';
  avg?: Maybe<Artwork_Count_Milestones_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Count_Milestones_Max_Fields>;
  min?: Maybe<Artwork_Count_Milestones_Min_Fields>;
  stddev?: Maybe<Artwork_Count_Milestones_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Count_Milestones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Count_Milestones_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Count_Milestones_Sum_Fields>;
  var_pop?: Maybe<Artwork_Count_Milestones_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Count_Milestones_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Count_Milestones_Variance_Fields>;
};


/** aggregate fields of "Artwork_count_milestones" */
export type Artwork_Count_Milestones_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Count_Milestones_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Count_Milestones_Avg_Fields = {
  __typename?: 'Artwork_count_milestones_avg_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_count_milestones". All fields are combined with a logical 'AND'. */
export type Artwork_Count_Milestones_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Count_Milestones_Bool_Exp>>;
  _not?: Maybe<Artwork_Count_Milestones_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Count_Milestones_Bool_Exp>>;
  artwork_count?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  milestone_type?: Maybe<Enum_Artwork_Count_Milestone_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_count_milestones" */
export enum Artwork_Count_Milestones_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkCountMilestonesPkey = 'Artwork_count_milestones_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_count_milestones" */
export type Artwork_Count_Milestones_Inc_Input = {
  artwork_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artwork_count_milestones" */
export type Artwork_Count_Milestones_Insert_Input = {
  artwork_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  milestone_type?: Maybe<Enum_Artwork_Count_Milestone_Type_Enum>;
};

/** aggregate max on columns */
export type Artwork_Count_Milestones_Max_Fields = {
  __typename?: 'Artwork_count_milestones_max_fields';
  artwork_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Artwork_Count_Milestones_Min_Fields = {
  __typename?: 'Artwork_count_milestones_min_fields';
  artwork_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Artwork_count_milestones" */
export type Artwork_Count_Milestones_Mutation_Response = {
  __typename?: 'Artwork_count_milestones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Count_Milestones>;
};

/** on_conflict condition type for table "Artwork_count_milestones" */
export type Artwork_Count_Milestones_On_Conflict = {
  constraint: Artwork_Count_Milestones_Constraint;
  update_columns?: Array<Artwork_Count_Milestones_Update_Column>;
  where?: Maybe<Artwork_Count_Milestones_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_count_milestones". */
export type Artwork_Count_Milestones_Order_By = {
  artwork_count?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_type?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_count_milestones */
export type Artwork_Count_Milestones_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Artwork_count_milestones" */
export enum Artwork_Count_Milestones_Select_Column {
  /** column name */
  ArtworkCount = 'artwork_count',
  /** column name */
  Id = 'id',
  /** column name */
  MilestoneType = 'milestone_type'
}

/** input type for updating data in table "Artwork_count_milestones" */
export type Artwork_Count_Milestones_Set_Input = {
  artwork_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  milestone_type?: Maybe<Enum_Artwork_Count_Milestone_Type_Enum>;
};

/** aggregate stddev on columns */
export type Artwork_Count_Milestones_Stddev_Fields = {
  __typename?: 'Artwork_count_milestones_stddev_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Count_Milestones_Stddev_Pop_Fields = {
  __typename?: 'Artwork_count_milestones_stddev_pop_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Count_Milestones_Stddev_Samp_Fields = {
  __typename?: 'Artwork_count_milestones_stddev_samp_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Count_Milestones_Sum_Fields = {
  __typename?: 'Artwork_count_milestones_sum_fields';
  artwork_count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Artwork_count_milestones" */
export enum Artwork_Count_Milestones_Update_Column {
  /** column name */
  ArtworkCount = 'artwork_count',
  /** column name */
  Id = 'id',
  /** column name */
  MilestoneType = 'milestone_type'
}

/** aggregate var_pop on columns */
export type Artwork_Count_Milestones_Var_Pop_Fields = {
  __typename?: 'Artwork_count_milestones_var_pop_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Count_Milestones_Var_Samp_Fields = {
  __typename?: 'Artwork_count_milestones_var_samp_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Count_Milestones_Variance_Fields = {
  __typename?: 'Artwork_count_milestones_variance_fields';
  artwork_count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_curation_score" */
export type Artwork_Curation_Score = {
  __typename?: 'Artwork_curation_score';
  /** An object relationship */
  artwork: Artwork;
  artwork_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  curator_context: Context;
  curator_context_id: Scalars['bigint'];
  score: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_curation_score" */
export type Artwork_Curation_Score_Aggregate = {
  __typename?: 'Artwork_curation_score_aggregate';
  aggregate?: Maybe<Artwork_Curation_Score_Aggregate_Fields>;
  nodes: Array<Artwork_Curation_Score>;
};

/** aggregate fields of "Artwork_curation_score" */
export type Artwork_Curation_Score_Aggregate_Fields = {
  __typename?: 'Artwork_curation_score_aggregate_fields';
  avg?: Maybe<Artwork_Curation_Score_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Curation_Score_Max_Fields>;
  min?: Maybe<Artwork_Curation_Score_Min_Fields>;
  stddev?: Maybe<Artwork_Curation_Score_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Curation_Score_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Curation_Score_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Curation_Score_Sum_Fields>;
  var_pop?: Maybe<Artwork_Curation_Score_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Curation_Score_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Curation_Score_Variance_Fields>;
};


/** aggregate fields of "Artwork_curation_score" */
export type Artwork_Curation_Score_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Curation_Score_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Curation_Score_Avg_Fields = {
  __typename?: 'Artwork_curation_score_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_curation_score". All fields are combined with a logical 'AND'. */
export type Artwork_Curation_Score_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Curation_Score_Bool_Exp>>;
  _not?: Maybe<Artwork_Curation_Score_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Curation_Score_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  curator_context?: Maybe<Context_Bool_Exp>;
  curator_context_id?: Maybe<Bigint_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_curation_score" */
export enum Artwork_Curation_Score_Constraint {
  /** unique or primary key constraint on columns "artwork_id" */
  ArtworkCurationScorePkey = 'Artwork_curation_score_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_curation_score" */
export type Artwork_Curation_Score_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artwork_curation_score" */
export type Artwork_Curation_Score_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  curator_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Curation_Score_Max_Fields = {
  __typename?: 'Artwork_curation_score_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artwork_Curation_Score_Min_Fields = {
  __typename?: 'Artwork_curation_score_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Artwork_curation_score" */
export type Artwork_Curation_Score_Mutation_Response = {
  __typename?: 'Artwork_curation_score_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Curation_Score>;
};

/** on_conflict condition type for table "Artwork_curation_score" */
export type Artwork_Curation_Score_On_Conflict = {
  constraint: Artwork_Curation_Score_Constraint;
  update_columns?: Array<Artwork_Curation_Score_Update_Column>;
  where?: Maybe<Artwork_Curation_Score_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_curation_score". */
export type Artwork_Curation_Score_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  curator_context?: Maybe<Context_Order_By>;
  curator_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_curation_score */
export type Artwork_Curation_Score_Pk_Columns_Input = {
  artwork_id: Scalars['bigint'];
};

/** select columns of table "Artwork_curation_score" */
export enum Artwork_Curation_Score_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CuratorContextId = 'curator_context_id',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_curation_score" */
export type Artwork_Curation_Score_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Curation_Score_Stddev_Fields = {
  __typename?: 'Artwork_curation_score_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Curation_Score_Stddev_Pop_Fields = {
  __typename?: 'Artwork_curation_score_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Curation_Score_Stddev_Samp_Fields = {
  __typename?: 'Artwork_curation_score_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Curation_Score_Sum_Fields = {
  __typename?: 'Artwork_curation_score_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  curator_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
};

/** update columns of table "Artwork_curation_score" */
export enum Artwork_Curation_Score_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CuratorContextId = 'curator_context_id',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Curation_Score_Var_Pop_Fields = {
  __typename?: 'Artwork_curation_score_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Curation_Score_Var_Samp_Fields = {
  __typename?: 'Artwork_curation_score_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Curation_Score_Variance_Fields = {
  __typename?: 'Artwork_curation_score_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  curator_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** Holds details about an artwork (name, description, etc) */
export type Artwork_Details = {
  __typename?: 'Artwork_details';
  /** An array relationship */
  Artwork_details_translations: Array<Artwork_Details_Translation>;
  /** An aggregate relationship */
  Artwork_details_translations_aggregate: Artwork_Details_Translation_Aggregate;
  /** An object relationship */
  ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline>;
  /** An object relationship */
  ENUM_blockchain?: Maybe<Enum_Blockchain>;
  /** An object relationship */
  Enum_art_type?: Maybe<Enum_Art_Type>;
  /** An object relationship */
  Genre?: Maybe<Interests>;
  /** An object relationship */
  Location_Map_metadata?: Maybe<File_Metadata>;
  art_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  artwork?: Maybe<Artwork>;
  blockchain?: Maybe<Enum_Blockchain_Enum>;
  blockchain_other?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Enum_Artwork_Discipline_Enum>;
  discipline_other?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id: Scalars['bigint'];
  is_dark_mode?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['geography']>;
  location_address?: Maybe<Scalars['String']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  nft_copies?: Maybe<Scalars['Int']>;
  nft_url?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  tools?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  width?: Maybe<Scalars['float8']>;
};


/** Holds details about an artwork (name, description, etc) */
export type Artwork_DetailsArtwork_Details_TranslationsArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Artwork_Details_Translation_Bool_Exp>;
};


/** Holds details about an artwork (name, description, etc) */
export type Artwork_DetailsArtwork_Details_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Artwork_Details_Translation_Bool_Exp>;
};

/** aggregated selection of "Artwork_details" */
export type Artwork_Details_Aggregate = {
  __typename?: 'Artwork_details_aggregate';
  aggregate?: Maybe<Artwork_Details_Aggregate_Fields>;
  nodes: Array<Artwork_Details>;
};

/** aggregate fields of "Artwork_details" */
export type Artwork_Details_Aggregate_Fields = {
  __typename?: 'Artwork_details_aggregate_fields';
  avg?: Maybe<Artwork_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Details_Max_Fields>;
  min?: Maybe<Artwork_Details_Min_Fields>;
  stddev?: Maybe<Artwork_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Details_Sum_Fields>;
  var_pop?: Maybe<Artwork_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Details_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Details_Variance_Fields>;
};


/** aggregate fields of "Artwork_details" */
export type Artwork_Details_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Details_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Details_Avg_Fields = {
  __typename?: 'Artwork_details_avg_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
  nft_copies?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_details". All fields are combined with a logical 'AND'. */
export type Artwork_Details_Bool_Exp = {
  Artwork_details_translations?: Maybe<Artwork_Details_Translation_Bool_Exp>;
  ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline_Bool_Exp>;
  ENUM_blockchain?: Maybe<Enum_Blockchain_Bool_Exp>;
  Enum_art_type?: Maybe<Enum_Art_Type_Bool_Exp>;
  Genre?: Maybe<Interests_Bool_Exp>;
  Location_Map_metadata?: Maybe<File_Metadata_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Details_Bool_Exp>>;
  _not?: Maybe<Artwork_Details_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Details_Bool_Exp>>;
  art_type?: Maybe<String_Comparison_Exp>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  blockchain?: Maybe<Enum_Blockchain_Enum_Comparison_Exp>;
  blockchain_other?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  creation_date_day?: Maybe<Int_Comparison_Exp>;
  creation_date_month?: Maybe<Int_Comparison_Exp>;
  creation_date_year?: Maybe<Int_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  discipline?: Maybe<Enum_Artwork_Discipline_Enum_Comparison_Exp>;
  discipline_other?: Maybe<String_Comparison_Exp>;
  duration?: Maybe<Int_Comparison_Exp>;
  genre_id?: Maybe<Bigint_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_dark_mode?: Maybe<Boolean_Comparison_Exp>;
  location?: Maybe<Geography_Comparison_Exp>;
  location_address?: Maybe<String_Comparison_Exp>;
  location_map_id?: Maybe<Bigint_Comparison_Exp>;
  materials?: Maybe<String_Comparison_Exp>;
  materials_and_medium?: Maybe<String_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  medium?: Maybe<String_Comparison_Exp>;
  nft_copies?: Maybe<Int_Comparison_Exp>;
  nft_url?: Maybe<String_Comparison_Exp>;
  technique?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  tools?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_details" */
export enum Artwork_Details_Constraint {
  /** unique or primary key constraint on columns "location_map_id" */
  ArtworkDetailsLocationMapIdKey = 'Artwork_details_location_map_id_key',
  /** unique or primary key constraint on columns "id" */
  ImageDetailsPkey = 'Image_details_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_details" */
export type Artwork_Details_Inc_Input = {
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  nft_copies?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "Artwork_details" */
export type Artwork_Details_Insert_Input = {
  Artwork_details_translations?: Maybe<Artwork_Details_Translation_Arr_Rel_Insert_Input>;
  ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline_Obj_Rel_Insert_Input>;
  ENUM_blockchain?: Maybe<Enum_Blockchain_Obj_Rel_Insert_Input>;
  Enum_art_type?: Maybe<Enum_Art_Type_Obj_Rel_Insert_Input>;
  Genre?: Maybe<Interests_Obj_Rel_Insert_Input>;
  Location_Map_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  art_type?: Maybe<Scalars['String']>;
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  blockchain?: Maybe<Enum_Blockchain_Enum>;
  blockchain_other?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Enum_Artwork_Discipline_Enum>;
  discipline_other?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  is_dark_mode?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['geography']>;
  location_address?: Maybe<Scalars['String']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  nft_copies?: Maybe<Scalars['Int']>;
  nft_url?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Artwork_Details_Max_Fields = {
  __typename?: 'Artwork_details_max_fields';
  art_type?: Maybe<Scalars['String']>;
  blockchain_other?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  discipline_other?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location_address?: Maybe<Scalars['String']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  nft_copies?: Maybe<Scalars['Int']>;
  nft_url?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Details_Min_Fields = {
  __typename?: 'Artwork_details_min_fields';
  art_type?: Maybe<Scalars['String']>;
  blockchain_other?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  discipline_other?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location_address?: Maybe<Scalars['String']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  nft_copies?: Maybe<Scalars['Int']>;
  nft_url?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "Artwork_details" */
export type Artwork_Details_Mutation_Response = {
  __typename?: 'Artwork_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Details>;
};

/** input type for inserting object relation for remote table "Artwork_details" */
export type Artwork_Details_Obj_Rel_Insert_Input = {
  data: Artwork_Details_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Details_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_details" */
export type Artwork_Details_On_Conflict = {
  constraint: Artwork_Details_Constraint;
  update_columns?: Array<Artwork_Details_Update_Column>;
  where?: Maybe<Artwork_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_details". */
export type Artwork_Details_Order_By = {
  Artwork_details_translations_aggregate?: Maybe<Artwork_Details_Translation_Aggregate_Order_By>;
  ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline_Order_By>;
  ENUM_blockchain?: Maybe<Enum_Blockchain_Order_By>;
  Enum_art_type?: Maybe<Enum_Art_Type_Order_By>;
  Genre?: Maybe<Interests_Order_By>;
  Location_Map_metadata?: Maybe<File_Metadata_Order_By>;
  art_type?: Maybe<Order_By>;
  artwork?: Maybe<Artwork_Order_By>;
  blockchain?: Maybe<Order_By>;
  blockchain_other?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creation_date_day?: Maybe<Order_By>;
  creation_date_month?: Maybe<Order_By>;
  creation_date_year?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  discipline_other?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  genre_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_dark_mode?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  location_address?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
  materials?: Maybe<Order_By>;
  materials_and_medium?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  medium?: Maybe<Order_By>;
  nft_copies?: Maybe<Order_By>;
  nft_url?: Maybe<Order_By>;
  technique?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  tools?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_details */
export type Artwork_Details_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_details" */
export enum Artwork_Details_Select_Column {
  /** column name */
  ArtType = 'art_type',
  /** column name */
  Blockchain = 'blockchain',
  /** column name */
  BlockchainOther = 'blockchain_other',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDateDay = 'creation_date_day',
  /** column name */
  CreationDateMonth = 'creation_date_month',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  Depth = 'depth',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  DisciplineOther = 'discipline_other',
  /** column name */
  Duration = 'duration',
  /** column name */
  GenreId = 'genre_id',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsDarkMode = 'is_dark_mode',
  /** column name */
  Location = 'location',
  /** column name */
  LocationAddress = 'location_address',
  /** column name */
  LocationMapId = 'location_map_id',
  /** column name */
  Materials = 'materials',
  /** column name */
  MaterialsAndMedium = 'materials_and_medium',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  Medium = 'medium',
  /** column name */
  NftCopies = 'nft_copies',
  /** column name */
  NftUrl = 'nft_url',
  /** column name */
  Technique = 'technique',
  /** column name */
  Title = 'title',
  /** column name */
  Tools = 'tools',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "Artwork_details" */
export type Artwork_Details_Set_Input = {
  art_type?: Maybe<Scalars['String']>;
  blockchain?: Maybe<Enum_Blockchain_Enum>;
  blockchain_other?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Enum_Artwork_Discipline_Enum>;
  discipline_other?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  is_dark_mode?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['geography']>;
  location_address?: Maybe<Scalars['String']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  nft_copies?: Maybe<Scalars['Int']>;
  nft_url?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Artwork_Details_Stddev_Fields = {
  __typename?: 'Artwork_details_stddev_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
  nft_copies?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Details_Stddev_Pop_Fields = {
  __typename?: 'Artwork_details_stddev_pop_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
  nft_copies?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Details_Stddev_Samp_Fields = {
  __typename?: 'Artwork_details_stddev_samp_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
  nft_copies?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Details_Sum_Fields = {
  __typename?: 'Artwork_details_sum_fields';
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  nft_copies?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** Holds the translations in different languages of the text content stored in Artwork_details */
export type Artwork_Details_Translation = {
  __typename?: 'Artwork_details_translation';
  /** An object relationship */
  Artwork_detail: Artwork_Details;
  /** An object relationship */
  Language: Languages;
  artwork_details_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  description_translation: Scalars['String'];
  dimensions?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  language_code: Scalars['String'];
  name_translation: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_details_translation" */
export type Artwork_Details_Translation_Aggregate = {
  __typename?: 'Artwork_details_translation_aggregate';
  aggregate?: Maybe<Artwork_Details_Translation_Aggregate_Fields>;
  nodes: Array<Artwork_Details_Translation>;
};

/** aggregate fields of "Artwork_details_translation" */
export type Artwork_Details_Translation_Aggregate_Fields = {
  __typename?: 'Artwork_details_translation_aggregate_fields';
  avg?: Maybe<Artwork_Details_Translation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Details_Translation_Max_Fields>;
  min?: Maybe<Artwork_Details_Translation_Min_Fields>;
  stddev?: Maybe<Artwork_Details_Translation_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Details_Translation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Details_Translation_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Details_Translation_Sum_Fields>;
  var_pop?: Maybe<Artwork_Details_Translation_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Details_Translation_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Details_Translation_Variance_Fields>;
};


/** aggregate fields of "Artwork_details_translation" */
export type Artwork_Details_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Details_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Details_Translation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Details_Translation_Max_Order_By>;
  min?: Maybe<Artwork_Details_Translation_Min_Order_By>;
  stddev?: Maybe<Artwork_Details_Translation_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Details_Translation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Details_Translation_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Details_Translation_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Details_Translation_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Details_Translation_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Details_Translation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_details_translation" */
export type Artwork_Details_Translation_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Details_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Details_Translation_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Details_Translation_Avg_Fields = {
  __typename?: 'Artwork_details_translation_avg_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Avg_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_details_translation". All fields are combined with a logical 'AND'. */
export type Artwork_Details_Translation_Bool_Exp = {
  Artwork_detail?: Maybe<Artwork_Details_Bool_Exp>;
  Language?: Maybe<Languages_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Details_Translation_Bool_Exp>>;
  _not?: Maybe<Artwork_Details_Translation_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Details_Translation_Bool_Exp>>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description_translation?: Maybe<String_Comparison_Exp>;
  dimensions?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  language_code?: Maybe<String_Comparison_Exp>;
  name_translation?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_details_translation" */
export enum Artwork_Details_Translation_Constraint {
  /** unique or primary key constraint on columns "id" */
  ImageDetailsTranslationPkey = 'Image_details_translation_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_details_translation" */
export type Artwork_Details_Translation_Inc_Input = {
  artwork_details_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_details_translation" */
export type Artwork_Details_Translation_Insert_Input = {
  Artwork_detail?: Maybe<Artwork_Details_Obj_Rel_Insert_Input>;
  Language?: Maybe<Languages_Obj_Rel_Insert_Input>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Details_Translation_Max_Fields = {
  __typename?: 'Artwork_details_translation_max_fields';
  artwork_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Max_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  dimensions?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  name_translation?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Details_Translation_Min_Fields = {
  __typename?: 'Artwork_details_translation_min_fields';
  artwork_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Min_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  dimensions?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  name_translation?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_details_translation" */
export type Artwork_Details_Translation_Mutation_Response = {
  __typename?: 'Artwork_details_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Details_Translation>;
};

/** on_conflict condition type for table "Artwork_details_translation" */
export type Artwork_Details_Translation_On_Conflict = {
  constraint: Artwork_Details_Translation_Constraint;
  update_columns?: Array<Artwork_Details_Translation_Update_Column>;
  where?: Maybe<Artwork_Details_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_details_translation". */
export type Artwork_Details_Translation_Order_By = {
  Artwork_detail?: Maybe<Artwork_Details_Order_By>;
  Language?: Maybe<Languages_Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  dimensions?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  name_translation?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_details_translation */
export type Artwork_Details_Translation_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_details_translation" */
export enum Artwork_Details_Translation_Select_Column {
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionTranslation = 'description_translation',
  /** column name */
  Dimensions = 'dimensions',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  NameTranslation = 'name_translation',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_details_translation" */
export type Artwork_Details_Translation_Set_Input = {
  artwork_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Details_Translation_Stddev_Fields = {
  __typename?: 'Artwork_details_translation_stddev_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Stddev_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Details_Translation_Stddev_Pop_Fields = {
  __typename?: 'Artwork_details_translation_stddev_pop_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Stddev_Pop_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Details_Translation_Stddev_Samp_Fields = {
  __typename?: 'Artwork_details_translation_stddev_samp_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Stddev_Samp_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Details_Translation_Sum_Fields = {
  __typename?: 'Artwork_details_translation_sum_fields';
  artwork_details_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Sum_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_details_translation" */
export enum Artwork_Details_Translation_Update_Column {
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionTranslation = 'description_translation',
  /** column name */
  Dimensions = 'dimensions',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  NameTranslation = 'name_translation',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Details_Translation_Var_Pop_Fields = {
  __typename?: 'Artwork_details_translation_var_pop_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Var_Pop_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Details_Translation_Var_Samp_Fields = {
  __typename?: 'Artwork_details_translation_var_samp_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Var_Samp_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Details_Translation_Variance_Fields = {
  __typename?: 'Artwork_details_translation_variance_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_details_translation" */
export type Artwork_Details_Translation_Variance_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_details" */
export enum Artwork_Details_Update_Column {
  /** column name */
  ArtType = 'art_type',
  /** column name */
  Blockchain = 'blockchain',
  /** column name */
  BlockchainOther = 'blockchain_other',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDateDay = 'creation_date_day',
  /** column name */
  CreationDateMonth = 'creation_date_month',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  Depth = 'depth',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  DisciplineOther = 'discipline_other',
  /** column name */
  Duration = 'duration',
  /** column name */
  GenreId = 'genre_id',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsDarkMode = 'is_dark_mode',
  /** column name */
  Location = 'location',
  /** column name */
  LocationAddress = 'location_address',
  /** column name */
  LocationMapId = 'location_map_id',
  /** column name */
  Materials = 'materials',
  /** column name */
  MaterialsAndMedium = 'materials_and_medium',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  Medium = 'medium',
  /** column name */
  NftCopies = 'nft_copies',
  /** column name */
  NftUrl = 'nft_url',
  /** column name */
  Technique = 'technique',
  /** column name */
  Title = 'title',
  /** column name */
  Tools = 'tools',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Width = 'width'
}

/** aggregate var_pop on columns */
export type Artwork_Details_Var_Pop_Fields = {
  __typename?: 'Artwork_details_var_pop_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
  nft_copies?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Details_Var_Samp_Fields = {
  __typename?: 'Artwork_details_var_samp_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
  nft_copies?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Details_Variance_Fields = {
  __typename?: 'Artwork_details_variance_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
  nft_copies?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_digital_twin" */
export type Artwork_Digital_Twin = {
  __typename?: 'Artwork_digital_twin';
  artwork_id: Scalars['bigint'];
  /** An object relationship */
  details?: Maybe<Digital_Twin_Details>;
  digital_twin_id: Scalars['bigint'];
};

/** aggregated selection of "Artwork_digital_twin" */
export type Artwork_Digital_Twin_Aggregate = {
  __typename?: 'Artwork_digital_twin_aggregate';
  aggregate?: Maybe<Artwork_Digital_Twin_Aggregate_Fields>;
  nodes: Array<Artwork_Digital_Twin>;
};

/** aggregate fields of "Artwork_digital_twin" */
export type Artwork_Digital_Twin_Aggregate_Fields = {
  __typename?: 'Artwork_digital_twin_aggregate_fields';
  avg?: Maybe<Artwork_Digital_Twin_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Digital_Twin_Max_Fields>;
  min?: Maybe<Artwork_Digital_Twin_Min_Fields>;
  stddev?: Maybe<Artwork_Digital_Twin_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Digital_Twin_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Digital_Twin_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Digital_Twin_Sum_Fields>;
  var_pop?: Maybe<Artwork_Digital_Twin_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Digital_Twin_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Digital_Twin_Variance_Fields>;
};


/** aggregate fields of "Artwork_digital_twin" */
export type Artwork_Digital_Twin_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Digital_Twin_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Digital_Twin_Avg_Fields = {
  __typename?: 'Artwork_digital_twin_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  digital_twin_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_digital_twin". All fields are combined with a logical 'AND'. */
export type Artwork_Digital_Twin_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Digital_Twin_Bool_Exp>>;
  _not?: Maybe<Artwork_Digital_Twin_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Digital_Twin_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  details?: Maybe<Digital_Twin_Details_Bool_Exp>;
  digital_twin_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_digital_twin" */
export enum Artwork_Digital_Twin_Constraint {
  /** unique or primary key constraint on columns "digital_twin_id" */
  ArtworkDigitalTwinPkey = 'Artwork_digital_twin_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_digital_twin" */
export type Artwork_Digital_Twin_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_digital_twin" */
export type Artwork_Digital_Twin_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  details?: Maybe<Digital_Twin_Details_Obj_Rel_Insert_Input>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Artwork_Digital_Twin_Max_Fields = {
  __typename?: 'Artwork_digital_twin_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Artwork_Digital_Twin_Min_Fields = {
  __typename?: 'Artwork_digital_twin_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Artwork_digital_twin" */
export type Artwork_Digital_Twin_Mutation_Response = {
  __typename?: 'Artwork_digital_twin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Digital_Twin>;
};

/** on_conflict condition type for table "Artwork_digital_twin" */
export type Artwork_Digital_Twin_On_Conflict = {
  constraint: Artwork_Digital_Twin_Constraint;
  update_columns?: Array<Artwork_Digital_Twin_Update_Column>;
  where?: Maybe<Artwork_Digital_Twin_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_digital_twin". */
export type Artwork_Digital_Twin_Order_By = {
  artwork_id?: Maybe<Order_By>;
  details?: Maybe<Digital_Twin_Details_Order_By>;
  digital_twin_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_digital_twin */
export type Artwork_Digital_Twin_Pk_Columns_Input = {
  digital_twin_id: Scalars['bigint'];
};

/** select columns of table "Artwork_digital_twin" */
export enum Artwork_Digital_Twin_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  DigitalTwinId = 'digital_twin_id'
}

/** input type for updating data in table "Artwork_digital_twin" */
export type Artwork_Digital_Twin_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Artwork_Digital_Twin_Stddev_Fields = {
  __typename?: 'Artwork_digital_twin_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  digital_twin_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Digital_Twin_Stddev_Pop_Fields = {
  __typename?: 'Artwork_digital_twin_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  digital_twin_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Digital_Twin_Stddev_Samp_Fields = {
  __typename?: 'Artwork_digital_twin_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  digital_twin_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Digital_Twin_Sum_Fields = {
  __typename?: 'Artwork_digital_twin_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Artwork_digital_twin" */
export enum Artwork_Digital_Twin_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  DigitalTwinId = 'digital_twin_id'
}

/** aggregate var_pop on columns */
export type Artwork_Digital_Twin_Var_Pop_Fields = {
  __typename?: 'Artwork_digital_twin_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  digital_twin_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Digital_Twin_Var_Samp_Fields = {
  __typename?: 'Artwork_digital_twin_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  digital_twin_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Digital_Twin_Variance_Fields = {
  __typename?: 'Artwork_digital_twin_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  digital_twin_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_documents_info" */
export type Artwork_Documents_Info = {
  __typename?: 'Artwork_documents_info';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_is_available_sale?: Maybe<Scalars['Boolean']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  last_transaction_artwork_id?: Maybe<Scalars['bigint']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Artwork_documents_info" */
export type Artwork_Documents_Info_Aggregate = {
  __typename?: 'Artwork_documents_info_aggregate';
  aggregate?: Maybe<Artwork_Documents_Info_Aggregate_Fields>;
  nodes: Array<Artwork_Documents_Info>;
};

/** aggregate fields of "Artwork_documents_info" */
export type Artwork_Documents_Info_Aggregate_Fields = {
  __typename?: 'Artwork_documents_info_aggregate_fields';
  avg?: Maybe<Artwork_Documents_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Documents_Info_Max_Fields>;
  min?: Maybe<Artwork_Documents_Info_Min_Fields>;
  stddev?: Maybe<Artwork_Documents_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Documents_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Documents_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Documents_Info_Sum_Fields>;
  var_pop?: Maybe<Artwork_Documents_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Documents_Info_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Documents_Info_Variance_Fields>;
};


/** aggregate fields of "Artwork_documents_info" */
export type Artwork_Documents_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Documents_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Documents_Info_Avg_Fields = {
  __typename?: 'Artwork_documents_info_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_documents_info". All fields are combined with a logical 'AND'. */
export type Artwork_Documents_Info_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Documents_Info_Bool_Exp>>;
  _not?: Maybe<Artwork_Documents_Info_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Documents_Info_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  available_print_amount?: Maybe<Bigint_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  edition_is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  last_transaction_artwork_id?: Maybe<Bigint_Comparison_Exp>;
  last_transaction_id?: Maybe<Bigint_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  original_artwork_id?: Maybe<Bigint_Comparison_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  vuforia_rating?: Maybe<Int_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Documents_Info_Max_Fields = {
  __typename?: 'Artwork_documents_info_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  last_transaction_artwork_id?: Maybe<Scalars['bigint']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Documents_Info_Min_Fields = {
  __typename?: 'Artwork_documents_info_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  last_transaction_artwork_id?: Maybe<Scalars['bigint']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "Artwork_documents_info". */
export type Artwork_Documents_Info_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  edition_is_available_sale?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  last_transaction_artwork_id?: Maybe<Order_By>;
  last_transaction_id?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  original_artwork_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Artwork_documents_info" */
export enum Artwork_Documents_Info_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AvailablePrintAmount = 'available_print_amount',
  /** column name */
  Depth = 'depth',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  EditionIsAvailableSale = 'edition_is_available_sale',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  Height = 'height',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  LastTransactionArtworkId = 'last_transaction_artwork_id',
  /** column name */
  LastTransactionId = 'last_transaction_id',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  OriginalArtworkId = 'original_artwork_id',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  VuforiaRating = 'vuforia_rating',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Documents_Info_Stddev_Fields = {
  __typename?: 'Artwork_documents_info_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Documents_Info_Stddev_Pop_Fields = {
  __typename?: 'Artwork_documents_info_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Documents_Info_Stddev_Samp_Fields = {
  __typename?: 'Artwork_documents_info_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Documents_Info_Sum_Fields = {
  __typename?: 'Artwork_documents_info_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  height?: Maybe<Scalars['float8']>;
  last_transaction_artwork_id?: Maybe<Scalars['bigint']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Documents_Info_Var_Pop_Fields = {
  __typename?: 'Artwork_documents_info_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Documents_Info_Var_Samp_Fields = {
  __typename?: 'Artwork_documents_info_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Documents_Info_Variance_Fields = {
  __typename?: 'Artwork_documents_info_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Artwork Events List */
export type Artwork_Events = {
  __typename?: 'Artwork_events';
  /** An object relationship */
  Artwork?: Maybe<Artwork>;
  /** An object relationship */
  Artwork_transaction?: Maybe<Artwork_Transactions>;
  /** An object relationship */
  Context?: Maybe<Context>;
  /** An object relationship */
  ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  order?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** Artwork Events List */
export type Artwork_EventsPayloadArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artwork_events" */
export type Artwork_Events_Aggregate = {
  __typename?: 'Artwork_events_aggregate';
  aggregate?: Maybe<Artwork_Events_Aggregate_Fields>;
  nodes: Array<Artwork_Events>;
};

/** aggregate fields of "Artwork_events" */
export type Artwork_Events_Aggregate_Fields = {
  __typename?: 'Artwork_events_aggregate_fields';
  avg?: Maybe<Artwork_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Events_Max_Fields>;
  min?: Maybe<Artwork_Events_Min_Fields>;
  stddev?: Maybe<Artwork_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Events_Sum_Fields>;
  var_pop?: Maybe<Artwork_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Events_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Events_Variance_Fields>;
};


/** aggregate fields of "Artwork_events" */
export type Artwork_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_events" */
export type Artwork_Events_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Events_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Events_Max_Order_By>;
  min?: Maybe<Artwork_Events_Min_Order_By>;
  stddev?: Maybe<Artwork_Events_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Events_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Events_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Events_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Events_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Events_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Events_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Artwork_Events_Append_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Artwork_events" */
export type Artwork_Events_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Events_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Events_Avg_Fields = {
  __typename?: 'Artwork_events_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_events" */
export type Artwork_Events_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_events". All fields are combined with a logical 'AND'. */
export type Artwork_Events_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  Artwork_transaction?: Maybe<Artwork_Transactions_Bool_Exp>;
  Context?: Maybe<Context_Bool_Exp>;
  ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Events_Bool_Exp>>;
  _not?: Maybe<Artwork_Events_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Events_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_transaction_id?: Maybe<Bigint_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  event_date?: Maybe<Timestamptz_Comparison_Exp>;
  event_message?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  payload?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_events" */
export enum Artwork_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkEventsPkey = 'Artwork_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Artwork_Events_Delete_At_Path_Input = {
  payload?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Artwork_Events_Delete_Elem_Input = {
  payload?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Artwork_Events_Delete_Key_Input = {
  payload?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Artwork_events" */
export type Artwork_Events_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artwork_events" */
export type Artwork_Events_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  Artwork_transaction?: Maybe<Artwork_Transactions_Obj_Rel_Insert_Input>;
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Events_Max_Fields = {
  __typename?: 'Artwork_events_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_events" */
export type Artwork_Events_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  event_message?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Events_Min_Fields = {
  __typename?: 'Artwork_events_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_events" */
export type Artwork_Events_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  event_message?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_events" */
export type Artwork_Events_Mutation_Response = {
  __typename?: 'Artwork_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Events>;
};

/** on_conflict condition type for table "Artwork_events" */
export type Artwork_Events_On_Conflict = {
  constraint: Artwork_Events_Constraint;
  update_columns?: Array<Artwork_Events_Update_Column>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_events". */
export type Artwork_Events_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  Artwork_transaction?: Maybe<Artwork_Transactions_Order_By>;
  Context?: Maybe<Context_Order_By>;
  ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type_Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  event_message?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_events */
export type Artwork_Events_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Artwork_Events_Prepend_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Artwork_events" */
export enum Artwork_Events_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkTransactionId = 'artwork_transaction_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventMessage = 'event_message',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Payload = 'payload',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_events" */
export type Artwork_Events_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Events_Stddev_Fields = {
  __typename?: 'Artwork_events_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_events" */
export type Artwork_Events_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Events_Stddev_Pop_Fields = {
  __typename?: 'Artwork_events_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_events" */
export type Artwork_Events_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Events_Stddev_Samp_Fields = {
  __typename?: 'Artwork_events_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_events" */
export type Artwork_Events_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Events_Sum_Fields = {
  __typename?: 'Artwork_events_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Artwork_events" */
export type Artwork_Events_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "Artwork_events" */
export enum Artwork_Events_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkTransactionId = 'artwork_transaction_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventMessage = 'event_message',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Payload = 'payload',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Events_Var_Pop_Fields = {
  __typename?: 'Artwork_events_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_events" */
export type Artwork_Events_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Events_Var_Samp_Fields = {
  __typename?: 'Artwork_events_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_events" */
export type Artwork_Events_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Events_Variance_Fields = {
  __typename?: 'Artwork_events_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_events" */
export type Artwork_Events_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** columns and relationships of "Artwork_explore_info" */
export type Artwork_Explore_Info = {
  __typename?: 'Artwork_explore_info';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_is_available_sale?: Maybe<Scalars['Boolean']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  last_transaction_artwork_id?: Maybe<Scalars['bigint']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Artwork_explore_info" */
export type Artwork_Explore_Info_Aggregate = {
  __typename?: 'Artwork_explore_info_aggregate';
  aggregate?: Maybe<Artwork_Explore_Info_Aggregate_Fields>;
  nodes: Array<Artwork_Explore_Info>;
};

/** aggregate fields of "Artwork_explore_info" */
export type Artwork_Explore_Info_Aggregate_Fields = {
  __typename?: 'Artwork_explore_info_aggregate_fields';
  avg?: Maybe<Artwork_Explore_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Explore_Info_Max_Fields>;
  min?: Maybe<Artwork_Explore_Info_Min_Fields>;
  stddev?: Maybe<Artwork_Explore_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Explore_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Explore_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Explore_Info_Sum_Fields>;
  var_pop?: Maybe<Artwork_Explore_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Explore_Info_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Explore_Info_Variance_Fields>;
};


/** aggregate fields of "Artwork_explore_info" */
export type Artwork_Explore_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Explore_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Explore_Info_Avg_Fields = {
  __typename?: 'Artwork_explore_info_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_explore_info". All fields are combined with a logical 'AND'. */
export type Artwork_Explore_Info_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Explore_Info_Bool_Exp>>;
  _not?: Maybe<Artwork_Explore_Info_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Explore_Info_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  available_print_amount?: Maybe<Bigint_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  edition_is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  last_transaction_artwork_id?: Maybe<Bigint_Comparison_Exp>;
  last_transaction_id?: Maybe<Bigint_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  original_artwork_id?: Maybe<Bigint_Comparison_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  vuforia_rating?: Maybe<Int_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Explore_Info_Max_Fields = {
  __typename?: 'Artwork_explore_info_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  last_transaction_artwork_id?: Maybe<Scalars['bigint']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Explore_Info_Min_Fields = {
  __typename?: 'Artwork_explore_info_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  last_transaction_artwork_id?: Maybe<Scalars['bigint']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "Artwork_explore_info". */
export type Artwork_Explore_Info_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  edition_is_available_sale?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  last_transaction_artwork_id?: Maybe<Order_By>;
  last_transaction_id?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  original_artwork_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Artwork_explore_info" */
export enum Artwork_Explore_Info_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AvailablePrintAmount = 'available_print_amount',
  /** column name */
  Depth = 'depth',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  EditionIsAvailableSale = 'edition_is_available_sale',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  Height = 'height',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  LastTransactionArtworkId = 'last_transaction_artwork_id',
  /** column name */
  LastTransactionId = 'last_transaction_id',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  OriginalArtworkId = 'original_artwork_id',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  VuforiaRating = 'vuforia_rating',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Explore_Info_Stddev_Fields = {
  __typename?: 'Artwork_explore_info_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Explore_Info_Stddev_Pop_Fields = {
  __typename?: 'Artwork_explore_info_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Explore_Info_Stddev_Samp_Fields = {
  __typename?: 'Artwork_explore_info_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Explore_Info_Sum_Fields = {
  __typename?: 'Artwork_explore_info_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  height?: Maybe<Scalars['float8']>;
  last_transaction_artwork_id?: Maybe<Scalars['bigint']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Explore_Info_Var_Pop_Fields = {
  __typename?: 'Artwork_explore_info_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Explore_Info_Var_Samp_Fields = {
  __typename?: 'Artwork_explore_info_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Explore_Info_Variance_Fields = {
  __typename?: 'Artwork_explore_info_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_artwork_id?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_explore_listing" */
export type Artwork_Explore_Listing = {
  __typename?: 'Artwork_explore_listing';
  artist_context_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  artist_recognitions: Array<Social_Graph_Followers>;
  /** An aggregate relationship */
  artist_recognitions_aggregate: Social_Graph_Followers_Aggregate;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Artwork_explore_listing" */
export type Artwork_Explore_ListingArtist_RecognitionsArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


/** columns and relationships of "Artwork_explore_listing" */
export type Artwork_Explore_ListingArtist_Recognitions_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};

/** aggregated selection of "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Aggregate = {
  __typename?: 'Artwork_explore_listing_aggregate';
  aggregate?: Maybe<Artwork_Explore_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Explore_Listing>;
};

/** aggregate fields of "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_explore_listing_aggregate_fields';
  avg?: Maybe<Artwork_Explore_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Explore_Listing_Max_Fields>;
  min?: Maybe<Artwork_Explore_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Explore_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Explore_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Explore_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Explore_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Explore_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Explore_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Explore_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Explore_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Explore_Listing_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Explore_Listing_Max_Order_By>;
  min?: Maybe<Artwork_Explore_Listing_Min_Order_By>;
  stddev?: Maybe<Artwork_Explore_Listing_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Explore_Listing_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Explore_Listing_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Explore_Listing_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Explore_Listing_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Explore_Listing_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Explore_Listing_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Explore_Listing_Insert_Input>;
};

/** aggregate avg on columns */
export type Artwork_Explore_Listing_Avg_Fields = {
  __typename?: 'Artwork_explore_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Avg_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_explore_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Explore_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Explore_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Explore_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Explore_Listing_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_recognitions?: Maybe<Social_Graph_Followers_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_score?: Maybe<Int_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  vuforia_rating?: Maybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Insert_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_recognitions?: Maybe<Social_Graph_Followers_Arr_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Artwork_Explore_Listing_Max_Fields = {
  __typename?: 'Artwork_explore_listing_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Max_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Explore_Listing_Min_Fields = {
  __typename?: 'Artwork_explore_listing_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Min_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "Artwork_explore_listing". */
export type Artwork_Explore_Listing_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_recognitions_aggregate?: Maybe<Social_Graph_Followers_Aggregate_Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** select columns of table "Artwork_explore_listing" */
export enum Artwork_Explore_Listing_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkScore = 'artwork_score',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Score = 'score',
  /** column name */
  VuforiaRating = 'vuforia_rating'
}

/** aggregate stddev on columns */
export type Artwork_Explore_Listing_Stddev_Fields = {
  __typename?: 'Artwork_explore_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Stddev_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Explore_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_explore_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Stddev_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Explore_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_explore_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Stddev_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Explore_Listing_Sum_Fields = {
  __typename?: 'Artwork_explore_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Sum_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Artwork_Explore_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_explore_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Var_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Explore_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_explore_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Var_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Explore_Listing_Variance_Fields = {
  __typename?: 'Artwork_explore_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_explore_listing" */
export type Artwork_Explore_Listing_Variance_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** columns and relationships of "Artwork_external_buy_link" */
export type Artwork_External_Buy_Link = {
  __typename?: 'Artwork_external_buy_link';
  artwork_id: Scalars['bigint'];
  external_link?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artwork_external_buy_link" */
export type Artwork_External_Buy_Link_Aggregate = {
  __typename?: 'Artwork_external_buy_link_aggregate';
  aggregate?: Maybe<Artwork_External_Buy_Link_Aggregate_Fields>;
  nodes: Array<Artwork_External_Buy_Link>;
};

/** aggregate fields of "Artwork_external_buy_link" */
export type Artwork_External_Buy_Link_Aggregate_Fields = {
  __typename?: 'Artwork_external_buy_link_aggregate_fields';
  avg?: Maybe<Artwork_External_Buy_Link_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_External_Buy_Link_Max_Fields>;
  min?: Maybe<Artwork_External_Buy_Link_Min_Fields>;
  stddev?: Maybe<Artwork_External_Buy_Link_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_External_Buy_Link_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_External_Buy_Link_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_External_Buy_Link_Sum_Fields>;
  var_pop?: Maybe<Artwork_External_Buy_Link_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_External_Buy_Link_Var_Samp_Fields>;
  variance?: Maybe<Artwork_External_Buy_Link_Variance_Fields>;
};


/** aggregate fields of "Artwork_external_buy_link" */
export type Artwork_External_Buy_Link_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_External_Buy_Link_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_External_Buy_Link_Avg_Fields = {
  __typename?: 'Artwork_external_buy_link_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_external_buy_link". All fields are combined with a logical 'AND'. */
export type Artwork_External_Buy_Link_Bool_Exp = {
  _and?: Maybe<Array<Artwork_External_Buy_Link_Bool_Exp>>;
  _not?: Maybe<Artwork_External_Buy_Link_Bool_Exp>;
  _or?: Maybe<Array<Artwork_External_Buy_Link_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  external_link?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_external_buy_link" */
export enum Artwork_External_Buy_Link_Constraint {
  /** unique or primary key constraint on columns "artwork_id" */
  ArtworkExternalBuyLinkPkey = 'Artwork_external_buy_link_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_external_buy_link" */
export type Artwork_External_Buy_Link_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_external_buy_link" */
export type Artwork_External_Buy_Link_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  external_link?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Artwork_External_Buy_Link_Max_Fields = {
  __typename?: 'Artwork_external_buy_link_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  external_link?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artwork_External_Buy_Link_Min_Fields = {
  __typename?: 'Artwork_external_buy_link_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  external_link?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Artwork_external_buy_link" */
export type Artwork_External_Buy_Link_Mutation_Response = {
  __typename?: 'Artwork_external_buy_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_External_Buy_Link>;
};

/** on_conflict condition type for table "Artwork_external_buy_link" */
export type Artwork_External_Buy_Link_On_Conflict = {
  constraint: Artwork_External_Buy_Link_Constraint;
  update_columns?: Array<Artwork_External_Buy_Link_Update_Column>;
  where?: Maybe<Artwork_External_Buy_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_external_buy_link". */
export type Artwork_External_Buy_Link_Order_By = {
  artwork_id?: Maybe<Order_By>;
  external_link?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_external_buy_link */
export type Artwork_External_Buy_Link_Pk_Columns_Input = {
  artwork_id: Scalars['bigint'];
};

/** select columns of table "Artwork_external_buy_link" */
export enum Artwork_External_Buy_Link_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ExternalLink = 'external_link'
}

/** input type for updating data in table "Artwork_external_buy_link" */
export type Artwork_External_Buy_Link_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  external_link?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Artwork_External_Buy_Link_Stddev_Fields = {
  __typename?: 'Artwork_external_buy_link_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_External_Buy_Link_Stddev_Pop_Fields = {
  __typename?: 'Artwork_external_buy_link_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_External_Buy_Link_Stddev_Samp_Fields = {
  __typename?: 'Artwork_external_buy_link_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_External_Buy_Link_Sum_Fields = {
  __typename?: 'Artwork_external_buy_link_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Artwork_external_buy_link" */
export enum Artwork_External_Buy_Link_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ExternalLink = 'external_link'
}

/** aggregate var_pop on columns */
export type Artwork_External_Buy_Link_Var_Pop_Fields = {
  __typename?: 'Artwork_external_buy_link_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_External_Buy_Link_Var_Samp_Fields = {
  __typename?: 'Artwork_external_buy_link_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_External_Buy_Link_Variance_Fields = {
  __typename?: 'Artwork_external_buy_link_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_favorite_listing" */
export type Artwork_Favorite_Listing = {
  __typename?: 'Artwork_favorite_listing';
  artist_context_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  artist_price_range: Array<Profile_Price_Ranges>;
  /** An aggregate relationship */
  artist_price_range_aggregate: Profile_Price_Ranges_Aggregate;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  favorited_at?: Maybe<Scalars['timestamptz']>;
  is_available?: Maybe<Scalars['Boolean']>;
  order_in_collection?: Maybe<Scalars['Int']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "Artwork_favorite_listing" */
export type Artwork_Favorite_ListingArtist_Price_RangeArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** columns and relationships of "Artwork_favorite_listing" */
export type Artwork_Favorite_ListingArtist_Price_Range_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};

/** aggregated selection of "Artwork_favorite_listing" */
export type Artwork_Favorite_Listing_Aggregate = {
  __typename?: 'Artwork_favorite_listing_aggregate';
  aggregate?: Maybe<Artwork_Favorite_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Favorite_Listing>;
};

/** aggregate fields of "Artwork_favorite_listing" */
export type Artwork_Favorite_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_favorite_listing_aggregate_fields';
  avg?: Maybe<Artwork_Favorite_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Favorite_Listing_Max_Fields>;
  min?: Maybe<Artwork_Favorite_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Favorite_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Favorite_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Favorite_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Favorite_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Favorite_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Favorite_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Favorite_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_favorite_listing" */
export type Artwork_Favorite_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Favorite_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Favorite_Listing_Avg_Fields = {
  __typename?: 'Artwork_favorite_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  order_in_collection?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_favorite_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Favorite_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Favorite_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Favorite_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Favorite_Listing_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_price_range?: Maybe<Profile_Price_Ranges_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
  collected_by_context_id?: Maybe<Bigint_Comparison_Exp>;
  collection_id?: Maybe<Bigint_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  favorited_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  order_in_collection?: Maybe<Int_Comparison_Exp>;
  owner_context_id?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Favorite_Listing_Max_Fields = {
  __typename?: 'Artwork_favorite_listing_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  favorited_at?: Maybe<Scalars['timestamptz']>;
  order_in_collection?: Maybe<Scalars['Int']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Artwork_Favorite_Listing_Min_Fields = {
  __typename?: 'Artwork_favorite_listing_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  favorited_at?: Maybe<Scalars['timestamptz']>;
  order_in_collection?: Maybe<Scalars['Int']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "Artwork_favorite_listing". */
export type Artwork_Favorite_Listing_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_price_range_aggregate?: Maybe<Profile_Price_Ranges_Aggregate_Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  collected_by_context_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  favorited_at?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  order_in_collection?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
};

/** select columns of table "Artwork_favorite_listing" */
export enum Artwork_Favorite_Listing_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AspectRatio = 'aspect_ratio',
  /** column name */
  CollectedByContextId = 'collected_by_context_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  FavoritedAt = 'favorited_at',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  OrderInCollection = 'order_in_collection',
  /** column name */
  OwnerContextId = 'owner_context_id'
}

/** aggregate stddev on columns */
export type Artwork_Favorite_Listing_Stddev_Fields = {
  __typename?: 'Artwork_favorite_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  order_in_collection?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Favorite_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_favorite_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  order_in_collection?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Favorite_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_favorite_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  order_in_collection?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Favorite_Listing_Sum_Fields = {
  __typename?: 'Artwork_favorite_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  order_in_collection?: Maybe<Scalars['Int']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artwork_Favorite_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_favorite_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  order_in_collection?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Favorite_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_favorite_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  order_in_collection?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Favorite_Listing_Variance_Fields = {
  __typename?: 'Artwork_favorite_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  order_in_collection?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_favorited_by_listing" */
export type Artwork_Favorited_By_Listing = {
  __typename?: 'Artwork_favorited_by_listing';
  /** An object relationship */
  artist?: Maybe<Context>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  favorited_at?: Maybe<Scalars['timestamptz']>;
  favorited_by?: Maybe<Scalars['bigint']>;
  favorited_by_first_name?: Maybe<Scalars['String']>;
  favorited_by_last_name?: Maybe<Scalars['String']>;
  favorited_by_privacy?: Maybe<Scalars['String']>;
  favorited_by_title?: Maybe<Scalars['String']>;
  favorited_by_type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artwork_favorited_by_listing" */
export type Artwork_Favorited_By_Listing_Aggregate = {
  __typename?: 'Artwork_favorited_by_listing_aggregate';
  aggregate?: Maybe<Artwork_Favorited_By_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Favorited_By_Listing>;
};

/** aggregate fields of "Artwork_favorited_by_listing" */
export type Artwork_Favorited_By_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_favorited_by_listing_aggregate_fields';
  avg?: Maybe<Artwork_Favorited_By_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Favorited_By_Listing_Max_Fields>;
  min?: Maybe<Artwork_Favorited_By_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Favorited_By_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Favorited_By_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Favorited_By_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Favorited_By_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Favorited_By_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Favorited_By_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Favorited_By_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_favorited_by_listing" */
export type Artwork_Favorited_By_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Favorited_By_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Favorited_By_Listing_Avg_Fields = {
  __typename?: 'Artwork_favorited_by_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  favorited_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_favorited_by_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Favorited_By_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Favorited_By_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Favorited_By_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Favorited_By_Listing_Bool_Exp>>;
  artist?: Maybe<Context_Bool_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  favorited_at?: Maybe<Timestamptz_Comparison_Exp>;
  favorited_by?: Maybe<Bigint_Comparison_Exp>;
  favorited_by_first_name?: Maybe<String_Comparison_Exp>;
  favorited_by_last_name?: Maybe<String_Comparison_Exp>;
  favorited_by_privacy?: Maybe<String_Comparison_Exp>;
  favorited_by_title?: Maybe<String_Comparison_Exp>;
  favorited_by_type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Favorited_By_Listing_Max_Fields = {
  __typename?: 'Artwork_favorited_by_listing_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  favorited_at?: Maybe<Scalars['timestamptz']>;
  favorited_by?: Maybe<Scalars['bigint']>;
  favorited_by_first_name?: Maybe<Scalars['String']>;
  favorited_by_last_name?: Maybe<Scalars['String']>;
  favorited_by_privacy?: Maybe<Scalars['String']>;
  favorited_by_title?: Maybe<Scalars['String']>;
  favorited_by_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artwork_Favorited_By_Listing_Min_Fields = {
  __typename?: 'Artwork_favorited_by_listing_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  favorited_at?: Maybe<Scalars['timestamptz']>;
  favorited_by?: Maybe<Scalars['bigint']>;
  favorited_by_first_name?: Maybe<Scalars['String']>;
  favorited_by_last_name?: Maybe<Scalars['String']>;
  favorited_by_privacy?: Maybe<Scalars['String']>;
  favorited_by_title?: Maybe<Scalars['String']>;
  favorited_by_type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artwork_favorited_by_listing". */
export type Artwork_Favorited_By_Listing_Order_By = {
  artist?: Maybe<Context_Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  favorited_at?: Maybe<Order_By>;
  favorited_by?: Maybe<Order_By>;
  favorited_by_first_name?: Maybe<Order_By>;
  favorited_by_last_name?: Maybe<Order_By>;
  favorited_by_privacy?: Maybe<Order_By>;
  favorited_by_title?: Maybe<Order_By>;
  favorited_by_type?: Maybe<Order_By>;
};

/** select columns of table "Artwork_favorited_by_listing" */
export enum Artwork_Favorited_By_Listing_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  FavoritedAt = 'favorited_at',
  /** column name */
  FavoritedBy = 'favorited_by',
  /** column name */
  FavoritedByFirstName = 'favorited_by_first_name',
  /** column name */
  FavoritedByLastName = 'favorited_by_last_name',
  /** column name */
  FavoritedByPrivacy = 'favorited_by_privacy',
  /** column name */
  FavoritedByTitle = 'favorited_by_title',
  /** column name */
  FavoritedByType = 'favorited_by_type'
}

/** aggregate stddev on columns */
export type Artwork_Favorited_By_Listing_Stddev_Fields = {
  __typename?: 'Artwork_favorited_by_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  favorited_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Favorited_By_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_favorited_by_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  favorited_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Favorited_By_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_favorited_by_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  favorited_by?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Favorited_By_Listing_Sum_Fields = {
  __typename?: 'Artwork_favorited_by_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  favorited_by?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artwork_Favorited_By_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_favorited_by_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  favorited_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Favorited_By_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_favorited_by_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  favorited_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Favorited_By_Listing_Variance_Fields = {
  __typename?: 'Artwork_favorited_by_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  favorited_by?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_favorites_art_universe_listing" */
export type Artwork_Favorites_Art_Universe_Listing = {
  __typename?: 'Artwork_favorites_art_universe_listing';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_depth?: Maybe<Scalars['float8']>;
  artwork_details_discipline?: Maybe<Scalars['String']>;
  artwork_details_height?: Maybe<Scalars['float8']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_details_materials?: Maybe<Scalars['String']>;
  artwork_details_measuring_unit?: Maybe<Scalars['String']>;
  artwork_details_title?: Maybe<Scalars['String']>;
  artwork_details_width?: Maybe<Scalars['float8']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_id?: Maybe<Scalars['bigint']>;
  artwork_to_collection_created_at?: Maybe<Scalars['timestamptz']>;
  artwork_to_collection_updated_at?: Maybe<Scalars['timestamptz']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  interest_type?: Maybe<Scalars['String']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  original_image_file_extension?: Maybe<Scalars['String']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  profile_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Artwork_favorites_art_universe_listing" */
export type Artwork_Favorites_Art_Universe_Listing_Aggregate = {
  __typename?: 'Artwork_favorites_art_universe_listing_aggregate';
  aggregate?: Maybe<Artwork_Favorites_Art_Universe_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Favorites_Art_Universe_Listing>;
};

/** aggregate fields of "Artwork_favorites_art_universe_listing" */
export type Artwork_Favorites_Art_Universe_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_aggregate_fields';
  avg?: Maybe<Artwork_Favorites_Art_Universe_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Favorites_Art_Universe_Listing_Max_Fields>;
  min?: Maybe<Artwork_Favorites_Art_Universe_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Favorites_Art_Universe_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Favorites_Art_Universe_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Favorites_Art_Universe_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Favorites_Art_Universe_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Favorites_Art_Universe_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Favorites_Art_Universe_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Favorites_Art_Universe_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_favorites_art_universe_listing" */
export type Artwork_Favorites_Art_Universe_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Favorites_Art_Universe_Listing_Avg_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_depth?: Maybe<Scalars['Float']>;
  artwork_details_height?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_details_width?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_favorites_art_universe_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Favorites_Art_Universe_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Favorites_Art_Universe_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_details_depth?: Maybe<Float8_Comparison_Exp>;
  artwork_details_discipline?: Maybe<String_Comparison_Exp>;
  artwork_details_height?: Maybe<Float8_Comparison_Exp>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_details_materials?: Maybe<String_Comparison_Exp>;
  artwork_details_measuring_unit?: Maybe<String_Comparison_Exp>;
  artwork_details_title?: Maybe<String_Comparison_Exp>;
  artwork_details_width?: Maybe<Float8_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_price_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_to_collection_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  artwork_to_collection_updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  interest_id?: Maybe<Int_Comparison_Exp>;
  interest_type?: Maybe<String_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_landscape?: Maybe<Boolean_Comparison_Exp>;
  is_portrait?: Maybe<Boolean_Comparison_Exp>;
  is_square?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
  original_image_file_extension?: Maybe<String_Comparison_Exp>;
  owner_context_id?: Maybe<Bigint_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Favorites_Art_Universe_Listing_Max_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_depth?: Maybe<Scalars['float8']>;
  artwork_details_discipline?: Maybe<Scalars['String']>;
  artwork_details_height?: Maybe<Scalars['float8']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_details_materials?: Maybe<Scalars['String']>;
  artwork_details_measuring_unit?: Maybe<Scalars['String']>;
  artwork_details_title?: Maybe<Scalars['String']>;
  artwork_details_width?: Maybe<Scalars['float8']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_id?: Maybe<Scalars['bigint']>;
  artwork_to_collection_created_at?: Maybe<Scalars['timestamptz']>;
  artwork_to_collection_updated_at?: Maybe<Scalars['timestamptz']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  interest_type?: Maybe<Scalars['String']>;
  original_image_file_extension?: Maybe<Scalars['String']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  profile_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Favorites_Art_Universe_Listing_Min_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_depth?: Maybe<Scalars['float8']>;
  artwork_details_discipline?: Maybe<Scalars['String']>;
  artwork_details_height?: Maybe<Scalars['float8']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_details_materials?: Maybe<Scalars['String']>;
  artwork_details_measuring_unit?: Maybe<Scalars['String']>;
  artwork_details_title?: Maybe<Scalars['String']>;
  artwork_details_width?: Maybe<Scalars['float8']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_id?: Maybe<Scalars['bigint']>;
  artwork_to_collection_created_at?: Maybe<Scalars['timestamptz']>;
  artwork_to_collection_updated_at?: Maybe<Scalars['timestamptz']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  interest_type?: Maybe<Scalars['String']>;
  original_image_file_extension?: Maybe<Scalars['String']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  profile_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "Artwork_favorites_art_universe_listing". */
export type Artwork_Favorites_Art_Universe_Listing_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_details_depth?: Maybe<Order_By>;
  artwork_details_discipline?: Maybe<Order_By>;
  artwork_details_height?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_details_materials?: Maybe<Order_By>;
  artwork_details_measuring_unit?: Maybe<Order_By>;
  artwork_details_title?: Maybe<Order_By>;
  artwork_details_width?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_price_id?: Maybe<Order_By>;
  artwork_to_collection_created_at?: Maybe<Order_By>;
  artwork_to_collection_updated_at?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  interest_type?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_landscape?: Maybe<Order_By>;
  is_portrait?: Maybe<Order_By>;
  is_square?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
  original_image_file_extension?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Artwork_favorites_art_universe_listing" */
export enum Artwork_Favorites_Art_Universe_Listing_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkDetailsDepth = 'artwork_details_depth',
  /** column name */
  ArtworkDetailsDiscipline = 'artwork_details_discipline',
  /** column name */
  ArtworkDetailsHeight = 'artwork_details_height',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  ArtworkDetailsMaterials = 'artwork_details_materials',
  /** column name */
  ArtworkDetailsMeasuringUnit = 'artwork_details_measuring_unit',
  /** column name */
  ArtworkDetailsTitle = 'artwork_details_title',
  /** column name */
  ArtworkDetailsWidth = 'artwork_details_width',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkPriceId = 'artwork_price_id',
  /** column name */
  ArtworkToCollectionCreatedAt = 'artwork_to_collection_created_at',
  /** column name */
  ArtworkToCollectionUpdatedAt = 'artwork_to_collection_updated_at',
  /** column name */
  AspectRatio = 'aspect_ratio',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Currency = 'currency',
  /** column name */
  Handle = 'handle',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  InterestId = 'interest_id',
  /** column name */
  InterestType = 'interest_type',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsPortrait = 'is_portrait',
  /** column name */
  IsSquare = 'is_square',
  /** column name */
  IsUnavailable = 'is_unavailable',
  /** column name */
  OriginalImageFileExtension = 'original_image_file_extension',
  /** column name */
  OwnerContextId = 'owner_context_id',
  /** column name */
  Price = 'price',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Title = 'title',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Favorites_Art_Universe_Listing_Stddev_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_depth?: Maybe<Scalars['Float']>;
  artwork_details_height?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_details_width?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Favorites_Art_Universe_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_depth?: Maybe<Scalars['Float']>;
  artwork_details_height?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_details_width?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Favorites_Art_Universe_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_depth?: Maybe<Scalars['Float']>;
  artwork_details_height?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_details_width?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Favorites_Art_Universe_Listing_Sum_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_depth?: Maybe<Scalars['float8']>;
  artwork_details_height?: Maybe<Scalars['float8']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_details_width?: Maybe<Scalars['float8']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  profile_id?: Maybe<Scalars['bigint']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Favorites_Art_Universe_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_depth?: Maybe<Scalars['Float']>;
  artwork_details_height?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_details_width?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Favorites_Art_Universe_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_depth?: Maybe<Scalars['Float']>;
  artwork_details_height?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_details_width?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Favorites_Art_Universe_Listing_Variance_Fields = {
  __typename?: 'Artwork_favorites_art_universe_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_depth?: Maybe<Scalars['Float']>;
  artwork_details_height?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_details_width?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "Artwork" */
export type Artwork_Inc_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  crop_area_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  original_image_metadata_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artwork" */
export type Artwork_Insert_Input = {
  Artwork_Art_Universe_Listing?: Maybe<Artwork_Art_Universe_Listing_Arr_Rel_Insert_Input>;
  Artwork_ai_tags?: Maybe<Artwork_Ai_Tags_Arr_Rel_Insert_Input>;
  Artwork_artist_profile_listing?: Maybe<Artwork_Artist_Profile_Listing_Obj_Rel_Insert_Input>;
  Artwork_events?: Maybe<Artwork_Events_Arr_Rel_Insert_Input>;
  Artwork_prices?: Maybe<Artwork_Prices_Arr_Rel_Insert_Input>;
  Artwork_state_histories?: Maybe<Artwork_State_History_Arr_Rel_Insert_Input>;
  Artwork_to_favorite_count?: Maybe<Artwork_Favorite_Count_Obj_Rel_Insert_Input>;
  ENUM_add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Obj_Rel_Insert_Input>;
  ENUM_artwork_edition_type?: Maybe<Enum_Artwork_Edition_Type_Obj_Rel_Insert_Input>;
  Editions?: Maybe<Edition_Arr_Rel_Insert_Input>;
  Print_amounts?: Maybe<Print_Amounts_Arr_Rel_Insert_Input>;
  add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Enum>;
  art_prizes?: Maybe<Art_Prize_Artwork_Arr_Rel_Insert_Input>;
  artist_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details?: Maybe<Artwork_Details_Obj_Rel_Insert_Input>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Arr_Rel_Insert_Input>;
  artwork_story?: Maybe<Artwork_Story_Obj_Rel_Insert_Input>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  artwork_to_collections?: Maybe<Artwork_To_Collection_Arr_Rel_Insert_Input>;
  artwork_to_tags?: Maybe<Artwork_To_Tag_Arr_Rel_Insert_Input>;
  certificate?: Maybe<Certificate_Of_Authenticity_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_area?: Maybe<Crop_Area_Obj_Rel_Insert_Input>;
  crop_area_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edition_type?: Maybe<Enum_Artwork_Edition_Type_Enum>;
  gallery_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  has_ar_animation?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_hard_minted?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  last_updater?: Maybe<Scalars['bigint']>;
  links?: Maybe<Artwork_Links_Obj_Rel_Insert_Input>;
  listed_at?: Maybe<Scalars['timestamptz']>;
  original_image_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  original_image_metadata_id?: Maybe<Scalars['bigint']>;
  /** Pending vuforia image path generated by forge to control vuforia process start */
  pending_vuforia_image_path?: Maybe<Scalars['String']>;
  primary_image_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  purchase_inquires?: Maybe<Purchase_Inquires_Arr_Rel_Insert_Input>;
  purchase_inquires_outside_users?: Maybe<Purchase_Inquires_Outside_Users_Arr_Rel_Insert_Input>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Artwork_Status_Enum>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  transaction_histories?: Maybe<Artwork_Transactions_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  vumarks?: Maybe<Vumarks_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "Artwork_last_print_transaction" */
export type Artwork_Last_Print_Transaction = {
  __typename?: 'Artwork_last_print_transaction';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  is_gallery_sold?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  transaction_rank?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Artwork_last_print_transaction" */
export type Artwork_Last_Print_Transaction_Aggregate = {
  __typename?: 'Artwork_last_print_transaction_aggregate';
  aggregate?: Maybe<Artwork_Last_Print_Transaction_Aggregate_Fields>;
  nodes: Array<Artwork_Last_Print_Transaction>;
};

/** aggregate fields of "Artwork_last_print_transaction" */
export type Artwork_Last_Print_Transaction_Aggregate_Fields = {
  __typename?: 'Artwork_last_print_transaction_aggregate_fields';
  avg?: Maybe<Artwork_Last_Print_Transaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Last_Print_Transaction_Max_Fields>;
  min?: Maybe<Artwork_Last_Print_Transaction_Min_Fields>;
  stddev?: Maybe<Artwork_Last_Print_Transaction_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Last_Print_Transaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Last_Print_Transaction_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Last_Print_Transaction_Sum_Fields>;
  var_pop?: Maybe<Artwork_Last_Print_Transaction_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Last_Print_Transaction_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Last_Print_Transaction_Variance_Fields>;
};


/** aggregate fields of "Artwork_last_print_transaction" */
export type Artwork_Last_Print_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Last_Print_Transaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Last_Print_Transaction_Avg_Fields = {
  __typename?: 'Artwork_last_print_transaction_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_last_print_transaction". All fields are combined with a logical 'AND'. */
export type Artwork_Last_Print_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Last_Print_Transaction_Bool_Exp>>;
  _not?: Maybe<Artwork_Last_Print_Transaction_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Last_Print_Transaction_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  buyer_email?: Maybe<String_Comparison_Exp>;
  buyer_first_name?: Maybe<String_Comparison_Exp>;
  context_buyer_id?: Maybe<Bigint_Comparison_Exp>;
  context_seller_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  creation_day?: Maybe<Int_Comparison_Exp>;
  creation_month?: Maybe<Int_Comparison_Exp>;
  creation_year?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_gallery_sold?: Maybe<Boolean_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  print_id?: Maybe<Bigint_Comparison_Exp>;
  sold_date?: Maybe<Date_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  status_at?: Maybe<Timestamptz_Comparison_Exp>;
  transaction_rank?: Maybe<Bigint_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Last_Print_Transaction_Max_Fields = {
  __typename?: 'Artwork_last_print_transaction_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  transaction_rank?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artwork_Last_Print_Transaction_Min_Fields = {
  __typename?: 'Artwork_last_print_transaction_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  transaction_rank?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "Artwork_last_print_transaction". */
export type Artwork_Last_Print_Transaction_Order_By = {
  artwork_id?: Maybe<Order_By>;
  buyer_email?: Maybe<Order_By>;
  buyer_first_name?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_gallery_sold?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
  sold_date?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  transaction_rank?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "Artwork_last_print_transaction" */
export enum Artwork_Last_Print_Transaction_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  BuyerEmail = 'buyer_email',
  /** column name */
  BuyerFirstName = 'buyer_first_name',
  /** column name */
  ContextBuyerId = 'context_buyer_id',
  /** column name */
  ContextSellerId = 'context_seller_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDay = 'creation_day',
  /** column name */
  CreationMonth = 'creation_month',
  /** column name */
  CreationYear = 'creation_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsGallerySold = 'is_gallery_sold',
  /** column name */
  Language = 'language',
  /** column name */
  Price = 'price',
  /** column name */
  PrintId = 'print_id',
  /** column name */
  SoldDate = 'sold_date',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  TransactionRank = 'transaction_rank',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Artwork_Last_Print_Transaction_Stddev_Fields = {
  __typename?: 'Artwork_last_print_transaction_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Last_Print_Transaction_Stddev_Pop_Fields = {
  __typename?: 'Artwork_last_print_transaction_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Last_Print_Transaction_Stddev_Samp_Fields = {
  __typename?: 'Artwork_last_print_transaction_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Last_Print_Transaction_Sum_Fields = {
  __typename?: 'Artwork_last_print_transaction_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  transaction_rank?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artwork_Last_Print_Transaction_Var_Pop_Fields = {
  __typename?: 'Artwork_last_print_transaction_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Last_Print_Transaction_Var_Samp_Fields = {
  __typename?: 'Artwork_last_print_transaction_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Last_Print_Transaction_Variance_Fields = {
  __typename?: 'Artwork_last_print_transaction_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_last_transaction" */
export type Artwork_Last_Transaction = {
  __typename?: 'Artwork_last_transaction';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  is_gallery_sold?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  transaction_rank?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Artwork_last_transaction" */
export type Artwork_Last_Transaction_Aggregate = {
  __typename?: 'Artwork_last_transaction_aggregate';
  aggregate?: Maybe<Artwork_Last_Transaction_Aggregate_Fields>;
  nodes: Array<Artwork_Last_Transaction>;
};

/** aggregate fields of "Artwork_last_transaction" */
export type Artwork_Last_Transaction_Aggregate_Fields = {
  __typename?: 'Artwork_last_transaction_aggregate_fields';
  avg?: Maybe<Artwork_Last_Transaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Last_Transaction_Max_Fields>;
  min?: Maybe<Artwork_Last_Transaction_Min_Fields>;
  stddev?: Maybe<Artwork_Last_Transaction_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Last_Transaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Last_Transaction_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Last_Transaction_Sum_Fields>;
  var_pop?: Maybe<Artwork_Last_Transaction_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Last_Transaction_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Last_Transaction_Variance_Fields>;
};


/** aggregate fields of "Artwork_last_transaction" */
export type Artwork_Last_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Last_Transaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Last_Transaction_Avg_Fields = {
  __typename?: 'Artwork_last_transaction_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_last_transaction". All fields are combined with a logical 'AND'. */
export type Artwork_Last_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Last_Transaction_Bool_Exp>>;
  _not?: Maybe<Artwork_Last_Transaction_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Last_Transaction_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  buyer_email?: Maybe<String_Comparison_Exp>;
  buyer_first_name?: Maybe<String_Comparison_Exp>;
  context_buyer_id?: Maybe<Bigint_Comparison_Exp>;
  context_seller_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  creation_day?: Maybe<Int_Comparison_Exp>;
  creation_month?: Maybe<Int_Comparison_Exp>;
  creation_year?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_gallery_sold?: Maybe<Boolean_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  print_id?: Maybe<Bigint_Comparison_Exp>;
  sold_date?: Maybe<Date_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  status_at?: Maybe<Timestamptz_Comparison_Exp>;
  transaction_rank?: Maybe<Bigint_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Last_Transaction_Max_Fields = {
  __typename?: 'Artwork_last_transaction_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  transaction_rank?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artwork_Last_Transaction_Min_Fields = {
  __typename?: 'Artwork_last_transaction_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  transaction_rank?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "Artwork_last_transaction". */
export type Artwork_Last_Transaction_Order_By = {
  artwork_id?: Maybe<Order_By>;
  buyer_email?: Maybe<Order_By>;
  buyer_first_name?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_gallery_sold?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
  sold_date?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  transaction_rank?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "Artwork_last_transaction" */
export enum Artwork_Last_Transaction_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  BuyerEmail = 'buyer_email',
  /** column name */
  BuyerFirstName = 'buyer_first_name',
  /** column name */
  ContextBuyerId = 'context_buyer_id',
  /** column name */
  ContextSellerId = 'context_seller_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDay = 'creation_day',
  /** column name */
  CreationMonth = 'creation_month',
  /** column name */
  CreationYear = 'creation_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsGallerySold = 'is_gallery_sold',
  /** column name */
  Language = 'language',
  /** column name */
  Price = 'price',
  /** column name */
  PrintId = 'print_id',
  /** column name */
  SoldDate = 'sold_date',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  TransactionRank = 'transaction_rank',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Artwork_Last_Transaction_Stddev_Fields = {
  __typename?: 'Artwork_last_transaction_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Last_Transaction_Stddev_Pop_Fields = {
  __typename?: 'Artwork_last_transaction_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Last_Transaction_Stddev_Samp_Fields = {
  __typename?: 'Artwork_last_transaction_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Last_Transaction_Sum_Fields = {
  __typename?: 'Artwork_last_transaction_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  transaction_rank?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artwork_Last_Transaction_Var_Pop_Fields = {
  __typename?: 'Artwork_last_transaction_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Last_Transaction_Var_Samp_Fields = {
  __typename?: 'Artwork_last_transaction_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Last_Transaction_Variance_Fields = {
  __typename?: 'Artwork_last_transaction_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  transaction_rank?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_links" */
export type Artwork_Links = {
  __typename?: 'Artwork_links';
  /** An object relationship */
  artwork?: Maybe<Artwork>;
  gallery_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  showcase_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artwork_links" */
export type Artwork_Links_Aggregate = {
  __typename?: 'Artwork_links_aggregate';
  aggregate?: Maybe<Artwork_Links_Aggregate_Fields>;
  nodes: Array<Artwork_Links>;
};

/** aggregate fields of "Artwork_links" */
export type Artwork_Links_Aggregate_Fields = {
  __typename?: 'Artwork_links_aggregate_fields';
  avg?: Maybe<Artwork_Links_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Links_Max_Fields>;
  min?: Maybe<Artwork_Links_Min_Fields>;
  stddev?: Maybe<Artwork_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Links_Sum_Fields>;
  var_pop?: Maybe<Artwork_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Links_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Links_Variance_Fields>;
};


/** aggregate fields of "Artwork_links" */
export type Artwork_Links_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Links_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Links_Avg_Fields = {
  __typename?: 'Artwork_links_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_links". All fields are combined with a logical 'AND'. */
export type Artwork_Links_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Links_Bool_Exp>>;
  _not?: Maybe<Artwork_Links_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Links_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  gallery_url?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  profile_card_url?: Maybe<String_Comparison_Exp>;
  profile_url?: Maybe<String_Comparison_Exp>;
  showcase_url?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Artwork_links" */
export type Artwork_Links_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  gallery_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  showcase_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Artwork_Links_Max_Fields = {
  __typename?: 'Artwork_links_max_fields';
  gallery_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  showcase_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artwork_Links_Min_Fields = {
  __typename?: 'Artwork_links_min_fields';
  gallery_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
  profile_url?: Maybe<Scalars['String']>;
  showcase_url?: Maybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "Artwork_links" */
export type Artwork_Links_Obj_Rel_Insert_Input = {
  data: Artwork_Links_Insert_Input;
};

/** Ordering options when selecting data from "Artwork_links". */
export type Artwork_Links_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  gallery_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_card_url?: Maybe<Order_By>;
  profile_url?: Maybe<Order_By>;
  showcase_url?: Maybe<Order_By>;
};

/** select columns of table "Artwork_links" */
export enum Artwork_Links_Select_Column {
  /** column name */
  GalleryUrl = 'gallery_url',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileCardUrl = 'profile_card_url',
  /** column name */
  ProfileUrl = 'profile_url',
  /** column name */
  ShowcaseUrl = 'showcase_url'
}

/** aggregate stddev on columns */
export type Artwork_Links_Stddev_Fields = {
  __typename?: 'Artwork_links_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Links_Stddev_Pop_Fields = {
  __typename?: 'Artwork_links_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Links_Stddev_Samp_Fields = {
  __typename?: 'Artwork_links_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Links_Sum_Fields = {
  __typename?: 'Artwork_links_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artwork_Links_Var_Pop_Fields = {
  __typename?: 'Artwork_links_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Links_Var_Samp_Fields = {
  __typename?: 'Artwork_links_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Links_Variance_Fields = {
  __typename?: 'Artwork_links_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Artwork_Max_Fields = {
  __typename?: 'Artwork_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_area_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  listed_at?: Maybe<Scalars['timestamptz']>;
  original_image_metadata_id?: Maybe<Scalars['bigint']>;
  /** Pending vuforia image path generated by forge to control vuforia process start */
  pending_vuforia_image_path?: Maybe<Scalars['String']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Artwork" */
export type Artwork_Max_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  listed_at?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  /** Pending vuforia image path generated by forge to control vuforia process start */
  pending_vuforia_image_path?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Min_Fields = {
  __typename?: 'Artwork_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_area_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  listed_at?: Maybe<Scalars['timestamptz']>;
  original_image_metadata_id?: Maybe<Scalars['bigint']>;
  /** Pending vuforia image path generated by forge to control vuforia process start */
  pending_vuforia_image_path?: Maybe<Scalars['String']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Artwork" */
export type Artwork_Min_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  listed_at?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  /** Pending vuforia image path generated by forge to control vuforia process start */
  pending_vuforia_image_path?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork" */
export type Artwork_Mutation_Response = {
  __typename?: 'Artwork_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork>;
};

/** input type for inserting object relation for remote table "Artwork" */
export type Artwork_Obj_Rel_Insert_Input = {
  data: Artwork_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_On_Conflict>;
};

/** on_conflict condition type for table "Artwork" */
export type Artwork_On_Conflict = {
  constraint: Artwork_Constraint;
  update_columns?: Array<Artwork_Update_Column>;
  where?: Maybe<Artwork_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork". */
export type Artwork_Order_By = {
  Artwork_Art_Universe_Listing_aggregate?: Maybe<Artwork_Art_Universe_Listing_Aggregate_Order_By>;
  Artwork_ai_tags_aggregate?: Maybe<Artwork_Ai_Tags_Aggregate_Order_By>;
  Artwork_artist_profile_listing?: Maybe<Artwork_Artist_Profile_Listing_Order_By>;
  Artwork_events_aggregate?: Maybe<Artwork_Events_Aggregate_Order_By>;
  Artwork_prices_aggregate?: Maybe<Artwork_Prices_Aggregate_Order_By>;
  Artwork_state_histories_aggregate?: Maybe<Artwork_State_History_Aggregate_Order_By>;
  Artwork_to_favorite_count?: Maybe<Artwork_Favorite_Count_Order_By>;
  ENUM_add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Order_By>;
  ENUM_artwork_edition_type?: Maybe<Enum_Artwork_Edition_Type_Order_By>;
  Editions_aggregate?: Maybe<Edition_Aggregate_Order_By>;
  Print_amounts_aggregate?: Maybe<Print_Amounts_Aggregate_Order_By>;
  add_artwork_flow_step?: Maybe<Order_By>;
  art_prizes_aggregate?: Maybe<Art_Prize_Artwork_Aggregate_Order_By>;
  artist_context?: Maybe<Context_Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details?: Maybe<Artwork_Details_Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_secondary_media_aggregate?: Maybe<Artwork_Secondary_Media_Aggregate_Order_By>;
  artwork_story?: Maybe<Artwork_Story_Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  artwork_to_collections_aggregate?: Maybe<Artwork_To_Collection_Aggregate_Order_By>;
  artwork_to_tags_aggregate?: Maybe<Artwork_To_Tag_Aggregate_Order_By>;
  certificate?: Maybe<Certificate_Of_Authenticity_Order_By>;
  created_at?: Maybe<Order_By>;
  crop_area?: Maybe<Crop_Area_Order_By>;
  crop_area_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  gallery_context?: Maybe<Context_Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  has_ar_animation?: Maybe<Order_By>;
  has_video?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  is_hard_minted?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  is_transaction_locked?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  links?: Maybe<Artwork_Links_Order_By>;
  listed_at?: Maybe<Order_By>;
  original_image_metadata?: Maybe<File_Metadata_Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  pending_vuforia_image_path?: Maybe<Order_By>;
  primary_image_metadata?: Maybe<File_Metadata_Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  purchase_inquires_aggregate?: Maybe<Purchase_Inquires_Aggregate_Order_By>;
  purchase_inquires_outside_users_aggregate?: Maybe<Purchase_Inquires_Outside_Users_Aggregate_Order_By>;
  slug?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
  thumbnail_metadata?: Maybe<File_Metadata_Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  transaction_histories_aggregate?: Maybe<Artwork_Transactions_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
  vumarks_aggregate?: Maybe<Vumarks_Aggregate_Order_By>;
};

/** primary key columns input for table: Artwork */
export type Artwork_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** Stores the price in FIat and Crypto of an Artwork */
export type Artwork_Prices = {
  __typename?: 'Artwork_prices';
  /** An object relationship */
  Artwork: Artwork;
  artwork_id: Scalars['bigint'];
  /** An object relationship */
  artwork_to_showroom_details?: Maybe<Artwork_To_Showroom_Details>;
  created_at: Scalars['timestamptz'];
  currency?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  is_public?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  /** An object relationship */
  price_scope: Enum_Price_Scope;
  scope: Enum_Price_Scope_Enum;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_prices" */
export type Artwork_Prices_Aggregate = {
  __typename?: 'Artwork_prices_aggregate';
  aggregate?: Maybe<Artwork_Prices_Aggregate_Fields>;
  nodes: Array<Artwork_Prices>;
};

/** aggregate fields of "Artwork_prices" */
export type Artwork_Prices_Aggregate_Fields = {
  __typename?: 'Artwork_prices_aggregate_fields';
  avg?: Maybe<Artwork_Prices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Prices_Max_Fields>;
  min?: Maybe<Artwork_Prices_Min_Fields>;
  stddev?: Maybe<Artwork_Prices_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Prices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Prices_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Prices_Sum_Fields>;
  var_pop?: Maybe<Artwork_Prices_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Prices_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Prices_Variance_Fields>;
};


/** aggregate fields of "Artwork_prices" */
export type Artwork_Prices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Prices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_prices" */
export type Artwork_Prices_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Prices_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Prices_Max_Order_By>;
  min?: Maybe<Artwork_Prices_Min_Order_By>;
  stddev?: Maybe<Artwork_Prices_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Prices_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Prices_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Prices_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Prices_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Prices_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Prices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_prices" */
export type Artwork_Prices_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Prices_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Prices_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Prices_Avg_Fields = {
  __typename?: 'Artwork_prices_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_prices" */
export type Artwork_Prices_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_prices". All fields are combined with a logical 'AND'. */
export type Artwork_Prices_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Prices_Bool_Exp>>;
  _not?: Maybe<Artwork_Prices_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Prices_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_to_showroom_details?: Maybe<Artwork_To_Showroom_Details_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  price_scope?: Maybe<Enum_Price_Scope_Bool_Exp>;
  scope?: Maybe<Enum_Price_Scope_Enum_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_prices" */
export enum Artwork_Prices_Constraint {
  /** unique or primary key constraint on columns "id" */
  PricePkey = 'Price_pkey',
  /** unique or primary key constraint on columns "artwork_id" */
  IdxArtworkPricesPublic = 'idx_artwork_prices_public'
}

/** input type for incrementing numeric columns in table "Artwork_prices" */
export type Artwork_Prices_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "Artwork_prices" */
export type Artwork_Prices_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_to_showroom_details?: Maybe<Artwork_To_Showroom_Details_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  price_scope?: Maybe<Enum_Price_Scope_Obj_Rel_Insert_Input>;
  scope?: Maybe<Enum_Price_Scope_Enum>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Prices_Max_Fields = {
  __typename?: 'Artwork_prices_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_prices" */
export type Artwork_Prices_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Prices_Min_Fields = {
  __typename?: 'Artwork_prices_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_prices" */
export type Artwork_Prices_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_prices" */
export type Artwork_Prices_Mutation_Response = {
  __typename?: 'Artwork_prices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Prices>;
};

/** input type for inserting object relation for remote table "Artwork_prices" */
export type Artwork_Prices_Obj_Rel_Insert_Input = {
  data: Artwork_Prices_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Prices_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_prices" */
export type Artwork_Prices_On_Conflict = {
  constraint: Artwork_Prices_Constraint;
  update_columns?: Array<Artwork_Prices_Update_Column>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_prices". */
export type Artwork_Prices_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_to_showroom_details?: Maybe<Artwork_To_Showroom_Details_Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  price_scope?: Maybe<Enum_Price_Scope_Order_By>;
  scope?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_prices */
export type Artwork_Prices_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_prices" */
export enum Artwork_Prices_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Price = 'price',
  /** column name */
  Scope = 'scope',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_prices" */
export type Artwork_Prices_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  scope?: Maybe<Enum_Price_Scope_Enum>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Prices_Stddev_Fields = {
  __typename?: 'Artwork_prices_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_prices" */
export type Artwork_Prices_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Prices_Stddev_Pop_Fields = {
  __typename?: 'Artwork_prices_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_prices" */
export type Artwork_Prices_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Prices_Stddev_Samp_Fields = {
  __typename?: 'Artwork_prices_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_prices" */
export type Artwork_Prices_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Prices_Sum_Fields = {
  __typename?: 'Artwork_prices_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "Artwork_prices" */
export type Artwork_Prices_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** update columns of table "Artwork_prices" */
export enum Artwork_Prices_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Price = 'price',
  /** column name */
  Scope = 'scope',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Prices_Var_Pop_Fields = {
  __typename?: 'Artwork_prices_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_prices" */
export type Artwork_Prices_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Prices_Var_Samp_Fields = {
  __typename?: 'Artwork_prices_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_prices" */
export type Artwork_Prices_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Prices_Variance_Fields = {
  __typename?: 'Artwork_prices_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_prices" */
export type Artwork_Prices_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** columns and relationships of "Artwork_prize_info" */
export type Artwork_Prize_Info = {
  __typename?: 'Artwork_prize_info';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_prize_ids?: Maybe<Scalars['String']>;
  position_type?: Maybe<Scalars['String']>;
  prize_show_slug?: Maybe<Scalars['String']>;
  social_media_url?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artwork_prize_info" */
export type Artwork_Prize_Info_Aggregate = {
  __typename?: 'Artwork_prize_info_aggregate';
  aggregate?: Maybe<Artwork_Prize_Info_Aggregate_Fields>;
  nodes: Array<Artwork_Prize_Info>;
};

/** aggregate fields of "Artwork_prize_info" */
export type Artwork_Prize_Info_Aggregate_Fields = {
  __typename?: 'Artwork_prize_info_aggregate_fields';
  avg?: Maybe<Artwork_Prize_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Prize_Info_Max_Fields>;
  min?: Maybe<Artwork_Prize_Info_Min_Fields>;
  stddev?: Maybe<Artwork_Prize_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Prize_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Prize_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Prize_Info_Sum_Fields>;
  var_pop?: Maybe<Artwork_Prize_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Prize_Info_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Prize_Info_Variance_Fields>;
};


/** aggregate fields of "Artwork_prize_info" */
export type Artwork_Prize_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Prize_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Prize_Info_Avg_Fields = {
  __typename?: 'Artwork_prize_info_avg_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_prize_info". All fields are combined with a logical 'AND'. */
export type Artwork_Prize_Info_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Prize_Info_Bool_Exp>>;
  _not?: Maybe<Artwork_Prize_Info_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Prize_Info_Bool_Exp>>;
  art_prize_show_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_prize_ids?: Maybe<String_Comparison_Exp>;
  position_type?: Maybe<String_Comparison_Exp>;
  prize_show_slug?: Maybe<String_Comparison_Exp>;
  social_media_url?: Maybe<String_Comparison_Exp>;
  subtitle?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Prize_Info_Max_Fields = {
  __typename?: 'Artwork_prize_info_max_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_prize_ids?: Maybe<Scalars['String']>;
  position_type?: Maybe<Scalars['String']>;
  prize_show_slug?: Maybe<Scalars['String']>;
  social_media_url?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artwork_Prize_Info_Min_Fields = {
  __typename?: 'Artwork_prize_info_min_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_prize_ids?: Maybe<Scalars['String']>;
  position_type?: Maybe<Scalars['String']>;
  prize_show_slug?: Maybe<Scalars['String']>;
  social_media_url?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artwork_prize_info". */
export type Artwork_Prize_Info_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_prize_ids?: Maybe<Order_By>;
  position_type?: Maybe<Order_By>;
  prize_show_slug?: Maybe<Order_By>;
  social_media_url?: Maybe<Order_By>;
  subtitle?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Artwork_prize_info" */
export enum Artwork_Prize_Info_Select_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkPrizeIds = 'artwork_prize_ids',
  /** column name */
  PositionType = 'position_type',
  /** column name */
  PrizeShowSlug = 'prize_show_slug',
  /** column name */
  SocialMediaUrl = 'social_media_url',
  /** column name */
  Subtitle = 'subtitle',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Artwork_Prize_Info_Stddev_Fields = {
  __typename?: 'Artwork_prize_info_stddev_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Prize_Info_Stddev_Pop_Fields = {
  __typename?: 'Artwork_prize_info_stddev_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Prize_Info_Stddev_Samp_Fields = {
  __typename?: 'Artwork_prize_info_stddev_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Prize_Info_Sum_Fields = {
  __typename?: 'Artwork_prize_info_sum_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artwork_Prize_Info_Var_Pop_Fields = {
  __typename?: 'Artwork_prize_info_var_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Prize_Info_Var_Samp_Fields = {
  __typename?: 'Artwork_prize_info_var_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Prize_Info_Variance_Fields = {
  __typename?: 'Artwork_prize_info_variance_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_prize_listing" */
export type Artwork_Prize_Listing = {
  __typename?: 'Artwork_prize_listing';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "Artwork_prize_listing" */
export type Artwork_Prize_Listing_Aggregate = {
  __typename?: 'Artwork_prize_listing_aggregate';
  aggregate?: Maybe<Artwork_Prize_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Prize_Listing>;
};

/** aggregate fields of "Artwork_prize_listing" */
export type Artwork_Prize_Listing_Aggregate_Fields = {
  __typename?: 'Artwork_prize_listing_aggregate_fields';
  avg?: Maybe<Artwork_Prize_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Prize_Listing_Max_Fields>;
  min?: Maybe<Artwork_Prize_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Prize_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Prize_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Prize_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Prize_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Prize_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Prize_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Prize_Listing_Variance_Fields>;
};


/** aggregate fields of "Artwork_prize_listing" */
export type Artwork_Prize_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Prize_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Prize_Listing_Avg_Fields = {
  __typename?: 'Artwork_prize_listing_avg_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_prize_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Prize_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Prize_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Prize_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Prize_Listing_Bool_Exp>>;
  art_prize_show_id?: Maybe<Bigint_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_score?: Maybe<Int_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  vuforia_rating?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Prize_Listing_Max_Fields = {
  __typename?: 'Artwork_prize_listing_max_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Artwork_Prize_Listing_Min_Fields = {
  __typename?: 'Artwork_prize_listing_min_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "Artwork_prize_listing". */
export type Artwork_Prize_Listing_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** select columns of table "Artwork_prize_listing" */
export enum Artwork_Prize_Listing_Select_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkScore = 'artwork_score',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Score = 'score',
  /** column name */
  VuforiaRating = 'vuforia_rating'
}

/** aggregate stddev on columns */
export type Artwork_Prize_Listing_Stddev_Fields = {
  __typename?: 'Artwork_prize_listing_stddev_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Prize_Listing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_prize_listing_stddev_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Prize_Listing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_prize_listing_stddev_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Prize_Listing_Sum_Fields = {
  __typename?: 'Artwork_prize_listing_sum_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Artwork_Prize_Listing_Var_Pop_Fields = {
  __typename?: 'Artwork_prize_listing_var_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Prize_Listing_Var_Samp_Fields = {
  __typename?: 'Artwork_prize_listing_var_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Prize_Listing_Variance_Fields = {
  __typename?: 'Artwork_prize_listing_variance_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** Initial entry point for the history of an artwork purchase journey. It will connect to the tables required to track the history of the 3 parties involved: Kali, Seller and Buyer. */
export type Artwork_Purchase_Journey = {
  __typename?: 'Artwork_purchase_journey';
  /** An object relationship */
  Anon_buyer?: Maybe<Billing_Anon_Client>;
  /** An object relationship */
  Buyer?: Maybe<Context>;
  /** An object relationship */
  Gallery?: Maybe<Context>;
  /** An array relationship */
  History: Array<Artwork_Purchase_Journey_History>;
  /** An aggregate relationship */
  History_aggregate: Artwork_Purchase_Journey_History_Aggregate;
  /** An array relationship */
  Last_updates: Array<Artwork_Purchase_Journey_Last_Update>;
  /** An aggregate relationship */
  Last_updates_aggregate: Artwork_Purchase_Journey_Last_Update_Aggregate;
  /** An array relationship */
  Pricing: Array<Artwork_Purchase_Journey_Pricing>;
  /** An aggregate relationship */
  Pricing_aggregate: Artwork_Purchase_Journey_Pricing_Aggregate;
  /** An object relationship */
  Seller: Context;
  /** An array relationship */
  Shipping_locations: Array<Artwork_Purchase_Journey_Shipping_Info>;
  /** An aggregate relationship */
  Shipping_locations_aggregate: Artwork_Purchase_Journey_Shipping_Info_Aggregate;
  anon_buyer_id?: Maybe<Scalars['bigint']>;
  artwork_id: Scalars['bigint'];
  buyer_context_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  fail_reason?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  inquire_id?: Maybe<Scalars['bigint']>;
  origin?: Maybe<Enum_Transaction_Origin_Enum>;
  seller_context_id: Scalars['bigint'];
  status: Enum_Artwork_Purchase_Journey_Status_Enum;
  transaction_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};


/** Initial entry point for the history of an artwork purchase journey. It will connect to the tables required to track the history of the 3 parties involved: Kali, Seller and Buyer. */
export type Artwork_Purchase_JourneyHistoryArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_History_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
};


/** Initial entry point for the history of an artwork purchase journey. It will connect to the tables required to track the history of the 3 parties involved: Kali, Seller and Buyer. */
export type Artwork_Purchase_JourneyHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_History_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
};


/** Initial entry point for the history of an artwork purchase journey. It will connect to the tables required to track the history of the 3 parties involved: Kali, Seller and Buyer. */
export type Artwork_Purchase_JourneyLast_UpdatesArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
};


/** Initial entry point for the history of an artwork purchase journey. It will connect to the tables required to track the history of the 3 parties involved: Kali, Seller and Buyer. */
export type Artwork_Purchase_JourneyLast_Updates_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
};


/** Initial entry point for the history of an artwork purchase journey. It will connect to the tables required to track the history of the 3 parties involved: Kali, Seller and Buyer. */
export type Artwork_Purchase_JourneyPricingArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Pricing_Bool_Exp>;
};


/** Initial entry point for the history of an artwork purchase journey. It will connect to the tables required to track the history of the 3 parties involved: Kali, Seller and Buyer. */
export type Artwork_Purchase_JourneyPricing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Pricing_Bool_Exp>;
};


/** Initial entry point for the history of an artwork purchase journey. It will connect to the tables required to track the history of the 3 parties involved: Kali, Seller and Buyer. */
export type Artwork_Purchase_JourneyShipping_LocationsArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
};


/** Initial entry point for the history of an artwork purchase journey. It will connect to the tables required to track the history of the 3 parties involved: Kali, Seller and Buyer. */
export type Artwork_Purchase_JourneyShipping_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
};

/** aggregated selection of "Artwork_purchase_journey" */
export type Artwork_Purchase_Journey_Aggregate = {
  __typename?: 'Artwork_purchase_journey_aggregate';
  aggregate?: Maybe<Artwork_Purchase_Journey_Aggregate_Fields>;
  nodes: Array<Artwork_Purchase_Journey>;
};

/** aggregate fields of "Artwork_purchase_journey" */
export type Artwork_Purchase_Journey_Aggregate_Fields = {
  __typename?: 'Artwork_purchase_journey_aggregate_fields';
  avg?: Maybe<Artwork_Purchase_Journey_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Purchase_Journey_Max_Fields>;
  min?: Maybe<Artwork_Purchase_Journey_Min_Fields>;
  stddev?: Maybe<Artwork_Purchase_Journey_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Purchase_Journey_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Purchase_Journey_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Purchase_Journey_Sum_Fields>;
  var_pop?: Maybe<Artwork_Purchase_Journey_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Purchase_Journey_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Purchase_Journey_Variance_Fields>;
};


/** aggregate fields of "Artwork_purchase_journey" */
export type Artwork_Purchase_Journey_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Purchase_Journey_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Purchase_Journey_Avg_Fields = {
  __typename?: 'Artwork_purchase_journey_avg_fields';
  anon_buyer_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inquire_id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_purchase_journey". All fields are combined with a logical 'AND'. */
export type Artwork_Purchase_Journey_Bool_Exp = {
  Anon_buyer?: Maybe<Billing_Anon_Client_Bool_Exp>;
  Buyer?: Maybe<Context_Bool_Exp>;
  Gallery?: Maybe<Context_Bool_Exp>;
  History?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
  Last_updates?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
  Pricing?: Maybe<Artwork_Purchase_Journey_Pricing_Bool_Exp>;
  Seller?: Maybe<Context_Bool_Exp>;
  Shipping_locations?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Purchase_Journey_Bool_Exp>>;
  _not?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Purchase_Journey_Bool_Exp>>;
  anon_buyer_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  buyer_context_id?: Maybe<Bigint_Comparison_Exp>;
  buyer_email?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  fail_reason?: Maybe<String_Comparison_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  inquire_id?: Maybe<Bigint_Comparison_Exp>;
  origin?: Maybe<Enum_Transaction_Origin_Enum_Comparison_Exp>;
  seller_context_id?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<Enum_Artwork_Purchase_Journey_Status_Enum_Comparison_Exp>;
  transaction_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_purchase_journey" */
export enum Artwork_Purchase_Journey_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkPurchaseJourneyPkey = 'Artwork_purchase_journey_pkey'
}

/** entries here track all the events, actions and notifications happening through the journey between the SELLER, BUYER and KALI */
export type Artwork_Purchase_Journey_History = {
  __typename?: 'Artwork_purchase_journey_history';
  /** An object relationship */
  Journey: Artwork_Purchase_Journey;
  /** An array relationship */
  Last_updates: Array<Artwork_Purchase_Journey_Last_Update>;
  /** An aggregate relationship */
  Last_updates_aggregate: Artwork_Purchase_Journey_Last_Update_Aggregate;
  /** An object relationship */
  Party_context?: Maybe<Context>;
  /** An array relationship */
  Shipping_locations: Array<Artwork_Purchase_Journey_Shipping_Info>;
  /** An aggregate relationship */
  Shipping_locations_aggregate: Artwork_Purchase_Journey_Shipping_Info_Aggregate;
  created_at: Scalars['timestamptz'];
  entry_kind: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum;
  entry_kind_id: Scalars['bigint'];
  entry_kind_type: Scalars['String'];
  id: Scalars['bigint'];
  journey_id: Scalars['bigint'];
  party_context_id?: Maybe<Scalars['bigint']>;
  party_type: Enum_Artwork_Purchase_Journey_Party_Enum;
  updated_at: Scalars['timestamptz'];
};


/** entries here track all the events, actions and notifications happening through the journey between the SELLER, BUYER and KALI */
export type Artwork_Purchase_Journey_HistoryLast_UpdatesArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
};


/** entries here track all the events, actions and notifications happening through the journey between the SELLER, BUYER and KALI */
export type Artwork_Purchase_Journey_HistoryLast_Updates_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
};


/** entries here track all the events, actions and notifications happening through the journey between the SELLER, BUYER and KALI */
export type Artwork_Purchase_Journey_HistoryShipping_LocationsArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
};


/** entries here track all the events, actions and notifications happening through the journey between the SELLER, BUYER and KALI */
export type Artwork_Purchase_Journey_HistoryShipping_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
};

/** aggregated selection of "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Aggregate = {
  __typename?: 'Artwork_purchase_journey_history_aggregate';
  aggregate?: Maybe<Artwork_Purchase_Journey_History_Aggregate_Fields>;
  nodes: Array<Artwork_Purchase_Journey_History>;
};

/** aggregate fields of "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Aggregate_Fields = {
  __typename?: 'Artwork_purchase_journey_history_aggregate_fields';
  avg?: Maybe<Artwork_Purchase_Journey_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Purchase_Journey_History_Max_Fields>;
  min?: Maybe<Artwork_Purchase_Journey_History_Min_Fields>;
  stddev?: Maybe<Artwork_Purchase_Journey_History_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Purchase_Journey_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Purchase_Journey_History_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Purchase_Journey_History_Sum_Fields>;
  var_pop?: Maybe<Artwork_Purchase_Journey_History_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Purchase_Journey_History_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Purchase_Journey_History_Variance_Fields>;
};


/** aggregate fields of "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Purchase_Journey_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Purchase_Journey_History_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Purchase_Journey_History_Max_Order_By>;
  min?: Maybe<Artwork_Purchase_Journey_History_Min_Order_By>;
  stddev?: Maybe<Artwork_Purchase_Journey_History_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Purchase_Journey_History_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Purchase_Journey_History_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Purchase_Journey_History_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Purchase_Journey_History_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Purchase_Journey_History_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Purchase_Journey_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Purchase_Journey_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Purchase_Journey_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Purchase_Journey_History_Avg_Fields = {
  __typename?: 'Artwork_purchase_journey_history_avg_fields';
  entry_kind_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  party_context_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Avg_Order_By = {
  entry_kind_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_purchase_journey_history". All fields are combined with a logical 'AND'. */
export type Artwork_Purchase_Journey_History_Bool_Exp = {
  Journey?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
  Last_updates?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
  Party_context?: Maybe<Context_Bool_Exp>;
  Shipping_locations?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Purchase_Journey_History_Bool_Exp>>;
  _not?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Purchase_Journey_History_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  entry_kind?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum_Comparison_Exp>;
  entry_kind_id?: Maybe<Bigint_Comparison_Exp>;
  entry_kind_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  journey_id?: Maybe<Bigint_Comparison_Exp>;
  party_context_id?: Maybe<Bigint_Comparison_Exp>;
  party_type?: Maybe<Enum_Artwork_Purchase_Journey_Party_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_purchase_journey_history" */
export enum Artwork_Purchase_Journey_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkPurchaseJourneyHistoryPkey = 'Artwork_purchase_journey_history_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Inc_Input = {
  entry_kind_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  party_context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Insert_Input = {
  Journey?: Maybe<Artwork_Purchase_Journey_Obj_Rel_Insert_Input>;
  Last_updates?: Maybe<Artwork_Purchase_Journey_Last_Update_Arr_Rel_Insert_Input>;
  Party_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  Shipping_locations?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  entry_kind?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum>;
  entry_kind_id?: Maybe<Scalars['bigint']>;
  entry_kind_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  party_context_id?: Maybe<Scalars['bigint']>;
  party_type?: Maybe<Enum_Artwork_Purchase_Journey_Party_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Purchase_Journey_History_Max_Fields = {
  __typename?: 'Artwork_purchase_journey_history_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entry_kind_id?: Maybe<Scalars['bigint']>;
  entry_kind_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  party_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  entry_kind_id?: Maybe<Order_By>;
  entry_kind_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Purchase_Journey_History_Min_Fields = {
  __typename?: 'Artwork_purchase_journey_history_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entry_kind_id?: Maybe<Scalars['bigint']>;
  entry_kind_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  party_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  entry_kind_id?: Maybe<Order_By>;
  entry_kind_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Mutation_Response = {
  __typename?: 'Artwork_purchase_journey_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Purchase_Journey_History>;
};

/** input type for inserting object relation for remote table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Obj_Rel_Insert_Input = {
  data: Artwork_Purchase_Journey_History_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Purchase_Journey_History_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_On_Conflict = {
  constraint: Artwork_Purchase_Journey_History_Constraint;
  update_columns?: Array<Artwork_Purchase_Journey_History_Update_Column>;
  where?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_purchase_journey_history". */
export type Artwork_Purchase_Journey_History_Order_By = {
  Journey?: Maybe<Artwork_Purchase_Journey_Order_By>;
  Last_updates_aggregate?: Maybe<Artwork_Purchase_Journey_Last_Update_Aggregate_Order_By>;
  Party_context?: Maybe<Context_Order_By>;
  Shipping_locations_aggregate?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  entry_kind?: Maybe<Order_By>;
  entry_kind_id?: Maybe<Order_By>;
  entry_kind_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
  party_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_purchase_journey_history */
export type Artwork_Purchase_Journey_History_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_purchase_journey_history" */
export enum Artwork_Purchase_Journey_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntryKind = 'entry_kind',
  /** column name */
  EntryKindId = 'entry_kind_id',
  /** column name */
  EntryKindType = 'entry_kind_type',
  /** column name */
  Id = 'id',
  /** column name */
  JourneyId = 'journey_id',
  /** column name */
  PartyContextId = 'party_context_id',
  /** column name */
  PartyType = 'party_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  entry_kind?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum>;
  entry_kind_id?: Maybe<Scalars['bigint']>;
  entry_kind_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  party_context_id?: Maybe<Scalars['bigint']>;
  party_type?: Maybe<Enum_Artwork_Purchase_Journey_Party_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Purchase_Journey_History_Stddev_Fields = {
  __typename?: 'Artwork_purchase_journey_history_stddev_fields';
  entry_kind_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  party_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Stddev_Order_By = {
  entry_kind_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Purchase_Journey_History_Stddev_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_history_stddev_pop_fields';
  entry_kind_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  party_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Stddev_Pop_Order_By = {
  entry_kind_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Purchase_Journey_History_Stddev_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_history_stddev_samp_fields';
  entry_kind_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  party_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Stddev_Samp_Order_By = {
  entry_kind_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Purchase_Journey_History_Sum_Fields = {
  __typename?: 'Artwork_purchase_journey_history_sum_fields';
  entry_kind_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  party_context_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Sum_Order_By = {
  entry_kind_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_purchase_journey_history" */
export enum Artwork_Purchase_Journey_History_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntryKind = 'entry_kind',
  /** column name */
  EntryKindId = 'entry_kind_id',
  /** column name */
  EntryKindType = 'entry_kind_type',
  /** column name */
  Id = 'id',
  /** column name */
  JourneyId = 'journey_id',
  /** column name */
  PartyContextId = 'party_context_id',
  /** column name */
  PartyType = 'party_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Purchase_Journey_History_Var_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_history_var_pop_fields';
  entry_kind_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  party_context_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Var_Pop_Order_By = {
  entry_kind_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Purchase_Journey_History_Var_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_history_var_samp_fields';
  entry_kind_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  party_context_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Var_Samp_Order_By = {
  entry_kind_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Purchase_Journey_History_Variance_Fields = {
  __typename?: 'Artwork_purchase_journey_history_variance_fields';
  entry_kind_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  party_context_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_purchase_journey_history" */
export type Artwork_Purchase_Journey_History_Variance_Order_By = {
  entry_kind_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_context_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "Artwork_purchase_journey" */
export type Artwork_Purchase_Journey_Inc_Input = {
  anon_buyer_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inquire_id?: Maybe<Scalars['bigint']>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_purchase_journey" */
export type Artwork_Purchase_Journey_Insert_Input = {
  Anon_buyer?: Maybe<Billing_Anon_Client_Obj_Rel_Insert_Input>;
  Buyer?: Maybe<Context_Obj_Rel_Insert_Input>;
  Gallery?: Maybe<Context_Obj_Rel_Insert_Input>;
  History?: Maybe<Artwork_Purchase_Journey_History_Arr_Rel_Insert_Input>;
  Last_updates?: Maybe<Artwork_Purchase_Journey_Last_Update_Arr_Rel_Insert_Input>;
  Pricing?: Maybe<Artwork_Purchase_Journey_Pricing_Arr_Rel_Insert_Input>;
  Seller?: Maybe<Context_Obj_Rel_Insert_Input>;
  Shipping_locations?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Arr_Rel_Insert_Input>;
  anon_buyer_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fail_reason?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inquire_id?: Maybe<Scalars['bigint']>;
  origin?: Maybe<Enum_Transaction_Origin_Enum>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_Artwork_Purchase_Journey_Status_Enum>;
  transaction_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** This table is meant to be updated through a trigger via inserts into the Artwork_purchase_journey_history for entries with entry_kind: EVENT */
export type Artwork_Purchase_Journey_Last_Update = {
  __typename?: 'Artwork_purchase_journey_last_update';
  /** An object relationship */
  History_entry: Artwork_Purchase_Journey_History;
  /** An object relationship */
  Journey: Artwork_Purchase_Journey;
  created_at: Scalars['timestamptz'];
  entry_kind: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum;
  history_entry_id: Scalars['bigint'];
  journey_id: Scalars['bigint'];
  party_type: Enum_Artwork_Purchase_Journey_Party_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Aggregate = {
  __typename?: 'Artwork_purchase_journey_last_update_aggregate';
  aggregate?: Maybe<Artwork_Purchase_Journey_Last_Update_Aggregate_Fields>;
  nodes: Array<Artwork_Purchase_Journey_Last_Update>;
};

/** aggregate fields of "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Aggregate_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_aggregate_fields';
  avg?: Maybe<Artwork_Purchase_Journey_Last_Update_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Purchase_Journey_Last_Update_Max_Fields>;
  min?: Maybe<Artwork_Purchase_Journey_Last_Update_Min_Fields>;
  stddev?: Maybe<Artwork_Purchase_Journey_Last_Update_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Purchase_Journey_Last_Update_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Purchase_Journey_Last_Update_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Purchase_Journey_Last_Update_Sum_Fields>;
  var_pop?: Maybe<Artwork_Purchase_Journey_Last_Update_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Purchase_Journey_Last_Update_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Purchase_Journey_Last_Update_Variance_Fields>;
};


/** aggregate fields of "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Purchase_Journey_Last_Update_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Purchase_Journey_Last_Update_Max_Order_By>;
  min?: Maybe<Artwork_Purchase_Journey_Last_Update_Min_Order_By>;
  stddev?: Maybe<Artwork_Purchase_Journey_Last_Update_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Purchase_Journey_Last_Update_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Purchase_Journey_Last_Update_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Purchase_Journey_Last_Update_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Purchase_Journey_Last_Update_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Purchase_Journey_Last_Update_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Purchase_Journey_Last_Update_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Purchase_Journey_Last_Update_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Purchase_Journey_Last_Update_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Purchase_Journey_Last_Update_Avg_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_avg_fields';
  history_entry_id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Avg_Order_By = {
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_purchase_journey_last_update". All fields are combined with a logical 'AND'. */
export type Artwork_Purchase_Journey_Last_Update_Bool_Exp = {
  History_entry?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
  Journey?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Bool_Exp>>;
  _not?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  entry_kind?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum_Comparison_Exp>;
  history_entry_id?: Maybe<Bigint_Comparison_Exp>;
  journey_id?: Maybe<Bigint_Comparison_Exp>;
  party_type?: Maybe<Enum_Artwork_Purchase_Journey_Party_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_purchase_journey_last_update" */
export enum Artwork_Purchase_Journey_Last_Update_Constraint {
  /** unique or primary key constraint on columns "party_type", "entry_kind", "journey_id" */
  ArtworkPurchaseJourneyLastUpdatePkey = 'Artwork_purchase_journey_last_update_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Inc_Input = {
  history_entry_id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Insert_Input = {
  History_entry?: Maybe<Artwork_Purchase_Journey_History_Obj_Rel_Insert_Input>;
  Journey?: Maybe<Artwork_Purchase_Journey_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  entry_kind?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum>;
  history_entry_id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  party_type?: Maybe<Enum_Artwork_Purchase_Journey_Party_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Purchase_Journey_Last_Update_Max_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  history_entry_id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Purchase_Journey_Last_Update_Min_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  history_entry_id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Mutation_Response = {
  __typename?: 'Artwork_purchase_journey_last_update_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Purchase_Journey_Last_Update>;
};

/** on_conflict condition type for table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_On_Conflict = {
  constraint: Artwork_Purchase_Journey_Last_Update_Constraint;
  update_columns?: Array<Artwork_Purchase_Journey_Last_Update_Update_Column>;
  where?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_purchase_journey_last_update". */
export type Artwork_Purchase_Journey_Last_Update_Order_By = {
  History_entry?: Maybe<Artwork_Purchase_Journey_History_Order_By>;
  Journey?: Maybe<Artwork_Purchase_Journey_Order_By>;
  created_at?: Maybe<Order_By>;
  entry_kind?: Maybe<Order_By>;
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  party_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_purchase_journey_last_update */
export type Artwork_Purchase_Journey_Last_Update_Pk_Columns_Input = {
  entry_kind: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum;
  journey_id: Scalars['bigint'];
  party_type: Enum_Artwork_Purchase_Journey_Party_Enum;
};

/** select columns of table "Artwork_purchase_journey_last_update" */
export enum Artwork_Purchase_Journey_Last_Update_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntryKind = 'entry_kind',
  /** column name */
  HistoryEntryId = 'history_entry_id',
  /** column name */
  JourneyId = 'journey_id',
  /** column name */
  PartyType = 'party_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  entry_kind?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum>;
  history_entry_id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  party_type?: Maybe<Enum_Artwork_Purchase_Journey_Party_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Purchase_Journey_Last_Update_Stddev_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_stddev_fields';
  history_entry_id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Stddev_Order_By = {
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Purchase_Journey_Last_Update_Stddev_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_stddev_pop_fields';
  history_entry_id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Stddev_Pop_Order_By = {
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Purchase_Journey_Last_Update_Stddev_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_stddev_samp_fields';
  history_entry_id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Stddev_Samp_Order_By = {
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Purchase_Journey_Last_Update_Sum_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_sum_fields';
  history_entry_id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Sum_Order_By = {
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_purchase_journey_last_update" */
export enum Artwork_Purchase_Journey_Last_Update_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntryKind = 'entry_kind',
  /** column name */
  HistoryEntryId = 'history_entry_id',
  /** column name */
  JourneyId = 'journey_id',
  /** column name */
  PartyType = 'party_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Purchase_Journey_Last_Update_Var_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_var_pop_fields';
  history_entry_id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Var_Pop_Order_By = {
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Purchase_Journey_Last_Update_Var_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_var_samp_fields';
  history_entry_id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Var_Samp_Order_By = {
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Purchase_Journey_Last_Update_Variance_Fields = {
  __typename?: 'Artwork_purchase_journey_last_update_variance_fields';
  history_entry_id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_purchase_journey_last_update" */
export type Artwork_Purchase_Journey_Last_Update_Variance_Order_By = {
  history_entry_id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Artwork_Purchase_Journey_Max_Fields = {
  __typename?: 'Artwork_purchase_journey_max_fields';
  anon_buyer_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fail_reason?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inquire_id?: Maybe<Scalars['bigint']>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Artwork_Purchase_Journey_Min_Fields = {
  __typename?: 'Artwork_purchase_journey_min_fields';
  anon_buyer_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fail_reason?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inquire_id?: Maybe<Scalars['bigint']>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Artwork_purchase_journey" */
export type Artwork_Purchase_Journey_Mutation_Response = {
  __typename?: 'Artwork_purchase_journey_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Purchase_Journey>;
};

/** input type for inserting object relation for remote table "Artwork_purchase_journey" */
export type Artwork_Purchase_Journey_Obj_Rel_Insert_Input = {
  data: Artwork_Purchase_Journey_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Purchase_Journey_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_purchase_journey" */
export type Artwork_Purchase_Journey_On_Conflict = {
  constraint: Artwork_Purchase_Journey_Constraint;
  update_columns?: Array<Artwork_Purchase_Journey_Update_Column>;
  where?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_purchase_journey". */
export type Artwork_Purchase_Journey_Order_By = {
  Anon_buyer?: Maybe<Billing_Anon_Client_Order_By>;
  Buyer?: Maybe<Context_Order_By>;
  Gallery?: Maybe<Context_Order_By>;
  History_aggregate?: Maybe<Artwork_Purchase_Journey_History_Aggregate_Order_By>;
  Last_updates_aggregate?: Maybe<Artwork_Purchase_Journey_Last_Update_Aggregate_Order_By>;
  Pricing_aggregate?: Maybe<Artwork_Purchase_Journey_Pricing_Aggregate_Order_By>;
  Seller?: Maybe<Context_Order_By>;
  Shipping_locations_aggregate?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Aggregate_Order_By>;
  anon_buyer_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  buyer_context_id?: Maybe<Order_By>;
  buyer_email?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  fail_reason?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inquire_id?: Maybe<Order_By>;
  origin?: Maybe<Order_By>;
  seller_context_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_purchase_journey */
export type Artwork_Purchase_Journey_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** holds the pricing information for an artwork/print tied to a history event of its journey */
export type Artwork_Purchase_Journey_Pricing = {
  __typename?: 'Artwork_purchase_journey_pricing';
  /** An object relationship */
  History_entry: Artwork_Purchase_Journey_History;
  /** An object relationship */
  Journey: Artwork_Purchase_Journey;
  artwork_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  currency: Scalars['String'];
  history_id: Scalars['bigint'];
  id: Scalars['bigint'];
  journey_id: Scalars['bigint'];
  price: Scalars['Int'];
  print_size?: Maybe<Enum_Size_Enum>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Aggregate = {
  __typename?: 'Artwork_purchase_journey_pricing_aggregate';
  aggregate?: Maybe<Artwork_Purchase_Journey_Pricing_Aggregate_Fields>;
  nodes: Array<Artwork_Purchase_Journey_Pricing>;
};

/** aggregate fields of "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Aggregate_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_aggregate_fields';
  avg?: Maybe<Artwork_Purchase_Journey_Pricing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Purchase_Journey_Pricing_Max_Fields>;
  min?: Maybe<Artwork_Purchase_Journey_Pricing_Min_Fields>;
  stddev?: Maybe<Artwork_Purchase_Journey_Pricing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Purchase_Journey_Pricing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Purchase_Journey_Pricing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Purchase_Journey_Pricing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Purchase_Journey_Pricing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Purchase_Journey_Pricing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Purchase_Journey_Pricing_Variance_Fields>;
};


/** aggregate fields of "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Purchase_Journey_Pricing_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Purchase_Journey_Pricing_Max_Order_By>;
  min?: Maybe<Artwork_Purchase_Journey_Pricing_Min_Order_By>;
  stddev?: Maybe<Artwork_Purchase_Journey_Pricing_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Purchase_Journey_Pricing_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Purchase_Journey_Pricing_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Purchase_Journey_Pricing_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Purchase_Journey_Pricing_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Purchase_Journey_Pricing_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Purchase_Journey_Pricing_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Purchase_Journey_Pricing_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Purchase_Journey_Pricing_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Purchase_Journey_Pricing_Avg_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_purchase_journey_pricing". All fields are combined with a logical 'AND'. */
export type Artwork_Purchase_Journey_Pricing_Bool_Exp = {
  History_entry?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
  Journey?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Bool_Exp>>;
  _not?: Maybe<Artwork_Purchase_Journey_Pricing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  history_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  journey_id?: Maybe<Bigint_Comparison_Exp>;
  price?: Maybe<Int_Comparison_Exp>;
  print_size?: Maybe<Enum_Size_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_purchase_journey_pricing" */
export enum Artwork_Purchase_Journey_Pricing_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkPurchaseJourneyPricingPkey = 'Artwork_purchase_journey_pricing_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Insert_Input = {
  History_entry?: Maybe<Artwork_Purchase_Journey_History_Obj_Rel_Insert_Input>;
  Journey?: Maybe<Artwork_Purchase_Journey_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
  print_size?: Maybe<Enum_Size_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Purchase_Journey_Pricing_Max_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Purchase_Journey_Pricing_Min_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Mutation_Response = {
  __typename?: 'Artwork_purchase_journey_pricing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Purchase_Journey_Pricing>;
};

/** on_conflict condition type for table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_On_Conflict = {
  constraint: Artwork_Purchase_Journey_Pricing_Constraint;
  update_columns?: Array<Artwork_Purchase_Journey_Pricing_Update_Column>;
  where?: Maybe<Artwork_Purchase_Journey_Pricing_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_purchase_journey_pricing". */
export type Artwork_Purchase_Journey_Pricing_Order_By = {
  History_entry?: Maybe<Artwork_Purchase_Journey_History_Order_By>;
  Journey?: Maybe<Artwork_Purchase_Journey_Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_size?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_purchase_journey_pricing */
export type Artwork_Purchase_Journey_Pricing_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_purchase_journey_pricing" */
export enum Artwork_Purchase_Journey_Pricing_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  HistoryId = 'history_id',
  /** column name */
  Id = 'id',
  /** column name */
  JourneyId = 'journey_id',
  /** column name */
  Price = 'price',
  /** column name */
  PrintSize = 'print_size',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
  print_size?: Maybe<Enum_Size_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Purchase_Journey_Pricing_Stddev_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Purchase_Journey_Pricing_Stddev_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Purchase_Journey_Pricing_Stddev_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Purchase_Journey_Pricing_Sum_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** update columns of table "Artwork_purchase_journey_pricing" */
export enum Artwork_Purchase_Journey_Pricing_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  HistoryId = 'history_id',
  /** column name */
  Id = 'id',
  /** column name */
  JourneyId = 'journey_id',
  /** column name */
  Price = 'price',
  /** column name */
  PrintSize = 'print_size',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Purchase_Journey_Pricing_Var_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Purchase_Journey_Pricing_Var_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Purchase_Journey_Pricing_Variance_Fields = {
  __typename?: 'Artwork_purchase_journey_pricing_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_purchase_journey_pricing" */
export type Artwork_Purchase_Journey_Pricing_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** select columns of table "Artwork_purchase_journey" */
export enum Artwork_Purchase_Journey_Select_Column {
  /** column name */
  AnonBuyerId = 'anon_buyer_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  BuyerContextId = 'buyer_context_id',
  /** column name */
  BuyerEmail = 'buyer_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailReason = 'fail_reason',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  InquireId = 'inquire_id',
  /** column name */
  Origin = 'origin',
  /** column name */
  SellerContextId = 'seller_context_id',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_purchase_journey" */
export type Artwork_Purchase_Journey_Set_Input = {
  anon_buyer_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fail_reason?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inquire_id?: Maybe<Scalars['bigint']>;
  origin?: Maybe<Enum_Transaction_Origin_Enum>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_Artwork_Purchase_Journey_Status_Enum>;
  transaction_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** holds the shipping information for an artwork tied to a history event of its journey */
export type Artwork_Purchase_Journey_Shipping_Info = {
  __typename?: 'Artwork_purchase_journey_shipping_info';
  /** An object relationship */
  History_entry: Artwork_Purchase_Journey_History;
  /** An object relationship */
  Journey: Artwork_Purchase_Journey;
  /** An object relationship */
  Worldcity?: Maybe<Worldcities>;
  city_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  history_id: Scalars['bigint'];
  id: Scalars['bigint'];
  journey_id: Scalars['bigint'];
  method: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zip: Scalars['String'];
};

/** aggregated selection of "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Aggregate = {
  __typename?: 'Artwork_purchase_journey_shipping_info_aggregate';
  aggregate?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Aggregate_Fields>;
  nodes: Array<Artwork_Purchase_Journey_Shipping_Info>;
};

/** aggregate fields of "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Aggregate_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_aggregate_fields';
  avg?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Max_Fields>;
  min?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Min_Fields>;
  stddev?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Sum_Fields>;
  var_pop?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Variance_Fields>;
};


/** aggregate fields of "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Max_Order_By>;
  min?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Min_Order_By>;
  stddev?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Purchase_Journey_Shipping_Info_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Purchase_Journey_Shipping_Info_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Avg_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_avg_fields';
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Avg_Order_By = {
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_purchase_journey_shipping_info". All fields are combined with a logical 'AND'. */
export type Artwork_Purchase_Journey_Shipping_Info_Bool_Exp = {
  History_entry?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
  Journey?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
  Worldcity?: Maybe<Worldcities_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>>;
  _not?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>>;
  city_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  history_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  journey_id?: Maybe<Bigint_Comparison_Exp>;
  method?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  zip?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_purchase_journey_shipping_info" */
export enum Artwork_Purchase_Journey_Shipping_Info_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkPurchaseJourneyShippingInfoPkey = 'Artwork_purchase_journey_shipping_info_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Inc_Input = {
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Insert_Input = {
  History_entry?: Maybe<Artwork_Purchase_Journey_History_Obj_Rel_Insert_Input>;
  Journey?: Maybe<Artwork_Purchase_Journey_Obj_Rel_Insert_Input>;
  Worldcity?: Maybe<Worldcities_Obj_Rel_Insert_Input>;
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  method?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Max_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_max_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  method?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Max_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Min_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_min_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  method?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Min_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Mutation_Response = {
  __typename?: 'Artwork_purchase_journey_shipping_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Purchase_Journey_Shipping_Info>;
};

/** on_conflict condition type for table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_On_Conflict = {
  constraint: Artwork_Purchase_Journey_Shipping_Info_Constraint;
  update_columns?: Array<Artwork_Purchase_Journey_Shipping_Info_Update_Column>;
  where?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_purchase_journey_shipping_info". */
export type Artwork_Purchase_Journey_Shipping_Info_Order_By = {
  History_entry?: Maybe<Artwork_Purchase_Journey_History_Order_By>;
  Journey?: Maybe<Artwork_Purchase_Journey_Order_By>;
  Worldcity?: Maybe<Worldcities_Order_By>;
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_purchase_journey_shipping_info */
export type Artwork_Purchase_Journey_Shipping_Info_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_purchase_journey_shipping_info" */
export enum Artwork_Purchase_Journey_Shipping_Info_Select_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HistoryId = 'history_id',
  /** column name */
  Id = 'id',
  /** column name */
  JourneyId = 'journey_id',
  /** column name */
  Method = 'method',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Set_Input = {
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
  method?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Stddev_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_stddev_fields';
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Stddev_Order_By = {
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Stddev_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_stddev_pop_fields';
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Stddev_Pop_Order_By = {
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Stddev_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_stddev_samp_fields';
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Stddev_Samp_Order_By = {
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Sum_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_sum_fields';
  history_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  journey_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Sum_Order_By = {
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_purchase_journey_shipping_info" */
export enum Artwork_Purchase_Journey_Shipping_Info_Update_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HistoryId = 'history_id',
  /** column name */
  Id = 'id',
  /** column name */
  JourneyId = 'journey_id',
  /** column name */
  Method = 'method',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zip = 'zip'
}

/** aggregate var_pop on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Var_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_var_pop_fields';
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Var_Pop_Order_By = {
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Var_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_var_samp_fields';
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Var_Samp_Order_By = {
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Purchase_Journey_Shipping_Info_Variance_Fields = {
  __typename?: 'Artwork_purchase_journey_shipping_info_variance_fields';
  history_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  journey_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_purchase_journey_shipping_info" */
export type Artwork_Purchase_Journey_Shipping_Info_Variance_Order_By = {
  history_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  journey_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Artwork_Purchase_Journey_Stddev_Fields = {
  __typename?: 'Artwork_purchase_journey_stddev_fields';
  anon_buyer_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inquire_id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Purchase_Journey_Stddev_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_stddev_pop_fields';
  anon_buyer_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inquire_id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Purchase_Journey_Stddev_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_stddev_samp_fields';
  anon_buyer_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inquire_id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Purchase_Journey_Sum_Fields = {
  __typename?: 'Artwork_purchase_journey_sum_fields';
  anon_buyer_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inquire_id?: Maybe<Scalars['bigint']>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Artwork_purchase_journey" */
export enum Artwork_Purchase_Journey_Update_Column {
  /** column name */
  AnonBuyerId = 'anon_buyer_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  BuyerContextId = 'buyer_context_id',
  /** column name */
  BuyerEmail = 'buyer_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailReason = 'fail_reason',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  InquireId = 'inquire_id',
  /** column name */
  Origin = 'origin',
  /** column name */
  SellerContextId = 'seller_context_id',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Purchase_Journey_Var_Pop_Fields = {
  __typename?: 'Artwork_purchase_journey_var_pop_fields';
  anon_buyer_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inquire_id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Purchase_Journey_Var_Samp_Fields = {
  __typename?: 'Artwork_purchase_journey_var_samp_fields';
  anon_buyer_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inquire_id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Purchase_Journey_Variance_Fields = {
  __typename?: 'Artwork_purchase_journey_variance_fields';
  anon_buyer_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inquire_id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** Media metadata of artworks are stored here */
export type Artwork_Secondary_Media = {
  __typename?: 'Artwork_secondary_media';
  /** An object relationship */
  Artwork: Artwork;
  /** An object relationship */
  Edition?: Maybe<Edition>;
  artwork_id: Scalars['bigint'];
  artwork_secondary_image_download_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  artwork_secondary_media_metadata?: Maybe<File_Metadata>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  edition_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  file_metadata?: Maybe<File_Metadata>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  order?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Aggregate = {
  __typename?: 'Artwork_secondary_media_aggregate';
  aggregate?: Maybe<Artwork_Secondary_Media_Aggregate_Fields>;
  nodes: Array<Artwork_Secondary_Media>;
};

/** aggregate fields of "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Aggregate_Fields = {
  __typename?: 'Artwork_secondary_media_aggregate_fields';
  avg?: Maybe<Artwork_Secondary_Media_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Secondary_Media_Max_Fields>;
  min?: Maybe<Artwork_Secondary_Media_Min_Fields>;
  stddev?: Maybe<Artwork_Secondary_Media_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Secondary_Media_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Secondary_Media_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Secondary_Media_Sum_Fields>;
  var_pop?: Maybe<Artwork_Secondary_Media_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Secondary_Media_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Secondary_Media_Variance_Fields>;
};


/** aggregate fields of "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Secondary_Media_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Secondary_Media_Max_Order_By>;
  min?: Maybe<Artwork_Secondary_Media_Min_Order_By>;
  stddev?: Maybe<Artwork_Secondary_Media_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Secondary_Media_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Secondary_Media_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Secondary_Media_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Secondary_Media_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Secondary_Media_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Secondary_Media_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Secondary_Media_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Secondary_Media_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Secondary_Media_Avg_Fields = {
  __typename?: 'Artwork_secondary_media_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_secondary_media". All fields are combined with a logical 'AND'. */
export type Artwork_Secondary_Media_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  Edition?: Maybe<Edition_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Secondary_Media_Bool_Exp>>;
  _not?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Secondary_Media_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_secondary_media_metadata?: Maybe<File_Metadata_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamp_Comparison_Exp>;
  edition_id?: Maybe<Bigint_Comparison_Exp>;
  file_metadata?: Maybe<File_Metadata_Bool_Exp>;
  file_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_secondary_media" */
export enum Artwork_Secondary_Media_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkMediaIdKey = 'Artwork_media_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtworkMediaMetadataPkey = 'Artwork_media_metadata_pkey',
  /** unique or primary key constraint on columns "file_metadata_id" */
  ArtworkSecondaryMediaFileMetadataIdKey = 'Artwork_secondary_media_file_metadata_id_key'
}

/** input type for incrementing numeric columns in table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  Edition?: Maybe<Edition_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_secondary_media_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  edition_id?: Maybe<Scalars['bigint']>;
  file_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Secondary_Media_Max_Fields = {
  __typename?: 'Artwork_secondary_media_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  edition_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Secondary_Media_Min_Fields = {
  __typename?: 'Artwork_secondary_media_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  edition_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Mutation_Response = {
  __typename?: 'Artwork_secondary_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Secondary_Media>;
};

/** on_conflict condition type for table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_On_Conflict = {
  constraint: Artwork_Secondary_Media_Constraint;
  update_columns?: Array<Artwork_Secondary_Media_Update_Column>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_secondary_media". */
export type Artwork_Secondary_Media_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  Edition?: Maybe<Edition_Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_secondary_media_metadata?: Maybe<File_Metadata_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata?: Maybe<File_Metadata_Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_secondary_media */
export type Artwork_Secondary_Media_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_secondary_media" */
export enum Artwork_Secondary_Media_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  edition_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Secondary_Media_Stddev_Fields = {
  __typename?: 'Artwork_secondary_media_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Secondary_Media_Stddev_Pop_Fields = {
  __typename?: 'Artwork_secondary_media_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Secondary_Media_Stddev_Samp_Fields = {
  __typename?: 'Artwork_secondary_media_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Secondary_Media_Sum_Fields = {
  __typename?: 'Artwork_secondary_media_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "Artwork_secondary_media" */
export enum Artwork_Secondary_Media_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Secondary_Media_Var_Pop_Fields = {
  __typename?: 'Artwork_secondary_media_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Secondary_Media_Var_Samp_Fields = {
  __typename?: 'Artwork_secondary_media_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Secondary_Media_Variance_Fields = {
  __typename?: 'Artwork_secondary_media_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_secondary_media" */
export type Artwork_Secondary_Media_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** select columns of table "Artwork" */
export enum Artwork_Select_Column {
  /** column name */
  AddArtworkFlowStep = 'add_artwork_flow_step',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkArtistIndex = 'artwork_artist_index',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropAreaId = 'crop_area_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  HasArAnimation = 'has_ar_animation',
  /** column name */
  HasVideo = 'has_video',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsHardMinted = 'is_hard_minted',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  IsTransactionLocked = 'is_transaction_locked',
  /** column name */
  LastUpdater = 'last_updater',
  /** column name */
  ListedAt = 'listed_at',
  /** column name */
  OriginalImageMetadataId = 'original_image_metadata_id',
  /** column name */
  PendingVuforiaImagePath = 'pending_vuforia_image_path',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VuforiaRating = 'vuforia_rating'
}

/** input type for updating data in table "Artwork" */
export type Artwork_Set_Input = {
  add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Enum>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_area_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edition_type?: Maybe<Enum_Artwork_Edition_Type_Enum>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  has_ar_animation?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_hard_minted?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  last_updater?: Maybe<Scalars['bigint']>;
  listed_at?: Maybe<Scalars['timestamptz']>;
  original_image_metadata_id?: Maybe<Scalars['bigint']>;
  /** Pending vuforia image path generated by forge to control vuforia process start */
  pending_vuforia_image_path?: Maybe<Scalars['String']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Artwork_Status_Enum>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "Artwork_showcase_filtered" */
export type Artwork_Showcase_Filtered = {
  __typename?: 'Artwork_showcase_filtered';
  id?: Maybe<Scalars['bigint']>;
  showcase_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Artwork_showcase_filtered" */
export type Artwork_Showcase_Filtered_Aggregate = {
  __typename?: 'Artwork_showcase_filtered_aggregate';
  aggregate?: Maybe<Artwork_Showcase_Filtered_Aggregate_Fields>;
  nodes: Array<Artwork_Showcase_Filtered>;
};

/** aggregate fields of "Artwork_showcase_filtered" */
export type Artwork_Showcase_Filtered_Aggregate_Fields = {
  __typename?: 'Artwork_showcase_filtered_aggregate_fields';
  avg?: Maybe<Artwork_Showcase_Filtered_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Showcase_Filtered_Max_Fields>;
  min?: Maybe<Artwork_Showcase_Filtered_Min_Fields>;
  stddev?: Maybe<Artwork_Showcase_Filtered_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Showcase_Filtered_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Showcase_Filtered_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Showcase_Filtered_Sum_Fields>;
  var_pop?: Maybe<Artwork_Showcase_Filtered_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Showcase_Filtered_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Showcase_Filtered_Variance_Fields>;
};


/** aggregate fields of "Artwork_showcase_filtered" */
export type Artwork_Showcase_Filtered_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Showcase_Filtered_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Showcase_Filtered_Avg_Fields = {
  __typename?: 'Artwork_showcase_filtered_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_showcase_filtered". All fields are combined with a logical 'AND'. */
export type Artwork_Showcase_Filtered_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Showcase_Filtered_Bool_Exp>>;
  _not?: Maybe<Artwork_Showcase_Filtered_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Showcase_Filtered_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  showcase_url?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Showcase_Filtered_Max_Fields = {
  __typename?: 'Artwork_showcase_filtered_max_fields';
  id?: Maybe<Scalars['bigint']>;
  showcase_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artwork_Showcase_Filtered_Min_Fields = {
  __typename?: 'Artwork_showcase_filtered_min_fields';
  id?: Maybe<Scalars['bigint']>;
  showcase_url?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Artwork_showcase_filtered". */
export type Artwork_Showcase_Filtered_Order_By = {
  id?: Maybe<Order_By>;
  showcase_url?: Maybe<Order_By>;
};

/** select columns of table "Artwork_showcase_filtered" */
export enum Artwork_Showcase_Filtered_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ShowcaseUrl = 'showcase_url'
}

/** aggregate stddev on columns */
export type Artwork_Showcase_Filtered_Stddev_Fields = {
  __typename?: 'Artwork_showcase_filtered_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Showcase_Filtered_Stddev_Pop_Fields = {
  __typename?: 'Artwork_showcase_filtered_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Showcase_Filtered_Stddev_Samp_Fields = {
  __typename?: 'Artwork_showcase_filtered_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Showcase_Filtered_Sum_Fields = {
  __typename?: 'Artwork_showcase_filtered_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artwork_Showcase_Filtered_Var_Pop_Fields = {
  __typename?: 'Artwork_showcase_filtered_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Showcase_Filtered_Var_Samp_Fields = {
  __typename?: 'Artwork_showcase_filtered_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Showcase_Filtered_Variance_Fields = {
  __typename?: 'Artwork_showcase_filtered_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** [DEFERRED] This table stores the history of state changes on an artwork */
export type Artwork_State_History = {
  __typename?: 'Artwork_state_history';
  /** An object relationship */
  Artwork: Artwork;
  artwork_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  state: Scalars['String'];
  state_by: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_state_history" */
export type Artwork_State_History_Aggregate = {
  __typename?: 'Artwork_state_history_aggregate';
  aggregate?: Maybe<Artwork_State_History_Aggregate_Fields>;
  nodes: Array<Artwork_State_History>;
};

/** aggregate fields of "Artwork_state_history" */
export type Artwork_State_History_Aggregate_Fields = {
  __typename?: 'Artwork_state_history_aggregate_fields';
  avg?: Maybe<Artwork_State_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_State_History_Max_Fields>;
  min?: Maybe<Artwork_State_History_Min_Fields>;
  stddev?: Maybe<Artwork_State_History_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_State_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_State_History_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_State_History_Sum_Fields>;
  var_pop?: Maybe<Artwork_State_History_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_State_History_Var_Samp_Fields>;
  variance?: Maybe<Artwork_State_History_Variance_Fields>;
};


/** aggregate fields of "Artwork_state_history" */
export type Artwork_State_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_State_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_state_history" */
export type Artwork_State_History_Aggregate_Order_By = {
  avg?: Maybe<Artwork_State_History_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_State_History_Max_Order_By>;
  min?: Maybe<Artwork_State_History_Min_Order_By>;
  stddev?: Maybe<Artwork_State_History_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_State_History_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_State_History_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_State_History_Sum_Order_By>;
  var_pop?: Maybe<Artwork_State_History_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_State_History_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_State_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_state_history" */
export type Artwork_State_History_Arr_Rel_Insert_Input = {
  data: Array<Artwork_State_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_State_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_State_History_Avg_Fields = {
  __typename?: 'Artwork_state_history_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  state_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_state_history". All fields are combined with a logical 'AND'. */
export type Artwork_State_History_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  _and?: Maybe<Array<Artwork_State_History_Bool_Exp>>;
  _not?: Maybe<Artwork_State_History_Bool_Exp>;
  _or?: Maybe<Array<Artwork_State_History_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  state_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_state_history" */
export enum Artwork_State_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkMintingStagePkey = 'Artwork_minting_stage_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_state_history" */
export type Artwork_State_History_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  state_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_state_history" */
export type Artwork_State_History_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  state_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_State_History_Max_Fields = {
  __typename?: 'Artwork_state_history_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  state_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_State_History_Min_Fields = {
  __typename?: 'Artwork_state_history_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  state_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_state_history" */
export type Artwork_State_History_Mutation_Response = {
  __typename?: 'Artwork_state_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_State_History>;
};

/** on_conflict condition type for table "Artwork_state_history" */
export type Artwork_State_History_On_Conflict = {
  constraint: Artwork_State_History_Constraint;
  update_columns?: Array<Artwork_State_History_Update_Column>;
  where?: Maybe<Artwork_State_History_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_state_history". */
export type Artwork_State_History_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_state_history */
export type Artwork_State_History_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_state_history" */
export enum Artwork_State_History_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  StateBy = 'state_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_state_history" */
export type Artwork_State_History_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  state_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_State_History_Stddev_Fields = {
  __typename?: 'Artwork_state_history_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  state_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_State_History_Stddev_Pop_Fields = {
  __typename?: 'Artwork_state_history_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  state_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_State_History_Stddev_Samp_Fields = {
  __typename?: 'Artwork_state_history_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  state_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_State_History_Sum_Fields = {
  __typename?: 'Artwork_state_history_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  state_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
};

/** update columns of table "Artwork_state_history" */
export enum Artwork_State_History_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  StateBy = 'state_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_State_History_Var_Pop_Fields = {
  __typename?: 'Artwork_state_history_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  state_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_State_History_Var_Samp_Fields = {
  __typename?: 'Artwork_state_history_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  state_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_State_History_Variance_Fields = {
  __typename?: 'Artwork_state_history_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  state_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_state_history" */
export type Artwork_State_History_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state_by?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Artwork_Stddev_Fields = {
  __typename?: 'Artwork_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  crop_area_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  original_image_metadata_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork" */
export type Artwork_Stddev_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Stddev_Pop_Fields = {
  __typename?: 'Artwork_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  crop_area_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  original_image_metadata_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork" */
export type Artwork_Stddev_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Stddev_Samp_Fields = {
  __typename?: 'Artwork_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  crop_area_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  original_image_metadata_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork" */
export type Artwork_Stddev_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** columns and relationships of "Artwork_story" */
export type Artwork_Story = {
  __typename?: 'Artwork_story';
  /** An object relationship */
  artwork?: Maybe<Artwork>;
  /** An array relationship */
  artwork_story_media: Array<Artwork_Story_Media>;
  /** An aggregate relationship */
  artwork_story_media_aggregate: Artwork_Story_Media_Aggregate;
  /** An array relationship */
  artwork_story_video_links: Array<Artwork_Story_Video_Links>;
  /** An aggregate relationship */
  artwork_story_video_links_aggregate: Artwork_Story_Video_Links_Aggregate;
  /** An object relationship */
  audio_metadata?: Maybe<File_Metadata>;
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  statement?: Maybe<Scalars['String']>;
  story_audio_download_link?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Artwork_story" */
export type Artwork_StoryArtwork_Story_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Media_Order_By>>;
  where?: Maybe<Artwork_Story_Media_Bool_Exp>;
};


/** columns and relationships of "Artwork_story" */
export type Artwork_StoryArtwork_Story_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Media_Order_By>>;
  where?: Maybe<Artwork_Story_Media_Bool_Exp>;
};


/** columns and relationships of "Artwork_story" */
export type Artwork_StoryArtwork_Story_Video_LinksArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Video_Links_Order_By>>;
  where?: Maybe<Artwork_Story_Video_Links_Bool_Exp>;
};


/** columns and relationships of "Artwork_story" */
export type Artwork_StoryArtwork_Story_Video_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Video_Links_Order_By>>;
  where?: Maybe<Artwork_Story_Video_Links_Bool_Exp>;
};

/** aggregated selection of "Artwork_story" */
export type Artwork_Story_Aggregate = {
  __typename?: 'Artwork_story_aggregate';
  aggregate?: Maybe<Artwork_Story_Aggregate_Fields>;
  nodes: Array<Artwork_Story>;
};

/** aggregate fields of "Artwork_story" */
export type Artwork_Story_Aggregate_Fields = {
  __typename?: 'Artwork_story_aggregate_fields';
  avg?: Maybe<Artwork_Story_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Story_Max_Fields>;
  min?: Maybe<Artwork_Story_Min_Fields>;
  stddev?: Maybe<Artwork_Story_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Story_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Story_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Story_Sum_Fields>;
  var_pop?: Maybe<Artwork_Story_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Story_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Story_Variance_Fields>;
};


/** aggregate fields of "Artwork_story" */
export type Artwork_Story_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Story_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_story" */
export type Artwork_Story_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Story_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Story_Max_Order_By>;
  min?: Maybe<Artwork_Story_Min_Order_By>;
  stddev?: Maybe<Artwork_Story_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Story_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Story_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Story_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Story_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Story_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Story_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_story" */
export type Artwork_Story_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Story_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Story_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Story_Avg_Fields = {
  __typename?: 'Artwork_story_avg_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_story" */
export type Artwork_Story_Avg_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_story". All fields are combined with a logical 'AND'. */
export type Artwork_Story_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Story_Bool_Exp>>;
  _not?: Maybe<Artwork_Story_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Story_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_story_media?: Maybe<Artwork_Story_Media_Bool_Exp>;
  artwork_story_video_links?: Maybe<Artwork_Story_Video_Links_Bool_Exp>;
  audio_metadata?: Maybe<File_Metadata_Bool_Exp>;
  audio_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  statement?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_story" */
export enum Artwork_Story_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkStoryPkey = 'Artwork_story_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_story" */
export type Artwork_Story_Inc_Input = {
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_story" */
export type Artwork_Story_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_story_media?: Maybe<Artwork_Story_Media_Arr_Rel_Insert_Input>;
  artwork_story_video_links?: Maybe<Artwork_Story_Video_Links_Arr_Rel_Insert_Input>;
  audio_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Story_Max_Fields = {
  __typename?: 'Artwork_story_max_fields';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_story" */
export type Artwork_Story_Max_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "Artwork_story_media" */
export type Artwork_Story_Media = {
  __typename?: 'Artwork_story_media';
  /** An object relationship */
  artwork_story: Artwork_Story;
  artwork_story_id: Scalars['bigint'];
  artwork_story_media_download_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  artwork_story_media_metadata?: Maybe<File_Metadata>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  order?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_story_media" */
export type Artwork_Story_Media_Aggregate = {
  __typename?: 'Artwork_story_media_aggregate';
  aggregate?: Maybe<Artwork_Story_Media_Aggregate_Fields>;
  nodes: Array<Artwork_Story_Media>;
};

/** aggregate fields of "Artwork_story_media" */
export type Artwork_Story_Media_Aggregate_Fields = {
  __typename?: 'Artwork_story_media_aggregate_fields';
  avg?: Maybe<Artwork_Story_Media_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Story_Media_Max_Fields>;
  min?: Maybe<Artwork_Story_Media_Min_Fields>;
  stddev?: Maybe<Artwork_Story_Media_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Story_Media_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Story_Media_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Story_Media_Sum_Fields>;
  var_pop?: Maybe<Artwork_Story_Media_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Story_Media_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Story_Media_Variance_Fields>;
};


/** aggregate fields of "Artwork_story_media" */
export type Artwork_Story_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Story_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_story_media" */
export type Artwork_Story_Media_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Story_Media_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Story_Media_Max_Order_By>;
  min?: Maybe<Artwork_Story_Media_Min_Order_By>;
  stddev?: Maybe<Artwork_Story_Media_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Story_Media_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Story_Media_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Story_Media_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Story_Media_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Story_Media_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Story_Media_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_story_media" */
export type Artwork_Story_Media_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Story_Media_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Story_Media_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Story_Media_Avg_Fields = {
  __typename?: 'Artwork_story_media_avg_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Avg_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_story_media". All fields are combined with a logical 'AND'. */
export type Artwork_Story_Media_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Story_Media_Bool_Exp>>;
  _not?: Maybe<Artwork_Story_Media_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Story_Media_Bool_Exp>>;
  artwork_story?: Maybe<Artwork_Story_Bool_Exp>;
  artwork_story_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_story_media_metadata?: Maybe<File_Metadata_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_story_media" */
export enum Artwork_Story_Media_Constraint {
  /** unique or primary key constraint on columns "file_metadata_id", "artwork_story_id" */
  ArtworkStoryMediaArtworkStoryIdFileMetadataIdKey = 'Artwork_story_media_artwork_story_id_file_metadata_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtworkStoryMediaPkey = 'Artwork_story_media_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_story_media" */
export type Artwork_Story_Media_Inc_Input = {
  artwork_story_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artwork_story_media" */
export type Artwork_Story_Media_Insert_Input = {
  artwork_story?: Maybe<Artwork_Story_Obj_Rel_Insert_Input>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  artwork_story_media_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Story_Media_Max_Fields = {
  __typename?: 'Artwork_story_media_max_fields';
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Max_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Story_Media_Min_Fields = {
  __typename?: 'Artwork_story_media_min_fields';
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Min_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_story_media" */
export type Artwork_Story_Media_Mutation_Response = {
  __typename?: 'Artwork_story_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Story_Media>;
};

/** on_conflict condition type for table "Artwork_story_media" */
export type Artwork_Story_Media_On_Conflict = {
  constraint: Artwork_Story_Media_Constraint;
  update_columns?: Array<Artwork_Story_Media_Update_Column>;
  where?: Maybe<Artwork_Story_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_story_media". */
export type Artwork_Story_Media_Order_By = {
  artwork_story?: Maybe<Artwork_Story_Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  artwork_story_media_metadata?: Maybe<File_Metadata_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_story_media */
export type Artwork_Story_Media_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_story_media" */
export enum Artwork_Story_Media_Select_Column {
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_story_media" */
export type Artwork_Story_Media_Set_Input = {
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Story_Media_Stddev_Fields = {
  __typename?: 'Artwork_story_media_stddev_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Stddev_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Story_Media_Stddev_Pop_Fields = {
  __typename?: 'Artwork_story_media_stddev_pop_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Stddev_Pop_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Story_Media_Stddev_Samp_Fields = {
  __typename?: 'Artwork_story_media_stddev_samp_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Stddev_Samp_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Story_Media_Sum_Fields = {
  __typename?: 'Artwork_story_media_sum_fields';
  artwork_story_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Sum_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "Artwork_story_media" */
export enum Artwork_Story_Media_Update_Column {
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Story_Media_Var_Pop_Fields = {
  __typename?: 'Artwork_story_media_var_pop_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Var_Pop_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Story_Media_Var_Samp_Fields = {
  __typename?: 'Artwork_story_media_var_samp_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Var_Samp_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Story_Media_Variance_Fields = {
  __typename?: 'Artwork_story_media_variance_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_story_media" */
export type Artwork_Story_Media_Variance_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Story_Min_Fields = {
  __typename?: 'Artwork_story_min_fields';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_story" */
export type Artwork_Story_Min_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_story" */
export type Artwork_Story_Mutation_Response = {
  __typename?: 'Artwork_story_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Story>;
};

/** input type for inserting object relation for remote table "Artwork_story" */
export type Artwork_Story_Obj_Rel_Insert_Input = {
  data: Artwork_Story_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Story_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_story" */
export type Artwork_Story_On_Conflict = {
  constraint: Artwork_Story_Constraint;
  update_columns?: Array<Artwork_Story_Update_Column>;
  where?: Maybe<Artwork_Story_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_story". */
export type Artwork_Story_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  artwork_story_media_aggregate?: Maybe<Artwork_Story_Media_Aggregate_Order_By>;
  artwork_story_video_links_aggregate?: Maybe<Artwork_Story_Video_Links_Aggregate_Order_By>;
  audio_metadata?: Maybe<File_Metadata_Order_By>;
  audio_metadata_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_story */
export type Artwork_Story_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_story" */
export enum Artwork_Story_Select_Column {
  /** column name */
  AudioMetadataId = 'audio_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Statement = 'statement',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_story" */
export type Artwork_Story_Set_Input = {
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Story_Stddev_Fields = {
  __typename?: 'Artwork_story_stddev_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_story" */
export type Artwork_Story_Stddev_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Story_Stddev_Pop_Fields = {
  __typename?: 'Artwork_story_stddev_pop_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_story" */
export type Artwork_Story_Stddev_Pop_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Story_Stddev_Samp_Fields = {
  __typename?: 'Artwork_story_stddev_samp_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_story" */
export type Artwork_Story_Stddev_Samp_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Story_Sum_Fields = {
  __typename?: 'Artwork_story_sum_fields';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_story" */
export type Artwork_Story_Sum_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_story" */
export enum Artwork_Story_Update_Column {
  /** column name */
  AudioMetadataId = 'audio_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Statement = 'statement',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Story_Var_Pop_Fields = {
  __typename?: 'Artwork_story_var_pop_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_story" */
export type Artwork_Story_Var_Pop_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Story_Var_Samp_Fields = {
  __typename?: 'Artwork_story_var_samp_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_story" */
export type Artwork_Story_Var_Samp_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Story_Variance_Fields = {
  __typename?: 'Artwork_story_variance_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_story" */
export type Artwork_Story_Variance_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "Artwork_story_video_links" */
export type Artwork_Story_Video_Links = {
  __typename?: 'Artwork_story_video_links';
  URL: Scalars['String'];
  /** An object relationship */
  artwork_story: Artwork_Story;
  artwork_story_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  platform: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Aggregate = {
  __typename?: 'Artwork_story_video_links_aggregate';
  aggregate?: Maybe<Artwork_Story_Video_Links_Aggregate_Fields>;
  nodes: Array<Artwork_Story_Video_Links>;
};

/** aggregate fields of "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Aggregate_Fields = {
  __typename?: 'Artwork_story_video_links_aggregate_fields';
  avg?: Maybe<Artwork_Story_Video_Links_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Story_Video_Links_Max_Fields>;
  min?: Maybe<Artwork_Story_Video_Links_Min_Fields>;
  stddev?: Maybe<Artwork_Story_Video_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Story_Video_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Story_Video_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Story_Video_Links_Sum_Fields>;
  var_pop?: Maybe<Artwork_Story_Video_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Story_Video_Links_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Story_Video_Links_Variance_Fields>;
};


/** aggregate fields of "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Story_Video_Links_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Story_Video_Links_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Story_Video_Links_Max_Order_By>;
  min?: Maybe<Artwork_Story_Video_Links_Min_Order_By>;
  stddev?: Maybe<Artwork_Story_Video_Links_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Story_Video_Links_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Story_Video_Links_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Story_Video_Links_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Story_Video_Links_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Story_Video_Links_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Story_Video_Links_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Story_Video_Links_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Story_Video_Links_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Story_Video_Links_Avg_Fields = {
  __typename?: 'Artwork_story_video_links_avg_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Avg_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_story_video_links". All fields are combined with a logical 'AND'. */
export type Artwork_Story_Video_Links_Bool_Exp = {
  URL?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Artwork_Story_Video_Links_Bool_Exp>>;
  _not?: Maybe<Artwork_Story_Video_Links_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Story_Video_Links_Bool_Exp>>;
  artwork_story?: Maybe<Artwork_Story_Bool_Exp>;
  artwork_story_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  platform?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_story_video_links" */
export enum Artwork_Story_Video_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkStoryVideoLinksPkey = 'Artwork_story_video_links_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Inc_Input = {
  artwork_story_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Insert_Input = {
  URL?: Maybe<Scalars['String']>;
  artwork_story?: Maybe<Artwork_Story_Obj_Rel_Insert_Input>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Story_Video_Links_Max_Fields = {
  __typename?: 'Artwork_story_video_links_max_fields';
  URL?: Maybe<Scalars['String']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Max_Order_By = {
  URL?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Story_Video_Links_Min_Fields = {
  __typename?: 'Artwork_story_video_links_min_fields';
  URL?: Maybe<Scalars['String']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Min_Order_By = {
  URL?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Mutation_Response = {
  __typename?: 'Artwork_story_video_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Story_Video_Links>;
};

/** on_conflict condition type for table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_On_Conflict = {
  constraint: Artwork_Story_Video_Links_Constraint;
  update_columns?: Array<Artwork_Story_Video_Links_Update_Column>;
  where?: Maybe<Artwork_Story_Video_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_story_video_links". */
export type Artwork_Story_Video_Links_Order_By = {
  URL?: Maybe<Order_By>;
  artwork_story?: Maybe<Artwork_Story_Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_story_video_links */
export type Artwork_Story_Video_Links_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_story_video_links" */
export enum Artwork_Story_Video_Links_Select_Column {
  /** column name */
  Url = 'URL',
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Set_Input = {
  URL?: Maybe<Scalars['String']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Story_Video_Links_Stddev_Fields = {
  __typename?: 'Artwork_story_video_links_stddev_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Stddev_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Story_Video_Links_Stddev_Pop_Fields = {
  __typename?: 'Artwork_story_video_links_stddev_pop_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Stddev_Pop_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Story_Video_Links_Stddev_Samp_Fields = {
  __typename?: 'Artwork_story_video_links_stddev_samp_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Stddev_Samp_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Story_Video_Links_Sum_Fields = {
  __typename?: 'Artwork_story_video_links_sum_fields';
  artwork_story_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Sum_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_story_video_links" */
export enum Artwork_Story_Video_Links_Update_Column {
  /** column name */
  Url = 'URL',
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Story_Video_Links_Var_Pop_Fields = {
  __typename?: 'Artwork_story_video_links_var_pop_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Var_Pop_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Story_Video_Links_Var_Samp_Fields = {
  __typename?: 'Artwork_story_video_links_var_samp_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Var_Samp_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Story_Video_Links_Variance_Fields = {
  __typename?: 'Artwork_story_video_links_variance_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_story_video_links" */
export type Artwork_Story_Video_Links_Variance_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Sum_Fields = {
  __typename?: 'Artwork_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  crop_area_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  original_image_metadata_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Artwork" */
export type Artwork_Sum_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** columns and relationships of "Artwork_tag" */
export type Artwork_Tag = {
  __typename?: 'Artwork_tag';
  can_be_primary: Scalars['Boolean'];
  description: Scalars['String'];
  family: Scalars['String'];
  genome_id?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  name: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "Artwork_tag" */
export type Artwork_Tag_Aggregate = {
  __typename?: 'Artwork_tag_aggregate';
  aggregate?: Maybe<Artwork_Tag_Aggregate_Fields>;
  nodes: Array<Artwork_Tag>;
};

/** aggregate fields of "Artwork_tag" */
export type Artwork_Tag_Aggregate_Fields = {
  __typename?: 'Artwork_tag_aggregate_fields';
  avg?: Maybe<Artwork_Tag_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Tag_Max_Fields>;
  min?: Maybe<Artwork_Tag_Min_Fields>;
  stddev?: Maybe<Artwork_Tag_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Tag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Tag_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Tag_Sum_Fields>;
  var_pop?: Maybe<Artwork_Tag_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Tag_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Tag_Variance_Fields>;
};


/** aggregate fields of "Artwork_tag" */
export type Artwork_Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Tag_Avg_Fields = {
  __typename?: 'Artwork_tag_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_tag". All fields are combined with a logical 'AND'. */
export type Artwork_Tag_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Tag_Bool_Exp>>;
  _not?: Maybe<Artwork_Tag_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Tag_Bool_Exp>>;
  can_be_primary?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  family?: Maybe<String_Comparison_Exp>;
  genome_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_tag" */
export enum Artwork_Tag_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkTagPkey = 'Artwork_tag_pkey',
  /** unique or primary key constraint on columns "value" */
  ArtworkTagValueKey = 'Artwork_tag_value_key'
}

/** input type for incrementing numeric columns in table "Artwork_tag" */
export type Artwork_Tag_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_tag" */
export type Artwork_Tag_Insert_Input = {
  can_be_primary?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  genome_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Artwork_Tag_Max_Fields = {
  __typename?: 'Artwork_tag_max_fields';
  description?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  genome_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artwork_Tag_Min_Fields = {
  __typename?: 'Artwork_tag_min_fields';
  description?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  genome_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Artwork_tag" */
export type Artwork_Tag_Mutation_Response = {
  __typename?: 'Artwork_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Tag>;
};

/** input type for inserting object relation for remote table "Artwork_tag" */
export type Artwork_Tag_Obj_Rel_Insert_Input = {
  data: Artwork_Tag_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Tag_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_tag" */
export type Artwork_Tag_On_Conflict = {
  constraint: Artwork_Tag_Constraint;
  update_columns?: Array<Artwork_Tag_Update_Column>;
  where?: Maybe<Artwork_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_tag". */
export type Artwork_Tag_Order_By = {
  can_be_primary?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  family?: Maybe<Order_By>;
  genome_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_tag */
export type Artwork_Tag_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_tag" */
export enum Artwork_Tag_Select_Column {
  /** column name */
  CanBePrimary = 'can_be_primary',
  /** column name */
  Description = 'description',
  /** column name */
  Family = 'family',
  /** column name */
  GenomeId = 'genome_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Artwork_tag" */
export type Artwork_Tag_Set_Input = {
  can_be_primary?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  genome_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Artwork_Tag_Stddev_Fields = {
  __typename?: 'Artwork_tag_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Tag_Stddev_Pop_Fields = {
  __typename?: 'Artwork_tag_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Tag_Stddev_Samp_Fields = {
  __typename?: 'Artwork_tag_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Tag_Sum_Fields = {
  __typename?: 'Artwork_tag_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Artwork_tag" */
export enum Artwork_Tag_Update_Column {
  /** column name */
  CanBePrimary = 'can_be_primary',
  /** column name */
  Description = 'description',
  /** column name */
  Family = 'family',
  /** column name */
  GenomeId = 'genome_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Artwork_Tag_Var_Pop_Fields = {
  __typename?: 'Artwork_tag_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Tag_Var_Samp_Fields = {
  __typename?: 'Artwork_tag_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Tag_Variance_Fields = {
  __typename?: 'Artwork_tag_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Associates an Artwork to a Collection */
export type Artwork_To_Collection = {
  __typename?: 'Artwork_to_Collection';
  /** An object relationship */
  Artwork: Artwork;
  /** An object relationship */
  Artwork_to_showroom_detail?: Maybe<Artwork_To_Showroom_Details>;
  /** An object relationship */
  Collection: Collection;
  artwork_id: Scalars['bigint'];
  artwork_type?: Maybe<Scalars['String']>;
  collection_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  is_public?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Artwork_to_Collection" */
export type Artwork_To_Collection_Aggregate = {
  __typename?: 'Artwork_to_Collection_aggregate';
  aggregate?: Maybe<Artwork_To_Collection_Aggregate_Fields>;
  nodes: Array<Artwork_To_Collection>;
};

/** aggregate fields of "Artwork_to_Collection" */
export type Artwork_To_Collection_Aggregate_Fields = {
  __typename?: 'Artwork_to_Collection_aggregate_fields';
  avg?: Maybe<Artwork_To_Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_To_Collection_Max_Fields>;
  min?: Maybe<Artwork_To_Collection_Min_Fields>;
  stddev?: Maybe<Artwork_To_Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_To_Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_To_Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_To_Collection_Sum_Fields>;
  var_pop?: Maybe<Artwork_To_Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_To_Collection_Var_Samp_Fields>;
  variance?: Maybe<Artwork_To_Collection_Variance_Fields>;
};


/** aggregate fields of "Artwork_to_Collection" */
export type Artwork_To_Collection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Aggregate_Order_By = {
  avg?: Maybe<Artwork_To_Collection_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_To_Collection_Max_Order_By>;
  min?: Maybe<Artwork_To_Collection_Min_Order_By>;
  stddev?: Maybe<Artwork_To_Collection_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_To_Collection_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_To_Collection_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_To_Collection_Sum_Order_By>;
  var_pop?: Maybe<Artwork_To_Collection_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_To_Collection_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_To_Collection_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_to_Collection" */
export type Artwork_To_Collection_Arr_Rel_Insert_Input = {
  data: Array<Artwork_To_Collection_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_To_Collection_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_To_Collection_Avg_Fields = {
  __typename?: 'Artwork_to_Collection_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_to_Collection". All fields are combined with a logical 'AND'. */
export type Artwork_To_Collection_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  Artwork_to_showroom_detail?: Maybe<Artwork_To_Showroom_Details_Bool_Exp>;
  Collection?: Maybe<Collection_Bool_Exp>;
  _and?: Maybe<Array<Artwork_To_Collection_Bool_Exp>>;
  _not?: Maybe<Artwork_To_Collection_Bool_Exp>;
  _or?: Maybe<Array<Artwork_To_Collection_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_type?: Maybe<String_Comparison_Exp>;
  collection_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_to_Collection" */
export enum Artwork_To_Collection_Constraint {
  /** unique or primary key constraint on columns "collection_id", "artwork_id" */
  ArtworkToCollectionArtworkIdCollectionIdKey = 'Artwork_to_Collection_artwork_id_collection_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtworkToCollectionPkey = 'Artwork_to_Collection_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_to_Collection" */
export type Artwork_To_Collection_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Artwork_to_Collection" */
export type Artwork_To_Collection_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  Artwork_to_showroom_detail?: Maybe<Artwork_To_Showroom_Details_Obj_Rel_Insert_Input>;
  Collection?: Maybe<Collection_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_To_Collection_Max_Fields = {
  __typename?: 'Artwork_to_Collection_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_type?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_To_Collection_Min_Fields = {
  __typename?: 'Artwork_to_Collection_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_type?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_to_Collection" */
export type Artwork_To_Collection_Mutation_Response = {
  __typename?: 'Artwork_to_Collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_To_Collection>;
};

/** input type for inserting object relation for remote table "Artwork_to_Collection" */
export type Artwork_To_Collection_Obj_Rel_Insert_Input = {
  data: Artwork_To_Collection_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_To_Collection_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_to_Collection" */
export type Artwork_To_Collection_On_Conflict = {
  constraint: Artwork_To_Collection_Constraint;
  update_columns?: Array<Artwork_To_Collection_Update_Column>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_to_Collection". */
export type Artwork_To_Collection_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  Artwork_to_showroom_detail?: Maybe<Artwork_To_Showroom_Details_Order_By>;
  Collection?: Maybe<Collection_Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_type?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_to_Collection */
export type Artwork_To_Collection_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_to_Collection" */
export enum Artwork_To_Collection_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkType = 'artwork_type',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_to_Collection" */
export type Artwork_To_Collection_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_To_Collection_Stddev_Fields = {
  __typename?: 'Artwork_to_Collection_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_To_Collection_Stddev_Pop_Fields = {
  __typename?: 'Artwork_to_Collection_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_To_Collection_Stddev_Samp_Fields = {
  __typename?: 'Artwork_to_Collection_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_To_Collection_Sum_Fields = {
  __typename?: 'Artwork_to_Collection_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "Artwork_to_Collection" */
export enum Artwork_To_Collection_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkType = 'artwork_type',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_To_Collection_Var_Pop_Fields = {
  __typename?: 'Artwork_to_Collection_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_To_Collection_Var_Samp_Fields = {
  __typename?: 'Artwork_to_Collection_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_To_Collection_Variance_Fields = {
  __typename?: 'Artwork_to_Collection_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_to_Collection" */
export type Artwork_To_Collection_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** columns and relationships of "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details = {
  __typename?: 'Artwork_to_showroom_details';
  Artwork_to_collection_id: Scalars['bigint'];
  /** An object relationship */
  artwork_showroom_price?: Maybe<Artwork_Prices>;
  /** An object relationship */
  artwork_to_collection: Artwork_To_Collection;
  id: Scalars['bigint'];
  price_id?: Maybe<Scalars['bigint']>;
  status: Enum_Artwork_Showroom_Status_Enum;
  /** An object relationship */
  status_value: Enum_Artwork_Showroom_Status;
};

/** aggregated selection of "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details_Aggregate = {
  __typename?: 'Artwork_to_showroom_details_aggregate';
  aggregate?: Maybe<Artwork_To_Showroom_Details_Aggregate_Fields>;
  nodes: Array<Artwork_To_Showroom_Details>;
};

/** aggregate fields of "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details_Aggregate_Fields = {
  __typename?: 'Artwork_to_showroom_details_aggregate_fields';
  avg?: Maybe<Artwork_To_Showroom_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_To_Showroom_Details_Max_Fields>;
  min?: Maybe<Artwork_To_Showroom_Details_Min_Fields>;
  stddev?: Maybe<Artwork_To_Showroom_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_To_Showroom_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_To_Showroom_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_To_Showroom_Details_Sum_Fields>;
  var_pop?: Maybe<Artwork_To_Showroom_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_To_Showroom_Details_Var_Samp_Fields>;
  variance?: Maybe<Artwork_To_Showroom_Details_Variance_Fields>;
};


/** aggregate fields of "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_To_Showroom_Details_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_To_Showroom_Details_Avg_Fields = {
  __typename?: 'Artwork_to_showroom_details_avg_fields';
  Artwork_to_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Artwork_to_showroom_details". All fields are combined with a logical 'AND'. */
export type Artwork_To_Showroom_Details_Bool_Exp = {
  Artwork_to_collection_id?: Maybe<Bigint_Comparison_Exp>;
  _and?: Maybe<Array<Artwork_To_Showroom_Details_Bool_Exp>>;
  _not?: Maybe<Artwork_To_Showroom_Details_Bool_Exp>;
  _or?: Maybe<Array<Artwork_To_Showroom_Details_Bool_Exp>>;
  artwork_showroom_price?: Maybe<Artwork_Prices_Bool_Exp>;
  artwork_to_collection?: Maybe<Artwork_To_Collection_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  price_id?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<Enum_Artwork_Showroom_Status_Enum_Comparison_Exp>;
  status_value?: Maybe<Enum_Artwork_Showroom_Status_Bool_Exp>;
};

/** unique or primary key constraints on table "Artwork_to_showroom_details" */
export enum Artwork_To_Showroom_Details_Constraint {
  /** unique or primary key constraint on columns "Artwork_to_collection_id" */
  ArtworkToShowroomDetailsArtworkToCollectionIdKey = 'Artwork_to_showroom_details_Artwork_to_collection_id_key',
  /** unique or primary key constraint on columns "id" */
  ArtworkToShowroomDetailsPkey = 'Artwork_to_showroom_details_pkey',
  /** unique or primary key constraint on columns "price_id" */
  ArtworkToShowroomDetailsPriceIdKey = 'Artwork_to_showroom_details_price_id_key'
}

/** input type for incrementing numeric columns in table "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details_Inc_Input = {
  Artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details_Insert_Input = {
  Artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  artwork_showroom_price?: Maybe<Artwork_Prices_Obj_Rel_Insert_Input>;
  artwork_to_collection?: Maybe<Artwork_To_Collection_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  price_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_Artwork_Showroom_Status_Enum>;
  status_value?: Maybe<Enum_Artwork_Showroom_Status_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Artwork_To_Showroom_Details_Max_Fields = {
  __typename?: 'Artwork_to_showroom_details_max_fields';
  Artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Artwork_To_Showroom_Details_Min_Fields = {
  __typename?: 'Artwork_to_showroom_details_min_fields';
  Artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details_Mutation_Response = {
  __typename?: 'Artwork_to_showroom_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_To_Showroom_Details>;
};

/** input type for inserting object relation for remote table "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details_Obj_Rel_Insert_Input = {
  data: Artwork_To_Showroom_Details_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_To_Showroom_Details_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details_On_Conflict = {
  constraint: Artwork_To_Showroom_Details_Constraint;
  update_columns?: Array<Artwork_To_Showroom_Details_Update_Column>;
  where?: Maybe<Artwork_To_Showroom_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_to_showroom_details". */
export type Artwork_To_Showroom_Details_Order_By = {
  Artwork_to_collection_id?: Maybe<Order_By>;
  artwork_showroom_price?: Maybe<Artwork_Prices_Order_By>;
  artwork_to_collection?: Maybe<Artwork_To_Collection_Order_By>;
  id?: Maybe<Order_By>;
  price_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_value?: Maybe<Enum_Artwork_Showroom_Status_Order_By>;
};

/** primary key columns input for table: Artwork_to_showroom_details */
export type Artwork_To_Showroom_Details_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_to_showroom_details" */
export enum Artwork_To_Showroom_Details_Select_Column {
  /** column name */
  ArtworkToCollectionId = 'Artwork_to_collection_id',
  /** column name */
  Id = 'id',
  /** column name */
  PriceId = 'price_id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "Artwork_to_showroom_details" */
export type Artwork_To_Showroom_Details_Set_Input = {
  Artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_Artwork_Showroom_Status_Enum>;
};

/** aggregate stddev on columns */
export type Artwork_To_Showroom_Details_Stddev_Fields = {
  __typename?: 'Artwork_to_showroom_details_stddev_fields';
  Artwork_to_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_To_Showroom_Details_Stddev_Pop_Fields = {
  __typename?: 'Artwork_to_showroom_details_stddev_pop_fields';
  Artwork_to_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_To_Showroom_Details_Stddev_Samp_Fields = {
  __typename?: 'Artwork_to_showroom_details_stddev_samp_fields';
  Artwork_to_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_To_Showroom_Details_Sum_Fields = {
  __typename?: 'Artwork_to_showroom_details_sum_fields';
  Artwork_to_collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Artwork_to_showroom_details" */
export enum Artwork_To_Showroom_Details_Update_Column {
  /** column name */
  ArtworkToCollectionId = 'Artwork_to_collection_id',
  /** column name */
  Id = 'id',
  /** column name */
  PriceId = 'price_id',
  /** column name */
  Status = 'status'
}

/** aggregate var_pop on columns */
export type Artwork_To_Showroom_Details_Var_Pop_Fields = {
  __typename?: 'Artwork_to_showroom_details_var_pop_fields';
  Artwork_to_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_To_Showroom_Details_Var_Samp_Fields = {
  __typename?: 'Artwork_to_showroom_details_var_samp_fields';
  Artwork_to_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_To_Showroom_Details_Variance_Fields = {
  __typename?: 'Artwork_to_showroom_details_variance_fields';
  Artwork_to_collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Artwork_to_tag" */
export type Artwork_To_Tag = {
  __typename?: 'Artwork_to_tag';
  /** An object relationship */
  ENUM_artwork_tag_type: Enum_Artwork_Tag_Type;
  /** An object relationship */
  artwork: Artwork;
  artwork_id: Scalars['bigint'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  /** An object relationship */
  tag: Artwork_Tag;
  tag_value: Scalars['String'];
  type: Enum_Artwork_Tag_Type_Enum;
};

/** aggregated selection of "Artwork_to_tag" */
export type Artwork_To_Tag_Aggregate = {
  __typename?: 'Artwork_to_tag_aggregate';
  aggregate?: Maybe<Artwork_To_Tag_Aggregate_Fields>;
  nodes: Array<Artwork_To_Tag>;
};

/** aggregate fields of "Artwork_to_tag" */
export type Artwork_To_Tag_Aggregate_Fields = {
  __typename?: 'Artwork_to_tag_aggregate_fields';
  avg?: Maybe<Artwork_To_Tag_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_To_Tag_Max_Fields>;
  min?: Maybe<Artwork_To_Tag_Min_Fields>;
  stddev?: Maybe<Artwork_To_Tag_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_To_Tag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_To_Tag_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_To_Tag_Sum_Fields>;
  var_pop?: Maybe<Artwork_To_Tag_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_To_Tag_Var_Samp_Fields>;
  variance?: Maybe<Artwork_To_Tag_Variance_Fields>;
};


/** aggregate fields of "Artwork_to_tag" */
export type Artwork_To_Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_To_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_to_tag" */
export type Artwork_To_Tag_Aggregate_Order_By = {
  avg?: Maybe<Artwork_To_Tag_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_To_Tag_Max_Order_By>;
  min?: Maybe<Artwork_To_Tag_Min_Order_By>;
  stddev?: Maybe<Artwork_To_Tag_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_To_Tag_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_To_Tag_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_To_Tag_Sum_Order_By>;
  var_pop?: Maybe<Artwork_To_Tag_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_To_Tag_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_To_Tag_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_to_tag" */
export type Artwork_To_Tag_Arr_Rel_Insert_Input = {
  data: Array<Artwork_To_Tag_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_To_Tag_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_To_Tag_Avg_Fields = {
  __typename?: 'Artwork_to_tag_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_to_tag". All fields are combined with a logical 'AND'. */
export type Artwork_To_Tag_Bool_Exp = {
  ENUM_artwork_tag_type?: Maybe<Enum_Artwork_Tag_Type_Bool_Exp>;
  _and?: Maybe<Array<Artwork_To_Tag_Bool_Exp>>;
  _not?: Maybe<Artwork_To_Tag_Bool_Exp>;
  _or?: Maybe<Array<Artwork_To_Tag_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  tag?: Maybe<Artwork_Tag_Bool_Exp>;
  tag_value?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Enum_Artwork_Tag_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_to_tag" */
export enum Artwork_To_Tag_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkTagsPkey = 'Artwork_tags_pkey'
}

/** input type for incrementing numeric columns in table "Artwork_to_tag" */
export type Artwork_To_Tag_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_to_tag" */
export type Artwork_To_Tag_Insert_Input = {
  ENUM_artwork_tag_type?: Maybe<Enum_Artwork_Tag_Type_Obj_Rel_Insert_Input>;
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tag?: Maybe<Artwork_Tag_Obj_Rel_Insert_Input>;
  tag_value?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Artwork_Tag_Type_Enum>;
};

/** aggregate max on columns */
export type Artwork_To_Tag_Max_Fields = {
  __typename?: 'Artwork_to_tag_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tag_value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tag_value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_To_Tag_Min_Fields = {
  __typename?: 'Artwork_to_tag_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tag_value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tag_value?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_to_tag" */
export type Artwork_To_Tag_Mutation_Response = {
  __typename?: 'Artwork_to_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_To_Tag>;
};

/** on_conflict condition type for table "Artwork_to_tag" */
export type Artwork_To_Tag_On_Conflict = {
  constraint: Artwork_To_Tag_Constraint;
  update_columns?: Array<Artwork_To_Tag_Update_Column>;
  where?: Maybe<Artwork_To_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_to_tag". */
export type Artwork_To_Tag_Order_By = {
  ENUM_artwork_tag_type?: Maybe<Enum_Artwork_Tag_Type_Order_By>;
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tag?: Maybe<Artwork_Tag_Order_By>;
  tag_value?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_to_tag */
export type Artwork_To_Tag_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_to_tag" */
export enum Artwork_To_Tag_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  TagValue = 'tag_value',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "Artwork_to_tag" */
export type Artwork_To_Tag_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tag_value?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Artwork_Tag_Type_Enum>;
};

/** aggregate stddev on columns */
export type Artwork_To_Tag_Stddev_Fields = {
  __typename?: 'Artwork_to_tag_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_To_Tag_Stddev_Pop_Fields = {
  __typename?: 'Artwork_to_tag_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_To_Tag_Stddev_Samp_Fields = {
  __typename?: 'Artwork_to_tag_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_To_Tag_Sum_Fields = {
  __typename?: 'Artwork_to_tag_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_to_tag" */
export enum Artwork_To_Tag_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  TagValue = 'tag_value',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Artwork_To_Tag_Var_Pop_Fields = {
  __typename?: 'Artwork_to_tag_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_To_Tag_Var_Samp_Fields = {
  __typename?: 'Artwork_to_tag_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_To_Tag_Variance_Fields = {
  __typename?: 'Artwork_to_tag_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_to_tag" */
export type Artwork_To_Tag_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Keeps a record for every artwork transacted through our platform (maybe transactions pulled from the Ethereum blockchain) */
export type Artwork_Transactions = {
  __typename?: 'Artwork_transactions';
  /** An array relationship */
  Artwork_events: Array<Artwork_Events>;
  /** An aggregate relationship */
  Artwork_events_aggregate: Artwork_Events_Aggregate;
  /** An object relationship */
  Print?: Maybe<Print>;
  /** An object relationship */
  artwork: Artwork;
  artwork_id: Scalars['bigint'];
  /** An object relationship */
  buyer_context?: Maybe<Context>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  context: Context;
  /** An object relationship */
  contextByContextBuyerId?: Maybe<Context>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  is_gallery_sold?: Maybe<Scalars['Boolean']>;
  language: Enum_Email_Language_Enum;
  price: Scalars['float8'];
  print_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller_context?: Maybe<Context>;
  sold_date?: Maybe<Scalars['date']>;
  status: Enum_Transaction_Status_Enum;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** Keeps a record for every artwork transacted through our platform (maybe transactions pulled from the Ethereum blockchain) */
export type Artwork_TransactionsArtwork_EventsArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


/** Keeps a record for every artwork transacted through our platform (maybe transactions pulled from the Ethereum blockchain) */
export type Artwork_TransactionsArtwork_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};

/** aggregated selection of "Artwork_transactions" */
export type Artwork_Transactions_Aggregate = {
  __typename?: 'Artwork_transactions_aggregate';
  aggregate?: Maybe<Artwork_Transactions_Aggregate_Fields>;
  nodes: Array<Artwork_Transactions>;
};

/** aggregate fields of "Artwork_transactions" */
export type Artwork_Transactions_Aggregate_Fields = {
  __typename?: 'Artwork_transactions_aggregate_fields';
  avg?: Maybe<Artwork_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Transactions_Max_Fields>;
  min?: Maybe<Artwork_Transactions_Min_Fields>;
  stddev?: Maybe<Artwork_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Transactions_Sum_Fields>;
  var_pop?: Maybe<Artwork_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Transactions_Variance_Fields>;
};


/** aggregate fields of "Artwork_transactions" */
export type Artwork_Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Artwork_transactions" */
export type Artwork_Transactions_Aggregate_Order_By = {
  avg?: Maybe<Artwork_Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artwork_Transactions_Max_Order_By>;
  min?: Maybe<Artwork_Transactions_Min_Order_By>;
  stddev?: Maybe<Artwork_Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Artwork_Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artwork_Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Artwork_Transactions_Sum_Order_By>;
  var_pop?: Maybe<Artwork_Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Artwork_Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Artwork_Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Artwork_transactions" */
export type Artwork_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Artwork_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Artwork_Transactions_Avg_Fields = {
  __typename?: 'Artwork_transactions_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Artwork_transactions". All fields are combined with a logical 'AND'. */
export type Artwork_Transactions_Bool_Exp = {
  Artwork_events?: Maybe<Artwork_Events_Bool_Exp>;
  Print?: Maybe<Print_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Transactions_Bool_Exp>>;
  _not?: Maybe<Artwork_Transactions_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Transactions_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  buyer_context?: Maybe<Context_Bool_Exp>;
  buyer_email?: Maybe<String_Comparison_Exp>;
  buyer_first_name?: Maybe<String_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  contextByContextBuyerId?: Maybe<Context_Bool_Exp>;
  context_buyer_id?: Maybe<Bigint_Comparison_Exp>;
  context_seller_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  creation_day?: Maybe<Int_Comparison_Exp>;
  creation_month?: Maybe<Int_Comparison_Exp>;
  creation_year?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_gallery_sold?: Maybe<Boolean_Comparison_Exp>;
  language?: Maybe<Enum_Email_Language_Enum_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  print_id?: Maybe<Bigint_Comparison_Exp>;
  seller_context?: Maybe<Context_Bool_Exp>;
  sold_date?: Maybe<Date_Comparison_Exp>;
  status?: Maybe<Enum_Transaction_Status_Enum_Comparison_Exp>;
  status_at?: Maybe<Timestamptz_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Artwork_transactions" */
export enum Artwork_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionHistoryPkey = 'Transaction_history_pkey',
  /** unique or primary key constraint on columns "artwork_id", "print_id", "status" */
  ForbidMultipleTransfersOnArtwork = 'forbid_multiple_transfers_on_artwork'
}

/** input type for incrementing numeric columns in table "Artwork_transactions" */
export type Artwork_Transactions_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Artwork_transactions" */
export type Artwork_Transactions_Insert_Input = {
  Artwork_events?: Maybe<Artwork_Events_Arr_Rel_Insert_Input>;
  Print?: Maybe<Print_Obj_Rel_Insert_Input>;
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  contextByContextBuyerId?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  is_gallery_sold?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Enum_Email_Language_Enum>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  seller_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Enum_Transaction_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Artwork_Transactions_Max_Fields = {
  __typename?: 'Artwork_transactions_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  sold_date?: Maybe<Scalars['date']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  buyer_email?: Maybe<Order_By>;
  buyer_first_name?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
  sold_date?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artwork_Transactions_Min_Fields = {
  __typename?: 'Artwork_transactions_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  sold_date?: Maybe<Scalars['date']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  buyer_email?: Maybe<Order_By>;
  buyer_first_name?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
  sold_date?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Artwork_transactions" */
export type Artwork_Transactions_Mutation_Response = {
  __typename?: 'Artwork_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Artwork_Transactions>;
};

/** input type for inserting object relation for remote table "Artwork_transactions" */
export type Artwork_Transactions_Obj_Rel_Insert_Input = {
  data: Artwork_Transactions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Artwork_Transactions_On_Conflict>;
};

/** on_conflict condition type for table "Artwork_transactions" */
export type Artwork_Transactions_On_Conflict = {
  constraint: Artwork_Transactions_Constraint;
  update_columns?: Array<Artwork_Transactions_Update_Column>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "Artwork_transactions". */
export type Artwork_Transactions_Order_By = {
  Artwork_events_aggregate?: Maybe<Artwork_Events_Aggregate_Order_By>;
  Print?: Maybe<Print_Order_By>;
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  buyer_context?: Maybe<Context_Order_By>;
  buyer_email?: Maybe<Order_By>;
  buyer_first_name?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  contextByContextBuyerId?: Maybe<Context_Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_gallery_sold?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
  seller_context?: Maybe<Context_Order_By>;
  sold_date?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Artwork_transactions */
export type Artwork_Transactions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Artwork_transactions" */
export enum Artwork_Transactions_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  BuyerEmail = 'buyer_email',
  /** column name */
  BuyerFirstName = 'buyer_first_name',
  /** column name */
  ContextBuyerId = 'context_buyer_id',
  /** column name */
  ContextSellerId = 'context_seller_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDay = 'creation_day',
  /** column name */
  CreationMonth = 'creation_month',
  /** column name */
  CreationYear = 'creation_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsGallerySold = 'is_gallery_sold',
  /** column name */
  Language = 'language',
  /** column name */
  Price = 'price',
  /** column name */
  PrintId = 'print_id',
  /** column name */
  SoldDate = 'sold_date',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Artwork_transactions" */
export type Artwork_Transactions_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_first_name?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  is_gallery_sold?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Enum_Email_Language_Enum>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Enum_Transaction_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Artwork_Transactions_Stddev_Fields = {
  __typename?: 'Artwork_transactions_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Transactions_Stddev_Pop_Fields = {
  __typename?: 'Artwork_transactions_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Transactions_Stddev_Samp_Fields = {
  __typename?: 'Artwork_transactions_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artwork_Transactions_Sum_Fields = {
  __typename?: 'Artwork_transactions_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** update columns of table "Artwork_transactions" */
export enum Artwork_Transactions_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  BuyerEmail = 'buyer_email',
  /** column name */
  BuyerFirstName = 'buyer_first_name',
  /** column name */
  ContextBuyerId = 'context_buyer_id',
  /** column name */
  ContextSellerId = 'context_seller_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDay = 'creation_day',
  /** column name */
  CreationMonth = 'creation_month',
  /** column name */
  CreationYear = 'creation_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsGallerySold = 'is_gallery_sold',
  /** column name */
  Language = 'language',
  /** column name */
  Price = 'price',
  /** column name */
  PrintId = 'print_id',
  /** column name */
  SoldDate = 'sold_date',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Artwork_Transactions_Var_Pop_Fields = {
  __typename?: 'Artwork_transactions_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Transactions_Var_Samp_Fields = {
  __typename?: 'Artwork_transactions_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Transactions_Variance_Fields = {
  __typename?: 'Artwork_transactions_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork_transactions" */
export type Artwork_Transactions_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** update columns of table "Artwork" */
export enum Artwork_Update_Column {
  /** column name */
  AddArtworkFlowStep = 'add_artwork_flow_step',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkArtistIndex = 'artwork_artist_index',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropAreaId = 'crop_area_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  HasArAnimation = 'has_ar_animation',
  /** column name */
  HasVideo = 'has_video',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsHardMinted = 'is_hard_minted',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  IsTransactionLocked = 'is_transaction_locked',
  /** column name */
  LastUpdater = 'last_updater',
  /** column name */
  ListedAt = 'listed_at',
  /** column name */
  OriginalImageMetadataId = 'original_image_metadata_id',
  /** column name */
  PendingVuforiaImagePath = 'pending_vuforia_image_path',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VuforiaRating = 'vuforia_rating'
}

/** aggregate var_pop on columns */
export type Artwork_Var_Pop_Fields = {
  __typename?: 'Artwork_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  crop_area_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  original_image_metadata_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Artwork" */
export type Artwork_Var_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artwork_Var_Samp_Fields = {
  __typename?: 'Artwork_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  crop_area_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  original_image_metadata_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Artwork" */
export type Artwork_Var_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artwork_Variance_Fields = {
  __typename?: 'Artwork_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  crop_area_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  original_image_metadata_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Artwork" */
export type Artwork_Variance_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  crop_area_id?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  original_image_metadata_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

export type AudioStatementResult = {
  __typename?: 'AudioStatementResult';
  audioGenerationStatus: Scalars['String'];
  audioId?: Maybe<Scalars['Int']>;
  failReason?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** Keeps records of all the login methods (mail, MetaMask, Facebook, Instagram, etc.) for each user */
export type Auth = {
  __typename?: 'Auth';
  /** An object relationship */
  ENUM_auth_type: Enum_Auth_Type;
  /** An array relationship */
  auth_context_accesses: Array<Auth_Context_Access>;
  /** An aggregate relationship */
  auth_context_accesses_aggregate: Auth_Context_Access_Aggregate;
  auth_type: Enum_Auth_Type_Enum;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  firebase_profiles: Array<Firebase_Profile>;
  /** An aggregate relationship */
  firebase_profiles_aggregate: Firebase_Profile_Aggregate;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  last_name?: Maybe<Scalars['String']>;
  preferred_language: Enum_Language_Preference_Enum;
  updated_at: Scalars['timestamptz'];
};


/** Keeps records of all the login methods (mail, MetaMask, Facebook, Instagram, etc.) for each user */
export type AuthAuth_Context_AccessesArgs = {
  distinct_on?: Maybe<Array<Auth_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Context_Access_Order_By>>;
  where?: Maybe<Auth_Context_Access_Bool_Exp>;
};


/** Keeps records of all the login methods (mail, MetaMask, Facebook, Instagram, etc.) for each user */
export type AuthAuth_Context_Accesses_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Context_Access_Order_By>>;
  where?: Maybe<Auth_Context_Access_Bool_Exp>;
};


/** Keeps records of all the login methods (mail, MetaMask, Facebook, Instagram, etc.) for each user */
export type AuthFirebase_ProfilesArgs = {
  distinct_on?: Maybe<Array<Firebase_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Profile_Order_By>>;
  where?: Maybe<Firebase_Profile_Bool_Exp>;
};


/** Keeps records of all the login methods (mail, MetaMask, Facebook, Instagram, etc.) for each user */
export type AuthFirebase_Profiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Firebase_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Profile_Order_By>>;
  where?: Maybe<Firebase_Profile_Bool_Exp>;
};

/** aggregated selection of "Auth" */
export type Auth_Aggregate = {
  __typename?: 'Auth_aggregate';
  aggregate?: Maybe<Auth_Aggregate_Fields>;
  nodes: Array<Auth>;
};

/** aggregate fields of "Auth" */
export type Auth_Aggregate_Fields = {
  __typename?: 'Auth_aggregate_fields';
  avg?: Maybe<Auth_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Max_Fields>;
  min?: Maybe<Auth_Min_Fields>;
  stddev?: Maybe<Auth_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Sum_Fields>;
  var_pop?: Maybe<Auth_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Var_Samp_Fields>;
  variance?: Maybe<Auth_Variance_Fields>;
};


/** aggregate fields of "Auth" */
export type Auth_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Auth" */
export type Auth_Aggregate_Order_By = {
  avg?: Maybe<Auth_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Max_Order_By>;
  min?: Maybe<Auth_Min_Order_By>;
  stddev?: Maybe<Auth_Stddev_Order_By>;
  stddev_pop?: Maybe<Auth_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Auth_Stddev_Samp_Order_By>;
  sum?: Maybe<Auth_Sum_Order_By>;
  var_pop?: Maybe<Auth_Var_Pop_Order_By>;
  var_samp?: Maybe<Auth_Var_Samp_Order_By>;
  variance?: Maybe<Auth_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Auth" */
export type Auth_Arr_Rel_Insert_Input = {
  data: Array<Auth_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Auth_On_Conflict>;
};

/** aggregate avg on columns */
export type Auth_Avg_Fields = {
  __typename?: 'Auth_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Auth" */
export type Auth_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Auth". All fields are combined with a logical 'AND'. */
export type Auth_Bool_Exp = {
  ENUM_auth_type?: Maybe<Enum_Auth_Type_Bool_Exp>;
  _and?: Maybe<Array<Auth_Bool_Exp>>;
  _not?: Maybe<Auth_Bool_Exp>;
  _or?: Maybe<Array<Auth_Bool_Exp>>;
  auth_context_accesses?: Maybe<Auth_Context_Access_Bool_Exp>;
  auth_type?: Maybe<Enum_Auth_Type_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  firebase_profiles?: Maybe<Firebase_Profile_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Auth" */
export enum Auth_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuthPkey = 'Auth_pkey'
}

/** Associates a context with one or more login option from the Auth table */
export type Auth_Context_Access = {
  __typename?: 'Auth_context_access';
  /** An object relationship */
  auth: Auth;
  auth_id: Scalars['bigint'];
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  role: Scalars['String'];
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Auth_context_access" */
export type Auth_Context_Access_Aggregate = {
  __typename?: 'Auth_context_access_aggregate';
  aggregate?: Maybe<Auth_Context_Access_Aggregate_Fields>;
  nodes: Array<Auth_Context_Access>;
};

/** aggregate fields of "Auth_context_access" */
export type Auth_Context_Access_Aggregate_Fields = {
  __typename?: 'Auth_context_access_aggregate_fields';
  avg?: Maybe<Auth_Context_Access_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Context_Access_Max_Fields>;
  min?: Maybe<Auth_Context_Access_Min_Fields>;
  stddev?: Maybe<Auth_Context_Access_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Context_Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Context_Access_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Context_Access_Sum_Fields>;
  var_pop?: Maybe<Auth_Context_Access_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Context_Access_Var_Samp_Fields>;
  variance?: Maybe<Auth_Context_Access_Variance_Fields>;
};


/** aggregate fields of "Auth_context_access" */
export type Auth_Context_Access_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Context_Access_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Auth_context_access" */
export type Auth_Context_Access_Aggregate_Order_By = {
  avg?: Maybe<Auth_Context_Access_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Context_Access_Max_Order_By>;
  min?: Maybe<Auth_Context_Access_Min_Order_By>;
  stddev?: Maybe<Auth_Context_Access_Stddev_Order_By>;
  stddev_pop?: Maybe<Auth_Context_Access_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Auth_Context_Access_Stddev_Samp_Order_By>;
  sum?: Maybe<Auth_Context_Access_Sum_Order_By>;
  var_pop?: Maybe<Auth_Context_Access_Var_Pop_Order_By>;
  var_samp?: Maybe<Auth_Context_Access_Var_Samp_Order_By>;
  variance?: Maybe<Auth_Context_Access_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Auth_context_access" */
export type Auth_Context_Access_Arr_Rel_Insert_Input = {
  data: Array<Auth_Context_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Auth_Context_Access_On_Conflict>;
};

/** aggregate avg on columns */
export type Auth_Context_Access_Avg_Fields = {
  __typename?: 'Auth_context_access_avg_fields';
  auth_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Avg_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Auth_context_access". All fields are combined with a logical 'AND'. */
export type Auth_Context_Access_Bool_Exp = {
  _and?: Maybe<Array<Auth_Context_Access_Bool_Exp>>;
  _not?: Maybe<Auth_Context_Access_Bool_Exp>;
  _or?: Maybe<Array<Auth_Context_Access_Bool_Exp>>;
  auth?: Maybe<Auth_Bool_Exp>;
  auth_id?: Maybe<Bigint_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Auth_context_access" */
export enum Auth_Context_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  AuthContextAccessPkey = 'Auth_context_access_pkey'
}

/** input type for incrementing numeric columns in table "Auth_context_access" */
export type Auth_Context_Access_Inc_Input = {
  auth_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Auth_context_access" */
export type Auth_Context_Access_Insert_Input = {
  auth?: Maybe<Auth_Obj_Rel_Insert_Input>;
  auth_id?: Maybe<Scalars['bigint']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Context_Access_Max_Fields = {
  __typename?: 'Auth_context_access_max_fields';
  auth_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Max_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Context_Access_Min_Fields = {
  __typename?: 'Auth_context_access_min_fields';
  auth_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Min_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Auth_context_access" */
export type Auth_Context_Access_Mutation_Response = {
  __typename?: 'Auth_context_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Context_Access>;
};

/** on_conflict condition type for table "Auth_context_access" */
export type Auth_Context_Access_On_Conflict = {
  constraint: Auth_Context_Access_Constraint;
  update_columns?: Array<Auth_Context_Access_Update_Column>;
  where?: Maybe<Auth_Context_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "Auth_context_access". */
export type Auth_Context_Access_Order_By = {
  auth?: Maybe<Auth_Order_By>;
  auth_id?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Auth_context_access */
export type Auth_Context_Access_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Auth_context_access" */
export enum Auth_Context_Access_Select_Column {
  /** column name */
  AuthId = 'auth_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Auth_context_access" */
export type Auth_Context_Access_Set_Input = {
  auth_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Auth_Context_Access_Stddev_Fields = {
  __typename?: 'Auth_context_access_stddev_fields';
  auth_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Stddev_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auth_Context_Access_Stddev_Pop_Fields = {
  __typename?: 'Auth_context_access_stddev_pop_fields';
  auth_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Stddev_Pop_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auth_Context_Access_Stddev_Samp_Fields = {
  __typename?: 'Auth_context_access_stddev_samp_fields';
  auth_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Stddev_Samp_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Auth_Context_Access_Sum_Fields = {
  __typename?: 'Auth_context_access_sum_fields';
  auth_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Sum_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Auth_context_access" */
export enum Auth_Context_Access_Update_Column {
  /** column name */
  AuthId = 'auth_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Auth_Context_Access_Var_Pop_Fields = {
  __typename?: 'Auth_context_access_var_pop_fields';
  auth_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Var_Pop_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auth_Context_Access_Var_Samp_Fields = {
  __typename?: 'Auth_context_access_var_samp_fields';
  auth_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Var_Samp_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Auth_Context_Access_Variance_Fields = {
  __typename?: 'Auth_context_access_variance_fields';
  auth_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Auth_context_access" */
export type Auth_Context_Access_Variance_Order_By = {
  auth_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "Auth" */
export type Auth_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Auth" */
export type Auth_Insert_Input = {
  ENUM_auth_type?: Maybe<Enum_Auth_Type_Obj_Rel_Insert_Input>;
  auth_context_accesses?: Maybe<Auth_Context_Access_Arr_Rel_Insert_Input>;
  auth_type?: Maybe<Enum_Auth_Type_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  firebase_profiles?: Maybe<Firebase_Profile_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Max_Fields = {
  __typename?: 'Auth_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Auth" */
export type Auth_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Min_Fields = {
  __typename?: 'Auth_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Auth" */
export type Auth_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Auth" */
export type Auth_Mutation_Response = {
  __typename?: 'Auth_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth>;
};

/** input type for inserting object relation for remote table "Auth" */
export type Auth_Obj_Rel_Insert_Input = {
  data: Auth_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Auth_On_Conflict>;
};

/** on_conflict condition type for table "Auth" */
export type Auth_On_Conflict = {
  constraint: Auth_Constraint;
  update_columns?: Array<Auth_Update_Column>;
  where?: Maybe<Auth_Bool_Exp>;
};

/** Ordering options when selecting data from "Auth". */
export type Auth_Order_By = {
  ENUM_auth_type?: Maybe<Enum_Auth_Type_Order_By>;
  auth_context_accesses_aggregate?: Maybe<Auth_Context_Access_Aggregate_Order_By>;
  auth_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  firebase_profiles_aggregate?: Maybe<Firebase_Profile_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Auth */
export type Auth_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Auth" */
export enum Auth_Select_Column {
  /** column name */
  AuthType = 'auth_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Auth" */
export type Auth_Set_Input = {
  auth_type?: Maybe<Enum_Auth_Type_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Auth_Stddev_Fields = {
  __typename?: 'Auth_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Auth" */
export type Auth_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auth_Stddev_Pop_Fields = {
  __typename?: 'Auth_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Auth" */
export type Auth_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auth_Stddev_Samp_Fields = {
  __typename?: 'Auth_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Auth" */
export type Auth_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Auth_Sum_Fields = {
  __typename?: 'Auth_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Auth" */
export type Auth_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "Auth" */
export enum Auth_Update_Column {
  /** column name */
  AuthType = 'auth_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Auth_Var_Pop_Fields = {
  __typename?: 'Auth_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Auth" */
export type Auth_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auth_Var_Samp_Fields = {
  __typename?: 'Auth_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Auth" */
export type Auth_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Auth_Variance_Fields = {
  __typename?: 'Auth_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Auth" */
export type Auth_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** Empty table structure to work with get_min_price_available_print_id function */
export type Available_Print_Price = {
  __typename?: 'Available_print_price';
  artwork_id: Scalars['bigint'];
  edition_by_size_id: Scalars['bigint'];
  edition_by_type_id: Scalars['bigint'];
  edition_id: Scalars['bigint'];
  id: Scalars['Int'];
  price: Scalars['float8'];
  print_id: Scalars['bigint'];
};

/** aggregated selection of "Available_print_price" */
export type Available_Print_Price_Aggregate = {
  __typename?: 'Available_print_price_aggregate';
  aggregate?: Maybe<Available_Print_Price_Aggregate_Fields>;
  nodes: Array<Available_Print_Price>;
};

/** aggregate fields of "Available_print_price" */
export type Available_Print_Price_Aggregate_Fields = {
  __typename?: 'Available_print_price_aggregate_fields';
  avg?: Maybe<Available_Print_Price_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Available_Print_Price_Max_Fields>;
  min?: Maybe<Available_Print_Price_Min_Fields>;
  stddev?: Maybe<Available_Print_Price_Stddev_Fields>;
  stddev_pop?: Maybe<Available_Print_Price_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Available_Print_Price_Stddev_Samp_Fields>;
  sum?: Maybe<Available_Print_Price_Sum_Fields>;
  var_pop?: Maybe<Available_Print_Price_Var_Pop_Fields>;
  var_samp?: Maybe<Available_Print_Price_Var_Samp_Fields>;
  variance?: Maybe<Available_Print_Price_Variance_Fields>;
};


/** aggregate fields of "Available_print_price" */
export type Available_Print_Price_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Available_Print_Price_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Available_Print_Price_Avg_Fields = {
  __typename?: 'Available_print_price_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Available_print_price". All fields are combined with a logical 'AND'. */
export type Available_Print_Price_Bool_Exp = {
  _and?: Maybe<Array<Available_Print_Price_Bool_Exp>>;
  _not?: Maybe<Available_Print_Price_Bool_Exp>;
  _or?: Maybe<Array<Available_Print_Price_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  edition_by_size_id?: Maybe<Bigint_Comparison_Exp>;
  edition_by_type_id?: Maybe<Bigint_Comparison_Exp>;
  edition_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  print_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Available_print_price" */
export enum Available_Print_Price_Constraint {
  /** unique or primary key constraint on columns "id" */
  AvailablePrintPricePkey = 'Available_print_price_pkey'
}

/** input type for incrementing numeric columns in table "Available_print_price" */
export type Available_Print_Price_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Available_print_price" */
export type Available_Print_Price_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Available_Print_Price_Max_Fields = {
  __typename?: 'Available_print_price_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Available_Print_Price_Min_Fields = {
  __typename?: 'Available_print_price_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Available_print_price" */
export type Available_Print_Price_Mutation_Response = {
  __typename?: 'Available_print_price_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Available_Print_Price>;
};

/** on_conflict condition type for table "Available_print_price" */
export type Available_Print_Price_On_Conflict = {
  constraint: Available_Print_Price_Constraint;
  update_columns?: Array<Available_Print_Price_Update_Column>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};

/** Ordering options when selecting data from "Available_print_price". */
export type Available_Print_Price_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Available_print_price */
export type Available_Print_Price_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Available_print_price" */
export enum Available_Print_Price_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  EditionBySizeId = 'edition_by_size_id',
  /** column name */
  EditionByTypeId = 'edition_by_type_id',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  PrintId = 'print_id'
}

/** input type for updating data in table "Available_print_price" */
export type Available_Print_Price_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Available_Print_Price_Stddev_Fields = {
  __typename?: 'Available_print_price_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Available_Print_Price_Stddev_Pop_Fields = {
  __typename?: 'Available_print_price_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Available_Print_Price_Stddev_Samp_Fields = {
  __typename?: 'Available_print_price_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Available_Print_Price_Sum_Fields = {
  __typename?: 'Available_print_price_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Available_print_price" */
export enum Available_Print_Price_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  EditionBySizeId = 'edition_by_size_id',
  /** column name */
  EditionByTypeId = 'edition_by_type_id',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  PrintId = 'print_id'
}

/** aggregate var_pop on columns */
export type Available_Print_Price_Var_Pop_Fields = {
  __typename?: 'Available_print_price_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Available_Print_Price_Var_Samp_Fields = {
  __typename?: 'Available_print_price_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Available_Print_Price_Variance_Fields = {
  __typename?: 'Available_print_price_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Available_print_price_view" */
export type Available_Print_Price_View = {
  __typename?: 'Available_print_price_view';
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "Available_print_price_view" */
export type Available_Print_Price_View_Aggregate = {
  __typename?: 'Available_print_price_view_aggregate';
  aggregate?: Maybe<Available_Print_Price_View_Aggregate_Fields>;
  nodes: Array<Available_Print_Price_View>;
};

/** aggregate fields of "Available_print_price_view" */
export type Available_Print_Price_View_Aggregate_Fields = {
  __typename?: 'Available_print_price_view_aggregate_fields';
  avg?: Maybe<Available_Print_Price_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Available_Print_Price_View_Max_Fields>;
  min?: Maybe<Available_Print_Price_View_Min_Fields>;
  stddev?: Maybe<Available_Print_Price_View_Stddev_Fields>;
  stddev_pop?: Maybe<Available_Print_Price_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Available_Print_Price_View_Stddev_Samp_Fields>;
  sum?: Maybe<Available_Print_Price_View_Sum_Fields>;
  var_pop?: Maybe<Available_Print_Price_View_Var_Pop_Fields>;
  var_samp?: Maybe<Available_Print_Price_View_Var_Samp_Fields>;
  variance?: Maybe<Available_Print_Price_View_Variance_Fields>;
};


/** aggregate fields of "Available_print_price_view" */
export type Available_Print_Price_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Available_Print_Price_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Available_Print_Price_View_Avg_Fields = {
  __typename?: 'Available_print_price_view_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Available_print_price_view". All fields are combined with a logical 'AND'. */
export type Available_Print_Price_View_Bool_Exp = {
  _and?: Maybe<Array<Available_Print_Price_View_Bool_Exp>>;
  _not?: Maybe<Available_Print_Price_View_Bool_Exp>;
  _or?: Maybe<Array<Available_Print_Price_View_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  edition_by_size_id?: Maybe<Bigint_Comparison_Exp>;
  edition_by_type_id?: Maybe<Bigint_Comparison_Exp>;
  edition_id?: Maybe<Bigint_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  print_id?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Available_Print_Price_View_Max_Fields = {
  __typename?: 'Available_print_price_view_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Available_Print_Price_View_Min_Fields = {
  __typename?: 'Available_print_price_view_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "Available_print_price_view". */
export type Available_Print_Price_View_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** select columns of table "Available_print_price_view" */
export enum Available_Print_Price_View_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  EditionBySizeId = 'edition_by_size_id',
  /** column name */
  EditionByTypeId = 'edition_by_type_id',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  Price = 'price',
  /** column name */
  PrintId = 'print_id'
}

/** aggregate stddev on columns */
export type Available_Print_Price_View_Stddev_Fields = {
  __typename?: 'Available_print_price_view_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Available_Print_Price_View_Stddev_Pop_Fields = {
  __typename?: 'Available_print_price_view_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Available_Print_Price_View_Stddev_Samp_Fields = {
  __typename?: 'Available_print_price_view_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Available_Print_Price_View_Sum_Fields = {
  __typename?: 'Available_print_price_view_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Available_Print_Price_View_Var_Pop_Fields = {
  __typename?: 'Available_print_price_view_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Available_Print_Price_View_Var_Samp_Fields = {
  __typename?: 'Available_print_price_view_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Available_Print_Price_View_Variance_Fields = {
  __typename?: 'Available_print_price_view_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** Stores billing information for anonymous users */
export type Billing_Anon_Client = {
  __typename?: 'Billing_anon_client';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  stripe_customer_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Billing_anon_client" */
export type Billing_Anon_Client_Aggregate = {
  __typename?: 'Billing_anon_client_aggregate';
  aggregate?: Maybe<Billing_Anon_Client_Aggregate_Fields>;
  nodes: Array<Billing_Anon_Client>;
};

/** aggregate fields of "Billing_anon_client" */
export type Billing_Anon_Client_Aggregate_Fields = {
  __typename?: 'Billing_anon_client_aggregate_fields';
  avg?: Maybe<Billing_Anon_Client_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_Anon_Client_Max_Fields>;
  min?: Maybe<Billing_Anon_Client_Min_Fields>;
  stddev?: Maybe<Billing_Anon_Client_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Anon_Client_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Anon_Client_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Anon_Client_Sum_Fields>;
  var_pop?: Maybe<Billing_Anon_Client_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Anon_Client_Var_Samp_Fields>;
  variance?: Maybe<Billing_Anon_Client_Variance_Fields>;
};


/** aggregate fields of "Billing_anon_client" */
export type Billing_Anon_Client_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Anon_Client_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Billing_Anon_Client_Avg_Fields = {
  __typename?: 'Billing_anon_client_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Billing_anon_client". All fields are combined with a logical 'AND'. */
export type Billing_Anon_Client_Bool_Exp = {
  _and?: Maybe<Array<Billing_Anon_Client_Bool_Exp>>;
  _not?: Maybe<Billing_Anon_Client_Bool_Exp>;
  _or?: Maybe<Array<Billing_Anon_Client_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Billing_anon_client" */
export enum Billing_Anon_Client_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingAnonClientPkey = 'Billing_anon_client_pkey',
  /** unique or primary key constraint on columns "stripe_customer_id" */
  BillingAnonClientStripeCustomerIdKey = 'Billing_anon_client_stripe_customer_id_key'
}

/** input type for incrementing numeric columns in table "Billing_anon_client" */
export type Billing_Anon_Client_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Billing_anon_client" */
export type Billing_Anon_Client_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Billing_Anon_Client_Max_Fields = {
  __typename?: 'Billing_anon_client_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Billing_Anon_Client_Min_Fields = {
  __typename?: 'Billing_anon_client_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Billing_anon_client" */
export type Billing_Anon_Client_Mutation_Response = {
  __typename?: 'Billing_anon_client_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Anon_Client>;
};

/** input type for inserting object relation for remote table "Billing_anon_client" */
export type Billing_Anon_Client_Obj_Rel_Insert_Input = {
  data: Billing_Anon_Client_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Anon_Client_On_Conflict>;
};

/** on_conflict condition type for table "Billing_anon_client" */
export type Billing_Anon_Client_On_Conflict = {
  constraint: Billing_Anon_Client_Constraint;
  update_columns?: Array<Billing_Anon_Client_Update_Column>;
  where?: Maybe<Billing_Anon_Client_Bool_Exp>;
};

/** Ordering options when selecting data from "Billing_anon_client". */
export type Billing_Anon_Client_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Billing_anon_client */
export type Billing_Anon_Client_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Billing_anon_client" */
export enum Billing_Anon_Client_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Billing_anon_client" */
export type Billing_Anon_Client_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Billing_Anon_Client_Stddev_Fields = {
  __typename?: 'Billing_anon_client_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Billing_Anon_Client_Stddev_Pop_Fields = {
  __typename?: 'Billing_anon_client_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Billing_Anon_Client_Stddev_Samp_Fields = {
  __typename?: 'Billing_anon_client_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Billing_Anon_Client_Sum_Fields = {
  __typename?: 'Billing_anon_client_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Billing_anon_client" */
export enum Billing_Anon_Client_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Billing_Anon_Client_Var_Pop_Fields = {
  __typename?: 'Billing_anon_client_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Billing_Anon_Client_Var_Samp_Fields = {
  __typename?: 'Billing_anon_client_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Billing_Anon_Client_Variance_Fields = {
  __typename?: 'Billing_anon_client_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Holds payment intent information for artwork purchases */
export type Billing_Artwork_Payment_Intent = {
  __typename?: 'Billing_artwork_payment_intent';
  /** An object relationship */
  ENUM_transaction_origin?: Maybe<Enum_Transaction_Origin>;
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units: Scalars['numeric'];
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units: Scalars['numeric'];
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units: Scalars['numeric'];
  /** An object relationship */
  anon_buyer?: Maybe<Billing_Anon_Client>;
  /** An object relationship */
  authorized_by_admin?: Maybe<Context>;
  authorized_by_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  buyer?: Maybe<Billing_Client>;
  buyer_anon_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  currency_code: Scalars['String'];
  id: Scalars['bigint'];
  origin?: Maybe<Enum_Transaction_Origin_Enum>;
  payment_authorized_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  products: Array<Billing_Artwork_Payment_Intent_Product>;
  /** An aggregate relationship */
  products_aggregate: Billing_Artwork_Payment_Intent_Product_Aggregate;
  /** An object relationship */
  seller_context: Context;
  seller_context_id: Scalars['bigint'];
  /** An array relationship */
  shipping_address: Array<Billing_Artwork_Payment_Intent_Shipping_Address>;
  /** An aggregate relationship */
  shipping_address_aggregate: Billing_Artwork_Payment_Intent_Shipping_Address_Aggregate;
  shipping_fee_estimate: Scalars['numeric'];
  status: Enum_Billing_Artwork_Payment_Intent_Status_Enum;
  /** An array relationship */
  status_history: Array<Billing_Artwork_Payment_Intent_Status_History>;
  /** An aggregate relationship */
  status_history_aggregate: Billing_Artwork_Payment_Intent_Status_History_Aggregate;
  stripe_checkout_session_id: Scalars['String'];
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** Holds payment intent information for artwork purchases */
export type Billing_Artwork_Payment_IntentProductsArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Product_Bool_Exp>;
};


/** Holds payment intent information for artwork purchases */
export type Billing_Artwork_Payment_IntentProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Product_Bool_Exp>;
};


/** Holds payment intent information for artwork purchases */
export type Billing_Artwork_Payment_IntentShipping_AddressArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>;
};


/** Holds payment intent information for artwork purchases */
export type Billing_Artwork_Payment_IntentShipping_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>;
};


/** Holds payment intent information for artwork purchases */
export type Billing_Artwork_Payment_IntentStatus_HistoryArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>;
};


/** Holds payment intent information for artwork purchases */
export type Billing_Artwork_Payment_IntentStatus_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>;
};

/** aggregated selection of "Billing_artwork_payment_intent" */
export type Billing_Artwork_Payment_Intent_Aggregate = {
  __typename?: 'Billing_artwork_payment_intent_aggregate';
  aggregate?: Maybe<Billing_Artwork_Payment_Intent_Aggregate_Fields>;
  nodes: Array<Billing_Artwork_Payment_Intent>;
};

/** aggregate fields of "Billing_artwork_payment_intent" */
export type Billing_Artwork_Payment_Intent_Aggregate_Fields = {
  __typename?: 'Billing_artwork_payment_intent_aggregate_fields';
  avg?: Maybe<Billing_Artwork_Payment_Intent_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_Artwork_Payment_Intent_Max_Fields>;
  min?: Maybe<Billing_Artwork_Payment_Intent_Min_Fields>;
  stddev?: Maybe<Billing_Artwork_Payment_Intent_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Artwork_Payment_Intent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Artwork_Payment_Intent_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Artwork_Payment_Intent_Sum_Fields>;
  var_pop?: Maybe<Billing_Artwork_Payment_Intent_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Artwork_Payment_Intent_Var_Samp_Fields>;
  variance?: Maybe<Billing_Artwork_Payment_Intent_Variance_Fields>;
};


/** aggregate fields of "Billing_artwork_payment_intent" */
export type Billing_Artwork_Payment_Intent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Artwork_Payment_Intent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Billing_Artwork_Payment_Intent_Avg_Fields = {
  __typename?: 'Billing_artwork_payment_intent_avg_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['Float']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['Float']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['Float']>;
  authorized_by_context_id?: Maybe<Scalars['Float']>;
  buyer_anon_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  shipping_fee_estimate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Billing_artwork_payment_intent". All fields are combined with a logical 'AND'. */
export type Billing_Artwork_Payment_Intent_Bool_Exp = {
  ENUM_transaction_origin?: Maybe<Enum_Transaction_Origin_Bool_Exp>;
  _and?: Maybe<Array<Billing_Artwork_Payment_Intent_Bool_Exp>>;
  _not?: Maybe<Billing_Artwork_Payment_Intent_Bool_Exp>;
  _or?: Maybe<Array<Billing_Artwork_Payment_Intent_Bool_Exp>>;
  amount_capturable_units?: Maybe<Numeric_Comparison_Exp>;
  amount_subtotal_units?: Maybe<Numeric_Comparison_Exp>;
  amount_total_units?: Maybe<Numeric_Comparison_Exp>;
  anon_buyer?: Maybe<Billing_Anon_Client_Bool_Exp>;
  authorized_by_admin?: Maybe<Context_Bool_Exp>;
  authorized_by_context_id?: Maybe<Bigint_Comparison_Exp>;
  buyer?: Maybe<Billing_Client_Bool_Exp>;
  buyer_anon_id?: Maybe<Bigint_Comparison_Exp>;
  buyer_context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  origin?: Maybe<Enum_Transaction_Origin_Enum_Comparison_Exp>;
  payment_authorized_at?: Maybe<Timestamptz_Comparison_Exp>;
  products?: Maybe<Billing_Artwork_Payment_Intent_Product_Bool_Exp>;
  seller_context?: Maybe<Context_Bool_Exp>;
  seller_context_id?: Maybe<Bigint_Comparison_Exp>;
  shipping_address?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>;
  shipping_fee_estimate?: Maybe<Numeric_Comparison_Exp>;
  status?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Enum_Comparison_Exp>;
  status_history?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>;
  stripe_checkout_session_id?: Maybe<String_Comparison_Exp>;
  stripe_payment_intent_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Billing_artwork_payment_intent" */
export enum Billing_Artwork_Payment_Intent_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingArtworkPaymentIntentPkey = 'Billing_artwork_payment_intent_pkey',
  /** unique or primary key constraint on columns "stripe_checkout_session_id" */
  BillingArtworkPaymentIntentStripeCheckoutSessionIdKey = 'Billing_artwork_payment_intent_stripe_checkout_session_id_key',
  /** unique or primary key constraint on columns "stripe_payment_intent_id" */
  BillingArtworkPaymentIntentStripePaymentIntentIdKey = 'Billing_artwork_payment_intent_stripe_payment_intent_id_key'
}

/** input type for incrementing numeric columns in table "Billing_artwork_payment_intent" */
export type Billing_Artwork_Payment_Intent_Inc_Input = {
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['numeric']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['numeric']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['numeric']>;
  authorized_by_context_id?: Maybe<Scalars['bigint']>;
  buyer_anon_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  shipping_fee_estimate?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Billing_artwork_payment_intent" */
export type Billing_Artwork_Payment_Intent_Insert_Input = {
  ENUM_transaction_origin?: Maybe<Enum_Transaction_Origin_Obj_Rel_Insert_Input>;
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['numeric']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['numeric']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['numeric']>;
  anon_buyer?: Maybe<Billing_Anon_Client_Obj_Rel_Insert_Input>;
  authorized_by_admin?: Maybe<Context_Obj_Rel_Insert_Input>;
  authorized_by_context_id?: Maybe<Scalars['bigint']>;
  buyer?: Maybe<Billing_Client_Obj_Rel_Insert_Input>;
  buyer_anon_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  origin?: Maybe<Enum_Transaction_Origin_Enum>;
  payment_authorized_at?: Maybe<Scalars['timestamptz']>;
  products?: Maybe<Billing_Artwork_Payment_Intent_Product_Arr_Rel_Insert_Input>;
  seller_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  shipping_address?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Arr_Rel_Insert_Input>;
  shipping_fee_estimate?: Maybe<Scalars['numeric']>;
  status?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Enum>;
  status_history?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Arr_Rel_Insert_Input>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Billing_Artwork_Payment_Intent_Max_Fields = {
  __typename?: 'Billing_artwork_payment_intent_max_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['numeric']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['numeric']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['numeric']>;
  authorized_by_context_id?: Maybe<Scalars['bigint']>;
  buyer_anon_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  payment_authorized_at?: Maybe<Scalars['timestamptz']>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  shipping_fee_estimate?: Maybe<Scalars['numeric']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Billing_Artwork_Payment_Intent_Min_Fields = {
  __typename?: 'Billing_artwork_payment_intent_min_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['numeric']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['numeric']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['numeric']>;
  authorized_by_context_id?: Maybe<Scalars['bigint']>;
  buyer_anon_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  payment_authorized_at?: Maybe<Scalars['timestamptz']>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  shipping_fee_estimate?: Maybe<Scalars['numeric']>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Billing_artwork_payment_intent" */
export type Billing_Artwork_Payment_Intent_Mutation_Response = {
  __typename?: 'Billing_artwork_payment_intent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Artwork_Payment_Intent>;
};

/** input type for inserting object relation for remote table "Billing_artwork_payment_intent" */
export type Billing_Artwork_Payment_Intent_Obj_Rel_Insert_Input = {
  data: Billing_Artwork_Payment_Intent_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_On_Conflict>;
};

/** on_conflict condition type for table "Billing_artwork_payment_intent" */
export type Billing_Artwork_Payment_Intent_On_Conflict = {
  constraint: Billing_Artwork_Payment_Intent_Constraint;
  update_columns?: Array<Billing_Artwork_Payment_Intent_Update_Column>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Bool_Exp>;
};

/** Ordering options when selecting data from "Billing_artwork_payment_intent". */
export type Billing_Artwork_Payment_Intent_Order_By = {
  ENUM_transaction_origin?: Maybe<Enum_Transaction_Origin_Order_By>;
  amount_capturable_units?: Maybe<Order_By>;
  amount_subtotal_units?: Maybe<Order_By>;
  amount_total_units?: Maybe<Order_By>;
  anon_buyer?: Maybe<Billing_Anon_Client_Order_By>;
  authorized_by_admin?: Maybe<Context_Order_By>;
  authorized_by_context_id?: Maybe<Order_By>;
  buyer?: Maybe<Billing_Client_Order_By>;
  buyer_anon_id?: Maybe<Order_By>;
  buyer_context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  origin?: Maybe<Order_By>;
  payment_authorized_at?: Maybe<Order_By>;
  products_aggregate?: Maybe<Billing_Artwork_Payment_Intent_Product_Aggregate_Order_By>;
  seller_context?: Maybe<Context_Order_By>;
  seller_context_id?: Maybe<Order_By>;
  shipping_address_aggregate?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Aggregate_Order_By>;
  shipping_fee_estimate?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_history_aggregate?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Aggregate_Order_By>;
  stripe_checkout_session_id?: Maybe<Order_By>;
  stripe_payment_intent_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Billing_artwork_payment_intent */
export type Billing_Artwork_Payment_Intent_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** Represents an artwork as a product being sold through Kaleido */
export type Billing_Artwork_Payment_Intent_Product = {
  __typename?: 'Billing_artwork_payment_intent_product';
  /** An object relationship */
  artwork: Artwork;
  artwork_id: Scalars['bigint'];
  artwork_price_amount_units: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  kaleido_fee_units: Scalars['numeric'];
  /** An object relationship */
  payment_intent: Billing_Artwork_Payment_Intent;
  payment_intent_id: Scalars['bigint'];
  rejection_reason?: Maybe<Scalars['String']>;
  status: Enum_Billing_Payment_Intent_Product_Status_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Aggregate = {
  __typename?: 'Billing_artwork_payment_intent_product_aggregate';
  aggregate?: Maybe<Billing_Artwork_Payment_Intent_Product_Aggregate_Fields>;
  nodes: Array<Billing_Artwork_Payment_Intent_Product>;
};

/** aggregate fields of "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Aggregate_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_aggregate_fields';
  avg?: Maybe<Billing_Artwork_Payment_Intent_Product_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_Artwork_Payment_Intent_Product_Max_Fields>;
  min?: Maybe<Billing_Artwork_Payment_Intent_Product_Min_Fields>;
  stddev?: Maybe<Billing_Artwork_Payment_Intent_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Artwork_Payment_Intent_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Artwork_Payment_Intent_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Artwork_Payment_Intent_Product_Sum_Fields>;
  var_pop?: Maybe<Billing_Artwork_Payment_Intent_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Artwork_Payment_Intent_Product_Var_Samp_Fields>;
  variance?: Maybe<Billing_Artwork_Payment_Intent_Product_Variance_Fields>;
};


/** aggregate fields of "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Aggregate_Order_By = {
  avg?: Maybe<Billing_Artwork_Payment_Intent_Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Billing_Artwork_Payment_Intent_Product_Max_Order_By>;
  min?: Maybe<Billing_Artwork_Payment_Intent_Product_Min_Order_By>;
  stddev?: Maybe<Billing_Artwork_Payment_Intent_Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Billing_Artwork_Payment_Intent_Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Billing_Artwork_Payment_Intent_Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Billing_Artwork_Payment_Intent_Product_Sum_Order_By>;
  var_pop?: Maybe<Billing_Artwork_Payment_Intent_Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Billing_Artwork_Payment_Intent_Product_Var_Samp_Order_By>;
  variance?: Maybe<Billing_Artwork_Payment_Intent_Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Arr_Rel_Insert_Input = {
  data: Array<Billing_Artwork_Payment_Intent_Product_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Billing_Artwork_Payment_Intent_Product_Avg_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_amount_units?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kaleido_fee_units?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Billing_artwork_payment_intent_product". All fields are combined with a logical 'AND'. */
export type Billing_Artwork_Payment_Intent_Product_Bool_Exp = {
  _and?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Bool_Exp>>;
  _not?: Maybe<Billing_Artwork_Payment_Intent_Product_Bool_Exp>;
  _or?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_price_amount_units?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  kaleido_fee_units?: Maybe<Numeric_Comparison_Exp>;
  payment_intent?: Maybe<Billing_Artwork_Payment_Intent_Bool_Exp>;
  payment_intent_id?: Maybe<Bigint_Comparison_Exp>;
  rejection_reason?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Billing_artwork_payment_intent_product" */
export enum Billing_Artwork_Payment_Intent_Product_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingArtworkPaymentIntentProductPkey = 'Billing_artwork_payment_intent_product_pkey'
}

/** input type for incrementing numeric columns in table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_amount_units?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  kaleido_fee_units?: Maybe<Scalars['numeric']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_amount_units?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  kaleido_fee_units?: Maybe<Scalars['numeric']>;
  payment_intent?: Maybe<Billing_Artwork_Payment_Intent_Obj_Rel_Insert_Input>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  rejection_reason?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Billing_Artwork_Payment_Intent_Product_Max_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_amount_units?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  kaleido_fee_units?: Maybe<Scalars['numeric']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  rejection_reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Billing_Artwork_Payment_Intent_Product_Min_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_amount_units?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  kaleido_fee_units?: Maybe<Scalars['numeric']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  rejection_reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Mutation_Response = {
  __typename?: 'Billing_artwork_payment_intent_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Artwork_Payment_Intent_Product>;
};

/** on_conflict condition type for table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_On_Conflict = {
  constraint: Billing_Artwork_Payment_Intent_Product_Constraint;
  update_columns?: Array<Billing_Artwork_Payment_Intent_Product_Update_Column>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "Billing_artwork_payment_intent_product". */
export type Billing_Artwork_Payment_Intent_Product_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent?: Maybe<Billing_Artwork_Payment_Intent_Order_By>;
  payment_intent_id?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Billing_artwork_payment_intent_product */
export type Billing_Artwork_Payment_Intent_Product_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Billing_artwork_payment_intent_product" */
export enum Billing_Artwork_Payment_Intent_Product_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkPriceAmountUnits = 'artwork_price_amount_units',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  KaleidoFeeUnits = 'kaleido_fee_units',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_amount_units?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  kaleido_fee_units?: Maybe<Scalars['numeric']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  rejection_reason?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Billing_Artwork_Payment_Intent_Product_Stddev_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_amount_units?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kaleido_fee_units?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Billing_Artwork_Payment_Intent_Product_Stddev_Pop_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_amount_units?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kaleido_fee_units?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Billing_Artwork_Payment_Intent_Product_Stddev_Samp_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_amount_units?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kaleido_fee_units?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Billing_Artwork_Payment_Intent_Product_Sum_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_price_amount_units?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  kaleido_fee_units?: Maybe<Scalars['numeric']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** update columns of table "Billing_artwork_payment_intent_product" */
export enum Billing_Artwork_Payment_Intent_Product_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkPriceAmountUnits = 'artwork_price_amount_units',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  KaleidoFeeUnits = 'kaleido_fee_units',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Billing_Artwork_Payment_Intent_Product_Var_Pop_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_amount_units?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kaleido_fee_units?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Billing_Artwork_Payment_Intent_Product_Var_Samp_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_amount_units?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kaleido_fee_units?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Billing_Artwork_Payment_Intent_Product_Variance_Fields = {
  __typename?: 'Billing_artwork_payment_intent_product_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_price_amount_units?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kaleido_fee_units?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Billing_artwork_payment_intent_product" */
export type Billing_Artwork_Payment_Intent_Product_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_price_amount_units?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kaleido_fee_units?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** select columns of table "Billing_artwork_payment_intent" */
export enum Billing_Artwork_Payment_Intent_Select_Column {
  /** column name */
  AmountCapturableUnits = 'amount_capturable_units',
  /** column name */
  AmountSubtotalUnits = 'amount_subtotal_units',
  /** column name */
  AmountTotalUnits = 'amount_total_units',
  /** column name */
  AuthorizedByContextId = 'authorized_by_context_id',
  /** column name */
  BuyerAnonId = 'buyer_anon_id',
  /** column name */
  BuyerContextId = 'buyer_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Id = 'id',
  /** column name */
  Origin = 'origin',
  /** column name */
  PaymentAuthorizedAt = 'payment_authorized_at',
  /** column name */
  SellerContextId = 'seller_context_id',
  /** column name */
  ShippingFeeEstimate = 'shipping_fee_estimate',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutSessionId = 'stripe_checkout_session_id',
  /** column name */
  StripePaymentIntentId = 'stripe_payment_intent_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Billing_artwork_payment_intent" */
export type Billing_Artwork_Payment_Intent_Set_Input = {
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['numeric']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['numeric']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['numeric']>;
  authorized_by_context_id?: Maybe<Scalars['bigint']>;
  buyer_anon_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  origin?: Maybe<Enum_Transaction_Origin_Enum>;
  payment_authorized_at?: Maybe<Scalars['timestamptz']>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  shipping_fee_estimate?: Maybe<Scalars['numeric']>;
  status?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Enum>;
  stripe_checkout_session_id?: Maybe<Scalars['String']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Stores the shipping address information for an artwork purchase intent */
export type Billing_Artwork_Payment_Intent_Shipping_Address = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address';
  city: Scalars['String'];
  country: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  line1: Scalars['String'];
  line2: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  payment_intent: Billing_Artwork_Payment_Intent;
  payment_intent_id: Scalars['bigint'];
  postal_code: Scalars['String'];
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Aggregate = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_aggregate';
  aggregate?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Aggregate_Fields>;
  nodes: Array<Billing_Artwork_Payment_Intent_Shipping_Address>;
};

/** aggregate fields of "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Aggregate_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_aggregate_fields';
  avg?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Max_Fields>;
  min?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Min_Fields>;
  stddev?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Sum_Fields>;
  var_pop?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Var_Samp_Fields>;
  variance?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Variance_Fields>;
};


/** aggregate fields of "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Aggregate_Order_By = {
  avg?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Max_Order_By>;
  min?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Min_Order_By>;
  stddev?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Order_By>;
  stddev_pop?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Samp_Order_By>;
  sum?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Sum_Order_By>;
  var_pop?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Var_Pop_Order_By>;
  var_samp?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Var_Samp_Order_By>;
  variance?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Arr_Rel_Insert_Input = {
  data: Array<Billing_Artwork_Payment_Intent_Shipping_Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Avg_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_avg_fields';
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Avg_Order_By = {
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Billing_artwork_payment_intent_shipping_address". All fields are combined with a logical 'AND'. */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp = {
  _and?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>>;
  _not?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>;
  _or?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  line1?: Maybe<String_Comparison_Exp>;
  line2?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payment_intent?: Maybe<Billing_Artwork_Payment_Intent_Bool_Exp>;
  payment_intent_id?: Maybe<Bigint_Comparison_Exp>;
  postal_code?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Billing_artwork_payment_intent_shipping_address" */
export enum Billing_Artwork_Payment_Intent_Shipping_Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingArtworkPaymentIntentShippingAddressPkey = 'Billing_artwork_payment_intent_shipping_address_pkey'
}

/** input type for incrementing numeric columns in table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Insert_Input = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payment_intent?: Maybe<Billing_Artwork_Payment_Intent_Obj_Rel_Insert_Input>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Max_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Max_Order_By = {
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line1?: Maybe<Order_By>;
  line2?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Min_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Min_Order_By = {
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line1?: Maybe<Order_By>;
  line2?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Mutation_Response = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Artwork_Payment_Intent_Shipping_Address>;
};

/** on_conflict condition type for table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_On_Conflict = {
  constraint: Billing_Artwork_Payment_Intent_Shipping_Address_Constraint;
  update_columns?: Array<Billing_Artwork_Payment_Intent_Shipping_Address_Update_Column>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "Billing_artwork_payment_intent_shipping_address". */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Order_By = {
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  line1?: Maybe<Order_By>;
  line2?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_intent?: Maybe<Billing_Artwork_Payment_Intent_Order_By>;
  payment_intent_id?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Billing_artwork_payment_intent_shipping_address */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Billing_artwork_payment_intent_shipping_address" */
export enum Billing_Artwork_Payment_Intent_Shipping_Address_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Line1 = 'line1',
  /** column name */
  Line2 = 'line2',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Set_Input = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Pop_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Samp_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Sum_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Sum_Order_By = {
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** update columns of table "Billing_artwork_payment_intent_shipping_address" */
export enum Billing_Artwork_Payment_Intent_Shipping_Address_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Line1 = 'line1',
  /** column name */
  Line2 = 'line2',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Var_Pop_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Var_Samp_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Variance_Fields = {
  __typename?: 'Billing_artwork_payment_intent_shipping_address_variance_fields';
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Billing_artwork_payment_intent_shipping_address" */
export type Billing_Artwork_Payment_Intent_Shipping_Address_Variance_Order_By = {
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** Holds history about status changes of payment intents, and notes from the admins managing the transaction */
export type Billing_Artwork_Payment_Intent_Status_History = {
  __typename?: 'Billing_artwork_payment_intent_status_history';
  /** An object relationship */
  admin_context?: Maybe<Context>;
  admin_context_id?: Maybe<Scalars['bigint']>;
  admin_notes?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  payment_intent_id: Scalars['bigint'];
  source: Scalars['String'];
  status_change: Enum_Billing_Artwork_Payment_Intent_Status_Enum;
};

/** aggregated selection of "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Aggregate = {
  __typename?: 'Billing_artwork_payment_intent_status_history_aggregate';
  aggregate?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Aggregate_Fields>;
  nodes: Array<Billing_Artwork_Payment_Intent_Status_History>;
};

/** aggregate fields of "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Aggregate_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_aggregate_fields';
  avg?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Max_Fields>;
  min?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Min_Fields>;
  stddev?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Sum_Fields>;
  var_pop?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Var_Samp_Fields>;
  variance?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Variance_Fields>;
};


/** aggregate fields of "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Aggregate_Order_By = {
  avg?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Max_Order_By>;
  min?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Min_Order_By>;
  stddev?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Stddev_Order_By>;
  stddev_pop?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Stddev_Samp_Order_By>;
  sum?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Sum_Order_By>;
  var_pop?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Var_Pop_Order_By>;
  var_samp?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Var_Samp_Order_By>;
  variance?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Arr_Rel_Insert_Input = {
  data: Array<Billing_Artwork_Payment_Intent_Status_History_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_Status_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Avg_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_avg_fields';
  admin_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Avg_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Billing_artwork_payment_intent_status_history". All fields are combined with a logical 'AND'. */
export type Billing_Artwork_Payment_Intent_Status_History_Bool_Exp = {
  _and?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>>;
  _not?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>;
  _or?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>>;
  admin_context?: Maybe<Context_Bool_Exp>;
  admin_context_id?: Maybe<Bigint_Comparison_Exp>;
  admin_notes?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  payment_intent_id?: Maybe<Bigint_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  status_change?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "Billing_artwork_payment_intent_status_history" */
export enum Billing_Artwork_Payment_Intent_Status_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingArtworkPaymentIntentStatusHistoryPkey = 'Billing_artwork_payment_intent_status_history_pkey'
}

/** input type for incrementing numeric columns in table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Inc_Input = {
  admin_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Insert_Input = {
  admin_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  admin_context_id?: Maybe<Scalars['bigint']>;
  admin_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Scalars['String']>;
  status_change?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Enum>;
};

/** aggregate max on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Max_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_max_fields';
  admin_context_id?: Maybe<Scalars['bigint']>;
  admin_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Max_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  admin_notes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Min_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_min_fields';
  admin_context_id?: Maybe<Scalars['bigint']>;
  admin_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Min_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  admin_notes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
};

/** response of any mutation on the table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Mutation_Response = {
  __typename?: 'Billing_artwork_payment_intent_status_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Artwork_Payment_Intent_Status_History>;
};

/** on_conflict condition type for table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_On_Conflict = {
  constraint: Billing_Artwork_Payment_Intent_Status_History_Constraint;
  update_columns?: Array<Billing_Artwork_Payment_Intent_Status_History_Update_Column>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>;
};

/** Ordering options when selecting data from "Billing_artwork_payment_intent_status_history". */
export type Billing_Artwork_Payment_Intent_Status_History_Order_By = {
  admin_context?: Maybe<Context_Order_By>;
  admin_context_id?: Maybe<Order_By>;
  admin_notes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  status_change?: Maybe<Order_By>;
};

/** primary key columns input for table: Billing_artwork_payment_intent_status_history */
export type Billing_Artwork_Payment_Intent_Status_History_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Billing_artwork_payment_intent_status_history" */
export enum Billing_Artwork_Payment_Intent_Status_History_Select_Column {
  /** column name */
  AdminContextId = 'admin_context_id',
  /** column name */
  AdminNotes = 'admin_notes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  Source = 'source',
  /** column name */
  StatusChange = 'status_change'
}

/** input type for updating data in table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Set_Input = {
  admin_context_id?: Maybe<Scalars['bigint']>;
  admin_notes?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Scalars['String']>;
  status_change?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Enum>;
};

/** aggregate stddev on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Stddev_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_stddev_fields';
  admin_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Stddev_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Stddev_Pop_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_stddev_pop_fields';
  admin_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Stddev_Pop_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Stddev_Samp_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_stddev_samp_fields';
  admin_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Stddev_Samp_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Sum_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_sum_fields';
  admin_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  payment_intent_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Sum_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** update columns of table "Billing_artwork_payment_intent_status_history" */
export enum Billing_Artwork_Payment_Intent_Status_History_Update_Column {
  /** column name */
  AdminContextId = 'admin_context_id',
  /** column name */
  AdminNotes = 'admin_notes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentIntentId = 'payment_intent_id',
  /** column name */
  Source = 'source',
  /** column name */
  StatusChange = 'status_change'
}

/** aggregate var_pop on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Var_Pop_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_var_pop_fields';
  admin_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Var_Pop_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Var_Samp_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_var_samp_fields';
  admin_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Var_Samp_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Billing_Artwork_Payment_Intent_Status_History_Variance_Fields = {
  __typename?: 'Billing_artwork_payment_intent_status_history_variance_fields';
  admin_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_intent_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Billing_artwork_payment_intent_status_history" */
export type Billing_Artwork_Payment_Intent_Status_History_Variance_Order_By = {
  admin_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_intent_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Billing_Artwork_Payment_Intent_Stddev_Fields = {
  __typename?: 'Billing_artwork_payment_intent_stddev_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['Float']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['Float']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['Float']>;
  authorized_by_context_id?: Maybe<Scalars['Float']>;
  buyer_anon_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  shipping_fee_estimate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Billing_Artwork_Payment_Intent_Stddev_Pop_Fields = {
  __typename?: 'Billing_artwork_payment_intent_stddev_pop_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['Float']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['Float']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['Float']>;
  authorized_by_context_id?: Maybe<Scalars['Float']>;
  buyer_anon_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  shipping_fee_estimate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Billing_Artwork_Payment_Intent_Stddev_Samp_Fields = {
  __typename?: 'Billing_artwork_payment_intent_stddev_samp_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['Float']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['Float']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['Float']>;
  authorized_by_context_id?: Maybe<Scalars['Float']>;
  buyer_anon_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  shipping_fee_estimate?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Billing_Artwork_Payment_Intent_Sum_Fields = {
  __typename?: 'Billing_artwork_payment_intent_sum_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['numeric']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['numeric']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['numeric']>;
  authorized_by_context_id?: Maybe<Scalars['bigint']>;
  buyer_anon_id?: Maybe<Scalars['bigint']>;
  buyer_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  seller_context_id?: Maybe<Scalars['bigint']>;
  shipping_fee_estimate?: Maybe<Scalars['numeric']>;
};

/** update columns of table "Billing_artwork_payment_intent" */
export enum Billing_Artwork_Payment_Intent_Update_Column {
  /** column name */
  AmountCapturableUnits = 'amount_capturable_units',
  /** column name */
  AmountSubtotalUnits = 'amount_subtotal_units',
  /** column name */
  AmountTotalUnits = 'amount_total_units',
  /** column name */
  AuthorizedByContextId = 'authorized_by_context_id',
  /** column name */
  BuyerAnonId = 'buyer_anon_id',
  /** column name */
  BuyerContextId = 'buyer_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Id = 'id',
  /** column name */
  Origin = 'origin',
  /** column name */
  PaymentAuthorizedAt = 'payment_authorized_at',
  /** column name */
  SellerContextId = 'seller_context_id',
  /** column name */
  ShippingFeeEstimate = 'shipping_fee_estimate',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCheckoutSessionId = 'stripe_checkout_session_id',
  /** column name */
  StripePaymentIntentId = 'stripe_payment_intent_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Billing_Artwork_Payment_Intent_Var_Pop_Fields = {
  __typename?: 'Billing_artwork_payment_intent_var_pop_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['Float']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['Float']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['Float']>;
  authorized_by_context_id?: Maybe<Scalars['Float']>;
  buyer_anon_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  shipping_fee_estimate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Billing_Artwork_Payment_Intent_Var_Samp_Fields = {
  __typename?: 'Billing_artwork_payment_intent_var_samp_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['Float']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['Float']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['Float']>;
  authorized_by_context_id?: Maybe<Scalars['Float']>;
  buyer_anon_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  shipping_fee_estimate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Billing_Artwork_Payment_Intent_Variance_Fields = {
  __typename?: 'Billing_artwork_payment_intent_variance_fields';
  /** Amount (in cents) authorized to be captured once the order is confirmed */
  amount_capturable_units?: Maybe<Scalars['Float']>;
  /** Amount (in cents) excluding taxes and shipping */
  amount_subtotal_units?: Maybe<Scalars['Float']>;
  /** Total amount (in cents) including taxes, shipping and discounts */
  amount_total_units?: Maybe<Scalars['Float']>;
  authorized_by_context_id?: Maybe<Scalars['Float']>;
  buyer_anon_id?: Maybe<Scalars['Float']>;
  buyer_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  seller_context_id?: Maybe<Scalars['Float']>;
  shipping_fee_estimate?: Maybe<Scalars['Float']>;
};

/** Stores billing information related to a user profile (context) */
export type Billing_Client = {
  __typename?: 'Billing_client';
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  stripe_customer_id: Scalars['String'];
  /** An array relationship */
  subscription_products: Array<Billing_Subscription_Product>;
  /** An aggregate relationship */
  subscription_products_aggregate: Billing_Subscription_Product_Aggregate;
  /** An array relationship */
  subscriptions: Array<Billing_Subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: Billing_Subscription_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** Stores billing information related to a user profile (context) */
export type Billing_ClientSubscription_ProductsArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Product_Order_By>>;
  where?: Maybe<Billing_Subscription_Product_Bool_Exp>;
};


/** Stores billing information related to a user profile (context) */
export type Billing_ClientSubscription_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Product_Order_By>>;
  where?: Maybe<Billing_Subscription_Product_Bool_Exp>;
};


/** Stores billing information related to a user profile (context) */
export type Billing_ClientSubscriptionsArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Order_By>>;
  where?: Maybe<Billing_Subscription_Bool_Exp>;
};


/** Stores billing information related to a user profile (context) */
export type Billing_ClientSubscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Order_By>>;
  where?: Maybe<Billing_Subscription_Bool_Exp>;
};

/** aggregated selection of "Billing_client" */
export type Billing_Client_Aggregate = {
  __typename?: 'Billing_client_aggregate';
  aggregate?: Maybe<Billing_Client_Aggregate_Fields>;
  nodes: Array<Billing_Client>;
};

/** aggregate fields of "Billing_client" */
export type Billing_Client_Aggregate_Fields = {
  __typename?: 'Billing_client_aggregate_fields';
  avg?: Maybe<Billing_Client_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_Client_Max_Fields>;
  min?: Maybe<Billing_Client_Min_Fields>;
  stddev?: Maybe<Billing_Client_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Client_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Client_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Client_Sum_Fields>;
  var_pop?: Maybe<Billing_Client_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Client_Var_Samp_Fields>;
  variance?: Maybe<Billing_Client_Variance_Fields>;
};


/** aggregate fields of "Billing_client" */
export type Billing_Client_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Client_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Billing_Client_Avg_Fields = {
  __typename?: 'Billing_client_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Billing_client". All fields are combined with a logical 'AND'. */
export type Billing_Client_Bool_Exp = {
  _and?: Maybe<Array<Billing_Client_Bool_Exp>>;
  _not?: Maybe<Billing_Client_Bool_Exp>;
  _or?: Maybe<Array<Billing_Client_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
  subscription_products?: Maybe<Billing_Subscription_Product_Bool_Exp>;
  subscriptions?: Maybe<Billing_Subscription_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Billing_client" */
export enum Billing_Client_Constraint {
  /** unique or primary key constraint on columns "context_id" */
  BillingClientPkey = 'Billing_client_pkey',
  /** unique or primary key constraint on columns "stripe_customer_id" */
  BillingClientStripeCustomerIdKey = 'Billing_client_stripe_customer_id_key'
}

/** input type for incrementing numeric columns in table "Billing_client" */
export type Billing_Client_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Billing_client" */
export type Billing_Client_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  subscription_products?: Maybe<Billing_Subscription_Product_Arr_Rel_Insert_Input>;
  subscriptions?: Maybe<Billing_Subscription_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Billing_Client_Max_Fields = {
  __typename?: 'Billing_client_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Billing_Client_Min_Fields = {
  __typename?: 'Billing_client_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Billing_client" */
export type Billing_Client_Mutation_Response = {
  __typename?: 'Billing_client_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Client>;
};

/** input type for inserting object relation for remote table "Billing_client" */
export type Billing_Client_Obj_Rel_Insert_Input = {
  data: Billing_Client_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Client_On_Conflict>;
};

/** on_conflict condition type for table "Billing_client" */
export type Billing_Client_On_Conflict = {
  constraint: Billing_Client_Constraint;
  update_columns?: Array<Billing_Client_Update_Column>;
  where?: Maybe<Billing_Client_Bool_Exp>;
};

/** Ordering options when selecting data from "Billing_client". */
export type Billing_Client_Order_By = {
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  subscription_products_aggregate?: Maybe<Billing_Subscription_Product_Aggregate_Order_By>;
  subscriptions_aggregate?: Maybe<Billing_Subscription_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Billing_client */
export type Billing_Client_Pk_Columns_Input = {
  context_id: Scalars['bigint'];
};

/** select columns of table "Billing_client" */
export enum Billing_Client_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Billing_client" */
export type Billing_Client_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Billing_Client_Stddev_Fields = {
  __typename?: 'Billing_client_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Billing_Client_Stddev_Pop_Fields = {
  __typename?: 'Billing_client_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Billing_Client_Stddev_Samp_Fields = {
  __typename?: 'Billing_client_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Billing_Client_Sum_Fields = {
  __typename?: 'Billing_client_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Billing_client" */
export enum Billing_Client_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Billing_Client_Var_Pop_Fields = {
  __typename?: 'Billing_client_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Billing_Client_Var_Samp_Fields = {
  __typename?: 'Billing_client_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Billing_Client_Variance_Fields = {
  __typename?: 'Billing_client_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** Artists selling their artwork, as well as resellers */
export type Billing_Seller = {
  __typename?: 'Billing_seller';
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score: Scalars['Int'];
  status: Enum_Billing_Seller_Status_Enum;
  stripe_account_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Billing_seller" */
export type Billing_Seller_Aggregate = {
  __typename?: 'Billing_seller_aggregate';
  aggregate?: Maybe<Billing_Seller_Aggregate_Fields>;
  nodes: Array<Billing_Seller>;
};

/** aggregate fields of "Billing_seller" */
export type Billing_Seller_Aggregate_Fields = {
  __typename?: 'Billing_seller_aggregate_fields';
  avg?: Maybe<Billing_Seller_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_Seller_Max_Fields>;
  min?: Maybe<Billing_Seller_Min_Fields>;
  stddev?: Maybe<Billing_Seller_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Seller_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Seller_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Seller_Sum_Fields>;
  var_pop?: Maybe<Billing_Seller_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Seller_Var_Samp_Fields>;
  variance?: Maybe<Billing_Seller_Variance_Fields>;
};


/** aggregate fields of "Billing_seller" */
export type Billing_Seller_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Seller_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Billing_Seller_Avg_Fields = {
  __typename?: 'Billing_seller_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Billing_seller". All fields are combined with a logical 'AND'. */
export type Billing_Seller_Bool_Exp = {
  _and?: Maybe<Array<Billing_Seller_Bool_Exp>>;
  _not?: Maybe<Billing_Seller_Bool_Exp>;
  _or?: Maybe<Array<Billing_Seller_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Enum_Billing_Seller_Status_Enum_Comparison_Exp>;
  stripe_account_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Billing_seller" */
export enum Billing_Seller_Constraint {
  /** unique or primary key constraint on columns "context_id" */
  BillingSellerPkey = 'Billing_seller_pkey',
  /** unique or primary key constraint on columns "stripe_account_id" */
  BillingSellerStripeAccountIdKey = 'Billing_seller_stripe_account_id_key'
}

/** input type for incrementing numeric columns in table "Billing_seller" */
export type Billing_Seller_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Billing_seller" */
export type Billing_Seller_Insert_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Billing_Seller_Status_Enum>;
  stripe_account_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Billing_Seller_Max_Fields = {
  __typename?: 'Billing_seller_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Int']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Billing_Seller_Min_Fields = {
  __typename?: 'Billing_seller_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Int']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Billing_seller" */
export type Billing_Seller_Mutation_Response = {
  __typename?: 'Billing_seller_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Seller>;
};

/** input type for inserting object relation for remote table "Billing_seller" */
export type Billing_Seller_Obj_Rel_Insert_Input = {
  data: Billing_Seller_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Seller_On_Conflict>;
};

/** on_conflict condition type for table "Billing_seller" */
export type Billing_Seller_On_Conflict = {
  constraint: Billing_Seller_Constraint;
  update_columns?: Array<Billing_Seller_Update_Column>;
  where?: Maybe<Billing_Seller_Bool_Exp>;
};

/** Ordering options when selecting data from "Billing_seller". */
export type Billing_Seller_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stripe_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Billing_seller */
export type Billing_Seller_Pk_Columns_Input = {
  context_id: Scalars['bigint'];
};

/** select columns of table "Billing_seller" */
export enum Billing_Seller_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Score = 'score',
  /** column name */
  Status = 'status',
  /** column name */
  StripeAccountId = 'stripe_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Billing_seller" */
export type Billing_Seller_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Billing_Seller_Status_Enum>;
  stripe_account_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Billing_Seller_Stddev_Fields = {
  __typename?: 'Billing_seller_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Billing_Seller_Stddev_Pop_Fields = {
  __typename?: 'Billing_seller_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Billing_Seller_Stddev_Samp_Fields = {
  __typename?: 'Billing_seller_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Billing_Seller_Sum_Fields = {
  __typename?: 'Billing_seller_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Int']>;
};

/** update columns of table "Billing_seller" */
export enum Billing_Seller_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Score = 'score',
  /** column name */
  Status = 'status',
  /** column name */
  StripeAccountId = 'stripe_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Billing_Seller_Var_Pop_Fields = {
  __typename?: 'Billing_seller_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Billing_Seller_Var_Samp_Fields = {
  __typename?: 'Billing_seller_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Billing_Seller_Variance_Fields = {
  __typename?: 'Billing_seller_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  /** We're using the score to determine the reputation of the seller (negative means he had incidents) */
  score?: Maybe<Scalars['Float']>;
};

/** Stores records of billing subscription transactions and states */
export type Billing_Subscription = {
  __typename?: 'Billing_subscription';
  /** An object relationship */
  billing_client?: Maybe<Billing_Client>;
  billing_subscription_period: Enum_Billing_Subscription_Period_Enum;
  billing_subscription_status: Enum_Billing_Subscription_Status_Enum;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_decline_code?: Maybe<Scalars['String']>;
  last_failed_payment_error_code?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  stripe_subscription: StripeSubscription;
  stripe_subscription_id: Scalars['String'];
  /** An array relationship */
  subscription_products: Array<Billing_Subscription_Product>;
  /** An aggregate relationship */
  subscription_products_aggregate: Billing_Subscription_Product_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** Stores records of billing subscription transactions and states */
export type Billing_SubscriptionSubscription_ProductsArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Product_Order_By>>;
  where?: Maybe<Billing_Subscription_Product_Bool_Exp>;
};


/** Stores records of billing subscription transactions and states */
export type Billing_SubscriptionSubscription_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Product_Order_By>>;
  where?: Maybe<Billing_Subscription_Product_Bool_Exp>;
};

/** aggregated selection of "Billing_subscription" */
export type Billing_Subscription_Aggregate = {
  __typename?: 'Billing_subscription_aggregate';
  aggregate?: Maybe<Billing_Subscription_Aggregate_Fields>;
  nodes: Array<Billing_Subscription>;
};

/** aggregate fields of "Billing_subscription" */
export type Billing_Subscription_Aggregate_Fields = {
  __typename?: 'Billing_subscription_aggregate_fields';
  avg?: Maybe<Billing_Subscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_Subscription_Max_Fields>;
  min?: Maybe<Billing_Subscription_Min_Fields>;
  stddev?: Maybe<Billing_Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Subscription_Sum_Fields>;
  var_pop?: Maybe<Billing_Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Subscription_Var_Samp_Fields>;
  variance?: Maybe<Billing_Subscription_Variance_Fields>;
};


/** aggregate fields of "Billing_subscription" */
export type Billing_Subscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Subscription_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Billing_subscription" */
export type Billing_Subscription_Aggregate_Order_By = {
  avg?: Maybe<Billing_Subscription_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Billing_Subscription_Max_Order_By>;
  min?: Maybe<Billing_Subscription_Min_Order_By>;
  stddev?: Maybe<Billing_Subscription_Stddev_Order_By>;
  stddev_pop?: Maybe<Billing_Subscription_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Billing_Subscription_Stddev_Samp_Order_By>;
  sum?: Maybe<Billing_Subscription_Sum_Order_By>;
  var_pop?: Maybe<Billing_Subscription_Var_Pop_Order_By>;
  var_samp?: Maybe<Billing_Subscription_Var_Samp_Order_By>;
  variance?: Maybe<Billing_Subscription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Billing_subscription" */
export type Billing_Subscription_Arr_Rel_Insert_Input = {
  data: Array<Billing_Subscription_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Subscription_On_Conflict>;
};

/** aggregate avg on columns */
export type Billing_Subscription_Avg_Fields = {
  __typename?: 'Billing_subscription_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Billing_subscription" */
export type Billing_Subscription_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Billing_subscription". All fields are combined with a logical 'AND'. */
export type Billing_Subscription_Bool_Exp = {
  _and?: Maybe<Array<Billing_Subscription_Bool_Exp>>;
  _not?: Maybe<Billing_Subscription_Bool_Exp>;
  _or?: Maybe<Array<Billing_Subscription_Bool_Exp>>;
  billing_client?: Maybe<Billing_Client_Bool_Exp>;
  billing_subscription_period?: Maybe<Enum_Billing_Subscription_Period_Enum_Comparison_Exp>;
  billing_subscription_status?: Maybe<Enum_Billing_Subscription_Status_Enum_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_billed_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_failed_payment_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_failed_payment_decline_code?: Maybe<String_Comparison_Exp>;
  last_failed_payment_error_code?: Maybe<String_Comparison_Exp>;
  next_billing_date?: Maybe<Timestamptz_Comparison_Exp>;
  stripe_subscription_id?: Maybe<String_Comparison_Exp>;
  subscription_products?: Maybe<Billing_Subscription_Product_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Billing_subscription" */
export enum Billing_Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingSubscriptionPkey = 'Billing_subscription_pkey',
  /** unique or primary key constraint on columns "stripe_subscription_id" */
  BillingSubscriptionStripeSubscriptionIdKey = 'Billing_subscription_stripe_subscription_id_key'
}

/** input type for incrementing numeric columns in table "Billing_subscription" */
export type Billing_Subscription_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Billing_subscription" */
export type Billing_Subscription_Insert_Input = {
  billing_client?: Maybe<Billing_Client_Obj_Rel_Insert_Input>;
  billing_subscription_period?: Maybe<Enum_Billing_Subscription_Period_Enum>;
  billing_subscription_status?: Maybe<Enum_Billing_Subscription_Status_Enum>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_decline_code?: Maybe<Scalars['String']>;
  last_failed_payment_error_code?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  subscription_products?: Maybe<Billing_Subscription_Product_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Billing_Subscription_Max_Fields = {
  __typename?: 'Billing_subscription_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_decline_code?: Maybe<Scalars['String']>;
  last_failed_payment_error_code?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Billing_subscription" */
export type Billing_Subscription_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_at?: Maybe<Order_By>;
  last_failed_payment_at?: Maybe<Order_By>;
  last_failed_payment_decline_code?: Maybe<Order_By>;
  last_failed_payment_error_code?: Maybe<Order_By>;
  next_billing_date?: Maybe<Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Billing_Subscription_Min_Fields = {
  __typename?: 'Billing_subscription_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_decline_code?: Maybe<Scalars['String']>;
  last_failed_payment_error_code?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Billing_subscription" */
export type Billing_Subscription_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_at?: Maybe<Order_By>;
  last_failed_payment_at?: Maybe<Order_By>;
  last_failed_payment_decline_code?: Maybe<Order_By>;
  last_failed_payment_error_code?: Maybe<Order_By>;
  next_billing_date?: Maybe<Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Billing_subscription" */
export type Billing_Subscription_Mutation_Response = {
  __typename?: 'Billing_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Subscription>;
};

/** input type for inserting object relation for remote table "Billing_subscription" */
export type Billing_Subscription_Obj_Rel_Insert_Input = {
  data: Billing_Subscription_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Subscription_On_Conflict>;
};

/** on_conflict condition type for table "Billing_subscription" */
export type Billing_Subscription_On_Conflict = {
  constraint: Billing_Subscription_Constraint;
  update_columns?: Array<Billing_Subscription_Update_Column>;
  where?: Maybe<Billing_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "Billing_subscription". */
export type Billing_Subscription_Order_By = {
  billing_client?: Maybe<Billing_Client_Order_By>;
  billing_subscription_period?: Maybe<Order_By>;
  billing_subscription_status?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_at?: Maybe<Order_By>;
  last_failed_payment_at?: Maybe<Order_By>;
  last_failed_payment_decline_code?: Maybe<Order_By>;
  last_failed_payment_error_code?: Maybe<Order_By>;
  next_billing_date?: Maybe<Order_By>;
  stripe_subscription_id?: Maybe<Order_By>;
  subscription_products_aggregate?: Maybe<Billing_Subscription_Product_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Billing_subscription */
export type Billing_Subscription_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** Subscription products linked to a context */
export type Billing_Subscription_Product = {
  __typename?: 'Billing_subscription_product';
  /** An object relationship */
  billing_client?: Maybe<Billing_Client>;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  last_billed_subscription_id: Scalars['bigint'];
  /** An object relationship */
  last_billing_subscription?: Maybe<Billing_Subscription>;
  last_successful_invoice_date?: Maybe<Scalars['timestamptz']>;
  /** Last successfully paid (stripe) invoice that included a payment for this product */
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_subscription_product_id?: Maybe<Scalars['String']>;
  type: Enum_Billing_Subscription_Product_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Billing_subscription_product" */
export type Billing_Subscription_Product_Aggregate = {
  __typename?: 'Billing_subscription_product_aggregate';
  aggregate?: Maybe<Billing_Subscription_Product_Aggregate_Fields>;
  nodes: Array<Billing_Subscription_Product>;
};

/** aggregate fields of "Billing_subscription_product" */
export type Billing_Subscription_Product_Aggregate_Fields = {
  __typename?: 'Billing_subscription_product_aggregate_fields';
  avg?: Maybe<Billing_Subscription_Product_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_Subscription_Product_Max_Fields>;
  min?: Maybe<Billing_Subscription_Product_Min_Fields>;
  stddev?: Maybe<Billing_Subscription_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_Subscription_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_Subscription_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_Subscription_Product_Sum_Fields>;
  var_pop?: Maybe<Billing_Subscription_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_Subscription_Product_Var_Samp_Fields>;
  variance?: Maybe<Billing_Subscription_Product_Variance_Fields>;
};


/** aggregate fields of "Billing_subscription_product" */
export type Billing_Subscription_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Subscription_Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Aggregate_Order_By = {
  avg?: Maybe<Billing_Subscription_Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Billing_Subscription_Product_Max_Order_By>;
  min?: Maybe<Billing_Subscription_Product_Min_Order_By>;
  stddev?: Maybe<Billing_Subscription_Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Billing_Subscription_Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Billing_Subscription_Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Billing_Subscription_Product_Sum_Order_By>;
  var_pop?: Maybe<Billing_Subscription_Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Billing_Subscription_Product_Var_Samp_Order_By>;
  variance?: Maybe<Billing_Subscription_Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Billing_subscription_product" */
export type Billing_Subscription_Product_Arr_Rel_Insert_Input = {
  data: Array<Billing_Subscription_Product_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Billing_Subscription_Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Billing_Subscription_Product_Avg_Fields = {
  __typename?: 'Billing_subscription_product_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_billed_subscription_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Billing_subscription_product". All fields are combined with a logical 'AND'. */
export type Billing_Subscription_Product_Bool_Exp = {
  _and?: Maybe<Array<Billing_Subscription_Product_Bool_Exp>>;
  _not?: Maybe<Billing_Subscription_Product_Bool_Exp>;
  _or?: Maybe<Array<Billing_Subscription_Product_Bool_Exp>>;
  billing_client?: Maybe<Billing_Client_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_billed_subscription_id?: Maybe<Bigint_Comparison_Exp>;
  last_billing_subscription?: Maybe<Billing_Subscription_Bool_Exp>;
  last_successful_invoice_date?: Maybe<Timestamptz_Comparison_Exp>;
  stripe_invoice_id?: Maybe<String_Comparison_Exp>;
  stripe_subscription_product_id?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Enum_Billing_Subscription_Product_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Billing_subscription_product" */
export enum Billing_Subscription_Product_Constraint {
  /** unique or primary key constraint on columns "type", "context_id" */
  BillingSubscriptionProductContextIdTypeKey = 'Billing_subscription_product_context_id_type_key',
  /** unique or primary key constraint on columns "id" */
  BillingSubscriptionProductPkey = 'Billing_subscription_product_pkey'
}

/** input type for incrementing numeric columns in table "Billing_subscription_product" */
export type Billing_Subscription_Product_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_subscription_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Billing_subscription_product" */
export type Billing_Subscription_Product_Insert_Input = {
  billing_client?: Maybe<Billing_Client_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_subscription_id?: Maybe<Scalars['bigint']>;
  last_billing_subscription?: Maybe<Billing_Subscription_Obj_Rel_Insert_Input>;
  last_successful_invoice_date?: Maybe<Scalars['timestamptz']>;
  /** Last successfully paid (stripe) invoice that included a payment for this product */
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_subscription_product_id?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Billing_Subscription_Product_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Billing_Subscription_Product_Max_Fields = {
  __typename?: 'Billing_subscription_product_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_subscription_id?: Maybe<Scalars['bigint']>;
  last_successful_invoice_date?: Maybe<Scalars['timestamptz']>;
  /** Last successfully paid (stripe) invoice that included a payment for this product */
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_subscription_product_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
  last_successful_invoice_date?: Maybe<Order_By>;
  /** Last successfully paid (stripe) invoice that included a payment for this product */
  stripe_invoice_id?: Maybe<Order_By>;
  stripe_subscription_product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Billing_Subscription_Product_Min_Fields = {
  __typename?: 'Billing_subscription_product_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_subscription_id?: Maybe<Scalars['bigint']>;
  last_successful_invoice_date?: Maybe<Scalars['timestamptz']>;
  /** Last successfully paid (stripe) invoice that included a payment for this product */
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_subscription_product_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
  last_successful_invoice_date?: Maybe<Order_By>;
  /** Last successfully paid (stripe) invoice that included a payment for this product */
  stripe_invoice_id?: Maybe<Order_By>;
  stripe_subscription_product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Billing_subscription_product" */
export type Billing_Subscription_Product_Mutation_Response = {
  __typename?: 'Billing_subscription_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Subscription_Product>;
};

/** on_conflict condition type for table "Billing_subscription_product" */
export type Billing_Subscription_Product_On_Conflict = {
  constraint: Billing_Subscription_Product_Constraint;
  update_columns?: Array<Billing_Subscription_Product_Update_Column>;
  where?: Maybe<Billing_Subscription_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "Billing_subscription_product". */
export type Billing_Subscription_Product_Order_By = {
  billing_client?: Maybe<Billing_Client_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
  last_billing_subscription?: Maybe<Billing_Subscription_Order_By>;
  last_successful_invoice_date?: Maybe<Order_By>;
  stripe_invoice_id?: Maybe<Order_By>;
  stripe_subscription_product_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Billing_subscription_product */
export type Billing_Subscription_Product_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Billing_subscription_product" */
export enum Billing_Subscription_Product_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastBilledSubscriptionId = 'last_billed_subscription_id',
  /** column name */
  LastSuccessfulInvoiceDate = 'last_successful_invoice_date',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  StripeSubscriptionProductId = 'stripe_subscription_product_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Billing_subscription_product" */
export type Billing_Subscription_Product_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_subscription_id?: Maybe<Scalars['bigint']>;
  last_successful_invoice_date?: Maybe<Scalars['timestamptz']>;
  /** Last successfully paid (stripe) invoice that included a payment for this product */
  stripe_invoice_id?: Maybe<Scalars['String']>;
  stripe_subscription_product_id?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Billing_Subscription_Product_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Billing_Subscription_Product_Stddev_Fields = {
  __typename?: 'Billing_subscription_product_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_billed_subscription_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Billing_Subscription_Product_Stddev_Pop_Fields = {
  __typename?: 'Billing_subscription_product_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_billed_subscription_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Billing_Subscription_Product_Stddev_Samp_Fields = {
  __typename?: 'Billing_subscription_product_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_billed_subscription_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Billing_Subscription_Product_Sum_Fields = {
  __typename?: 'Billing_subscription_product_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_subscription_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
};

/** update columns of table "Billing_subscription_product" */
export enum Billing_Subscription_Product_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastBilledSubscriptionId = 'last_billed_subscription_id',
  /** column name */
  LastSuccessfulInvoiceDate = 'last_successful_invoice_date',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  StripeSubscriptionProductId = 'stripe_subscription_product_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Billing_Subscription_Product_Var_Pop_Fields = {
  __typename?: 'Billing_subscription_product_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_billed_subscription_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Billing_Subscription_Product_Var_Samp_Fields = {
  __typename?: 'Billing_subscription_product_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_billed_subscription_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Billing_Subscription_Product_Variance_Fields = {
  __typename?: 'Billing_subscription_product_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_billed_subscription_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Billing_subscription_product" */
export type Billing_Subscription_Product_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_billed_subscription_id?: Maybe<Order_By>;
};

/** select columns of table "Billing_subscription" */
export enum Billing_Subscription_Select_Column {
  /** column name */
  BillingSubscriptionPeriod = 'billing_subscription_period',
  /** column name */
  BillingSubscriptionStatus = 'billing_subscription_status',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastBilledAt = 'last_billed_at',
  /** column name */
  LastFailedPaymentAt = 'last_failed_payment_at',
  /** column name */
  LastFailedPaymentDeclineCode = 'last_failed_payment_decline_code',
  /** column name */
  LastFailedPaymentErrorCode = 'last_failed_payment_error_code',
  /** column name */
  NextBillingDate = 'next_billing_date',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Billing_subscription" */
export type Billing_Subscription_Set_Input = {
  billing_subscription_period?: Maybe<Enum_Billing_Subscription_Period_Enum>;
  billing_subscription_status?: Maybe<Enum_Billing_Subscription_Status_Enum>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_at?: Maybe<Scalars['timestamptz']>;
  last_failed_payment_decline_code?: Maybe<Scalars['String']>;
  last_failed_payment_error_code?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Billing_Subscription_Stddev_Fields = {
  __typename?: 'Billing_subscription_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Billing_subscription" */
export type Billing_Subscription_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Billing_Subscription_Stddev_Pop_Fields = {
  __typename?: 'Billing_subscription_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Billing_subscription" */
export type Billing_Subscription_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Billing_Subscription_Stddev_Samp_Fields = {
  __typename?: 'Billing_subscription_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Billing_subscription" */
export type Billing_Subscription_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Billing_Subscription_Sum_Fields = {
  __typename?: 'Billing_subscription_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Billing_subscription" */
export type Billing_Subscription_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Billing_subscription" */
export enum Billing_Subscription_Update_Column {
  /** column name */
  BillingSubscriptionPeriod = 'billing_subscription_period',
  /** column name */
  BillingSubscriptionStatus = 'billing_subscription_status',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastBilledAt = 'last_billed_at',
  /** column name */
  LastFailedPaymentAt = 'last_failed_payment_at',
  /** column name */
  LastFailedPaymentDeclineCode = 'last_failed_payment_decline_code',
  /** column name */
  LastFailedPaymentErrorCode = 'last_failed_payment_error_code',
  /** column name */
  NextBillingDate = 'next_billing_date',
  /** column name */
  StripeSubscriptionId = 'stripe_subscription_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Billing_Subscription_Var_Pop_Fields = {
  __typename?: 'Billing_subscription_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Billing_subscription" */
export type Billing_Subscription_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Billing_Subscription_Var_Samp_Fields = {
  __typename?: 'Billing_subscription_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Billing_subscription" */
export type Billing_Subscription_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Billing_Subscription_Variance_Fields = {
  __typename?: 'Billing_subscription_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Billing_subscription" */
export type Billing_Subscription_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "Biolink_sections" */
export type Biolink_Sections = {
  __typename?: 'Biolink_sections';
  /** An object relationship */
  ENUM_social_link_type: Enum_Social_Link_Type;
  /** An object relationship */
  context?: Maybe<Context>;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  order: Scalars['Int'];
  section: Enum_Social_Link_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Biolink_sections" */
export type Biolink_Sections_Aggregate = {
  __typename?: 'Biolink_sections_aggregate';
  aggregate?: Maybe<Biolink_Sections_Aggregate_Fields>;
  nodes: Array<Biolink_Sections>;
};

/** aggregate fields of "Biolink_sections" */
export type Biolink_Sections_Aggregate_Fields = {
  __typename?: 'Biolink_sections_aggregate_fields';
  avg?: Maybe<Biolink_Sections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Biolink_Sections_Max_Fields>;
  min?: Maybe<Biolink_Sections_Min_Fields>;
  stddev?: Maybe<Biolink_Sections_Stddev_Fields>;
  stddev_pop?: Maybe<Biolink_Sections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Biolink_Sections_Stddev_Samp_Fields>;
  sum?: Maybe<Biolink_Sections_Sum_Fields>;
  var_pop?: Maybe<Biolink_Sections_Var_Pop_Fields>;
  var_samp?: Maybe<Biolink_Sections_Var_Samp_Fields>;
  variance?: Maybe<Biolink_Sections_Variance_Fields>;
};


/** aggregate fields of "Biolink_sections" */
export type Biolink_Sections_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Biolink_Sections_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Biolink_Sections_Avg_Fields = {
  __typename?: 'Biolink_sections_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Biolink_sections". All fields are combined with a logical 'AND'. */
export type Biolink_Sections_Bool_Exp = {
  ENUM_social_link_type?: Maybe<Enum_Social_Link_Type_Bool_Exp>;
  _and?: Maybe<Array<Biolink_Sections_Bool_Exp>>;
  _not?: Maybe<Biolink_Sections_Bool_Exp>;
  _or?: Maybe<Array<Biolink_Sections_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  section?: Maybe<Enum_Social_Link_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Biolink_sections" */
export enum Biolink_Sections_Constraint {
  /** unique or primary key constraint on columns "section", "context_id" */
  BiolinkSectionsContextIdSectionKey = 'Biolink_sections_context_id_section_key',
  /** unique or primary key constraint on columns "id" */
  BiolinkSectionsPkey = 'Biolink_sections_pkey'
}

/** input type for incrementing numeric columns in table "Biolink_sections" */
export type Biolink_Sections_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Biolink_sections" */
export type Biolink_Sections_Insert_Input = {
  ENUM_social_link_type?: Maybe<Enum_Social_Link_Type_Obj_Rel_Insert_Input>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  section?: Maybe<Enum_Social_Link_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Biolink_Sections_Max_Fields = {
  __typename?: 'Biolink_sections_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Biolink_Sections_Min_Fields = {
  __typename?: 'Biolink_sections_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Biolink_sections" */
export type Biolink_Sections_Mutation_Response = {
  __typename?: 'Biolink_sections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Biolink_Sections>;
};

/** on_conflict condition type for table "Biolink_sections" */
export type Biolink_Sections_On_Conflict = {
  constraint: Biolink_Sections_Constraint;
  update_columns?: Array<Biolink_Sections_Update_Column>;
  where?: Maybe<Biolink_Sections_Bool_Exp>;
};

/** Ordering options when selecting data from "Biolink_sections". */
export type Biolink_Sections_Order_By = {
  ENUM_social_link_type?: Maybe<Enum_Social_Link_Type_Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  section?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Biolink_sections */
export type Biolink_Sections_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Biolink_sections" */
export enum Biolink_Sections_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Section = 'section',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Biolink_sections" */
export type Biolink_Sections_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  section?: Maybe<Enum_Social_Link_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Biolink_Sections_Stddev_Fields = {
  __typename?: 'Biolink_sections_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Biolink_Sections_Stddev_Pop_Fields = {
  __typename?: 'Biolink_sections_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Biolink_Sections_Stddev_Samp_Fields = {
  __typename?: 'Biolink_sections_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Biolink_Sections_Sum_Fields = {
  __typename?: 'Biolink_sections_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "Biolink_sections" */
export enum Biolink_Sections_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Section = 'section',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Biolink_Sections_Var_Pop_Fields = {
  __typename?: 'Biolink_sections_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Biolink_Sections_Var_Samp_Fields = {
  __typename?: 'Biolink_sections_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Biolink_Sections_Variance_Fields = {
  __typename?: 'Biolink_sections_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type Categories = {
  __typename?: 'Categories';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type CertificateUploadResult = {
  __typename?: 'CertificateUploadResult';
  certificateURL?: Maybe<Scalars['String']>;
  newFileId: Scalars['bigint'];
  status: Scalars['Boolean'];
};

/** Table to track certificates of authenticity  */
export type Certificate_Of_Authenticity = {
  __typename?: 'Certificate_of_authenticity';
  /** An object relationship */
  Artwork?: Maybe<Artwork>;
  /** An object relationship */
  Print?: Maybe<Print>;
  /** An object relationship */
  Vumark_metadata?: Maybe<File_Metadata>;
  created_at: Scalars['timestamptz'];
  fingerprint: Scalars['String'];
  id: Scalars['bigint'];
  refresh: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  vumark_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "Certificate_of_authenticity" */
export type Certificate_Of_Authenticity_Aggregate = {
  __typename?: 'Certificate_of_authenticity_aggregate';
  aggregate?: Maybe<Certificate_Of_Authenticity_Aggregate_Fields>;
  nodes: Array<Certificate_Of_Authenticity>;
};

/** aggregate fields of "Certificate_of_authenticity" */
export type Certificate_Of_Authenticity_Aggregate_Fields = {
  __typename?: 'Certificate_of_authenticity_aggregate_fields';
  avg?: Maybe<Certificate_Of_Authenticity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Certificate_Of_Authenticity_Max_Fields>;
  min?: Maybe<Certificate_Of_Authenticity_Min_Fields>;
  stddev?: Maybe<Certificate_Of_Authenticity_Stddev_Fields>;
  stddev_pop?: Maybe<Certificate_Of_Authenticity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Certificate_Of_Authenticity_Stddev_Samp_Fields>;
  sum?: Maybe<Certificate_Of_Authenticity_Sum_Fields>;
  var_pop?: Maybe<Certificate_Of_Authenticity_Var_Pop_Fields>;
  var_samp?: Maybe<Certificate_Of_Authenticity_Var_Samp_Fields>;
  variance?: Maybe<Certificate_Of_Authenticity_Variance_Fields>;
};


/** aggregate fields of "Certificate_of_authenticity" */
export type Certificate_Of_Authenticity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Certificate_Of_Authenticity_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Certificate_Of_Authenticity_Avg_Fields = {
  __typename?: 'Certificate_of_authenticity_avg_fields';
  id?: Maybe<Scalars['Float']>;
  refresh?: Maybe<Scalars['Float']>;
  vumark_metadata_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Certificate_of_authenticity". All fields are combined with a logical 'AND'. */
export type Certificate_Of_Authenticity_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  Print?: Maybe<Print_Bool_Exp>;
  Vumark_metadata?: Maybe<File_Metadata_Bool_Exp>;
  _and?: Maybe<Array<Certificate_Of_Authenticity_Bool_Exp>>;
  _not?: Maybe<Certificate_Of_Authenticity_Bool_Exp>;
  _or?: Maybe<Array<Certificate_Of_Authenticity_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  fingerprint?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  refresh?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vumark_metadata_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Certificate_of_authenticity" */
export enum Certificate_Of_Authenticity_Constraint {
  /** unique or primary key constraint on columns "id" */
  CertificateOfAuthenticityPkey = 'Certificate_of_authenticity_pkey'
}

/** input type for incrementing numeric columns in table "Certificate_of_authenticity" */
export type Certificate_Of_Authenticity_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  refresh?: Maybe<Scalars['Int']>;
  vumark_metadata_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Certificate_of_authenticity" */
export type Certificate_Of_Authenticity_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  Print?: Maybe<Print_Obj_Rel_Insert_Input>;
  Vumark_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vumark_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Certificate_Of_Authenticity_Max_Fields = {
  __typename?: 'Certificate_of_authenticity_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vumark_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Certificate_Of_Authenticity_Min_Fields = {
  __typename?: 'Certificate_of_authenticity_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vumark_metadata_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Certificate_of_authenticity" */
export type Certificate_Of_Authenticity_Mutation_Response = {
  __typename?: 'Certificate_of_authenticity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Certificate_Of_Authenticity>;
};

/** input type for inserting object relation for remote table "Certificate_of_authenticity" */
export type Certificate_Of_Authenticity_Obj_Rel_Insert_Input = {
  data: Certificate_Of_Authenticity_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Certificate_Of_Authenticity_On_Conflict>;
};

/** on_conflict condition type for table "Certificate_of_authenticity" */
export type Certificate_Of_Authenticity_On_Conflict = {
  constraint: Certificate_Of_Authenticity_Constraint;
  update_columns?: Array<Certificate_Of_Authenticity_Update_Column>;
  where?: Maybe<Certificate_Of_Authenticity_Bool_Exp>;
};

/** Ordering options when selecting data from "Certificate_of_authenticity". */
export type Certificate_Of_Authenticity_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  Print?: Maybe<Print_Order_By>;
  Vumark_metadata?: Maybe<File_Metadata_Order_By>;
  created_at?: Maybe<Order_By>;
  fingerprint?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  refresh?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vumark_metadata_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Certificate_of_authenticity */
export type Certificate_Of_Authenticity_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Certificate_of_authenticity" */
export enum Certificate_Of_Authenticity_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Id = 'id',
  /** column name */
  Refresh = 'refresh',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VumarkMetadataId = 'vumark_metadata_id'
}

/** input type for updating data in table "Certificate_of_authenticity" */
export type Certificate_Of_Authenticity_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  refresh?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vumark_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Certificate_Of_Authenticity_Stddev_Fields = {
  __typename?: 'Certificate_of_authenticity_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  refresh?: Maybe<Scalars['Float']>;
  vumark_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Certificate_Of_Authenticity_Stddev_Pop_Fields = {
  __typename?: 'Certificate_of_authenticity_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  refresh?: Maybe<Scalars['Float']>;
  vumark_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Certificate_Of_Authenticity_Stddev_Samp_Fields = {
  __typename?: 'Certificate_of_authenticity_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  refresh?: Maybe<Scalars['Float']>;
  vumark_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Certificate_Of_Authenticity_Sum_Fields = {
  __typename?: 'Certificate_of_authenticity_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  refresh?: Maybe<Scalars['Int']>;
  vumark_metadata_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Certificate_of_authenticity" */
export enum Certificate_Of_Authenticity_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Id = 'id',
  /** column name */
  Refresh = 'refresh',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VumarkMetadataId = 'vumark_metadata_id'
}

/** aggregate var_pop on columns */
export type Certificate_Of_Authenticity_Var_Pop_Fields = {
  __typename?: 'Certificate_of_authenticity_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  refresh?: Maybe<Scalars['Float']>;
  vumark_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Certificate_Of_Authenticity_Var_Samp_Fields = {
  __typename?: 'Certificate_of_authenticity_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  refresh?: Maybe<Scalars['Float']>;
  vumark_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Certificate_Of_Authenticity_Variance_Fields = {
  __typename?: 'Certificate_of_authenticity_variance_fields';
  id?: Maybe<Scalars['Float']>;
  refresh?: Maybe<Scalars['Float']>;
  vumark_metadata_id?: Maybe<Scalars['Float']>;
};

export type CheckCoAFileResult = {
  __typename?: 'CheckCoAFileResult';
  exists?: Maybe<Scalars['Boolean']>;
  lastUpdate?: Maybe<Scalars['timestamptz']>;
};

export type CheckIfValidationTokenIsValidResponse = {
  __typename?: 'CheckIfValidationTokenIsValidResponse';
  valid: Scalars['Boolean'];
};

export type CheckoutDomainInput = {
  domainName: Scalars['String'];
  registerPrice: DomainPriceInput;
  registrantInfo: DomainRegistrantInfoInput;
};

export type CheckoutDomainResult = {
  __typename?: 'CheckoutDomainResult';
  checkoutUrl: Scalars['String'];
};

export type CheckoutMyStudioResult = {
  __typename?: 'CheckoutMyStudioResult';
  checkoutUrl: Scalars['String'];
};

export type CheckoutSuperSiteDomainInput = {
  domainName: Scalars['String'];
  registerPrice: DomainPriceInput;
  registrantInfo: DomainRegistrantInfoInput;
};

export type CheckoutSuperSiteResult = {
  __typename?: 'CheckoutSuperSiteResult';
  checkoutUrl: Scalars['String'];
};

/** columns and relationships of "Collaboration" */
export type Collaboration = {
  __typename?: 'Collaboration';
  /** An object relationship */
  artist_context: Context;
  artist_id: Scalars['bigint'];
  artist_status: Enum_Collaboration_Status_Enum;
  /** An object relationship */
  artist_status_enum_value: Enum_Collaboration_Status;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  gallery_context: Context;
  gallery_id: Scalars['bigint'];
  gallery_status: Enum_Collaboration_Status_Enum;
  /** An object relationship */
  gallery_status_enum_value: Enum_Collaboration_Status;
  id: Scalars['bigint'];
  /** An array relationship */
  pending_collaboration_on_artworks: Array<Pending_Collaboration_On_Artwork>;
  /** An aggregate relationship */
  pending_collaboration_on_artworks_aggregate: Pending_Collaboration_On_Artwork_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Collaboration" */
export type CollaborationPending_Collaboration_On_ArtworksArgs = {
  distinct_on?: Maybe<Array<Pending_Collaboration_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Collaboration_On_Artwork_Order_By>>;
  where?: Maybe<Pending_Collaboration_On_Artwork_Bool_Exp>;
};


/** columns and relationships of "Collaboration" */
export type CollaborationPending_Collaboration_On_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Pending_Collaboration_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Collaboration_On_Artwork_Order_By>>;
  where?: Maybe<Pending_Collaboration_On_Artwork_Bool_Exp>;
};

/** aggregated selection of "Collaboration" */
export type Collaboration_Aggregate = {
  __typename?: 'Collaboration_aggregate';
  aggregate?: Maybe<Collaboration_Aggregate_Fields>;
  nodes: Array<Collaboration>;
};

/** aggregate fields of "Collaboration" */
export type Collaboration_Aggregate_Fields = {
  __typename?: 'Collaboration_aggregate_fields';
  avg?: Maybe<Collaboration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Collaboration_Max_Fields>;
  min?: Maybe<Collaboration_Min_Fields>;
  stddev?: Maybe<Collaboration_Stddev_Fields>;
  stddev_pop?: Maybe<Collaboration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collaboration_Stddev_Samp_Fields>;
  sum?: Maybe<Collaboration_Sum_Fields>;
  var_pop?: Maybe<Collaboration_Var_Pop_Fields>;
  var_samp?: Maybe<Collaboration_Var_Samp_Fields>;
  variance?: Maybe<Collaboration_Variance_Fields>;
};


/** aggregate fields of "Collaboration" */
export type Collaboration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collaboration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Collaboration" */
export type Collaboration_Aggregate_Order_By = {
  avg?: Maybe<Collaboration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Collaboration_Max_Order_By>;
  min?: Maybe<Collaboration_Min_Order_By>;
  stddev?: Maybe<Collaboration_Stddev_Order_By>;
  stddev_pop?: Maybe<Collaboration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Collaboration_Stddev_Samp_Order_By>;
  sum?: Maybe<Collaboration_Sum_Order_By>;
  var_pop?: Maybe<Collaboration_Var_Pop_Order_By>;
  var_samp?: Maybe<Collaboration_Var_Samp_Order_By>;
  variance?: Maybe<Collaboration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Collaboration" */
export type Collaboration_Arr_Rel_Insert_Input = {
  data: Array<Collaboration_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Collaboration_On_Conflict>;
};

/** aggregate avg on columns */
export type Collaboration_Avg_Fields = {
  __typename?: 'Collaboration_avg_fields';
  artist_id?: Maybe<Scalars['Float']>;
  gallery_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Collaboration" */
export type Collaboration_Avg_Order_By = {
  artist_id?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Collaboration". All fields are combined with a logical 'AND'. */
export type Collaboration_Bool_Exp = {
  _and?: Maybe<Array<Collaboration_Bool_Exp>>;
  _not?: Maybe<Collaboration_Bool_Exp>;
  _or?: Maybe<Array<Collaboration_Bool_Exp>>;
  artist_context?: Maybe<Context_Bool_Exp>;
  artist_id?: Maybe<Bigint_Comparison_Exp>;
  artist_status?: Maybe<Enum_Collaboration_Status_Enum_Comparison_Exp>;
  artist_status_enum_value?: Maybe<Enum_Collaboration_Status_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  gallery_context?: Maybe<Context_Bool_Exp>;
  gallery_id?: Maybe<Bigint_Comparison_Exp>;
  gallery_status?: Maybe<Enum_Collaboration_Status_Enum_Comparison_Exp>;
  gallery_status_enum_value?: Maybe<Enum_Collaboration_Status_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  pending_collaboration_on_artworks?: Maybe<Pending_Collaboration_On_Artwork_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Collaboration" */
export enum Collaboration_Constraint {
  /** unique or primary key constraint on columns "gallery_id", "artist_id" */
  CollaborationGalleryIdArtistIdKey = 'Collaboration_gallery_id_artist_id_key',
  /** unique or primary key constraint on columns "id" */
  CollaborationPkey = 'Collaboration_pkey'
}

/** input type for incrementing numeric columns in table "Collaboration" */
export type Collaboration_Inc_Input = {
  artist_id?: Maybe<Scalars['bigint']>;
  gallery_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Collaboration" */
export type Collaboration_Insert_Input = {
  artist_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  artist_id?: Maybe<Scalars['bigint']>;
  artist_status?: Maybe<Enum_Collaboration_Status_Enum>;
  artist_status_enum_value?: Maybe<Enum_Collaboration_Status_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gallery_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  gallery_id?: Maybe<Scalars['bigint']>;
  gallery_status?: Maybe<Enum_Collaboration_Status_Enum>;
  gallery_status_enum_value?: Maybe<Enum_Collaboration_Status_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  pending_collaboration_on_artworks?: Maybe<Pending_Collaboration_On_Artwork_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork = {
  __typename?: 'Collaboration_invitation_on_artwork';
  /** An object relationship */
  artwork: Artwork;
  artwork_id: Scalars['bigint'];
  invitation_gallery_name?: Maybe<Scalars['String']>;
  invitation_id: Scalars['bigint'];
  /** An object relationship */
  profile_invitation: Profile_Invitations;
};

/** aggregated selection of "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Aggregate = {
  __typename?: 'Collaboration_invitation_on_artwork_aggregate';
  aggregate?: Maybe<Collaboration_Invitation_On_Artwork_Aggregate_Fields>;
  nodes: Array<Collaboration_Invitation_On_Artwork>;
};

/** aggregate fields of "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Aggregate_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_aggregate_fields';
  avg?: Maybe<Collaboration_Invitation_On_Artwork_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Collaboration_Invitation_On_Artwork_Max_Fields>;
  min?: Maybe<Collaboration_Invitation_On_Artwork_Min_Fields>;
  stddev?: Maybe<Collaboration_Invitation_On_Artwork_Stddev_Fields>;
  stddev_pop?: Maybe<Collaboration_Invitation_On_Artwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collaboration_Invitation_On_Artwork_Stddev_Samp_Fields>;
  sum?: Maybe<Collaboration_Invitation_On_Artwork_Sum_Fields>;
  var_pop?: Maybe<Collaboration_Invitation_On_Artwork_Var_Pop_Fields>;
  var_samp?: Maybe<Collaboration_Invitation_On_Artwork_Var_Samp_Fields>;
  variance?: Maybe<Collaboration_Invitation_On_Artwork_Variance_Fields>;
};


/** aggregate fields of "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collaboration_Invitation_On_Artwork_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Aggregate_Order_By = {
  avg?: Maybe<Collaboration_Invitation_On_Artwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Collaboration_Invitation_On_Artwork_Max_Order_By>;
  min?: Maybe<Collaboration_Invitation_On_Artwork_Min_Order_By>;
  stddev?: Maybe<Collaboration_Invitation_On_Artwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Collaboration_Invitation_On_Artwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Collaboration_Invitation_On_Artwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Collaboration_Invitation_On_Artwork_Sum_Order_By>;
  var_pop?: Maybe<Collaboration_Invitation_On_Artwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Collaboration_Invitation_On_Artwork_Var_Samp_Order_By>;
  variance?: Maybe<Collaboration_Invitation_On_Artwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Arr_Rel_Insert_Input = {
  data: Array<Collaboration_Invitation_On_Artwork_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Collaboration_Invitation_On_Artwork_On_Conflict>;
};

/** aggregate avg on columns */
export type Collaboration_Invitation_On_Artwork_Avg_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Collaboration_invitation_on_artwork". All fields are combined with a logical 'AND'. */
export type Collaboration_Invitation_On_Artwork_Bool_Exp = {
  _and?: Maybe<Array<Collaboration_Invitation_On_Artwork_Bool_Exp>>;
  _not?: Maybe<Collaboration_Invitation_On_Artwork_Bool_Exp>;
  _or?: Maybe<Array<Collaboration_Invitation_On_Artwork_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  invitation_gallery_name?: Maybe<String_Comparison_Exp>;
  invitation_id?: Maybe<Bigint_Comparison_Exp>;
  profile_invitation?: Maybe<Profile_Invitations_Bool_Exp>;
};

/** unique or primary key constraints on table "Collaboration_invitation_on_artwork" */
export enum Collaboration_Invitation_On_Artwork_Constraint {
  /** unique or primary key constraint on columns "artwork_id", "invitation_id" */
  CollaborationInvitationOnArtworInvitationIdArtworkIdKey = 'Collaboration_invitation_on_artwor_invitation_id_artwork_id_key',
  /** unique or primary key constraint on columns "artwork_id", "invitation_id" */
  CollaborationInvitationOnArtworkPkey = 'Collaboration_invitation_on_artwork_pkey'
}

/** input type for incrementing numeric columns in table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  invitation_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  invitation_gallery_name?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['bigint']>;
  profile_invitation?: Maybe<Profile_Invitations_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Collaboration_Invitation_On_Artwork_Max_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  invitation_gallery_name?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_gallery_name?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Collaboration_Invitation_On_Artwork_Min_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  invitation_gallery_name?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_gallery_name?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Mutation_Response = {
  __typename?: 'Collaboration_invitation_on_artwork_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Collaboration_Invitation_On_Artwork>;
};

/** on_conflict condition type for table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_On_Conflict = {
  constraint: Collaboration_Invitation_On_Artwork_Constraint;
  update_columns?: Array<Collaboration_Invitation_On_Artwork_Update_Column>;
  where?: Maybe<Collaboration_Invitation_On_Artwork_Bool_Exp>;
};

/** Ordering options when selecting data from "Collaboration_invitation_on_artwork". */
export type Collaboration_Invitation_On_Artwork_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  invitation_gallery_name?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
  profile_invitation?: Maybe<Profile_Invitations_Order_By>;
};

/** primary key columns input for table: Collaboration_invitation_on_artwork */
export type Collaboration_Invitation_On_Artwork_Pk_Columns_Input = {
  artwork_id: Scalars['bigint'];
  invitation_id: Scalars['bigint'];
};

/** select columns of table "Collaboration_invitation_on_artwork" */
export enum Collaboration_Invitation_On_Artwork_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  InvitationGalleryName = 'invitation_gallery_name',
  /** column name */
  InvitationId = 'invitation_id'
}

/** input type for updating data in table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  invitation_gallery_name?: Maybe<Scalars['String']>;
  invitation_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Collaboration_Invitation_On_Artwork_Stddev_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Collaboration_Invitation_On_Artwork_Stddev_Pop_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Collaboration_Invitation_On_Artwork_Stddev_Samp_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Collaboration_Invitation_On_Artwork_Sum_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  invitation_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** update columns of table "Collaboration_invitation_on_artwork" */
export enum Collaboration_Invitation_On_Artwork_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  InvitationGalleryName = 'invitation_gallery_name',
  /** column name */
  InvitationId = 'invitation_id'
}

/** aggregate var_pop on columns */
export type Collaboration_Invitation_On_Artwork_Var_Pop_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Collaboration_Invitation_On_Artwork_Var_Samp_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Collaboration_Invitation_On_Artwork_Variance_Fields = {
  __typename?: 'Collaboration_invitation_on_artwork_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  invitation_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Collaboration_invitation_on_artwork" */
export type Collaboration_Invitation_On_Artwork_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  invitation_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Collaboration_Max_Fields = {
  __typename?: 'Collaboration_max_fields';
  artist_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gallery_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Collaboration" */
export type Collaboration_Max_Order_By = {
  artist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Collaboration_Min_Fields = {
  __typename?: 'Collaboration_min_fields';
  artist_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gallery_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Collaboration" */
export type Collaboration_Min_Order_By = {
  artist_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Collaboration" */
export type Collaboration_Mutation_Response = {
  __typename?: 'Collaboration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Collaboration>;
};

/** input type for inserting object relation for remote table "Collaboration" */
export type Collaboration_Obj_Rel_Insert_Input = {
  data: Collaboration_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Collaboration_On_Conflict>;
};

/** on_conflict condition type for table "Collaboration" */
export type Collaboration_On_Conflict = {
  constraint: Collaboration_Constraint;
  update_columns?: Array<Collaboration_Update_Column>;
  where?: Maybe<Collaboration_Bool_Exp>;
};

/** Ordering options when selecting data from "Collaboration". */
export type Collaboration_Order_By = {
  artist_context?: Maybe<Context_Order_By>;
  artist_id?: Maybe<Order_By>;
  artist_status?: Maybe<Order_By>;
  artist_status_enum_value?: Maybe<Enum_Collaboration_Status_Order_By>;
  created_at?: Maybe<Order_By>;
  gallery_context?: Maybe<Context_Order_By>;
  gallery_id?: Maybe<Order_By>;
  gallery_status?: Maybe<Order_By>;
  gallery_status_enum_value?: Maybe<Enum_Collaboration_Status_Order_By>;
  id?: Maybe<Order_By>;
  pending_collaboration_on_artworks_aggregate?: Maybe<Pending_Collaboration_On_Artwork_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Collaboration */
export type Collaboration_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Collaboration" */
export enum Collaboration_Select_Column {
  /** column name */
  ArtistId = 'artist_id',
  /** column name */
  ArtistStatus = 'artist_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GalleryId = 'gallery_id',
  /** column name */
  GalleryStatus = 'gallery_status',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Collaboration" */
export type Collaboration_Set_Input = {
  artist_id?: Maybe<Scalars['bigint']>;
  artist_status?: Maybe<Enum_Collaboration_Status_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gallery_id?: Maybe<Scalars['bigint']>;
  gallery_status?: Maybe<Enum_Collaboration_Status_Enum>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Collaboration_Stddev_Fields = {
  __typename?: 'Collaboration_stddev_fields';
  artist_id?: Maybe<Scalars['Float']>;
  gallery_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Collaboration" */
export type Collaboration_Stddev_Order_By = {
  artist_id?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Collaboration_Stddev_Pop_Fields = {
  __typename?: 'Collaboration_stddev_pop_fields';
  artist_id?: Maybe<Scalars['Float']>;
  gallery_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Collaboration" */
export type Collaboration_Stddev_Pop_Order_By = {
  artist_id?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Collaboration_Stddev_Samp_Fields = {
  __typename?: 'Collaboration_stddev_samp_fields';
  artist_id?: Maybe<Scalars['Float']>;
  gallery_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Collaboration" */
export type Collaboration_Stddev_Samp_Order_By = {
  artist_id?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Collaboration_Sum_Fields = {
  __typename?: 'Collaboration_sum_fields';
  artist_id?: Maybe<Scalars['bigint']>;
  gallery_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Collaboration" */
export type Collaboration_Sum_Order_By = {
  artist_id?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Collaboration" */
export enum Collaboration_Update_Column {
  /** column name */
  ArtistId = 'artist_id',
  /** column name */
  ArtistStatus = 'artist_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GalleryId = 'gallery_id',
  /** column name */
  GalleryStatus = 'gallery_status',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Collaboration_Var_Pop_Fields = {
  __typename?: 'Collaboration_var_pop_fields';
  artist_id?: Maybe<Scalars['Float']>;
  gallery_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Collaboration" */
export type Collaboration_Var_Pop_Order_By = {
  artist_id?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Collaboration_Var_Samp_Fields = {
  __typename?: 'Collaboration_var_samp_fields';
  artist_id?: Maybe<Scalars['Float']>;
  gallery_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Collaboration" */
export type Collaboration_Var_Samp_Order_By = {
  artist_id?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Collaboration_Variance_Fields = {
  __typename?: 'Collaboration_variance_fields';
  artist_id?: Maybe<Scalars['Float']>;
  gallery_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Collaboration" */
export type Collaboration_Variance_Order_By = {
  artist_id?: Maybe<Order_By>;
  gallery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** A Collection row describes a list that holds one or more artworks. */
export type Collection = {
  __typename?: 'Collection';
  /** An array relationship */
  Collection_translations: Array<Collection_Translation>;
  /** An aggregate relationship */
  Collection_translations_aggregate: Collection_Translation_Aggregate;
  /** An object relationship */
  ENUM_collection_type: Enum_Collection_Type;
  /** An object relationship */
  Showroom_detail?: Maybe<Showroom_Details>;
  /** An array relationship */
  access: Array<Flat_Context_Access>;
  /** An aggregate relationship */
  access_aggregate: Flat_Context_Access_Aggregate;
  /** An array relationship */
  artwork_to_collections: Array<Artwork_To_Collection>;
  /** An aggregate relationship */
  artwork_to_collections_aggregate: Artwork_To_Collection_Aggregate;
  /** An object relationship */
  audio_metadata?: Maybe<File_Metadata>;
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  collection_audio_download_link?: Maybe<Scalars['String']>;
  /** An array relationship */
  collection_video_links: Array<Collection_Video_Links>;
  /** An aggregate relationship */
  collection_video_links_aggregate: Collection_Video_Links_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  owner_context: Context;
  /** Creator of the collection */
  owner_context_id: Scalars['bigint'];
  public?: Maybe<Scalars['Boolean']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  type: Enum_Collection_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/** A Collection row describes a list that holds one or more artworks. */
export type CollectionCollection_TranslationsArgs = {
  distinct_on?: Maybe<Array<Collection_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Translation_Order_By>>;
  where?: Maybe<Collection_Translation_Bool_Exp>;
};


/** A Collection row describes a list that holds one or more artworks. */
export type CollectionCollection_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Translation_Order_By>>;
  where?: Maybe<Collection_Translation_Bool_Exp>;
};


/** A Collection row describes a list that holds one or more artworks. */
export type CollectionAccessArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


/** A Collection row describes a list that holds one or more artworks. */
export type CollectionAccess_AggregateArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


/** A Collection row describes a list that holds one or more artworks. */
export type CollectionArtwork_To_CollectionsArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


/** A Collection row describes a list that holds one or more artworks. */
export type CollectionArtwork_To_Collections_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


/** A Collection row describes a list that holds one or more artworks. */
export type CollectionCollection_Video_LinksArgs = {
  distinct_on?: Maybe<Array<Collection_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Video_Links_Order_By>>;
  where?: Maybe<Collection_Video_Links_Bool_Exp>;
};


/** A Collection row describes a list that holds one or more artworks. */
export type CollectionCollection_Video_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Video_Links_Order_By>>;
  where?: Maybe<Collection_Video_Links_Bool_Exp>;
};

/** aggregated selection of "Collection" */
export type Collection_Aggregate = {
  __typename?: 'Collection_aggregate';
  aggregate?: Maybe<Collection_Aggregate_Fields>;
  nodes: Array<Collection>;
};

/** aggregate fields of "Collection" */
export type Collection_Aggregate_Fields = {
  __typename?: 'Collection_aggregate_fields';
  avg?: Maybe<Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Collection_Max_Fields>;
  min?: Maybe<Collection_Min_Fields>;
  stddev?: Maybe<Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Collection_Sum_Fields>;
  var_pop?: Maybe<Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Collection_Var_Samp_Fields>;
  variance?: Maybe<Collection_Variance_Fields>;
};


/** aggregate fields of "Collection" */
export type Collection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Collection" */
export type Collection_Aggregate_Order_By = {
  avg?: Maybe<Collection_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Collection_Max_Order_By>;
  min?: Maybe<Collection_Min_Order_By>;
  stddev?: Maybe<Collection_Stddev_Order_By>;
  stddev_pop?: Maybe<Collection_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Collection_Stddev_Samp_Order_By>;
  sum?: Maybe<Collection_Sum_Order_By>;
  var_pop?: Maybe<Collection_Var_Pop_Order_By>;
  var_samp?: Maybe<Collection_Var_Samp_Order_By>;
  variance?: Maybe<Collection_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Collection" */
export type Collection_Arr_Rel_Insert_Input = {
  data: Array<Collection_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Collection_On_Conflict>;
};

/** aggregate avg on columns */
export type Collection_Avg_Fields = {
  __typename?: 'Collection_avg_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Collection" */
export type Collection_Avg_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Collection". All fields are combined with a logical 'AND'. */
export type Collection_Bool_Exp = {
  Collection_translations?: Maybe<Collection_Translation_Bool_Exp>;
  ENUM_collection_type?: Maybe<Enum_Collection_Type_Bool_Exp>;
  Showroom_detail?: Maybe<Showroom_Details_Bool_Exp>;
  _and?: Maybe<Array<Collection_Bool_Exp>>;
  _not?: Maybe<Collection_Bool_Exp>;
  _or?: Maybe<Array<Collection_Bool_Exp>>;
  access?: Maybe<Flat_Context_Access_Bool_Exp>;
  artwork_to_collections?: Maybe<Artwork_To_Collection_Bool_Exp>;
  audio_metadata?: Maybe<File_Metadata_Bool_Exp>;
  audio_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  collection_video_links?: Maybe<Collection_Video_Links_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  owner_context?: Maybe<Context_Bool_Exp>;
  owner_context_id?: Maybe<Bigint_Comparison_Exp>;
  public?: Maybe<Boolean_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<Enum_Collection_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Collection" */
export enum Collection_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollectionPkey = 'Collection_pkey',
  /** unique or primary key constraint on columns "owner_context_id" */
  CollectionArtUniverseIdx = 'collection_art_universe_idx'
}

/** input type for incrementing numeric columns in table "Collection" */
export type Collection_Inc_Input = {
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Collection" */
export type Collection_Insert_Input = {
  Collection_translations?: Maybe<Collection_Translation_Arr_Rel_Insert_Input>;
  ENUM_collection_type?: Maybe<Enum_Collection_Type_Obj_Rel_Insert_Input>;
  Showroom_detail?: Maybe<Showroom_Details_Obj_Rel_Insert_Input>;
  access?: Maybe<Flat_Context_Access_Arr_Rel_Insert_Input>;
  artwork_to_collections?: Maybe<Artwork_To_Collection_Arr_Rel_Insert_Input>;
  audio_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  collection_video_links?: Maybe<Collection_Video_Links_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  owner_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['bigint']>;
  public?: Maybe<Scalars['Boolean']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Enum_Collection_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Collection_Max_Fields = {
  __typename?: 'Collection_max_fields';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Collection" */
export type Collection_Max_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Collection_Min_Fields = {
  __typename?: 'Collection_min_fields';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Collection" */
export type Collection_Min_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Collection" */
export type Collection_Mutation_Response = {
  __typename?: 'Collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Collection>;
};

/** input type for inserting object relation for remote table "Collection" */
export type Collection_Obj_Rel_Insert_Input = {
  data: Collection_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Collection_On_Conflict>;
};

/** on_conflict condition type for table "Collection" */
export type Collection_On_Conflict = {
  constraint: Collection_Constraint;
  update_columns?: Array<Collection_Update_Column>;
  where?: Maybe<Collection_Bool_Exp>;
};

/** Ordering options when selecting data from "Collection". */
export type Collection_Order_By = {
  Collection_translations_aggregate?: Maybe<Collection_Translation_Aggregate_Order_By>;
  ENUM_collection_type?: Maybe<Enum_Collection_Type_Order_By>;
  Showroom_detail?: Maybe<Showroom_Details_Order_By>;
  access_aggregate?: Maybe<Flat_Context_Access_Aggregate_Order_By>;
  artwork_to_collections_aggregate?: Maybe<Artwork_To_Collection_Aggregate_Order_By>;
  audio_metadata?: Maybe<File_Metadata_Order_By>;
  audio_metadata_id?: Maybe<Order_By>;
  collection_video_links_aggregate?: Maybe<Collection_Video_Links_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  owner_context?: Maybe<Context_Order_By>;
  owner_context_id?: Maybe<Order_By>;
  public?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Collection */
export type Collection_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Collection" */
export enum Collection_Select_Column {
  /** column name */
  AudioMetadataId = 'audio_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerContextId = 'owner_context_id',
  /** column name */
  Public = 'public',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Collection" */
export type Collection_Set_Input = {
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['bigint']>;
  public?: Maybe<Scalars['Boolean']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Enum_Collection_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Collection_Stddev_Fields = {
  __typename?: 'Collection_stddev_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Collection" */
export type Collection_Stddev_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Collection_Stddev_Pop_Fields = {
  __typename?: 'Collection_stddev_pop_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Collection" */
export type Collection_Stddev_Pop_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Collection_Stddev_Samp_Fields = {
  __typename?: 'Collection_stddev_samp_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Collection" */
export type Collection_Stddev_Samp_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Collection_Sum_Fields = {
  __typename?: 'Collection_sum_fields';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Collection" */
export type Collection_Sum_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
};

/** Holds the translations of the Collection text columns */
export type Collection_Translation = {
  __typename?: 'Collection_translation';
  /** An object relationship */
  Collection: Collection;
  /** An object relationship */
  Language: Languages;
  collection_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  description_translation: Scalars['String'];
  id: Scalars['bigint'];
  language_code: Scalars['String'];
  name_translation: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Collection_translation" */
export type Collection_Translation_Aggregate = {
  __typename?: 'Collection_translation_aggregate';
  aggregate?: Maybe<Collection_Translation_Aggregate_Fields>;
  nodes: Array<Collection_Translation>;
};

/** aggregate fields of "Collection_translation" */
export type Collection_Translation_Aggregate_Fields = {
  __typename?: 'Collection_translation_aggregate_fields';
  avg?: Maybe<Collection_Translation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Collection_Translation_Max_Fields>;
  min?: Maybe<Collection_Translation_Min_Fields>;
  stddev?: Maybe<Collection_Translation_Stddev_Fields>;
  stddev_pop?: Maybe<Collection_Translation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collection_Translation_Stddev_Samp_Fields>;
  sum?: Maybe<Collection_Translation_Sum_Fields>;
  var_pop?: Maybe<Collection_Translation_Var_Pop_Fields>;
  var_samp?: Maybe<Collection_Translation_Var_Samp_Fields>;
  variance?: Maybe<Collection_Translation_Variance_Fields>;
};


/** aggregate fields of "Collection_translation" */
export type Collection_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Collection_translation" */
export type Collection_Translation_Aggregate_Order_By = {
  avg?: Maybe<Collection_Translation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Collection_Translation_Max_Order_By>;
  min?: Maybe<Collection_Translation_Min_Order_By>;
  stddev?: Maybe<Collection_Translation_Stddev_Order_By>;
  stddev_pop?: Maybe<Collection_Translation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Collection_Translation_Stddev_Samp_Order_By>;
  sum?: Maybe<Collection_Translation_Sum_Order_By>;
  var_pop?: Maybe<Collection_Translation_Var_Pop_Order_By>;
  var_samp?: Maybe<Collection_Translation_Var_Samp_Order_By>;
  variance?: Maybe<Collection_Translation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Collection_translation" */
export type Collection_Translation_Arr_Rel_Insert_Input = {
  data: Array<Collection_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Collection_Translation_On_Conflict>;
};

/** aggregate avg on columns */
export type Collection_Translation_Avg_Fields = {
  __typename?: 'Collection_translation_avg_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Collection_translation" */
export type Collection_Translation_Avg_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Collection_translation". All fields are combined with a logical 'AND'. */
export type Collection_Translation_Bool_Exp = {
  Collection?: Maybe<Collection_Bool_Exp>;
  Language?: Maybe<Languages_Bool_Exp>;
  _and?: Maybe<Array<Collection_Translation_Bool_Exp>>;
  _not?: Maybe<Collection_Translation_Bool_Exp>;
  _or?: Maybe<Array<Collection_Translation_Bool_Exp>>;
  collection_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description_translation?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  language_code?: Maybe<String_Comparison_Exp>;
  name_translation?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Collection_translation" */
export enum Collection_Translation_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollectionTranslationPkey = 'Collection_Translation_pkey'
}

/** input type for incrementing numeric columns in table "Collection_translation" */
export type Collection_Translation_Inc_Input = {
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Collection_translation" */
export type Collection_Translation_Insert_Input = {
  Collection?: Maybe<Collection_Obj_Rel_Insert_Input>;
  Language?: Maybe<Languages_Obj_Rel_Insert_Input>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Collection_Translation_Max_Fields = {
  __typename?: 'Collection_translation_max_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Collection_translation" */
export type Collection_Translation_Max_Order_By = {
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  name_translation?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Collection_Translation_Min_Fields = {
  __typename?: 'Collection_translation_min_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Collection_translation" */
export type Collection_Translation_Min_Order_By = {
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  name_translation?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Collection_translation" */
export type Collection_Translation_Mutation_Response = {
  __typename?: 'Collection_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Translation>;
};

/** on_conflict condition type for table "Collection_translation" */
export type Collection_Translation_On_Conflict = {
  constraint: Collection_Translation_Constraint;
  update_columns?: Array<Collection_Translation_Update_Column>;
  where?: Maybe<Collection_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "Collection_translation". */
export type Collection_Translation_Order_By = {
  Collection?: Maybe<Collection_Order_By>;
  Language?: Maybe<Languages_Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  name_translation?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Collection_translation */
export type Collection_Translation_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Collection_translation" */
export enum Collection_Translation_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionTranslation = 'description_translation',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  NameTranslation = 'name_translation',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Collection_translation" */
export type Collection_Translation_Set_Input = {
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Collection_Translation_Stddev_Fields = {
  __typename?: 'Collection_translation_stddev_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Collection_translation" */
export type Collection_Translation_Stddev_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Collection_Translation_Stddev_Pop_Fields = {
  __typename?: 'Collection_translation_stddev_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Collection_translation" */
export type Collection_Translation_Stddev_Pop_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Collection_Translation_Stddev_Samp_Fields = {
  __typename?: 'Collection_translation_stddev_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Collection_translation" */
export type Collection_Translation_Stddev_Samp_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Collection_Translation_Sum_Fields = {
  __typename?: 'Collection_translation_sum_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Collection_translation" */
export type Collection_Translation_Sum_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Collection_translation" */
export enum Collection_Translation_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionTranslation = 'description_translation',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  NameTranslation = 'name_translation',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Collection_Translation_Var_Pop_Fields = {
  __typename?: 'Collection_translation_var_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Collection_translation" */
export type Collection_Translation_Var_Pop_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Collection_Translation_Var_Samp_Fields = {
  __typename?: 'Collection_translation_var_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Collection_translation" */
export type Collection_Translation_Var_Samp_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Collection_Translation_Variance_Fields = {
  __typename?: 'Collection_translation_variance_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Collection_translation" */
export type Collection_Translation_Variance_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Collection" */
export enum Collection_Update_Column {
  /** column name */
  AudioMetadataId = 'audio_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerContextId = 'owner_context_id',
  /** column name */
  Public = 'public',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Collection_Var_Pop_Fields = {
  __typename?: 'Collection_var_pop_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Collection" */
export type Collection_Var_Pop_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Collection_Var_Samp_Fields = {
  __typename?: 'Collection_var_samp_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Collection" */
export type Collection_Var_Samp_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Collection_Variance_Fields = {
  __typename?: 'Collection_variance_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Collection" */
export type Collection_Variance_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Creator of the collection */
  owner_context_id?: Maybe<Order_By>;
};

/** columns and relationships of "Collection_video_links" */
export type Collection_Video_Links = {
  __typename?: 'Collection_video_links';
  URL: Scalars['String'];
  /** An object relationship */
  collection: Collection;
  collection_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  platform: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Collection_video_links" */
export type Collection_Video_Links_Aggregate = {
  __typename?: 'Collection_video_links_aggregate';
  aggregate?: Maybe<Collection_Video_Links_Aggregate_Fields>;
  nodes: Array<Collection_Video_Links>;
};

/** aggregate fields of "Collection_video_links" */
export type Collection_Video_Links_Aggregate_Fields = {
  __typename?: 'Collection_video_links_aggregate_fields';
  avg?: Maybe<Collection_Video_Links_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Collection_Video_Links_Max_Fields>;
  min?: Maybe<Collection_Video_Links_Min_Fields>;
  stddev?: Maybe<Collection_Video_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Collection_Video_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collection_Video_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Collection_Video_Links_Sum_Fields>;
  var_pop?: Maybe<Collection_Video_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Collection_Video_Links_Var_Samp_Fields>;
  variance?: Maybe<Collection_Video_Links_Variance_Fields>;
};


/** aggregate fields of "Collection_video_links" */
export type Collection_Video_Links_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Video_Links_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Collection_video_links" */
export type Collection_Video_Links_Aggregate_Order_By = {
  avg?: Maybe<Collection_Video_Links_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Collection_Video_Links_Max_Order_By>;
  min?: Maybe<Collection_Video_Links_Min_Order_By>;
  stddev?: Maybe<Collection_Video_Links_Stddev_Order_By>;
  stddev_pop?: Maybe<Collection_Video_Links_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Collection_Video_Links_Stddev_Samp_Order_By>;
  sum?: Maybe<Collection_Video_Links_Sum_Order_By>;
  var_pop?: Maybe<Collection_Video_Links_Var_Pop_Order_By>;
  var_samp?: Maybe<Collection_Video_Links_Var_Samp_Order_By>;
  variance?: Maybe<Collection_Video_Links_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Collection_video_links" */
export type Collection_Video_Links_Arr_Rel_Insert_Input = {
  data: Array<Collection_Video_Links_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Collection_Video_Links_On_Conflict>;
};

/** aggregate avg on columns */
export type Collection_Video_Links_Avg_Fields = {
  __typename?: 'Collection_video_links_avg_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Avg_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Collection_video_links". All fields are combined with a logical 'AND'. */
export type Collection_Video_Links_Bool_Exp = {
  URL?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Collection_Video_Links_Bool_Exp>>;
  _not?: Maybe<Collection_Video_Links_Bool_Exp>;
  _or?: Maybe<Array<Collection_Video_Links_Bool_Exp>>;
  collection?: Maybe<Collection_Bool_Exp>;
  collection_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  platform?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Collection_video_links" */
export enum Collection_Video_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollectionVideoLinksIdKey = 'Collection_video_links_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionVideoLinksPkey = 'Collection_video_links_pkey'
}

/** input type for incrementing numeric columns in table "Collection_video_links" */
export type Collection_Video_Links_Inc_Input = {
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Collection_video_links" */
export type Collection_Video_Links_Insert_Input = {
  URL?: Maybe<Scalars['String']>;
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Collection_Video_Links_Max_Fields = {
  __typename?: 'Collection_video_links_max_fields';
  URL?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Max_Order_By = {
  URL?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Collection_Video_Links_Min_Fields = {
  __typename?: 'Collection_video_links_min_fields';
  URL?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Min_Order_By = {
  URL?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Collection_video_links" */
export type Collection_Video_Links_Mutation_Response = {
  __typename?: 'Collection_video_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Video_Links>;
};

/** on_conflict condition type for table "Collection_video_links" */
export type Collection_Video_Links_On_Conflict = {
  constraint: Collection_Video_Links_Constraint;
  update_columns?: Array<Collection_Video_Links_Update_Column>;
  where?: Maybe<Collection_Video_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "Collection_video_links". */
export type Collection_Video_Links_Order_By = {
  URL?: Maybe<Order_By>;
  collection?: Maybe<Collection_Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Collection_video_links */
export type Collection_Video_Links_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Collection_video_links" */
export enum Collection_Video_Links_Select_Column {
  /** column name */
  Url = 'URL',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Collection_video_links" */
export type Collection_Video_Links_Set_Input = {
  URL?: Maybe<Scalars['String']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Collection_Video_Links_Stddev_Fields = {
  __typename?: 'Collection_video_links_stddev_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Stddev_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Collection_Video_Links_Stddev_Pop_Fields = {
  __typename?: 'Collection_video_links_stddev_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Stddev_Pop_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Collection_Video_Links_Stddev_Samp_Fields = {
  __typename?: 'Collection_video_links_stddev_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Stddev_Samp_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Collection_Video_Links_Sum_Fields = {
  __typename?: 'Collection_video_links_sum_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Sum_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Collection_video_links" */
export enum Collection_Video_Links_Update_Column {
  /** column name */
  Url = 'URL',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Collection_Video_Links_Var_Pop_Fields = {
  __typename?: 'Collection_video_links_var_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Var_Pop_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Collection_Video_Links_Var_Samp_Fields = {
  __typename?: 'Collection_video_links_var_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Var_Samp_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Collection_Video_Links_Variance_Fields = {
  __typename?: 'Collection_video_links_variance_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Collection_video_links" */
export type Collection_Video_Links_Variance_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "Collections_links_filtered" */
export type Collections_Links_Filtered = {
  __typename?: 'Collections_links_filtered';
  collection_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "Collections_links_filtered" */
export type Collections_Links_Filtered_Aggregate = {
  __typename?: 'Collections_links_filtered_aggregate';
  aggregate?: Maybe<Collections_Links_Filtered_Aggregate_Fields>;
  nodes: Array<Collections_Links_Filtered>;
};

/** aggregate fields of "Collections_links_filtered" */
export type Collections_Links_Filtered_Aggregate_Fields = {
  __typename?: 'Collections_links_filtered_aggregate_fields';
  avg?: Maybe<Collections_Links_Filtered_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Collections_Links_Filtered_Max_Fields>;
  min?: Maybe<Collections_Links_Filtered_Min_Fields>;
  stddev?: Maybe<Collections_Links_Filtered_Stddev_Fields>;
  stddev_pop?: Maybe<Collections_Links_Filtered_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collections_Links_Filtered_Stddev_Samp_Fields>;
  sum?: Maybe<Collections_Links_Filtered_Sum_Fields>;
  var_pop?: Maybe<Collections_Links_Filtered_Var_Pop_Fields>;
  var_samp?: Maybe<Collections_Links_Filtered_Var_Samp_Fields>;
  variance?: Maybe<Collections_Links_Filtered_Variance_Fields>;
};


/** aggregate fields of "Collections_links_filtered" */
export type Collections_Links_Filtered_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collections_Links_Filtered_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Collections_Links_Filtered_Avg_Fields = {
  __typename?: 'Collections_links_filtered_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Collections_links_filtered". All fields are combined with a logical 'AND'. */
export type Collections_Links_Filtered_Bool_Exp = {
  _and?: Maybe<Array<Collections_Links_Filtered_Bool_Exp>>;
  _not?: Maybe<Collections_Links_Filtered_Bool_Exp>;
  _or?: Maybe<Array<Collections_Links_Filtered_Bool_Exp>>;
  collection_url?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "Collections_links_filtered" */
export type Collections_Links_Filtered_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Collections_links_filtered" */
export type Collections_Links_Filtered_Insert_Input = {
  collection_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Collections_Links_Filtered_Max_Fields = {
  __typename?: 'Collections_links_filtered_max_fields';
  collection_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Collections_Links_Filtered_Min_Fields = {
  __typename?: 'Collections_links_filtered_min_fields';
  collection_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Collections_links_filtered" */
export type Collections_Links_Filtered_Mutation_Response = {
  __typename?: 'Collections_links_filtered_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Collections_Links_Filtered>;
};

/** Ordering options when selecting data from "Collections_links_filtered". */
export type Collections_Links_Filtered_Order_By = {
  collection_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** select columns of table "Collections_links_filtered" */
export enum Collections_Links_Filtered_Select_Column {
  /** column name */
  CollectionUrl = 'collection_url',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "Collections_links_filtered" */
export type Collections_Links_Filtered_Set_Input = {
  collection_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Collections_Links_Filtered_Stddev_Fields = {
  __typename?: 'Collections_links_filtered_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Collections_Links_Filtered_Stddev_Pop_Fields = {
  __typename?: 'Collections_links_filtered_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Collections_Links_Filtered_Stddev_Samp_Fields = {
  __typename?: 'Collections_links_filtered_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Collections_Links_Filtered_Sum_Fields = {
  __typename?: 'Collections_links_filtered_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Collections_Links_Filtered_Var_Pop_Fields = {
  __typename?: 'Collections_links_filtered_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Collections_Links_Filtered_Var_Samp_Fields = {
  __typename?: 'Collections_links_filtered_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Collections_Links_Filtered_Variance_Fields = {
  __typename?: 'Collections_links_filtered_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Collector_explore_info" */
export type Collector_Explore_Info = {
  __typename?: 'Collector_explore_info';
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  collector_context_id?: Maybe<Scalars['bigint']>;
  collector_profile_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Collector_explore_info" */
export type Collector_Explore_Info_Aggregate = {
  __typename?: 'Collector_explore_info_aggregate';
  aggregate?: Maybe<Collector_Explore_Info_Aggregate_Fields>;
  nodes: Array<Collector_Explore_Info>;
};

/** aggregate fields of "Collector_explore_info" */
export type Collector_Explore_Info_Aggregate_Fields = {
  __typename?: 'Collector_explore_info_aggregate_fields';
  avg?: Maybe<Collector_Explore_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Collector_Explore_Info_Max_Fields>;
  min?: Maybe<Collector_Explore_Info_Min_Fields>;
  stddev?: Maybe<Collector_Explore_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Collector_Explore_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collector_Explore_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Collector_Explore_Info_Sum_Fields>;
  var_pop?: Maybe<Collector_Explore_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Collector_Explore_Info_Var_Samp_Fields>;
  variance?: Maybe<Collector_Explore_Info_Variance_Fields>;
};


/** aggregate fields of "Collector_explore_info" */
export type Collector_Explore_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collector_Explore_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Collector_Explore_Info_Avg_Fields = {
  __typename?: 'Collector_explore_info_avg_fields';
  avatar?: Maybe<Scalars['Float']>;
  collector_context_id?: Maybe<Scalars['Float']>;
  collector_profile_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Collector_explore_info". All fields are combined with a logical 'AND'. */
export type Collector_Explore_Info_Bool_Exp = {
  _and?: Maybe<Array<Collector_Explore_Info_Bool_Exp>>;
  _not?: Maybe<Collector_Explore_Info_Bool_Exp>;
  _or?: Maybe<Array<Collector_Explore_Info_Bool_Exp>>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  collector_context_id?: Maybe<Bigint_Comparison_Exp>;
  collector_profile_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  is_public?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Collector_Explore_Info_Max_Fields = {
  __typename?: 'Collector_explore_info_max_fields';
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  collector_context_id?: Maybe<Scalars['bigint']>;
  collector_profile_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Collector_Explore_Info_Min_Fields = {
  __typename?: 'Collector_explore_info_min_fields';
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  collector_context_id?: Maybe<Scalars['bigint']>;
  collector_profile_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Collector_explore_info". */
export type Collector_Explore_Info_Order_By = {
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  collector_context_id?: Maybe<Order_By>;
  collector_profile_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Collector_explore_info" */
export enum Collector_Explore_Info_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  CollectorContextId = 'collector_context_id',
  /** column name */
  CollectorProfileId = 'collector_profile_id',
  /** column name */
  Country = 'country',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Handle = 'handle',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Collector_Explore_Info_Stddev_Fields = {
  __typename?: 'Collector_explore_info_stddev_fields';
  avatar?: Maybe<Scalars['Float']>;
  collector_context_id?: Maybe<Scalars['Float']>;
  collector_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Collector_Explore_Info_Stddev_Pop_Fields = {
  __typename?: 'Collector_explore_info_stddev_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  collector_context_id?: Maybe<Scalars['Float']>;
  collector_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Collector_Explore_Info_Stddev_Samp_Fields = {
  __typename?: 'Collector_explore_info_stddev_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  collector_context_id?: Maybe<Scalars['Float']>;
  collector_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Collector_Explore_Info_Sum_Fields = {
  __typename?: 'Collector_explore_info_sum_fields';
  avatar?: Maybe<Scalars['bigint']>;
  collector_context_id?: Maybe<Scalars['bigint']>;
  collector_profile_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Collector_Explore_Info_Var_Pop_Fields = {
  __typename?: 'Collector_explore_info_var_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  collector_context_id?: Maybe<Scalars['Float']>;
  collector_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Collector_Explore_Info_Var_Samp_Fields = {
  __typename?: 'Collector_explore_info_var_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  collector_context_id?: Maybe<Scalars['Float']>;
  collector_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Collector_Explore_Info_Variance_Fields = {
  __typename?: 'Collector_explore_info_variance_fields';
  avatar?: Maybe<Scalars['Float']>;
  collector_context_id?: Maybe<Scalars['Float']>;
  collector_profile_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Collector_links_filtered" */
export type Collector_Links_Filtered = {
  __typename?: 'Collector_links_filtered';
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Collector_links_filtered" */
export type Collector_Links_Filtered_Aggregate = {
  __typename?: 'Collector_links_filtered_aggregate';
  aggregate?: Maybe<Collector_Links_Filtered_Aggregate_Fields>;
  nodes: Array<Collector_Links_Filtered>;
};

/** aggregate fields of "Collector_links_filtered" */
export type Collector_Links_Filtered_Aggregate_Fields = {
  __typename?: 'Collector_links_filtered_aggregate_fields';
  avg?: Maybe<Collector_Links_Filtered_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Collector_Links_Filtered_Max_Fields>;
  min?: Maybe<Collector_Links_Filtered_Min_Fields>;
  stddev?: Maybe<Collector_Links_Filtered_Stddev_Fields>;
  stddev_pop?: Maybe<Collector_Links_Filtered_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Collector_Links_Filtered_Stddev_Samp_Fields>;
  sum?: Maybe<Collector_Links_Filtered_Sum_Fields>;
  var_pop?: Maybe<Collector_Links_Filtered_Var_Pop_Fields>;
  var_samp?: Maybe<Collector_Links_Filtered_Var_Samp_Fields>;
  variance?: Maybe<Collector_Links_Filtered_Variance_Fields>;
};


/** aggregate fields of "Collector_links_filtered" */
export type Collector_Links_Filtered_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collector_Links_Filtered_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Collector_Links_Filtered_Avg_Fields = {
  __typename?: 'Collector_links_filtered_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Collector_links_filtered". All fields are combined with a logical 'AND'. */
export type Collector_Links_Filtered_Bool_Exp = {
  _and?: Maybe<Array<Collector_Links_Filtered_Bool_Exp>>;
  _not?: Maybe<Collector_Links_Filtered_Bool_Exp>;
  _or?: Maybe<Array<Collector_Links_Filtered_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  profile_card_url?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "Collector_links_filtered" */
export type Collector_Links_Filtered_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Collector_links_filtered" */
export type Collector_Links_Filtered_Insert_Input = {
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Collector_Links_Filtered_Max_Fields = {
  __typename?: 'Collector_links_filtered_max_fields';
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Collector_Links_Filtered_Min_Fields = {
  __typename?: 'Collector_links_filtered_min_fields';
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Collector_links_filtered" */
export type Collector_Links_Filtered_Mutation_Response = {
  __typename?: 'Collector_links_filtered_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Collector_Links_Filtered>;
};

/** Ordering options when selecting data from "Collector_links_filtered". */
export type Collector_Links_Filtered_Order_By = {
  id?: Maybe<Order_By>;
  profile_card_url?: Maybe<Order_By>;
};

/** select columns of table "Collector_links_filtered" */
export enum Collector_Links_Filtered_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProfileCardUrl = 'profile_card_url'
}

/** input type for updating data in table "Collector_links_filtered" */
export type Collector_Links_Filtered_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  profile_card_url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Collector_Links_Filtered_Stddev_Fields = {
  __typename?: 'Collector_links_filtered_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Collector_Links_Filtered_Stddev_Pop_Fields = {
  __typename?: 'Collector_links_filtered_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Collector_Links_Filtered_Stddev_Samp_Fields = {
  __typename?: 'Collector_links_filtered_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Collector_Links_Filtered_Sum_Fields = {
  __typename?: 'Collector_links_filtered_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Collector_Links_Filtered_Var_Pop_Fields = {
  __typename?: 'Collector_links_filtered_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Collector_Links_Filtered_Var_Samp_Fields = {
  __typename?: 'Collector_links_filtered_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Collector_Links_Filtered_Variance_Fields = {
  __typename?: 'Collector_links_filtered_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type CompleteEmailVerificationResponse = {
  __typename?: 'CompleteEmailVerificationResponse';
  email: Scalars['String'];
  tokenId: Scalars['Int'];
};

export type CompletePseudoAuthResponse = {
  __typename?: 'CompletePseudoAuthResponse';
  status: PseudoAuthStatusResponse;
  token?: Maybe<Scalars['String']>;
};

export type ConfirmArtworkAvailabilityInput = {
  availability: ArtworkAvailabilityConfirmationEnum;
  cityId?: Maybe<Scalars['String']>;
  historyId: Scalars['bigint'];
  journeyId: Scalars['bigint'];
  method?: Maybe<Scalars['String']>;
  pricing?: Maybe<Array<Maybe<ConfirmArtworkAvailabilityPricing>>>;
  zip?: Maybe<Scalars['String']>;
};

export type ConfirmArtworkAvailabilityPricing = {
  currency?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  printSize?: Maybe<Scalars['String']>;
};

/** Stores connected domains owned by the users */
export type Connected_Domain = {
  __typename?: 'Connected_domain';
  /** An object relationship */
  context?: Maybe<Context>;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain_name: Scalars['String'];
  domain_provider: Scalars['String'];
  id: Scalars['Int'];
  ssl_generated: Scalars['Boolean'];
  state: Enum_Connected_Domain_State_Enum;
  tld?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Connected_domain" */
export type Connected_Domain_Aggregate = {
  __typename?: 'Connected_domain_aggregate';
  aggregate?: Maybe<Connected_Domain_Aggregate_Fields>;
  nodes: Array<Connected_Domain>;
};

/** aggregate fields of "Connected_domain" */
export type Connected_Domain_Aggregate_Fields = {
  __typename?: 'Connected_domain_aggregate_fields';
  avg?: Maybe<Connected_Domain_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Connected_Domain_Max_Fields>;
  min?: Maybe<Connected_Domain_Min_Fields>;
  stddev?: Maybe<Connected_Domain_Stddev_Fields>;
  stddev_pop?: Maybe<Connected_Domain_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Connected_Domain_Stddev_Samp_Fields>;
  sum?: Maybe<Connected_Domain_Sum_Fields>;
  var_pop?: Maybe<Connected_Domain_Var_Pop_Fields>;
  var_samp?: Maybe<Connected_Domain_Var_Samp_Fields>;
  variance?: Maybe<Connected_Domain_Variance_Fields>;
};


/** aggregate fields of "Connected_domain" */
export type Connected_Domain_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Connected_Domain_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Connected_Domain_Avg_Fields = {
  __typename?: 'Connected_domain_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Connected_domain". All fields are combined with a logical 'AND'. */
export type Connected_Domain_Bool_Exp = {
  _and?: Maybe<Array<Connected_Domain_Bool_Exp>>;
  _not?: Maybe<Connected_Domain_Bool_Exp>;
  _or?: Maybe<Array<Connected_Domain_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  domain_name?: Maybe<String_Comparison_Exp>;
  domain_provider?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  ssl_generated?: Maybe<Boolean_Comparison_Exp>;
  state?: Maybe<Enum_Connected_Domain_State_Enum_Comparison_Exp>;
  tld?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Connected_domain" */
export enum Connected_Domain_Constraint {
  /** unique or primary key constraint on columns "context_id" */
  ConnectedDomainContextIdKey = 'Connected_domain_context_id_key',
  /** unique or primary key constraint on columns "domain_name" */
  ConnectedDomainDomainNameKey = 'Connected_domain_domain_name_key',
  /** unique or primary key constraint on columns "id" */
  ConnectedDomainPkey = 'Connected_domain_pkey'
}

/** input type for incrementing numeric columns in table "Connected_domain" */
export type Connected_Domain_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Connected_domain" */
export type Connected_Domain_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_provider?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ssl_generated?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Enum_Connected_Domain_State_Enum>;
  tld?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Connected_Domain_Max_Fields = {
  __typename?: 'Connected_domain_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_provider?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tld?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Connected_Domain_Min_Fields = {
  __typename?: 'Connected_domain_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_provider?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  tld?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Connected_domain" */
export type Connected_Domain_Mutation_Response = {
  __typename?: 'Connected_domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Connected_Domain>;
};

/** input type for inserting object relation for remote table "Connected_domain" */
export type Connected_Domain_Obj_Rel_Insert_Input = {
  data: Connected_Domain_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Connected_Domain_On_Conflict>;
};

/** on_conflict condition type for table "Connected_domain" */
export type Connected_Domain_On_Conflict = {
  constraint: Connected_Domain_Constraint;
  update_columns?: Array<Connected_Domain_Update_Column>;
  where?: Maybe<Connected_Domain_Bool_Exp>;
};

/** Ordering options when selecting data from "Connected_domain". */
export type Connected_Domain_Order_By = {
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  domain_name?: Maybe<Order_By>;
  domain_provider?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ssl_generated?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  tld?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Connected_domain */
export type Connected_Domain_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Connected_domain" */
export enum Connected_Domain_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DomainName = 'domain_name',
  /** column name */
  DomainProvider = 'domain_provider',
  /** column name */
  Id = 'id',
  /** column name */
  SslGenerated = 'ssl_generated',
  /** column name */
  State = 'state',
  /** column name */
  Tld = 'tld',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Connected_domain" */
export type Connected_Domain_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_provider?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  ssl_generated?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Enum_Connected_Domain_State_Enum>;
  tld?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Connected_Domain_Stddev_Fields = {
  __typename?: 'Connected_domain_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Connected_Domain_Stddev_Pop_Fields = {
  __typename?: 'Connected_domain_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Connected_Domain_Stddev_Samp_Fields = {
  __typename?: 'Connected_domain_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Connected_Domain_Sum_Fields = {
  __typename?: 'Connected_domain_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Connected_domain" */
export enum Connected_Domain_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DomainName = 'domain_name',
  /** column name */
  DomainProvider = 'domain_provider',
  /** column name */
  Id = 'id',
  /** column name */
  SslGenerated = 'ssl_generated',
  /** column name */
  State = 'state',
  /** column name */
  Tld = 'tld',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Connected_Domain_Var_Pop_Fields = {
  __typename?: 'Connected_domain_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Connected_Domain_Var_Samp_Fields = {
  __typename?: 'Connected_domain_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Connected_Domain_Variance_Fields = {
  __typename?: 'Connected_domain_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Stores translations of user input content (statement, quotation, etc.) */
export type Content_Translations = {
  __typename?: 'Content_translations';
  entity_id: Scalars['bigint'];
  secondary_entity_id: Scalars['bigint'];
  source_language: Enum_Language_Preference_Enum;
  translation: Scalars['String'];
  translation_language: Enum_Language_Preference_Enum;
  type: Enum_Content_Type_Enum;
};

/** aggregated selection of "Content_translations" */
export type Content_Translations_Aggregate = {
  __typename?: 'Content_translations_aggregate';
  aggregate?: Maybe<Content_Translations_Aggregate_Fields>;
  nodes: Array<Content_Translations>;
};

/** aggregate fields of "Content_translations" */
export type Content_Translations_Aggregate_Fields = {
  __typename?: 'Content_translations_aggregate_fields';
  avg?: Maybe<Content_Translations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Content_Translations_Max_Fields>;
  min?: Maybe<Content_Translations_Min_Fields>;
  stddev?: Maybe<Content_Translations_Stddev_Fields>;
  stddev_pop?: Maybe<Content_Translations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Content_Translations_Stddev_Samp_Fields>;
  sum?: Maybe<Content_Translations_Sum_Fields>;
  var_pop?: Maybe<Content_Translations_Var_Pop_Fields>;
  var_samp?: Maybe<Content_Translations_Var_Samp_Fields>;
  variance?: Maybe<Content_Translations_Variance_Fields>;
};


/** aggregate fields of "Content_translations" */
export type Content_Translations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Content_Translations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Content_Translations_Avg_Fields = {
  __typename?: 'Content_translations_avg_fields';
  entity_id?: Maybe<Scalars['Float']>;
  secondary_entity_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Content_translations". All fields are combined with a logical 'AND'. */
export type Content_Translations_Bool_Exp = {
  _and?: Maybe<Array<Content_Translations_Bool_Exp>>;
  _not?: Maybe<Content_Translations_Bool_Exp>;
  _or?: Maybe<Array<Content_Translations_Bool_Exp>>;
  entity_id?: Maybe<Bigint_Comparison_Exp>;
  secondary_entity_id?: Maybe<Bigint_Comparison_Exp>;
  source_language?: Maybe<Enum_Language_Preference_Enum_Comparison_Exp>;
  translation?: Maybe<String_Comparison_Exp>;
  translation_language?: Maybe<Enum_Language_Preference_Enum_Comparison_Exp>;
  type?: Maybe<Enum_Content_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "Content_translations" */
export enum Content_Translations_Constraint {
  /** unique or primary key constraint on columns "type", "secondary_entity_id", "entity_id", "translation_language" */
  ContentTranslationsTypeTranslationLanguageEntityIdSecond = 'Content_translations_type_translation_language_entity_id_second'
}

/** input type for incrementing numeric columns in table "Content_translations" */
export type Content_Translations_Inc_Input = {
  entity_id?: Maybe<Scalars['bigint']>;
  secondary_entity_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Content_translations" */
export type Content_Translations_Insert_Input = {
  entity_id?: Maybe<Scalars['bigint']>;
  secondary_entity_id?: Maybe<Scalars['bigint']>;
  source_language?: Maybe<Enum_Language_Preference_Enum>;
  translation?: Maybe<Scalars['String']>;
  translation_language?: Maybe<Enum_Language_Preference_Enum>;
  type?: Maybe<Enum_Content_Type_Enum>;
};

/** aggregate max on columns */
export type Content_Translations_Max_Fields = {
  __typename?: 'Content_translations_max_fields';
  entity_id?: Maybe<Scalars['bigint']>;
  secondary_entity_id?: Maybe<Scalars['bigint']>;
  translation?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Content_Translations_Min_Fields = {
  __typename?: 'Content_translations_min_fields';
  entity_id?: Maybe<Scalars['bigint']>;
  secondary_entity_id?: Maybe<Scalars['bigint']>;
  translation?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Content_translations" */
export type Content_Translations_Mutation_Response = {
  __typename?: 'Content_translations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Content_Translations>;
};

/** on_conflict condition type for table "Content_translations" */
export type Content_Translations_On_Conflict = {
  constraint: Content_Translations_Constraint;
  update_columns?: Array<Content_Translations_Update_Column>;
  where?: Maybe<Content_Translations_Bool_Exp>;
};

/** Ordering options when selecting data from "Content_translations". */
export type Content_Translations_Order_By = {
  entity_id?: Maybe<Order_By>;
  secondary_entity_id?: Maybe<Order_By>;
  source_language?: Maybe<Order_By>;
  translation?: Maybe<Order_By>;
  translation_language?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "Content_translations" */
export enum Content_Translations_Select_Column {
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  SecondaryEntityId = 'secondary_entity_id',
  /** column name */
  SourceLanguage = 'source_language',
  /** column name */
  Translation = 'translation',
  /** column name */
  TranslationLanguage = 'translation_language',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "Content_translations" */
export type Content_Translations_Set_Input = {
  entity_id?: Maybe<Scalars['bigint']>;
  secondary_entity_id?: Maybe<Scalars['bigint']>;
  source_language?: Maybe<Enum_Language_Preference_Enum>;
  translation?: Maybe<Scalars['String']>;
  translation_language?: Maybe<Enum_Language_Preference_Enum>;
  type?: Maybe<Enum_Content_Type_Enum>;
};

/** aggregate stddev on columns */
export type Content_Translations_Stddev_Fields = {
  __typename?: 'Content_translations_stddev_fields';
  entity_id?: Maybe<Scalars['Float']>;
  secondary_entity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Content_Translations_Stddev_Pop_Fields = {
  __typename?: 'Content_translations_stddev_pop_fields';
  entity_id?: Maybe<Scalars['Float']>;
  secondary_entity_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Content_Translations_Stddev_Samp_Fields = {
  __typename?: 'Content_translations_stddev_samp_fields';
  entity_id?: Maybe<Scalars['Float']>;
  secondary_entity_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Content_Translations_Sum_Fields = {
  __typename?: 'Content_translations_sum_fields';
  entity_id?: Maybe<Scalars['bigint']>;
  secondary_entity_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Content_translations" */
export enum Content_Translations_Update_Column {
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  SecondaryEntityId = 'secondary_entity_id',
  /** column name */
  SourceLanguage = 'source_language',
  /** column name */
  Translation = 'translation',
  /** column name */
  TranslationLanguage = 'translation_language',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Content_Translations_Var_Pop_Fields = {
  __typename?: 'Content_translations_var_pop_fields';
  entity_id?: Maybe<Scalars['Float']>;
  secondary_entity_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Content_Translations_Var_Samp_Fields = {
  __typename?: 'Content_translations_var_samp_fields';
  entity_id?: Maybe<Scalars['Float']>;
  secondary_entity_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Content_Translations_Variance_Fields = {
  __typename?: 'Content_translations_variance_fields';
  entity_id?: Maybe<Scalars['Float']>;
  secondary_entity_id?: Maybe<Scalars['Float']>;
};

/** Main table, holds accounts and their roles. */
export type Context = {
  __typename?: 'Context';
  /** An array relationship */
  Artist_Collection_to_Artist_Contexts: Array<Artist_Collection_To_Artist_Context>;
  /** An aggregate relationship */
  Artist_Collection_to_Artist_Contexts_aggregate: Artist_Collection_To_Artist_Context_Aggregate;
  /** An array relationship */
  Artist_newsletter: Array<Artist_Newsletter>;
  /** An aggregate relationship */
  Artist_newsletter_aggregate: Artist_Newsletter_Aggregate;
  /** An array relationship */
  Artwork_events: Array<Artwork_Events>;
  /** An aggregate relationship */
  Artwork_events_aggregate: Artwork_Events_Aggregate;
  /** An array relationship */
  Artworks: Array<Artwork>;
  /** An aggregate relationship */
  Artworks_aggregate: Artwork_Aggregate;
  /** An object relationship */
  ENUM_context_state: Enum_Context_State;
  /** An object relationship */
  ENUM_context_type: Enum_Context_Type;
  /** An array relationship */
  FCM_Tokens: Array<Fcm_Token>;
  /** An aggregate relationship */
  FCM_Tokens_aggregate: Fcm_Token_Aggregate;
  /** An array relationship */
  File_metadata: Array<File_Metadata>;
  /** An aggregate relationship */
  File_metadata_aggregate: File_Metadata_Aggregate;
  /** An array relationship */
  Wallets: Array<Wallets>;
  /** An aggregate relationship */
  Wallets_aggregate: Wallets_Aggregate;
  /** An array relationship */
  access: Array<Flat_Context_Access>;
  /** An aggregate relationship */
  access_aggregate: Flat_Context_Access_Aggregate;
  /** An object relationship */
  account_validation?: Maybe<Account_Validation>;
  account_validation_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  analytics_events?: Maybe<Analytics_Events>;
  /** An array relationship */
  artwork_transactions_by_buyer_id: Array<Artwork_Transactions>;
  /** An aggregate relationship */
  artwork_transactions_by_buyer_id_aggregate: Artwork_Transactions_Aggregate;
  /** An array relationship */
  artwork_transactions_by_seller_id: Array<Artwork_Transactions>;
  /** An aggregate relationship */
  artwork_transactions_by_seller_id_aggregate: Artwork_Transactions_Aggregate;
  /** An array relationship */
  auth_context_accesses: Array<Auth_Context_Access>;
  /** An aggregate relationship */
  auth_context_accesses_aggregate: Auth_Context_Access_Aggregate;
  /** An object relationship */
  billing_client?: Maybe<Billing_Client>;
  /** An object relationship */
  billing_seller?: Maybe<Billing_Seller>;
  /** An array relationship */
  collaboration_by_artist_id: Array<Collaboration>;
  /** An aggregate relationship */
  collaboration_by_artist_id_aggregate: Collaboration_Aggregate;
  /** An array relationship */
  collaboration_by_gallery_id: Array<Collaboration>;
  /** An aggregate relationship */
  collaboration_by_gallery_id_aggregate: Collaboration_Aggregate;
  /** An array relationship */
  collections: Array<Collection>;
  /** An aggregate relationship */
  collections_aggregate: Collection_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  emails: Array<Emails>;
  /** An aggregate relationship */
  emails_aggregate: Emails_Aggregate;
  /** An object relationship */
  explore_view?: Maybe<Explore_Artists>;
  /** An array relationship */
  features: Array<Features>;
  /** An aggregate relationship */
  features_aggregate: Features_Aggregate;
  /** An array relationship */
  file_metadata_by_status_by_id: Array<File_Metadata>;
  /** An aggregate relationship */
  file_metadata_by_status_by_id_aggregate: File_Metadata_Aggregate;
  /** An array relationship */
  granted_access: Array<Context_Delegate_Access>;
  /** An aggregate relationship */
  granted_access_aggregate: Context_Delegate_Access_Aggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  interests: Array<Profile_Interests>;
  /** An aggregate relationship */
  interests_aggregate: Profile_Interests_Aggregate;
  /** An array relationship */
  invitations: Array<Profile_Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Profile_Invitations_Aggregate;
  latest_setup_step: Scalars['Int'];
  /** An array relationship */
  locations: Array<Profile_Location>;
  /** An aggregate relationship */
  locations_aggregate: Profile_Location_Aggregate;
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An array relationship */
  profile_achievements: Array<Profile_Achievements>;
  /** An aggregate relationship */
  profile_achievements_aggregate: Profile_Achievements_Aggregate;
  profile_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  profile_invitations_invitee: Array<Profile_Invitations>;
  /** An aggregate relationship */
  profile_invitations_invitee_aggregate: Profile_Invitations_Aggregate;
  /** An array relationship */
  profile_phones: Array<Profile_Phones>;
  /** An aggregate relationship */
  profile_phones_aggregate: Profile_Phones_Aggregate;
  /** An array relationship */
  profile_selected_price_ranges: Array<Profile_Price_Ranges>;
  /** An aggregate relationship */
  profile_selected_price_ranges_aggregate: Profile_Price_Ranges_Aggregate;
  /** An object relationship */
  profile_settings?: Maybe<Profile_Settings>;
  profile_settings_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  profile_social_links: Array<Profile_Social_Links>;
  /** An aggregate relationship */
  profile_social_links_aggregate: Profile_Social_Links_Aggregate;
  /** An array relationship */
  profile_video_links: Array<Profile_Video_Links>;
  /** An aggregate relationship */
  profile_video_links_aggregate: Profile_Video_Links_Aggregate;
  /** An array relationship */
  purchase_inquires: Array<Purchase_Inquires>;
  /** An aggregate relationship */
  purchase_inquires_aggregate: Purchase_Inquires_Aggregate;
  /** An array relationship */
  received_access: Array<Context_Delegate_Access>;
  /** An aggregate relationship */
  received_access_aggregate: Context_Delegate_Access_Aggregate;
  /** An array relationship */
  social_graph_context: Array<Social_Graph_Followers>;
  /** An aggregate relationship */
  social_graph_context_aggregate: Social_Graph_Followers_Aggregate;
  /** An array relationship */
  social_graph_target_context: Array<Social_Graph_Followers>;
  /** An aggregate relationship */
  social_graph_target_context_aggregate: Social_Graph_Followers_Aggregate;
  state: Enum_Context_State_Enum;
  /** An object relationship */
  test_account?: Maybe<Test_Accounts>;
  type: Enum_Context_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/** Main table, holds accounts and their roles. */
export type ContextArtist_Collection_To_Artist_ContextsArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtist_Collection_To_Artist_Contexts_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtist_NewsletterArgs = {
  distinct_on?: Maybe<Array<Artist_Newsletter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Newsletter_Order_By>>;
  where?: Maybe<Artist_Newsletter_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtist_Newsletter_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Newsletter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Newsletter_Order_By>>;
  where?: Maybe<Artist_Newsletter_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtwork_EventsArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtwork_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtworksArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextFcm_TokensArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextFcm_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextFile_MetadataArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextFile_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextWalletsArgs = {
  distinct_on?: Maybe<Array<Wallets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wallets_Order_By>>;
  where?: Maybe<Wallets_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextWallets_AggregateArgs = {
  distinct_on?: Maybe<Array<Wallets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wallets_Order_By>>;
  where?: Maybe<Wallets_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextAccessArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextAccess_AggregateArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtwork_Transactions_By_Buyer_IdArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtwork_Transactions_By_Buyer_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtwork_Transactions_By_Seller_IdArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextArtwork_Transactions_By_Seller_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextAuth_Context_AccessesArgs = {
  distinct_on?: Maybe<Array<Auth_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Context_Access_Order_By>>;
  where?: Maybe<Auth_Context_Access_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextAuth_Context_Accesses_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Context_Access_Order_By>>;
  where?: Maybe<Auth_Context_Access_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextCollaboration_By_Artist_IdArgs = {
  distinct_on?: Maybe<Array<Collaboration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Order_By>>;
  where?: Maybe<Collaboration_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextCollaboration_By_Artist_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<Collaboration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Order_By>>;
  where?: Maybe<Collaboration_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextCollaboration_By_Gallery_IdArgs = {
  distinct_on?: Maybe<Array<Collaboration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Order_By>>;
  where?: Maybe<Collaboration_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextCollaboration_By_Gallery_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<Collaboration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Order_By>>;
  where?: Maybe<Collaboration_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextCollectionsArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextCollections_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextEmailsArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextEmails_AggregateArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextFeaturesArgs = {
  distinct_on?: Maybe<Array<Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Features_Order_By>>;
  where?: Maybe<Features_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextFeatures_AggregateArgs = {
  distinct_on?: Maybe<Array<Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Features_Order_By>>;
  where?: Maybe<Features_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextFile_Metadata_By_Status_By_IdArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextFile_Metadata_By_Status_By_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextGranted_AccessArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextGranted_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextInterestsArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_Order_By>>;
  where?: Maybe<Profile_Interests_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_Order_By>>;
  where?: Maybe<Profile_Interests_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextInvitationsArgs = {
  distinct_on?: Maybe<Array<Profile_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Invitations_Order_By>>;
  where?: Maybe<Profile_Invitations_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextInvitations_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Invitations_Order_By>>;
  where?: Maybe<Profile_Invitations_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextLocationsArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Order_By>>;
  where?: Maybe<Profile_Location_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Order_By>>;
  where?: Maybe<Profile_Location_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_AchievementsArgs = {
  distinct_on?: Maybe<Array<Profile_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Achievements_Order_By>>;
  where?: Maybe<Profile_Achievements_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Achievements_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Achievements_Order_By>>;
  where?: Maybe<Profile_Achievements_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Invitations_InviteeArgs = {
  distinct_on?: Maybe<Array<Profile_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Invitations_Order_By>>;
  where?: Maybe<Profile_Invitations_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Invitations_Invitee_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Invitations_Order_By>>;
  where?: Maybe<Profile_Invitations_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_PhonesArgs = {
  distinct_on?: Maybe<Array<Profile_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Phones_Order_By>>;
  where?: Maybe<Profile_Phones_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Phones_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Phones_Order_By>>;
  where?: Maybe<Profile_Phones_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Selected_Price_RangesArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Selected_Price_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Social_LinksArgs = {
  distinct_on?: Maybe<Array<Profile_Social_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Social_Links_Order_By>>;
  where?: Maybe<Profile_Social_Links_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Social_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Social_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Social_Links_Order_By>>;
  where?: Maybe<Profile_Social_Links_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Video_LinksArgs = {
  distinct_on?: Maybe<Array<Profile_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Video_Links_Order_By>>;
  where?: Maybe<Profile_Video_Links_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextProfile_Video_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Video_Links_Order_By>>;
  where?: Maybe<Profile_Video_Links_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextPurchase_InquiresArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextPurchase_Inquires_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextReceived_AccessArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextReceived_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextSocial_Graph_ContextArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextSocial_Graph_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextSocial_Graph_Target_ContextArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


/** Main table, holds accounts and their roles. */
export type ContextSocial_Graph_Target_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};

/** aggregated selection of "Context" */
export type Context_Aggregate = {
  __typename?: 'Context_aggregate';
  aggregate?: Maybe<Context_Aggregate_Fields>;
  nodes: Array<Context>;
};

/** aggregate fields of "Context" */
export type Context_Aggregate_Fields = {
  __typename?: 'Context_aggregate_fields';
  avg?: Maybe<Context_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Context_Max_Fields>;
  min?: Maybe<Context_Min_Fields>;
  stddev?: Maybe<Context_Stddev_Fields>;
  stddev_pop?: Maybe<Context_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Context_Stddev_Samp_Fields>;
  sum?: Maybe<Context_Sum_Fields>;
  var_pop?: Maybe<Context_Var_Pop_Fields>;
  var_samp?: Maybe<Context_Var_Samp_Fields>;
  variance?: Maybe<Context_Variance_Fields>;
};


/** aggregate fields of "Context" */
export type Context_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Context_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Context" */
export type Context_Aggregate_Order_By = {
  avg?: Maybe<Context_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Context_Max_Order_By>;
  min?: Maybe<Context_Min_Order_By>;
  stddev?: Maybe<Context_Stddev_Order_By>;
  stddev_pop?: Maybe<Context_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Context_Stddev_Samp_Order_By>;
  sum?: Maybe<Context_Sum_Order_By>;
  var_pop?: Maybe<Context_Var_Pop_Order_By>;
  var_samp?: Maybe<Context_Var_Samp_Order_By>;
  variance?: Maybe<Context_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Context" */
export type Context_Arr_Rel_Insert_Input = {
  data: Array<Context_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Context_On_Conflict>;
};

/** aggregate avg on columns */
export type Context_Avg_Fields = {
  __typename?: 'Context_avg_fields';
  account_validation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latest_setup_step?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  profile_settings_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Context" */
export type Context_Avg_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Context". All fields are combined with a logical 'AND'. */
export type Context_Bool_Exp = {
  Artist_Collection_to_Artist_Contexts?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
  Artist_newsletter?: Maybe<Artist_Newsletter_Bool_Exp>;
  Artwork_events?: Maybe<Artwork_Events_Bool_Exp>;
  Artworks?: Maybe<Artwork_Bool_Exp>;
  ENUM_context_state?: Maybe<Enum_Context_State_Bool_Exp>;
  ENUM_context_type?: Maybe<Enum_Context_Type_Bool_Exp>;
  FCM_Tokens?: Maybe<Fcm_Token_Bool_Exp>;
  File_metadata?: Maybe<File_Metadata_Bool_Exp>;
  Wallets?: Maybe<Wallets_Bool_Exp>;
  _and?: Maybe<Array<Context_Bool_Exp>>;
  _not?: Maybe<Context_Bool_Exp>;
  _or?: Maybe<Array<Context_Bool_Exp>>;
  access?: Maybe<Flat_Context_Access_Bool_Exp>;
  account_validation?: Maybe<Account_Validation_Bool_Exp>;
  account_validation_id?: Maybe<Bigint_Comparison_Exp>;
  analytics_events?: Maybe<Analytics_Events_Bool_Exp>;
  artwork_transactions_by_buyer_id?: Maybe<Artwork_Transactions_Bool_Exp>;
  artwork_transactions_by_seller_id?: Maybe<Artwork_Transactions_Bool_Exp>;
  auth_context_accesses?: Maybe<Auth_Context_Access_Bool_Exp>;
  billing_client?: Maybe<Billing_Client_Bool_Exp>;
  billing_seller?: Maybe<Billing_Seller_Bool_Exp>;
  collaboration_by_artist_id?: Maybe<Collaboration_Bool_Exp>;
  collaboration_by_gallery_id?: Maybe<Collaboration_Bool_Exp>;
  collections?: Maybe<Collection_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  emails?: Maybe<Emails_Bool_Exp>;
  explore_view?: Maybe<Explore_Artists_Bool_Exp>;
  features?: Maybe<Features_Bool_Exp>;
  file_metadata_by_status_by_id?: Maybe<File_Metadata_Bool_Exp>;
  granted_access?: Maybe<Context_Delegate_Access_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  interests?: Maybe<Profile_Interests_Bool_Exp>;
  invitations?: Maybe<Profile_Invitations_Bool_Exp>;
  latest_setup_step?: Maybe<Int_Comparison_Exp>;
  locations?: Maybe<Profile_Location_Bool_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profile_achievements?: Maybe<Profile_Achievements_Bool_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  profile_invitations_invitee?: Maybe<Profile_Invitations_Bool_Exp>;
  profile_phones?: Maybe<Profile_Phones_Bool_Exp>;
  profile_selected_price_ranges?: Maybe<Profile_Price_Ranges_Bool_Exp>;
  profile_settings?: Maybe<Profile_Settings_Bool_Exp>;
  profile_settings_id?: Maybe<Bigint_Comparison_Exp>;
  profile_social_links?: Maybe<Profile_Social_Links_Bool_Exp>;
  profile_video_links?: Maybe<Profile_Video_Links_Bool_Exp>;
  purchase_inquires?: Maybe<Purchase_Inquires_Bool_Exp>;
  received_access?: Maybe<Context_Delegate_Access_Bool_Exp>;
  social_graph_context?: Maybe<Social_Graph_Followers_Bool_Exp>;
  social_graph_target_context?: Maybe<Social_Graph_Followers_Bool_Exp>;
  state?: Maybe<Enum_Context_State_Enum_Comparison_Exp>;
  test_account?: Maybe<Test_Accounts_Bool_Exp>;
  type?: Maybe<Enum_Context_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Context" */
export enum Context_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContextPkey = 'Context_pkey',
  /** unique or primary key constraint on columns "profile_id" */
  ContextProfileIdKey = 'Context_profile_id_key',
  /** unique or primary key constraint on columns "profile_settings_id" */
  ContextProfileSettingsIdKey = 'Context_profile_settings_id_key'
}

/** An account can delegate access to another account or give certain roles */
export type Context_Delegate_Access = {
  __typename?: 'Context_delegate_access';
  /** An object relationship */
  ENUM_context_delegate_role: Enum_Context_Delegate_Role;
  /** An object relationship */
  ENUM_context_delegate_state: Enum_Context_Delegate_State;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  granter: Context;
  granter_id: Scalars['bigint'];
  id: Scalars['bigint'];
  inheritance_depth: Scalars['Int'];
  /** An object relationship */
  receiver: Context;
  receiver_id: Scalars['bigint'];
  role: Enum_Context_Delegate_Role_Enum;
  state: Enum_Context_Delegate_State_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Context_delegate_access" */
export type Context_Delegate_Access_Aggregate = {
  __typename?: 'Context_delegate_access_aggregate';
  aggregate?: Maybe<Context_Delegate_Access_Aggregate_Fields>;
  nodes: Array<Context_Delegate_Access>;
};

/** aggregate fields of "Context_delegate_access" */
export type Context_Delegate_Access_Aggregate_Fields = {
  __typename?: 'Context_delegate_access_aggregate_fields';
  avg?: Maybe<Context_Delegate_Access_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Context_Delegate_Access_Max_Fields>;
  min?: Maybe<Context_Delegate_Access_Min_Fields>;
  stddev?: Maybe<Context_Delegate_Access_Stddev_Fields>;
  stddev_pop?: Maybe<Context_Delegate_Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Context_Delegate_Access_Stddev_Samp_Fields>;
  sum?: Maybe<Context_Delegate_Access_Sum_Fields>;
  var_pop?: Maybe<Context_Delegate_Access_Var_Pop_Fields>;
  var_samp?: Maybe<Context_Delegate_Access_Var_Samp_Fields>;
  variance?: Maybe<Context_Delegate_Access_Variance_Fields>;
};


/** aggregate fields of "Context_delegate_access" */
export type Context_Delegate_Access_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Context_delegate_access" */
export type Context_Delegate_Access_Aggregate_Order_By = {
  avg?: Maybe<Context_Delegate_Access_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Context_Delegate_Access_Max_Order_By>;
  min?: Maybe<Context_Delegate_Access_Min_Order_By>;
  stddev?: Maybe<Context_Delegate_Access_Stddev_Order_By>;
  stddev_pop?: Maybe<Context_Delegate_Access_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Context_Delegate_Access_Stddev_Samp_Order_By>;
  sum?: Maybe<Context_Delegate_Access_Sum_Order_By>;
  var_pop?: Maybe<Context_Delegate_Access_Var_Pop_Order_By>;
  var_samp?: Maybe<Context_Delegate_Access_Var_Samp_Order_By>;
  variance?: Maybe<Context_Delegate_Access_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Context_delegate_access" */
export type Context_Delegate_Access_Arr_Rel_Insert_Input = {
  data: Array<Context_Delegate_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Context_Delegate_Access_On_Conflict>;
};

/** aggregate avg on columns */
export type Context_Delegate_Access_Avg_Fields = {
  __typename?: 'Context_delegate_access_avg_fields';
  granter_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inheritance_depth?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Avg_Order_By = {
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Context_delegate_access". All fields are combined with a logical 'AND'. */
export type Context_Delegate_Access_Bool_Exp = {
  ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role_Bool_Exp>;
  ENUM_context_delegate_state?: Maybe<Enum_Context_Delegate_State_Bool_Exp>;
  _and?: Maybe<Array<Context_Delegate_Access_Bool_Exp>>;
  _not?: Maybe<Context_Delegate_Access_Bool_Exp>;
  _or?: Maybe<Array<Context_Delegate_Access_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  granter?: Maybe<Context_Bool_Exp>;
  granter_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  inheritance_depth?: Maybe<Int_Comparison_Exp>;
  receiver?: Maybe<Context_Bool_Exp>;
  receiver_id?: Maybe<Bigint_Comparison_Exp>;
  role?: Maybe<Enum_Context_Delegate_Role_Enum_Comparison_Exp>;
  state?: Maybe<Enum_Context_Delegate_State_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Context_delegate_access" */
export enum Context_Delegate_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  DelegateContextAccessPkey = 'Delegate_context_access_pkey'
}

/** input type for incrementing numeric columns in table "Context_delegate_access" */
export type Context_Delegate_Access_Inc_Input = {
  granter_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inheritance_depth?: Maybe<Scalars['Int']>;
  receiver_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Context_delegate_access" */
export type Context_Delegate_Access_Insert_Input = {
  ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role_Obj_Rel_Insert_Input>;
  ENUM_context_delegate_state?: Maybe<Enum_Context_Delegate_State_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  granter?: Maybe<Context_Obj_Rel_Insert_Input>;
  granter_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inheritance_depth?: Maybe<Scalars['Int']>;
  receiver?: Maybe<Context_Obj_Rel_Insert_Input>;
  receiver_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Enum_Context_Delegate_Role_Enum>;
  state?: Maybe<Enum_Context_Delegate_State_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Context_Delegate_Access_Max_Fields = {
  __typename?: 'Context_delegate_access_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  granter_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inheritance_depth?: Maybe<Scalars['Int']>;
  receiver_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Context_Delegate_Access_Min_Fields = {
  __typename?: 'Context_delegate_access_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  granter_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inheritance_depth?: Maybe<Scalars['Int']>;
  receiver_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Context_delegate_access" */
export type Context_Delegate_Access_Mutation_Response = {
  __typename?: 'Context_delegate_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Context_Delegate_Access>;
};

/** on_conflict condition type for table "Context_delegate_access" */
export type Context_Delegate_Access_On_Conflict = {
  constraint: Context_Delegate_Access_Constraint;
  update_columns?: Array<Context_Delegate_Access_Update_Column>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "Context_delegate_access". */
export type Context_Delegate_Access_Order_By = {
  ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role_Order_By>;
  ENUM_context_delegate_state?: Maybe<Enum_Context_Delegate_State_Order_By>;
  created_at?: Maybe<Order_By>;
  granter?: Maybe<Context_Order_By>;
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver?: Maybe<Context_Order_By>;
  receiver_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Context_delegate_access */
export type Context_Delegate_Access_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Context_delegate_access" */
export enum Context_Delegate_Access_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GranterId = 'granter_id',
  /** column name */
  Id = 'id',
  /** column name */
  InheritanceDepth = 'inheritance_depth',
  /** column name */
  ReceiverId = 'receiver_id',
  /** column name */
  Role = 'role',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Context_delegate_access" */
export type Context_Delegate_Access_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  granter_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inheritance_depth?: Maybe<Scalars['Int']>;
  receiver_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Enum_Context_Delegate_Role_Enum>;
  state?: Maybe<Enum_Context_Delegate_State_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Context_Delegate_Access_Stddev_Fields = {
  __typename?: 'Context_delegate_access_stddev_fields';
  granter_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inheritance_depth?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Stddev_Order_By = {
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Context_Delegate_Access_Stddev_Pop_Fields = {
  __typename?: 'Context_delegate_access_stddev_pop_fields';
  granter_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inheritance_depth?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Stddev_Pop_Order_By = {
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Context_Delegate_Access_Stddev_Samp_Fields = {
  __typename?: 'Context_delegate_access_stddev_samp_fields';
  granter_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inheritance_depth?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Stddev_Samp_Order_By = {
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Context_Delegate_Access_Sum_Fields = {
  __typename?: 'Context_delegate_access_sum_fields';
  granter_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inheritance_depth?: Maybe<Scalars['Int']>;
  receiver_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Sum_Order_By = {
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** update columns of table "Context_delegate_access" */
export enum Context_Delegate_Access_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GranterId = 'granter_id',
  /** column name */
  Id = 'id',
  /** column name */
  InheritanceDepth = 'inheritance_depth',
  /** column name */
  ReceiverId = 'receiver_id',
  /** column name */
  Role = 'role',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Context_Delegate_Access_Var_Pop_Fields = {
  __typename?: 'Context_delegate_access_var_pop_fields';
  granter_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inheritance_depth?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Var_Pop_Order_By = {
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Context_Delegate_Access_Var_Samp_Fields = {
  __typename?: 'Context_delegate_access_var_samp_fields';
  granter_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inheritance_depth?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Var_Samp_Order_By = {
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Context_Delegate_Access_Variance_Fields = {
  __typename?: 'Context_delegate_access_variance_fields';
  granter_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inheritance_depth?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Context_delegate_access" */
export type Context_Delegate_Access_Variance_Order_By = {
  granter_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inheritance_depth?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** Handles the flags needed by the user context */
export type Context_Flags = {
  __typename?: 'Context_flags';
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  flag_type: Enum_Flags_Type_Enum;
  flag_value: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Context_flags" */
export type Context_Flags_Aggregate = {
  __typename?: 'Context_flags_aggregate';
  aggregate?: Maybe<Context_Flags_Aggregate_Fields>;
  nodes: Array<Context_Flags>;
};

/** aggregate fields of "Context_flags" */
export type Context_Flags_Aggregate_Fields = {
  __typename?: 'Context_flags_aggregate_fields';
  avg?: Maybe<Context_Flags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Context_Flags_Max_Fields>;
  min?: Maybe<Context_Flags_Min_Fields>;
  stddev?: Maybe<Context_Flags_Stddev_Fields>;
  stddev_pop?: Maybe<Context_Flags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Context_Flags_Stddev_Samp_Fields>;
  sum?: Maybe<Context_Flags_Sum_Fields>;
  var_pop?: Maybe<Context_Flags_Var_Pop_Fields>;
  var_samp?: Maybe<Context_Flags_Var_Samp_Fields>;
  variance?: Maybe<Context_Flags_Variance_Fields>;
};


/** aggregate fields of "Context_flags" */
export type Context_Flags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Context_Flags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Context_Flags_Avg_Fields = {
  __typename?: 'Context_flags_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Context_flags". All fields are combined with a logical 'AND'. */
export type Context_Flags_Bool_Exp = {
  _and?: Maybe<Array<Context_Flags_Bool_Exp>>;
  _not?: Maybe<Context_Flags_Bool_Exp>;
  _or?: Maybe<Array<Context_Flags_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  flag_type?: Maybe<Enum_Flags_Type_Enum_Comparison_Exp>;
  flag_value?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Context_flags" */
export enum Context_Flags_Constraint {
  /** unique or primary key constraint on columns "flag_type", "context_id" */
  ContextFlagsPkey = 'Context_flags_pkey'
}

/** input type for incrementing numeric columns in table "Context_flags" */
export type Context_Flags_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Context_flags" */
export type Context_Flags_Insert_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_type?: Maybe<Enum_Flags_Type_Enum>;
  flag_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Context_Flags_Max_Fields = {
  __typename?: 'Context_flags_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Context_Flags_Min_Fields = {
  __typename?: 'Context_flags_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Context_flags" */
export type Context_Flags_Mutation_Response = {
  __typename?: 'Context_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Context_Flags>;
};

/** on_conflict condition type for table "Context_flags" */
export type Context_Flags_On_Conflict = {
  constraint: Context_Flags_Constraint;
  update_columns?: Array<Context_Flags_Update_Column>;
  where?: Maybe<Context_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "Context_flags". */
export type Context_Flags_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  flag_type?: Maybe<Order_By>;
  flag_value?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Context_flags */
export type Context_Flags_Pk_Columns_Input = {
  context_id: Scalars['bigint'];
  flag_type: Enum_Flags_Type_Enum;
};

/** select columns of table "Context_flags" */
export enum Context_Flags_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagType = 'flag_type',
  /** column name */
  FlagValue = 'flag_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Context_flags" */
export type Context_Flags_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_type?: Maybe<Enum_Flags_Type_Enum>;
  flag_value?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Context_Flags_Stddev_Fields = {
  __typename?: 'Context_flags_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Context_Flags_Stddev_Pop_Fields = {
  __typename?: 'Context_flags_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Context_Flags_Stddev_Samp_Fields = {
  __typename?: 'Context_flags_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Context_Flags_Sum_Fields = {
  __typename?: 'Context_flags_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Context_flags" */
export enum Context_Flags_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagType = 'flag_type',
  /** column name */
  FlagValue = 'flag_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Context_Flags_Var_Pop_Fields = {
  __typename?: 'Context_flags_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Context_Flags_Var_Samp_Fields = {
  __typename?: 'Context_flags_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Context_Flags_Variance_Fields = {
  __typename?: 'Context_flags_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "Context" */
export type Context_Inc_Input = {
  account_validation_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  latest_setup_step?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_settings_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Context" */
export type Context_Insert_Input = {
  Artist_Collection_to_Artist_Contexts?: Maybe<Artist_Collection_To_Artist_Context_Arr_Rel_Insert_Input>;
  Artist_newsletter?: Maybe<Artist_Newsletter_Arr_Rel_Insert_Input>;
  Artwork_events?: Maybe<Artwork_Events_Arr_Rel_Insert_Input>;
  Artworks?: Maybe<Artwork_Arr_Rel_Insert_Input>;
  ENUM_context_state?: Maybe<Enum_Context_State_Obj_Rel_Insert_Input>;
  ENUM_context_type?: Maybe<Enum_Context_Type_Obj_Rel_Insert_Input>;
  FCM_Tokens?: Maybe<Fcm_Token_Arr_Rel_Insert_Input>;
  File_metadata?: Maybe<File_Metadata_Arr_Rel_Insert_Input>;
  Wallets?: Maybe<Wallets_Arr_Rel_Insert_Input>;
  access?: Maybe<Flat_Context_Access_Arr_Rel_Insert_Input>;
  account_validation?: Maybe<Account_Validation_Obj_Rel_Insert_Input>;
  account_validation_id?: Maybe<Scalars['bigint']>;
  analytics_events?: Maybe<Analytics_Events_Obj_Rel_Insert_Input>;
  artwork_transactions_by_buyer_id?: Maybe<Artwork_Transactions_Arr_Rel_Insert_Input>;
  artwork_transactions_by_seller_id?: Maybe<Artwork_Transactions_Arr_Rel_Insert_Input>;
  auth_context_accesses?: Maybe<Auth_Context_Access_Arr_Rel_Insert_Input>;
  billing_client?: Maybe<Billing_Client_Obj_Rel_Insert_Input>;
  billing_seller?: Maybe<Billing_Seller_Obj_Rel_Insert_Input>;
  collaboration_by_artist_id?: Maybe<Collaboration_Arr_Rel_Insert_Input>;
  collaboration_by_gallery_id?: Maybe<Collaboration_Arr_Rel_Insert_Input>;
  collections?: Maybe<Collection_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  emails?: Maybe<Emails_Arr_Rel_Insert_Input>;
  explore_view?: Maybe<Explore_Artists_Obj_Rel_Insert_Input>;
  features?: Maybe<Features_Arr_Rel_Insert_Input>;
  file_metadata_by_status_by_id?: Maybe<File_Metadata_Arr_Rel_Insert_Input>;
  granted_access?: Maybe<Context_Delegate_Access_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  interests?: Maybe<Profile_Interests_Arr_Rel_Insert_Input>;
  invitations?: Maybe<Profile_Invitations_Arr_Rel_Insert_Input>;
  latest_setup_step?: Maybe<Scalars['Int']>;
  locations?: Maybe<Profile_Location_Arr_Rel_Insert_Input>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profile_achievements?: Maybe<Profile_Achievements_Arr_Rel_Insert_Input>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_invitations_invitee?: Maybe<Profile_Invitations_Arr_Rel_Insert_Input>;
  profile_phones?: Maybe<Profile_Phones_Arr_Rel_Insert_Input>;
  profile_selected_price_ranges?: Maybe<Profile_Price_Ranges_Arr_Rel_Insert_Input>;
  profile_settings?: Maybe<Profile_Settings_Obj_Rel_Insert_Input>;
  profile_settings_id?: Maybe<Scalars['bigint']>;
  profile_social_links?: Maybe<Profile_Social_Links_Arr_Rel_Insert_Input>;
  profile_video_links?: Maybe<Profile_Video_Links_Arr_Rel_Insert_Input>;
  purchase_inquires?: Maybe<Purchase_Inquires_Arr_Rel_Insert_Input>;
  received_access?: Maybe<Context_Delegate_Access_Arr_Rel_Insert_Input>;
  social_graph_context?: Maybe<Social_Graph_Followers_Arr_Rel_Insert_Input>;
  social_graph_target_context?: Maybe<Social_Graph_Followers_Arr_Rel_Insert_Input>;
  state?: Maybe<Enum_Context_State_Enum>;
  test_account?: Maybe<Test_Accounts_Obj_Rel_Insert_Input>;
  type?: Maybe<Enum_Context_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Context_Max_Fields = {
  __typename?: 'Context_max_fields';
  account_validation_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  latest_setup_step?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_settings_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Context" */
export type Context_Max_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Context_Min_Fields = {
  __typename?: 'Context_min_fields';
  account_validation_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  latest_setup_step?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_settings_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Context" */
export type Context_Min_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Context" */
export type Context_Mutation_Response = {
  __typename?: 'Context_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Context>;
};

/** input type for inserting object relation for remote table "Context" */
export type Context_Obj_Rel_Insert_Input = {
  data: Context_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Context_On_Conflict>;
};

/** on_conflict condition type for table "Context" */
export type Context_On_Conflict = {
  constraint: Context_Constraint;
  update_columns?: Array<Context_Update_Column>;
  where?: Maybe<Context_Bool_Exp>;
};

/** Ordering options when selecting data from "Context". */
export type Context_Order_By = {
  Artist_Collection_to_Artist_Contexts_aggregate?: Maybe<Artist_Collection_To_Artist_Context_Aggregate_Order_By>;
  Artist_newsletter_aggregate?: Maybe<Artist_Newsletter_Aggregate_Order_By>;
  Artwork_events_aggregate?: Maybe<Artwork_Events_Aggregate_Order_By>;
  Artworks_aggregate?: Maybe<Artwork_Aggregate_Order_By>;
  ENUM_context_state?: Maybe<Enum_Context_State_Order_By>;
  ENUM_context_type?: Maybe<Enum_Context_Type_Order_By>;
  FCM_Tokens_aggregate?: Maybe<Fcm_Token_Aggregate_Order_By>;
  File_metadata_aggregate?: Maybe<File_Metadata_Aggregate_Order_By>;
  Wallets_aggregate?: Maybe<Wallets_Aggregate_Order_By>;
  access_aggregate?: Maybe<Flat_Context_Access_Aggregate_Order_By>;
  account_validation?: Maybe<Account_Validation_Order_By>;
  account_validation_id?: Maybe<Order_By>;
  analytics_events?: Maybe<Analytics_Events_Order_By>;
  artwork_transactions_by_buyer_id_aggregate?: Maybe<Artwork_Transactions_Aggregate_Order_By>;
  artwork_transactions_by_seller_id_aggregate?: Maybe<Artwork_Transactions_Aggregate_Order_By>;
  auth_context_accesses_aggregate?: Maybe<Auth_Context_Access_Aggregate_Order_By>;
  billing_client?: Maybe<Billing_Client_Order_By>;
  billing_seller?: Maybe<Billing_Seller_Order_By>;
  collaboration_by_artist_id_aggregate?: Maybe<Collaboration_Aggregate_Order_By>;
  collaboration_by_gallery_id_aggregate?: Maybe<Collaboration_Aggregate_Order_By>;
  collections_aggregate?: Maybe<Collection_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  emails_aggregate?: Maybe<Emails_Aggregate_Order_By>;
  explore_view?: Maybe<Explore_Artists_Order_By>;
  features_aggregate?: Maybe<Features_Aggregate_Order_By>;
  file_metadata_by_status_by_id_aggregate?: Maybe<File_Metadata_Aggregate_Order_By>;
  granted_access_aggregate?: Maybe<Context_Delegate_Access_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  interests_aggregate?: Maybe<Profile_Interests_Aggregate_Order_By>;
  invitations_aggregate?: Maybe<Profile_Invitations_Aggregate_Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Profile_Location_Aggregate_Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profile_achievements_aggregate?: Maybe<Profile_Achievements_Aggregate_Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_invitations_invitee_aggregate?: Maybe<Profile_Invitations_Aggregate_Order_By>;
  profile_phones_aggregate?: Maybe<Profile_Phones_Aggregate_Order_By>;
  profile_selected_price_ranges_aggregate?: Maybe<Profile_Price_Ranges_Aggregate_Order_By>;
  profile_settings?: Maybe<Profile_Settings_Order_By>;
  profile_settings_id?: Maybe<Order_By>;
  profile_social_links_aggregate?: Maybe<Profile_Social_Links_Aggregate_Order_By>;
  profile_video_links_aggregate?: Maybe<Profile_Video_Links_Aggregate_Order_By>;
  purchase_inquires_aggregate?: Maybe<Purchase_Inquires_Aggregate_Order_By>;
  received_access_aggregate?: Maybe<Context_Delegate_Access_Aggregate_Order_By>;
  social_graph_context_aggregate?: Maybe<Social_Graph_Followers_Aggregate_Order_By>;
  social_graph_target_context_aggregate?: Maybe<Social_Graph_Followers_Aggregate_Order_By>;
  state?: Maybe<Order_By>;
  test_account?: Maybe<Test_Accounts_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Context */
export type Context_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Context" */
export enum Context_Select_Column {
  /** column name */
  AccountValidationId = 'account_validation_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestSetupStep = 'latest_setup_step',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ProfileSettingsId = 'profile_settings_id',
  /** column name */
  State = 'state',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Context" */
export type Context_Set_Input = {
  account_validation_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  latest_setup_step?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_settings_id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Enum_Context_State_Enum>;
  type?: Maybe<Enum_Context_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Context_Stddev_Fields = {
  __typename?: 'Context_stddev_fields';
  account_validation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latest_setup_step?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  profile_settings_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Context" */
export type Context_Stddev_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Context_Stddev_Pop_Fields = {
  __typename?: 'Context_stddev_pop_fields';
  account_validation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latest_setup_step?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  profile_settings_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Context" */
export type Context_Stddev_Pop_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Context_Stddev_Samp_Fields = {
  __typename?: 'Context_stddev_samp_fields';
  account_validation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latest_setup_step?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  profile_settings_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Context" */
export type Context_Stddev_Samp_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
};

/** Table to use in get suggested tags database functions */
export type Context_Suggested_Tags = {
  __typename?: 'Context_suggested_tags';
  context_id: Scalars['bigint'];
  id: Scalars['bigint'];
  last_usage_date: Scalars['timestamptz'];
  order_by_last_usage: Scalars['bigint'];
  order_by_usage_amount: Scalars['bigint'];
  tag: Scalars['String'];
  type: Scalars['String'];
  usage_amount: Scalars['bigint'];
};

/** aggregated selection of "Context_suggested_tags" */
export type Context_Suggested_Tags_Aggregate = {
  __typename?: 'Context_suggested_tags_aggregate';
  aggregate?: Maybe<Context_Suggested_Tags_Aggregate_Fields>;
  nodes: Array<Context_Suggested_Tags>;
};

/** aggregate fields of "Context_suggested_tags" */
export type Context_Suggested_Tags_Aggregate_Fields = {
  __typename?: 'Context_suggested_tags_aggregate_fields';
  avg?: Maybe<Context_Suggested_Tags_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Context_Suggested_Tags_Max_Fields>;
  min?: Maybe<Context_Suggested_Tags_Min_Fields>;
  stddev?: Maybe<Context_Suggested_Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Context_Suggested_Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Context_Suggested_Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Context_Suggested_Tags_Sum_Fields>;
  var_pop?: Maybe<Context_Suggested_Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Context_Suggested_Tags_Var_Samp_Fields>;
  variance?: Maybe<Context_Suggested_Tags_Variance_Fields>;
};


/** aggregate fields of "Context_suggested_tags" */
export type Context_Suggested_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Context_Suggested_Tags_Avg_Fields = {
  __typename?: 'Context_suggested_tags_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_by_last_usage?: Maybe<Scalars['Float']>;
  order_by_usage_amount?: Maybe<Scalars['Float']>;
  usage_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Context_suggested_tags". All fields are combined with a logical 'AND'. */
export type Context_Suggested_Tags_Bool_Exp = {
  _and?: Maybe<Array<Context_Suggested_Tags_Bool_Exp>>;
  _not?: Maybe<Context_Suggested_Tags_Bool_Exp>;
  _or?: Maybe<Array<Context_Suggested_Tags_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_usage_date?: Maybe<Timestamptz_Comparison_Exp>;
  order_by_last_usage?: Maybe<Bigint_Comparison_Exp>;
  order_by_usage_amount?: Maybe<Bigint_Comparison_Exp>;
  tag?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  usage_amount?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Context_suggested_tags" */
export enum Context_Suggested_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContextSuggestedTagsPkey = 'Context_suggested_tags_pkey'
}

/** input type for incrementing numeric columns in table "Context_suggested_tags" */
export type Context_Suggested_Tags_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order_by_last_usage?: Maybe<Scalars['bigint']>;
  order_by_usage_amount?: Maybe<Scalars['bigint']>;
  usage_amount?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Context_suggested_tags" */
export type Context_Suggested_Tags_Insert_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_usage_date?: Maybe<Scalars['timestamptz']>;
  order_by_last_usage?: Maybe<Scalars['bigint']>;
  order_by_usage_amount?: Maybe<Scalars['bigint']>;
  tag?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  usage_amount?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Context_Suggested_Tags_Max_Fields = {
  __typename?: 'Context_suggested_tags_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_usage_date?: Maybe<Scalars['timestamptz']>;
  order_by_last_usage?: Maybe<Scalars['bigint']>;
  order_by_usage_amount?: Maybe<Scalars['bigint']>;
  tag?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  usage_amount?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Context_Suggested_Tags_Min_Fields = {
  __typename?: 'Context_suggested_tags_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_usage_date?: Maybe<Scalars['timestamptz']>;
  order_by_last_usage?: Maybe<Scalars['bigint']>;
  order_by_usage_amount?: Maybe<Scalars['bigint']>;
  tag?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  usage_amount?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Context_suggested_tags" */
export type Context_Suggested_Tags_Mutation_Response = {
  __typename?: 'Context_suggested_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Context_Suggested_Tags>;
};

/** on_conflict condition type for table "Context_suggested_tags" */
export type Context_Suggested_Tags_On_Conflict = {
  constraint: Context_Suggested_Tags_Constraint;
  update_columns?: Array<Context_Suggested_Tags_Update_Column>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "Context_suggested_tags". */
export type Context_Suggested_Tags_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_usage_date?: Maybe<Order_By>;
  order_by_last_usage?: Maybe<Order_By>;
  order_by_usage_amount?: Maybe<Order_By>;
  tag?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  usage_amount?: Maybe<Order_By>;
};

/** primary key columns input for table: Context_suggested_tags */
export type Context_Suggested_Tags_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Context_suggested_tags" */
export enum Context_Suggested_Tags_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastUsageDate = 'last_usage_date',
  /** column name */
  OrderByLastUsage = 'order_by_last_usage',
  /** column name */
  OrderByUsageAmount = 'order_by_usage_amount',
  /** column name */
  Tag = 'tag',
  /** column name */
  Type = 'type',
  /** column name */
  UsageAmount = 'usage_amount'
}

/** input type for updating data in table "Context_suggested_tags" */
export type Context_Suggested_Tags_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_usage_date?: Maybe<Scalars['timestamptz']>;
  order_by_last_usage?: Maybe<Scalars['bigint']>;
  order_by_usage_amount?: Maybe<Scalars['bigint']>;
  tag?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  usage_amount?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Context_Suggested_Tags_Stddev_Fields = {
  __typename?: 'Context_suggested_tags_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_by_last_usage?: Maybe<Scalars['Float']>;
  order_by_usage_amount?: Maybe<Scalars['Float']>;
  usage_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Context_Suggested_Tags_Stddev_Pop_Fields = {
  __typename?: 'Context_suggested_tags_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_by_last_usage?: Maybe<Scalars['Float']>;
  order_by_usage_amount?: Maybe<Scalars['Float']>;
  usage_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Context_Suggested_Tags_Stddev_Samp_Fields = {
  __typename?: 'Context_suggested_tags_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_by_last_usage?: Maybe<Scalars['Float']>;
  order_by_usage_amount?: Maybe<Scalars['Float']>;
  usage_amount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Context_Suggested_Tags_Sum_Fields = {
  __typename?: 'Context_suggested_tags_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order_by_last_usage?: Maybe<Scalars['bigint']>;
  order_by_usage_amount?: Maybe<Scalars['bigint']>;
  usage_amount?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Context_suggested_tags" */
export enum Context_Suggested_Tags_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastUsageDate = 'last_usage_date',
  /** column name */
  OrderByLastUsage = 'order_by_last_usage',
  /** column name */
  OrderByUsageAmount = 'order_by_usage_amount',
  /** column name */
  Tag = 'tag',
  /** column name */
  Type = 'type',
  /** column name */
  UsageAmount = 'usage_amount'
}

/** aggregate var_pop on columns */
export type Context_Suggested_Tags_Var_Pop_Fields = {
  __typename?: 'Context_suggested_tags_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_by_last_usage?: Maybe<Scalars['Float']>;
  order_by_usage_amount?: Maybe<Scalars['Float']>;
  usage_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Context_Suggested_Tags_Var_Samp_Fields = {
  __typename?: 'Context_suggested_tags_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_by_last_usage?: Maybe<Scalars['Float']>;
  order_by_usage_amount?: Maybe<Scalars['Float']>;
  usage_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Context_Suggested_Tags_Variance_Fields = {
  __typename?: 'Context_suggested_tags_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order_by_last_usage?: Maybe<Scalars['Float']>;
  order_by_usage_amount?: Maybe<Scalars['Float']>;
  usage_amount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Context_Sum_Fields = {
  __typename?: 'Context_sum_fields';
  account_validation_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  latest_setup_step?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_settings_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Context" */
export type Context_Sum_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
};

/** columns and relationships of "Context_to_milestone" */
export type Context_To_Milestone = {
  __typename?: 'Context_to_milestone';
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  milestone: Milestone;
  milestone_id: Scalars['bigint'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Context_to_milestone" */
export type Context_To_Milestone_Aggregate = {
  __typename?: 'Context_to_milestone_aggregate';
  aggregate?: Maybe<Context_To_Milestone_Aggregate_Fields>;
  nodes: Array<Context_To_Milestone>;
};

/** aggregate fields of "Context_to_milestone" */
export type Context_To_Milestone_Aggregate_Fields = {
  __typename?: 'Context_to_milestone_aggregate_fields';
  avg?: Maybe<Context_To_Milestone_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Context_To_Milestone_Max_Fields>;
  min?: Maybe<Context_To_Milestone_Min_Fields>;
  stddev?: Maybe<Context_To_Milestone_Stddev_Fields>;
  stddev_pop?: Maybe<Context_To_Milestone_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Context_To_Milestone_Stddev_Samp_Fields>;
  sum?: Maybe<Context_To_Milestone_Sum_Fields>;
  var_pop?: Maybe<Context_To_Milestone_Var_Pop_Fields>;
  var_samp?: Maybe<Context_To_Milestone_Var_Samp_Fields>;
  variance?: Maybe<Context_To_Milestone_Variance_Fields>;
};


/** aggregate fields of "Context_to_milestone" */
export type Context_To_Milestone_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Context_To_Milestone_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Context_to_milestone" */
export type Context_To_Milestone_Aggregate_Order_By = {
  avg?: Maybe<Context_To_Milestone_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Context_To_Milestone_Max_Order_By>;
  min?: Maybe<Context_To_Milestone_Min_Order_By>;
  stddev?: Maybe<Context_To_Milestone_Stddev_Order_By>;
  stddev_pop?: Maybe<Context_To_Milestone_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Context_To_Milestone_Stddev_Samp_Order_By>;
  sum?: Maybe<Context_To_Milestone_Sum_Order_By>;
  var_pop?: Maybe<Context_To_Milestone_Var_Pop_Order_By>;
  var_samp?: Maybe<Context_To_Milestone_Var_Samp_Order_By>;
  variance?: Maybe<Context_To_Milestone_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Context_to_milestone" */
export type Context_To_Milestone_Arr_Rel_Insert_Input = {
  data: Array<Context_To_Milestone_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Context_To_Milestone_On_Conflict>;
};

/** aggregate avg on columns */
export type Context_To_Milestone_Avg_Fields = {
  __typename?: 'Context_to_milestone_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  milestone_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Context_to_milestone". All fields are combined with a logical 'AND'. */
export type Context_To_Milestone_Bool_Exp = {
  _and?: Maybe<Array<Context_To_Milestone_Bool_Exp>>;
  _not?: Maybe<Context_To_Milestone_Bool_Exp>;
  _or?: Maybe<Array<Context_To_Milestone_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  milestone?: Maybe<Milestone_Bool_Exp>;
  milestone_id?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Context_to_milestone" */
export enum Context_To_Milestone_Constraint {
  /** unique or primary key constraint on columns "context_id", "milestone_id" */
  ContextToMilestoneIdx = 'Context_to_milestone_idx',
  /** unique or primary key constraint on columns "id" */
  ContextToMilestonePkey = 'Context_to_milestone_pkey'
}

/** input type for incrementing numeric columns in table "Context_to_milestone" */
export type Context_To_Milestone_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  milestone_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Context_to_milestone" */
export type Context_To_Milestone_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  milestone?: Maybe<Milestone_Obj_Rel_Insert_Input>;
  milestone_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Context_To_Milestone_Max_Fields = {
  __typename?: 'Context_to_milestone_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  milestone_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Context_To_Milestone_Min_Fields = {
  __typename?: 'Context_to_milestone_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  milestone_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Context_to_milestone" */
export type Context_To_Milestone_Mutation_Response = {
  __typename?: 'Context_to_milestone_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Context_To_Milestone>;
};

/** on_conflict condition type for table "Context_to_milestone" */
export type Context_To_Milestone_On_Conflict = {
  constraint: Context_To_Milestone_Constraint;
  update_columns?: Array<Context_To_Milestone_Update_Column>;
  where?: Maybe<Context_To_Milestone_Bool_Exp>;
};

/** Ordering options when selecting data from "Context_to_milestone". */
export type Context_To_Milestone_Order_By = {
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone?: Maybe<Milestone_Order_By>;
  milestone_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Context_to_milestone */
export type Context_To_Milestone_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Context_to_milestone" */
export enum Context_To_Milestone_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MilestoneId = 'milestone_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Context_to_milestone" */
export type Context_To_Milestone_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  milestone_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Context_To_Milestone_Stddev_Fields = {
  __typename?: 'Context_to_milestone_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  milestone_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Context_To_Milestone_Stddev_Pop_Fields = {
  __typename?: 'Context_to_milestone_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  milestone_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Context_To_Milestone_Stddev_Samp_Fields = {
  __typename?: 'Context_to_milestone_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  milestone_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Context_To_Milestone_Sum_Fields = {
  __typename?: 'Context_to_milestone_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  milestone_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
};

/** update columns of table "Context_to_milestone" */
export enum Context_To_Milestone_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MilestoneId = 'milestone_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Context_To_Milestone_Var_Pop_Fields = {
  __typename?: 'Context_to_milestone_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  milestone_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Context_To_Milestone_Var_Samp_Fields = {
  __typename?: 'Context_to_milestone_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  milestone_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Context_To_Milestone_Variance_Fields = {
  __typename?: 'Context_to_milestone_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  milestone_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Context_to_milestone" */
export type Context_To_Milestone_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  milestone_id?: Maybe<Order_By>;
};

/** columns and relationships of "Context_to_tutorial" */
export type Context_To_Tutorial = {
  __typename?: 'Context_to_tutorial';
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  current_step?: Maybe<Enum_Tutorial_Step_Type_Enum>;
  id: Scalars['bigint'];
  status: Enum_Tutorial_Status_Enum;
  /** An object relationship */
  tutorial: Tutorial;
  tutorial_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Context_to_tutorial" */
export type Context_To_Tutorial_Aggregate = {
  __typename?: 'Context_to_tutorial_aggregate';
  aggregate?: Maybe<Context_To_Tutorial_Aggregate_Fields>;
  nodes: Array<Context_To_Tutorial>;
};

/** aggregate fields of "Context_to_tutorial" */
export type Context_To_Tutorial_Aggregate_Fields = {
  __typename?: 'Context_to_tutorial_aggregate_fields';
  avg?: Maybe<Context_To_Tutorial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Context_To_Tutorial_Max_Fields>;
  min?: Maybe<Context_To_Tutorial_Min_Fields>;
  stddev?: Maybe<Context_To_Tutorial_Stddev_Fields>;
  stddev_pop?: Maybe<Context_To_Tutorial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Context_To_Tutorial_Stddev_Samp_Fields>;
  sum?: Maybe<Context_To_Tutorial_Sum_Fields>;
  var_pop?: Maybe<Context_To_Tutorial_Var_Pop_Fields>;
  var_samp?: Maybe<Context_To_Tutorial_Var_Samp_Fields>;
  variance?: Maybe<Context_To_Tutorial_Variance_Fields>;
};


/** aggregate fields of "Context_to_tutorial" */
export type Context_To_Tutorial_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Context_To_Tutorial_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Context_to_tutorial" */
export type Context_To_Tutorial_Aggregate_Order_By = {
  avg?: Maybe<Context_To_Tutorial_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Context_To_Tutorial_Max_Order_By>;
  min?: Maybe<Context_To_Tutorial_Min_Order_By>;
  stddev?: Maybe<Context_To_Tutorial_Stddev_Order_By>;
  stddev_pop?: Maybe<Context_To_Tutorial_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Context_To_Tutorial_Stddev_Samp_Order_By>;
  sum?: Maybe<Context_To_Tutorial_Sum_Order_By>;
  var_pop?: Maybe<Context_To_Tutorial_Var_Pop_Order_By>;
  var_samp?: Maybe<Context_To_Tutorial_Var_Samp_Order_By>;
  variance?: Maybe<Context_To_Tutorial_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Context_to_tutorial" */
export type Context_To_Tutorial_Arr_Rel_Insert_Input = {
  data: Array<Context_To_Tutorial_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Context_To_Tutorial_On_Conflict>;
};

/** aggregate avg on columns */
export type Context_To_Tutorial_Avg_Fields = {
  __typename?: 'Context_to_tutorial_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Context_to_tutorial". All fields are combined with a logical 'AND'. */
export type Context_To_Tutorial_Bool_Exp = {
  _and?: Maybe<Array<Context_To_Tutorial_Bool_Exp>>;
  _not?: Maybe<Context_To_Tutorial_Bool_Exp>;
  _or?: Maybe<Array<Context_To_Tutorial_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current_step?: Maybe<Enum_Tutorial_Step_Type_Enum_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<Enum_Tutorial_Status_Enum_Comparison_Exp>;
  tutorial?: Maybe<Tutorial_Bool_Exp>;
  tutorial_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Context_to_tutorial" */
export enum Context_To_Tutorial_Constraint {
  /** unique or primary key constraint on columns "tutorial_id", "context_id" */
  ContextToTutorialIdx = 'Context_to_tutorial_idx',
  /** unique or primary key constraint on columns "id" */
  ContextToTutorialPkey = 'Context_to_tutorial_pkey'
}

/** input type for incrementing numeric columns in table "Context_to_tutorial" */
export type Context_To_Tutorial_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Context_to_tutorial" */
export type Context_To_Tutorial_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_step?: Maybe<Enum_Tutorial_Step_Type_Enum>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_Tutorial_Status_Enum>;
  tutorial?: Maybe<Tutorial_Obj_Rel_Insert_Input>;
  tutorial_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Context_To_Tutorial_Max_Fields = {
  __typename?: 'Context_to_tutorial_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Context_To_Tutorial_Min_Fields = {
  __typename?: 'Context_to_tutorial_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Context_to_tutorial" */
export type Context_To_Tutorial_Mutation_Response = {
  __typename?: 'Context_to_tutorial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Context_To_Tutorial>;
};

/** on_conflict condition type for table "Context_to_tutorial" */
export type Context_To_Tutorial_On_Conflict = {
  constraint: Context_To_Tutorial_Constraint;
  update_columns?: Array<Context_To_Tutorial_Update_Column>;
  where?: Maybe<Context_To_Tutorial_Bool_Exp>;
};

/** Ordering options when selecting data from "Context_to_tutorial". */
export type Context_To_Tutorial_Order_By = {
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  current_step?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tutorial?: Maybe<Tutorial_Order_By>;
  tutorial_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Context_to_tutorial */
export type Context_To_Tutorial_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Context_to_tutorial" */
export enum Context_To_Tutorial_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStep = 'current_step',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TutorialId = 'tutorial_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Context_to_tutorial" */
export type Context_To_Tutorial_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_step?: Maybe<Enum_Tutorial_Step_Type_Enum>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_Tutorial_Status_Enum>;
  tutorial_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Context_To_Tutorial_Stddev_Fields = {
  __typename?: 'Context_to_tutorial_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Context_To_Tutorial_Stddev_Pop_Fields = {
  __typename?: 'Context_to_tutorial_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Context_To_Tutorial_Stddev_Samp_Fields = {
  __typename?: 'Context_to_tutorial_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Context_To_Tutorial_Sum_Fields = {
  __typename?: 'Context_to_tutorial_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** update columns of table "Context_to_tutorial" */
export enum Context_To_Tutorial_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentStep = 'current_step',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TutorialId = 'tutorial_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Context_To_Tutorial_Var_Pop_Fields = {
  __typename?: 'Context_to_tutorial_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Context_To_Tutorial_Var_Samp_Fields = {
  __typename?: 'Context_to_tutorial_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Context_To_Tutorial_Variance_Fields = {
  __typename?: 'Context_to_tutorial_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Context_to_tutorial" */
export type Context_To_Tutorial_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** update columns of table "Context" */
export enum Context_Update_Column {
  /** column name */
  AccountValidationId = 'account_validation_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestSetupStep = 'latest_setup_step',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ProfileSettingsId = 'profile_settings_id',
  /** column name */
  State = 'state',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Context_Var_Pop_Fields = {
  __typename?: 'Context_var_pop_fields';
  account_validation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latest_setup_step?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  profile_settings_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Context" */
export type Context_Var_Pop_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Context_Var_Samp_Fields = {
  __typename?: 'Context_var_samp_fields';
  account_validation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latest_setup_step?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  profile_settings_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Context" */
export type Context_Var_Samp_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Context_Variance_Fields = {
  __typename?: 'Context_variance_fields';
  account_validation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latest_setup_step?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  profile_settings_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Context" */
export type Context_Variance_Order_By = {
  account_validation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  latest_setup_step?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_settings_id?: Maybe<Order_By>;
};

/** columns and relationships of "Countries" */
export type Countries = {
  __typename?: 'Countries';
  iso2: Scalars['String'];
  iso3: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "Countries" */
export type Countries_Aggregate = {
  __typename?: 'Countries_aggregate';
  aggregate?: Maybe<Countries_Aggregate_Fields>;
  nodes: Array<Countries>;
};

/** aggregate fields of "Countries" */
export type Countries_Aggregate_Fields = {
  __typename?: 'Countries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Countries_Max_Fields>;
  min?: Maybe<Countries_Min_Fields>;
};


/** aggregate fields of "Countries" */
export type Countries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Countries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Countries". All fields are combined with a logical 'AND'. */
export type Countries_Bool_Exp = {
  _and?: Maybe<Array<Countries_Bool_Exp>>;
  _not?: Maybe<Countries_Bool_Exp>;
  _or?: Maybe<Array<Countries_Bool_Exp>>;
  iso2?: Maybe<String_Comparison_Exp>;
  iso3?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Countries" */
export enum Countries_Constraint {
  /** unique or primary key constraint on columns "iso3" */
  CountriesPkey = 'Countries_pkey'
}

/** input type for inserting data into table "Countries" */
export type Countries_Insert_Input = {
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Countries_Max_Fields = {
  __typename?: 'Countries_max_fields';
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Countries_Min_Fields = {
  __typename?: 'Countries_min_fields';
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Countries" */
export type Countries_Mutation_Response = {
  __typename?: 'Countries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Countries>;
};

/** input type for inserting object relation for remote table "Countries" */
export type Countries_Obj_Rel_Insert_Input = {
  data: Countries_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Countries_On_Conflict>;
};

/** on_conflict condition type for table "Countries" */
export type Countries_On_Conflict = {
  constraint: Countries_Constraint;
  update_columns?: Array<Countries_Update_Column>;
  where?: Maybe<Countries_Bool_Exp>;
};

/** Ordering options when selecting data from "Countries". */
export type Countries_Order_By = {
  iso2?: Maybe<Order_By>;
  iso3?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: Countries */
export type Countries_Pk_Columns_Input = {
  iso3: Scalars['String'];
};

/** select columns of table "Countries" */
export enum Countries_Select_Column {
  /** column name */
  Iso2 = 'iso2',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "Countries" */
export type Countries_Set_Input = {
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "Countries" */
export enum Countries_Update_Column {
  /** column name */
  Iso2 = 'iso2',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  Name = 'name'
}

export type CreateArtPrizeArtwork = {
  artwork_id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateArtPrizeResult = {
  __typename?: 'CreateArtPrizeResult';
  id?: Maybe<Scalars['Int']>;
};

export type CreateArtPrizeWinner = {
  art_prize_show_id?: Maybe<Scalars['Int']>;
  artist_context_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  position_type?: Maybe<Scalars['String']>;
};

export type CreateArtworkResult = {
  __typename?: 'CreateArtworkResult';
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['Int']>;
  artwork_story_id?: Maybe<Scalars['Int']>;
  edition_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type CreateCollectionResult = {
  __typename?: 'CreateCollectionResult';
  collection_details_id: Scalars['Int'];
  collection_id: Scalars['Int'];
  slug: Scalars['String'];
};

export type CreateEditionResult = {
  __typename?: 'CreateEditionResult';
  Artwork?: Maybe<CreateArtworkResult>;
  add_edition_flow_step?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_price_public?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  publish_date?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CreateEditionSizeResult = {
  __typename?: 'CreateEditionSizeResult';
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  is_price_public?: Maybe<Scalars['Boolean']>;
  materials?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  print_date_day?: Maybe<Scalars['Int']>;
  print_date_month?: Maybe<Scalars['Int']>;
  print_date_year?: Maybe<Scalars['Int']>;
  print_size?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type CreateEditionTypeResult = {
  __typename?: 'CreateEditionTypeResult';
  edition_by_size_id?: Maybe<Scalars['Int']>;
  edition_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  print_amount?: Maybe<Scalars['Int']>;
};

export type CreatePrintTypeResult = {
  __typename?: 'CreatePrintTypeResult';
  artwork_id?: Maybe<Scalars['Int']>;
  edition_by_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Crop_Area" */
export type Crop_Area = {
  __typename?: 'Crop_Area';
  height: Scalars['numeric'];
  id: Scalars['Int'];
  unit: Scalars['String'];
  width: Scalars['numeric'];
  x: Scalars['numeric'];
  y: Scalars['numeric'];
  zoom?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "Crop_Area" */
export type Crop_Area_Aggregate = {
  __typename?: 'Crop_Area_aggregate';
  aggregate?: Maybe<Crop_Area_Aggregate_Fields>;
  nodes: Array<Crop_Area>;
};

/** aggregate fields of "Crop_Area" */
export type Crop_Area_Aggregate_Fields = {
  __typename?: 'Crop_Area_aggregate_fields';
  avg?: Maybe<Crop_Area_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Crop_Area_Max_Fields>;
  min?: Maybe<Crop_Area_Min_Fields>;
  stddev?: Maybe<Crop_Area_Stddev_Fields>;
  stddev_pop?: Maybe<Crop_Area_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Crop_Area_Stddev_Samp_Fields>;
  sum?: Maybe<Crop_Area_Sum_Fields>;
  var_pop?: Maybe<Crop_Area_Var_Pop_Fields>;
  var_samp?: Maybe<Crop_Area_Var_Samp_Fields>;
  variance?: Maybe<Crop_Area_Variance_Fields>;
};


/** aggregate fields of "Crop_Area" */
export type Crop_Area_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Crop_Area_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Crop_Area_Avg_Fields = {
  __typename?: 'Crop_Area_avg_fields';
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  zoom?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Crop_Area". All fields are combined with a logical 'AND'. */
export type Crop_Area_Bool_Exp = {
  _and?: Maybe<Array<Crop_Area_Bool_Exp>>;
  _not?: Maybe<Crop_Area_Bool_Exp>;
  _or?: Maybe<Array<Crop_Area_Bool_Exp>>;
  height?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  unit?: Maybe<String_Comparison_Exp>;
  width?: Maybe<Numeric_Comparison_Exp>;
  x?: Maybe<Numeric_Comparison_Exp>;
  y?: Maybe<Numeric_Comparison_Exp>;
  zoom?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "Crop_Area" */
export enum Crop_Area_Constraint {
  /** unique or primary key constraint on columns "id" */
  CropAreaPkey = 'Crop_Area_pkey'
}

/** input type for incrementing numeric columns in table "Crop_Area" */
export type Crop_Area_Inc_Input = {
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['numeric']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
  zoom?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Crop_Area" */
export type Crop_Area_Insert_Input = {
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['numeric']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
  zoom?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Crop_Area_Max_Fields = {
  __typename?: 'Crop_Area_max_fields';
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['numeric']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
  zoom?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Crop_Area_Min_Fields = {
  __typename?: 'Crop_Area_min_fields';
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['numeric']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
  zoom?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "Crop_Area" */
export type Crop_Area_Mutation_Response = {
  __typename?: 'Crop_Area_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Crop_Area>;
};

/** input type for inserting object relation for remote table "Crop_Area" */
export type Crop_Area_Obj_Rel_Insert_Input = {
  data: Crop_Area_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Crop_Area_On_Conflict>;
};

/** on_conflict condition type for table "Crop_Area" */
export type Crop_Area_On_Conflict = {
  constraint: Crop_Area_Constraint;
  update_columns?: Array<Crop_Area_Update_Column>;
  where?: Maybe<Crop_Area_Bool_Exp>;
};

/** Ordering options when selecting data from "Crop_Area". */
export type Crop_Area_Order_By = {
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
  zoom?: Maybe<Order_By>;
};

/** primary key columns input for table: Crop_Area */
export type Crop_Area_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Crop_Area" */
export enum Crop_Area_Select_Column {
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Width = 'width',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y',
  /** column name */
  Zoom = 'zoom'
}

/** input type for updating data in table "Crop_Area" */
export type Crop_Area_Set_Input = {
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['numeric']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
  zoom?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Crop_Area_Stddev_Fields = {
  __typename?: 'Crop_Area_stddev_fields';
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  zoom?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Crop_Area_Stddev_Pop_Fields = {
  __typename?: 'Crop_Area_stddev_pop_fields';
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  zoom?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Crop_Area_Stddev_Samp_Fields = {
  __typename?: 'Crop_Area_stddev_samp_fields';
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  zoom?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Crop_Area_Sum_Fields = {
  __typename?: 'Crop_Area_sum_fields';
  height?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['numeric']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
  zoom?: Maybe<Scalars['numeric']>;
};

/** update columns of table "Crop_Area" */
export enum Crop_Area_Update_Column {
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Width = 'width',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y',
  /** column name */
  Zoom = 'zoom'
}

/** aggregate var_pop on columns */
export type Crop_Area_Var_Pop_Fields = {
  __typename?: 'Crop_Area_var_pop_fields';
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  zoom?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Crop_Area_Var_Samp_Fields = {
  __typename?: 'Crop_Area_var_samp_fields';
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  zoom?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Crop_Area_Variance_Fields = {
  __typename?: 'Crop_Area_variance_fields';
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  zoom?: Maybe<Scalars['Float']>;
};

/** keeps the conversion numbers to USD */
export type Currency_Conversion = {
  __typename?: 'Currency_conversion';
  currency: Scalars['String'];
  rate: Scalars['float8'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Currency_conversion" */
export type Currency_Conversion_Aggregate = {
  __typename?: 'Currency_conversion_aggregate';
  aggregate?: Maybe<Currency_Conversion_Aggregate_Fields>;
  nodes: Array<Currency_Conversion>;
};

/** aggregate fields of "Currency_conversion" */
export type Currency_Conversion_Aggregate_Fields = {
  __typename?: 'Currency_conversion_aggregate_fields';
  avg?: Maybe<Currency_Conversion_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Currency_Conversion_Max_Fields>;
  min?: Maybe<Currency_Conversion_Min_Fields>;
  stddev?: Maybe<Currency_Conversion_Stddev_Fields>;
  stddev_pop?: Maybe<Currency_Conversion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Currency_Conversion_Stddev_Samp_Fields>;
  sum?: Maybe<Currency_Conversion_Sum_Fields>;
  var_pop?: Maybe<Currency_Conversion_Var_Pop_Fields>;
  var_samp?: Maybe<Currency_Conversion_Var_Samp_Fields>;
  variance?: Maybe<Currency_Conversion_Variance_Fields>;
};


/** aggregate fields of "Currency_conversion" */
export type Currency_Conversion_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Currency_Conversion_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Currency_Conversion_Avg_Fields = {
  __typename?: 'Currency_conversion_avg_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Currency_conversion". All fields are combined with a logical 'AND'. */
export type Currency_Conversion_Bool_Exp = {
  _and?: Maybe<Array<Currency_Conversion_Bool_Exp>>;
  _not?: Maybe<Currency_Conversion_Bool_Exp>;
  _or?: Maybe<Array<Currency_Conversion_Bool_Exp>>;
  currency?: Maybe<String_Comparison_Exp>;
  rate?: Maybe<Float8_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Currency_conversion" */
export enum Currency_Conversion_Constraint {
  /** unique or primary key constraint on columns "currency" */
  CurrencyConversionPkey = 'Currency_conversion_pkey'
}

/** input type for incrementing numeric columns in table "Currency_conversion" */
export type Currency_Conversion_Inc_Input = {
  rate?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "Currency_conversion" */
export type Currency_Conversion_Insert_Input = {
  currency?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Currency_Conversion_Max_Fields = {
  __typename?: 'Currency_conversion_max_fields';
  currency?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Currency_Conversion_Min_Fields = {
  __typename?: 'Currency_conversion_min_fields';
  currency?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Currency_conversion" */
export type Currency_Conversion_Mutation_Response = {
  __typename?: 'Currency_conversion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Currency_Conversion>;
};

/** on_conflict condition type for table "Currency_conversion" */
export type Currency_Conversion_On_Conflict = {
  constraint: Currency_Conversion_Constraint;
  update_columns?: Array<Currency_Conversion_Update_Column>;
  where?: Maybe<Currency_Conversion_Bool_Exp>;
};

/** Ordering options when selecting data from "Currency_conversion". */
export type Currency_Conversion_Order_By = {
  currency?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Currency_conversion */
export type Currency_Conversion_Pk_Columns_Input = {
  currency: Scalars['String'];
};

/** select columns of table "Currency_conversion" */
export enum Currency_Conversion_Select_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Rate = 'rate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Currency_conversion" */
export type Currency_Conversion_Set_Input = {
  currency?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Currency_Conversion_Stddev_Fields = {
  __typename?: 'Currency_conversion_stddev_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Currency_Conversion_Stddev_Pop_Fields = {
  __typename?: 'Currency_conversion_stddev_pop_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Currency_Conversion_Stddev_Samp_Fields = {
  __typename?: 'Currency_conversion_stddev_samp_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Currency_Conversion_Sum_Fields = {
  __typename?: 'Currency_conversion_sum_fields';
  rate?: Maybe<Scalars['float8']>;
};

/** update columns of table "Currency_conversion" */
export enum Currency_Conversion_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Rate = 'rate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Currency_Conversion_Var_Pop_Fields = {
  __typename?: 'Currency_conversion_var_pop_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Currency_Conversion_Var_Samp_Fields = {
  __typename?: 'Currency_conversion_var_samp_fields';
  rate?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Currency_Conversion_Variance_Fields = {
  __typename?: 'Currency_conversion_variance_fields';
  rate?: Maybe<Scalars['Float']>;
};

export type CustomArtistCollectionToArtistContextInsertInput = {
  artistCollectionToArtistContextId: Scalars['bigint'];
  order: Scalars['bigint'];
};

export type Custom_Pubnub_Channels_Type = {
  __typename?: 'Custom_Pubnub_Channels_Type';
  firstProfileUrl: Scalars['String'];
  firstUserContextId: Scalars['bigint'];
  firstUserName: Scalars['String'];
  lastMessage?: Maybe<Scalars['String']>;
  lastMessageSenderContextId?: Maybe<Scalars['bigint']>;
  lastMessageUpdatedAt?: Maybe<Scalars['timestamptz']>;
  lastReadTimetoken?: Maybe<Scalars['String']>;
  secondProfileUrl: Scalars['String'];
  secondUserContextId: Scalars['bigint'];
  secondUserName: Scalars['String'];
};

export type DaySchedule = {
  __typename?: 'DaySchedule';
  close?: Maybe<Scalars['String']>;
  day?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Digital_twin_details" */
export type Digital_Twin_Details = {
  __typename?: 'Digital_twin_details';
  creator_wallet_address: Scalars['String'];
  description: Scalars['String'];
  digital_twin_id: Scalars['bigint'];
  ipfs_hash?: Maybe<Scalars['String']>;
  minting_date: Scalars['timestamptz'];
  objkt_url?: Maybe<Scalars['String']>;
  royalties?: Maybe<Scalars['numeric']>;
  title: Scalars['String'];
  token_id: Scalars['String'];
  token_uri: Scalars['String'];
};

/** aggregated selection of "Digital_twin_details" */
export type Digital_Twin_Details_Aggregate = {
  __typename?: 'Digital_twin_details_aggregate';
  aggregate?: Maybe<Digital_Twin_Details_Aggregate_Fields>;
  nodes: Array<Digital_Twin_Details>;
};

/** aggregate fields of "Digital_twin_details" */
export type Digital_Twin_Details_Aggregate_Fields = {
  __typename?: 'Digital_twin_details_aggregate_fields';
  avg?: Maybe<Digital_Twin_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Digital_Twin_Details_Max_Fields>;
  min?: Maybe<Digital_Twin_Details_Min_Fields>;
  stddev?: Maybe<Digital_Twin_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Digital_Twin_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Digital_Twin_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Digital_Twin_Details_Sum_Fields>;
  var_pop?: Maybe<Digital_Twin_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Digital_Twin_Details_Var_Samp_Fields>;
  variance?: Maybe<Digital_Twin_Details_Variance_Fields>;
};


/** aggregate fields of "Digital_twin_details" */
export type Digital_Twin_Details_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Digital_Twin_Details_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Digital_Twin_Details_Avg_Fields = {
  __typename?: 'Digital_twin_details_avg_fields';
  digital_twin_id?: Maybe<Scalars['Float']>;
  royalties?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Digital_twin_details". All fields are combined with a logical 'AND'. */
export type Digital_Twin_Details_Bool_Exp = {
  _and?: Maybe<Array<Digital_Twin_Details_Bool_Exp>>;
  _not?: Maybe<Digital_Twin_Details_Bool_Exp>;
  _or?: Maybe<Array<Digital_Twin_Details_Bool_Exp>>;
  creator_wallet_address?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  digital_twin_id?: Maybe<Bigint_Comparison_Exp>;
  ipfs_hash?: Maybe<String_Comparison_Exp>;
  minting_date?: Maybe<Timestamptz_Comparison_Exp>;
  objkt_url?: Maybe<String_Comparison_Exp>;
  royalties?: Maybe<Numeric_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  token_id?: Maybe<String_Comparison_Exp>;
  token_uri?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Digital_twin_details" */
export enum Digital_Twin_Details_Constraint {
  /** unique or primary key constraint on columns "digital_twin_id" */
  DigitalTwinDetailsPkey = 'Digital_twin_details_pkey'
}

/** input type for incrementing numeric columns in table "Digital_twin_details" */
export type Digital_Twin_Details_Inc_Input = {
  digital_twin_id?: Maybe<Scalars['bigint']>;
  royalties?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Digital_twin_details" */
export type Digital_Twin_Details_Insert_Input = {
  creator_wallet_address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
  ipfs_hash?: Maybe<Scalars['String']>;
  minting_date?: Maybe<Scalars['timestamptz']>;
  objkt_url?: Maybe<Scalars['String']>;
  royalties?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  token_uri?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Digital_Twin_Details_Max_Fields = {
  __typename?: 'Digital_twin_details_max_fields';
  creator_wallet_address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
  ipfs_hash?: Maybe<Scalars['String']>;
  minting_date?: Maybe<Scalars['timestamptz']>;
  objkt_url?: Maybe<Scalars['String']>;
  royalties?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  token_uri?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Digital_Twin_Details_Min_Fields = {
  __typename?: 'Digital_twin_details_min_fields';
  creator_wallet_address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
  ipfs_hash?: Maybe<Scalars['String']>;
  minting_date?: Maybe<Scalars['timestamptz']>;
  objkt_url?: Maybe<Scalars['String']>;
  royalties?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  token_uri?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Digital_twin_details" */
export type Digital_Twin_Details_Mutation_Response = {
  __typename?: 'Digital_twin_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Digital_Twin_Details>;
};

/** input type for inserting object relation for remote table "Digital_twin_details" */
export type Digital_Twin_Details_Obj_Rel_Insert_Input = {
  data: Digital_Twin_Details_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Digital_Twin_Details_On_Conflict>;
};

/** on_conflict condition type for table "Digital_twin_details" */
export type Digital_Twin_Details_On_Conflict = {
  constraint: Digital_Twin_Details_Constraint;
  update_columns?: Array<Digital_Twin_Details_Update_Column>;
  where?: Maybe<Digital_Twin_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "Digital_twin_details". */
export type Digital_Twin_Details_Order_By = {
  creator_wallet_address?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  digital_twin_id?: Maybe<Order_By>;
  ipfs_hash?: Maybe<Order_By>;
  minting_date?: Maybe<Order_By>;
  objkt_url?: Maybe<Order_By>;
  royalties?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  token_id?: Maybe<Order_By>;
  token_uri?: Maybe<Order_By>;
};

/** primary key columns input for table: Digital_twin_details */
export type Digital_Twin_Details_Pk_Columns_Input = {
  digital_twin_id: Scalars['bigint'];
};

/** select columns of table "Digital_twin_details" */
export enum Digital_Twin_Details_Select_Column {
  /** column name */
  CreatorWalletAddress = 'creator_wallet_address',
  /** column name */
  Description = 'description',
  /** column name */
  DigitalTwinId = 'digital_twin_id',
  /** column name */
  IpfsHash = 'ipfs_hash',
  /** column name */
  MintingDate = 'minting_date',
  /** column name */
  ObjktUrl = 'objkt_url',
  /** column name */
  Royalties = 'royalties',
  /** column name */
  Title = 'title',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  TokenUri = 'token_uri'
}

/** input type for updating data in table "Digital_twin_details" */
export type Digital_Twin_Details_Set_Input = {
  creator_wallet_address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  digital_twin_id?: Maybe<Scalars['bigint']>;
  ipfs_hash?: Maybe<Scalars['String']>;
  minting_date?: Maybe<Scalars['timestamptz']>;
  objkt_url?: Maybe<Scalars['String']>;
  royalties?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  token_uri?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Digital_Twin_Details_Stddev_Fields = {
  __typename?: 'Digital_twin_details_stddev_fields';
  digital_twin_id?: Maybe<Scalars['Float']>;
  royalties?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Digital_Twin_Details_Stddev_Pop_Fields = {
  __typename?: 'Digital_twin_details_stddev_pop_fields';
  digital_twin_id?: Maybe<Scalars['Float']>;
  royalties?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Digital_Twin_Details_Stddev_Samp_Fields = {
  __typename?: 'Digital_twin_details_stddev_samp_fields';
  digital_twin_id?: Maybe<Scalars['Float']>;
  royalties?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Digital_Twin_Details_Sum_Fields = {
  __typename?: 'Digital_twin_details_sum_fields';
  digital_twin_id?: Maybe<Scalars['bigint']>;
  royalties?: Maybe<Scalars['numeric']>;
};

/** update columns of table "Digital_twin_details" */
export enum Digital_Twin_Details_Update_Column {
  /** column name */
  CreatorWalletAddress = 'creator_wallet_address',
  /** column name */
  Description = 'description',
  /** column name */
  DigitalTwinId = 'digital_twin_id',
  /** column name */
  IpfsHash = 'ipfs_hash',
  /** column name */
  MintingDate = 'minting_date',
  /** column name */
  ObjktUrl = 'objkt_url',
  /** column name */
  Royalties = 'royalties',
  /** column name */
  Title = 'title',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  TokenUri = 'token_uri'
}

/** aggregate var_pop on columns */
export type Digital_Twin_Details_Var_Pop_Fields = {
  __typename?: 'Digital_twin_details_var_pop_fields';
  digital_twin_id?: Maybe<Scalars['Float']>;
  royalties?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Digital_Twin_Details_Var_Samp_Fields = {
  __typename?: 'Digital_twin_details_var_samp_fields';
  digital_twin_id?: Maybe<Scalars['Float']>;
  royalties?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Digital_Twin_Details_Variance_Fields = {
  __typename?: 'Digital_twin_details_variance_fields';
  digital_twin_id?: Maybe<Scalars['Float']>;
  royalties?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Direct_messages_from_collectors" */
export type Direct_Messages_From_Collectors = {
  __typename?: 'Direct_messages_from_collectors';
  created_at?: Maybe<Scalars['timestamptz']>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  receiver_email?: Maybe<Scalars['String']>;
  receiver_first_name?: Maybe<Scalars['String']>;
  receiver_last_name?: Maybe<Scalars['String']>;
  receiver_preferred_language?: Maybe<Scalars['String']>;
  receiver_profile_link?: Maybe<Scalars['String']>;
  receiver_profile_type?: Maybe<Scalars['String']>;
  receiver_title?: Maybe<Scalars['String']>;
  sender_context_id?: Maybe<Scalars['bigint']>;
  sender_email?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_preferred_language?: Maybe<Scalars['String']>;
  sender_profile_link?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Direct_messages_from_collectors" */
export type Direct_Messages_From_Collectors_Aggregate = {
  __typename?: 'Direct_messages_from_collectors_aggregate';
  aggregate?: Maybe<Direct_Messages_From_Collectors_Aggregate_Fields>;
  nodes: Array<Direct_Messages_From_Collectors>;
};

/** aggregate fields of "Direct_messages_from_collectors" */
export type Direct_Messages_From_Collectors_Aggregate_Fields = {
  __typename?: 'Direct_messages_from_collectors_aggregate_fields';
  avg?: Maybe<Direct_Messages_From_Collectors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Direct_Messages_From_Collectors_Max_Fields>;
  min?: Maybe<Direct_Messages_From_Collectors_Min_Fields>;
  stddev?: Maybe<Direct_Messages_From_Collectors_Stddev_Fields>;
  stddev_pop?: Maybe<Direct_Messages_From_Collectors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Direct_Messages_From_Collectors_Stddev_Samp_Fields>;
  sum?: Maybe<Direct_Messages_From_Collectors_Sum_Fields>;
  var_pop?: Maybe<Direct_Messages_From_Collectors_Var_Pop_Fields>;
  var_samp?: Maybe<Direct_Messages_From_Collectors_Var_Samp_Fields>;
  variance?: Maybe<Direct_Messages_From_Collectors_Variance_Fields>;
};


/** aggregate fields of "Direct_messages_from_collectors" */
export type Direct_Messages_From_Collectors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Direct_Messages_From_Collectors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Direct_Messages_From_Collectors_Avg_Fields = {
  __typename?: 'Direct_messages_from_collectors_avg_fields';
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Direct_messages_from_collectors". All fields are combined with a logical 'AND'. */
export type Direct_Messages_From_Collectors_Bool_Exp = {
  _and?: Maybe<Array<Direct_Messages_From_Collectors_Bool_Exp>>;
  _not?: Maybe<Direct_Messages_From_Collectors_Bool_Exp>;
  _or?: Maybe<Array<Direct_Messages_From_Collectors_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  receiver_context_id?: Maybe<Bigint_Comparison_Exp>;
  receiver_email?: Maybe<String_Comparison_Exp>;
  receiver_first_name?: Maybe<String_Comparison_Exp>;
  receiver_last_name?: Maybe<String_Comparison_Exp>;
  receiver_preferred_language?: Maybe<String_Comparison_Exp>;
  receiver_profile_link?: Maybe<String_Comparison_Exp>;
  receiver_profile_type?: Maybe<String_Comparison_Exp>;
  receiver_title?: Maybe<String_Comparison_Exp>;
  sender_context_id?: Maybe<Bigint_Comparison_Exp>;
  sender_email?: Maybe<String_Comparison_Exp>;
  sender_name?: Maybe<String_Comparison_Exp>;
  sender_preferred_language?: Maybe<String_Comparison_Exp>;
  sender_profile_link?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Direct_Messages_From_Collectors_Max_Fields = {
  __typename?: 'Direct_messages_from_collectors_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  receiver_email?: Maybe<Scalars['String']>;
  receiver_first_name?: Maybe<Scalars['String']>;
  receiver_last_name?: Maybe<Scalars['String']>;
  receiver_preferred_language?: Maybe<Scalars['String']>;
  receiver_profile_link?: Maybe<Scalars['String']>;
  receiver_profile_type?: Maybe<Scalars['String']>;
  receiver_title?: Maybe<Scalars['String']>;
  sender_context_id?: Maybe<Scalars['bigint']>;
  sender_email?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_preferred_language?: Maybe<Scalars['String']>;
  sender_profile_link?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Direct_Messages_From_Collectors_Min_Fields = {
  __typename?: 'Direct_messages_from_collectors_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  receiver_email?: Maybe<Scalars['String']>;
  receiver_first_name?: Maybe<Scalars['String']>;
  receiver_last_name?: Maybe<Scalars['String']>;
  receiver_preferred_language?: Maybe<Scalars['String']>;
  receiver_profile_link?: Maybe<Scalars['String']>;
  receiver_profile_type?: Maybe<Scalars['String']>;
  receiver_title?: Maybe<Scalars['String']>;
  sender_context_id?: Maybe<Scalars['bigint']>;
  sender_email?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_preferred_language?: Maybe<Scalars['String']>;
  sender_profile_link?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Direct_messages_from_collectors". */
export type Direct_Messages_From_Collectors_Order_By = {
  created_at?: Maybe<Order_By>;
  receiver_context_id?: Maybe<Order_By>;
  receiver_email?: Maybe<Order_By>;
  receiver_first_name?: Maybe<Order_By>;
  receiver_last_name?: Maybe<Order_By>;
  receiver_preferred_language?: Maybe<Order_By>;
  receiver_profile_link?: Maybe<Order_By>;
  receiver_profile_type?: Maybe<Order_By>;
  receiver_title?: Maybe<Order_By>;
  sender_context_id?: Maybe<Order_By>;
  sender_email?: Maybe<Order_By>;
  sender_name?: Maybe<Order_By>;
  sender_preferred_language?: Maybe<Order_By>;
  sender_profile_link?: Maybe<Order_By>;
};

/** select columns of table "Direct_messages_from_collectors" */
export enum Direct_Messages_From_Collectors_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ReceiverContextId = 'receiver_context_id',
  /** column name */
  ReceiverEmail = 'receiver_email',
  /** column name */
  ReceiverFirstName = 'receiver_first_name',
  /** column name */
  ReceiverLastName = 'receiver_last_name',
  /** column name */
  ReceiverPreferredLanguage = 'receiver_preferred_language',
  /** column name */
  ReceiverProfileLink = 'receiver_profile_link',
  /** column name */
  ReceiverProfileType = 'receiver_profile_type',
  /** column name */
  ReceiverTitle = 'receiver_title',
  /** column name */
  SenderContextId = 'sender_context_id',
  /** column name */
  SenderEmail = 'sender_email',
  /** column name */
  SenderName = 'sender_name',
  /** column name */
  SenderPreferredLanguage = 'sender_preferred_language',
  /** column name */
  SenderProfileLink = 'sender_profile_link'
}

/** aggregate stddev on columns */
export type Direct_Messages_From_Collectors_Stddev_Fields = {
  __typename?: 'Direct_messages_from_collectors_stddev_fields';
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Direct_Messages_From_Collectors_Stddev_Pop_Fields = {
  __typename?: 'Direct_messages_from_collectors_stddev_pop_fields';
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Direct_Messages_From_Collectors_Stddev_Samp_Fields = {
  __typename?: 'Direct_messages_from_collectors_stddev_samp_fields';
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Direct_Messages_From_Collectors_Sum_Fields = {
  __typename?: 'Direct_messages_from_collectors_sum_fields';
  receiver_context_id?: Maybe<Scalars['bigint']>;
  sender_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Direct_Messages_From_Collectors_Var_Pop_Fields = {
  __typename?: 'Direct_messages_from_collectors_var_pop_fields';
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Direct_Messages_From_Collectors_Var_Samp_Fields = {
  __typename?: 'Direct_messages_from_collectors_var_samp_fields';
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Direct_Messages_From_Collectors_Variance_Fields = {
  __typename?: 'Direct_messages_from_collectors_variance_fields';
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

export type DiscountCodeInput = {
  code: Scalars['String'];
  finalDiscount: Scalars['Float'];
  originalPrice: Scalars['Float'];
};

/** List of discount codes */
export type Discount_Code = {
  __typename?: 'Discount_code';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  discount_emerging_value: Scalars['float8'];
  discount_professional_value: Scalars['float8'];
  discount_type: Enum_Discount_Type_Enum;
  discount_value_type: Enum_Discount_Value_Type_Enum;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  state: Enum_Discount_State_Enum;
  trial_period_days?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Discount_code" */
export type Discount_Code_Aggregate = {
  __typename?: 'Discount_code_aggregate';
  aggregate?: Maybe<Discount_Code_Aggregate_Fields>;
  nodes: Array<Discount_Code>;
};

/** aggregate fields of "Discount_code" */
export type Discount_Code_Aggregate_Fields = {
  __typename?: 'Discount_code_aggregate_fields';
  avg?: Maybe<Discount_Code_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Discount_Code_Max_Fields>;
  min?: Maybe<Discount_Code_Min_Fields>;
  stddev?: Maybe<Discount_Code_Stddev_Fields>;
  stddev_pop?: Maybe<Discount_Code_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Discount_Code_Stddev_Samp_Fields>;
  sum?: Maybe<Discount_Code_Sum_Fields>;
  var_pop?: Maybe<Discount_Code_Var_Pop_Fields>;
  var_samp?: Maybe<Discount_Code_Var_Samp_Fields>;
  variance?: Maybe<Discount_Code_Variance_Fields>;
};


/** aggregate fields of "Discount_code" */
export type Discount_Code_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discount_Code_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Discount_Code_Avg_Fields = {
  __typename?: 'Discount_code_avg_fields';
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Discount_code". All fields are combined with a logical 'AND'. */
export type Discount_Code_Bool_Exp = {
  _and?: Maybe<Array<Discount_Code_Bool_Exp>>;
  _not?: Maybe<Discount_Code_Bool_Exp>;
  _or?: Maybe<Array<Discount_Code_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  discount_emerging_value?: Maybe<Float8_Comparison_Exp>;
  discount_professional_value?: Maybe<Float8_Comparison_Exp>;
  discount_type?: Maybe<Enum_Discount_Type_Enum_Comparison_Exp>;
  discount_value_type?: Maybe<Enum_Discount_Value_Type_Enum_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  expired_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  state?: Maybe<Enum_Discount_State_Enum_Comparison_Exp>;
  trial_period_days?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Discount_code" */
export enum Discount_Code_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiscountCodePkey = 'Discount_code_pkey'
}

/** input type for incrementing numeric columns in table "Discount_code" */
export type Discount_Code_Inc_Input = {
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  trial_period_days?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Discount_code" */
export type Discount_Code_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  discount_type?: Maybe<Enum_Discount_Type_Enum>;
  discount_value_type?: Maybe<Enum_Discount_Value_Type_Enum>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Enum_Discount_State_Enum>;
  trial_period_days?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Discount_Code_Max_Fields = {
  __typename?: 'Discount_code_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trial_period_days?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Discount_Code_Min_Fields = {
  __typename?: 'Discount_code_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trial_period_days?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Discount_code" */
export type Discount_Code_Mutation_Response = {
  __typename?: 'Discount_code_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Discount_Code>;
};

/** on_conflict condition type for table "Discount_code" */
export type Discount_Code_On_Conflict = {
  constraint: Discount_Code_Constraint;
  update_columns?: Array<Discount_Code_Update_Column>;
  where?: Maybe<Discount_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "Discount_code". */
export type Discount_Code_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  discount_emerging_value?: Maybe<Order_By>;
  discount_professional_value?: Maybe<Order_By>;
  discount_type?: Maybe<Order_By>;
  discount_value_type?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  expired_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  trial_period_days?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Discount_code */
export type Discount_Code_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Discount_code" */
export enum Discount_Code_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscountEmergingValue = 'discount_emerging_value',
  /** column name */
  DiscountProfessionalValue = 'discount_professional_value',
  /** column name */
  DiscountType = 'discount_type',
  /** column name */
  DiscountValueType = 'discount_value_type',
  /** column name */
  Email = 'email',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  TrialPeriodDays = 'trial_period_days',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Discount_code" */
export type Discount_Code_Set_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  discount_type?: Maybe<Enum_Discount_Type_Enum>;
  discount_value_type?: Maybe<Enum_Discount_Value_Type_Enum>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Enum_Discount_State_Enum>;
  trial_period_days?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Discount_Code_Stddev_Fields = {
  __typename?: 'Discount_code_stddev_fields';
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Discount_Code_Stddev_Pop_Fields = {
  __typename?: 'Discount_code_stddev_pop_fields';
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Discount_Code_Stddev_Samp_Fields = {
  __typename?: 'Discount_code_stddev_samp_fields';
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Discount_Code_Sum_Fields = {
  __typename?: 'Discount_code_sum_fields';
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  trial_period_days?: Maybe<Scalars['Int']>;
};

/** update columns of table "Discount_code" */
export enum Discount_Code_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscountEmergingValue = 'discount_emerging_value',
  /** column name */
  DiscountProfessionalValue = 'discount_professional_value',
  /** column name */
  DiscountType = 'discount_type',
  /** column name */
  DiscountValueType = 'discount_value_type',
  /** column name */
  Email = 'email',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  TrialPeriodDays = 'trial_period_days',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Table to track the usage for the discount codes */
export type Discount_Code_Usage = {
  __typename?: 'Discount_code_usage';
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discount_code_id: Scalars['bigint'];
  discount_value: Scalars['float8'];
  discount_value_type: Enum_Discount_Value_Type_Enum;
  email: Scalars['String'];
  final_price: Scalars['float8'];
  id: Scalars['bigint'];
  original_price: Scalars['float8'];
  purchased_item: Scalars['String'];
};

/** aggregated selection of "Discount_code_usage" */
export type Discount_Code_Usage_Aggregate = {
  __typename?: 'Discount_code_usage_aggregate';
  aggregate?: Maybe<Discount_Code_Usage_Aggregate_Fields>;
  nodes: Array<Discount_Code_Usage>;
};

/** aggregate fields of "Discount_code_usage" */
export type Discount_Code_Usage_Aggregate_Fields = {
  __typename?: 'Discount_code_usage_aggregate_fields';
  avg?: Maybe<Discount_Code_Usage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Discount_Code_Usage_Max_Fields>;
  min?: Maybe<Discount_Code_Usage_Min_Fields>;
  stddev?: Maybe<Discount_Code_Usage_Stddev_Fields>;
  stddev_pop?: Maybe<Discount_Code_Usage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Discount_Code_Usage_Stddev_Samp_Fields>;
  sum?: Maybe<Discount_Code_Usage_Sum_Fields>;
  var_pop?: Maybe<Discount_Code_Usage_Var_Pop_Fields>;
  var_samp?: Maybe<Discount_Code_Usage_Var_Samp_Fields>;
  variance?: Maybe<Discount_Code_Usage_Variance_Fields>;
};


/** aggregate fields of "Discount_code_usage" */
export type Discount_Code_Usage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discount_Code_Usage_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Discount_Code_Usage_Avg_Fields = {
  __typename?: 'Discount_code_usage_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_code_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Discount_code_usage". All fields are combined with a logical 'AND'. */
export type Discount_Code_Usage_Bool_Exp = {
  _and?: Maybe<Array<Discount_Code_Usage_Bool_Exp>>;
  _not?: Maybe<Discount_Code_Usage_Bool_Exp>;
  _or?: Maybe<Array<Discount_Code_Usage_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  discount_code_id?: Maybe<Bigint_Comparison_Exp>;
  discount_value?: Maybe<Float8_Comparison_Exp>;
  discount_value_type?: Maybe<Enum_Discount_Value_Type_Enum_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  final_price?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  original_price?: Maybe<Float8_Comparison_Exp>;
  purchased_item?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Discount_code_usage" */
export enum Discount_Code_Usage_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiscountCodeUsagePkey = 'Discount_code_usage_pkey'
}

/** input type for incrementing numeric columns in table "Discount_code_usage" */
export type Discount_Code_Usage_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  discount_code_id?: Maybe<Scalars['bigint']>;
  discount_value?: Maybe<Scalars['float8']>;
  final_price?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  original_price?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "Discount_code_usage" */
export type Discount_Code_Usage_Insert_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discount_code_id?: Maybe<Scalars['bigint']>;
  discount_value?: Maybe<Scalars['float8']>;
  discount_value_type?: Maybe<Enum_Discount_Value_Type_Enum>;
  email?: Maybe<Scalars['String']>;
  final_price?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  original_price?: Maybe<Scalars['float8']>;
  purchased_item?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Discount_Code_Usage_Max_Fields = {
  __typename?: 'Discount_code_usage_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discount_code_id?: Maybe<Scalars['bigint']>;
  discount_value?: Maybe<Scalars['float8']>;
  email?: Maybe<Scalars['String']>;
  final_price?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  original_price?: Maybe<Scalars['float8']>;
  purchased_item?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Discount_Code_Usage_Min_Fields = {
  __typename?: 'Discount_code_usage_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discount_code_id?: Maybe<Scalars['bigint']>;
  discount_value?: Maybe<Scalars['float8']>;
  email?: Maybe<Scalars['String']>;
  final_price?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  original_price?: Maybe<Scalars['float8']>;
  purchased_item?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Discount_code_usage" */
export type Discount_Code_Usage_Mutation_Response = {
  __typename?: 'Discount_code_usage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Discount_Code_Usage>;
};

/** on_conflict condition type for table "Discount_code_usage" */
export type Discount_Code_Usage_On_Conflict = {
  constraint: Discount_Code_Usage_Constraint;
  update_columns?: Array<Discount_Code_Usage_Update_Column>;
  where?: Maybe<Discount_Code_Usage_Bool_Exp>;
};

/** Ordering options when selecting data from "Discount_code_usage". */
export type Discount_Code_Usage_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  discount_code_id?: Maybe<Order_By>;
  discount_value?: Maybe<Order_By>;
  discount_value_type?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  final_price?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  original_price?: Maybe<Order_By>;
  purchased_item?: Maybe<Order_By>;
};

/** primary key columns input for table: Discount_code_usage */
export type Discount_Code_Usage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Discount_code_usage" */
export enum Discount_Code_Usage_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiscountCodeId = 'discount_code_id',
  /** column name */
  DiscountValue = 'discount_value',
  /** column name */
  DiscountValueType = 'discount_value_type',
  /** column name */
  Email = 'email',
  /** column name */
  FinalPrice = 'final_price',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalPrice = 'original_price',
  /** column name */
  PurchasedItem = 'purchased_item'
}

/** input type for updating data in table "Discount_code_usage" */
export type Discount_Code_Usage_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  discount_code_id?: Maybe<Scalars['bigint']>;
  discount_value?: Maybe<Scalars['float8']>;
  discount_value_type?: Maybe<Enum_Discount_Value_Type_Enum>;
  email?: Maybe<Scalars['String']>;
  final_price?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  original_price?: Maybe<Scalars['float8']>;
  purchased_item?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Discount_Code_Usage_Stddev_Fields = {
  __typename?: 'Discount_code_usage_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_code_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Discount_Code_Usage_Stddev_Pop_Fields = {
  __typename?: 'Discount_code_usage_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_code_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Discount_Code_Usage_Stddev_Samp_Fields = {
  __typename?: 'Discount_code_usage_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_code_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Discount_Code_Usage_Sum_Fields = {
  __typename?: 'Discount_code_usage_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  discount_code_id?: Maybe<Scalars['bigint']>;
  discount_value?: Maybe<Scalars['float8']>;
  final_price?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  original_price?: Maybe<Scalars['float8']>;
};

/** update columns of table "Discount_code_usage" */
export enum Discount_Code_Usage_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiscountCodeId = 'discount_code_id',
  /** column name */
  DiscountValue = 'discount_value',
  /** column name */
  DiscountValueType = 'discount_value_type',
  /** column name */
  Email = 'email',
  /** column name */
  FinalPrice = 'final_price',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalPrice = 'original_price',
  /** column name */
  PurchasedItem = 'purchased_item'
}

/** aggregate var_pop on columns */
export type Discount_Code_Usage_Var_Pop_Fields = {
  __typename?: 'Discount_code_usage_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_code_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Discount_Code_Usage_Var_Samp_Fields = {
  __typename?: 'Discount_code_usage_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_code_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Discount_Code_Usage_Variance_Fields = {
  __typename?: 'Discount_code_usage_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_code_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type Discount_Code_Var_Pop_Fields = {
  __typename?: 'Discount_code_var_pop_fields';
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Discount_Code_Var_Samp_Fields = {
  __typename?: 'Discount_code_var_samp_fields';
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Discount_Code_Variance_Fields = {
  __typename?: 'Discount_code_variance_fields';
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

export enum DomainAvailability {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN',
  Unsupported = 'UNSUPPORTED'
}

export type DomainPrice = {
  __typename?: 'DomainPrice';
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type DomainPriceInput = {
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type DomainRegistrantInfo = {
  __typename?: 'DomainRegistrantInfo';
  address1: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  stateProvince: Scalars['String'];
};

export type DomainRegistrantInfoInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** must be in the form: +NNN.NNNNNNNNNN */
  phone: Scalars['String'];
  postalCode: Scalars['String'];
  stateProvince: Scalars['String'];
};

export type DomainRegistrationPreferences = {
  __typename?: 'DomainRegistrationPreferences';
  domain: DomainSuggestion;
  registrantInfo: DomainRegistrantInfo;
};

export type DomainSuggestion = {
  __typename?: 'DomainSuggestion';
  availability: DomainAvailability;
  domainName: Scalars['String'];
  registerPrice?: Maybe<DomainPrice>;
  renewalPrice?: Maybe<DomainPrice>;
};

export type DomainSuggestionsResult = {
  __typename?: 'DomainSuggestionsResult';
  domains: Array<DomainSuggestion>;
};

/** Holds registrant info for a Domain_registration */
export type Domain_Registrant_Info = {
  __typename?: 'Domain_registrant_info';
  city: Scalars['String'];
  country: Scalars['String'];
  domain_registration_id: Scalars['bigint'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone: Scalars['String'];
  postal_code: Scalars['String'];
  state_or_province: Scalars['String'];
  street: Scalars['String'];
};

/** aggregated selection of "Domain_registrant_info" */
export type Domain_Registrant_Info_Aggregate = {
  __typename?: 'Domain_registrant_info_aggregate';
  aggregate?: Maybe<Domain_Registrant_Info_Aggregate_Fields>;
  nodes: Array<Domain_Registrant_Info>;
};

/** aggregate fields of "Domain_registrant_info" */
export type Domain_Registrant_Info_Aggregate_Fields = {
  __typename?: 'Domain_registrant_info_aggregate_fields';
  avg?: Maybe<Domain_Registrant_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Domain_Registrant_Info_Max_Fields>;
  min?: Maybe<Domain_Registrant_Info_Min_Fields>;
  stddev?: Maybe<Domain_Registrant_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Domain_Registrant_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Domain_Registrant_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Domain_Registrant_Info_Sum_Fields>;
  var_pop?: Maybe<Domain_Registrant_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Domain_Registrant_Info_Var_Samp_Fields>;
  variance?: Maybe<Domain_Registrant_Info_Variance_Fields>;
};


/** aggregate fields of "Domain_registrant_info" */
export type Domain_Registrant_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Domain_Registrant_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Domain_Registrant_Info_Avg_Fields = {
  __typename?: 'Domain_registrant_info_avg_fields';
  domain_registration_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Domain_registrant_info". All fields are combined with a logical 'AND'. */
export type Domain_Registrant_Info_Bool_Exp = {
  _and?: Maybe<Array<Domain_Registrant_Info_Bool_Exp>>;
  _not?: Maybe<Domain_Registrant_Info_Bool_Exp>;
  _or?: Maybe<Array<Domain_Registrant_Info_Bool_Exp>>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  domain_registration_id?: Maybe<Bigint_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  postal_code?: Maybe<String_Comparison_Exp>;
  state_or_province?: Maybe<String_Comparison_Exp>;
  street?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Domain_registrant_info" */
export enum Domain_Registrant_Info_Constraint {
  /** unique or primary key constraint on columns "domain_registration_id" */
  DomainRegistrantInfoPkey = 'Domain_registrant_info_pkey'
}

/** input type for incrementing numeric columns in table "Domain_registrant_info" */
export type Domain_Registrant_Info_Inc_Input = {
  domain_registration_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Domain_registrant_info" */
export type Domain_Registrant_Info_Insert_Input = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  domain_registration_id?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state_or_province?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Domain_Registrant_Info_Max_Fields = {
  __typename?: 'Domain_registrant_info_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  domain_registration_id?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state_or_province?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Domain_Registrant_Info_Min_Fields = {
  __typename?: 'Domain_registrant_info_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  domain_registration_id?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state_or_province?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Domain_registrant_info" */
export type Domain_Registrant_Info_Mutation_Response = {
  __typename?: 'Domain_registrant_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Domain_Registrant_Info>;
};

/** input type for inserting object relation for remote table "Domain_registrant_info" */
export type Domain_Registrant_Info_Obj_Rel_Insert_Input = {
  data: Domain_Registrant_Info_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Domain_Registrant_Info_On_Conflict>;
};

/** on_conflict condition type for table "Domain_registrant_info" */
export type Domain_Registrant_Info_On_Conflict = {
  constraint: Domain_Registrant_Info_Constraint;
  update_columns?: Array<Domain_Registrant_Info_Update_Column>;
  where?: Maybe<Domain_Registrant_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "Domain_registrant_info". */
export type Domain_Registrant_Info_Order_By = {
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  domain_registration_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  state_or_province?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
};

/** primary key columns input for table: Domain_registrant_info */
export type Domain_Registrant_Info_Pk_Columns_Input = {
  domain_registration_id: Scalars['bigint'];
};

/** select columns of table "Domain_registrant_info" */
export enum Domain_Registrant_Info_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  DomainRegistrationId = 'domain_registration_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  StateOrProvince = 'state_or_province',
  /** column name */
  Street = 'street'
}

/** input type for updating data in table "Domain_registrant_info" */
export type Domain_Registrant_Info_Set_Input = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  domain_registration_id?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state_or_province?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Domain_Registrant_Info_Stddev_Fields = {
  __typename?: 'Domain_registrant_info_stddev_fields';
  domain_registration_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Domain_Registrant_Info_Stddev_Pop_Fields = {
  __typename?: 'Domain_registrant_info_stddev_pop_fields';
  domain_registration_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Domain_Registrant_Info_Stddev_Samp_Fields = {
  __typename?: 'Domain_registrant_info_stddev_samp_fields';
  domain_registration_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Domain_Registrant_Info_Sum_Fields = {
  __typename?: 'Domain_registrant_info_sum_fields';
  domain_registration_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Domain_registrant_info" */
export enum Domain_Registrant_Info_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  DomainRegistrationId = 'domain_registration_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  StateOrProvince = 'state_or_province',
  /** column name */
  Street = 'street'
}

/** aggregate var_pop on columns */
export type Domain_Registrant_Info_Var_Pop_Fields = {
  __typename?: 'Domain_registrant_info_var_pop_fields';
  domain_registration_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Domain_Registrant_Info_Var_Samp_Fields = {
  __typename?: 'Domain_registrant_info_var_samp_fields';
  domain_registration_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Domain_Registrant_Info_Variance_Fields = {
  __typename?: 'Domain_registrant_info_variance_fields';
  domain_registration_id?: Maybe<Scalars['Float']>;
};

/** List of domains registered and managed by Kaleido (for SuperSite) */
export type Domain_Registration = {
  __typename?: 'Domain_registration';
  billed_renewal_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  connected_domain?: Maybe<Connected_Domain>;
  connected_domain_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  domain_name: Scalars['String'];
  expires_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  register_price_currency: Scalars['String'];
  register_price_units: Scalars['String'];
  registered_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  registrant_info?: Maybe<Domain_Registrant_Info>;
  renewed_at?: Maybe<Scalars['timestamptz']>;
  status: Enum_Domain_Registration_Status_Enum;
  tld: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Domain_registration" */
export type Domain_Registration_Aggregate = {
  __typename?: 'Domain_registration_aggregate';
  aggregate?: Maybe<Domain_Registration_Aggregate_Fields>;
  nodes: Array<Domain_Registration>;
};

/** aggregate fields of "Domain_registration" */
export type Domain_Registration_Aggregate_Fields = {
  __typename?: 'Domain_registration_aggregate_fields';
  avg?: Maybe<Domain_Registration_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Domain_Registration_Max_Fields>;
  min?: Maybe<Domain_Registration_Min_Fields>;
  stddev?: Maybe<Domain_Registration_Stddev_Fields>;
  stddev_pop?: Maybe<Domain_Registration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Domain_Registration_Stddev_Samp_Fields>;
  sum?: Maybe<Domain_Registration_Sum_Fields>;
  var_pop?: Maybe<Domain_Registration_Var_Pop_Fields>;
  var_samp?: Maybe<Domain_Registration_Var_Samp_Fields>;
  variance?: Maybe<Domain_Registration_Variance_Fields>;
};


/** aggregate fields of "Domain_registration" */
export type Domain_Registration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Domain_Registration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Domain_Registration_Avg_Fields = {
  __typename?: 'Domain_registration_avg_fields';
  connected_domain_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Domain_registration". All fields are combined with a logical 'AND'. */
export type Domain_Registration_Bool_Exp = {
  _and?: Maybe<Array<Domain_Registration_Bool_Exp>>;
  _not?: Maybe<Domain_Registration_Bool_Exp>;
  _or?: Maybe<Array<Domain_Registration_Bool_Exp>>;
  billed_renewal_at?: Maybe<Timestamptz_Comparison_Exp>;
  connected_domain?: Maybe<Connected_Domain_Bool_Exp>;
  connected_domain_id?: Maybe<Int_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  domain_name?: Maybe<String_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  register_price_currency?: Maybe<String_Comparison_Exp>;
  register_price_units?: Maybe<String_Comparison_Exp>;
  registered_at?: Maybe<Timestamptz_Comparison_Exp>;
  registrant_info?: Maybe<Domain_Registrant_Info_Bool_Exp>;
  renewed_at?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<Enum_Domain_Registration_Status_Enum_Comparison_Exp>;
  tld?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Domain_registration" */
export enum Domain_Registration_Constraint {
  /** unique or primary key constraint on columns "connected_domain_id" */
  DomainRegistrationConnectedDomainIdKey = 'Domain_registration_connected_domain_id_key',
  /** unique or primary key constraint on columns "context_id" */
  DomainRegistrationContextIdKey = 'Domain_registration_context_id_key',
  /** unique or primary key constraint on columns "domain_name" */
  DomainRegistrationDomainNameKey = 'Domain_registration_domain_name_key',
  /** unique or primary key constraint on columns "id" */
  DomainRegistrationPkey = 'Domain_registration_pkey'
}

/** input type for incrementing numeric columns in table "Domain_registration" */
export type Domain_Registration_Inc_Input = {
  connected_domain_id?: Maybe<Scalars['Int']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Domain_registration" */
export type Domain_Registration_Insert_Input = {
  billed_renewal_at?: Maybe<Scalars['timestamptz']>;
  connected_domain?: Maybe<Connected_Domain_Obj_Rel_Insert_Input>;
  connected_domain_id?: Maybe<Scalars['Int']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  domain_name?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  register_price_currency?: Maybe<Scalars['String']>;
  register_price_units?: Maybe<Scalars['String']>;
  registered_at?: Maybe<Scalars['timestamptz']>;
  registrant_info?: Maybe<Domain_Registrant_Info_Obj_Rel_Insert_Input>;
  renewed_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Enum_Domain_Registration_Status_Enum>;
  tld?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Domain_Registration_Max_Fields = {
  __typename?: 'Domain_registration_max_fields';
  billed_renewal_at?: Maybe<Scalars['timestamptz']>;
  connected_domain_id?: Maybe<Scalars['Int']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  domain_name?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  register_price_currency?: Maybe<Scalars['String']>;
  register_price_units?: Maybe<Scalars['String']>;
  registered_at?: Maybe<Scalars['timestamptz']>;
  renewed_at?: Maybe<Scalars['timestamptz']>;
  tld?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Domain_Registration_Min_Fields = {
  __typename?: 'Domain_registration_min_fields';
  billed_renewal_at?: Maybe<Scalars['timestamptz']>;
  connected_domain_id?: Maybe<Scalars['Int']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  domain_name?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  register_price_currency?: Maybe<Scalars['String']>;
  register_price_units?: Maybe<Scalars['String']>;
  registered_at?: Maybe<Scalars['timestamptz']>;
  renewed_at?: Maybe<Scalars['timestamptz']>;
  tld?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Domain_registration" */
export type Domain_Registration_Mutation_Response = {
  __typename?: 'Domain_registration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Domain_Registration>;
};

/** on_conflict condition type for table "Domain_registration" */
export type Domain_Registration_On_Conflict = {
  constraint: Domain_Registration_Constraint;
  update_columns?: Array<Domain_Registration_Update_Column>;
  where?: Maybe<Domain_Registration_Bool_Exp>;
};

/** Ordering options when selecting data from "Domain_registration". */
export type Domain_Registration_Order_By = {
  billed_renewal_at?: Maybe<Order_By>;
  connected_domain?: Maybe<Connected_Domain_Order_By>;
  connected_domain_id?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  domain_name?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  register_price_currency?: Maybe<Order_By>;
  register_price_units?: Maybe<Order_By>;
  registered_at?: Maybe<Order_By>;
  registrant_info?: Maybe<Domain_Registrant_Info_Order_By>;
  renewed_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tld?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Domain_registration */
export type Domain_Registration_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Domain_registration" */
export enum Domain_Registration_Select_Column {
  /** column name */
  BilledRenewalAt = 'billed_renewal_at',
  /** column name */
  ConnectedDomainId = 'connected_domain_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DomainName = 'domain_name',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  RegisterPriceCurrency = 'register_price_currency',
  /** column name */
  RegisterPriceUnits = 'register_price_units',
  /** column name */
  RegisteredAt = 'registered_at',
  /** column name */
  RenewedAt = 'renewed_at',
  /** column name */
  Status = 'status',
  /** column name */
  Tld = 'tld',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Domain_registration" */
export type Domain_Registration_Set_Input = {
  billed_renewal_at?: Maybe<Scalars['timestamptz']>;
  connected_domain_id?: Maybe<Scalars['Int']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  domain_name?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  register_price_currency?: Maybe<Scalars['String']>;
  register_price_units?: Maybe<Scalars['String']>;
  registered_at?: Maybe<Scalars['timestamptz']>;
  renewed_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Enum_Domain_Registration_Status_Enum>;
  tld?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Domain_Registration_Stddev_Fields = {
  __typename?: 'Domain_registration_stddev_fields';
  connected_domain_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Domain_Registration_Stddev_Pop_Fields = {
  __typename?: 'Domain_registration_stddev_pop_fields';
  connected_domain_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Domain_Registration_Stddev_Samp_Fields = {
  __typename?: 'Domain_registration_stddev_samp_fields';
  connected_domain_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Domain_Registration_Sum_Fields = {
  __typename?: 'Domain_registration_sum_fields';
  connected_domain_id?: Maybe<Scalars['Int']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Domain_registration" */
export enum Domain_Registration_Update_Column {
  /** column name */
  BilledRenewalAt = 'billed_renewal_at',
  /** column name */
  ConnectedDomainId = 'connected_domain_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DomainName = 'domain_name',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  RegisterPriceCurrency = 'register_price_currency',
  /** column name */
  RegisterPriceUnits = 'register_price_units',
  /** column name */
  RegisteredAt = 'registered_at',
  /** column name */
  RenewedAt = 'renewed_at',
  /** column name */
  Status = 'status',
  /** column name */
  Tld = 'tld',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Domain_Registration_Var_Pop_Fields = {
  __typename?: 'Domain_registration_var_pop_fields';
  connected_domain_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Domain_Registration_Var_Samp_Fields = {
  __typename?: 'Domain_registration_var_samp_fields';
  connected_domain_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Domain_Registration_Variance_Fields = {
  __typename?: 'Domain_registration_variance_fields';
  connected_domain_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Dummy_table_for_front" */
export type Dummy_Table_For_Front = {
  __typename?: 'Dummy_table_for_front';
  age?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_id_new?: Maybe<Scalars['bigint']>;
  artwork_type_enum_val?: Maybe<Enum_Artwork_Type_Enum>;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  jsonb?: Maybe<Scalars['jsonb']>;
  migrationTest1?: Maybe<Scalars['String']>;
  migrationTest2?: Maybe<Scalars['Int']>;
  migrationTest3?: Maybe<Scalars['Int']>;
  migrationTest4?: Maybe<Scalars['Int']>;
  migrationTest5?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationGroup?: Maybe<Enum_Notification_Group_Enum>;
  pass?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  uuid?: Maybe<Scalars['uuid']>;
  website_filters?: Maybe<Enum_My_Website_Filters_Enum>;
  website_theme?: Maybe<Enum_My_Website_Theme_Enum>;
};


/** columns and relationships of "Dummy_table_for_front" */
export type Dummy_Table_For_FrontJsonbArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Dummy_table_for_front" */
export type Dummy_Table_For_Front_Aggregate = {
  __typename?: 'Dummy_table_for_front_aggregate';
  aggregate?: Maybe<Dummy_Table_For_Front_Aggregate_Fields>;
  nodes: Array<Dummy_Table_For_Front>;
};

/** aggregate fields of "Dummy_table_for_front" */
export type Dummy_Table_For_Front_Aggregate_Fields = {
  __typename?: 'Dummy_table_for_front_aggregate_fields';
  avg?: Maybe<Dummy_Table_For_Front_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dummy_Table_For_Front_Max_Fields>;
  min?: Maybe<Dummy_Table_For_Front_Min_Fields>;
  stddev?: Maybe<Dummy_Table_For_Front_Stddev_Fields>;
  stddev_pop?: Maybe<Dummy_Table_For_Front_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dummy_Table_For_Front_Stddev_Samp_Fields>;
  sum?: Maybe<Dummy_Table_For_Front_Sum_Fields>;
  var_pop?: Maybe<Dummy_Table_For_Front_Var_Pop_Fields>;
  var_samp?: Maybe<Dummy_Table_For_Front_Var_Samp_Fields>;
  variance?: Maybe<Dummy_Table_For_Front_Variance_Fields>;
};


/** aggregate fields of "Dummy_table_for_front" */
export type Dummy_Table_For_Front_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dummy_Table_For_Front_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dummy_Table_For_Front_Append_Input = {
  jsonb?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Dummy_Table_For_Front_Avg_Fields = {
  __typename?: 'Dummy_table_for_front_avg_fields';
  age?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_id_new?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  migrationTest2?: Maybe<Scalars['Float']>;
  migrationTest3?: Maybe<Scalars['Float']>;
  migrationTest4?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Dummy_table_for_front". All fields are combined with a logical 'AND'. */
export type Dummy_Table_For_Front_Bool_Exp = {
  _and?: Maybe<Array<Dummy_Table_For_Front_Bool_Exp>>;
  _not?: Maybe<Dummy_Table_For_Front_Bool_Exp>;
  _or?: Maybe<Array<Dummy_Table_For_Front_Bool_Exp>>;
  age?: Maybe<Int_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id_new?: Maybe<Bigint_Comparison_Exp>;
  artwork_type_enum_val?: Maybe<Enum_Artwork_Type_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  jsonb?: Maybe<Jsonb_Comparison_Exp>;
  migrationTest1?: Maybe<String_Comparison_Exp>;
  migrationTest2?: Maybe<Int_Comparison_Exp>;
  migrationTest3?: Maybe<Int_Comparison_Exp>;
  migrationTest4?: Maybe<Int_Comparison_Exp>;
  migrationTest5?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notificationGroup?: Maybe<Enum_Notification_Group_Enum_Comparison_Exp>;
  pass?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid?: Maybe<Uuid_Comparison_Exp>;
  website_filters?: Maybe<Enum_My_Website_Filters_Enum_Comparison_Exp>;
  website_theme?: Maybe<Enum_My_Website_Theme_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "Dummy_table_for_front" */
export enum Dummy_Table_For_Front_Constraint {
  /** unique or primary key constraint on columns "id" */
  DummyTableForFrontIdKey = 'Dummy_table_for_front_id_key',
  /** unique or primary key constraint on columns "id" */
  DummyTableForFrontPkey = 'Dummy_table_for_front_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dummy_Table_For_Front_Delete_At_Path_Input = {
  jsonb?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dummy_Table_For_Front_Delete_Elem_Input = {
  jsonb?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dummy_Table_For_Front_Delete_Key_Input = {
  jsonb?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Dummy_table_for_front" */
export type Dummy_Table_For_Front_Inc_Input = {
  age?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_id_new?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  migrationTest2?: Maybe<Scalars['Int']>;
  migrationTest3?: Maybe<Scalars['Int']>;
  migrationTest4?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Dummy_table_for_front" */
export type Dummy_Table_For_Front_Insert_Input = {
  age?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_id_new?: Maybe<Scalars['bigint']>;
  artwork_type_enum_val?: Maybe<Enum_Artwork_Type_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  jsonb?: Maybe<Scalars['jsonb']>;
  migrationTest1?: Maybe<Scalars['String']>;
  migrationTest2?: Maybe<Scalars['Int']>;
  migrationTest3?: Maybe<Scalars['Int']>;
  migrationTest4?: Maybe<Scalars['Int']>;
  migrationTest5?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationGroup?: Maybe<Enum_Notification_Group_Enum>;
  pass?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
  website_filters?: Maybe<Enum_My_Website_Filters_Enum>;
  website_theme?: Maybe<Enum_My_Website_Theme_Enum>;
};

/** aggregate max on columns */
export type Dummy_Table_For_Front_Max_Fields = {
  __typename?: 'Dummy_table_for_front_max_fields';
  age?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_id_new?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  migrationTest1?: Maybe<Scalars['String']>;
  migrationTest2?: Maybe<Scalars['Int']>;
  migrationTest3?: Maybe<Scalars['Int']>;
  migrationTest4?: Maybe<Scalars['Int']>;
  migrationTest5?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Dummy_Table_For_Front_Min_Fields = {
  __typename?: 'Dummy_table_for_front_min_fields';
  age?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_id_new?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  migrationTest1?: Maybe<Scalars['String']>;
  migrationTest2?: Maybe<Scalars['Int']>;
  migrationTest3?: Maybe<Scalars['Int']>;
  migrationTest4?: Maybe<Scalars['Int']>;
  migrationTest5?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "Dummy_table_for_front" */
export type Dummy_Table_For_Front_Mutation_Response = {
  __typename?: 'Dummy_table_for_front_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dummy_Table_For_Front>;
};

/** on_conflict condition type for table "Dummy_table_for_front" */
export type Dummy_Table_For_Front_On_Conflict = {
  constraint: Dummy_Table_For_Front_Constraint;
  update_columns?: Array<Dummy_Table_For_Front_Update_Column>;
  where?: Maybe<Dummy_Table_For_Front_Bool_Exp>;
};

/** Ordering options when selecting data from "Dummy_table_for_front". */
export type Dummy_Table_For_Front_Order_By = {
  age?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_id_new?: Maybe<Order_By>;
  artwork_type_enum_val?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jsonb?: Maybe<Order_By>;
  migrationTest1?: Maybe<Order_By>;
  migrationTest2?: Maybe<Order_By>;
  migrationTest3?: Maybe<Order_By>;
  migrationTest4?: Maybe<Order_By>;
  migrationTest5?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notificationGroup?: Maybe<Order_By>;
  pass?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid?: Maybe<Order_By>;
  website_filters?: Maybe<Order_By>;
  website_theme?: Maybe<Order_By>;
};

/** primary key columns input for table: Dummy_table_for_front */
export type Dummy_Table_For_Front_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dummy_Table_For_Front_Prepend_Input = {
  jsonb?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Dummy_table_for_front" */
export enum Dummy_Table_For_Front_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkIdNew = 'artwork_id_new',
  /** column name */
  ArtworkTypeEnumVal = 'artwork_type_enum_val',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Jsonb = 'jsonb',
  /** column name */
  MigrationTest1 = 'migrationTest1',
  /** column name */
  MigrationTest2 = 'migrationTest2',
  /** column name */
  MigrationTest3 = 'migrationTest3',
  /** column name */
  MigrationTest4 = 'migrationTest4',
  /** column name */
  MigrationTest5 = 'migrationTest5',
  /** column name */
  Name = 'name',
  /** column name */
  NotificationGroup = 'notificationGroup',
  /** column name */
  Pass = 'pass',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  WebsiteFilters = 'website_filters',
  /** column name */
  WebsiteTheme = 'website_theme'
}

/** input type for updating data in table "Dummy_table_for_front" */
export type Dummy_Table_For_Front_Set_Input = {
  age?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_id_new?: Maybe<Scalars['bigint']>;
  artwork_type_enum_val?: Maybe<Enum_Artwork_Type_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  jsonb?: Maybe<Scalars['jsonb']>;
  migrationTest1?: Maybe<Scalars['String']>;
  migrationTest2?: Maybe<Scalars['Int']>;
  migrationTest3?: Maybe<Scalars['Int']>;
  migrationTest4?: Maybe<Scalars['Int']>;
  migrationTest5?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationGroup?: Maybe<Enum_Notification_Group_Enum>;
  pass?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid?: Maybe<Scalars['uuid']>;
  website_filters?: Maybe<Enum_My_Website_Filters_Enum>;
  website_theme?: Maybe<Enum_My_Website_Theme_Enum>;
};

/** aggregate stddev on columns */
export type Dummy_Table_For_Front_Stddev_Fields = {
  __typename?: 'Dummy_table_for_front_stddev_fields';
  age?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_id_new?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  migrationTest2?: Maybe<Scalars['Float']>;
  migrationTest3?: Maybe<Scalars['Float']>;
  migrationTest4?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dummy_Table_For_Front_Stddev_Pop_Fields = {
  __typename?: 'Dummy_table_for_front_stddev_pop_fields';
  age?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_id_new?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  migrationTest2?: Maybe<Scalars['Float']>;
  migrationTest3?: Maybe<Scalars['Float']>;
  migrationTest4?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dummy_Table_For_Front_Stddev_Samp_Fields = {
  __typename?: 'Dummy_table_for_front_stddev_samp_fields';
  age?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_id_new?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  migrationTest2?: Maybe<Scalars['Float']>;
  migrationTest3?: Maybe<Scalars['Float']>;
  migrationTest4?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dummy_Table_For_Front_Sum_Fields = {
  __typename?: 'Dummy_table_for_front_sum_fields';
  age?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_id_new?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  migrationTest2?: Maybe<Scalars['Int']>;
  migrationTest3?: Maybe<Scalars['Int']>;
  migrationTest4?: Maybe<Scalars['Int']>;
};

/** update columns of table "Dummy_table_for_front" */
export enum Dummy_Table_For_Front_Update_Column {
  /** column name */
  Age = 'age',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkIdNew = 'artwork_id_new',
  /** column name */
  ArtworkTypeEnumVal = 'artwork_type_enum_val',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Jsonb = 'jsonb',
  /** column name */
  MigrationTest1 = 'migrationTest1',
  /** column name */
  MigrationTest2 = 'migrationTest2',
  /** column name */
  MigrationTest3 = 'migrationTest3',
  /** column name */
  MigrationTest4 = 'migrationTest4',
  /** column name */
  MigrationTest5 = 'migrationTest5',
  /** column name */
  Name = 'name',
  /** column name */
  NotificationGroup = 'notificationGroup',
  /** column name */
  Pass = 'pass',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  WebsiteFilters = 'website_filters',
  /** column name */
  WebsiteTheme = 'website_theme'
}

/** aggregate var_pop on columns */
export type Dummy_Table_For_Front_Var_Pop_Fields = {
  __typename?: 'Dummy_table_for_front_var_pop_fields';
  age?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_id_new?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  migrationTest2?: Maybe<Scalars['Float']>;
  migrationTest3?: Maybe<Scalars['Float']>;
  migrationTest4?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dummy_Table_For_Front_Var_Samp_Fields = {
  __typename?: 'Dummy_table_for_front_var_samp_fields';
  age?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_id_new?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  migrationTest2?: Maybe<Scalars['Float']>;
  migrationTest3?: Maybe<Scalars['Float']>;
  migrationTest4?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dummy_Table_For_Front_Variance_Fields = {
  __typename?: 'Dummy_table_for_front_variance_fields';
  age?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_id_new?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  migrationTest2?: Maybe<Scalars['Float']>;
  migrationTest3?: Maybe<Scalars['Float']>;
  migrationTest4?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Dynamic_cv" */
export type Dynamic_Cv = {
  __typename?: 'Dynamic_cv';
  artist_context_id: Scalars['bigint'];
  /** An array relationship */
  awards: Array<Dynamic_Cv_Award>;
  /** An aggregate relationship */
  awards_aggregate: Dynamic_Cv_Award_Aggregate;
  birth_year?: Maybe<Scalars['Int']>;
  /** An object relationship */
  city?: Maybe<Worldcities>;
  city_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  collections: Array<Dynamic_Cv_Collection>;
  /** An aggregate relationship */
  collections_aggregate: Dynamic_Cv_Collection_Aggregate;
  /** An object relationship */
  contact?: Maybe<Dynamic_Cv_Contact>;
  /** An object relationship */
  context: Context;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  education: Array<Dynamic_Cv_Education>;
  /** An aggregate relationship */
  education_aggregate: Dynamic_Cv_Education_Aggregate;
  /** An array relationship */
  exhibitions: Array<Dynamic_Cv_Exhibition>;
  /** An aggregate relationship */
  exhibitions_aggregate: Dynamic_Cv_Exhibition_Aggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  publications: Array<Dynamic_Cv_Publication>;
  /** An aggregate relationship */
  publications_aggregate: Dynamic_Cv_Publication_Aggregate;
  published_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  sections: Array<Dynamic_Cv_Section>;
  /** An aggregate relationship */
  sections_aggregate: Dynamic_Cv_Section_Aggregate;
  status: Enum_Dynamic_Cv_Status_Enum;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvAwardsArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Award_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Award_Order_By>>;
  where?: Maybe<Dynamic_Cv_Award_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvAwards_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Award_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Award_Order_By>>;
  where?: Maybe<Dynamic_Cv_Award_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvCollectionsArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Collection_Order_By>>;
  where?: Maybe<Dynamic_Cv_Collection_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvCollections_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Collection_Order_By>>;
  where?: Maybe<Dynamic_Cv_Collection_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvEducationArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Education_Order_By>>;
  where?: Maybe<Dynamic_Cv_Education_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvEducation_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Education_Order_By>>;
  where?: Maybe<Dynamic_Cv_Education_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvExhibitionsArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Exhibition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Exhibition_Order_By>>;
  where?: Maybe<Dynamic_Cv_Exhibition_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvExhibitions_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Exhibition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Exhibition_Order_By>>;
  where?: Maybe<Dynamic_Cv_Exhibition_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvPublicationsArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Publication_Order_By>>;
  where?: Maybe<Dynamic_Cv_Publication_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvPublications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Publication_Order_By>>;
  where?: Maybe<Dynamic_Cv_Publication_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvSectionsArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Section_Order_By>>;
  where?: Maybe<Dynamic_Cv_Section_Bool_Exp>;
};


/** columns and relationships of "Dynamic_cv" */
export type Dynamic_CvSections_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Section_Order_By>>;
  where?: Maybe<Dynamic_Cv_Section_Bool_Exp>;
};

/** aggregated selection of "Dynamic_cv" */
export type Dynamic_Cv_Aggregate = {
  __typename?: 'Dynamic_cv_aggregate';
  aggregate?: Maybe<Dynamic_Cv_Aggregate_Fields>;
  nodes: Array<Dynamic_Cv>;
};

/** aggregate fields of "Dynamic_cv" */
export type Dynamic_Cv_Aggregate_Fields = {
  __typename?: 'Dynamic_cv_aggregate_fields';
  avg?: Maybe<Dynamic_Cv_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dynamic_Cv_Max_Fields>;
  min?: Maybe<Dynamic_Cv_Min_Fields>;
  stddev?: Maybe<Dynamic_Cv_Stddev_Fields>;
  stddev_pop?: Maybe<Dynamic_Cv_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dynamic_Cv_Stddev_Samp_Fields>;
  sum?: Maybe<Dynamic_Cv_Sum_Fields>;
  var_pop?: Maybe<Dynamic_Cv_Var_Pop_Fields>;
  var_samp?: Maybe<Dynamic_Cv_Var_Samp_Fields>;
  variance?: Maybe<Dynamic_Cv_Variance_Fields>;
};


/** aggregate fields of "Dynamic_cv" */
export type Dynamic_Cv_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dynamic_Cv_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dynamic_Cv_Avg_Fields = {
  __typename?: 'Dynamic_cv_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  birth_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Dynamic_cv_award" */
export type Dynamic_Cv_Award = {
  __typename?: 'Dynamic_cv_award';
  /** An object relationship */
  Dynamic_cv: Dynamic_Cv;
  /** An object relationship */
  city?: Maybe<Worldcities>;
  city_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cv_id: Scalars['bigint'];
  id: Scalars['bigint'];
  institution_name?: Maybe<Scalars['String']>;
  is_hidden: Scalars['Boolean'];
  is_kaleido_prize: Scalars['Boolean'];
  kaleido_prize_show_slug?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  year: Scalars['Int'];
};

/** aggregated selection of "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Aggregate = {
  __typename?: 'Dynamic_cv_award_aggregate';
  aggregate?: Maybe<Dynamic_Cv_Award_Aggregate_Fields>;
  nodes: Array<Dynamic_Cv_Award>;
};

/** aggregate fields of "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Aggregate_Fields = {
  __typename?: 'Dynamic_cv_award_aggregate_fields';
  avg?: Maybe<Dynamic_Cv_Award_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dynamic_Cv_Award_Max_Fields>;
  min?: Maybe<Dynamic_Cv_Award_Min_Fields>;
  stddev?: Maybe<Dynamic_Cv_Award_Stddev_Fields>;
  stddev_pop?: Maybe<Dynamic_Cv_Award_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dynamic_Cv_Award_Stddev_Samp_Fields>;
  sum?: Maybe<Dynamic_Cv_Award_Sum_Fields>;
  var_pop?: Maybe<Dynamic_Cv_Award_Var_Pop_Fields>;
  var_samp?: Maybe<Dynamic_Cv_Award_Var_Samp_Fields>;
  variance?: Maybe<Dynamic_Cv_Award_Variance_Fields>;
};


/** aggregate fields of "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dynamic_Cv_Award_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Aggregate_Order_By = {
  avg?: Maybe<Dynamic_Cv_Award_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dynamic_Cv_Award_Max_Order_By>;
  min?: Maybe<Dynamic_Cv_Award_Min_Order_By>;
  stddev?: Maybe<Dynamic_Cv_Award_Stddev_Order_By>;
  stddev_pop?: Maybe<Dynamic_Cv_Award_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dynamic_Cv_Award_Stddev_Samp_Order_By>;
  sum?: Maybe<Dynamic_Cv_Award_Sum_Order_By>;
  var_pop?: Maybe<Dynamic_Cv_Award_Var_Pop_Order_By>;
  var_samp?: Maybe<Dynamic_Cv_Award_Var_Samp_Order_By>;
  variance?: Maybe<Dynamic_Cv_Award_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Arr_Rel_Insert_Input = {
  data: Array<Dynamic_Cv_Award_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Dynamic_Cv_Award_On_Conflict>;
};

/** aggregate avg on columns */
export type Dynamic_Cv_Award_Avg_Fields = {
  __typename?: 'Dynamic_cv_award_avg_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Avg_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Dynamic_cv_award". All fields are combined with a logical 'AND'. */
export type Dynamic_Cv_Award_Bool_Exp = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Bool_Exp>;
  _and?: Maybe<Array<Dynamic_Cv_Award_Bool_Exp>>;
  _not?: Maybe<Dynamic_Cv_Award_Bool_Exp>;
  _or?: Maybe<Array<Dynamic_Cv_Award_Bool_Exp>>;
  city?: Maybe<Worldcities_Bool_Exp>;
  city_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cv_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  institution_name?: Maybe<String_Comparison_Exp>;
  is_hidden?: Maybe<Boolean_Comparison_Exp>;
  is_kaleido_prize?: Maybe<Boolean_Comparison_Exp>;
  kaleido_prize_show_slug?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Dynamic_cv_award" */
export enum Dynamic_Cv_Award_Constraint {
  /** unique or primary key constraint on columns "id" */
  DynamicCvAwardPkey = 'Dynamic_cv_award_pkey'
}

/** input type for incrementing numeric columns in table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Inc_Input = {
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Insert_Input = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Obj_Rel_Insert_Input>;
  city?: Maybe<Worldcities_Obj_Rel_Insert_Input>;
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  is_hidden?: Maybe<Scalars['Boolean']>;
  is_kaleido_prize?: Maybe<Scalars['Boolean']>;
  kaleido_prize_show_slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dynamic_Cv_Award_Max_Fields = {
  __typename?: 'Dynamic_cv_award_max_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  kaleido_prize_show_slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Max_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  institution_name?: Maybe<Order_By>;
  kaleido_prize_show_slug?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dynamic_Cv_Award_Min_Fields = {
  __typename?: 'Dynamic_cv_award_min_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  kaleido_prize_show_slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Min_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  institution_name?: Maybe<Order_By>;
  kaleido_prize_show_slug?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Mutation_Response = {
  __typename?: 'Dynamic_cv_award_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dynamic_Cv_Award>;
};

/** on_conflict condition type for table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_On_Conflict = {
  constraint: Dynamic_Cv_Award_Constraint;
  update_columns?: Array<Dynamic_Cv_Award_Update_Column>;
  where?: Maybe<Dynamic_Cv_Award_Bool_Exp>;
};

/** Ordering options when selecting data from "Dynamic_cv_award". */
export type Dynamic_Cv_Award_Order_By = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Order_By>;
  city?: Maybe<Worldcities_Order_By>;
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  institution_name?: Maybe<Order_By>;
  is_hidden?: Maybe<Order_By>;
  is_kaleido_prize?: Maybe<Order_By>;
  kaleido_prize_show_slug?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: Dynamic_cv_award */
export type Dynamic_Cv_Award_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Dynamic_cv_award" */
export enum Dynamic_Cv_Award_Select_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionName = 'institution_name',
  /** column name */
  IsHidden = 'is_hidden',
  /** column name */
  IsKaleidoPrize = 'is_kaleido_prize',
  /** column name */
  KaleidoPrizeShowSlug = 'kaleido_prize_show_slug',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Set_Input = {
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  is_hidden?: Maybe<Scalars['Boolean']>;
  is_kaleido_prize?: Maybe<Scalars['Boolean']>;
  kaleido_prize_show_slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dynamic_Cv_Award_Stddev_Fields = {
  __typename?: 'Dynamic_cv_award_stddev_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Stddev_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dynamic_Cv_Award_Stddev_Pop_Fields = {
  __typename?: 'Dynamic_cv_award_stddev_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Stddev_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dynamic_Cv_Award_Stddev_Samp_Fields = {
  __typename?: 'Dynamic_cv_award_stddev_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Stddev_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dynamic_Cv_Award_Sum_Fields = {
  __typename?: 'Dynamic_cv_award_sum_fields';
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Sum_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "Dynamic_cv_award" */
export enum Dynamic_Cv_Award_Update_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionName = 'institution_name',
  /** column name */
  IsHidden = 'is_hidden',
  /** column name */
  IsKaleidoPrize = 'is_kaleido_prize',
  /** column name */
  KaleidoPrizeShowSlug = 'kaleido_prize_show_slug',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Dynamic_Cv_Award_Var_Pop_Fields = {
  __typename?: 'Dynamic_cv_award_var_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Var_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dynamic_Cv_Award_Var_Samp_Fields = {
  __typename?: 'Dynamic_cv_award_var_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Var_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dynamic_Cv_Award_Variance_Fields = {
  __typename?: 'Dynamic_cv_award_variance_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Dynamic_cv_award" */
export type Dynamic_Cv_Award_Variance_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Dynamic_cv". All fields are combined with a logical 'AND'. */
export type Dynamic_Cv_Bool_Exp = {
  _and?: Maybe<Array<Dynamic_Cv_Bool_Exp>>;
  _not?: Maybe<Dynamic_Cv_Bool_Exp>;
  _or?: Maybe<Array<Dynamic_Cv_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  awards?: Maybe<Dynamic_Cv_Award_Bool_Exp>;
  birth_year?: Maybe<Int_Comparison_Exp>;
  city?: Maybe<Worldcities_Bool_Exp>;
  city_id?: Maybe<String_Comparison_Exp>;
  collections?: Maybe<Dynamic_Cv_Collection_Bool_Exp>;
  contact?: Maybe<Dynamic_Cv_Contact_Bool_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  country_of_origin?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  education?: Maybe<Dynamic_Cv_Education_Bool_Exp>;
  exhibitions?: Maybe<Dynamic_Cv_Exhibition_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  publications?: Maybe<Dynamic_Cv_Publication_Bool_Exp>;
  published_at?: Maybe<Timestamptz_Comparison_Exp>;
  sections?: Maybe<Dynamic_Cv_Section_Bool_Exp>;
  status?: Maybe<Enum_Dynamic_Cv_Status_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection = {
  __typename?: 'Dynamic_cv_collection';
  /** An object relationship */
  Dynamic_cv: Dynamic_Cv;
  /** An object relationship */
  city?: Maybe<Worldcities>;
  city_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cv_id: Scalars['bigint'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  year?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Aggregate = {
  __typename?: 'Dynamic_cv_collection_aggregate';
  aggregate?: Maybe<Dynamic_Cv_Collection_Aggregate_Fields>;
  nodes: Array<Dynamic_Cv_Collection>;
};

/** aggregate fields of "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Aggregate_Fields = {
  __typename?: 'Dynamic_cv_collection_aggregate_fields';
  avg?: Maybe<Dynamic_Cv_Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dynamic_Cv_Collection_Max_Fields>;
  min?: Maybe<Dynamic_Cv_Collection_Min_Fields>;
  stddev?: Maybe<Dynamic_Cv_Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Dynamic_Cv_Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dynamic_Cv_Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Dynamic_Cv_Collection_Sum_Fields>;
  var_pop?: Maybe<Dynamic_Cv_Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Dynamic_Cv_Collection_Var_Samp_Fields>;
  variance?: Maybe<Dynamic_Cv_Collection_Variance_Fields>;
};


/** aggregate fields of "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dynamic_Cv_Collection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Aggregate_Order_By = {
  avg?: Maybe<Dynamic_Cv_Collection_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dynamic_Cv_Collection_Max_Order_By>;
  min?: Maybe<Dynamic_Cv_Collection_Min_Order_By>;
  stddev?: Maybe<Dynamic_Cv_Collection_Stddev_Order_By>;
  stddev_pop?: Maybe<Dynamic_Cv_Collection_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dynamic_Cv_Collection_Stddev_Samp_Order_By>;
  sum?: Maybe<Dynamic_Cv_Collection_Sum_Order_By>;
  var_pop?: Maybe<Dynamic_Cv_Collection_Var_Pop_Order_By>;
  var_samp?: Maybe<Dynamic_Cv_Collection_Var_Samp_Order_By>;
  variance?: Maybe<Dynamic_Cv_Collection_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Arr_Rel_Insert_Input = {
  data: Array<Dynamic_Cv_Collection_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Dynamic_Cv_Collection_On_Conflict>;
};

/** aggregate avg on columns */
export type Dynamic_Cv_Collection_Avg_Fields = {
  __typename?: 'Dynamic_cv_collection_avg_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Avg_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Dynamic_cv_collection". All fields are combined with a logical 'AND'. */
export type Dynamic_Cv_Collection_Bool_Exp = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Bool_Exp>;
  _and?: Maybe<Array<Dynamic_Cv_Collection_Bool_Exp>>;
  _not?: Maybe<Dynamic_Cv_Collection_Bool_Exp>;
  _or?: Maybe<Array<Dynamic_Cv_Collection_Bool_Exp>>;
  city?: Maybe<Worldcities_Bool_Exp>;
  city_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cv_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Dynamic_cv_collection" */
export enum Dynamic_Cv_Collection_Constraint {
  /** unique or primary key constraint on columns "id" */
  DynamicCvCollectionPkey = 'Dynamic_cv_collection_pkey'
}

/** input type for incrementing numeric columns in table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Inc_Input = {
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Insert_Input = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Obj_Rel_Insert_Input>;
  city?: Maybe<Worldcities_Obj_Rel_Insert_Input>;
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dynamic_Cv_Collection_Max_Fields = {
  __typename?: 'Dynamic_cv_collection_max_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Max_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dynamic_Cv_Collection_Min_Fields = {
  __typename?: 'Dynamic_cv_collection_min_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Min_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Mutation_Response = {
  __typename?: 'Dynamic_cv_collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dynamic_Cv_Collection>;
};

/** on_conflict condition type for table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_On_Conflict = {
  constraint: Dynamic_Cv_Collection_Constraint;
  update_columns?: Array<Dynamic_Cv_Collection_Update_Column>;
  where?: Maybe<Dynamic_Cv_Collection_Bool_Exp>;
};

/** Ordering options when selecting data from "Dynamic_cv_collection". */
export type Dynamic_Cv_Collection_Order_By = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Order_By>;
  city?: Maybe<Worldcities_Order_By>;
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: Dynamic_cv_collection */
export type Dynamic_Cv_Collection_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Dynamic_cv_collection" */
export enum Dynamic_Cv_Collection_Select_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Set_Input = {
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dynamic_Cv_Collection_Stddev_Fields = {
  __typename?: 'Dynamic_cv_collection_stddev_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Stddev_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dynamic_Cv_Collection_Stddev_Pop_Fields = {
  __typename?: 'Dynamic_cv_collection_stddev_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Stddev_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dynamic_Cv_Collection_Stddev_Samp_Fields = {
  __typename?: 'Dynamic_cv_collection_stddev_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Stddev_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dynamic_Cv_Collection_Sum_Fields = {
  __typename?: 'Dynamic_cv_collection_sum_fields';
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Sum_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "Dynamic_cv_collection" */
export enum Dynamic_Cv_Collection_Update_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Dynamic_Cv_Collection_Var_Pop_Fields = {
  __typename?: 'Dynamic_cv_collection_var_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Var_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dynamic_Cv_Collection_Var_Samp_Fields = {
  __typename?: 'Dynamic_cv_collection_var_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Var_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dynamic_Cv_Collection_Variance_Fields = {
  __typename?: 'Dynamic_cv_collection_variance_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Dynamic_cv_collection" */
export type Dynamic_Cv_Collection_Variance_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "Dynamic_cv" */
export enum Dynamic_Cv_Constraint {
  /** unique or primary key constraint on columns "artist_context_id" */
  DynamicCvArtistContextIdKey = 'Dynamic_cv_artist_context_id_key',
  /** unique or primary key constraint on columns "id" */
  DynamicCvPkey = 'Dynamic_cv_pkey'
}

/** columns and relationships of "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact = {
  __typename?: 'Dynamic_cv_contact';
  /** An object relationship */
  Dynamic_cv: Dynamic_Cv;
  address?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  cv_id: Scalars['bigint'];
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  website?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact_Aggregate = {
  __typename?: 'Dynamic_cv_contact_aggregate';
  aggregate?: Maybe<Dynamic_Cv_Contact_Aggregate_Fields>;
  nodes: Array<Dynamic_Cv_Contact>;
};

/** aggregate fields of "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact_Aggregate_Fields = {
  __typename?: 'Dynamic_cv_contact_aggregate_fields';
  avg?: Maybe<Dynamic_Cv_Contact_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dynamic_Cv_Contact_Max_Fields>;
  min?: Maybe<Dynamic_Cv_Contact_Min_Fields>;
  stddev?: Maybe<Dynamic_Cv_Contact_Stddev_Fields>;
  stddev_pop?: Maybe<Dynamic_Cv_Contact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dynamic_Cv_Contact_Stddev_Samp_Fields>;
  sum?: Maybe<Dynamic_Cv_Contact_Sum_Fields>;
  var_pop?: Maybe<Dynamic_Cv_Contact_Var_Pop_Fields>;
  var_samp?: Maybe<Dynamic_Cv_Contact_Var_Samp_Fields>;
  variance?: Maybe<Dynamic_Cv_Contact_Variance_Fields>;
};


/** aggregate fields of "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dynamic_Cv_Contact_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dynamic_Cv_Contact_Avg_Fields = {
  __typename?: 'Dynamic_cv_contact_avg_fields';
  cv_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Dynamic_cv_contact". All fields are combined with a logical 'AND'. */
export type Dynamic_Cv_Contact_Bool_Exp = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Bool_Exp>;
  _and?: Maybe<Array<Dynamic_Cv_Contact_Bool_Exp>>;
  _not?: Maybe<Dynamic_Cv_Contact_Bool_Exp>;
  _or?: Maybe<Array<Dynamic_Cv_Contact_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cv_id?: Maybe<Bigint_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  website?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Dynamic_cv_contact" */
export enum Dynamic_Cv_Contact_Constraint {
  /** unique or primary key constraint on columns "cv_id" */
  DynamicCvContactPkey = 'Dynamic_cv_contact_pkey'
}

/** input type for incrementing numeric columns in table "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact_Inc_Input = {
  cv_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact_Insert_Input = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Obj_Rel_Insert_Input>;
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dynamic_Cv_Contact_Max_Fields = {
  __typename?: 'Dynamic_cv_contact_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dynamic_Cv_Contact_Min_Fields = {
  __typename?: 'Dynamic_cv_contact_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact_Mutation_Response = {
  __typename?: 'Dynamic_cv_contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dynamic_Cv_Contact>;
};

/** input type for inserting object relation for remote table "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact_Obj_Rel_Insert_Input = {
  data: Dynamic_Cv_Contact_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Dynamic_Cv_Contact_On_Conflict>;
};

/** on_conflict condition type for table "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact_On_Conflict = {
  constraint: Dynamic_Cv_Contact_Constraint;
  update_columns?: Array<Dynamic_Cv_Contact_Update_Column>;
  where?: Maybe<Dynamic_Cv_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "Dynamic_cv_contact". */
export type Dynamic_Cv_Contact_Order_By = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Order_By>;
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** primary key columns input for table: Dynamic_cv_contact */
export type Dynamic_Cv_Contact_Pk_Columns_Input = {
  cv_id: Scalars['bigint'];
};

/** select columns of table "Dynamic_cv_contact" */
export enum Dynamic_Cv_Contact_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Email = 'email',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "Dynamic_cv_contact" */
export type Dynamic_Cv_Contact_Set_Input = {
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Dynamic_Cv_Contact_Stddev_Fields = {
  __typename?: 'Dynamic_cv_contact_stddev_fields';
  cv_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dynamic_Cv_Contact_Stddev_Pop_Fields = {
  __typename?: 'Dynamic_cv_contact_stddev_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dynamic_Cv_Contact_Stddev_Samp_Fields = {
  __typename?: 'Dynamic_cv_contact_stddev_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dynamic_Cv_Contact_Sum_Fields = {
  __typename?: 'Dynamic_cv_contact_sum_fields';
  cv_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Dynamic_cv_contact" */
export enum Dynamic_Cv_Contact_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Email = 'email',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website'
}

/** aggregate var_pop on columns */
export type Dynamic_Cv_Contact_Var_Pop_Fields = {
  __typename?: 'Dynamic_cv_contact_var_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dynamic_Cv_Contact_Var_Samp_Fields = {
  __typename?: 'Dynamic_cv_contact_var_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dynamic_Cv_Contact_Variance_Fields = {
  __typename?: 'Dynamic_cv_contact_variance_fields';
  cv_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Dynamic_cv_education" */
export type Dynamic_Cv_Education = {
  __typename?: 'Dynamic_cv_education';
  /** An object relationship */
  Dynamic_cv: Dynamic_Cv;
  /** An object relationship */
  city: Worldcities;
  city_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  currently_attending: Scalars['Boolean'];
  cv_id: Scalars['bigint'];
  degree: Scalars['String'];
  end_year: Scalars['Int'];
  id: Scalars['bigint'];
  institution_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Aggregate = {
  __typename?: 'Dynamic_cv_education_aggregate';
  aggregate?: Maybe<Dynamic_Cv_Education_Aggregate_Fields>;
  nodes: Array<Dynamic_Cv_Education>;
};

/** aggregate fields of "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Aggregate_Fields = {
  __typename?: 'Dynamic_cv_education_aggregate_fields';
  avg?: Maybe<Dynamic_Cv_Education_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dynamic_Cv_Education_Max_Fields>;
  min?: Maybe<Dynamic_Cv_Education_Min_Fields>;
  stddev?: Maybe<Dynamic_Cv_Education_Stddev_Fields>;
  stddev_pop?: Maybe<Dynamic_Cv_Education_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dynamic_Cv_Education_Stddev_Samp_Fields>;
  sum?: Maybe<Dynamic_Cv_Education_Sum_Fields>;
  var_pop?: Maybe<Dynamic_Cv_Education_Var_Pop_Fields>;
  var_samp?: Maybe<Dynamic_Cv_Education_Var_Samp_Fields>;
  variance?: Maybe<Dynamic_Cv_Education_Variance_Fields>;
};


/** aggregate fields of "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dynamic_Cv_Education_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Aggregate_Order_By = {
  avg?: Maybe<Dynamic_Cv_Education_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dynamic_Cv_Education_Max_Order_By>;
  min?: Maybe<Dynamic_Cv_Education_Min_Order_By>;
  stddev?: Maybe<Dynamic_Cv_Education_Stddev_Order_By>;
  stddev_pop?: Maybe<Dynamic_Cv_Education_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dynamic_Cv_Education_Stddev_Samp_Order_By>;
  sum?: Maybe<Dynamic_Cv_Education_Sum_Order_By>;
  var_pop?: Maybe<Dynamic_Cv_Education_Var_Pop_Order_By>;
  var_samp?: Maybe<Dynamic_Cv_Education_Var_Samp_Order_By>;
  variance?: Maybe<Dynamic_Cv_Education_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Arr_Rel_Insert_Input = {
  data: Array<Dynamic_Cv_Education_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Dynamic_Cv_Education_On_Conflict>;
};

/** aggregate avg on columns */
export type Dynamic_Cv_Education_Avg_Fields = {
  __typename?: 'Dynamic_cv_education_avg_fields';
  cv_id?: Maybe<Scalars['Float']>;
  end_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Avg_Order_By = {
  cv_id?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Dynamic_cv_education". All fields are combined with a logical 'AND'. */
export type Dynamic_Cv_Education_Bool_Exp = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Bool_Exp>;
  _and?: Maybe<Array<Dynamic_Cv_Education_Bool_Exp>>;
  _not?: Maybe<Dynamic_Cv_Education_Bool_Exp>;
  _or?: Maybe<Array<Dynamic_Cv_Education_Bool_Exp>>;
  city?: Maybe<Worldcities_Bool_Exp>;
  city_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currently_attending?: Maybe<Boolean_Comparison_Exp>;
  cv_id?: Maybe<Bigint_Comparison_Exp>;
  degree?: Maybe<String_Comparison_Exp>;
  end_year?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  institution_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Dynamic_cv_education" */
export enum Dynamic_Cv_Education_Constraint {
  /** unique or primary key constraint on columns "id" */
  DynamicCvEducationPkey = 'Dynamic_cv_education_pkey'
}

/** input type for incrementing numeric columns in table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Inc_Input = {
  cv_id?: Maybe<Scalars['bigint']>;
  end_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Insert_Input = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Obj_Rel_Insert_Input>;
  city?: Maybe<Worldcities_Obj_Rel_Insert_Input>;
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currently_attending?: Maybe<Scalars['Boolean']>;
  cv_id?: Maybe<Scalars['bigint']>;
  degree?: Maybe<Scalars['String']>;
  end_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Dynamic_Cv_Education_Max_Fields = {
  __typename?: 'Dynamic_cv_education_max_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  degree?: Maybe<Scalars['String']>;
  end_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Max_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  degree?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  institution_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dynamic_Cv_Education_Min_Fields = {
  __typename?: 'Dynamic_cv_education_min_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  degree?: Maybe<Scalars['String']>;
  end_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Min_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  degree?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  institution_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Mutation_Response = {
  __typename?: 'Dynamic_cv_education_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dynamic_Cv_Education>;
};

/** on_conflict condition type for table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_On_Conflict = {
  constraint: Dynamic_Cv_Education_Constraint;
  update_columns?: Array<Dynamic_Cv_Education_Update_Column>;
  where?: Maybe<Dynamic_Cv_Education_Bool_Exp>;
};

/** Ordering options when selecting data from "Dynamic_cv_education". */
export type Dynamic_Cv_Education_Order_By = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Order_By>;
  city?: Maybe<Worldcities_Order_By>;
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currently_attending?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  degree?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  institution_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Dynamic_cv_education */
export type Dynamic_Cv_Education_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Dynamic_cv_education" */
export enum Dynamic_Cv_Education_Select_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentlyAttending = 'currently_attending',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Degree = 'degree',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionName = 'institution_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Set_Input = {
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currently_attending?: Maybe<Scalars['Boolean']>;
  cv_id?: Maybe<Scalars['bigint']>;
  degree?: Maybe<Scalars['String']>;
  end_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Dynamic_Cv_Education_Stddev_Fields = {
  __typename?: 'Dynamic_cv_education_stddev_fields';
  cv_id?: Maybe<Scalars['Float']>;
  end_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Stddev_Order_By = {
  cv_id?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dynamic_Cv_Education_Stddev_Pop_Fields = {
  __typename?: 'Dynamic_cv_education_stddev_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  end_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Stddev_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dynamic_Cv_Education_Stddev_Samp_Fields = {
  __typename?: 'Dynamic_cv_education_stddev_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  end_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Stddev_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dynamic_Cv_Education_Sum_Fields = {
  __typename?: 'Dynamic_cv_education_sum_fields';
  cv_id?: Maybe<Scalars['bigint']>;
  end_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Sum_Order_By = {
  cv_id?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Dynamic_cv_education" */
export enum Dynamic_Cv_Education_Update_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentlyAttending = 'currently_attending',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Degree = 'degree',
  /** column name */
  EndYear = 'end_year',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionName = 'institution_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Dynamic_Cv_Education_Var_Pop_Fields = {
  __typename?: 'Dynamic_cv_education_var_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  end_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Var_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dynamic_Cv_Education_Var_Samp_Fields = {
  __typename?: 'Dynamic_cv_education_var_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  end_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Var_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dynamic_Cv_Education_Variance_Fields = {
  __typename?: 'Dynamic_cv_education_variance_fields';
  cv_id?: Maybe<Scalars['Float']>;
  end_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Dynamic_cv_education" */
export type Dynamic_Cv_Education_Variance_Order_By = {
  cv_id?: Maybe<Order_By>;
  end_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition = {
  __typename?: 'Dynamic_cv_exhibition';
  /** An object relationship */
  Dynamic_cv: Dynamic_Cv;
  /** An object relationship */
  city: Worldcities;
  city_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  cv_id: Scalars['bigint'];
  id: Scalars['bigint'];
  institution_name: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type: Enum_Dynamic_Cv_Exhibition_Type_Enum;
  updated_at: Scalars['timestamptz'];
  year: Scalars['Int'];
};

/** aggregated selection of "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Aggregate = {
  __typename?: 'Dynamic_cv_exhibition_aggregate';
  aggregate?: Maybe<Dynamic_Cv_Exhibition_Aggregate_Fields>;
  nodes: Array<Dynamic_Cv_Exhibition>;
};

/** aggregate fields of "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Aggregate_Fields = {
  __typename?: 'Dynamic_cv_exhibition_aggregate_fields';
  avg?: Maybe<Dynamic_Cv_Exhibition_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dynamic_Cv_Exhibition_Max_Fields>;
  min?: Maybe<Dynamic_Cv_Exhibition_Min_Fields>;
  stddev?: Maybe<Dynamic_Cv_Exhibition_Stddev_Fields>;
  stddev_pop?: Maybe<Dynamic_Cv_Exhibition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dynamic_Cv_Exhibition_Stddev_Samp_Fields>;
  sum?: Maybe<Dynamic_Cv_Exhibition_Sum_Fields>;
  var_pop?: Maybe<Dynamic_Cv_Exhibition_Var_Pop_Fields>;
  var_samp?: Maybe<Dynamic_Cv_Exhibition_Var_Samp_Fields>;
  variance?: Maybe<Dynamic_Cv_Exhibition_Variance_Fields>;
};


/** aggregate fields of "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dynamic_Cv_Exhibition_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Aggregate_Order_By = {
  avg?: Maybe<Dynamic_Cv_Exhibition_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dynamic_Cv_Exhibition_Max_Order_By>;
  min?: Maybe<Dynamic_Cv_Exhibition_Min_Order_By>;
  stddev?: Maybe<Dynamic_Cv_Exhibition_Stddev_Order_By>;
  stddev_pop?: Maybe<Dynamic_Cv_Exhibition_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dynamic_Cv_Exhibition_Stddev_Samp_Order_By>;
  sum?: Maybe<Dynamic_Cv_Exhibition_Sum_Order_By>;
  var_pop?: Maybe<Dynamic_Cv_Exhibition_Var_Pop_Order_By>;
  var_samp?: Maybe<Dynamic_Cv_Exhibition_Var_Samp_Order_By>;
  variance?: Maybe<Dynamic_Cv_Exhibition_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Arr_Rel_Insert_Input = {
  data: Array<Dynamic_Cv_Exhibition_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Dynamic_Cv_Exhibition_On_Conflict>;
};

/** aggregate avg on columns */
export type Dynamic_Cv_Exhibition_Avg_Fields = {
  __typename?: 'Dynamic_cv_exhibition_avg_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Avg_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Dynamic_cv_exhibition". All fields are combined with a logical 'AND'. */
export type Dynamic_Cv_Exhibition_Bool_Exp = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Bool_Exp>;
  _and?: Maybe<Array<Dynamic_Cv_Exhibition_Bool_Exp>>;
  _not?: Maybe<Dynamic_Cv_Exhibition_Bool_Exp>;
  _or?: Maybe<Array<Dynamic_Cv_Exhibition_Bool_Exp>>;
  city?: Maybe<Worldcities_Bool_Exp>;
  city_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cv_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  institution_name?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Dynamic_cv_exhibition" */
export enum Dynamic_Cv_Exhibition_Constraint {
  /** unique or primary key constraint on columns "id" */
  DynamicCvExhibitionPkey = 'Dynamic_cv_exhibition_pkey'
}

/** input type for incrementing numeric columns in table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Inc_Input = {
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Insert_Input = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Obj_Rel_Insert_Input>;
  city?: Maybe<Worldcities_Obj_Rel_Insert_Input>;
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dynamic_Cv_Exhibition_Max_Fields = {
  __typename?: 'Dynamic_cv_exhibition_max_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Max_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  institution_name?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dynamic_Cv_Exhibition_Min_Fields = {
  __typename?: 'Dynamic_cv_exhibition_min_fields';
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Min_Order_By = {
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  institution_name?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Mutation_Response = {
  __typename?: 'Dynamic_cv_exhibition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dynamic_Cv_Exhibition>;
};

/** on_conflict condition type for table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_On_Conflict = {
  constraint: Dynamic_Cv_Exhibition_Constraint;
  update_columns?: Array<Dynamic_Cv_Exhibition_Update_Column>;
  where?: Maybe<Dynamic_Cv_Exhibition_Bool_Exp>;
};

/** Ordering options when selecting data from "Dynamic_cv_exhibition". */
export type Dynamic_Cv_Exhibition_Order_By = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Order_By>;
  city?: Maybe<Worldcities_Order_By>;
  city_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  institution_name?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: Dynamic_cv_exhibition */
export type Dynamic_Cv_Exhibition_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Dynamic_cv_exhibition" */
export enum Dynamic_Cv_Exhibition_Select_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionName = 'institution_name',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Set_Input = {
  city_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  institution_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dynamic_Cv_Exhibition_Stddev_Fields = {
  __typename?: 'Dynamic_cv_exhibition_stddev_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Stddev_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dynamic_Cv_Exhibition_Stddev_Pop_Fields = {
  __typename?: 'Dynamic_cv_exhibition_stddev_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Stddev_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dynamic_Cv_Exhibition_Stddev_Samp_Fields = {
  __typename?: 'Dynamic_cv_exhibition_stddev_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Stddev_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dynamic_Cv_Exhibition_Sum_Fields = {
  __typename?: 'Dynamic_cv_exhibition_sum_fields';
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Sum_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "Dynamic_cv_exhibition" */
export enum Dynamic_Cv_Exhibition_Update_Column {
  /** column name */
  CityId = 'city_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionName = 'institution_name',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Dynamic_Cv_Exhibition_Var_Pop_Fields = {
  __typename?: 'Dynamic_cv_exhibition_var_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Var_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dynamic_Cv_Exhibition_Var_Samp_Fields = {
  __typename?: 'Dynamic_cv_exhibition_var_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Var_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dynamic_Cv_Exhibition_Variance_Fields = {
  __typename?: 'Dynamic_cv_exhibition_variance_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Dynamic_cv_exhibition" */
export type Dynamic_Cv_Exhibition_Variance_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "Dynamic_cv" */
export type Dynamic_Cv_Inc_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  birth_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Dynamic_cv" */
export type Dynamic_Cv_Insert_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  awards?: Maybe<Dynamic_Cv_Award_Arr_Rel_Insert_Input>;
  birth_year?: Maybe<Scalars['Int']>;
  city?: Maybe<Worldcities_Obj_Rel_Insert_Input>;
  city_id?: Maybe<Scalars['String']>;
  collections?: Maybe<Dynamic_Cv_Collection_Arr_Rel_Insert_Input>;
  contact?: Maybe<Dynamic_Cv_Contact_Obj_Rel_Insert_Input>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  education?: Maybe<Dynamic_Cv_Education_Arr_Rel_Insert_Input>;
  exhibitions?: Maybe<Dynamic_Cv_Exhibition_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  publications?: Maybe<Dynamic_Cv_Publication_Arr_Rel_Insert_Input>;
  published_at?: Maybe<Scalars['timestamptz']>;
  sections?: Maybe<Dynamic_Cv_Section_Arr_Rel_Insert_Input>;
  status?: Maybe<Enum_Dynamic_Cv_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Dynamic_Cv_Max_Fields = {
  __typename?: 'Dynamic_cv_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  birth_year?: Maybe<Scalars['Int']>;
  city_id?: Maybe<Scalars['String']>;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dynamic_Cv_Min_Fields = {
  __typename?: 'Dynamic_cv_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  birth_year?: Maybe<Scalars['Int']>;
  city_id?: Maybe<Scalars['String']>;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Dynamic_cv" */
export type Dynamic_Cv_Mutation_Response = {
  __typename?: 'Dynamic_cv_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dynamic_Cv>;
};

/** input type for inserting object relation for remote table "Dynamic_cv" */
export type Dynamic_Cv_Obj_Rel_Insert_Input = {
  data: Dynamic_Cv_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Dynamic_Cv_On_Conflict>;
};

/** on_conflict condition type for table "Dynamic_cv" */
export type Dynamic_Cv_On_Conflict = {
  constraint: Dynamic_Cv_Constraint;
  update_columns?: Array<Dynamic_Cv_Update_Column>;
  where?: Maybe<Dynamic_Cv_Bool_Exp>;
};

/** Ordering options when selecting data from "Dynamic_cv". */
export type Dynamic_Cv_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  awards_aggregate?: Maybe<Dynamic_Cv_Award_Aggregate_Order_By>;
  birth_year?: Maybe<Order_By>;
  city?: Maybe<Worldcities_Order_By>;
  city_id?: Maybe<Order_By>;
  collections_aggregate?: Maybe<Dynamic_Cv_Collection_Aggregate_Order_By>;
  contact?: Maybe<Dynamic_Cv_Contact_Order_By>;
  context?: Maybe<Context_Order_By>;
  country_of_origin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  education_aggregate?: Maybe<Dynamic_Cv_Education_Aggregate_Order_By>;
  exhibitions_aggregate?: Maybe<Dynamic_Cv_Exhibition_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  publications_aggregate?: Maybe<Dynamic_Cv_Publication_Aggregate_Order_By>;
  published_at?: Maybe<Order_By>;
  sections_aggregate?: Maybe<Dynamic_Cv_Section_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Dynamic_cv */
export type Dynamic_Cv_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication = {
  __typename?: 'Dynamic_cv_publication';
  /** An object relationship */
  Dynamic_cv: Dynamic_Cv;
  created_at: Scalars['timestamptz'];
  cv_id: Scalars['bigint'];
  id: Scalars['bigint'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
};

/** aggregated selection of "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Aggregate = {
  __typename?: 'Dynamic_cv_publication_aggregate';
  aggregate?: Maybe<Dynamic_Cv_Publication_Aggregate_Fields>;
  nodes: Array<Dynamic_Cv_Publication>;
};

/** aggregate fields of "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Aggregate_Fields = {
  __typename?: 'Dynamic_cv_publication_aggregate_fields';
  avg?: Maybe<Dynamic_Cv_Publication_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dynamic_Cv_Publication_Max_Fields>;
  min?: Maybe<Dynamic_Cv_Publication_Min_Fields>;
  stddev?: Maybe<Dynamic_Cv_Publication_Stddev_Fields>;
  stddev_pop?: Maybe<Dynamic_Cv_Publication_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dynamic_Cv_Publication_Stddev_Samp_Fields>;
  sum?: Maybe<Dynamic_Cv_Publication_Sum_Fields>;
  var_pop?: Maybe<Dynamic_Cv_Publication_Var_Pop_Fields>;
  var_samp?: Maybe<Dynamic_Cv_Publication_Var_Samp_Fields>;
  variance?: Maybe<Dynamic_Cv_Publication_Variance_Fields>;
};


/** aggregate fields of "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dynamic_Cv_Publication_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Aggregate_Order_By = {
  avg?: Maybe<Dynamic_Cv_Publication_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dynamic_Cv_Publication_Max_Order_By>;
  min?: Maybe<Dynamic_Cv_Publication_Min_Order_By>;
  stddev?: Maybe<Dynamic_Cv_Publication_Stddev_Order_By>;
  stddev_pop?: Maybe<Dynamic_Cv_Publication_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dynamic_Cv_Publication_Stddev_Samp_Order_By>;
  sum?: Maybe<Dynamic_Cv_Publication_Sum_Order_By>;
  var_pop?: Maybe<Dynamic_Cv_Publication_Var_Pop_Order_By>;
  var_samp?: Maybe<Dynamic_Cv_Publication_Var_Samp_Order_By>;
  variance?: Maybe<Dynamic_Cv_Publication_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Arr_Rel_Insert_Input = {
  data: Array<Dynamic_Cv_Publication_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Dynamic_Cv_Publication_On_Conflict>;
};

/** aggregate avg on columns */
export type Dynamic_Cv_Publication_Avg_Fields = {
  __typename?: 'Dynamic_cv_publication_avg_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Avg_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Dynamic_cv_publication". All fields are combined with a logical 'AND'. */
export type Dynamic_Cv_Publication_Bool_Exp = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Bool_Exp>;
  _and?: Maybe<Array<Dynamic_Cv_Publication_Bool_Exp>>;
  _not?: Maybe<Dynamic_Cv_Publication_Bool_Exp>;
  _or?: Maybe<Array<Dynamic_Cv_Publication_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cv_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  year?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Dynamic_cv_publication" */
export enum Dynamic_Cv_Publication_Constraint {
  /** unique or primary key constraint on columns "id" */
  DynamicCvPublicationPkey = 'Dynamic_cv_publication_pkey'
}

/** input type for incrementing numeric columns in table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Inc_Input = {
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Insert_Input = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dynamic_Cv_Publication_Max_Fields = {
  __typename?: 'Dynamic_cv_publication_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dynamic_Cv_Publication_Min_Fields = {
  __typename?: 'Dynamic_cv_publication_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** response of any mutation on the table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Mutation_Response = {
  __typename?: 'Dynamic_cv_publication_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dynamic_Cv_Publication>;
};

/** on_conflict condition type for table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_On_Conflict = {
  constraint: Dynamic_Cv_Publication_Constraint;
  update_columns?: Array<Dynamic_Cv_Publication_Update_Column>;
  where?: Maybe<Dynamic_Cv_Publication_Bool_Exp>;
};

/** Ordering options when selecting data from "Dynamic_cv_publication". */
export type Dynamic_Cv_Publication_Order_By = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** primary key columns input for table: Dynamic_cv_publication */
export type Dynamic_Cv_Publication_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Dynamic_cv_publication" */
export enum Dynamic_Cv_Publication_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Year = 'year'
}

/** input type for updating data in table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dynamic_Cv_Publication_Stddev_Fields = {
  __typename?: 'Dynamic_cv_publication_stddev_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Stddev_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dynamic_Cv_Publication_Stddev_Pop_Fields = {
  __typename?: 'Dynamic_cv_publication_stddev_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Stddev_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dynamic_Cv_Publication_Stddev_Samp_Fields = {
  __typename?: 'Dynamic_cv_publication_stddev_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Stddev_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dynamic_Cv_Publication_Sum_Fields = {
  __typename?: 'Dynamic_cv_publication_sum_fields';
  cv_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Sum_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** update columns of table "Dynamic_cv_publication" */
export enum Dynamic_Cv_Publication_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Year = 'year'
}

/** aggregate var_pop on columns */
export type Dynamic_Cv_Publication_Var_Pop_Fields = {
  __typename?: 'Dynamic_cv_publication_var_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Var_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dynamic_Cv_Publication_Var_Samp_Fields = {
  __typename?: 'Dynamic_cv_publication_var_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Var_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dynamic_Cv_Publication_Variance_Fields = {
  __typename?: 'Dynamic_cv_publication_variance_fields';
  cv_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Dynamic_cv_publication" */
export type Dynamic_Cv_Publication_Variance_Order_By = {
  cv_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  year?: Maybe<Order_By>;
};

/** columns and relationships of "Dynamic_cv_section" */
export type Dynamic_Cv_Section = {
  __typename?: 'Dynamic_cv_section';
  /** An object relationship */
  Dynamic_cv: Dynamic_Cv;
  created_at: Scalars['timestamptz'];
  cv_id: Scalars['bigint'];
  order: Scalars['Int'];
  type: Enum_Dynamic_Cv_Section_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Aggregate = {
  __typename?: 'Dynamic_cv_section_aggregate';
  aggregate?: Maybe<Dynamic_Cv_Section_Aggregate_Fields>;
  nodes: Array<Dynamic_Cv_Section>;
};

/** aggregate fields of "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Aggregate_Fields = {
  __typename?: 'Dynamic_cv_section_aggregate_fields';
  avg?: Maybe<Dynamic_Cv_Section_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dynamic_Cv_Section_Max_Fields>;
  min?: Maybe<Dynamic_Cv_Section_Min_Fields>;
  stddev?: Maybe<Dynamic_Cv_Section_Stddev_Fields>;
  stddev_pop?: Maybe<Dynamic_Cv_Section_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dynamic_Cv_Section_Stddev_Samp_Fields>;
  sum?: Maybe<Dynamic_Cv_Section_Sum_Fields>;
  var_pop?: Maybe<Dynamic_Cv_Section_Var_Pop_Fields>;
  var_samp?: Maybe<Dynamic_Cv_Section_Var_Samp_Fields>;
  variance?: Maybe<Dynamic_Cv_Section_Variance_Fields>;
};


/** aggregate fields of "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dynamic_Cv_Section_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Aggregate_Order_By = {
  avg?: Maybe<Dynamic_Cv_Section_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dynamic_Cv_Section_Max_Order_By>;
  min?: Maybe<Dynamic_Cv_Section_Min_Order_By>;
  stddev?: Maybe<Dynamic_Cv_Section_Stddev_Order_By>;
  stddev_pop?: Maybe<Dynamic_Cv_Section_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dynamic_Cv_Section_Stddev_Samp_Order_By>;
  sum?: Maybe<Dynamic_Cv_Section_Sum_Order_By>;
  var_pop?: Maybe<Dynamic_Cv_Section_Var_Pop_Order_By>;
  var_samp?: Maybe<Dynamic_Cv_Section_Var_Samp_Order_By>;
  variance?: Maybe<Dynamic_Cv_Section_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Arr_Rel_Insert_Input = {
  data: Array<Dynamic_Cv_Section_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Dynamic_Cv_Section_On_Conflict>;
};

/** aggregate avg on columns */
export type Dynamic_Cv_Section_Avg_Fields = {
  __typename?: 'Dynamic_cv_section_avg_fields';
  cv_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Avg_Order_By = {
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Dynamic_cv_section". All fields are combined with a logical 'AND'. */
export type Dynamic_Cv_Section_Bool_Exp = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Bool_Exp>;
  _and?: Maybe<Array<Dynamic_Cv_Section_Bool_Exp>>;
  _not?: Maybe<Dynamic_Cv_Section_Bool_Exp>;
  _or?: Maybe<Array<Dynamic_Cv_Section_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cv_id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<Enum_Dynamic_Cv_Section_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Dynamic_cv_section" */
export enum Dynamic_Cv_Section_Constraint {
  /** unique or primary key constraint on columns "type", "cv_id" */
  DynamicCvSectionPkey = 'Dynamic_cv_section_pkey'
}

/** input type for incrementing numeric columns in table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Inc_Input = {
  cv_id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Insert_Input = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Enum_Dynamic_Cv_Section_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Dynamic_Cv_Section_Max_Fields = {
  __typename?: 'Dynamic_cv_section_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dynamic_Cv_Section_Min_Fields = {
  __typename?: 'Dynamic_cv_section_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Mutation_Response = {
  __typename?: 'Dynamic_cv_section_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dynamic_Cv_Section>;
};

/** on_conflict condition type for table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_On_Conflict = {
  constraint: Dynamic_Cv_Section_Constraint;
  update_columns?: Array<Dynamic_Cv_Section_Update_Column>;
  where?: Maybe<Dynamic_Cv_Section_Bool_Exp>;
};

/** Ordering options when selecting data from "Dynamic_cv_section". */
export type Dynamic_Cv_Section_Order_By = {
  Dynamic_cv?: Maybe<Dynamic_Cv_Order_By>;
  created_at?: Maybe<Order_By>;
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Dynamic_cv_section */
export type Dynamic_Cv_Section_Pk_Columns_Input = {
  cv_id: Scalars['bigint'];
  type: Enum_Dynamic_Cv_Section_Type_Enum;
};

/** select columns of table "Dynamic_cv_section" */
export enum Dynamic_Cv_Section_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  cv_id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<Enum_Dynamic_Cv_Section_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Dynamic_Cv_Section_Stddev_Fields = {
  __typename?: 'Dynamic_cv_section_stddev_fields';
  cv_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Stddev_Order_By = {
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dynamic_Cv_Section_Stddev_Pop_Fields = {
  __typename?: 'Dynamic_cv_section_stddev_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Stddev_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dynamic_Cv_Section_Stddev_Samp_Fields = {
  __typename?: 'Dynamic_cv_section_stddev_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Stddev_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dynamic_Cv_Section_Sum_Fields = {
  __typename?: 'Dynamic_cv_section_sum_fields';
  cv_id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Sum_Order_By = {
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "Dynamic_cv_section" */
export enum Dynamic_Cv_Section_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CvId = 'cv_id',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Dynamic_Cv_Section_Var_Pop_Fields = {
  __typename?: 'Dynamic_cv_section_var_pop_fields';
  cv_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Var_Pop_Order_By = {
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dynamic_Cv_Section_Var_Samp_Fields = {
  __typename?: 'Dynamic_cv_section_var_samp_fields';
  cv_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Var_Samp_Order_By = {
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dynamic_Cv_Section_Variance_Fields = {
  __typename?: 'Dynamic_cv_section_variance_fields';
  cv_id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Dynamic_cv_section" */
export type Dynamic_Cv_Section_Variance_Order_By = {
  cv_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** select columns of table "Dynamic_cv" */
export enum Dynamic_Cv_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  BirthYear = 'birth_year',
  /** column name */
  CityId = 'city_id',
  /** column name */
  CountryOfOrigin = 'country_of_origin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Dynamic_cv" */
export type Dynamic_Cv_Set_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  birth_year?: Maybe<Scalars['Int']>;
  city_id?: Maybe<Scalars['String']>;
  country_of_origin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Enum_Dynamic_Cv_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Dynamic_Cv_Stddev_Fields = {
  __typename?: 'Dynamic_cv_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  birth_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dynamic_Cv_Stddev_Pop_Fields = {
  __typename?: 'Dynamic_cv_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  birth_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dynamic_Cv_Stddev_Samp_Fields = {
  __typename?: 'Dynamic_cv_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  birth_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dynamic_Cv_Sum_Fields = {
  __typename?: 'Dynamic_cv_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  birth_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Dynamic_cv" */
export enum Dynamic_Cv_Update_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  BirthYear = 'birth_year',
  /** column name */
  CityId = 'city_id',
  /** column name */
  CountryOfOrigin = 'country_of_origin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Dynamic_Cv_Var_Pop_Fields = {
  __typename?: 'Dynamic_cv_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  birth_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dynamic_Cv_Var_Samp_Fields = {
  __typename?: 'Dynamic_cv_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  birth_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dynamic_Cv_Variance_Fields = {
  __typename?: 'Dynamic_cv_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  birth_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ENUM_account_notice_type" */
export type Enum_Account_Notice_Type = {
  __typename?: 'ENUM_account_notice_type';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_account_notice_type" */
export type Enum_Account_Notice_Type_Aggregate = {
  __typename?: 'ENUM_account_notice_type_aggregate';
  aggregate?: Maybe<Enum_Account_Notice_Type_Aggregate_Fields>;
  nodes: Array<Enum_Account_Notice_Type>;
};

/** aggregate fields of "ENUM_account_notice_type" */
export type Enum_Account_Notice_Type_Aggregate_Fields = {
  __typename?: 'ENUM_account_notice_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Account_Notice_Type_Max_Fields>;
  min?: Maybe<Enum_Account_Notice_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_account_notice_type" */
export type Enum_Account_Notice_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Account_Notice_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_account_notice_type". All fields are combined with a logical 'AND'. */
export type Enum_Account_Notice_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Account_Notice_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Account_Notice_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Account_Notice_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_account_notice_type" */
export enum Enum_Account_Notice_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAccountNoticeTypePkey = 'ENUM_account_notice_type_pkey'
}

export enum Enum_Account_Notice_Type_Enum {
  /** Recurring subscription is past due */
  BillingSubscriptionPastDue = 'BILLING_SUBSCRIPTION_PAST_DUE',
  /** Domain renewal subscription is active, but the  hosting has expired */
  DomainWithExpiredHosting = 'DOMAIN_WITH_EXPIRED_HOSTING',
  /** Hosting (SuperSite) subscription is active, but no domain is set up */
  HostingWithNoDomain = 'HOSTING_WITH_NO_DOMAIN'
}

/** Boolean expression to compare columns of type "ENUM_account_notice_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Account_Notice_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Account_Notice_Type_Enum>;
  _in?: Maybe<Array<Enum_Account_Notice_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Account_Notice_Type_Enum>;
  _nin?: Maybe<Array<Enum_Account_Notice_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_account_notice_type" */
export type Enum_Account_Notice_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Account_Notice_Type_Max_Fields = {
  __typename?: 'ENUM_account_notice_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Account_Notice_Type_Min_Fields = {
  __typename?: 'ENUM_account_notice_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_account_notice_type" */
export type Enum_Account_Notice_Type_Mutation_Response = {
  __typename?: 'ENUM_account_notice_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Account_Notice_Type>;
};

/** on_conflict condition type for table "ENUM_account_notice_type" */
export type Enum_Account_Notice_Type_On_Conflict = {
  constraint: Enum_Account_Notice_Type_Constraint;
  update_columns?: Array<Enum_Account_Notice_Type_Update_Column>;
  where?: Maybe<Enum_Account_Notice_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_account_notice_type". */
export type Enum_Account_Notice_Type_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_account_notice_type */
export type Enum_Account_Notice_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_account_notice_type" */
export enum Enum_Account_Notice_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_account_notice_type" */
export type Enum_Account_Notice_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_account_notice_type" */
export enum Enum_Account_Notice_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_achievement_tags" */
export type Enum_Achievement_Tags = {
  __typename?: 'ENUM_achievement_tags';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_achievement_tags" */
export type Enum_Achievement_Tags_Aggregate = {
  __typename?: 'ENUM_achievement_tags_aggregate';
  aggregate?: Maybe<Enum_Achievement_Tags_Aggregate_Fields>;
  nodes: Array<Enum_Achievement_Tags>;
};

/** aggregate fields of "ENUM_achievement_tags" */
export type Enum_Achievement_Tags_Aggregate_Fields = {
  __typename?: 'ENUM_achievement_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Achievement_Tags_Max_Fields>;
  min?: Maybe<Enum_Achievement_Tags_Min_Fields>;
};


/** aggregate fields of "ENUM_achievement_tags" */
export type Enum_Achievement_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Achievement_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_achievement_tags". All fields are combined with a logical 'AND'. */
export type Enum_Achievement_Tags_Bool_Exp = {
  _and?: Maybe<Array<Enum_Achievement_Tags_Bool_Exp>>;
  _not?: Maybe<Enum_Achievement_Tags_Bool_Exp>;
  _or?: Maybe<Array<Enum_Achievement_Tags_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_achievement_tags" */
export enum Enum_Achievement_Tags_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAchievementTagsPkey = 'ENUM_achievement_tags_pkey'
}

export enum Enum_Achievement_Tags_Enum {
  ArtPublication = 'ART_PUBLICATION',
  Growth = 'GROWTH',
  KaleidoCard = 'KALEIDO_CARD',
  Profile = 'PROFILE',
  Series = 'SERIES'
}

/** Boolean expression to compare columns of type "ENUM_achievement_tags_enum". All fields are combined with logical 'AND'. */
export type Enum_Achievement_Tags_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Achievement_Tags_Enum>;
  _in?: Maybe<Array<Enum_Achievement_Tags_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Achievement_Tags_Enum>;
  _nin?: Maybe<Array<Enum_Achievement_Tags_Enum>>;
};

/** input type for inserting data into table "ENUM_achievement_tags" */
export type Enum_Achievement_Tags_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Achievement_Tags_Max_Fields = {
  __typename?: 'ENUM_achievement_tags_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Achievement_Tags_Min_Fields = {
  __typename?: 'ENUM_achievement_tags_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_achievement_tags" */
export type Enum_Achievement_Tags_Mutation_Response = {
  __typename?: 'ENUM_achievement_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Achievement_Tags>;
};

/** input type for inserting object relation for remote table "ENUM_achievement_tags" */
export type Enum_Achievement_Tags_Obj_Rel_Insert_Input = {
  data: Enum_Achievement_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Achievement_Tags_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_achievement_tags" */
export type Enum_Achievement_Tags_On_Conflict = {
  constraint: Enum_Achievement_Tags_Constraint;
  update_columns?: Array<Enum_Achievement_Tags_Update_Column>;
  where?: Maybe<Enum_Achievement_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_achievement_tags". */
export type Enum_Achievement_Tags_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_achievement_tags */
export type Enum_Achievement_Tags_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_achievement_tags" */
export enum Enum_Achievement_Tags_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_achievement_tags" */
export type Enum_Achievement_Tags_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_achievement_tags" */
export enum Enum_Achievement_Tags_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_add_artwork_flow_step" */
export type Enum_Add_Artwork_Flow_Step = {
  __typename?: 'ENUM_add_artwork_flow_step';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_add_artwork_flow_step" */
export type Enum_Add_Artwork_Flow_Step_Aggregate = {
  __typename?: 'ENUM_add_artwork_flow_step_aggregate';
  aggregate?: Maybe<Enum_Add_Artwork_Flow_Step_Aggregate_Fields>;
  nodes: Array<Enum_Add_Artwork_Flow_Step>;
};

/** aggregate fields of "ENUM_add_artwork_flow_step" */
export type Enum_Add_Artwork_Flow_Step_Aggregate_Fields = {
  __typename?: 'ENUM_add_artwork_flow_step_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Add_Artwork_Flow_Step_Max_Fields>;
  min?: Maybe<Enum_Add_Artwork_Flow_Step_Min_Fields>;
};


/** aggregate fields of "ENUM_add_artwork_flow_step" */
export type Enum_Add_Artwork_Flow_Step_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_add_artwork_flow_step". All fields are combined with a logical 'AND'. */
export type Enum_Add_Artwork_Flow_Step_Bool_Exp = {
  _and?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Bool_Exp>>;
  _not?: Maybe<Enum_Add_Artwork_Flow_Step_Bool_Exp>;
  _or?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_add_artwork_flow_step" */
export enum Enum_Add_Artwork_Flow_Step_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAddArtworkFlowStepPkey = 'ENUM_add_artwork_flow_step_pkey'
}

export enum Enum_Add_Artwork_Flow_Step_Enum {
  Commercial = 'COMMERCIAL',
  Details = 'DETAILS',
  DetailsBlockchain = 'DETAILS_BLOCKCHAIN',
  DetailsCreationDate = 'DETAILS_CREATION_DATE',
  DetailsDimensions = 'DETAILS_DIMENSIONS',
  DetailsDiscipline = 'DETAILS_DISCIPLINE',
  DetailsLocation = 'DETAILS_LOCATION',
  Edition = 'EDITION',
  Imagery = 'IMAGERY',
  Review = 'REVIEW',
  Series = 'SERIES',
  Storytelling = 'STORYTELLING'
}

/** Boolean expression to compare columns of type "ENUM_add_artwork_flow_step_enum". All fields are combined with logical 'AND'. */
export type Enum_Add_Artwork_Flow_Step_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Add_Artwork_Flow_Step_Enum>;
  _in?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Add_Artwork_Flow_Step_Enum>;
  _nin?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Enum>>;
};

/** input type for inserting data into table "ENUM_add_artwork_flow_step" */
export type Enum_Add_Artwork_Flow_Step_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Add_Artwork_Flow_Step_Max_Fields = {
  __typename?: 'ENUM_add_artwork_flow_step_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Add_Artwork_Flow_Step_Min_Fields = {
  __typename?: 'ENUM_add_artwork_flow_step_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_add_artwork_flow_step" */
export type Enum_Add_Artwork_Flow_Step_Mutation_Response = {
  __typename?: 'ENUM_add_artwork_flow_step_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Add_Artwork_Flow_Step>;
};

/** input type for inserting object relation for remote table "ENUM_add_artwork_flow_step" */
export type Enum_Add_Artwork_Flow_Step_Obj_Rel_Insert_Input = {
  data: Enum_Add_Artwork_Flow_Step_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Add_Artwork_Flow_Step_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_add_artwork_flow_step" */
export type Enum_Add_Artwork_Flow_Step_On_Conflict = {
  constraint: Enum_Add_Artwork_Flow_Step_Constraint;
  update_columns?: Array<Enum_Add_Artwork_Flow_Step_Update_Column>;
  where?: Maybe<Enum_Add_Artwork_Flow_Step_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_add_artwork_flow_step". */
export type Enum_Add_Artwork_Flow_Step_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_add_artwork_flow_step */
export type Enum_Add_Artwork_Flow_Step_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_add_artwork_flow_step" */
export enum Enum_Add_Artwork_Flow_Step_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_add_artwork_flow_step" */
export type Enum_Add_Artwork_Flow_Step_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_add_artwork_flow_step" */
export enum Enum_Add_Artwork_Flow_Step_Update_Column {
  /** column name */
  Value = 'value'
}

/** Catalog for kaleido card categories */
export type Enum_Analytics_KaleidoCard_Category = {
  __typename?: 'ENUM_analytics_kaleidoCard_category';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_analytics_kaleidoCard_category" */
export type Enum_Analytics_KaleidoCard_Category_Aggregate = {
  __typename?: 'ENUM_analytics_kaleidoCard_category_aggregate';
  aggregate?: Maybe<Enum_Analytics_KaleidoCard_Category_Aggregate_Fields>;
  nodes: Array<Enum_Analytics_KaleidoCard_Category>;
};

/** aggregate fields of "ENUM_analytics_kaleidoCard_category" */
export type Enum_Analytics_KaleidoCard_Category_Aggregate_Fields = {
  __typename?: 'ENUM_analytics_kaleidoCard_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Analytics_KaleidoCard_Category_Max_Fields>;
  min?: Maybe<Enum_Analytics_KaleidoCard_Category_Min_Fields>;
};


/** aggregate fields of "ENUM_analytics_kaleidoCard_category" */
export type Enum_Analytics_KaleidoCard_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_analytics_kaleidoCard_category". All fields are combined with a logical 'AND'. */
export type Enum_Analytics_KaleidoCard_Category_Bool_Exp = {
  _and?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Bool_Exp>>;
  _not?: Maybe<Enum_Analytics_KaleidoCard_Category_Bool_Exp>;
  _or?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_analytics_kaleidoCard_category" */
export enum Enum_Analytics_KaleidoCard_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAnalyticsKaleidoCardCategoryPkey = 'ENUM_analytics_kaleidoCard_category_pkey'
}

export enum Enum_Analytics_KaleidoCard_Category_Enum {
  FeaturedLink = 'FEATURED_LINK',
  KaleidoClicks = 'KALEIDO_CLICKS',
  Marketplace = 'MARKETPLACE',
  Personal = 'PERSONAL',
  Press = 'PRESS',
  Social = 'SOCIAL'
}

/** Boolean expression to compare columns of type "ENUM_analytics_kaleidoCard_category_enum". All fields are combined with logical 'AND'. */
export type Enum_Analytics_KaleidoCard_Category_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Analytics_KaleidoCard_Category_Enum>;
  _in?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Analytics_KaleidoCard_Category_Enum>;
  _nin?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Enum>>;
};

/** input type for inserting data into table "ENUM_analytics_kaleidoCard_category" */
export type Enum_Analytics_KaleidoCard_Category_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Analytics_KaleidoCard_Category_Max_Fields = {
  __typename?: 'ENUM_analytics_kaleidoCard_category_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Analytics_KaleidoCard_Category_Min_Fields = {
  __typename?: 'ENUM_analytics_kaleidoCard_category_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_analytics_kaleidoCard_category" */
export type Enum_Analytics_KaleidoCard_Category_Mutation_Response = {
  __typename?: 'ENUM_analytics_kaleidoCard_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Analytics_KaleidoCard_Category>;
};

/** on_conflict condition type for table "ENUM_analytics_kaleidoCard_category" */
export type Enum_Analytics_KaleidoCard_Category_On_Conflict = {
  constraint: Enum_Analytics_KaleidoCard_Category_Constraint;
  update_columns?: Array<Enum_Analytics_KaleidoCard_Category_Update_Column>;
  where?: Maybe<Enum_Analytics_KaleidoCard_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_analytics_kaleidoCard_category". */
export type Enum_Analytics_KaleidoCard_Category_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_analytics_kaleidoCard_category */
export type Enum_Analytics_KaleidoCard_Category_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_analytics_kaleidoCard_category" */
export enum Enum_Analytics_KaleidoCard_Category_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_analytics_kaleidoCard_category" */
export type Enum_Analytics_KaleidoCard_Category_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_analytics_kaleidoCard_category" */
export enum Enum_Analytics_KaleidoCard_Category_Update_Column {
  /** column name */
  Value = 'value'
}

/** Analytics type catalog */
export type Enum_Analytics_Type = {
  __typename?: 'ENUM_analytics_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_analytics_type" */
export type Enum_Analytics_Type_Aggregate = {
  __typename?: 'ENUM_analytics_type_aggregate';
  aggregate?: Maybe<Enum_Analytics_Type_Aggregate_Fields>;
  nodes: Array<Enum_Analytics_Type>;
};

/** aggregate fields of "ENUM_analytics_type" */
export type Enum_Analytics_Type_Aggregate_Fields = {
  __typename?: 'ENUM_analytics_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Analytics_Type_Max_Fields>;
  min?: Maybe<Enum_Analytics_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_analytics_type" */
export type Enum_Analytics_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Analytics_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_analytics_type". All fields are combined with a logical 'AND'. */
export type Enum_Analytics_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Analytics_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Analytics_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Analytics_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_analytics_type" */
export enum Enum_Analytics_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAnalyticsTypePkey = 'ENUM_analytics_type_pkey'
}

export enum Enum_Analytics_Type_Enum {
  Click = 'CLICK',
  View = 'VIEW'
}

/** Boolean expression to compare columns of type "ENUM_analytics_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Analytics_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Analytics_Type_Enum>;
  _in?: Maybe<Array<Enum_Analytics_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Analytics_Type_Enum>;
  _nin?: Maybe<Array<Enum_Analytics_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_analytics_type" */
export type Enum_Analytics_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Analytics_Type_Max_Fields = {
  __typename?: 'ENUM_analytics_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Analytics_Type_Min_Fields = {
  __typename?: 'ENUM_analytics_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_analytics_type" */
export type Enum_Analytics_Type_Mutation_Response = {
  __typename?: 'ENUM_analytics_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Analytics_Type>;
};

/** on_conflict condition type for table "ENUM_analytics_type" */
export type Enum_Analytics_Type_On_Conflict = {
  constraint: Enum_Analytics_Type_Constraint;
  update_columns?: Array<Enum_Analytics_Type_Update_Column>;
  where?: Maybe<Enum_Analytics_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_analytics_type". */
export type Enum_Analytics_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_analytics_type */
export type Enum_Analytics_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_analytics_type" */
export enum Enum_Analytics_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_analytics_type" */
export type Enum_Analytics_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_analytics_type" */
export enum Enum_Analytics_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_app_notification_type" */
export type Enum_App_Notification_Type = {
  __typename?: 'ENUM_app_notification_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_app_notification_type" */
export type Enum_App_Notification_Type_Aggregate = {
  __typename?: 'ENUM_app_notification_type_aggregate';
  aggregate?: Maybe<Enum_App_Notification_Type_Aggregate_Fields>;
  nodes: Array<Enum_App_Notification_Type>;
};

/** aggregate fields of "ENUM_app_notification_type" */
export type Enum_App_Notification_Type_Aggregate_Fields = {
  __typename?: 'ENUM_app_notification_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_App_Notification_Type_Max_Fields>;
  min?: Maybe<Enum_App_Notification_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_app_notification_type" */
export type Enum_App_Notification_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_App_Notification_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_app_notification_type". All fields are combined with a logical 'AND'. */
export type Enum_App_Notification_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_App_Notification_Type_Bool_Exp>>;
  _not?: Maybe<Enum_App_Notification_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_App_Notification_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_app_notification_type" */
export enum Enum_App_Notification_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAppNotificationTypePkey = 'ENUM_app_notification_type_pkey'
}

export enum Enum_App_Notification_Type_Enum {
  ArExperience = 'AR_EXPERIENCE',
  CollectorAcceptedInvitation = 'COLLECTOR_ACCEPTED_INVITATION',
  CompleteGettingStarted = 'COMPLETE_GETTING_STARTED',
  MultipleRecognitions = 'MULTIPLE_RECOGNITIONS',
  MutualRecognition = 'MUTUAL_RECOGNITION',
  ReceivedInquire = 'RECEIVED_INQUIRE',
  ReceivedRecognition = 'RECEIVED_RECOGNITION',
  TopRecognitions = 'TOP_RECOGNITIONS',
  UserFavoriteArtwork = 'USER_FAVORITE_ARTWORK'
}

/** Boolean expression to compare columns of type "ENUM_app_notification_type_enum". All fields are combined with logical 'AND'. */
export type Enum_App_Notification_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_App_Notification_Type_Enum>;
  _in?: Maybe<Array<Enum_App_Notification_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_App_Notification_Type_Enum>;
  _nin?: Maybe<Array<Enum_App_Notification_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_app_notification_type" */
export type Enum_App_Notification_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_App_Notification_Type_Max_Fields = {
  __typename?: 'ENUM_app_notification_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_App_Notification_Type_Min_Fields = {
  __typename?: 'ENUM_app_notification_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_app_notification_type" */
export type Enum_App_Notification_Type_Mutation_Response = {
  __typename?: 'ENUM_app_notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_App_Notification_Type>;
};

/** on_conflict condition type for table "ENUM_app_notification_type" */
export type Enum_App_Notification_Type_On_Conflict = {
  constraint: Enum_App_Notification_Type_Constraint;
  update_columns?: Array<Enum_App_Notification_Type_Update_Column>;
  where?: Maybe<Enum_App_Notification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_app_notification_type". */
export type Enum_App_Notification_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_app_notification_type */
export type Enum_App_Notification_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_app_notification_type" */
export enum Enum_App_Notification_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_app_notification_type" */
export type Enum_App_Notification_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_app_notification_type" */
export enum Enum_App_Notification_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** Artwork Details Art type Enum */
export type Enum_Art_Type = {
  __typename?: 'ENUM_art_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_art_type" */
export type Enum_Art_Type_Aggregate = {
  __typename?: 'ENUM_art_type_aggregate';
  aggregate?: Maybe<Enum_Art_Type_Aggregate_Fields>;
  nodes: Array<Enum_Art_Type>;
};

/** aggregate fields of "ENUM_art_type" */
export type Enum_Art_Type_Aggregate_Fields = {
  __typename?: 'ENUM_art_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Art_Type_Max_Fields>;
  min?: Maybe<Enum_Art_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_art_type" */
export type Enum_Art_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Art_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_art_type". All fields are combined with a logical 'AND'. */
export type Enum_Art_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Art_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Art_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Art_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_art_type" */
export enum Enum_Art_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtTypePkey = 'ENUM_art_type_pkey'
}

export enum Enum_Art_Type_Enum {
  /** DIGITAL */
  Digital = 'DIGITAL',
  /** OUTDOOR_ART */
  OutdoorArt = 'OUTDOOR_ART',
  /** PHOTOGRAPHY */
  Photography = 'PHOTOGRAPHY',
  /** PHYSICAL */
  Physical = 'PHYSICAL'
}

/** Boolean expression to compare columns of type "ENUM_art_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Art_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Art_Type_Enum>;
  _in?: Maybe<Array<Enum_Art_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Art_Type_Enum>;
  _nin?: Maybe<Array<Enum_Art_Type_Enum>>;
};

/** Extension data for table ENUM_art_type */
export type Enum_Art_Type_Extension = {
  __typename?: 'ENUM_art_type_extension';
  /** An object relationship */
  Art_type?: Maybe<Enum_Art_Type>;
  Enum_art_type: Enum_Art_Type_Enum;
  order: Scalars['Int'];
};

/** aggregated selection of "ENUM_art_type_extension" */
export type Enum_Art_Type_Extension_Aggregate = {
  __typename?: 'ENUM_art_type_extension_aggregate';
  aggregate?: Maybe<Enum_Art_Type_Extension_Aggregate_Fields>;
  nodes: Array<Enum_Art_Type_Extension>;
};

/** aggregate fields of "ENUM_art_type_extension" */
export type Enum_Art_Type_Extension_Aggregate_Fields = {
  __typename?: 'ENUM_art_type_extension_aggregate_fields';
  avg?: Maybe<Enum_Art_Type_Extension_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Enum_Art_Type_Extension_Max_Fields>;
  min?: Maybe<Enum_Art_Type_Extension_Min_Fields>;
  stddev?: Maybe<Enum_Art_Type_Extension_Stddev_Fields>;
  stddev_pop?: Maybe<Enum_Art_Type_Extension_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Enum_Art_Type_Extension_Stddev_Samp_Fields>;
  sum?: Maybe<Enum_Art_Type_Extension_Sum_Fields>;
  var_pop?: Maybe<Enum_Art_Type_Extension_Var_Pop_Fields>;
  var_samp?: Maybe<Enum_Art_Type_Extension_Var_Samp_Fields>;
  variance?: Maybe<Enum_Art_Type_Extension_Variance_Fields>;
};


/** aggregate fields of "ENUM_art_type_extension" */
export type Enum_Art_Type_Extension_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Art_Type_Extension_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Enum_Art_Type_Extension_Avg_Fields = {
  __typename?: 'ENUM_art_type_extension_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ENUM_art_type_extension". All fields are combined with a logical 'AND'. */
export type Enum_Art_Type_Extension_Bool_Exp = {
  Art_type?: Maybe<Enum_Art_Type_Bool_Exp>;
  Enum_art_type?: Maybe<Enum_Art_Type_Enum_Comparison_Exp>;
  _and?: Maybe<Array<Enum_Art_Type_Extension_Bool_Exp>>;
  _not?: Maybe<Enum_Art_Type_Extension_Bool_Exp>;
  _or?: Maybe<Array<Enum_Art_Type_Extension_Bool_Exp>>;
  order?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_art_type_extension" */
export enum Enum_Art_Type_Extension_Constraint {
  /** unique or primary key constraint on columns "Enum_art_type" */
  EnumArtTypeExtensionPkey = 'ENUM_art_type_extension_pkey'
}

/** input type for incrementing numeric columns in table "ENUM_art_type_extension" */
export type Enum_Art_Type_Extension_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "ENUM_art_type_extension" */
export type Enum_Art_Type_Extension_Insert_Input = {
  Art_type?: Maybe<Enum_Art_Type_Obj_Rel_Insert_Input>;
  Enum_art_type?: Maybe<Enum_Art_Type_Enum>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Enum_Art_Type_Extension_Max_Fields = {
  __typename?: 'ENUM_art_type_extension_max_fields';
  order?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Enum_Art_Type_Extension_Min_Fields = {
  __typename?: 'ENUM_art_type_extension_min_fields';
  order?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "ENUM_art_type_extension" */
export type Enum_Art_Type_Extension_Mutation_Response = {
  __typename?: 'ENUM_art_type_extension_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Art_Type_Extension>;
};

/** on_conflict condition type for table "ENUM_art_type_extension" */
export type Enum_Art_Type_Extension_On_Conflict = {
  constraint: Enum_Art_Type_Extension_Constraint;
  update_columns?: Array<Enum_Art_Type_Extension_Update_Column>;
  where?: Maybe<Enum_Art_Type_Extension_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_art_type_extension". */
export type Enum_Art_Type_Extension_Order_By = {
  Art_type?: Maybe<Enum_Art_Type_Order_By>;
  Enum_art_type?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_art_type_extension */
export type Enum_Art_Type_Extension_Pk_Columns_Input = {
  Enum_art_type: Enum_Art_Type_Enum;
};

/** select columns of table "ENUM_art_type_extension" */
export enum Enum_Art_Type_Extension_Select_Column {
  /** column name */
  EnumArtType = 'Enum_art_type',
  /** column name */
  Order = 'order'
}

/** input type for updating data in table "ENUM_art_type_extension" */
export type Enum_Art_Type_Extension_Set_Input = {
  Enum_art_type?: Maybe<Enum_Art_Type_Enum>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Enum_Art_Type_Extension_Stddev_Fields = {
  __typename?: 'ENUM_art_type_extension_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Enum_Art_Type_Extension_Stddev_Pop_Fields = {
  __typename?: 'ENUM_art_type_extension_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Enum_Art_Type_Extension_Stddev_Samp_Fields = {
  __typename?: 'ENUM_art_type_extension_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Enum_Art_Type_Extension_Sum_Fields = {
  __typename?: 'ENUM_art_type_extension_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "ENUM_art_type_extension" */
export enum Enum_Art_Type_Extension_Update_Column {
  /** column name */
  EnumArtType = 'Enum_art_type',
  /** column name */
  Order = 'order'
}

/** aggregate var_pop on columns */
export type Enum_Art_Type_Extension_Var_Pop_Fields = {
  __typename?: 'ENUM_art_type_extension_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Enum_Art_Type_Extension_Var_Samp_Fields = {
  __typename?: 'ENUM_art_type_extension_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Enum_Art_Type_Extension_Variance_Fields = {
  __typename?: 'ENUM_art_type_extension_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "ENUM_art_type" */
export type Enum_Art_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Art_Type_Max_Fields = {
  __typename?: 'ENUM_art_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Art_Type_Min_Fields = {
  __typename?: 'ENUM_art_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_art_type" */
export type Enum_Art_Type_Mutation_Response = {
  __typename?: 'ENUM_art_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Art_Type>;
};

/** input type for inserting object relation for remote table "ENUM_art_type" */
export type Enum_Art_Type_Obj_Rel_Insert_Input = {
  data: Enum_Art_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Art_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_art_type" */
export type Enum_Art_Type_On_Conflict = {
  constraint: Enum_Art_Type_Constraint;
  update_columns?: Array<Enum_Art_Type_Update_Column>;
  where?: Maybe<Enum_Art_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_art_type". */
export type Enum_Art_Type_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_art_type */
export type Enum_Art_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_art_type" */
export enum Enum_Art_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_art_type" */
export type Enum_Art_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_art_type" */
export enum Enum_Art_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Enum used for artist collections */
export type Enum_Artist_Collection_Categories = {
  __typename?: 'ENUM_artist_collection_categories';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artist_collection_categories" */
export type Enum_Artist_Collection_Categories_Aggregate = {
  __typename?: 'ENUM_artist_collection_categories_aggregate';
  aggregate?: Maybe<Enum_Artist_Collection_Categories_Aggregate_Fields>;
  nodes: Array<Enum_Artist_Collection_Categories>;
};

/** aggregate fields of "ENUM_artist_collection_categories" */
export type Enum_Artist_Collection_Categories_Aggregate_Fields = {
  __typename?: 'ENUM_artist_collection_categories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artist_Collection_Categories_Max_Fields>;
  min?: Maybe<Enum_Artist_Collection_Categories_Min_Fields>;
};


/** aggregate fields of "ENUM_artist_collection_categories" */
export type Enum_Artist_Collection_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artist_Collection_Categories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artist_collection_categories". All fields are combined with a logical 'AND'. */
export type Enum_Artist_Collection_Categories_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artist_Collection_Categories_Bool_Exp>>;
  _not?: Maybe<Enum_Artist_Collection_Categories_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artist_Collection_Categories_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artist_collection_categories" */
export enum Enum_Artist_Collection_Categories_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtistCollectionCategoriesPkey = 'ENUM_artist_collection_categories_pkey'
}

export enum Enum_Artist_Collection_Categories_Enum {
  Disciplines = 'Disciplines',
  Genres = 'Genres',
  Identities = 'Identities',
  Locations = 'Locations',
  Miscelaneous = 'Miscelaneous'
}

/** Boolean expression to compare columns of type "ENUM_artist_collection_categories_enum". All fields are combined with logical 'AND'. */
export type Enum_Artist_Collection_Categories_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artist_Collection_Categories_Enum>;
  _in?: Maybe<Array<Enum_Artist_Collection_Categories_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artist_Collection_Categories_Enum>;
  _nin?: Maybe<Array<Enum_Artist_Collection_Categories_Enum>>;
};

/** input type for inserting data into table "ENUM_artist_collection_categories" */
export type Enum_Artist_Collection_Categories_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artist_Collection_Categories_Max_Fields = {
  __typename?: 'ENUM_artist_collection_categories_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artist_Collection_Categories_Min_Fields = {
  __typename?: 'ENUM_artist_collection_categories_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artist_collection_categories" */
export type Enum_Artist_Collection_Categories_Mutation_Response = {
  __typename?: 'ENUM_artist_collection_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artist_Collection_Categories>;
};

/** on_conflict condition type for table "ENUM_artist_collection_categories" */
export type Enum_Artist_Collection_Categories_On_Conflict = {
  constraint: Enum_Artist_Collection_Categories_Constraint;
  update_columns?: Array<Enum_Artist_Collection_Categories_Update_Column>;
  where?: Maybe<Enum_Artist_Collection_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artist_collection_categories". */
export type Enum_Artist_Collection_Categories_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artist_collection_categories */
export type Enum_Artist_Collection_Categories_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artist_collection_categories" */
export enum Enum_Artist_Collection_Categories_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artist_collection_categories" */
export type Enum_Artist_Collection_Categories_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artist_collection_categories" */
export enum Enum_Artist_Collection_Categories_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_artist_collection_state" */
export type Enum_Artist_Collection_State = {
  __typename?: 'ENUM_artist_collection_state';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artist_collection_state" */
export type Enum_Artist_Collection_State_Aggregate = {
  __typename?: 'ENUM_artist_collection_state_aggregate';
  aggregate?: Maybe<Enum_Artist_Collection_State_Aggregate_Fields>;
  nodes: Array<Enum_Artist_Collection_State>;
};

/** aggregate fields of "ENUM_artist_collection_state" */
export type Enum_Artist_Collection_State_Aggregate_Fields = {
  __typename?: 'ENUM_artist_collection_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artist_Collection_State_Max_Fields>;
  min?: Maybe<Enum_Artist_Collection_State_Min_Fields>;
};


/** aggregate fields of "ENUM_artist_collection_state" */
export type Enum_Artist_Collection_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artist_Collection_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artist_collection_state". All fields are combined with a logical 'AND'. */
export type Enum_Artist_Collection_State_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artist_Collection_State_Bool_Exp>>;
  _not?: Maybe<Enum_Artist_Collection_State_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artist_Collection_State_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artist_collection_state" */
export enum Enum_Artist_Collection_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumVirtualCollectionPkey = 'ENUM_virtual_collection_pkey'
}

export enum Enum_Artist_Collection_State_Enum {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "ENUM_artist_collection_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Artist_Collection_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artist_Collection_State_Enum>;
  _in?: Maybe<Array<Enum_Artist_Collection_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artist_Collection_State_Enum>;
  _nin?: Maybe<Array<Enum_Artist_Collection_State_Enum>>;
};

/** input type for inserting data into table "ENUM_artist_collection_state" */
export type Enum_Artist_Collection_State_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artist_Collection_State_Max_Fields = {
  __typename?: 'ENUM_artist_collection_state_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artist_Collection_State_Min_Fields = {
  __typename?: 'ENUM_artist_collection_state_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artist_collection_state" */
export type Enum_Artist_Collection_State_Mutation_Response = {
  __typename?: 'ENUM_artist_collection_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artist_Collection_State>;
};

/** input type for inserting object relation for remote table "ENUM_artist_collection_state" */
export type Enum_Artist_Collection_State_Obj_Rel_Insert_Input = {
  data: Enum_Artist_Collection_State_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Artist_Collection_State_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_artist_collection_state" */
export type Enum_Artist_Collection_State_On_Conflict = {
  constraint: Enum_Artist_Collection_State_Constraint;
  update_columns?: Array<Enum_Artist_Collection_State_Update_Column>;
  where?: Maybe<Enum_Artist_Collection_State_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artist_collection_state". */
export type Enum_Artist_Collection_State_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artist_collection_state */
export type Enum_Artist_Collection_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artist_collection_state" */
export enum Enum_Artist_Collection_State_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artist_collection_state" */
export type Enum_Artist_Collection_State_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artist_collection_state" */
export enum Enum_Artist_Collection_State_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_artist_collection_type" */
export type Enum_Artist_Collection_Type = {
  __typename?: 'ENUM_artist_collection_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artist_collection_type" */
export type Enum_Artist_Collection_Type_Aggregate = {
  __typename?: 'ENUM_artist_collection_type_aggregate';
  aggregate?: Maybe<Enum_Artist_Collection_Type_Aggregate_Fields>;
  nodes: Array<Enum_Artist_Collection_Type>;
};

/** aggregate fields of "ENUM_artist_collection_type" */
export type Enum_Artist_Collection_Type_Aggregate_Fields = {
  __typename?: 'ENUM_artist_collection_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artist_Collection_Type_Max_Fields>;
  min?: Maybe<Enum_Artist_Collection_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_artist_collection_type" */
export type Enum_Artist_Collection_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artist_Collection_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artist_collection_type". All fields are combined with a logical 'AND'. */
export type Enum_Artist_Collection_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artist_Collection_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Artist_Collection_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artist_Collection_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artist_collection_type" */
export enum Enum_Artist_Collection_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtistCollectionTypePkey = 'ENUM_artist_collection_type_pkey'
}

export enum Enum_Artist_Collection_Type_Enum {
  ArtHistoryMovement = 'art_history_movement',
  ArtUniverseInterest = 'art_universe_interest',
  VirtualExhibition = 'virtual_exhibition'
}

/** Boolean expression to compare columns of type "ENUM_artist_collection_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Artist_Collection_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artist_Collection_Type_Enum>;
  _in?: Maybe<Array<Enum_Artist_Collection_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artist_Collection_Type_Enum>;
  _nin?: Maybe<Array<Enum_Artist_Collection_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_artist_collection_type" */
export type Enum_Artist_Collection_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artist_Collection_Type_Max_Fields = {
  __typename?: 'ENUM_artist_collection_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artist_Collection_Type_Min_Fields = {
  __typename?: 'ENUM_artist_collection_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artist_collection_type" */
export type Enum_Artist_Collection_Type_Mutation_Response = {
  __typename?: 'ENUM_artist_collection_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artist_Collection_Type>;
};

/** input type for inserting object relation for remote table "ENUM_artist_collection_type" */
export type Enum_Artist_Collection_Type_Obj_Rel_Insert_Input = {
  data: Enum_Artist_Collection_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Artist_Collection_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_artist_collection_type" */
export type Enum_Artist_Collection_Type_On_Conflict = {
  constraint: Enum_Artist_Collection_Type_Constraint;
  update_columns?: Array<Enum_Artist_Collection_Type_Update_Column>;
  where?: Maybe<Enum_Artist_Collection_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artist_collection_type". */
export type Enum_Artist_Collection_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artist_collection_type */
export type Enum_Artist_Collection_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artist_collection_type" */
export enum Enum_Artist_Collection_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artist_collection_type" */
export type Enum_Artist_Collection_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artist_collection_type" */
export enum Enum_Artist_Collection_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_artwork_category" */
export type Enum_Artwork_Category = {
  __typename?: 'ENUM_artwork_category';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_category" */
export type Enum_Artwork_Category_Aggregate = {
  __typename?: 'ENUM_artwork_category_aggregate';
  aggregate?: Maybe<Enum_Artwork_Category_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Category>;
};

/** aggregate fields of "ENUM_artwork_category" */
export type Enum_Artwork_Category_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Category_Max_Fields>;
  min?: Maybe<Enum_Artwork_Category_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_category" */
export type Enum_Artwork_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_category". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Category_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Category_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Category_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Category_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_category" */
export enum Enum_Artwork_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkCategoryPkey = 'ENUM_artwork_category_pkey'
}

/** input type for inserting data into table "ENUM_artwork_category" */
export type Enum_Artwork_Category_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Category_Max_Fields = {
  __typename?: 'ENUM_artwork_category_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Category_Min_Fields = {
  __typename?: 'ENUM_artwork_category_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_category" */
export type Enum_Artwork_Category_Mutation_Response = {
  __typename?: 'ENUM_artwork_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Category>;
};

/** on_conflict condition type for table "ENUM_artwork_category" */
export type Enum_Artwork_Category_On_Conflict = {
  constraint: Enum_Artwork_Category_Constraint;
  update_columns?: Array<Enum_Artwork_Category_Update_Column>;
  where?: Maybe<Enum_Artwork_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_category". */
export type Enum_Artwork_Category_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_category */
export type Enum_Artwork_Category_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_category" */
export enum Enum_Artwork_Category_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_category" */
export type Enum_Artwork_Category_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_category" */
export enum Enum_Artwork_Category_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Enum for possibly different type of artwork count milestones  */
export type Enum_Artwork_Count_Milestone_Type = {
  __typename?: 'ENUM_artwork_count_milestone_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_count_milestone_type" */
export type Enum_Artwork_Count_Milestone_Type_Aggregate = {
  __typename?: 'ENUM_artwork_count_milestone_type_aggregate';
  aggregate?: Maybe<Enum_Artwork_Count_Milestone_Type_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Count_Milestone_Type>;
};

/** aggregate fields of "ENUM_artwork_count_milestone_type" */
export type Enum_Artwork_Count_Milestone_Type_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_count_milestone_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Count_Milestone_Type_Max_Fields>;
  min?: Maybe<Enum_Artwork_Count_Milestone_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_count_milestone_type" */
export type Enum_Artwork_Count_Milestone_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_count_milestone_type". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Count_Milestone_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Count_Milestone_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_count_milestone_type" */
export enum Enum_Artwork_Count_Milestone_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkCountMilestoneTypePkey = 'ENUM_artwork_count_milestone_type_pkey'
}

export enum Enum_Artwork_Count_Milestone_Type_Enum {
  /** Whenever a higher milestone from this group gets reached, the artist should re-appear in the artist feed at the top. */
  ArtistExplore = 'ARTIST_EXPLORE'
}

/** Boolean expression to compare columns of type "ENUM_artwork_count_milestone_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Count_Milestone_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Count_Milestone_Type_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Count_Milestone_Type_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_count_milestone_type" */
export type Enum_Artwork_Count_Milestone_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Count_Milestone_Type_Max_Fields = {
  __typename?: 'ENUM_artwork_count_milestone_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Count_Milestone_Type_Min_Fields = {
  __typename?: 'ENUM_artwork_count_milestone_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_count_milestone_type" */
export type Enum_Artwork_Count_Milestone_Type_Mutation_Response = {
  __typename?: 'ENUM_artwork_count_milestone_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Count_Milestone_Type>;
};

/** on_conflict condition type for table "ENUM_artwork_count_milestone_type" */
export type Enum_Artwork_Count_Milestone_Type_On_Conflict = {
  constraint: Enum_Artwork_Count_Milestone_Type_Constraint;
  update_columns?: Array<Enum_Artwork_Count_Milestone_Type_Update_Column>;
  where?: Maybe<Enum_Artwork_Count_Milestone_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_count_milestone_type". */
export type Enum_Artwork_Count_Milestone_Type_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_count_milestone_type */
export type Enum_Artwork_Count_Milestone_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_count_milestone_type" */
export enum Enum_Artwork_Count_Milestone_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_count_milestone_type" */
export type Enum_Artwork_Count_Milestone_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_count_milestone_type" */
export enum Enum_Artwork_Count_Milestone_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_artwork_discipline" */
export type Enum_Artwork_Discipline = {
  __typename?: 'ENUM_artwork_discipline';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_discipline" */
export type Enum_Artwork_Discipline_Aggregate = {
  __typename?: 'ENUM_artwork_discipline_aggregate';
  aggregate?: Maybe<Enum_Artwork_Discipline_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Discipline>;
};

/** aggregate fields of "ENUM_artwork_discipline" */
export type Enum_Artwork_Discipline_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_discipline_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Discipline_Max_Fields>;
  min?: Maybe<Enum_Artwork_Discipline_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_discipline" */
export type Enum_Artwork_Discipline_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Discipline_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_discipline". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Discipline_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Discipline_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Discipline_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Discipline_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_discipline" */
export enum Enum_Artwork_Discipline_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkDisciplinePkey = 'ENUM_artwork_discipline_pkey'
}

export enum Enum_Artwork_Discipline_Enum {
  /** AR Experience */
  ArExperience = 'AR_EXPERIENCE',
  /** Collage */
  Collage = 'COLLAGE',
  /** Digital Drawing */
  DigitalDrawing = 'DIGITAL_DRAWING',
  /** Digital Painting */
  DigitalPainting = 'DIGITAL_PAINTING',
  /** Drawing */
  Drawing = 'DRAWING',
  /** 3D Modeling */
  DModeling = 'D_MODELING',
  /** 3D Street Art */
  DStreetArt = 'D_STREET_ART',
  /** Ephemeral Chalk Art */
  EphemeralChalkArt = 'EPHEMERAL_CHALK_ART',
  /** Graffiti */
  Graffiti = 'GRAFFITI',
  /** Installation */
  Installation = 'INSTALLATION',
  /** Mixed Media */
  MixedMedia = 'MIXED_MEDIA',
  /** Mosaic */
  Mosaic = 'MOSAIC',
  /** Mural */
  Mural = 'MURAL',
  /** Other */
  Other = 'OTHER',
  /** Other Digital */
  OtherDigital = 'OTHER_DIGITAL',
  /** Other Street Art */
  OtherStreetArt = 'OTHER_STREET_ART',
  /** Outdoor Installation */
  OutdoorInstallation = 'OUTDOOR_INSTALLATION',
  /** Painting */
  Painting = 'PAINTING',
  /** Photography */
  Photography = 'PHOTOGRAPHY',
  /** Photo Manipulation */
  PhotoManipulation = 'PHOTO_MANIPULATION',
  /** Pixel Art */
  PixelArt = 'PIXEL_ART',
  /** Printmaking */
  Printmaking = 'PRINTMAKING',
  /** Sculpture */
  Sculpture = 'SCULPTURE',
  /** Vector Art */
  VectorArt = 'VECTOR_ART'
}

/** Boolean expression to compare columns of type "ENUM_artwork_discipline_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Discipline_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Discipline_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Discipline_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Discipline_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Discipline_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_discipline" */
export type Enum_Artwork_Discipline_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Discipline_Max_Fields = {
  __typename?: 'ENUM_artwork_discipline_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Discipline_Min_Fields = {
  __typename?: 'ENUM_artwork_discipline_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_discipline" */
export type Enum_Artwork_Discipline_Mutation_Response = {
  __typename?: 'ENUM_artwork_discipline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Discipline>;
};

/** input type for inserting object relation for remote table "ENUM_artwork_discipline" */
export type Enum_Artwork_Discipline_Obj_Rel_Insert_Input = {
  data: Enum_Artwork_Discipline_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Artwork_Discipline_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_artwork_discipline" */
export type Enum_Artwork_Discipline_On_Conflict = {
  constraint: Enum_Artwork_Discipline_Constraint;
  update_columns?: Array<Enum_Artwork_Discipline_Update_Column>;
  where?: Maybe<Enum_Artwork_Discipline_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_discipline". */
export type Enum_Artwork_Discipline_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_discipline */
export type Enum_Artwork_Discipline_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_discipline" */
export enum Enum_Artwork_Discipline_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_discipline" */
export type Enum_Artwork_Discipline_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_discipline" */
export enum Enum_Artwork_Discipline_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Catalog for artwork edition type */
export type Enum_Artwork_Edition_Type = {
  __typename?: 'ENUM_artwork_edition_type';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_edition_type" */
export type Enum_Artwork_Edition_Type_Aggregate = {
  __typename?: 'ENUM_artwork_edition_type_aggregate';
  aggregate?: Maybe<Enum_Artwork_Edition_Type_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Edition_Type>;
};

/** aggregate fields of "ENUM_artwork_edition_type" */
export type Enum_Artwork_Edition_Type_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_edition_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Edition_Type_Max_Fields>;
  min?: Maybe<Enum_Artwork_Edition_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_edition_type" */
export type Enum_Artwork_Edition_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Edition_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_edition_type". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Edition_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Edition_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Edition_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Edition_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_edition_type" */
export enum Enum_Artwork_Edition_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  EnumArtworkEditionTypePkey = 'ENUM_artwork_edition_type_pkey'
}

export enum Enum_Artwork_Edition_Type_Enum {
  /** Artwork Edition */
  ArtworkEdition = 'ARTWORK_EDITION',
  /** Standalone Edition */
  StandaloneEdition = 'STANDALONE_EDITION',
  /** Unique */
  Unique = 'UNIQUE'
}

/** Boolean expression to compare columns of type "ENUM_artwork_edition_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Edition_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Edition_Type_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Edition_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Edition_Type_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Edition_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_edition_type" */
export type Enum_Artwork_Edition_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Edition_Type_Max_Fields = {
  __typename?: 'ENUM_artwork_edition_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Edition_Type_Min_Fields = {
  __typename?: 'ENUM_artwork_edition_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_edition_type" */
export type Enum_Artwork_Edition_Type_Mutation_Response = {
  __typename?: 'ENUM_artwork_edition_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Edition_Type>;
};

/** input type for inserting object relation for remote table "ENUM_artwork_edition_type" */
export type Enum_Artwork_Edition_Type_Obj_Rel_Insert_Input = {
  data: Enum_Artwork_Edition_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Artwork_Edition_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_artwork_edition_type" */
export type Enum_Artwork_Edition_Type_On_Conflict = {
  constraint: Enum_Artwork_Edition_Type_Constraint;
  update_columns?: Array<Enum_Artwork_Edition_Type_Update_Column>;
  where?: Maybe<Enum_Artwork_Edition_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_edition_type". */
export type Enum_Artwork_Edition_Type_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_edition_type */
export type Enum_Artwork_Edition_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ENUM_artwork_edition_type" */
export enum Enum_Artwork_Edition_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ENUM_artwork_edition_type" */
export type Enum_Artwork_Edition_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_edition_type" */
export enum Enum_Artwork_Edition_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** Artwork events types */
export type Enum_Artwork_Event_Type = {
  __typename?: 'ENUM_artwork_event_type';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_event_type" */
export type Enum_Artwork_Event_Type_Aggregate = {
  __typename?: 'ENUM_artwork_event_type_aggregate';
  aggregate?: Maybe<Enum_Artwork_Event_Type_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Event_Type>;
};

/** aggregate fields of "ENUM_artwork_event_type" */
export type Enum_Artwork_Event_Type_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_event_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Event_Type_Max_Fields>;
  min?: Maybe<Enum_Artwork_Event_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_event_type" */
export type Enum_Artwork_Event_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Event_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_event_type". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Event_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Event_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Event_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Event_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_event_type" */
export enum Enum_Artwork_Event_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkEventTypePkey1 = 'ENUM_artwork_event_type_pkey1'
}

/** input type for inserting data into table "ENUM_artwork_event_type" */
export type Enum_Artwork_Event_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Event_Type_Max_Fields = {
  __typename?: 'ENUM_artwork_event_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Event_Type_Min_Fields = {
  __typename?: 'ENUM_artwork_event_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_event_type" */
export type Enum_Artwork_Event_Type_Mutation_Response = {
  __typename?: 'ENUM_artwork_event_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Event_Type>;
};

/** input type for inserting object relation for remote table "ENUM_artwork_event_type" */
export type Enum_Artwork_Event_Type_Obj_Rel_Insert_Input = {
  data: Enum_Artwork_Event_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Artwork_Event_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_artwork_event_type" */
export type Enum_Artwork_Event_Type_On_Conflict = {
  constraint: Enum_Artwork_Event_Type_Constraint;
  update_columns?: Array<Enum_Artwork_Event_Type_Update_Column>;
  where?: Maybe<Enum_Artwork_Event_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_event_type". */
export type Enum_Artwork_Event_Type_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_event_type */
export type Enum_Artwork_Event_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_event_type" */
export enum Enum_Artwork_Event_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_event_type" */
export type Enum_Artwork_Event_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_event_type" */
export enum Enum_Artwork_Event_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_artwork_genre" */
export type Enum_Artwork_Genre = {
  __typename?: 'ENUM_artwork_genre';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_genre" */
export type Enum_Artwork_Genre_Aggregate = {
  __typename?: 'ENUM_artwork_genre_aggregate';
  aggregate?: Maybe<Enum_Artwork_Genre_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Genre>;
};

/** aggregate fields of "ENUM_artwork_genre" */
export type Enum_Artwork_Genre_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_genre_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Genre_Max_Fields>;
  min?: Maybe<Enum_Artwork_Genre_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_genre" */
export type Enum_Artwork_Genre_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Genre_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_genre". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Genre_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Genre_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Genre_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Genre_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_genre" */
export enum Enum_Artwork_Genre_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkGenrePkey = 'ENUM_artwork_genre_pkey'
}

/** input type for inserting data into table "ENUM_artwork_genre" */
export type Enum_Artwork_Genre_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Genre_Max_Fields = {
  __typename?: 'ENUM_artwork_genre_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Genre_Min_Fields = {
  __typename?: 'ENUM_artwork_genre_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_genre" */
export type Enum_Artwork_Genre_Mutation_Response = {
  __typename?: 'ENUM_artwork_genre_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Genre>;
};

/** on_conflict condition type for table "ENUM_artwork_genre" */
export type Enum_Artwork_Genre_On_Conflict = {
  constraint: Enum_Artwork_Genre_Constraint;
  update_columns?: Array<Enum_Artwork_Genre_Update_Column>;
  where?: Maybe<Enum_Artwork_Genre_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_genre". */
export type Enum_Artwork_Genre_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_genre */
export type Enum_Artwork_Genre_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_genre" */
export enum Enum_Artwork_Genre_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_genre" */
export type Enum_Artwork_Genre_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_genre" */
export enum Enum_Artwork_Genre_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** The "kinds" of entries in the history of an artwork_purchase_journey */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind = {
  __typename?: 'ENUM_artwork_purchase_journey_history_entry_kind';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_purchase_journey_history_entry_kind" */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Aggregate = {
  __typename?: 'ENUM_artwork_purchase_journey_history_entry_kind_aggregate';
  aggregate?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind>;
};

/** aggregate fields of "ENUM_artwork_purchase_journey_history_entry_kind" */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_purchase_journey_history_entry_kind_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Max_Fields>;
  min?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_purchase_journey_history_entry_kind" */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_purchase_journey_history_entry_kind". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_purchase_journey_history_entry_kind" */
export enum Enum_Artwork_Purchase_Journey_History_Entry_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkPurchaseJourneyHistoryEntryKindPkey = 'ENUM_artwork_purchase_journey_history_entry_kind_pkey'
}

export enum Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum {
  /** automated or manual markers denoting an event that happened in the journey or an action required to do by one of the parties */
  Event = 'EVENT',
  /** a marker to denote that the party was contacted via a notification of a certain type - entry_kind_type */
  Notification = 'NOTIFICATION'
}

/** Boolean expression to compare columns of type "ENUM_artwork_purchase_journey_history_entry_kind_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_purchase_journey_history_entry_kind" */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Max_Fields = {
  __typename?: 'ENUM_artwork_purchase_journey_history_entry_kind_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Min_Fields = {
  __typename?: 'ENUM_artwork_purchase_journey_history_entry_kind_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_purchase_journey_history_entry_kind" */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Mutation_Response = {
  __typename?: 'ENUM_artwork_purchase_journey_history_entry_kind_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind>;
};

/** on_conflict condition type for table "ENUM_artwork_purchase_journey_history_entry_kind" */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_On_Conflict = {
  constraint: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Constraint;
  update_columns?: Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Update_Column>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_purchase_journey_history_entry_kind". */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_purchase_journey_history_entry_kind */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_purchase_journey_history_entry_kind" */
export enum Enum_Artwork_Purchase_Journey_History_Entry_Kind_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_purchase_journey_history_entry_kind" */
export type Enum_Artwork_Purchase_Journey_History_Entry_Kind_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_purchase_journey_history_entry_kind" */
export enum Enum_Artwork_Purchase_Journey_History_Entry_Kind_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** the possible parties involved in a purchase: (e.g.: BUYER, SELLER, KALI) */
export type Enum_Artwork_Purchase_Journey_Party = {
  __typename?: 'ENUM_artwork_purchase_journey_party';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_purchase_journey_party" */
export type Enum_Artwork_Purchase_Journey_Party_Aggregate = {
  __typename?: 'ENUM_artwork_purchase_journey_party_aggregate';
  aggregate?: Maybe<Enum_Artwork_Purchase_Journey_Party_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Purchase_Journey_Party>;
};

/** aggregate fields of "ENUM_artwork_purchase_journey_party" */
export type Enum_Artwork_Purchase_Journey_Party_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_purchase_journey_party_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Purchase_Journey_Party_Max_Fields>;
  min?: Maybe<Enum_Artwork_Purchase_Journey_Party_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_purchase_journey_party" */
export type Enum_Artwork_Purchase_Journey_Party_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_purchase_journey_party". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Purchase_Journey_Party_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Purchase_Journey_Party_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_purchase_journey_party" */
export enum Enum_Artwork_Purchase_Journey_Party_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkPurchaseJourneyPartyPkey = 'ENUM_artwork_purchase_journey_party_pkey'
}

export enum Enum_Artwork_Purchase_Journey_Party_Enum {
  /** The party party ordering the artwork and providing the payment */
  Buyer = 'BUYER',
  /** The mediator. The admins and system overviewing the whole transaction. */
  Kali = 'KALI',
  /** The party shipping the artwork and receiving compensation */
  Seller = 'SELLER'
}

/** Boolean expression to compare columns of type "ENUM_artwork_purchase_journey_party_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Purchase_Journey_Party_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Purchase_Journey_Party_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Purchase_Journey_Party_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_purchase_journey_party" */
export type Enum_Artwork_Purchase_Journey_Party_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Purchase_Journey_Party_Max_Fields = {
  __typename?: 'ENUM_artwork_purchase_journey_party_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Purchase_Journey_Party_Min_Fields = {
  __typename?: 'ENUM_artwork_purchase_journey_party_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_purchase_journey_party" */
export type Enum_Artwork_Purchase_Journey_Party_Mutation_Response = {
  __typename?: 'ENUM_artwork_purchase_journey_party_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Purchase_Journey_Party>;
};

/** on_conflict condition type for table "ENUM_artwork_purchase_journey_party" */
export type Enum_Artwork_Purchase_Journey_Party_On_Conflict = {
  constraint: Enum_Artwork_Purchase_Journey_Party_Constraint;
  update_columns?: Array<Enum_Artwork_Purchase_Journey_Party_Update_Column>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Party_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_purchase_journey_party". */
export type Enum_Artwork_Purchase_Journey_Party_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_purchase_journey_party */
export type Enum_Artwork_Purchase_Journey_Party_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_purchase_journey_party" */
export enum Enum_Artwork_Purchase_Journey_Party_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_purchase_journey_party" */
export type Enum_Artwork_Purchase_Journey_Party_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_purchase_journey_party" */
export enum Enum_Artwork_Purchase_Journey_Party_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** possible status for the artwork_purchase_journey */
export type Enum_Artwork_Purchase_Journey_Status = {
  __typename?: 'ENUM_artwork_purchase_journey_status';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_purchase_journey_status" */
export type Enum_Artwork_Purchase_Journey_Status_Aggregate = {
  __typename?: 'ENUM_artwork_purchase_journey_status_aggregate';
  aggregate?: Maybe<Enum_Artwork_Purchase_Journey_Status_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Purchase_Journey_Status>;
};

/** aggregate fields of "ENUM_artwork_purchase_journey_status" */
export type Enum_Artwork_Purchase_Journey_Status_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_purchase_journey_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Purchase_Journey_Status_Max_Fields>;
  min?: Maybe<Enum_Artwork_Purchase_Journey_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_purchase_journey_status" */
export type Enum_Artwork_Purchase_Journey_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_purchase_journey_status". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Purchase_Journey_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Purchase_Journey_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_purchase_journey_status" */
export enum Enum_Artwork_Purchase_Journey_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ArtworkPurchaseJourneyStatusEnumPkey = 'Artwork_purchase_journey_status_enum_pkey'
}

export enum Enum_Artwork_Purchase_Journey_Status_Enum {
  /** artwork_purchase_journey_status.fail */
  Fail = 'FAIL',
  /** artwork_purchase_journey_status.in_progress */
  InProgress = 'IN_PROGRESS',
  /** artwork_purchase_journey_status.success */
  Success = 'SUCCESS'
}

/** Boolean expression to compare columns of type "ENUM_artwork_purchase_journey_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Purchase_Journey_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Purchase_Journey_Status_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Purchase_Journey_Status_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_purchase_journey_status" */
export type Enum_Artwork_Purchase_Journey_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Purchase_Journey_Status_Max_Fields = {
  __typename?: 'ENUM_artwork_purchase_journey_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Purchase_Journey_Status_Min_Fields = {
  __typename?: 'ENUM_artwork_purchase_journey_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_purchase_journey_status" */
export type Enum_Artwork_Purchase_Journey_Status_Mutation_Response = {
  __typename?: 'ENUM_artwork_purchase_journey_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Purchase_Journey_Status>;
};

/** on_conflict condition type for table "ENUM_artwork_purchase_journey_status" */
export type Enum_Artwork_Purchase_Journey_Status_On_Conflict = {
  constraint: Enum_Artwork_Purchase_Journey_Status_Constraint;
  update_columns?: Array<Enum_Artwork_Purchase_Journey_Status_Update_Column>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_purchase_journey_status". */
export type Enum_Artwork_Purchase_Journey_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_purchase_journey_status */
export type Enum_Artwork_Purchase_Journey_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_purchase_journey_status" */
export enum Enum_Artwork_Purchase_Journey_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_purchase_journey_status" */
export type Enum_Artwork_Purchase_Journey_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_purchase_journey_status" */
export enum Enum_Artwork_Purchase_Journey_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_artwork_showroom_status" */
export type Enum_Artwork_Showroom_Status = {
  __typename?: 'ENUM_artwork_showroom_status';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_showroom_status" */
export type Enum_Artwork_Showroom_Status_Aggregate = {
  __typename?: 'ENUM_artwork_showroom_status_aggregate';
  aggregate?: Maybe<Enum_Artwork_Showroom_Status_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Showroom_Status>;
};

/** aggregate fields of "ENUM_artwork_showroom_status" */
export type Enum_Artwork_Showroom_Status_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_showroom_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Showroom_Status_Max_Fields>;
  min?: Maybe<Enum_Artwork_Showroom_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_showroom_status" */
export type Enum_Artwork_Showroom_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Showroom_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_showroom_status". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Showroom_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Showroom_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Showroom_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Showroom_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_showroom_status" */
export enum Enum_Artwork_Showroom_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkShowroomStatusPkey = 'ENUM_artwork_showroom_status_pkey'
}

export enum Enum_Artwork_Showroom_Status_Enum {
  Available = 'AVAILABLE',
  None = 'NONE',
  Reserved = 'RESERVED',
  Sold = 'SOLD',
  Unavailable = 'UNAVAILABLE'
}

/** Boolean expression to compare columns of type "ENUM_artwork_showroom_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Showroom_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Showroom_Status_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Showroom_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Showroom_Status_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Showroom_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_showroom_status" */
export type Enum_Artwork_Showroom_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Showroom_Status_Max_Fields = {
  __typename?: 'ENUM_artwork_showroom_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Showroom_Status_Min_Fields = {
  __typename?: 'ENUM_artwork_showroom_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_showroom_status" */
export type Enum_Artwork_Showroom_Status_Mutation_Response = {
  __typename?: 'ENUM_artwork_showroom_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Showroom_Status>;
};

/** input type for inserting object relation for remote table "ENUM_artwork_showroom_status" */
export type Enum_Artwork_Showroom_Status_Obj_Rel_Insert_Input = {
  data: Enum_Artwork_Showroom_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Artwork_Showroom_Status_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_artwork_showroom_status" */
export type Enum_Artwork_Showroom_Status_On_Conflict = {
  constraint: Enum_Artwork_Showroom_Status_Constraint;
  update_columns?: Array<Enum_Artwork_Showroom_Status_Update_Column>;
  where?: Maybe<Enum_Artwork_Showroom_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_showroom_status". */
export type Enum_Artwork_Showroom_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_showroom_status */
export type Enum_Artwork_Showroom_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_showroom_status" */
export enum Enum_Artwork_Showroom_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_showroom_status" */
export type Enum_Artwork_Showroom_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_showroom_status" */
export enum Enum_Artwork_Showroom_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_artwork_status" */
export type Enum_Artwork_Status = {
  __typename?: 'ENUM_artwork_status';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_status" */
export type Enum_Artwork_Status_Aggregate = {
  __typename?: 'ENUM_artwork_status_aggregate';
  aggregate?: Maybe<Enum_Artwork_Status_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Status>;
};

/** aggregate fields of "ENUM_artwork_status" */
export type Enum_Artwork_Status_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Status_Max_Fields>;
  min?: Maybe<Enum_Artwork_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_status" */
export type Enum_Artwork_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_status". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_status" */
export enum Enum_Artwork_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkStatusPkey = 'ENUM_artwork_status_pkey'
}

export enum Enum_Artwork_Status_Enum {
  Draft = 'DRAFT',
  EditMode = 'EDIT_MODE',
  Finished = 'FINISHED',
  Transferred = 'TRANSFERRED',
  Transferring = 'TRANSFERRING'
}

/** Boolean expression to compare columns of type "ENUM_artwork_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Status_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Status_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_status" */
export type Enum_Artwork_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Status_Max_Fields = {
  __typename?: 'ENUM_artwork_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Status_Min_Fields = {
  __typename?: 'ENUM_artwork_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_status" */
export type Enum_Artwork_Status_Mutation_Response = {
  __typename?: 'ENUM_artwork_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Status>;
};

/** on_conflict condition type for table "ENUM_artwork_status" */
export type Enum_Artwork_Status_On_Conflict = {
  constraint: Enum_Artwork_Status_Constraint;
  update_columns?: Array<Enum_Artwork_Status_Update_Column>;
  where?: Maybe<Enum_Artwork_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_status". */
export type Enum_Artwork_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_status */
export type Enum_Artwork_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_status" */
export enum Enum_Artwork_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_status" */
export type Enum_Artwork_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_status" */
export enum Enum_Artwork_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_artwork_tag" */
export type Enum_Artwork_Tag = {
  __typename?: 'ENUM_artwork_tag';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_tag" */
export type Enum_Artwork_Tag_Aggregate = {
  __typename?: 'ENUM_artwork_tag_aggregate';
  aggregate?: Maybe<Enum_Artwork_Tag_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Tag>;
};

/** aggregate fields of "ENUM_artwork_tag" */
export type Enum_Artwork_Tag_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_tag_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Tag_Max_Fields>;
  min?: Maybe<Enum_Artwork_Tag_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_tag" */
export type Enum_Artwork_Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_tag". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Tag_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Tag_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Tag_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Tag_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_tag" */
export enum Enum_Artwork_Tag_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkTagPkey = 'ENUM_artwork_tag_pkey'
}

/** input type for inserting data into table "ENUM_artwork_tag" */
export type Enum_Artwork_Tag_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Tag_Max_Fields = {
  __typename?: 'ENUM_artwork_tag_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Tag_Min_Fields = {
  __typename?: 'ENUM_artwork_tag_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_tag" */
export type Enum_Artwork_Tag_Mutation_Response = {
  __typename?: 'ENUM_artwork_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Tag>;
};

/** on_conflict condition type for table "ENUM_artwork_tag" */
export type Enum_Artwork_Tag_On_Conflict = {
  constraint: Enum_Artwork_Tag_Constraint;
  update_columns?: Array<Enum_Artwork_Tag_Update_Column>;
  where?: Maybe<Enum_Artwork_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_tag". */
export type Enum_Artwork_Tag_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_tag */
export type Enum_Artwork_Tag_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_tag" */
export enum Enum_Artwork_Tag_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_tag" */
export type Enum_Artwork_Tag_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** columns and relationships of "ENUM_artwork_tag_type" */
export type Enum_Artwork_Tag_Type = {
  __typename?: 'ENUM_artwork_tag_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_tag_type" */
export type Enum_Artwork_Tag_Type_Aggregate = {
  __typename?: 'ENUM_artwork_tag_type_aggregate';
  aggregate?: Maybe<Enum_Artwork_Tag_Type_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Tag_Type>;
};

/** aggregate fields of "ENUM_artwork_tag_type" */
export type Enum_Artwork_Tag_Type_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_tag_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Tag_Type_Max_Fields>;
  min?: Maybe<Enum_Artwork_Tag_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_tag_type" */
export type Enum_Artwork_Tag_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Tag_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_tag_type". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Tag_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Tag_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Tag_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Tag_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_tag_type" */
export enum Enum_Artwork_Tag_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkTagTypePkey = 'ENUM_artwork_tag_type_pkey'
}

export enum Enum_Artwork_Tag_Type_Enum {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

/** Boolean expression to compare columns of type "ENUM_artwork_tag_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Tag_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Tag_Type_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Tag_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Tag_Type_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Tag_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_tag_type" */
export type Enum_Artwork_Tag_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Tag_Type_Max_Fields = {
  __typename?: 'ENUM_artwork_tag_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Tag_Type_Min_Fields = {
  __typename?: 'ENUM_artwork_tag_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_tag_type" */
export type Enum_Artwork_Tag_Type_Mutation_Response = {
  __typename?: 'ENUM_artwork_tag_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Tag_Type>;
};

/** input type for inserting object relation for remote table "ENUM_artwork_tag_type" */
export type Enum_Artwork_Tag_Type_Obj_Rel_Insert_Input = {
  data: Enum_Artwork_Tag_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Artwork_Tag_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_artwork_tag_type" */
export type Enum_Artwork_Tag_Type_On_Conflict = {
  constraint: Enum_Artwork_Tag_Type_Constraint;
  update_columns?: Array<Enum_Artwork_Tag_Type_Update_Column>;
  where?: Maybe<Enum_Artwork_Tag_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_tag_type". */
export type Enum_Artwork_Tag_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_tag_type */
export type Enum_Artwork_Tag_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_tag_type" */
export enum Enum_Artwork_Tag_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_tag_type" */
export type Enum_Artwork_Tag_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_tag_type" */
export enum Enum_Artwork_Tag_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "ENUM_artwork_tag" */
export enum Enum_Artwork_Tag_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Artwork category - digital, physical etc */
export type Enum_Artwork_Type = {
  __typename?: 'ENUM_artwork_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_artwork_type" */
export type Enum_Artwork_Type_Aggregate = {
  __typename?: 'ENUM_artwork_type_aggregate';
  aggregate?: Maybe<Enum_Artwork_Type_Aggregate_Fields>;
  nodes: Array<Enum_Artwork_Type>;
};

/** aggregate fields of "ENUM_artwork_type" */
export type Enum_Artwork_Type_Aggregate_Fields = {
  __typename?: 'ENUM_artwork_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Artwork_Type_Max_Fields>;
  min?: Maybe<Enum_Artwork_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_artwork_type" */
export type Enum_Artwork_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Artwork_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_artwork_type". All fields are combined with a logical 'AND'. */
export type Enum_Artwork_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Artwork_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Artwork_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Artwork_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_artwork_type" */
export enum Enum_Artwork_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumArtworkTypePkey = 'ENUM_artwork_type_pkey'
}

export enum Enum_Artwork_Type_Enum {
  /** DIGITAL */
  Digital = 'DIGITAL',
  /** OUTDOOR_ART */
  OutdoorArt = 'OUTDOOR_ART',
  /** PHYSICAL */
  Physical = 'PHYSICAL'
}

/** Boolean expression to compare columns of type "ENUM_artwork_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Artwork_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Artwork_Type_Enum>;
  _in?: Maybe<Array<Enum_Artwork_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Artwork_Type_Enum>;
  _nin?: Maybe<Array<Enum_Artwork_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_artwork_type" */
export type Enum_Artwork_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Artwork_Type_Max_Fields = {
  __typename?: 'ENUM_artwork_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Artwork_Type_Min_Fields = {
  __typename?: 'ENUM_artwork_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_artwork_type" */
export type Enum_Artwork_Type_Mutation_Response = {
  __typename?: 'ENUM_artwork_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Artwork_Type>;
};

/** on_conflict condition type for table "ENUM_artwork_type" */
export type Enum_Artwork_Type_On_Conflict = {
  constraint: Enum_Artwork_Type_Constraint;
  update_columns?: Array<Enum_Artwork_Type_Update_Column>;
  where?: Maybe<Enum_Artwork_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_artwork_type". */
export type Enum_Artwork_Type_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_artwork_type */
export type Enum_Artwork_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_artwork_type" */
export enum Enum_Artwork_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_artwork_type" */
export type Enum_Artwork_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_artwork_type" */
export enum Enum_Artwork_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Used to determine the text to audio conversion status */
export type Enum_Audio_Generation_Status = {
  __typename?: 'ENUM_audio_generation_status';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_audio_generation_status" */
export type Enum_Audio_Generation_Status_Aggregate = {
  __typename?: 'ENUM_audio_generation_status_aggregate';
  aggregate?: Maybe<Enum_Audio_Generation_Status_Aggregate_Fields>;
  nodes: Array<Enum_Audio_Generation_Status>;
};

/** aggregate fields of "ENUM_audio_generation_status" */
export type Enum_Audio_Generation_Status_Aggregate_Fields = {
  __typename?: 'ENUM_audio_generation_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Audio_Generation_Status_Max_Fields>;
  min?: Maybe<Enum_Audio_Generation_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_audio_generation_status" */
export type Enum_Audio_Generation_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Audio_Generation_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_audio_generation_status". All fields are combined with a logical 'AND'. */
export type Enum_Audio_Generation_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Audio_Generation_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Audio_Generation_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Audio_Generation_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_audio_generation_status" */
export enum Enum_Audio_Generation_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAudioGenerationStatusPkey = 'ENUM_audio_generation_status_pkey'
}

export enum Enum_Audio_Generation_Status_Enum {
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "ENUM_audio_generation_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Audio_Generation_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Audio_Generation_Status_Enum>;
  _in?: Maybe<Array<Enum_Audio_Generation_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Audio_Generation_Status_Enum>;
  _nin?: Maybe<Array<Enum_Audio_Generation_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_audio_generation_status" */
export type Enum_Audio_Generation_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Audio_Generation_Status_Max_Fields = {
  __typename?: 'ENUM_audio_generation_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Audio_Generation_Status_Min_Fields = {
  __typename?: 'ENUM_audio_generation_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_audio_generation_status" */
export type Enum_Audio_Generation_Status_Mutation_Response = {
  __typename?: 'ENUM_audio_generation_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Audio_Generation_Status>;
};

/** on_conflict condition type for table "ENUM_audio_generation_status" */
export type Enum_Audio_Generation_Status_On_Conflict = {
  constraint: Enum_Audio_Generation_Status_Constraint;
  update_columns?: Array<Enum_Audio_Generation_Status_Update_Column>;
  where?: Maybe<Enum_Audio_Generation_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_audio_generation_status". */
export type Enum_Audio_Generation_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_audio_generation_status */
export type Enum_Audio_Generation_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_audio_generation_status" */
export enum Enum_Audio_Generation_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_audio_generation_status" */
export type Enum_Audio_Generation_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_audio_generation_status" */
export enum Enum_Audio_Generation_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_auth_type" */
export type Enum_Auth_Type = {
  __typename?: 'ENUM_auth_type';
  /** An array relationship */
  Auths: Array<Auth>;
  /** An aggregate relationship */
  Auths_aggregate: Auth_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "ENUM_auth_type" */
export type Enum_Auth_TypeAuthsArgs = {
  distinct_on?: Maybe<Array<Auth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Order_By>>;
  where?: Maybe<Auth_Bool_Exp>;
};


/** columns and relationships of "ENUM_auth_type" */
export type Enum_Auth_TypeAuths_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Order_By>>;
  where?: Maybe<Auth_Bool_Exp>;
};

/** aggregated selection of "ENUM_auth_type" */
export type Enum_Auth_Type_Aggregate = {
  __typename?: 'ENUM_auth_type_aggregate';
  aggregate?: Maybe<Enum_Auth_Type_Aggregate_Fields>;
  nodes: Array<Enum_Auth_Type>;
};

/** aggregate fields of "ENUM_auth_type" */
export type Enum_Auth_Type_Aggregate_Fields = {
  __typename?: 'ENUM_auth_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Auth_Type_Max_Fields>;
  min?: Maybe<Enum_Auth_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_auth_type" */
export type Enum_Auth_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Auth_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_auth_type". All fields are combined with a logical 'AND'. */
export type Enum_Auth_Type_Bool_Exp = {
  Auths?: Maybe<Auth_Bool_Exp>;
  _and?: Maybe<Array<Enum_Auth_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Auth_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Auth_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_auth_type" */
export enum Enum_Auth_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAuthTypePkey = 'ENUM_auth_type_pkey'
}

export enum Enum_Auth_Type_Enum {
  BasicAuth = 'BASIC_AUTH',
  CustomAuth = 'CUSTOM_AUTH'
}

/** Boolean expression to compare columns of type "ENUM_auth_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Auth_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Auth_Type_Enum>;
  _in?: Maybe<Array<Enum_Auth_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Auth_Type_Enum>;
  _nin?: Maybe<Array<Enum_Auth_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_auth_type" */
export type Enum_Auth_Type_Insert_Input = {
  Auths?: Maybe<Auth_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Auth_Type_Max_Fields = {
  __typename?: 'ENUM_auth_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Auth_Type_Min_Fields = {
  __typename?: 'ENUM_auth_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_auth_type" */
export type Enum_Auth_Type_Mutation_Response = {
  __typename?: 'ENUM_auth_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Auth_Type>;
};

/** input type for inserting object relation for remote table "ENUM_auth_type" */
export type Enum_Auth_Type_Obj_Rel_Insert_Input = {
  data: Enum_Auth_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Auth_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_auth_type" */
export type Enum_Auth_Type_On_Conflict = {
  constraint: Enum_Auth_Type_Constraint;
  update_columns?: Array<Enum_Auth_Type_Update_Column>;
  where?: Maybe<Enum_Auth_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_auth_type". */
export type Enum_Auth_Type_Order_By = {
  Auths_aggregate?: Maybe<Auth_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_auth_type */
export type Enum_Auth_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_auth_type" */
export enum Enum_Auth_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_auth_type" */
export type Enum_Auth_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_auth_type" */
export enum Enum_Auth_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_billing_artwork_payment_intent_status" */
export type Enum_Billing_Artwork_Payment_Intent_Status = {
  __typename?: 'ENUM_billing_artwork_payment_intent_status';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_billing_artwork_payment_intent_status" */
export type Enum_Billing_Artwork_Payment_Intent_Status_Aggregate = {
  __typename?: 'ENUM_billing_artwork_payment_intent_status_aggregate';
  aggregate?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Aggregate_Fields>;
  nodes: Array<Enum_Billing_Artwork_Payment_Intent_Status>;
};

/** aggregate fields of "ENUM_billing_artwork_payment_intent_status" */
export type Enum_Billing_Artwork_Payment_Intent_Status_Aggregate_Fields = {
  __typename?: 'ENUM_billing_artwork_payment_intent_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Max_Fields>;
  min?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_billing_artwork_payment_intent_status" */
export type Enum_Billing_Artwork_Payment_Intent_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_billing_artwork_payment_intent_status". All fields are combined with a logical 'AND'. */
export type Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_billing_artwork_payment_intent_status" */
export enum Enum_Billing_Artwork_Payment_Intent_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumBillingArtworkPaymentIntentStatusPkey = 'ENUM_billing_artwork_payment_intent_status_pkey'
}

export enum Enum_Billing_Artwork_Payment_Intent_Status_Enum {
  /** A checkout session was created but then expired (either automatically, or when a new session was created) */
  CheckoutExpired = 'CHECKOUT_EXPIRED',
  /** Payment from buyer and transfer to the seller are complete */
  Complete = 'COMPLETE',
  /** First step - user pressed buy, but didn't yet authorise the payment */
  Initiated = 'INITIATED',
  /** Set after the payment is authorized, if, for example, shipping costs more than expected */
  NeedsExtraCharge = 'NEEDS_EXTRA_CHARGE',
  /** Can be requested by the seller, the buyer, or because the card hold expired */
  OrderCanceled = 'ORDER_CANCELED',
  /** The seller has confirmed that the artwork is available and can be shipped */
  OrderConfirmed = 'ORDER_CONFIRMED',
  /** A hold was set on the buyer's card */
  PaymentAuthorized = 'PAYMENT_AUTHORIZED',
  /** The payment from the buyer to Kaleido is complete (this does not include the extra charges) */
  PaymentConfirmed = 'PAYMENT_CONFIRMED',
  /** There was an error when trying to charge the buyer's card */
  PaymentError = 'PAYMENT_ERROR',
  /** While the process is not automised, an admin needs to contact the seller after a payment is authorized  */
  SellerContacted = 'SELLER_CONTACTED'
}

/** Boolean expression to compare columns of type "ENUM_billing_artwork_payment_intent_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Billing_Artwork_Payment_Intent_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Enum>;
  _in?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Enum>;
  _nin?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_billing_artwork_payment_intent_status" */
export type Enum_Billing_Artwork_Payment_Intent_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Billing_Artwork_Payment_Intent_Status_Max_Fields = {
  __typename?: 'ENUM_billing_artwork_payment_intent_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Billing_Artwork_Payment_Intent_Status_Min_Fields = {
  __typename?: 'ENUM_billing_artwork_payment_intent_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_billing_artwork_payment_intent_status" */
export type Enum_Billing_Artwork_Payment_Intent_Status_Mutation_Response = {
  __typename?: 'ENUM_billing_artwork_payment_intent_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Billing_Artwork_Payment_Intent_Status>;
};

/** on_conflict condition type for table "ENUM_billing_artwork_payment_intent_status" */
export type Enum_Billing_Artwork_Payment_Intent_Status_On_Conflict = {
  constraint: Enum_Billing_Artwork_Payment_Intent_Status_Constraint;
  update_columns?: Array<Enum_Billing_Artwork_Payment_Intent_Status_Update_Column>;
  where?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_billing_artwork_payment_intent_status". */
export type Enum_Billing_Artwork_Payment_Intent_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_billing_artwork_payment_intent_status */
export type Enum_Billing_Artwork_Payment_Intent_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_billing_artwork_payment_intent_status" */
export enum Enum_Billing_Artwork_Payment_Intent_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_billing_artwork_payment_intent_status" */
export type Enum_Billing_Artwork_Payment_Intent_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_billing_artwork_payment_intent_status" */
export enum Enum_Billing_Artwork_Payment_Intent_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Status of a single product part of a payment intent */
export type Enum_Billing_Payment_Intent_Product_Status = {
  __typename?: 'ENUM_billing_payment_intent_product_status';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_billing_payment_intent_product_status" */
export type Enum_Billing_Payment_Intent_Product_Status_Aggregate = {
  __typename?: 'ENUM_billing_payment_intent_product_status_aggregate';
  aggregate?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Aggregate_Fields>;
  nodes: Array<Enum_Billing_Payment_Intent_Product_Status>;
};

/** aggregate fields of "ENUM_billing_payment_intent_product_status" */
export type Enum_Billing_Payment_Intent_Product_Status_Aggregate_Fields = {
  __typename?: 'ENUM_billing_payment_intent_product_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Max_Fields>;
  min?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_billing_payment_intent_product_status" */
export type Enum_Billing_Payment_Intent_Product_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_billing_payment_intent_product_status". All fields are combined with a logical 'AND'. */
export type Enum_Billing_Payment_Intent_Product_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_billing_payment_intent_product_status" */
export enum Enum_Billing_Payment_Intent_Product_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumBillingPaymentIntentProductStatusPkey = 'ENUM_billing_payment_intent_product_status_pkey'
}

export enum Enum_Billing_Payment_Intent_Product_Status_Enum {
  /** The transaction of the product is complete */
  Complete = 'COMPLETE',
  /** Seller has confirmed that they can sell and ship the product */
  Confirmed = 'CONFIRMED',
  /** Product hasn't been paid for or shipped */
  Pending = 'PENDING',
  /** Seller can't sell or ship the product (e.g. no longer available) */
  RejectedBySeller = 'REJECTED_BY_SELLER'
}

/** Boolean expression to compare columns of type "ENUM_billing_payment_intent_product_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Billing_Payment_Intent_Product_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Enum>;
  _in?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Enum>;
  _nin?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_billing_payment_intent_product_status" */
export type Enum_Billing_Payment_Intent_Product_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Billing_Payment_Intent_Product_Status_Max_Fields = {
  __typename?: 'ENUM_billing_payment_intent_product_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Billing_Payment_Intent_Product_Status_Min_Fields = {
  __typename?: 'ENUM_billing_payment_intent_product_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_billing_payment_intent_product_status" */
export type Enum_Billing_Payment_Intent_Product_Status_Mutation_Response = {
  __typename?: 'ENUM_billing_payment_intent_product_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Billing_Payment_Intent_Product_Status>;
};

/** on_conflict condition type for table "ENUM_billing_payment_intent_product_status" */
export type Enum_Billing_Payment_Intent_Product_Status_On_Conflict = {
  constraint: Enum_Billing_Payment_Intent_Product_Status_Constraint;
  update_columns?: Array<Enum_Billing_Payment_Intent_Product_Status_Update_Column>;
  where?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_billing_payment_intent_product_status". */
export type Enum_Billing_Payment_Intent_Product_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_billing_payment_intent_product_status */
export type Enum_Billing_Payment_Intent_Product_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_billing_payment_intent_product_status" */
export enum Enum_Billing_Payment_Intent_Product_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_billing_payment_intent_product_status" */
export type Enum_Billing_Payment_Intent_Product_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_billing_payment_intent_product_status" */
export enum Enum_Billing_Payment_Intent_Product_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_billing_seller_status" */
export type Enum_Billing_Seller_Status = {
  __typename?: 'ENUM_billing_seller_status';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_billing_seller_status" */
export type Enum_Billing_Seller_Status_Aggregate = {
  __typename?: 'ENUM_billing_seller_status_aggregate';
  aggregate?: Maybe<Enum_Billing_Seller_Status_Aggregate_Fields>;
  nodes: Array<Enum_Billing_Seller_Status>;
};

/** aggregate fields of "ENUM_billing_seller_status" */
export type Enum_Billing_Seller_Status_Aggregate_Fields = {
  __typename?: 'ENUM_billing_seller_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Billing_Seller_Status_Max_Fields>;
  min?: Maybe<Enum_Billing_Seller_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_billing_seller_status" */
export type Enum_Billing_Seller_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Billing_Seller_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_billing_seller_status". All fields are combined with a logical 'AND'. */
export type Enum_Billing_Seller_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Billing_Seller_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Billing_Seller_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Billing_Seller_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_billing_seller_status" */
export enum Enum_Billing_Seller_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumBillingSellerStatusPkey = 'ENUM_billing_seller_status_pkey'
}

export enum Enum_Billing_Seller_Status_Enum {
  /** User is ready to process sales */
  Active = 'ACTIVE',
  /** Stripe account created. Artist needs to go through the onboarding process */
  NeedsOnboarding = 'NEEDS_ONBOARDING',
  /** The user has started the onboarding process, but we couldn't yet confirm that they finished it. */
  OnboardingStarted = 'ONBOARDING_STARTED',
  /** Suspended due to bad billing history */
  Suspended = 'SUSPENDED'
}

/** Boolean expression to compare columns of type "ENUM_billing_seller_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Billing_Seller_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Billing_Seller_Status_Enum>;
  _in?: Maybe<Array<Enum_Billing_Seller_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Billing_Seller_Status_Enum>;
  _nin?: Maybe<Array<Enum_Billing_Seller_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_billing_seller_status" */
export type Enum_Billing_Seller_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Billing_Seller_Status_Max_Fields = {
  __typename?: 'ENUM_billing_seller_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Billing_Seller_Status_Min_Fields = {
  __typename?: 'ENUM_billing_seller_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_billing_seller_status" */
export type Enum_Billing_Seller_Status_Mutation_Response = {
  __typename?: 'ENUM_billing_seller_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Billing_Seller_Status>;
};

/** on_conflict condition type for table "ENUM_billing_seller_status" */
export type Enum_Billing_Seller_Status_On_Conflict = {
  constraint: Enum_Billing_Seller_Status_Constraint;
  update_columns?: Array<Enum_Billing_Seller_Status_Update_Column>;
  where?: Maybe<Enum_Billing_Seller_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_billing_seller_status". */
export type Enum_Billing_Seller_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_billing_seller_status */
export type Enum_Billing_Seller_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_billing_seller_status" */
export enum Enum_Billing_Seller_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_billing_seller_status" */
export type Enum_Billing_Seller_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_billing_seller_status" */
export enum Enum_Billing_Seller_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Period at which the billing subscription is renewed */
export type Enum_Billing_Subscription_Period = {
  __typename?: 'ENUM_billing_subscription_period';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_billing_subscription_period" */
export type Enum_Billing_Subscription_Period_Aggregate = {
  __typename?: 'ENUM_billing_subscription_period_aggregate';
  aggregate?: Maybe<Enum_Billing_Subscription_Period_Aggregate_Fields>;
  nodes: Array<Enum_Billing_Subscription_Period>;
};

/** aggregate fields of "ENUM_billing_subscription_period" */
export type Enum_Billing_Subscription_Period_Aggregate_Fields = {
  __typename?: 'ENUM_billing_subscription_period_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Billing_Subscription_Period_Max_Fields>;
  min?: Maybe<Enum_Billing_Subscription_Period_Min_Fields>;
};


/** aggregate fields of "ENUM_billing_subscription_period" */
export type Enum_Billing_Subscription_Period_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Billing_Subscription_Period_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_billing_subscription_period". All fields are combined with a logical 'AND'. */
export type Enum_Billing_Subscription_Period_Bool_Exp = {
  _and?: Maybe<Array<Enum_Billing_Subscription_Period_Bool_Exp>>;
  _not?: Maybe<Enum_Billing_Subscription_Period_Bool_Exp>;
  _or?: Maybe<Array<Enum_Billing_Subscription_Period_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_billing_subscription_period" */
export enum Enum_Billing_Subscription_Period_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumBillingSubscriptionPeriodPkey = 'ENUM_billing_subscription_period_pkey'
}

export enum Enum_Billing_Subscription_Period_Enum {
  /** Billed each month */
  Monthly = 'MONTHLY',
  /** Billed once a year */
  Yearly = 'YEARLY'
}

/** Boolean expression to compare columns of type "ENUM_billing_subscription_period_enum". All fields are combined with logical 'AND'. */
export type Enum_Billing_Subscription_Period_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Billing_Subscription_Period_Enum>;
  _in?: Maybe<Array<Enum_Billing_Subscription_Period_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Billing_Subscription_Period_Enum>;
  _nin?: Maybe<Array<Enum_Billing_Subscription_Period_Enum>>;
};

/** input type for inserting data into table "ENUM_billing_subscription_period" */
export type Enum_Billing_Subscription_Period_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Billing_Subscription_Period_Max_Fields = {
  __typename?: 'ENUM_billing_subscription_period_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Billing_Subscription_Period_Min_Fields = {
  __typename?: 'ENUM_billing_subscription_period_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_billing_subscription_period" */
export type Enum_Billing_Subscription_Period_Mutation_Response = {
  __typename?: 'ENUM_billing_subscription_period_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Billing_Subscription_Period>;
};

/** on_conflict condition type for table "ENUM_billing_subscription_period" */
export type Enum_Billing_Subscription_Period_On_Conflict = {
  constraint: Enum_Billing_Subscription_Period_Constraint;
  update_columns?: Array<Enum_Billing_Subscription_Period_Update_Column>;
  where?: Maybe<Enum_Billing_Subscription_Period_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_billing_subscription_period". */
export type Enum_Billing_Subscription_Period_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_billing_subscription_period */
export type Enum_Billing_Subscription_Period_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_billing_subscription_period" */
export enum Enum_Billing_Subscription_Period_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_billing_subscription_period" */
export type Enum_Billing_Subscription_Period_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_billing_subscription_period" */
export enum Enum_Billing_Subscription_Period_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Types of products/services that can be subscribed to */
export type Enum_Billing_Subscription_Product_Type = {
  __typename?: 'ENUM_billing_subscription_product_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_billing_subscription_product_type" */
export type Enum_Billing_Subscription_Product_Type_Aggregate = {
  __typename?: 'ENUM_billing_subscription_product_type_aggregate';
  aggregate?: Maybe<Enum_Billing_Subscription_Product_Type_Aggregate_Fields>;
  nodes: Array<Enum_Billing_Subscription_Product_Type>;
};

/** aggregate fields of "ENUM_billing_subscription_product_type" */
export type Enum_Billing_Subscription_Product_Type_Aggregate_Fields = {
  __typename?: 'ENUM_billing_subscription_product_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Billing_Subscription_Product_Type_Max_Fields>;
  min?: Maybe<Enum_Billing_Subscription_Product_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_billing_subscription_product_type" */
export type Enum_Billing_Subscription_Product_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_billing_subscription_product_type". All fields are combined with a logical 'AND'. */
export type Enum_Billing_Subscription_Product_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Billing_Subscription_Product_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_billing_subscription_product_type" */
export enum Enum_Billing_Subscription_Product_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumBillingSubscriptionProductTypePkey = 'ENUM_billing_subscription_product_type_pkey'
}

export enum Enum_Billing_Subscription_Product_Type_Enum {
  /** One time domain registration (part of the hosting subscription payment) */
  DomainRegistration = 'DOMAIN_REGISTRATION',
  /** Yearly domain renewal subscription product */
  DomainRenewal = 'DOMAIN_RENEWAL',
  /** MyStudio Premium subscription */
  MystudioPremium = 'MYSTUDIO_PREMIUM',
  /** MyStudio Pro subscription */
  MystudioPro = 'MYSTUDIO_PRO',
  /** SuperSite (custom domain) hosting */
  SuperSite = 'SUPER_SITE'
}

/** Boolean expression to compare columns of type "ENUM_billing_subscription_product_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Billing_Subscription_Product_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Billing_Subscription_Product_Type_Enum>;
  _in?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Billing_Subscription_Product_Type_Enum>;
  _nin?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_billing_subscription_product_type" */
export type Enum_Billing_Subscription_Product_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Billing_Subscription_Product_Type_Max_Fields = {
  __typename?: 'ENUM_billing_subscription_product_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Billing_Subscription_Product_Type_Min_Fields = {
  __typename?: 'ENUM_billing_subscription_product_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_billing_subscription_product_type" */
export type Enum_Billing_Subscription_Product_Type_Mutation_Response = {
  __typename?: 'ENUM_billing_subscription_product_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Billing_Subscription_Product_Type>;
};

/** on_conflict condition type for table "ENUM_billing_subscription_product_type" */
export type Enum_Billing_Subscription_Product_Type_On_Conflict = {
  constraint: Enum_Billing_Subscription_Product_Type_Constraint;
  update_columns?: Array<Enum_Billing_Subscription_Product_Type_Update_Column>;
  where?: Maybe<Enum_Billing_Subscription_Product_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_billing_subscription_product_type". */
export type Enum_Billing_Subscription_Product_Type_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_billing_subscription_product_type */
export type Enum_Billing_Subscription_Product_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_billing_subscription_product_type" */
export enum Enum_Billing_Subscription_Product_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_billing_subscription_product_type" */
export type Enum_Billing_Subscription_Product_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_billing_subscription_product_type" */
export enum Enum_Billing_Subscription_Product_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Possible statuses for Billing_subscription */
export type Enum_Billing_Subscription_Status = {
  __typename?: 'ENUM_billing_subscription_status';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_billing_subscription_status" */
export type Enum_Billing_Subscription_Status_Aggregate = {
  __typename?: 'ENUM_billing_subscription_status_aggregate';
  aggregate?: Maybe<Enum_Billing_Subscription_Status_Aggregate_Fields>;
  nodes: Array<Enum_Billing_Subscription_Status>;
};

/** aggregate fields of "ENUM_billing_subscription_status" */
export type Enum_Billing_Subscription_Status_Aggregate_Fields = {
  __typename?: 'ENUM_billing_subscription_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Billing_Subscription_Status_Max_Fields>;
  min?: Maybe<Enum_Billing_Subscription_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_billing_subscription_status" */
export type Enum_Billing_Subscription_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Billing_Subscription_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_billing_subscription_status". All fields are combined with a logical 'AND'. */
export type Enum_Billing_Subscription_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Billing_Subscription_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Billing_Subscription_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Billing_Subscription_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_billing_subscription_status" */
export enum Enum_Billing_Subscription_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumBillingSubscriptionStatusPkey = 'ENUM_billing_subscription_status_pkey'
}

export enum Enum_Billing_Subscription_Status_Enum {
  /** Subscription is active, last payment was successful */
  Active = 'ACTIVE',
  /** Subscription is inactive */
  Canceled = 'CANCELED',
  /** Result of a payment failure, the subscription is not yet cancelled */
  PastDue = 'PAST_DUE',
  /** Payment intent was initiated */
  Pending = 'PENDING',
  /** Subscription is in a suspended state. Should query stripe for more details */
  Suspended = 'SUSPENDED'
}

/** Boolean expression to compare columns of type "ENUM_billing_subscription_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Billing_Subscription_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Billing_Subscription_Status_Enum>;
  _in?: Maybe<Array<Enum_Billing_Subscription_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Billing_Subscription_Status_Enum>;
  _nin?: Maybe<Array<Enum_Billing_Subscription_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_billing_subscription_status" */
export type Enum_Billing_Subscription_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Billing_Subscription_Status_Max_Fields = {
  __typename?: 'ENUM_billing_subscription_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Billing_Subscription_Status_Min_Fields = {
  __typename?: 'ENUM_billing_subscription_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_billing_subscription_status" */
export type Enum_Billing_Subscription_Status_Mutation_Response = {
  __typename?: 'ENUM_billing_subscription_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Billing_Subscription_Status>;
};

/** on_conflict condition type for table "ENUM_billing_subscription_status" */
export type Enum_Billing_Subscription_Status_On_Conflict = {
  constraint: Enum_Billing_Subscription_Status_Constraint;
  update_columns?: Array<Enum_Billing_Subscription_Status_Update_Column>;
  where?: Maybe<Enum_Billing_Subscription_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_billing_subscription_status". */
export type Enum_Billing_Subscription_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_billing_subscription_status */
export type Enum_Billing_Subscription_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_billing_subscription_status" */
export enum Enum_Billing_Subscription_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_billing_subscription_status" */
export type Enum_Billing_Subscription_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_billing_subscription_status" */
export enum Enum_Billing_Subscription_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** List of blockchain types */
export type Enum_Blockchain = {
  __typename?: 'ENUM_blockchain';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_blockchain" */
export type Enum_Blockchain_Aggregate = {
  __typename?: 'ENUM_blockchain_aggregate';
  aggregate?: Maybe<Enum_Blockchain_Aggregate_Fields>;
  nodes: Array<Enum_Blockchain>;
};

/** aggregate fields of "ENUM_blockchain" */
export type Enum_Blockchain_Aggregate_Fields = {
  __typename?: 'ENUM_blockchain_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Blockchain_Max_Fields>;
  min?: Maybe<Enum_Blockchain_Min_Fields>;
};


/** aggregate fields of "ENUM_blockchain" */
export type Enum_Blockchain_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Blockchain_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_blockchain". All fields are combined with a logical 'AND'. */
export type Enum_Blockchain_Bool_Exp = {
  _and?: Maybe<Array<Enum_Blockchain_Bool_Exp>>;
  _not?: Maybe<Enum_Blockchain_Bool_Exp>;
  _or?: Maybe<Array<Enum_Blockchain_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_blockchain" */
export enum Enum_Blockchain_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumBlockchainPkey = 'ENUM_blockchain_pkey'
}

export enum Enum_Blockchain_Enum {
  /** Avalanche */
  Avalanche = 'AVALANCHE',
  /** BNB */
  Bnb = 'BNB',
  /** Cardano */
  Cardano = 'CARDANO',
  /** EOS */
  Eos = 'EOS',
  /** Ethereum */
  Ethereum = 'ETHEREUM',
  /** Flow */
  Flow = 'FLOW',
  /** ImmutableX */
  ImmutableX = 'IMMUTABLE_X',
  /** Other */
  Other = 'OTHER',
  /** Polygon */
  Polygon = 'POLYGON',
  /** Solana */
  Solana = 'SOLANA',
  /** Tezos */
  Tezos = 'TEZOS'
}

/** Boolean expression to compare columns of type "ENUM_blockchain_enum". All fields are combined with logical 'AND'. */
export type Enum_Blockchain_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Blockchain_Enum>;
  _in?: Maybe<Array<Enum_Blockchain_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Blockchain_Enum>;
  _nin?: Maybe<Array<Enum_Blockchain_Enum>>;
};

/** input type for inserting data into table "ENUM_blockchain" */
export type Enum_Blockchain_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Blockchain_Max_Fields = {
  __typename?: 'ENUM_blockchain_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Blockchain_Min_Fields = {
  __typename?: 'ENUM_blockchain_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_blockchain" */
export type Enum_Blockchain_Mutation_Response = {
  __typename?: 'ENUM_blockchain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Blockchain>;
};

/** input type for inserting object relation for remote table "ENUM_blockchain" */
export type Enum_Blockchain_Obj_Rel_Insert_Input = {
  data: Enum_Blockchain_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Blockchain_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_blockchain" */
export type Enum_Blockchain_On_Conflict = {
  constraint: Enum_Blockchain_Constraint;
  update_columns?: Array<Enum_Blockchain_Update_Column>;
  where?: Maybe<Enum_Blockchain_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_blockchain". */
export type Enum_Blockchain_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_blockchain */
export type Enum_Blockchain_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_blockchain" */
export enum Enum_Blockchain_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_blockchain" */
export type Enum_Blockchain_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_blockchain" */
export enum Enum_Blockchain_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_collaboration_status" */
export type Enum_Collaboration_Status = {
  __typename?: 'ENUM_collaboration_status';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_collaboration_status" */
export type Enum_Collaboration_Status_Aggregate = {
  __typename?: 'ENUM_collaboration_status_aggregate';
  aggregate?: Maybe<Enum_Collaboration_Status_Aggregate_Fields>;
  nodes: Array<Enum_Collaboration_Status>;
};

/** aggregate fields of "ENUM_collaboration_status" */
export type Enum_Collaboration_Status_Aggregate_Fields = {
  __typename?: 'ENUM_collaboration_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Collaboration_Status_Max_Fields>;
  min?: Maybe<Enum_Collaboration_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_collaboration_status" */
export type Enum_Collaboration_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Collaboration_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_collaboration_status". All fields are combined with a logical 'AND'. */
export type Enum_Collaboration_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Collaboration_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Collaboration_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Collaboration_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_collaboration_status" */
export enum Enum_Collaboration_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCollaborationStatusPkey = 'ENUM_collaboration_status_pkey'
}

export enum Enum_Collaboration_Status_Enum {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

/** Boolean expression to compare columns of type "ENUM_collaboration_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Collaboration_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Collaboration_Status_Enum>;
  _in?: Maybe<Array<Enum_Collaboration_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Collaboration_Status_Enum>;
  _nin?: Maybe<Array<Enum_Collaboration_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_collaboration_status" */
export type Enum_Collaboration_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Collaboration_Status_Max_Fields = {
  __typename?: 'ENUM_collaboration_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Collaboration_Status_Min_Fields = {
  __typename?: 'ENUM_collaboration_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_collaboration_status" */
export type Enum_Collaboration_Status_Mutation_Response = {
  __typename?: 'ENUM_collaboration_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Collaboration_Status>;
};

/** input type for inserting object relation for remote table "ENUM_collaboration_status" */
export type Enum_Collaboration_Status_Obj_Rel_Insert_Input = {
  data: Enum_Collaboration_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Collaboration_Status_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_collaboration_status" */
export type Enum_Collaboration_Status_On_Conflict = {
  constraint: Enum_Collaboration_Status_Constraint;
  update_columns?: Array<Enum_Collaboration_Status_Update_Column>;
  where?: Maybe<Enum_Collaboration_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_collaboration_status". */
export type Enum_Collaboration_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_collaboration_status */
export type Enum_Collaboration_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_collaboration_status" */
export enum Enum_Collaboration_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_collaboration_status" */
export type Enum_Collaboration_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_collaboration_status" */
export enum Enum_Collaboration_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_collection_type" */
export type Enum_Collection_Type = {
  __typename?: 'ENUM_collection_type';
  /** An array relationship */
  Collections: Array<Collection>;
  /** An aggregate relationship */
  Collections_aggregate: Collection_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "ENUM_collection_type" */
export type Enum_Collection_TypeCollectionsArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};


/** columns and relationships of "ENUM_collection_type" */
export type Enum_Collection_TypeCollections_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};

/** aggregated selection of "ENUM_collection_type" */
export type Enum_Collection_Type_Aggregate = {
  __typename?: 'ENUM_collection_type_aggregate';
  aggregate?: Maybe<Enum_Collection_Type_Aggregate_Fields>;
  nodes: Array<Enum_Collection_Type>;
};

/** aggregate fields of "ENUM_collection_type" */
export type Enum_Collection_Type_Aggregate_Fields = {
  __typename?: 'ENUM_collection_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Collection_Type_Max_Fields>;
  min?: Maybe<Enum_Collection_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_collection_type" */
export type Enum_Collection_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Collection_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_collection_type". All fields are combined with a logical 'AND'. */
export type Enum_Collection_Type_Bool_Exp = {
  Collections?: Maybe<Collection_Bool_Exp>;
  _and?: Maybe<Array<Enum_Collection_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Collection_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Collection_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_collection_type" */
export enum Enum_Collection_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCollectionTypePkey = 'ENUM_collection_type_pkey'
}

export enum Enum_Collection_Type_Enum {
  ArtFair = 'ART_FAIR',
  ArtHistory = 'ART_HISTORY',
  ArtShow = 'ART_SHOW',
  ArtUniverse = 'ART_UNIVERSE',
  CardGrid = 'CARD_GRID',
  Cart = 'CART',
  Created = 'CREATED',
  Custom = 'CUSTOM',
  Favorite = 'FAVORITE',
  Managed = 'MANAGED',
  Owned = 'OWNED',
  Series = 'SERIES',
  Showroom = 'SHOWROOM'
}

/** Boolean expression to compare columns of type "ENUM_collection_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Collection_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Collection_Type_Enum>;
  _in?: Maybe<Array<Enum_Collection_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Collection_Type_Enum>;
  _nin?: Maybe<Array<Enum_Collection_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_collection_type" */
export type Enum_Collection_Type_Insert_Input = {
  Collections?: Maybe<Collection_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Collection_Type_Max_Fields = {
  __typename?: 'ENUM_collection_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Collection_Type_Min_Fields = {
  __typename?: 'ENUM_collection_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_collection_type" */
export type Enum_Collection_Type_Mutation_Response = {
  __typename?: 'ENUM_collection_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Collection_Type>;
};

/** input type for inserting object relation for remote table "ENUM_collection_type" */
export type Enum_Collection_Type_Obj_Rel_Insert_Input = {
  data: Enum_Collection_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Collection_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_collection_type" */
export type Enum_Collection_Type_On_Conflict = {
  constraint: Enum_Collection_Type_Constraint;
  update_columns?: Array<Enum_Collection_Type_Update_Column>;
  where?: Maybe<Enum_Collection_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_collection_type". */
export type Enum_Collection_Type_Order_By = {
  Collections_aggregate?: Maybe<Collection_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_collection_type */
export type Enum_Collection_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_collection_type" */
export enum Enum_Collection_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_collection_type" */
export type Enum_Collection_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_collection_type" */
export enum Enum_Collection_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** Connected Domains states */
export type Enum_Connected_Domain_State = {
  __typename?: 'ENUM_connected_domain_state';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_connected_domain_state" */
export type Enum_Connected_Domain_State_Aggregate = {
  __typename?: 'ENUM_connected_domain_state_aggregate';
  aggregate?: Maybe<Enum_Connected_Domain_State_Aggregate_Fields>;
  nodes: Array<Enum_Connected_Domain_State>;
};

/** aggregate fields of "ENUM_connected_domain_state" */
export type Enum_Connected_Domain_State_Aggregate_Fields = {
  __typename?: 'ENUM_connected_domain_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Connected_Domain_State_Max_Fields>;
  min?: Maybe<Enum_Connected_Domain_State_Min_Fields>;
};


/** aggregate fields of "ENUM_connected_domain_state" */
export type Enum_Connected_Domain_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Connected_Domain_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_connected_domain_state". All fields are combined with a logical 'AND'. */
export type Enum_Connected_Domain_State_Bool_Exp = {
  _and?: Maybe<Array<Enum_Connected_Domain_State_Bool_Exp>>;
  _not?: Maybe<Enum_Connected_Domain_State_Bool_Exp>;
  _or?: Maybe<Array<Enum_Connected_Domain_State_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_connected_domain_state" */
export enum Enum_Connected_Domain_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumConnectedDomainStatePkey = 'ENUM_connected_domain_state_pkey'
}

export enum Enum_Connected_Domain_State_Enum {
  /** Connected Domain is active */
  Active = 'ACTIVE',
  /** Connected Domain is disabled */
  Disabled = 'DISABLED',
  /** For existing domains (not handled by Kaleido), this is the state prior to any billing for supersite hosting. */
  ExistingNotBilled = 'EXISTING_NOT_BILLED',
  /** For domains handled by Kaleido, this is the state prior to setting up DNS records */
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "ENUM_connected_domain_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Connected_Domain_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Connected_Domain_State_Enum>;
  _in?: Maybe<Array<Enum_Connected_Domain_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Connected_Domain_State_Enum>;
  _nin?: Maybe<Array<Enum_Connected_Domain_State_Enum>>;
};

/** input type for inserting data into table "ENUM_connected_domain_state" */
export type Enum_Connected_Domain_State_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Connected_Domain_State_Max_Fields = {
  __typename?: 'ENUM_connected_domain_state_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Connected_Domain_State_Min_Fields = {
  __typename?: 'ENUM_connected_domain_state_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_connected_domain_state" */
export type Enum_Connected_Domain_State_Mutation_Response = {
  __typename?: 'ENUM_connected_domain_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Connected_Domain_State>;
};

/** on_conflict condition type for table "ENUM_connected_domain_state" */
export type Enum_Connected_Domain_State_On_Conflict = {
  constraint: Enum_Connected_Domain_State_Constraint;
  update_columns?: Array<Enum_Connected_Domain_State_Update_Column>;
  where?: Maybe<Enum_Connected_Domain_State_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_connected_domain_state". */
export type Enum_Connected_Domain_State_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_connected_domain_state */
export type Enum_Connected_Domain_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_connected_domain_state" */
export enum Enum_Connected_Domain_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_connected_domain_state" */
export type Enum_Connected_Domain_State_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_connected_domain_state" */
export enum Enum_Connected_Domain_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_content_type" */
export type Enum_Content_Type = {
  __typename?: 'ENUM_content_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_content_type" */
export type Enum_Content_Type_Aggregate = {
  __typename?: 'ENUM_content_type_aggregate';
  aggregate?: Maybe<Enum_Content_Type_Aggregate_Fields>;
  nodes: Array<Enum_Content_Type>;
};

/** aggregate fields of "ENUM_content_type" */
export type Enum_Content_Type_Aggregate_Fields = {
  __typename?: 'ENUM_content_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Content_Type_Max_Fields>;
  min?: Maybe<Enum_Content_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_content_type" */
export type Enum_Content_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Content_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_content_type". All fields are combined with a logical 'AND'. */
export type Enum_Content_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Content_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Content_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Content_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_content_type" */
export enum Enum_Content_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumContentTypePkey = 'ENUM_content_type_pkey'
}

export enum Enum_Content_Type_Enum {
  ArtistAiGeneratedStatement = 'ARTIST_AI_GENERATED_STATEMENT',
  ArtistQuestionnaireAnswer = 'ARTIST_QUESTIONNAIRE_ANSWER',
  ArtistQuotation = 'ARTIST_QUOTATION',
  ArtistStatement = 'ARTIST_STATEMENT',
  ArtworkStatement = 'ARTWORK_STATEMENT',
  CvAward = 'CV_AWARD',
  CvCollection = 'CV_COLLECTION',
  CvEducationDegree = 'CV_EDUCATION_DEGREE',
  CvEducationInstitution = 'CV_EDUCATION_INSTITUTION',
  CvGroupExhibition = 'CV_GROUP_EXHIBITION',
  CvPublication = 'CV_PUBLICATION',
  CvSoloExhibition = 'CV_SOLO_EXHIBITION',
  HistoricalStatement = 'HISTORICAL_STATEMENT',
  QuestionnaireQuestion = 'QUESTIONNAIRE_QUESTION',
  SeriesStatement = 'SERIES_STATEMENT'
}

/** Boolean expression to compare columns of type "ENUM_content_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Content_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Content_Type_Enum>;
  _in?: Maybe<Array<Enum_Content_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Content_Type_Enum>;
  _nin?: Maybe<Array<Enum_Content_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_content_type" */
export type Enum_Content_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Content_Type_Max_Fields = {
  __typename?: 'ENUM_content_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Content_Type_Min_Fields = {
  __typename?: 'ENUM_content_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_content_type" */
export type Enum_Content_Type_Mutation_Response = {
  __typename?: 'ENUM_content_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Content_Type>;
};

/** on_conflict condition type for table "ENUM_content_type" */
export type Enum_Content_Type_On_Conflict = {
  constraint: Enum_Content_Type_Constraint;
  update_columns?: Array<Enum_Content_Type_Update_Column>;
  where?: Maybe<Enum_Content_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_content_type". */
export type Enum_Content_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_content_type */
export type Enum_Content_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_content_type" */
export enum Enum_Content_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_content_type" */
export type Enum_Content_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_content_type" */
export enum Enum_Content_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_Role = {
  __typename?: 'ENUM_context_delegate_role';
  /** An array relationship */
  Context_delegate_accesses: Array<Context_Delegate_Access>;
  /** An aggregate relationship */
  Context_delegate_accesses_aggregate: Context_Delegate_Access_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_RoleContext_Delegate_AccessesArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


/** columns and relationships of "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_RoleContext_Delegate_Accesses_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};

/** aggregated selection of "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_Role_Aggregate = {
  __typename?: 'ENUM_context_delegate_role_aggregate';
  aggregate?: Maybe<Enum_Context_Delegate_Role_Aggregate_Fields>;
  nodes: Array<Enum_Context_Delegate_Role>;
};

/** aggregate fields of "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_Role_Aggregate_Fields = {
  __typename?: 'ENUM_context_delegate_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Context_Delegate_Role_Max_Fields>;
  min?: Maybe<Enum_Context_Delegate_Role_Min_Fields>;
};


/** aggregate fields of "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Context_Delegate_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_context_delegate_role". All fields are combined with a logical 'AND'. */
export type Enum_Context_Delegate_Role_Bool_Exp = {
  Context_delegate_accesses?: Maybe<Context_Delegate_Access_Bool_Exp>;
  _and?: Maybe<Array<Enum_Context_Delegate_Role_Bool_Exp>>;
  _not?: Maybe<Enum_Context_Delegate_Role_Bool_Exp>;
  _or?: Maybe<Array<Enum_Context_Delegate_Role_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_context_delegate_role" */
export enum Enum_Context_Delegate_Role_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumContextDelegateRolePkey = 'ENUM_context_delegate_role_pkey'
}

export enum Enum_Context_Delegate_Role_Enum {
  Manager = 'MANAGER',
  Owner = 'OWNER',
  Reader = 'READER'
}

/** Boolean expression to compare columns of type "ENUM_context_delegate_role_enum". All fields are combined with logical 'AND'. */
export type Enum_Context_Delegate_Role_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Context_Delegate_Role_Enum>;
  _in?: Maybe<Array<Enum_Context_Delegate_Role_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Context_Delegate_Role_Enum>;
  _nin?: Maybe<Array<Enum_Context_Delegate_Role_Enum>>;
};

/** input type for inserting data into table "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_Role_Insert_Input = {
  Context_delegate_accesses?: Maybe<Context_Delegate_Access_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Context_Delegate_Role_Max_Fields = {
  __typename?: 'ENUM_context_delegate_role_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Context_Delegate_Role_Min_Fields = {
  __typename?: 'ENUM_context_delegate_role_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_Role_Mutation_Response = {
  __typename?: 'ENUM_context_delegate_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Context_Delegate_Role>;
};

/** input type for inserting object relation for remote table "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_Role_Obj_Rel_Insert_Input = {
  data: Enum_Context_Delegate_Role_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Context_Delegate_Role_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_Role_On_Conflict = {
  constraint: Enum_Context_Delegate_Role_Constraint;
  update_columns?: Array<Enum_Context_Delegate_Role_Update_Column>;
  where?: Maybe<Enum_Context_Delegate_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_context_delegate_role". */
export type Enum_Context_Delegate_Role_Order_By = {
  Context_delegate_accesses_aggregate?: Maybe<Context_Delegate_Access_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_context_delegate_role */
export type Enum_Context_Delegate_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_context_delegate_role" */
export enum Enum_Context_Delegate_Role_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_context_delegate_role" */
export type Enum_Context_Delegate_Role_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_context_delegate_role" */
export enum Enum_Context_Delegate_Role_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_State = {
  __typename?: 'ENUM_context_delegate_state';
  /** An array relationship */
  Context_delegate_accesses: Array<Context_Delegate_Access>;
  /** An aggregate relationship */
  Context_delegate_accesses_aggregate: Context_Delegate_Access_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_StateContext_Delegate_AccessesArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


/** columns and relationships of "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_StateContext_Delegate_Accesses_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};

/** aggregated selection of "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_State_Aggregate = {
  __typename?: 'ENUM_context_delegate_state_aggregate';
  aggregate?: Maybe<Enum_Context_Delegate_State_Aggregate_Fields>;
  nodes: Array<Enum_Context_Delegate_State>;
};

/** aggregate fields of "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_State_Aggregate_Fields = {
  __typename?: 'ENUM_context_delegate_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Context_Delegate_State_Max_Fields>;
  min?: Maybe<Enum_Context_Delegate_State_Min_Fields>;
};


/** aggregate fields of "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Context_Delegate_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_context_delegate_state". All fields are combined with a logical 'AND'. */
export type Enum_Context_Delegate_State_Bool_Exp = {
  Context_delegate_accesses?: Maybe<Context_Delegate_Access_Bool_Exp>;
  _and?: Maybe<Array<Enum_Context_Delegate_State_Bool_Exp>>;
  _not?: Maybe<Enum_Context_Delegate_State_Bool_Exp>;
  _or?: Maybe<Array<Enum_Context_Delegate_State_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_context_delegate_state" */
export enum Enum_Context_Delegate_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumContextDelegateStatePkey = 'ENUM_context_delegate_state_pkey'
}

export enum Enum_Context_Delegate_State_Enum {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Deleted = 'DELETED'
}

/** Boolean expression to compare columns of type "ENUM_context_delegate_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Context_Delegate_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Context_Delegate_State_Enum>;
  _in?: Maybe<Array<Enum_Context_Delegate_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Context_Delegate_State_Enum>;
  _nin?: Maybe<Array<Enum_Context_Delegate_State_Enum>>;
};

/** input type for inserting data into table "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_State_Insert_Input = {
  Context_delegate_accesses?: Maybe<Context_Delegate_Access_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Context_Delegate_State_Max_Fields = {
  __typename?: 'ENUM_context_delegate_state_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Context_Delegate_State_Min_Fields = {
  __typename?: 'ENUM_context_delegate_state_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_State_Mutation_Response = {
  __typename?: 'ENUM_context_delegate_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Context_Delegate_State>;
};

/** input type for inserting object relation for remote table "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_State_Obj_Rel_Insert_Input = {
  data: Enum_Context_Delegate_State_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Context_Delegate_State_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_State_On_Conflict = {
  constraint: Enum_Context_Delegate_State_Constraint;
  update_columns?: Array<Enum_Context_Delegate_State_Update_Column>;
  where?: Maybe<Enum_Context_Delegate_State_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_context_delegate_state". */
export type Enum_Context_Delegate_State_Order_By = {
  Context_delegate_accesses_aggregate?: Maybe<Context_Delegate_Access_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_context_delegate_state */
export type Enum_Context_Delegate_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_context_delegate_state" */
export enum Enum_Context_Delegate_State_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_context_delegate_state" */
export type Enum_Context_Delegate_State_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_context_delegate_state" */
export enum Enum_Context_Delegate_State_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_context_state" */
export type Enum_Context_State = {
  __typename?: 'ENUM_context_state';
  /** An array relationship */
  Contexts: Array<Context>;
  /** An aggregate relationship */
  Contexts_aggregate: Context_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "ENUM_context_state" */
export type Enum_Context_StateContextsArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};


/** columns and relationships of "ENUM_context_state" */
export type Enum_Context_StateContexts_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};

/** aggregated selection of "ENUM_context_state" */
export type Enum_Context_State_Aggregate = {
  __typename?: 'ENUM_context_state_aggregate';
  aggregate?: Maybe<Enum_Context_State_Aggregate_Fields>;
  nodes: Array<Enum_Context_State>;
};

/** aggregate fields of "ENUM_context_state" */
export type Enum_Context_State_Aggregate_Fields = {
  __typename?: 'ENUM_context_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Context_State_Max_Fields>;
  min?: Maybe<Enum_Context_State_Min_Fields>;
};


/** aggregate fields of "ENUM_context_state" */
export type Enum_Context_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Context_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_context_state". All fields are combined with a logical 'AND'. */
export type Enum_Context_State_Bool_Exp = {
  Contexts?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<Enum_Context_State_Bool_Exp>>;
  _not?: Maybe<Enum_Context_State_Bool_Exp>;
  _or?: Maybe<Array<Enum_Context_State_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_context_state" */
export enum Enum_Context_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumContextStatePkey = 'ENUM_context_state_pkey'
}

export enum Enum_Context_State_Enum {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Deleted = 'DELETED'
}

/** Boolean expression to compare columns of type "ENUM_context_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Context_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Context_State_Enum>;
  _in?: Maybe<Array<Enum_Context_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Context_State_Enum>;
  _nin?: Maybe<Array<Enum_Context_State_Enum>>;
};

/** input type for inserting data into table "ENUM_context_state" */
export type Enum_Context_State_Insert_Input = {
  Contexts?: Maybe<Context_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Context_State_Max_Fields = {
  __typename?: 'ENUM_context_state_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Context_State_Min_Fields = {
  __typename?: 'ENUM_context_state_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_context_state" */
export type Enum_Context_State_Mutation_Response = {
  __typename?: 'ENUM_context_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Context_State>;
};

/** input type for inserting object relation for remote table "ENUM_context_state" */
export type Enum_Context_State_Obj_Rel_Insert_Input = {
  data: Enum_Context_State_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Context_State_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_context_state" */
export type Enum_Context_State_On_Conflict = {
  constraint: Enum_Context_State_Constraint;
  update_columns?: Array<Enum_Context_State_Update_Column>;
  where?: Maybe<Enum_Context_State_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_context_state". */
export type Enum_Context_State_Order_By = {
  Contexts_aggregate?: Maybe<Context_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_context_state */
export type Enum_Context_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_context_state" */
export enum Enum_Context_State_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_context_state" */
export type Enum_Context_State_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_context_state" */
export enum Enum_Context_State_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_context_type" */
export type Enum_Context_Type = {
  __typename?: 'ENUM_context_type';
  /** An array relationship */
  Contexts: Array<Context>;
  /** An aggregate relationship */
  Contexts_aggregate: Context_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "ENUM_context_type" */
export type Enum_Context_TypeContextsArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};


/** columns and relationships of "ENUM_context_type" */
export type Enum_Context_TypeContexts_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};

/** aggregated selection of "ENUM_context_type" */
export type Enum_Context_Type_Aggregate = {
  __typename?: 'ENUM_context_type_aggregate';
  aggregate?: Maybe<Enum_Context_Type_Aggregate_Fields>;
  nodes: Array<Enum_Context_Type>;
};

/** aggregate fields of "ENUM_context_type" */
export type Enum_Context_Type_Aggregate_Fields = {
  __typename?: 'ENUM_context_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Context_Type_Max_Fields>;
  min?: Maybe<Enum_Context_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_context_type" */
export type Enum_Context_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Context_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_context_type". All fields are combined with a logical 'AND'. */
export type Enum_Context_Type_Bool_Exp = {
  Contexts?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<Enum_Context_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Context_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Context_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_context_type" */
export enum Enum_Context_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumContextTypePkey = 'ENUM_context_type_pkey'
}

export enum Enum_Context_Type_Enum {
  Admin = 'ADMIN',
  AppAccess = 'APP_ACCESS',
  Artist = 'ARTIST',
  ArtUniverseAnonUser = 'ART_UNIVERSE_ANON_USER',
  BillingAdmin = 'BILLING_ADMIN',
  Collector = 'COLLECTOR',
  Curator = 'CURATOR',
  Gallery = 'GALLERY',
  Partnership = 'PARTNERSHIP',
  PremiumUser = 'PREMIUM_USER',
  ProUser = 'PRO_USER',
  Showroom = 'SHOWROOM',
  SimpleUser = 'SIMPLE_USER'
}

/** Boolean expression to compare columns of type "ENUM_context_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Context_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Context_Type_Enum>;
  _in?: Maybe<Array<Enum_Context_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Context_Type_Enum>;
  _nin?: Maybe<Array<Enum_Context_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_context_type" */
export type Enum_Context_Type_Insert_Input = {
  Contexts?: Maybe<Context_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Context_Type_Max_Fields = {
  __typename?: 'ENUM_context_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Context_Type_Min_Fields = {
  __typename?: 'ENUM_context_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_context_type" */
export type Enum_Context_Type_Mutation_Response = {
  __typename?: 'ENUM_context_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Context_Type>;
};

/** input type for inserting object relation for remote table "ENUM_context_type" */
export type Enum_Context_Type_Obj_Rel_Insert_Input = {
  data: Enum_Context_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Context_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_context_type" */
export type Enum_Context_Type_On_Conflict = {
  constraint: Enum_Context_Type_Constraint;
  update_columns?: Array<Enum_Context_Type_Update_Column>;
  where?: Maybe<Enum_Context_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_context_type". */
export type Enum_Context_Type_Order_By = {
  Contexts_aggregate?: Maybe<Context_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_context_type */
export type Enum_Context_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_context_type" */
export enum Enum_Context_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_context_type" */
export type Enum_Context_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_context_type" */
export enum Enum_Context_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** Discount state catalog */
export type Enum_Discount_State = {
  __typename?: 'ENUM_discount_state';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_discount_state" */
export type Enum_Discount_State_Aggregate = {
  __typename?: 'ENUM_discount_state_aggregate';
  aggregate?: Maybe<Enum_Discount_State_Aggregate_Fields>;
  nodes: Array<Enum_Discount_State>;
};

/** aggregate fields of "ENUM_discount_state" */
export type Enum_Discount_State_Aggregate_Fields = {
  __typename?: 'ENUM_discount_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Discount_State_Max_Fields>;
  min?: Maybe<Enum_Discount_State_Min_Fields>;
};


/** aggregate fields of "ENUM_discount_state" */
export type Enum_Discount_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Discount_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_discount_state". All fields are combined with a logical 'AND'. */
export type Enum_Discount_State_Bool_Exp = {
  _and?: Maybe<Array<Enum_Discount_State_Bool_Exp>>;
  _not?: Maybe<Enum_Discount_State_Bool_Exp>;
  _or?: Maybe<Array<Enum_Discount_State_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_discount_state" */
export enum Enum_Discount_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDiscountStatePkey = 'ENUM_discount_state_pkey'
}

export enum Enum_Discount_State_Enum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Used = 'USED'
}

/** Boolean expression to compare columns of type "ENUM_discount_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Discount_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Discount_State_Enum>;
  _in?: Maybe<Array<Enum_Discount_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Discount_State_Enum>;
  _nin?: Maybe<Array<Enum_Discount_State_Enum>>;
};

/** input type for inserting data into table "ENUM_discount_state" */
export type Enum_Discount_State_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Discount_State_Max_Fields = {
  __typename?: 'ENUM_discount_state_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Discount_State_Min_Fields = {
  __typename?: 'ENUM_discount_state_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_discount_state" */
export type Enum_Discount_State_Mutation_Response = {
  __typename?: 'ENUM_discount_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Discount_State>;
};

/** on_conflict condition type for table "ENUM_discount_state" */
export type Enum_Discount_State_On_Conflict = {
  constraint: Enum_Discount_State_Constraint;
  update_columns?: Array<Enum_Discount_State_Update_Column>;
  where?: Maybe<Enum_Discount_State_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_discount_state". */
export type Enum_Discount_State_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_discount_state */
export type Enum_Discount_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_discount_state" */
export enum Enum_Discount_State_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_discount_state" */
export type Enum_Discount_State_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_discount_state" */
export enum Enum_Discount_State_Update_Column {
  /** column name */
  Value = 'value'
}

/** Discount type catalog */
export type Enum_Discount_Type = {
  __typename?: 'ENUM_discount_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_discount_type" */
export type Enum_Discount_Type_Aggregate = {
  __typename?: 'ENUM_discount_type_aggregate';
  aggregate?: Maybe<Enum_Discount_Type_Aggregate_Fields>;
  nodes: Array<Enum_Discount_Type>;
};

/** aggregate fields of "ENUM_discount_type" */
export type Enum_Discount_Type_Aggregate_Fields = {
  __typename?: 'ENUM_discount_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Discount_Type_Max_Fields>;
  min?: Maybe<Enum_Discount_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_discount_type" */
export type Enum_Discount_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Discount_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_discount_type". All fields are combined with a logical 'AND'. */
export type Enum_Discount_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Discount_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Discount_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Discount_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_discount_type" */
export enum Enum_Discount_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDiscountTypePkey = 'ENUM_discount_type_pkey'
}

export enum Enum_Discount_Type_Enum {
  Global = 'GLOBAL',
  Unique = 'UNIQUE'
}

/** Boolean expression to compare columns of type "ENUM_discount_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Discount_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Discount_Type_Enum>;
  _in?: Maybe<Array<Enum_Discount_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Discount_Type_Enum>;
  _nin?: Maybe<Array<Enum_Discount_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_discount_type" */
export type Enum_Discount_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Discount_Type_Max_Fields = {
  __typename?: 'ENUM_discount_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Discount_Type_Min_Fields = {
  __typename?: 'ENUM_discount_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_discount_type" */
export type Enum_Discount_Type_Mutation_Response = {
  __typename?: 'ENUM_discount_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Discount_Type>;
};

/** on_conflict condition type for table "ENUM_discount_type" */
export type Enum_Discount_Type_On_Conflict = {
  constraint: Enum_Discount_Type_Constraint;
  update_columns?: Array<Enum_Discount_Type_Update_Column>;
  where?: Maybe<Enum_Discount_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_discount_type". */
export type Enum_Discount_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_discount_type */
export type Enum_Discount_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_discount_type" */
export enum Enum_Discount_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_discount_type" */
export type Enum_Discount_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_discount_type" */
export enum Enum_Discount_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** Wether is from kaleido app or My Website */
export type Enum_Discount_Value_Type = {
  __typename?: 'ENUM_discount_value_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_discount_value_type" */
export type Enum_Discount_Value_Type_Aggregate = {
  __typename?: 'ENUM_discount_value_type_aggregate';
  aggregate?: Maybe<Enum_Discount_Value_Type_Aggregate_Fields>;
  nodes: Array<Enum_Discount_Value_Type>;
};

/** aggregate fields of "ENUM_discount_value_type" */
export type Enum_Discount_Value_Type_Aggregate_Fields = {
  __typename?: 'ENUM_discount_value_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Discount_Value_Type_Max_Fields>;
  min?: Maybe<Enum_Discount_Value_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_discount_value_type" */
export type Enum_Discount_Value_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Discount_Value_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_discount_value_type". All fields are combined with a logical 'AND'. */
export type Enum_Discount_Value_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Discount_Value_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Discount_Value_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Discount_Value_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_discount_value_type" */
export enum Enum_Discount_Value_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDiscountValueTypePkey = 'ENUM_discount_value_type_pkey'
}

export enum Enum_Discount_Value_Type_Enum {
  Flat = 'FLAT',
  Percentage = 'PERCENTAGE'
}

/** Boolean expression to compare columns of type "ENUM_discount_value_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Discount_Value_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Discount_Value_Type_Enum>;
  _in?: Maybe<Array<Enum_Discount_Value_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Discount_Value_Type_Enum>;
  _nin?: Maybe<Array<Enum_Discount_Value_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_discount_value_type" */
export type Enum_Discount_Value_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Discount_Value_Type_Max_Fields = {
  __typename?: 'ENUM_discount_value_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Discount_Value_Type_Min_Fields = {
  __typename?: 'ENUM_discount_value_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_discount_value_type" */
export type Enum_Discount_Value_Type_Mutation_Response = {
  __typename?: 'ENUM_discount_value_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Discount_Value_Type>;
};

/** on_conflict condition type for table "ENUM_discount_value_type" */
export type Enum_Discount_Value_Type_On_Conflict = {
  constraint: Enum_Discount_Value_Type_Constraint;
  update_columns?: Array<Enum_Discount_Value_Type_Update_Column>;
  where?: Maybe<Enum_Discount_Value_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_discount_value_type". */
export type Enum_Discount_Value_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_discount_value_type */
export type Enum_Discount_Value_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_discount_value_type" */
export enum Enum_Discount_Value_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_discount_value_type" */
export type Enum_Discount_Value_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_discount_value_type" */
export enum Enum_Discount_Value_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** Possible statuses for Domain_registration */
export type Enum_Domain_Registration_Status = {
  __typename?: 'ENUM_domain_registration_status';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_domain_registration_status" */
export type Enum_Domain_Registration_Status_Aggregate = {
  __typename?: 'ENUM_domain_registration_status_aggregate';
  aggregate?: Maybe<Enum_Domain_Registration_Status_Aggregate_Fields>;
  nodes: Array<Enum_Domain_Registration_Status>;
};

/** aggregate fields of "ENUM_domain_registration_status" */
export type Enum_Domain_Registration_Status_Aggregate_Fields = {
  __typename?: 'ENUM_domain_registration_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Domain_Registration_Status_Max_Fields>;
  min?: Maybe<Enum_Domain_Registration_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_domain_registration_status" */
export type Enum_Domain_Registration_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Domain_Registration_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_domain_registration_status". All fields are combined with a logical 'AND'. */
export type Enum_Domain_Registration_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Domain_Registration_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Domain_Registration_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Domain_Registration_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_domain_registration_status" */
export enum Enum_Domain_Registration_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDomainRegistrationStatusPkey = 'ENUM_domain_registration_status_pkey'
}

export enum Enum_Domain_Registration_Status_Enum {
  /** Domain was registered and should be active */
  Active = 'ACTIVE',
  /** Domain expired and can't be renewed */
  Deleted = 'DELETED',
  /** Domain expired, but might still be able to renew */
  Expired = 'EXPIRED',
  /** Domain could not be registered for some reason */
  FailedToRegister = 'FAILED_TO_REGISTER',
  /** User initiated purchase */
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "ENUM_domain_registration_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Domain_Registration_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Domain_Registration_Status_Enum>;
  _in?: Maybe<Array<Enum_Domain_Registration_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Domain_Registration_Status_Enum>;
  _nin?: Maybe<Array<Enum_Domain_Registration_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_domain_registration_status" */
export type Enum_Domain_Registration_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Domain_Registration_Status_Max_Fields = {
  __typename?: 'ENUM_domain_registration_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Domain_Registration_Status_Min_Fields = {
  __typename?: 'ENUM_domain_registration_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_domain_registration_status" */
export type Enum_Domain_Registration_Status_Mutation_Response = {
  __typename?: 'ENUM_domain_registration_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Domain_Registration_Status>;
};

/** on_conflict condition type for table "ENUM_domain_registration_status" */
export type Enum_Domain_Registration_Status_On_Conflict = {
  constraint: Enum_Domain_Registration_Status_Constraint;
  update_columns?: Array<Enum_Domain_Registration_Status_Update_Column>;
  where?: Maybe<Enum_Domain_Registration_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_domain_registration_status". */
export type Enum_Domain_Registration_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_domain_registration_status */
export type Enum_Domain_Registration_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_domain_registration_status" */
export enum Enum_Domain_Registration_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_domain_registration_status" */
export type Enum_Domain_Registration_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_domain_registration_status" */
export enum Enum_Domain_Registration_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_dynamic_cv_exhibition_type" */
export type Enum_Dynamic_Cv_Exhibition_Type = {
  __typename?: 'ENUM_dynamic_cv_exhibition_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_dynamic_cv_exhibition_type" */
export type Enum_Dynamic_Cv_Exhibition_Type_Aggregate = {
  __typename?: 'ENUM_dynamic_cv_exhibition_type_aggregate';
  aggregate?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Aggregate_Fields>;
  nodes: Array<Enum_Dynamic_Cv_Exhibition_Type>;
};

/** aggregate fields of "ENUM_dynamic_cv_exhibition_type" */
export type Enum_Dynamic_Cv_Exhibition_Type_Aggregate_Fields = {
  __typename?: 'ENUM_dynamic_cv_exhibition_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Max_Fields>;
  min?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_dynamic_cv_exhibition_type" */
export type Enum_Dynamic_Cv_Exhibition_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_dynamic_cv_exhibition_type". All fields are combined with a logical 'AND'. */
export type Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_dynamic_cv_exhibition_type" */
export enum Enum_Dynamic_Cv_Exhibition_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDynamicCvExhibitionTypePkey = 'ENUM_dynamic_cv_exhibition_type_pkey'
}

export enum Enum_Dynamic_Cv_Exhibition_Type_Enum {
  Group = 'GROUP',
  Solo = 'SOLO'
}

/** Boolean expression to compare columns of type "ENUM_dynamic_cv_exhibition_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Dynamic_Cv_Exhibition_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Enum>;
  _in?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Enum>;
  _nin?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_dynamic_cv_exhibition_type" */
export type Enum_Dynamic_Cv_Exhibition_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Dynamic_Cv_Exhibition_Type_Max_Fields = {
  __typename?: 'ENUM_dynamic_cv_exhibition_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Dynamic_Cv_Exhibition_Type_Min_Fields = {
  __typename?: 'ENUM_dynamic_cv_exhibition_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_dynamic_cv_exhibition_type" */
export type Enum_Dynamic_Cv_Exhibition_Type_Mutation_Response = {
  __typename?: 'ENUM_dynamic_cv_exhibition_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Dynamic_Cv_Exhibition_Type>;
};

/** on_conflict condition type for table "ENUM_dynamic_cv_exhibition_type" */
export type Enum_Dynamic_Cv_Exhibition_Type_On_Conflict = {
  constraint: Enum_Dynamic_Cv_Exhibition_Type_Constraint;
  update_columns?: Array<Enum_Dynamic_Cv_Exhibition_Type_Update_Column>;
  where?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_dynamic_cv_exhibition_type". */
export type Enum_Dynamic_Cv_Exhibition_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_dynamic_cv_exhibition_type */
export type Enum_Dynamic_Cv_Exhibition_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_dynamic_cv_exhibition_type" */
export enum Enum_Dynamic_Cv_Exhibition_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_dynamic_cv_exhibition_type" */
export type Enum_Dynamic_Cv_Exhibition_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_dynamic_cv_exhibition_type" */
export enum Enum_Dynamic_Cv_Exhibition_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_dynamic_cv_section_type" */
export type Enum_Dynamic_Cv_Section_Type = {
  __typename?: 'ENUM_dynamic_cv_section_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_dynamic_cv_section_type" */
export type Enum_Dynamic_Cv_Section_Type_Aggregate = {
  __typename?: 'ENUM_dynamic_cv_section_type_aggregate';
  aggregate?: Maybe<Enum_Dynamic_Cv_Section_Type_Aggregate_Fields>;
  nodes: Array<Enum_Dynamic_Cv_Section_Type>;
};

/** aggregate fields of "ENUM_dynamic_cv_section_type" */
export type Enum_Dynamic_Cv_Section_Type_Aggregate_Fields = {
  __typename?: 'ENUM_dynamic_cv_section_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Dynamic_Cv_Section_Type_Max_Fields>;
  min?: Maybe<Enum_Dynamic_Cv_Section_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_dynamic_cv_section_type" */
export type Enum_Dynamic_Cv_Section_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_dynamic_cv_section_type". All fields are combined with a logical 'AND'. */
export type Enum_Dynamic_Cv_Section_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Dynamic_Cv_Section_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_dynamic_cv_section_type" */
export enum Enum_Dynamic_Cv_Section_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDynamicCvSectionTypePkey = 'ENUM_dynamic_cv_section_type_pkey'
}

export enum Enum_Dynamic_Cv_Section_Type_Enum {
  Awards = 'AWARDS',
  Collections = 'COLLECTIONS',
  Contact = 'CONTACT',
  Education = 'EDUCATION',
  Exhibitions = 'EXHIBITIONS',
  PersonalInfo = 'PERSONAL_INFO',
  Publications = 'PUBLICATIONS'
}

/** Boolean expression to compare columns of type "ENUM_dynamic_cv_section_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Dynamic_Cv_Section_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Dynamic_Cv_Section_Type_Enum>;
  _in?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Dynamic_Cv_Section_Type_Enum>;
  _nin?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_dynamic_cv_section_type" */
export type Enum_Dynamic_Cv_Section_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Dynamic_Cv_Section_Type_Max_Fields = {
  __typename?: 'ENUM_dynamic_cv_section_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Dynamic_Cv_Section_Type_Min_Fields = {
  __typename?: 'ENUM_dynamic_cv_section_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_dynamic_cv_section_type" */
export type Enum_Dynamic_Cv_Section_Type_Mutation_Response = {
  __typename?: 'ENUM_dynamic_cv_section_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Dynamic_Cv_Section_Type>;
};

/** on_conflict condition type for table "ENUM_dynamic_cv_section_type" */
export type Enum_Dynamic_Cv_Section_Type_On_Conflict = {
  constraint: Enum_Dynamic_Cv_Section_Type_Constraint;
  update_columns?: Array<Enum_Dynamic_Cv_Section_Type_Update_Column>;
  where?: Maybe<Enum_Dynamic_Cv_Section_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_dynamic_cv_section_type". */
export type Enum_Dynamic_Cv_Section_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_dynamic_cv_section_type */
export type Enum_Dynamic_Cv_Section_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_dynamic_cv_section_type" */
export enum Enum_Dynamic_Cv_Section_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_dynamic_cv_section_type" */
export type Enum_Dynamic_Cv_Section_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_dynamic_cv_section_type" */
export enum Enum_Dynamic_Cv_Section_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_dynamic_cv_status" */
export type Enum_Dynamic_Cv_Status = {
  __typename?: 'ENUM_dynamic_cv_status';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_dynamic_cv_status" */
export type Enum_Dynamic_Cv_Status_Aggregate = {
  __typename?: 'ENUM_dynamic_cv_status_aggregate';
  aggregate?: Maybe<Enum_Dynamic_Cv_Status_Aggregate_Fields>;
  nodes: Array<Enum_Dynamic_Cv_Status>;
};

/** aggregate fields of "ENUM_dynamic_cv_status" */
export type Enum_Dynamic_Cv_Status_Aggregate_Fields = {
  __typename?: 'ENUM_dynamic_cv_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Dynamic_Cv_Status_Max_Fields>;
  min?: Maybe<Enum_Dynamic_Cv_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_dynamic_cv_status" */
export type Enum_Dynamic_Cv_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Dynamic_Cv_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_dynamic_cv_status". All fields are combined with a logical 'AND'. */
export type Enum_Dynamic_Cv_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Dynamic_Cv_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Dynamic_Cv_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Dynamic_Cv_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_dynamic_cv_status" */
export enum Enum_Dynamic_Cv_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDynamicCvStatusPkey = 'ENUM_dynamic_cv_status_pkey'
}

export enum Enum_Dynamic_Cv_Status_Enum {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

/** Boolean expression to compare columns of type "ENUM_dynamic_cv_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Dynamic_Cv_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Dynamic_Cv_Status_Enum>;
  _in?: Maybe<Array<Enum_Dynamic_Cv_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Dynamic_Cv_Status_Enum>;
  _nin?: Maybe<Array<Enum_Dynamic_Cv_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_dynamic_cv_status" */
export type Enum_Dynamic_Cv_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Dynamic_Cv_Status_Max_Fields = {
  __typename?: 'ENUM_dynamic_cv_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Dynamic_Cv_Status_Min_Fields = {
  __typename?: 'ENUM_dynamic_cv_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_dynamic_cv_status" */
export type Enum_Dynamic_Cv_Status_Mutation_Response = {
  __typename?: 'ENUM_dynamic_cv_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Dynamic_Cv_Status>;
};

/** on_conflict condition type for table "ENUM_dynamic_cv_status" */
export type Enum_Dynamic_Cv_Status_On_Conflict = {
  constraint: Enum_Dynamic_Cv_Status_Constraint;
  update_columns?: Array<Enum_Dynamic_Cv_Status_Update_Column>;
  where?: Maybe<Enum_Dynamic_Cv_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_dynamic_cv_status". */
export type Enum_Dynamic_Cv_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_dynamic_cv_status */
export type Enum_Dynamic_Cv_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_dynamic_cv_status" */
export enum Enum_Dynamic_Cv_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_dynamic_cv_status" */
export type Enum_Dynamic_Cv_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_dynamic_cv_status" */
export enum Enum_Dynamic_Cv_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_edition_status" */
export type Enum_Edition_Status = {
  __typename?: 'ENUM_edition_status';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_edition_status" */
export type Enum_Edition_Status_Aggregate = {
  __typename?: 'ENUM_edition_status_aggregate';
  aggregate?: Maybe<Enum_Edition_Status_Aggregate_Fields>;
  nodes: Array<Enum_Edition_Status>;
};

/** aggregate fields of "ENUM_edition_status" */
export type Enum_Edition_Status_Aggregate_Fields = {
  __typename?: 'ENUM_edition_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Edition_Status_Max_Fields>;
  min?: Maybe<Enum_Edition_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_edition_status" */
export type Enum_Edition_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Edition_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_edition_status". All fields are combined with a logical 'AND'. */
export type Enum_Edition_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Edition_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Edition_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Edition_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_edition_status" */
export enum Enum_Edition_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumEditionStatusPkey = 'ENUM_edition_status_pkey'
}

export enum Enum_Edition_Status_Enum {
  /** All the required fields have values */
  Complete = 'COMPLETE',
  /** Not all required fields have values */
  Incomplete = 'INCOMPLETE'
}

/** Boolean expression to compare columns of type "ENUM_edition_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Edition_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Edition_Status_Enum>;
  _in?: Maybe<Array<Enum_Edition_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Edition_Status_Enum>;
  _nin?: Maybe<Array<Enum_Edition_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_edition_status" */
export type Enum_Edition_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Edition_Status_Max_Fields = {
  __typename?: 'ENUM_edition_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Edition_Status_Min_Fields = {
  __typename?: 'ENUM_edition_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_edition_status" */
export type Enum_Edition_Status_Mutation_Response = {
  __typename?: 'ENUM_edition_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Edition_Status>;
};

/** on_conflict condition type for table "ENUM_edition_status" */
export type Enum_Edition_Status_On_Conflict = {
  constraint: Enum_Edition_Status_Constraint;
  update_columns?: Array<Enum_Edition_Status_Update_Column>;
  where?: Maybe<Enum_Edition_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_edition_status". */
export type Enum_Edition_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_edition_status */
export type Enum_Edition_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_edition_status" */
export enum Enum_Edition_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_edition_status" */
export type Enum_Edition_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_edition_status" */
export enum Enum_Edition_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_email_language" */
export type Enum_Email_Language = {
  __typename?: 'ENUM_email_language';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_email_language" */
export type Enum_Email_Language_Aggregate = {
  __typename?: 'ENUM_email_language_aggregate';
  aggregate?: Maybe<Enum_Email_Language_Aggregate_Fields>;
  nodes: Array<Enum_Email_Language>;
};

/** aggregate fields of "ENUM_email_language" */
export type Enum_Email_Language_Aggregate_Fields = {
  __typename?: 'ENUM_email_language_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Email_Language_Max_Fields>;
  min?: Maybe<Enum_Email_Language_Min_Fields>;
};


/** aggregate fields of "ENUM_email_language" */
export type Enum_Email_Language_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Email_Language_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_email_language". All fields are combined with a logical 'AND'. */
export type Enum_Email_Language_Bool_Exp = {
  _and?: Maybe<Array<Enum_Email_Language_Bool_Exp>>;
  _not?: Maybe<Enum_Email_Language_Bool_Exp>;
  _or?: Maybe<Array<Enum_Email_Language_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_email_language" */
export enum Enum_Email_Language_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumEmailLanguagePkey = 'ENUM_email_language_pkey'
}

export enum Enum_Email_Language_Enum {
  /** English */
  English = 'ENGLISH',
  /** Français */
  French = 'FRENCH',
  /** Português */
  Portuguese = 'PORTUGUESE',
  /** Română */
  Romanian = 'ROMANIAN',
  /** Español */
  Spanish = 'SPANISH'
}

/** Boolean expression to compare columns of type "ENUM_email_language_enum". All fields are combined with logical 'AND'. */
export type Enum_Email_Language_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Email_Language_Enum>;
  _in?: Maybe<Array<Enum_Email_Language_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Email_Language_Enum>;
  _nin?: Maybe<Array<Enum_Email_Language_Enum>>;
};

/** input type for inserting data into table "ENUM_email_language" */
export type Enum_Email_Language_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Email_Language_Max_Fields = {
  __typename?: 'ENUM_email_language_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Email_Language_Min_Fields = {
  __typename?: 'ENUM_email_language_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_email_language" */
export type Enum_Email_Language_Mutation_Response = {
  __typename?: 'ENUM_email_language_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Email_Language>;
};

/** input type for inserting object relation for remote table "ENUM_email_language" */
export type Enum_Email_Language_Obj_Rel_Insert_Input = {
  data: Enum_Email_Language_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Email_Language_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_email_language" */
export type Enum_Email_Language_On_Conflict = {
  constraint: Enum_Email_Language_Constraint;
  update_columns?: Array<Enum_Email_Language_Update_Column>;
  where?: Maybe<Enum_Email_Language_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_email_language". */
export type Enum_Email_Language_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_email_language */
export type Enum_Email_Language_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_email_language" */
export enum Enum_Email_Language_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_email_language" */
export type Enum_Email_Language_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_email_language" */
export enum Enum_Email_Language_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_email_status" */
export type Enum_Email_Status = {
  __typename?: 'ENUM_email_status';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_email_status" */
export type Enum_Email_Status_Aggregate = {
  __typename?: 'ENUM_email_status_aggregate';
  aggregate?: Maybe<Enum_Email_Status_Aggregate_Fields>;
  nodes: Array<Enum_Email_Status>;
};

/** aggregate fields of "ENUM_email_status" */
export type Enum_Email_Status_Aggregate_Fields = {
  __typename?: 'ENUM_email_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Email_Status_Max_Fields>;
  min?: Maybe<Enum_Email_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_email_status" */
export type Enum_Email_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Email_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_email_status". All fields are combined with a logical 'AND'. */
export type Enum_Email_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Email_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Email_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Email_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_email_status" */
export enum Enum_Email_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumEmailStatusPkey = 'ENUM_email_status_pkey'
}

export enum Enum_Email_Status_Enum {
  /** Status from SES through SNS webhook. */
  Bounce = 'BOUNCE',
  /** The recipient clicked one or more links in the email */
  Click = 'CLICK',
  /** Status from SES through SNS webhook. */
  Complaint = 'COMPLAINT',
  /** Status from SES through SNS webhook. */
  Delivery = 'DELIVERY',
  /** General error. */
  Error = 'ERROR',
  /** The recipient received the message and opened it in their email client */
  Open = 'OPEN',
  /** BE processed the email and sent it to SES. */
  Sent = 'SENT',
  /** Email inserted in Emails table, waiting for BE to process it. */
  Waiting = 'WAITING'
}

/** Boolean expression to compare columns of type "ENUM_email_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Email_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Email_Status_Enum>;
  _in?: Maybe<Array<Enum_Email_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Email_Status_Enum>;
  _nin?: Maybe<Array<Enum_Email_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_email_status" */
export type Enum_Email_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Email_Status_Max_Fields = {
  __typename?: 'ENUM_email_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Email_Status_Min_Fields = {
  __typename?: 'ENUM_email_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_email_status" */
export type Enum_Email_Status_Mutation_Response = {
  __typename?: 'ENUM_email_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Email_Status>;
};

/** on_conflict condition type for table "ENUM_email_status" */
export type Enum_Email_Status_On_Conflict = {
  constraint: Enum_Email_Status_Constraint;
  update_columns?: Array<Enum_Email_Status_Update_Column>;
  where?: Maybe<Enum_Email_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_email_status". */
export type Enum_Email_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_email_status */
export type Enum_Email_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_email_status" */
export enum Enum_Email_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_email_status" */
export type Enum_Email_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_email_status" */
export enum Enum_Email_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_email_validation_token_status" */
export type Enum_Email_Validation_Token_Status = {
  __typename?: 'ENUM_email_validation_token_status';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_email_validation_token_status" */
export type Enum_Email_Validation_Token_Status_Aggregate = {
  __typename?: 'ENUM_email_validation_token_status_aggregate';
  aggregate?: Maybe<Enum_Email_Validation_Token_Status_Aggregate_Fields>;
  nodes: Array<Enum_Email_Validation_Token_Status>;
};

/** aggregate fields of "ENUM_email_validation_token_status" */
export type Enum_Email_Validation_Token_Status_Aggregate_Fields = {
  __typename?: 'ENUM_email_validation_token_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Email_Validation_Token_Status_Max_Fields>;
  min?: Maybe<Enum_Email_Validation_Token_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_email_validation_token_status" */
export type Enum_Email_Validation_Token_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Email_Validation_Token_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_email_validation_token_status". All fields are combined with a logical 'AND'. */
export type Enum_Email_Validation_Token_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Email_Validation_Token_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Email_Validation_Token_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Email_Validation_Token_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_email_validation_token_status" */
export enum Enum_Email_Validation_Token_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumEmailValidationTokenStatusPkey = 'ENUM_email_validation_token_status_pkey'
}

export enum Enum_Email_Validation_Token_Status_Enum {
  /** Validation failed */
  Fail = 'FAIL',
  /** A started validation process */
  Pending = 'PENDING',
  /** Validation passed */
  Success = 'SUCCESS'
}

/** Boolean expression to compare columns of type "ENUM_email_validation_token_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Email_Validation_Token_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Email_Validation_Token_Status_Enum>;
  _in?: Maybe<Array<Enum_Email_Validation_Token_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Email_Validation_Token_Status_Enum>;
  _nin?: Maybe<Array<Enum_Email_Validation_Token_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_email_validation_token_status" */
export type Enum_Email_Validation_Token_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Email_Validation_Token_Status_Max_Fields = {
  __typename?: 'ENUM_email_validation_token_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Email_Validation_Token_Status_Min_Fields = {
  __typename?: 'ENUM_email_validation_token_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_email_validation_token_status" */
export type Enum_Email_Validation_Token_Status_Mutation_Response = {
  __typename?: 'ENUM_email_validation_token_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Email_Validation_Token_Status>;
};

/** input type for inserting object relation for remote table "ENUM_email_validation_token_status" */
export type Enum_Email_Validation_Token_Status_Obj_Rel_Insert_Input = {
  data: Enum_Email_Validation_Token_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Email_Validation_Token_Status_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_email_validation_token_status" */
export type Enum_Email_Validation_Token_Status_On_Conflict = {
  constraint: Enum_Email_Validation_Token_Status_Constraint;
  update_columns?: Array<Enum_Email_Validation_Token_Status_Update_Column>;
  where?: Maybe<Enum_Email_Validation_Token_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_email_validation_token_status". */
export type Enum_Email_Validation_Token_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_email_validation_token_status */
export type Enum_Email_Validation_Token_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_email_validation_token_status" */
export enum Enum_Email_Validation_Token_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_email_validation_token_status" */
export type Enum_Email_Validation_Token_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_email_validation_token_status" */
export enum Enum_Email_Validation_Token_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_file_metadata_status" */
export type Enum_File_Metadata_Status = {
  __typename?: 'ENUM_file_metadata_status';
  /** An array relationship */
  File_metadata: Array<File_Metadata>;
  /** An aggregate relationship */
  File_metadata_aggregate: File_Metadata_Aggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "ENUM_file_metadata_status" */
export type Enum_File_Metadata_StatusFile_MetadataArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};


/** columns and relationships of "ENUM_file_metadata_status" */
export type Enum_File_Metadata_StatusFile_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};

/** aggregated selection of "ENUM_file_metadata_status" */
export type Enum_File_Metadata_Status_Aggregate = {
  __typename?: 'ENUM_file_metadata_status_aggregate';
  aggregate?: Maybe<Enum_File_Metadata_Status_Aggregate_Fields>;
  nodes: Array<Enum_File_Metadata_Status>;
};

/** aggregate fields of "ENUM_file_metadata_status" */
export type Enum_File_Metadata_Status_Aggregate_Fields = {
  __typename?: 'ENUM_file_metadata_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_File_Metadata_Status_Max_Fields>;
  min?: Maybe<Enum_File_Metadata_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_file_metadata_status" */
export type Enum_File_Metadata_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_File_Metadata_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_file_metadata_status". All fields are combined with a logical 'AND'. */
export type Enum_File_Metadata_Status_Bool_Exp = {
  File_metadata?: Maybe<File_Metadata_Bool_Exp>;
  _and?: Maybe<Array<Enum_File_Metadata_Status_Bool_Exp>>;
  _not?: Maybe<Enum_File_Metadata_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_File_Metadata_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_file_metadata_status" */
export enum Enum_File_Metadata_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumFileMetadataStatusPkey = 'ENUM_file_metadata_status_pkey'
}

export enum Enum_File_Metadata_Status_Enum {
  /** Deleted file from storage service */
  Deleted = 'DELETED',
  /** Failed upload to storage service */
  Failed = 'FAILED',
  /** Finished upload to storage service */
  Finished = 'FINISHED',
  /** Started upload to storage service */
  Started = 'STARTED'
}

/** Boolean expression to compare columns of type "ENUM_file_metadata_status_enum". All fields are combined with logical 'AND'. */
export type Enum_File_Metadata_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_File_Metadata_Status_Enum>;
  _in?: Maybe<Array<Enum_File_Metadata_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_File_Metadata_Status_Enum>;
  _nin?: Maybe<Array<Enum_File_Metadata_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_file_metadata_status" */
export type Enum_File_Metadata_Status_Insert_Input = {
  File_metadata?: Maybe<File_Metadata_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_File_Metadata_Status_Max_Fields = {
  __typename?: 'ENUM_file_metadata_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_File_Metadata_Status_Min_Fields = {
  __typename?: 'ENUM_file_metadata_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_file_metadata_status" */
export type Enum_File_Metadata_Status_Mutation_Response = {
  __typename?: 'ENUM_file_metadata_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_File_Metadata_Status>;
};

/** input type for inserting object relation for remote table "ENUM_file_metadata_status" */
export type Enum_File_Metadata_Status_Obj_Rel_Insert_Input = {
  data: Enum_File_Metadata_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_File_Metadata_Status_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_file_metadata_status" */
export type Enum_File_Metadata_Status_On_Conflict = {
  constraint: Enum_File_Metadata_Status_Constraint;
  update_columns?: Array<Enum_File_Metadata_Status_Update_Column>;
  where?: Maybe<Enum_File_Metadata_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_file_metadata_status". */
export type Enum_File_Metadata_Status_Order_By = {
  File_metadata_aggregate?: Maybe<File_Metadata_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_file_metadata_status */
export type Enum_File_Metadata_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_file_metadata_status" */
export enum Enum_File_Metadata_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_file_metadata_status" */
export type Enum_File_Metadata_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_file_metadata_status" */
export enum Enum_File_Metadata_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Flags type for Kaleido */
export type Enum_Flags_Type = {
  __typename?: 'ENUM_flags_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_flags_type" */
export type Enum_Flags_Type_Aggregate = {
  __typename?: 'ENUM_flags_type_aggregate';
  aggregate?: Maybe<Enum_Flags_Type_Aggregate_Fields>;
  nodes: Array<Enum_Flags_Type>;
};

/** aggregate fields of "ENUM_flags_type" */
export type Enum_Flags_Type_Aggregate_Fields = {
  __typename?: 'ENUM_flags_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Flags_Type_Max_Fields>;
  min?: Maybe<Enum_Flags_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_flags_type" */
export type Enum_Flags_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Flags_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_flags_type". All fields are combined with a logical 'AND'. */
export type Enum_Flags_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Flags_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Flags_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Flags_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_flags_type" */
export enum Enum_Flags_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumFlagsTypePkey = 'ENUM_flags_type_pkey'
}

export enum Enum_Flags_Type_Enum {
  BottomNavbarInteracted = 'BOTTOM_NAVBAR_INTERACTED',
  DismissedDynamicCvBanner = 'DISMISSED_DYNAMIC_CV_BANNER',
  DownloadArAppCtaPrompt = 'DOWNLOAD_AR_APP_CTA_PROMPT',
  EnableDashboardStu = 'ENABLE_DASHBOARD_STU',
  EnableSupersite = 'ENABLE_SUPERSITE',
  FollowersDashboardInfoBanner = 'FOLLOWERS_DASHBOARD_INFO_BANNER',
  HasNewRecognition = 'HAS_NEW_RECOGNITION',
  HasSeen_4ColumnTooltip = 'HAS_SEEN_4_COLUMN_TOOLTIP',
  HasSeenDashboardWelcomeFeatures = 'HAS_SEEN_DASHBOARD_WELCOME_FEATURES',
  HasSeenMyProvenanceFtu = 'HAS_SEEN_MY_PROVENANCE_FTU',
  HasSeenMyStudioModal = 'HAS_SEEN_MY_STUDIO_MODAL',
  HasUsedMyWebsiteFtu = 'HAS_USED_MY_WEBSITE_FTU',
  HasValidatedLocation = 'HAS_VALIDATED_LOCATION',
  MyAugmentationsInfoBanner = 'MY_AUGMENTATIONS_INFO_BANNER',
  MyProvenanceInfoBanner = 'MY_PROVENANCE_INFO_BANNER',
  MySubcriptionsInfoBanner = 'MY_SUBCRIPTIONS_INFO_BANNER',
  NotificationsChatModal = 'NOTIFICATIONS_CHAT_MODAL',
  NotificationsDefaultModal = 'NOTIFICATIONS_DEFAULT_MODAL',
  NotificationsFavoriteModal = 'NOTIFICATIONS_FAVORITE_MODAL',
  NotificationsOnboardingModal = 'NOTIFICATIONS_ONBOARDING_MODAL',
  NotificationsRecognizeModal = 'NOTIFICATIONS_RECOGNIZE_MODAL',
  OwnerHasViewedProfile = 'OWNER_HAS_VIEWED_PROFILE',
  VoteForKaleidoWebby = 'VOTE_FOR_KALEIDO_WEBBY'
}

/** Boolean expression to compare columns of type "ENUM_flags_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Flags_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Flags_Type_Enum>;
  _in?: Maybe<Array<Enum_Flags_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Flags_Type_Enum>;
  _nin?: Maybe<Array<Enum_Flags_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_flags_type" */
export type Enum_Flags_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Flags_Type_Max_Fields = {
  __typename?: 'ENUM_flags_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Flags_Type_Min_Fields = {
  __typename?: 'ENUM_flags_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_flags_type" */
export type Enum_Flags_Type_Mutation_Response = {
  __typename?: 'ENUM_flags_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Flags_Type>;
};

/** on_conflict condition type for table "ENUM_flags_type" */
export type Enum_Flags_Type_On_Conflict = {
  constraint: Enum_Flags_Type_Constraint;
  update_columns?: Array<Enum_Flags_Type_Update_Column>;
  where?: Maybe<Enum_Flags_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_flags_type". */
export type Enum_Flags_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_flags_type */
export type Enum_Flags_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_flags_type" */
export enum Enum_Flags_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_flags_type" */
export type Enum_Flags_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_flags_type" */
export enum Enum_Flags_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** Catalog use to detect what configuration has to execute forge (Primary, Secondary, Stories, etc) */
export type Enum_Forge_Configuration_Type = {
  __typename?: 'ENUM_forge_configuration_type';
  VALUE: Scalars['String'];
};

/** aggregated selection of "ENUM_forge_configuration_type" */
export type Enum_Forge_Configuration_Type_Aggregate = {
  __typename?: 'ENUM_forge_configuration_type_aggregate';
  aggregate?: Maybe<Enum_Forge_Configuration_Type_Aggregate_Fields>;
  nodes: Array<Enum_Forge_Configuration_Type>;
};

/** aggregate fields of "ENUM_forge_configuration_type" */
export type Enum_Forge_Configuration_Type_Aggregate_Fields = {
  __typename?: 'ENUM_forge_configuration_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Forge_Configuration_Type_Max_Fields>;
  min?: Maybe<Enum_Forge_Configuration_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_forge_configuration_type" */
export type Enum_Forge_Configuration_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Forge_Configuration_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_forge_configuration_type". All fields are combined with a logical 'AND'. */
export type Enum_Forge_Configuration_Type_Bool_Exp = {
  VALUE?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Enum_Forge_Configuration_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Forge_Configuration_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Forge_Configuration_Type_Bool_Exp>>;
};

/** unique or primary key constraints on table "ENUM_forge_configuration_type" */
export enum Enum_Forge_Configuration_Type_Constraint {
  /** unique or primary key constraint on columns "VALUE" */
  EnumFileMetadataTypePkey = 'ENUM_file_metadata_type_pkey'
}

export enum Enum_Forge_Configuration_Type_Enum {
  ArtPrizeBackground = 'ART_PRIZE_BACKGROUND',
  Avatar = 'AVATAR',
  Banner = 'BANNER',
  PrimaryArtwork = 'PRIMARY_ARTWORK',
  SecondaryArtwork = 'SECONDARY_ARTWORK',
  StoryArtwork = 'STORY_ARTWORK',
  ThumbnailArtwork = 'THUMBNAIL_ARTWORK'
}

/** Boolean expression to compare columns of type "ENUM_forge_configuration_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Forge_Configuration_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Forge_Configuration_Type_Enum>;
  _in?: Maybe<Array<Enum_Forge_Configuration_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Forge_Configuration_Type_Enum>;
  _nin?: Maybe<Array<Enum_Forge_Configuration_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_forge_configuration_type" */
export type Enum_Forge_Configuration_Type_Insert_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Forge_Configuration_Type_Max_Fields = {
  __typename?: 'ENUM_forge_configuration_type_max_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Forge_Configuration_Type_Min_Fields = {
  __typename?: 'ENUM_forge_configuration_type_min_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_forge_configuration_type" */
export type Enum_Forge_Configuration_Type_Mutation_Response = {
  __typename?: 'ENUM_forge_configuration_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Forge_Configuration_Type>;
};

/** on_conflict condition type for table "ENUM_forge_configuration_type" */
export type Enum_Forge_Configuration_Type_On_Conflict = {
  constraint: Enum_Forge_Configuration_Type_Constraint;
  update_columns?: Array<Enum_Forge_Configuration_Type_Update_Column>;
  where?: Maybe<Enum_Forge_Configuration_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_forge_configuration_type". */
export type Enum_Forge_Configuration_Type_Order_By = {
  VALUE?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_forge_configuration_type */
export type Enum_Forge_Configuration_Type_Pk_Columns_Input = {
  VALUE: Scalars['String'];
};

/** select columns of table "ENUM_forge_configuration_type" */
export enum Enum_Forge_Configuration_Type_Select_Column {
  /** column name */
  Value = 'VALUE'
}

/** input type for updating data in table "ENUM_forge_configuration_type" */
export type Enum_Forge_Configuration_Type_Set_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_forge_configuration_type" */
export enum Enum_Forge_Configuration_Type_Update_Column {
  /** column name */
  Value = 'VALUE'
}

/** Catalog for the forge status available */
export type Enum_Forge_Status = {
  __typename?: 'ENUM_forge_status';
  VALUE: Scalars['String'];
};

/** aggregated selection of "ENUM_forge_status" */
export type Enum_Forge_Status_Aggregate = {
  __typename?: 'ENUM_forge_status_aggregate';
  aggregate?: Maybe<Enum_Forge_Status_Aggregate_Fields>;
  nodes: Array<Enum_Forge_Status>;
};

/** aggregate fields of "ENUM_forge_status" */
export type Enum_Forge_Status_Aggregate_Fields = {
  __typename?: 'ENUM_forge_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Forge_Status_Max_Fields>;
  min?: Maybe<Enum_Forge_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_forge_status" */
export type Enum_Forge_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Forge_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_forge_status". All fields are combined with a logical 'AND'. */
export type Enum_Forge_Status_Bool_Exp = {
  VALUE?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Enum_Forge_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Forge_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Forge_Status_Bool_Exp>>;
};

/** unique or primary key constraints on table "ENUM_forge_status" */
export enum Enum_Forge_Status_Constraint {
  /** unique or primary key constraint on columns "VALUE" */
  EnumForgeStatusPkey = 'ENUM_forge_status_pkey'
}

export enum Enum_Forge_Status_Enum {
  Created = 'CREATED',
  Fetched = 'FETCHED',
  Finished = 'FINISHED'
}

/** Boolean expression to compare columns of type "ENUM_forge_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Forge_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Forge_Status_Enum>;
  _in?: Maybe<Array<Enum_Forge_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Forge_Status_Enum>;
  _nin?: Maybe<Array<Enum_Forge_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_forge_status" */
export type Enum_Forge_Status_Insert_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Forge_Status_Max_Fields = {
  __typename?: 'ENUM_forge_status_max_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Forge_Status_Min_Fields = {
  __typename?: 'ENUM_forge_status_min_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_forge_status" */
export type Enum_Forge_Status_Mutation_Response = {
  __typename?: 'ENUM_forge_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Forge_Status>;
};

/** on_conflict condition type for table "ENUM_forge_status" */
export type Enum_Forge_Status_On_Conflict = {
  constraint: Enum_Forge_Status_Constraint;
  update_columns?: Array<Enum_Forge_Status_Update_Column>;
  where?: Maybe<Enum_Forge_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_forge_status". */
export type Enum_Forge_Status_Order_By = {
  VALUE?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_forge_status */
export type Enum_Forge_Status_Pk_Columns_Input = {
  VALUE: Scalars['String'];
};

/** select columns of table "ENUM_forge_status" */
export enum Enum_Forge_Status_Select_Column {
  /** column name */
  Value = 'VALUE'
}

/** input type for updating data in table "ENUM_forge_status" */
export type Enum_Forge_Status_Set_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_forge_status" */
export enum Enum_Forge_Status_Update_Column {
  /** column name */
  Value = 'VALUE'
}

/** This is an enum for types of infractions a user has done in Kaleido */
export type Enum_Infraction_Flag_Type = {
  __typename?: 'ENUM_infraction_flag_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_infraction_flag_type" */
export type Enum_Infraction_Flag_Type_Aggregate = {
  __typename?: 'ENUM_infraction_flag_type_aggregate';
  aggregate?: Maybe<Enum_Infraction_Flag_Type_Aggregate_Fields>;
  nodes: Array<Enum_Infraction_Flag_Type>;
};

/** aggregate fields of "ENUM_infraction_flag_type" */
export type Enum_Infraction_Flag_Type_Aggregate_Fields = {
  __typename?: 'ENUM_infraction_flag_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Infraction_Flag_Type_Max_Fields>;
  min?: Maybe<Enum_Infraction_Flag_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_infraction_flag_type" */
export type Enum_Infraction_Flag_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Infraction_Flag_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_infraction_flag_type". All fields are combined with a logical 'AND'. */
export type Enum_Infraction_Flag_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Infraction_Flag_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Infraction_Flag_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Infraction_Flag_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_infraction_flag_type" */
export enum Enum_Infraction_Flag_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumInfractionFlagTypePkey = 'ENUM_infraction_flag_type_pkey'
}

export enum Enum_Infraction_Flag_Type_Enum {
  ScamMessages = 'SCAM_MESSAGES'
}

/** Boolean expression to compare columns of type "ENUM_infraction_flag_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Infraction_Flag_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Infraction_Flag_Type_Enum>;
  _in?: Maybe<Array<Enum_Infraction_Flag_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Infraction_Flag_Type_Enum>;
  _nin?: Maybe<Array<Enum_Infraction_Flag_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_infraction_flag_type" */
export type Enum_Infraction_Flag_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Infraction_Flag_Type_Max_Fields = {
  __typename?: 'ENUM_infraction_flag_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Infraction_Flag_Type_Min_Fields = {
  __typename?: 'ENUM_infraction_flag_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_infraction_flag_type" */
export type Enum_Infraction_Flag_Type_Mutation_Response = {
  __typename?: 'ENUM_infraction_flag_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Infraction_Flag_Type>;
};

/** input type for inserting object relation for remote table "ENUM_infraction_flag_type" */
export type Enum_Infraction_Flag_Type_Obj_Rel_Insert_Input = {
  data: Enum_Infraction_Flag_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Infraction_Flag_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_infraction_flag_type" */
export type Enum_Infraction_Flag_Type_On_Conflict = {
  constraint: Enum_Infraction_Flag_Type_Constraint;
  update_columns?: Array<Enum_Infraction_Flag_Type_Update_Column>;
  where?: Maybe<Enum_Infraction_Flag_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_infraction_flag_type". */
export type Enum_Infraction_Flag_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_infraction_flag_type */
export type Enum_Infraction_Flag_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_infraction_flag_type" */
export enum Enum_Infraction_Flag_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_infraction_flag_type" */
export type Enum_Infraction_Flag_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_infraction_flag_type" */
export enum Enum_Infraction_Flag_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_interest_type" */
export type Enum_Interest_Type = {
  __typename?: 'ENUM_interest_type';
  /** An array relationship */
  Interests: Array<Interests>;
  /** An aggregate relationship */
  Interests_aggregate: Interests_Aggregate;
  VALUE: Scalars['String'];
  description: Scalars['String'];
};


/** columns and relationships of "ENUM_interest_type" */
export type Enum_Interest_TypeInterestsArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


/** columns and relationships of "ENUM_interest_type" */
export type Enum_Interest_TypeInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};

/** aggregated selection of "ENUM_interest_type" */
export type Enum_Interest_Type_Aggregate = {
  __typename?: 'ENUM_interest_type_aggregate';
  aggregate?: Maybe<Enum_Interest_Type_Aggregate_Fields>;
  nodes: Array<Enum_Interest_Type>;
};

/** aggregate fields of "ENUM_interest_type" */
export type Enum_Interest_Type_Aggregate_Fields = {
  __typename?: 'ENUM_interest_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Interest_Type_Max_Fields>;
  min?: Maybe<Enum_Interest_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_interest_type" */
export type Enum_Interest_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Interest_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_interest_type". All fields are combined with a logical 'AND'. */
export type Enum_Interest_Type_Bool_Exp = {
  Interests?: Maybe<Interests_Bool_Exp>;
  VALUE?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Enum_Interest_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Interest_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Interest_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_interest_type" */
export enum Enum_Interest_Type_Constraint {
  /** unique or primary key constraint on columns "VALUE" */
  EnumInterestTypePkey = 'ENUM_interest_type_pkey'
}

export enum Enum_Interest_Type_Enum {
  /** Countries */
  Countries = 'COUNTRIES',
  /** Discipline */
  Discipline = 'DISCIPLINE',
  /** Genres */
  Generes = 'GENERES',
  /** Identity Based */
  IdentityBased = 'IDENTITY_BASED',
  /** Miscellaneous */
  Miscellaneous = 'MISCELLANEOUS'
}

/** Boolean expression to compare columns of type "ENUM_interest_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Interest_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Interest_Type_Enum>;
  _in?: Maybe<Array<Enum_Interest_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Interest_Type_Enum>;
  _nin?: Maybe<Array<Enum_Interest_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_interest_type" */
export type Enum_Interest_Type_Insert_Input = {
  Interests?: Maybe<Interests_Arr_Rel_Insert_Input>;
  VALUE?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Interest_Type_Max_Fields = {
  __typename?: 'ENUM_interest_type_max_fields';
  VALUE?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Interest_Type_Min_Fields = {
  __typename?: 'ENUM_interest_type_min_fields';
  VALUE?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_interest_type" */
export type Enum_Interest_Type_Mutation_Response = {
  __typename?: 'ENUM_interest_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Interest_Type>;
};

/** input type for inserting object relation for remote table "ENUM_interest_type" */
export type Enum_Interest_Type_Obj_Rel_Insert_Input = {
  data: Enum_Interest_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Interest_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_interest_type" */
export type Enum_Interest_Type_On_Conflict = {
  constraint: Enum_Interest_Type_Constraint;
  update_columns?: Array<Enum_Interest_Type_Update_Column>;
  where?: Maybe<Enum_Interest_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_interest_type". */
export type Enum_Interest_Type_Order_By = {
  Interests_aggregate?: Maybe<Interests_Aggregate_Order_By>;
  VALUE?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_interest_type */
export type Enum_Interest_Type_Pk_Columns_Input = {
  VALUE: Scalars['String'];
};

/** select columns of table "ENUM_interest_type" */
export enum Enum_Interest_Type_Select_Column {
  /** column name */
  Value = 'VALUE',
  /** column name */
  Description = 'description'
}

/** input type for updating data in table "ENUM_interest_type" */
export type Enum_Interest_Type_Set_Input = {
  VALUE?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_interest_type" */
export enum Enum_Interest_Type_Update_Column {
  /** column name */
  Value = 'VALUE',
  /** column name */
  Description = 'description'
}

/** columns and relationships of "ENUM_language_preference" */
export type Enum_Language_Preference = {
  __typename?: 'ENUM_language_preference';
  /** An array relationship */
  Auths: Array<Auth>;
  /** An aggregate relationship */
  Auths_aggregate: Auth_Aggregate;
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};


/** columns and relationships of "ENUM_language_preference" */
export type Enum_Language_PreferenceAuthsArgs = {
  distinct_on?: Maybe<Array<Auth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Order_By>>;
  where?: Maybe<Auth_Bool_Exp>;
};


/** columns and relationships of "ENUM_language_preference" */
export type Enum_Language_PreferenceAuths_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Order_By>>;
  where?: Maybe<Auth_Bool_Exp>;
};

/** aggregated selection of "ENUM_language_preference" */
export type Enum_Language_Preference_Aggregate = {
  __typename?: 'ENUM_language_preference_aggregate';
  aggregate?: Maybe<Enum_Language_Preference_Aggregate_Fields>;
  nodes: Array<Enum_Language_Preference>;
};

/** aggregate fields of "ENUM_language_preference" */
export type Enum_Language_Preference_Aggregate_Fields = {
  __typename?: 'ENUM_language_preference_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Language_Preference_Max_Fields>;
  min?: Maybe<Enum_Language_Preference_Min_Fields>;
};


/** aggregate fields of "ENUM_language_preference" */
export type Enum_Language_Preference_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Language_Preference_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_language_preference". All fields are combined with a logical 'AND'. */
export type Enum_Language_Preference_Bool_Exp = {
  Auths?: Maybe<Auth_Bool_Exp>;
  _and?: Maybe<Array<Enum_Language_Preference_Bool_Exp>>;
  _not?: Maybe<Enum_Language_Preference_Bool_Exp>;
  _or?: Maybe<Array<Enum_Language_Preference_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_language_preference" */
export enum Enum_Language_Preference_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumLanguagePreferencePkey = 'ENUM_language_preference_pkey'
}

export enum Enum_Language_Preference_Enum {
  /** English */
  English = 'ENGLISH',
  /** Français */
  French = 'FRENCH',
  /** Português */
  Portuguese = 'PORTUGUESE',
  /** Română */
  Romanian = 'ROMANIAN',
  /** Español */
  Spanish = 'SPANISH'
}

/** Boolean expression to compare columns of type "ENUM_language_preference_enum". All fields are combined with logical 'AND'. */
export type Enum_Language_Preference_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Language_Preference_Enum>;
  _in?: Maybe<Array<Enum_Language_Preference_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Language_Preference_Enum>;
  _nin?: Maybe<Array<Enum_Language_Preference_Enum>>;
};

/** input type for inserting data into table "ENUM_language_preference" */
export type Enum_Language_Preference_Insert_Input = {
  Auths?: Maybe<Auth_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Language_Preference_Max_Fields = {
  __typename?: 'ENUM_language_preference_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Language_Preference_Min_Fields = {
  __typename?: 'ENUM_language_preference_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_language_preference" */
export type Enum_Language_Preference_Mutation_Response = {
  __typename?: 'ENUM_language_preference_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Language_Preference>;
};

/** on_conflict condition type for table "ENUM_language_preference" */
export type Enum_Language_Preference_On_Conflict = {
  constraint: Enum_Language_Preference_Constraint;
  update_columns?: Array<Enum_Language_Preference_Update_Column>;
  where?: Maybe<Enum_Language_Preference_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_language_preference". */
export type Enum_Language_Preference_Order_By = {
  Auths_aggregate?: Maybe<Auth_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_language_preference */
export type Enum_Language_Preference_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_language_preference" */
export enum Enum_Language_Preference_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_language_preference" */
export type Enum_Language_Preference_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_language_preference" */
export enum Enum_Language_Preference_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_my_website_artwork_info" */
export type Enum_My_Website_Artwork_Info = {
  __typename?: 'ENUM_my_website_artwork_info';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_my_website_artwork_info" */
export type Enum_My_Website_Artwork_Info_Aggregate = {
  __typename?: 'ENUM_my_website_artwork_info_aggregate';
  aggregate?: Maybe<Enum_My_Website_Artwork_Info_Aggregate_Fields>;
  nodes: Array<Enum_My_Website_Artwork_Info>;
};

/** aggregate fields of "ENUM_my_website_artwork_info" */
export type Enum_My_Website_Artwork_Info_Aggregate_Fields = {
  __typename?: 'ENUM_my_website_artwork_info_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_My_Website_Artwork_Info_Max_Fields>;
  min?: Maybe<Enum_My_Website_Artwork_Info_Min_Fields>;
};


/** aggregate fields of "ENUM_my_website_artwork_info" */
export type Enum_My_Website_Artwork_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_My_Website_Artwork_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_my_website_artwork_info". All fields are combined with a logical 'AND'. */
export type Enum_My_Website_Artwork_Info_Bool_Exp = {
  _and?: Maybe<Array<Enum_My_Website_Artwork_Info_Bool_Exp>>;
  _not?: Maybe<Enum_My_Website_Artwork_Info_Bool_Exp>;
  _or?: Maybe<Array<Enum_My_Website_Artwork_Info_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_my_website_artwork_info" */
export enum Enum_My_Website_Artwork_Info_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMyWebsiteArtworkInfoPkey = 'ENUM_my_website_artwork_info_pkey'
}

export enum Enum_My_Website_Artwork_Info_Enum {
  Overlay = 'OVERLAY',
  UnderImage = 'UNDER_IMAGE'
}

/** Boolean expression to compare columns of type "ENUM_my_website_artwork_info_enum". All fields are combined with logical 'AND'. */
export type Enum_My_Website_Artwork_Info_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_My_Website_Artwork_Info_Enum>;
  _in?: Maybe<Array<Enum_My_Website_Artwork_Info_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_My_Website_Artwork_Info_Enum>;
  _nin?: Maybe<Array<Enum_My_Website_Artwork_Info_Enum>>;
};

/** input type for inserting data into table "ENUM_my_website_artwork_info" */
export type Enum_My_Website_Artwork_Info_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_My_Website_Artwork_Info_Max_Fields = {
  __typename?: 'ENUM_my_website_artwork_info_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_My_Website_Artwork_Info_Min_Fields = {
  __typename?: 'ENUM_my_website_artwork_info_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_my_website_artwork_info" */
export type Enum_My_Website_Artwork_Info_Mutation_Response = {
  __typename?: 'ENUM_my_website_artwork_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_My_Website_Artwork_Info>;
};

/** on_conflict condition type for table "ENUM_my_website_artwork_info" */
export type Enum_My_Website_Artwork_Info_On_Conflict = {
  constraint: Enum_My_Website_Artwork_Info_Constraint;
  update_columns?: Array<Enum_My_Website_Artwork_Info_Update_Column>;
  where?: Maybe<Enum_My_Website_Artwork_Info_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_my_website_artwork_info". */
export type Enum_My_Website_Artwork_Info_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_my_website_artwork_info */
export type Enum_My_Website_Artwork_Info_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_my_website_artwork_info" */
export enum Enum_My_Website_Artwork_Info_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_my_website_artwork_info" */
export type Enum_My_Website_Artwork_Info_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_my_website_artwork_info" */
export enum Enum_My_Website_Artwork_Info_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_my_website_filters" */
export type Enum_My_Website_Filters = {
  __typename?: 'ENUM_my_website_filters';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_my_website_filters" */
export type Enum_My_Website_Filters_Aggregate = {
  __typename?: 'ENUM_my_website_filters_aggregate';
  aggregate?: Maybe<Enum_My_Website_Filters_Aggregate_Fields>;
  nodes: Array<Enum_My_Website_Filters>;
};

/** aggregate fields of "ENUM_my_website_filters" */
export type Enum_My_Website_Filters_Aggregate_Fields = {
  __typename?: 'ENUM_my_website_filters_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_My_Website_Filters_Max_Fields>;
  min?: Maybe<Enum_My_Website_Filters_Min_Fields>;
};


/** aggregate fields of "ENUM_my_website_filters" */
export type Enum_My_Website_Filters_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_My_Website_Filters_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_my_website_filters". All fields are combined with a logical 'AND'. */
export type Enum_My_Website_Filters_Bool_Exp = {
  _and?: Maybe<Array<Enum_My_Website_Filters_Bool_Exp>>;
  _not?: Maybe<Enum_My_Website_Filters_Bool_Exp>;
  _or?: Maybe<Array<Enum_My_Website_Filters_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_my_website_filters" */
export enum Enum_My_Website_Filters_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMyWebsiteFiltersPkey = 'ENUM_my_website_filters_pkey'
}

export enum Enum_My_Website_Filters_Enum {
  ShowSeries = 'SHOW_SERIES',
  ShowYears = 'SHOW_YEARS'
}

/** Boolean expression to compare columns of type "ENUM_my_website_filters_enum". All fields are combined with logical 'AND'. */
export type Enum_My_Website_Filters_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_My_Website_Filters_Enum>;
  _in?: Maybe<Array<Enum_My_Website_Filters_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_My_Website_Filters_Enum>;
  _nin?: Maybe<Array<Enum_My_Website_Filters_Enum>>;
};

/** input type for inserting data into table "ENUM_my_website_filters" */
export type Enum_My_Website_Filters_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_My_Website_Filters_Max_Fields = {
  __typename?: 'ENUM_my_website_filters_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_My_Website_Filters_Min_Fields = {
  __typename?: 'ENUM_my_website_filters_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_my_website_filters" */
export type Enum_My_Website_Filters_Mutation_Response = {
  __typename?: 'ENUM_my_website_filters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_My_Website_Filters>;
};

/** on_conflict condition type for table "ENUM_my_website_filters" */
export type Enum_My_Website_Filters_On_Conflict = {
  constraint: Enum_My_Website_Filters_Constraint;
  update_columns?: Array<Enum_My_Website_Filters_Update_Column>;
  where?: Maybe<Enum_My_Website_Filters_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_my_website_filters". */
export type Enum_My_Website_Filters_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_my_website_filters */
export type Enum_My_Website_Filters_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_my_website_filters" */
export enum Enum_My_Website_Filters_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_my_website_filters" */
export type Enum_My_Website_Filters_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_my_website_filters" */
export enum Enum_My_Website_Filters_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_my_website_layout" */
export type Enum_My_Website_Layout = {
  __typename?: 'ENUM_my_website_layout';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_my_website_layout" */
export type Enum_My_Website_Layout_Aggregate = {
  __typename?: 'ENUM_my_website_layout_aggregate';
  aggregate?: Maybe<Enum_My_Website_Layout_Aggregate_Fields>;
  nodes: Array<Enum_My_Website_Layout>;
};

/** aggregate fields of "ENUM_my_website_layout" */
export type Enum_My_Website_Layout_Aggregate_Fields = {
  __typename?: 'ENUM_my_website_layout_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_My_Website_Layout_Max_Fields>;
  min?: Maybe<Enum_My_Website_Layout_Min_Fields>;
};


/** aggregate fields of "ENUM_my_website_layout" */
export type Enum_My_Website_Layout_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_My_Website_Layout_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_my_website_layout". All fields are combined with a logical 'AND'. */
export type Enum_My_Website_Layout_Bool_Exp = {
  _and?: Maybe<Array<Enum_My_Website_Layout_Bool_Exp>>;
  _not?: Maybe<Enum_My_Website_Layout_Bool_Exp>;
  _or?: Maybe<Array<Enum_My_Website_Layout_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_my_website_layout" */
export enum Enum_My_Website_Layout_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMyWebsiteLayoutPkey = 'ENUM_my_website_layout_pkey'
}

export enum Enum_My_Website_Layout_Enum {
  Grid = 'GRID',
  Masonry = 'MASONRY'
}

/** Boolean expression to compare columns of type "ENUM_my_website_layout_enum". All fields are combined with logical 'AND'. */
export type Enum_My_Website_Layout_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_My_Website_Layout_Enum>;
  _in?: Maybe<Array<Enum_My_Website_Layout_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_My_Website_Layout_Enum>;
  _nin?: Maybe<Array<Enum_My_Website_Layout_Enum>>;
};

/** input type for inserting data into table "ENUM_my_website_layout" */
export type Enum_My_Website_Layout_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_My_Website_Layout_Max_Fields = {
  __typename?: 'ENUM_my_website_layout_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_My_Website_Layout_Min_Fields = {
  __typename?: 'ENUM_my_website_layout_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_my_website_layout" */
export type Enum_My_Website_Layout_Mutation_Response = {
  __typename?: 'ENUM_my_website_layout_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_My_Website_Layout>;
};

/** on_conflict condition type for table "ENUM_my_website_layout" */
export type Enum_My_Website_Layout_On_Conflict = {
  constraint: Enum_My_Website_Layout_Constraint;
  update_columns?: Array<Enum_My_Website_Layout_Update_Column>;
  where?: Maybe<Enum_My_Website_Layout_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_my_website_layout". */
export type Enum_My_Website_Layout_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_my_website_layout */
export type Enum_My_Website_Layout_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_my_website_layout" */
export enum Enum_My_Website_Layout_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_my_website_layout" */
export type Enum_My_Website_Layout_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_my_website_layout" */
export enum Enum_My_Website_Layout_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_my_website_navigation" */
export type Enum_My_Website_Navigation = {
  __typename?: 'ENUM_my_website_navigation';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_my_website_navigation" */
export type Enum_My_Website_Navigation_Aggregate = {
  __typename?: 'ENUM_my_website_navigation_aggregate';
  aggregate?: Maybe<Enum_My_Website_Navigation_Aggregate_Fields>;
  nodes: Array<Enum_My_Website_Navigation>;
};

/** aggregate fields of "ENUM_my_website_navigation" */
export type Enum_My_Website_Navigation_Aggregate_Fields = {
  __typename?: 'ENUM_my_website_navigation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_My_Website_Navigation_Max_Fields>;
  min?: Maybe<Enum_My_Website_Navigation_Min_Fields>;
};


/** aggregate fields of "ENUM_my_website_navigation" */
export type Enum_My_Website_Navigation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_My_Website_Navigation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_my_website_navigation". All fields are combined with a logical 'AND'. */
export type Enum_My_Website_Navigation_Bool_Exp = {
  _and?: Maybe<Array<Enum_My_Website_Navigation_Bool_Exp>>;
  _not?: Maybe<Enum_My_Website_Navigation_Bool_Exp>;
  _or?: Maybe<Array<Enum_My_Website_Navigation_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_my_website_navigation" */
export enum Enum_My_Website_Navigation_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMyWebsiteNavigationPkey = 'ENUM_my_website_navigation_pkey'
}

export enum Enum_My_Website_Navigation_Enum {
  FloatingNavigation = 'FLOATING_NAVIGATION',
  SideNavigation = 'SIDE_NAVIGATION',
  TopNavigation = 'TOP_NAVIGATION'
}

/** Boolean expression to compare columns of type "ENUM_my_website_navigation_enum". All fields are combined with logical 'AND'. */
export type Enum_My_Website_Navigation_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_My_Website_Navigation_Enum>;
  _in?: Maybe<Array<Enum_My_Website_Navigation_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_My_Website_Navigation_Enum>;
  _nin?: Maybe<Array<Enum_My_Website_Navigation_Enum>>;
};

/** input type for inserting data into table "ENUM_my_website_navigation" */
export type Enum_My_Website_Navigation_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_My_Website_Navigation_Max_Fields = {
  __typename?: 'ENUM_my_website_navigation_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_My_Website_Navigation_Min_Fields = {
  __typename?: 'ENUM_my_website_navigation_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_my_website_navigation" */
export type Enum_My_Website_Navigation_Mutation_Response = {
  __typename?: 'ENUM_my_website_navigation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_My_Website_Navigation>;
};

/** on_conflict condition type for table "ENUM_my_website_navigation" */
export type Enum_My_Website_Navigation_On_Conflict = {
  constraint: Enum_My_Website_Navigation_Constraint;
  update_columns?: Array<Enum_My_Website_Navigation_Update_Column>;
  where?: Maybe<Enum_My_Website_Navigation_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_my_website_navigation". */
export type Enum_My_Website_Navigation_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_my_website_navigation */
export type Enum_My_Website_Navigation_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_my_website_navigation" */
export enum Enum_My_Website_Navigation_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_my_website_navigation" */
export type Enum_My_Website_Navigation_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_my_website_navigation" */
export enum Enum_My_Website_Navigation_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_my_website_theme" */
export type Enum_My_Website_Theme = {
  __typename?: 'ENUM_my_website_theme';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_my_website_theme" */
export type Enum_My_Website_Theme_Aggregate = {
  __typename?: 'ENUM_my_website_theme_aggregate';
  aggregate?: Maybe<Enum_My_Website_Theme_Aggregate_Fields>;
  nodes: Array<Enum_My_Website_Theme>;
};

/** aggregate fields of "ENUM_my_website_theme" */
export type Enum_My_Website_Theme_Aggregate_Fields = {
  __typename?: 'ENUM_my_website_theme_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_My_Website_Theme_Max_Fields>;
  min?: Maybe<Enum_My_Website_Theme_Min_Fields>;
};


/** aggregate fields of "ENUM_my_website_theme" */
export type Enum_My_Website_Theme_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_My_Website_Theme_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_my_website_theme". All fields are combined with a logical 'AND'. */
export type Enum_My_Website_Theme_Bool_Exp = {
  _and?: Maybe<Array<Enum_My_Website_Theme_Bool_Exp>>;
  _not?: Maybe<Enum_My_Website_Theme_Bool_Exp>;
  _or?: Maybe<Array<Enum_My_Website_Theme_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_my_website_theme" */
export enum Enum_My_Website_Theme_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMyWebsiteThemePkey = 'ENUM_my_website_theme_pkey'
}

export enum Enum_My_Website_Theme_Enum {
  Dark = 'DARK',
  Light = 'LIGHT'
}

/** Boolean expression to compare columns of type "ENUM_my_website_theme_enum". All fields are combined with logical 'AND'. */
export type Enum_My_Website_Theme_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_My_Website_Theme_Enum>;
  _in?: Maybe<Array<Enum_My_Website_Theme_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_My_Website_Theme_Enum>;
  _nin?: Maybe<Array<Enum_My_Website_Theme_Enum>>;
};

/** input type for inserting data into table "ENUM_my_website_theme" */
export type Enum_My_Website_Theme_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_My_Website_Theme_Max_Fields = {
  __typename?: 'ENUM_my_website_theme_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_My_Website_Theme_Min_Fields = {
  __typename?: 'ENUM_my_website_theme_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_my_website_theme" */
export type Enum_My_Website_Theme_Mutation_Response = {
  __typename?: 'ENUM_my_website_theme_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_My_Website_Theme>;
};

/** on_conflict condition type for table "ENUM_my_website_theme" */
export type Enum_My_Website_Theme_On_Conflict = {
  constraint: Enum_My_Website_Theme_Constraint;
  update_columns?: Array<Enum_My_Website_Theme_Update_Column>;
  where?: Maybe<Enum_My_Website_Theme_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_my_website_theme". */
export type Enum_My_Website_Theme_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_my_website_theme */
export type Enum_My_Website_Theme_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_my_website_theme" */
export enum Enum_My_Website_Theme_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_my_website_theme" */
export type Enum_My_Website_Theme_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_my_website_theme" */
export enum Enum_My_Website_Theme_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_notification_channel" */
export type Enum_Notification_Channel = {
  __typename?: 'ENUM_notification_channel';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_notification_channel" */
export type Enum_Notification_Channel_Aggregate = {
  __typename?: 'ENUM_notification_channel_aggregate';
  aggregate?: Maybe<Enum_Notification_Channel_Aggregate_Fields>;
  nodes: Array<Enum_Notification_Channel>;
};

/** aggregate fields of "ENUM_notification_channel" */
export type Enum_Notification_Channel_Aggregate_Fields = {
  __typename?: 'ENUM_notification_channel_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Notification_Channel_Max_Fields>;
  min?: Maybe<Enum_Notification_Channel_Min_Fields>;
};


/** aggregate fields of "ENUM_notification_channel" */
export type Enum_Notification_Channel_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Notification_Channel_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_notification_channel". All fields are combined with a logical 'AND'. */
export type Enum_Notification_Channel_Bool_Exp = {
  _and?: Maybe<Array<Enum_Notification_Channel_Bool_Exp>>;
  _not?: Maybe<Enum_Notification_Channel_Bool_Exp>;
  _or?: Maybe<Array<Enum_Notification_Channel_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_notification_channel" */
export enum Enum_Notification_Channel_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumNotificationChannelPkey = 'ENUM_notification_channel_pkey'
}

export enum Enum_Notification_Channel_Enum {
  All = 'ALL',
  App = 'APP',
  Email = 'EMAIL',
  Push = 'PUSH'
}

/** Boolean expression to compare columns of type "ENUM_notification_channel_enum". All fields are combined with logical 'AND'. */
export type Enum_Notification_Channel_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Notification_Channel_Enum>;
  _in?: Maybe<Array<Enum_Notification_Channel_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Notification_Channel_Enum>;
  _nin?: Maybe<Array<Enum_Notification_Channel_Enum>>;
};

/** input type for inserting data into table "ENUM_notification_channel" */
export type Enum_Notification_Channel_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Notification_Channel_Max_Fields = {
  __typename?: 'ENUM_notification_channel_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Notification_Channel_Min_Fields = {
  __typename?: 'ENUM_notification_channel_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_notification_channel" */
export type Enum_Notification_Channel_Mutation_Response = {
  __typename?: 'ENUM_notification_channel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Notification_Channel>;
};

/** on_conflict condition type for table "ENUM_notification_channel" */
export type Enum_Notification_Channel_On_Conflict = {
  constraint: Enum_Notification_Channel_Constraint;
  update_columns?: Array<Enum_Notification_Channel_Update_Column>;
  where?: Maybe<Enum_Notification_Channel_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_notification_channel". */
export type Enum_Notification_Channel_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_notification_channel */
export type Enum_Notification_Channel_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_notification_channel" */
export enum Enum_Notification_Channel_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_notification_channel" */
export type Enum_Notification_Channel_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_notification_channel" */
export enum Enum_Notification_Channel_Update_Column {
  /** column name */
  Value = 'value'
}

/** Groups to which notifications belong to */
export type Enum_Notification_Group = {
  __typename?: 'ENUM_notification_group';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_notification_group" */
export type Enum_Notification_Group_Aggregate = {
  __typename?: 'ENUM_notification_group_aggregate';
  aggregate?: Maybe<Enum_Notification_Group_Aggregate_Fields>;
  nodes: Array<Enum_Notification_Group>;
};

/** aggregate fields of "ENUM_notification_group" */
export type Enum_Notification_Group_Aggregate_Fields = {
  __typename?: 'ENUM_notification_group_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Notification_Group_Max_Fields>;
  min?: Maybe<Enum_Notification_Group_Min_Fields>;
};


/** aggregate fields of "ENUM_notification_group" */
export type Enum_Notification_Group_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Notification_Group_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_notification_group". All fields are combined with a logical 'AND'. */
export type Enum_Notification_Group_Bool_Exp = {
  _and?: Maybe<Array<Enum_Notification_Group_Bool_Exp>>;
  _not?: Maybe<Enum_Notification_Group_Bool_Exp>;
  _or?: Maybe<Array<Enum_Notification_Group_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_notification_group" */
export enum Enum_Notification_Group_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumNotificationGroupPkey = 'ENUM_notification_group_pkey'
}

export enum Enum_Notification_Group_Enum {
  AdminEmails = 'ADMIN_EMAILS',
  ArtPublication = 'ART_PUBLICATION',
  ArRecognition = 'AR_RECOGNITION',
  BiweeklyAnalytics = 'BIWEEKLY_ANALYTICS',
  DailyUpdates = 'DAILY_UPDATES',
  DirectMessages = 'DIRECT_MESSAGES',
  GettingStarted = 'GETTING_STARTED',
  Miscellaneous = 'MISCELLANEOUS',
  Recognitions = 'RECOGNITIONS'
}

/** Boolean expression to compare columns of type "ENUM_notification_group_enum". All fields are combined with logical 'AND'. */
export type Enum_Notification_Group_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Notification_Group_Enum>;
  _in?: Maybe<Array<Enum_Notification_Group_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Notification_Group_Enum>;
  _nin?: Maybe<Array<Enum_Notification_Group_Enum>>;
};

/** input type for inserting data into table "ENUM_notification_group" */
export type Enum_Notification_Group_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Notification_Group_Max_Fields = {
  __typename?: 'ENUM_notification_group_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Notification_Group_Min_Fields = {
  __typename?: 'ENUM_notification_group_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_notification_group" */
export type Enum_Notification_Group_Mutation_Response = {
  __typename?: 'ENUM_notification_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Notification_Group>;
};

/** on_conflict condition type for table "ENUM_notification_group" */
export type Enum_Notification_Group_On_Conflict = {
  constraint: Enum_Notification_Group_Constraint;
  update_columns?: Array<Enum_Notification_Group_Update_Column>;
  where?: Maybe<Enum_Notification_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_notification_group". */
export type Enum_Notification_Group_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_notification_group */
export type Enum_Notification_Group_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_notification_group" */
export enum Enum_Notification_Group_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_notification_group" */
export type Enum_Notification_Group_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_notification_group" */
export enum Enum_Notification_Group_Update_Column {
  /** column name */
  Value = 'value'
}

/** Position types for Art prize */
export type Enum_Position_Type = {
  __typename?: 'ENUM_position_type';
  display_order?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_position_type" */
export type Enum_Position_Type_Aggregate = {
  __typename?: 'ENUM_position_type_aggregate';
  aggregate?: Maybe<Enum_Position_Type_Aggregate_Fields>;
  nodes: Array<Enum_Position_Type>;
};

/** aggregate fields of "ENUM_position_type" */
export type Enum_Position_Type_Aggregate_Fields = {
  __typename?: 'ENUM_position_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Position_Type_Max_Fields>;
  min?: Maybe<Enum_Position_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_position_type" */
export type Enum_Position_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Position_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_position_type". All fields are combined with a logical 'AND'. */
export type Enum_Position_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Position_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Position_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Position_Type_Bool_Exp>>;
  display_order?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_position_type" */
export enum Enum_Position_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPositionTypePkey = 'ENUM_position_type_pkey'
}

export enum Enum_Position_Type_Enum {
  /** 1 */
  BestInShow = 'BEST_IN_SHOW',
  /** 2 */
  FirstGrandPrize = 'FIRST_GRAND_PRIZE',
  /** 6 */
  HonorableMention = 'HONORABLE_MENTION',
  /** 5 */
  PopularVote = 'POPULAR_VOTE',
  /** 3 */
  SecondGrandPrize = 'SECOND_GRAND_PRIZE',
  /** 4 */
  ThirdGrandPrize = 'THIRD_GRAND_PRIZE'
}

/** Boolean expression to compare columns of type "ENUM_position_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Position_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Position_Type_Enum>;
  _in?: Maybe<Array<Enum_Position_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Position_Type_Enum>;
  _nin?: Maybe<Array<Enum_Position_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_position_type" */
export type Enum_Position_Type_Insert_Input = {
  display_order?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Position_Type_Max_Fields = {
  __typename?: 'ENUM_position_type_max_fields';
  display_order?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Position_Type_Min_Fields = {
  __typename?: 'ENUM_position_type_min_fields';
  display_order?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_position_type" */
export type Enum_Position_Type_Mutation_Response = {
  __typename?: 'ENUM_position_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Position_Type>;
};

/** input type for inserting object relation for remote table "ENUM_position_type" */
export type Enum_Position_Type_Obj_Rel_Insert_Input = {
  data: Enum_Position_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Position_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_position_type" */
export type Enum_Position_Type_On_Conflict = {
  constraint: Enum_Position_Type_Constraint;
  update_columns?: Array<Enum_Position_Type_Update_Column>;
  where?: Maybe<Enum_Position_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_position_type". */
export type Enum_Position_Type_Order_By = {
  display_order?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_position_type */
export type Enum_Position_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_position_type" */
export enum Enum_Position_Type_Select_Column {
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_position_type" */
export type Enum_Position_Type_Set_Input = {
  display_order?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_position_type" */
export enum Enum_Position_Type_Update_Column {
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_price_scope" */
export type Enum_Price_Scope = {
  __typename?: 'ENUM_price_scope';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_price_scope" */
export type Enum_Price_Scope_Aggregate = {
  __typename?: 'ENUM_price_scope_aggregate';
  aggregate?: Maybe<Enum_Price_Scope_Aggregate_Fields>;
  nodes: Array<Enum_Price_Scope>;
};

/** aggregate fields of "ENUM_price_scope" */
export type Enum_Price_Scope_Aggregate_Fields = {
  __typename?: 'ENUM_price_scope_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Price_Scope_Max_Fields>;
  min?: Maybe<Enum_Price_Scope_Min_Fields>;
};


/** aggregate fields of "ENUM_price_scope" */
export type Enum_Price_Scope_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Price_Scope_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_price_scope". All fields are combined with a logical 'AND'. */
export type Enum_Price_Scope_Bool_Exp = {
  _and?: Maybe<Array<Enum_Price_Scope_Bool_Exp>>;
  _not?: Maybe<Enum_Price_Scope_Bool_Exp>;
  _or?: Maybe<Array<Enum_Price_Scope_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_price_scope" */
export enum Enum_Price_Scope_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPriceScopePkey = 'ENUM_price_scope_pkey'
}

export enum Enum_Price_Scope_Enum {
  Showcase = 'SHOWCASE',
  Showroom = 'SHOWROOM'
}

/** Boolean expression to compare columns of type "ENUM_price_scope_enum". All fields are combined with logical 'AND'. */
export type Enum_Price_Scope_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Price_Scope_Enum>;
  _in?: Maybe<Array<Enum_Price_Scope_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Price_Scope_Enum>;
  _nin?: Maybe<Array<Enum_Price_Scope_Enum>>;
};

/** input type for inserting data into table "ENUM_price_scope" */
export type Enum_Price_Scope_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Price_Scope_Max_Fields = {
  __typename?: 'ENUM_price_scope_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Price_Scope_Min_Fields = {
  __typename?: 'ENUM_price_scope_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_price_scope" */
export type Enum_Price_Scope_Mutation_Response = {
  __typename?: 'ENUM_price_scope_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Price_Scope>;
};

/** input type for inserting object relation for remote table "ENUM_price_scope" */
export type Enum_Price_Scope_Obj_Rel_Insert_Input = {
  data: Enum_Price_Scope_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Price_Scope_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_price_scope" */
export type Enum_Price_Scope_On_Conflict = {
  constraint: Enum_Price_Scope_Constraint;
  update_columns?: Array<Enum_Price_Scope_Update_Column>;
  where?: Maybe<Enum_Price_Scope_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_price_scope". */
export type Enum_Price_Scope_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_price_scope */
export type Enum_Price_Scope_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_price_scope" */
export enum Enum_Price_Scope_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_price_scope" */
export type Enum_Price_Scope_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_price_scope" */
export enum Enum_Price_Scope_Update_Column {
  /** column name */
  Value = 'value'
}

/** Print Techniques catalog */
export type Enum_Print_Technique = {
  __typename?: 'ENUM_print_technique';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ENUM_print_technique" */
export type Enum_Print_Technique_Aggregate = {
  __typename?: 'ENUM_print_technique_aggregate';
  aggregate?: Maybe<Enum_Print_Technique_Aggregate_Fields>;
  nodes: Array<Enum_Print_Technique>;
};

/** aggregate fields of "ENUM_print_technique" */
export type Enum_Print_Technique_Aggregate_Fields = {
  __typename?: 'ENUM_print_technique_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Print_Technique_Max_Fields>;
  min?: Maybe<Enum_Print_Technique_Min_Fields>;
};


/** aggregate fields of "ENUM_print_technique" */
export type Enum_Print_Technique_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Print_Technique_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_print_technique". All fields are combined with a logical 'AND'. */
export type Enum_Print_Technique_Bool_Exp = {
  _and?: Maybe<Array<Enum_Print_Technique_Bool_Exp>>;
  _not?: Maybe<Enum_Print_Technique_Bool_Exp>;
  _or?: Maybe<Array<Enum_Print_Technique_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_print_technique" */
export enum Enum_Print_Technique_Constraint {
  /** unique or primary key constraint on columns "id" */
  EnumPrintTechniquePkey = 'ENUM_print_technique_pkey'
}

/** input type for inserting data into table "ENUM_print_technique" */
export type Enum_Print_Technique_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Print_Technique_Max_Fields = {
  __typename?: 'ENUM_print_technique_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Print_Technique_Min_Fields = {
  __typename?: 'ENUM_print_technique_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_print_technique" */
export type Enum_Print_Technique_Mutation_Response = {
  __typename?: 'ENUM_print_technique_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Print_Technique>;
};

/** on_conflict condition type for table "ENUM_print_technique" */
export type Enum_Print_Technique_On_Conflict = {
  constraint: Enum_Print_Technique_Constraint;
  update_columns?: Array<Enum_Print_Technique_Update_Column>;
  where?: Maybe<Enum_Print_Technique_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_print_technique". */
export type Enum_Print_Technique_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_print_technique */
export type Enum_Print_Technique_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ENUM_print_technique" */
export enum Enum_Print_Technique_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ENUM_print_technique" */
export type Enum_Print_Technique_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_print_technique" */
export enum Enum_Print_Technique_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** Enum for backgrounds type catalog */
export type Enum_Profile_Background_Type = {
  __typename?: 'ENUM_profile_background_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_profile_background_type" */
export type Enum_Profile_Background_Type_Aggregate = {
  __typename?: 'ENUM_profile_background_type_aggregate';
  aggregate?: Maybe<Enum_Profile_Background_Type_Aggregate_Fields>;
  nodes: Array<Enum_Profile_Background_Type>;
};

/** aggregate fields of "ENUM_profile_background_type" */
export type Enum_Profile_Background_Type_Aggregate_Fields = {
  __typename?: 'ENUM_profile_background_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Profile_Background_Type_Max_Fields>;
  min?: Maybe<Enum_Profile_Background_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_profile_background_type" */
export type Enum_Profile_Background_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Profile_Background_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_profile_background_type". All fields are combined with a logical 'AND'. */
export type Enum_Profile_Background_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Profile_Background_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Profile_Background_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Profile_Background_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_profile_background_type" */
export enum Enum_Profile_Background_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumProfileBackgroundTypePkey = 'ENUM_profile_background_type_pkey'
}

export enum Enum_Profile_Background_Type_Enum {
  ArtAppraiser = 'ART_APPRAISER',
  ArtCollector = 'ART_COLLECTOR',
  ArtCritic = 'ART_CRITIC',
  ArtDealer = 'ART_DEALER',
  ArtEnthusiast = 'ART_ENTHUSIAST',
  Auctioneer = 'AUCTIONEER',
  Consultant = 'CONSULTANT',
  Curator = 'CURATOR',
  Entrepreneur = 'ENTREPRENEUR',
  Gallerist = 'GALLERIST',
  Historian = 'HISTORIAN',
  MuseumOperator = 'MUSEUM_OPERATOR',
  Technologist = 'TECHNOLOGIST'
}

/** Boolean expression to compare columns of type "ENUM_profile_background_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Profile_Background_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Profile_Background_Type_Enum>;
  _in?: Maybe<Array<Enum_Profile_Background_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Profile_Background_Type_Enum>;
  _nin?: Maybe<Array<Enum_Profile_Background_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_profile_background_type" */
export type Enum_Profile_Background_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Profile_Background_Type_Max_Fields = {
  __typename?: 'ENUM_profile_background_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Profile_Background_Type_Min_Fields = {
  __typename?: 'ENUM_profile_background_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_profile_background_type" */
export type Enum_Profile_Background_Type_Mutation_Response = {
  __typename?: 'ENUM_profile_background_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Profile_Background_Type>;
};

/** on_conflict condition type for table "ENUM_profile_background_type" */
export type Enum_Profile_Background_Type_On_Conflict = {
  constraint: Enum_Profile_Background_Type_Constraint;
  update_columns?: Array<Enum_Profile_Background_Type_Update_Column>;
  where?: Maybe<Enum_Profile_Background_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_profile_background_type". */
export type Enum_Profile_Background_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_profile_background_type */
export type Enum_Profile_Background_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_profile_background_type" */
export enum Enum_Profile_Background_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_profile_background_type" */
export type Enum_Profile_Background_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_profile_background_type" */
export enum Enum_Profile_Background_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** REGISTERED - the email in registered, but the profile is not created, JOINED - the account is created, but not from this invite, ACCEPTED - the account is created from this invite */
export type Enum_Profile_Invitation_Status = {
  __typename?: 'ENUM_profile_invitation_status';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_profile_invitation_status" */
export type Enum_Profile_Invitation_Status_Aggregate = {
  __typename?: 'ENUM_profile_invitation_status_aggregate';
  aggregate?: Maybe<Enum_Profile_Invitation_Status_Aggregate_Fields>;
  nodes: Array<Enum_Profile_Invitation_Status>;
};

/** aggregate fields of "ENUM_profile_invitation_status" */
export type Enum_Profile_Invitation_Status_Aggregate_Fields = {
  __typename?: 'ENUM_profile_invitation_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Profile_Invitation_Status_Max_Fields>;
  min?: Maybe<Enum_Profile_Invitation_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_profile_invitation_status" */
export type Enum_Profile_Invitation_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Profile_Invitation_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_profile_invitation_status". All fields are combined with a logical 'AND'. */
export type Enum_Profile_Invitation_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Profile_Invitation_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Profile_Invitation_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Profile_Invitation_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_profile_invitation_status" */
export enum Enum_Profile_Invitation_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumProfileInvitationStatusPkey = 'ENUM_profile_invitation_status_pkey'
}

export enum Enum_Profile_Invitation_Status_Enum {
  Accepted = 'ACCEPTED',
  Joined = 'JOINED',
  Pending = 'PENDING',
  Registered = 'REGISTERED'
}

/** Boolean expression to compare columns of type "ENUM_profile_invitation_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Profile_Invitation_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Profile_Invitation_Status_Enum>;
  _in?: Maybe<Array<Enum_Profile_Invitation_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Profile_Invitation_Status_Enum>;
  _nin?: Maybe<Array<Enum_Profile_Invitation_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_profile_invitation_status" */
export type Enum_Profile_Invitation_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Profile_Invitation_Status_Max_Fields = {
  __typename?: 'ENUM_profile_invitation_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Profile_Invitation_Status_Min_Fields = {
  __typename?: 'ENUM_profile_invitation_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_profile_invitation_status" */
export type Enum_Profile_Invitation_Status_Mutation_Response = {
  __typename?: 'ENUM_profile_invitation_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Profile_Invitation_Status>;
};

/** on_conflict condition type for table "ENUM_profile_invitation_status" */
export type Enum_Profile_Invitation_Status_On_Conflict = {
  constraint: Enum_Profile_Invitation_Status_Constraint;
  update_columns?: Array<Enum_Profile_Invitation_Status_Update_Column>;
  where?: Maybe<Enum_Profile_Invitation_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_profile_invitation_status". */
export type Enum_Profile_Invitation_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_profile_invitation_status */
export type Enum_Profile_Invitation_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_profile_invitation_status" */
export enum Enum_Profile_Invitation_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_profile_invitation_status" */
export type Enum_Profile_Invitation_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_profile_invitation_status" */
export enum Enum_Profile_Invitation_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_profile_public_status" */
export type Enum_Profile_Public_Status = {
  __typename?: 'ENUM_profile_public_status';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_profile_public_status" */
export type Enum_Profile_Public_Status_Aggregate = {
  __typename?: 'ENUM_profile_public_status_aggregate';
  aggregate?: Maybe<Enum_Profile_Public_Status_Aggregate_Fields>;
  nodes: Array<Enum_Profile_Public_Status>;
};

/** aggregate fields of "ENUM_profile_public_status" */
export type Enum_Profile_Public_Status_Aggregate_Fields = {
  __typename?: 'ENUM_profile_public_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Profile_Public_Status_Max_Fields>;
  min?: Maybe<Enum_Profile_Public_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_profile_public_status" */
export type Enum_Profile_Public_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Profile_Public_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_profile_public_status". All fields are combined with a logical 'AND'. */
export type Enum_Profile_Public_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Profile_Public_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Profile_Public_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Profile_Public_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_profile_public_status" */
export enum Enum_Profile_Public_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumProfilePublicStatusPkey = 'ENUM_profile_public_status_pkey'
}

export enum Enum_Profile_Public_Status_Enum {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  PublicLegalName = 'PUBLIC_LEGAL_NAME',
  PublicUsername = 'PUBLIC_USERNAME'
}

/** Boolean expression to compare columns of type "ENUM_profile_public_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Profile_Public_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Profile_Public_Status_Enum>;
  _in?: Maybe<Array<Enum_Profile_Public_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Profile_Public_Status_Enum>;
  _nin?: Maybe<Array<Enum_Profile_Public_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_profile_public_status" */
export type Enum_Profile_Public_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Profile_Public_Status_Max_Fields = {
  __typename?: 'ENUM_profile_public_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Profile_Public_Status_Min_Fields = {
  __typename?: 'ENUM_profile_public_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_profile_public_status" */
export type Enum_Profile_Public_Status_Mutation_Response = {
  __typename?: 'ENUM_profile_public_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Profile_Public_Status>;
};

/** input type for inserting object relation for remote table "ENUM_profile_public_status" */
export type Enum_Profile_Public_Status_Obj_Rel_Insert_Input = {
  data: Enum_Profile_Public_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Profile_Public_Status_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_profile_public_status" */
export type Enum_Profile_Public_Status_On_Conflict = {
  constraint: Enum_Profile_Public_Status_Constraint;
  update_columns?: Array<Enum_Profile_Public_Status_Update_Column>;
  where?: Maybe<Enum_Profile_Public_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_profile_public_status". */
export type Enum_Profile_Public_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_profile_public_status */
export type Enum_Profile_Public_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_profile_public_status" */
export enum Enum_Profile_Public_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_profile_public_status" */
export type Enum_Profile_Public_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_profile_public_status" */
export enum Enum_Profile_Public_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_profile_region" */
export type Enum_Profile_Region = {
  __typename?: 'ENUM_profile_region';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_profile_region" */
export type Enum_Profile_Region_Aggregate = {
  __typename?: 'ENUM_profile_region_aggregate';
  aggregate?: Maybe<Enum_Profile_Region_Aggregate_Fields>;
  nodes: Array<Enum_Profile_Region>;
};

/** aggregate fields of "ENUM_profile_region" */
export type Enum_Profile_Region_Aggregate_Fields = {
  __typename?: 'ENUM_profile_region_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Profile_Region_Max_Fields>;
  min?: Maybe<Enum_Profile_Region_Min_Fields>;
};


/** aggregate fields of "ENUM_profile_region" */
export type Enum_Profile_Region_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Profile_Region_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_profile_region". All fields are combined with a logical 'AND'. */
export type Enum_Profile_Region_Bool_Exp = {
  _and?: Maybe<Array<Enum_Profile_Region_Bool_Exp>>;
  _not?: Maybe<Enum_Profile_Region_Bool_Exp>;
  _or?: Maybe<Array<Enum_Profile_Region_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_profile_region" */
export enum Enum_Profile_Region_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumProfileRegionPkey = 'ENUM_profile_region_pkey'
}

export enum Enum_Profile_Region_Enum {
  /** Africa */
  Africa = 'AFRICA',
  /** Asia */
  Asia = 'ASIA',
  /** Europe */
  Europe = 'EUROPE',
  /** North America */
  NorthAmerica = 'NORTH_AMERICA',
  /** Oceania */
  Oceania = 'OCEANIA',
  /** South America */
  SouthAmerica = 'SOUTH_AMERICA'
}

/** Boolean expression to compare columns of type "ENUM_profile_region_enum". All fields are combined with logical 'AND'. */
export type Enum_Profile_Region_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Profile_Region_Enum>;
  _in?: Maybe<Array<Enum_Profile_Region_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Profile_Region_Enum>;
  _nin?: Maybe<Array<Enum_Profile_Region_Enum>>;
};

/** input type for inserting data into table "ENUM_profile_region" */
export type Enum_Profile_Region_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Profile_Region_Max_Fields = {
  __typename?: 'ENUM_profile_region_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Profile_Region_Min_Fields = {
  __typename?: 'ENUM_profile_region_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_profile_region" */
export type Enum_Profile_Region_Mutation_Response = {
  __typename?: 'ENUM_profile_region_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Profile_Region>;
};

/** on_conflict condition type for table "ENUM_profile_region" */
export type Enum_Profile_Region_On_Conflict = {
  constraint: Enum_Profile_Region_Constraint;
  update_columns?: Array<Enum_Profile_Region_Update_Column>;
  where?: Maybe<Enum_Profile_Region_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_profile_region". */
export type Enum_Profile_Region_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_profile_region */
export type Enum_Profile_Region_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_profile_region" */
export enum Enum_Profile_Region_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_profile_region" */
export type Enum_Profile_Region_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_profile_region" */
export enum Enum_Profile_Region_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_profile_status" */
export type Enum_Profile_Status = {
  __typename?: 'ENUM_profile_status';
  /** An array relationship */
  Profiles: Array<Profile>;
  /** An aggregate relationship */
  Profiles_aggregate: Profile_Aggregate;
  desacription: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "ENUM_profile_status" */
export type Enum_Profile_StatusProfilesArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


/** columns and relationships of "ENUM_profile_status" */
export type Enum_Profile_StatusProfiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** aggregated selection of "ENUM_profile_status" */
export type Enum_Profile_Status_Aggregate = {
  __typename?: 'ENUM_profile_status_aggregate';
  aggregate?: Maybe<Enum_Profile_Status_Aggregate_Fields>;
  nodes: Array<Enum_Profile_Status>;
};

/** aggregate fields of "ENUM_profile_status" */
export type Enum_Profile_Status_Aggregate_Fields = {
  __typename?: 'ENUM_profile_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Profile_Status_Max_Fields>;
  min?: Maybe<Enum_Profile_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_profile_status" */
export type Enum_Profile_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Profile_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_profile_status". All fields are combined with a logical 'AND'. */
export type Enum_Profile_Status_Bool_Exp = {
  Profiles?: Maybe<Profile_Bool_Exp>;
  _and?: Maybe<Array<Enum_Profile_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Profile_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Profile_Status_Bool_Exp>>;
  desacription?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_profile_status" */
export enum Enum_Profile_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccountStatusEnumPkey = 'Account_status_enum_pkey'
}

export enum Enum_Profile_Status_Enum {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Deleted */
  Deleted = 'DELETED',
  /** deleted after a while */
  Incomplete = 'INCOMPLETE',
  /** Pending */
  Pending = 'PENDING',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Suspended */
  Suspended = 'SUSPENDED'
}

/** Boolean expression to compare columns of type "ENUM_profile_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Profile_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Profile_Status_Enum>;
  _in?: Maybe<Array<Enum_Profile_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Profile_Status_Enum>;
  _nin?: Maybe<Array<Enum_Profile_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_profile_status" */
export type Enum_Profile_Status_Insert_Input = {
  Profiles?: Maybe<Profile_Arr_Rel_Insert_Input>;
  desacription?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Profile_Status_Max_Fields = {
  __typename?: 'ENUM_profile_status_max_fields';
  desacription?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Profile_Status_Min_Fields = {
  __typename?: 'ENUM_profile_status_min_fields';
  desacription?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_profile_status" */
export type Enum_Profile_Status_Mutation_Response = {
  __typename?: 'ENUM_profile_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Profile_Status>;
};

/** input type for inserting object relation for remote table "ENUM_profile_status" */
export type Enum_Profile_Status_Obj_Rel_Insert_Input = {
  data: Enum_Profile_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Profile_Status_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_profile_status" */
export type Enum_Profile_Status_On_Conflict = {
  constraint: Enum_Profile_Status_Constraint;
  update_columns?: Array<Enum_Profile_Status_Update_Column>;
  where?: Maybe<Enum_Profile_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_profile_status". */
export type Enum_Profile_Status_Order_By = {
  Profiles_aggregate?: Maybe<Profile_Aggregate_Order_By>;
  desacription?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_profile_status */
export type Enum_Profile_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_profile_status" */
export enum Enum_Profile_Status_Select_Column {
  /** column name */
  Desacription = 'desacription',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_profile_status" */
export type Enum_Profile_Status_Set_Input = {
  desacription?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_profile_status" */
export enum Enum_Profile_Status_Update_Column {
  /** column name */
  Desacription = 'desacription',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_profile_theme" */
export type Enum_Profile_Theme = {
  __typename?: 'ENUM_profile_theme';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_profile_theme" */
export type Enum_Profile_Theme_Aggregate = {
  __typename?: 'ENUM_profile_theme_aggregate';
  aggregate?: Maybe<Enum_Profile_Theme_Aggregate_Fields>;
  nodes: Array<Enum_Profile_Theme>;
};

/** aggregate fields of "ENUM_profile_theme" */
export type Enum_Profile_Theme_Aggregate_Fields = {
  __typename?: 'ENUM_profile_theme_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Profile_Theme_Max_Fields>;
  min?: Maybe<Enum_Profile_Theme_Min_Fields>;
};


/** aggregate fields of "ENUM_profile_theme" */
export type Enum_Profile_Theme_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Profile_Theme_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_profile_theme". All fields are combined with a logical 'AND'. */
export type Enum_Profile_Theme_Bool_Exp = {
  _and?: Maybe<Array<Enum_Profile_Theme_Bool_Exp>>;
  _not?: Maybe<Enum_Profile_Theme_Bool_Exp>;
  _or?: Maybe<Array<Enum_Profile_Theme_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_profile_theme" */
export enum Enum_Profile_Theme_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumProfileThemePkey = 'ENUM_profile_theme_pkey'
}

export enum Enum_Profile_Theme_Enum {
  /** Dark theme */
  Dark = 'DARK',
  /** Light theme */
  Light = 'LIGHT'
}

/** Boolean expression to compare columns of type "ENUM_profile_theme_enum". All fields are combined with logical 'AND'. */
export type Enum_Profile_Theme_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Profile_Theme_Enum>;
  _in?: Maybe<Array<Enum_Profile_Theme_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Profile_Theme_Enum>;
  _nin?: Maybe<Array<Enum_Profile_Theme_Enum>>;
};

/** input type for inserting data into table "ENUM_profile_theme" */
export type Enum_Profile_Theme_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Profile_Theme_Max_Fields = {
  __typename?: 'ENUM_profile_theme_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Profile_Theme_Min_Fields = {
  __typename?: 'ENUM_profile_theme_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_profile_theme" */
export type Enum_Profile_Theme_Mutation_Response = {
  __typename?: 'ENUM_profile_theme_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Profile_Theme>;
};

/** on_conflict condition type for table "ENUM_profile_theme" */
export type Enum_Profile_Theme_On_Conflict = {
  constraint: Enum_Profile_Theme_Constraint;
  update_columns?: Array<Enum_Profile_Theme_Update_Column>;
  where?: Maybe<Enum_Profile_Theme_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_profile_theme". */
export type Enum_Profile_Theme_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_profile_theme */
export type Enum_Profile_Theme_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_profile_theme" */
export enum Enum_Profile_Theme_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_profile_theme" */
export type Enum_Profile_Theme_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_profile_theme" */
export enum Enum_Profile_Theme_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_profile_type" */
export type Enum_Profile_Type = {
  __typename?: 'ENUM_profile_type';
  VALUE: Scalars['String'];
};

/** aggregated selection of "ENUM_profile_type" */
export type Enum_Profile_Type_Aggregate = {
  __typename?: 'ENUM_profile_type_aggregate';
  aggregate?: Maybe<Enum_Profile_Type_Aggregate_Fields>;
  nodes: Array<Enum_Profile_Type>;
};

/** aggregate fields of "ENUM_profile_type" */
export type Enum_Profile_Type_Aggregate_Fields = {
  __typename?: 'ENUM_profile_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Profile_Type_Max_Fields>;
  min?: Maybe<Enum_Profile_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_profile_type" */
export type Enum_Profile_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Profile_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_profile_type". All fields are combined with a logical 'AND'. */
export type Enum_Profile_Type_Bool_Exp = {
  VALUE?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Enum_Profile_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Profile_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Profile_Type_Bool_Exp>>;
};

/** unique or primary key constraints on table "ENUM_profile_type" */
export enum Enum_Profile_Type_Constraint {
  /** unique or primary key constraint on columns "VALUE" */
  EnumProfileTypePkey = 'ENUM_profile_type_pkey'
}

export enum Enum_Profile_Type_Enum {
  Artist = 'ARTIST',
  Collector = 'COLLECTOR',
  Gallery = 'GALLERY',
  PseudoCollector = 'PSEUDO_COLLECTOR',
  SimpleUser = 'SIMPLE_USER'
}

/** Boolean expression to compare columns of type "ENUM_profile_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Profile_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Profile_Type_Enum>;
  _in?: Maybe<Array<Enum_Profile_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Profile_Type_Enum>;
  _nin?: Maybe<Array<Enum_Profile_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_profile_type" */
export type Enum_Profile_Type_Insert_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Profile_Type_Max_Fields = {
  __typename?: 'ENUM_profile_type_max_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Profile_Type_Min_Fields = {
  __typename?: 'ENUM_profile_type_min_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_profile_type" */
export type Enum_Profile_Type_Mutation_Response = {
  __typename?: 'ENUM_profile_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Profile_Type>;
};

/** on_conflict condition type for table "ENUM_profile_type" */
export type Enum_Profile_Type_On_Conflict = {
  constraint: Enum_Profile_Type_Constraint;
  update_columns?: Array<Enum_Profile_Type_Update_Column>;
  where?: Maybe<Enum_Profile_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_profile_type". */
export type Enum_Profile_Type_Order_By = {
  VALUE?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_profile_type */
export type Enum_Profile_Type_Pk_Columns_Input = {
  VALUE: Scalars['String'];
};

/** select columns of table "ENUM_profile_type" */
export enum Enum_Profile_Type_Select_Column {
  /** column name */
  Value = 'VALUE'
}

/** input type for updating data in table "ENUM_profile_type" */
export type Enum_Profile_Type_Set_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_profile_type" */
export enum Enum_Profile_Type_Update_Column {
  /** column name */
  Value = 'VALUE'
}

/** columns and relationships of "ENUM_pseudo_user_action" */
export type Enum_Pseudo_User_Action = {
  __typename?: 'ENUM_pseudo_user_action';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_pseudo_user_action" */
export type Enum_Pseudo_User_Action_Aggregate = {
  __typename?: 'ENUM_pseudo_user_action_aggregate';
  aggregate?: Maybe<Enum_Pseudo_User_Action_Aggregate_Fields>;
  nodes: Array<Enum_Pseudo_User_Action>;
};

/** aggregate fields of "ENUM_pseudo_user_action" */
export type Enum_Pseudo_User_Action_Aggregate_Fields = {
  __typename?: 'ENUM_pseudo_user_action_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Pseudo_User_Action_Max_Fields>;
  min?: Maybe<Enum_Pseudo_User_Action_Min_Fields>;
};


/** aggregate fields of "ENUM_pseudo_user_action" */
export type Enum_Pseudo_User_Action_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Pseudo_User_Action_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_pseudo_user_action". All fields are combined with a logical 'AND'. */
export type Enum_Pseudo_User_Action_Bool_Exp = {
  _and?: Maybe<Array<Enum_Pseudo_User_Action_Bool_Exp>>;
  _not?: Maybe<Enum_Pseudo_User_Action_Bool_Exp>;
  _or?: Maybe<Array<Enum_Pseudo_User_Action_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_pseudo_user_action" */
export enum Enum_Pseudo_User_Action_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPseudoUserActionPkey = 'ENUM_pseudo_user_action_pkey'
}

export enum Enum_Pseudo_User_Action_Enum {
  AddToCart = 'ADD_TO_CART',
  Contact = 'CONTACT',
  Favorite = 'FAVORITE',
  Inquire = 'INQUIRE',
  Message = 'MESSAGE',
  Recognize = 'RECOGNIZE'
}

/** Boolean expression to compare columns of type "ENUM_pseudo_user_action_enum". All fields are combined with logical 'AND'. */
export type Enum_Pseudo_User_Action_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Pseudo_User_Action_Enum>;
  _in?: Maybe<Array<Enum_Pseudo_User_Action_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Pseudo_User_Action_Enum>;
  _nin?: Maybe<Array<Enum_Pseudo_User_Action_Enum>>;
};

/** input type for inserting data into table "ENUM_pseudo_user_action" */
export type Enum_Pseudo_User_Action_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Pseudo_User_Action_Max_Fields = {
  __typename?: 'ENUM_pseudo_user_action_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Pseudo_User_Action_Min_Fields = {
  __typename?: 'ENUM_pseudo_user_action_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_pseudo_user_action" */
export type Enum_Pseudo_User_Action_Mutation_Response = {
  __typename?: 'ENUM_pseudo_user_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Pseudo_User_Action>;
};

/** on_conflict condition type for table "ENUM_pseudo_user_action" */
export type Enum_Pseudo_User_Action_On_Conflict = {
  constraint: Enum_Pseudo_User_Action_Constraint;
  update_columns?: Array<Enum_Pseudo_User_Action_Update_Column>;
  where?: Maybe<Enum_Pseudo_User_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_pseudo_user_action". */
export type Enum_Pseudo_User_Action_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_pseudo_user_action */
export type Enum_Pseudo_User_Action_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_pseudo_user_action" */
export enum Enum_Pseudo_User_Action_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_pseudo_user_action" */
export type Enum_Pseudo_User_Action_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_pseudo_user_action" */
export enum Enum_Pseudo_User_Action_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_push_notification_status" */
export type Enum_Push_Notification_Status = {
  __typename?: 'ENUM_push_notification_status';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_push_notification_status" */
export type Enum_Push_Notification_Status_Aggregate = {
  __typename?: 'ENUM_push_notification_status_aggregate';
  aggregate?: Maybe<Enum_Push_Notification_Status_Aggregate_Fields>;
  nodes: Array<Enum_Push_Notification_Status>;
};

/** aggregate fields of "ENUM_push_notification_status" */
export type Enum_Push_Notification_Status_Aggregate_Fields = {
  __typename?: 'ENUM_push_notification_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Push_Notification_Status_Max_Fields>;
  min?: Maybe<Enum_Push_Notification_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_push_notification_status" */
export type Enum_Push_Notification_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Push_Notification_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_push_notification_status". All fields are combined with a logical 'AND'. */
export type Enum_Push_Notification_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Push_Notification_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Push_Notification_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Push_Notification_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_push_notification_status" */
export enum Enum_Push_Notification_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPushNotificationStatusPkey = 'ENUM_push_notification_status_pkey'
}

export enum Enum_Push_Notification_Status_Enum {
  /** General error */
  Error = 'ERROR',
  /** BE processed the push notification and sent it to recepients. */
  Sent = 'SENT',
  /** Push notification inserted in Push_notificatons table, waiting for BE to process it */
  Waiting = 'WAITING'
}

/** Boolean expression to compare columns of type "ENUM_push_notification_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Push_Notification_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Push_Notification_Status_Enum>;
  _in?: Maybe<Array<Enum_Push_Notification_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Push_Notification_Status_Enum>;
  _nin?: Maybe<Array<Enum_Push_Notification_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_push_notification_status" */
export type Enum_Push_Notification_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Push_Notification_Status_Max_Fields = {
  __typename?: 'ENUM_push_notification_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Push_Notification_Status_Min_Fields = {
  __typename?: 'ENUM_push_notification_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_push_notification_status" */
export type Enum_Push_Notification_Status_Mutation_Response = {
  __typename?: 'ENUM_push_notification_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Push_Notification_Status>;
};

/** on_conflict condition type for table "ENUM_push_notification_status" */
export type Enum_Push_Notification_Status_On_Conflict = {
  constraint: Enum_Push_Notification_Status_Constraint;
  update_columns?: Array<Enum_Push_Notification_Status_Update_Column>;
  where?: Maybe<Enum_Push_Notification_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_push_notification_status". */
export type Enum_Push_Notification_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_push_notification_status */
export type Enum_Push_Notification_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_push_notification_status" */
export enum Enum_Push_Notification_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_push_notification_status" */
export type Enum_Push_Notification_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_push_notification_status" */
export enum Enum_Push_Notification_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_push_notification_type" */
export type Enum_Push_Notification_Type = {
  __typename?: 'ENUM_push_notification_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_push_notification_type" */
export type Enum_Push_Notification_Type_Aggregate = {
  __typename?: 'ENUM_push_notification_type_aggregate';
  aggregate?: Maybe<Enum_Push_Notification_Type_Aggregate_Fields>;
  nodes: Array<Enum_Push_Notification_Type>;
};

/** aggregate fields of "ENUM_push_notification_type" */
export type Enum_Push_Notification_Type_Aggregate_Fields = {
  __typename?: 'ENUM_push_notification_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Push_Notification_Type_Max_Fields>;
  min?: Maybe<Enum_Push_Notification_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_push_notification_type" */
export type Enum_Push_Notification_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Push_Notification_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_push_notification_type". All fields are combined with a logical 'AND'. */
export type Enum_Push_Notification_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Push_Notification_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Push_Notification_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Push_Notification_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_push_notification_type" */
export enum Enum_Push_Notification_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPushNotificationTypePkey = 'ENUM_push_notification_type_pkey'
}

export enum Enum_Push_Notification_Type_Enum {
  /** Used for manually sending app updates notifications */
  AppUpdates = 'APP_UPDATES',
  /** Used for manually sending custom push notifications */
  Custom = 'CUSTOM',
  /** Used to send push notification when direct messages are sent between users */
  DirectMessages = 'DIRECT_MESSAGES',
  /** Used for sending daily push notifications batches for received recognitions */
  IncomingRecognitions = 'INCOMING_RECOGNITIONS',
  /** Used for sending daily push notifications batches for new published artwork fron recognised artists */
  NewArtworkFromRecognisedArtists = 'NEW_ARTWORK_FROM_RECOGNISED_ARTISTS',
  /** Used for sending push notifications for every purchase inquiries  */
  PurchaseInquiries = 'PURCHASE_INQUIRIES'
}

/** Boolean expression to compare columns of type "ENUM_push_notification_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Push_Notification_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Push_Notification_Type_Enum>;
  _in?: Maybe<Array<Enum_Push_Notification_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Push_Notification_Type_Enum>;
  _nin?: Maybe<Array<Enum_Push_Notification_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_push_notification_type" */
export type Enum_Push_Notification_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Push_Notification_Type_Max_Fields = {
  __typename?: 'ENUM_push_notification_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Push_Notification_Type_Min_Fields = {
  __typename?: 'ENUM_push_notification_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_push_notification_type" */
export type Enum_Push_Notification_Type_Mutation_Response = {
  __typename?: 'ENUM_push_notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Push_Notification_Type>;
};

/** on_conflict condition type for table "ENUM_push_notification_type" */
export type Enum_Push_Notification_Type_On_Conflict = {
  constraint: Enum_Push_Notification_Type_Constraint;
  update_columns?: Array<Enum_Push_Notification_Type_Update_Column>;
  where?: Maybe<Enum_Push_Notification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_push_notification_type". */
export type Enum_Push_Notification_Type_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_push_notification_type */
export type Enum_Push_Notification_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_push_notification_type" */
export enum Enum_Push_Notification_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_push_notification_type" */
export type Enum_Push_Notification_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_push_notification_type" */
export enum Enum_Push_Notification_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_questionnaire_type" */
export type Enum_Questionnaire_Type = {
  __typename?: 'ENUM_questionnaire_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_questionnaire_type" */
export type Enum_Questionnaire_Type_Aggregate = {
  __typename?: 'ENUM_questionnaire_type_aggregate';
  aggregate?: Maybe<Enum_Questionnaire_Type_Aggregate_Fields>;
  nodes: Array<Enum_Questionnaire_Type>;
};

/** aggregate fields of "ENUM_questionnaire_type" */
export type Enum_Questionnaire_Type_Aggregate_Fields = {
  __typename?: 'ENUM_questionnaire_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Questionnaire_Type_Max_Fields>;
  min?: Maybe<Enum_Questionnaire_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_questionnaire_type" */
export type Enum_Questionnaire_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Questionnaire_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_questionnaire_type". All fields are combined with a logical 'AND'. */
export type Enum_Questionnaire_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Questionnaire_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Questionnaire_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Questionnaire_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_questionnaire_type" */
export enum Enum_Questionnaire_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumQuestionnaireTypePkey = 'ENUM_questionnaire_type_pkey'
}

export enum Enum_Questionnaire_Type_Enum {
  ProfileStatement = 'PROFILE_STATEMENT'
}

/** Boolean expression to compare columns of type "ENUM_questionnaire_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Questionnaire_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Questionnaire_Type_Enum>;
  _in?: Maybe<Array<Enum_Questionnaire_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Questionnaire_Type_Enum>;
  _nin?: Maybe<Array<Enum_Questionnaire_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_questionnaire_type" */
export type Enum_Questionnaire_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Questionnaire_Type_Max_Fields = {
  __typename?: 'ENUM_questionnaire_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Questionnaire_Type_Min_Fields = {
  __typename?: 'ENUM_questionnaire_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_questionnaire_type" */
export type Enum_Questionnaire_Type_Mutation_Response = {
  __typename?: 'ENUM_questionnaire_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Questionnaire_Type>;
};

/** on_conflict condition type for table "ENUM_questionnaire_type" */
export type Enum_Questionnaire_Type_On_Conflict = {
  constraint: Enum_Questionnaire_Type_Constraint;
  update_columns?: Array<Enum_Questionnaire_Type_Update_Column>;
  where?: Maybe<Enum_Questionnaire_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_questionnaire_type". */
export type Enum_Questionnaire_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_questionnaire_type */
export type Enum_Questionnaire_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_questionnaire_type" */
export enum Enum_Questionnaire_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_questionnaire_type" */
export type Enum_Questionnaire_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_questionnaire_type" */
export enum Enum_Questionnaire_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** ENUM for the possible states a render request can be in  */
export type Enum_Render_Request_Status = {
  __typename?: 'ENUM_render_request_status';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_render_request_status" */
export type Enum_Render_Request_Status_Aggregate = {
  __typename?: 'ENUM_render_request_status_aggregate';
  aggregate?: Maybe<Enum_Render_Request_Status_Aggregate_Fields>;
  nodes: Array<Enum_Render_Request_Status>;
};

/** aggregate fields of "ENUM_render_request_status" */
export type Enum_Render_Request_Status_Aggregate_Fields = {
  __typename?: 'ENUM_render_request_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Render_Request_Status_Max_Fields>;
  min?: Maybe<Enum_Render_Request_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_render_request_status" */
export type Enum_Render_Request_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Render_Request_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_render_request_status". All fields are combined with a logical 'AND'. */
export type Enum_Render_Request_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Render_Request_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Render_Request_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Render_Request_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_render_request_status" */
export enum Enum_Render_Request_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumRenderRequestStatusPkey = 'ENUM_render_request_status_pkey'
}

export enum Enum_Render_Request_Status_Enum {
  /** The render failed. */
  Failed = 'FAILED',
  /** The render was successful. */
  Finished = 'FINISHED',
  /** Managed to render some of the requested outputs, but not all. */
  Partial = 'PARTIAL',
  /** Default status upon insert. The insert triggers an event and stays pending until the event is finalized. */
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "ENUM_render_request_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Render_Request_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Render_Request_Status_Enum>;
  _in?: Maybe<Array<Enum_Render_Request_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Render_Request_Status_Enum>;
  _nin?: Maybe<Array<Enum_Render_Request_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_render_request_status" */
export type Enum_Render_Request_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Render_Request_Status_Max_Fields = {
  __typename?: 'ENUM_render_request_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Render_Request_Status_Min_Fields = {
  __typename?: 'ENUM_render_request_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_render_request_status" */
export type Enum_Render_Request_Status_Mutation_Response = {
  __typename?: 'ENUM_render_request_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Render_Request_Status>;
};

/** on_conflict condition type for table "ENUM_render_request_status" */
export type Enum_Render_Request_Status_On_Conflict = {
  constraint: Enum_Render_Request_Status_Constraint;
  update_columns?: Array<Enum_Render_Request_Status_Update_Column>;
  where?: Maybe<Enum_Render_Request_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_render_request_status". */
export type Enum_Render_Request_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_render_request_status */
export type Enum_Render_Request_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_render_request_status" */
export enum Enum_Render_Request_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_render_request_status" */
export type Enum_Render_Request_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_render_request_status" */
export enum Enum_Render_Request_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** ENUM for the possible input types on Render Requests (puppeteer can  visit urls or take local templates) */
export type Enum_Render_Request_Template_Type = {
  __typename?: 'ENUM_render_request_template_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_render_request_template_type" */
export type Enum_Render_Request_Template_Type_Aggregate = {
  __typename?: 'ENUM_render_request_template_type_aggregate';
  aggregate?: Maybe<Enum_Render_Request_Template_Type_Aggregate_Fields>;
  nodes: Array<Enum_Render_Request_Template_Type>;
};

/** aggregate fields of "ENUM_render_request_template_type" */
export type Enum_Render_Request_Template_Type_Aggregate_Fields = {
  __typename?: 'ENUM_render_request_template_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Render_Request_Template_Type_Max_Fields>;
  min?: Maybe<Enum_Render_Request_Template_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_render_request_template_type" */
export type Enum_Render_Request_Template_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Render_Request_Template_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_render_request_template_type". All fields are combined with a logical 'AND'. */
export type Enum_Render_Request_Template_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Render_Request_Template_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Render_Request_Template_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Render_Request_Template_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_render_request_template_type" */
export enum Enum_Render_Request_Template_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumRenderRequestTemplateTypePkey = 'ENUM_render_request_template_type_pkey'
}

export enum Enum_Render_Request_Template_Type_Enum {
  /** Use handlebars to generate html. Load the html in puppeteer and retrieve the output. */
  Hbs = 'HBS',
  /** Use puppeteer to load an url. Retrieve the output - for future use. */
  Url = 'URL'
}

/** Boolean expression to compare columns of type "ENUM_render_request_template_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Render_Request_Template_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Render_Request_Template_Type_Enum>;
  _in?: Maybe<Array<Enum_Render_Request_Template_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Render_Request_Template_Type_Enum>;
  _nin?: Maybe<Array<Enum_Render_Request_Template_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_render_request_template_type" */
export type Enum_Render_Request_Template_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Render_Request_Template_Type_Max_Fields = {
  __typename?: 'ENUM_render_request_template_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Render_Request_Template_Type_Min_Fields = {
  __typename?: 'ENUM_render_request_template_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_render_request_template_type" */
export type Enum_Render_Request_Template_Type_Mutation_Response = {
  __typename?: 'ENUM_render_request_template_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Render_Request_Template_Type>;
};

/** on_conflict condition type for table "ENUM_render_request_template_type" */
export type Enum_Render_Request_Template_Type_On_Conflict = {
  constraint: Enum_Render_Request_Template_Type_Constraint;
  update_columns?: Array<Enum_Render_Request_Template_Type_Update_Column>;
  where?: Maybe<Enum_Render_Request_Template_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_render_request_template_type". */
export type Enum_Render_Request_Template_Type_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_render_request_template_type */
export type Enum_Render_Request_Template_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_render_request_template_type" */
export enum Enum_Render_Request_Template_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_render_request_template_type" */
export type Enum_Render_Request_Template_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_render_request_template_type" */
export enum Enum_Render_Request_Template_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_restricted_handle_names" */
export type Enum_Restricted_Handle_Names = {
  __typename?: 'ENUM_restricted_handle_names';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_restricted_handle_names" */
export type Enum_Restricted_Handle_Names_Aggregate = {
  __typename?: 'ENUM_restricted_handle_names_aggregate';
  aggregate?: Maybe<Enum_Restricted_Handle_Names_Aggregate_Fields>;
  nodes: Array<Enum_Restricted_Handle_Names>;
};

/** aggregate fields of "ENUM_restricted_handle_names" */
export type Enum_Restricted_Handle_Names_Aggregate_Fields = {
  __typename?: 'ENUM_restricted_handle_names_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Restricted_Handle_Names_Max_Fields>;
  min?: Maybe<Enum_Restricted_Handle_Names_Min_Fields>;
};


/** aggregate fields of "ENUM_restricted_handle_names" */
export type Enum_Restricted_Handle_Names_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Restricted_Handle_Names_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_restricted_handle_names". All fields are combined with a logical 'AND'. */
export type Enum_Restricted_Handle_Names_Bool_Exp = {
  _and?: Maybe<Array<Enum_Restricted_Handle_Names_Bool_Exp>>;
  _not?: Maybe<Enum_Restricted_Handle_Names_Bool_Exp>;
  _or?: Maybe<Array<Enum_Restricted_Handle_Names_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_restricted_handle_names" */
export enum Enum_Restricted_Handle_Names_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumRestrictedHandleNamesPkey = 'ENUM_restricted_handle_names_pkey'
}

/** input type for inserting data into table "ENUM_restricted_handle_names" */
export type Enum_Restricted_Handle_Names_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Restricted_Handle_Names_Max_Fields = {
  __typename?: 'ENUM_restricted_handle_names_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Restricted_Handle_Names_Min_Fields = {
  __typename?: 'ENUM_restricted_handle_names_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_restricted_handle_names" */
export type Enum_Restricted_Handle_Names_Mutation_Response = {
  __typename?: 'ENUM_restricted_handle_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Restricted_Handle_Names>;
};

/** on_conflict condition type for table "ENUM_restricted_handle_names" */
export type Enum_Restricted_Handle_Names_On_Conflict = {
  constraint: Enum_Restricted_Handle_Names_Constraint;
  update_columns?: Array<Enum_Restricted_Handle_Names_Update_Column>;
  where?: Maybe<Enum_Restricted_Handle_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_restricted_handle_names". */
export type Enum_Restricted_Handle_Names_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_restricted_handle_names */
export type Enum_Restricted_Handle_Names_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_restricted_handle_names" */
export enum Enum_Restricted_Handle_Names_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_restricted_handle_names" */
export type Enum_Restricted_Handle_Names_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_restricted_handle_names" */
export enum Enum_Restricted_Handle_Names_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Size catalog */
export type Enum_Size = {
  __typename?: 'ENUM_size';
  description: Scalars['String'];
  id: Scalars['String'];
};

/** aggregated selection of "ENUM_size" */
export type Enum_Size_Aggregate = {
  __typename?: 'ENUM_size_aggregate';
  aggregate?: Maybe<Enum_Size_Aggregate_Fields>;
  nodes: Array<Enum_Size>;
};

/** aggregate fields of "ENUM_size" */
export type Enum_Size_Aggregate_Fields = {
  __typename?: 'ENUM_size_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Size_Max_Fields>;
  min?: Maybe<Enum_Size_Min_Fields>;
};


/** aggregate fields of "ENUM_size" */
export type Enum_Size_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Size_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_size". All fields are combined with a logical 'AND'. */
export type Enum_Size_Bool_Exp = {
  _and?: Maybe<Array<Enum_Size_Bool_Exp>>;
  _not?: Maybe<Enum_Size_Bool_Exp>;
  _or?: Maybe<Array<Enum_Size_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_size" */
export enum Enum_Size_Constraint {
  /** unique or primary key constraint on columns "id" */
  EnumSizePkey = 'ENUM_size_pkey'
}

export enum Enum_Size_Enum {
  /** Large */
  L = 'L',
  /** Medium */
  M = 'M',
  /** Small */
  S = 'S',
  /** Extra Large */
  Xl = 'XL',
  /** Mini */
  Xs = 'XS'
}

/** Boolean expression to compare columns of type "ENUM_size_enum". All fields are combined with logical 'AND'. */
export type Enum_Size_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Size_Enum>;
  _in?: Maybe<Array<Enum_Size_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Size_Enum>;
  _nin?: Maybe<Array<Enum_Size_Enum>>;
};

/** input type for inserting data into table "ENUM_size" */
export type Enum_Size_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Size_Max_Fields = {
  __typename?: 'ENUM_size_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Size_Min_Fields = {
  __typename?: 'ENUM_size_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_size" */
export type Enum_Size_Mutation_Response = {
  __typename?: 'ENUM_size_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Size>;
};

/** input type for inserting object relation for remote table "ENUM_size" */
export type Enum_Size_Obj_Rel_Insert_Input = {
  data: Enum_Size_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Size_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_size" */
export type Enum_Size_On_Conflict = {
  constraint: Enum_Size_Constraint;
  update_columns?: Array<Enum_Size_Update_Column>;
  where?: Maybe<Enum_Size_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_size". */
export type Enum_Size_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_size */
export type Enum_Size_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ENUM_size" */
export enum Enum_Size_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "ENUM_size" */
export type Enum_Size_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_size" */
export enum Enum_Size_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id'
}

/** Enum table for Social Graph Followers states */
export type Enum_Social_Graph_Followers_State = {
  __typename?: 'ENUM_social_graph_followers_state';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_social_graph_followers_state" */
export type Enum_Social_Graph_Followers_State_Aggregate = {
  __typename?: 'ENUM_social_graph_followers_state_aggregate';
  aggregate?: Maybe<Enum_Social_Graph_Followers_State_Aggregate_Fields>;
  nodes: Array<Enum_Social_Graph_Followers_State>;
};

/** aggregate fields of "ENUM_social_graph_followers_state" */
export type Enum_Social_Graph_Followers_State_Aggregate_Fields = {
  __typename?: 'ENUM_social_graph_followers_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Social_Graph_Followers_State_Max_Fields>;
  min?: Maybe<Enum_Social_Graph_Followers_State_Min_Fields>;
};


/** aggregate fields of "ENUM_social_graph_followers_state" */
export type Enum_Social_Graph_Followers_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Social_Graph_Followers_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_social_graph_followers_state". All fields are combined with a logical 'AND'. */
export type Enum_Social_Graph_Followers_State_Bool_Exp = {
  _and?: Maybe<Array<Enum_Social_Graph_Followers_State_Bool_Exp>>;
  _not?: Maybe<Enum_Social_Graph_Followers_State_Bool_Exp>;
  _or?: Maybe<Array<Enum_Social_Graph_Followers_State_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_social_graph_followers_state" */
export enum Enum_Social_Graph_Followers_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSocialGraphFollowersStatePkey = 'ENUM_social_graph_followers_state_pkey'
}

export enum Enum_Social_Graph_Followers_State_Enum {
  /** Social relation activated */
  Active = 'ACTIVE',
  /** Social relation declined */
  Declined = 'DECLINED',
  /** Social relation deleted */
  Deleted = 'DELETED'
}

/** Boolean expression to compare columns of type "ENUM_social_graph_followers_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Social_Graph_Followers_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Social_Graph_Followers_State_Enum>;
  _in?: Maybe<Array<Enum_Social_Graph_Followers_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Social_Graph_Followers_State_Enum>;
  _nin?: Maybe<Array<Enum_Social_Graph_Followers_State_Enum>>;
};

/** input type for inserting data into table "ENUM_social_graph_followers_state" */
export type Enum_Social_Graph_Followers_State_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Social_Graph_Followers_State_Max_Fields = {
  __typename?: 'ENUM_social_graph_followers_state_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Social_Graph_Followers_State_Min_Fields = {
  __typename?: 'ENUM_social_graph_followers_state_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_social_graph_followers_state" */
export type Enum_Social_Graph_Followers_State_Mutation_Response = {
  __typename?: 'ENUM_social_graph_followers_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Social_Graph_Followers_State>;
};

/** on_conflict condition type for table "ENUM_social_graph_followers_state" */
export type Enum_Social_Graph_Followers_State_On_Conflict = {
  constraint: Enum_Social_Graph_Followers_State_Constraint;
  update_columns?: Array<Enum_Social_Graph_Followers_State_Update_Column>;
  where?: Maybe<Enum_Social_Graph_Followers_State_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_social_graph_followers_state". */
export type Enum_Social_Graph_Followers_State_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_social_graph_followers_state */
export type Enum_Social_Graph_Followers_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_social_graph_followers_state" */
export enum Enum_Social_Graph_Followers_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_social_graph_followers_state" */
export type Enum_Social_Graph_Followers_State_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_social_graph_followers_state" */
export enum Enum_Social_Graph_Followers_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_social_link_status" */
export type Enum_Social_Link_Status = {
  __typename?: 'ENUM_social_link_status';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_social_link_status" */
export type Enum_Social_Link_Status_Aggregate = {
  __typename?: 'ENUM_social_link_status_aggregate';
  aggregate?: Maybe<Enum_Social_Link_Status_Aggregate_Fields>;
  nodes: Array<Enum_Social_Link_Status>;
};

/** aggregate fields of "ENUM_social_link_status" */
export type Enum_Social_Link_Status_Aggregate_Fields = {
  __typename?: 'ENUM_social_link_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Social_Link_Status_Max_Fields>;
  min?: Maybe<Enum_Social_Link_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_social_link_status" */
export type Enum_Social_Link_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Social_Link_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_social_link_status". All fields are combined with a logical 'AND'. */
export type Enum_Social_Link_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Social_Link_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Social_Link_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Social_Link_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_social_link_status" */
export enum Enum_Social_Link_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSocialLinkStatusPkey = 'ENUM_social_link_status_pkey'
}

export enum Enum_Social_Link_Status_Enum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

/** Boolean expression to compare columns of type "ENUM_social_link_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Social_Link_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Social_Link_Status_Enum>;
  _in?: Maybe<Array<Enum_Social_Link_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Social_Link_Status_Enum>;
  _nin?: Maybe<Array<Enum_Social_Link_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_social_link_status" */
export type Enum_Social_Link_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Social_Link_Status_Max_Fields = {
  __typename?: 'ENUM_social_link_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Social_Link_Status_Min_Fields = {
  __typename?: 'ENUM_social_link_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_social_link_status" */
export type Enum_Social_Link_Status_Mutation_Response = {
  __typename?: 'ENUM_social_link_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Social_Link_Status>;
};

/** input type for inserting object relation for remote table "ENUM_social_link_status" */
export type Enum_Social_Link_Status_Obj_Rel_Insert_Input = {
  data: Enum_Social_Link_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Social_Link_Status_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_social_link_status" */
export type Enum_Social_Link_Status_On_Conflict = {
  constraint: Enum_Social_Link_Status_Constraint;
  update_columns?: Array<Enum_Social_Link_Status_Update_Column>;
  where?: Maybe<Enum_Social_Link_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_social_link_status". */
export type Enum_Social_Link_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_social_link_status */
export type Enum_Social_Link_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_social_link_status" */
export enum Enum_Social_Link_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_social_link_status" */
export type Enum_Social_Link_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_social_link_status" */
export enum Enum_Social_Link_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_social_link_type" */
export type Enum_Social_Link_Type = {
  __typename?: 'ENUM_social_link_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_social_link_type" */
export type Enum_Social_Link_Type_Aggregate = {
  __typename?: 'ENUM_social_link_type_aggregate';
  aggregate?: Maybe<Enum_Social_Link_Type_Aggregate_Fields>;
  nodes: Array<Enum_Social_Link_Type>;
};

/** aggregate fields of "ENUM_social_link_type" */
export type Enum_Social_Link_Type_Aggregate_Fields = {
  __typename?: 'ENUM_social_link_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Social_Link_Type_Max_Fields>;
  min?: Maybe<Enum_Social_Link_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_social_link_type" */
export type Enum_Social_Link_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Social_Link_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_social_link_type". All fields are combined with a logical 'AND'. */
export type Enum_Social_Link_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Social_Link_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Social_Link_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Social_Link_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_social_link_type" */
export enum Enum_Social_Link_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSocialLinkTypePkey = 'ENUM_social_link_type_pkey'
}

export enum Enum_Social_Link_Type_Enum {
  Featured = 'FEATURED',
  Marketplace = 'MARKETPLACE',
  Media = 'MEDIA',
  Personal = 'PERSONAL',
  Press = 'PRESS'
}

/** Boolean expression to compare columns of type "ENUM_social_link_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Social_Link_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Social_Link_Type_Enum>;
  _in?: Maybe<Array<Enum_Social_Link_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Social_Link_Type_Enum>;
  _nin?: Maybe<Array<Enum_Social_Link_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_social_link_type" */
export type Enum_Social_Link_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Social_Link_Type_Max_Fields = {
  __typename?: 'ENUM_social_link_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Social_Link_Type_Min_Fields = {
  __typename?: 'ENUM_social_link_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_social_link_type" */
export type Enum_Social_Link_Type_Mutation_Response = {
  __typename?: 'ENUM_social_link_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Social_Link_Type>;
};

/** input type for inserting object relation for remote table "ENUM_social_link_type" */
export type Enum_Social_Link_Type_Obj_Rel_Insert_Input = {
  data: Enum_Social_Link_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Social_Link_Type_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_social_link_type" */
export type Enum_Social_Link_Type_On_Conflict = {
  constraint: Enum_Social_Link_Type_Constraint;
  update_columns?: Array<Enum_Social_Link_Type_Update_Column>;
  where?: Maybe<Enum_Social_Link_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_social_link_type". */
export type Enum_Social_Link_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_social_link_type */
export type Enum_Social_Link_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_social_link_type" */
export enum Enum_Social_Link_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_social_link_type" */
export type Enum_Social_Link_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_social_link_type" */
export enum Enum_Social_Link_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_timed_event_name" */
export type Enum_Timed_Event_Name = {
  __typename?: 'ENUM_timed_event_name';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_timed_event_name" */
export type Enum_Timed_Event_Name_Aggregate = {
  __typename?: 'ENUM_timed_event_name_aggregate';
  aggregate?: Maybe<Enum_Timed_Event_Name_Aggregate_Fields>;
  nodes: Array<Enum_Timed_Event_Name>;
};

/** aggregate fields of "ENUM_timed_event_name" */
export type Enum_Timed_Event_Name_Aggregate_Fields = {
  __typename?: 'ENUM_timed_event_name_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Timed_Event_Name_Max_Fields>;
  min?: Maybe<Enum_Timed_Event_Name_Min_Fields>;
};


/** aggregate fields of "ENUM_timed_event_name" */
export type Enum_Timed_Event_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Timed_Event_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_timed_event_name". All fields are combined with a logical 'AND'. */
export type Enum_Timed_Event_Name_Bool_Exp = {
  _and?: Maybe<Array<Enum_Timed_Event_Name_Bool_Exp>>;
  _not?: Maybe<Enum_Timed_Event_Name_Bool_Exp>;
  _or?: Maybe<Array<Enum_Timed_Event_Name_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_timed_event_name" */
export enum Enum_Timed_Event_Name_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTimedEventNamePkey = 'ENUM_timed_event_name_pkey'
}

export enum Enum_Timed_Event_Name_Enum {
  /** An update about artist's stats sent every 2 weeks */
  BiweeklyRecapNotifications = 'BIWEEKLY_RECAP_NOTIFICATIONS',
  /** Daily event which triggers a series of processors for daily custom domain subscriptions */
  DailyNewsletterNotification = 'DAILY_NEWSLETTER_NOTIFICATION',
  /** Daily event which triggers a series of processors for daily notifications about recognition */
  DailyRecognitionNotifications = 'DAILY_RECOGNITION_NOTIFICATIONS',
  /** An update about coins earned this week sent to users */
  WeeklyCoinsUpdate = 'WEEKLY_COINS_UPDATE'
}

/** Boolean expression to compare columns of type "ENUM_timed_event_name_enum". All fields are combined with logical 'AND'. */
export type Enum_Timed_Event_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Timed_Event_Name_Enum>;
  _in?: Maybe<Array<Enum_Timed_Event_Name_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Timed_Event_Name_Enum>;
  _nin?: Maybe<Array<Enum_Timed_Event_Name_Enum>>;
};

/** input type for inserting data into table "ENUM_timed_event_name" */
export type Enum_Timed_Event_Name_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Timed_Event_Name_Max_Fields = {
  __typename?: 'ENUM_timed_event_name_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Timed_Event_Name_Min_Fields = {
  __typename?: 'ENUM_timed_event_name_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_timed_event_name" */
export type Enum_Timed_Event_Name_Mutation_Response = {
  __typename?: 'ENUM_timed_event_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Timed_Event_Name>;
};

/** on_conflict condition type for table "ENUM_timed_event_name" */
export type Enum_Timed_Event_Name_On_Conflict = {
  constraint: Enum_Timed_Event_Name_Constraint;
  update_columns?: Array<Enum_Timed_Event_Name_Update_Column>;
  where?: Maybe<Enum_Timed_Event_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_timed_event_name". */
export type Enum_Timed_Event_Name_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_timed_event_name */
export type Enum_Timed_Event_Name_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_timed_event_name" */
export enum Enum_Timed_Event_Name_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_timed_event_name" */
export type Enum_Timed_Event_Name_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_timed_event_name" */
export enum Enum_Timed_Event_Name_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_token_storage_status" */
export type Enum_Token_Storage_Status = {
  __typename?: 'ENUM_token_storage_status';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_token_storage_status" */
export type Enum_Token_Storage_Status_Aggregate = {
  __typename?: 'ENUM_token_storage_status_aggregate';
  aggregate?: Maybe<Enum_Token_Storage_Status_Aggregate_Fields>;
  nodes: Array<Enum_Token_Storage_Status>;
};

/** aggregate fields of "ENUM_token_storage_status" */
export type Enum_Token_Storage_Status_Aggregate_Fields = {
  __typename?: 'ENUM_token_storage_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Token_Storage_Status_Max_Fields>;
  min?: Maybe<Enum_Token_Storage_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_token_storage_status" */
export type Enum_Token_Storage_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Token_Storage_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_token_storage_status". All fields are combined with a logical 'AND'. */
export type Enum_Token_Storage_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Token_Storage_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Token_Storage_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Token_Storage_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_token_storage_status" */
export enum Enum_Token_Storage_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTokenStorageStatusPkey = 'ENUM_token_storage_status_pkey'
}

export enum Enum_Token_Storage_Status_Enum {
  /** A token whitch is past the expiration date */
  Expired = 'expired',
  /** An invalid token */
  Invalid = 'invalid',
  /** A token which was used */
  Used = 'used',
  /** A token which can be used */
  Valid = 'valid'
}

/** Boolean expression to compare columns of type "ENUM_token_storage_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Token_Storage_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Token_Storage_Status_Enum>;
  _in?: Maybe<Array<Enum_Token_Storage_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Token_Storage_Status_Enum>;
  _nin?: Maybe<Array<Enum_Token_Storage_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_token_storage_status" */
export type Enum_Token_Storage_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Token_Storage_Status_Max_Fields = {
  __typename?: 'ENUM_token_storage_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Token_Storage_Status_Min_Fields = {
  __typename?: 'ENUM_token_storage_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_token_storage_status" */
export type Enum_Token_Storage_Status_Mutation_Response = {
  __typename?: 'ENUM_token_storage_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Token_Storage_Status>;
};

/** on_conflict condition type for table "ENUM_token_storage_status" */
export type Enum_Token_Storage_Status_On_Conflict = {
  constraint: Enum_Token_Storage_Status_Constraint;
  update_columns?: Array<Enum_Token_Storage_Status_Update_Column>;
  where?: Maybe<Enum_Token_Storage_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_token_storage_status". */
export type Enum_Token_Storage_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_token_storage_status */
export type Enum_Token_Storage_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_token_storage_status" */
export enum Enum_Token_Storage_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_token_storage_status" */
export type Enum_Token_Storage_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_token_storage_status" */
export enum Enum_Token_Storage_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_transaction_origin" */
export type Enum_Transaction_Origin = {
  __typename?: 'ENUM_transaction_origin';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_transaction_origin" */
export type Enum_Transaction_Origin_Aggregate = {
  __typename?: 'ENUM_transaction_origin_aggregate';
  aggregate?: Maybe<Enum_Transaction_Origin_Aggregate_Fields>;
  nodes: Array<Enum_Transaction_Origin>;
};

/** aggregate fields of "ENUM_transaction_origin" */
export type Enum_Transaction_Origin_Aggregate_Fields = {
  __typename?: 'ENUM_transaction_origin_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Transaction_Origin_Max_Fields>;
  min?: Maybe<Enum_Transaction_Origin_Min_Fields>;
};


/** aggregate fields of "ENUM_transaction_origin" */
export type Enum_Transaction_Origin_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Transaction_Origin_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_transaction_origin". All fields are combined with a logical 'AND'. */
export type Enum_Transaction_Origin_Bool_Exp = {
  _and?: Maybe<Array<Enum_Transaction_Origin_Bool_Exp>>;
  _not?: Maybe<Enum_Transaction_Origin_Bool_Exp>;
  _or?: Maybe<Array<Enum_Transaction_Origin_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_transaction_origin" */
export enum Enum_Transaction_Origin_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTransactionOriginPkey = 'ENUM_transaction_origin_pkey'
}

export enum Enum_Transaction_Origin_Enum {
  Kaleido = 'KALEIDO',
  MyWebsite = 'MY_WEBSITE'
}

/** Boolean expression to compare columns of type "ENUM_transaction_origin_enum". All fields are combined with logical 'AND'. */
export type Enum_Transaction_Origin_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Transaction_Origin_Enum>;
  _in?: Maybe<Array<Enum_Transaction_Origin_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Transaction_Origin_Enum>;
  _nin?: Maybe<Array<Enum_Transaction_Origin_Enum>>;
};

/** input type for inserting data into table "ENUM_transaction_origin" */
export type Enum_Transaction_Origin_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Transaction_Origin_Max_Fields = {
  __typename?: 'ENUM_transaction_origin_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Transaction_Origin_Min_Fields = {
  __typename?: 'ENUM_transaction_origin_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_transaction_origin" */
export type Enum_Transaction_Origin_Mutation_Response = {
  __typename?: 'ENUM_transaction_origin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Transaction_Origin>;
};

/** input type for inserting object relation for remote table "ENUM_transaction_origin" */
export type Enum_Transaction_Origin_Obj_Rel_Insert_Input = {
  data: Enum_Transaction_Origin_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Enum_Transaction_Origin_On_Conflict>;
};

/** on_conflict condition type for table "ENUM_transaction_origin" */
export type Enum_Transaction_Origin_On_Conflict = {
  constraint: Enum_Transaction_Origin_Constraint;
  update_columns?: Array<Enum_Transaction_Origin_Update_Column>;
  where?: Maybe<Enum_Transaction_Origin_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_transaction_origin". */
export type Enum_Transaction_Origin_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_transaction_origin */
export type Enum_Transaction_Origin_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_transaction_origin" */
export enum Enum_Transaction_Origin_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_transaction_origin" */
export type Enum_Transaction_Origin_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_transaction_origin" */
export enum Enum_Transaction_Origin_Update_Column {
  /** column name */
  Value = 'value'
}

/** Enum table for Artworks transactions */
export type Enum_Transaction_Status = {
  __typename?: 'ENUM_transaction_status';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_transaction_status" */
export type Enum_Transaction_Status_Aggregate = {
  __typename?: 'ENUM_transaction_status_aggregate';
  aggregate?: Maybe<Enum_Transaction_Status_Aggregate_Fields>;
  nodes: Array<Enum_Transaction_Status>;
};

/** aggregate fields of "ENUM_transaction_status" */
export type Enum_Transaction_Status_Aggregate_Fields = {
  __typename?: 'ENUM_transaction_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Transaction_Status_Max_Fields>;
  min?: Maybe<Enum_Transaction_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_transaction_status" */
export type Enum_Transaction_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Transaction_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_transaction_status". All fields are combined with a logical 'AND'. */
export type Enum_Transaction_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Transaction_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Transaction_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Transaction_Status_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_transaction_status" */
export enum Enum_Transaction_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTransactionStatusPkey1 = 'ENUM_transaction_status_pkey1'
}

export enum Enum_Transaction_Status_Enum {
  /** Used when the transaction is accepted by the receiver */
  Accepted = 'ACCEPTED',
  /** Used when the transaction is canceled by the receiver */
  Canceled = 'CANCELED',
  /** Used when the transaction is initialized by the sender */
  Initialized = 'INITIALIZED',
  /** Artwork sent to someone who is in the application */
  Pending = 'PENDING',
  /** Used when the transaction is rejected by the receiver */
  Rejected = 'REJECTED'
}

/** Boolean expression to compare columns of type "ENUM_transaction_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Transaction_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Transaction_Status_Enum>;
  _in?: Maybe<Array<Enum_Transaction_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Transaction_Status_Enum>;
  _nin?: Maybe<Array<Enum_Transaction_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_transaction_status" */
export type Enum_Transaction_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Transaction_Status_Max_Fields = {
  __typename?: 'ENUM_transaction_status_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Transaction_Status_Min_Fields = {
  __typename?: 'ENUM_transaction_status_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_transaction_status" */
export type Enum_Transaction_Status_Mutation_Response = {
  __typename?: 'ENUM_transaction_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Transaction_Status>;
};

/** on_conflict condition type for table "ENUM_transaction_status" */
export type Enum_Transaction_Status_On_Conflict = {
  constraint: Enum_Transaction_Status_Constraint;
  update_columns?: Array<Enum_Transaction_Status_Update_Column>;
  where?: Maybe<Enum_Transaction_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_transaction_status". */
export type Enum_Transaction_Status_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_transaction_status */
export type Enum_Transaction_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_transaction_status" */
export enum Enum_Transaction_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_transaction_status" */
export type Enum_Transaction_Status_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_transaction_status" */
export enum Enum_Transaction_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_tutorial_status" */
export type Enum_Tutorial_Status = {
  __typename?: 'ENUM_tutorial_status';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_tutorial_status" */
export type Enum_Tutorial_Status_Aggregate = {
  __typename?: 'ENUM_tutorial_status_aggregate';
  aggregate?: Maybe<Enum_Tutorial_Status_Aggregate_Fields>;
  nodes: Array<Enum_Tutorial_Status>;
};

/** aggregate fields of "ENUM_tutorial_status" */
export type Enum_Tutorial_Status_Aggregate_Fields = {
  __typename?: 'ENUM_tutorial_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Tutorial_Status_Max_Fields>;
  min?: Maybe<Enum_Tutorial_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_tutorial_status" */
export type Enum_Tutorial_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Tutorial_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_tutorial_status". All fields are combined with a logical 'AND'. */
export type Enum_Tutorial_Status_Bool_Exp = {
  _and?: Maybe<Array<Enum_Tutorial_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Tutorial_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Tutorial_Status_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_tutorial_status" */
export enum Enum_Tutorial_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTutorialStatusPkey = 'ENUM_tutorial_status_pkey'
}

export enum Enum_Tutorial_Status_Enum {
  Finished = 'FINISHED',
  Skipped = 'SKIPPED',
  Started = 'STARTED'
}

/** Boolean expression to compare columns of type "ENUM_tutorial_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Tutorial_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Tutorial_Status_Enum>;
  _in?: Maybe<Array<Enum_Tutorial_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Tutorial_Status_Enum>;
  _nin?: Maybe<Array<Enum_Tutorial_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_tutorial_status" */
export type Enum_Tutorial_Status_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Tutorial_Status_Max_Fields = {
  __typename?: 'ENUM_tutorial_status_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Tutorial_Status_Min_Fields = {
  __typename?: 'ENUM_tutorial_status_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_tutorial_status" */
export type Enum_Tutorial_Status_Mutation_Response = {
  __typename?: 'ENUM_tutorial_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Tutorial_Status>;
};

/** on_conflict condition type for table "ENUM_tutorial_status" */
export type Enum_Tutorial_Status_On_Conflict = {
  constraint: Enum_Tutorial_Status_Constraint;
  update_columns?: Array<Enum_Tutorial_Status_Update_Column>;
  where?: Maybe<Enum_Tutorial_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_tutorial_status". */
export type Enum_Tutorial_Status_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_tutorial_status */
export type Enum_Tutorial_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_tutorial_status" */
export enum Enum_Tutorial_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_tutorial_status" */
export type Enum_Tutorial_Status_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_tutorial_status" */
export enum Enum_Tutorial_Status_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_tutorial_step_type" */
export type Enum_Tutorial_Step_Type = {
  __typename?: 'ENUM_tutorial_step_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_tutorial_step_type" */
export type Enum_Tutorial_Step_Type_Aggregate = {
  __typename?: 'ENUM_tutorial_step_type_aggregate';
  aggregate?: Maybe<Enum_Tutorial_Step_Type_Aggregate_Fields>;
  nodes: Array<Enum_Tutorial_Step_Type>;
};

/** aggregate fields of "ENUM_tutorial_step_type" */
export type Enum_Tutorial_Step_Type_Aggregate_Fields = {
  __typename?: 'ENUM_tutorial_step_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Tutorial_Step_Type_Max_Fields>;
  min?: Maybe<Enum_Tutorial_Step_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_tutorial_step_type" */
export type Enum_Tutorial_Step_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Tutorial_Step_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_tutorial_step_type". All fields are combined with a logical 'AND'. */
export type Enum_Tutorial_Step_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Tutorial_Step_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Tutorial_Step_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Tutorial_Step_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_tutorial_step_type" */
export enum Enum_Tutorial_Step_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTutorialStepTypePkey = 'ENUM_tutorial_step_type_pkey'
}

export enum Enum_Tutorial_Step_Type_Enum {
  About = 'ABOUT',
  AiAudioStatement = 'AI_AUDIO_STATEMENT',
  Appearance = 'APPEARANCE',
  ArtworkActions = 'ARTWORK_ACTIONS',
  ArtworkCertificate = 'ARTWORK_CERTIFICATE',
  ArtworkEdit = 'ARTWORK_EDIT',
  ArtworkView = 'ARTWORK_VIEW',
  BuildAudience = 'BUILD_AUDIENCE',
  ClaimDiscount = 'CLAIM_DISCOUNT',
  Connections = 'CONNECTIONS',
  Discovery = 'DISCOVERY',
  EditProfile = 'EDIT_PROFILE',
  HelpCenter = 'HELP_CENTER',
  InformationArtwork = 'INFORMATION_ARTWORK',
  InviteArtists = 'INVITE_ARTISTS',
  JoinArtUniverse = 'JOIN_ART_UNIVERSE',
  KaleidoCard = 'KALEIDO_CARD',
  KaleidoCoins = 'KALEIDO_COINS',
  KaleidoLinks = 'KALEIDO_LINKS',
  KaleidoPortfolio = 'KALEIDO_PORTFOLIO',
  ManageArt = 'MANAGE_ART',
  ManageSeries = 'MANAGE_SERIES',
  MoreLinks = 'MORE_LINKS',
  Multimedia = 'MULTIMEDIA',
  MyCollectors = 'MY_COLLECTORS',
  MyGalleries = 'MY_GALLERIES',
  MyStudio = 'MY_STUDIO',
  NextArtwork = 'NEXT_ARTWORK',
  Pinch = 'PINCH',
  PinchV3 = 'PINCH_V3',
  PreviousArtwork = 'PREVIOUS_ARTWORK',
  ProfileLinks = 'PROFILE_LINKS',
  PublishFirstArtwork = 'PUBLISH_FIRST_ARTWORK',
  PublishMoreArtwork = 'PUBLISH_MORE_ARTWORK',
  SecondaryContent = 'SECONDARY_CONTENT',
  ShareKaleido = 'SHARE_KALEIDO',
  Showroom = 'SHOWROOM',
  SwipeDown = 'SWIPE_DOWN',
  SwipeDownV3 = 'SWIPE_DOWN_V3',
  SwipeLeftRight = 'SWIPE_LEFT_RIGHT',
  SwipeLeftV3 = 'SWIPE_LEFT_V3',
  SwipeRightV3 = 'SWIPE_RIGHT_V3',
  SwipeUp = 'SWIPE_UP',
  SwipeUpV3 = 'SWIPE_UP_V3',
  ZoomIn = 'ZOOM_IN'
}

/** Boolean expression to compare columns of type "ENUM_tutorial_step_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Tutorial_Step_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Tutorial_Step_Type_Enum>;
  _in?: Maybe<Array<Enum_Tutorial_Step_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Tutorial_Step_Type_Enum>;
  _nin?: Maybe<Array<Enum_Tutorial_Step_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_tutorial_step_type" */
export type Enum_Tutorial_Step_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Tutorial_Step_Type_Max_Fields = {
  __typename?: 'ENUM_tutorial_step_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Tutorial_Step_Type_Min_Fields = {
  __typename?: 'ENUM_tutorial_step_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_tutorial_step_type" */
export type Enum_Tutorial_Step_Type_Mutation_Response = {
  __typename?: 'ENUM_tutorial_step_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Tutorial_Step_Type>;
};

/** on_conflict condition type for table "ENUM_tutorial_step_type" */
export type Enum_Tutorial_Step_Type_On_Conflict = {
  constraint: Enum_Tutorial_Step_Type_Constraint;
  update_columns?: Array<Enum_Tutorial_Step_Type_Update_Column>;
  where?: Maybe<Enum_Tutorial_Step_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_tutorial_step_type". */
export type Enum_Tutorial_Step_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_tutorial_step_type */
export type Enum_Tutorial_Step_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_tutorial_step_type" */
export enum Enum_Tutorial_Step_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_tutorial_step_type" */
export type Enum_Tutorial_Step_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_tutorial_step_type" */
export enum Enum_Tutorial_Step_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_tutorial_type" */
export type Enum_Tutorial_Type = {
  __typename?: 'ENUM_tutorial_type';
  value: Scalars['String'];
};

/** aggregated selection of "ENUM_tutorial_type" */
export type Enum_Tutorial_Type_Aggregate = {
  __typename?: 'ENUM_tutorial_type_aggregate';
  aggregate?: Maybe<Enum_Tutorial_Type_Aggregate_Fields>;
  nodes: Array<Enum_Tutorial_Type>;
};

/** aggregate fields of "ENUM_tutorial_type" */
export type Enum_Tutorial_Type_Aggregate_Fields = {
  __typename?: 'ENUM_tutorial_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Tutorial_Type_Max_Fields>;
  min?: Maybe<Enum_Tutorial_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_tutorial_type" */
export type Enum_Tutorial_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Tutorial_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_tutorial_type". All fields are combined with a logical 'AND'. */
export type Enum_Tutorial_Type_Bool_Exp = {
  _and?: Maybe<Array<Enum_Tutorial_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Tutorial_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Tutorial_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ENUM_tutorial_type" */
export enum Enum_Tutorial_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTutorialTypePkey = 'ENUM_tutorial_type_pkey'
}

export enum Enum_Tutorial_Type_Enum {
  ArtShowcase = 'ART_SHOWCASE',
  ArtShowcaseV2 = 'ART_SHOWCASE_V2',
  ArtShowcaseV2Slider = 'ART_SHOWCASE_V2_SLIDER',
  ArtShowcaseV3 = 'ART_SHOWCASE_V3',
  CertificatesIntro = 'CERTIFICATES_INTRO',
  ConsumerProductCollections = 'CONSUMER_PRODUCT_COLLECTIONS',
  ConsumerProductDots = 'CONSUMER_PRODUCT_DOTS',
  ConsumerProductExplore = 'CONSUMER_PRODUCT_EXPLORE',
  ConsumerProductLive = 'CONSUMER_PRODUCT_LIVE',
  ConsumerProductPrizes = 'CONSUMER_PRODUCT_PRIZES',
  DashboardIntro = 'DASHBOARD_INTRO',
  DashboardIntroPages = 'DASHBOARD_INTRO_PAGES',
  EditProfile = 'EDIT_PROFILE',
  KaleidoCard = 'KALEIDO_CARD',
  KaleidoCoins = 'KALEIDO_COINS',
  ManageArt = 'MANAGE_ART',
  MyWebsite = 'MY_WEBSITE',
  ShareKaleido = 'SHARE_KALEIDO'
}

/** Boolean expression to compare columns of type "ENUM_tutorial_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Tutorial_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Tutorial_Type_Enum>;
  _in?: Maybe<Array<Enum_Tutorial_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Tutorial_Type_Enum>;
  _nin?: Maybe<Array<Enum_Tutorial_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_tutorial_type" */
export type Enum_Tutorial_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Tutorial_Type_Max_Fields = {
  __typename?: 'ENUM_tutorial_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Tutorial_Type_Min_Fields = {
  __typename?: 'ENUM_tutorial_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_tutorial_type" */
export type Enum_Tutorial_Type_Mutation_Response = {
  __typename?: 'ENUM_tutorial_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Tutorial_Type>;
};

/** on_conflict condition type for table "ENUM_tutorial_type" */
export type Enum_Tutorial_Type_On_Conflict = {
  constraint: Enum_Tutorial_Type_Constraint;
  update_columns?: Array<Enum_Tutorial_Type_Update_Column>;
  where?: Maybe<Enum_Tutorial_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_tutorial_type". */
export type Enum_Tutorial_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_tutorial_type */
export type Enum_Tutorial_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ENUM_tutorial_type" */
export enum Enum_Tutorial_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ENUM_tutorial_type" */
export type Enum_Tutorial_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_tutorial_type" */
export enum Enum_Tutorial_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "ENUM_vumark_status" */
export type Enum_Vumark_Status = {
  __typename?: 'ENUM_vumark_status';
  VALUE: Scalars['String'];
};

/** aggregated selection of "ENUM_vumark_status" */
export type Enum_Vumark_Status_Aggregate = {
  __typename?: 'ENUM_vumark_status_aggregate';
  aggregate?: Maybe<Enum_Vumark_Status_Aggregate_Fields>;
  nodes: Array<Enum_Vumark_Status>;
};

/** aggregate fields of "ENUM_vumark_status" */
export type Enum_Vumark_Status_Aggregate_Fields = {
  __typename?: 'ENUM_vumark_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Vumark_Status_Max_Fields>;
  min?: Maybe<Enum_Vumark_Status_Min_Fields>;
};


/** aggregate fields of "ENUM_vumark_status" */
export type Enum_Vumark_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Vumark_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_vumark_status". All fields are combined with a logical 'AND'. */
export type Enum_Vumark_Status_Bool_Exp = {
  VALUE?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Enum_Vumark_Status_Bool_Exp>>;
  _not?: Maybe<Enum_Vumark_Status_Bool_Exp>;
  _or?: Maybe<Array<Enum_Vumark_Status_Bool_Exp>>;
};

/** unique or primary key constraints on table "ENUM_vumark_status" */
export enum Enum_Vumark_Status_Constraint {
  /** unique or primary key constraint on columns "VALUE" */
  EnumVumarkStatusPkey = 'Enum_vumark_status_pkey'
}

export enum Enum_Vumark_Status_Enum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Disabled = 'DISABLED'
}

/** Boolean expression to compare columns of type "ENUM_vumark_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Vumark_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Vumark_Status_Enum>;
  _in?: Maybe<Array<Enum_Vumark_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Vumark_Status_Enum>;
  _nin?: Maybe<Array<Enum_Vumark_Status_Enum>>;
};

/** input type for inserting data into table "ENUM_vumark_status" */
export type Enum_Vumark_Status_Insert_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Vumark_Status_Max_Fields = {
  __typename?: 'ENUM_vumark_status_max_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Vumark_Status_Min_Fields = {
  __typename?: 'ENUM_vumark_status_min_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_vumark_status" */
export type Enum_Vumark_Status_Mutation_Response = {
  __typename?: 'ENUM_vumark_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Vumark_Status>;
};

/** on_conflict condition type for table "ENUM_vumark_status" */
export type Enum_Vumark_Status_On_Conflict = {
  constraint: Enum_Vumark_Status_Constraint;
  update_columns?: Array<Enum_Vumark_Status_Update_Column>;
  where?: Maybe<Enum_Vumark_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_vumark_status". */
export type Enum_Vumark_Status_Order_By = {
  VALUE?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_vumark_status */
export type Enum_Vumark_Status_Pk_Columns_Input = {
  VALUE: Scalars['String'];
};

/** select columns of table "ENUM_vumark_status" */
export enum Enum_Vumark_Status_Select_Column {
  /** column name */
  Value = 'VALUE'
}

/** input type for updating data in table "ENUM_vumark_status" */
export type Enum_Vumark_Status_Set_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_vumark_status" */
export enum Enum_Vumark_Status_Update_Column {
  /** column name */
  Value = 'VALUE'
}

/** columns and relationships of "ENUM_vumark_type" */
export type Enum_Vumark_Type = {
  __typename?: 'ENUM_vumark_type';
  VALUE: Scalars['String'];
};

/** aggregated selection of "ENUM_vumark_type" */
export type Enum_Vumark_Type_Aggregate = {
  __typename?: 'ENUM_vumark_type_aggregate';
  aggregate?: Maybe<Enum_Vumark_Type_Aggregate_Fields>;
  nodes: Array<Enum_Vumark_Type>;
};

/** aggregate fields of "ENUM_vumark_type" */
export type Enum_Vumark_Type_Aggregate_Fields = {
  __typename?: 'ENUM_vumark_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Vumark_Type_Max_Fields>;
  min?: Maybe<Enum_Vumark_Type_Min_Fields>;
};


/** aggregate fields of "ENUM_vumark_type" */
export type Enum_Vumark_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_Vumark_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ENUM_vumark_type". All fields are combined with a logical 'AND'. */
export type Enum_Vumark_Type_Bool_Exp = {
  VALUE?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Enum_Vumark_Type_Bool_Exp>>;
  _not?: Maybe<Enum_Vumark_Type_Bool_Exp>;
  _or?: Maybe<Array<Enum_Vumark_Type_Bool_Exp>>;
};

/** unique or primary key constraints on table "ENUM_vumark_type" */
export enum Enum_Vumark_Type_Constraint {
  /** unique or primary key constraint on columns "VALUE" */
  EnumVumarkTypePkey = 'ENUM_vumark_type_pkey'
}

export enum Enum_Vumark_Type_Enum {
  Certificate = 'CERTIFICATE'
}

/** Boolean expression to compare columns of type "ENUM_vumark_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Vumark_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Vumark_Type_Enum>;
  _in?: Maybe<Array<Enum_Vumark_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Vumark_Type_Enum>;
  _nin?: Maybe<Array<Enum_Vumark_Type_Enum>>;
};

/** input type for inserting data into table "ENUM_vumark_type" */
export type Enum_Vumark_Type_Insert_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Vumark_Type_Max_Fields = {
  __typename?: 'ENUM_vumark_type_max_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Vumark_Type_Min_Fields = {
  __typename?: 'ENUM_vumark_type_min_fields';
  VALUE?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ENUM_vumark_type" */
export type Enum_Vumark_Type_Mutation_Response = {
  __typename?: 'ENUM_vumark_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Vumark_Type>;
};

/** on_conflict condition type for table "ENUM_vumark_type" */
export type Enum_Vumark_Type_On_Conflict = {
  constraint: Enum_Vumark_Type_Constraint;
  update_columns?: Array<Enum_Vumark_Type_Update_Column>;
  where?: Maybe<Enum_Vumark_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "ENUM_vumark_type". */
export type Enum_Vumark_Type_Order_By = {
  VALUE?: Maybe<Order_By>;
};

/** primary key columns input for table: ENUM_vumark_type */
export type Enum_Vumark_Type_Pk_Columns_Input = {
  VALUE: Scalars['String'];
};

/** select columns of table "ENUM_vumark_type" */
export enum Enum_Vumark_Type_Select_Column {
  /** column name */
  Value = 'VALUE'
}

/** input type for updating data in table "ENUM_vumark_type" */
export type Enum_Vumark_Type_Set_Input = {
  VALUE?: Maybe<Scalars['String']>;
};

/** update columns of table "ENUM_vumark_type" */
export enum Enum_Vumark_Type_Update_Column {
  /** column name */
  Value = 'VALUE'
}

/** Table containing information about the edition of an artwork */
export type Edition = {
  __typename?: 'Edition';
  /** An object relationship */
  Artwork: Artwork;
  /** An array relationship */
  Artwork_secondary_media: Array<Artwork_Secondary_Media>;
  /** An aggregate relationship */
  Artwork_secondary_media_aggregate: Artwork_Secondary_Media_Aggregate;
  /** An array relationship */
  Edition_By_Sizes: Array<Edition_By_Size>;
  /** An aggregate relationship */
  Edition_By_Sizes_aggregate: Edition_By_Size_Aggregate;
  /** An array relationship */
  Print_amounts: Array<Print_Amounts>;
  /** An aggregate relationship */
  Print_amounts_aggregate: Print_Amounts_Aggregate;
  /** An array relationship */
  Prints: Array<Print>;
  /** An aggregate relationship */
  Prints_aggregate: Print_Aggregate;
  add_edition_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Enum>;
  artwork_id: Scalars['bigint'];
  available_prints_amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  is_available_sale?: Maybe<Scalars['Boolean']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  is_price_public?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['float8']>;
  publish_date?: Maybe<Scalars['timestamptz']>;
  scope: Enum_Price_Scope_Enum;
  title: Scalars['String'];
};


/** Table containing information about the edition of an artwork */
export type EditionArtwork_Secondary_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


/** Table containing information about the edition of an artwork */
export type EditionArtwork_Secondary_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


/** Table containing information about the edition of an artwork */
export type EditionEdition_By_SizesArgs = {
  distinct_on?: Maybe<Array<Edition_By_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Size_Order_By>>;
  where?: Maybe<Edition_By_Size_Bool_Exp>;
};


/** Table containing information about the edition of an artwork */
export type EditionEdition_By_Sizes_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_By_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Size_Order_By>>;
  where?: Maybe<Edition_By_Size_Bool_Exp>;
};


/** Table containing information about the edition of an artwork */
export type EditionPrint_AmountsArgs = {
  distinct_on?: Maybe<Array<Print_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Amounts_Order_By>>;
  where?: Maybe<Print_Amounts_Bool_Exp>;
};


/** Table containing information about the edition of an artwork */
export type EditionPrint_Amounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Amounts_Order_By>>;
  where?: Maybe<Print_Amounts_Bool_Exp>;
};


/** Table containing information about the edition of an artwork */
export type EditionPrintsArgs = {
  distinct_on?: Maybe<Array<Print_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Order_By>>;
  where?: Maybe<Print_Bool_Exp>;
};


/** Table containing information about the edition of an artwork */
export type EditionPrints_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Order_By>>;
  where?: Maybe<Print_Bool_Exp>;
};

/** Table containing information about the edition specified by size  */
export type Edition_By_Size = {
  __typename?: 'Edition_By_Size';
  /** An object relationship */
  ENUM_size: Enum_Size;
  /** An object relationship */
  Edition: Edition;
  /** An array relationship */
  Edition_By_Types: Array<Edition_By_Type>;
  /** An aggregate relationship */
  Edition_By_Types_aggregate: Edition_By_Type_Aggregate;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  edition_id: Scalars['bigint'];
  height?: Maybe<Scalars['float8']>;
  id: Scalars['bigint'];
  is_price_public?: Maybe<Scalars['Boolean']>;
  materials?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_date_day?: Maybe<Scalars['Int']>;
  print_date_month?: Maybe<Scalars['Int']>;
  print_date_year?: Maybe<Scalars['Int']>;
  print_size: Enum_Size_Enum;
  status?: Maybe<Enum_Edition_Status_Enum>;
  technique?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};


/** Table containing information about the edition specified by size  */
export type Edition_By_SizeEdition_By_TypesArgs = {
  distinct_on?: Maybe<Array<Edition_By_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Type_Order_By>>;
  where?: Maybe<Edition_By_Type_Bool_Exp>;
};


/** Table containing information about the edition specified by size  */
export type Edition_By_SizeEdition_By_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_By_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Type_Order_By>>;
  where?: Maybe<Edition_By_Type_Bool_Exp>;
};

/** aggregated selection of "Edition_By_Size" */
export type Edition_By_Size_Aggregate = {
  __typename?: 'Edition_By_Size_aggregate';
  aggregate?: Maybe<Edition_By_Size_Aggregate_Fields>;
  nodes: Array<Edition_By_Size>;
};

/** aggregate fields of "Edition_By_Size" */
export type Edition_By_Size_Aggregate_Fields = {
  __typename?: 'Edition_By_Size_aggregate_fields';
  avg?: Maybe<Edition_By_Size_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Edition_By_Size_Max_Fields>;
  min?: Maybe<Edition_By_Size_Min_Fields>;
  stddev?: Maybe<Edition_By_Size_Stddev_Fields>;
  stddev_pop?: Maybe<Edition_By_Size_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Edition_By_Size_Stddev_Samp_Fields>;
  sum?: Maybe<Edition_By_Size_Sum_Fields>;
  var_pop?: Maybe<Edition_By_Size_Var_Pop_Fields>;
  var_samp?: Maybe<Edition_By_Size_Var_Samp_Fields>;
  variance?: Maybe<Edition_By_Size_Variance_Fields>;
};


/** aggregate fields of "Edition_By_Size" */
export type Edition_By_Size_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Edition_By_Size_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Edition_By_Size" */
export type Edition_By_Size_Aggregate_Order_By = {
  avg?: Maybe<Edition_By_Size_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Edition_By_Size_Max_Order_By>;
  min?: Maybe<Edition_By_Size_Min_Order_By>;
  stddev?: Maybe<Edition_By_Size_Stddev_Order_By>;
  stddev_pop?: Maybe<Edition_By_Size_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Edition_By_Size_Stddev_Samp_Order_By>;
  sum?: Maybe<Edition_By_Size_Sum_Order_By>;
  var_pop?: Maybe<Edition_By_Size_Var_Pop_Order_By>;
  var_samp?: Maybe<Edition_By_Size_Var_Samp_Order_By>;
  variance?: Maybe<Edition_By_Size_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Edition_By_Size" */
export type Edition_By_Size_Arr_Rel_Insert_Input = {
  data: Array<Edition_By_Size_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Edition_By_Size_On_Conflict>;
};

/** aggregate avg on columns */
export type Edition_By_Size_Avg_Fields = {
  __typename?: 'Edition_By_Size_avg_fields';
  depth?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_date_day?: Maybe<Scalars['Float']>;
  print_date_month?: Maybe<Scalars['Float']>;
  print_date_year?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Avg_Order_By = {
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Edition_By_Size". All fields are combined with a logical 'AND'. */
export type Edition_By_Size_Bool_Exp = {
  ENUM_size?: Maybe<Enum_Size_Bool_Exp>;
  Edition?: Maybe<Edition_Bool_Exp>;
  Edition_By_Types?: Maybe<Edition_By_Type_Bool_Exp>;
  _and?: Maybe<Array<Edition_By_Size_Bool_Exp>>;
  _not?: Maybe<Edition_By_Size_Bool_Exp>;
  _or?: Maybe<Array<Edition_By_Size_Bool_Exp>>;
  currency?: Maybe<String_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  edition_id?: Maybe<Bigint_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_price_public?: Maybe<Boolean_Comparison_Exp>;
  materials?: Maybe<String_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  print_date_day?: Maybe<Int_Comparison_Exp>;
  print_date_month?: Maybe<Int_Comparison_Exp>;
  print_date_year?: Maybe<Int_Comparison_Exp>;
  print_size?: Maybe<Enum_Size_Enum_Comparison_Exp>;
  status?: Maybe<Enum_Edition_Status_Enum_Comparison_Exp>;
  technique?: Maybe<String_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "Edition_By_Size" */
export enum Edition_By_Size_Constraint {
  /** unique or primary key constraint on columns "id" */
  EditionBySizePkey = 'Edition_By_Size_pkey'
}

/** input type for incrementing numeric columns in table "Edition_By_Size" */
export type Edition_By_Size_Inc_Input = {
  depth?: Maybe<Scalars['float8']>;
  edition_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_date_day?: Maybe<Scalars['Int']>;
  print_date_month?: Maybe<Scalars['Int']>;
  print_date_year?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "Edition_By_Size" */
export type Edition_By_Size_Insert_Input = {
  ENUM_size?: Maybe<Enum_Size_Obj_Rel_Insert_Input>;
  Edition?: Maybe<Edition_Obj_Rel_Insert_Input>;
  Edition_By_Types?: Maybe<Edition_By_Type_Arr_Rel_Insert_Input>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  edition_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  is_price_public?: Maybe<Scalars['Boolean']>;
  materials?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_date_day?: Maybe<Scalars['Int']>;
  print_date_month?: Maybe<Scalars['Int']>;
  print_date_year?: Maybe<Scalars['Int']>;
  print_size?: Maybe<Enum_Size_Enum>;
  status?: Maybe<Enum_Edition_Status_Enum>;
  technique?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Edition_By_Size_Max_Fields = {
  __typename?: 'Edition_By_Size_max_fields';
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  edition_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  materials?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_date_day?: Maybe<Scalars['Int']>;
  print_date_month?: Maybe<Scalars['Int']>;
  print_date_year?: Maybe<Scalars['Int']>;
  technique?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Max_Order_By = {
  currency?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  materials?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  technique?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Edition_By_Size_Min_Fields = {
  __typename?: 'Edition_By_Size_min_fields';
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  edition_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  materials?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_date_day?: Maybe<Scalars['Int']>;
  print_date_month?: Maybe<Scalars['Int']>;
  print_date_year?: Maybe<Scalars['Int']>;
  technique?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Min_Order_By = {
  currency?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  materials?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  technique?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** response of any mutation on the table "Edition_By_Size" */
export type Edition_By_Size_Mutation_Response = {
  __typename?: 'Edition_By_Size_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Edition_By_Size>;
};

/** input type for inserting object relation for remote table "Edition_By_Size" */
export type Edition_By_Size_Obj_Rel_Insert_Input = {
  data: Edition_By_Size_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Edition_By_Size_On_Conflict>;
};

/** on_conflict condition type for table "Edition_By_Size" */
export type Edition_By_Size_On_Conflict = {
  constraint: Edition_By_Size_Constraint;
  update_columns?: Array<Edition_By_Size_Update_Column>;
  where?: Maybe<Edition_By_Size_Bool_Exp>;
};

/** Ordering options when selecting data from "Edition_By_Size". */
export type Edition_By_Size_Order_By = {
  ENUM_size?: Maybe<Enum_Size_Order_By>;
  Edition?: Maybe<Edition_Order_By>;
  Edition_By_Types_aggregate?: Maybe<Edition_By_Type_Aggregate_Order_By>;
  currency?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_price_public?: Maybe<Order_By>;
  materials?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  print_size?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  technique?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** primary key columns input for table: Edition_By_Size */
export type Edition_By_Size_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Edition_By_Size" */
export enum Edition_By_Size_Select_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Depth = 'depth',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsPricePublic = 'is_price_public',
  /** column name */
  Materials = 'materials',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  Price = 'price',
  /** column name */
  PrintDateDay = 'print_date_day',
  /** column name */
  PrintDateMonth = 'print_date_month',
  /** column name */
  PrintDateYear = 'print_date_year',
  /** column name */
  PrintSize = 'print_size',
  /** column name */
  Status = 'status',
  /** column name */
  Technique = 'technique',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "Edition_By_Size" */
export type Edition_By_Size_Set_Input = {
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  edition_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  is_price_public?: Maybe<Scalars['Boolean']>;
  materials?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  print_date_day?: Maybe<Scalars['Int']>;
  print_date_month?: Maybe<Scalars['Int']>;
  print_date_year?: Maybe<Scalars['Int']>;
  print_size?: Maybe<Enum_Size_Enum>;
  status?: Maybe<Enum_Edition_Status_Enum>;
  technique?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Edition_By_Size_Stddev_Fields = {
  __typename?: 'Edition_By_Size_stddev_fields';
  depth?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_date_day?: Maybe<Scalars['Float']>;
  print_date_month?: Maybe<Scalars['Float']>;
  print_date_year?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Stddev_Order_By = {
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Edition_By_Size_Stddev_Pop_Fields = {
  __typename?: 'Edition_By_Size_stddev_pop_fields';
  depth?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_date_day?: Maybe<Scalars['Float']>;
  print_date_month?: Maybe<Scalars['Float']>;
  print_date_year?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Stddev_Pop_Order_By = {
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Edition_By_Size_Stddev_Samp_Fields = {
  __typename?: 'Edition_By_Size_stddev_samp_fields';
  depth?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_date_day?: Maybe<Scalars['Float']>;
  print_date_month?: Maybe<Scalars['Float']>;
  print_date_year?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Stddev_Samp_Order_By = {
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Edition_By_Size_Sum_Fields = {
  __typename?: 'Edition_By_Size_sum_fields';
  depth?: Maybe<Scalars['float8']>;
  edition_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_date_day?: Maybe<Scalars['Int']>;
  print_date_month?: Maybe<Scalars['Int']>;
  print_date_year?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Sum_Order_By = {
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** update columns of table "Edition_By_Size" */
export enum Edition_By_Size_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Depth = 'depth',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsPricePublic = 'is_price_public',
  /** column name */
  Materials = 'materials',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  Price = 'price',
  /** column name */
  PrintDateDay = 'print_date_day',
  /** column name */
  PrintDateMonth = 'print_date_month',
  /** column name */
  PrintDateYear = 'print_date_year',
  /** column name */
  PrintSize = 'print_size',
  /** column name */
  Status = 'status',
  /** column name */
  Technique = 'technique',
  /** column name */
  Width = 'width'
}

/** aggregate var_pop on columns */
export type Edition_By_Size_Var_Pop_Fields = {
  __typename?: 'Edition_By_Size_var_pop_fields';
  depth?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_date_day?: Maybe<Scalars['Float']>;
  print_date_month?: Maybe<Scalars['Float']>;
  print_date_year?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Var_Pop_Order_By = {
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Edition_By_Size_Var_Samp_Fields = {
  __typename?: 'Edition_By_Size_var_samp_fields';
  depth?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_date_day?: Maybe<Scalars['Float']>;
  print_date_month?: Maybe<Scalars['Float']>;
  print_date_year?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Var_Samp_Order_By = {
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Edition_By_Size_Variance_Fields = {
  __typename?: 'Edition_By_Size_variance_fields';
  depth?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_date_day?: Maybe<Scalars['Float']>;
  print_date_month?: Maybe<Scalars['Float']>;
  print_date_year?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Edition_By_Size" */
export type Edition_By_Size_Variance_Order_By = {
  depth?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_date_day?: Maybe<Order_By>;
  print_date_month?: Maybe<Order_By>;
  print_date_year?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** Table  containing Edition information by Edition Type */
export type Edition_By_Type = {
  __typename?: 'Edition_By_Type';
  /** An object relationship */
  Edition_By_Size: Edition_By_Size;
  /** An object relationship */
  Edition_Type: Edition_Type;
  /** An array relationship */
  Prints: Array<Print>;
  /** An aggregate relationship */
  Prints_aggregate: Print_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_size_id: Scalars['bigint'];
  edition_type_id: Scalars['bigint'];
  id: Scalars['bigint'];
  print_amount?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Edition_Status_Enum>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};


/** Table  containing Edition information by Edition Type */
export type Edition_By_TypePrintsArgs = {
  distinct_on?: Maybe<Array<Print_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Order_By>>;
  where?: Maybe<Print_Bool_Exp>;
};


/** Table  containing Edition information by Edition Type */
export type Edition_By_TypePrints_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Order_By>>;
  where?: Maybe<Print_Bool_Exp>;
};

/** aggregated selection of "Edition_By_Type" */
export type Edition_By_Type_Aggregate = {
  __typename?: 'Edition_By_Type_aggregate';
  aggregate?: Maybe<Edition_By_Type_Aggregate_Fields>;
  nodes: Array<Edition_By_Type>;
};

/** aggregate fields of "Edition_By_Type" */
export type Edition_By_Type_Aggregate_Fields = {
  __typename?: 'Edition_By_Type_aggregate_fields';
  avg?: Maybe<Edition_By_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Edition_By_Type_Max_Fields>;
  min?: Maybe<Edition_By_Type_Min_Fields>;
  stddev?: Maybe<Edition_By_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Edition_By_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Edition_By_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Edition_By_Type_Sum_Fields>;
  var_pop?: Maybe<Edition_By_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Edition_By_Type_Var_Samp_Fields>;
  variance?: Maybe<Edition_By_Type_Variance_Fields>;
};


/** aggregate fields of "Edition_By_Type" */
export type Edition_By_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Edition_By_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Edition_By_Type" */
export type Edition_By_Type_Aggregate_Order_By = {
  avg?: Maybe<Edition_By_Type_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Edition_By_Type_Max_Order_By>;
  min?: Maybe<Edition_By_Type_Min_Order_By>;
  stddev?: Maybe<Edition_By_Type_Stddev_Order_By>;
  stddev_pop?: Maybe<Edition_By_Type_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Edition_By_Type_Stddev_Samp_Order_By>;
  sum?: Maybe<Edition_By_Type_Sum_Order_By>;
  var_pop?: Maybe<Edition_By_Type_Var_Pop_Order_By>;
  var_samp?: Maybe<Edition_By_Type_Var_Samp_Order_By>;
  variance?: Maybe<Edition_By_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Edition_By_Type" */
export type Edition_By_Type_Arr_Rel_Insert_Input = {
  data: Array<Edition_By_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Edition_By_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Edition_By_Type_Avg_Fields = {
  __typename?: 'Edition_By_Type_avg_fields';
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Avg_Order_By = {
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Edition_By_Type". All fields are combined with a logical 'AND'. */
export type Edition_By_Type_Bool_Exp = {
  Edition_By_Size?: Maybe<Edition_By_Size_Bool_Exp>;
  Edition_Type?: Maybe<Edition_Type_Bool_Exp>;
  Prints?: Maybe<Print_Bool_Exp>;
  _and?: Maybe<Array<Edition_By_Type_Bool_Exp>>;
  _not?: Maybe<Edition_By_Type_Bool_Exp>;
  _or?: Maybe<Array<Edition_By_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  edition_by_size_id?: Maybe<Bigint_Comparison_Exp>;
  edition_type_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  print_amount?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<Enum_Edition_Status_Enum_Comparison_Exp>;
  unavailable_print_amount?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Edition_By_Type" */
export enum Edition_By_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  EditionByTypePkey = 'Edition_By_Type_pkey'
}

/** input type for incrementing numeric columns in table "Edition_By_Type" */
export type Edition_By_Type_Inc_Input = {
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Edition_By_Type" */
export type Edition_By_Type_Insert_Input = {
  Edition_By_Size?: Maybe<Edition_By_Size_Obj_Rel_Insert_Input>;
  Edition_Type?: Maybe<Edition_Type_Obj_Rel_Insert_Input>;
  Prints?: Maybe<Print_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Edition_Status_Enum>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Edition_By_Type_Max_Fields = {
  __typename?: 'Edition_By_Type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Edition_By_Type_Min_Fields = {
  __typename?: 'Edition_By_Type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** response of any mutation on the table "Edition_By_Type" */
export type Edition_By_Type_Mutation_Response = {
  __typename?: 'Edition_By_Type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Edition_By_Type>;
};

/** input type for inserting object relation for remote table "Edition_By_Type" */
export type Edition_By_Type_Obj_Rel_Insert_Input = {
  data: Edition_By_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Edition_By_Type_On_Conflict>;
};

/** on_conflict condition type for table "Edition_By_Type" */
export type Edition_By_Type_On_Conflict = {
  constraint: Edition_By_Type_Constraint;
  update_columns?: Array<Edition_By_Type_Update_Column>;
  where?: Maybe<Edition_By_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "Edition_By_Type". */
export type Edition_By_Type_Order_By = {
  Edition_By_Size?: Maybe<Edition_By_Size_Order_By>;
  Edition_Type?: Maybe<Edition_Type_Order_By>;
  Prints_aggregate?: Maybe<Print_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** primary key columns input for table: Edition_By_Type */
export type Edition_By_Type_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Edition_By_Type" */
export enum Edition_By_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EditionBySizeId = 'edition_by_size_id',
  /** column name */
  EditionTypeId = 'edition_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  PrintAmount = 'print_amount',
  /** column name */
  Status = 'status',
  /** column name */
  UnavailablePrintAmount = 'unavailable_print_amount'
}

/** input type for updating data in table "Edition_By_Type" */
export type Edition_By_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Edition_Status_Enum>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Edition_By_Type_Stddev_Fields = {
  __typename?: 'Edition_By_Type_stddev_fields';
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Stddev_Order_By = {
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Edition_By_Type_Stddev_Pop_Fields = {
  __typename?: 'Edition_By_Type_stddev_pop_fields';
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Stddev_Pop_Order_By = {
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Edition_By_Type_Stddev_Samp_Fields = {
  __typename?: 'Edition_By_Type_stddev_samp_fields';
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Stddev_Samp_Order_By = {
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Edition_By_Type_Sum_Fields = {
  __typename?: 'Edition_By_Type_sum_fields';
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Sum_Order_By = {
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** update columns of table "Edition_By_Type" */
export enum Edition_By_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EditionBySizeId = 'edition_by_size_id',
  /** column name */
  EditionTypeId = 'edition_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  PrintAmount = 'print_amount',
  /** column name */
  Status = 'status',
  /** column name */
  UnavailablePrintAmount = 'unavailable_print_amount'
}

/** aggregate var_pop on columns */
export type Edition_By_Type_Var_Pop_Fields = {
  __typename?: 'Edition_By_Type_var_pop_fields';
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Var_Pop_Order_By = {
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Edition_By_Type_Var_Samp_Fields = {
  __typename?: 'Edition_By_Type_var_samp_fields';
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Var_Samp_Order_By = {
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Edition_By_Type_Variance_Fields = {
  __typename?: 'Edition_By_Type_variance_fields';
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Edition_By_Type" */
export type Edition_By_Type_Variance_Order_By = {
  edition_by_size_id?: Maybe<Order_By>;
  edition_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** Catalog for artwork edition type. Context_id equals to NULL represents Default Values, if NOT NULL is user-created value. */
export type Edition_Type = {
  __typename?: 'Edition_Type';
  /** An object relationship */
  Context?: Maybe<Context>;
  /** An array relationship */
  Edition_By_Types: Array<Edition_By_Type>;
  /** An aggregate relationship */
  Edition_By_Types_aggregate: Edition_By_Type_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  value: Scalars['String'];
};


/** Catalog for artwork edition type. Context_id equals to NULL represents Default Values, if NOT NULL is user-created value. */
export type Edition_TypeEdition_By_TypesArgs = {
  distinct_on?: Maybe<Array<Edition_By_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Type_Order_By>>;
  where?: Maybe<Edition_By_Type_Bool_Exp>;
};


/** Catalog for artwork edition type. Context_id equals to NULL represents Default Values, if NOT NULL is user-created value. */
export type Edition_TypeEdition_By_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_By_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Type_Order_By>>;
  where?: Maybe<Edition_By_Type_Bool_Exp>;
};

/** aggregated selection of "Edition_Type" */
export type Edition_Type_Aggregate = {
  __typename?: 'Edition_Type_aggregate';
  aggregate?: Maybe<Edition_Type_Aggregate_Fields>;
  nodes: Array<Edition_Type>;
};

/** aggregate fields of "Edition_Type" */
export type Edition_Type_Aggregate_Fields = {
  __typename?: 'Edition_Type_aggregate_fields';
  avg?: Maybe<Edition_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Edition_Type_Max_Fields>;
  min?: Maybe<Edition_Type_Min_Fields>;
  stddev?: Maybe<Edition_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Edition_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Edition_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Edition_Type_Sum_Fields>;
  var_pop?: Maybe<Edition_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Edition_Type_Var_Samp_Fields>;
  variance?: Maybe<Edition_Type_Variance_Fields>;
};


/** aggregate fields of "Edition_Type" */
export type Edition_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Edition_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Edition_Type_Avg_Fields = {
  __typename?: 'Edition_Type_avg_fields';
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Edition_Type". All fields are combined with a logical 'AND'. */
export type Edition_Type_Bool_Exp = {
  Context?: Maybe<Context_Bool_Exp>;
  Edition_By_Types?: Maybe<Edition_By_Type_Bool_Exp>;
  _and?: Maybe<Array<Edition_Type_Bool_Exp>>;
  _not?: Maybe<Edition_Type_Bool_Exp>;
  _or?: Maybe<Array<Edition_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  creator_context_id?: Maybe<Bigint_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Edition_Type" */
export enum Edition_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  EditionTypePkey = 'Edition_Type_pkey',
  /** unique or primary key constraint on columns "creator_context_id", "value" */
  EditionTypeArtistConstraint = 'edition_type_artist_constraint'
}

/** input type for incrementing numeric columns in table "Edition_Type" */
export type Edition_Type_Inc_Input = {
  creator_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Edition_Type" */
export type Edition_Type_Insert_Input = {
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  Edition_By_Types?: Maybe<Edition_By_Type_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Edition_Type_Max_Fields = {
  __typename?: 'Edition_Type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Edition_Type_Min_Fields = {
  __typename?: 'Edition_Type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Edition_Type" */
export type Edition_Type_Mutation_Response = {
  __typename?: 'Edition_Type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Edition_Type>;
};

/** input type for inserting object relation for remote table "Edition_Type" */
export type Edition_Type_Obj_Rel_Insert_Input = {
  data: Edition_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Edition_Type_On_Conflict>;
};

/** on_conflict condition type for table "Edition_Type" */
export type Edition_Type_On_Conflict = {
  constraint: Edition_Type_Constraint;
  update_columns?: Array<Edition_Type_Update_Column>;
  where?: Maybe<Edition_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "Edition_Type". */
export type Edition_Type_Order_By = {
  Context?: Maybe<Context_Order_By>;
  Edition_By_Types_aggregate?: Maybe<Edition_By_Type_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  creator_context_id?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: Edition_Type */
export type Edition_Type_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Edition_Type" */
export enum Edition_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorContextId = 'creator_context_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Edition_Type" */
export type Edition_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Edition_Type_Stddev_Fields = {
  __typename?: 'Edition_Type_stddev_fields';
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Edition_Type_Stddev_Pop_Fields = {
  __typename?: 'Edition_Type_stddev_pop_fields';
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Edition_Type_Stddev_Samp_Fields = {
  __typename?: 'Edition_Type_stddev_samp_fields';
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Edition_Type_Sum_Fields = {
  __typename?: 'Edition_Type_sum_fields';
  creator_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Edition_Type" */
export enum Edition_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorContextId = 'creator_context_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Edition_Type_Var_Pop_Fields = {
  __typename?: 'Edition_Type_var_pop_fields';
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Edition_Type_Var_Samp_Fields = {
  __typename?: 'Edition_Type_var_samp_fields';
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Edition_Type_Variance_Fields = {
  __typename?: 'Edition_Type_variance_fields';
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "Edition" */
export type Edition_Aggregate = {
  __typename?: 'Edition_aggregate';
  aggregate?: Maybe<Edition_Aggregate_Fields>;
  nodes: Array<Edition>;
};

/** aggregate fields of "Edition" */
export type Edition_Aggregate_Fields = {
  __typename?: 'Edition_aggregate_fields';
  avg?: Maybe<Edition_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Edition_Max_Fields>;
  min?: Maybe<Edition_Min_Fields>;
  stddev?: Maybe<Edition_Stddev_Fields>;
  stddev_pop?: Maybe<Edition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Edition_Stddev_Samp_Fields>;
  sum?: Maybe<Edition_Sum_Fields>;
  var_pop?: Maybe<Edition_Var_Pop_Fields>;
  var_samp?: Maybe<Edition_Var_Samp_Fields>;
  variance?: Maybe<Edition_Variance_Fields>;
};


/** aggregate fields of "Edition" */
export type Edition_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Edition_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Edition" */
export type Edition_Aggregate_Order_By = {
  avg?: Maybe<Edition_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Edition_Max_Order_By>;
  min?: Maybe<Edition_Min_Order_By>;
  stddev?: Maybe<Edition_Stddev_Order_By>;
  stddev_pop?: Maybe<Edition_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Edition_Stddev_Samp_Order_By>;
  sum?: Maybe<Edition_Sum_Order_By>;
  var_pop?: Maybe<Edition_Var_Pop_Order_By>;
  var_samp?: Maybe<Edition_Var_Samp_Order_By>;
  variance?: Maybe<Edition_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Edition" */
export type Edition_Arr_Rel_Insert_Input = {
  data: Array<Edition_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Edition_On_Conflict>;
};

/** aggregate avg on columns */
export type Edition_Avg_Fields = {
  __typename?: 'Edition_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_prints_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Edition" */
export type Edition_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Edition". All fields are combined with a logical 'AND'. */
export type Edition_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  Artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
  Edition_By_Sizes?: Maybe<Edition_By_Size_Bool_Exp>;
  Print_amounts?: Maybe<Print_Amounts_Bool_Exp>;
  Prints?: Maybe<Print_Bool_Exp>;
  _and?: Maybe<Array<Edition_Bool_Exp>>;
  _not?: Maybe<Edition_Bool_Exp>;
  _or?: Maybe<Array<Edition_Bool_Exp>>;
  add_edition_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Enum_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  available_prints_amount?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  is_price_public?: Maybe<Boolean_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  publish_date?: Maybe<Timestamptz_Comparison_Exp>;
  scope?: Maybe<Enum_Price_Scope_Enum_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Edition" */
export enum Edition_Constraint {
  /** unique or primary key constraint on columns "id" */
  EditionPkey = 'Edition_pkey'
}

/** Edition / Print event list */
export type Edition_Events = {
  __typename?: 'Edition_events';
  /** An object relationship */
  Artwork_transaction?: Maybe<Artwork_Transactions>;
  /** An object relationship */
  Context?: Maybe<Context>;
  /** An object relationship */
  ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type>;
  /** An object relationship */
  Edition: Edition;
  /** An object relationship */
  Edition_By_Type?: Maybe<Edition_By_Type>;
  /** An object relationship */
  Print?: Maybe<Print>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id: Scalars['bigint'];
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  order?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  print_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** Edition / Print event list */
export type Edition_EventsPayloadArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Edition_events" */
export type Edition_Events_Aggregate = {
  __typename?: 'Edition_events_aggregate';
  aggregate?: Maybe<Edition_Events_Aggregate_Fields>;
  nodes: Array<Edition_Events>;
};

/** aggregate fields of "Edition_events" */
export type Edition_Events_Aggregate_Fields = {
  __typename?: 'Edition_events_aggregate_fields';
  avg?: Maybe<Edition_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Edition_Events_Max_Fields>;
  min?: Maybe<Edition_Events_Min_Fields>;
  stddev?: Maybe<Edition_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Edition_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Edition_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Edition_Events_Sum_Fields>;
  var_pop?: Maybe<Edition_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Edition_Events_Var_Samp_Fields>;
  variance?: Maybe<Edition_Events_Variance_Fields>;
};


/** aggregate fields of "Edition_events" */
export type Edition_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Edition_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Edition_Events_Append_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Edition_Events_Avg_Fields = {
  __typename?: 'Edition_events_avg_fields';
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Edition_events". All fields are combined with a logical 'AND'. */
export type Edition_Events_Bool_Exp = {
  Artwork_transaction?: Maybe<Artwork_Transactions_Bool_Exp>;
  Context?: Maybe<Context_Bool_Exp>;
  ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type_Bool_Exp>;
  Edition?: Maybe<Edition_Bool_Exp>;
  Edition_By_Type?: Maybe<Edition_By_Type_Bool_Exp>;
  Print?: Maybe<Print_Bool_Exp>;
  _and?: Maybe<Array<Edition_Events_Bool_Exp>>;
  _not?: Maybe<Edition_Events_Bool_Exp>;
  _or?: Maybe<Array<Edition_Events_Bool_Exp>>;
  artwork_transaction_id?: Maybe<Bigint_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  edition_by_type_id?: Maybe<Bigint_Comparison_Exp>;
  edition_id?: Maybe<Bigint_Comparison_Exp>;
  event_date?: Maybe<Timestamptz_Comparison_Exp>;
  event_message?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  payload?: Maybe<Jsonb_Comparison_Exp>;
  print_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Edition_events" */
export enum Edition_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  EditionEventsPkey = 'Edition_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Edition_Events_Delete_At_Path_Input = {
  payload?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Edition_Events_Delete_Elem_Input = {
  payload?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Edition_Events_Delete_Key_Input = {
  payload?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Edition_events" */
export type Edition_Events_Inc_Input = {
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Edition_events" */
export type Edition_Events_Insert_Input = {
  Artwork_transaction?: Maybe<Artwork_Transactions_Obj_Rel_Insert_Input>;
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type_Obj_Rel_Insert_Input>;
  Edition?: Maybe<Edition_Obj_Rel_Insert_Input>;
  Edition_By_Type?: Maybe<Edition_By_Type_Obj_Rel_Insert_Input>;
  Print?: Maybe<Print_Obj_Rel_Insert_Input>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  print_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Edition_Events_Max_Fields = {
  __typename?: 'Edition_events_max_fields';
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Edition_Events_Min_Fields = {
  __typename?: 'Edition_events_min_fields';
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Edition_events" */
export type Edition_Events_Mutation_Response = {
  __typename?: 'Edition_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Edition_Events>;
};

/** on_conflict condition type for table "Edition_events" */
export type Edition_Events_On_Conflict = {
  constraint: Edition_Events_Constraint;
  update_columns?: Array<Edition_Events_Update_Column>;
  where?: Maybe<Edition_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "Edition_events". */
export type Edition_Events_Order_By = {
  Artwork_transaction?: Maybe<Artwork_Transactions_Order_By>;
  Context?: Maybe<Context_Order_By>;
  ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type_Order_By>;
  Edition?: Maybe<Edition_Order_By>;
  Edition_By_Type?: Maybe<Edition_By_Type_Order_By>;
  Print?: Maybe<Print_Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  event_message?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Edition_events */
export type Edition_Events_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Edition_Events_Prepend_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Edition_events" */
export enum Edition_Events_Select_Column {
  /** column name */
  ArtworkTransactionId = 'artwork_transaction_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EditionByTypeId = 'edition_by_type_id',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventMessage = 'event_message',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Payload = 'payload',
  /** column name */
  PrintId = 'print_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Edition_events" */
export type Edition_Events_Set_Input = {
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  print_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Edition_Events_Stddev_Fields = {
  __typename?: 'Edition_events_stddev_fields';
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Edition_Events_Stddev_Pop_Fields = {
  __typename?: 'Edition_events_stddev_pop_fields';
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Edition_Events_Stddev_Samp_Fields = {
  __typename?: 'Edition_events_stddev_samp_fields';
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Edition_Events_Sum_Fields = {
  __typename?: 'Edition_events_sum_fields';
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Edition_events" */
export enum Edition_Events_Update_Column {
  /** column name */
  ArtworkTransactionId = 'artwork_transaction_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EditionByTypeId = 'edition_by_type_id',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventMessage = 'event_message',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Payload = 'payload',
  /** column name */
  PrintId = 'print_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Edition_Events_Var_Pop_Fields = {
  __typename?: 'Edition_events_var_pop_fields';
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Edition_Events_Var_Samp_Fields = {
  __typename?: 'Edition_events_var_samp_fields';
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Edition_Events_Variance_Fields = {
  __typename?: 'Edition_events_variance_fields';
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "Edition" */
export type Edition_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  available_prints_amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "Edition" */
export type Edition_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  Artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Arr_Rel_Insert_Input>;
  Edition_By_Sizes?: Maybe<Edition_By_Size_Arr_Rel_Insert_Input>;
  Print_amounts?: Maybe<Print_Amounts_Arr_Rel_Insert_Input>;
  Prints?: Maybe<Print_Arr_Rel_Insert_Input>;
  add_edition_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Enum>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_prints_amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  is_price_public?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['float8']>;
  publish_date?: Maybe<Scalars['timestamptz']>;
  scope?: Maybe<Enum_Price_Scope_Enum>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Edition_Max_Fields = {
  __typename?: 'Edition_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_prints_amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['float8']>;
  publish_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Edition" */
export type Edition_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  currency?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
  publish_date?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Edition_Min_Fields = {
  __typename?: 'Edition_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_prints_amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['float8']>;
  publish_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Edition" */
export type Edition_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  currency?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
  publish_date?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "Edition" */
export type Edition_Mutation_Response = {
  __typename?: 'Edition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Edition>;
};

/** input type for inserting object relation for remote table "Edition" */
export type Edition_Obj_Rel_Insert_Input = {
  data: Edition_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Edition_On_Conflict>;
};

/** on_conflict condition type for table "Edition" */
export type Edition_On_Conflict = {
  constraint: Edition_Constraint;
  update_columns?: Array<Edition_Update_Column>;
  where?: Maybe<Edition_Bool_Exp>;
};

/** Ordering options when selecting data from "Edition". */
export type Edition_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  Artwork_secondary_media_aggregate?: Maybe<Artwork_Secondary_Media_Aggregate_Order_By>;
  Edition_By_Sizes_aggregate?: Maybe<Edition_By_Size_Aggregate_Order_By>;
  Print_amounts_aggregate?: Maybe<Print_Amounts_Aggregate_Order_By>;
  Prints_aggregate?: Maybe<Print_Aggregate_Order_By>;
  add_edition_flow_step?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  is_price_public?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  publish_date?: Maybe<Order_By>;
  scope?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: Edition */
export type Edition_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Edition" */
export enum Edition_Select_Column {
  /** column name */
  AddEditionFlowStep = 'add_edition_flow_step',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AvailablePrintsAmount = 'available_prints_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsPricePublic = 'is_price_public',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Price = 'price',
  /** column name */
  PublishDate = 'publish_date',
  /** column name */
  Scope = 'scope',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "Edition" */
export type Edition_Set_Input = {
  add_edition_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Enum>;
  artwork_id?: Maybe<Scalars['bigint']>;
  available_prints_amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  is_price_public?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['float8']>;
  publish_date?: Maybe<Scalars['timestamptz']>;
  scope?: Maybe<Enum_Price_Scope_Enum>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Edition_Stddev_Fields = {
  __typename?: 'Edition_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_prints_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Edition" */
export type Edition_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Edition_Stddev_Pop_Fields = {
  __typename?: 'Edition_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_prints_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Edition" */
export type Edition_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Edition_Stddev_Samp_Fields = {
  __typename?: 'Edition_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_prints_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Edition" */
export type Edition_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Edition_Sum_Fields = {
  __typename?: 'Edition_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_prints_amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "Edition" */
export type Edition_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
};

/** update columns of table "Edition" */
export enum Edition_Update_Column {
  /** column name */
  AddEditionFlowStep = 'add_edition_flow_step',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AvailablePrintsAmount = 'available_prints_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsPricePublic = 'is_price_public',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Price = 'price',
  /** column name */
  PublishDate = 'publish_date',
  /** column name */
  Scope = 'scope',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Edition_Var_Pop_Fields = {
  __typename?: 'Edition_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_prints_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Edition" */
export type Edition_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Edition_Var_Samp_Fields = {
  __typename?: 'Edition_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_prints_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Edition" */
export type Edition_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Edition_Variance_Fields = {
  __typename?: 'Edition_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_prints_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Edition" */
export type Edition_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_prints_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  /** Deprecated its use. Currently saving the column at Edition By Size table */
  price?: Maybe<Order_By>;
};

/** columns and relationships of "Email_validation_token" */
export type Email_Validation_Token = {
  __typename?: 'Email_validation_token';
  /** An object relationship */
  ENUM_email_validation_token_status: Enum_Email_Validation_Token_Status;
  action?: Maybe<Enum_Pseudo_User_Action_Enum>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  last_name?: Maybe<Scalars['String']>;
  preferred_language: Enum_Language_Preference_Enum;
  status: Enum_Email_Validation_Token_Status_Enum;
  /** An object relationship */
  stored_token: Token_Storage;
  token_storage_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Email_validation_token" */
export type Email_Validation_Token_Aggregate = {
  __typename?: 'Email_validation_token_aggregate';
  aggregate?: Maybe<Email_Validation_Token_Aggregate_Fields>;
  nodes: Array<Email_Validation_Token>;
};

/** aggregate fields of "Email_validation_token" */
export type Email_Validation_Token_Aggregate_Fields = {
  __typename?: 'Email_validation_token_aggregate_fields';
  avg?: Maybe<Email_Validation_Token_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Email_Validation_Token_Max_Fields>;
  min?: Maybe<Email_Validation_Token_Min_Fields>;
  stddev?: Maybe<Email_Validation_Token_Stddev_Fields>;
  stddev_pop?: Maybe<Email_Validation_Token_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Email_Validation_Token_Stddev_Samp_Fields>;
  sum?: Maybe<Email_Validation_Token_Sum_Fields>;
  var_pop?: Maybe<Email_Validation_Token_Var_Pop_Fields>;
  var_samp?: Maybe<Email_Validation_Token_Var_Samp_Fields>;
  variance?: Maybe<Email_Validation_Token_Variance_Fields>;
};


/** aggregate fields of "Email_validation_token" */
export type Email_Validation_Token_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Validation_Token_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Email_Validation_Token_Avg_Fields = {
  __typename?: 'Email_validation_token_avg_fields';
  id?: Maybe<Scalars['Float']>;
  token_storage_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Email_validation_token". All fields are combined with a logical 'AND'. */
export type Email_Validation_Token_Bool_Exp = {
  ENUM_email_validation_token_status?: Maybe<Enum_Email_Validation_Token_Status_Bool_Exp>;
  _and?: Maybe<Array<Email_Validation_Token_Bool_Exp>>;
  _not?: Maybe<Email_Validation_Token_Bool_Exp>;
  _or?: Maybe<Array<Email_Validation_Token_Bool_Exp>>;
  action?: Maybe<Enum_Pseudo_User_Action_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum_Comparison_Exp>;
  status?: Maybe<Enum_Email_Validation_Token_Status_Enum_Comparison_Exp>;
  stored_token?: Maybe<Token_Storage_Bool_Exp>;
  token_storage_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Email_validation_token" */
export enum Email_Validation_Token_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailValidationTokenPkey = 'Email_validation_token_pkey',
  /** unique or primary key constraint on columns "token_storage_id" */
  EmailValidationTokenTokenStorageIdKey = 'Email_validation_token_token_storage_id_key'
}

/** input type for incrementing numeric columns in table "Email_validation_token" */
export type Email_Validation_Token_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  token_storage_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Email_validation_token" */
export type Email_Validation_Token_Insert_Input = {
  ENUM_email_validation_token_status?: Maybe<Enum_Email_Validation_Token_Status_Obj_Rel_Insert_Input>;
  action?: Maybe<Enum_Pseudo_User_Action_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  status?: Maybe<Enum_Email_Validation_Token_Status_Enum>;
  stored_token?: Maybe<Token_Storage_Obj_Rel_Insert_Input>;
  token_storage_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Validation_Token_Max_Fields = {
  __typename?: 'Email_validation_token_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  token_storage_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Email_Validation_Token_Min_Fields = {
  __typename?: 'Email_validation_token_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  token_storage_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Email_validation_token" */
export type Email_Validation_Token_Mutation_Response = {
  __typename?: 'Email_validation_token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Validation_Token>;
};

/** on_conflict condition type for table "Email_validation_token" */
export type Email_Validation_Token_On_Conflict = {
  constraint: Email_Validation_Token_Constraint;
  update_columns?: Array<Email_Validation_Token_Update_Column>;
  where?: Maybe<Email_Validation_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "Email_validation_token". */
export type Email_Validation_Token_Order_By = {
  ENUM_email_validation_token_status?: Maybe<Enum_Email_Validation_Token_Status_Order_By>;
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stored_token?: Maybe<Token_Storage_Order_By>;
  token_storage_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Email_validation_token */
export type Email_Validation_Token_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Email_validation_token" */
export enum Email_Validation_Token_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  Status = 'status',
  /** column name */
  TokenStorageId = 'token_storage_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Email_validation_token" */
export type Email_Validation_Token_Set_Input = {
  action?: Maybe<Enum_Pseudo_User_Action_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  status?: Maybe<Enum_Email_Validation_Token_Status_Enum>;
  token_storage_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Email_Validation_Token_Stddev_Fields = {
  __typename?: 'Email_validation_token_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  token_storage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Email_Validation_Token_Stddev_Pop_Fields = {
  __typename?: 'Email_validation_token_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  token_storage_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Email_Validation_Token_Stddev_Samp_Fields = {
  __typename?: 'Email_validation_token_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  token_storage_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Email_Validation_Token_Sum_Fields = {
  __typename?: 'Email_validation_token_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  token_storage_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Email_validation_token" */
export enum Email_Validation_Token_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  Status = 'status',
  /** column name */
  TokenStorageId = 'token_storage_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Email_Validation_Token_Var_Pop_Fields = {
  __typename?: 'Email_validation_token_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  token_storage_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Email_Validation_Token_Var_Samp_Fields = {
  __typename?: 'Email_validation_token_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  token_storage_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Email_Validation_Token_Variance_Fields = {
  __typename?: 'Email_validation_token_variance_fields';
  id?: Maybe<Scalars['Float']>;
  token_storage_id?: Maybe<Scalars['Float']>;
};

/** Automated emails that are sent on a schedule when the user is eligible. Emails are sent by our Amazon provider and status will be updated by the latest one from our provider. */
export type Emails = {
  __typename?: 'Emails';
  /** An object relationship */
  ENUM_email_language: Enum_Email_Language;
  attachments?: Maybe<Scalars['String']>;
  /** An object relationship */
  context?: Maybe<Context>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  extra?: Maybe<Scalars['jsonb']>;
  from_email: Scalars['String'];
  from_name: Scalars['String'];
  id: Scalars['bigint'];
  language: Enum_Email_Language_Enum;
  mail_service: Scalars['String'];
  message: Scalars['String'];
  priority: Scalars['smallint'];
  send_at?: Maybe<Scalars['timestamptz']>;
  ses_id?: Maybe<Scalars['String']>;
  /** Is updated by the last status of an email. delivered < open < click */
  status: Enum_Email_Status_Enum;
  status_at: Scalars['timestamptz'];
  status_counter: Scalars['Int'];
  statuses?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  to_email: Scalars['String'];
  to_name: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Automated emails that are sent on a schedule when the user is eligible. Emails are sent by our Amazon provider and status will be updated by the latest one from our provider. */
export type EmailsExtraArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Emails" */
export type Emails_Aggregate = {
  __typename?: 'Emails_aggregate';
  aggregate?: Maybe<Emails_Aggregate_Fields>;
  nodes: Array<Emails>;
};

/** aggregate fields of "Emails" */
export type Emails_Aggregate_Fields = {
  __typename?: 'Emails_aggregate_fields';
  avg?: Maybe<Emails_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Emails_Max_Fields>;
  min?: Maybe<Emails_Min_Fields>;
  stddev?: Maybe<Emails_Stddev_Fields>;
  stddev_pop?: Maybe<Emails_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Emails_Stddev_Samp_Fields>;
  sum?: Maybe<Emails_Sum_Fields>;
  var_pop?: Maybe<Emails_Var_Pop_Fields>;
  var_samp?: Maybe<Emails_Var_Samp_Fields>;
  variance?: Maybe<Emails_Variance_Fields>;
};


/** aggregate fields of "Emails" */
export type Emails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Emails_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Emails" */
export type Emails_Aggregate_Order_By = {
  avg?: Maybe<Emails_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Emails_Max_Order_By>;
  min?: Maybe<Emails_Min_Order_By>;
  stddev?: Maybe<Emails_Stddev_Order_By>;
  stddev_pop?: Maybe<Emails_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Emails_Stddev_Samp_Order_By>;
  sum?: Maybe<Emails_Sum_Order_By>;
  var_pop?: Maybe<Emails_Var_Pop_Order_By>;
  var_samp?: Maybe<Emails_Var_Samp_Order_By>;
  variance?: Maybe<Emails_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Emails_Append_Input = {
  extra?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Emails" */
export type Emails_Arr_Rel_Insert_Input = {
  data: Array<Emails_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Emails_On_Conflict>;
};

/** aggregate avg on columns */
export type Emails_Avg_Fields = {
  __typename?: 'Emails_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  status_counter?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Emails" */
export type Emails_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Emails". All fields are combined with a logical 'AND'. */
export type Emails_Bool_Exp = {
  ENUM_email_language?: Maybe<Enum_Email_Language_Bool_Exp>;
  _and?: Maybe<Array<Emails_Bool_Exp>>;
  _not?: Maybe<Emails_Bool_Exp>;
  _or?: Maybe<Array<Emails_Bool_Exp>>;
  attachments?: Maybe<String_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  extra?: Maybe<Jsonb_Comparison_Exp>;
  from_email?: Maybe<String_Comparison_Exp>;
  from_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  language?: Maybe<Enum_Email_Language_Enum_Comparison_Exp>;
  mail_service?: Maybe<String_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  priority?: Maybe<Smallint_Comparison_Exp>;
  send_at?: Maybe<Timestamptz_Comparison_Exp>;
  ses_id?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Enum_Email_Status_Enum_Comparison_Exp>;
  status_at?: Maybe<Timestamptz_Comparison_Exp>;
  status_counter?: Maybe<Int_Comparison_Exp>;
  statuses?: Maybe<String_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  to_email?: Maybe<String_Comparison_Exp>;
  to_name?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Emails" */
export enum Emails_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailsPkey = 'Emails_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Emails_Delete_At_Path_Input = {
  extra?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Emails_Delete_Elem_Input = {
  extra?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Emails_Delete_Key_Input = {
  extra?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Emails" */
export type Emails_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  priority?: Maybe<Scalars['smallint']>;
  status_counter?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Emails" */
export type Emails_Insert_Input = {
  ENUM_email_language?: Maybe<Enum_Email_Language_Obj_Rel_Insert_Input>;
  attachments?: Maybe<Scalars['String']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  extra?: Maybe<Scalars['jsonb']>;
  from_email?: Maybe<Scalars['String']>;
  from_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Enum_Email_Language_Enum>;
  mail_service?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
  send_at?: Maybe<Scalars['timestamptz']>;
  ses_id?: Maybe<Scalars['String']>;
  /** Is updated by the last status of an email. delivered < open < click */
  status?: Maybe<Enum_Email_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  status_counter?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to_email?: Maybe<Scalars['String']>;
  to_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Emails_Max_Fields = {
  __typename?: 'Emails_max_fields';
  attachments?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_email?: Maybe<Scalars['String']>;
  from_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mail_service?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
  send_at?: Maybe<Scalars['timestamptz']>;
  ses_id?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  status_counter?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to_email?: Maybe<Scalars['String']>;
  to_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Emails" */
export type Emails_Max_Order_By = {
  attachments?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from_email?: Maybe<Order_By>;
  from_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mail_service?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  send_at?: Maybe<Order_By>;
  ses_id?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to_email?: Maybe<Order_By>;
  to_name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Emails_Min_Fields = {
  __typename?: 'Emails_min_fields';
  attachments?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from_email?: Maybe<Scalars['String']>;
  from_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mail_service?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
  send_at?: Maybe<Scalars['timestamptz']>;
  ses_id?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  status_counter?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to_email?: Maybe<Scalars['String']>;
  to_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Emails" */
export type Emails_Min_Order_By = {
  attachments?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from_email?: Maybe<Order_By>;
  from_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mail_service?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  send_at?: Maybe<Order_By>;
  ses_id?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to_email?: Maybe<Order_By>;
  to_name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Emails" */
export type Emails_Mutation_Response = {
  __typename?: 'Emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Emails>;
};

/** on_conflict condition type for table "Emails" */
export type Emails_On_Conflict = {
  constraint: Emails_Constraint;
  update_columns?: Array<Emails_Update_Column>;
  where?: Maybe<Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "Emails". */
export type Emails_Order_By = {
  ENUM_email_language?: Maybe<Enum_Email_Language_Order_By>;
  attachments?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  extra?: Maybe<Order_By>;
  from_email?: Maybe<Order_By>;
  from_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  mail_service?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  send_at?: Maybe<Order_By>;
  ses_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to_email?: Maybe<Order_By>;
  to_name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Emails */
export type Emails_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Emails_Prepend_Input = {
  extra?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Emails" */
export enum Emails_Select_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Extra = 'extra',
  /** column name */
  FromEmail = 'from_email',
  /** column name */
  FromName = 'from_name',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  MailService = 'mail_service',
  /** column name */
  Message = 'message',
  /** column name */
  Priority = 'priority',
  /** column name */
  SendAt = 'send_at',
  /** column name */
  SesId = 'ses_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  StatusCounter = 'status_counter',
  /** column name */
  Statuses = 'statuses',
  /** column name */
  Subject = 'subject',
  /** column name */
  ToEmail = 'to_email',
  /** column name */
  ToName = 'to_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Emails" */
export type Emails_Set_Input = {
  attachments?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  extra?: Maybe<Scalars['jsonb']>;
  from_email?: Maybe<Scalars['String']>;
  from_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Enum_Email_Language_Enum>;
  mail_service?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['smallint']>;
  send_at?: Maybe<Scalars['timestamptz']>;
  ses_id?: Maybe<Scalars['String']>;
  /** Is updated by the last status of an email. delivered < open < click */
  status?: Maybe<Enum_Email_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  status_counter?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to_email?: Maybe<Scalars['String']>;
  to_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Emails_Stddev_Fields = {
  __typename?: 'Emails_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  status_counter?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Emails" */
export type Emails_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Emails_Stddev_Pop_Fields = {
  __typename?: 'Emails_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  status_counter?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Emails" */
export type Emails_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Emails_Stddev_Samp_Fields = {
  __typename?: 'Emails_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  status_counter?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Emails" */
export type Emails_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Emails_Sum_Fields = {
  __typename?: 'Emails_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  priority?: Maybe<Scalars['smallint']>;
  status_counter?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Emails" */
export type Emails_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
};

/** update columns of table "Emails" */
export enum Emails_Update_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Extra = 'extra',
  /** column name */
  FromEmail = 'from_email',
  /** column name */
  FromName = 'from_name',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  MailService = 'mail_service',
  /** column name */
  Message = 'message',
  /** column name */
  Priority = 'priority',
  /** column name */
  SendAt = 'send_at',
  /** column name */
  SesId = 'ses_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  StatusCounter = 'status_counter',
  /** column name */
  Statuses = 'statuses',
  /** column name */
  Subject = 'subject',
  /** column name */
  ToEmail = 'to_email',
  /** column name */
  ToName = 'to_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Emails_Var_Pop_Fields = {
  __typename?: 'Emails_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  status_counter?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Emails" */
export type Emails_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Emails_Var_Samp_Fields = {
  __typename?: 'Emails_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  status_counter?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Emails" */
export type Emails_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Emails_Variance_Fields = {
  __typename?: 'Emails_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
  status_counter?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Emails" */
export type Emails_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status_counter?: Maybe<Order_By>;
};

/** columns and relationships of "Explore_artists" */
export type Explore_Artists = {
  __typename?: 'Explore_artists';
  artist_title?: Maybe<Scalars['String']>;
  /** An array relationship */
  artworks: Array<Artwork>;
  /** An aggregate relationship */
  artworks_aggregate: Artwork_Aggregate;
  artworks_count?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  cardGridArtworks: Array<Artist_Cardgrid_Artworks_View>;
  /** An aggregate relationship */
  cardGridArtworks_aggregate: Artist_Cardgrid_Artworks_View_Aggregate;
  /** An object relationship */
  context?: Maybe<Context>;
  context_created_at?: Maybe<Scalars['timestamptz']>;
  context_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  disciplines: Array<Explore_Artists_Disciplines>;
  /** An aggregate relationship */
  disciplines_aggregate: Explore_Artists_Disciplines_Aggregate;
  /** An array relationship */
  exploreArtworks: Array<Explore_Artists_Artworks>;
  /** An aggregate relationship */
  exploreArtworks_aggregate: Explore_Artists_Artworks_Aggregate;
  region?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "Explore_artists" */
export type Explore_ArtistsArtworksArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


/** columns and relationships of "Explore_artists" */
export type Explore_ArtistsArtworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


/** columns and relationships of "Explore_artists" */
export type Explore_ArtistsCardGridArtworksArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


/** columns and relationships of "Explore_artists" */
export type Explore_ArtistsCardGridArtworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


/** columns and relationships of "Explore_artists" */
export type Explore_ArtistsDisciplinesArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Disciplines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Disciplines_Order_By>>;
  where?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
};


/** columns and relationships of "Explore_artists" */
export type Explore_ArtistsDisciplines_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Disciplines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Disciplines_Order_By>>;
  where?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
};


/** columns and relationships of "Explore_artists" */
export type Explore_ArtistsExploreArtworksArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Artworks_Order_By>>;
  where?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
};


/** columns and relationships of "Explore_artists" */
export type Explore_ArtistsExploreArtworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Artworks_Order_By>>;
  where?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
};

/** aggregated selection of "Explore_artists" */
export type Explore_Artists_Aggregate = {
  __typename?: 'Explore_artists_aggregate';
  aggregate?: Maybe<Explore_Artists_Aggregate_Fields>;
  nodes: Array<Explore_Artists>;
};

/** aggregate fields of "Explore_artists" */
export type Explore_Artists_Aggregate_Fields = {
  __typename?: 'Explore_artists_aggregate_fields';
  avg?: Maybe<Explore_Artists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Explore_Artists_Max_Fields>;
  min?: Maybe<Explore_Artists_Min_Fields>;
  stddev?: Maybe<Explore_Artists_Stddev_Fields>;
  stddev_pop?: Maybe<Explore_Artists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Explore_Artists_Stddev_Samp_Fields>;
  sum?: Maybe<Explore_Artists_Sum_Fields>;
  var_pop?: Maybe<Explore_Artists_Var_Pop_Fields>;
  var_samp?: Maybe<Explore_Artists_Var_Samp_Fields>;
  variance?: Maybe<Explore_Artists_Variance_Fields>;
};


/** aggregate fields of "Explore_artists" */
export type Explore_Artists_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Explore_Artists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "Explore_artists_artworks" */
export type Explore_Artists_Artworks = {
  __typename?: 'Explore_artists_artworks';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Aggregate = {
  __typename?: 'Explore_artists_artworks_aggregate';
  aggregate?: Maybe<Explore_Artists_Artworks_Aggregate_Fields>;
  nodes: Array<Explore_Artists_Artworks>;
};

/** aggregate fields of "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Aggregate_Fields = {
  __typename?: 'Explore_artists_artworks_aggregate_fields';
  avg?: Maybe<Explore_Artists_Artworks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Explore_Artists_Artworks_Max_Fields>;
  min?: Maybe<Explore_Artists_Artworks_Min_Fields>;
  stddev?: Maybe<Explore_Artists_Artworks_Stddev_Fields>;
  stddev_pop?: Maybe<Explore_Artists_Artworks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Explore_Artists_Artworks_Stddev_Samp_Fields>;
  sum?: Maybe<Explore_Artists_Artworks_Sum_Fields>;
  var_pop?: Maybe<Explore_Artists_Artworks_Var_Pop_Fields>;
  var_samp?: Maybe<Explore_Artists_Artworks_Var_Samp_Fields>;
  variance?: Maybe<Explore_Artists_Artworks_Variance_Fields>;
};


/** aggregate fields of "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Explore_Artists_Artworks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Aggregate_Order_By = {
  avg?: Maybe<Explore_Artists_Artworks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Explore_Artists_Artworks_Max_Order_By>;
  min?: Maybe<Explore_Artists_Artworks_Min_Order_By>;
  stddev?: Maybe<Explore_Artists_Artworks_Stddev_Order_By>;
  stddev_pop?: Maybe<Explore_Artists_Artworks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Explore_Artists_Artworks_Stddev_Samp_Order_By>;
  sum?: Maybe<Explore_Artists_Artworks_Sum_Order_By>;
  var_pop?: Maybe<Explore_Artists_Artworks_Var_Pop_Order_By>;
  var_samp?: Maybe<Explore_Artists_Artworks_Var_Samp_Order_By>;
  variance?: Maybe<Explore_Artists_Artworks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Arr_Rel_Insert_Input = {
  data: Array<Explore_Artists_Artworks_Insert_Input>;
};

/** aggregate avg on columns */
export type Explore_Artists_Artworks_Avg_Fields = {
  __typename?: 'Explore_artists_artworks_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Avg_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Explore_artists_artworks". All fields are combined with a logical 'AND'. */
export type Explore_Artists_Artworks_Bool_Exp = {
  _and?: Maybe<Array<Explore_Artists_Artworks_Bool_Exp>>;
  _not?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
  _or?: Maybe<Array<Explore_Artists_Artworks_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Insert_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Explore_Artists_Artworks_Max_Fields = {
  __typename?: 'Explore_artists_artworks_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Max_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Explore_Artists_Artworks_Min_Fields = {
  __typename?: 'Explore_artists_artworks_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Min_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** input type for inserting object relation for remote table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Obj_Rel_Insert_Input = {
  data: Explore_Artists_Artworks_Insert_Input;
};

/** Ordering options when selecting data from "Explore_artists_artworks". */
export type Explore_Artists_Artworks_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Explore_artists_artworks" */
export enum Explore_Artists_Artworks_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Explore_Artists_Artworks_Stddev_Fields = {
  __typename?: 'Explore_artists_artworks_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Stddev_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Explore_Artists_Artworks_Stddev_Pop_Fields = {
  __typename?: 'Explore_artists_artworks_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Stddev_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Explore_Artists_Artworks_Stddev_Samp_Fields = {
  __typename?: 'Explore_artists_artworks_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Stddev_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Explore_Artists_Artworks_Sum_Fields = {
  __typename?: 'Explore_artists_artworks_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Sum_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Explore_Artists_Artworks_Var_Pop_Fields = {
  __typename?: 'Explore_artists_artworks_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Var_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Explore_Artists_Artworks_Var_Samp_Fields = {
  __typename?: 'Explore_artists_artworks_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Var_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Explore_Artists_Artworks_Variance_Fields = {
  __typename?: 'Explore_artists_artworks_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Explore_artists_artworks" */
export type Explore_Artists_Artworks_Variance_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate avg on columns */
export type Explore_Artists_Avg_Fields = {
  __typename?: 'Explore_artists_avg_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Explore_artists". All fields are combined with a logical 'AND'. */
export type Explore_Artists_Bool_Exp = {
  _and?: Maybe<Array<Explore_Artists_Bool_Exp>>;
  _not?: Maybe<Explore_Artists_Bool_Exp>;
  _or?: Maybe<Array<Explore_Artists_Bool_Exp>>;
  artist_title?: Maybe<String_Comparison_Exp>;
  artworks?: Maybe<Artwork_Bool_Exp>;
  artworks_count?: Maybe<Bigint_Comparison_Exp>;
  cardGridArtworks?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  disciplines?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
  exploreArtworks?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  total_points?: Maybe<Numeric_Comparison_Exp>;
};

/** columns and relationships of "Explore_artists_curation_score" */
export type Explore_Artists_Curation_Score = {
  __typename?: 'Explore_artists_curation_score';
  artist_title?: Maybe<Scalars['String']>;
  artworks_count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  context?: Maybe<Context>;
  context_created_at?: Maybe<Scalars['timestamptz']>;
  context_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  disciplines: Array<Explore_Artists_Disciplines>;
  /** An aggregate relationship */
  disciplines_aggregate: Explore_Artists_Disciplines_Aggregate;
  /** An array relationship */
  exploreArtworks: Array<Explore_Artists_Artworks>;
  /** An aggregate relationship */
  exploreArtworks_aggregate: Explore_Artists_Artworks_Aggregate;
  region?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "Explore_artists_curation_score" */
export type Explore_Artists_Curation_ScoreDisciplinesArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Disciplines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Disciplines_Order_By>>;
  where?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
};


/** columns and relationships of "Explore_artists_curation_score" */
export type Explore_Artists_Curation_ScoreDisciplines_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Disciplines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Disciplines_Order_By>>;
  where?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
};


/** columns and relationships of "Explore_artists_curation_score" */
export type Explore_Artists_Curation_ScoreExploreArtworksArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Artworks_Order_By>>;
  where?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
};


/** columns and relationships of "Explore_artists_curation_score" */
export type Explore_Artists_Curation_ScoreExploreArtworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Artworks_Order_By>>;
  where?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
};

/** aggregated selection of "Explore_artists_curation_score" */
export type Explore_Artists_Curation_Score_Aggregate = {
  __typename?: 'Explore_artists_curation_score_aggregate';
  aggregate?: Maybe<Explore_Artists_Curation_Score_Aggregate_Fields>;
  nodes: Array<Explore_Artists_Curation_Score>;
};

/** aggregate fields of "Explore_artists_curation_score" */
export type Explore_Artists_Curation_Score_Aggregate_Fields = {
  __typename?: 'Explore_artists_curation_score_aggregate_fields';
  avg?: Maybe<Explore_Artists_Curation_Score_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Explore_Artists_Curation_Score_Max_Fields>;
  min?: Maybe<Explore_Artists_Curation_Score_Min_Fields>;
  stddev?: Maybe<Explore_Artists_Curation_Score_Stddev_Fields>;
  stddev_pop?: Maybe<Explore_Artists_Curation_Score_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Explore_Artists_Curation_Score_Stddev_Samp_Fields>;
  sum?: Maybe<Explore_Artists_Curation_Score_Sum_Fields>;
  var_pop?: Maybe<Explore_Artists_Curation_Score_Var_Pop_Fields>;
  var_samp?: Maybe<Explore_Artists_Curation_Score_Var_Samp_Fields>;
  variance?: Maybe<Explore_Artists_Curation_Score_Variance_Fields>;
};


/** aggregate fields of "Explore_artists_curation_score" */
export type Explore_Artists_Curation_Score_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Explore_Artists_Curation_Score_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Explore_Artists_Curation_Score_Avg_Fields = {
  __typename?: 'Explore_artists_curation_score_avg_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Explore_artists_curation_score". All fields are combined with a logical 'AND'. */
export type Explore_Artists_Curation_Score_Bool_Exp = {
  _and?: Maybe<Array<Explore_Artists_Curation_Score_Bool_Exp>>;
  _not?: Maybe<Explore_Artists_Curation_Score_Bool_Exp>;
  _or?: Maybe<Array<Explore_Artists_Curation_Score_Bool_Exp>>;
  artist_title?: Maybe<String_Comparison_Exp>;
  artworks_count?: Maybe<Bigint_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  disciplines?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
  exploreArtworks?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  total_points?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Explore_Artists_Curation_Score_Max_Fields = {
  __typename?: 'Explore_artists_curation_score_max_fields';
  artist_title?: Maybe<Scalars['String']>;
  artworks_count?: Maybe<Scalars['bigint']>;
  context_created_at?: Maybe<Scalars['timestamptz']>;
  context_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Explore_Artists_Curation_Score_Min_Fields = {
  __typename?: 'Explore_artists_curation_score_min_fields';
  artist_title?: Maybe<Scalars['String']>;
  artworks_count?: Maybe<Scalars['bigint']>;
  context_created_at?: Maybe<Scalars['timestamptz']>;
  context_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "Explore_artists_curation_score". */
export type Explore_Artists_Curation_Score_Order_By = {
  artist_title?: Maybe<Order_By>;
  artworks_count?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_created_at?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  disciplines_aggregate?: Maybe<Explore_Artists_Disciplines_Aggregate_Order_By>;
  exploreArtworks_aggregate?: Maybe<Explore_Artists_Artworks_Aggregate_Order_By>;
  region?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** select columns of table "Explore_artists_curation_score" */
export enum Explore_Artists_Curation_Score_Select_Column {
  /** column name */
  ArtistTitle = 'artist_title',
  /** column name */
  ArtworksCount = 'artworks_count',
  /** column name */
  ContextCreatedAt = 'context_created_at',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Region = 'region',
  /** column name */
  Score = 'score',
  /** column name */
  TotalPoints = 'total_points'
}

/** aggregate stddev on columns */
export type Explore_Artists_Curation_Score_Stddev_Fields = {
  __typename?: 'Explore_artists_curation_score_stddev_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Explore_Artists_Curation_Score_Stddev_Pop_Fields = {
  __typename?: 'Explore_artists_curation_score_stddev_pop_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Explore_Artists_Curation_Score_Stddev_Samp_Fields = {
  __typename?: 'Explore_artists_curation_score_stddev_samp_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Explore_Artists_Curation_Score_Sum_Fields = {
  __typename?: 'Explore_artists_curation_score_sum_fields';
  artworks_count?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Explore_Artists_Curation_Score_Var_Pop_Fields = {
  __typename?: 'Explore_artists_curation_score_var_pop_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Explore_Artists_Curation_Score_Var_Samp_Fields = {
  __typename?: 'Explore_artists_curation_score_var_samp_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Explore_Artists_Curation_Score_Variance_Fields = {
  __typename?: 'Explore_artists_curation_score_variance_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines = {
  __typename?: 'Explore_artists_disciplines';
  /** An object relationship */
  context?: Maybe<Context>;
  context_id?: Maybe<Scalars['bigint']>;
  discipline?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Aggregate = {
  __typename?: 'Explore_artists_disciplines_aggregate';
  aggregate?: Maybe<Explore_Artists_Disciplines_Aggregate_Fields>;
  nodes: Array<Explore_Artists_Disciplines>;
};

/** aggregate fields of "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Aggregate_Fields = {
  __typename?: 'Explore_artists_disciplines_aggregate_fields';
  avg?: Maybe<Explore_Artists_Disciplines_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Explore_Artists_Disciplines_Max_Fields>;
  min?: Maybe<Explore_Artists_Disciplines_Min_Fields>;
  stddev?: Maybe<Explore_Artists_Disciplines_Stddev_Fields>;
  stddev_pop?: Maybe<Explore_Artists_Disciplines_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Explore_Artists_Disciplines_Stddev_Samp_Fields>;
  sum?: Maybe<Explore_Artists_Disciplines_Sum_Fields>;
  var_pop?: Maybe<Explore_Artists_Disciplines_Var_Pop_Fields>;
  var_samp?: Maybe<Explore_Artists_Disciplines_Var_Samp_Fields>;
  variance?: Maybe<Explore_Artists_Disciplines_Variance_Fields>;
};


/** aggregate fields of "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Explore_Artists_Disciplines_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Aggregate_Order_By = {
  avg?: Maybe<Explore_Artists_Disciplines_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Explore_Artists_Disciplines_Max_Order_By>;
  min?: Maybe<Explore_Artists_Disciplines_Min_Order_By>;
  stddev?: Maybe<Explore_Artists_Disciplines_Stddev_Order_By>;
  stddev_pop?: Maybe<Explore_Artists_Disciplines_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Explore_Artists_Disciplines_Stddev_Samp_Order_By>;
  sum?: Maybe<Explore_Artists_Disciplines_Sum_Order_By>;
  var_pop?: Maybe<Explore_Artists_Disciplines_Var_Pop_Order_By>;
  var_samp?: Maybe<Explore_Artists_Disciplines_Var_Samp_Order_By>;
  variance?: Maybe<Explore_Artists_Disciplines_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Arr_Rel_Insert_Input = {
  data: Array<Explore_Artists_Disciplines_Insert_Input>;
};

/** aggregate avg on columns */
export type Explore_Artists_Disciplines_Avg_Fields = {
  __typename?: 'Explore_artists_disciplines_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Explore_artists_disciplines". All fields are combined with a logical 'AND'. */
export type Explore_Artists_Disciplines_Bool_Exp = {
  _and?: Maybe<Array<Explore_Artists_Disciplines_Bool_Exp>>;
  _not?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
  _or?: Maybe<Array<Explore_Artists_Disciplines_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  discipline?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Explore_Artists_Disciplines_Max_Fields = {
  __typename?: 'Explore_artists_disciplines_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  discipline?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Explore_Artists_Disciplines_Min_Fields = {
  __typename?: 'Explore_artists_disciplines_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  discipline?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "Explore_artists_disciplines". */
export type Explore_Artists_Disciplines_Order_By = {
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
};

/** select columns of table "Explore_artists_disciplines" */
export enum Explore_Artists_Disciplines_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Discipline = 'discipline'
}

/** aggregate stddev on columns */
export type Explore_Artists_Disciplines_Stddev_Fields = {
  __typename?: 'Explore_artists_disciplines_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Explore_Artists_Disciplines_Stddev_Pop_Fields = {
  __typename?: 'Explore_artists_disciplines_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Explore_Artists_Disciplines_Stddev_Samp_Fields = {
  __typename?: 'Explore_artists_disciplines_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Explore_Artists_Disciplines_Sum_Fields = {
  __typename?: 'Explore_artists_disciplines_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Explore_Artists_Disciplines_Var_Pop_Fields = {
  __typename?: 'Explore_artists_disciplines_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Explore_Artists_Disciplines_Var_Samp_Fields = {
  __typename?: 'Explore_artists_disciplines_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Explore_Artists_Disciplines_Variance_Fields = {
  __typename?: 'Explore_artists_disciplines_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Explore_artists_disciplines" */
export type Explore_Artists_Disciplines_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
};

/** input type for inserting data into table "Explore_artists" */
export type Explore_Artists_Insert_Input = {
  artist_title?: Maybe<Scalars['String']>;
  artworks?: Maybe<Artwork_Arr_Rel_Insert_Input>;
  artworks_count?: Maybe<Scalars['bigint']>;
  cardGridArtworks?: Maybe<Artist_Cardgrid_Artworks_View_Arr_Rel_Insert_Input>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_created_at?: Maybe<Scalars['timestamptz']>;
  context_id?: Maybe<Scalars['bigint']>;
  disciplines?: Maybe<Explore_Artists_Disciplines_Arr_Rel_Insert_Input>;
  exploreArtworks?: Maybe<Explore_Artists_Artworks_Arr_Rel_Insert_Input>;
  region?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Explore_Artists_Max_Fields = {
  __typename?: 'Explore_artists_max_fields';
  artist_title?: Maybe<Scalars['String']>;
  artworks_count?: Maybe<Scalars['bigint']>;
  context_created_at?: Maybe<Scalars['timestamptz']>;
  context_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Explore_Artists_Min_Fields = {
  __typename?: 'Explore_artists_min_fields';
  artist_title?: Maybe<Scalars['String']>;
  artworks_count?: Maybe<Scalars['bigint']>;
  context_created_at?: Maybe<Scalars['timestamptz']>;
  context_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** input type for inserting object relation for remote table "Explore_artists" */
export type Explore_Artists_Obj_Rel_Insert_Input = {
  data: Explore_Artists_Insert_Input;
};

/** Ordering options when selecting data from "Explore_artists". */
export type Explore_Artists_Order_By = {
  artist_title?: Maybe<Order_By>;
  artworks_aggregate?: Maybe<Artwork_Aggregate_Order_By>;
  artworks_count?: Maybe<Order_By>;
  cardGridArtworks_aggregate?: Maybe<Artist_Cardgrid_Artworks_View_Aggregate_Order_By>;
  context?: Maybe<Context_Order_By>;
  context_created_at?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  disciplines_aggregate?: Maybe<Explore_Artists_Disciplines_Aggregate_Order_By>;
  exploreArtworks_aggregate?: Maybe<Explore_Artists_Artworks_Aggregate_Order_By>;
  region?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** select columns of table "Explore_artists" */
export enum Explore_Artists_Select_Column {
  /** column name */
  ArtistTitle = 'artist_title',
  /** column name */
  ArtworksCount = 'artworks_count',
  /** column name */
  ContextCreatedAt = 'context_created_at',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Region = 'region',
  /** column name */
  Score = 'score',
  /** column name */
  TotalPoints = 'total_points'
}

/** aggregate stddev on columns */
export type Explore_Artists_Stddev_Fields = {
  __typename?: 'Explore_artists_stddev_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Explore_Artists_Stddev_Pop_Fields = {
  __typename?: 'Explore_artists_stddev_pop_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Explore_Artists_Stddev_Samp_Fields = {
  __typename?: 'Explore_artists_stddev_samp_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Explore_Artists_Sum_Fields = {
  __typename?: 'Explore_artists_sum_fields';
  artworks_count?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Explore_Artists_Var_Pop_Fields = {
  __typename?: 'Explore_artists_var_pop_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Explore_Artists_Var_Samp_Fields = {
  __typename?: 'Explore_artists_var_samp_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Explore_Artists_Variance_Fields = {
  __typename?: 'Explore_artists_variance_fields';
  artworks_count?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

export type FcmToken = {
  __typename?: 'FCMToken';
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  token?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
};

/** This will be used to store Firebase messaging token for push notifications */
export type Fcm_Token = {
  __typename?: 'FCM_Token';
  /** An object relationship */
  Context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  platform: Scalars['String'];
  token: Scalars['String'];
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "FCM_Token" */
export type Fcm_Token_Aggregate = {
  __typename?: 'FCM_Token_aggregate';
  aggregate?: Maybe<Fcm_Token_Aggregate_Fields>;
  nodes: Array<Fcm_Token>;
};

/** aggregate fields of "FCM_Token" */
export type Fcm_Token_Aggregate_Fields = {
  __typename?: 'FCM_Token_aggregate_fields';
  avg?: Maybe<Fcm_Token_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fcm_Token_Max_Fields>;
  min?: Maybe<Fcm_Token_Min_Fields>;
  stddev?: Maybe<Fcm_Token_Stddev_Fields>;
  stddev_pop?: Maybe<Fcm_Token_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fcm_Token_Stddev_Samp_Fields>;
  sum?: Maybe<Fcm_Token_Sum_Fields>;
  var_pop?: Maybe<Fcm_Token_Var_Pop_Fields>;
  var_samp?: Maybe<Fcm_Token_Var_Samp_Fields>;
  variance?: Maybe<Fcm_Token_Variance_Fields>;
};


/** aggregate fields of "FCM_Token" */
export type Fcm_Token_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fcm_Token_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "FCM_Token" */
export type Fcm_Token_Aggregate_Order_By = {
  avg?: Maybe<Fcm_Token_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Fcm_Token_Max_Order_By>;
  min?: Maybe<Fcm_Token_Min_Order_By>;
  stddev?: Maybe<Fcm_Token_Stddev_Order_By>;
  stddev_pop?: Maybe<Fcm_Token_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Fcm_Token_Stddev_Samp_Order_By>;
  sum?: Maybe<Fcm_Token_Sum_Order_By>;
  var_pop?: Maybe<Fcm_Token_Var_Pop_Order_By>;
  var_samp?: Maybe<Fcm_Token_Var_Samp_Order_By>;
  variance?: Maybe<Fcm_Token_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "FCM_Token" */
export type Fcm_Token_Arr_Rel_Insert_Input = {
  data: Array<Fcm_Token_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Fcm_Token_On_Conflict>;
};

/** aggregate avg on columns */
export type Fcm_Token_Avg_Fields = {
  __typename?: 'FCM_Token_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "FCM_Token" */
export type Fcm_Token_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "FCM_Token". All fields are combined with a logical 'AND'. */
export type Fcm_Token_Bool_Exp = {
  Context?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<Fcm_Token_Bool_Exp>>;
  _not?: Maybe<Fcm_Token_Bool_Exp>;
  _or?: Maybe<Array<Fcm_Token_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deactivated_at?: Maybe<Timestamptz_Comparison_Exp>;
  expired_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  platform?: Maybe<String_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "FCM_Token" */
export enum Fcm_Token_Constraint {
  /** unique or primary key constraint on columns "context_id", "platform" */
  FcmTokenContextIdPlatformKey = 'FCM_Token_context_id_platform_key',
  /** unique or primary key constraint on columns "id" */
  FcmTokenPkey = 'FCM_Token_pkey'
}

/** input type for incrementing numeric columns in table "FCM_Token" */
export type Fcm_Token_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "FCM_Token" */
export type Fcm_Token_Insert_Input = {
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Fcm_Token_Max_Fields = {
  __typename?: 'FCM_Token_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "FCM_Token" */
export type Fcm_Token_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deactivated_at?: Maybe<Order_By>;
  expired_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Fcm_Token_Min_Fields = {
  __typename?: 'FCM_Token_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "FCM_Token" */
export type Fcm_Token_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deactivated_at?: Maybe<Order_By>;
  expired_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "FCM_Token" */
export type Fcm_Token_Mutation_Response = {
  __typename?: 'FCM_Token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fcm_Token>;
};

/** on_conflict condition type for table "FCM_Token" */
export type Fcm_Token_On_Conflict = {
  constraint: Fcm_Token_Constraint;
  update_columns?: Array<Fcm_Token_Update_Column>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "FCM_Token". */
export type Fcm_Token_Order_By = {
  Context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deactivated_at?: Maybe<Order_By>;
  expired_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: FCM_Token */
export type Fcm_Token_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "FCM_Token" */
export enum Fcm_Token_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  Token = 'token',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "FCM_Token" */
export type Fcm_Token_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Fcm_Token_Stddev_Fields = {
  __typename?: 'FCM_Token_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "FCM_Token" */
export type Fcm_Token_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fcm_Token_Stddev_Pop_Fields = {
  __typename?: 'FCM_Token_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "FCM_Token" */
export type Fcm_Token_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fcm_Token_Stddev_Samp_Fields = {
  __typename?: 'FCM_Token_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "FCM_Token" */
export type Fcm_Token_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Fcm_Token_Sum_Fields = {
  __typename?: 'FCM_Token_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "FCM_Token" */
export type Fcm_Token_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "FCM_Token" */
export enum Fcm_Token_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedAt = 'deactivated_at',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  Platform = 'platform',
  /** column name */
  Token = 'token',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Fcm_Token_Var_Pop_Fields = {
  __typename?: 'FCM_Token_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "FCM_Token" */
export type Fcm_Token_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fcm_Token_Var_Samp_Fields = {
  __typename?: 'FCM_Token_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "FCM_Token" */
export type Fcm_Token_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Fcm_Token_Variance_Fields = {
  __typename?: 'FCM_Token_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "FCM_Token" */
export type Fcm_Token_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Used for A/B Testing */
export type Features = {
  __typename?: 'Features';
  app_access_context_Id: Scalars['bigint'];
  /** An object relationship */
  context_id?: Maybe<Context>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  feature_flag: Scalars['String'];
  flag_status: Scalars['String'];
  id: Scalars['bigint'];
};

/** aggregated selection of "Features" */
export type Features_Aggregate = {
  __typename?: 'Features_aggregate';
  aggregate?: Maybe<Features_Aggregate_Fields>;
  nodes: Array<Features>;
};

/** aggregate fields of "Features" */
export type Features_Aggregate_Fields = {
  __typename?: 'Features_aggregate_fields';
  avg?: Maybe<Features_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Features_Max_Fields>;
  min?: Maybe<Features_Min_Fields>;
  stddev?: Maybe<Features_Stddev_Fields>;
  stddev_pop?: Maybe<Features_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Features_Stddev_Samp_Fields>;
  sum?: Maybe<Features_Sum_Fields>;
  var_pop?: Maybe<Features_Var_Pop_Fields>;
  var_samp?: Maybe<Features_Var_Samp_Fields>;
  variance?: Maybe<Features_Variance_Fields>;
};


/** aggregate fields of "Features" */
export type Features_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Features_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Features" */
export type Features_Aggregate_Order_By = {
  avg?: Maybe<Features_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Features_Max_Order_By>;
  min?: Maybe<Features_Min_Order_By>;
  stddev?: Maybe<Features_Stddev_Order_By>;
  stddev_pop?: Maybe<Features_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Features_Stddev_Samp_Order_By>;
  sum?: Maybe<Features_Sum_Order_By>;
  var_pop?: Maybe<Features_Var_Pop_Order_By>;
  var_samp?: Maybe<Features_Var_Samp_Order_By>;
  variance?: Maybe<Features_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Features" */
export type Features_Arr_Rel_Insert_Input = {
  data: Array<Features_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Features_On_Conflict>;
};

/** aggregate avg on columns */
export type Features_Avg_Fields = {
  __typename?: 'Features_avg_fields';
  app_access_context_Id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Features" */
export type Features_Avg_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Features". All fields are combined with a logical 'AND'. */
export type Features_Bool_Exp = {
  _and?: Maybe<Array<Features_Bool_Exp>>;
  _not?: Maybe<Features_Bool_Exp>;
  _or?: Maybe<Array<Features_Bool_Exp>>;
  app_access_context_Id?: Maybe<Bigint_Comparison_Exp>;
  context_id?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  feature_flag?: Maybe<String_Comparison_Exp>;
  flag_status?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Features" */
export enum Features_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeaturesPkey = 'Features_pkey'
}

/** input type for incrementing numeric columns in table "Features" */
export type Features_Inc_Input = {
  app_access_context_Id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Features" */
export type Features_Insert_Input = {
  app_access_context_Id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  feature_flag?: Maybe<Scalars['String']>;
  flag_status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Features_Max_Fields = {
  __typename?: 'Features_max_fields';
  app_access_context_Id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  feature_flag?: Maybe<Scalars['String']>;
  flag_status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "Features" */
export type Features_Max_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  feature_flag?: Maybe<Order_By>;
  flag_status?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Features_Min_Fields = {
  __typename?: 'Features_min_fields';
  app_access_context_Id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  feature_flag?: Maybe<Scalars['String']>;
  flag_status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "Features" */
export type Features_Min_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  feature_flag?: Maybe<Order_By>;
  flag_status?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "Features" */
export type Features_Mutation_Response = {
  __typename?: 'Features_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Features>;
};

/** on_conflict condition type for table "Features" */
export type Features_On_Conflict = {
  constraint: Features_Constraint;
  update_columns?: Array<Features_Update_Column>;
  where?: Maybe<Features_Bool_Exp>;
};

/** Ordering options when selecting data from "Features". */
export type Features_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  context_id?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  feature_flag?: Maybe<Order_By>;
  flag_status?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: Features */
export type Features_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Features" */
export enum Features_Select_Column {
  /** column name */
  AppAccessContextId = 'app_access_context_Id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FeatureFlag = 'feature_flag',
  /** column name */
  FlagStatus = 'flag_status',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "Features" */
export type Features_Set_Input = {
  app_access_context_Id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  feature_flag?: Maybe<Scalars['String']>;
  flag_status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Features_Stddev_Fields = {
  __typename?: 'Features_stddev_fields';
  app_access_context_Id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Features" */
export type Features_Stddev_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Features_Stddev_Pop_Fields = {
  __typename?: 'Features_stddev_pop_fields';
  app_access_context_Id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Features" */
export type Features_Stddev_Pop_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Features_Stddev_Samp_Fields = {
  __typename?: 'Features_stddev_samp_fields';
  app_access_context_Id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Features" */
export type Features_Stddev_Samp_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Features_Sum_Fields = {
  __typename?: 'Features_sum_fields';
  app_access_context_Id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Features" */
export type Features_Sum_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Features" */
export enum Features_Update_Column {
  /** column name */
  AppAccessContextId = 'app_access_context_Id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FeatureFlag = 'feature_flag',
  /** column name */
  FlagStatus = 'flag_status',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Features_Var_Pop_Fields = {
  __typename?: 'Features_var_pop_fields';
  app_access_context_Id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Features" */
export type Features_Var_Pop_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Features_Var_Samp_Fields = {
  __typename?: 'Features_var_samp_fields';
  app_access_context_Id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Features" */
export type Features_Var_Samp_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Features_Variance_Fields = {
  __typename?: 'Features_variance_fields';
  app_access_context_Id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Features" */
export type Features_Variance_Order_By = {
  app_access_context_Id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_Metadata = {
  __typename?: 'File_metadata';
  /** An array relationship */
  Collections: Array<Collection>;
  /** An aggregate relationship */
  Collections_aggregate: Collection_Aggregate;
  /** An object relationship */
  Context: Context;
  /** An object relationship */
  ENUM_file_metadata_status: Enum_File_Metadata_Status;
  /** An object relationship */
  Profile?: Maybe<Profile>;
  /** An array relationship */
  Profiles: Array<Profile>;
  /** An aggregate relationship */
  Profiles_aggregate: Profile_Aggregate;
  /** An array relationship */
  artwork_secondary_media: Array<Artwork_Secondary_Media>;
  /** An aggregate relationship */
  artwork_secondary_media_aggregate: Artwork_Secondary_Media_Aggregate;
  /** An array relationship */
  artwork_stories: Array<Artwork_Story>;
  /** An aggregate relationship */
  artwork_stories_aggregate: Artwork_Story_Aggregate;
  /** An array relationship */
  artwork_story_media: Array<Artwork_Story_Media>;
  /** An aggregate relationship */
  artwork_story_media_aggregate: Artwork_Story_Media_Aggregate;
  /** An array relationship */
  artwork_thumbnail: Array<Artwork>;
  /** An aggregate relationship */
  artwork_thumbnail_aggregate: Artwork_Aggregate;
  /** An array relationship */
  artworks: Array<Artwork>;
  /** An aggregate relationship */
  artworks_aggregate: Artwork_Aggregate;
  /** An object relationship */
  contextByOwnerContextId: Context;
  created_at: Scalars['timestamptz'];
  error?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['jsonb']>;
  file_extension: Scalars['String'];
  file_name: Scalars['String'];
  /** storage in kilobytes */
  file_size: Scalars['bigint'];
  forge_configuration_type?: Maybe<Enum_Forge_Configuration_Type_Enum>;
  hash: Scalars['String'];
  id: Scalars['bigint'];
  mimetype: Scalars['String'];
  owner_context_id: Scalars['bigint'];
  status: Enum_File_Metadata_Status_Enum;
  status_at: Scalars['timestamptz'];
  status_by: Scalars['bigint'];
  statuses: Scalars['smallint'];
  storage_path: Scalars['String'];
  storage_service: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataCollectionsArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataCollections_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataProfilesArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataProfiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtwork_Secondary_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtwork_Secondary_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtwork_StoriesArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Order_By>>;
  where?: Maybe<Artwork_Story_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtwork_Stories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Order_By>>;
  where?: Maybe<Artwork_Story_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtwork_Story_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Media_Order_By>>;
  where?: Maybe<Artwork_Story_Media_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtwork_Story_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Media_Order_By>>;
  where?: Maybe<Artwork_Story_Media_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtwork_ThumbnailArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtwork_Thumbnail_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtworksArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataArtworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


/** Stores information about user uploaded files. It can be artworks, profile pics or even collection banner/thumbnail. */
export type File_MetadataExtraArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "File_metadata" */
export type File_Metadata_Aggregate = {
  __typename?: 'File_metadata_aggregate';
  aggregate?: Maybe<File_Metadata_Aggregate_Fields>;
  nodes: Array<File_Metadata>;
};

/** aggregate fields of "File_metadata" */
export type File_Metadata_Aggregate_Fields = {
  __typename?: 'File_metadata_aggregate_fields';
  avg?: Maybe<File_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<File_Metadata_Max_Fields>;
  min?: Maybe<File_Metadata_Min_Fields>;
  stddev?: Maybe<File_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<File_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<File_Metadata_Sum_Fields>;
  var_pop?: Maybe<File_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<File_Metadata_Var_Samp_Fields>;
  variance?: Maybe<File_Metadata_Variance_Fields>;
};


/** aggregate fields of "File_metadata" */
export type File_Metadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<File_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "File_metadata" */
export type File_Metadata_Aggregate_Order_By = {
  avg?: Maybe<File_Metadata_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<File_Metadata_Max_Order_By>;
  min?: Maybe<File_Metadata_Min_Order_By>;
  stddev?: Maybe<File_Metadata_Stddev_Order_By>;
  stddev_pop?: Maybe<File_Metadata_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<File_Metadata_Stddev_Samp_Order_By>;
  sum?: Maybe<File_Metadata_Sum_Order_By>;
  var_pop?: Maybe<File_Metadata_Var_Pop_Order_By>;
  var_samp?: Maybe<File_Metadata_Var_Samp_Order_By>;
  variance?: Maybe<File_Metadata_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type File_Metadata_Append_Input = {
  extra?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "File_metadata" */
export type File_Metadata_Arr_Rel_Insert_Input = {
  data: Array<File_Metadata_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<File_Metadata_On_Conflict>;
};

/** aggregate avg on columns */
export type File_Metadata_Avg_Fields = {
  __typename?: 'File_metadata_avg_fields';
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  status_by?: Maybe<Scalars['Float']>;
  statuses?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "File_metadata" */
export type File_Metadata_Avg_Order_By = {
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "File_metadata". All fields are combined with a logical 'AND'. */
export type File_Metadata_Bool_Exp = {
  Collections?: Maybe<Collection_Bool_Exp>;
  Context?: Maybe<Context_Bool_Exp>;
  ENUM_file_metadata_status?: Maybe<Enum_File_Metadata_Status_Bool_Exp>;
  Profile?: Maybe<Profile_Bool_Exp>;
  Profiles?: Maybe<Profile_Bool_Exp>;
  _and?: Maybe<Array<File_Metadata_Bool_Exp>>;
  _not?: Maybe<File_Metadata_Bool_Exp>;
  _or?: Maybe<Array<File_Metadata_Bool_Exp>>;
  artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
  artwork_stories?: Maybe<Artwork_Story_Bool_Exp>;
  artwork_story_media?: Maybe<Artwork_Story_Media_Bool_Exp>;
  artwork_thumbnail?: Maybe<Artwork_Bool_Exp>;
  artworks?: Maybe<Artwork_Bool_Exp>;
  contextByOwnerContextId?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  error?: Maybe<String_Comparison_Exp>;
  extra?: Maybe<Jsonb_Comparison_Exp>;
  file_extension?: Maybe<String_Comparison_Exp>;
  file_name?: Maybe<String_Comparison_Exp>;
  file_size?: Maybe<Bigint_Comparison_Exp>;
  forge_configuration_type?: Maybe<Enum_Forge_Configuration_Type_Enum_Comparison_Exp>;
  hash?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  mimetype?: Maybe<String_Comparison_Exp>;
  owner_context_id?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<Enum_File_Metadata_Status_Enum_Comparison_Exp>;
  status_at?: Maybe<Timestamptz_Comparison_Exp>;
  status_by?: Maybe<Bigint_Comparison_Exp>;
  statuses?: Maybe<Smallint_Comparison_Exp>;
  storage_path?: Maybe<String_Comparison_Exp>;
  storage_service?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "File_metadata" */
export enum File_Metadata_Constraint {
  /** unique or primary key constraint on columns "id" */
  FileMetadataPkey = 'File_metadata_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type File_Metadata_Delete_At_Path_Input = {
  extra?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type File_Metadata_Delete_Elem_Input = {
  extra?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type File_Metadata_Delete_Key_Input = {
  extra?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "File_metadata" */
export type File_Metadata_Inc_Input = {
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  status_by?: Maybe<Scalars['bigint']>;
  statuses?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "File_metadata" */
export type File_Metadata_Insert_Input = {
  Collections?: Maybe<Collection_Arr_Rel_Insert_Input>;
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  ENUM_file_metadata_status?: Maybe<Enum_File_Metadata_Status_Obj_Rel_Insert_Input>;
  Profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  Profiles?: Maybe<Profile_Arr_Rel_Insert_Input>;
  artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Arr_Rel_Insert_Input>;
  artwork_stories?: Maybe<Artwork_Story_Arr_Rel_Insert_Input>;
  artwork_story_media?: Maybe<Artwork_Story_Media_Arr_Rel_Insert_Input>;
  artwork_thumbnail?: Maybe<Artwork_Arr_Rel_Insert_Input>;
  artworks?: Maybe<Artwork_Arr_Rel_Insert_Input>;
  contextByOwnerContextId?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['jsonb']>;
  file_extension?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['bigint']>;
  forge_configuration_type?: Maybe<Enum_Forge_Configuration_Type_Enum>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mimetype?: Maybe<Scalars['String']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_File_Metadata_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  status_by?: Maybe<Scalars['bigint']>;
  statuses?: Maybe<Scalars['smallint']>;
  storage_path?: Maybe<Scalars['String']>;
  storage_service?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type File_Metadata_Max_Fields = {
  __typename?: 'File_metadata_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  file_extension?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['bigint']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mimetype?: Maybe<Scalars['String']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  status_by?: Maybe<Scalars['bigint']>;
  statuses?: Maybe<Scalars['smallint']>;
  storage_path?: Maybe<Scalars['String']>;
  storage_service?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "File_metadata" */
export type File_Metadata_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  error?: Maybe<Order_By>;
  file_extension?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mimetype?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
  storage_path?: Maybe<Order_By>;
  storage_service?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type File_Metadata_Min_Fields = {
  __typename?: 'File_metadata_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  file_extension?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['bigint']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mimetype?: Maybe<Scalars['String']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  status_by?: Maybe<Scalars['bigint']>;
  statuses?: Maybe<Scalars['smallint']>;
  storage_path?: Maybe<Scalars['String']>;
  storage_service?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "File_metadata" */
export type File_Metadata_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  error?: Maybe<Order_By>;
  file_extension?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mimetype?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
  storage_path?: Maybe<Order_By>;
  storage_service?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "File_metadata" */
export type File_Metadata_Mutation_Response = {
  __typename?: 'File_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Metadata>;
};

/** input type for inserting object relation for remote table "File_metadata" */
export type File_Metadata_Obj_Rel_Insert_Input = {
  data: File_Metadata_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<File_Metadata_On_Conflict>;
};

/** on_conflict condition type for table "File_metadata" */
export type File_Metadata_On_Conflict = {
  constraint: File_Metadata_Constraint;
  update_columns?: Array<File_Metadata_Update_Column>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "File_metadata". */
export type File_Metadata_Order_By = {
  Collections_aggregate?: Maybe<Collection_Aggregate_Order_By>;
  Context?: Maybe<Context_Order_By>;
  ENUM_file_metadata_status?: Maybe<Enum_File_Metadata_Status_Order_By>;
  Profile?: Maybe<Profile_Order_By>;
  Profiles_aggregate?: Maybe<Profile_Aggregate_Order_By>;
  artwork_secondary_media_aggregate?: Maybe<Artwork_Secondary_Media_Aggregate_Order_By>;
  artwork_stories_aggregate?: Maybe<Artwork_Story_Aggregate_Order_By>;
  artwork_story_media_aggregate?: Maybe<Artwork_Story_Media_Aggregate_Order_By>;
  artwork_thumbnail_aggregate?: Maybe<Artwork_Aggregate_Order_By>;
  artworks_aggregate?: Maybe<Artwork_Aggregate_Order_By>;
  contextByOwnerContextId?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  error?: Maybe<Order_By>;
  extra?: Maybe<Order_By>;
  file_extension?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  file_size?: Maybe<Order_By>;
  forge_configuration_type?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mimetype?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
  storage_path?: Maybe<Order_By>;
  storage_service?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: File_metadata */
export type File_Metadata_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type File_Metadata_Prepend_Input = {
  extra?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "File_metadata" */
export enum File_Metadata_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  Extra = 'extra',
  /** column name */
  FileExtension = 'file_extension',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  ForgeConfigurationType = 'forge_configuration_type',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  OwnerContextId = 'owner_context_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  StatusBy = 'status_by',
  /** column name */
  Statuses = 'statuses',
  /** column name */
  StoragePath = 'storage_path',
  /** column name */
  StorageService = 'storage_service',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "File_metadata" */
export type File_Metadata_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['jsonb']>;
  file_extension?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['bigint']>;
  forge_configuration_type?: Maybe<Enum_Forge_Configuration_Type_Enum>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mimetype?: Maybe<Scalars['String']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_File_Metadata_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  status_by?: Maybe<Scalars['bigint']>;
  statuses?: Maybe<Scalars['smallint']>;
  storage_path?: Maybe<Scalars['String']>;
  storage_service?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type File_Metadata_Stddev_Fields = {
  __typename?: 'File_metadata_stddev_fields';
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  status_by?: Maybe<Scalars['Float']>;
  statuses?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "File_metadata" */
export type File_Metadata_Stddev_Order_By = {
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type File_Metadata_Stddev_Pop_Fields = {
  __typename?: 'File_metadata_stddev_pop_fields';
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  status_by?: Maybe<Scalars['Float']>;
  statuses?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "File_metadata" */
export type File_Metadata_Stddev_Pop_Order_By = {
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type File_Metadata_Stddev_Samp_Fields = {
  __typename?: 'File_metadata_stddev_samp_fields';
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  status_by?: Maybe<Scalars['Float']>;
  statuses?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "File_metadata" */
export type File_Metadata_Stddev_Samp_Order_By = {
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type File_Metadata_Sum_Fields = {
  __typename?: 'File_metadata_sum_fields';
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  status_by?: Maybe<Scalars['bigint']>;
  statuses?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "File_metadata" */
export type File_Metadata_Sum_Order_By = {
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
};

/** update columns of table "File_metadata" */
export enum File_Metadata_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  Extra = 'extra',
  /** column name */
  FileExtension = 'file_extension',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  ForgeConfigurationType = 'forge_configuration_type',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  OwnerContextId = 'owner_context_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  StatusBy = 'status_by',
  /** column name */
  Statuses = 'statuses',
  /** column name */
  StoragePath = 'storage_path',
  /** column name */
  StorageService = 'storage_service',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type File_Metadata_Var_Pop_Fields = {
  __typename?: 'File_metadata_var_pop_fields';
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  status_by?: Maybe<Scalars['Float']>;
  statuses?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "File_metadata" */
export type File_Metadata_Var_Pop_Order_By = {
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type File_Metadata_Var_Samp_Fields = {
  __typename?: 'File_metadata_var_samp_fields';
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  status_by?: Maybe<Scalars['Float']>;
  statuses?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "File_metadata" */
export type File_Metadata_Var_Samp_Order_By = {
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type File_Metadata_Variance_Fields = {
  __typename?: 'File_metadata_variance_fields';
  /** storage in kilobytes */
  file_size?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  status_by?: Maybe<Scalars['Float']>;
  statuses?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "File_metadata" */
export type File_Metadata_Variance_Order_By = {
  /** storage in kilobytes */
  file_size?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  status_by?: Maybe<Order_By>;
  statuses?: Maybe<Order_By>;
};

/** columns and relationships of "Firebase_profile" */
export type Firebase_Profile = {
  __typename?: 'Firebase_profile';
  /** An object relationship */
  auth: Auth;
  auth_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  display_name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebase_id: Scalars['String'];
  id: Scalars['bigint'];
  new_email?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Firebase_profile" */
export type Firebase_Profile_Aggregate = {
  __typename?: 'Firebase_profile_aggregate';
  aggregate?: Maybe<Firebase_Profile_Aggregate_Fields>;
  nodes: Array<Firebase_Profile>;
};

/** aggregate fields of "Firebase_profile" */
export type Firebase_Profile_Aggregate_Fields = {
  __typename?: 'Firebase_profile_aggregate_fields';
  avg?: Maybe<Firebase_Profile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Firebase_Profile_Max_Fields>;
  min?: Maybe<Firebase_Profile_Min_Fields>;
  stddev?: Maybe<Firebase_Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Firebase_Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Firebase_Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Firebase_Profile_Sum_Fields>;
  var_pop?: Maybe<Firebase_Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Firebase_Profile_Var_Samp_Fields>;
  variance?: Maybe<Firebase_Profile_Variance_Fields>;
};


/** aggregate fields of "Firebase_profile" */
export type Firebase_Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Firebase_Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Firebase_profile" */
export type Firebase_Profile_Aggregate_Order_By = {
  avg?: Maybe<Firebase_Profile_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Firebase_Profile_Max_Order_By>;
  min?: Maybe<Firebase_Profile_Min_Order_By>;
  stddev?: Maybe<Firebase_Profile_Stddev_Order_By>;
  stddev_pop?: Maybe<Firebase_Profile_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Firebase_Profile_Stddev_Samp_Order_By>;
  sum?: Maybe<Firebase_Profile_Sum_Order_By>;
  var_pop?: Maybe<Firebase_Profile_Var_Pop_Order_By>;
  var_samp?: Maybe<Firebase_Profile_Var_Samp_Order_By>;
  variance?: Maybe<Firebase_Profile_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Firebase_profile" */
export type Firebase_Profile_Arr_Rel_Insert_Input = {
  data: Array<Firebase_Profile_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Firebase_Profile_On_Conflict>;
};

/** aggregate avg on columns */
export type Firebase_Profile_Avg_Fields = {
  __typename?: 'Firebase_profile_avg_fields';
  auth_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Firebase_profile" */
export type Firebase_Profile_Avg_Order_By = {
  auth_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Firebase_profile". All fields are combined with a logical 'AND'. */
export type Firebase_Profile_Bool_Exp = {
  _and?: Maybe<Array<Firebase_Profile_Bool_Exp>>;
  _not?: Maybe<Firebase_Profile_Bool_Exp>;
  _or?: Maybe<Array<Firebase_Profile_Bool_Exp>>;
  auth?: Maybe<Auth_Bool_Exp>;
  auth_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firebase_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  new_email?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Firebase_profile" */
export enum Firebase_Profile_Constraint {
  /** unique or primary key constraint on columns "email" */
  FirebaseProfileEmailKey = 'Firebase_profile_email_key',
  /** unique or primary key constraint on columns "firebase_id" */
  FirebaseProfileFirebaseIdKey = 'Firebase_profile_firebase_id_key',
  /** unique or primary key constraint on columns "id" */
  FirebaseProfilePkey = 'Firebase_profile_pkey'
}

/** input type for incrementing numeric columns in table "Firebase_profile" */
export type Firebase_Profile_Inc_Input = {
  auth_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Firebase_profile" */
export type Firebase_Profile_Insert_Input = {
  auth?: Maybe<Auth_Obj_Rel_Insert_Input>;
  auth_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebase_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  new_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Firebase_Profile_Max_Fields = {
  __typename?: 'Firebase_profile_max_fields';
  auth_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebase_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  new_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Firebase_profile" */
export type Firebase_Profile_Max_Order_By = {
  auth_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebase_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Firebase_Profile_Min_Fields = {
  __typename?: 'Firebase_profile_min_fields';
  auth_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebase_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  new_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Firebase_profile" */
export type Firebase_Profile_Min_Order_By = {
  auth_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebase_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Firebase_profile" */
export type Firebase_Profile_Mutation_Response = {
  __typename?: 'Firebase_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Firebase_Profile>;
};

/** on_conflict condition type for table "Firebase_profile" */
export type Firebase_Profile_On_Conflict = {
  constraint: Firebase_Profile_Constraint;
  update_columns?: Array<Firebase_Profile_Update_Column>;
  where?: Maybe<Firebase_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "Firebase_profile". */
export type Firebase_Profile_Order_By = {
  auth?: Maybe<Auth_Order_By>;
  auth_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebase_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Firebase_profile */
export type Firebase_Profile_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Firebase_profile" */
export enum Firebase_Profile_Select_Column {
  /** column name */
  AuthId = 'auth_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Firebase_profile" */
export type Firebase_Profile_Set_Input = {
  auth_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firebase_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  new_email?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Firebase_Profile_Stddev_Fields = {
  __typename?: 'Firebase_profile_stddev_fields';
  auth_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Firebase_profile" */
export type Firebase_Profile_Stddev_Order_By = {
  auth_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Firebase_Profile_Stddev_Pop_Fields = {
  __typename?: 'Firebase_profile_stddev_pop_fields';
  auth_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Firebase_profile" */
export type Firebase_Profile_Stddev_Pop_Order_By = {
  auth_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Firebase_Profile_Stddev_Samp_Fields = {
  __typename?: 'Firebase_profile_stddev_samp_fields';
  auth_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Firebase_profile" */
export type Firebase_Profile_Stddev_Samp_Order_By = {
  auth_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Firebase_Profile_Sum_Fields = {
  __typename?: 'Firebase_profile_sum_fields';
  auth_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Firebase_profile" */
export type Firebase_Profile_Sum_Order_By = {
  auth_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Firebase_profile" */
export enum Firebase_Profile_Update_Column {
  /** column name */
  AuthId = 'auth_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  Id = 'id',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Firebase_Profile_Var_Pop_Fields = {
  __typename?: 'Firebase_profile_var_pop_fields';
  auth_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Firebase_profile" */
export type Firebase_Profile_Var_Pop_Order_By = {
  auth_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Firebase_Profile_Var_Samp_Fields = {
  __typename?: 'Firebase_profile_var_samp_fields';
  auth_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Firebase_profile" */
export type Firebase_Profile_Var_Samp_Order_By = {
  auth_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Firebase_Profile_Variance_Fields = {
  __typename?: 'Firebase_profile_variance_fields';
  auth_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Firebase_profile" */
export type Firebase_Profile_Variance_Order_By = {
  auth_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type FixMissingDomainSubscriptionResult = {
  __typename?: 'FixMissingDomainSubscriptionResult';
  fixes: Array<MissingDomainFixResult>;
};

export type FlagUserIfIsScammerReturnType = {
  __typename?: 'FlagUserIfIsScammerReturnType';
  context_id: Scalars['Int'];
  id: Scalars['Int'];
  infractions_counter: Scalars['Int'];
};

/** This table contains all users that has done an infraction */
export type Flagged_Users = {
  __typename?: 'Flagged_users';
  /** An object relationship */
  Context: Context;
  /** An object relationship */
  ENUM_infraction_flag_type?: Maybe<Enum_Infraction_Flag_Type>;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  flag_type: Enum_Infraction_Flag_Type_Enum;
  id: Scalars['bigint'];
  infractions_counter: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Flagged_users" */
export type Flagged_Users_Aggregate = {
  __typename?: 'Flagged_users_aggregate';
  aggregate?: Maybe<Flagged_Users_Aggregate_Fields>;
  nodes: Array<Flagged_Users>;
};

/** aggregate fields of "Flagged_users" */
export type Flagged_Users_Aggregate_Fields = {
  __typename?: 'Flagged_users_aggregate_fields';
  avg?: Maybe<Flagged_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Flagged_Users_Max_Fields>;
  min?: Maybe<Flagged_Users_Min_Fields>;
  stddev?: Maybe<Flagged_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Flagged_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Flagged_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Flagged_Users_Sum_Fields>;
  var_pop?: Maybe<Flagged_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Flagged_Users_Var_Samp_Fields>;
  variance?: Maybe<Flagged_Users_Variance_Fields>;
};


/** aggregate fields of "Flagged_users" */
export type Flagged_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Flagged_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Flagged_Users_Avg_Fields = {
  __typename?: 'Flagged_users_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  infractions_counter?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Flagged_users". All fields are combined with a logical 'AND'. */
export type Flagged_Users_Bool_Exp = {
  Context?: Maybe<Context_Bool_Exp>;
  ENUM_infraction_flag_type?: Maybe<Enum_Infraction_Flag_Type_Bool_Exp>;
  _and?: Maybe<Array<Flagged_Users_Bool_Exp>>;
  _not?: Maybe<Flagged_Users_Bool_Exp>;
  _or?: Maybe<Array<Flagged_Users_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  flag_type?: Maybe<Enum_Infraction_Flag_Type_Enum_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  infractions_counter?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Flagged_users" */
export enum Flagged_Users_Constraint {
  /** unique or primary key constraint on columns "flag_type", "context_id" */
  FlaggedUsersContextIdFlagTypeKey = 'Flagged_users_context_id_flag_type_key',
  /** unique or primary key constraint on columns "id" */
  FlaggedUsersPkey = 'Flagged_users_pkey'
}

/** input type for incrementing numeric columns in table "Flagged_users" */
export type Flagged_Users_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  infractions_counter?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Flagged_users" */
export type Flagged_Users_Insert_Input = {
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  ENUM_infraction_flag_type?: Maybe<Enum_Infraction_Flag_Type_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_type?: Maybe<Enum_Infraction_Flag_Type_Enum>;
  id?: Maybe<Scalars['bigint']>;
  infractions_counter?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Flagged_Users_Max_Fields = {
  __typename?: 'Flagged_users_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  infractions_counter?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Flagged_Users_Min_Fields = {
  __typename?: 'Flagged_users_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  infractions_counter?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Flagged_users" */
export type Flagged_Users_Mutation_Response = {
  __typename?: 'Flagged_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Flagged_Users>;
};

/** on_conflict condition type for table "Flagged_users" */
export type Flagged_Users_On_Conflict = {
  constraint: Flagged_Users_Constraint;
  update_columns?: Array<Flagged_Users_Update_Column>;
  where?: Maybe<Flagged_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "Flagged_users". */
export type Flagged_Users_Order_By = {
  Context?: Maybe<Context_Order_By>;
  ENUM_infraction_flag_type?: Maybe<Enum_Infraction_Flag_Type_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  flag_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  infractions_counter?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Flagged_users */
export type Flagged_Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Flagged_users" */
export enum Flagged_Users_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagType = 'flag_type',
  /** column name */
  Id = 'id',
  /** column name */
  InfractionsCounter = 'infractions_counter',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Flagged_users" */
export type Flagged_Users_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_type?: Maybe<Enum_Infraction_Flag_Type_Enum>;
  id?: Maybe<Scalars['bigint']>;
  infractions_counter?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Flagged_Users_Stddev_Fields = {
  __typename?: 'Flagged_users_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  infractions_counter?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Flagged_Users_Stddev_Pop_Fields = {
  __typename?: 'Flagged_users_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  infractions_counter?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Flagged_Users_Stddev_Samp_Fields = {
  __typename?: 'Flagged_users_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  infractions_counter?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Flagged_Users_Sum_Fields = {
  __typename?: 'Flagged_users_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  infractions_counter?: Maybe<Scalars['Int']>;
};

/** update columns of table "Flagged_users" */
export enum Flagged_Users_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagType = 'flag_type',
  /** column name */
  Id = 'id',
  /** column name */
  InfractionsCounter = 'infractions_counter',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Flagged_Users_Var_Pop_Fields = {
  __typename?: 'Flagged_users_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  infractions_counter?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Flagged_Users_Var_Samp_Fields = {
  __typename?: 'Flagged_users_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  infractions_counter?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Flagged_Users_Variance_Fields = {
  __typename?: 'Flagged_users_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  infractions_counter?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Flat_context_access" */
export type Flat_Context_Access = {
  __typename?: 'Flat_context_access';
  /** An object relationship */
  ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role>;
  depth?: Maybe<Scalars['Int']>;
  /** An object relationship */
  granter?: Maybe<Context>;
  granter_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  receiver?: Maybe<Context>;
  receiver_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Flat_context_access" */
export type Flat_Context_Access_Aggregate = {
  __typename?: 'Flat_context_access_aggregate';
  aggregate?: Maybe<Flat_Context_Access_Aggregate_Fields>;
  nodes: Array<Flat_Context_Access>;
};

/** aggregate fields of "Flat_context_access" */
export type Flat_Context_Access_Aggregate_Fields = {
  __typename?: 'Flat_context_access_aggregate_fields';
  avg?: Maybe<Flat_Context_Access_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Flat_Context_Access_Max_Fields>;
  min?: Maybe<Flat_Context_Access_Min_Fields>;
  stddev?: Maybe<Flat_Context_Access_Stddev_Fields>;
  stddev_pop?: Maybe<Flat_Context_Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Flat_Context_Access_Stddev_Samp_Fields>;
  sum?: Maybe<Flat_Context_Access_Sum_Fields>;
  var_pop?: Maybe<Flat_Context_Access_Var_Pop_Fields>;
  var_samp?: Maybe<Flat_Context_Access_Var_Samp_Fields>;
  variance?: Maybe<Flat_Context_Access_Variance_Fields>;
};


/** aggregate fields of "Flat_context_access" */
export type Flat_Context_Access_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Flat_context_access" */
export type Flat_Context_Access_Aggregate_Order_By = {
  avg?: Maybe<Flat_Context_Access_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Flat_Context_Access_Max_Order_By>;
  min?: Maybe<Flat_Context_Access_Min_Order_By>;
  stddev?: Maybe<Flat_Context_Access_Stddev_Order_By>;
  stddev_pop?: Maybe<Flat_Context_Access_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Flat_Context_Access_Stddev_Samp_Order_By>;
  sum?: Maybe<Flat_Context_Access_Sum_Order_By>;
  var_pop?: Maybe<Flat_Context_Access_Var_Pop_Order_By>;
  var_samp?: Maybe<Flat_Context_Access_Var_Samp_Order_By>;
  variance?: Maybe<Flat_Context_Access_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Flat_context_access" */
export type Flat_Context_Access_Arr_Rel_Insert_Input = {
  data: Array<Flat_Context_Access_Insert_Input>;
};

/** aggregate avg on columns */
export type Flat_Context_Access_Avg_Fields = {
  __typename?: 'Flat_context_access_avg_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Avg_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Flat_context_access". All fields are combined with a logical 'AND'. */
export type Flat_Context_Access_Bool_Exp = {
  ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role_Bool_Exp>;
  _and?: Maybe<Array<Flat_Context_Access_Bool_Exp>>;
  _not?: Maybe<Flat_Context_Access_Bool_Exp>;
  _or?: Maybe<Array<Flat_Context_Access_Bool_Exp>>;
  depth?: Maybe<Int_Comparison_Exp>;
  granter?: Maybe<Context_Bool_Exp>;
  granter_id?: Maybe<Bigint_Comparison_Exp>;
  receiver?: Maybe<Context_Bool_Exp>;
  receiver_id?: Maybe<Bigint_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Flat_context_access" */
export type Flat_Context_Access_Insert_Input = {
  ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role_Obj_Rel_Insert_Input>;
  depth?: Maybe<Scalars['Int']>;
  granter?: Maybe<Context_Obj_Rel_Insert_Input>;
  granter_id?: Maybe<Scalars['bigint']>;
  receiver?: Maybe<Context_Obj_Rel_Insert_Input>;
  receiver_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Flat_Context_Access_Max_Fields = {
  __typename?: 'Flat_context_access_max_fields';
  depth?: Maybe<Scalars['Int']>;
  granter_id?: Maybe<Scalars['bigint']>;
  receiver_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Max_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Flat_Context_Access_Min_Fields = {
  __typename?: 'Flat_context_access_min_fields';
  depth?: Maybe<Scalars['Int']>;
  granter_id?: Maybe<Scalars['bigint']>;
  receiver_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Min_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "Flat_context_access". */
export type Flat_Context_Access_Order_By = {
  ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role_Order_By>;
  depth?: Maybe<Order_By>;
  granter?: Maybe<Context_Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver?: Maybe<Context_Order_By>;
  receiver_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** select columns of table "Flat_context_access" */
export enum Flat_Context_Access_Select_Column {
  /** column name */
  Depth = 'depth',
  /** column name */
  GranterId = 'granter_id',
  /** column name */
  ReceiverId = 'receiver_id',
  /** column name */
  Role = 'role'
}

/** aggregate stddev on columns */
export type Flat_Context_Access_Stddev_Fields = {
  __typename?: 'Flat_context_access_stddev_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Stddev_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Flat_Context_Access_Stddev_Pop_Fields = {
  __typename?: 'Flat_context_access_stddev_pop_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Stddev_Pop_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Flat_Context_Access_Stddev_Samp_Fields = {
  __typename?: 'Flat_context_access_stddev_samp_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Stddev_Samp_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Flat_Context_Access_Sum_Fields = {
  __typename?: 'Flat_context_access_sum_fields';
  depth?: Maybe<Scalars['Int']>;
  granter_id?: Maybe<Scalars['bigint']>;
  receiver_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Sum_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Flat_Context_Access_Var_Pop_Fields = {
  __typename?: 'Flat_context_access_var_pop_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Var_Pop_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Flat_Context_Access_Var_Samp_Fields = {
  __typename?: 'Flat_context_access_var_samp_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Var_Samp_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Flat_Context_Access_Variance_Fields = {
  __typename?: 'Flat_context_access_variance_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Flat_context_access" */
export type Flat_Context_Access_Variance_Order_By = {
  depth?: Maybe<Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver_id?: Maybe<Order_By>;
};

/** columns and relationships of "Following_artists_art_universe_listing" */
export type Following_Artists_Art_Universe_Listing = {
  __typename?: 'Following_artists_art_universe_listing';
  /** An object relationship */
  artist_explore_info?: Maybe<Artist_Explore_Info>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  target_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "Following_artists_art_universe_listing" */
export type Following_Artists_Art_Universe_Listing_Aggregate = {
  __typename?: 'Following_artists_art_universe_listing_aggregate';
  aggregate?: Maybe<Following_Artists_Art_Universe_Listing_Aggregate_Fields>;
  nodes: Array<Following_Artists_Art_Universe_Listing>;
};

/** aggregate fields of "Following_artists_art_universe_listing" */
export type Following_Artists_Art_Universe_Listing_Aggregate_Fields = {
  __typename?: 'Following_artists_art_universe_listing_aggregate_fields';
  avg?: Maybe<Following_Artists_Art_Universe_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Following_Artists_Art_Universe_Listing_Max_Fields>;
  min?: Maybe<Following_Artists_Art_Universe_Listing_Min_Fields>;
  stddev?: Maybe<Following_Artists_Art_Universe_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Following_Artists_Art_Universe_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Following_Artists_Art_Universe_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Following_Artists_Art_Universe_Listing_Sum_Fields>;
  var_pop?: Maybe<Following_Artists_Art_Universe_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Following_Artists_Art_Universe_Listing_Var_Samp_Fields>;
  variance?: Maybe<Following_Artists_Art_Universe_Listing_Variance_Fields>;
};


/** aggregate fields of "Following_artists_art_universe_listing" */
export type Following_Artists_Art_Universe_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Following_Artists_Art_Universe_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Following_Artists_Art_Universe_Listing_Avg_Fields = {
  __typename?: 'Following_artists_art_universe_listing_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Following_artists_art_universe_listing". All fields are combined with a logical 'AND'. */
export type Following_Artists_Art_Universe_Listing_Bool_Exp = {
  _and?: Maybe<Array<Following_Artists_Art_Universe_Listing_Bool_Exp>>;
  _not?: Maybe<Following_Artists_Art_Universe_Listing_Bool_Exp>;
  _or?: Maybe<Array<Following_Artists_Art_Universe_Listing_Bool_Exp>>;
  artist_explore_info?: Maybe<Artist_Explore_Info_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  target_context_id?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Following_Artists_Art_Universe_Listing_Max_Fields = {
  __typename?: 'Following_artists_art_universe_listing_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  target_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Following_Artists_Art_Universe_Listing_Min_Fields = {
  __typename?: 'Following_artists_art_universe_listing_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  target_context_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "Following_artists_art_universe_listing". */
export type Following_Artists_Art_Universe_Listing_Order_By = {
  artist_explore_info?: Maybe<Artist_Explore_Info_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
};

/** select columns of table "Following_artists_art_universe_listing" */
export enum Following_Artists_Art_Universe_Listing_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TargetContextId = 'target_context_id'
}

/** aggregate stddev on columns */
export type Following_Artists_Art_Universe_Listing_Stddev_Fields = {
  __typename?: 'Following_artists_art_universe_listing_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Following_Artists_Art_Universe_Listing_Stddev_Pop_Fields = {
  __typename?: 'Following_artists_art_universe_listing_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Following_Artists_Art_Universe_Listing_Stddev_Samp_Fields = {
  __typename?: 'Following_artists_art_universe_listing_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Following_Artists_Art_Universe_Listing_Sum_Fields = {
  __typename?: 'Following_artists_art_universe_listing_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  target_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Following_Artists_Art_Universe_Listing_Var_Pop_Fields = {
  __typename?: 'Following_artists_art_universe_listing_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Following_Artists_Art_Universe_Listing_Var_Samp_Fields = {
  __typename?: 'Following_artists_art_universe_listing_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Following_Artists_Art_Universe_Listing_Variance_Fields = {
  __typename?: 'Following_artists_art_universe_listing_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** Table used to control forge tasks */
export type Forge_InProcess_Task = {
  __typename?: 'Forge_InProcess_Task';
  created_at: Scalars['timestamptz'];
  forge_task_id: Scalars['bigint'];
};

/** aggregated selection of "Forge_InProcess_Task" */
export type Forge_InProcess_Task_Aggregate = {
  __typename?: 'Forge_InProcess_Task_aggregate';
  aggregate?: Maybe<Forge_InProcess_Task_Aggregate_Fields>;
  nodes: Array<Forge_InProcess_Task>;
};

/** aggregate fields of "Forge_InProcess_Task" */
export type Forge_InProcess_Task_Aggregate_Fields = {
  __typename?: 'Forge_InProcess_Task_aggregate_fields';
  avg?: Maybe<Forge_InProcess_Task_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Forge_InProcess_Task_Max_Fields>;
  min?: Maybe<Forge_InProcess_Task_Min_Fields>;
  stddev?: Maybe<Forge_InProcess_Task_Stddev_Fields>;
  stddev_pop?: Maybe<Forge_InProcess_Task_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Forge_InProcess_Task_Stddev_Samp_Fields>;
  sum?: Maybe<Forge_InProcess_Task_Sum_Fields>;
  var_pop?: Maybe<Forge_InProcess_Task_Var_Pop_Fields>;
  var_samp?: Maybe<Forge_InProcess_Task_Var_Samp_Fields>;
  variance?: Maybe<Forge_InProcess_Task_Variance_Fields>;
};


/** aggregate fields of "Forge_InProcess_Task" */
export type Forge_InProcess_Task_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Forge_InProcess_Task_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Forge_InProcess_Task_Avg_Fields = {
  __typename?: 'Forge_InProcess_Task_avg_fields';
  forge_task_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Forge_InProcess_Task". All fields are combined with a logical 'AND'. */
export type Forge_InProcess_Task_Bool_Exp = {
  _and?: Maybe<Array<Forge_InProcess_Task_Bool_Exp>>;
  _not?: Maybe<Forge_InProcess_Task_Bool_Exp>;
  _or?: Maybe<Array<Forge_InProcess_Task_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  forge_task_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Forge_InProcess_Task" */
export enum Forge_InProcess_Task_Constraint {
  /** unique or primary key constraint on columns "forge_task_id" */
  ForgeInProcessTaskPkey = 'Forge_InProcess_Task_pkey'
}

/** input type for incrementing numeric columns in table "Forge_InProcess_Task" */
export type Forge_InProcess_Task_Inc_Input = {
  forge_task_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Forge_InProcess_Task" */
export type Forge_InProcess_Task_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  forge_task_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Forge_InProcess_Task_Max_Fields = {
  __typename?: 'Forge_InProcess_Task_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  forge_task_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Forge_InProcess_Task_Min_Fields = {
  __typename?: 'Forge_InProcess_Task_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  forge_task_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Forge_InProcess_Task" */
export type Forge_InProcess_Task_Mutation_Response = {
  __typename?: 'Forge_InProcess_Task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Forge_InProcess_Task>;
};

/** on_conflict condition type for table "Forge_InProcess_Task" */
export type Forge_InProcess_Task_On_Conflict = {
  constraint: Forge_InProcess_Task_Constraint;
  update_columns?: Array<Forge_InProcess_Task_Update_Column>;
  where?: Maybe<Forge_InProcess_Task_Bool_Exp>;
};

/** Ordering options when selecting data from "Forge_InProcess_Task". */
export type Forge_InProcess_Task_Order_By = {
  created_at?: Maybe<Order_By>;
  forge_task_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Forge_InProcess_Task */
export type Forge_InProcess_Task_Pk_Columns_Input = {
  forge_task_id: Scalars['bigint'];
};

/** select columns of table "Forge_InProcess_Task" */
export enum Forge_InProcess_Task_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForgeTaskId = 'forge_task_id'
}

/** input type for updating data in table "Forge_InProcess_Task" */
export type Forge_InProcess_Task_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  forge_task_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Forge_InProcess_Task_Stddev_Fields = {
  __typename?: 'Forge_InProcess_Task_stddev_fields';
  forge_task_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Forge_InProcess_Task_Stddev_Pop_Fields = {
  __typename?: 'Forge_InProcess_Task_stddev_pop_fields';
  forge_task_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Forge_InProcess_Task_Stddev_Samp_Fields = {
  __typename?: 'Forge_InProcess_Task_stddev_samp_fields';
  forge_task_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Forge_InProcess_Task_Sum_Fields = {
  __typename?: 'Forge_InProcess_Task_sum_fields';
  forge_task_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Forge_InProcess_Task" */
export enum Forge_InProcess_Task_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ForgeTaskId = 'forge_task_id'
}

/** aggregate var_pop on columns */
export type Forge_InProcess_Task_Var_Pop_Fields = {
  __typename?: 'Forge_InProcess_Task_var_pop_fields';
  forge_task_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Forge_InProcess_Task_Var_Samp_Fields = {
  __typename?: 'Forge_InProcess_Task_var_samp_fields';
  forge_task_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Forge_InProcess_Task_Variance_Fields = {
  __typename?: 'Forge_InProcess_Task_variance_fields';
  forge_task_id?: Maybe<Scalars['Float']>;
};

/** Table to queue the tasks for Forge */
export type Forge_Tasks = {
  __typename?: 'Forge_tasks';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  payload: Scalars['String'];
  status: Enum_Forge_Status_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Forge_tasks" */
export type Forge_Tasks_Aggregate = {
  __typename?: 'Forge_tasks_aggregate';
  aggregate?: Maybe<Forge_Tasks_Aggregate_Fields>;
  nodes: Array<Forge_Tasks>;
};

/** aggregate fields of "Forge_tasks" */
export type Forge_Tasks_Aggregate_Fields = {
  __typename?: 'Forge_tasks_aggregate_fields';
  avg?: Maybe<Forge_Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Forge_Tasks_Max_Fields>;
  min?: Maybe<Forge_Tasks_Min_Fields>;
  stddev?: Maybe<Forge_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Forge_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Forge_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Forge_Tasks_Sum_Fields>;
  var_pop?: Maybe<Forge_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Forge_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Forge_Tasks_Variance_Fields>;
};


/** aggregate fields of "Forge_tasks" */
export type Forge_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Forge_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Forge_Tasks_Avg_Fields = {
  __typename?: 'Forge_tasks_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Forge_tasks". All fields are combined with a logical 'AND'. */
export type Forge_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Forge_Tasks_Bool_Exp>>;
  _not?: Maybe<Forge_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Forge_Tasks_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  payload?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Enum_Forge_Status_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Forge_tasks" */
export enum Forge_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  ForgeTasksPkey = 'Forge_tasks_pkey'
}

/** input type for incrementing numeric columns in table "Forge_tasks" */
export type Forge_Tasks_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Forge_tasks" */
export type Forge_Tasks_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payload?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Forge_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Forge_Tasks_Max_Fields = {
  __typename?: 'Forge_tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payload?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Forge_Tasks_Min_Fields = {
  __typename?: 'Forge_tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payload?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Forge_tasks" */
export type Forge_Tasks_Mutation_Response = {
  __typename?: 'Forge_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Forge_Tasks>;
};

/** on_conflict condition type for table "Forge_tasks" */
export type Forge_Tasks_On_Conflict = {
  constraint: Forge_Tasks_Constraint;
  update_columns?: Array<Forge_Tasks_Update_Column>;
  where?: Maybe<Forge_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "Forge_tasks". */
export type Forge_Tasks_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Forge_tasks */
export type Forge_Tasks_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Forge_tasks" */
export enum Forge_Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Forge_tasks" */
export type Forge_Tasks_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payload?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Forge_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Forge_Tasks_Stddev_Fields = {
  __typename?: 'Forge_tasks_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Forge_Tasks_Stddev_Pop_Fields = {
  __typename?: 'Forge_tasks_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Forge_Tasks_Stddev_Samp_Fields = {
  __typename?: 'Forge_tasks_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Forge_Tasks_Sum_Fields = {
  __typename?: 'Forge_tasks_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Forge_tasks" */
export enum Forge_Tasks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Forge_Tasks_Var_Pop_Fields = {
  __typename?: 'Forge_tasks_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Forge_Tasks_Var_Samp_Fields = {
  __typename?: 'Forge_tasks_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Forge_Tasks_Variance_Fields = {
  __typename?: 'Forge_tasks_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Gallery_explore_info" */
export type Gallery_Explore_Info = {
  __typename?: 'Gallery_explore_info';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  gallery_profile_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Gallery_explore_info" */
export type Gallery_Explore_Info_Aggregate = {
  __typename?: 'Gallery_explore_info_aggregate';
  aggregate?: Maybe<Gallery_Explore_Info_Aggregate_Fields>;
  nodes: Array<Gallery_Explore_Info>;
};

/** aggregate fields of "Gallery_explore_info" */
export type Gallery_Explore_Info_Aggregate_Fields = {
  __typename?: 'Gallery_explore_info_aggregate_fields';
  avg?: Maybe<Gallery_Explore_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gallery_Explore_Info_Max_Fields>;
  min?: Maybe<Gallery_Explore_Info_Min_Fields>;
  stddev?: Maybe<Gallery_Explore_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Gallery_Explore_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gallery_Explore_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Gallery_Explore_Info_Sum_Fields>;
  var_pop?: Maybe<Gallery_Explore_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Gallery_Explore_Info_Var_Samp_Fields>;
  variance?: Maybe<Gallery_Explore_Info_Variance_Fields>;
};


/** aggregate fields of "Gallery_explore_info" */
export type Gallery_Explore_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gallery_Explore_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Gallery_Explore_Info_Avg_Fields = {
  __typename?: 'Gallery_explore_info_avg_fields';
  avatar?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Gallery_explore_info". All fields are combined with a logical 'AND'. */
export type Gallery_Explore_Info_Bool_Exp = {
  _and?: Maybe<Array<Gallery_Explore_Info_Bool_Exp>>;
  _not?: Maybe<Gallery_Explore_Info_Bool_Exp>;
  _or?: Maybe<Array<Gallery_Explore_Info_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  city_id?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  gallery_profile_id?: Maybe<Bigint_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  is_public?: Maybe<String_Comparison_Exp>;
  kard_banner?: Maybe<Bigint_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Gallery_Explore_Info_Max_Fields = {
  __typename?: 'Gallery_explore_info_max_fields';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  gallery_profile_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gallery_Explore_Info_Min_Fields = {
  __typename?: 'Gallery_explore_info_min_fields';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  gallery_profile_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Gallery_explore_info". */
export type Gallery_Explore_Info_Order_By = {
  address?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  gallery_profile_id?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Gallery_explore_info" */
export enum Gallery_Explore_Info_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  CityId = 'city_id',
  /** column name */
  Country = 'country',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  GalleryProfileId = 'gallery_profile_id',
  /** column name */
  Handle = 'handle',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  KardBanner = 'kard_banner',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Gallery_Explore_Info_Stddev_Fields = {
  __typename?: 'Gallery_explore_info_stddev_fields';
  avatar?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Gallery_Explore_Info_Stddev_Pop_Fields = {
  __typename?: 'Gallery_explore_info_stddev_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Gallery_Explore_Info_Stddev_Samp_Fields = {
  __typename?: 'Gallery_explore_info_stddev_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Gallery_Explore_Info_Sum_Fields = {
  __typename?: 'Gallery_explore_info_sum_fields';
  avatar?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  gallery_profile_id?: Maybe<Scalars['bigint']>;
  kard_banner?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Gallery_Explore_Info_Var_Pop_Fields = {
  __typename?: 'Gallery_explore_info_var_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Gallery_Explore_Info_Var_Samp_Fields = {
  __typename?: 'Gallery_explore_info_var_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Gallery_Explore_Info_Variance_Fields = {
  __typename?: 'Gallery_explore_info_variance_fields';
  avatar?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Gallery_explore_info_with_city_data" */
export type Gallery_Explore_Info_With_City_Data = {
  __typename?: 'Gallery_explore_info_with_city_data';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  gallery_profile_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state_code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Gallery_explore_info_with_city_data" */
export type Gallery_Explore_Info_With_City_Data_Aggregate = {
  __typename?: 'Gallery_explore_info_with_city_data_aggregate';
  aggregate?: Maybe<Gallery_Explore_Info_With_City_Data_Aggregate_Fields>;
  nodes: Array<Gallery_Explore_Info_With_City_Data>;
};

/** aggregate fields of "Gallery_explore_info_with_city_data" */
export type Gallery_Explore_Info_With_City_Data_Aggregate_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_aggregate_fields';
  avg?: Maybe<Gallery_Explore_Info_With_City_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gallery_Explore_Info_With_City_Data_Max_Fields>;
  min?: Maybe<Gallery_Explore_Info_With_City_Data_Min_Fields>;
  stddev?: Maybe<Gallery_Explore_Info_With_City_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Gallery_Explore_Info_With_City_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gallery_Explore_Info_With_City_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Gallery_Explore_Info_With_City_Data_Sum_Fields>;
  var_pop?: Maybe<Gallery_Explore_Info_With_City_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Gallery_Explore_Info_With_City_Data_Var_Samp_Fields>;
  variance?: Maybe<Gallery_Explore_Info_With_City_Data_Variance_Fields>;
};


/** aggregate fields of "Gallery_explore_info_with_city_data" */
export type Gallery_Explore_Info_With_City_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Gallery_Explore_Info_With_City_Data_Avg_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_avg_fields';
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Gallery_explore_info_with_city_data". All fields are combined with a logical 'AND'. */
export type Gallery_Explore_Info_With_City_Data_Bool_Exp = {
  _and?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Bool_Exp>>;
  _not?: Maybe<Gallery_Explore_Info_With_City_Data_Bool_Exp>;
  _or?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  city_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  gallery_profile_id?: Maybe<Bigint_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  kard_banner?: Maybe<Bigint_Comparison_Exp>;
  lat?: Maybe<Numeric_Comparison_Exp>;
  lng?: Maybe<Numeric_Comparison_Exp>;
  state_code?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Gallery_Explore_Info_With_City_Data_Max_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_max_fields';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  gallery_profile_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state_code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gallery_Explore_Info_With_City_Data_Min_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_min_fields';
  address?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  gallery_profile_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  state_code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Gallery_explore_info_with_city_data". */
export type Gallery_Explore_Info_With_City_Data_Order_By = {
  address?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  gallery_profile_id?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  state_code?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Gallery_explore_info_with_city_data" */
export enum Gallery_Explore_Info_With_City_Data_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  CityId = 'city_id',
  /** column name */
  Country = 'country',
  /** column name */
  GalleryProfileId = 'gallery_profile_id',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  KardBanner = 'kard_banner',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  StateCode = 'state_code',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Gallery_Explore_Info_With_City_Data_Stddev_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_stddev_fields';
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Gallery_Explore_Info_With_City_Data_Stddev_Pop_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_stddev_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Gallery_Explore_Info_With_City_Data_Stddev_Samp_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_stddev_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Gallery_Explore_Info_With_City_Data_Sum_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_sum_fields';
  avatar?: Maybe<Scalars['bigint']>;
  city_id?: Maybe<Scalars['bigint']>;
  gallery_profile_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Gallery_Explore_Info_With_City_Data_Var_Pop_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_var_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Gallery_Explore_Info_With_City_Data_Var_Samp_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_var_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Gallery_Explore_Info_With_City_Data_Variance_Fields = {
  __typename?: 'Gallery_explore_info_with_city_data_variance_fields';
  avatar?: Maybe<Scalars['Float']>;
  city_id?: Maybe<Scalars['Float']>;
  gallery_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type GeoCoorinates = {
  __typename?: 'GeoCoorinates';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type GetActiveSubscriptionResult = {
  __typename?: 'GetActiveSubscriptionResult';
  subscriptionType?: Maybe<Scalars['String']>;
};

export type GetBillingAccountDetailsResult = {
  __typename?: 'GetBillingAccountDetailsResult';
  /** Url at which the customer can see and manage details regarding their subscriptions */
  portalUrl: Scalars['String'];
  subscriptionProducts: Array<SubscriptionProductDetails>;
};

export type GetCollectorByEmailOrHandleReturnType = {
  __typename?: 'GetCollectorByEmailOrHandleReturnType';
  context_id?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  user_exists?: Maybe<Scalars['Boolean']>;
};

export type GetFinalPriceUsingDiscountCodeReturnType = {
  __typename?: 'GetFinalPriceUsingDiscountCodeReturnType';
  context_id?: Maybe<Scalars['bigint']>;
  discount_code_id?: Maybe<Scalars['bigint']>;
  discount_value?: Maybe<Scalars['Float']>;
  discount_value_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  final_discount?: Maybe<Scalars['Float']>;
  final_price?: Maybe<Scalars['Float']>;
  original_price?: Maybe<Scalars['Float']>;
  purchased_item?: Maybe<Scalars['String']>;
  trial_period_days?: Maybe<Scalars['Int']>;
};

export type GetFollowersCountReturnType = {
  __typename?: 'GetFollowersCountReturnType';
  artistFollowers: Scalars['bigint'];
  collectorFollowers: Scalars['bigint'];
  followers: Scalars['bigint'];
};

export type GetMyProvenanceArtworksDataOutputType = {
  __typename?: 'GetMyProvenanceArtworksDataOutputType';
  artistContextId: Scalars['Int'];
  artworks?: Maybe<Array<Maybe<MyProvenanceArtworksType>>>;
};

export type GetProrationForSubscriptionUpdateResult = {
  __typename?: 'GetProrationForSubscriptionUpdateResult';
  prorationAmount: Scalars['Int'];
  prorationDate: Scalars['Int'];
};

/** Stores properties with global values such as deployed commit -- update the permissions when you insert a new entry */
export type Global_Properties = {
  __typename?: 'Global_properties';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "Global_properties" */
export type Global_Properties_Aggregate = {
  __typename?: 'Global_properties_aggregate';
  aggregate?: Maybe<Global_Properties_Aggregate_Fields>;
  nodes: Array<Global_Properties>;
};

/** aggregate fields of "Global_properties" */
export type Global_Properties_Aggregate_Fields = {
  __typename?: 'Global_properties_aggregate_fields';
  avg?: Maybe<Global_Properties_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Global_Properties_Max_Fields>;
  min?: Maybe<Global_Properties_Min_Fields>;
  stddev?: Maybe<Global_Properties_Stddev_Fields>;
  stddev_pop?: Maybe<Global_Properties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Global_Properties_Stddev_Samp_Fields>;
  sum?: Maybe<Global_Properties_Sum_Fields>;
  var_pop?: Maybe<Global_Properties_Var_Pop_Fields>;
  var_samp?: Maybe<Global_Properties_Var_Samp_Fields>;
  variance?: Maybe<Global_Properties_Variance_Fields>;
};


/** aggregate fields of "Global_properties" */
export type Global_Properties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Global_Properties_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Global_Properties_Avg_Fields = {
  __typename?: 'Global_properties_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Global_properties". All fields are combined with a logical 'AND'. */
export type Global_Properties_Bool_Exp = {
  _and?: Maybe<Array<Global_Properties_Bool_Exp>>;
  _not?: Maybe<Global_Properties_Bool_Exp>;
  _or?: Maybe<Array<Global_Properties_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Global_properties" */
export enum Global_Properties_Constraint {
  /** unique or primary key constraint on columns "name" */
  GlobalPropertiesNameKey = 'Global_properties_name_key',
  /** unique or primary key constraint on columns "id" */
  GlobalPropertiesPkey = 'Global_properties_pkey'
}

/** input type for incrementing numeric columns in table "Global_properties" */
export type Global_Properties_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Global_properties" */
export type Global_Properties_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Global_Properties_Max_Fields = {
  __typename?: 'Global_properties_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Global_Properties_Min_Fields = {
  __typename?: 'Global_properties_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Global_properties" */
export type Global_Properties_Mutation_Response = {
  __typename?: 'Global_properties_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Global_Properties>;
};

/** on_conflict condition type for table "Global_properties" */
export type Global_Properties_On_Conflict = {
  constraint: Global_Properties_Constraint;
  update_columns?: Array<Global_Properties_Update_Column>;
  where?: Maybe<Global_Properties_Bool_Exp>;
};

/** Ordering options when selecting data from "Global_properties". */
export type Global_Properties_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: Global_properties */
export type Global_Properties_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Global_properties" */
export enum Global_Properties_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Global_properties" */
export type Global_Properties_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Global_Properties_Stddev_Fields = {
  __typename?: 'Global_properties_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Global_Properties_Stddev_Pop_Fields = {
  __typename?: 'Global_properties_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Global_Properties_Stddev_Samp_Fields = {
  __typename?: 'Global_properties_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Global_Properties_Sum_Fields = {
  __typename?: 'Global_properties_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Global_properties" */
export enum Global_Properties_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Global_Properties_Var_Pop_Fields = {
  __typename?: 'Global_properties_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Global_Properties_Var_Samp_Fields = {
  __typename?: 'Global_properties_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Global_Properties_Variance_Fields = {
  __typename?: 'Global_properties_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Graveyard_Artwork" */
export type Graveyard_Artwork = {
  __typename?: 'Graveyard_Artwork';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  has_ar_animation?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  id: Scalars['bigint'];
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_hard_minted?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "Graveyard_Artwork" */
export type Graveyard_Artwork_Aggregate = {
  __typename?: 'Graveyard_Artwork_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork>;
};

/** aggregate fields of "Graveyard_Artwork" */
export type Graveyard_Artwork_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork" */
export type Graveyard_Artwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_artist_index?: Maybe<Int_Comparison_Exp>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_story_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  has_ar_animation?: Maybe<Boolean_Comparison_Exp>;
  has_video?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  is_hard_minted?: Maybe<Boolean_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  is_transaction_locked?: Maybe<Boolean_Comparison_Exp>;
  last_updater?: Maybe<Bigint_Comparison_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  target_id?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vuforia_rating?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork" */
export enum Graveyard_Artwork_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkGraveyardPkey = 'Artwork_graveyard_pkey'
}

/** columns and relationships of "Graveyard_Artwork_details" */
export type Graveyard_Artwork_Details = {
  __typename?: 'Graveyard_Artwork_details';
  art_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  id: Scalars['bigint'];
  is_dark_mode?: Maybe<Scalars['Boolean']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Graveyard_Artwork_details" */
export type Graveyard_Artwork_Details_Aggregate = {
  __typename?: 'Graveyard_Artwork_details_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_Details_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork_Details>;
};

/** aggregate fields of "Graveyard_Artwork_details" */
export type Graveyard_Artwork_Details_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_details_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_Details_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_Details_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_Details_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_Details_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_Details_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork_details" */
export type Graveyard_Artwork_Details_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_Details_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_Details_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_details_avg_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork_details". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_Details_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_Details_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_Details_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_Details_Bool_Exp>>;
  art_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  creation_date_day?: Maybe<Int_Comparison_Exp>;
  creation_date_month?: Maybe<Int_Comparison_Exp>;
  creation_date_year?: Maybe<Int_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  duration?: Maybe<Int_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_dark_mode?: Maybe<Boolean_Comparison_Exp>;
  materials?: Maybe<String_Comparison_Exp>;
  materials_and_medium?: Maybe<String_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  medium?: Maybe<String_Comparison_Exp>;
  technique?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  tools?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork_details" */
export enum Graveyard_Artwork_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  ArtworkDetailsGraveyardPkey = 'Artwork_details_graveyard_pkey'
}

/** input type for incrementing numeric columns in table "Graveyard_Artwork_details" */
export type Graveyard_Artwork_Details_Inc_Input = {
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  width?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "Graveyard_Artwork_details" */
export type Graveyard_Artwork_Details_Insert_Input = {
  art_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  is_dark_mode?: Maybe<Scalars['Boolean']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_Details_Max_Fields = {
  __typename?: 'Graveyard_Artwork_details_max_fields';
  art_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_Details_Min_Fields = {
  __typename?: 'Graveyard_Artwork_details_min_fields';
  art_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "Graveyard_Artwork_details" */
export type Graveyard_Artwork_Details_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork_Details>;
};

/** on_conflict condition type for table "Graveyard_Artwork_details" */
export type Graveyard_Artwork_Details_On_Conflict = {
  constraint: Graveyard_Artwork_Details_Constraint;
  update_columns?: Array<Graveyard_Artwork_Details_Update_Column>;
  where?: Maybe<Graveyard_Artwork_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork_details". */
export type Graveyard_Artwork_Details_Order_By = {
  art_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creation_date_day?: Maybe<Order_By>;
  creation_date_month?: Maybe<Order_By>;
  creation_date_year?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_dark_mode?: Maybe<Order_By>;
  materials?: Maybe<Order_By>;
  materials_and_medium?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  medium?: Maybe<Order_By>;
  technique?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  tools?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork_details */
export type Graveyard_Artwork_Details_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Graveyard_Artwork_details" */
export enum Graveyard_Artwork_Details_Select_Column {
  /** column name */
  ArtType = 'art_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDateDay = 'creation_date_day',
  /** column name */
  CreationDateMonth = 'creation_date_month',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  Depth = 'depth',
  /** column name */
  Duration = 'duration',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsDarkMode = 'is_dark_mode',
  /** column name */
  Materials = 'materials',
  /** column name */
  MaterialsAndMedium = 'materials_and_medium',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  Medium = 'medium',
  /** column name */
  Technique = 'technique',
  /** column name */
  Title = 'title',
  /** column name */
  Tools = 'tools',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "Graveyard_Artwork_details" */
export type Graveyard_Artwork_Details_Set_Input = {
  art_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  is_dark_mode?: Maybe<Scalars['Boolean']>;
  materials?: Maybe<Scalars['String']>;
  materials_and_medium?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tools?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_Details_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_details_stddev_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_Details_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_details_stddev_pop_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_Details_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_details_stddev_samp_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_Details_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_details_sum_fields';
  creation_date_day?: Maybe<Scalars['Int']>;
  creation_date_month?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  width?: Maybe<Scalars['float8']>;
};

/** columns and relationships of "Graveyard_Artwork_details_translation" */
export type Graveyard_Artwork_Details_Translation = {
  __typename?: 'Graveyard_Artwork_details_translation';
  artwork_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Graveyard_Artwork_details_translation" */
export type Graveyard_Artwork_Details_Translation_Aggregate = {
  __typename?: 'Graveyard_Artwork_details_translation_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_Details_Translation_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork_Details_Translation>;
};

/** aggregate fields of "Graveyard_Artwork_details_translation" */
export type Graveyard_Artwork_Details_Translation_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_Details_Translation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_Details_Translation_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_Details_Translation_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_Details_Translation_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_Details_Translation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_Details_Translation_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_Details_Translation_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_Details_Translation_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_Details_Translation_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_Details_Translation_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork_details_translation" */
export type Graveyard_Artwork_Details_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_Details_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_Details_Translation_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_avg_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork_details_translation". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_Details_Translation_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_Details_Translation_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_Details_Translation_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_Details_Translation_Bool_Exp>>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description_translation?: Maybe<String_Comparison_Exp>;
  dimensions?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  language_code?: Maybe<String_Comparison_Exp>;
  name_translation?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork_details_translation" */
export enum Graveyard_Artwork_Details_Translation_Constraint {
  /** unique or primary key constraint on columns "id" */
  GraveyardArtworkDetailsTranslationPkey = 'Graveyard_Artwork_details_translation_pkey'
}

/** input type for incrementing numeric columns in table "Graveyard_Artwork_details_translation" */
export type Graveyard_Artwork_Details_Translation_Inc_Input = {
  artwork_details_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Graveyard_Artwork_details_translation" */
export type Graveyard_Artwork_Details_Translation_Insert_Input = {
  artwork_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_Details_Translation_Max_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_max_fields';
  artwork_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_Details_Translation_Min_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_min_fields';
  artwork_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Artwork_details_translation" */
export type Graveyard_Artwork_Details_Translation_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_details_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork_Details_Translation>;
};

/** on_conflict condition type for table "Graveyard_Artwork_details_translation" */
export type Graveyard_Artwork_Details_Translation_On_Conflict = {
  constraint: Graveyard_Artwork_Details_Translation_Constraint;
  update_columns?: Array<Graveyard_Artwork_Details_Translation_Update_Column>;
  where?: Maybe<Graveyard_Artwork_Details_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork_details_translation". */
export type Graveyard_Artwork_Details_Translation_Order_By = {
  artwork_details_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  dimensions?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  name_translation?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork_details_translation */
export type Graveyard_Artwork_Details_Translation_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Graveyard_Artwork_details_translation" */
export enum Graveyard_Artwork_Details_Translation_Select_Column {
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionTranslation = 'description_translation',
  /** column name */
  Dimensions = 'dimensions',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  NameTranslation = 'name_translation',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Artwork_details_translation" */
export type Graveyard_Artwork_Details_Translation_Set_Input = {
  artwork_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  name_translation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_Details_Translation_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_stddev_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_Details_Translation_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_stddev_pop_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_Details_Translation_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_stddev_samp_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_Details_Translation_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_sum_fields';
  artwork_details_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Graveyard_Artwork_details_translation" */
export enum Graveyard_Artwork_Details_Translation_Update_Column {
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionTranslation = 'description_translation',
  /** column name */
  Dimensions = 'dimensions',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  NameTranslation = 'name_translation',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_Details_Translation_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_var_pop_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_Details_Translation_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_var_samp_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_Details_Translation_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_details_translation_variance_fields';
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "Graveyard_Artwork_details" */
export enum Graveyard_Artwork_Details_Update_Column {
  /** column name */
  ArtType = 'art_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDateDay = 'creation_date_day',
  /** column name */
  CreationDateMonth = 'creation_date_month',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  Depth = 'depth',
  /** column name */
  Duration = 'duration',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  IsDarkMode = 'is_dark_mode',
  /** column name */
  Materials = 'materials',
  /** column name */
  MaterialsAndMedium = 'materials_and_medium',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  Medium = 'medium',
  /** column name */
  Technique = 'technique',
  /** column name */
  Title = 'title',
  /** column name */
  Tools = 'tools',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Width = 'width'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_Details_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_details_var_pop_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_Details_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_details_var_samp_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_Details_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_details_variance_fields';
  creation_date_day?: Maybe<Scalars['Float']>;
  creation_date_month?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Graveyard_Artwork_events" */
export type Graveyard_Artwork_Events = {
  __typename?: 'Graveyard_Artwork_events';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  order?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "Graveyard_Artwork_events" */
export type Graveyard_Artwork_EventsPayloadArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Graveyard_Artwork_events" */
export type Graveyard_Artwork_Events_Aggregate = {
  __typename?: 'Graveyard_Artwork_events_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_Events_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork_Events>;
};

/** aggregate fields of "Graveyard_Artwork_events" */
export type Graveyard_Artwork_Events_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_events_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_Events_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_Events_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_Events_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_Events_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_Events_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork_events" */
export type Graveyard_Artwork_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Graveyard_Artwork_Events_Append_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_Events_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_events_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork_events". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_Events_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_Events_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_Events_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_Events_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_transaction_id?: Maybe<Bigint_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  event_date?: Maybe<Timestamptz_Comparison_Exp>;
  event_message?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  payload?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork_events" */
export enum Graveyard_Artwork_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  GraveyardArtworkEventsPkey = 'Graveyard_Artwork_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Graveyard_Artwork_Events_Delete_At_Path_Input = {
  payload?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Graveyard_Artwork_Events_Delete_Elem_Input = {
  payload?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Graveyard_Artwork_Events_Delete_Key_Input = {
  payload?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Graveyard_Artwork_events" */
export type Graveyard_Artwork_Events_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Graveyard_Artwork_events" */
export type Graveyard_Artwork_Events_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_Events_Max_Fields = {
  __typename?: 'Graveyard_Artwork_events_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_Events_Min_Fields = {
  __typename?: 'Graveyard_Artwork_events_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Artwork_events" */
export type Graveyard_Artwork_Events_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork_Events>;
};

/** on_conflict condition type for table "Graveyard_Artwork_events" */
export type Graveyard_Artwork_Events_On_Conflict = {
  constraint: Graveyard_Artwork_Events_Constraint;
  update_columns?: Array<Graveyard_Artwork_Events_Update_Column>;
  where?: Maybe<Graveyard_Artwork_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork_events". */
export type Graveyard_Artwork_Events_Order_By = {
  artwork_id?: Maybe<Order_By>;
  artwork_transaction_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  event_message?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork_events */
export type Graveyard_Artwork_Events_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Graveyard_Artwork_Events_Prepend_Input = {
  payload?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Graveyard_Artwork_events" */
export enum Graveyard_Artwork_Events_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkTransactionId = 'artwork_transaction_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventMessage = 'event_message',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Payload = 'payload',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Artwork_events" */
export type Graveyard_Artwork_Events_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_date?: Maybe<Scalars['timestamptz']>;
  event_message?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  payload?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_Events_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_events_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_Events_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_events_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_Events_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_events_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_Events_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_events_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_transaction_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "Graveyard_Artwork_events" */
export enum Graveyard_Artwork_Events_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkTransactionId = 'artwork_transaction_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventDate = 'event_date',
  /** column name */
  EventMessage = 'event_message',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Payload = 'payload',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_Events_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_events_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_Events_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_events_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_Events_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_events_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_transaction_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "Graveyard_Artwork" */
export type Graveyard_Artwork_Inc_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Graveyard_Artwork" */
export type Graveyard_Artwork_Insert_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  has_ar_animation?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_hard_minted?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_Max_Fields = {
  __typename?: 'Graveyard_Artwork_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_Min_Fields = {
  __typename?: 'Graveyard_Artwork_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Graveyard_Artwork" */
export type Graveyard_Artwork_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork>;
};

/** on_conflict condition type for table "Graveyard_Artwork" */
export type Graveyard_Artwork_On_Conflict = {
  constraint: Graveyard_Artwork_Constraint;
  update_columns?: Array<Graveyard_Artwork_Update_Column>;
  where?: Maybe<Graveyard_Artwork_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork". */
export type Graveyard_Artwork_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  has_ar_animation?: Maybe<Order_By>;
  has_video?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  is_hard_minted?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  is_transaction_locked?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork */
export type Graveyard_Artwork_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "Graveyard_Artwork_prices" */
export type Graveyard_Artwork_Prices = {
  __typename?: 'Graveyard_Artwork_prices';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  is_public?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Graveyard_Artwork_prices" */
export type Graveyard_Artwork_Prices_Aggregate = {
  __typename?: 'Graveyard_Artwork_prices_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_Prices_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork_Prices>;
};

/** aggregate fields of "Graveyard_Artwork_prices" */
export type Graveyard_Artwork_Prices_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_prices_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_Prices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_Prices_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_Prices_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_Prices_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_Prices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_Prices_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_Prices_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_Prices_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_Prices_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_Prices_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork_prices" */
export type Graveyard_Artwork_Prices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_Prices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_Prices_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_prices_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork_prices". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_Prices_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_Prices_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_Prices_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_Prices_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork_prices" */
export enum Graveyard_Artwork_Prices_Constraint {
  /** unique or primary key constraint on columns "id" */
  GraveyardArtworkPricesPkey = 'Graveyard_Artwork_prices_pkey'
}

/** input type for incrementing numeric columns in table "Graveyard_Artwork_prices" */
export type Graveyard_Artwork_Prices_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "Graveyard_Artwork_prices" */
export type Graveyard_Artwork_Prices_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_Prices_Max_Fields = {
  __typename?: 'Graveyard_Artwork_prices_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_Prices_Min_Fields = {
  __typename?: 'Graveyard_Artwork_prices_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Artwork_prices" */
export type Graveyard_Artwork_Prices_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_prices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork_Prices>;
};

/** on_conflict condition type for table "Graveyard_Artwork_prices" */
export type Graveyard_Artwork_Prices_On_Conflict = {
  constraint: Graveyard_Artwork_Prices_Constraint;
  update_columns?: Array<Graveyard_Artwork_Prices_Update_Column>;
  where?: Maybe<Graveyard_Artwork_Prices_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork_prices". */
export type Graveyard_Artwork_Prices_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork_prices */
export type Graveyard_Artwork_Prices_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Graveyard_Artwork_prices" */
export enum Graveyard_Artwork_Prices_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Price = 'price',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Artwork_prices" */
export type Graveyard_Artwork_Prices_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_Prices_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_prices_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_Prices_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_prices_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_Prices_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_prices_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_Prices_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_prices_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
};

/** update columns of table "Graveyard_Artwork_prices" */
export enum Graveyard_Artwork_Prices_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Price = 'price',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_Prices_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_prices_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_Prices_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_prices_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_Prices_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_prices_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Graveyard_Artwork_secondary_media" */
export type Graveyard_Artwork_Secondary_Media = {
  __typename?: 'Graveyard_Artwork_secondary_media';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Graveyard_Artwork_secondary_media" */
export type Graveyard_Artwork_Secondary_Media_Aggregate = {
  __typename?: 'Graveyard_Artwork_secondary_media_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_Secondary_Media_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork_Secondary_Media>;
};

/** aggregate fields of "Graveyard_Artwork_secondary_media" */
export type Graveyard_Artwork_Secondary_Media_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_Secondary_Media_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_Secondary_Media_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_Secondary_Media_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_Secondary_Media_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_Secondary_Media_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_Secondary_Media_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_Secondary_Media_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_Secondary_Media_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_Secondary_Media_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_Secondary_Media_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork_secondary_media" */
export type Graveyard_Artwork_Secondary_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_Secondary_Media_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork_secondary_media". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_Secondary_Media_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_Secondary_Media_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork_secondary_media" */
export enum Graveyard_Artwork_Secondary_Media_Constraint {
  /** unique or primary key constraint on columns "id" */
  GraveyardArtworkSecondaryMediaPkey = 'Graveyard_Artwork_secondary_media_pkey'
}

/** input type for incrementing numeric columns in table "Graveyard_Artwork_secondary_media" */
export type Graveyard_Artwork_Secondary_Media_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Graveyard_Artwork_secondary_media" */
export type Graveyard_Artwork_Secondary_Media_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_Secondary_Media_Max_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_Secondary_Media_Min_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Artwork_secondary_media" */
export type Graveyard_Artwork_Secondary_Media_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_secondary_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork_Secondary_Media>;
};

/** on_conflict condition type for table "Graveyard_Artwork_secondary_media" */
export type Graveyard_Artwork_Secondary_Media_On_Conflict = {
  constraint: Graveyard_Artwork_Secondary_Media_Constraint;
  update_columns?: Array<Graveyard_Artwork_Secondary_Media_Update_Column>;
  where?: Maybe<Graveyard_Artwork_Secondary_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork_secondary_media". */
export type Graveyard_Artwork_Secondary_Media_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork_secondary_media */
export type Graveyard_Artwork_Secondary_Media_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Graveyard_Artwork_secondary_media" */
export enum Graveyard_Artwork_Secondary_Media_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Artwork_secondary_media" */
export type Graveyard_Artwork_Secondary_Media_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_Secondary_Media_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_Secondary_Media_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_Secondary_Media_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_Secondary_Media_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "Graveyard_Artwork_secondary_media" */
export enum Graveyard_Artwork_Secondary_Media_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_Secondary_Media_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_Secondary_Media_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_Secondary_Media_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_secondary_media_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** select columns of table "Graveyard_Artwork" */
export enum Graveyard_Artwork_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkArtistIndex = 'artwork_artist_index',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  HasArAnimation = 'has_ar_animation',
  /** column name */
  HasVideo = 'has_video',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsHardMinted = 'is_hard_minted',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  IsTransactionLocked = 'is_transaction_locked',
  /** column name */
  LastUpdater = 'last_updater',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Slug = 'slug',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VuforiaRating = 'vuforia_rating'
}

/** input type for updating data in table "Graveyard_Artwork" */
export type Graveyard_Artwork_Set_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  has_ar_animation?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_hard_minted?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Graveyard_Artwork_story" */
export type Graveyard_Artwork_Story = {
  __typename?: 'Graveyard_Artwork_story';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Graveyard_Artwork_story" */
export type Graveyard_Artwork_Story_Aggregate = {
  __typename?: 'Graveyard_Artwork_story_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_Story_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork_Story>;
};

/** aggregate fields of "Graveyard_Artwork_story" */
export type Graveyard_Artwork_Story_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_story_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_Story_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_Story_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_Story_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_Story_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_Story_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_Story_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_Story_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_Story_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_Story_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_Story_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork_story" */
export type Graveyard_Artwork_Story_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_Story_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_Story_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_story_avg_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork_story". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_Story_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_Story_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_Story_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_Story_Bool_Exp>>;
  audio_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  statement?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork_story" */
export enum Graveyard_Artwork_Story_Constraint {
  /** unique or primary key constraint on columns "id" */
  GraveyardArtworkStoryPkey = 'Graveyard_Artwork_story_pkey'
}

/** input type for incrementing numeric columns in table "Graveyard_Artwork_story" */
export type Graveyard_Artwork_Story_Inc_Input = {
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Graveyard_Artwork_story" */
export type Graveyard_Artwork_Story_Insert_Input = {
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_Story_Max_Fields = {
  __typename?: 'Graveyard_Artwork_story_max_fields';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "Graveyard_Artwork_story_media" */
export type Graveyard_Artwork_Story_Media = {
  __typename?: 'Graveyard_Artwork_story_media';
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Graveyard_Artwork_story_media" */
export type Graveyard_Artwork_Story_Media_Aggregate = {
  __typename?: 'Graveyard_Artwork_story_media_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_Story_Media_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork_Story_Media>;
};

/** aggregate fields of "Graveyard_Artwork_story_media" */
export type Graveyard_Artwork_Story_Media_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_Story_Media_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_Story_Media_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_Story_Media_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_Story_Media_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_Story_Media_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_Story_Media_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_Story_Media_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_Story_Media_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_Story_Media_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_Story_Media_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork_story_media" */
export type Graveyard_Artwork_Story_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_Story_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_Story_Media_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_avg_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork_story_media". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_Story_Media_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_Story_Media_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_Story_Media_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_Story_Media_Bool_Exp>>;
  artwork_story_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork_story_media" */
export enum Graveyard_Artwork_Story_Media_Constraint {
  /** unique or primary key constraint on columns "id" */
  GraveyardArtworkStoryMediaPkey = 'Graveyard_Artwork_story_media_pkey'
}

/** input type for incrementing numeric columns in table "Graveyard_Artwork_story_media" */
export type Graveyard_Artwork_Story_Media_Inc_Input = {
  artwork_story_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Graveyard_Artwork_story_media" */
export type Graveyard_Artwork_Story_Media_Insert_Input = {
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_Story_Media_Max_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_max_fields';
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_Story_Media_Min_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_min_fields';
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Artwork_story_media" */
export type Graveyard_Artwork_Story_Media_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_story_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork_Story_Media>;
};

/** on_conflict condition type for table "Graveyard_Artwork_story_media" */
export type Graveyard_Artwork_Story_Media_On_Conflict = {
  constraint: Graveyard_Artwork_Story_Media_Constraint;
  update_columns?: Array<Graveyard_Artwork_Story_Media_Update_Column>;
  where?: Maybe<Graveyard_Artwork_Story_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork_story_media". */
export type Graveyard_Artwork_Story_Media_Order_By = {
  artwork_story_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork_story_media */
export type Graveyard_Artwork_Story_Media_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Graveyard_Artwork_story_media" */
export enum Graveyard_Artwork_Story_Media_Select_Column {
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Artwork_story_media" */
export type Graveyard_Artwork_Story_Media_Set_Input = {
  artwork_story_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_Story_Media_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_stddev_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_Story_Media_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_stddev_pop_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_Story_Media_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_stddev_samp_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_Story_Media_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_sum_fields';
  artwork_story_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "Graveyard_Artwork_story_media" */
export enum Graveyard_Artwork_Story_Media_Update_Column {
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_Story_Media_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_var_pop_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_Story_Media_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_var_samp_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_Story_Media_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_story_media_variance_fields';
  artwork_story_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_Story_Min_Fields = {
  __typename?: 'Graveyard_Artwork_story_min_fields';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Artwork_story" */
export type Graveyard_Artwork_Story_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_story_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork_Story>;
};

/** on_conflict condition type for table "Graveyard_Artwork_story" */
export type Graveyard_Artwork_Story_On_Conflict = {
  constraint: Graveyard_Artwork_Story_Constraint;
  update_columns?: Array<Graveyard_Artwork_Story_Update_Column>;
  where?: Maybe<Graveyard_Artwork_Story_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork_story". */
export type Graveyard_Artwork_Story_Order_By = {
  audio_metadata_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork_story */
export type Graveyard_Artwork_Story_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Graveyard_Artwork_story" */
export enum Graveyard_Artwork_Story_Select_Column {
  /** column name */
  AudioMetadataId = 'audio_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Statement = 'statement',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Artwork_story" */
export type Graveyard_Artwork_Story_Set_Input = {
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_Story_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_story_stddev_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_Story_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_story_stddev_pop_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_Story_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_story_stddev_samp_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_Story_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_story_sum_fields';
  audio_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Graveyard_Artwork_story" */
export enum Graveyard_Artwork_Story_Update_Column {
  /** column name */
  AudioMetadataId = 'audio_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Statement = 'statement',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_Story_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_story_var_pop_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_Story_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_story_var_samp_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_Story_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_story_variance_fields';
  audio_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "Graveyard_Artwork_to_Collection" */
export type Graveyard_Artwork_To_Collection = {
  __typename?: 'Graveyard_Artwork_to_Collection';
  artwork_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Graveyard_Artwork_to_Collection" */
export type Graveyard_Artwork_To_Collection_Aggregate = {
  __typename?: 'Graveyard_Artwork_to_Collection_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_To_Collection_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork_To_Collection>;
};

/** aggregate fields of "Graveyard_Artwork_to_Collection" */
export type Graveyard_Artwork_To_Collection_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_To_Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_To_Collection_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_To_Collection_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_To_Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_To_Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_To_Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_To_Collection_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_To_Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_To_Collection_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_To_Collection_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork_to_Collection" */
export type Graveyard_Artwork_To_Collection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_To_Collection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_To_Collection_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork_to_Collection". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_To_Collection_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_To_Collection_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_To_Collection_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_To_Collection_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  collection_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork_to_Collection" */
export enum Graveyard_Artwork_To_Collection_Constraint {
  /** unique or primary key constraint on columns "id" */
  GraveyardArtworkToCollectionPkey = 'Graveyard_Artwork_to_Collection_pkey'
}

/** input type for incrementing numeric columns in table "Graveyard_Artwork_to_Collection" */
export type Graveyard_Artwork_To_Collection_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Graveyard_Artwork_to_Collection" */
export type Graveyard_Artwork_To_Collection_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_To_Collection_Max_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_To_Collection_Min_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Artwork_to_Collection" */
export type Graveyard_Artwork_To_Collection_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_to_Collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork_To_Collection>;
};

/** on_conflict condition type for table "Graveyard_Artwork_to_Collection" */
export type Graveyard_Artwork_To_Collection_On_Conflict = {
  constraint: Graveyard_Artwork_To_Collection_Constraint;
  update_columns?: Array<Graveyard_Artwork_To_Collection_Update_Column>;
  where?: Maybe<Graveyard_Artwork_To_Collection_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork_to_Collection". */
export type Graveyard_Artwork_To_Collection_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork_to_Collection */
export type Graveyard_Artwork_To_Collection_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Graveyard_Artwork_to_Collection" */
export enum Graveyard_Artwork_To_Collection_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Artwork_to_Collection" */
export type Graveyard_Artwork_To_Collection_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_To_Collection_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_To_Collection_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_To_Collection_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_To_Collection_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Graveyard_Artwork_to_Collection" */
export enum Graveyard_Artwork_To_Collection_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_To_Collection_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_To_Collection_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_To_Collection_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_to_Collection_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Graveyard_Artwork_transactions" */
export type Graveyard_Artwork_Transactions = {
  __typename?: 'Graveyard_Artwork_transactions';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  price?: Maybe<Scalars['float8']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Graveyard_Artwork_transactions" */
export type Graveyard_Artwork_Transactions_Aggregate = {
  __typename?: 'Graveyard_Artwork_transactions_aggregate';
  aggregate?: Maybe<Graveyard_Artwork_Transactions_Aggregate_Fields>;
  nodes: Array<Graveyard_Artwork_Transactions>;
};

/** aggregate fields of "Graveyard_Artwork_transactions" */
export type Graveyard_Artwork_Transactions_Aggregate_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_aggregate_fields';
  avg?: Maybe<Graveyard_Artwork_Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Artwork_Transactions_Max_Fields>;
  min?: Maybe<Graveyard_Artwork_Transactions_Min_Fields>;
  stddev?: Maybe<Graveyard_Artwork_Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Artwork_Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Artwork_Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Artwork_Transactions_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Artwork_Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Artwork_Transactions_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Artwork_Transactions_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Artwork_transactions" */
export type Graveyard_Artwork_Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Artwork_Transactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Artwork_Transactions_Avg_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Artwork_transactions". All fields are combined with a logical 'AND'. */
export type Graveyard_Artwork_Transactions_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Artwork_Transactions_Bool_Exp>>;
  _not?: Maybe<Graveyard_Artwork_Transactions_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Artwork_Transactions_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  buyer_email?: Maybe<String_Comparison_Exp>;
  context_buyer_id?: Maybe<Bigint_Comparison_Exp>;
  context_seller_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  creation_day?: Maybe<Int_Comparison_Exp>;
  creation_month?: Maybe<Int_Comparison_Exp>;
  creation_year?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  sold_date?: Maybe<Date_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  status_at?: Maybe<Timestamptz_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Graveyard_Artwork_transactions" */
export enum Graveyard_Artwork_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  GraveyardArtworkTransactionsPkey = 'Graveyard_Artwork_transactions_pkey'
}

/** input type for incrementing numeric columns in table "Graveyard_Artwork_transactions" */
export type Graveyard_Artwork_Transactions_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "Graveyard_Artwork_transactions" */
export type Graveyard_Artwork_Transactions_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Artwork_Transactions_Max_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Graveyard_Artwork_Transactions_Min_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Artwork_transactions" */
export type Graveyard_Artwork_Transactions_Mutation_Response = {
  __typename?: 'Graveyard_Artwork_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Artwork_Transactions>;
};

/** on_conflict condition type for table "Graveyard_Artwork_transactions" */
export type Graveyard_Artwork_Transactions_On_Conflict = {
  constraint: Graveyard_Artwork_Transactions_Constraint;
  update_columns?: Array<Graveyard_Artwork_Transactions_Update_Column>;
  where?: Maybe<Graveyard_Artwork_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "Graveyard_Artwork_transactions". */
export type Graveyard_Artwork_Transactions_Order_By = {
  artwork_id?: Maybe<Order_By>;
  buyer_email?: Maybe<Order_By>;
  context_buyer_id?: Maybe<Order_By>;
  context_seller_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creation_day?: Maybe<Order_By>;
  creation_month?: Maybe<Order_By>;
  creation_year?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  sold_date?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Graveyard_Artwork_transactions */
export type Graveyard_Artwork_Transactions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Graveyard_Artwork_transactions" */
export enum Graveyard_Artwork_Transactions_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  BuyerEmail = 'buyer_email',
  /** column name */
  ContextBuyerId = 'context_buyer_id',
  /** column name */
  ContextSellerId = 'context_seller_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDay = 'creation_day',
  /** column name */
  CreationMonth = 'creation_month',
  /** column name */
  CreationYear = 'creation_year',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  SoldDate = 'sold_date',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Artwork_transactions" */
export type Graveyard_Artwork_Transactions_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  buyer_email?: Maybe<Scalars['String']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  sold_date?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Artwork_Transactions_Stddev_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Artwork_Transactions_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Artwork_Transactions_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Artwork_Transactions_Sum_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_buyer_id?: Maybe<Scalars['bigint']>;
  context_seller_id?: Maybe<Scalars['bigint']>;
  creation_day?: Maybe<Scalars['Int']>;
  creation_month?: Maybe<Scalars['Int']>;
  creation_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
};

/** update columns of table "Graveyard_Artwork_transactions" */
export enum Graveyard_Artwork_Transactions_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  BuyerEmail = 'buyer_email',
  /** column name */
  ContextBuyerId = 'context_buyer_id',
  /** column name */
  ContextSellerId = 'context_seller_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDay = 'creation_day',
  /** column name */
  CreationMonth = 'creation_month',
  /** column name */
  CreationYear = 'creation_year',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  SoldDate = 'sold_date',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_Transactions_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_Transactions_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_Transactions_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_transactions_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_buyer_id?: Maybe<Scalars['Float']>;
  context_seller_id?: Maybe<Scalars['Float']>;
  creation_day?: Maybe<Scalars['Float']>;
  creation_month?: Maybe<Scalars['Float']>;
  creation_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** update columns of table "Graveyard_Artwork" */
export enum Graveyard_Artwork_Update_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkArtistIndex = 'artwork_artist_index',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  HasArAnimation = 'has_ar_animation',
  /** column name */
  HasVideo = 'has_video',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsHardMinted = 'is_hard_minted',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  IsTransactionLocked = 'is_transaction_locked',
  /** column name */
  LastUpdater = 'last_updater',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Slug = 'slug',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VuforiaRating = 'vuforia_rating'
}

/** aggregate var_pop on columns */
export type Graveyard_Artwork_Var_Pop_Fields = {
  __typename?: 'Graveyard_Artwork_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Artwork_Var_Samp_Fields = {
  __typename?: 'Graveyard_Artwork_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Artwork_Variance_Fields = {
  __typename?: 'Graveyard_Artwork_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Graveyard_Purchase_inquires" */
export type Graveyard_Purchase_Inquires = {
  __typename?: 'Graveyard_Purchase_inquires';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Graveyard_Purchase_inquires" */
export type Graveyard_Purchase_Inquires_Aggregate = {
  __typename?: 'Graveyard_Purchase_inquires_aggregate';
  aggregate?: Maybe<Graveyard_Purchase_Inquires_Aggregate_Fields>;
  nodes: Array<Graveyard_Purchase_Inquires>;
};

/** aggregate fields of "Graveyard_Purchase_inquires" */
export type Graveyard_Purchase_Inquires_Aggregate_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_aggregate_fields';
  avg?: Maybe<Graveyard_Purchase_Inquires_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Purchase_Inquires_Max_Fields>;
  min?: Maybe<Graveyard_Purchase_Inquires_Min_Fields>;
  stddev?: Maybe<Graveyard_Purchase_Inquires_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Purchase_Inquires_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Purchase_Inquires_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Purchase_Inquires_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Purchase_Inquires_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Purchase_Inquires_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Purchase_Inquires_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Purchase_inquires" */
export type Graveyard_Purchase_Inquires_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Purchase_Inquires_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Purchase_Inquires_Avg_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Purchase_inquires". All fields are combined with a logical 'AND'. */
export type Graveyard_Purchase_Inquires_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Purchase_Inquires_Bool_Exp>>;
  _not?: Maybe<Graveyard_Purchase_Inquires_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Purchase_Inquires_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  context_sender_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "Graveyard_Purchase_inquires" */
export type Graveyard_Purchase_Inquires_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Graveyard_Purchase_inquires" */
export type Graveyard_Purchase_Inquires_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Purchase_Inquires_Max_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Graveyard_Purchase_Inquires_Min_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Purchase_inquires" */
export type Graveyard_Purchase_Inquires_Mutation_Response = {
  __typename?: 'Graveyard_Purchase_inquires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Purchase_Inquires>;
};

/** Ordering options when selecting data from "Graveyard_Purchase_inquires". */
export type Graveyard_Purchase_Inquires_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "Graveyard_Purchase_inquires_outside_users" */
export type Graveyard_Purchase_Inquires_Outside_Users = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  inquirer_email?: Maybe<Scalars['String']>;
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Graveyard_Purchase_inquires_outside_users" */
export type Graveyard_Purchase_Inquires_Outside_Users_Aggregate = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_aggregate';
  aggregate?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Aggregate_Fields>;
  nodes: Array<Graveyard_Purchase_Inquires_Outside_Users>;
};

/** aggregate fields of "Graveyard_Purchase_inquires_outside_users" */
export type Graveyard_Purchase_Inquires_Outside_Users_Aggregate_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_aggregate_fields';
  avg?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Max_Fields>;
  min?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Min_Fields>;
  stddev?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Sum_Fields>;
  var_pop?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Var_Samp_Fields>;
  variance?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Variance_Fields>;
};


/** aggregate fields of "Graveyard_Purchase_inquires_outside_users" */
export type Graveyard_Purchase_Inquires_Outside_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Avg_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Graveyard_Purchase_inquires_outside_users". All fields are combined with a logical 'AND'. */
export type Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp = {
  _and?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp>>;
  _not?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp>;
  _or?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  inquirer_email?: Maybe<String_Comparison_Exp>;
  inquirer_ip_addr?: Maybe<String_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "Graveyard_Purchase_inquires_outside_users" */
export type Graveyard_Purchase_Inquires_Outside_Users_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Graveyard_Purchase_inquires_outside_users" */
export type Graveyard_Purchase_Inquires_Outside_Users_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  inquirer_email?: Maybe<Scalars['String']>;
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Max_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  inquirer_email?: Maybe<Scalars['String']>;
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Min_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  inquirer_email?: Maybe<Scalars['String']>;
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Graveyard_Purchase_inquires_outside_users" */
export type Graveyard_Purchase_Inquires_Outside_Users_Mutation_Response = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Graveyard_Purchase_Inquires_Outside_Users>;
};

/** Ordering options when selecting data from "Graveyard_Purchase_inquires_outside_users". */
export type Graveyard_Purchase_Inquires_Outside_Users_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inquirer_email?: Maybe<Order_By>;
  inquirer_ip_addr?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "Graveyard_Purchase_inquires_outside_users" */
export enum Graveyard_Purchase_Inquires_Outside_Users_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InquirerEmail = 'inquirer_email',
  /** column name */
  InquirerIpAddr = 'inquirer_ip_addr',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Purchase_inquires_outside_users" */
export type Graveyard_Purchase_Inquires_Outside_Users_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  inquirer_email?: Maybe<Scalars['String']>;
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Stddev_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Sum_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Var_Pop_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Var_Samp_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Purchase_Inquires_Outside_Users_Variance_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_outside_users_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** select columns of table "Graveyard_Purchase_inquires" */
export enum Graveyard_Purchase_Inquires_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ContextSenderId = 'context_sender_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Graveyard_Purchase_inquires" */
export type Graveyard_Purchase_Inquires_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Graveyard_Purchase_Inquires_Stddev_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Graveyard_Purchase_Inquires_Stddev_Pop_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Graveyard_Purchase_Inquires_Stddev_Samp_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Graveyard_Purchase_Inquires_Sum_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Graveyard_Purchase_Inquires_Var_Pop_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Graveyard_Purchase_Inquires_Var_Samp_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Graveyard_Purchase_Inquires_Variance_Fields = {
  __typename?: 'Graveyard_Purchase_inquires_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Hours = {
  __typename?: 'Hours';
  display?: Maybe<Scalars['String']>;
  is_local_holiday?: Maybe<Scalars['Boolean']>;
  open_now?: Maybe<Scalars['Boolean']>;
  regular?: Maybe<Array<DaySchedule>>;
};

/** columns and relationships of "Inconsistent_missing_domain_renewal_subscription" */
export type Inconsistent_Missing_Domain_Renewal_Subscription = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription';
  context_id?: Maybe<Scalars['bigint']>;
  domain_id?: Maybe<Scalars['bigint']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_status?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Inconsistent_missing_domain_renewal_subscription" */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Aggregate = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_aggregate';
  aggregate?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Aggregate_Fields>;
  nodes: Array<Inconsistent_Missing_Domain_Renewal_Subscription>;
};

/** aggregate fields of "Inconsistent_missing_domain_renewal_subscription" */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Aggregate_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_aggregate_fields';
  avg?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Max_Fields>;
  min?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Min_Fields>;
  stddev?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Sum_Fields>;
  var_pop?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Var_Samp_Fields>;
  variance?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Variance_Fields>;
};


/** aggregate fields of "Inconsistent_missing_domain_renewal_subscription" */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Avg_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  domain_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Inconsistent_missing_domain_renewal_subscription". All fields are combined with a logical 'AND'. */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Bool_Exp = {
  _and?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Bool_Exp>>;
  _not?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Bool_Exp>;
  _or?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  domain_id?: Maybe<Bigint_Comparison_Exp>;
  domain_name?: Maybe<String_Comparison_Exp>;
  domain_status?: Maybe<String_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Max_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  domain_id?: Maybe<Scalars['bigint']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_status?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Min_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  domain_id?: Maybe<Scalars['bigint']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_status?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Inconsistent_missing_domain_renewal_subscription". */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Order_By = {
  context_id?: Maybe<Order_By>;
  domain_id?: Maybe<Order_By>;
  domain_name?: Maybe<Order_By>;
  domain_status?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
};

/** select columns of table "Inconsistent_missing_domain_renewal_subscription" */
export enum Inconsistent_Missing_Domain_Renewal_Subscription_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  DomainId = 'domain_id',
  /** column name */
  DomainName = 'domain_name',
  /** column name */
  DomainStatus = 'domain_status',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  StripeCustomerId = 'stripe_customer_id'
}

/** aggregate stddev on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Stddev_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  domain_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Stddev_Pop_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  domain_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Stddev_Samp_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  domain_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Sum_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  domain_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Var_Pop_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  domain_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Var_Samp_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  domain_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Inconsistent_Missing_Domain_Renewal_Subscription_Variance_Fields = {
  __typename?: 'Inconsistent_missing_domain_renewal_subscription_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  domain_id?: Maybe<Scalars['Float']>;
};

export type InitiateArtworkPurchaseTransactionResult = {
  __typename?: 'InitiateArtworkPurchaseTransactionResult';
  checkoutUrl: Scalars['String'];
};

export type InitiateBillingSellerOnboardingResult = {
  __typename?: 'InitiateBillingSellerOnboardingResult';
  stripeAccountId: Scalars['String'];
};

export type InitiateCartItemsPurchaseTransactionResult = {
  __typename?: 'InitiateCartItemsPurchaseTransactionResult';
  checkoutUrl: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Collector interests on onboarding. */
export type Interests = {
  __typename?: 'Interests';
  active: Scalars['Boolean'];
  id: Scalars['Int'];
  image_url: Scalars['String'];
  /** An object relationship */
  interest_type: Enum_Interest_Type;
  order: Scalars['Int'];
  translation_key: Scalars['String'];
  type: Enum_Interest_Type_Enum;
};

/** aggregated selection of "Interests" */
export type Interests_Aggregate = {
  __typename?: 'Interests_aggregate';
  aggregate?: Maybe<Interests_Aggregate_Fields>;
  nodes: Array<Interests>;
};

/** aggregate fields of "Interests" */
export type Interests_Aggregate_Fields = {
  __typename?: 'Interests_aggregate_fields';
  avg?: Maybe<Interests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Interests_Max_Fields>;
  min?: Maybe<Interests_Min_Fields>;
  stddev?: Maybe<Interests_Stddev_Fields>;
  stddev_pop?: Maybe<Interests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Interests_Stddev_Samp_Fields>;
  sum?: Maybe<Interests_Sum_Fields>;
  var_pop?: Maybe<Interests_Var_Pop_Fields>;
  var_samp?: Maybe<Interests_Var_Samp_Fields>;
  variance?: Maybe<Interests_Variance_Fields>;
};


/** aggregate fields of "Interests" */
export type Interests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Interests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Interests" */
export type Interests_Aggregate_Order_By = {
  avg?: Maybe<Interests_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Interests_Max_Order_By>;
  min?: Maybe<Interests_Min_Order_By>;
  stddev?: Maybe<Interests_Stddev_Order_By>;
  stddev_pop?: Maybe<Interests_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Interests_Stddev_Samp_Order_By>;
  sum?: Maybe<Interests_Sum_Order_By>;
  var_pop?: Maybe<Interests_Var_Pop_Order_By>;
  var_samp?: Maybe<Interests_Var_Samp_Order_By>;
  variance?: Maybe<Interests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Interests" */
export type Interests_Arr_Rel_Insert_Input = {
  data: Array<Interests_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Interests_On_Conflict>;
};

/** aggregate avg on columns */
export type Interests_Avg_Fields = {
  __typename?: 'Interests_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Interests" */
export type Interests_Avg_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Interests". All fields are combined with a logical 'AND'. */
export type Interests_Bool_Exp = {
  _and?: Maybe<Array<Interests_Bool_Exp>>;
  _not?: Maybe<Interests_Bool_Exp>;
  _or?: Maybe<Array<Interests_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  interest_type?: Maybe<Enum_Interest_Type_Bool_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  translation_key?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Enum_Interest_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "Interests" */
export enum Interests_Constraint {
  /** unique or primary key constraint on columns "id" */
  InterestsPkey = 'Interests_pkey'
}

/** input type for incrementing numeric columns in table "Interests" */
export type Interests_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Interests" */
export type Interests_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  interest_type?: Maybe<Enum_Interest_Type_Obj_Rel_Insert_Input>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Interest_Type_Enum>;
};

/** aggregate max on columns */
export type Interests_Max_Fields = {
  __typename?: 'Interests_max_fields';
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Interests" */
export type Interests_Max_Order_By = {
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  translation_key?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Interests_Min_Fields = {
  __typename?: 'Interests_min_fields';
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Interests" */
export type Interests_Min_Order_By = {
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  translation_key?: Maybe<Order_By>;
};

/** response of any mutation on the table "Interests" */
export type Interests_Mutation_Response = {
  __typename?: 'Interests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Interests>;
};

/** input type for inserting object relation for remote table "Interests" */
export type Interests_Obj_Rel_Insert_Input = {
  data: Interests_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Interests_On_Conflict>;
};

/** on_conflict condition type for table "Interests" */
export type Interests_On_Conflict = {
  constraint: Interests_Constraint;
  update_columns?: Array<Interests_Update_Column>;
  where?: Maybe<Interests_Bool_Exp>;
};

/** Ordering options when selecting data from "Interests". */
export type Interests_Order_By = {
  active?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  interest_type?: Maybe<Enum_Interest_Type_Order_By>;
  order?: Maybe<Order_By>;
  translation_key?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: Interests */
export type Interests_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Interests" */
export enum Interests_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translation_key',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "Interests" */
export type Interests_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Interest_Type_Enum>;
};

/** aggregate stddev on columns */
export type Interests_Stddev_Fields = {
  __typename?: 'Interests_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Interests" */
export type Interests_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Interests_Stddev_Pop_Fields = {
  __typename?: 'Interests_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Interests" */
export type Interests_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Interests_Stddev_Samp_Fields = {
  __typename?: 'Interests_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Interests" */
export type Interests_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Interests_Sum_Fields = {
  __typename?: 'Interests_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Interests" */
export type Interests_Sum_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "Interests" */
export enum Interests_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translation_key',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Interests_Var_Pop_Fields = {
  __typename?: 'Interests_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Interests" */
export type Interests_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Interests_Var_Samp_Fields = {
  __typename?: 'Interests_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Interests" */
export type Interests_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Interests_Variance_Fields = {
  __typename?: 'Interests_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Interests" */
export type Interests_Variance_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

export type InvitationDataByTokenResultType = {
  __typename?: 'InvitationDataByTokenResultType';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  inviter_context_id?: Maybe<Scalars['Int']>;
  preferred_language?: Maybe<Scalars['String']>;
};

export type InvitationResultType = {
  __typename?: 'InvitationResultType';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Table to save general settings for the app (this applies to the app not the user) */
export type Kaleido_General_Settings = {
  __typename?: 'Kaleido_general_settings';
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  id: Scalars['bigint'];
  /** Unique configuration name to fetch its data */
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** Table to save general settings for the app (this applies to the app not the user) */
export type Kaleido_General_SettingsDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Kaleido_general_settings" */
export type Kaleido_General_Settings_Aggregate = {
  __typename?: 'Kaleido_general_settings_aggregate';
  aggregate?: Maybe<Kaleido_General_Settings_Aggregate_Fields>;
  nodes: Array<Kaleido_General_Settings>;
};

/** aggregate fields of "Kaleido_general_settings" */
export type Kaleido_General_Settings_Aggregate_Fields = {
  __typename?: 'Kaleido_general_settings_aggregate_fields';
  avg?: Maybe<Kaleido_General_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Kaleido_General_Settings_Max_Fields>;
  min?: Maybe<Kaleido_General_Settings_Min_Fields>;
  stddev?: Maybe<Kaleido_General_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Kaleido_General_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kaleido_General_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Kaleido_General_Settings_Sum_Fields>;
  var_pop?: Maybe<Kaleido_General_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Kaleido_General_Settings_Var_Samp_Fields>;
  variance?: Maybe<Kaleido_General_Settings_Variance_Fields>;
};


/** aggregate fields of "Kaleido_general_settings" */
export type Kaleido_General_Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kaleido_General_Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Kaleido_General_Settings_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Kaleido_General_Settings_Avg_Fields = {
  __typename?: 'Kaleido_general_settings_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Kaleido_general_settings". All fields are combined with a logical 'AND'. */
export type Kaleido_General_Settings_Bool_Exp = {
  _and?: Maybe<Array<Kaleido_General_Settings_Bool_Exp>>;
  _not?: Maybe<Kaleido_General_Settings_Bool_Exp>;
  _or?: Maybe<Array<Kaleido_General_Settings_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Kaleido_general_settings" */
export enum Kaleido_General_Settings_Constraint {
  /** unique or primary key constraint on columns "name" */
  KaleidoGeneralSettingsNameKey = 'Kaleido_general_settings_name_key',
  /** unique or primary key constraint on columns "id" */
  KaleidoGeneralSettingsPkey = 'Kaleido_general_settings_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Kaleido_General_Settings_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Kaleido_General_Settings_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Kaleido_General_Settings_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Kaleido_general_settings" */
export type Kaleido_General_Settings_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Kaleido_general_settings" */
export type Kaleido_General_Settings_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  /** Unique configuration name to fetch its data */
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Kaleido_General_Settings_Max_Fields = {
  __typename?: 'Kaleido_general_settings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Unique configuration name to fetch its data */
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Kaleido_General_Settings_Min_Fields = {
  __typename?: 'Kaleido_general_settings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Unique configuration name to fetch its data */
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Kaleido_general_settings" */
export type Kaleido_General_Settings_Mutation_Response = {
  __typename?: 'Kaleido_general_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Kaleido_General_Settings>;
};

/** on_conflict condition type for table "Kaleido_general_settings" */
export type Kaleido_General_Settings_On_Conflict = {
  constraint: Kaleido_General_Settings_Constraint;
  update_columns?: Array<Kaleido_General_Settings_Update_Column>;
  where?: Maybe<Kaleido_General_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "Kaleido_general_settings". */
export type Kaleido_General_Settings_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Kaleido_general_settings */
export type Kaleido_General_Settings_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Kaleido_General_Settings_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Kaleido_general_settings" */
export enum Kaleido_General_Settings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Kaleido_general_settings" */
export type Kaleido_General_Settings_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  /** Unique configuration name to fetch its data */
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Kaleido_General_Settings_Stddev_Fields = {
  __typename?: 'Kaleido_general_settings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Kaleido_General_Settings_Stddev_Pop_Fields = {
  __typename?: 'Kaleido_general_settings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Kaleido_General_Settings_Stddev_Samp_Fields = {
  __typename?: 'Kaleido_general_settings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Kaleido_General_Settings_Sum_Fields = {
  __typename?: 'Kaleido_general_settings_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Kaleido_general_settings" */
export enum Kaleido_General_Settings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Kaleido_General_Settings_Var_Pop_Fields = {
  __typename?: 'Kaleido_general_settings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Kaleido_General_Settings_Var_Samp_Fields = {
  __typename?: 'Kaleido_general_settings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Kaleido_General_Settings_Variance_Fields = {
  __typename?: 'Kaleido_general_settings_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Kaleido_live_listing" */
export type Kaleido_Live_Listing = {
  __typename?: 'Kaleido_live_listing';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  artist_recognitions: Array<Social_Graph_Followers>;
  /** An aggregate relationship */
  artist_recognitions_aggregate: Social_Graph_Followers_Aggregate;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  /** An object relationship */
  aspect?: Maybe<Artwork_Aspect_Ratio>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "Kaleido_live_listing" */
export type Kaleido_Live_ListingArtist_RecognitionsArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


/** columns and relationships of "Kaleido_live_listing" */
export type Kaleido_Live_ListingArtist_Recognitions_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};

/** aggregated selection of "Kaleido_live_listing" */
export type Kaleido_Live_Listing_Aggregate = {
  __typename?: 'Kaleido_live_listing_aggregate';
  aggregate?: Maybe<Kaleido_Live_Listing_Aggregate_Fields>;
  nodes: Array<Kaleido_Live_Listing>;
};

/** aggregate fields of "Kaleido_live_listing" */
export type Kaleido_Live_Listing_Aggregate_Fields = {
  __typename?: 'Kaleido_live_listing_aggregate_fields';
  avg?: Maybe<Kaleido_Live_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Kaleido_Live_Listing_Max_Fields>;
  min?: Maybe<Kaleido_Live_Listing_Min_Fields>;
  stddev?: Maybe<Kaleido_Live_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Kaleido_Live_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kaleido_Live_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Kaleido_Live_Listing_Sum_Fields>;
  var_pop?: Maybe<Kaleido_Live_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Kaleido_Live_Listing_Var_Samp_Fields>;
  variance?: Maybe<Kaleido_Live_Listing_Variance_Fields>;
};


/** aggregate fields of "Kaleido_live_listing" */
export type Kaleido_Live_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kaleido_Live_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Kaleido_Live_Listing_Avg_Fields = {
  __typename?: 'Kaleido_live_listing_avg_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Kaleido_live_listing". All fields are combined with a logical 'AND'. */
export type Kaleido_Live_Listing_Bool_Exp = {
  _and?: Maybe<Array<Kaleido_Live_Listing_Bool_Exp>>;
  _not?: Maybe<Kaleido_Live_Listing_Bool_Exp>;
  _or?: Maybe<Array<Kaleido_Live_Listing_Bool_Exp>>;
  art_prize_show_id?: Maybe<Bigint_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_recognitions?: Maybe<Social_Graph_Followers_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_score?: Maybe<Int_Comparison_Exp>;
  aspect?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
  collected_by_context_id?: Maybe<Bigint_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  vuforia_rating?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Kaleido_Live_Listing_Max_Fields = {
  __typename?: 'Kaleido_live_listing_max_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Kaleido_Live_Listing_Min_Fields = {
  __typename?: 'Kaleido_live_listing_min_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "Kaleido_live_listing". */
export type Kaleido_Live_Listing_Order_By = {
  art_prize_show_id?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artist_recognitions_aggregate?: Maybe<Social_Graph_Followers_Aggregate_Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_score?: Maybe<Order_By>;
  aspect?: Maybe<Artwork_Aspect_Ratio_Order_By>;
  collected_by_context_id?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
};

/** select columns of table "Kaleido_live_listing" */
export enum Kaleido_Live_Listing_Select_Column {
  /** column name */
  ArtPrizeShowId = 'art_prize_show_id',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkScore = 'artwork_score',
  /** column name */
  CollectedByContextId = 'collected_by_context_id',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  EventType = 'event_type',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Score = 'score',
  /** column name */
  VuforiaRating = 'vuforia_rating'
}

/** aggregate stddev on columns */
export type Kaleido_Live_Listing_Stddev_Fields = {
  __typename?: 'Kaleido_live_listing_stddev_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Kaleido_Live_Listing_Stddev_Pop_Fields = {
  __typename?: 'Kaleido_live_listing_stddev_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Kaleido_Live_Listing_Stddev_Samp_Fields = {
  __typename?: 'Kaleido_live_listing_stddev_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Kaleido_Live_Listing_Sum_Fields = {
  __typename?: 'Kaleido_live_listing_sum_fields';
  art_prize_show_id?: Maybe<Scalars['bigint']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_score?: Maybe<Scalars['Int']>;
  collected_by_context_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  score?: Maybe<Scalars['Int']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Kaleido_Live_Listing_Var_Pop_Fields = {
  __typename?: 'Kaleido_live_listing_var_pop_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Kaleido_Live_Listing_Var_Samp_Fields = {
  __typename?: 'Kaleido_live_listing_var_samp_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Kaleido_Live_Listing_Variance_Fields = {
  __typename?: 'Kaleido_live_listing_variance_fields';
  art_prize_show_id?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_score?: Maybe<Scalars['Float']>;
  collected_by_context_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Landing_page_artwork" */
export type Landing_Page_Artwork = {
  __typename?: 'Landing_page_artwork';
  active: Scalars['Boolean'];
  /** An object relationship */
  artwork: Artwork;
  artwork_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Landing_page_artwork" */
export type Landing_Page_Artwork_Aggregate = {
  __typename?: 'Landing_page_artwork_aggregate';
  aggregate?: Maybe<Landing_Page_Artwork_Aggregate_Fields>;
  nodes: Array<Landing_Page_Artwork>;
};

/** aggregate fields of "Landing_page_artwork" */
export type Landing_Page_Artwork_Aggregate_Fields = {
  __typename?: 'Landing_page_artwork_aggregate_fields';
  avg?: Maybe<Landing_Page_Artwork_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Landing_Page_Artwork_Max_Fields>;
  min?: Maybe<Landing_Page_Artwork_Min_Fields>;
  stddev?: Maybe<Landing_Page_Artwork_Stddev_Fields>;
  stddev_pop?: Maybe<Landing_Page_Artwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Landing_Page_Artwork_Stddev_Samp_Fields>;
  sum?: Maybe<Landing_Page_Artwork_Sum_Fields>;
  var_pop?: Maybe<Landing_Page_Artwork_Var_Pop_Fields>;
  var_samp?: Maybe<Landing_Page_Artwork_Var_Samp_Fields>;
  variance?: Maybe<Landing_Page_Artwork_Variance_Fields>;
};


/** aggregate fields of "Landing_page_artwork" */
export type Landing_Page_Artwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Landing_Page_Artwork_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Landing_Page_Artwork_Avg_Fields = {
  __typename?: 'Landing_page_artwork_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Landing_page_artwork". All fields are combined with a logical 'AND'. */
export type Landing_Page_Artwork_Bool_Exp = {
  _and?: Maybe<Array<Landing_Page_Artwork_Bool_Exp>>;
  _not?: Maybe<Landing_Page_Artwork_Bool_Exp>;
  _or?: Maybe<Array<Landing_Page_Artwork_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Landing_page_artwork" */
export enum Landing_Page_Artwork_Constraint {
  /** unique or primary key constraint on columns "id" */
  LandingPageArtworkPkey = 'Landing_page_artwork_pkey'
}

/** input type for incrementing numeric columns in table "Landing_page_artwork" */
export type Landing_Page_Artwork_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Landing_page_artwork" */
export type Landing_Page_Artwork_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Landing_Page_Artwork_Max_Fields = {
  __typename?: 'Landing_page_artwork_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Landing_Page_Artwork_Min_Fields = {
  __typename?: 'Landing_page_artwork_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Landing_page_artwork" */
export type Landing_Page_Artwork_Mutation_Response = {
  __typename?: 'Landing_page_artwork_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Landing_Page_Artwork>;
};

/** on_conflict condition type for table "Landing_page_artwork" */
export type Landing_Page_Artwork_On_Conflict = {
  constraint: Landing_Page_Artwork_Constraint;
  update_columns?: Array<Landing_Page_Artwork_Update_Column>;
  where?: Maybe<Landing_Page_Artwork_Bool_Exp>;
};

/** Ordering options when selecting data from "Landing_page_artwork". */
export type Landing_Page_Artwork_Order_By = {
  active?: Maybe<Order_By>;
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Landing_page_artwork */
export type Landing_Page_Artwork_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Landing_page_artwork" */
export enum Landing_Page_Artwork_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Landing_page_artwork" */
export type Landing_Page_Artwork_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Landing_Page_Artwork_Stddev_Fields = {
  __typename?: 'Landing_page_artwork_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Landing_Page_Artwork_Stddev_Pop_Fields = {
  __typename?: 'Landing_page_artwork_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Landing_Page_Artwork_Stddev_Samp_Fields = {
  __typename?: 'Landing_page_artwork_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Landing_Page_Artwork_Sum_Fields = {
  __typename?: 'Landing_page_artwork_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Landing_page_artwork" */
export enum Landing_Page_Artwork_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Landing_Page_Artwork_Var_Pop_Fields = {
  __typename?: 'Landing_page_artwork_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Landing_Page_Artwork_Var_Samp_Fields = {
  __typename?: 'Landing_page_artwork_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Landing_Page_Artwork_Variance_Fields = {
  __typename?: 'Landing_page_artwork_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Stores the code of the language (en/US, ro/RO, jp/JP) and information related to localization. */
export type Languages = {
  __typename?: 'Languages';
  /** An array relationship */
  Artwork_details_translations: Array<Artwork_Details_Translation>;
  /** An aggregate relationship */
  Artwork_details_translations_aggregate: Artwork_Details_Translation_Aggregate;
  /** An array relationship */
  Collection_translations: Array<Collection_Translation>;
  /** An aggregate relationship */
  Collection_translations_aggregate: Collection_Translation_Aggregate;
  /** An array relationship */
  Location_translations: Array<Profile_Location_Translation>;
  /** An aggregate relationship */
  Location_translations_aggregate: Profile_Location_Translation_Aggregate;
  /** An array relationship */
  Profile_translations: Array<Profile_Translation>;
  /** An aggregate relationship */
  Profile_translations_aggregate: Profile_Translation_Aggregate;
  created_at: Scalars['timestamptz'];
  currency?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  language_code: Scalars['String'];
  name: Scalars['String'];
  name_in_native_language: Scalars['String'];
  time_format?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  value?: Maybe<Scalars['String']>;
};


/** Stores the code of the language (en/US, ro/RO, jp/JP) and information related to localization. */
export type LanguagesArtwork_Details_TranslationsArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Artwork_Details_Translation_Bool_Exp>;
};


/** Stores the code of the language (en/US, ro/RO, jp/JP) and information related to localization. */
export type LanguagesArtwork_Details_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Artwork_Details_Translation_Bool_Exp>;
};


/** Stores the code of the language (en/US, ro/RO, jp/JP) and information related to localization. */
export type LanguagesCollection_TranslationsArgs = {
  distinct_on?: Maybe<Array<Collection_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Translation_Order_By>>;
  where?: Maybe<Collection_Translation_Bool_Exp>;
};


/** Stores the code of the language (en/US, ro/RO, jp/JP) and information related to localization. */
export type LanguagesCollection_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Translation_Order_By>>;
  where?: Maybe<Collection_Translation_Bool_Exp>;
};


/** Stores the code of the language (en/US, ro/RO, jp/JP) and information related to localization. */
export type LanguagesLocation_TranslationsArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Translation_Order_By>>;
  where?: Maybe<Profile_Location_Translation_Bool_Exp>;
};


/** Stores the code of the language (en/US, ro/RO, jp/JP) and information related to localization. */
export type LanguagesLocation_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Translation_Order_By>>;
  where?: Maybe<Profile_Location_Translation_Bool_Exp>;
};


/** Stores the code of the language (en/US, ro/RO, jp/JP) and information related to localization. */
export type LanguagesProfile_TranslationsArgs = {
  distinct_on?: Maybe<Array<Profile_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Translation_Order_By>>;
  where?: Maybe<Profile_Translation_Bool_Exp>;
};


/** Stores the code of the language (en/US, ro/RO, jp/JP) and information related to localization. */
export type LanguagesProfile_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Translation_Order_By>>;
  where?: Maybe<Profile_Translation_Bool_Exp>;
};

/** aggregated selection of "Languages" */
export type Languages_Aggregate = {
  __typename?: 'Languages_aggregate';
  aggregate?: Maybe<Languages_Aggregate_Fields>;
  nodes: Array<Languages>;
};

/** aggregate fields of "Languages" */
export type Languages_Aggregate_Fields = {
  __typename?: 'Languages_aggregate_fields';
  avg?: Maybe<Languages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Languages_Max_Fields>;
  min?: Maybe<Languages_Min_Fields>;
  stddev?: Maybe<Languages_Stddev_Fields>;
  stddev_pop?: Maybe<Languages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Languages_Stddev_Samp_Fields>;
  sum?: Maybe<Languages_Sum_Fields>;
  var_pop?: Maybe<Languages_Var_Pop_Fields>;
  var_samp?: Maybe<Languages_Var_Samp_Fields>;
  variance?: Maybe<Languages_Variance_Fields>;
};


/** aggregate fields of "Languages" */
export type Languages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Languages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Languages_Avg_Fields = {
  __typename?: 'Languages_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Languages". All fields are combined with a logical 'AND'. */
export type Languages_Bool_Exp = {
  Artwork_details_translations?: Maybe<Artwork_Details_Translation_Bool_Exp>;
  Collection_translations?: Maybe<Collection_Translation_Bool_Exp>;
  Location_translations?: Maybe<Profile_Location_Translation_Bool_Exp>;
  Profile_translations?: Maybe<Profile_Translation_Bool_Exp>;
  _and?: Maybe<Array<Languages_Bool_Exp>>;
  _not?: Maybe<Languages_Bool_Exp>;
  _or?: Maybe<Array<Languages_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  language_code?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  name_in_native_language?: Maybe<String_Comparison_Exp>;
  time_format?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Languages" */
export enum Languages_Constraint {
  /** unique or primary key constraint on columns "language_code" */
  LanguagesPkey = 'Languages_pkey'
}

/** input type for incrementing numeric columns in table "Languages" */
export type Languages_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Languages" */
export type Languages_Insert_Input = {
  Artwork_details_translations?: Maybe<Artwork_Details_Translation_Arr_Rel_Insert_Input>;
  Collection_translations?: Maybe<Collection_Translation_Arr_Rel_Insert_Input>;
  Location_translations?: Maybe<Profile_Location_Translation_Arr_Rel_Insert_Input>;
  Profile_translations?: Maybe<Profile_Translation_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  language_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_in_native_language?: Maybe<Scalars['String']>;
  time_format?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Languages_Max_Fields = {
  __typename?: 'Languages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  language_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_in_native_language?: Maybe<Scalars['String']>;
  time_format?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Languages_Min_Fields = {
  __typename?: 'Languages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  language_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_in_native_language?: Maybe<Scalars['String']>;
  time_format?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Languages" */
export type Languages_Mutation_Response = {
  __typename?: 'Languages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Languages>;
};

/** input type for inserting object relation for remote table "Languages" */
export type Languages_Obj_Rel_Insert_Input = {
  data: Languages_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Languages_On_Conflict>;
};

/** on_conflict condition type for table "Languages" */
export type Languages_On_Conflict = {
  constraint: Languages_Constraint;
  update_columns?: Array<Languages_Update_Column>;
  where?: Maybe<Languages_Bool_Exp>;
};

/** Ordering options when selecting data from "Languages". */
export type Languages_Order_By = {
  Artwork_details_translations_aggregate?: Maybe<Artwork_Details_Translation_Aggregate_Order_By>;
  Collection_translations_aggregate?: Maybe<Collection_Translation_Aggregate_Order_By>;
  Location_translations_aggregate?: Maybe<Profile_Location_Translation_Aggregate_Order_By>;
  Profile_translations_aggregate?: Maybe<Profile_Translation_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_in_native_language?: Maybe<Order_By>;
  time_format?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: Languages */
export type Languages_Pk_Columns_Input = {
  language_code: Scalars['String'];
};

/** select columns of table "Languages" */
export enum Languages_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  Name = 'name',
  /** column name */
  NameInNativeLanguage = 'name_in_native_language',
  /** column name */
  TimeFormat = 'time_format',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Languages" */
export type Languages_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  language_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_in_native_language?: Maybe<Scalars['String']>;
  time_format?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Languages_Stddev_Fields = {
  __typename?: 'Languages_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Languages_Stddev_Pop_Fields = {
  __typename?: 'Languages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Languages_Stddev_Samp_Fields = {
  __typename?: 'Languages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Languages_Sum_Fields = {
  __typename?: 'Languages_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Languages" */
export enum Languages_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  Name = 'name',
  /** column name */
  NameInNativeLanguage = 'name_in_native_language',
  /** column name */
  TimeFormat = 'time_format',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Languages_Var_Pop_Fields = {
  __typename?: 'Languages_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Languages_Var_Samp_Fields = {
  __typename?: 'Languages_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Languages_Variance_Fields = {
  __typename?: 'Languages_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export enum Marker_Type {
  ArtGallery = 'ART_GALLERY',
  ArtMuseum = 'ART_MUSEUM',
  EroticMuseum = 'EROTIC_MUSEUM',
  HistoryMuseum = 'HISTORY_MUSEUM',
  Museum = 'MUSEUM',
  PublicArt = 'PUBLIC_ART',
  ScienceMuseum = 'SCIENCE_MUSEUM'
}

export type MaterializePrintResult = {
  __typename?: 'MaterializePrintResult';
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  print_number?: Maybe<Scalars['Int']>;
};

/** Table with trigger attached to control the refresh of the materialized views */
export type Materialized_View = {
  __typename?: 'Materialized_view';
  name: Scalars['String'];
  refresh: Scalars['Int'];
  refresh_cooldown_seconds: Scalars['Int'];
  refreshed_at: Scalars['timestamptz'];
};

/** aggregated selection of "Materialized_view" */
export type Materialized_View_Aggregate = {
  __typename?: 'Materialized_view_aggregate';
  aggregate?: Maybe<Materialized_View_Aggregate_Fields>;
  nodes: Array<Materialized_View>;
};

/** aggregate fields of "Materialized_view" */
export type Materialized_View_Aggregate_Fields = {
  __typename?: 'Materialized_view_aggregate_fields';
  avg?: Maybe<Materialized_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Materialized_View_Max_Fields>;
  min?: Maybe<Materialized_View_Min_Fields>;
  stddev?: Maybe<Materialized_View_Stddev_Fields>;
  stddev_pop?: Maybe<Materialized_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Materialized_View_Stddev_Samp_Fields>;
  sum?: Maybe<Materialized_View_Sum_Fields>;
  var_pop?: Maybe<Materialized_View_Var_Pop_Fields>;
  var_samp?: Maybe<Materialized_View_Var_Samp_Fields>;
  variance?: Maybe<Materialized_View_Variance_Fields>;
};


/** aggregate fields of "Materialized_view" */
export type Materialized_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Materialized_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Materialized_View_Avg_Fields = {
  __typename?: 'Materialized_view_avg_fields';
  refresh?: Maybe<Scalars['Float']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Materialized_view". All fields are combined with a logical 'AND'. */
export type Materialized_View_Bool_Exp = {
  _and?: Maybe<Array<Materialized_View_Bool_Exp>>;
  _not?: Maybe<Materialized_View_Bool_Exp>;
  _or?: Maybe<Array<Materialized_View_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
  refresh?: Maybe<Int_Comparison_Exp>;
  refresh_cooldown_seconds?: Maybe<Int_Comparison_Exp>;
  refreshed_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Materialized_view" */
export enum Materialized_View_Constraint {
  /** unique or primary key constraint on columns "name" */
  MaterializedViewPkey = 'Materialized_view_pkey'
}

/** input type for incrementing numeric columns in table "Materialized_view" */
export type Materialized_View_Inc_Input = {
  refresh?: Maybe<Scalars['Int']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Materialized_view" */
export type Materialized_View_Insert_Input = {
  name?: Maybe<Scalars['String']>;
  refresh?: Maybe<Scalars['Int']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Int']>;
  refreshed_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Materialized_View_Max_Fields = {
  __typename?: 'Materialized_view_max_fields';
  name?: Maybe<Scalars['String']>;
  refresh?: Maybe<Scalars['Int']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Int']>;
  refreshed_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Materialized_View_Min_Fields = {
  __typename?: 'Materialized_view_min_fields';
  name?: Maybe<Scalars['String']>;
  refresh?: Maybe<Scalars['Int']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Int']>;
  refreshed_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Materialized_view" */
export type Materialized_View_Mutation_Response = {
  __typename?: 'Materialized_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Materialized_View>;
};

/** on_conflict condition type for table "Materialized_view" */
export type Materialized_View_On_Conflict = {
  constraint: Materialized_View_Constraint;
  update_columns?: Array<Materialized_View_Update_Column>;
  where?: Maybe<Materialized_View_Bool_Exp>;
};

/** Ordering options when selecting data from "Materialized_view". */
export type Materialized_View_Order_By = {
  name?: Maybe<Order_By>;
  refresh?: Maybe<Order_By>;
  refresh_cooldown_seconds?: Maybe<Order_By>;
  refreshed_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Materialized_view */
export type Materialized_View_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "Materialized_view" */
export enum Materialized_View_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Refresh = 'refresh',
  /** column name */
  RefreshCooldownSeconds = 'refresh_cooldown_seconds',
  /** column name */
  RefreshedAt = 'refreshed_at'
}

/** input type for updating data in table "Materialized_view" */
export type Materialized_View_Set_Input = {
  name?: Maybe<Scalars['String']>;
  refresh?: Maybe<Scalars['Int']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Int']>;
  refreshed_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Materialized_View_Stddev_Fields = {
  __typename?: 'Materialized_view_stddev_fields';
  refresh?: Maybe<Scalars['Float']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Materialized_View_Stddev_Pop_Fields = {
  __typename?: 'Materialized_view_stddev_pop_fields';
  refresh?: Maybe<Scalars['Float']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Materialized_View_Stddev_Samp_Fields = {
  __typename?: 'Materialized_view_stddev_samp_fields';
  refresh?: Maybe<Scalars['Float']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Materialized_View_Sum_Fields = {
  __typename?: 'Materialized_view_sum_fields';
  refresh?: Maybe<Scalars['Int']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Int']>;
};

/** update columns of table "Materialized_view" */
export enum Materialized_View_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Refresh = 'refresh',
  /** column name */
  RefreshCooldownSeconds = 'refresh_cooldown_seconds',
  /** column name */
  RefreshedAt = 'refreshed_at'
}

/** aggregate var_pop on columns */
export type Materialized_View_Var_Pop_Fields = {
  __typename?: 'Materialized_view_var_pop_fields';
  refresh?: Maybe<Scalars['Float']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Materialized_View_Var_Samp_Fields = {
  __typename?: 'Materialized_view_var_samp_fields';
  refresh?: Maybe<Scalars['Float']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Materialized_View_Variance_Fields = {
  __typename?: 'Materialized_view_variance_fields';
  refresh?: Maybe<Scalars['Float']>;
  refresh_cooldown_seconds?: Maybe<Scalars['Float']>;
};

/** General table to store media files( images, videos, profile pictures, banners, etc.) or other files */
export type Media_Metadata = {
  __typename?: 'Media_metadata';
  created_at: Scalars['timestamptz'];
  crop_rect_x?: Maybe<Scalars['Int']>;
  crop_rect_y?: Maybe<Scalars['Int']>;
  extension: Scalars['String'];
  id: Scalars['bigint'];
  path: Scalars['String'];
  type: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Media_metadata" */
export type Media_Metadata_Aggregate = {
  __typename?: 'Media_metadata_aggregate';
  aggregate?: Maybe<Media_Metadata_Aggregate_Fields>;
  nodes: Array<Media_Metadata>;
};

/** aggregate fields of "Media_metadata" */
export type Media_Metadata_Aggregate_Fields = {
  __typename?: 'Media_metadata_aggregate_fields';
  avg?: Maybe<Media_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Media_Metadata_Max_Fields>;
  min?: Maybe<Media_Metadata_Min_Fields>;
  stddev?: Maybe<Media_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Media_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Media_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Media_Metadata_Sum_Fields>;
  var_pop?: Maybe<Media_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Media_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Media_Metadata_Variance_Fields>;
};


/** aggregate fields of "Media_metadata" */
export type Media_Metadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Media_Metadata_Avg_Fields = {
  __typename?: 'Media_metadata_avg_fields';
  crop_rect_x?: Maybe<Scalars['Float']>;
  crop_rect_y?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Media_metadata". All fields are combined with a logical 'AND'. */
export type Media_Metadata_Bool_Exp = {
  _and?: Maybe<Array<Media_Metadata_Bool_Exp>>;
  _not?: Maybe<Media_Metadata_Bool_Exp>;
  _or?: Maybe<Array<Media_Metadata_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  crop_rect_x?: Maybe<Int_Comparison_Exp>;
  crop_rect_y?: Maybe<Int_Comparison_Exp>;
  extension?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  path?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Media_metadata" */
export enum Media_Metadata_Constraint {
  /** unique or primary key constraint on columns "id" */
  ResourcesPkey = 'Resources_pkey'
}

/** input type for incrementing numeric columns in table "Media_metadata" */
export type Media_Metadata_Inc_Input = {
  crop_rect_x?: Maybe<Scalars['Int']>;
  crop_rect_y?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Media_metadata" */
export type Media_Metadata_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_rect_x?: Maybe<Scalars['Int']>;
  crop_rect_y?: Maybe<Scalars['Int']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Media_Metadata_Max_Fields = {
  __typename?: 'Media_metadata_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_rect_x?: Maybe<Scalars['Int']>;
  crop_rect_y?: Maybe<Scalars['Int']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Media_Metadata_Min_Fields = {
  __typename?: 'Media_metadata_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_rect_x?: Maybe<Scalars['Int']>;
  crop_rect_y?: Maybe<Scalars['Int']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Media_metadata" */
export type Media_Metadata_Mutation_Response = {
  __typename?: 'Media_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Media_Metadata>;
};

/** on_conflict condition type for table "Media_metadata" */
export type Media_Metadata_On_Conflict = {
  constraint: Media_Metadata_Constraint;
  update_columns?: Array<Media_Metadata_Update_Column>;
  where?: Maybe<Media_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "Media_metadata". */
export type Media_Metadata_Order_By = {
  created_at?: Maybe<Order_By>;
  crop_rect_x?: Maybe<Order_By>;
  crop_rect_y?: Maybe<Order_By>;
  extension?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  path?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Media_metadata */
export type Media_Metadata_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Media_metadata" */
export enum Media_Metadata_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropRectX = 'crop_rect_x',
  /** column name */
  CropRectY = 'crop_rect_y',
  /** column name */
  Extension = 'extension',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Media_metadata" */
export type Media_Metadata_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_rect_x?: Maybe<Scalars['Int']>;
  crop_rect_y?: Maybe<Scalars['Int']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Media_Metadata_Stddev_Fields = {
  __typename?: 'Media_metadata_stddev_fields';
  crop_rect_x?: Maybe<Scalars['Float']>;
  crop_rect_y?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Media_Metadata_Stddev_Pop_Fields = {
  __typename?: 'Media_metadata_stddev_pop_fields';
  crop_rect_x?: Maybe<Scalars['Float']>;
  crop_rect_y?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Media_Metadata_Stddev_Samp_Fields = {
  __typename?: 'Media_metadata_stddev_samp_fields';
  crop_rect_x?: Maybe<Scalars['Float']>;
  crop_rect_y?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Media_Metadata_Sum_Fields = {
  __typename?: 'Media_metadata_sum_fields';
  crop_rect_x?: Maybe<Scalars['Int']>;
  crop_rect_y?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['Int']>;
};

/** update columns of table "Media_metadata" */
export enum Media_Metadata_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropRectX = 'crop_rect_x',
  /** column name */
  CropRectY = 'crop_rect_y',
  /** column name */
  Extension = 'extension',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Media_Metadata_Var_Pop_Fields = {
  __typename?: 'Media_metadata_var_pop_fields';
  crop_rect_x?: Maybe<Scalars['Float']>;
  crop_rect_y?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Media_Metadata_Var_Samp_Fields = {
  __typename?: 'Media_metadata_var_samp_fields';
  crop_rect_x?: Maybe<Scalars['Float']>;
  crop_rect_y?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Media_Metadata_Variance_Fields = {
  __typename?: 'Media_metadata_variance_fields';
  crop_rect_x?: Maybe<Scalars['Float']>;
  crop_rect_y?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Milestone" */
export type Milestone = {
  __typename?: 'Milestone';
  /** An array relationship */
  context_to_milestones: Array<Context_To_Milestone>;
  /** An aggregate relationship */
  context_to_milestones_aggregate: Context_To_Milestone_Aggregate;
  created_at: Scalars['timestamptz'];
  feature: Enum_Tutorial_Step_Type_Enum;
  id: Scalars['bigint'];
  min_value: Scalars['bigint'];
  /** An object relationship */
  required_milestone?: Maybe<Milestone>;
  required_milestone_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Milestone" */
export type MilestoneContext_To_MilestonesArgs = {
  distinct_on?: Maybe<Array<Context_To_Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Milestone_Order_By>>;
  where?: Maybe<Context_To_Milestone_Bool_Exp>;
};


/** columns and relationships of "Milestone" */
export type MilestoneContext_To_Milestones_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_To_Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Milestone_Order_By>>;
  where?: Maybe<Context_To_Milestone_Bool_Exp>;
};

/** aggregated selection of "Milestone" */
export type Milestone_Aggregate = {
  __typename?: 'Milestone_aggregate';
  aggregate?: Maybe<Milestone_Aggregate_Fields>;
  nodes: Array<Milestone>;
};

/** aggregate fields of "Milestone" */
export type Milestone_Aggregate_Fields = {
  __typename?: 'Milestone_aggregate_fields';
  avg?: Maybe<Milestone_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Milestone_Max_Fields>;
  min?: Maybe<Milestone_Min_Fields>;
  stddev?: Maybe<Milestone_Stddev_Fields>;
  stddev_pop?: Maybe<Milestone_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Milestone_Stddev_Samp_Fields>;
  sum?: Maybe<Milestone_Sum_Fields>;
  var_pop?: Maybe<Milestone_Var_Pop_Fields>;
  var_samp?: Maybe<Milestone_Var_Samp_Fields>;
  variance?: Maybe<Milestone_Variance_Fields>;
};


/** aggregate fields of "Milestone" */
export type Milestone_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Milestone_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Milestone_Avg_Fields = {
  __typename?: 'Milestone_avg_fields';
  id?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  required_milestone_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Milestone". All fields are combined with a logical 'AND'. */
export type Milestone_Bool_Exp = {
  _and?: Maybe<Array<Milestone_Bool_Exp>>;
  _not?: Maybe<Milestone_Bool_Exp>;
  _or?: Maybe<Array<Milestone_Bool_Exp>>;
  context_to_milestones?: Maybe<Context_To_Milestone_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  feature?: Maybe<Enum_Tutorial_Step_Type_Enum_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  min_value?: Maybe<Bigint_Comparison_Exp>;
  required_milestone?: Maybe<Milestone_Bool_Exp>;
  required_milestone_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Milestone" */
export enum Milestone_Constraint {
  /** unique or primary key constraint on columns "id" */
  MilestonePkey = 'Milestone_pkey'
}

/** input type for incrementing numeric columns in table "Milestone" */
export type Milestone_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  min_value?: Maybe<Scalars['bigint']>;
  required_milestone_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Milestone" */
export type Milestone_Insert_Input = {
  context_to_milestones?: Maybe<Context_To_Milestone_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  feature?: Maybe<Enum_Tutorial_Step_Type_Enum>;
  id?: Maybe<Scalars['bigint']>;
  min_value?: Maybe<Scalars['bigint']>;
  required_milestone?: Maybe<Milestone_Obj_Rel_Insert_Input>;
  required_milestone_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Milestone_Max_Fields = {
  __typename?: 'Milestone_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_value?: Maybe<Scalars['bigint']>;
  required_milestone_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Milestone_Min_Fields = {
  __typename?: 'Milestone_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  min_value?: Maybe<Scalars['bigint']>;
  required_milestone_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Milestone" */
export type Milestone_Mutation_Response = {
  __typename?: 'Milestone_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Milestone>;
};

/** input type for inserting object relation for remote table "Milestone" */
export type Milestone_Obj_Rel_Insert_Input = {
  data: Milestone_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Milestone_On_Conflict>;
};

/** on_conflict condition type for table "Milestone" */
export type Milestone_On_Conflict = {
  constraint: Milestone_Constraint;
  update_columns?: Array<Milestone_Update_Column>;
  where?: Maybe<Milestone_Bool_Exp>;
};

/** Ordering options when selecting data from "Milestone". */
export type Milestone_Order_By = {
  context_to_milestones_aggregate?: Maybe<Context_To_Milestone_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  feature?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  min_value?: Maybe<Order_By>;
  required_milestone?: Maybe<Milestone_Order_By>;
  required_milestone_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Milestone */
export type Milestone_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Milestone" */
export enum Milestone_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  MinValue = 'min_value',
  /** column name */
  RequiredMilestoneId = 'required_milestone_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Milestone" */
export type Milestone_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  feature?: Maybe<Enum_Tutorial_Step_Type_Enum>;
  id?: Maybe<Scalars['bigint']>;
  min_value?: Maybe<Scalars['bigint']>;
  required_milestone_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Milestone_Stddev_Fields = {
  __typename?: 'Milestone_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  required_milestone_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Milestone_Stddev_Pop_Fields = {
  __typename?: 'Milestone_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  required_milestone_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Milestone_Stddev_Samp_Fields = {
  __typename?: 'Milestone_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  required_milestone_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Milestone_Sum_Fields = {
  __typename?: 'Milestone_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  min_value?: Maybe<Scalars['bigint']>;
  required_milestone_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Milestone" */
export enum Milestone_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  MinValue = 'min_value',
  /** column name */
  RequiredMilestoneId = 'required_milestone_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Milestone_Var_Pop_Fields = {
  __typename?: 'Milestone_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  required_milestone_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Milestone_Var_Samp_Fields = {
  __typename?: 'Milestone_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  required_milestone_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Milestone_Variance_Fields = {
  __typename?: 'Milestone_variance_fields';
  id?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  required_milestone_id?: Maybe<Scalars['Float']>;
};

export type MissingDomainFixResult = {
  __typename?: 'MissingDomainFixResult';
  error?: Maybe<Scalars['String']>;
  inconsistency: MissingDomainSubscriptionInconsistency;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type MissingDomainSubscriptionInconsistency = {
  __typename?: 'MissingDomainSubscriptionInconsistency';
  domainId: Scalars['bigint'];
};

/** columns and relationships of "Museum_artwork_listing" */
export type Museum_Artwork_Listing = {
  __typename?: 'Museum_artwork_listing';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_order?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  is_available?: Maybe<Scalars['Boolean']>;
  is_collected?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  is_unavailable?: Maybe<Scalars['Boolean']>;
  museum_context_id?: Maybe<Scalars['bigint']>;
  museum_profile_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Museum_artwork_listing" */
export type Museum_Artwork_Listing_Aggregate = {
  __typename?: 'Museum_artwork_listing_aggregate';
  aggregate?: Maybe<Museum_Artwork_Listing_Aggregate_Fields>;
  nodes: Array<Museum_Artwork_Listing>;
};

/** aggregate fields of "Museum_artwork_listing" */
export type Museum_Artwork_Listing_Aggregate_Fields = {
  __typename?: 'Museum_artwork_listing_aggregate_fields';
  avg?: Maybe<Museum_Artwork_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Museum_Artwork_Listing_Max_Fields>;
  min?: Maybe<Museum_Artwork_Listing_Min_Fields>;
  stddev?: Maybe<Museum_Artwork_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Museum_Artwork_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Museum_Artwork_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Museum_Artwork_Listing_Sum_Fields>;
  var_pop?: Maybe<Museum_Artwork_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Museum_Artwork_Listing_Var_Samp_Fields>;
  variance?: Maybe<Museum_Artwork_Listing_Variance_Fields>;
};


/** aggregate fields of "Museum_artwork_listing" */
export type Museum_Artwork_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Museum_Artwork_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Museum_Artwork_Listing_Avg_Fields = {
  __typename?: 'Museum_artwork_listing_avg_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  museum_context_id?: Maybe<Scalars['Float']>;
  museum_profile_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Museum_artwork_listing". All fields are combined with a logical 'AND'. */
export type Museum_Artwork_Listing_Bool_Exp = {
  _and?: Maybe<Array<Museum_Artwork_Listing_Bool_Exp>>;
  _not?: Maybe<Museum_Artwork_Listing_Bool_Exp>;
  _or?: Maybe<Array<Museum_Artwork_Listing_Bool_Exp>>;
  area?: Maybe<Float8_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  aspect_ratio?: Maybe<Int_Comparison_Exp>;
  collection_order?: Maybe<Int_Comparison_Exp>;
  creation_date_year?: Maybe<Int_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  is_available?: Maybe<Boolean_Comparison_Exp>;
  is_collected?: Maybe<Boolean_Comparison_Exp>;
  is_landscape?: Maybe<Boolean_Comparison_Exp>;
  is_portrait?: Maybe<Boolean_Comparison_Exp>;
  is_square?: Maybe<Boolean_Comparison_Exp>;
  is_unavailable?: Maybe<Boolean_Comparison_Exp>;
  museum_context_id?: Maybe<Bigint_Comparison_Exp>;
  museum_profile_id?: Maybe<Bigint_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Museum_Artwork_Listing_Max_Fields = {
  __typename?: 'Museum_artwork_listing_max_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_order?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  museum_context_id?: Maybe<Scalars['bigint']>;
  museum_profile_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Museum_Artwork_Listing_Min_Fields = {
  __typename?: 'Museum_artwork_listing_min_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_order?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  museum_context_id?: Maybe<Scalars['bigint']>;
  museum_profile_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  published_date?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "Museum_artwork_listing". */
export type Museum_Artwork_Listing_Order_By = {
  area?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Order_By>;
  collection_order?: Maybe<Order_By>;
  creation_date_year?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  is_available?: Maybe<Order_By>;
  is_collected?: Maybe<Order_By>;
  is_landscape?: Maybe<Order_By>;
  is_portrait?: Maybe<Order_By>;
  is_square?: Maybe<Order_By>;
  is_unavailable?: Maybe<Order_By>;
  museum_context_id?: Maybe<Order_By>;
  museum_profile_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
};

/** select columns of table "Museum_artwork_listing" */
export enum Museum_Artwork_Listing_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AspectRatio = 'aspect_ratio',
  /** column name */
  CollectionOrder = 'collection_order',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  Handle = 'handle',
  /** column name */
  IsAvailable = 'is_available',
  /** column name */
  IsCollected = 'is_collected',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsPortrait = 'is_portrait',
  /** column name */
  IsSquare = 'is_square',
  /** column name */
  IsUnavailable = 'is_unavailable',
  /** column name */
  MuseumContextId = 'museum_context_id',
  /** column name */
  MuseumProfileId = 'museum_profile_id',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedDate = 'published_date'
}

/** aggregate stddev on columns */
export type Museum_Artwork_Listing_Stddev_Fields = {
  __typename?: 'Museum_artwork_listing_stddev_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  museum_context_id?: Maybe<Scalars['Float']>;
  museum_profile_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Museum_Artwork_Listing_Stddev_Pop_Fields = {
  __typename?: 'Museum_artwork_listing_stddev_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  museum_context_id?: Maybe<Scalars['Float']>;
  museum_profile_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Museum_Artwork_Listing_Stddev_Samp_Fields = {
  __typename?: 'Museum_artwork_listing_stddev_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  museum_context_id?: Maybe<Scalars['Float']>;
  museum_profile_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Museum_Artwork_Listing_Sum_Fields = {
  __typename?: 'Museum_artwork_listing_sum_fields';
  area?: Maybe<Scalars['float8']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  aspect_ratio?: Maybe<Scalars['Int']>;
  collection_order?: Maybe<Scalars['Int']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  museum_context_id?: Maybe<Scalars['bigint']>;
  museum_profile_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Museum_Artwork_Listing_Var_Pop_Fields = {
  __typename?: 'Museum_artwork_listing_var_pop_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  museum_context_id?: Maybe<Scalars['Float']>;
  museum_profile_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Museum_Artwork_Listing_Var_Samp_Fields = {
  __typename?: 'Museum_artwork_listing_var_samp_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  museum_context_id?: Maybe<Scalars['Float']>;
  museum_profile_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Museum_Artwork_Listing_Variance_Fields = {
  __typename?: 'Museum_artwork_listing_variance_fields';
  area?: Maybe<Scalars['Float']>;
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  aspect_ratio?: Maybe<Scalars['Float']>;
  collection_order?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  museum_context_id?: Maybe<Scalars['Float']>;
  museum_profile_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

export type MyProvenanceArtworksType = {
  __typename?: 'MyProvenanceArtworksType';
  artworkId: Scalars['Int'];
  artworkShowcaseUrl: Scalars['String'];
  artworkSlug: Scalars['String'];
  artworkTitle: Scalars['String'];
  artworkType: Scalars['String'];
  collectorInfo?: Maybe<Array<Maybe<MyProvenanceCollectorInfoType>>>;
  editionId?: Maybe<Scalars['Int']>;
  hasMorePrints: Scalars['Boolean'];
  hasMultipleCollectors: Scalars['Boolean'];
  lastTransactionDate?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  thumbnailMetadataId: Scalars['Int'];
};

export type MyProvenanceCollectorInfoType = {
  __typename?: 'MyProvenanceCollectorInfoType';
  collectorAvatar?: Maybe<Scalars['Int']>;
  collectorEmail?: Maybe<Scalars['String']>;
  collectorHandle?: Maybe<Scalars['String']>;
  collectorName?: Maybe<Scalars['String']>;
  collectorProfileId?: Maybe<Scalars['Int']>;
  isKnownProfile?: Maybe<Scalars['Boolean']>;
  isPrivateProfile: Scalars['String'];
};

/** columns and relationships of "MyStudio_subscriptors" */
export type MyStudio_Subscriptors = {
  __typename?: 'MyStudio_subscriptors';
  artist_profile?: Maybe<Scalars['String']>;
  billing_subscription_period?: Maybe<Scalars['String']>;
  billing_subscription_status?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  discount_value?: Maybe<Scalars['float8']>;
  discount_value_type?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  instagram_link?: Maybe<Scalars['String']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  preferred_language?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  subscription_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_type?: Maybe<Scalars['String']>;
  trial_period_days?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_discount_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "MyStudio_subscriptors" */
export type MyStudio_Subscriptors_Aggregate = {
  __typename?: 'MyStudio_subscriptors_aggregate';
  aggregate?: Maybe<MyStudio_Subscriptors_Aggregate_Fields>;
  nodes: Array<MyStudio_Subscriptors>;
};

/** aggregate fields of "MyStudio_subscriptors" */
export type MyStudio_Subscriptors_Aggregate_Fields = {
  __typename?: 'MyStudio_subscriptors_aggregate_fields';
  avg?: Maybe<MyStudio_Subscriptors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<MyStudio_Subscriptors_Max_Fields>;
  min?: Maybe<MyStudio_Subscriptors_Min_Fields>;
  stddev?: Maybe<MyStudio_Subscriptors_Stddev_Fields>;
  stddev_pop?: Maybe<MyStudio_Subscriptors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<MyStudio_Subscriptors_Stddev_Samp_Fields>;
  sum?: Maybe<MyStudio_Subscriptors_Sum_Fields>;
  var_pop?: Maybe<MyStudio_Subscriptors_Var_Pop_Fields>;
  var_samp?: Maybe<MyStudio_Subscriptors_Var_Samp_Fields>;
  variance?: Maybe<MyStudio_Subscriptors_Variance_Fields>;
};


/** aggregate fields of "MyStudio_subscriptors" */
export type MyStudio_Subscriptors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<MyStudio_Subscriptors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MyStudio_Subscriptors_Avg_Fields = {
  __typename?: 'MyStudio_subscriptors_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "MyStudio_subscriptors". All fields are combined with a logical 'AND'. */
export type MyStudio_Subscriptors_Bool_Exp = {
  _and?: Maybe<Array<MyStudio_Subscriptors_Bool_Exp>>;
  _not?: Maybe<MyStudio_Subscriptors_Bool_Exp>;
  _or?: Maybe<Array<MyStudio_Subscriptors_Bool_Exp>>;
  artist_profile?: Maybe<String_Comparison_Exp>;
  billing_subscription_period?: Maybe<String_Comparison_Exp>;
  billing_subscription_status?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  discount_value?: Maybe<Float8_Comparison_Exp>;
  discount_value_type?: Maybe<String_Comparison_Exp>;
  domain_name?: Maybe<String_Comparison_Exp>;
  domain_state?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  instagram_link?: Maybe<String_Comparison_Exp>;
  last_billed_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  next_billing_date?: Maybe<Timestamptz_Comparison_Exp>;
  preferred_language?: Maybe<String_Comparison_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  subscription_start_date?: Maybe<Timestamptz_Comparison_Exp>;
  subscription_type?: Maybe<String_Comparison_Exp>;
  trial_period_days?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  used_discount_code?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type MyStudio_Subscriptors_Max_Fields = {
  __typename?: 'MyStudio_subscriptors_max_fields';
  artist_profile?: Maybe<Scalars['String']>;
  billing_subscription_period?: Maybe<Scalars['String']>;
  billing_subscription_status?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  discount_value?: Maybe<Scalars['float8']>;
  discount_value_type?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  instagram_link?: Maybe<Scalars['String']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  preferred_language?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  subscription_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_type?: Maybe<Scalars['String']>;
  trial_period_days?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_discount_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MyStudio_Subscriptors_Min_Fields = {
  __typename?: 'MyStudio_subscriptors_min_fields';
  artist_profile?: Maybe<Scalars['String']>;
  billing_subscription_period?: Maybe<Scalars['String']>;
  billing_subscription_status?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  discount_value?: Maybe<Scalars['float8']>;
  discount_value_type?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  instagram_link?: Maybe<Scalars['String']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  preferred_language?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  subscription_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_type?: Maybe<Scalars['String']>;
  trial_period_days?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_discount_code?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "MyStudio_subscriptors". */
export type MyStudio_Subscriptors_Order_By = {
  artist_profile?: Maybe<Order_By>;
  billing_subscription_period?: Maybe<Order_By>;
  billing_subscription_status?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  discount_value?: Maybe<Order_By>;
  discount_value_type?: Maybe<Order_By>;
  domain_name?: Maybe<Order_By>;
  domain_state?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  instagram_link?: Maybe<Order_By>;
  last_billed_at?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  next_billing_date?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  subscription_start_date?: Maybe<Order_By>;
  subscription_type?: Maybe<Order_By>;
  trial_period_days?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used_discount_code?: Maybe<Order_By>;
};

/** select columns of table "MyStudio_subscriptors" */
export enum MyStudio_Subscriptors_Select_Column {
  /** column name */
  ArtistProfile = 'artist_profile',
  /** column name */
  BillingSubscriptionPeriod = 'billing_subscription_period',
  /** column name */
  BillingSubscriptionStatus = 'billing_subscription_status',
  /** column name */
  City = 'city',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Country = 'country',
  /** column name */
  DiscountValue = 'discount_value',
  /** column name */
  DiscountValueType = 'discount_value_type',
  /** column name */
  DomainName = 'domain_name',
  /** column name */
  DomainState = 'domain_state',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  InstagramLink = 'instagram_link',
  /** column name */
  LastBilledAt = 'last_billed_at',
  /** column name */
  LastName = 'last_name',
  /** column name */
  NextBillingDate = 'next_billing_date',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  Region = 'region',
  /** column name */
  SubscriptionStartDate = 'subscription_start_date',
  /** column name */
  SubscriptionType = 'subscription_type',
  /** column name */
  TrialPeriodDays = 'trial_period_days',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedDiscountCode = 'used_discount_code'
}

/** aggregate stddev on columns */
export type MyStudio_Subscriptors_Stddev_Fields = {
  __typename?: 'MyStudio_subscriptors_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MyStudio_Subscriptors_Stddev_Pop_Fields = {
  __typename?: 'MyStudio_subscriptors_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MyStudio_Subscriptors_Stddev_Samp_Fields = {
  __typename?: 'MyStudio_subscriptors_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MyStudio_Subscriptors_Sum_Fields = {
  __typename?: 'MyStudio_subscriptors_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  discount_value?: Maybe<Scalars['float8']>;
  trial_period_days?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type MyStudio_Subscriptors_Var_Pop_Fields = {
  __typename?: 'MyStudio_subscriptors_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MyStudio_Subscriptors_Var_Samp_Fields = {
  __typename?: 'MyStudio_subscriptors_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MyStudio_Subscriptors_Variance_Fields = {
  __typename?: 'MyStudio_subscriptors_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  discount_value?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "My_Website_Settings" */
export type My_Website_Settings = {
  __typename?: 'My_Website_Settings';
  artwork_info: Enum_My_Website_Artwork_Info_Enum;
  columns: Scalars['bigint'];
  created_at: Scalars['timestamp'];
  ecommerce_enabled: Scalars['Boolean'];
  filters: Scalars['String'];
  id: Scalars['bigint'];
  layout: Enum_My_Website_Layout_Enum;
  navigation: Enum_My_Website_Navigation_Enum;
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['bigint'];
  published_settings?: Maybe<Scalars['String']>;
  theme: Enum_My_Website_Theme_Enum;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "My_Website_Settings" */
export type My_Website_Settings_Aggregate = {
  __typename?: 'My_Website_Settings_aggregate';
  aggregate?: Maybe<My_Website_Settings_Aggregate_Fields>;
  nodes: Array<My_Website_Settings>;
};

/** aggregate fields of "My_Website_Settings" */
export type My_Website_Settings_Aggregate_Fields = {
  __typename?: 'My_Website_Settings_aggregate_fields';
  avg?: Maybe<My_Website_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<My_Website_Settings_Max_Fields>;
  min?: Maybe<My_Website_Settings_Min_Fields>;
  stddev?: Maybe<My_Website_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<My_Website_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<My_Website_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<My_Website_Settings_Sum_Fields>;
  var_pop?: Maybe<My_Website_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<My_Website_Settings_Var_Samp_Fields>;
  variance?: Maybe<My_Website_Settings_Variance_Fields>;
};


/** aggregate fields of "My_Website_Settings" */
export type My_Website_Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<My_Website_Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "My_Website_Settings" */
export type My_Website_Settings_Aggregate_Order_By = {
  avg?: Maybe<My_Website_Settings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<My_Website_Settings_Max_Order_By>;
  min?: Maybe<My_Website_Settings_Min_Order_By>;
  stddev?: Maybe<My_Website_Settings_Stddev_Order_By>;
  stddev_pop?: Maybe<My_Website_Settings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<My_Website_Settings_Stddev_Samp_Order_By>;
  sum?: Maybe<My_Website_Settings_Sum_Order_By>;
  var_pop?: Maybe<My_Website_Settings_Var_Pop_Order_By>;
  var_samp?: Maybe<My_Website_Settings_Var_Samp_Order_By>;
  variance?: Maybe<My_Website_Settings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "My_Website_Settings" */
export type My_Website_Settings_Arr_Rel_Insert_Input = {
  data: Array<My_Website_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<My_Website_Settings_On_Conflict>;
};

/** aggregate avg on columns */
export type My_Website_Settings_Avg_Fields = {
  __typename?: 'My_Website_Settings_avg_fields';
  columns?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Avg_Order_By = {
  columns?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "My_Website_Settings". All fields are combined with a logical 'AND'. */
export type My_Website_Settings_Bool_Exp = {
  _and?: Maybe<Array<My_Website_Settings_Bool_Exp>>;
  _not?: Maybe<My_Website_Settings_Bool_Exp>;
  _or?: Maybe<Array<My_Website_Settings_Bool_Exp>>;
  artwork_info?: Maybe<Enum_My_Website_Artwork_Info_Enum_Comparison_Exp>;
  columns?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  ecommerce_enabled?: Maybe<Boolean_Comparison_Exp>;
  filters?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  layout?: Maybe<Enum_My_Website_Layout_Enum_Comparison_Exp>;
  navigation?: Maybe<Enum_My_Website_Navigation_Enum_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  published_settings?: Maybe<String_Comparison_Exp>;
  theme?: Maybe<Enum_My_Website_Theme_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "My_Website_Settings" */
export enum My_Website_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  MyWebsiteSettingsPkey = 'My_Website_Settings_pkey',
  /** unique or primary key constraint on columns "profile_id" */
  MyWebsiteSettingsProfileIdKey = 'My_Website_Settings_profile_id_key'
}

/** input type for incrementing numeric columns in table "My_Website_Settings" */
export type My_Website_Settings_Inc_Input = {
  columns?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "My_Website_Settings" */
export type My_Website_Settings_Insert_Input = {
  artwork_info?: Maybe<Enum_My_Website_Artwork_Info_Enum>;
  columns?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  ecommerce_enabled?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  layout?: Maybe<Enum_My_Website_Layout_Enum>;
  navigation?: Maybe<Enum_My_Website_Navigation_Enum>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: Maybe<Scalars['bigint']>;
  published_settings?: Maybe<Scalars['String']>;
  theme?: Maybe<Enum_My_Website_Theme_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type My_Website_Settings_Max_Fields = {
  __typename?: 'My_Website_Settings_max_fields';
  columns?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filters?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  published_settings?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Max_Order_By = {
  columns?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  filters?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  published_settings?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type My_Website_Settings_Min_Fields = {
  __typename?: 'My_Website_Settings_min_fields';
  columns?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  filters?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  published_settings?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Min_Order_By = {
  columns?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  filters?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  published_settings?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "My_Website_Settings" */
export type My_Website_Settings_Mutation_Response = {
  __typename?: 'My_Website_Settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<My_Website_Settings>;
};

/** on_conflict condition type for table "My_Website_Settings" */
export type My_Website_Settings_On_Conflict = {
  constraint: My_Website_Settings_Constraint;
  update_columns?: Array<My_Website_Settings_Update_Column>;
  where?: Maybe<My_Website_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "My_Website_Settings". */
export type My_Website_Settings_Order_By = {
  artwork_info?: Maybe<Order_By>;
  columns?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  ecommerce_enabled?: Maybe<Order_By>;
  filters?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  layout?: Maybe<Order_By>;
  navigation?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profile_id?: Maybe<Order_By>;
  published_settings?: Maybe<Order_By>;
  theme?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: My_Website_Settings */
export type My_Website_Settings_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "My_Website_Settings" */
export enum My_Website_Settings_Select_Column {
  /** column name */
  ArtworkInfo = 'artwork_info',
  /** column name */
  Columns = 'columns',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EcommerceEnabled = 'ecommerce_enabled',
  /** column name */
  Filters = 'filters',
  /** column name */
  Id = 'id',
  /** column name */
  Layout = 'layout',
  /** column name */
  Navigation = 'navigation',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  PublishedSettings = 'published_settings',
  /** column name */
  Theme = 'theme',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "My_Website_Settings" */
export type My_Website_Settings_Set_Input = {
  artwork_info?: Maybe<Enum_My_Website_Artwork_Info_Enum>;
  columns?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  ecommerce_enabled?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  layout?: Maybe<Enum_My_Website_Layout_Enum>;
  navigation?: Maybe<Enum_My_Website_Navigation_Enum>;
  profile_id?: Maybe<Scalars['bigint']>;
  published_settings?: Maybe<Scalars['String']>;
  theme?: Maybe<Enum_My_Website_Theme_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type My_Website_Settings_Stddev_Fields = {
  __typename?: 'My_Website_Settings_stddev_fields';
  columns?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Stddev_Order_By = {
  columns?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type My_Website_Settings_Stddev_Pop_Fields = {
  __typename?: 'My_Website_Settings_stddev_pop_fields';
  columns?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Stddev_Pop_Order_By = {
  columns?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type My_Website_Settings_Stddev_Samp_Fields = {
  __typename?: 'My_Website_Settings_stddev_samp_fields';
  columns?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Stddev_Samp_Order_By = {
  columns?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type My_Website_Settings_Sum_Fields = {
  __typename?: 'My_Website_Settings_sum_fields';
  columns?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Sum_Order_By = {
  columns?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** update columns of table "My_Website_Settings" */
export enum My_Website_Settings_Update_Column {
  /** column name */
  ArtworkInfo = 'artwork_info',
  /** column name */
  Columns = 'columns',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EcommerceEnabled = 'ecommerce_enabled',
  /** column name */
  Filters = 'filters',
  /** column name */
  Id = 'id',
  /** column name */
  Layout = 'layout',
  /** column name */
  Navigation = 'navigation',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  PublishedSettings = 'published_settings',
  /** column name */
  Theme = 'theme',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type My_Website_Settings_Var_Pop_Fields = {
  __typename?: 'My_Website_Settings_var_pop_fields';
  columns?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Var_Pop_Order_By = {
  columns?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type My_Website_Settings_Var_Samp_Fields = {
  __typename?: 'My_Website_Settings_var_samp_fields';
  columns?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Var_Samp_Order_By = {
  columns?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type My_Website_Settings_Variance_Fields = {
  __typename?: 'My_Website_Settings_variance_fields';
  columns?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "My_Website_Settings" */
export type My_Website_Settings_Variance_Order_By = {
  columns?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** This table contains user preference for MyStudio features */
export type My_Studio_Settings = {
  __typename?: 'My_studio_settings';
  /** An object relationship */
  Context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  my_documents_pagination: Scalars['Int'];
  my_provenance_pagination: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "My_studio_settings" */
export type My_Studio_Settings_Aggregate = {
  __typename?: 'My_studio_settings_aggregate';
  aggregate?: Maybe<My_Studio_Settings_Aggregate_Fields>;
  nodes: Array<My_Studio_Settings>;
};

/** aggregate fields of "My_studio_settings" */
export type My_Studio_Settings_Aggregate_Fields = {
  __typename?: 'My_studio_settings_aggregate_fields';
  avg?: Maybe<My_Studio_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<My_Studio_Settings_Max_Fields>;
  min?: Maybe<My_Studio_Settings_Min_Fields>;
  stddev?: Maybe<My_Studio_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<My_Studio_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<My_Studio_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<My_Studio_Settings_Sum_Fields>;
  var_pop?: Maybe<My_Studio_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<My_Studio_Settings_Var_Samp_Fields>;
  variance?: Maybe<My_Studio_Settings_Variance_Fields>;
};


/** aggregate fields of "My_studio_settings" */
export type My_Studio_Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<My_Studio_Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type My_Studio_Settings_Avg_Fields = {
  __typename?: 'My_studio_settings_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  my_documents_pagination?: Maybe<Scalars['Float']>;
  my_provenance_pagination?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "My_studio_settings". All fields are combined with a logical 'AND'. */
export type My_Studio_Settings_Bool_Exp = {
  Context?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<My_Studio_Settings_Bool_Exp>>;
  _not?: Maybe<My_Studio_Settings_Bool_Exp>;
  _or?: Maybe<Array<My_Studio_Settings_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  my_documents_pagination?: Maybe<Int_Comparison_Exp>;
  my_provenance_pagination?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "My_studio_settings" */
export enum My_Studio_Settings_Constraint {
  /** unique or primary key constraint on columns "context_id" */
  MyStudioSettingsContextIdKey = 'My_studio_settings_context_id_key',
  /** unique or primary key constraint on columns "id" */
  MyStudioSettingsPkey = 'My_studio_settings_pkey'
}

/** input type for incrementing numeric columns in table "My_studio_settings" */
export type My_Studio_Settings_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  my_documents_pagination?: Maybe<Scalars['Int']>;
  my_provenance_pagination?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "My_studio_settings" */
export type My_Studio_Settings_Insert_Input = {
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  my_documents_pagination?: Maybe<Scalars['Int']>;
  my_provenance_pagination?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type My_Studio_Settings_Max_Fields = {
  __typename?: 'My_studio_settings_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  my_documents_pagination?: Maybe<Scalars['Int']>;
  my_provenance_pagination?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type My_Studio_Settings_Min_Fields = {
  __typename?: 'My_studio_settings_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  my_documents_pagination?: Maybe<Scalars['Int']>;
  my_provenance_pagination?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "My_studio_settings" */
export type My_Studio_Settings_Mutation_Response = {
  __typename?: 'My_studio_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<My_Studio_Settings>;
};

/** on_conflict condition type for table "My_studio_settings" */
export type My_Studio_Settings_On_Conflict = {
  constraint: My_Studio_Settings_Constraint;
  update_columns?: Array<My_Studio_Settings_Update_Column>;
  where?: Maybe<My_Studio_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "My_studio_settings". */
export type My_Studio_Settings_Order_By = {
  Context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  my_documents_pagination?: Maybe<Order_By>;
  my_provenance_pagination?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: My_studio_settings */
export type My_Studio_Settings_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "My_studio_settings" */
export enum My_Studio_Settings_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MyDocumentsPagination = 'my_documents_pagination',
  /** column name */
  MyProvenancePagination = 'my_provenance_pagination',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "My_studio_settings" */
export type My_Studio_Settings_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  my_documents_pagination?: Maybe<Scalars['Int']>;
  my_provenance_pagination?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type My_Studio_Settings_Stddev_Fields = {
  __typename?: 'My_studio_settings_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  my_documents_pagination?: Maybe<Scalars['Float']>;
  my_provenance_pagination?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type My_Studio_Settings_Stddev_Pop_Fields = {
  __typename?: 'My_studio_settings_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  my_documents_pagination?: Maybe<Scalars['Float']>;
  my_provenance_pagination?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type My_Studio_Settings_Stddev_Samp_Fields = {
  __typename?: 'My_studio_settings_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  my_documents_pagination?: Maybe<Scalars['Float']>;
  my_provenance_pagination?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type My_Studio_Settings_Sum_Fields = {
  __typename?: 'My_studio_settings_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  my_documents_pagination?: Maybe<Scalars['Int']>;
  my_provenance_pagination?: Maybe<Scalars['Int']>;
};

/** update columns of table "My_studio_settings" */
export enum My_Studio_Settings_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MyDocumentsPagination = 'my_documents_pagination',
  /** column name */
  MyProvenancePagination = 'my_provenance_pagination',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type My_Studio_Settings_Var_Pop_Fields = {
  __typename?: 'My_studio_settings_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  my_documents_pagination?: Maybe<Scalars['Float']>;
  my_provenance_pagination?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type My_Studio_Settings_Var_Samp_Fields = {
  __typename?: 'My_studio_settings_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  my_documents_pagination?: Maybe<Scalars['Float']>;
  my_provenance_pagination?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type My_Studio_Settings_Variance_Fields = {
  __typename?: 'My_studio_settings_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  my_documents_pagination?: Maybe<Scalars['Float']>;
  my_provenance_pagination?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Notification_preference" */
export type Notification_Preference = {
  __typename?: 'Notification_preference';
  blocked: Scalars['Boolean'];
  channel: Enum_Notification_Channel_Enum;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  notification: Scalars['String'];
  /** An object relationship */
  receiver_context?: Maybe<Context>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  sender_context?: Maybe<Context>;
  sender_context_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Notification_preference" */
export type Notification_Preference_Aggregate = {
  __typename?: 'Notification_preference_aggregate';
  aggregate?: Maybe<Notification_Preference_Aggregate_Fields>;
  nodes: Array<Notification_Preference>;
};

/** aggregate fields of "Notification_preference" */
export type Notification_Preference_Aggregate_Fields = {
  __typename?: 'Notification_preference_aggregate_fields';
  avg?: Maybe<Notification_Preference_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Notification_Preference_Max_Fields>;
  min?: Maybe<Notification_Preference_Min_Fields>;
  stddev?: Maybe<Notification_Preference_Stddev_Fields>;
  stddev_pop?: Maybe<Notification_Preference_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Notification_Preference_Stddev_Samp_Fields>;
  sum?: Maybe<Notification_Preference_Sum_Fields>;
  var_pop?: Maybe<Notification_Preference_Var_Pop_Fields>;
  var_samp?: Maybe<Notification_Preference_Var_Samp_Fields>;
  variance?: Maybe<Notification_Preference_Variance_Fields>;
};


/** aggregate fields of "Notification_preference" */
export type Notification_Preference_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Preference_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Notification_Preference_Avg_Fields = {
  __typename?: 'Notification_preference_avg_fields';
  id?: Maybe<Scalars['Float']>;
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Notification_preference". All fields are combined with a logical 'AND'. */
export type Notification_Preference_Bool_Exp = {
  _and?: Maybe<Array<Notification_Preference_Bool_Exp>>;
  _not?: Maybe<Notification_Preference_Bool_Exp>;
  _or?: Maybe<Array<Notification_Preference_Bool_Exp>>;
  blocked?: Maybe<Boolean_Comparison_Exp>;
  channel?: Maybe<Enum_Notification_Channel_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  notification?: Maybe<String_Comparison_Exp>;
  receiver_context?: Maybe<Context_Bool_Exp>;
  receiver_context_id?: Maybe<Bigint_Comparison_Exp>;
  sender_context?: Maybe<Context_Bool_Exp>;
  sender_context_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Notification_preference" */
export enum Notification_Preference_Constraint {
  /** unique or primary key constraint on columns "notification", "channel" */
  NotificationPreferenceContextNotificationChannelIdx = 'Notification_preference_context_notification_channel_idx',
  /** unique or primary key constraint on columns "notification", "receiver_context_id", "sender_context_id", "channel" */
  NotificationPreferenceContextNotificationChannelKey = 'Notification_preference_context_notification_channel_key',
  /** unique or primary key constraint on columns "id" */
  NotificationPreferencePkey = 'Notification_preference_pkey',
  /** unique or primary key constraint on columns "notification", "sender_context_id", "channel" */
  NotificationPreferenceReceiverNotificationChannelIdx = 'Notification_preference_receiver_notification_channel_idx',
  /** unique or primary key constraint on columns "notification", "receiver_context_id", "channel" */
  NotificationPreferenceSenderNotificationChannelIdx = 'Notification_preference_sender_notification_channel_idx'
}

/** input type for incrementing numeric columns in table "Notification_preference" */
export type Notification_Preference_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  sender_context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Notification_preference" */
export type Notification_Preference_Insert_Input = {
  blocked?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Enum_Notification_Channel_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  notification?: Maybe<Scalars['String']>;
  receiver_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  sender_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  sender_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Notification_Preference_Max_Fields = {
  __typename?: 'Notification_preference_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  notification?: Maybe<Scalars['String']>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  sender_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Notification_Preference_Min_Fields = {
  __typename?: 'Notification_preference_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  notification?: Maybe<Scalars['String']>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  sender_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Notification_preference" */
export type Notification_Preference_Mutation_Response = {
  __typename?: 'Notification_preference_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Preference>;
};

/** on_conflict condition type for table "Notification_preference" */
export type Notification_Preference_On_Conflict = {
  constraint: Notification_Preference_Constraint;
  update_columns?: Array<Notification_Preference_Update_Column>;
  where?: Maybe<Notification_Preference_Bool_Exp>;
};

/** Ordering options when selecting data from "Notification_preference". */
export type Notification_Preference_Order_By = {
  blocked?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notification?: Maybe<Order_By>;
  receiver_context?: Maybe<Context_Order_By>;
  receiver_context_id?: Maybe<Order_By>;
  sender_context?: Maybe<Context_Order_By>;
  sender_context_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Notification_preference */
export type Notification_Preference_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Notification_preference" */
export enum Notification_Preference_Select_Column {
  /** column name */
  Blocked = 'blocked',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notification = 'notification',
  /** column name */
  ReceiverContextId = 'receiver_context_id',
  /** column name */
  SenderContextId = 'sender_context_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Notification_preference" */
export type Notification_Preference_Set_Input = {
  blocked?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Enum_Notification_Channel_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  notification?: Maybe<Scalars['String']>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  sender_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Notification_Preference_Stddev_Fields = {
  __typename?: 'Notification_preference_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Notification_Preference_Stddev_Pop_Fields = {
  __typename?: 'Notification_preference_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Notification_Preference_Stddev_Samp_Fields = {
  __typename?: 'Notification_preference_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Notification_Preference_Sum_Fields = {
  __typename?: 'Notification_preference_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  receiver_context_id?: Maybe<Scalars['bigint']>;
  sender_context_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Notification_preference" */
export enum Notification_Preference_Update_Column {
  /** column name */
  Blocked = 'blocked',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notification = 'notification',
  /** column name */
  ReceiverContextId = 'receiver_context_id',
  /** column name */
  SenderContextId = 'sender_context_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Notification_Preference_Var_Pop_Fields = {
  __typename?: 'Notification_preference_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Notification_Preference_Var_Samp_Fields = {
  __typename?: 'Notification_preference_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Notification_Preference_Variance_Fields = {
  __typename?: 'Notification_preference_variance_fields';
  id?: Maybe<Scalars['Float']>;
  receiver_context_id?: Maybe<Scalars['Float']>;
  sender_context_id?: Maybe<Scalars['Float']>;
};

export type OnboardingTransactionsPreview = {
  __typename?: 'OnboardingTransactionsPreview';
  number_of_pending_transactions?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<Maybe<TransactionsPreview>>>;
};

export type Payload = {
  __typename?: 'Payload';
  artwork?: Maybe<CustomArtwork>;
  artwork_transaction?: Maybe<CustomArtworkTransaction>;
  date?: Maybe<Scalars['String']>;
  edition_by_type_id?: Maybe<Scalars['Int']>;
  edition_id?: Maybe<Scalars['Int']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  print_id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type PaymentIntentCartProduct = {
  __typename?: 'PaymentIntentCartProduct';
  artworkId: Scalars['bigint'];
  artworkTitle: Scalars['String'];
  ownerArtistId?: Maybe<Scalars['bigint']>;
  ownerArtistTitle?: Maybe<Scalars['String']>;
  primaryMetadataId?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork = {
  __typename?: 'Pending_collaboration_on_artwork';
  /** An object relationship */
  artwork: Artwork;
  artwork_id: Scalars['bigint'];
  /** An object relationship */
  collaboration: Collaboration;
  collaboration_id: Scalars['bigint'];
};

/** aggregated selection of "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Aggregate = {
  __typename?: 'Pending_collaboration_on_artwork_aggregate';
  aggregate?: Maybe<Pending_Collaboration_On_Artwork_Aggregate_Fields>;
  nodes: Array<Pending_Collaboration_On_Artwork>;
};

/** aggregate fields of "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Aggregate_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_aggregate_fields';
  avg?: Maybe<Pending_Collaboration_On_Artwork_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pending_Collaboration_On_Artwork_Max_Fields>;
  min?: Maybe<Pending_Collaboration_On_Artwork_Min_Fields>;
  stddev?: Maybe<Pending_Collaboration_On_Artwork_Stddev_Fields>;
  stddev_pop?: Maybe<Pending_Collaboration_On_Artwork_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pending_Collaboration_On_Artwork_Stddev_Samp_Fields>;
  sum?: Maybe<Pending_Collaboration_On_Artwork_Sum_Fields>;
  var_pop?: Maybe<Pending_Collaboration_On_Artwork_Var_Pop_Fields>;
  var_samp?: Maybe<Pending_Collaboration_On_Artwork_Var_Samp_Fields>;
  variance?: Maybe<Pending_Collaboration_On_Artwork_Variance_Fields>;
};


/** aggregate fields of "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pending_Collaboration_On_Artwork_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Aggregate_Order_By = {
  avg?: Maybe<Pending_Collaboration_On_Artwork_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Pending_Collaboration_On_Artwork_Max_Order_By>;
  min?: Maybe<Pending_Collaboration_On_Artwork_Min_Order_By>;
  stddev?: Maybe<Pending_Collaboration_On_Artwork_Stddev_Order_By>;
  stddev_pop?: Maybe<Pending_Collaboration_On_Artwork_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Pending_Collaboration_On_Artwork_Stddev_Samp_Order_By>;
  sum?: Maybe<Pending_Collaboration_On_Artwork_Sum_Order_By>;
  var_pop?: Maybe<Pending_Collaboration_On_Artwork_Var_Pop_Order_By>;
  var_samp?: Maybe<Pending_Collaboration_On_Artwork_Var_Samp_Order_By>;
  variance?: Maybe<Pending_Collaboration_On_Artwork_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Arr_Rel_Insert_Input = {
  data: Array<Pending_Collaboration_On_Artwork_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Pending_Collaboration_On_Artwork_On_Conflict>;
};

/** aggregate avg on columns */
export type Pending_Collaboration_On_Artwork_Avg_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collaboration_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Pending_collaboration_on_artwork". All fields are combined with a logical 'AND'. */
export type Pending_Collaboration_On_Artwork_Bool_Exp = {
  _and?: Maybe<Array<Pending_Collaboration_On_Artwork_Bool_Exp>>;
  _not?: Maybe<Pending_Collaboration_On_Artwork_Bool_Exp>;
  _or?: Maybe<Array<Pending_Collaboration_On_Artwork_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  collaboration?: Maybe<Collaboration_Bool_Exp>;
  collaboration_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Pending_collaboration_on_artwork" */
export enum Pending_Collaboration_On_Artwork_Constraint {
  /** unique or primary key constraint on columns "collaboration_id", "artwork_id" */
  PendingCollaborationOnArtworkPkey = 'Pending_collaboration_on_artwork_pkey'
}

/** input type for incrementing numeric columns in table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  collaboration_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  collaboration?: Maybe<Collaboration_Obj_Rel_Insert_Input>;
  collaboration_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Pending_Collaboration_On_Artwork_Max_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  collaboration_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Pending_Collaboration_On_Artwork_Min_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  collaboration_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Mutation_Response = {
  __typename?: 'Pending_collaboration_on_artwork_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pending_Collaboration_On_Artwork>;
};

/** on_conflict condition type for table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_On_Conflict = {
  constraint: Pending_Collaboration_On_Artwork_Constraint;
  update_columns?: Array<Pending_Collaboration_On_Artwork_Update_Column>;
  where?: Maybe<Pending_Collaboration_On_Artwork_Bool_Exp>;
};

/** Ordering options when selecting data from "Pending_collaboration_on_artwork". */
export type Pending_Collaboration_On_Artwork_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  collaboration?: Maybe<Collaboration_Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Pending_collaboration_on_artwork */
export type Pending_Collaboration_On_Artwork_Pk_Columns_Input = {
  artwork_id: Scalars['bigint'];
  collaboration_id: Scalars['bigint'];
};

/** select columns of table "Pending_collaboration_on_artwork" */
export enum Pending_Collaboration_On_Artwork_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CollaborationId = 'collaboration_id'
}

/** input type for updating data in table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  collaboration_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Pending_Collaboration_On_Artwork_Stddev_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collaboration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pending_Collaboration_On_Artwork_Stddev_Pop_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collaboration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pending_Collaboration_On_Artwork_Stddev_Samp_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collaboration_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Pending_Collaboration_On_Artwork_Sum_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  collaboration_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** update columns of table "Pending_collaboration_on_artwork" */
export enum Pending_Collaboration_On_Artwork_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CollaborationId = 'collaboration_id'
}

/** aggregate var_pop on columns */
export type Pending_Collaboration_On_Artwork_Var_Pop_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collaboration_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pending_Collaboration_On_Artwork_Var_Samp_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collaboration_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Pending_Collaboration_On_Artwork_Variance_Fields = {
  __typename?: 'Pending_collaboration_on_artwork_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  collaboration_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Pending_collaboration_on_artwork" */
export type Pending_Collaboration_On_Artwork_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  collaboration_id?: Maybe<Order_By>;
};

export type Photo = {
  __typename?: 'Photo';
  classifications?: Maybe<Array<Scalars['String']>>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type Place = {
  __typename?: 'Place';
  categories?: Maybe<Array<Categories>>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  geoCoordinates: GeoCoorinates;
  hours?: Maybe<Hours>;
  id: Scalars['String'];
  location?: Maybe<Location>;
  name: Scalars['String'];
  photos?: Maybe<Array<Photo>>;
  popularity?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type PlacesSearchArea = {
  __typename?: 'PlacesSearchArea';
  centerPointLat: Scalars['Float'];
  centerPointLng: Scalars['Float'];
  radiusMeters: Scalars['Int'];
};

export type PlacesSearchParameters = {
  centerPointLat: Scalars['Float'];
  centerPointLng: Scalars['Float'];
  markerTypes?: Maybe<Array<Marker_Type>>;
  radiusMeters: Scalars['Int'];
};

export type PlacesSearchResult = {
  __typename?: 'PlacesSearchResult';
  context: PlacesSearchArea;
  places: Array<Place>;
};

/** Catalog for available profile price ranges  */
export type Price_Ranges = {
  __typename?: 'Price_Ranges';
  active: Scalars['Boolean'];
  id: Scalars['bigint'];
  max_value: Scalars['float8'];
  min_value: Scalars['float8'];
  order: Scalars['Int'];
  /** An array relationship */
  profile_selected_price_ranges: Array<Profile_Price_Ranges>;
  /** An aggregate relationship */
  profile_selected_price_ranges_aggregate: Profile_Price_Ranges_Aggregate;
  translation_key: Scalars['String'];
};


/** Catalog for available profile price ranges  */
export type Price_RangesProfile_Selected_Price_RangesArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


/** Catalog for available profile price ranges  */
export type Price_RangesProfile_Selected_Price_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};

/** aggregated selection of "Price_Ranges" */
export type Price_Ranges_Aggregate = {
  __typename?: 'Price_Ranges_aggregate';
  aggregate?: Maybe<Price_Ranges_Aggregate_Fields>;
  nodes: Array<Price_Ranges>;
};

/** aggregate fields of "Price_Ranges" */
export type Price_Ranges_Aggregate_Fields = {
  __typename?: 'Price_Ranges_aggregate_fields';
  avg?: Maybe<Price_Ranges_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Price_Ranges_Max_Fields>;
  min?: Maybe<Price_Ranges_Min_Fields>;
  stddev?: Maybe<Price_Ranges_Stddev_Fields>;
  stddev_pop?: Maybe<Price_Ranges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Price_Ranges_Stddev_Samp_Fields>;
  sum?: Maybe<Price_Ranges_Sum_Fields>;
  var_pop?: Maybe<Price_Ranges_Var_Pop_Fields>;
  var_samp?: Maybe<Price_Ranges_Var_Samp_Fields>;
  variance?: Maybe<Price_Ranges_Variance_Fields>;
};


/** aggregate fields of "Price_Ranges" */
export type Price_Ranges_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Price_Ranges_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Price_Ranges_Avg_Fields = {
  __typename?: 'Price_Ranges_avg_fields';
  id?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Price_Ranges". All fields are combined with a logical 'AND'. */
export type Price_Ranges_Bool_Exp = {
  _and?: Maybe<Array<Price_Ranges_Bool_Exp>>;
  _not?: Maybe<Price_Ranges_Bool_Exp>;
  _or?: Maybe<Array<Price_Ranges_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  max_value?: Maybe<Float8_Comparison_Exp>;
  min_value?: Maybe<Float8_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  profile_selected_price_ranges?: Maybe<Profile_Price_Ranges_Bool_Exp>;
  translation_key?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Price_Ranges" */
export enum Price_Ranges_Constraint {
  /** unique or primary key constraint on columns "id" */
  PriceRangesPkey = 'Price_Ranges_pkey'
}

/** input type for incrementing numeric columns in table "Price_Ranges" */
export type Price_Ranges_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  max_value?: Maybe<Scalars['float8']>;
  min_value?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Price_Ranges" */
export type Price_Ranges_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  max_value?: Maybe<Scalars['float8']>;
  min_value?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  profile_selected_price_ranges?: Maybe<Profile_Price_Ranges_Arr_Rel_Insert_Input>;
  translation_key?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Price_Ranges_Max_Fields = {
  __typename?: 'Price_Ranges_max_fields';
  id?: Maybe<Scalars['bigint']>;
  max_value?: Maybe<Scalars['float8']>;
  min_value?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Price_Ranges_Min_Fields = {
  __typename?: 'Price_Ranges_min_fields';
  id?: Maybe<Scalars['bigint']>;
  max_value?: Maybe<Scalars['float8']>;
  min_value?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Price_Ranges" */
export type Price_Ranges_Mutation_Response = {
  __typename?: 'Price_Ranges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Price_Ranges>;
};

/** on_conflict condition type for table "Price_Ranges" */
export type Price_Ranges_On_Conflict = {
  constraint: Price_Ranges_Constraint;
  update_columns?: Array<Price_Ranges_Update_Column>;
  where?: Maybe<Price_Ranges_Bool_Exp>;
};

/** Ordering options when selecting data from "Price_Ranges". */
export type Price_Ranges_Order_By = {
  active?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  max_value?: Maybe<Order_By>;
  min_value?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  profile_selected_price_ranges_aggregate?: Maybe<Profile_Price_Ranges_Aggregate_Order_By>;
  translation_key?: Maybe<Order_By>;
};

/** primary key columns input for table: Price_Ranges */
export type Price_Ranges_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Price_Ranges" */
export enum Price_Ranges_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  MaxValue = 'max_value',
  /** column name */
  MinValue = 'min_value',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translation_key'
}

/** input type for updating data in table "Price_Ranges" */
export type Price_Ranges_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  max_value?: Maybe<Scalars['float8']>;
  min_value?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Price_Ranges_Stddev_Fields = {
  __typename?: 'Price_Ranges_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Price_Ranges_Stddev_Pop_Fields = {
  __typename?: 'Price_Ranges_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Price_Ranges_Stddev_Samp_Fields = {
  __typename?: 'Price_Ranges_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Price_Ranges_Sum_Fields = {
  __typename?: 'Price_Ranges_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  max_value?: Maybe<Scalars['float8']>;
  min_value?: Maybe<Scalars['float8']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "Price_Ranges" */
export enum Price_Ranges_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  MaxValue = 'max_value',
  /** column name */
  MinValue = 'min_value',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translation_key'
}

/** aggregate var_pop on columns */
export type Price_Ranges_Var_Pop_Fields = {
  __typename?: 'Price_Ranges_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Price_Ranges_Var_Samp_Fields = {
  __typename?: 'Price_Ranges_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Price_Ranges_Variance_Fields = {
  __typename?: 'Price_Ranges_variance_fields';
  id?: Maybe<Scalars['Float']>;
  max_value?: Maybe<Scalars['Float']>;
  min_value?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Table containing information of every single print */
export type Print = {
  __typename?: 'Print';
  /** An object relationship */
  Artwork: Artwork;
  /** An object relationship */
  ENUM_artwork_showroom_status: Enum_Artwork_Showroom_Status;
  /** An object relationship */
  Edition: Edition;
  /** An object relationship */
  Edition_By_Type: Edition_By_Type;
  artwork_id: Scalars['bigint'];
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_type_id: Scalars['bigint'];
  edition_id: Scalars['bigint'];
  id: Scalars['bigint'];
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  print_number: Scalars['Int'];
  /** An array relationship */
  print_to_collections: Array<Print_To_Collection>;
  /** An aggregate relationship */
  print_to_collections_aggregate: Print_To_Collection_Aggregate;
  slug?: Maybe<Scalars['String']>;
  status: Enum_Artwork_Showroom_Status_Enum;
  /** An array relationship */
  transaction_histories: Array<Artwork_Transactions>;
  /** An aggregate relationship */
  transaction_histories_aggregate: Artwork_Transactions_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** Table containing information of every single print */
export type PrintPrint_To_CollectionsArgs = {
  distinct_on?: Maybe<Array<Print_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_To_Collection_Order_By>>;
  where?: Maybe<Print_To_Collection_Bool_Exp>;
};


/** Table containing information of every single print */
export type PrintPrint_To_Collections_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_To_Collection_Order_By>>;
  where?: Maybe<Print_To_Collection_Bool_Exp>;
};


/** Table containing information of every single print */
export type PrintTransaction_HistoriesArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** Table containing information of every single print */
export type PrintTransaction_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};

/** aggregated selection of "Print" */
export type Print_Aggregate = {
  __typename?: 'Print_aggregate';
  aggregate?: Maybe<Print_Aggregate_Fields>;
  nodes: Array<Print>;
};

/** aggregate fields of "Print" */
export type Print_Aggregate_Fields = {
  __typename?: 'Print_aggregate_fields';
  avg?: Maybe<Print_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Print_Max_Fields>;
  min?: Maybe<Print_Min_Fields>;
  stddev?: Maybe<Print_Stddev_Fields>;
  stddev_pop?: Maybe<Print_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Print_Stddev_Samp_Fields>;
  sum?: Maybe<Print_Sum_Fields>;
  var_pop?: Maybe<Print_Var_Pop_Fields>;
  var_samp?: Maybe<Print_Var_Samp_Fields>;
  variance?: Maybe<Print_Variance_Fields>;
};


/** aggregate fields of "Print" */
export type Print_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Print_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Print" */
export type Print_Aggregate_Order_By = {
  avg?: Maybe<Print_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Print_Max_Order_By>;
  min?: Maybe<Print_Min_Order_By>;
  stddev?: Maybe<Print_Stddev_Order_By>;
  stddev_pop?: Maybe<Print_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Print_Stddev_Samp_Order_By>;
  sum?: Maybe<Print_Sum_Order_By>;
  var_pop?: Maybe<Print_Var_Pop_Order_By>;
  var_samp?: Maybe<Print_Var_Samp_Order_By>;
  variance?: Maybe<Print_Variance_Order_By>;
};

/** columns and relationships of "Print_amounts" */
export type Print_Amounts = {
  __typename?: 'Print_amounts';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['Int']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "Print_amounts" */
export type Print_Amounts_Aggregate = {
  __typename?: 'Print_amounts_aggregate';
  aggregate?: Maybe<Print_Amounts_Aggregate_Fields>;
  nodes: Array<Print_Amounts>;
};

/** aggregate fields of "Print_amounts" */
export type Print_Amounts_Aggregate_Fields = {
  __typename?: 'Print_amounts_aggregate_fields';
  avg?: Maybe<Print_Amounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Print_Amounts_Max_Fields>;
  min?: Maybe<Print_Amounts_Min_Fields>;
  stddev?: Maybe<Print_Amounts_Stddev_Fields>;
  stddev_pop?: Maybe<Print_Amounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Print_Amounts_Stddev_Samp_Fields>;
  sum?: Maybe<Print_Amounts_Sum_Fields>;
  var_pop?: Maybe<Print_Amounts_Var_Pop_Fields>;
  var_samp?: Maybe<Print_Amounts_Var_Samp_Fields>;
  variance?: Maybe<Print_Amounts_Variance_Fields>;
};


/** aggregate fields of "Print_amounts" */
export type Print_Amounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Print_Amounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Print_amounts" */
export type Print_Amounts_Aggregate_Order_By = {
  avg?: Maybe<Print_Amounts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Print_Amounts_Max_Order_By>;
  min?: Maybe<Print_Amounts_Min_Order_By>;
  stddev?: Maybe<Print_Amounts_Stddev_Order_By>;
  stddev_pop?: Maybe<Print_Amounts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Print_Amounts_Stddev_Samp_Order_By>;
  sum?: Maybe<Print_Amounts_Sum_Order_By>;
  var_pop?: Maybe<Print_Amounts_Var_Pop_Order_By>;
  var_samp?: Maybe<Print_Amounts_Var_Samp_Order_By>;
  variance?: Maybe<Print_Amounts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Print_amounts" */
export type Print_Amounts_Arr_Rel_Insert_Input = {
  data: Array<Print_Amounts_Insert_Input>;
};

/** aggregate avg on columns */
export type Print_Amounts_Avg_Fields = {
  __typename?: 'Print_amounts_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Print_amounts" */
export type Print_Amounts_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Print_amounts". All fields are combined with a logical 'AND'. */
export type Print_Amounts_Bool_Exp = {
  _and?: Maybe<Array<Print_Amounts_Bool_Exp>>;
  _not?: Maybe<Print_Amounts_Bool_Exp>;
  _or?: Maybe<Array<Print_Amounts_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  available_print_amount?: Maybe<Int_Comparison_Exp>;
  edition_by_size_id?: Maybe<Bigint_Comparison_Exp>;
  edition_by_type_id?: Maybe<Bigint_Comparison_Exp>;
  edition_id?: Maybe<Bigint_Comparison_Exp>;
  print_amount?: Maybe<Int_Comparison_Exp>;
  unavailable_print_amount?: Maybe<Int_Comparison_Exp>;
};

/** input type for inserting data into table "Print_amounts" */
export type Print_Amounts_Insert_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['Int']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Print_Amounts_Max_Fields = {
  __typename?: 'Print_amounts_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['Int']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Print_amounts" */
export type Print_Amounts_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Print_Amounts_Min_Fields = {
  __typename?: 'Print_amounts_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['Int']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Print_amounts" */
export type Print_Amounts_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "Print_amounts". */
export type Print_Amounts_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** select columns of table "Print_amounts" */
export enum Print_Amounts_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AvailablePrintAmount = 'available_print_amount',
  /** column name */
  EditionBySizeId = 'edition_by_size_id',
  /** column name */
  EditionByTypeId = 'edition_by_type_id',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  PrintAmount = 'print_amount',
  /** column name */
  UnavailablePrintAmount = 'unavailable_print_amount'
}

/** aggregate stddev on columns */
export type Print_Amounts_Stddev_Fields = {
  __typename?: 'Print_amounts_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Print_amounts" */
export type Print_Amounts_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Print_Amounts_Stddev_Pop_Fields = {
  __typename?: 'Print_amounts_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Print_amounts" */
export type Print_Amounts_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Print_Amounts_Stddev_Samp_Fields = {
  __typename?: 'Print_amounts_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Print_amounts" */
export type Print_Amounts_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Print_Amounts_Sum_Fields = {
  __typename?: 'Print_amounts_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['Int']>;
  edition_by_size_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  print_amount?: Maybe<Scalars['Int']>;
  unavailable_print_amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Print_amounts" */
export type Print_Amounts_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Print_Amounts_Var_Pop_Fields = {
  __typename?: 'Print_amounts_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Print_amounts" */
export type Print_Amounts_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Print_Amounts_Var_Samp_Fields = {
  __typename?: 'Print_amounts_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Print_amounts" */
export type Print_Amounts_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Print_Amounts_Variance_Fields = {
  __typename?: 'Print_amounts_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
  edition_by_size_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  print_amount?: Maybe<Scalars['Float']>;
  unavailable_print_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Print_amounts" */
export type Print_Amounts_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
  edition_by_size_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  print_amount?: Maybe<Order_By>;
  unavailable_print_amount?: Maybe<Order_By>;
};

/** input type for inserting array relation for remote table "Print" */
export type Print_Arr_Rel_Insert_Input = {
  data: Array<Print_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Print_On_Conflict>;
};

/** columns and relationships of "Print_artwork_collector_listing" */
export type Print_Artwork_Collector_Listing = {
  __typename?: 'Print_artwork_collector_listing';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_collection_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  collected_at?: Maybe<Scalars['timestamptz']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  is_public?: Maybe<Scalars['Boolean']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "Print_artwork_collector_listing" */
export type Print_Artwork_Collector_Listing_Aggregate = {
  __typename?: 'Print_artwork_collector_listing_aggregate';
  aggregate?: Maybe<Print_Artwork_Collector_Listing_Aggregate_Fields>;
  nodes: Array<Print_Artwork_Collector_Listing>;
};

/** aggregate fields of "Print_artwork_collector_listing" */
export type Print_Artwork_Collector_Listing_Aggregate_Fields = {
  __typename?: 'Print_artwork_collector_listing_aggregate_fields';
  avg?: Maybe<Print_Artwork_Collector_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Print_Artwork_Collector_Listing_Max_Fields>;
  min?: Maybe<Print_Artwork_Collector_Listing_Min_Fields>;
  stddev?: Maybe<Print_Artwork_Collector_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Print_Artwork_Collector_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Print_Artwork_Collector_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Print_Artwork_Collector_Listing_Sum_Fields>;
  var_pop?: Maybe<Print_Artwork_Collector_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Print_Artwork_Collector_Listing_Var_Samp_Fields>;
  variance?: Maybe<Print_Artwork_Collector_Listing_Variance_Fields>;
};


/** aggregate fields of "Print_artwork_collector_listing" */
export type Print_Artwork_Collector_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Print_Artwork_Collector_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Print_Artwork_Collector_Listing_Avg_Fields = {
  __typename?: 'Print_artwork_collector_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Print_artwork_collector_listing". All fields are combined with a logical 'AND'. */
export type Print_Artwork_Collector_Listing_Bool_Exp = {
  _and?: Maybe<Array<Print_Artwork_Collector_Listing_Bool_Exp>>;
  _not?: Maybe<Print_Artwork_Collector_Listing_Bool_Exp>;
  _or?: Maybe<Array<Print_Artwork_Collector_Listing_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_title?: Maybe<String_Comparison_Exp>;
  artwork_collection_order?: Maybe<Int_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_type?: Maybe<String_Comparison_Exp>;
  collected_at?: Maybe<Timestamptz_Comparison_Exp>;
  creation_date_year?: Maybe<Int_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  owner_context_id?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Print_Artwork_Collector_Listing_Max_Fields = {
  __typename?: 'Print_artwork_collector_listing_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_collection_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  collected_at?: Maybe<Scalars['timestamptz']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Print_Artwork_Collector_Listing_Min_Fields = {
  __typename?: 'Print_artwork_collector_listing_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_collection_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  collected_at?: Maybe<Scalars['timestamptz']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "Print_artwork_collector_listing". */
export type Print_Artwork_Collector_Listing_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_title?: Maybe<Order_By>;
  artwork_collection_order?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_type?: Maybe<Order_By>;
  collected_at?: Maybe<Order_By>;
  creation_date_year?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
};

/** select columns of table "Print_artwork_collector_listing" */
export enum Print_Artwork_Collector_Listing_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistTitle = 'artist_title',
  /** column name */
  ArtworkCollectionOrder = 'artwork_collection_order',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkType = 'artwork_type',
  /** column name */
  CollectedAt = 'collected_at',
  /** column name */
  CreationDateYear = 'creation_date_year',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  OwnerContextId = 'owner_context_id'
}

/** aggregate stddev on columns */
export type Print_Artwork_Collector_Listing_Stddev_Fields = {
  __typename?: 'Print_artwork_collector_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Print_Artwork_Collector_Listing_Stddev_Pop_Fields = {
  __typename?: 'Print_artwork_collector_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Print_Artwork_Collector_Listing_Stddev_Samp_Fields = {
  __typename?: 'Print_artwork_collector_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Print_Artwork_Collector_Listing_Sum_Fields = {
  __typename?: 'Print_artwork_collector_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_collection_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  creation_date_year?: Maybe<Scalars['Int']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Print_Artwork_Collector_Listing_Var_Pop_Fields = {
  __typename?: 'Print_artwork_collector_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Print_Artwork_Collector_Listing_Var_Samp_Fields = {
  __typename?: 'Print_artwork_collector_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Print_Artwork_Collector_Listing_Variance_Fields = {
  __typename?: 'Print_artwork_collector_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  creation_date_year?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Print_artwork_info" */
export type Print_Artwork_Info = {
  __typename?: 'Print_artwork_info';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_collection_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  collected_at?: Maybe<Scalars['timestamptz']>;
  collection_id?: Maybe<Scalars['bigint']>;
  collection_type?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "Print_artwork_info" */
export type Print_Artwork_Info_Aggregate = {
  __typename?: 'Print_artwork_info_aggregate';
  aggregate?: Maybe<Print_Artwork_Info_Aggregate_Fields>;
  nodes: Array<Print_Artwork_Info>;
};

/** aggregate fields of "Print_artwork_info" */
export type Print_Artwork_Info_Aggregate_Fields = {
  __typename?: 'Print_artwork_info_aggregate_fields';
  avg?: Maybe<Print_Artwork_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Print_Artwork_Info_Max_Fields>;
  min?: Maybe<Print_Artwork_Info_Min_Fields>;
  stddev?: Maybe<Print_Artwork_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Print_Artwork_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Print_Artwork_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Print_Artwork_Info_Sum_Fields>;
  var_pop?: Maybe<Print_Artwork_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Print_Artwork_Info_Var_Samp_Fields>;
  variance?: Maybe<Print_Artwork_Info_Variance_Fields>;
};


/** aggregate fields of "Print_artwork_info" */
export type Print_Artwork_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Print_Artwork_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Print_Artwork_Info_Avg_Fields = {
  __typename?: 'Print_artwork_info_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Print_artwork_info". All fields are combined with a logical 'AND'. */
export type Print_Artwork_Info_Bool_Exp = {
  _and?: Maybe<Array<Print_Artwork_Info_Bool_Exp>>;
  _not?: Maybe<Print_Artwork_Info_Bool_Exp>;
  _or?: Maybe<Array<Print_Artwork_Info_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artist_title?: Maybe<String_Comparison_Exp>;
  artwork_collection_order?: Maybe<Int_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_type?: Maybe<String_Comparison_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  collected_at?: Maybe<Timestamptz_Comparison_Exp>;
  collection_id?: Maybe<Bigint_Comparison_Exp>;
  collection_type?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  last_transaction_id?: Maybe<Bigint_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  original_artwork_id?: Maybe<Bigint_Comparison_Exp>;
  owner_context_id?: Maybe<Bigint_Comparison_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Print_Artwork_Info_Max_Fields = {
  __typename?: 'Print_artwork_info_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_collection_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  collected_at?: Maybe<Scalars['timestamptz']>;
  collection_id?: Maybe<Scalars['bigint']>;
  collection_type?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  last_name?: Maybe<Scalars['String']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Print_Artwork_Info_Min_Fields = {
  __typename?: 'Print_artwork_info_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_title?: Maybe<Scalars['String']>;
  artwork_collection_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  collected_at?: Maybe<Scalars['timestamptz']>;
  collection_id?: Maybe<Scalars['bigint']>;
  collection_type?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['float8']>;
  last_name?: Maybe<Scalars['String']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  measuring_unit?: Maybe<Scalars['String']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "Print_artwork_info". */
export type Print_Artwork_Info_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artist_title?: Maybe<Order_By>;
  artwork_collection_order?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_type?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  collected_at?: Maybe<Order_By>;
  collection_id?: Maybe<Order_By>;
  collection_type?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  last_transaction_id?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  original_artwork_id?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "Print_artwork_info" */
export enum Print_Artwork_Info_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtistTitle = 'artist_title',
  /** column name */
  ArtworkCollectionOrder = 'artwork_collection_order',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkType = 'artwork_type',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  CollectedAt = 'collected_at',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CollectionType = 'collection_type',
  /** column name */
  Country = 'country',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Depth = 'depth',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Handle = 'handle',
  /** column name */
  Height = 'height',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastTransactionId = 'last_transaction_id',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  OriginalArtworkId = 'original_artwork_id',
  /** column name */
  OwnerContextId = 'owner_context_id',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Print_Artwork_Info_Stddev_Fields = {
  __typename?: 'Print_artwork_info_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Print_Artwork_Info_Stddev_Pop_Fields = {
  __typename?: 'Print_artwork_info_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Print_Artwork_Info_Stddev_Samp_Fields = {
  __typename?: 'Print_artwork_info_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Print_Artwork_Info_Sum_Fields = {
  __typename?: 'Print_artwork_info_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_collection_order?: Maybe<Scalars['Int']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  collection_id?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  height?: Maybe<Scalars['float8']>;
  last_transaction_id?: Maybe<Scalars['bigint']>;
  original_artwork_id?: Maybe<Scalars['bigint']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Print_Artwork_Info_Var_Pop_Fields = {
  __typename?: 'Print_artwork_info_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Print_Artwork_Info_Var_Samp_Fields = {
  __typename?: 'Print_artwork_info_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Print_Artwork_Info_Variance_Fields = {
  __typename?: 'Print_artwork_info_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_collection_order?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  collection_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  last_transaction_id?: Maybe<Scalars['Float']>;
  original_artwork_id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_View = {
  __typename?: 'Print_artwork_view';
  /** An array relationship */
  Artwork_events: Array<Artwork_Events>;
  /** An aggregate relationship */
  Artwork_events_aggregate: Artwork_Events_Aggregate;
  /** An array relationship */
  Artwork_prices: Array<Artwork_Prices>;
  /** An aggregate relationship */
  Artwork_prices_aggregate: Artwork_Prices_Aggregate;
  /** An array relationship */
  Artwork_state_histories: Array<Artwork_State_History>;
  /** An aggregate relationship */
  Artwork_state_histories_aggregate: Artwork_State_History_Aggregate;
  /** An object relationship */
  ENUM_add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step>;
  add_artwork_flow_step?: Maybe<Scalars['String']>;
  /** An object relationship */
  artist_context?: Maybe<Context>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  /** An object relationship */
  artwork_details?: Maybe<Artwork_Details>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  artwork_secondary_media: Array<Artwork_Secondary_Media>;
  /** An aggregate relationship */
  artwork_secondary_media_aggregate: Artwork_Secondary_Media_Aggregate;
  /** An object relationship */
  artwork_story?: Maybe<Artwork_Story>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  artwork_to_collections: Array<Artwork_To_Collection>;
  /** An aggregate relationship */
  artwork_to_collections_aggregate: Artwork_To_Collection_Aggregate;
  /** An array relationship */
  artwork_to_tags: Array<Artwork_To_Tag>;
  /** An aggregate relationship */
  artwork_to_tags_aggregate: Artwork_To_Tag_Aggregate;
  artwork_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edition_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  gallery_context?: Maybe<Context>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  has_ar_animation?: Maybe<Scalars['Boolean']>;
  has_video?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_hard_minted?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  last_updater?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  links?: Maybe<Artwork_Links>;
  /** An object relationship */
  primary_image_metadata?: Maybe<File_Metadata>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  print?: Maybe<Print>;
  print_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  purchase_inquires: Array<Purchase_Inquires>;
  /** An aggregate relationship */
  purchase_inquires_aggregate: Purchase_Inquires_Aggregate;
  /** An array relationship */
  purchase_inquires_outside_users: Array<Purchase_Inquires_Outside_Users>;
  /** An aggregate relationship */
  purchase_inquires_outside_users_aggregate: Purchase_Inquires_Outside_Users_Aggregate;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  thumbnail_metadata?: Maybe<File_Metadata>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  transaction_histories: Array<Artwork_Transactions>;
  /** An aggregate relationship */
  transaction_histories_aggregate: Artwork_Transactions_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
  /** An array relationship */
  vumarks: Array<Vumarks>;
  /** An aggregate relationship */
  vumarks_aggregate: Vumarks_Aggregate;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_EventsArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_PricesArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_Prices_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_State_HistoriesArgs = {
  distinct_on?: Maybe<Array<Artwork_State_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_State_History_Order_By>>;
  where?: Maybe<Artwork_State_History_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_State_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_State_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_State_History_Order_By>>;
  where?: Maybe<Artwork_State_History_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_Secondary_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_Secondary_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_To_CollectionsArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_To_Collections_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_To_TagsArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Tag_Order_By>>;
  where?: Maybe<Artwork_To_Tag_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewArtwork_To_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Tag_Order_By>>;
  where?: Maybe<Artwork_To_Tag_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewPurchase_InquiresArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewPurchase_Inquires_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewPurchase_Inquires_Outside_UsersArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewPurchase_Inquires_Outside_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewTransaction_HistoriesArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewTransaction_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewVumarksArgs = {
  distinct_on?: Maybe<Array<Vumarks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vumarks_Order_By>>;
  where?: Maybe<Vumarks_Bool_Exp>;
};


/** columns and relationships of "Print_artwork_view" */
export type Print_Artwork_ViewVumarks_AggregateArgs = {
  distinct_on?: Maybe<Array<Vumarks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vumarks_Order_By>>;
  where?: Maybe<Vumarks_Bool_Exp>;
};

/** aggregated selection of "Print_artwork_view" */
export type Print_Artwork_View_Aggregate = {
  __typename?: 'Print_artwork_view_aggregate';
  aggregate?: Maybe<Print_Artwork_View_Aggregate_Fields>;
  nodes: Array<Print_Artwork_View>;
};

/** aggregate fields of "Print_artwork_view" */
export type Print_Artwork_View_Aggregate_Fields = {
  __typename?: 'Print_artwork_view_aggregate_fields';
  avg?: Maybe<Print_Artwork_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Print_Artwork_View_Max_Fields>;
  min?: Maybe<Print_Artwork_View_Min_Fields>;
  stddev?: Maybe<Print_Artwork_View_Stddev_Fields>;
  stddev_pop?: Maybe<Print_Artwork_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Print_Artwork_View_Stddev_Samp_Fields>;
  sum?: Maybe<Print_Artwork_View_Sum_Fields>;
  var_pop?: Maybe<Print_Artwork_View_Var_Pop_Fields>;
  var_samp?: Maybe<Print_Artwork_View_Var_Samp_Fields>;
  variance?: Maybe<Print_Artwork_View_Variance_Fields>;
};


/** aggregate fields of "Print_artwork_view" */
export type Print_Artwork_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Print_Artwork_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Print_Artwork_View_Avg_Fields = {
  __typename?: 'Print_artwork_view_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Print_artwork_view". All fields are combined with a logical 'AND'. */
export type Print_Artwork_View_Bool_Exp = {
  Artwork_events?: Maybe<Artwork_Events_Bool_Exp>;
  Artwork_prices?: Maybe<Artwork_Prices_Bool_Exp>;
  Artwork_state_histories?: Maybe<Artwork_State_History_Bool_Exp>;
  ENUM_add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Bool_Exp>;
  _and?: Maybe<Array<Print_Artwork_View_Bool_Exp>>;
  _not?: Maybe<Print_Artwork_View_Bool_Exp>;
  _or?: Maybe<Array<Print_Artwork_View_Bool_Exp>>;
  add_artwork_flow_step?: Maybe<String_Comparison_Exp>;
  artist_context?: Maybe<Context_Bool_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_artist_index?: Maybe<Int_Comparison_Exp>;
  artwork_details?: Maybe<Artwork_Details_Bool_Exp>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
  artwork_story?: Maybe<Artwork_Story_Bool_Exp>;
  artwork_story_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_to_collections?: Maybe<Artwork_To_Collection_Bool_Exp>;
  artwork_to_tags?: Maybe<Artwork_To_Tag_Bool_Exp>;
  artwork_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  gallery_context?: Maybe<Context_Bool_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  has_ar_animation?: Maybe<Boolean_Comparison_Exp>;
  has_video?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  is_hard_minted?: Maybe<Boolean_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  is_transaction_locked?: Maybe<Boolean_Comparison_Exp>;
  last_updater?: Maybe<Bigint_Comparison_Exp>;
  links?: Maybe<Artwork_Links_Bool_Exp>;
  primary_image_metadata?: Maybe<File_Metadata_Bool_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  print?: Maybe<Print_Bool_Exp>;
  print_id?: Maybe<Bigint_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  purchase_inquires?: Maybe<Purchase_Inquires_Bool_Exp>;
  purchase_inquires_outside_users?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  target_id?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata?: Maybe<File_Metadata_Bool_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  transaction_histories?: Maybe<Artwork_Transactions_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vuforia_rating?: Maybe<Int_Comparison_Exp>;
  vumarks?: Maybe<Vumarks_Bool_Exp>;
};

/** aggregate max on columns */
export type Print_Artwork_View_Max_Fields = {
  __typename?: 'Print_artwork_view_max_fields';
  add_artwork_flow_step?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  print_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Print_Artwork_View_Min_Fields = {
  __typename?: 'Print_artwork_view_min_fields';
  add_artwork_flow_step?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  artwork_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  print_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "Print_artwork_view". */
export type Print_Artwork_View_Order_By = {
  Artwork_events_aggregate?: Maybe<Artwork_Events_Aggregate_Order_By>;
  Artwork_prices_aggregate?: Maybe<Artwork_Prices_Aggregate_Order_By>;
  Artwork_state_histories_aggregate?: Maybe<Artwork_State_History_Aggregate_Order_By>;
  ENUM_add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Order_By>;
  add_artwork_flow_step?: Maybe<Order_By>;
  artist_context?: Maybe<Context_Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_artist_index?: Maybe<Order_By>;
  artwork_details?: Maybe<Artwork_Details_Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_secondary_media_aggregate?: Maybe<Artwork_Secondary_Media_Aggregate_Order_By>;
  artwork_story?: Maybe<Artwork_Story_Order_By>;
  artwork_story_id?: Maybe<Order_By>;
  artwork_to_collections_aggregate?: Maybe<Artwork_To_Collection_Aggregate_Order_By>;
  artwork_to_tags_aggregate?: Maybe<Artwork_To_Tag_Aggregate_Order_By>;
  artwork_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  gallery_context?: Maybe<Context_Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  has_ar_animation?: Maybe<Order_By>;
  has_video?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  is_hard_minted?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  is_transaction_locked?: Maybe<Order_By>;
  last_updater?: Maybe<Order_By>;
  links?: Maybe<Artwork_Links_Order_By>;
  primary_image_metadata?: Maybe<File_Metadata_Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  print?: Maybe<Print_Order_By>;
  print_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  purchase_inquires_aggregate?: Maybe<Purchase_Inquires_Aggregate_Order_By>;
  purchase_inquires_outside_users_aggregate?: Maybe<Purchase_Inquires_Outside_Users_Aggregate_Order_By>;
  slug?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  target_id?: Maybe<Order_By>;
  thumbnail_metadata?: Maybe<File_Metadata_Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  transaction_histories_aggregate?: Maybe<Artwork_Transactions_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  vuforia_rating?: Maybe<Order_By>;
  vumarks_aggregate?: Maybe<Vumarks_Aggregate_Order_By>;
};

/** select columns of table "Print_artwork_view" */
export enum Print_Artwork_View_Select_Column {
  /** column name */
  AddArtworkFlowStep = 'add_artwork_flow_step',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkArtistIndex = 'artwork_artist_index',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ArtworkStoryId = 'artwork_story_id',
  /** column name */
  ArtworkType = 'artwork_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  HasArAnimation = 'has_ar_animation',
  /** column name */
  HasVideo = 'has_video',
  /** column name */
  Id = 'id',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsHardMinted = 'is_hard_minted',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  IsTransactionLocked = 'is_transaction_locked',
  /** column name */
  LastUpdater = 'last_updater',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  PrintId = 'print_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VuforiaRating = 'vuforia_rating'
}

/** aggregate stddev on columns */
export type Print_Artwork_View_Stddev_Fields = {
  __typename?: 'Print_artwork_view_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Print_Artwork_View_Stddev_Pop_Fields = {
  __typename?: 'Print_artwork_view_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Print_Artwork_View_Stddev_Samp_Fields = {
  __typename?: 'Print_artwork_view_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Print_Artwork_View_Sum_Fields = {
  __typename?: 'Print_artwork_view_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_artist_index?: Maybe<Scalars['Int']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  artwork_story_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  last_updater?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  print_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  vuforia_rating?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Print_Artwork_View_Var_Pop_Fields = {
  __typename?: 'Print_artwork_view_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Print_Artwork_View_Var_Samp_Fields = {
  __typename?: 'Print_artwork_view_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Print_Artwork_View_Variance_Fields = {
  __typename?: 'Print_artwork_view_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_artist_index?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  artwork_story_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  last_updater?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  vuforia_rating?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type Print_Avg_Fields = {
  __typename?: 'Print_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Print" */
export type Print_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Print". All fields are combined with a logical 'AND'. */
export type Print_Bool_Exp = {
  Artwork?: Maybe<Artwork_Bool_Exp>;
  ENUM_artwork_showroom_status?: Maybe<Enum_Artwork_Showroom_Status_Bool_Exp>;
  Edition?: Maybe<Edition_Bool_Exp>;
  Edition_By_Type?: Maybe<Edition_By_Type_Bool_Exp>;
  _and?: Maybe<Array<Print_Bool_Exp>>;
  _not?: Maybe<Print_Bool_Exp>;
  _or?: Maybe<Array<Print_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  edition_by_type_id?: Maybe<Bigint_Comparison_Exp>;
  edition_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  is_transaction_locked?: Maybe<Boolean_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  print_number?: Maybe<Int_Comparison_Exp>;
  print_to_collections?: Maybe<Print_To_Collection_Bool_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Enum_Artwork_Showroom_Status_Enum_Comparison_Exp>;
  transaction_histories?: Maybe<Artwork_Transactions_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Print" */
export enum Print_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrintPkey = 'Print_pkey'
}

/** input type for incrementing numeric columns in table "Print" */
export type Print_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Print" */
export type Print_Insert_Input = {
  Artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  ENUM_artwork_showroom_status?: Maybe<Enum_Artwork_Showroom_Status_Obj_Rel_Insert_Input>;
  Edition?: Maybe<Edition_Obj_Rel_Insert_Input>;
  Edition_By_Type?: Maybe<Edition_By_Type_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  print_number?: Maybe<Scalars['Int']>;
  print_to_collections?: Maybe<Print_To_Collection_Arr_Rel_Insert_Input>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Artwork_Showroom_Status_Enum>;
  transaction_histories?: Maybe<Artwork_Transactions_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Print_Max_Fields = {
  __typename?: 'Print_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_number?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Print" */
export type Print_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Print_Min_Fields = {
  __typename?: 'Print_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_number?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Print" */
export type Print_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Print" */
export type Print_Mutation_Response = {
  __typename?: 'Print_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Print>;
};

/** input type for inserting object relation for remote table "Print" */
export type Print_Obj_Rel_Insert_Input = {
  data: Print_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Print_On_Conflict>;
};

/** on_conflict condition type for table "Print" */
export type Print_On_Conflict = {
  constraint: Print_Constraint;
  update_columns?: Array<Print_Update_Column>;
  where?: Maybe<Print_Bool_Exp>;
};

/** Ordering options when selecting data from "Print". */
export type Print_Order_By = {
  Artwork?: Maybe<Artwork_Order_By>;
  ENUM_artwork_showroom_status?: Maybe<Enum_Artwork_Showroom_Status_Order_By>;
  Edition?: Maybe<Edition_Order_By>;
  Edition_By_Type?: Maybe<Edition_By_Type_Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  is_transaction_locked?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
  print_to_collections_aggregate?: Maybe<Print_To_Collection_Aggregate_Order_By>;
  slug?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  transaction_histories_aggregate?: Maybe<Artwork_Transactions_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Print */
export type Print_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Print" */
export enum Print_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EditionByTypeId = 'edition_by_type_id',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  IsTransactionLocked = 'is_transaction_locked',
  /** column name */
  Price = 'price',
  /** column name */
  PrintNumber = 'print_number',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Print" */
export type Print_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['float8']>;
  print_number?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Artwork_Showroom_Status_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Print_Stddev_Fields = {
  __typename?: 'Print_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Print" */
export type Print_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Print_Stddev_Pop_Fields = {
  __typename?: 'Print_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Print" */
export type Print_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Print_Stddev_Samp_Fields = {
  __typename?: 'Print_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Print" */
export type Print_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Print_Sum_Fields = {
  __typename?: 'Print_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['float8']>;
  print_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Print" */
export type Print_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
};

/** Associates an Print to a Collection */
export type Print_To_Collection = {
  __typename?: 'Print_to_Collection';
  /** An object relationship */
  Collection: Collection;
  /** An object relationship */
  Print: Print;
  collection_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_public?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  print_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Print_to_Collection" */
export type Print_To_Collection_Aggregate = {
  __typename?: 'Print_to_Collection_aggregate';
  aggregate?: Maybe<Print_To_Collection_Aggregate_Fields>;
  nodes: Array<Print_To_Collection>;
};

/** aggregate fields of "Print_to_Collection" */
export type Print_To_Collection_Aggregate_Fields = {
  __typename?: 'Print_to_Collection_aggregate_fields';
  avg?: Maybe<Print_To_Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Print_To_Collection_Max_Fields>;
  min?: Maybe<Print_To_Collection_Min_Fields>;
  stddev?: Maybe<Print_To_Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Print_To_Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Print_To_Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Print_To_Collection_Sum_Fields>;
  var_pop?: Maybe<Print_To_Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Print_To_Collection_Var_Samp_Fields>;
  variance?: Maybe<Print_To_Collection_Variance_Fields>;
};


/** aggregate fields of "Print_to_Collection" */
export type Print_To_Collection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Print_To_Collection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Print_to_Collection" */
export type Print_To_Collection_Aggregate_Order_By = {
  avg?: Maybe<Print_To_Collection_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Print_To_Collection_Max_Order_By>;
  min?: Maybe<Print_To_Collection_Min_Order_By>;
  stddev?: Maybe<Print_To_Collection_Stddev_Order_By>;
  stddev_pop?: Maybe<Print_To_Collection_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Print_To_Collection_Stddev_Samp_Order_By>;
  sum?: Maybe<Print_To_Collection_Sum_Order_By>;
  var_pop?: Maybe<Print_To_Collection_Var_Pop_Order_By>;
  var_samp?: Maybe<Print_To_Collection_Var_Samp_Order_By>;
  variance?: Maybe<Print_To_Collection_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Print_to_Collection" */
export type Print_To_Collection_Arr_Rel_Insert_Input = {
  data: Array<Print_To_Collection_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Print_To_Collection_On_Conflict>;
};

/** aggregate avg on columns */
export type Print_To_Collection_Avg_Fields = {
  __typename?: 'Print_to_Collection_avg_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Avg_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Print_to_Collection". All fields are combined with a logical 'AND'. */
export type Print_To_Collection_Bool_Exp = {
  Collection?: Maybe<Collection_Bool_Exp>;
  Print?: Maybe<Print_Bool_Exp>;
  _and?: Maybe<Array<Print_To_Collection_Bool_Exp>>;
  _not?: Maybe<Print_To_Collection_Bool_Exp>;
  _or?: Maybe<Array<Print_To_Collection_Bool_Exp>>;
  collection_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  print_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Print_to_Collection" */
export enum Print_To_Collection_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrintToCollectionPkey = 'Print_to_Collection_pkey',
  /** unique or primary key constraint on columns "collection_id", "print_id" */
  PrintToCollectionPrintIdCollectionIdKey = 'Print_to_Collection_print_id_collection_id_key'
}

/** input type for incrementing numeric columns in table "Print_to_Collection" */
export type Print_To_Collection_Inc_Input = {
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Print_to_Collection" */
export type Print_To_Collection_Insert_Input = {
  Collection?: Maybe<Collection_Obj_Rel_Insert_Input>;
  Print?: Maybe<Print_Obj_Rel_Insert_Input>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_public?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Print_To_Collection_Max_Fields = {
  __typename?: 'Print_to_Collection_max_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Max_Order_By = {
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Print_To_Collection_Min_Fields = {
  __typename?: 'Print_to_Collection_min_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Min_Order_By = {
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Print_to_Collection" */
export type Print_To_Collection_Mutation_Response = {
  __typename?: 'Print_to_Collection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Print_To_Collection>;
};

/** on_conflict condition type for table "Print_to_Collection" */
export type Print_To_Collection_On_Conflict = {
  constraint: Print_To_Collection_Constraint;
  update_columns?: Array<Print_To_Collection_Update_Column>;
  where?: Maybe<Print_To_Collection_Bool_Exp>;
};

/** Ordering options when selecting data from "Print_to_Collection". */
export type Print_To_Collection_Order_By = {
  Collection?: Maybe<Collection_Order_By>;
  Print?: Maybe<Print_Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Print_to_Collection */
export type Print_To_Collection_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Print_to_Collection" */
export enum Print_To_Collection_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Order = 'order',
  /** column name */
  PrintId = 'print_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Print_to_Collection" */
export type Print_To_Collection_Set_Input = {
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_public?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Print_To_Collection_Stddev_Fields = {
  __typename?: 'Print_to_Collection_stddev_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Stddev_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Print_To_Collection_Stddev_Pop_Fields = {
  __typename?: 'Print_to_Collection_stddev_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Stddev_Pop_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Print_To_Collection_Stddev_Samp_Fields = {
  __typename?: 'Print_to_Collection_stddev_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Stddev_Samp_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Print_To_Collection_Sum_Fields = {
  __typename?: 'Print_to_Collection_sum_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  print_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Sum_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** update columns of table "Print_to_Collection" */
export enum Print_To_Collection_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Order = 'order',
  /** column name */
  PrintId = 'print_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Print_To_Collection_Var_Pop_Fields = {
  __typename?: 'Print_to_Collection_var_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Var_Pop_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Print_To_Collection_Var_Samp_Fields = {
  __typename?: 'Print_to_Collection_var_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Var_Samp_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Print_To_Collection_Variance_Fields = {
  __typename?: 'Print_to_Collection_variance_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  print_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Print_to_Collection" */
export type Print_To_Collection_Variance_Order_By = {
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  print_id?: Maybe<Order_By>;
};

/** update columns of table "Print" */
export enum Print_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EditionByTypeId = 'edition_by_type_id',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  IsTransactionLocked = 'is_transaction_locked',
  /** column name */
  Price = 'price',
  /** column name */
  PrintNumber = 'print_number',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Print_Var_Pop_Fields = {
  __typename?: 'Print_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Print" */
export type Print_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Print_Var_Samp_Fields = {
  __typename?: 'Print_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Print" */
export type Print_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Print_Variance_Fields = {
  __typename?: 'Print_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  edition_by_type_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  print_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Print" */
export type Print_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  edition_by_type_id?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  print_number?: Maybe<Order_By>;
};

/** This table is used for Art show prizes */
export type Prize_Art_Show = {
  __typename?: 'Prize_Art_Show';
  /** An object relationship */
  File_metada_b?: Maybe<File_Metadata>;
  /** An object relationship */
  File_metadata_t?: Maybe<File_Metadata>;
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  description: Scalars['String'];
  external_url: Scalars['String'];
  first_place_artworks?: Maybe<Scalars['jsonb']>;
  id: Scalars['bigint'];
  internal_url: Scalars['String'];
  mentions?: Maybe<Scalars['jsonb']>;
  second_place_artworks?: Maybe<Scalars['jsonb']>;
  third_place_artworks?: Maybe<Scalars['jsonb']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** This table is used for Art show prizes */
export type Prize_Art_ShowFirst_Place_ArtworksArgs = {
  path?: Maybe<Scalars['String']>;
};


/** This table is used for Art show prizes */
export type Prize_Art_ShowMentionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** This table is used for Art show prizes */
export type Prize_Art_ShowSecond_Place_ArtworksArgs = {
  path?: Maybe<Scalars['String']>;
};


/** This table is used for Art show prizes */
export type Prize_Art_ShowThird_Place_ArtworksArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Prize_Art_Show" */
export type Prize_Art_Show_Aggregate = {
  __typename?: 'Prize_Art_Show_aggregate';
  aggregate?: Maybe<Prize_Art_Show_Aggregate_Fields>;
  nodes: Array<Prize_Art_Show>;
};

/** aggregate fields of "Prize_Art_Show" */
export type Prize_Art_Show_Aggregate_Fields = {
  __typename?: 'Prize_Art_Show_aggregate_fields';
  avg?: Maybe<Prize_Art_Show_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Prize_Art_Show_Max_Fields>;
  min?: Maybe<Prize_Art_Show_Min_Fields>;
  stddev?: Maybe<Prize_Art_Show_Stddev_Fields>;
  stddev_pop?: Maybe<Prize_Art_Show_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prize_Art_Show_Stddev_Samp_Fields>;
  sum?: Maybe<Prize_Art_Show_Sum_Fields>;
  var_pop?: Maybe<Prize_Art_Show_Var_Pop_Fields>;
  var_samp?: Maybe<Prize_Art_Show_Var_Samp_Fields>;
  variance?: Maybe<Prize_Art_Show_Variance_Fields>;
};


/** aggregate fields of "Prize_Art_Show" */
export type Prize_Art_Show_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Prize_Art_Show_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Prize_Art_Show_Append_Input = {
  first_place_artworks?: Maybe<Scalars['jsonb']>;
  mentions?: Maybe<Scalars['jsonb']>;
  second_place_artworks?: Maybe<Scalars['jsonb']>;
  third_place_artworks?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Prize_Art_Show_Avg_Fields = {
  __typename?: 'Prize_Art_Show_avg_fields';
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Prize_Art_Show". All fields are combined with a logical 'AND'. */
export type Prize_Art_Show_Bool_Exp = {
  File_metada_b?: Maybe<File_Metadata_Bool_Exp>;
  File_metadata_t?: Maybe<File_Metadata_Bool_Exp>;
  _and?: Maybe<Array<Prize_Art_Show_Bool_Exp>>;
  _not?: Maybe<Prize_Art_Show_Bool_Exp>;
  _or?: Maybe<Array<Prize_Art_Show_Bool_Exp>>;
  background_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamp_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  external_url?: Maybe<String_Comparison_Exp>;
  first_place_artworks?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  internal_url?: Maybe<String_Comparison_Exp>;
  mentions?: Maybe<Jsonb_Comparison_Exp>;
  second_place_artworks?: Maybe<Jsonb_Comparison_Exp>;
  third_place_artworks?: Maybe<Jsonb_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Prize_Art_Show" */
export enum Prize_Art_Show_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrizeArtShowPkey = 'Prize_Art_Show_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Prize_Art_Show_Delete_At_Path_Input = {
  first_place_artworks?: Maybe<Array<Scalars['String']>>;
  mentions?: Maybe<Array<Scalars['String']>>;
  second_place_artworks?: Maybe<Array<Scalars['String']>>;
  third_place_artworks?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Prize_Art_Show_Delete_Elem_Input = {
  first_place_artworks?: Maybe<Scalars['Int']>;
  mentions?: Maybe<Scalars['Int']>;
  second_place_artworks?: Maybe<Scalars['Int']>;
  third_place_artworks?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Prize_Art_Show_Delete_Key_Input = {
  first_place_artworks?: Maybe<Scalars['String']>;
  mentions?: Maybe<Scalars['String']>;
  second_place_artworks?: Maybe<Scalars['String']>;
  third_place_artworks?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Prize_Art_Show" */
export type Prize_Art_Show_Inc_Input = {
  background_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Prize_Art_Show" */
export type Prize_Art_Show_Insert_Input = {
  File_metada_b?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  File_metadata_t?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  first_place_artworks?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  mentions?: Maybe<Scalars['jsonb']>;
  second_place_artworks?: Maybe<Scalars['jsonb']>;
  third_place_artworks?: Maybe<Scalars['jsonb']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Prize_Art_Show_Max_Fields = {
  __typename?: 'Prize_Art_Show_max_fields';
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Prize_Art_Show_Min_Fields = {
  __typename?: 'Prize_Art_Show_min_fields';
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Prize_Art_Show" */
export type Prize_Art_Show_Mutation_Response = {
  __typename?: 'Prize_Art_Show_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Prize_Art_Show>;
};

/** on_conflict condition type for table "Prize_Art_Show" */
export type Prize_Art_Show_On_Conflict = {
  constraint: Prize_Art_Show_Constraint;
  update_columns?: Array<Prize_Art_Show_Update_Column>;
  where?: Maybe<Prize_Art_Show_Bool_Exp>;
};

/** Ordering options when selecting data from "Prize_Art_Show". */
export type Prize_Art_Show_Order_By = {
  File_metada_b?: Maybe<File_Metadata_Order_By>;
  File_metadata_t?: Maybe<File_Metadata_Order_By>;
  background_metadata_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  external_url?: Maybe<Order_By>;
  first_place_artworks?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_url?: Maybe<Order_By>;
  mentions?: Maybe<Order_By>;
  second_place_artworks?: Maybe<Order_By>;
  third_place_artworks?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Prize_Art_Show */
export type Prize_Art_Show_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Prize_Art_Show_Prepend_Input = {
  first_place_artworks?: Maybe<Scalars['jsonb']>;
  mentions?: Maybe<Scalars['jsonb']>;
  second_place_artworks?: Maybe<Scalars['jsonb']>;
  third_place_artworks?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Prize_Art_Show" */
export enum Prize_Art_Show_Select_Column {
  /** column name */
  BackgroundMetadataId = 'background_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalUrl = 'external_url',
  /** column name */
  FirstPlaceArtworks = 'first_place_artworks',
  /** column name */
  Id = 'id',
  /** column name */
  InternalUrl = 'internal_url',
  /** column name */
  Mentions = 'mentions',
  /** column name */
  SecondPlaceArtworks = 'second_place_artworks',
  /** column name */
  ThirdPlaceArtworks = 'third_place_artworks',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Prize_Art_Show" */
export type Prize_Art_Show_Set_Input = {
  background_metadata_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  first_place_artworks?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
  mentions?: Maybe<Scalars['jsonb']>;
  second_place_artworks?: Maybe<Scalars['jsonb']>;
  third_place_artworks?: Maybe<Scalars['jsonb']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Prize_Art_Show_Stddev_Fields = {
  __typename?: 'Prize_Art_Show_stddev_fields';
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Prize_Art_Show_Stddev_Pop_Fields = {
  __typename?: 'Prize_Art_Show_stddev_pop_fields';
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Prize_Art_Show_Stddev_Samp_Fields = {
  __typename?: 'Prize_Art_Show_stddev_samp_fields';
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Prize_Art_Show_Sum_Fields = {
  __typename?: 'Prize_Art_Show_sum_fields';
  background_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Prize_Art_Show" */
export enum Prize_Art_Show_Update_Column {
  /** column name */
  BackgroundMetadataId = 'background_metadata_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalUrl = 'external_url',
  /** column name */
  FirstPlaceArtworks = 'first_place_artworks',
  /** column name */
  Id = 'id',
  /** column name */
  InternalUrl = 'internal_url',
  /** column name */
  Mentions = 'mentions',
  /** column name */
  SecondPlaceArtworks = 'second_place_artworks',
  /** column name */
  ThirdPlaceArtworks = 'third_place_artworks',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Prize_Art_Show_Var_Pop_Fields = {
  __typename?: 'Prize_Art_Show_var_pop_fields';
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Prize_Art_Show_Var_Samp_Fields = {
  __typename?: 'Prize_Art_Show_var_samp_fields';
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Prize_Art_Show_Variance_Fields = {
  __typename?: 'Prize_Art_Show_variance_fields';
  background_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** General profile details of an account */
export type Profile = {
  __typename?: 'Profile';
  /** An object relationship */
  ENUM_profile_public_status?: Maybe<Enum_Profile_Public_Status>;
  /** An object relationship */
  ENUM_profile_status: Enum_Profile_Status;
  /** An array relationship */
  My_Website_Settings: Array<My_Website_Settings>;
  /** An aggregate relationship */
  My_Website_Settings_aggregate: My_Website_Settings_Aggregate;
  /** An array relationship */
  Profile_translations: Array<Profile_Translation>;
  /** An aggregate relationship */
  Profile_translations_aggregate: Profile_Translation_Aggregate;
  /** An array relationship */
  Valid_profile_questions_and_answers_view: Array<Valid_Profile_Questions_And_Answers_View>;
  /** An aggregate relationship */
  Valid_profile_questions_and_answers_view_aggregate: Valid_Profile_Questions_And_Answers_View_Aggregate;
  /** An array relationship */
  Valid_profile_statement_audio_syntheses: Array<Valid_Profile_Statement_Audio_Syntheses_View>;
  /** An aggregate relationship */
  Valid_profile_statement_audio_syntheses_aggregate: Valid_Profile_Statement_Audio_Syntheses_View_Aggregate;
  ai_short_statement?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['bigint']>;
  audio_download_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  audio_metadata?: Maybe<File_Metadata>;
  avatar?: Maybe<Scalars['bigint']>;
  avatar_download_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  avatar_metadata?: Maybe<File_Metadata>;
  banner?: Maybe<Scalars['bigint']>;
  banner_download_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  banner_metadata?: Maybe<File_Metadata>;
  /** An object relationship */
  context?: Maybe<Context>;
  created_at: Scalars['timestamptz'];
  cv?: Maybe<Scalars['bigint']>;
  cv_download_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  cv_metadata?: Maybe<File_Metadata>;
  description: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  featured_art?: Maybe<Scalars['bigint']>;
  featured_art_download_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  featured_art_metadata?: Maybe<File_Metadata>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  has_interests_notification_scheduled_event: Scalars['Boolean'];
  has_used_card: Scalars['Boolean'];
  id: Scalars['bigint'];
  is_public?: Maybe<Enum_Profile_Public_Status_Enum>;
  kard_banner?: Maybe<Scalars['bigint']>;
  kard_banner_download_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  kard_banner_metadata?: Maybe<File_Metadata>;
  last_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  /** An object relationship */
  profile_appearance_settings?: Maybe<Profile_Appearance_Settings>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  profile_ghost_tokens: Array<Profile_Ghost_Tokens>;
  /** An aggregate relationship */
  profile_ghost_tokens_aggregate: Profile_Ghost_Tokens_Aggregate;
  profile_theme: Enum_Profile_Theme_Enum;
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  status: Enum_Profile_Status_Enum;
  temp_audioId_?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  total_points?: Maybe<Scalars['numeric']>;
  type?: Maybe<Enum_Profile_Type_Enum>;
  updated_at: Scalars['timestamptz'];
};


/** General profile details of an account */
export type ProfileMy_Website_SettingsArgs = {
  distinct_on?: Maybe<Array<My_Website_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Website_Settings_Order_By>>;
  where?: Maybe<My_Website_Settings_Bool_Exp>;
};


/** General profile details of an account */
export type ProfileMy_Website_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<My_Website_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Website_Settings_Order_By>>;
  where?: Maybe<My_Website_Settings_Bool_Exp>;
};


/** General profile details of an account */
export type ProfileProfile_TranslationsArgs = {
  distinct_on?: Maybe<Array<Profile_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Translation_Order_By>>;
  where?: Maybe<Profile_Translation_Bool_Exp>;
};


/** General profile details of an account */
export type ProfileProfile_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Translation_Order_By>>;
  where?: Maybe<Profile_Translation_Bool_Exp>;
};


/** General profile details of an account */
export type ProfileValid_Profile_Questions_And_Answers_ViewArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Order_By>>;
  where?: Maybe<Valid_Profile_Questions_And_Answers_View_Bool_Exp>;
};


/** General profile details of an account */
export type ProfileValid_Profile_Questions_And_Answers_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Order_By>>;
  where?: Maybe<Valid_Profile_Questions_And_Answers_View_Bool_Exp>;
};


/** General profile details of an account */
export type ProfileValid_Profile_Statement_Audio_SynthesesArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Order_By>>;
  where?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>;
};


/** General profile details of an account */
export type ProfileValid_Profile_Statement_Audio_Syntheses_AggregateArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Order_By>>;
  where?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>;
};


/** General profile details of an account */
export type ProfileProfile_Ghost_TokensArgs = {
  distinct_on?: Maybe<Array<Profile_Ghost_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Ghost_Tokens_Order_By>>;
  where?: Maybe<Profile_Ghost_Tokens_Bool_Exp>;
};


/** General profile details of an account */
export type ProfileProfile_Ghost_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Ghost_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Ghost_Tokens_Order_By>>;
  where?: Maybe<Profile_Ghost_Tokens_Bool_Exp>;
};

export type ProfileCreationResultType = {
  __typename?: 'ProfileCreationResultType';
  context_id?: Maybe<Scalars['Int']>;
  profile_id?: Maybe<Scalars['Int']>;
  profile_settings_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "Profile_Interests" */
export type Profile_Interests = {
  __typename?: 'Profile_Interests';
  /** An array relationship */
  access: Array<Flat_Context_Access>;
  /** An aggregate relationship */
  access_aggregate: Flat_Context_Access_Aggregate;
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  exploration_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  /** An object relationship */
  interest: Interests;
  interest_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Profile_Interests" */
export type Profile_InterestsAccessArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


/** columns and relationships of "Profile_Interests" */
export type Profile_InterestsAccess_AggregateArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};

/** aggregated selection of "Profile_Interests" */
export type Profile_Interests_Aggregate = {
  __typename?: 'Profile_Interests_aggregate';
  aggregate?: Maybe<Profile_Interests_Aggregate_Fields>;
  nodes: Array<Profile_Interests>;
};

/** aggregate fields of "Profile_Interests" */
export type Profile_Interests_Aggregate_Fields = {
  __typename?: 'Profile_Interests_aggregate_fields';
  avg?: Maybe<Profile_Interests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Interests_Max_Fields>;
  min?: Maybe<Profile_Interests_Min_Fields>;
  stddev?: Maybe<Profile_Interests_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Interests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Interests_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Interests_Sum_Fields>;
  var_pop?: Maybe<Profile_Interests_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Interests_Var_Samp_Fields>;
  variance?: Maybe<Profile_Interests_Variance_Fields>;
};


/** aggregate fields of "Profile_Interests" */
export type Profile_Interests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Interests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_Interests" */
export type Profile_Interests_Aggregate_Order_By = {
  avg?: Maybe<Profile_Interests_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Interests_Max_Order_By>;
  min?: Maybe<Profile_Interests_Min_Order_By>;
  stddev?: Maybe<Profile_Interests_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Interests_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Interests_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Interests_Sum_Order_By>;
  var_pop?: Maybe<Profile_Interests_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Interests_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Interests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Profile_Interests" */
export type Profile_Interests_Arr_Rel_Insert_Input = {
  data: Array<Profile_Interests_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Interests_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Interests_Avg_Fields = {
  __typename?: 'Profile_Interests_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_Interests" */
export type Profile_Interests_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_Interests". All fields are combined with a logical 'AND'. */
export type Profile_Interests_Bool_Exp = {
  _and?: Maybe<Array<Profile_Interests_Bool_Exp>>;
  _not?: Maybe<Profile_Interests_Bool_Exp>;
  _or?: Maybe<Array<Profile_Interests_Bool_Exp>>;
  access?: Maybe<Flat_Context_Access_Bool_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  exploration_notification_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  interest?: Maybe<Interests_Bool_Exp>;
  interest_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_Interests" */
export enum Profile_Interests_Constraint {
  /** unique or primary key constraint on columns "interest_id", "context_id" */
  ProfileInterestsContextIdInterestIdKey1 = 'Profile_Interests_context_id_interest_id_key1',
  /** unique or primary key constraint on columns "id" */
  ProfileInterestsPkey1 = 'Profile_Interests_pkey1'
}

/** input type for incrementing numeric columns in table "Profile_Interests" */
export type Profile_Interests_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_Interests" */
export type Profile_Interests_Insert_Input = {
  access?: Maybe<Flat_Context_Access_Arr_Rel_Insert_Input>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exploration_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  interest?: Maybe<Interests_Obj_Rel_Insert_Input>;
  interest_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Interests_Max_Fields = {
  __typename?: 'Profile_Interests_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exploration_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Profile_Interests" */
export type Profile_Interests_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  exploration_notification_sent_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Interests_Min_Fields = {
  __typename?: 'Profile_Interests_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exploration_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Profile_Interests" */
export type Profile_Interests_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  exploration_notification_sent_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_Interests" */
export type Profile_Interests_Mutation_Response = {
  __typename?: 'Profile_Interests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Interests>;
};

/** on_conflict condition type for table "Profile_Interests" */
export type Profile_Interests_On_Conflict = {
  constraint: Profile_Interests_Constraint;
  update_columns?: Array<Profile_Interests_Update_Column>;
  where?: Maybe<Profile_Interests_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_Interests". */
export type Profile_Interests_Order_By = {
  access_aggregate?: Maybe<Flat_Context_Access_Aggregate_Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  exploration_notification_sent_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest?: Maybe<Interests_Order_By>;
  interest_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_Interests */
export type Profile_Interests_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_Interests" */
export enum Profile_Interests_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExplorationNotificationSentAt = 'exploration_notification_sent_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterestId = 'interest_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_Interests" */
export type Profile_Interests_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exploration_notification_sent_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Interests_Stddev_Fields = {
  __typename?: 'Profile_Interests_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_Interests" */
export type Profile_Interests_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Interests_Stddev_Pop_Fields = {
  __typename?: 'Profile_Interests_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_Interests" */
export type Profile_Interests_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Interests_Stddev_Samp_Fields = {
  __typename?: 'Profile_Interests_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_Interests" */
export type Profile_Interests_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Interests_Sum_Fields = {
  __typename?: 'Profile_Interests_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Profile_Interests" */
export type Profile_Interests_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** update columns of table "Profile_Interests" */
export enum Profile_Interests_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExplorationNotificationSentAt = 'exploration_notification_sent_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterestId = 'interest_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Interests_Var_Pop_Fields = {
  __typename?: 'Profile_Interests_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_Interests" */
export type Profile_Interests_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Interests_Var_Samp_Fields = {
  __typename?: 'Profile_Interests_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_Interests" */
export type Profile_Interests_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Interests_Variance_Fields = {
  __typename?: 'Profile_Interests_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_Interests" */
export type Profile_Interests_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
};

/** columns and relationships of "Profile_achievements" */
export type Profile_Achievements = {
  __typename?: 'Profile_achievements';
  /** An object relationship */
  Achievement?: Maybe<Achievements>;
  /** An object relationship */
  Context: Context;
  /** An object relationship */
  Profile: Profile;
  achievement_id?: Maybe<Scalars['Int']>;
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['bigint'];
  profile_context_id: Scalars['bigint'];
  profile_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "Profile_achievements" */
export type Profile_AchievementsDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Profile_achievements" */
export type Profile_Achievements_Aggregate = {
  __typename?: 'Profile_achievements_aggregate';
  aggregate?: Maybe<Profile_Achievements_Aggregate_Fields>;
  nodes: Array<Profile_Achievements>;
};

/** aggregate fields of "Profile_achievements" */
export type Profile_Achievements_Aggregate_Fields = {
  __typename?: 'Profile_achievements_aggregate_fields';
  avg?: Maybe<Profile_Achievements_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Achievements_Max_Fields>;
  min?: Maybe<Profile_Achievements_Min_Fields>;
  stddev?: Maybe<Profile_Achievements_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Achievements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Achievements_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Achievements_Sum_Fields>;
  var_pop?: Maybe<Profile_Achievements_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Achievements_Var_Samp_Fields>;
  variance?: Maybe<Profile_Achievements_Variance_Fields>;
};


/** aggregate fields of "Profile_achievements" */
export type Profile_Achievements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Achievements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_achievements" */
export type Profile_Achievements_Aggregate_Order_By = {
  avg?: Maybe<Profile_Achievements_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Achievements_Max_Order_By>;
  min?: Maybe<Profile_Achievements_Min_Order_By>;
  stddev?: Maybe<Profile_Achievements_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Achievements_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Achievements_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Achievements_Sum_Order_By>;
  var_pop?: Maybe<Profile_Achievements_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Achievements_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Achievements_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Profile_Achievements_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Profile_achievements" */
export type Profile_Achievements_Arr_Rel_Insert_Input = {
  data: Array<Profile_Achievements_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Achievements_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Achievements_Avg_Fields = {
  __typename?: 'Profile_achievements_avg_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_achievements" */
export type Profile_Achievements_Avg_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_achievements". All fields are combined with a logical 'AND'. */
export type Profile_Achievements_Bool_Exp = {
  Achievement?: Maybe<Achievements_Bool_Exp>;
  Context?: Maybe<Context_Bool_Exp>;
  Profile?: Maybe<Profile_Bool_Exp>;
  _and?: Maybe<Array<Profile_Achievements_Bool_Exp>>;
  _not?: Maybe<Profile_Achievements_Bool_Exp>;
  _or?: Maybe<Array<Profile_Achievements_Bool_Exp>>;
  achievement_id?: Maybe<Int_Comparison_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  profile_context_id?: Maybe<Bigint_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_achievements" */
export enum Profile_Achievements_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileAchievementsPkey = 'Profile_achievements_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Profile_Achievements_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Profile_Achievements_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Profile_Achievements_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Profile_achievements" */
export type Profile_Achievements_Inc_Input = {
  achievement_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_achievements" */
export type Profile_Achievements_Insert_Input = {
  Achievement?: Maybe<Achievements_Obj_Rel_Insert_Input>;
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  Profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  achievement_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Achievements_Max_Fields = {
  __typename?: 'Profile_achievements_max_fields';
  achievement_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Profile_achievements" */
export type Profile_Achievements_Max_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Achievements_Min_Fields = {
  __typename?: 'Profile_achievements_min_fields';
  achievement_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Profile_achievements" */
export type Profile_Achievements_Min_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_achievements" */
export type Profile_Achievements_Mutation_Response = {
  __typename?: 'Profile_achievements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Achievements>;
};

/** on_conflict condition type for table "Profile_achievements" */
export type Profile_Achievements_On_Conflict = {
  constraint: Profile_Achievements_Constraint;
  update_columns?: Array<Profile_Achievements_Update_Column>;
  where?: Maybe<Profile_Achievements_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_achievements". */
export type Profile_Achievements_Order_By = {
  Achievement?: Maybe<Achievements_Order_By>;
  Context?: Maybe<Context_Order_By>;
  Profile?: Maybe<Profile_Order_By>;
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_achievements */
export type Profile_Achievements_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Profile_Achievements_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Profile_achievements" */
export enum Profile_Achievements_Select_Column {
  /** column name */
  AchievementId = 'achievement_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_achievements" */
export type Profile_Achievements_Set_Input = {
  achievement_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Achievements_Stddev_Fields = {
  __typename?: 'Profile_achievements_stddev_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_achievements" */
export type Profile_Achievements_Stddev_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Achievements_Stddev_Pop_Fields = {
  __typename?: 'Profile_achievements_stddev_pop_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_achievements" */
export type Profile_Achievements_Stddev_Pop_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Achievements_Stddev_Samp_Fields = {
  __typename?: 'Profile_achievements_stddev_samp_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_achievements" */
export type Profile_Achievements_Stddev_Samp_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Achievements_Sum_Fields = {
  __typename?: 'Profile_achievements_sum_fields';
  achievement_id?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Profile_achievements" */
export type Profile_Achievements_Sum_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** update columns of table "Profile_achievements" */
export enum Profile_Achievements_Update_Column {
  /** column name */
  AchievementId = 'achievement_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Achievements_Var_Pop_Fields = {
  __typename?: 'Profile_achievements_var_pop_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_achievements" */
export type Profile_Achievements_Var_Pop_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Achievements_Var_Samp_Fields = {
  __typename?: 'Profile_achievements_var_samp_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_achievements" */
export type Profile_Achievements_Var_Samp_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Achievements_Variance_Fields = {
  __typename?: 'Profile_achievements_variance_fields';
  achievement_id?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_achievements" */
export type Profile_Achievements_Variance_Order_By = {
  achievement_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregated selection of "Profile" */
export type Profile_Aggregate = {
  __typename?: 'Profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

/** aggregate fields of "Profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'Profile_aggregate_fields';
  avg?: Maybe<Profile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
  stddev?: Maybe<Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Sum_Fields>;
  var_pop?: Maybe<Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Var_Samp_Fields>;
  variance?: Maybe<Profile_Variance_Fields>;
};


/** aggregate fields of "Profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile" */
export type Profile_Aggregate_Order_By = {
  avg?: Maybe<Profile_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Max_Order_By>;
  min?: Maybe<Profile_Min_Order_By>;
  stddev?: Maybe<Profile_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Sum_Order_By>;
  var_pop?: Maybe<Profile_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Variance_Order_By>;
};

/** columns and relationships of "Profile_appearance_settings" */
export type Profile_Appearance_Settings = {
  __typename?: 'Profile_appearance_settings';
  artwork_info: Scalars['String'];
  artwork_info_trigger: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  profile: Profile;
  profile_columns_count: Scalars['bigint'];
  profile_id: Scalars['bigint'];
  profile_layout_type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Profile_appearance_settings" */
export type Profile_Appearance_Settings_Aggregate = {
  __typename?: 'Profile_appearance_settings_aggregate';
  aggregate?: Maybe<Profile_Appearance_Settings_Aggregate_Fields>;
  nodes: Array<Profile_Appearance_Settings>;
};

/** aggregate fields of "Profile_appearance_settings" */
export type Profile_Appearance_Settings_Aggregate_Fields = {
  __typename?: 'Profile_appearance_settings_aggregate_fields';
  avg?: Maybe<Profile_Appearance_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Appearance_Settings_Max_Fields>;
  min?: Maybe<Profile_Appearance_Settings_Min_Fields>;
  stddev?: Maybe<Profile_Appearance_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Appearance_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Appearance_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Appearance_Settings_Sum_Fields>;
  var_pop?: Maybe<Profile_Appearance_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Appearance_Settings_Var_Samp_Fields>;
  variance?: Maybe<Profile_Appearance_Settings_Variance_Fields>;
};


/** aggregate fields of "Profile_appearance_settings" */
export type Profile_Appearance_Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Appearance_Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Appearance_Settings_Avg_Fields = {
  __typename?: 'Profile_appearance_settings_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profile_columns_count?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Profile_appearance_settings". All fields are combined with a logical 'AND'. */
export type Profile_Appearance_Settings_Bool_Exp = {
  _and?: Maybe<Array<Profile_Appearance_Settings_Bool_Exp>>;
  _not?: Maybe<Profile_Appearance_Settings_Bool_Exp>;
  _or?: Maybe<Array<Profile_Appearance_Settings_Bool_Exp>>;
  artwork_info?: Maybe<String_Comparison_Exp>;
  artwork_info_trigger?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profile_columns_count?: Maybe<Bigint_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  profile_layout_type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_appearance_settings" */
export enum Profile_Appearance_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileAppearanceSettingsPkey = 'Profile_appearance_settings_pkey',
  /** unique or primary key constraint on columns "profile_id" */
  ProfileAppearanceSettingsProfileIdKey = 'Profile_appearance_settings_profile_id_key'
}

/** input type for incrementing numeric columns in table "Profile_appearance_settings" */
export type Profile_Appearance_Settings_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  profile_columns_count?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_appearance_settings" */
export type Profile_Appearance_Settings_Insert_Input = {
  artwork_info?: Maybe<Scalars['String']>;
  artwork_info_trigger?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profile_columns_count?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_layout_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Appearance_Settings_Max_Fields = {
  __typename?: 'Profile_appearance_settings_max_fields';
  artwork_info?: Maybe<Scalars['String']>;
  artwork_info_trigger?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_columns_count?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_layout_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Profile_Appearance_Settings_Min_Fields = {
  __typename?: 'Profile_appearance_settings_min_fields';
  artwork_info?: Maybe<Scalars['String']>;
  artwork_info_trigger?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_columns_count?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_layout_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Profile_appearance_settings" */
export type Profile_Appearance_Settings_Mutation_Response = {
  __typename?: 'Profile_appearance_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Appearance_Settings>;
};

/** input type for inserting object relation for remote table "Profile_appearance_settings" */
export type Profile_Appearance_Settings_Obj_Rel_Insert_Input = {
  data: Profile_Appearance_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Appearance_Settings_On_Conflict>;
};

/** on_conflict condition type for table "Profile_appearance_settings" */
export type Profile_Appearance_Settings_On_Conflict = {
  constraint: Profile_Appearance_Settings_Constraint;
  update_columns?: Array<Profile_Appearance_Settings_Update_Column>;
  where?: Maybe<Profile_Appearance_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_appearance_settings". */
export type Profile_Appearance_Settings_Order_By = {
  artwork_info?: Maybe<Order_By>;
  artwork_info_trigger?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profile_columns_count?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  profile_layout_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_appearance_settings */
export type Profile_Appearance_Settings_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_appearance_settings" */
export enum Profile_Appearance_Settings_Select_Column {
  /** column name */
  ArtworkInfo = 'artwork_info',
  /** column name */
  ArtworkInfoTrigger = 'artwork_info_trigger',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileColumnsCount = 'profile_columns_count',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ProfileLayoutType = 'profile_layout_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_appearance_settings" */
export type Profile_Appearance_Settings_Set_Input = {
  artwork_info?: Maybe<Scalars['String']>;
  artwork_info_trigger?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_columns_count?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  profile_layout_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Appearance_Settings_Stddev_Fields = {
  __typename?: 'Profile_appearance_settings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profile_columns_count?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Appearance_Settings_Stddev_Pop_Fields = {
  __typename?: 'Profile_appearance_settings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_columns_count?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Appearance_Settings_Stddev_Samp_Fields = {
  __typename?: 'Profile_appearance_settings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_columns_count?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Profile_Appearance_Settings_Sum_Fields = {
  __typename?: 'Profile_appearance_settings_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  profile_columns_count?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Profile_appearance_settings" */
export enum Profile_Appearance_Settings_Update_Column {
  /** column name */
  ArtworkInfo = 'artwork_info',
  /** column name */
  ArtworkInfoTrigger = 'artwork_info_trigger',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileColumnsCount = 'profile_columns_count',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  ProfileLayoutType = 'profile_layout_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Appearance_Settings_Var_Pop_Fields = {
  __typename?: 'Profile_appearance_settings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_columns_count?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Appearance_Settings_Var_Samp_Fields = {
  __typename?: 'Profile_appearance_settings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_columns_count?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Appearance_Settings_Variance_Fields = {
  __typename?: 'Profile_appearance_settings_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profile_columns_count?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** input type for inserting array relation for remote table "Profile" */
export type Profile_Arr_Rel_Insert_Input = {
  data: Array<Profile_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Avg_Fields = {
  __typename?: 'Profile_avg_fields';
  audio?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  banner?: Maybe<Scalars['Float']>;
  cv?: Maybe<Scalars['Float']>;
  featured_art?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  temp_audioId_?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile" */
export type Profile_Avg_Order_By = {
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** Backgrounds set for a profile */
export type Profile_Background = {
  __typename?: 'Profile_background';
  background_type: Enum_Profile_Background_Type_Enum;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Profile_background" */
export type Profile_Background_Aggregate = {
  __typename?: 'Profile_background_aggregate';
  aggregate?: Maybe<Profile_Background_Aggregate_Fields>;
  nodes: Array<Profile_Background>;
};

/** aggregate fields of "Profile_background" */
export type Profile_Background_Aggregate_Fields = {
  __typename?: 'Profile_background_aggregate_fields';
  avg?: Maybe<Profile_Background_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Background_Max_Fields>;
  min?: Maybe<Profile_Background_Min_Fields>;
  stddev?: Maybe<Profile_Background_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Background_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Background_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Background_Sum_Fields>;
  var_pop?: Maybe<Profile_Background_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Background_Var_Samp_Fields>;
  variance?: Maybe<Profile_Background_Variance_Fields>;
};


/** aggregate fields of "Profile_background" */
export type Profile_Background_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Background_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Background_Avg_Fields = {
  __typename?: 'Profile_background_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Profile_background". All fields are combined with a logical 'AND'. */
export type Profile_Background_Bool_Exp = {
  _and?: Maybe<Array<Profile_Background_Bool_Exp>>;
  _not?: Maybe<Profile_Background_Bool_Exp>;
  _or?: Maybe<Array<Profile_Background_Bool_Exp>>;
  background_type?: Maybe<Enum_Profile_Background_Type_Enum_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_background" */
export enum Profile_Background_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileBackgroundPkey = 'Profile_background_pkey'
}

/** input type for incrementing numeric columns in table "Profile_background" */
export type Profile_Background_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_background" */
export type Profile_Background_Insert_Input = {
  background_type?: Maybe<Enum_Profile_Background_Type_Enum>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Background_Max_Fields = {
  __typename?: 'Profile_background_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Profile_Background_Min_Fields = {
  __typename?: 'Profile_background_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Profile_background" */
export type Profile_Background_Mutation_Response = {
  __typename?: 'Profile_background_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Background>;
};

/** on_conflict condition type for table "Profile_background" */
export type Profile_Background_On_Conflict = {
  constraint: Profile_Background_Constraint;
  update_columns?: Array<Profile_Background_Update_Column>;
  where?: Maybe<Profile_Background_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_background". */
export type Profile_Background_Order_By = {
  background_type?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_background */
export type Profile_Background_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_background" */
export enum Profile_Background_Select_Column {
  /** column name */
  BackgroundType = 'background_type',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_background" */
export type Profile_Background_Set_Input = {
  background_type?: Maybe<Enum_Profile_Background_Type_Enum>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Background_Stddev_Fields = {
  __typename?: 'Profile_background_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Background_Stddev_Pop_Fields = {
  __typename?: 'Profile_background_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Background_Stddev_Samp_Fields = {
  __typename?: 'Profile_background_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Profile_Background_Sum_Fields = {
  __typename?: 'Profile_background_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Profile_background" */
export enum Profile_Background_Update_Column {
  /** column name */
  BackgroundType = 'background_type',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Background_Var_Pop_Fields = {
  __typename?: 'Profile_background_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Background_Var_Samp_Fields = {
  __typename?: 'Profile_background_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Background_Variance_Fields = {
  __typename?: 'Profile_background_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  ENUM_profile_public_status?: Maybe<Enum_Profile_Public_Status_Bool_Exp>;
  ENUM_profile_status?: Maybe<Enum_Profile_Status_Bool_Exp>;
  My_Website_Settings?: Maybe<My_Website_Settings_Bool_Exp>;
  Profile_translations?: Maybe<Profile_Translation_Bool_Exp>;
  Valid_profile_questions_and_answers_view?: Maybe<Valid_Profile_Questions_And_Answers_View_Bool_Exp>;
  Valid_profile_statement_audio_syntheses?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>;
  _and?: Maybe<Array<Profile_Bool_Exp>>;
  _not?: Maybe<Profile_Bool_Exp>;
  _or?: Maybe<Array<Profile_Bool_Exp>>;
  ai_short_statement?: Maybe<String_Comparison_Exp>;
  audio?: Maybe<Bigint_Comparison_Exp>;
  audio_metadata?: Maybe<File_Metadata_Bool_Exp>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  avatar_metadata?: Maybe<File_Metadata_Bool_Exp>;
  banner?: Maybe<Bigint_Comparison_Exp>;
  banner_metadata?: Maybe<File_Metadata_Bool_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  cv?: Maybe<Bigint_Comparison_Exp>;
  cv_metadata?: Maybe<File_Metadata_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  featured_art?: Maybe<Bigint_Comparison_Exp>;
  featured_art_metadata?: Maybe<File_Metadata_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  gender?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  has_interests_notification_scheduled_event?: Maybe<Boolean_Comparison_Exp>;
  has_used_card?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_public?: Maybe<Enum_Profile_Public_Status_Enum_Comparison_Exp>;
  kard_banner?: Maybe<Bigint_Comparison_Exp>;
  kard_banner_metadata?: Maybe<File_Metadata_Bool_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum_Comparison_Exp>;
  profile_appearance_settings?: Maybe<Profile_Appearance_Settings_Bool_Exp>;
  profile_collaboration_code?: Maybe<String_Comparison_Exp>;
  profile_ghost_tokens?: Maybe<Profile_Ghost_Tokens_Bool_Exp>;
  profile_theme?: Maybe<Enum_Profile_Theme_Enum_Comparison_Exp>;
  quote?: Maybe<String_Comparison_Exp>;
  statement?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Enum_Profile_Status_Enum_Comparison_Exp>;
  temp_audioId_?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  total_points?: Maybe<Numeric_Comparison_Exp>;
  type?: Maybe<Enum_Profile_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile" */
export enum Profile_Constraint {
  /** unique or primary key constraint on columns "audio" */
  ProfileAudioKey = 'Profile_audio_key',
  /** unique or primary key constraint on columns "avatar" */
  ProfileAvatarKey = 'Profile_avatar_key',
  /** unique or primary key constraint on columns "banner" */
  ProfileBannerKey = 'Profile_banner_key',
  /** unique or primary key constraint on columns "featured_art" */
  ProfileFeaturedArtKey = 'Profile_featured_art_key',
  /** unique or primary key constraint on columns "handle" */
  ProfileHandleKey = 'Profile_handle_key',
  /** unique or primary key constraint on columns "id" */
  ProfilePkey = 'Profile_pkey',
  /** unique or primary key constraint on columns "profile_collaboration_code" */
  ProfileProfileCollaborationCodeKey = 'Profile_profile_collaboration_code_key'
}

/** Stores link tokens for the ghost profile auth */
export type Profile_Ghost_Tokens = {
  __typename?: 'Profile_ghost_tokens';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  is_validated: Scalars['Boolean'];
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['bigint'];
  token: Scalars['uuid'];
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Aggregate = {
  __typename?: 'Profile_ghost_tokens_aggregate';
  aggregate?: Maybe<Profile_Ghost_Tokens_Aggregate_Fields>;
  nodes: Array<Profile_Ghost_Tokens>;
};

/** aggregate fields of "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Aggregate_Fields = {
  __typename?: 'Profile_ghost_tokens_aggregate_fields';
  avg?: Maybe<Profile_Ghost_Tokens_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Ghost_Tokens_Max_Fields>;
  min?: Maybe<Profile_Ghost_Tokens_Min_Fields>;
  stddev?: Maybe<Profile_Ghost_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Ghost_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Ghost_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Ghost_Tokens_Sum_Fields>;
  var_pop?: Maybe<Profile_Ghost_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Ghost_Tokens_Var_Samp_Fields>;
  variance?: Maybe<Profile_Ghost_Tokens_Variance_Fields>;
};


/** aggregate fields of "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Ghost_Tokens_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Aggregate_Order_By = {
  avg?: Maybe<Profile_Ghost_Tokens_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Ghost_Tokens_Max_Order_By>;
  min?: Maybe<Profile_Ghost_Tokens_Min_Order_By>;
  stddev?: Maybe<Profile_Ghost_Tokens_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Ghost_Tokens_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Ghost_Tokens_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Ghost_Tokens_Sum_Order_By>;
  var_pop?: Maybe<Profile_Ghost_Tokens_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Ghost_Tokens_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Ghost_Tokens_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Profile_Ghost_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Ghost_Tokens_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Ghost_Tokens_Avg_Fields = {
  __typename?: 'Profile_ghost_tokens_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Avg_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_ghost_tokens". All fields are combined with a logical 'AND'. */
export type Profile_Ghost_Tokens_Bool_Exp = {
  _and?: Maybe<Array<Profile_Ghost_Tokens_Bool_Exp>>;
  _not?: Maybe<Profile_Ghost_Tokens_Bool_Exp>;
  _or?: Maybe<Array<Profile_Ghost_Tokens_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_validated?: Maybe<Boolean_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  token?: Maybe<Uuid_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_ghost_tokens" */
export enum Profile_Ghost_Tokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileGhostTokensPkey = 'Profile_ghost_tokens_pkey'
}

/** input type for incrementing numeric columns in table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_validated?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: Maybe<Scalars['bigint']>;
  token?: Maybe<Scalars['uuid']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Ghost_Tokens_Max_Fields = {
  __typename?: 'Profile_ghost_tokens_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  token?: Maybe<Scalars['uuid']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Ghost_Tokens_Min_Fields = {
  __typename?: 'Profile_ghost_tokens_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  token?: Maybe<Scalars['uuid']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Mutation_Response = {
  __typename?: 'Profile_ghost_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Ghost_Tokens>;
};

/** on_conflict condition type for table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_On_Conflict = {
  constraint: Profile_Ghost_Tokens_Constraint;
  update_columns?: Array<Profile_Ghost_Tokens_Update_Column>;
  where?: Maybe<Profile_Ghost_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_ghost_tokens". */
export type Profile_Ghost_Tokens_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_validated?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profile_id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_ghost_tokens */
export type Profile_Ghost_Tokens_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_ghost_tokens" */
export enum Profile_Ghost_Tokens_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsValidated = 'is_validated',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Token = 'token',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_validated?: Maybe<Scalars['Boolean']>;
  profile_id?: Maybe<Scalars['bigint']>;
  token?: Maybe<Scalars['uuid']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Ghost_Tokens_Stddev_Fields = {
  __typename?: 'Profile_ghost_tokens_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Ghost_Tokens_Stddev_Pop_Fields = {
  __typename?: 'Profile_ghost_tokens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Ghost_Tokens_Stddev_Samp_Fields = {
  __typename?: 'Profile_ghost_tokens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Ghost_Tokens_Sum_Fields = {
  __typename?: 'Profile_ghost_tokens_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Sum_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** update columns of table "Profile_ghost_tokens" */
export enum Profile_Ghost_Tokens_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsValidated = 'is_validated',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Token = 'token',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Ghost_Tokens_Var_Pop_Fields = {
  __typename?: 'Profile_ghost_tokens_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Ghost_Tokens_Var_Samp_Fields = {
  __typename?: 'Profile_ghost_tokens_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Ghost_Tokens_Variance_Fields = {
  __typename?: 'Profile_ghost_tokens_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_ghost_tokens" */
export type Profile_Ghost_Tokens_Variance_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "Profile" */
export type Profile_Inc_Input = {
  audio?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  banner?: Maybe<Scalars['bigint']>;
  cv?: Maybe<Scalars['bigint']>;
  featured_art?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  temp_audioId_?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Profile" */
export type Profile_Insert_Input = {
  ENUM_profile_public_status?: Maybe<Enum_Profile_Public_Status_Obj_Rel_Insert_Input>;
  ENUM_profile_status?: Maybe<Enum_Profile_Status_Obj_Rel_Insert_Input>;
  My_Website_Settings?: Maybe<My_Website_Settings_Arr_Rel_Insert_Input>;
  Profile_translations?: Maybe<Profile_Translation_Arr_Rel_Insert_Input>;
  Valid_profile_questions_and_answers_view?: Maybe<Valid_Profile_Questions_And_Answers_View_Arr_Rel_Insert_Input>;
  Valid_profile_statement_audio_syntheses?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Arr_Rel_Insert_Input>;
  ai_short_statement?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['bigint']>;
  audio_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  avatar?: Maybe<Scalars['bigint']>;
  avatar_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  banner?: Maybe<Scalars['bigint']>;
  banner_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv?: Maybe<Scalars['bigint']>;
  cv_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  featured_art?: Maybe<Scalars['bigint']>;
  featured_art_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  has_interests_notification_scheduled_event?: Maybe<Scalars['Boolean']>;
  has_used_card?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Enum_Profile_Public_Status_Enum>;
  kard_banner?: Maybe<Scalars['bigint']>;
  kard_banner_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  last_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  profile_appearance_settings?: Maybe<Profile_Appearance_Settings_Obj_Rel_Insert_Input>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  profile_ghost_tokens?: Maybe<Profile_Ghost_Tokens_Arr_Rel_Insert_Input>;
  profile_theme?: Maybe<Enum_Profile_Theme_Enum>;
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Profile_Status_Enum>;
  temp_audioId_?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  total_points?: Maybe<Scalars['numeric']>;
  type?: Maybe<Enum_Profile_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Keeps the record of users invited by existing users */
export type Profile_Invitations = {
  __typename?: 'Profile_invitations';
  /** An object relationship */
  context: Context;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An array relationship */
  invitation_on_artworks: Array<Collaboration_Invitation_On_Artwork>;
  /** An aggregate relationship */
  invitation_on_artworks_aggregate: Collaboration_Invitation_On_Artwork_Aggregate;
  /** An object relationship */
  invitee_context?: Maybe<Context>;
  invitee_context_id?: Maybe<Scalars['bigint']>;
  invitee_context_type?: Maybe<Scalars['String']>;
  invitee_email: Scalars['String'];
  invitee_first_name?: Maybe<Scalars['String']>;
  inviter_context_id: Scalars['bigint'];
  is_collaboration?: Maybe<Scalars['Boolean']>;
  language: Enum_Email_Language_Enum;
  started_sequence: Scalars['Boolean'];
  status: Enum_Profile_Invitation_Status_Enum;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  uuid_token: Scalars['uuid'];
};


/** Keeps the record of users invited by existing users */
export type Profile_InvitationsInvitation_On_ArtworksArgs = {
  distinct_on?: Maybe<Array<Collaboration_Invitation_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Invitation_On_Artwork_Order_By>>;
  where?: Maybe<Collaboration_Invitation_On_Artwork_Bool_Exp>;
};


/** Keeps the record of users invited by existing users */
export type Profile_InvitationsInvitation_On_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Collaboration_Invitation_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Invitation_On_Artwork_Order_By>>;
  where?: Maybe<Collaboration_Invitation_On_Artwork_Bool_Exp>;
};

/** aggregated selection of "Profile_invitations" */
export type Profile_Invitations_Aggregate = {
  __typename?: 'Profile_invitations_aggregate';
  aggregate?: Maybe<Profile_Invitations_Aggregate_Fields>;
  nodes: Array<Profile_Invitations>;
};

/** aggregate fields of "Profile_invitations" */
export type Profile_Invitations_Aggregate_Fields = {
  __typename?: 'Profile_invitations_aggregate_fields';
  avg?: Maybe<Profile_Invitations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Invitations_Max_Fields>;
  min?: Maybe<Profile_Invitations_Min_Fields>;
  stddev?: Maybe<Profile_Invitations_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Invitations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Invitations_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Invitations_Sum_Fields>;
  var_pop?: Maybe<Profile_Invitations_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Invitations_Var_Samp_Fields>;
  variance?: Maybe<Profile_Invitations_Variance_Fields>;
};


/** aggregate fields of "Profile_invitations" */
export type Profile_Invitations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Invitations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_invitations" */
export type Profile_Invitations_Aggregate_Order_By = {
  avg?: Maybe<Profile_Invitations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Invitations_Max_Order_By>;
  min?: Maybe<Profile_Invitations_Min_Order_By>;
  stddev?: Maybe<Profile_Invitations_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Invitations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Invitations_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Invitations_Sum_Order_By>;
  var_pop?: Maybe<Profile_Invitations_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Invitations_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Invitations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Profile_invitations" */
export type Profile_Invitations_Arr_Rel_Insert_Input = {
  data: Array<Profile_Invitations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Invitations_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Invitations_Avg_Fields = {
  __typename?: 'Profile_invitations_avg_fields';
  id?: Maybe<Scalars['Float']>;
  invitee_context_id?: Maybe<Scalars['Float']>;
  inviter_context_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_invitations" */
export type Profile_Invitations_Avg_Order_By = {
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_invitations". All fields are combined with a logical 'AND'. */
export type Profile_Invitations_Bool_Exp = {
  _and?: Maybe<Array<Profile_Invitations_Bool_Exp>>;
  _not?: Maybe<Profile_Invitations_Bool_Exp>;
  _or?: Maybe<Array<Profile_Invitations_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  invitation_on_artworks?: Maybe<Collaboration_Invitation_On_Artwork_Bool_Exp>;
  invitee_context?: Maybe<Context_Bool_Exp>;
  invitee_context_id?: Maybe<Bigint_Comparison_Exp>;
  invitee_context_type?: Maybe<String_Comparison_Exp>;
  invitee_email?: Maybe<String_Comparison_Exp>;
  invitee_first_name?: Maybe<String_Comparison_Exp>;
  inviter_context_id?: Maybe<Bigint_Comparison_Exp>;
  is_collaboration?: Maybe<Boolean_Comparison_Exp>;
  language?: Maybe<Enum_Email_Language_Enum_Comparison_Exp>;
  started_sequence?: Maybe<Boolean_Comparison_Exp>;
  status?: Maybe<Enum_Profile_Invitation_Status_Enum_Comparison_Exp>;
  status_at?: Maybe<Timestamptz_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uuid_token?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_invitations" */
export enum Profile_Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvitationsPkey = 'Invitations_pkey',
  /** unique or primary key constraint on columns "uuid_token" */
  ProfileInvitationsUuidTokenKey = 'Profile_invitations_uuid_token_key'
}

/** input type for incrementing numeric columns in table "Profile_invitations" */
export type Profile_Invitations_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  invitee_context_id?: Maybe<Scalars['bigint']>;
  inviter_context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_invitations" */
export type Profile_Invitations_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  invitation_on_artworks?: Maybe<Collaboration_Invitation_On_Artwork_Arr_Rel_Insert_Input>;
  invitee_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  invitee_context_id?: Maybe<Scalars['bigint']>;
  invitee_context_type?: Maybe<Scalars['String']>;
  invitee_email?: Maybe<Scalars['String']>;
  invitee_first_name?: Maybe<Scalars['String']>;
  inviter_context_id?: Maybe<Scalars['bigint']>;
  is_collaboration?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Enum_Email_Language_Enum>;
  started_sequence?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Enum_Profile_Invitation_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid_token?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Profile_Invitations_Max_Fields = {
  __typename?: 'Profile_invitations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  invitee_context_id?: Maybe<Scalars['bigint']>;
  invitee_context_type?: Maybe<Scalars['String']>;
  invitee_email?: Maybe<Scalars['String']>;
  invitee_first_name?: Maybe<Scalars['String']>;
  inviter_context_id?: Maybe<Scalars['bigint']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid_token?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "Profile_invitations" */
export type Profile_Invitations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  invitee_context_type?: Maybe<Order_By>;
  invitee_email?: Maybe<Order_By>;
  invitee_first_name?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid_token?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Invitations_Min_Fields = {
  __typename?: 'Profile_invitations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  invitee_context_id?: Maybe<Scalars['bigint']>;
  invitee_context_type?: Maybe<Scalars['String']>;
  invitee_email?: Maybe<Scalars['String']>;
  invitee_first_name?: Maybe<Scalars['String']>;
  inviter_context_id?: Maybe<Scalars['bigint']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid_token?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "Profile_invitations" */
export type Profile_Invitations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  invitee_context_type?: Maybe<Order_By>;
  invitee_email?: Maybe<Order_By>;
  invitee_first_name?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid_token?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_invitations" */
export type Profile_Invitations_Mutation_Response = {
  __typename?: 'Profile_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Invitations>;
};

/** input type for inserting object relation for remote table "Profile_invitations" */
export type Profile_Invitations_Obj_Rel_Insert_Input = {
  data: Profile_Invitations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Invitations_On_Conflict>;
};

/** on_conflict condition type for table "Profile_invitations" */
export type Profile_Invitations_On_Conflict = {
  constraint: Profile_Invitations_Constraint;
  update_columns?: Array<Profile_Invitations_Update_Column>;
  where?: Maybe<Profile_Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_invitations". */
export type Profile_Invitations_Order_By = {
  context?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invitation_on_artworks_aggregate?: Maybe<Collaboration_Invitation_On_Artwork_Aggregate_Order_By>;
  invitee_context?: Maybe<Context_Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  invitee_context_type?: Maybe<Order_By>;
  invitee_email?: Maybe<Order_By>;
  invitee_first_name?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
  is_collaboration?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  started_sequence?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uuid_token?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_invitations */
export type Profile_Invitations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_invitations" */
export enum Profile_Invitations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeContextId = 'invitee_context_id',
  /** column name */
  InviteeContextType = 'invitee_context_type',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  InviteeFirstName = 'invitee_first_name',
  /** column name */
  InviterContextId = 'inviter_context_id',
  /** column name */
  IsCollaboration = 'is_collaboration',
  /** column name */
  Language = 'language',
  /** column name */
  StartedSequence = 'started_sequence',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UuidToken = 'uuid_token'
}

/** input type for updating data in table "Profile_invitations" */
export type Profile_Invitations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  invitee_context_id?: Maybe<Scalars['bigint']>;
  invitee_context_type?: Maybe<Scalars['String']>;
  invitee_email?: Maybe<Scalars['String']>;
  invitee_first_name?: Maybe<Scalars['String']>;
  inviter_context_id?: Maybe<Scalars['bigint']>;
  is_collaboration?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Enum_Email_Language_Enum>;
  started_sequence?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Enum_Profile_Invitation_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uuid_token?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Profile_Invitations_Stddev_Fields = {
  __typename?: 'Profile_invitations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  invitee_context_id?: Maybe<Scalars['Float']>;
  inviter_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_invitations" */
export type Profile_Invitations_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Invitations_Stddev_Pop_Fields = {
  __typename?: 'Profile_invitations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  invitee_context_id?: Maybe<Scalars['Float']>;
  inviter_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_invitations" */
export type Profile_Invitations_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Invitations_Stddev_Samp_Fields = {
  __typename?: 'Profile_invitations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  invitee_context_id?: Maybe<Scalars['Float']>;
  inviter_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_invitations" */
export type Profile_Invitations_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Invitations_Sum_Fields = {
  __typename?: 'Profile_invitations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  invitee_context_id?: Maybe<Scalars['bigint']>;
  inviter_context_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Profile_invitations" */
export type Profile_Invitations_Sum_Order_By = {
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
};

/** update columns of table "Profile_invitations" */
export enum Profile_Invitations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeContextId = 'invitee_context_id',
  /** column name */
  InviteeContextType = 'invitee_context_type',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  InviteeFirstName = 'invitee_first_name',
  /** column name */
  InviterContextId = 'inviter_context_id',
  /** column name */
  IsCollaboration = 'is_collaboration',
  /** column name */
  Language = 'language',
  /** column name */
  StartedSequence = 'started_sequence',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UuidToken = 'uuid_token'
}

/** aggregate var_pop on columns */
export type Profile_Invitations_Var_Pop_Fields = {
  __typename?: 'Profile_invitations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  invitee_context_id?: Maybe<Scalars['Float']>;
  inviter_context_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_invitations" */
export type Profile_Invitations_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Invitations_Var_Samp_Fields = {
  __typename?: 'Profile_invitations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  invitee_context_id?: Maybe<Scalars['Float']>;
  inviter_context_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_invitations" */
export type Profile_Invitations_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Invitations_Variance_Fields = {
  __typename?: 'Profile_invitations_variance_fields';
  id?: Maybe<Scalars['Float']>;
  invitee_context_id?: Maybe<Scalars['Float']>;
  inviter_context_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_invitations" */
export type Profile_Invitations_Variance_Order_By = {
  id?: Maybe<Order_By>;
  invitee_context_id?: Maybe<Order_By>;
  inviter_context_id?: Maybe<Order_By>;
};

/** Holds info about the location of a gallery, artist or other accounts */
export type Profile_Location = {
  __typename?: 'Profile_location';
  /** An object relationship */
  WorldCity?: Maybe<Worldcities>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gps_lat?: Maybe<Scalars['float8']>;
  gps_long?: Maybe<Scalars['float8']>;
  id: Scalars['bigint'];
  location?: Maybe<Scalars['geography']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Enum_Profile_Region_Enum>;
  /** An array relationship */
  translations: Array<Profile_Location_Translation>;
  /** An aggregate relationship */
  translations_aggregate: Profile_Location_Translation_Aggregate;
  updated_at: Scalars['timestamptz'];
  zip_code?: Maybe<Scalars['String']>;
};


/** Holds info about the location of a gallery, artist or other accounts */
export type Profile_LocationTranslationsArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Translation_Order_By>>;
  where?: Maybe<Profile_Location_Translation_Bool_Exp>;
};


/** Holds info about the location of a gallery, artist or other accounts */
export type Profile_LocationTranslations_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Translation_Order_By>>;
  where?: Maybe<Profile_Location_Translation_Bool_Exp>;
};

/** aggregated selection of "Profile_location" */
export type Profile_Location_Aggregate = {
  __typename?: 'Profile_location_aggregate';
  aggregate?: Maybe<Profile_Location_Aggregate_Fields>;
  nodes: Array<Profile_Location>;
};

/** aggregate fields of "Profile_location" */
export type Profile_Location_Aggregate_Fields = {
  __typename?: 'Profile_location_aggregate_fields';
  avg?: Maybe<Profile_Location_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Location_Max_Fields>;
  min?: Maybe<Profile_Location_Min_Fields>;
  stddev?: Maybe<Profile_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Location_Sum_Fields>;
  var_pop?: Maybe<Profile_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Location_Var_Samp_Fields>;
  variance?: Maybe<Profile_Location_Variance_Fields>;
};


/** aggregate fields of "Profile_location" */
export type Profile_Location_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Location_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_location" */
export type Profile_Location_Aggregate_Order_By = {
  avg?: Maybe<Profile_Location_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Location_Max_Order_By>;
  min?: Maybe<Profile_Location_Min_Order_By>;
  stddev?: Maybe<Profile_Location_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Location_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Location_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Location_Sum_Order_By>;
  var_pop?: Maybe<Profile_Location_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Location_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Profile_location" */
export type Profile_Location_Arr_Rel_Insert_Input = {
  data: Array<Profile_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Location_Avg_Fields = {
  __typename?: 'Profile_location_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  gps_lat?: Maybe<Scalars['Float']>;
  gps_long?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_location" */
export type Profile_Location_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_location". All fields are combined with a logical 'AND'. */
export type Profile_Location_Bool_Exp = {
  WorldCity?: Maybe<Worldcities_Bool_Exp>;
  _and?: Maybe<Array<Profile_Location_Bool_Exp>>;
  _not?: Maybe<Profile_Location_Bool_Exp>;
  _or?: Maybe<Array<Profile_Location_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  city_id?: Maybe<String_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  gps_lat?: Maybe<Float8_Comparison_Exp>;
  gps_long?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  location?: Maybe<Geography_Comparison_Exp>;
  location_map_id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  region?: Maybe<Enum_Profile_Region_Enum_Comparison_Exp>;
  translations?: Maybe<Profile_Location_Translation_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_location" */
export enum Profile_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'Location_pkey',
  /** unique or primary key constraint on columns "location_map_id" */
  ProfileLocationLocationMapIdKey = 'Profile_location_location_map_id_key',
  /** unique or primary key constraint on columns "context_id" */
  PrimaryProfileLocationContext = 'primary_profile_location_context'
}

/** input type for incrementing numeric columns in table "Profile_location" */
export type Profile_Location_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  gps_lat?: Maybe<Scalars['float8']>;
  gps_long?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location_map_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_location" */
export type Profile_Location_Insert_Input = {
  WorldCity?: Maybe<Worldcities_Obj_Rel_Insert_Input>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gps_lat?: Maybe<Scalars['float8']>;
  gps_long?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<Scalars['geography']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Enum_Profile_Region_Enum>;
  translations?: Maybe<Profile_Location_Translation_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Profile_Location_Max_Fields = {
  __typename?: 'Profile_location_max_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gps_lat?: Maybe<Scalars['float8']>;
  gps_long?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Profile_location" */
export type Profile_Location_Max_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Location_Min_Fields = {
  __typename?: 'Profile_location_min_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gps_lat?: Maybe<Scalars['float8']>;
  gps_long?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Profile_location" */
export type Profile_Location_Min_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_location" */
export type Profile_Location_Mutation_Response = {
  __typename?: 'Profile_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Location>;
};

/** input type for inserting object relation for remote table "Profile_location" */
export type Profile_Location_Obj_Rel_Insert_Input = {
  data: Profile_Location_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Location_On_Conflict>;
};

/** on_conflict condition type for table "Profile_location" */
export type Profile_Location_On_Conflict = {
  constraint: Profile_Location_Constraint;
  update_columns?: Array<Profile_Location_Update_Column>;
  where?: Maybe<Profile_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_location". */
export type Profile_Location_Order_By = {
  WorldCity?: Maybe<Worldcities_Order_By>;
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  translations_aggregate?: Maybe<Profile_Location_Translation_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_location */
export type Profile_Location_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_location" */
export enum Profile_Location_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  CityId = 'city_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GpsLat = 'gps_lat',
  /** column name */
  GpsLong = 'gps_long',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LocationMapId = 'location_map_id',
  /** column name */
  Name = 'name',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "Profile_location" */
export type Profile_Location_Set_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  gps_lat?: Maybe<Scalars['float8']>;
  gps_long?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location?: Maybe<Scalars['geography']>;
  location_map_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Enum_Profile_Region_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Profile_Location_Stddev_Fields = {
  __typename?: 'Profile_location_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  gps_lat?: Maybe<Scalars['Float']>;
  gps_long?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_location" */
export type Profile_Location_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Location_Stddev_Pop_Fields = {
  __typename?: 'Profile_location_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  gps_lat?: Maybe<Scalars['Float']>;
  gps_long?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_location" */
export type Profile_Location_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Location_Stddev_Samp_Fields = {
  __typename?: 'Profile_location_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  gps_lat?: Maybe<Scalars['Float']>;
  gps_long?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_location" */
export type Profile_Location_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Location_Sum_Fields = {
  __typename?: 'Profile_location_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  gps_lat?: Maybe<Scalars['float8']>;
  gps_long?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  location_map_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Profile_location" */
export type Profile_Location_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
};

/** Associates translations to Location entries */
export type Profile_Location_Translation = {
  __typename?: 'Profile_location_translation';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  language: Languages;
  language_code: Scalars['String'];
  location_id: Scalars['bigint'];
  /** An object relationship */
  profile_location: Profile_Location;
  region?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Profile_location_translation" */
export type Profile_Location_Translation_Aggregate = {
  __typename?: 'Profile_location_translation_aggregate';
  aggregate?: Maybe<Profile_Location_Translation_Aggregate_Fields>;
  nodes: Array<Profile_Location_Translation>;
};

/** aggregate fields of "Profile_location_translation" */
export type Profile_Location_Translation_Aggregate_Fields = {
  __typename?: 'Profile_location_translation_aggregate_fields';
  avg?: Maybe<Profile_Location_Translation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Location_Translation_Max_Fields>;
  min?: Maybe<Profile_Location_Translation_Min_Fields>;
  stddev?: Maybe<Profile_Location_Translation_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Location_Translation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Location_Translation_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Location_Translation_Sum_Fields>;
  var_pop?: Maybe<Profile_Location_Translation_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Location_Translation_Var_Samp_Fields>;
  variance?: Maybe<Profile_Location_Translation_Variance_Fields>;
};


/** aggregate fields of "Profile_location_translation" */
export type Profile_Location_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Location_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_location_translation" */
export type Profile_Location_Translation_Aggregate_Order_By = {
  avg?: Maybe<Profile_Location_Translation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Location_Translation_Max_Order_By>;
  min?: Maybe<Profile_Location_Translation_Min_Order_By>;
  stddev?: Maybe<Profile_Location_Translation_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Location_Translation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Location_Translation_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Location_Translation_Sum_Order_By>;
  var_pop?: Maybe<Profile_Location_Translation_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Location_Translation_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Location_Translation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Profile_location_translation" */
export type Profile_Location_Translation_Arr_Rel_Insert_Input = {
  data: Array<Profile_Location_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Location_Translation_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Location_Translation_Avg_Fields = {
  __typename?: 'Profile_location_translation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Avg_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_location_translation". All fields are combined with a logical 'AND'. */
export type Profile_Location_Translation_Bool_Exp = {
  _and?: Maybe<Array<Profile_Location_Translation_Bool_Exp>>;
  _not?: Maybe<Profile_Location_Translation_Bool_Exp>;
  _or?: Maybe<Array<Profile_Location_Translation_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  language?: Maybe<Languages_Bool_Exp>;
  language_code?: Maybe<String_Comparison_Exp>;
  location_id?: Maybe<Bigint_Comparison_Exp>;
  profile_location?: Maybe<Profile_Location_Bool_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_location_translation" */
export enum Profile_Location_Translation_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationTranslationPkey = 'Location_translation_pkey'
}

/** input type for incrementing numeric columns in table "Profile_location_translation" */
export type Profile_Location_Translation_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_location_translation" */
export type Profile_Location_Translation_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Languages_Obj_Rel_Insert_Input>;
  language_code?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['bigint']>;
  profile_location?: Maybe<Profile_Location_Obj_Rel_Insert_Input>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Profile_Location_Translation_Max_Fields = {
  __typename?: 'Profile_location_translation_max_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Max_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Location_Translation_Min_Fields = {
  __typename?: 'Profile_location_translation_min_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Min_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_location_translation" */
export type Profile_Location_Translation_Mutation_Response = {
  __typename?: 'Profile_location_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Location_Translation>;
};

/** on_conflict condition type for table "Profile_location_translation" */
export type Profile_Location_Translation_On_Conflict = {
  constraint: Profile_Location_Translation_Constraint;
  update_columns?: Array<Profile_Location_Translation_Update_Column>;
  where?: Maybe<Profile_Location_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_location_translation". */
export type Profile_Location_Translation_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Languages_Order_By>;
  language_code?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  profile_location?: Maybe<Profile_Location_Order_By>;
  region?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_location_translation */
export type Profile_Location_Translation_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_location_translation" */
export enum Profile_Location_Translation_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "Profile_location_translation" */
export type Profile_Location_Translation_Set_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Profile_Location_Translation_Stddev_Fields = {
  __typename?: 'Profile_location_translation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Location_Translation_Stddev_Pop_Fields = {
  __typename?: 'Profile_location_translation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Location_Translation_Stddev_Samp_Fields = {
  __typename?: 'Profile_location_translation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Location_Translation_Sum_Fields = {
  __typename?: 'Profile_location_translation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  location_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Sum_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** update columns of table "Profile_location_translation" */
export enum Profile_Location_Translation_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** aggregate var_pop on columns */
export type Profile_Location_Translation_Var_Pop_Fields = {
  __typename?: 'Profile_location_translation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Location_Translation_Var_Samp_Fields = {
  __typename?: 'Profile_location_translation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Location_Translation_Variance_Fields = {
  __typename?: 'Profile_location_translation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  location_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_location_translation" */
export type Profile_Location_Translation_Variance_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** update columns of table "Profile_location" */
export enum Profile_Location_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  CityId = 'city_id',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GpsLat = 'gps_lat',
  /** column name */
  GpsLong = 'gps_long',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  LocationMapId = 'location_map_id',
  /** column name */
  Name = 'name',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** aggregate var_pop on columns */
export type Profile_Location_Var_Pop_Fields = {
  __typename?: 'Profile_location_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  gps_lat?: Maybe<Scalars['Float']>;
  gps_long?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_location" */
export type Profile_Location_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Location_Var_Samp_Fields = {
  __typename?: 'Profile_location_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  gps_lat?: Maybe<Scalars['Float']>;
  gps_long?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_location" */
export type Profile_Location_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Location_Variance_Fields = {
  __typename?: 'Profile_location_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  gps_lat?: Maybe<Scalars['Float']>;
  gps_long?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  location_map_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_location" */
export type Profile_Location_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  gps_lat?: Maybe<Order_By>;
  gps_long?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_map_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'Profile_max_fields';
  ai_short_statement?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  banner?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  featured_art?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  temp_audioId_?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  total_points?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Profile" */
export type Profile_Max_Order_By = {
  ai_short_statement?: Maybe<Order_By>;
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  profile_collaboration_code?: Maybe<Order_By>;
  quote?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'Profile_min_fields';
  ai_short_statement?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  banner?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  featured_art?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  temp_audioId_?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  total_points?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Profile" */
export type Profile_Min_Order_By = {
  ai_short_statement?: Maybe<Order_By>;
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  profile_collaboration_code?: Maybe<Order_By>;
  quote?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile" */
export type Profile_Mutation_Response = {
  __typename?: 'Profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile>;
};

/** input type for inserting object relation for remote table "Profile" */
export type Profile_Obj_Rel_Insert_Input = {
  data: Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Profile_On_Conflict>;
};

/** on_conflict condition type for table "Profile" */
export type Profile_On_Conflict = {
  constraint: Profile_Constraint;
  update_columns?: Array<Profile_Update_Column>;
  where?: Maybe<Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile". */
export type Profile_Order_By = {
  ENUM_profile_public_status?: Maybe<Enum_Profile_Public_Status_Order_By>;
  ENUM_profile_status?: Maybe<Enum_Profile_Status_Order_By>;
  My_Website_Settings_aggregate?: Maybe<My_Website_Settings_Aggregate_Order_By>;
  Profile_translations_aggregate?: Maybe<Profile_Translation_Aggregate_Order_By>;
  Valid_profile_questions_and_answers_view_aggregate?: Maybe<Valid_Profile_Questions_And_Answers_View_Aggregate_Order_By>;
  Valid_profile_statement_audio_syntheses_aggregate?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Aggregate_Order_By>;
  ai_short_statement?: Maybe<Order_By>;
  audio?: Maybe<Order_By>;
  audio_metadata?: Maybe<File_Metadata_Order_By>;
  avatar?: Maybe<Order_By>;
  avatar_metadata?: Maybe<File_Metadata_Order_By>;
  banner?: Maybe<Order_By>;
  banner_metadata?: Maybe<File_Metadata_Order_By>;
  context?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  cv_metadata?: Maybe<File_Metadata_Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  featured_art_metadata?: Maybe<File_Metadata_Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  has_interests_notification_scheduled_event?: Maybe<Order_By>;
  has_used_card?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  kard_banner_metadata?: Maybe<File_Metadata_Order_By>;
  last_name?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  profile_appearance_settings?: Maybe<Profile_Appearance_Settings_Order_By>;
  profile_collaboration_code?: Maybe<Order_By>;
  profile_ghost_tokens_aggregate?: Maybe<Profile_Ghost_Tokens_Aggregate_Order_By>;
  profile_theme?: Maybe<Order_By>;
  quote?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile */
export type Profile_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** Price range associated to a context  */
export type Profile_Price_Ranges = {
  __typename?: 'Profile_price_ranges';
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  price_range_id: Scalars['bigint'];
};

/** aggregated selection of "Profile_price_ranges" */
export type Profile_Price_Ranges_Aggregate = {
  __typename?: 'Profile_price_ranges_aggregate';
  aggregate?: Maybe<Profile_Price_Ranges_Aggregate_Fields>;
  nodes: Array<Profile_Price_Ranges>;
};

/** aggregate fields of "Profile_price_ranges" */
export type Profile_Price_Ranges_Aggregate_Fields = {
  __typename?: 'Profile_price_ranges_aggregate_fields';
  avg?: Maybe<Profile_Price_Ranges_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Price_Ranges_Max_Fields>;
  min?: Maybe<Profile_Price_Ranges_Min_Fields>;
  stddev?: Maybe<Profile_Price_Ranges_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Price_Ranges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Price_Ranges_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Price_Ranges_Sum_Fields>;
  var_pop?: Maybe<Profile_Price_Ranges_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Price_Ranges_Var_Samp_Fields>;
  variance?: Maybe<Profile_Price_Ranges_Variance_Fields>;
};


/** aggregate fields of "Profile_price_ranges" */
export type Profile_Price_Ranges_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Aggregate_Order_By = {
  avg?: Maybe<Profile_Price_Ranges_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Price_Ranges_Max_Order_By>;
  min?: Maybe<Profile_Price_Ranges_Min_Order_By>;
  stddev?: Maybe<Profile_Price_Ranges_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Price_Ranges_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Price_Ranges_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Price_Ranges_Sum_Order_By>;
  var_pop?: Maybe<Profile_Price_Ranges_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Price_Ranges_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Price_Ranges_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Profile_price_ranges" */
export type Profile_Price_Ranges_Arr_Rel_Insert_Input = {
  data: Array<Profile_Price_Ranges_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Price_Ranges_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Price_Ranges_Avg_Fields = {
  __typename?: 'Profile_price_ranges_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_range_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_price_ranges". All fields are combined with a logical 'AND'. */
export type Profile_Price_Ranges_Bool_Exp = {
  _and?: Maybe<Array<Profile_Price_Ranges_Bool_Exp>>;
  _not?: Maybe<Profile_Price_Ranges_Bool_Exp>;
  _or?: Maybe<Array<Profile_Price_Ranges_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  price_range_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_price_ranges" */
export enum Profile_Price_Ranges_Constraint {
  /** unique or primary key constraint on columns "context_id", "price_range_id" */
  ProfilePriceRangesContextIdPriceRangeIdKey = 'Profile_price_ranges_context_id_price_range_id_key',
  /** unique or primary key constraint on columns "id" */
  ProfilePriceRangesPkey = 'Profile_price_ranges_pkey'
}

/** input type for incrementing numeric columns in table "Profile_price_ranges" */
export type Profile_Price_Ranges_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price_range_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_price_ranges" */
export type Profile_Price_Ranges_Insert_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  price_range_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Profile_Price_Ranges_Max_Fields = {
  __typename?: 'Profile_price_ranges_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  price_range_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Price_Ranges_Min_Fields = {
  __typename?: 'Profile_price_ranges_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  price_range_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_price_ranges" */
export type Profile_Price_Ranges_Mutation_Response = {
  __typename?: 'Profile_price_ranges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Price_Ranges>;
};

/** on_conflict condition type for table "Profile_price_ranges" */
export type Profile_Price_Ranges_On_Conflict = {
  constraint: Profile_Price_Ranges_Constraint;
  update_columns?: Array<Profile_Price_Ranges_Update_Column>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_price_ranges". */
export type Profile_Price_Ranges_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_price_ranges */
export type Profile_Price_Ranges_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_price_ranges" */
export enum Profile_Price_Ranges_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PriceRangeId = 'price_range_id'
}

/** input type for updating data in table "Profile_price_ranges" */
export type Profile_Price_Ranges_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  price_range_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Profile_Price_Ranges_Stddev_Fields = {
  __typename?: 'Profile_price_ranges_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_range_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Price_Ranges_Stddev_Pop_Fields = {
  __typename?: 'Profile_price_ranges_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_range_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Price_Ranges_Stddev_Samp_Fields = {
  __typename?: 'Profile_price_ranges_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_range_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Price_Ranges_Sum_Fields = {
  __typename?: 'Profile_price_ranges_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  price_range_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** update columns of table "Profile_price_ranges" */
export enum Profile_Price_Ranges_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PriceRangeId = 'price_range_id'
}

/** aggregate var_pop on columns */
export type Profile_Price_Ranges_Var_Pop_Fields = {
  __typename?: 'Profile_price_ranges_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_range_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Price_Ranges_Var_Samp_Fields = {
  __typename?: 'Profile_price_ranges_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_range_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Price_Ranges_Variance_Fields = {
  __typename?: 'Profile_price_ranges_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price_range_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_price_ranges" */
export type Profile_Price_Ranges_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price_range_id?: Maybe<Order_By>;
};

/** Q&A for profiles */
export type Profile_Questions_And_Answers = {
  __typename?: 'Profile_questions_and_answers';
  /** An object relationship */
  Profile: Profile;
  /** An object relationship */
  Questionnaire_question: Questionnaire_Questions;
  answer?: Maybe<Scalars['String']>;
  created_at: Scalars['timetz'];
  deleted_at?: Maybe<Scalars['timetz']>;
  id: Scalars['bigint'];
  profile_id: Scalars['bigint'];
  question_id: Scalars['bigint'];
};

/** aggregated selection of "Profile_questions_and_answers" */
export type Profile_Questions_And_Answers_Aggregate = {
  __typename?: 'Profile_questions_and_answers_aggregate';
  aggregate?: Maybe<Profile_Questions_And_Answers_Aggregate_Fields>;
  nodes: Array<Profile_Questions_And_Answers>;
};

/** aggregate fields of "Profile_questions_and_answers" */
export type Profile_Questions_And_Answers_Aggregate_Fields = {
  __typename?: 'Profile_questions_and_answers_aggregate_fields';
  avg?: Maybe<Profile_Questions_And_Answers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Questions_And_Answers_Max_Fields>;
  min?: Maybe<Profile_Questions_And_Answers_Min_Fields>;
  stddev?: Maybe<Profile_Questions_And_Answers_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Questions_And_Answers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Questions_And_Answers_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Questions_And_Answers_Sum_Fields>;
  var_pop?: Maybe<Profile_Questions_And_Answers_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Questions_And_Answers_Var_Samp_Fields>;
  variance?: Maybe<Profile_Questions_And_Answers_Variance_Fields>;
};


/** aggregate fields of "Profile_questions_and_answers" */
export type Profile_Questions_And_Answers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Questions_And_Answers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Questions_And_Answers_Avg_Fields = {
  __typename?: 'Profile_questions_and_answers_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Profile_questions_and_answers". All fields are combined with a logical 'AND'. */
export type Profile_Questions_And_Answers_Bool_Exp = {
  Profile?: Maybe<Profile_Bool_Exp>;
  Questionnaire_question?: Maybe<Questionnaire_Questions_Bool_Exp>;
  _and?: Maybe<Array<Profile_Questions_And_Answers_Bool_Exp>>;
  _not?: Maybe<Profile_Questions_And_Answers_Bool_Exp>;
  _or?: Maybe<Array<Profile_Questions_And_Answers_Bool_Exp>>;
  answer?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timetz_Comparison_Exp>;
  deleted_at?: Maybe<Timetz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  question_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_questions_and_answers" */
export enum Profile_Questions_And_Answers_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileQuestionsAndAnswersPkey = 'Profile_questions_and_answers_pkey',
  /** unique or primary key constraint on columns "question_id", "profile_id" */
  ProfileQuestionsAndAnswersProfileIdQuestionIdKey = 'Profile_questions_and_answers_profile_id_question_id_key'
}

/** input type for incrementing numeric columns in table "Profile_questions_and_answers" */
export type Profile_Questions_And_Answers_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_questions_and_answers" */
export type Profile_Questions_And_Answers_Insert_Input = {
  Profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  Questionnaire_question?: Maybe<Questionnaire_Questions_Obj_Rel_Insert_Input>;
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timetz']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Profile_Questions_And_Answers_Max_Fields = {
  __typename?: 'Profile_questions_and_answers_max_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timetz']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Profile_Questions_And_Answers_Min_Fields = {
  __typename?: 'Profile_questions_and_answers_min_fields';
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timetz']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Profile_questions_and_answers" */
export type Profile_Questions_And_Answers_Mutation_Response = {
  __typename?: 'Profile_questions_and_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Questions_And_Answers>;
};

/** on_conflict condition type for table "Profile_questions_and_answers" */
export type Profile_Questions_And_Answers_On_Conflict = {
  constraint: Profile_Questions_And_Answers_Constraint;
  update_columns?: Array<Profile_Questions_And_Answers_Update_Column>;
  where?: Maybe<Profile_Questions_And_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_questions_and_answers". */
export type Profile_Questions_And_Answers_Order_By = {
  Profile?: Maybe<Profile_Order_By>;
  Questionnaire_question?: Maybe<Questionnaire_Questions_Order_By>;
  answer?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_questions_and_answers */
export type Profile_Questions_And_Answers_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_questions_and_answers" */
export enum Profile_Questions_And_Answers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  QuestionId = 'question_id'
}

/** input type for updating data in table "Profile_questions_and_answers" */
export type Profile_Questions_And_Answers_Set_Input = {
  answer?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timetz']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Profile_Questions_And_Answers_Stddev_Fields = {
  __typename?: 'Profile_questions_and_answers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Questions_And_Answers_Stddev_Pop_Fields = {
  __typename?: 'Profile_questions_and_answers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Questions_And_Answers_Stddev_Samp_Fields = {
  __typename?: 'Profile_questions_and_answers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Profile_Questions_And_Answers_Sum_Fields = {
  __typename?: 'Profile_questions_and_answers_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Profile_questions_and_answers" */
export enum Profile_Questions_And_Answers_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  QuestionId = 'question_id'
}

/** aggregate var_pop on columns */
export type Profile_Questions_And_Answers_Var_Pop_Fields = {
  __typename?: 'Profile_questions_and_answers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Questions_And_Answers_Var_Samp_Fields = {
  __typename?: 'Profile_questions_and_answers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Questions_And_Answers_Variance_Fields = {
  __typename?: 'Profile_questions_and_answers_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** select columns of table "Profile" */
export enum Profile_Select_Column {
  /** column name */
  AiShortStatement = 'ai_short_statement',
  /** column name */
  Audio = 'audio',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Banner = 'banner',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cv = 'cv',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FeaturedArt = 'featured_art',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Handle = 'handle',
  /** column name */
  HasInterestsNotificationScheduledEvent = 'has_interests_notification_scheduled_event',
  /** column name */
  HasUsedCard = 'has_used_card',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  KardBanner = 'kard_banner',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  ProfileCollaborationCode = 'profile_collaboration_code',
  /** column name */
  ProfileTheme = 'profile_theme',
  /** column name */
  Quote = 'quote',
  /** column name */
  Statement = 'statement',
  /** column name */
  Status = 'status',
  /** column name */
  TempAudioId = 'temp_audioId_',
  /** column name */
  Title = 'title',
  /** column name */
  TotalPoints = 'total_points',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile" */
export type Profile_Set_Input = {
  ai_short_statement?: Maybe<Scalars['String']>;
  audio?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  banner?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cv?: Maybe<Scalars['bigint']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  featured_art?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  has_interests_notification_scheduled_event?: Maybe<Scalars['Boolean']>;
  has_used_card?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_public?: Maybe<Enum_Profile_Public_Status_Enum>;
  kard_banner?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  profile_collaboration_code?: Maybe<Scalars['String']>;
  profile_theme?: Maybe<Enum_Profile_Theme_Enum>;
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Profile_Status_Enum>;
  temp_audioId_?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  total_points?: Maybe<Scalars['numeric']>;
  type?: Maybe<Enum_Profile_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** General account settings regarding localization  */
export type Profile_Settings = {
  __typename?: 'Profile_settings';
  /** An object relationship */
  context?: Maybe<Context>;
  created_at: Scalars['timestamptz'];
  crop_image?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  preferred_language: Enum_Language_Preference_Enum;
  time_format?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Profile_settings" */
export type Profile_Settings_Aggregate = {
  __typename?: 'Profile_settings_aggregate';
  aggregate?: Maybe<Profile_Settings_Aggregate_Fields>;
  nodes: Array<Profile_Settings>;
};

/** aggregate fields of "Profile_settings" */
export type Profile_Settings_Aggregate_Fields = {
  __typename?: 'Profile_settings_aggregate_fields';
  avg?: Maybe<Profile_Settings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Settings_Max_Fields>;
  min?: Maybe<Profile_Settings_Min_Fields>;
  stddev?: Maybe<Profile_Settings_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Settings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Settings_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Settings_Sum_Fields>;
  var_pop?: Maybe<Profile_Settings_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Settings_Var_Samp_Fields>;
  variance?: Maybe<Profile_Settings_Variance_Fields>;
};


/** aggregate fields of "Profile_settings" */
export type Profile_Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Settings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Settings_Avg_Fields = {
  __typename?: 'Profile_settings_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Profile_settings". All fields are combined with a logical 'AND'. */
export type Profile_Settings_Bool_Exp = {
  _and?: Maybe<Array<Profile_Settings_Bool_Exp>>;
  _not?: Maybe<Profile_Settings_Bool_Exp>;
  _or?: Maybe<Array<Profile_Settings_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  crop_image?: Maybe<Boolean_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum_Comparison_Exp>;
  time_format?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_settings" */
export enum Profile_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  SettingsPkey = 'Settings_pkey'
}

/** input type for incrementing numeric columns in table "Profile_settings" */
export type Profile_Settings_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_settings" */
export type Profile_Settings_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_image?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  time_format?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Settings_Max_Fields = {
  __typename?: 'Profile_settings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  time_format?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Profile_Settings_Min_Fields = {
  __typename?: 'Profile_settings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  time_format?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Profile_settings" */
export type Profile_Settings_Mutation_Response = {
  __typename?: 'Profile_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Settings>;
};

/** input type for inserting object relation for remote table "Profile_settings" */
export type Profile_Settings_Obj_Rel_Insert_Input = {
  data: Profile_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Settings_On_Conflict>;
};

/** on_conflict condition type for table "Profile_settings" */
export type Profile_Settings_On_Conflict = {
  constraint: Profile_Settings_Constraint;
  update_columns?: Array<Profile_Settings_Update_Column>;
  where?: Maybe<Profile_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_settings". */
export type Profile_Settings_Order_By = {
  context?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  crop_image?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  time_format?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_settings */
export type Profile_Settings_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_settings" */
export enum Profile_Settings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropImage = 'crop_image',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  TimeFormat = 'time_format',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_settings" */
export type Profile_Settings_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  crop_image?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  preferred_language?: Maybe<Enum_Language_Preference_Enum>;
  time_format?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Settings_Stddev_Fields = {
  __typename?: 'Profile_settings_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Settings_Stddev_Pop_Fields = {
  __typename?: 'Profile_settings_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Settings_Stddev_Samp_Fields = {
  __typename?: 'Profile_settings_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Profile_Settings_Sum_Fields = {
  __typename?: 'Profile_settings_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Profile_settings" */
export enum Profile_Settings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropImage = 'crop_image',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  TimeFormat = 'time_format',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Settings_Var_Pop_Fields = {
  __typename?: 'Profile_settings_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Settings_Var_Samp_Fields = {
  __typename?: 'Profile_settings_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Settings_Variance_Fields = {
  __typename?: 'Profile_settings_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Contact links for every account (Facebook page, Instagram page, Pinterest, site, etc.) */
export type Profile_Social_Links = {
  __typename?: 'Profile_social_links';
  /** An object relationship */
  ENUM_social_link_status: Enum_Social_Link_Status;
  /** An object relationship */
  ENUM_social_link_type: Enum_Social_Link_Type;
  URL: Scalars['String'];
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  custom_link: Scalars['Boolean'];
  id: Scalars['bigint'];
  is_pinned: Scalars['Boolean'];
  order: Scalars['Int'];
  platform: Scalars['String'];
  status: Enum_Social_Link_Status_Enum;
  type: Enum_Social_Link_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Profile_social_links" */
export type Profile_Social_Links_Aggregate = {
  __typename?: 'Profile_social_links_aggregate';
  aggregate?: Maybe<Profile_Social_Links_Aggregate_Fields>;
  nodes: Array<Profile_Social_Links>;
};

/** aggregate fields of "Profile_social_links" */
export type Profile_Social_Links_Aggregate_Fields = {
  __typename?: 'Profile_social_links_aggregate_fields';
  avg?: Maybe<Profile_Social_Links_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Social_Links_Max_Fields>;
  min?: Maybe<Profile_Social_Links_Min_Fields>;
  stddev?: Maybe<Profile_Social_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Social_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Social_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Social_Links_Sum_Fields>;
  var_pop?: Maybe<Profile_Social_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Social_Links_Var_Samp_Fields>;
  variance?: Maybe<Profile_Social_Links_Variance_Fields>;
};


/** aggregate fields of "Profile_social_links" */
export type Profile_Social_Links_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Social_Links_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_social_links" */
export type Profile_Social_Links_Aggregate_Order_By = {
  avg?: Maybe<Profile_Social_Links_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Social_Links_Max_Order_By>;
  min?: Maybe<Profile_Social_Links_Min_Order_By>;
  stddev?: Maybe<Profile_Social_Links_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Social_Links_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Social_Links_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Social_Links_Sum_Order_By>;
  var_pop?: Maybe<Profile_Social_Links_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Social_Links_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Social_Links_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Profile_social_links" */
export type Profile_Social_Links_Arr_Rel_Insert_Input = {
  data: Array<Profile_Social_Links_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Social_Links_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Social_Links_Avg_Fields = {
  __typename?: 'Profile_social_links_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_social_links". All fields are combined with a logical 'AND'. */
export type Profile_Social_Links_Bool_Exp = {
  ENUM_social_link_status?: Maybe<Enum_Social_Link_Status_Bool_Exp>;
  ENUM_social_link_type?: Maybe<Enum_Social_Link_Type_Bool_Exp>;
  URL?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Profile_Social_Links_Bool_Exp>>;
  _not?: Maybe<Profile_Social_Links_Bool_Exp>;
  _or?: Maybe<Array<Profile_Social_Links_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_link?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_pinned?: Maybe<Boolean_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  platform?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Enum_Social_Link_Status_Enum_Comparison_Exp>;
  type?: Maybe<Enum_Social_Link_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_social_links" */
export enum Profile_Social_Links_Constraint {
  /** unique or primary key constraint on columns "context_id", "status", "platform" */
  ProfileSocialLinksPlatformContextIdStatusIdx = 'Profile_social_links_platform_context_id_status_idx',
  /** unique or primary key constraint on columns "id" */
  SocialLinksPkey = 'Social_links_pkey'
}

/** input type for incrementing numeric columns in table "Profile_social_links" */
export type Profile_Social_Links_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Profile_social_links" */
export type Profile_Social_Links_Insert_Input = {
  ENUM_social_link_status?: Maybe<Enum_Social_Link_Status_Obj_Rel_Insert_Input>;
  ENUM_social_link_type?: Maybe<Enum_Social_Link_Type_Obj_Rel_Insert_Input>;
  URL?: Maybe<Scalars['String']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_link?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_pinned?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Social_Link_Status_Enum>;
  type?: Maybe<Enum_Social_Link_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Social_Links_Max_Fields = {
  __typename?: 'Profile_social_links_max_fields';
  URL?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Max_Order_By = {
  URL?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Social_Links_Min_Fields = {
  __typename?: 'Profile_social_links_min_fields';
  URL?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Min_Order_By = {
  URL?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_social_links" */
export type Profile_Social_Links_Mutation_Response = {
  __typename?: 'Profile_social_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Social_Links>;
};

/** on_conflict condition type for table "Profile_social_links" */
export type Profile_Social_Links_On_Conflict = {
  constraint: Profile_Social_Links_Constraint;
  update_columns?: Array<Profile_Social_Links_Update_Column>;
  where?: Maybe<Profile_Social_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_social_links". */
export type Profile_Social_Links_Order_By = {
  ENUM_social_link_status?: Maybe<Enum_Social_Link_Status_Order_By>;
  ENUM_social_link_type?: Maybe<Enum_Social_Link_Type_Order_By>;
  URL?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_link?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_pinned?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_social_links */
export type Profile_Social_Links_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_social_links" */
export enum Profile_Social_Links_Select_Column {
  /** column name */
  Url = 'URL',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomLink = 'custom_link',
  /** column name */
  Id = 'id',
  /** column name */
  IsPinned = 'is_pinned',
  /** column name */
  Order = 'order',
  /** column name */
  Platform = 'platform',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_social_links" */
export type Profile_Social_Links_Set_Input = {
  URL?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_link?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_pinned?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  platform?: Maybe<Scalars['String']>;
  status?: Maybe<Enum_Social_Link_Status_Enum>;
  type?: Maybe<Enum_Social_Link_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Social_Links_Stddev_Fields = {
  __typename?: 'Profile_social_links_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Social_Links_Stddev_Pop_Fields = {
  __typename?: 'Profile_social_links_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Social_Links_Stddev_Samp_Fields = {
  __typename?: 'Profile_social_links_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Social_Links_Sum_Fields = {
  __typename?: 'Profile_social_links_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** update columns of table "Profile_social_links" */
export enum Profile_Social_Links_Update_Column {
  /** column name */
  Url = 'URL',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomLink = 'custom_link',
  /** column name */
  Id = 'id',
  /** column name */
  IsPinned = 'is_pinned',
  /** column name */
  Order = 'order',
  /** column name */
  Platform = 'platform',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Social_Links_Var_Pop_Fields = {
  __typename?: 'Profile_social_links_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Social_Links_Var_Samp_Fields = {
  __typename?: 'Profile_social_links_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Social_Links_Variance_Fields = {
  __typename?: 'Profile_social_links_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_social_links" */
export type Profile_Social_Links_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Contains file_metadata id for audio generated based on the text for the profile id. Should not be able to add an entry if a PENDING or FINISHED, not deleted entry already exists for a profile_id, language combination */
export type Profile_Statement_Audio_Syntheses = {
  __typename?: 'Profile_statement_audio_syntheses';
  /** An object relationship */
  File_metadata?: Maybe<File_Metadata>;
  /** An object relationship */
  Profile: Profile;
  audio_generation_status: Enum_Audio_Generation_Status_Enum;
  audio_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timetz'];
  deleted_at?: Maybe<Scalars['timetz']>;
  fail_reason?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  is_enabled: Scalars['Boolean'];
  language: Enum_Language_Preference_Enum;
  profile_id: Scalars['bigint'];
  text: Scalars['String'];
  updated_at: Scalars['timetz'];
};

/** aggregated selection of "Profile_statement_audio_syntheses" */
export type Profile_Statement_Audio_Syntheses_Aggregate = {
  __typename?: 'Profile_statement_audio_syntheses_aggregate';
  aggregate?: Maybe<Profile_Statement_Audio_Syntheses_Aggregate_Fields>;
  nodes: Array<Profile_Statement_Audio_Syntheses>;
};

/** aggregate fields of "Profile_statement_audio_syntheses" */
export type Profile_Statement_Audio_Syntheses_Aggregate_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_aggregate_fields';
  avg?: Maybe<Profile_Statement_Audio_Syntheses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Statement_Audio_Syntheses_Max_Fields>;
  min?: Maybe<Profile_Statement_Audio_Syntheses_Min_Fields>;
  stddev?: Maybe<Profile_Statement_Audio_Syntheses_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Statement_Audio_Syntheses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Statement_Audio_Syntheses_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Statement_Audio_Syntheses_Sum_Fields>;
  var_pop?: Maybe<Profile_Statement_Audio_Syntheses_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Statement_Audio_Syntheses_Var_Samp_Fields>;
  variance?: Maybe<Profile_Statement_Audio_Syntheses_Variance_Fields>;
};


/** aggregate fields of "Profile_statement_audio_syntheses" */
export type Profile_Statement_Audio_Syntheses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Statement_Audio_Syntheses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Statement_Audio_Syntheses_Avg_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_avg_fields';
  audio_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Profile_statement_audio_syntheses". All fields are combined with a logical 'AND'. */
export type Profile_Statement_Audio_Syntheses_Bool_Exp = {
  File_metadata?: Maybe<File_Metadata_Bool_Exp>;
  Profile?: Maybe<Profile_Bool_Exp>;
  _and?: Maybe<Array<Profile_Statement_Audio_Syntheses_Bool_Exp>>;
  _not?: Maybe<Profile_Statement_Audio_Syntheses_Bool_Exp>;
  _or?: Maybe<Array<Profile_Statement_Audio_Syntheses_Bool_Exp>>;
  audio_generation_status?: Maybe<Enum_Audio_Generation_Status_Enum_Comparison_Exp>;
  audio_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timetz_Comparison_Exp>;
  deleted_at?: Maybe<Timetz_Comparison_Exp>;
  fail_reason?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_enabled?: Maybe<Boolean_Comparison_Exp>;
  language?: Maybe<Enum_Language_Preference_Enum_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timetz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_statement_audio_syntheses" */
export enum Profile_Statement_Audio_Syntheses_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileStatementAudioSynthesesPkey = 'Profile_statement_audio_syntheses_pkey',
  /** unique or primary key constraint on columns "profile_id", "language" */
  IdxUniqueValidAudioSyntheses = 'idx_unique_valid_audio_syntheses'
}

/** input type for incrementing numeric columns in table "Profile_statement_audio_syntheses" */
export type Profile_Statement_Audio_Syntheses_Inc_Input = {
  audio_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_statement_audio_syntheses" */
export type Profile_Statement_Audio_Syntheses_Insert_Input = {
  File_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  Profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  audio_generation_status?: Maybe<Enum_Audio_Generation_Status_Enum>;
  audio_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timetz']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  fail_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Enum_Language_Preference_Enum>;
  profile_id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timetz']>;
};

/** aggregate max on columns */
export type Profile_Statement_Audio_Syntheses_Max_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_max_fields';
  audio_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timetz']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  fail_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timetz']>;
};

/** aggregate min on columns */
export type Profile_Statement_Audio_Syntheses_Min_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_min_fields';
  audio_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timetz']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  fail_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timetz']>;
};

/** response of any mutation on the table "Profile_statement_audio_syntheses" */
export type Profile_Statement_Audio_Syntheses_Mutation_Response = {
  __typename?: 'Profile_statement_audio_syntheses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Statement_Audio_Syntheses>;
};

/** on_conflict condition type for table "Profile_statement_audio_syntheses" */
export type Profile_Statement_Audio_Syntheses_On_Conflict = {
  constraint: Profile_Statement_Audio_Syntheses_Constraint;
  update_columns?: Array<Profile_Statement_Audio_Syntheses_Update_Column>;
  where?: Maybe<Profile_Statement_Audio_Syntheses_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_statement_audio_syntheses". */
export type Profile_Statement_Audio_Syntheses_Order_By = {
  File_metadata?: Maybe<File_Metadata_Order_By>;
  Profile?: Maybe<Profile_Order_By>;
  audio_generation_status?: Maybe<Order_By>;
  audio_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  fail_reason?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_enabled?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_statement_audio_syntheses */
export type Profile_Statement_Audio_Syntheses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_statement_audio_syntheses" */
export enum Profile_Statement_Audio_Syntheses_Select_Column {
  /** column name */
  AudioGenerationStatus = 'audio_generation_status',
  /** column name */
  AudioId = 'audio_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FailReason = 'fail_reason',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Language = 'language',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_statement_audio_syntheses" */
export type Profile_Statement_Audio_Syntheses_Set_Input = {
  audio_generation_status?: Maybe<Enum_Audio_Generation_Status_Enum>;
  audio_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timetz']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  fail_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Enum_Language_Preference_Enum>;
  profile_id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timetz']>;
};

/** aggregate stddev on columns */
export type Profile_Statement_Audio_Syntheses_Stddev_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_stddev_fields';
  audio_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Statement_Audio_Syntheses_Stddev_Pop_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_stddev_pop_fields';
  audio_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Statement_Audio_Syntheses_Stddev_Samp_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_stddev_samp_fields';
  audio_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Profile_Statement_Audio_Syntheses_Sum_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_sum_fields';
  audio_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Profile_statement_audio_syntheses" */
export enum Profile_Statement_Audio_Syntheses_Update_Column {
  /** column name */
  AudioGenerationStatus = 'audio_generation_status',
  /** column name */
  AudioId = 'audio_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FailReason = 'fail_reason',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Language = 'language',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Statement_Audio_Syntheses_Var_Pop_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_var_pop_fields';
  audio_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Statement_Audio_Syntheses_Var_Samp_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_var_samp_fields';
  audio_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Statement_Audio_Syntheses_Variance_Fields = {
  __typename?: 'Profile_statement_audio_syntheses_variance_fields';
  audio_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Profile_Stddev_Fields = {
  __typename?: 'Profile_stddev_fields';
  audio?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  banner?: Maybe<Scalars['Float']>;
  cv?: Maybe<Scalars['Float']>;
  featured_art?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  temp_audioId_?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile" */
export type Profile_Stddev_Order_By = {
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Stddev_Pop_Fields = {
  __typename?: 'Profile_stddev_pop_fields';
  audio?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  banner?: Maybe<Scalars['Float']>;
  cv?: Maybe<Scalars['Float']>;
  featured_art?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  temp_audioId_?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile" */
export type Profile_Stddev_Pop_Order_By = {
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Stddev_Samp_Fields = {
  __typename?: 'Profile_stddev_samp_fields';
  audio?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  banner?: Maybe<Scalars['Float']>;
  cv?: Maybe<Scalars['Float']>;
  featured_art?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  temp_audioId_?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile" */
export type Profile_Stddev_Samp_Order_By = {
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Sum_Fields = {
  __typename?: 'Profile_sum_fields';
  audio?: Maybe<Scalars['bigint']>;
  avatar?: Maybe<Scalars['bigint']>;
  banner?: Maybe<Scalars['bigint']>;
  cv?: Maybe<Scalars['bigint']>;
  featured_art?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  kard_banner?: Maybe<Scalars['bigint']>;
  temp_audioId_?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "Profile" */
export type Profile_Sum_Order_By = {
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** Associates translation for each entry in Profile table */
export type Profile_Translation = {
  __typename?: 'Profile_translation';
  created_at: Scalars['timestamptz'];
  description_translation: Scalars['String'];
  first_name_translation?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An object relationship */
  language: Languages;
  language_code: Scalars['String'];
  last_name_translation?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile: Profile;
  profile_id: Scalars['bigint'];
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Profile_translation" */
export type Profile_Translation_Aggregate = {
  __typename?: 'Profile_translation_aggregate';
  aggregate?: Maybe<Profile_Translation_Aggregate_Fields>;
  nodes: Array<Profile_Translation>;
};

/** aggregate fields of "Profile_translation" */
export type Profile_Translation_Aggregate_Fields = {
  __typename?: 'Profile_translation_aggregate_fields';
  avg?: Maybe<Profile_Translation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Translation_Max_Fields>;
  min?: Maybe<Profile_Translation_Min_Fields>;
  stddev?: Maybe<Profile_Translation_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Translation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Translation_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Translation_Sum_Fields>;
  var_pop?: Maybe<Profile_Translation_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Translation_Var_Samp_Fields>;
  variance?: Maybe<Profile_Translation_Variance_Fields>;
};


/** aggregate fields of "Profile_translation" */
export type Profile_Translation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Translation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_translation" */
export type Profile_Translation_Aggregate_Order_By = {
  avg?: Maybe<Profile_Translation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Translation_Max_Order_By>;
  min?: Maybe<Profile_Translation_Min_Order_By>;
  stddev?: Maybe<Profile_Translation_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Translation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Translation_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Translation_Sum_Order_By>;
  var_pop?: Maybe<Profile_Translation_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Translation_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Translation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Profile_translation" */
export type Profile_Translation_Arr_Rel_Insert_Input = {
  data: Array<Profile_Translation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Translation_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Translation_Avg_Fields = {
  __typename?: 'Profile_translation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_translation" */
export type Profile_Translation_Avg_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_translation". All fields are combined with a logical 'AND'. */
export type Profile_Translation_Bool_Exp = {
  _and?: Maybe<Array<Profile_Translation_Bool_Exp>>;
  _not?: Maybe<Profile_Translation_Bool_Exp>;
  _or?: Maybe<Array<Profile_Translation_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description_translation?: Maybe<String_Comparison_Exp>;
  first_name_translation?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  language?: Maybe<Languages_Bool_Exp>;
  language_code?: Maybe<String_Comparison_Exp>;
  last_name_translation?: Maybe<String_Comparison_Exp>;
  profile?: Maybe<Profile_Bool_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  quote?: Maybe<String_Comparison_Exp>;
  statement?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_translation" */
export enum Profile_Translation_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileTranslationPkey = 'Profile_translation_pkey'
}

/** input type for incrementing numeric columns in table "Profile_translation" */
export type Profile_Translation_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_translation" */
export type Profile_Translation_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  first_name_translation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Languages_Obj_Rel_Insert_Input>;
  language_code?: Maybe<Scalars['String']>;
  last_name_translation?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile_Obj_Rel_Insert_Input>;
  profile_id?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Translation_Max_Fields = {
  __typename?: 'Profile_translation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  first_name_translation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  last_name_translation?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Profile_translation" */
export type Profile_Translation_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  first_name_translation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  last_name_translation?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  quote?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Translation_Min_Fields = {
  __typename?: 'Profile_translation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  first_name_translation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  last_name_translation?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Profile_translation" */
export type Profile_Translation_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  first_name_translation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language_code?: Maybe<Order_By>;
  last_name_translation?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  quote?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_translation" */
export type Profile_Translation_Mutation_Response = {
  __typename?: 'Profile_translation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Translation>;
};

/** on_conflict condition type for table "Profile_translation" */
export type Profile_Translation_On_Conflict = {
  constraint: Profile_Translation_Constraint;
  update_columns?: Array<Profile_Translation_Update_Column>;
  where?: Maybe<Profile_Translation_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_translation". */
export type Profile_Translation_Order_By = {
  created_at?: Maybe<Order_By>;
  description_translation?: Maybe<Order_By>;
  first_name_translation?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  language?: Maybe<Languages_Order_By>;
  language_code?: Maybe<Order_By>;
  last_name_translation?: Maybe<Order_By>;
  profile?: Maybe<Profile_Order_By>;
  profile_id?: Maybe<Order_By>;
  quote?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_translation */
export type Profile_Translation_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_translation" */
export enum Profile_Translation_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionTranslation = 'description_translation',
  /** column name */
  FirstNameTranslation = 'first_name_translation',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  LastNameTranslation = 'last_name_translation',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Quote = 'quote',
  /** column name */
  Statement = 'statement',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_translation" */
export type Profile_Translation_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description_translation?: Maybe<Scalars['String']>;
  first_name_translation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  language_code?: Maybe<Scalars['String']>;
  last_name_translation?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Translation_Stddev_Fields = {
  __typename?: 'Profile_translation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_translation" */
export type Profile_Translation_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Translation_Stddev_Pop_Fields = {
  __typename?: 'Profile_translation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_translation" */
export type Profile_Translation_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Translation_Stddev_Samp_Fields = {
  __typename?: 'Profile_translation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_translation" */
export type Profile_Translation_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Translation_Sum_Fields = {
  __typename?: 'Profile_translation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Profile_translation" */
export type Profile_Translation_Sum_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** update columns of table "Profile_translation" */
export enum Profile_Translation_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DescriptionTranslation = 'description_translation',
  /** column name */
  FirstNameTranslation = 'first_name_translation',
  /** column name */
  Id = 'id',
  /** column name */
  LanguageCode = 'language_code',
  /** column name */
  LastNameTranslation = 'last_name_translation',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Quote = 'quote',
  /** column name */
  Statement = 'statement',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Translation_Var_Pop_Fields = {
  __typename?: 'Profile_translation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_translation" */
export type Profile_Translation_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Translation_Var_Samp_Fields = {
  __typename?: 'Profile_translation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_translation" */
export type Profile_Translation_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Translation_Variance_Fields = {
  __typename?: 'Profile_translation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_translation" */
export type Profile_Translation_Variance_Order_By = {
  id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** update columns of table "Profile" */
export enum Profile_Update_Column {
  /** column name */
  AiShortStatement = 'ai_short_statement',
  /** column name */
  Audio = 'audio',
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Banner = 'banner',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cv = 'cv',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FeaturedArt = 'featured_art',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Handle = 'handle',
  /** column name */
  HasInterestsNotificationScheduledEvent = 'has_interests_notification_scheduled_event',
  /** column name */
  HasUsedCard = 'has_used_card',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  KardBanner = 'kard_banner',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  ProfileCollaborationCode = 'profile_collaboration_code',
  /** column name */
  ProfileTheme = 'profile_theme',
  /** column name */
  Quote = 'quote',
  /** column name */
  Statement = 'statement',
  /** column name */
  Status = 'status',
  /** column name */
  TempAudioId = 'temp_audioId_',
  /** column name */
  Title = 'title',
  /** column name */
  TotalPoints = 'total_points',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Var_Pop_Fields = {
  __typename?: 'Profile_var_pop_fields';
  audio?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  banner?: Maybe<Scalars['Float']>;
  cv?: Maybe<Scalars['Float']>;
  featured_art?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  temp_audioId_?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile" */
export type Profile_Var_Pop_Order_By = {
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Var_Samp_Fields = {
  __typename?: 'Profile_var_samp_fields';
  audio?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  banner?: Maybe<Scalars['Float']>;
  cv?: Maybe<Scalars['Float']>;
  featured_art?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  temp_audioId_?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile" */
export type Profile_Var_Samp_Order_By = {
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Variance_Fields = {
  __typename?: 'Profile_variance_fields';
  audio?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['Float']>;
  banner?: Maybe<Scalars['Float']>;
  cv?: Maybe<Scalars['Float']>;
  featured_art?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  kard_banner?: Maybe<Scalars['Float']>;
  temp_audioId_?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile" */
export type Profile_Variance_Order_By = {
  audio?: Maybe<Order_By>;
  avatar?: Maybe<Order_By>;
  banner?: Maybe<Order_By>;
  cv?: Maybe<Order_By>;
  featured_art?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  kard_banner?: Maybe<Order_By>;
  temp_audioId_?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
};

/** columns and relationships of "Profile_video_links" */
export type Profile_Video_Links = {
  __typename?: 'Profile_video_links';
  URL: Scalars['String'];
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  is_visible: Scalars['Boolean'];
  platform: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Profile_video_links" */
export type Profile_Video_Links_Aggregate = {
  __typename?: 'Profile_video_links_aggregate';
  aggregate?: Maybe<Profile_Video_Links_Aggregate_Fields>;
  nodes: Array<Profile_Video_Links>;
};

/** aggregate fields of "Profile_video_links" */
export type Profile_Video_Links_Aggregate_Fields = {
  __typename?: 'Profile_video_links_aggregate_fields';
  avg?: Maybe<Profile_Video_Links_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Video_Links_Max_Fields>;
  min?: Maybe<Profile_Video_Links_Min_Fields>;
  stddev?: Maybe<Profile_Video_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Video_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Video_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Video_Links_Sum_Fields>;
  var_pop?: Maybe<Profile_Video_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Video_Links_Var_Samp_Fields>;
  variance?: Maybe<Profile_Video_Links_Variance_Fields>;
};


/** aggregate fields of "Profile_video_links" */
export type Profile_Video_Links_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Video_Links_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Profile_video_links" */
export type Profile_Video_Links_Aggregate_Order_By = {
  avg?: Maybe<Profile_Video_Links_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Video_Links_Max_Order_By>;
  min?: Maybe<Profile_Video_Links_Min_Order_By>;
  stddev?: Maybe<Profile_Video_Links_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Video_Links_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Video_Links_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Video_Links_Sum_Order_By>;
  var_pop?: Maybe<Profile_Video_Links_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Video_Links_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Video_Links_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Profile_video_links" */
export type Profile_Video_Links_Arr_Rel_Insert_Input = {
  data: Array<Profile_Video_Links_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Video_Links_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Video_Links_Avg_Fields = {
  __typename?: 'Profile_video_links_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Profile_video_links". All fields are combined with a logical 'AND'. */
export type Profile_Video_Links_Bool_Exp = {
  URL?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Profile_Video_Links_Bool_Exp>>;
  _not?: Maybe<Profile_Video_Links_Bool_Exp>;
  _or?: Maybe<Array<Profile_Video_Links_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_visible?: Maybe<Boolean_Comparison_Exp>;
  platform?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_video_links" */
export enum Profile_Video_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProfileVideoLinksPkey = 'Profile_video_links_pkey'
}

/** input type for incrementing numeric columns in table "Profile_video_links" */
export type Profile_Video_Links_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_video_links" */
export type Profile_Video_Links_Insert_Input = {
  URL?: Maybe<Scalars['String']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Video_Links_Max_Fields = {
  __typename?: 'Profile_video_links_max_fields';
  URL?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Max_Order_By = {
  URL?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Video_Links_Min_Fields = {
  __typename?: 'Profile_video_links_min_fields';
  URL?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Min_Order_By = {
  URL?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Profile_video_links" */
export type Profile_Video_Links_Mutation_Response = {
  __typename?: 'Profile_video_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Video_Links>;
};

/** on_conflict condition type for table "Profile_video_links" */
export type Profile_Video_Links_On_Conflict = {
  constraint: Profile_Video_Links_Constraint;
  update_columns?: Array<Profile_Video_Links_Update_Column>;
  where?: Maybe<Profile_Video_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_video_links". */
export type Profile_Video_Links_Order_By = {
  URL?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_visible?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_video_links */
export type Profile_Video_Links_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Profile_video_links" */
export enum Profile_Video_Links_Select_Column {
  /** column name */
  Url = 'URL',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsVisible = 'is_visible',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Profile_video_links" */
export type Profile_Video_Links_Set_Input = {
  URL?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_visible?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Video_Links_Stddev_Fields = {
  __typename?: 'Profile_video_links_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Video_Links_Stddev_Pop_Fields = {
  __typename?: 'Profile_video_links_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Video_Links_Stddev_Samp_Fields = {
  __typename?: 'Profile_video_links_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Video_Links_Sum_Fields = {
  __typename?: 'Profile_video_links_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Profile_video_links" */
export enum Profile_Video_Links_Update_Column {
  /** column name */
  Url = 'URL',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsVisible = 'is_visible',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Video_Links_Var_Pop_Fields = {
  __typename?: 'Profile_video_links_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Video_Links_Var_Samp_Fields = {
  __typename?: 'Profile_video_links_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Video_Links_Variance_Fields = {
  __typename?: 'Profile_video_links_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Profile_video_links" */
export type Profile_Video_Links_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "Profile_wallet_address" */
export type Profile_Wallet_Address = {
  __typename?: 'Profile_wallet_address';
  profile_context_id: Scalars['bigint'];
  wallet_address: Scalars['String'];
};

/** aggregated selection of "Profile_wallet_address" */
export type Profile_Wallet_Address_Aggregate = {
  __typename?: 'Profile_wallet_address_aggregate';
  aggregate?: Maybe<Profile_Wallet_Address_Aggregate_Fields>;
  nodes: Array<Profile_Wallet_Address>;
};

/** aggregate fields of "Profile_wallet_address" */
export type Profile_Wallet_Address_Aggregate_Fields = {
  __typename?: 'Profile_wallet_address_aggregate_fields';
  avg?: Maybe<Profile_Wallet_Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Wallet_Address_Max_Fields>;
  min?: Maybe<Profile_Wallet_Address_Min_Fields>;
  stddev?: Maybe<Profile_Wallet_Address_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Wallet_Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Wallet_Address_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Wallet_Address_Sum_Fields>;
  var_pop?: Maybe<Profile_Wallet_Address_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Wallet_Address_Var_Samp_Fields>;
  variance?: Maybe<Profile_Wallet_Address_Variance_Fields>;
};


/** aggregate fields of "Profile_wallet_address" */
export type Profile_Wallet_Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Wallet_Address_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Wallet_Address_Avg_Fields = {
  __typename?: 'Profile_wallet_address_avg_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Profile_wallet_address". All fields are combined with a logical 'AND'. */
export type Profile_Wallet_Address_Bool_Exp = {
  _and?: Maybe<Array<Profile_Wallet_Address_Bool_Exp>>;
  _not?: Maybe<Profile_Wallet_Address_Bool_Exp>;
  _or?: Maybe<Array<Profile_Wallet_Address_Bool_Exp>>;
  profile_context_id?: Maybe<Bigint_Comparison_Exp>;
  wallet_address?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Profile_wallet_address" */
export enum Profile_Wallet_Address_Constraint {
  /** unique or primary key constraint on columns "profile_context_id" */
  ProfileWalletAddressPkey = 'Profile_wallet_address_pkey'
}

/** input type for incrementing numeric columns in table "Profile_wallet_address" */
export type Profile_Wallet_Address_Inc_Input = {
  profile_context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Profile_wallet_address" */
export type Profile_Wallet_Address_Insert_Input = {
  profile_context_id?: Maybe<Scalars['bigint']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Profile_Wallet_Address_Max_Fields = {
  __typename?: 'Profile_wallet_address_max_fields';
  profile_context_id?: Maybe<Scalars['bigint']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Profile_Wallet_Address_Min_Fields = {
  __typename?: 'Profile_wallet_address_min_fields';
  profile_context_id?: Maybe<Scalars['bigint']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Profile_wallet_address" */
export type Profile_Wallet_Address_Mutation_Response = {
  __typename?: 'Profile_wallet_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Wallet_Address>;
};

/** on_conflict condition type for table "Profile_wallet_address" */
export type Profile_Wallet_Address_On_Conflict = {
  constraint: Profile_Wallet_Address_Constraint;
  update_columns?: Array<Profile_Wallet_Address_Update_Column>;
  where?: Maybe<Profile_Wallet_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "Profile_wallet_address". */
export type Profile_Wallet_Address_Order_By = {
  profile_context_id?: Maybe<Order_By>;
  wallet_address?: Maybe<Order_By>;
};

/** primary key columns input for table: Profile_wallet_address */
export type Profile_Wallet_Address_Pk_Columns_Input = {
  profile_context_id: Scalars['bigint'];
};

/** select columns of table "Profile_wallet_address" */
export enum Profile_Wallet_Address_Select_Column {
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  WalletAddress = 'wallet_address'
}

/** input type for updating data in table "Profile_wallet_address" */
export type Profile_Wallet_Address_Set_Input = {
  profile_context_id?: Maybe<Scalars['bigint']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Profile_Wallet_Address_Stddev_Fields = {
  __typename?: 'Profile_wallet_address_stddev_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Wallet_Address_Stddev_Pop_Fields = {
  __typename?: 'Profile_wallet_address_stddev_pop_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Wallet_Address_Stddev_Samp_Fields = {
  __typename?: 'Profile_wallet_address_stddev_samp_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Profile_Wallet_Address_Sum_Fields = {
  __typename?: 'Profile_wallet_address_sum_fields';
  profile_context_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Profile_wallet_address" */
export enum Profile_Wallet_Address_Update_Column {
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  WalletAddress = 'wallet_address'
}

/** aggregate var_pop on columns */
export type Profile_Wallet_Address_Var_Pop_Fields = {
  __typename?: 'Profile_wallet_address_var_pop_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Wallet_Address_Var_Samp_Fields = {
  __typename?: 'Profile_wallet_address_var_samp_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Wallet_Address_Variance_Fields = {
  __typename?: 'Profile_wallet_address_variance_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
};

export enum ProxyAggregateEnum {
  ArtistCountByCountry = 'ArtistCountByCountry',
  ArtistFollowersCount = 'ArtistFollowersCount',
  ArtistFollowingCount = 'ArtistFollowingCount',
  ArtistFollowingsCount = 'ArtistFollowingsCount',
  AvailableArtworksOfArtist = 'AvailableArtworksOfArtist',
  CollectorFollowersCount = 'CollectorFollowersCount',
  CollectorFollowingCount = 'CollectorFollowingCount',
  DocumentsCount = 'DocumentsCount',
  FollowersCount = 'FollowersCount',
  GetArtworksCountArrangeArtworks = 'GetArtworksCountArrangeArtworks',
  HandleCollisionsCount = 'HandleCollisionsCount',
  HandleSafetyCounter = 'HandleSafetyCounter',
  MutualRecognitionsOfArtist = 'MutualRecognitionsOfArtist',
  NumberOfAppNotifications = 'NumberOfAppNotifications',
  NumberOfUnreadAppNotifications = 'NumberOfUnreadAppNotifications',
  ProfileFollowersTotal = 'ProfileFollowersTotal',
  ProfileFollowingTotal = 'ProfileFollowingTotal',
  ProfileLinksAgregateDeletedPress = 'ProfileLinksAgregateDeletedPress',
  PublishedArtworksOfArtist = 'PublishedArtworksOfArtist',
  TotalArtistsPartnershipByGallery = 'TotalArtistsPartnershipByGallery',
  TotalArtworksCountOfArtist = 'TotalArtworksCountOfArtist',
  TotalArtworksPrintsByCollector = 'TotalArtworksPrintsByCollector',
  TotalNumberOfArtworksForArtistMilestones = 'TotalNumberOfArtworksForArtistMilestones',
  TotalNumberOfPublicArtworks = 'TotalNumberOfPublicArtworks',
  TotalNumberOfPublicArtworksFromArtists = 'TotalNumberOfPublicArtworksFromArtists'
}

export enum PseudoAuthStatusEnum {
  LoginBasic = 'LOGIN_BASIC',
  Ok = 'OK'
}

export type PseudoAuthStatusResponse = {
  __typename?: 'PseudoAuthStatusResponse';
  message: Scalars['String'];
  status: PseudoAuthStatusEnum;
};

export type Pubnub_Channels_Type = {
  __typename?: 'Pubnub_Channels_Type';
  custom: Custom_Pubnub_Channels_Type;
  description?: Maybe<Scalars['String']>;
  eTag?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
};

/** Table to save the existing chat channels between users */
export type Pubnub_Channels = {
  __typename?: 'Pubnub_channels';
  description?: Maybe<Scalars['String']>;
  eTag?: Maybe<Scalars['String']>;
  firstProfileUrl?: Maybe<Scalars['String']>;
  firstUserContextId: Scalars['bigint'];
  firstUserName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastMessage?: Maybe<Scalars['String']>;
  lastMessageSenderContextId?: Maybe<Scalars['bigint']>;
  lastMessageUpdatedAt?: Maybe<Scalars['timestamptz']>;
  lastReadTimetoken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  secondProfileUrl?: Maybe<Scalars['String']>;
  secondUserContextId: Scalars['bigint'];
  secondUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated: Scalars['timestamptz'];
};

/** aggregated selection of "Pubnub_channels" */
export type Pubnub_Channels_Aggregate = {
  __typename?: 'Pubnub_channels_aggregate';
  aggregate?: Maybe<Pubnub_Channels_Aggregate_Fields>;
  nodes: Array<Pubnub_Channels>;
};

/** aggregate fields of "Pubnub_channels" */
export type Pubnub_Channels_Aggregate_Fields = {
  __typename?: 'Pubnub_channels_aggregate_fields';
  avg?: Maybe<Pubnub_Channels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pubnub_Channels_Max_Fields>;
  min?: Maybe<Pubnub_Channels_Min_Fields>;
  stddev?: Maybe<Pubnub_Channels_Stddev_Fields>;
  stddev_pop?: Maybe<Pubnub_Channels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pubnub_Channels_Stddev_Samp_Fields>;
  sum?: Maybe<Pubnub_Channels_Sum_Fields>;
  var_pop?: Maybe<Pubnub_Channels_Var_Pop_Fields>;
  var_samp?: Maybe<Pubnub_Channels_Var_Samp_Fields>;
  variance?: Maybe<Pubnub_Channels_Variance_Fields>;
};


/** aggregate fields of "Pubnub_channels" */
export type Pubnub_Channels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pubnub_Channels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Pubnub_Channels_Avg_Fields = {
  __typename?: 'Pubnub_channels_avg_fields';
  firstUserContextId?: Maybe<Scalars['Float']>;
  lastMessageSenderContextId?: Maybe<Scalars['Float']>;
  secondUserContextId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Pubnub_channels". All fields are combined with a logical 'AND'. */
export type Pubnub_Channels_Bool_Exp = {
  _and?: Maybe<Array<Pubnub_Channels_Bool_Exp>>;
  _not?: Maybe<Pubnub_Channels_Bool_Exp>;
  _or?: Maybe<Array<Pubnub_Channels_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  eTag?: Maybe<String_Comparison_Exp>;
  firstProfileUrl?: Maybe<String_Comparison_Exp>;
  firstUserContextId?: Maybe<Bigint_Comparison_Exp>;
  firstUserName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  lastMessage?: Maybe<String_Comparison_Exp>;
  lastMessageSenderContextId?: Maybe<Bigint_Comparison_Exp>;
  lastMessageUpdatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  lastReadTimetoken?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  secondProfileUrl?: Maybe<String_Comparison_Exp>;
  secondUserContextId?: Maybe<Bigint_Comparison_Exp>;
  secondUserName?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Pubnub_channels" */
export enum Pubnub_Channels_Constraint {
  /** unique or primary key constraint on columns "id" */
  PubnubChannelsPkey = 'Pubnub_channels_pkey'
}

/** input type for incrementing numeric columns in table "Pubnub_channels" */
export type Pubnub_Channels_Inc_Input = {
  firstUserContextId?: Maybe<Scalars['bigint']>;
  lastMessageSenderContextId?: Maybe<Scalars['bigint']>;
  secondUserContextId?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Pubnub_channels" */
export type Pubnub_Channels_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  eTag?: Maybe<Scalars['String']>;
  firstProfileUrl?: Maybe<Scalars['String']>;
  firstUserContextId?: Maybe<Scalars['bigint']>;
  firstUserName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<Scalars['String']>;
  lastMessageSenderContextId?: Maybe<Scalars['bigint']>;
  lastMessageUpdatedAt?: Maybe<Scalars['timestamptz']>;
  lastReadTimetoken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  secondProfileUrl?: Maybe<Scalars['String']>;
  secondUserContextId?: Maybe<Scalars['bigint']>;
  secondUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Pubnub_Channels_Max_Fields = {
  __typename?: 'Pubnub_channels_max_fields';
  description?: Maybe<Scalars['String']>;
  eTag?: Maybe<Scalars['String']>;
  firstProfileUrl?: Maybe<Scalars['String']>;
  firstUserContextId?: Maybe<Scalars['bigint']>;
  firstUserName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<Scalars['String']>;
  lastMessageSenderContextId?: Maybe<Scalars['bigint']>;
  lastMessageUpdatedAt?: Maybe<Scalars['timestamptz']>;
  lastReadTimetoken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  secondProfileUrl?: Maybe<Scalars['String']>;
  secondUserContextId?: Maybe<Scalars['bigint']>;
  secondUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Pubnub_Channels_Min_Fields = {
  __typename?: 'Pubnub_channels_min_fields';
  description?: Maybe<Scalars['String']>;
  eTag?: Maybe<Scalars['String']>;
  firstProfileUrl?: Maybe<Scalars['String']>;
  firstUserContextId?: Maybe<Scalars['bigint']>;
  firstUserName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<Scalars['String']>;
  lastMessageSenderContextId?: Maybe<Scalars['bigint']>;
  lastMessageUpdatedAt?: Maybe<Scalars['timestamptz']>;
  lastReadTimetoken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  secondProfileUrl?: Maybe<Scalars['String']>;
  secondUserContextId?: Maybe<Scalars['bigint']>;
  secondUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Pubnub_channels" */
export type Pubnub_Channels_Mutation_Response = {
  __typename?: 'Pubnub_channels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pubnub_Channels>;
};

/** on_conflict condition type for table "Pubnub_channels" */
export type Pubnub_Channels_On_Conflict = {
  constraint: Pubnub_Channels_Constraint;
  update_columns?: Array<Pubnub_Channels_Update_Column>;
  where?: Maybe<Pubnub_Channels_Bool_Exp>;
};

/** Ordering options when selecting data from "Pubnub_channels". */
export type Pubnub_Channels_Order_By = {
  description?: Maybe<Order_By>;
  eTag?: Maybe<Order_By>;
  firstProfileUrl?: Maybe<Order_By>;
  firstUserContextId?: Maybe<Order_By>;
  firstUserName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastMessage?: Maybe<Order_By>;
  lastMessageSenderContextId?: Maybe<Order_By>;
  lastMessageUpdatedAt?: Maybe<Order_By>;
  lastReadTimetoken?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  secondProfileUrl?: Maybe<Order_By>;
  secondUserContextId?: Maybe<Order_By>;
  secondUserName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated?: Maybe<Order_By>;
};

/** primary key columns input for table: Pubnub_channels */
export type Pubnub_Channels_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "Pubnub_channels" */
export enum Pubnub_Channels_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  ETag = 'eTag',
  /** column name */
  FirstProfileUrl = 'firstProfileUrl',
  /** column name */
  FirstUserContextId = 'firstUserContextId',
  /** column name */
  FirstUserName = 'firstUserName',
  /** column name */
  Id = 'id',
  /** column name */
  LastMessage = 'lastMessage',
  /** column name */
  LastMessageSenderContextId = 'lastMessageSenderContextId',
  /** column name */
  LastMessageUpdatedAt = 'lastMessageUpdatedAt',
  /** column name */
  LastReadTimetoken = 'lastReadTimetoken',
  /** column name */
  Name = 'name',
  /** column name */
  SecondProfileUrl = 'secondProfileUrl',
  /** column name */
  SecondUserContextId = 'secondUserContextId',
  /** column name */
  SecondUserName = 'secondUserName',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  Updated = 'updated'
}

/** input type for updating data in table "Pubnub_channels" */
export type Pubnub_Channels_Set_Input = {
  description?: Maybe<Scalars['String']>;
  eTag?: Maybe<Scalars['String']>;
  firstProfileUrl?: Maybe<Scalars['String']>;
  firstUserContextId?: Maybe<Scalars['bigint']>;
  firstUserName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<Scalars['String']>;
  lastMessageSenderContextId?: Maybe<Scalars['bigint']>;
  lastMessageUpdatedAt?: Maybe<Scalars['timestamptz']>;
  lastReadTimetoken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  secondProfileUrl?: Maybe<Scalars['String']>;
  secondUserContextId?: Maybe<Scalars['bigint']>;
  secondUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Pubnub_Channels_Stddev_Fields = {
  __typename?: 'Pubnub_channels_stddev_fields';
  firstUserContextId?: Maybe<Scalars['Float']>;
  lastMessageSenderContextId?: Maybe<Scalars['Float']>;
  secondUserContextId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Pubnub_Channels_Stddev_Pop_Fields = {
  __typename?: 'Pubnub_channels_stddev_pop_fields';
  firstUserContextId?: Maybe<Scalars['Float']>;
  lastMessageSenderContextId?: Maybe<Scalars['Float']>;
  secondUserContextId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Pubnub_Channels_Stddev_Samp_Fields = {
  __typename?: 'Pubnub_channels_stddev_samp_fields';
  firstUserContextId?: Maybe<Scalars['Float']>;
  lastMessageSenderContextId?: Maybe<Scalars['Float']>;
  secondUserContextId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Pubnub_Channels_Sum_Fields = {
  __typename?: 'Pubnub_channels_sum_fields';
  firstUserContextId?: Maybe<Scalars['bigint']>;
  lastMessageSenderContextId?: Maybe<Scalars['bigint']>;
  secondUserContextId?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Pubnub_channels" */
export enum Pubnub_Channels_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  ETag = 'eTag',
  /** column name */
  FirstProfileUrl = 'firstProfileUrl',
  /** column name */
  FirstUserContextId = 'firstUserContextId',
  /** column name */
  FirstUserName = 'firstUserName',
  /** column name */
  Id = 'id',
  /** column name */
  LastMessage = 'lastMessage',
  /** column name */
  LastMessageSenderContextId = 'lastMessageSenderContextId',
  /** column name */
  LastMessageUpdatedAt = 'lastMessageUpdatedAt',
  /** column name */
  LastReadTimetoken = 'lastReadTimetoken',
  /** column name */
  Name = 'name',
  /** column name */
  SecondProfileUrl = 'secondProfileUrl',
  /** column name */
  SecondUserContextId = 'secondUserContextId',
  /** column name */
  SecondUserName = 'secondUserName',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  Updated = 'updated'
}

/** aggregate var_pop on columns */
export type Pubnub_Channels_Var_Pop_Fields = {
  __typename?: 'Pubnub_channels_var_pop_fields';
  firstUserContextId?: Maybe<Scalars['Float']>;
  lastMessageSenderContextId?: Maybe<Scalars['Float']>;
  secondUserContextId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Pubnub_Channels_Var_Samp_Fields = {
  __typename?: 'Pubnub_channels_var_samp_fields';
  firstUserContextId?: Maybe<Scalars['Float']>;
  lastMessageSenderContextId?: Maybe<Scalars['Float']>;
  secondUserContextId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Pubnub_Channels_Variance_Fields = {
  __typename?: 'Pubnub_channels_variance_fields';
  firstUserContextId?: Maybe<Scalars['Float']>;
  lastMessageSenderContextId?: Maybe<Scalars['Float']>;
  secondUserContextId?: Maybe<Scalars['Float']>;
};

/** Stores all inquires for purchase from a collector to an artitst/gallery. An Inquire could be resent after 24h (or something like this) */
export type Purchase_Inquires = {
  __typename?: 'Purchase_inquires';
  /** An object relationship */
  artwork: Artwork;
  artwork_id: Scalars['bigint'];
  /** An object relationship */
  context: Context;
  context_sender_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  expires_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Purchase_inquires" */
export type Purchase_Inquires_Aggregate = {
  __typename?: 'Purchase_inquires_aggregate';
  aggregate?: Maybe<Purchase_Inquires_Aggregate_Fields>;
  nodes: Array<Purchase_Inquires>;
};

/** aggregate fields of "Purchase_inquires" */
export type Purchase_Inquires_Aggregate_Fields = {
  __typename?: 'Purchase_inquires_aggregate_fields';
  avg?: Maybe<Purchase_Inquires_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Purchase_Inquires_Max_Fields>;
  min?: Maybe<Purchase_Inquires_Min_Fields>;
  stddev?: Maybe<Purchase_Inquires_Stddev_Fields>;
  stddev_pop?: Maybe<Purchase_Inquires_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Purchase_Inquires_Stddev_Samp_Fields>;
  sum?: Maybe<Purchase_Inquires_Sum_Fields>;
  var_pop?: Maybe<Purchase_Inquires_Var_Pop_Fields>;
  var_samp?: Maybe<Purchase_Inquires_Var_Samp_Fields>;
  variance?: Maybe<Purchase_Inquires_Variance_Fields>;
};


/** aggregate fields of "Purchase_inquires" */
export type Purchase_Inquires_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Purchase_inquires" */
export type Purchase_Inquires_Aggregate_Order_By = {
  avg?: Maybe<Purchase_Inquires_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Purchase_Inquires_Max_Order_By>;
  min?: Maybe<Purchase_Inquires_Min_Order_By>;
  stddev?: Maybe<Purchase_Inquires_Stddev_Order_By>;
  stddev_pop?: Maybe<Purchase_Inquires_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Purchase_Inquires_Stddev_Samp_Order_By>;
  sum?: Maybe<Purchase_Inquires_Sum_Order_By>;
  var_pop?: Maybe<Purchase_Inquires_Var_Pop_Order_By>;
  var_samp?: Maybe<Purchase_Inquires_Var_Samp_Order_By>;
  variance?: Maybe<Purchase_Inquires_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Purchase_inquires" */
export type Purchase_Inquires_Arr_Rel_Insert_Input = {
  data: Array<Purchase_Inquires_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Purchase_Inquires_On_Conflict>;
};

/** aggregate avg on columns */
export type Purchase_Inquires_Avg_Fields = {
  __typename?: 'Purchase_inquires_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Purchase_inquires". All fields are combined with a logical 'AND'. */
export type Purchase_Inquires_Bool_Exp = {
  _and?: Maybe<Array<Purchase_Inquires_Bool_Exp>>;
  _not?: Maybe<Purchase_Inquires_Bool_Exp>;
  _or?: Maybe<Array<Purchase_Inquires_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_sender_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Purchase_inquires" */
export enum Purchase_Inquires_Constraint {
  /** unique or primary key constraint on columns "id" */
  PurchaseInquiresPkey = 'Purchase_inquires_pkey'
}

/** input type for incrementing numeric columns in table "Purchase_inquires" */
export type Purchase_Inquires_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Purchase_inquires" */
export type Purchase_Inquires_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Purchase_Inquires_Max_Fields = {
  __typename?: 'Purchase_inquires_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Purchase_Inquires_Min_Fields = {
  __typename?: 'Purchase_inquires_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Purchase_inquires" */
export type Purchase_Inquires_Mutation_Response = {
  __typename?: 'Purchase_inquires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Purchase_Inquires>;
};

/** on_conflict condition type for table "Purchase_inquires" */
export type Purchase_Inquires_On_Conflict = {
  constraint: Purchase_Inquires_Constraint;
  update_columns?: Array<Purchase_Inquires_Update_Column>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};

/** Ordering options when selecting data from "Purchase_inquires". */
export type Purchase_Inquires_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  context?: Maybe<Context_Order_By>;
  context_sender_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Same as Purchase_inquires, but for anon/outside/non logged users */
export type Purchase_Inquires_Outside_Users = {
  __typename?: 'Purchase_inquires_outside_users';
  /** An object relationship */
  artwork: Artwork;
  artwork_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  inquirer_email: Scalars['String'];
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Aggregate = {
  __typename?: 'Purchase_inquires_outside_users_aggregate';
  aggregate?: Maybe<Purchase_Inquires_Outside_Users_Aggregate_Fields>;
  nodes: Array<Purchase_Inquires_Outside_Users>;
};

/** aggregate fields of "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Aggregate_Fields = {
  __typename?: 'Purchase_inquires_outside_users_aggregate_fields';
  avg?: Maybe<Purchase_Inquires_Outside_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Purchase_Inquires_Outside_Users_Max_Fields>;
  min?: Maybe<Purchase_Inquires_Outside_Users_Min_Fields>;
  stddev?: Maybe<Purchase_Inquires_Outside_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Purchase_Inquires_Outside_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Purchase_Inquires_Outside_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Purchase_Inquires_Outside_Users_Sum_Fields>;
  var_pop?: Maybe<Purchase_Inquires_Outside_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Purchase_Inquires_Outside_Users_Var_Samp_Fields>;
  variance?: Maybe<Purchase_Inquires_Outside_Users_Variance_Fields>;
};


/** aggregate fields of "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Purchase_Inquires_Outside_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Aggregate_Order_By = {
  avg?: Maybe<Purchase_Inquires_Outside_Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Purchase_Inquires_Outside_Users_Max_Order_By>;
  min?: Maybe<Purchase_Inquires_Outside_Users_Min_Order_By>;
  stddev?: Maybe<Purchase_Inquires_Outside_Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Purchase_Inquires_Outside_Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Purchase_Inquires_Outside_Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Purchase_Inquires_Outside_Users_Sum_Order_By>;
  var_pop?: Maybe<Purchase_Inquires_Outside_Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Purchase_Inquires_Outside_Users_Var_Samp_Order_By>;
  variance?: Maybe<Purchase_Inquires_Outside_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Arr_Rel_Insert_Input = {
  data: Array<Purchase_Inquires_Outside_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Purchase_Inquires_Outside_Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Purchase_Inquires_Outside_Users_Avg_Fields = {
  __typename?: 'Purchase_inquires_outside_users_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Purchase_inquires_outside_users". All fields are combined with a logical 'AND'. */
export type Purchase_Inquires_Outside_Users_Bool_Exp = {
  _and?: Maybe<Array<Purchase_Inquires_Outside_Users_Bool_Exp>>;
  _not?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
  _or?: Maybe<Array<Purchase_Inquires_Outside_Users_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  inquirer_email?: Maybe<String_Comparison_Exp>;
  inquirer_ip_addr?: Maybe<String_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Purchase_inquires_outside_users" */
export enum Purchase_Inquires_Outside_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  PurchaseInquiresOutsideUsersPkey = 'Purchase_inquires_outside_users_pkey'
}

/** input type for incrementing numeric columns in table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  inquirer_email?: Maybe<Scalars['String']>;
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Purchase_Inquires_Outside_Users_Max_Fields = {
  __typename?: 'Purchase_inquires_outside_users_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  inquirer_email?: Maybe<Scalars['String']>;
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inquirer_email?: Maybe<Order_By>;
  inquirer_ip_addr?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Purchase_Inquires_Outside_Users_Min_Fields = {
  __typename?: 'Purchase_inquires_outside_users_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  inquirer_email?: Maybe<Scalars['String']>;
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inquirer_email?: Maybe<Order_By>;
  inquirer_ip_addr?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Mutation_Response = {
  __typename?: 'Purchase_inquires_outside_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Purchase_Inquires_Outside_Users>;
};

/** on_conflict condition type for table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_On_Conflict = {
  constraint: Purchase_Inquires_Outside_Users_Constraint;
  update_columns?: Array<Purchase_Inquires_Outside_Users_Update_Column>;
  where?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "Purchase_inquires_outside_users". */
export type Purchase_Inquires_Outside_Users_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inquirer_email?: Maybe<Order_By>;
  inquirer_ip_addr?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Purchase_inquires_outside_users */
export type Purchase_Inquires_Outside_Users_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Purchase_inquires_outside_users" */
export enum Purchase_Inquires_Outside_Users_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InquirerEmail = 'inquirer_email',
  /** column name */
  InquirerIpAddr = 'inquirer_ip_addr',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  inquirer_email?: Maybe<Scalars['String']>;
  inquirer_ip_addr?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Purchase_Inquires_Outside_Users_Stddev_Fields = {
  __typename?: 'Purchase_inquires_outside_users_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Purchase_Inquires_Outside_Users_Stddev_Pop_Fields = {
  __typename?: 'Purchase_inquires_outside_users_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Purchase_Inquires_Outside_Users_Stddev_Samp_Fields = {
  __typename?: 'Purchase_inquires_outside_users_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Purchase_Inquires_Outside_Users_Sum_Fields = {
  __typename?: 'Purchase_inquires_outside_users_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Purchase_inquires_outside_users" */
export enum Purchase_Inquires_Outside_Users_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InquirerEmail = 'inquirer_email',
  /** column name */
  InquirerIpAddr = 'inquirer_ip_addr',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Purchase_Inquires_Outside_Users_Var_Pop_Fields = {
  __typename?: 'Purchase_inquires_outside_users_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Purchase_Inquires_Outside_Users_Var_Samp_Fields = {
  __typename?: 'Purchase_inquires_outside_users_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Purchase_Inquires_Outside_Users_Variance_Fields = {
  __typename?: 'Purchase_inquires_outside_users_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Purchase_inquires_outside_users" */
export type Purchase_Inquires_Outside_Users_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: Purchase_inquires */
export type Purchase_Inquires_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Purchase_inquires" */
export enum Purchase_Inquires_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ContextSenderId = 'context_sender_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Purchase_inquires" */
export type Purchase_Inquires_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Purchase_Inquires_Stddev_Fields = {
  __typename?: 'Purchase_inquires_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Purchase_Inquires_Stddev_Pop_Fields = {
  __typename?: 'Purchase_inquires_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Purchase_Inquires_Stddev_Samp_Fields = {
  __typename?: 'Purchase_inquires_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Purchase_Inquires_Sum_Fields = {
  __typename?: 'Purchase_inquires_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  context_sender_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Purchase_inquires" */
export enum Purchase_Inquires_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  ContextSenderId = 'context_sender_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Purchase_Inquires_Var_Pop_Fields = {
  __typename?: 'Purchase_inquires_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Purchase_Inquires_Var_Samp_Fields = {
  __typename?: 'Purchase_inquires_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Purchase_Inquires_Variance_Fields = {
  __typename?: 'Purchase_inquires_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  context_sender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Purchase_inquires" */
export type Purchase_Inquires_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  context_sender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Table used to store all push notifications that were triggered by schedule events or user and admin actions and to be delivered. The message column should contain a json like this{ receivers: [{context_id, language}], payload: {image, title, body, clickAction}} . The payload is optional and will only be used for CUSTOM types */
export type Push_Notifications = {
  __typename?: 'Push_notifications';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  message: Scalars['jsonb'];
  status: Enum_Push_Notification_Status_Enum;
  status_at?: Maybe<Scalars['timestamptz']>;
  type: Enum_Push_Notification_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/** Table used to store all push notifications that were triggered by schedule events or user and admin actions and to be delivered. The message column should contain a json like this{ receivers: [{context_id, language}], payload: {image, title, body, clickAction}} . The payload is optional and will only be used for CUSTOM types */
export type Push_NotificationsMessageArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Push_notifications" */
export type Push_Notifications_Aggregate = {
  __typename?: 'Push_notifications_aggregate';
  aggregate?: Maybe<Push_Notifications_Aggregate_Fields>;
  nodes: Array<Push_Notifications>;
};

/** aggregate fields of "Push_notifications" */
export type Push_Notifications_Aggregate_Fields = {
  __typename?: 'Push_notifications_aggregate_fields';
  avg?: Maybe<Push_Notifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Push_Notifications_Max_Fields>;
  min?: Maybe<Push_Notifications_Min_Fields>;
  stddev?: Maybe<Push_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Push_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Push_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Push_Notifications_Sum_Fields>;
  var_pop?: Maybe<Push_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Push_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Push_Notifications_Variance_Fields>;
};


/** aggregate fields of "Push_notifications" */
export type Push_Notifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Push_Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Push_Notifications_Append_Input = {
  message?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Push_Notifications_Avg_Fields = {
  __typename?: 'Push_notifications_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Push_notifications". All fields are combined with a logical 'AND'. */
export type Push_Notifications_Bool_Exp = {
  _and?: Maybe<Array<Push_Notifications_Bool_Exp>>;
  _not?: Maybe<Push_Notifications_Bool_Exp>;
  _or?: Maybe<Array<Push_Notifications_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  message?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Enum_Push_Notification_Status_Enum_Comparison_Exp>;
  status_at?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<Enum_Push_Notification_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Push_notifications" */
export enum Push_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  PushNotificationsPkey = 'Push_notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Push_Notifications_Delete_At_Path_Input = {
  message?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Push_Notifications_Delete_Elem_Input = {
  message?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Push_Notifications_Delete_Key_Input = {
  message?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Push_notifications" */
export type Push_Notifications_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Push_notifications" */
export type Push_Notifications_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Enum_Push_Notification_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Enum_Push_Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Push_Notifications_Max_Fields = {
  __typename?: 'Push_notifications_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Push_Notifications_Min_Fields = {
  __typename?: 'Push_notifications_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  status_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Push_notifications" */
export type Push_Notifications_Mutation_Response = {
  __typename?: 'Push_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Push_Notifications>;
};

/** on_conflict condition type for table "Push_notifications" */
export type Push_Notifications_On_Conflict = {
  constraint: Push_Notifications_Constraint;
  update_columns?: Array<Push_Notifications_Update_Column>;
  where?: Maybe<Push_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "Push_notifications". */
export type Push_Notifications_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  status_at?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Push_notifications */
export type Push_Notifications_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Push_Notifications_Prepend_Input = {
  message?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Push_notifications" */
export enum Push_Notifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Push_notifications" */
export type Push_Notifications_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Enum_Push_Notification_Status_Enum>;
  status_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Enum_Push_Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Push_Notifications_Stddev_Fields = {
  __typename?: 'Push_notifications_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Push_Notifications_Stddev_Pop_Fields = {
  __typename?: 'Push_notifications_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Push_Notifications_Stddev_Samp_Fields = {
  __typename?: 'Push_notifications_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Push_Notifications_Sum_Fields = {
  __typename?: 'Push_notifications_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Push_notifications" */
export enum Push_Notifications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Status = 'status',
  /** column name */
  StatusAt = 'status_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Push_Notifications_Var_Pop_Fields = {
  __typename?: 'Push_notifications_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Push_Notifications_Var_Samp_Fields = {
  __typename?: 'Push_notifications_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Push_Notifications_Variance_Fields = {
  __typename?: 'Push_notifications_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type QuestionAndAnswer = {
  answer?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['bigint']>;
};

/** Holds questions to be displayed for questionnaires of a specific type. Default_text contains the question itself in english and lokalise_key represents the key used to get the question translated in different languages */
export type Questionnaire_Questions = {
  __typename?: 'Questionnaire_questions';
  created_at: Scalars['timetz'];
  default_text: Scalars['String'];
  deleted_at?: Maybe<Scalars['timetz']>;
  id: Scalars['bigint'];
  type: Enum_Questionnaire_Type_Enum;
};

/** aggregated selection of "Questionnaire_questions" */
export type Questionnaire_Questions_Aggregate = {
  __typename?: 'Questionnaire_questions_aggregate';
  aggregate?: Maybe<Questionnaire_Questions_Aggregate_Fields>;
  nodes: Array<Questionnaire_Questions>;
};

/** aggregate fields of "Questionnaire_questions" */
export type Questionnaire_Questions_Aggregate_Fields = {
  __typename?: 'Questionnaire_questions_aggregate_fields';
  avg?: Maybe<Questionnaire_Questions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Questionnaire_Questions_Max_Fields>;
  min?: Maybe<Questionnaire_Questions_Min_Fields>;
  stddev?: Maybe<Questionnaire_Questions_Stddev_Fields>;
  stddev_pop?: Maybe<Questionnaire_Questions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Questionnaire_Questions_Stddev_Samp_Fields>;
  sum?: Maybe<Questionnaire_Questions_Sum_Fields>;
  var_pop?: Maybe<Questionnaire_Questions_Var_Pop_Fields>;
  var_samp?: Maybe<Questionnaire_Questions_Var_Samp_Fields>;
  variance?: Maybe<Questionnaire_Questions_Variance_Fields>;
};


/** aggregate fields of "Questionnaire_questions" */
export type Questionnaire_Questions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Questionnaire_Questions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Questionnaire_Questions_Avg_Fields = {
  __typename?: 'Questionnaire_questions_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Questionnaire_questions". All fields are combined with a logical 'AND'. */
export type Questionnaire_Questions_Bool_Exp = {
  _and?: Maybe<Array<Questionnaire_Questions_Bool_Exp>>;
  _not?: Maybe<Questionnaire_Questions_Bool_Exp>;
  _or?: Maybe<Array<Questionnaire_Questions_Bool_Exp>>;
  created_at?: Maybe<Timetz_Comparison_Exp>;
  default_text?: Maybe<String_Comparison_Exp>;
  deleted_at?: Maybe<Timetz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<Enum_Questionnaire_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "Questionnaire_questions" */
export enum Questionnaire_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnaireQuestionsPkey = 'Questionnaire_questions_pkey'
}

/** input type for incrementing numeric columns in table "Questionnaire_questions" */
export type Questionnaire_Questions_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Questionnaire_questions" */
export type Questionnaire_Questions_Insert_Input = {
  created_at?: Maybe<Scalars['timetz']>;
  default_text?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Questionnaire_Type_Enum>;
};

/** aggregate max on columns */
export type Questionnaire_Questions_Max_Fields = {
  __typename?: 'Questionnaire_questions_max_fields';
  created_at?: Maybe<Scalars['timetz']>;
  default_text?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Questionnaire_Questions_Min_Fields = {
  __typename?: 'Questionnaire_questions_min_fields';
  created_at?: Maybe<Scalars['timetz']>;
  default_text?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Questionnaire_questions" */
export type Questionnaire_Questions_Mutation_Response = {
  __typename?: 'Questionnaire_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaire_Questions>;
};

/** input type for inserting object relation for remote table "Questionnaire_questions" */
export type Questionnaire_Questions_Obj_Rel_Insert_Input = {
  data: Questionnaire_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Questionnaire_Questions_On_Conflict>;
};

/** on_conflict condition type for table "Questionnaire_questions" */
export type Questionnaire_Questions_On_Conflict = {
  constraint: Questionnaire_Questions_Constraint;
  update_columns?: Array<Questionnaire_Questions_Update_Column>;
  where?: Maybe<Questionnaire_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "Questionnaire_questions". */
export type Questionnaire_Questions_Order_By = {
  created_at?: Maybe<Order_By>;
  default_text?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: Questionnaire_questions */
export type Questionnaire_Questions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Questionnaire_questions" */
export enum Questionnaire_Questions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultText = 'default_text',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "Questionnaire_questions" */
export type Questionnaire_Questions_Set_Input = {
  created_at?: Maybe<Scalars['timetz']>;
  default_text?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Questionnaire_Type_Enum>;
};

/** aggregate stddev on columns */
export type Questionnaire_Questions_Stddev_Fields = {
  __typename?: 'Questionnaire_questions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Questionnaire_Questions_Stddev_Pop_Fields = {
  __typename?: 'Questionnaire_questions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Questionnaire_Questions_Stddev_Samp_Fields = {
  __typename?: 'Questionnaire_questions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Questionnaire_Questions_Sum_Fields = {
  __typename?: 'Questionnaire_questions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Questionnaire_questions" */
export enum Questionnaire_Questions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultText = 'default_text',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Questionnaire_Questions_Var_Pop_Fields = {
  __typename?: 'Questionnaire_questions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Questionnaire_Questions_Var_Samp_Fields = {
  __typename?: 'Questionnaire_questions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Questionnaire_Questions_Variance_Fields = {
  __typename?: 'Questionnaire_questions_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type RegisterOutputType = {
  __typename?: 'RegisterOutputType';
  message?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type RegisterTokenOutputType = {
  __typename?: 'RegisterTokenOutputType';
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

/** Table to register and track rendering request (for images or pdfs  that we can generate server-side) */
export type Render_Request = {
  __typename?: 'Render_request';
  created_at: Scalars['timestamptz'];
  failure_references?: Maybe<Scalars['jsonb']>;
  fingerprint: Scalars['String'];
  id: Scalars['bigint'];
  render_props?: Maybe<Scalars['jsonb']>;
  status: Enum_Render_Request_Status_Enum;
  target_outputs: Scalars['jsonb'];
  template: Scalars['String'];
  template_type: Enum_Render_Request_Template_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/** Table to register and track rendering request (for images or pdfs  that we can generate server-side) */
export type Render_RequestFailure_ReferencesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table to register and track rendering request (for images or pdfs  that we can generate server-side) */
export type Render_RequestRender_PropsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** Table to register and track rendering request (for images or pdfs  that we can generate server-side) */
export type Render_RequestTarget_OutputsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Render_request" */
export type Render_Request_Aggregate = {
  __typename?: 'Render_request_aggregate';
  aggregate?: Maybe<Render_Request_Aggregate_Fields>;
  nodes: Array<Render_Request>;
};

/** aggregate fields of "Render_request" */
export type Render_Request_Aggregate_Fields = {
  __typename?: 'Render_request_aggregate_fields';
  avg?: Maybe<Render_Request_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Render_Request_Max_Fields>;
  min?: Maybe<Render_Request_Min_Fields>;
  stddev?: Maybe<Render_Request_Stddev_Fields>;
  stddev_pop?: Maybe<Render_Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Render_Request_Stddev_Samp_Fields>;
  sum?: Maybe<Render_Request_Sum_Fields>;
  var_pop?: Maybe<Render_Request_Var_Pop_Fields>;
  var_samp?: Maybe<Render_Request_Var_Samp_Fields>;
  variance?: Maybe<Render_Request_Variance_Fields>;
};


/** aggregate fields of "Render_request" */
export type Render_Request_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Render_Request_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Render_Request_Append_Input = {
  failure_references?: Maybe<Scalars['jsonb']>;
  render_props?: Maybe<Scalars['jsonb']>;
  target_outputs?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Render_Request_Avg_Fields = {
  __typename?: 'Render_request_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Render_request". All fields are combined with a logical 'AND'. */
export type Render_Request_Bool_Exp = {
  _and?: Maybe<Array<Render_Request_Bool_Exp>>;
  _not?: Maybe<Render_Request_Bool_Exp>;
  _or?: Maybe<Array<Render_Request_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  failure_references?: Maybe<Jsonb_Comparison_Exp>;
  fingerprint?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  render_props?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<Enum_Render_Request_Status_Enum_Comparison_Exp>;
  target_outputs?: Maybe<Jsonb_Comparison_Exp>;
  template?: Maybe<String_Comparison_Exp>;
  template_type?: Maybe<Enum_Render_Request_Template_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Render_request" */
export enum Render_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  RenderRequestPkey = 'Render_request_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Render_Request_Delete_At_Path_Input = {
  failure_references?: Maybe<Array<Scalars['String']>>;
  render_props?: Maybe<Array<Scalars['String']>>;
  target_outputs?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Render_Request_Delete_Elem_Input = {
  failure_references?: Maybe<Scalars['Int']>;
  render_props?: Maybe<Scalars['Int']>;
  target_outputs?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Render_Request_Delete_Key_Input = {
  failure_references?: Maybe<Scalars['String']>;
  render_props?: Maybe<Scalars['String']>;
  target_outputs?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Render_request" */
export type Render_Request_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Render_request" */
export type Render_Request_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  failure_references?: Maybe<Scalars['jsonb']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  render_props?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Enum_Render_Request_Status_Enum>;
  target_outputs?: Maybe<Scalars['jsonb']>;
  template?: Maybe<Scalars['String']>;
  template_type?: Maybe<Enum_Render_Request_Template_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Render_Request_Max_Fields = {
  __typename?: 'Render_request_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  template?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Render_Request_Min_Fields = {
  __typename?: 'Render_request_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  template?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Render_request" */
export type Render_Request_Mutation_Response = {
  __typename?: 'Render_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Render_Request>;
};

/** on_conflict condition type for table "Render_request" */
export type Render_Request_On_Conflict = {
  constraint: Render_Request_Constraint;
  update_columns?: Array<Render_Request_Update_Column>;
  where?: Maybe<Render_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "Render_request". */
export type Render_Request_Order_By = {
  created_at?: Maybe<Order_By>;
  failure_references?: Maybe<Order_By>;
  fingerprint?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  render_props?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  target_outputs?: Maybe<Order_By>;
  template?: Maybe<Order_By>;
  template_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Render_request */
export type Render_Request_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Render_Request_Prepend_Input = {
  failure_references?: Maybe<Scalars['jsonb']>;
  render_props?: Maybe<Scalars['jsonb']>;
  target_outputs?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Render_request" */
export enum Render_Request_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailureReferences = 'failure_references',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Id = 'id',
  /** column name */
  RenderProps = 'render_props',
  /** column name */
  Status = 'status',
  /** column name */
  TargetOutputs = 'target_outputs',
  /** column name */
  Template = 'template',
  /** column name */
  TemplateType = 'template_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Render_request" */
export type Render_Request_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  failure_references?: Maybe<Scalars['jsonb']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  render_props?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Enum_Render_Request_Status_Enum>;
  target_outputs?: Maybe<Scalars['jsonb']>;
  template?: Maybe<Scalars['String']>;
  template_type?: Maybe<Enum_Render_Request_Template_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Render_Request_Stddev_Fields = {
  __typename?: 'Render_request_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Render_Request_Stddev_Pop_Fields = {
  __typename?: 'Render_request_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Render_Request_Stddev_Samp_Fields = {
  __typename?: 'Render_request_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Render_Request_Sum_Fields = {
  __typename?: 'Render_request_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Render_request" */
export enum Render_Request_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailureReferences = 'failure_references',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Id = 'id',
  /** column name */
  RenderProps = 'render_props',
  /** column name */
  Status = 'status',
  /** column name */
  TargetOutputs = 'target_outputs',
  /** column name */
  Template = 'template',
  /** column name */
  TemplateType = 'template_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Render_Request_Var_Pop_Fields = {
  __typename?: 'Render_request_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Render_Request_Var_Samp_Fields = {
  __typename?: 'Render_request_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Render_Request_Variance_Fields = {
  __typename?: 'Render_request_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type RequestAccountValidationResponse = {
  __typename?: 'RequestAccountValidationResponse';
  token: Scalars['String'];
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  lastInquireDate: Scalars['String'];
};

/** Stores the user's setting for which profile to load by default when visiting the app. */
export type Setting_Context_Default_Profile = {
  __typename?: 'Setting_context_default_profile';
  /** An object relationship */
  app_access_context: Context;
  app_access_context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  profile_context?: Maybe<Context>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Setting_context_default_profile" */
export type Setting_Context_Default_Profile_Aggregate = {
  __typename?: 'Setting_context_default_profile_aggregate';
  aggregate?: Maybe<Setting_Context_Default_Profile_Aggregate_Fields>;
  nodes: Array<Setting_Context_Default_Profile>;
};

/** aggregate fields of "Setting_context_default_profile" */
export type Setting_Context_Default_Profile_Aggregate_Fields = {
  __typename?: 'Setting_context_default_profile_aggregate_fields';
  avg?: Maybe<Setting_Context_Default_Profile_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Setting_Context_Default_Profile_Max_Fields>;
  min?: Maybe<Setting_Context_Default_Profile_Min_Fields>;
  stddev?: Maybe<Setting_Context_Default_Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Setting_Context_Default_Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Setting_Context_Default_Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Setting_Context_Default_Profile_Sum_Fields>;
  var_pop?: Maybe<Setting_Context_Default_Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Setting_Context_Default_Profile_Var_Samp_Fields>;
  variance?: Maybe<Setting_Context_Default_Profile_Variance_Fields>;
};


/** aggregate fields of "Setting_context_default_profile" */
export type Setting_Context_Default_Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Setting_Context_Default_Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Setting_Context_Default_Profile_Avg_Fields = {
  __typename?: 'Setting_context_default_profile_avg_fields';
  app_access_context_id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Setting_context_default_profile". All fields are combined with a logical 'AND'. */
export type Setting_Context_Default_Profile_Bool_Exp = {
  _and?: Maybe<Array<Setting_Context_Default_Profile_Bool_Exp>>;
  _not?: Maybe<Setting_Context_Default_Profile_Bool_Exp>;
  _or?: Maybe<Array<Setting_Context_Default_Profile_Bool_Exp>>;
  app_access_context?: Maybe<Context_Bool_Exp>;
  app_access_context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  profile_context?: Maybe<Context_Bool_Exp>;
  profile_context_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Setting_context_default_profile" */
export enum Setting_Context_Default_Profile_Constraint {
  /** unique or primary key constraint on columns "app_access_context_id" */
  SettingContextDefaultProfilePkey = 'Setting_context_default_profile_pkey'
}

/** input type for incrementing numeric columns in table "Setting_context_default_profile" */
export type Setting_Context_Default_Profile_Inc_Input = {
  app_access_context_id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Setting_context_default_profile" */
export type Setting_Context_Default_Profile_Insert_Input = {
  app_access_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  app_access_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  profile_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Setting_Context_Default_Profile_Max_Fields = {
  __typename?: 'Setting_context_default_profile_max_fields';
  app_access_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Setting_Context_Default_Profile_Min_Fields = {
  __typename?: 'Setting_context_default_profile_min_fields';
  app_access_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Setting_context_default_profile" */
export type Setting_Context_Default_Profile_Mutation_Response = {
  __typename?: 'Setting_context_default_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Setting_Context_Default_Profile>;
};

/** on_conflict condition type for table "Setting_context_default_profile" */
export type Setting_Context_Default_Profile_On_Conflict = {
  constraint: Setting_Context_Default_Profile_Constraint;
  update_columns?: Array<Setting_Context_Default_Profile_Update_Column>;
  where?: Maybe<Setting_Context_Default_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "Setting_context_default_profile". */
export type Setting_Context_Default_Profile_Order_By = {
  app_access_context?: Maybe<Context_Order_By>;
  app_access_context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  profile_context?: Maybe<Context_Order_By>;
  profile_context_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Setting_context_default_profile */
export type Setting_Context_Default_Profile_Pk_Columns_Input = {
  app_access_context_id: Scalars['bigint'];
};

/** select columns of table "Setting_context_default_profile" */
export enum Setting_Context_Default_Profile_Select_Column {
  /** column name */
  AppAccessContextId = 'app_access_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Setting_context_default_profile" */
export type Setting_Context_Default_Profile_Set_Input = {
  app_access_context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Setting_Context_Default_Profile_Stddev_Fields = {
  __typename?: 'Setting_context_default_profile_stddev_fields';
  app_access_context_id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Setting_Context_Default_Profile_Stddev_Pop_Fields = {
  __typename?: 'Setting_context_default_profile_stddev_pop_fields';
  app_access_context_id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Setting_Context_Default_Profile_Stddev_Samp_Fields = {
  __typename?: 'Setting_context_default_profile_stddev_samp_fields';
  app_access_context_id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Setting_Context_Default_Profile_Sum_Fields = {
  __typename?: 'Setting_context_default_profile_sum_fields';
  app_access_context_id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Setting_context_default_profile" */
export enum Setting_Context_Default_Profile_Update_Column {
  /** column name */
  AppAccessContextId = 'app_access_context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Setting_Context_Default_Profile_Var_Pop_Fields = {
  __typename?: 'Setting_context_default_profile_var_pop_fields';
  app_access_context_id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Setting_Context_Default_Profile_Var_Samp_Fields = {
  __typename?: 'Setting_context_default_profile_var_samp_fields';
  app_access_context_id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Setting_Context_Default_Profile_Variance_Fields = {
  __typename?: 'Setting_context_default_profile_variance_fields';
  app_access_context_id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
};

export type ShoppingCartItemType = {
  __typename?: 'ShoppingCartItemType';
  artistContextId: Scalars['bigint'];
  artistTitle?: Maybe<Scalars['String']>;
  artworkId: Scalars['bigint'];
  depth?: Maybe<Scalars['Float']>;
  discipline?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  isAvailableForSale: Scalars['Boolean'];
  isInAnInitiatedCheckout: Scalars['Boolean'];
  materials?: Maybe<Scalars['String']>;
  measuringUnit?: Maybe<Scalars['String']>;
  prices: Array<ArtworkPrice>;
  primaryMetadataId?: Maybe<Scalars['bigint']>;
  shippingPrice: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Showroom_details" */
export type Showroom_Details = {
  __typename?: 'Showroom_details';
  /** An object relationship */
  collection: Collection;
  collection_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  creator_context?: Maybe<Context>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  is_commercial?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  owner_password?: Maybe<Showroom_Owner_Password>;
  password?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom_context?: Maybe<Context>;
  showroom_context_id?: Maybe<Scalars['bigint']>;
  slug: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  views: Scalars['bigint'];
};

/** aggregated selection of "Showroom_details" */
export type Showroom_Details_Aggregate = {
  __typename?: 'Showroom_details_aggregate';
  aggregate?: Maybe<Showroom_Details_Aggregate_Fields>;
  nodes: Array<Showroom_Details>;
};

/** aggregate fields of "Showroom_details" */
export type Showroom_Details_Aggregate_Fields = {
  __typename?: 'Showroom_details_aggregate_fields';
  avg?: Maybe<Showroom_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Showroom_Details_Max_Fields>;
  min?: Maybe<Showroom_Details_Min_Fields>;
  stddev?: Maybe<Showroom_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Showroom_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Showroom_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Showroom_Details_Sum_Fields>;
  var_pop?: Maybe<Showroom_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Showroom_Details_Var_Samp_Fields>;
  variance?: Maybe<Showroom_Details_Variance_Fields>;
};


/** aggregate fields of "Showroom_details" */
export type Showroom_Details_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showroom_Details_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Showroom_Details_Avg_Fields = {
  __typename?: 'Showroom_details_avg_fields';
  collection_id?: Maybe<Scalars['Float']>;
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  showroom_context_id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Showroom_details". All fields are combined with a logical 'AND'. */
export type Showroom_Details_Bool_Exp = {
  _and?: Maybe<Array<Showroom_Details_Bool_Exp>>;
  _not?: Maybe<Showroom_Details_Bool_Exp>;
  _or?: Maybe<Array<Showroom_Details_Bool_Exp>>;
  collection?: Maybe<Collection_Bool_Exp>;
  collection_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  creator_context?: Maybe<Context_Bool_Exp>;
  creator_context_id?: Maybe<Bigint_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  expiration_date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_commercial?: Maybe<Boolean_Comparison_Exp>;
  owner_password?: Maybe<Showroom_Owner_Password_Bool_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  showroom_context?: Maybe<Context_Bool_Exp>;
  showroom_context_id?: Maybe<Bigint_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  views?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "Showroom_details" */
export enum Showroom_Details_Constraint {
  /** unique or primary key constraint on columns "collection_id" */
  ShowroomDetailsCollectionIdKey = 'Showroom_details_collection_id_key',
  /** unique or primary key constraint on columns "id" */
  ShowroomDetailsPkey = 'Showroom_details_pkey',
  /** unique or primary key constraint on columns "slug" */
  ShowroomDetailsSlugKey = 'Showroom_details_slug_key'
}

/** input type for incrementing numeric columns in table "Showroom_details" */
export type Showroom_Details_Inc_Input = {
  collection_id?: Maybe<Scalars['bigint']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  showroom_context_id?: Maybe<Scalars['bigint']>;
  views?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Showroom_details" */
export type Showroom_Details_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>;
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_commercial?: Maybe<Scalars['Boolean']>;
  owner_password?: Maybe<Showroom_Owner_Password_Obj_Rel_Insert_Input>;
  password?: Maybe<Scalars['String']>;
  showroom_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  showroom_context_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  views?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Showroom_Details_Max_Fields = {
  __typename?: 'Showroom_details_max_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['String']>;
  showroom_context_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  views?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Showroom_Details_Min_Fields = {
  __typename?: 'Showroom_details_min_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['String']>;
  showroom_context_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  views?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "Showroom_details" */
export type Showroom_Details_Mutation_Response = {
  __typename?: 'Showroom_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showroom_Details>;
};

/** input type for inserting object relation for remote table "Showroom_details" */
export type Showroom_Details_Obj_Rel_Insert_Input = {
  data: Showroom_Details_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Showroom_Details_On_Conflict>;
};

/** on_conflict condition type for table "Showroom_details" */
export type Showroom_Details_On_Conflict = {
  constraint: Showroom_Details_Constraint;
  update_columns?: Array<Showroom_Details_Update_Column>;
  where?: Maybe<Showroom_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "Showroom_details". */
export type Showroom_Details_Order_By = {
  collection?: Maybe<Collection_Order_By>;
  collection_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  creator_context?: Maybe<Context_Order_By>;
  creator_context_id?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  expiration_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_commercial?: Maybe<Order_By>;
  owner_password?: Maybe<Showroom_Owner_Password_Order_By>;
  password?: Maybe<Order_By>;
  showroom_context?: Maybe<Context_Order_By>;
  showroom_context_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  views?: Maybe<Order_By>;
};

/** primary key columns input for table: Showroom_details */
export type Showroom_Details_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Showroom_details" */
export enum Showroom_Details_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorContextId = 'creator_context_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  Password = 'password',
  /** column name */
  ShowroomContextId = 'showroom_context_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Views = 'views'
}

/** input type for updating data in table "Showroom_details" */
export type Showroom_Details_Set_Input = {
  collection_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_commercial?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  showroom_context_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  views?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Showroom_Details_Stddev_Fields = {
  __typename?: 'Showroom_details_stddev_fields';
  collection_id?: Maybe<Scalars['Float']>;
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  showroom_context_id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Showroom_Details_Stddev_Pop_Fields = {
  __typename?: 'Showroom_details_stddev_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  showroom_context_id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Showroom_Details_Stddev_Samp_Fields = {
  __typename?: 'Showroom_details_stddev_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  showroom_context_id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Showroom_Details_Sum_Fields = {
  __typename?: 'Showroom_details_sum_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  creator_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  showroom_context_id?: Maybe<Scalars['bigint']>;
  views?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Showroom_details" */
export enum Showroom_Details_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorContextId = 'creator_context_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsCommercial = 'is_commercial',
  /** column name */
  Password = 'password',
  /** column name */
  ShowroomContextId = 'showroom_context_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Views = 'views'
}

/** aggregate var_pop on columns */
export type Showroom_Details_Var_Pop_Fields = {
  __typename?: 'Showroom_details_var_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  showroom_context_id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Showroom_Details_Var_Samp_Fields = {
  __typename?: 'Showroom_details_var_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  showroom_context_id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Showroom_Details_Variance_Fields = {
  __typename?: 'Showroom_details_variance_fields';
  collection_id?: Maybe<Scalars['Float']>;
  creator_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  showroom_context_id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Social_Graph_Target_Context_view" */
export type Social_Graph_Target_Context_View = {
  __typename?: 'Social_Graph_Target_Context_view';
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_mutual?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  natural_order?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  target_context_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Social_Graph_Target_Context_view" */
export type Social_Graph_Target_Context_View_Aggregate = {
  __typename?: 'Social_Graph_Target_Context_view_aggregate';
  aggregate?: Maybe<Social_Graph_Target_Context_View_Aggregate_Fields>;
  nodes: Array<Social_Graph_Target_Context_View>;
};

/** aggregate fields of "Social_Graph_Target_Context_view" */
export type Social_Graph_Target_Context_View_Aggregate_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_aggregate_fields';
  avg?: Maybe<Social_Graph_Target_Context_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Social_Graph_Target_Context_View_Max_Fields>;
  min?: Maybe<Social_Graph_Target_Context_View_Min_Fields>;
  stddev?: Maybe<Social_Graph_Target_Context_View_Stddev_Fields>;
  stddev_pop?: Maybe<Social_Graph_Target_Context_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Social_Graph_Target_Context_View_Stddev_Samp_Fields>;
  sum?: Maybe<Social_Graph_Target_Context_View_Sum_Fields>;
  var_pop?: Maybe<Social_Graph_Target_Context_View_Var_Pop_Fields>;
  var_samp?: Maybe<Social_Graph_Target_Context_View_Var_Samp_Fields>;
  variance?: Maybe<Social_Graph_Target_Context_View_Variance_Fields>;
};


/** aggregate fields of "Social_Graph_Target_Context_view" */
export type Social_Graph_Target_Context_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Social_Graph_Target_Context_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Social_Graph_Target_Context_View_Avg_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_avg_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Social_Graph_Target_Context_view". All fields are combined with a logical 'AND'. */
export type Social_Graph_Target_Context_View_Bool_Exp = {
  _and?: Maybe<Array<Social_Graph_Target_Context_View_Bool_Exp>>;
  _not?: Maybe<Social_Graph_Target_Context_View_Bool_Exp>;
  _or?: Maybe<Array<Social_Graph_Target_Context_View_Bool_Exp>>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_mutual?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  natural_order?: Maybe<Bigint_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  selected_order?: Maybe<Bigint_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  target_context_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Social_Graph_Target_Context_View_Max_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_max_fields';
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  natural_order?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  target_context_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Social_Graph_Target_Context_View_Min_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_min_fields';
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  natural_order?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  target_context_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "Social_Graph_Target_Context_view". */
export type Social_Graph_Target_Context_View_Order_By = {
  avatar?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_mutual?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "Social_Graph_Target_Context_view" */
export enum Social_Graph_Target_Context_View_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  IsMutual = 'is_mutual',
  /** column name */
  LastName = 'last_name',
  /** column name */
  NaturalOrder = 'natural_order',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SelectedOrder = 'selected_order',
  /** column name */
  State = 'state',
  /** column name */
  TargetContextId = 'target_context_id',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Social_Graph_Target_Context_View_Stddev_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_stddev_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Social_Graph_Target_Context_View_Stddev_Pop_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_stddev_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Social_Graph_Target_Context_View_Stddev_Samp_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_stddev_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Social_Graph_Target_Context_View_Sum_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_sum_fields';
  avatar?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  natural_order?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  target_context_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Social_Graph_Target_Context_View_Var_Pop_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_var_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Social_Graph_Target_Context_View_Var_Samp_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_var_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Social_Graph_Target_Context_View_Variance_Fields = {
  __typename?: 'Social_Graph_Target_Context_view_variance_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** Stores interconnections between profiles */
export type Social_Graph_Followers = {
  __typename?: 'Social_graph_followers';
  /** An object relationship */
  Social_graph_followers_Context?: Maybe<Context>;
  /** An object relationship */
  Social_graph_followers_Target_Context?: Maybe<Context>;
  /** An object relationship */
  artist_explore_info?: Maybe<Artist_Explore_Info>;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_mutual: Scalars['Boolean'];
  natural_order: Scalars['bigint'];
  selected_order: Scalars['bigint'];
  state?: Maybe<Enum_Social_Graph_Followers_State_Enum>;
  target_context_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
  updated_state_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Social_graph_followers" */
export type Social_Graph_Followers_Aggregate = {
  __typename?: 'Social_graph_followers_aggregate';
  aggregate?: Maybe<Social_Graph_Followers_Aggregate_Fields>;
  nodes: Array<Social_Graph_Followers>;
};

/** aggregate fields of "Social_graph_followers" */
export type Social_Graph_Followers_Aggregate_Fields = {
  __typename?: 'Social_graph_followers_aggregate_fields';
  avg?: Maybe<Social_Graph_Followers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Social_Graph_Followers_Max_Fields>;
  min?: Maybe<Social_Graph_Followers_Min_Fields>;
  stddev?: Maybe<Social_Graph_Followers_Stddev_Fields>;
  stddev_pop?: Maybe<Social_Graph_Followers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Social_Graph_Followers_Stddev_Samp_Fields>;
  sum?: Maybe<Social_Graph_Followers_Sum_Fields>;
  var_pop?: Maybe<Social_Graph_Followers_Var_Pop_Fields>;
  var_samp?: Maybe<Social_Graph_Followers_Var_Samp_Fields>;
  variance?: Maybe<Social_Graph_Followers_Variance_Fields>;
};


/** aggregate fields of "Social_graph_followers" */
export type Social_Graph_Followers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Social_graph_followers" */
export type Social_Graph_Followers_Aggregate_Order_By = {
  avg?: Maybe<Social_Graph_Followers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Social_Graph_Followers_Max_Order_By>;
  min?: Maybe<Social_Graph_Followers_Min_Order_By>;
  stddev?: Maybe<Social_Graph_Followers_Stddev_Order_By>;
  stddev_pop?: Maybe<Social_Graph_Followers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Social_Graph_Followers_Stddev_Samp_Order_By>;
  sum?: Maybe<Social_Graph_Followers_Sum_Order_By>;
  var_pop?: Maybe<Social_Graph_Followers_Var_Pop_Order_By>;
  var_samp?: Maybe<Social_Graph_Followers_Var_Samp_Order_By>;
  variance?: Maybe<Social_Graph_Followers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Social_graph_followers" */
export type Social_Graph_Followers_Arr_Rel_Insert_Input = {
  data: Array<Social_Graph_Followers_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Social_Graph_Followers_On_Conflict>;
};

/** aggregate avg on columns */
export type Social_Graph_Followers_Avg_Fields = {
  __typename?: 'Social_graph_followers_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Social_graph_followers". All fields are combined with a logical 'AND'. */
export type Social_Graph_Followers_Bool_Exp = {
  Social_graph_followers_Context?: Maybe<Context_Bool_Exp>;
  Social_graph_followers_Target_Context?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<Social_Graph_Followers_Bool_Exp>>;
  _not?: Maybe<Social_Graph_Followers_Bool_Exp>;
  _or?: Maybe<Array<Social_Graph_Followers_Bool_Exp>>;
  artist_explore_info?: Maybe<Artist_Explore_Info_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_mutual?: Maybe<Boolean_Comparison_Exp>;
  natural_order?: Maybe<Bigint_Comparison_Exp>;
  selected_order?: Maybe<Bigint_Comparison_Exp>;
  state?: Maybe<Enum_Social_Graph_Followers_State_Enum_Comparison_Exp>;
  target_context_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_state_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Social_graph_followers" */
export enum Social_Graph_Followers_Constraint {
  /** unique or primary key constraint on columns "target_context_id", "context_id" */
  SocialGraphFollowersContextIdTargetContextIdKey = 'Social_graph_followers_context_id_target_context_id_key',
  /** unique or primary key constraint on columns "id" */
  SocialGraphFollowersPkey = 'Social_graph_followers_pkey'
}

/** input type for incrementing numeric columns in table "Social_graph_followers" */
export type Social_Graph_Followers_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  target_context_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Social_graph_followers" */
export type Social_Graph_Followers_Insert_Input = {
  Social_graph_followers_Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  Social_graph_followers_Target_Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  artist_explore_info?: Maybe<Artist_Explore_Info_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_mutual?: Maybe<Scalars['Boolean']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  state?: Maybe<Enum_Social_Graph_Followers_State_Enum>;
  target_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_state_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Social_Graph_Followers_Max_Fields = {
  __typename?: 'Social_graph_followers_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  target_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_state_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_state_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Social_Graph_Followers_Min_Fields = {
  __typename?: 'Social_graph_followers_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  target_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_state_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_state_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Social_graph_followers" */
export type Social_Graph_Followers_Mutation_Response = {
  __typename?: 'Social_graph_followers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Social_Graph_Followers>;
};

/** on_conflict condition type for table "Social_graph_followers" */
export type Social_Graph_Followers_On_Conflict = {
  constraint: Social_Graph_Followers_Constraint;
  update_columns?: Array<Social_Graph_Followers_Update_Column>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};

/** Ordering options when selecting data from "Social_graph_followers". */
export type Social_Graph_Followers_Order_By = {
  Social_graph_followers_Context?: Maybe<Context_Order_By>;
  Social_graph_followers_Target_Context?: Maybe<Context_Order_By>;
  artist_explore_info?: Maybe<Artist_Explore_Info_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_mutual?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_state_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Social_graph_followers */
export type Social_Graph_Followers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Social_graph_followers" */
export enum Social_Graph_Followers_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsMutual = 'is_mutual',
  /** column name */
  NaturalOrder = 'natural_order',
  /** column name */
  SelectedOrder = 'selected_order',
  /** column name */
  State = 'state',
  /** column name */
  TargetContextId = 'target_context_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedStateAt = 'updated_state_at'
}

/** input type for updating data in table "Social_graph_followers" */
export type Social_Graph_Followers_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_mutual?: Maybe<Scalars['Boolean']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  state?: Maybe<Enum_Social_Graph_Followers_State_Enum>;
  target_context_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_state_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Social_Graph_Followers_Stddev_Fields = {
  __typename?: 'Social_graph_followers_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Social_Graph_Followers_Stddev_Pop_Fields = {
  __typename?: 'Social_graph_followers_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Social_Graph_Followers_Stddev_Samp_Fields = {
  __typename?: 'Social_graph_followers_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Social_Graph_Followers_Sum_Fields = {
  __typename?: 'Social_graph_followers_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  target_context_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
};

/** update columns of table "Social_graph_followers" */
export enum Social_Graph_Followers_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsMutual = 'is_mutual',
  /** column name */
  NaturalOrder = 'natural_order',
  /** column name */
  SelectedOrder = 'selected_order',
  /** column name */
  State = 'state',
  /** column name */
  TargetContextId = 'target_context_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedStateAt = 'updated_state_at'
}

/** aggregate var_pop on columns */
export type Social_Graph_Followers_Var_Pop_Fields = {
  __typename?: 'Social_graph_followers_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Social_Graph_Followers_Var_Samp_Fields = {
  __typename?: 'Social_graph_followers_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Social_Graph_Followers_Variance_Fields = {
  __typename?: 'Social_graph_followers_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Social_graph_followers" */
export type Social_Graph_Followers_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
};

/** columns and relationships of "Social_graph_followers_view" */
export type Social_Graph_Followers_View = {
  __typename?: 'Social_graph_followers_view';
  context_id?: Maybe<Scalars['bigint']>;
  context_profile_avatar?: Maybe<Scalars['bigint']>;
  context_profile_first_name?: Maybe<Scalars['String']>;
  context_profile_handle?: Maybe<Scalars['String']>;
  context_profile_id?: Maybe<Scalars['bigint']>;
  context_profile_is_private?: Maybe<Scalars['Boolean']>;
  context_profile_is_public?: Maybe<Scalars['String']>;
  context_profile_last_name?: Maybe<Scalars['String']>;
  context_profile_title?: Maybe<Scalars['String']>;
  context_profile_type?: Maybe<Scalars['String']>;
  context_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_mutual?: Maybe<Scalars['Boolean']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  target_context_id?: Maybe<Scalars['bigint']>;
  target_context_type?: Maybe<Scalars['String']>;
  target_profile_avatar?: Maybe<Scalars['bigint']>;
  target_profile_first_name?: Maybe<Scalars['String']>;
  target_profile_handle?: Maybe<Scalars['String']>;
  target_profile_id?: Maybe<Scalars['bigint']>;
  target_profile_is_public?: Maybe<Scalars['String']>;
  target_profile_last_name?: Maybe<Scalars['String']>;
  target_profile_title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "Social_graph_followers_view" */
export type Social_Graph_Followers_View_Aggregate = {
  __typename?: 'Social_graph_followers_view_aggregate';
  aggregate?: Maybe<Social_Graph_Followers_View_Aggregate_Fields>;
  nodes: Array<Social_Graph_Followers_View>;
};

/** aggregate fields of "Social_graph_followers_view" */
export type Social_Graph_Followers_View_Aggregate_Fields = {
  __typename?: 'Social_graph_followers_view_aggregate_fields';
  avg?: Maybe<Social_Graph_Followers_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Social_Graph_Followers_View_Max_Fields>;
  min?: Maybe<Social_Graph_Followers_View_Min_Fields>;
  stddev?: Maybe<Social_Graph_Followers_View_Stddev_Fields>;
  stddev_pop?: Maybe<Social_Graph_Followers_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Social_Graph_Followers_View_Stddev_Samp_Fields>;
  sum?: Maybe<Social_Graph_Followers_View_Sum_Fields>;
  var_pop?: Maybe<Social_Graph_Followers_View_Var_Pop_Fields>;
  var_samp?: Maybe<Social_Graph_Followers_View_Var_Samp_Fields>;
  variance?: Maybe<Social_Graph_Followers_View_Variance_Fields>;
};


/** aggregate fields of "Social_graph_followers_view" */
export type Social_Graph_Followers_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Social_Graph_Followers_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Social_Graph_Followers_View_Avg_Fields = {
  __typename?: 'Social_graph_followers_view_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  context_profile_avatar?: Maybe<Scalars['Float']>;
  context_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
  target_profile_avatar?: Maybe<Scalars['Float']>;
  target_profile_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Social_graph_followers_view". All fields are combined with a logical 'AND'. */
export type Social_Graph_Followers_View_Bool_Exp = {
  _and?: Maybe<Array<Social_Graph_Followers_View_Bool_Exp>>;
  _not?: Maybe<Social_Graph_Followers_View_Bool_Exp>;
  _or?: Maybe<Array<Social_Graph_Followers_View_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  context_profile_avatar?: Maybe<Bigint_Comparison_Exp>;
  context_profile_first_name?: Maybe<String_Comparison_Exp>;
  context_profile_handle?: Maybe<String_Comparison_Exp>;
  context_profile_id?: Maybe<Bigint_Comparison_Exp>;
  context_profile_is_private?: Maybe<Boolean_Comparison_Exp>;
  context_profile_is_public?: Maybe<String_Comparison_Exp>;
  context_profile_last_name?: Maybe<String_Comparison_Exp>;
  context_profile_title?: Maybe<String_Comparison_Exp>;
  context_profile_type?: Maybe<String_Comparison_Exp>;
  context_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_mutual?: Maybe<Boolean_Comparison_Exp>;
  natural_order?: Maybe<Bigint_Comparison_Exp>;
  selected_order?: Maybe<Bigint_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  target_context_id?: Maybe<Bigint_Comparison_Exp>;
  target_context_type?: Maybe<String_Comparison_Exp>;
  target_profile_avatar?: Maybe<Bigint_Comparison_Exp>;
  target_profile_first_name?: Maybe<String_Comparison_Exp>;
  target_profile_handle?: Maybe<String_Comparison_Exp>;
  target_profile_id?: Maybe<Bigint_Comparison_Exp>;
  target_profile_is_public?: Maybe<String_Comparison_Exp>;
  target_profile_last_name?: Maybe<String_Comparison_Exp>;
  target_profile_title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Social_Graph_Followers_View_Max_Fields = {
  __typename?: 'Social_graph_followers_view_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  context_profile_avatar?: Maybe<Scalars['bigint']>;
  context_profile_first_name?: Maybe<Scalars['String']>;
  context_profile_handle?: Maybe<Scalars['String']>;
  context_profile_id?: Maybe<Scalars['bigint']>;
  context_profile_is_public?: Maybe<Scalars['String']>;
  context_profile_last_name?: Maybe<Scalars['String']>;
  context_profile_title?: Maybe<Scalars['String']>;
  context_profile_type?: Maybe<Scalars['String']>;
  context_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  target_context_id?: Maybe<Scalars['bigint']>;
  target_context_type?: Maybe<Scalars['String']>;
  target_profile_avatar?: Maybe<Scalars['bigint']>;
  target_profile_first_name?: Maybe<Scalars['String']>;
  target_profile_handle?: Maybe<Scalars['String']>;
  target_profile_id?: Maybe<Scalars['bigint']>;
  target_profile_is_public?: Maybe<Scalars['String']>;
  target_profile_last_name?: Maybe<Scalars['String']>;
  target_profile_title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Social_Graph_Followers_View_Min_Fields = {
  __typename?: 'Social_graph_followers_view_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  context_profile_avatar?: Maybe<Scalars['bigint']>;
  context_profile_first_name?: Maybe<Scalars['String']>;
  context_profile_handle?: Maybe<Scalars['String']>;
  context_profile_id?: Maybe<Scalars['bigint']>;
  context_profile_is_public?: Maybe<Scalars['String']>;
  context_profile_last_name?: Maybe<Scalars['String']>;
  context_profile_title?: Maybe<Scalars['String']>;
  context_profile_type?: Maybe<Scalars['String']>;
  context_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  target_context_id?: Maybe<Scalars['bigint']>;
  target_context_type?: Maybe<Scalars['String']>;
  target_profile_avatar?: Maybe<Scalars['bigint']>;
  target_profile_first_name?: Maybe<Scalars['String']>;
  target_profile_handle?: Maybe<Scalars['String']>;
  target_profile_id?: Maybe<Scalars['bigint']>;
  target_profile_is_public?: Maybe<Scalars['String']>;
  target_profile_last_name?: Maybe<Scalars['String']>;
  target_profile_title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "Social_graph_followers_view". */
export type Social_Graph_Followers_View_Order_By = {
  context_id?: Maybe<Order_By>;
  context_profile_avatar?: Maybe<Order_By>;
  context_profile_first_name?: Maybe<Order_By>;
  context_profile_handle?: Maybe<Order_By>;
  context_profile_id?: Maybe<Order_By>;
  context_profile_is_private?: Maybe<Order_By>;
  context_profile_is_public?: Maybe<Order_By>;
  context_profile_last_name?: Maybe<Order_By>;
  context_profile_title?: Maybe<Order_By>;
  context_profile_type?: Maybe<Order_By>;
  context_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_mutual?: Maybe<Order_By>;
  natural_order?: Maybe<Order_By>;
  selected_order?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  target_context_id?: Maybe<Order_By>;
  target_context_type?: Maybe<Order_By>;
  target_profile_avatar?: Maybe<Order_By>;
  target_profile_first_name?: Maybe<Order_By>;
  target_profile_handle?: Maybe<Order_By>;
  target_profile_id?: Maybe<Order_By>;
  target_profile_is_public?: Maybe<Order_By>;
  target_profile_last_name?: Maybe<Order_By>;
  target_profile_title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "Social_graph_followers_view" */
export enum Social_Graph_Followers_View_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  ContextProfileAvatar = 'context_profile_avatar',
  /** column name */
  ContextProfileFirstName = 'context_profile_first_name',
  /** column name */
  ContextProfileHandle = 'context_profile_handle',
  /** column name */
  ContextProfileId = 'context_profile_id',
  /** column name */
  ContextProfileIsPrivate = 'context_profile_is_private',
  /** column name */
  ContextProfileIsPublic = 'context_profile_is_public',
  /** column name */
  ContextProfileLastName = 'context_profile_last_name',
  /** column name */
  ContextProfileTitle = 'context_profile_title',
  /** column name */
  ContextProfileType = 'context_profile_type',
  /** column name */
  ContextType = 'context_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsMutual = 'is_mutual',
  /** column name */
  NaturalOrder = 'natural_order',
  /** column name */
  SelectedOrder = 'selected_order',
  /** column name */
  State = 'state',
  /** column name */
  TargetContextId = 'target_context_id',
  /** column name */
  TargetContextType = 'target_context_type',
  /** column name */
  TargetProfileAvatar = 'target_profile_avatar',
  /** column name */
  TargetProfileFirstName = 'target_profile_first_name',
  /** column name */
  TargetProfileHandle = 'target_profile_handle',
  /** column name */
  TargetProfileId = 'target_profile_id',
  /** column name */
  TargetProfileIsPublic = 'target_profile_is_public',
  /** column name */
  TargetProfileLastName = 'target_profile_last_name',
  /** column name */
  TargetProfileTitle = 'target_profile_title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Social_Graph_Followers_View_Stddev_Fields = {
  __typename?: 'Social_graph_followers_view_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  context_profile_avatar?: Maybe<Scalars['Float']>;
  context_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
  target_profile_avatar?: Maybe<Scalars['Float']>;
  target_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Social_Graph_Followers_View_Stddev_Pop_Fields = {
  __typename?: 'Social_graph_followers_view_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  context_profile_avatar?: Maybe<Scalars['Float']>;
  context_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
  target_profile_avatar?: Maybe<Scalars['Float']>;
  target_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Social_Graph_Followers_View_Stddev_Samp_Fields = {
  __typename?: 'Social_graph_followers_view_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  context_profile_avatar?: Maybe<Scalars['Float']>;
  context_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
  target_profile_avatar?: Maybe<Scalars['Float']>;
  target_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Social_Graph_Followers_View_Sum_Fields = {
  __typename?: 'Social_graph_followers_view_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  context_profile_avatar?: Maybe<Scalars['bigint']>;
  context_profile_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  natural_order?: Maybe<Scalars['bigint']>;
  selected_order?: Maybe<Scalars['bigint']>;
  target_context_id?: Maybe<Scalars['bigint']>;
  target_profile_avatar?: Maybe<Scalars['bigint']>;
  target_profile_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Social_Graph_Followers_View_Var_Pop_Fields = {
  __typename?: 'Social_graph_followers_view_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  context_profile_avatar?: Maybe<Scalars['Float']>;
  context_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
  target_profile_avatar?: Maybe<Scalars['Float']>;
  target_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Social_Graph_Followers_View_Var_Samp_Fields = {
  __typename?: 'Social_graph_followers_view_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  context_profile_avatar?: Maybe<Scalars['Float']>;
  context_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
  target_profile_avatar?: Maybe<Scalars['Float']>;
  target_profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Social_Graph_Followers_View_Variance_Fields = {
  __typename?: 'Social_graph_followers_view_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  context_profile_avatar?: Maybe<Scalars['Float']>;
  context_profile_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  natural_order?: Maybe<Scalars['Float']>;
  selected_order?: Maybe<Scalars['Float']>;
  target_context_id?: Maybe<Scalars['Float']>;
  target_profile_avatar?: Maybe<Scalars['Float']>;
  target_profile_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Spotlight_artist" */
export type Spotlight_Artist = {
  __typename?: 'Spotlight_artist';
  avatar?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  cardgrid_artworks: Array<Artist_Cardgrid_Artworks_View>;
  /** An aggregate relationship */
  cardgrid_artworks_aggregate: Artist_Cardgrid_Artworks_View_Aggregate;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  handle?: Maybe<Scalars['String']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
  is_showing_now?: Maybe<Scalars['Boolean']>;
  profile_id?: Maybe<Scalars['bigint']>;
  spotlight_id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Spotlight_artist" */
export type Spotlight_ArtistCardgrid_ArtworksArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


/** columns and relationships of "Spotlight_artist" */
export type Spotlight_ArtistCardgrid_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};

/** aggregated selection of "Spotlight_artist" */
export type Spotlight_Artist_Aggregate = {
  __typename?: 'Spotlight_artist_aggregate';
  aggregate?: Maybe<Spotlight_Artist_Aggregate_Fields>;
  nodes: Array<Spotlight_Artist>;
};

/** aggregate fields of "Spotlight_artist" */
export type Spotlight_Artist_Aggregate_Fields = {
  __typename?: 'Spotlight_artist_aggregate_fields';
  avg?: Maybe<Spotlight_Artist_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Spotlight_Artist_Max_Fields>;
  min?: Maybe<Spotlight_Artist_Min_Fields>;
  stddev?: Maybe<Spotlight_Artist_Stddev_Fields>;
  stddev_pop?: Maybe<Spotlight_Artist_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spotlight_Artist_Stddev_Samp_Fields>;
  sum?: Maybe<Spotlight_Artist_Sum_Fields>;
  var_pop?: Maybe<Spotlight_Artist_Var_Pop_Fields>;
  var_samp?: Maybe<Spotlight_Artist_Var_Samp_Fields>;
  variance?: Maybe<Spotlight_Artist_Variance_Fields>;
};


/** aggregate fields of "Spotlight_artist" */
export type Spotlight_Artist_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Spotlight_Artist_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spotlight_Artist_Avg_Fields = {
  __typename?: 'Spotlight_artist_avg_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  spotlight_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Spotlight_artist". All fields are combined with a logical 'AND'. */
export type Spotlight_Artist_Bool_Exp = {
  _and?: Maybe<Array<Spotlight_Artist_Bool_Exp>>;
  _not?: Maybe<Spotlight_Artist_Bool_Exp>;
  _or?: Maybe<Array<Spotlight_Artist_Bool_Exp>>;
  avatar?: Maybe<Bigint_Comparison_Exp>;
  cardgrid_artworks?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  end_at?: Maybe<Timestamptz_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  is_enabled?: Maybe<Boolean_Comparison_Exp>;
  is_showing_now?: Maybe<Boolean_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  spotlight_id?: Maybe<Bigint_Comparison_Exp>;
  start_at?: Maybe<Timestamptz_Comparison_Exp>;
  statement?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Spotlight_Artist_Max_Fields = {
  __typename?: 'Spotlight_artist_max_fields';
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  handle?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  spotlight_id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Spotlight_Artist_Min_Fields = {
  __typename?: 'Spotlight_artist_min_fields';
  avatar?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  handle?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  spotlight_id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Spotlight_artist". */
export type Spotlight_Artist_Order_By = {
  avatar?: Maybe<Order_By>;
  cardgrid_artworks_aggregate?: Maybe<Artist_Cardgrid_Artworks_View_Aggregate_Order_By>;
  city?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  end_at?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  is_enabled?: Maybe<Order_By>;
  is_showing_now?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  spotlight_id?: Maybe<Order_By>;
  start_at?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** select columns of table "Spotlight_artist" */
export enum Spotlight_Artist_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  City = 'city',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Country = 'country',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  Handle = 'handle',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsShowingNow = 'is_showing_now',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  SpotlightId = 'spotlight_id',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Statement = 'statement',
  /** column name */
  Title = 'title'
}

/** aggregate stddev on columns */
export type Spotlight_Artist_Stddev_Fields = {
  __typename?: 'Spotlight_artist_stddev_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  spotlight_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spotlight_Artist_Stddev_Pop_Fields = {
  __typename?: 'Spotlight_artist_stddev_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  spotlight_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spotlight_Artist_Stddev_Samp_Fields = {
  __typename?: 'Spotlight_artist_stddev_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  spotlight_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Spotlight_Artist_Sum_Fields = {
  __typename?: 'Spotlight_artist_sum_fields';
  avatar?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  spotlight_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Spotlight_Artist_Var_Pop_Fields = {
  __typename?: 'Spotlight_artist_var_pop_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  spotlight_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spotlight_Artist_Var_Samp_Fields = {
  __typename?: 'Spotlight_artist_var_samp_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  spotlight_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spotlight_Artist_Variance_Fields = {
  __typename?: 'Spotlight_artist_variance_fields';
  avatar?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
  spotlight_id?: Maybe<Scalars['Float']>;
};

/** profile contexts featured on the home page */
export type Spotlight_Context = {
  __typename?: 'Spotlight_context';
  /** An object relationship */
  Context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  end_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  is_enabled: Scalars['Boolean'];
  start_at: Scalars['timestamptz'];
  statement?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Spotlight_context" */
export type Spotlight_Context_Aggregate = {
  __typename?: 'Spotlight_context_aggregate';
  aggregate?: Maybe<Spotlight_Context_Aggregate_Fields>;
  nodes: Array<Spotlight_Context>;
};

/** aggregate fields of "Spotlight_context" */
export type Spotlight_Context_Aggregate_Fields = {
  __typename?: 'Spotlight_context_aggregate_fields';
  avg?: Maybe<Spotlight_Context_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Spotlight_Context_Max_Fields>;
  min?: Maybe<Spotlight_Context_Min_Fields>;
  stddev?: Maybe<Spotlight_Context_Stddev_Fields>;
  stddev_pop?: Maybe<Spotlight_Context_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spotlight_Context_Stddev_Samp_Fields>;
  sum?: Maybe<Spotlight_Context_Sum_Fields>;
  var_pop?: Maybe<Spotlight_Context_Var_Pop_Fields>;
  var_samp?: Maybe<Spotlight_Context_Var_Samp_Fields>;
  variance?: Maybe<Spotlight_Context_Variance_Fields>;
};


/** aggregate fields of "Spotlight_context" */
export type Spotlight_Context_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Spotlight_Context_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Spotlight_Context_Avg_Fields = {
  __typename?: 'Spotlight_context_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Spotlight_context". All fields are combined with a logical 'AND'. */
export type Spotlight_Context_Bool_Exp = {
  Context?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<Spotlight_Context_Bool_Exp>>;
  _not?: Maybe<Spotlight_Context_Bool_Exp>;
  _or?: Maybe<Array<Spotlight_Context_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  end_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_enabled?: Maybe<Boolean_Comparison_Exp>;
  start_at?: Maybe<Timestamptz_Comparison_Exp>;
  statement?: Maybe<String_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Spotlight_context" */
export enum Spotlight_Context_Constraint {
  /** unique or primary key constraint on columns "id" */
  SpotlightContextPkey = 'Spotlight_context_pkey'
}

/** input type for incrementing numeric columns in table "Spotlight_context" */
export type Spotlight_Context_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Spotlight_context" */
export type Spotlight_Context_Insert_Input = {
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Spotlight_Context_Max_Fields = {
  __typename?: 'Spotlight_context_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Spotlight_Context_Min_Fields = {
  __typename?: 'Spotlight_context_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Spotlight_context" */
export type Spotlight_Context_Mutation_Response = {
  __typename?: 'Spotlight_context_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Spotlight_Context>;
};

/** on_conflict condition type for table "Spotlight_context" */
export type Spotlight_Context_On_Conflict = {
  constraint: Spotlight_Context_Constraint;
  update_columns?: Array<Spotlight_Context_Update_Column>;
  where?: Maybe<Spotlight_Context_Bool_Exp>;
};

/** Ordering options when selecting data from "Spotlight_context". */
export type Spotlight_Context_Order_By = {
  Context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_enabled?: Maybe<Order_By>;
  start_at?: Maybe<Order_By>;
  statement?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Spotlight_context */
export type Spotlight_Context_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Spotlight_context" */
export enum Spotlight_Context_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Statement = 'statement',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Spotlight_context" */
export type Spotlight_Context_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  is_enabled?: Maybe<Scalars['Boolean']>;
  start_at?: Maybe<Scalars['timestamptz']>;
  statement?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Spotlight_Context_Stddev_Fields = {
  __typename?: 'Spotlight_context_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Spotlight_Context_Stddev_Pop_Fields = {
  __typename?: 'Spotlight_context_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Spotlight_Context_Stddev_Samp_Fields = {
  __typename?: 'Spotlight_context_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Spotlight_Context_Sum_Fields = {
  __typename?: 'Spotlight_context_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Spotlight_context" */
export enum Spotlight_Context_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  StartAt = 'start_at',
  /** column name */
  Statement = 'statement',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Spotlight_Context_Var_Pop_Fields = {
  __typename?: 'Spotlight_context_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Spotlight_Context_Var_Samp_Fields = {
  __typename?: 'Spotlight_context_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Spotlight_Context_Variance_Fields = {
  __typename?: 'Spotlight_context_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type StatusAndErrorResult = {
  __typename?: 'StatusAndErrorResult';
  error?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type StepTwoType = {
  __typename?: 'StepTwoType';
  email?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  id: Scalars['String'];
  nextInvoiceDate: Scalars['timestamptz'];
  products: Array<StripeSubscriptionProduct>;
  /** Enum_Billing_Subscription_Status_Enum */
  status?: Maybe<Scalars['String']>;
};

export type StripeSubscriptionProduct = {
  __typename?: 'StripeSubscriptionProduct';
  price?: Maybe<StripePrice>;
  /** Enum_billing_subscription_product_type */
  type: Scalars['String'];
};

export enum SubscriptionPeriod {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type SubscriptionProductDetails = {
  __typename?: 'SubscriptionProductDetails';
  id: Scalars['bigint'];
  price?: Maybe<SubscriptionProductPriceDetails>;
  subscription?: Maybe<SubscriptionProductSubscriptionDetails>;
  type: Scalars['String'];
};

export type SubscriptionProductPriceDetails = {
  __typename?: 'SubscriptionProductPriceDetails';
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type SubscriptionProductSubscriptionDetails = {
  __typename?: 'SubscriptionProductSubscriptionDetails';
  id: Scalars['bigint'];
  nextInvoiceDate: Scalars['timestamptz'];
  recurringInterval: Scalars['String'];
  /** Enum_Billing_Subscription_Status_Enum */
  status: Scalars['String'];
};

export enum SubscriptionType {
  MystudioPremium = 'MYSTUDIO_PREMIUM',
  MystudioPro = 'MYSTUDIO_PRO'
}

export type SuggestedTagsResultType = {
  __typename?: 'SuggestedTagsResultType';
  primarySuggestedTags?: Maybe<Array<SuggestedTagsType>>;
  secondarySuggestedTags?: Maybe<Array<SuggestedTagsType>>;
};

export type SuggestedTagsType = {
  __typename?: 'SuggestedTagsType';
  description: Scalars['String'];
  order: Scalars['Int'];
  value: Scalars['String'];
};

/** columns and relationships of "Super_site_subscribers" */
export type Super_Site_Subscribers = {
  __typename?: 'Super_site_subscribers';
  artist_handle?: Maybe<Scalars['String']>;
  billing_subscription_id?: Maybe<Scalars['bigint']>;
  billing_subscription_period?: Maybe<Scalars['String']>;
  billing_subscription_status?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  context_id: Scalars['bigint'];
  country?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  subscription_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Super_site_subscribers" */
export type Super_Site_Subscribers_Aggregate = {
  __typename?: 'Super_site_subscribers_aggregate';
  aggregate?: Maybe<Super_Site_Subscribers_Aggregate_Fields>;
  nodes: Array<Super_Site_Subscribers>;
};

/** aggregate fields of "Super_site_subscribers" */
export type Super_Site_Subscribers_Aggregate_Fields = {
  __typename?: 'Super_site_subscribers_aggregate_fields';
  avg?: Maybe<Super_Site_Subscribers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Super_Site_Subscribers_Max_Fields>;
  min?: Maybe<Super_Site_Subscribers_Min_Fields>;
  stddev?: Maybe<Super_Site_Subscribers_Stddev_Fields>;
  stddev_pop?: Maybe<Super_Site_Subscribers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Super_Site_Subscribers_Stddev_Samp_Fields>;
  sum?: Maybe<Super_Site_Subscribers_Sum_Fields>;
  var_pop?: Maybe<Super_Site_Subscribers_Var_Pop_Fields>;
  var_samp?: Maybe<Super_Site_Subscribers_Var_Samp_Fields>;
  variance?: Maybe<Super_Site_Subscribers_Variance_Fields>;
};


/** aggregate fields of "Super_site_subscribers" */
export type Super_Site_Subscribers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Super_Site_Subscribers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Super_Site_Subscribers_Avg_Fields = {
  __typename?: 'Super_site_subscribers_avg_fields';
  billing_subscription_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Super_site_subscribers". All fields are combined with a logical 'AND'. */
export type Super_Site_Subscribers_Bool_Exp = {
  _and?: Maybe<Array<Super_Site_Subscribers_Bool_Exp>>;
  _not?: Maybe<Super_Site_Subscribers_Bool_Exp>;
  _or?: Maybe<Array<Super_Site_Subscribers_Bool_Exp>>;
  artist_handle?: Maybe<String_Comparison_Exp>;
  billing_subscription_id?: Maybe<Bigint_Comparison_Exp>;
  billing_subscription_period?: Maybe<String_Comparison_Exp>;
  billing_subscription_status?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  domain_name?: Maybe<String_Comparison_Exp>;
  domain_state?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  last_billed_at?: Maybe<Timestamptz_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  next_billing_date?: Maybe<Timestamptz_Comparison_Exp>;
  preferred_language?: Maybe<String_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  subscription_start_date?: Maybe<Timestamptz_Comparison_Exp>;
  subscription_type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Super_site_subscribers" */
export enum Super_Site_Subscribers_Constraint {
  /** unique or primary key constraint on columns "context_id" */
  SuperSiteSubscribersPkey = 'Super_site_subscribers_pkey'
}

/** input type for incrementing numeric columns in table "Super_site_subscribers" */
export type Super_Site_Subscribers_Inc_Input = {
  billing_subscription_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Super_site_subscribers" */
export type Super_Site_Subscribers_Insert_Input = {
  artist_handle?: Maybe<Scalars['String']>;
  billing_subscription_id?: Maybe<Scalars['bigint']>;
  billing_subscription_period?: Maybe<Scalars['String']>;
  billing_subscription_status?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  subscription_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Super_Site_Subscribers_Max_Fields = {
  __typename?: 'Super_site_subscribers_max_fields';
  artist_handle?: Maybe<Scalars['String']>;
  billing_subscription_id?: Maybe<Scalars['bigint']>;
  billing_subscription_period?: Maybe<Scalars['String']>;
  billing_subscription_status?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  subscription_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Super_Site_Subscribers_Min_Fields = {
  __typename?: 'Super_site_subscribers_min_fields';
  artist_handle?: Maybe<Scalars['String']>;
  billing_subscription_id?: Maybe<Scalars['bigint']>;
  billing_subscription_period?: Maybe<Scalars['String']>;
  billing_subscription_status?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  subscription_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Super_site_subscribers" */
export type Super_Site_Subscribers_Mutation_Response = {
  __typename?: 'Super_site_subscribers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Super_Site_Subscribers>;
};

/** on_conflict condition type for table "Super_site_subscribers" */
export type Super_Site_Subscribers_On_Conflict = {
  constraint: Super_Site_Subscribers_Constraint;
  update_columns?: Array<Super_Site_Subscribers_Update_Column>;
  where?: Maybe<Super_Site_Subscribers_Bool_Exp>;
};

/** Ordering options when selecting data from "Super_site_subscribers". */
export type Super_Site_Subscribers_Order_By = {
  artist_handle?: Maybe<Order_By>;
  billing_subscription_id?: Maybe<Order_By>;
  billing_subscription_period?: Maybe<Order_By>;
  billing_subscription_status?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  domain_name?: Maybe<Order_By>;
  domain_state?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  last_billed_at?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  next_billing_date?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  subscription_start_date?: Maybe<Order_By>;
  subscription_type?: Maybe<Order_By>;
};

/** primary key columns input for table: Super_site_subscribers */
export type Super_Site_Subscribers_Pk_Columns_Input = {
  context_id: Scalars['bigint'];
};

/** select columns of table "Super_site_subscribers" */
export enum Super_Site_Subscribers_Select_Column {
  /** column name */
  ArtistHandle = 'artist_handle',
  /** column name */
  BillingSubscriptionId = 'billing_subscription_id',
  /** column name */
  BillingSubscriptionPeriod = 'billing_subscription_period',
  /** column name */
  BillingSubscriptionStatus = 'billing_subscription_status',
  /** column name */
  City = 'city',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Country = 'country',
  /** column name */
  DomainName = 'domain_name',
  /** column name */
  DomainState = 'domain_state',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastBilledAt = 'last_billed_at',
  /** column name */
  LastName = 'last_name',
  /** column name */
  NextBillingDate = 'next_billing_date',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Region = 'region',
  /** column name */
  SubscriptionStartDate = 'subscription_start_date',
  /** column name */
  SubscriptionType = 'subscription_type'
}

/** input type for updating data in table "Super_site_subscribers" */
export type Super_Site_Subscribers_Set_Input = {
  artist_handle?: Maybe<Scalars['String']>;
  billing_subscription_id?: Maybe<Scalars['bigint']>;
  billing_subscription_period?: Maybe<Scalars['String']>;
  billing_subscription_status?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country?: Maybe<Scalars['String']>;
  domain_name?: Maybe<Scalars['String']>;
  domain_state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_billed_at?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  next_billing_date?: Maybe<Scalars['timestamptz']>;
  preferred_language?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  region?: Maybe<Scalars['String']>;
  subscription_start_date?: Maybe<Scalars['timestamptz']>;
  subscription_type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Super_Site_Subscribers_Stddev_Fields = {
  __typename?: 'Super_site_subscribers_stddev_fields';
  billing_subscription_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Super_Site_Subscribers_Stddev_Pop_Fields = {
  __typename?: 'Super_site_subscribers_stddev_pop_fields';
  billing_subscription_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Super_Site_Subscribers_Stddev_Samp_Fields = {
  __typename?: 'Super_site_subscribers_stddev_samp_fields';
  billing_subscription_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Super_Site_Subscribers_Sum_Fields = {
  __typename?: 'Super_site_subscribers_sum_fields';
  billing_subscription_id?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Super_site_subscribers" */
export enum Super_Site_Subscribers_Update_Column {
  /** column name */
  ArtistHandle = 'artist_handle',
  /** column name */
  BillingSubscriptionId = 'billing_subscription_id',
  /** column name */
  BillingSubscriptionPeriod = 'billing_subscription_period',
  /** column name */
  BillingSubscriptionStatus = 'billing_subscription_status',
  /** column name */
  City = 'city',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Country = 'country',
  /** column name */
  DomainName = 'domain_name',
  /** column name */
  DomainState = 'domain_state',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastBilledAt = 'last_billed_at',
  /** column name */
  LastName = 'last_name',
  /** column name */
  NextBillingDate = 'next_billing_date',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Region = 'region',
  /** column name */
  SubscriptionStartDate = 'subscription_start_date',
  /** column name */
  SubscriptionType = 'subscription_type'
}

/** aggregate var_pop on columns */
export type Super_Site_Subscribers_Var_Pop_Fields = {
  __typename?: 'Super_site_subscribers_var_pop_fields';
  billing_subscription_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Super_Site_Subscribers_Var_Samp_Fields = {
  __typename?: 'Super_site_subscribers_var_samp_fields';
  billing_subscription_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Super_Site_Subscribers_Variance_Fields = {
  __typename?: 'Super_site_subscribers_variance_fields';
  billing_subscription_id?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** Table with the mapping of the suggested tags to return by interest */
export type Tags_By_Interest = {
  __typename?: 'Tags_by_interest';
  /** An object relationship */
  Artwork_tag: Artwork_Tag;
  /** An object relationship */
  Interest: Interests;
  id: Scalars['bigint'];
  interest_id: Scalars['Int'];
  interest_type: Scalars['String'];
  tag_id: Scalars['bigint'];
  tag_type: Scalars['String'];
};

/** aggregated selection of "Tags_by_interest" */
export type Tags_By_Interest_Aggregate = {
  __typename?: 'Tags_by_interest_aggregate';
  aggregate?: Maybe<Tags_By_Interest_Aggregate_Fields>;
  nodes: Array<Tags_By_Interest>;
};

/** aggregate fields of "Tags_by_interest" */
export type Tags_By_Interest_Aggregate_Fields = {
  __typename?: 'Tags_by_interest_aggregate_fields';
  avg?: Maybe<Tags_By_Interest_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tags_By_Interest_Max_Fields>;
  min?: Maybe<Tags_By_Interest_Min_Fields>;
  stddev?: Maybe<Tags_By_Interest_Stddev_Fields>;
  stddev_pop?: Maybe<Tags_By_Interest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tags_By_Interest_Stddev_Samp_Fields>;
  sum?: Maybe<Tags_By_Interest_Sum_Fields>;
  var_pop?: Maybe<Tags_By_Interest_Var_Pop_Fields>;
  var_samp?: Maybe<Tags_By_Interest_Var_Samp_Fields>;
  variance?: Maybe<Tags_By_Interest_Variance_Fields>;
};


/** aggregate fields of "Tags_by_interest" */
export type Tags_By_Interest_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tags_By_Interest_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tags_By_Interest_Avg_Fields = {
  __typename?: 'Tags_by_interest_avg_fields';
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Tags_by_interest". All fields are combined with a logical 'AND'. */
export type Tags_By_Interest_Bool_Exp = {
  Artwork_tag?: Maybe<Artwork_Tag_Bool_Exp>;
  Interest?: Maybe<Interests_Bool_Exp>;
  _and?: Maybe<Array<Tags_By_Interest_Bool_Exp>>;
  _not?: Maybe<Tags_By_Interest_Bool_Exp>;
  _or?: Maybe<Array<Tags_By_Interest_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  interest_id?: Maybe<Int_Comparison_Exp>;
  interest_type?: Maybe<String_Comparison_Exp>;
  tag_id?: Maybe<Bigint_Comparison_Exp>;
  tag_type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tags_by_interest" */
export enum Tags_By_Interest_Constraint {
  /** unique or primary key constraint on columns "interest_id", "interest_type", "tag_id" */
  TagsByInterestInterestTypeInterestIdTagIdKey = 'Tags_by_interest_interest_type_interest_id_tag_id_key',
  /** unique or primary key constraint on columns "id" */
  TagsByInterestPkey = 'Tags_by_interest_pkey'
}

/** input type for incrementing numeric columns in table "Tags_by_interest" */
export type Tags_By_Interest_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Tags_by_interest" */
export type Tags_By_Interest_Insert_Input = {
  Artwork_tag?: Maybe<Artwork_Tag_Obj_Rel_Insert_Input>;
  Interest?: Maybe<Interests_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  interest_type?: Maybe<Scalars['String']>;
  tag_id?: Maybe<Scalars['bigint']>;
  tag_type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tags_By_Interest_Max_Fields = {
  __typename?: 'Tags_by_interest_max_fields';
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  interest_type?: Maybe<Scalars['String']>;
  tag_id?: Maybe<Scalars['bigint']>;
  tag_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tags_By_Interest_Min_Fields = {
  __typename?: 'Tags_by_interest_min_fields';
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  interest_type?: Maybe<Scalars['String']>;
  tag_id?: Maybe<Scalars['bigint']>;
  tag_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Tags_by_interest" */
export type Tags_By_Interest_Mutation_Response = {
  __typename?: 'Tags_by_interest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags_By_Interest>;
};

/** on_conflict condition type for table "Tags_by_interest" */
export type Tags_By_Interest_On_Conflict = {
  constraint: Tags_By_Interest_Constraint;
  update_columns?: Array<Tags_By_Interest_Update_Column>;
  where?: Maybe<Tags_By_Interest_Bool_Exp>;
};

/** Ordering options when selecting data from "Tags_by_interest". */
export type Tags_By_Interest_Order_By = {
  Artwork_tag?: Maybe<Artwork_Tag_Order_By>;
  Interest?: Maybe<Interests_Order_By>;
  id?: Maybe<Order_By>;
  interest_id?: Maybe<Order_By>;
  interest_type?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
  tag_type?: Maybe<Order_By>;
};

/** primary key columns input for table: Tags_by_interest */
export type Tags_By_Interest_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Tags_by_interest" */
export enum Tags_By_Interest_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InterestId = 'interest_id',
  /** column name */
  InterestType = 'interest_type',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TagType = 'tag_type'
}

/** input type for updating data in table "Tags_by_interest" */
export type Tags_By_Interest_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  interest_type?: Maybe<Scalars['String']>;
  tag_id?: Maybe<Scalars['bigint']>;
  tag_type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Tags_By_Interest_Stddev_Fields = {
  __typename?: 'Tags_by_interest_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tags_By_Interest_Stddev_Pop_Fields = {
  __typename?: 'Tags_by_interest_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tags_By_Interest_Stddev_Samp_Fields = {
  __typename?: 'Tags_by_interest_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Tags_By_Interest_Sum_Fields = {
  __typename?: 'Tags_by_interest_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  interest_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Tags_by_interest" */
export enum Tags_By_Interest_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InterestId = 'interest_id',
  /** column name */
  InterestType = 'interest_type',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TagType = 'tag_type'
}

/** aggregate var_pop on columns */
export type Tags_By_Interest_Var_Pop_Fields = {
  __typename?: 'Tags_by_interest_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tags_By_Interest_Var_Samp_Fields = {
  __typename?: 'Tags_by_interest_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tags_By_Interest_Variance_Fields = {
  __typename?: 'Tags_by_interest_variance_fields';
  id?: Maybe<Scalars['Float']>;
  interest_id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection = {
  __typename?: 'Temp_artist_explore_collection';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Aggregate = {
  __typename?: 'Temp_artist_explore_collection_aggregate';
  aggregate?: Maybe<Temp_Artist_Explore_Collection_Aggregate_Fields>;
  nodes: Array<Temp_Artist_Explore_Collection>;
};

/** aggregate fields of "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Aggregate_Fields = {
  __typename?: 'Temp_artist_explore_collection_aggregate_fields';
  avg?: Maybe<Temp_Artist_Explore_Collection_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Temp_Artist_Explore_Collection_Max_Fields>;
  min?: Maybe<Temp_Artist_Explore_Collection_Min_Fields>;
  stddev?: Maybe<Temp_Artist_Explore_Collection_Stddev_Fields>;
  stddev_pop?: Maybe<Temp_Artist_Explore_Collection_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Temp_Artist_Explore_Collection_Stddev_Samp_Fields>;
  sum?: Maybe<Temp_Artist_Explore_Collection_Sum_Fields>;
  var_pop?: Maybe<Temp_Artist_Explore_Collection_Var_Pop_Fields>;
  var_samp?: Maybe<Temp_Artist_Explore_Collection_Var_Samp_Fields>;
  variance?: Maybe<Temp_Artist_Explore_Collection_Variance_Fields>;
};


/** aggregate fields of "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Aggregate_Order_By = {
  avg?: Maybe<Temp_Artist_Explore_Collection_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Temp_Artist_Explore_Collection_Max_Order_By>;
  min?: Maybe<Temp_Artist_Explore_Collection_Min_Order_By>;
  stddev?: Maybe<Temp_Artist_Explore_Collection_Stddev_Order_By>;
  stddev_pop?: Maybe<Temp_Artist_Explore_Collection_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Temp_Artist_Explore_Collection_Stddev_Samp_Order_By>;
  sum?: Maybe<Temp_Artist_Explore_Collection_Sum_Order_By>;
  var_pop?: Maybe<Temp_Artist_Explore_Collection_Var_Pop_Order_By>;
  var_samp?: Maybe<Temp_Artist_Explore_Collection_Var_Samp_Order_By>;
  variance?: Maybe<Temp_Artist_Explore_Collection_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Arr_Rel_Insert_Input = {
  data: Array<Temp_Artist_Explore_Collection_Insert_Input>;
};

/** aggregate avg on columns */
export type Temp_Artist_Explore_Collection_Avg_Fields = {
  __typename?: 'Temp_artist_explore_collection_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Avg_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Temp_artist_explore_collection". All fields are combined with a logical 'AND'. */
export type Temp_Artist_Explore_Collection_Bool_Exp = {
  _and?: Maybe<Array<Temp_Artist_Explore_Collection_Bool_Exp>>;
  _not?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
  _or?: Maybe<Array<Temp_Artist_Explore_Collection_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  internal_url?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Insert_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Temp_Artist_Explore_Collection_Max_Fields = {
  __typename?: 'Temp_artist_explore_collection_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Max_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Temp_Artist_Explore_Collection_Min_Fields = {
  __typename?: 'Temp_artist_explore_collection_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  internal_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Min_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_url?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "Temp_artist_explore_collection". */
export type Temp_Artist_Explore_Collection_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_url?: Maybe<Order_By>;
};

/** select columns of table "Temp_artist_explore_collection" */
export enum Temp_Artist_Explore_Collection_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalUrl = 'internal_url'
}

/** aggregate stddev on columns */
export type Temp_Artist_Explore_Collection_Stddev_Fields = {
  __typename?: 'Temp_artist_explore_collection_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Stddev_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Temp_Artist_Explore_Collection_Stddev_Pop_Fields = {
  __typename?: 'Temp_artist_explore_collection_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Stddev_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Temp_Artist_Explore_Collection_Stddev_Samp_Fields = {
  __typename?: 'Temp_artist_explore_collection_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Stddev_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Temp_Artist_Explore_Collection_Sum_Fields = {
  __typename?: 'Temp_artist_explore_collection_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Sum_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Temp_Artist_Explore_Collection_Var_Pop_Fields = {
  __typename?: 'Temp_artist_explore_collection_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Var_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Temp_Artist_Explore_Collection_Var_Samp_Fields = {
  __typename?: 'Temp_artist_explore_collection_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Var_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Temp_Artist_Explore_Collection_Variance_Fields = {
  __typename?: 'Temp_artist_explore_collection_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Temp_artist_explore_collection" */
export type Temp_Artist_Explore_Collection_Variance_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** This analytics events table stores analytics data generated by mobile, backend and frontend apps. Only production data from test users will be stored. */
export type Test_Analytics_Events = {
  __typename?: 'Test_Analytics_events';
  /** An array relationship */
  Analytics_kaleidoCard_events: Array<Analytics_KaleidoCard_Events>;
  /** An aggregate relationship */
  Analytics_kaleidoCard_events_aggregate: Analytics_KaleidoCard_Events_Aggregate;
  /** An object relationship */
  context?: Maybe<Context>;
  created_at: Scalars['timestamptz'];
  event_action: Scalars['String'];
  event_category: Scalars['String'];
  hit_type: Scalars['String'];
  id: Scalars['Int'];
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  test_account: Array<Test_Accounts>;
  /** An aggregate relationship */
  test_account_aggregate: Test_Accounts_Aggregate;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};


/** This analytics events table stores analytics data generated by mobile, backend and frontend apps. Only production data from test users will be stored. */
export type Test_Analytics_EventsAnalytics_KaleidoCard_EventsArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Events_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
};


/** This analytics events table stores analytics data generated by mobile, backend and frontend apps. Only production data from test users will be stored. */
export type Test_Analytics_EventsAnalytics_KaleidoCard_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Events_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
};


/** This analytics events table stores analytics data generated by mobile, backend and frontend apps. Only production data from test users will be stored. */
export type Test_Analytics_EventsTagsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** This analytics events table stores analytics data generated by mobile, backend and frontend apps. Only production data from test users will be stored. */
export type Test_Analytics_EventsTest_AccountArgs = {
  distinct_on?: Maybe<Array<Test_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Accounts_Order_By>>;
  where?: Maybe<Test_Accounts_Bool_Exp>;
};


/** This analytics events table stores analytics data generated by mobile, backend and frontend apps. Only production data from test users will be stored. */
export type Test_Analytics_EventsTest_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Accounts_Order_By>>;
  where?: Maybe<Test_Accounts_Bool_Exp>;
};

/** aggregated selection of "Test_Analytics_events" */
export type Test_Analytics_Events_Aggregate = {
  __typename?: 'Test_Analytics_events_aggregate';
  aggregate?: Maybe<Test_Analytics_Events_Aggregate_Fields>;
  nodes: Array<Test_Analytics_Events>;
};

/** aggregate fields of "Test_Analytics_events" */
export type Test_Analytics_Events_Aggregate_Fields = {
  __typename?: 'Test_Analytics_events_aggregate_fields';
  avg?: Maybe<Test_Analytics_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Test_Analytics_Events_Max_Fields>;
  min?: Maybe<Test_Analytics_Events_Min_Fields>;
  stddev?: Maybe<Test_Analytics_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Test_Analytics_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Test_Analytics_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Test_Analytics_Events_Sum_Fields>;
  var_pop?: Maybe<Test_Analytics_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Test_Analytics_Events_Var_Samp_Fields>;
  variance?: Maybe<Test_Analytics_Events_Variance_Fields>;
};


/** aggregate fields of "Test_Analytics_events" */
export type Test_Analytics_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Test_Analytics_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Test_Analytics_Events_Append_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Test_Analytics_Events_Avg_Fields = {
  __typename?: 'Test_Analytics_events_avg_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Test_Analytics_events". All fields are combined with a logical 'AND'. */
export type Test_Analytics_Events_Bool_Exp = {
  Analytics_kaleidoCard_events?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
  _and?: Maybe<Array<Test_Analytics_Events_Bool_Exp>>;
  _not?: Maybe<Test_Analytics_Events_Bool_Exp>;
  _or?: Maybe<Array<Test_Analytics_Events_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  event_action?: Maybe<String_Comparison_Exp>;
  event_category?: Maybe<String_Comparison_Exp>;
  hit_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  profile_context_id?: Maybe<Bigint_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  tags?: Maybe<Jsonb_Comparison_Exp>;
  test_account?: Maybe<Test_Accounts_Bool_Exp>;
  visitor_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Test_Analytics_events" */
export enum Test_Analytics_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  TestAnalyticsEventsIdKey = 'Test_Analytics_events_id_key',
  /** unique or primary key constraint on columns "id" */
  TestAnalyticsEventsPkey = 'Test_Analytics_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Test_Analytics_Events_Delete_At_Path_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Test_Analytics_Events_Delete_Elem_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Test_Analytics_Events_Delete_Key_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Test_Analytics_events" */
export type Test_Analytics_Events_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Test_Analytics_events" */
export type Test_Analytics_Events_Insert_Input = {
  Analytics_kaleidoCard_events?: Maybe<Analytics_KaleidoCard_Events_Arr_Rel_Insert_Input>;
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_action?: Maybe<Scalars['String']>;
  event_category?: Maybe<Scalars['String']>;
  hit_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
  test_account?: Maybe<Test_Accounts_Arr_Rel_Insert_Input>;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Test_Analytics_Events_Max_Fields = {
  __typename?: 'Test_Analytics_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_action?: Maybe<Scalars['String']>;
  event_category?: Maybe<Scalars['String']>;
  hit_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Test_Analytics_Events_Min_Fields = {
  __typename?: 'Test_Analytics_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_action?: Maybe<Scalars['String']>;
  event_category?: Maybe<Scalars['String']>;
  hit_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Test_Analytics_events" */
export type Test_Analytics_Events_Mutation_Response = {
  __typename?: 'Test_Analytics_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Test_Analytics_Events>;
};

/** on_conflict condition type for table "Test_Analytics_events" */
export type Test_Analytics_Events_On_Conflict = {
  constraint: Test_Analytics_Events_Constraint;
  update_columns?: Array<Test_Analytics_Events_Update_Column>;
  where?: Maybe<Test_Analytics_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "Test_Analytics_events". */
export type Test_Analytics_Events_Order_By = {
  Analytics_kaleidoCard_events_aggregate?: Maybe<Analytics_KaleidoCard_Events_Aggregate_Order_By>;
  context?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  event_action?: Maybe<Order_By>;
  event_category?: Maybe<Order_By>;
  hit_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  test_account_aggregate?: Maybe<Test_Accounts_Aggregate_Order_By>;
  visitor_id?: Maybe<Order_By>;
};

/** primary key columns input for table: Test_Analytics_events */
export type Test_Analytics_Events_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Test_Analytics_Events_Prepend_Input = {
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Test_Analytics_events" */
export enum Test_Analytics_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventAction = 'event_action',
  /** column name */
  EventCategory = 'event_category',
  /** column name */
  HitType = 'hit_type',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  VisitorId = 'visitor_id'
}

/** input type for updating data in table "Test_Analytics_events" */
export type Test_Analytics_Events_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  event_action?: Maybe<Scalars['String']>;
  event_category?: Maybe<Scalars['String']>;
  hit_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
  /** Holds any dynamic information related to the event. The data must be similar for the same event_category and event_action. */
  tags?: Maybe<Scalars['jsonb']>;
  /** Used to check user history of generated events. */
  visitor_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Test_Analytics_Events_Stddev_Fields = {
  __typename?: 'Test_Analytics_events_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Test_Analytics_Events_Stddev_Pop_Fields = {
  __typename?: 'Test_Analytics_events_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Test_Analytics_Events_Stddev_Samp_Fields = {
  __typename?: 'Test_Analytics_events_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Test_Analytics_Events_Sum_Fields = {
  __typename?: 'Test_Analytics_events_sum_fields';
  id?: Maybe<Scalars['Int']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['bigint']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Test_Analytics_events" */
export enum Test_Analytics_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventAction = 'event_action',
  /** column name */
  EventCategory = 'event_category',
  /** column name */
  HitType = 'hit_type',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Tags = 'tags',
  /** column name */
  VisitorId = 'visitor_id'
}

/** aggregate var_pop on columns */
export type Test_Analytics_Events_Var_Pop_Fields = {
  __typename?: 'Test_Analytics_events_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Test_Analytics_Events_Var_Samp_Fields = {
  __typename?: 'Test_Analytics_events_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Test_Analytics_Events_Variance_Fields = {
  __typename?: 'Test_Analytics_events_variance_fields';
  id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user context_id here. */
  profile_context_id?: Maybe<Scalars['Float']>;
  /** If the user is an registered user then we will add the user profile_id here. */
  profile_id?: Maybe<Scalars['Float']>;
};

/** Table used to define testing accounts (Accounts created by team for testing purposes). Accounts stored here will not generate analytics events. Accounts stored here will not be included for reports. */
export type Test_Accounts = {
  __typename?: 'Test_accounts';
  /** An object relationship */
  context?: Maybe<Context>;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** Switch on and off in order to test analytics using an testing account. */
  is_testing_account: Scalars['Boolean'];
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Test_accounts" */
export type Test_Accounts_Aggregate = {
  __typename?: 'Test_accounts_aggregate';
  aggregate?: Maybe<Test_Accounts_Aggregate_Fields>;
  nodes: Array<Test_Accounts>;
};

/** aggregate fields of "Test_accounts" */
export type Test_Accounts_Aggregate_Fields = {
  __typename?: 'Test_accounts_aggregate_fields';
  avg?: Maybe<Test_Accounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Test_Accounts_Max_Fields>;
  min?: Maybe<Test_Accounts_Min_Fields>;
  stddev?: Maybe<Test_Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Test_Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Test_Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Test_Accounts_Sum_Fields>;
  var_pop?: Maybe<Test_Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Test_Accounts_Var_Samp_Fields>;
  variance?: Maybe<Test_Accounts_Variance_Fields>;
};


/** aggregate fields of "Test_accounts" */
export type Test_Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Test_Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Test_accounts" */
export type Test_Accounts_Aggregate_Order_By = {
  avg?: Maybe<Test_Accounts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Test_Accounts_Max_Order_By>;
  min?: Maybe<Test_Accounts_Min_Order_By>;
  stddev?: Maybe<Test_Accounts_Stddev_Order_By>;
  stddev_pop?: Maybe<Test_Accounts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Test_Accounts_Stddev_Samp_Order_By>;
  sum?: Maybe<Test_Accounts_Sum_Order_By>;
  var_pop?: Maybe<Test_Accounts_Var_Pop_Order_By>;
  var_samp?: Maybe<Test_Accounts_Var_Samp_Order_By>;
  variance?: Maybe<Test_Accounts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Test_accounts" */
export type Test_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Test_Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Test_Accounts_On_Conflict>;
};

/** aggregate avg on columns */
export type Test_Accounts_Avg_Fields = {
  __typename?: 'Test_accounts_avg_fields';
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Test_accounts" */
export type Test_Accounts_Avg_Order_By = {
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Test_accounts". All fields are combined with a logical 'AND'. */
export type Test_Accounts_Bool_Exp = {
  _and?: Maybe<Array<Test_Accounts_Bool_Exp>>;
  _not?: Maybe<Test_Accounts_Bool_Exp>;
  _or?: Maybe<Array<Test_Accounts_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_testing_account?: Maybe<Boolean_Comparison_Exp>;
  profile_context_id?: Maybe<Bigint_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Test_accounts" */
export enum Test_Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  TestAccountsPkey = 'Test_accounts_pkey'
}

/** input type for incrementing numeric columns in table "Test_accounts" */
export type Test_Accounts_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Test_accounts" */
export type Test_Accounts_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Switch on and off in order to test analytics using an testing account. */
  is_testing_account?: Maybe<Scalars['Boolean']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Test_Accounts_Max_Fields = {
  __typename?: 'Test_accounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Test_accounts" */
export type Test_Accounts_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Test_Accounts_Min_Fields = {
  __typename?: 'Test_accounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Test_accounts" */
export type Test_Accounts_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Test_accounts" */
export type Test_Accounts_Mutation_Response = {
  __typename?: 'Test_accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Test_Accounts>;
};

/** input type for inserting object relation for remote table "Test_accounts" */
export type Test_Accounts_Obj_Rel_Insert_Input = {
  data: Test_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Test_Accounts_On_Conflict>;
};

/** on_conflict condition type for table "Test_accounts" */
export type Test_Accounts_On_Conflict = {
  constraint: Test_Accounts_Constraint;
  update_columns?: Array<Test_Accounts_Update_Column>;
  where?: Maybe<Test_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "Test_accounts". */
export type Test_Accounts_Order_By = {
  context?: Maybe<Context_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_testing_account?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Test_accounts */
export type Test_Accounts_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Test_accounts" */
export enum Test_Accounts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsTestingAccount = 'is_testing_account',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Test_accounts" */
export type Test_Accounts_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  /** Switch on and off in order to test analytics using an testing account. */
  is_testing_account?: Maybe<Scalars['Boolean']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Test_Accounts_Stddev_Fields = {
  __typename?: 'Test_accounts_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Test_accounts" */
export type Test_Accounts_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Test_Accounts_Stddev_Pop_Fields = {
  __typename?: 'Test_accounts_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Test_accounts" */
export type Test_Accounts_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Test_Accounts_Stddev_Samp_Fields = {
  __typename?: 'Test_accounts_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Test_accounts" */
export type Test_Accounts_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Test_Accounts_Sum_Fields = {
  __typename?: 'Test_accounts_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  profile_context_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Test_accounts" */
export type Test_Accounts_Sum_Order_By = {
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** update columns of table "Test_accounts" */
export enum Test_Accounts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsTestingAccount = 'is_testing_account',
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Test_Accounts_Var_Pop_Fields = {
  __typename?: 'Test_accounts_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Test_accounts" */
export type Test_Accounts_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Test_Accounts_Var_Samp_Fields = {
  __typename?: 'Test_accounts_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Test_accounts" */
export type Test_Accounts_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Test_Accounts_Variance_Fields = {
  __typename?: 'Test_accounts_variance_fields';
  id?: Maybe<Scalars['Float']>;
  profile_context_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Test_accounts" */
export type Test_Accounts_Variance_Order_By = {
  id?: Maybe<Order_By>;
  profile_context_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

export type ThirdPartySignInOutputType = {
  __typename?: 'ThirdPartySignInOutputType';
  message: Scalars['String'];
  valid: Scalars['Boolean'];
};

/** columns and relationships of "Timed_event" */
export type Timed_Event = {
  __typename?: 'Timed_event';
  event_name: Enum_Timed_Event_Name_Enum;
  id: Scalars['bigint'];
  last_actual_run?: Maybe<Scalars['timestamptz']>;
  last_run?: Maybe<Scalars['timestamp']>;
  next_actual_run?: Maybe<Scalars['timestamptz']>;
  next_run: Scalars['timestamp'];
};

/** aggregated selection of "Timed_event" */
export type Timed_Event_Aggregate = {
  __typename?: 'Timed_event_aggregate';
  aggregate?: Maybe<Timed_Event_Aggregate_Fields>;
  nodes: Array<Timed_Event>;
};

/** aggregate fields of "Timed_event" */
export type Timed_Event_Aggregate_Fields = {
  __typename?: 'Timed_event_aggregate_fields';
  avg?: Maybe<Timed_Event_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Timed_Event_Max_Fields>;
  min?: Maybe<Timed_Event_Min_Fields>;
  stddev?: Maybe<Timed_Event_Stddev_Fields>;
  stddev_pop?: Maybe<Timed_Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Timed_Event_Stddev_Samp_Fields>;
  sum?: Maybe<Timed_Event_Sum_Fields>;
  var_pop?: Maybe<Timed_Event_Var_Pop_Fields>;
  var_samp?: Maybe<Timed_Event_Var_Samp_Fields>;
  variance?: Maybe<Timed_Event_Variance_Fields>;
};


/** aggregate fields of "Timed_event" */
export type Timed_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Timed_Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Timed_Event_Avg_Fields = {
  __typename?: 'Timed_event_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Timed_event". All fields are combined with a logical 'AND'. */
export type Timed_Event_Bool_Exp = {
  _and?: Maybe<Array<Timed_Event_Bool_Exp>>;
  _not?: Maybe<Timed_Event_Bool_Exp>;
  _or?: Maybe<Array<Timed_Event_Bool_Exp>>;
  event_name?: Maybe<Enum_Timed_Event_Name_Enum_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  last_actual_run?: Maybe<Timestamptz_Comparison_Exp>;
  last_run?: Maybe<Timestamp_Comparison_Exp>;
  next_actual_run?: Maybe<Timestamptz_Comparison_Exp>;
  next_run?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "Timed_event" */
export enum Timed_Event_Constraint {
  /** unique or primary key constraint on columns "event_name" */
  TimedEventEventNameKey = 'Timed_event_event_name_key',
  /** unique or primary key constraint on columns "id" */
  TimedEventPkey = 'Timed_event_pkey'
}

/** input type for incrementing numeric columns in table "Timed_event" */
export type Timed_Event_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Timed_event" */
export type Timed_Event_Insert_Input = {
  event_name?: Maybe<Enum_Timed_Event_Name_Enum>;
  id?: Maybe<Scalars['bigint']>;
  last_actual_run?: Maybe<Scalars['timestamptz']>;
  last_run?: Maybe<Scalars['timestamp']>;
  next_actual_run?: Maybe<Scalars['timestamptz']>;
  next_run?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Timed_Event_Max_Fields = {
  __typename?: 'Timed_event_max_fields';
  id?: Maybe<Scalars['bigint']>;
  last_actual_run?: Maybe<Scalars['timestamptz']>;
  last_run?: Maybe<Scalars['timestamp']>;
  next_actual_run?: Maybe<Scalars['timestamptz']>;
  next_run?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Timed_Event_Min_Fields = {
  __typename?: 'Timed_event_min_fields';
  id?: Maybe<Scalars['bigint']>;
  last_actual_run?: Maybe<Scalars['timestamptz']>;
  last_run?: Maybe<Scalars['timestamp']>;
  next_actual_run?: Maybe<Scalars['timestamptz']>;
  next_run?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "Timed_event" */
export type Timed_Event_Mutation_Response = {
  __typename?: 'Timed_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Timed_Event>;
};

/** on_conflict condition type for table "Timed_event" */
export type Timed_Event_On_Conflict = {
  constraint: Timed_Event_Constraint;
  update_columns?: Array<Timed_Event_Update_Column>;
  where?: Maybe<Timed_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "Timed_event". */
export type Timed_Event_Order_By = {
  event_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_actual_run?: Maybe<Order_By>;
  last_run?: Maybe<Order_By>;
  next_actual_run?: Maybe<Order_By>;
  next_run?: Maybe<Order_By>;
};

/** primary key columns input for table: Timed_event */
export type Timed_Event_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Timed_event" */
export enum Timed_Event_Select_Column {
  /** column name */
  EventName = 'event_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastActualRun = 'last_actual_run',
  /** column name */
  LastRun = 'last_run',
  /** column name */
  NextActualRun = 'next_actual_run',
  /** column name */
  NextRun = 'next_run'
}

/** input type for updating data in table "Timed_event" */
export type Timed_Event_Set_Input = {
  event_name?: Maybe<Enum_Timed_Event_Name_Enum>;
  id?: Maybe<Scalars['bigint']>;
  last_actual_run?: Maybe<Scalars['timestamptz']>;
  last_run?: Maybe<Scalars['timestamp']>;
  next_actual_run?: Maybe<Scalars['timestamptz']>;
  next_run?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Timed_Event_Stddev_Fields = {
  __typename?: 'Timed_event_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Timed_Event_Stddev_Pop_Fields = {
  __typename?: 'Timed_event_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Timed_Event_Stddev_Samp_Fields = {
  __typename?: 'Timed_event_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Timed_Event_Sum_Fields = {
  __typename?: 'Timed_event_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Timed_event" */
export enum Timed_Event_Update_Column {
  /** column name */
  EventName = 'event_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastActualRun = 'last_actual_run',
  /** column name */
  LastRun = 'last_run',
  /** column name */
  NextActualRun = 'next_actual_run',
  /** column name */
  NextRun = 'next_run'
}

/** aggregate var_pop on columns */
export type Timed_Event_Var_Pop_Fields = {
  __typename?: 'Timed_event_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Timed_Event_Var_Samp_Fields = {
  __typename?: 'Timed_event_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Timed_Event_Variance_Fields = {
  __typename?: 'Timed_event_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Token_Storage" */
export type Token_Storage = {
  __typename?: 'Token_Storage';
  bucket?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  expired_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  status: Enum_Token_Storage_Status_Enum;
  token: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Token_Storage" */
export type Token_Storage_Aggregate = {
  __typename?: 'Token_Storage_aggregate';
  aggregate?: Maybe<Token_Storage_Aggregate_Fields>;
  nodes: Array<Token_Storage>;
};

/** aggregate fields of "Token_Storage" */
export type Token_Storage_Aggregate_Fields = {
  __typename?: 'Token_Storage_aggregate_fields';
  avg?: Maybe<Token_Storage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Token_Storage_Max_Fields>;
  min?: Maybe<Token_Storage_Min_Fields>;
  stddev?: Maybe<Token_Storage_Stddev_Fields>;
  stddev_pop?: Maybe<Token_Storage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Token_Storage_Stddev_Samp_Fields>;
  sum?: Maybe<Token_Storage_Sum_Fields>;
  var_pop?: Maybe<Token_Storage_Var_Pop_Fields>;
  var_samp?: Maybe<Token_Storage_Var_Samp_Fields>;
  variance?: Maybe<Token_Storage_Variance_Fields>;
};


/** aggregate fields of "Token_Storage" */
export type Token_Storage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Token_Storage_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Token_Storage_Avg_Fields = {
  __typename?: 'Token_Storage_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Token_Storage". All fields are combined with a logical 'AND'. */
export type Token_Storage_Bool_Exp = {
  _and?: Maybe<Array<Token_Storage_Bool_Exp>>;
  _not?: Maybe<Token_Storage_Bool_Exp>;
  _or?: Maybe<Array<Token_Storage_Bool_Exp>>;
  bucket?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expired_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<Enum_Token_Storage_Status_Enum_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Token_Storage" */
export enum Token_Storage_Constraint {
  /** unique or primary key constraint on columns "id" */
  TokenStoragePkey = 'Token_Storage_pkey',
  /** unique or primary key constraint on columns "bucket", "status", "token" */
  TokenStorageTokenStatusBucketKey = 'Token_Storage_token_status_bucket_key'
}

/** input type for incrementing numeric columns in table "Token_Storage" */
export type Token_Storage_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Token_Storage" */
export type Token_Storage_Insert_Input = {
  bucket?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_Token_Storage_Status_Enum>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Token_Storage_Max_Fields = {
  __typename?: 'Token_Storage_max_fields';
  bucket?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Token_Storage_Min_Fields = {
  __typename?: 'Token_Storage_min_fields';
  bucket?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Token_Storage" */
export type Token_Storage_Mutation_Response = {
  __typename?: 'Token_Storage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Token_Storage>;
};

/** input type for inserting object relation for remote table "Token_Storage" */
export type Token_Storage_Obj_Rel_Insert_Input = {
  data: Token_Storage_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Token_Storage_On_Conflict>;
};

/** on_conflict condition type for table "Token_Storage" */
export type Token_Storage_On_Conflict = {
  constraint: Token_Storage_Constraint;
  update_columns?: Array<Token_Storage_Update_Column>;
  where?: Maybe<Token_Storage_Bool_Exp>;
};

/** Ordering options when selecting data from "Token_Storage". */
export type Token_Storage_Order_By = {
  bucket?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expired_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Token_Storage */
export type Token_Storage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Token_Storage" */
export enum Token_Storage_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Token_Storage" */
export type Token_Storage_Set_Input = {
  bucket?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Enum_Token_Storage_Status_Enum>;
  token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Token_Storage_Stddev_Fields = {
  __typename?: 'Token_Storage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Token_Storage_Stddev_Pop_Fields = {
  __typename?: 'Token_Storage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Token_Storage_Stddev_Samp_Fields = {
  __typename?: 'Token_Storage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Token_Storage_Sum_Fields = {
  __typename?: 'Token_Storage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Token_Storage" */
export enum Token_Storage_Update_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Token_Storage_Var_Pop_Fields = {
  __typename?: 'Token_Storage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Token_Storage_Var_Samp_Fields = {
  __typename?: 'Token_Storage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Token_Storage_Variance_Fields = {
  __typename?: 'Token_Storage_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type TransactionsPreview = {
  __typename?: 'TransactionsPreview';
  artist_context_profile_handle?: Maybe<Scalars['String']>;
  artist_context_profile_title?: Maybe<Scalars['String']>;
  artwork_details_title?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  primary_image_metadata_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

/** First time use feature. */
export type Tutorial = {
  __typename?: 'Tutorial';
  /** An array relationship */
  Context_to_tutorials: Array<Context_To_Tutorial>;
  /** An aggregate relationship */
  Context_to_tutorials_aggregate: Context_To_Tutorial_Aggregate;
  created_at: Scalars['timestamptz'];
  disabled: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** Make the popup skippable. */
  skippable: Scalars['Boolean'];
  /** An array relationship */
  tutorial_steps: Array<Tutorial_Step>;
  /** An aggregate relationship */
  tutorial_steps_aggregate: Tutorial_Step_Aggregate;
  type: Enum_Tutorial_Type_Enum;
  updated_at: Scalars['timestamptz'];
};


/** First time use feature. */
export type TutorialContext_To_TutorialsArgs = {
  distinct_on?: Maybe<Array<Context_To_Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Tutorial_Order_By>>;
  where?: Maybe<Context_To_Tutorial_Bool_Exp>;
};


/** First time use feature. */
export type TutorialContext_To_Tutorials_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_To_Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Tutorial_Order_By>>;
  where?: Maybe<Context_To_Tutorial_Bool_Exp>;
};


/** First time use feature. */
export type TutorialTutorial_StepsArgs = {
  distinct_on?: Maybe<Array<Tutorial_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Step_Order_By>>;
  where?: Maybe<Tutorial_Step_Bool_Exp>;
};


/** First time use feature. */
export type TutorialTutorial_Steps_AggregateArgs = {
  distinct_on?: Maybe<Array<Tutorial_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Step_Order_By>>;
  where?: Maybe<Tutorial_Step_Bool_Exp>;
};

/** aggregated selection of "Tutorial" */
export type Tutorial_Aggregate = {
  __typename?: 'Tutorial_aggregate';
  aggregate?: Maybe<Tutorial_Aggregate_Fields>;
  nodes: Array<Tutorial>;
};

/** aggregate fields of "Tutorial" */
export type Tutorial_Aggregate_Fields = {
  __typename?: 'Tutorial_aggregate_fields';
  avg?: Maybe<Tutorial_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tutorial_Max_Fields>;
  min?: Maybe<Tutorial_Min_Fields>;
  stddev?: Maybe<Tutorial_Stddev_Fields>;
  stddev_pop?: Maybe<Tutorial_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tutorial_Stddev_Samp_Fields>;
  sum?: Maybe<Tutorial_Sum_Fields>;
  var_pop?: Maybe<Tutorial_Var_Pop_Fields>;
  var_samp?: Maybe<Tutorial_Var_Samp_Fields>;
  variance?: Maybe<Tutorial_Variance_Fields>;
};


/** aggregate fields of "Tutorial" */
export type Tutorial_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tutorial_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Tutorial_Avg_Fields = {
  __typename?: 'Tutorial_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Tutorial". All fields are combined with a logical 'AND'. */
export type Tutorial_Bool_Exp = {
  Context_to_tutorials?: Maybe<Context_To_Tutorial_Bool_Exp>;
  _and?: Maybe<Array<Tutorial_Bool_Exp>>;
  _not?: Maybe<Tutorial_Bool_Exp>;
  _or?: Maybe<Array<Tutorial_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  skippable?: Maybe<Boolean_Comparison_Exp>;
  tutorial_steps?: Maybe<Tutorial_Step_Bool_Exp>;
  type?: Maybe<Enum_Tutorial_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tutorial" */
export enum Tutorial_Constraint {
  /** unique or primary key constraint on columns "id" */
  TutorialPkey = 'Tutorial_pkey',
  /** unique or primary key constraint on columns "type" */
  TutorialTypeKey = 'Tutorial_type_key'
}

/** input type for incrementing numeric columns in table "Tutorial" */
export type Tutorial_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Tutorial" */
export type Tutorial_Insert_Input = {
  Context_to_tutorials?: Maybe<Context_To_Tutorial_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  /** Make the popup skippable. */
  skippable?: Maybe<Scalars['Boolean']>;
  tutorial_steps?: Maybe<Tutorial_Step_Arr_Rel_Insert_Input>;
  type?: Maybe<Enum_Tutorial_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tutorial_Max_Fields = {
  __typename?: 'Tutorial_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tutorial_Min_Fields = {
  __typename?: 'Tutorial_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Tutorial" */
export type Tutorial_Mutation_Response = {
  __typename?: 'Tutorial_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tutorial>;
};

/** input type for inserting object relation for remote table "Tutorial" */
export type Tutorial_Obj_Rel_Insert_Input = {
  data: Tutorial_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Tutorial_On_Conflict>;
};

/** on_conflict condition type for table "Tutorial" */
export type Tutorial_On_Conflict = {
  constraint: Tutorial_Constraint;
  update_columns?: Array<Tutorial_Update_Column>;
  where?: Maybe<Tutorial_Bool_Exp>;
};

/** Ordering options when selecting data from "Tutorial". */
export type Tutorial_Order_By = {
  Context_to_tutorials_aggregate?: Maybe<Context_To_Tutorial_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  skippable?: Maybe<Order_By>;
  tutorial_steps_aggregate?: Maybe<Tutorial_Step_Aggregate_Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Tutorial */
export type Tutorial_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Tutorial" */
export enum Tutorial_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Skippable = 'skippable',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Tutorial" */
export type Tutorial_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  /** Make the popup skippable. */
  skippable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Enum_Tutorial_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tutorial_Stddev_Fields = {
  __typename?: 'Tutorial_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Tutorial_Stddev_Pop_Fields = {
  __typename?: 'Tutorial_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Tutorial_Stddev_Samp_Fields = {
  __typename?: 'Tutorial_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Tutorial_step" */
export type Tutorial_Step = {
  __typename?: 'Tutorial_step';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  order: Scalars['Int'];
  tutorial_id: Scalars['bigint'];
  type: Enum_Tutorial_Step_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Tutorial_step" */
export type Tutorial_Step_Aggregate = {
  __typename?: 'Tutorial_step_aggregate';
  aggregate?: Maybe<Tutorial_Step_Aggregate_Fields>;
  nodes: Array<Tutorial_Step>;
};

/** aggregate fields of "Tutorial_step" */
export type Tutorial_Step_Aggregate_Fields = {
  __typename?: 'Tutorial_step_aggregate_fields';
  avg?: Maybe<Tutorial_Step_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tutorial_Step_Max_Fields>;
  min?: Maybe<Tutorial_Step_Min_Fields>;
  stddev?: Maybe<Tutorial_Step_Stddev_Fields>;
  stddev_pop?: Maybe<Tutorial_Step_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tutorial_Step_Stddev_Samp_Fields>;
  sum?: Maybe<Tutorial_Step_Sum_Fields>;
  var_pop?: Maybe<Tutorial_Step_Var_Pop_Fields>;
  var_samp?: Maybe<Tutorial_Step_Var_Samp_Fields>;
  variance?: Maybe<Tutorial_Step_Variance_Fields>;
};


/** aggregate fields of "Tutorial_step" */
export type Tutorial_Step_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tutorial_Step_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Tutorial_step" */
export type Tutorial_Step_Aggregate_Order_By = {
  avg?: Maybe<Tutorial_Step_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tutorial_Step_Max_Order_By>;
  min?: Maybe<Tutorial_Step_Min_Order_By>;
  stddev?: Maybe<Tutorial_Step_Stddev_Order_By>;
  stddev_pop?: Maybe<Tutorial_Step_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tutorial_Step_Stddev_Samp_Order_By>;
  sum?: Maybe<Tutorial_Step_Sum_Order_By>;
  var_pop?: Maybe<Tutorial_Step_Var_Pop_Order_By>;
  var_samp?: Maybe<Tutorial_Step_Var_Samp_Order_By>;
  variance?: Maybe<Tutorial_Step_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Tutorial_step" */
export type Tutorial_Step_Arr_Rel_Insert_Input = {
  data: Array<Tutorial_Step_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Tutorial_Step_On_Conflict>;
};

/** aggregate avg on columns */
export type Tutorial_Step_Avg_Fields = {
  __typename?: 'Tutorial_step_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Tutorial_step" */
export type Tutorial_Step_Avg_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Tutorial_step". All fields are combined with a logical 'AND'. */
export type Tutorial_Step_Bool_Exp = {
  _and?: Maybe<Array<Tutorial_Step_Bool_Exp>>;
  _not?: Maybe<Tutorial_Step_Bool_Exp>;
  _or?: Maybe<Array<Tutorial_Step_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  tutorial_id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<Enum_Tutorial_Step_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Tutorial_step" */
export enum Tutorial_Step_Constraint {
  /** unique or primary key constraint on columns "id" */
  TutorialStepPkey = 'Tutorial_step_pkey',
  /** unique or primary key constraint on columns "type", "tutorial_id" */
  TutorialStepTypeTutorialIdKey = 'Tutorial_step_type_tutorial_id_key'
}

/** input type for incrementing numeric columns in table "Tutorial_step" */
export type Tutorial_Step_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Tutorial_step" */
export type Tutorial_Step_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Tutorial_Step_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tutorial_Step_Max_Fields = {
  __typename?: 'Tutorial_step_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Tutorial_step" */
export type Tutorial_Step_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tutorial_Step_Min_Fields = {
  __typename?: 'Tutorial_step_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Tutorial_step" */
export type Tutorial_Step_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Tutorial_step" */
export type Tutorial_Step_Mutation_Response = {
  __typename?: 'Tutorial_step_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tutorial_Step>;
};

/** on_conflict condition type for table "Tutorial_step" */
export type Tutorial_Step_On_Conflict = {
  constraint: Tutorial_Step_Constraint;
  update_columns?: Array<Tutorial_Step_Update_Column>;
  where?: Maybe<Tutorial_Step_Bool_Exp>;
};

/** Ordering options when selecting data from "Tutorial_step". */
export type Tutorial_Step_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Tutorial_step */
export type Tutorial_Step_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Tutorial_step" */
export enum Tutorial_Step_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  TutorialId = 'tutorial_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Tutorial_step" */
export type Tutorial_Step_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Enum_Tutorial_Step_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tutorial_Step_Stddev_Fields = {
  __typename?: 'Tutorial_step_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Tutorial_step" */
export type Tutorial_Step_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tutorial_Step_Stddev_Pop_Fields = {
  __typename?: 'Tutorial_step_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Tutorial_step" */
export type Tutorial_Step_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tutorial_Step_Stddev_Samp_Fields = {
  __typename?: 'Tutorial_step_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Tutorial_step" */
export type Tutorial_Step_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tutorial_Step_Sum_Fields = {
  __typename?: 'Tutorial_step_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  order?: Maybe<Scalars['Int']>;
  tutorial_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Tutorial_step" */
export type Tutorial_Step_Sum_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** update columns of table "Tutorial_step" */
export enum Tutorial_Step_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  TutorialId = 'tutorial_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tutorial_Step_Var_Pop_Fields = {
  __typename?: 'Tutorial_step_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Tutorial_step" */
export type Tutorial_Step_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tutorial_Step_Var_Samp_Fields = {
  __typename?: 'Tutorial_step_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Tutorial_step" */
export type Tutorial_Step_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tutorial_Step_Variance_Fields = {
  __typename?: 'Tutorial_step_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  tutorial_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Tutorial_step" */
export type Tutorial_Step_Variance_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  tutorial_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tutorial_Sum_Fields = {
  __typename?: 'Tutorial_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Tutorial" */
export enum Tutorial_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Skippable = 'skippable',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tutorial_Var_Pop_Fields = {
  __typename?: 'Tutorial_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Tutorial_Var_Samp_Fields = {
  __typename?: 'Tutorial_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Tutorial_Variance_Fields = {
  __typename?: 'Tutorial_variance_fields';
  id?: Maybe<Scalars['Float']>;
};


export type UploadedFileResponse = {
  __typename?: 'UploadedFileResponse';
  encoding: Scalars['String'];
  file_metadata_id: Scalars['bigint'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  parent_id: Scalars['bigint'];
  url: Scalars['String'];
};

export type UserAuthState = {
  __typename?: 'UserAuthState';
  authTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
};

/** columns and relationships of "User_analytics_points_index" */
export type User_Analytics_Points_Index = {
  __typename?: 'User_analytics_points_index';
  analytics_index_type: Scalars['bigint'];
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  value: Scalars['numeric'];
};

/** aggregated selection of "User_analytics_points_index" */
export type User_Analytics_Points_Index_Aggregate = {
  __typename?: 'User_analytics_points_index_aggregate';
  aggregate?: Maybe<User_Analytics_Points_Index_Aggregate_Fields>;
  nodes: Array<User_Analytics_Points_Index>;
};

/** aggregate fields of "User_analytics_points_index" */
export type User_Analytics_Points_Index_Aggregate_Fields = {
  __typename?: 'User_analytics_points_index_aggregate_fields';
  avg?: Maybe<User_Analytics_Points_Index_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Analytics_Points_Index_Max_Fields>;
  min?: Maybe<User_Analytics_Points_Index_Min_Fields>;
  stddev?: Maybe<User_Analytics_Points_Index_Stddev_Fields>;
  stddev_pop?: Maybe<User_Analytics_Points_Index_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Analytics_Points_Index_Stddev_Samp_Fields>;
  sum?: Maybe<User_Analytics_Points_Index_Sum_Fields>;
  var_pop?: Maybe<User_Analytics_Points_Index_Var_Pop_Fields>;
  var_samp?: Maybe<User_Analytics_Points_Index_Var_Samp_Fields>;
  variance?: Maybe<User_Analytics_Points_Index_Variance_Fields>;
};


/** aggregate fields of "User_analytics_points_index" */
export type User_Analytics_Points_Index_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Analytics_Points_Index_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Analytics_Points_Index_Avg_Fields = {
  __typename?: 'User_analytics_points_index_avg_fields';
  analytics_index_type?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "User_analytics_points_index". All fields are combined with a logical 'AND'. */
export type User_Analytics_Points_Index_Bool_Exp = {
  _and?: Maybe<Array<User_Analytics_Points_Index_Bool_Exp>>;
  _not?: Maybe<User_Analytics_Points_Index_Bool_Exp>;
  _or?: Maybe<Array<User_Analytics_Points_Index_Bool_Exp>>;
  analytics_index_type?: Maybe<Bigint_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  value?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "User_analytics_points_index" */
export enum User_Analytics_Points_Index_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPointsIndexesPkey = 'Users_Points_Indexes_pkey'
}

/** input type for incrementing numeric columns in table "User_analytics_points_index" */
export type User_Analytics_Points_Index_Inc_Input = {
  analytics_index_type?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "User_analytics_points_index" */
export type User_Analytics_Points_Index_Insert_Input = {
  analytics_index_type?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type User_Analytics_Points_Index_Max_Fields = {
  __typename?: 'User_analytics_points_index_max_fields';
  analytics_index_type?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type User_Analytics_Points_Index_Min_Fields = {
  __typename?: 'User_analytics_points_index_min_fields';
  analytics_index_type?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "User_analytics_points_index" */
export type User_Analytics_Points_Index_Mutation_Response = {
  __typename?: 'User_analytics_points_index_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Analytics_Points_Index>;
};

/** on_conflict condition type for table "User_analytics_points_index" */
export type User_Analytics_Points_Index_On_Conflict = {
  constraint: User_Analytics_Points_Index_Constraint;
  update_columns?: Array<User_Analytics_Points_Index_Update_Column>;
  where?: Maybe<User_Analytics_Points_Index_Bool_Exp>;
};

/** Ordering options when selecting data from "User_analytics_points_index". */
export type User_Analytics_Points_Index_Order_By = {
  analytics_index_type?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: User_analytics_points_index */
export type User_Analytics_Points_Index_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "User_analytics_points_index" */
export enum User_Analytics_Points_Index_Select_Column {
  /** column name */
  AnalyticsIndexType = 'analytics_index_type',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "User_analytics_points_index" */
export type User_Analytics_Points_Index_Set_Input = {
  analytics_index_type?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type User_Analytics_Points_Index_Stddev_Fields = {
  __typename?: 'User_analytics_points_index_stddev_fields';
  analytics_index_type?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Analytics_Points_Index_Stddev_Pop_Fields = {
  __typename?: 'User_analytics_points_index_stddev_pop_fields';
  analytics_index_type?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Analytics_Points_Index_Stddev_Samp_Fields = {
  __typename?: 'User_analytics_points_index_stddev_samp_fields';
  analytics_index_type?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type User_Analytics_Points_Index_Sum_Fields = {
  __typename?: 'User_analytics_points_index_sum_fields';
  analytics_index_type?: Maybe<Scalars['bigint']>;
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** update columns of table "User_analytics_points_index" */
export enum User_Analytics_Points_Index_Update_Column {
  /** column name */
  AnalyticsIndexType = 'analytics_index_type',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type User_Analytics_Points_Index_Var_Pop_Fields = {
  __typename?: 'User_analytics_points_index_var_pop_fields';
  analytics_index_type?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Analytics_Points_Index_Var_Samp_Fields = {
  __typename?: 'User_analytics_points_index_var_samp_fields';
  analytics_index_type?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Analytics_Points_Index_Variance_Fields = {
  __typename?: 'User_analytics_points_index_variance_fields';
  analytics_index_type?: Maybe<Scalars['Float']>;
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "VR_artists" */
export type Vr_Artists = {
  __typename?: 'VR_artists';
  /** An object relationship */
  artist_context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "VR_artists" */
export type Vr_Artists_Aggregate = {
  __typename?: 'VR_artists_aggregate';
  aggregate?: Maybe<Vr_Artists_Aggregate_Fields>;
  nodes: Array<Vr_Artists>;
};

/** aggregate fields of "VR_artists" */
export type Vr_Artists_Aggregate_Fields = {
  __typename?: 'VR_artists_aggregate_fields';
  avg?: Maybe<Vr_Artists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vr_Artists_Max_Fields>;
  min?: Maybe<Vr_Artists_Min_Fields>;
  stddev?: Maybe<Vr_Artists_Stddev_Fields>;
  stddev_pop?: Maybe<Vr_Artists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vr_Artists_Stddev_Samp_Fields>;
  sum?: Maybe<Vr_Artists_Sum_Fields>;
  var_pop?: Maybe<Vr_Artists_Var_Pop_Fields>;
  var_samp?: Maybe<Vr_Artists_Var_Samp_Fields>;
  variance?: Maybe<Vr_Artists_Variance_Fields>;
};


/** aggregate fields of "VR_artists" */
export type Vr_Artists_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vr_Artists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vr_Artists_Avg_Fields = {
  __typename?: 'VR_artists_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "VR_artists". All fields are combined with a logical 'AND'. */
export type Vr_Artists_Bool_Exp = {
  _and?: Maybe<Array<Vr_Artists_Bool_Exp>>;
  _not?: Maybe<Vr_Artists_Bool_Exp>;
  _or?: Maybe<Array<Vr_Artists_Bool_Exp>>;
  artist_context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "VR_artists" */
export enum Vr_Artists_Constraint {
  /** unique or primary key constraint on columns "context_id" */
  VrArtistsContextIdKey = 'VR_artists_context_id_key',
  /** unique or primary key constraint on columns "id" */
  VrArtistsPkey = 'VR_artists_pkey'
}

/** input type for incrementing numeric columns in table "VR_artists" */
export type Vr_Artists_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "VR_artists" */
export type Vr_Artists_Insert_Input = {
  artist_context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Vr_Artists_Max_Fields = {
  __typename?: 'VR_artists_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Vr_Artists_Min_Fields = {
  __typename?: 'VR_artists_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "VR_artists" */
export type Vr_Artists_Mutation_Response = {
  __typename?: 'VR_artists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vr_Artists>;
};

/** on_conflict condition type for table "VR_artists" */
export type Vr_Artists_On_Conflict = {
  constraint: Vr_Artists_Constraint;
  update_columns?: Array<Vr_Artists_Update_Column>;
  where?: Maybe<Vr_Artists_Bool_Exp>;
};

/** Ordering options when selecting data from "VR_artists". */
export type Vr_Artists_Order_By = {
  artist_context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: VR_artists */
export type Vr_Artists_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "VR_artists" */
export enum Vr_Artists_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "VR_artists" */
export type Vr_Artists_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Vr_Artists_Stddev_Fields = {
  __typename?: 'VR_artists_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vr_Artists_Stddev_Pop_Fields = {
  __typename?: 'VR_artists_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vr_Artists_Stddev_Samp_Fields = {
  __typename?: 'VR_artists_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Vr_Artists_Sum_Fields = {
  __typename?: 'VR_artists_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "VR_artists" */
export enum Vr_Artists_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Vr_Artists_Var_Pop_Fields = {
  __typename?: 'VR_artists_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vr_Artists_Var_Samp_Fields = {
  __typename?: 'VR_artists_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vr_Artists_Variance_Fields = {
  __typename?: 'VR_artists_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View = {
  __typename?: 'Valid_profile_questions_and_answers_view';
  answer?: Maybe<Scalars['String']>;
  default_text?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Aggregate = {
  __typename?: 'Valid_profile_questions_and_answers_view_aggregate';
  aggregate?: Maybe<Valid_Profile_Questions_And_Answers_View_Aggregate_Fields>;
  nodes: Array<Valid_Profile_Questions_And_Answers_View>;
};

/** aggregate fields of "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Aggregate_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_aggregate_fields';
  avg?: Maybe<Valid_Profile_Questions_And_Answers_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Valid_Profile_Questions_And_Answers_View_Max_Fields>;
  min?: Maybe<Valid_Profile_Questions_And_Answers_View_Min_Fields>;
  stddev?: Maybe<Valid_Profile_Questions_And_Answers_View_Stddev_Fields>;
  stddev_pop?: Maybe<Valid_Profile_Questions_And_Answers_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Valid_Profile_Questions_And_Answers_View_Stddev_Samp_Fields>;
  sum?: Maybe<Valid_Profile_Questions_And_Answers_View_Sum_Fields>;
  var_pop?: Maybe<Valid_Profile_Questions_And_Answers_View_Var_Pop_Fields>;
  var_samp?: Maybe<Valid_Profile_Questions_And_Answers_View_Var_Samp_Fields>;
  variance?: Maybe<Valid_Profile_Questions_And_Answers_View_Variance_Fields>;
};


/** aggregate fields of "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Aggregate_Order_By = {
  avg?: Maybe<Valid_Profile_Questions_And_Answers_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Valid_Profile_Questions_And_Answers_View_Max_Order_By>;
  min?: Maybe<Valid_Profile_Questions_And_Answers_View_Min_Order_By>;
  stddev?: Maybe<Valid_Profile_Questions_And_Answers_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Valid_Profile_Questions_And_Answers_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Valid_Profile_Questions_And_Answers_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Valid_Profile_Questions_And_Answers_View_Sum_Order_By>;
  var_pop?: Maybe<Valid_Profile_Questions_And_Answers_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Valid_Profile_Questions_And_Answers_View_Var_Samp_Order_By>;
  variance?: Maybe<Valid_Profile_Questions_And_Answers_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Arr_Rel_Insert_Input = {
  data: Array<Valid_Profile_Questions_And_Answers_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Valid_Profile_Questions_And_Answers_View_Avg_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_avg_fields';
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Avg_Order_By = {
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Valid_profile_questions_and_answers_view". All fields are combined with a logical 'AND'. */
export type Valid_Profile_Questions_And_Answers_View_Bool_Exp = {
  _and?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Bool_Exp>>;
  _not?: Maybe<Valid_Profile_Questions_And_Answers_View_Bool_Exp>;
  _or?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Bool_Exp>>;
  answer?: Maybe<String_Comparison_Exp>;
  default_text?: Maybe<String_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  question_id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Insert_Input = {
  answer?: Maybe<Scalars['String']>;
  default_text?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Valid_Profile_Questions_And_Answers_View_Max_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_max_fields';
  answer?: Maybe<Scalars['String']>;
  default_text?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Max_Order_By = {
  answer?: Maybe<Order_By>;
  default_text?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Valid_Profile_Questions_And_Answers_View_Min_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_min_fields';
  answer?: Maybe<Scalars['String']>;
  default_text?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Min_Order_By = {
  answer?: Maybe<Order_By>;
  default_text?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "Valid_profile_questions_and_answers_view". */
export type Valid_Profile_Questions_And_Answers_View_Order_By = {
  answer?: Maybe<Order_By>;
  default_text?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "Valid_profile_questions_and_answers_view" */
export enum Valid_Profile_Questions_And_Answers_View_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  DefaultText = 'default_text',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Valid_Profile_Questions_And_Answers_View_Stddev_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_stddev_fields';
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Stddev_Order_By = {
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Valid_Profile_Questions_And_Answers_View_Stddev_Pop_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_stddev_pop_fields';
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Stddev_Pop_Order_By = {
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Valid_Profile_Questions_And_Answers_View_Stddev_Samp_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_stddev_samp_fields';
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Stddev_Samp_Order_By = {
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Valid_Profile_Questions_And_Answers_View_Sum_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_sum_fields';
  profile_id?: Maybe<Scalars['bigint']>;
  question_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Sum_Order_By = {
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Valid_Profile_Questions_And_Answers_View_Var_Pop_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_var_pop_fields';
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Var_Pop_Order_By = {
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Valid_Profile_Questions_And_Answers_View_Var_Samp_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_var_samp_fields';
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Var_Samp_Order_By = {
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Valid_Profile_Questions_And_Answers_View_Variance_Fields = {
  __typename?: 'Valid_profile_questions_and_answers_view_variance_fields';
  profile_id?: Maybe<Scalars['Float']>;
  question_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Valid_profile_questions_and_answers_view" */
export type Valid_Profile_Questions_And_Answers_View_Variance_Order_By = {
  profile_id?: Maybe<Order_By>;
  question_id?: Maybe<Order_By>;
};

/** columns and relationships of "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view';
  audio_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  audio_metadata?: Maybe<File_Metadata>;
  language?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Aggregate = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_aggregate';
  aggregate?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Aggregate_Fields>;
  nodes: Array<Valid_Profile_Statement_Audio_Syntheses_View>;
};

/** aggregate fields of "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Aggregate_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_aggregate_fields';
  avg?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Max_Fields>;
  min?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Min_Fields>;
  stddev?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Fields>;
  stddev_pop?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Samp_Fields>;
  sum?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Sum_Fields>;
  var_pop?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Var_Pop_Fields>;
  var_samp?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Var_Samp_Fields>;
  variance?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Variance_Fields>;
};


/** aggregate fields of "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Aggregate_Order_By = {
  avg?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Max_Order_By>;
  min?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Min_Order_By>;
  stddev?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Sum_Order_By>;
  var_pop?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Var_Samp_Order_By>;
  variance?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Arr_Rel_Insert_Input = {
  data: Array<Valid_Profile_Statement_Audio_Syntheses_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Avg_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_avg_fields';
  audio_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Avg_Order_By = {
  audio_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Valid_profile_statement_audio_syntheses_view". All fields are combined with a logical 'AND'. */
export type Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp = {
  _and?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>>;
  _not?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>;
  _or?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>>;
  audio_id?: Maybe<Bigint_Comparison_Exp>;
  audio_metadata?: Maybe<File_Metadata_Bool_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  profile_id?: Maybe<Bigint_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Insert_Input = {
  audio_id?: Maybe<Scalars['bigint']>;
  audio_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  language?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Max_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_max_fields';
  audio_id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Max_Order_By = {
  audio_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Min_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_min_fields';
  audio_id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Min_Order_By = {
  audio_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "Valid_profile_statement_audio_syntheses_view". */
export type Valid_Profile_Statement_Audio_Syntheses_View_Order_By = {
  audio_id?: Maybe<Order_By>;
  audio_metadata?: Maybe<File_Metadata_Order_By>;
  language?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** select columns of table "Valid_profile_statement_audio_syntheses_view" */
export enum Valid_Profile_Statement_Audio_Syntheses_View_Select_Column {
  /** column name */
  AudioId = 'audio_id',
  /** column name */
  Language = 'language',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  Text = 'text'
}

/** aggregate stddev on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_stddev_fields';
  audio_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Order_By = {
  audio_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Pop_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_stddev_pop_fields';
  audio_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Pop_Order_By = {
  audio_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Samp_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_stddev_samp_fields';
  audio_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Stddev_Samp_Order_By = {
  audio_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Sum_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_sum_fields';
  audio_id?: Maybe<Scalars['bigint']>;
  profile_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Sum_Order_By = {
  audio_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Var_Pop_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_var_pop_fields';
  audio_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Var_Pop_Order_By = {
  audio_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Var_Samp_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_var_samp_fields';
  audio_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Var_Samp_Order_By = {
  audio_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Valid_Profile_Statement_Audio_Syntheses_View_Variance_Fields = {
  __typename?: 'Valid_profile_statement_audio_syntheses_view_variance_fields';
  audio_id?: Maybe<Scalars['Float']>;
  profile_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Valid_profile_statement_audio_syntheses_view" */
export type Valid_Profile_Statement_Audio_Syntheses_View_Variance_Order_By = {
  audio_id?: Maybe<Order_By>;
  profile_id?: Maybe<Order_By>;
};

/** columns and relationships of "Vision_active_interests" */
export type Vision_Active_Interests = {
  __typename?: 'Vision_active_interests';
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Vision_active_interests" */
export type Vision_Active_Interests_Aggregate = {
  __typename?: 'Vision_active_interests_aggregate';
  aggregate?: Maybe<Vision_Active_Interests_Aggregate_Fields>;
  nodes: Array<Vision_Active_Interests>;
};

/** aggregate fields of "Vision_active_interests" */
export type Vision_Active_Interests_Aggregate_Fields = {
  __typename?: 'Vision_active_interests_aggregate_fields';
  avg?: Maybe<Vision_Active_Interests_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vision_Active_Interests_Max_Fields>;
  min?: Maybe<Vision_Active_Interests_Min_Fields>;
  stddev?: Maybe<Vision_Active_Interests_Stddev_Fields>;
  stddev_pop?: Maybe<Vision_Active_Interests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vision_Active_Interests_Stddev_Samp_Fields>;
  sum?: Maybe<Vision_Active_Interests_Sum_Fields>;
  var_pop?: Maybe<Vision_Active_Interests_Var_Pop_Fields>;
  var_samp?: Maybe<Vision_Active_Interests_Var_Samp_Fields>;
  variance?: Maybe<Vision_Active_Interests_Variance_Fields>;
};


/** aggregate fields of "Vision_active_interests" */
export type Vision_Active_Interests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vision_Active_Interests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vision_Active_Interests_Avg_Fields = {
  __typename?: 'Vision_active_interests_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Vision_active_interests". All fields are combined with a logical 'AND'. */
export type Vision_Active_Interests_Bool_Exp = {
  _and?: Maybe<Array<Vision_Active_Interests_Bool_Exp>>;
  _not?: Maybe<Vision_Active_Interests_Bool_Exp>;
  _or?: Maybe<Array<Vision_Active_Interests_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  translation_key?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vision_Active_Interests_Max_Fields = {
  __typename?: 'Vision_active_interests_max_fields';
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Vision_Active_Interests_Min_Fields = {
  __typename?: 'Vision_active_interests_min_fields';
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "Vision_active_interests". */
export type Vision_Active_Interests_Order_By = {
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  translation_key?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "Vision_active_interests" */
export enum Vision_Active_Interests_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translation_key',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Vision_Active_Interests_Stddev_Fields = {
  __typename?: 'Vision_active_interests_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vision_Active_Interests_Stddev_Pop_Fields = {
  __typename?: 'Vision_active_interests_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vision_Active_Interests_Stddev_Samp_Fields = {
  __typename?: 'Vision_active_interests_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Vision_Active_Interests_Sum_Fields = {
  __typename?: 'Vision_active_interests_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Vision_Active_Interests_Var_Pop_Fields = {
  __typename?: 'Vision_active_interests_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vision_Active_Interests_Var_Samp_Fields = {
  __typename?: 'Vision_active_interests_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vision_Active_Interests_Variance_Fields = {
  __typename?: 'Vision_active_interests_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Same as Interests table, but with some overrides specific for the VisionOS app */
export type Vision_Interest = {
  __typename?: 'Vision_interest';
  /** An object relationship */
  Interest: Interests;
  active?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

/** aggregated selection of "Vision_interest" */
export type Vision_Interest_Aggregate = {
  __typename?: 'Vision_interest_aggregate';
  aggregate?: Maybe<Vision_Interest_Aggregate_Fields>;
  nodes: Array<Vision_Interest>;
};

/** aggregate fields of "Vision_interest" */
export type Vision_Interest_Aggregate_Fields = {
  __typename?: 'Vision_interest_aggregate_fields';
  avg?: Maybe<Vision_Interest_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vision_Interest_Max_Fields>;
  min?: Maybe<Vision_Interest_Min_Fields>;
  stddev?: Maybe<Vision_Interest_Stddev_Fields>;
  stddev_pop?: Maybe<Vision_Interest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vision_Interest_Stddev_Samp_Fields>;
  sum?: Maybe<Vision_Interest_Sum_Fields>;
  var_pop?: Maybe<Vision_Interest_Var_Pop_Fields>;
  var_samp?: Maybe<Vision_Interest_Var_Samp_Fields>;
  variance?: Maybe<Vision_Interest_Variance_Fields>;
};


/** aggregate fields of "Vision_interest" */
export type Vision_Interest_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vision_Interest_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vision_Interest_Avg_Fields = {
  __typename?: 'Vision_interest_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Vision_interest". All fields are combined with a logical 'AND'. */
export type Vision_Interest_Bool_Exp = {
  Interest?: Maybe<Interests_Bool_Exp>;
  _and?: Maybe<Array<Vision_Interest_Bool_Exp>>;
  _not?: Maybe<Vision_Interest_Bool_Exp>;
  _or?: Maybe<Array<Vision_Interest_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Vision_interest" */
export enum Vision_Interest_Constraint {
  /** unique or primary key constraint on columns "id" */
  VisionInterestPkey = 'Vision_interest_pkey'
}

/** input type for incrementing numeric columns in table "Vision_interest" */
export type Vision_Interest_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Vision_interest" */
export type Vision_Interest_Insert_Input = {
  Interest?: Maybe<Interests_Obj_Rel_Insert_Input>;
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Vision_Interest_Max_Fields = {
  __typename?: 'Vision_interest_max_fields';
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Vision_Interest_Min_Fields = {
  __typename?: 'Vision_interest_min_fields';
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "Vision_interest" */
export type Vision_Interest_Mutation_Response = {
  __typename?: 'Vision_interest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vision_Interest>;
};

/** on_conflict condition type for table "Vision_interest" */
export type Vision_Interest_On_Conflict = {
  constraint: Vision_Interest_Constraint;
  update_columns?: Array<Vision_Interest_Update_Column>;
  where?: Maybe<Vision_Interest_Bool_Exp>;
};

/** Ordering options when selecting data from "Vision_interest". */
export type Vision_Interest_Order_By = {
  Interest?: Maybe<Interests_Order_By>;
  active?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** primary key columns input for table: Vision_interest */
export type Vision_Interest_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Vision_interest" */
export enum Vision_Interest_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order'
}

/** input type for updating data in table "Vision_interest" */
export type Vision_Interest_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Vision_Interest_Stddev_Fields = {
  __typename?: 'Vision_interest_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vision_Interest_Stddev_Pop_Fields = {
  __typename?: 'Vision_interest_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vision_Interest_Stddev_Samp_Fields = {
  __typename?: 'Vision_interest_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Vision_Interest_Sum_Fields = {
  __typename?: 'Vision_interest_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** update columns of table "Vision_interest" */
export enum Vision_Interest_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order'
}

/** aggregate var_pop on columns */
export type Vision_Interest_Var_Pop_Fields = {
  __typename?: 'Vision_interest_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vision_Interest_Var_Samp_Fields = {
  __typename?: 'Vision_interest_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vision_Interest_Variance_Fields = {
  __typename?: 'Vision_interest_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "Vumarks" */
export type Vumarks = {
  __typename?: 'Vumarks';
  /** An object relationship */
  artwork?: Maybe<Artwork>;
  artwork_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file_metadata: File_Metadata;
  file_metadata_id: Scalars['bigint'];
  id: Scalars['Int'];
  /** An object relationship */
  print?: Maybe<Print>;
  status?: Maybe<Enum_Vumark_Status_Enum>;
  type: Enum_Vumark_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Vumarks" */
export type Vumarks_Aggregate = {
  __typename?: 'Vumarks_aggregate';
  aggregate?: Maybe<Vumarks_Aggregate_Fields>;
  nodes: Array<Vumarks>;
};

/** aggregate fields of "Vumarks" */
export type Vumarks_Aggregate_Fields = {
  __typename?: 'Vumarks_aggregate_fields';
  avg?: Maybe<Vumarks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vumarks_Max_Fields>;
  min?: Maybe<Vumarks_Min_Fields>;
  stddev?: Maybe<Vumarks_Stddev_Fields>;
  stddev_pop?: Maybe<Vumarks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vumarks_Stddev_Samp_Fields>;
  sum?: Maybe<Vumarks_Sum_Fields>;
  var_pop?: Maybe<Vumarks_Var_Pop_Fields>;
  var_samp?: Maybe<Vumarks_Var_Samp_Fields>;
  variance?: Maybe<Vumarks_Variance_Fields>;
};


/** aggregate fields of "Vumarks" */
export type Vumarks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vumarks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Vumarks" */
export type Vumarks_Aggregate_Order_By = {
  avg?: Maybe<Vumarks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Vumarks_Max_Order_By>;
  min?: Maybe<Vumarks_Min_Order_By>;
  stddev?: Maybe<Vumarks_Stddev_Order_By>;
  stddev_pop?: Maybe<Vumarks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Vumarks_Stddev_Samp_Order_By>;
  sum?: Maybe<Vumarks_Sum_Order_By>;
  var_pop?: Maybe<Vumarks_Var_Pop_Order_By>;
  var_samp?: Maybe<Vumarks_Var_Samp_Order_By>;
  variance?: Maybe<Vumarks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Vumarks" */
export type Vumarks_Arr_Rel_Insert_Input = {
  data: Array<Vumarks_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Vumarks_On_Conflict>;
};

/** aggregate avg on columns */
export type Vumarks_Avg_Fields = {
  __typename?: 'Vumarks_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Vumarks" */
export type Vumarks_Avg_Order_By = {
  artwork_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Vumarks". All fields are combined with a logical 'AND'. */
export type Vumarks_Bool_Exp = {
  _and?: Maybe<Array<Vumarks_Bool_Exp>>;
  _not?: Maybe<Vumarks_Bool_Exp>;
  _or?: Maybe<Array<Vumarks_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_metadata?: Maybe<File_Metadata_Bool_Exp>;
  file_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  print?: Maybe<Print_Bool_Exp>;
  status?: Maybe<Enum_Vumark_Status_Enum_Comparison_Exp>;
  type?: Maybe<Enum_Vumark_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Vumarks" */
export enum Vumarks_Constraint {
  /** unique or primary key constraint on columns "id" */
  VumarksPkey = 'Vumarks_pkey'
}

/** input type for incrementing numeric columns in table "Vumarks" */
export type Vumarks_Inc_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Vumarks" */
export type Vumarks_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  print?: Maybe<Print_Obj_Rel_Insert_Input>;
  status?: Maybe<Enum_Vumark_Status_Enum>;
  type?: Maybe<Enum_Vumark_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Vumarks_Max_Fields = {
  __typename?: 'Vumarks_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Vumarks" */
export type Vumarks_Max_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vumarks_Min_Fields = {
  __typename?: 'Vumarks_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Vumarks" */
export type Vumarks_Min_Order_By = {
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Vumarks" */
export type Vumarks_Mutation_Response = {
  __typename?: 'Vumarks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vumarks>;
};

/** on_conflict condition type for table "Vumarks" */
export type Vumarks_On_Conflict = {
  constraint: Vumarks_Constraint;
  update_columns?: Array<Vumarks_Update_Column>;
  where?: Maybe<Vumarks_Bool_Exp>;
};

/** Ordering options when selecting data from "Vumarks". */
export type Vumarks_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_metadata?: Maybe<File_Metadata_Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  print?: Maybe<Print_Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Vumarks */
export type Vumarks_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Vumarks" */
export enum Vumarks_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Vumarks" */
export type Vumarks_Set_Input = {
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Enum_Vumark_Status_Enum>;
  type?: Maybe<Enum_Vumark_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Vumarks_Stddev_Fields = {
  __typename?: 'Vumarks_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Vumarks" */
export type Vumarks_Stddev_Order_By = {
  artwork_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vumarks_Stddev_Pop_Fields = {
  __typename?: 'Vumarks_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Vumarks" */
export type Vumarks_Stddev_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vumarks_Stddev_Samp_Fields = {
  __typename?: 'Vumarks_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Vumarks" */
export type Vumarks_Stddev_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Vumarks_Sum_Fields = {
  __typename?: 'Vumarks_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  file_metadata_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Vumarks" */
export type Vumarks_Sum_Order_By = {
  artwork_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Vumarks" */
export enum Vumarks_Update_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileMetadataId = 'file_metadata_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Vumarks_Var_Pop_Fields = {
  __typename?: 'Vumarks_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Vumarks" */
export type Vumarks_Var_Pop_Order_By = {
  artwork_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vumarks_Var_Samp_Fields = {
  __typename?: 'Vumarks_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Vumarks" */
export type Vumarks_Var_Samp_Order_By = {
  artwork_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Vumarks_Variance_Fields = {
  __typename?: 'Vumarks_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  file_metadata_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Vumarks" */
export type Vumarks_Variance_Order_By = {
  artwork_id?: Maybe<Order_By>;
  file_metadata_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Stores the subscriptions from the landing page.  */
export type Waiting_List = {
  __typename?: 'Waiting_list';
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  instagram_url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
  website?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Waiting_list" */
export type Waiting_List_Aggregate = {
  __typename?: 'Waiting_list_aggregate';
  aggregate?: Maybe<Waiting_List_Aggregate_Fields>;
  nodes: Array<Waiting_List>;
};

/** aggregate fields of "Waiting_list" */
export type Waiting_List_Aggregate_Fields = {
  __typename?: 'Waiting_list_aggregate_fields';
  avg?: Maybe<Waiting_List_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Waiting_List_Max_Fields>;
  min?: Maybe<Waiting_List_Min_Fields>;
  stddev?: Maybe<Waiting_List_Stddev_Fields>;
  stddev_pop?: Maybe<Waiting_List_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Waiting_List_Stddev_Samp_Fields>;
  sum?: Maybe<Waiting_List_Sum_Fields>;
  var_pop?: Maybe<Waiting_List_Var_Pop_Fields>;
  var_samp?: Maybe<Waiting_List_Var_Samp_Fields>;
  variance?: Maybe<Waiting_List_Variance_Fields>;
};


/** aggregate fields of "Waiting_list" */
export type Waiting_List_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Waiting_List_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Waiting_List_Avg_Fields = {
  __typename?: 'Waiting_list_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Waiting_list". All fields are combined with a logical 'AND'. */
export type Waiting_List_Bool_Exp = {
  _and?: Maybe<Array<Waiting_List_Bool_Exp>>;
  _not?: Maybe<Waiting_List_Bool_Exp>;
  _or?: Maybe<Array<Waiting_List_Bool_Exp>>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  instagram_url?: Maybe<String_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  website?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Waiting_list" */
export enum Waiting_List_Constraint {
  /** unique or primary key constraint on columns "id" */
  WaitingListPkey = 'Waiting_list_pkey'
}

/** input type for incrementing numeric columns in table "Waiting_list" */
export type Waiting_List_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Waiting_list" */
export type Waiting_List_Insert_Input = {
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  instagram_url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Waiting_List_Max_Fields = {
  __typename?: 'Waiting_list_max_fields';
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  instagram_url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Waiting_List_Min_Fields = {
  __typename?: 'Waiting_list_min_fields';
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  instagram_url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Waiting_list" */
export type Waiting_List_Mutation_Response = {
  __typename?: 'Waiting_list_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Waiting_List>;
};

/** on_conflict condition type for table "Waiting_list" */
export type Waiting_List_On_Conflict = {
  constraint: Waiting_List_Constraint;
  update_columns?: Array<Waiting_List_Update_Column>;
  where?: Maybe<Waiting_List_Bool_Exp>;
};

/** Ordering options when selecting data from "Waiting_list". */
export type Waiting_List_Order_By = {
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instagram_url?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** primary key columns input for table: Waiting_list */
export type Waiting_List_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Waiting_list" */
export enum Waiting_List_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InstagramUrl = 'instagram_url',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "Waiting_list" */
export type Waiting_List_Set_Input = {
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  instagram_url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Waiting_List_Stddev_Fields = {
  __typename?: 'Waiting_list_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Waiting_List_Stddev_Pop_Fields = {
  __typename?: 'Waiting_list_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Waiting_List_Stddev_Samp_Fields = {
  __typename?: 'Waiting_list_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Waiting_List_Sum_Fields = {
  __typename?: 'Waiting_list_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "Waiting_list" */
export enum Waiting_List_Update_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InstagramUrl = 'instagram_url',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Name = 'name',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Website = 'website'
}

/** aggregate var_pop on columns */
export type Waiting_List_Var_Pop_Fields = {
  __typename?: 'Waiting_list_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Waiting_List_Var_Samp_Fields = {
  __typename?: 'Waiting_list_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Waiting_List_Variance_Fields = {
  __typename?: 'Waiting_list_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Holds the wallet addresses of each account */
export type Wallets = {
  __typename?: 'Wallets';
  /** An object relationship */
  Context: Context;
  address: Scalars['String'];
  blockchain?: Maybe<Scalars['String']>;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "Wallets" */
export type Wallets_Aggregate = {
  __typename?: 'Wallets_aggregate';
  aggregate?: Maybe<Wallets_Aggregate_Fields>;
  nodes: Array<Wallets>;
};

/** aggregate fields of "Wallets" */
export type Wallets_Aggregate_Fields = {
  __typename?: 'Wallets_aggregate_fields';
  avg?: Maybe<Wallets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wallets_Max_Fields>;
  min?: Maybe<Wallets_Min_Fields>;
  stddev?: Maybe<Wallets_Stddev_Fields>;
  stddev_pop?: Maybe<Wallets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wallets_Stddev_Samp_Fields>;
  sum?: Maybe<Wallets_Sum_Fields>;
  var_pop?: Maybe<Wallets_Var_Pop_Fields>;
  var_samp?: Maybe<Wallets_Var_Samp_Fields>;
  variance?: Maybe<Wallets_Variance_Fields>;
};


/** aggregate fields of "Wallets" */
export type Wallets_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wallets_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Wallets" */
export type Wallets_Aggregate_Order_By = {
  avg?: Maybe<Wallets_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wallets_Max_Order_By>;
  min?: Maybe<Wallets_Min_Order_By>;
  stddev?: Maybe<Wallets_Stddev_Order_By>;
  stddev_pop?: Maybe<Wallets_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wallets_Stddev_Samp_Order_By>;
  sum?: Maybe<Wallets_Sum_Order_By>;
  var_pop?: Maybe<Wallets_Var_Pop_Order_By>;
  var_samp?: Maybe<Wallets_Var_Samp_Order_By>;
  variance?: Maybe<Wallets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Wallets" */
export type Wallets_Arr_Rel_Insert_Input = {
  data: Array<Wallets_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Wallets_On_Conflict>;
};

/** aggregate avg on columns */
export type Wallets_Avg_Fields = {
  __typename?: 'Wallets_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Wallets" */
export type Wallets_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Wallets". All fields are combined with a logical 'AND'. */
export type Wallets_Bool_Exp = {
  Context?: Maybe<Context_Bool_Exp>;
  _and?: Maybe<Array<Wallets_Bool_Exp>>;
  _not?: Maybe<Wallets_Bool_Exp>;
  _or?: Maybe<Array<Wallets_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  blockchain?: Maybe<String_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  trashed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Wallets" */
export enum Wallets_Constraint {
  /** unique or primary key constraint on columns "id" */
  WalletsPkey = 'Wallets_pkey'
}

/** input type for incrementing numeric columns in table "Wallets" */
export type Wallets_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "Wallets" */
export type Wallets_Insert_Input = {
  Context?: Maybe<Context_Obj_Rel_Insert_Input>;
  address?: Maybe<Scalars['String']>;
  blockchain?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Wallets_Max_Fields = {
  __typename?: 'Wallets_max_fields';
  address?: Maybe<Scalars['String']>;
  blockchain?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Wallets" */
export type Wallets_Max_Order_By = {
  address?: Maybe<Order_By>;
  blockchain?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wallets_Min_Fields = {
  __typename?: 'Wallets_min_fields';
  address?: Maybe<Scalars['String']>;
  blockchain?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Wallets" */
export type Wallets_Min_Order_By = {
  address?: Maybe<Order_By>;
  blockchain?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "Wallets" */
export type Wallets_Mutation_Response = {
  __typename?: 'Wallets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallets>;
};

/** on_conflict condition type for table "Wallets" */
export type Wallets_On_Conflict = {
  constraint: Wallets_Constraint;
  update_columns?: Array<Wallets_Update_Column>;
  where?: Maybe<Wallets_Bool_Exp>;
};

/** Ordering options when selecting data from "Wallets". */
export type Wallets_Order_By = {
  Context?: Maybe<Context_Order_By>;
  address?: Maybe<Order_By>;
  blockchain?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  trashed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: Wallets */
export type Wallets_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "Wallets" */
export enum Wallets_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Blockchain = 'blockchain',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "Wallets" */
export type Wallets_Set_Input = {
  address?: Maybe<Scalars['String']>;
  blockchain?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  trashed_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Wallets_Stddev_Fields = {
  __typename?: 'Wallets_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Wallets" */
export type Wallets_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wallets_Stddev_Pop_Fields = {
  __typename?: 'Wallets_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Wallets" */
export type Wallets_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wallets_Stddev_Samp_Fields = {
  __typename?: 'Wallets_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Wallets" */
export type Wallets_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Wallets_Sum_Fields = {
  __typename?: 'Wallets_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "Wallets" */
export type Wallets_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "Wallets" */
export enum Wallets_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Blockchain = 'blockchain',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TrashedAt = 'trashed_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Wallets_Var_Pop_Fields = {
  __typename?: 'Wallets_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Wallets" */
export type Wallets_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wallets_Var_Samp_Fields = {
  __typename?: 'Wallets_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Wallets" */
export type Wallets_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wallets_Variance_Fields = {
  __typename?: 'Wallets_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Wallets" */
export type Wallets_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** This table contains a list of all the ~40k cities across the world and their coordinates */
export type World_Cities = {
  __typename?: 'World_cities';
  continent_iso2?: Maybe<Scalars['String']>;
  country_iso2: Scalars['String'];
  country_iso3: Scalars['String'];
  country_name: Scalars['String'];
  display_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  lat: Scalars['numeric'];
  lng: Scalars['numeric'];
  name: Scalars['String'];
  state_code: Scalars['String'];
  state_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** aggregated selection of "World_cities" */
export type World_Cities_Aggregate = {
  __typename?: 'World_cities_aggregate';
  aggregate?: Maybe<World_Cities_Aggregate_Fields>;
  nodes: Array<World_Cities>;
};

/** aggregate fields of "World_cities" */
export type World_Cities_Aggregate_Fields = {
  __typename?: 'World_cities_aggregate_fields';
  avg?: Maybe<World_Cities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<World_Cities_Max_Fields>;
  min?: Maybe<World_Cities_Min_Fields>;
  stddev?: Maybe<World_Cities_Stddev_Fields>;
  stddev_pop?: Maybe<World_Cities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<World_Cities_Stddev_Samp_Fields>;
  sum?: Maybe<World_Cities_Sum_Fields>;
  var_pop?: Maybe<World_Cities_Var_Pop_Fields>;
  var_samp?: Maybe<World_Cities_Var_Samp_Fields>;
  variance?: Maybe<World_Cities_Variance_Fields>;
};


/** aggregate fields of "World_cities" */
export type World_Cities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<World_Cities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type World_Cities_Avg_Fields = {
  __typename?: 'World_cities_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "World_cities". All fields are combined with a logical 'AND'. */
export type World_Cities_Bool_Exp = {
  _and?: Maybe<Array<World_Cities_Bool_Exp>>;
  _not?: Maybe<World_Cities_Bool_Exp>;
  _or?: Maybe<Array<World_Cities_Bool_Exp>>;
  continent_iso2?: Maybe<String_Comparison_Exp>;
  country_iso2?: Maybe<String_Comparison_Exp>;
  country_iso3?: Maybe<String_Comparison_Exp>;
  country_name?: Maybe<String_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  lat?: Maybe<Numeric_Comparison_Exp>;
  lng?: Maybe<Numeric_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  state_code?: Maybe<String_Comparison_Exp>;
  state_name?: Maybe<String_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "World_cities" */
export enum World_Cities_Constraint {
  /** unique or primary key constraint on columns "name", "state_code", "country_iso2" */
  WorldCitiesNameStateCodeCountryIso2Key = 'World_cities_name_state_code_country_iso2_key',
  /** unique or primary key constraint on columns "id" */
  WorldCitiesPkey = 'World_cities_pkey'
}

/** input type for incrementing numeric columns in table "World_cities" */
export type World_Cities_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "World_cities" */
export type World_Cities_Insert_Input = {
  continent_iso2?: Maybe<Scalars['String']>;
  country_iso2?: Maybe<Scalars['String']>;
  country_iso3?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  state_code?: Maybe<Scalars['String']>;
  state_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type World_Cities_Max_Fields = {
  __typename?: 'World_cities_max_fields';
  continent_iso2?: Maybe<Scalars['String']>;
  country_iso2?: Maybe<Scalars['String']>;
  country_iso3?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  state_code?: Maybe<Scalars['String']>;
  state_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type World_Cities_Min_Fields = {
  __typename?: 'World_cities_min_fields';
  continent_iso2?: Maybe<Scalars['String']>;
  country_iso2?: Maybe<Scalars['String']>;
  country_iso3?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  state_code?: Maybe<Scalars['String']>;
  state_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "World_cities" */
export type World_Cities_Mutation_Response = {
  __typename?: 'World_cities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<World_Cities>;
};

/** input type for inserting object relation for remote table "World_cities" */
export type World_Cities_Obj_Rel_Insert_Input = {
  data: World_Cities_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<World_Cities_On_Conflict>;
};

/** on_conflict condition type for table "World_cities" */
export type World_Cities_On_Conflict = {
  constraint: World_Cities_Constraint;
  update_columns?: Array<World_Cities_Update_Column>;
  where?: Maybe<World_Cities_Bool_Exp>;
};

/** Ordering options when selecting data from "World_cities". */
export type World_Cities_Order_By = {
  continent_iso2?: Maybe<Order_By>;
  country_iso2?: Maybe<Order_By>;
  country_iso3?: Maybe<Order_By>;
  country_name?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  state_code?: Maybe<Order_By>;
  state_name?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
};

/** primary key columns input for table: World_cities */
export type World_Cities_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "World_cities" */
export enum World_Cities_Select_Column {
  /** column name */
  ContinentIso2 = 'continent_iso2',
  /** column name */
  CountryIso2 = 'country_iso2',
  /** column name */
  CountryIso3 = 'country_iso3',
  /** column name */
  CountryName = 'country_name',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Name = 'name',
  /** column name */
  StateCode = 'state_code',
  /** column name */
  StateName = 'state_name',
  /** column name */
  Timezone = 'timezone'
}

/** input type for updating data in table "World_cities" */
export type World_Cities_Set_Input = {
  continent_iso2?: Maybe<Scalars['String']>;
  country_iso2?: Maybe<Scalars['String']>;
  country_iso3?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  state_code?: Maybe<Scalars['String']>;
  state_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type World_Cities_Stddev_Fields = {
  __typename?: 'World_cities_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type World_Cities_Stddev_Pop_Fields = {
  __typename?: 'World_cities_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type World_Cities_Stddev_Samp_Fields = {
  __typename?: 'World_cities_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type World_Cities_Sum_Fields = {
  __typename?: 'World_cities_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** update columns of table "World_cities" */
export enum World_Cities_Update_Column {
  /** column name */
  ContinentIso2 = 'continent_iso2',
  /** column name */
  CountryIso2 = 'country_iso2',
  /** column name */
  CountryIso3 = 'country_iso3',
  /** column name */
  CountryName = 'country_name',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Name = 'name',
  /** column name */
  StateCode = 'state_code',
  /** column name */
  StateName = 'state_name',
  /** column name */
  Timezone = 'timezone'
}

/** aggregate var_pop on columns */
export type World_Cities_Var_Pop_Fields = {
  __typename?: 'World_cities_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type World_Cities_Var_Samp_Fields = {
  __typename?: 'World_cities_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type World_Cities_Variance_Fields = {
  __typename?: 'World_cities_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type AggregateProxyVariables = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  artist_context_ids?: Maybe<Array<Maybe<Scalars['bigint']>>>;
  collection_type?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  country_code?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "app_notifications_info" */
export type App_Notifications_Info = {
  __typename?: 'app_notifications_info';
  /** An object relationship */
  context?: Maybe<Context>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "app_notifications_info" */
export type App_Notifications_Info_Aggregate = {
  __typename?: 'app_notifications_info_aggregate';
  aggregate?: Maybe<App_Notifications_Info_Aggregate_Fields>;
  nodes: Array<App_Notifications_Info>;
};

/** aggregate fields of "app_notifications_info" */
export type App_Notifications_Info_Aggregate_Fields = {
  __typename?: 'app_notifications_info_aggregate_fields';
  avg?: Maybe<App_Notifications_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<App_Notifications_Info_Max_Fields>;
  min?: Maybe<App_Notifications_Info_Min_Fields>;
  stddev?: Maybe<App_Notifications_Info_Stddev_Fields>;
  stddev_pop?: Maybe<App_Notifications_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Notifications_Info_Stddev_Samp_Fields>;
  sum?: Maybe<App_Notifications_Info_Sum_Fields>;
  var_pop?: Maybe<App_Notifications_Info_Var_Pop_Fields>;
  var_samp?: Maybe<App_Notifications_Info_Var_Samp_Fields>;
  variance?: Maybe<App_Notifications_Info_Variance_Fields>;
};


/** aggregate fields of "app_notifications_info" */
export type App_Notifications_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Notifications_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type App_Notifications_Info_Avg_Fields = {
  __typename?: 'app_notifications_info_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "app_notifications_info". All fields are combined with a logical 'AND'. */
export type App_Notifications_Info_Bool_Exp = {
  _and?: Maybe<Array<App_Notifications_Info_Bool_Exp>>;
  _not?: Maybe<App_Notifications_Info_Bool_Exp>;
  _or?: Maybe<Array<App_Notifications_Info_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  read_at?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_message_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "app_notifications_info" */
export type App_Notifications_Info_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "app_notifications_info" */
export type App_Notifications_Info_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type App_Notifications_Info_Max_Fields = {
  __typename?: 'app_notifications_info_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type App_Notifications_Info_Min_Fields = {
  __typename?: 'app_notifications_info_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "app_notifications_info" */
export type App_Notifications_Info_Mutation_Response = {
  __typename?: 'app_notifications_info_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Notifications_Info>;
};

/** Ordering options when selecting data from "app_notifications_info". */
export type App_Notifications_Info_Order_By = {
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_message_at?: Maybe<Order_By>;
};

/** select columns of table "app_notifications_info" */
export enum App_Notifications_Info_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedMessageAt = 'updated_message_at'
}

/** input type for updating data in table "app_notifications_info" */
export type App_Notifications_Info_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type App_Notifications_Info_Stddev_Fields = {
  __typename?: 'app_notifications_info_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type App_Notifications_Info_Stddev_Pop_Fields = {
  __typename?: 'app_notifications_info_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type App_Notifications_Info_Stddev_Samp_Fields = {
  __typename?: 'app_notifications_info_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type App_Notifications_Info_Sum_Fields = {
  __typename?: 'app_notifications_info_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type App_Notifications_Info_Var_Pop_Fields = {
  __typename?: 'app_notifications_info_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type App_Notifications_Info_Var_Samp_Fields = {
  __typename?: 'app_notifications_info_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type App_Notifications_Info_Variance_Fields = {
  __typename?: 'app_notifications_info_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "app_notifications_listing" */
export type App_Notifications_Listing = {
  __typename?: 'app_notifications_listing';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "app_notifications_listing" */
export type App_Notifications_Listing_Aggregate = {
  __typename?: 'app_notifications_listing_aggregate';
  aggregate?: Maybe<App_Notifications_Listing_Aggregate_Fields>;
  nodes: Array<App_Notifications_Listing>;
};

/** aggregate fields of "app_notifications_listing" */
export type App_Notifications_Listing_Aggregate_Fields = {
  __typename?: 'app_notifications_listing_aggregate_fields';
  avg?: Maybe<App_Notifications_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<App_Notifications_Listing_Max_Fields>;
  min?: Maybe<App_Notifications_Listing_Min_Fields>;
  stddev?: Maybe<App_Notifications_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<App_Notifications_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Notifications_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<App_Notifications_Listing_Sum_Fields>;
  var_pop?: Maybe<App_Notifications_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<App_Notifications_Listing_Var_Samp_Fields>;
  variance?: Maybe<App_Notifications_Listing_Variance_Fields>;
};


/** aggregate fields of "app_notifications_listing" */
export type App_Notifications_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Notifications_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type App_Notifications_Listing_Avg_Fields = {
  __typename?: 'app_notifications_listing_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "app_notifications_listing". All fields are combined with a logical 'AND'. */
export type App_Notifications_Listing_Bool_Exp = {
  _and?: Maybe<Array<App_Notifications_Listing_Bool_Exp>>;
  _not?: Maybe<App_Notifications_Listing_Bool_Exp>;
  _or?: Maybe<Array<App_Notifications_Listing_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  read_at?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_message_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "app_notifications_listing" */
export type App_Notifications_Listing_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "app_notifications_listing" */
export type App_Notifications_Listing_Insert_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type App_Notifications_Listing_Max_Fields = {
  __typename?: 'app_notifications_listing_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type App_Notifications_Listing_Min_Fields = {
  __typename?: 'app_notifications_listing_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "app_notifications_listing" */
export type App_Notifications_Listing_Mutation_Response = {
  __typename?: 'app_notifications_listing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Notifications_Listing>;
};

/** Ordering options when selecting data from "app_notifications_listing". */
export type App_Notifications_Listing_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_message_at?: Maybe<Order_By>;
};

/** select columns of table "app_notifications_listing" */
export enum App_Notifications_Listing_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedMessageAt = 'updated_message_at'
}

/** input type for updating data in table "app_notifications_listing" */
export type App_Notifications_Listing_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type App_Notifications_Listing_Stddev_Fields = {
  __typename?: 'app_notifications_listing_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type App_Notifications_Listing_Stddev_Pop_Fields = {
  __typename?: 'app_notifications_listing_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type App_Notifications_Listing_Stddev_Samp_Fields = {
  __typename?: 'app_notifications_listing_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type App_Notifications_Listing_Sum_Fields = {
  __typename?: 'app_notifications_listing_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type App_Notifications_Listing_Var_Pop_Fields = {
  __typename?: 'app_notifications_listing_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type App_Notifications_Listing_Var_Samp_Fields = {
  __typename?: 'app_notifications_listing_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type App_Notifications_Listing_Variance_Fields = {
  __typename?: 'app_notifications_listing_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "app_notifications_view" */
export type App_Notifications_View = {
  __typename?: 'app_notifications_view';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "app_notifications_view" */
export type App_Notifications_View_Aggregate = {
  __typename?: 'app_notifications_view_aggregate';
  aggregate?: Maybe<App_Notifications_View_Aggregate_Fields>;
  nodes: Array<App_Notifications_View>;
};

/** aggregate fields of "app_notifications_view" */
export type App_Notifications_View_Aggregate_Fields = {
  __typename?: 'app_notifications_view_aggregate_fields';
  avg?: Maybe<App_Notifications_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<App_Notifications_View_Max_Fields>;
  min?: Maybe<App_Notifications_View_Min_Fields>;
  stddev?: Maybe<App_Notifications_View_Stddev_Fields>;
  stddev_pop?: Maybe<App_Notifications_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Notifications_View_Stddev_Samp_Fields>;
  sum?: Maybe<App_Notifications_View_Sum_Fields>;
  var_pop?: Maybe<App_Notifications_View_Var_Pop_Fields>;
  var_samp?: Maybe<App_Notifications_View_Var_Samp_Fields>;
  variance?: Maybe<App_Notifications_View_Variance_Fields>;
};


/** aggregate fields of "app_notifications_view" */
export type App_Notifications_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Notifications_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type App_Notifications_View_Avg_Fields = {
  __typename?: 'app_notifications_view_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "app_notifications_view". All fields are combined with a logical 'AND'. */
export type App_Notifications_View_Bool_Exp = {
  _and?: Maybe<Array<App_Notifications_View_Bool_Exp>>;
  _not?: Maybe<App_Notifications_View_Bool_Exp>;
  _or?: Maybe<Array<App_Notifications_View_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  read_at?: Maybe<Timestamptz_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_message_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "app_notifications_view" */
export type App_Notifications_View_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "app_notifications_view" */
export type App_Notifications_View_Insert_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type App_Notifications_View_Max_Fields = {
  __typename?: 'app_notifications_view_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type App_Notifications_View_Min_Fields = {
  __typename?: 'app_notifications_view_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "app_notifications_view" */
export type App_Notifications_View_Mutation_Response = {
  __typename?: 'app_notifications_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App_Notifications_View>;
};

/** Ordering options when selecting data from "app_notifications_view". */
export type App_Notifications_View_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  read_at?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_message_at?: Maybe<Order_By>;
};

/** select columns of table "app_notifications_view" */
export enum App_Notifications_View_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedMessageAt = 'updated_message_at'
}

/** input type for updating data in table "app_notifications_view" */
export type App_Notifications_View_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_message_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type App_Notifications_View_Stddev_Fields = {
  __typename?: 'app_notifications_view_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type App_Notifications_View_Stddev_Pop_Fields = {
  __typename?: 'app_notifications_view_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type App_Notifications_View_Stddev_Samp_Fields = {
  __typename?: 'app_notifications_view_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type App_Notifications_View_Sum_Fields = {
  __typename?: 'app_notifications_view_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type App_Notifications_View_Var_Pop_Fields = {
  __typename?: 'app_notifications_view_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type App_Notifications_View_Var_Samp_Fields = {
  __typename?: 'app_notifications_view_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type App_Notifications_View_Variance_Fields = {
  __typename?: 'app_notifications_view_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View = {
  __typename?: 'artist_cardgrid_artworks_view';
  artist_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  exploreArtwork?: Maybe<Explore_Artists_Artworks>;
  id?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  thumbnail_metadata?: Maybe<File_Metadata>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Aggregate = {
  __typename?: 'artist_cardgrid_artworks_view_aggregate';
  aggregate?: Maybe<Artist_Cardgrid_Artworks_View_Aggregate_Fields>;
  nodes: Array<Artist_Cardgrid_Artworks_View>;
};

/** aggregate fields of "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Aggregate_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_aggregate_fields';
  avg?: Maybe<Artist_Cardgrid_Artworks_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artist_Cardgrid_Artworks_View_Max_Fields>;
  min?: Maybe<Artist_Cardgrid_Artworks_View_Min_Fields>;
  stddev?: Maybe<Artist_Cardgrid_Artworks_View_Stddev_Fields>;
  stddev_pop?: Maybe<Artist_Cardgrid_Artworks_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artist_Cardgrid_Artworks_View_Stddev_Samp_Fields>;
  sum?: Maybe<Artist_Cardgrid_Artworks_View_Sum_Fields>;
  var_pop?: Maybe<Artist_Cardgrid_Artworks_View_Var_Pop_Fields>;
  var_samp?: Maybe<Artist_Cardgrid_Artworks_View_Var_Samp_Fields>;
  variance?: Maybe<Artist_Cardgrid_Artworks_View_Variance_Fields>;
};


/** aggregate fields of "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Aggregate_Order_By = {
  avg?: Maybe<Artist_Cardgrid_Artworks_View_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Artist_Cardgrid_Artworks_View_Max_Order_By>;
  min?: Maybe<Artist_Cardgrid_Artworks_View_Min_Order_By>;
  stddev?: Maybe<Artist_Cardgrid_Artworks_View_Stddev_Order_By>;
  stddev_pop?: Maybe<Artist_Cardgrid_Artworks_View_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Artist_Cardgrid_Artworks_View_Stddev_Samp_Order_By>;
  sum?: Maybe<Artist_Cardgrid_Artworks_View_Sum_Order_By>;
  var_pop?: Maybe<Artist_Cardgrid_Artworks_View_Var_Pop_Order_By>;
  var_samp?: Maybe<Artist_Cardgrid_Artworks_View_Var_Samp_Order_By>;
  variance?: Maybe<Artist_Cardgrid_Artworks_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Arr_Rel_Insert_Input = {
  data: Array<Artist_Cardgrid_Artworks_View_Insert_Input>;
};

/** aggregate avg on columns */
export type Artist_Cardgrid_Artworks_View_Avg_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Avg_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "artist_cardgrid_artworks_view". All fields are combined with a logical 'AND'. */
export type Artist_Cardgrid_Artworks_View_Bool_Exp = {
  _and?: Maybe<Array<Artist_Cardgrid_Artworks_View_Bool_Exp>>;
  _not?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
  _or?: Maybe<Array<Artist_Cardgrid_Artworks_View_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  exploreArtwork?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  rank?: Maybe<Bigint_Comparison_Exp>;
  thumbnail_metadata?: Maybe<File_Metadata_Bool_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Insert_Input = {
  artist_context_id?: Maybe<Scalars['bigint']>;
  exploreArtwork?: Maybe<Explore_Artists_Artworks_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
  thumbnail_metadata?: Maybe<File_Metadata_Obj_Rel_Insert_Input>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Artist_Cardgrid_Artworks_View_Max_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Max_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Artist_Cardgrid_Artworks_View_Min_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Min_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "artist_cardgrid_artworks_view". */
export type Artist_Cardgrid_Artworks_View_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  exploreArtwork?: Maybe<Explore_Artists_Artworks_Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata?: Maybe<File_Metadata_Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** select columns of table "artist_cardgrid_artworks_view" */
export enum Artist_Cardgrid_Artworks_View_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rank = 'rank',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id'
}

/** aggregate stddev on columns */
export type Artist_Cardgrid_Artworks_View_Stddev_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Stddev_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Artist_Cardgrid_Artworks_View_Stddev_Pop_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Stddev_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Artist_Cardgrid_Artworks_View_Stddev_Samp_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Stddev_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Artist_Cardgrid_Artworks_View_Sum_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rank?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Sum_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Artist_Cardgrid_Artworks_View_Var_Pop_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Var_Pop_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Artist_Cardgrid_Artworks_View_Var_Samp_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Var_Samp_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Artist_Cardgrid_Artworks_View_Variance_Fields = {
  __typename?: 'artist_cardgrid_artworks_view_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "artist_cardgrid_artworks_view" */
export type Artist_Cardgrid_Artworks_View_Variance_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rank?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
};

/** columns and relationships of "artwork_favorite_count" */
export type Artwork_Favorite_Count = {
  __typename?: 'artwork_favorite_count';
  /** An object relationship */
  artwork?: Maybe<Artwork>;
  artwork_id?: Maybe<Scalars['bigint']>;
  favorite_count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "artwork_favorite_count" */
export type Artwork_Favorite_Count_Aggregate = {
  __typename?: 'artwork_favorite_count_aggregate';
  aggregate?: Maybe<Artwork_Favorite_Count_Aggregate_Fields>;
  nodes: Array<Artwork_Favorite_Count>;
};

/** aggregate fields of "artwork_favorite_count" */
export type Artwork_Favorite_Count_Aggregate_Fields = {
  __typename?: 'artwork_favorite_count_aggregate_fields';
  avg?: Maybe<Artwork_Favorite_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Favorite_Count_Max_Fields>;
  min?: Maybe<Artwork_Favorite_Count_Min_Fields>;
  stddev?: Maybe<Artwork_Favorite_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Favorite_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Favorite_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Favorite_Count_Sum_Fields>;
  var_pop?: Maybe<Artwork_Favorite_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Favorite_Count_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Favorite_Count_Variance_Fields>;
};


/** aggregate fields of "artwork_favorite_count" */
export type Artwork_Favorite_Count_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Favorite_Count_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Favorite_Count_Avg_Fields = {
  __typename?: 'artwork_favorite_count_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  favorite_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "artwork_favorite_count". All fields are combined with a logical 'AND'. */
export type Artwork_Favorite_Count_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Favorite_Count_Bool_Exp>>;
  _not?: Maybe<Artwork_Favorite_Count_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Favorite_Count_Bool_Exp>>;
  artwork?: Maybe<Artwork_Bool_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  favorite_count?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "artwork_favorite_count" */
export type Artwork_Favorite_Count_Insert_Input = {
  artwork?: Maybe<Artwork_Obj_Rel_Insert_Input>;
  artwork_id?: Maybe<Scalars['bigint']>;
  favorite_count?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Artwork_Favorite_Count_Max_Fields = {
  __typename?: 'artwork_favorite_count_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  favorite_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Artwork_Favorite_Count_Min_Fields = {
  __typename?: 'artwork_favorite_count_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  favorite_count?: Maybe<Scalars['bigint']>;
};

/** input type for inserting object relation for remote table "artwork_favorite_count" */
export type Artwork_Favorite_Count_Obj_Rel_Insert_Input = {
  data: Artwork_Favorite_Count_Insert_Input;
};

/** Ordering options when selecting data from "artwork_favorite_count". */
export type Artwork_Favorite_Count_Order_By = {
  artwork?: Maybe<Artwork_Order_By>;
  artwork_id?: Maybe<Order_By>;
  favorite_count?: Maybe<Order_By>;
};

/** select columns of table "artwork_favorite_count" */
export enum Artwork_Favorite_Count_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  FavoriteCount = 'favorite_count'
}

/** aggregate stddev on columns */
export type Artwork_Favorite_Count_Stddev_Fields = {
  __typename?: 'artwork_favorite_count_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  favorite_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Favorite_Count_Stddev_Pop_Fields = {
  __typename?: 'artwork_favorite_count_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  favorite_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Favorite_Count_Stddev_Samp_Fields = {
  __typename?: 'artwork_favorite_count_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  favorite_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Favorite_Count_Sum_Fields = {
  __typename?: 'artwork_favorite_count_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  favorite_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artwork_Favorite_Count_Var_Pop_Fields = {
  __typename?: 'artwork_favorite_count_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  favorite_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Favorite_Count_Var_Samp_Fields = {
  __typename?: 'artwork_favorite_count_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  favorite_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Favorite_Count_Variance_Fields = {
  __typename?: 'artwork_favorite_count_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  favorite_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "artwork_manager_view" */
export type Artwork_Manager_View = {
  __typename?: 'artwork_manager_view';
  /** An array relationship */
  Artwork_prices: Array<Artwork_Prices>;
  /** An aggregate relationship */
  Artwork_prices_aggregate: Artwork_Prices_Aggregate;
  /** An object relationship */
  Edition?: Maybe<Edition>;
  /** An array relationship */
  art_prizes: Array<Art_Prize_Artwork>;
  /** An aggregate relationship */
  art_prizes_aggregate: Art_Prize_Artwork_Aggregate;
  artist_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  artwork_details?: Maybe<Artwork_Details>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_edition_id?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  artwork_to_collections: Array<Artwork_To_Collection>;
  /** An aggregate relationship */
  artwork_to_collections_aggregate: Artwork_To_Collection_Aggregate;
  /** An object relationship */
  aspect?: Maybe<Artwork_Aspect_Ratio>;
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  is_hard_minted?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  transaction_histories: Array<Artwork_Transactions>;
  /** An aggregate relationship */
  transaction_histories_aggregate: Artwork_Transactions_Aggregate;
  transfer_state?: Maybe<Scalars['String']>;
};


/** columns and relationships of "artwork_manager_view" */
export type Artwork_Manager_ViewArtwork_PricesArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


/** columns and relationships of "artwork_manager_view" */
export type Artwork_Manager_ViewArtwork_Prices_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


/** columns and relationships of "artwork_manager_view" */
export type Artwork_Manager_ViewArt_PrizesArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};


/** columns and relationships of "artwork_manager_view" */
export type Artwork_Manager_ViewArt_Prizes_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};


/** columns and relationships of "artwork_manager_view" */
export type Artwork_Manager_ViewArtwork_To_CollectionsArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


/** columns and relationships of "artwork_manager_view" */
export type Artwork_Manager_ViewArtwork_To_Collections_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


/** columns and relationships of "artwork_manager_view" */
export type Artwork_Manager_ViewTransaction_HistoriesArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** columns and relationships of "artwork_manager_view" */
export type Artwork_Manager_ViewTransaction_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};

/** aggregated selection of "artwork_manager_view" */
export type Artwork_Manager_View_Aggregate = {
  __typename?: 'artwork_manager_view_aggregate';
  aggregate?: Maybe<Artwork_Manager_View_Aggregate_Fields>;
  nodes: Array<Artwork_Manager_View>;
};

/** aggregate fields of "artwork_manager_view" */
export type Artwork_Manager_View_Aggregate_Fields = {
  __typename?: 'artwork_manager_view_aggregate_fields';
  avg?: Maybe<Artwork_Manager_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Manager_View_Max_Fields>;
  min?: Maybe<Artwork_Manager_View_Min_Fields>;
  stddev?: Maybe<Artwork_Manager_View_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Manager_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Manager_View_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Manager_View_Sum_Fields>;
  var_pop?: Maybe<Artwork_Manager_View_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Manager_View_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Manager_View_Variance_Fields>;
};


/** aggregate fields of "artwork_manager_view" */
export type Artwork_Manager_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Manager_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Manager_View_Avg_Fields = {
  __typename?: 'artwork_manager_view_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "artwork_manager_view". All fields are combined with a logical 'AND'. */
export type Artwork_Manager_View_Bool_Exp = {
  Artwork_prices?: Maybe<Artwork_Prices_Bool_Exp>;
  Edition?: Maybe<Edition_Bool_Exp>;
  _and?: Maybe<Array<Artwork_Manager_View_Bool_Exp>>;
  _not?: Maybe<Artwork_Manager_View_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Manager_View_Bool_Exp>>;
  art_prizes?: Maybe<Art_Prize_Artwork_Bool_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_details?: Maybe<Artwork_Details_Bool_Exp>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_edition_id?: Maybe<String_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_to_collections?: Maybe<Artwork_To_Collection_Bool_Exp>;
  aspect?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  edition_id?: Maybe<Bigint_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  is_hard_minted?: Maybe<Boolean_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  transaction_histories?: Maybe<Artwork_Transactions_Bool_Exp>;
  transfer_state?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Manager_View_Max_Fields = {
  __typename?: 'artwork_manager_view_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_edition_id?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  transfer_state?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Artwork_Manager_View_Min_Fields = {
  __typename?: 'artwork_manager_view_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_edition_id?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  edition_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  transfer_state?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "artwork_manager_view". */
export type Artwork_Manager_View_Order_By = {
  Artwork_prices_aggregate?: Maybe<Artwork_Prices_Aggregate_Order_By>;
  Edition?: Maybe<Edition_Order_By>;
  art_prizes_aggregate?: Maybe<Art_Prize_Artwork_Aggregate_Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_details?: Maybe<Artwork_Details_Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_edition_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  artwork_to_collections_aggregate?: Maybe<Artwork_To_Collection_Aggregate_Order_By>;
  aspect?: Maybe<Artwork_Aspect_Ratio_Order_By>;
  created_at?: Maybe<Order_By>;
  edition_id?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  is_hard_minted?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  transaction_histories_aggregate?: Maybe<Artwork_Transactions_Aggregate_Order_By>;
  transfer_state?: Maybe<Order_By>;
};

/** select columns of table "artwork_manager_view" */
export enum Artwork_Manager_View_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  ArtworkEditionId = 'artwork_edition_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EditionId = 'edition_id',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  IsHardMinted = 'is_hard_minted',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  TransferState = 'transfer_state'
}

/** aggregate stddev on columns */
export type Artwork_Manager_View_Stddev_Fields = {
  __typename?: 'artwork_manager_view_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Manager_View_Stddev_Pop_Fields = {
  __typename?: 'artwork_manager_view_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Manager_View_Stddev_Samp_Fields = {
  __typename?: 'artwork_manager_view_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Manager_View_Sum_Fields = {
  __typename?: 'artwork_manager_view_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  edition_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Artwork_Manager_View_Var_Pop_Fields = {
  __typename?: 'artwork_manager_view_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Manager_View_Var_Samp_Fields = {
  __typename?: 'artwork_manager_view_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Manager_View_Variance_Fields = {
  __typename?: 'artwork_manager_view_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  edition_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "artwork_shop_info" */
export type Artwork_Shop_Info = {
  __typename?: 'artwork_shop_info';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "artwork_shop_info" */
export type Artwork_Shop_Info_Aggregate = {
  __typename?: 'artwork_shop_info_aggregate';
  aggregate?: Maybe<Artwork_Shop_Info_Aggregate_Fields>;
  nodes: Array<Artwork_Shop_Info>;
};

/** aggregate fields of "artwork_shop_info" */
export type Artwork_Shop_Info_Aggregate_Fields = {
  __typename?: 'artwork_shop_info_aggregate_fields';
  avg?: Maybe<Artwork_Shop_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Shop_Info_Max_Fields>;
  min?: Maybe<Artwork_Shop_Info_Min_Fields>;
  stddev?: Maybe<Artwork_Shop_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Shop_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Shop_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Shop_Info_Sum_Fields>;
  var_pop?: Maybe<Artwork_Shop_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Shop_Info_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Shop_Info_Variance_Fields>;
};


/** aggregate fields of "artwork_shop_info" */
export type Artwork_Shop_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Shop_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Shop_Info_Avg_Fields = {
  __typename?: 'artwork_shop_info_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "artwork_shop_info". All fields are combined with a logical 'AND'. */
export type Artwork_Shop_Info_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Shop_Info_Bool_Exp>>;
  _not?: Maybe<Artwork_Shop_Info_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Shop_Info_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  depth?: Maybe<Float8_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  genre_id?: Maybe<Bigint_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  measuring_unit?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Shop_Info_Max_Fields = {
  __typename?: 'artwork_shop_info_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Shop_Info_Min_Fields = {
  __typename?: 'artwork_shop_info_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['float8']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "artwork_shop_info". */
export type Artwork_Shop_Info_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  depth?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  genre_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  measuring_unit?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "artwork_shop_info" */
export enum Artwork_Shop_Info_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  Depth = 'depth',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  GenreId = 'genre_id',
  /** column name */
  Height = 'height',
  /** column name */
  MeasuringUnit = 'measuring_unit',
  /** column name */
  Price = 'price',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  Title = 'title',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Shop_Info_Stddev_Fields = {
  __typename?: 'artwork_shop_info_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Shop_Info_Stddev_Pop_Fields = {
  __typename?: 'artwork_shop_info_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Shop_Info_Stddev_Samp_Fields = {
  __typename?: 'artwork_shop_info_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Shop_Info_Sum_Fields = {
  __typename?: 'artwork_shop_info_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  depth?: Maybe<Scalars['float8']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Shop_Info_Var_Pop_Fields = {
  __typename?: 'artwork_shop_info_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Shop_Info_Var_Samp_Fields = {
  __typename?: 'artwork_shop_info_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Shop_Info_Variance_Fields = {
  __typename?: 'artwork_shop_info_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "artwork_shop_listing" */
export type Artwork_Shop_Listing = {
  __typename?: 'artwork_shop_listing';
  art_type?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  aspect_ratio?: Maybe<Artwork_Aspect_Ratio>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  en_image_tags?: Maybe<Scalars['String']>;
  fr_image_tags?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_landscape?: Maybe<Scalars['Boolean']>;
  is_portrait?: Maybe<Scalars['Boolean']>;
  is_square?: Maybe<Scalars['Boolean']>;
  pg_image_tags?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  ro_image_tags?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  sp_image_tags?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "artwork_shop_listing" */
export type Artwork_Shop_Listing_Aggregate = {
  __typename?: 'artwork_shop_listing_aggregate';
  aggregate?: Maybe<Artwork_Shop_Listing_Aggregate_Fields>;
  nodes: Array<Artwork_Shop_Listing>;
};

/** aggregate fields of "artwork_shop_listing" */
export type Artwork_Shop_Listing_Aggregate_Fields = {
  __typename?: 'artwork_shop_listing_aggregate_fields';
  avg?: Maybe<Artwork_Shop_Listing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Artwork_Shop_Listing_Max_Fields>;
  min?: Maybe<Artwork_Shop_Listing_Min_Fields>;
  stddev?: Maybe<Artwork_Shop_Listing_Stddev_Fields>;
  stddev_pop?: Maybe<Artwork_Shop_Listing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Artwork_Shop_Listing_Stddev_Samp_Fields>;
  sum?: Maybe<Artwork_Shop_Listing_Sum_Fields>;
  var_pop?: Maybe<Artwork_Shop_Listing_Var_Pop_Fields>;
  var_samp?: Maybe<Artwork_Shop_Listing_Var_Samp_Fields>;
  variance?: Maybe<Artwork_Shop_Listing_Variance_Fields>;
};


/** aggregate fields of "artwork_shop_listing" */
export type Artwork_Shop_Listing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Artwork_Shop_Listing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Artwork_Shop_Listing_Avg_Fields = {
  __typename?: 'artwork_shop_listing_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "artwork_shop_listing". All fields are combined with a logical 'AND'. */
export type Artwork_Shop_Listing_Bool_Exp = {
  _and?: Maybe<Array<Artwork_Shop_Listing_Bool_Exp>>;
  _not?: Maybe<Artwork_Shop_Listing_Bool_Exp>;
  _or?: Maybe<Array<Artwork_Shop_Listing_Bool_Exp>>;
  art_type?: Maybe<String_Comparison_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  aspect_ratio?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  city_id?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  discipline?: Maybe<String_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  en_image_tags?: Maybe<String_Comparison_Exp>;
  fr_image_tags?: Maybe<String_Comparison_Exp>;
  gallery_context_id?: Maybe<Bigint_Comparison_Exp>;
  genre_id?: Maybe<Bigint_Comparison_Exp>;
  height?: Maybe<Float8_Comparison_Exp>;
  is_available_sale?: Maybe<Boolean_Comparison_Exp>;
  is_landscape?: Maybe<Boolean_Comparison_Exp>;
  is_portrait?: Maybe<Boolean_Comparison_Exp>;
  is_square?: Maybe<Boolean_Comparison_Exp>;
  pg_image_tags?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  ro_image_tags?: Maybe<String_Comparison_Exp>;
  score?: Maybe<Int_Comparison_Exp>;
  sp_image_tags?: Maybe<String_Comparison_Exp>;
  width?: Maybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Artwork_Shop_Listing_Max_Fields = {
  __typename?: 'artwork_shop_listing_max_fields';
  art_type?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  en_image_tags?: Maybe<Scalars['String']>;
  fr_image_tags?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  pg_image_tags?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  ro_image_tags?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  sp_image_tags?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Artwork_Shop_Listing_Min_Fields = {
  __typename?: 'artwork_shop_listing_min_fields';
  art_type?: Maybe<Scalars['String']>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  city_id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  discipline?: Maybe<Scalars['String']>;
  edition_type?: Maybe<Scalars['String']>;
  en_image_tags?: Maybe<Scalars['String']>;
  fr_image_tags?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  pg_image_tags?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  ro_image_tags?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  sp_image_tags?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "artwork_shop_listing". */
export type Artwork_Shop_Listing_Order_By = {
  art_type?: Maybe<Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  artwork_id?: Maybe<Order_By>;
  aspect_ratio?: Maybe<Artwork_Aspect_Ratio_Order_By>;
  city?: Maybe<Order_By>;
  city_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  discipline?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  en_image_tags?: Maybe<Order_By>;
  fr_image_tags?: Maybe<Order_By>;
  gallery_context_id?: Maybe<Order_By>;
  genre_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  is_available_sale?: Maybe<Order_By>;
  is_landscape?: Maybe<Order_By>;
  is_portrait?: Maybe<Order_By>;
  is_square?: Maybe<Order_By>;
  pg_image_tags?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  ro_image_tags?: Maybe<Order_By>;
  score?: Maybe<Order_By>;
  sp_image_tags?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** select columns of table "artwork_shop_listing" */
export enum Artwork_Shop_Listing_Select_Column {
  /** column name */
  ArtType = 'art_type',
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  City = 'city',
  /** column name */
  CityId = 'city_id',
  /** column name */
  Country = 'country',
  /** column name */
  Discipline = 'discipline',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  EnImageTags = 'en_image_tags',
  /** column name */
  FrImageTags = 'fr_image_tags',
  /** column name */
  GalleryContextId = 'gallery_context_id',
  /** column name */
  GenreId = 'genre_id',
  /** column name */
  Height = 'height',
  /** column name */
  IsAvailableSale = 'is_available_sale',
  /** column name */
  IsLandscape = 'is_landscape',
  /** column name */
  IsPortrait = 'is_portrait',
  /** column name */
  IsSquare = 'is_square',
  /** column name */
  PgImageTags = 'pg_image_tags',
  /** column name */
  Price = 'price',
  /** column name */
  RoImageTags = 'ro_image_tags',
  /** column name */
  Score = 'score',
  /** column name */
  SpImageTags = 'sp_image_tags',
  /** column name */
  Width = 'width'
}

/** aggregate stddev on columns */
export type Artwork_Shop_Listing_Stddev_Fields = {
  __typename?: 'artwork_shop_listing_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Artwork_Shop_Listing_Stddev_Pop_Fields = {
  __typename?: 'artwork_shop_listing_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Artwork_Shop_Listing_Stddev_Samp_Fields = {
  __typename?: 'artwork_shop_listing_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Artwork_Shop_Listing_Sum_Fields = {
  __typename?: 'artwork_shop_listing_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  artwork_id?: Maybe<Scalars['bigint']>;
  gallery_context_id?: Maybe<Scalars['bigint']>;
  genre_id?: Maybe<Scalars['bigint']>;
  height?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
  score?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Artwork_Shop_Listing_Var_Pop_Fields = {
  __typename?: 'artwork_shop_listing_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Artwork_Shop_Listing_Var_Samp_Fields = {
  __typename?: 'artwork_shop_listing_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Artwork_Shop_Listing_Variance_Fields = {
  __typename?: 'artwork_shop_listing_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  artwork_id?: Maybe<Scalars['Float']>;
  gallery_context_id?: Maybe<Scalars['Float']>;
  genre_id?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "best_bet_cities_exclude_cities_with_duplicates_mv" */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv';
  admin_code?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "best_bet_cities_exclude_cities_with_duplicates_mv" */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Aggregate = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_aggregate';
  aggregate?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Aggregate_Fields>;
  nodes: Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv>;
};

/** aggregate fields of "best_bet_cities_exclude_cities_with_duplicates_mv" */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Aggregate_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_aggregate_fields';
  avg?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Max_Fields>;
  min?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Min_Fields>;
  stddev?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Stddev_Fields>;
  stddev_pop?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Stddev_Samp_Fields>;
  sum?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Sum_Fields>;
  var_pop?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Var_Pop_Fields>;
  var_samp?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Var_Samp_Fields>;
  variance?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Variance_Fields>;
};


/** aggregate fields of "best_bet_cities_exclude_cities_with_duplicates_mv" */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Avg_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_avg_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "best_bet_cities_exclude_cities_with_duplicates_mv". All fields are combined with a logical 'AND'. */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Bool_Exp = {
  _and?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Bool_Exp>>;
  _not?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Bool_Exp>;
  _or?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Bool_Exp>>;
  admin_code?: Maybe<String_Comparison_Exp>;
  city_ascii?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  iso2?: Maybe<String_Comparison_Exp>;
  lat?: Maybe<Float8_Comparison_Exp>;
  lng?: Maybe<Float8_Comparison_Exp>;
  population?: Maybe<Float8_Comparison_Exp>;
  ranking?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Max_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_max_fields';
  admin_code?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Min_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_min_fields';
  admin_code?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "best_bet_cities_exclude_cities_with_duplicates_mv". */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Order_By = {
  admin_code?: Maybe<Order_By>;
  city_ascii?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  iso2?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  population?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
};

/** select columns of table "best_bet_cities_exclude_cities_with_duplicates_mv" */
export enum Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Select_Column {
  /** column name */
  AdminCode = 'admin_code',
  /** column name */
  CityAscii = 'city_ascii',
  /** column name */
  Id = 'id',
  /** column name */
  Iso2 = 'iso2',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Population = 'population',
  /** column name */
  Ranking = 'ranking'
}

/** aggregate stddev on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Stddev_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_stddev_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Stddev_Pop_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_stddev_pop_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Stddev_Samp_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_stddev_samp_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Sum_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_sum_fields';
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Var_Pop_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_var_pop_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Var_Samp_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_var_samp_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Variance_Fields = {
  __typename?: 'best_bet_cities_exclude_cities_with_duplicates_mv_variance_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "best_bet_cities_mv" */
export type Best_Bet_Cities_Mv = {
  __typename?: 'best_bet_cities_mv';
  admin_code?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "best_bet_cities_mv" */
export type Best_Bet_Cities_Mv_Aggregate = {
  __typename?: 'best_bet_cities_mv_aggregate';
  aggregate?: Maybe<Best_Bet_Cities_Mv_Aggregate_Fields>;
  nodes: Array<Best_Bet_Cities_Mv>;
};

/** aggregate fields of "best_bet_cities_mv" */
export type Best_Bet_Cities_Mv_Aggregate_Fields = {
  __typename?: 'best_bet_cities_mv_aggregate_fields';
  avg?: Maybe<Best_Bet_Cities_Mv_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Best_Bet_Cities_Mv_Max_Fields>;
  min?: Maybe<Best_Bet_Cities_Mv_Min_Fields>;
  stddev?: Maybe<Best_Bet_Cities_Mv_Stddev_Fields>;
  stddev_pop?: Maybe<Best_Bet_Cities_Mv_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Best_Bet_Cities_Mv_Stddev_Samp_Fields>;
  sum?: Maybe<Best_Bet_Cities_Mv_Sum_Fields>;
  var_pop?: Maybe<Best_Bet_Cities_Mv_Var_Pop_Fields>;
  var_samp?: Maybe<Best_Bet_Cities_Mv_Var_Samp_Fields>;
  variance?: Maybe<Best_Bet_Cities_Mv_Variance_Fields>;
};


/** aggregate fields of "best_bet_cities_mv" */
export type Best_Bet_Cities_Mv_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Best_Bet_Cities_Mv_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Best_Bet_Cities_Mv_Avg_Fields = {
  __typename?: 'best_bet_cities_mv_avg_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "best_bet_cities_mv". All fields are combined with a logical 'AND'. */
export type Best_Bet_Cities_Mv_Bool_Exp = {
  _and?: Maybe<Array<Best_Bet_Cities_Mv_Bool_Exp>>;
  _not?: Maybe<Best_Bet_Cities_Mv_Bool_Exp>;
  _or?: Maybe<Array<Best_Bet_Cities_Mv_Bool_Exp>>;
  admin_code?: Maybe<String_Comparison_Exp>;
  city_ascii?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  iso2?: Maybe<String_Comparison_Exp>;
  lat?: Maybe<Float8_Comparison_Exp>;
  lng?: Maybe<Float8_Comparison_Exp>;
  population?: Maybe<Float8_Comparison_Exp>;
  ranking?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Best_Bet_Cities_Mv_Max_Fields = {
  __typename?: 'best_bet_cities_mv_max_fields';
  admin_code?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Best_Bet_Cities_Mv_Min_Fields = {
  __typename?: 'best_bet_cities_mv_min_fields';
  admin_code?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "best_bet_cities_mv". */
export type Best_Bet_Cities_Mv_Order_By = {
  admin_code?: Maybe<Order_By>;
  city_ascii?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  iso2?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  population?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
};

/** select columns of table "best_bet_cities_mv" */
export enum Best_Bet_Cities_Mv_Select_Column {
  /** column name */
  AdminCode = 'admin_code',
  /** column name */
  CityAscii = 'city_ascii',
  /** column name */
  Id = 'id',
  /** column name */
  Iso2 = 'iso2',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Population = 'population',
  /** column name */
  Ranking = 'ranking'
}

/** aggregate stddev on columns */
export type Best_Bet_Cities_Mv_Stddev_Fields = {
  __typename?: 'best_bet_cities_mv_stddev_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Best_Bet_Cities_Mv_Stddev_Pop_Fields = {
  __typename?: 'best_bet_cities_mv_stddev_pop_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Best_Bet_Cities_Mv_Stddev_Samp_Fields = {
  __typename?: 'best_bet_cities_mv_stddev_samp_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Best_Bet_Cities_Mv_Sum_Fields = {
  __typename?: 'best_bet_cities_mv_sum_fields';
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Best_Bet_Cities_Mv_Var_Pop_Fields = {
  __typename?: 'best_bet_cities_mv_var_pop_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Best_Bet_Cities_Mv_Var_Samp_Fields = {
  __typename?: 'best_bet_cities_mv_var_samp_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Best_Bet_Cities_Mv_Variance_Fields = {
  __typename?: 'best_bet_cities_mv_variance_fields';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "biweekly_recap_artists_info" */
export type Biweekly_Recap_Artists_Info = {
  __typename?: 'biweekly_recap_artists_info';
  /** An object relationship */
  context?: Maybe<Context>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  profile_visits_counter?: Maybe<Count_Profile_Visits_Published_Biweekly>;
  /** An object relationship */
  recognitions_counter?: Maybe<Count_Recognitions_Published_Biweekly>;
  title?: Maybe<Scalars['String']>;
  total_collector_recognitions_count?: Maybe<Scalars['bigint']>;
  weekly_favorites_count?: Maybe<Scalars['bigint']>;
  weekly_publish_count?: Maybe<Scalars['bigint']>;
  weekly_visit_card_count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "biweekly_recap_artists_info" */
export type Biweekly_Recap_Artists_Info_Aggregate = {
  __typename?: 'biweekly_recap_artists_info_aggregate';
  aggregate?: Maybe<Biweekly_Recap_Artists_Info_Aggregate_Fields>;
  nodes: Array<Biweekly_Recap_Artists_Info>;
};

/** aggregate fields of "biweekly_recap_artists_info" */
export type Biweekly_Recap_Artists_Info_Aggregate_Fields = {
  __typename?: 'biweekly_recap_artists_info_aggregate_fields';
  avg?: Maybe<Biweekly_Recap_Artists_Info_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Biweekly_Recap_Artists_Info_Max_Fields>;
  min?: Maybe<Biweekly_Recap_Artists_Info_Min_Fields>;
  stddev?: Maybe<Biweekly_Recap_Artists_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Biweekly_Recap_Artists_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Biweekly_Recap_Artists_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Biweekly_Recap_Artists_Info_Sum_Fields>;
  var_pop?: Maybe<Biweekly_Recap_Artists_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Biweekly_Recap_Artists_Info_Var_Samp_Fields>;
  variance?: Maybe<Biweekly_Recap_Artists_Info_Variance_Fields>;
};


/** aggregate fields of "biweekly_recap_artists_info" */
export type Biweekly_Recap_Artists_Info_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Biweekly_Recap_Artists_Info_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Biweekly_Recap_Artists_Info_Avg_Fields = {
  __typename?: 'biweekly_recap_artists_info_avg_fields';
  id?: Maybe<Scalars['Float']>;
  total_collector_recognitions_count?: Maybe<Scalars['Float']>;
  weekly_favorites_count?: Maybe<Scalars['Float']>;
  weekly_publish_count?: Maybe<Scalars['Float']>;
  weekly_visit_card_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "biweekly_recap_artists_info". All fields are combined with a logical 'AND'. */
export type Biweekly_Recap_Artists_Info_Bool_Exp = {
  _and?: Maybe<Array<Biweekly_Recap_Artists_Info_Bool_Exp>>;
  _not?: Maybe<Biweekly_Recap_Artists_Info_Bool_Exp>;
  _or?: Maybe<Array<Biweekly_Recap_Artists_Info_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  profile_visits_counter?: Maybe<Count_Profile_Visits_Published_Biweekly_Bool_Exp>;
  recognitions_counter?: Maybe<Count_Recognitions_Published_Biweekly_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  total_collector_recognitions_count?: Maybe<Bigint_Comparison_Exp>;
  weekly_favorites_count?: Maybe<Bigint_Comparison_Exp>;
  weekly_publish_count?: Maybe<Bigint_Comparison_Exp>;
  weekly_visit_card_count?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Biweekly_Recap_Artists_Info_Max_Fields = {
  __typename?: 'biweekly_recap_artists_info_max_fields';
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  total_collector_recognitions_count?: Maybe<Scalars['bigint']>;
  weekly_favorites_count?: Maybe<Scalars['bigint']>;
  weekly_publish_count?: Maybe<Scalars['bigint']>;
  weekly_visit_card_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Biweekly_Recap_Artists_Info_Min_Fields = {
  __typename?: 'biweekly_recap_artists_info_min_fields';
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  total_collector_recognitions_count?: Maybe<Scalars['bigint']>;
  weekly_favorites_count?: Maybe<Scalars['bigint']>;
  weekly_publish_count?: Maybe<Scalars['bigint']>;
  weekly_visit_card_count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "biweekly_recap_artists_info". */
export type Biweekly_Recap_Artists_Info_Order_By = {
  context?: Maybe<Context_Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  profile_visits_counter?: Maybe<Count_Profile_Visits_Published_Biweekly_Order_By>;
  recognitions_counter?: Maybe<Count_Recognitions_Published_Biweekly_Order_By>;
  title?: Maybe<Order_By>;
  total_collector_recognitions_count?: Maybe<Order_By>;
  weekly_favorites_count?: Maybe<Order_By>;
  weekly_publish_count?: Maybe<Order_By>;
  weekly_visit_card_count?: Maybe<Order_By>;
};

/** select columns of table "biweekly_recap_artists_info" */
export enum Biweekly_Recap_Artists_Info_Select_Column {
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  TotalCollectorRecognitionsCount = 'total_collector_recognitions_count',
  /** column name */
  WeeklyFavoritesCount = 'weekly_favorites_count',
  /** column name */
  WeeklyPublishCount = 'weekly_publish_count',
  /** column name */
  WeeklyVisitCardCount = 'weekly_visit_card_count'
}

/** aggregate stddev on columns */
export type Biweekly_Recap_Artists_Info_Stddev_Fields = {
  __typename?: 'biweekly_recap_artists_info_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  total_collector_recognitions_count?: Maybe<Scalars['Float']>;
  weekly_favorites_count?: Maybe<Scalars['Float']>;
  weekly_publish_count?: Maybe<Scalars['Float']>;
  weekly_visit_card_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Biweekly_Recap_Artists_Info_Stddev_Pop_Fields = {
  __typename?: 'biweekly_recap_artists_info_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  total_collector_recognitions_count?: Maybe<Scalars['Float']>;
  weekly_favorites_count?: Maybe<Scalars['Float']>;
  weekly_publish_count?: Maybe<Scalars['Float']>;
  weekly_visit_card_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Biweekly_Recap_Artists_Info_Stddev_Samp_Fields = {
  __typename?: 'biweekly_recap_artists_info_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  total_collector_recognitions_count?: Maybe<Scalars['Float']>;
  weekly_favorites_count?: Maybe<Scalars['Float']>;
  weekly_publish_count?: Maybe<Scalars['Float']>;
  weekly_visit_card_count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Biweekly_Recap_Artists_Info_Sum_Fields = {
  __typename?: 'biweekly_recap_artists_info_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  total_collector_recognitions_count?: Maybe<Scalars['bigint']>;
  weekly_favorites_count?: Maybe<Scalars['bigint']>;
  weekly_publish_count?: Maybe<Scalars['bigint']>;
  weekly_visit_card_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Biweekly_Recap_Artists_Info_Var_Pop_Fields = {
  __typename?: 'biweekly_recap_artists_info_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  total_collector_recognitions_count?: Maybe<Scalars['Float']>;
  weekly_favorites_count?: Maybe<Scalars['Float']>;
  weekly_publish_count?: Maybe<Scalars['Float']>;
  weekly_visit_card_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Biweekly_Recap_Artists_Info_Var_Samp_Fields = {
  __typename?: 'biweekly_recap_artists_info_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  total_collector_recognitions_count?: Maybe<Scalars['Float']>;
  weekly_favorites_count?: Maybe<Scalars['Float']>;
  weekly_publish_count?: Maybe<Scalars['Float']>;
  weekly_visit_card_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Biweekly_Recap_Artists_Info_Variance_Fields = {
  __typename?: 'biweekly_recap_artists_info_variance_fields';
  id?: Maybe<Scalars['Float']>;
  total_collector_recognitions_count?: Maybe<Scalars['Float']>;
  weekly_favorites_count?: Maybe<Scalars['Float']>;
  weekly_publish_count?: Maybe<Scalars['Float']>;
  weekly_visit_card_count?: Maybe<Scalars['Float']>;
};

export type BuyerContext = {
  __typename?: 'buyerContext';
  profile?: Maybe<CustomProfile>;
};

/** columns and relationships of "count_artists_approved_by_day" */
export type Count_Artists_Approved_By_Day = {
  __typename?: 'count_artists_approved_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_artists_approved_by_day" */
export type Count_Artists_Approved_By_Day_Aggregate = {
  __typename?: 'count_artists_approved_by_day_aggregate';
  aggregate?: Maybe<Count_Artists_Approved_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Artists_Approved_By_Day>;
};

/** aggregate fields of "count_artists_approved_by_day" */
export type Count_Artists_Approved_By_Day_Aggregate_Fields = {
  __typename?: 'count_artists_approved_by_day_aggregate_fields';
  avg?: Maybe<Count_Artists_Approved_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Artists_Approved_By_Day_Max_Fields>;
  min?: Maybe<Count_Artists_Approved_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Artists_Approved_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Artists_Approved_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Artists_Approved_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Artists_Approved_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Artists_Approved_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Artists_Approved_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Artists_Approved_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_artists_approved_by_day" */
export type Count_Artists_Approved_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Artists_Approved_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Artists_Approved_By_Day_Avg_Fields = {
  __typename?: 'count_artists_approved_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_artists_approved_by_day". All fields are combined with a logical 'AND'. */
export type Count_Artists_Approved_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Artists_Approved_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Artists_Approved_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Artists_Approved_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Artists_Approved_By_Day_Max_Fields = {
  __typename?: 'count_artists_approved_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Artists_Approved_By_Day_Min_Fields = {
  __typename?: 'count_artists_approved_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_artists_approved_by_day". */
export type Count_Artists_Approved_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_artists_approved_by_day" */
export enum Count_Artists_Approved_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Artists_Approved_By_Day_Stddev_Fields = {
  __typename?: 'count_artists_approved_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Artists_Approved_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_artists_approved_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Artists_Approved_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_artists_approved_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Artists_Approved_By_Day_Sum_Fields = {
  __typename?: 'count_artists_approved_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Artists_Approved_By_Day_Var_Pop_Fields = {
  __typename?: 'count_artists_approved_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Artists_Approved_By_Day_Var_Samp_Fields = {
  __typename?: 'count_artists_approved_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Artists_Approved_By_Day_Variance_Fields = {
  __typename?: 'count_artists_approved_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_artists_created_by_day" */
export type Count_Artists_Created_By_Day = {
  __typename?: 'count_artists_created_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_artists_created_by_day" */
export type Count_Artists_Created_By_Day_Aggregate = {
  __typename?: 'count_artists_created_by_day_aggregate';
  aggregate?: Maybe<Count_Artists_Created_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Artists_Created_By_Day>;
};

/** aggregate fields of "count_artists_created_by_day" */
export type Count_Artists_Created_By_Day_Aggregate_Fields = {
  __typename?: 'count_artists_created_by_day_aggregate_fields';
  avg?: Maybe<Count_Artists_Created_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Artists_Created_By_Day_Max_Fields>;
  min?: Maybe<Count_Artists_Created_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Artists_Created_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Artists_Created_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Artists_Created_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Artists_Created_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Artists_Created_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Artists_Created_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Artists_Created_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_artists_created_by_day" */
export type Count_Artists_Created_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Artists_Created_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Artists_Created_By_Day_Avg_Fields = {
  __typename?: 'count_artists_created_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_artists_created_by_day". All fields are combined with a logical 'AND'. */
export type Count_Artists_Created_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Artists_Created_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Artists_Created_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Artists_Created_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Artists_Created_By_Day_Max_Fields = {
  __typename?: 'count_artists_created_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Artists_Created_By_Day_Min_Fields = {
  __typename?: 'count_artists_created_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_artists_created_by_day". */
export type Count_Artists_Created_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_artists_created_by_day" */
export enum Count_Artists_Created_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Artists_Created_By_Day_Stddev_Fields = {
  __typename?: 'count_artists_created_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Artists_Created_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_artists_created_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Artists_Created_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_artists_created_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Artists_Created_By_Day_Sum_Fields = {
  __typename?: 'count_artists_created_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Artists_Created_By_Day_Var_Pop_Fields = {
  __typename?: 'count_artists_created_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Artists_Created_By_Day_Var_Samp_Fields = {
  __typename?: 'count_artists_created_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Artists_Created_By_Day_Variance_Fields = {
  __typename?: 'count_artists_created_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_artists_pending_by_day" */
export type Count_Artists_Pending_By_Day = {
  __typename?: 'count_artists_pending_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_artists_pending_by_day" */
export type Count_Artists_Pending_By_Day_Aggregate = {
  __typename?: 'count_artists_pending_by_day_aggregate';
  aggregate?: Maybe<Count_Artists_Pending_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Artists_Pending_By_Day>;
};

/** aggregate fields of "count_artists_pending_by_day" */
export type Count_Artists_Pending_By_Day_Aggregate_Fields = {
  __typename?: 'count_artists_pending_by_day_aggregate_fields';
  avg?: Maybe<Count_Artists_Pending_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Artists_Pending_By_Day_Max_Fields>;
  min?: Maybe<Count_Artists_Pending_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Artists_Pending_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Artists_Pending_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Artists_Pending_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Artists_Pending_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Artists_Pending_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Artists_Pending_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Artists_Pending_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_artists_pending_by_day" */
export type Count_Artists_Pending_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Artists_Pending_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Artists_Pending_By_Day_Avg_Fields = {
  __typename?: 'count_artists_pending_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_artists_pending_by_day". All fields are combined with a logical 'AND'. */
export type Count_Artists_Pending_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Artists_Pending_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Artists_Pending_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Artists_Pending_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Artists_Pending_By_Day_Max_Fields = {
  __typename?: 'count_artists_pending_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Artists_Pending_By_Day_Min_Fields = {
  __typename?: 'count_artists_pending_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_artists_pending_by_day". */
export type Count_Artists_Pending_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_artists_pending_by_day" */
export enum Count_Artists_Pending_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Artists_Pending_By_Day_Stddev_Fields = {
  __typename?: 'count_artists_pending_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Artists_Pending_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_artists_pending_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Artists_Pending_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_artists_pending_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Artists_Pending_By_Day_Sum_Fields = {
  __typename?: 'count_artists_pending_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Artists_Pending_By_Day_Var_Pop_Fields = {
  __typename?: 'count_artists_pending_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Artists_Pending_By_Day_Var_Samp_Fields = {
  __typename?: 'count_artists_pending_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Artists_Pending_By_Day_Variance_Fields = {
  __typename?: 'count_artists_pending_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_artists_rejected_by_day" */
export type Count_Artists_Rejected_By_Day = {
  __typename?: 'count_artists_rejected_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_artists_rejected_by_day" */
export type Count_Artists_Rejected_By_Day_Aggregate = {
  __typename?: 'count_artists_rejected_by_day_aggregate';
  aggregate?: Maybe<Count_Artists_Rejected_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Artists_Rejected_By_Day>;
};

/** aggregate fields of "count_artists_rejected_by_day" */
export type Count_Artists_Rejected_By_Day_Aggregate_Fields = {
  __typename?: 'count_artists_rejected_by_day_aggregate_fields';
  avg?: Maybe<Count_Artists_Rejected_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Artists_Rejected_By_Day_Max_Fields>;
  min?: Maybe<Count_Artists_Rejected_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Artists_Rejected_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Artists_Rejected_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Artists_Rejected_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Artists_Rejected_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Artists_Rejected_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Artists_Rejected_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Artists_Rejected_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_artists_rejected_by_day" */
export type Count_Artists_Rejected_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Artists_Rejected_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Artists_Rejected_By_Day_Avg_Fields = {
  __typename?: 'count_artists_rejected_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_artists_rejected_by_day". All fields are combined with a logical 'AND'. */
export type Count_Artists_Rejected_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Artists_Rejected_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Artists_Rejected_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Artists_Rejected_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Artists_Rejected_By_Day_Max_Fields = {
  __typename?: 'count_artists_rejected_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Artists_Rejected_By_Day_Min_Fields = {
  __typename?: 'count_artists_rejected_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_artists_rejected_by_day". */
export type Count_Artists_Rejected_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_artists_rejected_by_day" */
export enum Count_Artists_Rejected_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Artists_Rejected_By_Day_Stddev_Fields = {
  __typename?: 'count_artists_rejected_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Artists_Rejected_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_artists_rejected_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Artists_Rejected_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_artists_rejected_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Artists_Rejected_By_Day_Sum_Fields = {
  __typename?: 'count_artists_rejected_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Artists_Rejected_By_Day_Var_Pop_Fields = {
  __typename?: 'count_artists_rejected_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Artists_Rejected_By_Day_Var_Samp_Fields = {
  __typename?: 'count_artists_rejected_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Artists_Rejected_By_Day_Variance_Fields = {
  __typename?: 'count_artists_rejected_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_artworks_published_by_day" */
export type Count_Artworks_Published_By_Day = {
  __typename?: 'count_artworks_published_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_artworks_published_by_day" */
export type Count_Artworks_Published_By_Day_Aggregate = {
  __typename?: 'count_artworks_published_by_day_aggregate';
  aggregate?: Maybe<Count_Artworks_Published_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Artworks_Published_By_Day>;
};

/** aggregate fields of "count_artworks_published_by_day" */
export type Count_Artworks_Published_By_Day_Aggregate_Fields = {
  __typename?: 'count_artworks_published_by_day_aggregate_fields';
  avg?: Maybe<Count_Artworks_Published_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Artworks_Published_By_Day_Max_Fields>;
  min?: Maybe<Count_Artworks_Published_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Artworks_Published_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Artworks_Published_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Artworks_Published_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Artworks_Published_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Artworks_Published_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Artworks_Published_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Artworks_Published_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_artworks_published_by_day" */
export type Count_Artworks_Published_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Artworks_Published_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Artworks_Published_By_Day_Avg_Fields = {
  __typename?: 'count_artworks_published_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_artworks_published_by_day". All fields are combined with a logical 'AND'. */
export type Count_Artworks_Published_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Artworks_Published_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Artworks_Published_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Artworks_Published_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Artworks_Published_By_Day_Max_Fields = {
  __typename?: 'count_artworks_published_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Artworks_Published_By_Day_Min_Fields = {
  __typename?: 'count_artworks_published_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_artworks_published_by_day". */
export type Count_Artworks_Published_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_artworks_published_by_day" */
export enum Count_Artworks_Published_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Artworks_Published_By_Day_Stddev_Fields = {
  __typename?: 'count_artworks_published_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Artworks_Published_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_artworks_published_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Artworks_Published_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_artworks_published_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Artworks_Published_By_Day_Sum_Fields = {
  __typename?: 'count_artworks_published_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Artworks_Published_By_Day_Var_Pop_Fields = {
  __typename?: 'count_artworks_published_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Artworks_Published_By_Day_Var_Samp_Fields = {
  __typename?: 'count_artworks_published_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Artworks_Published_By_Day_Variance_Fields = {
  __typename?: 'count_artworks_published_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_artworks_published_this_week" */
export type Count_Artworks_Published_This_Week = {
  __typename?: 'count_artworks_published_this_week';
  artist_context_id?: Maybe<Scalars['bigint']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "count_artworks_published_this_week" */
export type Count_Artworks_Published_This_Week_Aggregate = {
  __typename?: 'count_artworks_published_this_week_aggregate';
  aggregate?: Maybe<Count_Artworks_Published_This_Week_Aggregate_Fields>;
  nodes: Array<Count_Artworks_Published_This_Week>;
};

/** aggregate fields of "count_artworks_published_this_week" */
export type Count_Artworks_Published_This_Week_Aggregate_Fields = {
  __typename?: 'count_artworks_published_this_week_aggregate_fields';
  avg?: Maybe<Count_Artworks_Published_This_Week_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Artworks_Published_This_Week_Max_Fields>;
  min?: Maybe<Count_Artworks_Published_This_Week_Min_Fields>;
  stddev?: Maybe<Count_Artworks_Published_This_Week_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Artworks_Published_This_Week_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Artworks_Published_This_Week_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Artworks_Published_This_Week_Sum_Fields>;
  var_pop?: Maybe<Count_Artworks_Published_This_Week_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Artworks_Published_This_Week_Var_Samp_Fields>;
  variance?: Maybe<Count_Artworks_Published_This_Week_Variance_Fields>;
};


/** aggregate fields of "count_artworks_published_this_week" */
export type Count_Artworks_Published_This_Week_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Artworks_Published_This_Week_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Artworks_Published_This_Week_Avg_Fields = {
  __typename?: 'count_artworks_published_this_week_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_artworks_published_this_week". All fields are combined with a logical 'AND'. */
export type Count_Artworks_Published_This_Week_Bool_Exp = {
  _and?: Maybe<Array<Count_Artworks_Published_This_Week_Bool_Exp>>;
  _not?: Maybe<Count_Artworks_Published_This_Week_Bool_Exp>;
  _or?: Maybe<Array<Count_Artworks_Published_This_Week_Bool_Exp>>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Artworks_Published_This_Week_Max_Fields = {
  __typename?: 'count_artworks_published_this_week_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Count_Artworks_Published_This_Week_Min_Fields = {
  __typename?: 'count_artworks_published_this_week_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "count_artworks_published_this_week". */
export type Count_Artworks_Published_This_Week_Order_By = {
  artist_context_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
};

/** select columns of table "count_artworks_published_this_week" */
export enum Count_Artworks_Published_This_Week_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Count_Artworks_Published_This_Week_Stddev_Fields = {
  __typename?: 'count_artworks_published_this_week_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Artworks_Published_This_Week_Stddev_Pop_Fields = {
  __typename?: 'count_artworks_published_this_week_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Artworks_Published_This_Week_Stddev_Samp_Fields = {
  __typename?: 'count_artworks_published_this_week_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Artworks_Published_This_Week_Sum_Fields = {
  __typename?: 'count_artworks_published_this_week_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Artworks_Published_This_Week_Var_Pop_Fields = {
  __typename?: 'count_artworks_published_this_week_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Artworks_Published_This_Week_Var_Samp_Fields = {
  __typename?: 'count_artworks_published_this_week_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Artworks_Published_This_Week_Variance_Fields = {
  __typename?: 'count_artworks_published_this_week_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_collectors_created_by_day" */
export type Count_Collectors_Created_By_Day = {
  __typename?: 'count_collectors_created_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_collectors_created_by_day" */
export type Count_Collectors_Created_By_Day_Aggregate = {
  __typename?: 'count_collectors_created_by_day_aggregate';
  aggregate?: Maybe<Count_Collectors_Created_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Collectors_Created_By_Day>;
};

/** aggregate fields of "count_collectors_created_by_day" */
export type Count_Collectors_Created_By_Day_Aggregate_Fields = {
  __typename?: 'count_collectors_created_by_day_aggregate_fields';
  avg?: Maybe<Count_Collectors_Created_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Collectors_Created_By_Day_Max_Fields>;
  min?: Maybe<Count_Collectors_Created_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Collectors_Created_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Collectors_Created_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Collectors_Created_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Collectors_Created_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Collectors_Created_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Collectors_Created_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Collectors_Created_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_collectors_created_by_day" */
export type Count_Collectors_Created_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Collectors_Created_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Collectors_Created_By_Day_Avg_Fields = {
  __typename?: 'count_collectors_created_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_collectors_created_by_day". All fields are combined with a logical 'AND'. */
export type Count_Collectors_Created_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Collectors_Created_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Collectors_Created_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Collectors_Created_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Collectors_Created_By_Day_Max_Fields = {
  __typename?: 'count_collectors_created_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Collectors_Created_By_Day_Min_Fields = {
  __typename?: 'count_collectors_created_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_collectors_created_by_day". */
export type Count_Collectors_Created_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_collectors_created_by_day" */
export enum Count_Collectors_Created_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Collectors_Created_By_Day_Stddev_Fields = {
  __typename?: 'count_collectors_created_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Collectors_Created_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_collectors_created_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Collectors_Created_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_collectors_created_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Collectors_Created_By_Day_Sum_Fields = {
  __typename?: 'count_collectors_created_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Collectors_Created_By_Day_Var_Pop_Fields = {
  __typename?: 'count_collectors_created_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Collectors_Created_By_Day_Var_Samp_Fields = {
  __typename?: 'count_collectors_created_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Collectors_Created_By_Day_Variance_Fields = {
  __typename?: 'count_collectors_created_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_galleries_approved_by_day" */
export type Count_Galleries_Approved_By_Day = {
  __typename?: 'count_galleries_approved_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_galleries_approved_by_day" */
export type Count_Galleries_Approved_By_Day_Aggregate = {
  __typename?: 'count_galleries_approved_by_day_aggregate';
  aggregate?: Maybe<Count_Galleries_Approved_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Galleries_Approved_By_Day>;
};

/** aggregate fields of "count_galleries_approved_by_day" */
export type Count_Galleries_Approved_By_Day_Aggregate_Fields = {
  __typename?: 'count_galleries_approved_by_day_aggregate_fields';
  avg?: Maybe<Count_Galleries_Approved_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Galleries_Approved_By_Day_Max_Fields>;
  min?: Maybe<Count_Galleries_Approved_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Galleries_Approved_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Galleries_Approved_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Galleries_Approved_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Galleries_Approved_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Galleries_Approved_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Galleries_Approved_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Galleries_Approved_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_galleries_approved_by_day" */
export type Count_Galleries_Approved_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Galleries_Approved_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Galleries_Approved_By_Day_Avg_Fields = {
  __typename?: 'count_galleries_approved_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_galleries_approved_by_day". All fields are combined with a logical 'AND'. */
export type Count_Galleries_Approved_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Galleries_Approved_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Galleries_Approved_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Galleries_Approved_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Galleries_Approved_By_Day_Max_Fields = {
  __typename?: 'count_galleries_approved_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Galleries_Approved_By_Day_Min_Fields = {
  __typename?: 'count_galleries_approved_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_galleries_approved_by_day". */
export type Count_Galleries_Approved_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_galleries_approved_by_day" */
export enum Count_Galleries_Approved_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Galleries_Approved_By_Day_Stddev_Fields = {
  __typename?: 'count_galleries_approved_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Galleries_Approved_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_galleries_approved_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Galleries_Approved_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_galleries_approved_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Galleries_Approved_By_Day_Sum_Fields = {
  __typename?: 'count_galleries_approved_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Galleries_Approved_By_Day_Var_Pop_Fields = {
  __typename?: 'count_galleries_approved_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Galleries_Approved_By_Day_Var_Samp_Fields = {
  __typename?: 'count_galleries_approved_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Galleries_Approved_By_Day_Variance_Fields = {
  __typename?: 'count_galleries_approved_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_galleries_created_by_day" */
export type Count_Galleries_Created_By_Day = {
  __typename?: 'count_galleries_created_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_galleries_created_by_day" */
export type Count_Galleries_Created_By_Day_Aggregate = {
  __typename?: 'count_galleries_created_by_day_aggregate';
  aggregate?: Maybe<Count_Galleries_Created_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Galleries_Created_By_Day>;
};

/** aggregate fields of "count_galleries_created_by_day" */
export type Count_Galleries_Created_By_Day_Aggregate_Fields = {
  __typename?: 'count_galleries_created_by_day_aggregate_fields';
  avg?: Maybe<Count_Galleries_Created_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Galleries_Created_By_Day_Max_Fields>;
  min?: Maybe<Count_Galleries_Created_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Galleries_Created_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Galleries_Created_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Galleries_Created_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Galleries_Created_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Galleries_Created_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Galleries_Created_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Galleries_Created_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_galleries_created_by_day" */
export type Count_Galleries_Created_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Galleries_Created_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Galleries_Created_By_Day_Avg_Fields = {
  __typename?: 'count_galleries_created_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_galleries_created_by_day". All fields are combined with a logical 'AND'. */
export type Count_Galleries_Created_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Galleries_Created_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Galleries_Created_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Galleries_Created_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Galleries_Created_By_Day_Max_Fields = {
  __typename?: 'count_galleries_created_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Galleries_Created_By_Day_Min_Fields = {
  __typename?: 'count_galleries_created_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_galleries_created_by_day". */
export type Count_Galleries_Created_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_galleries_created_by_day" */
export enum Count_Galleries_Created_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Galleries_Created_By_Day_Stddev_Fields = {
  __typename?: 'count_galleries_created_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Galleries_Created_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_galleries_created_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Galleries_Created_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_galleries_created_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Galleries_Created_By_Day_Sum_Fields = {
  __typename?: 'count_galleries_created_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Galleries_Created_By_Day_Var_Pop_Fields = {
  __typename?: 'count_galleries_created_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Galleries_Created_By_Day_Var_Samp_Fields = {
  __typename?: 'count_galleries_created_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Galleries_Created_By_Day_Variance_Fields = {
  __typename?: 'count_galleries_created_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_galleries_pending_by_day" */
export type Count_Galleries_Pending_By_Day = {
  __typename?: 'count_galleries_pending_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_galleries_pending_by_day" */
export type Count_Galleries_Pending_By_Day_Aggregate = {
  __typename?: 'count_galleries_pending_by_day_aggregate';
  aggregate?: Maybe<Count_Galleries_Pending_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Galleries_Pending_By_Day>;
};

/** aggregate fields of "count_galleries_pending_by_day" */
export type Count_Galleries_Pending_By_Day_Aggregate_Fields = {
  __typename?: 'count_galleries_pending_by_day_aggregate_fields';
  avg?: Maybe<Count_Galleries_Pending_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Galleries_Pending_By_Day_Max_Fields>;
  min?: Maybe<Count_Galleries_Pending_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Galleries_Pending_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Galleries_Pending_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Galleries_Pending_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Galleries_Pending_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Galleries_Pending_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Galleries_Pending_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Galleries_Pending_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_galleries_pending_by_day" */
export type Count_Galleries_Pending_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Galleries_Pending_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Galleries_Pending_By_Day_Avg_Fields = {
  __typename?: 'count_galleries_pending_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_galleries_pending_by_day". All fields are combined with a logical 'AND'. */
export type Count_Galleries_Pending_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Galleries_Pending_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Galleries_Pending_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Galleries_Pending_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Galleries_Pending_By_Day_Max_Fields = {
  __typename?: 'count_galleries_pending_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Galleries_Pending_By_Day_Min_Fields = {
  __typename?: 'count_galleries_pending_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_galleries_pending_by_day". */
export type Count_Galleries_Pending_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_galleries_pending_by_day" */
export enum Count_Galleries_Pending_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Galleries_Pending_By_Day_Stddev_Fields = {
  __typename?: 'count_galleries_pending_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Galleries_Pending_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_galleries_pending_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Galleries_Pending_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_galleries_pending_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Galleries_Pending_By_Day_Sum_Fields = {
  __typename?: 'count_galleries_pending_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Galleries_Pending_By_Day_Var_Pop_Fields = {
  __typename?: 'count_galleries_pending_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Galleries_Pending_By_Day_Var_Samp_Fields = {
  __typename?: 'count_galleries_pending_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Galleries_Pending_By_Day_Variance_Fields = {
  __typename?: 'count_galleries_pending_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_galleries_rejected_by_day" */
export type Count_Galleries_Rejected_By_Day = {
  __typename?: 'count_galleries_rejected_by_day';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "count_galleries_rejected_by_day" */
export type Count_Galleries_Rejected_By_Day_Aggregate = {
  __typename?: 'count_galleries_rejected_by_day_aggregate';
  aggregate?: Maybe<Count_Galleries_Rejected_By_Day_Aggregate_Fields>;
  nodes: Array<Count_Galleries_Rejected_By_Day>;
};

/** aggregate fields of "count_galleries_rejected_by_day" */
export type Count_Galleries_Rejected_By_Day_Aggregate_Fields = {
  __typename?: 'count_galleries_rejected_by_day_aggregate_fields';
  avg?: Maybe<Count_Galleries_Rejected_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Galleries_Rejected_By_Day_Max_Fields>;
  min?: Maybe<Count_Galleries_Rejected_By_Day_Min_Fields>;
  stddev?: Maybe<Count_Galleries_Rejected_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Galleries_Rejected_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Galleries_Rejected_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Galleries_Rejected_By_Day_Sum_Fields>;
  var_pop?: Maybe<Count_Galleries_Rejected_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Galleries_Rejected_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Count_Galleries_Rejected_By_Day_Variance_Fields>;
};


/** aggregate fields of "count_galleries_rejected_by_day" */
export type Count_Galleries_Rejected_By_Day_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Galleries_Rejected_By_Day_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Galleries_Rejected_By_Day_Avg_Fields = {
  __typename?: 'count_galleries_rejected_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_galleries_rejected_by_day". All fields are combined with a logical 'AND'. */
export type Count_Galleries_Rejected_By_Day_Bool_Exp = {
  _and?: Maybe<Array<Count_Galleries_Rejected_By_Day_Bool_Exp>>;
  _not?: Maybe<Count_Galleries_Rejected_By_Day_Bool_Exp>;
  _or?: Maybe<Array<Count_Galleries_Rejected_By_Day_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Galleries_Rejected_By_Day_Max_Fields = {
  __typename?: 'count_galleries_rejected_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Count_Galleries_Rejected_By_Day_Min_Fields = {
  __typename?: 'count_galleries_rejected_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  time?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "count_galleries_rejected_by_day". */
export type Count_Galleries_Rejected_By_Day_Order_By = {
  count?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** select columns of table "count_galleries_rejected_by_day" */
export enum Count_Galleries_Rejected_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Time = 'time'
}

/** aggregate stddev on columns */
export type Count_Galleries_Rejected_By_Day_Stddev_Fields = {
  __typename?: 'count_galleries_rejected_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Galleries_Rejected_By_Day_Stddev_Pop_Fields = {
  __typename?: 'count_galleries_rejected_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Galleries_Rejected_By_Day_Stddev_Samp_Fields = {
  __typename?: 'count_galleries_rejected_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Galleries_Rejected_By_Day_Sum_Fields = {
  __typename?: 'count_galleries_rejected_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Galleries_Rejected_By_Day_Var_Pop_Fields = {
  __typename?: 'count_galleries_rejected_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Galleries_Rejected_By_Day_Var_Samp_Fields = {
  __typename?: 'count_galleries_rejected_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Galleries_Rejected_By_Day_Variance_Fields = {
  __typename?: 'count_galleries_rejected_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_profile_visits_published_biweekly" */
export type Count_Profile_Visits_Published_Biweekly = {
  __typename?: 'count_profile_visits_published_biweekly';
  counter?: Maybe<Scalars['bigint']>;
  parts?: Maybe<Scalars['String']>;
};

/** aggregated selection of "count_profile_visits_published_biweekly" */
export type Count_Profile_Visits_Published_Biweekly_Aggregate = {
  __typename?: 'count_profile_visits_published_biweekly_aggregate';
  aggregate?: Maybe<Count_Profile_Visits_Published_Biweekly_Aggregate_Fields>;
  nodes: Array<Count_Profile_Visits_Published_Biweekly>;
};

/** aggregate fields of "count_profile_visits_published_biweekly" */
export type Count_Profile_Visits_Published_Biweekly_Aggregate_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_aggregate_fields';
  avg?: Maybe<Count_Profile_Visits_Published_Biweekly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Profile_Visits_Published_Biweekly_Max_Fields>;
  min?: Maybe<Count_Profile_Visits_Published_Biweekly_Min_Fields>;
  stddev?: Maybe<Count_Profile_Visits_Published_Biweekly_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Profile_Visits_Published_Biweekly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Profile_Visits_Published_Biweekly_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Profile_Visits_Published_Biweekly_Sum_Fields>;
  var_pop?: Maybe<Count_Profile_Visits_Published_Biweekly_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Profile_Visits_Published_Biweekly_Var_Samp_Fields>;
  variance?: Maybe<Count_Profile_Visits_Published_Biweekly_Variance_Fields>;
};


/** aggregate fields of "count_profile_visits_published_biweekly" */
export type Count_Profile_Visits_Published_Biweekly_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Profile_Visits_Published_Biweekly_Avg_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_avg_fields';
  counter?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_profile_visits_published_biweekly". All fields are combined with a logical 'AND'. */
export type Count_Profile_Visits_Published_Biweekly_Bool_Exp = {
  _and?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Bool_Exp>>;
  _not?: Maybe<Count_Profile_Visits_Published_Biweekly_Bool_Exp>;
  _or?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Bool_Exp>>;
  counter?: Maybe<Bigint_Comparison_Exp>;
  parts?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Profile_Visits_Published_Biweekly_Max_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_max_fields';
  counter?: Maybe<Scalars['bigint']>;
  parts?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Count_Profile_Visits_Published_Biweekly_Min_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_min_fields';
  counter?: Maybe<Scalars['bigint']>;
  parts?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "count_profile_visits_published_biweekly". */
export type Count_Profile_Visits_Published_Biweekly_Order_By = {
  counter?: Maybe<Order_By>;
  parts?: Maybe<Order_By>;
};

/** select columns of table "count_profile_visits_published_biweekly" */
export enum Count_Profile_Visits_Published_Biweekly_Select_Column {
  /** column name */
  Counter = 'counter',
  /** column name */
  Parts = 'parts'
}

/** aggregate stddev on columns */
export type Count_Profile_Visits_Published_Biweekly_Stddev_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_stddev_fields';
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Profile_Visits_Published_Biweekly_Stddev_Pop_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_stddev_pop_fields';
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Profile_Visits_Published_Biweekly_Stddev_Samp_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_stddev_samp_fields';
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Profile_Visits_Published_Biweekly_Sum_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_sum_fields';
  counter?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Count_Profile_Visits_Published_Biweekly_Var_Pop_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_var_pop_fields';
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Profile_Visits_Published_Biweekly_Var_Samp_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_var_samp_fields';
  counter?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Profile_Visits_Published_Biweekly_Variance_Fields = {
  __typename?: 'count_profile_visits_published_biweekly_variance_fields';
  counter?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "count_recognitions_published_biweekly" */
export type Count_Recognitions_Published_Biweekly = {
  __typename?: 'count_recognitions_published_biweekly';
  id?: Maybe<Scalars['bigint']>;
  weeklyartistrecognitions?: Maybe<Scalars['Int']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Int']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "count_recognitions_published_biweekly" */
export type Count_Recognitions_Published_Biweekly_Aggregate = {
  __typename?: 'count_recognitions_published_biweekly_aggregate';
  aggregate?: Maybe<Count_Recognitions_Published_Biweekly_Aggregate_Fields>;
  nodes: Array<Count_Recognitions_Published_Biweekly>;
};

/** aggregate fields of "count_recognitions_published_biweekly" */
export type Count_Recognitions_Published_Biweekly_Aggregate_Fields = {
  __typename?: 'count_recognitions_published_biweekly_aggregate_fields';
  avg?: Maybe<Count_Recognitions_Published_Biweekly_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Count_Recognitions_Published_Biweekly_Max_Fields>;
  min?: Maybe<Count_Recognitions_Published_Biweekly_Min_Fields>;
  stddev?: Maybe<Count_Recognitions_Published_Biweekly_Stddev_Fields>;
  stddev_pop?: Maybe<Count_Recognitions_Published_Biweekly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Count_Recognitions_Published_Biweekly_Stddev_Samp_Fields>;
  sum?: Maybe<Count_Recognitions_Published_Biweekly_Sum_Fields>;
  var_pop?: Maybe<Count_Recognitions_Published_Biweekly_Var_Pop_Fields>;
  var_samp?: Maybe<Count_Recognitions_Published_Biweekly_Var_Samp_Fields>;
  variance?: Maybe<Count_Recognitions_Published_Biweekly_Variance_Fields>;
};


/** aggregate fields of "count_recognitions_published_biweekly" */
export type Count_Recognitions_Published_Biweekly_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Count_Recognitions_Published_Biweekly_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Count_Recognitions_Published_Biweekly_Avg_Fields = {
  __typename?: 'count_recognitions_published_biweekly_avg_fields';
  id?: Maybe<Scalars['Float']>;
  weeklyartistrecognitions?: Maybe<Scalars['Float']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Float']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "count_recognitions_published_biweekly". All fields are combined with a logical 'AND'. */
export type Count_Recognitions_Published_Biweekly_Bool_Exp = {
  _and?: Maybe<Array<Count_Recognitions_Published_Biweekly_Bool_Exp>>;
  _not?: Maybe<Count_Recognitions_Published_Biweekly_Bool_Exp>;
  _or?: Maybe<Array<Count_Recognitions_Published_Biweekly_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  weeklyartistrecognitions?: Maybe<Int_Comparison_Exp>;
  weeklycollectorrecognitions?: Maybe<Int_Comparison_Exp>;
  weeklymutualartistrecognitions?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Count_Recognitions_Published_Biweekly_Max_Fields = {
  __typename?: 'count_recognitions_published_biweekly_max_fields';
  id?: Maybe<Scalars['bigint']>;
  weeklyartistrecognitions?: Maybe<Scalars['Int']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Int']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Count_Recognitions_Published_Biweekly_Min_Fields = {
  __typename?: 'count_recognitions_published_biweekly_min_fields';
  id?: Maybe<Scalars['bigint']>;
  weeklyartistrecognitions?: Maybe<Scalars['Int']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Int']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "count_recognitions_published_biweekly". */
export type Count_Recognitions_Published_Biweekly_Order_By = {
  id?: Maybe<Order_By>;
  weeklyartistrecognitions?: Maybe<Order_By>;
  weeklycollectorrecognitions?: Maybe<Order_By>;
  weeklymutualartistrecognitions?: Maybe<Order_By>;
};

/** select columns of table "count_recognitions_published_biweekly" */
export enum Count_Recognitions_Published_Biweekly_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Weeklyartistrecognitions = 'weeklyartistrecognitions',
  /** column name */
  Weeklycollectorrecognitions = 'weeklycollectorrecognitions',
  /** column name */
  Weeklymutualartistrecognitions = 'weeklymutualartistrecognitions'
}

/** aggregate stddev on columns */
export type Count_Recognitions_Published_Biweekly_Stddev_Fields = {
  __typename?: 'count_recognitions_published_biweekly_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  weeklyartistrecognitions?: Maybe<Scalars['Float']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Float']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Count_Recognitions_Published_Biweekly_Stddev_Pop_Fields = {
  __typename?: 'count_recognitions_published_biweekly_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  weeklyartistrecognitions?: Maybe<Scalars['Float']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Float']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Count_Recognitions_Published_Biweekly_Stddev_Samp_Fields = {
  __typename?: 'count_recognitions_published_biweekly_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  weeklyartistrecognitions?: Maybe<Scalars['Float']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Float']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Count_Recognitions_Published_Biweekly_Sum_Fields = {
  __typename?: 'count_recognitions_published_biweekly_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  weeklyartistrecognitions?: Maybe<Scalars['Int']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Int']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Count_Recognitions_Published_Biweekly_Var_Pop_Fields = {
  __typename?: 'count_recognitions_published_biweekly_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  weeklyartistrecognitions?: Maybe<Scalars['Float']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Float']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Count_Recognitions_Published_Biweekly_Var_Samp_Fields = {
  __typename?: 'count_recognitions_published_biweekly_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  weeklyartistrecognitions?: Maybe<Scalars['Float']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Float']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Count_Recognitions_Published_Biweekly_Variance_Fields = {
  __typename?: 'count_recognitions_published_biweekly_variance_fields';
  id?: Maybe<Scalars['Float']>;
  weeklyartistrecognitions?: Maybe<Scalars['Float']>;
  weeklycollectorrecognitions?: Maybe<Scalars['Float']>;
  weeklymutualartistrecognitions?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "country_states_mv" */
export type Country_States_Mv = {
  __typename?: 'country_states_mv';
  admin_code?: Maybe<Scalars['String']>;
  admin_name?: Maybe<Scalars['String']>;
  admin_name_ascii?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
};

/** aggregated selection of "country_states_mv" */
export type Country_States_Mv_Aggregate = {
  __typename?: 'country_states_mv_aggregate';
  aggregate?: Maybe<Country_States_Mv_Aggregate_Fields>;
  nodes: Array<Country_States_Mv>;
};

/** aggregate fields of "country_states_mv" */
export type Country_States_Mv_Aggregate_Fields = {
  __typename?: 'country_states_mv_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Country_States_Mv_Max_Fields>;
  min?: Maybe<Country_States_Mv_Min_Fields>;
};


/** aggregate fields of "country_states_mv" */
export type Country_States_Mv_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Country_States_Mv_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "country_states_mv". All fields are combined with a logical 'AND'. */
export type Country_States_Mv_Bool_Exp = {
  _and?: Maybe<Array<Country_States_Mv_Bool_Exp>>;
  _not?: Maybe<Country_States_Mv_Bool_Exp>;
  _or?: Maybe<Array<Country_States_Mv_Bool_Exp>>;
  admin_code?: Maybe<String_Comparison_Exp>;
  admin_name?: Maybe<String_Comparison_Exp>;
  admin_name_ascii?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  iso2?: Maybe<String_Comparison_Exp>;
  iso3?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Country_States_Mv_Max_Fields = {
  __typename?: 'country_states_mv_max_fields';
  admin_code?: Maybe<Scalars['String']>;
  admin_name?: Maybe<Scalars['String']>;
  admin_name_ascii?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Country_States_Mv_Min_Fields = {
  __typename?: 'country_states_mv_min_fields';
  admin_code?: Maybe<Scalars['String']>;
  admin_name?: Maybe<Scalars['String']>;
  admin_name_ascii?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "country_states_mv". */
export type Country_States_Mv_Order_By = {
  admin_code?: Maybe<Order_By>;
  admin_name?: Maybe<Order_By>;
  admin_name_ascii?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  iso2?: Maybe<Order_By>;
  iso3?: Maybe<Order_By>;
};

/** select columns of table "country_states_mv" */
export enum Country_States_Mv_Select_Column {
  /** column name */
  AdminCode = 'admin_code',
  /** column name */
  AdminName = 'admin_name',
  /** column name */
  AdminNameAscii = 'admin_name_ascii',
  /** column name */
  Country = 'country',
  /** column name */
  Iso2 = 'iso2',
  /** column name */
  Iso3 = 'iso3'
}

export type CustomArtwork = {
  __typename?: 'customArtwork';
  is_available_sale?: Maybe<Scalars['Boolean']>;
};

export type CustomArtworkTransaction = {
  __typename?: 'customArtworkTransaction';
  buyer_context?: Maybe<BuyerContext>;
};

export type CustomProfile = {
  __typename?: 'customProfile';
  handle?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "discount_code_view" */
export type Discount_Code_View = {
  __typename?: 'discount_code_view';
  amount_used?: Maybe<Scalars['bigint']>;
  code?: Maybe<Scalars['String']>;
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  discount_type?: Maybe<Scalars['String']>;
  discount_value_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  trial_period_days?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "discount_code_view" */
export type Discount_Code_View_Aggregate = {
  __typename?: 'discount_code_view_aggregate';
  aggregate?: Maybe<Discount_Code_View_Aggregate_Fields>;
  nodes: Array<Discount_Code_View>;
};

/** aggregate fields of "discount_code_view" */
export type Discount_Code_View_Aggregate_Fields = {
  __typename?: 'discount_code_view_aggregate_fields';
  avg?: Maybe<Discount_Code_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Discount_Code_View_Max_Fields>;
  min?: Maybe<Discount_Code_View_Min_Fields>;
  stddev?: Maybe<Discount_Code_View_Stddev_Fields>;
  stddev_pop?: Maybe<Discount_Code_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Discount_Code_View_Stddev_Samp_Fields>;
  sum?: Maybe<Discount_Code_View_Sum_Fields>;
  var_pop?: Maybe<Discount_Code_View_Var_Pop_Fields>;
  var_samp?: Maybe<Discount_Code_View_Var_Samp_Fields>;
  variance?: Maybe<Discount_Code_View_Variance_Fields>;
};


/** aggregate fields of "discount_code_view" */
export type Discount_Code_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discount_Code_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Discount_Code_View_Avg_Fields = {
  __typename?: 'discount_code_view_avg_fields';
  amount_used?: Maybe<Scalars['Float']>;
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "discount_code_view". All fields are combined with a logical 'AND'. */
export type Discount_Code_View_Bool_Exp = {
  _and?: Maybe<Array<Discount_Code_View_Bool_Exp>>;
  _not?: Maybe<Discount_Code_View_Bool_Exp>;
  _or?: Maybe<Array<Discount_Code_View_Bool_Exp>>;
  amount_used?: Maybe<Bigint_Comparison_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  discount_emerging_value?: Maybe<Float8_Comparison_Exp>;
  discount_professional_value?: Maybe<Float8_Comparison_Exp>;
  discount_type?: Maybe<String_Comparison_Exp>;
  discount_value_type?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  expired_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  trial_period_days?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Discount_Code_View_Max_Fields = {
  __typename?: 'discount_code_view_max_fields';
  amount_used?: Maybe<Scalars['bigint']>;
  code?: Maybe<Scalars['String']>;
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  discount_type?: Maybe<Scalars['String']>;
  discount_value_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  trial_period_days?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Discount_Code_View_Min_Fields = {
  __typename?: 'discount_code_view_min_fields';
  amount_used?: Maybe<Scalars['bigint']>;
  code?: Maybe<Scalars['String']>;
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  discount_type?: Maybe<Scalars['String']>;
  discount_value_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  trial_period_days?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "discount_code_view". */
export type Discount_Code_View_Order_By = {
  amount_used?: Maybe<Order_By>;
  code?: Maybe<Order_By>;
  discount_emerging_value?: Maybe<Order_By>;
  discount_professional_value?: Maybe<Order_By>;
  discount_type?: Maybe<Order_By>;
  discount_value_type?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  expired_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  trial_period_days?: Maybe<Order_By>;
};

/** select columns of table "discount_code_view" */
export enum Discount_Code_View_Select_Column {
  /** column name */
  AmountUsed = 'amount_used',
  /** column name */
  Code = 'code',
  /** column name */
  DiscountEmergingValue = 'discount_emerging_value',
  /** column name */
  DiscountProfessionalValue = 'discount_professional_value',
  /** column name */
  DiscountType = 'discount_type',
  /** column name */
  DiscountValueType = 'discount_value_type',
  /** column name */
  Email = 'email',
  /** column name */
  ExpiredAt = 'expired_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  TrialPeriodDays = 'trial_period_days'
}

/** aggregate stddev on columns */
export type Discount_Code_View_Stddev_Fields = {
  __typename?: 'discount_code_view_stddev_fields';
  amount_used?: Maybe<Scalars['Float']>;
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Discount_Code_View_Stddev_Pop_Fields = {
  __typename?: 'discount_code_view_stddev_pop_fields';
  amount_used?: Maybe<Scalars['Float']>;
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Discount_Code_View_Stddev_Samp_Fields = {
  __typename?: 'discount_code_view_stddev_samp_fields';
  amount_used?: Maybe<Scalars['Float']>;
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Discount_Code_View_Sum_Fields = {
  __typename?: 'discount_code_view_sum_fields';
  amount_used?: Maybe<Scalars['bigint']>;
  discount_emerging_value?: Maybe<Scalars['float8']>;
  discount_professional_value?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  trial_period_days?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Discount_Code_View_Var_Pop_Fields = {
  __typename?: 'discount_code_view_var_pop_fields';
  amount_used?: Maybe<Scalars['Float']>;
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Discount_Code_View_Var_Samp_Fields = {
  __typename?: 'discount_code_view_var_samp_fields';
  amount_used?: Maybe<Scalars['Float']>;
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Discount_Code_View_Variance_Fields = {
  __typename?: 'discount_code_view_variance_fields';
  amount_used?: Maybe<Scalars['Float']>;
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  trial_period_days?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "flat_delegate_access" */
export type Flat_Delegate_Access = {
  __typename?: 'flat_delegate_access';
  depth?: Maybe<Scalars['Int']>;
  /** An object relationship */
  granter?: Maybe<Context>;
  granter_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  receiver?: Maybe<Context>;
  receiver_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregated selection of "flat_delegate_access" */
export type Flat_Delegate_Access_Aggregate = {
  __typename?: 'flat_delegate_access_aggregate';
  aggregate?: Maybe<Flat_Delegate_Access_Aggregate_Fields>;
  nodes: Array<Flat_Delegate_Access>;
};

/** aggregate fields of "flat_delegate_access" */
export type Flat_Delegate_Access_Aggregate_Fields = {
  __typename?: 'flat_delegate_access_aggregate_fields';
  avg?: Maybe<Flat_Delegate_Access_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Flat_Delegate_Access_Max_Fields>;
  min?: Maybe<Flat_Delegate_Access_Min_Fields>;
  stddev?: Maybe<Flat_Delegate_Access_Stddev_Fields>;
  stddev_pop?: Maybe<Flat_Delegate_Access_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Flat_Delegate_Access_Stddev_Samp_Fields>;
  sum?: Maybe<Flat_Delegate_Access_Sum_Fields>;
  var_pop?: Maybe<Flat_Delegate_Access_Var_Pop_Fields>;
  var_samp?: Maybe<Flat_Delegate_Access_Var_Samp_Fields>;
  variance?: Maybe<Flat_Delegate_Access_Variance_Fields>;
};


/** aggregate fields of "flat_delegate_access" */
export type Flat_Delegate_Access_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Flat_Delegate_Access_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Flat_Delegate_Access_Avg_Fields = {
  __typename?: 'flat_delegate_access_avg_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "flat_delegate_access". All fields are combined with a logical 'AND'. */
export type Flat_Delegate_Access_Bool_Exp = {
  _and?: Maybe<Array<Flat_Delegate_Access_Bool_Exp>>;
  _not?: Maybe<Flat_Delegate_Access_Bool_Exp>;
  _or?: Maybe<Array<Flat_Delegate_Access_Bool_Exp>>;
  depth?: Maybe<Int_Comparison_Exp>;
  granter?: Maybe<Context_Bool_Exp>;
  granter_id?: Maybe<Bigint_Comparison_Exp>;
  receiver?: Maybe<Context_Bool_Exp>;
  receiver_id?: Maybe<Bigint_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Flat_Delegate_Access_Max_Fields = {
  __typename?: 'flat_delegate_access_max_fields';
  depth?: Maybe<Scalars['Int']>;
  granter_id?: Maybe<Scalars['bigint']>;
  receiver_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Flat_Delegate_Access_Min_Fields = {
  __typename?: 'flat_delegate_access_min_fields';
  depth?: Maybe<Scalars['Int']>;
  granter_id?: Maybe<Scalars['bigint']>;
  receiver_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "flat_delegate_access". */
export type Flat_Delegate_Access_Order_By = {
  depth?: Maybe<Order_By>;
  granter?: Maybe<Context_Order_By>;
  granter_id?: Maybe<Order_By>;
  receiver?: Maybe<Context_Order_By>;
  receiver_id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** select columns of table "flat_delegate_access" */
export enum Flat_Delegate_Access_Select_Column {
  /** column name */
  Depth = 'depth',
  /** column name */
  GranterId = 'granter_id',
  /** column name */
  ReceiverId = 'receiver_id',
  /** column name */
  Role = 'role'
}

/** aggregate stddev on columns */
export type Flat_Delegate_Access_Stddev_Fields = {
  __typename?: 'flat_delegate_access_stddev_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Flat_Delegate_Access_Stddev_Pop_Fields = {
  __typename?: 'flat_delegate_access_stddev_pop_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Flat_Delegate_Access_Stddev_Samp_Fields = {
  __typename?: 'flat_delegate_access_stddev_samp_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Flat_Delegate_Access_Sum_Fields = {
  __typename?: 'flat_delegate_access_sum_fields';
  depth?: Maybe<Scalars['Int']>;
  granter_id?: Maybe<Scalars['bigint']>;
  receiver_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Flat_Delegate_Access_Var_Pop_Fields = {
  __typename?: 'flat_delegate_access_var_pop_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Flat_Delegate_Access_Var_Samp_Fields = {
  __typename?: 'flat_delegate_access_var_samp_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Flat_Delegate_Access_Variance_Fields = {
  __typename?: 'flat_delegate_access_variance_fields';
  depth?: Maybe<Scalars['Float']>;
  granter_id?: Maybe<Scalars['Float']>;
  receiver_id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};


export type Geography_Cast_Exp = {
  geometry?: Maybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: Maybe<Geography_Cast_Exp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: Maybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>;
};


export type Geometry_Cast_Exp = {
  geography?: Maybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: Maybe<Geometry_Cast_Exp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: Maybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: Maybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: Maybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>;
};

export type GetAnalyticsKaleidoCardByProfileId_Args = {
  _from?: Maybe<Scalars['date']>;
  _profile_id?: Maybe<Scalars['bigint']>;
  _to?: Maybe<Scalars['date']>;
};

export type GetArtPrizeShowWinners_Args = {
  _art_prize_show_id?: Maybe<Scalars['bigint']>;
};

export type GetSuggestedPrimaryTagsByArtist_Args = {
  _artist_context_id?: Maybe<Scalars['bigint']>;
};

export type GetSuggestedSecondaryTagsByArtist_Args = {
  _artist_context_id?: Maybe<Scalars['bigint']>;
};

export type Get_Artists_In_Range_Args = {
  input_lat?: Maybe<Scalars['float8']>;
  input_long?: Maybe<Scalars['float8']>;
  range_km?: Maybe<Scalars['float8']>;
};

export type Get_Galleries_In_Range_Args = {
  input_lat?: Maybe<Scalars['float8']>;
  input_long?: Maybe<Scalars['float8']>;
  range_km?: Maybe<Scalars['float8']>;
};

export type Get_Min_Price_Available_Edition_By_Size_Args = {
  _artwork_id?: Maybe<Scalars['bigint']>;
  _edition_id?: Maybe<Scalars['bigint']>;
};

export type Get_Min_Price_Available_Print_Args = {
  _artwork_id?: Maybe<Scalars['bigint']>;
  _edition_by_size_id?: Maybe<Scalars['bigint']>;
  _edition_by_type_id?: Maybe<Scalars['bigint']>;
  _edition_id?: Maybe<Scalars['bigint']>;
};

export type InitiateCartItemsAnonPurchaseTransactionResult = {
  __typename?: 'initiateCartItemsAnonPurchaseTransactionResult';
  anonId?: Maybe<Scalars['bigint']>;
  checkoutUrl: Scalars['String'];
};

/** fields of action: "inquireForPurchaseByOutsideUserAction" */
export type InquireForPurchaseByOutsideUserAction = {
  __typename?: 'inquireForPurchaseByOutsideUserAction';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']>;
  /** the unique id of an action */
  id: Scalars['uuid'];
  /** the output fields of this action */
  output?: Maybe<SampleOutput>;
};

/** columns and relationships of "inside_purchase_inquiries_view" */
export type Inside_Purchase_Inquiries_View = {
  __typename?: 'inside_purchase_inquiries_view';
  artist_city?: Maybe<Scalars['String']>;
  artist_country?: Maybe<Scalars['String']>;
  artist_email?: Maybe<Scalars['String']>;
  artist_link?: Maybe<Scalars['String']>;
  artist_name?: Maybe<Scalars['String']>;
  artist_preferred_language?: Maybe<Scalars['String']>;
  artwork_currency?: Maybe<Scalars['String']>;
  artwork_link?: Maybe<Scalars['String']>;
  artwork_price?: Maybe<Scalars['float8']>;
  date?: Maybe<Scalars['timestamptz']>;
  purchase_inquire_id?: Maybe<Scalars['bigint']>;
  sender_email?: Maybe<Scalars['String']>;
  sender_link?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_preferred_language?: Maybe<Scalars['String']>;
};

/** aggregated selection of "inside_purchase_inquiries_view" */
export type Inside_Purchase_Inquiries_View_Aggregate = {
  __typename?: 'inside_purchase_inquiries_view_aggregate';
  aggregate?: Maybe<Inside_Purchase_Inquiries_View_Aggregate_Fields>;
  nodes: Array<Inside_Purchase_Inquiries_View>;
};

/** aggregate fields of "inside_purchase_inquiries_view" */
export type Inside_Purchase_Inquiries_View_Aggregate_Fields = {
  __typename?: 'inside_purchase_inquiries_view_aggregate_fields';
  avg?: Maybe<Inside_Purchase_Inquiries_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inside_Purchase_Inquiries_View_Max_Fields>;
  min?: Maybe<Inside_Purchase_Inquiries_View_Min_Fields>;
  stddev?: Maybe<Inside_Purchase_Inquiries_View_Stddev_Fields>;
  stddev_pop?: Maybe<Inside_Purchase_Inquiries_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inside_Purchase_Inquiries_View_Stddev_Samp_Fields>;
  sum?: Maybe<Inside_Purchase_Inquiries_View_Sum_Fields>;
  var_pop?: Maybe<Inside_Purchase_Inquiries_View_Var_Pop_Fields>;
  var_samp?: Maybe<Inside_Purchase_Inquiries_View_Var_Samp_Fields>;
  variance?: Maybe<Inside_Purchase_Inquiries_View_Variance_Fields>;
};


/** aggregate fields of "inside_purchase_inquiries_view" */
export type Inside_Purchase_Inquiries_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inside_Purchase_Inquiries_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Inside_Purchase_Inquiries_View_Avg_Fields = {
  __typename?: 'inside_purchase_inquiries_view_avg_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "inside_purchase_inquiries_view". All fields are combined with a logical 'AND'. */
export type Inside_Purchase_Inquiries_View_Bool_Exp = {
  _and?: Maybe<Array<Inside_Purchase_Inquiries_View_Bool_Exp>>;
  _not?: Maybe<Inside_Purchase_Inquiries_View_Bool_Exp>;
  _or?: Maybe<Array<Inside_Purchase_Inquiries_View_Bool_Exp>>;
  artist_city?: Maybe<String_Comparison_Exp>;
  artist_country?: Maybe<String_Comparison_Exp>;
  artist_email?: Maybe<String_Comparison_Exp>;
  artist_link?: Maybe<String_Comparison_Exp>;
  artist_name?: Maybe<String_Comparison_Exp>;
  artist_preferred_language?: Maybe<String_Comparison_Exp>;
  artwork_currency?: Maybe<String_Comparison_Exp>;
  artwork_link?: Maybe<String_Comparison_Exp>;
  artwork_price?: Maybe<Float8_Comparison_Exp>;
  date?: Maybe<Timestamptz_Comparison_Exp>;
  purchase_inquire_id?: Maybe<Bigint_Comparison_Exp>;
  sender_email?: Maybe<String_Comparison_Exp>;
  sender_link?: Maybe<String_Comparison_Exp>;
  sender_name?: Maybe<String_Comparison_Exp>;
  sender_preferred_language?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Inside_Purchase_Inquiries_View_Max_Fields = {
  __typename?: 'inside_purchase_inquiries_view_max_fields';
  artist_city?: Maybe<Scalars['String']>;
  artist_country?: Maybe<Scalars['String']>;
  artist_email?: Maybe<Scalars['String']>;
  artist_link?: Maybe<Scalars['String']>;
  artist_name?: Maybe<Scalars['String']>;
  artist_preferred_language?: Maybe<Scalars['String']>;
  artwork_currency?: Maybe<Scalars['String']>;
  artwork_link?: Maybe<Scalars['String']>;
  artwork_price?: Maybe<Scalars['float8']>;
  date?: Maybe<Scalars['timestamptz']>;
  purchase_inquire_id?: Maybe<Scalars['bigint']>;
  sender_email?: Maybe<Scalars['String']>;
  sender_link?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_preferred_language?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Inside_Purchase_Inquiries_View_Min_Fields = {
  __typename?: 'inside_purchase_inquiries_view_min_fields';
  artist_city?: Maybe<Scalars['String']>;
  artist_country?: Maybe<Scalars['String']>;
  artist_email?: Maybe<Scalars['String']>;
  artist_link?: Maybe<Scalars['String']>;
  artist_name?: Maybe<Scalars['String']>;
  artist_preferred_language?: Maybe<Scalars['String']>;
  artwork_currency?: Maybe<Scalars['String']>;
  artwork_link?: Maybe<Scalars['String']>;
  artwork_price?: Maybe<Scalars['float8']>;
  date?: Maybe<Scalars['timestamptz']>;
  purchase_inquire_id?: Maybe<Scalars['bigint']>;
  sender_email?: Maybe<Scalars['String']>;
  sender_link?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_preferred_language?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "inside_purchase_inquiries_view". */
export type Inside_Purchase_Inquiries_View_Order_By = {
  artist_city?: Maybe<Order_By>;
  artist_country?: Maybe<Order_By>;
  artist_email?: Maybe<Order_By>;
  artist_link?: Maybe<Order_By>;
  artist_name?: Maybe<Order_By>;
  artist_preferred_language?: Maybe<Order_By>;
  artwork_currency?: Maybe<Order_By>;
  artwork_link?: Maybe<Order_By>;
  artwork_price?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  purchase_inquire_id?: Maybe<Order_By>;
  sender_email?: Maybe<Order_By>;
  sender_link?: Maybe<Order_By>;
  sender_name?: Maybe<Order_By>;
  sender_preferred_language?: Maybe<Order_By>;
};

/** select columns of table "inside_purchase_inquiries_view" */
export enum Inside_Purchase_Inquiries_View_Select_Column {
  /** column name */
  ArtistCity = 'artist_city',
  /** column name */
  ArtistCountry = 'artist_country',
  /** column name */
  ArtistEmail = 'artist_email',
  /** column name */
  ArtistLink = 'artist_link',
  /** column name */
  ArtistName = 'artist_name',
  /** column name */
  ArtistPreferredLanguage = 'artist_preferred_language',
  /** column name */
  ArtworkCurrency = 'artwork_currency',
  /** column name */
  ArtworkLink = 'artwork_link',
  /** column name */
  ArtworkPrice = 'artwork_price',
  /** column name */
  Date = 'date',
  /** column name */
  PurchaseInquireId = 'purchase_inquire_id',
  /** column name */
  SenderEmail = 'sender_email',
  /** column name */
  SenderLink = 'sender_link',
  /** column name */
  SenderName = 'sender_name',
  /** column name */
  SenderPreferredLanguage = 'sender_preferred_language'
}

/** aggregate stddev on columns */
export type Inside_Purchase_Inquiries_View_Stddev_Fields = {
  __typename?: 'inside_purchase_inquiries_view_stddev_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Inside_Purchase_Inquiries_View_Stddev_Pop_Fields = {
  __typename?: 'inside_purchase_inquiries_view_stddev_pop_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Inside_Purchase_Inquiries_View_Stddev_Samp_Fields = {
  __typename?: 'inside_purchase_inquiries_view_stddev_samp_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Inside_Purchase_Inquiries_View_Sum_Fields = {
  __typename?: 'inside_purchase_inquiries_view_sum_fields';
  artwork_price?: Maybe<Scalars['float8']>;
  purchase_inquire_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Inside_Purchase_Inquiries_View_Var_Pop_Fields = {
  __typename?: 'inside_purchase_inquiries_view_var_pop_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Inside_Purchase_Inquiries_View_Var_Samp_Fields = {
  __typename?: 'inside_purchase_inquiries_view_var_samp_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Inside_Purchase_Inquiries_View_Variance_Fields = {
  __typename?: 'inside_purchase_inquiries_view_variance_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};



export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "kaleido_coins_distribution" */
export type Kaleido_Coins_Distribution = {
  __typename?: 'kaleido_coins_distribution';
  /** An object relationship */
  artworks_published_this_week?: Maybe<Count_Artworks_Published_This_Week>;
  /** An object relationship */
  coins_from_card_this_week?: Maybe<Sum_Coins_From_Card_This_Week>;
  /** An object relationship */
  context?: Maybe<Context>;
  context_id?: Maybe<Scalars['bigint']>;
  percentage?: Maybe<Scalars['numeric']>;
  points?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
  total_positions?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  weekly_distribution?: Maybe<Kaleido_Coins_Weekly_Distribution>;
};

/** aggregated selection of "kaleido_coins_distribution" */
export type Kaleido_Coins_Distribution_Aggregate = {
  __typename?: 'kaleido_coins_distribution_aggregate';
  aggregate?: Maybe<Kaleido_Coins_Distribution_Aggregate_Fields>;
  nodes: Array<Kaleido_Coins_Distribution>;
};

/** aggregate fields of "kaleido_coins_distribution" */
export type Kaleido_Coins_Distribution_Aggregate_Fields = {
  __typename?: 'kaleido_coins_distribution_aggregate_fields';
  avg?: Maybe<Kaleido_Coins_Distribution_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Kaleido_Coins_Distribution_Max_Fields>;
  min?: Maybe<Kaleido_Coins_Distribution_Min_Fields>;
  stddev?: Maybe<Kaleido_Coins_Distribution_Stddev_Fields>;
  stddev_pop?: Maybe<Kaleido_Coins_Distribution_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kaleido_Coins_Distribution_Stddev_Samp_Fields>;
  sum?: Maybe<Kaleido_Coins_Distribution_Sum_Fields>;
  var_pop?: Maybe<Kaleido_Coins_Distribution_Var_Pop_Fields>;
  var_samp?: Maybe<Kaleido_Coins_Distribution_Var_Samp_Fields>;
  variance?: Maybe<Kaleido_Coins_Distribution_Variance_Fields>;
};


/** aggregate fields of "kaleido_coins_distribution" */
export type Kaleido_Coins_Distribution_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kaleido_Coins_Distribution_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Kaleido_Coins_Distribution_Avg_Fields = {
  __typename?: 'kaleido_coins_distribution_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "kaleido_coins_distribution". All fields are combined with a logical 'AND'. */
export type Kaleido_Coins_Distribution_Bool_Exp = {
  _and?: Maybe<Array<Kaleido_Coins_Distribution_Bool_Exp>>;
  _not?: Maybe<Kaleido_Coins_Distribution_Bool_Exp>;
  _or?: Maybe<Array<Kaleido_Coins_Distribution_Bool_Exp>>;
  artworks_published_this_week?: Maybe<Count_Artworks_Published_This_Week_Bool_Exp>;
  coins_from_card_this_week?: Maybe<Sum_Coins_From_Card_This_Week_Bool_Exp>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  percentage?: Maybe<Numeric_Comparison_Exp>;
  points?: Maybe<Numeric_Comparison_Exp>;
  position?: Maybe<Bigint_Comparison_Exp>;
  total_points?: Maybe<Numeric_Comparison_Exp>;
  total_positions?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  weekly_distribution?: Maybe<Kaleido_Coins_Weekly_Distribution_Bool_Exp>;
};

/** aggregate max on columns */
export type Kaleido_Coins_Distribution_Max_Fields = {
  __typename?: 'kaleido_coins_distribution_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  percentage?: Maybe<Scalars['numeric']>;
  points?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
  total_positions?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Kaleido_Coins_Distribution_Min_Fields = {
  __typename?: 'kaleido_coins_distribution_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  percentage?: Maybe<Scalars['numeric']>;
  points?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
  total_positions?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "kaleido_coins_distribution". */
export type Kaleido_Coins_Distribution_Order_By = {
  artworks_published_this_week?: Maybe<Count_Artworks_Published_This_Week_Order_By>;
  coins_from_card_this_week?: Maybe<Sum_Coins_From_Card_This_Week_Order_By>;
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
  total_positions?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  weekly_distribution?: Maybe<Kaleido_Coins_Weekly_Distribution_Order_By>;
};

/** select columns of table "kaleido_coins_distribution" */
export enum Kaleido_Coins_Distribution_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Percentage = 'percentage',
  /** column name */
  Points = 'points',
  /** column name */
  Position = 'position',
  /** column name */
  TotalPoints = 'total_points',
  /** column name */
  TotalPositions = 'total_positions',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Kaleido_Coins_Distribution_Stddev_Fields = {
  __typename?: 'kaleido_coins_distribution_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Kaleido_Coins_Distribution_Stddev_Pop_Fields = {
  __typename?: 'kaleido_coins_distribution_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Kaleido_Coins_Distribution_Stddev_Samp_Fields = {
  __typename?: 'kaleido_coins_distribution_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Kaleido_Coins_Distribution_Sum_Fields = {
  __typename?: 'kaleido_coins_distribution_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  percentage?: Maybe<Scalars['numeric']>;
  points?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
  total_positions?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Kaleido_Coins_Distribution_Var_Pop_Fields = {
  __typename?: 'kaleido_coins_distribution_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Kaleido_Coins_Distribution_Var_Samp_Fields = {
  __typename?: 'kaleido_coins_distribution_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Kaleido_Coins_Distribution_Variance_Fields = {
  __typename?: 'kaleido_coins_distribution_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "kaleido_coins_weekly_distribution" */
export type Kaleido_Coins_Weekly_Distribution = {
  __typename?: 'kaleido_coins_weekly_distribution';
  context_id?: Maybe<Scalars['bigint']>;
  percentage?: Maybe<Scalars['numeric']>;
  points?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
  total_positions?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "kaleido_coins_weekly_distribution" */
export type Kaleido_Coins_Weekly_Distribution_Aggregate = {
  __typename?: 'kaleido_coins_weekly_distribution_aggregate';
  aggregate?: Maybe<Kaleido_Coins_Weekly_Distribution_Aggregate_Fields>;
  nodes: Array<Kaleido_Coins_Weekly_Distribution>;
};

/** aggregate fields of "kaleido_coins_weekly_distribution" */
export type Kaleido_Coins_Weekly_Distribution_Aggregate_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_aggregate_fields';
  avg?: Maybe<Kaleido_Coins_Weekly_Distribution_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Kaleido_Coins_Weekly_Distribution_Max_Fields>;
  min?: Maybe<Kaleido_Coins_Weekly_Distribution_Min_Fields>;
  stddev?: Maybe<Kaleido_Coins_Weekly_Distribution_Stddev_Fields>;
  stddev_pop?: Maybe<Kaleido_Coins_Weekly_Distribution_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Kaleido_Coins_Weekly_Distribution_Stddev_Samp_Fields>;
  sum?: Maybe<Kaleido_Coins_Weekly_Distribution_Sum_Fields>;
  var_pop?: Maybe<Kaleido_Coins_Weekly_Distribution_Var_Pop_Fields>;
  var_samp?: Maybe<Kaleido_Coins_Weekly_Distribution_Var_Samp_Fields>;
  variance?: Maybe<Kaleido_Coins_Weekly_Distribution_Variance_Fields>;
};


/** aggregate fields of "kaleido_coins_weekly_distribution" */
export type Kaleido_Coins_Weekly_Distribution_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Kaleido_Coins_Weekly_Distribution_Avg_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "kaleido_coins_weekly_distribution". All fields are combined with a logical 'AND'. */
export type Kaleido_Coins_Weekly_Distribution_Bool_Exp = {
  _and?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Bool_Exp>>;
  _not?: Maybe<Kaleido_Coins_Weekly_Distribution_Bool_Exp>;
  _or?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  percentage?: Maybe<Numeric_Comparison_Exp>;
  points?: Maybe<Numeric_Comparison_Exp>;
  position?: Maybe<Bigint_Comparison_Exp>;
  total_points?: Maybe<Numeric_Comparison_Exp>;
  total_positions?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Kaleido_Coins_Weekly_Distribution_Max_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  percentage?: Maybe<Scalars['numeric']>;
  points?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
  total_positions?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Kaleido_Coins_Weekly_Distribution_Min_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  percentage?: Maybe<Scalars['numeric']>;
  points?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
  total_positions?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "kaleido_coins_weekly_distribution". */
export type Kaleido_Coins_Weekly_Distribution_Order_By = {
  context_id?: Maybe<Order_By>;
  percentage?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  position?: Maybe<Order_By>;
  total_points?: Maybe<Order_By>;
  total_positions?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "kaleido_coins_weekly_distribution" */
export enum Kaleido_Coins_Weekly_Distribution_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Percentage = 'percentage',
  /** column name */
  Points = 'points',
  /** column name */
  Position = 'position',
  /** column name */
  TotalPoints = 'total_points',
  /** column name */
  TotalPositions = 'total_positions',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Kaleido_Coins_Weekly_Distribution_Stddev_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Kaleido_Coins_Weekly_Distribution_Stddev_Pop_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Kaleido_Coins_Weekly_Distribution_Stddev_Samp_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Kaleido_Coins_Weekly_Distribution_Sum_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  percentage?: Maybe<Scalars['numeric']>;
  points?: Maybe<Scalars['numeric']>;
  position?: Maybe<Scalars['bigint']>;
  total_points?: Maybe<Scalars['numeric']>;
  total_positions?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Kaleido_Coins_Weekly_Distribution_Var_Pop_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Kaleido_Coins_Weekly_Distribution_Var_Samp_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Kaleido_Coins_Weekly_Distribution_Variance_Fields = {
  __typename?: 'kaleido_coins_weekly_distribution_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  points?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
  total_points?: Maybe<Scalars['Float']>;
  total_positions?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  activateArtHistoryArtUniverse?: Maybe<Scalars['Boolean']>;
  activateMyFCMToken?: Maybe<Scalars['Int']>;
  addArtistCuratorScore?: Maybe<Scalars['Boolean']>;
  addArtworkCuratorScore?: Maybe<Scalars['Boolean']>;
  addArtworkToCart: Array<ShoppingCartItemType>;
  /** Inserts an artwork to an existing collection. */
  addArtworkToCollection?: Maybe<Scalars['Int']>;
  /**
   * Uploads an art prize background image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  artPrizeBackgroundUpload?: Maybe<UploadedFileResponse>;
  /**
   * Uploads an art prize thumbnail image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  artPrizeThumbnailUpload?: Maybe<UploadedFileResponse>;
  artUniverseAcctSetup?: Maybe<Scalars['String']>;
  /** - Updates artist collaboration to Accepted status */
  artistAcceptInvitation: Scalars['Int'];
  /**
   * Inserts the new artwork into Artworks table and associates it to the artist OWNER and CREATED collections
   *
   * ***Constraints:***
   *
   *   \- Only an artist or gallery to which he / she belongs can create an artwork for his /her profile
   */
  artistCreateArtwork?: Maybe<CreateArtworkResult>;
  artistHandleInvitation: Scalars['Int'];
  artistInviteGalleryCollaboration: InvitationResultType;
  /** - Updates artist collaboration to Rejected status */
  artistRejectInvitation: Scalars['Int'];
  /** Removes artwork from gallery, gallery collection and collaboration invitations. */
  artistRemovePartnerOnArtwork?: Maybe<Scalars['Boolean']>;
  /**
   * Removes previous gallery from artwork and removes artwork from previous gallery collection.
   *
   * Updates the artwork to the new gallery and collection.
   *
   * ***Constraints:***
   *
   *   \- If context id is owner or creator.
   *
   *   \- Only collaborating contexts.
   */
  artistSetPartnerOnArtwork?: Maybe<Scalars['Boolean']>;
  /**
   * Inserts or updates an Edition for an existing UNIQUE artwork.
   *
   * ***Constraints:***
   *
   *   \- If it has edition_id, it's an update
   *
   *   \- Title | artwork_id are required when inserting
   *
   *   \- Can't set deleted_at if the edition has transferring | transferred | sold prints
   */
  artistUpsertEdition?: Maybe<CreateEditionResult>;
  /**
   * Inserts or updates an Edition by Size for an existing edition.
   *
   * ***Constraints:***
   *
   *   \- If edition_id and print size exists, it's an update
   *
   *   \- edition_id and print_size are required
   *
   *   \- print_size is unique for the edition_id
   */
  artistUpsertEditionBySize?: Maybe<CreateEditionSizeResult>;
  /**
   * Inserts or updates an Edition by Type for an existing edition by size.
   *
   * ***Constraints:***
   *
   *   \- If edition_by_type_id exists, it's an update
   *
   *   \- If edition_by_size_id and edition_type_id exist, it's an update
   *
   *   \- If the edition published_date is null, edition by type is in draft mode
   *
   *   \- If it is not in draft mode, it can remove only prints that are available when changing the print_amount
   *
   *   \- If it is in draft mode, it can remove all prints (no restrictions) when changing the print_amount
   */
  artistUpsertEditionByType?: Maybe<CreateEditionTypeResult>;
  /**
   * Inserts or updates a print.
   *
   * ***Constraints:***
   *
   *   \- If print_number is set, it forces the number
   *
   *   \- If print_number is empty, it creates the next print number available
   *
   *   \- If the print_id or print_number exists for the edition by type, it's an update
   *
   *   \- If the print_price is set at the Edition by Size level, it is considered as fixed price, Edition by Size price overrides the print level price
   *
   *   \- Every update to the print is possible if this print is not in transferring mode
   */
  artistUpsertPrint?: Maybe<CreatePrintTypeResult>;
  /** Soft delete audio for artwork in the database */
  artworkAudioDelete?: Maybe<Scalars['Boolean']>;
  /**
   * Uploads an artwork location map image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  artworkLocationMapUpload?: Maybe<UploadedFileResponse>;
  /**
   * Uploads an original artwork image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  artworkOriginalImageUpload?: Maybe<UploadedFileResponse>;
  /**
   * Uploads a primary image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  artworkPrimaryImageUpload?: Maybe<UploadedFileResponse>;
  /** Soft delete a secondary image in the database */
  artworkSecondaryImageDelete?: Maybe<Scalars['Boolean']>;
  /**
   * Uploads an secondary image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  artworkSecondaryImageUpload?: Maybe<UploadedFileResponse>;
  artworkStoryAudioConvert?: Maybe<Scalars['Boolean']>;
  /** Uploads a story audio to S3 and saves metadata info in file_metadata. */
  artworkStoryAudioUpload?: Maybe<UploadedFileResponse>;
  /** Soft delete a story image in the database */
  artworkStoryImageDelete?: Maybe<Scalars['Boolean']>;
  /**
   * Uploads an artwork process/story image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  artworkStoryImageUpload?: Maybe<UploadedFileResponse>;
  /**
   * Uploads a thumbnail image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  artworkThumbnailUpload?: Maybe<UploadedFileResponse>;
  /**
   * Updates a transaction to CANCELED status and sets the artwork lock to false.
   *
   * ***Constraints:***
   *
   *   \- Only transactions with INITIALIZED and PENDING  status
   */
  cancelArtworkTransfer?: Maybe<Scalars['Boolean']>;
  /**
   * Uploads an certificate image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  certificateVumarkUpload?: Maybe<CertificateUploadResult>;
  checkIfValidationTokenIsValid: CheckIfValidationTokenIsValidResponse;
  checkoutDomain: CheckoutDomainResult;
  checkoutMyStudio: CheckoutMyStudioResult;
  checkoutSuperSite: CheckoutSuperSiteResult;
  closeAccount?: Maybe<Scalars['Boolean']>;
  /**
   * - Inserts artist or gallery collaboration
   *
   * ***Constraints:***:
   *
   *   \- Code should exist for that invitation
   */
  collaborationInviteWithProfileCode: Scalars['Boolean'];
  /** Soft delete audio for collections in the database */
  collectionAudioDelete?: Maybe<Scalars['Boolean']>;
  /**
   * Uploads a collection audio to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  collectionAudioUpload?: Maybe<UploadedFileResponse>;
  completeEmailVerification: CompleteEmailVerificationResponse;
  completeFirebaseCustomAuth: CompletePseudoAuthResponse;
  confirmArtworkAvailability?: Maybe<Scalars['Boolean']>;
  contactArtist?: Maybe<Scalars['Boolean']>;
  /** Updates an audio copying it from it serie to a an artwork story */
  copyAudioFromSeriesToStory?: Maybe<Scalars['Boolean']>;
  createArtistGhostProfile?: Maybe<Scalars['Boolean']>;
  /**
   * Inserts a new collection.
   *
   * If no partnership, it creates the new partnership.
   *
   * ***Constraints:***
   *
   *   \- No accepted OWNED, CREATED and MANAGED collections
   *
   *   \- If Artist, context id should be owner
   *   \- If Gallery, artist and gallery should be collaborating
   */
  createCollection?: Maybe<Scalars['Int']>;
  createGalleryGhostProfile?: Maybe<Scalars['Boolean']>;
  /**
   * Creates a Showroom collection for an artist and sets its own slug.
   *
   * ***Constraints:***
   *
   *   \- Only artist context.
   */
  createShowroom?: Maybe<CreateCollectionResult>;
  /** Transfer the funds from the payment intent to the seller */
  createTransferToSeller: Scalars['Boolean'];
  deactivateArtHistoryArtUniverse?: Maybe<Scalars['Boolean']>;
  deactivateMyFCMToken?: Maybe<Scalars['Int']>;
  /** Sets deleted_at to the art prize show */
  deleteArtPrizeShow?: Maybe<Scalars['bigint']>;
  /** Sets deleted_at to the art prize winners */
  deleteArtPrizeWinner?: Maybe<Scalars['bigint']>;
  /** - Deletes content translation for given entity_id and content_type and secondary_entity_id */
  deleteContentTranslation?: Maybe<Scalars['Boolean']>;
  /** - Deletes a phone number for a profile */
  deleteProfilePhoneNumber?: Maybe<Scalars['Boolean']>;
  /**
   * Deletes a SERIES collection
   *
   * ***Constraints:***
   *
   *   \- Context must have permission over the serie.
   */
  deleteSeries?: Maybe<Scalars['Int']>;
  /**
   * Sets deletead_at to the Showroom collection.
   *
   * Sets the showroom reader delegates to state Deleted.
   *
   * ***Constraints:***
   *
   *   \- Only owner of the collection.
   */
  deleteShowroom?: Maybe<Scalars['Boolean']>;
  /** delete data from the table: "Account_initial_profile_type" */
  delete_Account_initial_profile_type?: Maybe<Account_Initial_Profile_Type_Mutation_Response>;
  /** delete single row from the table: "Account_initial_profile_type" */
  delete_Account_initial_profile_type_by_pk?: Maybe<Account_Initial_Profile_Type>;
  /** delete data from the table: "Account_notice_ack" */
  delete_Account_notice_ack?: Maybe<Account_Notice_Ack_Mutation_Response>;
  /** delete single row from the table: "Account_notice_ack" */
  delete_Account_notice_ack_by_pk?: Maybe<Account_Notice_Ack>;
  /** delete data from the table: "Account_validation" */
  delete_Account_validation?: Maybe<Account_Validation_Mutation_Response>;
  /** delete single row from the table: "Account_validation" */
  delete_Account_validation_by_pk?: Maybe<Account_Validation>;
  /** delete data from the table: "Achievement_hooks" */
  delete_Achievement_hooks?: Maybe<Achievement_Hooks_Mutation_Response>;
  /** delete single row from the table: "Achievement_hooks" */
  delete_Achievement_hooks_by_pk?: Maybe<Achievement_Hooks>;
  /** delete data from the table: "Achievement_tags" */
  delete_Achievement_tags?: Maybe<Achievement_Tags_Mutation_Response>;
  /** delete single row from the table: "Achievement_tags" */
  delete_Achievement_tags_by_pk?: Maybe<Achievement_Tags>;
  /** delete data from the table: "Achievements" */
  delete_Achievements?: Maybe<Achievements_Mutation_Response>;
  /** delete single row from the table: "Achievements" */
  delete_Achievements_by_pk?: Maybe<Achievements>;
  /** delete data from the table: "Admin_password" */
  delete_Admin_password?: Maybe<Admin_Password_Mutation_Response>;
  /** delete single row from the table: "Admin_password" */
  delete_Admin_password_by_pk?: Maybe<Admin_Password>;
  /** delete data from the table: "Admin_to_user_notification" */
  delete_Admin_to_user_notification?: Maybe<Admin_To_User_Notification_Mutation_Response>;
  /** delete single row from the table: "Admin_to_user_notification" */
  delete_Admin_to_user_notification_by_pk?: Maybe<Admin_To_User_Notification>;
  /** delete data from the table: "All_artwork_availability_table" */
  delete_All_artwork_availability_table?: Maybe<All_Artwork_Availability_Table_Mutation_Response>;
  /** delete single row from the table: "All_artwork_availability_table" */
  delete_All_artwork_availability_table_by_pk?: Maybe<All_Artwork_Availability_Table>;
  /** delete data from the table: "Analytics_events" */
  delete_Analytics_events?: Maybe<Analytics_Events_Mutation_Response>;
  /** delete single row from the table: "Analytics_events" */
  delete_Analytics_events_by_pk?: Maybe<Analytics_Events>;
  /** delete data from the table: "Analytics_index_history" */
  delete_Analytics_index_history?: Maybe<Analytics_Index_History_Mutation_Response>;
  /** delete single row from the table: "Analytics_index_history" */
  delete_Analytics_index_history_by_pk?: Maybe<Analytics_Index_History>;
  /** delete data from the table: "Analytics_index_type" */
  delete_Analytics_index_type?: Maybe<Analytics_Index_Type_Mutation_Response>;
  /** delete single row from the table: "Analytics_index_type" */
  delete_Analytics_index_type_by_pk?: Maybe<Analytics_Index_Type>;
  /** delete data from the table: "Analytics_index_values" */
  delete_Analytics_index_values?: Maybe<Analytics_Index_Values_Mutation_Response>;
  /** delete single row from the table: "Analytics_index_values" */
  delete_Analytics_index_values_by_pk?: Maybe<Analytics_Index_Values>;
  /** delete data from the table: "Analytics_kaleidoCard_Amounts" */
  delete_Analytics_kaleidoCard_Amounts?: Maybe<Analytics_KaleidoCard_Amounts_Mutation_Response>;
  /** delete single row from the table: "Analytics_kaleidoCard_Amounts" */
  delete_Analytics_kaleidoCard_Amounts_by_pk?: Maybe<Analytics_KaleidoCard_Amounts>;
  /** delete data from the table: "Analytics_kaleidoCard_events" */
  delete_Analytics_kaleidoCard_events?: Maybe<Analytics_KaleidoCard_Events_Mutation_Response>;
  /** delete single row from the table: "Analytics_kaleidoCard_events" */
  delete_Analytics_kaleidoCard_events_by_pk?: Maybe<Analytics_KaleidoCard_Events>;
  /** delete data from the table: "App_notifications" */
  delete_App_notifications?: Maybe<App_Notifications_Mutation_Response>;
  /** delete single row from the table: "App_notifications" */
  delete_App_notifications_by_pk?: Maybe<App_Notifications>;
  /** delete data from the table: "Art_history_movement" */
  delete_Art_history_movement?: Maybe<Art_History_Movement_Mutation_Response>;
  /** delete single row from the table: "Art_history_movement" */
  delete_Art_history_movement_by_pk?: Maybe<Art_History_Movement>;
  /** delete data from the table: "Art_prize_artwork" */
  delete_Art_prize_artwork?: Maybe<Art_Prize_Artwork_Mutation_Response>;
  /** delete single row from the table: "Art_prize_artwork" */
  delete_Art_prize_artwork_by_pk?: Maybe<Art_Prize_Artwork>;
  /** delete data from the table: "Art_prize_show" */
  delete_Art_prize_show?: Maybe<Art_Prize_Show_Mutation_Response>;
  /** delete single row from the table: "Art_prize_show" */
  delete_Art_prize_show_by_pk?: Maybe<Art_Prize_Show>;
  /** delete data from the table: "Art_prize_show_winners" */
  delete_Art_prize_show_winners?: Maybe<Art_Prize_Show_Winners_Mutation_Response>;
  /** delete single row from the table: "Art_prize_show_winners" */
  delete_Art_prize_show_winners_by_pk?: Maybe<Art_Prize_Show_Winners>;
  /** delete data from the table: "Art_prize_winner" */
  delete_Art_prize_winner?: Maybe<Art_Prize_Winner_Mutation_Response>;
  /** delete single row from the table: "Art_prize_winner" */
  delete_Art_prize_winner_by_pk?: Maybe<Art_Prize_Winner>;
  /** delete data from the table: "Art_prizes_links_filtered" */
  delete_Art_prizes_links_filtered?: Maybe<Art_Prizes_Links_Filtered_Mutation_Response>;
  /** delete data from the table: "Art_show_details" */
  delete_Art_show_details?: Maybe<Art_Show_Details_Mutation_Response>;
  /** delete single row from the table: "Art_show_details" */
  delete_Art_show_details_by_pk?: Maybe<Art_Show_Details>;
  /** delete data from the table: "Art_type_to_Artwork_discipline" */
  delete_Art_type_to_Artwork_discipline?: Maybe<Art_Type_To_Artwork_Discipline_Mutation_Response>;
  /** delete single row from the table: "Art_type_to_Artwork_discipline" */
  delete_Art_type_to_Artwork_discipline_by_pk?: Maybe<Art_Type_To_Artwork_Discipline>;
  /** delete data from the table: "Artist_Collection" */
  delete_Artist_Collection?: Maybe<Artist_Collection_Mutation_Response>;
  /** delete data from the table: "Artist_Collection_Categories" */
  delete_Artist_Collection_Categories?: Maybe<Artist_Collection_Categories_Mutation_Response>;
  /** delete single row from the table: "Artist_Collection_Categories" */
  delete_Artist_Collection_Categories_by_pk?: Maybe<Artist_Collection_Categories>;
  /** delete single row from the table: "Artist_Collection" */
  delete_Artist_Collection_by_pk?: Maybe<Artist_Collection>;
  /** delete data from the table: "Artist_Collection_to_Artist_Context" */
  delete_Artist_Collection_to_Artist_Context?: Maybe<Artist_Collection_To_Artist_Context_Mutation_Response>;
  /** delete single row from the table: "Artist_Collection_to_Artist_Context" */
  delete_Artist_Collection_to_Artist_Context_by_pk?: Maybe<Artist_Collection_To_Artist_Context>;
  /** delete data from the table: "Artist_art_history_visibility" */
  delete_Artist_art_history_visibility?: Maybe<Artist_Art_History_Visibility_Mutation_Response>;
  /** delete single row from the table: "Artist_art_history_visibility" */
  delete_Artist_art_history_visibility_by_pk?: Maybe<Artist_Art_History_Visibility>;
  /** delete data from the table: "Artist_curation_score" */
  delete_Artist_curation_score?: Maybe<Artist_Curation_Score_Mutation_Response>;
  /** delete single row from the table: "Artist_curation_score" */
  delete_Artist_curation_score_by_pk?: Maybe<Artist_Curation_Score>;
  /** delete data from the table: "Artist_explore_visibility" */
  delete_Artist_explore_visibility?: Maybe<Artist_Explore_Visibility_Mutation_Response>;
  /** delete single row from the table: "Artist_explore_visibility" */
  delete_Artist_explore_visibility_by_pk?: Maybe<Artist_Explore_Visibility>;
  /** delete data from the table: "Artist_links_filtered" */
  delete_Artist_links_filtered?: Maybe<Artist_Links_Filtered_Mutation_Response>;
  /** delete data from the table: "Artist_newsletter" */
  delete_Artist_newsletter?: Maybe<Artist_Newsletter_Mutation_Response>;
  /** delete single row from the table: "Artist_newsletter" */
  delete_Artist_newsletter_by_pk?: Maybe<Artist_Newsletter>;
  /** delete data from the table: "Artist_vision_visibility" */
  delete_Artist_vision_visibility?: Maybe<Artist_Vision_Visibility_Mutation_Response>;
  /** delete single row from the table: "Artist_vision_visibility" */
  delete_Artist_vision_visibility_by_pk?: Maybe<Artist_Vision_Visibility>;
  /** delete data from the table: "Artwork" */
  delete_Artwork?: Maybe<Artwork_Mutation_Response>;
  /** delete data from the table: "Artwork_ai_tags" */
  delete_Artwork_ai_tags?: Maybe<Artwork_Ai_Tags_Mutation_Response>;
  /** delete single row from the table: "Artwork_ai_tags" */
  delete_Artwork_ai_tags_by_pk?: Maybe<Artwork_Ai_Tags>;
  /** delete data from the table: "Artwork_art_history_timetable" */
  delete_Artwork_art_history_timetable?: Maybe<Artwork_Art_History_Timetable_Mutation_Response>;
  /** delete single row from the table: "Artwork_art_history_timetable" */
  delete_Artwork_art_history_timetable_by_pk?: Maybe<Artwork_Art_History_Timetable>;
  /** delete data from the table: "Artwork_aspect_ratio" */
  delete_Artwork_aspect_ratio?: Maybe<Artwork_Aspect_Ratio_Mutation_Response>;
  /** delete single row from the table: "Artwork_aspect_ratio" */
  delete_Artwork_aspect_ratio_by_pk?: Maybe<Artwork_Aspect_Ratio>;
  /** delete single row from the table: "Artwork" */
  delete_Artwork_by_pk?: Maybe<Artwork>;
  /** delete data from the table: "Artwork_count_milestones" */
  delete_Artwork_count_milestones?: Maybe<Artwork_Count_Milestones_Mutation_Response>;
  /** delete single row from the table: "Artwork_count_milestones" */
  delete_Artwork_count_milestones_by_pk?: Maybe<Artwork_Count_Milestones>;
  /** delete data from the table: "Artwork_curation_score" */
  delete_Artwork_curation_score?: Maybe<Artwork_Curation_Score_Mutation_Response>;
  /** delete single row from the table: "Artwork_curation_score" */
  delete_Artwork_curation_score_by_pk?: Maybe<Artwork_Curation_Score>;
  /** delete data from the table: "Artwork_details" */
  delete_Artwork_details?: Maybe<Artwork_Details_Mutation_Response>;
  /** delete single row from the table: "Artwork_details" */
  delete_Artwork_details_by_pk?: Maybe<Artwork_Details>;
  /** delete data from the table: "Artwork_details_translation" */
  delete_Artwork_details_translation?: Maybe<Artwork_Details_Translation_Mutation_Response>;
  /** delete single row from the table: "Artwork_details_translation" */
  delete_Artwork_details_translation_by_pk?: Maybe<Artwork_Details_Translation>;
  /** delete data from the table: "Artwork_digital_twin" */
  delete_Artwork_digital_twin?: Maybe<Artwork_Digital_Twin_Mutation_Response>;
  /** delete single row from the table: "Artwork_digital_twin" */
  delete_Artwork_digital_twin_by_pk?: Maybe<Artwork_Digital_Twin>;
  /** delete data from the table: "Artwork_events" */
  delete_Artwork_events?: Maybe<Artwork_Events_Mutation_Response>;
  /** delete single row from the table: "Artwork_events" */
  delete_Artwork_events_by_pk?: Maybe<Artwork_Events>;
  /** delete data from the table: "Artwork_external_buy_link" */
  delete_Artwork_external_buy_link?: Maybe<Artwork_External_Buy_Link_Mutation_Response>;
  /** delete single row from the table: "Artwork_external_buy_link" */
  delete_Artwork_external_buy_link_by_pk?: Maybe<Artwork_External_Buy_Link>;
  /** delete data from the table: "Artwork_prices" */
  delete_Artwork_prices?: Maybe<Artwork_Prices_Mutation_Response>;
  /** delete single row from the table: "Artwork_prices" */
  delete_Artwork_prices_by_pk?: Maybe<Artwork_Prices>;
  /** delete data from the table: "Artwork_purchase_journey" */
  delete_Artwork_purchase_journey?: Maybe<Artwork_Purchase_Journey_Mutation_Response>;
  /** delete single row from the table: "Artwork_purchase_journey" */
  delete_Artwork_purchase_journey_by_pk?: Maybe<Artwork_Purchase_Journey>;
  /** delete data from the table: "Artwork_purchase_journey_history" */
  delete_Artwork_purchase_journey_history?: Maybe<Artwork_Purchase_Journey_History_Mutation_Response>;
  /** delete single row from the table: "Artwork_purchase_journey_history" */
  delete_Artwork_purchase_journey_history_by_pk?: Maybe<Artwork_Purchase_Journey_History>;
  /** delete data from the table: "Artwork_purchase_journey_last_update" */
  delete_Artwork_purchase_journey_last_update?: Maybe<Artwork_Purchase_Journey_Last_Update_Mutation_Response>;
  /** delete single row from the table: "Artwork_purchase_journey_last_update" */
  delete_Artwork_purchase_journey_last_update_by_pk?: Maybe<Artwork_Purchase_Journey_Last_Update>;
  /** delete data from the table: "Artwork_purchase_journey_pricing" */
  delete_Artwork_purchase_journey_pricing?: Maybe<Artwork_Purchase_Journey_Pricing_Mutation_Response>;
  /** delete single row from the table: "Artwork_purchase_journey_pricing" */
  delete_Artwork_purchase_journey_pricing_by_pk?: Maybe<Artwork_Purchase_Journey_Pricing>;
  /** delete data from the table: "Artwork_purchase_journey_shipping_info" */
  delete_Artwork_purchase_journey_shipping_info?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Mutation_Response>;
  /** delete single row from the table: "Artwork_purchase_journey_shipping_info" */
  delete_Artwork_purchase_journey_shipping_info_by_pk?: Maybe<Artwork_Purchase_Journey_Shipping_Info>;
  /** delete data from the table: "Artwork_secondary_media" */
  delete_Artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Mutation_Response>;
  /** delete single row from the table: "Artwork_secondary_media" */
  delete_Artwork_secondary_media_by_pk?: Maybe<Artwork_Secondary_Media>;
  /** delete data from the table: "Artwork_state_history" */
  delete_Artwork_state_history?: Maybe<Artwork_State_History_Mutation_Response>;
  /** delete single row from the table: "Artwork_state_history" */
  delete_Artwork_state_history_by_pk?: Maybe<Artwork_State_History>;
  /** delete data from the table: "Artwork_story" */
  delete_Artwork_story?: Maybe<Artwork_Story_Mutation_Response>;
  /** delete single row from the table: "Artwork_story" */
  delete_Artwork_story_by_pk?: Maybe<Artwork_Story>;
  /** delete data from the table: "Artwork_story_media" */
  delete_Artwork_story_media?: Maybe<Artwork_Story_Media_Mutation_Response>;
  /** delete single row from the table: "Artwork_story_media" */
  delete_Artwork_story_media_by_pk?: Maybe<Artwork_Story_Media>;
  /** delete data from the table: "Artwork_story_video_links" */
  delete_Artwork_story_video_links?: Maybe<Artwork_Story_Video_Links_Mutation_Response>;
  /** delete single row from the table: "Artwork_story_video_links" */
  delete_Artwork_story_video_links_by_pk?: Maybe<Artwork_Story_Video_Links>;
  /** delete data from the table: "Artwork_tag" */
  delete_Artwork_tag?: Maybe<Artwork_Tag_Mutation_Response>;
  /** delete single row from the table: "Artwork_tag" */
  delete_Artwork_tag_by_pk?: Maybe<Artwork_Tag>;
  /** delete data from the table: "Artwork_to_Collection" */
  delete_Artwork_to_Collection?: Maybe<Artwork_To_Collection_Mutation_Response>;
  /** delete single row from the table: "Artwork_to_Collection" */
  delete_Artwork_to_Collection_by_pk?: Maybe<Artwork_To_Collection>;
  /** delete data from the table: "Artwork_to_showroom_details" */
  delete_Artwork_to_showroom_details?: Maybe<Artwork_To_Showroom_Details_Mutation_Response>;
  /** delete single row from the table: "Artwork_to_showroom_details" */
  delete_Artwork_to_showroom_details_by_pk?: Maybe<Artwork_To_Showroom_Details>;
  /** delete data from the table: "Artwork_to_tag" */
  delete_Artwork_to_tag?: Maybe<Artwork_To_Tag_Mutation_Response>;
  /** delete single row from the table: "Artwork_to_tag" */
  delete_Artwork_to_tag_by_pk?: Maybe<Artwork_To_Tag>;
  /** delete data from the table: "Artwork_transactions" */
  delete_Artwork_transactions?: Maybe<Artwork_Transactions_Mutation_Response>;
  /** delete single row from the table: "Artwork_transactions" */
  delete_Artwork_transactions_by_pk?: Maybe<Artwork_Transactions>;
  /** delete data from the table: "Auth" */
  delete_Auth?: Maybe<Auth_Mutation_Response>;
  /** delete single row from the table: "Auth" */
  delete_Auth_by_pk?: Maybe<Auth>;
  /** delete data from the table: "Auth_context_access" */
  delete_Auth_context_access?: Maybe<Auth_Context_Access_Mutation_Response>;
  /** delete single row from the table: "Auth_context_access" */
  delete_Auth_context_access_by_pk?: Maybe<Auth_Context_Access>;
  /** delete data from the table: "Available_print_price" */
  delete_Available_print_price?: Maybe<Available_Print_Price_Mutation_Response>;
  /** delete single row from the table: "Available_print_price" */
  delete_Available_print_price_by_pk?: Maybe<Available_Print_Price>;
  /** delete data from the table: "Billing_anon_client" */
  delete_Billing_anon_client?: Maybe<Billing_Anon_Client_Mutation_Response>;
  /** delete single row from the table: "Billing_anon_client" */
  delete_Billing_anon_client_by_pk?: Maybe<Billing_Anon_Client>;
  /** delete data from the table: "Billing_artwork_payment_intent" */
  delete_Billing_artwork_payment_intent?: Maybe<Billing_Artwork_Payment_Intent_Mutation_Response>;
  /** delete single row from the table: "Billing_artwork_payment_intent" */
  delete_Billing_artwork_payment_intent_by_pk?: Maybe<Billing_Artwork_Payment_Intent>;
  /** delete data from the table: "Billing_artwork_payment_intent_product" */
  delete_Billing_artwork_payment_intent_product?: Maybe<Billing_Artwork_Payment_Intent_Product_Mutation_Response>;
  /** delete single row from the table: "Billing_artwork_payment_intent_product" */
  delete_Billing_artwork_payment_intent_product_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Product>;
  /** delete data from the table: "Billing_artwork_payment_intent_shipping_address" */
  delete_Billing_artwork_payment_intent_shipping_address?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Mutation_Response>;
  /** delete single row from the table: "Billing_artwork_payment_intent_shipping_address" */
  delete_Billing_artwork_payment_intent_shipping_address_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address>;
  /** delete data from the table: "Billing_artwork_payment_intent_status_history" */
  delete_Billing_artwork_payment_intent_status_history?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Mutation_Response>;
  /** delete single row from the table: "Billing_artwork_payment_intent_status_history" */
  delete_Billing_artwork_payment_intent_status_history_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Status_History>;
  /** delete data from the table: "Billing_client" */
  delete_Billing_client?: Maybe<Billing_Client_Mutation_Response>;
  /** delete single row from the table: "Billing_client" */
  delete_Billing_client_by_pk?: Maybe<Billing_Client>;
  /** delete data from the table: "Billing_seller" */
  delete_Billing_seller?: Maybe<Billing_Seller_Mutation_Response>;
  /** delete single row from the table: "Billing_seller" */
  delete_Billing_seller_by_pk?: Maybe<Billing_Seller>;
  /** delete data from the table: "Billing_subscription" */
  delete_Billing_subscription?: Maybe<Billing_Subscription_Mutation_Response>;
  /** delete single row from the table: "Billing_subscription" */
  delete_Billing_subscription_by_pk?: Maybe<Billing_Subscription>;
  /** delete data from the table: "Billing_subscription_product" */
  delete_Billing_subscription_product?: Maybe<Billing_Subscription_Product_Mutation_Response>;
  /** delete single row from the table: "Billing_subscription_product" */
  delete_Billing_subscription_product_by_pk?: Maybe<Billing_Subscription_Product>;
  /** delete data from the table: "Biolink_sections" */
  delete_Biolink_sections?: Maybe<Biolink_Sections_Mutation_Response>;
  /** delete single row from the table: "Biolink_sections" */
  delete_Biolink_sections_by_pk?: Maybe<Biolink_Sections>;
  /** delete data from the table: "Certificate_of_authenticity" */
  delete_Certificate_of_authenticity?: Maybe<Certificate_Of_Authenticity_Mutation_Response>;
  /** delete single row from the table: "Certificate_of_authenticity" */
  delete_Certificate_of_authenticity_by_pk?: Maybe<Certificate_Of_Authenticity>;
  /** delete data from the table: "Collaboration" */
  delete_Collaboration?: Maybe<Collaboration_Mutation_Response>;
  /** delete single row from the table: "Collaboration" */
  delete_Collaboration_by_pk?: Maybe<Collaboration>;
  /** delete data from the table: "Collaboration_invitation_on_artwork" */
  delete_Collaboration_invitation_on_artwork?: Maybe<Collaboration_Invitation_On_Artwork_Mutation_Response>;
  /** delete single row from the table: "Collaboration_invitation_on_artwork" */
  delete_Collaboration_invitation_on_artwork_by_pk?: Maybe<Collaboration_Invitation_On_Artwork>;
  /** delete data from the table: "Collection" */
  delete_Collection?: Maybe<Collection_Mutation_Response>;
  /** delete single row from the table: "Collection" */
  delete_Collection_by_pk?: Maybe<Collection>;
  /** delete data from the table: "Collection_translation" */
  delete_Collection_translation?: Maybe<Collection_Translation_Mutation_Response>;
  /** delete single row from the table: "Collection_translation" */
  delete_Collection_translation_by_pk?: Maybe<Collection_Translation>;
  /** delete data from the table: "Collection_video_links" */
  delete_Collection_video_links?: Maybe<Collection_Video_Links_Mutation_Response>;
  /** delete single row from the table: "Collection_video_links" */
  delete_Collection_video_links_by_pk?: Maybe<Collection_Video_Links>;
  /** delete data from the table: "Collections_links_filtered" */
  delete_Collections_links_filtered?: Maybe<Collections_Links_Filtered_Mutation_Response>;
  /** delete data from the table: "Collector_links_filtered" */
  delete_Collector_links_filtered?: Maybe<Collector_Links_Filtered_Mutation_Response>;
  /** delete data from the table: "Connected_domain" */
  delete_Connected_domain?: Maybe<Connected_Domain_Mutation_Response>;
  /** delete single row from the table: "Connected_domain" */
  delete_Connected_domain_by_pk?: Maybe<Connected_Domain>;
  /** delete data from the table: "Content_translations" */
  delete_Content_translations?: Maybe<Content_Translations_Mutation_Response>;
  /** delete data from the table: "Context" */
  delete_Context?: Maybe<Context_Mutation_Response>;
  /** delete single row from the table: "Context" */
  delete_Context_by_pk?: Maybe<Context>;
  /** delete data from the table: "Context_delegate_access" */
  delete_Context_delegate_access?: Maybe<Context_Delegate_Access_Mutation_Response>;
  /** delete single row from the table: "Context_delegate_access" */
  delete_Context_delegate_access_by_pk?: Maybe<Context_Delegate_Access>;
  /** delete data from the table: "Context_flags" */
  delete_Context_flags?: Maybe<Context_Flags_Mutation_Response>;
  /** delete single row from the table: "Context_flags" */
  delete_Context_flags_by_pk?: Maybe<Context_Flags>;
  /** delete data from the table: "Context_suggested_tags" */
  delete_Context_suggested_tags?: Maybe<Context_Suggested_Tags_Mutation_Response>;
  /** delete single row from the table: "Context_suggested_tags" */
  delete_Context_suggested_tags_by_pk?: Maybe<Context_Suggested_Tags>;
  /** delete data from the table: "Context_to_milestone" */
  delete_Context_to_milestone?: Maybe<Context_To_Milestone_Mutation_Response>;
  /** delete single row from the table: "Context_to_milestone" */
  delete_Context_to_milestone_by_pk?: Maybe<Context_To_Milestone>;
  /** delete data from the table: "Context_to_tutorial" */
  delete_Context_to_tutorial?: Maybe<Context_To_Tutorial_Mutation_Response>;
  /** delete single row from the table: "Context_to_tutorial" */
  delete_Context_to_tutorial_by_pk?: Maybe<Context_To_Tutorial>;
  /** delete data from the table: "Countries" */
  delete_Countries?: Maybe<Countries_Mutation_Response>;
  /** delete single row from the table: "Countries" */
  delete_Countries_by_pk?: Maybe<Countries>;
  /** delete data from the table: "Crop_Area" */
  delete_Crop_Area?: Maybe<Crop_Area_Mutation_Response>;
  /** delete single row from the table: "Crop_Area" */
  delete_Crop_Area_by_pk?: Maybe<Crop_Area>;
  /** delete data from the table: "Currency_conversion" */
  delete_Currency_conversion?: Maybe<Currency_Conversion_Mutation_Response>;
  /** delete single row from the table: "Currency_conversion" */
  delete_Currency_conversion_by_pk?: Maybe<Currency_Conversion>;
  /** delete data from the table: "Digital_twin_details" */
  delete_Digital_twin_details?: Maybe<Digital_Twin_Details_Mutation_Response>;
  /** delete single row from the table: "Digital_twin_details" */
  delete_Digital_twin_details_by_pk?: Maybe<Digital_Twin_Details>;
  /** delete data from the table: "Discount_code" */
  delete_Discount_code?: Maybe<Discount_Code_Mutation_Response>;
  /** delete single row from the table: "Discount_code" */
  delete_Discount_code_by_pk?: Maybe<Discount_Code>;
  /** delete data from the table: "Discount_code_usage" */
  delete_Discount_code_usage?: Maybe<Discount_Code_Usage_Mutation_Response>;
  /** delete single row from the table: "Discount_code_usage" */
  delete_Discount_code_usage_by_pk?: Maybe<Discount_Code_Usage>;
  /** delete data from the table: "Domain_registrant_info" */
  delete_Domain_registrant_info?: Maybe<Domain_Registrant_Info_Mutation_Response>;
  /** delete single row from the table: "Domain_registrant_info" */
  delete_Domain_registrant_info_by_pk?: Maybe<Domain_Registrant_Info>;
  /** delete data from the table: "Domain_registration" */
  delete_Domain_registration?: Maybe<Domain_Registration_Mutation_Response>;
  /** delete single row from the table: "Domain_registration" */
  delete_Domain_registration_by_pk?: Maybe<Domain_Registration>;
  /** delete data from the table: "Dummy_table_for_front" */
  delete_Dummy_table_for_front?: Maybe<Dummy_Table_For_Front_Mutation_Response>;
  /** delete single row from the table: "Dummy_table_for_front" */
  delete_Dummy_table_for_front_by_pk?: Maybe<Dummy_Table_For_Front>;
  /** delete data from the table: "Dynamic_cv" */
  delete_Dynamic_cv?: Maybe<Dynamic_Cv_Mutation_Response>;
  /** delete data from the table: "Dynamic_cv_award" */
  delete_Dynamic_cv_award?: Maybe<Dynamic_Cv_Award_Mutation_Response>;
  /** delete single row from the table: "Dynamic_cv_award" */
  delete_Dynamic_cv_award_by_pk?: Maybe<Dynamic_Cv_Award>;
  /** delete single row from the table: "Dynamic_cv" */
  delete_Dynamic_cv_by_pk?: Maybe<Dynamic_Cv>;
  /** delete data from the table: "Dynamic_cv_collection" */
  delete_Dynamic_cv_collection?: Maybe<Dynamic_Cv_Collection_Mutation_Response>;
  /** delete single row from the table: "Dynamic_cv_collection" */
  delete_Dynamic_cv_collection_by_pk?: Maybe<Dynamic_Cv_Collection>;
  /** delete data from the table: "Dynamic_cv_contact" */
  delete_Dynamic_cv_contact?: Maybe<Dynamic_Cv_Contact_Mutation_Response>;
  /** delete single row from the table: "Dynamic_cv_contact" */
  delete_Dynamic_cv_contact_by_pk?: Maybe<Dynamic_Cv_Contact>;
  /** delete data from the table: "Dynamic_cv_education" */
  delete_Dynamic_cv_education?: Maybe<Dynamic_Cv_Education_Mutation_Response>;
  /** delete single row from the table: "Dynamic_cv_education" */
  delete_Dynamic_cv_education_by_pk?: Maybe<Dynamic_Cv_Education>;
  /** delete data from the table: "Dynamic_cv_exhibition" */
  delete_Dynamic_cv_exhibition?: Maybe<Dynamic_Cv_Exhibition_Mutation_Response>;
  /** delete single row from the table: "Dynamic_cv_exhibition" */
  delete_Dynamic_cv_exhibition_by_pk?: Maybe<Dynamic_Cv_Exhibition>;
  /** delete data from the table: "Dynamic_cv_publication" */
  delete_Dynamic_cv_publication?: Maybe<Dynamic_Cv_Publication_Mutation_Response>;
  /** delete single row from the table: "Dynamic_cv_publication" */
  delete_Dynamic_cv_publication_by_pk?: Maybe<Dynamic_Cv_Publication>;
  /** delete data from the table: "Dynamic_cv_section" */
  delete_Dynamic_cv_section?: Maybe<Dynamic_Cv_Section_Mutation_Response>;
  /** delete single row from the table: "Dynamic_cv_section" */
  delete_Dynamic_cv_section_by_pk?: Maybe<Dynamic_Cv_Section>;
  /** delete data from the table: "ENUM_account_notice_type" */
  delete_ENUM_account_notice_type?: Maybe<Enum_Account_Notice_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_account_notice_type" */
  delete_ENUM_account_notice_type_by_pk?: Maybe<Enum_Account_Notice_Type>;
  /** delete data from the table: "ENUM_achievement_tags" */
  delete_ENUM_achievement_tags?: Maybe<Enum_Achievement_Tags_Mutation_Response>;
  /** delete single row from the table: "ENUM_achievement_tags" */
  delete_ENUM_achievement_tags_by_pk?: Maybe<Enum_Achievement_Tags>;
  /** delete data from the table: "ENUM_add_artwork_flow_step" */
  delete_ENUM_add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Mutation_Response>;
  /** delete single row from the table: "ENUM_add_artwork_flow_step" */
  delete_ENUM_add_artwork_flow_step_by_pk?: Maybe<Enum_Add_Artwork_Flow_Step>;
  /** delete data from the table: "ENUM_analytics_kaleidoCard_category" */
  delete_ENUM_analytics_kaleidoCard_category?: Maybe<Enum_Analytics_KaleidoCard_Category_Mutation_Response>;
  /** delete single row from the table: "ENUM_analytics_kaleidoCard_category" */
  delete_ENUM_analytics_kaleidoCard_category_by_pk?: Maybe<Enum_Analytics_KaleidoCard_Category>;
  /** delete data from the table: "ENUM_analytics_type" */
  delete_ENUM_analytics_type?: Maybe<Enum_Analytics_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_analytics_type" */
  delete_ENUM_analytics_type_by_pk?: Maybe<Enum_Analytics_Type>;
  /** delete data from the table: "ENUM_app_notification_type" */
  delete_ENUM_app_notification_type?: Maybe<Enum_App_Notification_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_app_notification_type" */
  delete_ENUM_app_notification_type_by_pk?: Maybe<Enum_App_Notification_Type>;
  /** delete data from the table: "ENUM_art_type" */
  delete_ENUM_art_type?: Maybe<Enum_Art_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_art_type" */
  delete_ENUM_art_type_by_pk?: Maybe<Enum_Art_Type>;
  /** delete data from the table: "ENUM_art_type_extension" */
  delete_ENUM_art_type_extension?: Maybe<Enum_Art_Type_Extension_Mutation_Response>;
  /** delete single row from the table: "ENUM_art_type_extension" */
  delete_ENUM_art_type_extension_by_pk?: Maybe<Enum_Art_Type_Extension>;
  /** delete data from the table: "ENUM_artist_collection_categories" */
  delete_ENUM_artist_collection_categories?: Maybe<Enum_Artist_Collection_Categories_Mutation_Response>;
  /** delete single row from the table: "ENUM_artist_collection_categories" */
  delete_ENUM_artist_collection_categories_by_pk?: Maybe<Enum_Artist_Collection_Categories>;
  /** delete data from the table: "ENUM_artist_collection_state" */
  delete_ENUM_artist_collection_state?: Maybe<Enum_Artist_Collection_State_Mutation_Response>;
  /** delete single row from the table: "ENUM_artist_collection_state" */
  delete_ENUM_artist_collection_state_by_pk?: Maybe<Enum_Artist_Collection_State>;
  /** delete data from the table: "ENUM_artist_collection_type" */
  delete_ENUM_artist_collection_type?: Maybe<Enum_Artist_Collection_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_artist_collection_type" */
  delete_ENUM_artist_collection_type_by_pk?: Maybe<Enum_Artist_Collection_Type>;
  /** delete data from the table: "ENUM_artwork_category" */
  delete_ENUM_artwork_category?: Maybe<Enum_Artwork_Category_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_category" */
  delete_ENUM_artwork_category_by_pk?: Maybe<Enum_Artwork_Category>;
  /** delete data from the table: "ENUM_artwork_count_milestone_type" */
  delete_ENUM_artwork_count_milestone_type?: Maybe<Enum_Artwork_Count_Milestone_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_count_milestone_type" */
  delete_ENUM_artwork_count_milestone_type_by_pk?: Maybe<Enum_Artwork_Count_Milestone_Type>;
  /** delete data from the table: "ENUM_artwork_discipline" */
  delete_ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_discipline" */
  delete_ENUM_artwork_discipline_by_pk?: Maybe<Enum_Artwork_Discipline>;
  /** delete data from the table: "ENUM_artwork_edition_type" */
  delete_ENUM_artwork_edition_type?: Maybe<Enum_Artwork_Edition_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_edition_type" */
  delete_ENUM_artwork_edition_type_by_pk?: Maybe<Enum_Artwork_Edition_Type>;
  /** delete data from the table: "ENUM_artwork_event_type" */
  delete_ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_event_type" */
  delete_ENUM_artwork_event_type_by_pk?: Maybe<Enum_Artwork_Event_Type>;
  /** delete data from the table: "ENUM_artwork_genre" */
  delete_ENUM_artwork_genre?: Maybe<Enum_Artwork_Genre_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_genre" */
  delete_ENUM_artwork_genre_by_pk?: Maybe<Enum_Artwork_Genre>;
  /** delete data from the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  delete_ENUM_artwork_purchase_journey_history_entry_kind?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  delete_ENUM_artwork_purchase_journey_history_entry_kind_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind>;
  /** delete data from the table: "ENUM_artwork_purchase_journey_party" */
  delete_ENUM_artwork_purchase_journey_party?: Maybe<Enum_Artwork_Purchase_Journey_Party_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_purchase_journey_party" */
  delete_ENUM_artwork_purchase_journey_party_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_Party>;
  /** delete data from the table: "ENUM_artwork_purchase_journey_status" */
  delete_ENUM_artwork_purchase_journey_status?: Maybe<Enum_Artwork_Purchase_Journey_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_purchase_journey_status" */
  delete_ENUM_artwork_purchase_journey_status_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_Status>;
  /** delete data from the table: "ENUM_artwork_showroom_status" */
  delete_ENUM_artwork_showroom_status?: Maybe<Enum_Artwork_Showroom_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_showroom_status" */
  delete_ENUM_artwork_showroom_status_by_pk?: Maybe<Enum_Artwork_Showroom_Status>;
  /** delete data from the table: "ENUM_artwork_status" */
  delete_ENUM_artwork_status?: Maybe<Enum_Artwork_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_status" */
  delete_ENUM_artwork_status_by_pk?: Maybe<Enum_Artwork_Status>;
  /** delete data from the table: "ENUM_artwork_tag" */
  delete_ENUM_artwork_tag?: Maybe<Enum_Artwork_Tag_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_tag" */
  delete_ENUM_artwork_tag_by_pk?: Maybe<Enum_Artwork_Tag>;
  /** delete data from the table: "ENUM_artwork_tag_type" */
  delete_ENUM_artwork_tag_type?: Maybe<Enum_Artwork_Tag_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_tag_type" */
  delete_ENUM_artwork_tag_type_by_pk?: Maybe<Enum_Artwork_Tag_Type>;
  /** delete data from the table: "ENUM_artwork_type" */
  delete_ENUM_artwork_type?: Maybe<Enum_Artwork_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_artwork_type" */
  delete_ENUM_artwork_type_by_pk?: Maybe<Enum_Artwork_Type>;
  /** delete data from the table: "ENUM_audio_generation_status" */
  delete_ENUM_audio_generation_status?: Maybe<Enum_Audio_Generation_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_audio_generation_status" */
  delete_ENUM_audio_generation_status_by_pk?: Maybe<Enum_Audio_Generation_Status>;
  /** delete data from the table: "ENUM_auth_type" */
  delete_ENUM_auth_type?: Maybe<Enum_Auth_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_auth_type" */
  delete_ENUM_auth_type_by_pk?: Maybe<Enum_Auth_Type>;
  /** delete data from the table: "ENUM_billing_artwork_payment_intent_status" */
  delete_ENUM_billing_artwork_payment_intent_status?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_billing_artwork_payment_intent_status" */
  delete_ENUM_billing_artwork_payment_intent_status_by_pk?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status>;
  /** delete data from the table: "ENUM_billing_payment_intent_product_status" */
  delete_ENUM_billing_payment_intent_product_status?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_billing_payment_intent_product_status" */
  delete_ENUM_billing_payment_intent_product_status_by_pk?: Maybe<Enum_Billing_Payment_Intent_Product_Status>;
  /** delete data from the table: "ENUM_billing_seller_status" */
  delete_ENUM_billing_seller_status?: Maybe<Enum_Billing_Seller_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_billing_seller_status" */
  delete_ENUM_billing_seller_status_by_pk?: Maybe<Enum_Billing_Seller_Status>;
  /** delete data from the table: "ENUM_billing_subscription_period" */
  delete_ENUM_billing_subscription_period?: Maybe<Enum_Billing_Subscription_Period_Mutation_Response>;
  /** delete single row from the table: "ENUM_billing_subscription_period" */
  delete_ENUM_billing_subscription_period_by_pk?: Maybe<Enum_Billing_Subscription_Period>;
  /** delete data from the table: "ENUM_billing_subscription_product_type" */
  delete_ENUM_billing_subscription_product_type?: Maybe<Enum_Billing_Subscription_Product_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_billing_subscription_product_type" */
  delete_ENUM_billing_subscription_product_type_by_pk?: Maybe<Enum_Billing_Subscription_Product_Type>;
  /** delete data from the table: "ENUM_billing_subscription_status" */
  delete_ENUM_billing_subscription_status?: Maybe<Enum_Billing_Subscription_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_billing_subscription_status" */
  delete_ENUM_billing_subscription_status_by_pk?: Maybe<Enum_Billing_Subscription_Status>;
  /** delete data from the table: "ENUM_blockchain" */
  delete_ENUM_blockchain?: Maybe<Enum_Blockchain_Mutation_Response>;
  /** delete single row from the table: "ENUM_blockchain" */
  delete_ENUM_blockchain_by_pk?: Maybe<Enum_Blockchain>;
  /** delete data from the table: "ENUM_collaboration_status" */
  delete_ENUM_collaboration_status?: Maybe<Enum_Collaboration_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_collaboration_status" */
  delete_ENUM_collaboration_status_by_pk?: Maybe<Enum_Collaboration_Status>;
  /** delete data from the table: "ENUM_collection_type" */
  delete_ENUM_collection_type?: Maybe<Enum_Collection_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_collection_type" */
  delete_ENUM_collection_type_by_pk?: Maybe<Enum_Collection_Type>;
  /** delete data from the table: "ENUM_connected_domain_state" */
  delete_ENUM_connected_domain_state?: Maybe<Enum_Connected_Domain_State_Mutation_Response>;
  /** delete single row from the table: "ENUM_connected_domain_state" */
  delete_ENUM_connected_domain_state_by_pk?: Maybe<Enum_Connected_Domain_State>;
  /** delete data from the table: "ENUM_content_type" */
  delete_ENUM_content_type?: Maybe<Enum_Content_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_content_type" */
  delete_ENUM_content_type_by_pk?: Maybe<Enum_Content_Type>;
  /** delete data from the table: "ENUM_context_delegate_role" */
  delete_ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role_Mutation_Response>;
  /** delete single row from the table: "ENUM_context_delegate_role" */
  delete_ENUM_context_delegate_role_by_pk?: Maybe<Enum_Context_Delegate_Role>;
  /** delete data from the table: "ENUM_context_delegate_state" */
  delete_ENUM_context_delegate_state?: Maybe<Enum_Context_Delegate_State_Mutation_Response>;
  /** delete single row from the table: "ENUM_context_delegate_state" */
  delete_ENUM_context_delegate_state_by_pk?: Maybe<Enum_Context_Delegate_State>;
  /** delete data from the table: "ENUM_context_state" */
  delete_ENUM_context_state?: Maybe<Enum_Context_State_Mutation_Response>;
  /** delete single row from the table: "ENUM_context_state" */
  delete_ENUM_context_state_by_pk?: Maybe<Enum_Context_State>;
  /** delete data from the table: "ENUM_context_type" */
  delete_ENUM_context_type?: Maybe<Enum_Context_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_context_type" */
  delete_ENUM_context_type_by_pk?: Maybe<Enum_Context_Type>;
  /** delete data from the table: "ENUM_discount_state" */
  delete_ENUM_discount_state?: Maybe<Enum_Discount_State_Mutation_Response>;
  /** delete single row from the table: "ENUM_discount_state" */
  delete_ENUM_discount_state_by_pk?: Maybe<Enum_Discount_State>;
  /** delete data from the table: "ENUM_discount_type" */
  delete_ENUM_discount_type?: Maybe<Enum_Discount_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_discount_type" */
  delete_ENUM_discount_type_by_pk?: Maybe<Enum_Discount_Type>;
  /** delete data from the table: "ENUM_discount_value_type" */
  delete_ENUM_discount_value_type?: Maybe<Enum_Discount_Value_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_discount_value_type" */
  delete_ENUM_discount_value_type_by_pk?: Maybe<Enum_Discount_Value_Type>;
  /** delete data from the table: "ENUM_domain_registration_status" */
  delete_ENUM_domain_registration_status?: Maybe<Enum_Domain_Registration_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_domain_registration_status" */
  delete_ENUM_domain_registration_status_by_pk?: Maybe<Enum_Domain_Registration_Status>;
  /** delete data from the table: "ENUM_dynamic_cv_exhibition_type" */
  delete_ENUM_dynamic_cv_exhibition_type?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_dynamic_cv_exhibition_type" */
  delete_ENUM_dynamic_cv_exhibition_type_by_pk?: Maybe<Enum_Dynamic_Cv_Exhibition_Type>;
  /** delete data from the table: "ENUM_dynamic_cv_section_type" */
  delete_ENUM_dynamic_cv_section_type?: Maybe<Enum_Dynamic_Cv_Section_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_dynamic_cv_section_type" */
  delete_ENUM_dynamic_cv_section_type_by_pk?: Maybe<Enum_Dynamic_Cv_Section_Type>;
  /** delete data from the table: "ENUM_dynamic_cv_status" */
  delete_ENUM_dynamic_cv_status?: Maybe<Enum_Dynamic_Cv_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_dynamic_cv_status" */
  delete_ENUM_dynamic_cv_status_by_pk?: Maybe<Enum_Dynamic_Cv_Status>;
  /** delete data from the table: "ENUM_edition_status" */
  delete_ENUM_edition_status?: Maybe<Enum_Edition_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_edition_status" */
  delete_ENUM_edition_status_by_pk?: Maybe<Enum_Edition_Status>;
  /** delete data from the table: "ENUM_email_language" */
  delete_ENUM_email_language?: Maybe<Enum_Email_Language_Mutation_Response>;
  /** delete single row from the table: "ENUM_email_language" */
  delete_ENUM_email_language_by_pk?: Maybe<Enum_Email_Language>;
  /** delete data from the table: "ENUM_email_status" */
  delete_ENUM_email_status?: Maybe<Enum_Email_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_email_status" */
  delete_ENUM_email_status_by_pk?: Maybe<Enum_Email_Status>;
  /** delete data from the table: "ENUM_email_validation_token_status" */
  delete_ENUM_email_validation_token_status?: Maybe<Enum_Email_Validation_Token_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_email_validation_token_status" */
  delete_ENUM_email_validation_token_status_by_pk?: Maybe<Enum_Email_Validation_Token_Status>;
  /** delete data from the table: "ENUM_file_metadata_status" */
  delete_ENUM_file_metadata_status?: Maybe<Enum_File_Metadata_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_file_metadata_status" */
  delete_ENUM_file_metadata_status_by_pk?: Maybe<Enum_File_Metadata_Status>;
  /** delete data from the table: "ENUM_flags_type" */
  delete_ENUM_flags_type?: Maybe<Enum_Flags_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_flags_type" */
  delete_ENUM_flags_type_by_pk?: Maybe<Enum_Flags_Type>;
  /** delete data from the table: "ENUM_forge_configuration_type" */
  delete_ENUM_forge_configuration_type?: Maybe<Enum_Forge_Configuration_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_forge_configuration_type" */
  delete_ENUM_forge_configuration_type_by_pk?: Maybe<Enum_Forge_Configuration_Type>;
  /** delete data from the table: "ENUM_forge_status" */
  delete_ENUM_forge_status?: Maybe<Enum_Forge_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_forge_status" */
  delete_ENUM_forge_status_by_pk?: Maybe<Enum_Forge_Status>;
  /** delete data from the table: "ENUM_infraction_flag_type" */
  delete_ENUM_infraction_flag_type?: Maybe<Enum_Infraction_Flag_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_infraction_flag_type" */
  delete_ENUM_infraction_flag_type_by_pk?: Maybe<Enum_Infraction_Flag_Type>;
  /** delete data from the table: "ENUM_interest_type" */
  delete_ENUM_interest_type?: Maybe<Enum_Interest_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_interest_type" */
  delete_ENUM_interest_type_by_pk?: Maybe<Enum_Interest_Type>;
  /** delete data from the table: "ENUM_language_preference" */
  delete_ENUM_language_preference?: Maybe<Enum_Language_Preference_Mutation_Response>;
  /** delete single row from the table: "ENUM_language_preference" */
  delete_ENUM_language_preference_by_pk?: Maybe<Enum_Language_Preference>;
  /** delete data from the table: "ENUM_my_website_artwork_info" */
  delete_ENUM_my_website_artwork_info?: Maybe<Enum_My_Website_Artwork_Info_Mutation_Response>;
  /** delete single row from the table: "ENUM_my_website_artwork_info" */
  delete_ENUM_my_website_artwork_info_by_pk?: Maybe<Enum_My_Website_Artwork_Info>;
  /** delete data from the table: "ENUM_my_website_filters" */
  delete_ENUM_my_website_filters?: Maybe<Enum_My_Website_Filters_Mutation_Response>;
  /** delete single row from the table: "ENUM_my_website_filters" */
  delete_ENUM_my_website_filters_by_pk?: Maybe<Enum_My_Website_Filters>;
  /** delete data from the table: "ENUM_my_website_layout" */
  delete_ENUM_my_website_layout?: Maybe<Enum_My_Website_Layout_Mutation_Response>;
  /** delete single row from the table: "ENUM_my_website_layout" */
  delete_ENUM_my_website_layout_by_pk?: Maybe<Enum_My_Website_Layout>;
  /** delete data from the table: "ENUM_my_website_navigation" */
  delete_ENUM_my_website_navigation?: Maybe<Enum_My_Website_Navigation_Mutation_Response>;
  /** delete single row from the table: "ENUM_my_website_navigation" */
  delete_ENUM_my_website_navigation_by_pk?: Maybe<Enum_My_Website_Navigation>;
  /** delete data from the table: "ENUM_my_website_theme" */
  delete_ENUM_my_website_theme?: Maybe<Enum_My_Website_Theme_Mutation_Response>;
  /** delete single row from the table: "ENUM_my_website_theme" */
  delete_ENUM_my_website_theme_by_pk?: Maybe<Enum_My_Website_Theme>;
  /** delete data from the table: "ENUM_notification_channel" */
  delete_ENUM_notification_channel?: Maybe<Enum_Notification_Channel_Mutation_Response>;
  /** delete single row from the table: "ENUM_notification_channel" */
  delete_ENUM_notification_channel_by_pk?: Maybe<Enum_Notification_Channel>;
  /** delete data from the table: "ENUM_notification_group" */
  delete_ENUM_notification_group?: Maybe<Enum_Notification_Group_Mutation_Response>;
  /** delete single row from the table: "ENUM_notification_group" */
  delete_ENUM_notification_group_by_pk?: Maybe<Enum_Notification_Group>;
  /** delete data from the table: "ENUM_position_type" */
  delete_ENUM_position_type?: Maybe<Enum_Position_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_position_type" */
  delete_ENUM_position_type_by_pk?: Maybe<Enum_Position_Type>;
  /** delete data from the table: "ENUM_price_scope" */
  delete_ENUM_price_scope?: Maybe<Enum_Price_Scope_Mutation_Response>;
  /** delete single row from the table: "ENUM_price_scope" */
  delete_ENUM_price_scope_by_pk?: Maybe<Enum_Price_Scope>;
  /** delete data from the table: "ENUM_print_technique" */
  delete_ENUM_print_technique?: Maybe<Enum_Print_Technique_Mutation_Response>;
  /** delete single row from the table: "ENUM_print_technique" */
  delete_ENUM_print_technique_by_pk?: Maybe<Enum_Print_Technique>;
  /** delete data from the table: "ENUM_profile_background_type" */
  delete_ENUM_profile_background_type?: Maybe<Enum_Profile_Background_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_profile_background_type" */
  delete_ENUM_profile_background_type_by_pk?: Maybe<Enum_Profile_Background_Type>;
  /** delete data from the table: "ENUM_profile_invitation_status" */
  delete_ENUM_profile_invitation_status?: Maybe<Enum_Profile_Invitation_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_profile_invitation_status" */
  delete_ENUM_profile_invitation_status_by_pk?: Maybe<Enum_Profile_Invitation_Status>;
  /** delete data from the table: "ENUM_profile_public_status" */
  delete_ENUM_profile_public_status?: Maybe<Enum_Profile_Public_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_profile_public_status" */
  delete_ENUM_profile_public_status_by_pk?: Maybe<Enum_Profile_Public_Status>;
  /** delete data from the table: "ENUM_profile_region" */
  delete_ENUM_profile_region?: Maybe<Enum_Profile_Region_Mutation_Response>;
  /** delete single row from the table: "ENUM_profile_region" */
  delete_ENUM_profile_region_by_pk?: Maybe<Enum_Profile_Region>;
  /** delete data from the table: "ENUM_profile_status" */
  delete_ENUM_profile_status?: Maybe<Enum_Profile_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_profile_status" */
  delete_ENUM_profile_status_by_pk?: Maybe<Enum_Profile_Status>;
  /** delete data from the table: "ENUM_profile_theme" */
  delete_ENUM_profile_theme?: Maybe<Enum_Profile_Theme_Mutation_Response>;
  /** delete single row from the table: "ENUM_profile_theme" */
  delete_ENUM_profile_theme_by_pk?: Maybe<Enum_Profile_Theme>;
  /** delete data from the table: "ENUM_profile_type" */
  delete_ENUM_profile_type?: Maybe<Enum_Profile_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_profile_type" */
  delete_ENUM_profile_type_by_pk?: Maybe<Enum_Profile_Type>;
  /** delete data from the table: "ENUM_pseudo_user_action" */
  delete_ENUM_pseudo_user_action?: Maybe<Enum_Pseudo_User_Action_Mutation_Response>;
  /** delete single row from the table: "ENUM_pseudo_user_action" */
  delete_ENUM_pseudo_user_action_by_pk?: Maybe<Enum_Pseudo_User_Action>;
  /** delete data from the table: "ENUM_push_notification_status" */
  delete_ENUM_push_notification_status?: Maybe<Enum_Push_Notification_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_push_notification_status" */
  delete_ENUM_push_notification_status_by_pk?: Maybe<Enum_Push_Notification_Status>;
  /** delete data from the table: "ENUM_push_notification_type" */
  delete_ENUM_push_notification_type?: Maybe<Enum_Push_Notification_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_push_notification_type" */
  delete_ENUM_push_notification_type_by_pk?: Maybe<Enum_Push_Notification_Type>;
  /** delete data from the table: "ENUM_questionnaire_type" */
  delete_ENUM_questionnaire_type?: Maybe<Enum_Questionnaire_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_questionnaire_type" */
  delete_ENUM_questionnaire_type_by_pk?: Maybe<Enum_Questionnaire_Type>;
  /** delete data from the table: "ENUM_render_request_status" */
  delete_ENUM_render_request_status?: Maybe<Enum_Render_Request_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_render_request_status" */
  delete_ENUM_render_request_status_by_pk?: Maybe<Enum_Render_Request_Status>;
  /** delete data from the table: "ENUM_render_request_template_type" */
  delete_ENUM_render_request_template_type?: Maybe<Enum_Render_Request_Template_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_render_request_template_type" */
  delete_ENUM_render_request_template_type_by_pk?: Maybe<Enum_Render_Request_Template_Type>;
  /** delete data from the table: "ENUM_restricted_handle_names" */
  delete_ENUM_restricted_handle_names?: Maybe<Enum_Restricted_Handle_Names_Mutation_Response>;
  /** delete single row from the table: "ENUM_restricted_handle_names" */
  delete_ENUM_restricted_handle_names_by_pk?: Maybe<Enum_Restricted_Handle_Names>;
  /** delete data from the table: "ENUM_size" */
  delete_ENUM_size?: Maybe<Enum_Size_Mutation_Response>;
  /** delete single row from the table: "ENUM_size" */
  delete_ENUM_size_by_pk?: Maybe<Enum_Size>;
  /** delete data from the table: "ENUM_social_graph_followers_state" */
  delete_ENUM_social_graph_followers_state?: Maybe<Enum_Social_Graph_Followers_State_Mutation_Response>;
  /** delete single row from the table: "ENUM_social_graph_followers_state" */
  delete_ENUM_social_graph_followers_state_by_pk?: Maybe<Enum_Social_Graph_Followers_State>;
  /** delete data from the table: "ENUM_social_link_status" */
  delete_ENUM_social_link_status?: Maybe<Enum_Social_Link_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_social_link_status" */
  delete_ENUM_social_link_status_by_pk?: Maybe<Enum_Social_Link_Status>;
  /** delete data from the table: "ENUM_social_link_type" */
  delete_ENUM_social_link_type?: Maybe<Enum_Social_Link_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_social_link_type" */
  delete_ENUM_social_link_type_by_pk?: Maybe<Enum_Social_Link_Type>;
  /** delete data from the table: "ENUM_timed_event_name" */
  delete_ENUM_timed_event_name?: Maybe<Enum_Timed_Event_Name_Mutation_Response>;
  /** delete single row from the table: "ENUM_timed_event_name" */
  delete_ENUM_timed_event_name_by_pk?: Maybe<Enum_Timed_Event_Name>;
  /** delete data from the table: "ENUM_token_storage_status" */
  delete_ENUM_token_storage_status?: Maybe<Enum_Token_Storage_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_token_storage_status" */
  delete_ENUM_token_storage_status_by_pk?: Maybe<Enum_Token_Storage_Status>;
  /** delete data from the table: "ENUM_transaction_origin" */
  delete_ENUM_transaction_origin?: Maybe<Enum_Transaction_Origin_Mutation_Response>;
  /** delete single row from the table: "ENUM_transaction_origin" */
  delete_ENUM_transaction_origin_by_pk?: Maybe<Enum_Transaction_Origin>;
  /** delete data from the table: "ENUM_transaction_status" */
  delete_ENUM_transaction_status?: Maybe<Enum_Transaction_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_transaction_status" */
  delete_ENUM_transaction_status_by_pk?: Maybe<Enum_Transaction_Status>;
  /** delete data from the table: "ENUM_tutorial_status" */
  delete_ENUM_tutorial_status?: Maybe<Enum_Tutorial_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_tutorial_status" */
  delete_ENUM_tutorial_status_by_pk?: Maybe<Enum_Tutorial_Status>;
  /** delete data from the table: "ENUM_tutorial_step_type" */
  delete_ENUM_tutorial_step_type?: Maybe<Enum_Tutorial_Step_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_tutorial_step_type" */
  delete_ENUM_tutorial_step_type_by_pk?: Maybe<Enum_Tutorial_Step_Type>;
  /** delete data from the table: "ENUM_tutorial_type" */
  delete_ENUM_tutorial_type?: Maybe<Enum_Tutorial_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_tutorial_type" */
  delete_ENUM_tutorial_type_by_pk?: Maybe<Enum_Tutorial_Type>;
  /** delete data from the table: "ENUM_vumark_status" */
  delete_ENUM_vumark_status?: Maybe<Enum_Vumark_Status_Mutation_Response>;
  /** delete single row from the table: "ENUM_vumark_status" */
  delete_ENUM_vumark_status_by_pk?: Maybe<Enum_Vumark_Status>;
  /** delete data from the table: "ENUM_vumark_type" */
  delete_ENUM_vumark_type?: Maybe<Enum_Vumark_Type_Mutation_Response>;
  /** delete single row from the table: "ENUM_vumark_type" */
  delete_ENUM_vumark_type_by_pk?: Maybe<Enum_Vumark_Type>;
  /** delete data from the table: "Edition" */
  delete_Edition?: Maybe<Edition_Mutation_Response>;
  /** delete data from the table: "Edition_By_Size" */
  delete_Edition_By_Size?: Maybe<Edition_By_Size_Mutation_Response>;
  /** delete single row from the table: "Edition_By_Size" */
  delete_Edition_By_Size_by_pk?: Maybe<Edition_By_Size>;
  /** delete data from the table: "Edition_By_Type" */
  delete_Edition_By_Type?: Maybe<Edition_By_Type_Mutation_Response>;
  /** delete single row from the table: "Edition_By_Type" */
  delete_Edition_By_Type_by_pk?: Maybe<Edition_By_Type>;
  /** delete data from the table: "Edition_Type" */
  delete_Edition_Type?: Maybe<Edition_Type_Mutation_Response>;
  /** delete single row from the table: "Edition_Type" */
  delete_Edition_Type_by_pk?: Maybe<Edition_Type>;
  /** delete single row from the table: "Edition" */
  delete_Edition_by_pk?: Maybe<Edition>;
  /** delete data from the table: "Edition_events" */
  delete_Edition_events?: Maybe<Edition_Events_Mutation_Response>;
  /** delete single row from the table: "Edition_events" */
  delete_Edition_events_by_pk?: Maybe<Edition_Events>;
  /** delete data from the table: "Email_validation_token" */
  delete_Email_validation_token?: Maybe<Email_Validation_Token_Mutation_Response>;
  /** delete single row from the table: "Email_validation_token" */
  delete_Email_validation_token_by_pk?: Maybe<Email_Validation_Token>;
  /** delete data from the table: "Emails" */
  delete_Emails?: Maybe<Emails_Mutation_Response>;
  /** delete single row from the table: "Emails" */
  delete_Emails_by_pk?: Maybe<Emails>;
  /** delete data from the table: "FCM_Token" */
  delete_FCM_Token?: Maybe<Fcm_Token_Mutation_Response>;
  /** delete single row from the table: "FCM_Token" */
  delete_FCM_Token_by_pk?: Maybe<Fcm_Token>;
  /** delete data from the table: "Features" */
  delete_Features?: Maybe<Features_Mutation_Response>;
  /** delete single row from the table: "Features" */
  delete_Features_by_pk?: Maybe<Features>;
  /** delete data from the table: "File_metadata" */
  delete_File_metadata?: Maybe<File_Metadata_Mutation_Response>;
  /** delete single row from the table: "File_metadata" */
  delete_File_metadata_by_pk?: Maybe<File_Metadata>;
  /** delete data from the table: "Firebase_profile" */
  delete_Firebase_profile?: Maybe<Firebase_Profile_Mutation_Response>;
  /** delete single row from the table: "Firebase_profile" */
  delete_Firebase_profile_by_pk?: Maybe<Firebase_Profile>;
  /** delete data from the table: "Flagged_users" */
  delete_Flagged_users?: Maybe<Flagged_Users_Mutation_Response>;
  /** delete single row from the table: "Flagged_users" */
  delete_Flagged_users_by_pk?: Maybe<Flagged_Users>;
  /** delete data from the table: "Forge_InProcess_Task" */
  delete_Forge_InProcess_Task?: Maybe<Forge_InProcess_Task_Mutation_Response>;
  /** delete single row from the table: "Forge_InProcess_Task" */
  delete_Forge_InProcess_Task_by_pk?: Maybe<Forge_InProcess_Task>;
  /** delete data from the table: "Forge_tasks" */
  delete_Forge_tasks?: Maybe<Forge_Tasks_Mutation_Response>;
  /** delete single row from the table: "Forge_tasks" */
  delete_Forge_tasks_by_pk?: Maybe<Forge_Tasks>;
  /** delete data from the table: "Global_properties" */
  delete_Global_properties?: Maybe<Global_Properties_Mutation_Response>;
  /** delete single row from the table: "Global_properties" */
  delete_Global_properties_by_pk?: Maybe<Global_Properties>;
  /** delete data from the table: "Graveyard_Artwork" */
  delete_Graveyard_Artwork?: Maybe<Graveyard_Artwork_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork" */
  delete_Graveyard_Artwork_by_pk?: Maybe<Graveyard_Artwork>;
  /** delete data from the table: "Graveyard_Artwork_details" */
  delete_Graveyard_Artwork_details?: Maybe<Graveyard_Artwork_Details_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork_details" */
  delete_Graveyard_Artwork_details_by_pk?: Maybe<Graveyard_Artwork_Details>;
  /** delete data from the table: "Graveyard_Artwork_details_translation" */
  delete_Graveyard_Artwork_details_translation?: Maybe<Graveyard_Artwork_Details_Translation_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork_details_translation" */
  delete_Graveyard_Artwork_details_translation_by_pk?: Maybe<Graveyard_Artwork_Details_Translation>;
  /** delete data from the table: "Graveyard_Artwork_events" */
  delete_Graveyard_Artwork_events?: Maybe<Graveyard_Artwork_Events_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork_events" */
  delete_Graveyard_Artwork_events_by_pk?: Maybe<Graveyard_Artwork_Events>;
  /** delete data from the table: "Graveyard_Artwork_prices" */
  delete_Graveyard_Artwork_prices?: Maybe<Graveyard_Artwork_Prices_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork_prices" */
  delete_Graveyard_Artwork_prices_by_pk?: Maybe<Graveyard_Artwork_Prices>;
  /** delete data from the table: "Graveyard_Artwork_secondary_media" */
  delete_Graveyard_Artwork_secondary_media?: Maybe<Graveyard_Artwork_Secondary_Media_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork_secondary_media" */
  delete_Graveyard_Artwork_secondary_media_by_pk?: Maybe<Graveyard_Artwork_Secondary_Media>;
  /** delete data from the table: "Graveyard_Artwork_story" */
  delete_Graveyard_Artwork_story?: Maybe<Graveyard_Artwork_Story_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork_story" */
  delete_Graveyard_Artwork_story_by_pk?: Maybe<Graveyard_Artwork_Story>;
  /** delete data from the table: "Graveyard_Artwork_story_media" */
  delete_Graveyard_Artwork_story_media?: Maybe<Graveyard_Artwork_Story_Media_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork_story_media" */
  delete_Graveyard_Artwork_story_media_by_pk?: Maybe<Graveyard_Artwork_Story_Media>;
  /** delete data from the table: "Graveyard_Artwork_to_Collection" */
  delete_Graveyard_Artwork_to_Collection?: Maybe<Graveyard_Artwork_To_Collection_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork_to_Collection" */
  delete_Graveyard_Artwork_to_Collection_by_pk?: Maybe<Graveyard_Artwork_To_Collection>;
  /** delete data from the table: "Graveyard_Artwork_transactions" */
  delete_Graveyard_Artwork_transactions?: Maybe<Graveyard_Artwork_Transactions_Mutation_Response>;
  /** delete single row from the table: "Graveyard_Artwork_transactions" */
  delete_Graveyard_Artwork_transactions_by_pk?: Maybe<Graveyard_Artwork_Transactions>;
  /** delete data from the table: "Graveyard_Purchase_inquires" */
  delete_Graveyard_Purchase_inquires?: Maybe<Graveyard_Purchase_Inquires_Mutation_Response>;
  /** delete data from the table: "Graveyard_Purchase_inquires_outside_users" */
  delete_Graveyard_Purchase_inquires_outside_users?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Mutation_Response>;
  /** delete data from the table: "Interests" */
  delete_Interests?: Maybe<Interests_Mutation_Response>;
  /** delete single row from the table: "Interests" */
  delete_Interests_by_pk?: Maybe<Interests>;
  /** delete data from the table: "Kaleido_general_settings" */
  delete_Kaleido_general_settings?: Maybe<Kaleido_General_Settings_Mutation_Response>;
  /** delete single row from the table: "Kaleido_general_settings" */
  delete_Kaleido_general_settings_by_pk?: Maybe<Kaleido_General_Settings>;
  /** delete data from the table: "Landing_page_artwork" */
  delete_Landing_page_artwork?: Maybe<Landing_Page_Artwork_Mutation_Response>;
  /** delete single row from the table: "Landing_page_artwork" */
  delete_Landing_page_artwork_by_pk?: Maybe<Landing_Page_Artwork>;
  /** delete data from the table: "Languages" */
  delete_Languages?: Maybe<Languages_Mutation_Response>;
  /** delete single row from the table: "Languages" */
  delete_Languages_by_pk?: Maybe<Languages>;
  /** delete data from the table: "Materialized_view" */
  delete_Materialized_view?: Maybe<Materialized_View_Mutation_Response>;
  /** delete single row from the table: "Materialized_view" */
  delete_Materialized_view_by_pk?: Maybe<Materialized_View>;
  /** delete data from the table: "Media_metadata" */
  delete_Media_metadata?: Maybe<Media_Metadata_Mutation_Response>;
  /** delete single row from the table: "Media_metadata" */
  delete_Media_metadata_by_pk?: Maybe<Media_Metadata>;
  /** delete data from the table: "Milestone" */
  delete_Milestone?: Maybe<Milestone_Mutation_Response>;
  /** delete single row from the table: "Milestone" */
  delete_Milestone_by_pk?: Maybe<Milestone>;
  /** delete data from the table: "My_Website_Settings" */
  delete_My_Website_Settings?: Maybe<My_Website_Settings_Mutation_Response>;
  /** delete single row from the table: "My_Website_Settings" */
  delete_My_Website_Settings_by_pk?: Maybe<My_Website_Settings>;
  /** delete data from the table: "My_studio_settings" */
  delete_My_studio_settings?: Maybe<My_Studio_Settings_Mutation_Response>;
  /** delete single row from the table: "My_studio_settings" */
  delete_My_studio_settings_by_pk?: Maybe<My_Studio_Settings>;
  /** delete data from the table: "Notification_preference" */
  delete_Notification_preference?: Maybe<Notification_Preference_Mutation_Response>;
  /** delete single row from the table: "Notification_preference" */
  delete_Notification_preference_by_pk?: Maybe<Notification_Preference>;
  /** delete data from the table: "Pending_collaboration_on_artwork" */
  delete_Pending_collaboration_on_artwork?: Maybe<Pending_Collaboration_On_Artwork_Mutation_Response>;
  /** delete single row from the table: "Pending_collaboration_on_artwork" */
  delete_Pending_collaboration_on_artwork_by_pk?: Maybe<Pending_Collaboration_On_Artwork>;
  /** delete data from the table: "Price_Ranges" */
  delete_Price_Ranges?: Maybe<Price_Ranges_Mutation_Response>;
  /** delete single row from the table: "Price_Ranges" */
  delete_Price_Ranges_by_pk?: Maybe<Price_Ranges>;
  /** delete data from the table: "Print" */
  delete_Print?: Maybe<Print_Mutation_Response>;
  /** delete single row from the table: "Print" */
  delete_Print_by_pk?: Maybe<Print>;
  /** delete data from the table: "Print_to_Collection" */
  delete_Print_to_Collection?: Maybe<Print_To_Collection_Mutation_Response>;
  /** delete single row from the table: "Print_to_Collection" */
  delete_Print_to_Collection_by_pk?: Maybe<Print_To_Collection>;
  /** delete data from the table: "Prize_Art_Show" */
  delete_Prize_Art_Show?: Maybe<Prize_Art_Show_Mutation_Response>;
  /** delete single row from the table: "Prize_Art_Show" */
  delete_Prize_Art_Show_by_pk?: Maybe<Prize_Art_Show>;
  /** delete data from the table: "Profile" */
  delete_Profile?: Maybe<Profile_Mutation_Response>;
  /** delete data from the table: "Profile_Interests" */
  delete_Profile_Interests?: Maybe<Profile_Interests_Mutation_Response>;
  /** delete single row from the table: "Profile_Interests" */
  delete_Profile_Interests_by_pk?: Maybe<Profile_Interests>;
  /** delete data from the table: "Profile_achievements" */
  delete_Profile_achievements?: Maybe<Profile_Achievements_Mutation_Response>;
  /** delete single row from the table: "Profile_achievements" */
  delete_Profile_achievements_by_pk?: Maybe<Profile_Achievements>;
  /** delete data from the table: "Profile_appearance_settings" */
  delete_Profile_appearance_settings?: Maybe<Profile_Appearance_Settings_Mutation_Response>;
  /** delete single row from the table: "Profile_appearance_settings" */
  delete_Profile_appearance_settings_by_pk?: Maybe<Profile_Appearance_Settings>;
  /** delete data from the table: "Profile_background" */
  delete_Profile_background?: Maybe<Profile_Background_Mutation_Response>;
  /** delete single row from the table: "Profile_background" */
  delete_Profile_background_by_pk?: Maybe<Profile_Background>;
  /** delete single row from the table: "Profile" */
  delete_Profile_by_pk?: Maybe<Profile>;
  /** delete data from the table: "Profile_ghost_tokens" */
  delete_Profile_ghost_tokens?: Maybe<Profile_Ghost_Tokens_Mutation_Response>;
  /** delete single row from the table: "Profile_ghost_tokens" */
  delete_Profile_ghost_tokens_by_pk?: Maybe<Profile_Ghost_Tokens>;
  /** delete data from the table: "Profile_invitations" */
  delete_Profile_invitations?: Maybe<Profile_Invitations_Mutation_Response>;
  /** delete single row from the table: "Profile_invitations" */
  delete_Profile_invitations_by_pk?: Maybe<Profile_Invitations>;
  /** delete data from the table: "Profile_location" */
  delete_Profile_location?: Maybe<Profile_Location_Mutation_Response>;
  /** delete single row from the table: "Profile_location" */
  delete_Profile_location_by_pk?: Maybe<Profile_Location>;
  /** delete data from the table: "Profile_location_translation" */
  delete_Profile_location_translation?: Maybe<Profile_Location_Translation_Mutation_Response>;
  /** delete single row from the table: "Profile_location_translation" */
  delete_Profile_location_translation_by_pk?: Maybe<Profile_Location_Translation>;
  /** delete data from the table: "Profile_price_ranges" */
  delete_Profile_price_ranges?: Maybe<Profile_Price_Ranges_Mutation_Response>;
  /** delete single row from the table: "Profile_price_ranges" */
  delete_Profile_price_ranges_by_pk?: Maybe<Profile_Price_Ranges>;
  /** delete data from the table: "Profile_questions_and_answers" */
  delete_Profile_questions_and_answers?: Maybe<Profile_Questions_And_Answers_Mutation_Response>;
  /** delete single row from the table: "Profile_questions_and_answers" */
  delete_Profile_questions_and_answers_by_pk?: Maybe<Profile_Questions_And_Answers>;
  /** delete data from the table: "Profile_settings" */
  delete_Profile_settings?: Maybe<Profile_Settings_Mutation_Response>;
  /** delete single row from the table: "Profile_settings" */
  delete_Profile_settings_by_pk?: Maybe<Profile_Settings>;
  /** delete data from the table: "Profile_social_links" */
  delete_Profile_social_links?: Maybe<Profile_Social_Links_Mutation_Response>;
  /** delete single row from the table: "Profile_social_links" */
  delete_Profile_social_links_by_pk?: Maybe<Profile_Social_Links>;
  /** delete data from the table: "Profile_statement_audio_syntheses" */
  delete_Profile_statement_audio_syntheses?: Maybe<Profile_Statement_Audio_Syntheses_Mutation_Response>;
  /** delete single row from the table: "Profile_statement_audio_syntheses" */
  delete_Profile_statement_audio_syntheses_by_pk?: Maybe<Profile_Statement_Audio_Syntheses>;
  /** delete data from the table: "Profile_translation" */
  delete_Profile_translation?: Maybe<Profile_Translation_Mutation_Response>;
  /** delete single row from the table: "Profile_translation" */
  delete_Profile_translation_by_pk?: Maybe<Profile_Translation>;
  /** delete data from the table: "Profile_video_links" */
  delete_Profile_video_links?: Maybe<Profile_Video_Links_Mutation_Response>;
  /** delete single row from the table: "Profile_video_links" */
  delete_Profile_video_links_by_pk?: Maybe<Profile_Video_Links>;
  /** delete data from the table: "Profile_wallet_address" */
  delete_Profile_wallet_address?: Maybe<Profile_Wallet_Address_Mutation_Response>;
  /** delete single row from the table: "Profile_wallet_address" */
  delete_Profile_wallet_address_by_pk?: Maybe<Profile_Wallet_Address>;
  /** delete data from the table: "Pubnub_channels" */
  delete_Pubnub_channels?: Maybe<Pubnub_Channels_Mutation_Response>;
  /** delete single row from the table: "Pubnub_channels" */
  delete_Pubnub_channels_by_pk?: Maybe<Pubnub_Channels>;
  /** delete data from the table: "Purchase_inquires" */
  delete_Purchase_inquires?: Maybe<Purchase_Inquires_Mutation_Response>;
  /** delete single row from the table: "Purchase_inquires" */
  delete_Purchase_inquires_by_pk?: Maybe<Purchase_Inquires>;
  /** delete data from the table: "Purchase_inquires_outside_users" */
  delete_Purchase_inquires_outside_users?: Maybe<Purchase_Inquires_Outside_Users_Mutation_Response>;
  /** delete single row from the table: "Purchase_inquires_outside_users" */
  delete_Purchase_inquires_outside_users_by_pk?: Maybe<Purchase_Inquires_Outside_Users>;
  /** delete data from the table: "Push_notifications" */
  delete_Push_notifications?: Maybe<Push_Notifications_Mutation_Response>;
  /** delete single row from the table: "Push_notifications" */
  delete_Push_notifications_by_pk?: Maybe<Push_Notifications>;
  /** delete data from the table: "Questionnaire_questions" */
  delete_Questionnaire_questions?: Maybe<Questionnaire_Questions_Mutation_Response>;
  /** delete single row from the table: "Questionnaire_questions" */
  delete_Questionnaire_questions_by_pk?: Maybe<Questionnaire_Questions>;
  /** delete data from the table: "Render_request" */
  delete_Render_request?: Maybe<Render_Request_Mutation_Response>;
  /** delete single row from the table: "Render_request" */
  delete_Render_request_by_pk?: Maybe<Render_Request>;
  /** delete data from the table: "Setting_context_default_profile" */
  delete_Setting_context_default_profile?: Maybe<Setting_Context_Default_Profile_Mutation_Response>;
  /** delete single row from the table: "Setting_context_default_profile" */
  delete_Setting_context_default_profile_by_pk?: Maybe<Setting_Context_Default_Profile>;
  /** delete data from the table: "Showroom_details" */
  delete_Showroom_details?: Maybe<Showroom_Details_Mutation_Response>;
  /** delete single row from the table: "Showroom_details" */
  delete_Showroom_details_by_pk?: Maybe<Showroom_Details>;
  /** delete data from the table: "Social_graph_followers" */
  delete_Social_graph_followers?: Maybe<Social_Graph_Followers_Mutation_Response>;
  /** delete single row from the table: "Social_graph_followers" */
  delete_Social_graph_followers_by_pk?: Maybe<Social_Graph_Followers>;
  /** delete data from the table: "Spotlight_context" */
  delete_Spotlight_context?: Maybe<Spotlight_Context_Mutation_Response>;
  /** delete single row from the table: "Spotlight_context" */
  delete_Spotlight_context_by_pk?: Maybe<Spotlight_Context>;
  /** delete data from the table: "Super_site_subscribers" */
  delete_Super_site_subscribers?: Maybe<Super_Site_Subscribers_Mutation_Response>;
  /** delete single row from the table: "Super_site_subscribers" */
  delete_Super_site_subscribers_by_pk?: Maybe<Super_Site_Subscribers>;
  /** delete data from the table: "Tags_by_interest" */
  delete_Tags_by_interest?: Maybe<Tags_By_Interest_Mutation_Response>;
  /** delete single row from the table: "Tags_by_interest" */
  delete_Tags_by_interest_by_pk?: Maybe<Tags_By_Interest>;
  /** delete data from the table: "Test_Analytics_events" */
  delete_Test_Analytics_events?: Maybe<Test_Analytics_Events_Mutation_Response>;
  /** delete single row from the table: "Test_Analytics_events" */
  delete_Test_Analytics_events_by_pk?: Maybe<Test_Analytics_Events>;
  /** delete data from the table: "Test_accounts" */
  delete_Test_accounts?: Maybe<Test_Accounts_Mutation_Response>;
  /** delete single row from the table: "Test_accounts" */
  delete_Test_accounts_by_pk?: Maybe<Test_Accounts>;
  /** delete data from the table: "Timed_event" */
  delete_Timed_event?: Maybe<Timed_Event_Mutation_Response>;
  /** delete single row from the table: "Timed_event" */
  delete_Timed_event_by_pk?: Maybe<Timed_Event>;
  /** delete data from the table: "Token_Storage" */
  delete_Token_Storage?: Maybe<Token_Storage_Mutation_Response>;
  /** delete single row from the table: "Token_Storage" */
  delete_Token_Storage_by_pk?: Maybe<Token_Storage>;
  /** delete data from the table: "Tutorial" */
  delete_Tutorial?: Maybe<Tutorial_Mutation_Response>;
  /** delete single row from the table: "Tutorial" */
  delete_Tutorial_by_pk?: Maybe<Tutorial>;
  /** delete data from the table: "Tutorial_step" */
  delete_Tutorial_step?: Maybe<Tutorial_Step_Mutation_Response>;
  /** delete single row from the table: "Tutorial_step" */
  delete_Tutorial_step_by_pk?: Maybe<Tutorial_Step>;
  /** delete data from the table: "User_analytics_points_index" */
  delete_User_analytics_points_index?: Maybe<User_Analytics_Points_Index_Mutation_Response>;
  /** delete single row from the table: "User_analytics_points_index" */
  delete_User_analytics_points_index_by_pk?: Maybe<User_Analytics_Points_Index>;
  /** delete data from the table: "VR_artists" */
  delete_VR_artists?: Maybe<Vr_Artists_Mutation_Response>;
  /** delete single row from the table: "VR_artists" */
  delete_VR_artists_by_pk?: Maybe<Vr_Artists>;
  /** delete data from the table: "Vision_interest" */
  delete_Vision_interest?: Maybe<Vision_Interest_Mutation_Response>;
  /** delete single row from the table: "Vision_interest" */
  delete_Vision_interest_by_pk?: Maybe<Vision_Interest>;
  /** delete data from the table: "Vumarks" */
  delete_Vumarks?: Maybe<Vumarks_Mutation_Response>;
  /** delete single row from the table: "Vumarks" */
  delete_Vumarks_by_pk?: Maybe<Vumarks>;
  /** delete data from the table: "Waiting_list" */
  delete_Waiting_list?: Maybe<Waiting_List_Mutation_Response>;
  /** delete single row from the table: "Waiting_list" */
  delete_Waiting_list_by_pk?: Maybe<Waiting_List>;
  /** delete data from the table: "Wallets" */
  delete_Wallets?: Maybe<Wallets_Mutation_Response>;
  /** delete single row from the table: "Wallets" */
  delete_Wallets_by_pk?: Maybe<Wallets>;
  /** delete data from the table: "World_cities" */
  delete_World_cities?: Maybe<World_Cities_Mutation_Response>;
  /** delete single row from the table: "World_cities" */
  delete_World_cities_by_pk?: Maybe<World_Cities>;
  /** delete data from the table: "app_notifications_info" */
  delete_app_notifications_info?: Maybe<App_Notifications_Info_Mutation_Response>;
  /** delete data from the table: "app_notifications_listing" */
  delete_app_notifications_listing?: Maybe<App_Notifications_Listing_Mutation_Response>;
  /** delete data from the table: "app_notifications_view" */
  delete_app_notifications_view?: Maybe<App_Notifications_View_Mutation_Response>;
  /** delete data from the table: "profile_phones" */
  delete_profile_phones?: Maybe<Profile_Phones_Mutation_Response>;
  /** delete single row from the table: "profile_phones" */
  delete_profile_phones_by_pk?: Maybe<Profile_Phones>;
  /** delete data from the table: "showroom_owner_password" */
  delete_showroom_owner_password?: Maybe<Showroom_Owner_Password_Mutation_Response>;
  /** delete data from the table: "worldcities" */
  delete_worldcities?: Maybe<Worldcities_Mutation_Response>;
  /** delete single row from the table: "worldcities" */
  delete_worldcities_by_pk?: Maybe<Worldcities>;
  disableExistingValidAIAudioStatements: Scalars['Int'];
  domainsMutation: Scalars['Boolean'];
  dummyMutation: Scalars['Int'];
  dummyPlacesMutation: Scalars['Int'];
  editArtistCategories?: Maybe<Scalars['Boolean']>;
  editArtistCollections?: Maybe<Scalars['Boolean']>;
  ensureArtUniverseArtistInterestOrder: Scalars['Int'];
  /**
   * Sets a reader delegate to the Showroom
   *
   * ***Constraints:***
   *
   *   \- Only not expired showrooms.
   *
   *   \- Only not deleted showrooms.
   *
   *   \- Only public showrooms.
   *
   *   \- Only showrooms with owner context id.
   */
  enterShowroomPassword?: Maybe<StatusAndErrorResult>;
  /** Expires an initiated checkout session */
  expireCheckoutActiveCheckoutSessionsForBuyer?: Maybe<Scalars['Boolean']>;
  /**
   * ***Only for Developers***.
   *
   * Finilize an in process transfer.
   */
  finishTransfer?: Maybe<Scalars['Boolean']>;
  firebaseSignUp: RegisterOutputType;
  firebaseSignUpNew: RegisterOutputType;
  firebaseSignUpNewFullContent: RegisterOutputType;
  firebaseSignUpWithVerification: RegisterTokenOutputType;
  fixMissingDomainSubscription: FixMissingDomainSubscriptionResult;
  flagUserIfIsScammerMutation: FlagUserIfIsScammerReturnType;
  /** - Updates gallery collaboration to Accepted status */
  galleryAcceptInvitation: Scalars['Int'];
  /**
   * Inserts the new artwork into Artworks table and associates it to the artist OWNER and CREATED collections for the artist and MANAGED for the gallery
   *
   * ***Constraints:***:
   *
   *   \- Should have a valid gallery and artist context id
   */
  galleryCreateArtwork?: Maybe<CreateArtworkResult>;
  galleryHandleInvitation: Scalars['Int'];
  /**
   * - Send gallery invitations to artist for collaborating.
   *
   * ***Constraints:***:
   *
   *   \- Only when profiles are not yet collaborating
   */
  galleryInviteArtistCollaboration: InvitationResultType;
  /** - Updates gallery collaboration to Rejected status */
  galleryRejectInvitation: Scalars['Int'];
  generateAudioStatement: AudioStatementResult;
  generateCoA?: Maybe<Scalars['bigint']>;
  /** - Sets collaboration code to null to be reprocessed */
  generateProfileCollaborationCode?: Maybe<Scalars['String']>;
  /** - Returns the translated content from db if it exists, otherwise translates it and stores it in db */
  getContentTranslation?: Maybe<Scalars['String']>;
  /** execute VOLATILE function "get_pending_forge_task_procedure" which returns "pending_forge_task_view" */
  get_pending_forge_task_procedure: Array<Pending_Forge_Task_View>;
  grantAchievement: Scalars['Boolean'];
  grantOwner?: Maybe<Scalars['Boolean']>;
  hideArtUniverseArtworks: Scalars['Int'];
  /**
   * Increments the amount of views to the showroom
   *
   * ***Constraints:***
   *
   *   \- Only owner of the collection.
   */
  increaseShowroomViews?: Maybe<Scalars['Boolean']>;
  initChat?: Maybe<Scalars['String']>;
  /**
   * Purchasing artwork is a multi-step process:
   * 1. Put a hold on the card (up to 7 days) for the sum of the artwork price (+ shipping fee margin + currency conversion margin)
   * 2. Check with the artist that the artwork is available and can be shipped
   * 3. Cancel or confirm the transaction
   *   *if shipping fee is more than what we estimated, check with the buyer, put a new hold on the extra sum, before confirming the transaction and making the transfer
   */
  initiateArtworkPurchaseTransaction: InitiateArtworkPurchaseTransactionResult;
  /** Starts the onboarding process for a user (generally an artist) for Stripe Connect so that he can receive money from selling artworks */
  initiateBillingSellerOnboarding: InitiateBillingSellerOnboardingResult;
  initiateCartItemsAnonPurchaseTransaction: InitiateCartItemsAnonPurchaseTransactionResult;
  initiateCartItemsPurchaseTransaction: InitiateCartItemsPurchaseTransactionResult;
  /**
   * Inquires an artwork for purchase by a context id.
   *
   * Inserts a new inquire for the artwork.
   *
   * Sends email notifications for the inquired artwork.
   *
   * ***Constraints:***
   *
   *   \- Only not owner and creator context accepted.
   *
   *   \- If latest inquire has not expired, returns its created date.
   *
   *   \- If artwork hasn't been inquired or latest inquire is expired, inserts a
   *       new inquire with expiration date set to 7 days in the future.
   */
  inquireForPurchaseByLoggedUser?: Maybe<Scalars['String']>;
  /**
   * Inquires an artwork for purchase by an email.
   *
   * Inserts a new inquire for the artwork.
   *
   * Sends email notifications for the inquired artwork.
   *
   * ***Constraints:***
   *
   *   \- If latest inquire has not expired, returns its created date.
   *
   *   \- If artwork hasn't been inquired or latest inquire is expired,
   *       inserts a new inquire.
   */
  inquireForPurchaseByOutsideUser?: Maybe<Scalars['String']>;
  inquireForPurchaseByOutsideUserAction: Scalars['uuid'];
  insertDiscountCodeUsage?: Maybe<Scalars['bigint']>;
  /** - Inserts backgrounds for a profile */
  insertProfileBackgrounds?: Maybe<Scalars['Boolean']>;
  /**
   * - Inserts a new phone number for a profile
   *
   * ***Constraints:***:
   *
   *   \- If a phone number exists and its disable, it enables it
   *
   *   \- If a phone number does not exists, it inserts the new value
   */
  insertProfilePhoneNumber?: Maybe<Scalars['Boolean']>;
  /** insert data into the table: "Account_initial_profile_type" */
  insert_Account_initial_profile_type?: Maybe<Account_Initial_Profile_Type_Mutation_Response>;
  /** insert a single row into the table: "Account_initial_profile_type" */
  insert_Account_initial_profile_type_one?: Maybe<Account_Initial_Profile_Type>;
  /** insert data into the table: "Account_notice_ack" */
  insert_Account_notice_ack?: Maybe<Account_Notice_Ack_Mutation_Response>;
  /** insert a single row into the table: "Account_notice_ack" */
  insert_Account_notice_ack_one?: Maybe<Account_Notice_Ack>;
  /** insert data into the table: "Account_validation" */
  insert_Account_validation?: Maybe<Account_Validation_Mutation_Response>;
  /** insert a single row into the table: "Account_validation" */
  insert_Account_validation_one?: Maybe<Account_Validation>;
  /** insert data into the table: "Achievement_hooks" */
  insert_Achievement_hooks?: Maybe<Achievement_Hooks_Mutation_Response>;
  /** insert a single row into the table: "Achievement_hooks" */
  insert_Achievement_hooks_one?: Maybe<Achievement_Hooks>;
  /** insert data into the table: "Achievement_tags" */
  insert_Achievement_tags?: Maybe<Achievement_Tags_Mutation_Response>;
  /** insert a single row into the table: "Achievement_tags" */
  insert_Achievement_tags_one?: Maybe<Achievement_Tags>;
  /** insert data into the table: "Achievements" */
  insert_Achievements?: Maybe<Achievements_Mutation_Response>;
  /** insert a single row into the table: "Achievements" */
  insert_Achievements_one?: Maybe<Achievements>;
  /** insert data into the table: "Admin_password" */
  insert_Admin_password?: Maybe<Admin_Password_Mutation_Response>;
  /** insert a single row into the table: "Admin_password" */
  insert_Admin_password_one?: Maybe<Admin_Password>;
  /** insert data into the table: "Admin_to_user_notification" */
  insert_Admin_to_user_notification?: Maybe<Admin_To_User_Notification_Mutation_Response>;
  /** insert a single row into the table: "Admin_to_user_notification" */
  insert_Admin_to_user_notification_one?: Maybe<Admin_To_User_Notification>;
  /** insert data into the table: "All_artwork_availability_table" */
  insert_All_artwork_availability_table?: Maybe<All_Artwork_Availability_Table_Mutation_Response>;
  /** insert a single row into the table: "All_artwork_availability_table" */
  insert_All_artwork_availability_table_one?: Maybe<All_Artwork_Availability_Table>;
  /** insert data into the table: "Analytics_events" */
  insert_Analytics_events?: Maybe<Analytics_Events_Mutation_Response>;
  /** insert a single row into the table: "Analytics_events" */
  insert_Analytics_events_one?: Maybe<Analytics_Events>;
  /** insert data into the table: "Analytics_index_history" */
  insert_Analytics_index_history?: Maybe<Analytics_Index_History_Mutation_Response>;
  /** insert a single row into the table: "Analytics_index_history" */
  insert_Analytics_index_history_one?: Maybe<Analytics_Index_History>;
  /** insert data into the table: "Analytics_index_type" */
  insert_Analytics_index_type?: Maybe<Analytics_Index_Type_Mutation_Response>;
  /** insert a single row into the table: "Analytics_index_type" */
  insert_Analytics_index_type_one?: Maybe<Analytics_Index_Type>;
  /** insert data into the table: "Analytics_index_values" */
  insert_Analytics_index_values?: Maybe<Analytics_Index_Values_Mutation_Response>;
  /** insert a single row into the table: "Analytics_index_values" */
  insert_Analytics_index_values_one?: Maybe<Analytics_Index_Values>;
  /** insert data into the table: "Analytics_kaleidoCard_Amounts" */
  insert_Analytics_kaleidoCard_Amounts?: Maybe<Analytics_KaleidoCard_Amounts_Mutation_Response>;
  /** insert a single row into the table: "Analytics_kaleidoCard_Amounts" */
  insert_Analytics_kaleidoCard_Amounts_one?: Maybe<Analytics_KaleidoCard_Amounts>;
  /** insert data into the table: "Analytics_kaleidoCard_events" */
  insert_Analytics_kaleidoCard_events?: Maybe<Analytics_KaleidoCard_Events_Mutation_Response>;
  /** insert a single row into the table: "Analytics_kaleidoCard_events" */
  insert_Analytics_kaleidoCard_events_one?: Maybe<Analytics_KaleidoCard_Events>;
  /** insert data into the table: "App_notifications" */
  insert_App_notifications?: Maybe<App_Notifications_Mutation_Response>;
  /** insert a single row into the table: "App_notifications" */
  insert_App_notifications_one?: Maybe<App_Notifications>;
  /** insert data into the table: "Art_history_movement" */
  insert_Art_history_movement?: Maybe<Art_History_Movement_Mutation_Response>;
  /** insert a single row into the table: "Art_history_movement" */
  insert_Art_history_movement_one?: Maybe<Art_History_Movement>;
  /** insert data into the table: "Art_prize_artwork" */
  insert_Art_prize_artwork?: Maybe<Art_Prize_Artwork_Mutation_Response>;
  /** insert a single row into the table: "Art_prize_artwork" */
  insert_Art_prize_artwork_one?: Maybe<Art_Prize_Artwork>;
  /** insert data into the table: "Art_prize_show" */
  insert_Art_prize_show?: Maybe<Art_Prize_Show_Mutation_Response>;
  /** insert a single row into the table: "Art_prize_show" */
  insert_Art_prize_show_one?: Maybe<Art_Prize_Show>;
  /** insert data into the table: "Art_prize_show_winners" */
  insert_Art_prize_show_winners?: Maybe<Art_Prize_Show_Winners_Mutation_Response>;
  /** insert a single row into the table: "Art_prize_show_winners" */
  insert_Art_prize_show_winners_one?: Maybe<Art_Prize_Show_Winners>;
  /** insert data into the table: "Art_prize_winner" */
  insert_Art_prize_winner?: Maybe<Art_Prize_Winner_Mutation_Response>;
  /** insert a single row into the table: "Art_prize_winner" */
  insert_Art_prize_winner_one?: Maybe<Art_Prize_Winner>;
  /** insert data into the table: "Art_prizes_links_filtered" */
  insert_Art_prizes_links_filtered?: Maybe<Art_Prizes_Links_Filtered_Mutation_Response>;
  /** insert a single row into the table: "Art_prizes_links_filtered" */
  insert_Art_prizes_links_filtered_one?: Maybe<Art_Prizes_Links_Filtered>;
  /** insert data into the table: "Art_show_details" */
  insert_Art_show_details?: Maybe<Art_Show_Details_Mutation_Response>;
  /** insert a single row into the table: "Art_show_details" */
  insert_Art_show_details_one?: Maybe<Art_Show_Details>;
  /** insert data into the table: "Art_type_to_Artwork_discipline" */
  insert_Art_type_to_Artwork_discipline?: Maybe<Art_Type_To_Artwork_Discipline_Mutation_Response>;
  /** insert a single row into the table: "Art_type_to_Artwork_discipline" */
  insert_Art_type_to_Artwork_discipline_one?: Maybe<Art_Type_To_Artwork_Discipline>;
  /** insert data into the table: "Artist_Collection" */
  insert_Artist_Collection?: Maybe<Artist_Collection_Mutation_Response>;
  /** insert data into the table: "Artist_Collection_Categories" */
  insert_Artist_Collection_Categories?: Maybe<Artist_Collection_Categories_Mutation_Response>;
  /** insert a single row into the table: "Artist_Collection_Categories" */
  insert_Artist_Collection_Categories_one?: Maybe<Artist_Collection_Categories>;
  /** insert a single row into the table: "Artist_Collection" */
  insert_Artist_Collection_one?: Maybe<Artist_Collection>;
  /** insert data into the table: "Artist_Collection_to_Artist_Context" */
  insert_Artist_Collection_to_Artist_Context?: Maybe<Artist_Collection_To_Artist_Context_Mutation_Response>;
  /** insert a single row into the table: "Artist_Collection_to_Artist_Context" */
  insert_Artist_Collection_to_Artist_Context_one?: Maybe<Artist_Collection_To_Artist_Context>;
  /** insert data into the table: "Artist_art_history_visibility" */
  insert_Artist_art_history_visibility?: Maybe<Artist_Art_History_Visibility_Mutation_Response>;
  /** insert a single row into the table: "Artist_art_history_visibility" */
  insert_Artist_art_history_visibility_one?: Maybe<Artist_Art_History_Visibility>;
  /** insert data into the table: "Artist_curation_score" */
  insert_Artist_curation_score?: Maybe<Artist_Curation_Score_Mutation_Response>;
  /** insert a single row into the table: "Artist_curation_score" */
  insert_Artist_curation_score_one?: Maybe<Artist_Curation_Score>;
  /** insert data into the table: "Artist_explore_visibility" */
  insert_Artist_explore_visibility?: Maybe<Artist_Explore_Visibility_Mutation_Response>;
  /** insert a single row into the table: "Artist_explore_visibility" */
  insert_Artist_explore_visibility_one?: Maybe<Artist_Explore_Visibility>;
  /** insert data into the table: "Artist_links_filtered" */
  insert_Artist_links_filtered?: Maybe<Artist_Links_Filtered_Mutation_Response>;
  /** insert a single row into the table: "Artist_links_filtered" */
  insert_Artist_links_filtered_one?: Maybe<Artist_Links_Filtered>;
  /** insert data into the table: "Artist_newsletter" */
  insert_Artist_newsletter?: Maybe<Artist_Newsletter_Mutation_Response>;
  /** insert a single row into the table: "Artist_newsletter" */
  insert_Artist_newsletter_one?: Maybe<Artist_Newsletter>;
  /** insert data into the table: "Artist_vision_visibility" */
  insert_Artist_vision_visibility?: Maybe<Artist_Vision_Visibility_Mutation_Response>;
  /** insert a single row into the table: "Artist_vision_visibility" */
  insert_Artist_vision_visibility_one?: Maybe<Artist_Vision_Visibility>;
  /** insert data into the table: "Artwork" */
  insert_Artwork?: Maybe<Artwork_Mutation_Response>;
  /** insert data into the table: "Artwork_ai_tags" */
  insert_Artwork_ai_tags?: Maybe<Artwork_Ai_Tags_Mutation_Response>;
  /** insert a single row into the table: "Artwork_ai_tags" */
  insert_Artwork_ai_tags_one?: Maybe<Artwork_Ai_Tags>;
  /** insert data into the table: "Artwork_art_history_timetable" */
  insert_Artwork_art_history_timetable?: Maybe<Artwork_Art_History_Timetable_Mutation_Response>;
  /** insert a single row into the table: "Artwork_art_history_timetable" */
  insert_Artwork_art_history_timetable_one?: Maybe<Artwork_Art_History_Timetable>;
  /** insert data into the table: "Artwork_aspect_ratio" */
  insert_Artwork_aspect_ratio?: Maybe<Artwork_Aspect_Ratio_Mutation_Response>;
  /** insert a single row into the table: "Artwork_aspect_ratio" */
  insert_Artwork_aspect_ratio_one?: Maybe<Artwork_Aspect_Ratio>;
  /** insert data into the table: "Artwork_count_milestones" */
  insert_Artwork_count_milestones?: Maybe<Artwork_Count_Milestones_Mutation_Response>;
  /** insert a single row into the table: "Artwork_count_milestones" */
  insert_Artwork_count_milestones_one?: Maybe<Artwork_Count_Milestones>;
  /** insert data into the table: "Artwork_curation_score" */
  insert_Artwork_curation_score?: Maybe<Artwork_Curation_Score_Mutation_Response>;
  /** insert a single row into the table: "Artwork_curation_score" */
  insert_Artwork_curation_score_one?: Maybe<Artwork_Curation_Score>;
  /** insert data into the table: "Artwork_details" */
  insert_Artwork_details?: Maybe<Artwork_Details_Mutation_Response>;
  /** insert a single row into the table: "Artwork_details" */
  insert_Artwork_details_one?: Maybe<Artwork_Details>;
  /** insert data into the table: "Artwork_details_translation" */
  insert_Artwork_details_translation?: Maybe<Artwork_Details_Translation_Mutation_Response>;
  /** insert a single row into the table: "Artwork_details_translation" */
  insert_Artwork_details_translation_one?: Maybe<Artwork_Details_Translation>;
  /** insert data into the table: "Artwork_digital_twin" */
  insert_Artwork_digital_twin?: Maybe<Artwork_Digital_Twin_Mutation_Response>;
  /** insert a single row into the table: "Artwork_digital_twin" */
  insert_Artwork_digital_twin_one?: Maybe<Artwork_Digital_Twin>;
  /** insert data into the table: "Artwork_events" */
  insert_Artwork_events?: Maybe<Artwork_Events_Mutation_Response>;
  /** insert a single row into the table: "Artwork_events" */
  insert_Artwork_events_one?: Maybe<Artwork_Events>;
  /** insert data into the table: "Artwork_external_buy_link" */
  insert_Artwork_external_buy_link?: Maybe<Artwork_External_Buy_Link_Mutation_Response>;
  /** insert a single row into the table: "Artwork_external_buy_link" */
  insert_Artwork_external_buy_link_one?: Maybe<Artwork_External_Buy_Link>;
  /** insert a single row into the table: "Artwork" */
  insert_Artwork_one?: Maybe<Artwork>;
  /** insert data into the table: "Artwork_prices" */
  insert_Artwork_prices?: Maybe<Artwork_Prices_Mutation_Response>;
  /** insert a single row into the table: "Artwork_prices" */
  insert_Artwork_prices_one?: Maybe<Artwork_Prices>;
  /** insert data into the table: "Artwork_purchase_journey" */
  insert_Artwork_purchase_journey?: Maybe<Artwork_Purchase_Journey_Mutation_Response>;
  /** insert data into the table: "Artwork_purchase_journey_history" */
  insert_Artwork_purchase_journey_history?: Maybe<Artwork_Purchase_Journey_History_Mutation_Response>;
  /** insert a single row into the table: "Artwork_purchase_journey_history" */
  insert_Artwork_purchase_journey_history_one?: Maybe<Artwork_Purchase_Journey_History>;
  /** insert data into the table: "Artwork_purchase_journey_last_update" */
  insert_Artwork_purchase_journey_last_update?: Maybe<Artwork_Purchase_Journey_Last_Update_Mutation_Response>;
  /** insert a single row into the table: "Artwork_purchase_journey_last_update" */
  insert_Artwork_purchase_journey_last_update_one?: Maybe<Artwork_Purchase_Journey_Last_Update>;
  /** insert a single row into the table: "Artwork_purchase_journey" */
  insert_Artwork_purchase_journey_one?: Maybe<Artwork_Purchase_Journey>;
  /** insert data into the table: "Artwork_purchase_journey_pricing" */
  insert_Artwork_purchase_journey_pricing?: Maybe<Artwork_Purchase_Journey_Pricing_Mutation_Response>;
  /** insert a single row into the table: "Artwork_purchase_journey_pricing" */
  insert_Artwork_purchase_journey_pricing_one?: Maybe<Artwork_Purchase_Journey_Pricing>;
  /** insert data into the table: "Artwork_purchase_journey_shipping_info" */
  insert_Artwork_purchase_journey_shipping_info?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Mutation_Response>;
  /** insert a single row into the table: "Artwork_purchase_journey_shipping_info" */
  insert_Artwork_purchase_journey_shipping_info_one?: Maybe<Artwork_Purchase_Journey_Shipping_Info>;
  /** insert data into the table: "Artwork_secondary_media" */
  insert_Artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Mutation_Response>;
  /** insert a single row into the table: "Artwork_secondary_media" */
  insert_Artwork_secondary_media_one?: Maybe<Artwork_Secondary_Media>;
  /** insert data into the table: "Artwork_state_history" */
  insert_Artwork_state_history?: Maybe<Artwork_State_History_Mutation_Response>;
  /** insert a single row into the table: "Artwork_state_history" */
  insert_Artwork_state_history_one?: Maybe<Artwork_State_History>;
  /** insert data into the table: "Artwork_story" */
  insert_Artwork_story?: Maybe<Artwork_Story_Mutation_Response>;
  /** insert data into the table: "Artwork_story_media" */
  insert_Artwork_story_media?: Maybe<Artwork_Story_Media_Mutation_Response>;
  /** insert a single row into the table: "Artwork_story_media" */
  insert_Artwork_story_media_one?: Maybe<Artwork_Story_Media>;
  /** insert a single row into the table: "Artwork_story" */
  insert_Artwork_story_one?: Maybe<Artwork_Story>;
  /** insert data into the table: "Artwork_story_video_links" */
  insert_Artwork_story_video_links?: Maybe<Artwork_Story_Video_Links_Mutation_Response>;
  /** insert a single row into the table: "Artwork_story_video_links" */
  insert_Artwork_story_video_links_one?: Maybe<Artwork_Story_Video_Links>;
  /** insert data into the table: "Artwork_tag" */
  insert_Artwork_tag?: Maybe<Artwork_Tag_Mutation_Response>;
  /** insert a single row into the table: "Artwork_tag" */
  insert_Artwork_tag_one?: Maybe<Artwork_Tag>;
  /** insert data into the table: "Artwork_to_Collection" */
  insert_Artwork_to_Collection?: Maybe<Artwork_To_Collection_Mutation_Response>;
  /** insert a single row into the table: "Artwork_to_Collection" */
  insert_Artwork_to_Collection_one?: Maybe<Artwork_To_Collection>;
  /** insert data into the table: "Artwork_to_showroom_details" */
  insert_Artwork_to_showroom_details?: Maybe<Artwork_To_Showroom_Details_Mutation_Response>;
  /** insert a single row into the table: "Artwork_to_showroom_details" */
  insert_Artwork_to_showroom_details_one?: Maybe<Artwork_To_Showroom_Details>;
  /** insert data into the table: "Artwork_to_tag" */
  insert_Artwork_to_tag?: Maybe<Artwork_To_Tag_Mutation_Response>;
  /** insert a single row into the table: "Artwork_to_tag" */
  insert_Artwork_to_tag_one?: Maybe<Artwork_To_Tag>;
  /** insert data into the table: "Artwork_transactions" */
  insert_Artwork_transactions?: Maybe<Artwork_Transactions_Mutation_Response>;
  /** insert a single row into the table: "Artwork_transactions" */
  insert_Artwork_transactions_one?: Maybe<Artwork_Transactions>;
  /** insert data into the table: "Auth" */
  insert_Auth?: Maybe<Auth_Mutation_Response>;
  /** insert data into the table: "Auth_context_access" */
  insert_Auth_context_access?: Maybe<Auth_Context_Access_Mutation_Response>;
  /** insert a single row into the table: "Auth_context_access" */
  insert_Auth_context_access_one?: Maybe<Auth_Context_Access>;
  /** insert a single row into the table: "Auth" */
  insert_Auth_one?: Maybe<Auth>;
  /** insert data into the table: "Available_print_price" */
  insert_Available_print_price?: Maybe<Available_Print_Price_Mutation_Response>;
  /** insert a single row into the table: "Available_print_price" */
  insert_Available_print_price_one?: Maybe<Available_Print_Price>;
  /** insert data into the table: "Billing_anon_client" */
  insert_Billing_anon_client?: Maybe<Billing_Anon_Client_Mutation_Response>;
  /** insert a single row into the table: "Billing_anon_client" */
  insert_Billing_anon_client_one?: Maybe<Billing_Anon_Client>;
  /** insert data into the table: "Billing_artwork_payment_intent" */
  insert_Billing_artwork_payment_intent?: Maybe<Billing_Artwork_Payment_Intent_Mutation_Response>;
  /** insert a single row into the table: "Billing_artwork_payment_intent" */
  insert_Billing_artwork_payment_intent_one?: Maybe<Billing_Artwork_Payment_Intent>;
  /** insert data into the table: "Billing_artwork_payment_intent_product" */
  insert_Billing_artwork_payment_intent_product?: Maybe<Billing_Artwork_Payment_Intent_Product_Mutation_Response>;
  /** insert a single row into the table: "Billing_artwork_payment_intent_product" */
  insert_Billing_artwork_payment_intent_product_one?: Maybe<Billing_Artwork_Payment_Intent_Product>;
  /** insert data into the table: "Billing_artwork_payment_intent_shipping_address" */
  insert_Billing_artwork_payment_intent_shipping_address?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Mutation_Response>;
  /** insert a single row into the table: "Billing_artwork_payment_intent_shipping_address" */
  insert_Billing_artwork_payment_intent_shipping_address_one?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address>;
  /** insert data into the table: "Billing_artwork_payment_intent_status_history" */
  insert_Billing_artwork_payment_intent_status_history?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Mutation_Response>;
  /** insert a single row into the table: "Billing_artwork_payment_intent_status_history" */
  insert_Billing_artwork_payment_intent_status_history_one?: Maybe<Billing_Artwork_Payment_Intent_Status_History>;
  /** insert data into the table: "Billing_client" */
  insert_Billing_client?: Maybe<Billing_Client_Mutation_Response>;
  /** insert a single row into the table: "Billing_client" */
  insert_Billing_client_one?: Maybe<Billing_Client>;
  /** insert data into the table: "Billing_seller" */
  insert_Billing_seller?: Maybe<Billing_Seller_Mutation_Response>;
  /** insert a single row into the table: "Billing_seller" */
  insert_Billing_seller_one?: Maybe<Billing_Seller>;
  /** insert data into the table: "Billing_subscription" */
  insert_Billing_subscription?: Maybe<Billing_Subscription_Mutation_Response>;
  /** insert a single row into the table: "Billing_subscription" */
  insert_Billing_subscription_one?: Maybe<Billing_Subscription>;
  /** insert data into the table: "Billing_subscription_product" */
  insert_Billing_subscription_product?: Maybe<Billing_Subscription_Product_Mutation_Response>;
  /** insert a single row into the table: "Billing_subscription_product" */
  insert_Billing_subscription_product_one?: Maybe<Billing_Subscription_Product>;
  /** insert data into the table: "Biolink_sections" */
  insert_Biolink_sections?: Maybe<Biolink_Sections_Mutation_Response>;
  /** insert a single row into the table: "Biolink_sections" */
  insert_Biolink_sections_one?: Maybe<Biolink_Sections>;
  /** insert data into the table: "Certificate_of_authenticity" */
  insert_Certificate_of_authenticity?: Maybe<Certificate_Of_Authenticity_Mutation_Response>;
  /** insert a single row into the table: "Certificate_of_authenticity" */
  insert_Certificate_of_authenticity_one?: Maybe<Certificate_Of_Authenticity>;
  /** insert data into the table: "Collaboration" */
  insert_Collaboration?: Maybe<Collaboration_Mutation_Response>;
  /** insert data into the table: "Collaboration_invitation_on_artwork" */
  insert_Collaboration_invitation_on_artwork?: Maybe<Collaboration_Invitation_On_Artwork_Mutation_Response>;
  /** insert a single row into the table: "Collaboration_invitation_on_artwork" */
  insert_Collaboration_invitation_on_artwork_one?: Maybe<Collaboration_Invitation_On_Artwork>;
  /** insert a single row into the table: "Collaboration" */
  insert_Collaboration_one?: Maybe<Collaboration>;
  /** insert data into the table: "Collection" */
  insert_Collection?: Maybe<Collection_Mutation_Response>;
  /** insert a single row into the table: "Collection" */
  insert_Collection_one?: Maybe<Collection>;
  /** insert data into the table: "Collection_translation" */
  insert_Collection_translation?: Maybe<Collection_Translation_Mutation_Response>;
  /** insert a single row into the table: "Collection_translation" */
  insert_Collection_translation_one?: Maybe<Collection_Translation>;
  /** insert data into the table: "Collection_video_links" */
  insert_Collection_video_links?: Maybe<Collection_Video_Links_Mutation_Response>;
  /** insert a single row into the table: "Collection_video_links" */
  insert_Collection_video_links_one?: Maybe<Collection_Video_Links>;
  /** insert data into the table: "Collections_links_filtered" */
  insert_Collections_links_filtered?: Maybe<Collections_Links_Filtered_Mutation_Response>;
  /** insert a single row into the table: "Collections_links_filtered" */
  insert_Collections_links_filtered_one?: Maybe<Collections_Links_Filtered>;
  /** insert data into the table: "Collector_links_filtered" */
  insert_Collector_links_filtered?: Maybe<Collector_Links_Filtered_Mutation_Response>;
  /** insert a single row into the table: "Collector_links_filtered" */
  insert_Collector_links_filtered_one?: Maybe<Collector_Links_Filtered>;
  /** insert data into the table: "Connected_domain" */
  insert_Connected_domain?: Maybe<Connected_Domain_Mutation_Response>;
  /** insert a single row into the table: "Connected_domain" */
  insert_Connected_domain_one?: Maybe<Connected_Domain>;
  /** insert data into the table: "Content_translations" */
  insert_Content_translations?: Maybe<Content_Translations_Mutation_Response>;
  /** insert a single row into the table: "Content_translations" */
  insert_Content_translations_one?: Maybe<Content_Translations>;
  /** insert data into the table: "Context" */
  insert_Context?: Maybe<Context_Mutation_Response>;
  /** insert data into the table: "Context_delegate_access" */
  insert_Context_delegate_access?: Maybe<Context_Delegate_Access_Mutation_Response>;
  /** insert a single row into the table: "Context_delegate_access" */
  insert_Context_delegate_access_one?: Maybe<Context_Delegate_Access>;
  /** insert data into the table: "Context_flags" */
  insert_Context_flags?: Maybe<Context_Flags_Mutation_Response>;
  /** insert a single row into the table: "Context_flags" */
  insert_Context_flags_one?: Maybe<Context_Flags>;
  /** insert a single row into the table: "Context" */
  insert_Context_one?: Maybe<Context>;
  /** insert data into the table: "Context_suggested_tags" */
  insert_Context_suggested_tags?: Maybe<Context_Suggested_Tags_Mutation_Response>;
  /** insert a single row into the table: "Context_suggested_tags" */
  insert_Context_suggested_tags_one?: Maybe<Context_Suggested_Tags>;
  /** insert data into the table: "Context_to_milestone" */
  insert_Context_to_milestone?: Maybe<Context_To_Milestone_Mutation_Response>;
  /** insert a single row into the table: "Context_to_milestone" */
  insert_Context_to_milestone_one?: Maybe<Context_To_Milestone>;
  /** insert data into the table: "Context_to_tutorial" */
  insert_Context_to_tutorial?: Maybe<Context_To_Tutorial_Mutation_Response>;
  /** insert a single row into the table: "Context_to_tutorial" */
  insert_Context_to_tutorial_one?: Maybe<Context_To_Tutorial>;
  /** insert data into the table: "Countries" */
  insert_Countries?: Maybe<Countries_Mutation_Response>;
  /** insert a single row into the table: "Countries" */
  insert_Countries_one?: Maybe<Countries>;
  /** insert data into the table: "Crop_Area" */
  insert_Crop_Area?: Maybe<Crop_Area_Mutation_Response>;
  /** insert a single row into the table: "Crop_Area" */
  insert_Crop_Area_one?: Maybe<Crop_Area>;
  /** insert data into the table: "Currency_conversion" */
  insert_Currency_conversion?: Maybe<Currency_Conversion_Mutation_Response>;
  /** insert a single row into the table: "Currency_conversion" */
  insert_Currency_conversion_one?: Maybe<Currency_Conversion>;
  /** insert data into the table: "Digital_twin_details" */
  insert_Digital_twin_details?: Maybe<Digital_Twin_Details_Mutation_Response>;
  /** insert a single row into the table: "Digital_twin_details" */
  insert_Digital_twin_details_one?: Maybe<Digital_Twin_Details>;
  /** insert data into the table: "Discount_code" */
  insert_Discount_code?: Maybe<Discount_Code_Mutation_Response>;
  /** insert a single row into the table: "Discount_code" */
  insert_Discount_code_one?: Maybe<Discount_Code>;
  /** insert data into the table: "Discount_code_usage" */
  insert_Discount_code_usage?: Maybe<Discount_Code_Usage_Mutation_Response>;
  /** insert a single row into the table: "Discount_code_usage" */
  insert_Discount_code_usage_one?: Maybe<Discount_Code_Usage>;
  /** insert data into the table: "Domain_registrant_info" */
  insert_Domain_registrant_info?: Maybe<Domain_Registrant_Info_Mutation_Response>;
  /** insert a single row into the table: "Domain_registrant_info" */
  insert_Domain_registrant_info_one?: Maybe<Domain_Registrant_Info>;
  /** insert data into the table: "Domain_registration" */
  insert_Domain_registration?: Maybe<Domain_Registration_Mutation_Response>;
  /** insert a single row into the table: "Domain_registration" */
  insert_Domain_registration_one?: Maybe<Domain_Registration>;
  /** insert data into the table: "Dummy_table_for_front" */
  insert_Dummy_table_for_front?: Maybe<Dummy_Table_For_Front_Mutation_Response>;
  /** insert a single row into the table: "Dummy_table_for_front" */
  insert_Dummy_table_for_front_one?: Maybe<Dummy_Table_For_Front>;
  /** insert data into the table: "Dynamic_cv" */
  insert_Dynamic_cv?: Maybe<Dynamic_Cv_Mutation_Response>;
  /** insert data into the table: "Dynamic_cv_award" */
  insert_Dynamic_cv_award?: Maybe<Dynamic_Cv_Award_Mutation_Response>;
  /** insert a single row into the table: "Dynamic_cv_award" */
  insert_Dynamic_cv_award_one?: Maybe<Dynamic_Cv_Award>;
  /** insert data into the table: "Dynamic_cv_collection" */
  insert_Dynamic_cv_collection?: Maybe<Dynamic_Cv_Collection_Mutation_Response>;
  /** insert a single row into the table: "Dynamic_cv_collection" */
  insert_Dynamic_cv_collection_one?: Maybe<Dynamic_Cv_Collection>;
  /** insert data into the table: "Dynamic_cv_contact" */
  insert_Dynamic_cv_contact?: Maybe<Dynamic_Cv_Contact_Mutation_Response>;
  /** insert a single row into the table: "Dynamic_cv_contact" */
  insert_Dynamic_cv_contact_one?: Maybe<Dynamic_Cv_Contact>;
  /** insert data into the table: "Dynamic_cv_education" */
  insert_Dynamic_cv_education?: Maybe<Dynamic_Cv_Education_Mutation_Response>;
  /** insert a single row into the table: "Dynamic_cv_education" */
  insert_Dynamic_cv_education_one?: Maybe<Dynamic_Cv_Education>;
  /** insert data into the table: "Dynamic_cv_exhibition" */
  insert_Dynamic_cv_exhibition?: Maybe<Dynamic_Cv_Exhibition_Mutation_Response>;
  /** insert a single row into the table: "Dynamic_cv_exhibition" */
  insert_Dynamic_cv_exhibition_one?: Maybe<Dynamic_Cv_Exhibition>;
  /** insert a single row into the table: "Dynamic_cv" */
  insert_Dynamic_cv_one?: Maybe<Dynamic_Cv>;
  /** insert data into the table: "Dynamic_cv_publication" */
  insert_Dynamic_cv_publication?: Maybe<Dynamic_Cv_Publication_Mutation_Response>;
  /** insert a single row into the table: "Dynamic_cv_publication" */
  insert_Dynamic_cv_publication_one?: Maybe<Dynamic_Cv_Publication>;
  /** insert data into the table: "Dynamic_cv_section" */
  insert_Dynamic_cv_section?: Maybe<Dynamic_Cv_Section_Mutation_Response>;
  /** insert a single row into the table: "Dynamic_cv_section" */
  insert_Dynamic_cv_section_one?: Maybe<Dynamic_Cv_Section>;
  /** insert data into the table: "ENUM_account_notice_type" */
  insert_ENUM_account_notice_type?: Maybe<Enum_Account_Notice_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_account_notice_type" */
  insert_ENUM_account_notice_type_one?: Maybe<Enum_Account_Notice_Type>;
  /** insert data into the table: "ENUM_achievement_tags" */
  insert_ENUM_achievement_tags?: Maybe<Enum_Achievement_Tags_Mutation_Response>;
  /** insert a single row into the table: "ENUM_achievement_tags" */
  insert_ENUM_achievement_tags_one?: Maybe<Enum_Achievement_Tags>;
  /** insert data into the table: "ENUM_add_artwork_flow_step" */
  insert_ENUM_add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Mutation_Response>;
  /** insert a single row into the table: "ENUM_add_artwork_flow_step" */
  insert_ENUM_add_artwork_flow_step_one?: Maybe<Enum_Add_Artwork_Flow_Step>;
  /** insert data into the table: "ENUM_analytics_kaleidoCard_category" */
  insert_ENUM_analytics_kaleidoCard_category?: Maybe<Enum_Analytics_KaleidoCard_Category_Mutation_Response>;
  /** insert a single row into the table: "ENUM_analytics_kaleidoCard_category" */
  insert_ENUM_analytics_kaleidoCard_category_one?: Maybe<Enum_Analytics_KaleidoCard_Category>;
  /** insert data into the table: "ENUM_analytics_type" */
  insert_ENUM_analytics_type?: Maybe<Enum_Analytics_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_analytics_type" */
  insert_ENUM_analytics_type_one?: Maybe<Enum_Analytics_Type>;
  /** insert data into the table: "ENUM_app_notification_type" */
  insert_ENUM_app_notification_type?: Maybe<Enum_App_Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_app_notification_type" */
  insert_ENUM_app_notification_type_one?: Maybe<Enum_App_Notification_Type>;
  /** insert data into the table: "ENUM_art_type" */
  insert_ENUM_art_type?: Maybe<Enum_Art_Type_Mutation_Response>;
  /** insert data into the table: "ENUM_art_type_extension" */
  insert_ENUM_art_type_extension?: Maybe<Enum_Art_Type_Extension_Mutation_Response>;
  /** insert a single row into the table: "ENUM_art_type_extension" */
  insert_ENUM_art_type_extension_one?: Maybe<Enum_Art_Type_Extension>;
  /** insert a single row into the table: "ENUM_art_type" */
  insert_ENUM_art_type_one?: Maybe<Enum_Art_Type>;
  /** insert data into the table: "ENUM_artist_collection_categories" */
  insert_ENUM_artist_collection_categories?: Maybe<Enum_Artist_Collection_Categories_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artist_collection_categories" */
  insert_ENUM_artist_collection_categories_one?: Maybe<Enum_Artist_Collection_Categories>;
  /** insert data into the table: "ENUM_artist_collection_state" */
  insert_ENUM_artist_collection_state?: Maybe<Enum_Artist_Collection_State_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artist_collection_state" */
  insert_ENUM_artist_collection_state_one?: Maybe<Enum_Artist_Collection_State>;
  /** insert data into the table: "ENUM_artist_collection_type" */
  insert_ENUM_artist_collection_type?: Maybe<Enum_Artist_Collection_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artist_collection_type" */
  insert_ENUM_artist_collection_type_one?: Maybe<Enum_Artist_Collection_Type>;
  /** insert data into the table: "ENUM_artwork_category" */
  insert_ENUM_artwork_category?: Maybe<Enum_Artwork_Category_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_category" */
  insert_ENUM_artwork_category_one?: Maybe<Enum_Artwork_Category>;
  /** insert data into the table: "ENUM_artwork_count_milestone_type" */
  insert_ENUM_artwork_count_milestone_type?: Maybe<Enum_Artwork_Count_Milestone_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_count_milestone_type" */
  insert_ENUM_artwork_count_milestone_type_one?: Maybe<Enum_Artwork_Count_Milestone_Type>;
  /** insert data into the table: "ENUM_artwork_discipline" */
  insert_ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_discipline" */
  insert_ENUM_artwork_discipline_one?: Maybe<Enum_Artwork_Discipline>;
  /** insert data into the table: "ENUM_artwork_edition_type" */
  insert_ENUM_artwork_edition_type?: Maybe<Enum_Artwork_Edition_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_edition_type" */
  insert_ENUM_artwork_edition_type_one?: Maybe<Enum_Artwork_Edition_Type>;
  /** insert data into the table: "ENUM_artwork_event_type" */
  insert_ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_event_type" */
  insert_ENUM_artwork_event_type_one?: Maybe<Enum_Artwork_Event_Type>;
  /** insert data into the table: "ENUM_artwork_genre" */
  insert_ENUM_artwork_genre?: Maybe<Enum_Artwork_Genre_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_genre" */
  insert_ENUM_artwork_genre_one?: Maybe<Enum_Artwork_Genre>;
  /** insert data into the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  insert_ENUM_artwork_purchase_journey_history_entry_kind?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  insert_ENUM_artwork_purchase_journey_history_entry_kind_one?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind>;
  /** insert data into the table: "ENUM_artwork_purchase_journey_party" */
  insert_ENUM_artwork_purchase_journey_party?: Maybe<Enum_Artwork_Purchase_Journey_Party_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_purchase_journey_party" */
  insert_ENUM_artwork_purchase_journey_party_one?: Maybe<Enum_Artwork_Purchase_Journey_Party>;
  /** insert data into the table: "ENUM_artwork_purchase_journey_status" */
  insert_ENUM_artwork_purchase_journey_status?: Maybe<Enum_Artwork_Purchase_Journey_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_purchase_journey_status" */
  insert_ENUM_artwork_purchase_journey_status_one?: Maybe<Enum_Artwork_Purchase_Journey_Status>;
  /** insert data into the table: "ENUM_artwork_showroom_status" */
  insert_ENUM_artwork_showroom_status?: Maybe<Enum_Artwork_Showroom_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_showroom_status" */
  insert_ENUM_artwork_showroom_status_one?: Maybe<Enum_Artwork_Showroom_Status>;
  /** insert data into the table: "ENUM_artwork_status" */
  insert_ENUM_artwork_status?: Maybe<Enum_Artwork_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_status" */
  insert_ENUM_artwork_status_one?: Maybe<Enum_Artwork_Status>;
  /** insert data into the table: "ENUM_artwork_tag" */
  insert_ENUM_artwork_tag?: Maybe<Enum_Artwork_Tag_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_tag" */
  insert_ENUM_artwork_tag_one?: Maybe<Enum_Artwork_Tag>;
  /** insert data into the table: "ENUM_artwork_tag_type" */
  insert_ENUM_artwork_tag_type?: Maybe<Enum_Artwork_Tag_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_tag_type" */
  insert_ENUM_artwork_tag_type_one?: Maybe<Enum_Artwork_Tag_Type>;
  /** insert data into the table: "ENUM_artwork_type" */
  insert_ENUM_artwork_type?: Maybe<Enum_Artwork_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_artwork_type" */
  insert_ENUM_artwork_type_one?: Maybe<Enum_Artwork_Type>;
  /** insert data into the table: "ENUM_audio_generation_status" */
  insert_ENUM_audio_generation_status?: Maybe<Enum_Audio_Generation_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_audio_generation_status" */
  insert_ENUM_audio_generation_status_one?: Maybe<Enum_Audio_Generation_Status>;
  /** insert data into the table: "ENUM_auth_type" */
  insert_ENUM_auth_type?: Maybe<Enum_Auth_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_auth_type" */
  insert_ENUM_auth_type_one?: Maybe<Enum_Auth_Type>;
  /** insert data into the table: "ENUM_billing_artwork_payment_intent_status" */
  insert_ENUM_billing_artwork_payment_intent_status?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_billing_artwork_payment_intent_status" */
  insert_ENUM_billing_artwork_payment_intent_status_one?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status>;
  /** insert data into the table: "ENUM_billing_payment_intent_product_status" */
  insert_ENUM_billing_payment_intent_product_status?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_billing_payment_intent_product_status" */
  insert_ENUM_billing_payment_intent_product_status_one?: Maybe<Enum_Billing_Payment_Intent_Product_Status>;
  /** insert data into the table: "ENUM_billing_seller_status" */
  insert_ENUM_billing_seller_status?: Maybe<Enum_Billing_Seller_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_billing_seller_status" */
  insert_ENUM_billing_seller_status_one?: Maybe<Enum_Billing_Seller_Status>;
  /** insert data into the table: "ENUM_billing_subscription_period" */
  insert_ENUM_billing_subscription_period?: Maybe<Enum_Billing_Subscription_Period_Mutation_Response>;
  /** insert a single row into the table: "ENUM_billing_subscription_period" */
  insert_ENUM_billing_subscription_period_one?: Maybe<Enum_Billing_Subscription_Period>;
  /** insert data into the table: "ENUM_billing_subscription_product_type" */
  insert_ENUM_billing_subscription_product_type?: Maybe<Enum_Billing_Subscription_Product_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_billing_subscription_product_type" */
  insert_ENUM_billing_subscription_product_type_one?: Maybe<Enum_Billing_Subscription_Product_Type>;
  /** insert data into the table: "ENUM_billing_subscription_status" */
  insert_ENUM_billing_subscription_status?: Maybe<Enum_Billing_Subscription_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_billing_subscription_status" */
  insert_ENUM_billing_subscription_status_one?: Maybe<Enum_Billing_Subscription_Status>;
  /** insert data into the table: "ENUM_blockchain" */
  insert_ENUM_blockchain?: Maybe<Enum_Blockchain_Mutation_Response>;
  /** insert a single row into the table: "ENUM_blockchain" */
  insert_ENUM_blockchain_one?: Maybe<Enum_Blockchain>;
  /** insert data into the table: "ENUM_collaboration_status" */
  insert_ENUM_collaboration_status?: Maybe<Enum_Collaboration_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_collaboration_status" */
  insert_ENUM_collaboration_status_one?: Maybe<Enum_Collaboration_Status>;
  /** insert data into the table: "ENUM_collection_type" */
  insert_ENUM_collection_type?: Maybe<Enum_Collection_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_collection_type" */
  insert_ENUM_collection_type_one?: Maybe<Enum_Collection_Type>;
  /** insert data into the table: "ENUM_connected_domain_state" */
  insert_ENUM_connected_domain_state?: Maybe<Enum_Connected_Domain_State_Mutation_Response>;
  /** insert a single row into the table: "ENUM_connected_domain_state" */
  insert_ENUM_connected_domain_state_one?: Maybe<Enum_Connected_Domain_State>;
  /** insert data into the table: "ENUM_content_type" */
  insert_ENUM_content_type?: Maybe<Enum_Content_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_content_type" */
  insert_ENUM_content_type_one?: Maybe<Enum_Content_Type>;
  /** insert data into the table: "ENUM_context_delegate_role" */
  insert_ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role_Mutation_Response>;
  /** insert a single row into the table: "ENUM_context_delegate_role" */
  insert_ENUM_context_delegate_role_one?: Maybe<Enum_Context_Delegate_Role>;
  /** insert data into the table: "ENUM_context_delegate_state" */
  insert_ENUM_context_delegate_state?: Maybe<Enum_Context_Delegate_State_Mutation_Response>;
  /** insert a single row into the table: "ENUM_context_delegate_state" */
  insert_ENUM_context_delegate_state_one?: Maybe<Enum_Context_Delegate_State>;
  /** insert data into the table: "ENUM_context_state" */
  insert_ENUM_context_state?: Maybe<Enum_Context_State_Mutation_Response>;
  /** insert a single row into the table: "ENUM_context_state" */
  insert_ENUM_context_state_one?: Maybe<Enum_Context_State>;
  /** insert data into the table: "ENUM_context_type" */
  insert_ENUM_context_type?: Maybe<Enum_Context_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_context_type" */
  insert_ENUM_context_type_one?: Maybe<Enum_Context_Type>;
  /** insert data into the table: "ENUM_discount_state" */
  insert_ENUM_discount_state?: Maybe<Enum_Discount_State_Mutation_Response>;
  /** insert a single row into the table: "ENUM_discount_state" */
  insert_ENUM_discount_state_one?: Maybe<Enum_Discount_State>;
  /** insert data into the table: "ENUM_discount_type" */
  insert_ENUM_discount_type?: Maybe<Enum_Discount_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_discount_type" */
  insert_ENUM_discount_type_one?: Maybe<Enum_Discount_Type>;
  /** insert data into the table: "ENUM_discount_value_type" */
  insert_ENUM_discount_value_type?: Maybe<Enum_Discount_Value_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_discount_value_type" */
  insert_ENUM_discount_value_type_one?: Maybe<Enum_Discount_Value_Type>;
  /** insert data into the table: "ENUM_domain_registration_status" */
  insert_ENUM_domain_registration_status?: Maybe<Enum_Domain_Registration_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_domain_registration_status" */
  insert_ENUM_domain_registration_status_one?: Maybe<Enum_Domain_Registration_Status>;
  /** insert data into the table: "ENUM_dynamic_cv_exhibition_type" */
  insert_ENUM_dynamic_cv_exhibition_type?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_dynamic_cv_exhibition_type" */
  insert_ENUM_dynamic_cv_exhibition_type_one?: Maybe<Enum_Dynamic_Cv_Exhibition_Type>;
  /** insert data into the table: "ENUM_dynamic_cv_section_type" */
  insert_ENUM_dynamic_cv_section_type?: Maybe<Enum_Dynamic_Cv_Section_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_dynamic_cv_section_type" */
  insert_ENUM_dynamic_cv_section_type_one?: Maybe<Enum_Dynamic_Cv_Section_Type>;
  /** insert data into the table: "ENUM_dynamic_cv_status" */
  insert_ENUM_dynamic_cv_status?: Maybe<Enum_Dynamic_Cv_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_dynamic_cv_status" */
  insert_ENUM_dynamic_cv_status_one?: Maybe<Enum_Dynamic_Cv_Status>;
  /** insert data into the table: "ENUM_edition_status" */
  insert_ENUM_edition_status?: Maybe<Enum_Edition_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_edition_status" */
  insert_ENUM_edition_status_one?: Maybe<Enum_Edition_Status>;
  /** insert data into the table: "ENUM_email_language" */
  insert_ENUM_email_language?: Maybe<Enum_Email_Language_Mutation_Response>;
  /** insert a single row into the table: "ENUM_email_language" */
  insert_ENUM_email_language_one?: Maybe<Enum_Email_Language>;
  /** insert data into the table: "ENUM_email_status" */
  insert_ENUM_email_status?: Maybe<Enum_Email_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_email_status" */
  insert_ENUM_email_status_one?: Maybe<Enum_Email_Status>;
  /** insert data into the table: "ENUM_email_validation_token_status" */
  insert_ENUM_email_validation_token_status?: Maybe<Enum_Email_Validation_Token_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_email_validation_token_status" */
  insert_ENUM_email_validation_token_status_one?: Maybe<Enum_Email_Validation_Token_Status>;
  /** insert data into the table: "ENUM_file_metadata_status" */
  insert_ENUM_file_metadata_status?: Maybe<Enum_File_Metadata_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_file_metadata_status" */
  insert_ENUM_file_metadata_status_one?: Maybe<Enum_File_Metadata_Status>;
  /** insert data into the table: "ENUM_flags_type" */
  insert_ENUM_flags_type?: Maybe<Enum_Flags_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_flags_type" */
  insert_ENUM_flags_type_one?: Maybe<Enum_Flags_Type>;
  /** insert data into the table: "ENUM_forge_configuration_type" */
  insert_ENUM_forge_configuration_type?: Maybe<Enum_Forge_Configuration_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_forge_configuration_type" */
  insert_ENUM_forge_configuration_type_one?: Maybe<Enum_Forge_Configuration_Type>;
  /** insert data into the table: "ENUM_forge_status" */
  insert_ENUM_forge_status?: Maybe<Enum_Forge_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_forge_status" */
  insert_ENUM_forge_status_one?: Maybe<Enum_Forge_Status>;
  /** insert data into the table: "ENUM_infraction_flag_type" */
  insert_ENUM_infraction_flag_type?: Maybe<Enum_Infraction_Flag_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_infraction_flag_type" */
  insert_ENUM_infraction_flag_type_one?: Maybe<Enum_Infraction_Flag_Type>;
  /** insert data into the table: "ENUM_interest_type" */
  insert_ENUM_interest_type?: Maybe<Enum_Interest_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_interest_type" */
  insert_ENUM_interest_type_one?: Maybe<Enum_Interest_Type>;
  /** insert data into the table: "ENUM_language_preference" */
  insert_ENUM_language_preference?: Maybe<Enum_Language_Preference_Mutation_Response>;
  /** insert a single row into the table: "ENUM_language_preference" */
  insert_ENUM_language_preference_one?: Maybe<Enum_Language_Preference>;
  /** insert data into the table: "ENUM_my_website_artwork_info" */
  insert_ENUM_my_website_artwork_info?: Maybe<Enum_My_Website_Artwork_Info_Mutation_Response>;
  /** insert a single row into the table: "ENUM_my_website_artwork_info" */
  insert_ENUM_my_website_artwork_info_one?: Maybe<Enum_My_Website_Artwork_Info>;
  /** insert data into the table: "ENUM_my_website_filters" */
  insert_ENUM_my_website_filters?: Maybe<Enum_My_Website_Filters_Mutation_Response>;
  /** insert a single row into the table: "ENUM_my_website_filters" */
  insert_ENUM_my_website_filters_one?: Maybe<Enum_My_Website_Filters>;
  /** insert data into the table: "ENUM_my_website_layout" */
  insert_ENUM_my_website_layout?: Maybe<Enum_My_Website_Layout_Mutation_Response>;
  /** insert a single row into the table: "ENUM_my_website_layout" */
  insert_ENUM_my_website_layout_one?: Maybe<Enum_My_Website_Layout>;
  /** insert data into the table: "ENUM_my_website_navigation" */
  insert_ENUM_my_website_navigation?: Maybe<Enum_My_Website_Navigation_Mutation_Response>;
  /** insert a single row into the table: "ENUM_my_website_navigation" */
  insert_ENUM_my_website_navigation_one?: Maybe<Enum_My_Website_Navigation>;
  /** insert data into the table: "ENUM_my_website_theme" */
  insert_ENUM_my_website_theme?: Maybe<Enum_My_Website_Theme_Mutation_Response>;
  /** insert a single row into the table: "ENUM_my_website_theme" */
  insert_ENUM_my_website_theme_one?: Maybe<Enum_My_Website_Theme>;
  /** insert data into the table: "ENUM_notification_channel" */
  insert_ENUM_notification_channel?: Maybe<Enum_Notification_Channel_Mutation_Response>;
  /** insert a single row into the table: "ENUM_notification_channel" */
  insert_ENUM_notification_channel_one?: Maybe<Enum_Notification_Channel>;
  /** insert data into the table: "ENUM_notification_group" */
  insert_ENUM_notification_group?: Maybe<Enum_Notification_Group_Mutation_Response>;
  /** insert a single row into the table: "ENUM_notification_group" */
  insert_ENUM_notification_group_one?: Maybe<Enum_Notification_Group>;
  /** insert data into the table: "ENUM_position_type" */
  insert_ENUM_position_type?: Maybe<Enum_Position_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_position_type" */
  insert_ENUM_position_type_one?: Maybe<Enum_Position_Type>;
  /** insert data into the table: "ENUM_price_scope" */
  insert_ENUM_price_scope?: Maybe<Enum_Price_Scope_Mutation_Response>;
  /** insert a single row into the table: "ENUM_price_scope" */
  insert_ENUM_price_scope_one?: Maybe<Enum_Price_Scope>;
  /** insert data into the table: "ENUM_print_technique" */
  insert_ENUM_print_technique?: Maybe<Enum_Print_Technique_Mutation_Response>;
  /** insert a single row into the table: "ENUM_print_technique" */
  insert_ENUM_print_technique_one?: Maybe<Enum_Print_Technique>;
  /** insert data into the table: "ENUM_profile_background_type" */
  insert_ENUM_profile_background_type?: Maybe<Enum_Profile_Background_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_profile_background_type" */
  insert_ENUM_profile_background_type_one?: Maybe<Enum_Profile_Background_Type>;
  /** insert data into the table: "ENUM_profile_invitation_status" */
  insert_ENUM_profile_invitation_status?: Maybe<Enum_Profile_Invitation_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_profile_invitation_status" */
  insert_ENUM_profile_invitation_status_one?: Maybe<Enum_Profile_Invitation_Status>;
  /** insert data into the table: "ENUM_profile_public_status" */
  insert_ENUM_profile_public_status?: Maybe<Enum_Profile_Public_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_profile_public_status" */
  insert_ENUM_profile_public_status_one?: Maybe<Enum_Profile_Public_Status>;
  /** insert data into the table: "ENUM_profile_region" */
  insert_ENUM_profile_region?: Maybe<Enum_Profile_Region_Mutation_Response>;
  /** insert a single row into the table: "ENUM_profile_region" */
  insert_ENUM_profile_region_one?: Maybe<Enum_Profile_Region>;
  /** insert data into the table: "ENUM_profile_status" */
  insert_ENUM_profile_status?: Maybe<Enum_Profile_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_profile_status" */
  insert_ENUM_profile_status_one?: Maybe<Enum_Profile_Status>;
  /** insert data into the table: "ENUM_profile_theme" */
  insert_ENUM_profile_theme?: Maybe<Enum_Profile_Theme_Mutation_Response>;
  /** insert a single row into the table: "ENUM_profile_theme" */
  insert_ENUM_profile_theme_one?: Maybe<Enum_Profile_Theme>;
  /** insert data into the table: "ENUM_profile_type" */
  insert_ENUM_profile_type?: Maybe<Enum_Profile_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_profile_type" */
  insert_ENUM_profile_type_one?: Maybe<Enum_Profile_Type>;
  /** insert data into the table: "ENUM_pseudo_user_action" */
  insert_ENUM_pseudo_user_action?: Maybe<Enum_Pseudo_User_Action_Mutation_Response>;
  /** insert a single row into the table: "ENUM_pseudo_user_action" */
  insert_ENUM_pseudo_user_action_one?: Maybe<Enum_Pseudo_User_Action>;
  /** insert data into the table: "ENUM_push_notification_status" */
  insert_ENUM_push_notification_status?: Maybe<Enum_Push_Notification_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_push_notification_status" */
  insert_ENUM_push_notification_status_one?: Maybe<Enum_Push_Notification_Status>;
  /** insert data into the table: "ENUM_push_notification_type" */
  insert_ENUM_push_notification_type?: Maybe<Enum_Push_Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_push_notification_type" */
  insert_ENUM_push_notification_type_one?: Maybe<Enum_Push_Notification_Type>;
  /** insert data into the table: "ENUM_questionnaire_type" */
  insert_ENUM_questionnaire_type?: Maybe<Enum_Questionnaire_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_questionnaire_type" */
  insert_ENUM_questionnaire_type_one?: Maybe<Enum_Questionnaire_Type>;
  /** insert data into the table: "ENUM_render_request_status" */
  insert_ENUM_render_request_status?: Maybe<Enum_Render_Request_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_render_request_status" */
  insert_ENUM_render_request_status_one?: Maybe<Enum_Render_Request_Status>;
  /** insert data into the table: "ENUM_render_request_template_type" */
  insert_ENUM_render_request_template_type?: Maybe<Enum_Render_Request_Template_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_render_request_template_type" */
  insert_ENUM_render_request_template_type_one?: Maybe<Enum_Render_Request_Template_Type>;
  /** insert data into the table: "ENUM_restricted_handle_names" */
  insert_ENUM_restricted_handle_names?: Maybe<Enum_Restricted_Handle_Names_Mutation_Response>;
  /** insert a single row into the table: "ENUM_restricted_handle_names" */
  insert_ENUM_restricted_handle_names_one?: Maybe<Enum_Restricted_Handle_Names>;
  /** insert data into the table: "ENUM_size" */
  insert_ENUM_size?: Maybe<Enum_Size_Mutation_Response>;
  /** insert a single row into the table: "ENUM_size" */
  insert_ENUM_size_one?: Maybe<Enum_Size>;
  /** insert data into the table: "ENUM_social_graph_followers_state" */
  insert_ENUM_social_graph_followers_state?: Maybe<Enum_Social_Graph_Followers_State_Mutation_Response>;
  /** insert a single row into the table: "ENUM_social_graph_followers_state" */
  insert_ENUM_social_graph_followers_state_one?: Maybe<Enum_Social_Graph_Followers_State>;
  /** insert data into the table: "ENUM_social_link_status" */
  insert_ENUM_social_link_status?: Maybe<Enum_Social_Link_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_social_link_status" */
  insert_ENUM_social_link_status_one?: Maybe<Enum_Social_Link_Status>;
  /** insert data into the table: "ENUM_social_link_type" */
  insert_ENUM_social_link_type?: Maybe<Enum_Social_Link_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_social_link_type" */
  insert_ENUM_social_link_type_one?: Maybe<Enum_Social_Link_Type>;
  /** insert data into the table: "ENUM_timed_event_name" */
  insert_ENUM_timed_event_name?: Maybe<Enum_Timed_Event_Name_Mutation_Response>;
  /** insert a single row into the table: "ENUM_timed_event_name" */
  insert_ENUM_timed_event_name_one?: Maybe<Enum_Timed_Event_Name>;
  /** insert data into the table: "ENUM_token_storage_status" */
  insert_ENUM_token_storage_status?: Maybe<Enum_Token_Storage_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_token_storage_status" */
  insert_ENUM_token_storage_status_one?: Maybe<Enum_Token_Storage_Status>;
  /** insert data into the table: "ENUM_transaction_origin" */
  insert_ENUM_transaction_origin?: Maybe<Enum_Transaction_Origin_Mutation_Response>;
  /** insert a single row into the table: "ENUM_transaction_origin" */
  insert_ENUM_transaction_origin_one?: Maybe<Enum_Transaction_Origin>;
  /** insert data into the table: "ENUM_transaction_status" */
  insert_ENUM_transaction_status?: Maybe<Enum_Transaction_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_transaction_status" */
  insert_ENUM_transaction_status_one?: Maybe<Enum_Transaction_Status>;
  /** insert data into the table: "ENUM_tutorial_status" */
  insert_ENUM_tutorial_status?: Maybe<Enum_Tutorial_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_tutorial_status" */
  insert_ENUM_tutorial_status_one?: Maybe<Enum_Tutorial_Status>;
  /** insert data into the table: "ENUM_tutorial_step_type" */
  insert_ENUM_tutorial_step_type?: Maybe<Enum_Tutorial_Step_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_tutorial_step_type" */
  insert_ENUM_tutorial_step_type_one?: Maybe<Enum_Tutorial_Step_Type>;
  /** insert data into the table: "ENUM_tutorial_type" */
  insert_ENUM_tutorial_type?: Maybe<Enum_Tutorial_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_tutorial_type" */
  insert_ENUM_tutorial_type_one?: Maybe<Enum_Tutorial_Type>;
  /** insert data into the table: "ENUM_vumark_status" */
  insert_ENUM_vumark_status?: Maybe<Enum_Vumark_Status_Mutation_Response>;
  /** insert a single row into the table: "ENUM_vumark_status" */
  insert_ENUM_vumark_status_one?: Maybe<Enum_Vumark_Status>;
  /** insert data into the table: "ENUM_vumark_type" */
  insert_ENUM_vumark_type?: Maybe<Enum_Vumark_Type_Mutation_Response>;
  /** insert a single row into the table: "ENUM_vumark_type" */
  insert_ENUM_vumark_type_one?: Maybe<Enum_Vumark_Type>;
  /** insert data into the table: "Edition" */
  insert_Edition?: Maybe<Edition_Mutation_Response>;
  /** insert data into the table: "Edition_By_Size" */
  insert_Edition_By_Size?: Maybe<Edition_By_Size_Mutation_Response>;
  /** insert a single row into the table: "Edition_By_Size" */
  insert_Edition_By_Size_one?: Maybe<Edition_By_Size>;
  /** insert data into the table: "Edition_By_Type" */
  insert_Edition_By_Type?: Maybe<Edition_By_Type_Mutation_Response>;
  /** insert a single row into the table: "Edition_By_Type" */
  insert_Edition_By_Type_one?: Maybe<Edition_By_Type>;
  /** insert data into the table: "Edition_Type" */
  insert_Edition_Type?: Maybe<Edition_Type_Mutation_Response>;
  /** insert a single row into the table: "Edition_Type" */
  insert_Edition_Type_one?: Maybe<Edition_Type>;
  /** insert data into the table: "Edition_events" */
  insert_Edition_events?: Maybe<Edition_Events_Mutation_Response>;
  /** insert a single row into the table: "Edition_events" */
  insert_Edition_events_one?: Maybe<Edition_Events>;
  /** insert a single row into the table: "Edition" */
  insert_Edition_one?: Maybe<Edition>;
  /** insert data into the table: "Email_validation_token" */
  insert_Email_validation_token?: Maybe<Email_Validation_Token_Mutation_Response>;
  /** insert a single row into the table: "Email_validation_token" */
  insert_Email_validation_token_one?: Maybe<Email_Validation_Token>;
  /** insert data into the table: "Emails" */
  insert_Emails?: Maybe<Emails_Mutation_Response>;
  /** insert a single row into the table: "Emails" */
  insert_Emails_one?: Maybe<Emails>;
  /** insert data into the table: "FCM_Token" */
  insert_FCM_Token?: Maybe<Fcm_Token_Mutation_Response>;
  /** insert a single row into the table: "FCM_Token" */
  insert_FCM_Token_one?: Maybe<Fcm_Token>;
  /** insert data into the table: "Features" */
  insert_Features?: Maybe<Features_Mutation_Response>;
  /** insert a single row into the table: "Features" */
  insert_Features_one?: Maybe<Features>;
  /** insert data into the table: "File_metadata" */
  insert_File_metadata?: Maybe<File_Metadata_Mutation_Response>;
  /** insert a single row into the table: "File_metadata" */
  insert_File_metadata_one?: Maybe<File_Metadata>;
  /** insert data into the table: "Firebase_profile" */
  insert_Firebase_profile?: Maybe<Firebase_Profile_Mutation_Response>;
  /** insert a single row into the table: "Firebase_profile" */
  insert_Firebase_profile_one?: Maybe<Firebase_Profile>;
  /** insert data into the table: "Flagged_users" */
  insert_Flagged_users?: Maybe<Flagged_Users_Mutation_Response>;
  /** insert a single row into the table: "Flagged_users" */
  insert_Flagged_users_one?: Maybe<Flagged_Users>;
  /** insert data into the table: "Forge_InProcess_Task" */
  insert_Forge_InProcess_Task?: Maybe<Forge_InProcess_Task_Mutation_Response>;
  /** insert a single row into the table: "Forge_InProcess_Task" */
  insert_Forge_InProcess_Task_one?: Maybe<Forge_InProcess_Task>;
  /** insert data into the table: "Forge_tasks" */
  insert_Forge_tasks?: Maybe<Forge_Tasks_Mutation_Response>;
  /** insert a single row into the table: "Forge_tasks" */
  insert_Forge_tasks_one?: Maybe<Forge_Tasks>;
  /** insert data into the table: "Global_properties" */
  insert_Global_properties?: Maybe<Global_Properties_Mutation_Response>;
  /** insert a single row into the table: "Global_properties" */
  insert_Global_properties_one?: Maybe<Global_Properties>;
  /** insert data into the table: "Graveyard_Artwork" */
  insert_Graveyard_Artwork?: Maybe<Graveyard_Artwork_Mutation_Response>;
  /** insert data into the table: "Graveyard_Artwork_details" */
  insert_Graveyard_Artwork_details?: Maybe<Graveyard_Artwork_Details_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Artwork_details" */
  insert_Graveyard_Artwork_details_one?: Maybe<Graveyard_Artwork_Details>;
  /** insert data into the table: "Graveyard_Artwork_details_translation" */
  insert_Graveyard_Artwork_details_translation?: Maybe<Graveyard_Artwork_Details_Translation_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Artwork_details_translation" */
  insert_Graveyard_Artwork_details_translation_one?: Maybe<Graveyard_Artwork_Details_Translation>;
  /** insert data into the table: "Graveyard_Artwork_events" */
  insert_Graveyard_Artwork_events?: Maybe<Graveyard_Artwork_Events_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Artwork_events" */
  insert_Graveyard_Artwork_events_one?: Maybe<Graveyard_Artwork_Events>;
  /** insert a single row into the table: "Graveyard_Artwork" */
  insert_Graveyard_Artwork_one?: Maybe<Graveyard_Artwork>;
  /** insert data into the table: "Graveyard_Artwork_prices" */
  insert_Graveyard_Artwork_prices?: Maybe<Graveyard_Artwork_Prices_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Artwork_prices" */
  insert_Graveyard_Artwork_prices_one?: Maybe<Graveyard_Artwork_Prices>;
  /** insert data into the table: "Graveyard_Artwork_secondary_media" */
  insert_Graveyard_Artwork_secondary_media?: Maybe<Graveyard_Artwork_Secondary_Media_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Artwork_secondary_media" */
  insert_Graveyard_Artwork_secondary_media_one?: Maybe<Graveyard_Artwork_Secondary_Media>;
  /** insert data into the table: "Graveyard_Artwork_story" */
  insert_Graveyard_Artwork_story?: Maybe<Graveyard_Artwork_Story_Mutation_Response>;
  /** insert data into the table: "Graveyard_Artwork_story_media" */
  insert_Graveyard_Artwork_story_media?: Maybe<Graveyard_Artwork_Story_Media_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Artwork_story_media" */
  insert_Graveyard_Artwork_story_media_one?: Maybe<Graveyard_Artwork_Story_Media>;
  /** insert a single row into the table: "Graveyard_Artwork_story" */
  insert_Graveyard_Artwork_story_one?: Maybe<Graveyard_Artwork_Story>;
  /** insert data into the table: "Graveyard_Artwork_to_Collection" */
  insert_Graveyard_Artwork_to_Collection?: Maybe<Graveyard_Artwork_To_Collection_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Artwork_to_Collection" */
  insert_Graveyard_Artwork_to_Collection_one?: Maybe<Graveyard_Artwork_To_Collection>;
  /** insert data into the table: "Graveyard_Artwork_transactions" */
  insert_Graveyard_Artwork_transactions?: Maybe<Graveyard_Artwork_Transactions_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Artwork_transactions" */
  insert_Graveyard_Artwork_transactions_one?: Maybe<Graveyard_Artwork_Transactions>;
  /** insert data into the table: "Graveyard_Purchase_inquires" */
  insert_Graveyard_Purchase_inquires?: Maybe<Graveyard_Purchase_Inquires_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Purchase_inquires" */
  insert_Graveyard_Purchase_inquires_one?: Maybe<Graveyard_Purchase_Inquires>;
  /** insert data into the table: "Graveyard_Purchase_inquires_outside_users" */
  insert_Graveyard_Purchase_inquires_outside_users?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Mutation_Response>;
  /** insert a single row into the table: "Graveyard_Purchase_inquires_outside_users" */
  insert_Graveyard_Purchase_inquires_outside_users_one?: Maybe<Graveyard_Purchase_Inquires_Outside_Users>;
  /** insert data into the table: "Interests" */
  insert_Interests?: Maybe<Interests_Mutation_Response>;
  /** insert a single row into the table: "Interests" */
  insert_Interests_one?: Maybe<Interests>;
  /** insert data into the table: "Kaleido_general_settings" */
  insert_Kaleido_general_settings?: Maybe<Kaleido_General_Settings_Mutation_Response>;
  /** insert a single row into the table: "Kaleido_general_settings" */
  insert_Kaleido_general_settings_one?: Maybe<Kaleido_General_Settings>;
  /** insert data into the table: "Landing_page_artwork" */
  insert_Landing_page_artwork?: Maybe<Landing_Page_Artwork_Mutation_Response>;
  /** insert a single row into the table: "Landing_page_artwork" */
  insert_Landing_page_artwork_one?: Maybe<Landing_Page_Artwork>;
  /** insert data into the table: "Languages" */
  insert_Languages?: Maybe<Languages_Mutation_Response>;
  /** insert a single row into the table: "Languages" */
  insert_Languages_one?: Maybe<Languages>;
  /** insert data into the table: "Materialized_view" */
  insert_Materialized_view?: Maybe<Materialized_View_Mutation_Response>;
  /** insert a single row into the table: "Materialized_view" */
  insert_Materialized_view_one?: Maybe<Materialized_View>;
  /** insert data into the table: "Media_metadata" */
  insert_Media_metadata?: Maybe<Media_Metadata_Mutation_Response>;
  /** insert a single row into the table: "Media_metadata" */
  insert_Media_metadata_one?: Maybe<Media_Metadata>;
  /** insert data into the table: "Milestone" */
  insert_Milestone?: Maybe<Milestone_Mutation_Response>;
  /** insert a single row into the table: "Milestone" */
  insert_Milestone_one?: Maybe<Milestone>;
  /** insert data into the table: "My_Website_Settings" */
  insert_My_Website_Settings?: Maybe<My_Website_Settings_Mutation_Response>;
  /** insert a single row into the table: "My_Website_Settings" */
  insert_My_Website_Settings_one?: Maybe<My_Website_Settings>;
  /** insert data into the table: "My_studio_settings" */
  insert_My_studio_settings?: Maybe<My_Studio_Settings_Mutation_Response>;
  /** insert a single row into the table: "My_studio_settings" */
  insert_My_studio_settings_one?: Maybe<My_Studio_Settings>;
  /** insert data into the table: "Notification_preference" */
  insert_Notification_preference?: Maybe<Notification_Preference_Mutation_Response>;
  /** insert a single row into the table: "Notification_preference" */
  insert_Notification_preference_one?: Maybe<Notification_Preference>;
  /** insert data into the table: "Pending_collaboration_on_artwork" */
  insert_Pending_collaboration_on_artwork?: Maybe<Pending_Collaboration_On_Artwork_Mutation_Response>;
  /** insert a single row into the table: "Pending_collaboration_on_artwork" */
  insert_Pending_collaboration_on_artwork_one?: Maybe<Pending_Collaboration_On_Artwork>;
  /** insert data into the table: "Price_Ranges" */
  insert_Price_Ranges?: Maybe<Price_Ranges_Mutation_Response>;
  /** insert a single row into the table: "Price_Ranges" */
  insert_Price_Ranges_one?: Maybe<Price_Ranges>;
  /** insert data into the table: "Print" */
  insert_Print?: Maybe<Print_Mutation_Response>;
  /** insert a single row into the table: "Print" */
  insert_Print_one?: Maybe<Print>;
  /** insert data into the table: "Print_to_Collection" */
  insert_Print_to_Collection?: Maybe<Print_To_Collection_Mutation_Response>;
  /** insert a single row into the table: "Print_to_Collection" */
  insert_Print_to_Collection_one?: Maybe<Print_To_Collection>;
  /** insert data into the table: "Prize_Art_Show" */
  insert_Prize_Art_Show?: Maybe<Prize_Art_Show_Mutation_Response>;
  /** insert a single row into the table: "Prize_Art_Show" */
  insert_Prize_Art_Show_one?: Maybe<Prize_Art_Show>;
  /** insert data into the table: "Profile" */
  insert_Profile?: Maybe<Profile_Mutation_Response>;
  /** insert data into the table: "Profile_Interests" */
  insert_Profile_Interests?: Maybe<Profile_Interests_Mutation_Response>;
  /** insert a single row into the table: "Profile_Interests" */
  insert_Profile_Interests_one?: Maybe<Profile_Interests>;
  /** insert data into the table: "Profile_achievements" */
  insert_Profile_achievements?: Maybe<Profile_Achievements_Mutation_Response>;
  /** insert a single row into the table: "Profile_achievements" */
  insert_Profile_achievements_one?: Maybe<Profile_Achievements>;
  /** insert data into the table: "Profile_appearance_settings" */
  insert_Profile_appearance_settings?: Maybe<Profile_Appearance_Settings_Mutation_Response>;
  /** insert a single row into the table: "Profile_appearance_settings" */
  insert_Profile_appearance_settings_one?: Maybe<Profile_Appearance_Settings>;
  /** insert data into the table: "Profile_background" */
  insert_Profile_background?: Maybe<Profile_Background_Mutation_Response>;
  /** insert a single row into the table: "Profile_background" */
  insert_Profile_background_one?: Maybe<Profile_Background>;
  /** insert data into the table: "Profile_ghost_tokens" */
  insert_Profile_ghost_tokens?: Maybe<Profile_Ghost_Tokens_Mutation_Response>;
  /** insert a single row into the table: "Profile_ghost_tokens" */
  insert_Profile_ghost_tokens_one?: Maybe<Profile_Ghost_Tokens>;
  /** insert data into the table: "Profile_invitations" */
  insert_Profile_invitations?: Maybe<Profile_Invitations_Mutation_Response>;
  /** insert a single row into the table: "Profile_invitations" */
  insert_Profile_invitations_one?: Maybe<Profile_Invitations>;
  /** insert data into the table: "Profile_location" */
  insert_Profile_location?: Maybe<Profile_Location_Mutation_Response>;
  /** insert a single row into the table: "Profile_location" */
  insert_Profile_location_one?: Maybe<Profile_Location>;
  /** insert data into the table: "Profile_location_translation" */
  insert_Profile_location_translation?: Maybe<Profile_Location_Translation_Mutation_Response>;
  /** insert a single row into the table: "Profile_location_translation" */
  insert_Profile_location_translation_one?: Maybe<Profile_Location_Translation>;
  /** insert a single row into the table: "Profile" */
  insert_Profile_one?: Maybe<Profile>;
  /** insert data into the table: "Profile_price_ranges" */
  insert_Profile_price_ranges?: Maybe<Profile_Price_Ranges_Mutation_Response>;
  /** insert a single row into the table: "Profile_price_ranges" */
  insert_Profile_price_ranges_one?: Maybe<Profile_Price_Ranges>;
  /** insert data into the table: "Profile_questions_and_answers" */
  insert_Profile_questions_and_answers?: Maybe<Profile_Questions_And_Answers_Mutation_Response>;
  /** insert a single row into the table: "Profile_questions_and_answers" */
  insert_Profile_questions_and_answers_one?: Maybe<Profile_Questions_And_Answers>;
  /** insert data into the table: "Profile_settings" */
  insert_Profile_settings?: Maybe<Profile_Settings_Mutation_Response>;
  /** insert a single row into the table: "Profile_settings" */
  insert_Profile_settings_one?: Maybe<Profile_Settings>;
  /** insert data into the table: "Profile_social_links" */
  insert_Profile_social_links?: Maybe<Profile_Social_Links_Mutation_Response>;
  /** insert a single row into the table: "Profile_social_links" */
  insert_Profile_social_links_one?: Maybe<Profile_Social_Links>;
  /** insert data into the table: "Profile_statement_audio_syntheses" */
  insert_Profile_statement_audio_syntheses?: Maybe<Profile_Statement_Audio_Syntheses_Mutation_Response>;
  /** insert a single row into the table: "Profile_statement_audio_syntheses" */
  insert_Profile_statement_audio_syntheses_one?: Maybe<Profile_Statement_Audio_Syntheses>;
  /** insert data into the table: "Profile_translation" */
  insert_Profile_translation?: Maybe<Profile_Translation_Mutation_Response>;
  /** insert a single row into the table: "Profile_translation" */
  insert_Profile_translation_one?: Maybe<Profile_Translation>;
  /** insert data into the table: "Profile_video_links" */
  insert_Profile_video_links?: Maybe<Profile_Video_Links_Mutation_Response>;
  /** insert a single row into the table: "Profile_video_links" */
  insert_Profile_video_links_one?: Maybe<Profile_Video_Links>;
  /** insert data into the table: "Profile_wallet_address" */
  insert_Profile_wallet_address?: Maybe<Profile_Wallet_Address_Mutation_Response>;
  /** insert a single row into the table: "Profile_wallet_address" */
  insert_Profile_wallet_address_one?: Maybe<Profile_Wallet_Address>;
  /** insert data into the table: "Pubnub_channels" */
  insert_Pubnub_channels?: Maybe<Pubnub_Channels_Mutation_Response>;
  /** insert a single row into the table: "Pubnub_channels" */
  insert_Pubnub_channels_one?: Maybe<Pubnub_Channels>;
  /** insert data into the table: "Purchase_inquires" */
  insert_Purchase_inquires?: Maybe<Purchase_Inquires_Mutation_Response>;
  /** insert a single row into the table: "Purchase_inquires" */
  insert_Purchase_inquires_one?: Maybe<Purchase_Inquires>;
  /** insert data into the table: "Purchase_inquires_outside_users" */
  insert_Purchase_inquires_outside_users?: Maybe<Purchase_Inquires_Outside_Users_Mutation_Response>;
  /** insert a single row into the table: "Purchase_inquires_outside_users" */
  insert_Purchase_inquires_outside_users_one?: Maybe<Purchase_Inquires_Outside_Users>;
  /** insert data into the table: "Push_notifications" */
  insert_Push_notifications?: Maybe<Push_Notifications_Mutation_Response>;
  /** insert a single row into the table: "Push_notifications" */
  insert_Push_notifications_one?: Maybe<Push_Notifications>;
  /** insert data into the table: "Questionnaire_questions" */
  insert_Questionnaire_questions?: Maybe<Questionnaire_Questions_Mutation_Response>;
  /** insert a single row into the table: "Questionnaire_questions" */
  insert_Questionnaire_questions_one?: Maybe<Questionnaire_Questions>;
  /** insert data into the table: "Render_request" */
  insert_Render_request?: Maybe<Render_Request_Mutation_Response>;
  /** insert a single row into the table: "Render_request" */
  insert_Render_request_one?: Maybe<Render_Request>;
  /** insert data into the table: "Setting_context_default_profile" */
  insert_Setting_context_default_profile?: Maybe<Setting_Context_Default_Profile_Mutation_Response>;
  /** insert a single row into the table: "Setting_context_default_profile" */
  insert_Setting_context_default_profile_one?: Maybe<Setting_Context_Default_Profile>;
  /** insert data into the table: "Showroom_details" */
  insert_Showroom_details?: Maybe<Showroom_Details_Mutation_Response>;
  /** insert a single row into the table: "Showroom_details" */
  insert_Showroom_details_one?: Maybe<Showroom_Details>;
  /** insert data into the table: "Social_graph_followers" */
  insert_Social_graph_followers?: Maybe<Social_Graph_Followers_Mutation_Response>;
  /** insert a single row into the table: "Social_graph_followers" */
  insert_Social_graph_followers_one?: Maybe<Social_Graph_Followers>;
  /** insert data into the table: "Spotlight_context" */
  insert_Spotlight_context?: Maybe<Spotlight_Context_Mutation_Response>;
  /** insert a single row into the table: "Spotlight_context" */
  insert_Spotlight_context_one?: Maybe<Spotlight_Context>;
  /** insert data into the table: "Super_site_subscribers" */
  insert_Super_site_subscribers?: Maybe<Super_Site_Subscribers_Mutation_Response>;
  /** insert a single row into the table: "Super_site_subscribers" */
  insert_Super_site_subscribers_one?: Maybe<Super_Site_Subscribers>;
  /** insert data into the table: "Tags_by_interest" */
  insert_Tags_by_interest?: Maybe<Tags_By_Interest_Mutation_Response>;
  /** insert a single row into the table: "Tags_by_interest" */
  insert_Tags_by_interest_one?: Maybe<Tags_By_Interest>;
  /** insert data into the table: "Test_Analytics_events" */
  insert_Test_Analytics_events?: Maybe<Test_Analytics_Events_Mutation_Response>;
  /** insert a single row into the table: "Test_Analytics_events" */
  insert_Test_Analytics_events_one?: Maybe<Test_Analytics_Events>;
  /** insert data into the table: "Test_accounts" */
  insert_Test_accounts?: Maybe<Test_Accounts_Mutation_Response>;
  /** insert a single row into the table: "Test_accounts" */
  insert_Test_accounts_one?: Maybe<Test_Accounts>;
  /** insert data into the table: "Timed_event" */
  insert_Timed_event?: Maybe<Timed_Event_Mutation_Response>;
  /** insert a single row into the table: "Timed_event" */
  insert_Timed_event_one?: Maybe<Timed_Event>;
  /** insert data into the table: "Token_Storage" */
  insert_Token_Storage?: Maybe<Token_Storage_Mutation_Response>;
  /** insert a single row into the table: "Token_Storage" */
  insert_Token_Storage_one?: Maybe<Token_Storage>;
  /** insert data into the table: "Tutorial" */
  insert_Tutorial?: Maybe<Tutorial_Mutation_Response>;
  /** insert a single row into the table: "Tutorial" */
  insert_Tutorial_one?: Maybe<Tutorial>;
  /** insert data into the table: "Tutorial_step" */
  insert_Tutorial_step?: Maybe<Tutorial_Step_Mutation_Response>;
  /** insert a single row into the table: "Tutorial_step" */
  insert_Tutorial_step_one?: Maybe<Tutorial_Step>;
  /** insert data into the table: "User_analytics_points_index" */
  insert_User_analytics_points_index?: Maybe<User_Analytics_Points_Index_Mutation_Response>;
  /** insert a single row into the table: "User_analytics_points_index" */
  insert_User_analytics_points_index_one?: Maybe<User_Analytics_Points_Index>;
  /** insert data into the table: "VR_artists" */
  insert_VR_artists?: Maybe<Vr_Artists_Mutation_Response>;
  /** insert a single row into the table: "VR_artists" */
  insert_VR_artists_one?: Maybe<Vr_Artists>;
  /** insert data into the table: "Vision_interest" */
  insert_Vision_interest?: Maybe<Vision_Interest_Mutation_Response>;
  /** insert a single row into the table: "Vision_interest" */
  insert_Vision_interest_one?: Maybe<Vision_Interest>;
  /** insert data into the table: "Vumarks" */
  insert_Vumarks?: Maybe<Vumarks_Mutation_Response>;
  /** insert a single row into the table: "Vumarks" */
  insert_Vumarks_one?: Maybe<Vumarks>;
  /** insert data into the table: "Waiting_list" */
  insert_Waiting_list?: Maybe<Waiting_List_Mutation_Response>;
  /** insert a single row into the table: "Waiting_list" */
  insert_Waiting_list_one?: Maybe<Waiting_List>;
  /** insert data into the table: "Wallets" */
  insert_Wallets?: Maybe<Wallets_Mutation_Response>;
  /** insert a single row into the table: "Wallets" */
  insert_Wallets_one?: Maybe<Wallets>;
  /** insert data into the table: "World_cities" */
  insert_World_cities?: Maybe<World_Cities_Mutation_Response>;
  /** insert a single row into the table: "World_cities" */
  insert_World_cities_one?: Maybe<World_Cities>;
  /** insert data into the table: "app_notifications_info" */
  insert_app_notifications_info?: Maybe<App_Notifications_Info_Mutation_Response>;
  /** insert a single row into the table: "app_notifications_info" */
  insert_app_notifications_info_one?: Maybe<App_Notifications_Info>;
  /** insert data into the table: "app_notifications_listing" */
  insert_app_notifications_listing?: Maybe<App_Notifications_Listing_Mutation_Response>;
  /** insert a single row into the table: "app_notifications_listing" */
  insert_app_notifications_listing_one?: Maybe<App_Notifications_Listing>;
  /** insert data into the table: "app_notifications_view" */
  insert_app_notifications_view?: Maybe<App_Notifications_View_Mutation_Response>;
  /** insert a single row into the table: "app_notifications_view" */
  insert_app_notifications_view_one?: Maybe<App_Notifications_View>;
  /** insert data into the table: "profile_phones" */
  insert_profile_phones?: Maybe<Profile_Phones_Mutation_Response>;
  /** insert a single row into the table: "profile_phones" */
  insert_profile_phones_one?: Maybe<Profile_Phones>;
  /** insert data into the table: "showroom_owner_password" */
  insert_showroom_owner_password?: Maybe<Showroom_Owner_Password_Mutation_Response>;
  /** insert a single row into the table: "showroom_owner_password" */
  insert_showroom_owner_password_one?: Maybe<Showroom_Owner_Password>;
  /** insert data into the table: "worldcities" */
  insert_worldcities?: Maybe<Worldcities_Mutation_Response>;
  /** insert a single row into the table: "worldcities" */
  insert_worldcities_one?: Maybe<Worldcities>;
  instantGratification: Scalars['Boolean'];
  /** Marks the pending domain registration for the associated user as failed */
  markDomainRegistrationAsFailed?: Maybe<Scalars['Boolean']>;
  /**
   * - Deletes all price ranges and inserts the new price ranges sent
   *
   * ***Constraints:***:
   *
   *   \- Only collectors, simple users, artists profile
   */
  markProfilePriceRanges?: Maybe<Scalars['Boolean']>;
  /** - Updates profile to the status received */
  markProfileStatus?: Maybe<Scalars['Boolean']>;
  /** Inserts a new print to the edition by type if the print does not exist. */
  materializePrint?: Maybe<MaterializePrintResult>;
  /**
   * Creates a new artist profile with an OWNED and CREATED collections.
   *
   * Sets the profile as incomplete
   *
   * ***Constraints:***:
   *
   *   \- Handle should be unique and alphanumeric
   */
  newArtistProfile?: Maybe<ProfileCreationResultType>;
  /**
   * - Creates a new collector profile with an OWNED collection.
   *
   * - Sets the profile as incomplete
   *
   * - Sends welcome to Kaleido mail and programs a reminder
   *
   * - Starts collector explore notifications mail sequence
   *
   * - Marks pending transfer to received for transfered artworks to the collector mail
   * ***Constraints:***:
   *
   *   \- Handle should be unique and alphanumeric
   *
   *   \- Only one collector profile by user account
   */
  newCollectorProfile?: Maybe<ProfileCreationResultType>;
  /**
   * Creates a new gallery profile with an OWNED and MANAGED collections.
   *
   * Sets the profile as incomplete
   *
   * ***Constraints:***:
   *
   *   \- Handle should be unique and alphanumeric
   */
  newGalleryProfile?: Maybe<ProfileCreationResultType>;
  /**
   * - Creates a new collector profile with an OWNED collection.
   *
   * - Sets the profile as incomplete
   *
   * - Sends welcome to Kaleido mail and programs a reminder
   *
   * - Starts collector explore notifications mail sequence
   *
   * ***Constraints:***:
   *
   *   \- Handle should be unique and alphanumeric
   *
   *   \- Only one collector profile by user account
   */
  newSimpleCollectorProfile?: Maybe<ProfileCreationResultType>;
  notificationMutation?: Maybe<Scalars['String']>;
  /** Soft delete audio for profile in the database */
  profileAudioDelete?: Maybe<Scalars['Boolean']>;
  /**
   * Uploads a profile audio to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  profileAudioUpload?: Maybe<UploadedFileResponse>;
  /**
   * Uploads an avatar image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  profileAvatarUpload?: Maybe<UploadedFileResponse>;
  /**
   * Uploads an banner image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  profileBannerUpload?: Maybe<UploadedFileResponse>;
  /** Soft delete cv for profile in the database */
  profileCVDelete?: Maybe<Scalars['Boolean']>;
  /** Uploads an CV files to S3 and saves metadata info in file_metadata. */
  profileCVUpload?: Maybe<UploadedFileResponse>;
  /** Soft delete a featured art image in the database */
  profileFeaturedArtDelete?: Maybe<Scalars['Boolean']>;
  /**
   * Uploads an featured art image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  profileFeaturedArtUpload?: Maybe<UploadedFileResponse>;
  /**
   * Uploads a kard banner image to S3 and saves metadata info in file_metadata.
   *
   * ***Note:***
   *
   *   \- Forge process the queue for image transformation and resizes
   */
  profileKardBannerUpload?: Maybe<UploadedFileResponse>;
  /** Publish an edition Artwork and updates the print_availability materialized view */
  publishEditionArtwork?: Maybe<Scalars['Boolean']>;
  /**
   * Sets the context id to the showroom collection.
   *
   * ***Constraints:***
   *
   *   \- Only the creator of the collection can edit it.
   *
   *   \- Only an artist can publish the showroom.
   */
  publishShowroom?: Maybe<Scalars['Boolean']>;
  removeArtworkFromCart: Array<ShoppingCartItemType>;
  /**
   * Removes an edition.
   *
   * ***Constraints:***
   *
   *   \- The edition by size should not have solds, transfered or used in a process prints
   */
  removeEditionById?: Maybe<Scalars['Boolean']>;
  /**
   * Removes an edition by size.
   *
   * ***Constraints:***
   *
   *   \- The edition by size should not have solds, transfered or used in a process prints
   *
   *   \- Should send edition_by_size_id or edition_id and print_size
   */
  removeEditionBySize?: Maybe<Scalars['Boolean']>;
  /**
   * Removes an edition by type.
   *
   * ***Constraints:***
   *
   *   \- The edition by type should not have solds, transfered or used in a process prints
   *
   *   \- Should send edition_by_type_id or edition_by_size_id and edition_type_id
   */
  removeEditionByType?: Maybe<Scalars['Boolean']>;
  /**
   * Removes a print.
   *
   * ***Constraints:***
   *
   *   \- The print should not be sold, transfered or used in a process
   */
  removePrintById?: Maybe<Scalars['Boolean']>;
  /** Removes an artwork from a Series collection */
  removeSeriesForArtwork?: Maybe<Scalars['Int']>;
  reorderArtistsInCollection?: Maybe<Scalars['Boolean']>;
  requestAccountValidation: RequestAccountValidationResponse;
  requestEmailVerification: Scalars['Boolean'];
  requestFirebaseCustomAuth: PseudoAuthStatusResponse;
  /**
   * - Resends mail collaboration email
   *
   * ***Constraints:***:
   *
   *   \- Collaboration status should be pending
   */
  resendCollaborationInvitation: Scalars['Boolean'];
  /** - Resends mail notifications to Gallery or Collector */
  resendInvitation: Scalars['Boolean'];
  resendValidationCode: Scalars['Boolean'];
  /**
   * - Updates status for context to tutorial to STARTED and current to Null
   *
   * ***Constraints:***:
   *
   *   \- Context to tutorial must exist
   */
  resetContextTutorial: Scalars['Boolean'];
  resetFlaggedUserInfractionsCounterMutation: Scalars['Boolean'];
  /** - Deletes collaboration, pending and partnered artworks. */
  revokeCollaboration: Scalars['Boolean'];
  /** - Deletes profile invitation and collaboration invitation on artworks */
  revokeInvitation: Scalars['Boolean'];
  /**
   * - Deletes an invitation on artwork for a gallery
   *
   * ***Constraints:***:
   *
   *   \- Context should own the invitation
   *
   *   \- Gallery must not have an account
   */
  revokeInvitationOnArtwork: Scalars['Boolean'];
  revokeOwner?: Maybe<Scalars['Boolean']>;
  runRule: Scalars['Boolean'];
  sendArtUniversePush?: Maybe<Scalars['Boolean']>;
  sendArtistBestPracticesEmail?: Maybe<Scalars['Boolean']>;
  sendArtistRequestMeetingEmail?: Maybe<Scalars['Boolean']>;
  sendDiscountCodeEmail?: Maybe<Scalars['Boolean']>;
  sendMessageNotifications?: Maybe<Scalars['String']>;
  /**
   * - Creates reminder for publications
   *
   * ***Constraints:***:
   *
   *   \- Profile must be for an artist, it should be active and accepted
   */
  sendProfilePublicationReminder?: Maybe<Scalars['Boolean']>;
  sendSimpleInvitation: Scalars['Int'];
  setAnonFCMToken?: Maybe<Scalars['Int']>;
  /**
   * Creates or updates an artist Card Grid collection with its associated artworks.
   *
   * ***Constraints:***
   *
   *   \- Only artist context.
   */
  setCardGridArtworks?: Maybe<Scalars['Boolean']>;
  /**
   * Creates a favorite collection, if not exists for the context id.
   *
   * Sets an artwork as favorite by inserting or deleting the artwork to the collection.
   *
   * ***Constraints:***
   *
   *   \- Only an artist or collector profile can set a favorite.
   */
  setFavoriteArtwork?: Maybe<Scalars['Boolean']>;
  /**
   * Inserts or udpates a recognition to a context id
   *
   * If new recognition then flags a new recognition flag
   *
   * ***Constraints:***
   *
   *   \- It's not possible to self recognize
   */
  setFollowRelation?: Maybe<Scalars['Int']>;
  /** Reorders all the recognitions for an specified context */
  setFollowRelationOrder?: Maybe<Scalars['Boolean']>;
  setMyFCMToken?: Maybe<Scalars['Int']>;
  /**
   * Inserts an artwork to a SERIES collection.
   *
   * ***Constraints:***
   *
   *   \- collection_id should be a SERIES collection
   */
  setSeriesForArtwork?: Maybe<Scalars['Int']>;
  /**
   * Inserts the artworks to the Showroom collection and deletes previous artworks.
   *
   * ***Constraints:***
   *
   *   \- Only the owner of the collection can edit it.
   */
  setShowroomArtworks?: Maybe<Scalars['Boolean']>;
  /** Inserts or udpates mail notifications preferences */
  setSubscriptionToNotificationGroup: Scalars['Boolean'];
  softDeleteAccount?: Maybe<Scalars['String']>;
  startChat?: Maybe<Scalars['String']>;
  stepOneChangeEmail: Scalars['String'];
  stepTwoChangeEmail: StepTwoType;
  thirdPartySignIn: ThirdPartySignInOutputType;
  /**
   * Inserts a new artwork or print transaction as INITIALIZED and set the artwork or print to locked.
   *
   * This method uses the collector context id.
   *
   * This process continues with the Hasura Cron Trigger triggerUpdatePostInitializeTransactions (transactionCronjob).
   *
   * ***Constraints:***
   *
   *   \- Only public and not locked artwork or print
   */
  transferArtworkToCollector?: Maybe<Scalars['Int']>;
  /**
   * Inserts a new artwork or print transaction as INITIALIZED and set the artwork or print to locked.
   *
   * This method uses the collector email.
   *
   * This process continues with the Hasura Cron Trigger triggerUpdatePostInitializeTransactions (transactionCronjob).
   *
   * ***Constraints:***
   *
   *   \- Only public and not locked artwork or print
   */
  transferArtworkToEmail?: Maybe<Scalars['Int']>;
  trashMyFCMToken?: Maybe<Scalars['Int']>;
  triggerAiIssueEmail?: Maybe<Scalars['Boolean']>;
  triggerUsersAutoUpgradedToMyStudioEmails?: Maybe<Scalars['Boolean']>;
  triggerWelcomeArtUniverseEmail: Scalars['Boolean'];
  unsubscribeFromArtistNewsletter?: Maybe<Scalars['Boolean']>;
  updateArtistProfile: Scalars['Boolean'];
  /**
   * Inserts or updates the vumark file id.
   *
   * Send a COA request.
   *
   * ***Constraints:***
   *
   *   \- Upoloads to vumark if it's an insert
   */
  updateCertificateOfAuthenticity?: Maybe<Scalars['bigint']>;
  updateProfileStatus?: Maybe<Scalars['Boolean']>;
  updatePubNubChannelLastMessage?: Maybe<Scalars['String']>;
  updatePubNubChannelLastRead?: Maybe<Scalars['String']>;
  updateSubscriptionPlan: Scalars['Boolean'];
  /** update data of the table: "Account_initial_profile_type" */
  update_Account_initial_profile_type?: Maybe<Account_Initial_Profile_Type_Mutation_Response>;
  /** update single row of the table: "Account_initial_profile_type" */
  update_Account_initial_profile_type_by_pk?: Maybe<Account_Initial_Profile_Type>;
  /** update data of the table: "Account_notice_ack" */
  update_Account_notice_ack?: Maybe<Account_Notice_Ack_Mutation_Response>;
  /** update single row of the table: "Account_notice_ack" */
  update_Account_notice_ack_by_pk?: Maybe<Account_Notice_Ack>;
  /** update data of the table: "Account_validation" */
  update_Account_validation?: Maybe<Account_Validation_Mutation_Response>;
  /** update single row of the table: "Account_validation" */
  update_Account_validation_by_pk?: Maybe<Account_Validation>;
  /** update data of the table: "Achievement_hooks" */
  update_Achievement_hooks?: Maybe<Achievement_Hooks_Mutation_Response>;
  /** update single row of the table: "Achievement_hooks" */
  update_Achievement_hooks_by_pk?: Maybe<Achievement_Hooks>;
  /** update data of the table: "Achievement_tags" */
  update_Achievement_tags?: Maybe<Achievement_Tags_Mutation_Response>;
  /** update single row of the table: "Achievement_tags" */
  update_Achievement_tags_by_pk?: Maybe<Achievement_Tags>;
  /** update data of the table: "Achievements" */
  update_Achievements?: Maybe<Achievements_Mutation_Response>;
  /** update single row of the table: "Achievements" */
  update_Achievements_by_pk?: Maybe<Achievements>;
  /** update data of the table: "Admin_password" */
  update_Admin_password?: Maybe<Admin_Password_Mutation_Response>;
  /** update single row of the table: "Admin_password" */
  update_Admin_password_by_pk?: Maybe<Admin_Password>;
  /** update data of the table: "Admin_to_user_notification" */
  update_Admin_to_user_notification?: Maybe<Admin_To_User_Notification_Mutation_Response>;
  /** update single row of the table: "Admin_to_user_notification" */
  update_Admin_to_user_notification_by_pk?: Maybe<Admin_To_User_Notification>;
  /** update data of the table: "All_artwork_availability_table" */
  update_All_artwork_availability_table?: Maybe<All_Artwork_Availability_Table_Mutation_Response>;
  /** update single row of the table: "All_artwork_availability_table" */
  update_All_artwork_availability_table_by_pk?: Maybe<All_Artwork_Availability_Table>;
  /** update data of the table: "Analytics_events" */
  update_Analytics_events?: Maybe<Analytics_Events_Mutation_Response>;
  /** update single row of the table: "Analytics_events" */
  update_Analytics_events_by_pk?: Maybe<Analytics_Events>;
  /** update data of the table: "Analytics_index_history" */
  update_Analytics_index_history?: Maybe<Analytics_Index_History_Mutation_Response>;
  /** update single row of the table: "Analytics_index_history" */
  update_Analytics_index_history_by_pk?: Maybe<Analytics_Index_History>;
  /** update data of the table: "Analytics_index_type" */
  update_Analytics_index_type?: Maybe<Analytics_Index_Type_Mutation_Response>;
  /** update single row of the table: "Analytics_index_type" */
  update_Analytics_index_type_by_pk?: Maybe<Analytics_Index_Type>;
  /** update data of the table: "Analytics_index_values" */
  update_Analytics_index_values?: Maybe<Analytics_Index_Values_Mutation_Response>;
  /** update single row of the table: "Analytics_index_values" */
  update_Analytics_index_values_by_pk?: Maybe<Analytics_Index_Values>;
  /** update data of the table: "Analytics_kaleidoCard_Amounts" */
  update_Analytics_kaleidoCard_Amounts?: Maybe<Analytics_KaleidoCard_Amounts_Mutation_Response>;
  /** update single row of the table: "Analytics_kaleidoCard_Amounts" */
  update_Analytics_kaleidoCard_Amounts_by_pk?: Maybe<Analytics_KaleidoCard_Amounts>;
  /** update data of the table: "Analytics_kaleidoCard_events" */
  update_Analytics_kaleidoCard_events?: Maybe<Analytics_KaleidoCard_Events_Mutation_Response>;
  /** update single row of the table: "Analytics_kaleidoCard_events" */
  update_Analytics_kaleidoCard_events_by_pk?: Maybe<Analytics_KaleidoCard_Events>;
  /** update data of the table: "App_notifications" */
  update_App_notifications?: Maybe<App_Notifications_Mutation_Response>;
  /** update single row of the table: "App_notifications" */
  update_App_notifications_by_pk?: Maybe<App_Notifications>;
  /** update data of the table: "Art_history_movement" */
  update_Art_history_movement?: Maybe<Art_History_Movement_Mutation_Response>;
  /** update single row of the table: "Art_history_movement" */
  update_Art_history_movement_by_pk?: Maybe<Art_History_Movement>;
  /** update data of the table: "Art_prize_artwork" */
  update_Art_prize_artwork?: Maybe<Art_Prize_Artwork_Mutation_Response>;
  /** update single row of the table: "Art_prize_artwork" */
  update_Art_prize_artwork_by_pk?: Maybe<Art_Prize_Artwork>;
  /** update data of the table: "Art_prize_show" */
  update_Art_prize_show?: Maybe<Art_Prize_Show_Mutation_Response>;
  /** update single row of the table: "Art_prize_show" */
  update_Art_prize_show_by_pk?: Maybe<Art_Prize_Show>;
  /** update data of the table: "Art_prize_show_winners" */
  update_Art_prize_show_winners?: Maybe<Art_Prize_Show_Winners_Mutation_Response>;
  /** update single row of the table: "Art_prize_show_winners" */
  update_Art_prize_show_winners_by_pk?: Maybe<Art_Prize_Show_Winners>;
  /** update data of the table: "Art_prize_winner" */
  update_Art_prize_winner?: Maybe<Art_Prize_Winner_Mutation_Response>;
  /** update single row of the table: "Art_prize_winner" */
  update_Art_prize_winner_by_pk?: Maybe<Art_Prize_Winner>;
  /** update data of the table: "Art_prizes_links_filtered" */
  update_Art_prizes_links_filtered?: Maybe<Art_Prizes_Links_Filtered_Mutation_Response>;
  /** update data of the table: "Art_show_details" */
  update_Art_show_details?: Maybe<Art_Show_Details_Mutation_Response>;
  /** update single row of the table: "Art_show_details" */
  update_Art_show_details_by_pk?: Maybe<Art_Show_Details>;
  /** update data of the table: "Art_type_to_Artwork_discipline" */
  update_Art_type_to_Artwork_discipline?: Maybe<Art_Type_To_Artwork_Discipline_Mutation_Response>;
  /** update single row of the table: "Art_type_to_Artwork_discipline" */
  update_Art_type_to_Artwork_discipline_by_pk?: Maybe<Art_Type_To_Artwork_Discipline>;
  /** update data of the table: "Artist_Collection" */
  update_Artist_Collection?: Maybe<Artist_Collection_Mutation_Response>;
  /** update data of the table: "Artist_Collection_Categories" */
  update_Artist_Collection_Categories?: Maybe<Artist_Collection_Categories_Mutation_Response>;
  /** update single row of the table: "Artist_Collection_Categories" */
  update_Artist_Collection_Categories_by_pk?: Maybe<Artist_Collection_Categories>;
  /** update single row of the table: "Artist_Collection" */
  update_Artist_Collection_by_pk?: Maybe<Artist_Collection>;
  /** update data of the table: "Artist_Collection_to_Artist_Context" */
  update_Artist_Collection_to_Artist_Context?: Maybe<Artist_Collection_To_Artist_Context_Mutation_Response>;
  /** update single row of the table: "Artist_Collection_to_Artist_Context" */
  update_Artist_Collection_to_Artist_Context_by_pk?: Maybe<Artist_Collection_To_Artist_Context>;
  /** update data of the table: "Artist_art_history_visibility" */
  update_Artist_art_history_visibility?: Maybe<Artist_Art_History_Visibility_Mutation_Response>;
  /** update single row of the table: "Artist_art_history_visibility" */
  update_Artist_art_history_visibility_by_pk?: Maybe<Artist_Art_History_Visibility>;
  /** update data of the table: "Artist_curation_score" */
  update_Artist_curation_score?: Maybe<Artist_Curation_Score_Mutation_Response>;
  /** update single row of the table: "Artist_curation_score" */
  update_Artist_curation_score_by_pk?: Maybe<Artist_Curation_Score>;
  /** update data of the table: "Artist_explore_visibility" */
  update_Artist_explore_visibility?: Maybe<Artist_Explore_Visibility_Mutation_Response>;
  /** update single row of the table: "Artist_explore_visibility" */
  update_Artist_explore_visibility_by_pk?: Maybe<Artist_Explore_Visibility>;
  /** update data of the table: "Artist_links_filtered" */
  update_Artist_links_filtered?: Maybe<Artist_Links_Filtered_Mutation_Response>;
  /** update data of the table: "Artist_newsletter" */
  update_Artist_newsletter?: Maybe<Artist_Newsletter_Mutation_Response>;
  /** update single row of the table: "Artist_newsletter" */
  update_Artist_newsletter_by_pk?: Maybe<Artist_Newsletter>;
  /** update data of the table: "Artist_vision_visibility" */
  update_Artist_vision_visibility?: Maybe<Artist_Vision_Visibility_Mutation_Response>;
  /** update single row of the table: "Artist_vision_visibility" */
  update_Artist_vision_visibility_by_pk?: Maybe<Artist_Vision_Visibility>;
  /** update data of the table: "Artwork" */
  update_Artwork?: Maybe<Artwork_Mutation_Response>;
  /** update data of the table: "Artwork_ai_tags" */
  update_Artwork_ai_tags?: Maybe<Artwork_Ai_Tags_Mutation_Response>;
  /** update single row of the table: "Artwork_ai_tags" */
  update_Artwork_ai_tags_by_pk?: Maybe<Artwork_Ai_Tags>;
  /** update data of the table: "Artwork_art_history_timetable" */
  update_Artwork_art_history_timetable?: Maybe<Artwork_Art_History_Timetable_Mutation_Response>;
  /** update single row of the table: "Artwork_art_history_timetable" */
  update_Artwork_art_history_timetable_by_pk?: Maybe<Artwork_Art_History_Timetable>;
  /** update data of the table: "Artwork_aspect_ratio" */
  update_Artwork_aspect_ratio?: Maybe<Artwork_Aspect_Ratio_Mutation_Response>;
  /** update single row of the table: "Artwork_aspect_ratio" */
  update_Artwork_aspect_ratio_by_pk?: Maybe<Artwork_Aspect_Ratio>;
  /** update single row of the table: "Artwork" */
  update_Artwork_by_pk?: Maybe<Artwork>;
  /** update data of the table: "Artwork_count_milestones" */
  update_Artwork_count_milestones?: Maybe<Artwork_Count_Milestones_Mutation_Response>;
  /** update single row of the table: "Artwork_count_milestones" */
  update_Artwork_count_milestones_by_pk?: Maybe<Artwork_Count_Milestones>;
  /** update data of the table: "Artwork_curation_score" */
  update_Artwork_curation_score?: Maybe<Artwork_Curation_Score_Mutation_Response>;
  /** update single row of the table: "Artwork_curation_score" */
  update_Artwork_curation_score_by_pk?: Maybe<Artwork_Curation_Score>;
  /** update data of the table: "Artwork_details" */
  update_Artwork_details?: Maybe<Artwork_Details_Mutation_Response>;
  /** update single row of the table: "Artwork_details" */
  update_Artwork_details_by_pk?: Maybe<Artwork_Details>;
  /** update data of the table: "Artwork_details_translation" */
  update_Artwork_details_translation?: Maybe<Artwork_Details_Translation_Mutation_Response>;
  /** update single row of the table: "Artwork_details_translation" */
  update_Artwork_details_translation_by_pk?: Maybe<Artwork_Details_Translation>;
  /** update data of the table: "Artwork_digital_twin" */
  update_Artwork_digital_twin?: Maybe<Artwork_Digital_Twin_Mutation_Response>;
  /** update single row of the table: "Artwork_digital_twin" */
  update_Artwork_digital_twin_by_pk?: Maybe<Artwork_Digital_Twin>;
  /** update data of the table: "Artwork_events" */
  update_Artwork_events?: Maybe<Artwork_Events_Mutation_Response>;
  /** update single row of the table: "Artwork_events" */
  update_Artwork_events_by_pk?: Maybe<Artwork_Events>;
  /** update data of the table: "Artwork_external_buy_link" */
  update_Artwork_external_buy_link?: Maybe<Artwork_External_Buy_Link_Mutation_Response>;
  /** update single row of the table: "Artwork_external_buy_link" */
  update_Artwork_external_buy_link_by_pk?: Maybe<Artwork_External_Buy_Link>;
  /** update data of the table: "Artwork_prices" */
  update_Artwork_prices?: Maybe<Artwork_Prices_Mutation_Response>;
  /** update single row of the table: "Artwork_prices" */
  update_Artwork_prices_by_pk?: Maybe<Artwork_Prices>;
  /** update data of the table: "Artwork_purchase_journey" */
  update_Artwork_purchase_journey?: Maybe<Artwork_Purchase_Journey_Mutation_Response>;
  /** update single row of the table: "Artwork_purchase_journey" */
  update_Artwork_purchase_journey_by_pk?: Maybe<Artwork_Purchase_Journey>;
  /** update data of the table: "Artwork_purchase_journey_history" */
  update_Artwork_purchase_journey_history?: Maybe<Artwork_Purchase_Journey_History_Mutation_Response>;
  /** update single row of the table: "Artwork_purchase_journey_history" */
  update_Artwork_purchase_journey_history_by_pk?: Maybe<Artwork_Purchase_Journey_History>;
  /** update data of the table: "Artwork_purchase_journey_last_update" */
  update_Artwork_purchase_journey_last_update?: Maybe<Artwork_Purchase_Journey_Last_Update_Mutation_Response>;
  /** update single row of the table: "Artwork_purchase_journey_last_update" */
  update_Artwork_purchase_journey_last_update_by_pk?: Maybe<Artwork_Purchase_Journey_Last_Update>;
  /** update data of the table: "Artwork_purchase_journey_pricing" */
  update_Artwork_purchase_journey_pricing?: Maybe<Artwork_Purchase_Journey_Pricing_Mutation_Response>;
  /** update single row of the table: "Artwork_purchase_journey_pricing" */
  update_Artwork_purchase_journey_pricing_by_pk?: Maybe<Artwork_Purchase_Journey_Pricing>;
  /** update data of the table: "Artwork_purchase_journey_shipping_info" */
  update_Artwork_purchase_journey_shipping_info?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Mutation_Response>;
  /** update single row of the table: "Artwork_purchase_journey_shipping_info" */
  update_Artwork_purchase_journey_shipping_info_by_pk?: Maybe<Artwork_Purchase_Journey_Shipping_Info>;
  /** update data of the table: "Artwork_secondary_media" */
  update_Artwork_secondary_media?: Maybe<Artwork_Secondary_Media_Mutation_Response>;
  /** update single row of the table: "Artwork_secondary_media" */
  update_Artwork_secondary_media_by_pk?: Maybe<Artwork_Secondary_Media>;
  /** update data of the table: "Artwork_state_history" */
  update_Artwork_state_history?: Maybe<Artwork_State_History_Mutation_Response>;
  /** update single row of the table: "Artwork_state_history" */
  update_Artwork_state_history_by_pk?: Maybe<Artwork_State_History>;
  /** update data of the table: "Artwork_story" */
  update_Artwork_story?: Maybe<Artwork_Story_Mutation_Response>;
  /** update single row of the table: "Artwork_story" */
  update_Artwork_story_by_pk?: Maybe<Artwork_Story>;
  /** update data of the table: "Artwork_story_media" */
  update_Artwork_story_media?: Maybe<Artwork_Story_Media_Mutation_Response>;
  /** update single row of the table: "Artwork_story_media" */
  update_Artwork_story_media_by_pk?: Maybe<Artwork_Story_Media>;
  /** update data of the table: "Artwork_story_video_links" */
  update_Artwork_story_video_links?: Maybe<Artwork_Story_Video_Links_Mutation_Response>;
  /** update single row of the table: "Artwork_story_video_links" */
  update_Artwork_story_video_links_by_pk?: Maybe<Artwork_Story_Video_Links>;
  /** update data of the table: "Artwork_tag" */
  update_Artwork_tag?: Maybe<Artwork_Tag_Mutation_Response>;
  /** update single row of the table: "Artwork_tag" */
  update_Artwork_tag_by_pk?: Maybe<Artwork_Tag>;
  /** update data of the table: "Artwork_to_Collection" */
  update_Artwork_to_Collection?: Maybe<Artwork_To_Collection_Mutation_Response>;
  /** update single row of the table: "Artwork_to_Collection" */
  update_Artwork_to_Collection_by_pk?: Maybe<Artwork_To_Collection>;
  /** update data of the table: "Artwork_to_showroom_details" */
  update_Artwork_to_showroom_details?: Maybe<Artwork_To_Showroom_Details_Mutation_Response>;
  /** update single row of the table: "Artwork_to_showroom_details" */
  update_Artwork_to_showroom_details_by_pk?: Maybe<Artwork_To_Showroom_Details>;
  /** update data of the table: "Artwork_to_tag" */
  update_Artwork_to_tag?: Maybe<Artwork_To_Tag_Mutation_Response>;
  /** update single row of the table: "Artwork_to_tag" */
  update_Artwork_to_tag_by_pk?: Maybe<Artwork_To_Tag>;
  /** update data of the table: "Artwork_transactions" */
  update_Artwork_transactions?: Maybe<Artwork_Transactions_Mutation_Response>;
  /** update single row of the table: "Artwork_transactions" */
  update_Artwork_transactions_by_pk?: Maybe<Artwork_Transactions>;
  /** update data of the table: "Auth" */
  update_Auth?: Maybe<Auth_Mutation_Response>;
  /** update single row of the table: "Auth" */
  update_Auth_by_pk?: Maybe<Auth>;
  /** update data of the table: "Auth_context_access" */
  update_Auth_context_access?: Maybe<Auth_Context_Access_Mutation_Response>;
  /** update single row of the table: "Auth_context_access" */
  update_Auth_context_access_by_pk?: Maybe<Auth_Context_Access>;
  /** update data of the table: "Available_print_price" */
  update_Available_print_price?: Maybe<Available_Print_Price_Mutation_Response>;
  /** update single row of the table: "Available_print_price" */
  update_Available_print_price_by_pk?: Maybe<Available_Print_Price>;
  /** update data of the table: "Billing_anon_client" */
  update_Billing_anon_client?: Maybe<Billing_Anon_Client_Mutation_Response>;
  /** update single row of the table: "Billing_anon_client" */
  update_Billing_anon_client_by_pk?: Maybe<Billing_Anon_Client>;
  /** update data of the table: "Billing_artwork_payment_intent" */
  update_Billing_artwork_payment_intent?: Maybe<Billing_Artwork_Payment_Intent_Mutation_Response>;
  /** update single row of the table: "Billing_artwork_payment_intent" */
  update_Billing_artwork_payment_intent_by_pk?: Maybe<Billing_Artwork_Payment_Intent>;
  /** update data of the table: "Billing_artwork_payment_intent_product" */
  update_Billing_artwork_payment_intent_product?: Maybe<Billing_Artwork_Payment_Intent_Product_Mutation_Response>;
  /** update single row of the table: "Billing_artwork_payment_intent_product" */
  update_Billing_artwork_payment_intent_product_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Product>;
  /** update data of the table: "Billing_artwork_payment_intent_shipping_address" */
  update_Billing_artwork_payment_intent_shipping_address?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Mutation_Response>;
  /** update single row of the table: "Billing_artwork_payment_intent_shipping_address" */
  update_Billing_artwork_payment_intent_shipping_address_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address>;
  /** update data of the table: "Billing_artwork_payment_intent_status_history" */
  update_Billing_artwork_payment_intent_status_history?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Mutation_Response>;
  /** update single row of the table: "Billing_artwork_payment_intent_status_history" */
  update_Billing_artwork_payment_intent_status_history_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Status_History>;
  /** update data of the table: "Billing_client" */
  update_Billing_client?: Maybe<Billing_Client_Mutation_Response>;
  /** update single row of the table: "Billing_client" */
  update_Billing_client_by_pk?: Maybe<Billing_Client>;
  /** update data of the table: "Billing_seller" */
  update_Billing_seller?: Maybe<Billing_Seller_Mutation_Response>;
  /** update single row of the table: "Billing_seller" */
  update_Billing_seller_by_pk?: Maybe<Billing_Seller>;
  /** update data of the table: "Billing_subscription" */
  update_Billing_subscription?: Maybe<Billing_Subscription_Mutation_Response>;
  /** update single row of the table: "Billing_subscription" */
  update_Billing_subscription_by_pk?: Maybe<Billing_Subscription>;
  /** update data of the table: "Billing_subscription_product" */
  update_Billing_subscription_product?: Maybe<Billing_Subscription_Product_Mutation_Response>;
  /** update single row of the table: "Billing_subscription_product" */
  update_Billing_subscription_product_by_pk?: Maybe<Billing_Subscription_Product>;
  /** update data of the table: "Biolink_sections" */
  update_Biolink_sections?: Maybe<Biolink_Sections_Mutation_Response>;
  /** update single row of the table: "Biolink_sections" */
  update_Biolink_sections_by_pk?: Maybe<Biolink_Sections>;
  /** update data of the table: "Certificate_of_authenticity" */
  update_Certificate_of_authenticity?: Maybe<Certificate_Of_Authenticity_Mutation_Response>;
  /** update single row of the table: "Certificate_of_authenticity" */
  update_Certificate_of_authenticity_by_pk?: Maybe<Certificate_Of_Authenticity>;
  /** update data of the table: "Collaboration" */
  update_Collaboration?: Maybe<Collaboration_Mutation_Response>;
  /** update single row of the table: "Collaboration" */
  update_Collaboration_by_pk?: Maybe<Collaboration>;
  /** update data of the table: "Collaboration_invitation_on_artwork" */
  update_Collaboration_invitation_on_artwork?: Maybe<Collaboration_Invitation_On_Artwork_Mutation_Response>;
  /** update single row of the table: "Collaboration_invitation_on_artwork" */
  update_Collaboration_invitation_on_artwork_by_pk?: Maybe<Collaboration_Invitation_On_Artwork>;
  /** update data of the table: "Collection" */
  update_Collection?: Maybe<Collection_Mutation_Response>;
  /** update single row of the table: "Collection" */
  update_Collection_by_pk?: Maybe<Collection>;
  /** update data of the table: "Collection_translation" */
  update_Collection_translation?: Maybe<Collection_Translation_Mutation_Response>;
  /** update single row of the table: "Collection_translation" */
  update_Collection_translation_by_pk?: Maybe<Collection_Translation>;
  /** update data of the table: "Collection_video_links" */
  update_Collection_video_links?: Maybe<Collection_Video_Links_Mutation_Response>;
  /** update single row of the table: "Collection_video_links" */
  update_Collection_video_links_by_pk?: Maybe<Collection_Video_Links>;
  /** update data of the table: "Collections_links_filtered" */
  update_Collections_links_filtered?: Maybe<Collections_Links_Filtered_Mutation_Response>;
  /** update data of the table: "Collector_links_filtered" */
  update_Collector_links_filtered?: Maybe<Collector_Links_Filtered_Mutation_Response>;
  /** update data of the table: "Connected_domain" */
  update_Connected_domain?: Maybe<Connected_Domain_Mutation_Response>;
  /** update single row of the table: "Connected_domain" */
  update_Connected_domain_by_pk?: Maybe<Connected_Domain>;
  /** update data of the table: "Content_translations" */
  update_Content_translations?: Maybe<Content_Translations_Mutation_Response>;
  /** update data of the table: "Context" */
  update_Context?: Maybe<Context_Mutation_Response>;
  /** update single row of the table: "Context" */
  update_Context_by_pk?: Maybe<Context>;
  /** update data of the table: "Context_delegate_access" */
  update_Context_delegate_access?: Maybe<Context_Delegate_Access_Mutation_Response>;
  /** update single row of the table: "Context_delegate_access" */
  update_Context_delegate_access_by_pk?: Maybe<Context_Delegate_Access>;
  /** update data of the table: "Context_flags" */
  update_Context_flags?: Maybe<Context_Flags_Mutation_Response>;
  /** update single row of the table: "Context_flags" */
  update_Context_flags_by_pk?: Maybe<Context_Flags>;
  /** update data of the table: "Context_suggested_tags" */
  update_Context_suggested_tags?: Maybe<Context_Suggested_Tags_Mutation_Response>;
  /** update single row of the table: "Context_suggested_tags" */
  update_Context_suggested_tags_by_pk?: Maybe<Context_Suggested_Tags>;
  /** update data of the table: "Context_to_milestone" */
  update_Context_to_milestone?: Maybe<Context_To_Milestone_Mutation_Response>;
  /** update single row of the table: "Context_to_milestone" */
  update_Context_to_milestone_by_pk?: Maybe<Context_To_Milestone>;
  /** update data of the table: "Context_to_tutorial" */
  update_Context_to_tutorial?: Maybe<Context_To_Tutorial_Mutation_Response>;
  /** update single row of the table: "Context_to_tutorial" */
  update_Context_to_tutorial_by_pk?: Maybe<Context_To_Tutorial>;
  /** update data of the table: "Countries" */
  update_Countries?: Maybe<Countries_Mutation_Response>;
  /** update single row of the table: "Countries" */
  update_Countries_by_pk?: Maybe<Countries>;
  /** update data of the table: "Crop_Area" */
  update_Crop_Area?: Maybe<Crop_Area_Mutation_Response>;
  /** update single row of the table: "Crop_Area" */
  update_Crop_Area_by_pk?: Maybe<Crop_Area>;
  /** update data of the table: "Currency_conversion" */
  update_Currency_conversion?: Maybe<Currency_Conversion_Mutation_Response>;
  /** update single row of the table: "Currency_conversion" */
  update_Currency_conversion_by_pk?: Maybe<Currency_Conversion>;
  /** update data of the table: "Digital_twin_details" */
  update_Digital_twin_details?: Maybe<Digital_Twin_Details_Mutation_Response>;
  /** update single row of the table: "Digital_twin_details" */
  update_Digital_twin_details_by_pk?: Maybe<Digital_Twin_Details>;
  /** update data of the table: "Discount_code" */
  update_Discount_code?: Maybe<Discount_Code_Mutation_Response>;
  /** update single row of the table: "Discount_code" */
  update_Discount_code_by_pk?: Maybe<Discount_Code>;
  /** update data of the table: "Discount_code_usage" */
  update_Discount_code_usage?: Maybe<Discount_Code_Usage_Mutation_Response>;
  /** update single row of the table: "Discount_code_usage" */
  update_Discount_code_usage_by_pk?: Maybe<Discount_Code_Usage>;
  /** update data of the table: "Domain_registrant_info" */
  update_Domain_registrant_info?: Maybe<Domain_Registrant_Info_Mutation_Response>;
  /** update single row of the table: "Domain_registrant_info" */
  update_Domain_registrant_info_by_pk?: Maybe<Domain_Registrant_Info>;
  /** update data of the table: "Domain_registration" */
  update_Domain_registration?: Maybe<Domain_Registration_Mutation_Response>;
  /** update single row of the table: "Domain_registration" */
  update_Domain_registration_by_pk?: Maybe<Domain_Registration>;
  /** update data of the table: "Dummy_table_for_front" */
  update_Dummy_table_for_front?: Maybe<Dummy_Table_For_Front_Mutation_Response>;
  /** update single row of the table: "Dummy_table_for_front" */
  update_Dummy_table_for_front_by_pk?: Maybe<Dummy_Table_For_Front>;
  /** update data of the table: "Dynamic_cv" */
  update_Dynamic_cv?: Maybe<Dynamic_Cv_Mutation_Response>;
  /** update data of the table: "Dynamic_cv_award" */
  update_Dynamic_cv_award?: Maybe<Dynamic_Cv_Award_Mutation_Response>;
  /** update single row of the table: "Dynamic_cv_award" */
  update_Dynamic_cv_award_by_pk?: Maybe<Dynamic_Cv_Award>;
  /** update single row of the table: "Dynamic_cv" */
  update_Dynamic_cv_by_pk?: Maybe<Dynamic_Cv>;
  /** update data of the table: "Dynamic_cv_collection" */
  update_Dynamic_cv_collection?: Maybe<Dynamic_Cv_Collection_Mutation_Response>;
  /** update single row of the table: "Dynamic_cv_collection" */
  update_Dynamic_cv_collection_by_pk?: Maybe<Dynamic_Cv_Collection>;
  /** update data of the table: "Dynamic_cv_contact" */
  update_Dynamic_cv_contact?: Maybe<Dynamic_Cv_Contact_Mutation_Response>;
  /** update single row of the table: "Dynamic_cv_contact" */
  update_Dynamic_cv_contact_by_pk?: Maybe<Dynamic_Cv_Contact>;
  /** update data of the table: "Dynamic_cv_education" */
  update_Dynamic_cv_education?: Maybe<Dynamic_Cv_Education_Mutation_Response>;
  /** update single row of the table: "Dynamic_cv_education" */
  update_Dynamic_cv_education_by_pk?: Maybe<Dynamic_Cv_Education>;
  /** update data of the table: "Dynamic_cv_exhibition" */
  update_Dynamic_cv_exhibition?: Maybe<Dynamic_Cv_Exhibition_Mutation_Response>;
  /** update single row of the table: "Dynamic_cv_exhibition" */
  update_Dynamic_cv_exhibition_by_pk?: Maybe<Dynamic_Cv_Exhibition>;
  /** update data of the table: "Dynamic_cv_publication" */
  update_Dynamic_cv_publication?: Maybe<Dynamic_Cv_Publication_Mutation_Response>;
  /** update single row of the table: "Dynamic_cv_publication" */
  update_Dynamic_cv_publication_by_pk?: Maybe<Dynamic_Cv_Publication>;
  /** update data of the table: "Dynamic_cv_section" */
  update_Dynamic_cv_section?: Maybe<Dynamic_Cv_Section_Mutation_Response>;
  /** update single row of the table: "Dynamic_cv_section" */
  update_Dynamic_cv_section_by_pk?: Maybe<Dynamic_Cv_Section>;
  /** update data of the table: "ENUM_account_notice_type" */
  update_ENUM_account_notice_type?: Maybe<Enum_Account_Notice_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_account_notice_type" */
  update_ENUM_account_notice_type_by_pk?: Maybe<Enum_Account_Notice_Type>;
  /** update data of the table: "ENUM_achievement_tags" */
  update_ENUM_achievement_tags?: Maybe<Enum_Achievement_Tags_Mutation_Response>;
  /** update single row of the table: "ENUM_achievement_tags" */
  update_ENUM_achievement_tags_by_pk?: Maybe<Enum_Achievement_Tags>;
  /** update data of the table: "ENUM_add_artwork_flow_step" */
  update_ENUM_add_artwork_flow_step?: Maybe<Enum_Add_Artwork_Flow_Step_Mutation_Response>;
  /** update single row of the table: "ENUM_add_artwork_flow_step" */
  update_ENUM_add_artwork_flow_step_by_pk?: Maybe<Enum_Add_Artwork_Flow_Step>;
  /** update data of the table: "ENUM_analytics_kaleidoCard_category" */
  update_ENUM_analytics_kaleidoCard_category?: Maybe<Enum_Analytics_KaleidoCard_Category_Mutation_Response>;
  /** update single row of the table: "ENUM_analytics_kaleidoCard_category" */
  update_ENUM_analytics_kaleidoCard_category_by_pk?: Maybe<Enum_Analytics_KaleidoCard_Category>;
  /** update data of the table: "ENUM_analytics_type" */
  update_ENUM_analytics_type?: Maybe<Enum_Analytics_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_analytics_type" */
  update_ENUM_analytics_type_by_pk?: Maybe<Enum_Analytics_Type>;
  /** update data of the table: "ENUM_app_notification_type" */
  update_ENUM_app_notification_type?: Maybe<Enum_App_Notification_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_app_notification_type" */
  update_ENUM_app_notification_type_by_pk?: Maybe<Enum_App_Notification_Type>;
  /** update data of the table: "ENUM_art_type" */
  update_ENUM_art_type?: Maybe<Enum_Art_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_art_type" */
  update_ENUM_art_type_by_pk?: Maybe<Enum_Art_Type>;
  /** update data of the table: "ENUM_art_type_extension" */
  update_ENUM_art_type_extension?: Maybe<Enum_Art_Type_Extension_Mutation_Response>;
  /** update single row of the table: "ENUM_art_type_extension" */
  update_ENUM_art_type_extension_by_pk?: Maybe<Enum_Art_Type_Extension>;
  /** update data of the table: "ENUM_artist_collection_categories" */
  update_ENUM_artist_collection_categories?: Maybe<Enum_Artist_Collection_Categories_Mutation_Response>;
  /** update single row of the table: "ENUM_artist_collection_categories" */
  update_ENUM_artist_collection_categories_by_pk?: Maybe<Enum_Artist_Collection_Categories>;
  /** update data of the table: "ENUM_artist_collection_state" */
  update_ENUM_artist_collection_state?: Maybe<Enum_Artist_Collection_State_Mutation_Response>;
  /** update single row of the table: "ENUM_artist_collection_state" */
  update_ENUM_artist_collection_state_by_pk?: Maybe<Enum_Artist_Collection_State>;
  /** update data of the table: "ENUM_artist_collection_type" */
  update_ENUM_artist_collection_type?: Maybe<Enum_Artist_Collection_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_artist_collection_type" */
  update_ENUM_artist_collection_type_by_pk?: Maybe<Enum_Artist_Collection_Type>;
  /** update data of the table: "ENUM_artwork_category" */
  update_ENUM_artwork_category?: Maybe<Enum_Artwork_Category_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_category" */
  update_ENUM_artwork_category_by_pk?: Maybe<Enum_Artwork_Category>;
  /** update data of the table: "ENUM_artwork_count_milestone_type" */
  update_ENUM_artwork_count_milestone_type?: Maybe<Enum_Artwork_Count_Milestone_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_count_milestone_type" */
  update_ENUM_artwork_count_milestone_type_by_pk?: Maybe<Enum_Artwork_Count_Milestone_Type>;
  /** update data of the table: "ENUM_artwork_discipline" */
  update_ENUM_artwork_discipline?: Maybe<Enum_Artwork_Discipline_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_discipline" */
  update_ENUM_artwork_discipline_by_pk?: Maybe<Enum_Artwork_Discipline>;
  /** update data of the table: "ENUM_artwork_edition_type" */
  update_ENUM_artwork_edition_type?: Maybe<Enum_Artwork_Edition_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_edition_type" */
  update_ENUM_artwork_edition_type_by_pk?: Maybe<Enum_Artwork_Edition_Type>;
  /** update data of the table: "ENUM_artwork_event_type" */
  update_ENUM_artwork_event_type?: Maybe<Enum_Artwork_Event_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_event_type" */
  update_ENUM_artwork_event_type_by_pk?: Maybe<Enum_Artwork_Event_Type>;
  /** update data of the table: "ENUM_artwork_genre" */
  update_ENUM_artwork_genre?: Maybe<Enum_Artwork_Genre_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_genre" */
  update_ENUM_artwork_genre_by_pk?: Maybe<Enum_Artwork_Genre>;
  /** update data of the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  update_ENUM_artwork_purchase_journey_history_entry_kind?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  update_ENUM_artwork_purchase_journey_history_entry_kind_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind>;
  /** update data of the table: "ENUM_artwork_purchase_journey_party" */
  update_ENUM_artwork_purchase_journey_party?: Maybe<Enum_Artwork_Purchase_Journey_Party_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_purchase_journey_party" */
  update_ENUM_artwork_purchase_journey_party_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_Party>;
  /** update data of the table: "ENUM_artwork_purchase_journey_status" */
  update_ENUM_artwork_purchase_journey_status?: Maybe<Enum_Artwork_Purchase_Journey_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_purchase_journey_status" */
  update_ENUM_artwork_purchase_journey_status_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_Status>;
  /** update data of the table: "ENUM_artwork_showroom_status" */
  update_ENUM_artwork_showroom_status?: Maybe<Enum_Artwork_Showroom_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_showroom_status" */
  update_ENUM_artwork_showroom_status_by_pk?: Maybe<Enum_Artwork_Showroom_Status>;
  /** update data of the table: "ENUM_artwork_status" */
  update_ENUM_artwork_status?: Maybe<Enum_Artwork_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_status" */
  update_ENUM_artwork_status_by_pk?: Maybe<Enum_Artwork_Status>;
  /** update data of the table: "ENUM_artwork_tag" */
  update_ENUM_artwork_tag?: Maybe<Enum_Artwork_Tag_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_tag" */
  update_ENUM_artwork_tag_by_pk?: Maybe<Enum_Artwork_Tag>;
  /** update data of the table: "ENUM_artwork_tag_type" */
  update_ENUM_artwork_tag_type?: Maybe<Enum_Artwork_Tag_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_tag_type" */
  update_ENUM_artwork_tag_type_by_pk?: Maybe<Enum_Artwork_Tag_Type>;
  /** update data of the table: "ENUM_artwork_type" */
  update_ENUM_artwork_type?: Maybe<Enum_Artwork_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_artwork_type" */
  update_ENUM_artwork_type_by_pk?: Maybe<Enum_Artwork_Type>;
  /** update data of the table: "ENUM_audio_generation_status" */
  update_ENUM_audio_generation_status?: Maybe<Enum_Audio_Generation_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_audio_generation_status" */
  update_ENUM_audio_generation_status_by_pk?: Maybe<Enum_Audio_Generation_Status>;
  /** update data of the table: "ENUM_auth_type" */
  update_ENUM_auth_type?: Maybe<Enum_Auth_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_auth_type" */
  update_ENUM_auth_type_by_pk?: Maybe<Enum_Auth_Type>;
  /** update data of the table: "ENUM_billing_artwork_payment_intent_status" */
  update_ENUM_billing_artwork_payment_intent_status?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_billing_artwork_payment_intent_status" */
  update_ENUM_billing_artwork_payment_intent_status_by_pk?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status>;
  /** update data of the table: "ENUM_billing_payment_intent_product_status" */
  update_ENUM_billing_payment_intent_product_status?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_billing_payment_intent_product_status" */
  update_ENUM_billing_payment_intent_product_status_by_pk?: Maybe<Enum_Billing_Payment_Intent_Product_Status>;
  /** update data of the table: "ENUM_billing_seller_status" */
  update_ENUM_billing_seller_status?: Maybe<Enum_Billing_Seller_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_billing_seller_status" */
  update_ENUM_billing_seller_status_by_pk?: Maybe<Enum_Billing_Seller_Status>;
  /** update data of the table: "ENUM_billing_subscription_period" */
  update_ENUM_billing_subscription_period?: Maybe<Enum_Billing_Subscription_Period_Mutation_Response>;
  /** update single row of the table: "ENUM_billing_subscription_period" */
  update_ENUM_billing_subscription_period_by_pk?: Maybe<Enum_Billing_Subscription_Period>;
  /** update data of the table: "ENUM_billing_subscription_product_type" */
  update_ENUM_billing_subscription_product_type?: Maybe<Enum_Billing_Subscription_Product_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_billing_subscription_product_type" */
  update_ENUM_billing_subscription_product_type_by_pk?: Maybe<Enum_Billing_Subscription_Product_Type>;
  /** update data of the table: "ENUM_billing_subscription_status" */
  update_ENUM_billing_subscription_status?: Maybe<Enum_Billing_Subscription_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_billing_subscription_status" */
  update_ENUM_billing_subscription_status_by_pk?: Maybe<Enum_Billing_Subscription_Status>;
  /** update data of the table: "ENUM_blockchain" */
  update_ENUM_blockchain?: Maybe<Enum_Blockchain_Mutation_Response>;
  /** update single row of the table: "ENUM_blockchain" */
  update_ENUM_blockchain_by_pk?: Maybe<Enum_Blockchain>;
  /** update data of the table: "ENUM_collaboration_status" */
  update_ENUM_collaboration_status?: Maybe<Enum_Collaboration_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_collaboration_status" */
  update_ENUM_collaboration_status_by_pk?: Maybe<Enum_Collaboration_Status>;
  /** update data of the table: "ENUM_collection_type" */
  update_ENUM_collection_type?: Maybe<Enum_Collection_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_collection_type" */
  update_ENUM_collection_type_by_pk?: Maybe<Enum_Collection_Type>;
  /** update data of the table: "ENUM_connected_domain_state" */
  update_ENUM_connected_domain_state?: Maybe<Enum_Connected_Domain_State_Mutation_Response>;
  /** update single row of the table: "ENUM_connected_domain_state" */
  update_ENUM_connected_domain_state_by_pk?: Maybe<Enum_Connected_Domain_State>;
  /** update data of the table: "ENUM_content_type" */
  update_ENUM_content_type?: Maybe<Enum_Content_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_content_type" */
  update_ENUM_content_type_by_pk?: Maybe<Enum_Content_Type>;
  /** update data of the table: "ENUM_context_delegate_role" */
  update_ENUM_context_delegate_role?: Maybe<Enum_Context_Delegate_Role_Mutation_Response>;
  /** update single row of the table: "ENUM_context_delegate_role" */
  update_ENUM_context_delegate_role_by_pk?: Maybe<Enum_Context_Delegate_Role>;
  /** update data of the table: "ENUM_context_delegate_state" */
  update_ENUM_context_delegate_state?: Maybe<Enum_Context_Delegate_State_Mutation_Response>;
  /** update single row of the table: "ENUM_context_delegate_state" */
  update_ENUM_context_delegate_state_by_pk?: Maybe<Enum_Context_Delegate_State>;
  /** update data of the table: "ENUM_context_state" */
  update_ENUM_context_state?: Maybe<Enum_Context_State_Mutation_Response>;
  /** update single row of the table: "ENUM_context_state" */
  update_ENUM_context_state_by_pk?: Maybe<Enum_Context_State>;
  /** update data of the table: "ENUM_context_type" */
  update_ENUM_context_type?: Maybe<Enum_Context_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_context_type" */
  update_ENUM_context_type_by_pk?: Maybe<Enum_Context_Type>;
  /** update data of the table: "ENUM_discount_state" */
  update_ENUM_discount_state?: Maybe<Enum_Discount_State_Mutation_Response>;
  /** update single row of the table: "ENUM_discount_state" */
  update_ENUM_discount_state_by_pk?: Maybe<Enum_Discount_State>;
  /** update data of the table: "ENUM_discount_type" */
  update_ENUM_discount_type?: Maybe<Enum_Discount_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_discount_type" */
  update_ENUM_discount_type_by_pk?: Maybe<Enum_Discount_Type>;
  /** update data of the table: "ENUM_discount_value_type" */
  update_ENUM_discount_value_type?: Maybe<Enum_Discount_Value_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_discount_value_type" */
  update_ENUM_discount_value_type_by_pk?: Maybe<Enum_Discount_Value_Type>;
  /** update data of the table: "ENUM_domain_registration_status" */
  update_ENUM_domain_registration_status?: Maybe<Enum_Domain_Registration_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_domain_registration_status" */
  update_ENUM_domain_registration_status_by_pk?: Maybe<Enum_Domain_Registration_Status>;
  /** update data of the table: "ENUM_dynamic_cv_exhibition_type" */
  update_ENUM_dynamic_cv_exhibition_type?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_dynamic_cv_exhibition_type" */
  update_ENUM_dynamic_cv_exhibition_type_by_pk?: Maybe<Enum_Dynamic_Cv_Exhibition_Type>;
  /** update data of the table: "ENUM_dynamic_cv_section_type" */
  update_ENUM_dynamic_cv_section_type?: Maybe<Enum_Dynamic_Cv_Section_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_dynamic_cv_section_type" */
  update_ENUM_dynamic_cv_section_type_by_pk?: Maybe<Enum_Dynamic_Cv_Section_Type>;
  /** update data of the table: "ENUM_dynamic_cv_status" */
  update_ENUM_dynamic_cv_status?: Maybe<Enum_Dynamic_Cv_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_dynamic_cv_status" */
  update_ENUM_dynamic_cv_status_by_pk?: Maybe<Enum_Dynamic_Cv_Status>;
  /** update data of the table: "ENUM_edition_status" */
  update_ENUM_edition_status?: Maybe<Enum_Edition_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_edition_status" */
  update_ENUM_edition_status_by_pk?: Maybe<Enum_Edition_Status>;
  /** update data of the table: "ENUM_email_language" */
  update_ENUM_email_language?: Maybe<Enum_Email_Language_Mutation_Response>;
  /** update single row of the table: "ENUM_email_language" */
  update_ENUM_email_language_by_pk?: Maybe<Enum_Email_Language>;
  /** update data of the table: "ENUM_email_status" */
  update_ENUM_email_status?: Maybe<Enum_Email_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_email_status" */
  update_ENUM_email_status_by_pk?: Maybe<Enum_Email_Status>;
  /** update data of the table: "ENUM_email_validation_token_status" */
  update_ENUM_email_validation_token_status?: Maybe<Enum_Email_Validation_Token_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_email_validation_token_status" */
  update_ENUM_email_validation_token_status_by_pk?: Maybe<Enum_Email_Validation_Token_Status>;
  /** update data of the table: "ENUM_file_metadata_status" */
  update_ENUM_file_metadata_status?: Maybe<Enum_File_Metadata_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_file_metadata_status" */
  update_ENUM_file_metadata_status_by_pk?: Maybe<Enum_File_Metadata_Status>;
  /** update data of the table: "ENUM_flags_type" */
  update_ENUM_flags_type?: Maybe<Enum_Flags_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_flags_type" */
  update_ENUM_flags_type_by_pk?: Maybe<Enum_Flags_Type>;
  /** update data of the table: "ENUM_forge_configuration_type" */
  update_ENUM_forge_configuration_type?: Maybe<Enum_Forge_Configuration_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_forge_configuration_type" */
  update_ENUM_forge_configuration_type_by_pk?: Maybe<Enum_Forge_Configuration_Type>;
  /** update data of the table: "ENUM_forge_status" */
  update_ENUM_forge_status?: Maybe<Enum_Forge_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_forge_status" */
  update_ENUM_forge_status_by_pk?: Maybe<Enum_Forge_Status>;
  /** update data of the table: "ENUM_infraction_flag_type" */
  update_ENUM_infraction_flag_type?: Maybe<Enum_Infraction_Flag_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_infraction_flag_type" */
  update_ENUM_infraction_flag_type_by_pk?: Maybe<Enum_Infraction_Flag_Type>;
  /** update data of the table: "ENUM_interest_type" */
  update_ENUM_interest_type?: Maybe<Enum_Interest_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_interest_type" */
  update_ENUM_interest_type_by_pk?: Maybe<Enum_Interest_Type>;
  /** update data of the table: "ENUM_language_preference" */
  update_ENUM_language_preference?: Maybe<Enum_Language_Preference_Mutation_Response>;
  /** update single row of the table: "ENUM_language_preference" */
  update_ENUM_language_preference_by_pk?: Maybe<Enum_Language_Preference>;
  /** update data of the table: "ENUM_my_website_artwork_info" */
  update_ENUM_my_website_artwork_info?: Maybe<Enum_My_Website_Artwork_Info_Mutation_Response>;
  /** update single row of the table: "ENUM_my_website_artwork_info" */
  update_ENUM_my_website_artwork_info_by_pk?: Maybe<Enum_My_Website_Artwork_Info>;
  /** update data of the table: "ENUM_my_website_filters" */
  update_ENUM_my_website_filters?: Maybe<Enum_My_Website_Filters_Mutation_Response>;
  /** update single row of the table: "ENUM_my_website_filters" */
  update_ENUM_my_website_filters_by_pk?: Maybe<Enum_My_Website_Filters>;
  /** update data of the table: "ENUM_my_website_layout" */
  update_ENUM_my_website_layout?: Maybe<Enum_My_Website_Layout_Mutation_Response>;
  /** update single row of the table: "ENUM_my_website_layout" */
  update_ENUM_my_website_layout_by_pk?: Maybe<Enum_My_Website_Layout>;
  /** update data of the table: "ENUM_my_website_navigation" */
  update_ENUM_my_website_navigation?: Maybe<Enum_My_Website_Navigation_Mutation_Response>;
  /** update single row of the table: "ENUM_my_website_navigation" */
  update_ENUM_my_website_navigation_by_pk?: Maybe<Enum_My_Website_Navigation>;
  /** update data of the table: "ENUM_my_website_theme" */
  update_ENUM_my_website_theme?: Maybe<Enum_My_Website_Theme_Mutation_Response>;
  /** update single row of the table: "ENUM_my_website_theme" */
  update_ENUM_my_website_theme_by_pk?: Maybe<Enum_My_Website_Theme>;
  /** update data of the table: "ENUM_notification_channel" */
  update_ENUM_notification_channel?: Maybe<Enum_Notification_Channel_Mutation_Response>;
  /** update single row of the table: "ENUM_notification_channel" */
  update_ENUM_notification_channel_by_pk?: Maybe<Enum_Notification_Channel>;
  /** update data of the table: "ENUM_notification_group" */
  update_ENUM_notification_group?: Maybe<Enum_Notification_Group_Mutation_Response>;
  /** update single row of the table: "ENUM_notification_group" */
  update_ENUM_notification_group_by_pk?: Maybe<Enum_Notification_Group>;
  /** update data of the table: "ENUM_position_type" */
  update_ENUM_position_type?: Maybe<Enum_Position_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_position_type" */
  update_ENUM_position_type_by_pk?: Maybe<Enum_Position_Type>;
  /** update data of the table: "ENUM_price_scope" */
  update_ENUM_price_scope?: Maybe<Enum_Price_Scope_Mutation_Response>;
  /** update single row of the table: "ENUM_price_scope" */
  update_ENUM_price_scope_by_pk?: Maybe<Enum_Price_Scope>;
  /** update data of the table: "ENUM_print_technique" */
  update_ENUM_print_technique?: Maybe<Enum_Print_Technique_Mutation_Response>;
  /** update single row of the table: "ENUM_print_technique" */
  update_ENUM_print_technique_by_pk?: Maybe<Enum_Print_Technique>;
  /** update data of the table: "ENUM_profile_background_type" */
  update_ENUM_profile_background_type?: Maybe<Enum_Profile_Background_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_profile_background_type" */
  update_ENUM_profile_background_type_by_pk?: Maybe<Enum_Profile_Background_Type>;
  /** update data of the table: "ENUM_profile_invitation_status" */
  update_ENUM_profile_invitation_status?: Maybe<Enum_Profile_Invitation_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_profile_invitation_status" */
  update_ENUM_profile_invitation_status_by_pk?: Maybe<Enum_Profile_Invitation_Status>;
  /** update data of the table: "ENUM_profile_public_status" */
  update_ENUM_profile_public_status?: Maybe<Enum_Profile_Public_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_profile_public_status" */
  update_ENUM_profile_public_status_by_pk?: Maybe<Enum_Profile_Public_Status>;
  /** update data of the table: "ENUM_profile_region" */
  update_ENUM_profile_region?: Maybe<Enum_Profile_Region_Mutation_Response>;
  /** update single row of the table: "ENUM_profile_region" */
  update_ENUM_profile_region_by_pk?: Maybe<Enum_Profile_Region>;
  /** update data of the table: "ENUM_profile_status" */
  update_ENUM_profile_status?: Maybe<Enum_Profile_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_profile_status" */
  update_ENUM_profile_status_by_pk?: Maybe<Enum_Profile_Status>;
  /** update data of the table: "ENUM_profile_theme" */
  update_ENUM_profile_theme?: Maybe<Enum_Profile_Theme_Mutation_Response>;
  /** update single row of the table: "ENUM_profile_theme" */
  update_ENUM_profile_theme_by_pk?: Maybe<Enum_Profile_Theme>;
  /** update data of the table: "ENUM_profile_type" */
  update_ENUM_profile_type?: Maybe<Enum_Profile_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_profile_type" */
  update_ENUM_profile_type_by_pk?: Maybe<Enum_Profile_Type>;
  /** update data of the table: "ENUM_pseudo_user_action" */
  update_ENUM_pseudo_user_action?: Maybe<Enum_Pseudo_User_Action_Mutation_Response>;
  /** update single row of the table: "ENUM_pseudo_user_action" */
  update_ENUM_pseudo_user_action_by_pk?: Maybe<Enum_Pseudo_User_Action>;
  /** update data of the table: "ENUM_push_notification_status" */
  update_ENUM_push_notification_status?: Maybe<Enum_Push_Notification_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_push_notification_status" */
  update_ENUM_push_notification_status_by_pk?: Maybe<Enum_Push_Notification_Status>;
  /** update data of the table: "ENUM_push_notification_type" */
  update_ENUM_push_notification_type?: Maybe<Enum_Push_Notification_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_push_notification_type" */
  update_ENUM_push_notification_type_by_pk?: Maybe<Enum_Push_Notification_Type>;
  /** update data of the table: "ENUM_questionnaire_type" */
  update_ENUM_questionnaire_type?: Maybe<Enum_Questionnaire_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_questionnaire_type" */
  update_ENUM_questionnaire_type_by_pk?: Maybe<Enum_Questionnaire_Type>;
  /** update data of the table: "ENUM_render_request_status" */
  update_ENUM_render_request_status?: Maybe<Enum_Render_Request_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_render_request_status" */
  update_ENUM_render_request_status_by_pk?: Maybe<Enum_Render_Request_Status>;
  /** update data of the table: "ENUM_render_request_template_type" */
  update_ENUM_render_request_template_type?: Maybe<Enum_Render_Request_Template_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_render_request_template_type" */
  update_ENUM_render_request_template_type_by_pk?: Maybe<Enum_Render_Request_Template_Type>;
  /** update data of the table: "ENUM_restricted_handle_names" */
  update_ENUM_restricted_handle_names?: Maybe<Enum_Restricted_Handle_Names_Mutation_Response>;
  /** update single row of the table: "ENUM_restricted_handle_names" */
  update_ENUM_restricted_handle_names_by_pk?: Maybe<Enum_Restricted_Handle_Names>;
  /** update data of the table: "ENUM_size" */
  update_ENUM_size?: Maybe<Enum_Size_Mutation_Response>;
  /** update single row of the table: "ENUM_size" */
  update_ENUM_size_by_pk?: Maybe<Enum_Size>;
  /** update data of the table: "ENUM_social_graph_followers_state" */
  update_ENUM_social_graph_followers_state?: Maybe<Enum_Social_Graph_Followers_State_Mutation_Response>;
  /** update single row of the table: "ENUM_social_graph_followers_state" */
  update_ENUM_social_graph_followers_state_by_pk?: Maybe<Enum_Social_Graph_Followers_State>;
  /** update data of the table: "ENUM_social_link_status" */
  update_ENUM_social_link_status?: Maybe<Enum_Social_Link_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_social_link_status" */
  update_ENUM_social_link_status_by_pk?: Maybe<Enum_Social_Link_Status>;
  /** update data of the table: "ENUM_social_link_type" */
  update_ENUM_social_link_type?: Maybe<Enum_Social_Link_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_social_link_type" */
  update_ENUM_social_link_type_by_pk?: Maybe<Enum_Social_Link_Type>;
  /** update data of the table: "ENUM_timed_event_name" */
  update_ENUM_timed_event_name?: Maybe<Enum_Timed_Event_Name_Mutation_Response>;
  /** update single row of the table: "ENUM_timed_event_name" */
  update_ENUM_timed_event_name_by_pk?: Maybe<Enum_Timed_Event_Name>;
  /** update data of the table: "ENUM_token_storage_status" */
  update_ENUM_token_storage_status?: Maybe<Enum_Token_Storage_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_token_storage_status" */
  update_ENUM_token_storage_status_by_pk?: Maybe<Enum_Token_Storage_Status>;
  /** update data of the table: "ENUM_transaction_origin" */
  update_ENUM_transaction_origin?: Maybe<Enum_Transaction_Origin_Mutation_Response>;
  /** update single row of the table: "ENUM_transaction_origin" */
  update_ENUM_transaction_origin_by_pk?: Maybe<Enum_Transaction_Origin>;
  /** update data of the table: "ENUM_transaction_status" */
  update_ENUM_transaction_status?: Maybe<Enum_Transaction_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_transaction_status" */
  update_ENUM_transaction_status_by_pk?: Maybe<Enum_Transaction_Status>;
  /** update data of the table: "ENUM_tutorial_status" */
  update_ENUM_tutorial_status?: Maybe<Enum_Tutorial_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_tutorial_status" */
  update_ENUM_tutorial_status_by_pk?: Maybe<Enum_Tutorial_Status>;
  /** update data of the table: "ENUM_tutorial_step_type" */
  update_ENUM_tutorial_step_type?: Maybe<Enum_Tutorial_Step_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_tutorial_step_type" */
  update_ENUM_tutorial_step_type_by_pk?: Maybe<Enum_Tutorial_Step_Type>;
  /** update data of the table: "ENUM_tutorial_type" */
  update_ENUM_tutorial_type?: Maybe<Enum_Tutorial_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_tutorial_type" */
  update_ENUM_tutorial_type_by_pk?: Maybe<Enum_Tutorial_Type>;
  /** update data of the table: "ENUM_vumark_status" */
  update_ENUM_vumark_status?: Maybe<Enum_Vumark_Status_Mutation_Response>;
  /** update single row of the table: "ENUM_vumark_status" */
  update_ENUM_vumark_status_by_pk?: Maybe<Enum_Vumark_Status>;
  /** update data of the table: "ENUM_vumark_type" */
  update_ENUM_vumark_type?: Maybe<Enum_Vumark_Type_Mutation_Response>;
  /** update single row of the table: "ENUM_vumark_type" */
  update_ENUM_vumark_type_by_pk?: Maybe<Enum_Vumark_Type>;
  /** update data of the table: "Edition" */
  update_Edition?: Maybe<Edition_Mutation_Response>;
  /** update data of the table: "Edition_By_Size" */
  update_Edition_By_Size?: Maybe<Edition_By_Size_Mutation_Response>;
  /** update single row of the table: "Edition_By_Size" */
  update_Edition_By_Size_by_pk?: Maybe<Edition_By_Size>;
  /** update data of the table: "Edition_By_Type" */
  update_Edition_By_Type?: Maybe<Edition_By_Type_Mutation_Response>;
  /** update single row of the table: "Edition_By_Type" */
  update_Edition_By_Type_by_pk?: Maybe<Edition_By_Type>;
  /** update data of the table: "Edition_Type" */
  update_Edition_Type?: Maybe<Edition_Type_Mutation_Response>;
  /** update single row of the table: "Edition_Type" */
  update_Edition_Type_by_pk?: Maybe<Edition_Type>;
  /** update single row of the table: "Edition" */
  update_Edition_by_pk?: Maybe<Edition>;
  /** update data of the table: "Edition_events" */
  update_Edition_events?: Maybe<Edition_Events_Mutation_Response>;
  /** update single row of the table: "Edition_events" */
  update_Edition_events_by_pk?: Maybe<Edition_Events>;
  /** update data of the table: "Email_validation_token" */
  update_Email_validation_token?: Maybe<Email_Validation_Token_Mutation_Response>;
  /** update single row of the table: "Email_validation_token" */
  update_Email_validation_token_by_pk?: Maybe<Email_Validation_Token>;
  /** update data of the table: "Emails" */
  update_Emails?: Maybe<Emails_Mutation_Response>;
  /** update single row of the table: "Emails" */
  update_Emails_by_pk?: Maybe<Emails>;
  /** update data of the table: "FCM_Token" */
  update_FCM_Token?: Maybe<Fcm_Token_Mutation_Response>;
  /** update single row of the table: "FCM_Token" */
  update_FCM_Token_by_pk?: Maybe<Fcm_Token>;
  /** update data of the table: "Features" */
  update_Features?: Maybe<Features_Mutation_Response>;
  /** update single row of the table: "Features" */
  update_Features_by_pk?: Maybe<Features>;
  /** update data of the table: "File_metadata" */
  update_File_metadata?: Maybe<File_Metadata_Mutation_Response>;
  /** update single row of the table: "File_metadata" */
  update_File_metadata_by_pk?: Maybe<File_Metadata>;
  /** update data of the table: "Firebase_profile" */
  update_Firebase_profile?: Maybe<Firebase_Profile_Mutation_Response>;
  /** update single row of the table: "Firebase_profile" */
  update_Firebase_profile_by_pk?: Maybe<Firebase_Profile>;
  /** update data of the table: "Flagged_users" */
  update_Flagged_users?: Maybe<Flagged_Users_Mutation_Response>;
  /** update single row of the table: "Flagged_users" */
  update_Flagged_users_by_pk?: Maybe<Flagged_Users>;
  /** update data of the table: "Forge_InProcess_Task" */
  update_Forge_InProcess_Task?: Maybe<Forge_InProcess_Task_Mutation_Response>;
  /** update single row of the table: "Forge_InProcess_Task" */
  update_Forge_InProcess_Task_by_pk?: Maybe<Forge_InProcess_Task>;
  /** update data of the table: "Forge_tasks" */
  update_Forge_tasks?: Maybe<Forge_Tasks_Mutation_Response>;
  /** update single row of the table: "Forge_tasks" */
  update_Forge_tasks_by_pk?: Maybe<Forge_Tasks>;
  /** update data of the table: "Global_properties" */
  update_Global_properties?: Maybe<Global_Properties_Mutation_Response>;
  /** update single row of the table: "Global_properties" */
  update_Global_properties_by_pk?: Maybe<Global_Properties>;
  /** update data of the table: "Graveyard_Artwork" */
  update_Graveyard_Artwork?: Maybe<Graveyard_Artwork_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork" */
  update_Graveyard_Artwork_by_pk?: Maybe<Graveyard_Artwork>;
  /** update data of the table: "Graveyard_Artwork_details" */
  update_Graveyard_Artwork_details?: Maybe<Graveyard_Artwork_Details_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork_details" */
  update_Graveyard_Artwork_details_by_pk?: Maybe<Graveyard_Artwork_Details>;
  /** update data of the table: "Graveyard_Artwork_details_translation" */
  update_Graveyard_Artwork_details_translation?: Maybe<Graveyard_Artwork_Details_Translation_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork_details_translation" */
  update_Graveyard_Artwork_details_translation_by_pk?: Maybe<Graveyard_Artwork_Details_Translation>;
  /** update data of the table: "Graveyard_Artwork_events" */
  update_Graveyard_Artwork_events?: Maybe<Graveyard_Artwork_Events_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork_events" */
  update_Graveyard_Artwork_events_by_pk?: Maybe<Graveyard_Artwork_Events>;
  /** update data of the table: "Graveyard_Artwork_prices" */
  update_Graveyard_Artwork_prices?: Maybe<Graveyard_Artwork_Prices_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork_prices" */
  update_Graveyard_Artwork_prices_by_pk?: Maybe<Graveyard_Artwork_Prices>;
  /** update data of the table: "Graveyard_Artwork_secondary_media" */
  update_Graveyard_Artwork_secondary_media?: Maybe<Graveyard_Artwork_Secondary_Media_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork_secondary_media" */
  update_Graveyard_Artwork_secondary_media_by_pk?: Maybe<Graveyard_Artwork_Secondary_Media>;
  /** update data of the table: "Graveyard_Artwork_story" */
  update_Graveyard_Artwork_story?: Maybe<Graveyard_Artwork_Story_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork_story" */
  update_Graveyard_Artwork_story_by_pk?: Maybe<Graveyard_Artwork_Story>;
  /** update data of the table: "Graveyard_Artwork_story_media" */
  update_Graveyard_Artwork_story_media?: Maybe<Graveyard_Artwork_Story_Media_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork_story_media" */
  update_Graveyard_Artwork_story_media_by_pk?: Maybe<Graveyard_Artwork_Story_Media>;
  /** update data of the table: "Graveyard_Artwork_to_Collection" */
  update_Graveyard_Artwork_to_Collection?: Maybe<Graveyard_Artwork_To_Collection_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork_to_Collection" */
  update_Graveyard_Artwork_to_Collection_by_pk?: Maybe<Graveyard_Artwork_To_Collection>;
  /** update data of the table: "Graveyard_Artwork_transactions" */
  update_Graveyard_Artwork_transactions?: Maybe<Graveyard_Artwork_Transactions_Mutation_Response>;
  /** update single row of the table: "Graveyard_Artwork_transactions" */
  update_Graveyard_Artwork_transactions_by_pk?: Maybe<Graveyard_Artwork_Transactions>;
  /** update data of the table: "Graveyard_Purchase_inquires" */
  update_Graveyard_Purchase_inquires?: Maybe<Graveyard_Purchase_Inquires_Mutation_Response>;
  /** update data of the table: "Graveyard_Purchase_inquires_outside_users" */
  update_Graveyard_Purchase_inquires_outside_users?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Mutation_Response>;
  /** update data of the table: "Interests" */
  update_Interests?: Maybe<Interests_Mutation_Response>;
  /** update single row of the table: "Interests" */
  update_Interests_by_pk?: Maybe<Interests>;
  /** update data of the table: "Kaleido_general_settings" */
  update_Kaleido_general_settings?: Maybe<Kaleido_General_Settings_Mutation_Response>;
  /** update single row of the table: "Kaleido_general_settings" */
  update_Kaleido_general_settings_by_pk?: Maybe<Kaleido_General_Settings>;
  /** update data of the table: "Landing_page_artwork" */
  update_Landing_page_artwork?: Maybe<Landing_Page_Artwork_Mutation_Response>;
  /** update single row of the table: "Landing_page_artwork" */
  update_Landing_page_artwork_by_pk?: Maybe<Landing_Page_Artwork>;
  /** update data of the table: "Languages" */
  update_Languages?: Maybe<Languages_Mutation_Response>;
  /** update single row of the table: "Languages" */
  update_Languages_by_pk?: Maybe<Languages>;
  /** update data of the table: "Materialized_view" */
  update_Materialized_view?: Maybe<Materialized_View_Mutation_Response>;
  /** update single row of the table: "Materialized_view" */
  update_Materialized_view_by_pk?: Maybe<Materialized_View>;
  /** update data of the table: "Media_metadata" */
  update_Media_metadata?: Maybe<Media_Metadata_Mutation_Response>;
  /** update single row of the table: "Media_metadata" */
  update_Media_metadata_by_pk?: Maybe<Media_Metadata>;
  /** update data of the table: "Milestone" */
  update_Milestone?: Maybe<Milestone_Mutation_Response>;
  /** update single row of the table: "Milestone" */
  update_Milestone_by_pk?: Maybe<Milestone>;
  /** update data of the table: "My_Website_Settings" */
  update_My_Website_Settings?: Maybe<My_Website_Settings_Mutation_Response>;
  /** update single row of the table: "My_Website_Settings" */
  update_My_Website_Settings_by_pk?: Maybe<My_Website_Settings>;
  /** update data of the table: "My_studio_settings" */
  update_My_studio_settings?: Maybe<My_Studio_Settings_Mutation_Response>;
  /** update single row of the table: "My_studio_settings" */
  update_My_studio_settings_by_pk?: Maybe<My_Studio_Settings>;
  /** update data of the table: "Notification_preference" */
  update_Notification_preference?: Maybe<Notification_Preference_Mutation_Response>;
  /** update single row of the table: "Notification_preference" */
  update_Notification_preference_by_pk?: Maybe<Notification_Preference>;
  /** update data of the table: "Pending_collaboration_on_artwork" */
  update_Pending_collaboration_on_artwork?: Maybe<Pending_Collaboration_On_Artwork_Mutation_Response>;
  /** update single row of the table: "Pending_collaboration_on_artwork" */
  update_Pending_collaboration_on_artwork_by_pk?: Maybe<Pending_Collaboration_On_Artwork>;
  /** update data of the table: "Price_Ranges" */
  update_Price_Ranges?: Maybe<Price_Ranges_Mutation_Response>;
  /** update single row of the table: "Price_Ranges" */
  update_Price_Ranges_by_pk?: Maybe<Price_Ranges>;
  /** update data of the table: "Print" */
  update_Print?: Maybe<Print_Mutation_Response>;
  /** update single row of the table: "Print" */
  update_Print_by_pk?: Maybe<Print>;
  /** update data of the table: "Print_to_Collection" */
  update_Print_to_Collection?: Maybe<Print_To_Collection_Mutation_Response>;
  /** update single row of the table: "Print_to_Collection" */
  update_Print_to_Collection_by_pk?: Maybe<Print_To_Collection>;
  /** update data of the table: "Prize_Art_Show" */
  update_Prize_Art_Show?: Maybe<Prize_Art_Show_Mutation_Response>;
  /** update single row of the table: "Prize_Art_Show" */
  update_Prize_Art_Show_by_pk?: Maybe<Prize_Art_Show>;
  /** update data of the table: "Profile" */
  update_Profile?: Maybe<Profile_Mutation_Response>;
  /** update data of the table: "Profile_Interests" */
  update_Profile_Interests?: Maybe<Profile_Interests_Mutation_Response>;
  /** update single row of the table: "Profile_Interests" */
  update_Profile_Interests_by_pk?: Maybe<Profile_Interests>;
  /** update data of the table: "Profile_achievements" */
  update_Profile_achievements?: Maybe<Profile_Achievements_Mutation_Response>;
  /** update single row of the table: "Profile_achievements" */
  update_Profile_achievements_by_pk?: Maybe<Profile_Achievements>;
  /** update data of the table: "Profile_appearance_settings" */
  update_Profile_appearance_settings?: Maybe<Profile_Appearance_Settings_Mutation_Response>;
  /** update single row of the table: "Profile_appearance_settings" */
  update_Profile_appearance_settings_by_pk?: Maybe<Profile_Appearance_Settings>;
  /** update data of the table: "Profile_background" */
  update_Profile_background?: Maybe<Profile_Background_Mutation_Response>;
  /** update single row of the table: "Profile_background" */
  update_Profile_background_by_pk?: Maybe<Profile_Background>;
  /** update single row of the table: "Profile" */
  update_Profile_by_pk?: Maybe<Profile>;
  /** update data of the table: "Profile_ghost_tokens" */
  update_Profile_ghost_tokens?: Maybe<Profile_Ghost_Tokens_Mutation_Response>;
  /** update single row of the table: "Profile_ghost_tokens" */
  update_Profile_ghost_tokens_by_pk?: Maybe<Profile_Ghost_Tokens>;
  /** update data of the table: "Profile_invitations" */
  update_Profile_invitations?: Maybe<Profile_Invitations_Mutation_Response>;
  /** update single row of the table: "Profile_invitations" */
  update_Profile_invitations_by_pk?: Maybe<Profile_Invitations>;
  /** update data of the table: "Profile_location" */
  update_Profile_location?: Maybe<Profile_Location_Mutation_Response>;
  /** update single row of the table: "Profile_location" */
  update_Profile_location_by_pk?: Maybe<Profile_Location>;
  /** update data of the table: "Profile_location_translation" */
  update_Profile_location_translation?: Maybe<Profile_Location_Translation_Mutation_Response>;
  /** update single row of the table: "Profile_location_translation" */
  update_Profile_location_translation_by_pk?: Maybe<Profile_Location_Translation>;
  /** update data of the table: "Profile_price_ranges" */
  update_Profile_price_ranges?: Maybe<Profile_Price_Ranges_Mutation_Response>;
  /** update single row of the table: "Profile_price_ranges" */
  update_Profile_price_ranges_by_pk?: Maybe<Profile_Price_Ranges>;
  /** update data of the table: "Profile_questions_and_answers" */
  update_Profile_questions_and_answers?: Maybe<Profile_Questions_And_Answers_Mutation_Response>;
  /** update single row of the table: "Profile_questions_and_answers" */
  update_Profile_questions_and_answers_by_pk?: Maybe<Profile_Questions_And_Answers>;
  /** update data of the table: "Profile_settings" */
  update_Profile_settings?: Maybe<Profile_Settings_Mutation_Response>;
  /** update single row of the table: "Profile_settings" */
  update_Profile_settings_by_pk?: Maybe<Profile_Settings>;
  /** update data of the table: "Profile_social_links" */
  update_Profile_social_links?: Maybe<Profile_Social_Links_Mutation_Response>;
  /** update single row of the table: "Profile_social_links" */
  update_Profile_social_links_by_pk?: Maybe<Profile_Social_Links>;
  /** update data of the table: "Profile_statement_audio_syntheses" */
  update_Profile_statement_audio_syntheses?: Maybe<Profile_Statement_Audio_Syntheses_Mutation_Response>;
  /** update single row of the table: "Profile_statement_audio_syntheses" */
  update_Profile_statement_audio_syntheses_by_pk?: Maybe<Profile_Statement_Audio_Syntheses>;
  /** update data of the table: "Profile_translation" */
  update_Profile_translation?: Maybe<Profile_Translation_Mutation_Response>;
  /** update single row of the table: "Profile_translation" */
  update_Profile_translation_by_pk?: Maybe<Profile_Translation>;
  /** update data of the table: "Profile_video_links" */
  update_Profile_video_links?: Maybe<Profile_Video_Links_Mutation_Response>;
  /** update single row of the table: "Profile_video_links" */
  update_Profile_video_links_by_pk?: Maybe<Profile_Video_Links>;
  /** update data of the table: "Profile_wallet_address" */
  update_Profile_wallet_address?: Maybe<Profile_Wallet_Address_Mutation_Response>;
  /** update single row of the table: "Profile_wallet_address" */
  update_Profile_wallet_address_by_pk?: Maybe<Profile_Wallet_Address>;
  /** update data of the table: "Pubnub_channels" */
  update_Pubnub_channels?: Maybe<Pubnub_Channels_Mutation_Response>;
  /** update single row of the table: "Pubnub_channels" */
  update_Pubnub_channels_by_pk?: Maybe<Pubnub_Channels>;
  /** update data of the table: "Purchase_inquires" */
  update_Purchase_inquires?: Maybe<Purchase_Inquires_Mutation_Response>;
  /** update single row of the table: "Purchase_inquires" */
  update_Purchase_inquires_by_pk?: Maybe<Purchase_Inquires>;
  /** update data of the table: "Purchase_inquires_outside_users" */
  update_Purchase_inquires_outside_users?: Maybe<Purchase_Inquires_Outside_Users_Mutation_Response>;
  /** update single row of the table: "Purchase_inquires_outside_users" */
  update_Purchase_inquires_outside_users_by_pk?: Maybe<Purchase_Inquires_Outside_Users>;
  /** update data of the table: "Push_notifications" */
  update_Push_notifications?: Maybe<Push_Notifications_Mutation_Response>;
  /** update single row of the table: "Push_notifications" */
  update_Push_notifications_by_pk?: Maybe<Push_Notifications>;
  /** update data of the table: "Questionnaire_questions" */
  update_Questionnaire_questions?: Maybe<Questionnaire_Questions_Mutation_Response>;
  /** update single row of the table: "Questionnaire_questions" */
  update_Questionnaire_questions_by_pk?: Maybe<Questionnaire_Questions>;
  /** update data of the table: "Render_request" */
  update_Render_request?: Maybe<Render_Request_Mutation_Response>;
  /** update single row of the table: "Render_request" */
  update_Render_request_by_pk?: Maybe<Render_Request>;
  /** update data of the table: "Setting_context_default_profile" */
  update_Setting_context_default_profile?: Maybe<Setting_Context_Default_Profile_Mutation_Response>;
  /** update single row of the table: "Setting_context_default_profile" */
  update_Setting_context_default_profile_by_pk?: Maybe<Setting_Context_Default_Profile>;
  /** update data of the table: "Showroom_details" */
  update_Showroom_details?: Maybe<Showroom_Details_Mutation_Response>;
  /** update single row of the table: "Showroom_details" */
  update_Showroom_details_by_pk?: Maybe<Showroom_Details>;
  /** update data of the table: "Social_graph_followers" */
  update_Social_graph_followers?: Maybe<Social_Graph_Followers_Mutation_Response>;
  /** update single row of the table: "Social_graph_followers" */
  update_Social_graph_followers_by_pk?: Maybe<Social_Graph_Followers>;
  /** update data of the table: "Spotlight_context" */
  update_Spotlight_context?: Maybe<Spotlight_Context_Mutation_Response>;
  /** update single row of the table: "Spotlight_context" */
  update_Spotlight_context_by_pk?: Maybe<Spotlight_Context>;
  /** update data of the table: "Super_site_subscribers" */
  update_Super_site_subscribers?: Maybe<Super_Site_Subscribers_Mutation_Response>;
  /** update single row of the table: "Super_site_subscribers" */
  update_Super_site_subscribers_by_pk?: Maybe<Super_Site_Subscribers>;
  /** update data of the table: "Tags_by_interest" */
  update_Tags_by_interest?: Maybe<Tags_By_Interest_Mutation_Response>;
  /** update single row of the table: "Tags_by_interest" */
  update_Tags_by_interest_by_pk?: Maybe<Tags_By_Interest>;
  /** update data of the table: "Test_Analytics_events" */
  update_Test_Analytics_events?: Maybe<Test_Analytics_Events_Mutation_Response>;
  /** update single row of the table: "Test_Analytics_events" */
  update_Test_Analytics_events_by_pk?: Maybe<Test_Analytics_Events>;
  /** update data of the table: "Test_accounts" */
  update_Test_accounts?: Maybe<Test_Accounts_Mutation_Response>;
  /** update single row of the table: "Test_accounts" */
  update_Test_accounts_by_pk?: Maybe<Test_Accounts>;
  /** update data of the table: "Timed_event" */
  update_Timed_event?: Maybe<Timed_Event_Mutation_Response>;
  /** update single row of the table: "Timed_event" */
  update_Timed_event_by_pk?: Maybe<Timed_Event>;
  /** update data of the table: "Token_Storage" */
  update_Token_Storage?: Maybe<Token_Storage_Mutation_Response>;
  /** update single row of the table: "Token_Storage" */
  update_Token_Storage_by_pk?: Maybe<Token_Storage>;
  /** update data of the table: "Tutorial" */
  update_Tutorial?: Maybe<Tutorial_Mutation_Response>;
  /** update single row of the table: "Tutorial" */
  update_Tutorial_by_pk?: Maybe<Tutorial>;
  /** update data of the table: "Tutorial_step" */
  update_Tutorial_step?: Maybe<Tutorial_Step_Mutation_Response>;
  /** update single row of the table: "Tutorial_step" */
  update_Tutorial_step_by_pk?: Maybe<Tutorial_Step>;
  /** update data of the table: "User_analytics_points_index" */
  update_User_analytics_points_index?: Maybe<User_Analytics_Points_Index_Mutation_Response>;
  /** update single row of the table: "User_analytics_points_index" */
  update_User_analytics_points_index_by_pk?: Maybe<User_Analytics_Points_Index>;
  /** update data of the table: "VR_artists" */
  update_VR_artists?: Maybe<Vr_Artists_Mutation_Response>;
  /** update single row of the table: "VR_artists" */
  update_VR_artists_by_pk?: Maybe<Vr_Artists>;
  /** update data of the table: "Vision_interest" */
  update_Vision_interest?: Maybe<Vision_Interest_Mutation_Response>;
  /** update single row of the table: "Vision_interest" */
  update_Vision_interest_by_pk?: Maybe<Vision_Interest>;
  /** update data of the table: "Vumarks" */
  update_Vumarks?: Maybe<Vumarks_Mutation_Response>;
  /** update single row of the table: "Vumarks" */
  update_Vumarks_by_pk?: Maybe<Vumarks>;
  /** update data of the table: "Waiting_list" */
  update_Waiting_list?: Maybe<Waiting_List_Mutation_Response>;
  /** update single row of the table: "Waiting_list" */
  update_Waiting_list_by_pk?: Maybe<Waiting_List>;
  /** update data of the table: "Wallets" */
  update_Wallets?: Maybe<Wallets_Mutation_Response>;
  /** update single row of the table: "Wallets" */
  update_Wallets_by_pk?: Maybe<Wallets>;
  /** update data of the table: "World_cities" */
  update_World_cities?: Maybe<World_Cities_Mutation_Response>;
  /** update single row of the table: "World_cities" */
  update_World_cities_by_pk?: Maybe<World_Cities>;
  /** update data of the table: "app_notifications_info" */
  update_app_notifications_info?: Maybe<App_Notifications_Info_Mutation_Response>;
  /** update data of the table: "app_notifications_listing" */
  update_app_notifications_listing?: Maybe<App_Notifications_Listing_Mutation_Response>;
  /** update data of the table: "app_notifications_view" */
  update_app_notifications_view?: Maybe<App_Notifications_View_Mutation_Response>;
  /** update data of the table: "profile_phones" */
  update_profile_phones?: Maybe<Profile_Phones_Mutation_Response>;
  /** update single row of the table: "profile_phones" */
  update_profile_phones_by_pk?: Maybe<Profile_Phones>;
  /** update data of the table: "showroom_owner_password" */
  update_showroom_owner_password?: Maybe<Showroom_Owner_Password_Mutation_Response>;
  /** update data of the table: "worldcities" */
  update_worldcities?: Maybe<Worldcities_Mutation_Response>;
  /** update single row of the table: "worldcities" */
  update_worldcities_by_pk?: Maybe<Worldcities>;
  upgradePseudoCollector: Scalars['Boolean'];
  upgradePseudoCollectorToArtist: Scalars['Boolean'];
  /** Inserts or updates an art prize show. */
  upsertArtPrizeShow?: Maybe<CreateArtPrizeResult>;
  /**
   * Inserts or updates an art prize winner with the artist artworks
   *
   * ***Constraints:***
   *
   *   \- Artworks must not be repeated
   *
   *   \- Artworks must belong the winner artist
   */
  upsertArtPrizeWinnerArtwork?: Maybe<Scalars['bigint']>;
  upsertArtistNewsletter?: Maybe<Scalars['Boolean']>;
  upsertConnectedDomain?: Maybe<Scalars['Boolean']>;
  /**
   * - Inserts or updates a context flag of any type
   *
   * ***Constraints:***:
   *
   *   \- Only the owner of the context can proceed
   */
  upsertContextFlag?: Maybe<Scalars['Boolean']>;
  /**
   * - Inserts or updates context to tutorial
   *
   * ***Constraints:***:
   *
   *   \- Not finished or skipped records are allowed
   */
  upsertContextTutorial: Scalars['Boolean'];
  upsertDiscountCode?: Maybe<Scalars['bigint']>;
  upsertQuestionnaireAnswers: Scalars['Int'];
  userUpgradeReminderTrigger: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootActivateArtHistoryArtUniverseArgs = {
  context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootActivateMyFcmTokenArgs = {
  platform: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAddArtistCuratorScoreArgs = {
  artist_context_id: Scalars['Int'];
  score: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAddArtworkCuratorScoreArgs = {
  artwork_id: Scalars['Int'];
  score: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAddArtworkToCartArgs = {
  artwork_id: Scalars['bigint'];
  context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootAddArtworkToCollectionArgs = {
  artwork_id: Scalars['Int'];
  collection_id: Scalars['Int'];
  context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootArtPrizeBackgroundUploadArgs = {
  art_prize_show_id: Scalars['bigint'];
  file: Scalars['Upload'];
};


/** mutation root */
export type Mutation_RootArtPrizeThumbnailUploadArgs = {
  art_prize_show_id: Scalars['bigint'];
  file: Scalars['Upload'];
};


/** mutation root */
export type Mutation_RootArtUniverseAcctSetupArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  platform: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootArtistAcceptInvitationArgs = {
  collaboration_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootArtistCreateArtworkArgs = {
  artist_context_id: Scalars['Int'];
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootArtistHandleInvitationArgs = {
  artist_status: Scalars['String'];
  collaboration_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootArtistInviteGalleryCollaborationArgs = {
  artwork_id?: Maybe<Scalars['Int']>;
  context_id: Scalars['Int'];
  email: Scalars['String'];
  invitee_context_id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootArtistRejectInvitationArgs = {
  collaboration_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootArtistRemovePartnerOnArtworkArgs = {
  artwork_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootArtistSetPartnerOnArtworkArgs = {
  artist_context_id: Scalars['Int'];
  artwork_id: Scalars['Int'];
  partner_context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootArtistUpsertEditionArgs = {
  add_edition_flow_step?: Maybe<Scalars['String']>;
  artwork_id?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  edition_id?: Maybe<Scalars['Int']>;
  is_available_sale?: Maybe<Scalars['Boolean']>;
  is_price_public?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  publish_date?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootArtistUpsertEditionBySizeArgs = {
  currency?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Float']>;
  edition_id: Scalars['Int'];
  height?: Maybe<Scalars['Float']>;
  is_price_public?: Maybe<Scalars['Boolean']>;
  materials?: Maybe<Scalars['String']>;
  measuring_unit?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  print_date_day?: Maybe<Scalars['Int']>;
  print_date_month?: Maybe<Scalars['Int']>;
  print_date_year?: Maybe<Scalars['Int']>;
  print_size: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  technique?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};


/** mutation root */
export type Mutation_RootArtistUpsertEditionByTypeArgs = {
  edition_by_size_id?: Maybe<Scalars['Int']>;
  edition_by_type_id?: Maybe<Scalars['Int']>;
  edition_type_id?: Maybe<Scalars['Int']>;
  print_amount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootArtistUpsertPrintArgs = {
  edition_by_type_id?: Maybe<Scalars['Int']>;
  is_public?: Maybe<Scalars['Boolean']>;
  print_id?: Maybe<Scalars['Int']>;
  print_number?: Maybe<Scalars['Int']>;
  print_price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootArtworkAudioDeleteArgs = {
  story_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootArtworkLocationMapUploadArgs = {
  artwork_details_id: Scalars['bigint'];
  file: Scalars['Upload'];
};


/** mutation root */
export type Mutation_RootArtworkOriginalImageUploadArgs = {
  artwork_id: Scalars['bigint'];
  file: Scalars['Upload'];
  processAITags?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootArtworkPrimaryImageUploadArgs = {
  artwork_id: Scalars['bigint'];
  file: Scalars['Upload'];
};


/** mutation root */
export type Mutation_RootArtworkSecondaryImageDeleteArgs = {
  artwork_secondary_media_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootArtworkSecondaryImageUploadArgs = {
  artwork_id: Scalars['bigint'];
  edition_id?: Maybe<Scalars['bigint']>;
  file: Scalars['Upload'];
  order?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootArtworkStoryAudioUploadArgs = {
  artwork_id: Scalars['bigint'];
  file: Scalars['Upload'];
};


/** mutation root */
export type Mutation_RootArtworkStoryImageDeleteArgs = {
  artwork_story_media_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootArtworkStoryImageUploadArgs = {
  artwork_id: Scalars['bigint'];
  file: Scalars['Upload'];
  order: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootArtworkThumbnailUploadArgs = {
  artwork_id: Scalars['bigint'];
  file: Scalars['Upload'];
};


/** mutation root */
export type Mutation_RootCancelArtworkTransferArgs = {
  transfer_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootCertificateVumarkUploadArgs = {
  artwork_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootCheckIfValidationTokenIsValidArgs = {
  email?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  tokenId?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootCheckoutDomainArgs = {
  artistContextId: Scalars['bigint'];
  domain: CheckoutDomainInput;
  redirectUrl: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCheckoutMyStudioArgs = {
  artistContextId: Scalars['bigint'];
  discountCode?: Maybe<DiscountCodeInput>;
  domain?: Maybe<CheckoutSuperSiteDomainInput>;
  period: SubscriptionPeriod;
  redirectUrl: Scalars['String'];
  subscriptionType: SubscriptionType;
};


/** mutation root */
export type Mutation_RootCheckoutSuperSiteArgs = {
  artistContextId: Scalars['bigint'];
  domain?: Maybe<CheckoutSuperSiteDomainInput>;
  period: SubscriptionPeriod;
  redirectUrl: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCollaborationInviteWithProfileCodeArgs = {
  code: Scalars['String'];
  context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootCollectionAudioDeleteArgs = {
  collection_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootCollectionAudioUploadArgs = {
  collection_id: Scalars['bigint'];
  file: Scalars['Upload'];
};


/** mutation root */
export type Mutation_RootCompleteEmailVerificationArgs = {
  email?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  tokenId?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootCompleteFirebaseCustomAuthArgs = {
  email?: Maybe<Scalars['String']>;
  invitationToken?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  tokenId?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootConfirmArtworkAvailabilityArgs = {
  input: ConfirmArtworkAvailabilityInput;
};


/** mutation root */
export type Mutation_RootContactArtistArgs = {
  artistContextId: Scalars['Int'];
  contactEmail: Scalars['String'];
  contactMessage: Scalars['String'];
  contactName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCopyAudioFromSeriesToStoryArgs = {
  artwork_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootCreateArtistGhostProfileArgs = {
  artist_picture_link: Scalars['String'];
  artistic_name: Scalars['String'];
  banner_link: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  facebook: Scalars['String'];
  handle: Scalars['String'];
  icon_link: Scalars['String'];
  instagram: Scalars['String'];
  pinterest: Scalars['String'];
  quote: Scalars['String'];
  statement: Scalars['String'];
  tiktok: Scalars['String'];
  twitter: Scalars['String'];
  website: Scalars['String'];
  youtube: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateCollectionArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner_context_id: Scalars['Int'];
  partner_context_id?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateGalleryGhostProfileArgs = {
  address: Scalars['String'];
  banner_link: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  facebook: Scalars['String'];
  gallery_name: Scalars['String'];
  gallery_picture_link: Scalars['String'];
  handle: Scalars['String'];
  icon_link: Scalars['String'];
  instagram: Scalars['String'];
  pinterest: Scalars['String'];
  quote: Scalars['String'];
  statement: Scalars['String'];
  tiktok: Scalars['String'];
  twitter: Scalars['String'];
  website: Scalars['String'];
  youtube: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateShowroomArgs = {
  context_id: Scalars['Int'];
  description: Scalars['String'];
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateTransferToSellerArgs = {
  artworkPaymentIntentId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeactivateArtHistoryArtUniverseArgs = {
  context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDeactivateMyFcmTokenArgs = {
  platform: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteArtPrizeShowArgs = {
  art_prize_show_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteArtPrizeWinnerArgs = {
  art_prize_winner_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteContentTranslationArgs = {
  contentType: Scalars['String'];
  entityId: Scalars['Int'];
  secondaryEntityId?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootDeleteProfilePhoneNumberArgs = {
  context_id: Scalars['Int'];
  phone: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteSeriesArgs = {
  collection_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteShowroomArgs = {
  showroom_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Account_Initial_Profile_TypeArgs = {
  where: Account_Initial_Profile_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Initial_Profile_Type_By_PkArgs = {
  context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Account_Notice_AckArgs = {
  where: Account_Notice_Ack_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Notice_Ack_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Account_ValidationArgs = {
  where: Account_Validation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Validation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Achievement_HooksArgs = {
  where: Achievement_Hooks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Achievement_Hooks_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Achievement_TagsArgs = {
  where: Achievement_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Achievement_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AchievementsArgs = {
  where: Achievements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Achievements_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Admin_PasswordArgs = {
  where: Admin_Password_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admin_Password_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Admin_To_User_NotificationArgs = {
  where: Admin_To_User_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Admin_To_User_Notification_By_PkArgs = {
  user_context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_All_Artwork_Availability_TableArgs = {
  where: All_Artwork_Availability_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_All_Artwork_Availability_Table_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Analytics_EventsArgs = {
  where: Analytics_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Analytics_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Analytics_Index_HistoryArgs = {
  where: Analytics_Index_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Analytics_Index_History_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Analytics_Index_TypeArgs = {
  where: Analytics_Index_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Analytics_Index_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Analytics_Index_ValuesArgs = {
  where: Analytics_Index_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Analytics_Index_Values_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Analytics_KaleidoCard_AmountsArgs = {
  where: Analytics_KaleidoCard_Amounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Analytics_KaleidoCard_Amounts_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Analytics_KaleidoCard_EventsArgs = {
  where: Analytics_KaleidoCard_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Analytics_KaleidoCard_Events_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_App_NotificationsArgs = {
  where: App_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Notifications_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Art_History_MovementArgs = {
  where: Art_History_Movement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Art_History_Movement_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Art_Prize_ArtworkArgs = {
  where: Art_Prize_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Art_Prize_Artwork_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Art_Prize_ShowArgs = {
  where: Art_Prize_Show_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Art_Prize_Show_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Art_Prize_Show_WinnersArgs = {
  where: Art_Prize_Show_Winners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Art_Prize_Show_Winners_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Art_Prize_WinnerArgs = {
  where: Art_Prize_Winner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Art_Prize_Winner_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Art_Prizes_Links_FilteredArgs = {
  where: Art_Prizes_Links_Filtered_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Art_Show_DetailsArgs = {
  where: Art_Show_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Art_Show_Details_By_PkArgs = {
  uri: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Art_Type_To_Artwork_DisciplineArgs = {
  where: Art_Type_To_Artwork_Discipline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Art_Type_To_Artwork_Discipline_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Artist_CollectionArgs = {
  where: Artist_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_Collection_CategoriesArgs = {
  where: Artist_Collection_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_Collection_Categories_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Artist_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artist_Collection_To_Artist_ContextArgs = {
  where: Artist_Collection_To_Artist_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_Collection_To_Artist_Context_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artist_Art_History_VisibilityArgs = {
  where: Artist_Art_History_Visibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_Art_History_Visibility_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artist_Curation_ScoreArgs = {
  where: Artist_Curation_Score_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_Curation_Score_By_PkArgs = {
  artist_context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artist_Explore_VisibilityArgs = {
  where: Artist_Explore_Visibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_Explore_Visibility_By_PkArgs = {
  appear_reason: Scalars['String'];
  artist_context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artist_Links_FilteredArgs = {
  where: Artist_Links_Filtered_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_NewsletterArgs = {
  where: Artist_Newsletter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_Newsletter_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Artist_Vision_VisibilityArgs = {
  where: Artist_Vision_Visibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artist_Vision_Visibility_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ArtworkArgs = {
  where: Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Ai_TagsArgs = {
  where: Artwork_Ai_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Ai_Tags_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Art_History_TimetableArgs = {
  where: Artwork_Art_History_Timetable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Art_History_Timetable_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Aspect_RatioArgs = {
  where: Artwork_Aspect_Ratio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Aspect_Ratio_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Count_MilestonesArgs = {
  where: Artwork_Count_Milestones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Count_Milestones_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Curation_ScoreArgs = {
  where: Artwork_Curation_Score_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Curation_Score_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_DetailsArgs = {
  where: Artwork_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Details_TranslationArgs = {
  where: Artwork_Details_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Details_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Digital_TwinArgs = {
  where: Artwork_Digital_Twin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Digital_Twin_By_PkArgs = {
  digital_twin_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_EventsArgs = {
  where: Artwork_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_External_Buy_LinkArgs = {
  where: Artwork_External_Buy_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_External_Buy_Link_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_PricesArgs = {
  where: Artwork_Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Prices_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_JourneyArgs = {
  where: Artwork_Purchase_Journey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_Journey_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_Journey_HistoryArgs = {
  where: Artwork_Purchase_Journey_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_Journey_History_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_Journey_Last_UpdateArgs = {
  where: Artwork_Purchase_Journey_Last_Update_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_Journey_Last_Update_By_PkArgs = {
  entry_kind: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum;
  journey_id: Scalars['bigint'];
  party_type: Enum_Artwork_Purchase_Journey_Party_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_Journey_PricingArgs = {
  where: Artwork_Purchase_Journey_Pricing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_Journey_Pricing_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_Journey_Shipping_InfoArgs = {
  where: Artwork_Purchase_Journey_Shipping_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Purchase_Journey_Shipping_Info_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Secondary_MediaArgs = {
  where: Artwork_Secondary_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Secondary_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_State_HistoryArgs = {
  where: Artwork_State_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_State_History_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_StoryArgs = {
  where: Artwork_Story_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Story_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Story_MediaArgs = {
  where: Artwork_Story_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Story_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Story_Video_LinksArgs = {
  where: Artwork_Story_Video_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Story_Video_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_TagArgs = {
  where: Artwork_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Tag_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_To_CollectionArgs = {
  where: Artwork_To_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_To_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_To_Showroom_DetailsArgs = {
  where: Artwork_To_Showroom_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_To_Showroom_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_To_TagArgs = {
  where: Artwork_To_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_To_Tag_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Artwork_TransactionsArgs = {
  where: Artwork_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Artwork_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_AuthArgs = {
  where: Auth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Context_AccessArgs = {
  where: Auth_Context_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Context_Access_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Available_Print_PriceArgs = {
  where: Available_Print_Price_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Available_Print_Price_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_Anon_ClientArgs = {
  where: Billing_Anon_Client_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Anon_Client_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_Artwork_Payment_IntentArgs = {
  where: Billing_Artwork_Payment_Intent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Artwork_Payment_Intent_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_Artwork_Payment_Intent_ProductArgs = {
  where: Billing_Artwork_Payment_Intent_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Artwork_Payment_Intent_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_Artwork_Payment_Intent_Shipping_AddressArgs = {
  where: Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Artwork_Payment_Intent_Shipping_Address_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_Artwork_Payment_Intent_Status_HistoryArgs = {
  where: Billing_Artwork_Payment_Intent_Status_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Artwork_Payment_Intent_Status_History_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_ClientArgs = {
  where: Billing_Client_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Client_By_PkArgs = {
  context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_SellerArgs = {
  where: Billing_Seller_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Seller_By_PkArgs = {
  context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_SubscriptionArgs = {
  where: Billing_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Subscription_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_Subscription_ProductArgs = {
  where: Billing_Subscription_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Billing_Subscription_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Biolink_SectionsArgs = {
  where: Biolink_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Biolink_Sections_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Certificate_Of_AuthenticityArgs = {
  where: Certificate_Of_Authenticity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Certificate_Of_Authenticity_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_CollaborationArgs = {
  where: Collaboration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Collaboration_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Collaboration_Invitation_On_ArtworkArgs = {
  where: Collaboration_Invitation_On_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Collaboration_Invitation_On_Artwork_By_PkArgs = {
  artwork_id: Scalars['bigint'];
  invitation_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_CollectionArgs = {
  where: Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Collection_TranslationArgs = {
  where: Collection_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Collection_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Collection_Video_LinksArgs = {
  where: Collection_Video_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Collection_Video_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Collections_Links_FilteredArgs = {
  where: Collections_Links_Filtered_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Collector_Links_FilteredArgs = {
  where: Collector_Links_Filtered_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Connected_DomainArgs = {
  where: Connected_Domain_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Connected_Domain_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Content_TranslationsArgs = {
  where: Content_Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ContextArgs = {
  where: Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Context_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Context_Delegate_AccessArgs = {
  where: Context_Delegate_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Context_Delegate_Access_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Context_FlagsArgs = {
  where: Context_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Context_Flags_By_PkArgs = {
  context_id: Scalars['bigint'];
  flag_type: Enum_Flags_Type_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Context_Suggested_TagsArgs = {
  where: Context_Suggested_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Context_Suggested_Tags_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Context_To_MilestoneArgs = {
  where: Context_To_Milestone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Context_To_Milestone_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Context_To_TutorialArgs = {
  where: Context_To_Tutorial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Context_To_Tutorial_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_CountriesArgs = {
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Countries_By_PkArgs = {
  iso3: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Crop_AreaArgs = {
  where: Crop_Area_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Crop_Area_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Currency_ConversionArgs = {
  where: Currency_Conversion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Currency_Conversion_By_PkArgs = {
  currency: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Digital_Twin_DetailsArgs = {
  where: Digital_Twin_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Digital_Twin_Details_By_PkArgs = {
  digital_twin_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Discount_CodeArgs = {
  where: Discount_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Discount_Code_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Discount_Code_UsageArgs = {
  where: Discount_Code_Usage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Discount_Code_Usage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Domain_Registrant_InfoArgs = {
  where: Domain_Registrant_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Domain_Registrant_Info_By_PkArgs = {
  domain_registration_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Domain_RegistrationArgs = {
  where: Domain_Registration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Domain_Registration_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dummy_Table_For_FrontArgs = {
  where: Dummy_Table_For_Front_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dummy_Table_For_Front_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_CvArgs = {
  where: Dynamic_Cv_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_AwardArgs = {
  where: Dynamic_Cv_Award_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_Award_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_CollectionArgs = {
  where: Dynamic_Cv_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_ContactArgs = {
  where: Dynamic_Cv_Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_Contact_By_PkArgs = {
  cv_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_EducationArgs = {
  where: Dynamic_Cv_Education_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_Education_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_ExhibitionArgs = {
  where: Dynamic_Cv_Exhibition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_Exhibition_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_PublicationArgs = {
  where: Dynamic_Cv_Publication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_Publication_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_SectionArgs = {
  where: Dynamic_Cv_Section_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dynamic_Cv_Section_By_PkArgs = {
  cv_id: Scalars['bigint'];
  type: Enum_Dynamic_Cv_Section_Type_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Account_Notice_TypeArgs = {
  where: Enum_Account_Notice_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Account_Notice_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Achievement_TagsArgs = {
  where: Enum_Achievement_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Achievement_Tags_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Add_Artwork_Flow_StepArgs = {
  where: Enum_Add_Artwork_Flow_Step_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Add_Artwork_Flow_Step_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Analytics_KaleidoCard_CategoryArgs = {
  where: Enum_Analytics_KaleidoCard_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Analytics_KaleidoCard_Category_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Analytics_TypeArgs = {
  where: Enum_Analytics_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Analytics_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_App_Notification_TypeArgs = {
  where: Enum_App_Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_App_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Art_TypeArgs = {
  where: Enum_Art_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Art_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Art_Type_ExtensionArgs = {
  where: Enum_Art_Type_Extension_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Art_Type_Extension_By_PkArgs = {
  Enum_art_type: Enum_Art_Type_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artist_Collection_CategoriesArgs = {
  where: Enum_Artist_Collection_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artist_Collection_Categories_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artist_Collection_StateArgs = {
  where: Enum_Artist_Collection_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artist_Collection_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artist_Collection_TypeArgs = {
  where: Enum_Artist_Collection_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artist_Collection_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_CategoryArgs = {
  where: Enum_Artwork_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Category_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Count_Milestone_TypeArgs = {
  where: Enum_Artwork_Count_Milestone_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Count_Milestone_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_DisciplineArgs = {
  where: Enum_Artwork_Discipline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Discipline_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Edition_TypeArgs = {
  where: Enum_Artwork_Edition_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Edition_Type_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Event_TypeArgs = {
  where: Enum_Artwork_Event_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Event_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_GenreArgs = {
  where: Enum_Artwork_Genre_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Genre_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Purchase_Journey_History_Entry_KindArgs = {
  where: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Purchase_Journey_History_Entry_Kind_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Purchase_Journey_PartyArgs = {
  where: Enum_Artwork_Purchase_Journey_Party_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Purchase_Journey_Party_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Purchase_Journey_StatusArgs = {
  where: Enum_Artwork_Purchase_Journey_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Purchase_Journey_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Showroom_StatusArgs = {
  where: Enum_Artwork_Showroom_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Showroom_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_StatusArgs = {
  where: Enum_Artwork_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_TagArgs = {
  where: Enum_Artwork_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Tag_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Tag_TypeArgs = {
  where: Enum_Artwork_Tag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Tag_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_TypeArgs = {
  where: Enum_Artwork_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Artwork_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Audio_Generation_StatusArgs = {
  where: Enum_Audio_Generation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Audio_Generation_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Auth_TypeArgs = {
  where: Enum_Auth_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Auth_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Artwork_Payment_Intent_StatusArgs = {
  where: Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Artwork_Payment_Intent_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Payment_Intent_Product_StatusArgs = {
  where: Enum_Billing_Payment_Intent_Product_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Payment_Intent_Product_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Seller_StatusArgs = {
  where: Enum_Billing_Seller_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Seller_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Subscription_PeriodArgs = {
  where: Enum_Billing_Subscription_Period_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Subscription_Period_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Subscription_Product_TypeArgs = {
  where: Enum_Billing_Subscription_Product_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Subscription_Product_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Subscription_StatusArgs = {
  where: Enum_Billing_Subscription_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Billing_Subscription_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_BlockchainArgs = {
  where: Enum_Blockchain_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Blockchain_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Collaboration_StatusArgs = {
  where: Enum_Collaboration_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Collaboration_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Collection_TypeArgs = {
  where: Enum_Collection_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Collection_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Connected_Domain_StateArgs = {
  where: Enum_Connected_Domain_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Connected_Domain_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Content_TypeArgs = {
  where: Enum_Content_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Content_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Context_Delegate_RoleArgs = {
  where: Enum_Context_Delegate_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Context_Delegate_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Context_Delegate_StateArgs = {
  where: Enum_Context_Delegate_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Context_Delegate_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Context_StateArgs = {
  where: Enum_Context_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Context_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Context_TypeArgs = {
  where: Enum_Context_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Context_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Discount_StateArgs = {
  where: Enum_Discount_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Discount_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Discount_TypeArgs = {
  where: Enum_Discount_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Discount_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Discount_Value_TypeArgs = {
  where: Enum_Discount_Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Discount_Value_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Domain_Registration_StatusArgs = {
  where: Enum_Domain_Registration_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Domain_Registration_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Dynamic_Cv_Exhibition_TypeArgs = {
  where: Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Dynamic_Cv_Exhibition_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Dynamic_Cv_Section_TypeArgs = {
  where: Enum_Dynamic_Cv_Section_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Dynamic_Cv_Section_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Dynamic_Cv_StatusArgs = {
  where: Enum_Dynamic_Cv_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Dynamic_Cv_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Edition_StatusArgs = {
  where: Enum_Edition_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Edition_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Email_LanguageArgs = {
  where: Enum_Email_Language_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Email_Language_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Email_StatusArgs = {
  where: Enum_Email_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Email_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Email_Validation_Token_StatusArgs = {
  where: Enum_Email_Validation_Token_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Email_Validation_Token_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_File_Metadata_StatusArgs = {
  where: Enum_File_Metadata_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_File_Metadata_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Flags_TypeArgs = {
  where: Enum_Flags_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Flags_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Forge_Configuration_TypeArgs = {
  where: Enum_Forge_Configuration_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Forge_Configuration_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Forge_StatusArgs = {
  where: Enum_Forge_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Forge_Status_By_PkArgs = {
  VALUE: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Infraction_Flag_TypeArgs = {
  where: Enum_Infraction_Flag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Infraction_Flag_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Interest_TypeArgs = {
  where: Enum_Interest_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Interest_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Language_PreferenceArgs = {
  where: Enum_Language_Preference_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Language_Preference_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_Artwork_InfoArgs = {
  where: Enum_My_Website_Artwork_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_Artwork_Info_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_FiltersArgs = {
  where: Enum_My_Website_Filters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_Filters_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_LayoutArgs = {
  where: Enum_My_Website_Layout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_Layout_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_NavigationArgs = {
  where: Enum_My_Website_Navigation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_Navigation_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_ThemeArgs = {
  where: Enum_My_Website_Theme_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_My_Website_Theme_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Notification_ChannelArgs = {
  where: Enum_Notification_Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Notification_Channel_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Notification_GroupArgs = {
  where: Enum_Notification_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Notification_Group_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Position_TypeArgs = {
  where: Enum_Position_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Position_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Price_ScopeArgs = {
  where: Enum_Price_Scope_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Price_Scope_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Print_TechniqueArgs = {
  where: Enum_Print_Technique_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Print_Technique_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Background_TypeArgs = {
  where: Enum_Profile_Background_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Background_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Invitation_StatusArgs = {
  where: Enum_Profile_Invitation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Invitation_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Public_StatusArgs = {
  where: Enum_Profile_Public_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Public_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_RegionArgs = {
  where: Enum_Profile_Region_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Region_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_StatusArgs = {
  where: Enum_Profile_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_ThemeArgs = {
  where: Enum_Profile_Theme_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Theme_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_TypeArgs = {
  where: Enum_Profile_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Profile_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Pseudo_User_ActionArgs = {
  where: Enum_Pseudo_User_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Pseudo_User_Action_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Push_Notification_StatusArgs = {
  where: Enum_Push_Notification_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Push_Notification_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Push_Notification_TypeArgs = {
  where: Enum_Push_Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Push_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Questionnaire_TypeArgs = {
  where: Enum_Questionnaire_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Questionnaire_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Render_Request_StatusArgs = {
  where: Enum_Render_Request_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Render_Request_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Render_Request_Template_TypeArgs = {
  where: Enum_Render_Request_Template_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Render_Request_Template_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Restricted_Handle_NamesArgs = {
  where: Enum_Restricted_Handle_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Restricted_Handle_Names_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_SizeArgs = {
  where: Enum_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Size_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Social_Graph_Followers_StateArgs = {
  where: Enum_Social_Graph_Followers_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Social_Graph_Followers_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Social_Link_StatusArgs = {
  where: Enum_Social_Link_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Social_Link_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Social_Link_TypeArgs = {
  where: Enum_Social_Link_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Social_Link_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Timed_Event_NameArgs = {
  where: Enum_Timed_Event_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Timed_Event_Name_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Token_Storage_StatusArgs = {
  where: Enum_Token_Storage_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Token_Storage_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Transaction_OriginArgs = {
  where: Enum_Transaction_Origin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Transaction_Origin_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Transaction_StatusArgs = {
  where: Enum_Transaction_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Transaction_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Tutorial_StatusArgs = {
  where: Enum_Tutorial_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Tutorial_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Tutorial_Step_TypeArgs = {
  where: Enum_Tutorial_Step_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Tutorial_Step_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Tutorial_TypeArgs = {
  where: Enum_Tutorial_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Tutorial_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Vumark_StatusArgs = {
  where: Enum_Vumark_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Vumark_Status_By_PkArgs = {
  VALUE: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Vumark_TypeArgs = {
  where: Enum_Vumark_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Vumark_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EditionArgs = {
  where: Edition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Edition_By_SizeArgs = {
  where: Edition_By_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Edition_By_Size_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Edition_By_TypeArgs = {
  where: Edition_By_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Edition_By_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Edition_TypeArgs = {
  where: Edition_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Edition_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Edition_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Edition_EventsArgs = {
  where: Edition_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Edition_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Validation_TokenArgs = {
  where: Email_Validation_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Validation_Token_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_EmailsArgs = {
  where: Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Emails_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Fcm_TokenArgs = {
  where: Fcm_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fcm_Token_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_FeaturesArgs = {
  where: Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Features_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_File_MetadataArgs = {
  where: File_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_Metadata_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Firebase_ProfileArgs = {
  where: Firebase_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Firebase_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Flagged_UsersArgs = {
  where: Flagged_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Flagged_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Forge_InProcess_TaskArgs = {
  where: Forge_InProcess_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Forge_InProcess_Task_By_PkArgs = {
  forge_task_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Forge_TasksArgs = {
  where: Forge_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Forge_Tasks_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Global_PropertiesArgs = {
  where: Global_Properties_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Global_Properties_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_ArtworkArgs = {
  where: Graveyard_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_DetailsArgs = {
  where: Graveyard_Artwork_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Details_TranslationArgs = {
  where: Graveyard_Artwork_Details_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Details_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_EventsArgs = {
  where: Graveyard_Artwork_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_PricesArgs = {
  where: Graveyard_Artwork_Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Prices_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Secondary_MediaArgs = {
  where: Graveyard_Artwork_Secondary_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Secondary_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_StoryArgs = {
  where: Graveyard_Artwork_Story_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Story_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Story_MediaArgs = {
  where: Graveyard_Artwork_Story_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Story_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_To_CollectionArgs = {
  where: Graveyard_Artwork_To_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_To_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_TransactionsArgs = {
  where: Graveyard_Artwork_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Artwork_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Purchase_InquiresArgs = {
  where: Graveyard_Purchase_Inquires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Graveyard_Purchase_Inquires_Outside_UsersArgs = {
  where: Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InterestsArgs = {
  where: Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Interests_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Kaleido_General_SettingsArgs = {
  where: Kaleido_General_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Kaleido_General_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Landing_Page_ArtworkArgs = {
  where: Landing_Page_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Landing_Page_Artwork_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_LanguagesArgs = {
  where: Languages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Languages_By_PkArgs = {
  language_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Materialized_ViewArgs = {
  where: Materialized_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Materialized_View_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Media_MetadataArgs = {
  where: Media_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Media_Metadata_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_MilestoneArgs = {
  where: Milestone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Milestone_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_My_Website_SettingsArgs = {
  where: My_Website_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_My_Website_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_My_Studio_SettingsArgs = {
  where: My_Studio_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_My_Studio_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_PreferenceArgs = {
  where: Notification_Preference_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Preference_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Pending_Collaboration_On_ArtworkArgs = {
  where: Pending_Collaboration_On_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pending_Collaboration_On_Artwork_By_PkArgs = {
  artwork_id: Scalars['bigint'];
  collaboration_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Price_RangesArgs = {
  where: Price_Ranges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Price_Ranges_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_PrintArgs = {
  where: Print_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Print_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Print_To_CollectionArgs = {
  where: Print_To_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Print_To_Collection_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Prize_Art_ShowArgs = {
  where: Prize_Art_Show_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Prize_Art_Show_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ProfileArgs = {
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_InterestsArgs = {
  where: Profile_Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Interests_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_AchievementsArgs = {
  where: Profile_Achievements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Achievements_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Appearance_SettingsArgs = {
  where: Profile_Appearance_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Appearance_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_BackgroundArgs = {
  where: Profile_Background_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Background_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Ghost_TokensArgs = {
  where: Profile_Ghost_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Ghost_Tokens_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_InvitationsArgs = {
  where: Profile_Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Invitations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_LocationArgs = {
  where: Profile_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Location_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Location_TranslationArgs = {
  where: Profile_Location_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Location_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Price_RangesArgs = {
  where: Profile_Price_Ranges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Price_Ranges_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Questions_And_AnswersArgs = {
  where: Profile_Questions_And_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Questions_And_Answers_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_SettingsArgs = {
  where: Profile_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Social_LinksArgs = {
  where: Profile_Social_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Social_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Statement_Audio_SynthesesArgs = {
  where: Profile_Statement_Audio_Syntheses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Statement_Audio_Syntheses_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_TranslationArgs = {
  where: Profile_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Video_LinksArgs = {
  where: Profile_Video_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Video_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_Wallet_AddressArgs = {
  where: Profile_Wallet_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Wallet_Address_By_PkArgs = {
  profile_context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Pubnub_ChannelsArgs = {
  where: Pubnub_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pubnub_Channels_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Purchase_InquiresArgs = {
  where: Purchase_Inquires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Purchase_Inquires_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Purchase_Inquires_Outside_UsersArgs = {
  where: Purchase_Inquires_Outside_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Purchase_Inquires_Outside_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Push_NotificationsArgs = {
  where: Push_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Push_Notifications_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_QuestionsArgs = {
  where: Questionnaire_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Render_RequestArgs = {
  where: Render_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Render_Request_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Setting_Context_Default_ProfileArgs = {
  where: Setting_Context_Default_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Setting_Context_Default_Profile_By_PkArgs = {
  app_access_context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Showroom_DetailsArgs = {
  where: Showroom_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Social_Graph_FollowersArgs = {
  where: Social_Graph_Followers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Social_Graph_Followers_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Spotlight_ContextArgs = {
  where: Spotlight_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Spotlight_Context_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Super_Site_SubscribersArgs = {
  where: Super_Site_Subscribers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Super_Site_Subscribers_By_PkArgs = {
  context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_InterestArgs = {
  where: Tags_By_Interest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_Interest_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Test_Analytics_EventsArgs = {
  where: Test_Analytics_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Test_Analytics_Events_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Test_AccountsArgs = {
  where: Test_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Test_Accounts_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Timed_EventArgs = {
  where: Timed_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Timed_Event_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Token_StorageArgs = {
  where: Token_Storage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Token_Storage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_TutorialArgs = {
  where: Tutorial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tutorial_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Tutorial_StepArgs = {
  where: Tutorial_Step_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tutorial_Step_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_Analytics_Points_IndexArgs = {
  where: User_Analytics_Points_Index_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Analytics_Points_Index_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Vr_ArtistsArgs = {
  where: Vr_Artists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vr_Artists_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Vision_InterestArgs = {
  where: Vision_Interest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vision_Interest_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_VumarksArgs = {
  where: Vumarks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vumarks_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Waiting_ListArgs = {
  where: Waiting_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Waiting_List_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_WalletsArgs = {
  where: Wallets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wallets_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_World_CitiesArgs = {
  where: World_Cities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_World_Cities_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_App_Notifications_InfoArgs = {
  where: App_Notifications_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Notifications_ListingArgs = {
  where: App_Notifications_Listing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Notifications_ViewArgs = {
  where: App_Notifications_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_PhonesArgs = {
  where: Profile_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Phones_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Showroom_Owner_PasswordArgs = {
  where: Showroom_Owner_Password_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_WorldcitiesArgs = {
  where: Worldcities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Worldcities_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDisableExistingValidAiAudioStatementsArgs = {
  profileId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootEditArtistCategoriesArgs = {
  artistContextId: Scalars['bigint'];
  interestIds: Array<Scalars['bigint']>;
};


/** mutation root */
export type Mutation_RootEditArtistCollectionsArgs = {
  artistContextId: Scalars['bigint'];
  collectionIds: Array<Scalars['bigint']>;
};


/** mutation root */
export type Mutation_RootEnsureArtUniverseArtistInterestOrderArgs = {
  context_id: Scalars['Int'];
  interest_ids: Array<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootEnterShowroomPasswordArgs = {
  password: Scalars['String'];
  showroom_slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootExpireCheckoutActiveCheckoutSessionsForBuyerArgs = {
  buyerContextId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootFinishTransferArgs = {
  artwork_slug?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootFirebaseSignUpArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  invitationToken?: Maybe<Scalars['String']>;
  last_name: Scalars['String'];
  password: Scalars['String'];
  preferred_language: Scalars['String'];
};


/** mutation root */
export type Mutation_RootFirebaseSignUpNewArgs = {
  email?: Maybe<Scalars['String']>;
  invitationToken?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootFirebaseSignUpNewFullContentArgs = {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  invitationToken?: Maybe<Scalars['String']>;
  languagePreference: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
  tokenId?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootFirebaseSignUpWithVerificationArgs = {
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  invitationToken?: Maybe<Scalars['String']>;
  last_name: Scalars['String'];
  password: Scalars['String'];
  preferred_language: Scalars['String'];
  token: Scalars['String'];
  tokenId?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootFixMissingDomainSubscriptionArgs = {
  domainId?: Maybe<Array<Scalars['bigint']>>;
};


/** mutation root */
export type Mutation_RootFlagUserIfIsScammerMutationArgs = {
  contextId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootGalleryAcceptInvitationArgs = {
  collaboration_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootGalleryCreateArtworkArgs = {
  artist_context_id: Scalars['Int'];
  edition_type?: Maybe<Scalars['String']>;
  gallery_context_id: Scalars['Int'];
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGalleryHandleInvitationArgs = {
  collaboration_id: Scalars['Int'];
  gallery_status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGalleryInviteArtistCollaborationArgs = {
  context_id: Scalars['Int'];
  email: Scalars['String'];
  invitee_context_id?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootGalleryRejectInvitationArgs = {
  collaboration_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootGenerateAudioStatementArgs = {
  language: Scalars['String'];
  profileId: Scalars['bigint'];
  text: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGenerateCoAArgs = {
  artPieceId: Scalars['bigint'];
  pdf?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootGenerateProfileCollaborationCodeArgs = {
  profile_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootGetContentTranslationArgs = {
  content: Scalars['String'];
  content_type: Scalars['String'];
  entity_id: Scalars['Int'];
  secondary_entity_id?: Maybe<Scalars['Int']>;
  target_language: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGet_Pending_Forge_Task_ProcedureArgs = {
  distinct_on?: Maybe<Array<Pending_Forge_Task_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Forge_Task_View_Order_By>>;
  where?: Maybe<Pending_Forge_Task_View_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootGrantAchievementArgs = {
  achievementName: Scalars['String'];
  profileContextId: Scalars['Int'];
  updateNow?: Maybe<Scalars['Boolean']>;
  userID: Scalars['Int'];
  visitorIdCookie: Scalars['String'];
};


/** mutation root */
export type Mutation_RootGrantOwnerArgs = {
  context_id: Scalars['Int'];
  pwd: Scalars['String'];
};


/** mutation root */
export type Mutation_RootHideArtUniverseArtworksArgs = {
  artworks: Array<Scalars['Int']>;
  context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootIncreaseShowroomViewsArgs = {
  showroom_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInitChatArgs = {
  contextId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInitiateArtworkPurchaseTransactionArgs = {
  artworkId: Scalars['bigint'];
  artworkPriceScope: Scalars['String'];
  cancelUrl: Scalars['String'];
  successUrl: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInitiateBillingSellerOnboardingArgs = {
  contextId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootInitiateCartItemsAnonPurchaseTransactionArgs = {
  anonId?: Maybe<Scalars['bigint']>;
  cancelUrl: Scalars['String'];
  cartArtworkIds: Array<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  successUrl: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInitiateCartItemsPurchaseTransactionArgs = {
  buyerContextId: Scalars['bigint'];
  cancelUrl: Scalars['String'];
  successUrl: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInquireForPurchaseByLoggedUserArgs = {
  artwork_id: Scalars['Int'];
  user_context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInquireForPurchaseByOutsideUserArgs = {
  artwork_id: Scalars['Int'];
  inquirer_email: Scalars['String'];
  inquirer_first_name: Scalars['String'];
  inquirer_ip_addr: Scalars['String'];
  is_custom_domain?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootInquireForPurchaseByOutsideUserActionArgs = {
  artwork_id: Scalars['Int'];
  inquirer_email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsertDiscountCodeUsageArgs = {
  contextId?: Maybe<Scalars['bigint']>;
  discountCode?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Scalars['Float']>;
  purchasedItem?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootInsertProfileBackgroundsArgs = {
  backgrounds: Array<Scalars['String']>;
  context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsertProfilePhoneNumberArgs = {
  context_id: Scalars['Int'];
  phone: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Account_Initial_Profile_TypeArgs = {
  objects: Array<Account_Initial_Profile_Type_Insert_Input>;
  on_conflict?: Maybe<Account_Initial_Profile_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Initial_Profile_Type_OneArgs = {
  object: Account_Initial_Profile_Type_Insert_Input;
  on_conflict?: Maybe<Account_Initial_Profile_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Notice_AckArgs = {
  objects: Array<Account_Notice_Ack_Insert_Input>;
  on_conflict?: Maybe<Account_Notice_Ack_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Notice_Ack_OneArgs = {
  object: Account_Notice_Ack_Insert_Input;
  on_conflict?: Maybe<Account_Notice_Ack_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_ValidationArgs = {
  objects: Array<Account_Validation_Insert_Input>;
  on_conflict?: Maybe<Account_Validation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Validation_OneArgs = {
  object: Account_Validation_Insert_Input;
  on_conflict?: Maybe<Account_Validation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Achievement_HooksArgs = {
  objects: Array<Achievement_Hooks_Insert_Input>;
  on_conflict?: Maybe<Achievement_Hooks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Achievement_Hooks_OneArgs = {
  object: Achievement_Hooks_Insert_Input;
  on_conflict?: Maybe<Achievement_Hooks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Achievement_TagsArgs = {
  objects: Array<Achievement_Tags_Insert_Input>;
  on_conflict?: Maybe<Achievement_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Achievement_Tags_OneArgs = {
  object: Achievement_Tags_Insert_Input;
  on_conflict?: Maybe<Achievement_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AchievementsArgs = {
  objects: Array<Achievements_Insert_Input>;
  on_conflict?: Maybe<Achievements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Achievements_OneArgs = {
  object: Achievements_Insert_Input;
  on_conflict?: Maybe<Achievements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_PasswordArgs = {
  objects: Array<Admin_Password_Insert_Input>;
  on_conflict?: Maybe<Admin_Password_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_Password_OneArgs = {
  object: Admin_Password_Insert_Input;
  on_conflict?: Maybe<Admin_Password_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_To_User_NotificationArgs = {
  objects: Array<Admin_To_User_Notification_Insert_Input>;
  on_conflict?: Maybe<Admin_To_User_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Admin_To_User_Notification_OneArgs = {
  object: Admin_To_User_Notification_Insert_Input;
  on_conflict?: Maybe<Admin_To_User_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_All_Artwork_Availability_TableArgs = {
  objects: Array<All_Artwork_Availability_Table_Insert_Input>;
  on_conflict?: Maybe<All_Artwork_Availability_Table_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_All_Artwork_Availability_Table_OneArgs = {
  object: All_Artwork_Availability_Table_Insert_Input;
  on_conflict?: Maybe<All_Artwork_Availability_Table_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_EventsArgs = {
  objects: Array<Analytics_Events_Insert_Input>;
  on_conflict?: Maybe<Analytics_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_Events_OneArgs = {
  object: Analytics_Events_Insert_Input;
  on_conflict?: Maybe<Analytics_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_Index_HistoryArgs = {
  objects: Array<Analytics_Index_History_Insert_Input>;
  on_conflict?: Maybe<Analytics_Index_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_Index_History_OneArgs = {
  object: Analytics_Index_History_Insert_Input;
  on_conflict?: Maybe<Analytics_Index_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_Index_TypeArgs = {
  objects: Array<Analytics_Index_Type_Insert_Input>;
  on_conflict?: Maybe<Analytics_Index_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_Index_Type_OneArgs = {
  object: Analytics_Index_Type_Insert_Input;
  on_conflict?: Maybe<Analytics_Index_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_Index_ValuesArgs = {
  objects: Array<Analytics_Index_Values_Insert_Input>;
  on_conflict?: Maybe<Analytics_Index_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_Index_Values_OneArgs = {
  object: Analytics_Index_Values_Insert_Input;
  on_conflict?: Maybe<Analytics_Index_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_KaleidoCard_AmountsArgs = {
  objects: Array<Analytics_KaleidoCard_Amounts_Insert_Input>;
  on_conflict?: Maybe<Analytics_KaleidoCard_Amounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_KaleidoCard_Amounts_OneArgs = {
  object: Analytics_KaleidoCard_Amounts_Insert_Input;
  on_conflict?: Maybe<Analytics_KaleidoCard_Amounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_KaleidoCard_EventsArgs = {
  objects: Array<Analytics_KaleidoCard_Events_Insert_Input>;
  on_conflict?: Maybe<Analytics_KaleidoCard_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Analytics_KaleidoCard_Events_OneArgs = {
  object: Analytics_KaleidoCard_Events_Insert_Input;
  on_conflict?: Maybe<Analytics_KaleidoCard_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_NotificationsArgs = {
  objects: Array<App_Notifications_Insert_Input>;
  on_conflict?: Maybe<App_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_Notifications_OneArgs = {
  object: App_Notifications_Insert_Input;
  on_conflict?: Maybe<App_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_History_MovementArgs = {
  objects: Array<Art_History_Movement_Insert_Input>;
  on_conflict?: Maybe<Art_History_Movement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_History_Movement_OneArgs = {
  object: Art_History_Movement_Insert_Input;
  on_conflict?: Maybe<Art_History_Movement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prize_ArtworkArgs = {
  objects: Array<Art_Prize_Artwork_Insert_Input>;
  on_conflict?: Maybe<Art_Prize_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prize_Artwork_OneArgs = {
  object: Art_Prize_Artwork_Insert_Input;
  on_conflict?: Maybe<Art_Prize_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prize_ShowArgs = {
  objects: Array<Art_Prize_Show_Insert_Input>;
  on_conflict?: Maybe<Art_Prize_Show_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prize_Show_OneArgs = {
  object: Art_Prize_Show_Insert_Input;
  on_conflict?: Maybe<Art_Prize_Show_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prize_Show_WinnersArgs = {
  objects: Array<Art_Prize_Show_Winners_Insert_Input>;
  on_conflict?: Maybe<Art_Prize_Show_Winners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prize_Show_Winners_OneArgs = {
  object: Art_Prize_Show_Winners_Insert_Input;
  on_conflict?: Maybe<Art_Prize_Show_Winners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prize_WinnerArgs = {
  objects: Array<Art_Prize_Winner_Insert_Input>;
  on_conflict?: Maybe<Art_Prize_Winner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prize_Winner_OneArgs = {
  object: Art_Prize_Winner_Insert_Input;
  on_conflict?: Maybe<Art_Prize_Winner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prizes_Links_FilteredArgs = {
  objects: Array<Art_Prizes_Links_Filtered_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Prizes_Links_Filtered_OneArgs = {
  object: Art_Prizes_Links_Filtered_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Art_Show_DetailsArgs = {
  objects: Array<Art_Show_Details_Insert_Input>;
  on_conflict?: Maybe<Art_Show_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Show_Details_OneArgs = {
  object: Art_Show_Details_Insert_Input;
  on_conflict?: Maybe<Art_Show_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Type_To_Artwork_DisciplineArgs = {
  objects: Array<Art_Type_To_Artwork_Discipline_Insert_Input>;
  on_conflict?: Maybe<Art_Type_To_Artwork_Discipline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Art_Type_To_Artwork_Discipline_OneArgs = {
  object: Art_Type_To_Artwork_Discipline_Insert_Input;
  on_conflict?: Maybe<Art_Type_To_Artwork_Discipline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_CollectionArgs = {
  objects: Array<Artist_Collection_Insert_Input>;
  on_conflict?: Maybe<Artist_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Collection_CategoriesArgs = {
  objects: Array<Artist_Collection_Categories_Insert_Input>;
  on_conflict?: Maybe<Artist_Collection_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Collection_Categories_OneArgs = {
  object: Artist_Collection_Categories_Insert_Input;
  on_conflict?: Maybe<Artist_Collection_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Collection_OneArgs = {
  object: Artist_Collection_Insert_Input;
  on_conflict?: Maybe<Artist_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Collection_To_Artist_ContextArgs = {
  objects: Array<Artist_Collection_To_Artist_Context_Insert_Input>;
  on_conflict?: Maybe<Artist_Collection_To_Artist_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Collection_To_Artist_Context_OneArgs = {
  object: Artist_Collection_To_Artist_Context_Insert_Input;
  on_conflict?: Maybe<Artist_Collection_To_Artist_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Art_History_VisibilityArgs = {
  objects: Array<Artist_Art_History_Visibility_Insert_Input>;
  on_conflict?: Maybe<Artist_Art_History_Visibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Art_History_Visibility_OneArgs = {
  object: Artist_Art_History_Visibility_Insert_Input;
  on_conflict?: Maybe<Artist_Art_History_Visibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Curation_ScoreArgs = {
  objects: Array<Artist_Curation_Score_Insert_Input>;
  on_conflict?: Maybe<Artist_Curation_Score_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Curation_Score_OneArgs = {
  object: Artist_Curation_Score_Insert_Input;
  on_conflict?: Maybe<Artist_Curation_Score_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Explore_VisibilityArgs = {
  objects: Array<Artist_Explore_Visibility_Insert_Input>;
  on_conflict?: Maybe<Artist_Explore_Visibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Explore_Visibility_OneArgs = {
  object: Artist_Explore_Visibility_Insert_Input;
  on_conflict?: Maybe<Artist_Explore_Visibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Links_FilteredArgs = {
  objects: Array<Artist_Links_Filtered_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Links_Filtered_OneArgs = {
  object: Artist_Links_Filtered_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Artist_NewsletterArgs = {
  objects: Array<Artist_Newsletter_Insert_Input>;
  on_conflict?: Maybe<Artist_Newsletter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Newsletter_OneArgs = {
  object: Artist_Newsletter_Insert_Input;
  on_conflict?: Maybe<Artist_Newsletter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Vision_VisibilityArgs = {
  objects: Array<Artist_Vision_Visibility_Insert_Input>;
  on_conflict?: Maybe<Artist_Vision_Visibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artist_Vision_Visibility_OneArgs = {
  object: Artist_Vision_Visibility_Insert_Input;
  on_conflict?: Maybe<Artist_Vision_Visibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ArtworkArgs = {
  objects: Array<Artwork_Insert_Input>;
  on_conflict?: Maybe<Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Ai_TagsArgs = {
  objects: Array<Artwork_Ai_Tags_Insert_Input>;
  on_conflict?: Maybe<Artwork_Ai_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Ai_Tags_OneArgs = {
  object: Artwork_Ai_Tags_Insert_Input;
  on_conflict?: Maybe<Artwork_Ai_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Art_History_TimetableArgs = {
  objects: Array<Artwork_Art_History_Timetable_Insert_Input>;
  on_conflict?: Maybe<Artwork_Art_History_Timetable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Art_History_Timetable_OneArgs = {
  object: Artwork_Art_History_Timetable_Insert_Input;
  on_conflict?: Maybe<Artwork_Art_History_Timetable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Aspect_RatioArgs = {
  objects: Array<Artwork_Aspect_Ratio_Insert_Input>;
  on_conflict?: Maybe<Artwork_Aspect_Ratio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Aspect_Ratio_OneArgs = {
  object: Artwork_Aspect_Ratio_Insert_Input;
  on_conflict?: Maybe<Artwork_Aspect_Ratio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Count_MilestonesArgs = {
  objects: Array<Artwork_Count_Milestones_Insert_Input>;
  on_conflict?: Maybe<Artwork_Count_Milestones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Count_Milestones_OneArgs = {
  object: Artwork_Count_Milestones_Insert_Input;
  on_conflict?: Maybe<Artwork_Count_Milestones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Curation_ScoreArgs = {
  objects: Array<Artwork_Curation_Score_Insert_Input>;
  on_conflict?: Maybe<Artwork_Curation_Score_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Curation_Score_OneArgs = {
  object: Artwork_Curation_Score_Insert_Input;
  on_conflict?: Maybe<Artwork_Curation_Score_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_DetailsArgs = {
  objects: Array<Artwork_Details_Insert_Input>;
  on_conflict?: Maybe<Artwork_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Details_OneArgs = {
  object: Artwork_Details_Insert_Input;
  on_conflict?: Maybe<Artwork_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Details_TranslationArgs = {
  objects: Array<Artwork_Details_Translation_Insert_Input>;
  on_conflict?: Maybe<Artwork_Details_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Details_Translation_OneArgs = {
  object: Artwork_Details_Translation_Insert_Input;
  on_conflict?: Maybe<Artwork_Details_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Digital_TwinArgs = {
  objects: Array<Artwork_Digital_Twin_Insert_Input>;
  on_conflict?: Maybe<Artwork_Digital_Twin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Digital_Twin_OneArgs = {
  object: Artwork_Digital_Twin_Insert_Input;
  on_conflict?: Maybe<Artwork_Digital_Twin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_EventsArgs = {
  objects: Array<Artwork_Events_Insert_Input>;
  on_conflict?: Maybe<Artwork_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Events_OneArgs = {
  object: Artwork_Events_Insert_Input;
  on_conflict?: Maybe<Artwork_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_External_Buy_LinkArgs = {
  objects: Array<Artwork_External_Buy_Link_Insert_Input>;
  on_conflict?: Maybe<Artwork_External_Buy_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_External_Buy_Link_OneArgs = {
  object: Artwork_External_Buy_Link_Insert_Input;
  on_conflict?: Maybe<Artwork_External_Buy_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_OneArgs = {
  object: Artwork_Insert_Input;
  on_conflict?: Maybe<Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_PricesArgs = {
  objects: Array<Artwork_Prices_Insert_Input>;
  on_conflict?: Maybe<Artwork_Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Prices_OneArgs = {
  object: Artwork_Prices_Insert_Input;
  on_conflict?: Maybe<Artwork_Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_JourneyArgs = {
  objects: Array<Artwork_Purchase_Journey_Insert_Input>;
  on_conflict?: Maybe<Artwork_Purchase_Journey_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_Journey_HistoryArgs = {
  objects: Array<Artwork_Purchase_Journey_History_Insert_Input>;
  on_conflict?: Maybe<Artwork_Purchase_Journey_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_Journey_History_OneArgs = {
  object: Artwork_Purchase_Journey_History_Insert_Input;
  on_conflict?: Maybe<Artwork_Purchase_Journey_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_Journey_Last_UpdateArgs = {
  objects: Array<Artwork_Purchase_Journey_Last_Update_Insert_Input>;
  on_conflict?: Maybe<Artwork_Purchase_Journey_Last_Update_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_Journey_Last_Update_OneArgs = {
  object: Artwork_Purchase_Journey_Last_Update_Insert_Input;
  on_conflict?: Maybe<Artwork_Purchase_Journey_Last_Update_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_Journey_OneArgs = {
  object: Artwork_Purchase_Journey_Insert_Input;
  on_conflict?: Maybe<Artwork_Purchase_Journey_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_Journey_PricingArgs = {
  objects: Array<Artwork_Purchase_Journey_Pricing_Insert_Input>;
  on_conflict?: Maybe<Artwork_Purchase_Journey_Pricing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_Journey_Pricing_OneArgs = {
  object: Artwork_Purchase_Journey_Pricing_Insert_Input;
  on_conflict?: Maybe<Artwork_Purchase_Journey_Pricing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_Journey_Shipping_InfoArgs = {
  objects: Array<Artwork_Purchase_Journey_Shipping_Info_Insert_Input>;
  on_conflict?: Maybe<Artwork_Purchase_Journey_Shipping_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Purchase_Journey_Shipping_Info_OneArgs = {
  object: Artwork_Purchase_Journey_Shipping_Info_Insert_Input;
  on_conflict?: Maybe<Artwork_Purchase_Journey_Shipping_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Secondary_MediaArgs = {
  objects: Array<Artwork_Secondary_Media_Insert_Input>;
  on_conflict?: Maybe<Artwork_Secondary_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Secondary_Media_OneArgs = {
  object: Artwork_Secondary_Media_Insert_Input;
  on_conflict?: Maybe<Artwork_Secondary_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_State_HistoryArgs = {
  objects: Array<Artwork_State_History_Insert_Input>;
  on_conflict?: Maybe<Artwork_State_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_State_History_OneArgs = {
  object: Artwork_State_History_Insert_Input;
  on_conflict?: Maybe<Artwork_State_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_StoryArgs = {
  objects: Array<Artwork_Story_Insert_Input>;
  on_conflict?: Maybe<Artwork_Story_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Story_MediaArgs = {
  objects: Array<Artwork_Story_Media_Insert_Input>;
  on_conflict?: Maybe<Artwork_Story_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Story_Media_OneArgs = {
  object: Artwork_Story_Media_Insert_Input;
  on_conflict?: Maybe<Artwork_Story_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Story_OneArgs = {
  object: Artwork_Story_Insert_Input;
  on_conflict?: Maybe<Artwork_Story_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Story_Video_LinksArgs = {
  objects: Array<Artwork_Story_Video_Links_Insert_Input>;
  on_conflict?: Maybe<Artwork_Story_Video_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Story_Video_Links_OneArgs = {
  object: Artwork_Story_Video_Links_Insert_Input;
  on_conflict?: Maybe<Artwork_Story_Video_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_TagArgs = {
  objects: Array<Artwork_Tag_Insert_Input>;
  on_conflict?: Maybe<Artwork_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Tag_OneArgs = {
  object: Artwork_Tag_Insert_Input;
  on_conflict?: Maybe<Artwork_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_To_CollectionArgs = {
  objects: Array<Artwork_To_Collection_Insert_Input>;
  on_conflict?: Maybe<Artwork_To_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_To_Collection_OneArgs = {
  object: Artwork_To_Collection_Insert_Input;
  on_conflict?: Maybe<Artwork_To_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_To_Showroom_DetailsArgs = {
  objects: Array<Artwork_To_Showroom_Details_Insert_Input>;
  on_conflict?: Maybe<Artwork_To_Showroom_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_To_Showroom_Details_OneArgs = {
  object: Artwork_To_Showroom_Details_Insert_Input;
  on_conflict?: Maybe<Artwork_To_Showroom_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_To_TagArgs = {
  objects: Array<Artwork_To_Tag_Insert_Input>;
  on_conflict?: Maybe<Artwork_To_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_To_Tag_OneArgs = {
  object: Artwork_To_Tag_Insert_Input;
  on_conflict?: Maybe<Artwork_To_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_TransactionsArgs = {
  objects: Array<Artwork_Transactions_Insert_Input>;
  on_conflict?: Maybe<Artwork_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Artwork_Transactions_OneArgs = {
  object: Artwork_Transactions_Insert_Input;
  on_conflict?: Maybe<Artwork_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AuthArgs = {
  objects: Array<Auth_Insert_Input>;
  on_conflict?: Maybe<Auth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Context_AccessArgs = {
  objects: Array<Auth_Context_Access_Insert_Input>;
  on_conflict?: Maybe<Auth_Context_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Context_Access_OneArgs = {
  object: Auth_Context_Access_Insert_Input;
  on_conflict?: Maybe<Auth_Context_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_OneArgs = {
  object: Auth_Insert_Input;
  on_conflict?: Maybe<Auth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Available_Print_PriceArgs = {
  objects: Array<Available_Print_Price_Insert_Input>;
  on_conflict?: Maybe<Available_Print_Price_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Available_Print_Price_OneArgs = {
  object: Available_Print_Price_Insert_Input;
  on_conflict?: Maybe<Available_Print_Price_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Anon_ClientArgs = {
  objects: Array<Billing_Anon_Client_Insert_Input>;
  on_conflict?: Maybe<Billing_Anon_Client_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Anon_Client_OneArgs = {
  object: Billing_Anon_Client_Insert_Input;
  on_conflict?: Maybe<Billing_Anon_Client_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Artwork_Payment_IntentArgs = {
  objects: Array<Billing_Artwork_Payment_Intent_Insert_Input>;
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Artwork_Payment_Intent_OneArgs = {
  object: Billing_Artwork_Payment_Intent_Insert_Input;
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Artwork_Payment_Intent_ProductArgs = {
  objects: Array<Billing_Artwork_Payment_Intent_Product_Insert_Input>;
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Artwork_Payment_Intent_Product_OneArgs = {
  object: Billing_Artwork_Payment_Intent_Product_Insert_Input;
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Artwork_Payment_Intent_Shipping_AddressArgs = {
  objects: Array<Billing_Artwork_Payment_Intent_Shipping_Address_Insert_Input>;
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Artwork_Payment_Intent_Shipping_Address_OneArgs = {
  object: Billing_Artwork_Payment_Intent_Shipping_Address_Insert_Input;
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Artwork_Payment_Intent_Status_HistoryArgs = {
  objects: Array<Billing_Artwork_Payment_Intent_Status_History_Insert_Input>;
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_Status_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Artwork_Payment_Intent_Status_History_OneArgs = {
  object: Billing_Artwork_Payment_Intent_Status_History_Insert_Input;
  on_conflict?: Maybe<Billing_Artwork_Payment_Intent_Status_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_ClientArgs = {
  objects: Array<Billing_Client_Insert_Input>;
  on_conflict?: Maybe<Billing_Client_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Client_OneArgs = {
  object: Billing_Client_Insert_Input;
  on_conflict?: Maybe<Billing_Client_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_SellerArgs = {
  objects: Array<Billing_Seller_Insert_Input>;
  on_conflict?: Maybe<Billing_Seller_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Seller_OneArgs = {
  object: Billing_Seller_Insert_Input;
  on_conflict?: Maybe<Billing_Seller_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_SubscriptionArgs = {
  objects: Array<Billing_Subscription_Insert_Input>;
  on_conflict?: Maybe<Billing_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Subscription_OneArgs = {
  object: Billing_Subscription_Insert_Input;
  on_conflict?: Maybe<Billing_Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Subscription_ProductArgs = {
  objects: Array<Billing_Subscription_Product_Insert_Input>;
  on_conflict?: Maybe<Billing_Subscription_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_Subscription_Product_OneArgs = {
  object: Billing_Subscription_Product_Insert_Input;
  on_conflict?: Maybe<Billing_Subscription_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Biolink_SectionsArgs = {
  objects: Array<Biolink_Sections_Insert_Input>;
  on_conflict?: Maybe<Biolink_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Biolink_Sections_OneArgs = {
  object: Biolink_Sections_Insert_Input;
  on_conflict?: Maybe<Biolink_Sections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_Of_AuthenticityArgs = {
  objects: Array<Certificate_Of_Authenticity_Insert_Input>;
  on_conflict?: Maybe<Certificate_Of_Authenticity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Certificate_Of_Authenticity_OneArgs = {
  object: Certificate_Of_Authenticity_Insert_Input;
  on_conflict?: Maybe<Certificate_Of_Authenticity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CollaborationArgs = {
  objects: Array<Collaboration_Insert_Input>;
  on_conflict?: Maybe<Collaboration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collaboration_Invitation_On_ArtworkArgs = {
  objects: Array<Collaboration_Invitation_On_Artwork_Insert_Input>;
  on_conflict?: Maybe<Collaboration_Invitation_On_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collaboration_Invitation_On_Artwork_OneArgs = {
  object: Collaboration_Invitation_On_Artwork_Insert_Input;
  on_conflict?: Maybe<Collaboration_Invitation_On_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collaboration_OneArgs = {
  object: Collaboration_Insert_Input;
  on_conflict?: Maybe<Collaboration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CollectionArgs = {
  objects: Array<Collection_Insert_Input>;
  on_conflict?: Maybe<Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collection_OneArgs = {
  object: Collection_Insert_Input;
  on_conflict?: Maybe<Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collection_TranslationArgs = {
  objects: Array<Collection_Translation_Insert_Input>;
  on_conflict?: Maybe<Collection_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collection_Translation_OneArgs = {
  object: Collection_Translation_Insert_Input;
  on_conflict?: Maybe<Collection_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collection_Video_LinksArgs = {
  objects: Array<Collection_Video_Links_Insert_Input>;
  on_conflict?: Maybe<Collection_Video_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collection_Video_Links_OneArgs = {
  object: Collection_Video_Links_Insert_Input;
  on_conflict?: Maybe<Collection_Video_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Collections_Links_FilteredArgs = {
  objects: Array<Collections_Links_Filtered_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Collections_Links_Filtered_OneArgs = {
  object: Collections_Links_Filtered_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Collector_Links_FilteredArgs = {
  objects: Array<Collector_Links_Filtered_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Collector_Links_Filtered_OneArgs = {
  object: Collector_Links_Filtered_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Connected_DomainArgs = {
  objects: Array<Connected_Domain_Insert_Input>;
  on_conflict?: Maybe<Connected_Domain_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Connected_Domain_OneArgs = {
  object: Connected_Domain_Insert_Input;
  on_conflict?: Maybe<Connected_Domain_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_TranslationsArgs = {
  objects: Array<Content_Translations_Insert_Input>;
  on_conflict?: Maybe<Content_Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Translations_OneArgs = {
  object: Content_Translations_Insert_Input;
  on_conflict?: Maybe<Content_Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContextArgs = {
  objects: Array<Context_Insert_Input>;
  on_conflict?: Maybe<Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_Delegate_AccessArgs = {
  objects: Array<Context_Delegate_Access_Insert_Input>;
  on_conflict?: Maybe<Context_Delegate_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_Delegate_Access_OneArgs = {
  object: Context_Delegate_Access_Insert_Input;
  on_conflict?: Maybe<Context_Delegate_Access_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_FlagsArgs = {
  objects: Array<Context_Flags_Insert_Input>;
  on_conflict?: Maybe<Context_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_Flags_OneArgs = {
  object: Context_Flags_Insert_Input;
  on_conflict?: Maybe<Context_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_OneArgs = {
  object: Context_Insert_Input;
  on_conflict?: Maybe<Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_Suggested_TagsArgs = {
  objects: Array<Context_Suggested_Tags_Insert_Input>;
  on_conflict?: Maybe<Context_Suggested_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_Suggested_Tags_OneArgs = {
  object: Context_Suggested_Tags_Insert_Input;
  on_conflict?: Maybe<Context_Suggested_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_To_MilestoneArgs = {
  objects: Array<Context_To_Milestone_Insert_Input>;
  on_conflict?: Maybe<Context_To_Milestone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_To_Milestone_OneArgs = {
  object: Context_To_Milestone_Insert_Input;
  on_conflict?: Maybe<Context_To_Milestone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_To_TutorialArgs = {
  objects: Array<Context_To_Tutorial_Insert_Input>;
  on_conflict?: Maybe<Context_To_Tutorial_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Context_To_Tutorial_OneArgs = {
  object: Context_To_Tutorial_Insert_Input;
  on_conflict?: Maybe<Context_To_Tutorial_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountriesArgs = {
  objects: Array<Countries_Insert_Input>;
  on_conflict?: Maybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Countries_OneArgs = {
  object: Countries_Insert_Input;
  on_conflict?: Maybe<Countries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Crop_AreaArgs = {
  objects: Array<Crop_Area_Insert_Input>;
  on_conflict?: Maybe<Crop_Area_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Crop_Area_OneArgs = {
  object: Crop_Area_Insert_Input;
  on_conflict?: Maybe<Crop_Area_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_ConversionArgs = {
  objects: Array<Currency_Conversion_Insert_Input>;
  on_conflict?: Maybe<Currency_Conversion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currency_Conversion_OneArgs = {
  object: Currency_Conversion_Insert_Input;
  on_conflict?: Maybe<Currency_Conversion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_Twin_DetailsArgs = {
  objects: Array<Digital_Twin_Details_Insert_Input>;
  on_conflict?: Maybe<Digital_Twin_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Digital_Twin_Details_OneArgs = {
  object: Digital_Twin_Details_Insert_Input;
  on_conflict?: Maybe<Digital_Twin_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discount_CodeArgs = {
  objects: Array<Discount_Code_Insert_Input>;
  on_conflict?: Maybe<Discount_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discount_Code_OneArgs = {
  object: Discount_Code_Insert_Input;
  on_conflict?: Maybe<Discount_Code_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discount_Code_UsageArgs = {
  objects: Array<Discount_Code_Usage_Insert_Input>;
  on_conflict?: Maybe<Discount_Code_Usage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Discount_Code_Usage_OneArgs = {
  object: Discount_Code_Usage_Insert_Input;
  on_conflict?: Maybe<Discount_Code_Usage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Domain_Registrant_InfoArgs = {
  objects: Array<Domain_Registrant_Info_Insert_Input>;
  on_conflict?: Maybe<Domain_Registrant_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Domain_Registrant_Info_OneArgs = {
  object: Domain_Registrant_Info_Insert_Input;
  on_conflict?: Maybe<Domain_Registrant_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Domain_RegistrationArgs = {
  objects: Array<Domain_Registration_Insert_Input>;
  on_conflict?: Maybe<Domain_Registration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Domain_Registration_OneArgs = {
  object: Domain_Registration_Insert_Input;
  on_conflict?: Maybe<Domain_Registration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dummy_Table_For_FrontArgs = {
  objects: Array<Dummy_Table_For_Front_Insert_Input>;
  on_conflict?: Maybe<Dummy_Table_For_Front_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dummy_Table_For_Front_OneArgs = {
  object: Dummy_Table_For_Front_Insert_Input;
  on_conflict?: Maybe<Dummy_Table_For_Front_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_CvArgs = {
  objects: Array<Dynamic_Cv_Insert_Input>;
  on_conflict?: Maybe<Dynamic_Cv_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_AwardArgs = {
  objects: Array<Dynamic_Cv_Award_Insert_Input>;
  on_conflict?: Maybe<Dynamic_Cv_Award_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_Award_OneArgs = {
  object: Dynamic_Cv_Award_Insert_Input;
  on_conflict?: Maybe<Dynamic_Cv_Award_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_CollectionArgs = {
  objects: Array<Dynamic_Cv_Collection_Insert_Input>;
  on_conflict?: Maybe<Dynamic_Cv_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_Collection_OneArgs = {
  object: Dynamic_Cv_Collection_Insert_Input;
  on_conflict?: Maybe<Dynamic_Cv_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_ContactArgs = {
  objects: Array<Dynamic_Cv_Contact_Insert_Input>;
  on_conflict?: Maybe<Dynamic_Cv_Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_Contact_OneArgs = {
  object: Dynamic_Cv_Contact_Insert_Input;
  on_conflict?: Maybe<Dynamic_Cv_Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_EducationArgs = {
  objects: Array<Dynamic_Cv_Education_Insert_Input>;
  on_conflict?: Maybe<Dynamic_Cv_Education_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_Education_OneArgs = {
  object: Dynamic_Cv_Education_Insert_Input;
  on_conflict?: Maybe<Dynamic_Cv_Education_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_ExhibitionArgs = {
  objects: Array<Dynamic_Cv_Exhibition_Insert_Input>;
  on_conflict?: Maybe<Dynamic_Cv_Exhibition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_Exhibition_OneArgs = {
  object: Dynamic_Cv_Exhibition_Insert_Input;
  on_conflict?: Maybe<Dynamic_Cv_Exhibition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_OneArgs = {
  object: Dynamic_Cv_Insert_Input;
  on_conflict?: Maybe<Dynamic_Cv_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_PublicationArgs = {
  objects: Array<Dynamic_Cv_Publication_Insert_Input>;
  on_conflict?: Maybe<Dynamic_Cv_Publication_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_Publication_OneArgs = {
  object: Dynamic_Cv_Publication_Insert_Input;
  on_conflict?: Maybe<Dynamic_Cv_Publication_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_SectionArgs = {
  objects: Array<Dynamic_Cv_Section_Insert_Input>;
  on_conflict?: Maybe<Dynamic_Cv_Section_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dynamic_Cv_Section_OneArgs = {
  object: Dynamic_Cv_Section_Insert_Input;
  on_conflict?: Maybe<Dynamic_Cv_Section_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Account_Notice_TypeArgs = {
  objects: Array<Enum_Account_Notice_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Account_Notice_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Account_Notice_Type_OneArgs = {
  object: Enum_Account_Notice_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Account_Notice_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Achievement_TagsArgs = {
  objects: Array<Enum_Achievement_Tags_Insert_Input>;
  on_conflict?: Maybe<Enum_Achievement_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Achievement_Tags_OneArgs = {
  object: Enum_Achievement_Tags_Insert_Input;
  on_conflict?: Maybe<Enum_Achievement_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Add_Artwork_Flow_StepArgs = {
  objects: Array<Enum_Add_Artwork_Flow_Step_Insert_Input>;
  on_conflict?: Maybe<Enum_Add_Artwork_Flow_Step_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Add_Artwork_Flow_Step_OneArgs = {
  object: Enum_Add_Artwork_Flow_Step_Insert_Input;
  on_conflict?: Maybe<Enum_Add_Artwork_Flow_Step_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Analytics_KaleidoCard_CategoryArgs = {
  objects: Array<Enum_Analytics_KaleidoCard_Category_Insert_Input>;
  on_conflict?: Maybe<Enum_Analytics_KaleidoCard_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Analytics_KaleidoCard_Category_OneArgs = {
  object: Enum_Analytics_KaleidoCard_Category_Insert_Input;
  on_conflict?: Maybe<Enum_Analytics_KaleidoCard_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Analytics_TypeArgs = {
  objects: Array<Enum_Analytics_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Analytics_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Analytics_Type_OneArgs = {
  object: Enum_Analytics_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Analytics_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_App_Notification_TypeArgs = {
  objects: Array<Enum_App_Notification_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_App_Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_App_Notification_Type_OneArgs = {
  object: Enum_App_Notification_Type_Insert_Input;
  on_conflict?: Maybe<Enum_App_Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Art_TypeArgs = {
  objects: Array<Enum_Art_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Art_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Art_Type_ExtensionArgs = {
  objects: Array<Enum_Art_Type_Extension_Insert_Input>;
  on_conflict?: Maybe<Enum_Art_Type_Extension_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Art_Type_Extension_OneArgs = {
  object: Enum_Art_Type_Extension_Insert_Input;
  on_conflict?: Maybe<Enum_Art_Type_Extension_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Art_Type_OneArgs = {
  object: Enum_Art_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Art_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artist_Collection_CategoriesArgs = {
  objects: Array<Enum_Artist_Collection_Categories_Insert_Input>;
  on_conflict?: Maybe<Enum_Artist_Collection_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artist_Collection_Categories_OneArgs = {
  object: Enum_Artist_Collection_Categories_Insert_Input;
  on_conflict?: Maybe<Enum_Artist_Collection_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artist_Collection_StateArgs = {
  objects: Array<Enum_Artist_Collection_State_Insert_Input>;
  on_conflict?: Maybe<Enum_Artist_Collection_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artist_Collection_State_OneArgs = {
  object: Enum_Artist_Collection_State_Insert_Input;
  on_conflict?: Maybe<Enum_Artist_Collection_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artist_Collection_TypeArgs = {
  objects: Array<Enum_Artist_Collection_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Artist_Collection_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artist_Collection_Type_OneArgs = {
  object: Enum_Artist_Collection_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Artist_Collection_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_CategoryArgs = {
  objects: Array<Enum_Artwork_Category_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Category_OneArgs = {
  object: Enum_Artwork_Category_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Count_Milestone_TypeArgs = {
  objects: Array<Enum_Artwork_Count_Milestone_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Count_Milestone_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Count_Milestone_Type_OneArgs = {
  object: Enum_Artwork_Count_Milestone_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Count_Milestone_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_DisciplineArgs = {
  objects: Array<Enum_Artwork_Discipline_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Discipline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Discipline_OneArgs = {
  object: Enum_Artwork_Discipline_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Discipline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Edition_TypeArgs = {
  objects: Array<Enum_Artwork_Edition_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Edition_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Edition_Type_OneArgs = {
  object: Enum_Artwork_Edition_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Edition_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Event_TypeArgs = {
  objects: Array<Enum_Artwork_Event_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Event_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Event_Type_OneArgs = {
  object: Enum_Artwork_Event_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Event_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_GenreArgs = {
  objects: Array<Enum_Artwork_Genre_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Genre_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Genre_OneArgs = {
  object: Enum_Artwork_Genre_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Genre_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Purchase_Journey_History_Entry_KindArgs = {
  objects: Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Purchase_Journey_History_Entry_Kind_OneArgs = {
  object: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Purchase_Journey_PartyArgs = {
  objects: Array<Enum_Artwork_Purchase_Journey_Party_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Purchase_Journey_Party_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Purchase_Journey_Party_OneArgs = {
  object: Enum_Artwork_Purchase_Journey_Party_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Purchase_Journey_Party_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Purchase_Journey_StatusArgs = {
  objects: Array<Enum_Artwork_Purchase_Journey_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Purchase_Journey_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Purchase_Journey_Status_OneArgs = {
  object: Enum_Artwork_Purchase_Journey_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Purchase_Journey_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Showroom_StatusArgs = {
  objects: Array<Enum_Artwork_Showroom_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Showroom_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Showroom_Status_OneArgs = {
  object: Enum_Artwork_Showroom_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Showroom_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_StatusArgs = {
  objects: Array<Enum_Artwork_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Status_OneArgs = {
  object: Enum_Artwork_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_TagArgs = {
  objects: Array<Enum_Artwork_Tag_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Tag_OneArgs = {
  object: Enum_Artwork_Tag_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Tag_TypeArgs = {
  objects: Array<Enum_Artwork_Tag_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Tag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Tag_Type_OneArgs = {
  object: Enum_Artwork_Tag_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Tag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_TypeArgs = {
  objects: Array<Enum_Artwork_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Artwork_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Artwork_Type_OneArgs = {
  object: Enum_Artwork_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Artwork_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Audio_Generation_StatusArgs = {
  objects: Array<Enum_Audio_Generation_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Audio_Generation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Audio_Generation_Status_OneArgs = {
  object: Enum_Audio_Generation_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Audio_Generation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Auth_TypeArgs = {
  objects: Array<Enum_Auth_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Auth_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Auth_Type_OneArgs = {
  object: Enum_Auth_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Auth_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Artwork_Payment_Intent_StatusArgs = {
  objects: Array<Enum_Billing_Artwork_Payment_Intent_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Artwork_Payment_Intent_Status_OneArgs = {
  object: Enum_Billing_Artwork_Payment_Intent_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Payment_Intent_Product_StatusArgs = {
  objects: Array<Enum_Billing_Payment_Intent_Product_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Billing_Payment_Intent_Product_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Payment_Intent_Product_Status_OneArgs = {
  object: Enum_Billing_Payment_Intent_Product_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Billing_Payment_Intent_Product_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Seller_StatusArgs = {
  objects: Array<Enum_Billing_Seller_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Billing_Seller_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Seller_Status_OneArgs = {
  object: Enum_Billing_Seller_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Billing_Seller_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Subscription_PeriodArgs = {
  objects: Array<Enum_Billing_Subscription_Period_Insert_Input>;
  on_conflict?: Maybe<Enum_Billing_Subscription_Period_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Subscription_Period_OneArgs = {
  object: Enum_Billing_Subscription_Period_Insert_Input;
  on_conflict?: Maybe<Enum_Billing_Subscription_Period_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Subscription_Product_TypeArgs = {
  objects: Array<Enum_Billing_Subscription_Product_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Billing_Subscription_Product_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Subscription_Product_Type_OneArgs = {
  object: Enum_Billing_Subscription_Product_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Billing_Subscription_Product_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Subscription_StatusArgs = {
  objects: Array<Enum_Billing_Subscription_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Billing_Subscription_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Billing_Subscription_Status_OneArgs = {
  object: Enum_Billing_Subscription_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Billing_Subscription_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_BlockchainArgs = {
  objects: Array<Enum_Blockchain_Insert_Input>;
  on_conflict?: Maybe<Enum_Blockchain_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Blockchain_OneArgs = {
  object: Enum_Blockchain_Insert_Input;
  on_conflict?: Maybe<Enum_Blockchain_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Collaboration_StatusArgs = {
  objects: Array<Enum_Collaboration_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Collaboration_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Collaboration_Status_OneArgs = {
  object: Enum_Collaboration_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Collaboration_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Collection_TypeArgs = {
  objects: Array<Enum_Collection_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Collection_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Collection_Type_OneArgs = {
  object: Enum_Collection_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Collection_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Connected_Domain_StateArgs = {
  objects: Array<Enum_Connected_Domain_State_Insert_Input>;
  on_conflict?: Maybe<Enum_Connected_Domain_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Connected_Domain_State_OneArgs = {
  object: Enum_Connected_Domain_State_Insert_Input;
  on_conflict?: Maybe<Enum_Connected_Domain_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Content_TypeArgs = {
  objects: Array<Enum_Content_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Content_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Content_Type_OneArgs = {
  object: Enum_Content_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Content_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Context_Delegate_RoleArgs = {
  objects: Array<Enum_Context_Delegate_Role_Insert_Input>;
  on_conflict?: Maybe<Enum_Context_Delegate_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Context_Delegate_Role_OneArgs = {
  object: Enum_Context_Delegate_Role_Insert_Input;
  on_conflict?: Maybe<Enum_Context_Delegate_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Context_Delegate_StateArgs = {
  objects: Array<Enum_Context_Delegate_State_Insert_Input>;
  on_conflict?: Maybe<Enum_Context_Delegate_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Context_Delegate_State_OneArgs = {
  object: Enum_Context_Delegate_State_Insert_Input;
  on_conflict?: Maybe<Enum_Context_Delegate_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Context_StateArgs = {
  objects: Array<Enum_Context_State_Insert_Input>;
  on_conflict?: Maybe<Enum_Context_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Context_State_OneArgs = {
  object: Enum_Context_State_Insert_Input;
  on_conflict?: Maybe<Enum_Context_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Context_TypeArgs = {
  objects: Array<Enum_Context_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Context_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Context_Type_OneArgs = {
  object: Enum_Context_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Context_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Discount_StateArgs = {
  objects: Array<Enum_Discount_State_Insert_Input>;
  on_conflict?: Maybe<Enum_Discount_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Discount_State_OneArgs = {
  object: Enum_Discount_State_Insert_Input;
  on_conflict?: Maybe<Enum_Discount_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Discount_TypeArgs = {
  objects: Array<Enum_Discount_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Discount_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Discount_Type_OneArgs = {
  object: Enum_Discount_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Discount_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Discount_Value_TypeArgs = {
  objects: Array<Enum_Discount_Value_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Discount_Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Discount_Value_Type_OneArgs = {
  object: Enum_Discount_Value_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Discount_Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Domain_Registration_StatusArgs = {
  objects: Array<Enum_Domain_Registration_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Domain_Registration_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Domain_Registration_Status_OneArgs = {
  object: Enum_Domain_Registration_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Domain_Registration_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Dynamic_Cv_Exhibition_TypeArgs = {
  objects: Array<Enum_Dynamic_Cv_Exhibition_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Dynamic_Cv_Exhibition_Type_OneArgs = {
  object: Enum_Dynamic_Cv_Exhibition_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Dynamic_Cv_Section_TypeArgs = {
  objects: Array<Enum_Dynamic_Cv_Section_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Dynamic_Cv_Section_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Dynamic_Cv_Section_Type_OneArgs = {
  object: Enum_Dynamic_Cv_Section_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Dynamic_Cv_Section_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Dynamic_Cv_StatusArgs = {
  objects: Array<Enum_Dynamic_Cv_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Dynamic_Cv_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Dynamic_Cv_Status_OneArgs = {
  object: Enum_Dynamic_Cv_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Dynamic_Cv_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Edition_StatusArgs = {
  objects: Array<Enum_Edition_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Edition_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Edition_Status_OneArgs = {
  object: Enum_Edition_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Edition_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Email_LanguageArgs = {
  objects: Array<Enum_Email_Language_Insert_Input>;
  on_conflict?: Maybe<Enum_Email_Language_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Email_Language_OneArgs = {
  object: Enum_Email_Language_Insert_Input;
  on_conflict?: Maybe<Enum_Email_Language_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Email_StatusArgs = {
  objects: Array<Enum_Email_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Email_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Email_Status_OneArgs = {
  object: Enum_Email_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Email_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Email_Validation_Token_StatusArgs = {
  objects: Array<Enum_Email_Validation_Token_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Email_Validation_Token_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Email_Validation_Token_Status_OneArgs = {
  object: Enum_Email_Validation_Token_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Email_Validation_Token_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_File_Metadata_StatusArgs = {
  objects: Array<Enum_File_Metadata_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_File_Metadata_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_File_Metadata_Status_OneArgs = {
  object: Enum_File_Metadata_Status_Insert_Input;
  on_conflict?: Maybe<Enum_File_Metadata_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Flags_TypeArgs = {
  objects: Array<Enum_Flags_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Flags_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Flags_Type_OneArgs = {
  object: Enum_Flags_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Flags_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Forge_Configuration_TypeArgs = {
  objects: Array<Enum_Forge_Configuration_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Forge_Configuration_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Forge_Configuration_Type_OneArgs = {
  object: Enum_Forge_Configuration_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Forge_Configuration_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Forge_StatusArgs = {
  objects: Array<Enum_Forge_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Forge_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Forge_Status_OneArgs = {
  object: Enum_Forge_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Forge_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Infraction_Flag_TypeArgs = {
  objects: Array<Enum_Infraction_Flag_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Infraction_Flag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Infraction_Flag_Type_OneArgs = {
  object: Enum_Infraction_Flag_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Infraction_Flag_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Interest_TypeArgs = {
  objects: Array<Enum_Interest_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Interest_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Interest_Type_OneArgs = {
  object: Enum_Interest_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Interest_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Language_PreferenceArgs = {
  objects: Array<Enum_Language_Preference_Insert_Input>;
  on_conflict?: Maybe<Enum_Language_Preference_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Language_Preference_OneArgs = {
  object: Enum_Language_Preference_Insert_Input;
  on_conflict?: Maybe<Enum_Language_Preference_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_Artwork_InfoArgs = {
  objects: Array<Enum_My_Website_Artwork_Info_Insert_Input>;
  on_conflict?: Maybe<Enum_My_Website_Artwork_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_Artwork_Info_OneArgs = {
  object: Enum_My_Website_Artwork_Info_Insert_Input;
  on_conflict?: Maybe<Enum_My_Website_Artwork_Info_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_FiltersArgs = {
  objects: Array<Enum_My_Website_Filters_Insert_Input>;
  on_conflict?: Maybe<Enum_My_Website_Filters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_Filters_OneArgs = {
  object: Enum_My_Website_Filters_Insert_Input;
  on_conflict?: Maybe<Enum_My_Website_Filters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_LayoutArgs = {
  objects: Array<Enum_My_Website_Layout_Insert_Input>;
  on_conflict?: Maybe<Enum_My_Website_Layout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_Layout_OneArgs = {
  object: Enum_My_Website_Layout_Insert_Input;
  on_conflict?: Maybe<Enum_My_Website_Layout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_NavigationArgs = {
  objects: Array<Enum_My_Website_Navigation_Insert_Input>;
  on_conflict?: Maybe<Enum_My_Website_Navigation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_Navigation_OneArgs = {
  object: Enum_My_Website_Navigation_Insert_Input;
  on_conflict?: Maybe<Enum_My_Website_Navigation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_ThemeArgs = {
  objects: Array<Enum_My_Website_Theme_Insert_Input>;
  on_conflict?: Maybe<Enum_My_Website_Theme_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_My_Website_Theme_OneArgs = {
  object: Enum_My_Website_Theme_Insert_Input;
  on_conflict?: Maybe<Enum_My_Website_Theme_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Notification_ChannelArgs = {
  objects: Array<Enum_Notification_Channel_Insert_Input>;
  on_conflict?: Maybe<Enum_Notification_Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Notification_Channel_OneArgs = {
  object: Enum_Notification_Channel_Insert_Input;
  on_conflict?: Maybe<Enum_Notification_Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Notification_GroupArgs = {
  objects: Array<Enum_Notification_Group_Insert_Input>;
  on_conflict?: Maybe<Enum_Notification_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Notification_Group_OneArgs = {
  object: Enum_Notification_Group_Insert_Input;
  on_conflict?: Maybe<Enum_Notification_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Position_TypeArgs = {
  objects: Array<Enum_Position_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Position_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Position_Type_OneArgs = {
  object: Enum_Position_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Position_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Price_ScopeArgs = {
  objects: Array<Enum_Price_Scope_Insert_Input>;
  on_conflict?: Maybe<Enum_Price_Scope_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Price_Scope_OneArgs = {
  object: Enum_Price_Scope_Insert_Input;
  on_conflict?: Maybe<Enum_Price_Scope_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Print_TechniqueArgs = {
  objects: Array<Enum_Print_Technique_Insert_Input>;
  on_conflict?: Maybe<Enum_Print_Technique_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Print_Technique_OneArgs = {
  object: Enum_Print_Technique_Insert_Input;
  on_conflict?: Maybe<Enum_Print_Technique_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Background_TypeArgs = {
  objects: Array<Enum_Profile_Background_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Profile_Background_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Background_Type_OneArgs = {
  object: Enum_Profile_Background_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Profile_Background_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Invitation_StatusArgs = {
  objects: Array<Enum_Profile_Invitation_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Profile_Invitation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Invitation_Status_OneArgs = {
  object: Enum_Profile_Invitation_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Profile_Invitation_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Public_StatusArgs = {
  objects: Array<Enum_Profile_Public_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Profile_Public_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Public_Status_OneArgs = {
  object: Enum_Profile_Public_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Profile_Public_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_RegionArgs = {
  objects: Array<Enum_Profile_Region_Insert_Input>;
  on_conflict?: Maybe<Enum_Profile_Region_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Region_OneArgs = {
  object: Enum_Profile_Region_Insert_Input;
  on_conflict?: Maybe<Enum_Profile_Region_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_StatusArgs = {
  objects: Array<Enum_Profile_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Profile_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Status_OneArgs = {
  object: Enum_Profile_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Profile_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_ThemeArgs = {
  objects: Array<Enum_Profile_Theme_Insert_Input>;
  on_conflict?: Maybe<Enum_Profile_Theme_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Theme_OneArgs = {
  object: Enum_Profile_Theme_Insert_Input;
  on_conflict?: Maybe<Enum_Profile_Theme_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_TypeArgs = {
  objects: Array<Enum_Profile_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Profile_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Profile_Type_OneArgs = {
  object: Enum_Profile_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Profile_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Pseudo_User_ActionArgs = {
  objects: Array<Enum_Pseudo_User_Action_Insert_Input>;
  on_conflict?: Maybe<Enum_Pseudo_User_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Pseudo_User_Action_OneArgs = {
  object: Enum_Pseudo_User_Action_Insert_Input;
  on_conflict?: Maybe<Enum_Pseudo_User_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Push_Notification_StatusArgs = {
  objects: Array<Enum_Push_Notification_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Push_Notification_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Push_Notification_Status_OneArgs = {
  object: Enum_Push_Notification_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Push_Notification_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Push_Notification_TypeArgs = {
  objects: Array<Enum_Push_Notification_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Push_Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Push_Notification_Type_OneArgs = {
  object: Enum_Push_Notification_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Push_Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Questionnaire_TypeArgs = {
  objects: Array<Enum_Questionnaire_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Questionnaire_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Questionnaire_Type_OneArgs = {
  object: Enum_Questionnaire_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Questionnaire_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Render_Request_StatusArgs = {
  objects: Array<Enum_Render_Request_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Render_Request_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Render_Request_Status_OneArgs = {
  object: Enum_Render_Request_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Render_Request_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Render_Request_Template_TypeArgs = {
  objects: Array<Enum_Render_Request_Template_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Render_Request_Template_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Render_Request_Template_Type_OneArgs = {
  object: Enum_Render_Request_Template_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Render_Request_Template_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Restricted_Handle_NamesArgs = {
  objects: Array<Enum_Restricted_Handle_Names_Insert_Input>;
  on_conflict?: Maybe<Enum_Restricted_Handle_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Restricted_Handle_Names_OneArgs = {
  object: Enum_Restricted_Handle_Names_Insert_Input;
  on_conflict?: Maybe<Enum_Restricted_Handle_Names_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_SizeArgs = {
  objects: Array<Enum_Size_Insert_Input>;
  on_conflict?: Maybe<Enum_Size_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Size_OneArgs = {
  object: Enum_Size_Insert_Input;
  on_conflict?: Maybe<Enum_Size_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Social_Graph_Followers_StateArgs = {
  objects: Array<Enum_Social_Graph_Followers_State_Insert_Input>;
  on_conflict?: Maybe<Enum_Social_Graph_Followers_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Social_Graph_Followers_State_OneArgs = {
  object: Enum_Social_Graph_Followers_State_Insert_Input;
  on_conflict?: Maybe<Enum_Social_Graph_Followers_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Social_Link_StatusArgs = {
  objects: Array<Enum_Social_Link_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Social_Link_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Social_Link_Status_OneArgs = {
  object: Enum_Social_Link_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Social_Link_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Social_Link_TypeArgs = {
  objects: Array<Enum_Social_Link_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Social_Link_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Social_Link_Type_OneArgs = {
  object: Enum_Social_Link_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Social_Link_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Timed_Event_NameArgs = {
  objects: Array<Enum_Timed_Event_Name_Insert_Input>;
  on_conflict?: Maybe<Enum_Timed_Event_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Timed_Event_Name_OneArgs = {
  object: Enum_Timed_Event_Name_Insert_Input;
  on_conflict?: Maybe<Enum_Timed_Event_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Token_Storage_StatusArgs = {
  objects: Array<Enum_Token_Storage_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Token_Storage_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Token_Storage_Status_OneArgs = {
  object: Enum_Token_Storage_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Token_Storage_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Transaction_OriginArgs = {
  objects: Array<Enum_Transaction_Origin_Insert_Input>;
  on_conflict?: Maybe<Enum_Transaction_Origin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Transaction_Origin_OneArgs = {
  object: Enum_Transaction_Origin_Insert_Input;
  on_conflict?: Maybe<Enum_Transaction_Origin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Transaction_StatusArgs = {
  objects: Array<Enum_Transaction_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Transaction_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Transaction_Status_OneArgs = {
  object: Enum_Transaction_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Transaction_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Tutorial_StatusArgs = {
  objects: Array<Enum_Tutorial_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Tutorial_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Tutorial_Status_OneArgs = {
  object: Enum_Tutorial_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Tutorial_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Tutorial_Step_TypeArgs = {
  objects: Array<Enum_Tutorial_Step_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Tutorial_Step_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Tutorial_Step_Type_OneArgs = {
  object: Enum_Tutorial_Step_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Tutorial_Step_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Tutorial_TypeArgs = {
  objects: Array<Enum_Tutorial_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Tutorial_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Tutorial_Type_OneArgs = {
  object: Enum_Tutorial_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Tutorial_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Vumark_StatusArgs = {
  objects: Array<Enum_Vumark_Status_Insert_Input>;
  on_conflict?: Maybe<Enum_Vumark_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Vumark_Status_OneArgs = {
  object: Enum_Vumark_Status_Insert_Input;
  on_conflict?: Maybe<Enum_Vumark_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Vumark_TypeArgs = {
  objects: Array<Enum_Vumark_Type_Insert_Input>;
  on_conflict?: Maybe<Enum_Vumark_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Vumark_Type_OneArgs = {
  object: Enum_Vumark_Type_Insert_Input;
  on_conflict?: Maybe<Enum_Vumark_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EditionArgs = {
  objects: Array<Edition_Insert_Input>;
  on_conflict?: Maybe<Edition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Edition_By_SizeArgs = {
  objects: Array<Edition_By_Size_Insert_Input>;
  on_conflict?: Maybe<Edition_By_Size_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Edition_By_Size_OneArgs = {
  object: Edition_By_Size_Insert_Input;
  on_conflict?: Maybe<Edition_By_Size_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Edition_By_TypeArgs = {
  objects: Array<Edition_By_Type_Insert_Input>;
  on_conflict?: Maybe<Edition_By_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Edition_By_Type_OneArgs = {
  object: Edition_By_Type_Insert_Input;
  on_conflict?: Maybe<Edition_By_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Edition_TypeArgs = {
  objects: Array<Edition_Type_Insert_Input>;
  on_conflict?: Maybe<Edition_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Edition_Type_OneArgs = {
  object: Edition_Type_Insert_Input;
  on_conflict?: Maybe<Edition_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Edition_EventsArgs = {
  objects: Array<Edition_Events_Insert_Input>;
  on_conflict?: Maybe<Edition_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Edition_Events_OneArgs = {
  object: Edition_Events_Insert_Input;
  on_conflict?: Maybe<Edition_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Edition_OneArgs = {
  object: Edition_Insert_Input;
  on_conflict?: Maybe<Edition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Validation_TokenArgs = {
  objects: Array<Email_Validation_Token_Insert_Input>;
  on_conflict?: Maybe<Email_Validation_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Validation_Token_OneArgs = {
  object: Email_Validation_Token_Insert_Input;
  on_conflict?: Maybe<Email_Validation_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailsArgs = {
  objects: Array<Emails_Insert_Input>;
  on_conflict?: Maybe<Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Emails_OneArgs = {
  object: Emails_Insert_Input;
  on_conflict?: Maybe<Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fcm_TokenArgs = {
  objects: Array<Fcm_Token_Insert_Input>;
  on_conflict?: Maybe<Fcm_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fcm_Token_OneArgs = {
  object: Fcm_Token_Insert_Input;
  on_conflict?: Maybe<Fcm_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FeaturesArgs = {
  objects: Array<Features_Insert_Input>;
  on_conflict?: Maybe<Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Features_OneArgs = {
  object: Features_Insert_Input;
  on_conflict?: Maybe<Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_MetadataArgs = {
  objects: Array<File_Metadata_Insert_Input>;
  on_conflict?: Maybe<File_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Metadata_OneArgs = {
  object: File_Metadata_Insert_Input;
  on_conflict?: Maybe<File_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Firebase_ProfileArgs = {
  objects: Array<Firebase_Profile_Insert_Input>;
  on_conflict?: Maybe<Firebase_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Firebase_Profile_OneArgs = {
  object: Firebase_Profile_Insert_Input;
  on_conflict?: Maybe<Firebase_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flagged_UsersArgs = {
  objects: Array<Flagged_Users_Insert_Input>;
  on_conflict?: Maybe<Flagged_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flagged_Users_OneArgs = {
  object: Flagged_Users_Insert_Input;
  on_conflict?: Maybe<Flagged_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Forge_InProcess_TaskArgs = {
  objects: Array<Forge_InProcess_Task_Insert_Input>;
  on_conflict?: Maybe<Forge_InProcess_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Forge_InProcess_Task_OneArgs = {
  object: Forge_InProcess_Task_Insert_Input;
  on_conflict?: Maybe<Forge_InProcess_Task_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Forge_TasksArgs = {
  objects: Array<Forge_Tasks_Insert_Input>;
  on_conflict?: Maybe<Forge_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Forge_Tasks_OneArgs = {
  object: Forge_Tasks_Insert_Input;
  on_conflict?: Maybe<Forge_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Global_PropertiesArgs = {
  objects: Array<Global_Properties_Insert_Input>;
  on_conflict?: Maybe<Global_Properties_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Global_Properties_OneArgs = {
  object: Global_Properties_Insert_Input;
  on_conflict?: Maybe<Global_Properties_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_ArtworkArgs = {
  objects: Array<Graveyard_Artwork_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_DetailsArgs = {
  objects: Array<Graveyard_Artwork_Details_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Details_OneArgs = {
  object: Graveyard_Artwork_Details_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Details_TranslationArgs = {
  objects: Array<Graveyard_Artwork_Details_Translation_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_Details_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Details_Translation_OneArgs = {
  object: Graveyard_Artwork_Details_Translation_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_Details_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_EventsArgs = {
  objects: Array<Graveyard_Artwork_Events_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Events_OneArgs = {
  object: Graveyard_Artwork_Events_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_OneArgs = {
  object: Graveyard_Artwork_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_PricesArgs = {
  objects: Array<Graveyard_Artwork_Prices_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Prices_OneArgs = {
  object: Graveyard_Artwork_Prices_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_Prices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Secondary_MediaArgs = {
  objects: Array<Graveyard_Artwork_Secondary_Media_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_Secondary_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Secondary_Media_OneArgs = {
  object: Graveyard_Artwork_Secondary_Media_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_Secondary_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_StoryArgs = {
  objects: Array<Graveyard_Artwork_Story_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_Story_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Story_MediaArgs = {
  objects: Array<Graveyard_Artwork_Story_Media_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_Story_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Story_Media_OneArgs = {
  object: Graveyard_Artwork_Story_Media_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_Story_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Story_OneArgs = {
  object: Graveyard_Artwork_Story_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_Story_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_To_CollectionArgs = {
  objects: Array<Graveyard_Artwork_To_Collection_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_To_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_To_Collection_OneArgs = {
  object: Graveyard_Artwork_To_Collection_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_To_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_TransactionsArgs = {
  objects: Array<Graveyard_Artwork_Transactions_Insert_Input>;
  on_conflict?: Maybe<Graveyard_Artwork_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Artwork_Transactions_OneArgs = {
  object: Graveyard_Artwork_Transactions_Insert_Input;
  on_conflict?: Maybe<Graveyard_Artwork_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Purchase_InquiresArgs = {
  objects: Array<Graveyard_Purchase_Inquires_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Purchase_Inquires_OneArgs = {
  object: Graveyard_Purchase_Inquires_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Purchase_Inquires_Outside_UsersArgs = {
  objects: Array<Graveyard_Purchase_Inquires_Outside_Users_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Graveyard_Purchase_Inquires_Outside_Users_OneArgs = {
  object: Graveyard_Purchase_Inquires_Outside_Users_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_InterestsArgs = {
  objects: Array<Interests_Insert_Input>;
  on_conflict?: Maybe<Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Interests_OneArgs = {
  object: Interests_Insert_Input;
  on_conflict?: Maybe<Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kaleido_General_SettingsArgs = {
  objects: Array<Kaleido_General_Settings_Insert_Input>;
  on_conflict?: Maybe<Kaleido_General_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Kaleido_General_Settings_OneArgs = {
  object: Kaleido_General_Settings_Insert_Input;
  on_conflict?: Maybe<Kaleido_General_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Landing_Page_ArtworkArgs = {
  objects: Array<Landing_Page_Artwork_Insert_Input>;
  on_conflict?: Maybe<Landing_Page_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Landing_Page_Artwork_OneArgs = {
  object: Landing_Page_Artwork_Insert_Input;
  on_conflict?: Maybe<Landing_Page_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LanguagesArgs = {
  objects: Array<Languages_Insert_Input>;
  on_conflict?: Maybe<Languages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Languages_OneArgs = {
  object: Languages_Insert_Input;
  on_conflict?: Maybe<Languages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Materialized_ViewArgs = {
  objects: Array<Materialized_View_Insert_Input>;
  on_conflict?: Maybe<Materialized_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Materialized_View_OneArgs = {
  object: Materialized_View_Insert_Input;
  on_conflict?: Maybe<Materialized_View_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Media_MetadataArgs = {
  objects: Array<Media_Metadata_Insert_Input>;
  on_conflict?: Maybe<Media_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Media_Metadata_OneArgs = {
  object: Media_Metadata_Insert_Input;
  on_conflict?: Maybe<Media_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MilestoneArgs = {
  objects: Array<Milestone_Insert_Input>;
  on_conflict?: Maybe<Milestone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Milestone_OneArgs = {
  object: Milestone_Insert_Input;
  on_conflict?: Maybe<Milestone_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_My_Website_SettingsArgs = {
  objects: Array<My_Website_Settings_Insert_Input>;
  on_conflict?: Maybe<My_Website_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_My_Website_Settings_OneArgs = {
  object: My_Website_Settings_Insert_Input;
  on_conflict?: Maybe<My_Website_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_My_Studio_SettingsArgs = {
  objects: Array<My_Studio_Settings_Insert_Input>;
  on_conflict?: Maybe<My_Studio_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_My_Studio_Settings_OneArgs = {
  object: My_Studio_Settings_Insert_Input;
  on_conflict?: Maybe<My_Studio_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_PreferenceArgs = {
  objects: Array<Notification_Preference_Insert_Input>;
  on_conflict?: Maybe<Notification_Preference_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Preference_OneArgs = {
  object: Notification_Preference_Insert_Input;
  on_conflict?: Maybe<Notification_Preference_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pending_Collaboration_On_ArtworkArgs = {
  objects: Array<Pending_Collaboration_On_Artwork_Insert_Input>;
  on_conflict?: Maybe<Pending_Collaboration_On_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pending_Collaboration_On_Artwork_OneArgs = {
  object: Pending_Collaboration_On_Artwork_Insert_Input;
  on_conflict?: Maybe<Pending_Collaboration_On_Artwork_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_RangesArgs = {
  objects: Array<Price_Ranges_Insert_Input>;
  on_conflict?: Maybe<Price_Ranges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_Ranges_OneArgs = {
  object: Price_Ranges_Insert_Input;
  on_conflict?: Maybe<Price_Ranges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PrintArgs = {
  objects: Array<Print_Insert_Input>;
  on_conflict?: Maybe<Print_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Print_OneArgs = {
  object: Print_Insert_Input;
  on_conflict?: Maybe<Print_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Print_To_CollectionArgs = {
  objects: Array<Print_To_Collection_Insert_Input>;
  on_conflict?: Maybe<Print_To_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Print_To_Collection_OneArgs = {
  object: Print_To_Collection_Insert_Input;
  on_conflict?: Maybe<Print_To_Collection_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prize_Art_ShowArgs = {
  objects: Array<Prize_Art_Show_Insert_Input>;
  on_conflict?: Maybe<Prize_Art_Show_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Prize_Art_Show_OneArgs = {
  object: Prize_Art_Show_Insert_Input;
  on_conflict?: Maybe<Prize_Art_Show_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfileArgs = {
  objects: Array<Profile_Insert_Input>;
  on_conflict?: Maybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_InterestsArgs = {
  objects: Array<Profile_Interests_Insert_Input>;
  on_conflict?: Maybe<Profile_Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Interests_OneArgs = {
  object: Profile_Interests_Insert_Input;
  on_conflict?: Maybe<Profile_Interests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_AchievementsArgs = {
  objects: Array<Profile_Achievements_Insert_Input>;
  on_conflict?: Maybe<Profile_Achievements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Achievements_OneArgs = {
  object: Profile_Achievements_Insert_Input;
  on_conflict?: Maybe<Profile_Achievements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Appearance_SettingsArgs = {
  objects: Array<Profile_Appearance_Settings_Insert_Input>;
  on_conflict?: Maybe<Profile_Appearance_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Appearance_Settings_OneArgs = {
  object: Profile_Appearance_Settings_Insert_Input;
  on_conflict?: Maybe<Profile_Appearance_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_BackgroundArgs = {
  objects: Array<Profile_Background_Insert_Input>;
  on_conflict?: Maybe<Profile_Background_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Background_OneArgs = {
  object: Profile_Background_Insert_Input;
  on_conflict?: Maybe<Profile_Background_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Ghost_TokensArgs = {
  objects: Array<Profile_Ghost_Tokens_Insert_Input>;
  on_conflict?: Maybe<Profile_Ghost_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Ghost_Tokens_OneArgs = {
  object: Profile_Ghost_Tokens_Insert_Input;
  on_conflict?: Maybe<Profile_Ghost_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_InvitationsArgs = {
  objects: Array<Profile_Invitations_Insert_Input>;
  on_conflict?: Maybe<Profile_Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Invitations_OneArgs = {
  object: Profile_Invitations_Insert_Input;
  on_conflict?: Maybe<Profile_Invitations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_LocationArgs = {
  objects: Array<Profile_Location_Insert_Input>;
  on_conflict?: Maybe<Profile_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Location_OneArgs = {
  object: Profile_Location_Insert_Input;
  on_conflict?: Maybe<Profile_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Location_TranslationArgs = {
  objects: Array<Profile_Location_Translation_Insert_Input>;
  on_conflict?: Maybe<Profile_Location_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Location_Translation_OneArgs = {
  object: Profile_Location_Translation_Insert_Input;
  on_conflict?: Maybe<Profile_Location_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_OneArgs = {
  object: Profile_Insert_Input;
  on_conflict?: Maybe<Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Price_RangesArgs = {
  objects: Array<Profile_Price_Ranges_Insert_Input>;
  on_conflict?: Maybe<Profile_Price_Ranges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Price_Ranges_OneArgs = {
  object: Profile_Price_Ranges_Insert_Input;
  on_conflict?: Maybe<Profile_Price_Ranges_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Questions_And_AnswersArgs = {
  objects: Array<Profile_Questions_And_Answers_Insert_Input>;
  on_conflict?: Maybe<Profile_Questions_And_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Questions_And_Answers_OneArgs = {
  object: Profile_Questions_And_Answers_Insert_Input;
  on_conflict?: Maybe<Profile_Questions_And_Answers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_SettingsArgs = {
  objects: Array<Profile_Settings_Insert_Input>;
  on_conflict?: Maybe<Profile_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Settings_OneArgs = {
  object: Profile_Settings_Insert_Input;
  on_conflict?: Maybe<Profile_Settings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Social_LinksArgs = {
  objects: Array<Profile_Social_Links_Insert_Input>;
  on_conflict?: Maybe<Profile_Social_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Social_Links_OneArgs = {
  object: Profile_Social_Links_Insert_Input;
  on_conflict?: Maybe<Profile_Social_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Statement_Audio_SynthesesArgs = {
  objects: Array<Profile_Statement_Audio_Syntheses_Insert_Input>;
  on_conflict?: Maybe<Profile_Statement_Audio_Syntheses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Statement_Audio_Syntheses_OneArgs = {
  object: Profile_Statement_Audio_Syntheses_Insert_Input;
  on_conflict?: Maybe<Profile_Statement_Audio_Syntheses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_TranslationArgs = {
  objects: Array<Profile_Translation_Insert_Input>;
  on_conflict?: Maybe<Profile_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Translation_OneArgs = {
  object: Profile_Translation_Insert_Input;
  on_conflict?: Maybe<Profile_Translation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Video_LinksArgs = {
  objects: Array<Profile_Video_Links_Insert_Input>;
  on_conflict?: Maybe<Profile_Video_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Video_Links_OneArgs = {
  object: Profile_Video_Links_Insert_Input;
  on_conflict?: Maybe<Profile_Video_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Wallet_AddressArgs = {
  objects: Array<Profile_Wallet_Address_Insert_Input>;
  on_conflict?: Maybe<Profile_Wallet_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Wallet_Address_OneArgs = {
  object: Profile_Wallet_Address_Insert_Input;
  on_conflict?: Maybe<Profile_Wallet_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pubnub_ChannelsArgs = {
  objects: Array<Pubnub_Channels_Insert_Input>;
  on_conflict?: Maybe<Pubnub_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pubnub_Channels_OneArgs = {
  object: Pubnub_Channels_Insert_Input;
  on_conflict?: Maybe<Pubnub_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchase_InquiresArgs = {
  objects: Array<Purchase_Inquires_Insert_Input>;
  on_conflict?: Maybe<Purchase_Inquires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchase_Inquires_OneArgs = {
  object: Purchase_Inquires_Insert_Input;
  on_conflict?: Maybe<Purchase_Inquires_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchase_Inquires_Outside_UsersArgs = {
  objects: Array<Purchase_Inquires_Outside_Users_Insert_Input>;
  on_conflict?: Maybe<Purchase_Inquires_Outside_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchase_Inquires_Outside_Users_OneArgs = {
  object: Purchase_Inquires_Outside_Users_Insert_Input;
  on_conflict?: Maybe<Purchase_Inquires_Outside_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Push_NotificationsArgs = {
  objects: Array<Push_Notifications_Insert_Input>;
  on_conflict?: Maybe<Push_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Push_Notifications_OneArgs = {
  object: Push_Notifications_Insert_Input;
  on_conflict?: Maybe<Push_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_QuestionsArgs = {
  objects: Array<Questionnaire_Questions_Insert_Input>;
  on_conflict?: Maybe<Questionnaire_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Questionnaire_Questions_OneArgs = {
  object: Questionnaire_Questions_Insert_Input;
  on_conflict?: Maybe<Questionnaire_Questions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Render_RequestArgs = {
  objects: Array<Render_Request_Insert_Input>;
  on_conflict?: Maybe<Render_Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Render_Request_OneArgs = {
  object: Render_Request_Insert_Input;
  on_conflict?: Maybe<Render_Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Setting_Context_Default_ProfileArgs = {
  objects: Array<Setting_Context_Default_Profile_Insert_Input>;
  on_conflict?: Maybe<Setting_Context_Default_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Setting_Context_Default_Profile_OneArgs = {
  object: Setting_Context_Default_Profile_Insert_Input;
  on_conflict?: Maybe<Setting_Context_Default_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_DetailsArgs = {
  objects: Array<Showroom_Details_Insert_Input>;
  on_conflict?: Maybe<Showroom_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Details_OneArgs = {
  object: Showroom_Details_Insert_Input;
  on_conflict?: Maybe<Showroom_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Social_Graph_FollowersArgs = {
  objects: Array<Social_Graph_Followers_Insert_Input>;
  on_conflict?: Maybe<Social_Graph_Followers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Social_Graph_Followers_OneArgs = {
  object: Social_Graph_Followers_Insert_Input;
  on_conflict?: Maybe<Social_Graph_Followers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spotlight_ContextArgs = {
  objects: Array<Spotlight_Context_Insert_Input>;
  on_conflict?: Maybe<Spotlight_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spotlight_Context_OneArgs = {
  object: Spotlight_Context_Insert_Input;
  on_conflict?: Maybe<Spotlight_Context_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Super_Site_SubscribersArgs = {
  objects: Array<Super_Site_Subscribers_Insert_Input>;
  on_conflict?: Maybe<Super_Site_Subscribers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Super_Site_Subscribers_OneArgs = {
  object: Super_Site_Subscribers_Insert_Input;
  on_conflict?: Maybe<Super_Site_Subscribers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_By_InterestArgs = {
  objects: Array<Tags_By_Interest_Insert_Input>;
  on_conflict?: Maybe<Tags_By_Interest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_By_Interest_OneArgs = {
  object: Tags_By_Interest_Insert_Input;
  on_conflict?: Maybe<Tags_By_Interest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_Analytics_EventsArgs = {
  objects: Array<Test_Analytics_Events_Insert_Input>;
  on_conflict?: Maybe<Test_Analytics_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_Analytics_Events_OneArgs = {
  object: Test_Analytics_Events_Insert_Input;
  on_conflict?: Maybe<Test_Analytics_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_AccountsArgs = {
  objects: Array<Test_Accounts_Insert_Input>;
  on_conflict?: Maybe<Test_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Test_Accounts_OneArgs = {
  object: Test_Accounts_Insert_Input;
  on_conflict?: Maybe<Test_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Timed_EventArgs = {
  objects: Array<Timed_Event_Insert_Input>;
  on_conflict?: Maybe<Timed_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Timed_Event_OneArgs = {
  object: Timed_Event_Insert_Input;
  on_conflict?: Maybe<Timed_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Token_StorageArgs = {
  objects: Array<Token_Storage_Insert_Input>;
  on_conflict?: Maybe<Token_Storage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Token_Storage_OneArgs = {
  object: Token_Storage_Insert_Input;
  on_conflict?: Maybe<Token_Storage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TutorialArgs = {
  objects: Array<Tutorial_Insert_Input>;
  on_conflict?: Maybe<Tutorial_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tutorial_OneArgs = {
  object: Tutorial_Insert_Input;
  on_conflict?: Maybe<Tutorial_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tutorial_StepArgs = {
  objects: Array<Tutorial_Step_Insert_Input>;
  on_conflict?: Maybe<Tutorial_Step_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tutorial_Step_OneArgs = {
  object: Tutorial_Step_Insert_Input;
  on_conflict?: Maybe<Tutorial_Step_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Analytics_Points_IndexArgs = {
  objects: Array<User_Analytics_Points_Index_Insert_Input>;
  on_conflict?: Maybe<User_Analytics_Points_Index_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Analytics_Points_Index_OneArgs = {
  object: User_Analytics_Points_Index_Insert_Input;
  on_conflict?: Maybe<User_Analytics_Points_Index_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vr_ArtistsArgs = {
  objects: Array<Vr_Artists_Insert_Input>;
  on_conflict?: Maybe<Vr_Artists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vr_Artists_OneArgs = {
  object: Vr_Artists_Insert_Input;
  on_conflict?: Maybe<Vr_Artists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vision_InterestArgs = {
  objects: Array<Vision_Interest_Insert_Input>;
  on_conflict?: Maybe<Vision_Interest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vision_Interest_OneArgs = {
  object: Vision_Interest_Insert_Input;
  on_conflict?: Maybe<Vision_Interest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VumarksArgs = {
  objects: Array<Vumarks_Insert_Input>;
  on_conflict?: Maybe<Vumarks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vumarks_OneArgs = {
  object: Vumarks_Insert_Input;
  on_conflict?: Maybe<Vumarks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Waiting_ListArgs = {
  objects: Array<Waiting_List_Insert_Input>;
  on_conflict?: Maybe<Waiting_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Waiting_List_OneArgs = {
  object: Waiting_List_Insert_Input;
  on_conflict?: Maybe<Waiting_List_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WalletsArgs = {
  objects: Array<Wallets_Insert_Input>;
  on_conflict?: Maybe<Wallets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wallets_OneArgs = {
  object: Wallets_Insert_Input;
  on_conflict?: Maybe<Wallets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_World_CitiesArgs = {
  objects: Array<World_Cities_Insert_Input>;
  on_conflict?: Maybe<World_Cities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_World_Cities_OneArgs = {
  object: World_Cities_Insert_Input;
  on_conflict?: Maybe<World_Cities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_Notifications_InfoArgs = {
  objects: Array<App_Notifications_Info_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_App_Notifications_Info_OneArgs = {
  object: App_Notifications_Info_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_App_Notifications_ListingArgs = {
  objects: Array<App_Notifications_Listing_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_App_Notifications_Listing_OneArgs = {
  object: App_Notifications_Listing_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_App_Notifications_ViewArgs = {
  objects: Array<App_Notifications_View_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_App_Notifications_View_OneArgs = {
  object: App_Notifications_View_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Profile_PhonesArgs = {
  objects: Array<Profile_Phones_Insert_Input>;
  on_conflict?: Maybe<Profile_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Phones_OneArgs = {
  object: Profile_Phones_Insert_Input;
  on_conflict?: Maybe<Profile_Phones_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Owner_PasswordArgs = {
  objects: Array<Showroom_Owner_Password_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Showroom_Owner_Password_OneArgs = {
  object: Showroom_Owner_Password_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_WorldcitiesArgs = {
  objects: Array<Worldcities_Insert_Input>;
  on_conflict?: Maybe<Worldcities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worldcities_OneArgs = {
  object: Worldcities_Insert_Input;
  on_conflict?: Maybe<Worldcities_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInstantGratificationArgs = {
  amount: Scalars['Int'];
  profileContextID: Scalars['Int'];
  updateNow?: Maybe<Scalars['Boolean']>;
  userID: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootMarkDomainRegistrationAsFailedArgs = {
  ctxId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootMarkProfilePriceRangesArgs = {
  context_id: Scalars['Int'];
  price_range_ids: Array<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootMarkProfileStatusArgs = {
  profile_id: Scalars['Int'];
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootMaterializePrintArgs = {
  edition_by_type_id?: Maybe<Scalars['bigint']>;
  print_number?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootNewArtistProfileArgs = {
  firstName?: Maybe<Scalars['String']>;
  handle: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootNewCollectorProfileArgs = {
  handle: Scalars['String'];
  is_public: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootNewGalleryProfileArgs = {
  handle: Scalars['String'];
  title: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootNotificationMutationArgs = {
  age: Scalars['Int'];
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootProfileAudioDeleteArgs = {
  profile_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootProfileAudioUploadArgs = {
  file: Scalars['Upload'];
  profile_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootProfileAvatarUploadArgs = {
  file: Scalars['Upload'];
  profile_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootProfileBannerUploadArgs = {
  file: Scalars['Upload'];
  profile_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootProfileCvDeleteArgs = {
  profile_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootProfileCvUploadArgs = {
  file: Scalars['Upload'];
  profile_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootProfileFeaturedArtDeleteArgs = {
  profile_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootProfileFeaturedArtUploadArgs = {
  file: Scalars['Upload'];
  profile_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootProfileKardBannerUploadArgs = {
  file: Scalars['Upload'];
  profile_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootPublishEditionArtworkArgs = {
  artworkId: Scalars['Int'];
  edition_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootPublishShowroomArgs = {
  context_id: Scalars['Int'];
  showroom_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootRemoveArtworkFromCartArgs = {
  artwork_id?: Maybe<Scalars['bigint']>;
  context_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootRemoveEditionByIdArgs = {
  edition_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootRemoveEditionBySizeArgs = {
  edition_by_size_id?: Maybe<Scalars['Int']>;
  edition_id?: Maybe<Scalars['Int']>;
  print_size?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootRemoveEditionByTypeArgs = {
  edition_by_size_id?: Maybe<Scalars['Int']>;
  edition_by_type_id?: Maybe<Scalars['Int']>;
  edition_type_id?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootRemovePrintByIdArgs = {
  print_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootRemoveSeriesForArtworkArgs = {
  artwork_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootReorderArtistsInCollectionArgs = {
  artistList: Array<CustomArtistCollectionToArtistContextInsertInput>;
  collectionId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootRequestAccountValidationArgs = {
  action?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  isCustomDomain?: Maybe<Scalars['Boolean']>;
  languagePreference: Scalars['String'];
  lastName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRequestEmailVerificationArgs = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  languagePreference?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootRequestFirebaseCustomAuthArgs = {
  action?: Maybe<Scalars['String']>;
  actionId?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  languagePreference?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootResendCollaborationInvitationArgs = {
  collaboration_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootResendInvitationArgs = {
  invitation_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootResendValidationCodeArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootResetContextTutorialArgs = {
  current_step?: Maybe<Scalars['String']>;
  tutorial_id?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootResetFlaggedUserInfractionsCounterMutationArgs = {
  flaggedUserId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootRevokeCollaborationArgs = {
  collaboration_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootRevokeInvitationArgs = {
  invitation_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootRevokeInvitationOnArtworkArgs = {
  artwork_id?: Maybe<Scalars['Int']>;
  invitation_id?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootRevokeOwnerArgs = {
  context_id: Scalars['Int'];
  pwd: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRunRuleArgs = {
  achievementName: Array<Maybe<Scalars['String']>>;
  updateNow?: Maybe<Scalars['Boolean']>;
  userID: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSendArtUniversePushArgs = {
  message: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendArtistBestPracticesEmailArgs = {
  user_context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSendArtistRequestMeetingEmailArgs = {
  user_context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSendDiscountCodeEmailArgs = {
  id?: Maybe<Scalars['bigint']>;
};


/** mutation root */
export type Mutation_RootSendMessageNotificationsArgs = {
  context_receiver_id: Scalars['Int'];
  context_sender_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSendProfilePublicationReminderArgs = {
  context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSendSimpleInvitationArgs = {
  contextId: Scalars['Int'];
  inviteeEmail: Scalars['String'];
  inviteeName?: Maybe<Scalars['String']>;
  inviteeType: Scalars['String'];
  language?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSetAnonFcmTokenArgs = {
  contextId: Scalars['bigint'];
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  platform: Scalars['String'];
  token: Scalars['String'];
  trashed_at?: Maybe<Scalars['timestamptz']>;
};


/** mutation root */
export type Mutation_RootSetCardGridArtworksArgs = {
  artwork_ids: Array<Maybe<Scalars['Int']>>;
  context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSetFavoriteArtworkArgs = {
  artwork_id: Scalars['Int'];
  context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSetFollowRelationArgs = {
  context_id: Scalars['Int'];
  state: Scalars['String'];
  target_context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSetFollowRelationOrderArgs = {
  context_id: Scalars['Int'];
  new_selected_order: Scalars['Int'];
  target_context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSetMyFcmTokenArgs = {
  deactivated_at?: Maybe<Scalars['timestamptz']>;
  platform: Scalars['String'];
  token: Scalars['String'];
  trashed_at?: Maybe<Scalars['timestamptz']>;
};


/** mutation root */
export type Mutation_RootSetSeriesForArtworkArgs = {
  artwork_id: Scalars['Int'];
  collection_id: Scalars['Int'];
  context_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSetShowroomArtworksArgs = {
  artwork_ids: Array<Scalars['Int']>;
  showroom_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSetSubscriptionToNotificationGroupArgs = {
  blocked: Scalars['Boolean'];
  notification_group: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSoftDeleteAccountArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootStartChatArgs = {
  receiverContextId: Scalars['Int'];
  senderContextId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootStepOneChangeEmailArgs = {
  new_email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootStepTwoChangeEmailArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootThirdPartySignInArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  preferred_language: Scalars['String'];
  provider_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTransferArtworkToCollectorArgs = {
  artwork_id: Scalars['Int'];
  edition_by_type_id?: Maybe<Scalars['Int']>;
  from_id: Scalars['Int'];
  print_id?: Maybe<Scalars['Int']>;
  print_number?: Maybe<Scalars['Int']>;
  to_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootTransferArtworkToEmailArgs = {
  artwork_id: Scalars['Int'];
  edition_by_type_id?: Maybe<Scalars['Int']>;
  first_name: Scalars['String'];
  from_id: Scalars['Int'];
  language: Scalars['String'];
  print_id?: Maybe<Scalars['Int']>;
  print_number?: Maybe<Scalars['Int']>;
  to_email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTrashMyFcmTokenArgs = {
  platform: Scalars['String'];
};


/** mutation root */
export type Mutation_RootTriggerAiIssueEmailArgs = {
  options: Array<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootTriggerWelcomeArtUniverseEmailArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUnsubscribeFromArtistNewsletterArgs = {
  artistContextId: Scalars['bigint'];
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateArtistProfileArgs = {
  handle: Scalars['String'];
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateCertificateOfAuthenticityArgs = {
  artPieceId: Scalars['bigint'];
  pdf?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootUpdateProfileStatusArgs = {
  profile_id: Scalars['Int'];
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdatePubNubChannelLastMessageArgs = {
  message: Scalars['String'];
  receiverContextId: Scalars['bigint'];
  senderContextId: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootUpdatePubNubChannelLastReadArgs = {
  channelId: Scalars['String'];
  lastReadTimetoken: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateSubscriptionPlanArgs = {
  artistContextId: Scalars['bigint'];
  discountCode?: Maybe<DiscountCodeInput>;
  period: SubscriptionPeriod;
  prorationDate: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUpdate_Account_Initial_Profile_TypeArgs = {
  _inc?: Maybe<Account_Initial_Profile_Type_Inc_Input>;
  _set?: Maybe<Account_Initial_Profile_Type_Set_Input>;
  where: Account_Initial_Profile_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Initial_Profile_Type_By_PkArgs = {
  _inc?: Maybe<Account_Initial_Profile_Type_Inc_Input>;
  _set?: Maybe<Account_Initial_Profile_Type_Set_Input>;
  pk_columns: Account_Initial_Profile_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Notice_AckArgs = {
  _inc?: Maybe<Account_Notice_Ack_Inc_Input>;
  _set?: Maybe<Account_Notice_Ack_Set_Input>;
  where: Account_Notice_Ack_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Notice_Ack_By_PkArgs = {
  _inc?: Maybe<Account_Notice_Ack_Inc_Input>;
  _set?: Maybe<Account_Notice_Ack_Set_Input>;
  pk_columns: Account_Notice_Ack_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_ValidationArgs = {
  _inc?: Maybe<Account_Validation_Inc_Input>;
  _set?: Maybe<Account_Validation_Set_Input>;
  where: Account_Validation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Validation_By_PkArgs = {
  _inc?: Maybe<Account_Validation_Inc_Input>;
  _set?: Maybe<Account_Validation_Set_Input>;
  pk_columns: Account_Validation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Achievement_HooksArgs = {
  _inc?: Maybe<Achievement_Hooks_Inc_Input>;
  _set?: Maybe<Achievement_Hooks_Set_Input>;
  where: Achievement_Hooks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Achievement_Hooks_By_PkArgs = {
  _inc?: Maybe<Achievement_Hooks_Inc_Input>;
  _set?: Maybe<Achievement_Hooks_Set_Input>;
  pk_columns: Achievement_Hooks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Achievement_TagsArgs = {
  _inc?: Maybe<Achievement_Tags_Inc_Input>;
  _set?: Maybe<Achievement_Tags_Set_Input>;
  where: Achievement_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Achievement_Tags_By_PkArgs = {
  _inc?: Maybe<Achievement_Tags_Inc_Input>;
  _set?: Maybe<Achievement_Tags_Set_Input>;
  pk_columns: Achievement_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AchievementsArgs = {
  _append?: Maybe<Achievements_Append_Input>;
  _delete_at_path?: Maybe<Achievements_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Achievements_Delete_Elem_Input>;
  _delete_key?: Maybe<Achievements_Delete_Key_Input>;
  _inc?: Maybe<Achievements_Inc_Input>;
  _prepend?: Maybe<Achievements_Prepend_Input>;
  _set?: Maybe<Achievements_Set_Input>;
  where: Achievements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Achievements_By_PkArgs = {
  _append?: Maybe<Achievements_Append_Input>;
  _delete_at_path?: Maybe<Achievements_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Achievements_Delete_Elem_Input>;
  _delete_key?: Maybe<Achievements_Delete_Key_Input>;
  _inc?: Maybe<Achievements_Inc_Input>;
  _prepend?: Maybe<Achievements_Prepend_Input>;
  _set?: Maybe<Achievements_Set_Input>;
  pk_columns: Achievements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_PasswordArgs = {
  _inc?: Maybe<Admin_Password_Inc_Input>;
  _set?: Maybe<Admin_Password_Set_Input>;
  where: Admin_Password_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_Password_By_PkArgs = {
  _inc?: Maybe<Admin_Password_Inc_Input>;
  _set?: Maybe<Admin_Password_Set_Input>;
  pk_columns: Admin_Password_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_To_User_NotificationArgs = {
  _inc?: Maybe<Admin_To_User_Notification_Inc_Input>;
  _set?: Maybe<Admin_To_User_Notification_Set_Input>;
  where: Admin_To_User_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Admin_To_User_Notification_By_PkArgs = {
  _inc?: Maybe<Admin_To_User_Notification_Inc_Input>;
  _set?: Maybe<Admin_To_User_Notification_Set_Input>;
  pk_columns: Admin_To_User_Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_All_Artwork_Availability_TableArgs = {
  _inc?: Maybe<All_Artwork_Availability_Table_Inc_Input>;
  _set?: Maybe<All_Artwork_Availability_Table_Set_Input>;
  where: All_Artwork_Availability_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_All_Artwork_Availability_Table_By_PkArgs = {
  _inc?: Maybe<All_Artwork_Availability_Table_Inc_Input>;
  _set?: Maybe<All_Artwork_Availability_Table_Set_Input>;
  pk_columns: All_Artwork_Availability_Table_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_EventsArgs = {
  _append?: Maybe<Analytics_Events_Append_Input>;
  _delete_at_path?: Maybe<Analytics_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Analytics_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Analytics_Events_Delete_Key_Input>;
  _inc?: Maybe<Analytics_Events_Inc_Input>;
  _prepend?: Maybe<Analytics_Events_Prepend_Input>;
  _set?: Maybe<Analytics_Events_Set_Input>;
  where: Analytics_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_Events_By_PkArgs = {
  _append?: Maybe<Analytics_Events_Append_Input>;
  _delete_at_path?: Maybe<Analytics_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Analytics_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Analytics_Events_Delete_Key_Input>;
  _inc?: Maybe<Analytics_Events_Inc_Input>;
  _prepend?: Maybe<Analytics_Events_Prepend_Input>;
  _set?: Maybe<Analytics_Events_Set_Input>;
  pk_columns: Analytics_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_Index_HistoryArgs = {
  _append?: Maybe<Analytics_Index_History_Append_Input>;
  _delete_at_path?: Maybe<Analytics_Index_History_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Analytics_Index_History_Delete_Elem_Input>;
  _delete_key?: Maybe<Analytics_Index_History_Delete_Key_Input>;
  _inc?: Maybe<Analytics_Index_History_Inc_Input>;
  _prepend?: Maybe<Analytics_Index_History_Prepend_Input>;
  _set?: Maybe<Analytics_Index_History_Set_Input>;
  where: Analytics_Index_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_Index_History_By_PkArgs = {
  _append?: Maybe<Analytics_Index_History_Append_Input>;
  _delete_at_path?: Maybe<Analytics_Index_History_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Analytics_Index_History_Delete_Elem_Input>;
  _delete_key?: Maybe<Analytics_Index_History_Delete_Key_Input>;
  _inc?: Maybe<Analytics_Index_History_Inc_Input>;
  _prepend?: Maybe<Analytics_Index_History_Prepend_Input>;
  _set?: Maybe<Analytics_Index_History_Set_Input>;
  pk_columns: Analytics_Index_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_Index_TypeArgs = {
  _inc?: Maybe<Analytics_Index_Type_Inc_Input>;
  _set?: Maybe<Analytics_Index_Type_Set_Input>;
  where: Analytics_Index_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_Index_Type_By_PkArgs = {
  _inc?: Maybe<Analytics_Index_Type_Inc_Input>;
  _set?: Maybe<Analytics_Index_Type_Set_Input>;
  pk_columns: Analytics_Index_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_Index_ValuesArgs = {
  _inc?: Maybe<Analytics_Index_Values_Inc_Input>;
  _set?: Maybe<Analytics_Index_Values_Set_Input>;
  where: Analytics_Index_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_Index_Values_By_PkArgs = {
  _inc?: Maybe<Analytics_Index_Values_Inc_Input>;
  _set?: Maybe<Analytics_Index_Values_Set_Input>;
  pk_columns: Analytics_Index_Values_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_KaleidoCard_AmountsArgs = {
  _inc?: Maybe<Analytics_KaleidoCard_Amounts_Inc_Input>;
  _set?: Maybe<Analytics_KaleidoCard_Amounts_Set_Input>;
  where: Analytics_KaleidoCard_Amounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_KaleidoCard_Amounts_By_PkArgs = {
  _inc?: Maybe<Analytics_KaleidoCard_Amounts_Inc_Input>;
  _set?: Maybe<Analytics_KaleidoCard_Amounts_Set_Input>;
  pk_columns: Analytics_KaleidoCard_Amounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_KaleidoCard_EventsArgs = {
  _inc?: Maybe<Analytics_KaleidoCard_Events_Inc_Input>;
  _set?: Maybe<Analytics_KaleidoCard_Events_Set_Input>;
  where: Analytics_KaleidoCard_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Analytics_KaleidoCard_Events_By_PkArgs = {
  _inc?: Maybe<Analytics_KaleidoCard_Events_Inc_Input>;
  _set?: Maybe<Analytics_KaleidoCard_Events_Set_Input>;
  pk_columns: Analytics_KaleidoCard_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_NotificationsArgs = {
  _inc?: Maybe<App_Notifications_Inc_Input>;
  _set?: Maybe<App_Notifications_Set_Input>;
  where: App_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Notifications_By_PkArgs = {
  _inc?: Maybe<App_Notifications_Inc_Input>;
  _set?: Maybe<App_Notifications_Set_Input>;
  pk_columns: App_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Art_History_MovementArgs = {
  _inc?: Maybe<Art_History_Movement_Inc_Input>;
  _set?: Maybe<Art_History_Movement_Set_Input>;
  where: Art_History_Movement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Art_History_Movement_By_PkArgs = {
  _inc?: Maybe<Art_History_Movement_Inc_Input>;
  _set?: Maybe<Art_History_Movement_Set_Input>;
  pk_columns: Art_History_Movement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Prize_ArtworkArgs = {
  _inc?: Maybe<Art_Prize_Artwork_Inc_Input>;
  _set?: Maybe<Art_Prize_Artwork_Set_Input>;
  where: Art_Prize_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Prize_Artwork_By_PkArgs = {
  _inc?: Maybe<Art_Prize_Artwork_Inc_Input>;
  _set?: Maybe<Art_Prize_Artwork_Set_Input>;
  pk_columns: Art_Prize_Artwork_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Prize_ShowArgs = {
  _inc?: Maybe<Art_Prize_Show_Inc_Input>;
  _set?: Maybe<Art_Prize_Show_Set_Input>;
  where: Art_Prize_Show_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Prize_Show_By_PkArgs = {
  _inc?: Maybe<Art_Prize_Show_Inc_Input>;
  _set?: Maybe<Art_Prize_Show_Set_Input>;
  pk_columns: Art_Prize_Show_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Prize_Show_WinnersArgs = {
  _inc?: Maybe<Art_Prize_Show_Winners_Inc_Input>;
  _set?: Maybe<Art_Prize_Show_Winners_Set_Input>;
  where: Art_Prize_Show_Winners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Prize_Show_Winners_By_PkArgs = {
  _inc?: Maybe<Art_Prize_Show_Winners_Inc_Input>;
  _set?: Maybe<Art_Prize_Show_Winners_Set_Input>;
  pk_columns: Art_Prize_Show_Winners_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Prize_WinnerArgs = {
  _inc?: Maybe<Art_Prize_Winner_Inc_Input>;
  _set?: Maybe<Art_Prize_Winner_Set_Input>;
  where: Art_Prize_Winner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Prize_Winner_By_PkArgs = {
  _inc?: Maybe<Art_Prize_Winner_Inc_Input>;
  _set?: Maybe<Art_Prize_Winner_Set_Input>;
  pk_columns: Art_Prize_Winner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Prizes_Links_FilteredArgs = {
  _inc?: Maybe<Art_Prizes_Links_Filtered_Inc_Input>;
  _set?: Maybe<Art_Prizes_Links_Filtered_Set_Input>;
  where: Art_Prizes_Links_Filtered_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Show_DetailsArgs = {
  _inc?: Maybe<Art_Show_Details_Inc_Input>;
  _set?: Maybe<Art_Show_Details_Set_Input>;
  where: Art_Show_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Show_Details_By_PkArgs = {
  _inc?: Maybe<Art_Show_Details_Inc_Input>;
  _set?: Maybe<Art_Show_Details_Set_Input>;
  pk_columns: Art_Show_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Type_To_Artwork_DisciplineArgs = {
  _inc?: Maybe<Art_Type_To_Artwork_Discipline_Inc_Input>;
  _set?: Maybe<Art_Type_To_Artwork_Discipline_Set_Input>;
  where: Art_Type_To_Artwork_Discipline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Art_Type_To_Artwork_Discipline_By_PkArgs = {
  _inc?: Maybe<Art_Type_To_Artwork_Discipline_Inc_Input>;
  _set?: Maybe<Art_Type_To_Artwork_Discipline_Set_Input>;
  pk_columns: Art_Type_To_Artwork_Discipline_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_CollectionArgs = {
  _append?: Maybe<Artist_Collection_Append_Input>;
  _delete_at_path?: Maybe<Artist_Collection_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artist_Collection_Delete_Elem_Input>;
  _delete_key?: Maybe<Artist_Collection_Delete_Key_Input>;
  _inc?: Maybe<Artist_Collection_Inc_Input>;
  _prepend?: Maybe<Artist_Collection_Prepend_Input>;
  _set?: Maybe<Artist_Collection_Set_Input>;
  where: Artist_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Collection_CategoriesArgs = {
  _inc?: Maybe<Artist_Collection_Categories_Inc_Input>;
  _set?: Maybe<Artist_Collection_Categories_Set_Input>;
  where: Artist_Collection_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Collection_Categories_By_PkArgs = {
  _inc?: Maybe<Artist_Collection_Categories_Inc_Input>;
  _set?: Maybe<Artist_Collection_Categories_Set_Input>;
  pk_columns: Artist_Collection_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Collection_By_PkArgs = {
  _append?: Maybe<Artist_Collection_Append_Input>;
  _delete_at_path?: Maybe<Artist_Collection_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artist_Collection_Delete_Elem_Input>;
  _delete_key?: Maybe<Artist_Collection_Delete_Key_Input>;
  _inc?: Maybe<Artist_Collection_Inc_Input>;
  _prepend?: Maybe<Artist_Collection_Prepend_Input>;
  _set?: Maybe<Artist_Collection_Set_Input>;
  pk_columns: Artist_Collection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Collection_To_Artist_ContextArgs = {
  _append?: Maybe<Artist_Collection_To_Artist_Context_Append_Input>;
  _delete_at_path?: Maybe<Artist_Collection_To_Artist_Context_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artist_Collection_To_Artist_Context_Delete_Elem_Input>;
  _delete_key?: Maybe<Artist_Collection_To_Artist_Context_Delete_Key_Input>;
  _inc?: Maybe<Artist_Collection_To_Artist_Context_Inc_Input>;
  _prepend?: Maybe<Artist_Collection_To_Artist_Context_Prepend_Input>;
  _set?: Maybe<Artist_Collection_To_Artist_Context_Set_Input>;
  where: Artist_Collection_To_Artist_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Collection_To_Artist_Context_By_PkArgs = {
  _append?: Maybe<Artist_Collection_To_Artist_Context_Append_Input>;
  _delete_at_path?: Maybe<Artist_Collection_To_Artist_Context_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artist_Collection_To_Artist_Context_Delete_Elem_Input>;
  _delete_key?: Maybe<Artist_Collection_To_Artist_Context_Delete_Key_Input>;
  _inc?: Maybe<Artist_Collection_To_Artist_Context_Inc_Input>;
  _prepend?: Maybe<Artist_Collection_To_Artist_Context_Prepend_Input>;
  _set?: Maybe<Artist_Collection_To_Artist_Context_Set_Input>;
  pk_columns: Artist_Collection_To_Artist_Context_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Art_History_VisibilityArgs = {
  _inc?: Maybe<Artist_Art_History_Visibility_Inc_Input>;
  _set?: Maybe<Artist_Art_History_Visibility_Set_Input>;
  where: Artist_Art_History_Visibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Art_History_Visibility_By_PkArgs = {
  _inc?: Maybe<Artist_Art_History_Visibility_Inc_Input>;
  _set?: Maybe<Artist_Art_History_Visibility_Set_Input>;
  pk_columns: Artist_Art_History_Visibility_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Curation_ScoreArgs = {
  _inc?: Maybe<Artist_Curation_Score_Inc_Input>;
  _set?: Maybe<Artist_Curation_Score_Set_Input>;
  where: Artist_Curation_Score_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Curation_Score_By_PkArgs = {
  _inc?: Maybe<Artist_Curation_Score_Inc_Input>;
  _set?: Maybe<Artist_Curation_Score_Set_Input>;
  pk_columns: Artist_Curation_Score_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Explore_VisibilityArgs = {
  _inc?: Maybe<Artist_Explore_Visibility_Inc_Input>;
  _set?: Maybe<Artist_Explore_Visibility_Set_Input>;
  where: Artist_Explore_Visibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Explore_Visibility_By_PkArgs = {
  _inc?: Maybe<Artist_Explore_Visibility_Inc_Input>;
  _set?: Maybe<Artist_Explore_Visibility_Set_Input>;
  pk_columns: Artist_Explore_Visibility_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Links_FilteredArgs = {
  _inc?: Maybe<Artist_Links_Filtered_Inc_Input>;
  _set?: Maybe<Artist_Links_Filtered_Set_Input>;
  where: Artist_Links_Filtered_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_NewsletterArgs = {
  _inc?: Maybe<Artist_Newsletter_Inc_Input>;
  _set?: Maybe<Artist_Newsletter_Set_Input>;
  where: Artist_Newsletter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Newsletter_By_PkArgs = {
  _inc?: Maybe<Artist_Newsletter_Inc_Input>;
  _set?: Maybe<Artist_Newsletter_Set_Input>;
  pk_columns: Artist_Newsletter_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Vision_VisibilityArgs = {
  _inc?: Maybe<Artist_Vision_Visibility_Inc_Input>;
  _set?: Maybe<Artist_Vision_Visibility_Set_Input>;
  where: Artist_Vision_Visibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artist_Vision_Visibility_By_PkArgs = {
  _inc?: Maybe<Artist_Vision_Visibility_Inc_Input>;
  _set?: Maybe<Artist_Vision_Visibility_Set_Input>;
  pk_columns: Artist_Vision_Visibility_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ArtworkArgs = {
  _inc?: Maybe<Artwork_Inc_Input>;
  _set?: Maybe<Artwork_Set_Input>;
  where: Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Ai_TagsArgs = {
  _append?: Maybe<Artwork_Ai_Tags_Append_Input>;
  _delete_at_path?: Maybe<Artwork_Ai_Tags_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artwork_Ai_Tags_Delete_Elem_Input>;
  _delete_key?: Maybe<Artwork_Ai_Tags_Delete_Key_Input>;
  _inc?: Maybe<Artwork_Ai_Tags_Inc_Input>;
  _prepend?: Maybe<Artwork_Ai_Tags_Prepend_Input>;
  _set?: Maybe<Artwork_Ai_Tags_Set_Input>;
  where: Artwork_Ai_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Ai_Tags_By_PkArgs = {
  _append?: Maybe<Artwork_Ai_Tags_Append_Input>;
  _delete_at_path?: Maybe<Artwork_Ai_Tags_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artwork_Ai_Tags_Delete_Elem_Input>;
  _delete_key?: Maybe<Artwork_Ai_Tags_Delete_Key_Input>;
  _inc?: Maybe<Artwork_Ai_Tags_Inc_Input>;
  _prepend?: Maybe<Artwork_Ai_Tags_Prepend_Input>;
  _set?: Maybe<Artwork_Ai_Tags_Set_Input>;
  pk_columns: Artwork_Ai_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Art_History_TimetableArgs = {
  _inc?: Maybe<Artwork_Art_History_Timetable_Inc_Input>;
  _set?: Maybe<Artwork_Art_History_Timetable_Set_Input>;
  where: Artwork_Art_History_Timetable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Art_History_Timetable_By_PkArgs = {
  _inc?: Maybe<Artwork_Art_History_Timetable_Inc_Input>;
  _set?: Maybe<Artwork_Art_History_Timetable_Set_Input>;
  pk_columns: Artwork_Art_History_Timetable_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Aspect_RatioArgs = {
  _inc?: Maybe<Artwork_Aspect_Ratio_Inc_Input>;
  _set?: Maybe<Artwork_Aspect_Ratio_Set_Input>;
  where: Artwork_Aspect_Ratio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Aspect_Ratio_By_PkArgs = {
  _inc?: Maybe<Artwork_Aspect_Ratio_Inc_Input>;
  _set?: Maybe<Artwork_Aspect_Ratio_Set_Input>;
  pk_columns: Artwork_Aspect_Ratio_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_By_PkArgs = {
  _inc?: Maybe<Artwork_Inc_Input>;
  _set?: Maybe<Artwork_Set_Input>;
  pk_columns: Artwork_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Count_MilestonesArgs = {
  _inc?: Maybe<Artwork_Count_Milestones_Inc_Input>;
  _set?: Maybe<Artwork_Count_Milestones_Set_Input>;
  where: Artwork_Count_Milestones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Count_Milestones_By_PkArgs = {
  _inc?: Maybe<Artwork_Count_Milestones_Inc_Input>;
  _set?: Maybe<Artwork_Count_Milestones_Set_Input>;
  pk_columns: Artwork_Count_Milestones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Curation_ScoreArgs = {
  _inc?: Maybe<Artwork_Curation_Score_Inc_Input>;
  _set?: Maybe<Artwork_Curation_Score_Set_Input>;
  where: Artwork_Curation_Score_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Curation_Score_By_PkArgs = {
  _inc?: Maybe<Artwork_Curation_Score_Inc_Input>;
  _set?: Maybe<Artwork_Curation_Score_Set_Input>;
  pk_columns: Artwork_Curation_Score_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_DetailsArgs = {
  _inc?: Maybe<Artwork_Details_Inc_Input>;
  _set?: Maybe<Artwork_Details_Set_Input>;
  where: Artwork_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Details_By_PkArgs = {
  _inc?: Maybe<Artwork_Details_Inc_Input>;
  _set?: Maybe<Artwork_Details_Set_Input>;
  pk_columns: Artwork_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Details_TranslationArgs = {
  _inc?: Maybe<Artwork_Details_Translation_Inc_Input>;
  _set?: Maybe<Artwork_Details_Translation_Set_Input>;
  where: Artwork_Details_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Details_Translation_By_PkArgs = {
  _inc?: Maybe<Artwork_Details_Translation_Inc_Input>;
  _set?: Maybe<Artwork_Details_Translation_Set_Input>;
  pk_columns: Artwork_Details_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Digital_TwinArgs = {
  _inc?: Maybe<Artwork_Digital_Twin_Inc_Input>;
  _set?: Maybe<Artwork_Digital_Twin_Set_Input>;
  where: Artwork_Digital_Twin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Digital_Twin_By_PkArgs = {
  _inc?: Maybe<Artwork_Digital_Twin_Inc_Input>;
  _set?: Maybe<Artwork_Digital_Twin_Set_Input>;
  pk_columns: Artwork_Digital_Twin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_EventsArgs = {
  _append?: Maybe<Artwork_Events_Append_Input>;
  _delete_at_path?: Maybe<Artwork_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artwork_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Artwork_Events_Delete_Key_Input>;
  _inc?: Maybe<Artwork_Events_Inc_Input>;
  _prepend?: Maybe<Artwork_Events_Prepend_Input>;
  _set?: Maybe<Artwork_Events_Set_Input>;
  where: Artwork_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Events_By_PkArgs = {
  _append?: Maybe<Artwork_Events_Append_Input>;
  _delete_at_path?: Maybe<Artwork_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Artwork_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Artwork_Events_Delete_Key_Input>;
  _inc?: Maybe<Artwork_Events_Inc_Input>;
  _prepend?: Maybe<Artwork_Events_Prepend_Input>;
  _set?: Maybe<Artwork_Events_Set_Input>;
  pk_columns: Artwork_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_External_Buy_LinkArgs = {
  _inc?: Maybe<Artwork_External_Buy_Link_Inc_Input>;
  _set?: Maybe<Artwork_External_Buy_Link_Set_Input>;
  where: Artwork_External_Buy_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_External_Buy_Link_By_PkArgs = {
  _inc?: Maybe<Artwork_External_Buy_Link_Inc_Input>;
  _set?: Maybe<Artwork_External_Buy_Link_Set_Input>;
  pk_columns: Artwork_External_Buy_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_PricesArgs = {
  _inc?: Maybe<Artwork_Prices_Inc_Input>;
  _set?: Maybe<Artwork_Prices_Set_Input>;
  where: Artwork_Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Prices_By_PkArgs = {
  _inc?: Maybe<Artwork_Prices_Inc_Input>;
  _set?: Maybe<Artwork_Prices_Set_Input>;
  pk_columns: Artwork_Prices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_JourneyArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_Set_Input>;
  where: Artwork_Purchase_Journey_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_Journey_By_PkArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_Set_Input>;
  pk_columns: Artwork_Purchase_Journey_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_Journey_HistoryArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_History_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_History_Set_Input>;
  where: Artwork_Purchase_Journey_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_Journey_History_By_PkArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_History_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_History_Set_Input>;
  pk_columns: Artwork_Purchase_Journey_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_Journey_Last_UpdateArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_Last_Update_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_Last_Update_Set_Input>;
  where: Artwork_Purchase_Journey_Last_Update_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_Journey_Last_Update_By_PkArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_Last_Update_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_Last_Update_Set_Input>;
  pk_columns: Artwork_Purchase_Journey_Last_Update_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_Journey_PricingArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_Pricing_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_Pricing_Set_Input>;
  where: Artwork_Purchase_Journey_Pricing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_Journey_Pricing_By_PkArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_Pricing_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_Pricing_Set_Input>;
  pk_columns: Artwork_Purchase_Journey_Pricing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_Journey_Shipping_InfoArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Set_Input>;
  where: Artwork_Purchase_Journey_Shipping_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Purchase_Journey_Shipping_Info_By_PkArgs = {
  _inc?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Inc_Input>;
  _set?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Set_Input>;
  pk_columns: Artwork_Purchase_Journey_Shipping_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Secondary_MediaArgs = {
  _inc?: Maybe<Artwork_Secondary_Media_Inc_Input>;
  _set?: Maybe<Artwork_Secondary_Media_Set_Input>;
  where: Artwork_Secondary_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Secondary_Media_By_PkArgs = {
  _inc?: Maybe<Artwork_Secondary_Media_Inc_Input>;
  _set?: Maybe<Artwork_Secondary_Media_Set_Input>;
  pk_columns: Artwork_Secondary_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_State_HistoryArgs = {
  _inc?: Maybe<Artwork_State_History_Inc_Input>;
  _set?: Maybe<Artwork_State_History_Set_Input>;
  where: Artwork_State_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_State_History_By_PkArgs = {
  _inc?: Maybe<Artwork_State_History_Inc_Input>;
  _set?: Maybe<Artwork_State_History_Set_Input>;
  pk_columns: Artwork_State_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_StoryArgs = {
  _inc?: Maybe<Artwork_Story_Inc_Input>;
  _set?: Maybe<Artwork_Story_Set_Input>;
  where: Artwork_Story_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Story_By_PkArgs = {
  _inc?: Maybe<Artwork_Story_Inc_Input>;
  _set?: Maybe<Artwork_Story_Set_Input>;
  pk_columns: Artwork_Story_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Story_MediaArgs = {
  _inc?: Maybe<Artwork_Story_Media_Inc_Input>;
  _set?: Maybe<Artwork_Story_Media_Set_Input>;
  where: Artwork_Story_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Story_Media_By_PkArgs = {
  _inc?: Maybe<Artwork_Story_Media_Inc_Input>;
  _set?: Maybe<Artwork_Story_Media_Set_Input>;
  pk_columns: Artwork_Story_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Story_Video_LinksArgs = {
  _inc?: Maybe<Artwork_Story_Video_Links_Inc_Input>;
  _set?: Maybe<Artwork_Story_Video_Links_Set_Input>;
  where: Artwork_Story_Video_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Story_Video_Links_By_PkArgs = {
  _inc?: Maybe<Artwork_Story_Video_Links_Inc_Input>;
  _set?: Maybe<Artwork_Story_Video_Links_Set_Input>;
  pk_columns: Artwork_Story_Video_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_TagArgs = {
  _inc?: Maybe<Artwork_Tag_Inc_Input>;
  _set?: Maybe<Artwork_Tag_Set_Input>;
  where: Artwork_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Tag_By_PkArgs = {
  _inc?: Maybe<Artwork_Tag_Inc_Input>;
  _set?: Maybe<Artwork_Tag_Set_Input>;
  pk_columns: Artwork_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_To_CollectionArgs = {
  _inc?: Maybe<Artwork_To_Collection_Inc_Input>;
  _set?: Maybe<Artwork_To_Collection_Set_Input>;
  where: Artwork_To_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_To_Collection_By_PkArgs = {
  _inc?: Maybe<Artwork_To_Collection_Inc_Input>;
  _set?: Maybe<Artwork_To_Collection_Set_Input>;
  pk_columns: Artwork_To_Collection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_To_Showroom_DetailsArgs = {
  _inc?: Maybe<Artwork_To_Showroom_Details_Inc_Input>;
  _set?: Maybe<Artwork_To_Showroom_Details_Set_Input>;
  where: Artwork_To_Showroom_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_To_Showroom_Details_By_PkArgs = {
  _inc?: Maybe<Artwork_To_Showroom_Details_Inc_Input>;
  _set?: Maybe<Artwork_To_Showroom_Details_Set_Input>;
  pk_columns: Artwork_To_Showroom_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_To_TagArgs = {
  _inc?: Maybe<Artwork_To_Tag_Inc_Input>;
  _set?: Maybe<Artwork_To_Tag_Set_Input>;
  where: Artwork_To_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_To_Tag_By_PkArgs = {
  _inc?: Maybe<Artwork_To_Tag_Inc_Input>;
  _set?: Maybe<Artwork_To_Tag_Set_Input>;
  pk_columns: Artwork_To_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_TransactionsArgs = {
  _inc?: Maybe<Artwork_Transactions_Inc_Input>;
  _set?: Maybe<Artwork_Transactions_Set_Input>;
  where: Artwork_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Artwork_Transactions_By_PkArgs = {
  _inc?: Maybe<Artwork_Transactions_Inc_Input>;
  _set?: Maybe<Artwork_Transactions_Set_Input>;
  pk_columns: Artwork_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AuthArgs = {
  _inc?: Maybe<Auth_Inc_Input>;
  _set?: Maybe<Auth_Set_Input>;
  where: Auth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_By_PkArgs = {
  _inc?: Maybe<Auth_Inc_Input>;
  _set?: Maybe<Auth_Set_Input>;
  pk_columns: Auth_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Context_AccessArgs = {
  _inc?: Maybe<Auth_Context_Access_Inc_Input>;
  _set?: Maybe<Auth_Context_Access_Set_Input>;
  where: Auth_Context_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Context_Access_By_PkArgs = {
  _inc?: Maybe<Auth_Context_Access_Inc_Input>;
  _set?: Maybe<Auth_Context_Access_Set_Input>;
  pk_columns: Auth_Context_Access_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Available_Print_PriceArgs = {
  _inc?: Maybe<Available_Print_Price_Inc_Input>;
  _set?: Maybe<Available_Print_Price_Set_Input>;
  where: Available_Print_Price_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Available_Print_Price_By_PkArgs = {
  _inc?: Maybe<Available_Print_Price_Inc_Input>;
  _set?: Maybe<Available_Print_Price_Set_Input>;
  pk_columns: Available_Print_Price_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Anon_ClientArgs = {
  _inc?: Maybe<Billing_Anon_Client_Inc_Input>;
  _set?: Maybe<Billing_Anon_Client_Set_Input>;
  where: Billing_Anon_Client_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Anon_Client_By_PkArgs = {
  _inc?: Maybe<Billing_Anon_Client_Inc_Input>;
  _set?: Maybe<Billing_Anon_Client_Set_Input>;
  pk_columns: Billing_Anon_Client_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Artwork_Payment_IntentArgs = {
  _inc?: Maybe<Billing_Artwork_Payment_Intent_Inc_Input>;
  _set?: Maybe<Billing_Artwork_Payment_Intent_Set_Input>;
  where: Billing_Artwork_Payment_Intent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Artwork_Payment_Intent_By_PkArgs = {
  _inc?: Maybe<Billing_Artwork_Payment_Intent_Inc_Input>;
  _set?: Maybe<Billing_Artwork_Payment_Intent_Set_Input>;
  pk_columns: Billing_Artwork_Payment_Intent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Artwork_Payment_Intent_ProductArgs = {
  _inc?: Maybe<Billing_Artwork_Payment_Intent_Product_Inc_Input>;
  _set?: Maybe<Billing_Artwork_Payment_Intent_Product_Set_Input>;
  where: Billing_Artwork_Payment_Intent_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Artwork_Payment_Intent_Product_By_PkArgs = {
  _inc?: Maybe<Billing_Artwork_Payment_Intent_Product_Inc_Input>;
  _set?: Maybe<Billing_Artwork_Payment_Intent_Product_Set_Input>;
  pk_columns: Billing_Artwork_Payment_Intent_Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Artwork_Payment_Intent_Shipping_AddressArgs = {
  _inc?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Inc_Input>;
  _set?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Set_Input>;
  where: Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Artwork_Payment_Intent_Shipping_Address_By_PkArgs = {
  _inc?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Inc_Input>;
  _set?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Set_Input>;
  pk_columns: Billing_Artwork_Payment_Intent_Shipping_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Artwork_Payment_Intent_Status_HistoryArgs = {
  _inc?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Inc_Input>;
  _set?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Set_Input>;
  where: Billing_Artwork_Payment_Intent_Status_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Artwork_Payment_Intent_Status_History_By_PkArgs = {
  _inc?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Inc_Input>;
  _set?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Set_Input>;
  pk_columns: Billing_Artwork_Payment_Intent_Status_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_ClientArgs = {
  _inc?: Maybe<Billing_Client_Inc_Input>;
  _set?: Maybe<Billing_Client_Set_Input>;
  where: Billing_Client_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Client_By_PkArgs = {
  _inc?: Maybe<Billing_Client_Inc_Input>;
  _set?: Maybe<Billing_Client_Set_Input>;
  pk_columns: Billing_Client_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_SellerArgs = {
  _inc?: Maybe<Billing_Seller_Inc_Input>;
  _set?: Maybe<Billing_Seller_Set_Input>;
  where: Billing_Seller_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Seller_By_PkArgs = {
  _inc?: Maybe<Billing_Seller_Inc_Input>;
  _set?: Maybe<Billing_Seller_Set_Input>;
  pk_columns: Billing_Seller_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_SubscriptionArgs = {
  _inc?: Maybe<Billing_Subscription_Inc_Input>;
  _set?: Maybe<Billing_Subscription_Set_Input>;
  where: Billing_Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Subscription_By_PkArgs = {
  _inc?: Maybe<Billing_Subscription_Inc_Input>;
  _set?: Maybe<Billing_Subscription_Set_Input>;
  pk_columns: Billing_Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Subscription_ProductArgs = {
  _inc?: Maybe<Billing_Subscription_Product_Inc_Input>;
  _set?: Maybe<Billing_Subscription_Product_Set_Input>;
  where: Billing_Subscription_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_Subscription_Product_By_PkArgs = {
  _inc?: Maybe<Billing_Subscription_Product_Inc_Input>;
  _set?: Maybe<Billing_Subscription_Product_Set_Input>;
  pk_columns: Billing_Subscription_Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Biolink_SectionsArgs = {
  _inc?: Maybe<Biolink_Sections_Inc_Input>;
  _set?: Maybe<Biolink_Sections_Set_Input>;
  where: Biolink_Sections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Biolink_Sections_By_PkArgs = {
  _inc?: Maybe<Biolink_Sections_Inc_Input>;
  _set?: Maybe<Biolink_Sections_Set_Input>;
  pk_columns: Biolink_Sections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_Of_AuthenticityArgs = {
  _inc?: Maybe<Certificate_Of_Authenticity_Inc_Input>;
  _set?: Maybe<Certificate_Of_Authenticity_Set_Input>;
  where: Certificate_Of_Authenticity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Certificate_Of_Authenticity_By_PkArgs = {
  _inc?: Maybe<Certificate_Of_Authenticity_Inc_Input>;
  _set?: Maybe<Certificate_Of_Authenticity_Set_Input>;
  pk_columns: Certificate_Of_Authenticity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CollaborationArgs = {
  _inc?: Maybe<Collaboration_Inc_Input>;
  _set?: Maybe<Collaboration_Set_Input>;
  where: Collaboration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Collaboration_By_PkArgs = {
  _inc?: Maybe<Collaboration_Inc_Input>;
  _set?: Maybe<Collaboration_Set_Input>;
  pk_columns: Collaboration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Collaboration_Invitation_On_ArtworkArgs = {
  _inc?: Maybe<Collaboration_Invitation_On_Artwork_Inc_Input>;
  _set?: Maybe<Collaboration_Invitation_On_Artwork_Set_Input>;
  where: Collaboration_Invitation_On_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Collaboration_Invitation_On_Artwork_By_PkArgs = {
  _inc?: Maybe<Collaboration_Invitation_On_Artwork_Inc_Input>;
  _set?: Maybe<Collaboration_Invitation_On_Artwork_Set_Input>;
  pk_columns: Collaboration_Invitation_On_Artwork_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CollectionArgs = {
  _inc?: Maybe<Collection_Inc_Input>;
  _set?: Maybe<Collection_Set_Input>;
  where: Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_By_PkArgs = {
  _inc?: Maybe<Collection_Inc_Input>;
  _set?: Maybe<Collection_Set_Input>;
  pk_columns: Collection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_TranslationArgs = {
  _inc?: Maybe<Collection_Translation_Inc_Input>;
  _set?: Maybe<Collection_Translation_Set_Input>;
  where: Collection_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_Translation_By_PkArgs = {
  _inc?: Maybe<Collection_Translation_Inc_Input>;
  _set?: Maybe<Collection_Translation_Set_Input>;
  pk_columns: Collection_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_Video_LinksArgs = {
  _inc?: Maybe<Collection_Video_Links_Inc_Input>;
  _set?: Maybe<Collection_Video_Links_Set_Input>;
  where: Collection_Video_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Collection_Video_Links_By_PkArgs = {
  _inc?: Maybe<Collection_Video_Links_Inc_Input>;
  _set?: Maybe<Collection_Video_Links_Set_Input>;
  pk_columns: Collection_Video_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Collections_Links_FilteredArgs = {
  _inc?: Maybe<Collections_Links_Filtered_Inc_Input>;
  _set?: Maybe<Collections_Links_Filtered_Set_Input>;
  where: Collections_Links_Filtered_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Collector_Links_FilteredArgs = {
  _inc?: Maybe<Collector_Links_Filtered_Inc_Input>;
  _set?: Maybe<Collector_Links_Filtered_Set_Input>;
  where: Collector_Links_Filtered_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Connected_DomainArgs = {
  _inc?: Maybe<Connected_Domain_Inc_Input>;
  _set?: Maybe<Connected_Domain_Set_Input>;
  where: Connected_Domain_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Connected_Domain_By_PkArgs = {
  _inc?: Maybe<Connected_Domain_Inc_Input>;
  _set?: Maybe<Connected_Domain_Set_Input>;
  pk_columns: Connected_Domain_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_TranslationsArgs = {
  _inc?: Maybe<Content_Translations_Inc_Input>;
  _set?: Maybe<Content_Translations_Set_Input>;
  where: Content_Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ContextArgs = {
  _inc?: Maybe<Context_Inc_Input>;
  _set?: Maybe<Context_Set_Input>;
  where: Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Context_By_PkArgs = {
  _inc?: Maybe<Context_Inc_Input>;
  _set?: Maybe<Context_Set_Input>;
  pk_columns: Context_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Delegate_AccessArgs = {
  _inc?: Maybe<Context_Delegate_Access_Inc_Input>;
  _set?: Maybe<Context_Delegate_Access_Set_Input>;
  where: Context_Delegate_Access_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Delegate_Access_By_PkArgs = {
  _inc?: Maybe<Context_Delegate_Access_Inc_Input>;
  _set?: Maybe<Context_Delegate_Access_Set_Input>;
  pk_columns: Context_Delegate_Access_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Context_FlagsArgs = {
  _inc?: Maybe<Context_Flags_Inc_Input>;
  _set?: Maybe<Context_Flags_Set_Input>;
  where: Context_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Flags_By_PkArgs = {
  _inc?: Maybe<Context_Flags_Inc_Input>;
  _set?: Maybe<Context_Flags_Set_Input>;
  pk_columns: Context_Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Suggested_TagsArgs = {
  _inc?: Maybe<Context_Suggested_Tags_Inc_Input>;
  _set?: Maybe<Context_Suggested_Tags_Set_Input>;
  where: Context_Suggested_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Context_Suggested_Tags_By_PkArgs = {
  _inc?: Maybe<Context_Suggested_Tags_Inc_Input>;
  _set?: Maybe<Context_Suggested_Tags_Set_Input>;
  pk_columns: Context_Suggested_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Context_To_MilestoneArgs = {
  _inc?: Maybe<Context_To_Milestone_Inc_Input>;
  _set?: Maybe<Context_To_Milestone_Set_Input>;
  where: Context_To_Milestone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Context_To_Milestone_By_PkArgs = {
  _inc?: Maybe<Context_To_Milestone_Inc_Input>;
  _set?: Maybe<Context_To_Milestone_Set_Input>;
  pk_columns: Context_To_Milestone_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Context_To_TutorialArgs = {
  _inc?: Maybe<Context_To_Tutorial_Inc_Input>;
  _set?: Maybe<Context_To_Tutorial_Set_Input>;
  where: Context_To_Tutorial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Context_To_Tutorial_By_PkArgs = {
  _inc?: Maybe<Context_To_Tutorial_Inc_Input>;
  _set?: Maybe<Context_To_Tutorial_Set_Input>;
  pk_columns: Context_To_Tutorial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CountriesArgs = {
  _set?: Maybe<Countries_Set_Input>;
  where: Countries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Countries_By_PkArgs = {
  _set?: Maybe<Countries_Set_Input>;
  pk_columns: Countries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Crop_AreaArgs = {
  _inc?: Maybe<Crop_Area_Inc_Input>;
  _set?: Maybe<Crop_Area_Set_Input>;
  where: Crop_Area_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Crop_Area_By_PkArgs = {
  _inc?: Maybe<Crop_Area_Inc_Input>;
  _set?: Maybe<Crop_Area_Set_Input>;
  pk_columns: Crop_Area_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_ConversionArgs = {
  _inc?: Maybe<Currency_Conversion_Inc_Input>;
  _set?: Maybe<Currency_Conversion_Set_Input>;
  where: Currency_Conversion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Currency_Conversion_By_PkArgs = {
  _inc?: Maybe<Currency_Conversion_Inc_Input>;
  _set?: Maybe<Currency_Conversion_Set_Input>;
  pk_columns: Currency_Conversion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Twin_DetailsArgs = {
  _inc?: Maybe<Digital_Twin_Details_Inc_Input>;
  _set?: Maybe<Digital_Twin_Details_Set_Input>;
  where: Digital_Twin_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Digital_Twin_Details_By_PkArgs = {
  _inc?: Maybe<Digital_Twin_Details_Inc_Input>;
  _set?: Maybe<Digital_Twin_Details_Set_Input>;
  pk_columns: Digital_Twin_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_CodeArgs = {
  _inc?: Maybe<Discount_Code_Inc_Input>;
  _set?: Maybe<Discount_Code_Set_Input>;
  where: Discount_Code_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_Code_By_PkArgs = {
  _inc?: Maybe<Discount_Code_Inc_Input>;
  _set?: Maybe<Discount_Code_Set_Input>;
  pk_columns: Discount_Code_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_Code_UsageArgs = {
  _inc?: Maybe<Discount_Code_Usage_Inc_Input>;
  _set?: Maybe<Discount_Code_Usage_Set_Input>;
  where: Discount_Code_Usage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Discount_Code_Usage_By_PkArgs = {
  _inc?: Maybe<Discount_Code_Usage_Inc_Input>;
  _set?: Maybe<Discount_Code_Usage_Set_Input>;
  pk_columns: Discount_Code_Usage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Domain_Registrant_InfoArgs = {
  _inc?: Maybe<Domain_Registrant_Info_Inc_Input>;
  _set?: Maybe<Domain_Registrant_Info_Set_Input>;
  where: Domain_Registrant_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Domain_Registrant_Info_By_PkArgs = {
  _inc?: Maybe<Domain_Registrant_Info_Inc_Input>;
  _set?: Maybe<Domain_Registrant_Info_Set_Input>;
  pk_columns: Domain_Registrant_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Domain_RegistrationArgs = {
  _inc?: Maybe<Domain_Registration_Inc_Input>;
  _set?: Maybe<Domain_Registration_Set_Input>;
  where: Domain_Registration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Domain_Registration_By_PkArgs = {
  _inc?: Maybe<Domain_Registration_Inc_Input>;
  _set?: Maybe<Domain_Registration_Set_Input>;
  pk_columns: Domain_Registration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dummy_Table_For_FrontArgs = {
  _append?: Maybe<Dummy_Table_For_Front_Append_Input>;
  _delete_at_path?: Maybe<Dummy_Table_For_Front_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Dummy_Table_For_Front_Delete_Elem_Input>;
  _delete_key?: Maybe<Dummy_Table_For_Front_Delete_Key_Input>;
  _inc?: Maybe<Dummy_Table_For_Front_Inc_Input>;
  _prepend?: Maybe<Dummy_Table_For_Front_Prepend_Input>;
  _set?: Maybe<Dummy_Table_For_Front_Set_Input>;
  where: Dummy_Table_For_Front_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dummy_Table_For_Front_By_PkArgs = {
  _append?: Maybe<Dummy_Table_For_Front_Append_Input>;
  _delete_at_path?: Maybe<Dummy_Table_For_Front_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Dummy_Table_For_Front_Delete_Elem_Input>;
  _delete_key?: Maybe<Dummy_Table_For_Front_Delete_Key_Input>;
  _inc?: Maybe<Dummy_Table_For_Front_Inc_Input>;
  _prepend?: Maybe<Dummy_Table_For_Front_Prepend_Input>;
  _set?: Maybe<Dummy_Table_For_Front_Set_Input>;
  pk_columns: Dummy_Table_For_Front_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_CvArgs = {
  _inc?: Maybe<Dynamic_Cv_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Set_Input>;
  where: Dynamic_Cv_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_AwardArgs = {
  _inc?: Maybe<Dynamic_Cv_Award_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Award_Set_Input>;
  where: Dynamic_Cv_Award_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_Award_By_PkArgs = {
  _inc?: Maybe<Dynamic_Cv_Award_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Award_Set_Input>;
  pk_columns: Dynamic_Cv_Award_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_By_PkArgs = {
  _inc?: Maybe<Dynamic_Cv_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Set_Input>;
  pk_columns: Dynamic_Cv_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_CollectionArgs = {
  _inc?: Maybe<Dynamic_Cv_Collection_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Collection_Set_Input>;
  where: Dynamic_Cv_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_Collection_By_PkArgs = {
  _inc?: Maybe<Dynamic_Cv_Collection_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Collection_Set_Input>;
  pk_columns: Dynamic_Cv_Collection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_ContactArgs = {
  _inc?: Maybe<Dynamic_Cv_Contact_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Contact_Set_Input>;
  where: Dynamic_Cv_Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_Contact_By_PkArgs = {
  _inc?: Maybe<Dynamic_Cv_Contact_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Contact_Set_Input>;
  pk_columns: Dynamic_Cv_Contact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_EducationArgs = {
  _inc?: Maybe<Dynamic_Cv_Education_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Education_Set_Input>;
  where: Dynamic_Cv_Education_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_Education_By_PkArgs = {
  _inc?: Maybe<Dynamic_Cv_Education_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Education_Set_Input>;
  pk_columns: Dynamic_Cv_Education_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_ExhibitionArgs = {
  _inc?: Maybe<Dynamic_Cv_Exhibition_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Exhibition_Set_Input>;
  where: Dynamic_Cv_Exhibition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_Exhibition_By_PkArgs = {
  _inc?: Maybe<Dynamic_Cv_Exhibition_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Exhibition_Set_Input>;
  pk_columns: Dynamic_Cv_Exhibition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_PublicationArgs = {
  _inc?: Maybe<Dynamic_Cv_Publication_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Publication_Set_Input>;
  where: Dynamic_Cv_Publication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_Publication_By_PkArgs = {
  _inc?: Maybe<Dynamic_Cv_Publication_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Publication_Set_Input>;
  pk_columns: Dynamic_Cv_Publication_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_SectionArgs = {
  _inc?: Maybe<Dynamic_Cv_Section_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Section_Set_Input>;
  where: Dynamic_Cv_Section_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dynamic_Cv_Section_By_PkArgs = {
  _inc?: Maybe<Dynamic_Cv_Section_Inc_Input>;
  _set?: Maybe<Dynamic_Cv_Section_Set_Input>;
  pk_columns: Dynamic_Cv_Section_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Account_Notice_TypeArgs = {
  _set?: Maybe<Enum_Account_Notice_Type_Set_Input>;
  where: Enum_Account_Notice_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Account_Notice_Type_By_PkArgs = {
  _set?: Maybe<Enum_Account_Notice_Type_Set_Input>;
  pk_columns: Enum_Account_Notice_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Achievement_TagsArgs = {
  _set?: Maybe<Enum_Achievement_Tags_Set_Input>;
  where: Enum_Achievement_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Achievement_Tags_By_PkArgs = {
  _set?: Maybe<Enum_Achievement_Tags_Set_Input>;
  pk_columns: Enum_Achievement_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Add_Artwork_Flow_StepArgs = {
  _set?: Maybe<Enum_Add_Artwork_Flow_Step_Set_Input>;
  where: Enum_Add_Artwork_Flow_Step_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Add_Artwork_Flow_Step_By_PkArgs = {
  _set?: Maybe<Enum_Add_Artwork_Flow_Step_Set_Input>;
  pk_columns: Enum_Add_Artwork_Flow_Step_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Analytics_KaleidoCard_CategoryArgs = {
  _set?: Maybe<Enum_Analytics_KaleidoCard_Category_Set_Input>;
  where: Enum_Analytics_KaleidoCard_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Analytics_KaleidoCard_Category_By_PkArgs = {
  _set?: Maybe<Enum_Analytics_KaleidoCard_Category_Set_Input>;
  pk_columns: Enum_Analytics_KaleidoCard_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Analytics_TypeArgs = {
  _set?: Maybe<Enum_Analytics_Type_Set_Input>;
  where: Enum_Analytics_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Analytics_Type_By_PkArgs = {
  _set?: Maybe<Enum_Analytics_Type_Set_Input>;
  pk_columns: Enum_Analytics_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_App_Notification_TypeArgs = {
  _set?: Maybe<Enum_App_Notification_Type_Set_Input>;
  where: Enum_App_Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_App_Notification_Type_By_PkArgs = {
  _set?: Maybe<Enum_App_Notification_Type_Set_Input>;
  pk_columns: Enum_App_Notification_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Art_TypeArgs = {
  _set?: Maybe<Enum_Art_Type_Set_Input>;
  where: Enum_Art_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Art_Type_By_PkArgs = {
  _set?: Maybe<Enum_Art_Type_Set_Input>;
  pk_columns: Enum_Art_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Art_Type_ExtensionArgs = {
  _inc?: Maybe<Enum_Art_Type_Extension_Inc_Input>;
  _set?: Maybe<Enum_Art_Type_Extension_Set_Input>;
  where: Enum_Art_Type_Extension_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Art_Type_Extension_By_PkArgs = {
  _inc?: Maybe<Enum_Art_Type_Extension_Inc_Input>;
  _set?: Maybe<Enum_Art_Type_Extension_Set_Input>;
  pk_columns: Enum_Art_Type_Extension_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artist_Collection_CategoriesArgs = {
  _set?: Maybe<Enum_Artist_Collection_Categories_Set_Input>;
  where: Enum_Artist_Collection_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artist_Collection_Categories_By_PkArgs = {
  _set?: Maybe<Enum_Artist_Collection_Categories_Set_Input>;
  pk_columns: Enum_Artist_Collection_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artist_Collection_StateArgs = {
  _set?: Maybe<Enum_Artist_Collection_State_Set_Input>;
  where: Enum_Artist_Collection_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artist_Collection_State_By_PkArgs = {
  _set?: Maybe<Enum_Artist_Collection_State_Set_Input>;
  pk_columns: Enum_Artist_Collection_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artist_Collection_TypeArgs = {
  _set?: Maybe<Enum_Artist_Collection_Type_Set_Input>;
  where: Enum_Artist_Collection_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artist_Collection_Type_By_PkArgs = {
  _set?: Maybe<Enum_Artist_Collection_Type_Set_Input>;
  pk_columns: Enum_Artist_Collection_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_CategoryArgs = {
  _set?: Maybe<Enum_Artwork_Category_Set_Input>;
  where: Enum_Artwork_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Category_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Category_Set_Input>;
  pk_columns: Enum_Artwork_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Count_Milestone_TypeArgs = {
  _set?: Maybe<Enum_Artwork_Count_Milestone_Type_Set_Input>;
  where: Enum_Artwork_Count_Milestone_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Count_Milestone_Type_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Count_Milestone_Type_Set_Input>;
  pk_columns: Enum_Artwork_Count_Milestone_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_DisciplineArgs = {
  _set?: Maybe<Enum_Artwork_Discipline_Set_Input>;
  where: Enum_Artwork_Discipline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Discipline_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Discipline_Set_Input>;
  pk_columns: Enum_Artwork_Discipline_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Edition_TypeArgs = {
  _set?: Maybe<Enum_Artwork_Edition_Type_Set_Input>;
  where: Enum_Artwork_Edition_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Edition_Type_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Edition_Type_Set_Input>;
  pk_columns: Enum_Artwork_Edition_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Event_TypeArgs = {
  _set?: Maybe<Enum_Artwork_Event_Type_Set_Input>;
  where: Enum_Artwork_Event_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Event_Type_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Event_Type_Set_Input>;
  pk_columns: Enum_Artwork_Event_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_GenreArgs = {
  _set?: Maybe<Enum_Artwork_Genre_Set_Input>;
  where: Enum_Artwork_Genre_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Genre_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Genre_Set_Input>;
  pk_columns: Enum_Artwork_Genre_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Purchase_Journey_History_Entry_KindArgs = {
  _set?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Set_Input>;
  where: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Purchase_Journey_History_Entry_Kind_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Set_Input>;
  pk_columns: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Purchase_Journey_PartyArgs = {
  _set?: Maybe<Enum_Artwork_Purchase_Journey_Party_Set_Input>;
  where: Enum_Artwork_Purchase_Journey_Party_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Purchase_Journey_Party_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Purchase_Journey_Party_Set_Input>;
  pk_columns: Enum_Artwork_Purchase_Journey_Party_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Purchase_Journey_StatusArgs = {
  _set?: Maybe<Enum_Artwork_Purchase_Journey_Status_Set_Input>;
  where: Enum_Artwork_Purchase_Journey_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Purchase_Journey_Status_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Purchase_Journey_Status_Set_Input>;
  pk_columns: Enum_Artwork_Purchase_Journey_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Showroom_StatusArgs = {
  _set?: Maybe<Enum_Artwork_Showroom_Status_Set_Input>;
  where: Enum_Artwork_Showroom_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Showroom_Status_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Showroom_Status_Set_Input>;
  pk_columns: Enum_Artwork_Showroom_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_StatusArgs = {
  _set?: Maybe<Enum_Artwork_Status_Set_Input>;
  where: Enum_Artwork_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Status_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Status_Set_Input>;
  pk_columns: Enum_Artwork_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_TagArgs = {
  _set?: Maybe<Enum_Artwork_Tag_Set_Input>;
  where: Enum_Artwork_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Tag_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Tag_Set_Input>;
  pk_columns: Enum_Artwork_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Tag_TypeArgs = {
  _set?: Maybe<Enum_Artwork_Tag_Type_Set_Input>;
  where: Enum_Artwork_Tag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Tag_Type_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Tag_Type_Set_Input>;
  pk_columns: Enum_Artwork_Tag_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_TypeArgs = {
  _set?: Maybe<Enum_Artwork_Type_Set_Input>;
  where: Enum_Artwork_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Artwork_Type_By_PkArgs = {
  _set?: Maybe<Enum_Artwork_Type_Set_Input>;
  pk_columns: Enum_Artwork_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Audio_Generation_StatusArgs = {
  _set?: Maybe<Enum_Audio_Generation_Status_Set_Input>;
  where: Enum_Audio_Generation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Audio_Generation_Status_By_PkArgs = {
  _set?: Maybe<Enum_Audio_Generation_Status_Set_Input>;
  pk_columns: Enum_Audio_Generation_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Auth_TypeArgs = {
  _set?: Maybe<Enum_Auth_Type_Set_Input>;
  where: Enum_Auth_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Auth_Type_By_PkArgs = {
  _set?: Maybe<Enum_Auth_Type_Set_Input>;
  pk_columns: Enum_Auth_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Artwork_Payment_Intent_StatusArgs = {
  _set?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Set_Input>;
  where: Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Artwork_Payment_Intent_Status_By_PkArgs = {
  _set?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Set_Input>;
  pk_columns: Enum_Billing_Artwork_Payment_Intent_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Payment_Intent_Product_StatusArgs = {
  _set?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Set_Input>;
  where: Enum_Billing_Payment_Intent_Product_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Payment_Intent_Product_Status_By_PkArgs = {
  _set?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Set_Input>;
  pk_columns: Enum_Billing_Payment_Intent_Product_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Seller_StatusArgs = {
  _set?: Maybe<Enum_Billing_Seller_Status_Set_Input>;
  where: Enum_Billing_Seller_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Seller_Status_By_PkArgs = {
  _set?: Maybe<Enum_Billing_Seller_Status_Set_Input>;
  pk_columns: Enum_Billing_Seller_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Subscription_PeriodArgs = {
  _set?: Maybe<Enum_Billing_Subscription_Period_Set_Input>;
  where: Enum_Billing_Subscription_Period_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Subscription_Period_By_PkArgs = {
  _set?: Maybe<Enum_Billing_Subscription_Period_Set_Input>;
  pk_columns: Enum_Billing_Subscription_Period_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Subscription_Product_TypeArgs = {
  _set?: Maybe<Enum_Billing_Subscription_Product_Type_Set_Input>;
  where: Enum_Billing_Subscription_Product_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Subscription_Product_Type_By_PkArgs = {
  _set?: Maybe<Enum_Billing_Subscription_Product_Type_Set_Input>;
  pk_columns: Enum_Billing_Subscription_Product_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Subscription_StatusArgs = {
  _set?: Maybe<Enum_Billing_Subscription_Status_Set_Input>;
  where: Enum_Billing_Subscription_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Billing_Subscription_Status_By_PkArgs = {
  _set?: Maybe<Enum_Billing_Subscription_Status_Set_Input>;
  pk_columns: Enum_Billing_Subscription_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_BlockchainArgs = {
  _set?: Maybe<Enum_Blockchain_Set_Input>;
  where: Enum_Blockchain_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Blockchain_By_PkArgs = {
  _set?: Maybe<Enum_Blockchain_Set_Input>;
  pk_columns: Enum_Blockchain_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Collaboration_StatusArgs = {
  _set?: Maybe<Enum_Collaboration_Status_Set_Input>;
  where: Enum_Collaboration_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Collaboration_Status_By_PkArgs = {
  _set?: Maybe<Enum_Collaboration_Status_Set_Input>;
  pk_columns: Enum_Collaboration_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Collection_TypeArgs = {
  _set?: Maybe<Enum_Collection_Type_Set_Input>;
  where: Enum_Collection_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Collection_Type_By_PkArgs = {
  _set?: Maybe<Enum_Collection_Type_Set_Input>;
  pk_columns: Enum_Collection_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Connected_Domain_StateArgs = {
  _set?: Maybe<Enum_Connected_Domain_State_Set_Input>;
  where: Enum_Connected_Domain_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Connected_Domain_State_By_PkArgs = {
  _set?: Maybe<Enum_Connected_Domain_State_Set_Input>;
  pk_columns: Enum_Connected_Domain_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Content_TypeArgs = {
  _set?: Maybe<Enum_Content_Type_Set_Input>;
  where: Enum_Content_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Content_Type_By_PkArgs = {
  _set?: Maybe<Enum_Content_Type_Set_Input>;
  pk_columns: Enum_Content_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Context_Delegate_RoleArgs = {
  _set?: Maybe<Enum_Context_Delegate_Role_Set_Input>;
  where: Enum_Context_Delegate_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Context_Delegate_Role_By_PkArgs = {
  _set?: Maybe<Enum_Context_Delegate_Role_Set_Input>;
  pk_columns: Enum_Context_Delegate_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Context_Delegate_StateArgs = {
  _set?: Maybe<Enum_Context_Delegate_State_Set_Input>;
  where: Enum_Context_Delegate_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Context_Delegate_State_By_PkArgs = {
  _set?: Maybe<Enum_Context_Delegate_State_Set_Input>;
  pk_columns: Enum_Context_Delegate_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Context_StateArgs = {
  _set?: Maybe<Enum_Context_State_Set_Input>;
  where: Enum_Context_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Context_State_By_PkArgs = {
  _set?: Maybe<Enum_Context_State_Set_Input>;
  pk_columns: Enum_Context_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Context_TypeArgs = {
  _set?: Maybe<Enum_Context_Type_Set_Input>;
  where: Enum_Context_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Context_Type_By_PkArgs = {
  _set?: Maybe<Enum_Context_Type_Set_Input>;
  pk_columns: Enum_Context_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Discount_StateArgs = {
  _set?: Maybe<Enum_Discount_State_Set_Input>;
  where: Enum_Discount_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Discount_State_By_PkArgs = {
  _set?: Maybe<Enum_Discount_State_Set_Input>;
  pk_columns: Enum_Discount_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Discount_TypeArgs = {
  _set?: Maybe<Enum_Discount_Type_Set_Input>;
  where: Enum_Discount_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Discount_Type_By_PkArgs = {
  _set?: Maybe<Enum_Discount_Type_Set_Input>;
  pk_columns: Enum_Discount_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Discount_Value_TypeArgs = {
  _set?: Maybe<Enum_Discount_Value_Type_Set_Input>;
  where: Enum_Discount_Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Discount_Value_Type_By_PkArgs = {
  _set?: Maybe<Enum_Discount_Value_Type_Set_Input>;
  pk_columns: Enum_Discount_Value_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Domain_Registration_StatusArgs = {
  _set?: Maybe<Enum_Domain_Registration_Status_Set_Input>;
  where: Enum_Domain_Registration_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Domain_Registration_Status_By_PkArgs = {
  _set?: Maybe<Enum_Domain_Registration_Status_Set_Input>;
  pk_columns: Enum_Domain_Registration_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Dynamic_Cv_Exhibition_TypeArgs = {
  _set?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Set_Input>;
  where: Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Dynamic_Cv_Exhibition_Type_By_PkArgs = {
  _set?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Set_Input>;
  pk_columns: Enum_Dynamic_Cv_Exhibition_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Dynamic_Cv_Section_TypeArgs = {
  _set?: Maybe<Enum_Dynamic_Cv_Section_Type_Set_Input>;
  where: Enum_Dynamic_Cv_Section_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Dynamic_Cv_Section_Type_By_PkArgs = {
  _set?: Maybe<Enum_Dynamic_Cv_Section_Type_Set_Input>;
  pk_columns: Enum_Dynamic_Cv_Section_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Dynamic_Cv_StatusArgs = {
  _set?: Maybe<Enum_Dynamic_Cv_Status_Set_Input>;
  where: Enum_Dynamic_Cv_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Dynamic_Cv_Status_By_PkArgs = {
  _set?: Maybe<Enum_Dynamic_Cv_Status_Set_Input>;
  pk_columns: Enum_Dynamic_Cv_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Edition_StatusArgs = {
  _set?: Maybe<Enum_Edition_Status_Set_Input>;
  where: Enum_Edition_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Edition_Status_By_PkArgs = {
  _set?: Maybe<Enum_Edition_Status_Set_Input>;
  pk_columns: Enum_Edition_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Email_LanguageArgs = {
  _set?: Maybe<Enum_Email_Language_Set_Input>;
  where: Enum_Email_Language_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Email_Language_By_PkArgs = {
  _set?: Maybe<Enum_Email_Language_Set_Input>;
  pk_columns: Enum_Email_Language_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Email_StatusArgs = {
  _set?: Maybe<Enum_Email_Status_Set_Input>;
  where: Enum_Email_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Email_Status_By_PkArgs = {
  _set?: Maybe<Enum_Email_Status_Set_Input>;
  pk_columns: Enum_Email_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Email_Validation_Token_StatusArgs = {
  _set?: Maybe<Enum_Email_Validation_Token_Status_Set_Input>;
  where: Enum_Email_Validation_Token_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Email_Validation_Token_Status_By_PkArgs = {
  _set?: Maybe<Enum_Email_Validation_Token_Status_Set_Input>;
  pk_columns: Enum_Email_Validation_Token_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_File_Metadata_StatusArgs = {
  _set?: Maybe<Enum_File_Metadata_Status_Set_Input>;
  where: Enum_File_Metadata_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_File_Metadata_Status_By_PkArgs = {
  _set?: Maybe<Enum_File_Metadata_Status_Set_Input>;
  pk_columns: Enum_File_Metadata_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Flags_TypeArgs = {
  _set?: Maybe<Enum_Flags_Type_Set_Input>;
  where: Enum_Flags_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Flags_Type_By_PkArgs = {
  _set?: Maybe<Enum_Flags_Type_Set_Input>;
  pk_columns: Enum_Flags_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Forge_Configuration_TypeArgs = {
  _set?: Maybe<Enum_Forge_Configuration_Type_Set_Input>;
  where: Enum_Forge_Configuration_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Forge_Configuration_Type_By_PkArgs = {
  _set?: Maybe<Enum_Forge_Configuration_Type_Set_Input>;
  pk_columns: Enum_Forge_Configuration_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Forge_StatusArgs = {
  _set?: Maybe<Enum_Forge_Status_Set_Input>;
  where: Enum_Forge_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Forge_Status_By_PkArgs = {
  _set?: Maybe<Enum_Forge_Status_Set_Input>;
  pk_columns: Enum_Forge_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Infraction_Flag_TypeArgs = {
  _set?: Maybe<Enum_Infraction_Flag_Type_Set_Input>;
  where: Enum_Infraction_Flag_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Infraction_Flag_Type_By_PkArgs = {
  _set?: Maybe<Enum_Infraction_Flag_Type_Set_Input>;
  pk_columns: Enum_Infraction_Flag_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Interest_TypeArgs = {
  _set?: Maybe<Enum_Interest_Type_Set_Input>;
  where: Enum_Interest_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Interest_Type_By_PkArgs = {
  _set?: Maybe<Enum_Interest_Type_Set_Input>;
  pk_columns: Enum_Interest_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Language_PreferenceArgs = {
  _set?: Maybe<Enum_Language_Preference_Set_Input>;
  where: Enum_Language_Preference_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Language_Preference_By_PkArgs = {
  _set?: Maybe<Enum_Language_Preference_Set_Input>;
  pk_columns: Enum_Language_Preference_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_Artwork_InfoArgs = {
  _set?: Maybe<Enum_My_Website_Artwork_Info_Set_Input>;
  where: Enum_My_Website_Artwork_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_Artwork_Info_By_PkArgs = {
  _set?: Maybe<Enum_My_Website_Artwork_Info_Set_Input>;
  pk_columns: Enum_My_Website_Artwork_Info_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_FiltersArgs = {
  _set?: Maybe<Enum_My_Website_Filters_Set_Input>;
  where: Enum_My_Website_Filters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_Filters_By_PkArgs = {
  _set?: Maybe<Enum_My_Website_Filters_Set_Input>;
  pk_columns: Enum_My_Website_Filters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_LayoutArgs = {
  _set?: Maybe<Enum_My_Website_Layout_Set_Input>;
  where: Enum_My_Website_Layout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_Layout_By_PkArgs = {
  _set?: Maybe<Enum_My_Website_Layout_Set_Input>;
  pk_columns: Enum_My_Website_Layout_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_NavigationArgs = {
  _set?: Maybe<Enum_My_Website_Navigation_Set_Input>;
  where: Enum_My_Website_Navigation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_Navigation_By_PkArgs = {
  _set?: Maybe<Enum_My_Website_Navigation_Set_Input>;
  pk_columns: Enum_My_Website_Navigation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_ThemeArgs = {
  _set?: Maybe<Enum_My_Website_Theme_Set_Input>;
  where: Enum_My_Website_Theme_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_My_Website_Theme_By_PkArgs = {
  _set?: Maybe<Enum_My_Website_Theme_Set_Input>;
  pk_columns: Enum_My_Website_Theme_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_ChannelArgs = {
  _set?: Maybe<Enum_Notification_Channel_Set_Input>;
  where: Enum_Notification_Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_Channel_By_PkArgs = {
  _set?: Maybe<Enum_Notification_Channel_Set_Input>;
  pk_columns: Enum_Notification_Channel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_GroupArgs = {
  _set?: Maybe<Enum_Notification_Group_Set_Input>;
  where: Enum_Notification_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_Group_By_PkArgs = {
  _set?: Maybe<Enum_Notification_Group_Set_Input>;
  pk_columns: Enum_Notification_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Position_TypeArgs = {
  _set?: Maybe<Enum_Position_Type_Set_Input>;
  where: Enum_Position_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Position_Type_By_PkArgs = {
  _set?: Maybe<Enum_Position_Type_Set_Input>;
  pk_columns: Enum_Position_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Price_ScopeArgs = {
  _set?: Maybe<Enum_Price_Scope_Set_Input>;
  where: Enum_Price_Scope_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Price_Scope_By_PkArgs = {
  _set?: Maybe<Enum_Price_Scope_Set_Input>;
  pk_columns: Enum_Price_Scope_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Print_TechniqueArgs = {
  _set?: Maybe<Enum_Print_Technique_Set_Input>;
  where: Enum_Print_Technique_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Print_Technique_By_PkArgs = {
  _set?: Maybe<Enum_Print_Technique_Set_Input>;
  pk_columns: Enum_Print_Technique_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Background_TypeArgs = {
  _set?: Maybe<Enum_Profile_Background_Type_Set_Input>;
  where: Enum_Profile_Background_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Background_Type_By_PkArgs = {
  _set?: Maybe<Enum_Profile_Background_Type_Set_Input>;
  pk_columns: Enum_Profile_Background_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Invitation_StatusArgs = {
  _set?: Maybe<Enum_Profile_Invitation_Status_Set_Input>;
  where: Enum_Profile_Invitation_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Invitation_Status_By_PkArgs = {
  _set?: Maybe<Enum_Profile_Invitation_Status_Set_Input>;
  pk_columns: Enum_Profile_Invitation_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Public_StatusArgs = {
  _set?: Maybe<Enum_Profile_Public_Status_Set_Input>;
  where: Enum_Profile_Public_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Public_Status_By_PkArgs = {
  _set?: Maybe<Enum_Profile_Public_Status_Set_Input>;
  pk_columns: Enum_Profile_Public_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_RegionArgs = {
  _set?: Maybe<Enum_Profile_Region_Set_Input>;
  where: Enum_Profile_Region_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Region_By_PkArgs = {
  _set?: Maybe<Enum_Profile_Region_Set_Input>;
  pk_columns: Enum_Profile_Region_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_StatusArgs = {
  _set?: Maybe<Enum_Profile_Status_Set_Input>;
  where: Enum_Profile_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Status_By_PkArgs = {
  _set?: Maybe<Enum_Profile_Status_Set_Input>;
  pk_columns: Enum_Profile_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_ThemeArgs = {
  _set?: Maybe<Enum_Profile_Theme_Set_Input>;
  where: Enum_Profile_Theme_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Theme_By_PkArgs = {
  _set?: Maybe<Enum_Profile_Theme_Set_Input>;
  pk_columns: Enum_Profile_Theme_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_TypeArgs = {
  _set?: Maybe<Enum_Profile_Type_Set_Input>;
  where: Enum_Profile_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Profile_Type_By_PkArgs = {
  _set?: Maybe<Enum_Profile_Type_Set_Input>;
  pk_columns: Enum_Profile_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Pseudo_User_ActionArgs = {
  _set?: Maybe<Enum_Pseudo_User_Action_Set_Input>;
  where: Enum_Pseudo_User_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Pseudo_User_Action_By_PkArgs = {
  _set?: Maybe<Enum_Pseudo_User_Action_Set_Input>;
  pk_columns: Enum_Pseudo_User_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Push_Notification_StatusArgs = {
  _set?: Maybe<Enum_Push_Notification_Status_Set_Input>;
  where: Enum_Push_Notification_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Push_Notification_Status_By_PkArgs = {
  _set?: Maybe<Enum_Push_Notification_Status_Set_Input>;
  pk_columns: Enum_Push_Notification_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Push_Notification_TypeArgs = {
  _set?: Maybe<Enum_Push_Notification_Type_Set_Input>;
  where: Enum_Push_Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Push_Notification_Type_By_PkArgs = {
  _set?: Maybe<Enum_Push_Notification_Type_Set_Input>;
  pk_columns: Enum_Push_Notification_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Questionnaire_TypeArgs = {
  _set?: Maybe<Enum_Questionnaire_Type_Set_Input>;
  where: Enum_Questionnaire_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Questionnaire_Type_By_PkArgs = {
  _set?: Maybe<Enum_Questionnaire_Type_Set_Input>;
  pk_columns: Enum_Questionnaire_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Render_Request_StatusArgs = {
  _set?: Maybe<Enum_Render_Request_Status_Set_Input>;
  where: Enum_Render_Request_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Render_Request_Status_By_PkArgs = {
  _set?: Maybe<Enum_Render_Request_Status_Set_Input>;
  pk_columns: Enum_Render_Request_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Render_Request_Template_TypeArgs = {
  _set?: Maybe<Enum_Render_Request_Template_Type_Set_Input>;
  where: Enum_Render_Request_Template_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Render_Request_Template_Type_By_PkArgs = {
  _set?: Maybe<Enum_Render_Request_Template_Type_Set_Input>;
  pk_columns: Enum_Render_Request_Template_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Restricted_Handle_NamesArgs = {
  _set?: Maybe<Enum_Restricted_Handle_Names_Set_Input>;
  where: Enum_Restricted_Handle_Names_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Restricted_Handle_Names_By_PkArgs = {
  _set?: Maybe<Enum_Restricted_Handle_Names_Set_Input>;
  pk_columns: Enum_Restricted_Handle_Names_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_SizeArgs = {
  _set?: Maybe<Enum_Size_Set_Input>;
  where: Enum_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Size_By_PkArgs = {
  _set?: Maybe<Enum_Size_Set_Input>;
  pk_columns: Enum_Size_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Social_Graph_Followers_StateArgs = {
  _set?: Maybe<Enum_Social_Graph_Followers_State_Set_Input>;
  where: Enum_Social_Graph_Followers_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Social_Graph_Followers_State_By_PkArgs = {
  _set?: Maybe<Enum_Social_Graph_Followers_State_Set_Input>;
  pk_columns: Enum_Social_Graph_Followers_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Social_Link_StatusArgs = {
  _set?: Maybe<Enum_Social_Link_Status_Set_Input>;
  where: Enum_Social_Link_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Social_Link_Status_By_PkArgs = {
  _set?: Maybe<Enum_Social_Link_Status_Set_Input>;
  pk_columns: Enum_Social_Link_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Social_Link_TypeArgs = {
  _set?: Maybe<Enum_Social_Link_Type_Set_Input>;
  where: Enum_Social_Link_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Social_Link_Type_By_PkArgs = {
  _set?: Maybe<Enum_Social_Link_Type_Set_Input>;
  pk_columns: Enum_Social_Link_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Timed_Event_NameArgs = {
  _set?: Maybe<Enum_Timed_Event_Name_Set_Input>;
  where: Enum_Timed_Event_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Timed_Event_Name_By_PkArgs = {
  _set?: Maybe<Enum_Timed_Event_Name_Set_Input>;
  pk_columns: Enum_Timed_Event_Name_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Token_Storage_StatusArgs = {
  _set?: Maybe<Enum_Token_Storage_Status_Set_Input>;
  where: Enum_Token_Storage_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Token_Storage_Status_By_PkArgs = {
  _set?: Maybe<Enum_Token_Storage_Status_Set_Input>;
  pk_columns: Enum_Token_Storage_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Transaction_OriginArgs = {
  _set?: Maybe<Enum_Transaction_Origin_Set_Input>;
  where: Enum_Transaction_Origin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Transaction_Origin_By_PkArgs = {
  _set?: Maybe<Enum_Transaction_Origin_Set_Input>;
  pk_columns: Enum_Transaction_Origin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Transaction_StatusArgs = {
  _set?: Maybe<Enum_Transaction_Status_Set_Input>;
  where: Enum_Transaction_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Transaction_Status_By_PkArgs = {
  _set?: Maybe<Enum_Transaction_Status_Set_Input>;
  pk_columns: Enum_Transaction_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tutorial_StatusArgs = {
  _set?: Maybe<Enum_Tutorial_Status_Set_Input>;
  where: Enum_Tutorial_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tutorial_Status_By_PkArgs = {
  _set?: Maybe<Enum_Tutorial_Status_Set_Input>;
  pk_columns: Enum_Tutorial_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tutorial_Step_TypeArgs = {
  _set?: Maybe<Enum_Tutorial_Step_Type_Set_Input>;
  where: Enum_Tutorial_Step_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tutorial_Step_Type_By_PkArgs = {
  _set?: Maybe<Enum_Tutorial_Step_Type_Set_Input>;
  pk_columns: Enum_Tutorial_Step_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tutorial_TypeArgs = {
  _set?: Maybe<Enum_Tutorial_Type_Set_Input>;
  where: Enum_Tutorial_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tutorial_Type_By_PkArgs = {
  _set?: Maybe<Enum_Tutorial_Type_Set_Input>;
  pk_columns: Enum_Tutorial_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Vumark_StatusArgs = {
  _set?: Maybe<Enum_Vumark_Status_Set_Input>;
  where: Enum_Vumark_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Vumark_Status_By_PkArgs = {
  _set?: Maybe<Enum_Vumark_Status_Set_Input>;
  pk_columns: Enum_Vumark_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Vumark_TypeArgs = {
  _set?: Maybe<Enum_Vumark_Type_Set_Input>;
  where: Enum_Vumark_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Vumark_Type_By_PkArgs = {
  _set?: Maybe<Enum_Vumark_Type_Set_Input>;
  pk_columns: Enum_Vumark_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EditionArgs = {
  _inc?: Maybe<Edition_Inc_Input>;
  _set?: Maybe<Edition_Set_Input>;
  where: Edition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Edition_By_SizeArgs = {
  _inc?: Maybe<Edition_By_Size_Inc_Input>;
  _set?: Maybe<Edition_By_Size_Set_Input>;
  where: Edition_By_Size_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Edition_By_Size_By_PkArgs = {
  _inc?: Maybe<Edition_By_Size_Inc_Input>;
  _set?: Maybe<Edition_By_Size_Set_Input>;
  pk_columns: Edition_By_Size_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Edition_By_TypeArgs = {
  _inc?: Maybe<Edition_By_Type_Inc_Input>;
  _set?: Maybe<Edition_By_Type_Set_Input>;
  where: Edition_By_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Edition_By_Type_By_PkArgs = {
  _inc?: Maybe<Edition_By_Type_Inc_Input>;
  _set?: Maybe<Edition_By_Type_Set_Input>;
  pk_columns: Edition_By_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Edition_TypeArgs = {
  _inc?: Maybe<Edition_Type_Inc_Input>;
  _set?: Maybe<Edition_Type_Set_Input>;
  where: Edition_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Edition_Type_By_PkArgs = {
  _inc?: Maybe<Edition_Type_Inc_Input>;
  _set?: Maybe<Edition_Type_Set_Input>;
  pk_columns: Edition_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Edition_By_PkArgs = {
  _inc?: Maybe<Edition_Inc_Input>;
  _set?: Maybe<Edition_Set_Input>;
  pk_columns: Edition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Edition_EventsArgs = {
  _append?: Maybe<Edition_Events_Append_Input>;
  _delete_at_path?: Maybe<Edition_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Edition_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Edition_Events_Delete_Key_Input>;
  _inc?: Maybe<Edition_Events_Inc_Input>;
  _prepend?: Maybe<Edition_Events_Prepend_Input>;
  _set?: Maybe<Edition_Events_Set_Input>;
  where: Edition_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Edition_Events_By_PkArgs = {
  _append?: Maybe<Edition_Events_Append_Input>;
  _delete_at_path?: Maybe<Edition_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Edition_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Edition_Events_Delete_Key_Input>;
  _inc?: Maybe<Edition_Events_Inc_Input>;
  _prepend?: Maybe<Edition_Events_Prepend_Input>;
  _set?: Maybe<Edition_Events_Set_Input>;
  pk_columns: Edition_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Validation_TokenArgs = {
  _inc?: Maybe<Email_Validation_Token_Inc_Input>;
  _set?: Maybe<Email_Validation_Token_Set_Input>;
  where: Email_Validation_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Validation_Token_By_PkArgs = {
  _inc?: Maybe<Email_Validation_Token_Inc_Input>;
  _set?: Maybe<Email_Validation_Token_Set_Input>;
  pk_columns: Email_Validation_Token_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailsArgs = {
  _append?: Maybe<Emails_Append_Input>;
  _delete_at_path?: Maybe<Emails_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Emails_Delete_Elem_Input>;
  _delete_key?: Maybe<Emails_Delete_Key_Input>;
  _inc?: Maybe<Emails_Inc_Input>;
  _prepend?: Maybe<Emails_Prepend_Input>;
  _set?: Maybe<Emails_Set_Input>;
  where: Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Emails_By_PkArgs = {
  _append?: Maybe<Emails_Append_Input>;
  _delete_at_path?: Maybe<Emails_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Emails_Delete_Elem_Input>;
  _delete_key?: Maybe<Emails_Delete_Key_Input>;
  _inc?: Maybe<Emails_Inc_Input>;
  _prepend?: Maybe<Emails_Prepend_Input>;
  _set?: Maybe<Emails_Set_Input>;
  pk_columns: Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fcm_TokenArgs = {
  _inc?: Maybe<Fcm_Token_Inc_Input>;
  _set?: Maybe<Fcm_Token_Set_Input>;
  where: Fcm_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fcm_Token_By_PkArgs = {
  _inc?: Maybe<Fcm_Token_Inc_Input>;
  _set?: Maybe<Fcm_Token_Set_Input>;
  pk_columns: Fcm_Token_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FeaturesArgs = {
  _inc?: Maybe<Features_Inc_Input>;
  _set?: Maybe<Features_Set_Input>;
  where: Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Features_By_PkArgs = {
  _inc?: Maybe<Features_Inc_Input>;
  _set?: Maybe<Features_Set_Input>;
  pk_columns: Features_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_MetadataArgs = {
  _append?: Maybe<File_Metadata_Append_Input>;
  _delete_at_path?: Maybe<File_Metadata_Delete_At_Path_Input>;
  _delete_elem?: Maybe<File_Metadata_Delete_Elem_Input>;
  _delete_key?: Maybe<File_Metadata_Delete_Key_Input>;
  _inc?: Maybe<File_Metadata_Inc_Input>;
  _prepend?: Maybe<File_Metadata_Prepend_Input>;
  _set?: Maybe<File_Metadata_Set_Input>;
  where: File_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Metadata_By_PkArgs = {
  _append?: Maybe<File_Metadata_Append_Input>;
  _delete_at_path?: Maybe<File_Metadata_Delete_At_Path_Input>;
  _delete_elem?: Maybe<File_Metadata_Delete_Elem_Input>;
  _delete_key?: Maybe<File_Metadata_Delete_Key_Input>;
  _inc?: Maybe<File_Metadata_Inc_Input>;
  _prepend?: Maybe<File_Metadata_Prepend_Input>;
  _set?: Maybe<File_Metadata_Set_Input>;
  pk_columns: File_Metadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Firebase_ProfileArgs = {
  _inc?: Maybe<Firebase_Profile_Inc_Input>;
  _set?: Maybe<Firebase_Profile_Set_Input>;
  where: Firebase_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Firebase_Profile_By_PkArgs = {
  _inc?: Maybe<Firebase_Profile_Inc_Input>;
  _set?: Maybe<Firebase_Profile_Set_Input>;
  pk_columns: Firebase_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Flagged_UsersArgs = {
  _inc?: Maybe<Flagged_Users_Inc_Input>;
  _set?: Maybe<Flagged_Users_Set_Input>;
  where: Flagged_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Flagged_Users_By_PkArgs = {
  _inc?: Maybe<Flagged_Users_Inc_Input>;
  _set?: Maybe<Flagged_Users_Set_Input>;
  pk_columns: Flagged_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Forge_InProcess_TaskArgs = {
  _inc?: Maybe<Forge_InProcess_Task_Inc_Input>;
  _set?: Maybe<Forge_InProcess_Task_Set_Input>;
  where: Forge_InProcess_Task_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Forge_InProcess_Task_By_PkArgs = {
  _inc?: Maybe<Forge_InProcess_Task_Inc_Input>;
  _set?: Maybe<Forge_InProcess_Task_Set_Input>;
  pk_columns: Forge_InProcess_Task_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Forge_TasksArgs = {
  _inc?: Maybe<Forge_Tasks_Inc_Input>;
  _set?: Maybe<Forge_Tasks_Set_Input>;
  where: Forge_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Forge_Tasks_By_PkArgs = {
  _inc?: Maybe<Forge_Tasks_Inc_Input>;
  _set?: Maybe<Forge_Tasks_Set_Input>;
  pk_columns: Forge_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Global_PropertiesArgs = {
  _inc?: Maybe<Global_Properties_Inc_Input>;
  _set?: Maybe<Global_Properties_Set_Input>;
  where: Global_Properties_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Global_Properties_By_PkArgs = {
  _inc?: Maybe<Global_Properties_Inc_Input>;
  _set?: Maybe<Global_Properties_Set_Input>;
  pk_columns: Global_Properties_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_ArtworkArgs = {
  _inc?: Maybe<Graveyard_Artwork_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Set_Input>;
  where: Graveyard_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_By_PkArgs = {
  _inc?: Maybe<Graveyard_Artwork_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Set_Input>;
  pk_columns: Graveyard_Artwork_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_DetailsArgs = {
  _inc?: Maybe<Graveyard_Artwork_Details_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Details_Set_Input>;
  where: Graveyard_Artwork_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Details_By_PkArgs = {
  _inc?: Maybe<Graveyard_Artwork_Details_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Details_Set_Input>;
  pk_columns: Graveyard_Artwork_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Details_TranslationArgs = {
  _inc?: Maybe<Graveyard_Artwork_Details_Translation_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Details_Translation_Set_Input>;
  where: Graveyard_Artwork_Details_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Details_Translation_By_PkArgs = {
  _inc?: Maybe<Graveyard_Artwork_Details_Translation_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Details_Translation_Set_Input>;
  pk_columns: Graveyard_Artwork_Details_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_EventsArgs = {
  _append?: Maybe<Graveyard_Artwork_Events_Append_Input>;
  _delete_at_path?: Maybe<Graveyard_Artwork_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Graveyard_Artwork_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Graveyard_Artwork_Events_Delete_Key_Input>;
  _inc?: Maybe<Graveyard_Artwork_Events_Inc_Input>;
  _prepend?: Maybe<Graveyard_Artwork_Events_Prepend_Input>;
  _set?: Maybe<Graveyard_Artwork_Events_Set_Input>;
  where: Graveyard_Artwork_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Events_By_PkArgs = {
  _append?: Maybe<Graveyard_Artwork_Events_Append_Input>;
  _delete_at_path?: Maybe<Graveyard_Artwork_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Graveyard_Artwork_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Graveyard_Artwork_Events_Delete_Key_Input>;
  _inc?: Maybe<Graveyard_Artwork_Events_Inc_Input>;
  _prepend?: Maybe<Graveyard_Artwork_Events_Prepend_Input>;
  _set?: Maybe<Graveyard_Artwork_Events_Set_Input>;
  pk_columns: Graveyard_Artwork_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_PricesArgs = {
  _inc?: Maybe<Graveyard_Artwork_Prices_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Prices_Set_Input>;
  where: Graveyard_Artwork_Prices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Prices_By_PkArgs = {
  _inc?: Maybe<Graveyard_Artwork_Prices_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Prices_Set_Input>;
  pk_columns: Graveyard_Artwork_Prices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Secondary_MediaArgs = {
  _inc?: Maybe<Graveyard_Artwork_Secondary_Media_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Secondary_Media_Set_Input>;
  where: Graveyard_Artwork_Secondary_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Secondary_Media_By_PkArgs = {
  _inc?: Maybe<Graveyard_Artwork_Secondary_Media_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Secondary_Media_Set_Input>;
  pk_columns: Graveyard_Artwork_Secondary_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_StoryArgs = {
  _inc?: Maybe<Graveyard_Artwork_Story_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Story_Set_Input>;
  where: Graveyard_Artwork_Story_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Story_By_PkArgs = {
  _inc?: Maybe<Graveyard_Artwork_Story_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Story_Set_Input>;
  pk_columns: Graveyard_Artwork_Story_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Story_MediaArgs = {
  _inc?: Maybe<Graveyard_Artwork_Story_Media_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Story_Media_Set_Input>;
  where: Graveyard_Artwork_Story_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Story_Media_By_PkArgs = {
  _inc?: Maybe<Graveyard_Artwork_Story_Media_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Story_Media_Set_Input>;
  pk_columns: Graveyard_Artwork_Story_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_To_CollectionArgs = {
  _inc?: Maybe<Graveyard_Artwork_To_Collection_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_To_Collection_Set_Input>;
  where: Graveyard_Artwork_To_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_To_Collection_By_PkArgs = {
  _inc?: Maybe<Graveyard_Artwork_To_Collection_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_To_Collection_Set_Input>;
  pk_columns: Graveyard_Artwork_To_Collection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_TransactionsArgs = {
  _inc?: Maybe<Graveyard_Artwork_Transactions_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Transactions_Set_Input>;
  where: Graveyard_Artwork_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Artwork_Transactions_By_PkArgs = {
  _inc?: Maybe<Graveyard_Artwork_Transactions_Inc_Input>;
  _set?: Maybe<Graveyard_Artwork_Transactions_Set_Input>;
  pk_columns: Graveyard_Artwork_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Purchase_InquiresArgs = {
  _inc?: Maybe<Graveyard_Purchase_Inquires_Inc_Input>;
  _set?: Maybe<Graveyard_Purchase_Inquires_Set_Input>;
  where: Graveyard_Purchase_Inquires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Graveyard_Purchase_Inquires_Outside_UsersArgs = {
  _inc?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Inc_Input>;
  _set?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Set_Input>;
  where: Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InterestsArgs = {
  _inc?: Maybe<Interests_Inc_Input>;
  _set?: Maybe<Interests_Set_Input>;
  where: Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Interests_By_PkArgs = {
  _inc?: Maybe<Interests_Inc_Input>;
  _set?: Maybe<Interests_Set_Input>;
  pk_columns: Interests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Kaleido_General_SettingsArgs = {
  _append?: Maybe<Kaleido_General_Settings_Append_Input>;
  _delete_at_path?: Maybe<Kaleido_General_Settings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Kaleido_General_Settings_Delete_Elem_Input>;
  _delete_key?: Maybe<Kaleido_General_Settings_Delete_Key_Input>;
  _inc?: Maybe<Kaleido_General_Settings_Inc_Input>;
  _prepend?: Maybe<Kaleido_General_Settings_Prepend_Input>;
  _set?: Maybe<Kaleido_General_Settings_Set_Input>;
  where: Kaleido_General_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Kaleido_General_Settings_By_PkArgs = {
  _append?: Maybe<Kaleido_General_Settings_Append_Input>;
  _delete_at_path?: Maybe<Kaleido_General_Settings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Kaleido_General_Settings_Delete_Elem_Input>;
  _delete_key?: Maybe<Kaleido_General_Settings_Delete_Key_Input>;
  _inc?: Maybe<Kaleido_General_Settings_Inc_Input>;
  _prepend?: Maybe<Kaleido_General_Settings_Prepend_Input>;
  _set?: Maybe<Kaleido_General_Settings_Set_Input>;
  pk_columns: Kaleido_General_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Landing_Page_ArtworkArgs = {
  _inc?: Maybe<Landing_Page_Artwork_Inc_Input>;
  _set?: Maybe<Landing_Page_Artwork_Set_Input>;
  where: Landing_Page_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Landing_Page_Artwork_By_PkArgs = {
  _inc?: Maybe<Landing_Page_Artwork_Inc_Input>;
  _set?: Maybe<Landing_Page_Artwork_Set_Input>;
  pk_columns: Landing_Page_Artwork_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LanguagesArgs = {
  _inc?: Maybe<Languages_Inc_Input>;
  _set?: Maybe<Languages_Set_Input>;
  where: Languages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Languages_By_PkArgs = {
  _inc?: Maybe<Languages_Inc_Input>;
  _set?: Maybe<Languages_Set_Input>;
  pk_columns: Languages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Materialized_ViewArgs = {
  _inc?: Maybe<Materialized_View_Inc_Input>;
  _set?: Maybe<Materialized_View_Set_Input>;
  where: Materialized_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Materialized_View_By_PkArgs = {
  _inc?: Maybe<Materialized_View_Inc_Input>;
  _set?: Maybe<Materialized_View_Set_Input>;
  pk_columns: Materialized_View_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Media_MetadataArgs = {
  _inc?: Maybe<Media_Metadata_Inc_Input>;
  _set?: Maybe<Media_Metadata_Set_Input>;
  where: Media_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Media_Metadata_By_PkArgs = {
  _inc?: Maybe<Media_Metadata_Inc_Input>;
  _set?: Maybe<Media_Metadata_Set_Input>;
  pk_columns: Media_Metadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MilestoneArgs = {
  _inc?: Maybe<Milestone_Inc_Input>;
  _set?: Maybe<Milestone_Set_Input>;
  where: Milestone_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Milestone_By_PkArgs = {
  _inc?: Maybe<Milestone_Inc_Input>;
  _set?: Maybe<Milestone_Set_Input>;
  pk_columns: Milestone_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_My_Website_SettingsArgs = {
  _inc?: Maybe<My_Website_Settings_Inc_Input>;
  _set?: Maybe<My_Website_Settings_Set_Input>;
  where: My_Website_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_My_Website_Settings_By_PkArgs = {
  _inc?: Maybe<My_Website_Settings_Inc_Input>;
  _set?: Maybe<My_Website_Settings_Set_Input>;
  pk_columns: My_Website_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_My_Studio_SettingsArgs = {
  _inc?: Maybe<My_Studio_Settings_Inc_Input>;
  _set?: Maybe<My_Studio_Settings_Set_Input>;
  where: My_Studio_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_My_Studio_Settings_By_PkArgs = {
  _inc?: Maybe<My_Studio_Settings_Inc_Input>;
  _set?: Maybe<My_Studio_Settings_Set_Input>;
  pk_columns: My_Studio_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_PreferenceArgs = {
  _inc?: Maybe<Notification_Preference_Inc_Input>;
  _set?: Maybe<Notification_Preference_Set_Input>;
  where: Notification_Preference_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Preference_By_PkArgs = {
  _inc?: Maybe<Notification_Preference_Inc_Input>;
  _set?: Maybe<Notification_Preference_Set_Input>;
  pk_columns: Notification_Preference_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pending_Collaboration_On_ArtworkArgs = {
  _inc?: Maybe<Pending_Collaboration_On_Artwork_Inc_Input>;
  _set?: Maybe<Pending_Collaboration_On_Artwork_Set_Input>;
  where: Pending_Collaboration_On_Artwork_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pending_Collaboration_On_Artwork_By_PkArgs = {
  _inc?: Maybe<Pending_Collaboration_On_Artwork_Inc_Input>;
  _set?: Maybe<Pending_Collaboration_On_Artwork_Set_Input>;
  pk_columns: Pending_Collaboration_On_Artwork_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Price_RangesArgs = {
  _inc?: Maybe<Price_Ranges_Inc_Input>;
  _set?: Maybe<Price_Ranges_Set_Input>;
  where: Price_Ranges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Price_Ranges_By_PkArgs = {
  _inc?: Maybe<Price_Ranges_Inc_Input>;
  _set?: Maybe<Price_Ranges_Set_Input>;
  pk_columns: Price_Ranges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PrintArgs = {
  _inc?: Maybe<Print_Inc_Input>;
  _set?: Maybe<Print_Set_Input>;
  where: Print_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Print_By_PkArgs = {
  _inc?: Maybe<Print_Inc_Input>;
  _set?: Maybe<Print_Set_Input>;
  pk_columns: Print_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Print_To_CollectionArgs = {
  _inc?: Maybe<Print_To_Collection_Inc_Input>;
  _set?: Maybe<Print_To_Collection_Set_Input>;
  where: Print_To_Collection_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Print_To_Collection_By_PkArgs = {
  _inc?: Maybe<Print_To_Collection_Inc_Input>;
  _set?: Maybe<Print_To_Collection_Set_Input>;
  pk_columns: Print_To_Collection_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Prize_Art_ShowArgs = {
  _append?: Maybe<Prize_Art_Show_Append_Input>;
  _delete_at_path?: Maybe<Prize_Art_Show_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Prize_Art_Show_Delete_Elem_Input>;
  _delete_key?: Maybe<Prize_Art_Show_Delete_Key_Input>;
  _inc?: Maybe<Prize_Art_Show_Inc_Input>;
  _prepend?: Maybe<Prize_Art_Show_Prepend_Input>;
  _set?: Maybe<Prize_Art_Show_Set_Input>;
  where: Prize_Art_Show_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Prize_Art_Show_By_PkArgs = {
  _append?: Maybe<Prize_Art_Show_Append_Input>;
  _delete_at_path?: Maybe<Prize_Art_Show_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Prize_Art_Show_Delete_Elem_Input>;
  _delete_key?: Maybe<Prize_Art_Show_Delete_Key_Input>;
  _inc?: Maybe<Prize_Art_Show_Inc_Input>;
  _prepend?: Maybe<Prize_Art_Show_Prepend_Input>;
  _set?: Maybe<Prize_Art_Show_Set_Input>;
  pk_columns: Prize_Art_Show_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProfileArgs = {
  _inc?: Maybe<Profile_Inc_Input>;
  _set?: Maybe<Profile_Set_Input>;
  where: Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_InterestsArgs = {
  _inc?: Maybe<Profile_Interests_Inc_Input>;
  _set?: Maybe<Profile_Interests_Set_Input>;
  where: Profile_Interests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Interests_By_PkArgs = {
  _inc?: Maybe<Profile_Interests_Inc_Input>;
  _set?: Maybe<Profile_Interests_Set_Input>;
  pk_columns: Profile_Interests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_AchievementsArgs = {
  _append?: Maybe<Profile_Achievements_Append_Input>;
  _delete_at_path?: Maybe<Profile_Achievements_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Profile_Achievements_Delete_Elem_Input>;
  _delete_key?: Maybe<Profile_Achievements_Delete_Key_Input>;
  _inc?: Maybe<Profile_Achievements_Inc_Input>;
  _prepend?: Maybe<Profile_Achievements_Prepend_Input>;
  _set?: Maybe<Profile_Achievements_Set_Input>;
  where: Profile_Achievements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Achievements_By_PkArgs = {
  _append?: Maybe<Profile_Achievements_Append_Input>;
  _delete_at_path?: Maybe<Profile_Achievements_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Profile_Achievements_Delete_Elem_Input>;
  _delete_key?: Maybe<Profile_Achievements_Delete_Key_Input>;
  _inc?: Maybe<Profile_Achievements_Inc_Input>;
  _prepend?: Maybe<Profile_Achievements_Prepend_Input>;
  _set?: Maybe<Profile_Achievements_Set_Input>;
  pk_columns: Profile_Achievements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Appearance_SettingsArgs = {
  _inc?: Maybe<Profile_Appearance_Settings_Inc_Input>;
  _set?: Maybe<Profile_Appearance_Settings_Set_Input>;
  where: Profile_Appearance_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Appearance_Settings_By_PkArgs = {
  _inc?: Maybe<Profile_Appearance_Settings_Inc_Input>;
  _set?: Maybe<Profile_Appearance_Settings_Set_Input>;
  pk_columns: Profile_Appearance_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_BackgroundArgs = {
  _inc?: Maybe<Profile_Background_Inc_Input>;
  _set?: Maybe<Profile_Background_Set_Input>;
  where: Profile_Background_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Background_By_PkArgs = {
  _inc?: Maybe<Profile_Background_Inc_Input>;
  _set?: Maybe<Profile_Background_Set_Input>;
  pk_columns: Profile_Background_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_By_PkArgs = {
  _inc?: Maybe<Profile_Inc_Input>;
  _set?: Maybe<Profile_Set_Input>;
  pk_columns: Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Ghost_TokensArgs = {
  _inc?: Maybe<Profile_Ghost_Tokens_Inc_Input>;
  _set?: Maybe<Profile_Ghost_Tokens_Set_Input>;
  where: Profile_Ghost_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Ghost_Tokens_By_PkArgs = {
  _inc?: Maybe<Profile_Ghost_Tokens_Inc_Input>;
  _set?: Maybe<Profile_Ghost_Tokens_Set_Input>;
  pk_columns: Profile_Ghost_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_InvitationsArgs = {
  _inc?: Maybe<Profile_Invitations_Inc_Input>;
  _set?: Maybe<Profile_Invitations_Set_Input>;
  where: Profile_Invitations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Invitations_By_PkArgs = {
  _inc?: Maybe<Profile_Invitations_Inc_Input>;
  _set?: Maybe<Profile_Invitations_Set_Input>;
  pk_columns: Profile_Invitations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_LocationArgs = {
  _inc?: Maybe<Profile_Location_Inc_Input>;
  _set?: Maybe<Profile_Location_Set_Input>;
  where: Profile_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Location_By_PkArgs = {
  _inc?: Maybe<Profile_Location_Inc_Input>;
  _set?: Maybe<Profile_Location_Set_Input>;
  pk_columns: Profile_Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Location_TranslationArgs = {
  _inc?: Maybe<Profile_Location_Translation_Inc_Input>;
  _set?: Maybe<Profile_Location_Translation_Set_Input>;
  where: Profile_Location_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Location_Translation_By_PkArgs = {
  _inc?: Maybe<Profile_Location_Translation_Inc_Input>;
  _set?: Maybe<Profile_Location_Translation_Set_Input>;
  pk_columns: Profile_Location_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Price_RangesArgs = {
  _inc?: Maybe<Profile_Price_Ranges_Inc_Input>;
  _set?: Maybe<Profile_Price_Ranges_Set_Input>;
  where: Profile_Price_Ranges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Price_Ranges_By_PkArgs = {
  _inc?: Maybe<Profile_Price_Ranges_Inc_Input>;
  _set?: Maybe<Profile_Price_Ranges_Set_Input>;
  pk_columns: Profile_Price_Ranges_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Questions_And_AnswersArgs = {
  _inc?: Maybe<Profile_Questions_And_Answers_Inc_Input>;
  _set?: Maybe<Profile_Questions_And_Answers_Set_Input>;
  where: Profile_Questions_And_Answers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Questions_And_Answers_By_PkArgs = {
  _inc?: Maybe<Profile_Questions_And_Answers_Inc_Input>;
  _set?: Maybe<Profile_Questions_And_Answers_Set_Input>;
  pk_columns: Profile_Questions_And_Answers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_SettingsArgs = {
  _inc?: Maybe<Profile_Settings_Inc_Input>;
  _set?: Maybe<Profile_Settings_Set_Input>;
  where: Profile_Settings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Settings_By_PkArgs = {
  _inc?: Maybe<Profile_Settings_Inc_Input>;
  _set?: Maybe<Profile_Settings_Set_Input>;
  pk_columns: Profile_Settings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Social_LinksArgs = {
  _inc?: Maybe<Profile_Social_Links_Inc_Input>;
  _set?: Maybe<Profile_Social_Links_Set_Input>;
  where: Profile_Social_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Social_Links_By_PkArgs = {
  _inc?: Maybe<Profile_Social_Links_Inc_Input>;
  _set?: Maybe<Profile_Social_Links_Set_Input>;
  pk_columns: Profile_Social_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Statement_Audio_SynthesesArgs = {
  _inc?: Maybe<Profile_Statement_Audio_Syntheses_Inc_Input>;
  _set?: Maybe<Profile_Statement_Audio_Syntheses_Set_Input>;
  where: Profile_Statement_Audio_Syntheses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Statement_Audio_Syntheses_By_PkArgs = {
  _inc?: Maybe<Profile_Statement_Audio_Syntheses_Inc_Input>;
  _set?: Maybe<Profile_Statement_Audio_Syntheses_Set_Input>;
  pk_columns: Profile_Statement_Audio_Syntheses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_TranslationArgs = {
  _inc?: Maybe<Profile_Translation_Inc_Input>;
  _set?: Maybe<Profile_Translation_Set_Input>;
  where: Profile_Translation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Translation_By_PkArgs = {
  _inc?: Maybe<Profile_Translation_Inc_Input>;
  _set?: Maybe<Profile_Translation_Set_Input>;
  pk_columns: Profile_Translation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Video_LinksArgs = {
  _inc?: Maybe<Profile_Video_Links_Inc_Input>;
  _set?: Maybe<Profile_Video_Links_Set_Input>;
  where: Profile_Video_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Video_Links_By_PkArgs = {
  _inc?: Maybe<Profile_Video_Links_Inc_Input>;
  _set?: Maybe<Profile_Video_Links_Set_Input>;
  pk_columns: Profile_Video_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Wallet_AddressArgs = {
  _inc?: Maybe<Profile_Wallet_Address_Inc_Input>;
  _set?: Maybe<Profile_Wallet_Address_Set_Input>;
  where: Profile_Wallet_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Wallet_Address_By_PkArgs = {
  _inc?: Maybe<Profile_Wallet_Address_Inc_Input>;
  _set?: Maybe<Profile_Wallet_Address_Set_Input>;
  pk_columns: Profile_Wallet_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pubnub_ChannelsArgs = {
  _inc?: Maybe<Pubnub_Channels_Inc_Input>;
  _set?: Maybe<Pubnub_Channels_Set_Input>;
  where: Pubnub_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pubnub_Channels_By_PkArgs = {
  _inc?: Maybe<Pubnub_Channels_Inc_Input>;
  _set?: Maybe<Pubnub_Channels_Set_Input>;
  pk_columns: Pubnub_Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Purchase_InquiresArgs = {
  _inc?: Maybe<Purchase_Inquires_Inc_Input>;
  _set?: Maybe<Purchase_Inquires_Set_Input>;
  where: Purchase_Inquires_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Purchase_Inquires_By_PkArgs = {
  _inc?: Maybe<Purchase_Inquires_Inc_Input>;
  _set?: Maybe<Purchase_Inquires_Set_Input>;
  pk_columns: Purchase_Inquires_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Purchase_Inquires_Outside_UsersArgs = {
  _inc?: Maybe<Purchase_Inquires_Outside_Users_Inc_Input>;
  _set?: Maybe<Purchase_Inquires_Outside_Users_Set_Input>;
  where: Purchase_Inquires_Outside_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Purchase_Inquires_Outside_Users_By_PkArgs = {
  _inc?: Maybe<Purchase_Inquires_Outside_Users_Inc_Input>;
  _set?: Maybe<Purchase_Inquires_Outside_Users_Set_Input>;
  pk_columns: Purchase_Inquires_Outside_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Push_NotificationsArgs = {
  _append?: Maybe<Push_Notifications_Append_Input>;
  _delete_at_path?: Maybe<Push_Notifications_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Push_Notifications_Delete_Elem_Input>;
  _delete_key?: Maybe<Push_Notifications_Delete_Key_Input>;
  _inc?: Maybe<Push_Notifications_Inc_Input>;
  _prepend?: Maybe<Push_Notifications_Prepend_Input>;
  _set?: Maybe<Push_Notifications_Set_Input>;
  where: Push_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Push_Notifications_By_PkArgs = {
  _append?: Maybe<Push_Notifications_Append_Input>;
  _delete_at_path?: Maybe<Push_Notifications_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Push_Notifications_Delete_Elem_Input>;
  _delete_key?: Maybe<Push_Notifications_Delete_Key_Input>;
  _inc?: Maybe<Push_Notifications_Inc_Input>;
  _prepend?: Maybe<Push_Notifications_Prepend_Input>;
  _set?: Maybe<Push_Notifications_Set_Input>;
  pk_columns: Push_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_QuestionsArgs = {
  _inc?: Maybe<Questionnaire_Questions_Inc_Input>;
  _set?: Maybe<Questionnaire_Questions_Set_Input>;
  where: Questionnaire_Questions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Questions_By_PkArgs = {
  _inc?: Maybe<Questionnaire_Questions_Inc_Input>;
  _set?: Maybe<Questionnaire_Questions_Set_Input>;
  pk_columns: Questionnaire_Questions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Render_RequestArgs = {
  _append?: Maybe<Render_Request_Append_Input>;
  _delete_at_path?: Maybe<Render_Request_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Render_Request_Delete_Elem_Input>;
  _delete_key?: Maybe<Render_Request_Delete_Key_Input>;
  _inc?: Maybe<Render_Request_Inc_Input>;
  _prepend?: Maybe<Render_Request_Prepend_Input>;
  _set?: Maybe<Render_Request_Set_Input>;
  where: Render_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Render_Request_By_PkArgs = {
  _append?: Maybe<Render_Request_Append_Input>;
  _delete_at_path?: Maybe<Render_Request_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Render_Request_Delete_Elem_Input>;
  _delete_key?: Maybe<Render_Request_Delete_Key_Input>;
  _inc?: Maybe<Render_Request_Inc_Input>;
  _prepend?: Maybe<Render_Request_Prepend_Input>;
  _set?: Maybe<Render_Request_Set_Input>;
  pk_columns: Render_Request_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Setting_Context_Default_ProfileArgs = {
  _inc?: Maybe<Setting_Context_Default_Profile_Inc_Input>;
  _set?: Maybe<Setting_Context_Default_Profile_Set_Input>;
  where: Setting_Context_Default_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Setting_Context_Default_Profile_By_PkArgs = {
  _inc?: Maybe<Setting_Context_Default_Profile_Inc_Input>;
  _set?: Maybe<Setting_Context_Default_Profile_Set_Input>;
  pk_columns: Setting_Context_Default_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_DetailsArgs = {
  _inc?: Maybe<Showroom_Details_Inc_Input>;
  _set?: Maybe<Showroom_Details_Set_Input>;
  where: Showroom_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Details_By_PkArgs = {
  _inc?: Maybe<Showroom_Details_Inc_Input>;
  _set?: Maybe<Showroom_Details_Set_Input>;
  pk_columns: Showroom_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Social_Graph_FollowersArgs = {
  _inc?: Maybe<Social_Graph_Followers_Inc_Input>;
  _set?: Maybe<Social_Graph_Followers_Set_Input>;
  where: Social_Graph_Followers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Social_Graph_Followers_By_PkArgs = {
  _inc?: Maybe<Social_Graph_Followers_Inc_Input>;
  _set?: Maybe<Social_Graph_Followers_Set_Input>;
  pk_columns: Social_Graph_Followers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Spotlight_ContextArgs = {
  _inc?: Maybe<Spotlight_Context_Inc_Input>;
  _set?: Maybe<Spotlight_Context_Set_Input>;
  where: Spotlight_Context_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Spotlight_Context_By_PkArgs = {
  _inc?: Maybe<Spotlight_Context_Inc_Input>;
  _set?: Maybe<Spotlight_Context_Set_Input>;
  pk_columns: Spotlight_Context_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Super_Site_SubscribersArgs = {
  _inc?: Maybe<Super_Site_Subscribers_Inc_Input>;
  _set?: Maybe<Super_Site_Subscribers_Set_Input>;
  where: Super_Site_Subscribers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Super_Site_Subscribers_By_PkArgs = {
  _inc?: Maybe<Super_Site_Subscribers_Inc_Input>;
  _set?: Maybe<Super_Site_Subscribers_Set_Input>;
  pk_columns: Super_Site_Subscribers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_InterestArgs = {
  _inc?: Maybe<Tags_By_Interest_Inc_Input>;
  _set?: Maybe<Tags_By_Interest_Set_Input>;
  where: Tags_By_Interest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_Interest_By_PkArgs = {
  _inc?: Maybe<Tags_By_Interest_Inc_Input>;
  _set?: Maybe<Tags_By_Interest_Set_Input>;
  pk_columns: Tags_By_Interest_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Analytics_EventsArgs = {
  _append?: Maybe<Test_Analytics_Events_Append_Input>;
  _delete_at_path?: Maybe<Test_Analytics_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Test_Analytics_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Test_Analytics_Events_Delete_Key_Input>;
  _inc?: Maybe<Test_Analytics_Events_Inc_Input>;
  _prepend?: Maybe<Test_Analytics_Events_Prepend_Input>;
  _set?: Maybe<Test_Analytics_Events_Set_Input>;
  where: Test_Analytics_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Analytics_Events_By_PkArgs = {
  _append?: Maybe<Test_Analytics_Events_Append_Input>;
  _delete_at_path?: Maybe<Test_Analytics_Events_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Test_Analytics_Events_Delete_Elem_Input>;
  _delete_key?: Maybe<Test_Analytics_Events_Delete_Key_Input>;
  _inc?: Maybe<Test_Analytics_Events_Inc_Input>;
  _prepend?: Maybe<Test_Analytics_Events_Prepend_Input>;
  _set?: Maybe<Test_Analytics_Events_Set_Input>;
  pk_columns: Test_Analytics_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Test_AccountsArgs = {
  _inc?: Maybe<Test_Accounts_Inc_Input>;
  _set?: Maybe<Test_Accounts_Set_Input>;
  where: Test_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Test_Accounts_By_PkArgs = {
  _inc?: Maybe<Test_Accounts_Inc_Input>;
  _set?: Maybe<Test_Accounts_Set_Input>;
  pk_columns: Test_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Timed_EventArgs = {
  _inc?: Maybe<Timed_Event_Inc_Input>;
  _set?: Maybe<Timed_Event_Set_Input>;
  where: Timed_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Timed_Event_By_PkArgs = {
  _inc?: Maybe<Timed_Event_Inc_Input>;
  _set?: Maybe<Timed_Event_Set_Input>;
  pk_columns: Timed_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Token_StorageArgs = {
  _inc?: Maybe<Token_Storage_Inc_Input>;
  _set?: Maybe<Token_Storage_Set_Input>;
  where: Token_Storage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Token_Storage_By_PkArgs = {
  _inc?: Maybe<Token_Storage_Inc_Input>;
  _set?: Maybe<Token_Storage_Set_Input>;
  pk_columns: Token_Storage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TutorialArgs = {
  _inc?: Maybe<Tutorial_Inc_Input>;
  _set?: Maybe<Tutorial_Set_Input>;
  where: Tutorial_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tutorial_By_PkArgs = {
  _inc?: Maybe<Tutorial_Inc_Input>;
  _set?: Maybe<Tutorial_Set_Input>;
  pk_columns: Tutorial_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tutorial_StepArgs = {
  _inc?: Maybe<Tutorial_Step_Inc_Input>;
  _set?: Maybe<Tutorial_Step_Set_Input>;
  where: Tutorial_Step_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tutorial_Step_By_PkArgs = {
  _inc?: Maybe<Tutorial_Step_Inc_Input>;
  _set?: Maybe<Tutorial_Step_Set_Input>;
  pk_columns: Tutorial_Step_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Analytics_Points_IndexArgs = {
  _inc?: Maybe<User_Analytics_Points_Index_Inc_Input>;
  _set?: Maybe<User_Analytics_Points_Index_Set_Input>;
  where: User_Analytics_Points_Index_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Analytics_Points_Index_By_PkArgs = {
  _inc?: Maybe<User_Analytics_Points_Index_Inc_Input>;
  _set?: Maybe<User_Analytics_Points_Index_Set_Input>;
  pk_columns: User_Analytics_Points_Index_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vr_ArtistsArgs = {
  _inc?: Maybe<Vr_Artists_Inc_Input>;
  _set?: Maybe<Vr_Artists_Set_Input>;
  where: Vr_Artists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vr_Artists_By_PkArgs = {
  _inc?: Maybe<Vr_Artists_Inc_Input>;
  _set?: Maybe<Vr_Artists_Set_Input>;
  pk_columns: Vr_Artists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vision_InterestArgs = {
  _inc?: Maybe<Vision_Interest_Inc_Input>;
  _set?: Maybe<Vision_Interest_Set_Input>;
  where: Vision_Interest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vision_Interest_By_PkArgs = {
  _inc?: Maybe<Vision_Interest_Inc_Input>;
  _set?: Maybe<Vision_Interest_Set_Input>;
  pk_columns: Vision_Interest_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_VumarksArgs = {
  _inc?: Maybe<Vumarks_Inc_Input>;
  _set?: Maybe<Vumarks_Set_Input>;
  where: Vumarks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vumarks_By_PkArgs = {
  _inc?: Maybe<Vumarks_Inc_Input>;
  _set?: Maybe<Vumarks_Set_Input>;
  pk_columns: Vumarks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Waiting_ListArgs = {
  _inc?: Maybe<Waiting_List_Inc_Input>;
  _set?: Maybe<Waiting_List_Set_Input>;
  where: Waiting_List_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Waiting_List_By_PkArgs = {
  _inc?: Maybe<Waiting_List_Inc_Input>;
  _set?: Maybe<Waiting_List_Set_Input>;
  pk_columns: Waiting_List_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WalletsArgs = {
  _inc?: Maybe<Wallets_Inc_Input>;
  _set?: Maybe<Wallets_Set_Input>;
  where: Wallets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wallets_By_PkArgs = {
  _inc?: Maybe<Wallets_Inc_Input>;
  _set?: Maybe<Wallets_Set_Input>;
  pk_columns: Wallets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_World_CitiesArgs = {
  _inc?: Maybe<World_Cities_Inc_Input>;
  _set?: Maybe<World_Cities_Set_Input>;
  where: World_Cities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_World_Cities_By_PkArgs = {
  _inc?: Maybe<World_Cities_Inc_Input>;
  _set?: Maybe<World_Cities_Set_Input>;
  pk_columns: World_Cities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_Notifications_InfoArgs = {
  _inc?: Maybe<App_Notifications_Info_Inc_Input>;
  _set?: Maybe<App_Notifications_Info_Set_Input>;
  where: App_Notifications_Info_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Notifications_ListingArgs = {
  _inc?: Maybe<App_Notifications_Listing_Inc_Input>;
  _set?: Maybe<App_Notifications_Listing_Set_Input>;
  where: App_Notifications_Listing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Notifications_ViewArgs = {
  _inc?: Maybe<App_Notifications_View_Inc_Input>;
  _set?: Maybe<App_Notifications_View_Set_Input>;
  where: App_Notifications_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_PhonesArgs = {
  _inc?: Maybe<Profile_Phones_Inc_Input>;
  _set?: Maybe<Profile_Phones_Set_Input>;
  where: Profile_Phones_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Phones_By_PkArgs = {
  _inc?: Maybe<Profile_Phones_Inc_Input>;
  _set?: Maybe<Profile_Phones_Set_Input>;
  pk_columns: Profile_Phones_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Showroom_Owner_PasswordArgs = {
  _inc?: Maybe<Showroom_Owner_Password_Inc_Input>;
  _set?: Maybe<Showroom_Owner_Password_Set_Input>;
  where: Showroom_Owner_Password_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_WorldcitiesArgs = {
  _inc?: Maybe<Worldcities_Inc_Input>;
  _set?: Maybe<Worldcities_Set_Input>;
  where: Worldcities_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Worldcities_By_PkArgs = {
  _inc?: Maybe<Worldcities_Inc_Input>;
  _set?: Maybe<Worldcities_Set_Input>;
  pk_columns: Worldcities_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpgradePseudoCollectorArgs = {
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpgradePseudoCollectorToArtistArgs = {
  password: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUpsertArtPrizeShowArgs = {
  amount_participants?: Maybe<Scalars['Int']>;
  background_metadata_id?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  prize_show_slug?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  social_media_url?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUpsertArtPrizeWinnerArtworkArgs = {
  artPrizeArtworks?: Maybe<Array<Maybe<CreateArtPrizeArtwork>>>;
  artPrizeWinner: CreateArtPrizeWinner;
};


/** mutation root */
export type Mutation_RootUpsertArtistNewsletterArgs = {
  artistContextId: Scalars['bigint'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  language: Scalars['String'];
  last_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpsertConnectedDomainArgs = {
  artistContextId: Scalars['bigint'];
  domainName: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUpsertContextFlagArgs = {
  context_id: Scalars['bigint'];
  flag_type: Scalars['String'];
  flag_value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpsertContextTutorialArgs = {
  status_or_step: Scalars['String'];
  tutorial_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUpsertDiscountCodeArgs = {
  code?: Maybe<Scalars['String']>;
  discount_emerging_value?: Maybe<Scalars['Float']>;
  discount_professional_value?: Maybe<Scalars['Float']>;
  discount_type?: Maybe<Scalars['String']>;
  discount_value_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expired_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  state?: Maybe<Scalars['String']>;
  trial_period_days?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootUpsertQuestionnaireAnswersArgs = {
  profileId: Scalars['bigint'];
  questionAndAnswers: Array<QuestionAndAnswer>;
  questionnaireType: Scalars['String'];
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "outside_purchase_inquiries_view" */
export type Outside_Purchase_Inquiries_View = {
  __typename?: 'outside_purchase_inquiries_view';
  artist_city?: Maybe<Scalars['String']>;
  artist_country?: Maybe<Scalars['String']>;
  artist_email?: Maybe<Scalars['String']>;
  artist_link?: Maybe<Scalars['String']>;
  artist_name?: Maybe<Scalars['String']>;
  artist_preferred_language?: Maybe<Scalars['String']>;
  artwork_currency?: Maybe<Scalars['String']>;
  artwork_link?: Maybe<Scalars['String']>;
  artwork_price?: Maybe<Scalars['float8']>;
  date?: Maybe<Scalars['timestamptz']>;
  purchase_inquire_id?: Maybe<Scalars['bigint']>;
  sender_email?: Maybe<Scalars['String']>;
  sender_link?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_preferred_language?: Maybe<Scalars['String']>;
};

/** aggregated selection of "outside_purchase_inquiries_view" */
export type Outside_Purchase_Inquiries_View_Aggregate = {
  __typename?: 'outside_purchase_inquiries_view_aggregate';
  aggregate?: Maybe<Outside_Purchase_Inquiries_View_Aggregate_Fields>;
  nodes: Array<Outside_Purchase_Inquiries_View>;
};

/** aggregate fields of "outside_purchase_inquiries_view" */
export type Outside_Purchase_Inquiries_View_Aggregate_Fields = {
  __typename?: 'outside_purchase_inquiries_view_aggregate_fields';
  avg?: Maybe<Outside_Purchase_Inquiries_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outside_Purchase_Inquiries_View_Max_Fields>;
  min?: Maybe<Outside_Purchase_Inquiries_View_Min_Fields>;
  stddev?: Maybe<Outside_Purchase_Inquiries_View_Stddev_Fields>;
  stddev_pop?: Maybe<Outside_Purchase_Inquiries_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outside_Purchase_Inquiries_View_Stddev_Samp_Fields>;
  sum?: Maybe<Outside_Purchase_Inquiries_View_Sum_Fields>;
  var_pop?: Maybe<Outside_Purchase_Inquiries_View_Var_Pop_Fields>;
  var_samp?: Maybe<Outside_Purchase_Inquiries_View_Var_Samp_Fields>;
  variance?: Maybe<Outside_Purchase_Inquiries_View_Variance_Fields>;
};


/** aggregate fields of "outside_purchase_inquiries_view" */
export type Outside_Purchase_Inquiries_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outside_Purchase_Inquiries_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Outside_Purchase_Inquiries_View_Avg_Fields = {
  __typename?: 'outside_purchase_inquiries_view_avg_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "outside_purchase_inquiries_view". All fields are combined with a logical 'AND'. */
export type Outside_Purchase_Inquiries_View_Bool_Exp = {
  _and?: Maybe<Array<Outside_Purchase_Inquiries_View_Bool_Exp>>;
  _not?: Maybe<Outside_Purchase_Inquiries_View_Bool_Exp>;
  _or?: Maybe<Array<Outside_Purchase_Inquiries_View_Bool_Exp>>;
  artist_city?: Maybe<String_Comparison_Exp>;
  artist_country?: Maybe<String_Comparison_Exp>;
  artist_email?: Maybe<String_Comparison_Exp>;
  artist_link?: Maybe<String_Comparison_Exp>;
  artist_name?: Maybe<String_Comparison_Exp>;
  artist_preferred_language?: Maybe<String_Comparison_Exp>;
  artwork_currency?: Maybe<String_Comparison_Exp>;
  artwork_link?: Maybe<String_Comparison_Exp>;
  artwork_price?: Maybe<Float8_Comparison_Exp>;
  date?: Maybe<Timestamptz_Comparison_Exp>;
  purchase_inquire_id?: Maybe<Bigint_Comparison_Exp>;
  sender_email?: Maybe<String_Comparison_Exp>;
  sender_link?: Maybe<String_Comparison_Exp>;
  sender_name?: Maybe<String_Comparison_Exp>;
  sender_preferred_language?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Outside_Purchase_Inquiries_View_Max_Fields = {
  __typename?: 'outside_purchase_inquiries_view_max_fields';
  artist_city?: Maybe<Scalars['String']>;
  artist_country?: Maybe<Scalars['String']>;
  artist_email?: Maybe<Scalars['String']>;
  artist_link?: Maybe<Scalars['String']>;
  artist_name?: Maybe<Scalars['String']>;
  artist_preferred_language?: Maybe<Scalars['String']>;
  artwork_currency?: Maybe<Scalars['String']>;
  artwork_link?: Maybe<Scalars['String']>;
  artwork_price?: Maybe<Scalars['float8']>;
  date?: Maybe<Scalars['timestamptz']>;
  purchase_inquire_id?: Maybe<Scalars['bigint']>;
  sender_email?: Maybe<Scalars['String']>;
  sender_link?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_preferred_language?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Outside_Purchase_Inquiries_View_Min_Fields = {
  __typename?: 'outside_purchase_inquiries_view_min_fields';
  artist_city?: Maybe<Scalars['String']>;
  artist_country?: Maybe<Scalars['String']>;
  artist_email?: Maybe<Scalars['String']>;
  artist_link?: Maybe<Scalars['String']>;
  artist_name?: Maybe<Scalars['String']>;
  artist_preferred_language?: Maybe<Scalars['String']>;
  artwork_currency?: Maybe<Scalars['String']>;
  artwork_link?: Maybe<Scalars['String']>;
  artwork_price?: Maybe<Scalars['float8']>;
  date?: Maybe<Scalars['timestamptz']>;
  purchase_inquire_id?: Maybe<Scalars['bigint']>;
  sender_email?: Maybe<Scalars['String']>;
  sender_link?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  sender_preferred_language?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "outside_purchase_inquiries_view". */
export type Outside_Purchase_Inquiries_View_Order_By = {
  artist_city?: Maybe<Order_By>;
  artist_country?: Maybe<Order_By>;
  artist_email?: Maybe<Order_By>;
  artist_link?: Maybe<Order_By>;
  artist_name?: Maybe<Order_By>;
  artist_preferred_language?: Maybe<Order_By>;
  artwork_currency?: Maybe<Order_By>;
  artwork_link?: Maybe<Order_By>;
  artwork_price?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  purchase_inquire_id?: Maybe<Order_By>;
  sender_email?: Maybe<Order_By>;
  sender_link?: Maybe<Order_By>;
  sender_name?: Maybe<Order_By>;
  sender_preferred_language?: Maybe<Order_By>;
};

/** select columns of table "outside_purchase_inquiries_view" */
export enum Outside_Purchase_Inquiries_View_Select_Column {
  /** column name */
  ArtistCity = 'artist_city',
  /** column name */
  ArtistCountry = 'artist_country',
  /** column name */
  ArtistEmail = 'artist_email',
  /** column name */
  ArtistLink = 'artist_link',
  /** column name */
  ArtistName = 'artist_name',
  /** column name */
  ArtistPreferredLanguage = 'artist_preferred_language',
  /** column name */
  ArtworkCurrency = 'artwork_currency',
  /** column name */
  ArtworkLink = 'artwork_link',
  /** column name */
  ArtworkPrice = 'artwork_price',
  /** column name */
  Date = 'date',
  /** column name */
  PurchaseInquireId = 'purchase_inquire_id',
  /** column name */
  SenderEmail = 'sender_email',
  /** column name */
  SenderLink = 'sender_link',
  /** column name */
  SenderName = 'sender_name',
  /** column name */
  SenderPreferredLanguage = 'sender_preferred_language'
}

/** aggregate stddev on columns */
export type Outside_Purchase_Inquiries_View_Stddev_Fields = {
  __typename?: 'outside_purchase_inquiries_view_stddev_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Outside_Purchase_Inquiries_View_Stddev_Pop_Fields = {
  __typename?: 'outside_purchase_inquiries_view_stddev_pop_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Outside_Purchase_Inquiries_View_Stddev_Samp_Fields = {
  __typename?: 'outside_purchase_inquiries_view_stddev_samp_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Outside_Purchase_Inquiries_View_Sum_Fields = {
  __typename?: 'outside_purchase_inquiries_view_sum_fields';
  artwork_price?: Maybe<Scalars['float8']>;
  purchase_inquire_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Outside_Purchase_Inquiries_View_Var_Pop_Fields = {
  __typename?: 'outside_purchase_inquiries_view_var_pop_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Outside_Purchase_Inquiries_View_Var_Samp_Fields = {
  __typename?: 'outside_purchase_inquiries_view_var_samp_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Outside_Purchase_Inquiries_View_Variance_Fields = {
  __typename?: 'outside_purchase_inquiries_view_variance_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  purchase_inquire_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "owner_artwork_view" */
export type Owner_Artwork_View = {
  __typename?: 'owner_artwork_view';
  /** An object relationship */
  artist_context?: Maybe<Context>;
  artist_context_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  artwork_details?: Maybe<Artwork_Details>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  is_hard_minted?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  is_transaction_locked?: Maybe<Scalars['Boolean']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  transaction_histories: Array<Artwork_Transactions>;
  /** An aggregate relationship */
  transaction_histories_aggregate: Artwork_Transactions_Aggregate;
  transactions_history_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};


/** columns and relationships of "owner_artwork_view" */
export type Owner_Artwork_ViewTransaction_HistoriesArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


/** columns and relationships of "owner_artwork_view" */
export type Owner_Artwork_ViewTransaction_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};

/** aggregated selection of "owner_artwork_view" */
export type Owner_Artwork_View_Aggregate = {
  __typename?: 'owner_artwork_view_aggregate';
  aggregate?: Maybe<Owner_Artwork_View_Aggregate_Fields>;
  nodes: Array<Owner_Artwork_View>;
};

/** aggregate fields of "owner_artwork_view" */
export type Owner_Artwork_View_Aggregate_Fields = {
  __typename?: 'owner_artwork_view_aggregate_fields';
  avg?: Maybe<Owner_Artwork_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Owner_Artwork_View_Max_Fields>;
  min?: Maybe<Owner_Artwork_View_Min_Fields>;
  stddev?: Maybe<Owner_Artwork_View_Stddev_Fields>;
  stddev_pop?: Maybe<Owner_Artwork_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Owner_Artwork_View_Stddev_Samp_Fields>;
  sum?: Maybe<Owner_Artwork_View_Sum_Fields>;
  var_pop?: Maybe<Owner_Artwork_View_Var_Pop_Fields>;
  var_samp?: Maybe<Owner_Artwork_View_Var_Samp_Fields>;
  variance?: Maybe<Owner_Artwork_View_Variance_Fields>;
};


/** aggregate fields of "owner_artwork_view" */
export type Owner_Artwork_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Owner_Artwork_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Owner_Artwork_View_Avg_Fields = {
  __typename?: 'owner_artwork_view_avg_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  transactions_history_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "owner_artwork_view". All fields are combined with a logical 'AND'. */
export type Owner_Artwork_View_Bool_Exp = {
  _and?: Maybe<Array<Owner_Artwork_View_Bool_Exp>>;
  _not?: Maybe<Owner_Artwork_View_Bool_Exp>;
  _or?: Maybe<Array<Owner_Artwork_View_Bool_Exp>>;
  artist_context?: Maybe<Context_Bool_Exp>;
  artist_context_id?: Maybe<Bigint_Comparison_Exp>;
  artwork_details?: Maybe<Artwork_Details_Bool_Exp>;
  artwork_details_id?: Maybe<Bigint_Comparison_Exp>;
  edition_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_hard_minted?: Maybe<Boolean_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  is_transaction_locked?: Maybe<Boolean_Comparison_Exp>;
  owner_context_id?: Maybe<Bigint_Comparison_Exp>;
  primary_image_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  published_date?: Maybe<Timestamptz_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
  thumbnail_metadata_id?: Maybe<Bigint_Comparison_Exp>;
  transaction_histories?: Maybe<Artwork_Transactions_Bool_Exp>;
  transactions_history_id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Owner_Artwork_View_Max_Fields = {
  __typename?: 'owner_artwork_view_max_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  transactions_history_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Owner_Artwork_View_Min_Fields = {
  __typename?: 'owner_artwork_view_min_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  edition_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  published_date?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  transactions_history_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "owner_artwork_view". */
export type Owner_Artwork_View_Order_By = {
  artist_context?: Maybe<Context_Order_By>;
  artist_context_id?: Maybe<Order_By>;
  artwork_details?: Maybe<Artwork_Details_Order_By>;
  artwork_details_id?: Maybe<Order_By>;
  edition_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_hard_minted?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  is_transaction_locked?: Maybe<Order_By>;
  owner_context_id?: Maybe<Order_By>;
  primary_image_metadata_id?: Maybe<Order_By>;
  published_date?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
  thumbnail_metadata_id?: Maybe<Order_By>;
  transaction_histories_aggregate?: Maybe<Artwork_Transactions_Aggregate_Order_By>;
  transactions_history_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "owner_artwork_view" */
export enum Owner_Artwork_View_Select_Column {
  /** column name */
  ArtistContextId = 'artist_context_id',
  /** column name */
  ArtworkDetailsId = 'artwork_details_id',
  /** column name */
  EditionType = 'edition_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsHardMinted = 'is_hard_minted',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  IsTransactionLocked = 'is_transaction_locked',
  /** column name */
  OwnerContextId = 'owner_context_id',
  /** column name */
  PrimaryImageMetadataId = 'primary_image_metadata_id',
  /** column name */
  PublishedDate = 'published_date',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThumbnailMetadataId = 'thumbnail_metadata_id',
  /** column name */
  TransactionsHistoryId = 'transactions_history_id',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Owner_Artwork_View_Stddev_Fields = {
  __typename?: 'owner_artwork_view_stddev_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  transactions_history_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Owner_Artwork_View_Stddev_Pop_Fields = {
  __typename?: 'owner_artwork_view_stddev_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  transactions_history_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Owner_Artwork_View_Stddev_Samp_Fields = {
  __typename?: 'owner_artwork_view_stddev_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  transactions_history_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Owner_Artwork_View_Sum_Fields = {
  __typename?: 'owner_artwork_view_sum_fields';
  artist_context_id?: Maybe<Scalars['bigint']>;
  artwork_details_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  owner_context_id?: Maybe<Scalars['bigint']>;
  primary_image_metadata_id?: Maybe<Scalars['bigint']>;
  thumbnail_metadata_id?: Maybe<Scalars['bigint']>;
  transactions_history_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Owner_Artwork_View_Var_Pop_Fields = {
  __typename?: 'owner_artwork_view_var_pop_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  transactions_history_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Owner_Artwork_View_Var_Samp_Fields = {
  __typename?: 'owner_artwork_view_var_samp_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  transactions_history_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Owner_Artwork_View_Variance_Fields = {
  __typename?: 'owner_artwork_view_variance_fields';
  artist_context_id?: Maybe<Scalars['Float']>;
  artwork_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  owner_context_id?: Maybe<Scalars['Float']>;
  primary_image_metadata_id?: Maybe<Scalars['Float']>;
  thumbnail_metadata_id?: Maybe<Scalars['Float']>;
  transactions_history_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payment_captures_view" */
export type Payment_Captures_View = {
  __typename?: 'payment_captures_view';
  artist_city?: Maybe<Scalars['String']>;
  artist_country?: Maybe<Scalars['String']>;
  artist_email?: Maybe<Scalars['String']>;
  artist_link?: Maybe<Scalars['String']>;
  artist_name?: Maybe<Scalars['String']>;
  artist_preferred_language?: Maybe<Scalars['String']>;
  artwork_currency?: Maybe<Scalars['String']>;
  artwork_link?: Maybe<Scalars['String']>;
  artwork_price?: Maybe<Scalars['numeric']>;
  buyer_city?: Maybe<Scalars['String']>;
  buyer_country?: Maybe<Scalars['String']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_name?: Maybe<Scalars['String']>;
  buyer_preferred_language?: Maybe<Scalars['String']>;
  buyer_profile?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  payment_capture_id?: Maybe<Scalars['bigint']>;
  payment_status?: Maybe<Scalars['String']>;
  shipping_fee?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "payment_captures_view" */
export type Payment_Captures_View_Aggregate = {
  __typename?: 'payment_captures_view_aggregate';
  aggregate?: Maybe<Payment_Captures_View_Aggregate_Fields>;
  nodes: Array<Payment_Captures_View>;
};

/** aggregate fields of "payment_captures_view" */
export type Payment_Captures_View_Aggregate_Fields = {
  __typename?: 'payment_captures_view_aggregate_fields';
  avg?: Maybe<Payment_Captures_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Captures_View_Max_Fields>;
  min?: Maybe<Payment_Captures_View_Min_Fields>;
  stddev?: Maybe<Payment_Captures_View_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Captures_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Captures_View_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Captures_View_Sum_Fields>;
  var_pop?: Maybe<Payment_Captures_View_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Captures_View_Var_Samp_Fields>;
  variance?: Maybe<Payment_Captures_View_Variance_Fields>;
};


/** aggregate fields of "payment_captures_view" */
export type Payment_Captures_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Captures_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_Captures_View_Avg_Fields = {
  __typename?: 'payment_captures_view_avg_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  payment_capture_id?: Maybe<Scalars['Float']>;
  shipping_fee?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payment_captures_view". All fields are combined with a logical 'AND'. */
export type Payment_Captures_View_Bool_Exp = {
  _and?: Maybe<Array<Payment_Captures_View_Bool_Exp>>;
  _not?: Maybe<Payment_Captures_View_Bool_Exp>;
  _or?: Maybe<Array<Payment_Captures_View_Bool_Exp>>;
  artist_city?: Maybe<String_Comparison_Exp>;
  artist_country?: Maybe<String_Comparison_Exp>;
  artist_email?: Maybe<String_Comparison_Exp>;
  artist_link?: Maybe<String_Comparison_Exp>;
  artist_name?: Maybe<String_Comparison_Exp>;
  artist_preferred_language?: Maybe<String_Comparison_Exp>;
  artwork_currency?: Maybe<String_Comparison_Exp>;
  artwork_link?: Maybe<String_Comparison_Exp>;
  artwork_price?: Maybe<Numeric_Comparison_Exp>;
  buyer_city?: Maybe<String_Comparison_Exp>;
  buyer_country?: Maybe<String_Comparison_Exp>;
  buyer_email?: Maybe<String_Comparison_Exp>;
  buyer_name?: Maybe<String_Comparison_Exp>;
  buyer_preferred_language?: Maybe<String_Comparison_Exp>;
  buyer_profile?: Maybe<String_Comparison_Exp>;
  date?: Maybe<Timestamptz_Comparison_Exp>;
  payment_capture_id?: Maybe<Bigint_Comparison_Exp>;
  payment_status?: Maybe<String_Comparison_Exp>;
  shipping_fee?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Payment_Captures_View_Max_Fields = {
  __typename?: 'payment_captures_view_max_fields';
  artist_city?: Maybe<Scalars['String']>;
  artist_country?: Maybe<Scalars['String']>;
  artist_email?: Maybe<Scalars['String']>;
  artist_link?: Maybe<Scalars['String']>;
  artist_name?: Maybe<Scalars['String']>;
  artist_preferred_language?: Maybe<Scalars['String']>;
  artwork_currency?: Maybe<Scalars['String']>;
  artwork_link?: Maybe<Scalars['String']>;
  artwork_price?: Maybe<Scalars['numeric']>;
  buyer_city?: Maybe<Scalars['String']>;
  buyer_country?: Maybe<Scalars['String']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_name?: Maybe<Scalars['String']>;
  buyer_preferred_language?: Maybe<Scalars['String']>;
  buyer_profile?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  payment_capture_id?: Maybe<Scalars['bigint']>;
  payment_status?: Maybe<Scalars['String']>;
  shipping_fee?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Payment_Captures_View_Min_Fields = {
  __typename?: 'payment_captures_view_min_fields';
  artist_city?: Maybe<Scalars['String']>;
  artist_country?: Maybe<Scalars['String']>;
  artist_email?: Maybe<Scalars['String']>;
  artist_link?: Maybe<Scalars['String']>;
  artist_name?: Maybe<Scalars['String']>;
  artist_preferred_language?: Maybe<Scalars['String']>;
  artwork_currency?: Maybe<Scalars['String']>;
  artwork_link?: Maybe<Scalars['String']>;
  artwork_price?: Maybe<Scalars['numeric']>;
  buyer_city?: Maybe<Scalars['String']>;
  buyer_country?: Maybe<Scalars['String']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_name?: Maybe<Scalars['String']>;
  buyer_preferred_language?: Maybe<Scalars['String']>;
  buyer_profile?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  payment_capture_id?: Maybe<Scalars['bigint']>;
  payment_status?: Maybe<Scalars['String']>;
  shipping_fee?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "payment_captures_view". */
export type Payment_Captures_View_Order_By = {
  artist_city?: Maybe<Order_By>;
  artist_country?: Maybe<Order_By>;
  artist_email?: Maybe<Order_By>;
  artist_link?: Maybe<Order_By>;
  artist_name?: Maybe<Order_By>;
  artist_preferred_language?: Maybe<Order_By>;
  artwork_currency?: Maybe<Order_By>;
  artwork_link?: Maybe<Order_By>;
  artwork_price?: Maybe<Order_By>;
  buyer_city?: Maybe<Order_By>;
  buyer_country?: Maybe<Order_By>;
  buyer_email?: Maybe<Order_By>;
  buyer_name?: Maybe<Order_By>;
  buyer_preferred_language?: Maybe<Order_By>;
  buyer_profile?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  payment_capture_id?: Maybe<Order_By>;
  payment_status?: Maybe<Order_By>;
  shipping_fee?: Maybe<Order_By>;
};

/** select columns of table "payment_captures_view" */
export enum Payment_Captures_View_Select_Column {
  /** column name */
  ArtistCity = 'artist_city',
  /** column name */
  ArtistCountry = 'artist_country',
  /** column name */
  ArtistEmail = 'artist_email',
  /** column name */
  ArtistLink = 'artist_link',
  /** column name */
  ArtistName = 'artist_name',
  /** column name */
  ArtistPreferredLanguage = 'artist_preferred_language',
  /** column name */
  ArtworkCurrency = 'artwork_currency',
  /** column name */
  ArtworkLink = 'artwork_link',
  /** column name */
  ArtworkPrice = 'artwork_price',
  /** column name */
  BuyerCity = 'buyer_city',
  /** column name */
  BuyerCountry = 'buyer_country',
  /** column name */
  BuyerEmail = 'buyer_email',
  /** column name */
  BuyerName = 'buyer_name',
  /** column name */
  BuyerPreferredLanguage = 'buyer_preferred_language',
  /** column name */
  BuyerProfile = 'buyer_profile',
  /** column name */
  Date = 'date',
  /** column name */
  PaymentCaptureId = 'payment_capture_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  ShippingFee = 'shipping_fee'
}

/** aggregate stddev on columns */
export type Payment_Captures_View_Stddev_Fields = {
  __typename?: 'payment_captures_view_stddev_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  payment_capture_id?: Maybe<Scalars['Float']>;
  shipping_fee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_Captures_View_Stddev_Pop_Fields = {
  __typename?: 'payment_captures_view_stddev_pop_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  payment_capture_id?: Maybe<Scalars['Float']>;
  shipping_fee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_Captures_View_Stddev_Samp_Fields = {
  __typename?: 'payment_captures_view_stddev_samp_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  payment_capture_id?: Maybe<Scalars['Float']>;
  shipping_fee?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Payment_Captures_View_Sum_Fields = {
  __typename?: 'payment_captures_view_sum_fields';
  artwork_price?: Maybe<Scalars['numeric']>;
  payment_capture_id?: Maybe<Scalars['bigint']>;
  shipping_fee?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Payment_Captures_View_Var_Pop_Fields = {
  __typename?: 'payment_captures_view_var_pop_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  payment_capture_id?: Maybe<Scalars['Float']>;
  shipping_fee?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_Captures_View_Var_Samp_Fields = {
  __typename?: 'payment_captures_view_var_samp_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  payment_capture_id?: Maybe<Scalars['Float']>;
  shipping_fee?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_Captures_View_Variance_Fields = {
  __typename?: 'payment_captures_view_variance_fields';
  artwork_price?: Maybe<Scalars['Float']>;
  payment_capture_id?: Maybe<Scalars['Float']>;
  shipping_fee?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "pending_forge_task_view" */
export type Pending_Forge_Task_View = {
  __typename?: 'pending_forge_task_view';
  id?: Maybe<Scalars['bigint']>;
  payload?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pending_forge_task_view" */
export type Pending_Forge_Task_View_Aggregate = {
  __typename?: 'pending_forge_task_view_aggregate';
  aggregate?: Maybe<Pending_Forge_Task_View_Aggregate_Fields>;
  nodes: Array<Pending_Forge_Task_View>;
};

/** aggregate fields of "pending_forge_task_view" */
export type Pending_Forge_Task_View_Aggregate_Fields = {
  __typename?: 'pending_forge_task_view_aggregate_fields';
  avg?: Maybe<Pending_Forge_Task_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pending_Forge_Task_View_Max_Fields>;
  min?: Maybe<Pending_Forge_Task_View_Min_Fields>;
  stddev?: Maybe<Pending_Forge_Task_View_Stddev_Fields>;
  stddev_pop?: Maybe<Pending_Forge_Task_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pending_Forge_Task_View_Stddev_Samp_Fields>;
  sum?: Maybe<Pending_Forge_Task_View_Sum_Fields>;
  var_pop?: Maybe<Pending_Forge_Task_View_Var_Pop_Fields>;
  var_samp?: Maybe<Pending_Forge_Task_View_Var_Samp_Fields>;
  variance?: Maybe<Pending_Forge_Task_View_Variance_Fields>;
};


/** aggregate fields of "pending_forge_task_view" */
export type Pending_Forge_Task_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pending_Forge_Task_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Pending_Forge_Task_View_Avg_Fields = {
  __typename?: 'pending_forge_task_view_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "pending_forge_task_view". All fields are combined with a logical 'AND'. */
export type Pending_Forge_Task_View_Bool_Exp = {
  _and?: Maybe<Array<Pending_Forge_Task_View_Bool_Exp>>;
  _not?: Maybe<Pending_Forge_Task_View_Bool_Exp>;
  _or?: Maybe<Array<Pending_Forge_Task_View_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  payload?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Pending_Forge_Task_View_Max_Fields = {
  __typename?: 'pending_forge_task_view_max_fields';
  id?: Maybe<Scalars['bigint']>;
  payload?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Pending_Forge_Task_View_Min_Fields = {
  __typename?: 'pending_forge_task_view_min_fields';
  id?: Maybe<Scalars['bigint']>;
  payload?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "pending_forge_task_view". */
export type Pending_Forge_Task_View_Order_By = {
  id?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
};

/** select columns of table "pending_forge_task_view" */
export enum Pending_Forge_Task_View_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload'
}

/** aggregate stddev on columns */
export type Pending_Forge_Task_View_Stddev_Fields = {
  __typename?: 'pending_forge_task_view_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Pending_Forge_Task_View_Stddev_Pop_Fields = {
  __typename?: 'pending_forge_task_view_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Pending_Forge_Task_View_Stddev_Samp_Fields = {
  __typename?: 'pending_forge_task_view_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Pending_Forge_Task_View_Sum_Fields = {
  __typename?: 'pending_forge_task_view_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Pending_Forge_Task_View_Var_Pop_Fields = {
  __typename?: 'pending_forge_task_view_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Pending_Forge_Task_View_Var_Samp_Fields = {
  __typename?: 'pending_forge_task_view_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Pending_Forge_Task_View_Variance_Fields = {
  __typename?: 'pending_forge_task_view_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "print_availability" */
export type Print_Availability = {
  __typename?: 'print_availability';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "print_availability" */
export type Print_Availability_Aggregate = {
  __typename?: 'print_availability_aggregate';
  aggregate?: Maybe<Print_Availability_Aggregate_Fields>;
  nodes: Array<Print_Availability>;
};

/** aggregate fields of "print_availability" */
export type Print_Availability_Aggregate_Fields = {
  __typename?: 'print_availability_aggregate_fields';
  avg?: Maybe<Print_Availability_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Print_Availability_Max_Fields>;
  min?: Maybe<Print_Availability_Min_Fields>;
  stddev?: Maybe<Print_Availability_Stddev_Fields>;
  stddev_pop?: Maybe<Print_Availability_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Print_Availability_Stddev_Samp_Fields>;
  sum?: Maybe<Print_Availability_Sum_Fields>;
  var_pop?: Maybe<Print_Availability_Var_Pop_Fields>;
  var_samp?: Maybe<Print_Availability_Var_Samp_Fields>;
  variance?: Maybe<Print_Availability_Variance_Fields>;
};


/** aggregate fields of "print_availability" */
export type Print_Availability_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Print_Availability_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Print_Availability_Avg_Fields = {
  __typename?: 'print_availability_avg_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "print_availability". All fields are combined with a logical 'AND'. */
export type Print_Availability_Bool_Exp = {
  _and?: Maybe<Array<Print_Availability_Bool_Exp>>;
  _not?: Maybe<Print_Availability_Bool_Exp>;
  _or?: Maybe<Array<Print_Availability_Bool_Exp>>;
  artwork_id?: Maybe<Bigint_Comparison_Exp>;
  available_print_amount?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Print_Availability_Max_Fields = {
  __typename?: 'print_availability_max_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Print_Availability_Min_Fields = {
  __typename?: 'print_availability_min_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "print_availability". */
export type Print_Availability_Order_By = {
  artwork_id?: Maybe<Order_By>;
  available_print_amount?: Maybe<Order_By>;
};

/** select columns of table "print_availability" */
export enum Print_Availability_Select_Column {
  /** column name */
  ArtworkId = 'artwork_id',
  /** column name */
  AvailablePrintAmount = 'available_print_amount'
}

/** aggregate stddev on columns */
export type Print_Availability_Stddev_Fields = {
  __typename?: 'print_availability_stddev_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Print_Availability_Stddev_Pop_Fields = {
  __typename?: 'print_availability_stddev_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Print_Availability_Stddev_Samp_Fields = {
  __typename?: 'print_availability_stddev_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Print_Availability_Sum_Fields = {
  __typename?: 'print_availability_sum_fields';
  artwork_id?: Maybe<Scalars['bigint']>;
  available_print_amount?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Print_Availability_Var_Pop_Fields = {
  __typename?: 'print_availability_var_pop_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Print_Availability_Var_Samp_Fields = {
  __typename?: 'print_availability_var_samp_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Print_Availability_Variance_Fields = {
  __typename?: 'print_availability_variance_fields';
  artwork_id?: Maybe<Scalars['Float']>;
  available_print_amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "profile_backgrounds_view" */
export type Profile_Backgrounds_View = {
  __typename?: 'profile_backgrounds_view';
  background_type?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "profile_backgrounds_view" */
export type Profile_Backgrounds_View_Aggregate = {
  __typename?: 'profile_backgrounds_view_aggregate';
  aggregate?: Maybe<Profile_Backgrounds_View_Aggregate_Fields>;
  nodes: Array<Profile_Backgrounds_View>;
};

/** aggregate fields of "profile_backgrounds_view" */
export type Profile_Backgrounds_View_Aggregate_Fields = {
  __typename?: 'profile_backgrounds_view_aggregate_fields';
  avg?: Maybe<Profile_Backgrounds_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Backgrounds_View_Max_Fields>;
  min?: Maybe<Profile_Backgrounds_View_Min_Fields>;
  stddev?: Maybe<Profile_Backgrounds_View_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Backgrounds_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Backgrounds_View_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Backgrounds_View_Sum_Fields>;
  var_pop?: Maybe<Profile_Backgrounds_View_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Backgrounds_View_Var_Samp_Fields>;
  variance?: Maybe<Profile_Backgrounds_View_Variance_Fields>;
};


/** aggregate fields of "profile_backgrounds_view" */
export type Profile_Backgrounds_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Backgrounds_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Backgrounds_View_Avg_Fields = {
  __typename?: 'profile_backgrounds_view_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "profile_backgrounds_view". All fields are combined with a logical 'AND'. */
export type Profile_Backgrounds_View_Bool_Exp = {
  _and?: Maybe<Array<Profile_Backgrounds_View_Bool_Exp>>;
  _not?: Maybe<Profile_Backgrounds_View_Bool_Exp>;
  _or?: Maybe<Array<Profile_Backgrounds_View_Bool_Exp>>;
  background_type?: Maybe<String_Comparison_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Profile_Backgrounds_View_Max_Fields = {
  __typename?: 'profile_backgrounds_view_max_fields';
  background_type?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Profile_Backgrounds_View_Min_Fields = {
  __typename?: 'profile_backgrounds_view_min_fields';
  background_type?: Maybe<Scalars['String']>;
  context_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "profile_backgrounds_view". */
export type Profile_Backgrounds_View_Order_By = {
  background_type?: Maybe<Order_By>;
  context_id?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** select columns of table "profile_backgrounds_view" */
export enum Profile_Backgrounds_View_Select_Column {
  /** column name */
  BackgroundType = 'background_type',
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type Profile_Backgrounds_View_Stddev_Fields = {
  __typename?: 'profile_backgrounds_view_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Backgrounds_View_Stddev_Pop_Fields = {
  __typename?: 'profile_backgrounds_view_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Backgrounds_View_Stddev_Samp_Fields = {
  __typename?: 'profile_backgrounds_view_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Profile_Backgrounds_View_Sum_Fields = {
  __typename?: 'profile_backgrounds_view_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Profile_Backgrounds_View_Var_Pop_Fields = {
  __typename?: 'profile_backgrounds_view_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Backgrounds_View_Var_Samp_Fields = {
  __typename?: 'profile_backgrounds_view_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Backgrounds_View_Variance_Fields = {
  __typename?: 'profile_backgrounds_view_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "profile_interests_view" */
export type Profile_Interests_View = {
  __typename?: 'profile_interests_view';
  context_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "profile_interests_view" */
export type Profile_Interests_View_Aggregate = {
  __typename?: 'profile_interests_view_aggregate';
  aggregate?: Maybe<Profile_Interests_View_Aggregate_Fields>;
  nodes: Array<Profile_Interests_View>;
};

/** aggregate fields of "profile_interests_view" */
export type Profile_Interests_View_Aggregate_Fields = {
  __typename?: 'profile_interests_view_aggregate_fields';
  avg?: Maybe<Profile_Interests_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Interests_View_Max_Fields>;
  min?: Maybe<Profile_Interests_View_Min_Fields>;
  stddev?: Maybe<Profile_Interests_View_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Interests_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Interests_View_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Interests_View_Sum_Fields>;
  var_pop?: Maybe<Profile_Interests_View_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Interests_View_Var_Samp_Fields>;
  variance?: Maybe<Profile_Interests_View_Variance_Fields>;
};


/** aggregate fields of "profile_interests_view" */
export type Profile_Interests_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Interests_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Profile_Interests_View_Avg_Fields = {
  __typename?: 'profile_interests_view_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "profile_interests_view". All fields are combined with a logical 'AND'. */
export type Profile_Interests_View_Bool_Exp = {
  _and?: Maybe<Array<Profile_Interests_View_Bool_Exp>>;
  _not?: Maybe<Profile_Interests_View_Bool_Exp>;
  _or?: Maybe<Array<Profile_Interests_View_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  handle?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  translation_key?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Profile_Interests_View_Max_Fields = {
  __typename?: 'profile_interests_view_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Profile_Interests_View_Min_Fields = {
  __typename?: 'profile_interests_view_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  handle?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image_url?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  translation_key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "profile_interests_view". */
export type Profile_Interests_View_Order_By = {
  context_id?: Maybe<Order_By>;
  handle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  translation_key?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "profile_interests_view" */
export enum Profile_Interests_View_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Order = 'order',
  /** column name */
  TranslationKey = 'translation_key',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Profile_Interests_View_Stddev_Fields = {
  __typename?: 'profile_interests_view_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Profile_Interests_View_Stddev_Pop_Fields = {
  __typename?: 'profile_interests_view_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Profile_Interests_View_Stddev_Samp_Fields = {
  __typename?: 'profile_interests_view_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Profile_Interests_View_Sum_Fields = {
  __typename?: 'profile_interests_view_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Profile_Interests_View_Var_Pop_Fields = {
  __typename?: 'profile_interests_view_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Profile_Interests_View_Var_Samp_Fields = {
  __typename?: 'profile_interests_view_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Profile_Interests_View_Variance_Fields = {
  __typename?: 'profile_interests_view_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** Holds info about the phone of a gallery, artist or other accounts */
export type Profile_Phones = {
  __typename?: 'profile_phones';
  /** An object relationship */
  context: Context;
  context_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  phone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile_phones" */
export type Profile_Phones_Aggregate = {
  __typename?: 'profile_phones_aggregate';
  aggregate?: Maybe<Profile_Phones_Aggregate_Fields>;
  nodes: Array<Profile_Phones>;
};

/** aggregate fields of "profile_phones" */
export type Profile_Phones_Aggregate_Fields = {
  __typename?: 'profile_phones_aggregate_fields';
  avg?: Maybe<Profile_Phones_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Profile_Phones_Max_Fields>;
  min?: Maybe<Profile_Phones_Min_Fields>;
  stddev?: Maybe<Profile_Phones_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Phones_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Phones_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Phones_Sum_Fields>;
  var_pop?: Maybe<Profile_Phones_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Phones_Var_Samp_Fields>;
  variance?: Maybe<Profile_Phones_Variance_Fields>;
};


/** aggregate fields of "profile_phones" */
export type Profile_Phones_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Phones_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile_phones" */
export type Profile_Phones_Aggregate_Order_By = {
  avg?: Maybe<Profile_Phones_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Profile_Phones_Max_Order_By>;
  min?: Maybe<Profile_Phones_Min_Order_By>;
  stddev?: Maybe<Profile_Phones_Stddev_Order_By>;
  stddev_pop?: Maybe<Profile_Phones_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Profile_Phones_Stddev_Samp_Order_By>;
  sum?: Maybe<Profile_Phones_Sum_Order_By>;
  var_pop?: Maybe<Profile_Phones_Var_Pop_Order_By>;
  var_samp?: Maybe<Profile_Phones_Var_Samp_Order_By>;
  variance?: Maybe<Profile_Phones_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "profile_phones" */
export type Profile_Phones_Arr_Rel_Insert_Input = {
  data: Array<Profile_Phones_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Profile_Phones_On_Conflict>;
};

/** aggregate avg on columns */
export type Profile_Phones_Avg_Fields = {
  __typename?: 'profile_phones_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "profile_phones" */
export type Profile_Phones_Avg_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile_phones". All fields are combined with a logical 'AND'. */
export type Profile_Phones_Bool_Exp = {
  _and?: Maybe<Array<Profile_Phones_Bool_Exp>>;
  _not?: Maybe<Profile_Phones_Bool_Exp>;
  _or?: Maybe<Array<Profile_Phones_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_phones" */
export enum Profile_Phones_Constraint {
  /** unique or primary key constraint on columns "context_id", "phone" */
  ProfilePhonesContextIdPhoneKey = 'profile_phones_context_id_phone_key',
  /** unique or primary key constraint on columns "id" */
  ProfilePhonesPkey = 'profile_phones_pkey'
}

/** input type for incrementing numeric columns in table "profile_phones" */
export type Profile_Phones_Inc_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "profile_phones" */
export type Profile_Phones_Insert_Input = {
  context?: Maybe<Context_Obj_Rel_Insert_Input>;
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Phones_Max_Fields = {
  __typename?: 'profile_phones_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "profile_phones" */
export type Profile_Phones_Max_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Phones_Min_Fields = {
  __typename?: 'profile_phones_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "profile_phones" */
export type Profile_Phones_Min_Order_By = {
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "profile_phones" */
export type Profile_Phones_Mutation_Response = {
  __typename?: 'profile_phones_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Phones>;
};

/** on_conflict condition type for table "profile_phones" */
export type Profile_Phones_On_Conflict = {
  constraint: Profile_Phones_Constraint;
  update_columns?: Array<Profile_Phones_Update_Column>;
  where?: Maybe<Profile_Phones_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_phones". */
export type Profile_Phones_Order_By = {
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_phones */
export type Profile_Phones_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "profile_phones" */
export enum Profile_Phones_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile_phones" */
export type Profile_Phones_Set_Input = {
  context_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Profile_Phones_Stddev_Fields = {
  __typename?: 'profile_phones_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "profile_phones" */
export type Profile_Phones_Stddev_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Phones_Stddev_Pop_Fields = {
  __typename?: 'profile_phones_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "profile_phones" */
export type Profile_Phones_Stddev_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Phones_Stddev_Samp_Fields = {
  __typename?: 'profile_phones_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "profile_phones" */
export type Profile_Phones_Stddev_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Phones_Sum_Fields = {
  __typename?: 'profile_phones_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "profile_phones" */
export type Profile_Phones_Sum_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "profile_phones" */
export enum Profile_Phones_Update_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Profile_Phones_Var_Pop_Fields = {
  __typename?: 'profile_phones_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "profile_phones" */
export type Profile_Phones_Var_Pop_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Phones_Var_Samp_Fields = {
  __typename?: 'profile_phones_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "profile_phones" */
export type Profile_Phones_Var_Samp_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Phones_Variance_Fields = {
  __typename?: 'profile_phones_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "profile_phones" */
export type Profile_Phones_Variance_Order_By = {
  context_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Account_initial_profile_type" */
  Account_initial_profile_type: Array<Account_Initial_Profile_Type>;
  /** fetch aggregated fields from the table: "Account_initial_profile_type" */
  Account_initial_profile_type_aggregate: Account_Initial_Profile_Type_Aggregate;
  /** fetch data from the table: "Account_initial_profile_type" using primary key columns */
  Account_initial_profile_type_by_pk?: Maybe<Account_Initial_Profile_Type>;
  /** fetch data from the table: "Account_notice_ack" */
  Account_notice_ack: Array<Account_Notice_Ack>;
  /** fetch aggregated fields from the table: "Account_notice_ack" */
  Account_notice_ack_aggregate: Account_Notice_Ack_Aggregate;
  /** fetch data from the table: "Account_notice_ack" using primary key columns */
  Account_notice_ack_by_pk?: Maybe<Account_Notice_Ack>;
  /** fetch data from the table: "Account_validation" */
  Account_validation: Array<Account_Validation>;
  /** fetch aggregated fields from the table: "Account_validation" */
  Account_validation_aggregate: Account_Validation_Aggregate;
  /** fetch data from the table: "Account_validation" using primary key columns */
  Account_validation_by_pk?: Maybe<Account_Validation>;
  /** fetch data from the table: "Achievement_hooks" */
  Achievement_hooks: Array<Achievement_Hooks>;
  /** fetch aggregated fields from the table: "Achievement_hooks" */
  Achievement_hooks_aggregate: Achievement_Hooks_Aggregate;
  /** fetch data from the table: "Achievement_hooks" using primary key columns */
  Achievement_hooks_by_pk?: Maybe<Achievement_Hooks>;
  /** fetch data from the table: "Achievement_tags" */
  Achievement_tags: Array<Achievement_Tags>;
  /** fetch aggregated fields from the table: "Achievement_tags" */
  Achievement_tags_aggregate: Achievement_Tags_Aggregate;
  /** fetch data from the table: "Achievement_tags" using primary key columns */
  Achievement_tags_by_pk?: Maybe<Achievement_Tags>;
  /** fetch data from the table: "Achievements" */
  Achievements: Array<Achievements>;
  /** fetch aggregated fields from the table: "Achievements" */
  Achievements_aggregate: Achievements_Aggregate;
  /** fetch data from the table: "Achievements" using primary key columns */
  Achievements_by_pk?: Maybe<Achievements>;
  AdminLoginToken?: Maybe<Scalars['String']>;
  /** fetch data from the table: "Admin_password" */
  Admin_password: Array<Admin_Password>;
  /** fetch aggregated fields from the table: "Admin_password" */
  Admin_password_aggregate: Admin_Password_Aggregate;
  /** fetch data from the table: "Admin_password" using primary key columns */
  Admin_password_by_pk?: Maybe<Admin_Password>;
  /** fetch data from the table: "Admin_table_profiles" */
  Admin_table_profiles: Array<Admin_Table_Profiles>;
  /** fetch aggregated fields from the table: "Admin_table_profiles" */
  Admin_table_profiles_aggregate: Admin_Table_Profiles_Aggregate;
  /** fetch data from the table: "Admin_table_profiles_v2" */
  Admin_table_profiles_v2: Array<Admin_Table_Profiles_V2>;
  /** fetch aggregated fields from the table: "Admin_table_profiles_v2" */
  Admin_table_profiles_v2_aggregate: Admin_Table_Profiles_V2_Aggregate;
  /** fetch data from the table: "Admin_to_user_notification" */
  Admin_to_user_notification: Array<Admin_To_User_Notification>;
  /** fetch aggregated fields from the table: "Admin_to_user_notification" */
  Admin_to_user_notification_aggregate: Admin_To_User_Notification_Aggregate;
  /** fetch data from the table: "Admin_to_user_notification" using primary key columns */
  Admin_to_user_notification_by_pk?: Maybe<Admin_To_User_Notification>;
  /** fetch data from the table: "All_artists" */
  All_artists: Array<All_Artists>;
  /** fetch aggregated fields from the table: "All_artists" */
  All_artists_aggregate: All_Artists_Aggregate;
  /** fetch data from the table: "All_artwork_availability" */
  All_artwork_availability: Array<All_Artwork_Availability>;
  /** fetch aggregated fields from the table: "All_artwork_availability" */
  All_artwork_availability_aggregate: All_Artwork_Availability_Aggregate;
  /** fetch data from the table: "All_artwork_availability_table" */
  All_artwork_availability_table: Array<All_Artwork_Availability_Table>;
  /** fetch aggregated fields from the table: "All_artwork_availability_table" */
  All_artwork_availability_table_aggregate: All_Artwork_Availability_Table_Aggregate;
  /** fetch data from the table: "All_artwork_availability_table" using primary key columns */
  All_artwork_availability_table_by_pk?: Maybe<All_Artwork_Availability_Table>;
  /** fetch data from the table: "All_collectors" */
  All_collectors: Array<All_Collectors>;
  /** fetch aggregated fields from the table: "All_collectors" */
  All_collectors_aggregate: All_Collectors_Aggregate;
  /** fetch data from the table: "All_galleries" */
  All_galleries: Array<All_Galleries>;
  /** fetch aggregated fields from the table: "All_galleries" */
  All_galleries_aggregate: All_Galleries_Aggregate;
  /** fetch data from the table: "Analytics_events" */
  Analytics_events: Array<Analytics_Events>;
  /** fetch aggregated fields from the table: "Analytics_events" */
  Analytics_events_aggregate: Analytics_Events_Aggregate;
  /** fetch data from the table: "Analytics_events" using primary key columns */
  Analytics_events_by_pk?: Maybe<Analytics_Events>;
  /** fetch data from the table: "Analytics_index_history" */
  Analytics_index_history: Array<Analytics_Index_History>;
  /** fetch aggregated fields from the table: "Analytics_index_history" */
  Analytics_index_history_aggregate: Analytics_Index_History_Aggregate;
  /** fetch data from the table: "Analytics_index_history" using primary key columns */
  Analytics_index_history_by_pk?: Maybe<Analytics_Index_History>;
  /** fetch data from the table: "Analytics_index_type" */
  Analytics_index_type: Array<Analytics_Index_Type>;
  /** fetch aggregated fields from the table: "Analytics_index_type" */
  Analytics_index_type_aggregate: Analytics_Index_Type_Aggregate;
  /** fetch data from the table: "Analytics_index_type" using primary key columns */
  Analytics_index_type_by_pk?: Maybe<Analytics_Index_Type>;
  /** fetch data from the table: "Analytics_index_values" */
  Analytics_index_values: Array<Analytics_Index_Values>;
  /** fetch aggregated fields from the table: "Analytics_index_values" */
  Analytics_index_values_aggregate: Analytics_Index_Values_Aggregate;
  /** fetch data from the table: "Analytics_index_values" using primary key columns */
  Analytics_index_values_by_pk?: Maybe<Analytics_Index_Values>;
  /** fetch data from the table: "Analytics_kaleidoCard_Amounts" */
  Analytics_kaleidoCard_Amounts: Array<Analytics_KaleidoCard_Amounts>;
  /** fetch aggregated fields from the table: "Analytics_kaleidoCard_Amounts" */
  Analytics_kaleidoCard_Amounts_aggregate: Analytics_KaleidoCard_Amounts_Aggregate;
  /** fetch data from the table: "Analytics_kaleidoCard_Amounts" using primary key columns */
  Analytics_kaleidoCard_Amounts_by_pk?: Maybe<Analytics_KaleidoCard_Amounts>;
  /** An array relationship */
  Analytics_kaleidoCard_events: Array<Analytics_KaleidoCard_Events>;
  /** An aggregate relationship */
  Analytics_kaleidoCard_events_aggregate: Analytics_KaleidoCard_Events_Aggregate;
  /** fetch data from the table: "Analytics_kaleidoCard_events" using primary key columns */
  Analytics_kaleidoCard_events_by_pk?: Maybe<Analytics_KaleidoCard_Events>;
  /** fetch data from the table: "Announced_art_show" */
  Announced_art_show: Array<Announced_Art_Show>;
  /** fetch aggregated fields from the table: "Announced_art_show" */
  Announced_art_show_aggregate: Announced_Art_Show_Aggregate;
  /** fetch data from the table: "App_notifications" */
  App_notifications: Array<App_Notifications>;
  /** fetch aggregated fields from the table: "App_notifications" */
  App_notifications_aggregate: App_Notifications_Aggregate;
  /** fetch data from the table: "App_notifications" using primary key columns */
  App_notifications_by_pk?: Maybe<App_Notifications>;
  /** fetch data from the table: "Art_history_movement" */
  Art_history_movement: Array<Art_History_Movement>;
  /** fetch aggregated fields from the table: "Art_history_movement" */
  Art_history_movement_aggregate: Art_History_Movement_Aggregate;
  /** fetch data from the table: "Art_history_movement" using primary key columns */
  Art_history_movement_by_pk?: Maybe<Art_History_Movement>;
  /** fetch data from the table: "Art_prize_artwork" */
  Art_prize_artwork: Array<Art_Prize_Artwork>;
  /** fetch aggregated fields from the table: "Art_prize_artwork" */
  Art_prize_artwork_aggregate: Art_Prize_Artwork_Aggregate;
  /** fetch data from the table: "Art_prize_artwork" using primary key columns */
  Art_prize_artwork_by_pk?: Maybe<Art_Prize_Artwork>;
  /** fetch data from the table: "Art_prize_show" */
  Art_prize_show: Array<Art_Prize_Show>;
  /** fetch aggregated fields from the table: "Art_prize_show" */
  Art_prize_show_aggregate: Art_Prize_Show_Aggregate;
  /** fetch data from the table: "Art_prize_show" using primary key columns */
  Art_prize_show_by_pk?: Maybe<Art_Prize_Show>;
  /** fetch data from the table: "Art_prize_show_winners" */
  Art_prize_show_winners: Array<Art_Prize_Show_Winners>;
  /** fetch aggregated fields from the table: "Art_prize_show_winners" */
  Art_prize_show_winners_aggregate: Art_Prize_Show_Winners_Aggregate;
  /** fetch data from the table: "Art_prize_show_winners" using primary key columns */
  Art_prize_show_winners_by_pk?: Maybe<Art_Prize_Show_Winners>;
  /** fetch data from the table: "Art_prize_winner" */
  Art_prize_winner: Array<Art_Prize_Winner>;
  /** fetch aggregated fields from the table: "Art_prize_winner" */
  Art_prize_winner_aggregate: Art_Prize_Winner_Aggregate;
  /** fetch data from the table: "Art_prize_winner" using primary key columns */
  Art_prize_winner_by_pk?: Maybe<Art_Prize_Winner>;
  /** fetch data from the table: "Art_prize_winner_ranking" */
  Art_prize_winner_ranking: Array<Art_Prize_Winner_Ranking>;
  /** fetch aggregated fields from the table: "Art_prize_winner_ranking" */
  Art_prize_winner_ranking_aggregate: Art_Prize_Winner_Ranking_Aggregate;
  /** fetch data from the table: "Art_prizes_links_filtered" */
  Art_prizes_links_filtered: Array<Art_Prizes_Links_Filtered>;
  /** fetch aggregated fields from the table: "Art_prizes_links_filtered" */
  Art_prizes_links_filtered_aggregate: Art_Prizes_Links_Filtered_Aggregate;
  /** fetch data from the table: "Art_show_artwork_listing" */
  Art_show_artwork_listing: Array<Art_Show_Artwork_Listing>;
  /** fetch aggregated fields from the table: "Art_show_artwork_listing" */
  Art_show_artwork_listing_aggregate: Art_Show_Artwork_Listing_Aggregate;
  /** fetch data from the table: "Art_show_details" */
  Art_show_details: Array<Art_Show_Details>;
  /** fetch aggregated fields from the table: "Art_show_details" */
  Art_show_details_aggregate: Art_Show_Details_Aggregate;
  /** fetch data from the table: "Art_show_details" using primary key columns */
  Art_show_details_by_pk?: Maybe<Art_Show_Details>;
  /** fetch data from the table: "Art_type_to_Artwork_discipline" */
  Art_type_to_Artwork_discipline: Array<Art_Type_To_Artwork_Discipline>;
  /** fetch aggregated fields from the table: "Art_type_to_Artwork_discipline" */
  Art_type_to_Artwork_discipline_aggregate: Art_Type_To_Artwork_Discipline_Aggregate;
  /** fetch data from the table: "Art_type_to_Artwork_discipline" using primary key columns */
  Art_type_to_Artwork_discipline_by_pk?: Maybe<Art_Type_To_Artwork_Discipline>;
  /** fetch data from the table: "Artist_Collection" */
  Artist_Collection: Array<Artist_Collection>;
  /** fetch data from the table: "Artist_Collection_Categories" */
  Artist_Collection_Categories: Array<Artist_Collection_Categories>;
  /** fetch aggregated fields from the table: "Artist_Collection_Categories" */
  Artist_Collection_Categories_aggregate: Artist_Collection_Categories_Aggregate;
  /** fetch data from the table: "Artist_Collection_Categories" using primary key columns */
  Artist_Collection_Categories_by_pk?: Maybe<Artist_Collection_Categories>;
  /** fetch aggregated fields from the table: "Artist_Collection" */
  Artist_Collection_aggregate: Artist_Collection_Aggregate;
  /** fetch data from the table: "Artist_Collection" using primary key columns */
  Artist_Collection_by_pk?: Maybe<Artist_Collection>;
  /** fetch data from the table: "Artist_Collection_to_Artist_Context" */
  Artist_Collection_to_Artist_Context: Array<Artist_Collection_To_Artist_Context>;
  /** fetch aggregated fields from the table: "Artist_Collection_to_Artist_Context" */
  Artist_Collection_to_Artist_Context_aggregate: Artist_Collection_To_Artist_Context_Aggregate;
  /** fetch data from the table: "Artist_Collection_to_Artist_Context" using primary key columns */
  Artist_Collection_to_Artist_Context_by_pk?: Maybe<Artist_Collection_To_Artist_Context>;
  /** fetch data from the table: "Artist_Vision_Listings_By_Interest" */
  Artist_Vision_Listings_By_Interest: Array<Artist_Vision_Listings_By_Interest>;
  /** fetch aggregated fields from the table: "Artist_Vision_Listings_By_Interest" */
  Artist_Vision_Listings_By_Interest_aggregate: Artist_Vision_Listings_By_Interest_Aggregate;
  /** fetch data from the table: "Artist_art_history_listing" */
  Artist_art_history_listing: Array<Artist_Art_History_Listing>;
  /** fetch aggregated fields from the table: "Artist_art_history_listing" */
  Artist_art_history_listing_aggregate: Artist_Art_History_Listing_Aggregate;
  /** fetch data from the table: "Artist_art_history_listing_by_movement" */
  Artist_art_history_listing_by_movement: Array<Artist_Art_History_Listing_By_Movement>;
  /** fetch aggregated fields from the table: "Artist_art_history_listing_by_movement" */
  Artist_art_history_listing_by_movement_aggregate: Artist_Art_History_Listing_By_Movement_Aggregate;
  /** fetch data from the table: "Artist_art_history_visibility" */
  Artist_art_history_visibility: Array<Artist_Art_History_Visibility>;
  /** fetch aggregated fields from the table: "Artist_art_history_visibility" */
  Artist_art_history_visibility_aggregate: Artist_Art_History_Visibility_Aggregate;
  /** fetch data from the table: "Artist_art_history_visibility" using primary key columns */
  Artist_art_history_visibility_by_pk?: Maybe<Artist_Art_History_Visibility>;
  /** fetch data from the table: "Artist_curation_score" */
  Artist_curation_score: Array<Artist_Curation_Score>;
  /** fetch aggregated fields from the table: "Artist_curation_score" */
  Artist_curation_score_aggregate: Artist_Curation_Score_Aggregate;
  /** fetch data from the table: "Artist_curation_score" using primary key columns */
  Artist_curation_score_by_pk?: Maybe<Artist_Curation_Score>;
  /** fetch data from the table: "Artist_explore_info" */
  Artist_explore_info: Array<Artist_Explore_Info>;
  /** fetch aggregated fields from the table: "Artist_explore_info" */
  Artist_explore_info_aggregate: Artist_Explore_Info_Aggregate;
  /** fetch data from the table: "Artist_explore_interests" */
  Artist_explore_interests: Array<Artist_Explore_Interests>;
  /** fetch aggregated fields from the table: "Artist_explore_interests" */
  Artist_explore_interests_aggregate: Artist_Explore_Interests_Aggregate;
  /** fetch data from the table: "Artist_explore_listing" */
  Artist_explore_listing: Array<Artist_Explore_Listing>;
  /** fetch aggregated fields from the table: "Artist_explore_listing" */
  Artist_explore_listing_aggregate: Artist_Explore_Listing_Aggregate;
  /** fetch data from the table: "Artist_explore_listing_with_city_data" */
  Artist_explore_listing_with_city_data: Array<Artist_Explore_Listing_With_City_Data>;
  /** fetch aggregated fields from the table: "Artist_explore_listing_with_city_data" */
  Artist_explore_listing_with_city_data_aggregate: Artist_Explore_Listing_With_City_Data_Aggregate;
  /** fetch data from the table: "Artist_explore_visibility" */
  Artist_explore_visibility: Array<Artist_Explore_Visibility>;
  /** fetch aggregated fields from the table: "Artist_explore_visibility" */
  Artist_explore_visibility_aggregate: Artist_Explore_Visibility_Aggregate;
  /** fetch data from the table: "Artist_explore_visibility" using primary key columns */
  Artist_explore_visibility_by_pk?: Maybe<Artist_Explore_Visibility>;
  /** fetch data from the table: "Artist_links_filtered" */
  Artist_links_filtered: Array<Artist_Links_Filtered>;
  /** fetch aggregated fields from the table: "Artist_links_filtered" */
  Artist_links_filtered_aggregate: Artist_Links_Filtered_Aggregate;
  /** An array relationship */
  Artist_newsletter: Array<Artist_Newsletter>;
  /** An aggregate relationship */
  Artist_newsletter_aggregate: Artist_Newsletter_Aggregate;
  /** fetch data from the table: "Artist_newsletter" using primary key columns */
  Artist_newsletter_by_pk?: Maybe<Artist_Newsletter>;
  /** fetch data from the table: "Artist_popular_discipline" */
  Artist_popular_discipline: Array<Artist_Popular_Discipline>;
  /** fetch aggregated fields from the table: "Artist_popular_discipline" */
  Artist_popular_discipline_aggregate: Artist_Popular_Discipline_Aggregate;
  /** fetch data from the table: "Artist_preview_artworks" */
  Artist_preview_artworks: Array<Artist_Preview_Artworks>;
  /** fetch aggregated fields from the table: "Artist_preview_artworks" */
  Artist_preview_artworks_aggregate: Artist_Preview_Artworks_Aggregate;
  /** fetch data from the table: "Artist_vision_listing" */
  Artist_vision_listing: Array<Artist_Vision_Listing>;
  /** fetch aggregated fields from the table: "Artist_vision_listing" */
  Artist_vision_listing_aggregate: Artist_Vision_Listing_Aggregate;
  /** fetch data from the table: "Artist_vision_visibility" */
  Artist_vision_visibility: Array<Artist_Vision_Visibility>;
  /** fetch aggregated fields from the table: "Artist_vision_visibility" */
  Artist_vision_visibility_aggregate: Artist_Vision_Visibility_Aggregate;
  /** fetch data from the table: "Artist_vision_visibility" using primary key columns */
  Artist_vision_visibility_by_pk?: Maybe<Artist_Vision_Visibility>;
  /** fetch data from the table: "Artwork" */
  Artwork: Array<Artwork>;
  /** An array relationship */
  Artwork_Art_Universe_Listing: Array<Artwork_Art_Universe_Listing>;
  /** An aggregate relationship */
  Artwork_Art_Universe_Listing_aggregate: Artwork_Art_Universe_Listing_Aggregate;
  /** fetch aggregated fields from the table: "Artwork" */
  Artwork_aggregate: Artwork_Aggregate;
  /** An array relationship */
  Artwork_ai_tags: Array<Artwork_Ai_Tags>;
  /** An aggregate relationship */
  Artwork_ai_tags_aggregate: Artwork_Ai_Tags_Aggregate;
  /** fetch data from the table: "Artwork_ai_tags" using primary key columns */
  Artwork_ai_tags_by_pk?: Maybe<Artwork_Ai_Tags>;
  /** fetch data from the table: "Artwork_art_history_timetable" */
  Artwork_art_history_timetable: Array<Artwork_Art_History_Timetable>;
  /** fetch aggregated fields from the table: "Artwork_art_history_timetable" */
  Artwork_art_history_timetable_aggregate: Artwork_Art_History_Timetable_Aggregate;
  /** fetch data from the table: "Artwork_art_history_timetable" using primary key columns */
  Artwork_art_history_timetable_by_pk?: Maybe<Artwork_Art_History_Timetable>;
  /** fetch data from the table: "Artwork_artist_art_history_listing" */
  Artwork_artist_art_history_listing: Array<Artwork_Artist_Art_History_Listing>;
  /** fetch aggregated fields from the table: "Artwork_artist_art_history_listing" */
  Artwork_artist_art_history_listing_aggregate: Artwork_Artist_Art_History_Listing_Aggregate;
  /** fetch data from the table: "Artwork_artist_collection_type_listing" */
  Artwork_artist_collection_type_listing: Array<Artwork_Artist_Collection_Type_Listing>;
  /** fetch aggregated fields from the table: "Artwork_artist_collection_type_listing" */
  Artwork_artist_collection_type_listing_aggregate: Artwork_Artist_Collection_Type_Listing_Aggregate;
  /** fetch data from the table: "Artwork_artist_profile_info" */
  Artwork_artist_profile_info: Array<Artwork_Artist_Profile_Info>;
  /** fetch aggregated fields from the table: "Artwork_artist_profile_info" */
  Artwork_artist_profile_info_aggregate: Artwork_Artist_Profile_Info_Aggregate;
  /** fetch data from the table: "Artwork_artist_profile_listing" */
  Artwork_artist_profile_listing: Array<Artwork_Artist_Profile_Listing>;
  /** fetch aggregated fields from the table: "Artwork_artist_profile_listing" */
  Artwork_artist_profile_listing_aggregate: Artwork_Artist_Profile_Listing_Aggregate;
  /** fetch data from the table: "Artwork_artist_vision_listing" */
  Artwork_artist_vision_listing: Array<Artwork_Artist_Vision_Listing>;
  /** fetch aggregated fields from the table: "Artwork_artist_vision_listing" */
  Artwork_artist_vision_listing_aggregate: Artwork_Artist_Vision_Listing_Aggregate;
  /** fetch data from the table: "Artwork_aspect_ratio" */
  Artwork_aspect_ratio: Array<Artwork_Aspect_Ratio>;
  /** fetch aggregated fields from the table: "Artwork_aspect_ratio" */
  Artwork_aspect_ratio_aggregate: Artwork_Aspect_Ratio_Aggregate;
  /** fetch data from the table: "Artwork_aspect_ratio" using primary key columns */
  Artwork_aspect_ratio_by_pk?: Maybe<Artwork_Aspect_Ratio>;
  /** fetch data from the table: "Artwork" using primary key columns */
  Artwork_by_pk?: Maybe<Artwork>;
  /** fetch data from the table: "Artwork_collected_listing" */
  Artwork_collected_listing: Array<Artwork_Collected_Listing>;
  /** fetch aggregated fields from the table: "Artwork_collected_listing" */
  Artwork_collected_listing_aggregate: Artwork_Collected_Listing_Aggregate;
  /** fetch data from the table: "Artwork_count_milestones" */
  Artwork_count_milestones: Array<Artwork_Count_Milestones>;
  /** fetch aggregated fields from the table: "Artwork_count_milestones" */
  Artwork_count_milestones_aggregate: Artwork_Count_Milestones_Aggregate;
  /** fetch data from the table: "Artwork_count_milestones" using primary key columns */
  Artwork_count_milestones_by_pk?: Maybe<Artwork_Count_Milestones>;
  /** fetch data from the table: "Artwork_curation_score" */
  Artwork_curation_score: Array<Artwork_Curation_Score>;
  /** fetch aggregated fields from the table: "Artwork_curation_score" */
  Artwork_curation_score_aggregate: Artwork_Curation_Score_Aggregate;
  /** fetch data from the table: "Artwork_curation_score" using primary key columns */
  Artwork_curation_score_by_pk?: Maybe<Artwork_Curation_Score>;
  /** fetch data from the table: "Artwork_details" */
  Artwork_details: Array<Artwork_Details>;
  /** fetch aggregated fields from the table: "Artwork_details" */
  Artwork_details_aggregate: Artwork_Details_Aggregate;
  /** fetch data from the table: "Artwork_details" using primary key columns */
  Artwork_details_by_pk?: Maybe<Artwork_Details>;
  /** fetch data from the table: "Artwork_details_translation" */
  Artwork_details_translation: Array<Artwork_Details_Translation>;
  /** fetch aggregated fields from the table: "Artwork_details_translation" */
  Artwork_details_translation_aggregate: Artwork_Details_Translation_Aggregate;
  /** fetch data from the table: "Artwork_details_translation" using primary key columns */
  Artwork_details_translation_by_pk?: Maybe<Artwork_Details_Translation>;
  /** fetch data from the table: "Artwork_digital_twin" */
  Artwork_digital_twin: Array<Artwork_Digital_Twin>;
  /** fetch aggregated fields from the table: "Artwork_digital_twin" */
  Artwork_digital_twin_aggregate: Artwork_Digital_Twin_Aggregate;
  /** fetch data from the table: "Artwork_digital_twin" using primary key columns */
  Artwork_digital_twin_by_pk?: Maybe<Artwork_Digital_Twin>;
  /** fetch data from the table: "Artwork_documents_info" */
  Artwork_documents_info: Array<Artwork_Documents_Info>;
  /** fetch aggregated fields from the table: "Artwork_documents_info" */
  Artwork_documents_info_aggregate: Artwork_Documents_Info_Aggregate;
  /** An array relationship */
  Artwork_events: Array<Artwork_Events>;
  /** An aggregate relationship */
  Artwork_events_aggregate: Artwork_Events_Aggregate;
  /** fetch data from the table: "Artwork_events" using primary key columns */
  Artwork_events_by_pk?: Maybe<Artwork_Events>;
  /** fetch data from the table: "Artwork_explore_info" */
  Artwork_explore_info: Array<Artwork_Explore_Info>;
  /** fetch aggregated fields from the table: "Artwork_explore_info" */
  Artwork_explore_info_aggregate: Artwork_Explore_Info_Aggregate;
  /** fetch data from the table: "Artwork_explore_listing" */
  Artwork_explore_listing: Array<Artwork_Explore_Listing>;
  /** fetch aggregated fields from the table: "Artwork_explore_listing" */
  Artwork_explore_listing_aggregate: Artwork_Explore_Listing_Aggregate;
  /** fetch data from the table: "Artwork_external_buy_link" */
  Artwork_external_buy_link: Array<Artwork_External_Buy_Link>;
  /** fetch aggregated fields from the table: "Artwork_external_buy_link" */
  Artwork_external_buy_link_aggregate: Artwork_External_Buy_Link_Aggregate;
  /** fetch data from the table: "Artwork_external_buy_link" using primary key columns */
  Artwork_external_buy_link_by_pk?: Maybe<Artwork_External_Buy_Link>;
  /** fetch data from the table: "Artwork_favorite_listing" */
  Artwork_favorite_listing: Array<Artwork_Favorite_Listing>;
  /** fetch aggregated fields from the table: "Artwork_favorite_listing" */
  Artwork_favorite_listing_aggregate: Artwork_Favorite_Listing_Aggregate;
  /** fetch data from the table: "Artwork_favorited_by_listing" */
  Artwork_favorited_by_listing: Array<Artwork_Favorited_By_Listing>;
  /** fetch aggregated fields from the table: "Artwork_favorited_by_listing" */
  Artwork_favorited_by_listing_aggregate: Artwork_Favorited_By_Listing_Aggregate;
  /** fetch data from the table: "Artwork_favorites_art_universe_listing" */
  Artwork_favorites_art_universe_listing: Array<Artwork_Favorites_Art_Universe_Listing>;
  /** fetch aggregated fields from the table: "Artwork_favorites_art_universe_listing" */
  Artwork_favorites_art_universe_listing_aggregate: Artwork_Favorites_Art_Universe_Listing_Aggregate;
  /** fetch data from the table: "Artwork_last_print_transaction" */
  Artwork_last_print_transaction: Array<Artwork_Last_Print_Transaction>;
  /** fetch aggregated fields from the table: "Artwork_last_print_transaction" */
  Artwork_last_print_transaction_aggregate: Artwork_Last_Print_Transaction_Aggregate;
  /** fetch data from the table: "Artwork_last_transaction" */
  Artwork_last_transaction: Array<Artwork_Last_Transaction>;
  /** fetch aggregated fields from the table: "Artwork_last_transaction" */
  Artwork_last_transaction_aggregate: Artwork_Last_Transaction_Aggregate;
  /** fetch data from the table: "Artwork_links" */
  Artwork_links: Array<Artwork_Links>;
  /** fetch aggregated fields from the table: "Artwork_links" */
  Artwork_links_aggregate: Artwork_Links_Aggregate;
  /** An array relationship */
  Artwork_prices: Array<Artwork_Prices>;
  /** An aggregate relationship */
  Artwork_prices_aggregate: Artwork_Prices_Aggregate;
  /** fetch data from the table: "Artwork_prices" using primary key columns */
  Artwork_prices_by_pk?: Maybe<Artwork_Prices>;
  /** fetch data from the table: "Artwork_prize_info" */
  Artwork_prize_info: Array<Artwork_Prize_Info>;
  /** fetch aggregated fields from the table: "Artwork_prize_info" */
  Artwork_prize_info_aggregate: Artwork_Prize_Info_Aggregate;
  /** fetch data from the table: "Artwork_prize_listing" */
  Artwork_prize_listing: Array<Artwork_Prize_Listing>;
  /** fetch aggregated fields from the table: "Artwork_prize_listing" */
  Artwork_prize_listing_aggregate: Artwork_Prize_Listing_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey" */
  Artwork_purchase_journey: Array<Artwork_Purchase_Journey>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey" */
  Artwork_purchase_journey_aggregate: Artwork_Purchase_Journey_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey" using primary key columns */
  Artwork_purchase_journey_by_pk?: Maybe<Artwork_Purchase_Journey>;
  /** fetch data from the table: "Artwork_purchase_journey_history" */
  Artwork_purchase_journey_history: Array<Artwork_Purchase_Journey_History>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey_history" */
  Artwork_purchase_journey_history_aggregate: Artwork_Purchase_Journey_History_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey_history" using primary key columns */
  Artwork_purchase_journey_history_by_pk?: Maybe<Artwork_Purchase_Journey_History>;
  /** fetch data from the table: "Artwork_purchase_journey_last_update" */
  Artwork_purchase_journey_last_update: Array<Artwork_Purchase_Journey_Last_Update>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey_last_update" */
  Artwork_purchase_journey_last_update_aggregate: Artwork_Purchase_Journey_Last_Update_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey_last_update" using primary key columns */
  Artwork_purchase_journey_last_update_by_pk?: Maybe<Artwork_Purchase_Journey_Last_Update>;
  /** fetch data from the table: "Artwork_purchase_journey_pricing" */
  Artwork_purchase_journey_pricing: Array<Artwork_Purchase_Journey_Pricing>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey_pricing" */
  Artwork_purchase_journey_pricing_aggregate: Artwork_Purchase_Journey_Pricing_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey_pricing" using primary key columns */
  Artwork_purchase_journey_pricing_by_pk?: Maybe<Artwork_Purchase_Journey_Pricing>;
  /** fetch data from the table: "Artwork_purchase_journey_shipping_info" */
  Artwork_purchase_journey_shipping_info: Array<Artwork_Purchase_Journey_Shipping_Info>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey_shipping_info" */
  Artwork_purchase_journey_shipping_info_aggregate: Artwork_Purchase_Journey_Shipping_Info_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey_shipping_info" using primary key columns */
  Artwork_purchase_journey_shipping_info_by_pk?: Maybe<Artwork_Purchase_Journey_Shipping_Info>;
  /** An array relationship */
  Artwork_secondary_media: Array<Artwork_Secondary_Media>;
  /** An aggregate relationship */
  Artwork_secondary_media_aggregate: Artwork_Secondary_Media_Aggregate;
  /** fetch data from the table: "Artwork_secondary_media" using primary key columns */
  Artwork_secondary_media_by_pk?: Maybe<Artwork_Secondary_Media>;
  /** fetch data from the table: "Artwork_showcase_filtered" */
  Artwork_showcase_filtered: Array<Artwork_Showcase_Filtered>;
  /** fetch aggregated fields from the table: "Artwork_showcase_filtered" */
  Artwork_showcase_filtered_aggregate: Artwork_Showcase_Filtered_Aggregate;
  /** fetch data from the table: "Artwork_state_history" */
  Artwork_state_history: Array<Artwork_State_History>;
  /** fetch aggregated fields from the table: "Artwork_state_history" */
  Artwork_state_history_aggregate: Artwork_State_History_Aggregate;
  /** fetch data from the table: "Artwork_state_history" using primary key columns */
  Artwork_state_history_by_pk?: Maybe<Artwork_State_History>;
  /** fetch data from the table: "Artwork_story" */
  Artwork_story: Array<Artwork_Story>;
  /** fetch aggregated fields from the table: "Artwork_story" */
  Artwork_story_aggregate: Artwork_Story_Aggregate;
  /** fetch data from the table: "Artwork_story" using primary key columns */
  Artwork_story_by_pk?: Maybe<Artwork_Story>;
  /** fetch data from the table: "Artwork_story_media" */
  Artwork_story_media: Array<Artwork_Story_Media>;
  /** fetch aggregated fields from the table: "Artwork_story_media" */
  Artwork_story_media_aggregate: Artwork_Story_Media_Aggregate;
  /** fetch data from the table: "Artwork_story_media" using primary key columns */
  Artwork_story_media_by_pk?: Maybe<Artwork_Story_Media>;
  /** fetch data from the table: "Artwork_story_video_links" */
  Artwork_story_video_links: Array<Artwork_Story_Video_Links>;
  /** fetch aggregated fields from the table: "Artwork_story_video_links" */
  Artwork_story_video_links_aggregate: Artwork_Story_Video_Links_Aggregate;
  /** fetch data from the table: "Artwork_story_video_links" using primary key columns */
  Artwork_story_video_links_by_pk?: Maybe<Artwork_Story_Video_Links>;
  /** fetch data from the table: "Artwork_tag" */
  Artwork_tag: Array<Artwork_Tag>;
  /** fetch aggregated fields from the table: "Artwork_tag" */
  Artwork_tag_aggregate: Artwork_Tag_Aggregate;
  /** fetch data from the table: "Artwork_tag" using primary key columns */
  Artwork_tag_by_pk?: Maybe<Artwork_Tag>;
  /** fetch data from the table: "Artwork_to_Collection" */
  Artwork_to_Collection: Array<Artwork_To_Collection>;
  /** fetch aggregated fields from the table: "Artwork_to_Collection" */
  Artwork_to_Collection_aggregate: Artwork_To_Collection_Aggregate;
  /** fetch data from the table: "Artwork_to_Collection" using primary key columns */
  Artwork_to_Collection_by_pk?: Maybe<Artwork_To_Collection>;
  /** fetch data from the table: "Artwork_to_showroom_details" */
  Artwork_to_showroom_details: Array<Artwork_To_Showroom_Details>;
  /** fetch aggregated fields from the table: "Artwork_to_showroom_details" */
  Artwork_to_showroom_details_aggregate: Artwork_To_Showroom_Details_Aggregate;
  /** fetch data from the table: "Artwork_to_showroom_details" using primary key columns */
  Artwork_to_showroom_details_by_pk?: Maybe<Artwork_To_Showroom_Details>;
  /** fetch data from the table: "Artwork_to_tag" */
  Artwork_to_tag: Array<Artwork_To_Tag>;
  /** fetch aggregated fields from the table: "Artwork_to_tag" */
  Artwork_to_tag_aggregate: Artwork_To_Tag_Aggregate;
  /** fetch data from the table: "Artwork_to_tag" using primary key columns */
  Artwork_to_tag_by_pk?: Maybe<Artwork_To_Tag>;
  /** fetch data from the table: "Artwork_transactions" */
  Artwork_transactions: Array<Artwork_Transactions>;
  /** fetch aggregated fields from the table: "Artwork_transactions" */
  Artwork_transactions_aggregate: Artwork_Transactions_Aggregate;
  /** fetch data from the table: "Artwork_transactions" using primary key columns */
  Artwork_transactions_by_pk?: Maybe<Artwork_Transactions>;
  /** fetch data from the table: "Auth" */
  Auth: Array<Auth>;
  /** fetch aggregated fields from the table: "Auth" */
  Auth_aggregate: Auth_Aggregate;
  /** fetch data from the table: "Auth" using primary key columns */
  Auth_by_pk?: Maybe<Auth>;
  /** fetch data from the table: "Auth_context_access" */
  Auth_context_access: Array<Auth_Context_Access>;
  /** fetch aggregated fields from the table: "Auth_context_access" */
  Auth_context_access_aggregate: Auth_Context_Access_Aggregate;
  /** fetch data from the table: "Auth_context_access" using primary key columns */
  Auth_context_access_by_pk?: Maybe<Auth_Context_Access>;
  /** fetch data from the table: "Available_print_price" */
  Available_print_price: Array<Available_Print_Price>;
  /** fetch aggregated fields from the table: "Available_print_price" */
  Available_print_price_aggregate: Available_Print_Price_Aggregate;
  /** fetch data from the table: "Available_print_price" using primary key columns */
  Available_print_price_by_pk?: Maybe<Available_Print_Price>;
  /** fetch data from the table: "Available_print_price_view" */
  Available_print_price_view: Array<Available_Print_Price_View>;
  /** fetch aggregated fields from the table: "Available_print_price_view" */
  Available_print_price_view_aggregate: Available_Print_Price_View_Aggregate;
  /** fetch data from the table: "Billing_anon_client" */
  Billing_anon_client: Array<Billing_Anon_Client>;
  /** fetch aggregated fields from the table: "Billing_anon_client" */
  Billing_anon_client_aggregate: Billing_Anon_Client_Aggregate;
  /** fetch data from the table: "Billing_anon_client" using primary key columns */
  Billing_anon_client_by_pk?: Maybe<Billing_Anon_Client>;
  /** fetch data from the table: "Billing_artwork_payment_intent" */
  Billing_artwork_payment_intent: Array<Billing_Artwork_Payment_Intent>;
  /** fetch aggregated fields from the table: "Billing_artwork_payment_intent" */
  Billing_artwork_payment_intent_aggregate: Billing_Artwork_Payment_Intent_Aggregate;
  /** fetch data from the table: "Billing_artwork_payment_intent" using primary key columns */
  Billing_artwork_payment_intent_by_pk?: Maybe<Billing_Artwork_Payment_Intent>;
  /** fetch data from the table: "Billing_artwork_payment_intent_product" */
  Billing_artwork_payment_intent_product: Array<Billing_Artwork_Payment_Intent_Product>;
  /** fetch aggregated fields from the table: "Billing_artwork_payment_intent_product" */
  Billing_artwork_payment_intent_product_aggregate: Billing_Artwork_Payment_Intent_Product_Aggregate;
  /** fetch data from the table: "Billing_artwork_payment_intent_product" using primary key columns */
  Billing_artwork_payment_intent_product_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Product>;
  /** fetch data from the table: "Billing_artwork_payment_intent_shipping_address" */
  Billing_artwork_payment_intent_shipping_address: Array<Billing_Artwork_Payment_Intent_Shipping_Address>;
  /** fetch aggregated fields from the table: "Billing_artwork_payment_intent_shipping_address" */
  Billing_artwork_payment_intent_shipping_address_aggregate: Billing_Artwork_Payment_Intent_Shipping_Address_Aggregate;
  /** fetch data from the table: "Billing_artwork_payment_intent_shipping_address" using primary key columns */
  Billing_artwork_payment_intent_shipping_address_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address>;
  /** fetch data from the table: "Billing_artwork_payment_intent_status_history" */
  Billing_artwork_payment_intent_status_history: Array<Billing_Artwork_Payment_Intent_Status_History>;
  /** fetch aggregated fields from the table: "Billing_artwork_payment_intent_status_history" */
  Billing_artwork_payment_intent_status_history_aggregate: Billing_Artwork_Payment_Intent_Status_History_Aggregate;
  /** fetch data from the table: "Billing_artwork_payment_intent_status_history" using primary key columns */
  Billing_artwork_payment_intent_status_history_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Status_History>;
  /** fetch data from the table: "Billing_client" */
  Billing_client: Array<Billing_Client>;
  /** fetch aggregated fields from the table: "Billing_client" */
  Billing_client_aggregate: Billing_Client_Aggregate;
  /** fetch data from the table: "Billing_client" using primary key columns */
  Billing_client_by_pk?: Maybe<Billing_Client>;
  /** fetch data from the table: "Billing_seller" */
  Billing_seller: Array<Billing_Seller>;
  /** fetch aggregated fields from the table: "Billing_seller" */
  Billing_seller_aggregate: Billing_Seller_Aggregate;
  /** fetch data from the table: "Billing_seller" using primary key columns */
  Billing_seller_by_pk?: Maybe<Billing_Seller>;
  /** fetch data from the table: "Billing_subscription" */
  Billing_subscription: Array<Billing_Subscription>;
  /** fetch aggregated fields from the table: "Billing_subscription" */
  Billing_subscription_aggregate: Billing_Subscription_Aggregate;
  /** fetch data from the table: "Billing_subscription" using primary key columns */
  Billing_subscription_by_pk?: Maybe<Billing_Subscription>;
  /** fetch data from the table: "Billing_subscription_product" */
  Billing_subscription_product: Array<Billing_Subscription_Product>;
  /** fetch aggregated fields from the table: "Billing_subscription_product" */
  Billing_subscription_product_aggregate: Billing_Subscription_Product_Aggregate;
  /** fetch data from the table: "Billing_subscription_product" using primary key columns */
  Billing_subscription_product_by_pk?: Maybe<Billing_Subscription_Product>;
  /** fetch data from the table: "Biolink_sections" */
  Biolink_sections: Array<Biolink_Sections>;
  /** fetch aggregated fields from the table: "Biolink_sections" */
  Biolink_sections_aggregate: Biolink_Sections_Aggregate;
  /** fetch data from the table: "Biolink_sections" using primary key columns */
  Biolink_sections_by_pk?: Maybe<Biolink_Sections>;
  /** fetch data from the table: "Certificate_of_authenticity" */
  Certificate_of_authenticity: Array<Certificate_Of_Authenticity>;
  /** fetch aggregated fields from the table: "Certificate_of_authenticity" */
  Certificate_of_authenticity_aggregate: Certificate_Of_Authenticity_Aggregate;
  /** fetch data from the table: "Certificate_of_authenticity" using primary key columns */
  Certificate_of_authenticity_by_pk?: Maybe<Certificate_Of_Authenticity>;
  /** fetch data from the table: "Collaboration" */
  Collaboration: Array<Collaboration>;
  /** fetch aggregated fields from the table: "Collaboration" */
  Collaboration_aggregate: Collaboration_Aggregate;
  /** fetch data from the table: "Collaboration" using primary key columns */
  Collaboration_by_pk?: Maybe<Collaboration>;
  /** fetch data from the table: "Collaboration_invitation_on_artwork" */
  Collaboration_invitation_on_artwork: Array<Collaboration_Invitation_On_Artwork>;
  /** fetch aggregated fields from the table: "Collaboration_invitation_on_artwork" */
  Collaboration_invitation_on_artwork_aggregate: Collaboration_Invitation_On_Artwork_Aggregate;
  /** fetch data from the table: "Collaboration_invitation_on_artwork" using primary key columns */
  Collaboration_invitation_on_artwork_by_pk?: Maybe<Collaboration_Invitation_On_Artwork>;
  /** fetch data from the table: "Collection" */
  Collection: Array<Collection>;
  /** fetch aggregated fields from the table: "Collection" */
  Collection_aggregate: Collection_Aggregate;
  /** fetch data from the table: "Collection" using primary key columns */
  Collection_by_pk?: Maybe<Collection>;
  /** fetch data from the table: "Collection_translation" */
  Collection_translation: Array<Collection_Translation>;
  /** fetch aggregated fields from the table: "Collection_translation" */
  Collection_translation_aggregate: Collection_Translation_Aggregate;
  /** fetch data from the table: "Collection_translation" using primary key columns */
  Collection_translation_by_pk?: Maybe<Collection_Translation>;
  /** fetch data from the table: "Collection_video_links" */
  Collection_video_links: Array<Collection_Video_Links>;
  /** fetch aggregated fields from the table: "Collection_video_links" */
  Collection_video_links_aggregate: Collection_Video_Links_Aggregate;
  /** fetch data from the table: "Collection_video_links" using primary key columns */
  Collection_video_links_by_pk?: Maybe<Collection_Video_Links>;
  /** fetch data from the table: "Collections_links_filtered" */
  Collections_links_filtered: Array<Collections_Links_Filtered>;
  /** fetch aggregated fields from the table: "Collections_links_filtered" */
  Collections_links_filtered_aggregate: Collections_Links_Filtered_Aggregate;
  /** fetch data from the table: "Collector_explore_info" */
  Collector_explore_info: Array<Collector_Explore_Info>;
  /** fetch aggregated fields from the table: "Collector_explore_info" */
  Collector_explore_info_aggregate: Collector_Explore_Info_Aggregate;
  /** fetch data from the table: "Collector_links_filtered" */
  Collector_links_filtered: Array<Collector_Links_Filtered>;
  /** fetch aggregated fields from the table: "Collector_links_filtered" */
  Collector_links_filtered_aggregate: Collector_Links_Filtered_Aggregate;
  /** fetch data from the table: "Connected_domain" */
  Connected_domain: Array<Connected_Domain>;
  /** fetch aggregated fields from the table: "Connected_domain" */
  Connected_domain_aggregate: Connected_Domain_Aggregate;
  /** fetch data from the table: "Connected_domain" using primary key columns */
  Connected_domain_by_pk?: Maybe<Connected_Domain>;
  /** fetch data from the table: "Content_translations" */
  Content_translations: Array<Content_Translations>;
  /** fetch aggregated fields from the table: "Content_translations" */
  Content_translations_aggregate: Content_Translations_Aggregate;
  /** fetch data from the table: "Context" */
  Context: Array<Context>;
  /** fetch aggregated fields from the table: "Context" */
  Context_aggregate: Context_Aggregate;
  /** fetch data from the table: "Context" using primary key columns */
  Context_by_pk?: Maybe<Context>;
  /** fetch data from the table: "Context_delegate_access" */
  Context_delegate_access: Array<Context_Delegate_Access>;
  /** fetch aggregated fields from the table: "Context_delegate_access" */
  Context_delegate_access_aggregate: Context_Delegate_Access_Aggregate;
  /** fetch data from the table: "Context_delegate_access" using primary key columns */
  Context_delegate_access_by_pk?: Maybe<Context_Delegate_Access>;
  /** fetch data from the table: "Context_flags" */
  Context_flags: Array<Context_Flags>;
  /** fetch aggregated fields from the table: "Context_flags" */
  Context_flags_aggregate: Context_Flags_Aggregate;
  /** fetch data from the table: "Context_flags" using primary key columns */
  Context_flags_by_pk?: Maybe<Context_Flags>;
  /** fetch data from the table: "Context_suggested_tags" */
  Context_suggested_tags: Array<Context_Suggested_Tags>;
  /** fetch aggregated fields from the table: "Context_suggested_tags" */
  Context_suggested_tags_aggregate: Context_Suggested_Tags_Aggregate;
  /** fetch data from the table: "Context_suggested_tags" using primary key columns */
  Context_suggested_tags_by_pk?: Maybe<Context_Suggested_Tags>;
  /** fetch data from the table: "Context_to_milestone" */
  Context_to_milestone: Array<Context_To_Milestone>;
  /** fetch aggregated fields from the table: "Context_to_milestone" */
  Context_to_milestone_aggregate: Context_To_Milestone_Aggregate;
  /** fetch data from the table: "Context_to_milestone" using primary key columns */
  Context_to_milestone_by_pk?: Maybe<Context_To_Milestone>;
  /** fetch data from the table: "Context_to_tutorial" */
  Context_to_tutorial: Array<Context_To_Tutorial>;
  /** fetch aggregated fields from the table: "Context_to_tutorial" */
  Context_to_tutorial_aggregate: Context_To_Tutorial_Aggregate;
  /** fetch data from the table: "Context_to_tutorial" using primary key columns */
  Context_to_tutorial_by_pk?: Maybe<Context_To_Tutorial>;
  /** fetch data from the table: "Countries" */
  Countries: Array<Countries>;
  /** fetch aggregated fields from the table: "Countries" */
  Countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "Countries" using primary key columns */
  Countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table: "Crop_Area" */
  Crop_Area: Array<Crop_Area>;
  /** fetch aggregated fields from the table: "Crop_Area" */
  Crop_Area_aggregate: Crop_Area_Aggregate;
  /** fetch data from the table: "Crop_Area" using primary key columns */
  Crop_Area_by_pk?: Maybe<Crop_Area>;
  /** fetch data from the table: "Currency_conversion" */
  Currency_conversion: Array<Currency_Conversion>;
  /** fetch aggregated fields from the table: "Currency_conversion" */
  Currency_conversion_aggregate: Currency_Conversion_Aggregate;
  /** fetch data from the table: "Currency_conversion" using primary key columns */
  Currency_conversion_by_pk?: Maybe<Currency_Conversion>;
  /** fetch data from the table: "Digital_twin_details" */
  Digital_twin_details: Array<Digital_Twin_Details>;
  /** fetch aggregated fields from the table: "Digital_twin_details" */
  Digital_twin_details_aggregate: Digital_Twin_Details_Aggregate;
  /** fetch data from the table: "Digital_twin_details" using primary key columns */
  Digital_twin_details_by_pk?: Maybe<Digital_Twin_Details>;
  /** fetch data from the table: "Direct_messages_from_collectors" */
  Direct_messages_from_collectors: Array<Direct_Messages_From_Collectors>;
  /** fetch aggregated fields from the table: "Direct_messages_from_collectors" */
  Direct_messages_from_collectors_aggregate: Direct_Messages_From_Collectors_Aggregate;
  /** fetch data from the table: "Discount_code" */
  Discount_code: Array<Discount_Code>;
  /** fetch aggregated fields from the table: "Discount_code" */
  Discount_code_aggregate: Discount_Code_Aggregate;
  /** fetch data from the table: "Discount_code" using primary key columns */
  Discount_code_by_pk?: Maybe<Discount_Code>;
  /** fetch data from the table: "Discount_code_usage" */
  Discount_code_usage: Array<Discount_Code_Usage>;
  /** fetch aggregated fields from the table: "Discount_code_usage" */
  Discount_code_usage_aggregate: Discount_Code_Usage_Aggregate;
  /** fetch data from the table: "Discount_code_usage" using primary key columns */
  Discount_code_usage_by_pk?: Maybe<Discount_Code_Usage>;
  /** fetch data from the table: "Domain_registrant_info" */
  Domain_registrant_info: Array<Domain_Registrant_Info>;
  /** fetch aggregated fields from the table: "Domain_registrant_info" */
  Domain_registrant_info_aggregate: Domain_Registrant_Info_Aggregate;
  /** fetch data from the table: "Domain_registrant_info" using primary key columns */
  Domain_registrant_info_by_pk?: Maybe<Domain_Registrant_Info>;
  /** fetch data from the table: "Domain_registration" */
  Domain_registration: Array<Domain_Registration>;
  /** fetch aggregated fields from the table: "Domain_registration" */
  Domain_registration_aggregate: Domain_Registration_Aggregate;
  /** fetch data from the table: "Domain_registration" using primary key columns */
  Domain_registration_by_pk?: Maybe<Domain_Registration>;
  /** fetch data from the table: "Dummy_table_for_front" */
  Dummy_table_for_front: Array<Dummy_Table_For_Front>;
  /** fetch aggregated fields from the table: "Dummy_table_for_front" */
  Dummy_table_for_front_aggregate: Dummy_Table_For_Front_Aggregate;
  /** fetch data from the table: "Dummy_table_for_front" using primary key columns */
  Dummy_table_for_front_by_pk?: Maybe<Dummy_Table_For_Front>;
  /** fetch data from the table: "Dynamic_cv" */
  Dynamic_cv: Array<Dynamic_Cv>;
  /** fetch aggregated fields from the table: "Dynamic_cv" */
  Dynamic_cv_aggregate: Dynamic_Cv_Aggregate;
  /** fetch data from the table: "Dynamic_cv_award" */
  Dynamic_cv_award: Array<Dynamic_Cv_Award>;
  /** fetch aggregated fields from the table: "Dynamic_cv_award" */
  Dynamic_cv_award_aggregate: Dynamic_Cv_Award_Aggregate;
  /** fetch data from the table: "Dynamic_cv_award" using primary key columns */
  Dynamic_cv_award_by_pk?: Maybe<Dynamic_Cv_Award>;
  /** fetch data from the table: "Dynamic_cv" using primary key columns */
  Dynamic_cv_by_pk?: Maybe<Dynamic_Cv>;
  /** fetch data from the table: "Dynamic_cv_collection" */
  Dynamic_cv_collection: Array<Dynamic_Cv_Collection>;
  /** fetch aggregated fields from the table: "Dynamic_cv_collection" */
  Dynamic_cv_collection_aggregate: Dynamic_Cv_Collection_Aggregate;
  /** fetch data from the table: "Dynamic_cv_collection" using primary key columns */
  Dynamic_cv_collection_by_pk?: Maybe<Dynamic_Cv_Collection>;
  /** fetch data from the table: "Dynamic_cv_contact" */
  Dynamic_cv_contact: Array<Dynamic_Cv_Contact>;
  /** fetch aggregated fields from the table: "Dynamic_cv_contact" */
  Dynamic_cv_contact_aggregate: Dynamic_Cv_Contact_Aggregate;
  /** fetch data from the table: "Dynamic_cv_contact" using primary key columns */
  Dynamic_cv_contact_by_pk?: Maybe<Dynamic_Cv_Contact>;
  /** fetch data from the table: "Dynamic_cv_education" */
  Dynamic_cv_education: Array<Dynamic_Cv_Education>;
  /** fetch aggregated fields from the table: "Dynamic_cv_education" */
  Dynamic_cv_education_aggregate: Dynamic_Cv_Education_Aggregate;
  /** fetch data from the table: "Dynamic_cv_education" using primary key columns */
  Dynamic_cv_education_by_pk?: Maybe<Dynamic_Cv_Education>;
  /** fetch data from the table: "Dynamic_cv_exhibition" */
  Dynamic_cv_exhibition: Array<Dynamic_Cv_Exhibition>;
  /** fetch aggregated fields from the table: "Dynamic_cv_exhibition" */
  Dynamic_cv_exhibition_aggregate: Dynamic_Cv_Exhibition_Aggregate;
  /** fetch data from the table: "Dynamic_cv_exhibition" using primary key columns */
  Dynamic_cv_exhibition_by_pk?: Maybe<Dynamic_Cv_Exhibition>;
  /** fetch data from the table: "Dynamic_cv_publication" */
  Dynamic_cv_publication: Array<Dynamic_Cv_Publication>;
  /** fetch aggregated fields from the table: "Dynamic_cv_publication" */
  Dynamic_cv_publication_aggregate: Dynamic_Cv_Publication_Aggregate;
  /** fetch data from the table: "Dynamic_cv_publication" using primary key columns */
  Dynamic_cv_publication_by_pk?: Maybe<Dynamic_Cv_Publication>;
  /** fetch data from the table: "Dynamic_cv_section" */
  Dynamic_cv_section: Array<Dynamic_Cv_Section>;
  /** fetch aggregated fields from the table: "Dynamic_cv_section" */
  Dynamic_cv_section_aggregate: Dynamic_Cv_Section_Aggregate;
  /** fetch data from the table: "Dynamic_cv_section" using primary key columns */
  Dynamic_cv_section_by_pk?: Maybe<Dynamic_Cv_Section>;
  /** fetch data from the table: "ENUM_account_notice_type" */
  ENUM_account_notice_type: Array<Enum_Account_Notice_Type>;
  /** fetch aggregated fields from the table: "ENUM_account_notice_type" */
  ENUM_account_notice_type_aggregate: Enum_Account_Notice_Type_Aggregate;
  /** fetch data from the table: "ENUM_account_notice_type" using primary key columns */
  ENUM_account_notice_type_by_pk?: Maybe<Enum_Account_Notice_Type>;
  /** fetch data from the table: "ENUM_achievement_tags" */
  ENUM_achievement_tags: Array<Enum_Achievement_Tags>;
  /** fetch aggregated fields from the table: "ENUM_achievement_tags" */
  ENUM_achievement_tags_aggregate: Enum_Achievement_Tags_Aggregate;
  /** fetch data from the table: "ENUM_achievement_tags" using primary key columns */
  ENUM_achievement_tags_by_pk?: Maybe<Enum_Achievement_Tags>;
  /** fetch data from the table: "ENUM_add_artwork_flow_step" */
  ENUM_add_artwork_flow_step: Array<Enum_Add_Artwork_Flow_Step>;
  /** fetch aggregated fields from the table: "ENUM_add_artwork_flow_step" */
  ENUM_add_artwork_flow_step_aggregate: Enum_Add_Artwork_Flow_Step_Aggregate;
  /** fetch data from the table: "ENUM_add_artwork_flow_step" using primary key columns */
  ENUM_add_artwork_flow_step_by_pk?: Maybe<Enum_Add_Artwork_Flow_Step>;
  /** fetch data from the table: "ENUM_analytics_kaleidoCard_category" */
  ENUM_analytics_kaleidoCard_category: Array<Enum_Analytics_KaleidoCard_Category>;
  /** fetch aggregated fields from the table: "ENUM_analytics_kaleidoCard_category" */
  ENUM_analytics_kaleidoCard_category_aggregate: Enum_Analytics_KaleidoCard_Category_Aggregate;
  /** fetch data from the table: "ENUM_analytics_kaleidoCard_category" using primary key columns */
  ENUM_analytics_kaleidoCard_category_by_pk?: Maybe<Enum_Analytics_KaleidoCard_Category>;
  /** fetch data from the table: "ENUM_analytics_type" */
  ENUM_analytics_type: Array<Enum_Analytics_Type>;
  /** fetch aggregated fields from the table: "ENUM_analytics_type" */
  ENUM_analytics_type_aggregate: Enum_Analytics_Type_Aggregate;
  /** fetch data from the table: "ENUM_analytics_type" using primary key columns */
  ENUM_analytics_type_by_pk?: Maybe<Enum_Analytics_Type>;
  /** fetch data from the table: "ENUM_app_notification_type" */
  ENUM_app_notification_type: Array<Enum_App_Notification_Type>;
  /** fetch aggregated fields from the table: "ENUM_app_notification_type" */
  ENUM_app_notification_type_aggregate: Enum_App_Notification_Type_Aggregate;
  /** fetch data from the table: "ENUM_app_notification_type" using primary key columns */
  ENUM_app_notification_type_by_pk?: Maybe<Enum_App_Notification_Type>;
  /** fetch data from the table: "ENUM_art_type" */
  ENUM_art_type: Array<Enum_Art_Type>;
  /** fetch aggregated fields from the table: "ENUM_art_type" */
  ENUM_art_type_aggregate: Enum_Art_Type_Aggregate;
  /** fetch data from the table: "ENUM_art_type" using primary key columns */
  ENUM_art_type_by_pk?: Maybe<Enum_Art_Type>;
  /** fetch data from the table: "ENUM_art_type_extension" */
  ENUM_art_type_extension: Array<Enum_Art_Type_Extension>;
  /** fetch aggregated fields from the table: "ENUM_art_type_extension" */
  ENUM_art_type_extension_aggregate: Enum_Art_Type_Extension_Aggregate;
  /** fetch data from the table: "ENUM_art_type_extension" using primary key columns */
  ENUM_art_type_extension_by_pk?: Maybe<Enum_Art_Type_Extension>;
  /** fetch data from the table: "ENUM_artist_collection_categories" */
  ENUM_artist_collection_categories: Array<Enum_Artist_Collection_Categories>;
  /** fetch aggregated fields from the table: "ENUM_artist_collection_categories" */
  ENUM_artist_collection_categories_aggregate: Enum_Artist_Collection_Categories_Aggregate;
  /** fetch data from the table: "ENUM_artist_collection_categories" using primary key columns */
  ENUM_artist_collection_categories_by_pk?: Maybe<Enum_Artist_Collection_Categories>;
  /** fetch data from the table: "ENUM_artist_collection_state" */
  ENUM_artist_collection_state: Array<Enum_Artist_Collection_State>;
  /** fetch aggregated fields from the table: "ENUM_artist_collection_state" */
  ENUM_artist_collection_state_aggregate: Enum_Artist_Collection_State_Aggregate;
  /** fetch data from the table: "ENUM_artist_collection_state" using primary key columns */
  ENUM_artist_collection_state_by_pk?: Maybe<Enum_Artist_Collection_State>;
  /** fetch data from the table: "ENUM_artist_collection_type" */
  ENUM_artist_collection_type: Array<Enum_Artist_Collection_Type>;
  /** fetch aggregated fields from the table: "ENUM_artist_collection_type" */
  ENUM_artist_collection_type_aggregate: Enum_Artist_Collection_Type_Aggregate;
  /** fetch data from the table: "ENUM_artist_collection_type" using primary key columns */
  ENUM_artist_collection_type_by_pk?: Maybe<Enum_Artist_Collection_Type>;
  /** fetch data from the table: "ENUM_artwork_category" */
  ENUM_artwork_category: Array<Enum_Artwork_Category>;
  /** fetch aggregated fields from the table: "ENUM_artwork_category" */
  ENUM_artwork_category_aggregate: Enum_Artwork_Category_Aggregate;
  /** fetch data from the table: "ENUM_artwork_category" using primary key columns */
  ENUM_artwork_category_by_pk?: Maybe<Enum_Artwork_Category>;
  /** fetch data from the table: "ENUM_artwork_count_milestone_type" */
  ENUM_artwork_count_milestone_type: Array<Enum_Artwork_Count_Milestone_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_count_milestone_type" */
  ENUM_artwork_count_milestone_type_aggregate: Enum_Artwork_Count_Milestone_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_count_milestone_type" using primary key columns */
  ENUM_artwork_count_milestone_type_by_pk?: Maybe<Enum_Artwork_Count_Milestone_Type>;
  /** fetch data from the table: "ENUM_artwork_discipline" */
  ENUM_artwork_discipline: Array<Enum_Artwork_Discipline>;
  /** fetch aggregated fields from the table: "ENUM_artwork_discipline" */
  ENUM_artwork_discipline_aggregate: Enum_Artwork_Discipline_Aggregate;
  /** fetch data from the table: "ENUM_artwork_discipline" using primary key columns */
  ENUM_artwork_discipline_by_pk?: Maybe<Enum_Artwork_Discipline>;
  /** fetch data from the table: "ENUM_artwork_edition_type" */
  ENUM_artwork_edition_type: Array<Enum_Artwork_Edition_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_edition_type" */
  ENUM_artwork_edition_type_aggregate: Enum_Artwork_Edition_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_edition_type" using primary key columns */
  ENUM_artwork_edition_type_by_pk?: Maybe<Enum_Artwork_Edition_Type>;
  /** fetch data from the table: "ENUM_artwork_event_type" */
  ENUM_artwork_event_type: Array<Enum_Artwork_Event_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_event_type" */
  ENUM_artwork_event_type_aggregate: Enum_Artwork_Event_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_event_type" using primary key columns */
  ENUM_artwork_event_type_by_pk?: Maybe<Enum_Artwork_Event_Type>;
  /** fetch data from the table: "ENUM_artwork_genre" */
  ENUM_artwork_genre: Array<Enum_Artwork_Genre>;
  /** fetch aggregated fields from the table: "ENUM_artwork_genre" */
  ENUM_artwork_genre_aggregate: Enum_Artwork_Genre_Aggregate;
  /** fetch data from the table: "ENUM_artwork_genre" using primary key columns */
  ENUM_artwork_genre_by_pk?: Maybe<Enum_Artwork_Genre>;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  ENUM_artwork_purchase_journey_history_entry_kind: Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind>;
  /** fetch aggregated fields from the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  ENUM_artwork_purchase_journey_history_entry_kind_aggregate: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Aggregate;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_history_entry_kind" using primary key columns */
  ENUM_artwork_purchase_journey_history_entry_kind_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind>;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_party" */
  ENUM_artwork_purchase_journey_party: Array<Enum_Artwork_Purchase_Journey_Party>;
  /** fetch aggregated fields from the table: "ENUM_artwork_purchase_journey_party" */
  ENUM_artwork_purchase_journey_party_aggregate: Enum_Artwork_Purchase_Journey_Party_Aggregate;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_party" using primary key columns */
  ENUM_artwork_purchase_journey_party_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_Party>;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_status" */
  ENUM_artwork_purchase_journey_status: Array<Enum_Artwork_Purchase_Journey_Status>;
  /** fetch aggregated fields from the table: "ENUM_artwork_purchase_journey_status" */
  ENUM_artwork_purchase_journey_status_aggregate: Enum_Artwork_Purchase_Journey_Status_Aggregate;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_status" using primary key columns */
  ENUM_artwork_purchase_journey_status_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_Status>;
  /** fetch data from the table: "ENUM_artwork_showroom_status" */
  ENUM_artwork_showroom_status: Array<Enum_Artwork_Showroom_Status>;
  /** fetch aggregated fields from the table: "ENUM_artwork_showroom_status" */
  ENUM_artwork_showroom_status_aggregate: Enum_Artwork_Showroom_Status_Aggregate;
  /** fetch data from the table: "ENUM_artwork_showroom_status" using primary key columns */
  ENUM_artwork_showroom_status_by_pk?: Maybe<Enum_Artwork_Showroom_Status>;
  /** fetch data from the table: "ENUM_artwork_status" */
  ENUM_artwork_status: Array<Enum_Artwork_Status>;
  /** fetch aggregated fields from the table: "ENUM_artwork_status" */
  ENUM_artwork_status_aggregate: Enum_Artwork_Status_Aggregate;
  /** fetch data from the table: "ENUM_artwork_status" using primary key columns */
  ENUM_artwork_status_by_pk?: Maybe<Enum_Artwork_Status>;
  /** fetch data from the table: "ENUM_artwork_tag" */
  ENUM_artwork_tag: Array<Enum_Artwork_Tag>;
  /** fetch aggregated fields from the table: "ENUM_artwork_tag" */
  ENUM_artwork_tag_aggregate: Enum_Artwork_Tag_Aggregate;
  /** fetch data from the table: "ENUM_artwork_tag" using primary key columns */
  ENUM_artwork_tag_by_pk?: Maybe<Enum_Artwork_Tag>;
  /** fetch data from the table: "ENUM_artwork_tag_type" */
  ENUM_artwork_tag_type: Array<Enum_Artwork_Tag_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_tag_type" */
  ENUM_artwork_tag_type_aggregate: Enum_Artwork_Tag_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_tag_type" using primary key columns */
  ENUM_artwork_tag_type_by_pk?: Maybe<Enum_Artwork_Tag_Type>;
  /** fetch data from the table: "ENUM_artwork_type" */
  ENUM_artwork_type: Array<Enum_Artwork_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_type" */
  ENUM_artwork_type_aggregate: Enum_Artwork_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_type" using primary key columns */
  ENUM_artwork_type_by_pk?: Maybe<Enum_Artwork_Type>;
  /** fetch data from the table: "ENUM_audio_generation_status" */
  ENUM_audio_generation_status: Array<Enum_Audio_Generation_Status>;
  /** fetch aggregated fields from the table: "ENUM_audio_generation_status" */
  ENUM_audio_generation_status_aggregate: Enum_Audio_Generation_Status_Aggregate;
  /** fetch data from the table: "ENUM_audio_generation_status" using primary key columns */
  ENUM_audio_generation_status_by_pk?: Maybe<Enum_Audio_Generation_Status>;
  /** fetch data from the table: "ENUM_auth_type" */
  ENUM_auth_type: Array<Enum_Auth_Type>;
  /** fetch aggregated fields from the table: "ENUM_auth_type" */
  ENUM_auth_type_aggregate: Enum_Auth_Type_Aggregate;
  /** fetch data from the table: "ENUM_auth_type" using primary key columns */
  ENUM_auth_type_by_pk?: Maybe<Enum_Auth_Type>;
  /** fetch data from the table: "ENUM_billing_artwork_payment_intent_status" */
  ENUM_billing_artwork_payment_intent_status: Array<Enum_Billing_Artwork_Payment_Intent_Status>;
  /** fetch aggregated fields from the table: "ENUM_billing_artwork_payment_intent_status" */
  ENUM_billing_artwork_payment_intent_status_aggregate: Enum_Billing_Artwork_Payment_Intent_Status_Aggregate;
  /** fetch data from the table: "ENUM_billing_artwork_payment_intent_status" using primary key columns */
  ENUM_billing_artwork_payment_intent_status_by_pk?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status>;
  /** fetch data from the table: "ENUM_billing_payment_intent_product_status" */
  ENUM_billing_payment_intent_product_status: Array<Enum_Billing_Payment_Intent_Product_Status>;
  /** fetch aggregated fields from the table: "ENUM_billing_payment_intent_product_status" */
  ENUM_billing_payment_intent_product_status_aggregate: Enum_Billing_Payment_Intent_Product_Status_Aggregate;
  /** fetch data from the table: "ENUM_billing_payment_intent_product_status" using primary key columns */
  ENUM_billing_payment_intent_product_status_by_pk?: Maybe<Enum_Billing_Payment_Intent_Product_Status>;
  /** fetch data from the table: "ENUM_billing_seller_status" */
  ENUM_billing_seller_status: Array<Enum_Billing_Seller_Status>;
  /** fetch aggregated fields from the table: "ENUM_billing_seller_status" */
  ENUM_billing_seller_status_aggregate: Enum_Billing_Seller_Status_Aggregate;
  /** fetch data from the table: "ENUM_billing_seller_status" using primary key columns */
  ENUM_billing_seller_status_by_pk?: Maybe<Enum_Billing_Seller_Status>;
  /** fetch data from the table: "ENUM_billing_subscription_period" */
  ENUM_billing_subscription_period: Array<Enum_Billing_Subscription_Period>;
  /** fetch aggregated fields from the table: "ENUM_billing_subscription_period" */
  ENUM_billing_subscription_period_aggregate: Enum_Billing_Subscription_Period_Aggregate;
  /** fetch data from the table: "ENUM_billing_subscription_period" using primary key columns */
  ENUM_billing_subscription_period_by_pk?: Maybe<Enum_Billing_Subscription_Period>;
  /** fetch data from the table: "ENUM_billing_subscription_product_type" */
  ENUM_billing_subscription_product_type: Array<Enum_Billing_Subscription_Product_Type>;
  /** fetch aggregated fields from the table: "ENUM_billing_subscription_product_type" */
  ENUM_billing_subscription_product_type_aggregate: Enum_Billing_Subscription_Product_Type_Aggregate;
  /** fetch data from the table: "ENUM_billing_subscription_product_type" using primary key columns */
  ENUM_billing_subscription_product_type_by_pk?: Maybe<Enum_Billing_Subscription_Product_Type>;
  /** fetch data from the table: "ENUM_billing_subscription_status" */
  ENUM_billing_subscription_status: Array<Enum_Billing_Subscription_Status>;
  /** fetch aggregated fields from the table: "ENUM_billing_subscription_status" */
  ENUM_billing_subscription_status_aggregate: Enum_Billing_Subscription_Status_Aggregate;
  /** fetch data from the table: "ENUM_billing_subscription_status" using primary key columns */
  ENUM_billing_subscription_status_by_pk?: Maybe<Enum_Billing_Subscription_Status>;
  /** fetch data from the table: "ENUM_blockchain" */
  ENUM_blockchain: Array<Enum_Blockchain>;
  /** fetch aggregated fields from the table: "ENUM_blockchain" */
  ENUM_blockchain_aggregate: Enum_Blockchain_Aggregate;
  /** fetch data from the table: "ENUM_blockchain" using primary key columns */
  ENUM_blockchain_by_pk?: Maybe<Enum_Blockchain>;
  /** fetch data from the table: "ENUM_collaboration_status" */
  ENUM_collaboration_status: Array<Enum_Collaboration_Status>;
  /** fetch aggregated fields from the table: "ENUM_collaboration_status" */
  ENUM_collaboration_status_aggregate: Enum_Collaboration_Status_Aggregate;
  /** fetch data from the table: "ENUM_collaboration_status" using primary key columns */
  ENUM_collaboration_status_by_pk?: Maybe<Enum_Collaboration_Status>;
  /** fetch data from the table: "ENUM_collection_type" */
  ENUM_collection_type: Array<Enum_Collection_Type>;
  /** fetch aggregated fields from the table: "ENUM_collection_type" */
  ENUM_collection_type_aggregate: Enum_Collection_Type_Aggregate;
  /** fetch data from the table: "ENUM_collection_type" using primary key columns */
  ENUM_collection_type_by_pk?: Maybe<Enum_Collection_Type>;
  /** fetch data from the table: "ENUM_connected_domain_state" */
  ENUM_connected_domain_state: Array<Enum_Connected_Domain_State>;
  /** fetch aggregated fields from the table: "ENUM_connected_domain_state" */
  ENUM_connected_domain_state_aggregate: Enum_Connected_Domain_State_Aggregate;
  /** fetch data from the table: "ENUM_connected_domain_state" using primary key columns */
  ENUM_connected_domain_state_by_pk?: Maybe<Enum_Connected_Domain_State>;
  /** fetch data from the table: "ENUM_content_type" */
  ENUM_content_type: Array<Enum_Content_Type>;
  /** fetch aggregated fields from the table: "ENUM_content_type" */
  ENUM_content_type_aggregate: Enum_Content_Type_Aggregate;
  /** fetch data from the table: "ENUM_content_type" using primary key columns */
  ENUM_content_type_by_pk?: Maybe<Enum_Content_Type>;
  /** fetch data from the table: "ENUM_context_delegate_role" */
  ENUM_context_delegate_role: Array<Enum_Context_Delegate_Role>;
  /** fetch aggregated fields from the table: "ENUM_context_delegate_role" */
  ENUM_context_delegate_role_aggregate: Enum_Context_Delegate_Role_Aggregate;
  /** fetch data from the table: "ENUM_context_delegate_role" using primary key columns */
  ENUM_context_delegate_role_by_pk?: Maybe<Enum_Context_Delegate_Role>;
  /** fetch data from the table: "ENUM_context_delegate_state" */
  ENUM_context_delegate_state: Array<Enum_Context_Delegate_State>;
  /** fetch aggregated fields from the table: "ENUM_context_delegate_state" */
  ENUM_context_delegate_state_aggregate: Enum_Context_Delegate_State_Aggregate;
  /** fetch data from the table: "ENUM_context_delegate_state" using primary key columns */
  ENUM_context_delegate_state_by_pk?: Maybe<Enum_Context_Delegate_State>;
  /** fetch data from the table: "ENUM_context_state" */
  ENUM_context_state: Array<Enum_Context_State>;
  /** fetch aggregated fields from the table: "ENUM_context_state" */
  ENUM_context_state_aggregate: Enum_Context_State_Aggregate;
  /** fetch data from the table: "ENUM_context_state" using primary key columns */
  ENUM_context_state_by_pk?: Maybe<Enum_Context_State>;
  /** fetch data from the table: "ENUM_context_type" */
  ENUM_context_type: Array<Enum_Context_Type>;
  /** fetch aggregated fields from the table: "ENUM_context_type" */
  ENUM_context_type_aggregate: Enum_Context_Type_Aggregate;
  /** fetch data from the table: "ENUM_context_type" using primary key columns */
  ENUM_context_type_by_pk?: Maybe<Enum_Context_Type>;
  /** fetch data from the table: "ENUM_discount_state" */
  ENUM_discount_state: Array<Enum_Discount_State>;
  /** fetch aggregated fields from the table: "ENUM_discount_state" */
  ENUM_discount_state_aggregate: Enum_Discount_State_Aggregate;
  /** fetch data from the table: "ENUM_discount_state" using primary key columns */
  ENUM_discount_state_by_pk?: Maybe<Enum_Discount_State>;
  /** fetch data from the table: "ENUM_discount_type" */
  ENUM_discount_type: Array<Enum_Discount_Type>;
  /** fetch aggregated fields from the table: "ENUM_discount_type" */
  ENUM_discount_type_aggregate: Enum_Discount_Type_Aggregate;
  /** fetch data from the table: "ENUM_discount_type" using primary key columns */
  ENUM_discount_type_by_pk?: Maybe<Enum_Discount_Type>;
  /** fetch data from the table: "ENUM_discount_value_type" */
  ENUM_discount_value_type: Array<Enum_Discount_Value_Type>;
  /** fetch aggregated fields from the table: "ENUM_discount_value_type" */
  ENUM_discount_value_type_aggregate: Enum_Discount_Value_Type_Aggregate;
  /** fetch data from the table: "ENUM_discount_value_type" using primary key columns */
  ENUM_discount_value_type_by_pk?: Maybe<Enum_Discount_Value_Type>;
  /** fetch data from the table: "ENUM_domain_registration_status" */
  ENUM_domain_registration_status: Array<Enum_Domain_Registration_Status>;
  /** fetch aggregated fields from the table: "ENUM_domain_registration_status" */
  ENUM_domain_registration_status_aggregate: Enum_Domain_Registration_Status_Aggregate;
  /** fetch data from the table: "ENUM_domain_registration_status" using primary key columns */
  ENUM_domain_registration_status_by_pk?: Maybe<Enum_Domain_Registration_Status>;
  /** fetch data from the table: "ENUM_dynamic_cv_exhibition_type" */
  ENUM_dynamic_cv_exhibition_type: Array<Enum_Dynamic_Cv_Exhibition_Type>;
  /** fetch aggregated fields from the table: "ENUM_dynamic_cv_exhibition_type" */
  ENUM_dynamic_cv_exhibition_type_aggregate: Enum_Dynamic_Cv_Exhibition_Type_Aggregate;
  /** fetch data from the table: "ENUM_dynamic_cv_exhibition_type" using primary key columns */
  ENUM_dynamic_cv_exhibition_type_by_pk?: Maybe<Enum_Dynamic_Cv_Exhibition_Type>;
  /** fetch data from the table: "ENUM_dynamic_cv_section_type" */
  ENUM_dynamic_cv_section_type: Array<Enum_Dynamic_Cv_Section_Type>;
  /** fetch aggregated fields from the table: "ENUM_dynamic_cv_section_type" */
  ENUM_dynamic_cv_section_type_aggregate: Enum_Dynamic_Cv_Section_Type_Aggregate;
  /** fetch data from the table: "ENUM_dynamic_cv_section_type" using primary key columns */
  ENUM_dynamic_cv_section_type_by_pk?: Maybe<Enum_Dynamic_Cv_Section_Type>;
  /** fetch data from the table: "ENUM_dynamic_cv_status" */
  ENUM_dynamic_cv_status: Array<Enum_Dynamic_Cv_Status>;
  /** fetch aggregated fields from the table: "ENUM_dynamic_cv_status" */
  ENUM_dynamic_cv_status_aggregate: Enum_Dynamic_Cv_Status_Aggregate;
  /** fetch data from the table: "ENUM_dynamic_cv_status" using primary key columns */
  ENUM_dynamic_cv_status_by_pk?: Maybe<Enum_Dynamic_Cv_Status>;
  /** fetch data from the table: "ENUM_edition_status" */
  ENUM_edition_status: Array<Enum_Edition_Status>;
  /** fetch aggregated fields from the table: "ENUM_edition_status" */
  ENUM_edition_status_aggregate: Enum_Edition_Status_Aggregate;
  /** fetch data from the table: "ENUM_edition_status" using primary key columns */
  ENUM_edition_status_by_pk?: Maybe<Enum_Edition_Status>;
  /** fetch data from the table: "ENUM_email_language" */
  ENUM_email_language: Array<Enum_Email_Language>;
  /** fetch aggregated fields from the table: "ENUM_email_language" */
  ENUM_email_language_aggregate: Enum_Email_Language_Aggregate;
  /** fetch data from the table: "ENUM_email_language" using primary key columns */
  ENUM_email_language_by_pk?: Maybe<Enum_Email_Language>;
  /** fetch data from the table: "ENUM_email_status" */
  ENUM_email_status: Array<Enum_Email_Status>;
  /** fetch aggregated fields from the table: "ENUM_email_status" */
  ENUM_email_status_aggregate: Enum_Email_Status_Aggregate;
  /** fetch data from the table: "ENUM_email_status" using primary key columns */
  ENUM_email_status_by_pk?: Maybe<Enum_Email_Status>;
  /** fetch data from the table: "ENUM_email_validation_token_status" */
  ENUM_email_validation_token_status: Array<Enum_Email_Validation_Token_Status>;
  /** fetch aggregated fields from the table: "ENUM_email_validation_token_status" */
  ENUM_email_validation_token_status_aggregate: Enum_Email_Validation_Token_Status_Aggregate;
  /** fetch data from the table: "ENUM_email_validation_token_status" using primary key columns */
  ENUM_email_validation_token_status_by_pk?: Maybe<Enum_Email_Validation_Token_Status>;
  /** fetch data from the table: "ENUM_file_metadata_status" */
  ENUM_file_metadata_status: Array<Enum_File_Metadata_Status>;
  /** fetch aggregated fields from the table: "ENUM_file_metadata_status" */
  ENUM_file_metadata_status_aggregate: Enum_File_Metadata_Status_Aggregate;
  /** fetch data from the table: "ENUM_file_metadata_status" using primary key columns */
  ENUM_file_metadata_status_by_pk?: Maybe<Enum_File_Metadata_Status>;
  /** fetch data from the table: "ENUM_flags_type" */
  ENUM_flags_type: Array<Enum_Flags_Type>;
  /** fetch aggregated fields from the table: "ENUM_flags_type" */
  ENUM_flags_type_aggregate: Enum_Flags_Type_Aggregate;
  /** fetch data from the table: "ENUM_flags_type" using primary key columns */
  ENUM_flags_type_by_pk?: Maybe<Enum_Flags_Type>;
  /** fetch data from the table: "ENUM_forge_configuration_type" */
  ENUM_forge_configuration_type: Array<Enum_Forge_Configuration_Type>;
  /** fetch aggregated fields from the table: "ENUM_forge_configuration_type" */
  ENUM_forge_configuration_type_aggregate: Enum_Forge_Configuration_Type_Aggregate;
  /** fetch data from the table: "ENUM_forge_configuration_type" using primary key columns */
  ENUM_forge_configuration_type_by_pk?: Maybe<Enum_Forge_Configuration_Type>;
  /** fetch data from the table: "ENUM_forge_status" */
  ENUM_forge_status: Array<Enum_Forge_Status>;
  /** fetch aggregated fields from the table: "ENUM_forge_status" */
  ENUM_forge_status_aggregate: Enum_Forge_Status_Aggregate;
  /** fetch data from the table: "ENUM_forge_status" using primary key columns */
  ENUM_forge_status_by_pk?: Maybe<Enum_Forge_Status>;
  /** fetch data from the table: "ENUM_infraction_flag_type" */
  ENUM_infraction_flag_type: Array<Enum_Infraction_Flag_Type>;
  /** fetch aggregated fields from the table: "ENUM_infraction_flag_type" */
  ENUM_infraction_flag_type_aggregate: Enum_Infraction_Flag_Type_Aggregate;
  /** fetch data from the table: "ENUM_infraction_flag_type" using primary key columns */
  ENUM_infraction_flag_type_by_pk?: Maybe<Enum_Infraction_Flag_Type>;
  /** fetch data from the table: "ENUM_interest_type" */
  ENUM_interest_type: Array<Enum_Interest_Type>;
  /** fetch aggregated fields from the table: "ENUM_interest_type" */
  ENUM_interest_type_aggregate: Enum_Interest_Type_Aggregate;
  /** fetch data from the table: "ENUM_interest_type" using primary key columns */
  ENUM_interest_type_by_pk?: Maybe<Enum_Interest_Type>;
  /** fetch data from the table: "ENUM_language_preference" */
  ENUM_language_preference: Array<Enum_Language_Preference>;
  /** fetch aggregated fields from the table: "ENUM_language_preference" */
  ENUM_language_preference_aggregate: Enum_Language_Preference_Aggregate;
  /** fetch data from the table: "ENUM_language_preference" using primary key columns */
  ENUM_language_preference_by_pk?: Maybe<Enum_Language_Preference>;
  /** fetch data from the table: "ENUM_my_website_artwork_info" */
  ENUM_my_website_artwork_info: Array<Enum_My_Website_Artwork_Info>;
  /** fetch aggregated fields from the table: "ENUM_my_website_artwork_info" */
  ENUM_my_website_artwork_info_aggregate: Enum_My_Website_Artwork_Info_Aggregate;
  /** fetch data from the table: "ENUM_my_website_artwork_info" using primary key columns */
  ENUM_my_website_artwork_info_by_pk?: Maybe<Enum_My_Website_Artwork_Info>;
  /** fetch data from the table: "ENUM_my_website_filters" */
  ENUM_my_website_filters: Array<Enum_My_Website_Filters>;
  /** fetch aggregated fields from the table: "ENUM_my_website_filters" */
  ENUM_my_website_filters_aggregate: Enum_My_Website_Filters_Aggregate;
  /** fetch data from the table: "ENUM_my_website_filters" using primary key columns */
  ENUM_my_website_filters_by_pk?: Maybe<Enum_My_Website_Filters>;
  /** fetch data from the table: "ENUM_my_website_layout" */
  ENUM_my_website_layout: Array<Enum_My_Website_Layout>;
  /** fetch aggregated fields from the table: "ENUM_my_website_layout" */
  ENUM_my_website_layout_aggregate: Enum_My_Website_Layout_Aggregate;
  /** fetch data from the table: "ENUM_my_website_layout" using primary key columns */
  ENUM_my_website_layout_by_pk?: Maybe<Enum_My_Website_Layout>;
  /** fetch data from the table: "ENUM_my_website_navigation" */
  ENUM_my_website_navigation: Array<Enum_My_Website_Navigation>;
  /** fetch aggregated fields from the table: "ENUM_my_website_navigation" */
  ENUM_my_website_navigation_aggregate: Enum_My_Website_Navigation_Aggregate;
  /** fetch data from the table: "ENUM_my_website_navigation" using primary key columns */
  ENUM_my_website_navigation_by_pk?: Maybe<Enum_My_Website_Navigation>;
  /** fetch data from the table: "ENUM_my_website_theme" */
  ENUM_my_website_theme: Array<Enum_My_Website_Theme>;
  /** fetch aggregated fields from the table: "ENUM_my_website_theme" */
  ENUM_my_website_theme_aggregate: Enum_My_Website_Theme_Aggregate;
  /** fetch data from the table: "ENUM_my_website_theme" using primary key columns */
  ENUM_my_website_theme_by_pk?: Maybe<Enum_My_Website_Theme>;
  /** fetch data from the table: "ENUM_notification_channel" */
  ENUM_notification_channel: Array<Enum_Notification_Channel>;
  /** fetch aggregated fields from the table: "ENUM_notification_channel" */
  ENUM_notification_channel_aggregate: Enum_Notification_Channel_Aggregate;
  /** fetch data from the table: "ENUM_notification_channel" using primary key columns */
  ENUM_notification_channel_by_pk?: Maybe<Enum_Notification_Channel>;
  /** fetch data from the table: "ENUM_notification_group" */
  ENUM_notification_group: Array<Enum_Notification_Group>;
  /** fetch aggregated fields from the table: "ENUM_notification_group" */
  ENUM_notification_group_aggregate: Enum_Notification_Group_Aggregate;
  /** fetch data from the table: "ENUM_notification_group" using primary key columns */
  ENUM_notification_group_by_pk?: Maybe<Enum_Notification_Group>;
  /** fetch data from the table: "ENUM_position_type" */
  ENUM_position_type: Array<Enum_Position_Type>;
  /** fetch aggregated fields from the table: "ENUM_position_type" */
  ENUM_position_type_aggregate: Enum_Position_Type_Aggregate;
  /** fetch data from the table: "ENUM_position_type" using primary key columns */
  ENUM_position_type_by_pk?: Maybe<Enum_Position_Type>;
  /** fetch data from the table: "ENUM_price_scope" */
  ENUM_price_scope: Array<Enum_Price_Scope>;
  /** fetch aggregated fields from the table: "ENUM_price_scope" */
  ENUM_price_scope_aggregate: Enum_Price_Scope_Aggregate;
  /** fetch data from the table: "ENUM_price_scope" using primary key columns */
  ENUM_price_scope_by_pk?: Maybe<Enum_Price_Scope>;
  /** fetch data from the table: "ENUM_print_technique" */
  ENUM_print_technique: Array<Enum_Print_Technique>;
  /** fetch aggregated fields from the table: "ENUM_print_technique" */
  ENUM_print_technique_aggregate: Enum_Print_Technique_Aggregate;
  /** fetch data from the table: "ENUM_print_technique" using primary key columns */
  ENUM_print_technique_by_pk?: Maybe<Enum_Print_Technique>;
  /** fetch data from the table: "ENUM_profile_background_type" */
  ENUM_profile_background_type: Array<Enum_Profile_Background_Type>;
  /** fetch aggregated fields from the table: "ENUM_profile_background_type" */
  ENUM_profile_background_type_aggregate: Enum_Profile_Background_Type_Aggregate;
  /** fetch data from the table: "ENUM_profile_background_type" using primary key columns */
  ENUM_profile_background_type_by_pk?: Maybe<Enum_Profile_Background_Type>;
  /** fetch data from the table: "ENUM_profile_invitation_status" */
  ENUM_profile_invitation_status: Array<Enum_Profile_Invitation_Status>;
  /** fetch aggregated fields from the table: "ENUM_profile_invitation_status" */
  ENUM_profile_invitation_status_aggregate: Enum_Profile_Invitation_Status_Aggregate;
  /** fetch data from the table: "ENUM_profile_invitation_status" using primary key columns */
  ENUM_profile_invitation_status_by_pk?: Maybe<Enum_Profile_Invitation_Status>;
  /** fetch data from the table: "ENUM_profile_public_status" */
  ENUM_profile_public_status: Array<Enum_Profile_Public_Status>;
  /** fetch aggregated fields from the table: "ENUM_profile_public_status" */
  ENUM_profile_public_status_aggregate: Enum_Profile_Public_Status_Aggregate;
  /** fetch data from the table: "ENUM_profile_public_status" using primary key columns */
  ENUM_profile_public_status_by_pk?: Maybe<Enum_Profile_Public_Status>;
  /** fetch data from the table: "ENUM_profile_region" */
  ENUM_profile_region: Array<Enum_Profile_Region>;
  /** fetch aggregated fields from the table: "ENUM_profile_region" */
  ENUM_profile_region_aggregate: Enum_Profile_Region_Aggregate;
  /** fetch data from the table: "ENUM_profile_region" using primary key columns */
  ENUM_profile_region_by_pk?: Maybe<Enum_Profile_Region>;
  /** fetch data from the table: "ENUM_profile_status" */
  ENUM_profile_status: Array<Enum_Profile_Status>;
  /** fetch aggregated fields from the table: "ENUM_profile_status" */
  ENUM_profile_status_aggregate: Enum_Profile_Status_Aggregate;
  /** fetch data from the table: "ENUM_profile_status" using primary key columns */
  ENUM_profile_status_by_pk?: Maybe<Enum_Profile_Status>;
  /** fetch data from the table: "ENUM_profile_theme" */
  ENUM_profile_theme: Array<Enum_Profile_Theme>;
  /** fetch aggregated fields from the table: "ENUM_profile_theme" */
  ENUM_profile_theme_aggregate: Enum_Profile_Theme_Aggregate;
  /** fetch data from the table: "ENUM_profile_theme" using primary key columns */
  ENUM_profile_theme_by_pk?: Maybe<Enum_Profile_Theme>;
  /** fetch data from the table: "ENUM_profile_type" */
  ENUM_profile_type: Array<Enum_Profile_Type>;
  /** fetch aggregated fields from the table: "ENUM_profile_type" */
  ENUM_profile_type_aggregate: Enum_Profile_Type_Aggregate;
  /** fetch data from the table: "ENUM_profile_type" using primary key columns */
  ENUM_profile_type_by_pk?: Maybe<Enum_Profile_Type>;
  /** fetch data from the table: "ENUM_pseudo_user_action" */
  ENUM_pseudo_user_action: Array<Enum_Pseudo_User_Action>;
  /** fetch aggregated fields from the table: "ENUM_pseudo_user_action" */
  ENUM_pseudo_user_action_aggregate: Enum_Pseudo_User_Action_Aggregate;
  /** fetch data from the table: "ENUM_pseudo_user_action" using primary key columns */
  ENUM_pseudo_user_action_by_pk?: Maybe<Enum_Pseudo_User_Action>;
  /** fetch data from the table: "ENUM_push_notification_status" */
  ENUM_push_notification_status: Array<Enum_Push_Notification_Status>;
  /** fetch aggregated fields from the table: "ENUM_push_notification_status" */
  ENUM_push_notification_status_aggregate: Enum_Push_Notification_Status_Aggregate;
  /** fetch data from the table: "ENUM_push_notification_status" using primary key columns */
  ENUM_push_notification_status_by_pk?: Maybe<Enum_Push_Notification_Status>;
  /** fetch data from the table: "ENUM_push_notification_type" */
  ENUM_push_notification_type: Array<Enum_Push_Notification_Type>;
  /** fetch aggregated fields from the table: "ENUM_push_notification_type" */
  ENUM_push_notification_type_aggregate: Enum_Push_Notification_Type_Aggregate;
  /** fetch data from the table: "ENUM_push_notification_type" using primary key columns */
  ENUM_push_notification_type_by_pk?: Maybe<Enum_Push_Notification_Type>;
  /** fetch data from the table: "ENUM_questionnaire_type" */
  ENUM_questionnaire_type: Array<Enum_Questionnaire_Type>;
  /** fetch aggregated fields from the table: "ENUM_questionnaire_type" */
  ENUM_questionnaire_type_aggregate: Enum_Questionnaire_Type_Aggregate;
  /** fetch data from the table: "ENUM_questionnaire_type" using primary key columns */
  ENUM_questionnaire_type_by_pk?: Maybe<Enum_Questionnaire_Type>;
  /** fetch data from the table: "ENUM_render_request_status" */
  ENUM_render_request_status: Array<Enum_Render_Request_Status>;
  /** fetch aggregated fields from the table: "ENUM_render_request_status" */
  ENUM_render_request_status_aggregate: Enum_Render_Request_Status_Aggregate;
  /** fetch data from the table: "ENUM_render_request_status" using primary key columns */
  ENUM_render_request_status_by_pk?: Maybe<Enum_Render_Request_Status>;
  /** fetch data from the table: "ENUM_render_request_template_type" */
  ENUM_render_request_template_type: Array<Enum_Render_Request_Template_Type>;
  /** fetch aggregated fields from the table: "ENUM_render_request_template_type" */
  ENUM_render_request_template_type_aggregate: Enum_Render_Request_Template_Type_Aggregate;
  /** fetch data from the table: "ENUM_render_request_template_type" using primary key columns */
  ENUM_render_request_template_type_by_pk?: Maybe<Enum_Render_Request_Template_Type>;
  /** fetch data from the table: "ENUM_restricted_handle_names" */
  ENUM_restricted_handle_names: Array<Enum_Restricted_Handle_Names>;
  /** fetch aggregated fields from the table: "ENUM_restricted_handle_names" */
  ENUM_restricted_handle_names_aggregate: Enum_Restricted_Handle_Names_Aggregate;
  /** fetch data from the table: "ENUM_restricted_handle_names" using primary key columns */
  ENUM_restricted_handle_names_by_pk?: Maybe<Enum_Restricted_Handle_Names>;
  /** fetch data from the table: "ENUM_size" */
  ENUM_size: Array<Enum_Size>;
  /** fetch aggregated fields from the table: "ENUM_size" */
  ENUM_size_aggregate: Enum_Size_Aggregate;
  /** fetch data from the table: "ENUM_size" using primary key columns */
  ENUM_size_by_pk?: Maybe<Enum_Size>;
  /** fetch data from the table: "ENUM_social_graph_followers_state" */
  ENUM_social_graph_followers_state: Array<Enum_Social_Graph_Followers_State>;
  /** fetch aggregated fields from the table: "ENUM_social_graph_followers_state" */
  ENUM_social_graph_followers_state_aggregate: Enum_Social_Graph_Followers_State_Aggregate;
  /** fetch data from the table: "ENUM_social_graph_followers_state" using primary key columns */
  ENUM_social_graph_followers_state_by_pk?: Maybe<Enum_Social_Graph_Followers_State>;
  /** fetch data from the table: "ENUM_social_link_status" */
  ENUM_social_link_status: Array<Enum_Social_Link_Status>;
  /** fetch aggregated fields from the table: "ENUM_social_link_status" */
  ENUM_social_link_status_aggregate: Enum_Social_Link_Status_Aggregate;
  /** fetch data from the table: "ENUM_social_link_status" using primary key columns */
  ENUM_social_link_status_by_pk?: Maybe<Enum_Social_Link_Status>;
  /** fetch data from the table: "ENUM_social_link_type" */
  ENUM_social_link_type: Array<Enum_Social_Link_Type>;
  /** fetch aggregated fields from the table: "ENUM_social_link_type" */
  ENUM_social_link_type_aggregate: Enum_Social_Link_Type_Aggregate;
  /** fetch data from the table: "ENUM_social_link_type" using primary key columns */
  ENUM_social_link_type_by_pk?: Maybe<Enum_Social_Link_Type>;
  /** fetch data from the table: "ENUM_timed_event_name" */
  ENUM_timed_event_name: Array<Enum_Timed_Event_Name>;
  /** fetch aggregated fields from the table: "ENUM_timed_event_name" */
  ENUM_timed_event_name_aggregate: Enum_Timed_Event_Name_Aggregate;
  /** fetch data from the table: "ENUM_timed_event_name" using primary key columns */
  ENUM_timed_event_name_by_pk?: Maybe<Enum_Timed_Event_Name>;
  /** fetch data from the table: "ENUM_token_storage_status" */
  ENUM_token_storage_status: Array<Enum_Token_Storage_Status>;
  /** fetch aggregated fields from the table: "ENUM_token_storage_status" */
  ENUM_token_storage_status_aggregate: Enum_Token_Storage_Status_Aggregate;
  /** fetch data from the table: "ENUM_token_storage_status" using primary key columns */
  ENUM_token_storage_status_by_pk?: Maybe<Enum_Token_Storage_Status>;
  /** fetch data from the table: "ENUM_transaction_origin" */
  ENUM_transaction_origin: Array<Enum_Transaction_Origin>;
  /** fetch aggregated fields from the table: "ENUM_transaction_origin" */
  ENUM_transaction_origin_aggregate: Enum_Transaction_Origin_Aggregate;
  /** fetch data from the table: "ENUM_transaction_origin" using primary key columns */
  ENUM_transaction_origin_by_pk?: Maybe<Enum_Transaction_Origin>;
  /** fetch data from the table: "ENUM_transaction_status" */
  ENUM_transaction_status: Array<Enum_Transaction_Status>;
  /** fetch aggregated fields from the table: "ENUM_transaction_status" */
  ENUM_transaction_status_aggregate: Enum_Transaction_Status_Aggregate;
  /** fetch data from the table: "ENUM_transaction_status" using primary key columns */
  ENUM_transaction_status_by_pk?: Maybe<Enum_Transaction_Status>;
  /** fetch data from the table: "ENUM_tutorial_status" */
  ENUM_tutorial_status: Array<Enum_Tutorial_Status>;
  /** fetch aggregated fields from the table: "ENUM_tutorial_status" */
  ENUM_tutorial_status_aggregate: Enum_Tutorial_Status_Aggregate;
  /** fetch data from the table: "ENUM_tutorial_status" using primary key columns */
  ENUM_tutorial_status_by_pk?: Maybe<Enum_Tutorial_Status>;
  /** fetch data from the table: "ENUM_tutorial_step_type" */
  ENUM_tutorial_step_type: Array<Enum_Tutorial_Step_Type>;
  /** fetch aggregated fields from the table: "ENUM_tutorial_step_type" */
  ENUM_tutorial_step_type_aggregate: Enum_Tutorial_Step_Type_Aggregate;
  /** fetch data from the table: "ENUM_tutorial_step_type" using primary key columns */
  ENUM_tutorial_step_type_by_pk?: Maybe<Enum_Tutorial_Step_Type>;
  /** fetch data from the table: "ENUM_tutorial_type" */
  ENUM_tutorial_type: Array<Enum_Tutorial_Type>;
  /** fetch aggregated fields from the table: "ENUM_tutorial_type" */
  ENUM_tutorial_type_aggregate: Enum_Tutorial_Type_Aggregate;
  /** fetch data from the table: "ENUM_tutorial_type" using primary key columns */
  ENUM_tutorial_type_by_pk?: Maybe<Enum_Tutorial_Type>;
  /** fetch data from the table: "ENUM_vumark_status" */
  ENUM_vumark_status: Array<Enum_Vumark_Status>;
  /** fetch aggregated fields from the table: "ENUM_vumark_status" */
  ENUM_vumark_status_aggregate: Enum_Vumark_Status_Aggregate;
  /** fetch data from the table: "ENUM_vumark_status" using primary key columns */
  ENUM_vumark_status_by_pk?: Maybe<Enum_Vumark_Status>;
  /** fetch data from the table: "ENUM_vumark_type" */
  ENUM_vumark_type: Array<Enum_Vumark_Type>;
  /** fetch aggregated fields from the table: "ENUM_vumark_type" */
  ENUM_vumark_type_aggregate: Enum_Vumark_Type_Aggregate;
  /** fetch data from the table: "ENUM_vumark_type" using primary key columns */
  ENUM_vumark_type_by_pk?: Maybe<Enum_Vumark_Type>;
  /** fetch data from the table: "Edition" */
  Edition: Array<Edition>;
  /** fetch data from the table: "Edition_By_Size" */
  Edition_By_Size: Array<Edition_By_Size>;
  /** fetch aggregated fields from the table: "Edition_By_Size" */
  Edition_By_Size_aggregate: Edition_By_Size_Aggregate;
  /** fetch data from the table: "Edition_By_Size" using primary key columns */
  Edition_By_Size_by_pk?: Maybe<Edition_By_Size>;
  /** fetch data from the table: "Edition_By_Type" */
  Edition_By_Type: Array<Edition_By_Type>;
  /** fetch aggregated fields from the table: "Edition_By_Type" */
  Edition_By_Type_aggregate: Edition_By_Type_Aggregate;
  /** fetch data from the table: "Edition_By_Type" using primary key columns */
  Edition_By_Type_by_pk?: Maybe<Edition_By_Type>;
  /** fetch data from the table: "Edition_Type" */
  Edition_Type: Array<Edition_Type>;
  /** fetch aggregated fields from the table: "Edition_Type" */
  Edition_Type_aggregate: Edition_Type_Aggregate;
  /** fetch data from the table: "Edition_Type" using primary key columns */
  Edition_Type_by_pk?: Maybe<Edition_Type>;
  /** fetch aggregated fields from the table: "Edition" */
  Edition_aggregate: Edition_Aggregate;
  /** fetch data from the table: "Edition" using primary key columns */
  Edition_by_pk?: Maybe<Edition>;
  /** fetch data from the table: "Edition_events" */
  Edition_events: Array<Edition_Events>;
  /** fetch aggregated fields from the table: "Edition_events" */
  Edition_events_aggregate: Edition_Events_Aggregate;
  /** fetch data from the table: "Edition_events" using primary key columns */
  Edition_events_by_pk?: Maybe<Edition_Events>;
  /** fetch data from the table: "Email_validation_token" */
  Email_validation_token: Array<Email_Validation_Token>;
  /** fetch aggregated fields from the table: "Email_validation_token" */
  Email_validation_token_aggregate: Email_Validation_Token_Aggregate;
  /** fetch data from the table: "Email_validation_token" using primary key columns */
  Email_validation_token_by_pk?: Maybe<Email_Validation_Token>;
  /** fetch data from the table: "Emails" */
  Emails: Array<Emails>;
  /** fetch aggregated fields from the table: "Emails" */
  Emails_aggregate: Emails_Aggregate;
  /** fetch data from the table: "Emails" using primary key columns */
  Emails_by_pk?: Maybe<Emails>;
  /** fetch data from the table: "Explore_artists" */
  Explore_artists: Array<Explore_Artists>;
  /** fetch aggregated fields from the table: "Explore_artists" */
  Explore_artists_aggregate: Explore_Artists_Aggregate;
  /** fetch data from the table: "Explore_artists_artworks" */
  Explore_artists_artworks: Array<Explore_Artists_Artworks>;
  /** fetch aggregated fields from the table: "Explore_artists_artworks" */
  Explore_artists_artworks_aggregate: Explore_Artists_Artworks_Aggregate;
  /** fetch data from the table: "Explore_artists_curation_score" */
  Explore_artists_curation_score: Array<Explore_Artists_Curation_Score>;
  /** fetch aggregated fields from the table: "Explore_artists_curation_score" */
  Explore_artists_curation_score_aggregate: Explore_Artists_Curation_Score_Aggregate;
  /** fetch data from the table: "Explore_artists_disciplines" */
  Explore_artists_disciplines: Array<Explore_Artists_Disciplines>;
  /** fetch aggregated fields from the table: "Explore_artists_disciplines" */
  Explore_artists_disciplines_aggregate: Explore_Artists_Disciplines_Aggregate;
  /** fetch data from the table: "FCM_Token" */
  FCM_Token: Array<Fcm_Token>;
  /** fetch aggregated fields from the table: "FCM_Token" */
  FCM_Token_aggregate: Fcm_Token_Aggregate;
  /** fetch data from the table: "FCM_Token" using primary key columns */
  FCM_Token_by_pk?: Maybe<Fcm_Token>;
  /** fetch data from the table: "Features" */
  Features: Array<Features>;
  /** fetch aggregated fields from the table: "Features" */
  Features_aggregate: Features_Aggregate;
  /** fetch data from the table: "Features" using primary key columns */
  Features_by_pk?: Maybe<Features>;
  /** An array relationship */
  File_metadata: Array<File_Metadata>;
  /** An aggregate relationship */
  File_metadata_aggregate: File_Metadata_Aggregate;
  /** fetch data from the table: "File_metadata" using primary key columns */
  File_metadata_by_pk?: Maybe<File_Metadata>;
  /** fetch data from the table: "Firebase_profile" */
  Firebase_profile: Array<Firebase_Profile>;
  /** fetch aggregated fields from the table: "Firebase_profile" */
  Firebase_profile_aggregate: Firebase_Profile_Aggregate;
  /** fetch data from the table: "Firebase_profile" using primary key columns */
  Firebase_profile_by_pk?: Maybe<Firebase_Profile>;
  /** fetch data from the table: "Flagged_users" */
  Flagged_users: Array<Flagged_Users>;
  /** fetch aggregated fields from the table: "Flagged_users" */
  Flagged_users_aggregate: Flagged_Users_Aggregate;
  /** fetch data from the table: "Flagged_users" using primary key columns */
  Flagged_users_by_pk?: Maybe<Flagged_Users>;
  /** fetch data from the table: "Flat_context_access" */
  Flat_context_access: Array<Flat_Context_Access>;
  /** fetch aggregated fields from the table: "Flat_context_access" */
  Flat_context_access_aggregate: Flat_Context_Access_Aggregate;
  /** fetch data from the table: "Following_artists_art_universe_listing" */
  Following_artists_art_universe_listing: Array<Following_Artists_Art_Universe_Listing>;
  /** fetch aggregated fields from the table: "Following_artists_art_universe_listing" */
  Following_artists_art_universe_listing_aggregate: Following_Artists_Art_Universe_Listing_Aggregate;
  /** fetch data from the table: "Forge_InProcess_Task" */
  Forge_InProcess_Task: Array<Forge_InProcess_Task>;
  /** fetch aggregated fields from the table: "Forge_InProcess_Task" */
  Forge_InProcess_Task_aggregate: Forge_InProcess_Task_Aggregate;
  /** fetch data from the table: "Forge_InProcess_Task" using primary key columns */
  Forge_InProcess_Task_by_pk?: Maybe<Forge_InProcess_Task>;
  /** fetch data from the table: "Forge_tasks" */
  Forge_tasks: Array<Forge_Tasks>;
  /** fetch aggregated fields from the table: "Forge_tasks" */
  Forge_tasks_aggregate: Forge_Tasks_Aggregate;
  /** fetch data from the table: "Forge_tasks" using primary key columns */
  Forge_tasks_by_pk?: Maybe<Forge_Tasks>;
  /** fetch data from the table: "Gallery_explore_info" */
  Gallery_explore_info: Array<Gallery_Explore_Info>;
  /** fetch aggregated fields from the table: "Gallery_explore_info" */
  Gallery_explore_info_aggregate: Gallery_Explore_Info_Aggregate;
  /** fetch data from the table: "Gallery_explore_info_with_city_data" */
  Gallery_explore_info_with_city_data: Array<Gallery_Explore_Info_With_City_Data>;
  /** fetch aggregated fields from the table: "Gallery_explore_info_with_city_data" */
  Gallery_explore_info_with_city_data_aggregate: Gallery_Explore_Info_With_City_Data_Aggregate;
  /** fetch data from the table: "Global_properties" */
  Global_properties: Array<Global_Properties>;
  /** fetch aggregated fields from the table: "Global_properties" */
  Global_properties_aggregate: Global_Properties_Aggregate;
  /** fetch data from the table: "Global_properties" using primary key columns */
  Global_properties_by_pk?: Maybe<Global_Properties>;
  /** fetch data from the table: "Graveyard_Artwork" */
  Graveyard_Artwork: Array<Graveyard_Artwork>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork" */
  Graveyard_Artwork_aggregate: Graveyard_Artwork_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork" using primary key columns */
  Graveyard_Artwork_by_pk?: Maybe<Graveyard_Artwork>;
  /** fetch data from the table: "Graveyard_Artwork_details" */
  Graveyard_Artwork_details: Array<Graveyard_Artwork_Details>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_details" */
  Graveyard_Artwork_details_aggregate: Graveyard_Artwork_Details_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_details" using primary key columns */
  Graveyard_Artwork_details_by_pk?: Maybe<Graveyard_Artwork_Details>;
  /** fetch data from the table: "Graveyard_Artwork_details_translation" */
  Graveyard_Artwork_details_translation: Array<Graveyard_Artwork_Details_Translation>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_details_translation" */
  Graveyard_Artwork_details_translation_aggregate: Graveyard_Artwork_Details_Translation_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_details_translation" using primary key columns */
  Graveyard_Artwork_details_translation_by_pk?: Maybe<Graveyard_Artwork_Details_Translation>;
  /** fetch data from the table: "Graveyard_Artwork_events" */
  Graveyard_Artwork_events: Array<Graveyard_Artwork_Events>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_events" */
  Graveyard_Artwork_events_aggregate: Graveyard_Artwork_Events_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_events" using primary key columns */
  Graveyard_Artwork_events_by_pk?: Maybe<Graveyard_Artwork_Events>;
  /** fetch data from the table: "Graveyard_Artwork_prices" */
  Graveyard_Artwork_prices: Array<Graveyard_Artwork_Prices>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_prices" */
  Graveyard_Artwork_prices_aggregate: Graveyard_Artwork_Prices_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_prices" using primary key columns */
  Graveyard_Artwork_prices_by_pk?: Maybe<Graveyard_Artwork_Prices>;
  /** fetch data from the table: "Graveyard_Artwork_secondary_media" */
  Graveyard_Artwork_secondary_media: Array<Graveyard_Artwork_Secondary_Media>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_secondary_media" */
  Graveyard_Artwork_secondary_media_aggregate: Graveyard_Artwork_Secondary_Media_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_secondary_media" using primary key columns */
  Graveyard_Artwork_secondary_media_by_pk?: Maybe<Graveyard_Artwork_Secondary_Media>;
  /** fetch data from the table: "Graveyard_Artwork_story" */
  Graveyard_Artwork_story: Array<Graveyard_Artwork_Story>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_story" */
  Graveyard_Artwork_story_aggregate: Graveyard_Artwork_Story_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_story" using primary key columns */
  Graveyard_Artwork_story_by_pk?: Maybe<Graveyard_Artwork_Story>;
  /** fetch data from the table: "Graveyard_Artwork_story_media" */
  Graveyard_Artwork_story_media: Array<Graveyard_Artwork_Story_Media>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_story_media" */
  Graveyard_Artwork_story_media_aggregate: Graveyard_Artwork_Story_Media_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_story_media" using primary key columns */
  Graveyard_Artwork_story_media_by_pk?: Maybe<Graveyard_Artwork_Story_Media>;
  /** fetch data from the table: "Graveyard_Artwork_to_Collection" */
  Graveyard_Artwork_to_Collection: Array<Graveyard_Artwork_To_Collection>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_to_Collection" */
  Graveyard_Artwork_to_Collection_aggregate: Graveyard_Artwork_To_Collection_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_to_Collection" using primary key columns */
  Graveyard_Artwork_to_Collection_by_pk?: Maybe<Graveyard_Artwork_To_Collection>;
  /** fetch data from the table: "Graveyard_Artwork_transactions" */
  Graveyard_Artwork_transactions: Array<Graveyard_Artwork_Transactions>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_transactions" */
  Graveyard_Artwork_transactions_aggregate: Graveyard_Artwork_Transactions_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_transactions" using primary key columns */
  Graveyard_Artwork_transactions_by_pk?: Maybe<Graveyard_Artwork_Transactions>;
  /** fetch data from the table: "Graveyard_Purchase_inquires" */
  Graveyard_Purchase_inquires: Array<Graveyard_Purchase_Inquires>;
  /** fetch aggregated fields from the table: "Graveyard_Purchase_inquires" */
  Graveyard_Purchase_inquires_aggregate: Graveyard_Purchase_Inquires_Aggregate;
  /** fetch data from the table: "Graveyard_Purchase_inquires_outside_users" */
  Graveyard_Purchase_inquires_outside_users: Array<Graveyard_Purchase_Inquires_Outside_Users>;
  /** fetch aggregated fields from the table: "Graveyard_Purchase_inquires_outside_users" */
  Graveyard_Purchase_inquires_outside_users_aggregate: Graveyard_Purchase_Inquires_Outside_Users_Aggregate;
  /** fetch data from the table: "Inconsistent_missing_domain_renewal_subscription" */
  Inconsistent_missing_domain_renewal_subscription: Array<Inconsistent_Missing_Domain_Renewal_Subscription>;
  /** fetch aggregated fields from the table: "Inconsistent_missing_domain_renewal_subscription" */
  Inconsistent_missing_domain_renewal_subscription_aggregate: Inconsistent_Missing_Domain_Renewal_Subscription_Aggregate;
  /** An array relationship */
  Interests: Array<Interests>;
  /** An aggregate relationship */
  Interests_aggregate: Interests_Aggregate;
  /** fetch data from the table: "Interests" using primary key columns */
  Interests_by_pk?: Maybe<Interests>;
  /** fetch data from the table: "Kaleido_general_settings" */
  Kaleido_general_settings: Array<Kaleido_General_Settings>;
  /** fetch aggregated fields from the table: "Kaleido_general_settings" */
  Kaleido_general_settings_aggregate: Kaleido_General_Settings_Aggregate;
  /** fetch data from the table: "Kaleido_general_settings" using primary key columns */
  Kaleido_general_settings_by_pk?: Maybe<Kaleido_General_Settings>;
  /** fetch data from the table: "Kaleido_live_listing" */
  Kaleido_live_listing: Array<Kaleido_Live_Listing>;
  /** fetch aggregated fields from the table: "Kaleido_live_listing" */
  Kaleido_live_listing_aggregate: Kaleido_Live_Listing_Aggregate;
  /** fetch data from the table: "Landing_page_artwork" */
  Landing_page_artwork: Array<Landing_Page_Artwork>;
  /** fetch aggregated fields from the table: "Landing_page_artwork" */
  Landing_page_artwork_aggregate: Landing_Page_Artwork_Aggregate;
  /** fetch data from the table: "Landing_page_artwork" using primary key columns */
  Landing_page_artwork_by_pk?: Maybe<Landing_Page_Artwork>;
  /** fetch data from the table: "Languages" */
  Languages: Array<Languages>;
  /** fetch aggregated fields from the table: "Languages" */
  Languages_aggregate: Languages_Aggregate;
  /** fetch data from the table: "Languages" using primary key columns */
  Languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table: "Materialized_view" */
  Materialized_view: Array<Materialized_View>;
  /** fetch aggregated fields from the table: "Materialized_view" */
  Materialized_view_aggregate: Materialized_View_Aggregate;
  /** fetch data from the table: "Materialized_view" using primary key columns */
  Materialized_view_by_pk?: Maybe<Materialized_View>;
  /** fetch data from the table: "Media_metadata" */
  Media_metadata: Array<Media_Metadata>;
  /** fetch aggregated fields from the table: "Media_metadata" */
  Media_metadata_aggregate: Media_Metadata_Aggregate;
  /** fetch data from the table: "Media_metadata" using primary key columns */
  Media_metadata_by_pk?: Maybe<Media_Metadata>;
  /** fetch data from the table: "Milestone" */
  Milestone: Array<Milestone>;
  /** fetch aggregated fields from the table: "Milestone" */
  Milestone_aggregate: Milestone_Aggregate;
  /** fetch data from the table: "Milestone" using primary key columns */
  Milestone_by_pk?: Maybe<Milestone>;
  /** fetch data from the table: "Museum_artwork_listing" */
  Museum_artwork_listing: Array<Museum_Artwork_Listing>;
  /** fetch aggregated fields from the table: "Museum_artwork_listing" */
  Museum_artwork_listing_aggregate: Museum_Artwork_Listing_Aggregate;
  /** fetch data from the table: "MyStudio_subscriptors" */
  MyStudio_subscriptors: Array<MyStudio_Subscriptors>;
  /** fetch aggregated fields from the table: "MyStudio_subscriptors" */
  MyStudio_subscriptors_aggregate: MyStudio_Subscriptors_Aggregate;
  /** An array relationship */
  My_Website_Settings: Array<My_Website_Settings>;
  /** An aggregate relationship */
  My_Website_Settings_aggregate: My_Website_Settings_Aggregate;
  /** fetch data from the table: "My_Website_Settings" using primary key columns */
  My_Website_Settings_by_pk?: Maybe<My_Website_Settings>;
  /** fetch data from the table: "My_studio_settings" */
  My_studio_settings: Array<My_Studio_Settings>;
  /** fetch aggregated fields from the table: "My_studio_settings" */
  My_studio_settings_aggregate: My_Studio_Settings_Aggregate;
  /** fetch data from the table: "My_studio_settings" using primary key columns */
  My_studio_settings_by_pk?: Maybe<My_Studio_Settings>;
  /** fetch data from the table: "Notification_preference" */
  Notification_preference: Array<Notification_Preference>;
  /** fetch aggregated fields from the table: "Notification_preference" */
  Notification_preference_aggregate: Notification_Preference_Aggregate;
  /** fetch data from the table: "Notification_preference" using primary key columns */
  Notification_preference_by_pk?: Maybe<Notification_Preference>;
  /** fetch data from the table: "Pending_collaboration_on_artwork" */
  Pending_collaboration_on_artwork: Array<Pending_Collaboration_On_Artwork>;
  /** fetch aggregated fields from the table: "Pending_collaboration_on_artwork" */
  Pending_collaboration_on_artwork_aggregate: Pending_Collaboration_On_Artwork_Aggregate;
  /** fetch data from the table: "Pending_collaboration_on_artwork" using primary key columns */
  Pending_collaboration_on_artwork_by_pk?: Maybe<Pending_Collaboration_On_Artwork>;
  /** fetch data from the table: "Price_Ranges" */
  Price_Ranges: Array<Price_Ranges>;
  /** fetch aggregated fields from the table: "Price_Ranges" */
  Price_Ranges_aggregate: Price_Ranges_Aggregate;
  /** fetch data from the table: "Price_Ranges" using primary key columns */
  Price_Ranges_by_pk?: Maybe<Price_Ranges>;
  /** fetch data from the table: "Print" */
  Print: Array<Print>;
  /** fetch aggregated fields from the table: "Print" */
  Print_aggregate: Print_Aggregate;
  /** An array relationship */
  Print_amounts: Array<Print_Amounts>;
  /** An aggregate relationship */
  Print_amounts_aggregate: Print_Amounts_Aggregate;
  /** fetch data from the table: "Print_artwork_collector_listing" */
  Print_artwork_collector_listing: Array<Print_Artwork_Collector_Listing>;
  /** fetch aggregated fields from the table: "Print_artwork_collector_listing" */
  Print_artwork_collector_listing_aggregate: Print_Artwork_Collector_Listing_Aggregate;
  /** fetch data from the table: "Print_artwork_info" */
  Print_artwork_info: Array<Print_Artwork_Info>;
  /** fetch aggregated fields from the table: "Print_artwork_info" */
  Print_artwork_info_aggregate: Print_Artwork_Info_Aggregate;
  /** fetch data from the table: "Print_artwork_view" */
  Print_artwork_view: Array<Print_Artwork_View>;
  /** fetch aggregated fields from the table: "Print_artwork_view" */
  Print_artwork_view_aggregate: Print_Artwork_View_Aggregate;
  /** fetch data from the table: "Print" using primary key columns */
  Print_by_pk?: Maybe<Print>;
  /** fetch data from the table: "Print_to_Collection" */
  Print_to_Collection: Array<Print_To_Collection>;
  /** fetch aggregated fields from the table: "Print_to_Collection" */
  Print_to_Collection_aggregate: Print_To_Collection_Aggregate;
  /** fetch data from the table: "Print_to_Collection" using primary key columns */
  Print_to_Collection_by_pk?: Maybe<Print_To_Collection>;
  /** fetch data from the table: "Prize_Art_Show" */
  Prize_Art_Show: Array<Prize_Art_Show>;
  /** fetch aggregated fields from the table: "Prize_Art_Show" */
  Prize_Art_Show_aggregate: Prize_Art_Show_Aggregate;
  /** fetch data from the table: "Prize_Art_Show" using primary key columns */
  Prize_Art_Show_by_pk?: Maybe<Prize_Art_Show>;
  /** fetch data from the table: "Profile" */
  Profile: Array<Profile>;
  /** fetch data from the table: "Profile_Interests" */
  Profile_Interests: Array<Profile_Interests>;
  /** fetch aggregated fields from the table: "Profile_Interests" */
  Profile_Interests_aggregate: Profile_Interests_Aggregate;
  /** fetch data from the table: "Profile_Interests" using primary key columns */
  Profile_Interests_by_pk?: Maybe<Profile_Interests>;
  /** fetch data from the table: "Profile_achievements" */
  Profile_achievements: Array<Profile_Achievements>;
  /** fetch aggregated fields from the table: "Profile_achievements" */
  Profile_achievements_aggregate: Profile_Achievements_Aggregate;
  /** fetch data from the table: "Profile_achievements" using primary key columns */
  Profile_achievements_by_pk?: Maybe<Profile_Achievements>;
  /** fetch aggregated fields from the table: "Profile" */
  Profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "Profile_appearance_settings" */
  Profile_appearance_settings: Array<Profile_Appearance_Settings>;
  /** fetch aggregated fields from the table: "Profile_appearance_settings" */
  Profile_appearance_settings_aggregate: Profile_Appearance_Settings_Aggregate;
  /** fetch data from the table: "Profile_appearance_settings" using primary key columns */
  Profile_appearance_settings_by_pk?: Maybe<Profile_Appearance_Settings>;
  /** fetch data from the table: "Profile_background" */
  Profile_background: Array<Profile_Background>;
  /** fetch aggregated fields from the table: "Profile_background" */
  Profile_background_aggregate: Profile_Background_Aggregate;
  /** fetch data from the table: "Profile_background" using primary key columns */
  Profile_background_by_pk?: Maybe<Profile_Background>;
  /** fetch data from the table: "Profile" using primary key columns */
  Profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "Profile_ghost_tokens" */
  Profile_ghost_tokens: Array<Profile_Ghost_Tokens>;
  /** fetch aggregated fields from the table: "Profile_ghost_tokens" */
  Profile_ghost_tokens_aggregate: Profile_Ghost_Tokens_Aggregate;
  /** fetch data from the table: "Profile_ghost_tokens" using primary key columns */
  Profile_ghost_tokens_by_pk?: Maybe<Profile_Ghost_Tokens>;
  /** fetch data from the table: "Profile_invitations" */
  Profile_invitations: Array<Profile_Invitations>;
  /** fetch aggregated fields from the table: "Profile_invitations" */
  Profile_invitations_aggregate: Profile_Invitations_Aggregate;
  /** fetch data from the table: "Profile_invitations" using primary key columns */
  Profile_invitations_by_pk?: Maybe<Profile_Invitations>;
  /** fetch data from the table: "Profile_location" */
  Profile_location: Array<Profile_Location>;
  /** fetch aggregated fields from the table: "Profile_location" */
  Profile_location_aggregate: Profile_Location_Aggregate;
  /** fetch data from the table: "Profile_location" using primary key columns */
  Profile_location_by_pk?: Maybe<Profile_Location>;
  /** fetch data from the table: "Profile_location_translation" */
  Profile_location_translation: Array<Profile_Location_Translation>;
  /** fetch aggregated fields from the table: "Profile_location_translation" */
  Profile_location_translation_aggregate: Profile_Location_Translation_Aggregate;
  /** fetch data from the table: "Profile_location_translation" using primary key columns */
  Profile_location_translation_by_pk?: Maybe<Profile_Location_Translation>;
  /** fetch data from the table: "Profile_price_ranges" */
  Profile_price_ranges: Array<Profile_Price_Ranges>;
  /** fetch aggregated fields from the table: "Profile_price_ranges" */
  Profile_price_ranges_aggregate: Profile_Price_Ranges_Aggregate;
  /** fetch data from the table: "Profile_price_ranges" using primary key columns */
  Profile_price_ranges_by_pk?: Maybe<Profile_Price_Ranges>;
  /** fetch data from the table: "Profile_questions_and_answers" */
  Profile_questions_and_answers: Array<Profile_Questions_And_Answers>;
  /** fetch aggregated fields from the table: "Profile_questions_and_answers" */
  Profile_questions_and_answers_aggregate: Profile_Questions_And_Answers_Aggregate;
  /** fetch data from the table: "Profile_questions_and_answers" using primary key columns */
  Profile_questions_and_answers_by_pk?: Maybe<Profile_Questions_And_Answers>;
  /** fetch data from the table: "Profile_settings" */
  Profile_settings: Array<Profile_Settings>;
  /** fetch aggregated fields from the table: "Profile_settings" */
  Profile_settings_aggregate: Profile_Settings_Aggregate;
  /** fetch data from the table: "Profile_settings" using primary key columns */
  Profile_settings_by_pk?: Maybe<Profile_Settings>;
  /** fetch data from the table: "Profile_social_links" */
  Profile_social_links: Array<Profile_Social_Links>;
  /** fetch aggregated fields from the table: "Profile_social_links" */
  Profile_social_links_aggregate: Profile_Social_Links_Aggregate;
  /** fetch data from the table: "Profile_social_links" using primary key columns */
  Profile_social_links_by_pk?: Maybe<Profile_Social_Links>;
  /** fetch data from the table: "Profile_statement_audio_syntheses" */
  Profile_statement_audio_syntheses: Array<Profile_Statement_Audio_Syntheses>;
  /** fetch aggregated fields from the table: "Profile_statement_audio_syntheses" */
  Profile_statement_audio_syntheses_aggregate: Profile_Statement_Audio_Syntheses_Aggregate;
  /** fetch data from the table: "Profile_statement_audio_syntheses" using primary key columns */
  Profile_statement_audio_syntheses_by_pk?: Maybe<Profile_Statement_Audio_Syntheses>;
  /** fetch data from the table: "Profile_translation" */
  Profile_translation: Array<Profile_Translation>;
  /** fetch aggregated fields from the table: "Profile_translation" */
  Profile_translation_aggregate: Profile_Translation_Aggregate;
  /** fetch data from the table: "Profile_translation" using primary key columns */
  Profile_translation_by_pk?: Maybe<Profile_Translation>;
  /** fetch data from the table: "Profile_video_links" */
  Profile_video_links: Array<Profile_Video_Links>;
  /** fetch aggregated fields from the table: "Profile_video_links" */
  Profile_video_links_aggregate: Profile_Video_Links_Aggregate;
  /** fetch data from the table: "Profile_video_links" using primary key columns */
  Profile_video_links_by_pk?: Maybe<Profile_Video_Links>;
  /** fetch data from the table: "Profile_wallet_address" */
  Profile_wallet_address: Array<Profile_Wallet_Address>;
  /** fetch aggregated fields from the table: "Profile_wallet_address" */
  Profile_wallet_address_aggregate: Profile_Wallet_Address_Aggregate;
  /** fetch data from the table: "Profile_wallet_address" using primary key columns */
  Profile_wallet_address_by_pk?: Maybe<Profile_Wallet_Address>;
  /** fetch data from the table: "Pubnub_channels" */
  Pubnub_channels: Array<Pubnub_Channels>;
  /** fetch aggregated fields from the table: "Pubnub_channels" */
  Pubnub_channels_aggregate: Pubnub_Channels_Aggregate;
  /** fetch data from the table: "Pubnub_channels" using primary key columns */
  Pubnub_channels_by_pk?: Maybe<Pubnub_Channels>;
  /** fetch data from the table: "Purchase_inquires" */
  Purchase_inquires: Array<Purchase_Inquires>;
  /** fetch aggregated fields from the table: "Purchase_inquires" */
  Purchase_inquires_aggregate: Purchase_Inquires_Aggregate;
  /** fetch data from the table: "Purchase_inquires" using primary key columns */
  Purchase_inquires_by_pk?: Maybe<Purchase_Inquires>;
  /** fetch data from the table: "Purchase_inquires_outside_users" */
  Purchase_inquires_outside_users: Array<Purchase_Inquires_Outside_Users>;
  /** fetch aggregated fields from the table: "Purchase_inquires_outside_users" */
  Purchase_inquires_outside_users_aggregate: Purchase_Inquires_Outside_Users_Aggregate;
  /** fetch data from the table: "Purchase_inquires_outside_users" using primary key columns */
  Purchase_inquires_outside_users_by_pk?: Maybe<Purchase_Inquires_Outside_Users>;
  /** fetch data from the table: "Push_notifications" */
  Push_notifications: Array<Push_Notifications>;
  /** fetch aggregated fields from the table: "Push_notifications" */
  Push_notifications_aggregate: Push_Notifications_Aggregate;
  /** fetch data from the table: "Push_notifications" using primary key columns */
  Push_notifications_by_pk?: Maybe<Push_Notifications>;
  /** fetch data from the table: "Questionnaire_questions" */
  Questionnaire_questions: Array<Questionnaire_Questions>;
  /** fetch aggregated fields from the table: "Questionnaire_questions" */
  Questionnaire_questions_aggregate: Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "Questionnaire_questions" using primary key columns */
  Questionnaire_questions_by_pk?: Maybe<Questionnaire_Questions>;
  /** fetch data from the table: "Render_request" */
  Render_request: Array<Render_Request>;
  /** fetch aggregated fields from the table: "Render_request" */
  Render_request_aggregate: Render_Request_Aggregate;
  /** fetch data from the table: "Render_request" using primary key columns */
  Render_request_by_pk?: Maybe<Render_Request>;
  /** fetch data from the table: "Setting_context_default_profile" */
  Setting_context_default_profile: Array<Setting_Context_Default_Profile>;
  /** fetch aggregated fields from the table: "Setting_context_default_profile" */
  Setting_context_default_profile_aggregate: Setting_Context_Default_Profile_Aggregate;
  /** fetch data from the table: "Setting_context_default_profile" using primary key columns */
  Setting_context_default_profile_by_pk?: Maybe<Setting_Context_Default_Profile>;
  /** fetch data from the table: "Showroom_details" */
  Showroom_details: Array<Showroom_Details>;
  /** fetch aggregated fields from the table: "Showroom_details" */
  Showroom_details_aggregate: Showroom_Details_Aggregate;
  /** fetch data from the table: "Showroom_details" using primary key columns */
  Showroom_details_by_pk?: Maybe<Showroom_Details>;
  /** fetch data from the table: "Social_Graph_Target_Context_view" */
  Social_Graph_Target_Context_view: Array<Social_Graph_Target_Context_View>;
  /** fetch aggregated fields from the table: "Social_Graph_Target_Context_view" */
  Social_Graph_Target_Context_view_aggregate: Social_Graph_Target_Context_View_Aggregate;
  /** fetch data from the table: "Social_graph_followers" */
  Social_graph_followers: Array<Social_Graph_Followers>;
  /** fetch aggregated fields from the table: "Social_graph_followers" */
  Social_graph_followers_aggregate: Social_Graph_Followers_Aggregate;
  /** fetch data from the table: "Social_graph_followers" using primary key columns */
  Social_graph_followers_by_pk?: Maybe<Social_Graph_Followers>;
  /** fetch data from the table: "Social_graph_followers_view" */
  Social_graph_followers_view: Array<Social_Graph_Followers_View>;
  /** fetch aggregated fields from the table: "Social_graph_followers_view" */
  Social_graph_followers_view_aggregate: Social_Graph_Followers_View_Aggregate;
  /** fetch data from the table: "Spotlight_artist" */
  Spotlight_artist: Array<Spotlight_Artist>;
  /** fetch aggregated fields from the table: "Spotlight_artist" */
  Spotlight_artist_aggregate: Spotlight_Artist_Aggregate;
  /** fetch data from the table: "Spotlight_context" */
  Spotlight_context: Array<Spotlight_Context>;
  /** fetch aggregated fields from the table: "Spotlight_context" */
  Spotlight_context_aggregate: Spotlight_Context_Aggregate;
  /** fetch data from the table: "Spotlight_context" using primary key columns */
  Spotlight_context_by_pk?: Maybe<Spotlight_Context>;
  /** fetch data from the table: "Super_site_subscribers" */
  Super_site_subscribers: Array<Super_Site_Subscribers>;
  /** fetch aggregated fields from the table: "Super_site_subscribers" */
  Super_site_subscribers_aggregate: Super_Site_Subscribers_Aggregate;
  /** fetch data from the table: "Super_site_subscribers" using primary key columns */
  Super_site_subscribers_by_pk?: Maybe<Super_Site_Subscribers>;
  /** fetch data from the table: "Tags_by_interest" */
  Tags_by_interest: Array<Tags_By_Interest>;
  /** fetch aggregated fields from the table: "Tags_by_interest" */
  Tags_by_interest_aggregate: Tags_By_Interest_Aggregate;
  /** fetch data from the table: "Tags_by_interest" using primary key columns */
  Tags_by_interest_by_pk?: Maybe<Tags_By_Interest>;
  /** fetch data from the table: "Temp_artist_explore_collection" */
  Temp_artist_explore_collection: Array<Temp_Artist_Explore_Collection>;
  /** fetch aggregated fields from the table: "Temp_artist_explore_collection" */
  Temp_artist_explore_collection_aggregate: Temp_Artist_Explore_Collection_Aggregate;
  /** fetch data from the table: "Test_Analytics_events" */
  Test_Analytics_events: Array<Test_Analytics_Events>;
  /** fetch aggregated fields from the table: "Test_Analytics_events" */
  Test_Analytics_events_aggregate: Test_Analytics_Events_Aggregate;
  /** fetch data from the table: "Test_Analytics_events" using primary key columns */
  Test_Analytics_events_by_pk?: Maybe<Test_Analytics_Events>;
  /** fetch data from the table: "Test_accounts" */
  Test_accounts: Array<Test_Accounts>;
  /** fetch aggregated fields from the table: "Test_accounts" */
  Test_accounts_aggregate: Test_Accounts_Aggregate;
  /** fetch data from the table: "Test_accounts" using primary key columns */
  Test_accounts_by_pk?: Maybe<Test_Accounts>;
  /** fetch data from the table: "Timed_event" */
  Timed_event: Array<Timed_Event>;
  /** fetch aggregated fields from the table: "Timed_event" */
  Timed_event_aggregate: Timed_Event_Aggregate;
  /** fetch data from the table: "Timed_event" using primary key columns */
  Timed_event_by_pk?: Maybe<Timed_Event>;
  /** fetch data from the table: "Token_Storage" */
  Token_Storage: Array<Token_Storage>;
  /** fetch aggregated fields from the table: "Token_Storage" */
  Token_Storage_aggregate: Token_Storage_Aggregate;
  /** fetch data from the table: "Token_Storage" using primary key columns */
  Token_Storage_by_pk?: Maybe<Token_Storage>;
  /** fetch data from the table: "Tutorial" */
  Tutorial: Array<Tutorial>;
  /** fetch aggregated fields from the table: "Tutorial" */
  Tutorial_aggregate: Tutorial_Aggregate;
  /** fetch data from the table: "Tutorial" using primary key columns */
  Tutorial_by_pk?: Maybe<Tutorial>;
  /** fetch data from the table: "Tutorial_step" */
  Tutorial_step: Array<Tutorial_Step>;
  /** fetch aggregated fields from the table: "Tutorial_step" */
  Tutorial_step_aggregate: Tutorial_Step_Aggregate;
  /** fetch data from the table: "Tutorial_step" using primary key columns */
  Tutorial_step_by_pk?: Maybe<Tutorial_Step>;
  /** fetch data from the table: "User_analytics_points_index" */
  User_analytics_points_index: Array<User_Analytics_Points_Index>;
  /** fetch aggregated fields from the table: "User_analytics_points_index" */
  User_analytics_points_index_aggregate: User_Analytics_Points_Index_Aggregate;
  /** fetch data from the table: "User_analytics_points_index" using primary key columns */
  User_analytics_points_index_by_pk?: Maybe<User_Analytics_Points_Index>;
  /** fetch data from the table: "VR_artists" */
  VR_artists: Array<Vr_Artists>;
  /** fetch aggregated fields from the table: "VR_artists" */
  VR_artists_aggregate: Vr_Artists_Aggregate;
  /** fetch data from the table: "VR_artists" using primary key columns */
  VR_artists_by_pk?: Maybe<Vr_Artists>;
  /** An array relationship */
  Valid_profile_questions_and_answers_view: Array<Valid_Profile_Questions_And_Answers_View>;
  /** An aggregate relationship */
  Valid_profile_questions_and_answers_view_aggregate: Valid_Profile_Questions_And_Answers_View_Aggregate;
  /** fetch data from the table: "Valid_profile_statement_audio_syntheses_view" */
  Valid_profile_statement_audio_syntheses_view: Array<Valid_Profile_Statement_Audio_Syntheses_View>;
  /** fetch aggregated fields from the table: "Valid_profile_statement_audio_syntheses_view" */
  Valid_profile_statement_audio_syntheses_view_aggregate: Valid_Profile_Statement_Audio_Syntheses_View_Aggregate;
  /** fetch data from the table: "Vision_active_interests" */
  Vision_active_interests: Array<Vision_Active_Interests>;
  /** fetch aggregated fields from the table: "Vision_active_interests" */
  Vision_active_interests_aggregate: Vision_Active_Interests_Aggregate;
  /** fetch data from the table: "Vision_interest" */
  Vision_interest: Array<Vision_Interest>;
  /** fetch aggregated fields from the table: "Vision_interest" */
  Vision_interest_aggregate: Vision_Interest_Aggregate;
  /** fetch data from the table: "Vision_interest" using primary key columns */
  Vision_interest_by_pk?: Maybe<Vision_Interest>;
  /** fetch data from the table: "Vumarks" */
  Vumarks: Array<Vumarks>;
  /** fetch aggregated fields from the table: "Vumarks" */
  Vumarks_aggregate: Vumarks_Aggregate;
  /** fetch data from the table: "Vumarks" using primary key columns */
  Vumarks_by_pk?: Maybe<Vumarks>;
  /** fetch data from the table: "Waiting_list" */
  Waiting_list: Array<Waiting_List>;
  /** fetch aggregated fields from the table: "Waiting_list" */
  Waiting_list_aggregate: Waiting_List_Aggregate;
  /** fetch data from the table: "Waiting_list" using primary key columns */
  Waiting_list_by_pk?: Maybe<Waiting_List>;
  /** An array relationship */
  Wallets: Array<Wallets>;
  /** An aggregate relationship */
  Wallets_aggregate: Wallets_Aggregate;
  /** fetch data from the table: "Wallets" using primary key columns */
  Wallets_by_pk?: Maybe<Wallets>;
  /** fetch data from the table: "World_cities" */
  World_cities: Array<World_Cities>;
  /** fetch aggregated fields from the table: "World_cities" */
  World_cities_aggregate: World_Cities_Aggregate;
  /** fetch data from the table: "World_cities" using primary key columns */
  World_cities_by_pk?: Maybe<World_Cities>;
  aggregateProxy: Scalars['Int'];
  analyzeMessageContentQuery: AnalyzeMessageContentReturnType;
  /** fetch data from the table: "app_notifications_info" */
  app_notifications_info: Array<App_Notifications_Info>;
  /** fetch aggregated fields from the table: "app_notifications_info" */
  app_notifications_info_aggregate: App_Notifications_Info_Aggregate;
  /** fetch data from the table: "app_notifications_listing" */
  app_notifications_listing: Array<App_Notifications_Listing>;
  /** fetch aggregated fields from the table: "app_notifications_listing" */
  app_notifications_listing_aggregate: App_Notifications_Listing_Aggregate;
  /** fetch data from the table: "app_notifications_view" */
  app_notifications_view: Array<App_Notifications_View>;
  /** fetch aggregated fields from the table: "app_notifications_view" */
  app_notifications_view_aggregate: App_Notifications_View_Aggregate;
  /** fetch data from the table: "artist_cardgrid_artworks_view" */
  artist_cardgrid_artworks_view: Array<Artist_Cardgrid_Artworks_View>;
  /** fetch aggregated fields from the table: "artist_cardgrid_artworks_view" */
  artist_cardgrid_artworks_view_aggregate: Artist_Cardgrid_Artworks_View_Aggregate;
  /**
   * Gets artwork certificate image public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  artworkCertificateImage?: Maybe<Scalars['String']>;
  /**
   * Gets Primary image public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  artworkPrimaryImage?: Maybe<Scalars['String']>;
  /**
   * Gets Secondary image public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  artworkSecondaryImage?: Maybe<Scalars['String']>;
  /**
   * Gets story audio public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  artworkStoryAudio?: Maybe<Scalars['String']>;
  /**
   * Gets story/process image public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  artworkStoryImage?: Maybe<Scalars['String']>;
  /**
   * Gets Thumbnail public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  artworkThumbnail?: Maybe<Scalars['String']>;
  /** fetch data from the table: "artwork_favorite_count" */
  artwork_favorite_count: Array<Artwork_Favorite_Count>;
  /** fetch aggregated fields from the table: "artwork_favorite_count" */
  artwork_favorite_count_aggregate: Artwork_Favorite_Count_Aggregate;
  /** fetch data from the table: "artwork_manager_view" */
  artwork_manager_view: Array<Artwork_Manager_View>;
  /** fetch aggregated fields from the table: "artwork_manager_view" */
  artwork_manager_view_aggregate: Artwork_Manager_View_Aggregate;
  /** fetch data from the table: "artwork_shop_info" */
  artwork_shop_info: Array<Artwork_Shop_Info>;
  /** fetch aggregated fields from the table: "artwork_shop_info" */
  artwork_shop_info_aggregate: Artwork_Shop_Info_Aggregate;
  /** fetch data from the table: "artwork_shop_listing" */
  artwork_shop_listing: Array<Artwork_Shop_Listing>;
  /** fetch aggregated fields from the table: "artwork_shop_listing" */
  artwork_shop_listing_aggregate: Artwork_Shop_Listing_Aggregate;
  /** fetch data from the table: "best_bet_cities_exclude_cities_with_duplicates_mv" */
  best_bet_cities_exclude_cities_with_duplicates_mv: Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv>;
  /** fetch aggregated fields from the table: "best_bet_cities_exclude_cities_with_duplicates_mv" */
  best_bet_cities_exclude_cities_with_duplicates_mv_aggregate: Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Aggregate;
  /** fetch data from the table: "best_bet_cities_mv" */
  best_bet_cities_mv: Array<Best_Bet_Cities_Mv>;
  /** fetch aggregated fields from the table: "best_bet_cities_mv" */
  best_bet_cities_mv_aggregate: Best_Bet_Cities_Mv_Aggregate;
  /** fetch data from the table: "biweekly_recap_artists_info" */
  biweekly_recap_artists_info: Array<Biweekly_Recap_Artists_Info>;
  /** fetch aggregated fields from the table: "biweekly_recap_artists_info" */
  biweekly_recap_artists_info_aggregate: Biweekly_Recap_Artists_Info_Aggregate;
  /** Gets a boolean if a context has not active recognition with a target. */
  canFollow?: Maybe<Scalars['Boolean']>;
  checkCoAFile?: Maybe<CheckCoAFileResult>;
  checkUserAuthState: UserAuthState;
  checkValidationCode: Scalars['Boolean'];
  /**
   * Gets collection audio public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  collectionAudio?: Maybe<Scalars['String']>;
  /** fetch data from the table: "count_artists_approved_by_day" */
  count_artists_approved_by_day: Array<Count_Artists_Approved_By_Day>;
  /** fetch aggregated fields from the table: "count_artists_approved_by_day" */
  count_artists_approved_by_day_aggregate: Count_Artists_Approved_By_Day_Aggregate;
  /** fetch data from the table: "count_artists_created_by_day" */
  count_artists_created_by_day: Array<Count_Artists_Created_By_Day>;
  /** fetch aggregated fields from the table: "count_artists_created_by_day" */
  count_artists_created_by_day_aggregate: Count_Artists_Created_By_Day_Aggregate;
  /** fetch data from the table: "count_artists_pending_by_day" */
  count_artists_pending_by_day: Array<Count_Artists_Pending_By_Day>;
  /** fetch aggregated fields from the table: "count_artists_pending_by_day" */
  count_artists_pending_by_day_aggregate: Count_Artists_Pending_By_Day_Aggregate;
  /** fetch data from the table: "count_artists_rejected_by_day" */
  count_artists_rejected_by_day: Array<Count_Artists_Rejected_By_Day>;
  /** fetch aggregated fields from the table: "count_artists_rejected_by_day" */
  count_artists_rejected_by_day_aggregate: Count_Artists_Rejected_By_Day_Aggregate;
  /** fetch data from the table: "count_artworks_published_by_day" */
  count_artworks_published_by_day: Array<Count_Artworks_Published_By_Day>;
  /** fetch aggregated fields from the table: "count_artworks_published_by_day" */
  count_artworks_published_by_day_aggregate: Count_Artworks_Published_By_Day_Aggregate;
  /** fetch data from the table: "count_artworks_published_this_week" */
  count_artworks_published_this_week: Array<Count_Artworks_Published_This_Week>;
  /** fetch aggregated fields from the table: "count_artworks_published_this_week" */
  count_artworks_published_this_week_aggregate: Count_Artworks_Published_This_Week_Aggregate;
  /** fetch data from the table: "count_collectors_created_by_day" */
  count_collectors_created_by_day: Array<Count_Collectors_Created_By_Day>;
  /** fetch aggregated fields from the table: "count_collectors_created_by_day" */
  count_collectors_created_by_day_aggregate: Count_Collectors_Created_By_Day_Aggregate;
  /** fetch data from the table: "count_galleries_approved_by_day" */
  count_galleries_approved_by_day: Array<Count_Galleries_Approved_By_Day>;
  /** fetch aggregated fields from the table: "count_galleries_approved_by_day" */
  count_galleries_approved_by_day_aggregate: Count_Galleries_Approved_By_Day_Aggregate;
  /** fetch data from the table: "count_galleries_created_by_day" */
  count_galleries_created_by_day: Array<Count_Galleries_Created_By_Day>;
  /** fetch aggregated fields from the table: "count_galleries_created_by_day" */
  count_galleries_created_by_day_aggregate: Count_Galleries_Created_By_Day_Aggregate;
  /** fetch data from the table: "count_galleries_pending_by_day" */
  count_galleries_pending_by_day: Array<Count_Galleries_Pending_By_Day>;
  /** fetch aggregated fields from the table: "count_galleries_pending_by_day" */
  count_galleries_pending_by_day_aggregate: Count_Galleries_Pending_By_Day_Aggregate;
  /** fetch data from the table: "count_galleries_rejected_by_day" */
  count_galleries_rejected_by_day: Array<Count_Galleries_Rejected_By_Day>;
  /** fetch aggregated fields from the table: "count_galleries_rejected_by_day" */
  count_galleries_rejected_by_day_aggregate: Count_Galleries_Rejected_By_Day_Aggregate;
  /** fetch data from the table: "count_profile_visits_published_biweekly" */
  count_profile_visits_published_biweekly: Array<Count_Profile_Visits_Published_Biweekly>;
  /** fetch aggregated fields from the table: "count_profile_visits_published_biweekly" */
  count_profile_visits_published_biweekly_aggregate: Count_Profile_Visits_Published_Biweekly_Aggregate;
  /** fetch data from the table: "count_recognitions_published_biweekly" */
  count_recognitions_published_biweekly: Array<Count_Recognitions_Published_Biweekly>;
  /** fetch aggregated fields from the table: "count_recognitions_published_biweekly" */
  count_recognitions_published_biweekly_aggregate: Count_Recognitions_Published_Biweekly_Aggregate;
  /** fetch data from the table: "country_states_mv" */
  country_states_mv: Array<Country_States_Mv>;
  /** fetch aggregated fields from the table: "country_states_mv" */
  country_states_mv_aggregate: Country_States_Mv_Aggregate;
  /** fetch data from the table: "discount_code_view" */
  discount_code_view: Array<Discount_Code_View>;
  /** fetch aggregated fields from the table: "discount_code_view" */
  discount_code_view_aggregate: Discount_Code_View_Aggregate;
  dummyQuery: Scalars['Int'];
  /** fetch data from the table: "flat_delegate_access" */
  flat_delegate_access: Array<Flat_Delegate_Access>;
  /** fetch aggregated fields from the table: "flat_delegate_access" */
  flat_delegate_access_aggregate: Flat_Delegate_Access_Aggregate;
  foursquarePlacesSearch: PlacesSearchResult;
  /**
   * Generates and returns the onboarding url to Stripe Connect;
   * The artist must go through this process in order to be able to receive money from selling artwork
   */
  generateBillingSellerOnboardingUrl: Scalars['String'];
  generateDiscountCode: Scalars['String'];
  getAIArtworkTextStatement?: Maybe<Scalars['String']>;
  getActiveSubscription: GetActiveSubscriptionResult;
  getAdmirationsQuantity?: Maybe<Scalars['Int']>;
  /** execute function "getAnalyticsKaleidoCardByProfileId" which returns "Analytics_kaleidoCard_Amounts" */
  getAnalyticsKaleidoCardByProfileId: Array<Analytics_KaleidoCard_Amounts>;
  /** execute function "getAnalyticsKaleidoCardByProfileId" and query aggregates on result of table type "Analytics_kaleidoCard_Amounts" */
  getAnalyticsKaleidoCardByProfileId_aggregate: Analytics_KaleidoCard_Amounts_Aggregate;
  getAnonFCMToken?: Maybe<FcmToken>;
  /** execute function "getArtPrizeShowWinners" which returns "Art_prize_show_winners" */
  getArtPrizeShowWinners: Array<Art_Prize_Show_Winners>;
  /** execute function "getArtPrizeShowWinners" and query aggregates on result of table type "Art_prize_show_winners" */
  getArtPrizeShowWinners_aggregate: Art_Prize_Show_Winners_Aggregate;
  getArtistProfileIdWithCollaborationCode?: Maybe<Scalars['Int']>;
  /** Computes the estimated shipping price for an artwork based on its price and the shipping fee margin */
  getArtworkEstimatedShippingPrice: Scalars['Int'];
  getArtworkEventByArtworkId?: Maybe<Array<Maybe<Payload>>>;
  /** retrieve stripe details related to products (that are part of subscriptions), and customer url for managing subscriptions */
  getBillingAccountDetails: GetBillingAccountDetailsResult;
  getCartItems: Array<ShoppingCartItemType>;
  getCartItemsByArtworksId: Array<ShoppingCartItemType>;
  getCartProductsFromBillingPaymentIntent: Array<PaymentIntentCartProduct>;
  getCollaborationCodeOfProfile?: Maybe<Scalars['String']>;
  getCollectorByEmailOrHandle?: Maybe<GetCollectorByEmailOrHandleReturnType>;
  getDomainRegistrationPreferences?: Maybe<DomainRegistrationPreferences>;
  getDomainSuggestions: DomainSuggestionsResult;
  getEditionEventByEditionId?: Maybe<Array<Maybe<Payload>>>;
  getFinalPriceUsingDiscountCode?: Maybe<GetFinalPriceUsingDiscountCodeReturnType>;
  getFollowersCount?: Maybe<GetFollowersCountReturnType>;
  getGalleryProfileIdWithCollaborationCode?: Maybe<Scalars['Int']>;
  getInvitationDataByToken?: Maybe<InvitationDataByTokenResultType>;
  /** Returns whether the card associated with the user's active MyStudio subscription is expiring within a month. */
  getIsCardExpiringWithinMonth: Scalars['Boolean'];
  getMyFCMToken?: Maybe<FcmToken>;
  getMyProvenanceArtworksDataQuery: GetMyProvenanceArtworksDataOutputType;
  getMyTransactionsPreview: OnboardingTransactionsPreview;
  getMyTransactionsPreviewNoJWT: OnboardingTransactionsPreview;
  getPayloadByEventById?: Maybe<Payload>;
  getProfileSuggestedPrimarySecondaryTags?: Maybe<SuggestedTagsResultType>;
  getProrationForSubscriptionUpdate: GetProrationForSubscriptionUpdateResult;
  getPubNubChannels: Array<Pubnub_Channels_Type>;
  getPubNubToken?: Maybe<Scalars['String']>;
  /** execute function "getSuggestedPrimaryTagsByArtist" which returns "Context_suggested_tags" */
  getSuggestedPrimaryTagsByArtist: Array<Context_Suggested_Tags>;
  /** execute function "getSuggestedPrimaryTagsByArtist" and query aggregates on result of table type "Context_suggested_tags" */
  getSuggestedPrimaryTagsByArtist_aggregate: Context_Suggested_Tags_Aggregate;
  /** execute function "getSuggestedSecondaryTagsByArtist" which returns "Context_suggested_tags" */
  getSuggestedSecondaryTagsByArtist: Array<Context_Suggested_Tags>;
  /** execute function "getSuggestedSecondaryTagsByArtist" and query aggregates on result of table type "Context_suggested_tags" */
  getSuggestedSecondaryTagsByArtist_aggregate: Context_Suggested_Tags_Aggregate;
  getTextStatement: Scalars['String'];
  /** execute function "get_artists_in_range" which returns "Artist_explore_listing_with_city_data" */
  get_artists_in_range: Array<Artist_Explore_Listing_With_City_Data>;
  /** execute function "get_artists_in_range" and query aggregates on result of table type "Artist_explore_listing_with_city_data" */
  get_artists_in_range_aggregate: Artist_Explore_Listing_With_City_Data_Aggregate;
  /** execute function "get_galleries_in_range" which returns "Gallery_explore_info_with_city_data" */
  get_galleries_in_range: Array<Gallery_Explore_Info_With_City_Data>;
  /** execute function "get_galleries_in_range" and query aggregates on result of table type "Gallery_explore_info_with_city_data" */
  get_galleries_in_range_aggregate: Gallery_Explore_Info_With_City_Data_Aggregate;
  /** execute function "get_min_price_available_edition_by_size" which returns "Available_print_price" */
  get_min_price_available_edition_by_size: Array<Available_Print_Price>;
  /** execute function "get_min_price_available_edition_by_size" and query aggregates on result of table type "Available_print_price" */
  get_min_price_available_edition_by_size_aggregate: Available_Print_Price_Aggregate;
  /** execute function "get_min_price_available_print" which returns "Available_print_price" */
  get_min_price_available_print: Array<Available_Print_Price>;
  /** execute function "get_min_price_available_print" and query aggregates on result of table type "Available_print_price" */
  get_min_price_available_print_aggregate: Available_Print_Price_Aggregate;
  googlePlacesSearch: PlacesSearchResult;
  helloAccount?: Maybe<Scalars['String']>;
  helloArtwork?: Maybe<Scalars['String']>;
  helloAuth: Scalars['String'];
  helloGamification?: Maybe<Scalars['String']>;
  helloNotification?: Maybe<Scalars['String']>;
  helloStorage?: Maybe<Scalars['ID']>;
  inquireForPurchaseByOutsideUserAction?: Maybe<InquireForPurchaseByOutsideUserAction>;
  /** fetch data from the table: "inside_purchase_inquiries_view" */
  inside_purchase_inquiries_view: Array<Inside_Purchase_Inquiries_View>;
  /** fetch aggregated fields from the table: "inside_purchase_inquiries_view" */
  inside_purchase_inquiries_view_aggregate: Inside_Purchase_Inquiries_View_Aggregate;
  /** fetch data from the table: "kaleido_coins_distribution" */
  kaleido_coins_distribution: Array<Kaleido_Coins_Distribution>;
  /** fetch aggregated fields from the table: "kaleido_coins_distribution" */
  kaleido_coins_distribution_aggregate: Kaleido_Coins_Distribution_Aggregate;
  /** fetch data from the table: "kaleido_coins_weekly_distribution" */
  kaleido_coins_weekly_distribution: Array<Kaleido_Coins_Weekly_Distribution>;
  /** fetch aggregated fields from the table: "kaleido_coins_weekly_distribution" */
  kaleido_coins_weekly_distribution_aggregate: Kaleido_Coins_Weekly_Distribution_Aggregate;
  /** fetch data from the table: "outside_purchase_inquiries_view" */
  outside_purchase_inquiries_view: Array<Outside_Purchase_Inquiries_View>;
  /** fetch aggregated fields from the table: "outside_purchase_inquiries_view" */
  outside_purchase_inquiries_view_aggregate: Outside_Purchase_Inquiries_View_Aggregate;
  /** fetch data from the table: "owner_artwork_view" */
  owner_artwork_view: Array<Owner_Artwork_View>;
  /** fetch aggregated fields from the table: "owner_artwork_view" */
  owner_artwork_view_aggregate: Owner_Artwork_View_Aggregate;
  /** fetch data from the table: "payment_captures_view" */
  payment_captures_view: Array<Payment_Captures_View>;
  /** fetch aggregated fields from the table: "payment_captures_view" */
  payment_captures_view_aggregate: Payment_Captures_View_Aggregate;
  /** fetch data from the table: "pending_forge_task_view" */
  pending_forge_task_view: Array<Pending_Forge_Task_View>;
  /** fetch aggregated fields from the table: "pending_forge_task_view" */
  pending_forge_task_view_aggregate: Pending_Forge_Task_View_Aggregate;
  /** fetch data from the table: "print_availability" */
  print_availability: Array<Print_Availability>;
  /** fetch aggregated fields from the table: "print_availability" */
  print_availability_aggregate: Print_Availability_Aggregate;
  /**
   * Gets profile audio public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  profileAudio?: Maybe<Scalars['String']>;
  /**
   * Gets avatar public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  profileAvatar?: Maybe<Scalars['String']>;
  /**
   * Gets Banner public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  profileBanner?: Maybe<Scalars['String']>;
  /**
   * Gets CV public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  profileCV?: Maybe<Scalars['String']>;
  /**
   * Gets Featured art public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  profileFeaturedArt?: Maybe<Scalars['String']>;
  /**
   * Gets Kard Banner public S3 url
   *
   * ***Constraints:***
   *
   *   \- Hasura context Validation
   */
  profileKardBanner?: Maybe<Scalars['String']>;
  /** fetch data from the table: "profile_backgrounds_view" */
  profile_backgrounds_view: Array<Profile_Backgrounds_View>;
  /** fetch aggregated fields from the table: "profile_backgrounds_view" */
  profile_backgrounds_view_aggregate: Profile_Backgrounds_View_Aggregate;
  /** fetch data from the table: "profile_interests_view" */
  profile_interests_view: Array<Profile_Interests_View>;
  /** fetch aggregated fields from the table: "profile_interests_view" */
  profile_interests_view_aggregate: Profile_Interests_View_Aggregate;
  /** An array relationship */
  profile_phones: Array<Profile_Phones>;
  /** An aggregate relationship */
  profile_phones_aggregate: Profile_Phones_Aggregate;
  /** fetch data from the table: "profile_phones" using primary key columns */
  profile_phones_by_pk?: Maybe<Profile_Phones>;
  /** fetch data from the table: "recognition_counts_mv" */
  recognition_counts_mv: Array<Recognition_Counts_Mv>;
  /** fetch aggregated fields from the table: "recognition_counts_mv" */
  recognition_counts_mv_aggregate: Recognition_Counts_Mv_Aggregate;
  sendSlackMessageForPurchaseInquiries: Scalars['Boolean'];
  /** fetch data from the table: "showroom_owner_password" */
  showroom_owner_password: Array<Showroom_Owner_Password>;
  /** fetch aggregated fields from the table: "showroom_owner_password" */
  showroom_owner_password_aggregate: Showroom_Owner_Password_Aggregate;
  /** fetch data from the table: "showroom_password_check" */
  showroom_password_check: Array<Showroom_Password_Check>;
  /** fetch aggregated fields from the table: "showroom_password_check" */
  showroom_password_check_aggregate: Showroom_Password_Check_Aggregate;
  /** retrieve stripe details for a Billing_subscription */
  stripeSubscription: StripeSubscription;
  /** fetch data from the table: "sum_coins_from_card_this_week" */
  sum_coins_from_card_this_week: Array<Sum_Coins_From_Card_This_Week>;
  /** fetch aggregated fields from the table: "sum_coins_from_card_this_week" */
  sum_coins_from_card_this_week_aggregate: Sum_Coins_From_Card_This_Week_Aggregate;
  testGQLogger: Scalars['String'];
  testLogger: Scalars['String'];
  verifyArtworkTransactionBuyer: Scalars['Boolean'];
  verifyEmailVerificationToken: Scalars['String'];
  /** fetch data from the table: "worldcities" */
  worldcities: Array<Worldcities>;
  /** fetch aggregated fields from the table: "worldcities" */
  worldcities_aggregate: Worldcities_Aggregate;
  /** fetch data from the table: "worldcities" using primary key columns */
  worldcities_by_pk?: Maybe<Worldcities>;
};


export type Query_RootAccount_Initial_Profile_TypeArgs = {
  distinct_on?: Maybe<Array<Account_Initial_Profile_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Initial_Profile_Type_Order_By>>;
  where?: Maybe<Account_Initial_Profile_Type_Bool_Exp>;
};


export type Query_RootAccount_Initial_Profile_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Initial_Profile_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Initial_Profile_Type_Order_By>>;
  where?: Maybe<Account_Initial_Profile_Type_Bool_Exp>;
};


export type Query_RootAccount_Initial_Profile_Type_By_PkArgs = {
  context_id: Scalars['bigint'];
};


export type Query_RootAccount_Notice_AckArgs = {
  distinct_on?: Maybe<Array<Account_Notice_Ack_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Notice_Ack_Order_By>>;
  where?: Maybe<Account_Notice_Ack_Bool_Exp>;
};


export type Query_RootAccount_Notice_Ack_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Notice_Ack_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Notice_Ack_Order_By>>;
  where?: Maybe<Account_Notice_Ack_Bool_Exp>;
};


export type Query_RootAccount_Notice_Ack_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAccount_ValidationArgs = {
  distinct_on?: Maybe<Array<Account_Validation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Validation_Order_By>>;
  where?: Maybe<Account_Validation_Bool_Exp>;
};


export type Query_RootAccount_Validation_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Validation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Validation_Order_By>>;
  where?: Maybe<Account_Validation_Bool_Exp>;
};


export type Query_RootAccount_Validation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAchievement_HooksArgs = {
  distinct_on?: Maybe<Array<Achievement_Hooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Hooks_Order_By>>;
  where?: Maybe<Achievement_Hooks_Bool_Exp>;
};


export type Query_RootAchievement_Hooks_AggregateArgs = {
  distinct_on?: Maybe<Array<Achievement_Hooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Hooks_Order_By>>;
  where?: Maybe<Achievement_Hooks_Bool_Exp>;
};


export type Query_RootAchievement_Hooks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAchievement_TagsArgs = {
  distinct_on?: Maybe<Array<Achievement_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Tags_Order_By>>;
  where?: Maybe<Achievement_Tags_Bool_Exp>;
};


export type Query_RootAchievement_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Achievement_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Tags_Order_By>>;
  where?: Maybe<Achievement_Tags_Bool_Exp>;
};


export type Query_RootAchievement_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAchievementsArgs = {
  distinct_on?: Maybe<Array<Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievements_Order_By>>;
  where?: Maybe<Achievements_Bool_Exp>;
};


export type Query_RootAchievements_AggregateArgs = {
  distinct_on?: Maybe<Array<Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievements_Order_By>>;
  where?: Maybe<Achievements_Bool_Exp>;
};


export type Query_RootAchievements_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAdminLoginTokenArgs = {
  email: Scalars['String'];
};


export type Query_RootAdmin_PasswordArgs = {
  distinct_on?: Maybe<Array<Admin_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Password_Order_By>>;
  where?: Maybe<Admin_Password_Bool_Exp>;
};


export type Query_RootAdmin_Password_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Password_Order_By>>;
  where?: Maybe<Admin_Password_Bool_Exp>;
};


export type Query_RootAdmin_Password_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAdmin_Table_ProfilesArgs = {
  distinct_on?: Maybe<Array<Admin_Table_Profiles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Table_Profiles_Order_By>>;
  where?: Maybe<Admin_Table_Profiles_Bool_Exp>;
};


export type Query_RootAdmin_Table_Profiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Table_Profiles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Table_Profiles_Order_By>>;
  where?: Maybe<Admin_Table_Profiles_Bool_Exp>;
};


export type Query_RootAdmin_Table_Profiles_V2Args = {
  distinct_on?: Maybe<Array<Admin_Table_Profiles_V2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Table_Profiles_V2_Order_By>>;
  where?: Maybe<Admin_Table_Profiles_V2_Bool_Exp>;
};


export type Query_RootAdmin_Table_Profiles_V2_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Table_Profiles_V2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Table_Profiles_V2_Order_By>>;
  where?: Maybe<Admin_Table_Profiles_V2_Bool_Exp>;
};


export type Query_RootAdmin_To_User_NotificationArgs = {
  distinct_on?: Maybe<Array<Admin_To_User_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_To_User_Notification_Order_By>>;
  where?: Maybe<Admin_To_User_Notification_Bool_Exp>;
};


export type Query_RootAdmin_To_User_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_To_User_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_To_User_Notification_Order_By>>;
  where?: Maybe<Admin_To_User_Notification_Bool_Exp>;
};


export type Query_RootAdmin_To_User_Notification_By_PkArgs = {
  user_context_id: Scalars['bigint'];
};


export type Query_RootAll_ArtistsArgs = {
  distinct_on?: Maybe<Array<All_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artists_Order_By>>;
  where?: Maybe<All_Artists_Bool_Exp>;
};


export type Query_RootAll_Artists_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artists_Order_By>>;
  where?: Maybe<All_Artists_Bool_Exp>;
};


export type Query_RootAll_Artwork_AvailabilityArgs = {
  distinct_on?: Maybe<Array<All_Artwork_Availability_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artwork_Availability_Order_By>>;
  where?: Maybe<All_Artwork_Availability_Bool_Exp>;
};


export type Query_RootAll_Artwork_Availability_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Artwork_Availability_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artwork_Availability_Order_By>>;
  where?: Maybe<All_Artwork_Availability_Bool_Exp>;
};


export type Query_RootAll_Artwork_Availability_TableArgs = {
  distinct_on?: Maybe<Array<All_Artwork_Availability_Table_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artwork_Availability_Table_Order_By>>;
  where?: Maybe<All_Artwork_Availability_Table_Bool_Exp>;
};


export type Query_RootAll_Artwork_Availability_Table_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Artwork_Availability_Table_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artwork_Availability_Table_Order_By>>;
  where?: Maybe<All_Artwork_Availability_Table_Bool_Exp>;
};


export type Query_RootAll_Artwork_Availability_Table_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


export type Query_RootAll_CollectorsArgs = {
  distinct_on?: Maybe<Array<All_Collectors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Collectors_Order_By>>;
  where?: Maybe<All_Collectors_Bool_Exp>;
};


export type Query_RootAll_Collectors_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Collectors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Collectors_Order_By>>;
  where?: Maybe<All_Collectors_Bool_Exp>;
};


export type Query_RootAll_GalleriesArgs = {
  distinct_on?: Maybe<Array<All_Galleries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Galleries_Order_By>>;
  where?: Maybe<All_Galleries_Bool_Exp>;
};


export type Query_RootAll_Galleries_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Galleries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Galleries_Order_By>>;
  where?: Maybe<All_Galleries_Bool_Exp>;
};


export type Query_RootAnalytics_EventsArgs = {
  distinct_on?: Maybe<Array<Analytics_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Events_Order_By>>;
  where?: Maybe<Analytics_Events_Bool_Exp>;
};


export type Query_RootAnalytics_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Events_Order_By>>;
  where?: Maybe<Analytics_Events_Bool_Exp>;
};


export type Query_RootAnalytics_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAnalytics_Index_HistoryArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_History_Order_By>>;
  where?: Maybe<Analytics_Index_History_Bool_Exp>;
};


export type Query_RootAnalytics_Index_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_History_Order_By>>;
  where?: Maybe<Analytics_Index_History_Bool_Exp>;
};


export type Query_RootAnalytics_Index_History_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAnalytics_Index_TypeArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_Type_Order_By>>;
  where?: Maybe<Analytics_Index_Type_Bool_Exp>;
};


export type Query_RootAnalytics_Index_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_Type_Order_By>>;
  where?: Maybe<Analytics_Index_Type_Bool_Exp>;
};


export type Query_RootAnalytics_Index_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAnalytics_Index_ValuesArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_Values_Order_By>>;
  where?: Maybe<Analytics_Index_Values_Bool_Exp>;
};


export type Query_RootAnalytics_Index_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_Values_Order_By>>;
  where?: Maybe<Analytics_Index_Values_Bool_Exp>;
};


export type Query_RootAnalytics_Index_Values_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAnalytics_KaleidoCard_AmountsArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Amounts_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
};


export type Query_RootAnalytics_KaleidoCard_Amounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Amounts_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
};


export type Query_RootAnalytics_KaleidoCard_Amounts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAnalytics_KaleidoCard_EventsArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Events_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
};


export type Query_RootAnalytics_KaleidoCard_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Events_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
};


export type Query_RootAnalytics_KaleidoCard_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAnnounced_Art_ShowArgs = {
  distinct_on?: Maybe<Array<Announced_Art_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Announced_Art_Show_Order_By>>;
  where?: Maybe<Announced_Art_Show_Bool_Exp>;
};


export type Query_RootAnnounced_Art_Show_AggregateArgs = {
  distinct_on?: Maybe<Array<Announced_Art_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Announced_Art_Show_Order_By>>;
  where?: Maybe<Announced_Art_Show_Bool_Exp>;
};


export type Query_RootApp_NotificationsArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Order_By>>;
  where?: Maybe<App_Notifications_Bool_Exp>;
};


export type Query_RootApp_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Order_By>>;
  where?: Maybe<App_Notifications_Bool_Exp>;
};


export type Query_RootApp_Notifications_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArt_History_MovementArgs = {
  distinct_on?: Maybe<Array<Art_History_Movement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_History_Movement_Order_By>>;
  where?: Maybe<Art_History_Movement_Bool_Exp>;
};


export type Query_RootArt_History_Movement_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_History_Movement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_History_Movement_Order_By>>;
  where?: Maybe<Art_History_Movement_Bool_Exp>;
};


export type Query_RootArt_History_Movement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootArt_Prize_ArtworkArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};


export type Query_RootArt_Prize_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};


export type Query_RootArt_Prize_Artwork_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArt_Prize_ShowArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Order_By>>;
  where?: Maybe<Art_Prize_Show_Bool_Exp>;
};


export type Query_RootArt_Prize_Show_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Order_By>>;
  where?: Maybe<Art_Prize_Show_Bool_Exp>;
};


export type Query_RootArt_Prize_Show_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArt_Prize_Show_WinnersArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Show_Winners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Winners_Order_By>>;
  where?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
};


export type Query_RootArt_Prize_Show_Winners_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Show_Winners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Winners_Order_By>>;
  where?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
};


export type Query_RootArt_Prize_Show_Winners_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootArt_Prize_WinnerArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Bool_Exp>;
};


export type Query_RootArt_Prize_Winner_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Bool_Exp>;
};


export type Query_RootArt_Prize_Winner_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArt_Prize_Winner_RankingArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Ranking_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Ranking_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Ranking_Bool_Exp>;
};


export type Query_RootArt_Prize_Winner_Ranking_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Ranking_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Ranking_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Ranking_Bool_Exp>;
};


export type Query_RootArt_Prizes_Links_FilteredArgs = {
  distinct_on?: Maybe<Array<Art_Prizes_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prizes_Links_Filtered_Order_By>>;
  where?: Maybe<Art_Prizes_Links_Filtered_Bool_Exp>;
};


export type Query_RootArt_Prizes_Links_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prizes_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prizes_Links_Filtered_Order_By>>;
  where?: Maybe<Art_Prizes_Links_Filtered_Bool_Exp>;
};


export type Query_RootArt_Show_Artwork_ListingArgs = {
  distinct_on?: Maybe<Array<Art_Show_Artwork_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Show_Artwork_Listing_Order_By>>;
  where?: Maybe<Art_Show_Artwork_Listing_Bool_Exp>;
};


export type Query_RootArt_Show_Artwork_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Show_Artwork_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Show_Artwork_Listing_Order_By>>;
  where?: Maybe<Art_Show_Artwork_Listing_Bool_Exp>;
};


export type Query_RootArt_Show_DetailsArgs = {
  distinct_on?: Maybe<Array<Art_Show_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Show_Details_Order_By>>;
  where?: Maybe<Art_Show_Details_Bool_Exp>;
};


export type Query_RootArt_Show_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Show_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Show_Details_Order_By>>;
  where?: Maybe<Art_Show_Details_Bool_Exp>;
};


export type Query_RootArt_Show_Details_By_PkArgs = {
  uri: Scalars['String'];
};


export type Query_RootArt_Type_To_Artwork_DisciplineArgs = {
  distinct_on?: Maybe<Array<Art_Type_To_Artwork_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Type_To_Artwork_Discipline_Order_By>>;
  where?: Maybe<Art_Type_To_Artwork_Discipline_Bool_Exp>;
};


export type Query_RootArt_Type_To_Artwork_Discipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Type_To_Artwork_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Type_To_Artwork_Discipline_Order_By>>;
  where?: Maybe<Art_Type_To_Artwork_Discipline_Bool_Exp>;
};


export type Query_RootArt_Type_To_Artwork_Discipline_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootArtist_CollectionArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_Order_By>>;
  where?: Maybe<Artist_Collection_Bool_Exp>;
};


export type Query_RootArtist_Collection_CategoriesArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_Categories_Order_By>>;
  where?: Maybe<Artist_Collection_Categories_Bool_Exp>;
};


export type Query_RootArtist_Collection_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_Categories_Order_By>>;
  where?: Maybe<Artist_Collection_Categories_Bool_Exp>;
};


export type Query_RootArtist_Collection_Categories_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootArtist_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_Order_By>>;
  where?: Maybe<Artist_Collection_Bool_Exp>;
};


export type Query_RootArtist_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtist_Collection_To_Artist_ContextArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


export type Query_RootArtist_Collection_To_Artist_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


export type Query_RootArtist_Collection_To_Artist_Context_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtist_Vision_Listings_By_InterestArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Listings_By_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Listings_By_Interest_Order_By>>;
  where?: Maybe<Artist_Vision_Listings_By_Interest_Bool_Exp>;
};


export type Query_RootArtist_Vision_Listings_By_Interest_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Listings_By_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Listings_By_Interest_Order_By>>;
  where?: Maybe<Artist_Vision_Listings_By_Interest_Bool_Exp>;
};


export type Query_RootArtist_Art_History_ListingArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Listing_Order_By>>;
  where?: Maybe<Artist_Art_History_Listing_Bool_Exp>;
};


export type Query_RootArtist_Art_History_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Listing_Order_By>>;
  where?: Maybe<Artist_Art_History_Listing_Bool_Exp>;
};


export type Query_RootArtist_Art_History_Listing_By_MovementArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Order_By>>;
  where?: Maybe<Artist_Art_History_Listing_By_Movement_Bool_Exp>;
};


export type Query_RootArtist_Art_History_Listing_By_Movement_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Order_By>>;
  where?: Maybe<Artist_Art_History_Listing_By_Movement_Bool_Exp>;
};


export type Query_RootArtist_Art_History_VisibilityArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Visibility_Order_By>>;
  where?: Maybe<Artist_Art_History_Visibility_Bool_Exp>;
};


export type Query_RootArtist_Art_History_Visibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Visibility_Order_By>>;
  where?: Maybe<Artist_Art_History_Visibility_Bool_Exp>;
};


export type Query_RootArtist_Art_History_Visibility_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtist_Curation_ScoreArgs = {
  distinct_on?: Maybe<Array<Artist_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Curation_Score_Order_By>>;
  where?: Maybe<Artist_Curation_Score_Bool_Exp>;
};


export type Query_RootArtist_Curation_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Curation_Score_Order_By>>;
  where?: Maybe<Artist_Curation_Score_Bool_Exp>;
};


export type Query_RootArtist_Curation_Score_By_PkArgs = {
  artist_context_id: Scalars['bigint'];
};


export type Query_RootArtist_Explore_InfoArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Info_Order_By>>;
  where?: Maybe<Artist_Explore_Info_Bool_Exp>;
};


export type Query_RootArtist_Explore_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Info_Order_By>>;
  where?: Maybe<Artist_Explore_Info_Bool_Exp>;
};


export type Query_RootArtist_Explore_InterestsArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


export type Query_RootArtist_Explore_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


export type Query_RootArtist_Explore_ListingArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_Bool_Exp>;
};


export type Query_RootArtist_Explore_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_Bool_Exp>;
};


export type Query_RootArtist_Explore_Listing_With_City_DataArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_With_City_Data_Bool_Exp>;
};


export type Query_RootArtist_Explore_Listing_With_City_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_With_City_Data_Bool_Exp>;
};


export type Query_RootArtist_Explore_VisibilityArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Visibility_Order_By>>;
  where?: Maybe<Artist_Explore_Visibility_Bool_Exp>;
};


export type Query_RootArtist_Explore_Visibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Visibility_Order_By>>;
  where?: Maybe<Artist_Explore_Visibility_Bool_Exp>;
};


export type Query_RootArtist_Explore_Visibility_By_PkArgs = {
  appear_reason: Scalars['String'];
  artist_context_id: Scalars['bigint'];
};


export type Query_RootArtist_Links_FilteredArgs = {
  distinct_on?: Maybe<Array<Artist_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Links_Filtered_Order_By>>;
  where?: Maybe<Artist_Links_Filtered_Bool_Exp>;
};


export type Query_RootArtist_Links_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Links_Filtered_Order_By>>;
  where?: Maybe<Artist_Links_Filtered_Bool_Exp>;
};


export type Query_RootArtist_NewsletterArgs = {
  distinct_on?: Maybe<Array<Artist_Newsletter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Newsletter_Order_By>>;
  where?: Maybe<Artist_Newsletter_Bool_Exp>;
};


export type Query_RootArtist_Newsletter_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Newsletter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Newsletter_Order_By>>;
  where?: Maybe<Artist_Newsletter_Bool_Exp>;
};


export type Query_RootArtist_Newsletter_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootArtist_Popular_DisciplineArgs = {
  distinct_on?: Maybe<Array<Artist_Popular_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Popular_Discipline_Order_By>>;
  where?: Maybe<Artist_Popular_Discipline_Bool_Exp>;
};


export type Query_RootArtist_Popular_Discipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Popular_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Popular_Discipline_Order_By>>;
  where?: Maybe<Artist_Popular_Discipline_Bool_Exp>;
};


export type Query_RootArtist_Preview_ArtworksArgs = {
  distinct_on?: Maybe<Array<Artist_Preview_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Preview_Artworks_Order_By>>;
  where?: Maybe<Artist_Preview_Artworks_Bool_Exp>;
};


export type Query_RootArtist_Preview_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Preview_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Preview_Artworks_Order_By>>;
  where?: Maybe<Artist_Preview_Artworks_Bool_Exp>;
};


export type Query_RootArtist_Vision_ListingArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Listing_Order_By>>;
  where?: Maybe<Artist_Vision_Listing_Bool_Exp>;
};


export type Query_RootArtist_Vision_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Listing_Order_By>>;
  where?: Maybe<Artist_Vision_Listing_Bool_Exp>;
};


export type Query_RootArtist_Vision_VisibilityArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Visibility_Order_By>>;
  where?: Maybe<Artist_Vision_Visibility_Bool_Exp>;
};


export type Query_RootArtist_Vision_Visibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Visibility_Order_By>>;
  where?: Maybe<Artist_Vision_Visibility_Bool_Exp>;
};


export type Query_RootArtist_Vision_Visibility_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtworkArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


export type Query_RootArtwork_Art_Universe_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Art_Universe_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Art_Universe_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Art_Universe_Listing_Bool_Exp>;
};


export type Query_RootArtwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


export type Query_RootArtwork_Ai_TagsArgs = {
  distinct_on?: Maybe<Array<Artwork_Ai_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Ai_Tags_Order_By>>;
  where?: Maybe<Artwork_Ai_Tags_Bool_Exp>;
};


export type Query_RootArtwork_Ai_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Ai_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Ai_Tags_Order_By>>;
  where?: Maybe<Artwork_Ai_Tags_Bool_Exp>;
};


export type Query_RootArtwork_Ai_Tags_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Art_History_TimetableArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_History_Timetable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_History_Timetable_Order_By>>;
  where?: Maybe<Artwork_Art_History_Timetable_Bool_Exp>;
};


export type Query_RootArtwork_Art_History_Timetable_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_History_Timetable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_History_Timetable_Order_By>>;
  where?: Maybe<Artwork_Art_History_Timetable_Bool_Exp>;
};


export type Query_RootArtwork_Art_History_Timetable_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Artist_Art_History_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Art_History_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Art_History_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Art_History_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Artist_Art_History_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Art_History_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Art_History_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Art_History_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Artist_Collection_Type_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Collection_Type_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Artist_Collection_Type_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Collection_Type_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Artist_Profile_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Profile_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Profile_Info_Order_By>>;
  where?: Maybe<Artwork_Artist_Profile_Info_Bool_Exp>;
};


export type Query_RootArtwork_Artist_Profile_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Profile_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Profile_Info_Order_By>>;
  where?: Maybe<Artwork_Artist_Profile_Info_Bool_Exp>;
};


export type Query_RootArtwork_Artist_Profile_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Profile_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Profile_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Profile_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Artist_Profile_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Profile_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Profile_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Profile_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Artist_Vision_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Vision_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Vision_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Vision_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Artist_Vision_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Vision_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Vision_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Vision_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Aspect_RatioArgs = {
  distinct_on?: Maybe<Array<Artwork_Aspect_Ratio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Aspect_Ratio_Order_By>>;
  where?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
};


export type Query_RootArtwork_Aspect_Ratio_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Aspect_Ratio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Aspect_Ratio_Order_By>>;
  where?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
};


export type Query_RootArtwork_Aspect_Ratio_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


export type Query_RootArtwork_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Collected_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Collected_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Collected_Listing_Order_By>>;
  where?: Maybe<Artwork_Collected_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Collected_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Collected_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Collected_Listing_Order_By>>;
  where?: Maybe<Artwork_Collected_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Count_MilestonesArgs = {
  distinct_on?: Maybe<Array<Artwork_Count_Milestones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Count_Milestones_Order_By>>;
  where?: Maybe<Artwork_Count_Milestones_Bool_Exp>;
};


export type Query_RootArtwork_Count_Milestones_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Count_Milestones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Count_Milestones_Order_By>>;
  where?: Maybe<Artwork_Count_Milestones_Bool_Exp>;
};


export type Query_RootArtwork_Count_Milestones_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootArtwork_Curation_ScoreArgs = {
  distinct_on?: Maybe<Array<Artwork_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Curation_Score_Order_By>>;
  where?: Maybe<Artwork_Curation_Score_Bool_Exp>;
};


export type Query_RootArtwork_Curation_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Curation_Score_Order_By>>;
  where?: Maybe<Artwork_Curation_Score_Bool_Exp>;
};


export type Query_RootArtwork_Curation_Score_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


export type Query_RootArtwork_DetailsArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Order_By>>;
  where?: Maybe<Artwork_Details_Bool_Exp>;
};


export type Query_RootArtwork_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Order_By>>;
  where?: Maybe<Artwork_Details_Bool_Exp>;
};


export type Query_RootArtwork_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Details_TranslationArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Artwork_Details_Translation_Bool_Exp>;
};


export type Query_RootArtwork_Details_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Artwork_Details_Translation_Bool_Exp>;
};


export type Query_RootArtwork_Details_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Digital_TwinArgs = {
  distinct_on?: Maybe<Array<Artwork_Digital_Twin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Digital_Twin_Order_By>>;
  where?: Maybe<Artwork_Digital_Twin_Bool_Exp>;
};


export type Query_RootArtwork_Digital_Twin_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Digital_Twin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Digital_Twin_Order_By>>;
  where?: Maybe<Artwork_Digital_Twin_Bool_Exp>;
};


export type Query_RootArtwork_Digital_Twin_By_PkArgs = {
  digital_twin_id: Scalars['bigint'];
};


export type Query_RootArtwork_Documents_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Documents_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Documents_Info_Order_By>>;
  where?: Maybe<Artwork_Documents_Info_Bool_Exp>;
};


export type Query_RootArtwork_Documents_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Documents_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Documents_Info_Order_By>>;
  where?: Maybe<Artwork_Documents_Info_Bool_Exp>;
};


export type Query_RootArtwork_EventsArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


export type Query_RootArtwork_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


export type Query_RootArtwork_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Explore_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Info_Order_By>>;
  where?: Maybe<Artwork_Explore_Info_Bool_Exp>;
};


export type Query_RootArtwork_Explore_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Info_Order_By>>;
  where?: Maybe<Artwork_Explore_Info_Bool_Exp>;
};


export type Query_RootArtwork_Explore_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Listing_Order_By>>;
  where?: Maybe<Artwork_Explore_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Explore_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Listing_Order_By>>;
  where?: Maybe<Artwork_Explore_Listing_Bool_Exp>;
};


export type Query_RootArtwork_External_Buy_LinkArgs = {
  distinct_on?: Maybe<Array<Artwork_External_Buy_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_External_Buy_Link_Order_By>>;
  where?: Maybe<Artwork_External_Buy_Link_Bool_Exp>;
};


export type Query_RootArtwork_External_Buy_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_External_Buy_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_External_Buy_Link_Order_By>>;
  where?: Maybe<Artwork_External_Buy_Link_Bool_Exp>;
};


export type Query_RootArtwork_External_Buy_Link_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


export type Query_RootArtwork_Favorite_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorite_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorite_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorite_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Favorite_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorite_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorite_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorite_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Favorited_By_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorited_By_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorited_By_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorited_By_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Favorited_By_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorited_By_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorited_By_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorited_By_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Favorites_Art_Universe_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorites_Art_Universe_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Favorites_Art_Universe_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorites_Art_Universe_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Last_Print_TransactionArgs = {
  distinct_on?: Maybe<Array<Artwork_Last_Print_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Last_Print_Transaction_Order_By>>;
  where?: Maybe<Artwork_Last_Print_Transaction_Bool_Exp>;
};


export type Query_RootArtwork_Last_Print_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Last_Print_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Last_Print_Transaction_Order_By>>;
  where?: Maybe<Artwork_Last_Print_Transaction_Bool_Exp>;
};


export type Query_RootArtwork_Last_TransactionArgs = {
  distinct_on?: Maybe<Array<Artwork_Last_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Last_Transaction_Order_By>>;
  where?: Maybe<Artwork_Last_Transaction_Bool_Exp>;
};


export type Query_RootArtwork_Last_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Last_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Last_Transaction_Order_By>>;
  where?: Maybe<Artwork_Last_Transaction_Bool_Exp>;
};


export type Query_RootArtwork_LinksArgs = {
  distinct_on?: Maybe<Array<Artwork_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Links_Order_By>>;
  where?: Maybe<Artwork_Links_Bool_Exp>;
};


export type Query_RootArtwork_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Links_Order_By>>;
  where?: Maybe<Artwork_Links_Bool_Exp>;
};


export type Query_RootArtwork_PricesArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


export type Query_RootArtwork_Prices_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


export type Query_RootArtwork_Prices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Prize_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Prize_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prize_Info_Order_By>>;
  where?: Maybe<Artwork_Prize_Info_Bool_Exp>;
};


export type Query_RootArtwork_Prize_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prize_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prize_Info_Order_By>>;
  where?: Maybe<Artwork_Prize_Info_Bool_Exp>;
};


export type Query_RootArtwork_Prize_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Prize_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prize_Listing_Order_By>>;
  where?: Maybe<Artwork_Prize_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Prize_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prize_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prize_Listing_Order_By>>;
  where?: Maybe<Artwork_Prize_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_JourneyArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Purchase_Journey_HistoryArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_History_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_History_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_History_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Purchase_Journey_Last_UpdateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_Last_Update_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_Last_Update_By_PkArgs = {
  entry_kind: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum;
  journey_id: Scalars['bigint'];
  party_type: Enum_Artwork_Purchase_Journey_Party_Enum;
};


export type Query_RootArtwork_Purchase_Journey_PricingArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Pricing_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_Pricing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Pricing_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_Pricing_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Purchase_Journey_Shipping_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_Shipping_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
};


export type Query_RootArtwork_Purchase_Journey_Shipping_Info_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Secondary_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


export type Query_RootArtwork_Secondary_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


export type Query_RootArtwork_Secondary_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Showcase_FilteredArgs = {
  distinct_on?: Maybe<Array<Artwork_Showcase_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Showcase_Filtered_Order_By>>;
  where?: Maybe<Artwork_Showcase_Filtered_Bool_Exp>;
};


export type Query_RootArtwork_Showcase_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Showcase_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Showcase_Filtered_Order_By>>;
  where?: Maybe<Artwork_Showcase_Filtered_Bool_Exp>;
};


export type Query_RootArtwork_State_HistoryArgs = {
  distinct_on?: Maybe<Array<Artwork_State_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_State_History_Order_By>>;
  where?: Maybe<Artwork_State_History_Bool_Exp>;
};


export type Query_RootArtwork_State_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_State_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_State_History_Order_By>>;
  where?: Maybe<Artwork_State_History_Bool_Exp>;
};


export type Query_RootArtwork_State_History_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_StoryArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Order_By>>;
  where?: Maybe<Artwork_Story_Bool_Exp>;
};


export type Query_RootArtwork_Story_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Order_By>>;
  where?: Maybe<Artwork_Story_Bool_Exp>;
};


export type Query_RootArtwork_Story_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Story_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Media_Order_By>>;
  where?: Maybe<Artwork_Story_Media_Bool_Exp>;
};


export type Query_RootArtwork_Story_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Media_Order_By>>;
  where?: Maybe<Artwork_Story_Media_Bool_Exp>;
};


export type Query_RootArtwork_Story_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_Story_Video_LinksArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Video_Links_Order_By>>;
  where?: Maybe<Artwork_Story_Video_Links_Bool_Exp>;
};


export type Query_RootArtwork_Story_Video_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Video_Links_Order_By>>;
  where?: Maybe<Artwork_Story_Video_Links_Bool_Exp>;
};


export type Query_RootArtwork_Story_Video_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_TagArgs = {
  distinct_on?: Maybe<Array<Artwork_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Tag_Order_By>>;
  where?: Maybe<Artwork_Tag_Bool_Exp>;
};


export type Query_RootArtwork_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Tag_Order_By>>;
  where?: Maybe<Artwork_Tag_Bool_Exp>;
};


export type Query_RootArtwork_Tag_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_To_CollectionArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


export type Query_RootArtwork_To_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


export type Query_RootArtwork_To_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_To_Showroom_DetailsArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Showroom_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Showroom_Details_Order_By>>;
  where?: Maybe<Artwork_To_Showroom_Details_Bool_Exp>;
};


export type Query_RootArtwork_To_Showroom_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Showroom_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Showroom_Details_Order_By>>;
  where?: Maybe<Artwork_To_Showroom_Details_Bool_Exp>;
};


export type Query_RootArtwork_To_Showroom_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_To_TagArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Tag_Order_By>>;
  where?: Maybe<Artwork_To_Tag_Bool_Exp>;
};


export type Query_RootArtwork_To_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Tag_Order_By>>;
  where?: Maybe<Artwork_To_Tag_Bool_Exp>;
};


export type Query_RootArtwork_To_Tag_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootArtwork_TransactionsArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


export type Query_RootArtwork_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


export type Query_RootArtwork_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAuthArgs = {
  distinct_on?: Maybe<Array<Auth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Order_By>>;
  where?: Maybe<Auth_Bool_Exp>;
};


export type Query_RootAuth_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Order_By>>;
  where?: Maybe<Auth_Bool_Exp>;
};


export type Query_RootAuth_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAuth_Context_AccessArgs = {
  distinct_on?: Maybe<Array<Auth_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Context_Access_Order_By>>;
  where?: Maybe<Auth_Context_Access_Bool_Exp>;
};


export type Query_RootAuth_Context_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Context_Access_Order_By>>;
  where?: Maybe<Auth_Context_Access_Bool_Exp>;
};


export type Query_RootAuth_Context_Access_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAvailable_Print_PriceArgs = {
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Query_RootAvailable_Print_Price_AggregateArgs = {
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Query_RootAvailable_Print_Price_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAvailable_Print_Price_ViewArgs = {
  distinct_on?: Maybe<Array<Available_Print_Price_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_View_Order_By>>;
  where?: Maybe<Available_Print_Price_View_Bool_Exp>;
};


export type Query_RootAvailable_Print_Price_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Available_Print_Price_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_View_Order_By>>;
  where?: Maybe<Available_Print_Price_View_Bool_Exp>;
};


export type Query_RootBilling_Anon_ClientArgs = {
  distinct_on?: Maybe<Array<Billing_Anon_Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Anon_Client_Order_By>>;
  where?: Maybe<Billing_Anon_Client_Bool_Exp>;
};


export type Query_RootBilling_Anon_Client_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Anon_Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Anon_Client_Order_By>>;
  where?: Maybe<Billing_Anon_Client_Bool_Exp>;
};


export type Query_RootBilling_Anon_Client_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBilling_Artwork_Payment_IntentArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Bool_Exp>;
};


export type Query_RootBilling_Artwork_Payment_Intent_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Bool_Exp>;
};


export type Query_RootBilling_Artwork_Payment_Intent_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBilling_Artwork_Payment_Intent_ProductArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Product_Bool_Exp>;
};


export type Query_RootBilling_Artwork_Payment_Intent_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Product_Bool_Exp>;
};


export type Query_RootBilling_Artwork_Payment_Intent_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBilling_Artwork_Payment_Intent_Shipping_AddressArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>;
};


export type Query_RootBilling_Artwork_Payment_Intent_Shipping_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>;
};


export type Query_RootBilling_Artwork_Payment_Intent_Shipping_Address_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBilling_Artwork_Payment_Intent_Status_HistoryArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>;
};


export type Query_RootBilling_Artwork_Payment_Intent_Status_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>;
};


export type Query_RootBilling_Artwork_Payment_Intent_Status_History_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBilling_ClientArgs = {
  distinct_on?: Maybe<Array<Billing_Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Client_Order_By>>;
  where?: Maybe<Billing_Client_Bool_Exp>;
};


export type Query_RootBilling_Client_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Client_Order_By>>;
  where?: Maybe<Billing_Client_Bool_Exp>;
};


export type Query_RootBilling_Client_By_PkArgs = {
  context_id: Scalars['bigint'];
};


export type Query_RootBilling_SellerArgs = {
  distinct_on?: Maybe<Array<Billing_Seller_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Seller_Order_By>>;
  where?: Maybe<Billing_Seller_Bool_Exp>;
};


export type Query_RootBilling_Seller_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Seller_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Seller_Order_By>>;
  where?: Maybe<Billing_Seller_Bool_Exp>;
};


export type Query_RootBilling_Seller_By_PkArgs = {
  context_id: Scalars['bigint'];
};


export type Query_RootBilling_SubscriptionArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Order_By>>;
  where?: Maybe<Billing_Subscription_Bool_Exp>;
};


export type Query_RootBilling_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Order_By>>;
  where?: Maybe<Billing_Subscription_Bool_Exp>;
};


export type Query_RootBilling_Subscription_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBilling_Subscription_ProductArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Product_Order_By>>;
  where?: Maybe<Billing_Subscription_Product_Bool_Exp>;
};


export type Query_RootBilling_Subscription_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Product_Order_By>>;
  where?: Maybe<Billing_Subscription_Product_Bool_Exp>;
};


export type Query_RootBilling_Subscription_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBiolink_SectionsArgs = {
  distinct_on?: Maybe<Array<Biolink_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Biolink_Sections_Order_By>>;
  where?: Maybe<Biolink_Sections_Bool_Exp>;
};


export type Query_RootBiolink_Sections_AggregateArgs = {
  distinct_on?: Maybe<Array<Biolink_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Biolink_Sections_Order_By>>;
  where?: Maybe<Biolink_Sections_Bool_Exp>;
};


export type Query_RootBiolink_Sections_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCertificate_Of_AuthenticityArgs = {
  distinct_on?: Maybe<Array<Certificate_Of_Authenticity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Certificate_Of_Authenticity_Order_By>>;
  where?: Maybe<Certificate_Of_Authenticity_Bool_Exp>;
};


export type Query_RootCertificate_Of_Authenticity_AggregateArgs = {
  distinct_on?: Maybe<Array<Certificate_Of_Authenticity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Certificate_Of_Authenticity_Order_By>>;
  where?: Maybe<Certificate_Of_Authenticity_Bool_Exp>;
};


export type Query_RootCertificate_Of_Authenticity_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCollaborationArgs = {
  distinct_on?: Maybe<Array<Collaboration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Order_By>>;
  where?: Maybe<Collaboration_Bool_Exp>;
};


export type Query_RootCollaboration_AggregateArgs = {
  distinct_on?: Maybe<Array<Collaboration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Order_By>>;
  where?: Maybe<Collaboration_Bool_Exp>;
};


export type Query_RootCollaboration_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCollaboration_Invitation_On_ArtworkArgs = {
  distinct_on?: Maybe<Array<Collaboration_Invitation_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Invitation_On_Artwork_Order_By>>;
  where?: Maybe<Collaboration_Invitation_On_Artwork_Bool_Exp>;
};


export type Query_RootCollaboration_Invitation_On_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Collaboration_Invitation_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Invitation_On_Artwork_Order_By>>;
  where?: Maybe<Collaboration_Invitation_On_Artwork_Bool_Exp>;
};


export type Query_RootCollaboration_Invitation_On_Artwork_By_PkArgs = {
  artwork_id: Scalars['bigint'];
  invitation_id: Scalars['bigint'];
};


export type Query_RootCollectionArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};


export type Query_RootCollection_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};


export type Query_RootCollection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCollection_TranslationArgs = {
  distinct_on?: Maybe<Array<Collection_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Translation_Order_By>>;
  where?: Maybe<Collection_Translation_Bool_Exp>;
};


export type Query_RootCollection_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Translation_Order_By>>;
  where?: Maybe<Collection_Translation_Bool_Exp>;
};


export type Query_RootCollection_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCollection_Video_LinksArgs = {
  distinct_on?: Maybe<Array<Collection_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Video_Links_Order_By>>;
  where?: Maybe<Collection_Video_Links_Bool_Exp>;
};


export type Query_RootCollection_Video_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Video_Links_Order_By>>;
  where?: Maybe<Collection_Video_Links_Bool_Exp>;
};


export type Query_RootCollection_Video_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCollections_Links_FilteredArgs = {
  distinct_on?: Maybe<Array<Collections_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collections_Links_Filtered_Order_By>>;
  where?: Maybe<Collections_Links_Filtered_Bool_Exp>;
};


export type Query_RootCollections_Links_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Collections_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collections_Links_Filtered_Order_By>>;
  where?: Maybe<Collections_Links_Filtered_Bool_Exp>;
};


export type Query_RootCollector_Explore_InfoArgs = {
  distinct_on?: Maybe<Array<Collector_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collector_Explore_Info_Order_By>>;
  where?: Maybe<Collector_Explore_Info_Bool_Exp>;
};


export type Query_RootCollector_Explore_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Collector_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collector_Explore_Info_Order_By>>;
  where?: Maybe<Collector_Explore_Info_Bool_Exp>;
};


export type Query_RootCollector_Links_FilteredArgs = {
  distinct_on?: Maybe<Array<Collector_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collector_Links_Filtered_Order_By>>;
  where?: Maybe<Collector_Links_Filtered_Bool_Exp>;
};


export type Query_RootCollector_Links_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Collector_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collector_Links_Filtered_Order_By>>;
  where?: Maybe<Collector_Links_Filtered_Bool_Exp>;
};


export type Query_RootConnected_DomainArgs = {
  distinct_on?: Maybe<Array<Connected_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Connected_Domain_Order_By>>;
  where?: Maybe<Connected_Domain_Bool_Exp>;
};


export type Query_RootConnected_Domain_AggregateArgs = {
  distinct_on?: Maybe<Array<Connected_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Connected_Domain_Order_By>>;
  where?: Maybe<Connected_Domain_Bool_Exp>;
};


export type Query_RootConnected_Domain_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootContent_TranslationsArgs = {
  distinct_on?: Maybe<Array<Content_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Translations_Order_By>>;
  where?: Maybe<Content_Translations_Bool_Exp>;
};


export type Query_RootContent_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Content_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Translations_Order_By>>;
  where?: Maybe<Content_Translations_Bool_Exp>;
};


export type Query_RootContextArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};


export type Query_RootContext_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};


export type Query_RootContext_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootContext_Delegate_AccessArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


export type Query_RootContext_Delegate_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


export type Query_RootContext_Delegate_Access_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootContext_FlagsArgs = {
  distinct_on?: Maybe<Array<Context_Flags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Flags_Order_By>>;
  where?: Maybe<Context_Flags_Bool_Exp>;
};


export type Query_RootContext_Flags_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Flags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Flags_Order_By>>;
  where?: Maybe<Context_Flags_Bool_Exp>;
};


export type Query_RootContext_Flags_By_PkArgs = {
  context_id: Scalars['bigint'];
  flag_type: Enum_Flags_Type_Enum;
};


export type Query_RootContext_Suggested_TagsArgs = {
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Query_RootContext_Suggested_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Query_RootContext_Suggested_Tags_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootContext_To_MilestoneArgs = {
  distinct_on?: Maybe<Array<Context_To_Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Milestone_Order_By>>;
  where?: Maybe<Context_To_Milestone_Bool_Exp>;
};


export type Query_RootContext_To_Milestone_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_To_Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Milestone_Order_By>>;
  where?: Maybe<Context_To_Milestone_Bool_Exp>;
};


export type Query_RootContext_To_Milestone_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootContext_To_TutorialArgs = {
  distinct_on?: Maybe<Array<Context_To_Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Tutorial_Order_By>>;
  where?: Maybe<Context_To_Tutorial_Bool_Exp>;
};


export type Query_RootContext_To_Tutorial_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_To_Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Tutorial_Order_By>>;
  where?: Maybe<Context_To_Tutorial_Bool_Exp>;
};


export type Query_RootContext_To_Tutorial_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCountriesArgs = {
  distinct_on?: Maybe<Array<Countries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Countries_Order_By>>;
  where?: Maybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_AggregateArgs = {
  distinct_on?: Maybe<Array<Countries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Countries_Order_By>>;
  where?: Maybe<Countries_Bool_Exp>;
};


export type Query_RootCountries_By_PkArgs = {
  iso3: Scalars['String'];
};


export type Query_RootCrop_AreaArgs = {
  distinct_on?: Maybe<Array<Crop_Area_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Crop_Area_Order_By>>;
  where?: Maybe<Crop_Area_Bool_Exp>;
};


export type Query_RootCrop_Area_AggregateArgs = {
  distinct_on?: Maybe<Array<Crop_Area_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Crop_Area_Order_By>>;
  where?: Maybe<Crop_Area_Bool_Exp>;
};


export type Query_RootCrop_Area_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCurrency_ConversionArgs = {
  distinct_on?: Maybe<Array<Currency_Conversion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currency_Conversion_Order_By>>;
  where?: Maybe<Currency_Conversion_Bool_Exp>;
};


export type Query_RootCurrency_Conversion_AggregateArgs = {
  distinct_on?: Maybe<Array<Currency_Conversion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currency_Conversion_Order_By>>;
  where?: Maybe<Currency_Conversion_Bool_Exp>;
};


export type Query_RootCurrency_Conversion_By_PkArgs = {
  currency: Scalars['String'];
};


export type Query_RootDigital_Twin_DetailsArgs = {
  distinct_on?: Maybe<Array<Digital_Twin_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Digital_Twin_Details_Order_By>>;
  where?: Maybe<Digital_Twin_Details_Bool_Exp>;
};


export type Query_RootDigital_Twin_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Digital_Twin_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Digital_Twin_Details_Order_By>>;
  where?: Maybe<Digital_Twin_Details_Bool_Exp>;
};


export type Query_RootDigital_Twin_Details_By_PkArgs = {
  digital_twin_id: Scalars['bigint'];
};


export type Query_RootDirect_Messages_From_CollectorsArgs = {
  distinct_on?: Maybe<Array<Direct_Messages_From_Collectors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direct_Messages_From_Collectors_Order_By>>;
  where?: Maybe<Direct_Messages_From_Collectors_Bool_Exp>;
};


export type Query_RootDirect_Messages_From_Collectors_AggregateArgs = {
  distinct_on?: Maybe<Array<Direct_Messages_From_Collectors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direct_Messages_From_Collectors_Order_By>>;
  where?: Maybe<Direct_Messages_From_Collectors_Bool_Exp>;
};


export type Query_RootDiscount_CodeArgs = {
  distinct_on?: Maybe<Array<Discount_Code_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_Order_By>>;
  where?: Maybe<Discount_Code_Bool_Exp>;
};


export type Query_RootDiscount_Code_AggregateArgs = {
  distinct_on?: Maybe<Array<Discount_Code_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_Order_By>>;
  where?: Maybe<Discount_Code_Bool_Exp>;
};


export type Query_RootDiscount_Code_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDiscount_Code_UsageArgs = {
  distinct_on?: Maybe<Array<Discount_Code_Usage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_Usage_Order_By>>;
  where?: Maybe<Discount_Code_Usage_Bool_Exp>;
};


export type Query_RootDiscount_Code_Usage_AggregateArgs = {
  distinct_on?: Maybe<Array<Discount_Code_Usage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_Usage_Order_By>>;
  where?: Maybe<Discount_Code_Usage_Bool_Exp>;
};


export type Query_RootDiscount_Code_Usage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDomain_Registrant_InfoArgs = {
  distinct_on?: Maybe<Array<Domain_Registrant_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Domain_Registrant_Info_Order_By>>;
  where?: Maybe<Domain_Registrant_Info_Bool_Exp>;
};


export type Query_RootDomain_Registrant_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Domain_Registrant_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Domain_Registrant_Info_Order_By>>;
  where?: Maybe<Domain_Registrant_Info_Bool_Exp>;
};


export type Query_RootDomain_Registrant_Info_By_PkArgs = {
  domain_registration_id: Scalars['bigint'];
};


export type Query_RootDomain_RegistrationArgs = {
  distinct_on?: Maybe<Array<Domain_Registration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Domain_Registration_Order_By>>;
  where?: Maybe<Domain_Registration_Bool_Exp>;
};


export type Query_RootDomain_Registration_AggregateArgs = {
  distinct_on?: Maybe<Array<Domain_Registration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Domain_Registration_Order_By>>;
  where?: Maybe<Domain_Registration_Bool_Exp>;
};


export type Query_RootDomain_Registration_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDummy_Table_For_FrontArgs = {
  distinct_on?: Maybe<Array<Dummy_Table_For_Front_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dummy_Table_For_Front_Order_By>>;
  where?: Maybe<Dummy_Table_For_Front_Bool_Exp>;
};


export type Query_RootDummy_Table_For_Front_AggregateArgs = {
  distinct_on?: Maybe<Array<Dummy_Table_For_Front_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dummy_Table_For_Front_Order_By>>;
  where?: Maybe<Dummy_Table_For_Front_Bool_Exp>;
};


export type Query_RootDummy_Table_For_Front_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDynamic_CvArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Order_By>>;
  where?: Maybe<Dynamic_Cv_Bool_Exp>;
};


export type Query_RootDynamic_Cv_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Order_By>>;
  where?: Maybe<Dynamic_Cv_Bool_Exp>;
};


export type Query_RootDynamic_Cv_AwardArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Award_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Award_Order_By>>;
  where?: Maybe<Dynamic_Cv_Award_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Award_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Award_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Award_Order_By>>;
  where?: Maybe<Dynamic_Cv_Award_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Award_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDynamic_Cv_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDynamic_Cv_CollectionArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Collection_Order_By>>;
  where?: Maybe<Dynamic_Cv_Collection_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Collection_Order_By>>;
  where?: Maybe<Dynamic_Cv_Collection_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDynamic_Cv_ContactArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Contact_Order_By>>;
  where?: Maybe<Dynamic_Cv_Contact_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Contact_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Contact_Order_By>>;
  where?: Maybe<Dynamic_Cv_Contact_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Contact_By_PkArgs = {
  cv_id: Scalars['bigint'];
};


export type Query_RootDynamic_Cv_EducationArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Education_Order_By>>;
  where?: Maybe<Dynamic_Cv_Education_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Education_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Education_Order_By>>;
  where?: Maybe<Dynamic_Cv_Education_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Education_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDynamic_Cv_ExhibitionArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Exhibition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Exhibition_Order_By>>;
  where?: Maybe<Dynamic_Cv_Exhibition_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Exhibition_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Exhibition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Exhibition_Order_By>>;
  where?: Maybe<Dynamic_Cv_Exhibition_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Exhibition_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDynamic_Cv_PublicationArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Publication_Order_By>>;
  where?: Maybe<Dynamic_Cv_Publication_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Publication_Order_By>>;
  where?: Maybe<Dynamic_Cv_Publication_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Publication_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDynamic_Cv_SectionArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Section_Order_By>>;
  where?: Maybe<Dynamic_Cv_Section_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Section_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Section_Order_By>>;
  where?: Maybe<Dynamic_Cv_Section_Bool_Exp>;
};


export type Query_RootDynamic_Cv_Section_By_PkArgs = {
  cv_id: Scalars['bigint'];
  type: Enum_Dynamic_Cv_Section_Type_Enum;
};


export type Query_RootEnum_Account_Notice_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Account_Notice_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Account_Notice_Type_Order_By>>;
  where?: Maybe<Enum_Account_Notice_Type_Bool_Exp>;
};


export type Query_RootEnum_Account_Notice_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Account_Notice_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Account_Notice_Type_Order_By>>;
  where?: Maybe<Enum_Account_Notice_Type_Bool_Exp>;
};


export type Query_RootEnum_Account_Notice_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Achievement_TagsArgs = {
  distinct_on?: Maybe<Array<Enum_Achievement_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Achievement_Tags_Order_By>>;
  where?: Maybe<Enum_Achievement_Tags_Bool_Exp>;
};


export type Query_RootEnum_Achievement_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Achievement_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Achievement_Tags_Order_By>>;
  where?: Maybe<Enum_Achievement_Tags_Bool_Exp>;
};


export type Query_RootEnum_Achievement_Tags_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Add_Artwork_Flow_StepArgs = {
  distinct_on?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Order_By>>;
  where?: Maybe<Enum_Add_Artwork_Flow_Step_Bool_Exp>;
};


export type Query_RootEnum_Add_Artwork_Flow_Step_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Order_By>>;
  where?: Maybe<Enum_Add_Artwork_Flow_Step_Bool_Exp>;
};


export type Query_RootEnum_Add_Artwork_Flow_Step_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Analytics_KaleidoCard_CategoryArgs = {
  distinct_on?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Order_By>>;
  where?: Maybe<Enum_Analytics_KaleidoCard_Category_Bool_Exp>;
};


export type Query_RootEnum_Analytics_KaleidoCard_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Order_By>>;
  where?: Maybe<Enum_Analytics_KaleidoCard_Category_Bool_Exp>;
};


export type Query_RootEnum_Analytics_KaleidoCard_Category_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Analytics_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Analytics_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Analytics_Type_Order_By>>;
  where?: Maybe<Enum_Analytics_Type_Bool_Exp>;
};


export type Query_RootEnum_Analytics_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Analytics_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Analytics_Type_Order_By>>;
  where?: Maybe<Enum_Analytics_Type_Bool_Exp>;
};


export type Query_RootEnum_Analytics_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_App_Notification_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_App_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_App_Notification_Type_Order_By>>;
  where?: Maybe<Enum_App_Notification_Type_Bool_Exp>;
};


export type Query_RootEnum_App_Notification_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_App_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_App_Notification_Type_Order_By>>;
  where?: Maybe<Enum_App_Notification_Type_Bool_Exp>;
};


export type Query_RootEnum_App_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Art_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Art_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Art_Type_Order_By>>;
  where?: Maybe<Enum_Art_Type_Bool_Exp>;
};


export type Query_RootEnum_Art_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Art_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Art_Type_Order_By>>;
  where?: Maybe<Enum_Art_Type_Bool_Exp>;
};


export type Query_RootEnum_Art_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Art_Type_ExtensionArgs = {
  distinct_on?: Maybe<Array<Enum_Art_Type_Extension_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Art_Type_Extension_Order_By>>;
  where?: Maybe<Enum_Art_Type_Extension_Bool_Exp>;
};


export type Query_RootEnum_Art_Type_Extension_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Art_Type_Extension_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Art_Type_Extension_Order_By>>;
  where?: Maybe<Enum_Art_Type_Extension_Bool_Exp>;
};


export type Query_RootEnum_Art_Type_Extension_By_PkArgs = {
  Enum_art_type: Enum_Art_Type_Enum;
};


export type Query_RootEnum_Artist_Collection_CategoriesArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_Categories_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_Categories_Bool_Exp>;
};


export type Query_RootEnum_Artist_Collection_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_Categories_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_Categories_Bool_Exp>;
};


export type Query_RootEnum_Artist_Collection_Categories_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artist_Collection_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_State_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_State_Bool_Exp>;
};


export type Query_RootEnum_Artist_Collection_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_State_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_State_Bool_Exp>;
};


export type Query_RootEnum_Artist_Collection_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artist_Collection_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_Type_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_Type_Bool_Exp>;
};


export type Query_RootEnum_Artist_Collection_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_Type_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_Type_Bool_Exp>;
};


export type Query_RootEnum_Artist_Collection_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_CategoryArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Category_Order_By>>;
  where?: Maybe<Enum_Artwork_Category_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Category_Order_By>>;
  where?: Maybe<Enum_Artwork_Category_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Category_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_Count_Milestone_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Count_Milestone_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Count_Milestone_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Count_Milestone_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Count_Milestone_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_DisciplineArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Discipline_Order_By>>;
  where?: Maybe<Enum_Artwork_Discipline_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Discipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Discipline_Order_By>>;
  where?: Maybe<Enum_Artwork_Discipline_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Discipline_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_Edition_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Edition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Edition_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Edition_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Edition_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Edition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Edition_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Edition_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Edition_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEnum_Artwork_Event_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Event_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Event_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Event_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Event_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Event_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Event_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_GenreArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Genre_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Genre_Order_By>>;
  where?: Maybe<Enum_Artwork_Genre_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Genre_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Genre_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Genre_Order_By>>;
  where?: Maybe<Enum_Artwork_Genre_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Genre_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_Purchase_Journey_History_Entry_KindArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Purchase_Journey_History_Entry_Kind_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Purchase_Journey_History_Entry_Kind_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_Purchase_Journey_PartyArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Party_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Purchase_Journey_Party_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Party_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Purchase_Journey_Party_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_Purchase_Journey_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Status_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Purchase_Journey_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Status_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Purchase_Journey_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_Showroom_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Showroom_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Showroom_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Showroom_Status_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Showroom_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Showroom_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Showroom_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Showroom_Status_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Showroom_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Status_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Status_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_TagArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Tag_Order_By>>;
  where?: Maybe<Enum_Artwork_Tag_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Tag_Order_By>>;
  where?: Maybe<Enum_Artwork_Tag_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Tag_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_Tag_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Tag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Tag_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Tag_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Tag_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Tag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Tag_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Tag_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Tag_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Artwork_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Type_Bool_Exp>;
};


export type Query_RootEnum_Artwork_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Audio_Generation_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Audio_Generation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Audio_Generation_Status_Order_By>>;
  where?: Maybe<Enum_Audio_Generation_Status_Bool_Exp>;
};


export type Query_RootEnum_Audio_Generation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Audio_Generation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Audio_Generation_Status_Order_By>>;
  where?: Maybe<Enum_Audio_Generation_Status_Bool_Exp>;
};


export type Query_RootEnum_Audio_Generation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Auth_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Auth_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Auth_Type_Order_By>>;
  where?: Maybe<Enum_Auth_Type_Bool_Exp>;
};


export type Query_RootEnum_Auth_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Auth_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Auth_Type_Order_By>>;
  where?: Maybe<Enum_Auth_Type_Bool_Exp>;
};


export type Query_RootEnum_Auth_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Billing_Artwork_Payment_Intent_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp>;
};


export type Query_RootEnum_Billing_Artwork_Payment_Intent_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp>;
};


export type Query_RootEnum_Billing_Artwork_Payment_Intent_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Billing_Payment_Intent_Product_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Bool_Exp>;
};


export type Query_RootEnum_Billing_Payment_Intent_Product_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Bool_Exp>;
};


export type Query_RootEnum_Billing_Payment_Intent_Product_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Billing_Seller_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Seller_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Seller_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Seller_Status_Bool_Exp>;
};


export type Query_RootEnum_Billing_Seller_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Seller_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Seller_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Seller_Status_Bool_Exp>;
};


export type Query_RootEnum_Billing_Seller_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Billing_Subscription_PeriodArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Period_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Period_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Period_Bool_Exp>;
};


export type Query_RootEnum_Billing_Subscription_Period_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Period_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Period_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Period_Bool_Exp>;
};


export type Query_RootEnum_Billing_Subscription_Period_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Billing_Subscription_Product_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Product_Type_Bool_Exp>;
};


export type Query_RootEnum_Billing_Subscription_Product_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Product_Type_Bool_Exp>;
};


export type Query_RootEnum_Billing_Subscription_Product_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Billing_Subscription_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Status_Bool_Exp>;
};


export type Query_RootEnum_Billing_Subscription_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Status_Bool_Exp>;
};


export type Query_RootEnum_Billing_Subscription_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_BlockchainArgs = {
  distinct_on?: Maybe<Array<Enum_Blockchain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Blockchain_Order_By>>;
  where?: Maybe<Enum_Blockchain_Bool_Exp>;
};


export type Query_RootEnum_Blockchain_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Blockchain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Blockchain_Order_By>>;
  where?: Maybe<Enum_Blockchain_Bool_Exp>;
};


export type Query_RootEnum_Blockchain_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Collaboration_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Collaboration_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Collaboration_Status_Order_By>>;
  where?: Maybe<Enum_Collaboration_Status_Bool_Exp>;
};


export type Query_RootEnum_Collaboration_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Collaboration_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Collaboration_Status_Order_By>>;
  where?: Maybe<Enum_Collaboration_Status_Bool_Exp>;
};


export type Query_RootEnum_Collaboration_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Collection_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Collection_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Collection_Type_Order_By>>;
  where?: Maybe<Enum_Collection_Type_Bool_Exp>;
};


export type Query_RootEnum_Collection_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Collection_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Collection_Type_Order_By>>;
  where?: Maybe<Enum_Collection_Type_Bool_Exp>;
};


export type Query_RootEnum_Collection_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Connected_Domain_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Connected_Domain_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Connected_Domain_State_Order_By>>;
  where?: Maybe<Enum_Connected_Domain_State_Bool_Exp>;
};


export type Query_RootEnum_Connected_Domain_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Connected_Domain_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Connected_Domain_State_Order_By>>;
  where?: Maybe<Enum_Connected_Domain_State_Bool_Exp>;
};


export type Query_RootEnum_Connected_Domain_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Content_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Content_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Content_Type_Order_By>>;
  where?: Maybe<Enum_Content_Type_Bool_Exp>;
};


export type Query_RootEnum_Content_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Content_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Content_Type_Order_By>>;
  where?: Maybe<Enum_Content_Type_Bool_Exp>;
};


export type Query_RootEnum_Content_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Context_Delegate_RoleArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Delegate_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Delegate_Role_Order_By>>;
  where?: Maybe<Enum_Context_Delegate_Role_Bool_Exp>;
};


export type Query_RootEnum_Context_Delegate_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Delegate_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Delegate_Role_Order_By>>;
  where?: Maybe<Enum_Context_Delegate_Role_Bool_Exp>;
};


export type Query_RootEnum_Context_Delegate_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Context_Delegate_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Delegate_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Delegate_State_Order_By>>;
  where?: Maybe<Enum_Context_Delegate_State_Bool_Exp>;
};


export type Query_RootEnum_Context_Delegate_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Delegate_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Delegate_State_Order_By>>;
  where?: Maybe<Enum_Context_Delegate_State_Bool_Exp>;
};


export type Query_RootEnum_Context_Delegate_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Context_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_State_Order_By>>;
  where?: Maybe<Enum_Context_State_Bool_Exp>;
};


export type Query_RootEnum_Context_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_State_Order_By>>;
  where?: Maybe<Enum_Context_State_Bool_Exp>;
};


export type Query_RootEnum_Context_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Context_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Type_Order_By>>;
  where?: Maybe<Enum_Context_Type_Bool_Exp>;
};


export type Query_RootEnum_Context_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Type_Order_By>>;
  where?: Maybe<Enum_Context_Type_Bool_Exp>;
};


export type Query_RootEnum_Context_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Discount_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_State_Order_By>>;
  where?: Maybe<Enum_Discount_State_Bool_Exp>;
};


export type Query_RootEnum_Discount_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_State_Order_By>>;
  where?: Maybe<Enum_Discount_State_Bool_Exp>;
};


export type Query_RootEnum_Discount_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Discount_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_Type_Order_By>>;
  where?: Maybe<Enum_Discount_Type_Bool_Exp>;
};


export type Query_RootEnum_Discount_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_Type_Order_By>>;
  where?: Maybe<Enum_Discount_Type_Bool_Exp>;
};


export type Query_RootEnum_Discount_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Discount_Value_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_Value_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_Value_Type_Order_By>>;
  where?: Maybe<Enum_Discount_Value_Type_Bool_Exp>;
};


export type Query_RootEnum_Discount_Value_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_Value_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_Value_Type_Order_By>>;
  where?: Maybe<Enum_Discount_Value_Type_Bool_Exp>;
};


export type Query_RootEnum_Discount_Value_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Domain_Registration_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Domain_Registration_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Domain_Registration_Status_Order_By>>;
  where?: Maybe<Enum_Domain_Registration_Status_Bool_Exp>;
};


export type Query_RootEnum_Domain_Registration_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Domain_Registration_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Domain_Registration_Status_Order_By>>;
  where?: Maybe<Enum_Domain_Registration_Status_Bool_Exp>;
};


export type Query_RootEnum_Domain_Registration_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Dynamic_Cv_Exhibition_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp>;
};


export type Query_RootEnum_Dynamic_Cv_Exhibition_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp>;
};


export type Query_RootEnum_Dynamic_Cv_Exhibition_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Dynamic_Cv_Section_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Section_Type_Bool_Exp>;
};


export type Query_RootEnum_Dynamic_Cv_Section_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Section_Type_Bool_Exp>;
};


export type Query_RootEnum_Dynamic_Cv_Section_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Dynamic_Cv_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Status_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Status_Bool_Exp>;
};


export type Query_RootEnum_Dynamic_Cv_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Status_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Status_Bool_Exp>;
};


export type Query_RootEnum_Dynamic_Cv_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Edition_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Edition_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Edition_Status_Order_By>>;
  where?: Maybe<Enum_Edition_Status_Bool_Exp>;
};


export type Query_RootEnum_Edition_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Edition_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Edition_Status_Order_By>>;
  where?: Maybe<Enum_Edition_Status_Bool_Exp>;
};


export type Query_RootEnum_Edition_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Email_LanguageArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Language_Order_By>>;
  where?: Maybe<Enum_Email_Language_Bool_Exp>;
};


export type Query_RootEnum_Email_Language_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Language_Order_By>>;
  where?: Maybe<Enum_Email_Language_Bool_Exp>;
};


export type Query_RootEnum_Email_Language_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Email_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Status_Order_By>>;
  where?: Maybe<Enum_Email_Status_Bool_Exp>;
};


export type Query_RootEnum_Email_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Status_Order_By>>;
  where?: Maybe<Enum_Email_Status_Bool_Exp>;
};


export type Query_RootEnum_Email_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Email_Validation_Token_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Validation_Token_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Validation_Token_Status_Order_By>>;
  where?: Maybe<Enum_Email_Validation_Token_Status_Bool_Exp>;
};


export type Query_RootEnum_Email_Validation_Token_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Validation_Token_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Validation_Token_Status_Order_By>>;
  where?: Maybe<Enum_Email_Validation_Token_Status_Bool_Exp>;
};


export type Query_RootEnum_Email_Validation_Token_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_File_Metadata_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_File_Metadata_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_File_Metadata_Status_Order_By>>;
  where?: Maybe<Enum_File_Metadata_Status_Bool_Exp>;
};


export type Query_RootEnum_File_Metadata_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_File_Metadata_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_File_Metadata_Status_Order_By>>;
  where?: Maybe<Enum_File_Metadata_Status_Bool_Exp>;
};


export type Query_RootEnum_File_Metadata_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Flags_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Flags_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Flags_Type_Order_By>>;
  where?: Maybe<Enum_Flags_Type_Bool_Exp>;
};


export type Query_RootEnum_Flags_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Flags_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Flags_Type_Order_By>>;
  where?: Maybe<Enum_Flags_Type_Bool_Exp>;
};


export type Query_RootEnum_Flags_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Forge_Configuration_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Forge_Configuration_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Forge_Configuration_Type_Order_By>>;
  where?: Maybe<Enum_Forge_Configuration_Type_Bool_Exp>;
};


export type Query_RootEnum_Forge_Configuration_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Forge_Configuration_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Forge_Configuration_Type_Order_By>>;
  where?: Maybe<Enum_Forge_Configuration_Type_Bool_Exp>;
};


export type Query_RootEnum_Forge_Configuration_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Query_RootEnum_Forge_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Forge_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Forge_Status_Order_By>>;
  where?: Maybe<Enum_Forge_Status_Bool_Exp>;
};


export type Query_RootEnum_Forge_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Forge_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Forge_Status_Order_By>>;
  where?: Maybe<Enum_Forge_Status_Bool_Exp>;
};


export type Query_RootEnum_Forge_Status_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Query_RootEnum_Infraction_Flag_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Infraction_Flag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Infraction_Flag_Type_Order_By>>;
  where?: Maybe<Enum_Infraction_Flag_Type_Bool_Exp>;
};


export type Query_RootEnum_Infraction_Flag_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Infraction_Flag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Infraction_Flag_Type_Order_By>>;
  where?: Maybe<Enum_Infraction_Flag_Type_Bool_Exp>;
};


export type Query_RootEnum_Infraction_Flag_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Interest_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Interest_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Interest_Type_Order_By>>;
  where?: Maybe<Enum_Interest_Type_Bool_Exp>;
};


export type Query_RootEnum_Interest_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Interest_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Interest_Type_Order_By>>;
  where?: Maybe<Enum_Interest_Type_Bool_Exp>;
};


export type Query_RootEnum_Interest_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Query_RootEnum_Language_PreferenceArgs = {
  distinct_on?: Maybe<Array<Enum_Language_Preference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Language_Preference_Order_By>>;
  where?: Maybe<Enum_Language_Preference_Bool_Exp>;
};


export type Query_RootEnum_Language_Preference_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Language_Preference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Language_Preference_Order_By>>;
  where?: Maybe<Enum_Language_Preference_Bool_Exp>;
};


export type Query_RootEnum_Language_Preference_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_My_Website_Artwork_InfoArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Artwork_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Artwork_Info_Order_By>>;
  where?: Maybe<Enum_My_Website_Artwork_Info_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Artwork_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Artwork_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Artwork_Info_Order_By>>;
  where?: Maybe<Enum_My_Website_Artwork_Info_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Artwork_Info_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_My_Website_FiltersArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Filters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Filters_Order_By>>;
  where?: Maybe<Enum_My_Website_Filters_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Filters_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Filters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Filters_Order_By>>;
  where?: Maybe<Enum_My_Website_Filters_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Filters_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_My_Website_LayoutArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Layout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Layout_Order_By>>;
  where?: Maybe<Enum_My_Website_Layout_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Layout_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Layout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Layout_Order_By>>;
  where?: Maybe<Enum_My_Website_Layout_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Layout_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_My_Website_NavigationArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Navigation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Navigation_Order_By>>;
  where?: Maybe<Enum_My_Website_Navigation_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Navigation_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Navigation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Navigation_Order_By>>;
  where?: Maybe<Enum_My_Website_Navigation_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Navigation_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_My_Website_ThemeArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Theme_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Theme_Order_By>>;
  where?: Maybe<Enum_My_Website_Theme_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Theme_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Theme_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Theme_Order_By>>;
  where?: Maybe<Enum_My_Website_Theme_Bool_Exp>;
};


export type Query_RootEnum_My_Website_Theme_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Notification_ChannelArgs = {
  distinct_on?: Maybe<Array<Enum_Notification_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Notification_Channel_Order_By>>;
  where?: Maybe<Enum_Notification_Channel_Bool_Exp>;
};


export type Query_RootEnum_Notification_Channel_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Notification_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Notification_Channel_Order_By>>;
  where?: Maybe<Enum_Notification_Channel_Bool_Exp>;
};


export type Query_RootEnum_Notification_Channel_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Notification_GroupArgs = {
  distinct_on?: Maybe<Array<Enum_Notification_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Notification_Group_Order_By>>;
  where?: Maybe<Enum_Notification_Group_Bool_Exp>;
};


export type Query_RootEnum_Notification_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Notification_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Notification_Group_Order_By>>;
  where?: Maybe<Enum_Notification_Group_Bool_Exp>;
};


export type Query_RootEnum_Notification_Group_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Position_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Position_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Position_Type_Order_By>>;
  where?: Maybe<Enum_Position_Type_Bool_Exp>;
};


export type Query_RootEnum_Position_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Position_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Position_Type_Order_By>>;
  where?: Maybe<Enum_Position_Type_Bool_Exp>;
};


export type Query_RootEnum_Position_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Price_ScopeArgs = {
  distinct_on?: Maybe<Array<Enum_Price_Scope_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Price_Scope_Order_By>>;
  where?: Maybe<Enum_Price_Scope_Bool_Exp>;
};


export type Query_RootEnum_Price_Scope_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Price_Scope_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Price_Scope_Order_By>>;
  where?: Maybe<Enum_Price_Scope_Bool_Exp>;
};


export type Query_RootEnum_Price_Scope_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Print_TechniqueArgs = {
  distinct_on?: Maybe<Array<Enum_Print_Technique_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Print_Technique_Order_By>>;
  where?: Maybe<Enum_Print_Technique_Bool_Exp>;
};


export type Query_RootEnum_Print_Technique_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Print_Technique_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Print_Technique_Order_By>>;
  where?: Maybe<Enum_Print_Technique_Bool_Exp>;
};


export type Query_RootEnum_Print_Technique_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEnum_Profile_Background_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Background_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Background_Type_Order_By>>;
  where?: Maybe<Enum_Profile_Background_Type_Bool_Exp>;
};


export type Query_RootEnum_Profile_Background_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Background_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Background_Type_Order_By>>;
  where?: Maybe<Enum_Profile_Background_Type_Bool_Exp>;
};


export type Query_RootEnum_Profile_Background_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Profile_Invitation_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Invitation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Invitation_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Invitation_Status_Bool_Exp>;
};


export type Query_RootEnum_Profile_Invitation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Invitation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Invitation_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Invitation_Status_Bool_Exp>;
};


export type Query_RootEnum_Profile_Invitation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Profile_Public_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Public_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Public_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Public_Status_Bool_Exp>;
};


export type Query_RootEnum_Profile_Public_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Public_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Public_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Public_Status_Bool_Exp>;
};


export type Query_RootEnum_Profile_Public_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Profile_RegionArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Region_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Region_Order_By>>;
  where?: Maybe<Enum_Profile_Region_Bool_Exp>;
};


export type Query_RootEnum_Profile_Region_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Region_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Region_Order_By>>;
  where?: Maybe<Enum_Profile_Region_Bool_Exp>;
};


export type Query_RootEnum_Profile_Region_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Profile_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Status_Bool_Exp>;
};


export type Query_RootEnum_Profile_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Status_Bool_Exp>;
};


export type Query_RootEnum_Profile_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Profile_ThemeArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Theme_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Theme_Order_By>>;
  where?: Maybe<Enum_Profile_Theme_Bool_Exp>;
};


export type Query_RootEnum_Profile_Theme_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Theme_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Theme_Order_By>>;
  where?: Maybe<Enum_Profile_Theme_Bool_Exp>;
};


export type Query_RootEnum_Profile_Theme_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Profile_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Type_Order_By>>;
  where?: Maybe<Enum_Profile_Type_Bool_Exp>;
};


export type Query_RootEnum_Profile_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Type_Order_By>>;
  where?: Maybe<Enum_Profile_Type_Bool_Exp>;
};


export type Query_RootEnum_Profile_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Query_RootEnum_Pseudo_User_ActionArgs = {
  distinct_on?: Maybe<Array<Enum_Pseudo_User_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Pseudo_User_Action_Order_By>>;
  where?: Maybe<Enum_Pseudo_User_Action_Bool_Exp>;
};


export type Query_RootEnum_Pseudo_User_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Pseudo_User_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Pseudo_User_Action_Order_By>>;
  where?: Maybe<Enum_Pseudo_User_Action_Bool_Exp>;
};


export type Query_RootEnum_Pseudo_User_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Push_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Push_Notification_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Push_Notification_Status_Order_By>>;
  where?: Maybe<Enum_Push_Notification_Status_Bool_Exp>;
};


export type Query_RootEnum_Push_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Push_Notification_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Push_Notification_Status_Order_By>>;
  where?: Maybe<Enum_Push_Notification_Status_Bool_Exp>;
};


export type Query_RootEnum_Push_Notification_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Push_Notification_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Push_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Push_Notification_Type_Order_By>>;
  where?: Maybe<Enum_Push_Notification_Type_Bool_Exp>;
};


export type Query_RootEnum_Push_Notification_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Push_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Push_Notification_Type_Order_By>>;
  where?: Maybe<Enum_Push_Notification_Type_Bool_Exp>;
};


export type Query_RootEnum_Push_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Questionnaire_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Questionnaire_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Questionnaire_Type_Order_By>>;
  where?: Maybe<Enum_Questionnaire_Type_Bool_Exp>;
};


export type Query_RootEnum_Questionnaire_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Questionnaire_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Questionnaire_Type_Order_By>>;
  where?: Maybe<Enum_Questionnaire_Type_Bool_Exp>;
};


export type Query_RootEnum_Questionnaire_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Render_Request_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Render_Request_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Render_Request_Status_Order_By>>;
  where?: Maybe<Enum_Render_Request_Status_Bool_Exp>;
};


export type Query_RootEnum_Render_Request_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Render_Request_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Render_Request_Status_Order_By>>;
  where?: Maybe<Enum_Render_Request_Status_Bool_Exp>;
};


export type Query_RootEnum_Render_Request_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Render_Request_Template_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Render_Request_Template_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Render_Request_Template_Type_Order_By>>;
  where?: Maybe<Enum_Render_Request_Template_Type_Bool_Exp>;
};


export type Query_RootEnum_Render_Request_Template_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Render_Request_Template_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Render_Request_Template_Type_Order_By>>;
  where?: Maybe<Enum_Render_Request_Template_Type_Bool_Exp>;
};


export type Query_RootEnum_Render_Request_Template_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Restricted_Handle_NamesArgs = {
  distinct_on?: Maybe<Array<Enum_Restricted_Handle_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Restricted_Handle_Names_Order_By>>;
  where?: Maybe<Enum_Restricted_Handle_Names_Bool_Exp>;
};


export type Query_RootEnum_Restricted_Handle_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Restricted_Handle_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Restricted_Handle_Names_Order_By>>;
  where?: Maybe<Enum_Restricted_Handle_Names_Bool_Exp>;
};


export type Query_RootEnum_Restricted_Handle_Names_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_SizeArgs = {
  distinct_on?: Maybe<Array<Enum_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Size_Order_By>>;
  where?: Maybe<Enum_Size_Bool_Exp>;
};


export type Query_RootEnum_Size_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Size_Order_By>>;
  where?: Maybe<Enum_Size_Bool_Exp>;
};


export type Query_RootEnum_Size_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootEnum_Social_Graph_Followers_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Graph_Followers_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Graph_Followers_State_Order_By>>;
  where?: Maybe<Enum_Social_Graph_Followers_State_Bool_Exp>;
};


export type Query_RootEnum_Social_Graph_Followers_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Graph_Followers_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Graph_Followers_State_Order_By>>;
  where?: Maybe<Enum_Social_Graph_Followers_State_Bool_Exp>;
};


export type Query_RootEnum_Social_Graph_Followers_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Social_Link_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Link_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Link_Status_Order_By>>;
  where?: Maybe<Enum_Social_Link_Status_Bool_Exp>;
};


export type Query_RootEnum_Social_Link_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Link_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Link_Status_Order_By>>;
  where?: Maybe<Enum_Social_Link_Status_Bool_Exp>;
};


export type Query_RootEnum_Social_Link_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Social_Link_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Link_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Link_Type_Order_By>>;
  where?: Maybe<Enum_Social_Link_Type_Bool_Exp>;
};


export type Query_RootEnum_Social_Link_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Link_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Link_Type_Order_By>>;
  where?: Maybe<Enum_Social_Link_Type_Bool_Exp>;
};


export type Query_RootEnum_Social_Link_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Timed_Event_NameArgs = {
  distinct_on?: Maybe<Array<Enum_Timed_Event_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Timed_Event_Name_Order_By>>;
  where?: Maybe<Enum_Timed_Event_Name_Bool_Exp>;
};


export type Query_RootEnum_Timed_Event_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Timed_Event_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Timed_Event_Name_Order_By>>;
  where?: Maybe<Enum_Timed_Event_Name_Bool_Exp>;
};


export type Query_RootEnum_Timed_Event_Name_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Token_Storage_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Token_Storage_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Token_Storage_Status_Order_By>>;
  where?: Maybe<Enum_Token_Storage_Status_Bool_Exp>;
};


export type Query_RootEnum_Token_Storage_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Token_Storage_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Token_Storage_Status_Order_By>>;
  where?: Maybe<Enum_Token_Storage_Status_Bool_Exp>;
};


export type Query_RootEnum_Token_Storage_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Transaction_OriginArgs = {
  distinct_on?: Maybe<Array<Enum_Transaction_Origin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Transaction_Origin_Order_By>>;
  where?: Maybe<Enum_Transaction_Origin_Bool_Exp>;
};


export type Query_RootEnum_Transaction_Origin_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Transaction_Origin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Transaction_Origin_Order_By>>;
  where?: Maybe<Enum_Transaction_Origin_Bool_Exp>;
};


export type Query_RootEnum_Transaction_Origin_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Transaction_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Transaction_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Transaction_Status_Order_By>>;
  where?: Maybe<Enum_Transaction_Status_Bool_Exp>;
};


export type Query_RootEnum_Transaction_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Transaction_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Transaction_Status_Order_By>>;
  where?: Maybe<Enum_Transaction_Status_Bool_Exp>;
};


export type Query_RootEnum_Transaction_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Tutorial_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Status_Order_By>>;
  where?: Maybe<Enum_Tutorial_Status_Bool_Exp>;
};


export type Query_RootEnum_Tutorial_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Status_Order_By>>;
  where?: Maybe<Enum_Tutorial_Status_Bool_Exp>;
};


export type Query_RootEnum_Tutorial_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Tutorial_Step_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Step_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Step_Type_Order_By>>;
  where?: Maybe<Enum_Tutorial_Step_Type_Bool_Exp>;
};


export type Query_RootEnum_Tutorial_Step_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Step_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Step_Type_Order_By>>;
  where?: Maybe<Enum_Tutorial_Step_Type_Bool_Exp>;
};


export type Query_RootEnum_Tutorial_Step_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Tutorial_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Type_Order_By>>;
  where?: Maybe<Enum_Tutorial_Type_Bool_Exp>;
};


export type Query_RootEnum_Tutorial_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Type_Order_By>>;
  where?: Maybe<Enum_Tutorial_Type_Bool_Exp>;
};


export type Query_RootEnum_Tutorial_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootEnum_Vumark_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Vumark_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Vumark_Status_Order_By>>;
  where?: Maybe<Enum_Vumark_Status_Bool_Exp>;
};


export type Query_RootEnum_Vumark_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Vumark_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Vumark_Status_Order_By>>;
  where?: Maybe<Enum_Vumark_Status_Bool_Exp>;
};


export type Query_RootEnum_Vumark_Status_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Query_RootEnum_Vumark_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Vumark_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Vumark_Type_Order_By>>;
  where?: Maybe<Enum_Vumark_Type_Bool_Exp>;
};


export type Query_RootEnum_Vumark_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Vumark_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Vumark_Type_Order_By>>;
  where?: Maybe<Enum_Vumark_Type_Bool_Exp>;
};


export type Query_RootEnum_Vumark_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Query_RootEditionArgs = {
  distinct_on?: Maybe<Array<Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Order_By>>;
  where?: Maybe<Edition_Bool_Exp>;
};


export type Query_RootEdition_By_SizeArgs = {
  distinct_on?: Maybe<Array<Edition_By_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Size_Order_By>>;
  where?: Maybe<Edition_By_Size_Bool_Exp>;
};


export type Query_RootEdition_By_Size_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_By_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Size_Order_By>>;
  where?: Maybe<Edition_By_Size_Bool_Exp>;
};


export type Query_RootEdition_By_Size_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEdition_By_TypeArgs = {
  distinct_on?: Maybe<Array<Edition_By_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Type_Order_By>>;
  where?: Maybe<Edition_By_Type_Bool_Exp>;
};


export type Query_RootEdition_By_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_By_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Type_Order_By>>;
  where?: Maybe<Edition_By_Type_Bool_Exp>;
};


export type Query_RootEdition_By_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEdition_TypeArgs = {
  distinct_on?: Maybe<Array<Edition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Type_Order_By>>;
  where?: Maybe<Edition_Type_Bool_Exp>;
};


export type Query_RootEdition_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Type_Order_By>>;
  where?: Maybe<Edition_Type_Bool_Exp>;
};


export type Query_RootEdition_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEdition_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Order_By>>;
  where?: Maybe<Edition_Bool_Exp>;
};


export type Query_RootEdition_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEdition_EventsArgs = {
  distinct_on?: Maybe<Array<Edition_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Events_Order_By>>;
  where?: Maybe<Edition_Events_Bool_Exp>;
};


export type Query_RootEdition_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Events_Order_By>>;
  where?: Maybe<Edition_Events_Bool_Exp>;
};


export type Query_RootEdition_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEmail_Validation_TokenArgs = {
  distinct_on?: Maybe<Array<Email_Validation_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Validation_Token_Order_By>>;
  where?: Maybe<Email_Validation_Token_Bool_Exp>;
};


export type Query_RootEmail_Validation_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Validation_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Validation_Token_Order_By>>;
  where?: Maybe<Email_Validation_Token_Bool_Exp>;
};


export type Query_RootEmail_Validation_Token_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEmailsArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


export type Query_RootEmails_AggregateArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


export type Query_RootEmails_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootExplore_ArtistsArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Order_By>>;
  where?: Maybe<Explore_Artists_Bool_Exp>;
};


export type Query_RootExplore_Artists_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Order_By>>;
  where?: Maybe<Explore_Artists_Bool_Exp>;
};


export type Query_RootExplore_Artists_ArtworksArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Artworks_Order_By>>;
  where?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
};


export type Query_RootExplore_Artists_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Artworks_Order_By>>;
  where?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
};


export type Query_RootExplore_Artists_Curation_ScoreArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Curation_Score_Order_By>>;
  where?: Maybe<Explore_Artists_Curation_Score_Bool_Exp>;
};


export type Query_RootExplore_Artists_Curation_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Curation_Score_Order_By>>;
  where?: Maybe<Explore_Artists_Curation_Score_Bool_Exp>;
};


export type Query_RootExplore_Artists_DisciplinesArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Disciplines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Disciplines_Order_By>>;
  where?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
};


export type Query_RootExplore_Artists_Disciplines_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Disciplines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Disciplines_Order_By>>;
  where?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
};


export type Query_RootFcm_TokenArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


export type Query_RootFcm_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


export type Query_RootFcm_Token_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFeaturesArgs = {
  distinct_on?: Maybe<Array<Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Features_Order_By>>;
  where?: Maybe<Features_Bool_Exp>;
};


export type Query_RootFeatures_AggregateArgs = {
  distinct_on?: Maybe<Array<Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Features_Order_By>>;
  where?: Maybe<Features_Bool_Exp>;
};


export type Query_RootFeatures_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFile_MetadataArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};


export type Query_RootFile_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};


export type Query_RootFile_Metadata_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFirebase_ProfileArgs = {
  distinct_on?: Maybe<Array<Firebase_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Profile_Order_By>>;
  where?: Maybe<Firebase_Profile_Bool_Exp>;
};


export type Query_RootFirebase_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Firebase_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Profile_Order_By>>;
  where?: Maybe<Firebase_Profile_Bool_Exp>;
};


export type Query_RootFirebase_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFlagged_UsersArgs = {
  distinct_on?: Maybe<Array<Flagged_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flagged_Users_Order_By>>;
  where?: Maybe<Flagged_Users_Bool_Exp>;
};


export type Query_RootFlagged_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Flagged_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flagged_Users_Order_By>>;
  where?: Maybe<Flagged_Users_Bool_Exp>;
};


export type Query_RootFlagged_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFlat_Context_AccessArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


export type Query_RootFlat_Context_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


export type Query_RootFollowing_Artists_Art_Universe_ListingArgs = {
  distinct_on?: Maybe<Array<Following_Artists_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Following_Artists_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Following_Artists_Art_Universe_Listing_Bool_Exp>;
};


export type Query_RootFollowing_Artists_Art_Universe_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Following_Artists_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Following_Artists_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Following_Artists_Art_Universe_Listing_Bool_Exp>;
};


export type Query_RootForge_InProcess_TaskArgs = {
  distinct_on?: Maybe<Array<Forge_InProcess_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forge_InProcess_Task_Order_By>>;
  where?: Maybe<Forge_InProcess_Task_Bool_Exp>;
};


export type Query_RootForge_InProcess_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Forge_InProcess_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forge_InProcess_Task_Order_By>>;
  where?: Maybe<Forge_InProcess_Task_Bool_Exp>;
};


export type Query_RootForge_InProcess_Task_By_PkArgs = {
  forge_task_id: Scalars['bigint'];
};


export type Query_RootForge_TasksArgs = {
  distinct_on?: Maybe<Array<Forge_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forge_Tasks_Order_By>>;
  where?: Maybe<Forge_Tasks_Bool_Exp>;
};


export type Query_RootForge_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Forge_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forge_Tasks_Order_By>>;
  where?: Maybe<Forge_Tasks_Bool_Exp>;
};


export type Query_RootForge_Tasks_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGallery_Explore_InfoArgs = {
  distinct_on?: Maybe<Array<Gallery_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_Bool_Exp>;
};


export type Query_RootGallery_Explore_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_Bool_Exp>;
};


export type Query_RootGallery_Explore_Info_With_City_DataArgs = {
  distinct_on?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_With_City_Data_Bool_Exp>;
};


export type Query_RootGallery_Explore_Info_With_City_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_With_City_Data_Bool_Exp>;
};


export type Query_RootGlobal_PropertiesArgs = {
  distinct_on?: Maybe<Array<Global_Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Global_Properties_Order_By>>;
  where?: Maybe<Global_Properties_Bool_Exp>;
};


export type Query_RootGlobal_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Global_Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Global_Properties_Order_By>>;
  where?: Maybe<Global_Properties_Bool_Exp>;
};


export type Query_RootGlobal_Properties_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootGraveyard_ArtworkArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Artwork_DetailsArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Details_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Details_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Details_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Details_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Artwork_Details_TranslationArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Details_Translation_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Details_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Details_Translation_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Details_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Artwork_EventsArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Events_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Events_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Events_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Events_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Artwork_PricesArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Prices_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Prices_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Prices_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Prices_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Prices_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Prices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Artwork_Secondary_MediaArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Secondary_Media_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Secondary_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Secondary_Media_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Secondary_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Artwork_StoryArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Story_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Story_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Story_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Story_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Story_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Story_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Artwork_Story_MediaArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Story_Media_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Story_Media_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Story_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Story_Media_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Story_Media_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Story_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Artwork_To_CollectionArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_To_Collection_Order_By>>;
  where?: Maybe<Graveyard_Artwork_To_Collection_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_To_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_To_Collection_Order_By>>;
  where?: Maybe<Graveyard_Artwork_To_Collection_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_To_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Artwork_TransactionsArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Transactions_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Transactions_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Transactions_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Transactions_Bool_Exp>;
};


export type Query_RootGraveyard_Artwork_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGraveyard_Purchase_InquiresArgs = {
  distinct_on?: Maybe<Array<Graveyard_Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Purchase_Inquires_Order_By>>;
  where?: Maybe<Graveyard_Purchase_Inquires_Bool_Exp>;
};


export type Query_RootGraveyard_Purchase_Inquires_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Purchase_Inquires_Order_By>>;
  where?: Maybe<Graveyard_Purchase_Inquires_Bool_Exp>;
};


export type Query_RootGraveyard_Purchase_Inquires_Outside_UsersArgs = {
  distinct_on?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp>;
};


export type Query_RootGraveyard_Purchase_Inquires_Outside_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp>;
};


export type Query_RootInconsistent_Missing_Domain_Renewal_SubscriptionArgs = {
  distinct_on?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Order_By>>;
  where?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Bool_Exp>;
};


export type Query_RootInconsistent_Missing_Domain_Renewal_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Order_By>>;
  where?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Bool_Exp>;
};


export type Query_RootInterestsArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


export type Query_RootInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


export type Query_RootInterests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootKaleido_General_SettingsArgs = {
  distinct_on?: Maybe<Array<Kaleido_General_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_General_Settings_Order_By>>;
  where?: Maybe<Kaleido_General_Settings_Bool_Exp>;
};


export type Query_RootKaleido_General_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Kaleido_General_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_General_Settings_Order_By>>;
  where?: Maybe<Kaleido_General_Settings_Bool_Exp>;
};


export type Query_RootKaleido_General_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootKaleido_Live_ListingArgs = {
  distinct_on?: Maybe<Array<Kaleido_Live_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Live_Listing_Order_By>>;
  where?: Maybe<Kaleido_Live_Listing_Bool_Exp>;
};


export type Query_RootKaleido_Live_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Kaleido_Live_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Live_Listing_Order_By>>;
  where?: Maybe<Kaleido_Live_Listing_Bool_Exp>;
};


export type Query_RootLanding_Page_ArtworkArgs = {
  distinct_on?: Maybe<Array<Landing_Page_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Landing_Page_Artwork_Order_By>>;
  where?: Maybe<Landing_Page_Artwork_Bool_Exp>;
};


export type Query_RootLanding_Page_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Landing_Page_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Landing_Page_Artwork_Order_By>>;
  where?: Maybe<Landing_Page_Artwork_Bool_Exp>;
};


export type Query_RootLanding_Page_Artwork_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLanguagesArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};


export type Query_RootLanguages_AggregateArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};


export type Query_RootLanguages_By_PkArgs = {
  language_code: Scalars['String'];
};


export type Query_RootMaterialized_ViewArgs = {
  distinct_on?: Maybe<Array<Materialized_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Materialized_View_Order_By>>;
  where?: Maybe<Materialized_View_Bool_Exp>;
};


export type Query_RootMaterialized_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Materialized_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Materialized_View_Order_By>>;
  where?: Maybe<Materialized_View_Bool_Exp>;
};


export type Query_RootMaterialized_View_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootMedia_MetadataArgs = {
  distinct_on?: Maybe<Array<Media_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Metadata_Order_By>>;
  where?: Maybe<Media_Metadata_Bool_Exp>;
};


export type Query_RootMedia_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Metadata_Order_By>>;
  where?: Maybe<Media_Metadata_Bool_Exp>;
};


export type Query_RootMedia_Metadata_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootMilestoneArgs = {
  distinct_on?: Maybe<Array<Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Milestone_Order_By>>;
  where?: Maybe<Milestone_Bool_Exp>;
};


export type Query_RootMilestone_AggregateArgs = {
  distinct_on?: Maybe<Array<Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Milestone_Order_By>>;
  where?: Maybe<Milestone_Bool_Exp>;
};


export type Query_RootMilestone_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootMuseum_Artwork_ListingArgs = {
  distinct_on?: Maybe<Array<Museum_Artwork_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Museum_Artwork_Listing_Order_By>>;
  where?: Maybe<Museum_Artwork_Listing_Bool_Exp>;
};


export type Query_RootMuseum_Artwork_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Museum_Artwork_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Museum_Artwork_Listing_Order_By>>;
  where?: Maybe<Museum_Artwork_Listing_Bool_Exp>;
};


export type Query_RootMyStudio_SubscriptorsArgs = {
  distinct_on?: Maybe<Array<MyStudio_Subscriptors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MyStudio_Subscriptors_Order_By>>;
  where?: Maybe<MyStudio_Subscriptors_Bool_Exp>;
};


export type Query_RootMyStudio_Subscriptors_AggregateArgs = {
  distinct_on?: Maybe<Array<MyStudio_Subscriptors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MyStudio_Subscriptors_Order_By>>;
  where?: Maybe<MyStudio_Subscriptors_Bool_Exp>;
};


export type Query_RootMy_Website_SettingsArgs = {
  distinct_on?: Maybe<Array<My_Website_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Website_Settings_Order_By>>;
  where?: Maybe<My_Website_Settings_Bool_Exp>;
};


export type Query_RootMy_Website_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<My_Website_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Website_Settings_Order_By>>;
  where?: Maybe<My_Website_Settings_Bool_Exp>;
};


export type Query_RootMy_Website_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootMy_Studio_SettingsArgs = {
  distinct_on?: Maybe<Array<My_Studio_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Studio_Settings_Order_By>>;
  where?: Maybe<My_Studio_Settings_Bool_Exp>;
};


export type Query_RootMy_Studio_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<My_Studio_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Studio_Settings_Order_By>>;
  where?: Maybe<My_Studio_Settings_Bool_Exp>;
};


export type Query_RootMy_Studio_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootNotification_PreferenceArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Preference_Order_By>>;
  where?: Maybe<Notification_Preference_Bool_Exp>;
};


export type Query_RootNotification_Preference_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Preference_Order_By>>;
  where?: Maybe<Notification_Preference_Bool_Exp>;
};


export type Query_RootNotification_Preference_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPending_Collaboration_On_ArtworkArgs = {
  distinct_on?: Maybe<Array<Pending_Collaboration_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Collaboration_On_Artwork_Order_By>>;
  where?: Maybe<Pending_Collaboration_On_Artwork_Bool_Exp>;
};


export type Query_RootPending_Collaboration_On_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Pending_Collaboration_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Collaboration_On_Artwork_Order_By>>;
  where?: Maybe<Pending_Collaboration_On_Artwork_Bool_Exp>;
};


export type Query_RootPending_Collaboration_On_Artwork_By_PkArgs = {
  artwork_id: Scalars['bigint'];
  collaboration_id: Scalars['bigint'];
};


export type Query_RootPrice_RangesArgs = {
  distinct_on?: Maybe<Array<Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Ranges_Order_By>>;
  where?: Maybe<Price_Ranges_Bool_Exp>;
};


export type Query_RootPrice_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Ranges_Order_By>>;
  where?: Maybe<Price_Ranges_Bool_Exp>;
};


export type Query_RootPrice_Ranges_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPrintArgs = {
  distinct_on?: Maybe<Array<Print_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Order_By>>;
  where?: Maybe<Print_Bool_Exp>;
};


export type Query_RootPrint_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Order_By>>;
  where?: Maybe<Print_Bool_Exp>;
};


export type Query_RootPrint_AmountsArgs = {
  distinct_on?: Maybe<Array<Print_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Amounts_Order_By>>;
  where?: Maybe<Print_Amounts_Bool_Exp>;
};


export type Query_RootPrint_Amounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Amounts_Order_By>>;
  where?: Maybe<Print_Amounts_Bool_Exp>;
};


export type Query_RootPrint_Artwork_Collector_ListingArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_Collector_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_Collector_Listing_Order_By>>;
  where?: Maybe<Print_Artwork_Collector_Listing_Bool_Exp>;
};


export type Query_RootPrint_Artwork_Collector_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_Collector_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_Collector_Listing_Order_By>>;
  where?: Maybe<Print_Artwork_Collector_Listing_Bool_Exp>;
};


export type Query_RootPrint_Artwork_InfoArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_Info_Order_By>>;
  where?: Maybe<Print_Artwork_Info_Bool_Exp>;
};


export type Query_RootPrint_Artwork_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_Info_Order_By>>;
  where?: Maybe<Print_Artwork_Info_Bool_Exp>;
};


export type Query_RootPrint_Artwork_ViewArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_View_Order_By>>;
  where?: Maybe<Print_Artwork_View_Bool_Exp>;
};


export type Query_RootPrint_Artwork_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_View_Order_By>>;
  where?: Maybe<Print_Artwork_View_Bool_Exp>;
};


export type Query_RootPrint_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPrint_To_CollectionArgs = {
  distinct_on?: Maybe<Array<Print_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_To_Collection_Order_By>>;
  where?: Maybe<Print_To_Collection_Bool_Exp>;
};


export type Query_RootPrint_To_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_To_Collection_Order_By>>;
  where?: Maybe<Print_To_Collection_Bool_Exp>;
};


export type Query_RootPrint_To_Collection_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPrize_Art_ShowArgs = {
  distinct_on?: Maybe<Array<Prize_Art_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Prize_Art_Show_Order_By>>;
  where?: Maybe<Prize_Art_Show_Bool_Exp>;
};


export type Query_RootPrize_Art_Show_AggregateArgs = {
  distinct_on?: Maybe<Array<Prize_Art_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Prize_Art_Show_Order_By>>;
  where?: Maybe<Prize_Art_Show_Bool_Exp>;
};


export type Query_RootPrize_Art_Show_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfileArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_InterestsArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_Order_By>>;
  where?: Maybe<Profile_Interests_Bool_Exp>;
};


export type Query_RootProfile_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_Order_By>>;
  where?: Maybe<Profile_Interests_Bool_Exp>;
};


export type Query_RootProfile_Interests_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_AchievementsArgs = {
  distinct_on?: Maybe<Array<Profile_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Achievements_Order_By>>;
  where?: Maybe<Profile_Achievements_Bool_Exp>;
};


export type Query_RootProfile_Achievements_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Achievements_Order_By>>;
  where?: Maybe<Profile_Achievements_Bool_Exp>;
};


export type Query_RootProfile_Achievements_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


export type Query_RootProfile_Appearance_SettingsArgs = {
  distinct_on?: Maybe<Array<Profile_Appearance_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Appearance_Settings_Order_By>>;
  where?: Maybe<Profile_Appearance_Settings_Bool_Exp>;
};


export type Query_RootProfile_Appearance_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Appearance_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Appearance_Settings_Order_By>>;
  where?: Maybe<Profile_Appearance_Settings_Bool_Exp>;
};


export type Query_RootProfile_Appearance_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_BackgroundArgs = {
  distinct_on?: Maybe<Array<Profile_Background_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Background_Order_By>>;
  where?: Maybe<Profile_Background_Bool_Exp>;
};


export type Query_RootProfile_Background_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Background_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Background_Order_By>>;
  where?: Maybe<Profile_Background_Bool_Exp>;
};


export type Query_RootProfile_Background_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_Ghost_TokensArgs = {
  distinct_on?: Maybe<Array<Profile_Ghost_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Ghost_Tokens_Order_By>>;
  where?: Maybe<Profile_Ghost_Tokens_Bool_Exp>;
};


export type Query_RootProfile_Ghost_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Ghost_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Ghost_Tokens_Order_By>>;
  where?: Maybe<Profile_Ghost_Tokens_Bool_Exp>;
};


export type Query_RootProfile_Ghost_Tokens_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_InvitationsArgs = {
  distinct_on?: Maybe<Array<Profile_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Invitations_Order_By>>;
  where?: Maybe<Profile_Invitations_Bool_Exp>;
};


export type Query_RootProfile_Invitations_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Invitations_Order_By>>;
  where?: Maybe<Profile_Invitations_Bool_Exp>;
};


export type Query_RootProfile_Invitations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_LocationArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Order_By>>;
  where?: Maybe<Profile_Location_Bool_Exp>;
};


export type Query_RootProfile_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Order_By>>;
  where?: Maybe<Profile_Location_Bool_Exp>;
};


export type Query_RootProfile_Location_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_Location_TranslationArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Translation_Order_By>>;
  where?: Maybe<Profile_Location_Translation_Bool_Exp>;
};


export type Query_RootProfile_Location_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Translation_Order_By>>;
  where?: Maybe<Profile_Location_Translation_Bool_Exp>;
};


export type Query_RootProfile_Location_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_Price_RangesArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


export type Query_RootProfile_Price_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


export type Query_RootProfile_Price_Ranges_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_Questions_And_AnswersArgs = {
  distinct_on?: Maybe<Array<Profile_Questions_And_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Questions_And_Answers_Order_By>>;
  where?: Maybe<Profile_Questions_And_Answers_Bool_Exp>;
};


export type Query_RootProfile_Questions_And_Answers_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Questions_And_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Questions_And_Answers_Order_By>>;
  where?: Maybe<Profile_Questions_And_Answers_Bool_Exp>;
};


export type Query_RootProfile_Questions_And_Answers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_SettingsArgs = {
  distinct_on?: Maybe<Array<Profile_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Settings_Order_By>>;
  where?: Maybe<Profile_Settings_Bool_Exp>;
};


export type Query_RootProfile_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Settings_Order_By>>;
  where?: Maybe<Profile_Settings_Bool_Exp>;
};


export type Query_RootProfile_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_Social_LinksArgs = {
  distinct_on?: Maybe<Array<Profile_Social_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Social_Links_Order_By>>;
  where?: Maybe<Profile_Social_Links_Bool_Exp>;
};


export type Query_RootProfile_Social_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Social_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Social_Links_Order_By>>;
  where?: Maybe<Profile_Social_Links_Bool_Exp>;
};


export type Query_RootProfile_Social_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_Statement_Audio_SynthesesArgs = {
  distinct_on?: Maybe<Array<Profile_Statement_Audio_Syntheses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Statement_Audio_Syntheses_Order_By>>;
  where?: Maybe<Profile_Statement_Audio_Syntheses_Bool_Exp>;
};


export type Query_RootProfile_Statement_Audio_Syntheses_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Statement_Audio_Syntheses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Statement_Audio_Syntheses_Order_By>>;
  where?: Maybe<Profile_Statement_Audio_Syntheses_Bool_Exp>;
};


export type Query_RootProfile_Statement_Audio_Syntheses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_TranslationArgs = {
  distinct_on?: Maybe<Array<Profile_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Translation_Order_By>>;
  where?: Maybe<Profile_Translation_Bool_Exp>;
};


export type Query_RootProfile_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Translation_Order_By>>;
  where?: Maybe<Profile_Translation_Bool_Exp>;
};


export type Query_RootProfile_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_Video_LinksArgs = {
  distinct_on?: Maybe<Array<Profile_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Video_Links_Order_By>>;
  where?: Maybe<Profile_Video_Links_Bool_Exp>;
};


export type Query_RootProfile_Video_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Video_Links_Order_By>>;
  where?: Maybe<Profile_Video_Links_Bool_Exp>;
};


export type Query_RootProfile_Video_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootProfile_Wallet_AddressArgs = {
  distinct_on?: Maybe<Array<Profile_Wallet_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Wallet_Address_Order_By>>;
  where?: Maybe<Profile_Wallet_Address_Bool_Exp>;
};


export type Query_RootProfile_Wallet_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Wallet_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Wallet_Address_Order_By>>;
  where?: Maybe<Profile_Wallet_Address_Bool_Exp>;
};


export type Query_RootProfile_Wallet_Address_By_PkArgs = {
  profile_context_id: Scalars['bigint'];
};


export type Query_RootPubnub_ChannelsArgs = {
  distinct_on?: Maybe<Array<Pubnub_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pubnub_Channels_Order_By>>;
  where?: Maybe<Pubnub_Channels_Bool_Exp>;
};


export type Query_RootPubnub_Channels_AggregateArgs = {
  distinct_on?: Maybe<Array<Pubnub_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pubnub_Channels_Order_By>>;
  where?: Maybe<Pubnub_Channels_Bool_Exp>;
};


export type Query_RootPubnub_Channels_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootPurchase_InquiresArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


export type Query_RootPurchase_Inquires_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


export type Query_RootPurchase_Inquires_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPurchase_Inquires_Outside_UsersArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
};


export type Query_RootPurchase_Inquires_Outside_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
};


export type Query_RootPurchase_Inquires_Outside_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPush_NotificationsArgs = {
  distinct_on?: Maybe<Array<Push_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Push_Notifications_Order_By>>;
  where?: Maybe<Push_Notifications_Bool_Exp>;
};


export type Query_RootPush_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Push_Notifications_Order_By>>;
  where?: Maybe<Push_Notifications_Bool_Exp>;
};


export type Query_RootPush_Notifications_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootQuestionnaire_QuestionsArgs = {
  distinct_on?: Maybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questionnaire_Questions_Order_By>>;
  where?: Maybe<Questionnaire_Questions_Bool_Exp>;
};


export type Query_RootQuestionnaire_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questionnaire_Questions_Order_By>>;
  where?: Maybe<Questionnaire_Questions_Bool_Exp>;
};


export type Query_RootQuestionnaire_Questions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootRender_RequestArgs = {
  distinct_on?: Maybe<Array<Render_Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Render_Request_Order_By>>;
  where?: Maybe<Render_Request_Bool_Exp>;
};


export type Query_RootRender_Request_AggregateArgs = {
  distinct_on?: Maybe<Array<Render_Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Render_Request_Order_By>>;
  where?: Maybe<Render_Request_Bool_Exp>;
};


export type Query_RootRender_Request_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSetting_Context_Default_ProfileArgs = {
  distinct_on?: Maybe<Array<Setting_Context_Default_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Context_Default_Profile_Order_By>>;
  where?: Maybe<Setting_Context_Default_Profile_Bool_Exp>;
};


export type Query_RootSetting_Context_Default_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Setting_Context_Default_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Context_Default_Profile_Order_By>>;
  where?: Maybe<Setting_Context_Default_Profile_Bool_Exp>;
};


export type Query_RootSetting_Context_Default_Profile_By_PkArgs = {
  app_access_context_id: Scalars['bigint'];
};


export type Query_RootShowroom_DetailsArgs = {
  distinct_on?: Maybe<Array<Showroom_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Details_Order_By>>;
  where?: Maybe<Showroom_Details_Bool_Exp>;
};


export type Query_RootShowroom_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Details_Order_By>>;
  where?: Maybe<Showroom_Details_Bool_Exp>;
};


export type Query_RootShowroom_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSocial_Graph_Target_Context_ViewArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Target_Context_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Target_Context_View_Order_By>>;
  where?: Maybe<Social_Graph_Target_Context_View_Bool_Exp>;
};


export type Query_RootSocial_Graph_Target_Context_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Target_Context_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Target_Context_View_Order_By>>;
  where?: Maybe<Social_Graph_Target_Context_View_Bool_Exp>;
};


export type Query_RootSocial_Graph_FollowersArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


export type Query_RootSocial_Graph_Followers_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


export type Query_RootSocial_Graph_Followers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSocial_Graph_Followers_ViewArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_View_Order_By>>;
  where?: Maybe<Social_Graph_Followers_View_Bool_Exp>;
};


export type Query_RootSocial_Graph_Followers_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_View_Order_By>>;
  where?: Maybe<Social_Graph_Followers_View_Bool_Exp>;
};


export type Query_RootSpotlight_ArtistArgs = {
  distinct_on?: Maybe<Array<Spotlight_Artist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spotlight_Artist_Order_By>>;
  where?: Maybe<Spotlight_Artist_Bool_Exp>;
};


export type Query_RootSpotlight_Artist_AggregateArgs = {
  distinct_on?: Maybe<Array<Spotlight_Artist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spotlight_Artist_Order_By>>;
  where?: Maybe<Spotlight_Artist_Bool_Exp>;
};


export type Query_RootSpotlight_ContextArgs = {
  distinct_on?: Maybe<Array<Spotlight_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spotlight_Context_Order_By>>;
  where?: Maybe<Spotlight_Context_Bool_Exp>;
};


export type Query_RootSpotlight_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<Spotlight_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spotlight_Context_Order_By>>;
  where?: Maybe<Spotlight_Context_Bool_Exp>;
};


export type Query_RootSpotlight_Context_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSuper_Site_SubscribersArgs = {
  distinct_on?: Maybe<Array<Super_Site_Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Super_Site_Subscribers_Order_By>>;
  where?: Maybe<Super_Site_Subscribers_Bool_Exp>;
};


export type Query_RootSuper_Site_Subscribers_AggregateArgs = {
  distinct_on?: Maybe<Array<Super_Site_Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Super_Site_Subscribers_Order_By>>;
  where?: Maybe<Super_Site_Subscribers_Bool_Exp>;
};


export type Query_RootSuper_Site_Subscribers_By_PkArgs = {
  context_id: Scalars['bigint'];
};


export type Query_RootTags_By_InterestArgs = {
  distinct_on?: Maybe<Array<Tags_By_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_By_Interest_Order_By>>;
  where?: Maybe<Tags_By_Interest_Bool_Exp>;
};


export type Query_RootTags_By_Interest_AggregateArgs = {
  distinct_on?: Maybe<Array<Tags_By_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_By_Interest_Order_By>>;
  where?: Maybe<Tags_By_Interest_Bool_Exp>;
};


export type Query_RootTags_By_Interest_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTemp_Artist_Explore_CollectionArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};


export type Query_RootTemp_Artist_Explore_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};


export type Query_RootTest_Analytics_EventsArgs = {
  distinct_on?: Maybe<Array<Test_Analytics_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Analytics_Events_Order_By>>;
  where?: Maybe<Test_Analytics_Events_Bool_Exp>;
};


export type Query_RootTest_Analytics_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Analytics_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Analytics_Events_Order_By>>;
  where?: Maybe<Test_Analytics_Events_Bool_Exp>;
};


export type Query_RootTest_Analytics_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTest_AccountsArgs = {
  distinct_on?: Maybe<Array<Test_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Accounts_Order_By>>;
  where?: Maybe<Test_Accounts_Bool_Exp>;
};


export type Query_RootTest_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Accounts_Order_By>>;
  where?: Maybe<Test_Accounts_Bool_Exp>;
};


export type Query_RootTest_Accounts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTimed_EventArgs = {
  distinct_on?: Maybe<Array<Timed_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Timed_Event_Order_By>>;
  where?: Maybe<Timed_Event_Bool_Exp>;
};


export type Query_RootTimed_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Timed_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Timed_Event_Order_By>>;
  where?: Maybe<Timed_Event_Bool_Exp>;
};


export type Query_RootTimed_Event_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootToken_StorageArgs = {
  distinct_on?: Maybe<Array<Token_Storage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Token_Storage_Order_By>>;
  where?: Maybe<Token_Storage_Bool_Exp>;
};


export type Query_RootToken_Storage_AggregateArgs = {
  distinct_on?: Maybe<Array<Token_Storage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Token_Storage_Order_By>>;
  where?: Maybe<Token_Storage_Bool_Exp>;
};


export type Query_RootToken_Storage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTutorialArgs = {
  distinct_on?: Maybe<Array<Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Order_By>>;
  where?: Maybe<Tutorial_Bool_Exp>;
};


export type Query_RootTutorial_AggregateArgs = {
  distinct_on?: Maybe<Array<Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Order_By>>;
  where?: Maybe<Tutorial_Bool_Exp>;
};


export type Query_RootTutorial_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTutorial_StepArgs = {
  distinct_on?: Maybe<Array<Tutorial_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Step_Order_By>>;
  where?: Maybe<Tutorial_Step_Bool_Exp>;
};


export type Query_RootTutorial_Step_AggregateArgs = {
  distinct_on?: Maybe<Array<Tutorial_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Step_Order_By>>;
  where?: Maybe<Tutorial_Step_Bool_Exp>;
};


export type Query_RootTutorial_Step_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUser_Analytics_Points_IndexArgs = {
  distinct_on?: Maybe<Array<User_Analytics_Points_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Analytics_Points_Index_Order_By>>;
  where?: Maybe<User_Analytics_Points_Index_Bool_Exp>;
};


export type Query_RootUser_Analytics_Points_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Analytics_Points_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Analytics_Points_Index_Order_By>>;
  where?: Maybe<User_Analytics_Points_Index_Bool_Exp>;
};


export type Query_RootUser_Analytics_Points_Index_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootVr_ArtistsArgs = {
  distinct_on?: Maybe<Array<Vr_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vr_Artists_Order_By>>;
  where?: Maybe<Vr_Artists_Bool_Exp>;
};


export type Query_RootVr_Artists_AggregateArgs = {
  distinct_on?: Maybe<Array<Vr_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vr_Artists_Order_By>>;
  where?: Maybe<Vr_Artists_Bool_Exp>;
};


export type Query_RootVr_Artists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootValid_Profile_Questions_And_Answers_ViewArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Order_By>>;
  where?: Maybe<Valid_Profile_Questions_And_Answers_View_Bool_Exp>;
};


export type Query_RootValid_Profile_Questions_And_Answers_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Order_By>>;
  where?: Maybe<Valid_Profile_Questions_And_Answers_View_Bool_Exp>;
};


export type Query_RootValid_Profile_Statement_Audio_Syntheses_ViewArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Order_By>>;
  where?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>;
};


export type Query_RootValid_Profile_Statement_Audio_Syntheses_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Order_By>>;
  where?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>;
};


export type Query_RootVision_Active_InterestsArgs = {
  distinct_on?: Maybe<Array<Vision_Active_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vision_Active_Interests_Order_By>>;
  where?: Maybe<Vision_Active_Interests_Bool_Exp>;
};


export type Query_RootVision_Active_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<Vision_Active_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vision_Active_Interests_Order_By>>;
  where?: Maybe<Vision_Active_Interests_Bool_Exp>;
};


export type Query_RootVision_InterestArgs = {
  distinct_on?: Maybe<Array<Vision_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vision_Interest_Order_By>>;
  where?: Maybe<Vision_Interest_Bool_Exp>;
};


export type Query_RootVision_Interest_AggregateArgs = {
  distinct_on?: Maybe<Array<Vision_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vision_Interest_Order_By>>;
  where?: Maybe<Vision_Interest_Bool_Exp>;
};


export type Query_RootVision_Interest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootVumarksArgs = {
  distinct_on?: Maybe<Array<Vumarks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vumarks_Order_By>>;
  where?: Maybe<Vumarks_Bool_Exp>;
};


export type Query_RootVumarks_AggregateArgs = {
  distinct_on?: Maybe<Array<Vumarks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vumarks_Order_By>>;
  where?: Maybe<Vumarks_Bool_Exp>;
};


export type Query_RootVumarks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWaiting_ListArgs = {
  distinct_on?: Maybe<Array<Waiting_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Waiting_List_Order_By>>;
  where?: Maybe<Waiting_List_Bool_Exp>;
};


export type Query_RootWaiting_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Waiting_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Waiting_List_Order_By>>;
  where?: Maybe<Waiting_List_Bool_Exp>;
};


export type Query_RootWaiting_List_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWalletsArgs = {
  distinct_on?: Maybe<Array<Wallets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wallets_Order_By>>;
  where?: Maybe<Wallets_Bool_Exp>;
};


export type Query_RootWallets_AggregateArgs = {
  distinct_on?: Maybe<Array<Wallets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wallets_Order_By>>;
  where?: Maybe<Wallets_Bool_Exp>;
};


export type Query_RootWallets_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWorld_CitiesArgs = {
  distinct_on?: Maybe<Array<World_Cities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<World_Cities_Order_By>>;
  where?: Maybe<World_Cities_Bool_Exp>;
};


export type Query_RootWorld_Cities_AggregateArgs = {
  distinct_on?: Maybe<Array<World_Cities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<World_Cities_Order_By>>;
  where?: Maybe<World_Cities_Bool_Exp>;
};


export type Query_RootWorld_Cities_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAggregateProxyArgs = {
  type: ProxyAggregateEnum;
  variables?: Maybe<AggregateProxyVariables>;
};


export type Query_RootAnalyzeMessageContentQueryArgs = {
  contextId: Scalars['Int'];
  message: Scalars['String'];
};


export type Query_RootApp_Notifications_InfoArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Info_Order_By>>;
  where?: Maybe<App_Notifications_Info_Bool_Exp>;
};


export type Query_RootApp_Notifications_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Info_Order_By>>;
  where?: Maybe<App_Notifications_Info_Bool_Exp>;
};


export type Query_RootApp_Notifications_ListingArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Listing_Order_By>>;
  where?: Maybe<App_Notifications_Listing_Bool_Exp>;
};


export type Query_RootApp_Notifications_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Listing_Order_By>>;
  where?: Maybe<App_Notifications_Listing_Bool_Exp>;
};


export type Query_RootApp_Notifications_ViewArgs = {
  distinct_on?: Maybe<Array<App_Notifications_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_View_Order_By>>;
  where?: Maybe<App_Notifications_View_Bool_Exp>;
};


export type Query_RootApp_Notifications_View_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Notifications_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_View_Order_By>>;
  where?: Maybe<App_Notifications_View_Bool_Exp>;
};


export type Query_RootArtist_Cardgrid_Artworks_ViewArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


export type Query_RootArtist_Cardgrid_Artworks_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


export type Query_RootArtworkCertificateImageArgs = {
  artwork_id: Scalars['bigint'];
};


export type Query_RootArtworkPrimaryImageArgs = {
  artwork_id: Scalars['bigint'];
};


export type Query_RootArtworkSecondaryImageArgs = {
  secondary_image_id: Scalars['bigint'];
};


export type Query_RootArtworkStoryAudioArgs = {
  artwork_story_id: Scalars['bigint'];
};


export type Query_RootArtworkStoryImageArgs = {
  story_image_id: Scalars['bigint'];
};


export type Query_RootArtworkThumbnailArgs = {
  artwork_id: Scalars['bigint'];
};


export type Query_RootArtwork_Favorite_CountArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorite_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorite_Count_Order_By>>;
  where?: Maybe<Artwork_Favorite_Count_Bool_Exp>;
};


export type Query_RootArtwork_Favorite_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorite_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorite_Count_Order_By>>;
  where?: Maybe<Artwork_Favorite_Count_Bool_Exp>;
};


export type Query_RootArtwork_Manager_ViewArgs = {
  distinct_on?: Maybe<Array<Artwork_Manager_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Manager_View_Order_By>>;
  where?: Maybe<Artwork_Manager_View_Bool_Exp>;
};


export type Query_RootArtwork_Manager_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Manager_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Manager_View_Order_By>>;
  where?: Maybe<Artwork_Manager_View_Bool_Exp>;
};


export type Query_RootArtwork_Shop_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Shop_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Shop_Info_Order_By>>;
  where?: Maybe<Artwork_Shop_Info_Bool_Exp>;
};


export type Query_RootArtwork_Shop_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Shop_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Shop_Info_Order_By>>;
  where?: Maybe<Artwork_Shop_Info_Bool_Exp>;
};


export type Query_RootArtwork_Shop_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Shop_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Shop_Listing_Order_By>>;
  where?: Maybe<Artwork_Shop_Listing_Bool_Exp>;
};


export type Query_RootArtwork_Shop_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Shop_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Shop_Listing_Order_By>>;
  where?: Maybe<Artwork_Shop_Listing_Bool_Exp>;
};


export type Query_RootBest_Bet_Cities_Exclude_Cities_With_Duplicates_MvArgs = {
  distinct_on?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Order_By>>;
  where?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Bool_Exp>;
};


export type Query_RootBest_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_AggregateArgs = {
  distinct_on?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Order_By>>;
  where?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Bool_Exp>;
};


export type Query_RootBest_Bet_Cities_MvArgs = {
  distinct_on?: Maybe<Array<Best_Bet_Cities_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Best_Bet_Cities_Mv_Order_By>>;
  where?: Maybe<Best_Bet_Cities_Mv_Bool_Exp>;
};


export type Query_RootBest_Bet_Cities_Mv_AggregateArgs = {
  distinct_on?: Maybe<Array<Best_Bet_Cities_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Best_Bet_Cities_Mv_Order_By>>;
  where?: Maybe<Best_Bet_Cities_Mv_Bool_Exp>;
};


export type Query_RootBiweekly_Recap_Artists_InfoArgs = {
  distinct_on?: Maybe<Array<Biweekly_Recap_Artists_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Biweekly_Recap_Artists_Info_Order_By>>;
  where?: Maybe<Biweekly_Recap_Artists_Info_Bool_Exp>;
};


export type Query_RootBiweekly_Recap_Artists_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Biweekly_Recap_Artists_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Biweekly_Recap_Artists_Info_Order_By>>;
  where?: Maybe<Biweekly_Recap_Artists_Info_Bool_Exp>;
};


export type Query_RootCanFollowArgs = {
  context_id: Scalars['Int'];
  target_context_id: Scalars['Int'];
};


export type Query_RootCheckCoAFileArgs = {
  artPieceId: Scalars['bigint'];
};


export type Query_RootCheckUserAuthStateArgs = {
  email: Scalars['String'];
};


export type Query_RootCheckValidationCodeArgs = {
  code: Scalars['String'];
  email: Scalars['String'];
};


export type Query_RootCollectionAudioArgs = {
  collection_id: Scalars['bigint'];
};


export type Query_RootCount_Artists_Approved_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Approved_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Approved_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Approved_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artists_Approved_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Approved_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Approved_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Approved_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artists_Created_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Created_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artists_Created_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Created_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artists_Pending_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Pending_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Pending_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Pending_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artists_Pending_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Pending_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Pending_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Pending_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artists_Rejected_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Rejected_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Rejected_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Rejected_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artists_Rejected_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Rejected_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Rejected_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Rejected_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artworks_Published_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artworks_Published_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artworks_Published_By_Day_Order_By>>;
  where?: Maybe<Count_Artworks_Published_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artworks_Published_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artworks_Published_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artworks_Published_By_Day_Order_By>>;
  where?: Maybe<Count_Artworks_Published_By_Day_Bool_Exp>;
};


export type Query_RootCount_Artworks_Published_This_WeekArgs = {
  distinct_on?: Maybe<Array<Count_Artworks_Published_This_Week_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artworks_Published_This_Week_Order_By>>;
  where?: Maybe<Count_Artworks_Published_This_Week_Bool_Exp>;
};


export type Query_RootCount_Artworks_Published_This_Week_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artworks_Published_This_Week_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artworks_Published_This_Week_Order_By>>;
  where?: Maybe<Count_Artworks_Published_This_Week_Bool_Exp>;
};


export type Query_RootCount_Collectors_Created_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Collectors_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Collectors_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Collectors_Created_By_Day_Bool_Exp>;
};


export type Query_RootCount_Collectors_Created_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Collectors_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Collectors_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Collectors_Created_By_Day_Bool_Exp>;
};


export type Query_RootCount_Galleries_Approved_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Approved_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Approved_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Approved_By_Day_Bool_Exp>;
};


export type Query_RootCount_Galleries_Approved_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Approved_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Approved_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Approved_By_Day_Bool_Exp>;
};


export type Query_RootCount_Galleries_Created_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Created_By_Day_Bool_Exp>;
};


export type Query_RootCount_Galleries_Created_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Created_By_Day_Bool_Exp>;
};


export type Query_RootCount_Galleries_Pending_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Pending_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Pending_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Pending_By_Day_Bool_Exp>;
};


export type Query_RootCount_Galleries_Pending_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Pending_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Pending_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Pending_By_Day_Bool_Exp>;
};


export type Query_RootCount_Galleries_Rejected_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Rejected_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Rejected_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Rejected_By_Day_Bool_Exp>;
};


export type Query_RootCount_Galleries_Rejected_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Rejected_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Rejected_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Rejected_By_Day_Bool_Exp>;
};


export type Query_RootCount_Profile_Visits_Published_BiweeklyArgs = {
  distinct_on?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Order_By>>;
  where?: Maybe<Count_Profile_Visits_Published_Biweekly_Bool_Exp>;
};


export type Query_RootCount_Profile_Visits_Published_Biweekly_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Order_By>>;
  where?: Maybe<Count_Profile_Visits_Published_Biweekly_Bool_Exp>;
};


export type Query_RootCount_Recognitions_Published_BiweeklyArgs = {
  distinct_on?: Maybe<Array<Count_Recognitions_Published_Biweekly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Recognitions_Published_Biweekly_Order_By>>;
  where?: Maybe<Count_Recognitions_Published_Biweekly_Bool_Exp>;
};


export type Query_RootCount_Recognitions_Published_Biweekly_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Recognitions_Published_Biweekly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Recognitions_Published_Biweekly_Order_By>>;
  where?: Maybe<Count_Recognitions_Published_Biweekly_Bool_Exp>;
};


export type Query_RootCountry_States_MvArgs = {
  distinct_on?: Maybe<Array<Country_States_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_States_Mv_Order_By>>;
  where?: Maybe<Country_States_Mv_Bool_Exp>;
};


export type Query_RootCountry_States_Mv_AggregateArgs = {
  distinct_on?: Maybe<Array<Country_States_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_States_Mv_Order_By>>;
  where?: Maybe<Country_States_Mv_Bool_Exp>;
};


export type Query_RootDiscount_Code_ViewArgs = {
  distinct_on?: Maybe<Array<Discount_Code_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_View_Order_By>>;
  where?: Maybe<Discount_Code_View_Bool_Exp>;
};


export type Query_RootDiscount_Code_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Discount_Code_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_View_Order_By>>;
  where?: Maybe<Discount_Code_View_Bool_Exp>;
};


export type Query_RootFlat_Delegate_AccessArgs = {
  distinct_on?: Maybe<Array<Flat_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Delegate_Access_Order_By>>;
  where?: Maybe<Flat_Delegate_Access_Bool_Exp>;
};


export type Query_RootFlat_Delegate_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Flat_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Delegate_Access_Order_By>>;
  where?: Maybe<Flat_Delegate_Access_Bool_Exp>;
};


export type Query_RootFoursquarePlacesSearchArgs = {
  queryParams: PlacesSearchParameters;
};


export type Query_RootGenerateBillingSellerOnboardingUrlArgs = {
  refreshUrl: Scalars['String'];
  returnUrl: Scalars['String'];
};


export type Query_RootGenerateDiscountCodeArgs = {
  code: Scalars['String'];
};


export type Query_RootGetAiArtworkTextStatementArgs = {
  artworkId: Scalars['bigint'];
  processAITags: Scalars['Boolean'];
};


export type Query_RootGetActiveSubscriptionArgs = {
  artistContextId: Scalars['bigint'];
};


export type Query_RootGetAdmirationsQuantityArgs = {
  context_id: Scalars['Int'];
};


export type Query_RootGetAnalyticsKaleidoCardByProfileIdArgs = {
  args: GetAnalyticsKaleidoCardByProfileId_Args;
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Amounts_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
};


export type Query_RootGetAnalyticsKaleidoCardByProfileId_AggregateArgs = {
  args: GetAnalyticsKaleidoCardByProfileId_Args;
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Amounts_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
};


export type Query_RootGetAnonFcmTokenArgs = {
  contextId: Scalars['bigint'];
  deviceId: Scalars['String'];
};


export type Query_RootGetArtPrizeShowWinnersArgs = {
  args: GetArtPrizeShowWinners_Args;
  distinct_on?: Maybe<Array<Art_Prize_Show_Winners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Winners_Order_By>>;
  where?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
};


export type Query_RootGetArtPrizeShowWinners_AggregateArgs = {
  args: GetArtPrizeShowWinners_Args;
  distinct_on?: Maybe<Array<Art_Prize_Show_Winners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Winners_Order_By>>;
  where?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
};


export type Query_RootGetArtistProfileIdWithCollaborationCodeArgs = {
  code: Scalars['String'];
};


export type Query_RootGetArtworkEstimatedShippingPriceArgs = {
  artworkId: Scalars['bigint'];
};


export type Query_RootGetArtworkEventByArtworkIdArgs = {
  artwork_id: Scalars['Int'];
};


export type Query_RootGetBillingAccountDetailsArgs = {
  portalRedirectUrl?: Maybe<Scalars['String']>;
  subscriptionProductTypes?: Maybe<Array<Scalars['String']>>;
};


export type Query_RootGetCartItemsArgs = {
  context_id: Scalars['bigint'];
};


export type Query_RootGetCartItemsByArtworksIdArgs = {
  artworksId: Array<Scalars['bigint']>;
};


export type Query_RootGetCartProductsFromBillingPaymentIntentArgs = {
  cartId: Scalars['bigint'];
};


export type Query_RootGetCollaborationCodeOfProfileArgs = {
  profile_id: Scalars['Int'];
};


export type Query_RootGetCollectorByEmailOrHandleArgs = {
  email?: Maybe<Scalars['String']>;
};


export type Query_RootGetDomainRegistrationPreferencesArgs = {
  artistContextId: Scalars['bigint'];
};


export type Query_RootGetDomainSuggestionsArgs = {
  domainName: Scalars['String'];
};


export type Query_RootGetEditionEventByEditionIdArgs = {
  edition_by_type_id?: Maybe<Scalars['Int']>;
  edition_id: Scalars['Int'];
};


export type Query_RootGetFinalPriceUsingDiscountCodeArgs = {
  contextId?: Maybe<Scalars['bigint']>;
  discountCode?: Maybe<Scalars['String']>;
  originalPrice?: Maybe<Scalars['Float']>;
  purchasedItem?: Maybe<Scalars['String']>;
};


export type Query_RootGetFollowersCountArgs = {
  artist_context_id: Scalars['bigint'];
};


export type Query_RootGetGalleryProfileIdWithCollaborationCodeArgs = {
  code: Scalars['String'];
};


export type Query_RootGetInvitationDataByTokenArgs = {
  token_uuid: Scalars['String'];
};


export type Query_RootGetIsCardExpiringWithinMonthArgs = {
  contextId: Scalars['bigint'];
};


export type Query_RootGetMyFcmTokenArgs = {
  deviceId: Scalars['String'];
};


export type Query_RootGetMyProvenanceArtworksDataQueryArgs = {
  contextId: Scalars['Int'];
};


export type Query_RootGetMyTransactionsPreviewNoJwtArgs = {
  email: Scalars['String'];
};


export type Query_RootGetPayloadByEventByIdArgs = {
  id: Scalars['Int'];
};


export type Query_RootGetProfileSuggestedPrimarySecondaryTagsArgs = {
  contextId: Scalars['bigint'];
};


export type Query_RootGetProrationForSubscriptionUpdateArgs = {
  artistContextId: Scalars['bigint'];
  period: SubscriptionPeriod;
};


export type Query_RootGetPubNubChannelsArgs = {
  contextId: Scalars['bigint'];
};


export type Query_RootGetPubNubTokenArgs = {
  userContextId: Scalars['Int'];
};


export type Query_RootGetSuggestedPrimaryTagsByArtistArgs = {
  args: GetSuggestedPrimaryTagsByArtist_Args;
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Query_RootGetSuggestedPrimaryTagsByArtist_AggregateArgs = {
  args: GetSuggestedPrimaryTagsByArtist_Args;
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Query_RootGetSuggestedSecondaryTagsByArtistArgs = {
  args: GetSuggestedSecondaryTagsByArtist_Args;
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Query_RootGetSuggestedSecondaryTagsByArtist_AggregateArgs = {
  args: GetSuggestedSecondaryTagsByArtist_Args;
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Query_RootGetTextStatementArgs = {
  ignoreExistingStatment?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  profileId: Scalars['String'];
  textGenerationAIModel?: Maybe<Scalars['String']>;
};


export type Query_RootGet_Artists_In_RangeArgs = {
  args: Get_Artists_In_Range_Args;
  distinct_on?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_With_City_Data_Bool_Exp>;
};


export type Query_RootGet_Artists_In_Range_AggregateArgs = {
  args: Get_Artists_In_Range_Args;
  distinct_on?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_With_City_Data_Bool_Exp>;
};


export type Query_RootGet_Galleries_In_RangeArgs = {
  args: Get_Galleries_In_Range_Args;
  distinct_on?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_With_City_Data_Bool_Exp>;
};


export type Query_RootGet_Galleries_In_Range_AggregateArgs = {
  args: Get_Galleries_In_Range_Args;
  distinct_on?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_With_City_Data_Bool_Exp>;
};


export type Query_RootGet_Min_Price_Available_Edition_By_SizeArgs = {
  args: Get_Min_Price_Available_Edition_By_Size_Args;
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Query_RootGet_Min_Price_Available_Edition_By_Size_AggregateArgs = {
  args: Get_Min_Price_Available_Edition_By_Size_Args;
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Query_RootGet_Min_Price_Available_PrintArgs = {
  args: Get_Min_Price_Available_Print_Args;
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Query_RootGet_Min_Price_Available_Print_AggregateArgs = {
  args: Get_Min_Price_Available_Print_Args;
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Query_RootGooglePlacesSearchArgs = {
  queryParams: PlacesSearchParameters;
};


export type Query_RootHelloAccountArgs = {
  firstname: Scalars['String'];
  surname: Scalars['String'];
};


export type Query_RootHelloArtworkArgs = {
  firstname: Scalars['String'];
  surname: Scalars['String'];
};


export type Query_RootHelloGamificationArgs = {
  firstname: Scalars['String'];
  surname: Scalars['String'];
};


export type Query_RootHelloNotificationArgs = {
  firstname: Scalars['String'];
  surname: Scalars['String'];
};


export type Query_RootInquireForPurchaseByOutsideUserActionArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInside_Purchase_Inquiries_ViewArgs = {
  distinct_on?: Maybe<Array<Inside_Purchase_Inquiries_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inside_Purchase_Inquiries_View_Order_By>>;
  where?: Maybe<Inside_Purchase_Inquiries_View_Bool_Exp>;
};


export type Query_RootInside_Purchase_Inquiries_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Inside_Purchase_Inquiries_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inside_Purchase_Inquiries_View_Order_By>>;
  where?: Maybe<Inside_Purchase_Inquiries_View_Bool_Exp>;
};


export type Query_RootKaleido_Coins_DistributionArgs = {
  distinct_on?: Maybe<Array<Kaleido_Coins_Distribution_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Coins_Distribution_Order_By>>;
  where?: Maybe<Kaleido_Coins_Distribution_Bool_Exp>;
};


export type Query_RootKaleido_Coins_Distribution_AggregateArgs = {
  distinct_on?: Maybe<Array<Kaleido_Coins_Distribution_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Coins_Distribution_Order_By>>;
  where?: Maybe<Kaleido_Coins_Distribution_Bool_Exp>;
};


export type Query_RootKaleido_Coins_Weekly_DistributionArgs = {
  distinct_on?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Order_By>>;
  where?: Maybe<Kaleido_Coins_Weekly_Distribution_Bool_Exp>;
};


export type Query_RootKaleido_Coins_Weekly_Distribution_AggregateArgs = {
  distinct_on?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Order_By>>;
  where?: Maybe<Kaleido_Coins_Weekly_Distribution_Bool_Exp>;
};


export type Query_RootOutside_Purchase_Inquiries_ViewArgs = {
  distinct_on?: Maybe<Array<Outside_Purchase_Inquiries_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outside_Purchase_Inquiries_View_Order_By>>;
  where?: Maybe<Outside_Purchase_Inquiries_View_Bool_Exp>;
};


export type Query_RootOutside_Purchase_Inquiries_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Outside_Purchase_Inquiries_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outside_Purchase_Inquiries_View_Order_By>>;
  where?: Maybe<Outside_Purchase_Inquiries_View_Bool_Exp>;
};


export type Query_RootOwner_Artwork_ViewArgs = {
  distinct_on?: Maybe<Array<Owner_Artwork_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Owner_Artwork_View_Order_By>>;
  where?: Maybe<Owner_Artwork_View_Bool_Exp>;
};


export type Query_RootOwner_Artwork_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Owner_Artwork_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Owner_Artwork_View_Order_By>>;
  where?: Maybe<Owner_Artwork_View_Bool_Exp>;
};


export type Query_RootPayment_Captures_ViewArgs = {
  distinct_on?: Maybe<Array<Payment_Captures_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Captures_View_Order_By>>;
  where?: Maybe<Payment_Captures_View_Bool_Exp>;
};


export type Query_RootPayment_Captures_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Captures_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Captures_View_Order_By>>;
  where?: Maybe<Payment_Captures_View_Bool_Exp>;
};


export type Query_RootPending_Forge_Task_ViewArgs = {
  distinct_on?: Maybe<Array<Pending_Forge_Task_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Forge_Task_View_Order_By>>;
  where?: Maybe<Pending_Forge_Task_View_Bool_Exp>;
};


export type Query_RootPending_Forge_Task_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Pending_Forge_Task_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Forge_Task_View_Order_By>>;
  where?: Maybe<Pending_Forge_Task_View_Bool_Exp>;
};


export type Query_RootPrint_AvailabilityArgs = {
  distinct_on?: Maybe<Array<Print_Availability_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Availability_Order_By>>;
  where?: Maybe<Print_Availability_Bool_Exp>;
};


export type Query_RootPrint_Availability_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Availability_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Availability_Order_By>>;
  where?: Maybe<Print_Availability_Bool_Exp>;
};


export type Query_RootProfileAudioArgs = {
  profile_id: Scalars['bigint'];
};


export type Query_RootProfileAvatarArgs = {
  profile_id: Scalars['bigint'];
};


export type Query_RootProfileBannerArgs = {
  profile_id: Scalars['bigint'];
};


export type Query_RootProfileCvArgs = {
  profile_id: Scalars['bigint'];
};


export type Query_RootProfileFeaturedArtArgs = {
  profile_id: Scalars['bigint'];
};


export type Query_RootProfileKardBannerArgs = {
  profile_id: Scalars['bigint'];
};


export type Query_RootProfile_Backgrounds_ViewArgs = {
  distinct_on?: Maybe<Array<Profile_Backgrounds_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Backgrounds_View_Order_By>>;
  where?: Maybe<Profile_Backgrounds_View_Bool_Exp>;
};


export type Query_RootProfile_Backgrounds_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Backgrounds_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Backgrounds_View_Order_By>>;
  where?: Maybe<Profile_Backgrounds_View_Bool_Exp>;
};


export type Query_RootProfile_Interests_ViewArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_View_Order_By>>;
  where?: Maybe<Profile_Interests_View_Bool_Exp>;
};


export type Query_RootProfile_Interests_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_View_Order_By>>;
  where?: Maybe<Profile_Interests_View_Bool_Exp>;
};


export type Query_RootProfile_PhonesArgs = {
  distinct_on?: Maybe<Array<Profile_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Phones_Order_By>>;
  where?: Maybe<Profile_Phones_Bool_Exp>;
};


export type Query_RootProfile_Phones_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Phones_Order_By>>;
  where?: Maybe<Profile_Phones_Bool_Exp>;
};


export type Query_RootProfile_Phones_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootRecognition_Counts_MvArgs = {
  distinct_on?: Maybe<Array<Recognition_Counts_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recognition_Counts_Mv_Order_By>>;
  where?: Maybe<Recognition_Counts_Mv_Bool_Exp>;
};


export type Query_RootRecognition_Counts_Mv_AggregateArgs = {
  distinct_on?: Maybe<Array<Recognition_Counts_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recognition_Counts_Mv_Order_By>>;
  where?: Maybe<Recognition_Counts_Mv_Bool_Exp>;
};


export type Query_RootSendSlackMessageForPurchaseInquiriesArgs = {
  artworkId: Scalars['bigint'];
  senderEmail: Scalars['String'];
  senderLanguage: Scalars['String'];
  senderName: Scalars['String'];
};


export type Query_RootShowroom_Owner_PasswordArgs = {
  distinct_on?: Maybe<Array<Showroom_Owner_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Owner_Password_Order_By>>;
  where?: Maybe<Showroom_Owner_Password_Bool_Exp>;
};


export type Query_RootShowroom_Owner_Password_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Owner_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Owner_Password_Order_By>>;
  where?: Maybe<Showroom_Owner_Password_Bool_Exp>;
};


export type Query_RootShowroom_Password_CheckArgs = {
  distinct_on?: Maybe<Array<Showroom_Password_Check_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Password_Check_Order_By>>;
  where?: Maybe<Showroom_Password_Check_Bool_Exp>;
};


export type Query_RootShowroom_Password_Check_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Password_Check_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Password_Check_Order_By>>;
  where?: Maybe<Showroom_Password_Check_Bool_Exp>;
};


export type Query_RootStripeSubscriptionArgs = {
  stripeSubscriptionId: Scalars['String'];
};


export type Query_RootSum_Coins_From_Card_This_WeekArgs = {
  distinct_on?: Maybe<Array<Sum_Coins_From_Card_This_Week_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sum_Coins_From_Card_This_Week_Order_By>>;
  where?: Maybe<Sum_Coins_From_Card_This_Week_Bool_Exp>;
};


export type Query_RootSum_Coins_From_Card_This_Week_AggregateArgs = {
  distinct_on?: Maybe<Array<Sum_Coins_From_Card_This_Week_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sum_Coins_From_Card_This_Week_Order_By>>;
  where?: Maybe<Sum_Coins_From_Card_This_Week_Bool_Exp>;
};


export type Query_RootVerifyArtworkTransactionBuyerArgs = {
  artwork_id: Scalars['Int'];
  email: Scalars['String'];
};


export type Query_RootVerifyEmailVerificationTokenArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
};


export type Query_RootWorldcitiesArgs = {
  distinct_on?: Maybe<Array<Worldcities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Worldcities_Order_By>>;
  where?: Maybe<Worldcities_Bool_Exp>;
};


export type Query_RootWorldcities_AggregateArgs = {
  distinct_on?: Maybe<Array<Worldcities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Worldcities_Order_By>>;
  where?: Maybe<Worldcities_Bool_Exp>;
};


export type Query_RootWorldcities_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "recognition_counts_mv" */
export type Recognition_Counts_Mv = {
  __typename?: 'recognition_counts_mv';
  context_id?: Maybe<Scalars['bigint']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "recognition_counts_mv" */
export type Recognition_Counts_Mv_Aggregate = {
  __typename?: 'recognition_counts_mv_aggregate';
  aggregate?: Maybe<Recognition_Counts_Mv_Aggregate_Fields>;
  nodes: Array<Recognition_Counts_Mv>;
};

/** aggregate fields of "recognition_counts_mv" */
export type Recognition_Counts_Mv_Aggregate_Fields = {
  __typename?: 'recognition_counts_mv_aggregate_fields';
  avg?: Maybe<Recognition_Counts_Mv_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Recognition_Counts_Mv_Max_Fields>;
  min?: Maybe<Recognition_Counts_Mv_Min_Fields>;
  stddev?: Maybe<Recognition_Counts_Mv_Stddev_Fields>;
  stddev_pop?: Maybe<Recognition_Counts_Mv_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recognition_Counts_Mv_Stddev_Samp_Fields>;
  sum?: Maybe<Recognition_Counts_Mv_Sum_Fields>;
  var_pop?: Maybe<Recognition_Counts_Mv_Var_Pop_Fields>;
  var_samp?: Maybe<Recognition_Counts_Mv_Var_Samp_Fields>;
  variance?: Maybe<Recognition_Counts_Mv_Variance_Fields>;
};


/** aggregate fields of "recognition_counts_mv" */
export type Recognition_Counts_Mv_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recognition_Counts_Mv_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Recognition_Counts_Mv_Avg_Fields = {
  __typename?: 'recognition_counts_mv_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "recognition_counts_mv". All fields are combined with a logical 'AND'. */
export type Recognition_Counts_Mv_Bool_Exp = {
  _and?: Maybe<Array<Recognition_Counts_Mv_Bool_Exp>>;
  _not?: Maybe<Recognition_Counts_Mv_Bool_Exp>;
  _or?: Maybe<Array<Recognition_Counts_Mv_Bool_Exp>>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Recognition_Counts_Mv_Max_Fields = {
  __typename?: 'recognition_counts_mv_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Recognition_Counts_Mv_Min_Fields = {
  __typename?: 'recognition_counts_mv_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "recognition_counts_mv". */
export type Recognition_Counts_Mv_Order_By = {
  context_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
};

/** select columns of table "recognition_counts_mv" */
export enum Recognition_Counts_Mv_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Recognition_Counts_Mv_Stddev_Fields = {
  __typename?: 'recognition_counts_mv_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Recognition_Counts_Mv_Stddev_Pop_Fields = {
  __typename?: 'recognition_counts_mv_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Recognition_Counts_Mv_Stddev_Samp_Fields = {
  __typename?: 'recognition_counts_mv_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Recognition_Counts_Mv_Sum_Fields = {
  __typename?: 'recognition_counts_mv_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Recognition_Counts_Mv_Var_Pop_Fields = {
  __typename?: 'recognition_counts_mv_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Recognition_Counts_Mv_Var_Samp_Fields = {
  __typename?: 'recognition_counts_mv_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Recognition_Counts_Mv_Variance_Fields = {
  __typename?: 'recognition_counts_mv_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "showroom_owner_password" */
export type Showroom_Owner_Password = {
  __typename?: 'showroom_owner_password';
  /** An object relationship */
  collection?: Maybe<Collection>;
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom_details?: Maybe<Showroom_Details>;
};

/** aggregated selection of "showroom_owner_password" */
export type Showroom_Owner_Password_Aggregate = {
  __typename?: 'showroom_owner_password_aggregate';
  aggregate?: Maybe<Showroom_Owner_Password_Aggregate_Fields>;
  nodes: Array<Showroom_Owner_Password>;
};

/** aggregate fields of "showroom_owner_password" */
export type Showroom_Owner_Password_Aggregate_Fields = {
  __typename?: 'showroom_owner_password_aggregate_fields';
  avg?: Maybe<Showroom_Owner_Password_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Showroom_Owner_Password_Max_Fields>;
  min?: Maybe<Showroom_Owner_Password_Min_Fields>;
  stddev?: Maybe<Showroom_Owner_Password_Stddev_Fields>;
  stddev_pop?: Maybe<Showroom_Owner_Password_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Showroom_Owner_Password_Stddev_Samp_Fields>;
  sum?: Maybe<Showroom_Owner_Password_Sum_Fields>;
  var_pop?: Maybe<Showroom_Owner_Password_Var_Pop_Fields>;
  var_samp?: Maybe<Showroom_Owner_Password_Var_Samp_Fields>;
  variance?: Maybe<Showroom_Owner_Password_Variance_Fields>;
};


/** aggregate fields of "showroom_owner_password" */
export type Showroom_Owner_Password_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showroom_Owner_Password_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Showroom_Owner_Password_Avg_Fields = {
  __typename?: 'showroom_owner_password_avg_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "showroom_owner_password". All fields are combined with a logical 'AND'. */
export type Showroom_Owner_Password_Bool_Exp = {
  _and?: Maybe<Array<Showroom_Owner_Password_Bool_Exp>>;
  _not?: Maybe<Showroom_Owner_Password_Bool_Exp>;
  _or?: Maybe<Array<Showroom_Owner_Password_Bool_Exp>>;
  collection?: Maybe<Collection_Bool_Exp>;
  collection_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  showroom_details?: Maybe<Showroom_Details_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "showroom_owner_password" */
export type Showroom_Owner_Password_Inc_Input = {
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "showroom_owner_password" */
export type Showroom_Owner_Password_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>;
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['String']>;
  showroom_details?: Maybe<Showroom_Details_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Showroom_Owner_Password_Max_Fields = {
  __typename?: 'showroom_owner_password_max_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Showroom_Owner_Password_Min_Fields = {
  __typename?: 'showroom_owner_password_min_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "showroom_owner_password" */
export type Showroom_Owner_Password_Mutation_Response = {
  __typename?: 'showroom_owner_password_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Showroom_Owner_Password>;
};

/** input type for inserting object relation for remote table "showroom_owner_password" */
export type Showroom_Owner_Password_Obj_Rel_Insert_Input = {
  data: Showroom_Owner_Password_Insert_Input;
};

/** Ordering options when selecting data from "showroom_owner_password". */
export type Showroom_Owner_Password_Order_By = {
  collection?: Maybe<Collection_Order_By>;
  collection_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  showroom_details?: Maybe<Showroom_Details_Order_By>;
};

/** select columns of table "showroom_owner_password" */
export enum Showroom_Owner_Password_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password'
}

/** input type for updating data in table "showroom_owner_password" */
export type Showroom_Owner_Password_Set_Input = {
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  password?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Showroom_Owner_Password_Stddev_Fields = {
  __typename?: 'showroom_owner_password_stddev_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Showroom_Owner_Password_Stddev_Pop_Fields = {
  __typename?: 'showroom_owner_password_stddev_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Showroom_Owner_Password_Stddev_Samp_Fields = {
  __typename?: 'showroom_owner_password_stddev_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Showroom_Owner_Password_Sum_Fields = {
  __typename?: 'showroom_owner_password_sum_fields';
  collection_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Showroom_Owner_Password_Var_Pop_Fields = {
  __typename?: 'showroom_owner_password_var_pop_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Showroom_Owner_Password_Var_Samp_Fields = {
  __typename?: 'showroom_owner_password_var_samp_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Showroom_Owner_Password_Variance_Fields = {
  __typename?: 'showroom_owner_password_variance_fields';
  collection_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "showroom_password_check" */
export type Showroom_Password_Check = {
  __typename?: 'showroom_password_check';
  /** An object relationship */
  context?: Maybe<Context>;
  context_id?: Maybe<Scalars['bigint']>;
  context_state?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  is_password_protected?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  showroom?: Maybe<Collection>;
  /** An object relationship */
  showroom_details?: Maybe<Showroom_Details>;
  showroom_details_id?: Maybe<Scalars['bigint']>;
  showroom_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregated selection of "showroom_password_check" */
export type Showroom_Password_Check_Aggregate = {
  __typename?: 'showroom_password_check_aggregate';
  aggregate?: Maybe<Showroom_Password_Check_Aggregate_Fields>;
  nodes: Array<Showroom_Password_Check>;
};

/** aggregate fields of "showroom_password_check" */
export type Showroom_Password_Check_Aggregate_Fields = {
  __typename?: 'showroom_password_check_aggregate_fields';
  avg?: Maybe<Showroom_Password_Check_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Showroom_Password_Check_Max_Fields>;
  min?: Maybe<Showroom_Password_Check_Min_Fields>;
  stddev?: Maybe<Showroom_Password_Check_Stddev_Fields>;
  stddev_pop?: Maybe<Showroom_Password_Check_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Showroom_Password_Check_Stddev_Samp_Fields>;
  sum?: Maybe<Showroom_Password_Check_Sum_Fields>;
  var_pop?: Maybe<Showroom_Password_Check_Var_Pop_Fields>;
  var_samp?: Maybe<Showroom_Password_Check_Var_Samp_Fields>;
  variance?: Maybe<Showroom_Password_Check_Variance_Fields>;
};


/** aggregate fields of "showroom_password_check" */
export type Showroom_Password_Check_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Showroom_Password_Check_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Showroom_Password_Check_Avg_Fields = {
  __typename?: 'showroom_password_check_avg_fields';
  context_id?: Maybe<Scalars['Float']>;
  showroom_details_id?: Maybe<Scalars['Float']>;
  showroom_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "showroom_password_check". All fields are combined with a logical 'AND'. */
export type Showroom_Password_Check_Bool_Exp = {
  _and?: Maybe<Array<Showroom_Password_Check_Bool_Exp>>;
  _not?: Maybe<Showroom_Password_Check_Bool_Exp>;
  _or?: Maybe<Array<Showroom_Password_Check_Bool_Exp>>;
  context?: Maybe<Context_Bool_Exp>;
  context_id?: Maybe<Bigint_Comparison_Exp>;
  context_state?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  expiration_date?: Maybe<Timestamptz_Comparison_Exp>;
  is_password_protected?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  showroom?: Maybe<Collection_Bool_Exp>;
  showroom_details?: Maybe<Showroom_Details_Bool_Exp>;
  showroom_details_id?: Maybe<Bigint_Comparison_Exp>;
  showroom_id?: Maybe<Bigint_Comparison_Exp>;
  slug?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Showroom_Password_Check_Max_Fields = {
  __typename?: 'showroom_password_check_max_fields';
  context_id?: Maybe<Scalars['bigint']>;
  context_state?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  showroom_details_id?: Maybe<Scalars['bigint']>;
  showroom_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Showroom_Password_Check_Min_Fields = {
  __typename?: 'showroom_password_check_min_fields';
  context_id?: Maybe<Scalars['bigint']>;
  context_state?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  showroom_details_id?: Maybe<Scalars['bigint']>;
  showroom_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "showroom_password_check". */
export type Showroom_Password_Check_Order_By = {
  context?: Maybe<Context_Order_By>;
  context_id?: Maybe<Order_By>;
  context_state?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  expiration_date?: Maybe<Order_By>;
  is_password_protected?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  showroom?: Maybe<Collection_Order_By>;
  showroom_details?: Maybe<Showroom_Details_Order_By>;
  showroom_details_id?: Maybe<Order_By>;
  showroom_id?: Maybe<Order_By>;
  slug?: Maybe<Order_By>;
};

/** select columns of table "showroom_password_check" */
export enum Showroom_Password_Check_Select_Column {
  /** column name */
  ContextId = 'context_id',
  /** column name */
  ContextState = 'context_state',
  /** column name */
  Description = 'description',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  IsPasswordProtected = 'is_password_protected',
  /** column name */
  Name = 'name',
  /** column name */
  ShowroomDetailsId = 'showroom_details_id',
  /** column name */
  ShowroomId = 'showroom_id',
  /** column name */
  Slug = 'slug'
}

/** aggregate stddev on columns */
export type Showroom_Password_Check_Stddev_Fields = {
  __typename?: 'showroom_password_check_stddev_fields';
  context_id?: Maybe<Scalars['Float']>;
  showroom_details_id?: Maybe<Scalars['Float']>;
  showroom_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Showroom_Password_Check_Stddev_Pop_Fields = {
  __typename?: 'showroom_password_check_stddev_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  showroom_details_id?: Maybe<Scalars['Float']>;
  showroom_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Showroom_Password_Check_Stddev_Samp_Fields = {
  __typename?: 'showroom_password_check_stddev_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  showroom_details_id?: Maybe<Scalars['Float']>;
  showroom_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Showroom_Password_Check_Sum_Fields = {
  __typename?: 'showroom_password_check_sum_fields';
  context_id?: Maybe<Scalars['bigint']>;
  showroom_details_id?: Maybe<Scalars['bigint']>;
  showroom_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Showroom_Password_Check_Var_Pop_Fields = {
  __typename?: 'showroom_password_check_var_pop_fields';
  context_id?: Maybe<Scalars['Float']>;
  showroom_details_id?: Maybe<Scalars['Float']>;
  showroom_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Showroom_Password_Check_Var_Samp_Fields = {
  __typename?: 'showroom_password_check_var_samp_fields';
  context_id?: Maybe<Scalars['Float']>;
  showroom_details_id?: Maybe<Scalars['Float']>;
  showroom_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Showroom_Password_Check_Variance_Fields = {
  __typename?: 'showroom_password_check_variance_fields';
  context_id?: Maybe<Scalars['Float']>;
  showroom_details_id?: Maybe<Scalars['Float']>;
  showroom_id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Account_initial_profile_type" */
  Account_initial_profile_type: Array<Account_Initial_Profile_Type>;
  /** fetch aggregated fields from the table: "Account_initial_profile_type" */
  Account_initial_profile_type_aggregate: Account_Initial_Profile_Type_Aggregate;
  /** fetch data from the table: "Account_initial_profile_type" using primary key columns */
  Account_initial_profile_type_by_pk?: Maybe<Account_Initial_Profile_Type>;
  /** fetch data from the table: "Account_notice_ack" */
  Account_notice_ack: Array<Account_Notice_Ack>;
  /** fetch aggregated fields from the table: "Account_notice_ack" */
  Account_notice_ack_aggregate: Account_Notice_Ack_Aggregate;
  /** fetch data from the table: "Account_notice_ack" using primary key columns */
  Account_notice_ack_by_pk?: Maybe<Account_Notice_Ack>;
  /** fetch data from the table: "Account_validation" */
  Account_validation: Array<Account_Validation>;
  /** fetch aggregated fields from the table: "Account_validation" */
  Account_validation_aggregate: Account_Validation_Aggregate;
  /** fetch data from the table: "Account_validation" using primary key columns */
  Account_validation_by_pk?: Maybe<Account_Validation>;
  /** fetch data from the table: "Achievement_hooks" */
  Achievement_hooks: Array<Achievement_Hooks>;
  /** fetch aggregated fields from the table: "Achievement_hooks" */
  Achievement_hooks_aggregate: Achievement_Hooks_Aggregate;
  /** fetch data from the table: "Achievement_hooks" using primary key columns */
  Achievement_hooks_by_pk?: Maybe<Achievement_Hooks>;
  /** fetch data from the table: "Achievement_tags" */
  Achievement_tags: Array<Achievement_Tags>;
  /** fetch aggregated fields from the table: "Achievement_tags" */
  Achievement_tags_aggregate: Achievement_Tags_Aggregate;
  /** fetch data from the table: "Achievement_tags" using primary key columns */
  Achievement_tags_by_pk?: Maybe<Achievement_Tags>;
  /** fetch data from the table: "Achievements" */
  Achievements: Array<Achievements>;
  /** fetch aggregated fields from the table: "Achievements" */
  Achievements_aggregate: Achievements_Aggregate;
  /** fetch data from the table: "Achievements" using primary key columns */
  Achievements_by_pk?: Maybe<Achievements>;
  /** fetch data from the table: "Admin_password" */
  Admin_password: Array<Admin_Password>;
  /** fetch aggregated fields from the table: "Admin_password" */
  Admin_password_aggregate: Admin_Password_Aggregate;
  /** fetch data from the table: "Admin_password" using primary key columns */
  Admin_password_by_pk?: Maybe<Admin_Password>;
  /** fetch data from the table: "Admin_table_profiles" */
  Admin_table_profiles: Array<Admin_Table_Profiles>;
  /** fetch aggregated fields from the table: "Admin_table_profiles" */
  Admin_table_profiles_aggregate: Admin_Table_Profiles_Aggregate;
  /** fetch data from the table: "Admin_table_profiles_v2" */
  Admin_table_profiles_v2: Array<Admin_Table_Profiles_V2>;
  /** fetch aggregated fields from the table: "Admin_table_profiles_v2" */
  Admin_table_profiles_v2_aggregate: Admin_Table_Profiles_V2_Aggregate;
  /** fetch data from the table: "Admin_to_user_notification" */
  Admin_to_user_notification: Array<Admin_To_User_Notification>;
  /** fetch aggregated fields from the table: "Admin_to_user_notification" */
  Admin_to_user_notification_aggregate: Admin_To_User_Notification_Aggregate;
  /** fetch data from the table: "Admin_to_user_notification" using primary key columns */
  Admin_to_user_notification_by_pk?: Maybe<Admin_To_User_Notification>;
  /** fetch data from the table: "All_artists" */
  All_artists: Array<All_Artists>;
  /** fetch aggregated fields from the table: "All_artists" */
  All_artists_aggregate: All_Artists_Aggregate;
  /** fetch data from the table: "All_artwork_availability" */
  All_artwork_availability: Array<All_Artwork_Availability>;
  /** fetch aggregated fields from the table: "All_artwork_availability" */
  All_artwork_availability_aggregate: All_Artwork_Availability_Aggregate;
  /** fetch data from the table: "All_artwork_availability_table" */
  All_artwork_availability_table: Array<All_Artwork_Availability_Table>;
  /** fetch aggregated fields from the table: "All_artwork_availability_table" */
  All_artwork_availability_table_aggregate: All_Artwork_Availability_Table_Aggregate;
  /** fetch data from the table: "All_artwork_availability_table" using primary key columns */
  All_artwork_availability_table_by_pk?: Maybe<All_Artwork_Availability_Table>;
  /** fetch data from the table: "All_collectors" */
  All_collectors: Array<All_Collectors>;
  /** fetch aggregated fields from the table: "All_collectors" */
  All_collectors_aggregate: All_Collectors_Aggregate;
  /** fetch data from the table: "All_galleries" */
  All_galleries: Array<All_Galleries>;
  /** fetch aggregated fields from the table: "All_galleries" */
  All_galleries_aggregate: All_Galleries_Aggregate;
  /** fetch data from the table: "Analytics_events" */
  Analytics_events: Array<Analytics_Events>;
  /** fetch aggregated fields from the table: "Analytics_events" */
  Analytics_events_aggregate: Analytics_Events_Aggregate;
  /** fetch data from the table: "Analytics_events" using primary key columns */
  Analytics_events_by_pk?: Maybe<Analytics_Events>;
  /** fetch data from the table: "Analytics_index_history" */
  Analytics_index_history: Array<Analytics_Index_History>;
  /** fetch aggregated fields from the table: "Analytics_index_history" */
  Analytics_index_history_aggregate: Analytics_Index_History_Aggregate;
  /** fetch data from the table: "Analytics_index_history" using primary key columns */
  Analytics_index_history_by_pk?: Maybe<Analytics_Index_History>;
  /** fetch data from the table: "Analytics_index_type" */
  Analytics_index_type: Array<Analytics_Index_Type>;
  /** fetch aggregated fields from the table: "Analytics_index_type" */
  Analytics_index_type_aggregate: Analytics_Index_Type_Aggregate;
  /** fetch data from the table: "Analytics_index_type" using primary key columns */
  Analytics_index_type_by_pk?: Maybe<Analytics_Index_Type>;
  /** fetch data from the table: "Analytics_index_values" */
  Analytics_index_values: Array<Analytics_Index_Values>;
  /** fetch aggregated fields from the table: "Analytics_index_values" */
  Analytics_index_values_aggregate: Analytics_Index_Values_Aggregate;
  /** fetch data from the table: "Analytics_index_values" using primary key columns */
  Analytics_index_values_by_pk?: Maybe<Analytics_Index_Values>;
  /** fetch data from the table: "Analytics_kaleidoCard_Amounts" */
  Analytics_kaleidoCard_Amounts: Array<Analytics_KaleidoCard_Amounts>;
  /** fetch aggregated fields from the table: "Analytics_kaleidoCard_Amounts" */
  Analytics_kaleidoCard_Amounts_aggregate: Analytics_KaleidoCard_Amounts_Aggregate;
  /** fetch data from the table: "Analytics_kaleidoCard_Amounts" using primary key columns */
  Analytics_kaleidoCard_Amounts_by_pk?: Maybe<Analytics_KaleidoCard_Amounts>;
  /** An array relationship */
  Analytics_kaleidoCard_events: Array<Analytics_KaleidoCard_Events>;
  /** An aggregate relationship */
  Analytics_kaleidoCard_events_aggregate: Analytics_KaleidoCard_Events_Aggregate;
  /** fetch data from the table: "Analytics_kaleidoCard_events" using primary key columns */
  Analytics_kaleidoCard_events_by_pk?: Maybe<Analytics_KaleidoCard_Events>;
  /** fetch data from the table: "Announced_art_show" */
  Announced_art_show: Array<Announced_Art_Show>;
  /** fetch aggregated fields from the table: "Announced_art_show" */
  Announced_art_show_aggregate: Announced_Art_Show_Aggregate;
  /** fetch data from the table: "App_notifications" */
  App_notifications: Array<App_Notifications>;
  /** fetch aggregated fields from the table: "App_notifications" */
  App_notifications_aggregate: App_Notifications_Aggregate;
  /** fetch data from the table: "App_notifications" using primary key columns */
  App_notifications_by_pk?: Maybe<App_Notifications>;
  /** fetch data from the table: "Art_history_movement" */
  Art_history_movement: Array<Art_History_Movement>;
  /** fetch aggregated fields from the table: "Art_history_movement" */
  Art_history_movement_aggregate: Art_History_Movement_Aggregate;
  /** fetch data from the table: "Art_history_movement" using primary key columns */
  Art_history_movement_by_pk?: Maybe<Art_History_Movement>;
  /** fetch data from the table: "Art_prize_artwork" */
  Art_prize_artwork: Array<Art_Prize_Artwork>;
  /** fetch aggregated fields from the table: "Art_prize_artwork" */
  Art_prize_artwork_aggregate: Art_Prize_Artwork_Aggregate;
  /** fetch data from the table: "Art_prize_artwork" using primary key columns */
  Art_prize_artwork_by_pk?: Maybe<Art_Prize_Artwork>;
  /** fetch data from the table: "Art_prize_show" */
  Art_prize_show: Array<Art_Prize_Show>;
  /** fetch aggregated fields from the table: "Art_prize_show" */
  Art_prize_show_aggregate: Art_Prize_Show_Aggregate;
  /** fetch data from the table: "Art_prize_show" using primary key columns */
  Art_prize_show_by_pk?: Maybe<Art_Prize_Show>;
  /** fetch data from the table: "Art_prize_show_winners" */
  Art_prize_show_winners: Array<Art_Prize_Show_Winners>;
  /** fetch aggregated fields from the table: "Art_prize_show_winners" */
  Art_prize_show_winners_aggregate: Art_Prize_Show_Winners_Aggregate;
  /** fetch data from the table: "Art_prize_show_winners" using primary key columns */
  Art_prize_show_winners_by_pk?: Maybe<Art_Prize_Show_Winners>;
  /** fetch data from the table: "Art_prize_winner" */
  Art_prize_winner: Array<Art_Prize_Winner>;
  /** fetch aggregated fields from the table: "Art_prize_winner" */
  Art_prize_winner_aggregate: Art_Prize_Winner_Aggregate;
  /** fetch data from the table: "Art_prize_winner" using primary key columns */
  Art_prize_winner_by_pk?: Maybe<Art_Prize_Winner>;
  /** fetch data from the table: "Art_prize_winner_ranking" */
  Art_prize_winner_ranking: Array<Art_Prize_Winner_Ranking>;
  /** fetch aggregated fields from the table: "Art_prize_winner_ranking" */
  Art_prize_winner_ranking_aggregate: Art_Prize_Winner_Ranking_Aggregate;
  /** fetch data from the table: "Art_prizes_links_filtered" */
  Art_prizes_links_filtered: Array<Art_Prizes_Links_Filtered>;
  /** fetch aggregated fields from the table: "Art_prizes_links_filtered" */
  Art_prizes_links_filtered_aggregate: Art_Prizes_Links_Filtered_Aggregate;
  /** fetch data from the table: "Art_show_artwork_listing" */
  Art_show_artwork_listing: Array<Art_Show_Artwork_Listing>;
  /** fetch aggregated fields from the table: "Art_show_artwork_listing" */
  Art_show_artwork_listing_aggregate: Art_Show_Artwork_Listing_Aggregate;
  /** fetch data from the table: "Art_show_details" */
  Art_show_details: Array<Art_Show_Details>;
  /** fetch aggregated fields from the table: "Art_show_details" */
  Art_show_details_aggregate: Art_Show_Details_Aggregate;
  /** fetch data from the table: "Art_show_details" using primary key columns */
  Art_show_details_by_pk?: Maybe<Art_Show_Details>;
  /** fetch data from the table: "Art_type_to_Artwork_discipline" */
  Art_type_to_Artwork_discipline: Array<Art_Type_To_Artwork_Discipline>;
  /** fetch aggregated fields from the table: "Art_type_to_Artwork_discipline" */
  Art_type_to_Artwork_discipline_aggregate: Art_Type_To_Artwork_Discipline_Aggregate;
  /** fetch data from the table: "Art_type_to_Artwork_discipline" using primary key columns */
  Art_type_to_Artwork_discipline_by_pk?: Maybe<Art_Type_To_Artwork_Discipline>;
  /** fetch data from the table: "Artist_Collection" */
  Artist_Collection: Array<Artist_Collection>;
  /** fetch data from the table: "Artist_Collection_Categories" */
  Artist_Collection_Categories: Array<Artist_Collection_Categories>;
  /** fetch aggregated fields from the table: "Artist_Collection_Categories" */
  Artist_Collection_Categories_aggregate: Artist_Collection_Categories_Aggregate;
  /** fetch data from the table: "Artist_Collection_Categories" using primary key columns */
  Artist_Collection_Categories_by_pk?: Maybe<Artist_Collection_Categories>;
  /** fetch aggregated fields from the table: "Artist_Collection" */
  Artist_Collection_aggregate: Artist_Collection_Aggregate;
  /** fetch data from the table: "Artist_Collection" using primary key columns */
  Artist_Collection_by_pk?: Maybe<Artist_Collection>;
  /** fetch data from the table: "Artist_Collection_to_Artist_Context" */
  Artist_Collection_to_Artist_Context: Array<Artist_Collection_To_Artist_Context>;
  /** fetch aggregated fields from the table: "Artist_Collection_to_Artist_Context" */
  Artist_Collection_to_Artist_Context_aggregate: Artist_Collection_To_Artist_Context_Aggregate;
  /** fetch data from the table: "Artist_Collection_to_Artist_Context" using primary key columns */
  Artist_Collection_to_Artist_Context_by_pk?: Maybe<Artist_Collection_To_Artist_Context>;
  /** fetch data from the table: "Artist_Vision_Listings_By_Interest" */
  Artist_Vision_Listings_By_Interest: Array<Artist_Vision_Listings_By_Interest>;
  /** fetch aggregated fields from the table: "Artist_Vision_Listings_By_Interest" */
  Artist_Vision_Listings_By_Interest_aggregate: Artist_Vision_Listings_By_Interest_Aggregate;
  /** fetch data from the table: "Artist_art_history_listing" */
  Artist_art_history_listing: Array<Artist_Art_History_Listing>;
  /** fetch aggregated fields from the table: "Artist_art_history_listing" */
  Artist_art_history_listing_aggregate: Artist_Art_History_Listing_Aggregate;
  /** fetch data from the table: "Artist_art_history_listing_by_movement" */
  Artist_art_history_listing_by_movement: Array<Artist_Art_History_Listing_By_Movement>;
  /** fetch aggregated fields from the table: "Artist_art_history_listing_by_movement" */
  Artist_art_history_listing_by_movement_aggregate: Artist_Art_History_Listing_By_Movement_Aggregate;
  /** fetch data from the table: "Artist_art_history_visibility" */
  Artist_art_history_visibility: Array<Artist_Art_History_Visibility>;
  /** fetch aggregated fields from the table: "Artist_art_history_visibility" */
  Artist_art_history_visibility_aggregate: Artist_Art_History_Visibility_Aggregate;
  /** fetch data from the table: "Artist_art_history_visibility" using primary key columns */
  Artist_art_history_visibility_by_pk?: Maybe<Artist_Art_History_Visibility>;
  /** fetch data from the table: "Artist_curation_score" */
  Artist_curation_score: Array<Artist_Curation_Score>;
  /** fetch aggregated fields from the table: "Artist_curation_score" */
  Artist_curation_score_aggregate: Artist_Curation_Score_Aggregate;
  /** fetch data from the table: "Artist_curation_score" using primary key columns */
  Artist_curation_score_by_pk?: Maybe<Artist_Curation_Score>;
  /** fetch data from the table: "Artist_explore_info" */
  Artist_explore_info: Array<Artist_Explore_Info>;
  /** fetch aggregated fields from the table: "Artist_explore_info" */
  Artist_explore_info_aggregate: Artist_Explore_Info_Aggregate;
  /** fetch data from the table: "Artist_explore_interests" */
  Artist_explore_interests: Array<Artist_Explore_Interests>;
  /** fetch aggregated fields from the table: "Artist_explore_interests" */
  Artist_explore_interests_aggregate: Artist_Explore_Interests_Aggregate;
  /** fetch data from the table: "Artist_explore_listing" */
  Artist_explore_listing: Array<Artist_Explore_Listing>;
  /** fetch aggregated fields from the table: "Artist_explore_listing" */
  Artist_explore_listing_aggregate: Artist_Explore_Listing_Aggregate;
  /** fetch data from the table: "Artist_explore_listing_with_city_data" */
  Artist_explore_listing_with_city_data: Array<Artist_Explore_Listing_With_City_Data>;
  /** fetch aggregated fields from the table: "Artist_explore_listing_with_city_data" */
  Artist_explore_listing_with_city_data_aggregate: Artist_Explore_Listing_With_City_Data_Aggregate;
  /** fetch data from the table: "Artist_explore_visibility" */
  Artist_explore_visibility: Array<Artist_Explore_Visibility>;
  /** fetch aggregated fields from the table: "Artist_explore_visibility" */
  Artist_explore_visibility_aggregate: Artist_Explore_Visibility_Aggregate;
  /** fetch data from the table: "Artist_explore_visibility" using primary key columns */
  Artist_explore_visibility_by_pk?: Maybe<Artist_Explore_Visibility>;
  /** fetch data from the table: "Artist_links_filtered" */
  Artist_links_filtered: Array<Artist_Links_Filtered>;
  /** fetch aggregated fields from the table: "Artist_links_filtered" */
  Artist_links_filtered_aggregate: Artist_Links_Filtered_Aggregate;
  /** An array relationship */
  Artist_newsletter: Array<Artist_Newsletter>;
  /** An aggregate relationship */
  Artist_newsletter_aggregate: Artist_Newsletter_Aggregate;
  /** fetch data from the table: "Artist_newsletter" using primary key columns */
  Artist_newsletter_by_pk?: Maybe<Artist_Newsletter>;
  /** fetch data from the table: "Artist_popular_discipline" */
  Artist_popular_discipline: Array<Artist_Popular_Discipline>;
  /** fetch aggregated fields from the table: "Artist_popular_discipline" */
  Artist_popular_discipline_aggregate: Artist_Popular_Discipline_Aggregate;
  /** fetch data from the table: "Artist_preview_artworks" */
  Artist_preview_artworks: Array<Artist_Preview_Artworks>;
  /** fetch aggregated fields from the table: "Artist_preview_artworks" */
  Artist_preview_artworks_aggregate: Artist_Preview_Artworks_Aggregate;
  /** fetch data from the table: "Artist_vision_listing" */
  Artist_vision_listing: Array<Artist_Vision_Listing>;
  /** fetch aggregated fields from the table: "Artist_vision_listing" */
  Artist_vision_listing_aggregate: Artist_Vision_Listing_Aggregate;
  /** fetch data from the table: "Artist_vision_visibility" */
  Artist_vision_visibility: Array<Artist_Vision_Visibility>;
  /** fetch aggregated fields from the table: "Artist_vision_visibility" */
  Artist_vision_visibility_aggregate: Artist_Vision_Visibility_Aggregate;
  /** fetch data from the table: "Artist_vision_visibility" using primary key columns */
  Artist_vision_visibility_by_pk?: Maybe<Artist_Vision_Visibility>;
  /** fetch data from the table: "Artwork" */
  Artwork: Array<Artwork>;
  /** An array relationship */
  Artwork_Art_Universe_Listing: Array<Artwork_Art_Universe_Listing>;
  /** An aggregate relationship */
  Artwork_Art_Universe_Listing_aggregate: Artwork_Art_Universe_Listing_Aggregate;
  /** fetch aggregated fields from the table: "Artwork" */
  Artwork_aggregate: Artwork_Aggregate;
  /** An array relationship */
  Artwork_ai_tags: Array<Artwork_Ai_Tags>;
  /** An aggregate relationship */
  Artwork_ai_tags_aggregate: Artwork_Ai_Tags_Aggregate;
  /** fetch data from the table: "Artwork_ai_tags" using primary key columns */
  Artwork_ai_tags_by_pk?: Maybe<Artwork_Ai_Tags>;
  /** fetch data from the table: "Artwork_art_history_timetable" */
  Artwork_art_history_timetable: Array<Artwork_Art_History_Timetable>;
  /** fetch aggregated fields from the table: "Artwork_art_history_timetable" */
  Artwork_art_history_timetable_aggregate: Artwork_Art_History_Timetable_Aggregate;
  /** fetch data from the table: "Artwork_art_history_timetable" using primary key columns */
  Artwork_art_history_timetable_by_pk?: Maybe<Artwork_Art_History_Timetable>;
  /** fetch data from the table: "Artwork_artist_art_history_listing" */
  Artwork_artist_art_history_listing: Array<Artwork_Artist_Art_History_Listing>;
  /** fetch aggregated fields from the table: "Artwork_artist_art_history_listing" */
  Artwork_artist_art_history_listing_aggregate: Artwork_Artist_Art_History_Listing_Aggregate;
  /** fetch data from the table: "Artwork_artist_collection_type_listing" */
  Artwork_artist_collection_type_listing: Array<Artwork_Artist_Collection_Type_Listing>;
  /** fetch aggregated fields from the table: "Artwork_artist_collection_type_listing" */
  Artwork_artist_collection_type_listing_aggregate: Artwork_Artist_Collection_Type_Listing_Aggregate;
  /** fetch data from the table: "Artwork_artist_profile_info" */
  Artwork_artist_profile_info: Array<Artwork_Artist_Profile_Info>;
  /** fetch aggregated fields from the table: "Artwork_artist_profile_info" */
  Artwork_artist_profile_info_aggregate: Artwork_Artist_Profile_Info_Aggregate;
  /** fetch data from the table: "Artwork_artist_profile_listing" */
  Artwork_artist_profile_listing: Array<Artwork_Artist_Profile_Listing>;
  /** fetch aggregated fields from the table: "Artwork_artist_profile_listing" */
  Artwork_artist_profile_listing_aggregate: Artwork_Artist_Profile_Listing_Aggregate;
  /** fetch data from the table: "Artwork_artist_vision_listing" */
  Artwork_artist_vision_listing: Array<Artwork_Artist_Vision_Listing>;
  /** fetch aggregated fields from the table: "Artwork_artist_vision_listing" */
  Artwork_artist_vision_listing_aggregate: Artwork_Artist_Vision_Listing_Aggregate;
  /** fetch data from the table: "Artwork_aspect_ratio" */
  Artwork_aspect_ratio: Array<Artwork_Aspect_Ratio>;
  /** fetch aggregated fields from the table: "Artwork_aspect_ratio" */
  Artwork_aspect_ratio_aggregate: Artwork_Aspect_Ratio_Aggregate;
  /** fetch data from the table: "Artwork_aspect_ratio" using primary key columns */
  Artwork_aspect_ratio_by_pk?: Maybe<Artwork_Aspect_Ratio>;
  /** fetch data from the table: "Artwork" using primary key columns */
  Artwork_by_pk?: Maybe<Artwork>;
  /** fetch data from the table: "Artwork_collected_listing" */
  Artwork_collected_listing: Array<Artwork_Collected_Listing>;
  /** fetch aggregated fields from the table: "Artwork_collected_listing" */
  Artwork_collected_listing_aggregate: Artwork_Collected_Listing_Aggregate;
  /** fetch data from the table: "Artwork_count_milestones" */
  Artwork_count_milestones: Array<Artwork_Count_Milestones>;
  /** fetch aggregated fields from the table: "Artwork_count_milestones" */
  Artwork_count_milestones_aggregate: Artwork_Count_Milestones_Aggregate;
  /** fetch data from the table: "Artwork_count_milestones" using primary key columns */
  Artwork_count_milestones_by_pk?: Maybe<Artwork_Count_Milestones>;
  /** fetch data from the table: "Artwork_curation_score" */
  Artwork_curation_score: Array<Artwork_Curation_Score>;
  /** fetch aggregated fields from the table: "Artwork_curation_score" */
  Artwork_curation_score_aggregate: Artwork_Curation_Score_Aggregate;
  /** fetch data from the table: "Artwork_curation_score" using primary key columns */
  Artwork_curation_score_by_pk?: Maybe<Artwork_Curation_Score>;
  /** fetch data from the table: "Artwork_details" */
  Artwork_details: Array<Artwork_Details>;
  /** fetch aggregated fields from the table: "Artwork_details" */
  Artwork_details_aggregate: Artwork_Details_Aggregate;
  /** fetch data from the table: "Artwork_details" using primary key columns */
  Artwork_details_by_pk?: Maybe<Artwork_Details>;
  /** fetch data from the table: "Artwork_details_translation" */
  Artwork_details_translation: Array<Artwork_Details_Translation>;
  /** fetch aggregated fields from the table: "Artwork_details_translation" */
  Artwork_details_translation_aggregate: Artwork_Details_Translation_Aggregate;
  /** fetch data from the table: "Artwork_details_translation" using primary key columns */
  Artwork_details_translation_by_pk?: Maybe<Artwork_Details_Translation>;
  /** fetch data from the table: "Artwork_digital_twin" */
  Artwork_digital_twin: Array<Artwork_Digital_Twin>;
  /** fetch aggregated fields from the table: "Artwork_digital_twin" */
  Artwork_digital_twin_aggregate: Artwork_Digital_Twin_Aggregate;
  /** fetch data from the table: "Artwork_digital_twin" using primary key columns */
  Artwork_digital_twin_by_pk?: Maybe<Artwork_Digital_Twin>;
  /** fetch data from the table: "Artwork_documents_info" */
  Artwork_documents_info: Array<Artwork_Documents_Info>;
  /** fetch aggregated fields from the table: "Artwork_documents_info" */
  Artwork_documents_info_aggregate: Artwork_Documents_Info_Aggregate;
  /** An array relationship */
  Artwork_events: Array<Artwork_Events>;
  /** An aggregate relationship */
  Artwork_events_aggregate: Artwork_Events_Aggregate;
  /** fetch data from the table: "Artwork_events" using primary key columns */
  Artwork_events_by_pk?: Maybe<Artwork_Events>;
  /** fetch data from the table: "Artwork_explore_info" */
  Artwork_explore_info: Array<Artwork_Explore_Info>;
  /** fetch aggregated fields from the table: "Artwork_explore_info" */
  Artwork_explore_info_aggregate: Artwork_Explore_Info_Aggregate;
  /** fetch data from the table: "Artwork_explore_listing" */
  Artwork_explore_listing: Array<Artwork_Explore_Listing>;
  /** fetch aggregated fields from the table: "Artwork_explore_listing" */
  Artwork_explore_listing_aggregate: Artwork_Explore_Listing_Aggregate;
  /** fetch data from the table: "Artwork_external_buy_link" */
  Artwork_external_buy_link: Array<Artwork_External_Buy_Link>;
  /** fetch aggregated fields from the table: "Artwork_external_buy_link" */
  Artwork_external_buy_link_aggregate: Artwork_External_Buy_Link_Aggregate;
  /** fetch data from the table: "Artwork_external_buy_link" using primary key columns */
  Artwork_external_buy_link_by_pk?: Maybe<Artwork_External_Buy_Link>;
  /** fetch data from the table: "Artwork_favorite_listing" */
  Artwork_favorite_listing: Array<Artwork_Favorite_Listing>;
  /** fetch aggregated fields from the table: "Artwork_favorite_listing" */
  Artwork_favorite_listing_aggregate: Artwork_Favorite_Listing_Aggregate;
  /** fetch data from the table: "Artwork_favorited_by_listing" */
  Artwork_favorited_by_listing: Array<Artwork_Favorited_By_Listing>;
  /** fetch aggregated fields from the table: "Artwork_favorited_by_listing" */
  Artwork_favorited_by_listing_aggregate: Artwork_Favorited_By_Listing_Aggregate;
  /** fetch data from the table: "Artwork_favorites_art_universe_listing" */
  Artwork_favorites_art_universe_listing: Array<Artwork_Favorites_Art_Universe_Listing>;
  /** fetch aggregated fields from the table: "Artwork_favorites_art_universe_listing" */
  Artwork_favorites_art_universe_listing_aggregate: Artwork_Favorites_Art_Universe_Listing_Aggregate;
  /** fetch data from the table: "Artwork_last_print_transaction" */
  Artwork_last_print_transaction: Array<Artwork_Last_Print_Transaction>;
  /** fetch aggregated fields from the table: "Artwork_last_print_transaction" */
  Artwork_last_print_transaction_aggregate: Artwork_Last_Print_Transaction_Aggregate;
  /** fetch data from the table: "Artwork_last_transaction" */
  Artwork_last_transaction: Array<Artwork_Last_Transaction>;
  /** fetch aggregated fields from the table: "Artwork_last_transaction" */
  Artwork_last_transaction_aggregate: Artwork_Last_Transaction_Aggregate;
  /** fetch data from the table: "Artwork_links" */
  Artwork_links: Array<Artwork_Links>;
  /** fetch aggregated fields from the table: "Artwork_links" */
  Artwork_links_aggregate: Artwork_Links_Aggregate;
  /** An array relationship */
  Artwork_prices: Array<Artwork_Prices>;
  /** An aggregate relationship */
  Artwork_prices_aggregate: Artwork_Prices_Aggregate;
  /** fetch data from the table: "Artwork_prices" using primary key columns */
  Artwork_prices_by_pk?: Maybe<Artwork_Prices>;
  /** fetch data from the table: "Artwork_prize_info" */
  Artwork_prize_info: Array<Artwork_Prize_Info>;
  /** fetch aggregated fields from the table: "Artwork_prize_info" */
  Artwork_prize_info_aggregate: Artwork_Prize_Info_Aggregate;
  /** fetch data from the table: "Artwork_prize_listing" */
  Artwork_prize_listing: Array<Artwork_Prize_Listing>;
  /** fetch aggregated fields from the table: "Artwork_prize_listing" */
  Artwork_prize_listing_aggregate: Artwork_Prize_Listing_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey" */
  Artwork_purchase_journey: Array<Artwork_Purchase_Journey>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey" */
  Artwork_purchase_journey_aggregate: Artwork_Purchase_Journey_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey" using primary key columns */
  Artwork_purchase_journey_by_pk?: Maybe<Artwork_Purchase_Journey>;
  /** fetch data from the table: "Artwork_purchase_journey_history" */
  Artwork_purchase_journey_history: Array<Artwork_Purchase_Journey_History>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey_history" */
  Artwork_purchase_journey_history_aggregate: Artwork_Purchase_Journey_History_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey_history" using primary key columns */
  Artwork_purchase_journey_history_by_pk?: Maybe<Artwork_Purchase_Journey_History>;
  /** fetch data from the table: "Artwork_purchase_journey_last_update" */
  Artwork_purchase_journey_last_update: Array<Artwork_Purchase_Journey_Last_Update>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey_last_update" */
  Artwork_purchase_journey_last_update_aggregate: Artwork_Purchase_Journey_Last_Update_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey_last_update" using primary key columns */
  Artwork_purchase_journey_last_update_by_pk?: Maybe<Artwork_Purchase_Journey_Last_Update>;
  /** fetch data from the table: "Artwork_purchase_journey_pricing" */
  Artwork_purchase_journey_pricing: Array<Artwork_Purchase_Journey_Pricing>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey_pricing" */
  Artwork_purchase_journey_pricing_aggregate: Artwork_Purchase_Journey_Pricing_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey_pricing" using primary key columns */
  Artwork_purchase_journey_pricing_by_pk?: Maybe<Artwork_Purchase_Journey_Pricing>;
  /** fetch data from the table: "Artwork_purchase_journey_shipping_info" */
  Artwork_purchase_journey_shipping_info: Array<Artwork_Purchase_Journey_Shipping_Info>;
  /** fetch aggregated fields from the table: "Artwork_purchase_journey_shipping_info" */
  Artwork_purchase_journey_shipping_info_aggregate: Artwork_Purchase_Journey_Shipping_Info_Aggregate;
  /** fetch data from the table: "Artwork_purchase_journey_shipping_info" using primary key columns */
  Artwork_purchase_journey_shipping_info_by_pk?: Maybe<Artwork_Purchase_Journey_Shipping_Info>;
  /** An array relationship */
  Artwork_secondary_media: Array<Artwork_Secondary_Media>;
  /** An aggregate relationship */
  Artwork_secondary_media_aggregate: Artwork_Secondary_Media_Aggregate;
  /** fetch data from the table: "Artwork_secondary_media" using primary key columns */
  Artwork_secondary_media_by_pk?: Maybe<Artwork_Secondary_Media>;
  /** fetch data from the table: "Artwork_showcase_filtered" */
  Artwork_showcase_filtered: Array<Artwork_Showcase_Filtered>;
  /** fetch aggregated fields from the table: "Artwork_showcase_filtered" */
  Artwork_showcase_filtered_aggregate: Artwork_Showcase_Filtered_Aggregate;
  /** fetch data from the table: "Artwork_state_history" */
  Artwork_state_history: Array<Artwork_State_History>;
  /** fetch aggregated fields from the table: "Artwork_state_history" */
  Artwork_state_history_aggregate: Artwork_State_History_Aggregate;
  /** fetch data from the table: "Artwork_state_history" using primary key columns */
  Artwork_state_history_by_pk?: Maybe<Artwork_State_History>;
  /** fetch data from the table: "Artwork_story" */
  Artwork_story: Array<Artwork_Story>;
  /** fetch aggregated fields from the table: "Artwork_story" */
  Artwork_story_aggregate: Artwork_Story_Aggregate;
  /** fetch data from the table: "Artwork_story" using primary key columns */
  Artwork_story_by_pk?: Maybe<Artwork_Story>;
  /** fetch data from the table: "Artwork_story_media" */
  Artwork_story_media: Array<Artwork_Story_Media>;
  /** fetch aggregated fields from the table: "Artwork_story_media" */
  Artwork_story_media_aggregate: Artwork_Story_Media_Aggregate;
  /** fetch data from the table: "Artwork_story_media" using primary key columns */
  Artwork_story_media_by_pk?: Maybe<Artwork_Story_Media>;
  /** fetch data from the table: "Artwork_story_video_links" */
  Artwork_story_video_links: Array<Artwork_Story_Video_Links>;
  /** fetch aggregated fields from the table: "Artwork_story_video_links" */
  Artwork_story_video_links_aggregate: Artwork_Story_Video_Links_Aggregate;
  /** fetch data from the table: "Artwork_story_video_links" using primary key columns */
  Artwork_story_video_links_by_pk?: Maybe<Artwork_Story_Video_Links>;
  /** fetch data from the table: "Artwork_tag" */
  Artwork_tag: Array<Artwork_Tag>;
  /** fetch aggregated fields from the table: "Artwork_tag" */
  Artwork_tag_aggregate: Artwork_Tag_Aggregate;
  /** fetch data from the table: "Artwork_tag" using primary key columns */
  Artwork_tag_by_pk?: Maybe<Artwork_Tag>;
  /** fetch data from the table: "Artwork_to_Collection" */
  Artwork_to_Collection: Array<Artwork_To_Collection>;
  /** fetch aggregated fields from the table: "Artwork_to_Collection" */
  Artwork_to_Collection_aggregate: Artwork_To_Collection_Aggregate;
  /** fetch data from the table: "Artwork_to_Collection" using primary key columns */
  Artwork_to_Collection_by_pk?: Maybe<Artwork_To_Collection>;
  /** fetch data from the table: "Artwork_to_showroom_details" */
  Artwork_to_showroom_details: Array<Artwork_To_Showroom_Details>;
  /** fetch aggregated fields from the table: "Artwork_to_showroom_details" */
  Artwork_to_showroom_details_aggregate: Artwork_To_Showroom_Details_Aggregate;
  /** fetch data from the table: "Artwork_to_showroom_details" using primary key columns */
  Artwork_to_showroom_details_by_pk?: Maybe<Artwork_To_Showroom_Details>;
  /** fetch data from the table: "Artwork_to_tag" */
  Artwork_to_tag: Array<Artwork_To_Tag>;
  /** fetch aggregated fields from the table: "Artwork_to_tag" */
  Artwork_to_tag_aggregate: Artwork_To_Tag_Aggregate;
  /** fetch data from the table: "Artwork_to_tag" using primary key columns */
  Artwork_to_tag_by_pk?: Maybe<Artwork_To_Tag>;
  /** fetch data from the table: "Artwork_transactions" */
  Artwork_transactions: Array<Artwork_Transactions>;
  /** fetch aggregated fields from the table: "Artwork_transactions" */
  Artwork_transactions_aggregate: Artwork_Transactions_Aggregate;
  /** fetch data from the table: "Artwork_transactions" using primary key columns */
  Artwork_transactions_by_pk?: Maybe<Artwork_Transactions>;
  /** fetch data from the table: "Auth" */
  Auth: Array<Auth>;
  /** fetch aggregated fields from the table: "Auth" */
  Auth_aggregate: Auth_Aggregate;
  /** fetch data from the table: "Auth" using primary key columns */
  Auth_by_pk?: Maybe<Auth>;
  /** fetch data from the table: "Auth_context_access" */
  Auth_context_access: Array<Auth_Context_Access>;
  /** fetch aggregated fields from the table: "Auth_context_access" */
  Auth_context_access_aggregate: Auth_Context_Access_Aggregate;
  /** fetch data from the table: "Auth_context_access" using primary key columns */
  Auth_context_access_by_pk?: Maybe<Auth_Context_Access>;
  /** fetch data from the table: "Available_print_price" */
  Available_print_price: Array<Available_Print_Price>;
  /** fetch aggregated fields from the table: "Available_print_price" */
  Available_print_price_aggregate: Available_Print_Price_Aggregate;
  /** fetch data from the table: "Available_print_price" using primary key columns */
  Available_print_price_by_pk?: Maybe<Available_Print_Price>;
  /** fetch data from the table: "Available_print_price_view" */
  Available_print_price_view: Array<Available_Print_Price_View>;
  /** fetch aggregated fields from the table: "Available_print_price_view" */
  Available_print_price_view_aggregate: Available_Print_Price_View_Aggregate;
  /** fetch data from the table: "Billing_anon_client" */
  Billing_anon_client: Array<Billing_Anon_Client>;
  /** fetch aggregated fields from the table: "Billing_anon_client" */
  Billing_anon_client_aggregate: Billing_Anon_Client_Aggregate;
  /** fetch data from the table: "Billing_anon_client" using primary key columns */
  Billing_anon_client_by_pk?: Maybe<Billing_Anon_Client>;
  /** fetch data from the table: "Billing_artwork_payment_intent" */
  Billing_artwork_payment_intent: Array<Billing_Artwork_Payment_Intent>;
  /** fetch aggregated fields from the table: "Billing_artwork_payment_intent" */
  Billing_artwork_payment_intent_aggregate: Billing_Artwork_Payment_Intent_Aggregate;
  /** fetch data from the table: "Billing_artwork_payment_intent" using primary key columns */
  Billing_artwork_payment_intent_by_pk?: Maybe<Billing_Artwork_Payment_Intent>;
  /** fetch data from the table: "Billing_artwork_payment_intent_product" */
  Billing_artwork_payment_intent_product: Array<Billing_Artwork_Payment_Intent_Product>;
  /** fetch aggregated fields from the table: "Billing_artwork_payment_intent_product" */
  Billing_artwork_payment_intent_product_aggregate: Billing_Artwork_Payment_Intent_Product_Aggregate;
  /** fetch data from the table: "Billing_artwork_payment_intent_product" using primary key columns */
  Billing_artwork_payment_intent_product_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Product>;
  /** fetch data from the table: "Billing_artwork_payment_intent_shipping_address" */
  Billing_artwork_payment_intent_shipping_address: Array<Billing_Artwork_Payment_Intent_Shipping_Address>;
  /** fetch aggregated fields from the table: "Billing_artwork_payment_intent_shipping_address" */
  Billing_artwork_payment_intent_shipping_address_aggregate: Billing_Artwork_Payment_Intent_Shipping_Address_Aggregate;
  /** fetch data from the table: "Billing_artwork_payment_intent_shipping_address" using primary key columns */
  Billing_artwork_payment_intent_shipping_address_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address>;
  /** fetch data from the table: "Billing_artwork_payment_intent_status_history" */
  Billing_artwork_payment_intent_status_history: Array<Billing_Artwork_Payment_Intent_Status_History>;
  /** fetch aggregated fields from the table: "Billing_artwork_payment_intent_status_history" */
  Billing_artwork_payment_intent_status_history_aggregate: Billing_Artwork_Payment_Intent_Status_History_Aggregate;
  /** fetch data from the table: "Billing_artwork_payment_intent_status_history" using primary key columns */
  Billing_artwork_payment_intent_status_history_by_pk?: Maybe<Billing_Artwork_Payment_Intent_Status_History>;
  /** fetch data from the table: "Billing_client" */
  Billing_client: Array<Billing_Client>;
  /** fetch aggregated fields from the table: "Billing_client" */
  Billing_client_aggregate: Billing_Client_Aggregate;
  /** fetch data from the table: "Billing_client" using primary key columns */
  Billing_client_by_pk?: Maybe<Billing_Client>;
  /** fetch data from the table: "Billing_seller" */
  Billing_seller: Array<Billing_Seller>;
  /** fetch aggregated fields from the table: "Billing_seller" */
  Billing_seller_aggregate: Billing_Seller_Aggregate;
  /** fetch data from the table: "Billing_seller" using primary key columns */
  Billing_seller_by_pk?: Maybe<Billing_Seller>;
  /** fetch data from the table: "Billing_subscription" */
  Billing_subscription: Array<Billing_Subscription>;
  /** fetch aggregated fields from the table: "Billing_subscription" */
  Billing_subscription_aggregate: Billing_Subscription_Aggregate;
  /** fetch data from the table: "Billing_subscription" using primary key columns */
  Billing_subscription_by_pk?: Maybe<Billing_Subscription>;
  /** fetch data from the table: "Billing_subscription_product" */
  Billing_subscription_product: Array<Billing_Subscription_Product>;
  /** fetch aggregated fields from the table: "Billing_subscription_product" */
  Billing_subscription_product_aggregate: Billing_Subscription_Product_Aggregate;
  /** fetch data from the table: "Billing_subscription_product" using primary key columns */
  Billing_subscription_product_by_pk?: Maybe<Billing_Subscription_Product>;
  /** fetch data from the table: "Biolink_sections" */
  Biolink_sections: Array<Biolink_Sections>;
  /** fetch aggregated fields from the table: "Biolink_sections" */
  Biolink_sections_aggregate: Biolink_Sections_Aggregate;
  /** fetch data from the table: "Biolink_sections" using primary key columns */
  Biolink_sections_by_pk?: Maybe<Biolink_Sections>;
  /** fetch data from the table: "Certificate_of_authenticity" */
  Certificate_of_authenticity: Array<Certificate_Of_Authenticity>;
  /** fetch aggregated fields from the table: "Certificate_of_authenticity" */
  Certificate_of_authenticity_aggregate: Certificate_Of_Authenticity_Aggregate;
  /** fetch data from the table: "Certificate_of_authenticity" using primary key columns */
  Certificate_of_authenticity_by_pk?: Maybe<Certificate_Of_Authenticity>;
  /** fetch data from the table: "Collaboration" */
  Collaboration: Array<Collaboration>;
  /** fetch aggregated fields from the table: "Collaboration" */
  Collaboration_aggregate: Collaboration_Aggregate;
  /** fetch data from the table: "Collaboration" using primary key columns */
  Collaboration_by_pk?: Maybe<Collaboration>;
  /** fetch data from the table: "Collaboration_invitation_on_artwork" */
  Collaboration_invitation_on_artwork: Array<Collaboration_Invitation_On_Artwork>;
  /** fetch aggregated fields from the table: "Collaboration_invitation_on_artwork" */
  Collaboration_invitation_on_artwork_aggregate: Collaboration_Invitation_On_Artwork_Aggregate;
  /** fetch data from the table: "Collaboration_invitation_on_artwork" using primary key columns */
  Collaboration_invitation_on_artwork_by_pk?: Maybe<Collaboration_Invitation_On_Artwork>;
  /** fetch data from the table: "Collection" */
  Collection: Array<Collection>;
  /** fetch aggregated fields from the table: "Collection" */
  Collection_aggregate: Collection_Aggregate;
  /** fetch data from the table: "Collection" using primary key columns */
  Collection_by_pk?: Maybe<Collection>;
  /** fetch data from the table: "Collection_translation" */
  Collection_translation: Array<Collection_Translation>;
  /** fetch aggregated fields from the table: "Collection_translation" */
  Collection_translation_aggregate: Collection_Translation_Aggregate;
  /** fetch data from the table: "Collection_translation" using primary key columns */
  Collection_translation_by_pk?: Maybe<Collection_Translation>;
  /** fetch data from the table: "Collection_video_links" */
  Collection_video_links: Array<Collection_Video_Links>;
  /** fetch aggregated fields from the table: "Collection_video_links" */
  Collection_video_links_aggregate: Collection_Video_Links_Aggregate;
  /** fetch data from the table: "Collection_video_links" using primary key columns */
  Collection_video_links_by_pk?: Maybe<Collection_Video_Links>;
  /** fetch data from the table: "Collections_links_filtered" */
  Collections_links_filtered: Array<Collections_Links_Filtered>;
  /** fetch aggregated fields from the table: "Collections_links_filtered" */
  Collections_links_filtered_aggregate: Collections_Links_Filtered_Aggregate;
  /** fetch data from the table: "Collector_explore_info" */
  Collector_explore_info: Array<Collector_Explore_Info>;
  /** fetch aggregated fields from the table: "Collector_explore_info" */
  Collector_explore_info_aggregate: Collector_Explore_Info_Aggregate;
  /** fetch data from the table: "Collector_links_filtered" */
  Collector_links_filtered: Array<Collector_Links_Filtered>;
  /** fetch aggregated fields from the table: "Collector_links_filtered" */
  Collector_links_filtered_aggregate: Collector_Links_Filtered_Aggregate;
  /** fetch data from the table: "Connected_domain" */
  Connected_domain: Array<Connected_Domain>;
  /** fetch aggregated fields from the table: "Connected_domain" */
  Connected_domain_aggregate: Connected_Domain_Aggregate;
  /** fetch data from the table: "Connected_domain" using primary key columns */
  Connected_domain_by_pk?: Maybe<Connected_Domain>;
  /** fetch data from the table: "Content_translations" */
  Content_translations: Array<Content_Translations>;
  /** fetch aggregated fields from the table: "Content_translations" */
  Content_translations_aggregate: Content_Translations_Aggregate;
  /** fetch data from the table: "Context" */
  Context: Array<Context>;
  /** fetch aggregated fields from the table: "Context" */
  Context_aggregate: Context_Aggregate;
  /** fetch data from the table: "Context" using primary key columns */
  Context_by_pk?: Maybe<Context>;
  /** fetch data from the table: "Context_delegate_access" */
  Context_delegate_access: Array<Context_Delegate_Access>;
  /** fetch aggregated fields from the table: "Context_delegate_access" */
  Context_delegate_access_aggregate: Context_Delegate_Access_Aggregate;
  /** fetch data from the table: "Context_delegate_access" using primary key columns */
  Context_delegate_access_by_pk?: Maybe<Context_Delegate_Access>;
  /** fetch data from the table: "Context_flags" */
  Context_flags: Array<Context_Flags>;
  /** fetch aggregated fields from the table: "Context_flags" */
  Context_flags_aggregate: Context_Flags_Aggregate;
  /** fetch data from the table: "Context_flags" using primary key columns */
  Context_flags_by_pk?: Maybe<Context_Flags>;
  /** fetch data from the table: "Context_suggested_tags" */
  Context_suggested_tags: Array<Context_Suggested_Tags>;
  /** fetch aggregated fields from the table: "Context_suggested_tags" */
  Context_suggested_tags_aggregate: Context_Suggested_Tags_Aggregate;
  /** fetch data from the table: "Context_suggested_tags" using primary key columns */
  Context_suggested_tags_by_pk?: Maybe<Context_Suggested_Tags>;
  /** fetch data from the table: "Context_to_milestone" */
  Context_to_milestone: Array<Context_To_Milestone>;
  /** fetch aggregated fields from the table: "Context_to_milestone" */
  Context_to_milestone_aggregate: Context_To_Milestone_Aggregate;
  /** fetch data from the table: "Context_to_milestone" using primary key columns */
  Context_to_milestone_by_pk?: Maybe<Context_To_Milestone>;
  /** fetch data from the table: "Context_to_tutorial" */
  Context_to_tutorial: Array<Context_To_Tutorial>;
  /** fetch aggregated fields from the table: "Context_to_tutorial" */
  Context_to_tutorial_aggregate: Context_To_Tutorial_Aggregate;
  /** fetch data from the table: "Context_to_tutorial" using primary key columns */
  Context_to_tutorial_by_pk?: Maybe<Context_To_Tutorial>;
  /** fetch data from the table: "Countries" */
  Countries: Array<Countries>;
  /** fetch aggregated fields from the table: "Countries" */
  Countries_aggregate: Countries_Aggregate;
  /** fetch data from the table: "Countries" using primary key columns */
  Countries_by_pk?: Maybe<Countries>;
  /** fetch data from the table: "Crop_Area" */
  Crop_Area: Array<Crop_Area>;
  /** fetch aggregated fields from the table: "Crop_Area" */
  Crop_Area_aggregate: Crop_Area_Aggregate;
  /** fetch data from the table: "Crop_Area" using primary key columns */
  Crop_Area_by_pk?: Maybe<Crop_Area>;
  /** fetch data from the table: "Currency_conversion" */
  Currency_conversion: Array<Currency_Conversion>;
  /** fetch aggregated fields from the table: "Currency_conversion" */
  Currency_conversion_aggregate: Currency_Conversion_Aggregate;
  /** fetch data from the table: "Currency_conversion" using primary key columns */
  Currency_conversion_by_pk?: Maybe<Currency_Conversion>;
  /** fetch data from the table: "Digital_twin_details" */
  Digital_twin_details: Array<Digital_Twin_Details>;
  /** fetch aggregated fields from the table: "Digital_twin_details" */
  Digital_twin_details_aggregate: Digital_Twin_Details_Aggregate;
  /** fetch data from the table: "Digital_twin_details" using primary key columns */
  Digital_twin_details_by_pk?: Maybe<Digital_Twin_Details>;
  /** fetch data from the table: "Direct_messages_from_collectors" */
  Direct_messages_from_collectors: Array<Direct_Messages_From_Collectors>;
  /** fetch aggregated fields from the table: "Direct_messages_from_collectors" */
  Direct_messages_from_collectors_aggregate: Direct_Messages_From_Collectors_Aggregate;
  /** fetch data from the table: "Discount_code" */
  Discount_code: Array<Discount_Code>;
  /** fetch aggregated fields from the table: "Discount_code" */
  Discount_code_aggregate: Discount_Code_Aggregate;
  /** fetch data from the table: "Discount_code" using primary key columns */
  Discount_code_by_pk?: Maybe<Discount_Code>;
  /** fetch data from the table: "Discount_code_usage" */
  Discount_code_usage: Array<Discount_Code_Usage>;
  /** fetch aggregated fields from the table: "Discount_code_usage" */
  Discount_code_usage_aggregate: Discount_Code_Usage_Aggregate;
  /** fetch data from the table: "Discount_code_usage" using primary key columns */
  Discount_code_usage_by_pk?: Maybe<Discount_Code_Usage>;
  /** fetch data from the table: "Domain_registrant_info" */
  Domain_registrant_info: Array<Domain_Registrant_Info>;
  /** fetch aggregated fields from the table: "Domain_registrant_info" */
  Domain_registrant_info_aggregate: Domain_Registrant_Info_Aggregate;
  /** fetch data from the table: "Domain_registrant_info" using primary key columns */
  Domain_registrant_info_by_pk?: Maybe<Domain_Registrant_Info>;
  /** fetch data from the table: "Domain_registration" */
  Domain_registration: Array<Domain_Registration>;
  /** fetch aggregated fields from the table: "Domain_registration" */
  Domain_registration_aggregate: Domain_Registration_Aggregate;
  /** fetch data from the table: "Domain_registration" using primary key columns */
  Domain_registration_by_pk?: Maybe<Domain_Registration>;
  /** fetch data from the table: "Dummy_table_for_front" */
  Dummy_table_for_front: Array<Dummy_Table_For_Front>;
  /** fetch aggregated fields from the table: "Dummy_table_for_front" */
  Dummy_table_for_front_aggregate: Dummy_Table_For_Front_Aggregate;
  /** fetch data from the table: "Dummy_table_for_front" using primary key columns */
  Dummy_table_for_front_by_pk?: Maybe<Dummy_Table_For_Front>;
  /** fetch data from the table: "Dynamic_cv" */
  Dynamic_cv: Array<Dynamic_Cv>;
  /** fetch aggregated fields from the table: "Dynamic_cv" */
  Dynamic_cv_aggregate: Dynamic_Cv_Aggregate;
  /** fetch data from the table: "Dynamic_cv_award" */
  Dynamic_cv_award: Array<Dynamic_Cv_Award>;
  /** fetch aggregated fields from the table: "Dynamic_cv_award" */
  Dynamic_cv_award_aggregate: Dynamic_Cv_Award_Aggregate;
  /** fetch data from the table: "Dynamic_cv_award" using primary key columns */
  Dynamic_cv_award_by_pk?: Maybe<Dynamic_Cv_Award>;
  /** fetch data from the table: "Dynamic_cv" using primary key columns */
  Dynamic_cv_by_pk?: Maybe<Dynamic_Cv>;
  /** fetch data from the table: "Dynamic_cv_collection" */
  Dynamic_cv_collection: Array<Dynamic_Cv_Collection>;
  /** fetch aggregated fields from the table: "Dynamic_cv_collection" */
  Dynamic_cv_collection_aggregate: Dynamic_Cv_Collection_Aggregate;
  /** fetch data from the table: "Dynamic_cv_collection" using primary key columns */
  Dynamic_cv_collection_by_pk?: Maybe<Dynamic_Cv_Collection>;
  /** fetch data from the table: "Dynamic_cv_contact" */
  Dynamic_cv_contact: Array<Dynamic_Cv_Contact>;
  /** fetch aggregated fields from the table: "Dynamic_cv_contact" */
  Dynamic_cv_contact_aggregate: Dynamic_Cv_Contact_Aggregate;
  /** fetch data from the table: "Dynamic_cv_contact" using primary key columns */
  Dynamic_cv_contact_by_pk?: Maybe<Dynamic_Cv_Contact>;
  /** fetch data from the table: "Dynamic_cv_education" */
  Dynamic_cv_education: Array<Dynamic_Cv_Education>;
  /** fetch aggregated fields from the table: "Dynamic_cv_education" */
  Dynamic_cv_education_aggregate: Dynamic_Cv_Education_Aggregate;
  /** fetch data from the table: "Dynamic_cv_education" using primary key columns */
  Dynamic_cv_education_by_pk?: Maybe<Dynamic_Cv_Education>;
  /** fetch data from the table: "Dynamic_cv_exhibition" */
  Dynamic_cv_exhibition: Array<Dynamic_Cv_Exhibition>;
  /** fetch aggregated fields from the table: "Dynamic_cv_exhibition" */
  Dynamic_cv_exhibition_aggregate: Dynamic_Cv_Exhibition_Aggregate;
  /** fetch data from the table: "Dynamic_cv_exhibition" using primary key columns */
  Dynamic_cv_exhibition_by_pk?: Maybe<Dynamic_Cv_Exhibition>;
  /** fetch data from the table: "Dynamic_cv_publication" */
  Dynamic_cv_publication: Array<Dynamic_Cv_Publication>;
  /** fetch aggregated fields from the table: "Dynamic_cv_publication" */
  Dynamic_cv_publication_aggregate: Dynamic_Cv_Publication_Aggregate;
  /** fetch data from the table: "Dynamic_cv_publication" using primary key columns */
  Dynamic_cv_publication_by_pk?: Maybe<Dynamic_Cv_Publication>;
  /** fetch data from the table: "Dynamic_cv_section" */
  Dynamic_cv_section: Array<Dynamic_Cv_Section>;
  /** fetch aggregated fields from the table: "Dynamic_cv_section" */
  Dynamic_cv_section_aggregate: Dynamic_Cv_Section_Aggregate;
  /** fetch data from the table: "Dynamic_cv_section" using primary key columns */
  Dynamic_cv_section_by_pk?: Maybe<Dynamic_Cv_Section>;
  /** fetch data from the table: "ENUM_account_notice_type" */
  ENUM_account_notice_type: Array<Enum_Account_Notice_Type>;
  /** fetch aggregated fields from the table: "ENUM_account_notice_type" */
  ENUM_account_notice_type_aggregate: Enum_Account_Notice_Type_Aggregate;
  /** fetch data from the table: "ENUM_account_notice_type" using primary key columns */
  ENUM_account_notice_type_by_pk?: Maybe<Enum_Account_Notice_Type>;
  /** fetch data from the table: "ENUM_achievement_tags" */
  ENUM_achievement_tags: Array<Enum_Achievement_Tags>;
  /** fetch aggregated fields from the table: "ENUM_achievement_tags" */
  ENUM_achievement_tags_aggregate: Enum_Achievement_Tags_Aggregate;
  /** fetch data from the table: "ENUM_achievement_tags" using primary key columns */
  ENUM_achievement_tags_by_pk?: Maybe<Enum_Achievement_Tags>;
  /** fetch data from the table: "ENUM_add_artwork_flow_step" */
  ENUM_add_artwork_flow_step: Array<Enum_Add_Artwork_Flow_Step>;
  /** fetch aggregated fields from the table: "ENUM_add_artwork_flow_step" */
  ENUM_add_artwork_flow_step_aggregate: Enum_Add_Artwork_Flow_Step_Aggregate;
  /** fetch data from the table: "ENUM_add_artwork_flow_step" using primary key columns */
  ENUM_add_artwork_flow_step_by_pk?: Maybe<Enum_Add_Artwork_Flow_Step>;
  /** fetch data from the table: "ENUM_analytics_kaleidoCard_category" */
  ENUM_analytics_kaleidoCard_category: Array<Enum_Analytics_KaleidoCard_Category>;
  /** fetch aggregated fields from the table: "ENUM_analytics_kaleidoCard_category" */
  ENUM_analytics_kaleidoCard_category_aggregate: Enum_Analytics_KaleidoCard_Category_Aggregate;
  /** fetch data from the table: "ENUM_analytics_kaleidoCard_category" using primary key columns */
  ENUM_analytics_kaleidoCard_category_by_pk?: Maybe<Enum_Analytics_KaleidoCard_Category>;
  /** fetch data from the table: "ENUM_analytics_type" */
  ENUM_analytics_type: Array<Enum_Analytics_Type>;
  /** fetch aggregated fields from the table: "ENUM_analytics_type" */
  ENUM_analytics_type_aggregate: Enum_Analytics_Type_Aggregate;
  /** fetch data from the table: "ENUM_analytics_type" using primary key columns */
  ENUM_analytics_type_by_pk?: Maybe<Enum_Analytics_Type>;
  /** fetch data from the table: "ENUM_app_notification_type" */
  ENUM_app_notification_type: Array<Enum_App_Notification_Type>;
  /** fetch aggregated fields from the table: "ENUM_app_notification_type" */
  ENUM_app_notification_type_aggregate: Enum_App_Notification_Type_Aggregate;
  /** fetch data from the table: "ENUM_app_notification_type" using primary key columns */
  ENUM_app_notification_type_by_pk?: Maybe<Enum_App_Notification_Type>;
  /** fetch data from the table: "ENUM_art_type" */
  ENUM_art_type: Array<Enum_Art_Type>;
  /** fetch aggregated fields from the table: "ENUM_art_type" */
  ENUM_art_type_aggregate: Enum_Art_Type_Aggregate;
  /** fetch data from the table: "ENUM_art_type" using primary key columns */
  ENUM_art_type_by_pk?: Maybe<Enum_Art_Type>;
  /** fetch data from the table: "ENUM_art_type_extension" */
  ENUM_art_type_extension: Array<Enum_Art_Type_Extension>;
  /** fetch aggregated fields from the table: "ENUM_art_type_extension" */
  ENUM_art_type_extension_aggregate: Enum_Art_Type_Extension_Aggregate;
  /** fetch data from the table: "ENUM_art_type_extension" using primary key columns */
  ENUM_art_type_extension_by_pk?: Maybe<Enum_Art_Type_Extension>;
  /** fetch data from the table: "ENUM_artist_collection_categories" */
  ENUM_artist_collection_categories: Array<Enum_Artist_Collection_Categories>;
  /** fetch aggregated fields from the table: "ENUM_artist_collection_categories" */
  ENUM_artist_collection_categories_aggregate: Enum_Artist_Collection_Categories_Aggregate;
  /** fetch data from the table: "ENUM_artist_collection_categories" using primary key columns */
  ENUM_artist_collection_categories_by_pk?: Maybe<Enum_Artist_Collection_Categories>;
  /** fetch data from the table: "ENUM_artist_collection_state" */
  ENUM_artist_collection_state: Array<Enum_Artist_Collection_State>;
  /** fetch aggregated fields from the table: "ENUM_artist_collection_state" */
  ENUM_artist_collection_state_aggregate: Enum_Artist_Collection_State_Aggregate;
  /** fetch data from the table: "ENUM_artist_collection_state" using primary key columns */
  ENUM_artist_collection_state_by_pk?: Maybe<Enum_Artist_Collection_State>;
  /** fetch data from the table: "ENUM_artist_collection_type" */
  ENUM_artist_collection_type: Array<Enum_Artist_Collection_Type>;
  /** fetch aggregated fields from the table: "ENUM_artist_collection_type" */
  ENUM_artist_collection_type_aggregate: Enum_Artist_Collection_Type_Aggregate;
  /** fetch data from the table: "ENUM_artist_collection_type" using primary key columns */
  ENUM_artist_collection_type_by_pk?: Maybe<Enum_Artist_Collection_Type>;
  /** fetch data from the table: "ENUM_artwork_category" */
  ENUM_artwork_category: Array<Enum_Artwork_Category>;
  /** fetch aggregated fields from the table: "ENUM_artwork_category" */
  ENUM_artwork_category_aggregate: Enum_Artwork_Category_Aggregate;
  /** fetch data from the table: "ENUM_artwork_category" using primary key columns */
  ENUM_artwork_category_by_pk?: Maybe<Enum_Artwork_Category>;
  /** fetch data from the table: "ENUM_artwork_count_milestone_type" */
  ENUM_artwork_count_milestone_type: Array<Enum_Artwork_Count_Milestone_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_count_milestone_type" */
  ENUM_artwork_count_milestone_type_aggregate: Enum_Artwork_Count_Milestone_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_count_milestone_type" using primary key columns */
  ENUM_artwork_count_milestone_type_by_pk?: Maybe<Enum_Artwork_Count_Milestone_Type>;
  /** fetch data from the table: "ENUM_artwork_discipline" */
  ENUM_artwork_discipline: Array<Enum_Artwork_Discipline>;
  /** fetch aggregated fields from the table: "ENUM_artwork_discipline" */
  ENUM_artwork_discipline_aggregate: Enum_Artwork_Discipline_Aggregate;
  /** fetch data from the table: "ENUM_artwork_discipline" using primary key columns */
  ENUM_artwork_discipline_by_pk?: Maybe<Enum_Artwork_Discipline>;
  /** fetch data from the table: "ENUM_artwork_edition_type" */
  ENUM_artwork_edition_type: Array<Enum_Artwork_Edition_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_edition_type" */
  ENUM_artwork_edition_type_aggregate: Enum_Artwork_Edition_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_edition_type" using primary key columns */
  ENUM_artwork_edition_type_by_pk?: Maybe<Enum_Artwork_Edition_Type>;
  /** fetch data from the table: "ENUM_artwork_event_type" */
  ENUM_artwork_event_type: Array<Enum_Artwork_Event_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_event_type" */
  ENUM_artwork_event_type_aggregate: Enum_Artwork_Event_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_event_type" using primary key columns */
  ENUM_artwork_event_type_by_pk?: Maybe<Enum_Artwork_Event_Type>;
  /** fetch data from the table: "ENUM_artwork_genre" */
  ENUM_artwork_genre: Array<Enum_Artwork_Genre>;
  /** fetch aggregated fields from the table: "ENUM_artwork_genre" */
  ENUM_artwork_genre_aggregate: Enum_Artwork_Genre_Aggregate;
  /** fetch data from the table: "ENUM_artwork_genre" using primary key columns */
  ENUM_artwork_genre_by_pk?: Maybe<Enum_Artwork_Genre>;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  ENUM_artwork_purchase_journey_history_entry_kind: Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind>;
  /** fetch aggregated fields from the table: "ENUM_artwork_purchase_journey_history_entry_kind" */
  ENUM_artwork_purchase_journey_history_entry_kind_aggregate: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Aggregate;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_history_entry_kind" using primary key columns */
  ENUM_artwork_purchase_journey_history_entry_kind_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind>;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_party" */
  ENUM_artwork_purchase_journey_party: Array<Enum_Artwork_Purchase_Journey_Party>;
  /** fetch aggregated fields from the table: "ENUM_artwork_purchase_journey_party" */
  ENUM_artwork_purchase_journey_party_aggregate: Enum_Artwork_Purchase_Journey_Party_Aggregate;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_party" using primary key columns */
  ENUM_artwork_purchase_journey_party_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_Party>;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_status" */
  ENUM_artwork_purchase_journey_status: Array<Enum_Artwork_Purchase_Journey_Status>;
  /** fetch aggregated fields from the table: "ENUM_artwork_purchase_journey_status" */
  ENUM_artwork_purchase_journey_status_aggregate: Enum_Artwork_Purchase_Journey_Status_Aggregate;
  /** fetch data from the table: "ENUM_artwork_purchase_journey_status" using primary key columns */
  ENUM_artwork_purchase_journey_status_by_pk?: Maybe<Enum_Artwork_Purchase_Journey_Status>;
  /** fetch data from the table: "ENUM_artwork_showroom_status" */
  ENUM_artwork_showroom_status: Array<Enum_Artwork_Showroom_Status>;
  /** fetch aggregated fields from the table: "ENUM_artwork_showroom_status" */
  ENUM_artwork_showroom_status_aggregate: Enum_Artwork_Showroom_Status_Aggregate;
  /** fetch data from the table: "ENUM_artwork_showroom_status" using primary key columns */
  ENUM_artwork_showroom_status_by_pk?: Maybe<Enum_Artwork_Showroom_Status>;
  /** fetch data from the table: "ENUM_artwork_status" */
  ENUM_artwork_status: Array<Enum_Artwork_Status>;
  /** fetch aggregated fields from the table: "ENUM_artwork_status" */
  ENUM_artwork_status_aggregate: Enum_Artwork_Status_Aggregate;
  /** fetch data from the table: "ENUM_artwork_status" using primary key columns */
  ENUM_artwork_status_by_pk?: Maybe<Enum_Artwork_Status>;
  /** fetch data from the table: "ENUM_artwork_tag" */
  ENUM_artwork_tag: Array<Enum_Artwork_Tag>;
  /** fetch aggregated fields from the table: "ENUM_artwork_tag" */
  ENUM_artwork_tag_aggregate: Enum_Artwork_Tag_Aggregate;
  /** fetch data from the table: "ENUM_artwork_tag" using primary key columns */
  ENUM_artwork_tag_by_pk?: Maybe<Enum_Artwork_Tag>;
  /** fetch data from the table: "ENUM_artwork_tag_type" */
  ENUM_artwork_tag_type: Array<Enum_Artwork_Tag_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_tag_type" */
  ENUM_artwork_tag_type_aggregate: Enum_Artwork_Tag_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_tag_type" using primary key columns */
  ENUM_artwork_tag_type_by_pk?: Maybe<Enum_Artwork_Tag_Type>;
  /** fetch data from the table: "ENUM_artwork_type" */
  ENUM_artwork_type: Array<Enum_Artwork_Type>;
  /** fetch aggregated fields from the table: "ENUM_artwork_type" */
  ENUM_artwork_type_aggregate: Enum_Artwork_Type_Aggregate;
  /** fetch data from the table: "ENUM_artwork_type" using primary key columns */
  ENUM_artwork_type_by_pk?: Maybe<Enum_Artwork_Type>;
  /** fetch data from the table: "ENUM_audio_generation_status" */
  ENUM_audio_generation_status: Array<Enum_Audio_Generation_Status>;
  /** fetch aggregated fields from the table: "ENUM_audio_generation_status" */
  ENUM_audio_generation_status_aggregate: Enum_Audio_Generation_Status_Aggregate;
  /** fetch data from the table: "ENUM_audio_generation_status" using primary key columns */
  ENUM_audio_generation_status_by_pk?: Maybe<Enum_Audio_Generation_Status>;
  /** fetch data from the table: "ENUM_auth_type" */
  ENUM_auth_type: Array<Enum_Auth_Type>;
  /** fetch aggregated fields from the table: "ENUM_auth_type" */
  ENUM_auth_type_aggregate: Enum_Auth_Type_Aggregate;
  /** fetch data from the table: "ENUM_auth_type" using primary key columns */
  ENUM_auth_type_by_pk?: Maybe<Enum_Auth_Type>;
  /** fetch data from the table: "ENUM_billing_artwork_payment_intent_status" */
  ENUM_billing_artwork_payment_intent_status: Array<Enum_Billing_Artwork_Payment_Intent_Status>;
  /** fetch aggregated fields from the table: "ENUM_billing_artwork_payment_intent_status" */
  ENUM_billing_artwork_payment_intent_status_aggregate: Enum_Billing_Artwork_Payment_Intent_Status_Aggregate;
  /** fetch data from the table: "ENUM_billing_artwork_payment_intent_status" using primary key columns */
  ENUM_billing_artwork_payment_intent_status_by_pk?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status>;
  /** fetch data from the table: "ENUM_billing_payment_intent_product_status" */
  ENUM_billing_payment_intent_product_status: Array<Enum_Billing_Payment_Intent_Product_Status>;
  /** fetch aggregated fields from the table: "ENUM_billing_payment_intent_product_status" */
  ENUM_billing_payment_intent_product_status_aggregate: Enum_Billing_Payment_Intent_Product_Status_Aggregate;
  /** fetch data from the table: "ENUM_billing_payment_intent_product_status" using primary key columns */
  ENUM_billing_payment_intent_product_status_by_pk?: Maybe<Enum_Billing_Payment_Intent_Product_Status>;
  /** fetch data from the table: "ENUM_billing_seller_status" */
  ENUM_billing_seller_status: Array<Enum_Billing_Seller_Status>;
  /** fetch aggregated fields from the table: "ENUM_billing_seller_status" */
  ENUM_billing_seller_status_aggregate: Enum_Billing_Seller_Status_Aggregate;
  /** fetch data from the table: "ENUM_billing_seller_status" using primary key columns */
  ENUM_billing_seller_status_by_pk?: Maybe<Enum_Billing_Seller_Status>;
  /** fetch data from the table: "ENUM_billing_subscription_period" */
  ENUM_billing_subscription_period: Array<Enum_Billing_Subscription_Period>;
  /** fetch aggregated fields from the table: "ENUM_billing_subscription_period" */
  ENUM_billing_subscription_period_aggregate: Enum_Billing_Subscription_Period_Aggregate;
  /** fetch data from the table: "ENUM_billing_subscription_period" using primary key columns */
  ENUM_billing_subscription_period_by_pk?: Maybe<Enum_Billing_Subscription_Period>;
  /** fetch data from the table: "ENUM_billing_subscription_product_type" */
  ENUM_billing_subscription_product_type: Array<Enum_Billing_Subscription_Product_Type>;
  /** fetch aggregated fields from the table: "ENUM_billing_subscription_product_type" */
  ENUM_billing_subscription_product_type_aggregate: Enum_Billing_Subscription_Product_Type_Aggregate;
  /** fetch data from the table: "ENUM_billing_subscription_product_type" using primary key columns */
  ENUM_billing_subscription_product_type_by_pk?: Maybe<Enum_Billing_Subscription_Product_Type>;
  /** fetch data from the table: "ENUM_billing_subscription_status" */
  ENUM_billing_subscription_status: Array<Enum_Billing_Subscription_Status>;
  /** fetch aggregated fields from the table: "ENUM_billing_subscription_status" */
  ENUM_billing_subscription_status_aggregate: Enum_Billing_Subscription_Status_Aggregate;
  /** fetch data from the table: "ENUM_billing_subscription_status" using primary key columns */
  ENUM_billing_subscription_status_by_pk?: Maybe<Enum_Billing_Subscription_Status>;
  /** fetch data from the table: "ENUM_blockchain" */
  ENUM_blockchain: Array<Enum_Blockchain>;
  /** fetch aggregated fields from the table: "ENUM_blockchain" */
  ENUM_blockchain_aggregate: Enum_Blockchain_Aggregate;
  /** fetch data from the table: "ENUM_blockchain" using primary key columns */
  ENUM_blockchain_by_pk?: Maybe<Enum_Blockchain>;
  /** fetch data from the table: "ENUM_collaboration_status" */
  ENUM_collaboration_status: Array<Enum_Collaboration_Status>;
  /** fetch aggregated fields from the table: "ENUM_collaboration_status" */
  ENUM_collaboration_status_aggregate: Enum_Collaboration_Status_Aggregate;
  /** fetch data from the table: "ENUM_collaboration_status" using primary key columns */
  ENUM_collaboration_status_by_pk?: Maybe<Enum_Collaboration_Status>;
  /** fetch data from the table: "ENUM_collection_type" */
  ENUM_collection_type: Array<Enum_Collection_Type>;
  /** fetch aggregated fields from the table: "ENUM_collection_type" */
  ENUM_collection_type_aggregate: Enum_Collection_Type_Aggregate;
  /** fetch data from the table: "ENUM_collection_type" using primary key columns */
  ENUM_collection_type_by_pk?: Maybe<Enum_Collection_Type>;
  /** fetch data from the table: "ENUM_connected_domain_state" */
  ENUM_connected_domain_state: Array<Enum_Connected_Domain_State>;
  /** fetch aggregated fields from the table: "ENUM_connected_domain_state" */
  ENUM_connected_domain_state_aggregate: Enum_Connected_Domain_State_Aggregate;
  /** fetch data from the table: "ENUM_connected_domain_state" using primary key columns */
  ENUM_connected_domain_state_by_pk?: Maybe<Enum_Connected_Domain_State>;
  /** fetch data from the table: "ENUM_content_type" */
  ENUM_content_type: Array<Enum_Content_Type>;
  /** fetch aggregated fields from the table: "ENUM_content_type" */
  ENUM_content_type_aggregate: Enum_Content_Type_Aggregate;
  /** fetch data from the table: "ENUM_content_type" using primary key columns */
  ENUM_content_type_by_pk?: Maybe<Enum_Content_Type>;
  /** fetch data from the table: "ENUM_context_delegate_role" */
  ENUM_context_delegate_role: Array<Enum_Context_Delegate_Role>;
  /** fetch aggregated fields from the table: "ENUM_context_delegate_role" */
  ENUM_context_delegate_role_aggregate: Enum_Context_Delegate_Role_Aggregate;
  /** fetch data from the table: "ENUM_context_delegate_role" using primary key columns */
  ENUM_context_delegate_role_by_pk?: Maybe<Enum_Context_Delegate_Role>;
  /** fetch data from the table: "ENUM_context_delegate_state" */
  ENUM_context_delegate_state: Array<Enum_Context_Delegate_State>;
  /** fetch aggregated fields from the table: "ENUM_context_delegate_state" */
  ENUM_context_delegate_state_aggregate: Enum_Context_Delegate_State_Aggregate;
  /** fetch data from the table: "ENUM_context_delegate_state" using primary key columns */
  ENUM_context_delegate_state_by_pk?: Maybe<Enum_Context_Delegate_State>;
  /** fetch data from the table: "ENUM_context_state" */
  ENUM_context_state: Array<Enum_Context_State>;
  /** fetch aggregated fields from the table: "ENUM_context_state" */
  ENUM_context_state_aggregate: Enum_Context_State_Aggregate;
  /** fetch data from the table: "ENUM_context_state" using primary key columns */
  ENUM_context_state_by_pk?: Maybe<Enum_Context_State>;
  /** fetch data from the table: "ENUM_context_type" */
  ENUM_context_type: Array<Enum_Context_Type>;
  /** fetch aggregated fields from the table: "ENUM_context_type" */
  ENUM_context_type_aggregate: Enum_Context_Type_Aggregate;
  /** fetch data from the table: "ENUM_context_type" using primary key columns */
  ENUM_context_type_by_pk?: Maybe<Enum_Context_Type>;
  /** fetch data from the table: "ENUM_discount_state" */
  ENUM_discount_state: Array<Enum_Discount_State>;
  /** fetch aggregated fields from the table: "ENUM_discount_state" */
  ENUM_discount_state_aggregate: Enum_Discount_State_Aggregate;
  /** fetch data from the table: "ENUM_discount_state" using primary key columns */
  ENUM_discount_state_by_pk?: Maybe<Enum_Discount_State>;
  /** fetch data from the table: "ENUM_discount_type" */
  ENUM_discount_type: Array<Enum_Discount_Type>;
  /** fetch aggregated fields from the table: "ENUM_discount_type" */
  ENUM_discount_type_aggregate: Enum_Discount_Type_Aggregate;
  /** fetch data from the table: "ENUM_discount_type" using primary key columns */
  ENUM_discount_type_by_pk?: Maybe<Enum_Discount_Type>;
  /** fetch data from the table: "ENUM_discount_value_type" */
  ENUM_discount_value_type: Array<Enum_Discount_Value_Type>;
  /** fetch aggregated fields from the table: "ENUM_discount_value_type" */
  ENUM_discount_value_type_aggregate: Enum_Discount_Value_Type_Aggregate;
  /** fetch data from the table: "ENUM_discount_value_type" using primary key columns */
  ENUM_discount_value_type_by_pk?: Maybe<Enum_Discount_Value_Type>;
  /** fetch data from the table: "ENUM_domain_registration_status" */
  ENUM_domain_registration_status: Array<Enum_Domain_Registration_Status>;
  /** fetch aggregated fields from the table: "ENUM_domain_registration_status" */
  ENUM_domain_registration_status_aggregate: Enum_Domain_Registration_Status_Aggregate;
  /** fetch data from the table: "ENUM_domain_registration_status" using primary key columns */
  ENUM_domain_registration_status_by_pk?: Maybe<Enum_Domain_Registration_Status>;
  /** fetch data from the table: "ENUM_dynamic_cv_exhibition_type" */
  ENUM_dynamic_cv_exhibition_type: Array<Enum_Dynamic_Cv_Exhibition_Type>;
  /** fetch aggregated fields from the table: "ENUM_dynamic_cv_exhibition_type" */
  ENUM_dynamic_cv_exhibition_type_aggregate: Enum_Dynamic_Cv_Exhibition_Type_Aggregate;
  /** fetch data from the table: "ENUM_dynamic_cv_exhibition_type" using primary key columns */
  ENUM_dynamic_cv_exhibition_type_by_pk?: Maybe<Enum_Dynamic_Cv_Exhibition_Type>;
  /** fetch data from the table: "ENUM_dynamic_cv_section_type" */
  ENUM_dynamic_cv_section_type: Array<Enum_Dynamic_Cv_Section_Type>;
  /** fetch aggregated fields from the table: "ENUM_dynamic_cv_section_type" */
  ENUM_dynamic_cv_section_type_aggregate: Enum_Dynamic_Cv_Section_Type_Aggregate;
  /** fetch data from the table: "ENUM_dynamic_cv_section_type" using primary key columns */
  ENUM_dynamic_cv_section_type_by_pk?: Maybe<Enum_Dynamic_Cv_Section_Type>;
  /** fetch data from the table: "ENUM_dynamic_cv_status" */
  ENUM_dynamic_cv_status: Array<Enum_Dynamic_Cv_Status>;
  /** fetch aggregated fields from the table: "ENUM_dynamic_cv_status" */
  ENUM_dynamic_cv_status_aggregate: Enum_Dynamic_Cv_Status_Aggregate;
  /** fetch data from the table: "ENUM_dynamic_cv_status" using primary key columns */
  ENUM_dynamic_cv_status_by_pk?: Maybe<Enum_Dynamic_Cv_Status>;
  /** fetch data from the table: "ENUM_edition_status" */
  ENUM_edition_status: Array<Enum_Edition_Status>;
  /** fetch aggregated fields from the table: "ENUM_edition_status" */
  ENUM_edition_status_aggregate: Enum_Edition_Status_Aggregate;
  /** fetch data from the table: "ENUM_edition_status" using primary key columns */
  ENUM_edition_status_by_pk?: Maybe<Enum_Edition_Status>;
  /** fetch data from the table: "ENUM_email_language" */
  ENUM_email_language: Array<Enum_Email_Language>;
  /** fetch aggregated fields from the table: "ENUM_email_language" */
  ENUM_email_language_aggregate: Enum_Email_Language_Aggregate;
  /** fetch data from the table: "ENUM_email_language" using primary key columns */
  ENUM_email_language_by_pk?: Maybe<Enum_Email_Language>;
  /** fetch data from the table: "ENUM_email_status" */
  ENUM_email_status: Array<Enum_Email_Status>;
  /** fetch aggregated fields from the table: "ENUM_email_status" */
  ENUM_email_status_aggregate: Enum_Email_Status_Aggregate;
  /** fetch data from the table: "ENUM_email_status" using primary key columns */
  ENUM_email_status_by_pk?: Maybe<Enum_Email_Status>;
  /** fetch data from the table: "ENUM_email_validation_token_status" */
  ENUM_email_validation_token_status: Array<Enum_Email_Validation_Token_Status>;
  /** fetch aggregated fields from the table: "ENUM_email_validation_token_status" */
  ENUM_email_validation_token_status_aggregate: Enum_Email_Validation_Token_Status_Aggregate;
  /** fetch data from the table: "ENUM_email_validation_token_status" using primary key columns */
  ENUM_email_validation_token_status_by_pk?: Maybe<Enum_Email_Validation_Token_Status>;
  /** fetch data from the table: "ENUM_file_metadata_status" */
  ENUM_file_metadata_status: Array<Enum_File_Metadata_Status>;
  /** fetch aggregated fields from the table: "ENUM_file_metadata_status" */
  ENUM_file_metadata_status_aggregate: Enum_File_Metadata_Status_Aggregate;
  /** fetch data from the table: "ENUM_file_metadata_status" using primary key columns */
  ENUM_file_metadata_status_by_pk?: Maybe<Enum_File_Metadata_Status>;
  /** fetch data from the table: "ENUM_flags_type" */
  ENUM_flags_type: Array<Enum_Flags_Type>;
  /** fetch aggregated fields from the table: "ENUM_flags_type" */
  ENUM_flags_type_aggregate: Enum_Flags_Type_Aggregate;
  /** fetch data from the table: "ENUM_flags_type" using primary key columns */
  ENUM_flags_type_by_pk?: Maybe<Enum_Flags_Type>;
  /** fetch data from the table: "ENUM_forge_configuration_type" */
  ENUM_forge_configuration_type: Array<Enum_Forge_Configuration_Type>;
  /** fetch aggregated fields from the table: "ENUM_forge_configuration_type" */
  ENUM_forge_configuration_type_aggregate: Enum_Forge_Configuration_Type_Aggregate;
  /** fetch data from the table: "ENUM_forge_configuration_type" using primary key columns */
  ENUM_forge_configuration_type_by_pk?: Maybe<Enum_Forge_Configuration_Type>;
  /** fetch data from the table: "ENUM_forge_status" */
  ENUM_forge_status: Array<Enum_Forge_Status>;
  /** fetch aggregated fields from the table: "ENUM_forge_status" */
  ENUM_forge_status_aggregate: Enum_Forge_Status_Aggregate;
  /** fetch data from the table: "ENUM_forge_status" using primary key columns */
  ENUM_forge_status_by_pk?: Maybe<Enum_Forge_Status>;
  /** fetch data from the table: "ENUM_infraction_flag_type" */
  ENUM_infraction_flag_type: Array<Enum_Infraction_Flag_Type>;
  /** fetch aggregated fields from the table: "ENUM_infraction_flag_type" */
  ENUM_infraction_flag_type_aggregate: Enum_Infraction_Flag_Type_Aggregate;
  /** fetch data from the table: "ENUM_infraction_flag_type" using primary key columns */
  ENUM_infraction_flag_type_by_pk?: Maybe<Enum_Infraction_Flag_Type>;
  /** fetch data from the table: "ENUM_interest_type" */
  ENUM_interest_type: Array<Enum_Interest_Type>;
  /** fetch aggregated fields from the table: "ENUM_interest_type" */
  ENUM_interest_type_aggregate: Enum_Interest_Type_Aggregate;
  /** fetch data from the table: "ENUM_interest_type" using primary key columns */
  ENUM_interest_type_by_pk?: Maybe<Enum_Interest_Type>;
  /** fetch data from the table: "ENUM_language_preference" */
  ENUM_language_preference: Array<Enum_Language_Preference>;
  /** fetch aggregated fields from the table: "ENUM_language_preference" */
  ENUM_language_preference_aggregate: Enum_Language_Preference_Aggregate;
  /** fetch data from the table: "ENUM_language_preference" using primary key columns */
  ENUM_language_preference_by_pk?: Maybe<Enum_Language_Preference>;
  /** fetch data from the table: "ENUM_my_website_artwork_info" */
  ENUM_my_website_artwork_info: Array<Enum_My_Website_Artwork_Info>;
  /** fetch aggregated fields from the table: "ENUM_my_website_artwork_info" */
  ENUM_my_website_artwork_info_aggregate: Enum_My_Website_Artwork_Info_Aggregate;
  /** fetch data from the table: "ENUM_my_website_artwork_info" using primary key columns */
  ENUM_my_website_artwork_info_by_pk?: Maybe<Enum_My_Website_Artwork_Info>;
  /** fetch data from the table: "ENUM_my_website_filters" */
  ENUM_my_website_filters: Array<Enum_My_Website_Filters>;
  /** fetch aggregated fields from the table: "ENUM_my_website_filters" */
  ENUM_my_website_filters_aggregate: Enum_My_Website_Filters_Aggregate;
  /** fetch data from the table: "ENUM_my_website_filters" using primary key columns */
  ENUM_my_website_filters_by_pk?: Maybe<Enum_My_Website_Filters>;
  /** fetch data from the table: "ENUM_my_website_layout" */
  ENUM_my_website_layout: Array<Enum_My_Website_Layout>;
  /** fetch aggregated fields from the table: "ENUM_my_website_layout" */
  ENUM_my_website_layout_aggregate: Enum_My_Website_Layout_Aggregate;
  /** fetch data from the table: "ENUM_my_website_layout" using primary key columns */
  ENUM_my_website_layout_by_pk?: Maybe<Enum_My_Website_Layout>;
  /** fetch data from the table: "ENUM_my_website_navigation" */
  ENUM_my_website_navigation: Array<Enum_My_Website_Navigation>;
  /** fetch aggregated fields from the table: "ENUM_my_website_navigation" */
  ENUM_my_website_navigation_aggregate: Enum_My_Website_Navigation_Aggregate;
  /** fetch data from the table: "ENUM_my_website_navigation" using primary key columns */
  ENUM_my_website_navigation_by_pk?: Maybe<Enum_My_Website_Navigation>;
  /** fetch data from the table: "ENUM_my_website_theme" */
  ENUM_my_website_theme: Array<Enum_My_Website_Theme>;
  /** fetch aggregated fields from the table: "ENUM_my_website_theme" */
  ENUM_my_website_theme_aggregate: Enum_My_Website_Theme_Aggregate;
  /** fetch data from the table: "ENUM_my_website_theme" using primary key columns */
  ENUM_my_website_theme_by_pk?: Maybe<Enum_My_Website_Theme>;
  /** fetch data from the table: "ENUM_notification_channel" */
  ENUM_notification_channel: Array<Enum_Notification_Channel>;
  /** fetch aggregated fields from the table: "ENUM_notification_channel" */
  ENUM_notification_channel_aggregate: Enum_Notification_Channel_Aggregate;
  /** fetch data from the table: "ENUM_notification_channel" using primary key columns */
  ENUM_notification_channel_by_pk?: Maybe<Enum_Notification_Channel>;
  /** fetch data from the table: "ENUM_notification_group" */
  ENUM_notification_group: Array<Enum_Notification_Group>;
  /** fetch aggregated fields from the table: "ENUM_notification_group" */
  ENUM_notification_group_aggregate: Enum_Notification_Group_Aggregate;
  /** fetch data from the table: "ENUM_notification_group" using primary key columns */
  ENUM_notification_group_by_pk?: Maybe<Enum_Notification_Group>;
  /** fetch data from the table: "ENUM_position_type" */
  ENUM_position_type: Array<Enum_Position_Type>;
  /** fetch aggregated fields from the table: "ENUM_position_type" */
  ENUM_position_type_aggregate: Enum_Position_Type_Aggregate;
  /** fetch data from the table: "ENUM_position_type" using primary key columns */
  ENUM_position_type_by_pk?: Maybe<Enum_Position_Type>;
  /** fetch data from the table: "ENUM_price_scope" */
  ENUM_price_scope: Array<Enum_Price_Scope>;
  /** fetch aggregated fields from the table: "ENUM_price_scope" */
  ENUM_price_scope_aggregate: Enum_Price_Scope_Aggregate;
  /** fetch data from the table: "ENUM_price_scope" using primary key columns */
  ENUM_price_scope_by_pk?: Maybe<Enum_Price_Scope>;
  /** fetch data from the table: "ENUM_print_technique" */
  ENUM_print_technique: Array<Enum_Print_Technique>;
  /** fetch aggregated fields from the table: "ENUM_print_technique" */
  ENUM_print_technique_aggregate: Enum_Print_Technique_Aggregate;
  /** fetch data from the table: "ENUM_print_technique" using primary key columns */
  ENUM_print_technique_by_pk?: Maybe<Enum_Print_Technique>;
  /** fetch data from the table: "ENUM_profile_background_type" */
  ENUM_profile_background_type: Array<Enum_Profile_Background_Type>;
  /** fetch aggregated fields from the table: "ENUM_profile_background_type" */
  ENUM_profile_background_type_aggregate: Enum_Profile_Background_Type_Aggregate;
  /** fetch data from the table: "ENUM_profile_background_type" using primary key columns */
  ENUM_profile_background_type_by_pk?: Maybe<Enum_Profile_Background_Type>;
  /** fetch data from the table: "ENUM_profile_invitation_status" */
  ENUM_profile_invitation_status: Array<Enum_Profile_Invitation_Status>;
  /** fetch aggregated fields from the table: "ENUM_profile_invitation_status" */
  ENUM_profile_invitation_status_aggregate: Enum_Profile_Invitation_Status_Aggregate;
  /** fetch data from the table: "ENUM_profile_invitation_status" using primary key columns */
  ENUM_profile_invitation_status_by_pk?: Maybe<Enum_Profile_Invitation_Status>;
  /** fetch data from the table: "ENUM_profile_public_status" */
  ENUM_profile_public_status: Array<Enum_Profile_Public_Status>;
  /** fetch aggregated fields from the table: "ENUM_profile_public_status" */
  ENUM_profile_public_status_aggregate: Enum_Profile_Public_Status_Aggregate;
  /** fetch data from the table: "ENUM_profile_public_status" using primary key columns */
  ENUM_profile_public_status_by_pk?: Maybe<Enum_Profile_Public_Status>;
  /** fetch data from the table: "ENUM_profile_region" */
  ENUM_profile_region: Array<Enum_Profile_Region>;
  /** fetch aggregated fields from the table: "ENUM_profile_region" */
  ENUM_profile_region_aggregate: Enum_Profile_Region_Aggregate;
  /** fetch data from the table: "ENUM_profile_region" using primary key columns */
  ENUM_profile_region_by_pk?: Maybe<Enum_Profile_Region>;
  /** fetch data from the table: "ENUM_profile_status" */
  ENUM_profile_status: Array<Enum_Profile_Status>;
  /** fetch aggregated fields from the table: "ENUM_profile_status" */
  ENUM_profile_status_aggregate: Enum_Profile_Status_Aggregate;
  /** fetch data from the table: "ENUM_profile_status" using primary key columns */
  ENUM_profile_status_by_pk?: Maybe<Enum_Profile_Status>;
  /** fetch data from the table: "ENUM_profile_theme" */
  ENUM_profile_theme: Array<Enum_Profile_Theme>;
  /** fetch aggregated fields from the table: "ENUM_profile_theme" */
  ENUM_profile_theme_aggregate: Enum_Profile_Theme_Aggregate;
  /** fetch data from the table: "ENUM_profile_theme" using primary key columns */
  ENUM_profile_theme_by_pk?: Maybe<Enum_Profile_Theme>;
  /** fetch data from the table: "ENUM_profile_type" */
  ENUM_profile_type: Array<Enum_Profile_Type>;
  /** fetch aggregated fields from the table: "ENUM_profile_type" */
  ENUM_profile_type_aggregate: Enum_Profile_Type_Aggregate;
  /** fetch data from the table: "ENUM_profile_type" using primary key columns */
  ENUM_profile_type_by_pk?: Maybe<Enum_Profile_Type>;
  /** fetch data from the table: "ENUM_pseudo_user_action" */
  ENUM_pseudo_user_action: Array<Enum_Pseudo_User_Action>;
  /** fetch aggregated fields from the table: "ENUM_pseudo_user_action" */
  ENUM_pseudo_user_action_aggregate: Enum_Pseudo_User_Action_Aggregate;
  /** fetch data from the table: "ENUM_pseudo_user_action" using primary key columns */
  ENUM_pseudo_user_action_by_pk?: Maybe<Enum_Pseudo_User_Action>;
  /** fetch data from the table: "ENUM_push_notification_status" */
  ENUM_push_notification_status: Array<Enum_Push_Notification_Status>;
  /** fetch aggregated fields from the table: "ENUM_push_notification_status" */
  ENUM_push_notification_status_aggregate: Enum_Push_Notification_Status_Aggregate;
  /** fetch data from the table: "ENUM_push_notification_status" using primary key columns */
  ENUM_push_notification_status_by_pk?: Maybe<Enum_Push_Notification_Status>;
  /** fetch data from the table: "ENUM_push_notification_type" */
  ENUM_push_notification_type: Array<Enum_Push_Notification_Type>;
  /** fetch aggregated fields from the table: "ENUM_push_notification_type" */
  ENUM_push_notification_type_aggregate: Enum_Push_Notification_Type_Aggregate;
  /** fetch data from the table: "ENUM_push_notification_type" using primary key columns */
  ENUM_push_notification_type_by_pk?: Maybe<Enum_Push_Notification_Type>;
  /** fetch data from the table: "ENUM_questionnaire_type" */
  ENUM_questionnaire_type: Array<Enum_Questionnaire_Type>;
  /** fetch aggregated fields from the table: "ENUM_questionnaire_type" */
  ENUM_questionnaire_type_aggregate: Enum_Questionnaire_Type_Aggregate;
  /** fetch data from the table: "ENUM_questionnaire_type" using primary key columns */
  ENUM_questionnaire_type_by_pk?: Maybe<Enum_Questionnaire_Type>;
  /** fetch data from the table: "ENUM_render_request_status" */
  ENUM_render_request_status: Array<Enum_Render_Request_Status>;
  /** fetch aggregated fields from the table: "ENUM_render_request_status" */
  ENUM_render_request_status_aggregate: Enum_Render_Request_Status_Aggregate;
  /** fetch data from the table: "ENUM_render_request_status" using primary key columns */
  ENUM_render_request_status_by_pk?: Maybe<Enum_Render_Request_Status>;
  /** fetch data from the table: "ENUM_render_request_template_type" */
  ENUM_render_request_template_type: Array<Enum_Render_Request_Template_Type>;
  /** fetch aggregated fields from the table: "ENUM_render_request_template_type" */
  ENUM_render_request_template_type_aggregate: Enum_Render_Request_Template_Type_Aggregate;
  /** fetch data from the table: "ENUM_render_request_template_type" using primary key columns */
  ENUM_render_request_template_type_by_pk?: Maybe<Enum_Render_Request_Template_Type>;
  /** fetch data from the table: "ENUM_restricted_handle_names" */
  ENUM_restricted_handle_names: Array<Enum_Restricted_Handle_Names>;
  /** fetch aggregated fields from the table: "ENUM_restricted_handle_names" */
  ENUM_restricted_handle_names_aggregate: Enum_Restricted_Handle_Names_Aggregate;
  /** fetch data from the table: "ENUM_restricted_handle_names" using primary key columns */
  ENUM_restricted_handle_names_by_pk?: Maybe<Enum_Restricted_Handle_Names>;
  /** fetch data from the table: "ENUM_size" */
  ENUM_size: Array<Enum_Size>;
  /** fetch aggregated fields from the table: "ENUM_size" */
  ENUM_size_aggregate: Enum_Size_Aggregate;
  /** fetch data from the table: "ENUM_size" using primary key columns */
  ENUM_size_by_pk?: Maybe<Enum_Size>;
  /** fetch data from the table: "ENUM_social_graph_followers_state" */
  ENUM_social_graph_followers_state: Array<Enum_Social_Graph_Followers_State>;
  /** fetch aggregated fields from the table: "ENUM_social_graph_followers_state" */
  ENUM_social_graph_followers_state_aggregate: Enum_Social_Graph_Followers_State_Aggregate;
  /** fetch data from the table: "ENUM_social_graph_followers_state" using primary key columns */
  ENUM_social_graph_followers_state_by_pk?: Maybe<Enum_Social_Graph_Followers_State>;
  /** fetch data from the table: "ENUM_social_link_status" */
  ENUM_social_link_status: Array<Enum_Social_Link_Status>;
  /** fetch aggregated fields from the table: "ENUM_social_link_status" */
  ENUM_social_link_status_aggregate: Enum_Social_Link_Status_Aggregate;
  /** fetch data from the table: "ENUM_social_link_status" using primary key columns */
  ENUM_social_link_status_by_pk?: Maybe<Enum_Social_Link_Status>;
  /** fetch data from the table: "ENUM_social_link_type" */
  ENUM_social_link_type: Array<Enum_Social_Link_Type>;
  /** fetch aggregated fields from the table: "ENUM_social_link_type" */
  ENUM_social_link_type_aggregate: Enum_Social_Link_Type_Aggregate;
  /** fetch data from the table: "ENUM_social_link_type" using primary key columns */
  ENUM_social_link_type_by_pk?: Maybe<Enum_Social_Link_Type>;
  /** fetch data from the table: "ENUM_timed_event_name" */
  ENUM_timed_event_name: Array<Enum_Timed_Event_Name>;
  /** fetch aggregated fields from the table: "ENUM_timed_event_name" */
  ENUM_timed_event_name_aggregate: Enum_Timed_Event_Name_Aggregate;
  /** fetch data from the table: "ENUM_timed_event_name" using primary key columns */
  ENUM_timed_event_name_by_pk?: Maybe<Enum_Timed_Event_Name>;
  /** fetch data from the table: "ENUM_token_storage_status" */
  ENUM_token_storage_status: Array<Enum_Token_Storage_Status>;
  /** fetch aggregated fields from the table: "ENUM_token_storage_status" */
  ENUM_token_storage_status_aggregate: Enum_Token_Storage_Status_Aggregate;
  /** fetch data from the table: "ENUM_token_storage_status" using primary key columns */
  ENUM_token_storage_status_by_pk?: Maybe<Enum_Token_Storage_Status>;
  /** fetch data from the table: "ENUM_transaction_origin" */
  ENUM_transaction_origin: Array<Enum_Transaction_Origin>;
  /** fetch aggregated fields from the table: "ENUM_transaction_origin" */
  ENUM_transaction_origin_aggregate: Enum_Transaction_Origin_Aggregate;
  /** fetch data from the table: "ENUM_transaction_origin" using primary key columns */
  ENUM_transaction_origin_by_pk?: Maybe<Enum_Transaction_Origin>;
  /** fetch data from the table: "ENUM_transaction_status" */
  ENUM_transaction_status: Array<Enum_Transaction_Status>;
  /** fetch aggregated fields from the table: "ENUM_transaction_status" */
  ENUM_transaction_status_aggregate: Enum_Transaction_Status_Aggregate;
  /** fetch data from the table: "ENUM_transaction_status" using primary key columns */
  ENUM_transaction_status_by_pk?: Maybe<Enum_Transaction_Status>;
  /** fetch data from the table: "ENUM_tutorial_status" */
  ENUM_tutorial_status: Array<Enum_Tutorial_Status>;
  /** fetch aggregated fields from the table: "ENUM_tutorial_status" */
  ENUM_tutorial_status_aggregate: Enum_Tutorial_Status_Aggregate;
  /** fetch data from the table: "ENUM_tutorial_status" using primary key columns */
  ENUM_tutorial_status_by_pk?: Maybe<Enum_Tutorial_Status>;
  /** fetch data from the table: "ENUM_tutorial_step_type" */
  ENUM_tutorial_step_type: Array<Enum_Tutorial_Step_Type>;
  /** fetch aggregated fields from the table: "ENUM_tutorial_step_type" */
  ENUM_tutorial_step_type_aggregate: Enum_Tutorial_Step_Type_Aggregate;
  /** fetch data from the table: "ENUM_tutorial_step_type" using primary key columns */
  ENUM_tutorial_step_type_by_pk?: Maybe<Enum_Tutorial_Step_Type>;
  /** fetch data from the table: "ENUM_tutorial_type" */
  ENUM_tutorial_type: Array<Enum_Tutorial_Type>;
  /** fetch aggregated fields from the table: "ENUM_tutorial_type" */
  ENUM_tutorial_type_aggregate: Enum_Tutorial_Type_Aggregate;
  /** fetch data from the table: "ENUM_tutorial_type" using primary key columns */
  ENUM_tutorial_type_by_pk?: Maybe<Enum_Tutorial_Type>;
  /** fetch data from the table: "ENUM_vumark_status" */
  ENUM_vumark_status: Array<Enum_Vumark_Status>;
  /** fetch aggregated fields from the table: "ENUM_vumark_status" */
  ENUM_vumark_status_aggregate: Enum_Vumark_Status_Aggregate;
  /** fetch data from the table: "ENUM_vumark_status" using primary key columns */
  ENUM_vumark_status_by_pk?: Maybe<Enum_Vumark_Status>;
  /** fetch data from the table: "ENUM_vumark_type" */
  ENUM_vumark_type: Array<Enum_Vumark_Type>;
  /** fetch aggregated fields from the table: "ENUM_vumark_type" */
  ENUM_vumark_type_aggregate: Enum_Vumark_Type_Aggregate;
  /** fetch data from the table: "ENUM_vumark_type" using primary key columns */
  ENUM_vumark_type_by_pk?: Maybe<Enum_Vumark_Type>;
  /** fetch data from the table: "Edition" */
  Edition: Array<Edition>;
  /** fetch data from the table: "Edition_By_Size" */
  Edition_By_Size: Array<Edition_By_Size>;
  /** fetch aggregated fields from the table: "Edition_By_Size" */
  Edition_By_Size_aggregate: Edition_By_Size_Aggregate;
  /** fetch data from the table: "Edition_By_Size" using primary key columns */
  Edition_By_Size_by_pk?: Maybe<Edition_By_Size>;
  /** fetch data from the table: "Edition_By_Type" */
  Edition_By_Type: Array<Edition_By_Type>;
  /** fetch aggregated fields from the table: "Edition_By_Type" */
  Edition_By_Type_aggregate: Edition_By_Type_Aggregate;
  /** fetch data from the table: "Edition_By_Type" using primary key columns */
  Edition_By_Type_by_pk?: Maybe<Edition_By_Type>;
  /** fetch data from the table: "Edition_Type" */
  Edition_Type: Array<Edition_Type>;
  /** fetch aggregated fields from the table: "Edition_Type" */
  Edition_Type_aggregate: Edition_Type_Aggregate;
  /** fetch data from the table: "Edition_Type" using primary key columns */
  Edition_Type_by_pk?: Maybe<Edition_Type>;
  /** fetch aggregated fields from the table: "Edition" */
  Edition_aggregate: Edition_Aggregate;
  /** fetch data from the table: "Edition" using primary key columns */
  Edition_by_pk?: Maybe<Edition>;
  /** fetch data from the table: "Edition_events" */
  Edition_events: Array<Edition_Events>;
  /** fetch aggregated fields from the table: "Edition_events" */
  Edition_events_aggregate: Edition_Events_Aggregate;
  /** fetch data from the table: "Edition_events" using primary key columns */
  Edition_events_by_pk?: Maybe<Edition_Events>;
  /** fetch data from the table: "Email_validation_token" */
  Email_validation_token: Array<Email_Validation_Token>;
  /** fetch aggregated fields from the table: "Email_validation_token" */
  Email_validation_token_aggregate: Email_Validation_Token_Aggregate;
  /** fetch data from the table: "Email_validation_token" using primary key columns */
  Email_validation_token_by_pk?: Maybe<Email_Validation_Token>;
  /** fetch data from the table: "Emails" */
  Emails: Array<Emails>;
  /** fetch aggregated fields from the table: "Emails" */
  Emails_aggregate: Emails_Aggregate;
  /** fetch data from the table: "Emails" using primary key columns */
  Emails_by_pk?: Maybe<Emails>;
  /** fetch data from the table: "Explore_artists" */
  Explore_artists: Array<Explore_Artists>;
  /** fetch aggregated fields from the table: "Explore_artists" */
  Explore_artists_aggregate: Explore_Artists_Aggregate;
  /** fetch data from the table: "Explore_artists_artworks" */
  Explore_artists_artworks: Array<Explore_Artists_Artworks>;
  /** fetch aggregated fields from the table: "Explore_artists_artworks" */
  Explore_artists_artworks_aggregate: Explore_Artists_Artworks_Aggregate;
  /** fetch data from the table: "Explore_artists_curation_score" */
  Explore_artists_curation_score: Array<Explore_Artists_Curation_Score>;
  /** fetch aggregated fields from the table: "Explore_artists_curation_score" */
  Explore_artists_curation_score_aggregate: Explore_Artists_Curation_Score_Aggregate;
  /** fetch data from the table: "Explore_artists_disciplines" */
  Explore_artists_disciplines: Array<Explore_Artists_Disciplines>;
  /** fetch aggregated fields from the table: "Explore_artists_disciplines" */
  Explore_artists_disciplines_aggregate: Explore_Artists_Disciplines_Aggregate;
  /** fetch data from the table: "FCM_Token" */
  FCM_Token: Array<Fcm_Token>;
  /** fetch aggregated fields from the table: "FCM_Token" */
  FCM_Token_aggregate: Fcm_Token_Aggregate;
  /** fetch data from the table: "FCM_Token" using primary key columns */
  FCM_Token_by_pk?: Maybe<Fcm_Token>;
  /** fetch data from the table: "Features" */
  Features: Array<Features>;
  /** fetch aggregated fields from the table: "Features" */
  Features_aggregate: Features_Aggregate;
  /** fetch data from the table: "Features" using primary key columns */
  Features_by_pk?: Maybe<Features>;
  /** An array relationship */
  File_metadata: Array<File_Metadata>;
  /** An aggregate relationship */
  File_metadata_aggregate: File_Metadata_Aggregate;
  /** fetch data from the table: "File_metadata" using primary key columns */
  File_metadata_by_pk?: Maybe<File_Metadata>;
  /** fetch data from the table: "Firebase_profile" */
  Firebase_profile: Array<Firebase_Profile>;
  /** fetch aggregated fields from the table: "Firebase_profile" */
  Firebase_profile_aggregate: Firebase_Profile_Aggregate;
  /** fetch data from the table: "Firebase_profile" using primary key columns */
  Firebase_profile_by_pk?: Maybe<Firebase_Profile>;
  /** fetch data from the table: "Flagged_users" */
  Flagged_users: Array<Flagged_Users>;
  /** fetch aggregated fields from the table: "Flagged_users" */
  Flagged_users_aggregate: Flagged_Users_Aggregate;
  /** fetch data from the table: "Flagged_users" using primary key columns */
  Flagged_users_by_pk?: Maybe<Flagged_Users>;
  /** fetch data from the table: "Flat_context_access" */
  Flat_context_access: Array<Flat_Context_Access>;
  /** fetch aggregated fields from the table: "Flat_context_access" */
  Flat_context_access_aggregate: Flat_Context_Access_Aggregate;
  /** fetch data from the table: "Following_artists_art_universe_listing" */
  Following_artists_art_universe_listing: Array<Following_Artists_Art_Universe_Listing>;
  /** fetch aggregated fields from the table: "Following_artists_art_universe_listing" */
  Following_artists_art_universe_listing_aggregate: Following_Artists_Art_Universe_Listing_Aggregate;
  /** fetch data from the table: "Forge_InProcess_Task" */
  Forge_InProcess_Task: Array<Forge_InProcess_Task>;
  /** fetch aggregated fields from the table: "Forge_InProcess_Task" */
  Forge_InProcess_Task_aggregate: Forge_InProcess_Task_Aggregate;
  /** fetch data from the table: "Forge_InProcess_Task" using primary key columns */
  Forge_InProcess_Task_by_pk?: Maybe<Forge_InProcess_Task>;
  /** fetch data from the table: "Forge_tasks" */
  Forge_tasks: Array<Forge_Tasks>;
  /** fetch aggregated fields from the table: "Forge_tasks" */
  Forge_tasks_aggregate: Forge_Tasks_Aggregate;
  /** fetch data from the table: "Forge_tasks" using primary key columns */
  Forge_tasks_by_pk?: Maybe<Forge_Tasks>;
  /** fetch data from the table: "Gallery_explore_info" */
  Gallery_explore_info: Array<Gallery_Explore_Info>;
  /** fetch aggregated fields from the table: "Gallery_explore_info" */
  Gallery_explore_info_aggregate: Gallery_Explore_Info_Aggregate;
  /** fetch data from the table: "Gallery_explore_info_with_city_data" */
  Gallery_explore_info_with_city_data: Array<Gallery_Explore_Info_With_City_Data>;
  /** fetch aggregated fields from the table: "Gallery_explore_info_with_city_data" */
  Gallery_explore_info_with_city_data_aggregate: Gallery_Explore_Info_With_City_Data_Aggregate;
  /** fetch data from the table: "Global_properties" */
  Global_properties: Array<Global_Properties>;
  /** fetch aggregated fields from the table: "Global_properties" */
  Global_properties_aggregate: Global_Properties_Aggregate;
  /** fetch data from the table: "Global_properties" using primary key columns */
  Global_properties_by_pk?: Maybe<Global_Properties>;
  /** fetch data from the table: "Graveyard_Artwork" */
  Graveyard_Artwork: Array<Graveyard_Artwork>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork" */
  Graveyard_Artwork_aggregate: Graveyard_Artwork_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork" using primary key columns */
  Graveyard_Artwork_by_pk?: Maybe<Graveyard_Artwork>;
  /** fetch data from the table: "Graveyard_Artwork_details" */
  Graveyard_Artwork_details: Array<Graveyard_Artwork_Details>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_details" */
  Graveyard_Artwork_details_aggregate: Graveyard_Artwork_Details_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_details" using primary key columns */
  Graveyard_Artwork_details_by_pk?: Maybe<Graveyard_Artwork_Details>;
  /** fetch data from the table: "Graveyard_Artwork_details_translation" */
  Graveyard_Artwork_details_translation: Array<Graveyard_Artwork_Details_Translation>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_details_translation" */
  Graveyard_Artwork_details_translation_aggregate: Graveyard_Artwork_Details_Translation_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_details_translation" using primary key columns */
  Graveyard_Artwork_details_translation_by_pk?: Maybe<Graveyard_Artwork_Details_Translation>;
  /** fetch data from the table: "Graveyard_Artwork_events" */
  Graveyard_Artwork_events: Array<Graveyard_Artwork_Events>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_events" */
  Graveyard_Artwork_events_aggregate: Graveyard_Artwork_Events_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_events" using primary key columns */
  Graveyard_Artwork_events_by_pk?: Maybe<Graveyard_Artwork_Events>;
  /** fetch data from the table: "Graveyard_Artwork_prices" */
  Graveyard_Artwork_prices: Array<Graveyard_Artwork_Prices>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_prices" */
  Graveyard_Artwork_prices_aggregate: Graveyard_Artwork_Prices_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_prices" using primary key columns */
  Graveyard_Artwork_prices_by_pk?: Maybe<Graveyard_Artwork_Prices>;
  /** fetch data from the table: "Graveyard_Artwork_secondary_media" */
  Graveyard_Artwork_secondary_media: Array<Graveyard_Artwork_Secondary_Media>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_secondary_media" */
  Graveyard_Artwork_secondary_media_aggregate: Graveyard_Artwork_Secondary_Media_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_secondary_media" using primary key columns */
  Graveyard_Artwork_secondary_media_by_pk?: Maybe<Graveyard_Artwork_Secondary_Media>;
  /** fetch data from the table: "Graveyard_Artwork_story" */
  Graveyard_Artwork_story: Array<Graveyard_Artwork_Story>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_story" */
  Graveyard_Artwork_story_aggregate: Graveyard_Artwork_Story_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_story" using primary key columns */
  Graveyard_Artwork_story_by_pk?: Maybe<Graveyard_Artwork_Story>;
  /** fetch data from the table: "Graveyard_Artwork_story_media" */
  Graveyard_Artwork_story_media: Array<Graveyard_Artwork_Story_Media>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_story_media" */
  Graveyard_Artwork_story_media_aggregate: Graveyard_Artwork_Story_Media_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_story_media" using primary key columns */
  Graveyard_Artwork_story_media_by_pk?: Maybe<Graveyard_Artwork_Story_Media>;
  /** fetch data from the table: "Graveyard_Artwork_to_Collection" */
  Graveyard_Artwork_to_Collection: Array<Graveyard_Artwork_To_Collection>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_to_Collection" */
  Graveyard_Artwork_to_Collection_aggregate: Graveyard_Artwork_To_Collection_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_to_Collection" using primary key columns */
  Graveyard_Artwork_to_Collection_by_pk?: Maybe<Graveyard_Artwork_To_Collection>;
  /** fetch data from the table: "Graveyard_Artwork_transactions" */
  Graveyard_Artwork_transactions: Array<Graveyard_Artwork_Transactions>;
  /** fetch aggregated fields from the table: "Graveyard_Artwork_transactions" */
  Graveyard_Artwork_transactions_aggregate: Graveyard_Artwork_Transactions_Aggregate;
  /** fetch data from the table: "Graveyard_Artwork_transactions" using primary key columns */
  Graveyard_Artwork_transactions_by_pk?: Maybe<Graveyard_Artwork_Transactions>;
  /** fetch data from the table: "Graveyard_Purchase_inquires" */
  Graveyard_Purchase_inquires: Array<Graveyard_Purchase_Inquires>;
  /** fetch aggregated fields from the table: "Graveyard_Purchase_inquires" */
  Graveyard_Purchase_inquires_aggregate: Graveyard_Purchase_Inquires_Aggregate;
  /** fetch data from the table: "Graveyard_Purchase_inquires_outside_users" */
  Graveyard_Purchase_inquires_outside_users: Array<Graveyard_Purchase_Inquires_Outside_Users>;
  /** fetch aggregated fields from the table: "Graveyard_Purchase_inquires_outside_users" */
  Graveyard_Purchase_inquires_outside_users_aggregate: Graveyard_Purchase_Inquires_Outside_Users_Aggregate;
  /** fetch data from the table: "Inconsistent_missing_domain_renewal_subscription" */
  Inconsistent_missing_domain_renewal_subscription: Array<Inconsistent_Missing_Domain_Renewal_Subscription>;
  /** fetch aggregated fields from the table: "Inconsistent_missing_domain_renewal_subscription" */
  Inconsistent_missing_domain_renewal_subscription_aggregate: Inconsistent_Missing_Domain_Renewal_Subscription_Aggregate;
  /** An array relationship */
  Interests: Array<Interests>;
  /** An aggregate relationship */
  Interests_aggregate: Interests_Aggregate;
  /** fetch data from the table: "Interests" using primary key columns */
  Interests_by_pk?: Maybe<Interests>;
  /** fetch data from the table: "Kaleido_general_settings" */
  Kaleido_general_settings: Array<Kaleido_General_Settings>;
  /** fetch aggregated fields from the table: "Kaleido_general_settings" */
  Kaleido_general_settings_aggregate: Kaleido_General_Settings_Aggregate;
  /** fetch data from the table: "Kaleido_general_settings" using primary key columns */
  Kaleido_general_settings_by_pk?: Maybe<Kaleido_General_Settings>;
  /** fetch data from the table: "Kaleido_live_listing" */
  Kaleido_live_listing: Array<Kaleido_Live_Listing>;
  /** fetch aggregated fields from the table: "Kaleido_live_listing" */
  Kaleido_live_listing_aggregate: Kaleido_Live_Listing_Aggregate;
  /** fetch data from the table: "Landing_page_artwork" */
  Landing_page_artwork: Array<Landing_Page_Artwork>;
  /** fetch aggregated fields from the table: "Landing_page_artwork" */
  Landing_page_artwork_aggregate: Landing_Page_Artwork_Aggregate;
  /** fetch data from the table: "Landing_page_artwork" using primary key columns */
  Landing_page_artwork_by_pk?: Maybe<Landing_Page_Artwork>;
  /** fetch data from the table: "Languages" */
  Languages: Array<Languages>;
  /** fetch aggregated fields from the table: "Languages" */
  Languages_aggregate: Languages_Aggregate;
  /** fetch data from the table: "Languages" using primary key columns */
  Languages_by_pk?: Maybe<Languages>;
  /** fetch data from the table: "Materialized_view" */
  Materialized_view: Array<Materialized_View>;
  /** fetch aggregated fields from the table: "Materialized_view" */
  Materialized_view_aggregate: Materialized_View_Aggregate;
  /** fetch data from the table: "Materialized_view" using primary key columns */
  Materialized_view_by_pk?: Maybe<Materialized_View>;
  /** fetch data from the table: "Media_metadata" */
  Media_metadata: Array<Media_Metadata>;
  /** fetch aggregated fields from the table: "Media_metadata" */
  Media_metadata_aggregate: Media_Metadata_Aggregate;
  /** fetch data from the table: "Media_metadata" using primary key columns */
  Media_metadata_by_pk?: Maybe<Media_Metadata>;
  /** fetch data from the table: "Milestone" */
  Milestone: Array<Milestone>;
  /** fetch aggregated fields from the table: "Milestone" */
  Milestone_aggregate: Milestone_Aggregate;
  /** fetch data from the table: "Milestone" using primary key columns */
  Milestone_by_pk?: Maybe<Milestone>;
  /** fetch data from the table: "Museum_artwork_listing" */
  Museum_artwork_listing: Array<Museum_Artwork_Listing>;
  /** fetch aggregated fields from the table: "Museum_artwork_listing" */
  Museum_artwork_listing_aggregate: Museum_Artwork_Listing_Aggregate;
  /** fetch data from the table: "MyStudio_subscriptors" */
  MyStudio_subscriptors: Array<MyStudio_Subscriptors>;
  /** fetch aggregated fields from the table: "MyStudio_subscriptors" */
  MyStudio_subscriptors_aggregate: MyStudio_Subscriptors_Aggregate;
  /** An array relationship */
  My_Website_Settings: Array<My_Website_Settings>;
  /** An aggregate relationship */
  My_Website_Settings_aggregate: My_Website_Settings_Aggregate;
  /** fetch data from the table: "My_Website_Settings" using primary key columns */
  My_Website_Settings_by_pk?: Maybe<My_Website_Settings>;
  /** fetch data from the table: "My_studio_settings" */
  My_studio_settings: Array<My_Studio_Settings>;
  /** fetch aggregated fields from the table: "My_studio_settings" */
  My_studio_settings_aggregate: My_Studio_Settings_Aggregate;
  /** fetch data from the table: "My_studio_settings" using primary key columns */
  My_studio_settings_by_pk?: Maybe<My_Studio_Settings>;
  /** fetch data from the table: "Notification_preference" */
  Notification_preference: Array<Notification_Preference>;
  /** fetch aggregated fields from the table: "Notification_preference" */
  Notification_preference_aggregate: Notification_Preference_Aggregate;
  /** fetch data from the table: "Notification_preference" using primary key columns */
  Notification_preference_by_pk?: Maybe<Notification_Preference>;
  /** fetch data from the table: "Pending_collaboration_on_artwork" */
  Pending_collaboration_on_artwork: Array<Pending_Collaboration_On_Artwork>;
  /** fetch aggregated fields from the table: "Pending_collaboration_on_artwork" */
  Pending_collaboration_on_artwork_aggregate: Pending_Collaboration_On_Artwork_Aggregate;
  /** fetch data from the table: "Pending_collaboration_on_artwork" using primary key columns */
  Pending_collaboration_on_artwork_by_pk?: Maybe<Pending_Collaboration_On_Artwork>;
  /** fetch data from the table: "Price_Ranges" */
  Price_Ranges: Array<Price_Ranges>;
  /** fetch aggregated fields from the table: "Price_Ranges" */
  Price_Ranges_aggregate: Price_Ranges_Aggregate;
  /** fetch data from the table: "Price_Ranges" using primary key columns */
  Price_Ranges_by_pk?: Maybe<Price_Ranges>;
  /** fetch data from the table: "Print" */
  Print: Array<Print>;
  /** fetch aggregated fields from the table: "Print" */
  Print_aggregate: Print_Aggregate;
  /** An array relationship */
  Print_amounts: Array<Print_Amounts>;
  /** An aggregate relationship */
  Print_amounts_aggregate: Print_Amounts_Aggregate;
  /** fetch data from the table: "Print_artwork_collector_listing" */
  Print_artwork_collector_listing: Array<Print_Artwork_Collector_Listing>;
  /** fetch aggregated fields from the table: "Print_artwork_collector_listing" */
  Print_artwork_collector_listing_aggregate: Print_Artwork_Collector_Listing_Aggregate;
  /** fetch data from the table: "Print_artwork_info" */
  Print_artwork_info: Array<Print_Artwork_Info>;
  /** fetch aggregated fields from the table: "Print_artwork_info" */
  Print_artwork_info_aggregate: Print_Artwork_Info_Aggregate;
  /** fetch data from the table: "Print_artwork_view" */
  Print_artwork_view: Array<Print_Artwork_View>;
  /** fetch aggregated fields from the table: "Print_artwork_view" */
  Print_artwork_view_aggregate: Print_Artwork_View_Aggregate;
  /** fetch data from the table: "Print" using primary key columns */
  Print_by_pk?: Maybe<Print>;
  /** fetch data from the table: "Print_to_Collection" */
  Print_to_Collection: Array<Print_To_Collection>;
  /** fetch aggregated fields from the table: "Print_to_Collection" */
  Print_to_Collection_aggregate: Print_To_Collection_Aggregate;
  /** fetch data from the table: "Print_to_Collection" using primary key columns */
  Print_to_Collection_by_pk?: Maybe<Print_To_Collection>;
  /** fetch data from the table: "Prize_Art_Show" */
  Prize_Art_Show: Array<Prize_Art_Show>;
  /** fetch aggregated fields from the table: "Prize_Art_Show" */
  Prize_Art_Show_aggregate: Prize_Art_Show_Aggregate;
  /** fetch data from the table: "Prize_Art_Show" using primary key columns */
  Prize_Art_Show_by_pk?: Maybe<Prize_Art_Show>;
  /** fetch data from the table: "Profile" */
  Profile: Array<Profile>;
  /** fetch data from the table: "Profile_Interests" */
  Profile_Interests: Array<Profile_Interests>;
  /** fetch aggregated fields from the table: "Profile_Interests" */
  Profile_Interests_aggregate: Profile_Interests_Aggregate;
  /** fetch data from the table: "Profile_Interests" using primary key columns */
  Profile_Interests_by_pk?: Maybe<Profile_Interests>;
  /** fetch data from the table: "Profile_achievements" */
  Profile_achievements: Array<Profile_Achievements>;
  /** fetch aggregated fields from the table: "Profile_achievements" */
  Profile_achievements_aggregate: Profile_Achievements_Aggregate;
  /** fetch data from the table: "Profile_achievements" using primary key columns */
  Profile_achievements_by_pk?: Maybe<Profile_Achievements>;
  /** fetch aggregated fields from the table: "Profile" */
  Profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "Profile_appearance_settings" */
  Profile_appearance_settings: Array<Profile_Appearance_Settings>;
  /** fetch aggregated fields from the table: "Profile_appearance_settings" */
  Profile_appearance_settings_aggregate: Profile_Appearance_Settings_Aggregate;
  /** fetch data from the table: "Profile_appearance_settings" using primary key columns */
  Profile_appearance_settings_by_pk?: Maybe<Profile_Appearance_Settings>;
  /** fetch data from the table: "Profile_background" */
  Profile_background: Array<Profile_Background>;
  /** fetch aggregated fields from the table: "Profile_background" */
  Profile_background_aggregate: Profile_Background_Aggregate;
  /** fetch data from the table: "Profile_background" using primary key columns */
  Profile_background_by_pk?: Maybe<Profile_Background>;
  /** fetch data from the table: "Profile" using primary key columns */
  Profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "Profile_ghost_tokens" */
  Profile_ghost_tokens: Array<Profile_Ghost_Tokens>;
  /** fetch aggregated fields from the table: "Profile_ghost_tokens" */
  Profile_ghost_tokens_aggregate: Profile_Ghost_Tokens_Aggregate;
  /** fetch data from the table: "Profile_ghost_tokens" using primary key columns */
  Profile_ghost_tokens_by_pk?: Maybe<Profile_Ghost_Tokens>;
  /** fetch data from the table: "Profile_invitations" */
  Profile_invitations: Array<Profile_Invitations>;
  /** fetch aggregated fields from the table: "Profile_invitations" */
  Profile_invitations_aggregate: Profile_Invitations_Aggregate;
  /** fetch data from the table: "Profile_invitations" using primary key columns */
  Profile_invitations_by_pk?: Maybe<Profile_Invitations>;
  /** fetch data from the table: "Profile_location" */
  Profile_location: Array<Profile_Location>;
  /** fetch aggregated fields from the table: "Profile_location" */
  Profile_location_aggregate: Profile_Location_Aggregate;
  /** fetch data from the table: "Profile_location" using primary key columns */
  Profile_location_by_pk?: Maybe<Profile_Location>;
  /** fetch data from the table: "Profile_location_translation" */
  Profile_location_translation: Array<Profile_Location_Translation>;
  /** fetch aggregated fields from the table: "Profile_location_translation" */
  Profile_location_translation_aggregate: Profile_Location_Translation_Aggregate;
  /** fetch data from the table: "Profile_location_translation" using primary key columns */
  Profile_location_translation_by_pk?: Maybe<Profile_Location_Translation>;
  /** fetch data from the table: "Profile_price_ranges" */
  Profile_price_ranges: Array<Profile_Price_Ranges>;
  /** fetch aggregated fields from the table: "Profile_price_ranges" */
  Profile_price_ranges_aggregate: Profile_Price_Ranges_Aggregate;
  /** fetch data from the table: "Profile_price_ranges" using primary key columns */
  Profile_price_ranges_by_pk?: Maybe<Profile_Price_Ranges>;
  /** fetch data from the table: "Profile_questions_and_answers" */
  Profile_questions_and_answers: Array<Profile_Questions_And_Answers>;
  /** fetch aggregated fields from the table: "Profile_questions_and_answers" */
  Profile_questions_and_answers_aggregate: Profile_Questions_And_Answers_Aggregate;
  /** fetch data from the table: "Profile_questions_and_answers" using primary key columns */
  Profile_questions_and_answers_by_pk?: Maybe<Profile_Questions_And_Answers>;
  /** fetch data from the table: "Profile_settings" */
  Profile_settings: Array<Profile_Settings>;
  /** fetch aggregated fields from the table: "Profile_settings" */
  Profile_settings_aggregate: Profile_Settings_Aggregate;
  /** fetch data from the table: "Profile_settings" using primary key columns */
  Profile_settings_by_pk?: Maybe<Profile_Settings>;
  /** fetch data from the table: "Profile_social_links" */
  Profile_social_links: Array<Profile_Social_Links>;
  /** fetch aggregated fields from the table: "Profile_social_links" */
  Profile_social_links_aggregate: Profile_Social_Links_Aggregate;
  /** fetch data from the table: "Profile_social_links" using primary key columns */
  Profile_social_links_by_pk?: Maybe<Profile_Social_Links>;
  /** fetch data from the table: "Profile_statement_audio_syntheses" */
  Profile_statement_audio_syntheses: Array<Profile_Statement_Audio_Syntheses>;
  /** fetch aggregated fields from the table: "Profile_statement_audio_syntheses" */
  Profile_statement_audio_syntheses_aggregate: Profile_Statement_Audio_Syntheses_Aggregate;
  /** fetch data from the table: "Profile_statement_audio_syntheses" using primary key columns */
  Profile_statement_audio_syntheses_by_pk?: Maybe<Profile_Statement_Audio_Syntheses>;
  /** fetch data from the table: "Profile_translation" */
  Profile_translation: Array<Profile_Translation>;
  /** fetch aggregated fields from the table: "Profile_translation" */
  Profile_translation_aggregate: Profile_Translation_Aggregate;
  /** fetch data from the table: "Profile_translation" using primary key columns */
  Profile_translation_by_pk?: Maybe<Profile_Translation>;
  /** fetch data from the table: "Profile_video_links" */
  Profile_video_links: Array<Profile_Video_Links>;
  /** fetch aggregated fields from the table: "Profile_video_links" */
  Profile_video_links_aggregate: Profile_Video_Links_Aggregate;
  /** fetch data from the table: "Profile_video_links" using primary key columns */
  Profile_video_links_by_pk?: Maybe<Profile_Video_Links>;
  /** fetch data from the table: "Profile_wallet_address" */
  Profile_wallet_address: Array<Profile_Wallet_Address>;
  /** fetch aggregated fields from the table: "Profile_wallet_address" */
  Profile_wallet_address_aggregate: Profile_Wallet_Address_Aggregate;
  /** fetch data from the table: "Profile_wallet_address" using primary key columns */
  Profile_wallet_address_by_pk?: Maybe<Profile_Wallet_Address>;
  /** fetch data from the table: "Pubnub_channels" */
  Pubnub_channels: Array<Pubnub_Channels>;
  /** fetch aggregated fields from the table: "Pubnub_channels" */
  Pubnub_channels_aggregate: Pubnub_Channels_Aggregate;
  /** fetch data from the table: "Pubnub_channels" using primary key columns */
  Pubnub_channels_by_pk?: Maybe<Pubnub_Channels>;
  /** fetch data from the table: "Purchase_inquires" */
  Purchase_inquires: Array<Purchase_Inquires>;
  /** fetch aggregated fields from the table: "Purchase_inquires" */
  Purchase_inquires_aggregate: Purchase_Inquires_Aggregate;
  /** fetch data from the table: "Purchase_inquires" using primary key columns */
  Purchase_inquires_by_pk?: Maybe<Purchase_Inquires>;
  /** fetch data from the table: "Purchase_inquires_outside_users" */
  Purchase_inquires_outside_users: Array<Purchase_Inquires_Outside_Users>;
  /** fetch aggregated fields from the table: "Purchase_inquires_outside_users" */
  Purchase_inquires_outside_users_aggregate: Purchase_Inquires_Outside_Users_Aggregate;
  /** fetch data from the table: "Purchase_inquires_outside_users" using primary key columns */
  Purchase_inquires_outside_users_by_pk?: Maybe<Purchase_Inquires_Outside_Users>;
  /** fetch data from the table: "Push_notifications" */
  Push_notifications: Array<Push_Notifications>;
  /** fetch aggregated fields from the table: "Push_notifications" */
  Push_notifications_aggregate: Push_Notifications_Aggregate;
  /** fetch data from the table: "Push_notifications" using primary key columns */
  Push_notifications_by_pk?: Maybe<Push_Notifications>;
  /** fetch data from the table: "Questionnaire_questions" */
  Questionnaire_questions: Array<Questionnaire_Questions>;
  /** fetch aggregated fields from the table: "Questionnaire_questions" */
  Questionnaire_questions_aggregate: Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "Questionnaire_questions" using primary key columns */
  Questionnaire_questions_by_pk?: Maybe<Questionnaire_Questions>;
  /** fetch data from the table: "Render_request" */
  Render_request: Array<Render_Request>;
  /** fetch aggregated fields from the table: "Render_request" */
  Render_request_aggregate: Render_Request_Aggregate;
  /** fetch data from the table: "Render_request" using primary key columns */
  Render_request_by_pk?: Maybe<Render_Request>;
  /** fetch data from the table: "Setting_context_default_profile" */
  Setting_context_default_profile: Array<Setting_Context_Default_Profile>;
  /** fetch aggregated fields from the table: "Setting_context_default_profile" */
  Setting_context_default_profile_aggregate: Setting_Context_Default_Profile_Aggregate;
  /** fetch data from the table: "Setting_context_default_profile" using primary key columns */
  Setting_context_default_profile_by_pk?: Maybe<Setting_Context_Default_Profile>;
  /** fetch data from the table: "Showroom_details" */
  Showroom_details: Array<Showroom_Details>;
  /** fetch aggregated fields from the table: "Showroom_details" */
  Showroom_details_aggregate: Showroom_Details_Aggregate;
  /** fetch data from the table: "Showroom_details" using primary key columns */
  Showroom_details_by_pk?: Maybe<Showroom_Details>;
  /** fetch data from the table: "Social_Graph_Target_Context_view" */
  Social_Graph_Target_Context_view: Array<Social_Graph_Target_Context_View>;
  /** fetch aggregated fields from the table: "Social_Graph_Target_Context_view" */
  Social_Graph_Target_Context_view_aggregate: Social_Graph_Target_Context_View_Aggregate;
  /** fetch data from the table: "Social_graph_followers" */
  Social_graph_followers: Array<Social_Graph_Followers>;
  /** fetch aggregated fields from the table: "Social_graph_followers" */
  Social_graph_followers_aggregate: Social_Graph_Followers_Aggregate;
  /** fetch data from the table: "Social_graph_followers" using primary key columns */
  Social_graph_followers_by_pk?: Maybe<Social_Graph_Followers>;
  /** fetch data from the table: "Social_graph_followers_view" */
  Social_graph_followers_view: Array<Social_Graph_Followers_View>;
  /** fetch aggregated fields from the table: "Social_graph_followers_view" */
  Social_graph_followers_view_aggregate: Social_Graph_Followers_View_Aggregate;
  /** fetch data from the table: "Spotlight_artist" */
  Spotlight_artist: Array<Spotlight_Artist>;
  /** fetch aggregated fields from the table: "Spotlight_artist" */
  Spotlight_artist_aggregate: Spotlight_Artist_Aggregate;
  /** fetch data from the table: "Spotlight_context" */
  Spotlight_context: Array<Spotlight_Context>;
  /** fetch aggregated fields from the table: "Spotlight_context" */
  Spotlight_context_aggregate: Spotlight_Context_Aggregate;
  /** fetch data from the table: "Spotlight_context" using primary key columns */
  Spotlight_context_by_pk?: Maybe<Spotlight_Context>;
  /** fetch data from the table: "Super_site_subscribers" */
  Super_site_subscribers: Array<Super_Site_Subscribers>;
  /** fetch aggregated fields from the table: "Super_site_subscribers" */
  Super_site_subscribers_aggregate: Super_Site_Subscribers_Aggregate;
  /** fetch data from the table: "Super_site_subscribers" using primary key columns */
  Super_site_subscribers_by_pk?: Maybe<Super_Site_Subscribers>;
  /** fetch data from the table: "Tags_by_interest" */
  Tags_by_interest: Array<Tags_By_Interest>;
  /** fetch aggregated fields from the table: "Tags_by_interest" */
  Tags_by_interest_aggregate: Tags_By_Interest_Aggregate;
  /** fetch data from the table: "Tags_by_interest" using primary key columns */
  Tags_by_interest_by_pk?: Maybe<Tags_By_Interest>;
  /** fetch data from the table: "Temp_artist_explore_collection" */
  Temp_artist_explore_collection: Array<Temp_Artist_Explore_Collection>;
  /** fetch aggregated fields from the table: "Temp_artist_explore_collection" */
  Temp_artist_explore_collection_aggregate: Temp_Artist_Explore_Collection_Aggregate;
  /** fetch data from the table: "Test_Analytics_events" */
  Test_Analytics_events: Array<Test_Analytics_Events>;
  /** fetch aggregated fields from the table: "Test_Analytics_events" */
  Test_Analytics_events_aggregate: Test_Analytics_Events_Aggregate;
  /** fetch data from the table: "Test_Analytics_events" using primary key columns */
  Test_Analytics_events_by_pk?: Maybe<Test_Analytics_Events>;
  /** fetch data from the table: "Test_accounts" */
  Test_accounts: Array<Test_Accounts>;
  /** fetch aggregated fields from the table: "Test_accounts" */
  Test_accounts_aggregate: Test_Accounts_Aggregate;
  /** fetch data from the table: "Test_accounts" using primary key columns */
  Test_accounts_by_pk?: Maybe<Test_Accounts>;
  /** fetch data from the table: "Timed_event" */
  Timed_event: Array<Timed_Event>;
  /** fetch aggregated fields from the table: "Timed_event" */
  Timed_event_aggregate: Timed_Event_Aggregate;
  /** fetch data from the table: "Timed_event" using primary key columns */
  Timed_event_by_pk?: Maybe<Timed_Event>;
  /** fetch data from the table: "Token_Storage" */
  Token_Storage: Array<Token_Storage>;
  /** fetch aggregated fields from the table: "Token_Storage" */
  Token_Storage_aggregate: Token_Storage_Aggregate;
  /** fetch data from the table: "Token_Storage" using primary key columns */
  Token_Storage_by_pk?: Maybe<Token_Storage>;
  /** fetch data from the table: "Tutorial" */
  Tutorial: Array<Tutorial>;
  /** fetch aggregated fields from the table: "Tutorial" */
  Tutorial_aggregate: Tutorial_Aggregate;
  /** fetch data from the table: "Tutorial" using primary key columns */
  Tutorial_by_pk?: Maybe<Tutorial>;
  /** fetch data from the table: "Tutorial_step" */
  Tutorial_step: Array<Tutorial_Step>;
  /** fetch aggregated fields from the table: "Tutorial_step" */
  Tutorial_step_aggregate: Tutorial_Step_Aggregate;
  /** fetch data from the table: "Tutorial_step" using primary key columns */
  Tutorial_step_by_pk?: Maybe<Tutorial_Step>;
  /** fetch data from the table: "User_analytics_points_index" */
  User_analytics_points_index: Array<User_Analytics_Points_Index>;
  /** fetch aggregated fields from the table: "User_analytics_points_index" */
  User_analytics_points_index_aggregate: User_Analytics_Points_Index_Aggregate;
  /** fetch data from the table: "User_analytics_points_index" using primary key columns */
  User_analytics_points_index_by_pk?: Maybe<User_Analytics_Points_Index>;
  /** fetch data from the table: "VR_artists" */
  VR_artists: Array<Vr_Artists>;
  /** fetch aggregated fields from the table: "VR_artists" */
  VR_artists_aggregate: Vr_Artists_Aggregate;
  /** fetch data from the table: "VR_artists" using primary key columns */
  VR_artists_by_pk?: Maybe<Vr_Artists>;
  /** An array relationship */
  Valid_profile_questions_and_answers_view: Array<Valid_Profile_Questions_And_Answers_View>;
  /** An aggregate relationship */
  Valid_profile_questions_and_answers_view_aggregate: Valid_Profile_Questions_And_Answers_View_Aggregate;
  /** fetch data from the table: "Valid_profile_statement_audio_syntheses_view" */
  Valid_profile_statement_audio_syntheses_view: Array<Valid_Profile_Statement_Audio_Syntheses_View>;
  /** fetch aggregated fields from the table: "Valid_profile_statement_audio_syntheses_view" */
  Valid_profile_statement_audio_syntheses_view_aggregate: Valid_Profile_Statement_Audio_Syntheses_View_Aggregate;
  /** fetch data from the table: "Vision_active_interests" */
  Vision_active_interests: Array<Vision_Active_Interests>;
  /** fetch aggregated fields from the table: "Vision_active_interests" */
  Vision_active_interests_aggregate: Vision_Active_Interests_Aggregate;
  /** fetch data from the table: "Vision_interest" */
  Vision_interest: Array<Vision_Interest>;
  /** fetch aggregated fields from the table: "Vision_interest" */
  Vision_interest_aggregate: Vision_Interest_Aggregate;
  /** fetch data from the table: "Vision_interest" using primary key columns */
  Vision_interest_by_pk?: Maybe<Vision_Interest>;
  /** fetch data from the table: "Vumarks" */
  Vumarks: Array<Vumarks>;
  /** fetch aggregated fields from the table: "Vumarks" */
  Vumarks_aggregate: Vumarks_Aggregate;
  /** fetch data from the table: "Vumarks" using primary key columns */
  Vumarks_by_pk?: Maybe<Vumarks>;
  /** fetch data from the table: "Waiting_list" */
  Waiting_list: Array<Waiting_List>;
  /** fetch aggregated fields from the table: "Waiting_list" */
  Waiting_list_aggregate: Waiting_List_Aggregate;
  /** fetch data from the table: "Waiting_list" using primary key columns */
  Waiting_list_by_pk?: Maybe<Waiting_List>;
  /** An array relationship */
  Wallets: Array<Wallets>;
  /** An aggregate relationship */
  Wallets_aggregate: Wallets_Aggregate;
  /** fetch data from the table: "Wallets" using primary key columns */
  Wallets_by_pk?: Maybe<Wallets>;
  /** fetch data from the table: "World_cities" */
  World_cities: Array<World_Cities>;
  /** fetch aggregated fields from the table: "World_cities" */
  World_cities_aggregate: World_Cities_Aggregate;
  /** fetch data from the table: "World_cities" using primary key columns */
  World_cities_by_pk?: Maybe<World_Cities>;
  /** fetch data from the table: "app_notifications_info" */
  app_notifications_info: Array<App_Notifications_Info>;
  /** fetch aggregated fields from the table: "app_notifications_info" */
  app_notifications_info_aggregate: App_Notifications_Info_Aggregate;
  /** fetch data from the table: "app_notifications_listing" */
  app_notifications_listing: Array<App_Notifications_Listing>;
  /** fetch aggregated fields from the table: "app_notifications_listing" */
  app_notifications_listing_aggregate: App_Notifications_Listing_Aggregate;
  /** fetch data from the table: "app_notifications_view" */
  app_notifications_view: Array<App_Notifications_View>;
  /** fetch aggregated fields from the table: "app_notifications_view" */
  app_notifications_view_aggregate: App_Notifications_View_Aggregate;
  /** fetch data from the table: "artist_cardgrid_artworks_view" */
  artist_cardgrid_artworks_view: Array<Artist_Cardgrid_Artworks_View>;
  /** fetch aggregated fields from the table: "artist_cardgrid_artworks_view" */
  artist_cardgrid_artworks_view_aggregate: Artist_Cardgrid_Artworks_View_Aggregate;
  /** fetch data from the table: "artwork_favorite_count" */
  artwork_favorite_count: Array<Artwork_Favorite_Count>;
  /** fetch aggregated fields from the table: "artwork_favorite_count" */
  artwork_favorite_count_aggregate: Artwork_Favorite_Count_Aggregate;
  /** fetch data from the table: "artwork_manager_view" */
  artwork_manager_view: Array<Artwork_Manager_View>;
  /** fetch aggregated fields from the table: "artwork_manager_view" */
  artwork_manager_view_aggregate: Artwork_Manager_View_Aggregate;
  /** fetch data from the table: "artwork_shop_info" */
  artwork_shop_info: Array<Artwork_Shop_Info>;
  /** fetch aggregated fields from the table: "artwork_shop_info" */
  artwork_shop_info_aggregate: Artwork_Shop_Info_Aggregate;
  /** fetch data from the table: "artwork_shop_listing" */
  artwork_shop_listing: Array<Artwork_Shop_Listing>;
  /** fetch aggregated fields from the table: "artwork_shop_listing" */
  artwork_shop_listing_aggregate: Artwork_Shop_Listing_Aggregate;
  /** fetch data from the table: "best_bet_cities_exclude_cities_with_duplicates_mv" */
  best_bet_cities_exclude_cities_with_duplicates_mv: Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv>;
  /** fetch aggregated fields from the table: "best_bet_cities_exclude_cities_with_duplicates_mv" */
  best_bet_cities_exclude_cities_with_duplicates_mv_aggregate: Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Aggregate;
  /** fetch data from the table: "best_bet_cities_mv" */
  best_bet_cities_mv: Array<Best_Bet_Cities_Mv>;
  /** fetch aggregated fields from the table: "best_bet_cities_mv" */
  best_bet_cities_mv_aggregate: Best_Bet_Cities_Mv_Aggregate;
  /** fetch data from the table: "biweekly_recap_artists_info" */
  biweekly_recap_artists_info: Array<Biweekly_Recap_Artists_Info>;
  /** fetch aggregated fields from the table: "biweekly_recap_artists_info" */
  biweekly_recap_artists_info_aggregate: Biweekly_Recap_Artists_Info_Aggregate;
  /** fetch data from the table: "count_artists_approved_by_day" */
  count_artists_approved_by_day: Array<Count_Artists_Approved_By_Day>;
  /** fetch aggregated fields from the table: "count_artists_approved_by_day" */
  count_artists_approved_by_day_aggregate: Count_Artists_Approved_By_Day_Aggregate;
  /** fetch data from the table: "count_artists_created_by_day" */
  count_artists_created_by_day: Array<Count_Artists_Created_By_Day>;
  /** fetch aggregated fields from the table: "count_artists_created_by_day" */
  count_artists_created_by_day_aggregate: Count_Artists_Created_By_Day_Aggregate;
  /** fetch data from the table: "count_artists_pending_by_day" */
  count_artists_pending_by_day: Array<Count_Artists_Pending_By_Day>;
  /** fetch aggregated fields from the table: "count_artists_pending_by_day" */
  count_artists_pending_by_day_aggregate: Count_Artists_Pending_By_Day_Aggregate;
  /** fetch data from the table: "count_artists_rejected_by_day" */
  count_artists_rejected_by_day: Array<Count_Artists_Rejected_By_Day>;
  /** fetch aggregated fields from the table: "count_artists_rejected_by_day" */
  count_artists_rejected_by_day_aggregate: Count_Artists_Rejected_By_Day_Aggregate;
  /** fetch data from the table: "count_artworks_published_by_day" */
  count_artworks_published_by_day: Array<Count_Artworks_Published_By_Day>;
  /** fetch aggregated fields from the table: "count_artworks_published_by_day" */
  count_artworks_published_by_day_aggregate: Count_Artworks_Published_By_Day_Aggregate;
  /** fetch data from the table: "count_artworks_published_this_week" */
  count_artworks_published_this_week: Array<Count_Artworks_Published_This_Week>;
  /** fetch aggregated fields from the table: "count_artworks_published_this_week" */
  count_artworks_published_this_week_aggregate: Count_Artworks_Published_This_Week_Aggregate;
  /** fetch data from the table: "count_collectors_created_by_day" */
  count_collectors_created_by_day: Array<Count_Collectors_Created_By_Day>;
  /** fetch aggregated fields from the table: "count_collectors_created_by_day" */
  count_collectors_created_by_day_aggregate: Count_Collectors_Created_By_Day_Aggregate;
  /** fetch data from the table: "count_galleries_approved_by_day" */
  count_galleries_approved_by_day: Array<Count_Galleries_Approved_By_Day>;
  /** fetch aggregated fields from the table: "count_galleries_approved_by_day" */
  count_galleries_approved_by_day_aggregate: Count_Galleries_Approved_By_Day_Aggregate;
  /** fetch data from the table: "count_galleries_created_by_day" */
  count_galleries_created_by_day: Array<Count_Galleries_Created_By_Day>;
  /** fetch aggregated fields from the table: "count_galleries_created_by_day" */
  count_galleries_created_by_day_aggregate: Count_Galleries_Created_By_Day_Aggregate;
  /** fetch data from the table: "count_galleries_pending_by_day" */
  count_galleries_pending_by_day: Array<Count_Galleries_Pending_By_Day>;
  /** fetch aggregated fields from the table: "count_galleries_pending_by_day" */
  count_galleries_pending_by_day_aggregate: Count_Galleries_Pending_By_Day_Aggregate;
  /** fetch data from the table: "count_galleries_rejected_by_day" */
  count_galleries_rejected_by_day: Array<Count_Galleries_Rejected_By_Day>;
  /** fetch aggregated fields from the table: "count_galleries_rejected_by_day" */
  count_galleries_rejected_by_day_aggregate: Count_Galleries_Rejected_By_Day_Aggregate;
  /** fetch data from the table: "count_profile_visits_published_biweekly" */
  count_profile_visits_published_biweekly: Array<Count_Profile_Visits_Published_Biweekly>;
  /** fetch aggregated fields from the table: "count_profile_visits_published_biweekly" */
  count_profile_visits_published_biweekly_aggregate: Count_Profile_Visits_Published_Biweekly_Aggregate;
  /** fetch data from the table: "count_recognitions_published_biweekly" */
  count_recognitions_published_biweekly: Array<Count_Recognitions_Published_Biweekly>;
  /** fetch aggregated fields from the table: "count_recognitions_published_biweekly" */
  count_recognitions_published_biweekly_aggregate: Count_Recognitions_Published_Biweekly_Aggregate;
  /** fetch data from the table: "country_states_mv" */
  country_states_mv: Array<Country_States_Mv>;
  /** fetch aggregated fields from the table: "country_states_mv" */
  country_states_mv_aggregate: Country_States_Mv_Aggregate;
  /** fetch data from the table: "discount_code_view" */
  discount_code_view: Array<Discount_Code_View>;
  /** fetch aggregated fields from the table: "discount_code_view" */
  discount_code_view_aggregate: Discount_Code_View_Aggregate;
  /** fetch data from the table: "flat_delegate_access" */
  flat_delegate_access: Array<Flat_Delegate_Access>;
  /** fetch aggregated fields from the table: "flat_delegate_access" */
  flat_delegate_access_aggregate: Flat_Delegate_Access_Aggregate;
  /** execute function "getAnalyticsKaleidoCardByProfileId" which returns "Analytics_kaleidoCard_Amounts" */
  getAnalyticsKaleidoCardByProfileId: Array<Analytics_KaleidoCard_Amounts>;
  /** execute function "getAnalyticsKaleidoCardByProfileId" and query aggregates on result of table type "Analytics_kaleidoCard_Amounts" */
  getAnalyticsKaleidoCardByProfileId_aggregate: Analytics_KaleidoCard_Amounts_Aggregate;
  /** execute function "getArtPrizeShowWinners" which returns "Art_prize_show_winners" */
  getArtPrizeShowWinners: Array<Art_Prize_Show_Winners>;
  /** execute function "getArtPrizeShowWinners" and query aggregates on result of table type "Art_prize_show_winners" */
  getArtPrizeShowWinners_aggregate: Art_Prize_Show_Winners_Aggregate;
  /** execute function "getSuggestedPrimaryTagsByArtist" which returns "Context_suggested_tags" */
  getSuggestedPrimaryTagsByArtist: Array<Context_Suggested_Tags>;
  /** execute function "getSuggestedPrimaryTagsByArtist" and query aggregates on result of table type "Context_suggested_tags" */
  getSuggestedPrimaryTagsByArtist_aggregate: Context_Suggested_Tags_Aggregate;
  /** execute function "getSuggestedSecondaryTagsByArtist" which returns "Context_suggested_tags" */
  getSuggestedSecondaryTagsByArtist: Array<Context_Suggested_Tags>;
  /** execute function "getSuggestedSecondaryTagsByArtist" and query aggregates on result of table type "Context_suggested_tags" */
  getSuggestedSecondaryTagsByArtist_aggregate: Context_Suggested_Tags_Aggregate;
  /** execute function "get_artists_in_range" which returns "Artist_explore_listing_with_city_data" */
  get_artists_in_range: Array<Artist_Explore_Listing_With_City_Data>;
  /** execute function "get_artists_in_range" and query aggregates on result of table type "Artist_explore_listing_with_city_data" */
  get_artists_in_range_aggregate: Artist_Explore_Listing_With_City_Data_Aggregate;
  /** execute function "get_galleries_in_range" which returns "Gallery_explore_info_with_city_data" */
  get_galleries_in_range: Array<Gallery_Explore_Info_With_City_Data>;
  /** execute function "get_galleries_in_range" and query aggregates on result of table type "Gallery_explore_info_with_city_data" */
  get_galleries_in_range_aggregate: Gallery_Explore_Info_With_City_Data_Aggregate;
  /** execute function "get_min_price_available_edition_by_size" which returns "Available_print_price" */
  get_min_price_available_edition_by_size: Array<Available_Print_Price>;
  /** execute function "get_min_price_available_edition_by_size" and query aggregates on result of table type "Available_print_price" */
  get_min_price_available_edition_by_size_aggregate: Available_Print_Price_Aggregate;
  /** execute function "get_min_price_available_print" which returns "Available_print_price" */
  get_min_price_available_print: Array<Available_Print_Price>;
  /** execute function "get_min_price_available_print" and query aggregates on result of table type "Available_print_price" */
  get_min_price_available_print_aggregate: Available_Print_Price_Aggregate;
  inquireForPurchaseByOutsideUserAction?: Maybe<InquireForPurchaseByOutsideUserAction>;
  /** fetch data from the table: "inside_purchase_inquiries_view" */
  inside_purchase_inquiries_view: Array<Inside_Purchase_Inquiries_View>;
  /** fetch aggregated fields from the table: "inside_purchase_inquiries_view" */
  inside_purchase_inquiries_view_aggregate: Inside_Purchase_Inquiries_View_Aggregate;
  /** fetch data from the table: "kaleido_coins_distribution" */
  kaleido_coins_distribution: Array<Kaleido_Coins_Distribution>;
  /** fetch aggregated fields from the table: "kaleido_coins_distribution" */
  kaleido_coins_distribution_aggregate: Kaleido_Coins_Distribution_Aggregate;
  /** fetch data from the table: "kaleido_coins_weekly_distribution" */
  kaleido_coins_weekly_distribution: Array<Kaleido_Coins_Weekly_Distribution>;
  /** fetch aggregated fields from the table: "kaleido_coins_weekly_distribution" */
  kaleido_coins_weekly_distribution_aggregate: Kaleido_Coins_Weekly_Distribution_Aggregate;
  /** fetch data from the table: "outside_purchase_inquiries_view" */
  outside_purchase_inquiries_view: Array<Outside_Purchase_Inquiries_View>;
  /** fetch aggregated fields from the table: "outside_purchase_inquiries_view" */
  outside_purchase_inquiries_view_aggregate: Outside_Purchase_Inquiries_View_Aggregate;
  /** fetch data from the table: "owner_artwork_view" */
  owner_artwork_view: Array<Owner_Artwork_View>;
  /** fetch aggregated fields from the table: "owner_artwork_view" */
  owner_artwork_view_aggregate: Owner_Artwork_View_Aggregate;
  /** fetch data from the table: "payment_captures_view" */
  payment_captures_view: Array<Payment_Captures_View>;
  /** fetch aggregated fields from the table: "payment_captures_view" */
  payment_captures_view_aggregate: Payment_Captures_View_Aggregate;
  /** fetch data from the table: "pending_forge_task_view" */
  pending_forge_task_view: Array<Pending_Forge_Task_View>;
  /** fetch aggregated fields from the table: "pending_forge_task_view" */
  pending_forge_task_view_aggregate: Pending_Forge_Task_View_Aggregate;
  /** fetch data from the table: "print_availability" */
  print_availability: Array<Print_Availability>;
  /** fetch aggregated fields from the table: "print_availability" */
  print_availability_aggregate: Print_Availability_Aggregate;
  /** fetch data from the table: "profile_backgrounds_view" */
  profile_backgrounds_view: Array<Profile_Backgrounds_View>;
  /** fetch aggregated fields from the table: "profile_backgrounds_view" */
  profile_backgrounds_view_aggregate: Profile_Backgrounds_View_Aggregate;
  /** fetch data from the table: "profile_interests_view" */
  profile_interests_view: Array<Profile_Interests_View>;
  /** fetch aggregated fields from the table: "profile_interests_view" */
  profile_interests_view_aggregate: Profile_Interests_View_Aggregate;
  /** An array relationship */
  profile_phones: Array<Profile_Phones>;
  /** An aggregate relationship */
  profile_phones_aggregate: Profile_Phones_Aggregate;
  /** fetch data from the table: "profile_phones" using primary key columns */
  profile_phones_by_pk?: Maybe<Profile_Phones>;
  /** fetch data from the table: "recognition_counts_mv" */
  recognition_counts_mv: Array<Recognition_Counts_Mv>;
  /** fetch aggregated fields from the table: "recognition_counts_mv" */
  recognition_counts_mv_aggregate: Recognition_Counts_Mv_Aggregate;
  /** fetch data from the table: "showroom_owner_password" */
  showroom_owner_password: Array<Showroom_Owner_Password>;
  /** fetch aggregated fields from the table: "showroom_owner_password" */
  showroom_owner_password_aggregate: Showroom_Owner_Password_Aggregate;
  /** fetch data from the table: "showroom_password_check" */
  showroom_password_check: Array<Showroom_Password_Check>;
  /** fetch aggregated fields from the table: "showroom_password_check" */
  showroom_password_check_aggregate: Showroom_Password_Check_Aggregate;
  /** fetch data from the table: "sum_coins_from_card_this_week" */
  sum_coins_from_card_this_week: Array<Sum_Coins_From_Card_This_Week>;
  /** fetch aggregated fields from the table: "sum_coins_from_card_this_week" */
  sum_coins_from_card_this_week_aggregate: Sum_Coins_From_Card_This_Week_Aggregate;
  /** fetch data from the table: "worldcities" */
  worldcities: Array<Worldcities>;
  /** fetch aggregated fields from the table: "worldcities" */
  worldcities_aggregate: Worldcities_Aggregate;
  /** fetch data from the table: "worldcities" using primary key columns */
  worldcities_by_pk?: Maybe<Worldcities>;
};


export type Subscription_RootAccount_Initial_Profile_TypeArgs = {
  distinct_on?: Maybe<Array<Account_Initial_Profile_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Initial_Profile_Type_Order_By>>;
  where?: Maybe<Account_Initial_Profile_Type_Bool_Exp>;
};


export type Subscription_RootAccount_Initial_Profile_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Initial_Profile_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Initial_Profile_Type_Order_By>>;
  where?: Maybe<Account_Initial_Profile_Type_Bool_Exp>;
};


export type Subscription_RootAccount_Initial_Profile_Type_By_PkArgs = {
  context_id: Scalars['bigint'];
};


export type Subscription_RootAccount_Notice_AckArgs = {
  distinct_on?: Maybe<Array<Account_Notice_Ack_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Notice_Ack_Order_By>>;
  where?: Maybe<Account_Notice_Ack_Bool_Exp>;
};


export type Subscription_RootAccount_Notice_Ack_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Notice_Ack_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Notice_Ack_Order_By>>;
  where?: Maybe<Account_Notice_Ack_Bool_Exp>;
};


export type Subscription_RootAccount_Notice_Ack_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAccount_ValidationArgs = {
  distinct_on?: Maybe<Array<Account_Validation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Validation_Order_By>>;
  where?: Maybe<Account_Validation_Bool_Exp>;
};


export type Subscription_RootAccount_Validation_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Validation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Validation_Order_By>>;
  where?: Maybe<Account_Validation_Bool_Exp>;
};


export type Subscription_RootAccount_Validation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAchievement_HooksArgs = {
  distinct_on?: Maybe<Array<Achievement_Hooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Hooks_Order_By>>;
  where?: Maybe<Achievement_Hooks_Bool_Exp>;
};


export type Subscription_RootAchievement_Hooks_AggregateArgs = {
  distinct_on?: Maybe<Array<Achievement_Hooks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Hooks_Order_By>>;
  where?: Maybe<Achievement_Hooks_Bool_Exp>;
};


export type Subscription_RootAchievement_Hooks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAchievement_TagsArgs = {
  distinct_on?: Maybe<Array<Achievement_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Tags_Order_By>>;
  where?: Maybe<Achievement_Tags_Bool_Exp>;
};


export type Subscription_RootAchievement_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Achievement_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievement_Tags_Order_By>>;
  where?: Maybe<Achievement_Tags_Bool_Exp>;
};


export type Subscription_RootAchievement_Tags_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAchievementsArgs = {
  distinct_on?: Maybe<Array<Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievements_Order_By>>;
  where?: Maybe<Achievements_Bool_Exp>;
};


export type Subscription_RootAchievements_AggregateArgs = {
  distinct_on?: Maybe<Array<Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Achievements_Order_By>>;
  where?: Maybe<Achievements_Bool_Exp>;
};


export type Subscription_RootAchievements_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAdmin_PasswordArgs = {
  distinct_on?: Maybe<Array<Admin_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Password_Order_By>>;
  where?: Maybe<Admin_Password_Bool_Exp>;
};


export type Subscription_RootAdmin_Password_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Password_Order_By>>;
  where?: Maybe<Admin_Password_Bool_Exp>;
};


export type Subscription_RootAdmin_Password_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAdmin_Table_ProfilesArgs = {
  distinct_on?: Maybe<Array<Admin_Table_Profiles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Table_Profiles_Order_By>>;
  where?: Maybe<Admin_Table_Profiles_Bool_Exp>;
};


export type Subscription_RootAdmin_Table_Profiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Table_Profiles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Table_Profiles_Order_By>>;
  where?: Maybe<Admin_Table_Profiles_Bool_Exp>;
};


export type Subscription_RootAdmin_Table_Profiles_V2Args = {
  distinct_on?: Maybe<Array<Admin_Table_Profiles_V2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Table_Profiles_V2_Order_By>>;
  where?: Maybe<Admin_Table_Profiles_V2_Bool_Exp>;
};


export type Subscription_RootAdmin_Table_Profiles_V2_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_Table_Profiles_V2_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_Table_Profiles_V2_Order_By>>;
  where?: Maybe<Admin_Table_Profiles_V2_Bool_Exp>;
};


export type Subscription_RootAdmin_To_User_NotificationArgs = {
  distinct_on?: Maybe<Array<Admin_To_User_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_To_User_Notification_Order_By>>;
  where?: Maybe<Admin_To_User_Notification_Bool_Exp>;
};


export type Subscription_RootAdmin_To_User_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Admin_To_User_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Admin_To_User_Notification_Order_By>>;
  where?: Maybe<Admin_To_User_Notification_Bool_Exp>;
};


export type Subscription_RootAdmin_To_User_Notification_By_PkArgs = {
  user_context_id: Scalars['bigint'];
};


export type Subscription_RootAll_ArtistsArgs = {
  distinct_on?: Maybe<Array<All_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artists_Order_By>>;
  where?: Maybe<All_Artists_Bool_Exp>;
};


export type Subscription_RootAll_Artists_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artists_Order_By>>;
  where?: Maybe<All_Artists_Bool_Exp>;
};


export type Subscription_RootAll_Artwork_AvailabilityArgs = {
  distinct_on?: Maybe<Array<All_Artwork_Availability_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artwork_Availability_Order_By>>;
  where?: Maybe<All_Artwork_Availability_Bool_Exp>;
};


export type Subscription_RootAll_Artwork_Availability_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Artwork_Availability_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artwork_Availability_Order_By>>;
  where?: Maybe<All_Artwork_Availability_Bool_Exp>;
};


export type Subscription_RootAll_Artwork_Availability_TableArgs = {
  distinct_on?: Maybe<Array<All_Artwork_Availability_Table_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artwork_Availability_Table_Order_By>>;
  where?: Maybe<All_Artwork_Availability_Table_Bool_Exp>;
};


export type Subscription_RootAll_Artwork_Availability_Table_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Artwork_Availability_Table_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Artwork_Availability_Table_Order_By>>;
  where?: Maybe<All_Artwork_Availability_Table_Bool_Exp>;
};


export type Subscription_RootAll_Artwork_Availability_Table_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


export type Subscription_RootAll_CollectorsArgs = {
  distinct_on?: Maybe<Array<All_Collectors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Collectors_Order_By>>;
  where?: Maybe<All_Collectors_Bool_Exp>;
};


export type Subscription_RootAll_Collectors_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Collectors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Collectors_Order_By>>;
  where?: Maybe<All_Collectors_Bool_Exp>;
};


export type Subscription_RootAll_GalleriesArgs = {
  distinct_on?: Maybe<Array<All_Galleries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Galleries_Order_By>>;
  where?: Maybe<All_Galleries_Bool_Exp>;
};


export type Subscription_RootAll_Galleries_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Galleries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Galleries_Order_By>>;
  where?: Maybe<All_Galleries_Bool_Exp>;
};


export type Subscription_RootAnalytics_EventsArgs = {
  distinct_on?: Maybe<Array<Analytics_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Events_Order_By>>;
  where?: Maybe<Analytics_Events_Bool_Exp>;
};


export type Subscription_RootAnalytics_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Events_Order_By>>;
  where?: Maybe<Analytics_Events_Bool_Exp>;
};


export type Subscription_RootAnalytics_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAnalytics_Index_HistoryArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_History_Order_By>>;
  where?: Maybe<Analytics_Index_History_Bool_Exp>;
};


export type Subscription_RootAnalytics_Index_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_History_Order_By>>;
  where?: Maybe<Analytics_Index_History_Bool_Exp>;
};


export type Subscription_RootAnalytics_Index_History_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAnalytics_Index_TypeArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_Type_Order_By>>;
  where?: Maybe<Analytics_Index_Type_Bool_Exp>;
};


export type Subscription_RootAnalytics_Index_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_Type_Order_By>>;
  where?: Maybe<Analytics_Index_Type_Bool_Exp>;
};


export type Subscription_RootAnalytics_Index_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAnalytics_Index_ValuesArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_Values_Order_By>>;
  where?: Maybe<Analytics_Index_Values_Bool_Exp>;
};


export type Subscription_RootAnalytics_Index_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Index_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Index_Values_Order_By>>;
  where?: Maybe<Analytics_Index_Values_Bool_Exp>;
};


export type Subscription_RootAnalytics_Index_Values_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAnalytics_KaleidoCard_AmountsArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Amounts_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
};


export type Subscription_RootAnalytics_KaleidoCard_Amounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Amounts_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
};


export type Subscription_RootAnalytics_KaleidoCard_Amounts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAnalytics_KaleidoCard_EventsArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Events_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
};


export type Subscription_RootAnalytics_KaleidoCard_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Events_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Events_Bool_Exp>;
};


export type Subscription_RootAnalytics_KaleidoCard_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAnnounced_Art_ShowArgs = {
  distinct_on?: Maybe<Array<Announced_Art_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Announced_Art_Show_Order_By>>;
  where?: Maybe<Announced_Art_Show_Bool_Exp>;
};


export type Subscription_RootAnnounced_Art_Show_AggregateArgs = {
  distinct_on?: Maybe<Array<Announced_Art_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Announced_Art_Show_Order_By>>;
  where?: Maybe<Announced_Art_Show_Bool_Exp>;
};


export type Subscription_RootApp_NotificationsArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Order_By>>;
  where?: Maybe<App_Notifications_Bool_Exp>;
};


export type Subscription_RootApp_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Order_By>>;
  where?: Maybe<App_Notifications_Bool_Exp>;
};


export type Subscription_RootApp_Notifications_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArt_History_MovementArgs = {
  distinct_on?: Maybe<Array<Art_History_Movement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_History_Movement_Order_By>>;
  where?: Maybe<Art_History_Movement_Bool_Exp>;
};


export type Subscription_RootArt_History_Movement_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_History_Movement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_History_Movement_Order_By>>;
  where?: Maybe<Art_History_Movement_Bool_Exp>;
};


export type Subscription_RootArt_History_Movement_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootArt_Prize_ArtworkArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};


export type Subscription_RootArt_Prize_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Artwork_Order_By>>;
  where?: Maybe<Art_Prize_Artwork_Bool_Exp>;
};


export type Subscription_RootArt_Prize_Artwork_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArt_Prize_ShowArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Order_By>>;
  where?: Maybe<Art_Prize_Show_Bool_Exp>;
};


export type Subscription_RootArt_Prize_Show_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Order_By>>;
  where?: Maybe<Art_Prize_Show_Bool_Exp>;
};


export type Subscription_RootArt_Prize_Show_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArt_Prize_Show_WinnersArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Show_Winners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Winners_Order_By>>;
  where?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
};


export type Subscription_RootArt_Prize_Show_Winners_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Show_Winners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Winners_Order_By>>;
  where?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
};


export type Subscription_RootArt_Prize_Show_Winners_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootArt_Prize_WinnerArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Bool_Exp>;
};


export type Subscription_RootArt_Prize_Winner_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Bool_Exp>;
};


export type Subscription_RootArt_Prize_Winner_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArt_Prize_Winner_RankingArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Ranking_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Ranking_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Ranking_Bool_Exp>;
};


export type Subscription_RootArt_Prize_Winner_Ranking_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prize_Winner_Ranking_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Winner_Ranking_Order_By>>;
  where?: Maybe<Art_Prize_Winner_Ranking_Bool_Exp>;
};


export type Subscription_RootArt_Prizes_Links_FilteredArgs = {
  distinct_on?: Maybe<Array<Art_Prizes_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prizes_Links_Filtered_Order_By>>;
  where?: Maybe<Art_Prizes_Links_Filtered_Bool_Exp>;
};


export type Subscription_RootArt_Prizes_Links_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Prizes_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prizes_Links_Filtered_Order_By>>;
  where?: Maybe<Art_Prizes_Links_Filtered_Bool_Exp>;
};


export type Subscription_RootArt_Show_Artwork_ListingArgs = {
  distinct_on?: Maybe<Array<Art_Show_Artwork_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Show_Artwork_Listing_Order_By>>;
  where?: Maybe<Art_Show_Artwork_Listing_Bool_Exp>;
};


export type Subscription_RootArt_Show_Artwork_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Show_Artwork_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Show_Artwork_Listing_Order_By>>;
  where?: Maybe<Art_Show_Artwork_Listing_Bool_Exp>;
};


export type Subscription_RootArt_Show_DetailsArgs = {
  distinct_on?: Maybe<Array<Art_Show_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Show_Details_Order_By>>;
  where?: Maybe<Art_Show_Details_Bool_Exp>;
};


export type Subscription_RootArt_Show_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Show_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Show_Details_Order_By>>;
  where?: Maybe<Art_Show_Details_Bool_Exp>;
};


export type Subscription_RootArt_Show_Details_By_PkArgs = {
  uri: Scalars['String'];
};


export type Subscription_RootArt_Type_To_Artwork_DisciplineArgs = {
  distinct_on?: Maybe<Array<Art_Type_To_Artwork_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Type_To_Artwork_Discipline_Order_By>>;
  where?: Maybe<Art_Type_To_Artwork_Discipline_Bool_Exp>;
};


export type Subscription_RootArt_Type_To_Artwork_Discipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Art_Type_To_Artwork_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Type_To_Artwork_Discipline_Order_By>>;
  where?: Maybe<Art_Type_To_Artwork_Discipline_Bool_Exp>;
};


export type Subscription_RootArt_Type_To_Artwork_Discipline_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootArtist_CollectionArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_Order_By>>;
  where?: Maybe<Artist_Collection_Bool_Exp>;
};


export type Subscription_RootArtist_Collection_CategoriesArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_Categories_Order_By>>;
  where?: Maybe<Artist_Collection_Categories_Bool_Exp>;
};


export type Subscription_RootArtist_Collection_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_Categories_Order_By>>;
  where?: Maybe<Artist_Collection_Categories_Bool_Exp>;
};


export type Subscription_RootArtist_Collection_Categories_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootArtist_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_Order_By>>;
  where?: Maybe<Artist_Collection_Bool_Exp>;
};


export type Subscription_RootArtist_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtist_Collection_To_Artist_ContextArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


export type Subscription_RootArtist_Collection_To_Artist_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Collection_To_Artist_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Collection_To_Artist_Context_Order_By>>;
  where?: Maybe<Artist_Collection_To_Artist_Context_Bool_Exp>;
};


export type Subscription_RootArtist_Collection_To_Artist_Context_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtist_Vision_Listings_By_InterestArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Listings_By_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Listings_By_Interest_Order_By>>;
  where?: Maybe<Artist_Vision_Listings_By_Interest_Bool_Exp>;
};


export type Subscription_RootArtist_Vision_Listings_By_Interest_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Listings_By_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Listings_By_Interest_Order_By>>;
  where?: Maybe<Artist_Vision_Listings_By_Interest_Bool_Exp>;
};


export type Subscription_RootArtist_Art_History_ListingArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Listing_Order_By>>;
  where?: Maybe<Artist_Art_History_Listing_Bool_Exp>;
};


export type Subscription_RootArtist_Art_History_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Listing_Order_By>>;
  where?: Maybe<Artist_Art_History_Listing_Bool_Exp>;
};


export type Subscription_RootArtist_Art_History_Listing_By_MovementArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Order_By>>;
  where?: Maybe<Artist_Art_History_Listing_By_Movement_Bool_Exp>;
};


export type Subscription_RootArtist_Art_History_Listing_By_Movement_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Listing_By_Movement_Order_By>>;
  where?: Maybe<Artist_Art_History_Listing_By_Movement_Bool_Exp>;
};


export type Subscription_RootArtist_Art_History_VisibilityArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Visibility_Order_By>>;
  where?: Maybe<Artist_Art_History_Visibility_Bool_Exp>;
};


export type Subscription_RootArtist_Art_History_Visibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Art_History_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Art_History_Visibility_Order_By>>;
  where?: Maybe<Artist_Art_History_Visibility_Bool_Exp>;
};


export type Subscription_RootArtist_Art_History_Visibility_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtist_Curation_ScoreArgs = {
  distinct_on?: Maybe<Array<Artist_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Curation_Score_Order_By>>;
  where?: Maybe<Artist_Curation_Score_Bool_Exp>;
};


export type Subscription_RootArtist_Curation_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Curation_Score_Order_By>>;
  where?: Maybe<Artist_Curation_Score_Bool_Exp>;
};


export type Subscription_RootArtist_Curation_Score_By_PkArgs = {
  artist_context_id: Scalars['bigint'];
};


export type Subscription_RootArtist_Explore_InfoArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Info_Order_By>>;
  where?: Maybe<Artist_Explore_Info_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Info_Order_By>>;
  where?: Maybe<Artist_Explore_Info_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_InterestsArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Interests_Order_By>>;
  where?: Maybe<Artist_Explore_Interests_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_ListingArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_Listing_With_City_DataArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_With_City_Data_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_Listing_With_City_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_With_City_Data_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_VisibilityArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Visibility_Order_By>>;
  where?: Maybe<Artist_Explore_Visibility_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_Visibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Explore_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Visibility_Order_By>>;
  where?: Maybe<Artist_Explore_Visibility_Bool_Exp>;
};


export type Subscription_RootArtist_Explore_Visibility_By_PkArgs = {
  appear_reason: Scalars['String'];
  artist_context_id: Scalars['bigint'];
};


export type Subscription_RootArtist_Links_FilteredArgs = {
  distinct_on?: Maybe<Array<Artist_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Links_Filtered_Order_By>>;
  where?: Maybe<Artist_Links_Filtered_Bool_Exp>;
};


export type Subscription_RootArtist_Links_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Links_Filtered_Order_By>>;
  where?: Maybe<Artist_Links_Filtered_Bool_Exp>;
};


export type Subscription_RootArtist_NewsletterArgs = {
  distinct_on?: Maybe<Array<Artist_Newsletter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Newsletter_Order_By>>;
  where?: Maybe<Artist_Newsletter_Bool_Exp>;
};


export type Subscription_RootArtist_Newsletter_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Newsletter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Newsletter_Order_By>>;
  where?: Maybe<Artist_Newsletter_Bool_Exp>;
};


export type Subscription_RootArtist_Newsletter_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootArtist_Popular_DisciplineArgs = {
  distinct_on?: Maybe<Array<Artist_Popular_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Popular_Discipline_Order_By>>;
  where?: Maybe<Artist_Popular_Discipline_Bool_Exp>;
};


export type Subscription_RootArtist_Popular_Discipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Popular_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Popular_Discipline_Order_By>>;
  where?: Maybe<Artist_Popular_Discipline_Bool_Exp>;
};


export type Subscription_RootArtist_Preview_ArtworksArgs = {
  distinct_on?: Maybe<Array<Artist_Preview_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Preview_Artworks_Order_By>>;
  where?: Maybe<Artist_Preview_Artworks_Bool_Exp>;
};


export type Subscription_RootArtist_Preview_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Preview_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Preview_Artworks_Order_By>>;
  where?: Maybe<Artist_Preview_Artworks_Bool_Exp>;
};


export type Subscription_RootArtist_Vision_ListingArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Listing_Order_By>>;
  where?: Maybe<Artist_Vision_Listing_Bool_Exp>;
};


export type Subscription_RootArtist_Vision_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Listing_Order_By>>;
  where?: Maybe<Artist_Vision_Listing_Bool_Exp>;
};


export type Subscription_RootArtist_Vision_VisibilityArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Visibility_Order_By>>;
  where?: Maybe<Artist_Vision_Visibility_Bool_Exp>;
};


export type Subscription_RootArtist_Vision_Visibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Vision_Visibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Vision_Visibility_Order_By>>;
  where?: Maybe<Artist_Vision_Visibility_Bool_Exp>;
};


export type Subscription_RootArtist_Vision_Visibility_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtworkArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


export type Subscription_RootArtwork_Art_Universe_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Art_Universe_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Art_Universe_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Art_Universe_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Order_By>>;
  where?: Maybe<Artwork_Bool_Exp>;
};


export type Subscription_RootArtwork_Ai_TagsArgs = {
  distinct_on?: Maybe<Array<Artwork_Ai_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Ai_Tags_Order_By>>;
  where?: Maybe<Artwork_Ai_Tags_Bool_Exp>;
};


export type Subscription_RootArtwork_Ai_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Ai_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Ai_Tags_Order_By>>;
  where?: Maybe<Artwork_Ai_Tags_Bool_Exp>;
};


export type Subscription_RootArtwork_Ai_Tags_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Art_History_TimetableArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_History_Timetable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_History_Timetable_Order_By>>;
  where?: Maybe<Artwork_Art_History_Timetable_Bool_Exp>;
};


export type Subscription_RootArtwork_Art_History_Timetable_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Art_History_Timetable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Art_History_Timetable_Order_By>>;
  where?: Maybe<Artwork_Art_History_Timetable_Bool_Exp>;
};


export type Subscription_RootArtwork_Art_History_Timetable_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Artist_Art_History_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Art_History_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Art_History_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Art_History_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Artist_Art_History_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Art_History_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Art_History_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Art_History_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Artist_Collection_Type_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Collection_Type_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Artist_Collection_Type_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Collection_Type_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Collection_Type_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Artist_Profile_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Profile_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Profile_Info_Order_By>>;
  where?: Maybe<Artwork_Artist_Profile_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Artist_Profile_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Profile_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Profile_Info_Order_By>>;
  where?: Maybe<Artwork_Artist_Profile_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Artist_Profile_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Profile_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Profile_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Profile_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Artist_Profile_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Profile_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Profile_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Profile_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Artist_Vision_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Vision_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Vision_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Vision_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Artist_Vision_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Artist_Vision_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Artist_Vision_Listing_Order_By>>;
  where?: Maybe<Artwork_Artist_Vision_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Aspect_RatioArgs = {
  distinct_on?: Maybe<Array<Artwork_Aspect_Ratio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Aspect_Ratio_Order_By>>;
  where?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
};


export type Subscription_RootArtwork_Aspect_Ratio_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Aspect_Ratio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Aspect_Ratio_Order_By>>;
  where?: Maybe<Artwork_Aspect_Ratio_Bool_Exp>;
};


export type Subscription_RootArtwork_Aspect_Ratio_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


export type Subscription_RootArtwork_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Collected_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Collected_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Collected_Listing_Order_By>>;
  where?: Maybe<Artwork_Collected_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Collected_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Collected_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Collected_Listing_Order_By>>;
  where?: Maybe<Artwork_Collected_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Count_MilestonesArgs = {
  distinct_on?: Maybe<Array<Artwork_Count_Milestones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Count_Milestones_Order_By>>;
  where?: Maybe<Artwork_Count_Milestones_Bool_Exp>;
};


export type Subscription_RootArtwork_Count_Milestones_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Count_Milestones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Count_Milestones_Order_By>>;
  where?: Maybe<Artwork_Count_Milestones_Bool_Exp>;
};


export type Subscription_RootArtwork_Count_Milestones_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootArtwork_Curation_ScoreArgs = {
  distinct_on?: Maybe<Array<Artwork_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Curation_Score_Order_By>>;
  where?: Maybe<Artwork_Curation_Score_Bool_Exp>;
};


export type Subscription_RootArtwork_Curation_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Curation_Score_Order_By>>;
  where?: Maybe<Artwork_Curation_Score_Bool_Exp>;
};


export type Subscription_RootArtwork_Curation_Score_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


export type Subscription_RootArtwork_DetailsArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Order_By>>;
  where?: Maybe<Artwork_Details_Bool_Exp>;
};


export type Subscription_RootArtwork_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Order_By>>;
  where?: Maybe<Artwork_Details_Bool_Exp>;
};


export type Subscription_RootArtwork_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Details_TranslationArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Artwork_Details_Translation_Bool_Exp>;
};


export type Subscription_RootArtwork_Details_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Artwork_Details_Translation_Bool_Exp>;
};


export type Subscription_RootArtwork_Details_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Digital_TwinArgs = {
  distinct_on?: Maybe<Array<Artwork_Digital_Twin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Digital_Twin_Order_By>>;
  where?: Maybe<Artwork_Digital_Twin_Bool_Exp>;
};


export type Subscription_RootArtwork_Digital_Twin_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Digital_Twin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Digital_Twin_Order_By>>;
  where?: Maybe<Artwork_Digital_Twin_Bool_Exp>;
};


export type Subscription_RootArtwork_Digital_Twin_By_PkArgs = {
  digital_twin_id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Documents_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Documents_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Documents_Info_Order_By>>;
  where?: Maybe<Artwork_Documents_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Documents_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Documents_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Documents_Info_Order_By>>;
  where?: Maybe<Artwork_Documents_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_EventsArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


export type Subscription_RootArtwork_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Events_Order_By>>;
  where?: Maybe<Artwork_Events_Bool_Exp>;
};


export type Subscription_RootArtwork_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Explore_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Info_Order_By>>;
  where?: Maybe<Artwork_Explore_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Explore_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Info_Order_By>>;
  where?: Maybe<Artwork_Explore_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Explore_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Listing_Order_By>>;
  where?: Maybe<Artwork_Explore_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Explore_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Explore_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Explore_Listing_Order_By>>;
  where?: Maybe<Artwork_Explore_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_External_Buy_LinkArgs = {
  distinct_on?: Maybe<Array<Artwork_External_Buy_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_External_Buy_Link_Order_By>>;
  where?: Maybe<Artwork_External_Buy_Link_Bool_Exp>;
};


export type Subscription_RootArtwork_External_Buy_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_External_Buy_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_External_Buy_Link_Order_By>>;
  where?: Maybe<Artwork_External_Buy_Link_Bool_Exp>;
};


export type Subscription_RootArtwork_External_Buy_Link_By_PkArgs = {
  artwork_id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Favorite_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorite_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorite_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorite_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Favorite_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorite_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorite_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorite_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Favorited_By_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorited_By_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorited_By_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorited_By_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Favorited_By_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorited_By_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorited_By_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorited_By_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Favorites_Art_Universe_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorites_Art_Universe_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Favorites_Art_Universe_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorites_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Artwork_Favorites_Art_Universe_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Last_Print_TransactionArgs = {
  distinct_on?: Maybe<Array<Artwork_Last_Print_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Last_Print_Transaction_Order_By>>;
  where?: Maybe<Artwork_Last_Print_Transaction_Bool_Exp>;
};


export type Subscription_RootArtwork_Last_Print_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Last_Print_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Last_Print_Transaction_Order_By>>;
  where?: Maybe<Artwork_Last_Print_Transaction_Bool_Exp>;
};


export type Subscription_RootArtwork_Last_TransactionArgs = {
  distinct_on?: Maybe<Array<Artwork_Last_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Last_Transaction_Order_By>>;
  where?: Maybe<Artwork_Last_Transaction_Bool_Exp>;
};


export type Subscription_RootArtwork_Last_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Last_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Last_Transaction_Order_By>>;
  where?: Maybe<Artwork_Last_Transaction_Bool_Exp>;
};


export type Subscription_RootArtwork_LinksArgs = {
  distinct_on?: Maybe<Array<Artwork_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Links_Order_By>>;
  where?: Maybe<Artwork_Links_Bool_Exp>;
};


export type Subscription_RootArtwork_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Links_Order_By>>;
  where?: Maybe<Artwork_Links_Bool_Exp>;
};


export type Subscription_RootArtwork_PricesArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


export type Subscription_RootArtwork_Prices_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prices_Order_By>>;
  where?: Maybe<Artwork_Prices_Bool_Exp>;
};


export type Subscription_RootArtwork_Prices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Prize_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Prize_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prize_Info_Order_By>>;
  where?: Maybe<Artwork_Prize_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Prize_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prize_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prize_Info_Order_By>>;
  where?: Maybe<Artwork_Prize_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Prize_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Prize_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prize_Listing_Order_By>>;
  where?: Maybe<Artwork_Prize_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Prize_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Prize_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Prize_Listing_Order_By>>;
  where?: Maybe<Artwork_Prize_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_JourneyArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Purchase_Journey_HistoryArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_History_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_History_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_History_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_History_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Purchase_Journey_Last_UpdateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_Last_Update_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Last_Update_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Last_Update_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_Last_Update_By_PkArgs = {
  entry_kind: Enum_Artwork_Purchase_Journey_History_Entry_Kind_Enum;
  journey_id: Scalars['bigint'];
  party_type: Enum_Artwork_Purchase_Journey_Party_Enum;
};


export type Subscription_RootArtwork_Purchase_Journey_PricingArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Pricing_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_Pricing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Pricing_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Pricing_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_Pricing_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Purchase_Journey_Shipping_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_Shipping_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Purchase_Journey_Shipping_Info_Order_By>>;
  where?: Maybe<Artwork_Purchase_Journey_Shipping_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Purchase_Journey_Shipping_Info_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Secondary_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


export type Subscription_RootArtwork_Secondary_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Artwork_Secondary_Media_Bool_Exp>;
};


export type Subscription_RootArtwork_Secondary_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Showcase_FilteredArgs = {
  distinct_on?: Maybe<Array<Artwork_Showcase_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Showcase_Filtered_Order_By>>;
  where?: Maybe<Artwork_Showcase_Filtered_Bool_Exp>;
};


export type Subscription_RootArtwork_Showcase_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Showcase_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Showcase_Filtered_Order_By>>;
  where?: Maybe<Artwork_Showcase_Filtered_Bool_Exp>;
};


export type Subscription_RootArtwork_State_HistoryArgs = {
  distinct_on?: Maybe<Array<Artwork_State_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_State_History_Order_By>>;
  where?: Maybe<Artwork_State_History_Bool_Exp>;
};


export type Subscription_RootArtwork_State_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_State_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_State_History_Order_By>>;
  where?: Maybe<Artwork_State_History_Bool_Exp>;
};


export type Subscription_RootArtwork_State_History_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_StoryArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Order_By>>;
  where?: Maybe<Artwork_Story_Bool_Exp>;
};


export type Subscription_RootArtwork_Story_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Order_By>>;
  where?: Maybe<Artwork_Story_Bool_Exp>;
};


export type Subscription_RootArtwork_Story_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Story_MediaArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Media_Order_By>>;
  where?: Maybe<Artwork_Story_Media_Bool_Exp>;
};


export type Subscription_RootArtwork_Story_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Media_Order_By>>;
  where?: Maybe<Artwork_Story_Media_Bool_Exp>;
};


export type Subscription_RootArtwork_Story_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_Story_Video_LinksArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Video_Links_Order_By>>;
  where?: Maybe<Artwork_Story_Video_Links_Bool_Exp>;
};


export type Subscription_RootArtwork_Story_Video_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Story_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Story_Video_Links_Order_By>>;
  where?: Maybe<Artwork_Story_Video_Links_Bool_Exp>;
};


export type Subscription_RootArtwork_Story_Video_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_TagArgs = {
  distinct_on?: Maybe<Array<Artwork_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Tag_Order_By>>;
  where?: Maybe<Artwork_Tag_Bool_Exp>;
};


export type Subscription_RootArtwork_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Tag_Order_By>>;
  where?: Maybe<Artwork_Tag_Bool_Exp>;
};


export type Subscription_RootArtwork_Tag_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_To_CollectionArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


export type Subscription_RootArtwork_To_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Collection_Order_By>>;
  where?: Maybe<Artwork_To_Collection_Bool_Exp>;
};


export type Subscription_RootArtwork_To_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_To_Showroom_DetailsArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Showroom_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Showroom_Details_Order_By>>;
  where?: Maybe<Artwork_To_Showroom_Details_Bool_Exp>;
};


export type Subscription_RootArtwork_To_Showroom_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Showroom_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Showroom_Details_Order_By>>;
  where?: Maybe<Artwork_To_Showroom_Details_Bool_Exp>;
};


export type Subscription_RootArtwork_To_Showroom_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_To_TagArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Tag_Order_By>>;
  where?: Maybe<Artwork_To_Tag_Bool_Exp>;
};


export type Subscription_RootArtwork_To_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_To_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_To_Tag_Order_By>>;
  where?: Maybe<Artwork_To_Tag_Bool_Exp>;
};


export type Subscription_RootArtwork_To_Tag_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootArtwork_TransactionsArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


export type Subscription_RootArtwork_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Transactions_Order_By>>;
  where?: Maybe<Artwork_Transactions_Bool_Exp>;
};


export type Subscription_RootArtwork_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAuthArgs = {
  distinct_on?: Maybe<Array<Auth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Order_By>>;
  where?: Maybe<Auth_Bool_Exp>;
};


export type Subscription_RootAuth_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Order_By>>;
  where?: Maybe<Auth_Bool_Exp>;
};


export type Subscription_RootAuth_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAuth_Context_AccessArgs = {
  distinct_on?: Maybe<Array<Auth_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Context_Access_Order_By>>;
  where?: Maybe<Auth_Context_Access_Bool_Exp>;
};


export type Subscription_RootAuth_Context_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Context_Access_Order_By>>;
  where?: Maybe<Auth_Context_Access_Bool_Exp>;
};


export type Subscription_RootAuth_Context_Access_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAvailable_Print_PriceArgs = {
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Subscription_RootAvailable_Print_Price_AggregateArgs = {
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Subscription_RootAvailable_Print_Price_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAvailable_Print_Price_ViewArgs = {
  distinct_on?: Maybe<Array<Available_Print_Price_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_View_Order_By>>;
  where?: Maybe<Available_Print_Price_View_Bool_Exp>;
};


export type Subscription_RootAvailable_Print_Price_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Available_Print_Price_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_View_Order_By>>;
  where?: Maybe<Available_Print_Price_View_Bool_Exp>;
};


export type Subscription_RootBilling_Anon_ClientArgs = {
  distinct_on?: Maybe<Array<Billing_Anon_Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Anon_Client_Order_By>>;
  where?: Maybe<Billing_Anon_Client_Bool_Exp>;
};


export type Subscription_RootBilling_Anon_Client_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Anon_Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Anon_Client_Order_By>>;
  where?: Maybe<Billing_Anon_Client_Bool_Exp>;
};


export type Subscription_RootBilling_Anon_Client_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBilling_Artwork_Payment_IntentArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Bool_Exp>;
};


export type Subscription_RootBilling_Artwork_Payment_Intent_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Bool_Exp>;
};


export type Subscription_RootBilling_Artwork_Payment_Intent_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBilling_Artwork_Payment_Intent_ProductArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Product_Bool_Exp>;
};


export type Subscription_RootBilling_Artwork_Payment_Intent_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Product_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Product_Bool_Exp>;
};


export type Subscription_RootBilling_Artwork_Payment_Intent_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBilling_Artwork_Payment_Intent_Shipping_AddressArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>;
};


export type Subscription_RootBilling_Artwork_Payment_Intent_Shipping_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Shipping_Address_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Shipping_Address_Bool_Exp>;
};


export type Subscription_RootBilling_Artwork_Payment_Intent_Shipping_Address_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBilling_Artwork_Payment_Intent_Status_HistoryArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>;
};


export type Subscription_RootBilling_Artwork_Payment_Intent_Status_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Artwork_Payment_Intent_Status_History_Order_By>>;
  where?: Maybe<Billing_Artwork_Payment_Intent_Status_History_Bool_Exp>;
};


export type Subscription_RootBilling_Artwork_Payment_Intent_Status_History_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBilling_ClientArgs = {
  distinct_on?: Maybe<Array<Billing_Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Client_Order_By>>;
  where?: Maybe<Billing_Client_Bool_Exp>;
};


export type Subscription_RootBilling_Client_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Client_Order_By>>;
  where?: Maybe<Billing_Client_Bool_Exp>;
};


export type Subscription_RootBilling_Client_By_PkArgs = {
  context_id: Scalars['bigint'];
};


export type Subscription_RootBilling_SellerArgs = {
  distinct_on?: Maybe<Array<Billing_Seller_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Seller_Order_By>>;
  where?: Maybe<Billing_Seller_Bool_Exp>;
};


export type Subscription_RootBilling_Seller_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Seller_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Seller_Order_By>>;
  where?: Maybe<Billing_Seller_Bool_Exp>;
};


export type Subscription_RootBilling_Seller_By_PkArgs = {
  context_id: Scalars['bigint'];
};


export type Subscription_RootBilling_SubscriptionArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Order_By>>;
  where?: Maybe<Billing_Subscription_Bool_Exp>;
};


export type Subscription_RootBilling_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Order_By>>;
  where?: Maybe<Billing_Subscription_Bool_Exp>;
};


export type Subscription_RootBilling_Subscription_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBilling_Subscription_ProductArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Product_Order_By>>;
  where?: Maybe<Billing_Subscription_Product_Bool_Exp>;
};


export type Subscription_RootBilling_Subscription_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Subscription_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Billing_Subscription_Product_Order_By>>;
  where?: Maybe<Billing_Subscription_Product_Bool_Exp>;
};


export type Subscription_RootBilling_Subscription_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBiolink_SectionsArgs = {
  distinct_on?: Maybe<Array<Biolink_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Biolink_Sections_Order_By>>;
  where?: Maybe<Biolink_Sections_Bool_Exp>;
};


export type Subscription_RootBiolink_Sections_AggregateArgs = {
  distinct_on?: Maybe<Array<Biolink_Sections_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Biolink_Sections_Order_By>>;
  where?: Maybe<Biolink_Sections_Bool_Exp>;
};


export type Subscription_RootBiolink_Sections_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCertificate_Of_AuthenticityArgs = {
  distinct_on?: Maybe<Array<Certificate_Of_Authenticity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Certificate_Of_Authenticity_Order_By>>;
  where?: Maybe<Certificate_Of_Authenticity_Bool_Exp>;
};


export type Subscription_RootCertificate_Of_Authenticity_AggregateArgs = {
  distinct_on?: Maybe<Array<Certificate_Of_Authenticity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Certificate_Of_Authenticity_Order_By>>;
  where?: Maybe<Certificate_Of_Authenticity_Bool_Exp>;
};


export type Subscription_RootCertificate_Of_Authenticity_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCollaborationArgs = {
  distinct_on?: Maybe<Array<Collaboration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Order_By>>;
  where?: Maybe<Collaboration_Bool_Exp>;
};


export type Subscription_RootCollaboration_AggregateArgs = {
  distinct_on?: Maybe<Array<Collaboration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Order_By>>;
  where?: Maybe<Collaboration_Bool_Exp>;
};


export type Subscription_RootCollaboration_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCollaboration_Invitation_On_ArtworkArgs = {
  distinct_on?: Maybe<Array<Collaboration_Invitation_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Invitation_On_Artwork_Order_By>>;
  where?: Maybe<Collaboration_Invitation_On_Artwork_Bool_Exp>;
};


export type Subscription_RootCollaboration_Invitation_On_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Collaboration_Invitation_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collaboration_Invitation_On_Artwork_Order_By>>;
  where?: Maybe<Collaboration_Invitation_On_Artwork_Bool_Exp>;
};


export type Subscription_RootCollaboration_Invitation_On_Artwork_By_PkArgs = {
  artwork_id: Scalars['bigint'];
  invitation_id: Scalars['bigint'];
};


export type Subscription_RootCollectionArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};


export type Subscription_RootCollection_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Order_By>>;
  where?: Maybe<Collection_Bool_Exp>;
};


export type Subscription_RootCollection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCollection_TranslationArgs = {
  distinct_on?: Maybe<Array<Collection_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Translation_Order_By>>;
  where?: Maybe<Collection_Translation_Bool_Exp>;
};


export type Subscription_RootCollection_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Translation_Order_By>>;
  where?: Maybe<Collection_Translation_Bool_Exp>;
};


export type Subscription_RootCollection_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCollection_Video_LinksArgs = {
  distinct_on?: Maybe<Array<Collection_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Video_Links_Order_By>>;
  where?: Maybe<Collection_Video_Links_Bool_Exp>;
};


export type Subscription_RootCollection_Video_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collection_Video_Links_Order_By>>;
  where?: Maybe<Collection_Video_Links_Bool_Exp>;
};


export type Subscription_RootCollection_Video_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCollections_Links_FilteredArgs = {
  distinct_on?: Maybe<Array<Collections_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collections_Links_Filtered_Order_By>>;
  where?: Maybe<Collections_Links_Filtered_Bool_Exp>;
};


export type Subscription_RootCollections_Links_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Collections_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collections_Links_Filtered_Order_By>>;
  where?: Maybe<Collections_Links_Filtered_Bool_Exp>;
};


export type Subscription_RootCollector_Explore_InfoArgs = {
  distinct_on?: Maybe<Array<Collector_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collector_Explore_Info_Order_By>>;
  where?: Maybe<Collector_Explore_Info_Bool_Exp>;
};


export type Subscription_RootCollector_Explore_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Collector_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collector_Explore_Info_Order_By>>;
  where?: Maybe<Collector_Explore_Info_Bool_Exp>;
};


export type Subscription_RootCollector_Links_FilteredArgs = {
  distinct_on?: Maybe<Array<Collector_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collector_Links_Filtered_Order_By>>;
  where?: Maybe<Collector_Links_Filtered_Bool_Exp>;
};


export type Subscription_RootCollector_Links_Filtered_AggregateArgs = {
  distinct_on?: Maybe<Array<Collector_Links_Filtered_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Collector_Links_Filtered_Order_By>>;
  where?: Maybe<Collector_Links_Filtered_Bool_Exp>;
};


export type Subscription_RootConnected_DomainArgs = {
  distinct_on?: Maybe<Array<Connected_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Connected_Domain_Order_By>>;
  where?: Maybe<Connected_Domain_Bool_Exp>;
};


export type Subscription_RootConnected_Domain_AggregateArgs = {
  distinct_on?: Maybe<Array<Connected_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Connected_Domain_Order_By>>;
  where?: Maybe<Connected_Domain_Bool_Exp>;
};


export type Subscription_RootConnected_Domain_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootContent_TranslationsArgs = {
  distinct_on?: Maybe<Array<Content_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Translations_Order_By>>;
  where?: Maybe<Content_Translations_Bool_Exp>;
};


export type Subscription_RootContent_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<Content_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Translations_Order_By>>;
  where?: Maybe<Content_Translations_Bool_Exp>;
};


export type Subscription_RootContextArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};


export type Subscription_RootContext_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Order_By>>;
  where?: Maybe<Context_Bool_Exp>;
};


export type Subscription_RootContext_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootContext_Delegate_AccessArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


export type Subscription_RootContext_Delegate_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Delegate_Access_Order_By>>;
  where?: Maybe<Context_Delegate_Access_Bool_Exp>;
};


export type Subscription_RootContext_Delegate_Access_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootContext_FlagsArgs = {
  distinct_on?: Maybe<Array<Context_Flags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Flags_Order_By>>;
  where?: Maybe<Context_Flags_Bool_Exp>;
};


export type Subscription_RootContext_Flags_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Flags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Flags_Order_By>>;
  where?: Maybe<Context_Flags_Bool_Exp>;
};


export type Subscription_RootContext_Flags_By_PkArgs = {
  context_id: Scalars['bigint'];
  flag_type: Enum_Flags_Type_Enum;
};


export type Subscription_RootContext_Suggested_TagsArgs = {
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Subscription_RootContext_Suggested_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Subscription_RootContext_Suggested_Tags_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootContext_To_MilestoneArgs = {
  distinct_on?: Maybe<Array<Context_To_Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Milestone_Order_By>>;
  where?: Maybe<Context_To_Milestone_Bool_Exp>;
};


export type Subscription_RootContext_To_Milestone_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_To_Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Milestone_Order_By>>;
  where?: Maybe<Context_To_Milestone_Bool_Exp>;
};


export type Subscription_RootContext_To_Milestone_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootContext_To_TutorialArgs = {
  distinct_on?: Maybe<Array<Context_To_Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Tutorial_Order_By>>;
  where?: Maybe<Context_To_Tutorial_Bool_Exp>;
};


export type Subscription_RootContext_To_Tutorial_AggregateArgs = {
  distinct_on?: Maybe<Array<Context_To_Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_To_Tutorial_Order_By>>;
  where?: Maybe<Context_To_Tutorial_Bool_Exp>;
};


export type Subscription_RootContext_To_Tutorial_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCountriesArgs = {
  distinct_on?: Maybe<Array<Countries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Countries_Order_By>>;
  where?: Maybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_AggregateArgs = {
  distinct_on?: Maybe<Array<Countries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Countries_Order_By>>;
  where?: Maybe<Countries_Bool_Exp>;
};


export type Subscription_RootCountries_By_PkArgs = {
  iso3: Scalars['String'];
};


export type Subscription_RootCrop_AreaArgs = {
  distinct_on?: Maybe<Array<Crop_Area_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Crop_Area_Order_By>>;
  where?: Maybe<Crop_Area_Bool_Exp>;
};


export type Subscription_RootCrop_Area_AggregateArgs = {
  distinct_on?: Maybe<Array<Crop_Area_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Crop_Area_Order_By>>;
  where?: Maybe<Crop_Area_Bool_Exp>;
};


export type Subscription_RootCrop_Area_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCurrency_ConversionArgs = {
  distinct_on?: Maybe<Array<Currency_Conversion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currency_Conversion_Order_By>>;
  where?: Maybe<Currency_Conversion_Bool_Exp>;
};


export type Subscription_RootCurrency_Conversion_AggregateArgs = {
  distinct_on?: Maybe<Array<Currency_Conversion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currency_Conversion_Order_By>>;
  where?: Maybe<Currency_Conversion_Bool_Exp>;
};


export type Subscription_RootCurrency_Conversion_By_PkArgs = {
  currency: Scalars['String'];
};


export type Subscription_RootDigital_Twin_DetailsArgs = {
  distinct_on?: Maybe<Array<Digital_Twin_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Digital_Twin_Details_Order_By>>;
  where?: Maybe<Digital_Twin_Details_Bool_Exp>;
};


export type Subscription_RootDigital_Twin_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Digital_Twin_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Digital_Twin_Details_Order_By>>;
  where?: Maybe<Digital_Twin_Details_Bool_Exp>;
};


export type Subscription_RootDigital_Twin_Details_By_PkArgs = {
  digital_twin_id: Scalars['bigint'];
};


export type Subscription_RootDirect_Messages_From_CollectorsArgs = {
  distinct_on?: Maybe<Array<Direct_Messages_From_Collectors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direct_Messages_From_Collectors_Order_By>>;
  where?: Maybe<Direct_Messages_From_Collectors_Bool_Exp>;
};


export type Subscription_RootDirect_Messages_From_Collectors_AggregateArgs = {
  distinct_on?: Maybe<Array<Direct_Messages_From_Collectors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direct_Messages_From_Collectors_Order_By>>;
  where?: Maybe<Direct_Messages_From_Collectors_Bool_Exp>;
};


export type Subscription_RootDiscount_CodeArgs = {
  distinct_on?: Maybe<Array<Discount_Code_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_Order_By>>;
  where?: Maybe<Discount_Code_Bool_Exp>;
};


export type Subscription_RootDiscount_Code_AggregateArgs = {
  distinct_on?: Maybe<Array<Discount_Code_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_Order_By>>;
  where?: Maybe<Discount_Code_Bool_Exp>;
};


export type Subscription_RootDiscount_Code_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDiscount_Code_UsageArgs = {
  distinct_on?: Maybe<Array<Discount_Code_Usage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_Usage_Order_By>>;
  where?: Maybe<Discount_Code_Usage_Bool_Exp>;
};


export type Subscription_RootDiscount_Code_Usage_AggregateArgs = {
  distinct_on?: Maybe<Array<Discount_Code_Usage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_Usage_Order_By>>;
  where?: Maybe<Discount_Code_Usage_Bool_Exp>;
};


export type Subscription_RootDiscount_Code_Usage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDomain_Registrant_InfoArgs = {
  distinct_on?: Maybe<Array<Domain_Registrant_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Domain_Registrant_Info_Order_By>>;
  where?: Maybe<Domain_Registrant_Info_Bool_Exp>;
};


export type Subscription_RootDomain_Registrant_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Domain_Registrant_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Domain_Registrant_Info_Order_By>>;
  where?: Maybe<Domain_Registrant_Info_Bool_Exp>;
};


export type Subscription_RootDomain_Registrant_Info_By_PkArgs = {
  domain_registration_id: Scalars['bigint'];
};


export type Subscription_RootDomain_RegistrationArgs = {
  distinct_on?: Maybe<Array<Domain_Registration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Domain_Registration_Order_By>>;
  where?: Maybe<Domain_Registration_Bool_Exp>;
};


export type Subscription_RootDomain_Registration_AggregateArgs = {
  distinct_on?: Maybe<Array<Domain_Registration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Domain_Registration_Order_By>>;
  where?: Maybe<Domain_Registration_Bool_Exp>;
};


export type Subscription_RootDomain_Registration_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDummy_Table_For_FrontArgs = {
  distinct_on?: Maybe<Array<Dummy_Table_For_Front_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dummy_Table_For_Front_Order_By>>;
  where?: Maybe<Dummy_Table_For_Front_Bool_Exp>;
};


export type Subscription_RootDummy_Table_For_Front_AggregateArgs = {
  distinct_on?: Maybe<Array<Dummy_Table_For_Front_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dummy_Table_For_Front_Order_By>>;
  where?: Maybe<Dummy_Table_For_Front_Bool_Exp>;
};


export type Subscription_RootDummy_Table_For_Front_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDynamic_CvArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Order_By>>;
  where?: Maybe<Dynamic_Cv_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Order_By>>;
  where?: Maybe<Dynamic_Cv_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_AwardArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Award_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Award_Order_By>>;
  where?: Maybe<Dynamic_Cv_Award_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Award_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Award_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Award_Order_By>>;
  where?: Maybe<Dynamic_Cv_Award_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Award_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDynamic_Cv_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDynamic_Cv_CollectionArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Collection_Order_By>>;
  where?: Maybe<Dynamic_Cv_Collection_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Collection_Order_By>>;
  where?: Maybe<Dynamic_Cv_Collection_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDynamic_Cv_ContactArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Contact_Order_By>>;
  where?: Maybe<Dynamic_Cv_Contact_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Contact_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Contact_Order_By>>;
  where?: Maybe<Dynamic_Cv_Contact_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Contact_By_PkArgs = {
  cv_id: Scalars['bigint'];
};


export type Subscription_RootDynamic_Cv_EducationArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Education_Order_By>>;
  where?: Maybe<Dynamic_Cv_Education_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Education_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Education_Order_By>>;
  where?: Maybe<Dynamic_Cv_Education_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Education_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDynamic_Cv_ExhibitionArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Exhibition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Exhibition_Order_By>>;
  where?: Maybe<Dynamic_Cv_Exhibition_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Exhibition_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Exhibition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Exhibition_Order_By>>;
  where?: Maybe<Dynamic_Cv_Exhibition_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Exhibition_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDynamic_Cv_PublicationArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Publication_Order_By>>;
  where?: Maybe<Dynamic_Cv_Publication_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Publication_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Publication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Publication_Order_By>>;
  where?: Maybe<Dynamic_Cv_Publication_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Publication_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDynamic_Cv_SectionArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Section_Order_By>>;
  where?: Maybe<Dynamic_Cv_Section_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Section_AggregateArgs = {
  distinct_on?: Maybe<Array<Dynamic_Cv_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dynamic_Cv_Section_Order_By>>;
  where?: Maybe<Dynamic_Cv_Section_Bool_Exp>;
};


export type Subscription_RootDynamic_Cv_Section_By_PkArgs = {
  cv_id: Scalars['bigint'];
  type: Enum_Dynamic_Cv_Section_Type_Enum;
};


export type Subscription_RootEnum_Account_Notice_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Account_Notice_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Account_Notice_Type_Order_By>>;
  where?: Maybe<Enum_Account_Notice_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Account_Notice_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Account_Notice_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Account_Notice_Type_Order_By>>;
  where?: Maybe<Enum_Account_Notice_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Account_Notice_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Achievement_TagsArgs = {
  distinct_on?: Maybe<Array<Enum_Achievement_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Achievement_Tags_Order_By>>;
  where?: Maybe<Enum_Achievement_Tags_Bool_Exp>;
};


export type Subscription_RootEnum_Achievement_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Achievement_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Achievement_Tags_Order_By>>;
  where?: Maybe<Enum_Achievement_Tags_Bool_Exp>;
};


export type Subscription_RootEnum_Achievement_Tags_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Add_Artwork_Flow_StepArgs = {
  distinct_on?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Order_By>>;
  where?: Maybe<Enum_Add_Artwork_Flow_Step_Bool_Exp>;
};


export type Subscription_RootEnum_Add_Artwork_Flow_Step_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Add_Artwork_Flow_Step_Order_By>>;
  where?: Maybe<Enum_Add_Artwork_Flow_Step_Bool_Exp>;
};


export type Subscription_RootEnum_Add_Artwork_Flow_Step_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Analytics_KaleidoCard_CategoryArgs = {
  distinct_on?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Order_By>>;
  where?: Maybe<Enum_Analytics_KaleidoCard_Category_Bool_Exp>;
};


export type Subscription_RootEnum_Analytics_KaleidoCard_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Analytics_KaleidoCard_Category_Order_By>>;
  where?: Maybe<Enum_Analytics_KaleidoCard_Category_Bool_Exp>;
};


export type Subscription_RootEnum_Analytics_KaleidoCard_Category_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Analytics_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Analytics_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Analytics_Type_Order_By>>;
  where?: Maybe<Enum_Analytics_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Analytics_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Analytics_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Analytics_Type_Order_By>>;
  where?: Maybe<Enum_Analytics_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Analytics_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_App_Notification_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_App_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_App_Notification_Type_Order_By>>;
  where?: Maybe<Enum_App_Notification_Type_Bool_Exp>;
};


export type Subscription_RootEnum_App_Notification_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_App_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_App_Notification_Type_Order_By>>;
  where?: Maybe<Enum_App_Notification_Type_Bool_Exp>;
};


export type Subscription_RootEnum_App_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Art_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Art_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Art_Type_Order_By>>;
  where?: Maybe<Enum_Art_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Art_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Art_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Art_Type_Order_By>>;
  where?: Maybe<Enum_Art_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Art_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Art_Type_ExtensionArgs = {
  distinct_on?: Maybe<Array<Enum_Art_Type_Extension_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Art_Type_Extension_Order_By>>;
  where?: Maybe<Enum_Art_Type_Extension_Bool_Exp>;
};


export type Subscription_RootEnum_Art_Type_Extension_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Art_Type_Extension_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Art_Type_Extension_Order_By>>;
  where?: Maybe<Enum_Art_Type_Extension_Bool_Exp>;
};


export type Subscription_RootEnum_Art_Type_Extension_By_PkArgs = {
  Enum_art_type: Enum_Art_Type_Enum;
};


export type Subscription_RootEnum_Artist_Collection_CategoriesArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_Categories_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_Categories_Bool_Exp>;
};


export type Subscription_RootEnum_Artist_Collection_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_Categories_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_Categories_Bool_Exp>;
};


export type Subscription_RootEnum_Artist_Collection_Categories_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artist_Collection_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_State_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_State_Bool_Exp>;
};


export type Subscription_RootEnum_Artist_Collection_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_State_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_State_Bool_Exp>;
};


export type Subscription_RootEnum_Artist_Collection_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artist_Collection_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_Type_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artist_Collection_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artist_Collection_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artist_Collection_Type_Order_By>>;
  where?: Maybe<Enum_Artist_Collection_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artist_Collection_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_CategoryArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Category_Order_By>>;
  where?: Maybe<Enum_Artwork_Category_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Category_Order_By>>;
  where?: Maybe<Enum_Artwork_Category_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Category_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_Count_Milestone_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Count_Milestone_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Count_Milestone_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Count_Milestone_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Count_Milestone_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Count_Milestone_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_DisciplineArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Discipline_Order_By>>;
  where?: Maybe<Enum_Artwork_Discipline_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Discipline_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Discipline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Discipline_Order_By>>;
  where?: Maybe<Enum_Artwork_Discipline_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Discipline_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_Edition_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Edition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Edition_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Edition_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Edition_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Edition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Edition_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Edition_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Edition_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_Event_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Event_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Event_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Event_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Event_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Event_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Event_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_GenreArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Genre_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Genre_Order_By>>;
  where?: Maybe<Enum_Artwork_Genre_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Genre_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Genre_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Genre_Order_By>>;
  where?: Maybe<Enum_Artwork_Genre_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Genre_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_Purchase_Journey_History_Entry_KindArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Purchase_Journey_History_Entry_Kind_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_History_Entry_Kind_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Purchase_Journey_History_Entry_Kind_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_Purchase_Journey_PartyArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Party_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Purchase_Journey_Party_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_Party_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Party_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Purchase_Journey_Party_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_Purchase_Journey_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Purchase_Journey_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Purchase_Journey_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Purchase_Journey_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Purchase_Journey_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_Showroom_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Showroom_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Showroom_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Showroom_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Showroom_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Showroom_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Showroom_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Showroom_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Showroom_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Status_Order_By>>;
  where?: Maybe<Enum_Artwork_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_TagArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Tag_Order_By>>;
  where?: Maybe<Enum_Artwork_Tag_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Tag_Order_By>>;
  where?: Maybe<Enum_Artwork_Tag_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Tag_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_Tag_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Tag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Tag_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Tag_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Tag_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Tag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Tag_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Tag_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Tag_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Artwork_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Artwork_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Artwork_Type_Order_By>>;
  where?: Maybe<Enum_Artwork_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Artwork_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Audio_Generation_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Audio_Generation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Audio_Generation_Status_Order_By>>;
  where?: Maybe<Enum_Audio_Generation_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Audio_Generation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Audio_Generation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Audio_Generation_Status_Order_By>>;
  where?: Maybe<Enum_Audio_Generation_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Audio_Generation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Auth_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Auth_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Auth_Type_Order_By>>;
  where?: Maybe<Enum_Auth_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Auth_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Auth_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Auth_Type_Order_By>>;
  where?: Maybe<Enum_Auth_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Auth_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Billing_Artwork_Payment_Intent_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Artwork_Payment_Intent_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Artwork_Payment_Intent_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Artwork_Payment_Intent_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Artwork_Payment_Intent_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Billing_Payment_Intent_Product_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Payment_Intent_Product_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Payment_Intent_Product_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Payment_Intent_Product_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Payment_Intent_Product_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Billing_Seller_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Seller_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Seller_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Seller_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Seller_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Seller_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Seller_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Seller_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Seller_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Billing_Subscription_PeriodArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Period_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Period_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Period_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Subscription_Period_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Period_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Period_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Period_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Subscription_Period_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Billing_Subscription_Product_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Product_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Subscription_Product_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Product_Type_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Product_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Subscription_Product_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Billing_Subscription_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Subscription_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Billing_Subscription_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Billing_Subscription_Status_Order_By>>;
  where?: Maybe<Enum_Billing_Subscription_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Billing_Subscription_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_BlockchainArgs = {
  distinct_on?: Maybe<Array<Enum_Blockchain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Blockchain_Order_By>>;
  where?: Maybe<Enum_Blockchain_Bool_Exp>;
};


export type Subscription_RootEnum_Blockchain_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Blockchain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Blockchain_Order_By>>;
  where?: Maybe<Enum_Blockchain_Bool_Exp>;
};


export type Subscription_RootEnum_Blockchain_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Collaboration_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Collaboration_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Collaboration_Status_Order_By>>;
  where?: Maybe<Enum_Collaboration_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Collaboration_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Collaboration_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Collaboration_Status_Order_By>>;
  where?: Maybe<Enum_Collaboration_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Collaboration_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Collection_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Collection_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Collection_Type_Order_By>>;
  where?: Maybe<Enum_Collection_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Collection_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Collection_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Collection_Type_Order_By>>;
  where?: Maybe<Enum_Collection_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Collection_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Connected_Domain_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Connected_Domain_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Connected_Domain_State_Order_By>>;
  where?: Maybe<Enum_Connected_Domain_State_Bool_Exp>;
};


export type Subscription_RootEnum_Connected_Domain_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Connected_Domain_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Connected_Domain_State_Order_By>>;
  where?: Maybe<Enum_Connected_Domain_State_Bool_Exp>;
};


export type Subscription_RootEnum_Connected_Domain_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Content_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Content_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Content_Type_Order_By>>;
  where?: Maybe<Enum_Content_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Content_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Content_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Content_Type_Order_By>>;
  where?: Maybe<Enum_Content_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Content_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Context_Delegate_RoleArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Delegate_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Delegate_Role_Order_By>>;
  where?: Maybe<Enum_Context_Delegate_Role_Bool_Exp>;
};


export type Subscription_RootEnum_Context_Delegate_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Delegate_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Delegate_Role_Order_By>>;
  where?: Maybe<Enum_Context_Delegate_Role_Bool_Exp>;
};


export type Subscription_RootEnum_Context_Delegate_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Context_Delegate_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Delegate_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Delegate_State_Order_By>>;
  where?: Maybe<Enum_Context_Delegate_State_Bool_Exp>;
};


export type Subscription_RootEnum_Context_Delegate_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Delegate_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Delegate_State_Order_By>>;
  where?: Maybe<Enum_Context_Delegate_State_Bool_Exp>;
};


export type Subscription_RootEnum_Context_Delegate_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Context_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_State_Order_By>>;
  where?: Maybe<Enum_Context_State_Bool_Exp>;
};


export type Subscription_RootEnum_Context_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_State_Order_By>>;
  where?: Maybe<Enum_Context_State_Bool_Exp>;
};


export type Subscription_RootEnum_Context_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Context_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Type_Order_By>>;
  where?: Maybe<Enum_Context_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Context_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Context_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Context_Type_Order_By>>;
  where?: Maybe<Enum_Context_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Context_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Discount_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_State_Order_By>>;
  where?: Maybe<Enum_Discount_State_Bool_Exp>;
};


export type Subscription_RootEnum_Discount_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_State_Order_By>>;
  where?: Maybe<Enum_Discount_State_Bool_Exp>;
};


export type Subscription_RootEnum_Discount_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Discount_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_Type_Order_By>>;
  where?: Maybe<Enum_Discount_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Discount_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_Type_Order_By>>;
  where?: Maybe<Enum_Discount_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Discount_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Discount_Value_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_Value_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_Value_Type_Order_By>>;
  where?: Maybe<Enum_Discount_Value_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Discount_Value_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Discount_Value_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Discount_Value_Type_Order_By>>;
  where?: Maybe<Enum_Discount_Value_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Discount_Value_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Domain_Registration_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Domain_Registration_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Domain_Registration_Status_Order_By>>;
  where?: Maybe<Enum_Domain_Registration_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Domain_Registration_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Domain_Registration_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Domain_Registration_Status_Order_By>>;
  where?: Maybe<Enum_Domain_Registration_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Domain_Registration_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Dynamic_Cv_Exhibition_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Dynamic_Cv_Exhibition_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Exhibition_Type_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Exhibition_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Dynamic_Cv_Exhibition_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Dynamic_Cv_Section_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Section_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Dynamic_Cv_Section_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Section_Type_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Section_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Dynamic_Cv_Section_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Dynamic_Cv_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Status_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Dynamic_Cv_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Dynamic_Cv_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Dynamic_Cv_Status_Order_By>>;
  where?: Maybe<Enum_Dynamic_Cv_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Dynamic_Cv_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Edition_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Edition_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Edition_Status_Order_By>>;
  where?: Maybe<Enum_Edition_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Edition_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Edition_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Edition_Status_Order_By>>;
  where?: Maybe<Enum_Edition_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Edition_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Email_LanguageArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Language_Order_By>>;
  where?: Maybe<Enum_Email_Language_Bool_Exp>;
};


export type Subscription_RootEnum_Email_Language_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Language_Order_By>>;
  where?: Maybe<Enum_Email_Language_Bool_Exp>;
};


export type Subscription_RootEnum_Email_Language_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Email_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Status_Order_By>>;
  where?: Maybe<Enum_Email_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Email_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Status_Order_By>>;
  where?: Maybe<Enum_Email_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Email_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Email_Validation_Token_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Validation_Token_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Validation_Token_Status_Order_By>>;
  where?: Maybe<Enum_Email_Validation_Token_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Email_Validation_Token_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Email_Validation_Token_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Email_Validation_Token_Status_Order_By>>;
  where?: Maybe<Enum_Email_Validation_Token_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Email_Validation_Token_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_File_Metadata_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_File_Metadata_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_File_Metadata_Status_Order_By>>;
  where?: Maybe<Enum_File_Metadata_Status_Bool_Exp>;
};


export type Subscription_RootEnum_File_Metadata_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_File_Metadata_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_File_Metadata_Status_Order_By>>;
  where?: Maybe<Enum_File_Metadata_Status_Bool_Exp>;
};


export type Subscription_RootEnum_File_Metadata_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Flags_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Flags_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Flags_Type_Order_By>>;
  where?: Maybe<Enum_Flags_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Flags_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Flags_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Flags_Type_Order_By>>;
  where?: Maybe<Enum_Flags_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Flags_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Forge_Configuration_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Forge_Configuration_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Forge_Configuration_Type_Order_By>>;
  where?: Maybe<Enum_Forge_Configuration_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Forge_Configuration_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Forge_Configuration_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Forge_Configuration_Type_Order_By>>;
  where?: Maybe<Enum_Forge_Configuration_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Forge_Configuration_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Subscription_RootEnum_Forge_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Forge_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Forge_Status_Order_By>>;
  where?: Maybe<Enum_Forge_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Forge_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Forge_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Forge_Status_Order_By>>;
  where?: Maybe<Enum_Forge_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Forge_Status_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Subscription_RootEnum_Infraction_Flag_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Infraction_Flag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Infraction_Flag_Type_Order_By>>;
  where?: Maybe<Enum_Infraction_Flag_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Infraction_Flag_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Infraction_Flag_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Infraction_Flag_Type_Order_By>>;
  where?: Maybe<Enum_Infraction_Flag_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Infraction_Flag_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Interest_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Interest_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Interest_Type_Order_By>>;
  where?: Maybe<Enum_Interest_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Interest_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Interest_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Interest_Type_Order_By>>;
  where?: Maybe<Enum_Interest_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Interest_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Subscription_RootEnum_Language_PreferenceArgs = {
  distinct_on?: Maybe<Array<Enum_Language_Preference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Language_Preference_Order_By>>;
  where?: Maybe<Enum_Language_Preference_Bool_Exp>;
};


export type Subscription_RootEnum_Language_Preference_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Language_Preference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Language_Preference_Order_By>>;
  where?: Maybe<Enum_Language_Preference_Bool_Exp>;
};


export type Subscription_RootEnum_Language_Preference_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_My_Website_Artwork_InfoArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Artwork_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Artwork_Info_Order_By>>;
  where?: Maybe<Enum_My_Website_Artwork_Info_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Artwork_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Artwork_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Artwork_Info_Order_By>>;
  where?: Maybe<Enum_My_Website_Artwork_Info_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Artwork_Info_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_My_Website_FiltersArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Filters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Filters_Order_By>>;
  where?: Maybe<Enum_My_Website_Filters_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Filters_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Filters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Filters_Order_By>>;
  where?: Maybe<Enum_My_Website_Filters_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Filters_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_My_Website_LayoutArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Layout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Layout_Order_By>>;
  where?: Maybe<Enum_My_Website_Layout_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Layout_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Layout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Layout_Order_By>>;
  where?: Maybe<Enum_My_Website_Layout_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Layout_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_My_Website_NavigationArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Navigation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Navigation_Order_By>>;
  where?: Maybe<Enum_My_Website_Navigation_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Navigation_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Navigation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Navigation_Order_By>>;
  where?: Maybe<Enum_My_Website_Navigation_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Navigation_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_My_Website_ThemeArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Theme_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Theme_Order_By>>;
  where?: Maybe<Enum_My_Website_Theme_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Theme_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_My_Website_Theme_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_My_Website_Theme_Order_By>>;
  where?: Maybe<Enum_My_Website_Theme_Bool_Exp>;
};


export type Subscription_RootEnum_My_Website_Theme_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Notification_ChannelArgs = {
  distinct_on?: Maybe<Array<Enum_Notification_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Notification_Channel_Order_By>>;
  where?: Maybe<Enum_Notification_Channel_Bool_Exp>;
};


export type Subscription_RootEnum_Notification_Channel_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Notification_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Notification_Channel_Order_By>>;
  where?: Maybe<Enum_Notification_Channel_Bool_Exp>;
};


export type Subscription_RootEnum_Notification_Channel_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Notification_GroupArgs = {
  distinct_on?: Maybe<Array<Enum_Notification_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Notification_Group_Order_By>>;
  where?: Maybe<Enum_Notification_Group_Bool_Exp>;
};


export type Subscription_RootEnum_Notification_Group_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Notification_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Notification_Group_Order_By>>;
  where?: Maybe<Enum_Notification_Group_Bool_Exp>;
};


export type Subscription_RootEnum_Notification_Group_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Position_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Position_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Position_Type_Order_By>>;
  where?: Maybe<Enum_Position_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Position_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Position_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Position_Type_Order_By>>;
  where?: Maybe<Enum_Position_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Position_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Price_ScopeArgs = {
  distinct_on?: Maybe<Array<Enum_Price_Scope_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Price_Scope_Order_By>>;
  where?: Maybe<Enum_Price_Scope_Bool_Exp>;
};


export type Subscription_RootEnum_Price_Scope_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Price_Scope_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Price_Scope_Order_By>>;
  where?: Maybe<Enum_Price_Scope_Bool_Exp>;
};


export type Subscription_RootEnum_Price_Scope_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Print_TechniqueArgs = {
  distinct_on?: Maybe<Array<Enum_Print_Technique_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Print_Technique_Order_By>>;
  where?: Maybe<Enum_Print_Technique_Bool_Exp>;
};


export type Subscription_RootEnum_Print_Technique_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Print_Technique_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Print_Technique_Order_By>>;
  where?: Maybe<Enum_Print_Technique_Bool_Exp>;
};


export type Subscription_RootEnum_Print_Technique_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEnum_Profile_Background_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Background_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Background_Type_Order_By>>;
  where?: Maybe<Enum_Profile_Background_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Background_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Background_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Background_Type_Order_By>>;
  where?: Maybe<Enum_Profile_Background_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Background_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Profile_Invitation_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Invitation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Invitation_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Invitation_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Invitation_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Invitation_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Invitation_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Invitation_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Invitation_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Profile_Public_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Public_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Public_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Public_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Public_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Public_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Public_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Public_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Public_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Profile_RegionArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Region_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Region_Order_By>>;
  where?: Maybe<Enum_Profile_Region_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Region_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Region_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Region_Order_By>>;
  where?: Maybe<Enum_Profile_Region_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Region_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Profile_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Status_Order_By>>;
  where?: Maybe<Enum_Profile_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Profile_ThemeArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Theme_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Theme_Order_By>>;
  where?: Maybe<Enum_Profile_Theme_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Theme_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Theme_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Theme_Order_By>>;
  where?: Maybe<Enum_Profile_Theme_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Theme_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Profile_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Type_Order_By>>;
  where?: Maybe<Enum_Profile_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Profile_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Profile_Type_Order_By>>;
  where?: Maybe<Enum_Profile_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Profile_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Subscription_RootEnum_Pseudo_User_ActionArgs = {
  distinct_on?: Maybe<Array<Enum_Pseudo_User_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Pseudo_User_Action_Order_By>>;
  where?: Maybe<Enum_Pseudo_User_Action_Bool_Exp>;
};


export type Subscription_RootEnum_Pseudo_User_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Pseudo_User_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Pseudo_User_Action_Order_By>>;
  where?: Maybe<Enum_Pseudo_User_Action_Bool_Exp>;
};


export type Subscription_RootEnum_Pseudo_User_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Push_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Push_Notification_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Push_Notification_Status_Order_By>>;
  where?: Maybe<Enum_Push_Notification_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Push_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Push_Notification_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Push_Notification_Status_Order_By>>;
  where?: Maybe<Enum_Push_Notification_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Push_Notification_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Push_Notification_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Push_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Push_Notification_Type_Order_By>>;
  where?: Maybe<Enum_Push_Notification_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Push_Notification_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Push_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Push_Notification_Type_Order_By>>;
  where?: Maybe<Enum_Push_Notification_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Push_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Questionnaire_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Questionnaire_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Questionnaire_Type_Order_By>>;
  where?: Maybe<Enum_Questionnaire_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Questionnaire_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Questionnaire_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Questionnaire_Type_Order_By>>;
  where?: Maybe<Enum_Questionnaire_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Questionnaire_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Render_Request_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Render_Request_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Render_Request_Status_Order_By>>;
  where?: Maybe<Enum_Render_Request_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Render_Request_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Render_Request_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Render_Request_Status_Order_By>>;
  where?: Maybe<Enum_Render_Request_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Render_Request_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Render_Request_Template_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Render_Request_Template_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Render_Request_Template_Type_Order_By>>;
  where?: Maybe<Enum_Render_Request_Template_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Render_Request_Template_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Render_Request_Template_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Render_Request_Template_Type_Order_By>>;
  where?: Maybe<Enum_Render_Request_Template_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Render_Request_Template_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Restricted_Handle_NamesArgs = {
  distinct_on?: Maybe<Array<Enum_Restricted_Handle_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Restricted_Handle_Names_Order_By>>;
  where?: Maybe<Enum_Restricted_Handle_Names_Bool_Exp>;
};


export type Subscription_RootEnum_Restricted_Handle_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Restricted_Handle_Names_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Restricted_Handle_Names_Order_By>>;
  where?: Maybe<Enum_Restricted_Handle_Names_Bool_Exp>;
};


export type Subscription_RootEnum_Restricted_Handle_Names_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_SizeArgs = {
  distinct_on?: Maybe<Array<Enum_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Size_Order_By>>;
  where?: Maybe<Enum_Size_Bool_Exp>;
};


export type Subscription_RootEnum_Size_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Size_Order_By>>;
  where?: Maybe<Enum_Size_Bool_Exp>;
};


export type Subscription_RootEnum_Size_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootEnum_Social_Graph_Followers_StateArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Graph_Followers_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Graph_Followers_State_Order_By>>;
  where?: Maybe<Enum_Social_Graph_Followers_State_Bool_Exp>;
};


export type Subscription_RootEnum_Social_Graph_Followers_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Graph_Followers_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Graph_Followers_State_Order_By>>;
  where?: Maybe<Enum_Social_Graph_Followers_State_Bool_Exp>;
};


export type Subscription_RootEnum_Social_Graph_Followers_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Social_Link_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Link_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Link_Status_Order_By>>;
  where?: Maybe<Enum_Social_Link_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Social_Link_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Link_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Link_Status_Order_By>>;
  where?: Maybe<Enum_Social_Link_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Social_Link_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Social_Link_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Link_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Link_Type_Order_By>>;
  where?: Maybe<Enum_Social_Link_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Social_Link_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Social_Link_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Social_Link_Type_Order_By>>;
  where?: Maybe<Enum_Social_Link_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Social_Link_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Timed_Event_NameArgs = {
  distinct_on?: Maybe<Array<Enum_Timed_Event_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Timed_Event_Name_Order_By>>;
  where?: Maybe<Enum_Timed_Event_Name_Bool_Exp>;
};


export type Subscription_RootEnum_Timed_Event_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Timed_Event_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Timed_Event_Name_Order_By>>;
  where?: Maybe<Enum_Timed_Event_Name_Bool_Exp>;
};


export type Subscription_RootEnum_Timed_Event_Name_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Token_Storage_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Token_Storage_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Token_Storage_Status_Order_By>>;
  where?: Maybe<Enum_Token_Storage_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Token_Storage_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Token_Storage_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Token_Storage_Status_Order_By>>;
  where?: Maybe<Enum_Token_Storage_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Token_Storage_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Transaction_OriginArgs = {
  distinct_on?: Maybe<Array<Enum_Transaction_Origin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Transaction_Origin_Order_By>>;
  where?: Maybe<Enum_Transaction_Origin_Bool_Exp>;
};


export type Subscription_RootEnum_Transaction_Origin_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Transaction_Origin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Transaction_Origin_Order_By>>;
  where?: Maybe<Enum_Transaction_Origin_Bool_Exp>;
};


export type Subscription_RootEnum_Transaction_Origin_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Transaction_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Transaction_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Transaction_Status_Order_By>>;
  where?: Maybe<Enum_Transaction_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Transaction_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Transaction_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Transaction_Status_Order_By>>;
  where?: Maybe<Enum_Transaction_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Transaction_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Tutorial_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Status_Order_By>>;
  where?: Maybe<Enum_Tutorial_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Tutorial_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Status_Order_By>>;
  where?: Maybe<Enum_Tutorial_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Tutorial_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Tutorial_Step_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Step_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Step_Type_Order_By>>;
  where?: Maybe<Enum_Tutorial_Step_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Tutorial_Step_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Step_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Step_Type_Order_By>>;
  where?: Maybe<Enum_Tutorial_Step_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Tutorial_Step_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Tutorial_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Type_Order_By>>;
  where?: Maybe<Enum_Tutorial_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Tutorial_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Tutorial_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Tutorial_Type_Order_By>>;
  where?: Maybe<Enum_Tutorial_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Tutorial_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootEnum_Vumark_StatusArgs = {
  distinct_on?: Maybe<Array<Enum_Vumark_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Vumark_Status_Order_By>>;
  where?: Maybe<Enum_Vumark_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Vumark_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Vumark_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Vumark_Status_Order_By>>;
  where?: Maybe<Enum_Vumark_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Vumark_Status_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Subscription_RootEnum_Vumark_TypeArgs = {
  distinct_on?: Maybe<Array<Enum_Vumark_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Vumark_Type_Order_By>>;
  where?: Maybe<Enum_Vumark_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Vumark_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_Vumark_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_Vumark_Type_Order_By>>;
  where?: Maybe<Enum_Vumark_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Vumark_Type_By_PkArgs = {
  VALUE: Scalars['String'];
};


export type Subscription_RootEditionArgs = {
  distinct_on?: Maybe<Array<Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Order_By>>;
  where?: Maybe<Edition_Bool_Exp>;
};


export type Subscription_RootEdition_By_SizeArgs = {
  distinct_on?: Maybe<Array<Edition_By_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Size_Order_By>>;
  where?: Maybe<Edition_By_Size_Bool_Exp>;
};


export type Subscription_RootEdition_By_Size_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_By_Size_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Size_Order_By>>;
  where?: Maybe<Edition_By_Size_Bool_Exp>;
};


export type Subscription_RootEdition_By_Size_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEdition_By_TypeArgs = {
  distinct_on?: Maybe<Array<Edition_By_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Type_Order_By>>;
  where?: Maybe<Edition_By_Type_Bool_Exp>;
};


export type Subscription_RootEdition_By_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_By_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_By_Type_Order_By>>;
  where?: Maybe<Edition_By_Type_Bool_Exp>;
};


export type Subscription_RootEdition_By_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEdition_TypeArgs = {
  distinct_on?: Maybe<Array<Edition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Type_Order_By>>;
  where?: Maybe<Edition_Type_Bool_Exp>;
};


export type Subscription_RootEdition_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Type_Order_By>>;
  where?: Maybe<Edition_Type_Bool_Exp>;
};


export type Subscription_RootEdition_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEdition_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Order_By>>;
  where?: Maybe<Edition_Bool_Exp>;
};


export type Subscription_RootEdition_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEdition_EventsArgs = {
  distinct_on?: Maybe<Array<Edition_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Events_Order_By>>;
  where?: Maybe<Edition_Events_Bool_Exp>;
};


export type Subscription_RootEdition_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Edition_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Edition_Events_Order_By>>;
  where?: Maybe<Edition_Events_Bool_Exp>;
};


export type Subscription_RootEdition_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmail_Validation_TokenArgs = {
  distinct_on?: Maybe<Array<Email_Validation_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Validation_Token_Order_By>>;
  where?: Maybe<Email_Validation_Token_Bool_Exp>;
};


export type Subscription_RootEmail_Validation_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Validation_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Validation_Token_Order_By>>;
  where?: Maybe<Email_Validation_Token_Bool_Exp>;
};


export type Subscription_RootEmail_Validation_Token_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmailsArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


export type Subscription_RootEmails_AggregateArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


export type Subscription_RootEmails_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootExplore_ArtistsArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Order_By>>;
  where?: Maybe<Explore_Artists_Bool_Exp>;
};


export type Subscription_RootExplore_Artists_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Order_By>>;
  where?: Maybe<Explore_Artists_Bool_Exp>;
};


export type Subscription_RootExplore_Artists_ArtworksArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Artworks_Order_By>>;
  where?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
};


export type Subscription_RootExplore_Artists_Artworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Artworks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Artworks_Order_By>>;
  where?: Maybe<Explore_Artists_Artworks_Bool_Exp>;
};


export type Subscription_RootExplore_Artists_Curation_ScoreArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Curation_Score_Order_By>>;
  where?: Maybe<Explore_Artists_Curation_Score_Bool_Exp>;
};


export type Subscription_RootExplore_Artists_Curation_Score_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Curation_Score_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Curation_Score_Order_By>>;
  where?: Maybe<Explore_Artists_Curation_Score_Bool_Exp>;
};


export type Subscription_RootExplore_Artists_DisciplinesArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Disciplines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Disciplines_Order_By>>;
  where?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
};


export type Subscription_RootExplore_Artists_Disciplines_AggregateArgs = {
  distinct_on?: Maybe<Array<Explore_Artists_Disciplines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Explore_Artists_Disciplines_Order_By>>;
  where?: Maybe<Explore_Artists_Disciplines_Bool_Exp>;
};


export type Subscription_RootFcm_TokenArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


export type Subscription_RootFcm_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<Fcm_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fcm_Token_Order_By>>;
  where?: Maybe<Fcm_Token_Bool_Exp>;
};


export type Subscription_RootFcm_Token_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFeaturesArgs = {
  distinct_on?: Maybe<Array<Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Features_Order_By>>;
  where?: Maybe<Features_Bool_Exp>;
};


export type Subscription_RootFeatures_AggregateArgs = {
  distinct_on?: Maybe<Array<Features_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Features_Order_By>>;
  where?: Maybe<Features_Bool_Exp>;
};


export type Subscription_RootFeatures_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFile_MetadataArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};


export type Subscription_RootFile_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Metadata_Order_By>>;
  where?: Maybe<File_Metadata_Bool_Exp>;
};


export type Subscription_RootFile_Metadata_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFirebase_ProfileArgs = {
  distinct_on?: Maybe<Array<Firebase_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Profile_Order_By>>;
  where?: Maybe<Firebase_Profile_Bool_Exp>;
};


export type Subscription_RootFirebase_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Firebase_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Profile_Order_By>>;
  where?: Maybe<Firebase_Profile_Bool_Exp>;
};


export type Subscription_RootFirebase_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFlagged_UsersArgs = {
  distinct_on?: Maybe<Array<Flagged_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flagged_Users_Order_By>>;
  where?: Maybe<Flagged_Users_Bool_Exp>;
};


export type Subscription_RootFlagged_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Flagged_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flagged_Users_Order_By>>;
  where?: Maybe<Flagged_Users_Bool_Exp>;
};


export type Subscription_RootFlagged_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFlat_Context_AccessArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


export type Subscription_RootFlat_Context_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Flat_Context_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Context_Access_Order_By>>;
  where?: Maybe<Flat_Context_Access_Bool_Exp>;
};


export type Subscription_RootFollowing_Artists_Art_Universe_ListingArgs = {
  distinct_on?: Maybe<Array<Following_Artists_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Following_Artists_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Following_Artists_Art_Universe_Listing_Bool_Exp>;
};


export type Subscription_RootFollowing_Artists_Art_Universe_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Following_Artists_Art_Universe_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Following_Artists_Art_Universe_Listing_Order_By>>;
  where?: Maybe<Following_Artists_Art_Universe_Listing_Bool_Exp>;
};


export type Subscription_RootForge_InProcess_TaskArgs = {
  distinct_on?: Maybe<Array<Forge_InProcess_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forge_InProcess_Task_Order_By>>;
  where?: Maybe<Forge_InProcess_Task_Bool_Exp>;
};


export type Subscription_RootForge_InProcess_Task_AggregateArgs = {
  distinct_on?: Maybe<Array<Forge_InProcess_Task_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forge_InProcess_Task_Order_By>>;
  where?: Maybe<Forge_InProcess_Task_Bool_Exp>;
};


export type Subscription_RootForge_InProcess_Task_By_PkArgs = {
  forge_task_id: Scalars['bigint'];
};


export type Subscription_RootForge_TasksArgs = {
  distinct_on?: Maybe<Array<Forge_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forge_Tasks_Order_By>>;
  where?: Maybe<Forge_Tasks_Bool_Exp>;
};


export type Subscription_RootForge_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Forge_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Forge_Tasks_Order_By>>;
  where?: Maybe<Forge_Tasks_Bool_Exp>;
};


export type Subscription_RootForge_Tasks_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGallery_Explore_InfoArgs = {
  distinct_on?: Maybe<Array<Gallery_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_Bool_Exp>;
};


export type Subscription_RootGallery_Explore_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Explore_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_Bool_Exp>;
};


export type Subscription_RootGallery_Explore_Info_With_City_DataArgs = {
  distinct_on?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_With_City_Data_Bool_Exp>;
};


export type Subscription_RootGallery_Explore_Info_With_City_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_With_City_Data_Bool_Exp>;
};


export type Subscription_RootGlobal_PropertiesArgs = {
  distinct_on?: Maybe<Array<Global_Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Global_Properties_Order_By>>;
  where?: Maybe<Global_Properties_Bool_Exp>;
};


export type Subscription_RootGlobal_Properties_AggregateArgs = {
  distinct_on?: Maybe<Array<Global_Properties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Global_Properties_Order_By>>;
  where?: Maybe<Global_Properties_Bool_Exp>;
};


export type Subscription_RootGlobal_Properties_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootGraveyard_ArtworkArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Artwork_DetailsArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Details_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Details_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Details_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Details_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Artwork_Details_TranslationArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Details_Translation_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Details_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Details_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Details_Translation_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Details_Translation_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Details_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Artwork_EventsArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Events_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Events_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Events_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Events_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Artwork_PricesArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Prices_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Prices_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Prices_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Prices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Prices_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Prices_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Prices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Artwork_Secondary_MediaArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Secondary_Media_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Secondary_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Secondary_Media_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Secondary_Media_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Secondary_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Artwork_StoryArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Story_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Story_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Story_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Story_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Story_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Story_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Story_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Artwork_Story_MediaArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Story_Media_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Story_Media_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Story_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Story_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Story_Media_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Story_Media_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Story_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Artwork_To_CollectionArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_To_Collection_Order_By>>;
  where?: Maybe<Graveyard_Artwork_To_Collection_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_To_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_To_Collection_Order_By>>;
  where?: Maybe<Graveyard_Artwork_To_Collection_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_To_Collection_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Artwork_TransactionsArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Transactions_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Transactions_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Artwork_Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Artwork_Transactions_Order_By>>;
  where?: Maybe<Graveyard_Artwork_Transactions_Bool_Exp>;
};


export type Subscription_RootGraveyard_Artwork_Transactions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGraveyard_Purchase_InquiresArgs = {
  distinct_on?: Maybe<Array<Graveyard_Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Purchase_Inquires_Order_By>>;
  where?: Maybe<Graveyard_Purchase_Inquires_Bool_Exp>;
};


export type Subscription_RootGraveyard_Purchase_Inquires_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Purchase_Inquires_Order_By>>;
  where?: Maybe<Graveyard_Purchase_Inquires_Bool_Exp>;
};


export type Subscription_RootGraveyard_Purchase_Inquires_Outside_UsersArgs = {
  distinct_on?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp>;
};


export type Subscription_RootGraveyard_Purchase_Inquires_Outside_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Graveyard_Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Graveyard_Purchase_Inquires_Outside_Users_Bool_Exp>;
};


export type Subscription_RootInconsistent_Missing_Domain_Renewal_SubscriptionArgs = {
  distinct_on?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Order_By>>;
  where?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Bool_Exp>;
};


export type Subscription_RootInconsistent_Missing_Domain_Renewal_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inconsistent_Missing_Domain_Renewal_Subscription_Order_By>>;
  where?: Maybe<Inconsistent_Missing_Domain_Renewal_Subscription_Bool_Exp>;
};


export type Subscription_RootInterestsArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


export type Subscription_RootInterests_AggregateArgs = {
  distinct_on?: Maybe<Array<Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Interests_Order_By>>;
  where?: Maybe<Interests_Bool_Exp>;
};


export type Subscription_RootInterests_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootKaleido_General_SettingsArgs = {
  distinct_on?: Maybe<Array<Kaleido_General_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_General_Settings_Order_By>>;
  where?: Maybe<Kaleido_General_Settings_Bool_Exp>;
};


export type Subscription_RootKaleido_General_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Kaleido_General_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_General_Settings_Order_By>>;
  where?: Maybe<Kaleido_General_Settings_Bool_Exp>;
};


export type Subscription_RootKaleido_General_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootKaleido_Live_ListingArgs = {
  distinct_on?: Maybe<Array<Kaleido_Live_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Live_Listing_Order_By>>;
  where?: Maybe<Kaleido_Live_Listing_Bool_Exp>;
};


export type Subscription_RootKaleido_Live_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Kaleido_Live_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Live_Listing_Order_By>>;
  where?: Maybe<Kaleido_Live_Listing_Bool_Exp>;
};


export type Subscription_RootLanding_Page_ArtworkArgs = {
  distinct_on?: Maybe<Array<Landing_Page_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Landing_Page_Artwork_Order_By>>;
  where?: Maybe<Landing_Page_Artwork_Bool_Exp>;
};


export type Subscription_RootLanding_Page_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Landing_Page_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Landing_Page_Artwork_Order_By>>;
  where?: Maybe<Landing_Page_Artwork_Bool_Exp>;
};


export type Subscription_RootLanding_Page_Artwork_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLanguagesArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};


export type Subscription_RootLanguages_AggregateArgs = {
  distinct_on?: Maybe<Array<Languages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Languages_Order_By>>;
  where?: Maybe<Languages_Bool_Exp>;
};


export type Subscription_RootLanguages_By_PkArgs = {
  language_code: Scalars['String'];
};


export type Subscription_RootMaterialized_ViewArgs = {
  distinct_on?: Maybe<Array<Materialized_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Materialized_View_Order_By>>;
  where?: Maybe<Materialized_View_Bool_Exp>;
};


export type Subscription_RootMaterialized_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Materialized_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Materialized_View_Order_By>>;
  where?: Maybe<Materialized_View_Bool_Exp>;
};


export type Subscription_RootMaterialized_View_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootMedia_MetadataArgs = {
  distinct_on?: Maybe<Array<Media_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Metadata_Order_By>>;
  where?: Maybe<Media_Metadata_Bool_Exp>;
};


export type Subscription_RootMedia_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Metadata_Order_By>>;
  where?: Maybe<Media_Metadata_Bool_Exp>;
};


export type Subscription_RootMedia_Metadata_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootMilestoneArgs = {
  distinct_on?: Maybe<Array<Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Milestone_Order_By>>;
  where?: Maybe<Milestone_Bool_Exp>;
};


export type Subscription_RootMilestone_AggregateArgs = {
  distinct_on?: Maybe<Array<Milestone_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Milestone_Order_By>>;
  where?: Maybe<Milestone_Bool_Exp>;
};


export type Subscription_RootMilestone_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootMuseum_Artwork_ListingArgs = {
  distinct_on?: Maybe<Array<Museum_Artwork_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Museum_Artwork_Listing_Order_By>>;
  where?: Maybe<Museum_Artwork_Listing_Bool_Exp>;
};


export type Subscription_RootMuseum_Artwork_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Museum_Artwork_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Museum_Artwork_Listing_Order_By>>;
  where?: Maybe<Museum_Artwork_Listing_Bool_Exp>;
};


export type Subscription_RootMyStudio_SubscriptorsArgs = {
  distinct_on?: Maybe<Array<MyStudio_Subscriptors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MyStudio_Subscriptors_Order_By>>;
  where?: Maybe<MyStudio_Subscriptors_Bool_Exp>;
};


export type Subscription_RootMyStudio_Subscriptors_AggregateArgs = {
  distinct_on?: Maybe<Array<MyStudio_Subscriptors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MyStudio_Subscriptors_Order_By>>;
  where?: Maybe<MyStudio_Subscriptors_Bool_Exp>;
};


export type Subscription_RootMy_Website_SettingsArgs = {
  distinct_on?: Maybe<Array<My_Website_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Website_Settings_Order_By>>;
  where?: Maybe<My_Website_Settings_Bool_Exp>;
};


export type Subscription_RootMy_Website_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<My_Website_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Website_Settings_Order_By>>;
  where?: Maybe<My_Website_Settings_Bool_Exp>;
};


export type Subscription_RootMy_Website_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootMy_Studio_SettingsArgs = {
  distinct_on?: Maybe<Array<My_Studio_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Studio_Settings_Order_By>>;
  where?: Maybe<My_Studio_Settings_Bool_Exp>;
};


export type Subscription_RootMy_Studio_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<My_Studio_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<My_Studio_Settings_Order_By>>;
  where?: Maybe<My_Studio_Settings_Bool_Exp>;
};


export type Subscription_RootMy_Studio_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootNotification_PreferenceArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Preference_Order_By>>;
  where?: Maybe<Notification_Preference_Bool_Exp>;
};


export type Subscription_RootNotification_Preference_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Preference_Order_By>>;
  where?: Maybe<Notification_Preference_Bool_Exp>;
};


export type Subscription_RootNotification_Preference_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPending_Collaboration_On_ArtworkArgs = {
  distinct_on?: Maybe<Array<Pending_Collaboration_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Collaboration_On_Artwork_Order_By>>;
  where?: Maybe<Pending_Collaboration_On_Artwork_Bool_Exp>;
};


export type Subscription_RootPending_Collaboration_On_Artwork_AggregateArgs = {
  distinct_on?: Maybe<Array<Pending_Collaboration_On_Artwork_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Collaboration_On_Artwork_Order_By>>;
  where?: Maybe<Pending_Collaboration_On_Artwork_Bool_Exp>;
};


export type Subscription_RootPending_Collaboration_On_Artwork_By_PkArgs = {
  artwork_id: Scalars['bigint'];
  collaboration_id: Scalars['bigint'];
};


export type Subscription_RootPrice_RangesArgs = {
  distinct_on?: Maybe<Array<Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Ranges_Order_By>>;
  where?: Maybe<Price_Ranges_Bool_Exp>;
};


export type Subscription_RootPrice_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Ranges_Order_By>>;
  where?: Maybe<Price_Ranges_Bool_Exp>;
};


export type Subscription_RootPrice_Ranges_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPrintArgs = {
  distinct_on?: Maybe<Array<Print_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Order_By>>;
  where?: Maybe<Print_Bool_Exp>;
};


export type Subscription_RootPrint_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Order_By>>;
  where?: Maybe<Print_Bool_Exp>;
};


export type Subscription_RootPrint_AmountsArgs = {
  distinct_on?: Maybe<Array<Print_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Amounts_Order_By>>;
  where?: Maybe<Print_Amounts_Bool_Exp>;
};


export type Subscription_RootPrint_Amounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Amounts_Order_By>>;
  where?: Maybe<Print_Amounts_Bool_Exp>;
};


export type Subscription_RootPrint_Artwork_Collector_ListingArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_Collector_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_Collector_Listing_Order_By>>;
  where?: Maybe<Print_Artwork_Collector_Listing_Bool_Exp>;
};


export type Subscription_RootPrint_Artwork_Collector_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_Collector_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_Collector_Listing_Order_By>>;
  where?: Maybe<Print_Artwork_Collector_Listing_Bool_Exp>;
};


export type Subscription_RootPrint_Artwork_InfoArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_Info_Order_By>>;
  where?: Maybe<Print_Artwork_Info_Bool_Exp>;
};


export type Subscription_RootPrint_Artwork_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_Info_Order_By>>;
  where?: Maybe<Print_Artwork_Info_Bool_Exp>;
};


export type Subscription_RootPrint_Artwork_ViewArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_View_Order_By>>;
  where?: Maybe<Print_Artwork_View_Bool_Exp>;
};


export type Subscription_RootPrint_Artwork_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Artwork_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Artwork_View_Order_By>>;
  where?: Maybe<Print_Artwork_View_Bool_Exp>;
};


export type Subscription_RootPrint_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPrint_To_CollectionArgs = {
  distinct_on?: Maybe<Array<Print_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_To_Collection_Order_By>>;
  where?: Maybe<Print_To_Collection_Bool_Exp>;
};


export type Subscription_RootPrint_To_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_To_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_To_Collection_Order_By>>;
  where?: Maybe<Print_To_Collection_Bool_Exp>;
};


export type Subscription_RootPrint_To_Collection_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPrize_Art_ShowArgs = {
  distinct_on?: Maybe<Array<Prize_Art_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Prize_Art_Show_Order_By>>;
  where?: Maybe<Prize_Art_Show_Bool_Exp>;
};


export type Subscription_RootPrize_Art_Show_AggregateArgs = {
  distinct_on?: Maybe<Array<Prize_Art_Show_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Prize_Art_Show_Order_By>>;
  where?: Maybe<Prize_Art_Show_Bool_Exp>;
};


export type Subscription_RootPrize_Art_Show_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfileArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_InterestsArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_Order_By>>;
  where?: Maybe<Profile_Interests_Bool_Exp>;
};


export type Subscription_RootProfile_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_Order_By>>;
  where?: Maybe<Profile_Interests_Bool_Exp>;
};


export type Subscription_RootProfile_Interests_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_AchievementsArgs = {
  distinct_on?: Maybe<Array<Profile_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Achievements_Order_By>>;
  where?: Maybe<Profile_Achievements_Bool_Exp>;
};


export type Subscription_RootProfile_Achievements_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Achievements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Achievements_Order_By>>;
  where?: Maybe<Profile_Achievements_Bool_Exp>;
};


export type Subscription_RootProfile_Achievements_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Order_By>>;
  where?: Maybe<Profile_Bool_Exp>;
};


export type Subscription_RootProfile_Appearance_SettingsArgs = {
  distinct_on?: Maybe<Array<Profile_Appearance_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Appearance_Settings_Order_By>>;
  where?: Maybe<Profile_Appearance_Settings_Bool_Exp>;
};


export type Subscription_RootProfile_Appearance_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Appearance_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Appearance_Settings_Order_By>>;
  where?: Maybe<Profile_Appearance_Settings_Bool_Exp>;
};


export type Subscription_RootProfile_Appearance_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_BackgroundArgs = {
  distinct_on?: Maybe<Array<Profile_Background_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Background_Order_By>>;
  where?: Maybe<Profile_Background_Bool_Exp>;
};


export type Subscription_RootProfile_Background_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Background_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Background_Order_By>>;
  where?: Maybe<Profile_Background_Bool_Exp>;
};


export type Subscription_RootProfile_Background_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_Ghost_TokensArgs = {
  distinct_on?: Maybe<Array<Profile_Ghost_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Ghost_Tokens_Order_By>>;
  where?: Maybe<Profile_Ghost_Tokens_Bool_Exp>;
};


export type Subscription_RootProfile_Ghost_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Ghost_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Ghost_Tokens_Order_By>>;
  where?: Maybe<Profile_Ghost_Tokens_Bool_Exp>;
};


export type Subscription_RootProfile_Ghost_Tokens_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_InvitationsArgs = {
  distinct_on?: Maybe<Array<Profile_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Invitations_Order_By>>;
  where?: Maybe<Profile_Invitations_Bool_Exp>;
};


export type Subscription_RootProfile_Invitations_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Invitations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Invitations_Order_By>>;
  where?: Maybe<Profile_Invitations_Bool_Exp>;
};


export type Subscription_RootProfile_Invitations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_LocationArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Order_By>>;
  where?: Maybe<Profile_Location_Bool_Exp>;
};


export type Subscription_RootProfile_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Order_By>>;
  where?: Maybe<Profile_Location_Bool_Exp>;
};


export type Subscription_RootProfile_Location_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_Location_TranslationArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Translation_Order_By>>;
  where?: Maybe<Profile_Location_Translation_Bool_Exp>;
};


export type Subscription_RootProfile_Location_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Location_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Location_Translation_Order_By>>;
  where?: Maybe<Profile_Location_Translation_Bool_Exp>;
};


export type Subscription_RootProfile_Location_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_Price_RangesArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


export type Subscription_RootProfile_Price_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Price_Ranges_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Price_Ranges_Order_By>>;
  where?: Maybe<Profile_Price_Ranges_Bool_Exp>;
};


export type Subscription_RootProfile_Price_Ranges_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_Questions_And_AnswersArgs = {
  distinct_on?: Maybe<Array<Profile_Questions_And_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Questions_And_Answers_Order_By>>;
  where?: Maybe<Profile_Questions_And_Answers_Bool_Exp>;
};


export type Subscription_RootProfile_Questions_And_Answers_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Questions_And_Answers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Questions_And_Answers_Order_By>>;
  where?: Maybe<Profile_Questions_And_Answers_Bool_Exp>;
};


export type Subscription_RootProfile_Questions_And_Answers_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_SettingsArgs = {
  distinct_on?: Maybe<Array<Profile_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Settings_Order_By>>;
  where?: Maybe<Profile_Settings_Bool_Exp>;
};


export type Subscription_RootProfile_Settings_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Settings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Settings_Order_By>>;
  where?: Maybe<Profile_Settings_Bool_Exp>;
};


export type Subscription_RootProfile_Settings_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_Social_LinksArgs = {
  distinct_on?: Maybe<Array<Profile_Social_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Social_Links_Order_By>>;
  where?: Maybe<Profile_Social_Links_Bool_Exp>;
};


export type Subscription_RootProfile_Social_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Social_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Social_Links_Order_By>>;
  where?: Maybe<Profile_Social_Links_Bool_Exp>;
};


export type Subscription_RootProfile_Social_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_Statement_Audio_SynthesesArgs = {
  distinct_on?: Maybe<Array<Profile_Statement_Audio_Syntheses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Statement_Audio_Syntheses_Order_By>>;
  where?: Maybe<Profile_Statement_Audio_Syntheses_Bool_Exp>;
};


export type Subscription_RootProfile_Statement_Audio_Syntheses_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Statement_Audio_Syntheses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Statement_Audio_Syntheses_Order_By>>;
  where?: Maybe<Profile_Statement_Audio_Syntheses_Bool_Exp>;
};


export type Subscription_RootProfile_Statement_Audio_Syntheses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_TranslationArgs = {
  distinct_on?: Maybe<Array<Profile_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Translation_Order_By>>;
  where?: Maybe<Profile_Translation_Bool_Exp>;
};


export type Subscription_RootProfile_Translation_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Translation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Translation_Order_By>>;
  where?: Maybe<Profile_Translation_Bool_Exp>;
};


export type Subscription_RootProfile_Translation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_Video_LinksArgs = {
  distinct_on?: Maybe<Array<Profile_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Video_Links_Order_By>>;
  where?: Maybe<Profile_Video_Links_Bool_Exp>;
};


export type Subscription_RootProfile_Video_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Video_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Video_Links_Order_By>>;
  where?: Maybe<Profile_Video_Links_Bool_Exp>;
};


export type Subscription_RootProfile_Video_Links_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootProfile_Wallet_AddressArgs = {
  distinct_on?: Maybe<Array<Profile_Wallet_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Wallet_Address_Order_By>>;
  where?: Maybe<Profile_Wallet_Address_Bool_Exp>;
};


export type Subscription_RootProfile_Wallet_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Wallet_Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Wallet_Address_Order_By>>;
  where?: Maybe<Profile_Wallet_Address_Bool_Exp>;
};


export type Subscription_RootProfile_Wallet_Address_By_PkArgs = {
  profile_context_id: Scalars['bigint'];
};


export type Subscription_RootPubnub_ChannelsArgs = {
  distinct_on?: Maybe<Array<Pubnub_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pubnub_Channels_Order_By>>;
  where?: Maybe<Pubnub_Channels_Bool_Exp>;
};


export type Subscription_RootPubnub_Channels_AggregateArgs = {
  distinct_on?: Maybe<Array<Pubnub_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pubnub_Channels_Order_By>>;
  where?: Maybe<Pubnub_Channels_Bool_Exp>;
};


export type Subscription_RootPubnub_Channels_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootPurchase_InquiresArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


export type Subscription_RootPurchase_Inquires_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Order_By>>;
  where?: Maybe<Purchase_Inquires_Bool_Exp>;
};


export type Subscription_RootPurchase_Inquires_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPurchase_Inquires_Outside_UsersArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
};


export type Subscription_RootPurchase_Inquires_Outside_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Inquires_Outside_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Inquires_Outside_Users_Order_By>>;
  where?: Maybe<Purchase_Inquires_Outside_Users_Bool_Exp>;
};


export type Subscription_RootPurchase_Inquires_Outside_Users_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPush_NotificationsArgs = {
  distinct_on?: Maybe<Array<Push_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Push_Notifications_Order_By>>;
  where?: Maybe<Push_Notifications_Bool_Exp>;
};


export type Subscription_RootPush_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Push_Notifications_Order_By>>;
  where?: Maybe<Push_Notifications_Bool_Exp>;
};


export type Subscription_RootPush_Notifications_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootQuestionnaire_QuestionsArgs = {
  distinct_on?: Maybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questionnaire_Questions_Order_By>>;
  where?: Maybe<Questionnaire_Questions_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Questionnaire_Questions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Questionnaire_Questions_Order_By>>;
  where?: Maybe<Questionnaire_Questions_Bool_Exp>;
};


export type Subscription_RootQuestionnaire_Questions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRender_RequestArgs = {
  distinct_on?: Maybe<Array<Render_Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Render_Request_Order_By>>;
  where?: Maybe<Render_Request_Bool_Exp>;
};


export type Subscription_RootRender_Request_AggregateArgs = {
  distinct_on?: Maybe<Array<Render_Request_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Render_Request_Order_By>>;
  where?: Maybe<Render_Request_Bool_Exp>;
};


export type Subscription_RootRender_Request_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSetting_Context_Default_ProfileArgs = {
  distinct_on?: Maybe<Array<Setting_Context_Default_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Context_Default_Profile_Order_By>>;
  where?: Maybe<Setting_Context_Default_Profile_Bool_Exp>;
};


export type Subscription_RootSetting_Context_Default_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Setting_Context_Default_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Context_Default_Profile_Order_By>>;
  where?: Maybe<Setting_Context_Default_Profile_Bool_Exp>;
};


export type Subscription_RootSetting_Context_Default_Profile_By_PkArgs = {
  app_access_context_id: Scalars['bigint'];
};


export type Subscription_RootShowroom_DetailsArgs = {
  distinct_on?: Maybe<Array<Showroom_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Details_Order_By>>;
  where?: Maybe<Showroom_Details_Bool_Exp>;
};


export type Subscription_RootShowroom_Details_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Details_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Details_Order_By>>;
  where?: Maybe<Showroom_Details_Bool_Exp>;
};


export type Subscription_RootShowroom_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSocial_Graph_Target_Context_ViewArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Target_Context_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Target_Context_View_Order_By>>;
  where?: Maybe<Social_Graph_Target_Context_View_Bool_Exp>;
};


export type Subscription_RootSocial_Graph_Target_Context_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Target_Context_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Target_Context_View_Order_By>>;
  where?: Maybe<Social_Graph_Target_Context_View_Bool_Exp>;
};


export type Subscription_RootSocial_Graph_FollowersArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


export type Subscription_RootSocial_Graph_Followers_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_Order_By>>;
  where?: Maybe<Social_Graph_Followers_Bool_Exp>;
};


export type Subscription_RootSocial_Graph_Followers_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSocial_Graph_Followers_ViewArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_View_Order_By>>;
  where?: Maybe<Social_Graph_Followers_View_Bool_Exp>;
};


export type Subscription_RootSocial_Graph_Followers_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Graph_Followers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Graph_Followers_View_Order_By>>;
  where?: Maybe<Social_Graph_Followers_View_Bool_Exp>;
};


export type Subscription_RootSpotlight_ArtistArgs = {
  distinct_on?: Maybe<Array<Spotlight_Artist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spotlight_Artist_Order_By>>;
  where?: Maybe<Spotlight_Artist_Bool_Exp>;
};


export type Subscription_RootSpotlight_Artist_AggregateArgs = {
  distinct_on?: Maybe<Array<Spotlight_Artist_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spotlight_Artist_Order_By>>;
  where?: Maybe<Spotlight_Artist_Bool_Exp>;
};


export type Subscription_RootSpotlight_ContextArgs = {
  distinct_on?: Maybe<Array<Spotlight_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spotlight_Context_Order_By>>;
  where?: Maybe<Spotlight_Context_Bool_Exp>;
};


export type Subscription_RootSpotlight_Context_AggregateArgs = {
  distinct_on?: Maybe<Array<Spotlight_Context_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Spotlight_Context_Order_By>>;
  where?: Maybe<Spotlight_Context_Bool_Exp>;
};


export type Subscription_RootSpotlight_Context_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSuper_Site_SubscribersArgs = {
  distinct_on?: Maybe<Array<Super_Site_Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Super_Site_Subscribers_Order_By>>;
  where?: Maybe<Super_Site_Subscribers_Bool_Exp>;
};


export type Subscription_RootSuper_Site_Subscribers_AggregateArgs = {
  distinct_on?: Maybe<Array<Super_Site_Subscribers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Super_Site_Subscribers_Order_By>>;
  where?: Maybe<Super_Site_Subscribers_Bool_Exp>;
};


export type Subscription_RootSuper_Site_Subscribers_By_PkArgs = {
  context_id: Scalars['bigint'];
};


export type Subscription_RootTags_By_InterestArgs = {
  distinct_on?: Maybe<Array<Tags_By_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_By_Interest_Order_By>>;
  where?: Maybe<Tags_By_Interest_Bool_Exp>;
};


export type Subscription_RootTags_By_Interest_AggregateArgs = {
  distinct_on?: Maybe<Array<Tags_By_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tags_By_Interest_Order_By>>;
  where?: Maybe<Tags_By_Interest_Bool_Exp>;
};


export type Subscription_RootTags_By_Interest_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTemp_Artist_Explore_CollectionArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};


export type Subscription_RootTemp_Artist_Explore_Collection_AggregateArgs = {
  distinct_on?: Maybe<Array<Temp_Artist_Explore_Collection_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Artist_Explore_Collection_Order_By>>;
  where?: Maybe<Temp_Artist_Explore_Collection_Bool_Exp>;
};


export type Subscription_RootTest_Analytics_EventsArgs = {
  distinct_on?: Maybe<Array<Test_Analytics_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Analytics_Events_Order_By>>;
  where?: Maybe<Test_Analytics_Events_Bool_Exp>;
};


export type Subscription_RootTest_Analytics_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Analytics_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Analytics_Events_Order_By>>;
  where?: Maybe<Test_Analytics_Events_Bool_Exp>;
};


export type Subscription_RootTest_Analytics_Events_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTest_AccountsArgs = {
  distinct_on?: Maybe<Array<Test_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Accounts_Order_By>>;
  where?: Maybe<Test_Accounts_Bool_Exp>;
};


export type Subscription_RootTest_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Test_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Test_Accounts_Order_By>>;
  where?: Maybe<Test_Accounts_Bool_Exp>;
};


export type Subscription_RootTest_Accounts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTimed_EventArgs = {
  distinct_on?: Maybe<Array<Timed_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Timed_Event_Order_By>>;
  where?: Maybe<Timed_Event_Bool_Exp>;
};


export type Subscription_RootTimed_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Timed_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Timed_Event_Order_By>>;
  where?: Maybe<Timed_Event_Bool_Exp>;
};


export type Subscription_RootTimed_Event_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootToken_StorageArgs = {
  distinct_on?: Maybe<Array<Token_Storage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Token_Storage_Order_By>>;
  where?: Maybe<Token_Storage_Bool_Exp>;
};


export type Subscription_RootToken_Storage_AggregateArgs = {
  distinct_on?: Maybe<Array<Token_Storage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Token_Storage_Order_By>>;
  where?: Maybe<Token_Storage_Bool_Exp>;
};


export type Subscription_RootToken_Storage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTutorialArgs = {
  distinct_on?: Maybe<Array<Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Order_By>>;
  where?: Maybe<Tutorial_Bool_Exp>;
};


export type Subscription_RootTutorial_AggregateArgs = {
  distinct_on?: Maybe<Array<Tutorial_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Order_By>>;
  where?: Maybe<Tutorial_Bool_Exp>;
};


export type Subscription_RootTutorial_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTutorial_StepArgs = {
  distinct_on?: Maybe<Array<Tutorial_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Step_Order_By>>;
  where?: Maybe<Tutorial_Step_Bool_Exp>;
};


export type Subscription_RootTutorial_Step_AggregateArgs = {
  distinct_on?: Maybe<Array<Tutorial_Step_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tutorial_Step_Order_By>>;
  where?: Maybe<Tutorial_Step_Bool_Exp>;
};


export type Subscription_RootTutorial_Step_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUser_Analytics_Points_IndexArgs = {
  distinct_on?: Maybe<Array<User_Analytics_Points_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Analytics_Points_Index_Order_By>>;
  where?: Maybe<User_Analytics_Points_Index_Bool_Exp>;
};


export type Subscription_RootUser_Analytics_Points_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Analytics_Points_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Analytics_Points_Index_Order_By>>;
  where?: Maybe<User_Analytics_Points_Index_Bool_Exp>;
};


export type Subscription_RootUser_Analytics_Points_Index_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootVr_ArtistsArgs = {
  distinct_on?: Maybe<Array<Vr_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vr_Artists_Order_By>>;
  where?: Maybe<Vr_Artists_Bool_Exp>;
};


export type Subscription_RootVr_Artists_AggregateArgs = {
  distinct_on?: Maybe<Array<Vr_Artists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vr_Artists_Order_By>>;
  where?: Maybe<Vr_Artists_Bool_Exp>;
};


export type Subscription_RootVr_Artists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootValid_Profile_Questions_And_Answers_ViewArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Order_By>>;
  where?: Maybe<Valid_Profile_Questions_And_Answers_View_Bool_Exp>;
};


export type Subscription_RootValid_Profile_Questions_And_Answers_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Questions_And_Answers_View_Order_By>>;
  where?: Maybe<Valid_Profile_Questions_And_Answers_View_Bool_Exp>;
};


export type Subscription_RootValid_Profile_Statement_Audio_Syntheses_ViewArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Order_By>>;
  where?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>;
};


export type Subscription_RootValid_Profile_Statement_Audio_Syntheses_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Valid_Profile_Statement_Audio_Syntheses_View_Order_By>>;
  where?: Maybe<Valid_Profile_Statement_Audio_Syntheses_View_Bool_Exp>;
};


export type Subscription_RootVision_Active_InterestsArgs = {
  distinct_on?: Maybe<Array<Vision_Active_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vision_Active_Interests_Order_By>>;
  where?: Maybe<Vision_Active_Interests_Bool_Exp>;
};


export type Subscription_RootVision_Active_Interests_AggregateArgs = {
  distinct_on?: Maybe<Array<Vision_Active_Interests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vision_Active_Interests_Order_By>>;
  where?: Maybe<Vision_Active_Interests_Bool_Exp>;
};


export type Subscription_RootVision_InterestArgs = {
  distinct_on?: Maybe<Array<Vision_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vision_Interest_Order_By>>;
  where?: Maybe<Vision_Interest_Bool_Exp>;
};


export type Subscription_RootVision_Interest_AggregateArgs = {
  distinct_on?: Maybe<Array<Vision_Interest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vision_Interest_Order_By>>;
  where?: Maybe<Vision_Interest_Bool_Exp>;
};


export type Subscription_RootVision_Interest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootVumarksArgs = {
  distinct_on?: Maybe<Array<Vumarks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vumarks_Order_By>>;
  where?: Maybe<Vumarks_Bool_Exp>;
};


export type Subscription_RootVumarks_AggregateArgs = {
  distinct_on?: Maybe<Array<Vumarks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vumarks_Order_By>>;
  where?: Maybe<Vumarks_Bool_Exp>;
};


export type Subscription_RootVumarks_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWaiting_ListArgs = {
  distinct_on?: Maybe<Array<Waiting_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Waiting_List_Order_By>>;
  where?: Maybe<Waiting_List_Bool_Exp>;
};


export type Subscription_RootWaiting_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Waiting_List_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Waiting_List_Order_By>>;
  where?: Maybe<Waiting_List_Bool_Exp>;
};


export type Subscription_RootWaiting_List_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWalletsArgs = {
  distinct_on?: Maybe<Array<Wallets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wallets_Order_By>>;
  where?: Maybe<Wallets_Bool_Exp>;
};


export type Subscription_RootWallets_AggregateArgs = {
  distinct_on?: Maybe<Array<Wallets_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wallets_Order_By>>;
  where?: Maybe<Wallets_Bool_Exp>;
};


export type Subscription_RootWallets_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorld_CitiesArgs = {
  distinct_on?: Maybe<Array<World_Cities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<World_Cities_Order_By>>;
  where?: Maybe<World_Cities_Bool_Exp>;
};


export type Subscription_RootWorld_Cities_AggregateArgs = {
  distinct_on?: Maybe<Array<World_Cities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<World_Cities_Order_By>>;
  where?: Maybe<World_Cities_Bool_Exp>;
};


export type Subscription_RootWorld_Cities_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootApp_Notifications_InfoArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Info_Order_By>>;
  where?: Maybe<App_Notifications_Info_Bool_Exp>;
};


export type Subscription_RootApp_Notifications_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Info_Order_By>>;
  where?: Maybe<App_Notifications_Info_Bool_Exp>;
};


export type Subscription_RootApp_Notifications_ListingArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Listing_Order_By>>;
  where?: Maybe<App_Notifications_Listing_Bool_Exp>;
};


export type Subscription_RootApp_Notifications_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Notifications_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_Listing_Order_By>>;
  where?: Maybe<App_Notifications_Listing_Bool_Exp>;
};


export type Subscription_RootApp_Notifications_ViewArgs = {
  distinct_on?: Maybe<Array<App_Notifications_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_View_Order_By>>;
  where?: Maybe<App_Notifications_View_Bool_Exp>;
};


export type Subscription_RootApp_Notifications_View_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Notifications_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Notifications_View_Order_By>>;
  where?: Maybe<App_Notifications_View_Bool_Exp>;
};


export type Subscription_RootArtist_Cardgrid_Artworks_ViewArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


export type Subscription_RootArtist_Cardgrid_Artworks_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Artist_Cardgrid_Artworks_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Cardgrid_Artworks_View_Order_By>>;
  where?: Maybe<Artist_Cardgrid_Artworks_View_Bool_Exp>;
};


export type Subscription_RootArtwork_Favorite_CountArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorite_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorite_Count_Order_By>>;
  where?: Maybe<Artwork_Favorite_Count_Bool_Exp>;
};


export type Subscription_RootArtwork_Favorite_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Favorite_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Favorite_Count_Order_By>>;
  where?: Maybe<Artwork_Favorite_Count_Bool_Exp>;
};


export type Subscription_RootArtwork_Manager_ViewArgs = {
  distinct_on?: Maybe<Array<Artwork_Manager_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Manager_View_Order_By>>;
  where?: Maybe<Artwork_Manager_View_Bool_Exp>;
};


export type Subscription_RootArtwork_Manager_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Manager_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Manager_View_Order_By>>;
  where?: Maybe<Artwork_Manager_View_Bool_Exp>;
};


export type Subscription_RootArtwork_Shop_InfoArgs = {
  distinct_on?: Maybe<Array<Artwork_Shop_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Shop_Info_Order_By>>;
  where?: Maybe<Artwork_Shop_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Shop_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Shop_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Shop_Info_Order_By>>;
  where?: Maybe<Artwork_Shop_Info_Bool_Exp>;
};


export type Subscription_RootArtwork_Shop_ListingArgs = {
  distinct_on?: Maybe<Array<Artwork_Shop_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Shop_Listing_Order_By>>;
  where?: Maybe<Artwork_Shop_Listing_Bool_Exp>;
};


export type Subscription_RootArtwork_Shop_Listing_AggregateArgs = {
  distinct_on?: Maybe<Array<Artwork_Shop_Listing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artwork_Shop_Listing_Order_By>>;
  where?: Maybe<Artwork_Shop_Listing_Bool_Exp>;
};


export type Subscription_RootBest_Bet_Cities_Exclude_Cities_With_Duplicates_MvArgs = {
  distinct_on?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Order_By>>;
  where?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Bool_Exp>;
};


export type Subscription_RootBest_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_AggregateArgs = {
  distinct_on?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Order_By>>;
  where?: Maybe<Best_Bet_Cities_Exclude_Cities_With_Duplicates_Mv_Bool_Exp>;
};


export type Subscription_RootBest_Bet_Cities_MvArgs = {
  distinct_on?: Maybe<Array<Best_Bet_Cities_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Best_Bet_Cities_Mv_Order_By>>;
  where?: Maybe<Best_Bet_Cities_Mv_Bool_Exp>;
};


export type Subscription_RootBest_Bet_Cities_Mv_AggregateArgs = {
  distinct_on?: Maybe<Array<Best_Bet_Cities_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Best_Bet_Cities_Mv_Order_By>>;
  where?: Maybe<Best_Bet_Cities_Mv_Bool_Exp>;
};


export type Subscription_RootBiweekly_Recap_Artists_InfoArgs = {
  distinct_on?: Maybe<Array<Biweekly_Recap_Artists_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Biweekly_Recap_Artists_Info_Order_By>>;
  where?: Maybe<Biweekly_Recap_Artists_Info_Bool_Exp>;
};


export type Subscription_RootBiweekly_Recap_Artists_Info_AggregateArgs = {
  distinct_on?: Maybe<Array<Biweekly_Recap_Artists_Info_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Biweekly_Recap_Artists_Info_Order_By>>;
  where?: Maybe<Biweekly_Recap_Artists_Info_Bool_Exp>;
};


export type Subscription_RootCount_Artists_Approved_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Approved_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Approved_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Approved_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artists_Approved_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Approved_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Approved_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Approved_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artists_Created_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Created_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artists_Created_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Created_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artists_Pending_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Pending_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Pending_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Pending_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artists_Pending_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Pending_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Pending_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Pending_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artists_Rejected_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Rejected_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Rejected_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Rejected_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artists_Rejected_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artists_Rejected_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artists_Rejected_By_Day_Order_By>>;
  where?: Maybe<Count_Artists_Rejected_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artworks_Published_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Artworks_Published_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artworks_Published_By_Day_Order_By>>;
  where?: Maybe<Count_Artworks_Published_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artworks_Published_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artworks_Published_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artworks_Published_By_Day_Order_By>>;
  where?: Maybe<Count_Artworks_Published_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Artworks_Published_This_WeekArgs = {
  distinct_on?: Maybe<Array<Count_Artworks_Published_This_Week_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artworks_Published_This_Week_Order_By>>;
  where?: Maybe<Count_Artworks_Published_This_Week_Bool_Exp>;
};


export type Subscription_RootCount_Artworks_Published_This_Week_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Artworks_Published_This_Week_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Artworks_Published_This_Week_Order_By>>;
  where?: Maybe<Count_Artworks_Published_This_Week_Bool_Exp>;
};


export type Subscription_RootCount_Collectors_Created_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Collectors_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Collectors_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Collectors_Created_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Collectors_Created_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Collectors_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Collectors_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Collectors_Created_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Galleries_Approved_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Approved_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Approved_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Approved_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Galleries_Approved_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Approved_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Approved_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Approved_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Galleries_Created_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Created_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Galleries_Created_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Created_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Created_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Created_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Galleries_Pending_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Pending_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Pending_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Pending_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Galleries_Pending_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Pending_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Pending_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Pending_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Galleries_Rejected_By_DayArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Rejected_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Rejected_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Rejected_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Galleries_Rejected_By_Day_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Galleries_Rejected_By_Day_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Galleries_Rejected_By_Day_Order_By>>;
  where?: Maybe<Count_Galleries_Rejected_By_Day_Bool_Exp>;
};


export type Subscription_RootCount_Profile_Visits_Published_BiweeklyArgs = {
  distinct_on?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Order_By>>;
  where?: Maybe<Count_Profile_Visits_Published_Biweekly_Bool_Exp>;
};


export type Subscription_RootCount_Profile_Visits_Published_Biweekly_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Profile_Visits_Published_Biweekly_Order_By>>;
  where?: Maybe<Count_Profile_Visits_Published_Biweekly_Bool_Exp>;
};


export type Subscription_RootCount_Recognitions_Published_BiweeklyArgs = {
  distinct_on?: Maybe<Array<Count_Recognitions_Published_Biweekly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Recognitions_Published_Biweekly_Order_By>>;
  where?: Maybe<Count_Recognitions_Published_Biweekly_Bool_Exp>;
};


export type Subscription_RootCount_Recognitions_Published_Biweekly_AggregateArgs = {
  distinct_on?: Maybe<Array<Count_Recognitions_Published_Biweekly_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Count_Recognitions_Published_Biweekly_Order_By>>;
  where?: Maybe<Count_Recognitions_Published_Biweekly_Bool_Exp>;
};


export type Subscription_RootCountry_States_MvArgs = {
  distinct_on?: Maybe<Array<Country_States_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_States_Mv_Order_By>>;
  where?: Maybe<Country_States_Mv_Bool_Exp>;
};


export type Subscription_RootCountry_States_Mv_AggregateArgs = {
  distinct_on?: Maybe<Array<Country_States_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_States_Mv_Order_By>>;
  where?: Maybe<Country_States_Mv_Bool_Exp>;
};


export type Subscription_RootDiscount_Code_ViewArgs = {
  distinct_on?: Maybe<Array<Discount_Code_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_View_Order_By>>;
  where?: Maybe<Discount_Code_View_Bool_Exp>;
};


export type Subscription_RootDiscount_Code_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Discount_Code_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Discount_Code_View_Order_By>>;
  where?: Maybe<Discount_Code_View_Bool_Exp>;
};


export type Subscription_RootFlat_Delegate_AccessArgs = {
  distinct_on?: Maybe<Array<Flat_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Delegate_Access_Order_By>>;
  where?: Maybe<Flat_Delegate_Access_Bool_Exp>;
};


export type Subscription_RootFlat_Delegate_Access_AggregateArgs = {
  distinct_on?: Maybe<Array<Flat_Delegate_Access_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Flat_Delegate_Access_Order_By>>;
  where?: Maybe<Flat_Delegate_Access_Bool_Exp>;
};


export type Subscription_RootGetAnalyticsKaleidoCardByProfileIdArgs = {
  args: GetAnalyticsKaleidoCardByProfileId_Args;
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Amounts_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
};


export type Subscription_RootGetAnalyticsKaleidoCardByProfileId_AggregateArgs = {
  args: GetAnalyticsKaleidoCardByProfileId_Args;
  distinct_on?: Maybe<Array<Analytics_KaleidoCard_Amounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_KaleidoCard_Amounts_Order_By>>;
  where?: Maybe<Analytics_KaleidoCard_Amounts_Bool_Exp>;
};


export type Subscription_RootGetArtPrizeShowWinnersArgs = {
  args: GetArtPrizeShowWinners_Args;
  distinct_on?: Maybe<Array<Art_Prize_Show_Winners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Winners_Order_By>>;
  where?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
};


export type Subscription_RootGetArtPrizeShowWinners_AggregateArgs = {
  args: GetArtPrizeShowWinners_Args;
  distinct_on?: Maybe<Array<Art_Prize_Show_Winners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Art_Prize_Show_Winners_Order_By>>;
  where?: Maybe<Art_Prize_Show_Winners_Bool_Exp>;
};


export type Subscription_RootGetSuggestedPrimaryTagsByArtistArgs = {
  args: GetSuggestedPrimaryTagsByArtist_Args;
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Subscription_RootGetSuggestedPrimaryTagsByArtist_AggregateArgs = {
  args: GetSuggestedPrimaryTagsByArtist_Args;
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Subscription_RootGetSuggestedSecondaryTagsByArtistArgs = {
  args: GetSuggestedSecondaryTagsByArtist_Args;
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Subscription_RootGetSuggestedSecondaryTagsByArtist_AggregateArgs = {
  args: GetSuggestedSecondaryTagsByArtist_Args;
  distinct_on?: Maybe<Array<Context_Suggested_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Context_Suggested_Tags_Order_By>>;
  where?: Maybe<Context_Suggested_Tags_Bool_Exp>;
};


export type Subscription_RootGet_Artists_In_RangeArgs = {
  args: Get_Artists_In_Range_Args;
  distinct_on?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_With_City_Data_Bool_Exp>;
};


export type Subscription_RootGet_Artists_In_Range_AggregateArgs = {
  args: Get_Artists_In_Range_Args;
  distinct_on?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Artist_Explore_Listing_With_City_Data_Order_By>>;
  where?: Maybe<Artist_Explore_Listing_With_City_Data_Bool_Exp>;
};


export type Subscription_RootGet_Galleries_In_RangeArgs = {
  args: Get_Galleries_In_Range_Args;
  distinct_on?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_With_City_Data_Bool_Exp>;
};


export type Subscription_RootGet_Galleries_In_Range_AggregateArgs = {
  args: Get_Galleries_In_Range_Args;
  distinct_on?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gallery_Explore_Info_With_City_Data_Order_By>>;
  where?: Maybe<Gallery_Explore_Info_With_City_Data_Bool_Exp>;
};


export type Subscription_RootGet_Min_Price_Available_Edition_By_SizeArgs = {
  args: Get_Min_Price_Available_Edition_By_Size_Args;
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Subscription_RootGet_Min_Price_Available_Edition_By_Size_AggregateArgs = {
  args: Get_Min_Price_Available_Edition_By_Size_Args;
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Subscription_RootGet_Min_Price_Available_PrintArgs = {
  args: Get_Min_Price_Available_Print_Args;
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Subscription_RootGet_Min_Price_Available_Print_AggregateArgs = {
  args: Get_Min_Price_Available_Print_Args;
  distinct_on?: Maybe<Array<Available_Print_Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Available_Print_Price_Order_By>>;
  where?: Maybe<Available_Print_Price_Bool_Exp>;
};


export type Subscription_RootInquireForPurchaseByOutsideUserActionArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInside_Purchase_Inquiries_ViewArgs = {
  distinct_on?: Maybe<Array<Inside_Purchase_Inquiries_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inside_Purchase_Inquiries_View_Order_By>>;
  where?: Maybe<Inside_Purchase_Inquiries_View_Bool_Exp>;
};


export type Subscription_RootInside_Purchase_Inquiries_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Inside_Purchase_Inquiries_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inside_Purchase_Inquiries_View_Order_By>>;
  where?: Maybe<Inside_Purchase_Inquiries_View_Bool_Exp>;
};


export type Subscription_RootKaleido_Coins_DistributionArgs = {
  distinct_on?: Maybe<Array<Kaleido_Coins_Distribution_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Coins_Distribution_Order_By>>;
  where?: Maybe<Kaleido_Coins_Distribution_Bool_Exp>;
};


export type Subscription_RootKaleido_Coins_Distribution_AggregateArgs = {
  distinct_on?: Maybe<Array<Kaleido_Coins_Distribution_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Coins_Distribution_Order_By>>;
  where?: Maybe<Kaleido_Coins_Distribution_Bool_Exp>;
};


export type Subscription_RootKaleido_Coins_Weekly_DistributionArgs = {
  distinct_on?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Order_By>>;
  where?: Maybe<Kaleido_Coins_Weekly_Distribution_Bool_Exp>;
};


export type Subscription_RootKaleido_Coins_Weekly_Distribution_AggregateArgs = {
  distinct_on?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Kaleido_Coins_Weekly_Distribution_Order_By>>;
  where?: Maybe<Kaleido_Coins_Weekly_Distribution_Bool_Exp>;
};


export type Subscription_RootOutside_Purchase_Inquiries_ViewArgs = {
  distinct_on?: Maybe<Array<Outside_Purchase_Inquiries_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outside_Purchase_Inquiries_View_Order_By>>;
  where?: Maybe<Outside_Purchase_Inquiries_View_Bool_Exp>;
};


export type Subscription_RootOutside_Purchase_Inquiries_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Outside_Purchase_Inquiries_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outside_Purchase_Inquiries_View_Order_By>>;
  where?: Maybe<Outside_Purchase_Inquiries_View_Bool_Exp>;
};


export type Subscription_RootOwner_Artwork_ViewArgs = {
  distinct_on?: Maybe<Array<Owner_Artwork_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Owner_Artwork_View_Order_By>>;
  where?: Maybe<Owner_Artwork_View_Bool_Exp>;
};


export type Subscription_RootOwner_Artwork_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Owner_Artwork_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Owner_Artwork_View_Order_By>>;
  where?: Maybe<Owner_Artwork_View_Bool_Exp>;
};


export type Subscription_RootPayment_Captures_ViewArgs = {
  distinct_on?: Maybe<Array<Payment_Captures_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Captures_View_Order_By>>;
  where?: Maybe<Payment_Captures_View_Bool_Exp>;
};


export type Subscription_RootPayment_Captures_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Captures_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Captures_View_Order_By>>;
  where?: Maybe<Payment_Captures_View_Bool_Exp>;
};


export type Subscription_RootPending_Forge_Task_ViewArgs = {
  distinct_on?: Maybe<Array<Pending_Forge_Task_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Forge_Task_View_Order_By>>;
  where?: Maybe<Pending_Forge_Task_View_Bool_Exp>;
};


export type Subscription_RootPending_Forge_Task_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Pending_Forge_Task_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pending_Forge_Task_View_Order_By>>;
  where?: Maybe<Pending_Forge_Task_View_Bool_Exp>;
};


export type Subscription_RootPrint_AvailabilityArgs = {
  distinct_on?: Maybe<Array<Print_Availability_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Availability_Order_By>>;
  where?: Maybe<Print_Availability_Bool_Exp>;
};


export type Subscription_RootPrint_Availability_AggregateArgs = {
  distinct_on?: Maybe<Array<Print_Availability_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Print_Availability_Order_By>>;
  where?: Maybe<Print_Availability_Bool_Exp>;
};


export type Subscription_RootProfile_Backgrounds_ViewArgs = {
  distinct_on?: Maybe<Array<Profile_Backgrounds_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Backgrounds_View_Order_By>>;
  where?: Maybe<Profile_Backgrounds_View_Bool_Exp>;
};


export type Subscription_RootProfile_Backgrounds_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Backgrounds_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Backgrounds_View_Order_By>>;
  where?: Maybe<Profile_Backgrounds_View_Bool_Exp>;
};


export type Subscription_RootProfile_Interests_ViewArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_View_Order_By>>;
  where?: Maybe<Profile_Interests_View_Bool_Exp>;
};


export type Subscription_RootProfile_Interests_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Interests_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Interests_View_Order_By>>;
  where?: Maybe<Profile_Interests_View_Bool_Exp>;
};


export type Subscription_RootProfile_PhonesArgs = {
  distinct_on?: Maybe<Array<Profile_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Phones_Order_By>>;
  where?: Maybe<Profile_Phones_Bool_Exp>;
};


export type Subscription_RootProfile_Phones_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Phones_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Phones_Order_By>>;
  where?: Maybe<Profile_Phones_Bool_Exp>;
};


export type Subscription_RootProfile_Phones_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRecognition_Counts_MvArgs = {
  distinct_on?: Maybe<Array<Recognition_Counts_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recognition_Counts_Mv_Order_By>>;
  where?: Maybe<Recognition_Counts_Mv_Bool_Exp>;
};


export type Subscription_RootRecognition_Counts_Mv_AggregateArgs = {
  distinct_on?: Maybe<Array<Recognition_Counts_Mv_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recognition_Counts_Mv_Order_By>>;
  where?: Maybe<Recognition_Counts_Mv_Bool_Exp>;
};


export type Subscription_RootShowroom_Owner_PasswordArgs = {
  distinct_on?: Maybe<Array<Showroom_Owner_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Owner_Password_Order_By>>;
  where?: Maybe<Showroom_Owner_Password_Bool_Exp>;
};


export type Subscription_RootShowroom_Owner_Password_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Owner_Password_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Owner_Password_Order_By>>;
  where?: Maybe<Showroom_Owner_Password_Bool_Exp>;
};


export type Subscription_RootShowroom_Password_CheckArgs = {
  distinct_on?: Maybe<Array<Showroom_Password_Check_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Password_Check_Order_By>>;
  where?: Maybe<Showroom_Password_Check_Bool_Exp>;
};


export type Subscription_RootShowroom_Password_Check_AggregateArgs = {
  distinct_on?: Maybe<Array<Showroom_Password_Check_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Showroom_Password_Check_Order_By>>;
  where?: Maybe<Showroom_Password_Check_Bool_Exp>;
};


export type Subscription_RootSum_Coins_From_Card_This_WeekArgs = {
  distinct_on?: Maybe<Array<Sum_Coins_From_Card_This_Week_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sum_Coins_From_Card_This_Week_Order_By>>;
  where?: Maybe<Sum_Coins_From_Card_This_Week_Bool_Exp>;
};


export type Subscription_RootSum_Coins_From_Card_This_Week_AggregateArgs = {
  distinct_on?: Maybe<Array<Sum_Coins_From_Card_This_Week_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sum_Coins_From_Card_This_Week_Order_By>>;
  where?: Maybe<Sum_Coins_From_Card_This_Week_Bool_Exp>;
};


export type Subscription_RootWorldcitiesArgs = {
  distinct_on?: Maybe<Array<Worldcities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Worldcities_Order_By>>;
  where?: Maybe<Worldcities_Bool_Exp>;
};


export type Subscription_RootWorldcities_AggregateArgs = {
  distinct_on?: Maybe<Array<Worldcities_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Worldcities_Order_By>>;
  where?: Maybe<Worldcities_Bool_Exp>;
};


export type Subscription_RootWorldcities_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "sum_coins_from_card_this_week" */
export type Sum_Coins_From_Card_This_Week = {
  __typename?: 'sum_coins_from_card_this_week';
  profile_context_id?: Maybe<Scalars['bigint']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "sum_coins_from_card_this_week" */
export type Sum_Coins_From_Card_This_Week_Aggregate = {
  __typename?: 'sum_coins_from_card_this_week_aggregate';
  aggregate?: Maybe<Sum_Coins_From_Card_This_Week_Aggregate_Fields>;
  nodes: Array<Sum_Coins_From_Card_This_Week>;
};

/** aggregate fields of "sum_coins_from_card_this_week" */
export type Sum_Coins_From_Card_This_Week_Aggregate_Fields = {
  __typename?: 'sum_coins_from_card_this_week_aggregate_fields';
  avg?: Maybe<Sum_Coins_From_Card_This_Week_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sum_Coins_From_Card_This_Week_Max_Fields>;
  min?: Maybe<Sum_Coins_From_Card_This_Week_Min_Fields>;
  stddev?: Maybe<Sum_Coins_From_Card_This_Week_Stddev_Fields>;
  stddev_pop?: Maybe<Sum_Coins_From_Card_This_Week_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sum_Coins_From_Card_This_Week_Stddev_Samp_Fields>;
  sum?: Maybe<Sum_Coins_From_Card_This_Week_Sum_Fields>;
  var_pop?: Maybe<Sum_Coins_From_Card_This_Week_Var_Pop_Fields>;
  var_samp?: Maybe<Sum_Coins_From_Card_This_Week_Var_Samp_Fields>;
  variance?: Maybe<Sum_Coins_From_Card_This_Week_Variance_Fields>;
};


/** aggregate fields of "sum_coins_from_card_this_week" */
export type Sum_Coins_From_Card_This_Week_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sum_Coins_From_Card_This_Week_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sum_Coins_From_Card_This_Week_Avg_Fields = {
  __typename?: 'sum_coins_from_card_this_week_avg_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sum_coins_from_card_this_week". All fields are combined with a logical 'AND'. */
export type Sum_Coins_From_Card_This_Week_Bool_Exp = {
  _and?: Maybe<Array<Sum_Coins_From_Card_This_Week_Bool_Exp>>;
  _not?: Maybe<Sum_Coins_From_Card_This_Week_Bool_Exp>;
  _or?: Maybe<Array<Sum_Coins_From_Card_This_Week_Bool_Exp>>;
  profile_context_id?: Maybe<Bigint_Comparison_Exp>;
  sum?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Sum_Coins_From_Card_This_Week_Max_Fields = {
  __typename?: 'sum_coins_from_card_this_week_max_fields';
  profile_context_id?: Maybe<Scalars['bigint']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Sum_Coins_From_Card_This_Week_Min_Fields = {
  __typename?: 'sum_coins_from_card_this_week_min_fields';
  profile_context_id?: Maybe<Scalars['bigint']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "sum_coins_from_card_this_week". */
export type Sum_Coins_From_Card_This_Week_Order_By = {
  profile_context_id?: Maybe<Order_By>;
  sum?: Maybe<Order_By>;
};

/** select columns of table "sum_coins_from_card_this_week" */
export enum Sum_Coins_From_Card_This_Week_Select_Column {
  /** column name */
  ProfileContextId = 'profile_context_id',
  /** column name */
  Sum = 'sum'
}

/** aggregate stddev on columns */
export type Sum_Coins_From_Card_This_Week_Stddev_Fields = {
  __typename?: 'sum_coins_from_card_this_week_stddev_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sum_Coins_From_Card_This_Week_Stddev_Pop_Fields = {
  __typename?: 'sum_coins_from_card_this_week_stddev_pop_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sum_Coins_From_Card_This_Week_Stddev_Samp_Fields = {
  __typename?: 'sum_coins_from_card_this_week_stddev_samp_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Sum_Coins_From_Card_This_Week_Sum_Fields = {
  __typename?: 'sum_coins_from_card_this_week_sum_fields';
  profile_context_id?: Maybe<Scalars['bigint']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Sum_Coins_From_Card_This_Week_Var_Pop_Fields = {
  __typename?: 'sum_coins_from_card_this_week_var_pop_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sum_Coins_From_Card_This_Week_Var_Samp_Fields = {
  __typename?: 'sum_coins_from_card_this_week_var_samp_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sum_Coins_From_Card_This_Week_Variance_Fields = {
  __typename?: 'sum_coins_from_card_this_week_variance_fields';
  profile_context_id?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timetz']>;
  _gt?: Maybe<Scalars['timetz']>;
  _gte?: Maybe<Scalars['timetz']>;
  _in?: Maybe<Array<Scalars['timetz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timetz']>;
  _lte?: Maybe<Scalars['timetz']>;
  _neq?: Maybe<Scalars['timetz']>;
  _nin?: Maybe<Array<Scalars['timetz']>>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "worldcities" */
export type Worldcities = {
  __typename?: 'worldcities';
  /** An object relationship */
  Country?: Maybe<Countries>;
  admin_code?: Maybe<Scalars['String']>;
  admin_name?: Maybe<Scalars['String']>;
  admin_name_ascii?: Maybe<Scalars['String']>;
  admin_type?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_alt?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  /** deprecated - use Country relationship instead */
  country_name?: Maybe<Scalars['String']>;
  density?: Maybe<Scalars['float8']>;
  id: Scalars['String'];
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  population_proper?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
  same_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** aggregated selection of "worldcities" */
export type Worldcities_Aggregate = {
  __typename?: 'worldcities_aggregate';
  aggregate?: Maybe<Worldcities_Aggregate_Fields>;
  nodes: Array<Worldcities>;
};

/** aggregate fields of "worldcities" */
export type Worldcities_Aggregate_Fields = {
  __typename?: 'worldcities_aggregate_fields';
  avg?: Maybe<Worldcities_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Worldcities_Max_Fields>;
  min?: Maybe<Worldcities_Min_Fields>;
  stddev?: Maybe<Worldcities_Stddev_Fields>;
  stddev_pop?: Maybe<Worldcities_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Worldcities_Stddev_Samp_Fields>;
  sum?: Maybe<Worldcities_Sum_Fields>;
  var_pop?: Maybe<Worldcities_Var_Pop_Fields>;
  var_samp?: Maybe<Worldcities_Var_Samp_Fields>;
  variance?: Maybe<Worldcities_Variance_Fields>;
};


/** aggregate fields of "worldcities" */
export type Worldcities_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Worldcities_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Worldcities_Avg_Fields = {
  __typename?: 'worldcities_avg_fields';
  density?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  population_proper?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "worldcities". All fields are combined with a logical 'AND'. */
export type Worldcities_Bool_Exp = {
  Country?: Maybe<Countries_Bool_Exp>;
  _and?: Maybe<Array<Worldcities_Bool_Exp>>;
  _not?: Maybe<Worldcities_Bool_Exp>;
  _or?: Maybe<Array<Worldcities_Bool_Exp>>;
  admin_code?: Maybe<String_Comparison_Exp>;
  admin_name?: Maybe<String_Comparison_Exp>;
  admin_name_ascii?: Maybe<String_Comparison_Exp>;
  admin_type?: Maybe<String_Comparison_Exp>;
  capital?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  city_alt?: Maybe<String_Comparison_Exp>;
  city_ascii?: Maybe<String_Comparison_Exp>;
  country_name?: Maybe<String_Comparison_Exp>;
  density?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  iso2?: Maybe<String_Comparison_Exp>;
  iso3?: Maybe<String_Comparison_Exp>;
  lat?: Maybe<Float8_Comparison_Exp>;
  lng?: Maybe<Float8_Comparison_Exp>;
  population?: Maybe<Float8_Comparison_Exp>;
  population_proper?: Maybe<Float8_Comparison_Exp>;
  ranking?: Maybe<Int_Comparison_Exp>;
  same_name?: Maybe<String_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "worldcities" */
export enum Worldcities_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorldcitiesPkey = 'worldcities_pkey'
}

/** input type for incrementing numeric columns in table "worldcities" */
export type Worldcities_Inc_Input = {
  density?: Maybe<Scalars['float8']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  population_proper?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "worldcities" */
export type Worldcities_Insert_Input = {
  Country?: Maybe<Countries_Obj_Rel_Insert_Input>;
  admin_code?: Maybe<Scalars['String']>;
  admin_name?: Maybe<Scalars['String']>;
  admin_name_ascii?: Maybe<Scalars['String']>;
  admin_type?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_alt?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  /** deprecated - use Country relationship instead */
  country_name?: Maybe<Scalars['String']>;
  density?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  population_proper?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
  same_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Worldcities_Max_Fields = {
  __typename?: 'worldcities_max_fields';
  admin_code?: Maybe<Scalars['String']>;
  admin_name?: Maybe<Scalars['String']>;
  admin_name_ascii?: Maybe<Scalars['String']>;
  admin_type?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_alt?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  /** deprecated - use Country relationship instead */
  country_name?: Maybe<Scalars['String']>;
  density?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  population_proper?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
  same_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Worldcities_Min_Fields = {
  __typename?: 'worldcities_min_fields';
  admin_code?: Maybe<Scalars['String']>;
  admin_name?: Maybe<Scalars['String']>;
  admin_name_ascii?: Maybe<Scalars['String']>;
  admin_type?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_alt?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  /** deprecated - use Country relationship instead */
  country_name?: Maybe<Scalars['String']>;
  density?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  population_proper?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
  same_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "worldcities" */
export type Worldcities_Mutation_Response = {
  __typename?: 'worldcities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Worldcities>;
};

/** input type for inserting object relation for remote table "worldcities" */
export type Worldcities_Obj_Rel_Insert_Input = {
  data: Worldcities_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Worldcities_On_Conflict>;
};

/** on_conflict condition type for table "worldcities" */
export type Worldcities_On_Conflict = {
  constraint: Worldcities_Constraint;
  update_columns?: Array<Worldcities_Update_Column>;
  where?: Maybe<Worldcities_Bool_Exp>;
};

/** Ordering options when selecting data from "worldcities". */
export type Worldcities_Order_By = {
  Country?: Maybe<Countries_Order_By>;
  admin_code?: Maybe<Order_By>;
  admin_name?: Maybe<Order_By>;
  admin_name_ascii?: Maybe<Order_By>;
  admin_type?: Maybe<Order_By>;
  capital?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  city_alt?: Maybe<Order_By>;
  city_ascii?: Maybe<Order_By>;
  country_name?: Maybe<Order_By>;
  density?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  iso2?: Maybe<Order_By>;
  iso3?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  population?: Maybe<Order_By>;
  population_proper?: Maybe<Order_By>;
  ranking?: Maybe<Order_By>;
  same_name?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
};

/** primary key columns input for table: worldcities */
export type Worldcities_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "worldcities" */
export enum Worldcities_Select_Column {
  /** column name */
  AdminCode = 'admin_code',
  /** column name */
  AdminName = 'admin_name',
  /** column name */
  AdminNameAscii = 'admin_name_ascii',
  /** column name */
  AdminType = 'admin_type',
  /** column name */
  Capital = 'capital',
  /** column name */
  City = 'city',
  /** column name */
  CityAlt = 'city_alt',
  /** column name */
  CityAscii = 'city_ascii',
  /** column name */
  CountryName = 'country_name',
  /** column name */
  Density = 'density',
  /** column name */
  Id = 'id',
  /** column name */
  Iso2 = 'iso2',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Population = 'population',
  /** column name */
  PopulationProper = 'population_proper',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  SameName = 'same_name',
  /** column name */
  Timezone = 'timezone'
}

/** input type for updating data in table "worldcities" */
export type Worldcities_Set_Input = {
  admin_code?: Maybe<Scalars['String']>;
  admin_name?: Maybe<Scalars['String']>;
  admin_name_ascii?: Maybe<Scalars['String']>;
  admin_type?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_alt?: Maybe<Scalars['String']>;
  city_ascii?: Maybe<Scalars['String']>;
  /** deprecated - use Country relationship instead */
  country_name?: Maybe<Scalars['String']>;
  density?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  population_proper?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
  same_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Worldcities_Stddev_Fields = {
  __typename?: 'worldcities_stddev_fields';
  density?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  population_proper?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Worldcities_Stddev_Pop_Fields = {
  __typename?: 'worldcities_stddev_pop_fields';
  density?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  population_proper?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Worldcities_Stddev_Samp_Fields = {
  __typename?: 'worldcities_stddev_samp_fields';
  density?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  population_proper?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Worldcities_Sum_Fields = {
  __typename?: 'worldcities_sum_fields';
  density?: Maybe<Scalars['float8']>;
  lat?: Maybe<Scalars['float8']>;
  lng?: Maybe<Scalars['float8']>;
  population?: Maybe<Scalars['float8']>;
  population_proper?: Maybe<Scalars['float8']>;
  ranking?: Maybe<Scalars['Int']>;
};

/** update columns of table "worldcities" */
export enum Worldcities_Update_Column {
  /** column name */
  AdminCode = 'admin_code',
  /** column name */
  AdminName = 'admin_name',
  /** column name */
  AdminNameAscii = 'admin_name_ascii',
  /** column name */
  AdminType = 'admin_type',
  /** column name */
  Capital = 'capital',
  /** column name */
  City = 'city',
  /** column name */
  CityAlt = 'city_alt',
  /** column name */
  CityAscii = 'city_ascii',
  /** column name */
  CountryName = 'country_name',
  /** column name */
  Density = 'density',
  /** column name */
  Id = 'id',
  /** column name */
  Iso2 = 'iso2',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Population = 'population',
  /** column name */
  PopulationProper = 'population_proper',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  SameName = 'same_name',
  /** column name */
  Timezone = 'timezone'
}

/** aggregate var_pop on columns */
export type Worldcities_Var_Pop_Fields = {
  __typename?: 'worldcities_var_pop_fields';
  density?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  population_proper?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Worldcities_Var_Samp_Fields = {
  __typename?: 'worldcities_var_samp_fields';
  density?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  population_proper?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Worldcities_Variance_Fields = {
  __typename?: 'worldcities_variance_fields';
  density?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  population?: Maybe<Scalars['Float']>;
  population_proper?: Maybe<Scalars['Float']>;
  ranking?: Maybe<Scalars['Float']>;
};
