import { FormikSectionConfig } from 'common/features/FormikConfigs/utils/formikTypeUtils';
import { t } from 'i18next';
import {
  Artwork_Tag,
  Enum_Artwork_Discipline_Enum,
  Enum_Art_Type_Enum,
  Enum_Blockchain_Enum,
  Artwork_Ai_Tags,
} from 'common/schema/commonSchemaRemoteTypes';
import * as Yup from 'yup';
import { ArtworkMeasureUnit } from 'common/utils/types';
import { ARTWORK_STATEMENT_LENGTH } from 'stacks/Dashboard/common/ArtworkPublish/components/common/Storytelling/components/ArtworkStatementSection';
import { DEFAULT_MEASURE_UNIT } from 'common/utils/constants';
import { LocationType } from 'common/features/GeoLocation/geolocationUtils';
import useAppSelector from 'common/hooks/useAppSelector';

export interface ArtworkTitleFormikConfigValues {
  artworkTitle: string;
}

export const ArtworkTitleFormikConfig: FormikSectionConfig<ArtworkTitleFormikConfigValues> = (override) => ({
  initialValues: {
    artworkTitle: override?.artworkTitle || '',
  },
  validationSchema: {
    artworkTitle: Yup.string()
      .required(t('addArtworkStrings.errors.requiredTitle'))
      .max(1024, t('addArtworkStrings.errors.maxLengthTitle')),
  },
});

export interface ArtworkTypeFormikConfigValues {
  artworkType?: Enum_Art_Type_Enum;
}

export const ArtworkTypeFormikConfig: FormikSectionConfig<ArtworkTypeFormikConfigValues> = (override) => {
  const incomingType = override?.artworkType === undefined ? null : override.artworkType;
  let artworkType;
  if (incomingType) {
    artworkType = incomingType;
  }
  return {
    initialValues: { artworkType },
    validationSchema: {
      artworkType: Yup.string().required(t('addArtworkStrings.errors.requiredType')),
    },
  };
};

export interface ArtworkMeasuringUnitConfigValues {
  artworkMeasuringUnit: string;
}

export const artworkMeasuringUnitValidationSchema = Yup.string()
  .oneOf([ArtworkMeasureUnit.Metric, ArtworkMeasureUnit.American, ArtworkMeasureUnit.Digital])
  .required(t('addArtworkStrings.errors.requiredUnit'));

export const ArtworkMeasuringUnitConfig: FormikSectionConfig<ArtworkMeasuringUnitConfigValues> = (override) => ({
  initialValues: {
    artworkMeasuringUnit: override?.artworkMeasuringUnit || DEFAULT_MEASURE_UNIT,
  },
  validationSchema: {
    artworkMeasuringUnit: artworkMeasuringUnitValidationSchema,
  },
});

export interface ArtworkDimensionsConfigValues {
  width?: number;
  height?: number;
  depth?: number | null;
}

export const widthAndHeightValidationSchema = {
  width: Yup.number().moreThan(0).max(1000000000).required(t('addArtworkStrings.errors.widthRequired')),
  height: Yup.number().moreThan(0).max(1000000000).required(t('addArtworkStrings.errors.heightRequired')),
};

export const ArtworkDimensionsConfig: FormikSectionConfig<ArtworkDimensionsConfigValues> = (override) => ({
  initialValues: {
    width: override?.width,
    height: override?.height,
    depth: override?.depth,
  },
  validationSchema: {
    ...widthAndHeightValidationSchema,
    depth: Yup.number().nullable().min(0).max(1000000000).notRequired(),
  },
});

export interface ArtworkCreationDateConfigValues {
  year?: number;
  month?: number;
  day?: number;
}

export const dateValidationSchema = {
  year: Yup.number().max(new Date().getFullYear()).required(t('addArtworkStrings.errors.requiredYear')),
  month: Yup.number()
    .nullable()
    .min(0)
    .max(11) // months values start from 0 for January until 11 for December
    .notRequired(),
  day: Yup.number().nullable().min(1).max(31).notRequired(),
};

export const ArtworkCreationDateConfig: FormikSectionConfig<ArtworkCreationDateConfigValues> = (override) => ({
  initialValues: {
    year: override?.year,
    month: override?.month,
    day: override?.day,
  },
  validationSchema: {
    year: Yup.number()
      .max(new Date().getFullYear(), t('addArtworkStrings.errors.noFutureDate'))
      .required(t('addArtworkStrings.errors.requiredYear')),
    month: Yup.number()
      .nullable()
      .min(0)
      .max(11, t('addArtworkStrings.errors.noFutureDate')) // months values start from 0 for January until 11 for December
      .when('year', {
        is: (year: number) => year === new Date().getFullYear(),
        then: Yup.number().max(new Date().getMonth(), t('addArtworkStrings.errors.noFutureDate')),
      })
      .notRequired(),
    day: Yup.number()
      .nullable()
      .min(1)
      .max(31, t('addArtworkStrings.errors.noFutureDate'))
      .when(['year', 'month'], {
        is: (year: number, month: number) => year === new Date().getFullYear() && month === new Date().getMonth(),
        then: Yup.number().max(new Date().getDate(), t('addArtworkStrings.errors.noFutureDate')),
      })
      .notRequired(),
  },
});

export interface ArtworkDisciplineConfigValues {
  discipline?: Enum_Artwork_Discipline_Enum;
  discipline_other?: string;
  genre_id?: number;
}

export const ArtworkDisciplineConfig: FormikSectionConfig<ArtworkDisciplineConfigValues> = (override) => ({
  initialValues: {
    discipline: override?.discipline,
    discipline_other: override?.discipline_other || '',
    genre_id: override?.genre_id,
  },
  validationSchema: {
    discipline: Yup.string().when('artworkType', {
      is: (artworkType: Enum_Art_Type_Enum) => artworkType !== Enum_Art_Type_Enum.Photography,
      then: Yup.string().required(t('addArtworkStrings.errors.requiredDiscipline')),
    }),
    discipline_other: Yup.string().when('discipline', {
      is: Enum_Artwork_Discipline_Enum.Other,
      then: Yup.string().required(t('addArtworkStrings.errors.requiredDiscipline')),
    }),
    genre_id: Yup.number().when('isPrimaryTagArtworkFlow', {
      is: (isPrimaryTagArtworkFlow: boolean) => isPrimaryTagArtworkFlow === true,
      then: Yup.number().required(t('addArtworkStrings.errors.requiredGenre')),
      otherwise: Yup.number().notRequired(),
    }),
  },
});

export interface ArtworkMediumConfigValues {
  medium?: string;
}

export const useArtworkMediumConfig: FormikSectionConfig<ArtworkMediumConfigValues> = (override) => {
  const artworkType = useAppSelector((state) => state.artworkType.artworkType);
  const isPhotography = artworkType === Enum_Art_Type_Enum.Photography;

  let errorMessage = t('addArtworkStrings.errors.requiredMedium');
  if (artworkType === Enum_Art_Type_Enum.Photography) {
    errorMessage = t('addArtworkStrings.errors.requiredPhotography');
  } else if (artworkType === Enum_Art_Type_Enum.Digital) {
    errorMessage = t('addArtworkStrings.errors.requiredTools');
  }
  return {
    initialValues: {
      medium: override?.medium || '',
    },
    validationSchema: {
      medium: Yup.string().when('isPrimaryTagArtworkFlow', {
        is: (isPrimaryTagArtworkFlow: boolean) => isPrimaryTagArtworkFlow === true || isPhotography,
        then: Yup.string().required(errorMessage),
        otherwise: Yup.string().notRequired(),
      }),
    },
  };
};

export interface ArtworkLocationConfigValues {
  locationString: string;
  location?: LocationType;
}

export const ArtworkLocationConfig: FormikSectionConfig<ArtworkLocationConfigValues> = (override) => ({
  initialValues: {
    locationString: override?.locationString || '',
    location: override?.location || undefined,
  },
  validationSchema: {
    locationString: Yup.string(),
    location: Yup.object(),
  },
});

export interface ArtworkTagsConfigValues {
  primaryTag?: Artwork_Tag | undefined;
  tags: Artwork_Tag[];
  isPrimaryTagArtworkFlow?: boolean;
  aiTags?: Pick<Artwork_Ai_Tags, 'user_selected_image_tags'>;
}

export const ArtworkTagsConfig: FormikSectionConfig<ArtworkTagsConfigValues> = (override) => ({
  initialValues: {
    primaryTag: override?.primaryTag || undefined,
    tags: override?.tags || [],
    isPrimaryTagArtworkFlow: override?.isPrimaryTagArtworkFlow || false,
    aiTags: override?.aiTags || undefined,
  },
  validationSchema: {
    primaryTag: Yup.mixed().when('isPrimaryTagArtworkFlow', {
      is: (isPrimaryTagArtworkFlow: boolean) => isPrimaryTagArtworkFlow === true,
      then: Yup.mixed()
        .required(t('addArtworkStrings.errors.requiredPrimaryTag'))
        .test('notObjectCheck', t('addArtworkStrings.errors.requiredPrimaryTag'), (value) => !!value?.name),
    }),
    tags: Yup.array().when('isPrimaryTagArtworkFlow', {
      is: (isPrimaryTagArtworkFlow: boolean) => isPrimaryTagArtworkFlow === true,
      then: Yup.array().max(10, t('addArtworkStrings.errors.maxTags')),
    }),
  },
});

export interface ArtworkStatementConfigValues {
  artworkStoryId?: number;
  statement?: string;
}

export const ArtworkStatementConfig: FormikSectionConfig<ArtworkStatementConfigValues> = (override) => ({
  initialValues: {
    artworkStoryId: override?.artworkStoryId,
    statement: override?.statement,
  },
  validationSchema: {
    statement: Yup.string().max(ARTWORK_STATEMENT_LENGTH),
  },
});

export interface ArtworkSeriesConfigValues {
  seriesId?: number;
}

export const ArtworkSeriesConfig: FormikSectionConfig<ArtworkSeriesConfigValues> = (override) => ({
  initialValues: {
    seriesId: override?.seriesId,
  },
  validationSchema: {
    seriesId: Yup.string().max(ARTWORK_STATEMENT_LENGTH),
  },
});

export interface ArtworkBlockchainConfigValues {
  hasBlockchain?: boolean;
  blockchain?: Enum_Blockchain_Enum;
  blockchain_other?: string;
  nft?: string;
  hasMultipleNft?: boolean;
  nftCopiesNumber?: number;
}

// eslint-disable-next-line no-useless-escape
const regexUrlValidation =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
export const ArtworkBlockchainConfig: FormikSectionConfig<ArtworkBlockchainConfigValues> = (override) => ({
  initialValues: {
    hasBlockchain: override?.hasBlockchain || false,
    blockchain: override?.blockchain || undefined,
    blockchain_other: override?.blockchain_other || '',
    nft: override?.nft || '',
    hasMultipleNft: override?.hasMultipleNft || false,
    nftCopiesNumber: override?.nftCopiesNumber || undefined,
  },
  validationSchema: {
    blockchain: Yup.string().when('artworkType', {
      // eslint-disable-next-line max-len
      is: (artworkType: Enum_Art_Type_Enum) => artworkType === Enum_Art_Type_Enum.Digital,
      then: Yup.string().when('hasBlockchain', {
        is: true,
        then: Yup.string().required(t('addArtworkStrings.artDetails.blockchain.error.isRequired')),
      }),
    }),
    blockchain_other: Yup.string().when('artworkType', {
      // eslint-disable-next-line max-len
      is: (artworkType: Enum_Art_Type_Enum) => artworkType === Enum_Art_Type_Enum.Digital,
      then: Yup.string().when('blockchain', {
        is: Enum_Blockchain_Enum.Other,
        then: Yup.string().required(t('addArtworkStrings.artDetails.blockchain.error.otherRequired')),
      }),
    }),
    nft: Yup.string().when('artworkType', {
      // eslint-disable-next-line max-len
      is: (artworkType: Enum_Art_Type_Enum) => artworkType === Enum_Art_Type_Enum.Digital,
      then: Yup.string().when('hasBlockchain', {
        is: true,
        then: Yup.string()
          .matches(regexUrlValidation, t('addArtworkStrings.artDetails.textField.urlFormat'))
          .required(t('addArtworkStrings.artDetails.textField.nftRequired')),
      }),
    }),
    nftCopiesNumber: Yup.number().when('artworkType', {
      // eslint-disable-next-line max-len
      is: (artworkType: Enum_Art_Type_Enum) => artworkType === Enum_Art_Type_Enum.Digital,
      then: Yup.number().when('hasMultipleNft', {
        is: true,
        then: Yup.number()
          .required(t('addArtworkStrings.artDetails.blockchain.error.nftCopiesNumberRequired'))
          .moreThan(1, t('addArtworkStrings.artDetails.blockchain.error.nftCopiesNumberMultiple')),
      }),
    }),
  },
});
