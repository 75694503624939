import { IconButton, makeStyles } from '@material-ui/core';
import Section from 'common/components/Section/Section';
import { ArtworkTagsConfigValues } from 'common/features/FormikConfigs/components/Artwork';
import { UseFormikSection } from 'common/features/FormikConfigs/utils/formikTypeUtils';
import { useTranslation } from 'react-i18next';
import { Enum_Language_Preference_Enum, SuggestedTagsType } from 'common/schema/commonSchemaRemoteTypes';
import { useEffect, useMemo, useState } from 'react';
import { CDNUrl } from 'utils/constants';
import { useUserPreferredLanguage } from 'common/hooks/useUserPreferredLanguage';
import SuggestedTags from '../../Details/components/SuggestedTags/SuggestedTags';
import { mapArtworkAITagsWithLanguage } from '../../../PublishArtwork/utils/publishArtworkUtils';

const customStyle = makeStyles({
  container: {
    backgroundColor: 'white',
  },
  section: {
    width: '100%',
    marginBottom: '16px',
  },
  banner: {
    position: 'relative',
    background: '#E4F3FC',
    padding: '16px',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '12px',
    marginTop: '-12px',
    marginBottom: '24px',
  },
  tagContainer: {
    marginTop: '16px',
    marginBottom: '20px',
  },
  buttonTagContainerClassName: {
    color: '#121112',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    height: '24px',
    gap: '3px',
    position: 'relative',
    padding: '2px 9px',
    background: '#FFFFFF;',
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '6px',
      padding: '1px',
      background: 'linear-gradient(85.98deg, #E77858 3.7%, #D7358E 54.8%, #9567F9 97.14%)',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
    },
  },
  removeTagButton: {
    border: 'none',
    width: '15px',
    height: '15px',
    padding: '4px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '0',
    '& img': {
      width: '9px',
      height: '9px',
    },
  },
});

export enum Enum_UI_AI_Category_Tags_Enum {
  Genre = 'Genre',
  Subjects = 'Subjects',
  Colors = 'Colors',
  Emotions = 'Emotions',
}

export enum Enum_DB_AI_Category_Tags_Enum {
  Subjects = 'Subject Matter',
  Vibes = 'Vibes',
  Mood = 'Mood',
}

export const EMOTIONS_CATEGORY_TAGS = [Enum_DB_AI_Category_Tags_Enum.Vibes, Enum_DB_AI_Category_Tags_Enum.Mood];

export type AIArtworkTags = {
  [key: string]: {
    [language: string]: string[];
  };
};

export type CategoryTag = {
  categoryTagEnum: Enum_UI_AI_Category_Tags_Enum;
  tagList: string[];
  order: number;
};

export const ArtworkAITagsSection = <T extends ArtworkTagsConfigValues = ArtworkTagsConfigValues>({
  formik,
}: UseFormikSection<T>): ReturnType<React.FC<UseFormikSection<T> & { title: string; description: string }>> => {
  const { t } = useTranslation();
  const customClasses = customStyle();
  const preferredLanguage = useUserPreferredLanguage({ shouldFallbackToNavigator: true });
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [userTags, setUserTags] = useState<AIArtworkTags>(formik.values.aiTags?.user_selected_image_tags);

  useEffect(() => {
    formik.setFieldValue('aiTags.user_selected_image_tags', userTags);
  }, [userTags]);

  const handleTagClick = (uiCategoryName: string) => (tag: string) => {
    const languageMap = {
      [Enum_Language_Preference_Enum.English]: 'en',
      [Enum_Language_Preference_Enum.Spanish]: 'sp',
      [Enum_Language_Preference_Enum.French]: 'fr',
      [Enum_Language_Preference_Enum.Portuguese]: 'pg',
      [Enum_Language_Preference_Enum.Romanian]: 'ro',
    };

    const mappedLanguage = languageMap[preferredLanguage];
    const updatedCategories = Object.keys(userTags).reduce((acc: AIArtworkTags, userTagsCategory: string) => {
      let uiToDbCategoryTag = [uiCategoryName];

      if (uiCategoryName === Enum_UI_AI_Category_Tags_Enum.Subjects) {
        uiToDbCategoryTag = [Enum_DB_AI_Category_Tags_Enum.Subjects];
      }

      if (uiCategoryName === Enum_UI_AI_Category_Tags_Enum.Emotions) {
        uiToDbCategoryTag = EMOTIONS_CATEGORY_TAGS;
      }

      // remove the tag from the ui categories
      if (uiToDbCategoryTag.includes(userTagsCategory)) {
        uiToDbCategoryTag.forEach((uiCategory) => {
          const indexToRemove = userTags[uiCategory][mappedLanguage].indexOf(tag.toLowerCase());

          // Remove the tag for each language in the category
          const updatedTags = Object.keys(userTags[uiCategory]).reduce(
            (
              innerAcc: {
                [language: string]: string[];
              },
              language: string
            ) => {
              const updatedLanguageTags = userTags[uiCategory][language].filter((_, index) => index !== indexToRemove);
              innerAcc[language] = updatedLanguageTags;
              return innerAcc;
            },
            {}
          );

          acc[uiCategory] = updatedTags;
        });
      } else {
        acc[userTagsCategory] = userTags[userTagsCategory];
      }
      return acc;
    }, {});

    setUserTags(updatedCategories);
  };

  const aiTags = useMemo(() => {
    if (!userTags) {
      return null;
    }
    const processedTags = mapArtworkAITagsWithLanguage(userTags, preferredLanguage);

    return processedTags
      .filter((category) => category.tagList.length > 0)
      .map(({ categoryTagEnum, tagList }) => {
        const tags: SuggestedTagsType[] = tagList.map((tag) => ({
          value: tag,
          description: tag,
          order: 0,
        }));

        return (
          <div key={categoryTagEnum} style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontWeight: 600 }}>{t(`aiTagsCategory.${categoryTagEnum}`)}</div>
            <div
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <SuggestedTags
                onTagRemoveClick={handleTagClick(categoryTagEnum)}
                tags={tags}
                limitTagsToShow={100}
                canRemove
                showPlusIcon={false}
                containerClassName={customClasses.tagContainer}
                buttonTagContainerClassName={customClasses.buttonTagContainerClassName}
                removeButtonClassName={customClasses.removeTagButton}
              />
            </div>
          </div>
        );
      });
  }, [formik.values.aiTags, preferredLanguage]);

  return (
    <Section
      title={t('addArtworkStrings.storytelling.TagsTitle')}
      description={t('addArtworkStrings.storytelling.TagsDescription')}
      className={customClasses.section}
    >
      {isBannerOpen && (
        <div className={customClasses.banner}>
          <img src={`${CDNUrl}/images/dashboardIcons/InfoIconBlack.svg`} alt="info" height="20px" />

          {t('addArtworkStrings.storytelling.TagsDeleteBanner')}

          <IconButton
            onClick={() => setIsBannerOpen(false)}
            style={{
              position: 'absolute',
              right: '8px',
              padding: '4px',
            }}
          >
            <img src={`${CDNUrl}/images/CloseIcon.svg`} alt="close-button" height="20px" width="20px" />
          </IconButton>
        </div>
      )}
      {aiTags}
    </Section>
  );
};
