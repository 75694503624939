import { makeStyles } from '@material-ui/core';

export const suggestedTagsStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: ' 8px 0 24px',
    gap: '8px',
  },
  item: {},
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '4px',
    background: '#F5F8F8',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    borderRadius: '6px',
    height: '32px',
    padding: '0 12px',
    gap: '6px',
    color: '#2D4991',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  addButton: {
    cursor: 'pointer',
  },
  removeButton: {
    border: '1px solid rgba(0, 0, 0, 0.16)',
    width: '15px',
    height: '15px',
    borderRadius: '20%',
    marginLeft: '4px',
    padding: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  removeImage: {
    width: '11px',
    height: '11px',
    margin: 'auto',
  },
}));
