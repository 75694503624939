import { Enum_Add_Artwork_Flow_Step_Enum, Maybe } from 'common/schema/commonSchemaRemoteTypes';

export type StepsCallbacks = {
  [key in AddArtworkStepType]: RegisteredCallback | null;
};

export type PublishStepCallbacks<T extends PublishStep> = {
  [key in T]?: RegisteredCallback;
};

export type RegisteredCallback = (
  onSuccess: (artworkId?: number) => Promise<void> | void,
  onFailure: (error?: unknown) => Promise<void> | void,
  setIsSteploading?: (value: boolean) => void
) => void | Promise<void>;

export type OptionalRegisteredCallback = (
  onSuccess?: (artworkId?: number) => Promise<void> | void,
  onFailure?: (error?: unknown) => Promise<void> | void
) => void | Promise<void>;

export type Artwork = {
  id: number;
  isDarkMode: boolean;
  seriesId?: number;
};

export type PublishArtworkType = {
  id?: number;
  seriesId?: number;
  isDarkMode?: boolean;
};

export type PublishStep = PublishPreliminaryStepEnum | PublishArtworkStep | PublishPrintStep | PublishSimpleArtworkStep;

export type PublishFlowStep = PublishArtworkStep | PublishPrintStep | PublishSimpleArtworkStep;

type PublishCommonStep =
  | Enum_Add_Artwork_Flow_Step_Enum.Imagery
  | Enum_Add_Artwork_Flow_Step_Enum.Details
  | Enum_Add_Artwork_Flow_Step_Enum.Series
  | Enum_Add_Artwork_Flow_Step_Enum.Storytelling
  | Enum_Add_Artwork_Flow_Step_Enum.Commercial
  | Enum_Add_Artwork_Flow_Step_Enum.Review
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsBlockchain
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsLocation;

// Type guard
export const isPublishCommonStep = (step: Enum_Add_Artwork_Flow_Step_Enum): step is PublishCommonStep =>
  step === Enum_Add_Artwork_Flow_Step_Enum.Imagery ||
  step === Enum_Add_Artwork_Flow_Step_Enum.Details ||
  step === Enum_Add_Artwork_Flow_Step_Enum.Series ||
  step === Enum_Add_Artwork_Flow_Step_Enum.Storytelling ||
  step === Enum_Add_Artwork_Flow_Step_Enum.Commercial ||
  step === Enum_Add_Artwork_Flow_Step_Enum.Review ||
  step === Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate ||
  step === Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions ||
  step === Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline ||
  step === Enum_Add_Artwork_Flow_Step_Enum.DetailsBlockchain ||
  step === Enum_Add_Artwork_Flow_Step_Enum.DetailsLocation;

export type PublishPrintStep = PublishCommonStep | Enum_Add_Artwork_Flow_Step_Enum.Edition;
export type PublishSimpleArtworkStep =
  | PublishCommonStep
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsBlockchain
  | Enum_Add_Artwork_Flow_Step_Enum.DetailsLocation;
export type PublishArtworkStep = PublishCommonStep;

// TODO: add and use from db
export enum PublishPreliminaryStepEnum {
  'PUBLISH_TYPE' = 'PUBLISH_TYPE',
  'PARTNER_ARTIST' = 'PARTNER_ARTIST',
  'PRINT_ARTWORK_TYPE' = 'PRINT_ARTWORK_TYPE',
  // TODO: maybe add congratz step enum too?
}

// TODO: add this to Enum_Add_Artwork_Flow_Step_Enum
export enum PublishExtraStepEnum {
  'CONGRATS' = 'CONGRATS',
}

export enum PublishTypeEnum {
  'UNIQUE_ARTWORK' = 'UNIQUE_ARTWORK',
  'PRINT_EDITION' = 'PRINT_EDITION',
}

export enum PublishArtworkFlowEnum {
  'FULL_FLOW' = 'FULL_FLOW',
  'SIMPLE_FLOW' = 'SIMPLE_FLOW',
  'DIGITAL_FLOW' = 'DIGITAL_FLOW',
  'OUTDOOR_FLOW' = 'OUTDOOR_FLOW',
  'FULL_FLOW_WITH_AI_TAGS' = 'FULL_FLOW_WITH_AI_TAGS',
}

export type AddArtworkStepType = Exclude<Enum_Add_Artwork_Flow_Step_Enum, 'EDITION'>;

export interface IArtworkPublishContext {
  artwork: Artwork;
  setArtwork: React.Dispatch<React.SetStateAction<Artwork>>;
  currentStep: AddArtworkStepType;
  progressStep: AddArtworkStepType;
  setProgressStep: (step: AddArtworkStepType) => void;
  exit: () => void;
  isStepDisabled: (step: AddArtworkStepType) => boolean;
  stepsCallbacks: StepsCallbacks;
  registerStepCallback: (step: AddArtworkStepType, callback: RegisteredCallback) => void;
  thumbnail: Maybe<string>;
  setThumbnail: React.Dispatch<React.SetStateAction<Maybe<string>>>;
  saveChanges: OptionalRegisteredCallback;
  goToPreviousStep: () => void;
  goToNextStep: (artworkId?: number) => void;
  isEdit?: boolean;
  isPublished?: boolean;
  partenerArtistId: Maybe<number>;
  setPartenerArtistId: (id: number) => void;
  isPrintsPublish?: boolean;
  isStepLoading?: boolean;
}

export type CollaborationType = {
  name: string;
  email: string;
  inviteId?: number;
  collabId?: number;
};
