import { ROUTES } from 'routes/_definition';
import {
  Enum_Add_Artwork_Flow_Step_Enum,
  Enum_Artwork_Edition_Type_Enum,
  Enum_Artwork_Showroom_Status_Enum,
  Enum_Art_Type_Enum,
} from 'common/schema/commonSchemaRemoteTypes';
import { NotNullish } from 'common/utils/types';
import { DashboardRoutesConfig } from '../../DashboardRoot/configs/routesConfigs';
import { PUBLISH_ARTWORK_ROUTE, PUBLISH_PRINTS_ROUTE } from '../hooks/usePublishRoutes';
import {
  PublishArtworkFlowEnum,
  PublishArtworkStep,
  PublishExtraStepEnum,
  PublishFlowStep,
  PublishPreliminaryStepEnum,
  PublishStep,
  PublishTypeEnum,
  StepsCallbacks,
} from './artworkPublishTypes';

export const AddArtworkStepsToPath: { [key in Enum_Add_Artwork_Flow_Step_Enum]: string } = {
  [Enum_Add_Artwork_Flow_Step_Enum.Imagery]: 'imagery',
  [Enum_Add_Artwork_Flow_Step_Enum.Edition]: 'edition',
  [Enum_Add_Artwork_Flow_Step_Enum.Details]: 'details',
  [Enum_Add_Artwork_Flow_Step_Enum.Storytelling]: 'storytelling',
  [Enum_Add_Artwork_Flow_Step_Enum.Series]: 'series',
  [Enum_Add_Artwork_Flow_Step_Enum.Commercial]: 'commercial',
  [Enum_Add_Artwork_Flow_Step_Enum.Review]: 'review',
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate]: 'creationDate',
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions]: 'dimensions',
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline]: 'discipline',
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsBlockchain]: 'blockchain',
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsLocation]: 'location',
};

export const AddArtworkPathsToStep: { [key: string]: Enum_Add_Artwork_Flow_Step_Enum } = {
  imagery: Enum_Add_Artwork_Flow_Step_Enum.Imagery,
  edition: Enum_Add_Artwork_Flow_Step_Enum.Edition,
  details: Enum_Add_Artwork_Flow_Step_Enum.Details,
  storytelling: Enum_Add_Artwork_Flow_Step_Enum.Storytelling,
  series: Enum_Add_Artwork_Flow_Step_Enum.Series,
  commercial: Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  review: Enum_Add_Artwork_Flow_Step_Enum.Review,
};

export const publishPreliminarySteps = [
  PublishPreliminaryStepEnum.PUBLISH_TYPE,
  PublishPreliminaryStepEnum.PARTNER_ARTIST,
  PublishPreliminaryStepEnum.PRINT_ARTWORK_TYPE,
];

export const publishArtworkSteps: PublishArtworkStep[] = [
  Enum_Add_Artwork_Flow_Step_Enum.Imagery,
  Enum_Add_Artwork_Flow_Step_Enum.Details,
  Enum_Add_Artwork_Flow_Step_Enum.Series,
  Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  Enum_Add_Artwork_Flow_Step_Enum.Review,
];

export const publishArtworkStepsWithAITags: PublishArtworkStep[] = [
  Enum_Add_Artwork_Flow_Step_Enum.Imagery,
  Enum_Add_Artwork_Flow_Step_Enum.Details,
  Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  Enum_Add_Artwork_Flow_Step_Enum.Storytelling,
  Enum_Add_Artwork_Flow_Step_Enum.Review,
];

// TODO : MOVE OLD ARTWORK PUBLICATION ROUTES TO THE NEW ROUTING SYSTEM
export const publishArtworkStepRoutes: Partial<Record<Enum_Add_Artwork_Flow_Step_Enum, PublishArtworkIdRoutes>> = {
  [Enum_Add_Artwork_Flow_Step_Enum.Imagery]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Details]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Storytelling]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Series]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Commercial]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Review]: null,
};

export const publishArtworkWithAITagsStepRoutes: Partial<
  Record<Enum_Add_Artwork_Flow_Step_Enum, PublishArtworkIdRoutes>
> = {
  [Enum_Add_Artwork_Flow_Step_Enum.Imagery]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Details]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Commercial]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Storytelling]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Review]: null,
};

export const simplePublishArtworkSteps: PublishArtworkStep[] = [
  Enum_Add_Artwork_Flow_Step_Enum.Imagery,
  Enum_Add_Artwork_Flow_Step_Enum.Details,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline,
  Enum_Add_Artwork_Flow_Step_Enum.Storytelling,
  Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  Enum_Add_Artwork_Flow_Step_Enum.Review,
];

export const simplePublishArtworkStepRoutes: Partial<Record<Enum_Add_Artwork_Flow_Step_Enum, PublishArtworkIdRoutes>> =
  {
    [Enum_Add_Artwork_Flow_Step_Enum.Imagery]: ROUTES.MOBILE_ART_PUBLICATION_IMAGERY_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Details]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DIMENSIONS_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_CREATION_DATE_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DISCIPLINE_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Storytelling]: ROUTES.MOBILE_ART_PUBLICATION_STORYTELLING_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Commercial]: ROUTES.MOBILE_ART_PUBLICATION_COMMERCIAL_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Review]: ROUTES.MOBILE_ART_PUBLICATION_REVIEW_ID,
  } as const;

export const publishArtworkIdRoutes = [
  [Enum_Add_Artwork_Flow_Step_Enum.Imagery, ROUTES.MOBILE_ART_PUBLICATION_IMAGERY_ID],
  [Enum_Add_Artwork_Flow_Step_Enum.Details, ROUTES.MOBILE_ART_PUBLICATION_DETAILS_ID],
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions, ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DIMENSIONS_ID],
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate, ROUTES.MOBILE_ART_PUBLICATION_DETAILS_CREATION_DATE_ID],
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline, ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DISCIPLINE_ID],
  [Enum_Add_Artwork_Flow_Step_Enum.Storytelling, ROUTES.MOBILE_ART_PUBLICATION_STORYTELLING_ID],
  [Enum_Add_Artwork_Flow_Step_Enum.Commercial, ROUTES.MOBILE_ART_PUBLICATION_COMMERCIAL_ID],
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsBlockchain, ROUTES.MOBILE_ART_PUBLICATION_DETAILS_BLOCKCHAIN_ID],
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsLocation, ROUTES.MOBILE_ART_PUBLICATION_DETAILS_LOCATION_ID],
  [Enum_Add_Artwork_Flow_Step_Enum.Review, ROUTES.MOBILE_ART_PUBLICATION_REVIEW_ID],
] as const;

// TODO : add the dashboard publication id routes
export type PublishArtworkIdRoutes = (typeof publishArtworkIdRoutes)[number][1] | null;
export type PublishArtworkIdSteps = (typeof publishArtworkIdRoutes)[number][0];

export const isPublishArtworkIdRoute = (route: ROUTES): route is NotNullish<PublishArtworkIdRoutes> =>
  !!publishArtworkIdRoutes.find(([_, matchRoute]) => (route as PublishArtworkIdRoutes) === matchRoute);

export const digitalPublishArtworkSteps: PublishArtworkStep[] = [
  Enum_Add_Artwork_Flow_Step_Enum.Imagery,
  Enum_Add_Artwork_Flow_Step_Enum.Details,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsBlockchain,
  Enum_Add_Artwork_Flow_Step_Enum.Storytelling,
  Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  Enum_Add_Artwork_Flow_Step_Enum.Review,
];

export const digitalPublishArtworkStepRoutes: Partial<Record<Enum_Add_Artwork_Flow_Step_Enum, PublishArtworkIdRoutes>> =
  {
    [Enum_Add_Artwork_Flow_Step_Enum.Imagery]: ROUTES.MOBILE_ART_PUBLICATION_IMAGERY_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Details]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DIMENSIONS_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_CREATION_DATE_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DISCIPLINE_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsBlockchain]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_BLOCKCHAIN_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Storytelling]: ROUTES.MOBILE_ART_PUBLICATION_STORYTELLING_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Commercial]: ROUTES.MOBILE_ART_PUBLICATION_COMMERCIAL_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Review]: ROUTES.MOBILE_ART_PUBLICATION_REVIEW_ID,
  };

export const outdoorPublishArtworkSteps: PublishArtworkStep[] = [
  Enum_Add_Artwork_Flow_Step_Enum.Imagery,
  Enum_Add_Artwork_Flow_Step_Enum.Details,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsLocation,
  Enum_Add_Artwork_Flow_Step_Enum.Storytelling,
  Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  Enum_Add_Artwork_Flow_Step_Enum.Review,
];

export const outdoorPublishArtworkStepRoutes: Partial<Record<Enum_Add_Artwork_Flow_Step_Enum, PublishArtworkIdRoutes>> =
  {
    [Enum_Add_Artwork_Flow_Step_Enum.Imagery]: ROUTES.MOBILE_ART_PUBLICATION_IMAGERY_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Details]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DIMENSIONS_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_CREATION_DATE_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DISCIPLINE_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.DetailsLocation]: ROUTES.MOBILE_ART_PUBLICATION_DETAILS_LOCATION_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Storytelling]: ROUTES.MOBILE_ART_PUBLICATION_STORYTELLING_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Commercial]: ROUTES.MOBILE_ART_PUBLICATION_COMMERCIAL_ID,
    [Enum_Add_Artwork_Flow_Step_Enum.Review]: ROUTES.MOBILE_ART_PUBLICATION_REVIEW_ID,
  };

export const publishStandalonePrintSteps = [
  Enum_Add_Artwork_Flow_Step_Enum.Imagery,
  Enum_Add_Artwork_Flow_Step_Enum.Edition,
  Enum_Add_Artwork_Flow_Step_Enum.Details,
  Enum_Add_Artwork_Flow_Step_Enum.Series,
  Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  Enum_Add_Artwork_Flow_Step_Enum.Review,
];

// TODO : MOVE OLD ARTWORK PUBLICATION ROUTES TO THE NEW ROUTING SYSTEM
export const publishStandalonePrintStepRoutes: Partial<
  Record<Enum_Add_Artwork_Flow_Step_Enum, PublishArtworkIdRoutes>
> = {
  [Enum_Add_Artwork_Flow_Step_Enum.Imagery]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Edition]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Details]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Storytelling]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Series]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Commercial]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Review]: null,
};

export const publishArtworkPrintSteps = [
  Enum_Add_Artwork_Flow_Step_Enum.Imagery,
  Enum_Add_Artwork_Flow_Step_Enum.Edition,
  Enum_Add_Artwork_Flow_Step_Enum.Details,
  Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  Enum_Add_Artwork_Flow_Step_Enum.Review,
];

// TODO : MOVE OLD ARTWORK PUBLICATION ROUTES TO THE NEW ROUTING SYSTEM
export const publishArtworkPrintStepRoutes: Partial<Record<Enum_Add_Artwork_Flow_Step_Enum, PublishArtworkIdRoutes>> = {
  [Enum_Add_Artwork_Flow_Step_Enum.Imagery]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Edition]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Details]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Commercial]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Review]: null,
};

export const publishPrintSteps = publishStandalonePrintSteps;

export const publishStepsByArtworkEditionType: {
  [key in Enum_Artwork_Edition_Type_Enum]: Enum_Add_Artwork_Flow_Step_Enum[];
} = {
  [Enum_Artwork_Edition_Type_Enum.ArtworkEdition]: publishArtworkPrintSteps,
  [Enum_Artwork_Edition_Type_Enum.StandaloneEdition]: publishStandalonePrintSteps,
  [Enum_Artwork_Edition_Type_Enum.Unique]: publishArtworkSteps,
};

export const publishArtworkStepsByFlowType: {
  [key in PublishArtworkFlowEnum]: Enum_Add_Artwork_Flow_Step_Enum[];
} = {
  [PublishArtworkFlowEnum.FULL_FLOW]: publishArtworkSteps,
  [PublishArtworkFlowEnum.SIMPLE_FLOW]: simplePublishArtworkSteps,
  [PublishArtworkFlowEnum.DIGITAL_FLOW]: digitalPublishArtworkSteps,
  [PublishArtworkFlowEnum.OUTDOOR_FLOW]: outdoorPublishArtworkSteps,
  [PublishArtworkFlowEnum.FULL_FLOW_WITH_AI_TAGS]: publishArtworkStepsWithAITags,
};

export const publishArtworkRoutesByFlowType: {
  [key in PublishArtworkFlowEnum]: Partial<Record<Enum_Add_Artwork_Flow_Step_Enum, PublishArtworkIdRoutes>>;
} = {
  [PublishArtworkFlowEnum.FULL_FLOW]: publishArtworkStepRoutes,
  [PublishArtworkFlowEnum.SIMPLE_FLOW]: simplePublishArtworkStepRoutes,
  [PublishArtworkFlowEnum.DIGITAL_FLOW]: digitalPublishArtworkStepRoutes,
  [PublishArtworkFlowEnum.OUTDOOR_FLOW]: outdoorPublishArtworkStepRoutes,
  [PublishArtworkFlowEnum.FULL_FLOW_WITH_AI_TAGS]: publishArtworkWithAITagsStepRoutes,
};

export const publishFlowSteps: PublishFlowStep[] = [
  Enum_Add_Artwork_Flow_Step_Enum.Imagery,
  Enum_Add_Artwork_Flow_Step_Enum.Edition,
  Enum_Add_Artwork_Flow_Step_Enum.Details,
  Enum_Add_Artwork_Flow_Step_Enum.Series,
  Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  Enum_Add_Artwork_Flow_Step_Enum.Storytelling,
  Enum_Add_Artwork_Flow_Step_Enum.Review,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsBlockchain,
  Enum_Add_Artwork_Flow_Step_Enum.DetailsLocation,
];

export const publishSteps: PublishStep[] = [...publishPreliminarySteps, ...publishFlowSteps];

export const SECONDARY_IMAGES_COUNT = 4;
export const PROCESS_IMAGES_COUNT = 8;

export const initialStepsCallback: StepsCallbacks = {
  [Enum_Add_Artwork_Flow_Step_Enum.Imagery]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Details]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Storytelling]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Series]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Commercial]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.Review]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsCreationDate]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsDimensions]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsDiscipline]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsBlockchain]: null,
  [Enum_Add_Artwork_Flow_Step_Enum.DetailsLocation]: null,
};

export const publishTypeRouteToEnum: { [key: string]: PublishTypeEnum } = {
  [PUBLISH_ARTWORK_ROUTE]: PublishTypeEnum.UNIQUE_ARTWORK,
  [PUBLISH_PRINTS_ROUTE]: PublishTypeEnum.PRINT_EDITION,
};

export const publishRoutes = {
  ARTWORK_CONGRATS: `${DashboardRoutesConfig.publish}/${PublishExtraStepEnum.CONGRATS.toLocaleLowerCase()}/:id`,
};

export const MAX_PRINT_COUNT = 1000;
export const DEFAULT_PRINT_STATUS = Enum_Artwork_Showroom_Status_Enum.Available;

export const PRINTS_PER_PAGE = 10;

export const DEFAULT_ARTWORK_TYPE = Enum_Art_Type_Enum.Physical;
export const DEFAULT_ARTWORK_EDITION_TYPE = Enum_Artwork_Edition_Type_Enum.Unique;
export const DEFAULT_ARTWORK_FLOW_TYPE = PublishArtworkFlowEnum.FULL_FLOW;
export const DEFAULT_PRINT_EDITION_TYPE = Enum_Artwork_Edition_Type_Enum.StandaloneEdition;

export const editableEditionSteps = [
  Enum_Add_Artwork_Flow_Step_Enum.Commercial,
  Enum_Add_Artwork_Flow_Step_Enum.Review,
];

export const printStatusValues = [
  Enum_Artwork_Showroom_Status_Enum.Available,
  Enum_Artwork_Showroom_Status_Enum.Unavailable,
  Enum_Artwork_Showroom_Status_Enum.Sold,
];
