import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { CurrencyTypeEnum } from 'common/components/CurrencySelector/CurrencySelector.formik';
import { Enum_Profile_Type_Enum, Enum_Size_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { ROUTES } from 'routes/_definition';
import { ArtworkMeasureUnit } from './types';

export const TABLET_BREAKPOINT: Breakpoint = 'sm';
export const MOBILE_BREAKPOINT: Breakpoint = 'xs';

export const DEFAULT_PROFILE_PICTURE_NAME = 'KaleidoAppDefaultPicture';

export const orderedSizes = [
  Enum_Size_Enum.Xs,
  Enum_Size_Enum.S,
  Enum_Size_Enum.M,
  Enum_Size_Enum.L,
  Enum_Size_Enum.Xl,
];

export const deprecatedCurrencies = [CurrencyTypeEnum.lei, CurrencyTypeEnum['₿'], CurrencyTypeEnum['Ξ']];
export const AVAILABLE_CRYPTOCURRENCIES = [CurrencyTypeEnum['₿'], CurrencyTypeEnum.Ξ];

export const DEFAULT_MEASURE_UNIT = ArtworkMeasureUnit.American;
export const DEFAULT_ARTWORK_TITLE = 'Artwork';
export const DEFAULT_EDITION_TITLE = 'Edition';
export const DEFAULT_ZINDEX_NAVBAR = 1300;
export const DEFAULT_NOTIFICATION_PANEL_ZINDEX = 10001;
export const DEFAULT_ZINDEX_MOBILE_MENU = DEFAULT_ZINDEX_NAVBAR + 1;
export const DEFAULT_ZINDEX_GLASSMORPHIC_NOTCH = DEFAULT_ZINDEX_MOBILE_MENU + 1;
export const DEFALT_ZINDEX_MODAL_OVERLAY = DEFAULT_ZINDEX_GLASSMORPHIC_NOTCH + 1; // So it can be above the Menu Button and nav bar
export const DEFAULT_ZINDEX_ADMIN_WIDGET = DEFAULT_ZINDEX_NAVBAR * 1000;
export const DEFAULT_ZINDEX_SNACKBAR = DEFAULT_ZINDEX_NAVBAR * 1000;
export const AR_APP_DOWNLOAD_LINK = 'https://bit.ly/3WOqZjG';
export const DEFAULT_ZINDEX_BUY_MODAL = 14000;
export const LOW_TOP_NAVBAR_ZINDEX = 1303;
export const LOW_BOTTOM_NAVBAR_ZINDEX = 1303;
export const DEFAULT_ZINDEX_DRAWER = LOW_TOP_NAVBAR_ZINDEX + 1;
export const DEFAULT_TOP_NAVBAR_ZINDEX = 900001;
export const DEFAULT_BOTTOM_NAVBAR_ZINDEX = 900001; // just one above the loading screen
export const TOP_NAVBAR_HEIGHT_NUMBER = 52;
export const TOP_NAVBAR_HEIGHT = `${TOP_NAVBAR_HEIGHT_NUMBER}px`;
export const BOTTOM_NAVBAR_HEIGHT_WITH_TITLE_NUMBER = 58;
export const BOTTOM_NAVBAR_HEIGHT_WITH_TITLE = `${BOTTOM_NAVBAR_HEIGHT_WITH_TITLE_NUMBER}px`;
export const BOTTOM_NAVBAR_HEIGHT_NO_TITLE_NUMBER = 50;
export const BOTTOM_NAVBAR_HEIGHT_NO_TITLE = `${BOTTOM_NAVBAR_HEIGHT_NO_TITLE_NUMBER}px`;
export const MYWEBSITE_TOP_MENU_NAVBAR_HEIGHT_NUMBER = 80;
export const MYWEBSITE_TOP_MENU_NAVBAR_HEIGHT = `${MYWEBSITE_TOP_MENU_NAVBAR_HEIGHT_NUMBER}px`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getWindowFlag = (key: WindowFlags): boolean => !!(window as any)?.[key];
export const setWindowFlag = (key: WindowFlags, value: unknown): void => {
  if (!window) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any)[key] = value;
};
export enum WindowFlags {
  SKIP_KALEIDO_ANALYTICS = 'SKIP_KALEIDO_ANALYTICS',
}

export const ONBOARDING_CONTEXT_URLS: { [key in Enum_Profile_Type_Enum]: string } = {
  ARTIST: String(ROUTES.ONBOARDING_ARTIST),
  SIMPLE_USER: String(ROUTES.ONBOARDING),
  GALLERY: '/onboarding/gallery/profile/first-step',
  COLLECTOR: String(ROUTES.ONBOARDING_USER),
  PSEUDO_COLLECTOR: String(ROUTES.ONBOARDING),
};
